import React, { useMemo } from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import { groupBy, noop } from "underscore";

let getSites = (accounts, onUpdateSite) => {
  if (!accounts) {
    return [];
  }
  let sites = groupBy(accounts, "userSiteId");
  let siteList = [];
  for (const [siteId, accountsInSite] of Object.entries(sites)) {
    siteList.push(
      <div key={siteId}>
        <div className="table__row table__row--accounts">
          <div className="table__column u-text-left milli pc-datagrid__cell">
            <div className="u-text-bold">{accountsInSite[0].firmName} </div>
            {accountsInSite.map((accountInSite) => (
              <div
                key={accountInSite.accountId}
                className="existing-sites__account-name"
              >
                {accountInSite.name}
              </div>
            ))}
          </div>
          <div className="table__column u-text-right milli pc-datagrid__cell">
            <button
              type="button"
              className="pc-btn pc-btn--primary qa-btn-update-site"
              onClick={() => onUpdateSite(accountsInSite[0].userSiteId)}
              value={accountsInSite[0].userSiteId}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }
  return siteList;
};

const SuggestExistingSites = (props) => {
  const {
    loading,
    errors,
    accounts,
    onUpdateSite,
    onLinkNewAccount,
    siteId,
    onBack,
  } = props;
  let siteList = useMemo(
    () => getSites(accounts, onUpdateSite),
    [accounts, onUpdateSite]
  );

  return (
    <div className="suggest-existing-sites pc-u-mt">
      <LoadingOverlay active={loading} />
      {!loading && (
        <>
          <Message className="pc-u-mb" severity="error" messages={errors} />
          We notice that you already have one or more logins associated with
          this institution. You can update accounts associated with your
          existing login. Please note that if you choose to add a new login with
          the same credentials, you may see duplicate accounts on your
          dashboard, which you can remove later.
          <h2 className="pc-u-mt">Review Existing Logins</h2>
          {siteList}
          <div className="u-text-bold pc-u-mt">Add New Login?</div>
          Click below if you would like to make a new login with new or other
          credentials.
          <div className="pc-u-mt-- u-text-right">
            <button
              type="button"
              className="pc-btn qa-btn-back"
              onClick={onBack}
            >
              Back
            </button>
            <button
              type="button"
              className="pc-btn pc-btn--primary qa-btn-add-new-site"
              onClick={() => onLinkNewAccount()}
              value={siteId}
            >
              Add New Login
            </button>
          </div>
        </>
      )}
    </div>
  );
};

SuggestExistingSites.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  accounts: PropTypes.array,
  onUpdateSite: PropTypes.func,
  onLinkNewAccount: PropTypes.func,
  onBack: PropTypes.func,
  siteId: PropTypes.number.isRequired,
};

SuggestExistingSites.defaultProps = {
  loading: false,
  errors: undefined,
  accounts: [],
  onUpdateSite: noop,
  onLinkNewAccount: noop,
  onBack: noop,
};

export default SuggestExistingSites;
