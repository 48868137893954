var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"upgradeBrowserBanner\">\n	<p>You are using a browser which we do not support. <a id=\"upgradeBrowserLink\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"upgradeBrowserLink") || (depth0 != null ? lookupProperty(depth0,"upgradeBrowserLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"upgradeBrowserLink","hash":{},"data":data,"loc":{"start":{"line":2,"column":86},"end":{"line":2,"column":108}}}) : helper)))
    + "\" target=\"_blank\">Consider upgrading</a> to a modern browser.</p>\n</div>";
},"useData":true});