import getUrlParameterByName from "libs/pcap/utils/getUrlParameterByName";

const APPOINTMENT_SCHEDULER_HASH = "#/appointment";

export const isAppointmentUrl = (url) => {
  return (url || "").indexOf(APPOINTMENT_SCHEDULER_HASH) > -1;
};

export const addReasonToAppointmentUrl = (url, reason) => {
  const hasReasonParameterAlready = getUrlParameterByName("r", url);
  if (isAppointmentUrl(url) && reason && !hasReasonParameterAlready) {
    // Add obfuscated reason to appointment scheduling url
    url += `${url.indexOf("?") > -1 ? "&" : "?"}r=${btoa(reason)}`;
  }
  return url;
};

export const addReasonToAppointmentActionButtonUrls = (actions, reason) => {
  if (actions && actions.length) {
    actions.forEach((action) => {
      if (isAppointmentUrl(action.url)) {
        action.url = addReasonToAppointmentUrl(action.url, reason);
      }
    });
  }
};
