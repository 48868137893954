import PropTypes from "prop-types";
import React from "react";
import SplitYourTransactionContainer from "components/TransactionsGridV3/SplitYourTransaction/Container";
import Modal from "components/modal/Modal";

const SplitYourTransactionModal = (props) => {
  return (
    <Modal
      componentName="SplitYourTransactionModal"
      title="Split Your Transaction"
      isOpen={props.isOpen}
      onClosed={props.onCancel}
      className="pc-modal pc-modal--large split-transactions__modal"
      shouldCloseOnOverlayClick={false}
    >
      <SplitYourTransactionContainer {...props} />
    </Modal>
  );
};

SplitYourTransactionModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  onCategoryCreated: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default SplitYourTransactionModal;
