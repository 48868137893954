import PropTypes from "prop-types";
import React from "react";
import RetirementSavingsSummaryContainer from "components/retirementSavingsSummary/RetirementSavingsSummaryContainer";
import EmergencyFundSummaryContainer from "components/emergencyFundSummary/EmergencyFundSummaryContainer";

const DISPLAY_LOCATION__RETIREMENT = "#/dashboard/retirement-planner";

const FourthRow = (props) => {
  return (
    <div className="dashboard__row">
      <div className="dashboard__cell pc-u-1/2">
        <div className="dashboard__item">
          <RetirementSavingsSummaryContainer
            messages={props.messages}
            ace={props.dashboardMessages[DISPLAY_LOCATION__RETIREMENT]}
          />
        </div>
      </div>
      <div className="dashboard__cell pc-u-1/2">
        <div className="dashboard__item">
          <EmergencyFundSummaryContainer />
        </div>
      </div>
    </div>
  );
};

FourthRow.propTypes = {
  messages: PropTypes.array,
  dashboardMessages: PropTypes.object,
};

FourthRow.defaultProps = {
  messages: [],
  dashboardMessages: {},
};

export default FourthRow;
