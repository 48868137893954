import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Mixpanel from "mixpanel";

const AppointmentInformation = ({
  firstName,
  advisorName,
  appointmentData,
}) => {
  const handleCalendarLink = (event) => {
    event.preventDefault();
    const buttonLabel = event.target.textContent;
    Mixpanel.trackEvent(`Click on ${buttonLabel} Calendar link`, {
      component: "Appointment Information",
    });
    if (buttonLabel !== "Google") {
      window.location.href =
        buttonLabel === "iCal"
          ? appointmentData.icalURL
          : appointmentData.outlookURL;
    }
    window.open(appointmentData.gcalURL, "_blank");
  };
  useEffect(() => {
    Mixpanel.trackEvent("View Appointment Confirmation", {
      component: "Appointment Information",
    });
  }, []);
  return (
    <div className="appointment-information-container">
      <div className="milli"> We look forward to meeting you, {firstName}.</div>
      <div className="centi">
        {" "}
        <span>{advisorName} will call you on </span>
        <span className="u-text-bold">
          {appointmentData.appointmentDay},{" "}
          {appointmentData.appointmentStartTime} -{" "}
          {appointmentData.appointmentEndTime}.
        </span>
      </div>
      <div className="milli">
        Add to calendar:
        <button className="pc-btn--link pc-u-pl--" onClick={handleCalendarLink}>
          Google
        </button>
        <button className="pc-btn--link pc-u-pl--" onClick={handleCalendarLink}>
          iCal
        </button>
        <button className="pc-btn--link pc-u-pl--" onClick={handleCalendarLink}>
          Outlook
        </button>
      </div>
    </div>
  );
};
export default AppointmentInformation;
AppointmentInformation.propTypes = {
  firstName: PropTypes.string,
  advisorName: PropTypes.string,
  appointmentData: PropTypes.object,
};

AppointmentInformation.defaultProps = {
  firstName: "",
  advisorName: "",
  appointmentData: {},
};
