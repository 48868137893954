var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"pc-layout__item\">\n  <span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isRefetchTransactionEligible") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":53,"column":11}}})) != null ? stack1 : "")
    + "  </span>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPrivileged") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":36,"column":13}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div\n          class=\"js-button-refetch-transactions-wrapper\"\n          title=\"Retrieve transactions to try to correct missing or duplicate transactions.\"\n          aria-label=\"Retrieve transactions to try to correct missing or duplicate transactions.\"\n        >\n          <button\n            class=\"pc-btn pc-btn--tiny js-button-refetch-transactions refetch-transactions-button\"\n            type=\"button\"\n            disabled\n          >\n            <svg class=\"transactions__multi-edit-icon\" viewBox=\"0 0 13 12\">\n              <use xlink:href=\"#icon-refresh\"></use>\n            </svg>\n          </button>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <div\n        class=\"js-button-refetch-transactions-wrapper\"\n        title=\"Retrieve transactions to try to correct missing or duplicate transactions.\"\n        aria-label=\"Retrieve transactions to try to correct missing or duplicate transactions.\"\n      >\n        <button\n          class=\"pc-btn pc-btn--tiny js-button-refetch-transactions refetch-transactions-button\"\n          type=\"button\"\n        >\n          <svg class=\"transactions__multi-edit-icon\" viewBox=\"0 0 13 12\">\n            <use xlink:href=\"#icon-refresh\"></use>\n          </svg>\n        </button>\n      </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    <div\n      class=\"js-button-refetch-transactions-wrapper\"\n      title=\"Transaction refresh can be done once a week at most.\"\n      aria-label=\"Transaction refresh can be done once a week at most.\"\n    >\n      <button\n        class=\"pc-btn pc-btn--tiny js-button-refetch-transactions refetch-transactions-button\"\n        type=\"button\"\n        disabled\n      >\n        <svg class=\"transactions__multi-edit-icon\" viewBox=\"0 0 13 12\">\n          <use xlink:href=\"#icon-refresh-disabled\"></use>\n        </svg>\n      </button>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showRefetchTransaction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":56,"column":7}}})) != null ? stack1 : "");
},"useData":true});