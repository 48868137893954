import PropTypes from "prop-types";
import React from "react";
import objectPath from "object-path";
import { isAppointmentAction } from "../helpers";

const TrackAccountsOrPickATime = ({ data, onActionClick }) => {
  const actions = data.action || [];
  const actionsMap =
    objectPath.get(data, "resources.0.jsonContent.actionsMap") || {};
  return (
    <div className="pc-layout pc-layout--auto track-accounts-or-pick-a-time">
      {actions.map((action) => {
        const buttonProps = isAppointmentAction(action.key)
          ? { "data-attribution-source": action.attributionSource }
          : {};
        const actionData = actionsMap[action.key] || {};
        return (
          <div
            key={action.key}
            className="pc-layout__item pc-u-1/2 track-accounts-or-pick-a-time__pane"
          >
            <div className="track-accounts-or-pick-a-time__pane-content">
              <h2 className="track-accounts-or-pick-a-time__title">
                {actionData.title}
              </h2>
              <div className="track-accounts-or-pick-a-time__image-container">
                <img src={actionData.image} />
              </div>
              <p className="track-accounts-or-pick-a-time__description">
                {actionData.description}
              </p>
              <div className="track-accounts-or-pick-a-time__button-container">
                <button
                  {...buttonProps}
                  type="button"
                  className={`pc-btn pc-btn--${action.type}`}
                  onClick={onActionClick.bind(null, action.key, action.title)}
                >
                  {action.title}
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TrackAccountsOrPickATime;

TrackAccountsOrPickATime.propTypes = {
  data: PropTypes.object.isRequired,
  onActionClick: PropTypes.func,
};

TrackAccountsOrPickATime.defaultProps = {
  onActionClick: undefined,
};
