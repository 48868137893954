import React, { useEffect, useState } from "react";
import GoalsQuestionnaire from "../GoalsQuestionnaire/GoalsQuestionnaire";
import PropTypes from "prop-types";
import { promisify } from "utils/service";
import Services from "services";
import Mixpanel from "mixpanel";

export const MANAGE_INVESTMENT_OPTIONS = [
  {
    label: "Work Retirement Plan (ex. 401k, IRA)",
    icon: "/static/img/firstUse/icons/icon_retirement-plan.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_retirement-plan2.svg",
    key: "RETIREMENT_PLAN",
  },
  {
    label: "Do It Myself",
    icon: "/static/img/firstUse/icons/icon_self-serve.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_self-serve2.svg",
    key: "DO_IT_MYSELF",
  },
  {
    label: "Roboadvisor",
    icon: "/static/img/firstUse/icons/icon_robo-advisor.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_robo-advisor2.svg",
    key: "ROBO_ADVISOR",
  },
  {
    label: "Work with an Advisor",
    icon: "/static/img/firstUse/icons/icon_advisor.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_advisor2.svg",
    key: "WORK_WITH_ADVISOR",
  },
  {
    label: "Certified Financial Planner©",
    icon: "/static/img/firstUse/icons/icon_financial-planner.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_financial-planner2.svg",
    key: "CERTIFIED_FINANCIAL_PLANNER",
  },
  {
    label: "First Time Investor",
    icon: "/static/img/firstUse/icons/icon_first-time-investor.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_first-time-investor2.svg",
    key: "FIRST_TIME_INVESTOR",
  },
];

const component = "Information Gathering";

const ManageYourInvestment = ({
  person,
  updatePerson,
  onSubmit,
  addUserResponse,
  getByQuestionGroupName,
}) => {
  const [questionId, setQuestionId] = useState(null);
  useEffect(() => {
    getByQuestionGroupName({ questionGroupName: "ONBOARDING_IG_QG" }).then(
      (response) => {
        const questionId =
          response.questionGroupResponses.questionGroupDto?.questionDtoList?.find(
            (list) => list.questionKey === "MANAGE_INVESTMENT"
          )?.questionMapUuid;
        setQuestionId(questionId);
      }
    );
  }, []);

  const handleSubmit = (model) => {
    const goalKeys = [];
    const notesForAdvisor = {
      igFormInvestmentManagementStyle: [],
    };
    if (model.options.length > 0) {
      for (const item of model.options) {
        notesForAdvisor.igFormInvestmentManagementStyle.push(item.label);
        goalKeys.push(item.key);
      }
    }
    if (model.otherText) {
      notesForAdvisor.igFormInvestmentManagementStyle.push(
        `Other - ${model.otherText}`
      );
      goalKeys.push("OTHER_REASON");
    }
    if (notesForAdvisor.igFormInvestmentManagementStyle.length) {
      updatePerson({
        personId: person.id,
        source: "In-App Short IG 2 Form - Page 1",
        person: JSON.stringify({ notesForAdvisor: notesForAdvisor }),
      });
    }
    if (goalKeys.length > 0) {
      Mixpanel.trackEvent("Information Gathering Goals - Submit", {
        selectedGoals: goalKeys.join(", "),
        otherReason: model?.otherText,
        component: component,
      });
    }
    if (goalKeys.length > 0 && questionId) {
      const goalKeysCaps = goalKeys.map((key) => key.toUpperCase());
      addUserResponse({
        userResponse: JSON.stringify({ [questionId]: goalKeysCaps }),
      });
    }
    onSubmit();
  };

  const textAreaData = {
    label: "Any other questions or concerns?",
    placeholder: "Additional information you want our advisors to know",
    rows: 3,
  };

  useEffect(() => {
    Mixpanel.trackEvent("View Information Gathering Goals", {
      component: component,
    });
  }, []);

  return (
    <GoalsQuestionnaire
      title="How do you manage your investments?"
      subTitle="This helps our advisors understand how they can help. Select all that apply."
      options={MANAGE_INVESTMENT_OPTIONS}
      textAreaData={textAreaData}
      onSubmit={handleSubmit}
    />
  );
};

export default ManageYourInvestment;

ManageYourInvestment.propTypes = {
  person: PropTypes.object.isRequired,
  updatePerson: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  addUserResponse: PropTypes.func,
  getByQuestionGroupName: PropTypes.func,
};
ManageYourInvestment.defaultProps = {
  updatePerson: promisify(Services.Person.update),
  addUserResponse: promisify(Services.UserResponse.add),
  getByQuestionGroupName: promisify(
    Services.UserResponse.getByQuestionGroupName
  ),
};
