import PropTypes from "prop-types";
import React from "react";
import { noop } from "underscore";
import Mixpanel from "mixpanel";
import Modal from "components/modal/Modal";

const CANCEL_LABEL = "No thanks";
const CONFIRM_LABEL = "Continue without linking";
const COMPONENT_NAME = "Not Ready to Link An Account";
const TITLE = "Not ready to link an account?";

export default class NotReadyToLinkAnAccountModal extends React.Component {
  constructor(props) {
    super(props);

    this.onContinueWithoutLinkingAccount =
      this.onContinueWithoutLinkingAccount.bind(this);
  }

  componentDidMount() {
    Mixpanel.trackEvent("View Not Ready to Link Account Modal");
  }

  onContinueWithoutLinkingAccount() {
    this.props.onContinue();
  }

  render() {
    return (
      <Modal
        componentName={COMPONENT_NAME}
        title={TITLE}
        className="pc-modal"
        isOpen
      >
        <section>
          If you&apos;re not ready to link an account yet, you can manually add
          your account balances to continue exploring our free tools.&nbsp;
          You&apos;ll still be able to calculate your net worth and build a
          retirement plan.
          <div className="pc-u-mt u-text-right">
            <button
              type="button"
              className="pc-btn qa-cancel-btn"
              onClick={this.props.onCancel}
            >
              {CANCEL_LABEL}
            </button>
            <button
              type="submit"
              className={`pc-btn pc-btn--primary qa-submit-btn`}
              onClick={this.onContinueWithoutLinkingAccount}
            >
              {CONFIRM_LABEL}
            </button>
          </div>
        </section>
      </Modal>
    );
  }
}

NotReadyToLinkAnAccountModal.propTypes = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
};

NotReadyToLinkAnAccountModal.defaultProps = {
  onCancel: noop,
  onContinue: noop,
};
