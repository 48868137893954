/* eslint-disable no-magic-numbers */

/**
 * Visit: `https://github.com/nosir/cleave.js/blob/master/doc/options.md` to read about Cleave options
 * useFormattedValue: Is not a cleave option it's added to support some fields which requires formatted data
 * When `useFormattedValue`: `true` model the model data will be in formated state
 * Example: For a Date of Birth field value will be `12/31/2019` and the model will also have
 * formatted value `model.dob`: `12/31/2019`.
 * When `useFormattedValue`: `false` data will be unformatted
 * Example: For a currency field value will be unformatted `500,201.50` but the model should be updated
 * with unformatted data `model.currency`: `500201.50`.
 * `use of "useFormattedValue" is not encouraged` use to-server mappers to unformat data.
 */

export const US_FULL_DATE_FORMAT = {
  useFormattedValue: true,
  date: true,
  datePattern: ["m", "d", "Y"],
};

export const MONTH_FORMAT = {
  date: true,
  datePattern: ["m"],
};

export const DAY_FORMAT = {
  date: true,
  datePattern: ["d"],
};

export const YEAR_FORMAT = {
  date: true,
  datePattern: ["Y"],
};

export const SSN_FORMAT = {
  useFormattedValue: true,
  blocks: [3, 2, 4],
  delimiter: "-",
  numericOnly: true,
};

export const ZIP_CODE_FORMAT = {
  blocks: [5, 4],
  delimiter: "-",
  numericOnly: true,
  // prevents the display of a trailing "-" if the zip code is 5 numbers only
  delimiterLazyShow: true,
};

export const CANADA_ZIP_CODE_FORMAT = {
  blocks: [3, 3],
  delimiter: " ",
  numericOnly: false,
  delimiterLazyShow: true,
};

export const CURRENCY_FORMAT = {
  numeral: true,
  numeralThousandsGroupStyle: "thousand",
};

export const POSITIVE_CURRENCY_FORMAT = {
  numeral: true,
  numeralPositiveOnly: true,
  numeralThousandsGroupStyle: "thousand",
};

export const PHONE_NUMBER_FORMAT = {
  blocks: [0, 3, 3, 4],
  numericOnly: true,
  delimiters: ["(", ") ", "-"],
};

export const MASKED_PHONE_NUMBER_FORMAT = {
  blocks: [0, 3, 3, 4],
  numericOnly: false,
  delimiters: ["(", ") ", "-"],
};

export const ROUTING_NUMBER_FORMAT = {
  blocks: [9],
  numericOnly: true,
};

export const POSITIVE_INTEGER_FORMAT = {
  numeral: true,
  numeralPositiveOnly: true,
  numeralThousandsGroupStyle: "thousand",
  numeralDecimalScale: 0,
};

export const RETIREMENT_AGE_FORMAT = {
  blocks: [2],
  numericOnly: true,
};

export const FIVE_DECIMAL_PLACES_FORMAT = {
  numeral: true,
  numeralDecimalScale: 5,
};

export const PERCENTAGE_FORMAT = {
  numeral: true,
  numeralPositiveOnly: true,
  numeralDecimalScale: 2,
  useFormattedValue: true,
};
