var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-toggle='account-block-tooltip' title='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "'";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='notificationIcon expand sticky sidebar-account__status-icon sidebar-account__status-icon--warning qa-sidebar-account-status-icon-warning' data-toggle='tooltip' title='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "'></div>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='notificationIcon expand sticky sidebar-account__status-icon sidebar-account__status-icon--info qa-sidebar-account-status-icon-info' data-toggle='tooltip' title='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "'></div>";
},"7":function(container,depth0,helpers,partials,data) {
    return "            <div class='notificationIcon expand sticky'>\n                <div class=\"loader--dark loader__container--small loader__container u-full-center--abs\">\n                  <div class=\"loader__circle loader__circle--small loader__full-circle loader__full-circle--small\"></div>\n                  <div class=\"loader__circle loader__circle--small loader__spinning-circle loader__spinning-circle--small qa-loader-small\"></div>\n                </div>\n            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"edit u-full-center--abs qa-sidebar-account-edit\">\n        <svg class=\"sidebar-account__edit-icon\" viewBox=\"0 0 9 9\" role=\"button\" title=\"Edit account\" alt=\"Edit account\">\n          <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon-pencil\"></use>\n        </svg>\n      </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='sidebar-account__detail-name qa-sidebar-account-detail-name' title='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "' data-hj-masked>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='sidebar-account__status-message' title='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0))
    + "'>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0))
    + "</div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"manual-entry__text\">\n            <span class=\"js-manual-entry\">Manual entry</span>\n              <svg  class=\"js-manual-entry-info icon__help-circled\" data-toggle=\"manual-entry-tooltip\" title='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"manualAccountTooltipText") : depth0), depth0))
    + "'></svg>\n          </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","NONE",{"name":"compare","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":10},"end":{"line":63,"column":22}}})) != null ? stack1 : "")
    + "          <!--Since the server is not returning iconType for onUS, we have to verify manually. Also, we have to verify\n          that the account action is not \"WAIT\" so we don't show the refresh button when aggregating. This will be solved in PFM-2016 -->\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==",undefined,{"name":"compare","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":10},"end":{"line":74,"column":22}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":10},"end":{"line":77,"column":17}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "            <div class='notificationIcon expand sticky NONE sidebar-account__refresh'>\n              <svg class=\"\" viewBox=\"0 0 10 10\">\n                <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon-refresh\"></use>\n              </svg>\n            </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"action") : stack1),"!=","WAIT",{"name":"compare","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":73,"column":24}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "              <div class='notificationIcon expand sticky NONE sidebar-account__refresh'>\n                <svg class=\"\" viewBox=\"0 0 10 10\">\n                  <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon-refresh\"></use>\n                </svg>\n              </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(__default(require("../../helpers/timestamp.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0),{"name":"timestamp","hash":{},"data":data,"loc":{"start":{"line":76,"column":12},"end":{"line":76,"column":39}}}))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"sidebar-account__row sidebar-account--move-it\">\n      <div class=\"sidebar-account__first-col\">\n      </div>\n      <div class=\"sidebar-account__second-col\" style=\"color: white\">\n        Move Funds to "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"rebrandOrganizationName") : depth0), depth0)) != null ? stack1 : "")
    + "\n      </div>\n      <div class=\"sidebar-account__third-col\">\n        <a href=\"#\" class=\"js-move-it pc-btn--primary sidebar-account__move-it-link\"><img class=\"sidebar-account__move-it-icon\" src=\"/static/img/icons/arrow--long-right.svg\" alt=\"\"></a>\n      </div>\n    </div>\n    <div class=\"sidebar-account__row js-move-it-confirmation hidden sidebar-account--move-it\" style=\"margin-top: 0;\">\n      <div class=\"sidebar-account__first-col\">\n      </div>\n      <div class=\"sidebar-account__second-col milli sidebar-account__move-it-confirm-text\" >\n        Continue to select your accounts for transfer <br> and schedule a call?\n      </div>\n      <div class=\"sidebar-account__third-col sidebar-account__move-it-confirm-row\">\n        <a href=\"#/invest?psaid="
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"accountId") : depth0), depth0))
    + "\" class=\"js-move-it-confirm pc-btn--primary sidebar-account__move-it-link\"><img class=\"sidebar-account__move-it-icon\" src=\"/static/img/icons/arrow--long-right.svg\" alt=\"\"></a>\n      </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sidebar-account__header qa-sidebar-account-header\" "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","ERROR",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":63},"end":{"line":1,"column":196}}})) != null ? stack1 : "")
    + ">\n  <div class=\"sidebar-account__row\">\n    <div class=\"js-edit-account-container\"></div>\n      <div class=\"sidebar-account__first-col\">\n          "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","WARNING",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":288}}})) != null ? stack1 : "")
    + "\n          "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","INFO",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":279}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","AGGREGATING",{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":14,"column":22}}})) != null ? stack1 : "")
    + "      </div>\n\n    <div class=\"sidebar-account__second-col\">\n\n      <div class=\"sidebar-account__title js-sidebar-account-title qa-sidebar-account-title\">\n        "
    + alias2(__default(require("../../helpers/detailsLink2.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"firmName") : depth0),{"name":"detailsLink2","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":33}}}))
    + "\n      </div>\n    </div>\n\n    <div class=\"sidebar-account__third-col\">\n      <div class=\"sidebar-account__value qa-sidebar-account-value tabular-numbers\">\n        "
    + alias2(__default(require("../../helpers/dollarAndCentsAmount.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"balance") : depth0),true,true,(depth0 != null ? lookupProperty(depth0,"isLiability") : depth0),false,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":26,"column":68}}}))
    + "\n      </div>\n    </div>\n  </div>\n\n  <div class=\"sidebar-account__row\">\n    <div class=\"sidebar-account__first-col\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAccountEditable") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":6},"end":{"line":39,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"sidebar-account__second-col\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":46,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"sidebar-account__third-col\">\n      <div class=\"sidebar-account__time js-sidebar-account__time\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dontShowTimestamp") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":55,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"dontShowTimestamp") : depth0), depth0),{"name":"dontShowTimestamp","hash":{},"fn":container.noop,"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":56,"column":8},"end":{"line":78,"column":30}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMoveItAccount") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":2},"end":{"line":104,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});