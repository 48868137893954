const KEY_GOALS_UPDATED = "updatedGoals";
const KEY_GOALS_REMOVED = "removedGoals";
const KEY_GOALS_NEW = "newGoals";
// rename to a "goal" if we end up using the same concept for all upcoming planners
const KEY_GOAL_EDUCATION = "educationGoal";
const KEY_WHAT_IF = "whatIf";
const KEY_PLAN_METADATA = "planMetadata";

export function saveWhatIf(whatIf) {
  sessionStorage.setItem(KEY_WHAT_IF, JSON.stringify(whatIf));
}

export function saveEducationGoal(goal) {
  sessionStorage.setItem(KEY_GOAL_EDUCATION, JSON.stringify(goal));
}

export function saveRemovedGoals(goals) {
  sessionStorage.setItem(KEY_GOALS_REMOVED, JSON.stringify(goals));
}

export function saveUpdatedGoals(goals) {
  sessionStorage.setItem(KEY_GOALS_UPDATED, JSON.stringify(goals));
}

export function saveNewGoals(goals) {
  sessionStorage.setItem(KEY_GOALS_NEW, JSON.stringify(goals));
}

export function savePlanMetadata(planMetadata) {
  sessionStorage.setItem(KEY_PLAN_METADATA, JSON.stringify(planMetadata));
}

export function getWhatIf() {
  return JSON.parse(sessionStorage.getItem(KEY_WHAT_IF));
}

export function getEducationGoal() {
  return JSON.parse(sessionStorage.getItem(KEY_GOAL_EDUCATION));
}

export function getRemovedGoals() {
  return JSON.parse(sessionStorage.getItem(KEY_GOALS_REMOVED));
}

export function getUpdatedGoals() {
  return JSON.parse(sessionStorage.getItem(KEY_GOALS_UPDATED));
}

export function getNewGoals() {
  return JSON.parse(sessionStorage.getItem(KEY_GOALS_NEW));
}

export function getPlanMetadata() {
  return JSON.parse(sessionStorage.getItem(KEY_PLAN_METADATA));
}

export function hasUnsavedChanges() {
  const updatedGoals = sessionStorage.getItem(KEY_GOALS_UPDATED);
  const removedGoals = sessionStorage.getItem(KEY_GOALS_REMOVED);
  const newGoal = sessionStorage.getItem(KEY_GOAL_EDUCATION);
  return Boolean(newGoal || updatedGoals || removedGoals);
}

export function clear() {
  sessionStorage.removeItem(KEY_WHAT_IF);
  sessionStorage.removeItem(KEY_GOALS_NEW);
  sessionStorage.removeItem(KEY_GOALS_REMOVED);
  sessionStorage.removeItem(KEY_GOALS_UPDATED);
  sessionStorage.removeItem(KEY_GOAL_EDUCATION);
  sessionStorage.removeItem(KEY_PLAN_METADATA);
}

export function clearEducationGoal() {
  sessionStorage.removeItem(KEY_GOAL_EDUCATION);
}
