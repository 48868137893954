import PropTypes from "prop-types";
import React from "react";
import { formatCurrency } from "libs/pcap/utils/format";
import Loading from "components/common/loading/Loading";
import Message from "components/common/Message";
import CtaOverlay from "components/common/CtaOverlay";
import { noop, isEmpty } from "underscore";
import BudgetingWatch from "components/budgeting/BudgetingWatch";
import moment from "moment";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";
import { handleAmplitudeEvent } from "../../empower/utils/eventUtils";

const OUTER_RADIUS = 75;
const INNER_RADIUS = 67;
const OUTER_THICKNESS = 6;
const INNER_THICKNESS = 4;
const CHART_PADDING = 10;
const LABEL_SPACE = 20;

/**
 * `Budgeting` displays top spending categories.
 *
 * Initialization options:
 *
 * @param  {Object}   props                 Initialization options.
 * @param  {Array}    props.categories      An array of spending categories
 * @param  {Number}   props.current         Aggregate of spending amount so far
 * @param  {Number}   props.target          Target spending amount
 */
export default class BudgetingSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCategoryIndex: 0,
    };

    this.onCategoryMouseOut = this.onCategoryMouseOut.bind(this);
  }

  onCategoryMouseOver(category) {
    this.props.onCategoryMouseOverCallback(category);
  }

  onCategoryMouseOut() {
    this.props.onCategoryMouseOutCallback();
  }

  getCategorySummary(category) {
    return (
      <div className="budgeting-summary__category qa-budgeting-category">
        <div
          className="pc-layout__item pc-u-2/3 budgeting-summary__category-name"
          tabIndex="0"
          title={category.name}
          aria-label={
            "Spent " +
            formatCurrency(category.amount, 0) +
            " in " +
            category.name
          }
        >
          {category.name}
        </div>
        <div className="pc-layout__item pc-u-1/3 u-text-right tabular-numbers">
          {formatCurrency(category.amount, 0)}
        </div>
      </div>
    );
  }
  handleTitleClick() {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "widgets_budgeting_clicked_event"
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
        event_properties: {
          selection: "widgets_budgeting_clicked_event",
        },
      });
    }
  }

  handleTooltipClick = () => {
    const name = "Budgeting";
    const element = "SELECT_BUTTON";
    const section = "Widget.toolTip";
    const payload = { widget: name };

    handleAmplitudeEvent(element, section, payload);
  };

  render() {
    const {
      loading,
      errors,
      ace,
      chartLayoutClassName,
      current,
      target,
      previous,
      hover,
      onUpdate,
      legendLayoutClassName,
      categories,
    } = this.props;

    const empowerSharedData = window.integratedSharedData;
    const tooltip =
      empowerSharedData?.translations?.get("zeroStateDashboard")?.budgeting
        ?.tooltip;
    const tooltipClass = tooltip ? "with-tooltip" : "";
    const widgetClass = "summary-widget__budgeting";

    if (loading) {
      return <Loading />;
    }
    if (errors) {
      return <Message severity="error" messages={errors} />;
    }

    let aceOverlay, budgetingWatchProps;
    if (ace) {
      aceOverlay = <CtaOverlay message={ace} widgetName="Budgeting Summary" />;
    }

    budgetingWatchProps = {
      hideTicks: true,
      hideSummaryLabel: true,
      hideSummaryMonthLegend: true,
      targetBudget: target,
      onBudgetUpdate: onUpdate,
      current: {
        totalValue: target || 1,
        strongDialValue: isEmpty(hover) ? current : hover.current,
        lightDialValue: current,
      },
      startDate: moment().startOf("month"),
      endDate: moment(),

      outerRadius: OUTER_RADIUS,
      innerRadius: INNER_RADIUS,
      outerThickness: OUTER_THICKNESS,
      chartPadding: CHART_PADDING,
      labelPadding: LABEL_SPACE,
      innerThickness: INNER_THICKNESS,
    };

    if (!isEmpty(previous)) {
      const { total: monthBudgetingTotal } = previous.monthBudgeting;
      budgetingWatchProps.past = {
        totalValue: target || 1,
        strongDialValue: isEmpty(hover)
          ? previous.sameTimeBudgeting.total
          : hover.previous,
        lightDialValue:
          target < monthBudgetingTotal ? target : monthBudgetingTotal,
      };
    }
    return (
      <section className={`summary-widget ${tooltipClass} ${widgetClass}`}>
        <div className="summary-widget__header">
          <div className="pc-delta header-container">
            <h2 className="pc-u-mb0">
              <a
                href={BUDGETING_URL}
                className="summary-widget__title--link qa-budgeting-summary-title"
                aria-label="Go to Budgeting"
                onClick={this.handleTitleClick}
              >
                Budgeting
              </a>
            </h2>
            {tooltip && (
              <InfoTooltipIcon
                title={tooltip}
                container={false}
                parentClassName={widgetClass}
                tooltipProps={{
                  trigger: "click",
                  role: "alert",
                }}
                onClick={this.handleTooltipClick}
              />
            )}
          </div>
        </div>
        <div className="summary-widget__body">
          <div>
            <div className={chartLayoutClassName}>
              <div className="budgeting-summary-chart-section js-budgeting-summary-chart-section">
                <BudgetingWatch {...budgetingWatchProps} />
              </div>
            </div>
            <div
              className={legendLayoutClassName}
              tabIndex={categories.length === 0 ? -1 : 0}
              aria-label="Spending by category"
            >
              {categories.length === 0 ? (
                <span className="pc-help-block pc-help-block--small qa-budgeting-summary-zero-spending">
                  No Spending
                </span>
              ) : (
                categories.map((category, i) => {
                  return category.transactionCategoryId ? (
                    <a
                      href={`${BUDGETING_URL}/${category.transactionCategoryId}`}
                      key={`key-budgeting-summary-category-${category.transactionCategoryId}`}
                      onMouseOver={this.onCategoryMouseOver.bind(
                        this,
                        category
                      )}
                      onMouseOut={this.onCategoryMouseOut}
                      className={`qa-budgeting-category-link js-budgeting-summary-category-${category.transactionCategoryId}`}
                    >
                      {this.getCategorySummary(category)}
                    </a>
                  ) : (
                    <React.Fragment key={`key-budgeting-summary-category-${i}`}>
                      {this.getCategorySummary(category)}
                    </React.Fragment>
                  );
                })
              )}
            </div>
          </div>
        </div>
        {aceOverlay}
      </section>
    );
  }
}

BudgetingSummary.defaultProps = {
  loading: false,
  errors: null,
  categories: [],
  current: undefined,
  target: undefined,
  previous: undefined,
  takeaway: undefined,
  onEditCallback: undefined,
  onUpdate: undefined,
  ace: undefined,
  onCategoryMouseOverCallback: noop,
  onCategoryMouseOutCallback: noop,
  hover: {},
  chartLayoutClassName: "pc-u-1/2",
  legendLayoutClassName: "pc-u-1/2 pc-u-mt",
};

BudgetingSummary.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  categories: PropTypes.array,
  current: PropTypes.number,
  target: PropTypes.number,
  previous: PropTypes.object,
  takeaway: PropTypes.object,
  onEditCallback: PropTypes.func,
  onUpdate: PropTypes.func,
  ace: PropTypes.object,
  onCategoryMouseOverCallback: PropTypes.func,
  onCategoryMouseOutCallback: PropTypes.func,
  hover: PropTypes.object,
  chartLayoutClassName: PropTypes.string,
  legendLayoutClassName: PropTypes.string,
};
