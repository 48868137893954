import PropTypes from "prop-types";
import React from "react";
import RemoveAccountView from "components/common/RemoveAccount/RemoveAccountView";
import Modal from "components/modal/Modal";

const RemoveAccountModal = ({
  onCancel,
  onRemoveAccount,
  isOpen,
  loading,
  errors,
  modalCancelButtonText,
  modalConfirmButtonText,
  modalBody,
  modalTitle,
  renderButtonSet,
  customActions,
  componentName,
  modalClassName,
}) => {
  let className = "pc-modal pc-modal--small";
  if (modalClassName) {
    className += ` ${modalClassName}`;
  }
  return (
    <Modal
      componentName={componentName}
      title={modalTitle}
      isOpen={isOpen}
      onClosed={onCancel}
      className={className}
      shouldCloseOnOverlayClick={false}
    >
      <RemoveAccountView
        onCancel={onCancel}
        onRemoveAccount={onRemoveAccount}
        loading={loading}
        errors={errors}
        modalCancelButtonText={modalCancelButtonText}
        modalConfirmButtonText={modalConfirmButtonText}
        customActions={customActions}
        renderButtonSet={renderButtonSet}
        modalBody={modalBody}
      />
    </Modal>
  );
};

RemoveAccountModal.propTypes = {
  componentName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemoveAccount: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  modalCancelButtonText: PropTypes.string.isRequired,
  modalConfirmButtonText: PropTypes.string.isRequired,
  modalBody: PropTypes.element.isRequired,
  modalTitle: PropTypes.string,
  renderButtonSet: PropTypes.func,
  customActions: PropTypes.object,
  modalClassName: PropTypes.string,
};

RemoveAccountModal.defaultProps = {
  loading: false,
  errors: [],
  renderButtonSet: undefined,
  customActions: undefined,
  modalTitle: undefined,
  modalClassName: "",
};

export default RemoveAccountModal;
