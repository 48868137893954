import React from "react";
export default function WebTools() {
  return (
    <svg
      width="110px"
      height="110px"
      viewBox="0 0 110 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ACE_Web_Tools</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="ACE_WebTools_A-Used"
          transform="translate(-75.000000, -62.000000)"
        >
          <g id="ACE_Web_Tools" transform="translate(75.000000, 62.000000)">
            <g filter="url(#filter-1)" id="circle">
              <g transform="translate(0.000000, -0.000611)">
                <path
                  d="M110,54.9992369 C110,85.3747971 85.3759816,110 55,110 C24.6255445,110 0,85.3747971 0,54.9992369 C0,24.6252029 24.6255445,0 55,0 C85.3759816,0 110,24.6252029 110,54.9992369"
                  id="Fill-1"
                  fill="#FDF8E7"
                ></path>
              </g>
            </g>
            <g transform="translate(21.412621, 27.882708)" strokeWidth="1.5">
              <polyline
                stroke="#002157"
                points="49.1262892 35.2305556 49.1262892 7.3975 57.2039952 7.3975 57.2039952 35.2305556"
              ></polyline>
              <polyline
                stroke="#DB1830"
                points="36.8448727 35.2305556 36.8448727 18.8115278 44.9241369 18.8115278 44.9241369 35.2305556"
              ></polyline>
              <polyline
                stroke="#002157"
                points="24.5634563 35.2305556 24.5634563 14.1731944 32.6427204 14.1731944 32.6427204 35.2305556"
              ></polyline>
              <polyline
                stroke="#002157"
                points="12.2834421 35.2305556 12.2834421 23.2665278 20.3611481 23.2665278 20.3611481 35.2305556"
              ></polyline>
              <polyline
                stroke="#002157"
                points="41.0203798 45.626625 41.0203798 49.8463472 49.2694878 49.8463472 49.2694878 54.3532917 20.2168589 54.3532917 20.2168589 49.8463472 28.4659668 49.8463472 28.4659668 45.626625"
              ></polyline>
              <path
                d="M0.000467459837,35.2305556 L0.000467459837,3.00819444 C0.000467459837,1.35361111 1.38103218,0 3.06856219,0 L5.22199384,0 L64.4070839,0 L66.4187194,0 C68.1062494,0 69.4868141,1.35361111 69.4868141,3.00819444 L69.4868141,35.2305556"
                stroke="#002157"
              ></path>
              <path
                d="M69.4863466,35.2305556 L69.4863466,39.58625 C69.4863466,41.2408333 68.1057819,42.5929167 66.4182519,42.5929167 L3.06809473,42.5929167 C1.38056472,42.5929167 0,41.2408333 0,39.58625 L0,35.2305556 L69.4863466,35.2305556 Z"
                stroke="#002157"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
