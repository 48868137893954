import _ from "underscore";
import Stats from "libs/pcap/utils/stats";
/**
 * the distance between two points. Returns 0 if there is bad data.
 *
 * @param p1
 * @param p2
 * @returns {number}
 * @private
 */
function _d(p1, p2) {
  var dx = p1.x - p2.x;
  var dy = p1.y - p2.y;
  if (isNaN(dx) || isNaN(dy)) return 0;
  return Math.sqrt(dx * dx + dy * dy);
}

export default function () {
  var _points = [];

  return function (point) {
    if (_points.length) {
      point.distance = _d(_points[0], point);
    }

    var distances = _.reject(_.pluck(_points, "distance"), isNaN);

    if (distances.length < 5) {
      _points.unshift(point);
      //	console.log('only ', distances.length, 'distances');
      return false;
    } else if (_points.length > 40) {
      _points = _points.slice(0, 30);
    }

    distances = _.sortBy(distances, _.identity);

    // taking the 70% smallest sample of distances
    distances = distances.slice(0, Math.floor(distances.length * 0.8));

    var stat = new Stats(distances);
    var mean = stat.getArithmeticMean();
    var stdev = stat.getStandardDeviation();
    var thresh = mean + 2 * stdev + 10;
    var strange = (point.strange = point.distance > thresh);

    _points.unshift(point);

    if (false && strange) {
      console.log("xs: ", _.pluck(_points, "x").join(","));
      console.log("ys: ", _.pluck(_points, "y").join(","));
      console.log("point: ", JSON.stringify(point));
      console.log(
        "points:",
        _.map(distances, Math.round).join(","),
        "distance:",
        Math.round(point.distance),
        "thresh: ",
        thresh,
        "strange: ",
        strange
      );
    }

    if (isNaN(thresh)) {
      return false;
    } else if (point.distance > thresh) {
      return true;
    }
    return false;
  };
}
