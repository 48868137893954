/* eslint-disable react/no-unused-prop-types */
import PropTypes from "prop-types";
import React from "react";
import CtaOverlay from "components/common/CtaOverlay";
import Loading from "components/common/loading/Loading";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import ProjectionSummaryChart from "components/projectionSummary/ProjectionSummaryChart";
import FancySelect from "components/common/form/FancySelect";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { isEmpty } from "underscore";
import moment from "moment";
import DateUtil from "libs/pcap/utils/date";

function ZeroStateChart() {
  return (
    <svg
      width="287px"
      height="111px"
      viewBox="0 0 287 111"
      className="pc-u-mb- pc-u-mt-"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-960.000000, -638.000000)">
          <g
            id="Projection-default"
            transform="translate(949.000000, 571.000000)"
          >
            <g
              id="img_projectionZeroState"
              transform="translate(11.000000, 67.000000)"
            >
              <g
                id="Group-7"
                transform="translate(0.000000, 109.000000)"
                stroke="#979797"
                strokeLinecap="square"
              >
                <path d="M0.5,1.5 L286.5,1.5" id="Line-2" />
              </g>
              <path
                d="M130.859475,74.9450549 C218.301155,41.3136397 287,0 287,0 L287,110 L1,110 C1,110 43.4177952,108.57647 130.859475,74.9450549 Z"
                id="Rectangle-12"
                fill="#E7E8E9"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

class ProjectionSummary extends React.Component {
  render() {
    const {
      isActivePlan,
      participantHasValidBalance,
      participantHasContribution,
      ace,
      errors,
      loading,
      loadingOverlaid,
      accountsSelectOptions,
      activeAccount,
      onAccountChange,
      header,
      form,
      chartYDomain,
      currentStrategyProjection,
      newStrategyProjection,
      title,
    } = this.props;
    if (loading) {
      return <Loading />;
    }
    if (errors) {
      return <Message severity="error" messages={errors} />;
    }

    let aceOverlay;
    if (ace) {
      aceOverlay = (
        <CtaOverlay
          message={ace}
          widgetName="Personal Strategy® Projection Summary"
        />
      );
    }

    return (
      <div
        className={`js-projection-summary${
          IS_EMPOWER ? " dashboard__item--tall-2x u-text-left" : ""
        }`}
      >
        <LoadingOverlay active={loadingOverlaid} />
        <div className="l-spaced l-spaced--flush projection-summary__header">
          {accountsSelectOptions && accountsSelectOptions.length > 1 && (
            <div className="projection-summary__plan-selector">
              <FancySelect
                name="planId"
                className="Select--small"
                value={activeAccount}
                options={accountsSelectOptions}
                onChange={onAccountChange}
              />
            </div>
          )}
          {title && (
            <div className="pc-delta qa-projection-summary-widget-title">
              {title}
            </div>
          )}
        </div>
        {header}
        {isActivePlan ||
        participantHasContribution ||
        participantHasValidBalance ? null : (
          <p className="pc-u-mt pc-u-mb qa-no-balances-to-project">
            No balances to project
            <br />
            as of {moment().format(DateUtil.CHART_MONTH_DATE_YEAR_FORMAT)}
          </p>
        )}
        {isEmpty(currentStrategyProjection) &&
        isEmpty(newStrategyProjection) ? (
          <ZeroStateChart />
        ) : (
          <ProjectionSummaryChart
            className="pc-u-mb--"
            endIsRetirement={this.props.endIsRetirement}
            currentValueAtRetirement={this.props.currentValueAtRetirement}
            currentStrategyProjection={currentStrategyProjection}
            newStrategyProjection={newStrategyProjection}
            additionalValueAtRetirement={this.props.additionalValueAtRetirement}
            classNameChart={this.props.classNameChart}
            yDomain={chartYDomain}
          />
        )}
        {form}
        {aceOverlay}
      </div>
    );
  }
}

ProjectionSummary.propTypes = {
  loading: PropTypes.bool,
  loadingOverlaid: PropTypes.bool,
  errors: PropTypes.array,
  endIsRetirement: PropTypes.bool,
  participantHasContribution: PropTypes.bool,
  participantHasValidBalance: PropTypes.bool,
  isActivePlan: PropTypes.bool,
  additionalValueAtRetirement: PropTypes.number,
  currentValueAtRetirement: PropTypes.number,
  numberOfContributions: PropTypes.number,
  currentStrategyProjection: PropTypes.array,
  newStrategyProjection: PropTypes.array,
  ace: PropTypes.object,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
  header: PropTypes.element,
  form: PropTypes.element,
  accountsSelectOptions: PropTypes.array,
  activeAccount: PropTypes.number,
  onAccountChange: PropTypes.func,
  classNameChart: PropTypes.string,
  chartYDomain: PropTypes.array,
  title: PropTypes.string,
};

ProjectionSummary.defaultProps = {
  currentStrategyProjection: [],
  participantHasContribution: true,
  participantHasValidBalance: true,
  isActivePlan: true,
  newStrategyProjection: [],
  loading: undefined,
  loadingOverlaid: undefined,
  errors: undefined,
  endIsRetirement: undefined,
  additionalValueAtRetirement: undefined,
  currentValueAtRetirement: undefined,
  numberOfContributions: undefined,
  ace: undefined,
  storeTooltipRef: undefined,
  header: undefined,
  form: undefined,
  accountsSelectOptions: undefined,
  activeAccount: undefined,
  onAccountChange: undefined,
  classNameChart: undefined,
  chartYDomain: undefined,
  title: "Projection",
};

export default withTooltips(ProjectionSummary);
