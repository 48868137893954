import moment from "moment";
import _ from "underscore";

export default {
  /**
   * Given an array of accounts, filter out accounts whose closedDate is before startDate
   * @param {Array} accounts objects
   * @param {Object} startDate (optional) moment.js date object, if not supplied, today's date will be used
   * @return {Array} Open accounts based on filter
   */
  filter: function (accounts, startDate) {
    if (!startDate) {
      startDate = moment(); // Default to today
    }
    if (!moment.isMoment(startDate)) {
      throw new Error(
        "Error: closedAccountsFilter.filter(): " +
          startDate +
          " is not a valid moment.js object"
      );
    }
    accounts = _.reject(accounts, function (account) {
      account = account || {};
      var closedDate = _.isFunction(account.get)
        ? account.get("closedDate")
        : account.closedDate;
      return closedDate && moment(closedDate).isBefore(startDate);
    });
    return accounts;
  },
  /**
   * Given an array of accounts, get accounts whose closedDate is not empty or null
   * @param {Array} accounts objects
   * @return {Array} closed accounts
   */
  getAllClosedAccounts: function (accounts) {
    accounts = _.reject(accounts, function (account) {
      account = account || {};
      var closedDate = _.isFunction(account.get)
        ? account.get("closedDate")
        : account.closedDate;
      if (!closedDate) {
        return true;
      }
    });
    return accounts;
  },
};
