import React from "react";
import PropTypes from "prop-types";
import { noop } from "underscore";
import UploadStatement from "components/transferFunds/UploadStatement";
import YodleeStatementFetchContainer from "../../YodleeStatementFetchContainer/YodleeStatementFetchContainer";

const statementFetchCopy =
  "To initiate the transfer of your account to Empower, we'll need a copy of a recent account statement. You must provide a statement in order to proceed.";

class UploadStatements extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <h1 className="nav-secondary__title">Upload a recent statement</h1>
        <div className="upload-statement__review-msg pc-u-mt-">
          Review the details below to process your contribution request.
        </div>
        <UploadStatement
          {...this.props}
          CustomUploader={YodleeStatementFetchContainer}
          statementFetchCopy={statementFetchCopy}
        ></UploadStatement>
      </div>
    );
  }
}

UploadStatements.propTypes = {
  statements: PropTypes.array,
  userAccountId: PropTypes.number.isRequired,
  accountName: PropTypes.string.isRequired,
  firmName: PropTypes.string.isRequired,
  logoPath: PropTypes.string,
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  onMounted: PropTypes.func,
  showAccountBox: PropTypes.bool,
  transferType: PropTypes.number.isRequired,
  isYodleeAllowed: PropTypes.bool,
};

UploadStatements.defaultProps = {
  statements: undefined,
  className: undefined,
  logoPath: undefined,
  balance: undefined,
  onBack: noop,
  onContinue: noop,
  onMounted: noop,
  showAccountBox: true,
  isYodleeAllowed: false,
};

export default UploadStatements;
