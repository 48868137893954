/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "../../../common/form/AbstractForm";
import ChartReact from "libs/pcap/chart/chart--react";
import { formatCurrency } from "libs/pcap/utils/format";
import { POSITIVE_INTEGER_FORMAT } from "components/common/form/formattingOptions";
import LoadingOverlay from "components/common/LoadingOverlay";
import {
  format,
  scaleBand,
  scaleLinear,
  axisBottom,
  axisRight,
  range,
  select,
} from "d3";
import Input from "components/common/form/Input";
import moment from "moment";
import { isEmpowerPrivilegedMode } from "../../../../views/modules/sidebar/utils/accountUtils";

const INPUT_LENGTH_HUNDRED_THOUSANDS = 7; // 6 digits plus a comma

const xAccessor = (d) => d.date;
const yAccessor = (d) => d.amount;

function yAxisFormatter(amount) {
  return format("$.0s")(amount);
}

const Y_AXIS_TICK_COUNT = 5;
const TICK_PADDING = 10;
const X_SCALE_PADDING = 0.3;
const AVERAGE_LABEL_VERTICAL_OFFSET = 5;
const AVERAGE_LABEL_HORIZONTAL_OFFSET = 45;
const ANNUAL_FACTOR = 12;

const isPrivileged = isEmpowerPrivilegedMode();

class BudgetingView extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        target: this.props.monthlyBudget,
      },
    };

    this.xScale = scaleBand();
    this.yScale = scaleLinear();
    this.xAxis = axisBottom(this.xScale)
      .tickFormat((d) => moment(d).format("MMM").toUpperCase())
      .tickSize(0)
      .tickPadding(TICK_PADDING);
    this.validateSubmit = this.validateSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    if (this.props.history.length) {
      this.setupChart();
      this.drawAverageLine();
    }
  }

  componentDidUpdate() {
    //only need to setup and draw line once, when APIs have returned data
    if (!this.props.loadingChartData) {
      this.setupChart();
      this.drawAverageLine();
    }
  }

  setupChart() {
    const { history } = this.props;
    let highestMonthTotal = 0;

    if (history[0] && history[0].length) {
      highestMonthTotal = Math.max.apply(
        Math,
        history[0].map(function (nextMonth) {
          return nextMonth.amount;
        })
      );
    }

    const step = highestMonthTotal / (Y_AXIS_TICK_COUNT - 1);
    this.yAxis = axisRight(this.yScale)
      .tickFormat(yAxisFormatter)
      .ticks(Y_AXIS_TICK_COUNT)
      .tickSize(0)
      .tickValues(range(0, step + highestMonthTotal, step));
  }

  drawAverageLine() {
    const { average } = this.props;

    if (average <= 0) return;

    const chartSelector = select(".js-emergency-fund-budget-chart");

    const lineNode = chartSelector.select(
      ".js-emergency-fund-budget-chart-average-line"
    );

    const averageLineWidth = this.xScale.range()[1];

    if (lineNode.node()) {
      chartSelector
        .attr("x1", 0)
        .attr("x2", averageLineWidth)
        .attr("y1", this.yScale(average))
        .attr("y2", this.yScale(average));

      chartSelector
        .attr("x", AVERAGE_LABEL_HORIZONTAL_OFFSET)
        .attr("y", this.yScale(average) - AVERAGE_LABEL_VERTICAL_OFFSET);
    } else {
      chartSelector
        .append("line")
        .classed(
          "js-emergency-fund-budget-chart-average-line emergency-fund-budget-chart-line ",
          true
        )
        .attr("x1", 0)
        .attr("x2", averageLineWidth)
        .attr("y1", this.yScale(average))
        .attr("y2", this.yScale(average));

      chartSelector
        .append("text")
        .attr("x", AVERAGE_LABEL_HORIZONTAL_OFFSET)
        .attr("y", this.yScale(average) - AVERAGE_LABEL_VERTICAL_OFFSET)
        .attr("text-anchor", "middle")
        .attr("class", "emergency-fund-budget-chart-line__label")
        .text(`AVG: ${formatCurrency(average, 0)}`);
    }
  }

  displaySavings() {
    const { model } = this.state;
    const { average } = this.props;

    if (isNaN(model.target) || model.target === "" || model.target < 0) {
      return "";
    }

    const budgetTarget = (average - model.target) * ANNUAL_FACTOR;

    if (budgetTarget < 0) {
      return "By using this budget, you might not save any money compared to your current spending.";
    }

    return (
      <>
        By using this budget, you could save
        <span className="emergency-fund-budget-view__target u-text-bold">
          {" "}
          {formatCurrency(budgetTarget, 0)}{" "}
        </span>
        per year compared to your typical spending.
      </>
    );
  }

  validateSubmit(ev) {
    const { onDone } = this.props;
    const validationResult = this.validate();

    ev.preventDefault();

    if (this.props.isPrivileged) {
      return;
    }

    if (validationResult.valid) {
      // Dispatch event bus
      if (IS_EMPOWER) {
        const AMPLITUDE_EVENTS = window.integratedSharedData?.AMPLITUDE_EVENTS;
        const eventBus = window.dashboardUtils?.eventBus;
        const selection = "budget_page.save_button.click";
        eventBus?.dispatch(selection);
        eventBus?.dispatchAmplitude({
          event_type: AMPLITUDE_EVENTS?.SELECT_BUTTON ?? "select_button",
          event_properties: {
            selection,
          },
        });
      }
      onDone(this.state.model);
    }
  }

  handleCancel() {
    // Dispatch event bus
    if (IS_EMPOWER)
      window.dashboardUtils?.eventBus.dispatch(
        "withdrawal_planner.set_monthly_budget_cancel_button.click"
      );
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type: window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
      event_properties: {
        selection: "withdrawal_planner.set_monthly_budget_cancel_button.click",
      },
    });
    this.props.onCancel();
  }

  render() {
    const {
      cancelButtonText,
      doneButtonText,
      history,
      average,
      monthlyBudget,
      showZeroState,
      loadingChartData,
      showCancelButton,
      isPrivileged,
    } = this.props;
    return (
      <form
        className="js-done qa-monthly-budget-form"
        onSubmit={this.validateSubmit}
        autoComplete="off"
      >
        {!showZeroState && (
          <>
            <div className="pc-layout pc-u-mb--">
              <div className="pc-layout__item">
                Update your monthly budget if it is not up to date. Your prior
                year&apos;s spending indicates your monthly spending is{" "}
                {formatCurrency(average, 0)}.
              </div>
            </div>

            <div className="pc-u-mb emergency-fund-budget-chart qa-emergency-fund-budget-chart">
              <LoadingOverlay active={loadingChartData} />
              <ChartReact
                data={history}
                className="js-emergency-fund-budget-chart"
                type="bar"
                x={xAccessor}
                xAxis={this.xAxis}
                xScale={this.xScale}
                xScalePadding={X_SCALE_PADDING}
                y={yAccessor}
                yScale={this.yScale}
                yAxis={this.yAxis}
                showXGrid={false}
                showYZeroLine={true}
                tabIndex={0}
                barClassName={() => "emergency-fund-budget-chart__bar"}
              />
            </div>
          </>
        )}

        <div className="pc-form-group">
          <div className="pc-layout pc-layout--small">
            <div className="pc-layout__item pc-u-3/9">
              <label
                htmlFor="monthly-budget-label"
                className="pc-label pc-u-mt-- pc-interview-label"
              >
                Monthly Budget
              </label>
            </div>
            <div className="pc-layout__item pc-u-6/9">
              <Input
                type="text"
                name="target"
                className="js-monthly-budget-input qa-monthly-budget-input"
                ref={this.storeInputRef}
                sizeVariation="full"
                value={showZeroState ? 0 : monthlyBudget}
                maxLength={INPUT_LENGTH_HUNDRED_THOUSANDS}
                formattingOptions={POSITIVE_INTEGER_FORMAT}
                onChange={this.handleInputChange}
                validator={this.props.validator.target}
                autoFocus={true}
                prefix="$"
                id="monthly-budget"
                aria-describedby="monthly-budget-label"
              />

              {!showZeroState && (
                <label className="pc-label pc-help-block pc-help-block--small js-potential-savings-label">
                  {this.displaySavings()}
                </label>
              )}
            </div>
          </div>
        </div>

        <div className="pc-layout u-text-right pc-u-mt">
          {showCancelButton && (
            <button
              type="button"
              className="pc-btn pc-btn--cancel js-cancel qa-cancel-btn"
              onClick={this.handleCancel}
            >
              {cancelButtonText}
            </button>
          )}
          <button
            type="submit"
            className={`pc-btn pc-btn--primary qa-next-btn ${
              isPrivileged ? "is-disabled" : ""
            }`}
            aria-disabled={isPrivileged}
          >
            {doneButtonText}
          </button>
        </div>
      </form>
    );
  }
}

BudgetingView.propTypes = {
  loadingChartData: PropTypes.bool,
  history: PropTypes.array,
  monthlyBudget: PropTypes.number,
  average: PropTypes.number,
  showZeroState: PropTypes.bool.isRequired,
  cancelButtonText: PropTypes.string,
  doneButtonText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  validator: PropTypes.object,
  showCancelButton: PropTypes.bool,
  isPrivileged: PropTypes.bool,
};

BudgetingView.defaultProps = {
  loadingChartData: true,
  history: [],
  monthlyBudget: 0,
  average: 0,
  cancelButtonText: "Cancel",
  doneButtonText: "Submit",
  validator: {
    target: {
      type: "number",
      required: true,
      allowEmpty: false,
      messages: {
        required: "This field is required.",
        allowEmpty: "This field is required.",
      },
    },
  },
  showCancelButton: true,
  isPrivileged: isPrivileged,
};

export default BudgetingView;
