import PropTypes from "prop-types";
import React from "react";
import AddPersonView from "./AddPersonView";
import { createPerson } from "accessors/person/accessor";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const isPrivileged = isEmpowerPrivilegedMode();
export default class AddPersonContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddPerson = this.handleAddPerson.bind(this);
  }

  handleAddPerson(person) {
    if(isPrivileged){return}
    createPerson(person, {
      source: "enrollment",
    }).then((newPerson) => {
      this.props.onSubmit(newPerson);
    });
  }

  render() {
    return (
      <AddPersonView
        people={this.props.people}
        onCancel={this.props.onCancel}
        onSubmit={this.handleAddPerson}
        isPrivileged={isPrivileged}
      />
    );
  }
}

AddPersonContainer.propTypes = {
  people: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
