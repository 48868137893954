import React from "react";
import PropTypes from "prop-types";
import noop from "utils/noop";
import Modal from "components/modal/Modal";

const TradingBlackoutConfirmModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      componentName="TradingBlackoutConfirmModal"
      isOpen={isOpen}
      onClosed={onClose}
      className="pc-modal pc-modal--smedium"
      shouldCloseOnOverlayClick={false}
      showCloseButton={true}
    >
      <div>
        <div className="pc-u-mt">
          By clicking OK below, you acknowledge that you are being directed to
          an external site. Empower is not responsible for the third-party
          content provided.
        </div>
        <div className="pc-u-mt l-spaced l-spaced--flush">
          <button
            className="pc-btn pc-btn--primary js-cancel-btn change-fund__yes-button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="pc-btn pc-btn--primary js-ok-btn change-fund__yes-button"
            onClick={() => {
              window.open(
                "https://www.nyse.com/markets/hours-calendars",
                "_blank"
              );
              onClose();
            }}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

TradingBlackoutConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

TradingBlackoutConfirmModal.defaultProps = {
  onClose: noop,
};

export default TradingBlackoutConfirmModal;
