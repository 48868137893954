/* eslint-disable camelcase */
import React from "react";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import analytics from "analytics";
import {
  isAppointmentUrl,
  addReasonToAppointmentUrl,
} from "libs/pcap/utils/appointmentUtils";
import removeNonAscii from "libs/pcap/utils/removeNonAscii";
import { markUserMessageAsClickedBaseOnTrackingFlag } from "libs/pcap/utils/markUserMessageAsClicked";
import { getUserMessageActionTrackingFlag } from "views/components/postLoginActions/helpers";
import markUserMessageAsViewed from "libs/pcap/utils/markUserMessageAsViewed";

const REGEX_STARTS_WITH_HASH = /^#\//;
const EMPOWER_INSIGHTS_COMPONENT = "Insights Widget";

export const getEventData = ({ message, buttonLabel }) => {
  const buttonLabelObject = buttonLabel ? { button_label: buttonLabel } : {};
  const messageData = {
    message_template: message.template,
    user_message_id: message.userMessageId,
    user_message_title: message.title,
    component: message.component,
    token: message.token,
  };
  return Object.assign(messageData, buttonLabelObject);
};

export const getActionButtons = ({
  message = {},
  markAsViewed,
  componentName = "insights",
  appointmentReason,
  getButtonClass,
  buttonClass = "pc-btn pc-btn--full pc-btn--primary",
} = {}) => {
  const messageActions = message.action || [];
  const key = btoa(removeNonAscii(message.template + message.title)); // Generate unique key for current message

  return messageActions.map((action, i) => {
    const onActionClick = (isExternalLink, actionUrl) => (event) => {
      const trackEventPromise = new Promise((resolve) => {
        if (IS_EMPOWER) {
          analytics.sendEmpowerTrackEvent(
            EMPOWER_INSIGHTS_COMPONENT,
            `${action.label} - ${action.key}`
          );
        } else {
          ComponentAnalytics.trackEvent(
            componentName,
            "Click Insight",
            getEventData({ message, buttonLabel: action.label }),
            {
              callback: resolve,
            }
          );
        }
      });
      const markAsViewedPromise = new Promise((resolve) => {
        if (markAsViewed) {
          markUserMessageAsViewed(message.userMessageId, resolve);
        } else {
          resolve();
        }
      });
      const markAsClickedPromise = new Promise((resolve) => {
        markUserMessageAsClickedBaseOnTrackingFlag(message, event, resolve);
      });
      if (isExternalLink) {
        Promise.all([
          trackEventPromise,
          markAsViewedPromise,
          markAsClickedPromise,
        ]).then(() => {
          window.location.href = actionUrl;
        });
      } else if (IS_EMPOWER) {
        analytics.sendEmpowerTrackEvent(
          EMPOWER_INSIGHTS_COMPONENT,
          `${action.label} - ${action.key}`
        );
      }
    };

    let actionUrl = action.url;
    const isActionUrlForAppointmentScheduler = isAppointmentUrl(actionUrl);
    let isExternalLink = !REGEX_STARTS_WITH_HASH.test(actionUrl);
    if (isActionUrlForAppointmentScheduler && appointmentReason) {
      actionUrl = addReasonToAppointmentUrl(actionUrl, appointmentReason);
    }
    const buttonClassAppend =
      typeof getButtonClass === "function" ? getButtonClass(action) : "";

    let ButtonType = isExternalLink ? "button" : "a";
    const buttonProps = {};
    if (!isExternalLink) {
      buttonProps.href = actionUrl;
    }
    if (action.type === "TAB") {
      ButtonType = "a";
      buttonProps.href = actionUrl;
      buttonProps.target = "_blank";
      isExternalLink = false;
    }
    if (action.type === "ANCHOR") {
      ButtonType = "a";
      buttonProps.href = actionUrl;
      isExternalLink = false;
    }

    return (
      <ButtonType
        {...buttonProps}
        key={key + "_" + i}
        onClick={onActionClick(isExternalLink, actionUrl)}
        className={`${buttonClass} ${buttonClassAppend}`}
        data-tracking-flag={getUserMessageActionTrackingFlag(action)}
        data-attribution-source={action.attributionSource}
      >
        {action.label || action.title}
      </ButtonType>
    );
  });
};

export const getScheduleACallLink = ({
  message = {},
  markAsViewed,
  label = "Schedule a Call",
  reason = "",
  componentName = "insights",
} = {}) => {
  const onClick = () => {
    ComponentAnalytics.trackEvent(
      componentName,
      "Click Insight",
      getEventData({ message, buttonLabel: label })
    );
    if (markAsViewed) {
      markAsViewed(message.userMessageId);
    }
  };
  const advisorAppointmentUrl = addReasonToAppointmentUrl(
    window.advisorAppointmentURL,
    reason
  );
  const isExternalLink = !isAppointmentUrl(advisorAppointmentUrl); // Only open TimeTrade links in new tabs
  return (
    <a
      onClick={onClick}
      href={advisorAppointmentUrl}
      className="insight__action"
      target={isExternalLink ? "_blank" : "_self"}
      rel="noreferrer"
    >
      {label}
    </a>
  );
};
