import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import Loading from "components/common/loading/Loading";
import InvestmentCheckup from "components/opportunitiesSummary/InvestmentCheckup/InvestmentCheckup";
import { getJsonContent } from "views/components/postLoginActions/helpers";
import objectPath from "object-path";

const VALUE_OFFSET = 5;

const assetClassMap = {
  cash: { name: "Cash" },
  intlBonds: { name: "International Bonds", label: "Int'l Bonds" },
  usBonds: { name: "US Bonds" },
  intlStocks: { name: "International Stocks", label: "Int'l Stocks" },
  usStocks: { name: "US Stocks" },
  alternatives: { name: "Alternatives" },
};

function getChartDataForAssetClass(allocationSet) {
  const difference = allocationSet.difference;
  const absoluteDifference = Math.abs(difference);
  const increaseToTargetAllocation = difference > 0;
  const userAllocation = {
    y: 2,
    value: (increaseToTargetAllocation ? absoluteDifference : 0) + VALUE_OFFSET,
  };
  const targetAllocation = {
    y: 1,
    value: (increaseToTargetAllocation ? 0 : absoluteDifference) + VALUE_OFFSET,
  };
  return [targetAllocation, userAllocation];
}

function generateAssetClasses(allocationSets, rebalanceActions) {
  const assetClasses = [];
  _.each(assetClassMap, (data, key) => {
    const allocationSet = allocationSets[key];
    const rebalanceAction = _.findWhere(rebalanceActions, {
      assetClass: data.name,
    });
    if (rebalanceAction) {
      assetClasses.push({
        key: _.uniqueId(),
        label: data.label || data.name,
        action: rebalanceAction.action,
        amount: rebalanceAction.value,
        chartData: getChartDataForAssetClass(allocationSet),
      });
    }
  });
  return assetClasses;
}

function getXDomain(allocationSets) {
  const maxDifference = _.chain(allocationSets)
    .pluck("difference")
    .map((diff) => Math.abs(diff))
    .max()
    .value();
  return [0, maxDifference + VALUE_OFFSET];
}

export default class InvestmentCheckupContainer extends React.Component {
  constructor(props) {
    super(props);

    const jsonContent = getJsonContent(props.message) || {};

    const data = jsonContent.data;
    const allocationSets =
      objectPath.get(data, "assetClassAllocation.allocationSet") || {};
    const rebalanceActions = objectPath.get(data, "rebalanceActions") || [];
    const assetClassSummary =
      objectPath.get(data, "assetClassAllocation.summary") || {};

    this.state = {
      loading: false,
      xDomain: getXDomain(allocationSets),
      assetClasses: generateAssetClasses(allocationSets, rebalanceActions),
      medianScenarioDelta: objectPath.get(
        data,
        "monteCarlo.medianScenario.delta"
      ),
      risks: {
        user: assetClassSummary.userStandardDeviation,
        target: assetClassSummary.targetStandardDeviation,
      },
      expectedReturns: {
        user: assetClassSummary.userExpectedReturn,
        target: assetClassSummary.targetExpectedReturn,
      },
      isClient: jsonContent.isClient,
    };
  }

  render() {
    const {
      loading,
      xDomain,
      assetClasses,
      medianScenarioDelta,
      risks,
      expectedReturns,
      isClient,
    } = this.state;
    if (loading) {
      return <Loading />;
    }

    return (
      <InvestmentCheckup
        {...this.props}
        xDomain={xDomain}
        assetClasses={assetClasses}
        isClient={isClient}
        medianScenarioDelta={medianScenarioDelta}
        risks={risks}
        expectedReturns={expectedReturns}
      />
    );
  }
}

InvestmentCheckupContainer.propTypes = {
  message: PropTypes.object,
  markAsViewed: PropTypes.func,
};

InvestmentCheckupContainer.defaultProps = {
  message: undefined,
  markAsViewed: undefined,
};
