import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";

const RequiredMinimumDistributionInformationModal = (props) => {
  const { onClosed } = props;

  const title = `Required minimum distribution (RMD)`;
  const copy = `According to our records, you are required under federal tax law to take an annual minimum distribution from your retirement account. Required minimum distributions (RMDs) usually begin in the year you reach the age of 73 and are calculated by dividing your year-end fair market value by a factor determined by the Internal Revenue Service (IRS). For the year you turn 73, the first RMD payment can be delayed until April 1st of the year following the year in which you turn 73. For all subsequent years, including the year in which the first RMD was paid by April 1st, you must take the RMD by December 31st of the year. Please verify this calculation with your tax professional, as there may be other information that could impact your RMD. If you do not take enough to satisfy the requirement, the IRS may impose a 25% excise tax on the shortfall.`;
  const footerCopy = `The estimate is provided to you for informational purposes only as a courtesy and based upon the information provided by you, which is assumed to be complete and accurate. Empower did not independently verify any of the information used for the creation of this estimate. We are not licensed legal or tax professionals. Prior to taking action, you should consult a qualified licensed legal or tax professional regarding your specific situation.`;

  return (
    <Modal
      componentName="RequiredMinimumDistributionInformationModal"
      contentLabel="Required Minimum Distribution Information Modal"
      title={title}
      isOpen={true}
      showConfirmationButton={false}
      isTitleSentenceCase={false}
      onClosed={onClosed}
    >
      <p className="required-minimum-distribution-information-modal__copy">
        {copy}
      </p>
      <p className="required-minimum-distribution-information-modal__footer-copy">
        {footerCopy}
      </p>

      <div className="pc-u-mt u-text-right">
        <button className={"pc-btn pc-btn--primary"} onClick={onClosed}>
          Close{" "}
        </button>
      </div>
    </Modal>
  );
};

RequiredMinimumDistributionInformationModal.propTypes = {
  onClosed: PropTypes.func,
};

RequiredMinimumDistributionInformationModal.defaultProps = {
  onClosed: () => null,
};

export default RequiredMinimumDistributionInformationModal;
