import PropTypes from "prop-types";
import React from "react";
import AccountInformationContainer from "components/hysa/AccountInformation/Container";
import Modal from "components/modal/Modal";

const AccountInformationModal = ({ onDone, isOpen, userAccountId }) => {
  return (
    <Modal
      componentName="AccountInformationModal"
      title="Account Information"
      isOpen={isOpen}
      onClosed={onDone}
      className="pc-modal pc-modal--small pc-modal--account-information"
      shouldCloseOnOverlayClick={false}
    >
      <AccountInformationContainer
        onDone={onDone}
        userAccountId={userAccountId}
      />
    </Modal>
  );
};

AccountInformationModal.propTypes = {
  onDone: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userAccountId: PropTypes.number.isRequired,
};

export default AccountInformationModal;
