import PropTypes from "prop-types";
import React from "react";
import mixpanel from "mixpanel";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import InvestableCashAreaChart from "views/components/postLoginActions/charts/InvestableCashAreaChart";
import { getEventData } from "components/opportunitiesSummary/InsightsUtils";

class InvestibleCash extends React.Component {
  componentDidMount() {
    mixpanel.trackEvent(
      `View Insight`,
      getEventData({ message: this.props.message })
    );

    if (this.props.isFirstRendered) {
      this.props.markAsImpressed(this.props.message.userMessageId);
    } else {
      this.props.markAsViewed(this.props.message.userMessageId);
    }
  }

  render() {
    const {
      message = {},
      markAsViewed,
      chartOnly,
      disableAnimations,
      storeTooltipRef,
    } = this.props;

    const chartReact = (
      <InvestableCashAreaChart
        message={message}
        disableAnimations={disableAnimations}
        storeTooltipRef={storeTooltipRef}
      />
    );

    if (chartOnly) {
      return chartReact;
    }

    return (
      <div className="insight__message">
        <div
          className="insight__header qa-insight-header u-sentence-case"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div className="insight__summary pc-u-pb0">
          {chartReact}
          <div
            className="insight__summary insight__summary--cost-of-cash"
            dangerouslySetInnerHTML={{ __html: message.summary }}
          />
        </div>
        <div className="insight__buttons">
          {getActionButtons({
            message,
            markAsViewed,
            appointmentReason: "Idle Cash",
          })}
        </div>
      </div>
    );
  }
}

InvestibleCash.propTypes = {
  message: PropTypes.object,
  markAsViewed: PropTypes.func,
  markAsImpressed: PropTypes.func,
  chartOnly: PropTypes.bool,
  isFirstRendered: PropTypes.bool,
  disableAnimations: PropTypes.bool,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
};

export default withTooltips(InvestibleCash);
