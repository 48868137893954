import React from "react";
import PropTypes from "prop-types";
import Message from "components/common/Message";
import Input from "components/common/form/Input";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";
import { ROUTING_NUMBER_FORMAT } from "components/common/form/formattingOptions";
import { ACCOUNT_NUMBER } from "utils/patterns";
import AccountBox from "components/AccountBox/AccountBox";

const TOOLTIP_ROUTING_NUMBER =
  "Your bank routing number is a nine-digit code that's based on the U.S. Bank location where your account was opened. It's the first set of numbers printed on the bottom of your checks, on the left side.";
const ROUTING_NUMBER_LENGTH = 9;
const MAXLENGTH_ACCOUNT_NUMBER = 17; // from NACHA standard file format

export default function AccountNumberForm({
  model = {},
  accountName,
  firmName,
  balance,
  submitButtonText,
  helpText,
  logoPath,
  onSubmit,
  onCancel,
  onChange,
  validator,
  errors,
  warnings,
  refFunc,
  className,
}) {
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <section className="account-number-form">
        <div className={className}>
          <Message className="pc-u-mb" severity="error" messages={errors} />
          <Message className="pc-u-mb" severity="warning" messages={warnings} />
          <AccountBox
            showLogo={true}
            logoPath={logoPath}
            firmName={firmName}
            name={accountName}
            balance={balance}
            className="pc-u-2/3 pc-u-mb+"
          />
          {helpText && <p>{helpText}</p>}
          <div className="pc-layout pc-layout--small pc-form-group pc-u-mb0">
            <div
              className={`pc-layout__item ${
                IS_EMPOWER ? `pc-u-1/6` : `pc-u-1/4`
              } pc-u-mb`}
            >
              <label className="pc-label" htmlFor="routingNumber">
                Routing Number
                <InfoTooltipIcon
                  title={TOOLTIP_ROUTING_NUMBER}
                  className="pc-u-ml--"
                />
              </label>
            </div>
            <div
              className={`pc-layout__item ${
                IS_EMPOWER ? `pc-u-3/8` : `pc-u-5/8`
              }`}
            >
              <Input
                ref={refFunc}
                type="text"
                id="routingNumber"
                sizeVariation="full"
                name="routingNumber"
                value={model.routingNumber}
                onChange={onChange}
                validator={validator.routingNumber}
                formattingOptions={ROUTING_NUMBER_FORMAT}
                data-hj-masked
              />
            </div>
          </div>
          <div className="pc-layout pc-layout--small pc-form-group pc-u-mb+">
            <div
              className={`pc-layout__item ${
                IS_EMPOWER ? `pc-u-1/6` : `pc-u-1/4`
              }`}
            >
              <label className="pc-label" htmlFor="accountNumber">
                Account Number
              </label>
            </div>
            <div
              className={`pc-layout__item ${
                IS_EMPOWER ? `pc-u-3/8` : `pc-u-5/8`
              }`}
            >
              <Input
                ref={refFunc}
                type="text"
                id="accountNumber"
                sizeVariation="full"
                name="accountNumber"
                value={model.accountNumber}
                onChange={onChange}
                validator={validator.accountNumber}
                maxLength={MAXLENGTH_ACCOUNT_NUMBER}
                data-hj-masked
              />
            </div>
          </div>
        </div>
        <div className="u-text-right">
          <button type="button" className="pc-btn" onClick={onCancel}>
            Back
          </button>
          <button type="submit" className="pc-btn pc-btn--primary">
            {submitButtonText}
          </button>
        </div>
      </section>
    </form>
  );
}

AccountNumberForm.defaultProps = {
  model: undefined,
  logoPath: undefined,
  errors: undefined,
  warnings: undefined,
  accountName: undefined,
  firmName: undefined,
  balance: undefined,
  helpText: undefined,
  submitButtonText: "Continue",
  validator: {
    routingNumber: {
      type: "string",
      allowEmpty: false,
      minLength: ROUTING_NUMBER_LENGTH,
      messages: {
        allowEmpty: "This field is required.",
        minLength: "Please enter the 9 digit routing number",
      },
    },
    accountNumber: {
      type: "string",
      allowEmpty: false,
      minLength: 5,
      maxLength: MAXLENGTH_ACCOUNT_NUMBER,
      pattern: ACCOUNT_NUMBER,
      messages: {
        allowEmpty: "This field is required.",
        pattern: "Account number may only contain letters, numbers, and -.",
      },
    },
  },
  className: undefined,
};

AccountNumberForm.propTypes = {
  model: PropTypes.object,
  submitButtonText: PropTypes.string,
  logoPath: PropTypes.string,
  accountName: PropTypes.string,
  firmName: PropTypes.string,
  balance: PropTypes.number,
  helpText: PropTypes.string,
  errors: PropTypes.array,
  warnings: PropTypes.array,
  validator: PropTypes.object,
  refFunc: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
