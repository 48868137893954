import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import moment from "moment";
import grantBarGraph from "views/modules/stockOptions/components/barGraphVis";
import Raphael from "raphael";
import Formats from "formats";
import { API_FORMAT, DISPLAY_FORMAT } from "libs/pcap/utils/date2";
import overlayView from "views/components/overlayView";
import { noop } from "underscore";

const MINIMUM_YEARS = 4;
const MINIMUM_WEIGTH = 40;

function monthToDate(item) {
  return moment(item, Formats.DATE_FORMAT).unix();
}

function isPos(g) {
  return g.grant.totalValue > 0;
}

function grantGraphSort(grants) {
  grants = _.sortBy(grants, function (g) {
    return g.grant.order;
  }).reverse();
  var posGrants = _.filter(grants, isPos);
  var negGrants = _.reject(grants, isPos);
  return posGrants.concat(negGrants.reverse());
}

var BarGraph = Backbone.View.extend({
  initialize: function () {
    this.paper = Raphael(this.el, this.$el.width(), this.$el.height(), 0, 0);
    _.extend(this, Backbone.Events);
  },

  percentValues: function (percent, useWhatIf) {
    var gsi = this.getScaleItem(percent);
    var out = {
      grants: gsi ? grantGraphSort(gsi.data.values.slice(0)) : [],
    };

    if (
      useWhatIf &&
      gsi && // eslint-disable-next-line no-prototype-builtins
      /* Prev impl */ gsi.data.hasOwnProperty("whatIfValue")
    ) {
      out.grants.unshift({
        color: "#F25100",
        valueString: Formats.filters.dollar_no_cents(gsi.data.whatIfValue),
        grant: { grantName: "What-If Value" },
      });
    }

    return out;
  },

  percentDate: function (percent) {
    var scaleItem = this.getScaleItem(percent);

    if (scaleItem && scaleItem.noData) {
      return "...";
    }
    return scaleItem
      ? moment(scaleItem.data.month, API_FORMAT).format(DISPLAY_FORMAT)
      : "--";
  },

  makeDataScale: function () {
    if (!this.data) {
      console.log("NO DATA FOR DATA SCALE");
      this.dataScale = { noData: true };
      return;
    } /* eslint-disable no-underscore-dangle, camelcase */
    /* Previous implementation */ if (
      !this.dataScale ||
      !this.data.__version ||
      this.data.__version !== this._data_version
    ) {
      var months = _.map(_.pluck(this.data, "month"), monthToDate);

      var minMonth = _.min(months);
      var maxMonth = _.max(months);
      var range = maxMonth - minMonth;

      this.dataScale = _.map(this.fullHistory, function (item) {
        return {
          percent: (monthToDate(item.month) - minMonth) / range,
          data: item,
        };
      });

      // we are tagging the data block with a version to enable the above test
      // that only overwrites the dataScale when the version has changed.

      if (!this._data_version) {
        this._data_version = 1;
      }
      ++this._data_version;

      this.data.__version = this._data_version;
    }
    /* eslint-enable no-underscore-dangle */
  },

  grantTotal: function (percent) {
    if (!this.data) {
      return "--";
    }

    this.makeDataScale();

    if (this.dataScale.noData) {
      return "---";
    }

    var scaleItem = this.getScaleItem(percent);

    return scaleItem
      ? Formats.filters.dollar_no_cents(this.scaleItemGrandTotal(scaleItem))
      : "--";
  },

  scaleItemGrandTotal: function (si) {
    return _.reduce(
      _.pluck(si.data.values, "value"),
      function (o, n) {
        return o + n;
      },
      0
    );
  },

  getScaleItem: function (percent) {
    this.makeDataScale();

    return _.reduce(
      this.dataScale,
      function (out, scaleItem) {
        if (!out) return scaleItem;
        var percentDistance = Math.abs(scaleItem.percent - percent);
        var percentOutDistance = Math.abs(out.percent - percent);
        return percentDistance < percentOutDistance ? scaleItem : out;
      },
      null
    );
  },

  onClose: noop,

  updateFilter: function (params) {
    _.extend(this.transParams, params);
    this.fetchTransactions();
  },

  render: function (data, shortData) {
    this.data = data;
    this.fullHistory = data;

    $(this.$el.find(".tips")).remove();

    var self = this;

    var config = {
      width: this.$el.width(),
      height: this.$el.height(),
      showYLabelsOutside: true,
    };

    if (self.barGraphVis) {
      var newBarGraphVis = grantBarGraph(data, config);

      var years = 1,
        grants = 0,
        weight = 0;
      if (data.length) {
        var year1 = moment(_.first(data).month, "YYYY-MM-DD").year();
        var year2 = moment(_.last(data).month, "YYYY-MM-DD").year();
        years = year2 - year1 + 1;

        grants = _.reduce(
          data,
          function (out, item) {
            return Math.max(out, item.values.length);
          },
          0
        );
      }

      weight = years * grants;

      if (this.lastWeight) {
        weight = Math.max(weight, this.lastWeight);
      }
      this.lastWeight = weight;

      if (years > MINIMUM_YEARS || weight > MINIMUM_WEIGTH) {
        data = shortData;
      }

      self.$el.empty();
      self.paper = Raphael(this.el, this.$el.width(), this.$el.height(), 0, 0);
      self.barGraphVis = newBarGraphVis;
      newBarGraphVis.draw(self.paper);
    } else {
      self.barGraphVis = grantBarGraph(data, config);
      self.barGraphVis.draw(self.paper);
    }

    const overlayEle = document.createElement("div");
    overlayEle.className =
      "grant-graph-overlay-placeholder grant-graph-overlay-placeholder--overrides";
    if (!self.$el.has(".grant-graph-overlay-placeholder").length) {
      self.$el.append(overlayEle);
    }

    this.overlayView = new overlayView({
      el: $(".grant-graph-overlay-placeholder"),
      xBorder: 0,
      headerTemplateData: function (percent) {
        return {
          total: self.grantTotal(percent),
          percent: percent,
        };
      },
      footerTemplateData: function (percent) {
        return {
          date: self.percentDate(percent),
        };
      },
      headerTemplate: _.template("<%= total %> Total Grants"),
      footerTemplate: _.template("<%= date %>"),
      contentTemplate: _.template(
        "<% _.each(grants, function(grant){ %>" +
          '<div class="grant-item tipContentItem">' +
          '<span class="swatch" style="background-color: <%= grant.color %>">&nbsp;</span>' +
          "<span><%= grant.valueString %> <% if (grant.grant && grant.grant.grantName){ %><%= grant.grant.grantName %><% } %></span>" +
          "</div>" +
          "<% }) %>"
      ),

      contentTemplateData: function (percent) {
        return self.percentValues(percent, true);
      },
    });
  },
});

export default BarGraph;
