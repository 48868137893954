import PropTypes from "prop-types";
import React from "react";
import { promisify } from "utils/service";
import Services from "services";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import AccountInformation from "components/hysa/AccountInformation";
import { cancel } from "accessors/pcb/enrollment/accessor";
import { unfinishedEnrollment } from "components/hysa/utils/enrollment";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import { fullName } from "libs/pcap/utils/person";
import { trackClick } from "components/common/ComponentAnalytics";

export default class AccountInformationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initializing: true,
    };
    this.handleWithdrawnInvitation = this.handleWithdrawnInvitation.bind(this);
  }

  componentDidMount() {
    const { serviceFetchPcbAccountInfo, userAccountId } = this.props;
    this.cancelableService = makeCancelablePromise(
      serviceFetchPcbAccountInfo({ userAccountId })
    );
    this.cancelableService.promise.then(
      ({ accountNumber, routingNumber, owners }) => {
        let primaryOwner;
        let secondaryOwner;
        owners.forEach((o) => {
          if (o.personRole === "PRIMARY") {
            primaryOwner = o;
          } else if (o.personRole === "SECONDARY") {
            secondaryOwner = o;
          }
        });

        let expirationDate;
        let serviceWithdrawInvite;
        if (secondaryOwner) {
          if (secondaryOwner.inviteExpiration) {
            expirationDate = secondaryOwner.inviteExpiration;
          }
          if (unfinishedEnrollment(secondaryOwner)) {
            serviceWithdrawInvite = () => cancel(secondaryOwner.enrollmentId);
          }
        }

        this.setState({
          accountNumber,
          routingNumber,
          primaryOwner,
          secondaryOwner,
          expirationDate,
          serviceWithdrawInvite,
          errors: undefined,
          initializing: false,
        });
      },
      (errors) => {
        if (this.cancelableService.isCanceled()) {
          return;
        }

        this.setState({
          initializing: false,
          errors,
        });
      }
    );
  }

  componentWillUnmount() {
    if (this.cancelableService) {
      this.cancelableService.cancel();
    }
  }

  handleWithdrawnInvitation() {
    trackClick(null, "Account Details", "Withdraw Invitation");
    this.setState({
      serviceWithdrawInvite: undefined,
      secondaryOwner: undefined,
      expirationDate: undefined,
    });
  }

  render() {
    const {
      errors,
      loading,
      initializing,
      accountNumber,
      routingNumber,
      primaryOwner,
      secondaryOwner,
      expirationDate,
      serviceWithdrawInvite,
    } = this.state;
    const { onDone } = this.props;

    if (initializing) {
      return <LoadingOverlay active={true} />;
    }

    if (errors) {
      return <Message className="pc-u-mb" severity="error" messages={errors} />;
    }

    return (
      <AccountInformation
        errors={errors}
        loading={loading}
        accountNumber={accountNumber}
        routingNumber={routingNumber}
        primaryOwner={fullName(primaryOwner)}
        secondaryOwner={secondaryOwner && fullName(secondaryOwner)}
        expirationDate={expirationDate}
        serviceWithdrawInvite={serviceWithdrawInvite}
        onWithdrawnInvitation={this.handleWithdrawnInvitation}
        onDone={onDone}
      />
    );
  }
}

AccountInformationContainer.propTypes = {
  userAccountId: PropTypes.number.isRequired,
  onDone: PropTypes.func.isRequired,
  serviceFetchPcbAccountInfo: PropTypes.func,
};

AccountInformationContainer.defaultProps = {
  serviceFetchPcbAccountInfo: promisify(Services.PCBAccount.accountInfo),
};
