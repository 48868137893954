import PropTypes from "prop-types";
import React from "react";
import Loading from "components/common/loading/Loading";
import Message from "components/common/Message";
import WidgetZeroStateContainer from "components/dashboard/WidgetZeroStateContainer";
import CtaOverlay from "components/common/CtaOverlay";
import { formatCurrency } from "libs/pcap/utils/format";
import RetirementWithdrawalsChart from "./RetirementWithdrawalsChart";
import { humanizeTaxStatus } from "components/retirementPaycheck/RetirementPaycheckUtils";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";

const TOOLTIP_TEXT =
  "Retirement savings is made up of this year's net inflows to investment accounts.";

export default class RetirementWithdrawalsSummary extends React.Component {
  render() {
    const { loading, errors, currentBalance, targetBalance } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (errors) {
      return <Message severity="error" messages={this.props.errors} />;
    }

    if (this.props.zeroState) {
      return (
        <WidgetZeroStateContainer
          message={this.props.zeroState}
          widgetName="Retirement Withdrawals Summary"
        />
      );
    }

    const classNames = [
      "summary-widget qa-retirement-summary-widget retirement-savings-widget",
    ];
    if (this.props.className) {
      classNames.push(this.props.className);
    }
    let aceOverlay;
    if (this.props.ace) {
      aceOverlay = (
        <CtaOverlay
          message={this.props.ace}
          widgetName="Retirement Withdrawals Summary"
        />
      );
    }

    return (
      <section className={classNames.join(" ")}>
        <div className="summary-widget__header">
          <div className="l-spaced l-spaced--flush">
            <div
              tabIndex="0"
              className="pc-delta qa-retirement-savings-widget-title"
            >
              {this.props.title}
            </div>
            <InfoTooltipIcon title={TOOLTIP_TEXT} />
          </div>
        </div>
        <div className="summary-widget__body">
          <RetirementWithdrawalsChart
            currentWithdrawal={currentBalance}
            targetWithdrawal={targetBalance}
            rmdIncome={this.props.rmdIncome}
            isClient={this.props.isUserClient}
            portfoliosCount={this.props.portfolios.length}
          />
        </div>
        {this.renderWithdrawals()}
        {aceOverlay}
      </section>
    );
  }

  renderWithdrawals() {
    const { portfolios, targetBalance, isUserClient, isCalculatedWithdrawal } =
      this.props;
    const hasPlanned = isUserClient && isCalculatedWithdrawal;

    if (portfolios.length > 1) {
      return (
        <table className="retirement-withdrawals-table">
          <thead>
            <tr className="micro">
              <th className="u-text-left">Account Tax Status</th>
              <th className="u-text-right">Withdrawn</th>
              {hasPlanned && <th className="u-text-right">Plan</th>}
            </tr>
          </thead>
          <tbody>
            {this.props.portfolios.map((p) => {
              return (
                <tr key={p.name}>
                  <td>{humanizeTaxStatus(p.name)}</td>
                  <td
                    className={`u-text-right ${
                      p.withdrawal > p.plan && isUserClient
                        ? "retirement-withdrawals-table__text--overdrawn"
                        : ""
                    }`}
                  >
                    {formatCurrency(p.withdrawal, 0)}
                  </td>
                  {hasPlanned && (
                    <td className="u-text-right">
                      {formatCurrency(p.plan, 0)}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    if (portfolios.length === 1) {
      return (
        <div className="retirement-savings-widget__withdrawals-text">
          Withdrawn This Year of {formatCurrency(targetBalance, 0)} Plan
        </div>
      );
    }

    return (
      <div className="retirement-savings-widget__withdrawals-text retirement-savings-widget__withdrawals-text--wide">
        Income is expected to cover your spending needs this year.
      </div>
    );
  }
}

RetirementWithdrawalsSummary.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  currentBalance: PropTypes.number,
  targetBalance: PropTypes.number,
  zeroState: PropTypes.object,
  ace: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  portfolios: PropTypes.array,
  isCalculatedWithdrawal: PropTypes.bool,
  isUserClient: PropTypes.bool,
  rmdIncome: PropTypes.number,
};

RetirementWithdrawalsSummary.defaultProps = {
  loading: false,
  title: "",
  ace: null,
  errors: null,
  currentBalance: null,
  targetBalance: null,
  zeroState: null,
  className: "",
  isUserClient: false,
  portfolios: [],
  isCalculatedWithdrawal: false,
  rmdIncome: 0,
};
