/* eslint-disable camelcase */
import React from "react";
import ReactDOM from "react-dom";
import { handleOneTimeCustomContent } from "libs/pcap/utils/customAppointmentSchedulerUtils";
import TrackAccountsOrPickATimeModal from "components/interjection/TrackAccountsOrPickATime/TrackAccountsOrPickATimeModal";
import PromoModal from "views/components/postLoginActions/Promo/PromoModal";
import BasicContentInterjectionModal from "components/interjection/BasicContentInterjection/BasicContentInterjectionModal";
import AdviceNotification from "views/components/postLoginActions/AdviceNotification";
import TextWithImgWidePLA from "views/components/postLoginActions/TextWithImgWidePLA/TextWithImgWidePLA";
import PostLoginActionModal from "components/modal/PostLoginActionModal";
import PcapCashPLA from "views/components/postLoginActions/PcapCashPLA";
import { setSource } from "components/common/attributionStore";
import { renderContentComponent } from "views/components/postLoginActions/PostLoginActionsUtils";
import {
  PCAP_CASH_MODAL_WIDTH,
  MODAL_TITLE_BLANK,
  MEDIUM_MODAL_WIDTH,
  IC_MODAL_WIDTH,
  SMEDIUM_MODAL_WIDTH,
} from "views/components/postLoginActions/constants";
import {
  trackUserMessageViewEvent,
  getJsonContent,
} from "views/components/postLoginActions/helpers";
import { isEqual } from "underscore";
import { normalizeUserMessage } from "views/components/postLoginActions/PcapCashPLA";
import ProjectionChartPLA from "views/components/postLoginActions/ProjectionChartPLA.js";
import UserQuizPLA from "views/components/postLoginActions/UserQuiz/UserQuizPLA";
import {
  getNamespace,
  removeInterjectionFromActiveQueue,
  getSortedActiveInterjections,
  addHandledInterjectionUserMessageId,
  isUserResponseBannerUserMessage,
} from "components/interjection/helpers";
import UserResponseSurveyCustom from "../../views/components/postLoginActions/UserResponseSurveyCustom/UserResponseSurveyCustom";
import Services from "services";
import { promisify } from "utils/service";

// How to Create a Standard Interjection User Message:
//   - Set message.category to `INTERJECTION`
//   - Set message.viewTemplate to a valid React Interjection Modal component name such as `TrackAccountsOrPickATimeModal`
// Below are special customized interjections that do not conform to the standard interjection flow
export const INTERJECTION = {
  INTERJECTION_RP_IC_FA_WEB: "INTERJECTION_RP_IC_FA_WEB",
  INTERJECTION_MAD_LIBS_QQ_WEB: "INTERJECTION_MAD_LIBS_QQ_WEB",
};

export const INTERJECTION_VIEW_TEMPLATE_TO_COMPONENT_MAP = {
  [""]: AdviceNotification, // Leave msg.viewTemplate field blank to use this default modal
  // Below are interjection React modal component names as defined in the msg.viewTemplate field
  TrackAccountsOrPickATimeModal,
  BasicContentInterjectionModal,
  // eslint-disable-next-line react/display-name, react/prop-types
  PCAP_CASH_PLA: ({ onModalClosed, interjection, plaDetails }) => (
    <PostLoginActionModal
      contentComponent={PcapCashPLA}
      userMessage={normalizeUserMessage(interjection)}
      plaDetails={plaDetails}
      loadingModalTitle={MODAL_TITLE_BLANK}
      width={PCAP_CASH_MODAL_WIDTH}
      onModalClosed={onModalClosed}
    />
  ),
  INTERJECTION_WITH_IMAGE_WIDE: TextWithImgWidePLA,
  TEXT_WITH_PROJECTION_CHART: ProjectionChartPLA,
  USER_QUIZ: UserQuizPLA,
  USER_RESPONSE_PLA: PromoModal,
  USER_RESPONSE_SURVEY_CUSTOM: UserResponseSurveyCustom,
};

export const getNextActiveInterjection = ({
  showBlankDisplayLocationInterjection = false,
} = {}) => {
  const activeInterjections = getSortedActiveInterjections();
  if (!activeInterjections || !activeInterjections.length) {
    return;
  }

  const currentHash = window.location.hash;
  // Return the first interjection with blank display location if flag is true
  // Otherwise return the first interjection that has a display location matching current hash
  return activeInterjections.find((activeInterjection) => {
    const displayLocations = activeInterjection.displayLocations || [];
    const isBlankDisplayLocationInterjection = !displayLocations.length;
    if (
      showBlankDisplayLocationInterjection &&
      isBlankDisplayLocationInterjection
    ) {
      return true;
    }
    return displayLocations.find((location) => currentHash.includes(location));
  });
};

export const getPromoInterjection = ({
  showBlankDisplayLocationInterjection = false,
} = {}) => {
  const activeInterjections = getSortedActiveInterjections();
  if (!activeInterjections || !activeInterjections.length) {
    return;
  }

  return activeInterjections.find((activeInterjection) => {
    if (
      activeInterjection.category === "BANNER" &&
      activeInterjection.component === "USER_RESPONSE" &&
      showBlankDisplayLocationInterjection
    ) {
      return activeInterjection;
    }
    return false;
  });
};

//check if it is trying to render same message within a less then a second interval
const shouldRenderMessage = (() => {
  const userMessages = [];
  return (message) => {
    let valueToReturn = true;
    if (message.template !== "MEETING_STARTED") return valueToReturn;
    for (let i = 0; i < userMessages.length; i++) {
      const lastCalled = userMessages[i].lastCalled;
      const timeDiff = Date.now() - lastCalled;
      delete userMessages[i].lastCalled;
      const isSameMessage = isEqual(userMessages[i], message);
      if (isSameMessage && timeDiff < 1000) {
        message.lastCalled = Date.now();
        userMessages.splice(i, 1);
        return false;
      }
    }
    message.lastCalled = Date.now();
    userMessages.push(message);
    return valueToReturn;
  };
})();

export const checkForActiveInterjection = (options) => {
  const userMessage = getNextActiveInterjection(options);
  if (
    !userMessage ||
    // prevents rendering two modals at the same time
    !shouldRenderMessage(userMessage) ||
    // prevents rendering modal again after user started or exited meeting (hash change)
    (options.calledByHashChange &&
      userMessage.template === "MEETING_STARTED") ||
    window.location.hash.includes("view-meeting")
  ) {
    return;
  }
  let interjectionLauncherNode = document.createElement("div");
  document.body.appendChild(interjectionLauncherNode);
  const plaDetails = {
    component: userMessage.template,
    view_template: userMessage.viewTemplate,
    message_template: userMessage.template,
    user_message_id: userMessage.userMessageId,
    user_message_title: userMessage.title,
    is_interjection: true,
  };
  if (!isUserResponseBannerUserMessage(userMessage)) {
    removeInterjectionFromActiveQueue(getNamespace(userMessage));
    addHandledInterjectionUserMessageId(userMessage.userMessageId);
    trackUserMessageViewEvent({ userMessage, plaDetails });
  }

  const onModalClosed = function () {
    if (interjectionLauncherNode) {
      ReactDOM.unmountComponentAtNode(interjectionLauncherNode);
      interjectionLauncherNode.parentNode.removeChild(interjectionLauncherNode);
      interjectionLauncherNode = null;
    }
  };
  const Component =
    INTERJECTION_VIEW_TEMPLATE_TO_COMPONENT_MAP[userMessage.viewTemplate];
  if (Component) {
    if (Component === AdviceNotification) {
      const className = "pc-advice-notification";
      const loadingModalTitle = " ";
      renderContentComponent(
        AdviceNotification,
        userMessage.template,
        userMessage,
        plaDetails,
        loadingModalTitle,
        className,
        MEDIUM_MODAL_WIDTH,
        false
      );
    } else if (Component === TextWithImgWidePLA) {
      const className = "pc-interjection-with-image-wide";
      const loadingModalTitle = "";
      renderContentComponent(
        TextWithImgWidePLA,
        userMessage.template,
        userMessage,
        plaDetails,
        loadingModalTitle,
        className,
        IC_MODAL_WIDTH,
        false
      );
    } else if (Component === ProjectionChartPLA) {
      const className = "pc-interjection-with-projection-chart";
      const loadingModalTitle = " ";
      renderContentComponent(
        ProjectionChartPLA,
        userMessage.template,
        userMessage,
        plaDetails,
        loadingModalTitle,
        className,
        IC_MODAL_WIDTH,
        false
      );
    } else if (Component === PromoModal) {
      const className = "promo-pla";
      const loadingModalTitle = "Link Your Account";
      renderContentComponent(
        PromoModal,
        userMessage.template,
        userMessage,
        plaDetails,
        loadingModalTitle,
        className,
        SMEDIUM_MODAL_WIDTH,
        false
      );
    } else if (Component === UserQuizPLA) {
      const className = "user-quiz-pla";
      const loadingModalTitle = " ";
      renderContentComponent(
        UserQuizPLA,
        userMessage.template,
        userMessage,
        plaDetails,
        loadingModalTitle,
        className,
        MEDIUM_MODAL_WIDTH,
        false
      );
    } else if (Component === UserResponseSurveyCustom) {
      const className = "user-response-survey-custom-pla";
      const loadingModalTitle = " ";
      const fetchAccounts = promisify(Services.Accounts.get);
      fetchAccounts()
        .then((response) => {
          const { accounts } = response;
          const hasAggregatedAccounts = accounts.some((account) => {
            return account.isManual === false;
          });

          if (hasAggregatedAccounts) {
            removeInterjectionFromActiveQueue(getNamespace(userMessage));
            addHandledInterjectionUserMessageId(userMessage.userMessageId);
          } else {
            renderContentComponent(
              UserResponseSurveyCustom,
              userMessage.template,
              userMessage,
              plaDetails,
              loadingModalTitle,
              className,
              PCAP_CASH_MODAL_WIDTH,
              false
            );
          }
        })
        .catch(() => {
          // Error Handling
          // Dont render anything if there is an error with API call
        });
    } else {
      const props = { onModalClosed, userMessage: userMessage, plaDetails };
      ReactDOM.render(
        React.createElement(Component, props),
        interjectionLauncherNode
      );
    }
  } else if (userMessage.viewTemplate === "CUSTOM_APPOINTMENT_SCHEDULER") {
    handleOneTimeCustomContent(userMessage);
  } else if (userMessage.viewTemplate === "IN_APP_MODAL_URL") {
    const jsonContent = getJsonContent(userMessage) || {};
    const modalUrl = jsonContent.redirectTo;
    if (modalUrl.indexOf("#") === 0) {
      setSource(userMessage.template);
      window.location.href = modalUrl;
    }
  }
};
