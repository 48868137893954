import React, { Component } from "react";
import TableClient from "libs/pcap/table/tableClient/TableClient";
import moment from "moment";
import DateUtils from "libs/pcap/utils/date";
import Row from "libs/pcap/table/Row";
import PropTypes from "prop-types";
import { noop, isEqual } from "underscore";

const timeComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }

    return (
      moment(a.documentDate) - moment(b.documentDate) ||
      a.edocumentId - b.edocumentId
    );
  };
};

const alphaComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }
    let aVal = accessor(a).toUpperCase();
    let bVal = accessor(b).toUpperCase();

    return aVal.localeCompare(bVal);
  };
};

export const documentTitleComparator = (accessor, order) => {
  return alphaComparator((d) => accessor(d).documentTitle, order);
};

const getFullFilename = (activeTab, document) => {
  if (
    activeTab !== "UPLOADED" &&
    document.documentTitle &&
    document.extensionType
  ) {
    return document.documentTitle + `.${document.extensionType.toLowerCase()}`;
  }

  return document.documentTitle;
};

export const onLoadHandler = (request, documentObj) => {
  if (request.status !== 200) return;

  const binaryData = [];
  binaryData.push(request.response);
  const docURL = URL.createObjectURL(
    new Blob(binaryData, { type: "application/png" })
  );

  const anchor = document.createElement("a");
  anchor.href = docURL;
  anchor.download = documentObj.documentTitle;
  document.body.appendChild(anchor);
  anchor.click();
};

const downloadDocument = (e, doc) => {
  e.preventDefault();
  const edocumentId = doc.edocumentId;
  let baseUrl = window.baseUrl || "";
  const documentURL =
    baseUrl + "servlet/edocs/getEdocsById?edocumentId=" + edocumentId;

  const xhr = new XMLHttpRequest();
  xhr.responseType = "arraybuffer";
  xhr.open("GET", documentURL, true);

  if (IS_EMPOWER) {
    xhr.withCredentials = true;
  }
  xhr.send(documentURL);
  xhr.onload = () => {
    onLoadHandler(xhr, doc);
  };
};

const getColumns = ({ showDocType, onDeleteClick, activeTab }) => {
  const actionsFormatter = (document) => {
    return (
      <>
        {activeTab === "UPLOADED" ? (
          <button
            onClick={onDeleteClick}
            className="js-action-document-delete qa-action-document-delete pc-btn--link pc-u-mr--"
            data-docid={document.edocumentId}
            data-filename={document.documentTitle}
            title="Delete File"
          >
            <span className="icon icon-trash--negative" />
          </button>
        ) : null}
        <a
          onClick={(e) => downloadDocument(e, document)}
          target="_blank"
          rel="noopener noreferrer"
          className=" js-action-document-download"
          title="Download File"
          download={getFullFilename(activeTab, document)}
        >
          <span className="icon icon-download--primary" />
        </a>
      </>
    );
  };

  const documentTitleFormatter = (document) => {
    return (
      <div
        rel="noopener noreferrer"
        className="document-vault-table-cell js-action-document-download"
        title={document.documentTitle}
      >
        {document.documentTitle}
      </div>
    );
  };

  const columns = [
    {
      header: "Date",
      accessor: (d) => d.documentDate,
      formatter: (d) => moment(d).format(DateUtils.DISPLAY_FORMAT),
      className:
        "document-vault-table-cell document-vault-table-cell--date js-document-vault-table-date",
      headerClassName:
        "document-vault-table-cell--date js-document-vault-table-date",
      isSortable: true,
      sortOrder: "desc",
      comparator: timeComparator,
    },
    {
      header: "Document Title",
      accessor: (d) => d,
      formatter: documentTitleFormatter,
      className:
        "document-vault-table-cell document-vault-table-cell--title u-clip-text js-document-vault-table-title",
      headerClassName:
        "document-vault-table-cell--title js-document-vault-table-title",
      isSortable: true,
      comparator: documentTitleComparator,
    },
    {
      header: "",
      accessor: (d) => d,
      formatter: actionsFormatter,
      className:
        "document-vault-table-cell document-vault-table-cell--actions u-clip-text js-document-vault-table-name",
      headerClassName:
        "document-vault-table-cell--actions js-document-vault-table-actions",
      isSortable: false,
    },
  ];

  if (showDocType) {
    columns.splice(columns.length - 1, 0, {
      header: "Document Type",
      accessor: (d) => d.displayableName || "",
      titleAccessor: (d) => d.documentType || "",
      className:
        "document-vault-table-cell document-vault-table-cell--type u-clip-text js-document-vault-table-type",
      headerClassName:
        "document-vault-table-cell--type js-document-vault-table-type",
      isSortable: true,
      comparator: alphaComparator,
    });
  }
  return columns;
};

class DocumentVaultTable extends Component {
  static keyAccessor(document) {
    return document.edocumentId;
  }

  static getDerivedStateFromProps(props, state) {
    const { documents } = props;
    if (!isEqual(documents, state.documents)) {
      return {
        documents,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);

    this.state = {
      documents: props.documents,
    };
  }

  getRow(props) {
    /* Add row editing component here when needed */

    return <Row {...props} />;
  }

  render() {
    const { documents } = this.state;

    return (
      <TableClient
        {...this.props}
        columns={getColumns(this.props)}
        keyAccessor={DocumentVaultTable.keyAccessor}
        data={documents}
        onRowClick={noop}
        Row={this.getRow}
        tableClassName={
          "table--primary table__body--primary centi " +
          this.props.tableClassName
        }
        className="document-vault-table-container qa-document-vault-table-container"
        paginator={false}
        zeroState={this.props.renderZeroStateMessage()}
      />
    );
  }
}

DocumentVaultTable.propTypes = {
  documents: PropTypes.array,
  renderZeroStateMessage: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func,
  activeTab: PropTypes.string,
  showDocType: PropTypes.bool,
  tableClassName: PropTypes.string,
};

DocumentVaultTable.defaultProps = {
  documents: [],
  onDeleteClick: noop,
  activeTab: "STATEMENTS",
  showDocType: true,
  tableClassName: "document-vault-table",
};

export default DocumentVaultTable;
