import React from "react";
import PropTypes from "prop-types";
import { formatCurrency, formatPercent } from "libs/pcap/utils/format";
import Input from "components/common/form/Input";
import { POSITIVE_INTEGER_FORMAT } from "components/common/form/formattingOptions";
import AbstractForm from "components/common/form/AbstractForm";

class EditFederalTaxWithholding extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      updatedTaxWithholdingPercentage: props.originalTaxWithholdingPercentage,
    };
    this.scrollPositionRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.onMounted("Edit federal tax withholding");
    // Scrolls up to topmost referenced element when displayed in scrollable modal
    this.scrollPositionRef.current?.scrollIntoView({ block: "nearest" });
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({ updatedTaxWithholdingPercentage: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.onSave(this.state.updatedTaxWithholdingPercentage);
    }
  }

  render() {
    const { updatedTaxWithholdingPercentage } = this.state;
    const { onCancel, originalTaxWithholdingPercentage, federalTaxOptOut } =
      this.props;

    const currentYear = new Date().getFullYear();
    const tableInformationByYear = {
      2023: {
        tableHeader: "[2023] Marginal rate tables",
        externalIRSFormUrl: "https://www.irs.gov/pub/irs-prior/fw4r--2023.pdf",
        marriedFilingSeparatelyDisclaimer: `If married filing separately, use $360,725 instead for this 37% rate`,
        withholdingTaxTableValues: [
          {
            separate: { dollar: 0, percent: 0 },
            joint: { dollar: 0, percent: 0 },
            head: { dollar: 0, percent: 0 },
          },
          {
            separate: { dollar: 13850, percent: 10 },
            joint: { dollar: 27700, percent: 10 },
            head: { dollar: 20800, percent: 10 },
          },
          {
            separate: { dollar: 24850, percent: 12 },
            joint: { dollar: 49700, percent: 12 },
            head: { dollar: 36500, percent: 12 },
          },
          {
            separate: { dollar: 58575, percent: 22 },
            joint: { dollar: 117150, percent: 22 },
            head: { dollar: 80650, percent: 22 },
          },
          {
            separate: { dollar: 109225, percent: 24 },
            joint: { dollar: 218450, percent: 24 },
            head: { dollar: 116150, percent: 24 },
          },
          {
            separate: { dollar: 195950, percent: 32 },
            joint: { dollar: 391900, percent: 32 },
            head: { dollar: 202900, percent: 32 },
          },
          {
            separate: { dollar: 245100, percent: 35 },
            joint: { dollar: 490200, percent: 35 },
            head: { dollar: 252050, percent: 35 },
          },
          {
            separate: { dollar: 591975, percent: 37, disclaimer: true },
            joint: { dollar: 721450, percent: 37 },
            head: { dollar: 598900, percent: 37 },
          },
        ],
      },
      2024: {
        tableHeader: "[2024] Marginal rate tables",
        externalIRSFormUrl: "https://www.irs.gov/pub/irs-prior/fw4r--2024.pdf",
        marriedFilingSeparatelyDisclaimer: `If married filing separately, use $380,200 instead for this 37% rate.`,
        withholdingTaxTableValues: [
          {
            separate: { dollar: 0, percent: 0 },
            joint: { dollar: 0, percent: 0 },
            head: { dollar: 0, percent: 0 },
          },
          {
            separate: { dollar: 14600, percent: 10 },
            joint: { dollar: 29200, percent: 10 },
            head: { dollar: 21900, percent: 10 },
          },
          {
            separate: { dollar: 26200, percent: 12 },
            joint: { dollar: 52400, percent: 12 },
            head: { dollar: 38450, percent: 12 },
          },
          {
            separate: { dollar: 61750, percent: 22 },
            joint: { dollar: 123500, percent: 22 },
            head: { dollar: 85000, percent: 22 },
          },
          {
            separate: { dollar: 115125, percent: 24 },
            joint: { dollar: 230250, percent: 24 },
            head: { dollar: 122400, percent: 24 },
          },
          {
            separate: { dollar: 206550, percent: 32 },
            joint: { dollar: 413100, percent: 32 },
            head: { dollar: 213850, percent: 32 },
          },
          {
            separate: { dollar: 258325, percent: 35 },
            joint: { dollar: 516650, percent: 35 },
            head: { dollar: 265600, percent: 35 },
          },
          {
            separate: { dollar: 623950, percent: 37, disclaimer: true },
            joint: { dollar: 760400, percent: 37 },
            head: { dollar: 631250, percent: 37 },
          },
        ],
      },
    };

    const currentYearTableInformation =
      tableInformationByYear[currentYear] ??
      tableInformationByYear[currentYear - 1];

    const tablePrimaryCopy = `Update below if you would like a rate of withholding that is different from the default withholding rate. Generally, you can't choose less than 10% for payments to be delivered outside the United States and its possessions. See instructions on page 2 of the <a rel="noopener noreferrer" target="_blank" href="${currentYearTableInformation.externalIRSFormUrl}"> IRS Form W-4R</a> and the Marginal tax tables below for additional information.`;
    const tableRateCopy = `You may use these tables to help you select the appropriate withholding rate for this payment or distribution. Add your income from all sources and use the column that matches your filing status to find the corresponding rate of withholding. See page 2 of the <a rel="noopener noreferrer" target="_blank" href="${currentYearTableInformation.externalIRSFormUrl}">IRS Form W-4R</a> for more information on how to use this table.`;

    const taxWithholdingValidator = {
      type: "number",
      required: true,
      allowEmpty: false,
      pattern: /^\s*[0-9]+([0-9][0-9]{0,2})?$|^$|^\s*$/,
      minimum: originalTaxWithholdingPercentage,
      messages: {
        minimum: `Must be at least ${originalTaxWithholdingPercentage}`,
      },
    };

    return (
      <>
        <div
          className="pc-u-mb"
          dangerouslySetInnerHTML={{
            __html: tablePrimaryCopy,
          }}
          ref={this.scrollPositionRef}
        ></div>
        <div>
          <label htmlFor="updatedTaxWithholdingPercentage">
            Federal tax withholding
          </label>
          <div className="pc-u-mt-- pc-u-mb--">
            <Input
              containerClassName="edit-federal-tax-withholding--input"
              ref={this.storeInputRef}
              type="text"
              id="updatedTaxWithholdingPercentage"
              name="updatedTaxWithholdingPercentage"
              sizeVariation="full"
              value={updatedTaxWithholdingPercentage}
              formattingOptions={POSITIVE_INTEGER_FORMAT}
              onChange={this.handleChange}
              maxLength={3}
              placeholder="0"
              validator={taxWithholdingValidator}
              data-hj-masked
              suffix="%"
              disabled={federalTaxOptOut}
            />
          </div>
          <p className="edit-federal-tax-withholding--help-text pc-label--tiny">
            Applies to taxable amount only.
          </p>
        </div>
        <h2>{currentYearTableInformation.tableHeader}</h2>
        <div
          className="pc-u-mb-"
          dangerouslySetInnerHTML={{
            __html: tableRateCopy,
          }}
        ></div>
        <table
          className="edit-federal-tax-withholding--table"
          role="presentation"
        >
          <thead>
            <tr className="edit-federal-tax-withholding--table-row">
              <th className="edit-federal-tax-withholding--header-cell">
                Single or married filing separately
              </th>
              <th className="edit-federal-tax-withholding--header-cell">
                Married filing jointly or qualifying widow(er)
              </th>
              <th className="edit-federal-tax-withholding--header-cell">
                Head of household
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="edit-federal-tax-withholding--table-row">
              <td className="edit-federal-tax-withholding--shared-table-cell">
                <div className="edit-federal-tax-withholding--table-cell-left-element">
                  Total income over...
                </div>
                <div className="edit-federal-tax-withholding--table-cell-right-element">
                  Tax rate for every dollar more
                </div>
              </td>
              <td className="edit-federal-tax-withholding--shared-table-cell">
                <div className="edit-federal-tax-withholding--table-cell-left-element">
                  Total income over...
                </div>
                <div className="edit-federal-tax-withholding--table-cell-right-element">
                  Tax rate for every dollar more
                </div>
              </td>
              <td className="edit-federal-tax-withholding--shared-table-cell">
                <div className="edit-federal-tax-withholding--table-cell-left-element">
                  Total income over...
                </div>
                <div className="edit-federal-tax-withholding--table-cell-right-element">
                  Tax rate for every dollar more
                </div>
              </td>
            </tr>
            {currentYearTableInformation.withholdingTaxTableValues?.length >
              0 &&
              currentYearTableInformation.withholdingTaxTableValues.map(
                ({ separate, joint, head }, idx) => {
                  return (
                    <tr
                      key={idx}
                      className="edit-federal-tax-withholding--table-row"
                    >
                      <td className="edit-federal-tax-withholding--shared-table-cell">
                        <div className="edit-federal-tax-withholding--table-cell-left-element">
                          {formatCurrency(separate.dollar, 0)}
                          {separate.disclaimer && "*"}
                        </div>
                        <div className="edit-federal-tax-withholding--table-cell-right-element">
                          {formatPercent(separate.percent, { precision: 0 })}
                        </div>
                      </td>
                      <td className="edit-federal-tax-withholding--shared-table-cell">
                        <div className="edit-federal-tax-withholding--table-cell-left-element">
                          {formatCurrency(joint.dollar, 0)}
                        </div>
                        <div className="edit-federal-tax-withholding--table-cell-right-element">
                          {formatPercent(joint.percent, { precision: 0 })}
                        </div>
                      </td>
                      <td className="edit-federal-tax-withholding--shared-table-cell">
                        <div className="edit-federal-tax-withholding--table-cell-left-element">
                          {formatCurrency(head.dollar, 0)}
                        </div>
                        <div className="edit-federal-tax-withholding--table-cell-right-element">
                          {formatPercent(head.percent, { precision: 0 })}
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
        <p className="edit-federal-tax-withholding--help-text pc-label--tiny pc-u-mb-">
          {`*${currentYearTableInformation.marriedFilingSeparatelyDisclaimer}`}
        </p>
        <p>
          The information below is from the IRS Form W-4R and is for
          informational purposes only. Please ensure you complete the
          appropriate distribution form section for federal income tax
          withholding.
        </p>
        <div className="u-text-right">
          <button type="button" className="pc-btn" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="submit"
            className="pc-btn pc-btn--primary"
            onClick={this.handleSubmit}
          >
            Save
          </button>
        </div>
      </>
    );
  }
}

EditFederalTaxWithholding.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  originalTaxWithholdingPercentage: PropTypes.number.isRequired,
  federalTaxOptOut: PropTypes.bool,
  onMounted: PropTypes.func,
};

EditFederalTaxWithholding.defaultProps = {
  federalTaxOptOut: false,
  onMounted: () => null,
};

export default EditFederalTaxWithholding;
