import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import _ from "underscore";
import EmailAdvisorContainer from "components/contactAdvisor/emailAdvisor/EmailAdvisorContainer";
import ComponentAnalytics from "components/common/ComponentAnalytics";

const MODAL_WIDTH = 567;

const trackLauncherViewOnce = _.once((props) => {
  ComponentAnalytics.trackView(`${props.modalComponentName} Launcher`);
});

export default class EmailAdvisorLauncher extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      showLauncher: true,
      showModal: false,
      emailSent: false,
    };
  }

  showModal() {
    this.setState({
      showLauncher: false,
      showModal: true,
      emailSent: false,
    });
  }

  onEmailAdvisorDone(options = {}) {
    this.setState({
      emailSent: options.emailSent,
    });
  }

  onModalClosed() {
    this.setState({
      showLauncher: true,
      showModal: false,
    });
  }

  getEmailAdvisorLauncher() {
    trackLauncherViewOnce(this.props);

    return (
      <div
        className="contact-advisor-launcher"
        onClick={this.showModal.bind(this)}
      >
        <div className="contact-advisor-launcher__advisor-image" />
        <h4 className="contact-advisor-launcher__advisor-name">Andrew T.</h4>
        <p className="contact-advisor-launcher__advisor-description">
          Vice President at Empower
        </p>
        <button className="pc-btn pc-btn--full pc-btn--small js-email-me-btn">
          <svg
            className="contact-advisor-launcher__button-icon"
            viewBox="0 0 24 24"
          >
            <use xlinkHref="#pc-icon__email" />
          </svg>
          Email Me
        </button>
      </div>
    );
  }

  getEmailAdvisorContainer() {
    const { featureName, modalComponentName } = this.props;
    return (
      <EmailAdvisorContainer
        featureName={featureName}
        componentName={modalComponentName}
        onEmailAdvisorDone={this.onEmailAdvisorDone.bind(this)}
      />
    );
  }

  getThankYouMessage() {
    return (
      <div>
        <p>
          Your email has been received! Andrew will be in contact with you
          shortly, to see how we can help you!
        </p>
        <button className="pc-btn" onClick={this.onModalClosed.bind(this)}>
          Close
        </button>
      </div>
    );
  }

  render() {
    const { modalComponentName } = this.props;
    const emailAdvisorLauncher = this.state.showLauncher
      ? this.getEmailAdvisorLauncher()
      : null;
    const modalTitle = this.state.emailSent ? "Thank You" : "How Can We Help?";
    const modalContent = this.state.emailSent
      ? this.getThankYouMessage()
      : this.getEmailAdvisorContainer();

    return (
      <div>
        {emailAdvisorLauncher}
        <Modal
          componentName={modalComponentName}
          title={modalTitle}
          onClosed={this.onModalClosed.bind(this)}
          // react-modal APIs
          contentLabel="Email Advisor Modal"
          isOpen={this.state.showModal}
          style={{ content: { width: MODAL_WIDTH } }}
        >
          {modalContent}
        </Modal>
      </div>
    );
  }
}

EmailAdvisorLauncher.propTypes = {
  // The web app feature the user is currently on
  featureName: PropTypes.string.isRequired,
  // Name of modal component to use when tracking analytics events
  modalComponentName: PropTypes.string.isRequired,
};
