var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"js-account-credentials sidebar-account__prompts\">\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"aggregationError") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"PASSWORD_OR_QUESTIONS_INCORRECT",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":41},"end":{"line":32,"column":12}}})) != null ? stack1 : "")
    + " ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<p class=\"pc-bg-dark pc-u-mb-\">\n  <button class=\"pc-btn pc-btn--link pc-btn--tiny js-prompts-more-information-toggle\">\n    Still having trouble?\n  </button>\n</p>\n<div class=\"js-prompts-more-information-content hidden\">\n  <p class=\"sidebar-account__message\">\n    Make sure you are using the credentials for\n    <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"loginUrl") : depth0), depth0))
    + "\" target=\"_blank\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"loginUrl") : depth0), depth0))
    + "</a>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"supportedAccountTypes") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":59},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "."
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"loginFieldsMaxLengthAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":19,"column":58}}})) != null ? stack1 : "")
    + "\n  </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showContactSuppot") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":2},"end":{"line":30,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", which only supports\n    "
    + container.escapeExpression(__default(require("../../helpers/concatenatedAccountTypesString.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"supportedAccountTypes") : stack1),{"name":"concatenatedAccountTypesString","hash":{},"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":16,"column":71}}}))
    + "\n    accounts";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " Also check that your\n    username and password doesn't exceed "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"usernameMaxlength") : depth0), depth0))
    + " and\n    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"passwordMaxlength") : depth0), depth0))
    + " characters, respectively.";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p class=\"sidebar-account__message js-contact-support-link\">\n      If this didn't help, please\n      <a\n        href=\"https://"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"organizationSupportUrl") : depth0), depth0))
    + "/hc/en-us/requests/new\"\n        target=\"_blank\"\n        >contact support</a\n      >.\n    </p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(require("./removeAccount2.html"),depth0,{"name":"templates/modules/sidebar/removeAccount2","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"aggregationError") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"ZILLOW_ADDRESS_INVALID",{"name":"compare","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":27},"end":{"line":39,"column":14}}})) != null ? stack1 : "")
    + " ";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = container.invokePartial(require("./removeAccount2.html"),depth0,{"name":"templates/modules/sidebar/removeAccount2","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"nextAction") : depth0), depth0),{"name":"nextAction","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":4,"column":15}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"aggregationError") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":32,"column":20}}})) != null ? stack1 : "")
    + "\n<div class=\"sidebar-account__actions\">\n  "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.noop,"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":34,"column":2},"end":{"line":35,"column":20}}})) != null ? stack1 : "")
    + "  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"aggregationError") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":2},"end":{"line":39,"column":22}}})) != null ? stack1 : "")
    + "\n  <button type=\"button\" class=\"js-account-cancel pc-btn pc-btn--tiny\">\n    Cancel\n  </button>\n  <button\n    type=\"button\"\n    class=\"js-account-authorize pc-btn pc-btn--tiny pc-btn--primary qa-account-authorize\"\n  >\n    Submit\n  </button>\n</div>\n";
},"usePartial":true,"useData":true});