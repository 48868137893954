import React from "react";
import Services from "services";
import { subscribify } from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import PropTypes from "prop-types";
import Message from "components/common/Message";
import DebtPaydownSummary from "./DebtPaydownSummary";
import { noop } from "underscore";
import ZeroStateWidget from "../dashboard/ZeroStateWidget";

export default class DebtPaydownSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
  }

  handleSuccess(response) {
    const { plannerStage, accountInfos } = response.whatIf;
    const { result } = response;

    this.setState(
      {
        accounts: accountInfos.filter(
          (a) =>
            ["CREDIT_CARD", "LOAN", "MORTGAGE"].includes(a.productType) &&
            (a.balance > 0 || a.balanceChange !== 0)
        ),
        debtBalances: result.debtHistoriesArray,
        ytdBalanceChange: result.ytdCashFlowDebt,
        plannerStage,
        actions: result.actions,
        takeaways: result.takeaways,
        loading: false,
        errors: [],
      },
      this.props.onDCDashboardComponentLoaded
    );
  }

  handleFailure(errors) {
    this.setState(
      {
        loading: false,
        errors,
      },
      this.props.onDCDashboardComponentLoaded
    );
  }

  componentDidMount() {
    this.getDebtPaydownSubscription = this.props.fetchDebtPaydown();
    this.getDebtPaydownSubscription.on("change", this.handleSuccess);
    this.fetchDebtPaydownCancelablePromise = makeCancelablePromise(
      this.getDebtPaydownSubscription.promise
    );
    this.fetchDebtPaydownCancelablePromise.promise
      .then(this.handleSuccess)
      .catch(this.handleFailure);
  }

  componentWillUnmount() {
    if (this.fetchDebtPaydownCancelablePromise) {
      this.fetchDebtPaydownCancelablePromise.cancel();
    }

    if (this.getDebtPaydownSubscription) {
      this.getDebtPaydownSubscription.unwatch();
      this.getDebtPaydownSubscription.off("change");
    }
  }

  render() {
    const {
      accounts,
      debtBalances,
      ytdBalanceChange,
      plannerStage,
      actions,
      takeaways,
      loading,
      errors,
    } = this.state;

    if (errors?.length) {
      return <Message className="pc-u-mb" severity="error" messages={errors} />;
    }

    if (
      !loading &&
      accounts &&
      accounts.length === 0 &&
      window.integratedSharedData
    ) {
      const translations = window.integratedSharedData?.translations;
      const zeroStateTranslations = translations?.get("zeroStateDashboard");

      return (
        <ZeroStateWidget
          displayName={zeroStateTranslations?.debtPaydown?.title}
          cta={zeroStateTranslations?.debtPaydown?.cta}
          link={"#/savings-planner/debt-paydown"}
          label={zeroStateTranslations?.debtPaydown?.label}
          tooltip={zeroStateTranslations?.debtPaydown?.tooltip}
          buttonAlign={"center"}
          buttonClass={"btn__debtPaydown"}
          widgetHeaderClass={"header__debtPaydown"}
          backgroundName="debtpaydown"
        />
      );
    }

    return (
      <DebtPaydownSummary
        accounts={accounts}
        debtBalances={debtBalances}
        ytdBalanceChange={ytdBalanceChange}
        plannerStage={plannerStage}
        actions={actions}
        takeaways={takeaways}
        loading={loading}
        showAddAccounts={this.props.showAddAccounts}
      />
    );
  }
}

DebtPaydownSummaryContainer.propTypes = {
  fetchDebtPaydown: PropTypes.func,
  onDCDashboardComponentLoaded: PropTypes.func,
  showAddAccounts: PropTypes.bool,
};

DebtPaydownSummaryContainer.defaultProps = {
  onDCDashboardComponentLoaded: noop,
  fetchDebtPaydown: subscribify(Services.DebtHistory.get),
  showAddAccounts: true,
};
