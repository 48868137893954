function BaseStateModel() {
  this.baseUrl = "";

  // path related values
  this.path = "";

  this.pathNodes = [];
  this.upStreamPath = "";

  // values coming from url query string
  this.optionalUrlParams = [];

  // values coming from Backbone view
  this.internalStateParams = [];
}

BaseStateModel.prototype.constructor = BaseStateModel;

export default BaseStateModel;
