import { isEmpty, indexBy } from "underscore";
import { dateStructToString } from "libs/pcap/utils/date2";
import { nonUsCitizen } from "utils/person";
import { validateSuffix } from "libs/pcap/utils/person";

function ssnStructToString({ ssn1, ssn2, ssn3 }) {
  if (!ssn1 || !ssn2 || !ssn3) {
    return;
  }
  return `${ssn1}-${ssn2}-${ssn3}`;
}

/**
 * Takes a person server representation and converts it to the form used on the client side.
 *
 * @param {Object}  obj                       the person object
 * @return {Object} the client side model
 */
export default function (obj) {
  const model = Object.assign({}, obj);
  if (!isEmpty(model.ssn)) {
    model.taxpayerIdNumber = ssnStructToString(model.ssn);
  }

  if (!isEmpty(model.governmentIds)) {
    const governmentIds = model.governmentIds.map((id) =>
      Object.assign({}, id, {
        expirationDate:
          id.expirationDate && dateStructToString(id.expirationDate),
      })
    );
    model.governmentIdsMap = indexBy(governmentIds, "type");
  }

  if (nonUsCitizen(model)) {
    model.governmentIdType = "PASSPORT";
  }

  if (!isEmpty(model.birthday)) {
    model.birthdayString = dateStructToString(model.birthday);
  }

  // When patriotActGovtOfficial has a valid value true or false we want to set it
  // to 'Y' or 'N' so that one of the check box is selected
  if (model.patriotActGovtOfficial != null) {
    model.patriotActGovtOfficial = model.patriotActGovtOfficial ? "Y" : "N";
  }

  if (IS_EMPOWER) {
    model.name = validateSuffix(model.name);
  }
  model.hasMailingAddress = Boolean(model.mailingAddress);

  return model;
}
