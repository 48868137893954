import Handlebars from "handlebars/runtime";
import { formatCurrency } from "../../libs/pcap/utils/format";

/* eslint-disable sonarjs/cognitive-complexity */
export function generateDetailsURL(account) {
  var url = "";
  // has user account and not a plain manual account
  if (
    account.accountId !== undefined &&
    account.userAccountId !== undefined &&
    (account.isManual === false ||
      account.isCrypto === true ||
      account.isManualPortfolio === true ||
      account.isEsog === true)
  ) {
    // has user account with a details screen in a non-error state
    url = "#/accounts";
    if (IS_EMPOWER) {
      //add forward slash if accountURL doesn't include one
      const accountURL =
        account.accountSummary?.accountURL ||
        account.empowerData?.accountSummary?.accountURL ||
        account.empowerData?.accountSummaries?.accountURL;
      if (accountURL) {
        const empowerAccountOverviewUrl =
          accountURL.charAt(0) === "/" ? accountURL : "/" + accountURL;

        url = empowerAccountOverviewUrl;
      } else if (account.isEsog) {
        url =
          "#/stock-options?ua=" +
          account.userAccountId +
          "&firmName=" +
          account.firmName;
      } else {
        url +=
          "/details?ua=" +
          account.userAccountId +
          "&firmName=" +
          account.firmName;
      }
    } else if (account.isEsog) {
      url = "#/stock-options?ua=" + account.userAccountId;
    } else if (account.isCrypto) {
      url = "#/crypto?ua=" + account.userAccountId;
    } else {
      url += "/details?ua=" + account.userAccountId;
    }
  }
  return url;
}

export default function (accountObj) {
  // eslint-disable-next-line consistent-this, no-invalid-this
  var account = this;

  const accountBalanceLabel = account.balance
    ? `Balance: ${formatCurrency(account.balance, 0)}`
    : "";

  const accountAriaLabel = `${account.firmName} ${
    account.name || account.originalName || ""
  } ${accountBalanceLabel} ${account.nextAction?.statusMessage || ""}`;

  var link = `<span role="link" aria-label="${accountAriaLabel}" class="firmName" `;

  if (!account) {
    account = accountObj;
  }

  if (
    account.productType !== "OTHER_ASSETS" &&
    account.productType !== "OTHER_LIABILITIES"
  ) {
    link +=
      'title="' +
      (IS_EMPOWER ? account.firmName : account.originalFirmName) +
      '"';
  }

  if (
    account.isHome === true &&
    account.useHomeValuation === true &&
    account.homeUrl !== undefined
  ) {
    account.firmName +=
      ' - <a class="zestimateCopy" href="' +
      account.homeUrl +
      '" target="_blank">Zestimate&reg;</a>';
  }

  link += " data-hj-masked>" + account.firmName + "</span>";

  // if empowerData.accountSummary is not found for an empower account, it means we do not have all the details
  // needed to make the account clickbale in the sidebar. Simply return is a <span> tag
  if (IS_EMPOWER && account.isEmpower && !account?.empowerData?.accountSummary)
    return new Handlebars.SafeString(link);

  if (
    account.userAccountId > 0 &&
    (account.isManual === false ||
      account.isManualPortfolio === true ||
      account.isEsog === true)
  ) {
    // and not a plain manual account
    // has user account with a details screen in a non-error state
    const accountName = account.originalName
      ? account.originalName
      : account.name;
    link =
      '<a class="firmName" title="' +
      (IS_EMPOWER ? account.firmName : account.originalFirmName) +
      `-${accountName}` +
      '" href="' +
      generateDetailsURL(account) +
      '" aria-label="' +
      accountAriaLabel +
      '" data-hj-masked>' +
      account.firmName +
      "</a>";
  }

  return new Handlebars.SafeString(link);
}
