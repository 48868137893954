import Backbone from "backbone";
import { formatCurrency } from "libs/pcap/utils/format";
import template from "templates/components/sidebar/netWorthBar.html";

const PERCENT_HUNDRED = 100;
const CLASS_NAME_ASSETS = "net-worth-bar--assets";
const CLASS_NAME_LIABILITIES = "net-worth-bar--liabilities";

/**
 * Net Worth Bar
 *
 * Displays a horizontal bar representing the supplied `value`.
 * The maximum value the bar can expand to is defined as `total`.
 *
 * The maximum value of assets and liabilities should be provided
 * as `total`.
 *
 * Initialization options:
 * @param {String}  type    Defines the type of the bar.
 *                          Supported values: `assets`, `liabilities`.
 * @param {Number}  value   The value to be represented by the bar.
 * @param {Number}  total   The total or the maximum value.
 */
export default Backbone.View.extend({
  /**
   * Constructor.
   *
   * @param {object}  options The initialization options.
   * @param {string}  type    Defines the type of the bar.
   *                          Supported values: `assets`, `liabilities`.
   * @param {number}  value   The value to be represented by the bar.
   * @param {number}  total   The total or the maximum value.
   */
  initialize(options) {
    this.type = options.type;
    // round the value during the assignment, so that
    // the filler's width accurately represents the displayed value.
    this.value = Math.floor(options.value || 0);
    this.total = options.total;

    this.render();
  },

  /**
   * Sets the new `total` and updates the bar.
   *
   * @param {number} [total=0]  The new total.
   */
  setTotal(total = 0) {
    this.total = total;
    this.updateFillerWidth();
  },

  /**
   * Sets the new `value` and updates the bar.
   *
   * @param {number} [value=0]  The new value.
   */
  setValue(value = 0) {
    // round the value during the assignment, so that
    // the filler's width accurately represents the displayed value.
    this.value = Math.floor(value);
    this.valueEl.text(this.getFormattedValue());
    this.updateFillerWidth();
  },

  render() {
    let className = this.getClassName();
    let name = this.getName();
    let fragment = $(
      template({
        className: className,
        name: name,
        value: this.value,
      })
    );
    this.fillerEl = fragment.find(".js-net-worth-bar-filler");
    this.valueEl = fragment.find(".js-net-worth-bar-value");

    this.updateFillerWidth();

    this.$el.html(fragment);
  },

  updateFillerWidth() {
    let width;
    if (this.value && this.total) {
      width = (this.value * PERCENT_HUNDRED) / this.total;
    } else {
      width = 0;
    }
    this.fillerEl.css({ width: width + "%" });
  },

  getClassName() {
    let className;
    switch (this.type) {
      case "assets":
        className = CLASS_NAME_ASSETS;
        break;
      case "liabilities":
        className = CLASS_NAME_LIABILITIES;
        break;
      default:
        throw new Error(`"${this.type}" type is not supported`);
    }

    return className;
  },

  getName() {
    let name;
    switch (this.type) {
      case "assets":
        name = "Assets";
        break;
      case "liabilities":
        name = "Liabilities";
        break;
      default:
        throw new Error(`"${this.type}" type is not supported`);
    }

    return name;
  },

  getFormattedValue() {
    return formatCurrency(this.value, 0);
  },
});
