import PropTypes from "prop-types";
import React from "react";
import MultiselectDropdown from "components/common/multiselectDropdown/MultiselectDropdown";
import SelectableAccount from "components/common/multiselectDropdown/SelectableAccount";

const AccountsDropdown = (props) => {
  function label(items) {
    const checkedNumber = items.reduce((accumulator, item) => {
      return accumulator + (item.checked ? 1 : 0);
    }, 0);

    if (checkedNumber === items.length) {
      return "All Accounts";
    }

    if (checkedNumber === 0) {
      return props.defaultLabel;
    }

    if (checkedNumber === 1) {
      return "1 Account";
    }

    return checkedNumber + " Accounts";
  }
  return (
    <MultiselectDropdown
      {...props}
      selectableItemType={SelectableAccount}
      label={label}
      selectAllLabel={"All accounts"}
    />
  );
};

AccountsDropdown.propTypes = {
  className: PropTypes.string,
  defaultLabel: PropTypes.string,
  items: PropTypes.array,
  groupBy: PropTypes.func,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  allowEmpty: PropTypes.bool,
  sortBy: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  showBalancesAndTimeStamp: PropTypes.bool,
  isAccountDropdownSelectorFocus: PropTypes.bool,
  onOpen: PropTypes.func,
};

AccountsDropdown.defaultProps = {
  className: "",
  groupBy: undefined,
  onChange: undefined,
  onCancel: undefined,
  defaultLabel: "Select Accounts",
  items: [],
  allowEmpty: false,
  sortBy: "info",
  showBalancesAndTimeStamp: false,
  isAccountDropdownSelectorFocus: false,
  onOpen: undefined,
};

export default AccountsDropdown;
