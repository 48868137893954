import PropTypes from "prop-types";
import React from "react";
import CashFlowSummaryContainer from "components/cashFlowSummary2/CashFlowSummaryContainer";
import BudgetingSummaryContainer from "components/budgetingSummary/BudgetingSummaryContainer";
import WidgetRowZeroState from "components/dashboard/WidgetRowZeroState";
import getZeroStateUserMessage from "libs/pcap/utils/getZeroStateUserMessage";
import MarRetirementChartWidget from "./MarRetirementChartWidget";

const DISPLAY_LOCATION__CASH_FLOW = "#/dashboard/cash-flow";
const DISPLAY_LOCATION__BUDGETING = "#/dashboard/cash-flow/spending";

export default class SecondRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let zeroStateMessage = getZeroStateUserMessage(
      this.props.messages,
      DISPLAY_LOCATION__BUDGETING
    );

    // display retirement planner chart widget if useUnifiedMar feature flag is true and
    // the user has not yet created a Retirement Plan and only has manual accounts linked (FCDX-42676)
    if (
      this.props.useUnifiedMar &&
      this.props.firstTimeForecastExperience &&
      this.props.numOfExternalAccounts === 0
    ) {
      const { age, retirementAge } = this.props.person;
      return (
        <div className="dashboard__row">
          <div className="dashboard__cell">
            <div className="dashboard__item dashboard__item--tall-3/2x">
              <div className="planner__retirement-plan-chart dashboard__">
                <MarRetirementChartWidget
                  age={age}
                  retirementAge={retirementAge}
                  isMarried={false}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (zeroStateMessage) {
      return (
        <div className="dashboard__row">
          <div className="dashboard__cell">
            <div className="dashboard__item dashboard__item--tall-3/2x">
              <div className="pc-layout pc-layout--center">
                <div className="pc-layout__item pc-u-1/2">
                  <div className="dashboard-second-row__zero-state" />
                </div>
                <div className="pc-layout__item pc-u-1/2">
                  <WidgetRowZeroState
                    message={zeroStateMessage}
                    widgetName="Dashboard Cashflow and Budgeting Row"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="dashboard__row">
        <div className="dashboard__cell pc-u-1/2">
          <div className="dashboard__item dashboard__item--tall-3/2x">
            <BudgetingSummaryContainer
              ace={this.props.dashboardMessages[DISPLAY_LOCATION__BUDGETING]}
              legendLayoutClassName=""
              chartLayoutClassName=""
            />
          </div>
        </div>
        <div className="dashboard__cell pc-u-1/2">
          <div className="dashboard__item dashboard__item--tall-3/2x">
            <CashFlowSummaryContainer
              ace={this.props.dashboardMessages[DISPLAY_LOCATION__CASH_FLOW]}
            />
          </div>
        </div>
      </div>
    );
  }
}

SecondRow.propTypes = {
  messages: PropTypes.array,
  dashboardMessages: PropTypes.object,
  person: PropTypes.object,
  useUnifiedMar: PropTypes.bool,
  firstTimeForecastExperience: PropTypes.bool,
  numOfExternalAccounts: PropTypes.number,
};

SecondRow.defaultProps = {
  messages: [],
  dashboardMessages: {},
  person: {},
  useUnifiedMar: false,
  firstTimeForecastExperience: false,
  numOfExternalAccounts: 0,
};
