/* eslint-disable camelcase */
/* eslint-disable no-magic-numbers */
/* eslint-disable sonarjs/cognitive-complexity */
import React from "react";
import ReactDOM from "react-dom";
import eventBus from "eventBus";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import "FormSerializer";
import Services from "services";
import moment from "moment";
import analytics from "analytics";
import Mixpanel from "mixpanel";
import parseResponseErrors from "libs/pcap/utils/response";
import abFrameworkProperties, {
  getAbFrameworkData,
  getGtmConstant,
} from "libs/pcap/utils/abFrameworkProperties";
import isExternalMadLibsRegFlow from "libs/pcap/utils/isExternalMadLibsRegFlow";
import fireGoogleTagManagerEvent from "libs/pcap/utils/fireGoogleTagManagerEvent";
import {
  showMadLibsInterjectionReviewModal,
  prefetchNextWeeksAvailabilities,
  isPersonCanadian,
} from "libs/pcap/utils/customAppointmentSchedulerUtils";
import "tooltip";
import objectPath from "object-path";
import { isEmpty } from "underscore";
import {
  getABTestGroupByTargetedUserGuidIds,
  AB_TEST_GROUP_A,
  AB_TEST_GROUP_B,
} from "libs/pcap/utils/getABTestGroupForCurrentUser";
import ShortInfoGatheringPage2 from "components/informationGathering/shortIG2/ShortInfoGatheringPage2";
import { isMobileDevice } from "../../../libs/pcap/utils/isMobileDevice";
import ManageYourInvestment from "./PersonalizedOnboarding/ManageYourInvestment/ManageYourInvestment";

const SHIFT_KEY_CODE = 16;
const DEFAULT_QQ_THRESHOLD_AMOUNT = 135000;
const MAX_QQ_THRESHOLD_AMOUNT = 4999999;
const DEFAULT_FIN_PLAN_OFFER_THRESHOLD_AMOUNT = 100000;
const MAX_NUMBER_OF_API_CHECKS = 2;
const MILLISECOND_IN_A_SECOND = 1000;
const API_CHECK_DELAY = 1000;
const component = "First Use";
const ONE_HUNDRED_K_THRESHOLD = 100000;
const TWO_HUNDRED_AND_FIFTY_K_THRESHOLD = 250000;
const ONE_MILLION_THRESHOLD = 1000000;
const FIVE_MILLION_THRESHOLD = 5000000;
const DEFAULT_MIN_RETIREMENT_AGE = 55;
const DEFAULT_MIN_INVESTIBLE_ASSET_AMOUNT = 15000;
const APPOINTMENT_MIN_QQ_THRESHOLD_AMOUNT = 135000;
const MENU_ACTION_SEPARATOR = ".js-menu-action-separator";
const FIRST_USE_SKIP_FOR_NOW = ".js-first-use-skip-for-now";
const DEFAULT_MAD_LIBS_CONFIG = {
  title: "Tell us about yourself",
  intro: "This helps us give you better advice to keep you on track",
  fieldLabels: {
    name: { pre: "My name is" },
    age: { pre: "and I am", post: "years old.<br/>" },
    retirementAge: { pre: "I plan to retire at age", post: "." },
    assetValue: {
      pre: "So far, my household <br/>savings are $",
      post: "in cash, investments<br/>and retirement accounts.",
    },
    tooltipContent:
      "For households with joint finances include your spouse/partner accounts for holistic analysis. Do not include the value of property such as homes, vehicles, or collectibles.",
  },
};
const FIRST_USE_SIGNOUT_EVENT = "firstUseSignOut:clicked";
const CLOSE_IG = "CLOSE_IG_FORM--short2";
export const qualificationReasonMap = (investibleAsset) => {
  if (investibleAsset >= 50000 && investibleAsset < 75000)
    return "USER_ACE_IAV_50K_QUALIFIED";
  if (investibleAsset >= 75000 && investibleAsset < 110000)
    return "USER_ACE_IAV_75K_QUALIFIED";
  if (investibleAsset >= 110000 && investibleAsset < 135000)
    return "USER_ACE_IAV_110K_QUALIFIED";
  if (investibleAsset >= 135000) return "USER_ACE_IAV_QUALIFIED";
  return null;
};

export const getFeatureFlagData = (marketingABTesting, featureData) => {
  const featureDetails =
    marketingABTesting?.AB_TEST_CONFIGURATION?.featureDetails;
  const featureFlagData =
    featureDetails && featureDetails[featureData]
      ? featureDetails[featureData]
      : null;
  return featureFlagData && featureFlagData?.isEnabled
    ? getABTestGroupByTargetedUserGuidIds(featureFlagData.targetedUserGuidIds)
    : null;
};

export default Backbone.View.extend(
  {
    className: "firstUseQuestionnaire",
    events: {
      "submit form": "submitHandler",
      "focus input": "removeErrors",
      "focus select": "removeErrors",
    },
    initialize: function (options) {
      this.options = options;
      this.formDataCopy = {};
      fireGoogleTagManagerEvent("View Mad Libs Form");
      this.elementContainer = options.elementContainer;
      var renderDataAppend = options.renderDataAppend || {};
      const onboarding3Test = getAbFrameworkData("onboarding3Test") || {};
      this.onboarding3Test = onboarding3Test;
      this.setReferralOnboardingTest(
        options.marketingABTesting,
        options.funnelAttributes
      );

      this.setMintQ(options.marketingABTesting, options.funnelAttributes);

      if (
        options?.funnelAttributes?.marketingId &&
        options.funnelAttributes.marketingId === "pcap_source=WWW--USERFLOW"
      )
        this.setWWWUserFlowTest(options.marketingABTesting);

      const personalizedOnboarding =
        options.marketingABTesting?.AB_TEST_CONFIGURATION?.featureDetails
          ?.personalizedOnboarding;
      this.personalizedOnboarding = personalizedOnboarding?.isEnabled
        ? getABTestGroupByTargetedUserGuidIds(
            personalizedOnboarding.targetedUserGuidIds
          )
        : null;

      this.skipAppointmentSchedulerBeforeAggregation = getFeatureFlagData(
        options.marketingABTesting,
        "skipAppointmentSchedulerBeforeAggregation"
      );

      this.enableAppointmentSchedulerAfterAggregation =
        window.sessionStorage.getItem(
          "enableAppointmentSchedulerAfterAggregation"
        ) === "1";

      this.sharedTrackedEventCustomData = {
        component,
        isGettingStartedV2Flow: this.options?.isGettingStartedV2Flow,
        firstUseMadLibsTestName: onboarding3Test?.madLibs?.testName || "",
      };

      this.getAvailabilitiesPromise = new Promise((resolve, reject) => {
        this.resolveGetAvailabilitiesPromise = resolve;
        this.rejectGetAvailabilitiesPromise = reject;
      });

      Services.Person.get(
        function (err, response) {
          this.person = (response && response.spData) || {};
          if (options.isExternalMadLibsRegFlow) {
            this.shortCircuitToExternalMadLibsRegFlow(this.person);
            return;
          }
          const data = Object.assign(
            { madLibs: DEFAULT_MAD_LIBS_CONFIG },
            this.person,
            renderDataAppend
          );
          $(FIRST_USE_SKIP_FOR_NOW).hide();
          $(MENU_ACTION_SEPARATOR).hide();
          this.render(data);
          this.$('[data-toggle="tooltip"]').tooltip();
          this.elementContainer.html(this.$el);
          this.$(".js-first-name").focus();
          // sign up another user
          if (!window.isQuestionnaireAnswered) {
            window.localStorage.removeItem("onInformationGatheringPage");
            window.localStorage.removeItem("onTollGateOffRampPage");
            window.localStorage.removeItem("onUserGoalsPage");
          }
          // sign in with same user
          if (window.localStorage.getItem("onInformationGatheringPage")) {
            const userInputInvestableAssets = objectPath.get(
              this.person,
              "investableAssets.userInputInvestableAssets"
            );
            const { madLibsQQInterjection } = this.getMadLibsQQInterjectionData(
              this.userInputInvestableAssets
            );
            this.onMadLibsQuestionQualifiedSubmit({
              madLibsQQInterjection,
              person: this.person,
              userInputInvestableAssets,
            });
          }
        }.bind(this)
      );

      Mixpanel.trackEvent(
        "View Mad Libs Form",
        this.sharedTrackedEventCustomData
      );
      this.enableAdvisorShortcut();
      eventBus.on(FIRST_USE_SIGNOUT_EVENT, () => {
        Mixpanel.trackEvent("Sign Out", {
          component: "Madlibs Form Page",
        });
      });
    },
    getMadLibsQQInterjectionData: function (userInputInvestableAssets) {
      const madLibsQQInterjection =
        objectPath.get(
          abFrameworkProperties,
          "firstuse.madLibsQQInterjection"
        ) || {};

      const isUserQQ =
        userInputInvestableAssets >=
          (madLibsQQInterjection.qqThresholdAmount ||
            DEFAULT_QQ_THRESHOLD_AMOUNT) &&
        userInputInvestableAssets <=
          (madLibsQQInterjection.maxQqThresholdAmount ||
            MAX_QQ_THRESHOLD_AMOUNT);

      let showMadLibsQQInterjection = Boolean(
        madLibsQQInterjection && madLibsQQInterjection.show && isUserQQ
      );

      if (this.isMintQUser && showMadLibsQQInterjection)
        showMadLibsQQInterjection =
          userInputInvestableAssets >= this.mintQThresholdAmount &&
          userInputInvestableAssets <= this.maxMintQThresholdAmount;

      return {
        madLibsQQInterjection,
        showMadLibsQQInterjection,
        isUserQQ,
      };
    },

    shortCircuitToExternalMadLibsRegFlow: function (person) {
      const isCanadian = isPersonCanadian(person);
      const userInputInvestableAssets = objectPath.get(
        person,
        "investableAssets.userInputInvestableAssets"
      );
      const { madLibsQQInterjection, showMadLibsQQInterjection } =
        this.getMadLibsQQInterjectionData(
          userInputInvestableAssets,
          person.age
        );

      if (showMadLibsQQInterjection && !isCanadian) {
        this.onMadLibsQuestionQualifiedSubmit({
          madLibsQQInterjection,
          person,
          userInputInvestableAssets,
        });
      }
    },

    /**
     * Allow advisors to hold down the shift key and click on the
     * top nav header to jump directly to Enrollment
     */
    enableAdvisorShortcut: function () {
      var shiftIsPressed = false;
      $(document)
        .on("keydown.advisorshortcut", function (event) {
          if (event.which === SHIFT_KEY_CODE) {
            shiftIsPressed = true;
          }
        })
        .on("keyup.advisorshortcut", function () {
          shiftIsPressed = false;
        });
      $(".js-first-use-header").on("click.advisorshortcut", () => {
        if (shiftIsPressed) {
          Mixpanel.trackEvent(
            "Shift + Click Header to Go Directly to Enrollment",
            this.sharedTrackedEventCustomData
          );
          shiftIsPressed = false;
          window.location.href =
            IS_EMPOWER && !IS_ADVISOR
              ? ENROLL_URL_PREFIX
              : "/page/enrollment/start";
        }
      });
    },

    removeErrors: function (e) {
      $(e.currentTarget).removeClass("errorMessage");
      //special case birthday
      $(e.currentTarget).siblings().removeClass("errorMessage");
    },

    submitHandler: function (e) {
      if (e) {
        e.preventDefault();
      }
      const formData = this.$(".js-first-use-questions-form").serializeObject();
      if (_.isFunction(this.sanitizeMaskedInputs)) {
        this.sanitizeMaskedInputs(formData);
      }
      const contextualPromptData = {
        minRetirementAge:
          this.onboarding3Test.minRetirementAge || DEFAULT_MIN_RETIREMENT_AGE,
        minInvestableAsset:
          this.onboarding3Test.minInvestableAsset ||
          DEFAULT_MIN_INVESTIBLE_ASSET_AMOUNT,
      };

      if (
        this.validate(
          formData,
          contextualPromptData,
          this.madLibsInputRepromptTest
        )
      ) {
        this.onValidFormSubmit(formData);
        if (
          this.personalizedOnboarding &&
          this.personalizedOnboarding === AB_TEST_GROUP_A
        ) {
          Mixpanel.updateProfile(
            { TestGroupID: "PersonalizedOnboarding_Test" },
            null,
            true
          );
          Mixpanel.trackEvent("PersonalizedOnboarding_Test", {
            component: "First Use",
          });
        } else {
          Mixpanel.updateProfile(
            { TestGroupID: "PersonalizedOnboarding_Control" },
            null,
            true
          );
          Mixpanel.trackEvent("PersonalizedOnboarding_Control", {
            component: "First Use",
          });
        }
        window.sessionStorage.setItem("FirstName", formData?.name?.firstName);
      } else {
        Mixpanel.trackEvent(
          "First Use Quest Submit Attempt",
          Object.assign({}, formData, this.sharedTrackedEventCustomData)
        );
      }
    },

    onValidFormSubmit: function (formData) {
      const userAge = formData.age;
      if (_.isFunction(this.transformFormData)) {
        this.transformFormData(formData);
      }
      const userInputInvestableAssets = parseInt(
        formData.investableAssetsAmount,
        10
      );
      const { madLibsQQInterjection, showMadLibsQQInterjection, isUserQQ } =
        this.getMadLibsQQInterjectionData(userInputInvestableAssets, userAge);
      const financialPlanOffer =
        (abFrameworkProperties && abFrameworkProperties.finPlanOffer) || {};
      const isFinPlanOfferEligible =
        parseInt(formData.investableAssetsAmount, 10) <=
        (financialPlanOffer.thresholdAmount ||
          DEFAULT_FIN_PLAN_OFFER_THRESHOLD_AMOUNT);
      const showFinancialPlanOffer =
        financialPlanOffer.isEnabled && isFinPlanOfferEligible;

      window.AppRouter.addPreloader(null, { alwaysCentered: true });
      const qualificationReason =
        formData.investableAssetsAmount < APPOINTMENT_MIN_QQ_THRESHOLD_AMOUNT &&
        formData.investableAssetsAmount >=
          madLibsQQInterjection.qqThresholdAmount
          ? qualificationReasonMap(Number(formData.investableAssetsAmount))
          : null;
      this.updatePerson(formData, {
        showMadLibsQQInterjection,
        showFinancialPlanOffer,
        qualificationReason,
      }).then((person) => {
        window.AppRouter.removePreloader();
        window.isQuestionnaireAnswered = true;
        const isCanadian = isPersonCanadian(person);
        if (showMadLibsQQInterjection && !isCanadian) {
          this.onMadLibsQuestionQualifiedSubmit({
            madLibsQQInterjection,
            person,
            userInputInvestableAssets,
          });
        } else if (showFinancialPlanOffer && !isCanadian) {
          // User is not Questionnaire Qualified
          window.AppRouter.financialPlanOffer();
        } else if (isMobileDevice()) {
          Mixpanel.trackEvent(
            "Redirect Mobile Page",
            { component: "First Use" },
            {
              // eslint-disable-next-line camelcase
              send_immediately: true,
              callback: () => {
                window.location.replace("/static/html/redirectmobile/");
              },
            }
          );
        } else {
          eventBus.trigger("firstUseQuestionnaire:completed", formData);
        }

        analytics.sendPageView("/firstuse/submit-profile");
        if (_.isFunction(this.onPostSubmit)) {
          this.onPostSubmit();
        }
        if (!isEmpty(this.formDataCopy)) {
          this.prePromptData = this.getPrePromptData(
            formData,
            this.formDataCopy
          );
        }
        this.updateProfileWithFirstUseQuestionnaireData(
          formData,
          this.prePromptData
        );

        Mixpanel.trackEvent(
          "First Use Quest Submit Success",
          Object.assign(
            {},
            formData,
            this.prePromptData,
            { mad_libs_qq: isUserQQ },
            this.sharedTrackedEventCustomData
          )
        );
        if (isUserQQ) {
          // Fire GTM event so we could trigger a Hotjar video recording on an as-needed basis
          fireGoogleTagManagerEvent("ON_MAD_LIBS_FORM_QQ_SUBMIT");
        }

        if (showMadLibsQQInterjection && typeof window.hj !== "undefined") {
          window.hj("tagRecording", ["QL=true"]);
        }
      });
    },

    getPrePromptData: function (formData, formDataCopy) {
      const data = {};
      const currentYear = new Date().getFullYear();
      const age = currentYear - formData.birthday.year;
      if (
        formData.name.firstName !==
        $.pcap.capitalizeFirstLetter(formDataCopy.name.firstName)
      ) {
        data.preprompt_firstName = formDataCopy.name.firstName;
      }
      if (age !== parseInt(formDataCopy.age, 10)) {
        data.preprompt_age = formDataCopy.age;
      }
      if (formData.retirementAge !== formDataCopy.retirementAge) {
        data.preprompt_retirementAge = formDataCopy.retirementAge;
      }
      if (
        formData.investableAssetsValue !== formDataCopy.investableAssetsValue
      ) {
        data.preprompt_q_investable_assets_amount =
          formDataCopy.investableAssetsValue;
      }
      data.preprompt_changed = !isEmpty(data);
      return data;
    },
    onMadLibsQuestionQualifiedSubmit: function ({
      madLibsQQInterjection,
      person,
      userInputInvestableAssets,
    }) {
      // Save the following for appointment scheduler
      if (!this.enableAppointmentSchedulerAfterAggregation) {
        window.sessionStorage.setItem(
          "firstUsePersonData",
          JSON.stringify(person)
        );
        window.sessionStorage.setItem(
          "madLibsQQInterjection",
          JSON.stringify(madLibsQQInterjection)
        );
        const appointmentConfirmationForm =
          madLibsQQInterjection.appointmentConfirmationForm || {};
        if (
          appointmentConfirmationForm.show &&
          userInputInvestableAssets >=
            appointmentConfirmationForm.investableAssetsAmountThreshold
        ) {
          window.sessionStorage.setItem("showAppointmentConfirmationForm", "1");
        }
      }
      const isUserFromWwwRegFlow = isExternalMadLibsRegFlow();
      if (
        !window.localStorage.getItem("onTollGateOffRampPage") &&
        ((window.isRegistrationSession &&
          window.isQuestionnaireAnswered &&
          window.showFirstUse &&
          !window.skipLinkAccount) ||
          !isUserFromWwwRegFlow ||
          (isUserFromWwwRegFlow &&
            getGtmConstant("MADLIBS_SHOW_PAGE2_FORM_FOR_WWW_REG_FLOW")))
      ) {
        eventBus.on(CLOSE_IG, () => {
          if (
            this.skipAppointmentSchedulerBeforeAggregation ===
              AB_TEST_GROUP_A &&
            !isMobileDevice()
          ) {
            window.AppRouter.removePreloader();
            eventBus.trigger("firstUseQuestionnaire:completed");
            window.localStorage.removeItem("onInformationGatheringPage");
            Mixpanel.updateProfile(
              {
                TestGroupID:
                  "Skip_Appointment_Scheduler_Before_Aggregation_Test",
              },
              null,
              true
            );
            Mixpanel.trackEvent(
              "Skip_Appointment_Scheduler_Before_Aggregation_Test"
            );
          } else {
            window.AppRouter.addPreloader(null, { alwaysCentered: true });
            this.getAvailabilitiesPromise
              .then(this.onResolveGetAvailabilitiesPromise.bind(this))
              .catch(this.onRejectGetAvailabilitiesPromise.bind(this));
            Mixpanel.updateProfile(
              {
                TestGroupID:
                  "Skip_Appointment_Scheduler_Before_Aggregation_Control",
              },
              null,
              true
            );
            Mixpanel.trackEvent(
              "Skip_Appointment_Scheduler_Before_Aggregation_Control"
            );
          }
        });
        $(FIRST_USE_SKIP_FOR_NOW).hide();
        eventBus.off(FIRST_USE_SIGNOUT_EVENT);
        this.elementContainer.hide();
        if (
          this.personalizedOnboarding &&
          this.personalizedOnboarding === AB_TEST_GROUP_A &&
          !isMobileDevice()
        ) {
          this.ManageYourInvestmentPage(person);
        } else {
          this.informationGatheringPage(person);
        }
      } else {
        window.AppRouter.addPreloader(null, { alwaysCentered: true });
        eventBus.off(FIRST_USE_SIGNOUT_EVENT);
        this.getAvailabilitiesPromise
          .then(this.onResolveGetAvailabilitiesPromise.bind(this))
          .catch(this.onRejectGetAvailabilitiesPromise.bind(this));
      }
      this.attemptStartTime = new Date();
      if (
        !(
          this.skipAppointmentSchedulerBeforeAggregation === AB_TEST_GROUP_A &&
          !isMobileDevice()
        )
      ) {
        this.attemptMadLibsQQInterjection({
          isSilentMode: true,
        });
        Mixpanel.updateProfile(
          {
            TestGroupID:
              "Skip_Appointment_Scheduler_Before_Aggregation_Control",
          },
          null,
          true
        );
        Mixpanel.trackEvent(
          "Skip_Appointment_Scheduler_Before_Aggregation_Control"
        );
      }
    },
    getAvailabilitiesForAfterFirstAggregation: function () {
      Services.Appointment.getAvailabilities(
        {
          appointmentType: "INITIAL_CONSULTATION",
          duration: 30,
          limit: 3,
        },
        (error, response) => {
          const errors = parseResponseErrors(error, response);
          if (
            !errors &&
            response?.spData?.appointments &&
            !isEmpty(response.spData.appointments)
          ) {
            const availableSpots = response.spData.appointments.filter(
              (a) => a.availabilityStatus === "FREE"
            );
            if (availableSpots.length > 0) {
              // Save for used by Mad Libs Interjection
              window.sessionStorage.setItem(
                "firstUseGetAvailabilitiesResponse",
                JSON.stringify(availableSpots)
              );
              if (showMadLibsInterjectionReviewModal()) {
                prefetchNextWeeksAvailabilities();
              }
            }
          } else if (
            !this.getAvailCheckCount ||
            this.getAvailCheckCount < MAX_NUMBER_OF_API_CHECKS
          ) {
            setTimeout(
              this.attemptMadLibsQQInterjection.bind(this),
              API_CHECK_DELAY
            );
            this.getAvailCheckCount = this.getAvailCheckCount || 0;
            this.getAvailCheckCount++;
          }
        }
      );
    },
    onResolveGetAvailabilitiesPromise: function () {
      window.AppRouter.removePreloader();
      window.AppRouter.tollGateOffRampPage(
        this.referralOnboardingTest,
        this.referrerName
      );
      if (this.referralOnboardingTest === AB_TEST_GROUP_A) {
        Mixpanel.updateProfile(
          { TestGroupID: "Ref_Onboarding_Var2_Control" },
          null,
          true
        );
        Mixpanel.trackEvent("Ref_Onboarding_Var2_Control");
      } else if (this.referralOnboardingTest === AB_TEST_GROUP_B) {
        Mixpanel.updateProfile(
          { TestGroupID: "Ref_Onboarding_Var2_Test" },
          null,
          true
        );
        Mixpanel.trackEvent("Ref_Onboarding_Var2_Test");
      } else {
        Mixpanel.updateProfile(
          { TestGroupID: "ScheduleACall_vs_ChooseYourAdventure_Var2" },
          null,
          true
        );
        Mixpanel.trackEvent("ScheduleACall_vs_ChooseYourAdventure_Var2");
      }
    },
    onRejectGetAvailabilitiesPromise: function () {
      window.AppRouter.removePreloader();
      eventBus.trigger("firstUseQuestionnaire:completed");
    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    attemptMadLibsQQInterjection: function (options = { isSilentMode: false }) {
      if (!options.isSilentMode) {
        window.AppRouter.addPreloader(null, { alwaysCentered: true });
      }
      Services.Appointment.getAvailabilities(
        {
          appointmentType: "INITIAL_CONSULTATION",
          duration: 30,
          limit: 3,
        },
        (error, response) => {
          const errors = parseResponseErrors(error, response);
          const timeDiffInSeconds = Math.round(
            (new Date() - this.attemptStartTime) / MILLISECOND_IN_A_SECOND
          );
          if (
            !errors &&
            response?.spData?.appointments &&
            !isEmpty(response.spData.appointments)
          ) {
            const availableSpots = response.spData.appointments.filter(
              (a) => a.availabilityStatus === "FREE"
            );
            if (availableSpots.length > 0) {
              // Save for used by Mad Libs Interjection
              window.sessionStorage.setItem(
                "firstUseGetAvailabilitiesResponse",
                JSON.stringify(availableSpots)
              );
              window.AppRouter.removePreloader();
              Mixpanel.trackEvent(
                "View Mad Libs QQ Interjection",
                Object.assign(
                  {
                    wait_time: `${timeDiffInSeconds} seconds`,
                  },
                  this.sharedTrackedEventCustomData
                )
              );

              this.resolveGetAvailabilitiesPromise();

              if (showMadLibsInterjectionReviewModal()) {
                prefetchNextWeeksAvailabilities();
              }
            } else {
              window.AppRouter.removePreloader();
              this.rejectGetAvailabilitiesPromise();
            }
          } else if (
            !this.getAvailCheckCount ||
            this.getAvailCheckCount < MAX_NUMBER_OF_API_CHECKS
          ) {
            setTimeout(
              this.attemptMadLibsQQInterjection.bind(this, options),
              API_CHECK_DELAY
            );
            this.getAvailCheckCount = this.getAvailCheckCount || 0;
            this.getAvailCheckCount++;
          } else {
            Mixpanel.trackEvent(
              "Skip Mad Libs QQ Interjection Since It Took Too Long",
              Object.assign(
                { wait_time: `${timeDiffInSeconds} seconds` },
                this.sharedTrackedEventCustomData
              )
            );
            window.AppRouter.removePreloader();
            this.rejectGetAvailabilitiesPromise();
          }
        }
      );
    },

    validate: function (formData) {
      var errorClass = "errorMessage";
      if (
        !formData.name.firstName ||
        !formData.name.firstName.match(
          this.constructor.REGEX_FIRST_NAME_VALIDATOR
        )
      ) {
        this.$('[name="name[firstName]"]').addClass(errorClass);
      }
      if (!formData.investableAssetsValue) {
        this.$('[name="investableAssetsValue"]').addClass(errorClass);
      }
      if (!this.validateBirthday(formData.birthday)) {
        this.$(
          '[name="birthday[month]"], [name="birthday[day]"], [name="birthday[year]"]'
        ).addClass(errorClass);
      }
      if (!formData.userRiskTolerance) {
        this.$('[name="userRiskTolerance"]').addClass(errorClass);
      }
      if (!formData.retirementAge) {
        this.$('[name="retirementAge"]').addClass(errorClass);
      }
      return !this.$("." + errorClass).length;
    },

    updatePerson: function (
      formData,
      {
        showMadLibsQQInterjection = false,
        showFinancialPlanOffer = false,
        qualificationReason = null,
      } = {}
    ) {
      this.$(".js-submit-button").prop("disabled", true).val("Saving");
      if (formData?.name?.firstName) {
        formData.name.firstName = $.pcap.capitalizeFirstLetter(
          formData.name.firstName
        );
      }
      const params = qualificationReason
        ? {
            personId: this.person.id,
            source: "First Use",
            person: JSON.stringify(formData),
            qualificationReason: qualificationReason,
          }
        : {
            personId: this.person.id,
            source: "First Use",
            person: JSON.stringify(formData),
          };
      analytics.sendPageView("/welcomePopup-Complete");
      return new Promise((resolve) => {
        Services.Person.update(params, function (err, response) {
          if (response.spHeader.success !== true) {
            analytics.sendEngineeringEvent(
              "Error",
              "First Use Questionnaire Submission Error:" +
                JSON.stringify(params)
            );
          }
          resolve(response.spData);
        });
        if (!showMadLibsQQInterjection && !showFinancialPlanOffer) {
          resolve(); // No need to wait for Person.update() to finish when we're not showing the Mad Libs QQ interjection or Financial Plan Offer
        }
      });
    },

    validateBirthday: function (obj) {
      var minBirthYear = 1900,
        birthday = moment([obj.year, obj.month - 1, obj.day, 0, 0, 0]),
        today = moment();
      return (
        birthday.isValid() &&
        birthday.year() >= minBirthYear &&
        today.diff(birthday, "days") >= 0
      );
    },

    updateProfileWithFirstUseQuestionnaireData: function (
      formData,
      prePromptData
    ) {
      formData = formData || {};
      var profileData = {};
      const currentYear = new Date().getFullYear();
      if (formData.name && formData.name.firstName) {
        profileData.$first_name = formData.name.firstName;
      }
      if (formData.birthday && formData.birthday.year) {
        profileData.q_reg_age = currentYear - formData.birthday.year;
        profileData.q_birth_year = parseInt(formData.birthday.year, 10);
      }
      if (formData.retirementAge) {
        profileData.q_retirement_age = parseInt(formData.retirementAge, 10);
      }
      if (formData.investableAssetsValue) {
        profileData.q_investable_assets_range =
          this.mapInvestableAssetsAmountToRange(formData.investableAssetsValue);
        profileData.qq =
          formData.investableAssetsValue >= DEFAULT_QQ_THRESHOLD_AMOUNT;
      }
      if (formData.investableAssetsAmount) {
        profileData.q_saved_amount = parseFloat(
          formData.investableAssetsAmount
        );
      }
      if (!isEmpty(prePromptData)) {
        Object.assign(profileData, prePromptData);
      }
      Mixpanel.updateProfile(profileData);
    },

    mapInvestableAssetsAmountToRange: function (investableAssets) {
      investableAssets = Number(investableAssets);
      var investableAssetsRange;

      if (investableAssets < ONE_HUNDRED_K_THRESHOLD) {
        investableAssetsRange = "LESS_THAN_100K";
      } else if (investableAssets <= TWO_HUNDRED_AND_FIFTY_K_THRESHOLD) {
        investableAssetsRange = "_100K_TO_250K";
      } else if (investableAssets <= ONE_MILLION_THRESHOLD) {
        investableAssetsRange = "_250K_TO_1MILLION";
      } else if (investableAssets <= FIVE_MILLION_THRESHOLD) {
        investableAssetsRange = "_1MILLION_TO_5MILLION";
      } else {
        investableAssetsRange = "MORE_THAN_5MILLION";
      }
      return investableAssetsRange;
    },
    remove: function () {
      $(document).off("keydown.advisorshortcut keyup.advisorshortcut");
      $(".js-first-use-header").off("click.advisorshortcut");
    },

    setReferralOnboardingTest: function (marketingABTesting, funnelAttributes) {
      const isClientReferral = funnelAttributes?.isClientReferral;
      const enableReferralOnboarding =
        marketingABTesting?.MARKETING_AB_TEST_REFERRAL_ONBOARDING?.isEnabled;
      this.referrerName = funnelAttributes?.referrerName;
      this.referralOnboardingTest = "";

      if (enableReferralOnboarding && isClientReferral) {
        const assignedGroup = getABTestGroupByTargetedUserGuidIds(
          marketingABTesting?.MARKETING_AB_TEST_REFERRAL_ONBOARDING
            ?.featureDetails?.targetedUserGuidIds
        );
        this.referralOnboardingTest = assignedGroup
          ? assignedGroup
          : AB_TEST_GROUP_B;
      }
      window.referralOnboardingTest = this.referralOnboardingTest;
    },

    informationGatheringPage: function (person) {
      if (!this.informationGatheringView) {
        var onSubmit = function () {
          this.removeInformationGatheringView();
          eventBus.trigger(CLOSE_IG);
        }.bind(this);
        const props = {
          person: person,
          onSubmit: onSubmit,
        };
        this.informationGatheringView = document.createElement("div");
        $(".moduleContainer").get(0).append(this.informationGatheringView);

        ReactDOM.render(
          React.createElement(ShortInfoGatheringPage2, props),
          this.informationGatheringView
        );
        window.localStorage.removeItem("onTollGateOffRampPage");
        window.localStorage.setItem("onInformationGatheringPage", "1");
        eventBus.on(FIRST_USE_SIGNOUT_EVENT, () => {
          Mixpanel.trackEvent("Sign Out", {
            component: "Information Gathering Page",
          });
        });
      }
    },
    removeInformationGatheringView: function () {
      if (this.informationGatheringView) {
        ReactDOM.unmountComponentAtNode(this.informationGatheringView);
        this.informationGatheringView.parentNode.removeChild(
          this.informationGatheringView
        );
        this.informationGatheringView = null;
        eventBus.off(FIRST_USE_SIGNOUT_EVENT);
      }
    },

    ManageYourInvestmentPage: function (person) {
      if (!this.ManageYourInvestmentView) {
        const submitHandler = function () {
          this.removeManageYourInvestmentView();
          eventBus.trigger(CLOSE_IG);
        }.bind(this);
        const props = {
          person: person,
          onSubmit: submitHandler,
        };
        this.ManageYourInvestmentView = document.createElement("div");
        $(".moduleContainer").get(0).append(this.ManageYourInvestmentView);

        ReactDOM.render(
          React.createElement(ManageYourInvestment, props),
          this.ManageYourInvestmentView
        );
        window.localStorage.removeItem("onTollGateOffRampPage");
        window.localStorage.setItem("onInformationGatheringPage", "1");
        eventBus.on(FIRST_USE_SIGNOUT_EVENT, () => {
          Mixpanel.trackEvent("Sign Out", {
            component: "Information Gathering Page",
          });
        });
      }
    },

    removeManageYourInvestmentView: function () {
      if (this.ManageYourInvestmentView) {
        ReactDOM.unmountComponentAtNode(this.ManageYourInvestmentView);
        this.ManageYourInvestmentView.parentNode.removeChild(
          this.ManageYourInvestmentView
        );
        this.ManageYourInvestmentView = null;
        eventBus.off(FIRST_USE_SIGNOUT_EVENT);
      }
    },

    setWWWUserFlowTest: function () {
      Mixpanel.updateProfile(
        { TestGroupID: "FB_To_WebReg_Test_WebApp" },
        null,
        true
      );
      Mixpanel.trackEvent(
        "FB_To_WebReg_Test_WebApp",
        { component: "First Use" },
        {
          // eslint-disable-next-line camelcase
          send_immediately: true,
        }
      );
      window.sessionStorage.setItem(
        "MOBILE_APP_DOWNLOAD_IDENTIFIER",
        `WWWUserFlow`
      );
    },

    setMintQ: function (marketingABTesting, funnelAttributes) {
      const mintQ =
        marketingABTesting?.AB_TEST_CONFIGURATION?.featureDetails?.mintQ;
      const isMintQEnabled = mintQ?.isEnabled;
      const mintQThresholdAmount = mintQ?.mintQThresholdAmount;
      const maxMintQThresholdAmount = mintQ?.maxMintQThresholdAmount;

      const isMintQUser = Boolean(
        isMintQEnabled && funnelAttributes?.marketingId === "pcap_source=MINT"
      );
      this.isMintQUser = isMintQUser;
      this.mintQThresholdAmount = mintQThresholdAmount;
      this.maxMintQThresholdAmount = maxMintQThresholdAmount;
    },
  },

  {
    REGEX_FIRST_NAME_VALIDATOR: /^[a-zA-Z][a-zA-Z\s'-]*$/, // First character must be an alphabet. Allow white spaces, dashes and apostrophes after that
  }
);
