/* eslint-disable no-magic-numbers, sonarjs/cognitive-complexity */
import React from "react";
import PropTypes from "prop-types";
import Column from "libs/pcap/table/Column";
import Input from "components/common/form/Input";
import AbstractForm from "components/common/form/AbstractForm";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import { CategorySelector } from "./CategorySelector";
import InputTagsContainer from "components/common/InputTags/Container";
import { formatCurrency, formatNegativeNumber } from "libs/pcap/utils/format";
import { isEqual, clone, isEmpty } from "underscore";
import deepCopy from "deep-copy";
import SplitYourTransactionModal from "components/TransactionsGridV3/SplitYourTransaction/Modal";
import getCategoryType from "components/TransactionsGridV3/getCategoryType";
import TransactionImageUploadModal from "components/TransactionImageUploadModal";
import EditTransactionImageModal from "components/common/EditTransactionImage/Modal";
import { promisify } from "utils/service";
import Services from "services";
import moment from "moment";
import mixpanel from "../../libs/pcap/utils/mixpanel";
import { isEmpowerPrivilegedMode } from "../../views/modules/sidebar/utils/accountUtils";
import ApplyTransactionRuleModal from "./ApplyTransactionRule/ApplyTransactionRuleModal";
import getTransactionEditedMessage from "./getTransactionEditedMessage";
import DatePickerInput from "components/common/form/DatePickerInput";
import { DISPLAY_FORMAT } from "libs/pcap/utils/date2";
import { CURRENCY_FORMAT } from "components/common/form/formattingOptions";

const MAX_TRANSACTION_AMOUNT_DIGITS = 12;
const isPrivileged = isEmpowerPrivilegedMode();
class TransactionEditRow extends AbstractForm {
  constructor(props) {
    super(props);
    let modelCopy = deepCopy(props.model);
    if (!modelCopy.isCredit) {
      modelCopy.amount *= -1;
    }
    this.state = {
      originalModel: deepCopy(props.model),
      model: modelCopy,
      categories: [],
      tags: [],
      showSplitTransaction: !isEmpty(props.model ? props.model.splits : []),
      showApplyTransactionRuleModal: false,
      loading: false,
      didTransactionDateUpdate: false,
      originalTransactionDate: deepCopy(props.model.transactionDate),
    };

    this.handlePreSubmit = this.handlePreSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAmoutInputChange = this.handleAmoutInputChange.bind(this);
    this.handleShowTransactionImageUploader =
      this.handleShowTransactionImageUploader.bind(this);
    this.handleDeleteTransactionImage =
      this.handleDeleteTransactionImage.bind(this);
    this.handleFileUploaded = this.handleFileUploaded.bind(this);
    this.handleCloseTransactionImageUploader =
      this.handleCloseTransactionImageUploader.bind(this);
    this.handleEditImageClick = this.handleEditImageClick.bind(this);
    this.handleCategoryCreated = this.handleCategoryCreated.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleSplitTransactionClick =
      this.handleSplitTransactionClick.bind(this);
    this.handleDeleteTransaction = this.handleDeleteTransaction.bind(this);
    this.handleCancelSplitTransaction =
      this.handleCancelSplitTransaction.bind(this);
    this.handleRevertToOriginalDescription =
      this.handleRevertToOriginalDescription.bind(this);
    this.handleFileUploadError = this.handleFileUploadError.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.isValidStartDate = this.isValidStartDate.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    if (!isEqual(nextProps.categories, prevState.prevPropsCategories)) {
      newState.categories = nextProps.categories;
      newState.prevPropsCategories = nextProps.categories;
    }

    if (!isEqual(nextProps.tags, prevState.prevPropsTags)) {
      newState.tags = nextProps.tags;
      newState.prevPropsTags = nextProps.tags;
    }

    return isEmpty(newState) ? null : newState;
  }

  componentDidMount() {
    // Doing this check for testing purposes
    let descriptionInput = this.inputElements.find(
      (input) => input.props.name === "description"
    );
    if (descriptionInput) {
      descriptionInput.focus();
    }
    // converting transaction date to display format from api format object before rendeing
    let model = this.state.model;
    model.transactionDate = moment(this.state.model.transactionDate).format(
      DISPLAY_FORMAT
    );
    this.setState({ model });
  }

  handleShowApplyTransactionRuleModal() {
    this.setState({
      showApplyTransactionRuleModal: true,
    });
  }

  handleShowTransactionImageUploader() {
    // Dispatch event bus
    window.dashboardUtils?.eventBus.dispatch(
      `BudgetingEvent.add_image_transactions_event`
    );
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type:
        window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
        "select_button",
      event_properties: {
        selection: `BudgetingEvent.add_image_transactions_event`,
      },
    });
    this.setState({
      showTransactionImageUploader: true,
      showEditTransactionImageModal: false,
      errors: undefined,
    });
  }

  handleFileUploaded() {
    let { model: modelCopy } = this.state;
    modelCopy = Object.assign({}, modelCopy, {
      imageUrl: `${
        window.baseUrl
      }servlet/image/getImageByTransactionId?userTransactionId=${
        modelCopy.userTransactionId
      }&v=${moment().valueOf()}`,
    });

    mixpanel.trackEvent("Edit Transaction Image Upload Success", {
      component: "TransactionEditRow",
    });

    this.setState({ model: modelCopy }, () => {
      this.props.onSaved([modelCopy], false);
    });
  }

  handleFileUploadError() {
    mixpanel.trackEvent("Edit Transaction Image Upload Fail", {
      component: "TransactionEditRow",
    });
  }

  handleCloseTransactionImageUploader() {
    this.setState(
      {
        showTransactionImageUploader: false,
        showEditTransactionImageModal: false,
      },
      () => {
        if (this.imageButton) {
          this.imageButton.focus();
        }
      }
    );
  }

  handleEditImageClick() {
    this.setState({
      showEditTransactionImageModal: true,
      errors: undefined,
    });
  }

  handleDeleteTransactionImage() {
    this.setState({ loading: true });
    return this.props
      .removeTransactionImage({
        userTransactionId: this.state.model.userTransactionId,
      })
      .then(() => {
        let { model: modelCopy } = this.state;
        modelCopy = Object.assign({}, modelCopy);
        delete modelCopy.imageUrl;
        this.setState({ model: modelCopy, loading: false }, () => {
          this.props.onSaved([modelCopy], false);
        });
        this.handleCloseTransactionImageUploader();
      })
      .catch((errors) => {
        this.setState({ loading: false, errors });
      });
  }

  handleSplitTransactionClick() {
    const { model, categories } = this.state;
    const isUnCategorizedCategory =
      getCategoryType(model.categoryId, categories) === "UNCATEGORIZED";

    // Dispatch event bus
    window.dashboardUtils?.eventBus.dispatch(
      `BudgetingEvent.split_transactions_event`
    );
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type:
        window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
        "select_button",
      event_properties: {
        selection: `BudgetingEvent.split_transactions_event`,
      },
    });

    if (
      model.hideSplitTransaction ||
      model.isDuplicate ||
      isUnCategorizedCategory
    ) {
      return;
    }

    this.setState({ showSplitTransaction: true, errors: undefined });
  }

  handleCancelSplitTransaction() {
    this.setState({ showSplitTransaction: false });
  }

  handlePreSubmit(ev) {
    ev.preventDefault();
    const { model, originalModel } = this.state;
    if (
      Boolean(this.props.transactionCategoryRulesFlag) &&
      (model.description !== originalModel.description ||
        model.categoryId !== originalModel.categoryId) &&
      !model.isManual &&
      this.validate().valid
    ) {
      this.setState({ showApplyTransactionRuleModal: true });
    } else {
      this.handleSubmit(ev);
    }
  }

  handleAmoutInputChange(event, value) {
    this.handleInputChange(event, value);
    let { model } = this.state;
    model.isCredit = value > 0;
    this.setState({ model });
  }

  handleSubmit(
    ev,
    shouldApplyToFutureTransactions,
    applyToPreviousTransactions
  ) {
    ev.preventDefault();
    const { errors } = this.validate() || {};
    if (errors) {
      this.setState({ errors: errors.map((error) => error.message) });
    }
    if (this.validate().valid) {
      this.setState({ loading: true });
      const { model } = this.state;
      this.trackUpdateInMixpanel(model, this.props.model);
      let requestAmount;
      if (model.isManual && model.amount < 0) {
        // If the amount is a negative value, don't transform the value and send as a negative value to the server
        requestAmount = model.amount;
      } else {
        // Debit amounts are passed as +ve amount and isCredit is set to false
        requestAmount = model.amount < 0 ? model.amount * -1 : model.amount;
      }
      const requestIsCredit = model.amount > 0;
      let requestModel = {
        userTransactionIds: JSON.stringify([model.userTransactionId]),
        description: model.description,
        transactionCategoryId: parseInt(model.categoryId, 10),
        isDuplicate: Boolean(model.isDuplicate),
        amount: parseFloat(requestAmount),
        isCredit: requestIsCredit,
      };

      if (this.state.didTransactionDateUpdate) {
        const newtransactionDate = moment(model.transactionDate).format(
          "YYYY-MM-DD"
        );
        requestModel.transactionDate = newtransactionDate;
      }

      if (this.props.transactionCategoryRulesFlag) {
        requestModel.historical = applyToPreviousTransactions;
        requestModel.future = shouldApplyToFutureTransactions;
        requestModel.startDate = this.props.startDate.format("YYYY-MM-DD");
        requestModel.endDate = this.props.endDate.format("YYYY-MM-DD");
      }

      // Dispatch event bus
      if (IS_EMPOWER) {
        window.dashboardUtils?.eventBus.dispatch(
          `budget_page.transaction_detail_save_button.click`
        );
        window.dashboardUtils?.eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
            "select_button",
          event_properties: {
            selection: `budget_page.transaction_detail_save_button.click`,
          },
        });
        if (
          this.state.model.imageUrl &&
          this.state.model.imageUpdatedDate === undefined
        ) {
          window.dashboardUtils?.eventBus.dispatch(
            `budget_page.add_image_done_button.click`
          );
        }
      }

      // For single Edit transaction selectedTagIds represents
      // tag ids that are selected. When `selectedTagIds` is empty server requires
      // customTags to be an empty array to clear existing tags.
      if (isEmpty(model.selectedTagIds)) {
        requestModel.customTags = "[]";
      } else {
        requestModel.tags = model.selectedTagIds;
      }

      this.props
        .onSubmit(requestModel)
        .then((transaction) => {
          this.props.onSaved(transaction);
        })
        .catch((errors) => {
          this.setState({ errors, loading: false });
        });
    }
  }

  handleDeleteTransaction() {
    const { model } = this.state;
    this.setState({ loading: true });
    this.props
      .onDeleteTransaction({
        userTransactionId: parseFloat(model.userTransactionId),
        userAccountId: parseFloat(model.userAccountId),
      })
      .then(() => {
        this.props.onSaved(model, true, true);
      })
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });
  }

  trackUpdateInMixpanel(updated, original) {
    if (updated.description !== original.description) {
      mixpanel.trackEvent(
        "Edit Transaction Single Mode - Description Updated",
        {
          component: "TransactionEditRow",
        }
      );
    }

    if (updated.categoryId !== original.categoryId) {
      mixpanel.trackEvent("Edit Transaction Single Mode - Category Updated", {
        component: "TransactionEditRow",
      });
    }

    if (!isEqual(updated.selectedTagIds, original.selectedTagIds)) {
      mixpanel.trackEvent("Edit Transaction Single Mode - Tags Updated", {
        component: "TransactionEditRow",
      });
    }
  }

  handleTagsChange(selectedTagIds) {
    let { model } = this.state;
    model.selectedTagIds = selectedTagIds;
    this.setState({ model });
  }

  handleCategoryCreated(category) {
    let { model } = this.state;
    let categories = clone(this.state.categories);
    model.categoryId = category.transactionCategoryId;
    model.categoryName = category.name;
    categories.push(category);
    this.setState({ model, categories });
  }

  handleRevertToOriginalDescription() {
    let { model } = this.state;
    model.description = model.originalDescription;
    this.setState({ model });
  }

  handleCloseModal() {
    this.setState({ showApplyTransactionRuleModal: false });
  }

  isValidStartDate(currentDate) {
    const createdDate = moment(this.state.model.accountCreatedDate)
      .subtract(365, "days")
      .calendar();
    const today = moment().endOf("day");
    return (
      currentDate.isSameOrAfter(createdDate) &&
      currentDate.isSameOrBefore(today)
    );
  }

  handleDateChange(event) {
    const currentDate = moment(event.target.value).format(DISPLAY_FORMAT);
    const originalDate = moment(this.state.originalTransactionDate).format(
      DISPLAY_FORMAT
    );

    if (currentDate !== originalDate) {
      this.setState({ didTransactionDateUpdate: true });
    }
    let { model } = this.state;
    model.transactionDate = event.target.value;
    this.setState({ model });
  }

  render() {
    const { columns, onClose, schema, onSaved } = this.props;
    const {
      originalModel,
      model,
      categories,
      tags,
      loading,
      errors,
      showSplitTransaction,
      showTransactionImageUploader,
      showEditTransactionImageModal,
      showApplyTransactionRuleModal,
    } = this.state;
    
    // Make sure we keep `CategorySelector` options sorted as custom categories are added.
    const categoryColumn = columns.find((col) => col.header === "Category");
    const categoryComparatorAsc = categoryColumn.comparator(
      (cat) => cat.name,
      "asc"
    );
    const sortedCategories = clone(categories).sort(categoryComparatorAsc);
    const isUnCategorizedCategory =
      getCategoryType(model.categoryId, categories) === "UNCATEGORIZED";
    let splitTransactionTitle = "Split this transaction.";

    if (model.isDuplicate) {
      splitTransactionTitle = "Duplicate transaction cannot be split.";
    } else if (isUnCategorizedCategory) {
      splitTransactionTitle = "Uncategorized transaction cannot be split.";
    }

    const transactionEditedMessage = getTransactionEditedMessage(
      originalModel,
      categories
    );

    if (transactionEditedMessage.length === 0)
      this.setState({ displayMessage: true });
    if (isEmpty(model.splits)) {
      return (
        <>
          <form
            className="table__row--edit pc-u-pt- pc-bg-dark qa-edit-transaction-form js-edit-transaction-form"
            onSubmit={this.handlePreSubmit}
          >
            {errors && (
              <Message className="pc-u-mh- pc-u-mb--" messages={errors} />
            )}
            <LoadingOverlay active={loading} />
            <div className="pc-transactions-grid__edit-row-inner">
              <div
                className={`table__column pc-transactions-grid pc-transactions-grid-cell--date`}
              >
                {Boolean(this.props.transactionCategoryRulesFlag) ||
                model.isManual ? (
                  <DatePickerInput
                    name="transactionDate"
                    className="pc-transaction-grid_transaction-date"
                    ref={this.storeInputRef}
                    validator={DatePickerInput.getValidator({
                      valueDateFormat: DISPLAY_FORMAT,
                      allowEmpty: true,
                      isAllowedDate: this.isValidStartDate,
                    })}
                    displayDateFormat={DISPLAY_FORMAT}
                    onChange={this.handleDateChange}
                    position="top"
                    value={model.transactionDate}
                    isAllowedDate={this.isValidStartDate}
                  />
                ) : (
                  <label>{model.transactionDate}</label>
                )}
              </div>

              <Column data={model} {...columns[1]} />
              <div className={`table__column ${columns[2].className}`}>
                <Input
                  ref={this.storeInputRef}
                  containerClassName={`qa-edit-row-description-input ${
                    model.originalDescription === model.description
                      ? ""
                      : "pc-transactions-grid__edit-row-description-input"
                  }`}
                  type="text"
                  name="description"
                  value={model.description}
                  validator={schema.properties.description}
                  errorBlockClassName="is-hidden"
                  onChange={this.handleInputChange}
                  sizeVariation="full"
                />
                {model.originalDescription !== model.description && (
                  <InfoTooltipIcon
                    className="icon__help-circled--white pc-u-ml-- qa-transaction-edit-original-description-tooltip"
                    title={`<div>Original description:</div>${model.originalDescription}<div>Double click to use original</div>`}
                    onDoubleClick={this.handleRevertToOriginalDescription}
                  />
                )}
              </div>
              <div
                className={`table__column ${columns[3].className}`}
                style={{ overflow: "visible" }}
              >
                <CategorySelector
                  options={sortedCategories}
                  name="categoryId"
                  onChange={this.handleInputChange}
                  onCategoryCreated={this.handleCategoryCreated}
                  value={model.categoryId}
                  validator={schema.properties.categoryId}
                />
              </div>
              {model.isManual ? (
                <>
                  <div className={`table__column ${columns[4].className}`} />
                  <div
                    className={`table__column ${columns[5].className} pc-transactions-grid-cell-edit-transaction-amount pc-transactions-grid-cell--amount-parent qa-edit-transactions-cell--amount tabular-numbers`}
                  >
                    <Input
                      id="amount"
                      ref={this.storeInputRef}
                      type="text"
                      value={
                        model.isCredit
                          ? formatCurrency(model.amount)
                          : formatNegativeNumber(model.amount)
                      }
                      name="amount"
                      onChange={this.handleAmoutInputChange}
                      prefix="$"
                      formattingOptions={CURRENCY_FORMAT}
                      validator={this.props.schema.properties.amount}
                      ariaLabel="Transaction amount"
                      maxLength={MAX_TRANSACTION_AMOUNT_DIGITS}
                      className={"input--manual-amount"}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={`table__column ${columns[4].className}`} />
                  <Column
                    data={model}
                    {...columns[5]}
                    formatter={(transaction) => {
                      const value = transaction.isCredit
                      ? formatCurrency(transaction.amount)
                      : formatNegativeNumber(transaction.amount);
                      return <span>{value}</span>;
                    }}
                  />
                </>
              )}
            </div>
            {this.props.transactionCategoryRulesFlag &&
              model?.isModifiedByRule && (
                <div className="pc-transactions-grid__edit-row-inner">
                  <div className={`${columns[0].className}`} />
                  <div className={`${columns[1].className}`} />
                  <div
                    className={`${columns[2].className} pc-transactions-grid__rule-applied-message`}
                  >
                    {transactionEditedMessage}
                  </div>
                </div>
              )}
            <div className="pc-transactions-grid__edit-row-inner">
              <div className={`table__column ${columns[0].className}`} />
              <div
                className={`table__column u-text-right pc-u-p0 ${columns[1].className}`}
              >
                <label htmlFor="transactionEditTag">Tags</label>
              </div>
              <div className="pc-form-group--small pc-layout pc-layout--middle pc-u-mb0 pc-transactions-grid__edit-row-tags-container">
                <div className="pc-layout__item pc-u-5/8">
                  <InputTagsContainer
                    tags={tags}
                    onTagsModified={this.handleTagsChange}
                    value={model.selectedTagIds}
                    selectInputId="transactionEditTag"
                  />
                </div>
              </div>
            </div>
            <div className="pc-layout pc-u-p-">
              {model.isManual ? (
                <div className="pc-layout__item pc-u-1/2" />
              ) : (
                <div className="pc-layout__item pc-u-1/2">
                  <label
                    htmlFor="hideDuplicate"
                    className="pc-label pc-label--inline"
                  >
                    <Input
                      id="hideDuplicate"
                      ref={this.storeInputRef}
                      type="checkbox"
                      name="isDuplicate"
                      checked={model.isDuplicate}
                      onChange={this.handleInputChange}
                      containerClassName={"input--inline-block"}
                      disabled={isPrivileged}
                      ariaLabel="Hide this duplicate"
                    />
                    Hide this duplicate
                  </label>
                  <InfoTooltipIcon
                    className="icon__help-circled--white pc-u-ml--"
                    title={
                      "Marking a transaction as a duplicate will stop counting that transaction in reporting but display it in the account it came from in case you need to view or unmark it."
                    }
                  />
                </div>
              )}
              <div className="pc-layout__item pc-u-1/2 pc-layout--right">
                {model.isManual && (
                  <button
                    type="button"
                    className="pc-btn pc-btn--danger pc-btn--small js-delete-transaction qa-delete-transaction"
                    onClick={this.handleDeleteTransaction}
                  >
                    Delete
                  </button>
                )}
                {!model.imageUrl && (
                  <button
                    type="button"
                    ref={(el) => {
                      this.imageButton = el;
                    }}
                    className="pc-btn pc-btn--small js-add-transaction-image qa-add-transaction-image"
                    onClick={this.handleShowTransactionImageUploader}
                    aria-disabled={isPrivileged}
                    disabled={isPrivileged}
                  >
                    Add Image
                  </button>
                )}
                {model.imageUrl && (
                  <button
                    type="button"
                    ref={(el) => {
                      this.imageButton = el;
                    }}
                    className="pc-btn pc-btn--small js-edit-transaction-image qa-edit-transaction-image"
                    onClick={this.handleEditImageClick}
                  >
                    Edit Image
                  </button>
                )}
                {!model.hideSplitTransaction && !model.isManual && (
                  <button
                    type="button"
                    className="pc-btn pc-btn--small js-split-transaction qa-split-transaction"
                    onClick={this.handleSplitTransactionClick}
                    disabled={model.isDuplicate || isUnCategorizedCategory}
                    title={splitTransactionTitle}
                  >
                    Split Transaction
                  </button>
                )}
                <button
                  type="button"
                  className="pc-btn pc-btn--cancel pc-btn--small qa-close-edit-transaction"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="pc-btn pc-btn--small pc-btn--primary qa-save-transaction"
                  aria-disabled={isPrivileged}
                  disabled={isPrivileged}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
          {showSplitTransaction && (
            <SplitYourTransactionModal
              onCancel={this.handleCancelSplitTransaction}
              onSaved={onSaved}
              isOpen={showSplitTransaction}
              model={model}
              categories={sortedCategories}
              onCategoryCreated={this.handleCategoryCreated}
              tags={tags}
              onFileUploaded={this.handleFileUploaded}
              onFileUploadError={this.handleFileUploadError}
              onDeleteTransactionImage={this.handleDeleteTransactionImage}
              imageErrors={errors}
              imageLoading={loading}
            />
          )}
          {showTransactionImageUploader && (
            <TransactionImageUploadModal
              onDone={this.handleCloseTransactionImageUploader}
              userTransactionId={model.userTransactionId}
              title={model.imageUrl ? "Replace Image" : undefined}
              onFileUploaded={this.handleFileUploaded}
              onFileUploadError={this.handleFileUploadError}
            />
          )}
          {showEditTransactionImageModal && (
            <EditTransactionImageModal
              onCancel={this.handleCloseTransactionImageUploader}
              imageUrl={model.imageUrl}
              onDelete={this.handleDeleteTransactionImage}
              onReplace={this.handleShowTransactionImageUploader}
              loading={loading}
              errors={errors}
            />
          )}
          {showApplyTransactionRuleModal &&
            this.props.transactionCategoryRulesFlag && (
              <ApplyTransactionRuleModal
                isOpen={showApplyTransactionRuleModal}
                model={model}
                originalModel={originalModel}
                categories={categories}
                handleSubmit={this.handleSubmit}
                handleCloseModal={this.handleCloseModal}
              />
            )}
        </>
      );
    }

    return (
      <SplitYourTransactionModal
        onCancel={onClose}
        onSaved={onSaved}
        isOpen={showSplitTransaction}
        model={model}
        categories={sortedCategories}
        onCategoryCreated={this.handleCategoryCreated}
        tags={tags}
        onFileUploaded={this.handleFileUploaded}
        onDeleteTransactionImage={this.handleDeleteTransactionImage}
        imageErrors={errors}
        imageLoading={loading}
      />
    );
  }
}

TransactionEditRow.defaultProps = {
  schema: {
    type: "object",
    properties: {
      description: {
        required: true,
        type: "string",
        allowEmpty: false,
        conform: (str) => /.*\S.*/.test(str),
        messages: {
          required: "Description is required.",
          allowEmpty: "Description is required",
          conform: "Description cannot be empty",
        },
      },
      categoryId: {
        required: true,
        type: "number",
        allowEmpty: false,
        messages: {
          required: "Please select a category.",
        },
      },
      amount: {
        required: true,
        allowEmpty: false,
        messages: {
          required: "Amount is required.",
          allowEmpty: "Amount is required.",
        },
      },
    },
  },
  removeTransactionImage: promisify(
    Services.Transactions.removeTransactionImage
  ),
};

TransactionEditRow.propTypes = {
  categories: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  removeTransactionImage: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  onDeleteTransaction: PropTypes.func.isRequired,
  transactionCategoryRulesFlag: PropTypes.bool,
};

export default TransactionEditRow;
