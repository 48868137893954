import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ChartReact from "libs/pcap/chart/chart--react";
import { formatPercent, formatCurrency } from "libs/pcap/utils/format";
import { scaleLinear, scaleBand } from "d3";
import $ from "jquery";
import deepCopy from "deep-copy";
import objectPath from "object-path";
import { isUndefined } from "underscore";

const XSCALE_INNER_PADDING = 0.36;
const XSCALE_OUTER_PADDING = 0.5;
const Y_DOMAIN_PADDING = 5;
const BLANK_FOOTER = " ";
const Y_VALUE_PATH = "data.0.y";

const xAccessor = (d) => d.id;
const yAccessor = (d) => d.value;

const yScale = scaleLinear();
const xScale = scaleBand()
  .paddingInner(XSCALE_INNER_PADDING)
  .paddingOuter(XSCALE_OUTER_PADDING);

export const getLabel = (item, isYAxisTypeDollar) => {
  const label = item && item.name ? item.name + " " : "";
  const displayValue = item && item.displayValue ? item.displayValue : "";
  const value = item && item.value ? item.value : "";
  if (isYAxisTypeDollar) {
    return `${label}${formatCurrency(displayValue || value)}`;
  }
  return `${label}${formatPercent(value, { precision: 2 })} APY`;
};

export const isMobileDataStructure = (data, chartFooter) =>
  !isUndefined(objectPath.get(data, "0.description")) && chartFooter;

export const isPcapCashItem = (item) =>
  (objectPath.get(item, "description") || "").includes("Personal Capital");

export const getChartYValueForDollar = ({
  pcapCashDollarAmount,
  targetedBankDollarAmount,
  isPcapCash,
}) => {
  return isPcapCash ? 1 : targetedBankDollarAmount / pcapCashDollarAmount;
};

// Transform mobile chart data structure for web use
export const normalizeMobileData = (data, chartFooter, isYAxisTypeDollar) => {
  const dataToUse = deepCopy(data);
  if (isMobileDataStructure(data, chartFooter)) {
    const pcapCashDollarAmount = objectPath.get(
      dataToUse.find(isPcapCashItem) || {},
      Y_VALUE_PATH
    );
    const targetedBankDollarAmount = objectPath.get(
      dataToUse.find((item) => !isPcapCashItem(item)) || {},
      Y_VALUE_PATH
    );
    dataToUse.forEach((item) => {
      const isPcapCash = isPcapCashItem(item);
      item.id = isPcapCash ? chartFooter : BLANK_FOOTER;
      item.value = objectPath.get(item, Y_VALUE_PATH);
      item.name = item.description;
      if (isYAxisTypeDollar) {
        item.displayValue = item.value;
        item.value = getChartYValueForDollar({
          pcapCashDollarAmount,
          targetedBankDollarAmount,
          isPcapCash,
        });
      }
    });
  }
  return dataToUse;
};

export const getLabelPosition = (index) => {
  const el = $(".pcap-cash-pla__container .chart__bar--positive").get(index);
  let result = 0;
  try {
    result = el.height.animVal.value;
  } catch (error) {
    /* Noop */
  }
  return result;
};

const TargetedBankBarChart = ({ data, chartFooter, chartAxisType }) => {
  const isYAxisTypeDollar = objectPath.get(chartAxisType, "y") === "DOLLAR";
  const dataToUse = normalizeMobileData(data, chartFooter, isYAxisTypeDollar);
  const LABEL_SPACING = 30;
  const LABEL_TIMEOUT = 1200;
  const [leftLabelStyles, setLeftLabelStyles] = useState({ display: "none" });
  const [rightLabelStyles, setRightLabelStyles] = useState({ display: "none" });

  useEffect(() => {
    setTimeout(() => {
      setLeftLabelStyles({
        bottom: getLabelPosition(0) + LABEL_SPACING,
      });
      setRightLabelStyles({
        bottom: getLabelPosition(1) + LABEL_SPACING,
      });
    }, LABEL_TIMEOUT);
  }, []);

  return (
    <div
      className={`targeted-bank-bar-chart ${
        isYAxisTypeDollar ? "targeted-bank-bar-chart--compare-dollars" : ""
      }`}
    >
      <label style={leftLabelStyles}>
        {getLabel(dataToUse[0], isYAxisTypeDollar)}
      </label>
      <label style={rightLabelStyles}>
        {getLabel(dataToUse[1], isYAxisTypeDollar)}
      </label>
      <ChartReact
        data={[dataToUse]}
        type="bar"
        keyFunc={xAccessor}
        x={xAccessor}
        y={yAccessor}
        leftAlignYAxis={false}
        yScale={yScale}
        xScale={xScale}
        showYZeroLine={true}
        showYAxis={false}
        showXAxis={true}
        showYGrid={false}
        xAxisPosition="bottom"
        showBarValue={false}
        yDomainPadding={Y_DOMAIN_PADDING}
      />
    </div>
  );
};

TargetedBankBarChart.defaultProps = {
  chartFooter: undefined,
  chartAxisType: {
    x: "DEFAULT",
    y: "PERCENTAGE",
  },
};

TargetedBankBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  chartAxisType: PropTypes.object,
  chartFooter: PropTypes.string,
};

export default TargetedBankBarChart;
