import React from "react";
import PropTypes from "prop-types";
import Warning from "svg-icons/Warning.svg";

const ICON_DETAILS = {
  WARNING: {
    IconComponent: Warning,
    iconWidth: "45",
    iconHeight: "38",
    iconViewBox: "0 0 45 38",
  },
};

const PershingMessages = ({
  containerClassName,
  iconClassName,
  messageClassName,
  messageArray,
}) => {
  const { IconComponent, iconWidth, iconHeight, iconViewBox } =
    ICON_DETAILS.WARNING;

  return (
    <div
      className={`pershing-messages__container ${containerClassName}`}
      role={"alert"}
    >
      <span>
        <IconComponent
          aria-labelledby="Warning_icon-label"
          width={iconWidth}
          height={iconHeight}
          className={iconClassName}
          viewBox={iconViewBox}
        />
      </span>
      {messageArray.length > 0 && (
        <div className="pc-u-pl- pershing-messages__message-array" role="table">
          {messageArray.map((messageObject, index) => (
            <div
              className="pershing-messages__message-array--row"
              role="row"
              key={index}
            >
              <span title="Code" className={`${messageClassName}`}>
                {messageObject.code}
              </span>
              <span title="Type" className={`pc-u-pl ${messageClassName}`}>
                {messageObject.type}
              </span>
              <span
                title="Description"
                className={`pc-u-pl ${messageClassName}`}
              >
                {messageObject.description}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

PershingMessages.propTypes = {
  containerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  messageClassName: PropTypes.string,
  messageArray: PropTypes.array.isRequired,
};

PershingMessages.defaultProps = {
  containerClassName: "",
  iconClassName: "",
  messageClassName: "",
};

export default PershingMessages;
