import React from "react";
import PropTypes from "prop-types";
import { PLANS_SECTION_OFFICIAL } from "components/financialPlanner/financialPlannerConstants";

export default class PlanListSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };

    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleToggleClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { plans, title, subtitle, sectionType } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={`qa-${title.replace(/\s/g, "-")}`}>
        <div className="plan-list-section__title-section">
          <div className="plan-list-section__icon">
            {sectionType === PLANS_SECTION_OFFICIAL ? (
              <svg
                className="sidebar-plan__master-star-icon"
                viewBox="0 0 24 24"
              >
                <use xlinkHref="#pc-icon__star" />
              </svg>
            ) : (
              <div
                className={`plan-list-section__toggle${
                  isOpen ? "--open" : "--closed"
                }`}
                onClick={this.handleToggleClick}
              />
            )}
          </div>
          <div className="plan-list-section__title qa-section-title">
            {title}
          </div>
          {subtitle && (
            <div className="plan-list-section__title--secondary js-plan-section-subtitle">
              {subtitle}
            </div>
          )}
        </div>
        {this.state.isOpen &&
          plans.map((plan) => this.props.renderPlan(plan, this.props))}
      </div>
    );
  }
}

PlanListSection.propTypes = {
  activePlanId: PropTypes.number,
  disabled: PropTypes.bool,
  onPlanSelected: PropTypes.func,
  plans: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sectionType: PropTypes.string,
  renderPlan: PropTypes.func,
};
