import PropTypes from "prop-types";
import React from "react";
import ChartReact from "libs/pcap/chart/chart--react";
import { formatCurrency } from "libs/pcap/utils/format";
import ImportantDataPointsContainer from "components/importantDataPoints/ImportantDataPointsContainer";
import { scaleTime } from "d3";
import CtaOverlay from "components/common/CtaOverlay";
import oneDayChangeTemplate from "common/chartTooltips/oneDayChange";
import { noop } from "underscore";

const preserveCaseRebranding = "u-preserve-case";

function xAccessor(item) {
  return item.date;
}

function yAccessor(item) {
  return item.aggregateBalance;
}

const templateTooltip = oneDayChangeTemplate({
  x: xAccessor,
  y: yAccessor,
});
const Y_DOMAIN_PADDING = 10;

function Filters({ filters, activeFilter, onFilterChange }) {
  return (
    <div className="portfolio-balance-summary-filter">
      {filters.map((f) => {
        return (
          <button
            type="button"
            key={f.id}
            data-filter-id={f.id}
            className={`portfolio-balance-summary-filter__item portfolio-balance-summary-filter__item__action micro ${preserveCaseRebranding} ${
              f.className || ""
            } ${
              String(f.id) === activeFilter
                ? "portfolio-balance-summary-filter__item__action--selected"
                : ""
            }`}
            onClick={onFilterChange}
          >
            {f.name}
            <div className="pc-delta qa-balance">
              {formatCurrency(f.balance, 0)}
            </div>
          </button>
        );
      })}
    </div>
  );
}

Filters.propTypes = {
  filters: PropTypes.array,
  activeFilter: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  filters: [],
  activeFilter: "",
};

export default class PortfolioBalanceSummaryView extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilter = this.handleFilter.bind(this);
  }

  redirectToPortfolioBalances() {
    window.location.href = PORTFOLIO_URL + "/balances";
  }

  handleFilter(ev) {
    const el = ev.currentTarget;
    const { onFilterChange } = this.props;
    if (onFilterChange) {
      onFilterChange(el.dataset.filterId);
    }
  }

  render() {
    const {
      onChartClick,
      filters,
      histories,
      activeFilter,
      balance,
      headerText,
      useVariableTimeFrame,
      disableHeaderLink,
    } = this.props;

    const activeFilterData = filters
      ? filters.find((f) => f.id && f.id.toString() === activeFilter)
      : null;

    let aceOverlay;
    if (this.props.ace) {
      aceOverlay = (
        <CtaOverlay
          message={this.props.ace}
          widgetName="Portfolio Balance Summary"
        />
      );
    }

    return (
      <div className="portfolio-balance-summary summary-widget qa-portfolio-balance-widget">
        <div className="summary-widget__header">
          <div className="l-spaced l-spaced--flush">
            <div className="pc-delta">
              {disableHeaderLink ? (
                headerText
              ) : (
                <h2 className="pc-u-mb0">
                  <a
                    href={`${PORTFOLIO_URL}/balances`}
                    className="summary-widget__title--link qa-portfolio-widget-title"
                    aria-label="Go to protfolio balances"
                  >
                    {headerText}
                  </a>
                </h2>
              )}
            </div>
            {balance != null && (
              <div className="pc-delta portfolio-balance-summary-balance qa-portfolio-balance-summary-balance">
                {formatCurrency(balance, 0)}
              </div>
            )}
          </div>
          {filters && (
            <Filters
              filters={filters}
              activeFilter={activeFilter}
              onFilterChange={this.handleFilter}
            />
          )}
        </div>
        {activeFilterData && activeFilterData.altContent ? (
          activeFilterData.altContent
        ) : (
          <ChartReact
            data={[histories]}
            className={`portfolio-balance-summary__chart summary-widget__body dashboard-chart ${
              filters ? "portfolio-balance-summary__chart--with-filters" : ""
            }`}
            type="area"
            xScale={scaleTime()}
            interpolate="monotone"
            showYGrid={false}
            showXGrid={false}
            showXAxis={false}
            showYAxis={false}
            showYZeroLine={true}
            x={xAccessor}
            y={yAccessor}
            tooltip={{
              template: templateTooltip,
              className: "chart-tooltip--small",
            }}
            onClick={onChartClick || this.redirectToPortfolioBalances}
            ariaLabel={"Go to portfolio balances"}
            tabIndex={0}
            yDomainPadding={Y_DOMAIN_PADDING}
          />
        )}
        <div className="summary-widget__footer">
          {activeFilterData && activeFilterData.altContent ? null : (
            <ImportantDataPointsContainer
              histories={histories}
              useActualTimeFrame={useVariableTimeFrame}
            />
          )}
        </div>
        {aceOverlay}
      </div>
    );
  }
}

PortfolioBalanceSummaryView.defaultProps = {
  filters: [],
  activeFilter: "",
  balance: undefined,
  onFilterChange: noop,
  ace: undefined,
  useVariableTimeFrame: false,
  onChartClick: noop,
  headerText: "Portfolio Balance",
  disableHeaderLink: false,
};

PortfolioBalanceSummaryView.propTypes = {
  headerText: PropTypes.string,
  filters: PropTypes.array,
  activeFilter: PropTypes.string,
  histories: PropTypes.array.isRequired,
  balance: PropTypes.number,
  onFilterChange: PropTypes.func,
  ace: PropTypes.object,
  useVariableTimeFrame: PropTypes.bool,
  onChartClick: PropTypes.func,
  disableHeaderLink: PropTypes.bool,
};
