/* eslint-disable camelcase */
/* eslint-disable sonarjs/cognitive-complexity */
import $ from "jquery";
import _ from "underscore";

import Backbone from "backbone";
import Services from "services";
import dollarAndCentsAmount from "templates/helpers/dollarAndCentsAmount";
import sidebarModel from "models/sidebar";
import NeedAttentionAccountGroupView from "views/modules/sidebar/partials/NeedAttentionAccountGroup";
import ResumeLinkingAccountGroupView from "views/modules/sidebar/partials/ResumeLinkingAccountGroup";
import AccountGroupView from "views/modules/sidebar/partials/accountGroup2";
import Account from "models/account";
import AccountsCollection from "collections/accounts";
import * as detailsLink2 from "templates/helpers/detailsLink2";
import AccountListHeaderTemplate from "templates/modules/sidebar/accountListHeader2.html";
import LinkAnAccountButton from "templates/modules/sidebar/linkAnAccountButton.html";
import NetWorthBar from "components/sidebar/netWorthBar";
import OnlineShoppingUtils from "components/onlineShopping/OnlineShoppingUtils";
import abFrameworkProperties from "libs/pcap/utils/abFrameworkProperties";
import {
  isEmpowerPaeMode,
  isEmpowerPrivilegedMode,
} from "views/modules/sidebar/utils/accountUtils";
import { componentLoaded } from "empower/utils/componentLoading";
import { noop } from "underscore";
import eventBus from "eventBus";
import BackboneReactView from "common/BackboneReactView";
import AggregationHelper from "empower/components/AggregationHelper/AggregationHelper";
import StillImportingAccountGroupView from "views/modules/sidebar/partials/StillImportingAccountGroupView";

const NEED_ATTENTION = "NEEDS_ATTENTION";
const RESUME_LINKING = "RESUME_LINKING";
const IN_PROGRESS = "IN_PROGRESS";

const FASTLINK_ACCOUNT_LINKED = "FASTLINK_ACCOUNT_LINKED";
const isEmulatorMode = isEmpowerPaeMode();
const isPrivileged = isEmpowerPrivilegedMode();

var AccountsListView = Backbone.View.extend({
  initiallyLoaded: false,
  className: "sidebar-accounts js-accounts-list",
  tagName: "ul",
  isLoaded: false,
  initialize: function (elementContainer, options) {
    this.empowerData = options?.empowerData || {};
    this.collection = new AccountsCollection();
    this.collection.on("removed", this.onAccountRemoved, this);
    this.model = new (Backbone.Model.extend({}))();
    this.model.on("change", this.renderMetadata, this);
    this.$container = $(elementContainer);
    this.$container.html(this.$el);
    this.render();
    this.renderNetWorth();
    this.fetchAccounts();
    this.model.hasSeenMoveItAccounts = false;
    this.accountGroups = {};
    sidebarModel.on("change:state", this.onSidebarStateChanged, this);

    this.accountGroups[IN_PROGRESS] = new StillImportingAccountGroupView({
      groupName: IN_PROGRESS,
      label: Account.PRODUCT_TYPES_LABELS[IN_PROGRESS],
      elementContainer: this.$el,
      model: this.model,
      collection: this.collection,
    });

    this.accountGroups[NEED_ATTENTION] = new NeedAttentionAccountGroupView({
      groupName: NEED_ATTENTION,
      label: Account.PRODUCT_TYPES_LABELS[NEED_ATTENTION],
      elementContainer: this.$el,
      model: this.model,
      collection: this.collection,
    });

    if (!isEmulatorMode || isPrivileged) {
      this.accountGroups[RESUME_LINKING] = new ResumeLinkingAccountGroupView({
        groupName: RESUME_LINKING,
        label: Account.PRODUCT_TYPES_LABELS[RESUME_LINKING],
        elementContainer: this.$el,
        model: this.model,
        collection: this.collection,
      });
    }

    this.buildList();
    sidebarModel.on(
      "ensureElementIsVisible",
      this.ensureElementIsVisible,
      this
    );
    eventBus.on(FASTLINK_ACCOUNT_LINKED, this.fastlinkNewAccountLinked, this);
  },

  fastlinkNewAccountLinked: function (accounts) {
    if (_.isEmpty(accounts)) return;
    if (this.collection.length < 1) {
      this.collection.reset(accounts);
    } else {
      // This triggers accountGroup2::renderAccounts()
      this.collection.addAccounts(accounts);
    }
  },

  appendSidebar: function () {
    // Append the 'Link an account' button or Aggregation Helper for affiliate build
    this.$el.append(
      LinkAnAccountButton({
        isEmpower: IS_EMPOWER,
        ADD_ACCOUNT_BTN_CLASS: "pc-btn",
        linkAccountMessage: "Link another account",
      })
    );
    if (IS_EMPOWER) {
      if (this.empowerData?.aggregationLevel === "NONE") {
        return false;
      }
      this.renderAggregationHelper();
    }
  },

  renderAggregationHelper: function () {
    // Remove the existing aggregation helper and create a new one
    if (this.aggregationHelper) {
      this.aggregationHelper.remove();
    }
    this.aggregationHelper = new BackboneReactView({
      el: "#sidebar__aggregation-helper",
      component: AggregationHelper,
      componentProps: {
        aggregatedAccounts: self?.numOfExternalAccounts || 0,
      },
    });
  },

  buildList: function () {
    _.each(
      _.values(Account.PRODUCT_TYPES),
      function (groupName) {
        this.accountGroups[groupName] = new AccountGroupView({
          empowerData: this.empowerData,
          groupName: groupName,
          label: Account.PRODUCT_TYPES_LABELS[groupName],
          elementContainer: this.$el,
          model: this.model,
          collection: this.collection,
        });
      }.bind(this)
    );
  },

  render: function () {
    this.renderMetadata();
    return this;
  },
  renderMetadata: function () {
    if (
      isEmulatorMode ||
      (IS_EMPOWER && this.empowerData?.aggregationLevel === "NONE")
    ) {
      return false;
    }

    var span = $(".js-sidebar-networth-amount");
    if (span.length) {
      span.text(
        dollarAndCentsAmount(
          this.model.get("networth"),
          true,
          true,
          this.model.get("isLiability"),
          true
        )
      );
    } else {
      var templateData = this.model.toJSON();
      templateData.NETWORTH_URL = NETWORTH_URL;
      templateData.ADD_ACCOUNT_BTN_CLASS = `pc-btn pc-btn--add-account`;
      $(".js-sidebar-networth").append(AccountListHeaderTemplate(templateData));
    }
    $(".js-sidebar-networth-container").toggle(
      !(this.collection == null || this.collection.length < 1)
    );
    this.trigger("rendered");
  },
  renderNetWorth: function () {
    if (!isEmulatorMode || isPrivileged) {
      this.assetsBar = new NetWorthBar({
        el: $(".js-sidebar-assets"),
        type: "assets",
      });
      this.liabilitiesBar = new NetWorthBar({
        el: $(".js-sidebar-liabilities"),
        type: "liabilities",
      });
    }
  },
  fetchAccounts: function () {
    Services.Accounts.get.watch(
      { includeTransfer: true, requestSource: "ONLINE_TRANSFER" },
      this.onAccountsFetched.bind(this),
      this
    );
  },
  onAccountsFetched: function (err, response) {
    if (!err) {
      let model = {};
      // Set move it accounts
      Services.Profile.getFunnelAttributes(
        (faResponseErrors, funnelAttributesResponse) => {
          if (
            !faResponseErrors &&
            funnelAttributesResponse &&
            funnelAttributesResponse.spData
          ) {
            const data = funnelAttributesResponse.spData;
            const isAq = data.daysSinceAggregationQualified > -1;
            const hasOpenOpportunity = data.hasOpenOpportunity;
            const isClient = data.isClient;
            const salesType = data.salesType === "DIGITAL";
            const moveItGTM = abFrameworkProperties.moveIt.show;

            let moveItAccounts = OnlineShoppingUtils.getTransferableAccounts(
              response.spData.account
            ).filter((account) => {
              return (
                account.balance >= abFrameworkProperties.moveIt.balanceCutOff &&
                account.nextAction.iconType === "NONE"
              );
            });
            if (
              moveItAccounts.length > abFrameworkProperties.numberOfAccounts
            ) {
              moveItAccounts = moveItAccounts.slice(
                0,
                abFrameworkProperties.numberOfAccounts
              );
            }
            model = {
              isEligibleForMoveIt:
                !isClient &&
                salesType &&
                !hasOpenOpportunity &&
                isAq &&
                moveItGTM &&
                moveItAccounts.length > 0,
              moveItAccounts: moveItAccounts,
            };

            // For empower builds, check if the AggregationHelper should be updated
            // after a new account is linked or removed
            if (
              IS_EMPOWER &&
              self.numOfExternalAccounts !== undefined &&
              ((self.numOfExternalAccounts === 0 &&
                data.numOfExternalAccounts > 0) ||
                (self.numOfExternalAccounts > 0 &&
                  data.numOfExternalAccounts === 0))
            ) {
              self.numOfExternalAccounts = data?.numOfExternalAccounts;
              this.renderAggregationHelper();
            }
            self.numOfExternalAccounts = data?.numOfExternalAccounts;
          }
          this.isLoaded = true;
          if (
            IS_EMPOWER &&
            this.collection.length !== 0 &&
            this.collection.length !== response.spData.accounts.length
          ) {
            // If the API fails, it's not a big issue, so we ignore it
            window.PublicServices?.getAccountSummaryRefresh()
              .then(this.onAccountSummaryFetched)
              .catch(noop);
          }

          var metadata = _.clone(response.spData);

          if (response.spData?.accounts) {
            delete metadata.accounts;
            if (this.collection.length < 1) {
              this.collection.reset(response.spData.accounts);
            } else {
              // This triggers accountGroup2::renderAccounts()
              this.collection.freshen(response.spData.accounts);
            }
          }

          model = Object.assign(model, metadata);

          // This triggers accountGroup2::updateMetaData()
          this.model.set(model);

          if (!this.initiallyLoaded) {
            this.initiallyLoaded = true;
            if (sidebarModel.get("state") === sidebarModel.COLLAPSED) {
              this.trigger("accountsFetched");
            }
            componentLoaded("sidebar");
            const data = funnelAttributesResponse.spData;

            const empAcctCount = this.accountGroups?.EMPOWER?.accountViews
              ? Object.values(this.accountGroups?.EMPOWER?.accountViews).length
              : 0;

            const loanAcctCount = this.accountGroups?.LOAN?.accountViews
              ? Object.values(this.accountGroups?.LOAN?.accountViews).length
              : 0;

            const pendAcctCount = this.accountGroups?.NEEDS_ATTENTION
              ?.accountViews
              ? Object.values(this.accountGroups?.NEEDS_ATTENTION?.accountViews)
                  .length
              : 0;

            const otherPendAcctCount = this.accountGroups?.NEEDS_ATTENTION
              ?.siteViews
              ? Object.values(this.accountGroups?.NEEDS_ATTENTION?.siteViews)
                  .length
              : 0;

            const bankAcctCount = this.accountGroups?.BANK?.accountViews
              ? Object.values(this.accountGroups?.BANK?.accountViews).length
              : 0;

            const creditAcctCount = this.accountGroups?.CREDIT_CARD
              ?.accountViews
              ? Object.values(this.accountGroups?.CREDIT_CARD?.accountViews)
                  .length
              : 0;

            const investAcctCount = this.accountGroups?.INVESTMENT?.accountViews
              ? Object.values(this.accountGroups?.INVESTMENT?.accountViews)
                  .length
              : 0;

            const mortAcctCount = this.accountGroups?.MORTGAGE?.accountViews
              ? Object.values(this.accountGroups?.MORTGAGE?.accountViews).length
              : 0;

            const inProgAcctCount = this.accountGroups?.IN_PROGRESS
              ?.accountViews
              ? Object.values(this.accountGroups?.IN_PROGRESS?.accountViews)
                  .length
              : 0;

            const otherAssetsAcctCount = this.accountGroups?.OTHER_ASSETS
              ?.accountViews
              ? Object.values(this.accountGroups?.OTHER_ASSETS?.accountViews)
                  .length
              : 0;

            const otherLiabAcctCount = this.accountGroups?.OTHER_LIABILITIES
              ?.accountViews
              ? Object.values(
                  this.accountGroups?.OTHER_LIABILITIES.accountViews
                ).length
              : 0;

            const headerData = response.spHeader;
            const payload = {
              netWorth: this.model.attributes.networth,
              acctCount:
                empAcctCount +
                bankAcctCount +
                investAcctCount +
                mortAcctCount +
                creditAcctCount +
                loanAcctCount +
                pendAcctCount +
                inProgAcctCount +
                otherAssetsAcctCount +
                otherLiabAcctCount,
              asstAmt: this.model.attributes.assets,
              liabAmt: this.model.attributes.liabilities,
              empAcctCount: empAcctCount,
              pendCount: pendAcctCount + otherPendAcctCount,
              consentStat:
                data?.optedToAggregation === undefined
                  ? false
                  : data?.optedToAggregation,
              redwoodPersonId: headerData?.personId ?? "",
              easyPersonId:
                headerData?.participantContext?.empowerPersonId ?? "",
              userGuid: headerData?.userGuid ?? "",
              userStage: headerData?.userStage ?? "",
              qualLead: headerData?.qualifiedLead ?? "",
            };

            const eventBusEvent = window.dashboardUtils
              ? window.dashboardUtils.eventBus
              : null;
            const eventName = "Aggregator_complete_event";

            if (eventBusEvent) {
              eventBusEvent.dispatch(eventName, {}, payload);
              eventBusEvent.dispatchAmplitude({
                event_type:
                  window.integratedSharedData?.AMPLITUDE_EVENTS
                    ?.SELECT_BUTTON ?? "select_button",
                event_properties: { selection: eventName, ...payload },
              });
            }
            // Append the lower 'Link another account' section and aggregation helper video (for empower app)
            this.appendSidebar();
          }
          // if the user has accounts and they're not closed
          if (
            (!isEmulatorMode &&
              (response.spData.accounts.length !== 0 ||
                response.spData.accounts.filter(function (account) {
                  return account.closedDate && account.closedDate !== "";
                }).length !== response.spData.accounts.length)) ||
            (isPrivileged &&
              (response.spData.accounts.length !== 0 ||
                response.spData.accounts.filter(function (account) {
                  return account.closedDate && account.closedDate !== "";
                }).length !== response.spData.accounts.length))
          ) {
            var assets = response.spData.assets;
            var liabilities = response.spData.liabilities;
            var max = Math.max(assets, liabilities);
            this.assetsBar.setTotal(max);
            this.assetsBar.setValue(assets);
            this.liabilitiesBar.setTotal(max);
            this.liabilitiesBar.setValue(liabilities);
          }
        }
      );
    }
  },
  onAccountSummaryFetched: function (response) {
    this.empowerData.accountSummaries = response;
  },
  onSidebarStateChanged: function (model, state) {
    if (state === model.EXPANDED) {
      this.$el.addClass("expanded");
    } else {
      this.$el.removeClass("expanded");
    }
  },
  onAccountRemoved: function (account) {
    // PFA-6656
    if (account) {
      var accountDetailsUrl = detailsLink2.generateDetailsURL(account.toJSON());
      if (
        window.location.href.indexOf(accountDetailsUrl) !== -1 &&
        accountDetailsUrl !== ""
      ) {
        window.location.href = DASHBOARD_URL;
      }
    }
  },
  ensureElementIsVisible: function (element) {
    // attempt to scroll to element in the page and expand the appropriate category group
    var targetElement = $(element);

    const noAggregation = this.empowerData?.aggregationLevel === "NONE";
    const accountsListTopElement = noAggregation
      ? $("#js-sidebar-accounts-container")
      : $(".js-networth-section");

    if (targetElement && accountsListTopElement) {
      var accountListHeight = this.$el.height(),
        anchorOffsetTop =
          accountsListTopElement.offset().top + accountsListTopElement.height(),
        currentScrollTop = this.$el.scrollTop(),
        targetHeight = targetElement.height();

      var targetOffsetTop = targetElement.offset().top - anchorOffsetTop;

      if (
        targetOffsetTop < 0 ||
        targetOffsetTop > accountListHeight - targetHeight
      ) {
        targetOffsetTop = currentScrollTop + targetOffsetTop;
        this.$el.animate(
          {
            scrollTop: targetOffsetTop + "px",
          },
          "fast"
        );
      }
    }
  },

  isAnyExtraFormVisible: function () {
    return _.some(this.accountGroups, function (accountGroup) {
      return accountGroup.isAnyExtraFormVisible();
    });
  },

  updateAccountGroupsBalancesGraph: function () {
    _.each(this.accountGroups, function (accountGroup) {
      accountGroup.fetchGroupBalances();
    });
  },
});
export default AccountsListView;
