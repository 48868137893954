import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import DateRangeSelector from "views/components/dateRangeSelectorView2";
import DateUtils from "libs/pcap/utils/date";

import ActiveTransfersTable from "components/transferManager/ActiveTransfersTable";
import TransferManagerHistory from "components/transferManager/TransferManagerHistory";
import RecurringTransfersTableContainer from "components/transferFunds/RecurringTransfersTable/Container";
import RecurringTransfers from "empower/components/RecurringTransfers";
import noop from "utils/noop";

export const TRANSFER_MANAGER_HISTORY = "history";
export const TRANSFER_MANAGER_ACTIVE = "active";
export const TRANSFER_MANAGER_RECURRING = "recurring";

const getActionButtons = (buttonText) => (
  <div>
    <a href={TRANSFER_FUNDS_URL} className="pc-btn" type="button">
      {buttonText}
    </a>
    {!IS_ADVISOR && !IS_EMPOWER && (
      <a href="/page/enrollment/start" className="pc-btn" type="button">
        Open An Account
      </a>
    )}
    {!IS_ADVISOR && IS_EMPOWER && (
      <a href={ENROLL_URL_PREFIX} className="pc-btn" type="button">
        Open An Account
      </a>
    )}
  </div>
);

class TransferManager extends React.Component {
  constructor(props) {
    super(props);

    const dateRangeEnd = moment();
    const dateRangeStart = dateRangeEnd.clone().startOf("year");

    this.state = {
      dateRangeEnd,
      dateRangeStart,
    };

    this.dateRangeSelectorRef = React.createRef();
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
  }

  componentDidMount() {
    if (this.props.section === "history") this.initializeDateRangeSelector();
  }

  componentDidUpdate() {
    if (this.props.section === "history") this.initializeDateRangeSelector();
    if (this.props.section !== "history" && this.dateRangeSelector) {
      this.destroyDateRangeSelector();
    }
  }

  componentWillUnmount() {
    if (this.dateRangeSelector) this.destroyDateRangeSelector();
  }

  initializeDateRangeSelector() {
    this.dateRangeSelector = new DateRangeSelector({
      el: this.dateRangeSelectorRef,
      initialStartDate: this.state.dateRangeStart.format(
        DateUtils.DISPLAY_FORMAT
      ),
      initialEndDate: this.state.dateRangeEnd.format(DateUtils.DISPLAY_FORMAT),
    });
    this.dateRangeSelector.on("change:dateRange", this.handleDateRangeChange);
  }

  handleDateRangeChange(startDate, endDate) {
    this.setState((prev) => ({
      ...prev,
      dateRangeStart: moment(startDate, DateUtils.DISPLAY_FORMAT),
      dateRangeEnd: moment(endDate, DateUtils.DISPLAY_FORMAT),
    }));
  }

  destroyDateRangeSelector() {
    this.dateRangeSelector.off("change:dateRange");
    this.dateRangeSelector.remove();
    delete this.dateRangeSelector;
  }

  render() {
    const {
      section,
      loading,
      transfers,
      showMessage,
      isTaxWithholdingEnabled,
    } = this.props;

    const filteredTransfers =
      section === TRANSFER_MANAGER_HISTORY
        ? transfers.filter(({ completedDate = false }) => {
            completedDate = completedDate && new Date(completedDate);
            const startDate = new Date(this.state.dateRangeStart);
            const endDate = new Date(this.state.dateRangeEnd);

            return (
              Boolean(completedDate) &&
              completedDate <= endDate &&
              completedDate >= startDate
            );
          })
        : transfers
            .filter((t) => !t.completedDate)
            .filter((t) => Boolean(t.timeline))
            .sort(
              (a, b) =>
                new Date(b.timeline[0]?.description) -
                new Date(a.timeline[0]?.description)
            );

    return (
      <div className="transfer-manager__section">
        <div className="l-spaced l-spaced--flush">
          {getActionButtons(
            IS_EMPOWER && section === TRANSFER_MANAGER_RECURRING
              ? "Submit a new request"
              : "Submit a new transfer"
          )}
          {section === TRANSFER_MANAGER_HISTORY && (
            <div
              className="dateSelector"
              ref={(el) => {
                this.dateRangeSelectorRef = el;
              }}
            />
          )}
        </div>
        <section className={`transfer-manager__${section}-wrapper`}>
          {section === TRANSFER_MANAGER_HISTORY && (
            <TransferManagerHistory
              transfers={filteredTransfers}
              loading={loading}
            />
          )}
          {section === TRANSFER_MANAGER_ACTIVE && (
            <ActiveTransfersTable
              transfers={filteredTransfers}
              loading={loading}
              showMessage={showMessage}
            />
          )}
          {section === TRANSFER_MANAGER_RECURRING &&
            (IS_EMPOWER ? (
              <RecurringTransfers
                showNewRequestButton={false}
                showTitle={false}
              />
            ) : (
              <RecurringTransfersTableContainer
                isTaxWithholdingEnabled={isTaxWithholdingEnabled}
              />
            ))}
        </section>
      </div>
    );
  }
}

TransferManager.propTypes = {
  section: PropTypes.string.isRequired,
  transfers: PropTypes.array,
  loading: PropTypes.bool,
  showMessage: PropTypes.func,
  isTaxWithholdingEnabled: PropTypes.bool,
};

TransferManager.defaultProps = {
  transfers: [],
  loading: false,
  showMessage: noop,
  isTaxWithholdingEnabled: false,
};

export default TransferManager;
