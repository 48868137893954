import React from "react";
import PropTypes from "prop-types";
import ExpandedOverviewHeader from "./ExpandedOverviewHeader";
import ExpandedOverviewTile from "./ExpandedOverviewTile";

const ExpandedOverview = ({ onToggleOverview, personName, tiles }) => {
  return (
    <div className="expanded-overview">
      <ExpandedOverviewHeader
        personName={personName}
        onToggleOverview={onToggleOverview}
      />
      <div className="expanded-overview__tiles">
        {tiles.map((tile) => (
          <ExpandedOverviewTile {...tile} key={tile.title} />
        ))}
      </div>
    </div>
  );
};

ExpandedOverview.propTypes = {
  onToggleOverview: PropTypes.func.isRequired,
  personName: PropTypes.string,
  tiles: PropTypes.array,
};

ExpandedOverview.defaultProps = {
  personName: "",
  tiles: [],
};

export default ExpandedOverview;
