import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";

/**
 * `WidgetZeroState` displays text and buttons handed from server at the widget level.
 *
 * Initialization options:
 *
 * @param  {Object}   props                 Initialization options.
 * @param  {Array}    props.text            Text to show
 * @param  {Array}    props.buttons         An array of buttons to display
 */
export default class WidgetZeroState extends React.Component {
  buttonComponent(title, link, trackingCallback, key, attributionSource) {
    return (
      <div className="advice-summary__button--zero-state" key={key}>
        <a
          href={link}
          className="pc-btn pc-btn--primary qa-advice-summary-button"
          onClick={() => trackingCallback()}
          data-attribution-source={attributionSource}
        >
          {title}
        </a>
      </div>
    );
  }

  render() {
    let buttons = _.map(this.props.buttons, (button, index) => {
      return this.buttonComponent(
        button.title,
        button.link,
        button.trackingCallback,
        index,
        button.attributionSource
      );
    });

    return (
      <section className="summary-widget advice-summary--zero-state qa-advice-summary-body-zero-state">
        <div className="u-vertical-center--abs">
          <div className="summary-widget__header advice-summary__header--zero-state">
            <div
              className="pc-layout--middle pc-gamma qa-advice-summary-title"
              tabIndex="0"
            >
              {this.props.title}
            </div>
          </div>
          <div className="summary-widget__body">{buttons}</div>
        </div>
      </section>
    );
  }
}

WidgetZeroState.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array,
};
