import React from "react";
import PropTypes from "prop-types";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { noop } from "underscore";

class InfoTooltipIcon extends React.Component {
  render() {
    const {
      iconRef,
      storeTooltipRef,
      title,
      tooltipProps,
      onDoubleClick,
    } = this.props;
    return (
      <svg
        className="icon-svg"
        ref={(el) => {
          storeTooltipRef(
            el,
            Object.assign(
              { html: true, container: "body", placement: "bottom" },
              tooltipProps
            )
          );
        }}
        data-toggle="tooltip"
        title={title}
        onDoubleClick={onDoubleClick}
      >
        <use href={iconRef}></use>
      </svg>
    );
  }
}

InfoTooltipIcon.propTypes = {
  iconRef: PropTypes.string,
  storeTooltipRef: PropTypes.func,
  title: PropTypes.string.isRequired,
  tooltipProps: PropTypes.object,
  onDoubleClick: PropTypes.func,
};

InfoTooltipIcon.defaultProps = {
  iconRef: "#icon-image",
  onDoubleClick: noop,
  storeTooltipRef: noop,
  tooltipProps: undefined,
};

export default withTooltips(InfoTooltipIcon);
