import React from "react";
import PropTypes from "prop-types";
import EditStakeholdersView from "./EditStakeholdersView";
import AddPersonContainer from "components/common/Person/AddPersonContainer";
import Modal from "components/modal/Modal";
import deepCopy from "deep-copy";
import { isEmpowerPrivilegedMode } from '../../../views/modules/sidebar/utils/accountUtils';

function personToStakeholder(person, stakeholderType) {
  const stakeholder = {
    personName: person.name,
    personId: person.id,
    percentage: "",
  };

  if (stakeholderType === "beneficiary") {
    stakeholder.role = "BENEFICIARY";
  } else if (stakeholderType === "owner") {
    stakeholder.role = "PRIMARY";
  }

  return stakeholder;
}

const isPrivileged = isEmpowerPrivilegedMode();

// StakeholderEditor encapsulates the edit stakeholders modal and add person modal
export default class StakeholderEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddPersonModalOpen: false,
      loading: false,
      stakeholders: deepCopy(props.stakeholders),
    };

    this.handleAddPerson = this.handleAddPerson.bind(this);
    this.handleAddPersonClick = this.handleAddPersonClick.bind(this);
    this.handleAddPersonModalClose = this.handleAddPersonModalClose.bind(this);
  }

  handleAddPersonClick(index) {
    this.setState({ isAddPersonModalOpen: true, addPersonIndex: index });
  }

  handleAddPersonModalClose() {
    const newState = {
      isAddPersonModalOpen: false,
      addPersonIndex: undefined,
      stakeholders: this.state.stakeholders,
    };
    let role, index;
    if (this.props.stakeholdersType === "beneficiaries") {
      role = "BENEFICIARY";
      index = this.state.addPersonIndex;
    } else if (this.props.stakeholdersType === "owners") {
      role = this.state.addPersonIndex === 0 ? "PRIMARY" : "SECONDARY";
      index =
        role === "PRIMARY"
          ? this.state.addPersonIndex
          : this.state.addPersonIndex - 1;
    }

    newState.stakeholders[role][index] = {
      personName: "",
      personId: "",
      percentage: "",
      role,
    };
    this.setState(newState);
  }

  handleAddPerson(newPerson) {
    this.setState({ loading: true, isAddPersonModalOpen: false });

    // 1. Call onPersonAdded prop, which will refresh list of people and apply it to dropdowns
    // 2. Locate the index where the user had selected "Add Person" (addPersonIndex) and set it to the new person
    // 3. Clear the addPersonIndex
    this.props.onPersonAdded().then(() => {
      const newState = {
        loading: false,
        stakeholders: this.state.stakeholders,
      };

      if (this.state.addPersonIndex != null) {
        if (this.props.stakeholdersType === "beneficiaries") {
          newState.stakeholders.BENEFICIARY[
            this.state.addPersonIndex
          ] = personToStakeholder(newPerson, "BENEFICIARY");
        } else if (this.props.stakeholdersType === "owners") {
          const role =
            this.state.addPersonIndex === 0 ? "PRIMARY" : "SECONDARY";
          const index =
            role === "PRIMARY"
              ? this.state.addPersonIndex
              : this.state.addPersonIndex - 1;
          newState.stakeholders[role][index] = personToStakeholder(
            newPerson,
            role
          );
        }

        newState.addPersonIndex = undefined;
      }

      this.setState(newState);
    });
  }

  displayErrors(errors) {
    this.setState({
      errors,
      loading: false,
    });
  }

  render() {
    const { stakeholdersType, people } = this.props;
    const { isAddPersonModalOpen, stakeholders, errors } = this.state;

    const modalConfig =
      stakeholdersType === "beneficiaries"
        ? {
            componentName: "EditBeneficiariesModal",
            title: "Edit Beneficiaries",
          }
        : {
            componentName: "EditOwnersModal",
            title: "Edit Owners",
          };

    return (
      <>
        <Modal
          isOpen={true}
          componentName={modalConfig.componentName}
          title={modalConfig.title}
          className="pc-modal--small"
          loading={this.state.loading}
          onClosed={this.props.onClosed}
        >
          <EditStakeholdersView
            stakeholdersType={stakeholdersType}
            stakeholders={stakeholders}
            errors={errors}
            people={people}
            onAddPersonClick={this.handleAddPersonClick}
            onCancel={this.props.onCancel}
            onSubmit={this.props.onSubmit}
            isPrivileged={isPrivileged}
          />
        </Modal>
        {isAddPersonModalOpen && (
          <Modal
            isOpen={true}
            componentName="Add Person Modal"
            title="New Person"
            className="pc-modal--small"
            onClosed={this.handleAddPersonModalClose}
          >
            <AddPersonContainer
              people={people}
              onCancel={this.handleAddPersonModalClose}
              onSubmit={this.handleAddPerson}
            />
          </Modal>
        )}
      </>
    );
  }
}

StakeholderEditor.propTypes = {
  stakeholders: PropTypes.object.isRequired,
  stakeholdersType: PropTypes.oneOf(["owners", "beneficiaries"]).isRequired,
  onPersonAdded: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  people: PropTypes.array,
};

StakeholderEditor.defaultProps = {
  people: [],
};
