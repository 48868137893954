import PropTypes from "prop-types";
import React from "react";
import TableClient from "libs/pcap/table/tableClient/TableClient";
import Row from "libs/pcap/table/Row";
import { isEqual, isEmpty } from "underscore";
import Search from "libs/pcap/table/Search";
import searchData from "libs/pcap/table/data/search";
import memoizeOne from "memoize-one";
import FancySelect from "components/common/form/FancySelect/FancySelect";
import defaultColumns from "./columns/default";
import ManualHoldingsAddRow from "./ManualHoldings/ManualHoldingsAddRow";
import ManualHoldingsEditRow from "./ManualHoldings/ManualHoldingsEditRow";

const EMPOWER_PAGER_SIZE = 25;

const searchConfig = [
  (d) => d.ticker,
  (d) => d.description,
  (d) => d.quantity,
  (d) => d.price,
  (d) => d.change,
  (d) => d.oneDayPercentChange,
  (d) => d.oneDayValueChange,
  (d) => d.value,
];

const getFilteredHoldings = memoizeOne(
  (searchInput, transactions, searchConfig) => {
    return searchData(searchInput, transactions, searchConfig);
  }
);

const SEARCH_BAR_HEIGHT = 43.5;

export class HoldingsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: props.searchInput || "",
    };
    this.handleRowClick = this.handleRowClick.bind(this);
    this.getRow = this.getRow.bind(this);
    this.handleCloseEditor = this.handleCloseEditor.bind(this);
    this.handleAddHoldingClick = this.handleAddHoldingClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleGroupByChange = this.handleGroupByChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      isEmpty(nextProps.holdings) &&
      nextProps.showAddHolding &&
      !prevState.newHolding &&
      !prevState.shouldCloseForm
    ) {
      return { newHolding: { userAccountId: nextProps.userAccountId } };
    }

    if (prevState.shouldCloseForm) {
      return { shouldCloseForm: undefined };
    }

    return null;
  }

  handleRowClick(ev, data) {
    if (this.props.isSelectable) {
      this.setState(
        {
          selectedHolding: isEqual(data, this.state.selectedHolding)
            ? undefined
            : data,
        },
        () => {
          if (this.state.selectedHolding) {
            if (this.props.onHoldingSelected) {
              this.props.onHoldingSelected(this.state.selectedHolding);
            }
          } else if (this.props.onHoldingUnSelected) {
            this.props.onHoldingUnSelected(data);
          }
        }
      );
      return;
    }
    this.setState({ holdingBeingEdited: data, newHolding: undefined }, () => {
      if (this.props.onRowClick) {
        this.props.onRowClick(data);
      }
    });
  }

  handleCloseEditor() {
    this.setState({
      holdingBeingEdited: undefined,
      newHolding: undefined,
      shouldCloseForm: true,
    });
  }

  handleAddHoldingClick() {
    this.setState({
      holdingBeingEdited: undefined,
      newHolding: { userAccountId: this.props.userAccountId },
      searchInput: "",
    });
  }

  handleSearch(results, input) {
    this.setState({
      searchInput: input,
    });
  }

  handleGroupByChange(e) {
    let selectedGroupBy = this.props.groupBys.find(
      (gb) => gb.value === e.target.value
    );
    this.setState({
      groupByFn: selectedGroupBy.groupByFn ? selectedGroupBy.groupByFn : null,
    });
  }

  // If there are no holdings passed, and the props.showAddButton === true, then we need to display
  // the AddHoldingRow form by default so the user can enter a holding.
  getRow(props) {
    if (this.state.newHolding && props.index === 0) {
      let AddHoldingRowComponent = this.props.AddHoldingRow;
      let AddHoldingRow = (
        <AddHoldingRowComponent
          {...props}
          className={`qa-datagrid-row ${props.className || ""}`}
          columns={this.props.columns}
          model={this.state.newHolding}
          onCancel={this.handleCloseEditor}
          onSubmit={this.props.onCreateHolding}
          onFetchQuote={this.props.onFetchQuote}
          holdings={this.props.holdings}
          onSaved={this.handleCloseEditor}
          key={"newHoldingRow"}
        />
      );
      // If there are no holdings passed OR all of them are filtered through search, AND there's a holding being created, only render the AddHoldingRow
      let { holdings, showSearch } = this.props;
      let { searchInput } = this.state;
      if (
        (isEmpty(this.props.holdings) ||
          (showSearch &&
            isEmpty(
              getFilteredHoldings(searchInput, holdings, searchConfig)
            ))) &&
        this.state.newHolding
      ) {
        return AddHoldingRow;
      }
      return [AddHoldingRow, <Row {...props} key={props.index} />];
    }
    if (
      this.state.holdingBeingEdited &&
      isEqual(this.state.holdingBeingEdited, props.data)
    ) {
      let EditHoldingRow = this.props.EditHoldingRow;
      return (
        <EditHoldingRow
          {...props}
          columns={this.props.columns}
          className={`qa-datagrid-row ${props.className || ""}`}
          model={this.state.holdingBeingEdited}
          onCancel={this.handleCloseEditor}
          onSubmit={this.props.onUpdateHolding}
          onRemoveHolding={this.props.onRemoveHolding}
          onFetchQuote={this.props.onFetchQuote}
          holdings={this.props.holdings}
          onSaved={this.handleCloseEditor}
        />
      );
    }
    if (
      this.state.selectedHolding &&
      isEqual(this.state.selectedHolding, props.data)
    ) {
      const SelectedRow = this.props.SelectedRow;
      return (
        <SelectedRow
          {...props}
          className={`${props.className} holdings-grid-table__selected-holding qa-active-row qa-datagrid-row`}
          title={props.data.accountName}
        />
      );
    }
    return (
      <Row
        {...props}
        className={`qa-datagrid-row ${props.className || ""}`}
        title={props.data.accountName}
      />
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    let handleRowClick;
    let {
      className,
      isEditable,
      isSelectable,
      zeroState,
      holdings,
      showAddHolding,
      showSearch,
      groupBys,
      hasStickyHeader,
      stickyHeaderOffset,
    } = this.props;
    let { searchInput, newHolding } = this.state;
    let filteredHoldings = showSearch
      ? getFilteredHoldings(searchInput, holdings, searchConfig)
      : holdings;
    // If there's no holding and the add mode is enabled (ref constructor), pre append it to the collection so the
    // grid doesn't render zero state
    if (newHolding && filteredHoldings.length === 0) {
      filteredHoldings = filteredHoldings.slice(0);
      filteredHoldings.unshift(newHolding);
    }

    // enables row clicks if the component is initialized as `editable` and we're not editing a row at the moment
    if (isEditable || isSelectable) {
      handleRowClick = this.handleRowClick;
      className += " table--actionable";
    }

    return (
      <div className={this.props.containerClassName}>
        <div
          className={
            hasStickyHeader
              ? "holdings-grid-sticky-header"
              : "holdings-grid-default-header"
          }
          style={hasStickyHeader ? { top: stickyHeaderOffset + "px" } : {}}
        >
          {showSearch && (
            <div className="holdings-grid-table__search-container qa-holdings-grid-search pc-u-ml-">
              <span className="pc-label--inline">Search holdings</span>
              <Search
                containerClassName="pc-u-pl-"
                clearClassName="qa-holdings-reset-search"
                className="holdings-grid-table__search qa-holdings-search-input"
                label=""
                onSearch={this.handleSearch}
                searchConfig={searchConfig}
                searchInput={this.state.searchInput}
              />
            </div>
          )}
          {groupBys && (
            <div className="holdings-grid-table__groupBy-container qa-holdings-grid-groupBy pc-u-ml-">
              <label
                className="pc-label--inline"
                id="ariaGroupByIdLabel"
                htmlFor="ariaGroupByIdInput"
              >
                Group by
              </label>
              <FancySelect
                name="groupBy"
                value={"None"}
                inline={!IS_EMPOWER}
                className="Select--small"
                containerClassName={"holdings-grid-table__groupBy"}
                options={groupBys}
                onChange={this.handleGroupByChange}
                isSearchable={false}
                inputId="ariaGroupByIdLabel"
                selectInputId="ariaGroupByIdInput"
              />
            </div>
          )}
        </div>
        {showAddHolding && (
          <button
            type="button"
            className="pc-btn pc-btn--primary pc-btn--small pc-u-ml-- qa-add-holdings-button"
            onClick={this.handleAddHoldingClick}
          >
            Add Holding
          </button>
        )}
        {filteredHoldings && filteredHoldings.length ? (
          <TableClient
            {...this.props}
            groupBy={this.state.groupByFn}
            keyAccessor={HoldingsGrid.keyAccessor}
            data={filteredHoldings}
            onRowClick={handleRowClick}
            Row={this.getRow}
            tableClassName={`table--primary table__body--primary pc-holdings-grid qa-datagrid-rows centi ${className}`}
            className="holdings-grid-table-client-container tabular-numbers js-holdings-grid-table-container"
            stickyHeaderOffset={
              hasStickyHeader ? stickyHeaderOffset + SEARCH_BAR_HEIGHT : 0
            }
          />
        ) : (
          <>
            {typeof zeroState === "string" && (
              <div className="pc-u-mt pc-block--center qa-holdings-grid-zero-state">
                <strong>{zeroState}</strong>
              </div>
            )}
            {typeof zeroState === "function" && zeroState(this.props)}
            {typeof zeroState === "object" && zeroState}
          </>
        )}
      </div>
    );
  }
}

HoldingsGrid.propTypes = {
  holdings: PropTypes.array.isRequired,
  groupBys: PropTypes.array,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  searchInput: PropTypes.string,
  headerRowClassName: PropTypes.string,
  onRowClick: PropTypes.func,
  onHoldingSelected: PropTypes.func,
  onHoldingUnSelected: PropTypes.func,
  onUpdateHolding: PropTypes.func,
  onCreateHolding: PropTypes.func,
  onRemoveHolding: PropTypes.func,
  onFetchQuote: PropTypes.func,
  columns: PropTypes.array,
  isEditable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  showSearch: PropTypes.bool,
  showAddHolding: PropTypes.bool,
  paginator: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  zeroState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  AddHoldingRow: PropTypes.oneOfType([PropTypes.func]),
  EditHoldingRow: PropTypes.oneOfType([PropTypes.func]),
  SelectedRow: PropTypes.func,
  userAccountId: PropTypes.number,
  hasStickyHeader: PropTypes.bool,
  stickyHeaderOffset: PropTypes.number,
};

HoldingsGrid.defaultProps = {
  columns: defaultColumns,
  showAddHolding: false,
  showSearch: false,
  isEditable: false,
  isSelectable: false,
  zeroState: "No holdings match your search criteria",
  className: "",
  containerClassName: undefined,
  groupBys: undefined,
  searchInput: undefined,
  headerRowClassName: undefined,
  onRowClick: undefined,
  onHoldingSelected: undefined,
  onHoldingUnSelected: undefined,
  onUpdateHolding: undefined,
  onCreateHolding: undefined,
  onRemoveHolding: undefined,
  onFetchQuote: undefined,
  AddHoldingRow: ManualHoldingsAddRow,
  EditHoldingRow: ManualHoldingsEditRow,
  SelectedRow: Row,
  userAccountId: undefined,
  paginator: {
    stepSize: IS_EMPOWER ? EMPOWER_PAGER_SIZE : 100,
    start: 0,
    className: "holdings-grid-paginator-container",
  },
  hasStickyHeader: false,
  stickyHeaderOffset: 0,
};
