import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import AppOverlay from "appOverlay";
import ClosedAccountsFilter from "closedAccountsFilter";
import CashFlowState from "stateModels/cashFlowState";
import PERSONALCAPITAL from "PERSONALCAPITAL";
import AccountSelector2 from "views/components/accountSelectorFactory2";
import DateRangeSelector2 from "views/components/dateRangeSelectorView2";
import LineGraph from "views/components/cashFlow/lineGraph";
import CashFlowBarGraph from "views/components/cashFlow/cashFlowBarGraph";
import moment from "moment";
import DateUtils from "libs/pcap/utils/date";
import transitionEndEvent from "libs/pcap/utils/transitionEndEvent";
import takeAways from "takeAways";
import { getTransactionSummaries } from "libs/pcap/utils/getTransactionSummaries";
import * as DateUtils2 from "libs/pcap/utils/date2";
import FixedControlsMixin from "mixins/fixFeatureControls";
import NoopFixedControlsMixin from "mixins/noopFixFeatureControls/index";
import buildCumulativeCashFlow from "libs/pcap/utils/buildCumulativeCashFlow";
import EventBus from "eventBus";
import TransactionsGridContainer from "components/TransactionsGridV3/TransactionsGridContainer";
import BackboneReactView from "common/BackboneReactView";
import isItFirstDayOfTheMonth from "libs/pcap/utils/isItFirstDayOfTheMonth";
import parseResponseErrors from "libs/pcap/utils/response";
import "backbone.view";
import deepCopy from "deep-copy";
import ReactDOM from "react-dom";

function buildPreviousMonthFilter(filter) {
  var prevStartDate = moment(filter.startDate, DateUtils.API_FORMAT).subtract(
    1,
    "month"
  );
  var prevEndDate = prevStartDate.clone().endOf("month");

  return _.extend({}, filter, {
    startDate: prevStartDate.format(DateUtils.API_FORMAT),
    endDate: prevEndDate.format(DateUtils.API_FORMAT),
  });
}

const errorMessage = "Not implemented";

const ARIA_LABELS = {
  onChangeInputs: "Loading $mode data for $accounts $dateRange",
  cashFlow: "cash flow",
  allAccounts: "all accounts",
  customAccountSelection: "$nbAccounts accounts",
  customDateRange: "from $startDate to $endDate",
  dateRange: "for $range",
};

/**
 * This is the base class for `cashFlowView` and `cashFlowIncomeExpenseView`.
 * It implements methods for:
 * - maintaining the page filter;
 * - handling account and date selectors;
 * - fetching and processing user transactions data;
 * - managing data grid;
 */
var CashFlowBaseView = Backbone.View.extend({
  // share the filter between line and bar charts
  graphFilter: {},

  initialize: function (options) {
    this.nowString = moment().format(DateUtils.DISPLAY_FORMAT);
    this.options = options;
    this.dataTransactionsCurrPeriod = {};
    this.dataTransactionsPrevPeriod = {};
    this.trackViewState("cashFlow", CashFlowState, false);
    this.saveInternalState("cashFlow", { startDate: this.options.startDate });
  },

  /**
   * Returns `true` if any full month or the current partial month selected.
   * @returns {Boolean} The boolean flag.
   */
  isCompleteOrCurrentMonthSelected: function () {
    var startDate = this.getStartDateObj();
    var endDate = this.getEndDateObj();
    return (
      DateUtils2.isMonthRange(startDate, endDate) ||
      DateUtils2.isCurrentMonthRange(startDate, endDate)
    );
  },

  /**
   * Returns the chart class to be displayed for the current state of the page.
   */

  getChartClass: function () {
    throw new Error(errorMessage);
  },

  initializeBarGraph: function () {
    throw new Error(errorMessage);
  },

  buildBarGraphData: function () {
    throw new Error(errorMessage);
  },

  /**
   * Provides custom ticks for line chart X axis.
   * Should be implemented by the child component.
   */
  getLineChartXTicks: function () {
    throw new Error(errorMessage);
  },

  /**
   * Specifies the format for line chart X axis ticks.
   * Should be implemented by the child component.
   */
  getLineChartXTickFormat: function () {
    throw new Error(errorMessage);
  },

  /**
   * Returns a complete month domain for the complete or the current (incomplete) month,
   * or `undefined` otherwise.
   *
   * @returns {Array}   The domain.
   */
  getLineChartXDomain: function () {
    if (this.isCompleteOrCurrentMonthSelected()) {
      var startDate = this.getStartDateObj().startOf("month");
      var endDate = startDate.clone().endOf("month");
      return [startDate, endDate];
    }
  },

  getTransactionsFilter: function () {
    return _.extend(this.graphFilter, {
      // 'all' value is passed in during view initialization to setup account selector.
      // We don't need it in the filter however.
      userAccountIds:
        this.options.userAccountIds === "all"
          ? []
          : this.options.userAccountIds,
      startDate: this.getStartDate(true),
      endDate: this.getEndDate(true),
      incomeExpenseMode: this.incomeExpenseMode,
      categoryId: this.selectedCategoryId,
      merchant: this.selectedMerchantId,
      categoryName: this.selectedCategory?.name,
      merchantName: this.selectedMerchant?.name,
    });
  },

  getCashFlow: function (data = {}, filter, intervalType) {
    const { startDate, endDate } = filter;

    return getTransactionSummaries({
      transactions: data.transactions || [],
      startDate,
      endDate,
      categories: this.transactionCategories,
      intervalType: intervalType,
      includeAggregates: true,
      includeCategoryAggregates: true,
      includeMerchants: true,
      includeMerchantAggregates: true,
      includeCategoryTransactions: true,
      includeMerchantTransactions: true,
      includeCashFlow: true,
      // Put this behind a feature flag
      includeCategoryBasedCashFlow: this.categoryBasedCashFlowFlag,
    }).cashFlowSummary;
  },

  buildLineGraphData: function () {
    var filter = this.getTransactionsFilter();
    var isCompleteOrCurrentMonthSelected =
      this.isCompleteOrCurrentMonthSelected();
    var previousMonthData;
    if (
      !_.isEmpty(this.dataTransactionsPrevPeriod) &&
      isCompleteOrCurrentMonthSelected
    ) {
      var prevFilter = buildPreviousMonthFilter(filter);
      this.previousMonthCashFlow = this.filterTransactions(
        this.dataTransactionsPrevPeriod,
        prevFilter
      );
      previousMonthData = buildCumulativeCashFlow(
        this.previousMonthCashFlow,
        prevFilter.incomeExpenseMode
      );
    }
    this.currentMonthCashFlow = this.filterTransactions(
      this.dataTransactionsCurrPeriod,
      filter
    );
    return {
      currentMonthData: buildCumulativeCashFlow(
        this.currentMonthCashFlow,
        filter.incomeExpenseMode
      ),
      previousMonthData: previousMonthData || [],
    };
  },

  initializeLineGraph: function () {
    // add another div so that `.js-line-chart-container` stays in DOM when the view is destroyed
    var el = $("<div>").appendTo(this.$(".js-line-chart-container"));
    var filter = this.getTransactionsFilter();
    return new LineGraph({
      el: el,
      filter: filter,
      xTicks: this.getLineChartXTicks(),
      xDomain: this.getLineChartXDomain(),
      xTickFormat: this.getLineChartXTickFormat(),
      data: this.buildLineGraphData(),
    });
  },

  /**
   * Re-filters the existing data stored in `this.dataTransactionsPrevPeriod` and `this.dataTransactionsPrevPeriod`
   * to match the current client-side filter (merchantId, categoryId and incomeExpenseMode) and applies
   * the updated data set to the graph instance.
   */
  updateGraph: function () {
    var data;
    if (this.graph) {
      if (this.graph instanceof LineGraph) {
        data = this.buildLineGraphData();
        this.graph.setXTicks(this.getLineChartXTicks());
        this.graph.setXDomain(this.getLineChartXDomain());
        this.graph.setXTickFormat(this.getLineChartXTickFormat());
        this.graph.update(data, this.getTransactionsFilter());
      } else if (this.graph instanceof CashFlowBarGraph) {
        data = this.buildBarGraphData();
        this.graph.update(data, this.getTransactionsFilter());
      }
    } else {
      //React based barchart for cashflow income expense
      this.initializeBarGraph();
    }
  },

  /**
   * Creates new or replaces an existing graph with a different type based on the selected date range.
   * Line graph is displayed for a month range, bar graph is for any other range.
   */
  createUpdateGraph: function () {
    var newChartClass = this.getChartClass();
    if (this.graph instanceof newChartClass) {
      this.updateGraph();
    } else {
      var oldGraph = this.graph;
      var lineGraphContainer = this.$(".js-line-chart-container");
      var isLineChart = newChartClass === LineGraph;
      lineGraphContainer.toggleClass(
        "cash-flow__line-chart__container--active",
        isLineChart
      );
      this.graph = isLineChart
        ? this.initializeLineGraph()
        : this.initializeBarGraph();
      if (oldGraph) {
        if (transitionEndEvent) {
          lineGraphContainer.one(transitionEndEvent, function () {
            oldGraph.remove();
          });
        } else {
          oldGraph.remove();
        }
      } else if (this.barGraphEl && isLineChart) {
        //React bar chart in income expense
        ReactDOM.unmountComponentAtNode(this.barGraphEl);
      }
    }
    this.updateSRWithChartInfo();
  },

  updateSRWithChartInfo: function () {
    if (this.donut?.updateAriaInfo) this.donut.updateAriaInfo();
    const { donut, lineBar, bar } = this.getChartsAriaInfo();
    if (this.incomeExpenseMode) {
      this.setSRInfo(
        `${donut.title} ${donut.desc} ${lineBar.title || ""} ${
          lineBar.desc || ""
        } ${bar.title || ""} ${bar.desc || ""}`
      );
    } else {
      this.setSRInfo(`${lineBar.title} ${lineBar.desc}`);
    }
  },

  getModeDiplayName: function () {
    return this.pathNodes?.length
      ? this.pathNodes[0] === "income"
        ? "income"
        : "expenses"
      : ARIA_LABELS.cashFlow;
  },
  informSROnFetchData: function () {
    if (this.accountSelector && this.dateRangeSelector && this.graphFilter) {
      const { allAccountsSelected, checkedUserAccountIds } =
        this.accountSelector;
      const { buttonLabel } = this.dateRangeSelector;
      const { startDate, endDate } = this.graphFilter;
      const {
        onChangeInputs,
        allAccounts,
        customAccountSelection,
        customDateRange,
        dateRange,
      } = ARIA_LABELS;
      let ariaDescription = onChangeInputs
        .replace("$mode", this.getModeDiplayName())
        .replace(
          "$accounts",
          allAccountsSelected
            ? allAccounts
            : customAccountSelection.replace(
                "$nbAccounts",
                checkedUserAccountIds.length
              )
        );
      ariaDescription =
        buttonLabel === "Custom"
          ? ariaDescription.replace(
              "$dateRange",
              customDateRange
                .replace("$startDate", startDate)
                .replace("$endDate", endDate)
            )
          : ariaDescription.replace(
              "$dateRange",
              dateRange.replace("$range", buttonLabel.toLowerCase())
            );
      this.setSRInfo(ariaDescription);
    }
  },
  /**
   * Render takeaways
   * Using the getTakeAway API
   */
  renderTakeaways: function () {
    var takeAway;
    var filter = this.getTransactionsFilter();

    takeAway = takeAways.getTakeaways({
      startDate: filter.startDate,
      endDate: filter.endDate,
      prevCashFlow: this.dataTransactionsPrevPeriod,
      currentCashFlow: this.dataTransactionsCurrPeriod,
      incomeExpenseMode: filter.incomeExpenseMode,
      drillDownLevel: this.getDrilldownLevel()
        ? this.getDrilldownLevel()
        : null,
      categoryId: filter.categoryId,
      merchantId: filter.merchant,
    });

    this.$(".firstTakeAway").text(takeAway.firstTakeAway ?? "");
    this.$(".secondTakeAway").text(takeAway.secondTakeAway ?? "");
  },

  // eslint-disable-next-line sonarjs/cognitive-complexity
  fetchData: function () {
    if (this.empowerZeroStateDisplayed) {
      return;
    }
    AppOverlay.show();

    var filter = this.getTransactionsFilter();
    if (this.watchIdCurrentPeriod) {
      Services.Transactions.get.unwatch(this.watchIdCurrentPeriod);
    }

    var userAccountIds = JSON.stringify(filter.userAccountIds);
    var prevStartDate = moment(filter.startDate, DateUtils.API_FORMAT).subtract(
      1,
      "month"
    );
    var prevEndDate = prevStartDate.clone().endOf("month");

    var params = {
      startDate: filter.startDate,
      endDate: filter.endDate,
      userAccountIds: userAccountIds,
    };

    var selectedMonthRequest = $.Deferred();
    var previousMonthRequest = $.Deferred();
    var transactionCategoriesRequest = $.Deferred();

    this.informSROnFetchData();

    if (this.isCompleteOrCurrentMonthSelected()) {
      var previousMonthParams = {
        startDate: prevStartDate.format(DateUtils.API_FORMAT),
        endDate: prevEndDate.format(DateUtils.API_FORMAT),
        userAccountIds: userAccountIds,
      };

      Services.Transactions.get(previousMonthParams, function (err, response) {
        if (
          err ||
          (response && response.spHeader && response.spHeader.errors)
        ) {
          previousMonthRequest.reject(err || response.spHeader.errors);
        } else {
          previousMonthRequest.resolve(deepCopy(response.spData));
        }
      });
    } else {
      previousMonthRequest.resolve();
    }

    this.watchIdCurrentPeriod = Services.Transactions.get.watch(
      params,
      function (err, response) {
        if (
          err ||
          (response && response.spHeader && response.spHeader.errors)
        ) {
          selectedMonthRequest.reject(err || response.spHeader.errors);

          // This callback will be called in two "modes":
          // 1. Initial `getUserTransactions` request.
          // 2. As a watcher callback after receiving the transactions update from the server.
          //
          // `selectedMonthRequest` in the "pending" state indicates that
          // we are operating in the first mode.
        } else if (selectedMonthRequest.state() === "pending") {
          selectedMonthRequest.resolve(deepCopy(response.spData));
        } else {
          this.onTransactionsReceived(
            deepCopy(response.spData),
            this.dataTransactionsPrevPeriod
          );
        }
      }.bind(this)
    );

    Services.Profile.getUIPreferences((err, response) => {
      if (response?.spData) {
        this.transactionCategoryRulesFlag =
          response.spData.features.TRANSACTION_CATEGORY_RULES;
        this.categoryBasedCashFlowFlag =
          response.spData.features.ENHANCED_CASHFLOW_ENABLED;
      }
    });

    Services.TransactionCategories.get((err, response) => {
      const errors = parseResponseErrors(err, response);
      if (errors) {
        transactionCategoriesRequest.reject(errors);
      }
      transactionCategoriesRequest.resolve(response?.spData);
    });
    $.when(
      selectedMonthRequest,
      previousMonthRequest,
      transactionCategoriesRequest
    )
      .done(
        function (currentMonthData, previousMonthData, transactionCategories) {
          window.dispatchEvent(new CustomEvent("pageloaded"));

          this.transactionCategories = transactionCategories;
          this.onTransactionsReceived(currentMonthData, previousMonthData);

          // View only gets displayed when the preloader is removed.
          // We need to know when that happens to initialize the fixed headers
          // on the elements when they are visible in the viewport. Otherwise,
          // the fixed headers offset cannot be recalculated.
          this.isDisplayed = true;
          this.trigger("displayed");
        }.bind(this)
      )
      .fail(() => {
        // TODO handle error
        window.dispatchEvent(new CustomEvent("pageloaded"));

        this.isDisplayed = true;
        this.trigger("displayed");
      });
  },

  /**
   * Filters the cash flow using the supplied filter.
   *
   * @param   {Object} data   `getUserTransactions` API response
   * @param   {Object} filter the filter
   * @returns {Object}        cash flow data
   */
  filterTransactions: function (data = {}, filter) {
    var cashFlow = data.cashFlow;
    var categoryId = filter.categoryId;
    var merchantId = filter.merchant;
    if (categoryId && merchantId) {
      var merchantCategories =
        data[
          filter.incomeExpenseMode === "income"
            ? "incomeMerchants"
            : "expenseMerchants"
        ];
      var merchants = merchantCategories && merchantCategories[categoryId];
      var merchant = _.findWhere(merchants, { id: merchantId });
      cashFlow = merchant
        ? merchant.cashFlow
        : this.generateZeroStateLineData(filter.startDate, filter.endDate);
    } else if (categoryId) {
      var categories =
        data[
          filter.incomeExpenseMode === "income"
            ? "incomeCategories"
            : "expenseCategories"
        ];
      var category = _.findWhere(categories, {
        transactionCategoryId: categoryId,
      });
      cashFlow = category
        ? category.cashFlow
        : this.generateZeroStateLineData(filter.startDate, filter.endDate);
    }
    return cashFlow;
  },

  generateZeroStateLineData: function (strDate, eDate) {
    var startDate = moment(strDate),
      endDate = moment(eDate);
    var dummyData = [];
    for (var i = 0, e = endDate.diff(startDate, "day"); i <= e; i++) {
      dummyData.push({
        date: moment(startDate.clone().add(i, "day")),
        moneyIn: 0,
        moneyOut: 0,
        cashflow: 0,
      });
    }
    return dummyData;
  },

  getChartsAriaInfo: function () {
    return {
      donut: {
        title: this.$("#donut-title-id")?.text(),
        desc: this.$("#donut-desc-id")?.text(),
      },
      lineBar: {
        title: this.graph?.$el?.find("svg > title")?.text(),
        desc: this.graph?.$el?.find("svg > desc")?.first().text(),
      },
      bar: {
        title: this.$(".js-bar-chart-container > svg > title")?.text(),
        desc: this.$(".js-bar-chart-container > svg > desc")?.first().text(),
      },
    };
  },
  onTransactionsReceived: function (currentMonthData, previousMonthData) {
    var filter = this.getTransactionsFilter();
    var prevFilter = buildPreviousMonthFilter(filter);

    this.dataTransactionsPrevPeriod = this.getCashFlow(
      previousMonthData,
      prevFilter,
      "DAY"
    );
    this.dataTransactionsCurrPeriod = this.getCashFlow(
      currentMonthData,
      filter,
      "DAY"
    );
    this.createUpdateGraph();
    this.renderTakeaways();
    this.renderDatagrid();
  },

  updateUserAccountIds: function (userAccountIds) {
    this.options.userAccountIds = userAccountIds;
    this.graphFilter.userAccountIds = userAccountIds;
    this.fetchData();
  },

  saveUserAccountIdsToState: function (userAccountIds) {
    this.saveInternalState("cashFlow", { userAccountIds: userAccountIds });
  },

  renderAccountSelector: function () {
    this.accountSelector = new AccountSelector2({
      el: this.$(".accountSelector"),
      excludeManualAccounts: true,
      filter: ["CASH", "INVESTMENT", "CREDIT", "LOAN", "MORTGAGE"],
      closedAccountsFilter: function (accounts) {
        return ClosedAccountsFilter.filter(accounts, this.getStartDateObj());
      }.bind(this),
    });

    this.accountSelector.setSelectedUserAccountIds(this.options.userAccountIds);
    this.accountSelector.on(
      "selectedUserAccountIds",
      this.updateUserAccountIds,
      this
    );
    this.accountSelector.on(
      "selectedUserAccountIds",
      this.saveUserAccountIdsToState,
      this
    );
    this.accountSelector.on(
      "selectedUserAccountIds",
      this.renderDatagrid,
      this
    );
  },

  renderDateRangeSelector: function () {
    this.dateRangeSelector = new DateRangeSelector2({
      el: this.$(".dateSelector"),
      initialStartDate: this.getStartDate(),
      initialEndDate: this.getEndDate(),
      initialNowDate: this.nowString,
      quickPicks: [
        { value: "M,0s", label: "This Month", index: 0 },
        { value: "M,-1s;M,-1e", label: "Last Month", index: 1 },
        { value: "d,-365s", label: "1 Year", index: 2 },
        { value: "y,0s", label: "This Year", index: 3 },
        { value: "y,-1s;y,-1e", label: "Last Year", index: 4 },
      ],
    });

    var transactionsFilter = PERSONALCAPITAL.get("transactionsFilter") || {};
    var filter = this.getTransactionsFilter();
    transactionsFilter.startDate = moment(
      filter.startDate,
      DateUtils.API_FORMAT
    ).toDate();
    transactionsFilter.endDate = moment(
      filter.endDate,
      DateUtils.API_FORMAT
    ).toDate();
    PERSONALCAPITAL.set("transactionsFilter", _.clone(transactionsFilter));

    this.dateRangeSelector.on(
      "change:dateRange",
      function (startDate, endDate) {
        this.setStartDate(startDate);
        this.setEndDate(endDate);
        this.accountSelector.onAccountCollectionChange(); // This will also re-render the account selector
        var selectedAccountIds = this.accountSelector.selectedUserAccountIds;
        this.graphFilter.userAccountIds = selectedAccountIds;
        this.updateUserAccountIds(selectedAccountIds);
        this.saveUserAccountIdsToState(selectedAccountIds);
        this.renderDatagrid(selectedAccountIds);
      }.bind(this)
    );
  },

  removeAccountSelector: function () {
    if (this.accountSelector) {
      this.accountSelector.off("selectedUserAccountIds");
      this.accountSelector.close();
      delete this.accountSelector;
    }
  },

  /**
   * Filters data of current period based on category and merchant selected.
   * Also considers the tab selected income or expense.
   * @return  {Array} transactions that are specific to income, expense, categories and merchants.
   */
  getFilteredTransactions() {
    var filter = this.getTransactionsFilter();
    var categoryId = filter.categoryId;
    var merchantId = filter.merchant;

    if (categoryId && merchantId) {
      var merchantCategories =
        this.dataTransactionsCurrPeriod[
          filter.incomeExpenseMode === "income"
            ? "incomeMerchants"
            : "expenseMerchants"
        ];
      var merchants = merchantCategories && merchantCategories[categoryId];
      var merchant = _.findWhere(merchants, { id: merchantId });
      return merchant?.transactions || [];
    } else if (categoryId) {
      var categories =
        this.dataTransactionsCurrPeriod[
          filter.incomeExpenseMode === "income"
            ? "incomeCategories"
            : "expenseCategories"
        ];
      var category = _.findWhere(categories, {
        transactionCategoryId: categoryId,
      });
      return category?.transactions || [];
    }

    return (
      this.dataTransactionsCurrPeriod[filter.incomeExpenseMode]?.transactions ||
      []
    );
  },

  removeDateRangeSelector: function () {
    if (this.dateRangeSelector) {
      this.dateRangeSelector.off("change:dateRange");
      this.dateRangeSelector.close();
      delete this.dateRangeSelector;
    }
  },

  /* ******************** GRID ******************* */

  /**
   * Update the props for transactions gird component based on tab and accounts selected.
   * @param  {Array} accountIds array of transactions to be modified.
   */
  renderDatagrid: function (accountIds) {
    let props = {
      isEditable: true,
      className: "pc-u-mt-",
      startDate: this.getStartDateObj(),
      endDate: this.getEndDateObj(),
      categories: this.transactionCategories,
      transactionCategoryRulesFlag: this.transactionCategoryRulesFlag,
    };

    switch (this.incomeExpenseMode) {
      case "income":
      case "expense":
        props.transactions = this.getFilteredTransactions();
        break;
      default:
        props.transactions =
          this.dataTransactionsCurrPeriod?.transactions || [];
    }

    if (!_.isEmpty(accountIds)) {
      props.transactions = props.transactions.filter((t) =>
        accountIds.includes(t.userAccountId)
      );
    }

    if (this.dataGridV2) {
      this.dataGridV2.update(props);
    } else {
      this.dataGridV2 = new BackboneReactView({
        el: ".gridFrame",
        component: TransactionsGridContainer,
        componentProps: props,
      });
    }
  },

  /* ************** DATE METHODS ***************** */

  getStartDate: function (asTrans) {
    if (!this.options.startDate) {
      this.setStartDate(
        moment().startOf("month").format(DateUtils.DISPLAY_FORMAT)
      );
    }

    if (asTrans) {
      return moment(this.options.startDate, DateUtils.DISPLAY_FORMAT).format(
        DateUtils.API_FORMAT
      );
    }
    return this.options.startDate;
  },

  getStartDateObj: function () {
    var sd = this.getStartDate();
    return moment(sd, DateUtils.DISPLAY_FORMAT);
  },

  getEndDateObj: function () {
    var ed = this.getEndDate();
    return moment(ed, DateUtils.DISPLAY_FORMAT);
  },

  getEndDate: function (asTrans) {
    if (!this.options.endDate) {
      // If is the first day of the month PFM-2244
      if (isItFirstDayOfTheMonth()) {
        this.setEndDate(
          moment().endOf("month").format(DateUtils.DISPLAY_FORMAT)
        );
      } else {
        this.setEndDate(moment().format(DateUtils.DISPLAY_FORMAT));
      }
    }

    if (asTrans) {
      return moment(this.options.endDate, DateUtils.DISPLAY_FORMAT).format(
        DateUtils.API_FORMAT
      );
    }
    return this.options.endDate;
  },

  setStartDate: function (dateString) {
    this.options.startDate = dateString;
    this.saveInternalState("cashFlow", { startDate: dateString });
    this.graphFilter.startDate = moment(
      dateString,
      DateUtils.DISPLAY_FORMAT
    ).format(DateUtils.API_FORMAT);
  },

  setEndDate: function (dateString) {
    this.options.endDate = dateString;
    this.saveInternalState("cashFlow", { endDate: dateString });
    this.graphFilter.endDate = moment(
      dateString,
      DateUtils.DISPLAY_FORMAT
    ).format(DateUtils.API_FORMAT);
  },

  /* ************** ACCESSIBILITY METHODS ***************** */

  setSRInfo: function (message) {
    const self = this;
    const srDelay = 10;

    setTimeout(function () {
      self.$("#cash-flow__info").html(message);
    }, srDelay);
  },

  unwatchAccounts: function () {
    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unwatch();
      this.getAccountsSubscription.off("change");
      this.getAccountsSubscription = null;
    }

    if (this.fetchAccountsCancelablePromise) {
      this.fetchAccountsCancelablePromise.cancel();
    }
  },

  remove: function () {
    if (this.watchIdCurrentPeriod) {
      Services.Transactions.get.unwatch(this.watchIdCurrentPeriod);
      delete this.watchIdCurrentPeriod;
    }

    this.removeAccountSelector();
    this.removeDateRangeSelector();
    this.destroyFixedControls();
    this.unwatchAccounts();

    EventBus.trigger("viewClosing");
    Backbone.View.prototype.remove.apply(this, arguments);
  },
});

if (IS_IFRAMED || IS_EMPOWER) {
  _.extend(CashFlowBaseView.prototype, NoopFixedControlsMixin);
} else {
  _.extend(CashFlowBaseView.prototype, FixedControlsMixin);
}

export default CashFlowBaseView;
