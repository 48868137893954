/* Colors as defined in scss/framework/settings/_colors.scss */

export const COLOR_SCALE_OLD = {
  CURIOUS_BLUE: "#407cca",
  BERMUDA: "#7fd7c3",
  TANGERINE_YELLOW: "#ffc708",
};

export const COLOR_SCALE = {
  BLACK: "#000",
  TANGAROA_V2: "#0c2340",
  BLACK_PEARL_V2: "#1d272d",
  TANGAROA_V3: "#233038",
  ATOMIC: "#3c4d57",
  BLUE_BAYOUX: "#5b7381",
  SHUTTLE_GREY_V2: "#586770",
  ALUMINIUM_V2: "#7e8b91",
  GULL_GREY: "#abb0b2",
  IRON_V2: "#c4cacc",
  WHITE: "#fff",
  COBALT: "#0047bb",
  DARK_CERULEAN_V2: "#004b87",
  NAVY_BLUE: "#005eb8",
  NAVY_BLUE_V2: "#0072ce",
  PACIFIC_BLUE_V2: "#009cde",
  PACIFIC_BLUE_V3: "#007faf",
  DEEP_SKY_BLUE: "#00cfff",
  PICTON_BLUE: "#418fde",
  TURQUOISE_BLUE: "#6ad1e3",
  TROPICAL_BLUE_V2: "#a7c6ed",
  CORNFLOWER_V2: "#8dc8e8",
  PATTENS_BLUE: "#dae0e2",
  ALICE_BLUE: "#eef3f5",
  TURQUOISE: "#3fd8b6",
  LIGHT_CYAN: "#e7faff",
  INCH_WORM: "#97d700",
  SCHOOL_BUS_YELLOW: "#fed800",
  PALE_GOLDENROD: "#f2e7aa",
  DARK_ORCHID: "#9b26b6",
  CARDINAL_V2: "#ba1f2b",
  ALIZARIN: "#df2a33",
  ORANGE_RED: "#ff490d",
  BURNT_ORANGE: "#ff7f32",
  ROMANTIC: "#ffbf99",
  PERSIAN_ROSE: "#e93cac",
  COTTON_CANDY: "#fbbbe3",
  WATER: "#0077CC",
  TOMATO: "#DB291F",
  LAKE: "#009CDE",
  // Legacy
  DARK_TANGERINE: "#ff9e15",
  SUMMER_SKY2: "#3b8fe1",
  SORBUS: "#E8663C",
  BASIL: "#008A00",
};

const RETIREMENT_FEE_ANALYZER_CHART_COLORS_V3 = {
  CONTRIBUTIONS: COLOR_SCALE.WATER,
  EARNINGS: COLOR_SCALE.LAKE,
};

export const POSITIVE = COLOR_SCALE.BASIL;
export const NEGATIVE = COLOR_SCALE.TOMATO;
export const WARNING = COLOR_SCALE.DARK_TANGERINE;
export const PRIMARY = COLOR_SCALE.SUMMER_SKY2;
export const RETIREMENT_FEE_ANALYZER_CHART_COLORS =
  RETIREMENT_FEE_ANALYZER_CHART_COLORS_V3;
export const ASSETS = COLOR_SCALE.COBALT;
export const LIABILITIES = COLOR_SCALE.SORBUS;
export const REBRAND_ALLOCATION_COLORS = {
  US_STOCKS: "#ec6f27",
  INTL_STOCKS: "#f6bf16",
  US_BONDS: "#00b8d0",
  INTL_BONDS: "#6bdbd5",
  ALTERNATIVES: "#db3d72",
  CASH: "#4664b4",
  UNCLASSIFIED: "#d6d6d6",
};
