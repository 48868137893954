import PropTypes from "prop-types";
import React from "react";
import mixpanel from "mixpanel";
import _ from "underscore";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import { getEventData } from "components/opportunitiesSummary/InsightsUtils";

const WARNING_SYMBOL_CODE = 10;
const DANGER_SYMBOL_CODE = 20;

class Scorecard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headers:
        this.props.message.resources[0].jsonContent.scorecardData.tableHeaders,
      rows: this.props.message.resources[0].jsonContent.scorecardData.tableRows,
    };
  }

  componentDidMount() {
    mixpanel.trackEvent(
      `View Insight`,
      getEventData({ message: this.props.message })
    );

    this.props.storeTooltipRef(
      document.getElementById("scorecardTitleTooltip"),
      { container: "body", placement: "top" }
    );
  }

  generateRows() {
    return _.map(this.state.rows, (row, i) => {
      if (!row.isAvailable) {
        return null;
      }

      const hasToolTip = row.tooltip !== undefined && row.tooltip !== "";

      let iconClass = "";
      if (row.symbol === WARNING_SYMBOL_CODE) {
        iconClass = " scorecard__table-cell--warning";
      } else if (row.symbol === DANGER_SYMBOL_CODE) {
        iconClass = " scorecard__table-cell--danger";
      }

      return (
        <tr key={i + "scorecard-row"} className="scorecard__table-row">
          <td className="scorecard__table-cell scorecard__table-cell--gray">
            {row.title}
          </td>
          <td
            ref={(el) => {
              this.props.storeTooltipRef(el, {
                container: "body",
                placement: "top",
              });
            }}
            data-toggle={hasToolTip ? "tooltip" : null}
            data-original-title={hasToolTip ? row.tooltip : null}
            className={`scorecard__table-cell scorecard__table-cell--with-icon${iconClass}${
              hasToolTip ? " insight__tooltip" : ""
            }`}
          >
            <div> {row.actualText}</div>
            <div>{row.actualValue}</div>
          </td>
          <td className="scorecard__table-cell scorecard__table-cell--blue">
            <div>{row.reccommendedText}</div>
            <div>{row.reccommendedValue}</div>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { message } = this.props;
    return (
      <div className="insight__message">
        <div
          className="insight__header qa-insight-header"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div
          className="insight__summary"
          dangerouslySetInnerHTML={{ __html: message.summary }}
        />
        <table className="scorecard__table">
          <tbody>
            <tr className="scorecard__table-row">
              <th className="scorecard__table-cell scorecard__table-cell--header" />
              <th className="scorecard__table-cell scorecard__table-cell--header">
                {this.state.headers.actual}
              </th>
              <th
                className="scorecard__table-cell scorecard__table-cell--header scorecard__table-cell--reccomended-header"
                dangerouslySetInnerHTML={{
                  __html: this.state.headers.reccommended,
                }}
              />
            </tr>
            {this.generateRows()}
          </tbody>
        </table>
        <div className="insight__buttons">
          {getActionButtons({
            message,
            appointmentReason: "Portfolio Review",
          })}
        </div>
      </div>
    );
  }
}

Scorecard.propTypes = {
  message: PropTypes.object,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
};
Scorecard.defaultProps = {
  message: undefined,
  storeTooltipRef: undefined,
};

export default withTooltips(Scorecard);
