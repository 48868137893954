import moment from "moment";
import objectPath from "object-path";
import Services from "services";
import { isUndefined, isEmpty } from "underscore";
import { merge } from "lodash";
import Mixpanel from "mixpanel";
import { API_FORMAT } from "libs/pcap/utils/date2";
import abFrameworkProperties from "libs/pcap/utils/abFrameworkProperties";
import { promisify } from "utils/service";
import { replaceHash } from "./location";

export const COUNTRY_CODE_CANADA = "CA";
const TWO_HUNDRED_AND_FIFTY_K_THRESHOLD = 250000;

export const MAD_LIBS_REVIEW_APPT_DAYS_AWAY = 5;
export const MAD_LIBS_REVIEW_APPT_START_DATE = moment().add(
  MAD_LIBS_REVIEW_APPT_DAYS_AWAY,
  "days"
);

export const SESSION_WIDE_CUSTOM_APPT_SCHEDULER =
  "SESSION_WIDE_CUSTOM_APPT_SCHEDULER";
export const VIEW_TEMPLATES_TO_SKIP = [SESSION_WIDE_CUSTOM_APPT_SCHEDULER];

export const FIRST_USE_APPT_TYPES = [
  {
    appointmentType: "INITIAL_CONSULTATION",
    optionalDurations: [30, 60], // eslint-disable-line no-magic-numbers
    targetAdvisorType: "SALES",
    displayName: "Information Gathering",
    defaultDuration: 30,
  },
];

const SCHEDULE_APPOINTMENT_REVIEW_CONTENT = {
  isNew2019Version: true,
  attributionSource: "MAD_LIBS_DISMISSED_REVIEW_MODAL",
  modalTitle: "Our financial professionals are here to help",
  title: "Schedule a Review for Later",
  description:
    "Our financial professionals are here to help, but take your time to link your accounts, see your finances in one place, and get to know our tools. Then hold a review with one of our financial professionals. Our investment advisors abide by the fiduciary standard to make a recommendation in your best interest.<br><br>Here are some appointments available next week.",
  showDisclaimerLink: false,
};

export const SCHEDULE_APPOINTMENT_CONTENT = {
  isNew2019Version: true,
  modalTitle: " ",
  title: "Nice work!",
  titleIcon:
    "https://www.personalcapital.com/static/images/ace/emp-rebrand/pla/trophy.png",
  teaser: "You've earned a free session with an Empower advisor.",
  description:
    "Our financial professional tools are easy to use, but they truly shine when combined with our financial knowledge. We'll review your current situation and then share our personalized assessment focusing on opportunities at hand, yours to keep and completely free.",
  show: true,
};

export const SESSION_STORAGE_KEYS = {
  appointmentSchedulerCustomContentOnce:
    "appointmentSchedulerCustomContentOnce",
  appointmentSchedulerCustomContentPersistent:
    "appointmentSchedulerCustomContentPersistent",
};

/**
 * @param   {String} str         The JSON string to attempt to parse
 * @return  {object} or {null}   Parsed object if str is valid JSON, null otherwise
 */
export function attemptParseJson(str) {
  let result = null;
  if (!str) {
    return result;
  }
  try {
    result = JSON.parse(str);
  } catch (e) {
    /* Noop */
  }
  return result; // Intentionally not passing back the original value when str is invalid JSON
}

export function getFromSessionStorage(key) {
  return attemptParseJson(window.sessionStorage.getItem(key));
}

export function getAndRemoveFromSessionStorage(key) {
  const data = getFromSessionStorage(key);
  window.sessionStorage.removeItem(key);
  return data;
}

export function isPersonCanadian(person) {
  return Boolean(
    person &&
      [person.countryCode, person.countryOfGovtIdIssue].includes(
        COUNTRY_CODE_CANADA
      )
  );
}

export function isFirstUse() {
  // First use is when there are no accounts and no enrollments
  var skipFirstUse = isUndefined(window.PCAP.skipFirstUse)
    ? false
    : window.PCAP.skipFirstUse;
  return (
    window.numberOfAccounts <= 0 && !window.hasEnrollments && !skipFirstUse
  );
}

let nextWeeksAppointments;

export async function prefetchNextWeeksAvailabilities() {
  const fetchNextWeekAvailabilities = promisify(
    Services.Appointment.getAvailabilities
  );
  nextWeeksAppointments = await fetchNextWeekAvailabilities({
    startDate: MAD_LIBS_REVIEW_APPT_START_DATE.format(API_FORMAT),
  });
}

export const getNextWeeksAppointments = () => {
  let availabilities;
  if (
    nextWeeksAppointments?.appointments &&
    !isEmpty(nextWeeksAppointments.appointments)
  ) {
    availabilities = nextWeeksAppointments.appointments.filter(
      (a) => a.availabilityStatus === "FREE"
    );
  }
  return availabilities ? availabilities : null;
};

export async function setAppointmentData() {
  const getAvailabilities = promisify(Services.Appointment.getAvailabilities);
  const getPerson = promisify(Services.Person.get);
  Promise.all([
    getAvailabilities({
      appointmentType: "INITIAL_CONSULTATION",
      duration: 30,
      limit: 3,
    }),
    getAvailabilities({
      startDate: MAD_LIBS_REVIEW_APPT_START_DATE.format(API_FORMAT),
    }),
    getPerson(),
  ]).then(([availabilities, nextWeekAvailabilities, person]) => {
    if (availabilities?.appointments && !isEmpty(availabilities.appointments)) {
      const availableSpots = availabilities.appointments.filter(
        (a) => a.availabilityStatus === "FREE"
      );
      if (availableSpots.length > 0) {
        window.sessionStorage.setItem(
          "firstUseGetAvailabilitiesResponse",
          JSON.stringify(availableSpots)
        );
      }
    }
    if (
      nextWeekAvailabilities?.appointments &&
      !isEmpty(nextWeekAvailabilities.appointments)
    ) {
      const availableSpots = nextWeekAvailabilities.appointments.filter(
        (a) => a.availabilityStatus === "FREE"
      );
      if (availableSpots.length > 0) {
        window.sessionStorage.setItem(
          "firstUseNextWeeksAvailabilitiesResponse",
          JSON.stringify(availableSpots)
        );
      }
    }
    if (person) {
      window.sessionStorage.setItem(
        "firstUsePersonData",
        JSON.stringify(person)
      );
    }
  });
}

export function clearSessionStorage() {
  if (window.sessionStorage.getItem("firstUseGetAvailabilitiesResponse")) {
    window.sessionStorage.removeItem("firstUseGetAvailabilitiesResponse");
  }
  if (
    window.sessionStorage.getItem("firstUseNextWeeksAvailabilitiesResponse")
  ) {
    window.sessionStorage.removeItem("firstUseNextWeeksAvailabilitiesResponse");
  }
  if (window.sessionStorage.getItem("firstUsePersonData")) {
    window.sessionStorage.removeItem("firstUsePersonData");
  }
}

export function normalizeCustomContent(originalCustomContent) {
  // `originalCustomContent` is either 1) ACE advice message object, or 2) GTM defined `PCAP.abFrameworkProperties.firstuse.madLibsQQInterjection`
  if (!originalCustomContent) {
    return originalCustomContent;
  }
  // `copyFrom` is either 1) ACE advice message's `jsonContent` object, or 2) GTM defined `PCAP.abFrameworkProperties.firstuse.madLibsQQInterjection`
  const copyFrom =
    objectPath.get(originalCustomContent, "resources.0.jsonContent") ||
    originalCustomContent;
  return Object.assign(
    {},
    copyFrom.isNew2019Version
      ? {
          isNew2019Version: copyFrom.isNew2019Version,
          titleIcon: copyFrom.titleIcon,
          titleBackground: copyFrom.titleBackground,
          headerAnimation: copyFrom.headerAnimation,
        }
      : {},
    {
      modalTitle: copyFrom.modalTitle || originalCustomContent.title,
      title: copyFrom.title || originalCustomContent.title,
      image: copyFrom.image,
      teaser: copyFrom.teaser,
      description: copyFrom.description,
      topContent: originalCustomContent.summary || copyFrom.topContent,
      bottomContent: copyFrom.bottomContent,
      // Meta Data
      attributionSource:
        copyFrom.attributionSource || originalCustomContent.template,
      userMessageId: originalCustomContent.userMessageId,
      skipMarkAsViewed: copyFrom.skipMarkAsViewed,
      showDisclaimerLink: Boolean(copyFrom.showDisclaimerLink),
      disclaimer: copyFrom.disclaimer,
      headerClassName: copyFrom.headerClassName,
      showTypeSelector: copyFrom.showTypeSelector,
      isMTREnrollment: copyFrom.isMTREnrollment,
      titleIcon: copyFrom.titleIcon,
      footerClassName: copyFrom.footerClassName,
      titleClassName: copyFrom.titleClassName,
    }
  );
}

export function setCustomContent(customContent, { isPersistent = false } = {}) {
  const sessionStorageKey = isPersistent
    ? SESSION_STORAGE_KEYS.appointmentSchedulerCustomContentPersistent
    : SESSION_STORAGE_KEYS.appointmentSchedulerCustomContentOnce;
  window.sessionStorage.setItem(
    sessionStorageKey,
    JSON.stringify(customContent)
  );
  if (
    customContent?.resources &&
    customContent.resources[0]?.jsonContent?.testGroupId
  ) {
    window.sessionStorage.setItem(
      "testGroupID",
      customContent.resources[0].jsonContent.testGroupId
    );
    if (customContent?.resources[0]?.jsonContent?.isPromoInSalesforce) {
      window.sessionStorage.setItem(
        "isPromoInSalesforce",
        customContent?.resources[0]?.jsonContent?.isPromoInSalesforce
      );
    }
  }
}

export function hasCustomContent() {
  return Boolean(
    window.sessionStorage.getItem(
      SESSION_STORAGE_KEYS.appointmentSchedulerCustomContentOnce
    ) ||
      window.sessionStorage.getItem(
        SESSION_STORAGE_KEYS.appointmentSchedulerCustomContentPersistent
      )
  );
}

export function getCustomContent() {
  const oneTimeCustomContent = attemptParseJson(
    window.sessionStorage.getItem(
      SESSION_STORAGE_KEYS.appointmentSchedulerCustomContentOnce
    )
  );
  if (oneTimeCustomContent) {
    window.sessionStorage.removeItem(
      SESSION_STORAGE_KEYS.appointmentSchedulerCustomContentOnce
    );
    return normalizeCustomContent(oneTimeCustomContent);
  }
  return normalizeCustomContent(
    attemptParseJson(
      window.sessionStorage.getItem(
        SESSION_STORAGE_KEYS.appointmentSchedulerCustomContentPersistent
      )
    )
  );
}

export function handlePersistentCustomContent(userMessage) {
  setCustomContent(userMessage, { isPersistent: true });
}

export function handleOneTimeCustomContent(userMessage) {
  setCustomContent(userMessage, { isPersistent: false });
  setTimeout(() => replaceHash("/appointment"), 1000);
}

export function showMadLibsInterjectionReviewModal() {
  return objectPath.get(
    abFrameworkProperties,
    "madLibsInterjectionReviewCustomContent.show"
  );
}

export function setMadLibsInterjectionReviewCustomContent(
  isCustomFlow = false
) {
  let userMessage = objectPath.get(
    abFrameworkProperties,
    "madLibsInterjectionReviewCustomContent"
  );
  let customUserMessage = {};
  const hundredDollarTestUserMessage = objectPath.get(
    abFrameworkProperties,
    "madLibsInterjectionReview100DollarTest"
  );

  const person = getFromSessionStorage("firstUsePersonData");
  // If the user is in test group.
  if (
    !isEmpty(hundredDollarTestUserMessage) &&
    person?.investableAssets?.userInputInvestableAssets >
      TWO_HUNDRED_AND_FIFTY_K_THRESHOLD
  ) {
    if (window.experimentId && window.experimentId % 2 !== 0) {
      customUserMessage = hundredDollarTestUserMessage;
      Mixpanel.trackEvent("2107_100-later2-test");
      Mixpanel.updateProfile({ TestGroupID: "2107_100-later2-test" }, true);
      Services.Appointment.addPromoInSalesforce();
    } else {
      Mixpanel.trackEvent("2107_100-later2-control");
      Mixpanel.updateProfile({ TestGroupID: "2107_100-later2-control" }, true);
    }
  }

  if (userMessage && userMessage.show) {
    setCustomContent(merge(userMessage, customUserMessage), {
      isPersistent: false,
    });
  } else if (isCustomFlow) {
    const sessionStorageKey =
      SESSION_STORAGE_KEYS.appointmentSchedulerCustomContentOnce;
    window.sessionStorage.setItem(
      sessionStorageKey,
      JSON.stringify(SCHEDULE_APPOINTMENT_REVIEW_CONTENT)
    );
  }
}

export function trackTestGroupID() {
  const testGroupId = window.sessionStorage.getItem("testGroupID");
  Mixpanel.trackEvent(testGroupId);
  Mixpanel.updateProfile({ TestGroupID: testGroupId }, true);
  if (window.sessionStorage.getItem("isPromoInSalesforce")) {
    Services.Appointment.addPromoInSalesforce();
  }
}

export function getPrefetchedAvailabilities() {
  const prefetchedFirstUseAvailabilities =
    objectPath.get(
      getAndRemoveFromSessionStorage("firstUseGetAvailabilitiesResponse")
    ) || [];
  const prefetchedNextWeeksAvailabilities =
    objectPath.get(
      getAndRemoveFromSessionStorage("firstUseNextWeeksAvailabilitiesResponse")
    ) || [];
  return {
    prefetchedFirstUseAvailabilities,
    prefetchedNextWeeksAvailabilities,
  };
}
