import React from "react";
import PropTypes from "prop-types";
import noop from "utils/noop";

const FiDefaultSuccessScreen = (props) => {
  const { onClose } = props;
  return (
    <div className="js-fi-authentication-success__default qa-fi-authentication-success">
      <div className="icon-body">
        <svg className="icon-svg__authentication-success">
          <use href="#icon-check-mark-round"></use>
        </svg>
      </div>
      <h2 className="u-text-center">Connection successful!</h2>
      <div className="u-text-center pc-u-mt">
        <button
          type="button"
          className="pc-btn qa-action-close pc-btn--primary"
          onClick={onClose}
        >
          Done
        </button>
      </div>
    </div>
  );
};

FiDefaultSuccessScreen.propTypes = {
  onClose: PropTypes.func,
};

FiDefaultSuccessScreen.defaultProps = {
  onClose: noop,
};

export default FiDefaultSuccessScreen;
