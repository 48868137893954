const root = "retirement_planner.";
const RetirementPlannerEvents = {
  ROOT: root,
  ABOUT: root + "about.click",
  ACCOUNTS_EXPLAINER_LINK: root + "accounts_explainer_link_click_event",
  ASSUMPTIONS_EDIT: root + "assumptions_edit.click",
  ASSUMPTIONS_PREVIEW: root + "assumptions_preview.click",
  ASSUMPTIONS_SAVE: root + "assumptions_save.click",
  COLLAPSE_CASH_FLOW_TABLE: root + "collapse_cash_flow_table.click",
  EXPAND_CASH_FLOW_TABLE: root + "expand_cash_flow_table.click",
  COMPARE_SCENARIOS: root + "compare_scenarios.click",
  COMPARE_SCENARIOS_MAKE_THIS_OFFICIAL:
    root + "compare_scenarios.make_this_official.click",
  COMPARE_SCENARIOS_MAKE_THIS_OFFICIAL_SAVE_PLAN:
    root + "compare_scenarios.make_this_official.save_plan.click",
  DISCLAIMER: root + "disclaimer.click",
  FAQ_LINK: root + "faq_link.click",
  FINISH: root + "finish_button.click",
  INCOME_EVENTS: root + "income_events",
  SMART_WITHDRAWAL_DETAILS: root + "smart_withdrawal_details_icon.click",
  SMART_WITHDRAWAL_INCOME_EVENTS:
    root + "smart_withdrawal_income_events_icon.click",
  SMART_WITHDRAWAL_TAB: root + "smart_withdrawal_tab.click",
  SOCIAL_SECURITY_ACCEPT_ESTIMATE:
    root + "social_security_accept_estimate_button.click",
  SOCIAL_SECURITY_EDIT_ESTIMATE:
    root + "social_security_edit_estimate_button.click",
  CHANGE_CHART_TYPE: root + "RetirementPlanner.change_chart_type_event",
  NEW_SCENARIO: root + "new_scenario.click",
  NEW_SCENARIO_SAVE: root + "new_scenario_save.click",
  NEXT_BUTTON: root + "next_button.click",
  PLAN_TAB: root + "plan_tab.click",
  PREVIEW_RESET: root + "preview_reset.click",
  PREVIEW_SAVE: root + "preview_save.click",
  PREVIEW_SAVE_AS_NEW_SCENARIO: root + "preview_save_as_new_scenario.click",
  PROFILE_EDIT: root + "profile_edit.click",
  PROFILE_SAVE: root + "profile_save.click",
};

export default RetirementPlannerEvents;
