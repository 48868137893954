import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import mixpanel from "mixpanel";
import analytics from "analytics";

const STATE_OVERLAY_ACTIVE = "cta-overlay--active";
const DEFAULT_DELAY_REVEAL_IN_MILLISECONDS = 1500;

export default class CtaOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
    this.onAction = this.onDismiss.bind(this);
  }

  onDismiss() {
    $(this.container).removeClass(STATE_OVERLAY_ACTIVE);
    if (this.props.message.dismissFunction) {
      this.props.message.dismissFunction();
    }

    analytics.sendEngagementEvent(
      "Notification Action Close Banner",
      this.props.message.template
    );
    mixpanel.trackEvent(
      "Close Dashboard Overlay Notification Banner in " + this.props.widgetName,
      {
        component: this.props.widgetName,
      }
    );
  }

  onAction(label) {
    analytics.sendEngagementEvent(
      "Notification Action Click",
      this.props.message.template
    );
    mixpanel.trackEvent(
      `Click on Dashboard Overlay Notification '${label}' Button in ${this.props.widgetName}`,
      {
        component: this.props.widgetName,
      }
    );
  }

  componentDidMount() {
    _.delay(() => {
      $(this.container).addClass(STATE_OVERLAY_ACTIVE);
    }, this.props.delayReveal || DEFAULT_DELAY_REVEAL_IN_MILLISECONDS);

    analytics.sendEngagementEvent(
      "Viewed Dashboard Notification",
      this.props.message.template
    );
    mixpanel.trackEvent(
      "View Dashboard Overlay Notification in " + this.props.widgetName,
      {
        component: this.props.widgetName,
      }
    );
  }

  render() {
    if (!this.props.message) {
      return null;
    }

    const title = this.props.message.title;
    const actions = this.props.message.action;

    return (
      <div
        className="cta-overlay"
        ref={(container) => {
          this.container = container;
        }}
      >
        <p>{title}</p>
        {actions && (
          <div className="cta-overlay__primary-actions">
            {actions.map((item) => {
              const label = item.label;
              const className = item.className || "pc-btn pc-btn--primary";
              // NOTE: even though this is a Fat Arrow function, it does not bind to `this` scope (bug?),
              //   and must use bind() below in the onClick attribute.
              const callback = () => {
                this.onAction(item.label);
              };
              return [
                <button
                  type="button"
                  className={className}
                  onClick={callback.bind(this)}
                >
                  {label}
                </button>,
              ];
            }, this)}
          </div>
        )}
        <div className="cta-overlay__tertiary-actions">
          <button
            type="button"
            className="pc-btn pc-btn--small pc-btn--stripped cta-overlay__dismiss-btn"
            onClick={this.onDismiss}
          >
            Dismiss
          </button>
        </div>
      </div>
    );
  }
}

CtaOverlay.propTypes = {
  delayReveal: PropTypes.number,
  message: PropTypes.object,
  onAfterDismiss: PropTypes.func,
  widgetName: PropTypes.string,
};
