import PropTypes from "prop-types";
import React from "react";
import FancySelect from "components/common/form/FancySelect/FancySelect";
import { CSSTransition } from "react-transition-group";
import { getDefaultValue } from "components/modal/editAccount/utils/selectOptions";
import { isEqual } from "underscore";

export default class AccountTypeCombo extends React.Component {
  constructor(props) {
    super(props);

    const value = props.value;
    const optionsType = props.typesMap[value.group];
    const optionsSubType = props.subTypesMap[value.type];

    this.state = {
      value,
      optionsType,
      optionsSubType,
    };

    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleSubTypeChange = this.handleSubTypeChange.bind(this);
  }

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = {};
    let newValue;

    if (nextProps.value !== this.props.value) {
      newValue = nextProps.value;
      newState.value = newValue;
    }

    // make sure the types are updated if product type changes
    if (!isEqual(nextProps.typesMap, this.props.typesMap)) {
      newState.optionsType = nextProps.typesMap[newValue || this.props.value];
    }

    this.setState(newState);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleGroupChange(ev) {
    const group = ev.target.value;
    const value = this.state.value;

    const optionsType = this.props.typesMap[group];
    const type = getDefaultValue(value.type, optionsType);

    const optionsSubType = this.props.subTypesMap[type];
    const subType = getDefaultValue(value.subType, optionsSubType);

    const newValue = { group, type, subType };

    this.setState({
      value: newValue,
      optionsType,
      optionsSubType,
    });
    this.triggerChangeEvent(newValue);
  }

  handleTypeChange(ev) {
    const value = this.state.value;

    const type = ev.target.value;

    const optionsSubType = this.props.subTypesMap[type];
    const subType = getDefaultValue(this.state.subType, optionsSubType);

    const newValue = Object.assign({}, value, { type, subType });
    this.setState({
      value: newValue,
      optionsSubType,
    });
    this.triggerChangeEvent(newValue);
  }

  handleSubTypeChange(ev) {
    const value = this.state.value;
    const subType = ev.target.value;

    const newValue = Object.assign({}, value, { subType });
    this.setState({ value: newValue });
    this.triggerChangeEvent(newValue);
  }

  triggerChangeEvent(value) {
    // send a simple object that looks like an event
    this.props.onChange({
      target: {
        name: this.props.name,
        value: value,
      },
    });
  }

  /**
   * Validate method for compatibility with `AbstractForm`.
   *
   * @return {Object} The validation result
   */
  validate() {
    let errors = [this.elGroup, this.elType, this.elSubType]
      .map((el) => {
        let errors;
        if (el) {
          const result = el.validate();
          if (!result.valid) {
            errors = result.errors;
          }
        }
        return errors;
      })
      .reduce((acc, cur) => (acc ? acc.concat(cur) : cur));

    return {
      valid: !errors,
      errors,
    };
  }

  render() {
    const { optionsType, optionsSubType, value } = this.state;
    const showTypesField = optionsType && optionsType.length > 1;
    const showSubTypesField = optionsSubType && optionsSubType.length > 1;
    const ariaLabels = this.props.ariaLabels;
    return (
      <div>
        <div className="pc-form-group--small">
          <FancySelect
            inputId={this.props.inputId}
            ariaLabel={ariaLabels.group}
            id={this.props.id}
            name="group"
            ref={(el) => {
              this.elGroup = el;
            }}
            value={value.group}
            className={`input--full qa-account-group Select--mini`}
            onChange={this.handleGroupChange}
            validator={this.props.validator.properties.group}
            options={this.props.optionsGroup}
            isDisabled={this.props.disabled}
          >
            <option />
          </FancySelect>
        </div>

        <CSSTransition
          in={showTypesField}
          unmountOnExit={true}
          classNames="transition-fade"
          timeout={250}
        >
          <div className="pc-form-group--small">
            <div className="pc-layout">
              <div
                className={`pc-layout__item ${
                  showSubTypesField ? "pc-u-1/2" : "pc-u-1/1"
                }`}
              >
                <FancySelect
                  inputId="atTypeInput"
                  ariaLabel={ariaLabels.type}
                  id="atType"
                  name="type"
                  ref={(el) => {
                    this.elType = el;
                  }}
                  value={value.type}
                  className={`input--full qa-account-type Select--mini`}
                  onChange={this.handleTypeChange}
                  validator={this.props.validator.properties.type}
                  options={optionsType}
                  isDisabled={this.props.disabled}
                >
                  <option />
                </FancySelect>
              </div>

              <CSSTransition
                in={showSubTypesField}
                unmountOnExit={true}
                classNames="transition-fade"
                timeout={250}
              >
                <div className="pc-layout__item pc-u-1/2">
                  <FancySelect
                    inputId="atSubTypeInput"
                    ariaLabel={ariaLabels.subType}
                    id="atSubType"
                    name="subType"
                    ref={(el) => {
                      this.elSubType = el;
                    }}
                    value={value.subType}
                    className={`input--full qa-account-sub-type Select--mini`}
                    onChange={this.handleSubTypeChange}
                    validator={this.props.validator.properties.subType}
                    options={optionsSubType}
                    isDisabled={this.props.disabled}
                  />
                </div>
              </CSSTransition>
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

AccountTypeCombo.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  validator: PropTypes.object.isRequired,
  group: PropTypes.string,
  subType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  optionsGroup: PropTypes.array.isRequired,
  typesMap: PropTypes.object.isRequired,
  subTypesMap: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  ariaLabels: PropTypes.object,
  inputId: PropTypes.string,
};

AccountTypeCombo.defaultProps = {
  group: undefined,
  id: undefined,
  subType: "",
  disabled: false,
  ariaLabels: {},
  inputId: undefined,
};
