import PropTypes from "prop-types";
import React from "react";

const ReferralCenterWidget = (props) => {
  return (
    <section className="summary-widget">
      <div className="summary-widget__header">
        <div className="l-spaced l-spaced--flush">
          <div tabIndex="0" className="pc-delta">
            <img
              className="icon-refer-a-friend-gift"
              src={`${
                window.staticUrl || ""
              }/static/img/icons/refer-a-friend--gift-black.svg`}
              alt=""
            />
            <b className="u-preserve-case">Refer a friend</b>
          </div>
        </div>
        <div tabIndex="0" className="pc-delta pc-u-mt-">
          <div
            dangerouslySetInnerHTML={{ __html: props.message.explanation }}
          />
        </div>
        <div className="u-text-center" style={{ marginTop: "36px" }}>
          <a
            href={props.message.action[0].url}
            className="pc-btn pc-btn--primary"
          >
            Share
          </a>
        </div>
      </div>
    </section>
  );
};

ReferralCenterWidget.propTypes = {
  message: PropTypes.object,
};

ReferralCenterWidget.defaultProps = {
  message: {},
};

export default ReferralCenterWidget;
