import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import { formatCurrency } from "libs/pcap/utils/format";
import { fromNow } from "libs/pcap/utils/fromNow";
import AccountCredentialFields from "./AccountCredentialFields";
import { getInitialModelWithOptionFields } from "./AccountCredentialFields/utils";
import { breadcrumb } from "logger";

export default class EditLoginView extends AbstractForm {
  constructor(props) {
    super(props);
    breadcrumb({
      message: "Rendering edit login. Attaching loginFields:",
      data: props.account.loginFields,
    });

    // model is used for login fields, props.account is used for read-only display fields
    this.state = {
      model: getInitialModelWithOptionFields(props.account.loginFields),
      areOptionalFieldsShown: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(ev) {
    ev.preventDefault();

    const validateResult = this.validate();

    if (validateResult.valid) {
      this.props.onSubmit(this.state.model);
    }
  }

  render() {
    const { account } = this.props;
    let isCreditAccount = account.productType === "CREDIT_CARD";
    let balance = account.balance;
    if (isCreditAccount) {
      balance *= -1;
    }

    return (
      <div>
        {account.logoPath && (
          <div className="edit-account__logo-container">
            <img src={account.logoPath} className="u-full-center--abs" />
          </div>
        )}
        <div className="l-spaced l-spaced--flush edit-account__text--info">
          <div className="u-text-bold">{account.firmName}</div>
          <div className="u-text-bold">{formatCurrency(balance)}</div>
        </div>
        <div className="l-spaced l-spaced--flush edit-account__text--info">
          <div>{account.name}</div>
          <div>{fromNow(account.lastRefreshed)}</div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="edit-login__fields-wrapper">
            <AccountCredentialFields
              model={this.state.model}
              className={"qa-edit-login-field-required"}
              fields={account.loginFields}
              storeInputRef={this.storeInputRef}
              onChange={this.handleInputChange}
              shallowModelPropertyPath={true}
            />
          </div>

          <div className="l-spaced l-spaced--right">
            <button
              type="button"
              className="pc-btn js-btn-cancel"
              onClick={this.props.onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="pc-btn pc-btn--primary js-btn-submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

EditLoginView.propTypes = {
  account: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

EditLoginView.defaultProps = {
  schema: {
    properties: {
      requiredTextField: {
        type: "string",
        required: true,
        allowEmpty: false,
      },
    },
  },
};
