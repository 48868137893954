import React from "react";
import {
  hasCustomContent,
  getCustomContent,
} from "libs/pcap/utils/customAppointmentSchedulerUtils";
import { isEmpty } from "underscore";
import abFrameworkProperties from "libs/pcap/utils/abFrameworkProperties";

const withAppointmentCustomContent = (WrappedComponent) => {
  let componentFunc = (props) => {
    let customContent = { data: {} };

    // Set in firstUseQuestionsBaseView
    const madLibsQQInterjection = JSON.parse(
      window.sessionStorage.getItem("madLibsQQInterjection") || "{}"
    );
    window.sessionStorage.removeItem("madLibsQQInterjection");

    if (!isEmpty(madLibsQQInterjection)) {
      customContent.data = madLibsQQInterjection; // Used in AppointmentTime
      customContent.isFirstUseAppointmentInterjection = true; // Used in AppointmentContainer
    } else if (hasCustomContent()) {
      customContent.data = getCustomContent();
    }

    // Set in firstUseQuestionsBaseView, used in AppointmentConfirmation
    customContent.showAppointmentConfirmationForm = Boolean(
      window.sessionStorage.getItem("showAppointmentConfirmationForm")
    );
    window.sessionStorage.removeItem("showAppointmentConfirmationForm");

    const viewMoreTimesButton =
      abFrameworkProperties &&
      abFrameworkProperties.schedulerViewMoreTimesButton;
    const isMadLibsInterjection = !isEmpty(customContent.data);
    if (
      viewMoreTimesButton &&
      (isMadLibsInterjection || viewMoreTimesButton.customizeLabelEverywhere)
    ) {
      customContent.viewMoreTimesCustomLabel = viewMoreTimesButton.label;
    }

    return <WrappedComponent customContent={customContent} {...props} />;
  };
  componentFunc.displayName = `withCustomContent(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  }`;

  return componentFunc;
};

export default withAppointmentCustomContent;
