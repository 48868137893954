import { normalizeForDisplay } from "libs/pcap/utils/humanize";
import suffixes from "enums/nameSuffixes";
import Relationships from "enums/personRelationships";

export function fullName(person, nameField = "name") {
  let result = "";
  if (person[nameField]) {
    const name = person[nameField];
    result += name.firstName || "";
    result += name.middleName ? " " + name.middleName : "";
    result += name.middleName?.length === 1 ? "." : "";
    result += name.lastName ? " " + name.lastName : "";
    if (name.suffix) {
      const suffix = suffixes.find((entry) => entry.value === name.suffix);
      if (suffix) {
        result += " " + suffix.label;
      }
    }
  }

  return result;
}

export function fullNameAndRelationship(person) {
  let result = fullName(person);

  if (person.relationship) {
    result += " (" + normalizeForDisplay(person.relationship) + ")";
  }

  return result;
}

export function getFamiliarForm(firstName, relationship) {
  if (firstName) {
    return firstName;
  }
  if (relationship.toUpperCase() === "SELF") {
    return "You";
  }
  if (relationship.toUpperCase() === "OTHER") {
    return "Other";
  }
  return "Your " + normalizeForDisplay(relationship);
}

export function getPossessiveForm(firstName, relationship) {
  if (!firstName && relationship.toUpperCase() === "SELF") {
    return "Your";
  }

  return getFamiliarForm(firstName, relationship) + "'s";
}

export function getFullNamePossessiveForm(firstName, lastName, relationship) {
  if (!firstName && !lastName && relationship.toUpperCase() === "SELF") {
    return "Your";
  }
  let name = "";
  if (firstName) {
    name += firstName;
    if (lastName) {
      name += " " + lastName;
    }
  }
  return getFamiliarForm(name, relationship) + "'s";
}

const RELATIONSHIPS_UNIQUE = ["SELF", "SPOUSE"];
/**
 * Constructs an array of relationships available for selection on UI.
 *
 * Implementation details:
 * - Filters out `SELF` if it's already selected.
 * - Filters out `SPOUSE` if it's already selected.
 * - Always includes the relationship defined by `include` parameter.
 *
 * @param  {Array} persons      The array of existing persons to look for the selected relationships in. Array should be in same shape as that returned from getPersons API. At a minimum it should have a relationship field.
 * @param  {String} include     The relationship to always include in the array.
 * @return {Array}              The array of available relationships.
 */
export function getAvailableRelationships(persons, include) {
  const used = persons
    .filter((person) => person.relationship)
    .map((person) => person.relationship);
  const uniquesToFilter = include
    ? RELATIONSHIPS_UNIQUE.filter((ur) => !ur.includes(include))
    : RELATIONSHIPS_UNIQUE;
  const usedUnique = used.filter((u) => uniquesToFilter.includes(u));
  return Relationships.filter((r) => !usedUnique.includes(r.value));
}

export function validateSuffix(person) {
  if (person?.suffix) {
    let newSuffix;
    switch (person.suffix) {
      case "JUNIOR":
      case "JR":
      case "JR.":
        newSuffix = "JUNIOR";
        break;
      case "SENIOR":
      case "SR":
      case "SR.":
        newSuffix = "SENIOR";
        break;
      case "SECOND":
      case "II":
        newSuffix = "SECOND";
        break;
      case "THIRD":
      case "III":
        newSuffix = "THIRD";
        break;
      case "FOURTH":
      case "IV":
        newSuffix = "FOURTH";
        break;
      default:
        newSuffix = person.suffix;
        break;
    }
    person.suffix = newSuffix;
  }
  return person;
}
