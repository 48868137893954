import PropTypes from "prop-types";
import React from "react";
import { scaleBand, select } from "d3";
import chart from "libs/pcap/chart/chart";
import cashFlowBarChartTooltip from "./CashFlowBarChartTooltip";

const BAR_MARGIN_TOP = 5;
const BAR_MARGIN_BOTTOM = 5;
const NARROW = 0.1;

export default class CashFlowBarChart extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Post-initialization and render operations to set up d3 graph
   */
  componentDidMount() {
    const { showCents, showNegativeValues, stacks, barHeight } = this.props;
    let keys = Object.keys(stacks);

    keys.splice(0, 1);
    const x = keys[0];

    const xScale = scaleBand().padding(NARROW);
    const tooltipTemplate = cashFlowBarChartTooltip({
      xScale: xScale,
      showCents: showCents,
      showNegativeValues: showNegativeValues,
    });

    this.chart = chart({
      type: "bar",
      showXGrid: false,
      showXAxis: false,
      showYAxis: false,
      showYGrid: false,
      showYZeroLine: false,
      horizontalOrientation: true,
      stacks: keys,
      height: barHeight + (BAR_MARGIN_BOTTOM + BAR_MARGIN_TOP),
      margin: {
        top: BAR_MARGIN_TOP,
        right: 0,
        bottom: BAR_MARGIN_BOTTOM,
        left: 0,
      },
      tooltip: {
        template: tooltipTemplate,
        hideTooltipKey: "remaining",
      },
    })
      .x(function (d) {
        return d[x];
      })
      .y(function (d) {
        return d.type;
      });

    this.updateChart(this.getChartData());
  }

  /**
   * Post-update operations to re-do up d3 graph
   */
  componentDidUpdate() {
    this.updateChart(this.getChartData());
  }

  getChartData() {
    return [this.props.stacks];
  }

  updateChart(data) {
    select(this.container).datum([data]).call(this.chart);

    this.attachMouseEvents();
  }

  attachMouseEvents() {
    const onChartCategoryClick = this.props.onChartCategoryClick;

    select(this.container)
      .selectAll(".js-cash-flow-summary-bar-chart .js-chart-bar-stack")
      .on("click.cashflowSummary", function (d) {
        onChartCategoryClick(d.key, d[0].data.type);
      });
  }

  render() {
    let className = "qa-svg-chart " + (this.props.className || "");
    return (
      <svg
        role="link"
        aria-label="Go to cashflow for a selected category"
        width="100%"
        height="100%"
        className={className}
        ref={(container) => {
          this.container = container;
        }}
      />
    );
  }
}

CashFlowBarChart.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  stacks: PropTypes.object,
  titleNode: PropTypes.object,
  onChartCategoryClick: PropTypes.func,
  barHeight: PropTypes.number.isRequired,
  showCents: PropTypes.bool,
  showNegativeValues: PropTypes.bool,
};
