import { VIEW_TEMPLATES_TO_SKIP } from "libs/pcap/utils/customAppointmentSchedulerUtils";

function isQualifiedNotification(message) {
  // either there is no displayLocations object or if present, should contain location for notifications
  const isNotInterjection = message.category !== "INTERJECTION";
  const hasProperDisplayLocation =
    !message.displayLocations ||
    message.displayLocations.indexOf("#/notifications") > -1;
  const skipUserMessage = VIEW_TEMPLATES_TO_SKIP.includes(message.viewTemplate);
  return isNotInterjection && hasProperDisplayLocation && !skipUserMessage;
}

export function filterUnreadNotifications(userMessages) {
  if (!userMessages) {
    return [];
  }

  return userMessages.filter((m) => {
    return isQualifiedNotification(m) && m.lastViewedTime == null;
  });
}

export function isUnreadNotification(notification) {
  return (
    isQualifiedNotification(notification) && notification.lastViewedTime == null
  );
}
