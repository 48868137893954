import React from "react";
import PropTypes from "prop-types";
import RingChart from "components/common/RingChart/RingChart";
import moment from "moment";
import { API_FORMAT } from "libs/pcap/utils/date2";
import { formatCurrency } from "libs/pcap/utils/format";
import BudgetingSummaryEditModal from "components/budgetingSummary/BudgetingSummaryEditModal";
import FlexibleDollar from "components/common/FlexibleDollar";

const NUMBER_OF_MONTHS = 12;
const PAST_WIDTH = 90;
const NO_PAST_WIDTH = 125;
const INNER_RADIUS = 80;
const OUTER_THICKNESS = 10;
const INNER_THICKNESS = 5;
/**
 *
 * @param {moment} startDate Moment object for start date.
 * @param {moment} endDate Moment object for end date.
 * @param {moment} today Moment object for todays date.
 *
 * @returns {Object}  numberOfTicks to render, tick label and tick position.
 */
function getTicks({ startDate, endDate, today }) {
  const numberOfMonthsDifference = moment(endDate)
    .endOf("month")
    .diff(moment(startDate).startOf("month"), "months");
  const isFirstDayOfTheMonth = startDate.date() === 1;

  if (isFirstDayOfTheMonth) {
    const isLastDayOfTheMonth =
      moment(endDate).endOf("month").date() === endDate.date();
    if (numberOfMonthsDifference === 0) {
      const isThisMonth = endDate.month() === today.month();
      if (isThisMonth || isLastDayOfTheMonth) {
        const isCurrentMonth = startDate.isSame(today, "month");
        const daysInMonth = startDate.daysInMonth();
        const isTodayIsInTheRange =
          moment(today).endOf("day").isAfter(startDate) &&
          moment(today).startOf("day").isBefore(endDate);

        if (isCurrentMonth) {
          if (isTodayIsInTheRange) {
            const todayDate = today.date();
            return {
              numberOfTicks: daysInMonth,
              tick: {
                // 0 represents the last day of the month.
                position: todayDate === daysInMonth ? 0 : todayDate,
                label: todayDate,
              },
            };
          }

          return undefined;
        }

        return {
          numberOfTicks: daysInMonth,
        };
      }

      return undefined;
    }

    const isFirstMonthOfTheYear = startDate.month() === 0;
    const isLastDayOfTheSameYear =
      moment(endDate).endOf("year").format(API_FORMAT) ===
      moment(startDate).endOf("year").format(API_FORMAT);
    const isTodayIsInTheRange =
      moment(today).endOf("day").isAfter(startDate) &&
      moment(today).startOf("day").isBefore(endDate);

    if (
      isFirstMonthOfTheYear &&
      isLastDayOfTheSameYear &&
      isTodayIsInTheRange
    ) {
      const month = today.month();
      return {
        numberOfTicks: NUMBER_OF_MONTHS,
        tick: {
          position: month === NUMBER_OF_MONTHS - 1 ? 0 : month + 1,
          label: today.format("MMM"),
        },
      };
    }

    if (isLastDayOfTheMonth) {
      return {
        numberOfTicks: numberOfMonthsDifference + 1,
      };
    }
  }
}

class BudgetingWatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.isModalOpenWithQueryParams() || false,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleBudgetUpdate = this.handleBudgetUpdate.bind(this);
    this.isModalOpenWithQueryParams =
      this.isModalOpenWithQueryParams.bind(this);
  }

  isModalOpenWithQueryParams() {
    if (window?.AppRouter?.activeModuleID === "#budgeting") {
      var queryParamsString = location.hash.split("?")[1];
      var queryParams = $.pcap.getQueryStringParams(queryParamsString);
      return queryParams && queryParams["set-monthly-goal"] === "true";
    }
  }

  handleEdit() {
    this.setState({
      isOpen: true,
    });
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "widgets_edit_budgeting_clicked_event"
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
        event_properties: {
          selection: "widgets_edit_budgeting_clicked_event",
        },
      });
    }
  }

  handleBudgetUpdate(newBudget) {
    this.setState({
      isOpen: false,
    });
    this.props.onBudgetUpdate(newBudget);
  }

  handleClose() {
    this.setState({
      isOpen: false,
    });
    if (this.isModalOpenWithQueryParams()) {
      var hashAry = location.hash.split("?");
      AppRouter.navigate(hashAry[0]);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.startDate !== prevState.startDate ||
      nextProps.endDate !== prevState.endDate ||
      nextProps.today !== prevState.today
    ) {
      const tickProps = {
        startDate: moment(nextProps.startDate).startOf("day"),
        endDate: moment(nextProps.endDate).endOf("day"),
        today: nextProps.today,
        past: nextProps.past,
      };

      return {
        startDate: nextProps.startDate,
        endDate: nextProps.endDate,
        today: nextProps.today,
        ticks: getTicks(tickProps),
      };
    }

    return null;
  }

  render() {
    const {
      current,
      past,
      startDate,
      today,
      targetBudget,
      outerRadius,
      innerRadius,
      outerThickness,
      innerThickness,
      chartPadding,
      labelPadding,
      showEditIcon,
      hideTicks,
    } = this.props;

    const { ticks, isOpen } = this.state;
    const showPastRing = past != null;

    const difference = past
      ? past.strongDialValue - current.strongDialValue
      : undefined;
    const isBudgetExceeded = current.strongDialValue > current.totalValue;
    const isCurrentMonth =
      startDate.isSame(today, "month") && startDate.isSame(today, "year");

    let watchClassName = "budgeting-watch";
    if (hideTicks) watchClassName += " budgeting-watch--hide-ticks";

    const graphDescription =
      "Progress meter comparing your total spend to your budget";
    const dynamicDescription = `You have spent ${formatCurrency(
      current.strongDialValue,
      0
    )} of ${formatCurrency(targetBudget ? current.totalValue : 0, 0)}${
      isCurrentMonth ? " this month" : ""
    }`;

    return (
      <div className={watchClassName}>
        <RingChart
          name="budgeting-watch__current"
          {...current}
          {...ticks}
          className="budgeting-watch__current"
          strongDialClassName="budgeting-watch__current-strong-dial"
          lightDialClassName="budgeting-watch__current-light-dial"
          thickness={outerThickness}
          radius={outerRadius}
          chartPadding={chartPadding}
          labelPadding={labelPadding}
          ariaLabel={graphDescription}
        />
        {showPastRing && (
          <RingChart
            name="budgeting-watch__past"
            {...past}
            radius={innerRadius}
            className="budgeting-watch__past"
            exceededDialClassName="ring-chart__strong-dial"
            thickness={innerThickness}
            chartPadding={chartPadding}
            labelPadding={labelPadding}
            ariaLabel={graphDescription}
          />
        )}
        <div
          className={`milli budgeting-watch-legend-box js-budgeting-watch__summary budgeting-watch__summary${
            showPastRing ? "" : " budgeting-watch__summary--large"
          }`}
        >
          {isCurrentMonth && !this.props.hideSummaryLabel ? (
            <span
              className={`budgeting-watch__summary-chiclets-label chart-legend__item budgeting-watch-legend__item budgeting-watch-legend${
                isBudgetExceeded ? "--exceeded" : "--current"
              }`}
            >
              This Month
            </span>
          ) : null}

          <div
            className={`pc-beta budgeting-watch__summary-current qa-budgeting-summary-current-total${
              current.strongDialValue < 0
                ? " budgeting-watch__summary-current--income"
                : ""
            }${
              isBudgetExceeded
                ? " budgeting-watch__summary-current--exceeded"
                : ""
            }`}
            aria-label={dynamicDescription}
          >
            <FlexibleDollar
              value={current.strongDialValue}
              containerWidth={`${showPastRing ? PAST_WIDTH : NO_PAST_WIDTH}px`}
              supClassName="budgeting-watch__summary-current-sup"
            />
          </div>
          <div className="budgeting-watch__summary-target qa-budgeting-watch-target">
            of {`${targetBudget ? formatCurrency(current.totalValue, 0) : 0}`}
            {showEditIcon && (
              <button
                aria-label="Edit monthly budget"
                type="button"
                onClick={this.handleEdit}
                className="pc-btn pc-btn--stripped pc-btn--tiny pc-u-p0 js-budgeting-watch__edit-target qa-edit-budgeting-icon"
              >
                &nbsp;
                <svg
                  className="budgeting-watch__edit-target-icon"
                  viewBox="0 0 9 9"
                  aria-hidden
                  alt=""
                >
                  <use xlinkHref="#icon-pencil" />
                </svg>
              </button>
            )}
          </div>
          {showPastRing && (
            <>
              <div className="budgeting-watch__summary-separator" />
              <div className="centi">
                {difference === 0 && "Same as"}
                {difference !== 0 && (
                  <div>
                    {formatCurrency(Math.abs(difference), 0)}{" "}
                    {difference < 0 ? "over" : "under"}
                  </div>
                )}
              </div>
              <div className="budgeting-watch__summary-month">
                {isCurrentMonth ? (
                  <span
                    className={
                      this.props.hideSummaryMonthLegend
                        ? ""
                        : "chart-legend__item budgeting-watch-summary-month-legend__item"
                    }
                  >
                    This time last month
                  </span>
                ) : (
                  moment(startDate).subtract(1, "months").format("MMM 'YY")
                )}
              </div>
            </>
          )}
        </div>
        {isOpen && (
          <BudgetingSummaryEditModal
            isOpen={isOpen}
            onSave={this.handleBudgetUpdate}
            onClosed={this.handleClose}
          />
        )}
        <div aria-live="polite" aria-atomic className="sr-only">
          {dynamicDescription}
        </div>
      </div>
    );
  }
}

BudgetingWatch.propTypes = {
  hideTicks: PropTypes.bool,
  hideSummaryLabel: PropTypes.bool,
  hideSummaryMonthLegend: PropTypes.bool,
  targetBudget: PropTypes.number.isRequired,
  onBudgetUpdate: PropTypes.func.isRequired,
  current: PropTypes.shape({
    totalValue: PropTypes.number.isRequired,
    strongDialValue: PropTypes.number.isRequired,
    lightDialValue: PropTypes.number.isRequired,
  }).isRequired,
  startDate: PropTypes.objectOf(moment).isRequired,
  endDate: PropTypes.objectOf(moment).isRequired,
  today: PropTypes.objectOf(moment),
  outerRadius: PropTypes.number,
  innerRadius: PropTypes.number,
  outerThickness: PropTypes.number,
  innerThickness: PropTypes.number,
  chartPadding: PropTypes.number,
  labelPadding: PropTypes.number,
  past: PropTypes.shape({
    totalValue: PropTypes.number.isRequired,
    strongDialValue: PropTypes.number.isRequired,
    lightDialValue: PropTypes.number.isRequired,
  }),
  showEditIcon: PropTypes.bool,
};

BudgetingWatch.defaultProps = {
  hideTicks: false,
  hideSummaryLabel: false,
  hideSummaryMonthLegend: false,
  past: undefined,
  outerRadius: undefined,
  innerRadius: INNER_RADIUS,
  outerThickness: OUTER_THICKNESS,
  innerThickness: INNER_THICKNESS,
  chartPadding: undefined,
  labelPadding: undefined,
  today: moment().startOf("day"),
  showEditIcon: true,
};

export default BudgetingWatch;
