import PropTypes from "prop-types";
import React from "react";
import { trackView } from "components/common/ComponentAnalytics";
import Slider from "react-slick";

const BASE_PATH_SLIDE = `${
  window.staticUrl || ""
}/static/img/financialPlanOffer/sampleFinancialPlan/`;
const BASE_PATH_THUMBNAIL = `${BASE_PATH_SLIDE}thumbnails/`;
const SLIDE_COUNT = 32;
const THUMBNAILS_PER_PAGE = 7;
const COUNT_THRESHOLD = 10;
const THUMBNAIL_CONTAINER_STYLES = { margin: "0 6px", height: 68 };
const HIGHLIGHT_LAST_PAGE_THUMBNAIL_DELAY = 1000;

const manuallyUpdateCurrentThumbnailIndex = (
  thumbnailSlider,
  newThumbnailIndex
) => {
  thumbnailSlider.innerSlider.setState({ currentSlide: newThumbnailIndex });
};

export default class FinancialPlanGallery extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      viewerSlider: null,
      thumbnailSlider: null,
    };
    this.getSliderContent = this.getSliderContent.bind(this);
    this.onThumbnailClick = this.onThumbnailClick.bind(this);
    this.onAfterViewerSliderChange = this.onAfterViewerSliderChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      viewerSlider: this.viewerSlider,
      thumbnailSlider: this.thumbnailSlider,
    });
  }

  /*
   * Workaround for react-slick issue where the thumbnails on the last page do not get highlighted
   * correctly when clicked, even when the viewer is navigating correctly to the right slide.
   */
  onThumbnailClick(event) {
    const thumbnailIndex = parseInt(
      event.currentTarget.getAttribute("data-index"),
      0
    );
    const thumbnailSliderCurrentIndex = this.thumbnailSlider.innerSlider.state
      .currentSlide;
    const clickedOnLastThumbnailPage =
      thumbnailIndex >= SLIDE_COUNT - THUMBNAILS_PER_PAGE;
    const thumbnailSliderIsOnLastPage =
      thumbnailSliderCurrentIndex === SLIDE_COUNT - THUMBNAILS_PER_PAGE - 1;
    if (clickedOnLastThumbnailPage && thumbnailSliderIsOnLastPage) {
      setTimeout(() => {
        manuallyUpdateCurrentThumbnailIndex(
          this.thumbnailSlider,
          thumbnailIndex
        );
      }, HIGHLIGHT_LAST_PAGE_THUMBNAIL_DELAY);
    }
  }

  onAfterViewerSliderChange() {
    const { componentName } = this.props;
    const viewerSliderCurrentIndex = this.thumbnailSlider.innerSlider.state
      .currentSlide;
    trackView(`Gallery Slide ${viewerSliderCurrentIndex + 1}`, {
      componentName,
    });
  }

  getSliderContent({ isThumbnail = false } = {}) {
    const slides = [];
    const baseUrl = isThumbnail ? BASE_PATH_THUMBNAIL : BASE_PATH_SLIDE;
    for (let i = 1; i <= SLIDE_COUNT; i++) {
      const suffix = i < COUNT_THRESHOLD ? `0${i}` : i;
      const name = `slide${suffix}`;
      if (isThumbnail) {
        slides.push(
          <div
            data-index={i - 1}
            onClick={this.onThumbnailClick}
            key={`thumbnail-${i}`}
          >
            <div style={THUMBNAIL_CONTAINER_STYLES}>
              <img src={`${baseUrl + name}.jpg`} />
            </div>
          </div>
        );
      } else {
        slides.push(
          <div key={`viewer-${i}`}>
            <img src={`${baseUrl + name}.jpg`} />
          </div>
        );
      }
    }
    return slides;
  }

  render() {
    const { viewerSlider, thumbnailSlider } = this.state;
    return (
      <div className="fin-plan-offer__gallery">
        <Slider
          ref={(el) => {
            this.viewerSlider = el;
          }}
          asNavFor={thumbnailSlider}
          className="fin-plan-offer__gallery-viewer"
          afterChange={this.onAfterViewerSliderChange}
          initialSlide={0}
          slidesToShow={1}
          lazyLoad={true}
          infinite={false}
        >
          {this.getSliderContent({ isThumbnail: false })}
        </Slider>
        <Slider
          ref={(el) => {
            this.thumbnailSlider = el;
          }}
          asNavFor={viewerSlider}
          className="fin-plan-offer__gallery-thumbnails"
          slidesToShow={THUMBNAILS_PER_PAGE}
          slidesToScroll={THUMBNAILS_PER_PAGE}
          swipeToSlide={true}
          focusOnSelect={true}
          infinite={false}
        >
          {this.getSliderContent({ isThumbnail: true })}
        </Slider>
      </div>
    );
  }
}

FinancialPlanGallery.propTypes = {
  componentName: PropTypes.string,
  className: PropTypes.string,
};
