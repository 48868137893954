import React from "react";
import PropTypes from "prop-types";
import FancySelect from "components/common/form/FancySelect";
import { fullName, fullNameAndRelationship } from "libs/pcap/utils/person";

const NEW_PERSON_ID = -1;

function getPeopleMap(people, includeAddNew, includeRelationship, ownerOnly) {
  const peopleNameIdMap = people
    .filter((person) => {
      if (ownerOnly) {
        return person.relationship === "SELF";
      }
      return true;
    })
    .map((person) => {
      return {
        value: person.id,
        label: includeRelationship
          ? fullNameAndRelationship(person)
          : fullName(person),
      };
    });

  if (includeAddNew) {
    peopleNameIdMap.push({
      value: NEW_PERSON_ID,
      label: "Add New",
    });
  }

  return peopleNameIdMap;
}

/*
 * PersonSelect component provides a dropdown of people.
 * In addition it allows for adding a new person.
 */
function PersonSelect(props) {
  const { people, forwardedRef, showRelationship, ownerOnly } = props;
  return (
    <FancySelect
      {...props}
      options={getPeopleMap(
        people,
        props.isAddNewPersonEnabled,
        showRelationship,
        ownerOnly
      )}
      ref={forwardedRef}
      data-hj-masked
    />
  );
}

PersonSelect.propTypes = {
  people: PropTypes.array.isRequired,
  isAddNewPersonEnabled: PropTypes.bool,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  showRelationship: PropTypes.bool,
  ownerOnly: PropTypes.bool,
  validator: PropTypes.object,
};
PersonSelect.defaultProps = {
  isAddNewPersonEnabled: true,
  forwardedRef: undefined,
  showRelationship: true,
  ownerOnly: false,
  validator: {},
};

const Wrapper = React.forwardRef((props, ref) => {
  return <PersonSelect {...props} forwardedRef={ref} />;
});

Wrapper.displayName = "PersonSelect";

export default Wrapper;
