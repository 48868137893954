import PropTypes from "prop-types";
import React from "react";
import noop from "utils/noop";

/**
 * React implementation of Selectable Item component
 * https://devtrunk.pcapcloud.com/static/styleguide/dist/section-framework.html#kssref-framework-components-selectable-item
 */
class SelectableItem extends React.Component {
  render() {
    return (
      <label
        className={`selectable-item ${this.props.className}`}
        data-hj-masked
      >
        <span className="selectable-item__checkbox">
          <input
            type="checkbox"
            checked={this.props.checked}
            value={this.props.value}
            onChange={this.props.onChange || noop}
            disabled={this.props.disabled}
          />
        </span>
        <span className="selectable-item__body">
          {this.props.name && (
            <span
              className={`selectable-item__name u-preserve-case`}
              title={this.props.name}
            >
              {this.props.name}
            </span>
          )}
          {this.props.info && (
            <span
              className={`selectable-item__info u-preserve-case`}
              title={this.props.info}
            >
              {this.props.info}
            </span>
          )}
        </span>
      </label>
    );
  }
}

SelectableItem.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  info: PropTypes.string,
  onChange: PropTypes.func,
  balance: PropTypes.number,
  lastRefreshed: PropTypes.number,
  disabled: PropTypes.bool,
};

SelectableItem.defaultProps = {
  checked: false,
  className: "",
};

export default SelectableItem;
