import React from "react";
import PropTypes from "prop-types";
import { defer } from "underscore";
import getUrlParameterByName from "libs/pcap/utils/getUrlParameterByName";
import isProductionEnvironment from "libs/pcap/utils/isProductionEnvironment";
import Loading from "components/common/loading/Loading";

const TALKABLE_SITE_ID = `pcap${isProductionEnvironment() ? "" : "-test"}`;
const CAMPAIGN_SOURCE_URL_PARAM_NAME = "source";
const REQUIRED_DIV_ID_FOR_TALKABLE = "talkable-offer";
const IFRAME_PAGE_IS_READY = "IFRAME_PAGE_IS_READY";
const SHARE_WITH_FRIENDS_HASH = "#/share";

class ShareWithFriends extends React.Component {
  constructor() {
    super(...arguments);
    this.state = { isLoading: true };

    this.handleIframeMessage = this.handleIframeMessage.bind(this);
    window.addEventListener("message", this.handleIframeMessage);

    // Defer is required to load Talkable iframe correctly
    defer(() => {
      this.setupTalkableIframe();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleIframeMessage);
  }

  handleIframeMessage(event) {
    const data = event && event.data;
    if (data === IFRAME_PAGE_IS_READY) {
      this.setState({ isLoading: false });
    }
  }

  setupTalkableIframe() {
    const {
      username,
      usernameFirst,
      usernameLast,
      userGuid,
      baseUrl,
      staticVersion,
    } = window;

    delete window._talkableq;

    window._talkableq = window._talkableq || [];
    window._talkableq.push(["init", { site_id: TALKABLE_SITE_ID }]);

    window._talkableq.push([
      "authenticate_customer",
      {
        email: username,
        first_name: usernameFirst,
        last_name: usernameLast,
        person_custom_properties: {
          user_guid: userGuid,
          refer_from: SHARE_WITH_FRIENDS_HASH,
          source: getUrlParameterByName(CAMPAIGN_SOURCE_URL_PARAM_NAME),
          base_url: baseUrl,
          bundle_version: staticVersion,
          is_from_sign_out_page: "false", // Talkable.com requires all passed values to be strings
        },
      },
    ]);

    window._talkableq.push([
      "register_affiliate",
      {
        // Note: If no campaign_tags is passed in, Talkable will show the previously cookied
        // campaign, if it exists. Otherwise it will randomly select a live campaign to use.
        campaign_tags: this.props.keyParam,
      },
    ]);
  }

  render() {
    const { isLoading } = this.state;
    const message = this.props.keyParam ? "" : "Invalid key";
    return (
      <div>
        {isLoading && <Loading />}
        <div
          className={`${REQUIRED_DIV_ID_FOR_TALKABLE}`}
          id={`${REQUIRED_DIV_ID_FOR_TALKABLE}`}
        >
          {message}
        </div>
      </div>
    );
  }
}

ShareWithFriends.propTypes = {
  keyParam: PropTypes.string,
};

ShareWithFriends.defaultProps = {
  keyParam: "",
};

export default ShareWithFriends;
