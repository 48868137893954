/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable camelcase */
/* eslint react/require-default-props: 0 */

import PropTypes from "prop-types";
import React from "react";
import { TransactionsGrid } from "../TransactionsGrid";
import defaultColumns, {
  buildDataForExportToCsvDefaultColumns,
} from "../columns/default";
import TransactionMultiEditForm from "../TransactionMultiEditForm";
import Pagination from "libs/pcap/table/pagination/Pagination";
import Search from "libs/pcap/table/Search";
import transactionsGridSearchIterator from "../searchAccessors/transactionsGridSearchIterator";
import {
  dateSearchAccessor,
  accountNameSearchAccessor,
  descriptionSearchAccessor,
  categoryNameSearchAccessor,
  tagSearchAccessor,
  formattedAmountSearchAccessor,
  unformattedAmountSearchAccessor,
} from "../searchAccessors/searchAccessors";
import MultiEdit from "svg-icons/MultiEdit.svg";
import memoizeOne from "memoize-one";
import { isEqual, clone, isEmpty } from "underscore";
import ExportCsvButton from "components/common/ExportCsvButton/ExportCsvButton";
import DateUtils from "libs/pcap/utils/date";
import moment from "moment";
import transactionsToClient from "components/TransactionsGridV3/toClient";
import mixpanel from "../../../libs/pcap/utils/mixpanel";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const EMPOWER_PAGER_SIZE = 25;
const getColumnHeadersForCSV = memoizeOne((column) =>
  column.filter((c) => !c.isMultiEdit).map((c) => c.header)
);
const getColumns = memoizeOne(
  (
    isEditable,
    columns,
    categories,
    isMultiEditOpen,
    handleMultiEditSelectAll,
    handleMultiEditSelect,
    transactionsInPage,
    transactions
  ) => {
    // If is not multi-editable and there no columns passed by prop render the defaultColumns
    if (!isEditable && !columns) {
      return;
    }

    let newColumns = (columns ? columns : defaultColumns).slice(0);
    if (isMultiEditOpen) {
      let multiEditFormatter = (t) => {
        return t.isEditable && isEmpty(t.splits) && !t?.isManual ? (
          <input
            type="checkbox"
            name={t.userTransactionId}
            checked={Boolean(t.isBeingMultiEdited)}
            onChange={handleMultiEditSelect}
          />
        ) : null;
      };

      const transactionsInPageEditable = transactionsInPage.filter(
        (t) => t.isEditable
      );
      newColumns.unshift({
        header: (
          <input
            type="checkbox"
            name="checkAll"
            onChange={handleMultiEditSelectAll}
            checked={Boolean(
              transactionsInPageEditable.length &&
                transactionsInPageEditable.filter((t) => !t.splits).length ===
                  transactions.filter((t) => t.isBeingMultiEdited).length
            )}
          />
        ),
        accessor: (t) => t,
        formatter: multiEditFormatter,
        headerClassName: "pc-transactions-grid-cell--multi-select pc-u-p-",
        className: "pc-transactions-grid-cell--multi-select",
        isMultiEdit: true,
      });
      return newColumns;
    }
    return newColumns;
  }
);

const getFilteredTransactions = memoizeOne(
  (searchInput, transactions, searchAccessors) => {
    return transactionsGridSearchIterator(
      searchInput,
      transactions,
      searchAccessors
    );
  }
);

/**
 * Generate a zero state to display when no valid transactions are found
 * for the current search query / product type / column filters / date range.
 *
 * @param {Object} startDate Beginning of the date range.
 * @param {Object} endDate End of the date range.
 * @param {String} searchQuery The active search query, if any.
 * @param {String} productFilter The name of the active product filter, if any.
 * @param {Boolean} areColumnFiltersActive Are any columns being filtered?
 *
 * @returns {*} A div with message telling the user why there are no results.
 */
export const generateZeroState = function (
  startDate,
  endDate,
  searchQuery,
  productFilter,
  areColumnFiltersActive
) {
  const startDateText = startDate.format(DateUtils.DISPLAY_FORMAT);
  const endDateText = endDate.isSame(moment(), "day")
    ? "today"
    : endDate.format(DateUtils.DISPLAY_FORMAT);
  const optionalSearchSpan =
    searchQuery && searchQuery !== "" ? (
      <span>
        {" "}
        matching <strong>{searchQuery}</strong>
      </span>
    ) : (
      <span />
    );

  let optionalFilterSpan = <span />;
  // Showing product filters and column filters at the same time would be
  // misleading, since a product filter can hide active column filters from view.
  if (productFilter && productFilter.toLowerCase() !== "all") {
    optionalFilterSpan = (
      <span>
        {" "}
        in your <strong>{productFilter} accounts</strong>
      </span>
    );
    // Showing search query and column filters at the same time would be
    // misleading, since a search query can hide active column filters from view.
  } else if (areColumnFiltersActive && !searchQuery) {
    optionalFilterSpan = <span> in your filtered columns</span>;
  }

  return (
    <div className="pc-u-mt pc-block--center qa-transactions-grid-zero-state">
      No transactions{optionalSearchSpan} found
      {optionalFilterSpan} between <strong>{startDateText}</strong> and{" "}
      <strong>{endDateText}</strong>.
    </div>
  );
};

const memoizedGenerateZeroState = memoizeOne(generateZeroState);

const isPrivileged = isEmpowerPrivilegedMode();

export default class TransactionsGridController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: props.searchInput || "",
      transactions: [],
      transactionsInPage: [],
      isMultiEditOpen: false,
      paginator: props.paginator,
      isAddManualOpen: false,
      eventBus: null,
      AMPLITUDE_EVENTS: null,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleRequestPage = this.handleRequestPage.bind(this);
    this.handleOpenMultiEdit = this.handleOpenMultiEdit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleCloseMultiEdit = this.handleCloseMultiEdit.bind(this);
    this.handleMultiEditSelectAll = this.handleMultiEditSelectAll.bind(this);
    this.handleMultiEditSelect = this.handleMultiEditSelect.bind(this);
    this.handlePageDataChange = this.handlePageDataChange.bind(this);
    this.handleTransactionSortAndOrFilter =
      this.handleTransactionSortAndOrFilter.bind(this);
    this.handleUpdateTransactions = this.handleUpdateTransactions.bind(this);
    this.trackExportToCsv = this.trackExportToCsv.bind(this);
    this.handleOpenManualTransactionContainer =
      this.handleOpenManualTransactionContainer.bind(this);
    this.handleCloseManualTransactionContainer =
      this.handleCloseManualTransactionContainer.bind(this);
  }

  componentDidMount() {
    const eventBus = window.dashboardUtils?.eventBus;
    const AMPLITUDE_EVENTS = window.integratedSharedData?.AMPLITUDE_EVENTS ?? {
      SELECT_BUTTON: "select_button",
    };
    if (eventBus) {
      this.setState({ eventBus, AMPLITUDE_EVENTS });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.transactions, prevState.prevPropsTransactions)) {
      //If multi edit is open, then apply the multi edit changes to the transaction
      if (prevState.isMultiEditOpen) {
        const transactionsBeenEdited = prevState.transactions.filter(
          (t) => t.isBeingMultiEdited
        );
        return {
          transactions: nextProps.transactions.map((newTransaction) => {
            // If the transaction is been multi edited
            if (
              transactionsBeenEdited.some(
                (t) => t.userTransactionId === newTransaction.userTransactionId
              )
            ) {
              let t = clone(newTransaction);
              t.isBeingMultiEdited = true;
              return t;
            }
            return newTransaction;
          }),
          prevPropsTransactions: nextProps.transactions,
        };
      }
      return {
        transactions: nextProps.transactions,
        prevPropsTransactions: nextProps.transactions,
        isAddManualOpen: false,
      };
    }
    return null;
  }

  handleSearch(results, input) {
    // Dispatch event bus
    const { eventBus, AMPLITUDE_EVENTS } = this.state;
    const selection = "budget_page.filter_transactions_button.click";
    const payload = { input };
    eventBus?.dispatch(selection, payload);
    eventBus?.dispatchAmplitude({
      event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
      event_properties: { selection, ...payload },
    });
    this.setState({
      searchInput: input,
    });
  }

  handleMultiEditSelectAll(e) {
    if (e.target.checked) {
      // Are there editable transactions in page?
      if (
        this.state.transactionsInPage.some(
          (t) => t.isEditable && isEmpty(t.splits)
        )
      ) {
        let transactions = this.state.transactions.map((transaction) => {
          if (
            transaction.isEditable &&
            isEmpty(transaction.splits) &&
            this.state.transactionsInPage.some(
              (t) => t.userTransactionId === transaction.userTransactionId
            )
          ) {
            let t = clone(transaction);
            t.isBeingMultiEdited = true;
            return t;
          }
          return transaction;
        });
        this.setState({ transactions });
      }
    } else {
      this.setState({
        transactions: this.props.transactions,
      });
    }
  }

  handleMultiEditSelect(e) {
    let transactionIdChecked = parseInt(e.target.name, 10);
    let transactions = clone(this.state.transactions);
    let transactionIndex = transactions.findIndex(
      (t) => t.userTransactionId === transactionIdChecked
    );
    transactions[transactionIndex] = clone(transactions[transactionIndex]);
    transactions[transactionIndex].isBeingMultiEdited = e.target.checked;

    this.setState({ transactions });
  }

  handleOpenMultiEdit() {
    // Dispatch event bus
    const { eventBus, AMPLITUDE_EVENTS } = this.state;
    const selection = "budget_page.edit_multiple_transactions_button.click";
    eventBus?.dispatch(selection);
    eventBus?.dispatchAmplitude({
      event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
      event_properties: {
        selection,
      },
    });
    this.setState({
      isMultiEditOpen: true,
    });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();

      this.setState({
        isMultiEditOpen: true,
      });
    }
  }

  handleCloseMultiEdit() {
    this.setState({
      isMultiEditOpen: false,
      transactions: this.props.transactions,
      multiEditModel: {},
    });
  }

  handleUpdateTransactions(transactionMultiEditReqModel) {
    transactionMultiEditReqModel.userTransactionIds = JSON.stringify(
      this.state.transactions
        .filter((t) => t.isBeingMultiEdited)
        .map((t) => t.userTransactionId)
    );
    if (transactionMultiEditReqModel.categoryId) {
      transactionMultiEditReqModel.transactionCategoryId =
        transactionMultiEditReqModel.categoryId;
      delete transactionMultiEditReqModel.categoryId;
    }

    return new Promise((resolve, reject) => {
      this.props
        .onUpdateTransactions(transactionMultiEditReqModel)
        .then((transactionsUpdateResponse) => {
          const { tags, categories } = this.props;
          const updatedTransactions = transactionsToClient(
            transactionsUpdateResponse,
            categories,
            tags
          );
          let newTransactionsState = this.state.transactions.map((t) => {
            const updatedTransactionIndex = updatedTransactions.findIndex(
              (updatedTransaction) =>
                updatedTransaction.userTransactionId === t.userTransactionId
            );
            if (updatedTransactionIndex > -1) {
              let updatedTransaction = clone(
                updatedTransactions[updatedTransactionIndex]
              );
              updatedTransaction.categoryName = this.props.categories.find(
                (cat) =>
                  cat.transactionCategoryId === updatedTransaction.categoryId
              ).name;
              return updatedTransaction;
            }
            t.isBeingMultiEdited = false;
            return t;
          });
          this.setState({
            isMultiEditOpen: false,
            multiEditModel: {},
            transactions: newTransactionsState,
          });
          resolve();
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  }

  /*
    This method gets called when:
      - TableClient sorts
      - TableClient gets new data
      - TableClient changes page
    This 3 cases indicate that the data in page has changed, and we should keep track of
    the elements in page to make get the select all feature in multi-edit working.
   */
  handlePageDataChange(transactionsInPage, pageStart) {
    // If the page did not change
    if (this.state.paginator.start === pageStart) {
      //If multi edit is open, then revert the selected transactions that are out of the page
      if (this.state.isMultiEditOpen) {
        let transactions = this.state.transactions.map((transaction) => {
          // If it has never been edited OR it was unmarked from edition  OR is editing and in page we don't touch it
          if (
            transaction.isBeingMultiEdited === undefined ||
            transaction.isBeingMultiEdited === false ||
            (transaction.isBeingMultiEdited &&
              transactionsInPage.some(
                (t) => transaction.userTransactionId === t.userTransactionId
              ))
          ) {
            return transaction;
          }
          // If is editing and NOT in page we revert it
          return this.props.transactions.find(
            (t) => transaction.userTransactionId === t.userTransactionId
          );
        });
        this.setState({ transactionsInPage, transactions });
      } else {
        this.setState({ transactionsInPage });
      }
    } else {
      let paginator = this.state.paginator;
      paginator.start = pageStart;
      if (this.state.isMultiEditOpen) {
        this.setState({
          transactionsInPage,
          paginator,
          transactions: this.props.transactions,
        });
      } else {
        this.setState({ transactionsInPage, paginator });
      }
    }
  }

  handleRequestPage(pageStart) {
    let paginator = clone(this.state.paginator);
    paginator.start = pageStart;
    let newState = { paginator };
    if (this.state.isMultiEditOpen) {
      newState.transactions = this.props.transactions;
    }
    this.setState(newState);
  }

  /**
   * When `TransactionsGridTableClient` filters and/or sorts transactions,
   * it sends them here to be tracked in the state. This is so we can use them for
   * generating CSV data and the `paginator` total.
   *
   * @param {Array} modifiedTransactionsFromTableClient The data passed back from the child component.
   * @param {Boolean} areColumnFiltersActive Whether the data passed is a result of a column filters.
   */
  handleTransactionSortAndOrFilter(
    modifiedTransactionsFromTableClient,
    areColumnFiltersActive
  ) {
    this.setState({
      modifiedTransactionsFromTableClient,
      areColumnFiltersActive,
    });
  }

  trackExportToCsv() {
    // Dispatch event bus
    const { eventBus, AMPLITUDE_EVENTS } = this.state;
    const selection = "budget_page.download_transactions_button.click";
    eventBus?.dispatch(selection);
    eventBus?.dispatchAmplitude({
      event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
      event_properties: { selection },
    });
    mixpanel.trackEvent("Click Export Transactions To CSV", {
      component: "TransactionGridController",
    });
  }

  /**
   * Take the sorted and/or filtered data which was sent back up from
   * `TransactionsGridTableClient`, and also filter it by the searchQuery,
   * if one exists.
   *
   * @returns {Array} properly-sorted transactions which are filtered by both the search query and the active column filters of the table.
   */
  getTransactionsForPaginationAndCsv() {
    const {
      transactions,
      modifiedTransactionsFromTableClient,
      searchInput: activeSearchQuery,
    } = this.state;
    const transactionsToSearch =
      modifiedTransactionsFromTableClient || transactions;

    if (activeSearchQuery !== "") {
      // The transactions in the CSV need to reflect both the Column filters
      // AND the search query filter.
      return getFilteredTransactions(
        activeSearchQuery,
        transactionsToSearch,
        this.props.searchAccessors
      );
    }

    return transactionsToSearch;
  }

  handleOpenManualTransactionContainer() {
    if (!this.state.isAddManualOpen) {
      this.setState({
        isAddManualOpen: true,
      });
    }
  }

  handleCloseManualTransactionContainer() {
    this.setState({
      isAddManualOpen: false,
    });
  }

  render() {
    const {
      isEditable,
      columns,
      categories,
      tags,
      onUpdateTransactions,
      onDeleteTransaction,
      csvFileName,
      className,
      startDate,
      endDate,
      activeProductFilterLabel,
      searchAccessors,
      buildDataForExportToCsv,
      onAddManualTransaction,
      manualTransactionsFlag,
      eligibleForManualTransactions,
      accountForManualTransaction,
      accountsList,
      account,
    } = this.props;
    const {
      isMultiEditOpen,
      transactionsInPage,
      transactions,
      searchInput,
      paginator,
      areColumnFiltersActive,
      isAddManualOpen,
    } = this.state;
    let filteredTransactions = transactions;
    if (searchInput !== "") {
      filteredTransactions = getFilteredTransactions(
        searchInput,
        transactions,
        searchAccessors
      );
    }

    const zeroState = memoizedGenerateZeroState(
      startDate,
      endDate,
      searchInput,
      activeProductFilterLabel,
      areColumnFiltersActive
    );

    // Filter out accounts eligible for manual transactions
    const accountsListForManualTransactions =
      accountsList?.filter(
        (acc) =>
          acc.isManualTransactionAllowed &&
          ((activeProductFilterLabel === "All" &&
            acc.productType !== "INVESTMENT") ||
            activeProductFilterLabel.toLowerCase() ===
              acc.productType.toLowerCase() ||
            (activeProductFilterLabel === "Cash" &&
              acc.productType === "BANK") ||
            (activeProductFilterLabel === "Credit" &&
              acc.productType === "CREDIT_CARD"))
      ) || [];

    /**
     * This data has been filtered by column filters from the child table
     * in addition to the search query.
     *
     * It has to be kept separate from `filteredTransactions` to avoid an
     * infinite loop of `getDerivedStateFromProps` in the child table.
     */
    const transactionsForPaginationAndCsv =
      this.getTransactionsForPaginationAndCsv();

    return (
      <div className={className}>
        <div className="transactions-grid-table__header-controls">
          <div className="l-spaced l-spaced--flush">
            <div>
              <Search
                containerClassName="transactions-grid-table__search-container"
                clearClassName="qa-transaction-reset-search"
                className="transactions-grid-table__search qa-transaction-search-input"
                label="Search transactions"
                onSearch={this.handleSearch}
                searchIterator={transactionsGridSearchIterator}
                searchConfig={searchAccessors}
                searchInput={this.state.searchInput}
              />
              {isEditable && !isMultiEditOpen && !window.isAdvisorApp && (
                <button
                  className="pc-btn pc-btn--tiny transactions__multi-edit qa-transactions-multi-edit"
                  title="Edit multiple transactions"
                  aria-label="Edit multiple transactions"
                  type="button"
                  onClick={this.handleOpenMultiEdit}
                  onKeyPress={this.handleKeyPress}
                >
                  <MultiEdit
                    className="transactions__multi-edit-icon"
                    alt=""
                    aria-hidden
                  />
                </button>
              )}
              <ExportCsvButton
                headers={getColumnHeadersForCSV(columns)}
                data={buildDataForExportToCsv(transactionsForPaginationAndCsv)}
                fileNameBase={csvFileName}
                fileNameTimestampFormat=""
                isMiniVersion={true}
                onClick={this.trackExportToCsv}
                fakeLinkClassName={
                  "js-action-transactions-export-csv qa-action-transactions-export-csv"
                }
                isPrivileged={isPrivileged}
              />

              {manualTransactionsFlag &&
              eligibleForManualTransactions &&
              account?.attributes?.productType !== "INVESTMENT" &&
              activeProductFilterLabel !== "Investment" &&
              (accountsListForManualTransactions.length > 0 ||
                activeProductFilterLabel === undefined) ? (
                <button
                  type="button"
                  className="pc-btn"
                  disabled={isAddManualOpen}
                  onClick={this.handleOpenManualTransactionContainer}
                >
                  Add transaction
                </button>
              ) : null}
            </div>
            <div>
              {transactionsForPaginationAndCsv.length > paginator.stepSize && (
                <Pagination
                  className={paginator.className}
                  total={transactionsForPaginationAndCsv.length}
                  onPageChange={this.handleRequestPage}
                  stepSize={paginator.stepSize}
                  rangeStart={paginator.start}
                />
              )}
            </div>
          </div>
          {isEditable && isMultiEditOpen && (
            <TransactionMultiEditForm
              categories={categories}
              tags={tags}
              onClose={this.handleCloseMultiEdit}
              numberOfTransactionsSelected={
                transactions.length
                  ? transactions.filter((t) => t.isBeingMultiEdited).length
                  : 0
              }
              onSubmit={this.handleUpdateTransactions}
              className={"pc-u-mt--"}
            />
          )}
        </div>

        <TransactionsGrid
          {...this.props}
          onUpdateTransactions={onUpdateTransactions}
          onDeleteTransaction={onDeleteTransaction}
          onPageDataChanged={this.handlePageDataChange}
          onTransactionSortAndOrFilter={this.handleTransactionSortAndOrFilter}
          transactions={filteredTransactions}
          categories={categories}
          columns={getColumns(
            isEditable,
            columns,
            categories,
            isMultiEditOpen,
            this.handleMultiEditSelectAll,
            this.handleMultiEditSelect,
            transactionsInPage,
            transactions
          )}
          className={
            isMultiEditOpen ? "pc-transactions-grid--multi-select" : ""
          }
          headerRowClassName={
            isMultiEditOpen
              ? "transactions-grid-table__fixed-header-row--multi-editing"
              : "transactions-grid-table__fixed-header-row"
          }
          isEditable={isEditable && !isMultiEditOpen && !window.isAdvisorApp}
          paginator={paginator}
          zeroState={zeroState}
          accountsListForManualTransactions={accountsListForManualTransactions}
          isAddManualOpen={isAddManualOpen}
          handleCloseManualTransactionContainer={
            this.handleCloseManualTransactionContainer
          }
          onAddManualTransaction={onAddManualTransaction}
          accountForManualTransaction={accountForManualTransaction}
        />
      </div>
    );
  }
}

TransactionsGridController.propTypes = {
  transactions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  renderDuplicates: PropTypes.bool,
  isEditable: PropTypes.bool,
  groupBy: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  onUpdateTransactions: PropTypes.func,
  onDeleteTransaction: PropTypes.func,
  buildDataForExportToCsv: PropTypes.func,
  columns: PropTypes.array,
  paginator: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  searchInput: PropTypes.string,
  searchAccessors: PropTypes.arrayOf(PropTypes.func),
  csvFileName: PropTypes.string,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  activeProductFilterLabel: PropTypes.string,
  transactionCategoryRulesFlag: PropTypes.bool,
  manualTransactionsFlag: PropTypes.bool,
  eligibleForManualTransactions: PropTypes.bool,
  accountsList: PropTypes.array,
  account: PropTypes.object,
  onAddManualTransaction: PropTypes.func.isRequired,
  accountForManualTransaction: PropTypes.object,
};

TransactionsGridController.defaultProps = {
  renderDuplicates: false,
  isEditable: true,
  paginator: {
    stepSize: IS_EMPOWER ? EMPOWER_PAGER_SIZE : 100,
    start: 0,
    className: "transactions-grid-table__pagination",
  },
  activeProductFilterLabel: undefined,
  searchAccessors: [
    dateSearchAccessor,
    accountNameSearchAccessor,
    descriptionSearchAccessor,
    categoryNameSearchAccessor,
    formattedAmountSearchAccessor,
    unformattedAmountSearchAccessor,
    tagSearchAccessor,
  ],
  buildDataForExportToCsv: buildDataForExportToCsvDefaultColumns,
  columns: defaultColumns,
  transactionCategoryRulesFlag: true,
  manualTransactionsFlag: false,
  eligibleForManualTransactions: false,
  accountsList: undefined,
  accountForManualTransaction: undefined,
};
