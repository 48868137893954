import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import Message from "components/common/Message";
import FirstRow from "components/dashboard/FirstRow";
import SecondRow from "components/dashboard/SecondRow";
import ThirdRow from "components/dashboard/ThirdRow";
import FourthRow from "components/dashboard/FourthRow";
import DashboardColumn from "components/dashboard/DashboardColumn";
import AceBlock, {
  DISPLAY_LOCATION__ACE_BLOCK,
} from "components/aceBlock/AceBlock";
import Analytics from "analytics";
import Mixpanel from "mixpanel";
import TopRow from "./TopRow";
import { isEmpowerPaeMode } from "views/modules/sidebar/utils/accountUtils";

const DASHBOARD_ACE_BLOCK_COMPONENT_NAME = "Dashboard ACE Block";
const HIGH_PRIORITY_MESSAGE_TEMPLATES = ["PCC_ACE_BLOCK_BANNER"]; // These will show in place of Completeness Meter

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAceBlock: true };
    this.onAceBlockRemove = this.onAceBlockRemove.bind(this);
    this.onMarkAsViewed = this.onMarkAsViewed.bind(this);
  }

  onAceBlockRemove() {
    const componentName = DASHBOARD_ACE_BLOCK_COMPONENT_NAME;
    Analytics.sendEngagementEvent(`${componentName} Action Close Banner`);
    Mixpanel.trackEvent(`Close ${componentName}`, { component: componentName });
    this.setState({ showAceBlock: false });
  }

  onMarkAsViewed(id) {
    if (!isEmpowerPaeMode()) {
      Services.UserMessages.updateV2({
        userMessageIds: "[" + id + "]",
        actions: '["markViewed"]',
      });
    }
  }

  render() {
    const {
      errors,
      dashboardCallsResolved,
      completenessMeterData,
      messages,
      referralBanner,
      numOfExternalAccounts,
      useUnifiedMar,
      person,
      firstTimeForecastExperience,
    } = this.props;

    if (errors) {
      return <Message severity="error" messages={errors} />;
    }

    let dashboardMessages = this.props.dashboardMessages || {};
    const aceBlockMessage = dashboardMessages[DISPLAY_LOCATION__ACE_BLOCK];
    const hasHighPriorityAceBlock =
      Boolean(aceBlockMessage) &&
      HIGH_PRIORITY_MESSAGE_TEMPLATES.includes(aceBlockMessage.template);

    const showCompletenessMeter =
      Boolean(completenessMeterData) && !hasHighPriorityAceBlock;
    const showAceBlock =
      this.state.showAceBlock &&
      aceBlockMessage &&
      dashboardCallsResolved &&
      !showCompletenessMeter;

    return (
      <section className="qa-dashboard-grid">
        {showAceBlock && (
          <AceBlock
            ref={(el) => {
              this.aceBlockElement = el;
            }}
            componentName={DASHBOARD_ACE_BLOCK_COMPONENT_NAME}
            userMessage={aceBlockMessage}
            onRemove={this.onAceBlockRemove}
            onMarkAsViewed={this.onMarkAsViewed}
          />
        )}
        {showCompletenessMeter && (
          <TopRow completenessMeterData={completenessMeterData} />
        )}
        <div className="pc-layout pc-layout--tiny">
          <div className="pc-layout__item pc-u-2/3">
            <FirstRow
              messages={messages}
              dashboardMessages={dashboardMessages}
              dashboardCallsResolved={dashboardCallsResolved}
            />
            <SecondRow
              messages={messages}
              dashboardMessages={dashboardMessages}
              useUnifiedMar={useUnifiedMar}
              person={person}
              firstTimeForecastExperience={firstTimeForecastExperience}
              numOfExternalAccounts={numOfExternalAccounts}
            />
            <ThirdRow
              messages={messages}
              dashboardMessages={dashboardMessages}
            />
            <FourthRow
              messages={messages}
              dashboardMessages={dashboardMessages}
            />
          </div>
          <div className="pc-layout__item pc-u-1/3">
            <DashboardColumn
              messages={messages}
              referralMessage={referralBanner}
              dashboardCallsResolved={dashboardCallsResolved}
              dashboardMessages={dashboardMessages}
              numOfExternalAccounts={numOfExternalAccounts}
              useUnifiedMar={useUnifiedMar}
            />
          </div>
        </div>
      </section>
    );
  }
}

Dashboard.propTypes = {
  errors: PropTypes.array,
  messages: PropTypes.array,
  dashboardMessages: PropTypes.object,
  completenessMeterData: PropTypes.object,
  referralBanner: PropTypes.object,
  dashboardCallsResolved: PropTypes.bool,
  numOfExternalAccounts: PropTypes.number,
  useUnifiedMar: PropTypes.bool,
  firstTimeForecastExperience: PropTypes.bool,
  person: PropTypes.object,
};

Dashboard.defaultProps = {
  errors: null,
  messages: [],
  dashboardMessages: {},
  completenessMeterData: null,
  referralBanner: undefined,
  dashboardCallsResolved: false,
  numOfExternalAccounts: 0,
  useUnifiedMar: false,
  firstTimeForecastExperience: false,
  person: {},
};
