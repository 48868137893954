var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"header wrap\">\n  <h2>Disclaimer</h2>\n  <a href=\"#\" class=\"close\">Close</a>\n</div>\n<div class=\"body wrap\">\n  <p class=\"padded\">Empower Advisory Group, LLC (EAG) is offering six months free advisory service to clients who refer new investment clients to EAG. Once the new client is verified and signs up for our advisory service and begins trading, they also receive six months of free advisory service. Account holders must maintain the minimum qualifying account balance (minus any losses related to trading or market volatility, or margin debit balances) with "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":6,"column":455},"end":{"line":6,"column":477}}}) : helper))) != null ? stack1 : "")
    + " during the term of the six month free period. "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":6,"column":524},"end":{"line":6,"column":546}}}) : helper))) != null ? stack1 : "")
    + " reserves the right to modify these terms and conditions or terminate this program at any time. This offer is not transferable, not valid with internal transfers and may not be combined with other offers.</p>\n</div>\n";
},"useData":true});