import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import Services from "services";
import analytics from "analytics";
import PortfolioBalanceSummary from "components/portfolioBalanceSummary/PortfolioBalanceSummary";
import { API_FORMAT } from "libs/pcap/utils/date";
import { promisify, subscribify } from "utils/service";

const NUM_DAYS_PAST_PERIOD = 90;
const DEFAULT_DATE_RANGE = "90_DAYS";
const ONE_YEAR_DATE_RANGE = "1_YEAR";

function getDefaultFetchConfig(uiPreferenceDateRange, accounts) {
  let startDate;
  if (uiPreferenceDateRange === DEFAULT_DATE_RANGE) {
    startDate = moment()
      .startOf("day")
      .subtract(NUM_DAYS_PAST_PERIOD - 1, "days")
      .format(API_FORMAT);
  } else {
    startDate = moment()
      .startOf("day")
      .subtract(1, "years")
      .add(1, "days")
      .format(API_FORMAT);
  }

  return {
    startDate,
    endDate: moment().format(API_FORMAT),
    interval: "DAY",
    types: JSON.stringify(["balances", "dailyChangeAmount"]),
    userAccountIds: JSON.stringify(
      accounts.map((account) => account.userAccountId)
    ),
  };
}

export default class PortfolioBalanceSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: null,
    };

    this.onFailure = this.onFailure.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
    const { getUIPreferences } = this.props;

    this.getAccountsSubscription = this.props.getAccounts();
    this.getAccountsSubscription.on("change", this.onSuccess);

    Promise.all([getUIPreferences(), this.getAccountsSubscription.promise])
      .then(([uiPreferences, getAccounts]) => {
        if (this.isUnmounted) {
          return;
        }

        const uiPreferenceDateRange =
          uiPreferences?.defaultDateRanges?.NETWORTH_INVESTING ||
          DEFAULT_DATE_RANGE;

        this.setState(
          {
            uiPreferenceDateRange,
          },
          () => {
            this.onSuccess(getAccounts);
          }
        );
      })
      .catch(this.onFailure);
  }

  onFailure(errors) {
    if (this.isUnmounted) {
      return;
    }

    this.setState({
      loading: false,
      errors: errors,
    });

    analytics.sendEngineeringEvent(
      "Error",
      "`PortfolioBalanceSummaryContainer()` " + JSON.stringify(errors)
    );
  }

  onSuccess({ accounts }) {
    if (this.isUnmounted) {
      return;
    }

    const { getHistories, getHistories2 } = this.props;
    const { uiPreferenceDateRange } = this.state;
    const filteredAccounts = accounts.filter(
      (account) =>
        !account.isExcludeFromHousehold &&
        (account.productType === "INVESTMENT" || account.treatedAsInvestment)
    );
    if (this.getHistoriesSubscription) {
      this.getHistoriesSubscription.unwatch();
      this.getHistoriesSubscription.off("change");
    }

    this.getHistoriesSubscription =
      uiPreferenceDateRange === ONE_YEAR_DATE_RANGE
        ? getHistories2(
            getDefaultFetchConfig(uiPreferenceDateRange, filteredAccounts)
          )
        : getHistories(
            getDefaultFetchConfig(uiPreferenceDateRange, filteredAccounts)
          );
    this.getHistoriesSubscription.on("change", (response) =>
      this.onGetHistoriesSuccess(response, filteredAccounts)
    );

    this.getHistoriesSubscription.promise
      .then((response) =>
        this.onGetHistoriesSuccess(response, filteredAccounts)
      )
      .catch(this.onFailure);
  }

  onGetHistoriesSuccess({ histories }, accounts) {
    if (this.isUnmounted) {
      return;
    }

    this.setState({
      histories,
      accounts,
      loading: false,
    });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unwatch();
      this.getAccountsSubscription.off("change");
      this.getAccountsSubscription = null;
    }

    if (this.getHistoriesSubscription) {
      this.getHistoriesSubscription.unwatch();
      this.getHistoriesSubscription.off("change");
      this.getHistoriesSubscription = null;
    }
  }

  render() {
    const { errors, loading, accounts, histories } = this.state;
    return (
      <PortfolioBalanceSummary
        errors={errors}
        loading={loading}
        accounts={accounts}
        histories={histories}
        ace={this.props.ace}
        useVariableTimeFrame={true}
      />
    );
  }
}

PortfolioBalanceSummaryContainer.propTypes = {
  getUIPreferences: PropTypes.func,
  getHistories: PropTypes.func,
  getHistories2: PropTypes.func,
  getAccounts: PropTypes.func,
  ace: PropTypes.object,
};

PortfolioBalanceSummaryContainer.defaultProps = {
  getUIPreferences: promisify(Services.Profile.getUIPreferences),
  getHistories: subscribify(Services.Histories.get),
  getAccounts: subscribify(Services.Accounts.get),
  getHistories2: subscribify(Services.Histories.getV2),
  ace: undefined,
};
