import React from "react";
import PropTypes from "prop-types";
import Loading from "components/common/loading/Loading";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import DebtPaydownChart from "components/savingsPlanner/DebtPaydown/DebtPaydownChart.js";
import { DEBT_PAYDOWN_TOOLTIP } from "components/savingsPlanner/DebtPaydown/DebtPaydownView";
import { formatCurrency } from "libs/pcap/utils/format";
import { first, isEmpty } from "underscore";
import { trackEvent } from "components/common/ComponentAnalytics";
import { handleAmplitudeEvent } from "../../empower/utils/eventUtils";

const ZERO_STATE_TITLE = "Manage your Debt";
const DEBT_PAYDOWN_URL = "#/savings-planner/debt-paydown";
const COMPONENT_NAME = "Debt Paydown";
let loadWidget = 1;

export default class DebtPaydownSummary extends React.Component {
  handleTitleClick() {
    trackEvent(COMPONENT_NAME, "Click on Debt Paydown Widget");
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "debt_paydown.header_link.click"
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_LINK,
        event_properties: {
          selection: "debt_paydown.header_link.click",
        },
      });
    }
  }
  handleTitleKeyDown(e) {
    if (e.key === " ") {
      e.handleTitleClick();
    }
  }

  handleLoadWidget() {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch("debt_paydown.widget_load");
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_WIDGET,
        event_properties: {
          selection: "debt_paydown.widget_load",
        },
      });
    }
  }

  handleTooltipClick = () => {
    const name = "Debt Paydown";
    const element = "SELECT_BUTTON";
    const section = "Widget.toolTip";
    const payload = { widget: name };

    handleAmplitudeEvent(element, section, payload);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const { accounts, ytdBalanceChange, debtBalances, showAddAccounts } =
      this.props;

    const empowerSharedData = window.integratedSharedData;
    const tooltip =
      empowerSharedData?.translations?.get("zeroStateDashboard")?.debtPaydown
        ?.tooltip;
    const tooltipClass = tooltip ? "with-tooltip" : "";
    const widgetClass = "summary-widget__debt-paydown";
    const changeYearContainerClass = "change-year-container";

    if (this.props.loading) {
      return <Loading />;
    }

    /**
     * the zero-state for cases when no accounts are linked at all
     */
    if (this.props.plannerStage === "NO_ACCOUNT") {
      const takeaway = first(this.props.takeaways);
      const action = first(this.props.actions);

      return (
        <section className="vertical-align u-text-center js-widget-zero-state-section qa-widget-zero-state-section ">
          <div className="pc-gamma">{ZERO_STATE_TITLE}</div>
          <div className="debt-paydown-widget__takeaway centi">{takeaway}</div>
          {!isEmpty(action) && showAddAccounts && (
            <a href={action.url} className="pc-btn pc-btn--primary">
              {action.label}
            </a>
          )}
        </section>
      );
    } else if (!debtBalances || debtBalances.length === 0) {
      /**
       * this handles the state where a manual account has been added,
       * but there are no `debtBalances` generated yet. We can't show the
       * zero-state, because in this case the takeaways and actions
       * will also be missing
       */
      return <Loading />;
    }

    const totalBalance = accounts.reduce(
      (total, account) => total + (account.balance || 0),
      0
    );

    const handleMouseOver = () => {
      if (IS_EMPOWER) {
        window.dashboardUtils?.eventBus.dispatch(
          "debt_paydown_widget.change_this_year_tooltip.mouseover"
        );
        window.dashboardUtils?.eventBus.dispatchAmplitude({
          event_type: window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_TOOLTIP,
          event_properties: {
            selection:
              "debt_paydown_widget.change_this_year_tooltip.mouseover",
          },
        });
      }
    };

    if (
      this.props.loading === false &&
      (debtBalances || debtBalances.length > 0) &&
      loadWidget === 1
    ) {
      this.handleLoadWidget();
      loadWidget++;
    }

    const redirectToDebtPaydown = () => {
      window.location.href = DEBT_PAYDOWN_URL;
    };

    const handleOnKeyDown = (e) => {
      if (e.key === "Enter") {
        redirectToDebtPaydown();
      }
    };

    const totalBalanceValue = totalBalance
      ? formatCurrency(totalBalance, 0)
      : "";

    const ytdBalanceChangeValue = formatCurrency(ytdBalanceChange || 0, 0);
    const chartAriaLabel = `Debt is ${totalBalanceValue}. Change this year is ${ytdBalanceChangeValue}`;

    return (
      <section
        className={`summary-widget js-widget-summary-section qa-widget-summary-section ${tooltipClass} ${widgetClass}`}
        alt="data visualization on yearly debt paydown"
      >
        <div className="summary-widget__header">
          <div className="l-spaced l-spaced--flush">
            <div className="pc-delta header-container">
              <h2 className="pc-u-mb0">
                <a
                  href={DEBT_PAYDOWN_URL}
                  className="summary-widget__title--link"
                  aria-label="Go to Debt paydown"
                  onClick={this.handleTitleClick}
                  onKeyDown={this.handleTitleKeyDown}
                >
                  Debt Paydown
                </a>
              </h2>
              {tooltip && (
                <InfoTooltipIcon
                  title={tooltip}
                  parentClassName={widgetClass}
                  container={false}
                  tooltipProps={{
                    trigger: "click",
                    role: "alert",
                  }}
                  onClick={this.handleTooltipClick}
                />
              )}
            </div>
            <div className="chart-legend milli">
              <div className="chart-legend__item chart-legend__item--debt-paydown pc-delta debt-paydown-widget__total-balance js-debt-paydown-widget-total-balance qa-debt-paydown-widget-total-balance">
                {totalBalanceValue}
              </div>
            </div>
          </div>
        </div>
        <DebtPaydownChart
          debtBalances={this.props.debtBalances}
          isZeroState={false}
          chartContainerClassName={"debt-paydown-widget__chart-container"}
          chartClassName={"debt-paydown-widget__chart"}
          tabIndex={0}
          onClick={redirectToDebtPaydown}
          onKeyDown={handleOnKeyDown}
          ariaLabel={chartAriaLabel}
        />
        <div
          className={`debt-paydown-widget__summary milli ${changeYearContainerClass}`}
        >
          <span className={"summary-label"}>
            Change this year
            <span>
              <InfoTooltipIcon
                title={DEBT_PAYDOWN_TOOLTIP}
                parentClassName={changeYearContainerClass}
                container={false}
                tooltipProps={{
                  trigger: "click",
                  role: "alert",
                }}
                onClick={handleMouseOver}
              />
            </span>
          </span>
          <span
            className={`js-debt-paydown-widget-balance-change qa-debt-paydown-widget-balance-change`}
          >
            {ytdBalanceChangeValue}
          </span>
        </div>
      </section>
    );
  }
}

DebtPaydownSummary.propTypes = {
  accounts: PropTypes.array,
  debtBalances: PropTypes.array,
  ytdBalanceChange: PropTypes.number,
  plannerStage: PropTypes.string,
  actions: PropTypes.array,
  takeaways: PropTypes.array,
  loading: PropTypes.bool,
  showAddAccounts: PropTypes.bool,
};

DebtPaydownSummary.defaultProps = {
  accounts: [],
  debtBalances: [],
  ytdBalanceChange: 0,
  plannerStage: undefined,
  actions: [],
  takeaways: [],
  loading: false,
  showAddAccounts: true,
};
