import PropTypes from "prop-types";
import React from "react";
import { select } from "d3";
import chart from "libs/pcap/chart/chart";

export default class InvestmentCheckupBarChart extends React.Component {
  componentDidMount() {
    this.chart = chart({
      type: "bar",
      showXGrid: false,
      showXAxis: false,
      showYAxis: false,
      showYGrid: false,
      showYZeroLine: false,
      horizontalOrientation: true,
      xDomain: this.props.xDomain,
      key: function (d) {
        return d.y;
      },
    })
      .x(function (d) {
        return d.value;
      })
      .y(function (d) {
        return d.y;
      });

    select(".js-bar-chart-container")
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .classed("svg-chart", true);

    this.updateChart(this.props.chartData);
  }

  componentDidUpdate() {
    this.updateChart(this.props.chartData);
  }

  updateChart(data) {
    select(this.container).datum([data]).call(this.chart);
  }

  render() {
    let className = "qa-svg-chart " + (this.props.className || "");
    return (
      <svg
        ref={(container) => {
          this.container = container;
        }}
        className={className}
        role="link"
        aria-label="Current vs Alternative allocations"
        width="100%"
        height="100%"
      />
    );
  }
}

InvestmentCheckupBarChart.propTypes = {
  className: PropTypes.string,
  xDomain: PropTypes.array,
  chartData: PropTypes.array,
};
