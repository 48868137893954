import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import Services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import PersonalStrategyTradesWidget from "components/PersonalStrategy/PersonalStrategyTradesWidget";
import DateUtil from "libs/pcap/utils/date";
import deepCopy from "deep-copy";

const PROPER_DATE_FORMAT = DateUtil.API_FORMAT;
const EXCLUDED_TRADE_CATEGORIES = [
  "NOT_TRADED",
  "DIVIDEND_RECEIVED",
  "DIVIDEND_RECEIVED_TAX_ADVANTAGED",
];

export default class PersonalStrategyTradesMadeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      loading: true,
    };

    this.onAccountsFetched = this.onAccountsFetched.bind(this);
  }

  fetchTransactionCategories() {
    return new Promise((resolve, reject) => {
      Services.TransactionCategories.get((err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }
        resolve(response);
      });
    });
  }

  fetchTransactions(onUsAccountIds) {
    let endDate = moment();

    return new Promise((resolve, reject) => {
      Services.Transactions.get(
        {
          startDate: endDate.clone().startOf("year").format(PROPER_DATE_FORMAT),
          endDate: endDate.format(PROPER_DATE_FORMAT),
          userAccountIds: JSON.stringify(onUsAccountIds),
        },
        (err, response) => {
          let errors = parseResponseErrors(err, response);
          if (errors) {
            reject(errors);
            return;
          }
          resolve(deepCopy(response));
        }
      );
    });
  }

  onAccountsFetched(err, response) {
    const errors = parseResponseErrors(err, response);
    if (errors) {
      this.setState(
        {
          errors: errors,
        },
        () => {
          this.props.finishedLoading?.();
        }
      );
      return;
    }

    const allAccounts =
      (response && response.spData && response.spData.accounts) || [];
    const onUsAccountIds = allAccounts
      .filter((account) => account.isOnUs || account.isOnusRetirement)
      .map((account) => account.userAccountId);

    return Promise.all([
      this.fetchTransactionCategories(),
      this.fetchTransactions(onUsAccountIds),
    ]).then(
      ([categoriesResponse, transactionsResponse]) => {
        this.onFetched(categoriesResponse, transactionsResponse);
      },
      (errors) => {
        this.setState(
          {
            errors: errors,
          },
          () => {
            this.props.finishedLoading?.();
          }
        );
      }
    );
  }

  getTransactionsForThisMonth(transactions) {
    let today = moment(),
      startDate = today.clone().startOf("month");

    return transactions.filter(function (transaction) {
      const transactionDate = moment(
        transaction.transactionDate,
        PROPER_DATE_FORMAT
      );

      return (
        transactionDate.isSame(startDate, "day") ||
        transactionDate.isSame(today, "day") ||
        transactionDate.isBetween(startDate, today, "day")
      );
    });
  }

  getLastTransactionTradeReason(transactions, tradeReasons) {
    const lastTransaction = transactions[transactions.length - 1];
    if (lastTransaction) {
      const lastTradeReason = tradeReasons.find(
        (reason) => reason.transactionCategoryId === lastTransaction.categoryId
      );
      if (lastTradeReason) {
        return {
          name: lastTradeReason.name,
          description: lastTradeReason.shortDescription,
        };
      }
    }
  }

  onFetched(categoriesResponse, transactionsResponse) {
    const transactionCategories = categoriesResponse.spData || [];
    const strategyTransactionCategories = transactionCategories.filter(
      (category) =>
        category.transactionCategoryKey &&
        !EXCLUDED_TRADE_CATEGORIES.includes(category.transactionCategoryKey)
    );
    const strategyTransactionCategoryIds = strategyTransactionCategories.map(
      (transactionCategory) => transactionCategory.transactionCategoryId
    );

    const transactions =
      (transactionsResponse.spData &&
        transactionsResponse.spData.transactions) ||
      [];
    const strategyTransactions = transactions.filter(function (transaction) {
      return strategyTransactionCategoryIds.includes(transaction.categoryId);
    });
    const strategyTransactionsThisMonth =
        this.getTransactionsForThisMonth(strategyTransactions),
      lastTransactionTradeReason = this.getLastTransactionTradeReason(
        strategyTransactions,
        strategyTransactionCategories
      );

    this.setState(
      {
        loading: false,
        thisMonthTrades: strategyTransactionsThisMonth.length,
        totalTradesYTD: strategyTransactions.length,
        lastTransactionTradeReason: lastTransactionTradeReason,
      },
      () => {
        this.props.finishedLoading?.();
      }
    );
  }

  componentDidMount() {
    this.watchedAccountsService = Services.Accounts.get.watch(
      {},
      this.onAccountsFetched,
      this
    );
  }

  componentWillUnmount() {
    if (this.watchedAccountsService) {
      Services.Accounts.get.unwatch(this.watchedAccountsService);
    }
  }

  render() {
    return (
      <>
        <PersonalStrategyTradesWidget
          errors={this.state.errors}
          loading={this.state.loading}
          thisMonthTrades={this.state.thisMonthTrades}
          totalTradesYTD={this.state.totalTradesYTD}
          lastTransactionTradeReason={this.state.lastTransactionTradeReason}
          titleClassName={this.props.titleClassName}
        />
      </>
    );
  }
}

PersonalStrategyTradesMadeContainer.propTypes = {
  titleClassName: PropTypes.string,
  finishedLoading: PropTypes.func,
};

PersonalStrategyTradesMadeContainer.defaultProps = {
  titleClassName: "",
  finishedLoading: undefined,
};
