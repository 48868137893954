/* eslint-disable react/display-name */
import { formatPercent } from "libs/pcap/utils/format";
import React from "react";

export const STAGES = {
  FUND_OPTIONS_NOT_AVAILABLE: "FUND_OPTIONS_NOT_AVAILABLE",
  FUND_RECOMMENDATION_IN_REVIEW: "FUND_RECOMMENDATION_IN_REVIEW",
  FUND_RECOMMENDATION_REVIEWED: "FUND_RECOMMENDATION_REVIEWED",
};

export const RECOMMENDATION_PIE_PROPS = {
  innerRadius: 0,
  padAngle: 0,
  height: 200,
  width: 200,
  chartClassName: "",
};

const assetClasses = [
  { key: "cash", title: "Cash" },
  { key: "intlBonds", title: "International Bonds" },
  { key: "usBonds", title: "US Bonds" },
  { key: "intlStocks", title: "International Stocks" },
  { key: "usStocks", title: "US Stocks" },
  { key: "alternatives", title: "Alternatives" },
  { key: "unclassified", title: "Unclassified" },
];

export function prepareDataForSectorPieChart(allocationSet, allocationType) {
  if (!allocationSet) {
    return;
  }

  return assetClasses.map((ac) => {
    return {
      value:
        (allocationSet &&
          allocationSet[ac.key] &&
          allocationSet[ac.key][allocationType]) ||
        0,
      title: ac.title,
      key: ac.key,
    };
  });
}

export function prepareDataForHoldingsPieChart(holdings) {
  if (!holdings) {
    return;
  }

  return holdings.map((h) => {
    return {
      value: h.value,
      title: h.name,
      key: h.name,
    };
  });
}

export function getSectorArcClasses(datum) {
  return {
    classesToAdd: [`recommendation-401k-pie-chart__arc--${datum.data.key}`],
  };
}

export function prepareDataForSectorTable(allocationSet) {
  if (!allocationSet) {
    return [];
  }

  return assetClasses.map((ac) => {
    return {
      key: ac.key,
      title: ac.title,
      targetAllocation: allocationSet[ac.key].targetAllocation,
      userAllocation: allocationSet[ac.key].userAllocation,
      difference: allocationSet[ac.key].difference,
    };
  });
}
/**
 * Returns a merged row of user and target holdings
 * @param {Array} uh - User Holdings
 * @param {Array} rh - Recommended Holdings
 *
 * @returns {Array} Array of merged rows
 */
export function prepareDataForHoldingsTable(uh, rh) {
  const holdingsSet = {};
  for (let i = 0; i < uh.length; i++) {
    // Use symbol to identify security, with description as fallback
    const key = uh[i].name || uh[i].description;
    holdingsSet[key] = {
      name: uh[i].name,
      description: uh[i].description,
      userAllocation: uh[i].value,
      targetAllocation: 0,
    };
  }

  for (let i = 0; i < rh.length; i++) {
    const key = rh[i].name || rh[i].description;
    if (holdingsSet[key]) {
      holdingsSet[key].targetAllocation = rh[i].value;
    } else {
      holdingsSet[key] = {
        name: rh[i].name,
        description: rh[i].description,
        targetAllocation: rh[i].value,
        userAllocation: 0,
      };
    }
  }

  return Object.values(holdingsSet);
}

function formatAllocationData(rawInput) {
  if (rawInput === 0) {
    return "-";
  }

  return formatPercent(rawInput, { precision: 1 });
}

function formatDifference(rawInput) {
  if (rawInput === 0) {
    return "-";
  }

  return `${rawInput > 0 ? "+" : ""}${formatPercent(rawInput, {
    precision: 1,
  })}`;
}

export const SECTOR_ALLOCATION_TABLE_COLUMNS = [
  {
    header: "",
    accessor: (d) => d.key,
    formatter: (key) => {
      return (
        <div
          className={`recommendation-401k-color recommendation-401k-color--${key}`}
        />
      );
    },
    style: {
      width: "1%",
    },
    className: "recommendation-401k-color-cell",
  },
  {
    header: "Asset Class",
    className: "table__column--left",
    accessor: (d) => d.title,
    style: {
      width: "45%",
    },
  },
  {
    header: "Current Allocation",
    className: "table__column--right",
    formatter: formatAllocationData,
    accessor: (d) => d.userAllocation,
    style: {
      width: "20%",
    },
  },
  {
    header: "Over/Under",
    className: "table__column--center table__column--highlight",
    formatter: formatDifference,
    accessor: (d) => d.difference,
    style: {
      width: "16%",
    },
  },
  {
    header: "Alternative allocation",
    className: "table__column--right",
    formatter: formatAllocationData,
    accessor: (d) => d.targetAllocation,
    style: {
      width: "17%",
    },
  },
];

export const HOLDING_TABLE_COLUMNS = [
  {
    header: "",
    accessor: (d) => d.key,
    formatter: (key) => {
      return (
        <div
          className={`recommendation-401k-color recommendation-401k-color--${key}`}
        />
      );
    },
    style: {
      width: "1%",
    },
    className: "recommendation-401k-color-cell",
  },
  {
    header: "Holding",
    className: "table__column--left",
    accessor: (d) => (
      <div>
        <div className="u-text-bold">{d.name}</div>
        <div className="pc-help-block">{d.description}</div>
      </div>
    ),
    style: {
      width: "49%",
    },
  },
  {
    header: "Current Fund Allocation",
    className: "table__column--right",
    formatter: formatAllocationData,
    accessor: (d) => d.userAllocation,
    style: {
      width: "23%",
    },
  },
  {
    header: "Recommended Fund Allocation",
    className: "table__column--right",
    formatter: formatAllocationData,
    accessor: (d) => d.targetAllocation,
    style: {
      width: "27%",
    },
  },
];
