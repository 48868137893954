import React from "react";

export default function InvestableCashSummaryZeroState() {
  return (
    <section className="summary-widget">
      <div className="summary-widget__header" tabIndex="0">
        Investable Cash
      </div>
      <div className="summary-widget__body advice-summary__body">
        <p className="dashboard-zero-state__summary centi">
          Link more bank and investment accounts to see how much cash you could
          invest.
        </p>
        <a
          href="#/accounts/add"
          className="pc-btn pc-btn--primary"
          role="button"
        >
          Link Account
        </a>
      </div>
    </section>
  );
}
