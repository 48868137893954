/* eslint-disable quote-props */
import PropTypes from "prop-types";
import React from "react";
import BaseInput from "../BaseInput";
import ReactAutocomplete from "react-autocomplete";

export default class InputWithAutocomplete extends BaseInput {
  constructor() {
    super(...arguments);
    this.ref = React.createRef();
  }

  focus() {
    this.ref.current.focus();
  }

  render() {
    const {
      prefix,
      suffix,
      onChange,
      onFocus,
      onBlur,
      errorBlockClassName,
      sizeVariation,
      value,
      helpText,
      maxLength,
      disabled,
      placeholder,
      autoFocus,
      id,
      name,
      refFunc,
      onAutocompleteSelect,
      autocompleteSuggestions,
    } = this.props;
    const inputGroupClassName =
      (sizeVariation ? `pc-input-group--${sizeVariation}` : "") +
      " " +
      (prefix ? "pc-input-group--with-prefix" : "");
    const inputSizeClassName = sizeVariation ? `input--${sizeVariation}` : "";
    const containerClassName = this.props.containerClassName || "";
    const className = `input ${
      this.props.className || ""
    } ${inputSizeClassName} ${this.state.valid ? "" : "input--error"} ${
      prefix || suffix ? "pc-input-group__field" : ""
    }`;

    return (
      <div className={containerClassName}>
        <div className={`pc-input-group ${inputGroupClassName}`}>
          {prefix && (
            <label className="pc-input-group__label pc-input-group__label--prefix">
              {prefix}
            </label>
          )}
          <ReactAutocomplete
            ref={this.ref}
            inputProps={{
              ref: refFunc,
              "data-lpignore": "true", // Disable LastPass icon
              id,
              name,
              className,
              placeholder,
              maxLength,
              onFocus,
              onBlur,
              disabled,
              autoFocus,
            }}
            wrapperProps={{ className: "dropdown input--full" }}
            value={value}
            items={autocompleteSuggestions}
            getItemValue={(item) => item.value} // Prop name as defined by the underlying third-party library <ReactAutocomplete />
            onSelect={onAutocompleteSelect}
            onChange={onChange}
            renderMenu={(children) => (
              <div className="menu menu--vertical menu--bordered menu--tiny u-display-block">
                {children}
              </div>
            )}
            renderItem={(item, isHighlighted) => (
              <div
                className={`menu__item ${
                  isHighlighted ? "menu__item--active" : ""
                }`}
                key={item.index}
              >
                <div className="menu__action">{item.label}</div>
              </div>
            )}
          />
          {suffix && (
            <label className="pc-input-group__label pc-input-group__label--suffix">
              {suffix}
            </label>
          )}
        </div>
        {helpText && (
          <label className="pc-help-block pc-help-block--tiny u-text-left">
            {helpText}
          </label>
        )}
        {this.getErrorBlock({
          placeholder: this.props.errorPlaceholder,
          className: errorBlockClassName,
        })}
      </div>
    );
  }
}

InputWithAutocomplete.propTypes = Object.assign({}, BaseInput.propTypes, {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onAutocompleteSelect: PropTypes.func,
  autocompleteSuggestions: PropTypes.array,
  className: PropTypes.string,
  errorBlockClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  sizeVariation: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helpText: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  errorPlaceholder: PropTypes.bool,
  autoFocus: PropTypes.bool,
});
