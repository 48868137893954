import PropTypes from "prop-types";
import React from "react";
import ViewPlanList from "components/sidebar/ViewPlanList";
import ComparePlanList from "components/sidebar/ComparePlanList";
import PlanListSection from "components/sidebar/PlanListSection";
import { VIEW_PLAN_LIST } from "components/sidebar/constants";
import {
  PLANS_SECTION_OFFICIAL,
  PLANS_SECTION_ALTERNATIVE,
  PLANS_SECTION_ADVISOR,
} from "components/financialPlanner/financialPlannerConstants";
import { omit } from "underscore";

function renderPlanSections(planListProps) {
  const { masterPlan, alternativePlans, advisorPlans } = planListProps;
  const otherProps = omit(
    planListProps,
    "masterPlan",
    "alternativePlans",
    "advisorPlans"
  );

  return (
    <div className="planner-control__plan-list qa-plan-list">
      <PlanListSection
        title="Official Plan"
        subtitle="Success Rate"
        sectionType={PLANS_SECTION_OFFICIAL}
        plans={masterPlan}
        {...otherProps}
      />
      {alternativePlans.length > 0 && (
        <PlanListSection
          title="Alternative Plans"
          sectionType={PLANS_SECTION_ALTERNATIVE}
          plans={alternativePlans}
          {...otherProps}
        />
      )}
      {advisorPlans.length > 0 && (
        <PlanListSection
          title="Advisor Prepared Plans"
          sectionType={PLANS_SECTION_ADVISOR}
          plans={advisorPlans}
          {...otherProps}
        />
      )}
    </div>
  );
}

const PlanList = (props) => {
  if (props.errors) {
    return (
      <div className="planner-control__error-message">
        Unable to retrieve plans.
      </div>
    );
  }

  const { plans } = props;
  const otherProps = omit(props, "plans");

  const masterPlan = plans.filter((plan) => plan.isMasterPlan === true);
  const alternativePlans = plans.filter(
    (plan) => !plan.isMasterPlan && !plan.planStatus
  );

  // Advisor-prepared plans always have a status defined; others do not. Only show PUBLISHED to regular users.
  const advisorPlans = plans.filter((plan) =>
    window.isAdvisorApp ? plan.planStatus : plan.planStatus === "PUBLISHED"
  );

  const planGroups = { masterPlan, alternativePlans, advisorPlans };

  if (props.viewType === VIEW_PLAN_LIST) {
    return (
      <ViewPlanList
        {...planGroups}
        {...otherProps}
        render={renderPlanSections}
      />
    );
  }

  // viewType is COMPARE_PLAN_LIST
  return (
    <ComparePlanList
      {...planGroups}
      {...otherProps}
      render={renderPlanSections}
    />
  );
};

PlanList.propTypes = {
  activePlanId: PropTypes.number,
  disabled: PropTypes.bool,
  onPlanSelected: PropTypes.func,
  plans: PropTypes.array,
  errors: PropTypes.array,
  viewType: PropTypes.string,
  isGMWB: PropTypes.bool,
  accounts: PropTypes.array,
  maModelPortfolios: PropTypes.array,
};

PlanList.defaultProps = {
  isGMWB: false,
  accounts: [],
  maModelPortfolios: [],
};

export default PlanList;
