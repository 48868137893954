const aceMessageActionButtonTypeToClassNameMap = {
  primary: "pc-btn pc-btn--primary",
  TAB: "pc-btn",
  "call-to-action": "pc-btn pc-btn--primary",
  danger: "pc-btn pc-btn--danger",
  warning: "pc-btn pc-btn--danger",
  bare: "pc-btn pc-btn--bare",
  dismiss: "pc-btn js-btn-dismiss",
  small: "pc-btn pc-btn--small",
};

/*
 * Given a button type, return the class names for that button
 *
 * @param {String} buttonType   The ACE message action button type as defined in ACE
 * @return {String} The class names of the button or 'pc-btn' if no mapping is found
 */
function getAceMessageActionButtonClassNameByType(buttonType) {
  return (
    aceMessageActionButtonTypeToClassNameMap[buttonType] ||
    buttonType ||
    "pc-btn"
  );
}

export default getAceMessageActionButtonClassNameByType;
