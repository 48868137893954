import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import "log";
var Questionnaire = Backbone.Model.extend({
  //  		defaults: {
  //  	        isAdvisorInterview: true
  //  	        , firstName: null
  //  	        , lastName: null
  //  	        , age: null
  //  	        , investableAssets: null
  //  	        , retirementHorizon: null
  //  	        , retirementAge: null
  //  	        , riskTolerance:null
  //  	        , companySize: null
  //  	        , spouseContributing: false
  //  	        , contributionPerYear: null
  //  	        , employeeMatchPerYear:null
  //  	        , annualPerformance: null
  //  	        , planAdminFees: null
  //  	        , defaultExpenseRatio: null
  //  	        , minimumAnnualContribution: null
  //  	        , maximumAnnualContriution: null
  //  	        , minimumEmployerMatch: null
  //  	        , maximumEmployerMatch: null
  //  	        , minimumAnnualGrowth: null
  //  	        , maximumAnnualGrowth:null
  //  	        , minimumPlanAdministrationFees:null
  //  	        , maximumPlanAdministrationFees:null
  //  	        , minimumExpenseRation:null
  //  	        , maximumExpenseRatio:null
  //  		}
  sync: function (method, collection, options) {
    if (method == "read") {
      Services.Person.get(this.onQuestionnaireFetched, this);
    } else if (method == "create") {
      Services.Person.update(this.toJSON(), this.onQuestionnaireUpdated, this);
    }
  },
  onQuestionnaireFetched: function (err, response) {
    if (err == null) {
      if (
        typeof response == "undefined" ||
        typeof response.spData == "undefined"
      )
        return;
      this.set(response.spData);
    }
  },
  onQuestionnaireUpdated: function (err, response) {
    if (err == null) {
      if (
        typeof response == "undefined" ||
        typeof response.spData == "undefined"
      )
        return;
      this.set(response.spData);
    }
  },
});

var questionnaire = new Questionnaire();
export default questionnaire;
