import moment from "moment";

export function aggregateBalanceSummaryByAccountIds(histories, accountIds) {
  return histories.map((item) => {
    const aggregateBalance = accountIds.reduce((memo, accountId) => {
      return memo + (item.balances[accountId] || 0);
    }, 0);
    const aggregateDailyChangeAmount = accountIds.reduce((memo, accountId) => {
      return memo + (item.dailyChangeAmount[accountId] || 0);
    }, 0);

    return {
      date: moment(item.date),
      aggregateBalance: aggregateBalance,
      oneDayChange: aggregateDailyChangeAmount,
    };
  });
}

export function aggregateBalanceSummary(histories) {
  return histories.map((item) => {
    return {
      date: moment(item.date),
      aggregateBalance: item.aggregateBalance,
      oneDayChange: item.aggregateDailyChangeAmount,
    };
  });
}
