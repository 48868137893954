/* eslint-disable no-magic-numbers */
import _ from "underscore";
import rebrandColor from "./rebrandColor";

const LIABILITY_COLORS_7 = [
  "#ff490d",
  "#ff6640",
  "#ff8c40",
  "#ffbb19",
  "#fed800",
  "#fef200",
  "#feff82",
];

const LIABILITY_COLORS_15 = [
  "#ff264e",
  "#ff490d",
  "#ff6640",
  "#ff8c40",
  "#ffbb19",
  "#fed800",
  "#fef200",
  "#feff82",
  "#6ad1e3",
  "#295fa6",
  "#6a7ce6",
  "#5a50e6",
  "#a255d9",
  "#e93cac",
  "#ff6ba6",
];

const LIABILITY_COLORS_27 = [
  "#db291f",
  "#ff490d",
  "#e8663c",
  "#ff9e15",
  "#ffbb19",
  "#fed800",
  "#fef200",
  "#ddf218",
  "#bee817",
  "#97d700",
  "#94eb49",
  "#9bff82",
  "#70f067",
  "#43e075",
  "#51c48c",
  "#3fd8b6",
  "#67e6d9",
  "#48c2e0",
  "#009cde",
  "#0072ce",
  "#0047bb",
  "#6a7ce6",
  "#5a50e6",
  "#8d5eeb",
  "#9b26b6",
  "#e93cac",
  "#fd87d1",
];

const LIABILITY_COLORS_40 = [
  "#ff264e",
  "#ff6640",
  "#e8663c",
  "#ff9e15",
  "#ffbb19",
  "#fed800",
  "#fef200",
  "#feff82",
  "#ddf218",
  "#bee817",
  "#ade014",
  "#97d700",
  "#94eb49",
  "#9bff82",
  "#70f067",
  "#45e650",
  "#49f26b",
  "#47d685",
  "#51c48c",
  "#3fd8b6",
  "#67e6d9",
  "#6ad1e3",
  "#48c2e0",
  "#2db4e0",
  "#418fde",
  "#0072ce",
  "#004b87",
  "#295fa6",
  "#6a7ce6",
  "#5e65eb",
  "#5a50e6",
  "#8d5eeb",
  "#a365e6",
  "#9b26b6",
  "#b522a9",
  "#d132af",
  "#e93cac",
  "#f25ebe",
  "#fd87d1",
  "#ff6ba6",
];

const LIABILITY_COLORS_60 = [
  "#db291f",
  "#ff490d",
  "#ff6640",
  "#e8663c",
  "#ff8c40",
  "#ff9e15",
  "#ffbb19",
  "#ffc640",
  "#fed800",
  "#fee500",
  "#fef200",
  "#feff82",
  "#ddf218",
  "#bee817",
  "#ade014",
  "#97d700",
  "#93e01f",
  "#94eb49",
  "#91f55f",
  "#9bff82",
  "#70f067",
  "#45e650",
  "#49f26b",
  "#43e075",
  "#47d685",
  "#51c48c",
  "#52cca3",
  "#3fd8b6",
  "#43e0c9",
  "#67e6d9",
  "#6ad1e3",
  "#48c2e0",
  "#2db4e0",
  "#009cde",
  "#418fde",
  "#0072ce",
  "#005eb8",
  "#0047bb",
  "#004b87",
  "#295fa6",
  "#4d73bf",
  "#6a7ce6",
  "#5e65eb",
  "#5a50e6",
  "#755ce6",
  "#8d5eeb",
  "#a365e6",
  "#a255d9",
  "#a53fd1",
  "#9b26b6",
  "#9e029e",
  "#b522a9",
  "#d132af",
  "#e93cac",
  "#f25ebe",
  "#fd87d1",
  "#ff78bb",
  "#ff6ba6",
  "#ff5988",
  "#ff264e",
];

const ASSET_COLORS_7 = [
  "#0047bb",
  "#0072ce",
  "#009cde",
  "#48c2e0",
  "#67e6d9",
  "#51c48c",
  "#43e075",
];

const ASSET_COLORS_15 = [
  "#0047bb",
  "#0072ce",
  "#009cde",
  "#48c2e0",
  "#67e6d9",
  "#43e075",
  "#9bff82",
  "#97d700",
  "#bee817",
  "#feff82",
  "#fed800",
  "#fd87d1",
  "#e93cac",
  "#9b26b6",
  "#8d5eeb",
];

const ASSET_COLORS_27 = [
  "#51c48c",
  "#3fd8b6",
  "#67e6d9",
  "#48c2e0",
  "#009cde",
  "#0072ce",
  "#0047bb",
  "#6a7ce6",
  "#5a50e6",
  "#8d5eeb",
  "#9b26b6",
  "#e93cac",
  "#fd87d1",
  "#ff6ba6",
  "#ff490d",
  "#e8663c",
  "#ff9e15",
  "#ffbb19",
  "#fed800",
  "#fef200",
  "#ddf218",
  "#bee817",
  "#97d700",
  "#94eb49",
  "#9bff82",
  "#70f067",
  "#43e075",
];

const ASSET_COLORS_40 = [
  "#51c48c",
  "#3fd8b6",
  "#67e6d9",
  "#6ad1e3",
  "#48c2e0",
  "#2db4e0",
  "#418fde",
  "#0072ce",
  "#004b87",
  "#295fa6",
  "#6a7ce6",
  "#5e65eb",
  "#5a50e6",
  "#8d5eeb",
  "#a365e6",
  "#9b26b6",
  "#b522a9",
  "#d132af",
  "#e93cac",
  "#f25ebe",
  "#fd87d1",
  "#ff6ba6",
  "#ff264e",
  "#ff6640",
  "#e8663c",
  "#ff9e15",
  "#ffbb19",
  "#fed800",
  "#fef200",
  "#feff82",
  "#ddf218",
  "#bee817",
  "#ade014",
  "#97d700",
  "#94eb49",
  "#9bff82",
  "#70f067",
  "#45e650",
  "#49f26b",
  "#47d685",
];

const ASSET_COLORS_60 = [
  "#51c48c",
  "#52cca3",
  "#3fd8b6",
  "#43e0c9",
  "#67e6d9",
  "#6ad1e3",
  "#48c2e0",
  "#2db4e0",
  "#009cde",
  "#418fde",
  "#0072ce",
  "#005eb8",
  "#0047bb",
  "#004b87",
  "#295fa6",
  "#4d73bf",
  "#6a7ce6",
  "#5e65eb",
  "#5a50e6",
  "#755ce6",
  "#8d5eeb",
  "#a365e6",
  "#a255d9",
  "#a53fd1",
  "#9b26b6",
  "#9e029e",
  "#b522a9",
  "#d132af",
  "#e93cac",
  "#f25ebe",
  "#fd87d1",
  "#ff78bb",
  "#ff6ba6",
  "#ff5988",
  "#ff264e",
  "#db291f",
  "#ff490d",
  "#ff6640",
  "#e8663c",
  "#ff8c40",
  "#ff9e15",
  "#ffbb19",
  "#ffc640",
  "#fed800",
  "#fee500",
  "#fef200",
  "#feff82",
  "#ddf218",
  "#bee817",
  "#ade014",
  "#97d700",
  "#93e01f",
  "#94eb49",
  "#91f55f",
  "#9bff82",
  "#70f067",
  "#45e650",
  "#49f26b",
  "#43e075",
  "#47d685",
];

export default {
  ASSET: "asset",
  LIABILITY: "liability",

  /*
   * This method generates an array of hex colors based on the following params:
   *
   * @param	numberOfItems A number of items that need colors
   * @param	colorFamily A string representations how the color spectrum is skewed
   * @param	excludeColor A hex color value
   *
   * @return	colorSet An array of hex colors
   */
  getColorSet(numberOfItems, colorFamily, excludeColor) {
    var colorSet;

    if (excludeColor) {
      numberOfItems++;
    }

    colorSet = rebrandColor.getRebrandColorSet(numberOfItems, colorFamily);

    if (!colorSet) {
      throw new Error("cannot find color set " + colorSet);
    }

    if (excludeColor) {
      colorSet = _.reject(colorSet, function (color) {
        return color.toUpperCase() === excludeColor.toUpperCase();
      });
    }

    if (colorSet.length < numberOfItems) {
      var multiple = Math.floor(numberOfItems / colorSet.length);

      for (var i = 0; i < multiple; i++) {
        var repeatColors = _.clone(colorSet);
        colorSet = colorSet.concat(repeatColors);
      }
    }

    return colorSet;
  },
  assetColors7: ASSET_COLORS_7,

  assetColors15: ASSET_COLORS_15,

  assetColors27: ASSET_COLORS_27,

  assetColors40: ASSET_COLORS_40,

  assetColors60: ASSET_COLORS_60,

  liabilityColors7: LIABILITY_COLORS_7,

  liabilityColors15: LIABILITY_COLORS_15,

  liabilityColors27: LIABILITY_COLORS_27,

  liabilityColors40: LIABILITY_COLORS_40,

  liabilityColors60: LIABILITY_COLORS_60,
};
