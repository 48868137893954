import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectableItemsList from "components/common/multiselectDropdown/SelectableItemsList";
import SelectableItemsGroup from "components/common/multiselectDropdown/SelectableItemsGroup";
import _ from "underscore";

/**
 * Creates an object from the items, where the key is `item.value`
 * and the value is corresponding the `item` objects.
 *
 * @param {Array} items the array of items
 * @returns {Object} checked items indexed by 'value'
 */
function indexItems(items) {
  // extra carefully clone the original items
  items = items.map((i) => Object.assign({}, i));
  return _.indexBy(items, "value");
}

/**
 * # Multiselect Dropdown
 *
 * The components for selecting items. Accepts an array of `items` which represent the items
 * in the following format:
 * ```js
  {
    checked: Boolean,     // the initial checked state
    value: String|Number, // unique identifier of the account
    name: String,         // top line
    info: String          // bottom line
  }
 * ```
 *
 * Returns the updated `items` array in `onChange` callback.
 *
 *
 * ## Props
 * - `items` {Array} -  an array of items to render
 * - `onChange` {Function} - called on "Done" click, returns an array of updated `items`.
 * - `onCancel` {Function} - called on "Cancel" and the dropdown toggle button click.
 * - `label` {String|Function} - returns the string to display on the button.
 * - `selectAllLabel` {String} - string to show next to checkbox that selects all items.
 * - `className` {String} - a class name to apply on the dropdown container element.
 * - `groupBy` {String|Function} - enables grouping of the items via underscore's `groupBy` function. Either a string that
 *    represents an object property or a function to be called on each item and should return a group name for the item.
 *    Items with the same group will be grouped together.
 * - `sortBy` {String|Function} - enables sorting of the items via underscore's `sortBy` function. Either a string that represents
 *    an object property or a function to be called on each item and should return a value used to sort items in ascending order.
 *
 *
 * ## Example
 * ```jsx
    <MultiselectDropdown
      items={Array}
      groupBy={Function}
      sortBy={String}
      onChange={Function}
      className={String}
      label={Function}
      selectAllLabel={String}
      onCancel={Function} />
 * ```
 *
 * @class MultiselectDropdown
 * @extends {Component}
 */
class MultiselectDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      label:
        typeof this.props.label === "function"
          ? this.props.label(props.items)
          : this.props.label,
      items: indexItems(props.items),
    };

    this.handleDropdownClick = this.handleDropdownClick.bind(this);
    this.handleDoneClick = this.handleDoneClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleOffClick = this.handleOffClick.bind(this);
    this.handleSelectDrop = this.handleSelectDrop.bind(this);
    this.eventBusEventName = "budget_page.select_account.click";
  }

  componentDidUpdate() {
    if (this.props.isAccountDropdownSelectorFocus) {
      this.buttonRef.current.focus();
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleOffClick);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setItems(nextProps.items);
    }
  }

  /**
   * Sets the array of the supplied items to the internal state.
   * Does a diff with the previous state, so the user selection is preserved.
   *
   * @param {Array} items the array of items
   */
  setItems(items) {
    const curItems = this.state.items;
    const newItems = indexItems(items);
    Object.values(newItems).forEach((item) => {
      const curItem = curItems[item.value];
      if (curItem) {
        item.checked = curItem.checked;
      }
    });
    this.setState({
      items: newItems,
      label:
        typeof this.props.label === "function"
          ? this.props.label(items)
          : this.props.label,
    });
  }

  toggle() {
    this.setState(
      {
        isOpen: !this.state.isOpen,
      },
      () => {
        if (this.state.isOpen) {
          if (this.props.onOpen && _.isFunction(this.props.onOpen)) {
            this.props.onOpen();
          }
          document.body.addEventListener("click", this.handleOffClick);
        } else {
          document.body.removeEventListener("click", this.handleOffClick);
        }
      }
    );
  }

  handleSelect(ev) {
    const el = ev.target;
    const { items } = this.state;
    items[el.value].checked = el.checked;
    this.setState({ items });
    // GA Event
    window.dashboardUtils?.eventBus.dispatch(this.eventBusEventName);
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type:
        window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
        "select_button",
      event_properties: {
        selection: this.eventBusEventName,
      },
    });
  }

  handleSelectDrop(element) {
    const { items } = this.state;
    const elementChecked = items[element.value];
    elementChecked.checked = !element.checked;
    this.setState({ items });
  }

  handleSelectGroup(ev) {
    const el = ev.target;
    const checked = el.checked;
    const groupName = el.dataset.groupName;
    const { groupBy } = this.props;
    const { items } = this.state;
    Object.values(items)
      .filter((i) => groupBy(i) === groupName)
      .forEach((i) => {
        i.checked = checked;
      });
    this.setState({ items });
    window.dashboardUtils?.eventBus.dispatch(this.eventBusEventName);
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type:
        window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
        "select_button",
      event_properties: {
        selection: this.eventBusEventName,
      },
    });
  }

  handleSelectAll(ev, element) {
    const checked = ev.target.checked;
    const { items } = this.state;
    if (ev.type === "keydown") {
      Object.values(items).forEach((i) => {
        if (!i.disabled) {
          i.checked = !element.checked;
        }
      });
    } else {
      Object.values(items).forEach((i) => {
        if (!i.disabled) {
          i.checked = checked;
        }
      });
    }
    this.setState({ items });
    window.dashboardUtils?.eventBus.dispatch(this.eventBusEventName);
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type:
        window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
        "select_button",
      event_properties: {
        selection: this.eventBusEventName,
      },
    });
  }

  cancel() {
    // revert the selection
    const origItems = indexItems(this.props.items);
    this.setState({ items: origItems });
    this.toggle();
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
  }

  handleDropdownClick() {
    if (this.state.isOpen) {
      this.cancel();
    } else {
      this.toggle();
    }
  }

  handleOffClick(event) {
    if (!this.el.contains(event.target)) {
      this.handleCancelClick();
    }
  }

  handleCancelClick() {
    this.cancel();
  }

  handleDoneClick() {
    const items = Object.values(this.state.items);
    if (typeof this.props.label === "function") {
      this.setState({
        label: this.props.label(items),
      });
    }
    const { onChange } = this.props;
    if (onChange) {
      onChange(items);
      this.setState({
        isOpen: false,
      });
      document.body.removeEventListener("click", this.handleOffClick);
    }
    window.dashboardUtils?.eventBus.dispatch("budget_page.save_button.click");
  }

  renderGroupedItems(groupedItems) {
    return Object.keys(groupedItems)
      .sort(this.props.groupSortBy)
      .map((group) => {
        const items = groupedItems[group];
        const checkedAll = !items.some((i) => !i.checked);
        return (
          <SelectableItemsGroup
            key={group}
            header={group}
            items={items}
            checkedAll={checkedAll}
            onChange={this.handleSelect}
            onChangeAll={this.handleSelectGroup}
            selectableItemType={this.props.selectableItemType}
            classNameHeader="menu__item--box menu__item--group"
            classNameItem="pc-u-m--"
            showBalancesAndTimeStamp={this.props.showBalancesAndTimeStamp}
            tabIndex="-1"
          />
        );
      });
  }

  stopPropagation(e) {
    e?.stopPropagation();
    e?.nativeEvent?.stopImmediatePropagation();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const { isOpen, label } = this.state;
    const { className, groupBy, sortBy, selectableItemType } = this.props;
    let items = Object.values(this.state.items);
    const dropdownList = document.querySelectorAll(".pc-u-mr--");
    this.buttonRef = React.createRef();

    const handleMoveButton = () => {
      this.setState({
        isOpen: false,
      });
      document.body.removeEventListener("click", this.handleOffClick);
    };

    const handleDropdownKeyDown = (e) => {
      const accountItems = document.querySelector(".menu--vertical");
      const scrollList = document.querySelector(".qa-multiselect-account-list");
      const boxSize = document.querySelector(".pc-u-mb--");
      const scrollDistance = 300;

      if (dropdownList && isOpen) {
        let itemsGroup = Array.from(dropdownList);
        let actual = Array.from(itemsGroup).findIndex((item) =>
          item.classList.contains("focused")
        );

        //handle close dropdown when the navigation back
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (isOpen && e.key === "Shift") {
          if (this.state.isOpen) {
            this.cancel();
          }
        }

        //handle navigation with arrows
        if (isOpen && (e.key === "ArrowDown" || e.key === "ArrowUp")) {
          e.preventDefault();
          if (itemsGroup[actual]) {
            itemsGroup[actual].classList.remove("focused");
          }
          actual += e.key === "ArrowUp" ? -1 : 1;

          //return to the bottom or top of the list
          if (actual < 0) {
            actual = itemsGroup.length - 1;
          } else if (actual >= itemsGroup.length) {
            actual = -1;
          }
          itemsGroup[actual].classList.add("focused");

          //move scroll when the element is out of the viewport
          const el = itemsGroup[actual];
          const firstItems = el.classList.contains("drop-menu");
          const position = itemsGroup[actual].getBoundingClientRect();
          const positionBox = accountItems.getBoundingClientRect();
          const positionBoxBottomSpacing = boxSize.offsetHeight - 3;
          const isVisible =
            position.top < positionBox.bottom - positionBoxBottomSpacing &&
            position.bottom > 0;
          const accountsNumber = dropdownList.length - 2;
          const itemBoxHeight = boxSize.offsetHeight;
          const maxHeight = accountsNumber * itemBoxHeight;
          const currentHeight = actual * itemBoxHeight;

          //scroll down
          if (!isVisible && firstItems && e.key === "ArrowDown") {
            scrollList.scroll(0, 0);
          }
          if (!isVisible && !firstItems && e.key === "ArrowDown") {
            scrollList.scrollBy(0, scrollDistance);
          }
          if (
            position.top < scrollDistance &&
            firstItems &&
            e.key === "ArrowDown"
          ) {
            scrollList.scroll(0, 0);
          }

          //scroll up
          if (!isVisible && !firstItems && e.key === "ArrowUp") {
            scrollList.scrollBy(0, maxHeight);
          }
          if (
            position.top < scrollDistance &&
            !firstItems &&
            e.key === "ArrowUp"
          ) {
            scrollList.scroll(0, currentHeight - itemBoxHeight);
          }
          if (
            position.top < scrollDistance &&
            firstItems &&
            e.key === "ArrowUp"
          ) {
            scrollList.scroll(0, 0);
          }
          this.stopPropagation();
        }

        //handle select items
        if (isOpen && (e.key === "Enter" || e.key === " ")) {
          e.preventDefault();
          const { items } = this.state;
          const el = itemsGroup[actual];
          const selectedItem = el.value;
          const firstItems = el.classList.contains("drop-menu");
          const element = firstItems ? el : items[selectedItem];
          if (firstItems) {
            this.handleSelectAll(e, element);
          } else {
            this.handleSelectDrop(element);
          }
          this.stopPropagation();
        }
      }
    };

    const isAllChecked = !items.some((i) => !i.disabled && !i.checked);
    const isNoItemChecked =
      !this.props.allowEmpty && !items.some((i) => !i.disabled && i.checked);
    if (sortBy) {
      items = _.sortBy(items, sortBy);
    }

    let groupedItems;
    if (groupBy) {
      groupedItems = _.groupBy(items, groupBy);
    }

    return (
      <div
        className={`dropdown js-multiselect-dropdown ${
          isOpen ? "open" : ""
        } ${className}`}
        ref={(el) => {
          this.el = el;
        }}
      >
        <button
          type="button"
          className="empower-account-selector-container  qa-multiselect-dropdown-btn"
          onClick={this.handleDropdownClick}
          onKeyDown={handleDropdownKeyDown}
          ref={this.buttonRef}
        >
          <div className="empower-account-selector-label empower-budgeting-account-selector-label u-sentence-case">
            {label}
          </div>
          <div
            className={`empower-account-selector-indicator pc-toggle pc-triangle-down--small`}
          />
        </button>

        <ul
          className="menu menu--vertical menu--bordered menu--tiny"
          style={{ display: `${isOpen ? "block" : "none"}` }}
        >
          <li className="menu__item menu__item--box menu__header ">
            <label className={`u-preserve-case`}>
              <input
                type="checkbox"
                className="pc-u-mr-- header-menu drop-menu"
                checked={isAllChecked}
                onChange={this.handleSelectAll}
                tabIndex="-1"
              />
              {this.props.selectAllLabel}
            </label>
          </li>
          <li
            className="menu__item menu__item--accounts-list qa-multiselect-account-list menu__header"
            tabIndex="-1"
          >
            {groupedItems ? (
              this.renderGroupedItems(groupedItems)
            ) : (
              <SelectableItemsList
                items={items}
                onChange={this.handleSelect}
                selectableItemType={selectableItemType}
                classNameItem="pc-u-m--"
                showBalancesAndTimeStamp={this.props.showBalancesAndTimeStamp}
                tabIndex="-1"
              />
            )}
          </li>
          <li className={`menu__item menu__item--box menu__footer pc-bg-dark`}>
            <div className="u-text-right">
              <button
                type="button"
                className="pc-btn pc-btn--small qa-account-cancel"
                onClick={this.handleCancelClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className="pc-btn pc-btn--small pc-btn--primary qa-account-done"
                onClick={this.handleDoneClick}
                disabled={isNoItemChecked}
                onKeyDown={handleMoveButton}
              >
                Done
              </button>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

MultiselectDropdown.propTypes = {
  className: PropTypes.string,
  items: SelectableItemsList.propTypes.items,
  groupBy: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  sortBy: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  groupSortBy: PropTypes.func,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  selectAllLabel: PropTypes.string,
  allowEmpty: PropTypes.bool,
  showBalancesAndTimeStamp: PropTypes.bool,
  selectableItemType: PropTypes.func,
  isAccountDropdownSelectorFocus: PropTypes.bool,
  onOpen: PropTypes.func,
};

MultiselectDropdown.defaultProps = {
  className: "",
  groupBy: undefined,
  sortBy: undefined,
  groupSortBy: undefined,
  onChange: undefined,
  onCancel: undefined,
  selectableItemType: undefined,
  items: [],
  selectAllLabel: "Select All",
  allowEmpty: true,
  showBalancesAndTimeStamp: false,
  isAccountDropdownSelectorFocus: false,
  onOpen: undefined,
};

export default MultiselectDropdown;
