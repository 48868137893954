import PropTypes from "prop-types";
import React from "react";
import { HoldingsGrid } from "./HoldingsGrid";
import defaultColumns from "./columns/default";
import Services from "services";
import analytics from "analytics";

const EMPOWER_PAGER_SIZE = 25;
const COULD_NOT_FETCH_QUOTE = "We could not find a price for this holding.";
const ERROR_CREATING_HOLDING =
  "We could not create this holding, Our engineering team has been alerted, please try again later.";
const ERROR_UPDATING_HOLDING =
  "We could not update this holding, Our engineering team has been alerted, please try again later.";
const ERROR_REMOVE_HOLDING =
  "We could not remove this holding, Our engineering team has been alerted, please try again later.";
export default class HoldingsGridContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnCreateHolding = this.handleOnCreateHolding.bind(this);
    this.handleOnUpdateHolding = this.handleOnUpdateHolding.bind(this);
    this.handleOnRemoveHolding = this.handleOnRemoveHolding.bind(this);
    this.handleOnFetchQuote = this.handleOnFetchQuote.bind(this);
  }

  handleOnCreateHolding(holding) {
    return new Promise((resolve, reject) => {
      Services.Holdings.create(holding, (err, response) => {
        if (response.spHeader.success) {
          resolve(response.spData.holding);
        }
        analytics.sendEngineeringEvent(
          "Error",
          "Holdings grid create holding " +
            JSON.stringify(response) +
            JSON.stringify(err)
        );
        reject([ERROR_CREATING_HOLDING]);
      });
    });
  }

  handleOnUpdateHolding(holding) {
    return new Promise((resolve, reject) => {
      Services.Holdings.update(holding, function (err, response) {
        if (response.spHeader.success) {
          resolve(response.spData.holding);
        }
        analytics.sendEngineeringEvent(
          "Error",
          "Holdings grid update holding " +
            JSON.stringify(response) +
            JSON.stringify(err)
        );
        reject([ERROR_UPDATING_HOLDING]);
      });
    });
  }

  handleOnRemoveHolding(holding) {
    return new Promise((resolve, reject) => {
      Services.Holdings.remove(holding, (err, response) => {
        if (response.spHeader.success) {
          resolve(response.spData.holding);
        }
        analytics.sendEngineeringEvent(
          "Error",
          "Holdings grid remove holding " +
            JSON.stringify(response) +
            JSON.stringify(err)
        );
        reject([ERROR_REMOVE_HOLDING]);
      });
    });
  }

  handleOnFetchQuote(ticker) {
    return new Promise((resolve, reject) => {
      Services.Securities.get(
        { resolveSearchTerm: ticker },
        (err, response) => {
          if (response.spData && response.spData.symbol) {
            resolve({
              ticker: response.spData.symbol,
              cusip: response.spData.cusip,
              description: response.spData.description,
              price: response.spData.currentPrice,
            });
          } else {
            reject([COULD_NOT_FETCH_QUOTE]);
          }
        }
      );
    });
  }

  render() {
    return (
      <HoldingsGrid
        {...this.props}
        onCreateHolding={this.handleOnCreateHolding}
        onUpdateHolding={this.handleOnUpdateHolding}
        onRemoveHolding={this.handleOnRemoveHolding}
        onFetchQuote={this.handleOnFetchQuote}
      />
    );
  }
}

HoldingsGridContainer.propTypes = {
  holdings: PropTypes.array.isRequired,
  groupBys: PropTypes.array,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  searchInput: PropTypes.string,
  headerRowClassName: PropTypes.string,
  onRowClick: PropTypes.func,
  onHoldingSelected: PropTypes.func,
  onHoldingUnSelected: PropTypes.func,
  columns: PropTypes.array,
  isEditable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  showSearch: PropTypes.bool,
  showAddHolding: PropTypes.bool,
  paginator: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  zeroState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  AddHoldingRow: PropTypes.oneOfType([PropTypes.func]),
  EditHoldingRow: PropTypes.oneOfType([PropTypes.func]),
  userAccountId: PropTypes.number,
};

HoldingsGridContainer.defaultProps = {
  columns: defaultColumns,
  showAddHolding: false,
  showSearch: false,
  isEditable: false,
  isSelectable: false,
  zeroState: "No holdings match your search criteria",
  className: "",
  containerClassName: undefined,
  groupBys: undefined,
  searchInput: undefined,
  headerRowClassName: undefined,
  onRowClick: undefined,
  onHoldingSelected: undefined,
  onHoldingUnSelected: undefined,
  AddHoldingRow: undefined,
  EditHoldingRow: undefined,
  userAccountId: undefined,
  paginator: {
    stepSize: IS_EMPOWER ? EMPOWER_PAGER_SIZE : 100,
    start: 0,
    className: "holdings-grid-paginator-container",
  },
};
