/*eslint no-magic-numbers: ["error", { "ignore": [0, 1, 90, 180, -90] }]*/
import React from "react";
import PropTypes from "prop-types";
import { pie, select, arc as arcD3 } from "d3";
import { SPACING_UNIT } from "constants/styles";

const HALF = 0.5;
const { PI } = Math;

export default class ArcChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.pie = pie()
      .value((d) => d.value)
      .sort(null)
      .startAngle(-90 * (PI / 180))
      .endAngle(90 * (PI / 180));
  }

  maybeGetInactiveClassName(datum) {
    const { inactiveClassName, activeArcKey } = this.props;
    return inactiveClassName && activeArcKey && datum.key !== activeArcKey
      ? inactiveClassName
      : "";
  }

  parseData(currentValues, targetValue) {
    const { remainderClassName } = this.props;
    if (currentValues == null || targetValue == null) {
      return [];
    }

    const sumOfAllValues = currentValues.reduce(
      (total, d) => total + d.value,
      0
    );
    const remainder = targetValue - sumOfAllValues;
    const hasNegativeValues = currentValues.some((d) => d.value < 0);
    let filledArcData;

    if (this.props.rescaleForNegatives && hasNegativeValues) {
      const sumOfPositiveValues = currentValues
        .filter((d) => d.value > 0)
        .reduce((total, d) => total + d.value, 0);
      const positiveToTotalRatio = sumOfAllValues / sumOfPositiveValues;
      const totalIsPositive = sumOfAllValues > 0;

      filledArcData = currentValues.map((d, i) => {
        return {
          className: `chart__series--arc-${
            i + 1
          } ${this.maybeGetInactiveClassName(d)}`,
          value: totalIsPositive ? positiveToTotalRatio * d.value : 0,
        };
      });
    } else {
      filledArcData = currentValues.map((d, i) => {
        return {
          className: `chart__series--arc-${
            i + 1
          } ${this.maybeGetInactiveClassName(d)}`,
          value: d.value,
        };
      });
    }

    const remainderArcData = {
      className: remainderClassName,
      value: remainder,
    };

    if (remainder <= 0) {
      return filledArcData;
    }
    return [...filledArcData, remainderArcData];
  }

  componentDidMount() {
    if (this.svgEl) {
      const rect = this.svgEl.getBoundingClientRect();
      this.setState(
        {
          width: rect.width,
          height: rect.height,
        },
        this.renderChart.bind(this)
      );
    }
  }

  /**
   * Post-update operations to re-do up d3 graph
   */
  componentDidUpdate() {
    this.renderChart();
  }

  renderChart() {
    const { currentValues, targetValue, pathClassName, onChartRender } =
      this.props;
    const data = this.parseData(currentValues, targetValue);
    const { width, height } = this.state;
    const heightPadded = height - this.props.paddingTop;
    const radius = Math.min(width * HALF, heightPadded);
    const pieData = this.pie(data);
    const container = select(this.chartContainer);
    const arc = arcD3()
      .outerRadius(radius)
      .innerRadius(radius - this.props.arcWidth);

    const arcs = container
      .selectAll(`.${pathClassName}`)
      .data(pieData, (d) => d.data.className);

    // exit
    arcs.exit().remove();

    // update
    arcs.attr("d", arc);

    // enter
    arcs
      .enter()
      .append("path")
      .attr("class", (d, i) => [pathClassName, data[i].className].join(" "))
      .attr("d", arc);

    if (onChartRender) {
      onChartRender(width, height, radius);
    }
  }

  render() {
    const { width, height } = this.state;
    const {
      className,
      arcClassName,
      underArcDisplay,
      paddingBottom,
      arcChartAriaLabel,
      arcChartDescribedBy,
    } = this.props;
    return (
      <div className={className}>
        <svg
          aria-label={arcChartAriaLabel}
          aria-describedby={arcChartDescribedBy}
          className="svg-chart-frame"
          ref={(el) => {
            this.svgEl = el;
          }}
        >
          <g
            className={arcClassName}
            transform={`translate(${width * HALF},${height - paddingBottom})`}
            ref={(el) => {
              this.chartContainer = el;
            }}
          />
        </svg>
        {underArcDisplay}
      </div>
    );
  }
}

ArcChart.propTypes = {
  currentValues:
    PropTypes.array
      .isRequired /* Array members must be objects with a value property. key property required if multi-sector and any interactivitiy is desired, e.g. highlighting.
                                                Example: {key: 'cash', value: 50000} */,
  targetValue: PropTypes.number.isRequired,
  arcWidth: PropTypes.number,
  className: PropTypes.string,
  underArcDisplay: PropTypes.element,
  arcClassName: PropTypes.string,
  pathClassName: PropTypes.string,
  remainderClassName: PropTypes.string,
  onChartRender: PropTypes.func,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  rescaleForNegatives: PropTypes.bool,
  activeArcKey: PropTypes.string, // The key for the sector to highlight. Valid only if invalidClassName is passed, and currentValues has keys defined.
  inactiveClassName: PropTypes.string, // Used to un-highlight inactive sectors. Valid only with multi-sector Arc Charts and when activeArcKey is passed.
  arcChartAriaLabel: PropTypes.string,
  arcChartDescribedBy: PropTypes.string,
};

ArcChart.defaultProps = {
  arcWidth: SPACING_UNIT,
  remainderClassName: "arc-chart__remainder",
  className: "arc-chart--default",
  pathClassName: "js-arc-chart-path",
  arcClassName: "js-arc-chart-arc",
  paddingTop: 4, // NOTE: this value matches `DEFAULT_MARGIN` constant from `scripts/libs/pcap/chart/chart.js`
  paddingBottom: 0,
  onChartRender: undefined,
  underArcDisplay: undefined,
  rescaleForNegatives: false,
  activeArcKey: "",
  inactiveClassName: "",
  arcChartAriaLabel: null,
  arcChartDescribedBy: null,
};
