export const AB_TEST_GROUP_A = "A"; // Variation
export const AB_TEST_GROUP_B = "B"; // Control

export const ABC_TEST_GROUP_A = "A"; // Control
export const ABC_TEST_GROUP_B = "B"; // First Variation
export const ABC_TEST_GROUP_C = "C"; // Second Variation

export const AB_TEST_GROUP_A_THRESHOLD = 7; // nth char from backwards of userGuid 0 - 7: AB_TEST_GROUP_A, 8 - F: AB_TEST_GROUP_B

const getABTestGroupForCurrentUser = (nth = 1) => {
  if (!window.userGuid) {
    return;
  }
  const userGuidLastChar = window.userGuid[window.userGuid.length - nth];
  const lastCharParsedAsInt = parseInt(userGuidLastChar, 10);
  const lastCharIsNumeric = !isNaN(lastCharParsedAsInt);
  const userIsInGroupA =
    lastCharIsNumeric && lastCharParsedAsInt <= AB_TEST_GROUP_A_THRESHOLD;
  return userIsInGroupA ? AB_TEST_GROUP_A : AB_TEST_GROUP_B;
};

export const getABTestGroupByTargetedUserGuidIds = (targetedUserGuidIds) => {
  if (!window.userGuid) {
    return;
  }
  if (targetedUserGuidIds) {
    const userGuidLastChar = window.userGuid.slice(-1);
    return targetedUserGuidIds.includes(userGuidLastChar)
      ? AB_TEST_GROUP_A
      : AB_TEST_GROUP_B;
  }
  return false;
};

export const getABCTestGroupByTargetedUserGuidIds = (targetedUserGuidIds) => {
  if (!window.userGuid) {
    return;
  }
  const userGuidLastChar = window.userGuid.slice(-1);
  if (targetedUserGuidIds?.test1?.includes(userGuidLastChar)) {
    return ABC_TEST_GROUP_B;
  }
  if (targetedUserGuidIds?.test2?.includes(userGuidLastChar)) {
    return ABC_TEST_GROUP_C;
  }
  return AB_TEST_GROUP_A;
};

export default getABTestGroupForCurrentUser;
