export const MAX_FILE_SIZE = 20971520;
export const MAX_DV_FILE_SIZE = 104857600;
export const MAX_IMAGE_SIZE = 10485760;

export const FILE_UPLOAD_STATUS_MESSAGE = {
  UPLOADING: "is uploading.",
  SUCCESS: "uploaded successfully.",
  FAIL: "failed to upload.",
};

export const FILE_UPLOAD_STATUS_MESSAGE_REBRAND = {
  UPLOADING: "uploading...",
  SUCCESS: "successfully uploaded!",
  FAIL: "failed to upload!",
};

export const INPUT_FILE_TYPES = {
  ALL: "bmp,csv,doc,docx,gif,jpeg,jpg,pdf,png,rtf,tiff,txt,xls,xlsx",
  DOCUMENTS:
    ".pdf,.doc,.docx,.odt,.txt,.pages,.rtf,.gif,.jpg,.jpeg,.png,.bmp,.xls,.xlsx,.csv",
  IMAGES: ".gif,.jpg,.jpeg,.png,.tiff,.bmp",
};

export const DOCUMENT_ENDPOINTS = {
  MILESTONE: window.baseUrl + "api/filecabinet/uploadMilestoneDocument",
  DOCUMENT: window.baseUrl + "api/filecabinet/uploadDocument",
  EMPOWER_DOCUMENT: window.baseUrl + "api/empower/filecabinet/uploadDocument",
  STATEMENT: window.baseUrl + "api/newaccount/uploadStatement",
  TRANSACTION_IMAGE: window.baseUrl + "api/transaction/updateTransactionImage",
};

export const DOCUMENT_ENDPOINT_PATHS = {
  MILESTONE: "api/filecabinet/uploadMilestoneDocument",
  DOCUMENT: "api/filecabinet/uploadDocument",
  EMPOWER_DOCUMENT: "api/empower/filecabinet/uploadDocument",
  STATEMENT: "api/newaccount/uploadStatement",
  TRANSACTION_IMAGE: "api/transaction/updateTransactionImage",
};

export const TYPE_VALIDATORS = {
  IMAGE_DOCUMENTS: {
    validate: (filename) => {
      return /(\.|\/)(gif|jpe?g|png|pdf|tiff|bmp|doc|docx|rtf|txt|xls|xlsx|csv)$/i.test(
        filename
      );
    },

    errorFormatter: () => {
      return `Unsupported file type. Please refer to the list of supported types before trying again.`;
    },
  },
  IMAGES: {
    validate: (filename) => {
      return /(\.|\/)(gif|jpe?g|png|tiff|bmp)$/i.test(filename);
    },

    errorFormatter: () => {
      return `Unsupported file type. Please refer to the list of supported types before trying again.`;
    },
  },
  DOCUMENT_NAME: {
    validate: (filename) => {
      const multipleExtensionValidation =
        filename.indexOf(".") === filename.lastIndexOf(".") ? true : false;
      const onlyFileName = multipleExtensionValidation
        ? filename.slice(0, filename.lastIndexOf("."))
        : "";
      const regForbiddenFileNames = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
      const regSpecialCharSpace = /^[a-zA-Z0-9 _()-.]+$/gi; // Alphanumeric and () - _. are accepted
      if (onlyFileName.trim().length > 0) {
        return (
          regSpecialCharSpace.test(onlyFileName) &&
          !regForbiddenFileNames.test(onlyFileName)
        );
      }
    },

    errorFormatter: () => {
      return `Invalid file name, please check the file name and proceed.`;
    },
  },
};
