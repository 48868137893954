import PropTypes from "prop-types";
import React from "react";
import ManageTags from "components/TransactionsGridV3/ManageTags";
import Modal from "components/modal/Modal";
import PerfectScrollbar from "react-perfect-scrollbar";

const ManageTagsModal = ({
  loading,
  errors,
  onCancel,
  isOpen,
  tags,
  disableCreateButton,
  onShowCreateTag,
  onShowUpdateTag,
  onShowDeleteTag,
}) => {
  return (
    <Modal
      componentName="ManageTagsModal"
      title="Manage Tags"
      isOpen={isOpen}
      onClosed={onCancel}
      className="pc-modal qa-manage-tags__modal"
      shouldCloseOnOverlayClick={false}
    >
      <PerfectScrollbar>
        <ManageTags
          tags={tags}
          disableCreateButton={disableCreateButton}
          onShowCreateTag={onShowCreateTag}
          onShowUpdateTag={onShowUpdateTag}
          onShowDeleteTag={onShowDeleteTag}
          loading={loading}
          errors={errors}
        />
      </PerfectScrollbar>
    </Modal>
  );
};

ManageTagsModal.propTypes = {
  loading: PropTypes.bool,
  disableCreateButton: PropTypes.bool,
  errors: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  onShowCreateTag: PropTypes.func.isRequired,
  onShowUpdateTag: PropTypes.func.isRequired,
  onShowDeleteTag: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

ManageTagsModal.defaultProps = {
  loading: false,
  disableCreateButton: false,
  errors: undefined,
};

export default ManageTagsModal;
