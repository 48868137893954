import Raphael from "raphael";
import $ from "jquery";
import Backbone from "backbone";
import _ from "underscore";
import Colors from "colorArrays";
import dollarAndCentsAmount from "templates/helpers/dollarAndCentsAmount";
import arrayUtil from "libs/pcap/utils/array";

const DEGREES_IN_CIRCLE = 360;

const ARIA_LABELS = {
  title: "Donut chart of your $mode by category",
  titleDrilldown:
    "Donut chart of your $mode by merchant for category $category",
  desc: "",
};
/*
 * Constructor for Donut class.
 *
 * @param	paper Reference to Raphael paper
 * @param	centerX X position on the paper for the center of the donut
 * @param	centerY Y position on the paper for the center of the donut
 * @param	outerRadius
 * @param	innerRadius
 * @param	categories An array of category objects.  Each object needs to
 *			have the following properties:
 *				percent
 *				, name
 *				, transactionCategoryId
 *				, amount
 */
function Donut(
  paper,
  centerX,
  centerY,
  outerRadius,
  innerRadius,
  categories,
  incomeExpenseMode,
  drilldownLevel
) {
  _.extend(this, Backbone.Events);
  this.paper = paper;
  this.centerX = centerX;
  this.centerY = centerY;
  this.outerRadius = outerRadius;
  this.innerRadius = innerRadius;
  this.stickOutSize = 5;
  this.shrinkSize = 5;
  // numberOfCategorySlices tracks the number of slices created.  This could be different from this.categories.length if some categories have 0 amounts.
  this.numberOfCategorySlices = 0;

  this.INCOME = "income";
  this.EXPENSE = "expense";
  this.drilldownLevel = drilldownLevel;
  this.incomeExpenseMode = incomeExpenseMode;
  this.paper.addArcAttributes();
  this.buildCategoryDonut(categories, incomeExpenseMode, drilldownLevel);
}

// baselineDuration is the time it should take to animate 10 slices at a certain ms per slice, which are sized evenly
Donut.prototype.baselineDuration = 800;

// baselinePause is based on 10 slices
Donut.prototype.baselinePause = 300;

Donut.prototype.generateRealtimeColors = function (
  numberOfItems,
  saturation,
  luminosity
) {
  var colors = [];
  var stepSize = 1 / numberOfItems;
  var hue;
  for (var i = 0; i < numberOfItems; i++) {
    hue = i * stepSize;
    colors.push(Raphael.hsb(hue, saturation, luminosity));
  }

  return colors;
};

Donut.prototype.calculateTotalDuration = function (numberOfSlices) {
  return (numberOfSlices / 10) * this.baselineDuration;
};

Donut.prototype.calculateDurationFromAngle = function (angle, totalDuration) {
  return (angle / DEGREES_IN_CIRCLE) * totalDuration;
};

Donut.prototype.calculatePauseBetweenSlices = function (numberOfSlices) {
  return this.baselinePause / numberOfSlices;
};

Donut.prototype.getModeDisplayName = function () {
  return this.incomeExpenseMode === this.INCOME
    ? this.INCOME
    : `${this.EXPENSE}s`;
};

Donut.prototype.getTitle = function () {
  let title =
    this.drilldownLevel === 0 ? ARIA_LABELS.title : ARIA_LABELS.titleDrilldown;
  title = title.replace("$mode", this.getModeDisplayName());
  if (this.drilldownLevel > 0) {
    title = title.replace("$category", this.selectedCategory?.name);
  }
  return title;
};

Donut.prototype.getDescription = function () {
  return ARIA_LABELS.desc;
};

Donut.prototype.addAriaInfo = function () {
  $(this.paper.canvas)
    .prepend(
      `<title id="donut-title-id"></title><desc id="donut-desc-id"></desc>`
    )
    .attr("aria-labelledby", "donut-title-id")
    .attr("aria-describedby", "donut-desc-id");
};

Donut.prototype.updateAriaInfo = function () {
  $(this.paper.canvas).find("#donut-title-id").text(this.getTitle());
  $(this.paper.canvas).find("#donut-desc-id").text(this.getDescription());
};

/* ====================================================
 *
 * 				Category functions
 *
 *  ====================================================
 */

/*
 * buildCategoryDonut creates the Raphael donut slice elements from the categories array.
 *
 * @param	categories An array of category objects.  Each object needs to
 *			have the following properties:
 *				percent
 *				, name
 *				, transactionCategoryId
 *				, amount
 */
Donut.prototype.buildCategoryDonut = function (
  categories,
  incomeExpenseMode,
  drilldownLevel
) {
  this.drilldownLevel = drilldownLevel;
  this.incomeExpenseMode = incomeExpenseMode;
  var colorSkew =
    incomeExpenseMode === this.INCOME ? Colors.ASSET : Colors.LIABILITY;
  var colors = Colors.getColorSet(categories.length, colorSkew);
  var startAngle = 0;
  var endAngle = 0;
  var self = this;

  this.numberOfCategorySlices = 0;
  this.paper.clear();

  if (!this.boundsEventListener) {
    this.boundsEventListener = function () {
      self.trigger("donutMouseout");
    };
  }

  this.donutBounds = this.paper
    .circle(this.centerX, this.centerY, this.outerRadius + 30)
    .attr({
      opacity: 0,
      fill: "red",
    });
  this.donutBounds.mouseover(this.boundsEventListener);

  this.categories = categories;

  for (var i = 0; i < categories.length; i++) {
    categories[i].color = colors[i];
    categories[i].sliceId = categories[i].transactionCategoryId;
    if (categories[i].amount > 0) {
      this.numberOfCategorySlices++;
      endAngle = startAngle + (categories[i].percent / 100) * DEGREES_IN_CIRCLE;
      categories[i].element = this.paper
        .path()
        .attr({
          circularArc: [
            this.centerX,
            this.centerY,
            this.outerRadius,
            this.innerRadius,
            startAngle,
            endAngle,
          ],
        })
        .attr({
          fill: colors[i],
          stroke: colors[i],
          title:
            categories[i].name +
            "\n" +
            dollarAndCentsAmount(
              categories[i].amount,
              true,
              true,
              incomeExpenseMode === this.EXPENSE,
              false
            ),
          cursor: "pointer",
        });
      categories[i].element.id = categories[i].transactionCategoryId;
      categories[i].element.data("startAngle", startAngle);
      categories[i].element.data("endAngle", endAngle);
      categories[i].element.mouseover(
        this.getCategoryListeners(this).onCategoryMouseover
      );
      categories[i].element.mouseout(
        this.getCategoryListeners(this).onCategoryMouseout
      );
      categories[i].element.click(
        this.getCategoryListeners(this).onCategoryClick
      );
      startAngle = endAngle;
    }
  }
  this.addAriaInfo();
  this.updateAriaInfo();
  this.trigger("ariaDataUpdated");
};

/*
 * updateCategoryDonut transitions the category donut to the new category data state.
 *
 * @param	categories An array of category objects.  Each object needs to
 *			have the following properties:
 *				percent
 *				, name
 *				, transactionCategoryId
 *				, amount
 */
Donut.prototype.updateCategoryDonut = function (
  newCategories,
  incomeExpenseMode,
  drilldownLevel
) {
  this.drilldownLevel = drilldownLevel;
  this.incomeExpenseMode = incomeExpenseMode;

  var self = this;
  var colorSkew =
    incomeExpenseMode === this.INCOME ? Colors.ASSET : Colors.LIABILITY;
  var colors = Colors.getColorSet(newCategories.length, colorSkew);

  var differences = arrayUtil.compareArrays(this.categories, newCategories, [
    "transactionCategoryId",
  ]);
  var categoryToBeDeleted;
  var i;
  var k;
  var deleteCompleted = false;
  var updateCompleted = false;
  var addCompleted = false;

  function replaceCategoriesArray() {
    if (deleteCompleted && updateCompleted && addCompleted) {
      self.categories = newCategories;
    }
  }

  /*
   * -----------  Remove slices code segment  ---------------
   */

  function removeDeletedSlices() {
    for (i = 0; i < differences.deletedItems.length; i++) {
      if (differences.deletedItems[i].amount > 0) {
        categoryToBeDeleted = _.find(self.categories, function (category) {
          return (
            category.transactionCategoryId ==
            differences.deletedItems[i].transactionCategoryId
          );
        });

        categoryToBeDeleted.element.remove();
        delete categoryToBeDeleted.element;
      }
    }

    deleteCompleted = true;
    replaceCategoriesArray();
  }

  // remove old slices
  if (differences.deletedItems.length > 0) {
    for (i = 0; i < differences.deletedItems.length; i++) {
      if (differences.deletedItems[i].amount > 0) {
        categoryToBeDeleted = _.find(this.categories, function (category) {
          return (
            category.transactionCategoryId ==
            differences.deletedItems[i].transactionCategoryId
          );
        });

        categoryToBeDeleted.element.unmouseover(
          this.getCategoryListeners(this).onCategoryMouseover
        );
        categoryToBeDeleted.element.unmouseout(
          this.getCategoryListeners(this).onCategoryMouseout
        );
        categoryToBeDeleted.element.unclick(
          this.getCategoryListeners(this).onCategoryClick
        );
        if (i === 0) {
          categoryToBeDeleted.element
            .stop()
            .animate({ opacity: 0 }, 200, removeDeletedSlices);
        } else {
          categoryToBeDeleted.element.stop().animate({ opacity: 0 }, 200);
        }
        this.numberOfCategorySlices--;
      }
    }
  } else {
    deleteCompleted = true;
    replaceCategoriesArray();
  }

  /*
   * -----------  Update slices code segment  ---------------
   */

  // create an array of start and end angles for for all items in newCategories
  var startAngle = 0;
  var currentStartAngle = 0;
  var endAngle = 0;
  var angles = _.map(newCategories, function (category) {
    endAngle = currentStartAngle + (category.percent / 100) * DEGREES_IN_CIRCLE;
    startAngle = currentStartAngle;
    currentStartAngle = endAngle;

    return { startAngle: startAngle, endAngle: endAngle };
  });

  // combine changed and unchanged categories into a single array
  var currentCategories = differences.changedItems.slice(0);
  currentCategories = currentCategories.concat(differences.unchangedItems);
  currentCategories = _.sortBy(currentCategories, "amount").reverse();
  var numberOfNonZeroCurrentCategories = _.reduce(
    currentCategories,
    function (memo, category) {
      return category.amount > 0 ? memo + 1 : memo;
    },
    0
  );

  if (currentCategories.length > 0) {
    for (i = 0; i < numberOfNonZeroCurrentCategories; i++) {
      // find index for current items in newCategories
      for (k = 0; k < newCategories.length; k++) {
        if (
          newCategories[k].transactionCategoryId ==
          currentCategories[i].transactionCategoryId
        ) {
          break;
        }
      }

      var categoryInOldList = _.find(this.categories, function (category) {
        return (
          category.transactionCategoryId ===
          newCategories[k].transactionCategoryId
        );
      });

      newCategories[k].color = colors[k];
      newCategories[k].sliceId = categoryInOldList.transactionCategoryId;
      newCategories[k].element = categoryInOldList.element;
      newCategories[k].element.data("startAngle", angles[k].startAngle);
      newCategories[k].element.data("endAngle", angles[k].endAngle);
      newCategories[k].element.attr({
        title:
          newCategories[k].name +
          "\n" +
          dollarAndCentsAmount(
            newCategories[k].amount,
            true,
            true,
            incomeExpenseMode === this.EXPENSE,
            false
          ),
      });
      if (i === numberOfNonZeroCurrentCategories - 1) {
        newCategories[k].element.stop().animate(
          {
            circularArc: [
              this.centerX,
              this.centerY,
              this.outerRadius,
              this.innerRadius,
              newCategories[k].element.data("startAngle"),
              newCategories[k].element.data("endAngle"),
            ],
            fill: colors[k],
            stroke: colors[k],
            opacity: 1,
          },
          200,
          function () {
            updateCompleted = true;
            replaceCategoriesArray();
          }
        );
      } else {
        newCategories[k].element.stop().animate(
          {
            circularArc: [
              this.centerX,
              this.centerY,
              this.outerRadius,
              this.innerRadius,
              newCategories[k].element.data("startAngle"),
              newCategories[k].element.data("endAngle"),
            ],
            fill: colors[k],
            stroke: colors[k],
            opacity: 1,
          },
          200
        );
      }
    }
  } else {
    updateCompleted = true;
    replaceCategoriesArray();
  }

  /*
   * -----------  Add slices code segment  ---------------
   */

  function addEventListenersToNewSlices() {
    for (k = 0; k < differences.addedItems.length; k++) {
      differences.addedItems[k].element.mouseover(
        self.getCategoryListeners(self).onCategoryMouseover
      );
      differences.addedItems[k].element.mouseout(
        self.getCategoryListeners(self).onCategoryMouseout
      );
      differences.addedItems[k].element.click(
        self.getCategoryListeners(self).onCategoryClick
      );
    }

    addCompleted = true;
    replaceCategoriesArray();
  }

  // for added items
  if (differences.addedItems.length > 0) {
    for (i = 0; i < differences.addedItems.length; i++) {
      // find index for addedItem in newCategories
      for (k = 0; k < newCategories.length; k++) {
        if (
          newCategories[k].transactionCategoryId ==
          differences.addedItems[i].transactionCategoryId
        ) {
          break;
        }
      }

      newCategories[k].color = colors[k];
      newCategories[k].sliceId = newCategories[k].transactionCategoryId;

      if (newCategories[k].amount > 0) {
        newCategories[k].element = this.paper.path().attr({
          circularArc: [
            this.centerX,
            this.centerY,
            this.outerRadius,
            this.innerRadius,
            angles[k].startAngle,
            angles[k].endAngle,
          ],
          opacity: 0,
          fill: colors[k],
          stroke: colors[k],
          title:
            newCategories[k].name +
            "\n" +
            dollarAndCentsAmount(
              newCategories[k].amount,
              true,
              true,
              false,
              true
            ),
          cursor: "pointer",
        });
        newCategories[k].element.id = newCategories[k].transactionCategoryId;
        newCategories[k].element.data("startAngle", angles[k].startAngle);
        newCategories[k].element.data("endAngle", angles[k].endAngle);
        newCategories[k].element.unmouseover(
          this.getCategoryListeners(this).onCategoryMouseover
        );
        newCategories[k].element.unmouseout(
          this.getCategoryListeners(this).onCategoryMouseout
        );
        newCategories[k].element.unclick(
          this.getCategoryListeners(this).onCategoryClick
        );
        this.numberOfCategorySlices++;

        if (i === differences.addedItems.length - 1) {
          differences.addedItems[i].element
            .stop()
            .animate({ opacity: 1 }, 200, addEventListenersToNewSlices);
        } else {
          differences.addedItems[i].element.stop().animate({ opacity: 1 }, 200);
        }
      }
    }
  } else {
    addCompleted = true;
    replaceCategoriesArray();
  }
  this.updateAriaInfo();
};

/*
 * When a category slice has been clicked and the data for category merchants have
 * been returned from getUserTransaction, transition to merchant donut state.
 *
 * @param	transactionCategoryId Used to identify which category slice was clicked.
 * @param	categories An array of category objects.
 * @param	merchants An array of merchant objects.  Each object needs to
 *			have the following properties:
 *				percent
 *				, name
 *				, amount
 */
Donut.prototype.drillDownToMerchantDonut = function (
  transactionCategoryId,
  merchants,
  incomeExpenseMode
) {
  var self = this;
  this.selectedCategory = _.find(this.categories, function (category) {
    return category.transactionCategoryId == transactionCategoryId;
  });

  var selectedCategoryAttr = this.selectedCategory.element.attr();
  // remove cataegory eventlisteners
  for (var j = 0; j < self.categories.length; j++) {
    self.categories[j].element.unmouseover(
      self.getCategoryListeners(self).onCategoryMouseover
    );
    self.categories[j].element.unmouseout(
      self.getCategoryListeners(self).onCategoryMouseout
    );
    self.categories[j].element.unclick(
      self.getCategoryListeners(self).onCategoryClick
    );
  }

  // Wrap the selected donut slice 360 degrees and call hideCategories when complete
  this.selectedCategory.element
    .stop()
    .toFront()
    .animate(
      {
        circularArc: [
          this.centerX,
          this.centerY,
          this.outerRadius + 10,
          this.innerRadius,
          0,
          DEGREES_IN_CIRCLE,
        ],
        opacity: 1,
      },
      300,
      hideCategories
    );

  // Hide categories slices and invoke createMerchantDonut
  function hideCategories() {
    for (var i = 0; i < self.categories.length; i++) {
      self.categories[i].element.unmouseover(
        self.getCategoryListeners(self).onCategoryMouseover
      );
      self.categories[i].element.unmouseout(
        self.getCategoryListeners(self).onCategoryMouseout
      );
      self.categories[i].element.unclick(
        self.getCategoryListeners(self).onCategoryClick
      );

      if (self.categories[i].transactionCategoryId != transactionCategoryId) {
        self.categories[i].element.stop().attr({
          circularArc: [
            self.centerX,
            self.centerY,
            self.outerRadius - self.shrinkSize,
            self.innerRadius + self.shrinkSize,
            self.categories[i].element.data("startAngle"),
            self.categories[i].element.data("endAngle"),
          ],
          opacity: 0,
        });
      } else {
        self.selectedCategory.element.mouseover(
          self.getSelectedCategoryListeners(self).onSelectedCategoryMouseover
        );
        self.selectedCategory.element.mouseout(
          self.getSelectedCategoryListeners(self).onSelectedCategoryMouseout
        );
        self.selectedCategory.element.click(
          self.getSelectedCategoryListeners(self).onSelectedCategoryClick
        );
      }
    }

    self.createMerchantDonut(
      merchants,
      selectedCategoryAttr.fill,
      incomeExpenseMode,
      self.drilldownLevel
    );
  }
};

/* ====================================================
 *
 * 				Resuable eventlisteners
 *
 *  ====================================================
 */

/*
 * getCategoryListeners is used when donut is at top level
 *
 * @param	instance Reference to an instance of Donut class.
 *
 * @return	an object containing eventlisteners
 */

Donut.prototype.getCategoryListeners = function (instance) {
  if (!instance._categoryListeners) {
    instance._categoryListeners = {
      // On category slice mouseover, expand the slice outward
      onCategoryMouseover: function (event) {
        var slice = this;
        this.stop().animate(
          {
            circularArc: [
              instance.centerX,
              instance.centerY,
              instance.outerRadius + instance.stickOutSize,
              instance.innerRadius,
              this.data("startAngle"),
              this.data("endAngle"),
            ],
            opacity: 1,
          },
          300,
          ">"
        );

        _.each(instance.categories, function (category) {
          if (category.percent > 0 && category.element !== slice) {
            category.element.stop().animate(
              {
                circularArc: [
                  instance.centerX,
                  instance.centerY,
                  instance.outerRadius,
                  instance.innerRadius,
                  category.element.data("startAngle"),
                  category.element.data("endAngle"),
                ],
                opacity: 0.5,
              },
              100
            );
          }
        });

        instance.trigger("sliceMouseover", this.id);
      },

      // On category slice mouseout, return slice to default size
      onCategoryMouseout: function (event) {
        var slice = this;
        this.stop().animate(
          {
            circularArc: [
              instance.centerX,
              instance.centerY,
              instance.outerRadius,
              instance.innerRadius,
              this.data("startAngle"),
              this.data("endAngle"),
            ],
          },
          100
        );

        _.each(instance.categories, function (category) {
          if (category.percent > 0 && category.element !== slice) {
            category.element.stop().animate(
              {
                circularArc: [
                  instance.centerX,
                  instance.centerY,
                  instance.outerRadius,
                  instance.innerRadius,
                  category.element.data("startAngle"),
                  category.element.data("endAngle"),
                ],
                opacity: 1,
              },
              100
            );
          }
        });

        instance.trigger("sliceMouseout", this.id);
      },

      onCategoryClick: function (event) {
        instance.trigger("categorySelected", this.id);
      },
    };
  }

  return instance._categoryListeners;
};

/*
 * getSelectedCategoryListeners is used when donut is at drilldown level 1.  It is used by the ring around the donut.
 *
 * @param	instance Reference to an instance of Donut class.
 *
 * @return	an object containing eventlisteners
 */

Donut.prototype.getSelectedCategoryListeners = function (instance) {
  if (!instance._selectedCategoryListeners) {
    instance._selectedCategoryListeners = {
      // On selected category slice mouseover, do a fadeOut/fadeIn effect
      onSelectedCategoryMouseover: function (event) {
        this.stop().animate(
          {
            opacity: 0.5,
          },
          600,
          instance._selectedCategoryListeners.fadeInOnSelectedCategory
        );
      },

      fadeInOnSelectedCategory: function () {
        this.stop().animate(
          {
            opacity: 1,
          },
          600,
          instance._selectedCategoryListeners.onSelectedCategoryMouseover
        );
      },

      // On selected category slice mouseout, return slice to default opacity
      onSelectedCategoryMouseout: function (event) {
        this.stop().attr({ opacity: 1 });
      },

      onSelectedCategoryClick: function (event) {
        this.toBack();
        this.stop().attr({ opacity: 1 });
        this.unmouseover(
          instance._selectedCategoryListeners.onSelectedCategoryMouseover
        );
        this.unmouseout(
          instance._selectedCategoryListeners.onSelectedCategoryMouseout
        );
        this.unclick(
          instance._selectedCategoryListeners.onSelectedCategoryClick
        );
        instance.trigger("drillUpToCategory");
        instance.returnToCategories();
      },
    };
  }

  return instance._selectedCategoryListeners;
};

/*
 * getMerchantListeners is used when donut is at drilldown level 1.  It is used by the donut slices.
 *
 * @param	instance Reference to an instance of Donut class.
 *
 * @return	an object containing eventlisteners
 */

Donut.prototype.getMerchantListeners = function (instance) {
  if (!this._merchantListeners) {
    this._merchantListeners = {
      onMerchantMouseover: function (event) {
        var slice = this;
        this.stop().animate(
          {
            circularArc: [
              instance.centerX,
              instance.centerY,
              instance.outerRadius + instance.stickOutSize,
              instance.innerRadius,
              this.data("startAngle"),
              this.data("endAngle"),
            ],
            opacity: 1,
          },
          300,
          ">"
        );

        instance.selectedCategory.element.stop().animate({ opacity: 0.1 }, 200);

        _.each(instance.merchants, function (merchant) {
          if (merchant.element !== slice) {
            merchant.element.stop().animate(
              {
                circularArc: [
                  instance.centerX,
                  instance.centerY,
                  instance.outerRadius,
                  instance.innerRadius,
                  merchant.element.data("startAngle"),
                  merchant.element.data("endAngle"),
                ],
                opacity: 0.5,
              },
              100
            );
          }
        });

        instance.trigger("sliceMouseover", this.id);
      },

      onMerchantMouseout: function (event) {
        var slice = this;
        this.stop().animate(
          {
            circularArc: [
              instance.centerX,
              instance.centerY,
              instance.outerRadius,
              instance.innerRadius,
              this.data("startAngle"),
              this.data("endAngle"),
            ],
            opacity: 1,
          },
          100
        );

        instance.selectedCategory.element.stop().animate({ opacity: 1 }, 200);

        _.each(instance.merchants, function (merchant) {
          if (merchant.element !== slice) {
            merchant.element.stop().animate(
              {
                circularArc: [
                  instance.centerX,
                  instance.centerY,
                  instance.outerRadius,
                  instance.innerRadius,
                  merchant.element.data("startAngle"),
                  merchant.element.data("endAngle"),
                ],
                opacity: 1,
              },
              100
            );
          }
        });

        instance.trigger("sliceMouseout", this.id);
      },

      onMerchantClick: function (event) {
        instance.trigger("merchantSelected", this.id);
      },
    };
  }

  return instance._merchantListeners;
};

/* ====================================================
 *
 * 				Merchant functions
 *
 *  ====================================================
 */

/*
 * createMerchantDonut creates the Raphael donut slice elements from the merchants array.
 *
 * @param	merchants An array of merchant objects.  Each object needs to
 *			have the following properties:
 *				percent
 *				, name
 *				, amount
 * @param	excludeColor Hex code of th color to exclude from the chart.

 */
Donut.prototype.createMerchantDonut = function (
  merchants,
  excludeColor,
  incomeExpenseMode,
  drilldownLevel
) {
  var colorSkew =
    incomeExpenseMode === this.INCOME ? Colors.ASSET : Colors.LIABILITY;
  var colors = Colors.getColorSet(merchants.length, colorSkew, excludeColor);
  var startAngle = 0;
  var endAngle = 0;
  var self = this;
  var i;

  this.excludeColor = excludeColor;
  this.merchants = merchants;

  this.incomeExpenseMode = incomeExpenseMode;
  this.drilldownLevel = drilldownLevel;

  // setup initial state of slices
  for (i = 0; i < merchants.length; i++) {
    endAngle = startAngle + (merchants[i].percent / 100) * DEGREES_IN_CIRCLE;
    merchants[i].element = this.paper.path().attr({
      circularArc: [
        this.centerX,
        this.centerY,
        this.outerRadius - this.shrinkSize,
        this.innerRadius + this.shrinkSize,
        startAngle,
        endAngle,
      ],
      opacity: 0,
      fill: colors[i],
      stroke: colors[i],
      // , 'stroke-width': 0
      title:
        merchants[i].name +
        "\n" +
        dollarAndCentsAmount(
          merchants[i].amount,
          true,
          true,
          incomeExpenseMode === this.EXPENSE,
          false
        ),
      cursor: "pointer",
    });
    merchants[i].color = colors[i];
    merchants[i].sliceId = merchants[i].id;
    merchants[i].element.id = merchants[i].id;
    merchants[i].element.data("startAngle", startAngle);
    merchants[i].element.data("endAngle", endAngle);
    startAngle = endAngle;
  }

  var delay = 0,
    sliceAngle,
    totalDuration = this.calculateTotalDuration(merchants.length),
    pause = this.calculatePauseBetweenSlices(merchants.length),
    addSliceEventListenersAndTriggerCompleteEvent = function () {
      for (var j = 0; j < merchants.length; j++) {
        merchants[j].element.mouseover(
          self.getMerchantListeners(self).onMerchantMouseover
        );
        merchants[j].element.mouseout(
          self.getMerchantListeners(self).onMerchantMouseout
        );
        merchants[j].element.click(
          self.getMerchantListeners(self).onMerchantClick
        );
      }
      self.trigger("drillDownToMerchantComplete");
    };

  // animate the display of slices
  for (i = 0; i < merchants.length; i++) {
    sliceAngle =
      merchants[i].element.data("endAngle") -
      merchants[i].element.data("startAngle");

    let animation;
    if (i === merchants.length - 1) {
      animation = Raphael.animation(
        {
          circularArc: [
            this.centerX,
            this.centerY,
            this.outerRadius,
            this.innerRadius,
            merchants[i].element.data("startAngle"),
            merchants[i].element.data("endAngle"),
          ],
          opacity: 1,
        },
        this.calculateDurationFromAngle(sliceAngle, totalDuration),
        ">",
        addSliceEventListenersAndTriggerCompleteEvent
      );
    } else {
      animation = Raphael.animation(
        {
          circularArc: [
            this.centerX,
            this.centerY,
            this.outerRadius,
            this.innerRadius,
            merchants[i].element.data("startAngle"),
            merchants[i].element.data("endAngle"),
          ],
          opacity: 1,
        },
        this.calculateDurationFromAngle(sliceAngle, totalDuration),
        ">"
      );
    }

    delay = pause * i;
    merchants[i].element.stop().animate(animation.delay(delay));
  }
  this.updateAriaInfo();
};

Donut.prototype.updateMerchantDonut = function (
  newMerchants,
  incomeExpenseMode,
  drilldownLevel
) {
  var self = this;
  var colorSkew =
    incomeExpenseMode === this.INCOME ? Colors.ASSET : Colors.LIABILITY;
  var colors = Colors.getColorSet(
    newMerchants.length,
    colorSkew,
    this.excludeColor
  );
  var differences = arrayUtil.compareArrays(this.merchants, newMerchants, [
    "id",
  ]);
  var merchantToBeDeleted;
  var merchantToBeAdded;
  var i;
  var k;
  var deleteCompleted = false;
  var updateCompleted = false;
  var addCompleted = false;

  this.incomeExpenseMode = incomeExpenseMode;
  this.drilldownLevel = drilldownLevel;

  function replaceMerchantsArray() {
    if (deleteCompleted && updateCompleted && addCompleted) {
      self.merchants = newMerchants;
      self.trigger("drillDownToMerchantComplete");
    }
  }

  /*
   * -----------  Remove slices code segment  ---------------
   */

  function removeDeletedSlices() {
    for (i = 0; i < differences.deletedItems.length; i++) {
      if (differences.deletedItems[i].amount > 0) {
        merchantToBeDeleted = _.find(self.merchants, function (merchant) {
          return merchant.id === differences.deletedItems[i].id;
        });

        merchantToBeDeleted.element.remove();
        delete merchantToBeDeleted.element;
      }
    }

    deleteCompleted = true;
    replaceMerchantsArray();
  }

  // remove old slices
  if (differences.deletedItems.length > 0) {
    for (i = differences.deletedItems.length - 1; i >= 0; i--) {
      if (differences.deletedItems[i].amount > 0) {
        merchantToBeDeleted = _.find(this.merchants, function (merchant) {
          return merchant.id === differences.deletedItems[i].id;
        });

        merchantToBeDeleted.element.unmouseover(
          this.getMerchantListeners(this).onMerchantMouseover
        );
        merchantToBeDeleted.element.unmouseout(
          this.getMerchantListeners(this).onMerchantMouseout
        );
        merchantToBeDeleted.element.unclick(
          this.getMerchantListeners(this).onMerchantClick
        );
        if (i === 0) {
          merchantToBeDeleted.element
            .stop()
            .animate({ opacity: 0 }, 200, removeDeletedSlices);
        } else {
          merchantToBeDeleted.element.stop().animate({ opacity: 0 }, 200);
        }
      }
    }
  } else {
    deleteCompleted = true;
    replaceMerchantsArray();
  }

  /*
   * -----------  Update slices code segment  ---------------
   */

  // create an array of start and end angles for for all items in newMerchants
  var startAngle = 0;
  var currentStartAngle = 0;
  var endAngle = 0;
  var angles = _.map(newMerchants, function (merchant) {
    endAngle = currentStartAngle + (merchant.percent / 100) * DEGREES_IN_CIRCLE;
    startAngle = currentStartAngle;
    currentStartAngle = endAngle;

    return { startAngle: startAngle, endAngle: endAngle };
  });

  // combine changed and unchanged merchants into a single array
  var currentMerchants = differences.changedItems.slice(0);
  currentMerchants = currentMerchants.concat(differences.unchangedItems);

  if (currentMerchants.length > 0) {
    for (i = currentMerchants.length - 1; i >= 0; i--) {
      // find index for current items in newMerchants
      for (k = 0; k < newMerchants.length; k++) {
        if (newMerchants[k].id == currentMerchants[i].id) {
          break;
        }
      }

      var merchantInOldList = _.find(this.merchants, function (merchant) {
        return merchant.id === newMerchants[k].id;
      });

      newMerchants[k].color = colors[k];
      newMerchants[k].sliceId = merchantInOldList.id;
      newMerchants[k].element = merchantInOldList.element;
      newMerchants[k].element.data("startAngle", angles[k].startAngle);
      newMerchants[k].element.data("endAngle", angles[k].endAngle);
      newMerchants[k].element.attr({
        title:
          newMerchants[k].name +
          "\n" +
          dollarAndCentsAmount(
            newMerchants[k].amount,
            true,
            true,
            incomeExpenseMode === this.EXPENSE,
            false
          ),
      });
      if (i === 0) {
        newMerchants[k].element.stop().animate(
          {
            circularArc: [
              this.centerX,
              this.centerY,
              this.outerRadius,
              this.innerRadius,
              newMerchants[k].element.data("startAngle"),
              newMerchants[k].element.data("endAngle"),
            ],
            fill: colors[k],
            stroke: colors[k],
            opacity: 1,
          },
          200,
          function () {
            updateCompleted = true;
            replaceMerchantsArray();
          }
        );
      } else {
        newMerchants[k].element.stop().animate(
          {
            circularArc: [
              this.centerX,
              this.centerY,
              this.outerRadius,
              this.innerRadius,
              newMerchants[k].element.data("startAngle"),
              newMerchants[k].element.data("endAngle"),
            ],
            fill: colors[k],
            stroke: colors[k],
            opacity: 1,
          },
          200
        );
      }
    }
  } else {
    updateCompleted = true;
    replaceMerchantsArray();
  }

  /*
   * -----------  Add slices code segment  ---------------
   */

  function addEventListenersToNewSlices() {
    for (k = 0; k < differences.addedItems.length; k++) {
      differences.addedItems[k].element.mouseover(
        self.getMerchantListeners(self).onMerchantMouseover
      );
      differences.addedItems[k].element.mouseout(
        self.getMerchantListeners(self).onMerchantMouseout
      );
      differences.addedItems[k].element.click(
        self.getMerchantListeners(self).onMerchantClick
      );
    }

    addCompleted = true;
    replaceMerchantsArray();
  }

  // for added items
  if (differences.addedItems.length > 0) {
    // add back selected category ring when going from zero state to non-zero state
    if (!this.selectedCategory.element) {
      var selectedCategoryInNewCategories = _.find(
        this.categories,
        function (category) {
          return (category.transactionCategoryId =
            self.selectedCategory.transactionCategoryId);
        }
      );

      selectedCategoryInNewCategories.element.attr({
        circularArc: [
          this.centerX,
          this.centerY,
          this.outerRadius + 10,
          this.innerRadius,
          0,
          DEGREES_IN_CIRCLE,
        ],
        opacity: 1,
      });
      selectedCategoryInNewCategories.element.mouseover(
        this.getSelectedCategoryListeners(this).onSelectedCategoryMouseover
      );
      selectedCategoryInNewCategories.element.mouseout(
        this.getSelectedCategoryListeners(this).onSelectedCategoryMouseout
      );
      selectedCategoryInNewCategories.element.click(
        this.getSelectedCategoryListeners(this).onSelectedCategoryClick
      );

      this.selectedCategory = selectedCategoryInNewCategories;
    }

    // setup initial state for added slices
    for (i = differences.addedItems.length - 1; i >= 0; i--) {
      if (differences.addedItems[i].amount > 0) {
        // find index for addedItem in newMerchants
        for (k = 0; k < newMerchants.length; k++) {
          if (newMerchants[k].id == differences.addedItems[i].id) {
            break;
          }
        }

        newMerchants[k].element = this.paper.path().attr({
          circularArc: [
            this.centerX,
            this.centerY,
            this.outerRadius,
            this.innerRadius,
            angles[k].startAngle,
            angles[k].endAngle,
          ],
          opacity: 0,
          fill: colors[k],
          stroke: colors[k],
          title:
            newMerchants[k].name +
            "\n" +
            dollarAndCentsAmount(
              newMerchants[k].amount,
              true,
              true,
              incomeExpenseMode === this.EXPENSE,
              false
            ),
          cursor: "pointer",
        });
        newMerchants[k].color = colors[k];
        newMerchants[k].sliceId = newMerchants[k].id;
        newMerchants[k].element.id = newMerchants[k].id;
        newMerchants[k].element.data("startAngle", angles[k].startAngle);
        newMerchants[k].element.data("endAngle", angles[k].endAngle);
      }
    }

    // animate the display of slices
    for (i = differences.addedItems.length - 1; i >= 0; i--) {
      if (i === 0) {
        differences.addedItems[i].element
          .stop()
          .animate({ opacity: 1 }, 200, addEventListenersToNewSlices);
      } else {
        differences.addedItems[i].element.stop().animate({ opacity: 1 }, 200);
      }
    }
  } else {
    addCompleted = true;
    replaceMerchantsArray();
  }
  this.updateAriaInfo();
};

/*
 * Shows the drilldown state without any am.
 *
 * @param	transactionCategoryId Used to identify which category slice was clicked.
 * @param	categories An array of category objects.
 * @param	merchants An array of merchant objects.  Each object needs to
 *			have the following properties:
 *				percent
 *				, name
 *				, amount
 */
Donut.prototype.nonAnimateDrillDownToMerchantDonut = function (
  transactionCategoryId,
  merchants,
  incomeExpenseMode
) {
  var self = this;

  this.selectedCategory = _.find(this.categories, function (category) {
    return category.transactionCategoryId == transactionCategoryId;
  });

  var selectedCategoryAttr = this.selectedCategory.element.attr();

  // Wrap the selected donut slice 360 degrees and call hideCategories when complete
  this.selectedCategory.element
    .stop()
    .toFront()
    .attr({
      circularArc: [
        this.centerX,
        this.centerY,
        this.outerRadius + 10,
        this.innerRadius,
        0,
        DEGREES_IN_CIRCLE,
      ],
      opacity: 1,
    });

  for (var i = 0; i < this.numberOfCategorySlices; i++) {
    this.categories[i].element.unmouseover(
      this.getCategoryListeners(this).onCategoryMouseover
    );
    this.categories[i].element.unmouseout(
      this.getCategoryListeners(this).onCategoryMouseout
    );
    this.categories[i].element.unclick(
      this.getCategoryListeners(this).onCategoryClick
    );

    if (this.categories[i].transactionCategoryId != transactionCategoryId) {
      this.categories[i].element.stop().attr({
        circularArc: [
          this.centerX,
          this.centerY,
          this.outerRadius - this.shrinkSize,
          this.innerRadius + this.shrinkSize,
          this.categories[i].element.data("startAngle"),
          this.categories[i].element.data("endAngle"),
        ],
        opacity: 0,
      });
    } else {
      this.selectedCategory.element.mouseover(
        this.getSelectedCategoryListeners(this).onSelectedCategoryMouseover
      );
      this.selectedCategory.element.mouseout(
        this.getSelectedCategoryListeners(this).onSelectedCategoryMouseout
      );
      this.selectedCategory.element.click(
        this.getSelectedCategoryListeners(this).onSelectedCategoryClick
      );
    }
  }

  var colorSkew =
    incomeExpenseMode === this.INCOME ? Colors.ASSET : Colors.LIABILITY;
  var colors = Colors.getColorSet(
    merchants.length,
    colorSkew,
    selectedCategoryAttr.fill
  );
  var startAngle = 0;
  var endAngle = 0;
  var i;

  this.excludeColor = selectedCategoryAttr.fill;
  this.merchants = merchants;

  // setup initial state of slices
  for (i = 0; i < merchants.length; i++) {
    endAngle = startAngle + (merchants[i].percent / 100) * DEGREES_IN_CIRCLE;
    merchants[i].element = this.paper.path().attr({
      circularArc: [
        this.centerX,
        this.centerY,
        this.outerRadius,
        this.innerRadius,
        startAngle,
        endAngle,
      ],
      opacity: 1,
      fill: colors[i],
      stroke: colors[i],
      // , 'stroke-width': 0
      title:
        merchants[i].name +
        "\n" +
        dollarAndCentsAmount(
          merchants[i].amount,
          true,
          true,
          incomeExpenseMode === this.EXPENSE,
          false
        ),
      cursor: "pointer",
    });
    merchants[i].color = colors[i];
    merchants[i].sliceId = merchants[i].id;
    merchants[i].element.id = merchants[i].id;
    merchants[i].element.data("startAngle", startAngle);
    merchants[i].element.data("endAngle", endAngle);
    merchants[i].element.mouseover(
      self.getMerchantListeners(self).onMerchantMouseover
    );
    merchants[i].element.mouseout(
      self.getMerchantListeners(self).onMerchantMouseout
    );
    merchants[i].element.click(self.getMerchantListeners(self).onMerchantClick);
    startAngle = endAngle;
  }
};

Donut.prototype.nonAnimateUpdateCategoryDonut = function (
  newCategories,
  incomeExpenseMode,
  drilldownLevel
) {
  var self = this;
  var colorSkew =
    incomeExpenseMode === this.INCOME ? Colors.ASSET : Colors.LIABILITY;
  var colors = Colors.getColorSet(newCategories.length, colorSkew);
  var differences = arrayUtil.compareArrays(this.categories, newCategories, [
    "transactionCategoryId",
  ]);
  var categoryToBeDeleted;
  var i;
  var k;

  this.incomeExpenseMode = incomeExpenseMode;
  this.drilldownLevel = drilldownLevel;

  // remove old slices
  for (i = 0; i < differences.deletedItems.length; i++) {
    if (differences.deletedItems[i].amount > 0) {
      categoryToBeDeleted = _.find(this.categories, function (category) {
        return (
          category.transactionCategoryId ==
          differences.deletedItems[i].transactionCategoryId
        );
      });

      categoryToBeDeleted.element.unmouseover(
        this.getCategoryListeners(this).onCategoryMouseover
      );
      categoryToBeDeleted.element.unmouseout(
        this.getCategoryListeners(this).onCategoryMouseout
      );
      categoryToBeDeleted.element.unclick(
        this.getCategoryListeners(this).onCategoryClick
      );
      categoryToBeDeleted.element.remove();
      delete categoryToBeDeleted.element;
      this.numberOfCategorySlices--;
    }
  }

  /*
   * -----------  Update slices code segment  ---------------
   */

  // create an array of start and end angles for for all items in newCategories
  var startAngle = 0;
  var currentStartAngle = 0;
  var endAngle = 0;
  var angles = _.map(newCategories, function (category) {
    endAngle = currentStartAngle + (category.percent / 100) * DEGREES_IN_CIRCLE;
    startAngle = currentStartAngle;
    currentStartAngle = endAngle;

    return { startAngle: startAngle, endAngle: endAngle };
  });

  // update current slices
  var currentCategories = differences.changedItems.slice(0);
  currentCategories = currentCategories.concat(differences.unchangedItems);

  for (i = 0; i < currentCategories.length; i++) {
    // find index for changedItem in newCategories
    for (k = 0; k < newCategories.length; k++) {
      if (
        newCategories[k].transactionCategoryId ==
        currentCategories[i].transactionCategoryId
      ) {
        break;
      }
    }

    var categoryInOldList = _.find(this.categories, function (category) {
      return (
        category.transactionCategoryId ===
        newCategories[k].transactionCategoryId
      );
    });

    if (categoryInOldList.amount > 0) {
      newCategories[k].color = colors[k];
      newCategories[k].sliceId = categoryInOldList.transactionCategoryId;
      newCategories[k].element = categoryInOldList.element;
      newCategories[k].element.data("startAngle", angles[k].startAngle);
      newCategories[k].element.data("endAngle", angles[k].endAngle);
      if (
        newCategories[k].transactionCategoryId !=
        this.selectedCategory.transactionCategoryId
      ) {
        newCategories[k].element.attr({
          circularArc: [
            this.centerX,
            this.centerY,
            this.outerRadius - this.shrinkSize,
            this.innerRadius + this.shrinkSize,
            newCategories[k].element.data("startAngle"),
            newCategories[k].element.data("endAngle"),
          ],
          fill: colors[k],
          stroke: colors[k],
        });
      } else {
        this.excludeColor = colors[k];
        newCategories[k].element.stop().animate(
          {
            fill: colors[k],
            stroke: colors[k],
          },
          200
        );
      }
    }
  }

  /*
   * -----------  Add slices code segment  ---------------
   */

  // for added items
  for (i = 0; i < differences.addedItems.length; i++) {
    // find index for addedItem in newCategories
    for (k = 0; k < newCategories.length; k++) {
      if (
        newCategories[k].transactionCategoryId ==
        differences.addedItems[i].transactionCategoryId
      ) {
        break;
      }
    }

    newCategories[k].color = colors[k];
    newCategories[k].sliceId = newCategories[k].transactionCategoryId;
    if (newCategories[k].amount > 0) {
      newCategories[k].element = this.paper.path().attr({
        circularArc: [
          this.centerX,
          this.centerY,
          this.outerRadius - this.shrinkSize,
          this.innerRadius + this.shrinkSize,
          angles[k].startAngle,
          angles[k].endAngle,
        ],
        opacity: 0,
        fill: colors[k],
        stroke: colors[k],
        title: newCategories[k].name + "\n$" + newCategories[k].amount,
        cursor: "pointer",
      });
      newCategories[k].element.id = newCategories[k].transactionCategoryId;
      newCategories[k].element.data("startAngle", angles[k].startAngle);
      newCategories[k].element.data("endAngle", angles[k].endAngle);
      // newCategories[k].element.unmouseover(this.categoryEventListeners.onCategoryMouseover);
      // newCategories[k].element.unmouseout(this.categoryEventListeners.onCategoryMouseout);
      // newCategories[k].element.unclick(this.categoryEventListeners.onCategoryClick);
      // console.log('new category', newCategories[k].transactionCategoryId, 'startAngle', newCategories[k].element.data('startAngle'), 'endAngle', newCategories[k].element.data('endAngle'));
      this.numberOfCategorySlices++;
    }
  }

  this.categories = newCategories;
};

Donut.prototype.hideMerchantSlicesAndUnwrapCategorySlice = function (callback) {
  var self = this,
    i,
    j = 0,
    delay = 0,
    animation,
    sliceAngle,
    totalDuration = this.calculateTotalDuration(this.merchants.length),
    pause = this.calculatePauseBetweenSlices(this.merchants.length);

  // unwrap selectedCategory
  function removeMerchantSlicesAndUnwrapCategory() {
    // remove merchart slices from paper
    for (i = self.merchants.length - 1; i >= 0; i--) {
      self.merchants[i].element.remove();
      delete self.merchants[i].element;
    }

    self.selectedCategory.element.toFront();
    // console.log('startAngle', self.selectedCategory.element.data('startAngle'), 'endAngle', self.selectedCategory.element.data('endAngle'));
    self.selectedCategory.element.stop().animate(
      {
        circularArc: [
          self.centerX,
          self.centerY,
          self.outerRadius,
          self.innerRadius,
          self.selectedCategory.element.data("startAngle"),
          self.selectedCategory.element.data("endAngle"),
        ],
      },
      300,
      callback
    );
  }

  if (this.merchants.length === 0) {
    callback();
  } else {
    // remove merchant slices eventListeners
    for (i = this.merchants.length - 1; i >= 0; i--) {
      const element = this.merchants[i].element;
      if (element) {
        element.unmouseover(
          this.getMerchantListeners(this).onMerchantMouseover
        );
        element.unmouseout(this.getMerchantListeners(this).onMerchantMouseout);
        element.unclick(this.getMerchantListeners(this).onMerchantClick);
      }
    }

    // hide merchant slice in reverse sequence
    for (i = this.merchants.length - 1; i >= 0; i--) {
      const element = this.merchants[i].element;
      if (element) {
        sliceAngle = element.data("endAngle") - element.data("startAngle");

        if (i === 0) {
          animation = Raphael.animation(
            {
              circularArc: [
                this.centerX,
                this.centerY,
                this.outerRadius - this.shrinkSize,
                this.innerRadius + this.shrinkSize,
                element.data("startAngle"),
                element.data("endAngle"),
              ],
              opacity: 0,
            },
            this.calculateDurationFromAngle(sliceAngle, totalDuration),
            ">",
            removeMerchantSlicesAndUnwrapCategory
          );
        } else {
          animation = Raphael.animation(
            {
              circularArc: [
                this.centerX,
                this.centerY,
                this.outerRadius - this.shrinkSize,
                this.innerRadius + this.shrinkSize,
                element.data("startAngle"),
                element.data("endAngle"),
              ],
              opacity: 0,
            },
            this.calculateDurationFromAngle(sliceAngle, totalDuration),
            ">"
          );
        }
        delay = pause * j++;
        element.stop().animate(animation.delay(delay));
      }
    }
  }
};

/*
 * returnToCategories transitions the state of the donut chart from merchant level
 * by to category level.
 *
 * @param	categories An array of category objects.  Each object needs to
 *			have the following properties:
 *				percent
 *				, name
 *				, transactionCategoryId
 *				, amount
 */
Donut.prototype.returnToCategories = function () {
  var self = this,
    i,
    animation,
    sliceAngle;
  this.hideMerchantSlicesAndUnwrapCategorySlice(rebuildCategorySlices);

  // rebuild category slices
  function rebuildCategorySlices() {
    var delay = 0,
      j = 0,
      totalDuration = self.calculateTotalDuration(self.categories.length),
      pause = self.calculatePauseBetweenSlices(self.categories.length);

    if (self.categories.length === 0) {
      self.trigger("drillUpToCategoryComplete");
    } else {
      // setup initial state of category slices
      for (i = 0; i < self.categories.length; i++) {
        if (
          self.categories[i].transactionCategoryId !=
          self.selectedCategory.transactionCategoryId
        ) {
          self.categories[i].element.stop().attr({
            circularArc: [
              self.centerX,
              self.centerY,
              self.outerRadius - self.shrinkSize,
              self.innerRadius + self.shrinkSize,
              self.categories[i].element.data("startAngle"),
              self.categories[i].element.data("endAngle"),
            ],
          });
        }
      }

      var triggerDrillUpToCateoryComplete = function () {
        for (i = self.categories.length - 1; i >= 0; i--) {
          self.categories[i].element.mouseover(
            self.getCategoryListeners(self).onCategoryMouseover
          );
          self.categories[i].element.mouseout(
            self.getCategoryListeners(self).onCategoryMouseout
          );
          self.categories[i].element.click(
            self.getCategoryListeners(self).onCategoryClick
          );
        }
        self.trigger("drillUpToCategoryComplete");
      };

      // animate in category slices
      for (i = self.categories.length - 1; i >= 0; i--) {
        sliceAngle =
          self.categories[i].element.data("endAngle") -
          self.categories[i].element.data("startAngle");

        if (i === self.categories.length - 1) {
          animation = Raphael.animation(
            {
              circularArc: [
                self.centerX,
                self.centerY,
                self.outerRadius,
                self.innerRadius,
                self.categories[i].element.data("startAngle"),
                self.categories[i].element.data("endAngle"),
              ],
              opacity: 1,
            },
            self.calculateDurationFromAngle(sliceAngle, totalDuration),
            ">",
            triggerDrillUpToCateoryComplete
          );

          self.categories[i].element.stop().animate(animation.delay(delay));
        } else {
          animation = Raphael.animation(
            {
              circularArc: [
                self.centerX,
                self.centerY,
                self.outerRadius,
                self.innerRadius,
                self.categories[i].element.data("startAngle"),
                self.categories[i].element.data("endAngle"),
              ],
              opacity: 1,
            },
            self.calculateDurationFromAngle(sliceAngle, totalDuration),
            ">"
          );
        }

        delay = pause * j++;
        self.categories[i].element.stop().animate(animation.delay(delay));
      }
    }
  }
};

/*
 * switchCategory transitions the state of the donut chart from one category to another.
 *
 * @param	transactionCategoryId Used to identify which category slice was clicked.
 * @param	merchants An array of merchant objects.  Each object needs assert.notTypeOf(value, name, "[message]");
 *			have the following properties:
 *				percent
 *				, name
 *				, amount
 */
Donut.prototype.switchCategory = function (
  transactionCategoryId,
  merchants,
  incomeExpenseMode
) {
  var self = this;

  self.selectedCategory.element.unmouseover(
    self.getSelectedCategoryListeners(self).onSelectedCategoryMouseover
  );
  self.selectedCategory.element.unmouseout(
    self.getSelectedCategoryListeners(self).onSelectedCategoryMouseout
  );
  self.selectedCategory.element.unclick(
    self.getSelectedCategoryListeners(self).onSelectedCategoryClick
  );
  // show new merchant slices
  function showNewCategorySelectionAndDrilldownToMerchant() {
    self.drillDownToMerchantDonut(
      transactionCategoryId,
      merchants,
      incomeExpenseMode
    );
  }
  this.hideMerchantSlicesAndUnwrapCategorySlice(
    showNewCategorySelectionAndDrilldownToMerchant
  );
};

Donut.prototype.setDrilldownLevel = function (drilldownLevel) {
  this.drilldownLevel = drilldownLevel;
};

Donut.prototype.simulateClickToDrillUp = function () {
  window.PCAP.NativeEventEmitter.dispatchMouseEvent(
    this.selectedCategory.element.node,
    "click"
  );
  // var evObj = document.createEvent('MouseEvents');
  // evObj.initEvent('click', true, false);
  // this.selectedCategory.element.node.dispatchEvent(evObj);
};

export default Donut;
