import PropTypes from "prop-types";
import React from "react";
import Message from "components/common/Message";
import Input from "components/common/form/Input";
import Select from "components/common/form/Select";
import AbstractForm from "components/common/form/AbstractForm";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const isPrivileged = isEmpowerPrivilegedMode();
export default class AddCategory extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
      errors: this.props.errors,
      model: {
        category: {
          name: this.props.categoryName,
          type: this.props.categoryType,
        },
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const category = this.state.model.category;
    // Check if the category already exists
    const found = this.props.categories.find(
      (cat) =>
        category.name.trim().toLowerCase() === cat.name.trim().toLowerCase()
    );

    // If the category already exists, don't call the service
    if (found) {
      this.setState({
        errors: ["Category already exists"],
      });
    } else if (this.validate().valid) {
      this.setState({
        creating: true,
      });
      this.props.onCreateCategory(this.state.model.category);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    return (
      <div>
        {this.state.errors && (
          <Message severity="error" messages={this.state.errors} />
        )}
        <form
          ref={(formContainer) => {
            this.formContainer = formContainer;
          }}
          onSubmit={this.handleSubmit}
        >
          <div className="pc-form-group">
            <label htmlFor="categoryName" className="pc-label" title="Required">
              Name
            </label>
            <Input
              id="categoryName"
              type="text"
              name="category.name"
              ref={this.storeInputRef}
              sizeVariation="full"
              maxLength={100}
              className="input input--full"
              onChange={this.handleInputChange}
              placeholder="Category name"
              value={this.state.model.category.name}
              validator={this.props.schema.properties.name}
            />
          </div>
          <div className="pc-form-group">
            <label htmlFor="categoryType" className="pc-label" title="Required">
              Type
            </label>
            <Select
              id="categoryType"
              name="category.type"
              ref={this.storeInputRef}
              value={this.state.model.category.type}
              className="input input--full qa-custom-category-type"
              onChange={this.handleInputChange}
              options={this.props.categoryTypes}
              validator={this.props.schema.properties.type}
            >
              <option />
            </Select>
          </div>
          {!this.state.creating && (
            <div className="pc-layout--right pc-u-mb-">
              <button
                type="button"
                className="pc-btn qa-custom-category-cancel"
                onClick={this.props.onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="pc-btn pc-btn--primary qa-custom-category-done"
                aria-disabled={isPrivileged}
                disabled={isPrivileged}
              >
                Done
              </button>
            </div>
          )}
          {this.state.creating && (
            <div className="pc-layout--right pc-u-mb-">
              <button type="submit" className="pc-btn pc-btn--primary" disabled>
                Creating...
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

AddCategory.propTypes = {
  errors: PropTypes.array,
  categories: PropTypes.array,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  onCreateCategory: PropTypes.func,
  categoryTypes: PropTypes.array,
  categoryType: PropTypes.string,
  categoryName: PropTypes.string,
};

// Static
AddCategory.defaultProps = {
  schema: {
    type: "object",
    properties: {
      name: {
        allowEmpty: false,
        type: "string",
        messages: {
          allowEmpty: "Please enter the category name.",
        },
      },
      type: {
        allowEmpty: false,
        type: "string",
        messages: {
          allowEmpty: "Please select the category type.",
        },
      },
    },
  },
  categoryType: "",
  categoryName: "",
  categories: [],
  errors: undefined,
};
