import PropTypes from "prop-types";
import React from "react";
import Table from "libs/pcap/table/Table";
import TableClient from "libs/pcap/table/tableClient/TableClient";
import memoizeOne from "memoize-one";
import Mixpanel from "mixpanel";
import { clone, isEmpty, isEqual, pick } from "underscore";
import {
  getPaginatedData,
  getFilteredData,
  getSortedData,
  getColumnsWithActiveFiltersAndOptions,
  doesDataRowPassFilterForEveryColumn,
  setSortingStateToColumn,
} from "libs/pcap/table/tableClient/TableClient";
import deepCopy from "deep-copy";

/**
 * Get a subset of data which matches a set of (potentially) multiple
 * simultaneous `activeFilter`s on the associated `columns`.
 *
 * @param {array} splits of transactions to filter.
 * @param {array} columns containing the `activeFilter` labels.
 *
 * @returns {array} of data which passes the filters in the columns.
 */
function getFilteredSplitsData(splits, columns) {
  const activelyFilteredColumns = columns.filter((column) => {
    return column.activeFilter && !isEmpty(column.activeFilter);
  });

  if (!isEmpty(activelyFilteredColumns)) {
    return splits.map((split) => {
      split.filterSplit = !doesDataRowPassFilterForEveryColumn(
        split,
        activelyFilteredColumns
      );
      return split;
    });
  }

  return splits;
}

const memoizedGetPaginatedData = memoizeOne(getPaginatedData);
const memoizedGetFilteredData = memoizeOne(getFilteredData);
const memoizedGetFilteredSplitsData = memoizeOne(getFilteredSplitsData);
const memoizedGetSortedData = memoizeOne(getSortedData);
const memoizedGetColumnsWithActiveFiltersAndOptions = memoizeOne(
  getColumnsWithActiveFiltersAndOptions
);
const memoizedSetSortingStateToColumn = memoizeOne(setSortingStateToColumn);

/**
 * This is a wrapper component for `Table` which provides sorting capabilities. It intercepts `onRequestSort`
 * callback sort data in memory. Uses `Pagination` to paginate if `shouldPaginate` prop is true
 *
 *
 * ## Props
 * Look at `Table` component's documentation for the complete list of supported properties. Additional properties are:
 * - `paginator` {Object | Boolean} - Object with data describing how to paginate table. Defaults to object with below values.
 *                                  - `stepSize`  {Number}     - Size of each page if table is paginated. Default 100
 *                                  - `start`     {Number}     - Where pagination should start. Default 0.
 *                                  - `className` {String}     - Classes to apply to paginator.

 *
 * ## Comparator
 * In addition to the standard column definition, `comparator` function must be provided for the sortable columns.
 *
 * The function receives:
 * 1. `accessor` function for the column
 * 2. Sort order `asc` or `desc`
 *
 * The function returns `compareFunction` which is used for actual comparison in `Array.prototype.sort(compareFunction)`.
 *
 *
 * There is generally no reason for implementing a custom comparator. There are standard comparators available in
 * `scripts/libs/pcap/table/data/compare.js`. They should cover most of possible cases:
 *  - `compareAlpha`
 *  - `compareNum`
 *
 * Example:
    ```
    {
      header: 'First Name',
      accessor: d => d.firstName,
      isSortable: true,
      sortOrder: 'asc',
      comparator: compareAlpha
    }
    ```
 *
 * @class TransactionsGridTableClient
 * @extends {TableClient}
 */
class TransactionsGridTableClient extends TableClient {
  constructor(props) {
    super(props);

    const { data, columns: columnsProp, paginator, isFilteringEnabled } = props;
    const columns = isFilteringEnabled
      ? memoizedGetColumnsWithActiveFiltersAndOptions(
          data,
          columnsProp,
          undefined,
          props.disableTransactionsFilters
        )
      : columnsProp;
    const sortedColumn = columns.find(
      (col) => typeof col.sortOrder === "string"
    );

    if (!sortedColumn) {
      // eslint-disable-next-line no-console
      console.error(
        "Warning: Using a sortable table, but none of the columns define the default `sortOrder`. Consider setting `sortOrder` on one of the columns."
      );
    }

    const pageStartProp = paginator && paginator.start ? paginator.start : 0;

    this.state = {
      columns,
      prevColumnsProp: columnsProp,
      sortedColumn,
      sortOrder: sortedColumn.sortOrder,
      prevPageStartProp: pageStartProp,
      prevDataProp: data,
      pageStart: pageStartProp,
      activeFilters: [],
    };

    // The Consumer needs to be updated so it can keep its state in sync.
    if (props.onPageDataChanged || props.onTransactionSortAndOrFilter) {
      const sortedData = memoizedGetSortedData(
        data,
        sortedColumn,
        sortedColumn.sortOrder
      );
      const renderData =
        pageStartProp && paginator && paginator.stepSize
          ? memoizedGetPaginatedData(
              sortedData,
              pageStartProp,
              paginator.stepSize
            )
          : sortedData;
      // Update the consumer about the updated pagination.
      if (props.onPageDataChanged) {
        props.onPageDataChanged(renderData, pageStartProp);
      }
      // Update consumer about the updated sort, so it
      // can be used for CSV export and paginator total.
      if (props.onTransactionSortAndOrFilter) {
        props.onTransactionSortAndOrFilter(sortedData);
      }
    }

    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleColumnFilterChange = this.handleColumnFilterChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};
    const {
      data: nextData,
      columns: nextColumns,
      isFilteringEnabled,
    } = nextProps;
    const {
      prevPageStartProp: prevPageStart,
      sortedColumn,
      sortOrder,
      activeFilters,
    } = prevState;
    const nextPageStart =
      nextProps.paginator && nextProps.paginator.start
        ? nextProps.paginator.start
        : 0;

    newState.columns = isFilteringEnabled
      ? memoizedGetColumnsWithActiveFiltersAndOptions(
          nextData,
          nextColumns,
          activeFilters,
          nextProps.disableTransactionsFilters
        )
      : nextColumns;

    // if the new columns contains the column we were sorting for, set the sort in the column array
    if (
      newState.columns.some((column) => column.header === sortedColumn.header)
    ) {
      // Set sorting state to the new columns
      newState.columns = memoizedSetSortingStateToColumn(
        newState.columns,
        sortedColumn,
        sortOrder
      );
    } else {
      // If the column that we were sorting for is not longer present, reset the sorting to whichever column prop has the sortOrder attribute
      newState.sortedColumn = newState.columns.find(
        (col) => typeof col.sortOrder === "string"
      );
      newState.sortOrder =
        (newState.sortedColumn && newState.sortedColumn.sortOrder) || undefined;
    }

    if (nextPageStart !== prevPageStart) {
      newState.pageStart = nextPageStart;
      newState.prevPageStartProp = nextPageStart;
    }

    return isEmpty(newState) ? null : newState;
  }

  componentDidUpdate(prevProps) {
    const { data, paginator, onPageDataChanged } = this.props;
    const dataWasUpdated = this.didTransactionsChange(prevProps.data, data);

    if (onPageDataChanged) {
      const paginatorWasUpdated = !isEqual(paginator, prevProps.paginator);
      /**
       * We need to call `onPageDataChanged` after the `data` or `paginator`
       * props are updated, so consumer gets what is really on the page.
       * Otherwise, they'll get the transactions showing before updating.
       */
      if (dataWasUpdated || paginatorWasUpdated) {
        // If we're on a page for which we dont have data then send pageStart 0 to the parent so it reset the paginator
        let pageStart = this.props.paginator ? this.props.paginator.start : 0;
        if (this.props.paginator && data.length <= this.props.paginator.start) {
          pageStart = 0;
        }
        onPageDataChanged(this.getDataInPage(pageStart), pageStart);
      }
    }

    // Consumer `TransactionsGridController` needs these transactions so it
    // can export them to CSV and track the total in its paginator.
    if (dataWasUpdated) {
      this.updateParentWithSortedAndOrFilteredTransactions();
    }
  }

  /**
   * Performs a deep compare between two transactions arrays omitting the
   * `isBeingMultiEdited` flag at the transaction level.
   *
   * @param {Array} previousTransactions - array containing the transactions to compare from
   * @param {Array} newTransactions -  array containing the transactions to compare to
   *
   * @return {Boolean} true if they're different, false if they're the same.
   */
  didTransactionsChange(previousTransactions, newTransactions) {
    // If there are more or less transactions
    if (previousTransactions.length !== newTransactions.length) {
      return true;
    } else if (
      previousTransactions.length === 0 &&
      newTransactions.length === 0
    ) {
      return false;
    }

    // Perform a deep compare omitting isBeingMultiEdited flag
    let keysToCompare = Object.keys(
      previousTransactions[0] || newTransactions[0]
    );
    keysToCompare = keysToCompare.filter(
      (keys) => keys !== "isBeingMultiEdited"
    );

    return previousTransactions.some((previousTransaction, i) => {
      let newTransaction = newTransactions[i];
      return !isEqual(
        pick(previousTransaction, keysToCompare),
        pick(newTransaction, keysToCompare)
      );
    });
  }

  handleRequestSort(sortedColumn, sortOrder) {
    this.setState(
      {
        sortedColumn,
        sortOrder,
      },
      () => {
        if (this.props.onPageDataChanged) {
          this.props.onPageDataChanged(this.getDataInPage(), 0);
        }
        // Consumer `TransactionsGridController` needs these transactions so it
        // can export them to CSV and track the total in its paginator.
        this.updateParentWithSortedAndOrFilteredTransactions();
      }
    );
  }

  handleColumnFilterOpen() {
    // Hotjar tracking.
    if (typeof window.hj !== "undefined") {
      window.hj("trigger", "js-hj-transactions-grid-column-filtering");
    }
  }

  handleColumnFilterChange(columnHeader, value) {
    let activeFilters = clone(this.state.activeFilters);
    let filterIndex = this.state.activeFilters.findIndex(
      (filter) => filter.header === columnHeader
    );

    if (filterIndex > -1) {
      activeFilters[filterIndex].value = value;
    } else {
      activeFilters.push({ header: columnHeader, value });
    }

    this.setState({ activeFilters }, () => {
      if (this.props.onPageDataChanged) {
        this.props.onPageDataChanged(this.getDataInPage(), 0);
      }

      // Consumer `TransactionsGridController` needs these transactions so it
      // can export them to CSV and track the total in its paginator.
      this.updateParentWithSortedAndOrFilteredTransactions();
    });

    // Add analytics.
    if (columnHeader === "Category" || columnHeader === "Tags") {
      Mixpanel.trackEvent(`Filtering by ${columnHeader}`, {
        selection: value,
      });
    }
  }

  getDataInPage(pageStart = this.state.pageStart) {
    const { data, paginator, isFilteringEnabled } = this.props;
    const { sortedColumn, sortOrder, columns } = this.state;
    const sortedData = memoizedGetSortedData(data, sortedColumn, sortOrder);
    const filteredData = isFilteringEnabled
      ? this.getFilteredTransactions(sortedData, columns)
      : memoizedGetFilteredData(sortedData, columns);
    return paginator && paginator.stepSize
      ? memoizedGetPaginatedData(filteredData, pageStart, paginator.stepSize)
      : filteredData;
  }

  /**
   * Performs a filter operation of data of transaction grid
   * and filters out transactions and splits that do not match
   * filter criteria.
   *
   * @param {Array} data - array of transactions
   * @param {Array} columns -  columns config
   *
   * @return {Array} Filtered transactions based on the filter criteria.
   */
  getFilteredTransactions(data, columns) {
    const hasSplits = data.some((d) => !isEmpty(d.splits));

    // Filter out transactions based on filter criteria.
    // Clone when at least one transaction has splits, this
    // will make sure that when filter is cleared filtered splits are shown again.
    let filteredTransactions = hasSplits
      ? deepCopy(memoizedGetFilteredData(data, columns))
      : memoizedGetFilteredData(data, columns);
    const transactionsWithSplits = filteredTransactions.filter(
      (d) => !isEmpty(d.splits)
    );

    // For all transactions with splits, filter out splits
    // based on filter criteria.
    if (!isEmpty(transactionsWithSplits)) {
      transactionsWithSplits.forEach((t) => {
        t.splits = memoizedGetFilteredSplitsData(t.splits, columns);
      });
    }

    // This will filter out a transaction that has splits but
    // all the splits are filtered out by the selected filter.
    // This will avoid rendering parent transaction when all the splits are
    // filtered out of a transaction.
    filteredTransactions = filteredTransactions.filter(
      (ft) => !ft.hasSplits || (ft.hasSplits && !isEmpty(ft.splits))
    );
    return filteredTransactions;
  }

  /**
   * Consumer `TransactionsGridController` needs to know about filtered
   * and/or sorted transactions, so it can export them to CSV and track
   * the total in its paginator.
   */
  updateParentWithSortedAndOrFilteredTransactions() {
    const {
      data,
      isFilteringEnabled,
      onTransactionSortAndOrFilter,
    } = this.props;
    const {
      sortedColumn,
      sortOrder,
      columns,
      activeFilters: activeColumnFilters,
    } = this.state;

    if (onTransactionSortAndOrFilter) {
      const sortedData = memoizedGetSortedData(data, sortedColumn, sortOrder);
      const sortedAndFilteredData = isFilteringEnabled
        ? this.getFilteredTransactions(sortedData, columns)
        : memoizedGetFilteredData(sortedData, columns);
      const sortedAndOrFilteredData =
        isFilteringEnabled && activeColumnFilters
          ? sortedAndFilteredData
          : sortedData;
      // Also let the consumer know if there are active column filters, so
      // that information can be included in the zeroState message when appropriate.
      const areThereActiveColumnFilters = activeColumnFilters.some(
        (columnFilter) => !isEmpty(columnFilter.value)
      );

      onTransactionSortAndOrFilter(
        sortedAndOrFilteredData,
        areThereActiveColumnFilters
      );
    }
  }

  render() {
    const { className, tableClassName, headerRowClassName } = this.props;
    const { columns } = this.state;

    return (
      <div className={className}>
        <Table
          {...this.props}
          columns={columns}
          data={this.getDataInPage()}
          onRequestSort={this.handleRequestSort}
          onColumnFilterOpen={this.handleColumnFilterOpen}
          onColumnFilterChange={this.handleColumnFilterChange}
          className={tableClassName + " table--overflow-visible"}
          headerRowClassName={headerRowClassName}
        />
      </div>
    );
  }
}
TransactionsGridTableClient.propTypes = Object.assign(
  {
    headerRowClassName: PropTypes.string,
    onPageDataChanged: PropTypes.func,
    onTransactionSortAndOrFilter: PropTypes.func,
  },
  TableClient.propTypes
);

TransactionsGridTableClient.defaultProps = {
  paginator: {
    stepSize: 100,
    start: 0,
  },
  className: "",
  tableClassName: "",
  headerRowClassName: "",
  isFilteringEnabled: false,
};

export default TransactionsGridTableClient;
