export default {
  timeSeries: [
    {
      x: 22,
      y: 106486,
    },
    {
      x: 23,
      y: 113327,
    },
    {
      x: 24,
      y: 120544,
    },
    {
      x: 25,
      y: 128157,
    },
    {
      x: 26,
      y: 136187,
    },
    {
      x: 27,
      y: 144658,
    },
    {
      x: 28,
      y: 153593,
    },
    {
      x: 29,
      y: 163019,
    },
    {
      x: 30,
      y: 172961,
    },
    {
      x: 31,
      y: 183450,
    },
    {
      x: 32,
      y: 194513,
    },
    {
      x: 33,
      y: 206183,
    },
    {
      x: 34,
      y: 218494,
    },
    {
      x: 35,
      y: 231480,
    },
    {
      x: 36,
      y: 245178,
    },
    {
      x: 37,
      y: 259628,
    },
    {
      x: 38,
      y: 274870,
    },
    {
      x: 39,
      y: 290949,
    },
    {
      x: 40,
      y: 307909,
    },
    {
      x: 41,
      y: 325800,
    },
    {
      x: 42,
      y: 344672,
    },
    {
      x: 43,
      y: 364580,
    },
    {
      x: 44,
      y: 385580,
    },
    {
      x: 45,
      y: 407731,
    },
    {
      x: 46,
      y: 431098,
    },
    {
      x: 47,
      y: 455747,
    },
    {
      x: 48,
      y: 481748,
    },
    {
      x: 49,
      y: 509175,
    },
    {
      x: 50,
      y: 538107,
    },
    {
      x: 51,
      y: 568626,
    },
    {
      x: 52,
      y: 600819,
    },
    {
      x: 53,
      y: 634778,
    },
    {
      x: 54,
      y: 670599,
    },
    {
      x: 55,
      y: 708386,
    },
    {
      x: 56,
      y: 709746,
    },
    {
      x: 57,
      y: 711181,
    },
    {
      x: 58,
      y: 712694,
    },
    {
      x: 59,
      y: 714290,
    },
    {
      x: 60,
      y: 715974,
    },
    {
      x: 61,
      y: 717750,
    },
    {
      x: 62,
      y: 719623,
    },
    {
      x: 63,
      y: 721599,
    },
    {
      x: 64,
      y: 723684,
    },
    {
      x: 65,
      y: 725883,
    },
    {
      x: 66,
      y: 728203,
    },
    {
      x: 67,
      y: 730650,
    },
    {
      x: 68,
      y: 733231,
    },
    {
      x: 69,
      y: 735953,
    },
    {
      x: 70,
      y: 738825,
    },
    {
      x: 71,
      y: 741855,
    },
    {
      x: 72,
      y: 745051,
    },
    {
      x: 73,
      y: 748422,
    },
    {
      x: 74,
      y: 751978,
    },
    {
      x: 75,
      y: 755729,
    },
    {
      x: 76,
      y: 759686,
    },
    {
      x: 77,
      y: 763860,
    },
    {
      x: 78,
      y: 768263,
    },
    {
      x: 79,
      y: 772908,
    },
    {
      x: 80,
      y: 777807,
    },
    {
      x: 81,
      y: 782975,
    },
    {
      x: 82,
      y: 788426,
    },
    {
      x: 83,
      y: 794177,
    },
    {
      x: 84,
      y: 800243,
    },
    {
      x: 85,
      y: 806642,
    },
    {
      x: 86,
      y: 813392,
    },
    {
      x: 87,
      y: 820512,
    },
    {
      x: 88,
      y: 828022,
    },
    {
      x: 89,
      y: 835945,
    },
    {
      x: 90,
      y: 844302,
    },
    {
      x: 91,
      y: 853118,
    },
    {
      x: 92,
      y: 862417,
    },
  ],
};
