import ArcChart from "components/common/charts/ArcChart";
import React from "react";
import PropTypes from "prop-types";
import { transition, select, arc, pie as pieD3, timeDays } from "d3";
import { SPACING_UNIT, SPACING_UNIT_SMALL } from "constants/styles";
import { formatCurrency } from "libs/pcap/utils/format";
import FlexibleDollar from "components/common/FlexibleDollar";
import moment from "moment";

const JULY = 6;
const MID_YEAR = moment([moment().year(), JULY, 1]);
const HALF_YEAR = 183;
const MAX_MONTH_MARGIN_FACTOR = 0.23; // Distance of month label from arc for ends of the year, based on arc radius
const MIN_MONTH_MARGIN_FACTOR = 0.14; // Distance of month label from arc for middle of the year, based on arc radius
const POINT_MARGIN = 6; // Distance of point from arc
const POINT_RADIUS = 3; // Size of point
const MAIN_ARC_CLASS = "js-savings-progress-chart-main-arc";

const OUTER_ARC_MARGIN = 6;
const OUTER_ARC_WIDTH = 1;

const { PI } = Math;

const defaultBuildUnderArc = (
  currentSavings,
  targetSavings,
  isRetired,
  onEditFn
) => {
  const YEARLY_SAVINGS_PLAN_EDIT_BUTTON_LABEL = `Edit yearly ${
    isRetired ? "" : "savings"
  } plan`;

  return (
    <div className="savings-progress-chart__under-arc">
      <div
        className={`
          ${
            isRetired
              ? "savings-progress-chart__current-savings--retired"
              : "savings-progress-chart__current-savings"
          } qa-savings-progress-chart-current-savings`}
      >
        <FlexibleDollar
          value={isRetired ? Math.max(0, currentSavings) : currentSavings}
        />
      </div>
      <div className={`milli qa-retirement-savings-target-balance`}>
        of{" "}
        <span className="qa-target-annual-savings">
          {formatCurrency(targetSavings, 0)}
        </span>
      </div>
      <div className={`u-sentence-case milli`}>
        Yearly {!isRetired && "Savings "}Plan
        {onEditFn && (
          <button
            type="button"
            className="pc-btn pc-btn--stripped pc-btn--tiny qa-edit-yearly-savings"
            onClick={onEditFn}
            aria-label={YEARLY_SAVINGS_PLAN_EDIT_BUTTON_LABEL}
          >
            <svg
              className="savings-progress-chart__edit-button-icon"
              viewBox="0 0 9 9"
              aria-hidden
              alt=""
            >
              <use xlinkHref="#icon-pencil" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

function point(arc) {
  return function (context) {
    var hasInheritedTransition = context instanceof transition;
    var selection = hasInheritedTransition ? context.selection() : context;
    const point = selection
      .selectAll(".js-savings-progress-month-point")
      .data((d) => d);

    point.attr("r", POINT_RADIUS).attr("transform", function (d) {
      return "translate(" + arc.centroid(d) + ")";
    });

    point
      .enter()
      .append("circle")
      .classed("js-savings-progress-month-point", true)
      .attr("r", POINT_RADIUS)
      .attr("transform", function (d) {
        return "translate(" + arc.centroid(d) + ")";
      });

    point.exit().remove();
  };
}

function label(arc, dateFormat) {
  return function (context) {
    var hasInheritedTransition = context instanceof transition;
    var selection = hasInheritedTransition ? context.selection() : context;

    const label = selection
      .selectAll(".js-savings-progress-month-label")
      .data((d) => d);

    label
      .attr("transform", function (d) {
        return "translate(" + arc.centroid(d) + ")";
      })
      .text(function (d) {
        return moment(d.data).format(dateFormat).toUpperCase();
      });

    label
      .enter()
      .append("text")
      .classed(
        "savings-progress-chart__month-label js-savings-progress-month-label",
        true
      )
      .attr("transform", function (d) {
        return "translate(" + arc.centroid(d) + ")";
      })
      .attr("dy", ".35em")
      .attr("text-anchor", "middle")
      // .attr("aria-hidden", "true")
      .text(function (d) {
        return moment(d.data).format(dateFormat).toUpperCase();
      });

    label.exit().remove();
  };
}

export default class SavingsProgressChart extends React.Component {
  constructor(props) {
    super(props);
    this.handleChartRendered = this.handleChartRendered.bind(this);
  }

  handleChartRendered(width, height, radius) {
    this.drawOuterArc(width, height, radius);
    this.drawTodayAccent(radius, width, height);
  }

  drawOuterArc(width, height, radius) {
    const container = select(`.${MAIN_ARC_CLASS}`);
    const outerArc = arc();

    select(".js-savings-planner-outer-arc").remove();
    container
      .append("path")
      .attr(
        "d",
        outerArc({
          startAngle: -PI / 2,
          endAngle: PI / 2,
          innerRadius: radius + OUTER_ARC_MARGIN - OUTER_ARC_WIDTH,
          outerRadius: radius + OUTER_ARC_MARGIN,
        })
      )
      .classed(
        "js-savings-planner-outer-arc savings-progress-chart__outer-arc",
        true
      );
  }

  getMaxDateMargin(arcRadius) {
    return parseInt(MAX_MONTH_MARGIN_FACTOR * arcRadius, 10);
  }

  getMinDateMargin(arcRadius) {
    return parseInt(MIN_MONTH_MARGIN_FACTOR * arcRadius, 10);
  }

  drawTodayAccent(arcRadius) {
    const today = moment(),
      pie = pieD3()
        .sort(null)
        .startAngle(-PI / 2)
        .endAngle(PI / 2),
      dataPoints = timeDays(
        today.clone().startOf("year"),
        today.clone().endOf("year"),
        1
      ),
      datum = pie(dataPoints)[today.dayOfYear() - 1],
      group = select(`.${MAIN_ARC_CLASS}`)
        .selectAll(".js-savings-progress-chart-current-month")
        .data([[datum]]);

    // Keep the text an appropriate amount away from the dot and border
    const distanceFromMidYear = Math.abs(today.diff(MID_YEAR, "days"));
    const minMonthMargin = this.getMinDateMargin(arcRadius);
    const maxMonthMargin = this.getMaxDateMargin(arcRadius);
    const monthMargin =
      minMonthMargin +
      Math.floor(
        (distanceFromMidYear / HALF_YEAR) * (maxMonthMargin - minMonthMargin)
      );

    const pointShape = point(
      arc()
        .innerRadius(arcRadius + POINT_MARGIN)
        .outerRadius(arcRadius + POINT_MARGIN)
    );
    const labelShape = label(
      arc()
        .innerRadius(arcRadius + monthMargin)
        .outerRadius(arcRadius + monthMargin),
      this.props.dateFormat
    );

    group.call(pointShape).raise();
    group.call(labelShape);

    const groupNew = group
      .enter()
      .append("g")
      .attr("aria-hidden", "true")
      .classed("js-savings-progress-chart-current-month", true);

    groupNew.call(pointShape).raise();
    groupNew.call(labelShape);

    group.exit().remove();
  }

  render() {
    const {
      arcWidth,
      currentSavings,
      savingsComponents,
      targetSavings,
      paddingTop,
      paddingBottom,
      buildUnderArc,
      isRetired,
      inactiveClassName,
      activeArcKey,
      arcChartAriaLabel,
      arcChartDescribedBy,
    } = this.props;

    const underArc = buildUnderArc(
      currentSavings,
      targetSavings,
      isRetired,
      this.props.onEditSavingsPlannerClick
    );

    return (
      <div>
        <ArcChart
          currentValues={savingsComponents}
          targetValue={targetSavings}
          onChartRender={this.handleChartRendered}
          arcClassName={MAIN_ARC_CLASS}
          pathClassName="js-savings-progress-arc"
          className={`js-savings-progress-chart savings-progress-chart__container ${
            isRetired
              ? "savings-progress-chart__filled--retired"
              : "savings-progress-chart__filled"
          }`}
          arcWidth={arcWidth}
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
          underArcDisplay={underArc}
          rescaleForNegatives={true}
          activeArcKey={activeArcKey}
          inactiveClassName={inactiveClassName}
          arcChartAriaLabel={arcChartAriaLabel}
          arcChartDescribedBy={arcChartDescribedBy}
        />
      </div>
    );
  }
}

SavingsProgressChart.propTypes = {
  currentSavings: PropTypes.number.isRequired,
  targetSavings: PropTypes.number.isRequired,
  savingsComponents: PropTypes.array,
  arcWidth: PropTypes.number,
  onEditSavingsPlannerClick: PropTypes.func,
  dateFormat: PropTypes.string, // Uses Moment format
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  buildUnderArc: PropTypes.func,
  isRetired: PropTypes.bool,
  activeArcKey: PropTypes.string,
  inactiveClassName: PropTypes.string,
  arcChartAriaLabel: PropTypes.string,
  arcChartDescribedBy: PropTypes.string,
};

SavingsProgressChart.defaultProps = {
  dateFormat: "MMM D",
  savingsComponents: [],
  arcWidth: SPACING_UNIT + SPACING_UNIT_SMALL,
  onEditSavingsPlannerClick: undefined,
  paddingTop: 46,
  paddingBottom: SPACING_UNIT,
  buildUnderArc: defaultBuildUnderArc,
  isRetired: false,
  activeArcKey: "",
  inactiveClassName: "",
  arcChartAriaLabel: null,
  arcChartDescribedBy: null,
};
