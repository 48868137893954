/* spellchecker: disable */
/* eslint-disable no-useless-escape */
export const US_ZIP_CODE = /^\d{5}(-\d{4})?$/;
export const US_ZIP_OPT_HYPHEN = /^\d{5}(-?\d{4})?$/;
export const US_ZIP_CODE_DIGITS_ONLY = /^\d{5}(\d{4})?$/;
export const CANADA_POSTAL_CODE =
  /^[a-zA-Z]\d{1}[a-zA-Z][ -]?\d{1}[a-zA-Z]\d{1}$/;
export const INTERNATIONAL_POSTAL_CODE = /[a-zA-Z0-9]{1,}/;
export const US_PHONE = /^\d{10}$/;
export const PO_BOX = /\bp(ost)?[.\s-]*o(ffice)?[.\s-]+box\b/i;
export const DATE_MM_DD_YYYY = /^\d{2}\/\d{2}\/\d{4}$/;
export const SSN = /^\d{3}-\d{2}-\d{4}$/;
export const MASKED_SSN = /^\*{3}-\*{2}-\d{4}$/;
export const EMAIL =
  /^[A-Za-z0-9._%+-]+@(([0-9A-Za-z][-\w]*[0-9a-z]*\.)+[A-Za-z]{2,5})$/;
export const ID_NUMBER = /^[\w*]+$/;
export const PERSON_OCCUPATION = /^[a-zA-Z\s,_-]*$/;
export const FIRST_NAME = /^[a-zA-Z][a-zA-Z\s'-]*$/;
export const INTERNATIONAL_ADDRESS = /^[a-zA-Z0-9]{1,}([a-zA-Z0-9\s])*$/;
export const INTERNATIONAL_CITY = /^[a-zA-Z]{1,}([a-zA-Z\s-])*$/;
export const US_CITY = /^[^\d]*$/;
export const ACCOUNT_NUMBER = /^[a-zA-Z0-9-]+$/;
export const EMPOWER_ACCOUNT_NUMBER = /^[a-zA-Z0-9_-]+$/;
export const PHONE = /^\d{3,14}$/;
export const SPECIAL_CHARACTERS =
  /[!@$%^()_+=|\\{}\[\]:~`<>,?"*!@$%^()_+=|\\{}\[\]:~`<>,?"*!@$%^()_+=|\\{}\[\]:~`<>,?"*]/;
export const SPECIAL_CHARACTERS_SRC =
  /^[^!@$%^()_+=|\\{}\[\]:~`<>,?"*!@$%^()_+=|\\{}\[\]:~`<>,?"*!@$%^()_+=|\\{}\[\]:~`<>,?"*]*$/;
