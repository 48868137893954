import React from "react";
import PropTypes from "prop-types";
import RecurringTransfersTableContainer from "components/transferFunds/RecurringTransfersTable/Container";
import EditRecurringTransfersContainer from "../TransferFunds/EditRecurringTransfersContainer/EditRecurringTransfersContainer";
import { EditRecurringTransfersContext } from "components/transferFunds/EditRecurringTransfers/EditRecurringTransfersContext";

function handleSubmit() {
  window.dashboardUtils?.eventBus.dispatch(
    "recurring_requests.submit_new_request_button.click"
  );
}

const RecurringTransfers = ({ showNewRequestButton, showTitle }) => {
  return (
    <EditRecurringTransfersContext.Provider value={true}>
      {showTitle && (
        <h1 className="nav-secondary__title pc-u-mb">Recurring Requests</h1>
      )}
      <RecurringTransfersTableContainer
        CustomEditTransfer={EditRecurringTransfersContainer}
      />
      {showNewRequestButton && (
        <a
          href={TRANSFER_FUNDS_URL}
          className="pc-btn pc-u-mt js-recurring-transfers-submit-button"
          onClick={handleSubmit}
        >
          Submit a new request
        </a>
      )}
    </EditRecurringTransfersContext.Provider>
  );
};

RecurringTransfers.propTypes = {
  showNewRequestButton: PropTypes.bool,
  showTitle: PropTypes.bool,
};

RecurringTransfers.defaultProps = {
  showNewRequestButton: true,
  showTitle: true,
};
export default RecurringTransfers;
