import moment from "moment";
import _ from "underscore";
import Formats from "formats";
import Box from "libs/pcap/utils/Box2";
import graphExtent from "libs/pcap/utils/graphExtent";
import yAxisGridLines from "libs/pcap/visualizations/yAxisGridLines";
var GRAPH_MIN_VALUE = 20;

function maxValue(dataset) {
  var maxWhatIf = _.reduce(
    _.pluck(dataset, "whatIfValue"),
    function (out, value) {
      if (isNaN(value)) {
        return out;
      }
      return Math.max(out, value);
    },
    GRAPH_MIN_VALUE
  );
  return _.reduce(
    dataset,
    function (max, bars) {
      return Math.max(
        max,
        _.reduce(
          bars.values,
          function (out2, valueItem) {
            if (valueItem.value < 0) return out2;
            return valueItem.value + out2;
          },
          0
        )
      );
    },
    maxWhatIf
  );
}

function minValue(dataset) {
  var minWhatIf = _.reduce(
    _.pluck(dataset, "whatIfValue"),
    function (out, value) {
      if (isNaN(value)) {
        return out;
      }
      return Math.min(out, value);
    },
    GRAPH_MIN_VALUE
  );

  return _.reduce(
    dataset,
    function (min, bars) {
      return Math.min(
        min,
        _.reduce(
          bars.values,
          function (out2, valueItem) {
            if (valueItem.value > 0) return out2;
            return valueItem.value + out2;
          },
          0
        )
      );
    },
    minWhatIf
  );
}

var todayBox;
const MONTHS_IN_YEAR = 12;
const DAYS_IN_WEEK = 7;

const LEGEND_MARGIN_LEFT = 0;
const LEGEND_FIRST_MARGIN_LEFT = 8;

const LEGEND_MARGIN_RIGHT = 0;
const LEGEND_LAST_MARGIN_RIGHT = 8;

function makeBars(dataset, config) {
  var largestValue = maxValue(dataset);
  var smallestValue = minValue(dataset);

  var extent = graphExtent(smallestValue, largestValue);
  var range = extent.zeroBasedExtents.max - extent.zeroBasedExtents.min;
  var ceiling = (100 * extent.zeroBasedExtents.max) / range;
  var floor = (-1 * 100 * extent.zeroBasedExtents.min) / range;

  var barRegions = new Box({
    height: "97%",
    width: config.showYLabelsOutside ? "94%" : "100%",
    widthPos: "left",
    heightPos: "top",
    name: "barRegions",
    drawMode: "none",
    drawAttrs: {},
  });

  var year = 0;

  var firstCheck = false;

  _.each(dataset, function (data, i) {
    data.drawLabel = Number(year) !== Number(data.date.year());
    year = data.date.year();

    if (i && !firstCheck && data.drawLabel) {
      firstCheck = true;
      if (i < MONTHS_IN_YEAR) {
        dataset[0].drawLabel = false;
      }
    }
  });

  /**
   * render the visible bar rectangles
   */
  barRegions.hGrid({
    count: dataset.length,
    name: "bars",
    height: "90%",
    draw: function (paper) {
      var hIndex = this.hgridIndex;
      var hItem = dataset[hIndex];
      var lastHitem = dataset[hIndex - 1];
      var datasetBoxes = hItem.values;
      var margin = 0;
      var seriesNum = 0;

      var date = moment();
      var dataDate = moment(hItem.date, Formats.DATE_FORMAT);

      /**
       * Create a series that is ordered by descending absolute value
       * and attach an index to it indicating sequence
       * @type {Array}
       */

      function asSeries(dataItem) {
        return _.extend(
          {
            seriesIndex: seriesNum++,
            series: hItem,
            order: dataItem.grant.order,
          },
          dataItem
        );
      }

      var series = _.filter(
        _.sortBy(_.map(datasetBoxes, asSeries), "order"),
        function (i) {
          return i.value;
        }
      ); // remove zero items; sort by order

      //console.log('series: ', series);

      var boBottomAttrs = {
        name: "barset_" + hIndex + "_outer_bottom",
        width: "100%",
        height: floor + "%",
        heightPos: "bottom",
        drawMode: "none",
      };

      var bottomBox = new Box(boBottomAttrs);
      this.add(bottomBox);

      var boTopAttrs = {
        name: "barset_" + hIndex + "_outer_top",
        width: "100%",
        height: ceiling + "%",
        heightPos: "top",
        drawMode: "none",
      };

      var topBox = new Box(boTopAttrs);
      this.add(topBox);

      /**
       * we iterate over each value
       * creating a box that has a margin relative to the previously drawn boxes
       * in its side of the x axis.
       *
       */
      var prevPos = 0;
      var prevNeg = 0;

      /**
       * adding the height of the previous boxes to the "stack" that offsets
       * each box. Done in reverse so that when drawn, the first boxes in the series
       * are highest (if positive) or lowest (if negative).
       */
      _.each(series, function (item) {
        if (item.value > 0) {
          item.prevValue = prevPos;
          prevPos += item.value;
        } else {
          item.prevValue = prevNeg;
          prevNeg += Math.abs(item.value);
        }
      });

      /**
       * draw bars
       */
      _.each(
        series,
        function (dataItem) {
          if (Number(dataItem.value) === 0) {
            return;
          }
          var name =
            "graphbox" + dataItem.series.month + "_" + dataItem.seriesIndex;
          var bar, bAttrs, height;

          bAttrs = {
            name: name,
            width: "100%",
            drawMode: "rect",
            $role: "BAR",
            drawAttrs: {
              fill: dataItem.color,
            },
          };

          height = 100.0 * Math.abs(dataItem.value);
          margin = 100.0 * Math.abs(dataItem.prevValue);
          /**
           * the top and bottom bars are placed within two different containers
           * and are scaled based on the containers height relative to the
           * max or min extend above/below zero.
           *
           * (the assumption here is that the min is <= 0;
           */
          if (dataItem.value < 0) {
            height = Math.abs(height / extent.zeroBasedExtents.min);
            margin = Math.abs(margin / extent.zeroBasedExtents.min);
            _.extend(bAttrs, {
              heightPos: "top",
              marginTop: margin + "%",
              height: height + "%",
            });

            bar = new Box(bAttrs);
            bottomBox.add(bar);
          } else {
            height /= Math.abs(extent.zeroBasedExtents.max);
            margin /= Math.abs(extent.zeroBasedExtents.max);

            _.extend(bAttrs, {
              heightPos: "bottom",
              marginBottom: margin + "%",
              height: height + "%",
            });

            bar = new Box(bAttrs);
            topBox.add(bar);
          }
        },
        this
      );

      //	console.log(' ... series: ', series, 'margin', margin, 'height: ', height);

      /**
       * draw what if price
       */

      // eslint-disable-next-line no-prototype-builtins
      if (hItem.hasOwnProperty("whatIfValue")) {
        var name = "whatIfPrice_" + hIndex;
        var bar, bAttrs, height;

        bAttrs = {
          name: name,
          width: "100%",
          drawMode: "line",
          $role: "WHATIF",
          drawAttrs: {
            fill: "#F25100",
            $lineType: "T",
            "stroke-width": 1, //,
            // 'stroke-dasharray' : '-'
          },
        };

        height = 100.0 * hItem.whatIfValue;

        var lastHeight = lastHitem ? 100 * lastHitem.whatIfValue : 0;

        /**
         * the top and bottom bars are placed within two different containers
         * and are scaled based on the containers height relative to the
         * max or min extend above/below zero.
         *
         * (the assumption here is that the min is <= 0;
         */
        if (hItem.whatIfValue < 0) {
          if (lastHeight < 0) {
            margin = Math.abs(lastHeight / extent.zeroBasedExtents.min);
            height = Math.abs(
              (height - lastHeight) / extent.zeroBasedExtents.min
            );
          } else {
            height = Math.abs(height / extent.zeroBasedExtents.min);
            margin = 0;
          }

          _.extend(bAttrs, {
            heightPos: "top",
            marginTop: margin + "%",
            height: height + "%",
          });

          bAttrs.drawAttrs.$lineType = "B";
          bar = new Box(bAttrs);
          bottomBox.add(bar);

          let lbattrs = JSON.parse(JSON.stringify(bAttrs));
          lbattrs.drawAttrs.$lineType = "L";
          lbattrs.name += "L";
          bar = new Box(lbattrs);
          bottomBox.add(bar);
        } else {
          if (lastHeight > 0) {
            margin = Math.abs(lastHeight / extent.zeroBasedExtents.max);
            height = Math.abs(
              (height - lastHeight) / extent.zeroBasedExtents.max
            );
          } else {
            height /= Math.abs(extent.zeroBasedExtents.max);
            margin = 0;
          }

          bAttrs.drawAttrs.$lineType = "T";
          _.extend(bAttrs, {
            heightPos: "bottom",
            marginBottom: margin + "%",
            height: height + "%",
          });

          bar = new Box(bAttrs);
          topBox.add(bar);

          let lbattrs = JSON.parse(JSON.stringify(bAttrs));
          lbattrs.drawAttrs.$lineType = "L";

          lbattrs.name += "L";
          bar = new Box(lbattrs);
          topBox.add(bar);
        }
      }

      topBox.draw(paper);
      bottomBox.draw(paper);

      var dateProgress = 0;

      if (
        Number(date.year()) === Number(dataDate.year()) &&
        Number(date.date()) === Number(dataDate.date()) &&
        Number(date.month()) === Number(dataDate.month())
      ) {
        todayBox = new Box({
          height: "100%",
          width: dateProgress + "%",
          drawMode: "line",
          $lineType: "R",
          drawAttrs: {
            fill: "rgb(0,0,0,0.5)",
          },
        });

        this.add(todayBox);
      }
    },
  });

  /**
   * add the graph line overlay
   */
  barRegions.add(
    yAxisGridLines(smallestValue, largestValue, {
      color: "#5b7381",
      labelFormatter: function (value) {
        return Formats.filters.dollar_no_cents(value);
      },
      showYLabelsOutside: config.showYLabelsOutside,
    })
  );

  /**
   * render the bar legends
   */
  barRegions.hGrid({
    count: dataset.length,
    name: "legends",
    height: "10%",
    heightPos: "bottom",
    draw: function (paper) {
      var hIndex = this.hgridIndex;
      var hItem = dataset[hIndex];
      var isLast = hIndex === dataset.length - 1;
      var isFirst = hIndex === 0;
      var label;

      if (hItem.drawLabel) {
        label = moment(hItem.month, Formats.DATE_FORMAT).format("YYYY");
      } else {
        return;
      }

      var box = new Box({
        marginTop: 0,
        name: "legend" + hIndex + "frame",
        width: "100%",
        height: "100%",
        marginLeft: isFirst ? LEGEND_FIRST_MARGIN_LEFT : LEGEND_MARGIN_LEFT,
        marginRight: isLast ? LEGEND_LAST_MARGIN_RIGHT : LEGEND_MARGIN_RIGHT,
        widthPos: isFirst ? "left" : "right",
        drawMode: "rect",
        drawAttrs: {
          fill: "white",
        },
      });
      var subbox = new Box({
        name: "legend" + hIndex,
        width: "100%",
        height: "100%",
        drawMode: "text",
        drawAttrs: {
          text: label,
          $textAnchor: isLast ? "TR" : "TL",
          "font-size": "12",
          fill: "#5b7381",
        },
      });
      box.add(subbox);
      this.add(box);
      box.draw(paper);
    },
  });

  todayBox = false;
  /**
   * render today line
   */
  barRegions.hGrid({
    count: dataset.length,
    name: "bars",
    height: "100%",
    marginTop: 20,
    heightPos: "top",
    draw: function (paper) {
      var hIndex = this.hgridIndex;
      var hItem = dataset[hIndex];

      var date = moment();
      var dataDate = moment(hItem.month, Formats.DATE_FORMAT);
      var dateProgress = 1; //@TODO: calculate extent in month

      var alignRight = hIndex > dataset.length / 2;
      //	console.log('comparing ', date.format('YYYY-MM-DD'), 'and ', dataDate.format('YYYY-MM-DD'), Math.abs(date.diff(dataDate, 'days')));

      if (!todayBox && Math.abs(date.diff(dataDate, "days")) < DAYS_IN_WEEK) {
        todayBox = new Box({
          name: "todayBar",
          height: "100%",
          width: dateProgress + "%",
          drawMode: "line",
          drawAttrs: {
            $lineType: "R",
            fill: "rgba(0,0,0,0.8)",
          },
        });

        this.add(todayBox);
        todayBox.draw(paper);

        var boxAttrs = {
          name: "todayLabelFrame",
          height: 20,
          width: 60,
          widthPos: alignRight ? "right" : "left",
          heightPos: "top",
          paddingLeft: 14,
          paddingBottom: 1,
          drawMode: "box",
          drawAttrs: {
            fill: "rgba(0,0,0,0.8)",
          },
        };

        if (alignRight) {
          boxAttrs.widthPos = "right";
        }

        var todayLabelFrame = new Box(boxAttrs);

        todayBox.add(todayLabelFrame);

        var inner = new Box({
          name: "tlinner",
          width: "100%",
          height: "100%",
          drawMode: "rect",
          widthPos: "left",
        });

        var todayLabel = new Box({
          name: "todayLabel",
          width: "100%",
          height: "100%",
          drawMode: "text",
          $textAnchor: "BL",
          drawAttrs: {
            text: " Today",
            fill: "white",
            "font-size": 10,
            $textAnchor: "BL",
          },
        });

        inner.add(todayLabel);

        todayLabelFrame.add(inner);
        todayLabelFrame.draw(paper);
      }
    },
  });

  return barRegions;
}

const GRAPH_DEFAULT_WIDTH = "100%";
const GRAPH_DEFAULT_HEIGHT = "100%";
const GRAPH_DEFAULT_PADDING = 0;
const GRAPH_DEFAULT_MARGIN_LEFT = 0;
const GRAPH_DEFAULT_MARGIN_TOP = 15;

function GrantBarGraph(data, config) {
  return new Box({
    width: config.width || GRAPH_DEFAULT_WIDTH,
    height: config.height || GRAPH_DEFAULT_HEIGHT,
    padding: config.padding || GRAPH_DEFAULT_PADDING,
    name: "grantChartBox",
    marginLeft: config.marginLeft || GRAPH_DEFAULT_MARGIN_LEFT,
    marginTop: config.marginTop || GRAPH_DEFAULT_MARGIN_TOP,
    drawMode: "none",
  }).add(makeBars(data, config));
}

export default GrantBarGraph;
