import PropTypes from "prop-types";
import React from "react";
import ProjectionSummaryContainer from "components/projectionSummary/ProjectionSummaryContainer";

const Projection = (props) => {
  const { ProjectionSummary } = props;
  return (
    <div className={`insight__message${IS_EMPOWER ? " pc-u-mt-" : ""}`}>
      <div
        className="insight__header qa-insight-header js-insight-header"
        dangerouslySetInnerHTML={{ __html: props.message.title }}
      />
      <div>
        {ProjectionSummary ? (
          ProjectionSummary
        ) : (
          <ProjectionSummaryContainer title={null} />
        )}
      </div>
    </div>
  );
};

Projection.defaultProps = {
  ProjectionSummary: undefined,
};

Projection.propTypes = {
  message: PropTypes.object.isRequired,
  ProjectionSummary: PropTypes.node,
};

export default Projection;
