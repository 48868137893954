import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import AccountTypeCombo from "components/common/EditAccount/AccountTypeCombo";
import { hasPrintableCharacters } from "common/validators/functions.js";
import { POSITIVE_CURRENCY_FORMAT } from "components/common/form/formattingOptions";

const MAX_CREDIT_LIMIT_DIGITS = 13;
const MAX_BALANCE_DIGITS = 19;
const MAX_APR_DIGITS = 6;
const MAX_ACCOUNT_NUMBER_LENGTH = 50;

class CreditAccountDetails extends React.Component {
  render() {
    const {
      model,
      onInputChange,
      schema,
      storeInputRef,
      accountGroups,
      accountTypesMap,
      accountSubTypesMap,
      onAccountTypeChange,
    } = this.props;
    const { userAccountId, isManual } = model;
    const { properties } = schema;
    const disableFields = Boolean(model.closedDate);

    return (
      <div>
        <div className="pc-layout">
          {!isManual && (
            <div className="pc-form-group--small">
              <div className="pc-layout__item pc-u-1/3">
                <label className="pc-label--small">Firm Name</label>
              </div>
              <div className="pc-layout__item pc-u-2/3">
                <Input
                  className="input--tiny qa-firm-name"
                  type="text"
                  name="firmName"
                  sizeVariation="full"
                  onChange={onInputChange}
                  value={model.firmName}
                  ref={storeInputRef}
                  validator={properties.firmName}
                  disabled={!userAccountId || disableFields}
                  maxLength={200}
                  ariaLabel="Firm name"
                />
              </div>
            </div>
          )}
          <div className="pc-form-group--small">
            <div className="pc-layout__item pc-u-1/3">
              <label className="pc-label--small">
                {isManual ? "Description" : "Account Name"}
              </label>
            </div>
            <div className="pc-layout__item pc-u-2/3">
              <Input
                className="input--tiny qa-account-name"
                type="text"
                name="name"
                sizeVariation="full"
                onChange={onInputChange}
                value={model.name}
                ref={storeInputRef}
                validator={properties.name}
                disabled={!userAccountId || disableFields}
                maxLength={200}
                ariaLabel={isManual ? "Description" : "Account name"}
              />
            </div>
          </div>
          {isManual ? (
            <>
              <div className="pc-form-group--small">
                <div className="pc-layout__item pc-u-1/3">
                  <label className="pc-label--small">Account Number</label>
                </div>
                <div className="pc-layout__item pc-u-2/3">
                  <Input
                    className="input--tiny qa-account-number"
                    type="text"
                    name="accountNumber"
                    sizeVariation="full"
                    onChange={onInputChange}
                    value={model.accountNumber}
                    maxLength={MAX_ACCOUNT_NUMBER_LENGTH}
                    ref={this.props.storeInputRef}
                    validator={this.props.schema.properties.accountNumber}
                    disabled={disableFields}
                    ariaLabel="Account number"
                  />
                </div>
              </div>
              <div className="pc-form-group--small">
                <div className="pc-layout__item pc-u-1/3">
                  <label className="pc-label--small">Current Balance</label>
                </div>
                <div className="pc-layout__item pc-u-2/3">
                  <Input
                    className="input--tiny qa-current-balance"
                    type="text"
                    name="currentBalance"
                    sizeVariation="full"
                    onChange={onInputChange}
                    value={model.currentBalance}
                    ref={storeInputRef}
                    validator={properties.currentBalance}
                    maxLength={MAX_BALANCE_DIGITS}
                    formattingOptions={POSITIVE_CURRENCY_FORMAT}
                    prefix="$"
                    disabled={disableFields}
                    ariaLabel="Current balance"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="pc-layout__item pc-u-1/3">
                <label className="pc-label--small">Account Type</label>
              </div>
              <div className="pc-layout__item pc-u-2/3">
                <AccountTypeCombo
                  name="accountTypeCombo"
                  className="Select--small qa-account-type"
                  ref={storeInputRef}
                  onChange={onAccountTypeChange}
                  value={{
                    group: model.accountTypeGroup,
                    type: model.accountTypeNew,
                    subType: model.accountTypeSubtype,
                  }}
                  validator={properties.accountTypeCombo}
                  disabled={!userAccountId || disableFields}
                  optionsGroup={accountGroups}
                  typesMap={accountTypesMap}
                  subTypesMap={accountSubTypesMap}
                  ariaLabels={{
                    group: "Account Type",
                    type: `Type of ${model.accountTypeGroup} account`,
                    subType: `Type of ${model.accountTypeNew} account`,
                  }}
                />
              </div>
              <div className="pc-form-group--small">
                <div className="pc-layout__item pc-u-1/3">
                  <label className="pc-label--small">Credit Limit</label>
                </div>
                <div className="pc-layout__item pc-u-2/3">
                  <Input
                    className="input--tiny qa-credit-limit"
                    type="text"
                    name="creditLimit"
                    sizeVariation="full"
                    onChange={onInputChange}
                    value={model.creditLimit}
                    ref={storeInputRef}
                    validator={properties.creditLimit}
                    maxLength={MAX_CREDIT_LIMIT_DIGITS}
                    formattingOptions={POSITIVE_CURRENCY_FORMAT}
                    prefix="$"
                    disabled={disableFields}
                    ariaLabel="Credit limit"
                  />
                </div>
              </div>
              <div className="pc-form-group--small">
                <div className="pc-layout__item pc-u-1/3">
                  <label className="pc-label--small">APR</label>
                </div>
                <div className="pc-layout__item pc-u-2/3">
                  <Input
                    className="input--tiny qa-apr"
                    type="text"
                    name="apr"
                    sizeVariation="full"
                    onChange={onInputChange}
                    value={model.apr}
                    ref={storeInputRef}
                    validator={properties.apr}
                    formattingOptions={POSITIVE_CURRENCY_FORMAT}
                    maxLength={MAX_APR_DIGITS}
                    suffix="%"
                    disabled={disableFields}
                    ariaLabel="APR"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

CreditAccountDetails.propTypes = {
  model: PropTypes.object.isRequired,
  accountTypesMap: PropTypes.object.isRequired,
  schema: PropTypes.object,
  onInputChange: PropTypes.func.isRequired,
  onAccountTypeChange: PropTypes.func.isRequired,
  accountGroups: PropTypes.array.isRequired,
  accountSubTypesMap: PropTypes.object.isRequired,
  storeInputRef: PropTypes.func.isRequired,
};

CreditAccountDetails.defaultProps = {
  schema: {
    type: "object",
    properties: {
      firmName: {
        allowEmpty: false,
        required: true,
        type: "string",
        conform: hasPrintableCharacters,
        messages: {
          conform: "must not be empty",
        },
      },
      name: {
        allowEmpty: false,
        required: true,
        type: "string",
        conform: hasPrintableCharacters,
        messages: {
          conform: "must not be empty",
        },
      },
      creditLimit: {
        type: "number",
      },
      currentBalance: {
        required: true,
        type: "number",
      },
      accountTypeCombo: {
        type: "object",
        properties: {
          group: {
            allowEmpty: false,
          },
          type: {
            allowEmpty: false,
          },
          subType: {
            allowEmpty: false,
          },
        },
      },
      apr: {
        type: "number",
        exclusiveMinimum: 0,
        maximum: 100,
        messages: {
          type: "numbers only",
          exclusiveMinimum: "positive numbers only",
          maximum: "100 maximum",
        },
      },
    },
  },
};

export default CreditAccountDetails;
