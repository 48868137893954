import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppointmentInformation from "../AppointmentInformation/AppointmentInformation";
import GoalsQuestionnaire from "../GoalsQuestionnaire/GoalsQuestionnaire";
import { promisify } from "utils/service";
import Services from "services";
import { noop } from "jquery";
import Mixpanel from "mixpanel";
import Loading from "components/common/loading/Loading";

const COMPONENT = "Goals For Dashboard";
export const GOALS = [
  {
    label: "Understand and track my net worth",
    icon: "/static/img/firstUse/icons/icon_pc.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_pc2.svg",
    key: "TRACK_NET_WORTH",
  },
  {
    label: "Set savings goals and track my budget",
    icon: "/static/img/firstUse/icons/icon_pie-chart.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_pie-chart2.svg",
    key: "TRACK_BUDGET",
  },
  {
    label: "Monitor and track my investments",
    icon: "/static/img/firstUse/icons/icon_bar-chart.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_bar-chart2.svg",
    key: "TRACK_INVESTMENTS",
  },
  {
    label: "Optimize my investment strategy and reduce fees",
    icon: "/static/img/firstUse/icons/icon_magnifier.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_magnifier2.svg",
    key: "OPTIMIZE_STRATEGY",
  },
  {
    label: "Feel more confident about my retirement",
    icon: "/static/img/firstUse/icons/icon_self-serve.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_self-serve2.svg",
    key: "RETIREMENT_CONFIDENCE",
  },
  {
    label: "Plan for life financial events (home purchase, education, etc.)",
    icon: "/static/img/firstUse/icons/icon_binder.svg",
    hoverIcon: "/static/img/firstUse/icons/icon_binder2.svg",
    key: "PLAN_FINANCIAL_EVENTS",
  },
];

const textAreaData = {
  label: "Other goals(optional)?",
  placeholder: "Please let us know any additional goals.",
  rows: 1,
};

export const findKey = (items, key) => {
  return items.find((item) => item.key === key);
};
export const prepareLinkAccountMessage = (model, messages) => {
  const selectedGoals = model.options;
  if (selectedGoals.length === 0 && model.otherText) {
    return messages.other;
  }
  if (
    selectedGoals.length === 1 &&
    selectedGoals[0].key === "TRACK_NET_WORTH"
  ) {
    return messages.networth;
  }
  if (selectedGoals.length === 1 && selectedGoals[0].key === "TRACK_BUDGET") {
    return messages.spending;
  }
  if (
    findKey(selectedGoals, "RETIREMENT_CONFIDENCE") ||
    findKey(selectedGoals, "PLAN_FINANCIAL_EVENTS")
  ) {
    return messages.retirement;
  }
  if (
    findKey(selectedGoals, "TRACK_INVESTMENTS") ||
    findKey(selectedGoals, "OPTIMIZE_STRATEGY")
  ) {
    return messages.investment;
  }
  if (
    findKey(selectedGoals, "TRACK_NET_WORTH") &&
    findKey(selectedGoals, "TRACK_BUDGET") &&
    selectedGoals.length === 2
  ) {
    return messages.money;
  }
  return messages.default;
};

const UserGoals = ({
  updatePerson,
  onSubmit,
  addUserResponse,
  linkAccountMessages,
  appointmentData,
  hasSkipButton,
  getPerson,
  getByQuestionGroupName,
}) => {
  const [questionId, setQuestionId] = useState(null);
  const [person, setPerson] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      getPerson(),
      getByQuestionGroupName({ questionGroupName: "ONBOARDING_IG_QG" }),
    ]).then(([person, response]) => {
      const questionId =
        response.questionGroupResponses.questionGroupDto?.questionDtoList?.find(
          (list) => list.questionKey === "USER_GOALS"
        )?.questionMapUuid;
      setQuestionId(questionId);
      setPerson(person);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    Mixpanel.trackEvent("View Goals For Dashboard", {
      component: COMPONENT,
    });
  }, []);
  const handleSubmit = (model, isSkipped = false) => {
    const goalKeys = [];
    const notesForAdvisor = {
      igFormChangeMoneyManagementWay: [],
    };
    if (model.options.length > 0) {
      for (const item of model.options) {
        notesForAdvisor.igFormChangeMoneyManagementWay.push(item.label);
        goalKeys.push(item.key);
      }
    }
    if (model.otherText) {
      notesForAdvisor.notesForAdvisorFromUser = model.otherText;
      goalKeys.push("OTHER_REASON");
    }
    if (
      (person && notesForAdvisor.igFormChangeMoneyManagementWay.length) ||
      notesForAdvisor.notesForAdvisorFromUser
    ) {
      const advisorNotes = Object.assign(
        person.notesForAdvisor,
        notesForAdvisor
      );
      updatePerson({
        personId: person.id,
        source: "In-App Short IG 2 Form - Page 1",
        person: JSON.stringify({ notesForAdvisor: advisorNotes }),
      });
    }
    if (isSkipped) {
      Mixpanel.trackEvent("Goals For Dashboard - Skip", {
        selectedGoals: goalKeys.join(", "),
        otherReason: notesForAdvisor.notesForAdvisorFromUser,
        component: COMPONENT,
      });
    } else {
      Mixpanel.trackEvent("Goals For Dashboard - Submit", {
        selectedGoals: goalKeys.join(", "),
        otherReason: notesForAdvisor.notesForAdvisorFromUser,
        component: COMPONENT,
      });
    }
    if (questionId && goalKeys.length > 0) {
      const goalKeysCaps = goalKeys.map((key) => key.toUpperCase());
      addUserResponse({
        userResponse: JSON.stringify({ [questionId]: goalKeysCaps }),
      });
    }
    onSubmit(prepareLinkAccountMessage(model, linkAccountMessages));
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      {appointmentData && (
        <AppointmentInformation
          firstName={person?.name?.firstName}
          advisorName={appointmentData.advisorName}
          appointmentData={appointmentData}
        />
      )}
      <GoalsQuestionnaire
        title="What would you like to get out of our free tools?"
        subTitle="Telling us about your goals helps us understand what’s important to you. Select all that apply."
        options={GOALS}
        textAreaData={textAreaData}
        onSubmit={handleSubmit}
        hasSkipButton={hasSkipButton}
      />
    </div>
  );
};
export default UserGoals;

UserGoals.propTypes = {
  appointmentData: PropTypes.object,
  updatePerson: PropTypes.func,
  onSubmit: PropTypes.func,
  linkAccountMessages: PropTypes.object,
  hasSkipButton: PropTypes.bool,
  addUserResponse: PropTypes.func,
  getPerson: PropTypes.func,
  getByQuestionGroupName: PropTypes.func,
};

UserGoals.defaultProps = {
  appointmentData: null,
  updatePerson: promisify(Services.Person.update),
  onSubmit: noop,
  linkAccountMessages: {},
  hasSkipButton: false,
  addUserResponse: promisify(Services.UserResponse.add),
  getPerson: promisify(Services.Person.get),
  getByQuestionGroupName: promisify(
    Services.UserResponse.getByQuestionGroupName
  ),
};
