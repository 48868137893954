import Backbone from "backbone";
import _ from "underscore";
import infoModalTemplate from "templates/modal-windows/infoModal.html";

export const DEFAULT_CLASS_NAMES =
  "modal mod-pcap modal__new vertically-center fade";

export default Backbone.View.extend({
  attributes: {
    class: DEFAULT_CLASS_NAMES,
    tabindex: -1,
    role: "dialog",
  },

  events: {
    "shown.bs.modal": "shownHandler",
    "hidden.bs.modal": "hiddenHandler",
    "click .js-create-dummy-account": "handleCreateDummyAccount",
    "click .js-dismiss": "handleDismiss",
  },

  initialize: function (options) {
    this.options = options;
    this.$el.html(this.template());
    if (options.wrap) {
      $('<div class="webapp3"></div>').append(this.$el).appendTo(document.body);
    } else {
      $(document.body).append(this.$el);
    }

    this.$el.modal();
  },

  template: function () {
    var options = this.options;
    var content = options.content;
    return infoModalTemplate({
      bodyClass: options.bodyClass,
      title: options.title,
      content: _.isFunction(content) ? content() : content,
    });
  },

  show: function () {
    this.$el.modal("show");
  },

  hide: function () {
    this.$el.modal("hide");
  },

  /**
   * Override `Backbone.View.prototype.remove` implementation to
   * hide the modal dialog before the view is destroyed.
   */
  remove: function () {
    var modalData = this.$el.data("bs.modal");
    if (modalData && modalData.isShown) {
      this.once(
        "hidden",
        function () {
          Backbone.View.prototype.remove.apply(this, arguments);
          this.removeParentDiv();
        }.bind(this)
      );
      this.hide();
    } else {
      Backbone.View.prototype.remove.apply(this, arguments);
      this.removeParentDiv();
    }
  },

  removeParentDiv: function () {
    var parentDiv = this.options.wrap ? this.$el.closest(".webapp3") : null;
    if (parentDiv) {
      parentDiv.remove();
    }
  },

  /* --- Event Handlers --- */

  shownHandler: function () {
    this.trigger("shown");
  },

  hiddenHandler: function () {
    this.trigger("hidden", this.model);
  },
});
