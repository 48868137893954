import PropTypes from "prop-types";
import React from "react";
import PostLoginActionsProvider from "./PostLoginActionsProvider";
import {
  getPostLoginActionChart,
  getJsonContent,
} from "views/components/postLoginActions/helpers";
import { noop } from "underscore";
import { prefetchPcapCashLiveData } from "views/components/postLoginActions/pcapCashPlaUtils";
import deepCopy from "deep-copy";
import getUrlParameterByName from "libs/pcap/utils/getUrlParameterByName";
import objectPath from "object-path";

const organizationNameCash = "Empower Personal Cash";

export function normalizeUserMessage(
  originalUserMessage,
  userAccount = getUrlParameterByName("ua")
) {
  const hasAccountMap = Boolean(
    (getJsonContent(originalUserMessage) || {}).accountMap
  );
  if (!hasAccountMap) return originalUserMessage;
  const userMessage = deepCopy(originalUserMessage);
  const jsonContent = getJsonContent(userMessage) || {};
  const accountMap = jsonContent.accountMap || {};
  const matchedAccountData = accountMap[userAccount];
  if (matchedAccountData) {
    if (matchedAccountData.title) {
      userMessage.title = matchedAccountData.title;
    }
    if (matchedAccountData.summary) {
      userMessage.summary = matchedAccountData.summary;
    }
    const bankName = objectPath.get(
      jsonContent,
      `accountMap.${userAccount}.bankName`
    );
    const matchedAccountJsonContent = getJsonContent(matchedAccountData) || {};
    matchedAccountJsonContent.disclaimer =
      matchedAccountJsonContent.disclaimer ||
      objectPath.get(jsonContent, `disclaimerMap.${bankName}`);
    if (!matchedAccountJsonContent.chartType && jsonContent.chartType) {
      matchedAccountJsonContent.chartType = jsonContent.chartType;
    }
    userMessage.resources = matchedAccountData.resources;
    delete jsonContent.accountMap;
  }
  return userMessage;
}

const PcapCashPLA = (props) => {
  const empowerCashLogoUrl =
    "https://www.personalcapital.com/images/ace/emp-rebrand/logo/empower-personal-cash.svg";
  prefetchPcapCashLiveData();
  return (
    <PostLoginActionsProvider {...props}>
      {({
        getButtons,
        onButtonClick,
        imageSource,
        getImageBlock,
        userMessage: originalUserMessage,
      }) => {
        const userMessage = normalizeUserMessage(originalUserMessage);
        return (
          <div>
            <div className="pcap-cash-pla__container">
              <img
                className="pcap-cash-pla__logo"
                alt=""
                src={empowerCashLogoUrl}
              />
              <div className="pcap-cash-pla__content">
                <h1
                  className="pcap-cash-pla__title"
                  dangerouslySetInnerHTML={{ __html: userMessage.title }}
                />
                <div
                  className="pc-u-mb"
                  dangerouslySetInnerHTML={{ __html: userMessage.summary }}
                />
                {getButtons({
                  getButtonClassName: () =>
                    "simple-image__cta pc-btn pc-btn--small pc-btn--primary",
                })}
              </div>
              <div className="pcap-cash-pla__right-content">
                {Boolean(imageSource) && getImageBlock(userMessage)}
                {!imageSource &&
                  getPostLoginActionChart(userMessage, onButtonClick)}
              </div>
            </div>
          </div>
        );
      }}
    </PostLoginActionsProvider>
  );
};

PcapCashPLA.propTypes = {
  componentName: PropTypes.string,
  userMessage: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func,
  onTitleChange: PropTypes.func,
};

PcapCashPLA.defaultProps = {
  componentName: organizationNameCash + " PLA",
  onRequestClose: noop,
  onTitleChange: noop,
};

export default PcapCashPLA;
