import deepCopy from "deep-copy";
import objectPath from "object-path";
import { SYSTEM_TAGS } from "components/common/InputTags/tagsToClient";
import { isEmpty } from "underscore";

const parseTransactions = (
  transactions,
  categories,
  tags,
  isCredit,
  accountName
) => {
  return transactions.map((transaction) => {
    const category = categories.find(
      (cat) => cat.transactionCategoryId === transaction.categoryId
    );

    const systemTags = objectPath.get(transaction, "customTags.systemTags", []);
    const userTags = objectPath.get(transaction, "customTags.userTags", []);

    transaction.categoryName = (category && category.name) || "";
    transaction.selectedTagIds = [...systemTags, ...userTags];

    transaction.selectedTagNames = transaction.selectedTagIds.map((tagId) => {
      const tag = tags.find((t) => t.tagId === tagId) || {};
      const tagName = tag.isCustom ? tag.tagName : SYSTEM_TAGS[tag.tagName];
      return tagName || "";
    });

    if (isCredit != null) {
      transaction.isCredit = isCredit;
    }

    if (accountName != null) {
      transaction.accountName = accountName;
    }

    if (!isEmpty(transaction.splits)) {
      parseTransactions(
        transaction.splits,
        categories,
        tags,
        transaction.isCredit,
        transaction.accountName
      );
    }

    if (transaction.imageUrl) {
      transaction.imageUrl = `${window.baseUrl}servlet/image/getImageByTransactionId?userTransactionId=${transaction.userTransactionId}&v=${transaction.imageUpdatedDate}`;
    }

    return transaction;
  });
};

export default function (transactions, categories, tags) {
  const transactionsClone = deepCopy(isEmpty(transactions) ? [] : transactions);
  return parseTransactions(transactionsClone, categories, tags);
}
