import React from "react";
import PropTypes from "prop-types";
import Loading from "components/common/loading/Loading";
import { formatCurrency } from "libs/pcap/utils/format";
import DebtPaydownChart from "components/savingsPlanner/DebtPaydown/DebtPaydownChart.js";
import DebtPaydownTable from "components/savingsPlanner/DebtPaydown/DebtPaydownTable.js";
import moment from "moment";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";
import EditAccountContainer from "components/common/EditAccount/EditAccountContainer";
import { totalDebtBalance } from "components/savingsPlanner/SavingsPlannerUtils";
import { accounts as mockAccounts } from "./DebtPaydownTable.mock";

const DAYS_IN_YEAR = 365;
const DAYS_IN_MONTH = 30;
const TYPICAL_DEBT_PAYMENT = 1000;

export const DEBT_PAYDOWN_TOOLTIP =
  "Debt change this year is calculated as the year to date balance change of your linked debt accounts.";
const REMAINING_LOANS_TOOLTIP =
  "The remaining debt balance is the sum of all linked loans, mortgages, and credit cards with at least 2 interest charges over the past 90 days.";

export default class DebtPaydownView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditAccountModalOpen: false,
    };

    this.handleEditAccountClick = this.handleEditAccountClick.bind(this);
    this.handleEditAccountModalClose =
      this.handleEditAccountModalClose.bind(this);
    this.handleChangeMouseOver = this.handleChangeMouseOver.bind(this);
    this.handleDebtMouseOver = this.handleDebtMouseOver.bind(this);
  }

  handleEditAccountClick(selectedUserAccountId) {
    this.setState({
      isEditAccountModalOpen: true,
      selectedUserAccountId,
    });
  }

  handleEditAccountModalClose() {
    this.setState({
      isEditAccountModalOpen: false,
      selectedUserAccountId: null,
    });
  }
  //GA Tags
  handleChangeMouseOver() {
    if (IS_EMPOWER)
      window.dashboardUtils?.eventBus.dispatch(
        `debt_paydown_page.change_this_year_tooltip.mouseover`
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_TOOLTIP,
        event_properties: {
          selection: "debt_paydown_page.change_this_year_tooltip.mouseover",
        },
      });
  }

  handleDebtMouseOver() {
    if (IS_EMPOWER)
      window.dashboardUtils?.eventBus.dispatch(
        `debt_paydown_page.remaining_debt_tooltip.mouseover`
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_TOOLTIP,
        event_properties: {
          selection: "debt_paydown_page.remaining_debt_tooltip.mouseover",
        },
      });
  }

  render() {
    const {
      loading,
      accounts,
      ytdBalanceChange,
      takeaways,
      actions,
      debtBalances,
      hasHeadline,
    } = this.props;

    const { isEditAccountModalOpen, selectedUserAccountId } = this.state;
    const chartAriaLabel = `Line graph of remaining debt Year to Date`;

    if (loading) {
      return <Loading />;
    }

    const isZeroState = debtBalances.length === 0;
    const today = moment().subtract(1, "years");
    const beginningDebtBalance = 100000;

    const fakeBalances = Array.from({ length: DAYS_IN_YEAR }, (_, i) => {
      return {
        date: today.add(1, "days").format("YYYY-MM-DD"),
        totalLiabilities:
          beginningDebtBalance -
          Math.floor(i / DAYS_IN_MONTH) * TYPICAL_DEBT_PAYMENT,
      };
    });
    if (isZeroState) {
      if (IS_EMPOWER) {
        document.querySelector("#moduleContainer")?.dispatchEvent(
          new CustomEvent("zeroState", {
            detail: { page: "debtPaydown", close: true },
          })
        );
      } else {
        return (
          <div className="js-zero-state">
            <div className="pc-layout pc-layout--flush">
              <div className="pc-layout__item pc-u-1/2 pc-u-pr-">
                {takeaways.map((ta, i) => (
                  <div
                    key={i}
                    className="savings-planner__zero-state-takeaway pc-u-mt-"
                  >
                    {ta}
                  </div>
                ))}
                {actions.length > 0 && (
                  <div className="l-spaced l-spaced--flush pc-u-mv">
                    {actions.map((act, i) => (
                      <a
                        key={i}
                        href={act.url}
                        className="pc-btn pc-btn--primary"
                      >
                        {act.label}
                      </a>
                    ))}
                  </div>
                )}
              </div>
              <div className="pc-layout__item pc-u-1/2 pc-u-pl-">
                <DebtPaydownChart
                  accounts={accounts}
                  debtBalances={fakeBalances}
                  isZeroState={true}
                  rightPadding={64}
                  ariaLabel={chartAriaLabel}
                />
              </div>
            </div>
          </div>
        );
      }
    }
    const accountList = isZeroState ? mockAccounts : accounts;
    const totalBalance = totalDebtBalance(accountList);

    const debtBalance = isZeroState ? fakeBalances : debtBalances;

    return (
      <div {...(isZeroState ? { inert: "inert" } : {})}>
        {!hasHeadline && (
          <div className="pc-u-mv">
            Your savings ability is impacted by your take home income and
            spending needs including Debt Paydown.
          </div>
        )}
        <div className="l-spaced l-spaced--flush">
          <div>
            <div className="u-sentence-case u-text-bold">
              Change This Year
              <InfoTooltipIcon
                title={DEBT_PAYDOWN_TOOLTIP}
                parentClassName="savings-planner__view-container"
                onMouseOver={this.handleChangeMouseOver}
              />
            </div>
            <div className="savings-planner-takeaway__amount--debt qa-debt-paydown-yearly-change-amount">
              {formatCurrency(ytdBalanceChange, 0)}
            </div>
          </div>
          <div>
            <div className="u-sentence-case u-text-bold">
              Remaining Debt
              <InfoTooltipIcon
                title={REMAINING_LOANS_TOOLTIP}
                tooltipProps={{ placement: "left" }}
                parentClassName="savings-planner__view-container"
                onMouseOver={this.handleDebtMouseOver}
              />
            </div>
            <div className="savings-planner-takeaway__amount--debt u-text-right qa-debt-paydown-remaining-debt-amount">
              {formatCurrency(totalBalance, 0)}
            </div>
          </div>
        </div>
        <div className="pc-u-mb">
          <DebtPaydownChart
            debtBalances={debtBalance}
            rightPadding={64}
            ariaLabel={chartAriaLabel}
          />
          {takeaways.map((ta, i) => (
            <div key={i} className="pc-u-mt-">
              {ta}
            </div>
          ))}
        </div>
        <DebtPaydownTable
          accounts={accountList}
          onEditAccountClick={this.handleEditAccountClick}
        />
        {isEditAccountModalOpen && (
          <EditAccountContainer
            userAccountId={selectedUserAccountId}
            isOpen={true}
            onCancel={this.handleEditAccountModalClose}
            onSave={this.handleEditAccountModalClose}
          />
        )}
      </div>
    );
  }
}

DebtPaydownView.propTypes = {
  loading: PropTypes.bool,
  debtBalances: PropTypes.array,
  ytdBalanceChange: PropTypes.number,
  takeaways: PropTypes.array,
  actions: PropTypes.array,
  accounts: PropTypes.array,
  // hasHeadline refers to the main Savings Planner Headline that appears in all SP views
  hasHeadline: PropTypes.bool,
};

DebtPaydownView.defaultProps = {
  loading: false,
  debtBalances: [],
  ytdBalanceChange: 0,
  actions: [],
  takeaways: [],
  accounts: [],
  hasHeadline: false,
};
