import React from "react";
import PropTypes from "prop-types";
import { LazyMotion, domAnimation } from "framer-motion";
import { MotionButton, MotionDiv, MotionPolygon } from "../MotionComponents";

const Accordion = (props) => {
  const ROTATE_DEG = 90;
  const ROTATE_180 = 180;

  const { id, header, text, iconType, isExpanded, onClick, isHtml } = props;

  const TRIANGLE_ICON = (
    <svg
      className="accordion__header-icon js-triangle-icon"
      viewBox="0 0 100 100"
      width="10"
      height="10"
    >
      <MotionPolygon
        points="100 50, 0 100, 0 0"
        animate={{ rotate: isExpanded ? ROTATE_DEG : 0 }}
      />
    </svg>
  );

  const CHEVRON_ICON = (
    <svg
      className="accordion__header-icon js-chevron-icon"
      viewBox="0 0 100 100"
      width="14"
      height="14"
    >
      <MotionPolygon
        points="100 100, 50 50,0 100, 10 100, 50 60, 90 100"
        animate={{ rotate: isExpanded ? ROTATE_180 : 0 }}
      />
    </svg>
  );

  const ACCORDION_ICON = iconType === "CHEVRON" ? CHEVRON_ICON : TRIANGLE_ICON;

  const handleKeyDown = (event, id) => {
    // eslint-disable-next-line no-magic-numbers
    if (event.key === "Enter" || event.keyCode === 13) {
      onClick(id);
    }
  };

  return (
    <LazyMotion features={domAnimation}>
      <div className="accordion" id={`accordion-${id}`}>
        <MotionButton
          className="centi accordion__header"
          onTap={onClick}
          onKeyPress={(e) => handleKeyDown(e, id)}
          data-test={"accordion__header"}
          aria-controls={`accordion-${id}-description`}
          aria-expanded={isExpanded}
          id={`accordion-${id}-header`}
        >
          {ACCORDION_ICON}
          <p className="accordion__header-text">{header}</p>
        </MotionButton>
        <MotionDiv
          className="accordion__description"
          animate={{
            height: isExpanded ? "auto" : 0,
            overflow: isExpanded ? "initial" : "hidden",
          }}
          data-test={"accordion__description"}
          id={`accordion-${id}-description`}
          role="region"
          aria-labelledby={`accordion-${id}-header`}
        >
          {isHtml ? (
            <div
              className="accordion__description-container"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ) : (
            <div className="accordion__description-container">{text}</div>
          )}
        </MotionDiv>
      </div>
    </LazyMotion>
  );
};

Accordion.propTypes = {
  id: PropTypes.string,
  header: PropTypes.string,
  text: PropTypes.node,
  iconType: PropTypes.string,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  isHtml: PropTypes.bool,
};

Accordion.defaultProps = {
  id: "1",
  header: "header",
  text: "description",
  iconType: "TRIANGLE",
  isExpanded: false,
  onClick: () => null,
  isHtml: false,
};

export default Accordion;
