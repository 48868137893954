import { useState, useEffect } from "react";
import eventBus from "eventBus";
import createTrackingEvent from "libs/pcap/utils/createTrackingEvent";

const useSchedulerInteractionTracker = () => {
  const [interactedWithScheduler, setInteractedWithScheduler] = useState(false);

  useEffect(() => {
    eventBus.on("ThreeTimeSlotOrViewMoreTimesClicked", () => {
      setInteractedWithScheduler(true);
    });

    return () => {
      eventBus.off("ThreeTimeSlotOrViewMoreTimesClicked");

      if (interactedWithScheduler) {
        // Per ACE-3640, fire this event only when user clicks on either the three time slots or the view more times button
        createTrackingEvent({ eventName: "USER_APPOINTMENT_MODAL_CLOSED" });
      }
    };
  }, [interactedWithScheduler]);

  return interactedWithScheduler;
};

export default useSchedulerInteractionTracker;
