var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<a class=\"customAccount "
    + alias1(__default(require("../../../helpers/hyphenate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"hyphenate","hash":{},"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":43}}}))
    + "\" data-product_id=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"productId") : depth0), depth0))
    + "\" data-custom_type=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n       href=\"#\"><img aria-hidden=\"true\"\n            src=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"logoPath") : depth0), depth0))
    + "\" /><span>"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"products") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":9}}})) != null ? stack1 : "");
},"useData":true});