import React from "react";
import PropTypes from "prop-types";
import TransferManager from "components/transferManager/TransferManager";
import {
  TRANSFER_MANAGER_HISTORY,
  TRANSFER_MANAGER_ACTIVE,
  TRANSFER_MANAGER_RECURRING,
} from "components/transferManager/TransferManager/TransferManager";
import AppWideBanner from "components/appWideBanner/AppWideBanner";
import Message from "components/common/Message";

import Services from "services";
import { promisify } from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";

const BANNER_TIMEOUT = 5000;

class TransferManagerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      transfers: [],
      message: undefined,
      errors: [],
    };

    this.showMessage = this.showMessage.bind(this);
    this.getTransfers = this.getTransfers.bind(this);
  }

  componentDidMount() {
    this.getTransfers();
  }

  getTransfers() {
    this.fetchDataRequest = makeCancelablePromise(this.props.getTransfers());

    this.fetchDataRequest.promise
      .then((transfers) => {
        if (transfers) this.setState({ transfers });
        this.setState({ loading: false });
      })
      .catch((errors) => {
        this.showMessage({ title: "Error", text: errors });
        this.setState({ loading: false });
      });
  }

  showMessage(message) {
    if (message.title === "Error")
      this.setState((prev) => ({
        ...prev,
        errors: Array.isArray(message.text) ? message.text : [message.text],
      }));
    else
      this.setState((prev) => ({
        ...prev,
        message,
      }));

    setTimeout(() => {
      this.setState((prev) => ({
        ...prev,
        message: undefined,
        errors: [],
      }));
    }, BANNER_TIMEOUT);
  }

  componentWillUnmount() {
    if (this.fetchDataRequest) this.fetchDataRequest.cancel();
  }

  render() {
    const { loading, transfers, message, errors } = this.state;
    const { section, isTaxWithholdingEnabled } = this.props;

    return (
      <>
        <Message className="pc-u-mb" severity="error" messages={errors} />
        <div className="nav-secondary l-spaced l-spaced--flush js-secondary-nav qa-nav-secondary">
          {message && (
            <AppWideBanner
              className="transfer-manager__banner app-wide-banner--prominent"
              title={message.title}
              text={message.text}
              isOpen={true}
            />
          )}
          <h1 className="nav-secondary__title qa-page-title">
            Transfer manager
          </h1>
          <nav>
            <ul className="pc-layout pc-layout--auto pc-layout--small">
              <li className="pc-layout__item">
                <a
                  className={`pc-btn pc-btn--bare ${
                    section === TRANSFER_MANAGER_ACTIVE ? "is-active" : ""
                  }`}
                  href="#/transfer-manager/active"
                >
                  Active
                </a>
              </li>
              <li className="pc-layout__item">
                <a
                  className={`pc-btn pc-btn--bare ${
                    section === TRANSFER_MANAGER_HISTORY ? "is-active" : ""
                  }`}
                  href="#/transfer-manager/history"
                >
                  History
                </a>
              </li>
              <li className="pc-layout__item">
                <a
                  className={`pc-btn pc-btn--bare ${
                    section === TRANSFER_MANAGER_RECURRING ? "is-active" : ""
                  }`}
                  href="#/transfer-manager/recurring"
                >
                  Recurring
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <TransferManager
          section={section}
          transfers={transfers}
          loading={loading}
          showMessage={this.showMessage}
          isTaxWithholdingEnabled={isTaxWithholdingEnabled}
        />
      </>
    );
  }
}

TransferManagerContainer.propTypes = {
  section: PropTypes.string,
  getTransfers: PropTypes.func,
  isTaxWithholdingEnabled: PropTypes.bool,
};

TransferManagerContainer.defaultProps = {
  section: "active",
  getTransfers: promisify(Services.TransferManager.getTransfers),
  isTaxWithholdingEnabled: false,
};

export default TransferManagerContainer;
