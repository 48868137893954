import BreadcrumbView2 from "views/components/breadcrumbView2";
import _ from "underscore";

export default {
  renderBreadcrumb: function (level) {
    var self = this,
      breadcrumbs = [];
    var j = 0;

    this.setDrilldownLevel(level);

    // generate data structure for breadcrumb component
    for (var i = 0; i <= level; i++) {
      var crumb = {};

      switch (i) {
        default:
        case 0:
          crumb.level = 0;
          crumb.name =
            this.incomeExpenseMode === "income" ? "All Income" : "All Expenses";
          crumb.id = "";
          crumb.cssClass = "level0";
          crumb.isBranch = level !== 0;
          crumb.path =
            "#" + this.options.baseUrl + "/" + this.incomeExpenseMode;
          break;
        case 1:
          var selectedCategory = _.find(this.getCurrentCategories(), function (
            category
          ) {
            return category.transactionCategoryId === self.selectedCategoryId;
          });

          crumb.level = 1;
          crumb.name = selectedCategory?.name;
          crumb.id = this.selectedCategoryId;
          // The category color is set in Donut.js, buildCategoryDonut()
          crumb.color = selectedCategory?.color;
          crumb.cssClass = "level1";
          crumb.isBranch = level !== 1;
          crumb.path =
            "#" +
            this.options.baseUrl +
            "/" +
            this.incomeExpenseMode +
            "/" +
            this.selectedCategoryId;

          // generate category menu data
          if (level >= 1 && this.getCurrentCategories().length > 1) {
            crumb.menuItems = [];

            for (j = 0; j < this.getCurrentCategories().length; j++) {
              var categoryItem = {
                name: this.getCurrentCategories()[j].name,
                id: this.getCurrentCategories()[j].transactionCategoryId,
                // The category color is set in Donut.js, buildCategoryDonut()
                color: this.getCurrentCategories()[j].color,
                level: 1,
                selected:
                  this.getCurrentCategories()[j].transactionCategoryId ===
                  this.selectedCategoryId,
                path:
                  "#" +
                  this.options.baseUrl +
                  "/" +
                  this.incomeExpenseMode +
                  "/" +
                  this.getCurrentCategories()[j].transactionCategoryId,
              };
              crumb.menuItems.push(categoryItem);
            }
          }
          break;

        case 2:
          crumb.level = 2;
          crumb.name = this.selectedMerchant ? this.selectedMerchant.name : "";
          crumb.id = this.selectedMerchant ? this.selectedMerchant.sliceId : "";
          // The merchant color is set in Donut.js, createMerchantDonut()
          crumb.color = this.selectedMerchant
            ? this.selectedMerchant.color
            : "";
          crumb.cssClass = "level2";
          crumb.isBranch = false;

          // generate merchant menu data
          if (level >= 1 && this.getMerchants().length > 1) {
            crumb.menuItems = [];

            for (j = 0; j < this.getMerchants().length; j++) {
              var merchantItem = {
                name: this.getMerchants()[j].name,
                id: this.getMerchants()[j].sliceId,
                // The merchant color is set in Donut.js, createMerchantDonut()
                color: this.getMerchants()[j].color,
                level: 2,
                selected:
                  this.getMerchants()[j].sliceId === this.selectedMerchantId,
                path:
                  "#" +
                  this.options.baseUrl +
                  "/" +
                  this.incomeExpenseMode +
                  "/" +
                  this.selectedCategoryId +
                  "/" +
                  this.getMerchants()[j].sliceId,
              };

              crumb.menuItems.push(merchantItem);
            }
          }
          break;
      }

      breadcrumbs.push(crumb);
    }

    if (this.breadcrumbComponent) {
      this.breadcrumbComponent.update(breadcrumbs);
    } else {
      this.breadcrumbComponent = new BreadcrumbView2({
        el: ".js-breadcrumb",
        breadcrumbs: breadcrumbs,
      });
    }
  },
};
