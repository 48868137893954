/* eslint-disable camelcase */
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import ReporterMixin from "views/modules/enrollmentNew/mixins/reporter";
import ReferralLinkTemplate from "templates/components/getReferralLink/getReferralLink.html";
import ReferralLinkTemplateNonClient from "templates/components/getReferralLink/getReferralLinkNonClient.html";
import DisclaimerTemplate from "templates/components/getReferralLink/disclaimer.html";
import utils from "libs/pcap/utils/utils";
import analytics from "analytics";
import Clipboard from "clipboard";
import Mixpanel from "mixpanel";
import "tooltip";

var EMAIL_LINE_BREAK = "%0D%0A";
var REGEX_SPACE_ALL = /\s+/g;
const organizationName = `The ${EMPOWER_PERSONAL_DASHBOARD}`;

const termsUrl = "https://www.empower.com/hpc/terms-of-use";

var getFullName = function () {
  var fullName = utils.capitalizeWords(
    window.usernameFirst + " " + window.usernameLast
  );
  var nameIsBlank = !fullName.replace(REGEX_SPACE_ALL, "");
  if (nameIsBlank) {
    fullName = "Your friend";
  }
  return fullName;
};

var GetReferralLinkView = Backbone.View.extend({
  events: {
    "click .js-close": "closeModalClickHandler",
    "click .js-action-button": "actionClickHandler",
    "click .js-action-copy-link": "copyLinkClickHandler",
    "mouseleave .js-action-copy-link": "copyLinkMouseLeaveHandler",
    "click .js-action-terms": "termsLinkClickHandler",
    "click .js-social-button": "trackClick",
  },
  initialize: function ({ onModalClosed } = {}) {
    this.onModalClosed = onModalClosed;
    Services.Advisor.get(
      function (error, response) {
        if (
          !error &&
          response &&
          response.spData &&
          (response.spData.clientReferralShortUrl ||
            response.spData.clientReferralUrl)
        ) {
          this.renderOptions = {
            inviteLink: utils.ensureUrlHasHttps(
              response.spData.clientReferralShortUrl ||
                response.spData.clientReferralUrl
            ),
          };
          $(".js-action-copy-link").tooltip();
        } else {
          this.renderOptions = {
            error: error || _.pluck(response.spHeader.errors, "message"),
          };
        }
        this.render();
      }.bind(this)
    );
  },

  render: function () {
    var options = this.renderOptions || {};
    var isClient = window.userHasOnusAccounts;
    var template = isClient
      ? ReferralLinkTemplate
      : ReferralLinkTemplateNonClient;
    this.$el.html(
      template({
        inviteLink: options.inviteLink,
        email: window.username,
        fullName: getFullName(),
        BR: EMAIL_LINE_BREAK,
        organizationName: "Empower",
        termsUrl: termsUrl,
      })
    );

    var error = null;
    if (options.error) {
      error = _.isEmpty(options.error)
        ? [
            "Sorry. An error occurred while retrieving your unique referral link.",
          ]
        : options.error;
      this.displayErrors(error);
    }

    // Show the view in modal window using colorbox
    $.colorbox({
      inline: true,
      href: this.$el,
      overlayClose: false,
      transition: "none",
      onComplete: function () {
        Mixpanel.trackEvent("View Refer a Friend Modal", {
          component: "Refer a Friend Modal",
          invite_link: options.inviteLink,
          error: error ? JSON.stringify(error) : null,
        });
      },
    });

    if (!isClient) {
      this.nonClientReferralRender();
    }

    return this;
  },

  nonClientReferralRender: function () {
    var rootURL =
      window.location.origin === "https://home.personalcapital.com"
        ? "https://www.personalcapital.com"
        : "https://www-devtrunk.pcapcloud.com";
    var userReferralID = window.userGuid;

    var mailLink = `mailto:?subject=Take control of your money now with ${organizationName}&body=
      ${encodeURIComponent(
        rootURL +
          "/get-started?utm_source=user_referral&utm_medium=referralEmail&utm_campaign=get_referral_link&transactionID=" +
          userReferralID
      )}`;
    var fbLink =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      encodeURIComponent(
        rootURL +
          "/get-started?utm_source=user_referral&utm_medium=facebook&utm_campaign=get_referral_link&transactionID=" +
          userReferralID
      );
    var inLink =
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      encodeURIComponent(
        rootURL +
          "/get-started?utm_source=user_referral&utm_medium=linkedin&utm_campaign=get_referral_link&transactionID=" +
          userReferralID
      );
    var twitterURL = encodeURIComponent(
      rootURL +
        "/get-started?utm_source=user_referral&utm_medium=twitter&utm_campaign=get_referral_link&transactionID=" +
        userReferralID
    );
    var tweetLink = `https://twitter.com/intent/tweet?original_referer=${twitterURL}&text=Take control of your money now with ${organizationName}&url=${twitterURL}`;

    var getStartedPageUrl =
      rootURL +
      "/get-started?utm_source=user_referral&utm_medium=copiedLink&utm_campaign=get_referral_link&transactionID=" +
      userReferralID;
    document.getElementById("js-non-client-referral-link").value =
      getStartedPageUrl;
    document.getElementById(
      "js-content-to-copy"
    ).value = `The future of investing is ${organizationName}! It's free to try! ${getStartedPageUrl}`;
    document.getElementById("Refer-Through-LinkedIn").href = inLink;
    document.getElementById("Refer-Through-Mail").href = mailLink;
    document.getElementById("Refer-Through-Facebook").href = fbLink;
    document.getElementById("Refer-Through-Twitter").href = tweetLink;
  },

  trackClick: function (e) {
    Mixpanel.trackEvent(
      'Click Social Share Button "' + e.currentTarget.id + '"',
      { component: "Referral Modal" }
    );
  },

  closeModal: function () {
    $.colorbox.close();
    this.remove();
    if (this.onModalClosed) {
      this.onModalClosed();
    }
  },
  closeModalClickHandler: function (e) {
    e.preventDefault();
    analytics.sendEvent("Client Referral Program", "Close");
    Mixpanel.trackEvent("Close Refer a Friend Modal", {
      component: "Refer a Friend Modal",
    });
    this.closeModal();
  },
  actionClickHandler: function (e) {
    var actionLabel = $(e.currentTarget).data("analytics-action");
    analytics.sendEvent("Client Referral Program", actionLabel);
    Mixpanel.trackEvent("Click on " + actionLabel, {
      component: "Refer a Friend Modal",
    });
  },
  copyLinkClickHandler: function (ev) {
    ev.preventDefault();

    if (!this.clipboard) {
      this.clipboard = new Clipboard(".js-action-copy-link");
    }
    var clipboard = this.clipboard;

    clipboard.on(
      "success",
      function (e) {
        this.$(".js-action-copy-link").tooltip("show");
        e.clearSelection();
        Mixpanel.trackEvent("View Clipboard Icon Button Tooltip", {
          component: "Refer a Friend Modal",
          tooltip_text: "Copied",
        });
        this.clipboardTooltipVisible = true;
      }.bind(this)
    );

    clipboard.on("error", function (e) {
      analytics.sendEngineeringEvent("Error", e.message);
      Mixpanel.trackEvent("Copy to Clipboard Failed", {
        component: "Refer a Friend Modal",
        error: e.message,
      });
    });
  },
  copyLinkMouseLeaveHandler: function () {
    if (this.clipboardTooltipVisible) {
      this.$(".js-action-copy-link").tooltip("hide");
      Mixpanel.trackEvent("Mouse Leave Clipboard Icon Button to Hide Tooltip", {
        component: "Refer a Friend Modal",
        tooltip_text: "Copied",
      });
      this.clipboardTooltipVisible = false;
    }
  },
  remove: function () {
    if (this.clipboard) {
      this.clipboard.off("success");
      this.clipboard.off("error");
      this.clipboard.destroy();
    }
    Backbone.View.prototype.remove.apply(this, arguments);
  },
  termsLinkClickHandler: function (ev) {
    ev.preventDefault();
    $.colorbox({
      transition: "none",
      html: `<div id="referralDisclaimer" class="adviceModal">
        ${DisclaimerTemplate({
          organizationName: "Empower",
        })} </div>`,
      onComplete: function () {
        $("#referralDisclaimer a.close").on(
          "click",
          this.closeDisclaimer.bind(this)
        );
        Mixpanel.trackEvent("View Terms and Conditions Modal", {
          component: "Refer a Friend Modal",
        });
      }.bind(this),
    });
  },
  closeDisclaimer: function (ev) {
    ev.preventDefault();
    $("#referralDisclaimer a.close").off("click");

    Mixpanel.trackEvent("Close Terms and Conditions Modal", {
      component: "Refer a Friend Modal",
    });

    // render parent
    this.render();
  },
});

_.extend(GetReferralLinkView.prototype, ReporterMixin);

export default GetReferralLinkView;
