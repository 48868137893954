import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import AccountTypeCombo from "components/common/EditAccount/AccountTypeCombo";
import { CSSTransition } from "react-transition-group";
import { filterOutOptions } from "components/modal/editAccount/utils/selectOptions";
import FancySelect from "components/common/form/FancySelect/FancySelect";
import {
  CURRENCY_FORMAT,
  POSITIVE_CURRENCY_FORMAT,
} from "components/common/form/formattingOptions";
import { MAX_INTEREST_DIGITS } from "utils/account";
import { hasPrintableCharacters } from "common/validators/functions.js";
import LOAN_PRODUCT_TYPES from "enums/loanProductTypes";

const MAX_ACCOUNT_NUMBER_LENGTH = 50;
const MAX_BALANCE_DIGITS = 19;

class LoanAccountDetails extends React.Component {
  renderOwnersSection({ model, beneficiaries, disabled = false }) {
    const primaryOwners = model.stakeholders.PRIMARY || [];
    const primaryOwner =
      (primaryOwners.length &&
        primaryOwners[0].personId &&
        primaryOwners[0].personId.toString()) ||
      "";
    const { optionsPeople } = this.props;
    const beneficiariesIds = beneficiaries.map((b) => String(b.personId));
    const optionsPrimaryOwner = filterOutOptions(
      optionsPeople,
      [model.secondaryOwner].concat(beneficiariesIds)
    );

    return (
      <div>
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/3">
            <label htmlFor="eaAccountOwner" className="pc-label--tiny">
              Account Owner
            </label>
          </div>
          <div className="pc-layout__item pc-u-2/3">
            <FancySelect
              id="eaAccountOwner"
              name="stakeholders.PRIMARY.0.personId"
              ref={this.props.storeInputRef}
              value={primaryOwner}
              className={`input--full qa-primary-owner Select--mini`}
              onChange={this.props.onInputChange}
              validator={this.props.schema.properties.primaryOwner}
              options={optionsPrimaryOwner}
              menuPortalTarget={document.body}
              isDisabled={disabled}
              ariaLabel="Account owner"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { model, onInputChange } = this.props;
    const beneficiaries = model.stakeholders.BENEFICIARY || [];
    const disableFields = Boolean(model.closedDate);

    return (
      <div>
        <div className="pc-layout">
          <div className="pc-form-group--small">
            <div className="pc-layout__item pc-u-1/3">
              <label className="pc-label--tiny">Firm Name</label>
            </div>
            <div className="pc-layout__item pc-u-2/3">
              <Input
                className="input--tiny qa-firm-name"
                type="text"
                name="firmName"
                sizeVariation="full"
                onChange={onInputChange}
                value={model.firmName}
                ref={this.props.storeInputRef}
                validator={this.props.schema.properties.firmName}
                disabled={disableFields}
                maxLength={200}
                ariaLabel="Firm name"
              />
            </div>
          </div>
          <div className="pc-form-group--small">
            <div className="pc-layout__item pc-u-1/3">
              <label className="pc-label--tiny">Account Name</label>
            </div>
            <div className="pc-layout__item pc-u-2/3">
              <Input
                className="input--tiny qa-account-name"
                type="text"
                name="name"
                sizeVariation="full"
                onChange={onInputChange}
                value={model.name}
                ref={this.props.storeInputRef}
                validator={this.props.schema.properties.name}
                disabled={disableFields}
                maxLength={200}
                ariaLabel="Account name"
              />
            </div>
          </div>
          <CSSTransition
            in={model.isManual}
            unmountOnExit={true}
            classNames="transition-form-group"
            className="js-edit-account-account-number pc-form-group--small"
            timeout={250}
          >
            <div>
              <div className="pc-layout__item pc-u-1/3">
                <label className="pc-label--tiny">Account Number</label>
              </div>
              <div className="pc-layout__item pc-u-2/3">
                <Input
                  className="input--tiny qa-account-number"
                  type="text"
                  name="accountNumber"
                  sizeVariation="full"
                  onChange={onInputChange}
                  value={model.accountNumber}
                  maxLength={MAX_ACCOUNT_NUMBER_LENGTH}
                  ref={this.props.storeInputRef}
                  validator={this.props.schema.properties.accountNumber}
                  disabled={disableFields}
                  ariaLabel="Account number"
                />
              </div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={
              model.isManual && ["LOAN", "MORTGAGE"].includes(model.productType)
            }
            unmountOnExit={true}
            classNames="transition-form-group"
            className="js-edit-account-balance pc-form-group--small"
            timeout={250}
          >
            <div>
              <div className="pc-layout__item pc-u-1/3">
                <label className="pc-label--tiny">Current Balance</label>
              </div>
              <div className="pc-layout__item pc-u-2/3">
                <Input
                  className="input--tiny qa-current-balance"
                  type="text"
                  name="currentBalance"
                  maxLength={MAX_BALANCE_DIGITS}
                  sizeVariation="full"
                  prefix="$"
                  onChange={onInputChange}
                  value={model.currentBalance}
                  formattingOptions={CURRENCY_FORMAT}
                  ref={this.props.storeInputRef}
                  validator={this.props.schema.properties.currentBalance}
                  disabled={disableFields}
                  ariaLabel="Current balance"
                />
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className="pc-layout">
          <div className="pc-form-group--small">
            <div className="pc-layout__item pc-u-1/3">
              <label className="pc-label--tiny">Account Type</label>
            </div>
            <div className="pc-layout__item pc-u-2/3">
              <FancySelect
                key={model.productType} // prevent dropdown from getting stuck in a bad state
                name="productType"
                ref={this.props.storeInputRef}
                value={model.productType}
                className={`input--full qa-product-type Select--mini`}
                onChange={this.props.onProductTypeChange}
                validator={this.props.schema.properties.productType}
                options={LOAN_PRODUCT_TYPES}
                isDisabled={disableFields}
                ariaLabel="Account type"
              >
                <option />
              </FancySelect>
            </div>
          </div>
          <div className="pc-layout__item pc-u-1/3">
            {/* Blank space to hold `AccountTypeCombo` to the right*/}
          </div>
          <div className="pc-layout__item pc-u-2/3">
            <AccountTypeCombo
              name="accountTypeCombo"
              className="Select--tiny qa-account-type"
              ref={this.props.storeInputRef}
              onChange={this.props.onAccountTypeChange}
              value={{
                group: model.accountTypeGroup,
                type: model.accountTypeNew,
                subType: model.accountTypeSubtype,
              }}
              validator={this.props.schema.properties.accountTypeCombo}
              optionsGroup={this.props.accountGroups}
              typesMap={this.props.accountTypesMap}
              subTypesMap={this.props.accountSubTypesMap}
              disabled={disableFields}
              ariaLabels={{
                group: "Account Type",
                type: `Type of ${model.accountTypeGroup} account`,
                subType: `Type of ${model.accountTypeNew} account`,
              }}
            />
          </div>
          <div className="pc-form-group--small">
            <div className="pc-layout__item pc-u-1/3">
              <label className="pc-label--tiny">Interest Rate</label>
            </div>
            <div className="pc-layout__item pc-u-1/4">
              <Input
                className="input--tiny qa-interest-rate"
                type="text"
                name="interestRate"
                sizeVariation="full"
                onChange={onInputChange}
                value={model.interestRate}
                ref={this.props.storeInputRef}
                formattingOptions={POSITIVE_CURRENCY_FORMAT}
                validator={this.props.schema.properties.interestRate}
                maxLength={MAX_INTEREST_DIGITS}
                suffix={"%"}
                disabled={disableFields}
                ariaLabel="Interest rate"
              />
            </div>
          </div>
        </div>
        {this.renderOwnersSection({
          model,
          beneficiaries,
          disabled: disableFields,
        })}
      </div>
    );
  }
}

LoanAccountDetails.propTypes = {
  model: PropTypes.object.isRequired,
  accountTypesMap: PropTypes.object.isRequired,
  optionsPeople: PropTypes.array,
  schema: PropTypes.object,
  onInputChange: PropTypes.func.isRequired,
  onProductTypeChange: PropTypes.func.isRequired,
  onAccountTypeChange: PropTypes.func.isRequired,
  accountGroups: PropTypes.array.isRequired,
  accountSubTypesMap: PropTypes.object.isRequired,
  storeInputRef: PropTypes.func.isRequired,
};

LoanAccountDetails.defaultProps = {
  optionsPeople: [],
  schema: {
    type: "object",
    properties: {
      firmName: {
        allowEmpty: false,
        required: true,
        type: "string",
        conform: hasPrintableCharacters,
        messages: {
          conform: "must not be empty",
        },
      },
      name: {
        allowEmpty: false,
        required: true,
        type: "string",
        conform: hasPrintableCharacters,
        messages: {
          conform: "must not be empty",
        },
      },
      accountNumber: {
        type: "string",
      },
      currentBalance: {
        type: "number",
        required: true,
        allowEmpty: false,
      },
      productType: {
        required: true,
        allowEmpty: false,
      },
      accountTypeCombo: {
        type: "object",
        properties: {
          group: {
            required: true,
            allowEmpty: false,
          },
          type: {
            required: true,
            allowEmpty: false,
          },
          subType: {
            required: true,
            allowEmpty: false,
          },
        },
      },
      interestRate: {
        type: "number",
        minimum: 0,
        maximum: 100,
        messages: {
          type: "numbers only",
          minimum: "Minimum 0%",
          maximum: "100 maximum",
        },
      },
    },
  },
};

export default LoanAccountDetails;
