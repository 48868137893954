import React from "react";

const organizationNameCash = "Empower Personal Cash";

export default function PcapCashGridFooterAndDisclaimers() {
  return (
    <div className="l-spaced l-spaced--flush pc-u-mt">
      <a
        rel="noopener noreferrer"
        target="_blank"
        className="pc-delta"
        href={UMB_PRIVACY_POLICY_LINK_REBRANDED}
      >
        UMB Privacy Policy
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        className="pc-delta"
        href={PROGRAM_BANKS_LINK_REBRANDED}
      >
        Program Banks
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        className="pc-delta"
        href={CASH_TERMS_LINK_REBRANDED}
      >
        {organizationNameCash} Customer Agreement
      </a>
    </div>
  );
}
