import { formatCurrency } from "libs/pcap/utils/format";

/**
 * Defines the tooltip for cashflow widget.
 *
 * @param {Object}    options           the options object
 * @param {Boolean}  options.showNegativeValues  specify whether to show - sign in front of the value.
 * @param {Boolean}  options.showCents         specify whether to show decimals in the value.
 *
 * @returns {String}            the tooltip html string
 */
export default function (options) {
  return function (datum, index, key) {
    let formatedValue = options.showCents
      ? formatCurrency(datum[key])
      : formatCurrency(datum[key], 0);

    // For expense types we need to show negative numbers.
    if (options.showNegativeValues) {
      formatedValue = `-${formatedValue}`;
    }

    return `<div class="milli">
              <div class="chart-tooltip__header pc-u-m0">${key}</div>
              <div>${formatedValue}</div>
            </div>`;
  };
}
