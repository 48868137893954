var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(require("./removeAccount2.html"),depth0,{"name":"templates/modules/sidebar/removeAccount2","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"aggregationError") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"ZILLOW_ADDRESS_INVALID",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":5,"column":14}}})) != null ? stack1 : "")
    + " ";
},"5":function(container,depth0,helpers,partials,data) {
    return "  <button\n    type=\"button\"\n    class=\"js-account-authorize pc-btn pc-btn--tiny pc-btn--primary qa-account-authorize\"\n    disabled\n  >\n    Fix Account\n  </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "  <button\n    type=\"button\"\n    class=\"js-account-authorize pc-btn pc-btn--tiny pc-btn--primary qa-account-authorize\"\n  >\n    Fix Account\n  </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sidebar-account__actions\">\n  "
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.noop,"inverse":container.program(1, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":3,"column":20}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"aggregationError") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":21},"end":{"line":5,"column":22}}})) != null ? stack1 : "")
    + "\n  <button type=\"button\" class=\"js-account-cancel pc-btn pc-btn--tiny\">\n    Cancel\n  </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPrivileged") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":24,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});