/* eslint-disable camelcase, no-underscore-dangle */

import Backbone from "backbone";
import React from "react";
import ReactDOM from "react-dom";
import AceAppWideBannerView from "components/appWideBanner/AceAppWideBanner";
import markUserMessageAsViewed from "libs/pcap/utils/markUserMessageAsViewed";
import { USER_MSG_TYPE } from "components/interjection/helpers";
import { getLatestUserMessages } from "views/components/postLoginActions/PostLoginActionsUtils";

const PARENT_CONTAINER_SELECTOR = "body > .l-content > .l-content__inner";
const LAUNCHER_NODE_PARENT_SELECTOR = ".js-main-container";
const WITH_APP_WIDE_BANNER_CLASS = "l-content__inner--with-app-wide-banner";
const REFETCH_USER_MESSAGES_DELAY = 1000;

export default Backbone.View.extend({
  show(userMessage) {
    if (!this.bannerLauncherNode) {
      $(PARENT_CONTAINER_SELECTOR).addClass(WITH_APP_WIDE_BANNER_CLASS);
      this.bannerLauncherNode = $("<div></div>")
        .prependTo(LAUNCHER_NODE_PARENT_SELECTOR)
        .get(0);
    }
    const props = {
      userMessage,
      onRemove: this.onRemoveBanner.bind(this),
      onMarkAsViewed: markUserMessageAsViewed,
    };
    ReactDOM.render(
      React.createElement(AceAppWideBannerView, props),
      this.bannerLauncherNode
    );
  },

  onRemoveBanner() {
    $(PARENT_CONTAINER_SELECTOR).removeClass(WITH_APP_WIDE_BANNER_CLASS);
    if (this.bannerLauncherNode) {
      ReactDOM.unmountComponentAtNode(this.bannerLauncherNode);
      this.bannerLauncherNode.parentNode.removeChild(this.bannerLauncherNode);
      this.bannerLauncherNode = null;
    }
    this.trigger("closed");
    if (this.refetchUserMessagesTimer) {
      clearTimeout(this.refetchUserMessagesTimer);
    }
    this.refetchUserMessagesTimer = setTimeout(() => {
      // Wait for updateUserMessage to finish before calling getUserMessages again
      getLatestUserMessages({ screenFor: [USER_MSG_TYPE.APP_WIDE_BANNER] });
    }, REFETCH_USER_MESSAGES_DELAY);
  },

  remove() {
    if (this.refetchUserMessagesTimer) {
      clearTimeout(this.refetchUserMessagesTimer);
    }
    this.$el.remove();
    Backbone.View.prototype.remove.apply(this, arguments);
  },

  render() {
    return this;
  },
});
