import React from "react";
import PropTypes from "prop-types";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { noop } from "underscore";
// eslint-disable-next-line import/no-unresolved
import { convert } from "html-to-text";

class InfoTooltipIcon extends React.Component {
  render() {
    const {
      parentClassName,
      className,
      storeTooltipRef,
      title,
      container,
      tooltipProps,
      onClick,
      onDoubleClick,
      onMouseOver,
      nonInteractiveElement,
    } = this.props;

    const noteOrButtonRole = nonInteractiveElement ? "note" : "button";
    const options = {
      selectors: [{ selector: "ul", options: { itemPrefix: " " } }],
    };
    const plainText = convert(title, options)?.trim();

    const handleKeyDown = (event) => {
      if (["Enter", " "].includes(event.key)) {
        event.preventDefault();
        if (tooltipProps.trigger === "click") {
          const selector = $(`.${parentClassName} span[data-toggle=tooltip]:first`);
          if (selector.length) {
            selector.tooltip("toggle");
          }
        } else {
          onDoubleClick(event.key);
        }
      } else if (event.key === "Escape") {
        event.preventDefault();
        $(`.${parentClassName} span[data-toggle=tooltip]`).tooltip("hide");
      }
    };

    /** Manually setting the template to add the role="tooltip" on the outer div.*/
    const template = `<div class="tooltip" role=${
      tooltipProps?.role ?? "tooltip"
    }><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`;
    const tooltipContainer = container === undefined ? "body" : false;
    return (
      <span
        role={parentClassName ? "tooltip" : noteOrButtonRole}
        tabIndex={nonInteractiveElement ? -1 : 0}
        aria-label={plainText}
        ref={(el) => {
          storeTooltipRef(
            el,
            Object.assign(
              {
                html: true,
                container: tooltipContainer,
                placement: "auto",
                template,
              },
              tooltipProps
            )
          );
        }}
        onDoubleClick={onDoubleClick}
        onMouseOver={onMouseOver}
        onFocus={onMouseOver}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        data-toggle="tooltip"
        title={title}
        className="tooltip-icon-wrapper"
      >
        <svg className={`icon__help-circled ${className}`} alt="" aria-hidden />
      </span>
    );
  }
}

InfoTooltipIcon.propTypes = {
  parentClassName: PropTypes.string,
  className: PropTypes.string,
  storeTooltipRef: PropTypes.func,
  title: PropTypes.string.isRequired,
  container: PropTypes.bool,
  tooltipProps: PropTypes.object,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  nonInteractiveElement: PropTypes.bool,
};

InfoTooltipIcon.defaultProps = {
  className: "pc-u-ml-",
  onClick: undefined,
  onDoubleClick: noop,
  onMouseOver: undefined,
  parentClassName: undefined,
  storeTooltipRef: noop,
  tooltipProps: undefined,
  container: undefined,
  nonInteractiveElement: false,
};

export default withTooltips(InfoTooltipIcon);
