import React, { Component } from "react";
import PropTypes from "prop-types";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import { promisify } from "utils/service";
import Services from "services";
import { noop } from "underscore";
import LoadingOverlay from "components/common/LoadingOverlay";
import { getSource } from "components/common/attributionStore";
import Message from "components/common/Message";
import VerifyTransferMessage from "../VerifyTransferMessage/VerifyTransferMessage";

class VerifyTransferContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { initializing: true };
  }

  componentDidMount() {
    this.props.onMounted("Transfer Funds");
    this.serviceVerifyTransfer();
  }

  componentWillUnmount() {
    if (this.serviceVerifyTransferPromise) {
      this.serviceVerifyTransferPromise.cancel();
    }
  }

  async serviceVerifyTransfer() {
    this.setState({ loading: true });

    const { serviceVerifyTransfer, sourceAccountId, targetAccountId } =
      this.props;

    const requestParams = {
      sourceAccountId,
      targetAccountId,
      source: getSource(),
    };

    this.serviceVerifyTransferPromise = makeCancelablePromise(
      serviceVerifyTransfer(requestParams)
    );

    try {
      const rs = await this.serviceVerifyTransferPromise.promise;
      // rs.status === "FAILED" && rs.nextAction === "NONE" covers the following scenario.
      // GIACT validation failed. UI client should continue forward as we will fall back on the manual verification.
      // After submitting the transfer we will ask the user to sign documents via DocuSign.
      if (
        rs.status === "PASSED" ||
        rs.status === "UNKNOWN" ||
        (rs.status === "FAILED" && rs.nextAction === "NONE")
      ) {
        this.props.onComplete(true);
        return;
      } else if (rs.status === "FAILED" && rs.nextAction === "SHOW_MESSAGE") {
        this.setState({
          loading: false,
          initializing: false,
          errors: undefined,
          message: rs.messages[0].description,
        });
      } else {
        this.setState({
          loading: false,
          initializing: false,
          errors: [
            "We are unable to verify the account. The transfer cannot be completed.",
          ],
        });
      }
    } catch (errors) {
      if (this.serviceVerifyTransferPromise.isCanceled()) {
        return;
      }
      this.setState({ errors, initializing: false, loading: false });
    }
  }

  render() {
    const { loading, initializing, errors } = this.state;

    if (initializing) {
      return (
        <LoadingOverlay
          key="init-verify-transfer"
          active={true}
          className="qa-loading-overlay--verify-transfer-init"
        />
      );
    }
    if (errors) {
      return <Message severity="error" messages={errors} />;
    }
    return (
      <>
        <LoadingOverlay
          key="verify-transfer"
          active={loading}
          className="qa-loading-overlay--verify-transfer"
        />
        <VerifyTransferMessage
          className={this.props.className}
          message={this.state.message}
          onReturnToHome={this.props.onBackToHome}
          onBack={this.props.onBack}
          shouldShowTradingBlackoutWarningMsg={
            this.props.shouldShowTradingBlackoutWarningMsg
          }
        />
      </>
    );
  }
}

VerifyTransferContainer.propTypes = {
  sourceAccountId: PropTypes.number.isRequired,
  targetAccountId: PropTypes.number.isRequired,
  onComplete: PropTypes.func,
  onBack: PropTypes.func,
  onBackToHome: PropTypes.func,
  serviceVerifyTransfer: PropTypes.func,
  className: PropTypes.string,
  onMounted: PropTypes.func,
  shouldShowTradingBlackoutWarningMsg: PropTypes.bool,
};

VerifyTransferContainer.defaultProps = {
  onComplete: noop,
  onBack: noop,
  serviceVerifyTransfer: promisify(Services.Verify.transfer),
  className: undefined,
  onMounted: noop,
  onBackToHome: noop,
  shouldShowTradingBlackoutWarningMsg: false,
};

export default VerifyTransferContainer;
