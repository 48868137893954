import { useState, useEffect, useLayoutEffect } from "react";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import {
  NET_WORTH,
  CASH_FLOW,
  PORTFOLIO,
  retirementPlannerGenerator,
  INVESTMENT_CHECKUP,
  GOALS,
} from "../helper";

export const useLockBodyScroll = (shouldLock) => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (shouldLock) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = originalStyle;
    }
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [shouldLock]);
};

export const useExpandableState = (initialState = false, componentName) => {
  const [isExpanded, setIsExpanded] = useState(initialState);

  const handleToggle = () => {
    setIsExpanded((prevState) => {
      const action = prevState ? "Hide" : "Expand";
      ComponentAnalytics.trackClick(
        null,
        componentName,
        `${action} Getting Started Page`
      );
      return !prevState;
    });
  };

  return [isExpanded, handleToggle];
};

export const useSetTilesBasedOnGoals = (notesForAdvisor, personAge) => {
  const RETIREMENT_PLANNER = retirementPlannerGenerator(personAge);
  const [tiles, setTiles] = useState([
    NET_WORTH,
    CASH_FLOW,
    PORTFOLIO,
    RETIREMENT_PLANNER,
  ]);
  const analyticsData = {
    InvestmentCheckupTile: false,
    NetWorthTile: true,
    RetirementPlannerTile: true,
    PortfolioTile: true,
    CashFlowTile: true,
  };

  useEffect(() => {
    const selectedGoals = notesForAdvisor.igFormChangeMoneyManagementWay || [];
    const otherGoal = notesForAdvisor.notesForAdvisorFromUser || "";
    const updateTiles = () => {
      if (
        selectedGoals.includes(GOALS.CONFIDENT_RETIREMENT) ||
        selectedGoals.includes(GOALS.PLAN_LIFE_EVENTS)
      ) {
        setTiles([RETIREMENT_PLANNER, NET_WORTH, PORTFOLIO, CASH_FLOW]);
      } else if (
        (selectedGoals.includes(GOALS.MONITOR_INVESTMENTS) ||
          selectedGoals.includes(GOALS.OPTIMIZE_STRATEGY)) &&
        !selectedGoals.includes(GOALS.CONFIDENT_RETIREMENT) &&
        !selectedGoals.includes(GOALS.PLAN_LIFE_EVENTS)
      ) {
        analyticsData.CashFlowTile = false;
        analyticsData.InvestmentCheckupTile = true;
        setTiles([
          INVESTMENT_CHECKUP,
          PORTFOLIO,
          NET_WORTH,
          RETIREMENT_PLANNER,
        ]);
      } else if (
        selectedGoals.includes(GOALS.SET_SAVINGS_GOALS) &&
        !selectedGoals.includes(GOALS.MONITOR_INVESTMENTS) &&
        !selectedGoals.includes(GOALS.OPTIMIZE_STRATEGY) &&
        !selectedGoals.includes(GOALS.CONFIDENT_RETIREMENT) &&
        !selectedGoals.includes(GOALS.PLAN_LIFE_EVENTS)
      ) {
        setTiles([CASH_FLOW, NET_WORTH, PORTFOLIO, RETIREMENT_PLANNER]);
      } else if (
        (selectedGoals.includes(GOALS.UNDERSTAND_NET_WORTH) || otherGoal) &&
        !selectedGoals.includes(GOALS.SET_SAVINGS_GOALS) &&
        !selectedGoals.includes(GOALS.MONITOR_INVESTMENTS) &&
        !selectedGoals.includes(GOALS.OPTIMIZE_STRATEGY) &&
        !selectedGoals.includes(GOALS.CONFIDENT_RETIREMENT) &&
        !selectedGoals.includes(GOALS.PLAN_LIFE_EVENTS)
      ) {
        setTiles([NET_WORTH, CASH_FLOW, PORTFOLIO, RETIREMENT_PLANNER]);
      }
    };

    updateTiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [tiles, analyticsData];
};
