import PropTypes from "prop-types";
import React from "react";
import { promisify } from "utils/service";
import Services from "services";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import SweepProgramDetails from "components/hysa/SweepProgramDetails";

export default class SweepProgramDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.handleFailure = this.handleFailure.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    this.cancelableService = makeCancelablePromise(
      this.props.fetchSweepDetails({ userAccountId: this.props.userAccountId })
    );
    this.cancelableService.promise.then(this.handleSuccess, this.handleFailure);
  }

  handleSuccess({ banks, balanceDate, totalBalance }) {
    this.setState({
      loading: false,
      errors: undefined,
      banks,
      balanceDate,
      totalBalance,
    });
  }

  handleFailure(errors) {
    if (this.cancelableService.isCanceled()) {
      return;
    }

    this.setState({
      loading: false,
      errors: errors,
    });
  }

  componentWillUnmount() {
    if (this.cancelableService) {
      this.cancelableService.cancel();
    }
  }

  render() {
    const { errors, loading, banks, balanceDate, totalBalance } = this.state;
    return (
      <SweepProgramDetails
        errors={errors}
        loading={loading}
        banks={banks}
        balanceDate={balanceDate}
        total={totalBalance}
        userAccountId={this.props.userAccountId}
        cashAccountData={this.props.cashAccountData}
        isOwner={this.props.isOwner}
      />
    );
  }
}

SweepProgramDetailsContainer.propTypes = {
  userAccountId: PropTypes.number.isRequired,
  fetchSweepDetails: PropTypes.func,
  cashAccountData: PropTypes.shape({
    formattedBalance: PropTypes.string,
    isClosed: PropTypes.string,
  }),
  isOwner: PropTypes.bool,
};

SweepProgramDetailsContainer.defaultProps = {
  fetchSweepDetails: promisify(Services.PCBAccount.sweepDetails),
  cashAccountData: {},
  isOwner: false,
};
