/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import { promisify } from "utils/service";
import Loading from "components/common/loading/Loading";
import parseResponseErrors from "libs/pcap/utils/response";
import { trackEvent } from "components/common/ComponentAnalytics";
import Analytics from "analytics";
import { map, findWhere, filter } from "underscore";
import $ from "jquery";
import objectPath from "object-path";
import { isEmpowerPaeMode } from "views/modules/sidebar/utils/accountUtils";

const COMPONENT_NAME = "insights";
const COOKIE_IMPRESSED_INSIGHTS_LIFETIME_IN_DAYS = 1;
const COOKIE_IMPRESSED_INSIGHTS = "_insights";
const MAX_NUMBER_OF_INSIGHTS = 5;

export default class InsightsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: null,
      insights: [],
      activeIndex: 0,
      activeIcon: 0,
      hotjarActivated: false,
    };
    this.shiftIndex = this.shiftIndex.bind(this);
    this.handleWidgetException = this.handleWidgetException.bind(this);
  }

  markedAsViewed(id) {
    if (!isEmpowerPaeMode()) {
      Services.UserMessages.updateV2({
        userMessageIds: "[" + id + "]",
        actions: '["markViewed"]',
      });
    }
  }

  markFirstAsViewed(id) {
    if (
      this.state.insights[0].template !== "NON_CLIENT_SCORECARD_INSIGHT" &&
      this.state.insights[0].template !== "MY_STRATEGY_INSIGHT"
    ) {
      this.markedAsViewed(id);
    }
  }

  markedAsImpressed(id) {
    const cookieString = $.pcap.getCookie(COOKIE_IMPRESSED_INSIGHTS);
    const trackedInsights = cookieString ? JSON.parse(atob(cookieString)) : [];
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (!isEmpowerPaeMode()) {
      if (trackedInsights.indexOf(window.csrf) === -1) {
        trackedInsights.push(window.csrf);
        $.pcap.setCookie(
          COOKIE_IMPRESSED_INSIGHTS,
          btoa(JSON.stringify(trackedInsights)),
          COOKIE_IMPRESSED_INSIGHTS_LIFETIME_IN_DAYS
        );
        Services.UserMessages.updateV2({
          userMessageIds: "[" + id + "]",
          actions: '["markImpressed"]',
        });
      }
    }
  }

  componentDidMount() {
    const { fetchMessages, fetchUIPreferences } = this.props;
    Promise.all([fetchMessages(), fetchUIPreferences()])
      .then(([insightsResponse, preferencesResponse]) => {
        this.sortInsightsFromMessages(insightsResponse.userMessages);
        this.setState({
          isAddFundsEligible: preferencesResponse?.features?.ADD_FUNDS_ELIGIBLE,
        });
      })
      .catch((errors) => {
        this.setState({ loading: false });
        Analytics.sendEngineeringEvent(
          "Error",
          "Insights: Services.Advisor.get: " + errors
        );
      });
  }

  sortInsightsFromMessages(allMessages) {
    Services.UserMessages.getInsights((error, response) => {
      const errors = parseResponseErrors(error, response);
      if (!errors && response && response.spData.insights) {
        let insights = map(response.spData.insights, (insight) => {
          return findWhere(allMessages, { userMessageId: insight });
        });
        insights = filter(insights, function (insight) {
          const displayLocations = objectPath.get(insight, "displayLocations");
          const isSidebarNotification =
            displayLocations &&
            displayLocations.reduce((acc, el) => {
              if (acc === true) {
                return true;
              }
              if (el.indexOf("sidebar") > 0) {
                acc = true;
              }
              return acc;
            }, false);
          return insight !== undefined && !isSidebarNotification;
        });
        this.setState({
          insights,
          firstInsightId:
            insights.length > 0 ? insights[0].userMessageId : null,
          loading: false,
        });
      } else {
        Analytics.sendEngineeringEvent(
          "Error",
          "Insights: Services.UserMessages.getInsights: " + errors
        );
      }
    });
  }

  renderToggler() {
    const icon = "dot";
    let togglers = map(this.state.insights, (message, i) => {
      return (
        <a
          key={`toggler-${i}`}
          className={`js-insight-toggler insight__button qa-icon-lightbulb icon-${icon}${
            i === this.state.activeIcon ? ` icon-${icon}--lit` : ""
          }`}
          onClick={() => {
            this.setState({
              activeIndex: i,
              activeIcon: i,
            });
            trackEvent(COMPONENT_NAME, "Click on Insight Carousel");
            if (this.state.firstInsightId !== null) {
              this.markFirstAsViewed(this.state.firstInsightId);
            }
          }}
        />
      );
    });

    if (!this.props.isEmpower) {
      const togglersLength = togglers.length;
      if (togglersLength < MAX_NUMBER_OF_INSIGHTS) {
        togglers[togglersLength] = (
          <a
            key="blog-toggler"
            className={`js-insight-toggler insight__button qa-icon-lightbulb icon-${icon}${
              togglersLength === this.state.activeIcon
                ? ` icon-${icon}--lit`
                : ""
            }`}
            onClick={() => {
              if (this.state.firstInsightId !== null) {
                this.markFirstAsViewed(this.state.firstInsightId);
              }
              trackEvent(COMPONENT_NAME, "Click on Insight Carousel");
              this.setState({
                activeIndex: togglersLength,
                activeIcon: togglersLength,
              });
            }}
          />
        );
      }
    }

    return togglers;
  }

  hotjarTracking(activated) {
    if (!activated) {
      this.setState(
        {
          hotjarActivated: true,
        },
        () => {
          if (typeof window.hj !== "undefined") {
            window.hj("trigger", "js-hj-insightswidget");
            window.hj("tagRecording", ["Insight Widget", window.userGuid]);
          }
        }
      );
    }
  }

  shiftIndex(direction) {
    const togglers = document.getElementsByClassName("js-insight-toggler");

    const shift =
      direction === "DECREASE"
        ? this.state.activeIndex - 1
        : this.state.activeIndex + 1;
    if (togglers[shift]) {
      togglers[shift].click();
    }
  }

  handleWidgetException(message, error, info) {
    const currentActiveIndex = this.state.activeIndex;
    const insightsWithErrantWidgetRemoved = this.state.insights.filter(
      (insight) => insight.userMessageId !== message.userMessageId
    );
    this.setState(
      {
        insights: insightsWithErrantWidgetRemoved,
        firstInsightId:
          insightsWithErrantWidgetRemoved.length > 0
            ? insightsWithErrantWidgetRemoved[0].userMessageId
            : null,
        activeIndex: currentActiveIndex - 1, // Need to update activeIndex for the replacement widget to render right away
      },
      () => {
        this.setState({ activeIndex: currentActiveIndex }); // Revert back to original active index
      }
    );
    trackEvent(COMPONENT_NAME, "Insight Widget Silently Failed to Load", {
      widgetTemplate: message.template,
      widgetError: error && error.stack,
      widgetInfo: JSON.stringify(info),
    });
  }

  renderZeroState() {
    return null;
  }

  render() {
    const { createInsights, isEmpower } = this.props;
    const insights = createInsights(
      this.state.insights,
      this.markedAsViewed,
      this.markedAsImpressed,
      this.handleWidgetException,
      this.state.isAddFundsEligible
    );
    const toggler = this.renderToggler();

    if (this.state.loading) {
      return <Loading />;
    }
    const Toggler = () => {
      const app = "emp";
      return insights.length > 1 ? (
        <div className={`insight__button-container emp-pagination__container`}>
          <button
            type="button"
            className={`insight__pagination-button pc-pagination__button ${app}-pagination__button--back${
              insights.length === 0 ? " hidden" : ""
            }`}
            disabled={this.state.activeIndex === 0}
            onClick={() => this.shiftIndex("DECREASE")}
          />
          {isEmpower ? <div>{toggler}</div> : toggler}
          <button
            type="button"
            className={`insight__pagination-button pc-pagination__button ${app}-pagination__button--forward${
              insights.length === 0 ? " hidden" : ""
            }`}
            disabled={
              this.state.activeIndex ===
              document.getElementsByClassName("js-insight-toggler").length - 1
            }
            onClick={() => this.shiftIndex("INCREASE")}
          />
        </div>
      ) : null;
    };

    return (
      <div
        onMouseOver={() => this.hotjarTracking(this.state.hotjarActivated)}
        className={"insight__container"}
      >
        {insights.length
          ? insights[this.state.activeIndex]
          : this.renderZeroState()}
        <Toggler />
      </div>
    );
  }
}

InsightsContainer.defaultProps = {
  isEmpower: false,
  fetchMessages: promisify(Services.UserMessages.getV2),
  fetchUIPreferences: promisify(Services.Profile.getUIPreferences),
};

InsightsContainer.propTypes = {
  isEmpower: PropTypes.bool,
  fetchMessages: PropTypes.func,
  fetchUIPreferences: PropTypes.func,
  createInsights: PropTypes.func.isRequired,
};
