import PropTypes from "prop-types";
import React from "react";
import DocumentVaultDeleteConfirm from "components/documentVault/DocumentVaultDeleteConfirm";
import Modal from "components/modal/Modal";

const DocumentVaultDeleteConfirmModal = ({
  onCancel,
  onDelete,
  filename,
  isOpen,
}) => {
  return (
    <Modal
      componentName="DocumentVaultDeleteConfirmModal"
      title="Delete Document"
      isOpen={isOpen}
      onClosed={onCancel}
      className="pc-modal pc-modal--small document-vault-delete-modal qa-document-vault-delete-modal"
      shouldCloseOnOverlayClick={false}
    >
      <DocumentVaultDeleteConfirm
        onCancel={onCancel}
        onDelete={onDelete}
        filename={filename}
      />
    </Modal>
  );
};

DocumentVaultDeleteConfirmModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  filename: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
};

DocumentVaultDeleteConfirmModal.defaultProps = {
  filename: "",
};

export default DocumentVaultDeleteConfirmModal;
