import Pinger from "pinger";
import Services from "services";
import moment from "moment";
import "registry";
import { getInstance as getRouterInstance } from "router";
import "common/keepAlive";

var RELATIVE_TIME_THRESHOLD = 24;
moment.locale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "Just now",
    m: "a min ago",
    mm: "%d mins ago",
    h: "1 hr ago",
    hh: "%d hrs ago",
    d: "a day ago",
    dd: "%d days ago",
    M: "a month ago",
    MM: "%d months ago",
    y: "a year ago",
    yy: "%d years ago",
  },
});
moment.relativeTimeThreshold("h", RELATIVE_TIME_THRESHOLD);

window.PCAP = window.PCAP || {};
window.PCAP.skipFirstUse = false;

window.pinger = new Pinger({ services: Services }).ping();

// TODO remove global references to AppRouter
// We reference the global router from many views to remove the loading indicator when the view finishes loading.
// Since the views themselves are imported and instantiated by the router, there is a cyclical dependency when
// we try to import the router instance within the views.
// The solution is to trigger `rendered` event when the view finishes loading and listen to that event in the router
// to remove the preloader.
window.AppRouter = getRouterInstance();
window.AppRouter.start();
