/* eslint-disable sonarjs/no-duplicate-string */
import $ from "jquery";
import _ from "underscore";
import Services from "services";
import PERSONALCAPITAL from "PERSONALCAPITAL";
import BaseDetailsView from "views/components/accountDetails/baseDetailsView";
import ManualPortfolioTemplate2 from "templates/accountDetails/manualPortfolio2.html";
import dollarAndCentsAmount from "templates/helpers/dollarAndCentsAmount";
import React from "react";
import ReactDOM from "react-dom";
import Recommendation401kContainer from "components/AccountDetails/Recommendation401k/Recommendation401kContainer";
import ClientRecommendation401kContainer from "components/AccountDetails/Recommendation401k/ClientRecommendation401kContainer";
import shouldShowRecommendationTab from "views/components/accountDetails/shouldShowRecommendationTab";
import mixpanel from "../../../libs/pcap/utils/mixpanel";
import { isEmpowerPrivilegedMode } from "../../modules/sidebar/utils/accountUtils";

var MAX_LENGTH_FIRM_NAME = 45;
var MAX_LENGTH_NAME = 40;
const MARGIN_RIGHT_LABELS_X = 34;

var ManualPortfolioView = BaseDetailsView.extend({
  events: function () {
    return _.extend({}, BaseDetailsView.prototype.events, {
      "click .js-edit-account-cash-holding-button": "onEditCashHoldingClick",
      "click .js-cancel-edit-account-cash-holding-button":
        "onCancelCashHoldingClick",
      "submit .js-manual-portfolio-cash-form": "onCashHoldingSubmit",
    });
  },

  initialize: function (options) {
    this.hasTabs = shouldShowRecommendationTab(this.model, options);
    BaseDetailsView.prototype.initialize.apply(this, arguments);
    this.getBalanceHistory(true);
    this.getHoldings();
    mixpanel.trackEvent("View Account Details Investment Manual Portfolio", {
      component: "manualPortfolioView",
    });
  },
  setTransHolding: function () {
    PERSONALCAPITAL.set("datagridTab", "holdings");
  },
  render: function () {
    var data = this.model.toJSON();
    data.cappedFirmName = this.capStringLength(
      data.firmName,
      MAX_LENGTH_FIRM_NAME
    );
    data.cappedName = this.capStringLength(data.name, MAX_LENGTH_NAME);

    data.tabId = this.options.tabId = this.options.tabId || "holdings";
    data.showRefetchTransaction = this.options.showRefetchTransaction;
    data.isRefetchTransactionEligible =
      this.options.isRefetchTransactionEligible;
    this.$el.html(ManualPortfolioTemplate2(data));
    BaseDetailsView.prototype.render.call(this);

    this.$(".js-manual-account-nav").toggleClass("is-hidden", !this.hasTabs);

    this.$(".qa-done-edit-account-cash-holding-button").toggleClass(
      "disabled",
      Boolean(isEmpowerPrivilegedMode())
    );

    if (data.tabId === "recommendation") {
      this.showRecommendation();
    } else {
      this.showHoldings();
    }

    return this;
  },

  showRecommendation: function () {
    this.$(".visualization, .nav-secondary--feature-controls").addClass(
      "is-hidden"
    );
    this.$(".js-account-details-tabs").removeClass("is-active");
    this.$(".js-account-details-tabs.recommendationTab").addClass("is-active");

    this.recommendationShell = this.$(".js-recommendation-shell").get(0);
    $(this.recommendationShell).removeClass("is-hidden");
    ReactDOM.render(
      this.options.isUserClient ? (
        <ClientRecommendation401kContainer
          userAccountId={parseInt(this.options.userAccountId, 10)}
        />
      ) : (
        <Recommendation401kContainer
          isInvestmentCheckupV2={this.options.isInvestmentCheckupV2}
          userAccountId={parseInt(this.options.userAccountId, 10)}
        />
      ),
      this.recommendationShell
    );
  },
  showHoldings: function () {
    this.options.tabId = "holdings";
    this.$(".js-recommendation-shell").addClass("is-hidden");

    this.$(".visualization, .nav-secondary--feature-controls").removeClass(
      "is-hidden"
    );

    this.$(".js-account-details-tabs").removeClass("is-active");
    this.$(".js-account-details-tabs.holdingsTab").addClass("is-active");
  },
  changeAccount: function (account) {
    BaseDetailsView.prototype.changeAccount.call(this, account);
    this.getBalanceHistory();
    this.getHoldings();
  },

  /*
   * If only account info has been updated, then only update specific DOM elements
   */
  updateContent: function () {
    BaseDetailsView.prototype.updateContent.call(this);
    var htmlText = dollarAndCentsAmount(
      this.model.get("balance"),
      true,
      true,
      this.model.get("isLiability")
    );
    this.$el.find(".js-manual-portfolio-balance").text(htmlText);
    this.$el
      .find(".js-manual-portfolio-account-number")
      .text(this.model.get("accountNumber"));
  },

  updateView: function (newViewState) {
    BaseDetailsView.prototype.updateView.call(this, newViewState);
    if (newViewState.tabId === "recommendation") {
      this.showRecommendation();
    }
  },

  /*
   * Override method defined in baseDetailsView
   */
  onEditableClick: function (event) {
    var targetElement = $(event.currentTarget),
      className = targetElement.attr("class").split(" ")[0],
      inputId = "#" + className + "Input";

    if (inputId === "#accountNumberInput" || inputId === "#cashBalanceInput") {
      // If there's an input element that is still active, return it to non-edit mode
      if (
        !_.isUndefined(this.previousInputID) &&
        !_.isNull(this.previousInputID)
      ) {
        var previousInputElement = this.$el.find(this.previousInputID);
        previousInputElement.val(this.previousInputData);
        previousInputElement.parent().parent().hide();
      }

      // track current input element so it can be used
      // to reset the previous input element
      this.previousInputID = inputId;
      this.previousInputData = this.$el.find(inputId).val();

      // Display editor
      var editContainer = this.$el.find(
        '[class~="editContainer"][class~=' + className + "]"
      );
      var offset = targetElement.offset();
      if (inputId !== "#cashBalanceInput") {
        offset.left -= 7;
      }
      offset.top -= 9;
      editContainer.show();
      editContainer.offset(offset);
    } else {
      BaseDetailsView.prototype.onEditableClick.call(this, event);
    }
  },

  onEditCashHoldingClick: function () {
    this.$(".js-manual-portfolio-cash-form").addClass(
      "manual-portfolio-cash__form--active"
    );
    this.$(".js-edit-account-cash-holding-input")
      .val(this.cashHolding.quantity)
      .focus();
  },

  onCancelCashHoldingClick: function () {
    this.$(".js-manual-portfolio-cash-form").removeClass(
      "manual-portfolio-cash__form--active"
    );
  },

  onCashHoldingSubmit: function (ev) {
    ev.preventDefault();
    var form = $(ev.target);
    var balance = form.find(".js-edit-account-cash-holding-input").val();
    this.updateCashBalance(balance);
    this.$(".js-manual-portfolio-cash-balance").html(
      dollarAndCentsAmount(balance, true, true)
    );
    form.removeClass("manual-portfolio-cash__form--active");
  },

  /*
   * Override method defined in baseDetailsView
   */
  onSaveClick: function () {
    if (
      typeof this.previousInputID !== "undefined" &&
      this.previousInputID !== null
    ) {
      var propertyName = this.previousInputID.replace(/(#|Input)/g, ""),
        inputElement = this.$el.find(this.previousInputID),
        newText,
        maxStringLength,
        accountObject = { accountId: this.model.get("accountId") },
        isManual = this.model.get("isManual");

      if (propertyName === "cashBalance") {
        var balance = inputElement.val();
        this.updateCashBalance(balance);
        newText = dollarAndCentsAmount(balance, true);
      } else if (propertyName === "accountNumber") {
        accountObject.isManualPortfolio = this.model.get("isManualPortfolio");
        accountObject.accountNumber = inputElement.val();

        if (isManual) {
          Services.Accounts.updateManual(accountObject);
        } else {
          Services.Accounts.update(accountObject);
        }

        newText = inputElement.val();
      } else {
        if (propertyName === "firmName") {
          maxStringLength = MAX_LENGTH_FIRM_NAME;
        } else if (propertyName === "name") {
          maxStringLength = MAX_LENGTH_NAME;
        }

        // create generic object to pass as an argument to API call
        accountObject.isManualPortfolio = this.model.get("isManualPortfolio");
        accountObject[propertyName] = inputElement.val();

        Services.Accounts.update(accountObject);

        newText = this.capStringLength(inputElement.val(), maxStringLength);
      }

      var htmlText = newText + '<span class="editIcon"></span>';
      this.$el.find(".editable." + propertyName).html(htmlText);

      inputElement.parent().parent().hide();

      this.previousInputID = null;
      this.previousInputData = null;
    }
  },

  updateCashBalance: function (balance) {
    var holdingObject = { userAccountId: this.cashHolding.userAccountId };
    holdingObject.ticker = this.cashHolding.ticker;
    holdingObject.cusip = this.cashHolding.cusip;
    holdingObject.description = this.cashHolding.description;
    holdingObject.quantity = balance;
    holdingObject.price = this.cashHolding.price;
    holdingObject.priceSource = this.cashHolding.priceSource;
    holdingObject.sourceAssetId = this.cashHolding.sourceAssetId;
    Services.Holdings.update(holdingObject);
  },

  getHistory: function () {
    this.getBalanceHistory();
    this.getHoldings();
  },
  onBalanceFetched: function (err, response) {
    if (err === null) {
      BaseDetailsView.prototype.onBalanceFetched.call(this, err, response);

      const balanceChartEl = this.$el.find("#balanceChart");
      const originalWidth = balanceChartEl.width();
      this.renderBalanceChart();
      if (balanceChartEl && originalWidth) {
        balanceChartEl.find("svg").css({ width: originalWidth });
        balanceChartEl.find(".startDate")?.css({ left: 0 });
        balanceChartEl
          .find(".endDate")
          ?.css({ "margin-right": MARGIN_RIGHT_LABELS_X });
      }
      window.dispatchEvent(new CustomEvent("pageloaded"));
    }
  },
  getHoldings: function () {
    if (this.getHoldingsWatchId) {
      Services.Holdings.get.unwatch(this.getHoldingsWatchId);
    }

    this.getHoldingsWatchId = Services.Holdings.get.watch(
      { userAccountIds: JSON.stringify([this.model.get("userAccountId")]) },
      this.onHoldingsFetched,
      this
    );
  },
  onHoldingsFetched: function (err, response) {
    if (err === null) {
      if (
        typeof response == "undefined" ||
        typeof response.spData == "undefined"
      ) {
        return;
      }

      this.holdingsData = _.filter(
        response.spData.holdings,
        this.filterOutCashHolding,
        this
      );

      // update cashBalance
      var htmlText = dollarAndCentsAmount(
        this.cashHolding.quantity,
        true,
        true
      );
      this.$el.find(".js-manual-portfolio-cash-balance").html(htmlText);
      this.$el.find("#cashBalanceInput").val(this.cashHolding.quantity);
    }
  },
  filterOutCashHolding: function (holding) {
    if (holding.holdingType === "Cash") {
      this.cashHolding = _.clone(holding);
    }

    return holding.holdingType !== "Cash";
  },
  displayChart: function () {
    this.options.tabId = "balance";

    if (this.hasTabs) {
      this.showHoldings();
    }
  },
  remove: function () {
    if (this.recommendationShell) {
      ReactDOM.unmountComponentAtNode(this.recommendationShell);
    }

    BaseDetailsView.prototype.remove.apply(this, arguments);
  },
});

export default ManualPortfolioView;
