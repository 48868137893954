var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " hidden ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <input\n        class=\"input input--full pc-u-pt- pc-u-pb- js-search-account-input qa-search-account-input is-disabled\"\n        type=\"text\"\n        placeholder=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isFirstUseScreen") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":16,"column":21},"end":{"line":16,"column":136}}})) != null ? stack1 : "")
    + "\"\n      />\n      <span class=\"reset is-disabled\">X</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "Search Wells Fargo, Fidelity, American Express...";
},"6":function(container,depth0,helpers,partials,data) {
    return "Enter your bank name or URL";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <input\n        class=\"input input--full pc-u-pt- pc-u-pb- js-search-account-input qa-search-account-input\"\n        type=\"text\"\n        placeholder=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isFirstUseScreen") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":23,"column":21},"end":{"line":23,"column":136}}})) != null ? stack1 : "")
    + "\"\n      />\n      <span class=\"reset\">X</span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "            <p class=\"cash-marketing-info\" data-testid=\"qa-cash-marketing-info\">\n              Fund your new Cash account by linking at least one checking, savings, or money market account. Manually added accounts don't qualify. If you have any issues, reach out to <a href=\"mailto:cash@personalwealth.empower.com\">cash@personalwealth.empower.com</a>.\n            </p>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return " u-invisible";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>\n          Add other assets such as property or stock options to get a more\n          complete snapshot of your finances.\n        </p>\n        <div class=\"actions threeColumns\">\n          <div class=\"table-cell first\">\n            <a\n              class=\"customAccount pc-btn pc-u-ph\"\n              href=\"#\"\n              data-custom_type=\"Home\"\n              >Add Home Value\n              <img\n                class=\"icon-zillow-logo\"\n                src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"staticImageBaseUrl") || (depth0 != null ? lookupProperty(depth0,"staticImageBaseUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"staticImageBaseUrl","hash":{},"data":data,"loc":{"start":{"line":79,"column":21},"end":{"line":79,"column":45}}}) : helper)))
    + "linkAccount/zillow-logo.png\"\n            /></a>\n          </div>\n          <div class=\"table-cell\">\n            <a\n              class=\"customAccount pc-btn pc-u-ph\"\n              href=\"#\"\n              data-custom_type=\"Manual Investment Holdings\"\n              >Add Portfolio</a\n            >\n          </div>\n          <div class=\"table-cell last\">\n            <a\n              class=\"allCustomAccountsButton pc-btn pc-u-ph last\"\n              href=\"#\"\n              >More...</a\n            >\n          </div>\n        </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"backLinkAnAccount\">\n          <div class=\"threeColumns\">\n            <div class=\"table-cell first\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmulatorMode") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":102,"column":14},"end":{"line":115,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n        </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "              <a\n                class=\"is-disabled backLinkAnAccountClick pc-btn pc-u-ph\"\n                href=\"#\"\n                aria-disabled=\"true\"\n                >Back\n              </a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "              <a\n                class=\"backLinkAnAccountClick pc-btn pc-u-ph\"\n                href=\"#\"\n                >Back\n              </a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "          Can't find your account? Link it manually.\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "          Can't find your account? Link it manually or submit request to get\n          support for your financial institution.\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"table-cell last\">\n            <a\n              class=\"showSendRequestForm pc-btn pc-u-ph+\"\n              href=\"#\"\n              >Send Request</a\n            >\n          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tier0\">\n  <div class=\"wrap\">\n    <div class=\"progressBar hidden\">\n      <span class=\"progressMessage\">Connecting your account(s)</span>\n      <span class=\"continueToFeature hidden\">Account(s) connected!</span>\n    </div>\n  </div>\n</div>\n<div class=\"tier1 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isManualOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":53}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"wrap\">\n    <div class=\"searchAccountsWrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmulatorMode") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":26,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>\n<div class=\"tier2\">\n  <div class=\"wrap\">\n    <div class=\"suggestions "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isManualOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":32,"column":63}}})) != null ? stack1 : "")
    + " slideshow\">\n      <div class=\"scroller\">\n        <div class=\"suggestedAndPopularSites\"></div>\n        <div class=\"popularSitesOnly qa-popular-sites-only\">\n          <p class=\"suggestionsCopy qa-suggestion-copy\">\n            Or select from these financial institutions:\n          </p>\n          <div class=\"container\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFromCashMarketing") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":44,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n    <div class=\"searchResults "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isManualOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":30},"end":{"line":48,"column":65}}})) != null ? stack1 : "")
    + " hidden\">\n      <div class=\"scroller\"></div>\n    </div>\n    <div class=\"accountAuthentication hidden\"></div>\n    <div class=\"accountSubmitted qa-account-submitted hidden\"></div>\n    <div class=\"customAccounts "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"unlesss") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":31},"end":{"line":53,"column":61}}})) != null ? stack1 : "")
    + " \">\n      <h2 class=\"title\">Select Account Type</h2>\n      <div class=\"list scroller\"></div>\n    </div>\n    <div class=\"customAccountForm hidden\"></div>\n    <div class=\"sendRequestForm hidden\"></div>\n    <div class=\"sendRequestSubmitted hidden\"></div>\n  </div>\n</div>\n<div class=\"tier3"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFirstUseScreen") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":17},"end":{"line":62,"column":60}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"wrap "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isManualOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":19},"end":{"line":63,"column":54}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"customAccountsHandle hidden qa-custom-account-handle\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmulatorMode") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":65,"column":6},"end":{"line":119,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"cantFindAccountHandle\">\n      <p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":123,"column":8},"end":{"line":128,"column":15}}})) != null ? stack1 : "")
    + "      </p>\n      <div class=\"actions twoColumns\">\n        <div class=\"table-cell first\">\n          <a\n            class=\"allCustomAccountsButton pc-btn pc-u-ph+\"\n            href=\"#\"\n            >Add Manual Account</a\n          >\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"unless","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":138,"column":8},"end":{"line":146,"column":19}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"backHandle\">\n      <a\n        class=\"back pc-btn pc-u-ph+\"\n        href=\"#\"\n        >Back</a\n      >\n    </div>\n  </div>\n</div>\n";
},"useData":true});