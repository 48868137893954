import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import SelectPersonInput, {
  getDisabledFieldsConfigForPcapUser,
} from "components/common/SelectPersonInput";
import { STATUS_PCAP_USER } from "utils/delegateUser";

class InviteSecondaryAccountOwner extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model || {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const { model } = this.state;
    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.onInvite(model.person);
    }
    event.preventDefault();
  }

  getDisabledFieldsConfig() {
    const { isDelegateUser, delegateUsers } = this.props;
    const { person } = this.state.model;

    if (isDelegateUser) {
      return true;
    }

    if (
      person &&
      delegateUsers.some(
        (d) => d.personId === person.id && STATUS_PCAP_USER.includes(d.status)
      )
    ) {
      return getDisabledFieldsConfigForPcapUser("person");
    }
  }

  render() {
    const {
      loading,
      errors,
      onCancel,
      selectOptions,
      household,
      consentLabel,
      selectPersonValidator,
      selectDropdownHeading,
      selectDropdownPlaceholder,
      isDelegateUser,
    } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <LoadingOverlay active={loading} />
        <Message className="pc-u-mb" severity="error" messages={errors} />
        <SelectPersonInput
          name="person"
          refFunc={this.storeInputRef}
          onChange={this.handleInputChange}
          household={household}
          loading={loading}
          selectOptions={selectOptions}
          value={this.state.model.person}
          validator={selectPersonValidator}
          selectDropdownHeading={selectDropdownHeading}
          selectDropdownPlaceholder={selectDropdownPlaceholder}
          consentLabel={consentLabel}
          showPersonDropdown={!isDelegateUser}
          showRelationship={!isDelegateUser}
          disabled={this.getDisabledFieldsConfig()}
        />
        {!loading && (
          <div className="u-text-right pc-u-mt">
            <button
              type="button"
              className="pc-btn js-action-cancel"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button type="submit" className="pc-btn pc-btn--primary">
              Send Invite
            </button>
          </div>
        )}
      </form>
    );
  }
}

InviteSecondaryAccountOwner.propTypes = {
  model: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  selectOptions: PropTypes.array,
  household: PropTypes.array,
  consentLabel: PropTypes.node,
  selectPersonValidator: PropTypes.object,
  selectDropdownHeading: PropTypes.node,
  selectDropdownPlaceholder: PropTypes.string,
  isDelegateUser: PropTypes.bool,
  delegateUsers: PropTypes.array,
};

InviteSecondaryAccountOwner.defaultProps = {
  loading: false,
  errors: undefined,
  selectOptions: [],
  household: [],
  consentLabel: undefined,
  selectPersonValidator: undefined,
  selectDropdownHeading: undefined,
  selectDropdownPlaceholder: undefined,
  isDelegateUser: false,
  delegateUsers: [],
};

export default InviteSecondaryAccountOwner;
