import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import Checkbox from "components/common/form/Checkbox/Checkbox";
import { hasPrintableCharacters } from "common/validators/functions.js";

const StockOptionsDetails = ({
  model,
  schema,
  onInputChange,
  storeInputRef,
}) => {
  const { properties } = schema;
  return (
    <div>
      <div className="pc-layout">
        <div className="pc-form-group--small">
          <div className="pc-layout__item pc-u-1/3">
            <label className="pc-label--tiny">Company Name</label>
          </div>
          <div className="pc-layout__item pc-u-2/3">
            <Input
              className="input--small qa-name"
              type="text"
              name="name"
              placeholder="Firm, bank, or institution"
              sizeVariation="full"
              onChange={onInputChange}
              value={model.name}
              ref={storeInputRef}
              validator={properties.name}
              data-hj-masked
              ariaLabel="Company name (Firm, bank, or institution)"
            />
          </div>
        </div>
        <div className="pc-form-group--small">
          <div className="pc-layout__item pc-u-1/3">
            <label className="pc-label--tiny">Stock Ticker</label>
          </div>
          <div className="pc-layout__item pc-u-2/3">
            <Input
              className="input--small qa-ticker"
              type="text"
              name="ticker"
              sizeVariation="full"
              placeholder="ex. GOOG, AAPL"
              value={model.ticker}
              ref={storeInputRef}
              onChange={onInputChange}
              validator={properties.ticker}
              data-hj-masked
              ariaLabel="Stock ticker"
            />
          </div>
        </div>
        <div className="pc-form-group--small">
          <div className="pc-layout__item">
            <Checkbox
              className="input--tiny qa-track-unvested-shares"
              name="shouldOnlyTrackUnvestedShares"
              onChange={onInputChange}
              checked={model.shouldOnlyTrackUnvestedShares}
              ref={storeInputRef}
              label="Only track unvested shares"
              data-hj-masked
            />
          </div>
        </div>
        <div className="pc-form-group--small">
          <div className="pc-layout__item">
            <Checkbox
              className="input--tiny qa-stop-vesting"
              name="shouldStopVesting"
              checked={model.shouldStopVesting}
              ref={storeInputRef}
              onChange={onInputChange}
              label="Stop vesting"
              data-hj-masked
            />
          </div>
        </div>
      </div>
    </div>
  );
};

StockOptionsDetails.defaultProps = {
  schema: {
    type: "object",
    properties: {
      name: {
        allowEmpty: false,
        required: true,
        type: "string",
        conform: hasPrintableCharacters,
        messages: {
          conform: "must not be empty",
        },
      },
      ticker: {
        allowEmpty: true,
        required: false,
        type: "string",
        conform: hasPrintableCharacters,
        messages: {
          conform: "must not be empty",
        },
      },
    },
  },
};

StockOptionsDetails.propTypes = {
  model: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  storeInputRef: PropTypes.func.isRequired,
  schema: PropTypes.object,
};

export default StockOptionsDetails;
