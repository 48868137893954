import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import Table from "libs/pcap/table/Table";
import { formatCurrency } from "libs/pcap/utils/format";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";

const COLUMNS_CONFIG = [
  {
    header: "Program Banks",
    name: "name",
    className: "centi table__column--clipped",
    headerClassName: "centi sweep-program-details__program-bank-column-heading",
    accessor: (d) => d.name,
    style: {
      width: "65%",
    },
  },
  {
    header: "",
    name: "balance",
    className: "centi table__column--right",
    headerClassName: "centi table__column--right",
    accessor: (d) => d.balance,
    formatter: formatCurrency,
    style: {
      width: "35%",
    },
  },
];

const SweepProgramDetails = (props) => {
  const { loading, errors, banks, userAccountId, cashAccountData, isOwner } =
    props;
  return (
    <>
      <Message className="pc-u-mb" severity="error" messages={errors} />
      <LoadingOverlay active={loading} />
      {!loading && (
        <>
          <Table data={banks} columns={COLUMNS_CONFIG} zeroState="No data." />
          <div className="table__row table__row--summary sweep-program-total">
            <div
              className="table__column centi table__column--clipped"
              style={{ width: "65%" }}
            >
              Total program balance
              <InfoTooltipIcon title="This is the aggregate principal balance representing your funds held in the Funding Account and your interests in the Program Accounts held in your Custodial Account." />
            </div>
            <div
              className="table__column centi table__column--right"
              style={{ width: "35%" }}
              data-testid="sweep-program-details-balance"
            >
              {cashAccountData.formattedBalance}
            </div>
          </div>
          <div className="pc-u-pt- u-text-right">
            {!cashAccountData.isClosed && isOwner && (
              <a
                href={`#/transfer-funds?transferType=1&to=${userAccountId}`}
                className="pc-btn"
                aria-label="Make a deposit"
                title="Make a deposit"
                data-testid="sweep-program-details-deposit-link"
                data-analytic-event="cash.make_a_deposit_menu.click"
              >
                Make a deposit
              </a>
            )}
          </div>
        </>
      )}
    </>
  );
};

SweepProgramDetails.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  banks: PropTypes.array,
  userAccountId: PropTypes.number,
  cashAccountData: PropTypes.object,
  isOwner: PropTypes.bool,
};

SweepProgramDetails.defaultProps = {
  loading: false,
  errors: undefined,
  banks: undefined,
  userAccountId: undefined,
  cashAccountData: {},
  isOwner: false,
};

export default SweepProgramDetails;
