import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import { noop } from "jquery";
import Mixpanel from "mixpanel";

export default class MadLibsPromptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
  }

  componentDidMount() {
    Mixpanel.trackEvent(`View ${this.props.componentName}`);
  }

  handleUpdate() {
    const { componentName, updateButtonLabel, onUpdate } = this.props;
    this.setState({
      showModal: false,
    });
    Mixpanel.trackEvent(`Click on ${componentName} ${updateButtonLabel}`);
    onUpdate();
  }

  handleSkip() {
    const { componentName, skipButtonLabel, onSkip } = this.props;
    this.setState({
      showModal: false,
    });
    Mixpanel.trackEvent(`Click on ${componentName} ${skipButtonLabel}`);
    onSkip();
  }

  render() {
    const { title, summary, updateButtonLabel, skipButtonLabel } = this.props;
    return (
      <Modal
        componentName="Madlibs Prompt"
        isOpen={this.state.showModal}
        className="pc-modal--smedium"
        showCloseButton={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="madlibs-prompt-modal__container">
          <h1 className="madlibs-prompt-modal__title u-text-center">{title}</h1>
          <p className="pc-u-mb">{summary}</p>
          <div className="u-text-center">
            <button
              type="button"
              className="pc-btn pc-btn--primary madlibs-prompt-modal__action first-use-questionnaire__form-submit-button js-change-btn"
              onClick={this.handleUpdate}
            >
              {updateButtonLabel}
            </button>
          </div>
          {skipButtonLabel && (
            <div className="u-text-center pc-u-mt-- madlibs-prompt-modal__skip-btn">
              <button
                type="submit"
                className="pc-btn pc-btn--link js-skip-btn milli first-use-questionnaire_mad-libs-prompt-link"
                onClick={this.handleSkip}
              >
                {skipButtonLabel}
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

MadLibsPromptModal.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  skipButtonLabel: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onSkip: PropTypes.func,
  componentName: PropTypes.string,
};

MadLibsPromptModal.defaultProps = {
  skipButtonLabel: undefined,
  onSkip: noop,
  componentName: "",
};
