import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import Loading from "components/common/loading/Loading";
import Message from "components/common/Message";
import { formatNumber, formatCurrency } from "libs/pcap/utils/format";
import WidgetZeroStateContainer from "components/dashboard/WidgetZeroStateContainer";
import SavingsProgressChart from "components/savingsPlanner/Retirement/SavingsProgressChart";
import FlexibleDollar from "components/common/FlexibleDollar";
import InfoTooltipIcon from "../common/InfoTooltipIcon/InfoTooltipIcon";
import { handleAmplitudeEvent } from "../../empower/utils/eventUtils";

const TOOLTIP_TEXT = "Your retirement saving goals";
const MONTHS_IN_1_YEAR = 12;
const TAKE_AWAY_ID = "takeaway";
const retirementWidgetClassName = "qa-retirement-summery-widget";
class RetirementSavingsSummary extends React.Component {
  constructor(props) {
    super(props);
    this.buildUnderArc = this.buildUnderArc.bind(this);
  }

  /**
   * @param {number} currentBalance - current retirement fund balance
   * @param {number} targetBalance - overall total balance
   * @param {date} date - optional date for ease of unit testing.
   * @return {string} a takeaway string
   */
  parseTakeaway(currentBalance, targetBalance, date = moment()) {
    const monthIndex = moment(date).month();
    const numRemainingMonths = MONTHS_IN_1_YEAR - monthIndex;

    // Not retired yet.
    const amountLeftToBeSaved = targetBalance - currentBalance;

    // If the user has hit his/her target!
    if (amountLeftToBeSaved < 0) {
      return (
        <div
          className="qa-retirement-savings-summary-saved-more"
          id={TAKE_AWAY_ID}
        >
          Good job! You&apos;ve hit your savings goal this year.
        </div>
      );
    }

    // If it's December, the last month of the year, tell him/her about how to hit their goal.
    if (numRemainingMonths === 1) {
      return (
        <div
          className="qa-retirement-savings-summary-last-month-of-year"
          id={TAKE_AWAY_ID}
        >
          Save ${formatNumber(Math.ceil(amountLeftToBeSaved), 0)} to hit your
          savings goal this year.
        </div>
      );
    }

    // Calculate how much the user should save per month for the rest of the year.
    let perMonthContributionToHitTarget = Math.ceil(
      amountLeftToBeSaved / numRemainingMonths
    );

    return (
      <div
        className="qa-retirement-savings-summary-should-save-more"
        id={TAKE_AWAY_ID}
      >
        Save ${formatNumber(perMonthContributionToHitTarget, 0)} per month to
        hit your savings goal this year.
      </div>
    );
  }

  buildUnderArc() {
    const showNegativeColor = this.props.currentBalance < 0;
    return (
      <div aria-hidden="true" className="savings-progress-chart__under-arc">
        <div
          className={`savings-progress-chart__current-savings qa-savings-progress-chart-current-savings ${
            showNegativeColor
              ? "savings-progress-chart__current-savings--negative"
              : ""
          }`}
        >
          <FlexibleDollar
            value={this.props.currentBalance}
            containerWidth={"100px"}
          />
        </div>
        <div className="milli qa-retirement-savings-target-balance">
          of {formatCurrency(this.props.targetBalance, 0)}
        </div>
      </div>
    );
  }

  handleTooltipClick = () => {
    const name = "Savings Planner";
    const element = "SELECT_BUTTON";
    const section = "Widget.toolTip";
    const payload = { widget: name };

    handleAmplitudeEvent(element, section, payload);
  };

  render() {
    const {
      loading,
      errors,
      currentBalance,
      targetBalance,
      annualSavingsNeededFrom,
      annualSavingsNeededTo,
      actions,
      excludeTakeaway,
      chartPaddingTop,
      chartPaddingBottom,
      arcWidth,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (errors) {
      return <Message severity="error" messages={this.props.errors} />;
    }

    if (!IS_EMPOWER && this.props.zeroState) {
      return (
        <WidgetZeroStateContainer
          message={this.props.zeroState}
          widgetName="Retirement Savings Summary"
        />
      );
    }

    const arcChartAriaLabel = `As of ${moment().format(
      "MMMM, D, YYYY"
    )}, you have saved ${formatCurrency(
      currentBalance,
      0
    )} out of the target retirement savings of ${formatCurrency(
      targetBalance,
      0
    )}.`;

    const takeaway = excludeTakeaway
      ? ""
      : this.parseTakeaway(currentBalance, targetBalance);
    const classNames = [
      "summary-widget qa-retirement-summary-widget retirement-savings-widget with-tooltip",
    ];
    if (this.props.className) {
      classNames.push(this.props.className);
    }

    const empowerSharedData = window.integratedSharedData;
    const tooltip =
      empowerSharedData?.translations?.get("zeroStateDashboard")?.savingsPlanner
        ?.tooltip;

    return (
      <section
        className={classNames.join(" ")}
        alt="Recommended monthly amount to reach savings goal."
      >
        <div className="summary-widget__header">
          <div
            className={`l-spaced l-spaced--flush zero-state__header-container ${retirementWidgetClassName}`}
          >
            {this.props.title}
            <InfoTooltipIcon
              title={tooltip || TOOLTIP_TEXT}
              container={false}
              onClick={this.handleTooltipClick}
              parentClassName={retirementWidgetClassName}
              tooltipProps={{
                trigger: "click",
                role: "alert",
              }}
            />
          </div>

          {annualSavingsNeededFrom > 0 && annualSavingsNeededTo > 0 && (
            <div className="l-spaced l-spaced--flush retirement-savings-summary__recommendation-text qa-retirement-savings-summary-recommendation-text">
              <div>Recommended</div>
              <div>
                {formatCurrency(annualSavingsNeededFrom, 0)}-
                {formatCurrency(annualSavingsNeededTo, 0)}/yr
              </div>
            </div>
          )}
        </div>
        <div className="summary-widget__body--align-center">
          <SavingsProgressChart
            currentSavings={currentBalance}
            savingsComponents={[{ value: currentBalance }]}
            targetSavings={targetBalance}
            dateFormat="M/D"
            onEditSavingsPlanClick={this.handleEditSavingsPlanClick}
            title={null}
            paddingTop={chartPaddingTop}
            paddingBottom={chartPaddingBottom}
            buildUnderArc={this.buildUnderArc}
            arcWidth={arcWidth}
            arcChartAriaLabel={arcChartAriaLabel}
            arcChartDescribedBy={TAKE_AWAY_ID}
          />
        </div>
        {takeaway && !excludeTakeaway && (
          <div
            className="pc-help-block pc-help-block--small js-retirement-savings-summary-takeaway retirement-savings-summary__takeaway"
            aria-hidden="true"
          >
            {takeaway}
          </div>
        )}
        {actions.length > 0 && (
          <div className="pc-u-pt-- l-spaced l-spaced--right qa-retirement-savings-summary-actions">
            {actions.map((act, i) => {
              const onClick = act.onClick ? { onClick: act.onClick } : {};
              return (
                <a key={i} href={act.url} className="pc-btn" {...onClick}>
                  {act.label}
                </a>
              );
            })}
          </div>
        )}
      </section>
    );
  }
}

RetirementSavingsSummary.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  currentBalance: PropTypes.number,
  targetBalance: PropTypes.number,
  zeroState: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  annualSavingsNeededFrom: PropTypes.number,
  annualSavingsNeededTo: PropTypes.number,
  actions: PropTypes.array,
  excludeTakeaway: PropTypes.bool,
  chartPaddingTop: PropTypes.number,
  chartPaddingBottom: PropTypes.number,
  arcWidth: PropTypes.number,
};

RetirementSavingsSummary.defaultProps = {
  loading: false,
  title: "",
  errors: null,
  currentBalance: null,
  targetBalance: null,
  zeroState: null,
  className: "",
  annualSavingsNeededFrom: 0,
  annualSavingsNeededTo: 0,
  actions: [],
  excludeTakeaway: false,
  chartPaddingTop: 65,
  chartPaddingBottom: 30,
  arcWidth: undefined,
};

export default RetirementSavingsSummary;
