import _ from "underscore";

var accountTypeMap = {
  BANK: "cash",
  INVESTMENT: "investment",
  CREDIT_CARD: "credit card",
  LOAN: "loan",
  MORTGAGE: "mortgage",
  MANUAL: "manual",
  RETIREMENT: "retirement",
};

export default function (accountTypes) {
  var ary = [];
  _.each(accountTypes, function (accountType) {
    ary.push(accountTypeMap[accountType]);
  });
  return ary.join(", ");
}
