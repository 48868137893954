import PropTypes from "prop-types";
import React from "react";
import EditTransactionImage from "components/common/EditTransactionImage";
import Modal from "components/modal/Modal";

const EditTransactionImageModal = (props) => {
  return (
    <Modal
      componentName="EditTransactionImageModal"
      title="Edit Image"
      isOpen={true}
      onClosed={props.onCancel}
      shouldCloseOnOverlayClick={false}
      className="pc-modal--huge"
    >
      <EditTransactionImage {...props} />
    </Modal>
  );
};

EditTransactionImageModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default EditTransactionImageModal;
