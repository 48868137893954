import BaseAccountSelector from "views/components/accountSelector/baseAccountSelectorView2";
import TaxableAccountSelectorView from "views/components/accountSelector/taxableAccountSelectorView2";
import TaxableAccountSelectorViewExcludeFeeX from "views/components/accountSelector/taxableAccountSelectorViewExcludeFeeX";
import EmpowerAccountSelectorView from "empower/components/accountSelectorView/accountSelectorView.empower";

var AccountSelectorView = function (params) {
  switch (params.groupBy) {
    case AccountSelectorView.GROUP_BY_TAXABLE_TYPE:
      return new TaxableAccountSelectorView(params);
    case AccountSelectorView.GROUP_BY_TAXABLE_TYPE_EXCLUDE_FEEX:
      return new TaxableAccountSelectorViewExcludeFeeX(params);
    case AccountSelectorView.GROUP_BY_PRODUCT_TYPE:
    default:
      return IS_EMPOWER
        ? new EmpowerAccountSelectorView(params)
        : new BaseAccountSelector(params);
  }
};

AccountSelectorView.ALL_ACCOUNTS = "all";
AccountSelectorView.GROUP_BY_PRODUCT_TYPE = "Group by productTypes";
AccountSelectorView.GROUP_BY_TAXABLE_TYPE = "Group by Taxable Type";
AccountSelectorView.GROUP_BY_TAXABLE_TYPE_EXCLUDE_FEEX =
  "Group by Taxable Type Exclude FeeX";

export default AccountSelectorView;
