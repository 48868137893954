import _ from "underscore";
import Box from "libs/pcap/utils/Box2";
import graphExtent from "libs/pcap/utils/graphExtent";
var GRIDLINE_COLOR = "rgba(0,0,0,0.4)";

const LABELS_MARGIN_RIGHT = 20;
const LABELS_MARGIN_RIGHT_OUTSIDE = 0;

export default function (min, max, params) {
  var overlay = new Box({
    height: "90%",
    width: params.showYLabelsOutside ? "106%" : "102%",
    widthPos: params.showYLabelsOutside ? "left" : "right",
    name: "overlay",
    drawMode: "none",
  });

  var extent = graphExtent(min, max);
  var zbe = extent.zeroBasedExtents.increments;
  var index = 0;

  _.each(zbe, function (value, i) {
    var percentFor100 = (100 * i) / (zbe.length - 1);

    var lineAttrs = {
      $lineType: "T",
      fill: params.color || GRIDLINE_COLOR,
      "stroke-width": 1,
      "stroke-dasharray": ["."],
    };

    if (params.customLineAttrs) {
      //_.extend(lineAttrs, params.customLineAttrs);
    }

    var labelText = params.labelFormatter
      ? params.labelFormatter(value)
      : value;

    var labelAttrs = {
      text: labelText,
      $textAnchor: "BR",
      "font-size": 10,
      fill: params.color || GRIDLINE_COLOR,
    };

    if (params.customLabelAttrs) {
      _.extend(labelAttrs, params.customLabelAttrs);
    }

    /*	if (value == 0){
                  _.extend(lineAttrs, BASE_AXIS_LINE_ATTRS);
                  _.extend(labelAttrs, BASE_AXIS_LABEL_ATTRS)
              } */

    var lineBox = new Box({
      name: "gridlinebox" + index,
      width: "100%",
      height: Math.round(Math.max(1, percentFor100)) + "%",
      heightPos: "bottom",
      drawMode: "none",
    });

    var hline = new Box({
      name: "gridline" + index,
      drawMode: "line",
      width: params.showYLabelsOutside ? "94.5%" : "100%",
      height: "100%",
      drawAttrs: lineAttrs,
    });

    var label = new Box({
      name: "gridlineText" + index,
      drawMode: "text",
      width: "100%",
      height: 1,
      paddingTop: -15,
      widthPos: "right",
      $role: "YLABEL",
      marginRight: params.showYLabelsOutside
        ? LABELS_MARGIN_RIGHT_OUTSIDE
        : LABELS_MARGIN_RIGHT,
      drawAttrs: labelAttrs,
    });

    lineBox.add(hline);
    lineBox.add(label);
    overlay.add(lineBox);

    ++index;
  });

  return overlay;
}
