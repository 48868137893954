import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import Services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import AccountTypeMappers from "views/modules/enrollmentNew/utils/accountTypeMappers";
import {
  getAccount,
  saveAccount,
  savePersonAccounts,
  removeAccounts,
  removeManualAccountsByUserAccountId,
} from "accessors/account/accessor";
import EditAccountView from "components/common/EditAccount/EditAccountView";
import EditLoginContainer from "components/common/EditAccount/EditLoginContainer";
import RemoveAccountContainer from "../RemoveAccount/RemoveAccountContainer";
import RemoveAccountView from "components/common/EditAccount/RemoveAccountView";
import MarkAccountAsClosedContainer from "components/common/EditAccount/MarkAccountAsClosedContainer";
import ChangeClosedDateContainer from "components/common/EditAccount/ChangeClosedDateModal/ChangeClosedDateContainer";
import StakeholderEditor from "components/common/EditAccount/StakeholderEditor";
import ConfirmModal from "common/ConfirmModal/ConfirmModal";
import People from "models/enrollment/people";
import LOAN_PRODUCT_TYPES from "enums/loanProductTypes";
import { find, findWhere } from "underscore";
import deepCopy from "deep-copy";
import Message from "components/common/Message";
import { isJointAccount, isOwnerShareSupported } from "utils/account";
import { updateHash } from "libs/pcap/utils/location";
import ReopenAccountContainer from "./ReopenAccountModal/ReopenAccountContainer";
import FastlinkContainerModal from "views/components/FastlinkContainer/Modal/FastlinkContainerModal";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const ACCOUNT_DETAILS_HASH = ACCOUNT_DETAILS_URL.replace("#", "");

const STAKEHOLDER_ROLES = ["BENEFICIARY", "PRIMARY", "SECONDARY"];

const isPrivileged = isEmpowerPrivilegedMode();

const renderRemoveAccountButtons = (buttonProps, customActions) => {
  return (
    <div className="u-text-right pc-u-mt">
      <button
        type="button"
        onClick={buttonProps.onCancel}
        className="pc-btn js-remove-account__cancel qa-remove-account-cancel"
      >
        {buttonProps.modalCancelButtonText}
      </button>
      {customActions?.GO_TO_MARK_CLOSED && (
        <button
          type="button"
          onClick={customActions.GO_TO_MARK_CLOSED.eventHandler}
          className="pc-btn js-btn-go-mark-closed qa-btn-go-mark-closed"
        >
          {customActions.GO_TO_MARK_CLOSED.label}
        </button>
      )}
      <button
        type="button"
        onClick={buttonProps.onRemoveAccount}
        className="pc-btn pc-btn--danger js-remove-account__confirm qa-remove-account-confirm"
        aria-disabled={isPrivileged}
        disabled={isPrivileged}
      >
        {buttonProps.modalConfirmButtonText}
      </button>
    </div>
  );
};

// Do not allow owners to also be beneficiaries
function buildBeneficiaryOptions(people, model) {
  const stakeholders = model.stakeholders || {};
  const additionalAttributes = model.additionalAttributes || {};
  const ownerIds = (stakeholders.PRIMARY || []).map((o) =>
    parseInt(o.personId, 10)
  );

  if (
    isJointAccount(model.ownershipType) ||
    isOwnerShareSupported(
      model.accountTypeGroup,
      additionalAttributes.jointAccountType
    )
  ) {
    ownerIds.push(
      ...(stakeholders.SECONDARY || []).map((o) => parseInt(o.personId, 10))
    );
  }
  return people.filter((p) => !ownerIds.includes(p.id));
}

function getProductTypeLabel(productType) {
  const product = findWhere(LOAN_PRODUCT_TYPES, { value: productType });

  return product.label;
}

export default class EditAccountContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMarkClosedModalOpen: false,
      isChangeClosedDateModalOpen: false,
      isReopenAccountModalOpen: false,
      isRemoveModalOpen: false,
      isEditLoginModalOpen: false,
      isEditOwnersModalOpen: false,
      isEditOauthModalOpen: false,
      isFastlinkModalOpen: false,
      isEditBeneficiariesModalOpen: false,
      isChangeProductTypeConfirmationModalOpen: false,
    };

    this.handleModalClosed = this.handleModalClosed.bind(this);
    this.handleAccountSave = this.handleAccountSave.bind(this);

    this.handleEditLoginClick = this.handleEditLoginClick.bind(this);
    this.handleReconnectLinkClick = this.handleReconnectLinkClick.bind(this);
    this.handleEditLoginModalClose = this.handleEditLoginModalClose.bind(this);
    this.handleCredentialsUpdate = this.handleCredentialsUpdate.bind(this);

    this.handleMarkAsClosed = this.handleMarkAsClosed.bind(this);
    this.handleClosedDateChanged = this.handleClosedDateChanged.bind(this);
    this.handleChangeClosedDateClick =
      this.handleChangeClosedDateClick.bind(this);
    this.handleAccountReopened = this.handleAccountReopened.bind(this);
    this.handleReopenAccountClick = this.handleReopenAccountClick.bind(this);

    this.handleChangeClosedDateModalClose =
      this.handleChangeClosedDateModalClose.bind(this);
    this.handleReopenAccountModalClose =
      this.handleReopenAccountModalClose.bind(this);
    this.handleMarkAccountClosedClick =
      this.handleMarkAccountClosedClick.bind(this);
    this.handleMarkAccountClosedModalClose =
      this.handleMarkAccountClosedModalClose.bind(this);

    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleRemoveModalClose = this.handleRemoveModalClose.bind(this);
    this.handleAccountRemove = this.handleAccountRemove.bind(this);

    this.handleEditOwnersClick = this.handleEditOwnersClick.bind(this);
    this.handleEditOwnersModalClose =
      this.handleEditOwnersModalClose.bind(this);
    this.handleOwnersUpdate = this.handleOwnersUpdate.bind(this);

    this.handleEditBeneficiariesClick =
      this.handleEditBeneficiariesClick.bind(this);
    this.handleEditBeneficiariesModalClose =
      this.handleEditBeneficiariesModalClose.bind(this);
    this.handleBeneficiariesUpdate = this.handleBeneficiariesUpdate.bind(this);
    this.handleAddPerson = this.handleAddPerson.bind(this);

    this.changeProductType = this.changeProductType.bind(this);
    this.handleChangeProductTypeConfirmationModalOpen =
      this.handleChangeProductTypeConfirmationModalOpen.bind(this);
    this.handleChangeProductTypeConfirmationModalCancel =
      this.handleChangeProductTypeConfirmationModalCancel.bind(this);
    this.handleBackToEditAccountView =
      this.handleBackToEditAccountView.bind(this);
    this.handleFastlinkModalClose = this.handleFastlinkModalClose.bind(this);
    this.displayErrors.bind(this);
  }

  componentDidMount() {
    Promise.all([
      this.fetchAccount(),
      this.fetchAccountTypes(),
      this.fetchPeople(),
    ])
      .then(([model, productTypes, people]) => {
        const newState = {
          model: deepCopy(model),
          productTypes,
          people,
        };

        // Loan/Mortgage accounts are now able to switch their productType
        if (["LOAN", "MORTGAGE"].includes(model.productType)) {
          newState.originalProductType = model.productType;
        }

        // Other Asset/Liability
        if (
          model.isManual &&
          !model.isEsog &&
          !model.isManualPortfolio &&
          model.productType !== "BANK"
        ) {
          this.getCustomProductData(model.productId, newState);
        } else {
          // No need to fetch custom product data
          newState.loading = false;
          this.setState(newState);
        }
      })
      .catch((errors) => {
        this.displayErrors(errors);
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userAccountId &&
      prevProps.userAccountId !== this.props.userAccountId
    ) {
      this.fetchAccount()
        .then((account) => {
          const newState = {
            model: account,
            customProduct: undefined,
            originalProductType: undefined,
          };

          // Loan/Mortgage accounts are now able to switch their productType
          if (["LOAN", "MORTGAGE"].includes(account.productType)) {
            newState.originalProductType = account.productType;
          }

          // Other Asset/Liability
          if (
            account.isManual &&
            !account.isEsog &&
            !account.isManualPortfolio &&
            account.productType !== "BANK"
          ) {
            this.getCustomProductData(account.productId, newState);
          } else {
            // No need to fetch custom product data
            newState.loading = false;
            this.setState(newState);
          }
        })
        .catch((errors) => this.displayErrors(errors));
    }
  }

  getCustomProductData(productId, newState) {
    // Get custom product data
    this.fetchCustomProduct(productId)
      .then((customProduct) => {
        newState.customProduct = customProduct;
        newState.loading = false;
        this.setState(newState);
      })
      .catch((errors) => this.displayErrors(errors));
  }

  fetchAccount() {
    this.setState({
      loading: true,
      model: null,
    });

    return getAccount(this.props.userAccountId, {
      personRoles: STAKEHOLDER_ROLES,
    });
  }

  fetchAccountTypes() {
    this.setState({
      loading: true,
    });

    return new Promise((resolve, reject) => {
      Services.AccountTypes.get((err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }
        resolve(AccountTypeMappers.mapChildrenByParentValue(response.spData));
      });
    });
  }

  fetchPeople() {
    this.setState({
      loading: true,
    });

    return new Promise((resolve, reject) => {
      Services.Person.getAll((err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }

        resolve(response.spData);
      });
    });
  }

  fetchCustomProduct(productId) {
    this.setState({
      loading: true,
    });

    return new Promise((resolve, reject) => {
      Services.CustomProducts.get((err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }

        const customProduct = find(response.spData, function (product) {
          return product.productId === productId;
        });

        resolve(customProduct);
      });
    });
  }

  handleAccountSave(model) {
    const { originalProductType } = this.state;

    this.setState({ loading: true });

    if (originalProductType && originalProductType !== model.productType) {
      // move the account between sidebar categories
      if (model.productType === "LOAN") {
        model.treatedAsLoan = true;
        model.treatedAsMortgage = false;
      } else if (model.productType === "MORTGAGE") {
        model.treatedAsLoan = false;
        model.treatedAsMortgage = true;
      }
    }

    if (model.ownershipType === "INDIVIDUAL") {
      delete model.stakeholders.SECONDARY;
    }

    saveAccount(model)
      .then((model) => {
        this.setState({
          model,
          loading: false,
        });
        this.props.onSave(model);
      })
      .catch((errors) => this.displayErrors(errors));
  }

  handleAddPerson() {
    return this.fetchPeople().then((people) => {
      this.setState({ people, loading: false });
    });
  }

  /***
   * Save the stakeholder accounts to the server and refresh the client model.
   *
   * @param {Object} stakeholders The associated stakeholders which will be saved.
   * @param {Number} userAccountId The main user to which to save the stakeholders.
   * @returns {Promise<[any, any, any, any, any, any, any, any, any, any] | never>} The promises for saving each type of stakeholder.
   */
  handleStakeholdersSave(stakeholders, userAccountId) {
    let requests = [];

    // Present API behavior allows saving of only one role type at a time
    for (const [key, value] of Object.entries(stakeholders)) {
      requests.push(savePersonAccounts(value, userAccountId, key));
    }

    return Promise.all(requests).then(() => {
      this.setState({ loading: true });

      getAccount(userAccountId, { personRoles: STAKEHOLDER_ROLES })
        .then((account) => {
          const newModel = this.state.model;

          newModel.stakeholders = account.stakeholders;

          this.setState({
            model: newModel,
            loading: false,
          });
        })
        .catch((errors) => this.displayErrors(errors));
    });
  }

  handleModalClosed() {
    this.props.onCancel();
  }

  handleEditLoginClick() {
    this.setState({
      isFastlinkModalOpen: true,
    });
  }

  handleFastlinkModalClose() {
    this.setState({ isFastlinkModalOpen: false });
    if (this.state.isEditOauthModalOpen) {
      this.setState({ isEditOauthModalOpen: false });
    }
  }

  handleReconnectLinkClick(value) {
    this.setState({
      isFastlinkModalOpen: true,
      isEditOauthModalOpen: value,
    });
  }

  handleBackToEditAccountView() {
    this.setState({
      isEditOauthModalOpen: false,
    });
  }

  handleEditLoginModalClose() {
    this.setState({ isEditLoginModalOpen: false });
  }

  handleCredentialsUpdate() {
    this.setState({ isEditLoginModalOpen: false });
  }

  handleMarkAccountClosedClick() {
    this.setState({ isMarkClosedModalOpen: true });
  }

  handleChangeClosedDateClick() {
    this.setState({ isChangeClosedDateModalOpen: true });
  }

  handleReopenAccountClick() {
    this.setState({ isReopenAccountModalOpen: true });
  }

  handleMarkAccountClosedModalClose() {
    this.setState({ isMarkClosedModalOpen: false });
  }

  handleChangeClosedDateModalClose() {
    this.setState({ isChangeClosedDateModalOpen: false });
  }

  handleReopenAccountModalClose() {
    this.setState({ isReopenAccountModalOpen: false });
  }

  handleMarkAsClosed() {
    this.setState({ isMarkClosedModalOpen: false }, this.props.onCancel);
  }

  handleClosedDateChanged() {
    const accountsDetailsUrl = `${ACCOUNT_DETAILS_HASH}/details?ua=${this.props.userAccountId}`;
    this.setState({ isChangeClosedDateModalOpen: false }, () => {
      updateHash(accountsDetailsUrl);
      this.props.onCancel();
    });
  }

  handleAccountReopened() {
    const accountsDetailsUrl = `${ACCOUNT_DETAILS_HASH}/details?ua=${this.props.userAccountId}`;
    this.setState({ isReopenAccountModalOpen: false }, () => {
      updateHash(accountsDetailsUrl);
      this.props.onCancel();
    });
  }

  handleRemoveClick() {
    this.setState({ isRemoveModalOpen: true });
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "edit_account_remove_account_clicked_event"
      );
    }
  }

  handleSwitchToMarkAsClosed() {
    this.setState({
      isRemoveModalOpen: false,
      isMarkClosedModalOpen: true,
    });
  }

  handleRemoveModalClose() {
    this.setState({ isRemoveModalOpen: false });
  }

  handleAccountRemove() {
    this.setState({ isRemoveModalOpen: false }, this.props.onCancel);
  }

  handleEditOwnersClick() {
    this.setState({ isEditOwnersModalOpen: true });
  }

  handleEditOwnersModalClose() {
    this.setState({ isEditOwnersModalOpen: false });
  }

  handleEditBeneficiariesClick() {
    this.setState({ isEditBeneficiariesModalOpen: true });
  }

  handleEditBeneficiariesModalClose() {
    this.setState({ isEditBeneficiariesModalOpen: false });
  }

  handleOwnersUpdate(stakeholders) {
    this.handleStakeholdersSave(
      stakeholders,
      this.state.model.userAccountId
    ).then(this.setState({ isEditOwnersModalOpen: false }));
  }

  handleBeneficiariesUpdate(stakeholders) {
    this.handleStakeholdersSave(
      stakeholders,
      this.state.model.userAccountId
    ).then(this.setState({ isEditBeneficiariesModalOpen: false }));
  }

  handleChangeProductTypeConfirmationModalOpen(ev) {
    const { model } = this.state;
    const sourceProductType = model.productType;
    const targetProductType = ev.target.value;

    model.productType = targetProductType;

    this.setState({
      model,
      sourceProductType,
      targetProductType,
      isChangeProductTypeConfirmationModalOpen: true,
    });
  }

  handleChangeProductTypeConfirmationModalCancel() {
    const { model, sourceProductType } = this.state;

    if (sourceProductType) {
      model.productType = sourceProductType;
    }
    this.setState({
      model,
      sourceProductType: null,
      targetProductType: null,
      isChangeProductTypeConfirmationModalOpen: false,
    });
  }

  changeProductType() {
    const { model } = this.state;

    // this will reset the `value` prop on `accountTypeCombo`
    // and make sure any dropdowns will be properly reset/hidden.
    delete model.accountTypeGroup;
    delete model.accountTypeNew;
    delete model.accountTypeSubtype;

    this.setState({
      model,
      sourceProductType: null,
      targetProductType: null,
      isChangeProductTypeConfirmationModalOpen: false,
    });
  }

  displayErrors(errors) {
    this.setState({
      errors,
      loading: false,
    });
  }

  renderEditLoginModal() {
    const { isEditLoginModalOpen } = this.state;

    if (isEditLoginModalOpen) {
      return (
        <Modal
          isOpen={true}
          componentName="EditLoginModal"
          title="Edit Login"
          className="pc-modal--small"
          onClosed={this.handleEditLoginModalClose}
        >
          <EditLoginContainer
            account={this.state.model}
            onCancel={this.handleEditLoginModalClose}
            onCredentialsUpdate={this.handleCredentialsUpdate}
          />
        </Modal>
      );
    }

    return null;
  }

  renderFastlinkModal() {
    const { isFastlinkModalOpen, model, isEditOauthModalOpen } = this.state;

    if (isFastlinkModalOpen) {
      return (
        <FastlinkContainerModal
          isOpen={true}
          title={isEditOauthModalOpen ? "Update Connection" : "Edit Login"}
          onClose={this.handleFastlinkModalClose}
          onCancel={this.handleFastlinkModalClose}
          onSuccess={this.handleFastlinkModalClose}
          siteId={model.siteId}
          userSiteId={model.userSiteId}
          flow={"EDIT"}
        />
      );
    }
    return null;
  }

  renderMarkClosedModal() {
    const { isMarkClosedModalOpen } = this.state;

    if (isMarkClosedModalOpen) {
      return (
        <Modal
          isOpen={true}
          componentName="MarkAccountAsClosedModal"
          title="Mark as Closed"
          className="pc-modal--small pc-modal--close-account"
          onClosed={this.handleMarkAccountClosedModalClose}
        >
          <MarkAccountAsClosedContainer
            model={this.state.model}
            onCancel={this.handleMarkAccountClosedModalClose}
            onMarkAsClosed={this.handleMarkAsClosed}
          />
        </Modal>
      );
    }

    return null;
  }

  renderChangeClosedDateModal() {
    const { isChangeClosedDateModalOpen } = this.state;

    if (isChangeClosedDateModalOpen) {
      return (
        <Modal
          isOpen={true}
          componentName="Change Closed Date"
          title="Change Closed Date"
          className="pc-modal--small qa-modal-change-closed-date js-modal-change-closed-date"
          onClosed={this.handleChangeClosedDateModalClose}
        >
          <ChangeClosedDateContainer
            model={this.state.model}
            onCancel={this.handleChangeClosedDateModalClose}
            onClosedDateChanged={this.handleClosedDateChanged}
          />
        </Modal>
      );
    }

    return null;
  }

  renderReopenAccountModal() {
    const { isReopenAccountModalOpen } = this.state;

    if (isReopenAccountModalOpen) {
      return (
        <Modal
          isOpen={true}
          componentName="Reopen"
          title="Reopen"
          className="pc-modal--small qa-modal-reopen-account js-modal-reopen-account"
          onClosed={this.handleReopenAccountModalClose}
        >
          <ReopenAccountContainer
            model={this.state.model}
            onCancel={this.handleReopenAccountModalClose}
            onReopened={this.handleAccountReopened}
          />
        </Modal>
      );
    }

    return null;
  }

  renderRemoveModal() {
    const { isRemoveModalOpen, model } = this.state;

    if (isRemoveModalOpen) {
      return (
        <RemoveAccountContainer
          isOpen={true}
          componentName="RemoveAccountModal"
          modalTitle="Remove Account"
          className="pc-modal--small"
          service={() =>
            window.isAdvisorApp && IS_EMPOWER && model.isManual
              ? removeManualAccountsByUserAccountId([
                  this.state.model.userAccountId,
                ])
              : removeAccounts([this.state.model.userAccountId])
          }
          onCancel={this.handleRemoveModalClose}
          onAccountRemoved={this.handleAccountRemove}
          modalCancelButtonText="Cancel"
          modalConfirmButtonText="Remove"
          modalBody={
            <RemoveAccountView
              model={this.state.model}
              hideMarkAsClosedMessage={window.isAdvisorApp && IS_EMPOWER}
            />
          }
          renderButtonSet={renderRemoveAccountButtons}
          customActions={
            window.isAdvisorApp && IS_EMPOWER
              ? null
              : {
                  GO_TO_MARK_CLOSED: {
                    label: "Mark As Closed",
                    eventHandler: this.handleSwitchToMarkAsClosed.bind(this),
                  },
                }
          }
        />
      );
    }

    return null;
  }

  renderEditOwnersModal() {
    let stakeholders = {};
    const { isEditOwnersModalOpen, model } = this.state;

    if (model) {
      stakeholders = model.stakeholders || {};
    }

    if (isEditOwnersModalOpen) {
      return (
        <StakeholderEditor
          onClosed={this.handleEditOwnersModalClose}
          stakeholders={{
            PRIMARY: stakeholders.PRIMARY || [],
            SECONDARY: stakeholders.SECONDARY || [],
          }}
          people={this.state.people}
          stakeholdersType="owners"
          onPersonAdded={this.handleAddPerson}
          onCancel={this.handleEditOwnersModalClose}
          onSubmit={this.handleOwnersUpdate}
        />
      );
    }

    return null;
  }

  renderEditBeneficiariesModal() {
    const { isEditBeneficiariesModalOpen, model } = this.state;
    let stakeholders = {};
    let possibleBeneficiaries = [];

    if (model) {
      stakeholders = model.stakeholders || {};
      possibleBeneficiaries = buildBeneficiaryOptions(this.state.people, model);
    }
    const beneficiaries = stakeholders.BENEFICIARY || [];

    if (isEditBeneficiariesModalOpen) {
      return (
        <StakeholderEditor
          onClosed={this.handleEditBeneficiariesModalClose}
          stakeholders={{
            BENEFICIARY: beneficiaries,
          }}
          people={possibleBeneficiaries}
          stakeholdersType="beneficiaries"
          onPersonAdded={this.handleAddPerson}
          onCancel={this.handleEditBeneficiariesModalClose}
          onSubmit={this.handleBeneficiariesUpdate}
        />
      );
    }

    return null;
  }

  renderChangeProductTypeConfirmationModal() {
    const {
      isChangeProductTypeConfirmationModalOpen,
      sourceProductType,
      targetProductType,
    } = this.state;

    // display names for Change Product Type `ConfirmationModal`
    let sourceProductTypeLabel, targetProductTypeLabel;

    if (isChangeProductTypeConfirmationModalOpen) {
      sourceProductTypeLabel = getProductTypeLabel(sourceProductType);
      targetProductTypeLabel = getProductTypeLabel(targetProductType);

      return (
        <ConfirmModal
          isOpen={isChangeProductTypeConfirmationModalOpen}
          onCancel={this.handleChangeProductTypeConfirmationModalCancel}
          title={`Group with ${targetProductTypeLabel}s`}
          onConfirm={this.changeProductType}
          confirmLabel="Move"
        >
          {`You’ve indicated this account is a ${targetProductTypeLabel} and not a ${sourceProductTypeLabel}. We will move this account to the ${targetProductTypeLabel} group on account lists.`}
        </ConfirmModal>
      );
    }

    return null;
  }

  render() {
    let accountGroups;
    let accountTypesMap;
    let accountSubTypesMap;
    const model = this.state.model;

    if (model) {
      accountGroups = this.state.productTypes[model.productType];
      accountTypesMap =
        AccountTypeMappers.mapChildrenByParentValue(accountGroups);
      accountSubTypesMap =
        AccountTypeMappers.mapAccountSubTypesByType(accountGroups);
    }
    const optionsPeople = new People(this.state.people).toSelectOptionsArray();

    const { isEditOauthModalOpen, errors, isFastlinkModalOpen } = this.state;

    const modalClassName = isEditOauthModalOpen
      ? "pc-modal pc-modal--medium qa-pc-modal--edit-oauth-account js-modal--edit-oauth-account"
      : `pc-modal--edit-account qa-pc-modal--edit-account ${
          this.props.isSimpleInterest ? "pc-modal--small" : "pc-modal--large"
        }`;
    const modalTitleName = isEditOauthModalOpen
      ? "Update Connection"
      : "Edit Account";

    if (isFastlinkModalOpen) {
      return this.renderFastlinkModal();
    }

    return (
      <>
        <Modal
          componentName="EditAccount"
          contentLabel="Edit Account"
          title={modalTitleName}
          className={modalClassName}
          shouldCloseOnOverlayClick={false}
          onClosed={this.handleModalClosed}
          loading={this.state.loading}
          isOpen={this.props.isOpen}
        >
          {!model && errors && <Message severity="error" messages={errors} />}
          {model && !isEditOauthModalOpen && (
            <EditAccountView
              model={this.state.model}
              accountGroups={accountGroups}
              accountTypesMap={accountTypesMap}
              accountSubTypesMap={accountSubTypesMap}
              customProduct={this.state.customProduct}
              people={this.state.people}
              onCancel={this.props.onCancel}
              onSubmit={this.handleAccountSave}
              onEditLoginClick={this.handleEditLoginClick}
              onRemoveClick={this.handleRemoveClick}
              errors={errors}
              onMarkAccountClosedClick={this.handleMarkAccountClosedClick}
              onChangeClosedDateClick={this.handleChangeClosedDateClick}
              onReopenAccountClick={this.handleReopenAccountClick}
              onEditBeneficiariesClick={this.handleEditBeneficiariesClick}
              onEditOwnersClick={this.handleEditOwnersClick}
              onReconnectClick={this.handleReconnectLinkClick}
              onStakeholdersSave={this.handleStakeholdersSave}
              isSimpleInterest={this.props.isSimpleInterest}
              optionsPeople={optionsPeople}
              onProductTypeChange={
                this.handleChangeProductTypeConfirmationModalOpen
              }
              isPrivileged={isPrivileged}
            />
          )}
        </Modal>
        {this.renderEditLoginModal()}
        {this.renderMarkClosedModal()}
        {this.renderChangeClosedDateModal()}
        {this.renderReopenAccountModal()}
        {this.renderRemoveModal()}
        {this.renderEditOwnersModal()}
        {this.renderEditBeneficiariesModal()}
        {this.renderChangeProductTypeConfirmationModal()}
      </>
    );
  }
}

EditAccountContainer.propTypes = {
  isOpen: PropTypes.bool,
  isSimpleInterest: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  userAccountId: PropTypes.number.isRequired,
};

EditAccountContainer.defaultProps = {
  isSimpleInterest: false,
  isOpen: false,
};
