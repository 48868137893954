import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import BannerTemplate from "templates/components/upgradeBrowserBanner.html";

export default Backbone.View.extend({
  events: {
    "click #upgradeBrowserLink": "onClickUpgradeBrowserLink",
  },
  initialize: function () {
    const supportedBrowsersTemplatePath =
      "/static/html/supported-browsers_v2.html";
    if (
      !_.isUndefined(window.BrowserDetect) &&
      _.isString(window.BrowserDetect.browser) &&
      _.isNumber(window.BrowserDetect.version)
    ) {
      // show banner to upgrade for ie browsers with version less than or equal to 11
      if (
        window.BrowserDetect.browser.toLowerCase() == "explorer" &&
        window.BrowserDetect.version <= 11
      ) {
        $("#moduleContainer").append(
          BannerTemplate({
            upgradeBrowserLink: `${
              window.staticUrl || ""
            }${supportedBrowsersTemplatePath}`,
          })
        );
        $("#upgradeBrowserLink").on("click", this.onClickUpgradeBrowserLink);
        setTimeout(function () {
          $("#upgradeBrowserBanner").fadeOut();
        }, 15000);
      }
    }
  },
  onClickUpgradeBrowserLink: function (e) {
    return true;
  },
});
