/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import $ from "jquery";

import _ from "underscore";
import Backbone from "backbone";
import "tiptip";
import moment from "moment";
import "dropdown";
import PickerDate from "libs/pcap/utils/PickerDate";
import Template from "templates/partials/dateRangeSelector2.html";
/**
 * Note - there are five fundamental sub-parts to this component:
 *
 *  1, 2) the startDate and endDate objects - these are internal instantiations
 *      of PickerDate, a class that uses the moment library to easily manage ranges.
 *      PickerDate also allows for a custom setting for "now"
 *      that is independent of system time for easy testing.
 *
 *  3, 4) the startDatePicker and endDatePicker - dom elements
 *      that the datePicker jQuery element is attached to.
 *
 *  5) the quickPicker select DOM element that triggers staged date ranges.
 *     it is identified by .quickPickList.
 *
 *  Changes to 1/3 emit 'startDate'  [datestring] when their value is changed;
 *  Changes to 2/4 emit 'endDate'    [datestring] when their value is changed;
 *
 *  changes to 5 directly sets 1, 2 value indirectly emitting 'startDate', 'endDate'.
 *
 *  Emitting either 'startDate' or 'endDate' emits 'change:dateRange' from this component;
 *  because these events can be on the heels of each other,
 *  the 'change:dateRange' emission is buffer-delayed.
 *
 */

const THIS_MONTH = "This Month";

const QUICKPICK_MAP = {
  "M,0s": THIS_MONTH,
  "M,-1s;M,-1e": "Last Month",
  "d,-89s": "90 Days",
  "d,-364s": "1 Year",
  "y,0s": "This Year",
  "y,-1s;y,-1e": "Last Year",
};

const PERIOD_LIST_SELECTOR = ".js-period-list";
const DATE_LIST_SELECTOR = ".js-date-selector-btn";
const DATE_PICKER_SELECTOR = "#ui-datepicker-div";
const START_DATE_INPUT_SELECTOR = ".js-start-date";
const END_DATE_INPUT_SELECTOR = ".js-end-date";
const TIMEOUT = 100;
const FIELDSETLABEL = "Date range selector";

export default Backbone.View.extend({
  events: {
    "click .js-period-item": "onQuickDatePick",
    "change .js-start-date": "onStartPickerChange",
    "change .js-end-date": "onEndPickerChange",
    "keypress .js-date-selector-btn": "onDateSelectorKeyPress", // doesn't seem to work for tab or click
    "click .js-date-selector-btn": "onDateSelectorKeyPress",
  },
  initialize: function (options) {
    this._id = _.uniqueId("daterangeselector");
    this.options = options;
    _.extend(this, Backbone.Events);

    this.startDate = new PickerDate(
      this.options.initialStartDate || this.defaultStartDateOffset
    );

    this.quickPicks = this.options.quickPicksOverride || [
      { value: "M,0s", label: THIS_MONTH, index: 0 },
      { value: "M,-1s;M,-1e", label: "Last Month", index: 1 },
      { value: "d,-89s", label: "90 Days", index: 2 },
      { value: "d,-365s", label: "1 Year", index: 3 },
      { value: "y,0s", label: "This Year", index: 4 },
      { value: "y,-1s;y,-1e", label: "Last Year", index: 5 },
    ];

    this.on("focus:dropdown", (dropDownClassSelector) => {
      this.$el.find(dropDownClassSelector).trigger("focus");
    });

    // On the 1st of the month, `This Month` will refer to a single day.
    // Net Worth overrides 1-day ranges by moving the start date a day earlier.
    // To match on the 1st, `This Month` quickpick must change to 2-day range.
    const isFirstOfTheMonth = moment().isSame(moment().startOf("month"), "day");
    const thisMonthQuickPick = this.quickPicks.find(
      (pick) => pick.label === THIS_MONTH
    );

    if (isFirstOfTheMonth && thisMonthQuickPick) {
      thisMonthQuickPick.value = "d,-1s";
    }

    this.fixEndDateToToday = options.fixEndDateToToday || false;
    if (this.fixEndDateToToday) {
      this.endDate = new PickerDate(this.defaultEndDateOffset);
      //WEB-780: Remove "Last Year" from Performance for #23.2 release.
      this.quickPicks = this.quickPicks.slice(0, 4); //eslint-disable-line no-magic-numbers
    } else {
      this.endDate = new PickerDate(
        this.options.initialEndDate || this.defaultEndDateOffset
      );
    }

    //If passing custom quickPicks, override default
    if (this.options.quickPicks) {
      this.quickPicks = this.options.quickPicks;
    }
    this.dateFormat = "m/d/yy";

    if (this.options.initialNowDate) {
      this.startDate.setNow(this.options.initialNowDate);
      this.endDate.setNow(this.options.initialNowDate);
    }

    this.setButtonLabel();
  },

  dateInputDateFormat: "M/D/YY",

  render: function () {
    this.$el.html(
      Template({
        buttonLabel: this.buttonLabel,
        quickPicks: this.quickPicks,
        selectedQuickPick: this.selectedQuickPick,
        fromString: this.startDate.getString(),
        endString: this.endDate.getString(),
        fieldsetLabel: FIELDSETLABEL,
      })
    );
    this.initializeDatepicker(this.options);
  },

  defaultStartDateOffset: ["d", -29], //eslint-disable-line no-magic-numbers

  defaultEndDateOffset: ["d", 0],

  // refreshing dates to current values.
  setQuickPickDates: function () {
    _.each(
      this.quickPicks,
      function (data) {
        const dates = this._datesFromValue(data.value);
        _.extend(data, {
          startDate: dates[0].toString(),
          endDate: dates[1].toString(),
        });
      }.bind(this)
    );
  },

  // eslint-disable-next-line sonarjs/cognitive-complexity
  initializeDatepicker: function (options) {
    const dayCellsSelector =
      "#ui-datepicker-div td:not('.ui-state-disabled') > a.ui-state-default";
    const prevButtonSelector = "#ui-datepicker-div .ui-datepicker-prev";
    const nextButtonSelector = "#ui-datepicker-div .ui-datepicker-next";
    const buttonsSelector = "#ui-datepicker-div [role='button']";
    let focusOnNextElement = true;

    function handleNextKeyPressed(event) {
      if (["Enter", " "].includes(event.key)) {
        $(nextButtonSelector).click();
        setTimeout(function () {
          if ($(".ui-datepicker-next.ui-state-disabled").length) {
            if ($(".ui-datepicker-prev.ui-state-disabled").length) {
              $(dayCellsSelector)[0].focus();
            } else {
              $(prevButtonSelector)[0].focus();
            }
          } else {
            $(nextButtonSelector)[0].focus();
          }
        }, TIMEOUT);
      }
    }
    function handlePrevKeyPressed(event) {
      if (["Enter", " "].includes(event.key)) {
        $(prevButtonSelector).click();
        setTimeout(function () {
          $(buttonsSelector)[0].focus();
        }, TIMEOUT);
      }
    }
    function hideDatePicker() {
      if ($(DATE_PICKER_SELECTOR).hasClass("start")) {
        $(START_DATE_INPUT_SELECTOR).datepicker("hide");
      } else if ($(DATE_PICKER_SELECTOR).hasClass("end")) {
        $(END_DATE_INPUT_SELECTOR).datepicker("hide");
      }
    }
    function handleKeyPressed(event) {
      event.preventDefault();
      const buttons = $(buttonsSelector);
      const focusedElement = $(":focus:first")[0];
      const firstButton = buttons[0];
      const lastButton = buttons[buttons.length - 1];
      // const dayCells = $(dayCellsSelector);

      if (event.key === "ArrowLeft") {
        if (focusedElement === firstButton) {
          lastButton.focus();
        } else {
          const currentIndex = buttons.index(focusedElement);
          buttons[currentIndex - 1].focus();
        }
      } else if (event.key === "ArrowRight") {
        if (focusedElement === lastButton) {
          firstButton.focus();
        } else {
          const currentIndex = buttons.index(focusedElement);
          buttons[currentIndex + 1].focus();
        }
      } else if (event.key === "Tab") {
        if (event.shiftKey) focusOnNextElement = false;
        hideDatePicker();
      } else if (event.key === "Escape") {
        hideDatePicker();
      }
    }
    function handleDateKeyPressed(event) {
      if (["Enter", " "].includes(event.key)) {
        event.currentTarget.click();
      }
    }
    function setAccessibility() {
      const selectedDateCellSelector =
        ".ui-datepicker-calendar .ui-state-active";
      setTimeout(function () {
        $(".ui-datepicker-calendar").attr("role", "grid");
        $(".ui-datepicker-calendar td").attr("role", "gridcell");
        $(
          ".ui-datepicker-prev:not('.ui-state-disabled'), .ui-datepicker-next:not('.ui-state-disabled')"
        )
          .attr("role", "button")
          .attr("tabindex", 0)
          .on("click", setAccessibility);
        $(".ui-datepicker-prev")
          .attr("aria-label", "Previous month")
          .off("keydown")
          .on("keydown", handlePrevKeyPressed);
        $(".ui-datepicker-next")
          .attr("aria-label", "Next month")
          .off("keydown")
          .on("keydown", handleNextKeyPressed);
        $(".ui-datepicker-calendar .ui-state-default")
          .attr("role", "button")
          .attr("tabindex", 0)
          .on("keydown", handleDateKeyPressed)
          .parent()
          .removeAttr("aria-current");
        $(selectedDateCellSelector)
          .attr("role", "button")
          .attr("tabindex", 0)
          .on("keydown", handleDateKeyPressed)
          .parent()
          .attr("aria-current", true);
        $(".ui-datepicker-unselectable > .ui-state-default")
          .attr("aria-disabled", true)
          .removeAttr("role")
          .removeAttr("tabindex");
        $(DATE_PICKER_SELECTOR).off("keydown").on("keydown", handleKeyPressed);

        if ($(selectedDateCellSelector).length) {
          $(selectedDateCellSelector)[0].focus();
        }
      }, TIMEOUT);
    }
    function handleClose() {
      const dateInput = $(DATE_PICKER_SELECTOR).hasClass("end")
        ? END_DATE_INPUT_SELECTOR
        : START_DATE_INPUT_SELECTOR;
      setTimeout(function () {
        const documentFocusable = $(
          'body button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        ).filter(":visible");
        const currentIndex = documentFocusable.index($(dateInput)[0]);
        if (focusOnNextElement) {
          documentFocusable[currentIndex + 1].focus();
        } else if (dateInput === END_DATE_INPUT_SELECTOR) {
          $(START_DATE_INPUT_SELECTOR)[0].focus();
        } else {
          $(DATE_LIST_SELECTOR)[0].focus();
        }
        focusOnNextElement = true;
      }, TIMEOUT);
    }

    // datepicker initialization
    this.startDatePicker = this.$el.find(START_DATE_INPUT_SELECTOR);
    this.endDatePicker = this.$el.find(END_DATE_INPUT_SELECTOR);
    this.setQuickPickDates();

    this.startDatePicker.datepicker({
      dateFormat: this.dateFormat,
      beforeShow: function (textbox, instance) {
        const widget = $(instance).datepicker("widget");
        instance.dpDiv.css({
          marginTop: "0px",
          marginLeft: $(textbox).outerWidth() - widget.outerWidth(),
        });
        $(DATE_PICKER_SELECTOR).removeClass("start end").addClass("start");
        setAccessibility();
        if (
          options?.onBeforeShowStartDate &&
          _.isFunction(options?.onBeforeShowStartDate)
        ) {
          options?.onBeforeShowStartDate();
        }
      },
      onClose: function () {
        handleClose();
      },
    });
    const momentCeiling = this.endDate.getMoment().subtract(1, "days");
    this.startDatePicker.datepicker(
      "option",
      "maxDate",
      momentCeiling.toDate()
    );

    if (this.fixEndDateToToday) {
      if (
        this.endDate.getString() === this.endDate.getNow().format("M/D/YYYY")
      ) {
        this.endDatePicker.val("today");
      } else {
        this.endDatePicker.val(this.endDate.getString());
      }
      this.endDatePicker.prop("disabled", true);
    } else {
      this.endDatePicker.datepicker({
        dateFormat: this.dateFormat,
        beforeShow: function (textbox, instance) {
          const widget = $(instance).datepicker("widget");
          $(DATE_PICKER_SELECTOR).removeClass("start end").addClass("end");
          instance.dpDiv.css({
            marginTop: "0px",
            marginLeft: $(textbox).outerWidth() - widget.outerWidth(),
          });
          setAccessibility();
          if (
            options?.onBeforeShowEndDate &&
            _.isFunction(options?.onBeforeShowEndDate)
          ) {
            options?.onBeforeShowEndDate();
          }
        },
        onClose: function () {
          handleClose();
        },
      });

      const momentFloor = this.startDate.getMoment().add(1, "days");
      this.endDatePicker.datepicker("option", "minDate", momentFloor.toDate());
      this.endDatePicker.datepicker(
        "option",
        "maxDate",
        this.endDate.getNow().toDate()
      );
      if (this.endDatePicker.prop("disabled")) {
        this.endDatePicker.prop("disabled", false);
      }
    }
  },

  setPosition: function (offset, featureControlSelector) {
    const $dateSelector = $(".dateSelector:visible");

    if ($dateSelector && $dateSelector.position() !== undefined) {
      const position = $dateSelector[0].getBoundingClientRect().bottom + offset;

      if (
        featureControlSelector &&
        featureControlSelector.hasClass("is-stuck")
      ) {
        $(DATE_PICKER_SELECTOR).css({
          position: "fixed",
          top: position,
          zIndex: 600,
        });
      } else {
        $(DATE_PICKER_SELECTOR).css({
          position: "absolute",
          top: position,
          zIndex: 600,
        });
      }
    }
  },

  setButtonLabel: function (quickPickValue) {
    const sdString = this.startDate.toString();
    const edString = this.endDate.toString();

    this.setQuickPickDates(); // refreshing dates based on current time

    const quickPick = _.find(this.quickPicks, function (qpd) {
      if (quickPickValue) {
        return qpd.value === quickPickValue;
      }
      return qpd.startDate === sdString && qpd.endDate === edString;
    });
    this.selectedQuickPick = quickPick;
    this.buttonLabel = quickPick ? quickPick.label : "Custom";
    this.render();
  },

  _datesFromValue: function (value, startPicker, endPicker) {
    const dateValues = value.split(";");

    startPicker = startPicker ? startPicker : new PickerDate();
    startPicker.setNow(this.options.initialNowDate);

    endPicker = endPicker ? endPicker : new PickerDate();
    endPicker.setNow(this.options.initialNowDate);

    if (dateValues.length > 1) {
      endPicker.setOffset(dateValues[1]);
    } else {
      endPicker.setToNow();
    }

    startPicker.setOffset(dateValues[0]);

    return [startPicker, endPicker];
  },

  onQuickDatePick: function (event) {
    event.preventDefault();

    const self = this;
    const quickPickMenu = this.$el.find(PERIOD_LIST_SELECTOR);
    const data = $(event.target).data();

    //eslint-disable-next-line no-magic-numbers
    quickPickMenu.fadeOut(10, function () {
      self.dropdownClassSelector = DATE_LIST_SELECTOR;
      self._datesFromValue(data.value, self.startDate, self.endDate);
      self.setButtonLabel(data.value);
      self.emitDateRange(data.value);
    });

    const currentPage = window.location.href;
    const isPortfolio = currentPage.indexOf("/portfolio") > -1;
    if (!isPortfolio && IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch("date_range_updated_event", {
        location: window.location.hash,
        startDate: self.startDate,
        endDate: self.endDate,
      });
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_FIELD ??
          "select_field",
        event_properties: {
          selection: `date_range_updated_event`,
          payload: {
            location: window.location.hash,
            startDate: self.startDate,
            endDate: self.endDate,
          },
        },
      });
    }
  },

  onDateSelectorKeyPress: function (event) {
    if (
      this.options.onQuickPicksBtnClick &&
      _.isFunction(this.options.onQuickPicksBtnClick)
    ) {
      this.options.onQuickPicksBtnClick();
    }
    if (["Enter", " "].includes(event.key)) {
      this.$el.find(DATE_LIST_SELECTOR).click();
    }
  },
  emitAnalyticsEvent: function () {
    const eventBus = window.dashboardUtils?.eventBus;
    const AMPLITUDE_EVENTS = window.integratedSharedData?.AMPLITUDE_EVENTS;
    let dateChange = document.getElementById("ui-datepicker-div");
    let callType = "";

    if (dateChange.classList.contains("holdings")) {
      callType = "holdings";
    }

    if (dateChange.classList.contains("balances")) {
      callType = "balances";
    }

    if (dateChange.classList.contains("performance")) {
      callType = "performance";
    }

    const selection = `budget_page.custom${callType}__date_range.click`;

    eventBus?.dispatch(selection);
    eventBus?.dispatchAmplitude({
      event_type: AMPLITUDE_EVENTS?.SELECT_BUTTON ?? "select_button",
      event_properties: {
        selection,
      },
    });
  },

  onStartPickerChange: function (event) {
    this.$el.find(PERIOD_LIST_SELECTOR).val("custom");
    const value = $(event.target).val();

    this.startDate.setString(value);
    this.setButtonLabel();
    this.dropdownClassSelector = START_DATE_INPUT_SELECTOR;
    this.emitDateRange();

    if (IS_EMPOWER) {
      this.emitAnalyticsEvent();
    }
  },

  onEndPickerChange: function (event) {
    this.$el.find(PERIOD_LIST_SELECTOR).val("custom");
    const value = $(event.target).val();
    this.endDate.setString(value);
    this.dropdownClassSelector = END_DATE_INPUT_SELECTOR;
    this.setButtonLabel();
    this.emitDateRange();
    if (IS_EMPOWER) {
      this.emitAnalyticsEvent();
    }
  },

  emitDateRange: function (quickPickVal) {
    this.trigger(
      "change:dateRange",
      this.startDate.getString(),
      this.endDate.getString(),
      QUICKPICK_MAP[quickPickVal],
      this.dropdownClassSelector
    );
  },

  remove: function () {
    $(document.body).off("." + this._id);
    Backbone.View.prototype.remove.apply(this, arguments);
  },
});
