var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./removeAccount2.html"),depth0,{"name":"templates/modules/sidebar/removeAccount2","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<a href='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"loginUrl") : depth0), depth0))
    + "' target='_blank' class='js-account-visit-site pc-btn pc-btn--primary pc-btn--tiny js-account-action disabled'>Visit Site</a>\n		<button type=\"button\" class='js-account-submit pc-btn pc-btn--primary pc-btn--tiny is-hidden disabled'>Continue</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<a href='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"loginUrl") : depth0), depth0))
    + "' target='_blank' class='js-account-visit-site pc-btn pc-btn--primary pc-btn--tiny js-account-action'>Visit Site</a>\n		<button type=\"button\" class='js-account-submit pc-btn pc-btn--primary pc-btn--tiny is-hidden'>Continue</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./site.html"),depth0,{"name":"templates/modules/sidebar/site","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"js-account-extras sidebar-account__extras is-hidden\">\n	<div class='sidebar-account__message'>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n\n	<div class=\"sidebar-account__actions\">\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.noop,"inverse":container.program(1, data, 0),"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":9,"column":26}}})) != null ? stack1 : "")
    + "    <button type=\"button\" class='js-account-cancel pc-btn pc-btn--tiny'>Cancel</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPrivileged") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":17,"column":9}}})) != null ? stack1 : "")
    + "		\n	</div>\n</div>\n";
},"usePartial":true,"useData":true});