import React from "react";
import PropTypes from "prop-types";
import withTooltips from "components/higherOrderComponents/withTooltips";

class InfoTooltipText extends React.Component {
  render() {
    const {
      className,
      storeTooltipRef,
      tooltipContent,
      tooltipProps,
      text,
      WrapperElement,
      handleFocus,
      handleBlur,
    } = this.props;
    return (
      <WrapperElement
        ref={(el) => {
          storeTooltipRef(
            el,
            Object.assign(
              { html: true, container: "body", placement: "auto" },
              tooltipProps
            )
          );
        }}
        className={`js-info-tooltip-text ${className}`}
        data-toggle="tooltip"
        title={tooltipContent}
        tabIndex="0"
        role="tooltip"
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {text}
      </WrapperElement>
    );
  }
}

InfoTooltipText.propTypes = {
  className: PropTypes.string,
  storeTooltipRef: PropTypes.func,
  tooltipContent: PropTypes.string,
  text: PropTypes.node,
  tooltipProps: PropTypes.object,
  WrapperElement: PropTypes.string,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
};

InfoTooltipText.defaultProps = {
  className: "pc-u-ml-",
  storeTooltipRef: undefined,
  tooltipContent: "",
  text: "",
  tooltipProps: {},
  WrapperElement: "span",
  handleFocus: () => {},
  handleBlur: () => {},
};

export default withTooltips(InfoTooltipText);
