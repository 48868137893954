/* eslint-disable sonarjs/cognitive-complexity, sonarjs/no-duplicate-string, no-magic-numbers */
import eventBus from "eventBus";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import LinkAccountView2 from "views/components/addAccount2";
import FirstUseQuestionsMadLibsView from "views/components/firstUse/firstUseQuestionsMadLibsView";
import isExternalMadLibsRegFlow from "libs/pcap/utils/isExternalMadLibsRegFlow";
import FirstUseSocialProofTemplate from "templates/modules/firstUse/firstUseSocialProof.html";
import analytics from "analytics";
import Mixpanel from "mixpanel";
import React from "react";
import ReactDOM from "react-dom";
import ForeignerOnboardingModalMessage from "components/modal/foreignerOnboarding/ForeignerOnboardingModalMessage";
import productTypes from "libs/pcap/utils/productTypes";
import {
  isPersonCanadian,
  getNextWeeksAppointments,
} from "libs/pcap/utils/customAppointmentSchedulerUtils";
import getAbFrameworkPromise from "libs/pcap/utils/getAbFrameworkPromise";
import { getFunnelAttributesPromise } from "components/shareWithFriends/ShareWithFriendsUtils";
import { formatNumber } from "libs/pcap/utils/format";
import {
  getABTestGroupByTargetedUserGuidIds,
  AB_TEST_GROUP_A,
  AB_TEST_GROUP_B,
} from "libs/pcap/utils/getABTestGroupForCurrentUser";
import UserGoals from "../components/firstUse/PersonalizedOnboarding/UserGoals/UserGoals";
import { isMobileDevice } from "../../libs/pcap/utils/isMobileDevice";
import { linkAccountMessage } from "./constant";

const U_INVISIBLE_CLASS = "u-invisible";
const LINK_ACCOUNT_CREATED_EVENT = "linkAccount:created";
const FIRST_USE_APPOINTMENT_SKIPPED_EVENT = "firstUseAppointment:skipped";
const FIRST_USE_SIGNOUT_EVENT = "firstUseSignOut:clicked";
const MOUSEOVER_POPUP_ON_LEAVE_ADD_FI_EVENT = "mouseover.popuponleaveaddfi";
const MOUSEOVER_USER_HOVERED_OVER_NON_HEADER_EVENT =
  "mouseover.userhoveredovernonheader";
const SESSION_KEY_HANDLED_NORMAL_FLOW =
  "SESSION_KEY_HANDLED_NORMAL_FLOW" + window.userGuid;
const SESSION_KEY_HANDLED_EXT_MAD_LIBS_REG_FLOW =
  "handledExternalMadLibsRegFlow_" + window.userGuid;

var BODY_CLASS_ONBOARDING_ADD_ACCOUNTS = "app--onboarding-add-accounts";
var BODY_CLASS_ONBOARDING_GETTING_STARTED_V2_FLOW =
  "app--onboarding-getting-started-v2-flow-rebrand";
var CLASS_ONBOARDING_ADD_ACCOUNTS_WITH_BG = "onboarding-add-accounts-with-bg";
var CLASS_SIDEBAR_OPENED = "sidebar--opened";
var HEADER_SKIP_APPOINTMENT_SELECTOR = ".js-first-use-skip-for-now";
var HEADER_SKIP_LINKING_ACCOUNT_SELECTOR = ".js-first-use-skip-account";
var COOKIE_PCAP_SOURCE = "pcap_source";
const FORTY = 40;
const HUNDRED_THOUSAND = 100000;
const THREE_MILLION_TWO_HUNDREDS = 3200000;
const EIGHT_MILLION_NINE_HUNDREDS = 8900000;
var DEFAULT_PRODUCT_TYPE = ""; // Get popular sites

function isFirstUseUrl(url) {
  return url.indexOf("app#/firstuse") > -1;
}

const showFirstUseAppointmentModal = () => {
  window.AppRouter.appointment({
    isFirstUseAppointment: true,
  });
};

const EMPOWER_MARKETING_ID = "utmcsr=empower";
const GETTING_STARTED_V2_MARKETING_ID = "getting_started_v2";
const signOutButtonRebranding = "menu__item--signout--rebranding";

const FirstUseView2 = Backbone.View.extend({
  el: "#firstUseModule",
  events: {
    "click .js-first-use-skip-account": "skipLinkAccount",
    "click .js-first-use-status-continue": "statusContinueClick",
    "click .js-security-toggle": "toggleSecurityContent",
    "click .js-security-policy-link": "securityPolicyClicked",
    "click .js-first-use-skip-for-now": "skipAppointment",
    "click .js-first-use-signout": "signOut",
    "click .js-first-use-link-account-calendar-link":
      "appointmentCalendarClick",
  },
  productType: "INVESTMENT",
  person: {},
  path: "",
  initialize: function (path, socialProofFlag, marketingABTesting) {
    this.marketingABTesting = marketingABTesting;
    this.path = path;
    this.linkAccountMessage = {
      title: "To Get Started, Link Your Accounts",
      subTitle: "Investments, 401ks, Credit Cards and Banks, all in one place.",
      successMessage: "",
    };
    Promise.all([
      getAbFrameworkPromise({ path: "firstuse" }),
      getFunnelAttributesPromise(),
    ]).then(([firstUseProperties, funnelAttributes]) => {
      this.abFrameworkFirstUseProperties = firstUseProperties || {};
      const funnelAttrMarketingId = funnelAttributes?.marketingId || "";
      const isEmpowerUser = funnelAttrMarketingId
        .toLowerCase()
        .includes(EMPOWER_MARKETING_ID);
      const isGettingStartedV2Flow =
        funnelAttrMarketingId
          .toLowerCase()
          .includes(GETTING_STARTED_V2_MARKETING_ID) ||
        ($.pcap.getCookie(COOKIE_PCAP_SOURCE) || "")
          .toLowerCase()
          .includes(GETTING_STARTED_V2_MARKETING_ID) ||
        window.localStorage.getItem("GETTING_STARTED_V2_FLOW");
      this.abFrameworkFirstUseProperties.isEmpowerUser = isEmpowerUser;
      this.abFrameworkFirstUseProperties.isGettingStartedV2Flow =
        isGettingStartedV2Flow;
      Mixpanel.unregisterSuperProperties([
        "isEmpowerUser",
        "isGettingStartedV2Flow",
      ]);
      if (isGettingStartedV2Flow) {
        $("body").addClass(BODY_CLASS_ONBOARDING_GETTING_STARTED_V2_FLOW);
        Mixpanel.updateProfile({ marketingId: funnelAttrMarketingId });
        Mixpanel.registerSuperProperties({ isGettingStartedV2Flow: true });
      }
      if (isEmpowerUser) {
        Mixpanel.updateProfile({ marketingId: funnelAttrMarketingId });
        Mixpanel.registerSuperProperties({ isEmpowerUser: true });
      }
      this.setup(path, socialProofFlag, marketingABTesting, funnelAttributes);
    });
    Services.Person.get((err, response) => {
      this.person = (response && response.spData) || {};
    });
  },
  madLibFormData: {},
  processMadLibResponse: function (formData) {
    this.madLibFormData = formData;
    if (
      this.isPersonalizedOnboarding === AB_TEST_GROUP_A &&
      !isMobileDevice()
    ) {
      this.$el.find("#firstUseQuestionnaire").hide();
      this.showUserGoalsPage(formData);
    } else {
      this.showLinkAccount(this.path, formData);
    }
  },

  setup: function (
    path,
    socialProofFlag,
    marketingABTesting,
    funnelAttributes
  ) {
    this.personalizedOnboarding =
      this.marketingABTesting?.AB_TEST_CONFIGURATION?.featureDetails?.personalizedOnboarding;
    this.isPersonalizedOnboarding = this.personalizedOnboarding?.isEnabled
      ? getABTestGroupByTargetedUserGuidIds(
          this.personalizedOnboarding.targetedUserGuidIds
        )
      : null;
    this.personalizedOnboardingSkipBtn =
      this.marketingABTesting?.AB_TEST_CONFIGURATION.featureDetails?.personalizedOnboardingSkipBtn;
    this.isPersonalizedOnboardingSkipBtn = this.personalizedOnboardingSkipBtn
      ?.isEnabled
      ? getABTestGroupByTargetedUserGuidIds(
          this.personalizedOnboardingSkipBtn.targetedUserGuidIds
        )
      : null;
    this.appointmentConfirmationData = this.isPersonalizedOnboarding
      ? null
      : JSON.parse(
          window.sessionStorage.getItem("appointmentConfirmationData")
        );
    // render template
    this.render({
      cmsUrl: window.cmsUrl,
      isGettingStartedV2Flow:
        this.abFrameworkFirstUseProperties?.isGettingStartedV2Flow,
      appointmentConfirmationData: this.appointmentConfirmationData,
      isNewSkipQuestionareLink: this.isNewSkipQuestionareLink,
      socialProofFlag,
    });
    // code for hideSkipLinkAccount
    if (this.abFrameworkFirstUseProperties.hideSkipLinkAccount === true) {
      this.$el.find(HEADER_SKIP_LINKING_ACCOUNT_SELECTOR).hide();
    }
    var forceShowAddFi = false;
    var REGEX_ENDS_WITH_ADD = /\/add$/;
    if (path && REGEX_ENDS_WITH_ADD.test(path)) {
      forceShowAddFi = true;
      path = path.replace(REGEX_ENDS_WITH_ADD, "");
    }

    if (
      !forceShowAddFi &&
      window.showFirstUse &&
      !window.isQuestionnaireAnswered
    ) {
      this.$el.removeClass(CLASS_ONBOARDING_ADD_ACCOUNTS_WITH_BG);
      var questionnaireEl = this.$el.find("#firstUseQuestionnaire");
      this.firstUseQuestionsView = new FirstUseQuestionsMadLibsView({
        elementContainer: questionnaireEl,
        isGettingStartedV2Flow:
          this.abFrameworkFirstUseProperties?.isGettingStartedV2Flow,
        marketingABTesting,
        funnelAttributes,
      });
      this.firstUseQuestionsView.on(
        "showFirstUseAppointment",
        showFirstUseAppointmentModal
      );
      questionnaireEl.show();
      window.sessionStorage.setItem(SESSION_KEY_HANDLED_NORMAL_FLOW, "1");
    } else if (window.localStorage.getItem("onInformationGatheringPage")) {
      this.firstUseQuestionsView = new FirstUseQuestionsMadLibsView({
        elementContainer: this.$el.find("#firstUseQuestionnaire"),
        isGettingStartedV2Flow:
          this.abFrameworkFirstUseProperties?.isGettingStartedV2Flow,
        marketingABTesting,
        funnelAttributes,
      });
      this.firstUseQuestionsView.on(
        "showFirstUseAppointment",
        showFirstUseAppointmentModal
      );
    } else {
      if (
        this.isPersonalizedOnboarding === AB_TEST_GROUP_A &&
        window.localStorage.getItem("onUserGoalsPage") === "1" &&
        !isMobileDevice()
      ) {
        this.showUserGoalsPage();
      } else {
        this.showLinkAccount(path);
      }
      if (
        isExternalMadLibsRegFlow() &&
        !(
          window.sessionStorage.getItem(
            SESSION_KEY_HANDLED_EXT_MAD_LIBS_REG_FLOW
          ) || window.sessionStorage.getItem(SESSION_KEY_HANDLED_NORMAL_FLOW)
        )
      ) {
        window.sessionStorage.setItem(
          SESSION_KEY_HANDLED_EXT_MAD_LIBS_REG_FLOW,
          "1"
        );
        this.firstUseQuestionsView = new FirstUseQuestionsMadLibsView({
          isExternalMadLibsRegFlow: true,
        });
        this.firstUseQuestionsView.on(
          "showFirstUseAppointment",
          showFirstUseAppointmentModal
        );
        Mixpanel.trackEvent("User Filled Out Mad Libs Form Outside the App", {
          component: "First Use",
        });
        Mixpanel.trackEvent("Continue External Mad Libs Reg Flow", {
          component: "First Use",
        });
      }
    }
    // Always hide `Skip Linking Account` until user added at least one account
    $(HEADER_SKIP_LINKING_ACCOUNT_SELECTOR).hide();

    // subscribe to questionnaire events
    eventBus.on(
      "firstUseQuestionnaire:completed",
      this.processMadLibResponse,
      this
    );
    eventBus.on(
      FIRST_USE_APPOINTMENT_SKIPPED_EVENT,
      this.showMadLibsInterjectionReviewModal,
      this
    );

    // subscribe to link account widget events
    eventBus.on(LINK_ACCOUNT_CREATED_EVENT, this.linkAccountCreated, this);
    eventBus.on("linkAccount:done", this.linkAccountDone, this);
    eventBus.on(
      "linkAccount:firstAccountAggregated",
      this.firstAccountAggregated,
      this
    );
    eventBus.on(
      "linkAccount:manualAccountContinue",
      this.manualAccountContinue,
      this
    );
    eventBus.on("linkAccount:searchResults", this.hideContent, this);
    eventBus.on("linkAccount:suggestions", this.showContent, this);
    eventBus.on("linkAccount:showSection:suggestions", this.showContent, this);
    eventBus.on("linkAccount:accountAuthentication", this.hideContent, this);
  },

  // object of setInterval timers initialization to be used by countAnimation method
  countAnimationTimers: {},

  /**
   * @description this method triggers a counting up animation.
   * @param {string} id identifier of the HTMl element attached
   * @param {number} finalValue the desired final value to be shown after animation
   */
  countAnimation: function (id, finalValue) {
    const duration = 3500; // milliseconds the animation will last
    const increment = 10000; // quantity to be added every step of interval
    let current = 0;
    const stepTime = Math.abs(Math.floor(duration / (finalValue / increment)));
    let countEl = document.getElementById(id);
    this.countAnimationTimers[id] = setInterval(() => {
      current += increment;
      if (current === finalValue || !countEl) {
        clearInterval(this.countAnimationTimers[id]);
      }
      if (countEl) {
        countEl.innerHTML = formatNumber(current, 0, false);
      }
    }, stepTime);
  },
  renderData: {},
  render: function (data) {
    data.socialProofSignOutRebrand = signOutButtonRebranding;
    this.renderData = data;
    this.$el.html(FirstUseSocialProofTemplate(this.renderData));
    return this;
  },
  onClose: function () {
    var self = this;

    // clear setInterval functions for countAnimationTimers
    if (this.countAnimationTimers) {
      for (const timer in this.countAnimationTimers) {
        if (
          Object.prototype.hasOwnProperty.call(this.countAnimationTimers, timer)
        ) {
          clearInterval(timer);
        }
      }
    }

    // unsubscribe to questionnaire events
    eventBus.off("firstUseQuestionnaire:completed", self.processMadLibResponse);
    eventBus.off(
      FIRST_USE_APPOINTMENT_SKIPPED_EVENT,
      self.showMadLibsInterjectionReviewModal
    );
    // unsubscribe to link account widget events
    eventBus.off(LINK_ACCOUNT_CREATED_EVENT, self.linkAccountCreated);
    eventBus.off("linkAccount:done", self.linkAccountDone);
    eventBus.off(
      "linkAccount:firstAccountAggregated",
      self.firstAccountAggregated
    );
    eventBus.off(
      "linkAccount:manualAccountContinue",
      self.manualAccountContinue
    );
    eventBus.off("linkAccount:searchResults", self.hideContent);
    eventBus.off("linkAccount:suggestions", self.showContent);
    eventBus.off("linkAccount:showSection:suggestions", self.showContent);
    eventBus.off("linkAccount:accountAuthentication", self.hideContent);

    if (!_.isUndefined(this.linkAccountView)) {
      this.linkAccountView.remove();
    }
  },
  closeView: function () {
    var self = this;
    // remove view
    self.close();
  },
  showLinkAccount: function (path, madlibFormData = {}) {
    eventBus.on("firstUseSignOut:clicked", () => {
      Mixpanel.trackEvent("Sign Out", {
        component: "Link Account Page",
      });
    });

    // Handle suspicious users
    if (isMobileDevice()) {
      Mixpanel.trackEvent(
        "Redirect Mobile Page",
        { component: "Link Account Page" },
        {
          // eslint-disable-next-line camelcase
          send_immediately: true,
          callback: () => {
            window.location.replace("/static/html/redirectmobile/");
          },
        }
      );
    }

    this.revertGettingStartedV2Mode();
    if (window.localStorage.getItem("linkAccountMessage")) {
      this.linkAccountMessage = JSON.parse(
        window.localStorage.getItem("linkAccountMessage")
      );
    }

    this.$el
      .find(".first-use-link-account-header__title")
      .html(this.linkAccountMessage.title);
    this.$el
      .find(".first-use-link-account-header__subtitle")
      .html(this.linkAccountMessage.subTitle);

    path = productTypes.transformPath(path);
    const appointmentConfirmationData = JSON.parse(
      window.sessionStorage.getItem("appointmentConfirmationData")
    );

    if (appointmentConfirmationData) {
      Mixpanel.trackEvent("FI Search Appointment Confirmation", {
        component: "First Use",
      });
    }

    Services.Profile.getUIPreferences(
      function (err, response) {
        var cryptoFeatureFlag =
          (response &&
            response.spData &&
            response.spData.features &&
            response.spData.features.CRYPTO_CURRENCY) ||
          false;
        const isConnectivityTrackerEnabled =
          response?.spData?.features?.ENABLE_CONNECTIVITY_TRACKER;
        const aggregationAbandoners =
          response?.spData?.marketingABTestingFeatures
            ?.MARKETING_AB_TEST_AGGREGATION_ABANDONERS;
        const aggregationAbandonersTest = aggregationAbandoners?.isEnabled
          ? getABTestGroupByTargetedUserGuidIds(
              aggregationAbandoners?.featureDetails?.targetedUserGuidIds
            )
          : AB_TEST_GROUP_A;
        this.isAggregationAbandonersTest =
          aggregationAbandonersTest === AB_TEST_GROUP_B;
        var accountExpectationTest =
          response?.spData?.features?.WEB_ACCOUNT_EXPECTATION_TEST || false;
        this.unifiedMarFeatureFlag =
          response?.spData?.features?.USE_UNIFIED_MAR;

        this.$el.addClass(CLASS_ONBOARDING_ADD_ACCOUNTS_WITH_BG);
        $(HEADER_SKIP_APPOINTMENT_SELECTOR).hide();
        // if skip link account flag is true, close the first use experience
        if (!_.isUndefined(window.skipLinkAccount) && window.skipLinkAccount) {
          window.PCAP.skipFirstUse = true;
          this.linkAccountDone();
        }
        // trigger GA page view
        window.ga && window.ga("send", "pageview", "/accounts/add"); // eslint-disable-line no-unused-expressions
        //Send Mixpanel event that tracks Manual Account Recapture test and control group
        if (this.unifiedMarFeatureFlag) {
          Mixpanel.trackEvent("MOB_Test_Group", {
            component: "First Use",
          });
        } else {
          Mixpanel.trackEvent("MOB_Control_Group", {
            component: "First Use",
          });
        }
        // initialize link account widget
        var linkAccountContainer = this.$el.find(".linkAccountContainer");
        var showManualAccounts = false;

        this.productType = this.getProductType(path);
        this.subProductType = this.getSubProductType(path);
        const addAccountViewParams = {
          suggestionsMatrix: "4x3",
          width: {
            "4x3": 530,
            "3x4": 375,
          },
          height: 654,
          productType: this.productType,
          subProductType: this.subProductType,
          showManualAccounts,
          numberOfSuggestions: 12,
          showAllProducts: true,
          showProgressBar: false,
          cryptoFeatureFlag,
          isConnectivityTrackerEnabled,
          accountExpectationTest,
          uiPreferences: response?.spData,
          personInfo: this.person,
          linkAccountMessage: this.linkAccountMessage,
          useUnifiedMar: this.unifiedMarFeatureFlag,
        };
        this.linkAccountView = new LinkAccountView2(
          linkAccountContainer,
          addAccountViewParams,
          this.person
        );

        var linkAccountEl = this.$el.find("#firstUseLinkAccount");
        this.$el.find("#firstUseQuestionnaire").hide();
        linkAccountEl.show();
        linkAccountEl.find(".js-search-account-input").focus();
        this.toggleShowPopupOnLeaveAddFi(true);

        this.handleCounterDisplay(madlibFormData);

        if (this.$el.find("#js-first-use-user-counter").length > 0) {
          this.countAnimation(
            "js-first-use-user-counter",
            THREE_MILLION_TWO_HUNDREDS
          );
          this.countAnimation(
            "js-first-use-accounts-linked-counter",
            EIGHT_MILLION_NINE_HUNDREDS
          );
        }

        if (isPersonCanadian(this.person)) {
          this.foreignerOnboardingModalMessageLauncherNode = $("<div></div>")
            .appendTo("body")
            .get(0);
          ReactDOM.render(
            React.createElement(ForeignerOnboardingModalMessage),
            this.foreignerOnboardingModalMessageLauncherNode
          );
        }
      }.bind(this)
    );
  },
  /**
   *
   * @param {object} madlibFormData object with madlib form info from user
   * @description this function handles the display of the user counter depending
   *              on several conditions obtained from the madlib form.
   */
  handleCounterDisplay: function (madlibFormData) {
    let userCountModal = false;
    const date = new Date();
    const personInfo = { age: 0, investableAssets: 0 };

    if (Object.keys(madlibFormData).length > 0) {
      personInfo.age = date.getFullYear() - madlibFormData.birthday.year;
      personInfo.investableAssets = madlibFormData.investableAssetsAmount;
    } else {
      personInfo.age = this.person.age;
      personInfo.investableAssets =
        this.person.investableAssets &&
        this.person.investableAssets.userInputInvestableAssets;
    }

    if (
      this.renderData.socialProofFlag &&
      personInfo.investableAssets >= HUNDRED_THOUSAND &&
      personInfo.age >= FORTY
    ) {
      userCountModal = true;
    } else {
      this.$el
        .find(".first-use-link-account-social-proof")
        .css("display", "none");
    }
    // Mixpanel View FI Search Screen event
    Mixpanel.trackEvent("View FI Search Screen", {
      component: "First Use",
      // eslint-disable-next-line camelcase
      is_first_use: true,
      userCountModal,
    });
  },
  toggleShowPopupOnLeaveAddFi: function (toggleOn) {
    if (toggleOn) {
      this.$el.off(MOUSEOVER_USER_HOVERED_OVER_NON_HEADER_EVENT);
      this.$el.on(
        MOUSEOVER_USER_HOVERED_OVER_NON_HEADER_EVENT,
        ".moduleContainer",
        function () {
          this.hoveredOverNonHeader = true;
          this.$el.off(MOUSEOVER_USER_HOVERED_OVER_NON_HEADER_EVENT);
        }.bind(this)
      );

      this.$el.off(MOUSEOVER_POPUP_ON_LEAVE_ADD_FI_EVENT);
      this.$el.on(
        MOUSEOVER_POPUP_ON_LEAVE_ADD_FI_EVENT,
        ".js-first-use-header",
        function () {
          if (!this.hoveredOverNonHeader) {
            return;
          }
          var userIsOnLoginScreen = this.$el.find(
            ".accountAuthentication:visible"
          ).length;

          if (
            this.linkAccountView &&
            !window.localStorage.getItem("AggregationAbandon") &&
            !window.localStorage.getItem("LeaveAddFi")
          ) {
            const isFirstUse = userIsOnLoginScreen === 0;
            this.linkAccountView.renderFirstUseLeaveModal(
              userIsOnLoginScreen,
              this.isAggregationAbandonersTest,
              isFirstUse
            );
          }
        }.bind(this)
      );

      eventBus.on(LINK_ACCOUNT_CREATED_EVENT, () => {
        this.toggleShowPopupOnLeaveAddFi(false);
      });
    } else {
      this.$el.off(MOUSEOVER_POPUP_ON_LEAVE_ADD_FI_EVENT);
      this.$el.off(MOUSEOVER_USER_HOVERED_OVER_NON_HEADER_EVENT);
    }
  },

  linkAccountDone: function (url) {
    this.close();
    window.localStorage.removeItem("FirstName");
    this.cleanUpFirstUseClasses();
    if (
      _.isString(redirectTo) &&
      $.trim(redirectTo).length > 0 &&
      !isFirstUseUrl(redirectTo)
    ) {
      window.location.replace(redirectTo);
    } else {
      if (!_.isString(url)) {
        url = DASHBOARD_URL;
      }
      // Manually set skipFirstUse flag to prevent router.showModule()'s checkForFirstUse() from
      // sending user back to #/firstuse again, as the just submitted credentials most likely
      // has not completed aggregating and therefore numberOfAccounts is still 0.
      if (!window.numberOfAccounts && window.PCAP) {
        window.PCAP.skipFirstUse = true;
      }
      AppRouter.navigate(url, { replace: true });
    }
  },
  linkAccountCreated: function () {
    // show skip link account after user attempts to link first account
    this.$(HEADER_SKIP_LINKING_ACCOUNT_SELECTOR).show();
    this.showSidebar();
  },
  showSidebar: function () {
    $("body").addClass(BODY_CLASS_ONBOARDING_ADD_ACCOUNTS);
    $(".js-sidebar")
      .addClass(CLASS_SIDEBAR_OPENED)
      .removeClass(U_INVISIBLE_CLASS);
  },
  getProductType: function (path) {
    if (_.isUndefined(path) || $.trim(path) === "") {
      return DEFAULT_PRODUCT_TYPE;
    }
    const productType = (path.split("/")[0] || "").toUpperCase();
    return productTypes.allowedProductTypes.includes(productType)
      ? productType
      : "";
  },
  getSubProductType: function (path) {
    if (_.isUndefined(path)) {
      return "";
    }
    const subProductType = (path.split("/")[1] || "").toUpperCase();
    return [
      ...productTypes.allowedSubProductTypes,
      ...productTypes.manualProductTypes,
    ].includes(subProductType)
      ? subProductType
      : "";
  },
  hideContent: function () {
    this.$(".footer").hide();
    this.$(".js-first-use-link-account-header").addClass(U_INVISIBLE_CLASS); // Need blank placeholder when hidden
    this.$(".js-first-use-link-account-header-appointment-details").hide();
  },
  showContent: function () {
    this.$(".footer").show();
    this.$(".js-first-use-link-account-header").removeClass(U_INVISIBLE_CLASS);
    this.$(".js-first-use-link-account-header-appointment-details").show();
  },
  firstAccountAggregated: function (accounts) {
    // hide progress bar
    window.numberOfAccounts = accounts.length;
  },
  skipAppointment: function (e) {
    e.preventDefault();
    this.revertGettingStartedV2Mode();
    $(HEADER_SKIP_APPOINTMENT_SELECTOR).hide();
    if (window.referralOnboardingTest === AB_TEST_GROUP_A) {
      Mixpanel.trackEvent("Close Appointment Scheduler Modal", {
        component: "First Use Ref_On",
      });
    } else if (window.referralOnboardingTest === AB_TEST_GROUP_B) {
      Mixpanel.trackEvent("Close Appointment Scheduler Modal", {
        component: "First Use Ref_Ont",
      });
    } else {
      Mixpanel.trackEvent("Close Appointment Scheduler Modal", {
        component: "First Use",
      });
    }
    eventBus.trigger(FIRST_USE_APPOINTMENT_SKIPPED_EVENT);
  },
  signOut: function () {
    eventBus.trigger(FIRST_USE_SIGNOUT_EVENT);
  },
  showMadLibsInterjectionReviewModal: function () {
    window.AppRouter.removeTollGateOffRampPageView();
    window.sessionStorage.removeItem(
      "showMadLibsReviewModalOnMadLibsModalClose"
    );
    if (getNextWeeksAppointments()) {
      window.AppRouter.appointment({
        isMadLibsInterjectionReviewModal: true,
      });
    } else {
      window.localStorage.removeItem("onInformationGatheringPage");
      window.localStorage.removeItem("onTollGateOffRampPage");
      window.AppRouter.showFirstUse();
    }
  },
  skipLinkAccount: function (e) {
    e.preventDefault();
    window.PCAP.skipFirstUse = true;
    analytics.sendPageView("/click-skip-linking-account");
    var eventName = "First Use Add FI Skip";
    Mixpanel.trackEvent(
      eventName,
      {
        component: "First Use",
      },
      { ignoreNextEventName: eventName }
    );
    this.linkAccountDone(DASHBOARD_URL);
  },
  manualAccountContinue: function (href) {
    if (_.isString(href)) {
      this.close();
      window.location.replace(href);
    } else {
      window.location.replace("");
    }
  },
  statusContinueClick: function (e) {
    e.preventDefault();
    this.linkAccountDone();
  },
  revertGettingStartedV2Mode: function () {
    $("body").removeClass(BODY_CLASS_ONBOARDING_GETTING_STARTED_V2_FLOW);
    $.pcap.setCookie(COOKIE_PCAP_SOURCE, "", -1, true);
  },
  cleanUpFirstUseClasses: function () {
    this.revertGettingStartedV2Mode();
    $("body").removeClass(BODY_CLASS_ONBOARDING_ADD_ACCOUNTS);
    $(".js-sidebar")
      .removeClass(CLASS_SIDEBAR_OPENED)
      .removeClass(U_INVISIBLE_CLASS);
  },
  appointmentCalendarClick: function (e) {
    e.preventDefault();
    const buttonLabel = e.target.textContent;
    Mixpanel.trackEvent(`Click on ${e.target.textContent} Calendar link`, {
      component: "First Use Link Account",
    });
    if (buttonLabel === "Google") {
      window.open(this.appointmentConfirmationData.gcalURL, "_blank");
      return;
    }
    window.location.href =
      buttonLabel === "iCal"
        ? this.appointmentConfirmationData.icalURL
        : this.appointmentConfirmationData.outlookURL;
  },
  remove: function () {
    Mixpanel.unregisterSuperProperties();
    this.cleanUpFirstUseClasses();
    if (this.firstUseQuestionsView) {
      this.firstUseQuestionsView.remove();
    }
    this.toggleShowPopupOnLeaveAddFi(false);
    if (this.foreignerOnboardingModalMessageLauncherNode) {
      ReactDOM.unmountComponentAtNode(
        this.foreignerOnboardingModalMessageLauncherNode
      );
      this.foreignerOnboardingModalMessageLauncherNode.parentNode.removeChild(
        this.foreignerOnboardingModalMessageLauncherNode
      );
      this.foreignerOnboardingModalMessageLauncherNode = null;
    }
    Backbone.View.prototype.remove.apply(this, arguments);
  },
  removeUserGoalsView: function () {
    if (this.userGoalsView) {
      ReactDOM.unmountComponentAtNode(this.userGoalsView);
      this.userGoalsView.parentNode.removeChild(this.userGoalsView);
      this.userGoalsView = null;
      eventBus.off(FIRST_USE_SIGNOUT_EVENT);
    }
  },

  showUserGoalsPage: function (formData = {}) {
    if (!this.UserGoalsView) {
      $(".js-first-use-skip-for-now").hide();
      window.localStorage.setItem("onUserGoalsPage", "1");
      const hasSkipButton =
        this.isPersonalizedOnboardingSkipBtn === AB_TEST_GROUP_A;
      const submitHandler = function (data) {
        window.localStorage.removeItem("onUserGoalsPage");
        window.sessionStorage.removeItem("appointmentConfirmationData");
        window.localStorage.setItem("linkAccountMessage", JSON.stringify(data));
        this.linkAccountMessage = data;
        this.removeUserGoalsView();
        $(".moduleContainer").css({ width: "828px" });
        this.showLinkAccount(this.path, formData);
      }.bind(this);
      const props = {
        appointmentData: JSON.parse(
          window.sessionStorage.getItem("appointmentConfirmationData")
        ),
        linkAccountMessages: linkAccountMessage,
        onSubmit: submitHandler,
        hasSkipButton: hasSkipButton,
      };
      this.userGoalsView = document.createElement("div");
      $(".moduleContainer").css({ width: "100%" });
      $(".moduleContainer").get(0).append(this.userGoalsView);
      ReactDOM.render(
        React.createElement(UserGoals, props),
        this.userGoalsView
      );
      eventBus.on(FIRST_USE_SIGNOUT_EVENT, () => {
        Mixpanel.trackEvent("Sign Out", {
          component: "Goals For Dashboard",
        });
      });
    }
  },
});

export default FirstUseView2;
