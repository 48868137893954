import React from "react";
import { CSSTransition } from "react-transition-group";
import AbstractForm from "components/common/form/AbstractForm";
import FinancialAssetAccountDetails from "components/common/EditAccount/FinancialAssetAccountDetails";
import HomeDetails from "components/common/EditAccount/HomeDetails";
import LoanAccountDetails from "components/common/EditAccount/LoanAccountDetails";
import CreditAccountDetails from "components/common/EditAccount/CreditAccountDetails";
import AccountSummary from "components/common/EditAccount/AccountSummary";
import EditAccountFooter from "components/common/EditAccount/EditAccountFooter";
import { getDefaultValue } from "components/modal/editAccount/utils/selectOptions";
import {
  getOwnershipTypes,
  isBeneficiarySupported,
  isOwnerShareSupported,
  getEmployerMatchLimitType,
} from "utils/account";
import PropTypes from "prop-types";
import StakeholdersView from "components/common/EditAccount/StakeholdersView";
import { getAllOwners } from "components/common/EditAccount/stakeholdersUtils";
import Message from "components/common/Message";
import { noop } from "underscore";
import SimpleInterestRateAccountDetails from "components/common/EditAccount/SimpleInterestRateAccountDetails";
import StockOptionsDetails from "components/common/EditAccount/StockOptionsDetails";
import CustomProductDetails from "components/common/EditAccount/CustomProductDetails";

const IS_USED_FOR_FUNDING_TOOLTIP = `This account cannot be removed because it was used to fund an Empower account. You can mark the account closed to stop importing data but keep your historic data.`;

const hasExcludeFromAdvice = (model) => {
  return model.productType === "BANK" || model.productType === "INVESTMENT";
};

export default class EditAccountView extends AbstractForm {
  constructor(props) {
    super(props);

    const ownershipTypes = getOwnershipTypes(
      props.model.accountTypeGroup,
      props.model.accountTypeNew,
      props.accountTypesMap
    );
    this.state = {
      model: props.model,
      ownershipTypes,
      // Only show check as investment checkbox if the account is a BANK or if it is treated as investment on initial load
      canTreatAsInvestment:
        props.model.productType === "BANK" || props.model.treatedAsInvestment,
    };
    this.state.model.ownershipType = getDefaultValue(
      this.state.model.ownershipType,
      ownershipTypes
    );

    // This property lives only in client state, and is derived from other values.
    // If it doesn't exist it needs to be derived.
    if (!this.state.model.employerMatchLimitType) {
      this.state.model.employerMatchLimitType = getEmployerMatchLimitType(
        this.state.model.maxMatchDollarValue,
        this.state.model.maxMatchPercentOfSalary
      );
    }

    // If there are maximum dollar match and maximum percentage match values
    // on the account, we reset the percent value and default to `dollar`.
    if (this.state.model.employerMatchLimitType === "both") {
      this.state.model.maxMatchPercentOfSalary = null;
      this.state.model.employerMatchLimitType = "dollar";
    }

    this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
    this.handleEmployerMatchLimitChange =
      this.handleEmployerMatchLimitChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMatchProfitChange = this.handleMatchProfitChange.bind(this);
    this.handleToggleChange = this.handleToggleChange.bind(this);
  }

  currentMatchProfitValue() {
    const model = this.state.model;
    if (model.hasProfitSharing === "Y") {
      if (model.hasSponsorMatch === "Y") {
        return "BOTH";
      }
      return "PROFIT_SHARING";
    }
    if (model.hasSponsorMatch === "Y") {
      return "MATCH";
    }
    return "NEITHER";
  }

  handleMatchProfitChange(ev) {
    const value = ev.target.value;
    const model = this.state.model;
    switch (value) {
      case "MATCH":
        model.hasSponsorMatch = "Y";
        model.hasProfitSharing = "N";
        model.profitSharingUnit = "";
        model.profitSharingValue = "";
        break;
      case "PROFIT_SHARING":
        if (!model.profitSharingUnit) {
          //default to dollar
          model.profitSharingUnit = "DOLLAR";
        }
        model.hasSponsorMatch = "N";
        model.matchPercentOfContribution = undefined;
        model.maxMatchPercentOfSalary = null;
        model.maxMatchDollarValue = null;
        model.hasProfitSharing = "Y";
        break;
      case "BOTH":
        if (!model.profitSharingUnit) {
          //default to dollar
          model.profitSharingUnit = "DOLLAR";
        }
        model.hasSponsorMatch = "Y";
        model.hasProfitSharing = "Y";
        break;
      default:
        model.hasSponsorMatch = "N";
        model.hasProfitSharing = "N";
        model.matchPercentOfContribution = undefined;
        model.maxMatchPercentOfSalary = null;
        model.maxMatchDollarValue = null;
        model.profitSharingUnit = "";
        model.profitSharingValue = "";
        break;
    }
    this.setState({ model });
    this.handleInputChange.apply(this, arguments);
  }

  handleToggleChange(value, name) {
    const { model } = this.state;
    model[name] = value;
    this.setState({ model });
  }

  handleAccountTypeChange(ev) {
    const value = ev.target.value;
    const model = this.state.model;
    model.accountTypeGroup = value.group;
    model.accountTypeNew = value.type;
    model.accountTypeSubtype = value.subType;

    const typesMap = this.props.accountTypesMap;
    const ownershipTypes = getOwnershipTypes(
      model.accountTypeGroup,
      model.accountTypeNew,
      typesMap
    );
    model.ownershipType = getDefaultValue(model.ownershipType, ownershipTypes);
    model.additionalAttributes = {
      trust: { kindOfTrust: "", kindOfTrustSecondary: "" },
    };

    this.setState({ model, ownershipTypes });
    this.handleInputChange.apply(this, arguments);
  }

  handleEmployerMatchLimitChange(ev) {
    const { model } = this.state;

    const value = ev.target.value;
    const name = ev.target.name;

    // Because the active limit type isn't saved on the server, we need to
    // null out the inactive one. then we'll be able to activate the correct
    // radio button based on which values are null.
    switch (name) {
      case "maxMatchDollarValue": {
        model[name] = value;
        model.maxMatchPercentOfSalary = null;
        break;
      }
      case "maxMatchPercentOfSalary": {
        model[name] = value;
        model.maxMatchDollarValue = null;
        break;
      }
      default: {
        // this case should never happen
        // eslint-disable-next-line no-console
        console.error(
          "Invalid target passed to handleEmployerMatchLimitChange"
        );
      }
    }

    this.setState({ model });
    this.handleInputChange.apply(this, arguments);
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.onSubmit(this.state.model);
    }

    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "edit_account_save_clicked_event"
      );
    }
  }

  renderEditBeneficiaries(isOnUs, closedDate, isOnUsRetirement) {
    if (!isOnUs && !closedDate && !isOnUsRetirement) {
      return (
        <button
          type="button"
          className="pc-btn--link milli u-text-bold js-btn-edit-beneficiaries"
          onClick={this.props.onEditBeneficiariesClick}
        >
          Edit
        </button>
      );
    }

    return null;
  }

  getDetailsComponent() {
    const {
      accountGroups,
      accountTypesMap,
      accountSubTypesMap,
      onProductTypeChange,
      optionsPeople,
      isSimpleInterest,
    } = this.props;
    const { model, ownershipTypes } = this.state;

    if (isSimpleInterest) {
      return (
        <SimpleInterestRateAccountDetails
          model={model}
          onInputChange={this.handleInputChange}
          storeInputRef={this.storeInputRef}
        />
      );
    }

    if (model.productType === "CREDIT_CARD") {
      return (
        <CreditAccountDetails
          model={model}
          accountGroups={accountGroups}
          accountTypesMap={accountTypesMap}
          accountSubTypesMap={accountSubTypesMap}
          onInputChange={this.handleInputChange}
          storeInputRef={this.storeInputRef}
          onAccountTypeChange={this.handleAccountTypeChange}
        />
      );
    }

    if (["LOAN", "MORTGAGE"].includes(model.productType)) {
      return (
        <LoanAccountDetails
          model={model}
          accountGroups={accountGroups}
          accountTypesMap={accountTypesMap}
          accountSubTypesMap={accountSubTypesMap}
          optionsPeople={optionsPeople}
          onInputChange={this.handleInputChange}
          onProductTypeChange={onProductTypeChange}
          storeInputRef={this.storeInputRef}
          onAccountTypeChange={this.handleAccountTypeChange}
          ownershipTypes={ownershipTypes}
        />
      );
    }

    if (
      model.productType === "OTHER_ASSETS" ||
      model.productType === "OTHER_LIABILITIES"
    ) {
      if (model.isHome) {
        return (
          <HomeDetails
            customProduct={this.props.customProduct}
            model={model}
            onInputChange={this.handleInputChange}
            storeInputRef={this.storeInputRef}
          />
        );
      }
      return (
        <CustomProductDetails
          customProduct={this.props.customProduct}
          model={model}
          onInputChange={this.handleInputChange}
          storeInputRef={this.storeInputRef}
        />
      );
    }

    if (model.isEsog) {
      return (
        <StockOptionsDetails
          model={model}
          onInputChange={this.handleInputChange}
          storeInputRef={this.storeInputRef}
        />
      );
    }

    return (
      <FinancialAssetAccountDetails
        model={model}
        accountGroups={accountGroups}
        accountTypesMap={accountTypesMap}
        accountSubTypesMap={accountSubTypesMap}
        optionsPeople={optionsPeople}
        onInputChange={this.handleInputChange}
        storeInputRef={this.storeInputRef}
        onAccountTypeChange={this.handleAccountTypeChange}
        onEmployerMatchLimitChange={this.handleEmployerMatchLimitChange}
        ownershipTypes={ownershipTypes}
        onMatchProfitChange={this.handleMatchProfitChange}
        currentMatchProfitValue={this.currentMatchProfitValue()}
        onToggleChange={this.handleToggleChange}
      />
    );
  }

  shouldDisableSave(model) {
    const isInvestmentAccount = model.productType === "INVESTMENT";
    const showExcludeFromAdvice = hasExcludeFromAdvice(model);

    if (IS_EMPOWER) {
      if (showExcludeFromAdvice) {
        return false;
      }
      const isEmpowerAccount = model.isEmpower;
      const isNonInvestmentEmpowerAccount =
        isEmpowerAccount && !isInvestmentAccount;
      const hasEmpowerRecordKeptPlanMatch =
        isEmpowerAccount &&
        isInvestmentAccount &&
        model.empowerAttributes?.hasRecordKeptPlanMatch;

      return isNonInvestmentEmpowerAccount || hasEmpowerRecordKeptPlanMatch;
    }

    return !window.isAdvisorApp && model.isOnUs;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const { errors, isSimpleInterest } = this.props;
    const { model } = this.state;

    const beneficiaries = model.stakeholders.BENEFICIARY || [];
    const owners = getAllOwners(model.stakeholders);

    const isEmpowerAccount = IS_EMPOWER && model.isEmpower;

    let disclaimer;
    if (!model.isOnUsBank) {
      disclaimer = `Beneficiary information for reference only. To establish legal beneficiaries for this account, you must do so directly with
      ${
        model.isManual
          ? "your financial institution"
          : model.originalFirmName || model.firmName
      }.`;
    }
    // An account can be closed only if is not a PCAP cash account, not already closed and not a Empower account
    const qualifiesForMarkAsClosed =
      !model.isOnUsBank &&
      !model.closedDate &&
      !isSimpleInterest &&
      !isEmpowerAccount;
    // For the advisor app, an account can be closed only if it meets above conditions and it must be a Manual account
    const advisorAppQualifiesForMarkAsClosed =
      model.isManual && qualifiesForMarkAsClosed;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="pc-layout qa-edit-account-form">
          {!isSimpleInterest && (
            <div className="pc-layout__item pc-u-2/5">
              <AccountSummary
                model={model}
                hasTreatAsInvestment={
                  (model.productType === "BANK" ||
                    this.state.canTreatAsInvestment) &&
                  !model.isOnUsBank &&
                  !isEmpowerAccount
                }
                hasExcludeFromAdvice={hasExcludeFromAdvice(model)}
                storeInputRef={this.storeInputRef}
                onInputChange={this.handleInputChange}
                logoClassName={
                  model.isOnUsBank ? "edit-account__logo-container--small" : ""
                }
                onChangeClosedDateClick={this.props.onChangeClosedDateClick}
                onReopenAccountClick={this.props.onReopenAccountClick}
              />
              {(model.isOAuth ||
                model.nextAction.action === "MIGRATE_OAUTH") && (
                <div className="js-update-connection pc-u-mt">
                  <button
                    type="button"
                    className="pc-btn pc-btn--edit-account-link milli u-text-bold js-update-connection-button"
                    onClick={() => this.props.onReconnectClick(true)}
                  >
                    Update Connection
                  </button>
                </div>
              )}
            </div>
          )}
          <div
            className={`pc-layout__item ${
              isSimpleInterest ? "" : "pc-u-3/5"
            } edit-account__section--scrollable`}
          >
            {!window.isAdvisorApp &&
              !isSimpleInterest &&
              !model.isManual &&
              !model.isOnUsBank &&
              !isEmpowerAccount &&
              !model.closedDate &&
              !(
                model.isOAuth || model.nextAction.action === "MIGRATE_OAUTH"
              ) && (
                <div className="edit-account__section-heading js-edit-login-section qa--edit-login-section">
                  <div>User ID and Password </div>
                  <button
                    type="button"
                    className={`pc-btn--link milli u-text-bold ${
                      this.props.isPrivileged ? "is-disabled" : ""
                    }`}
                    onClick={
                      this.props.isPrivileged
                        ? noop
                        : this.props.onEditLoginClick
                    }
                    aria-disabled={this.props.isPrivileged}
                  >
                    Edit
                  </button>
                </div>
              )}
            <div className="edit-account__section-heading">
              <div>Edit Details</div>
            </div>
            {errors && (
              <Message severity="error" messages={errors} className="pc-u-mb" />
            )}
            {this.getDetailsComponent()}
            <CSSTransition
              in={isOwnerShareSupported(
                model.accountTypeGroup,
                model.additionalAttributes &&
                  model.additionalAttributes.jointAccountType
              )}
              unmountOnExit={true}
              classNames="transition-height--large"
              timeout={250}
            >
              <div>
                <div className="edit-account__section-heading">
                  <div>Owners</div>
                  {!model.isOnUs && !model.closedDate && (
                    <button
                      type="button"
                      className="pc-btn--link milli u-text-bold js--edit-login-section__edit-owners qa--edit-login-section__edit-owners"
                      onClick={this.props.onEditOwnersClick}
                    >
                      Edit
                    </button>
                  )}
                </div>
                {owners.length > 0 && (
                  <div className="pc-form-group">
                    <StakeholdersView
                      title="Owner Shares"
                      stakeholders={owners}
                      className="js-owners-view"
                    />
                  </div>
                )}
              </div>
            </CSSTransition>
            <CSSTransition
              in={isBeneficiarySupported(
                model.accountTypeGroup,
                model.ownershipType,
                model.isEsog,
                isEmpowerAccount &&
                  model.originalFirmName !== "empower-retail-pcap-cash"
              )}
              unmountOnExit={true}
              classNames="transition-height--large"
              timeout={250}
            >
              <div>
                <div className="edit-account__section-heading">
                  <div>Beneficiaries</div>
                  {this.renderEditBeneficiaries(
                    model.isOnUs,
                    model.closedDate,
                    model.isOnUsRetirement
                  )}
                </div>
                {beneficiaries.length > 0 && (
                  <div className="pc-form-group">
                    <StakeholdersView
                      title="Primary Beneficiaries"
                      stakeholders={beneficiaries}
                      className="js-beneficiaries-view"
                      disclaimer={disclaimer}
                    />
                  </div>
                )}
              </div>
            </CSSTransition>
          </div>
          <EditAccountFooter
            onCancel={this.props.onCancel}
            onRemove={this.props.onRemoveClick}
            onSave={this.handleSubmit}
            onMarkAsClosed={this.props.onMarkAccountClosedClick}
            hasRemove={
              (!window.isAdvisorApp || (IS_EMPOWER && model.isManual)) &&
              !model.isOnUsBank &&
              !isSimpleInterest &&
              !isEmpowerAccount
            }
            isRemoveDisabled={model.isAccountUsedInFunding}
            isSaveDisabled={this.shouldDisableSave(model)}
            hasSave={!model.closedDate}
            removeDisabledReason={
              model.isAccountUsedInFunding ? IS_USED_FOR_FUNDING_TOOLTIP : ""
            }
            hasMarkAsClosed={
              window.isAdvisorApp
                ? advisorAppQualifiesForMarkAsClosed
                : qualifiesForMarkAsClosed
            }
            isPrivileged={this.props.isPrivileged}
          />
        </div>
      </form>
    );
  }
}

EditAccountView.propTypes = {
  model: PropTypes.object.isRequired,
  errors: PropTypes.array,
  customProduct: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onEditLoginClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onMarkAccountClosedClick: PropTypes.func.isRequired,
  onChangeClosedDateClick: PropTypes.func.isRequired,
  onReopenAccountClick: PropTypes.func.isRequired,
  onEditBeneficiariesClick: PropTypes.func.isRequired,
  onStakeholdersSave: PropTypes.func.isRequired,
  onEditOwnersClick: PropTypes.func.isRequired,
  onProductTypeChange: PropTypes.func,
  onReconnectClick: PropTypes.func,
  isPrivileged: PropTypes.bool,
};

EditAccountView.defaultProps = {
  errors: undefined,
  onProductTypeChange: noop,
  onReconnectClick: noop,
};
