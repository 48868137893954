import React from "react";
import PropTypes from "prop-types";
import { noop } from "underscore";
import Input from "components/common/form/Input";

class ReenterAccountNumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialAccountNumber: props.model.accountNumber,
    };

    this.handlePaste = this.handlePasteOrDropPaste.bind(this);
  }

  handlePasteOrDropPaste(e) {
    e.preventDefault();
  }

  render() {
    const { model, onChange, refFunc, validator } = this.props;

    let showReenterAccountNumber = false;
    if (model.accountNumber !== this.state.initialAccountNumber) {
      showReenterAccountNumber = true;
    }

    return (
      <>
        <div className="field-set field-set--horizontal field-set--accountDetails qa-input-field">
          <label
            htmlFor="modifiedAccountNumber"
            className="pc-label pc-u-mb- field-set__label "
          >
            Account number
          </label>
          <Input
            name="accountNumber"
            id="modifiedAccountNumber"
            type="text"
            ref={refFunc}
            onChange={onChange}
            value={model.accountNumber}
            validator={validator}
          />
        </div>
        {showReenterAccountNumber && (
          <div className="field-set field-set--horizontal field-set--accountDetails qa-input-field">
            <label
              htmlFor="reenterAccountNumber"
              className="pc-label pc-u-mb- field-set__label "
            >
              Re-enter account number
            </label>
            <Input
              id="reenterAccountNumber"
              name="reenterAccountNumber"
              type="text"
              onChange={onChange}
              ref={refFunc}
              onPaste={this.handlePasteOrDropPaste}
              onDrop={this.handlePasteOrDropPaste}
              autoComplete={"off"}
              validator={{
                allowEmpty: false,
                required: true,
                pattern: new RegExp(`^${model.accountNumber}$`),
                messages: { pattern: "Account number does not match." },
              }}
            />
          </div>
        )}
      </>
    );
  }
}

ReenterAccountNumberInput.propTypes = {
  model: PropTypes.object,
  onChange: PropTypes.func,
  refFunc: PropTypes.func,
  validator: PropTypes.object,
};

ReenterAccountNumberInput.defaultProps = {
  model: {},
  onChange: noop,
  refFunc: noop,
  validator: {},
};

export default ReenterAccountNumberInput;
