import PropTypes from "prop-types";
import React from "react";
import mixpanel from "mixpanel";
import { formatNumber, formatPercent } from "libs/pcap/utils/format";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import InvestmentCheckupBarChart from "components/opportunitiesSummary/InvestmentCheckup/InvestmentCheckupBarChart";
import AdviceDisclaimerModal from "components/modal/InvestmentCheckupDisclaimerModal";

const DELTA_AMOUNT_VISIBILITY_THRESHOLD = 1000; // Do not show amount if difference is less than this number
const DELTA_RISK_VISIBILITY_MIN_THRESHOLD_IN_PERCENTAGE = 1; // Do not show percentage if difference is less than this number
const DELTA_RISK_VISIBILITY_MAX_THRESHOLD_IN_PERCENTAGE = 30; // Show the word `significantly` instead of actual percentage if difference is greater than or equal to this number
const PERCENT_MULTIPLIER = 100;

const ChartLegends = () => {
  return (
    <div className="insight__summary--investment-checkup-legends">
      <div className="pc-layout pc-layout--flush">
        <div className="pc-layout__item pc-u-1/2">
          <div className="insight__summary--investment-checkup-legend">
            <span className="insight__summary--investment-checkup-legend-icon">
              &nbsp;
            </span>{" "}
            Your Allocation
          </div>
        </div>
        <div className="pc-layout__item pc-u-1/2">
          <div className="insight__summary--investment-checkup-legend">
            <span className="insight__summary--investment-checkup-legend-icon insight__summary--investment-checkup-legend-icon--target-allocation">
              &nbsp;
            </span>{" "}
            Alternative allocation
          </div>
        </div>
      </div>
    </div>
  );
};

class InvestmentCheckup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetClasses: props.assetClasses || [],
      showDisclaimerModal: false,
    };
    this.onDisclaimerClick = this.onDisclaimerClick.bind(this);
  }

  componentDidMount() {
    mixpanel.trackEvent("View Investment Checkup Insight", {
      component: "insights",
    });
  }

  getSummary() {
    const { isClient, medianScenarioDelta, risks } = this.props;
    const targetRiskIsLower = risks.target < risks.user;
    const deltaRisk =
      ((risks.user - risks.target) / risks.user) * PERCENT_MULTIPLIER;
    const deltaRiskFormatted = formatPercent(deltaRisk, { precision: 0 });
    const deltaAmountFormatted = "$" + formatNumber(medianScenarioDelta, 0);
    const disclaimerLink = (
      <span>
        (
        <a
          href="#"
          className="insight__disclaimer-link"
          onClick={this.onDisclaimerClick}
        >
          disclaimer
        </a>
        )
      </span>
    );
    const summaryAppend = (
      <span>Below are changes we recommend implementing {disclaimerLink}.</span>
    );
    const deltaValueContainerClassNames =
      "insight__summary--investment-checkup-delta--negative";
    const styledDeltaAmount =
      medianScenarioDelta >= DELTA_AMOUNT_VISIBILITY_THRESHOLD ? (
        <span className={deltaValueContainerClassNames}>
          {deltaAmountFormatted}
        </span>
      ) : (
        ""
      );
    let styledDeltaRisk =
      deltaRisk >= DELTA_RISK_VISIBILITY_MIN_THRESHOLD_IN_PERCENTAGE ? (
        <span className={deltaValueContainerClassNames}>
          {deltaRiskFormatted}
        </span>
      ) : (
        ""
      );
    if (deltaRisk >= DELTA_RISK_VISIBILITY_MAX_THRESHOLD_IN_PERCENTAGE) {
      styledDeltaRisk = "significantly";
    }
    let summary = <div>{summaryAppend}</div>;
    let reduceVerticalMargins = false;

    if (isClient) {
      if (medianScenarioDelta > 0) {
        summary = (
          <div>
            {`Your outside investments have a ${styledDeltaAmount} lower expected return than your alternative allocation. Consolidating these accounts can increase your returns `}
            {disclaimerLink}.
          </div>
        );
        reduceVerticalMargins = true;
      } else if (targetRiskIsLower) {
        summary = (
          <div>
            {`Your outside investments have a ${styledDeltaRisk} higher expected risk than your alternative allocation. Consolidating these accounts can lower your risk `}
            {disclaimerLink}.
          </div>
        );
        reduceVerticalMargins = true;
      } else {
        // These clients should not receive the Investment Checkup widget from ACE
      }
    } else if (medianScenarioDelta > 0) {
      summary = (
        <div>
          {`Your current portfolio has a ${styledDeltaAmount} lower expected return than your alternative allocation. ${summaryAppend}`}
        </div>
      );
    } else if (targetRiskIsLower) {
      summary = (
        <div>
          {`Your current portfolio has a ${styledDeltaRisk} higher expected risk than your alternative allocation. ${summaryAppend}`}
        </div>
      );
    } else {
      summary = (
        <div>
          Based on your investment profile and retirement time horizon, we
          recommend the following changes to your asset allocation strategy{" "}
          {disclaimerLink}.
        </div>
      );
    }

    return { summary, reduceVerticalMargins };
  }

  generateCharts() {
    const { xDomain } = this.props;
    return this.state.assetClasses.map((assetClass) => {
      return (
        <div key={assetClass.key}>
          <div className="pc-layout">
            <div className="pc-layout__item pc-u-1/2">
              {assetClass.name || assetClass.label}
            </div>
            <div className="pc-layout__item pc-u-1/2 u-text-right">
              {`${assetClass.action === "increase" ? "+" : "-"}$${formatNumber(
                assetClass.amount,
                0
              )}`}
            </div>
          </div>
          <div className="insight__chart-container insight__chart-container--investment-checkup">
            <InvestmentCheckupBarChart
              xDomain={xDomain}
              chartData={assetClass.chartData}
            />
          </div>
        </div>
      );
    });
  }

  onDisclaimerClick(event) {
    event.preventDefault();
    this.setState({ showDisclaimerModal: true });
  }

  render() {
    const { message = {}, markAsViewed } = this.props;
    const { summary, reduceVerticalMargins } = this.getSummary();
    const classToReduceVerticalMargins = reduceVerticalMargins
      ? "pc-u-mb--"
      : "";
    return (
      <div className="insight__message insight__message--investment-checkup">
        <div
          className="insight__header qa-insight-header"
          dangerouslySetInnerHTML={{
            __html: message.title || "Investment Checkup",
          }}
        />
        <div className={`insight__summary ${classToReduceVerticalMargins}`}>
          <div
            className={`insight__summary insight__summary--investment-checkup ${classToReduceVerticalMargins}`}
          >
            {summary}
          </div>
          {this.generateCharts()}
          <ChartLegends />
        </div>
        <div className="insight__buttons">
          {getActionButtons({
            message,
            markAsViewed,
            appointmentReason: "Investment Checkup Insight Widget",
          })}
        </div>
        <AdviceDisclaimerModal
          componentName="Investment Checkup Insight Widget"
          isOpen={this.state.showDisclaimerModal}
          onClosed={() => {
            this.setState({ showDisclaimerModal: false });
          }}
        />
      </div>
    );
  }
}

InvestmentCheckup.propTypes = {
  xDomain: PropTypes.array,
  assetClasses: PropTypes.array,
  message: PropTypes.object,
  markAsViewed: PropTypes.func,
  isClient: PropTypes.bool,
  medianScenarioDelta: PropTypes.number,
  risks: PropTypes.object,
  expectedReturns: PropTypes.object,
};

export default InvestmentCheckup;
