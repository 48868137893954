import React from "react";
export default function LinkAccount() {
  return (
    <svg
      width="110px"
      height="110px"
      viewBox="0 0 110 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ACE_Link_Accnt</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
        <polygon
          id="path-2"
          points="0 62.7534722 72.9015486 62.7534722 72.9015486 0 0 0"
        ></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="ACE_LinkAccnt_A-Has-Linked"
          transform="translate(-75.000000, -62.000000)"
        >
          <g id="ACE_Link_Accnt" transform="translate(75.000000, 62.000000)">
            <g filter="url(#filter-1)" id="circle">
              <g transform="translate(0.000000, -0.000611)">
                <path
                  d="M110,54.9992369 C110,85.3747971 85.3759816,110 55,110 C24.6255445,110 0,85.3747971 0,54.9992369 C0,24.6252029 24.6255445,0 55,0 C85.3759816,0 110,24.6252029 110,54.9992369"
                  id="Fill-1"
                  fill="#FDF8E7"
                ></path>
              </g>
            </g>
            <g transform="translate(20.094995, 22.916819)">
              <path
                d="M11.3887609,6.20125 L15.0275006,6.20125 C29.1913802,6.20125 19.7158174,24.4001389 33.3865968,24.4001389 L38.2140328,24.4001389"
                stroke="#002157"
                strokeWidth="1.5"
              ></path>
              <path
                d="M11.3887609,56.5522222 L15.0275006,56.5522222 C29.1913802,56.5522222 19.7158174,38.3533333 33.3865968,38.3533333 L38.2140328,38.3533333"
                stroke="#002157"
                strokeWidth="1.5"
              ></path>
              <line
                x1="49.2253169"
                y1="31.5901667"
                x2="71.261797"
                y2="31.5901667"
                stroke="#002157"
                strokeWidth="1.5"
              ></line>
              <line
                x1="11.3887609"
                y1="31.5901667"
                x2="38.7535061"
                y2="31.5901667"
                stroke="#002157"
                strokeWidth="1.5"
              ></line>
              <polyline
                stroke="#DB1830"
                strokeWidth="1.5"
                points="66.0792979 26.4681389 71.2622608 31.5907778 66.0792979 36.7118889"
              ></polyline>
              <mask id="mask-3" fill="white">
                <use xlinkHref="#path-2"></use>
              </mask>
              <g id="Clip-12"></g>
              <polygon
                stroke="#002157"
                strokeWidth="1.5"
                mask="url(#mask-3)"
                points="38.6905933 43.9847222 45.3404907 43.9847222 45.3404907 19.0361111 38.6905933 19.0361111"
              ></polygon>
              <path
                d="M11.3887609,6.20125 C11.3887609,8.9925 9.09947725,11.2566667 6.27381187,11.2566667 C3.44969225,11.2566667 1.15886288,8.9925 1.15886288,6.20125 C1.15886288,3.40847222 3.44969225,1.14430556 6.27381187,1.14430556 C9.09947725,1.14430556 11.3887609,3.40847222 11.3887609,6.20125 Z"
                stroke="#DB1830"
                strokeWidth="1.5"
                mask="url(#mask-3)"
              ></path>
              <path
                d="M11.3887609,31.5901667 C11.3887609,34.3829444 9.09947725,36.6471111 6.27381187,36.6471111 C3.44969225,36.6471111 1.15886288,34.3829444 1.15886288,31.5901667 C1.15886288,28.7989167 3.44969225,26.53475 6.27381187,26.53475 C9.09947725,26.53475 11.3887609,28.7989167 11.3887609,31.5901667 Z"
                stroke="#DB1830"
                strokeWidth="1.5"
                mask="url(#mask-3)"
              ></path>
              <path
                d="M11.3887609,56.5522222 C11.3887609,53.7609722 9.09947725,51.4968056 6.27381187,51.4968056 C3.44969225,51.4968056 1.15886288,53.7609722 1.15886288,56.5522222 C1.15886288,59.3434722 3.44969225,61.6076389 6.27381187,61.6076389 C9.09947725,61.6076389 11.3887609,59.3434722 11.3887609,56.5522222 Z"
                stroke="#DB1830"
                strokeWidth="1.5"
                mask="url(#mask-3)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
