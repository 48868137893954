/* eslint-disable no-invalid-this, no-magic-numbers */
import LegendTemplate from "templates/cashFlow/legend.html";

import _ from "underscore";
const LEGEND_CONTAINER_MAX_HEIGHT = 240;
const LEGEND_CONTAINER_SCROLL_LIMIT = 9;

export default {
  renderLegend: function (slices) {
    var self = this,
      scrollingUp = false,
      scrollingDown = false,
      onLegendContainerMouseout = function () {
        self.renderDonutLabels();
        self.$el.find(".highlight").removeClass("highlight");
      },
      dispatchSliceEvent = function (legendItem, mouseEventType) {
        var sliceId = legendItem.getAttribute("data-sliceid");

        var slice = _.find(slices, function (slice) {
          // Slice ID for 1st level are int == string
          // Slice ID for 2nd level are string == string
          // Removing type check
          //eslint-disable-next-line eqeqeq
          return slice.sliceId == sliceId;
        });

        if (slice && slice.amount > 0 && slice.element) {
          window.PCAP.NativeEventEmitter.dispatchMouseEvent(
            slice.element.node,
            mouseEventType
          );
        }
      },
      onLegendItemMouseover = function (event) {
        self.renderDonutLabels(event);
        dispatchSliceEvent(this, "mouseover");
      },
      onLegendItemMouseout = function () {
        dispatchSliceEvent(this, "mouseout");
      },
      onLegendItemClick = function () {
        dispatchSliceEvent(this, "click");
      },
      scrollUpStart = function (event) {
        var containerPosition = self.container.position().top;
        var scrollUpButton = $(event.currentTarget);
        var scrollDownButton = $(".scrollDown");

        if (Math.abs(containerPosition) > 0 && !scrollingUp) {
          scrollingUp = true;
          containerPosition += LEGEND_CONTAINER_MAX_HEIGHT;

          if (
            Math.abs(containerPosition) === 0 &&
            !scrollUpButton.hasClass("disabled")
          ) {
            scrollUpButton.addClass("disabled");
          } else if (scrollUpButton.hasClass("disabled")) {
            scrollUpButton.removeClass("disabled");
          }

          containerPosition = containerPosition + "px";

          $(self.container).animate(
            { top: containerPosition },
            500,
            function () {
              scrollingUp = false;
            }
          );

          if (scrollDownButton.hasClass("disabled")) {
            scrollDownButton.removeClass("disabled");
          }
        } else if (!scrollUpButton.hasClass("disabled")) {
          scrollUpButton.addClass("disabled");
        }
      },
      scrollDownStart = function (event) {
        var containerPosition = self.container.position().top;
        var scrollDownButton = $(event.currentTarget);
        var scrollUpButton = $(".scrollUp");

        if (
          Math.abs(containerPosition) < self.scrollDistance &&
          !scrollingDown
        ) {
          scrollingDown = true;
          containerPosition -= LEGEND_CONTAINER_MAX_HEIGHT;

          if (
            Math.abs(containerPosition) >= self.scrollDistance &&
            !scrollDownButton.hasClass("disabled")
          ) {
            scrollDownButton.addClass("disabled");
          } else if (scrollDownButton.hasClass("disabled")) {
            scrollDownButton.removeClass("disabled");
          }

          containerPosition = containerPosition + "px";

          $(self.container).animate(
            { top: containerPosition },
            500,
            function () {
              scrollingDown = false;
            }
          );

          if (scrollUpButton.hasClass("disabled")) {
            scrollUpButton.removeClass("disabled");
          }
        } else if (!scrollDownButton.hasClass("disabled")) {
          scrollDownButton.addClass("disabled");
        }
      };

    this.$el.find(".legend").html(
      LegendTemplate({
        items: slices,
        isNegative: this.incomeExpenseMode === "expense",
      })
    );

    this.legendItems = [];

    _.each(slices, function (slice) {
      var item = self.$el.find('[data-sliceid="' + slice.sliceId + '"]');
      if (item.length > 0) {
        item.mouseover(onLegendItemMouseover);
        item.mouseout(onLegendItemMouseout);
        item.click(onLegendItemClick);
        self.legendItems.push(item);
      }
    });

    this.$el
      .find(".legendDonut")
      .find(".legend")
      .mouseout(onLegendContainerMouseout);

    this.scrollArea = this.$el.find(".js-cashflow-scrollable");
    this.container = this.$el.find(".js-cashflow-scrollable__container");

    this.on(
      "donutLegendRendered",
      function () {
        this.scrollDistance =
          this.container.height() - this.scrollArea.height();
      }.bind(this)
    );

    if (slices.length > LEGEND_CONTAINER_SCROLL_LIMIT) {
      this.scrollArea.css("height", `${LEGEND_CONTAINER_MAX_HEIGHT}px`);
      this.scrollDistance = this.container.height() - this.scrollArea.height();
      this.$el.find(".scrollUp").show();
      this.$el.find(".scrollDown").show();
      this.$el.find(".scrollUp").mousedown(scrollUpStart);
      this.$el.find(".scrollDown").mousedown(scrollDownStart);
    } else {
      var height =
        (slices.length * LEGEND_CONTAINER_MAX_HEIGHT) /
        LEGEND_CONTAINER_SCROLL_LIMIT;
      height = height + "px";
      this.scrollArea.css("height", height);

      this.$el.find(".scrollUp").hide();
      this.$el.find(".scrollDown").hide();
    }
  },

  fadeOutLegend: function () {
    if (this.getDrilldownLevel() !== 2 && this.previousLevel !== 2) {
      var legend = $(".legend");
      legend.fadeTo(200, 0);
    }
  },

  fadeInLegend: function () {
    var legend = $(".legend");
    legend.fadeTo(200, 1);
  },
};
