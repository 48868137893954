import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { formatCurrency, formatNumber } from "libs/pcap/utils/format";
import objectPath from "object-path";

const CURRENT_PRICE_PRECISION = 4;

const StockOptionsNav2 = (props) => {
  const {
    isPublic,
    totalValue,
    closedDate,
    stockPrice,
    handleCurrentPriceChange,
    handleUseWhatIfPrice,
    handleWhatIfPriceChange,
    grants,
    isPrivileged,
  } = props;

  const [currentPrice, setCurrentPrice] = useState("");
  const [isCurrentValueFieldFocused, setIsCurrentCalueFieldFocused] =
    useState(false);

  useEffect(() => {
    const currentPrice = objectPath.get(grants, "0.currentPrice");
    if (isCurrentValueFieldFocused === false) {
      setCurrentPrice(formatNumber(currentPrice, 2));
    }
  }, [JSON.stringify(grants)]);

  return (
    <div className="nav-secondary nav-secondary--feature-controls">
      <div className="l-spaced l-spaced--flush">
        <div className="pc-layout pc-layout--middle pc-layout--auto centi">
          {!closedDate && (
            <div className="account-details-info pc-layout__item">
              Total{" "}
              <span className="account-details-info__value">
                {formatCurrency(totalValue, 0)}
              </span>
            </div>
          )}
          {closedDate && (
            <div className="account-details-info pc-layout__item">
              Closed{" "}
              <span className="account-details-info__value">{closedDate}</span>
            </div>
          )}
          {isPublic && !closedDate && (
            <div className="account-details-info pc-layout__item">
              Current Price{" "}
              <span className="account-details-info__value">
                {formatCurrency(stockPrice, CURRENT_PRICE_PRECISION)}
              </span>
            </div>
          )}
          {!(isPublic || closedDate) && (
            <div className="pc-layout__item">
              <label className="pc-label pc-label--inline pc-label--small">
                Current Price&nbsp;
              </label>
              <div className="pc-input-group pc-input-group--small">
                <form>
                  <label className="pc-input-group__label pc-input-group__label--prefix">
                    $
                  </label>
                  <input
                    value={currentPrice}
                    onChange={(event) => {
                      setCurrentPrice(event.currentTarget.value);
                      handleCurrentPriceChange(event.currentTarget);
                    }}
                    type="number"
                    className="pc-input-group__field"
                    min="0"
                    step="0.1"
                    onFocus={() => setIsCurrentCalueFieldFocused(true)}
                    onBlur={() => setIsCurrentCalueFieldFocused(false)}
                    disabled={isPrivileged}
                  />
                </form>
              </div>
            </div>
          )}
          {!closedDate && (
            <div className="pc-layout__item">
              <label className="pc-label pc-label--inline pc-label--small">
                <input type="checkbox" onChange={handleUseWhatIfPrice} />
                What-if Price&nbsp;
              </label>
              <div className="pc-input-group pc-input-group--small">
                <label className="pc-input-group__label pc-input-group__label--prefix">
                  $
                </label>
                <input
                  type="number"
                  className="pc-input-group__field"
                  min="0"
                  step="0.1"
                  onChange={(event) =>
                    handleWhatIfPriceChange(event.currentTarget)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StockOptionsNav2.propTypes = {
  totalValue: PropTypes.number,
  isPublic: PropTypes.bool,
  stockPrice: PropTypes.number,
  closedDate: PropTypes.string,
  handleCurrentPriceChange: PropTypes.func.isRequired,
  handleUseWhatIfPrice: PropTypes.func.isRequired,
  handleWhatIfPriceChange: PropTypes.func.isRequired,
  grants: PropTypes.array,
  isPrivileged: PropTypes.bool,
};

StockOptionsNav2.defaultProps = {
  totalValue: 0,
  isPublic: false,
  stockPrice: 0,
  closedDate: "",
  grants: [],
  isPrivileged: false,
};

export default StockOptionsNav2;
