var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='notificationIcon expand sticky sidebar-account__status-icon sidebar-account__status-icon--warning qa-sidebar-account-status-icon-warning' data-toggle='tooltip' title='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "'>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='notificationIcon expand sticky sidebar-account__status-icon sidebar-account__status-icon--error qa-sidebar-account-status-icon-error' data-toggle='tooltip' title='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "'>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " - "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " - "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"originalName") : depth0), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " Balance: "
    + container.escapeExpression(__default(require("../../helpers/dollarAndCentsAmount.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"balance") : depth0),false,true,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":15,"column":199},"end":{"line":15,"column":243}}}));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " - "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(__default(require("../../helpers/timestamp.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0),{"name":"timestamp","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":35}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "        <div class='sidebar-account__detail-name qa-sidebar-account-detail-name' title='"
    + alias2(alias1(depth0, depth0))
    + "' data-hj-masked>"
    + alias2(alias1(depth0, depth0))
    + "</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"sidebar-account__progress-bar progressBar\">\n    <span class=\"progressMessage micro\">Establishing secure connection...</span>\n  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sidebar-account__header qa-sidebar-account-header\">\n  <div class=\"sidebar-account__row\">\n    <div class=\"sidebar-account__first-col\">\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","WARNING",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":7,"column":18}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","ERROR",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":11,"column":18}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"sidebar-account__second-col\">\n      <div class=\"sidebar-account__title js-sidebar-account-title qa-sidebar-account-title\" aria-label='"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"firmName") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":15,"column":116},"end":{"line":15,"column":173}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"balance") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":174},"end":{"line":15,"column":250}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":251},"end":{"line":15,"column":329}}})) != null ? stack1 : "")
    + "'>\n        "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"firmName") : depth0), depth0))
    + "\n      </div>\n    </div>\n\n    <div class=\"sidebar-account__third-col\">\n      <div class=\"sidebar-account__time\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n\n  <div class=\"sidebar-account__row js-need-attention-site__accounts-names is-hidden\">\n    <div class=\"pc-u-ml u-clip-text\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"names") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":6},"end":{"line":33,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias2((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.noop,"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":37,"column":2},"end":{"line":41,"column":20}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});