/* eslint-disable camelcase */
/* cSpell:disable */
import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import searchData from "libs/pcap/table/data/search";

/**
 * Display input box and calls callback on input change.
 *
 *
 * ## Props
 *  - `className` {String}     - ClassName applied to pagination nav.
 *  - `placeholder` {String}   - Placeholder text for input box.
 *  - `searchConfig` {Array}   - Optional : Data accessors of data array for search. Accessors take in an element of data array and return value string comparable to text inputed.
 *                                For each data point, each accessor will be used to find a match with the input string.
 *  - `searchIterator` {Function} - Optional: The function that will iterate over the data running the searchConfig. This is a good place to filter out unwanted search results i.e onUs transactions
 *                                For each data point, it will run the all searchConfig Array functions to determinate if is a match or not.
 *                                To filter out results, make a condition before running the searchConfig accessors.
 *  - `data` {Array}           - Optional : Data to search through.
 *  - `onSearch` {Function}    - Callback function called after `search` function is run.
 *                                  Receives the following arguments:
 *                                  1. `searchResults` - If searchConfig and data are specified, is the result of the search on data retrieved using accessors in searchConfig. Otherwise is data property.
 *                                  2. `searchInput` - Text value of the input.
 *
 * @class Search
 * @extends {Component}
 */
export default class Search extends React.Component {
  constructor(props) {
    super(props);

    const { searchInput, searchConfig, data, onSearch } = props;
    this.state = {
      searchInput,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClear = this.handleClear.bind(this);

    if (searchInput) {
      let searchResults = props.searchIterator(searchInput, data, searchConfig);

      if (onSearch) {
        onSearch(searchResults, searchInput);
      }
    }
  }

  render() {
    const {
      className,
      label,
      containerClassName,
      clearClassName,
      labelClassName,
      placeholder,
    } = this.props;
    return (
      <div className={`pc-search-input ${containerClassName}`}>
        <Input
          id="transaction-search-input"
          type="text"
          containerClassName="pc-input-group"
          className={
            "pc-input-group__field qa-search-component " + (className || "")
          }
          name="searchInput"
          prefix={label}
          prefixClassName={labelClassName}
          ref={this.storeInputRef}
          placeholder={placeholder ?? ""}
          onChange={this.handleInputChange}
          value={this.state.searchInput}
        />
        {this.state.searchInput && (
          <button
            type="button"
            className={`pc-input-group__button ${clearClassName || ""}`}
            title="Clear"
            aria-label="Clear"
            onClick={this.handleClear}
          >
            &times;
          </button>
        )}
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //eslint-disable-line react/no-deprecated
    if (nextProps.searchInput !== this.props.searchInput) {
      this.handleInputChange({
        target: { name: "searchInput", value: nextProps.searchInput },
      });
    }
  }

  handleInputChange(ev) {
    const searchInput = ev.target && ev.target.value;
    const { onSearch, data, searchConfig, searchIterator } = this.props;
    let searchResults = searchIterator(searchInput, data, searchConfig);

    if (onSearch) {
      onSearch(searchResults, searchInput);
    }

    this.setState({
      searchInput,
    });
  }

  handleClear() {
    this.handleInputChange({ target: { name: "searchInput", value: "" } });
  }
}

Search.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  clearClassName: PropTypes.string,
  onSearch: PropTypes.func,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  data: PropTypes.array,
  searchConfig: PropTypes.array,
  searchInput: PropTypes.string,
  searchIterator: PropTypes.func,
  placeholder: PropTypes.string,
};

Search.defaultProps = {
  containerClassName: undefined,
  className: undefined,
  onSearch: undefined,
  data: [],
  searchConfig: [],
  searchInput: "",
  clearClassName: "",
  searchIterator: searchData,
  label: "Search",
  labelClassName: "pc-input-group__label--prefix-inline",
  placeholder: "",
};
