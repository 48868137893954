import { isEmpty } from "underscore";

export function getByAccountIds(
  sourceAccountId,
  targetAccountId,
  transferInstructions
) {
  if (!sourceAccountId || !targetAccountId || !transferInstructions) {
    return;
  }

  return transferInstructions.find(
    (ti) =>
      (ti.sourceAccountId === sourceAccountId ||
        ti.sourceAccountId === targetAccountId) &&
      (ti.targetAccountId === targetAccountId ||
        ti.targetAccountId === sourceAccountId)
  );
}

/**
 * builds the displayable `transfersData` by mapping `transferInstructions`to `accounts` array data
 * used to build recent transfers list and recurring transfers table
 *
 * @param {Array} accounts array of accounts retrieved by getAccount API
 * @param {Array} transferInstructions array of transfer instructions
 * @param {Function} toDisplayObject toDisplayObject(fromAccount, toAccount, transferInstruction) function to format data into object for display in table or list
 * @returns {Array} returns list of objects otherwise empty array if could not map transfer instructions to account data
 *
 */

export const getTransfersData = (
  accounts,
  transferInstructions,
  toDisplayObject
) => {
  if (isEmpty(accounts)) {
    return [];
  }

  const transfersData = [];
  transferInstructions.forEach((t) => {
    const fromAccount = accounts.find(
      (a) => a.userAccountId === t.sourceAccountId
    );
    const toAccount = accounts.find(
      (a) => a.userAccountId === t.targetAccountId
    );
    if (fromAccount && toAccount) {
      transfersData.push(toDisplayObject(fromAccount, toAccount, t));
    }
  });

  return transfersData;
};
