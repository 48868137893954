import PropTypes from "prop-types";
import React from "react";
import {
  getPlaAttributionSource,
  getUserMessageActionTrackingFlag,
  getResourceImageBlock,
  getResourceEmbedBlock,
} from "views/components/postLoginActions/helpers";
import { noop } from "underscore";

// Presentation layer for default interjection modal content component
export default class BasicContentInterjection extends React.Component {
  getButtons() {
    const { data } = this.props;
    const actions = data.action;
    if (actions) {
      return actions.map((action, index) => {
        return (
          <a
            key={index}
            className="simple-image__cta pc-btn pc-btn--primary"
            target={`${action.type === "TAB" ? "_blank" : "_self"}`}
            data-attribution-source={getPlaAttributionSource(data, action)}
            data-tracking-flag={getUserMessageActionTrackingFlag(action)}
            onClick={() => {
              this.props.onActionClick(action.label);
            }}
            href={action.url}
          >
            {action.label}
          </a>
        );
      });
    }
    return null;
  }
  render() {
    const { data } = this.props;
    return (
      <div className="pc-layout pc-layout--auto pc-layout--flush basic-content-interjection">
        <div dangerouslySetInnerHTML={{ __html: data.explanation }} />
        <div className="basic-content-interjection__resource">
          {getResourceEmbedBlock(data) || getResourceImageBlock(data)}
        </div>
        <div dangerouslySetInnerHTML={{ __html: data.summary }} />
        <div className="basic-content-interjection__actions">
          {this.getButtons()}
        </div>
      </div>
    );
  }
}

BasicContentInterjection.propTypes = {
  data: PropTypes.object.isRequired,
  onActionClick: PropTypes.func,
};

BasicContentInterjection.defaultProps = {
  onActionClick: noop,
};
