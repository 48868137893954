import PropTypes from "prop-types";
import React from "react";
import ConfirmModal from "common/ConfirmModal/ConfirmModal";

const EmpowerWithdrawNonManagedModal = (props) => {
  const { onCancel, onConfirm, onClosed } = props;

  return (
    <ConfirmModal
      title={"You are requesting to withdraw your full available balance."}
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelLabel={"Keep account open"}
      confirmLabel={"Close account"}
      isOpen={true}
      showCloseButton={true}
      onClosed={onClosed}
    >
      <div className="empower-withdraw-non-managed-modal--body">
        Please select below whether you want to keep the account open or close
        the account.
      </div>
      <div className="empower-withdraw-non-managed-modal--reminder">
        By clicking <em>Close account</em>, I understand the terms and
        conditions. Any remaining funds will be sent to my address on file.
      </div>
    </ConfirmModal>
  );
};

EmpowerWithdrawNonManagedModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
};

export default EmpowerWithdrawNonManagedModal;
