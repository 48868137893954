import PropTypes from "prop-types";
import React from "react";
import ReactDatetime from "react-datetime";
import BaseInput from "../BaseInput";
import moment from "moment";
import {
  API_FORMAT,
  DISPLAY_FORMAT,
  toDisplayFormatDateIfValid,
  DISPLAY_DATE_FORMAT,
} from "libs/pcap/utils/date2";

const DEFAULT_DATE_FORMAT = "MM-DD-YYYY";
export default class DatePickerInput extends BaseInput {
  componentWillUnmount() {
    this.unmounted = true;
  }

  focus() {
    this.ref.focus();
  }

  /**
   * Since reactDateTime returns the moment instance instead of the event, we need to mock it
   * to a React event so BaseInput:handleChange doesn't throw a NPE when reading the target.
   *
   * @param {moment} value  A moment instance representing the selected value if date is valid
   *        {String} value  A string representing the entered value if date is invalid
   */
  handleChange(value) {
    const { name, displayDateFormat } = this.props;
    // Always validate
    if (!this.state.validationStarted) {
      this.setState({ validationStarted: true });
    }

    super.handleChange({
      target: {
        value:
          value instanceof moment ? value.format(displayDateFormat) : value,
        name,
        type: "text",
      },
    });
  }

  render() {
    const {
      helpText,
      sizeVariation,
      onFocus,
      errorBlockClassName,
      value,
      displayDateFormat,
    } = this.props;
    const inputGroupClassName = sizeVariation
      ? `pc-input-group--${sizeVariation}`
      : "";
    const inputSizeClassName = sizeVariation ? `input--${sizeVariation}` : "";
    const inputClassName = `input pc-input-date ${
      this.props.className || ""
    } ${inputSizeClassName} ${this.state.valid ? "" : "input--error"}`;

    let inputProps = Object.assign({
      placeholder: this.props.placeholder || displayDateFormat,
      disabled: this.props.disabled || false,
      readOnly: this.props.readOnly || false,
      className: inputClassName,
      autoComplete: "off",
      maxLength: DEFAULT_DATE_FORMAT.length,
      name: this.props.name, //This might be in the reactDateTime component
      id: this.props.id || this.id,
    });

    // Date is invalid if user manually types partial date
    const displayValue = toDisplayFormatDateIfValid({
      date: value,
      displayFormat: displayDateFormat,
    });

    return (
      <div className={`pc-input-group ${inputGroupClassName}`}>
        <ReactDatetime
          ref={(el) => {
            this.ref = el;
          }}
          type={this.props.type}
          input={true}
          inputProps={inputProps}
          viewMode={this.props.viewMode}
          placeholder={this.props.placeholder}
          name={this.props.name}
          value={displayValue}
          dateFormat={displayDateFormat}
          timeFormat={this.props.timeFormat}
          closeOnSelect={this.props.closeOnSelect}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={onFocus}
          isValidDate={this.props.isAllowedDate}
          className={this.props.position === "top" ? "rdt--position-top" : ""}
        />
        {helpText && (
          <label className="pc-help-block pc-help-block--tiny u-text-left">
            {helpText}
          </label>
        )}
        {this.getErrorBlock({
          placeholder: this.props.errorPlaceholder,
          className: errorBlockClassName,
        })}
      </div>
    );
  }
}

DatePickerInput.propTypes = Object.assign({}, BaseInput.propTypes, {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  errorBlockClassName: PropTypes.string,
  sizeVariation: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  helpText: PropTypes.string,
  viewMode: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  displayDateFormat: PropTypes.string,
  timeFormat: PropTypes.bool,
  errorPlaceholder: PropTypes.bool,
  isAllowedDate: PropTypes.func,
  position: PropTypes.string,
});

DatePickerInput.defaultProps = Object.assign({}, BaseInput.defaultProps, {
  closeOnSelect: true,
  displayDateFormat: DEFAULT_DATE_FORMAT,
  timeFormat: false,
  viewMode: "days",
});

DatePickerInput.getValidator = ({
  valueDateFormat = DISPLAY_FORMAT,
  allowEmpty,
  isAllowedDate,
}) => {
  return {
    allowEmpty,
    conform: (value) => {
      // empty value is handled by `allowEmpty` validator
      if (value === "") {
        return true;
      }

      // Making sure that the date value is in correct format.
      if (
        valueDateFormat === API_FORMAT &&
        typeof value === "string" &&
        value.split("-")[0].length === DISPLAY_DATE_FORMAT.split("-")[0].length
      ) {
        valueDateFormat = DISPLAY_DATE_FORMAT;
      }
      const date = moment(value, valueDateFormat, true /* strict parse mode */);
      return (
        date.isValid() &&
        (!isAllowedDate || (isAllowedDate && isAllowedDate(date)))
      );
    },
    messages: {
      conform: "Invalid date",
    },
  };
};
