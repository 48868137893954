import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { noop } from "underscore";
import CSV from "svg-icons/CSV.svg";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const IS_MS_BROWSER = Boolean(navigator.msSaveOrOpenBlob);
const IS_MAC_CHROME_OR_SAFARI =
  navigator.appVersion.includes("Mac") &&
  (navigator.appVersion.includes("Chrome") ||
    navigator.appVersion.includes("Safari"));
const DEFAULT_FAKE_LINK_ID = "js-fake-download-link";
const NUM_WORDS_IN_LINE = 20;

export default class ExportCsvButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.getCSV = this.getCSV.bind(this);
  }

  getCSV() {
    const { fieldDelimiter, footerText, lineTerminator } = this.props;
    const output = this.props.headers.length
      ? [this.props.headers, ...this.props.data]
      : this.props.data;
    const csvRows = output.map((row) => row.join(fieldDelimiter));

    if (footerText) {
      csvRows.push("");
      let splittedFooter = footerText.split(" ");
      while (splittedFooter.length > 0) {
        let line = splittedFooter.splice(0, NUM_WORDS_IN_LINE).join(" ");
        csvRows.push(`"${line}"`);
      }
    }

    return csvRows.join(lineTerminator);
  }

  handleExportClick() {
    if (this.props.isPrivileged) {
      return;
    }
    const { fileNameBase, fileNameTimestampFormat } = this.props;
    const fileName = `${fileNameBase}${
      fileNameTimestampFormat
        ? "_" + moment().format(fileNameTimestampFormat)
        : ""
    }${this.getExtension()}`;

    if (IS_MS_BROWSER) {
      const csvBlob = new Blob([this.getCSV()], {
        type: "text/plain;charset=utf-8;",
      });
      navigator.msSaveOrOpenBlob(csvBlob, fileName);
    } else {
      this.removeLink();
      const csv =
        "data:text/csv;charset=utf-8," + encodeURIComponent(this.getCSV());
      const link = document.createElement("a");
      link.id = DEFAULT_FAKE_LINK_ID;
      link.setAttribute("href", csv);
      link.setAttribute("download", fileName);
      // Adding class to the fake link for testing purposes so our automation framework can select it
      if (this.props.fakeLinkClassName) {
        link.className = this.props.fakeLinkClassName;
      }
      document.body.appendChild(link);
      link.click();
    }
    this.props.onClick();
  }

  removeLink() {
    if (document.getElementById(DEFAULT_FAKE_LINK_ID)) {
      document.body.removeChild(document.getElementById(DEFAULT_FAKE_LINK_ID));
    }
  }

  componentWillUnmount() {
    this.removeLink();
  }

  getExtension() {
    // Chrome and Safari on Mac OS automatically insert a file extension, so this component shouldn't add one
    if (IS_MAC_CHROME_OR_SAFARI) {
      return "";
    }

    return `.${this.props.extension}`;
  }

  render() {
    const { isPrivileged } = this.props;

    if (this.props.isMiniVersion) {
      return (
        <button
          aria-disabled={isPrivileged}
          data-testid="csv-btn"
          className={`pc-btn pc-btn--tiny qa-export-csv-btn ${
            isPrivileged ? "is-disabled" : ""
          }`}
          onClick={this.handleExportClick}
          title="Download transactions in CSV format"
          aria-label="Download transactions in CSV format"
          type="button"
        >
          <CSV className="transactions__csv-icon" aria-hidden alt="" />
        </button>
      );
    }
    return (
      <button
        data-testid="csv-btn"
        className={`pc-btn pc-btn--tiny ${isPrivileged ? "is-disabled" : ""}`}
        onClick={this.handleExportClick}
        type="button"
      >
        <span>
          <svg className="export-csv-button" viewBox="0 0 2 2">
            <use
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="#pc-icon__download"
            />
          </svg>
          {this.props.label}
        </span>
      </button>
    );
  }
}

ExportCsvButton.propTypes = {
  label: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.array),
  extension: PropTypes.string,
  fieldDelimiter: PropTypes.string,
  lineTerminator: PropTypes.string,
  fileNameBase: PropTypes.string.isRequired,
  fileNameTimestampFormat: PropTypes.string,
  isMiniVersion: PropTypes.bool,
  footerText: PropTypes.string,
  onClick: PropTypes.func,
  fakeLinkClassName: PropTypes.string, // For testing purposes
  isPrivileged: PropTypes.bool,
};

ExportCsvButton.defaultProps = {
  headers: [],
  label: "Export as CSV",
  extension: "csv",
  fieldDelimiter: ",",
  lineTerminator: "\r\n",
  fileNameTimestampFormat: "YYYYMMDD_HHmmss",
  isMiniVersion: false,
  data: undefined,
  fakeLinkClassName: undefined,
  footerText: undefined,
  onClick: noop,
  isPrivileged: false,
};
