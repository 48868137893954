/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";

const organizationName = "Empower's Advisory services";

const DISCLAIMER = `*Customer satisfaction data was collected from an internally created survey and is representative of how satisfied participants were with the call at the time of the survey.  Participants were not clients of ${organizationName} at the time of the survey and were not compensated for participating.`;

const TollGateBottomImage = ({ onClickIcon }) => {
  const rebrandImageClass = "rebrand-image";
  const starGroupImageName = "app-store-stars_v2.png";

  const expertImageName = "expert_v2.png";

  return (
    <div className="toll_gate_bottom_image-container">
      <div className={`toll_gate__image-container ${rebrandImageClass}`}>
        <div
          className="toll_gate__expert-container"
          onClick={() => onClickIcon("Experts Fiduciaries")}
        >
          <img
            className="toll_gate__image-expert"
            src={`/static/img/firstUse/${expertImageName}`}
            alt={"Our advisors are fiduciaries"}
          />
          <p className="toll_gate__image-expert-text">
            {"Our advisors are fiduciaries"}
          </p>
        </div>
        <div
          className="toll_gate__stars-container"
          onClick={() => onClickIcon("Star Rating")}
        >
          <p className="toll_gate__image-stars-title">4.8</p>
          <img
            className="toll_gate__image-stars"
            src={`/static/img/firstUse/${starGroupImageName}`}
            alt="4.8 stars"
          />
          <p className="toll_gate__image-stars-text">Average session rating*</p>
        </div>
        <div
          className="toll_gate__assets-container"
          onClick={() => onClickIcon("AUM")}
        >
          <p className="toll_gate__assets-title">$22.4B</p>
          <p className="toll_gate__assets-text">Managed as of 4/13/22</p>
        </div>
      </div>
      <p className="toll_gate_bottom_image_disclaimer">{DISCLAIMER}</p>
    </div>
  );
};

export default TollGateBottomImage;

TollGateBottomImage.propTypes = {
  onClickIcon: PropTypes.func.isRequired,
};
