import React from "react";
import { formatCurrency } from "libs/pcap/utils/format";
import {
  positiveNegativeColoredCurrency,
  positiveNegativeColoredPercentage,
} from "./formatters";
import {
  holdingAndAccountNameComparator,
  numberAndAccountNameComparator,
} from "./sortingUtils";

export default [
  {
    header: "Holding",
    accessor: (d) => d,
    formatter: function holdingName(d) {
      return (
        <>
          <div className="u-text-bold qa-ticker" title={d.description}>
            {d.ticker}
          </div>
          <div className="pc-holdings-grid-cell--holding-description u-clip-text">
            {d.description}
          </div>
        </>
      );
    },
    className:
      "pc-holdings-grid-account-details-cell--holding-name qa-holding-name",
    headerClassName:
      "pc-holdings-grid-account-details-cell--holding-name qa-holding-name-col-header",
    summaryClassName:
      "pc-holdings-grid-account-details-cell--holding-name qa-holding-name",
    isSortable: true,
    summaryAccessor: () => "Grand Total",
    comparator: holdingAndAccountNameComparator,
  },
  {
    header: "Shares",
    accessor: (d) => d.quantity,
    className:
      "table__column--right pc-holdings-grid-cell--holding-shares qa-holding-shares",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-shares qa-holding-shares-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-shares",
    isSortable: true,
    comparator: numberAndAccountNameComparator,
  },
  {
    header: "Price",
    accessor: (d) => d.price,
    className:
      "table__column--right pc-holdings-grid-cell--holding-price qa-holding-price",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-price qa-holding-price-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-price",
    formatter: function holdingPrice(price) {
      return <span>{formatCurrency(price)}</span>;
    },
    isSortable: true,
    comparator: numberAndAccountNameComparator,
  },
  {
    header: "Value",
    accessor: (d) => d.value,
    className:
      "table__column--right pc-holdings-grid-cell--holding-value qa-holding-value",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-value qa-holding-value-col-header",
    summaryClassName:
      "table__column--right pc-holdings-grid-cell--holding-value",
    formatter: function holdingValue(value) {
      return <span>{formatCurrency(value)}</span>;
    },
    isSortable: true,
    comparator: numberAndAccountNameComparator,
    summaryFormatter: function valueTotal(value) {
      return <span>{Number.isNaN(value) ? "" : formatCurrency(value)}</span>;
    },
    summaryAccessor: (data) => {
      return data.reduce((total, holding) => {
        return total + holding.value;
      }, 0);
    },
  },
  {
    header: "1 day $",
    accessor: (d) => d.oneDayValueChange,
    className:
      "table__column--right pc-holdings-grid-cell--holding-one-day-usd qa-holding-one-day-usd",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-one-day-usd qa-holding-one-day-usd-col-header",
    summaryClassName:
      "table__column--right pc-holdings-grid-cell--holding-one-day-usd qa-one-day-change-value",
    formatter: positiveNegativeColoredCurrency,
    isSortable: true,
    comparator: numberAndAccountNameComparator,
    summaryFormatter: function oneDayDolarTotal(value) {
      return <span>{Number.isNaN(value) ? "" : formatCurrency(value)}</span>;
    },
    summaryAccessor: (data) => {
      return data.reduce((total, holding) => {
        return total + holding.oneDayValueChange;
      }, 0);
    },
  },
  {
    header: "1 Day %",
    accessor: (d) => d.oneDayPercentChange,
    className:
      "table__column--right pc-holdings-grid-cell--holding-one-day-percentage qa-holding-one-day-percentage",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-one-day-percentage qa-holding-one-day-percentage-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-one-day-percentage",
    formatter: positiveNegativeColoredPercentage,
    isSortable: true,
    sortOrder: "desc",
    comparator: numberAndAccountNameComparator,
  },
];
