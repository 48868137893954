/* eslint-disable react/prop-types, react/display-name, no-magic-numbers */
import React, { Component } from "react";
import AppointmentTime from "./AppointmentTime";
import toClient from "accessors/appointmentTypes/mappers/toClient";
import moment from "moment";

export const AVAILABILITIES_HOUR = [
  {
    startTime: moment().tz("Pacific/Honolulu").hour(4).startOf("hour"),
    endTime: moment().tz("Pacific/Honolulu").hour(5).startOf("hour"),
    appointmentId: "1",
  },
  {
    startTime: moment().tz("Pacific/Honolulu").hour(11).startOf("hour"),
    endTime: moment().tz("Pacific/Honolulu").hour(12).startOf("hour"),
    appointmentId: "2",
  },
  {
    startTime: moment().tz("Pacific/Honolulu").hour(15).startOf("hour"),
    endTime: moment().tz("Pacific/Honolulu").hour(16).startOf("hour"),
    appointmentId: "3",
  },
  {
    startTime: moment().tz("Pacific/Honolulu").hour(19).startOf("hour"),
    endTime: moment().tz("Pacific/Honolulu").hour(20).startOf("hour"),
    appointmentId: "4",
  },
];

export const AVAILABILITIES_HALF_HOUR = [
  {
    startTime: moment().tz("Pacific/Honolulu").hour(4).startOf("hour"),
    endTime: moment()
      .tz("Pacific/Honolulu")
      .hour(4)
      .minute(30)
      .startOf("minute"),
    appointmentId: "5",
  },
  {
    startTime: moment().tz("Pacific/Honolulu").hour(11).startOf("hour"),
    endTime: moment()
      .tz("Pacific/Honolulu")
      .hour(11)
      .minute(30)
      .startOf("minute"),
    appointmentId: "6",
  },
  {
    startTime: moment().tz("Pacific/Honolulu").hour(15).startOf("hour"),
    endTime: moment()
      .tz("Pacific/Honolulu")
      .hour(15)
      .minute(30)
      .startOf("minute"),
    appointmentId: "7",
  },
  {
    startTime: moment().tz("Pacific/Honolulu").hour(19).startOf("hour"),
    endTime: moment()
      .tz("Pacific/Honolulu")
      .hour(19)
      .minute(30)
      .startOf("minute"),
    appointmentId: "8",
  },
];

export const APPOINTMENT_TYPES = toClient([
  {
    appointmentType: "DASHBOARD_TOUR",
    defaultDuration: 30,
    displayName: "Dashboard Tour",
    optionalDurations: [30, 60],
  },
  {
    appointmentType: "PORTFOLIO_REVIEW",
    defaultDuration: 60,
    displayName: "Portfolio Review",
    optionalDurations: [30, 60],
    targetAdvisorType: "SERVICE",
  },
  {
    appointmentType: "OUTSIDE_ACCOUNT_REVIEW",
    defaultDuration: 60,
    displayName: "Outside Account Review",
    optionalDurations: [30, 60],
    targetAdvisorType: "SERVICE",
  },
  {
    appointmentType: "FINANCIAL_PLANNING",
    defaultDuration: 60,
    displayName: "Financial Planning",
    optionalDurations: [30, 60],
    targetAdvisorType: "SERVICE",
  },
  {
    appointmentType: "OTHER",
    displayName: "Other",
    optionalDurations: [30, 60],
    targetAdvisorType: "SERVICE",
  },
]);

export function getAvailabilitiesForDuration(duration) {
  let availabilities = [];
  if (duration === 30) {
    availabilities = AVAILABILITIES_HALF_HOUR;
  } else if (duration === 60) {
    availabilities = AVAILABILITIES_HOUR;
  }
  return availabilities;
}

export class AppointmentTimeContainer extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.duration !== state.prevPropsDuration) {
      return {
        availabilities: getAvailabilitiesForDuration(props.duration),
        prevPropsDuration: props.duration,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      isThreeTimeSlotsMode: props.isThreeTimeSlotsMode,
    };
    this.handleAppointmentTypeChange = this.handleAppointmentTypeChange.bind(
      this
    );
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleOnShowMoreTimes = this.handleOnShowMoreTimes.bind(this);
  }

  handleAppointmentTypeChange({ duration }) {
    // clone to mimic a new value returned from the API
    this.setState({
      duration,
      availabilities: getAvailabilitiesForDuration(duration).slice(),
    });
  }

  handleMonthChange() {
    // clone to mimic a new value returned from the API
    this.setState({
      availabilities: getAvailabilitiesForDuration(this.state.duration).slice(),
    });
  }

  handleOnShowMoreTimes() {
    this.setState({ isThreeTimeSlotsMode: false });
  }

  render() {
    const { availabilities } = this.state;
    return (
      <AppointmentTime
        {...this.props}
        advisorImgURL="https://via.placeholder.com/102.png?text=Advisor+Image"
        advisorName="Oyibo Brendan CFP"
        appointmentTypes={APPOINTMENT_TYPES}
        availabilities={availabilities}
        onAppointmentTypeChange={this.handleAppointmentTypeChange}
        onMonthChange={this.handleMonthChange}
        onShowMoreTimes={this.handleOnShowMoreTimes}
        isThreeTimeSlotsMode={this.state.isThreeTimeSlotsMode}
        onNext={() => {
          console.log("onNext");
        }}
      />
    );
  }
}
