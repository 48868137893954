/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-nested-ternary */
import { isArray } from "underscore";
import { formatCurrency, formatPercent } from "libs/pcap/utils/format";
import React from "react";
import { fetchJsonPromise } from "utils/service";

export const fundSelectedFilter = (
  fundsIDs,
  funds,
  fundsToTransferOut = []
) => {
  if (!fundsIDs && isArray(fundsIDs)) return;
  const addedElements = [];
  if (
    fundsToTransferOut.length > 0 &&
    addedElements.push(fundsToTransferOut[0])
  );

  fundsIDs.forEach((fundId) => {
    let addFund = funds.find((fund) => fund.investmentOptionId === fundId);
    addedElements.push(addFund);
  });
  return addedElements;
};

export const unselectedFundFilter = (funds, fundsToTransfer, fundsIDs) => {
  const selectedFunds = fundsToTransfer.filter((funds) => {
    return fundsIDs.includes(funds.investmentOptionId);
  });
  const selectedFundsIDs = [];
  selectedFunds.forEach((fund) => {
    selectedFundsIDs.push(fund.investmentOptionId);
  });
  const newSelectedFunds = funds.filter((funds) => {
    return (
      !selectedFundsIDs.includes(funds.investmentOptionId) &&
      fundsIDs.includes(funds.investmentOptionId)
    );
  });

  return [...newSelectedFunds, ...selectedFunds];
};

export const fundNameComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }

    return (
      accessor(a) - accessor(b) ||
      (a.legalName || "").localeCompare(b.legalName || "")
    );
  };
};

export const equityBondComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      if (accessor(a).equityPercent === accessor(b).equityPercent) {
        return (
          accessor(a) - accessor(b) ||
          (a.legalName || "").localeCompare(b.legalName || "")
        );
      }
      return (
        accessor(a) - accessor(b) ||
        (a.equityPercent || "") - (b.equityPercent || "")
      );
    }

    if (accessor(a).bondPercent === accessor(b).bondPercent) {
      return (
        accessor(a) - accessor(b) ||
        (a.legalName || "").localeCompare(b.legalName || "")
      );
    }
    return (
      accessor(a) - accessor(b) || (a.bondPercent || "") - (b.bondPercent || "")
    );
  };
};

export const compareAlphaKeys = (accessor, order) => {
  return (a, b) => {
    const aName = a.legalName.toLowerCase() || "";
    const bName = b.legalName.toLowerCase() || "";
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }

    if (
      (accessor(a).toLowerCase() || "") === (accessor(b).toLowerCase() || "")
    ) {
      return aName.localeCompare(bName);
    }
    return (accessor(a).toLowerCase() || "").localeCompare(
      accessor(b).toLowerCase() || ""
    );
  };
};

export const compareAlphaAndNumber = (accessor, order) => {
  return (a, b) => {
    const aName = a.legalName || "";
    const bName = b.legalName || "";
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }
    if ((accessor(a) || 0) === (accessor(b) || 0)) {
      return aName.localeCompare(bName);
    }
    return (accessor(a) || 0) - (accessor(b) || 0);
  };
};

export const compareMorningstar = (accessor, order) => {
  return (a, b) => {
    const aName = a.legalName || "";
    const bName = b.legalName || "";
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }
    if ((accessor(a.overallRating) || 0) === (accessor(b.overallRating) || 0)) {
      return aName.localeCompare(bName);
    }
    return (accessor(a.overallRating) || 0) - (accessor(b.overallRating) || 0);
  };
};

export const compareTotalGainLoss = (accessor, order) => {
  return (a, b) => {
    const aName = a.legalName || "";
    const bName = b.legalName || "";
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }
    if (
      (accessor(a.totalUnrealizedGainLoss) || 0) ===
      (accessor(b.totalUnrealizedGainLoss) || 0)
    ) {
      return aName.localeCompare(bName);
    }
    return (
      (accessor(a.totalUnrealizedGainLoss) || 0) -
      (accessor(b.totalUnrealizedGainLoss) || 0)
    );
  };
};

export const percentageFormatter = (value) => {
  return value ? parseFloat(value).toFixed(2) + "%" : "N/A";
};

export const getGainLossPercentage = (balance, totalCost) => {
  if (totalCost === 0) return 0;
  return (balance - totalCost) / totalCost;
};

export const getGainLossClassName = (data) => {
  if (data > 0) return "rebalance-data-gain";
  if (data < 0) return "rebalance-data-loss";
  return "";
};

export const formatGainLoss = (value, isPercentage = false) => {
  if (value !== 0 && !value) return "";

  const className = getGainLossClassName(value);
  const format = isPercentage ? formatPercent : formatCurrency;
  return <div className={className}>{format(value)}</div>;
};

export const mapFundsTable = (
  funds,
  includeBalance = false,
  handleInvestmentOverviewLink,
  includeGainLoss = true
) => {
  const columns = [
    {
      id: "INVESTMENT",
      header: "INVESTMENT",
      accessor: (d) => d,
      // eslint-disable-next-line react/display-name
      formatter: (d) => (
        <a
          className="pc-label"
          href={d.investmentOverviewLink}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            handleInvestmentOverviewLink(
              d.name,
              d.investmentOverviewLink,
              d.investmentOptionId
            )
          }
        >
          {d.name}
        </a>
      ),
      style: {
        width: includeGainLoss ? "20%" : "40%",
        fontSize: "15px",
      },
      className: "table__column--left pc-u-pl0",
    },
    {
      id: "UNREALIZED_SHORT_TERM_GAIN_LOSS",
      header: "UNREALIZED SHORT-TERM GAIN/LOSS",
      accessor: (d) => d.shortTermUnrealizedGainLoss,
      formatter: formatGainLoss,
      style: {
        width: "20%",
        fontSize: "15px",
      },
      className: "table__column--right pc-u-pr0",
    },
    {
      id: "UNREALIZED_LONG_TERM_GAIN_LOSS",
      header: "UNREALIZED LONG-TERM GAIN/LOSS",
      accessor: (d) => d.longTermUnrealizedGainLoss,
      formatter: formatGainLoss,
      style: {
        width: "20%",
        fontSize: "15px",
      },
      className: "table__column--right pc-u-pr0",
    },
    {
      id: "TOTAL_GAIN_LOSS",
      header: "TOTAL GAIN/LOSS",
      accessor: (d) => d,
      formatter: (d) => (
        <div style={{ display: "block" }}>
          {formatGainLoss(d.totalUnrealizedGainLoss)}
          {formatGainLoss(d.totalGainLossPercentage, true)}
        </div>
      ),
      style: {
        width: "15%",
        fontSize: "15px",
      },
      className: "table__column--right pc-u-pr0",
    },
    {
      id: "BALANCE",
      header: "BALANCE",
      accessor: (d) => d.balance,
      formatter: formatCurrency,
      style: {
        width: includeGainLoss ? "10%" : "20%",
        fontSize: "15px",
      },
      className: "table__column--right",
    },
    {
      id: includeBalance ? "CURRENT_ALLOCATION" : "NEW_ALLOCATION",
      header: includeBalance ? "CURRENT ALLOCATION" : "NEW ALLOCATION",
      accessor: (d) => d.allocationPercentage,
      formatter: (d) => formatPercent(d, { precision: 2 }),
      style: {
        width:
          includeBalance && includeGainLoss
            ? "15%"
            : !includeBalance && !includeGainLoss
            ? "60%"
            : "40%",
        fontSize: "15px",
      },
      className: "table__column--right pc-u-pr0",
    },
  ];

  if (!includeBalance) {
    columns.splice(3, 2);
  }

  if (!includeGainLoss && includeBalance) {
    columns.splice(1, 3);
  }

  if (!includeGainLoss && !includeBalance) {
    columns.splice(1, 2);
  }

  const data = funds.map((fund) => {
    const newFund = {
      name: fund.data?.fundInfo?.legalName
        ? fund.data.fundInfo.legalName
        : fund.legalName
        ? fund.legalName
        : "",
      allocationPercentage: fund.allocationPercentage
        ? fund.allocationPercentage
        : fund.percentAllocation
        ? fund.percentAllocation
        : 0,
      investmentOverviewLink: fund.data?.fundInfo?.investmentOverviewLink
        ? fund.data.fundInfo.investmentOverviewLink
        : fund.investmentOverviewLink
        ? fund.investmentOverviewLink
        : "",

      shortTermUnrealizedGainLoss:
        fund.data?.fundInfo?.shortTermUnrealizedGainLoss ??
        fund.shortTermUnrealizedGainLoss ??
        "",
      longTermUnrealizedGainLoss:
        fund.data?.fundInfo?.longTermUnrealizedGainLoss ??
        fund.longTermUnrealizedGainLoss ??
        "",
      totalUnrealizedGainLoss:
        fund.data?.fundInfo?.totalUnrealizedGainLoss ??
        fund.totalUnrealizedGainLoss ??
        "",
      totalGainLossPercentage:
        fund.data?.fundInfo?.totalGainLossPercentage ??
        fund.totalGainLossPercentage ??
        "",
    };

    if (includeBalance) {
      newFund.balance = fund.amount
        ? fund.amount
        : fund.balance
        ? fund.balance
        : 0;
    }

    return newFund;
  });

  return {
    columns,
    data,
  };
};

export const mapSourceFundsReviewTable = (funds) => {
  const columns = [
    {
      id: "INVESTMENT",
      header: (
        <span className="help-me-do-it-review-view__table__column--source-fund-header">
          BUILD YOUR OWN PORTFOLIO
        </span>
      ),
      accessor: (d) => d,
      // eslint-disable-next-line react/display-name, jsx-a11y/anchor-is-valid
      formatter: (d) => <a>{d.name ?? ""}</a>,
      style: {
        width: "75%",
      },
      className: "table__column--left pc-u-pl0",
    },
    {
      id: "PERCENT",
      header: "",
      accessor: (d) => d.allocationPercentage,
      formatter: (d) => formatPercent(d, { precision: 0 }),
      style: {
        width: "25%",
      },
      className: "table__column--right pc-u-pr0",
    },
  ];

  const data = funds.map((fund) => {
    return {
      name: fund.data?.fundInfo?.name ?? "",
      allocationPercentage: fund.allocationPercentage ?? 0,
    };
  });

  return {
    columns,
    data,
  };
};

export const mapDestinationFundsReviewTable = (funds) => {
  const columns = [
    {
      id: "INVESTMENT",
      header: (
        <span className="help-me-do-it-review-view__table__column--source-fund-header">
          RISK-BASED FUND
        </span>
      ),
      accessor: (d) => d,
      // eslint-disable-next-line react/display-name, jsx-a11y/anchor-is-valid
      formatter: (d) => <a>{d.name ?? ""}</a>,
      style: {
        width: "75%",
      },
      className: "table__column--left pc-u-pl0",
    },
    {
      id: "PERCENT",
      header: "",
      accessor: (d) => d.allocationPercentage,
      formatter: (d) => formatPercent(d, { precision: 0 }),
      style: {
        width: "25%",
      },
      className: "table__column--right pc-u-pr0",
    },
  ];

  // eslint-disable-next-line sonarjs/no-identical-functions
  const data = funds.map((fund) => {
    return {
      name: fund.data?.fundInfo?.name ?? "",
      allocationPercentage: fund.allocationPercentage ?? 0,
    };
  });

  return {
    columns,
    data,
  };
};

export const morningstarTooltipContent = (fund, styleGuide) => {
  if (!styleGuide) {
    return;
  }

  return styleGuide
    .replace("{category}", fund.assetClass)
    .replace("{fundname}", fund.legalName)
    .replace(
      "{threeYearRating}",
      fund.threeYearRating ? fund.threeYearRating : 0
    )
    .replace(
      "{threeYearRatingCategorySize}",
      fund.threeYearRatingCategorySize ? fund.threeYearRatingCategorySize : 0
    )
    .replace("{fiveYearRating}", fund.fiveYearRating ? fund.fiveYearRating : 0)
    .replace(
      "{fiveYearRatingCategorySize}",
      fund.fiveYearRatingCategorySize ? fund.fiveYearRatingCategorySize : 0
    )
    .replace("{tenYearRating}", fund.tenYearRating ? fund.tenYearRating : 0)
    .replace(
      "{tenYearRatingCategorySize}",
      fund.tenYearRatingCategorySize ? fund.tenYearRatingCategorySize : 0
    );
};

export const changeFundModalContent = (fromFund, toFund, styleGuide) => {
  if (!styleGuide) {
    return;
  }

  return styleGuide
    .replace("{fromFundname}", fromFund)
    .replace("{toFundname}", toFund);
};

export const getDate = (date) => {
  const isAmReg = /am/g;
  const isAm = date.search(isAmReg);
  return date.replace(isAm > 0 ? "am" : "pm", isAm > 0 ? "a.m." : "p.m.");
};

export const getImageBaseUrl = () =>
  IS_ADVISOR
    ? `${window.staticUrl || ""}/static/img/`
    : `${window.baseUrl}static/img/`;

export const getStyleGuideUrl = (styleGuideKey) => {
  let styleGuideUrl;
  if (IS_EMPOWER && !window.isAdvisorApp) {
    styleGuideUrl = window.integratedSharedData
      ? new Promise((resolve) =>
          resolve(window.integratedSharedData.translations.get(styleGuideKey))
        )
      : // this is for local and pcap demo environments only
        fetchJsonPromise(
          `${window.staticUrl}/static/mockData/empower/style_guide.json`
        );
  } else {
    styleGuideUrl = window.styleGuideUrl
      ? fetchJsonPromise(window.styleGuideUrl)
      : // this is for local and pcap demo environments only
        fetchJsonPromise(
          `${window.staticUrl}/static/mockData/empower/style_guide.json`
        );
  }
  return styleGuideUrl;
};
