import React from "react";
import PropTypes from "prop-types";
import { formatPercent } from "libs/pcap/utils/format";

const StakeholdersView = (props) => {
  return (
    <div>
      {props.disclaimer && (
        <div className="pc-u-mb milli js-stakeholders-disclaimer">
          {props.disclaimer}
        </div>
      )}
      <div className={`pc-layout stakeholders-view ${props.className}`}>
        <div className="pc-layout__item pc-u-1/3 js-stakeholders-title milli">
          {props.title}
        </div>
        <div className="pc-layout__item pc-u-2/3">
          {props.stakeholders.map((stakeholder) => {
            const { personId, personName, percentage } = stakeholder;
            const firstName = (personName && personName.firstName) || "";
            const lastName = (personName && personName.lastName) || "";
            const percentageText =
              isNaN(percentage) || !percentage
                ? ""
                : formatPercent(percentage, {
                    precision: 0,
                  });

            return (
              <div
                key={personId}
                className="stakeholders-view__row l-spaced l-spaced--flush"
              >
                <div>{firstName + " " + lastName}</div>
                <div className="pc-u-pr js-stakeholders-percentage">
                  {percentageText}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

StakeholdersView.propTypes = {
  stakeholders: PropTypes.array,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  disclaimer: PropTypes.string,
};

StakeholdersView.defaultProps = {
  stakeholders: [],
  className: "",
  disclaimer: "",
};

export default StakeholdersView;
