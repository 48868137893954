import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import ChangeClosedDateView from "./ChangeClosedDateView";
import { promisify } from "utils/service";

export default class ChangeClosedDateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.handleOnChangeClosingDate = this.handleOnChangeClosingDate.bind(this);
    this.changeClosedDateService = promisify(Services.Accounts.close);
  }

  handleOnChangeClosingDate(closedDate) {
    this.setState({ loading: true });
    this.changeClosedDateService({
      accountId: this.props.model.accountId,
      closedDate,
    })
      .then(() => {
        this.setState(
          {
            loading: false,
            errors: undefined,
          },
          this.props.onClosedDateChanged
        );
      })
      .catch((errors) => {
        this.setState({
          loading: false,
          errors,
        });
      });
  }

  render() {
    const { model, onCancel } = this.props;

    return (
      <ChangeClosedDateView
        model={model}
        onCancel={onCancel}
        errors={this.state.errors}
        loading={this.state.loading}
        onChangeClosingDate={this.handleOnChangeClosingDate}
      />
    );
  }
}

ChangeClosedDateContainer.propTypes = {
  model: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClosedDateChanged: PropTypes.func.isRequired,
};
