import moment from "moment";

// matches "mm/dd/yyyy"
const REGEX_DATE_FORMAT = /(\d{2})\/(\d{2})\/(\d{4})/;

export const API_FORMAT = "YYYY-MM-DD";
export const DISPLAY_FORMAT = "M/D/YYYY";
export const FULL_MONTH_DATE_YEAR_FORMAT = "MMMM D, YYYY";
export const FILE_NAME_FORMAT = "MM_DD_YYYY";
export const DISPLAY_DATE_FORMAT = "M-D-YYYY";

function ensureMoment(date) {
  if (!moment.isMoment(date)) {
    return moment(date);
  }
  return date;
}

/*--------------------*\
     Date Utilities
\*--------------------*/

export const now = moment();

/**
 * Used in DatePickerInput consumers to enable user to manually enter dates
 * Date is invalid if it does not conform to currentFormat
 *
 * @return {String} Formatted display date if valid, original string is invalid
 */
export const toApiFormatDateIfValid = ({
  date,
  currentFormat = DISPLAY_FORMAT,
  apiFormat = API_FORMAT,
}) => {
  const momentDate = moment(date, currentFormat, true /* strict */);
  return momentDate.isValid() ? momentDate.format(apiFormat) : date;
};

/**
 * Used in DatePickerInput.js to enable user to manually enter dates
 * Date is invalid if it does not conform to currentFormat
 *
 * @return {String} Formatted display date if valid, original string is invalid
 */
export const toDisplayFormatDateIfValid = ({
  date,
  currentFormat = API_FORMAT,
  displayFormat = DISPLAY_FORMAT,
}) => {
  const momentDate = moment(date, currentFormat, true /* strict */);
  return momentDate.isValid() ? momentDate.format(displayFormat) : date;
};

/**
 * Determines if the supplied date range is a complete month.
 *
 * @param {Date|Moment|String} startDate  The start date.
 *                                        In case of a string, it should be in the format accepted by `moment` factory.
 * @param {Date|Moment|String} endDate    The end date.
 *                                        In case of a string, it should be in the format accepted by `moment` factory.
 * @returns {Boolean}                     The boolean flag.
 */
export function isMonthRange(startDate, endDate) {
  startDate = ensureMoment(startDate);
  endDate = ensureMoment(endDate);
  let firstDayOfMonth = startDate.clone().startOf("month");
  let lastDayOfMonth = startDate.clone().endOf("month");
  return (
    startDate.isSame(firstDayOfMonth, "day") &&
    endDate.isSame(lastDayOfMonth, "day")
  );
}

/**
 * Determines if the supplied date range less than a month.
 *
 * @param {Date|Moment|String} startDate  The start date.
 *                                        In case of a string, it should be in the format accepted by `moment` factory.
 * @param {Date|Moment|String} endDate    The end date.
 *                                        In case of a string, it should be in the format accepted by `moment` factory.
 * @returns {Boolean}                     The boolean flag.
 */
export function isDiffLessThanAMonth(startDate, endDate) {
  startDate = ensureMoment(startDate);
  endDate = ensureMoment(endDate);
  return (
    endDate.diff(startDate, "days") < startDate.clone().endOf("month").date()
  );
}

/**
 * Determines if the supplied date range is the current month.
 * The method defines the supplied range as the current month if the end date matches today
 * or it is the last day of the month.
 *
 * @param {Date|Moment|String} startDate  The start date.
 *                                        In case of a string, it should be in the format accepted by `moment` factory.
 * @param {Date|Moment|String} endDate    The end date.
 *                                        In case of a string, it should be in the format accepted by `moment` factory.
 * @returns {Boolean}                     The boolean flag.
 */
export function isCurrentMonthRange(startDate, endDate) {
  startDate = ensureMoment(startDate);
  endDate = ensureMoment(endDate);
  let firstDayOfMonth = startDate.clone().startOf("month");
  return (
    startDate.isSame(firstDayOfMonth, "day") &&
    startDate.isSame(now, "month") &&
    (endDate.isSame(now, "day") || isMonthRange(startDate, endDate))
  );
}

/**
 * Converts the supplied date string into `DateStruct` format used on the server.
 * `DateStruct` format: `{ month, day, year }`.
 *
 * @param {String} dateString the date string in 'mm/dd/yyyy' format
 * @returns {DateStruct} the date object
 */
export function dateStringToStruct(dateString) {
  const match = REGEX_DATE_FORMAT.exec(dateString);
  if (match) {
    const dateObj = {};
    dateObj.month = match[1];
    dateObj.day = match[2];
    dateObj.year = match[3];
    return dateObj;
  }
}

/**
 * Converts the supplied `DateStruct` format used on the server to a string.
 * `DateStruct` format: `{ month, day, year }`.
 *
 * @param {DateStruct} the date object
 * @returns {String} dateString the date string in 'mm/dd/yyyy' format
 */
export function dateStructToString({ day, month, year }) {
  if (!day || !month || !year) {
    return;
  }
  return `${month}/${day}/${year}`;
}
