import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import SiteView2 from "views/modules/sidebar/partials/site2";
import RetailAccountView from "views/modules/sidebar/partials/retailAccount";
import AccountGroupTemplate from "templates/modules/sidebar/accountGroup2.html";
import AccountGroupHeaderTemplate from "templates/modules/sidebar/accountGroupHeader2.html";
import Account from "models/account";
import { isEmpowerPaeMode } from "views/modules/sidebar/utils/accountUtils";

const NEED_ATTENTION_GROUP_CONFIG = {
  label: "NEEDS ATTENTION",
  isLiability: false,
  isStillImporting: false,
  isNeedAttention: true,
  isInstitutionalPartner: false,
};

/**
 * 'PCB_FINISH_SETUP', 'PCB_SUBMITTED', 'PCB_IN_REVIEW', 'PCB_KBA_REVIEW', 'PCB_BLOCKED', 'PCB_FUND_NOW', 'PCB_FAILED' values are used in the open Empower Personal Cash account flow
 */
const NEXT_ACTION_TO_SHOW = [
  "VISIT_SITE",
  "MIGRATE_OAUTH",
  "OAUTH_RECONNECT",
  "RETIREMENT_CREDENTIALS_HAVE_CHANGED",
  "RETIREMENT_INCORRECT_SECURITY_ANSWERS",
  "RETIREMENT_ADDITIONAL_SECURITY_QUESTIONS",
  "RETIREMENT_CREDENTIALS_MUST_BE_RESET",
  "RETIREMENT_INCORRECT_CREDENTIALS_PROVIDED",
  "RETIREMENT_SET_UP_SECURITY_QUESTIONS",
  "RETIREMENT_DEFAULT_HANDLER",
  "RETIREMENT_CLIENT_NEEDS_TO_CALL",
  "RETIREMENT_UNIQUE_FORWARDING_EMAIL_REMOVED",
  "RETIREMENT_UNIQUE_FORWARDING_PHONE_REMOVED",
  "RETIREMENT_UNIQUE_FORWARDING_PHONE_AND_EMAIL_REMOVED",
  "INITIATE_REFRESH",
  "MORE_INFO",
  "PCB_FINISH_SETUP",
  "PCB_SUBMITTED",
  "PCB_IN_REVIEW",
  "PCB_KBA_REVIEW",
  "PCB_BLOCKED",
  "PCB_FUND_NOW",
  "PCB_FAILED",
  "FAILED_KBA",
  "FAILED",
  "AM_HOLD",
  "IN_REVIEW",
];
const MFA_FAILED_ERRORS = ["MFA_REQUIRED", "MFA_TIMEDOUT"];
const EMPOWER_RETAIL_ERRORS = ["TAKE_ACTION"];
const isEmulatorMode = isEmpowerPaeMode();

let AccountGroupView = Backbone.View.extend({
  className: "sidebar-account__group",
  tagName: "li",
  events: {
    "click .sidebar-account__group-header": "isSidebarAccountExpanded",
  },
  initialize: function (options) {
    this.options = options;
    this.$el.html(
      AccountGroupTemplate({
        groupName: options.groupName,
        panelName: `${options.groupName}_GROUP_VIEW`,
        label: Account.PRODUCT_TYPES_LABELS[options.groupName],
        groupClassName: "sidebar-account__group-header--thin",
      })
    );
    this.$el.addClass(
      this.options.groupName +
        " qa-" +
        this.options.groupName.toLowerCase().replace("_", "-") +
        "-accounts"
    );
    $(options.elementContainer).append(this.$el);
    this.$list = this.$("ul");
    this.$(".js-sidebar-account__group-header").html(
      AccountGroupHeaderTemplate(NEED_ATTENTION_GROUP_CONFIG)
    );
    this.model.on("requestRenderAccount", this.render, this);
    this.collection.on("reset", this.render, this);
    this.collection.on("freshen", this.render, this);
    this.siteViews = {};
    this.accountViews = {};
    this.render();
  },
  render: function () {
    // mark all views for deletion
    _.each(this.siteViews, (view) => {
      view.toBeRemoved = true;
    });

    _.each(this.accountViews, (view) => {
      view.toBeRemoved = true;
    });
    // Only render accounts in which are NOT closed and the next action is in NEXT_ACTION_TO_SHOW OR
    // the nextAction is wait and is in MFA error AND the icon is error. (It could be aggregating, in such case we wont show it need attention section)

    let accountsInError = this.collection.filter(
      (act) =>
        !(
          act.get("isEmpower") &&
          act.get("empowerAttributes")?.type === "RETAIL" &&
          act.get("originalFirmName") !== "empower-retail-pcap-cash"
        ) &&
        (!isEmulatorMode || act.get("isEmpower")) &&
        !act.get("closedDate") &&
        (NEXT_ACTION_TO_SHOW.includes(act.get("nextAction")?.action) ||
          (act.get("nextAction")?.action === "WAIT" &&
            MFA_FAILED_ERRORS.includes(
              act.get("nextAction")?.aggregationErrorType
            ) &&
            act.get("nextAction")?.iconType === "ERROR"))
    );
    let empowerAccountsInError = this.collection.filter(
      (act) =>
        act.get("isEmpower") &&
        act.get("empowerAttributes")?.type === "RETAIL" &&
        !act.get("closedDate") &&
        EMPOWER_RETAIL_ERRORS.includes(act.get("nextAction")?.action)
    );

    if (accountsInError?.length > 0 || empowerAccountsInError?.length > 0) {
      let sites = _.groupBy(accountsInError, (act) => act.get("userSiteId"));
      _.each(sites, this.renderSite, this);
      _.each(empowerAccountsInError, this.renderEmpowerAccount, this);
      this.$el.show();
    } else {
      this.$el.hide();
    }
    // remove views still marked for deletion
    _.each(this.siteViews, (view) => {
      if (view.toBeRemoved) {
        view.remove();
        delete this.siteViews[view.model.at(0).get("userSiteId")];
      }
    });

    _.each(this.accountViews, (view) => {
      if (view.toBeRemoved) {
        view.remove();
        delete this.accountViews[view.model.get("userAccountId")];
      }
    });
  },
  renderEmpowerAccount: function (account) {
    const userAccountId = account.get("userAccountId");
    var view = this.accountViews[userAccountId];
    if (typeof view === "object") {
      // exists, update it
      view.model = account;
    } else {
      view = this.accountViews[userAccountId] = new RetailAccountView({
        model: account,
      });
      this.$list.append(view.render().el);
    }
    view.toBeRemoved = false;
  },
  renderSite: function (accounts, userSiteId) {
    var view = this.siteViews[userSiteId];
    // in-place updates, cache a reference to each account renderer by accountId and re-use the renderer if it was already created
    if (typeof view === "object") {
      // exists, update it
      view.model.reset(accounts);
    } else {
      view = this.siteViews[userSiteId] = new SiteView2({
        userSiteId,
        accounts,
      });
      this.$list.append(view.render().el);
    }
    view.toBeRemoved = false;
  },
  isAnyExtraFormVisible: function () {
    return (
      _.some(this.siteViews, function (accountView) {
        return accountView.isExtraFormVisible;
      }) ||
      _.some(this.accountViews, function (accountView) {
        return accountView.isExtraFormVisible;
      })
    );
  },

  isSidebarAccountExpanded: function (e) {
    console.log(e);

    // Due to accordion accessibility guidelines, each button must be enclosed in a heading
    // element of an appropriate level. The button is now a child of the heading element.
    const buttonClassName =
      this.$el.context.children[0]?.childNodes[1]?.className;

    // Sets the aria-expanded based on the collapsed class of the button
    this.$el.context.children[0].childNodes[1].ariaExpanded =
      buttonClassName.includes("collapsed") ? "true" : "false";
  },
});
export default AccountGroupView;
