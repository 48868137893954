import React from "react";
import PropTypes from "prop-types";
import { FULL_MONTH_DATE_YEAR_FORMAT } from "libs/pcap/utils/date2";
import moment from "moment";
import RemoveAccount from "components/common/RemoveAccount";
import { cancel } from "accessors/pcb/enrollment/accessor";

function InviteSecondaryAccountOwnerConfirmation({
  name,
  emailAddress,
  expirationDate,
  enrollmentId,
  onDone,
  onWithdrawnInvitation,
}) {
  return (
    <>
      <h2>An Invite Has Been Sent!</h2>
      <p>
        We have sent an invitation to <strong>{name}</strong> at{" "}
        <strong>{emailAddress}</strong>.{" "}
        {expirationDate ? (
          <>
            This invitation will expire on{" "}
            {moment(expirationDate).format(FULL_MONTH_DATE_YEAR_FORMAT)}, and it
            cannot be withdrawn after the additional account owner has completed
            the application.
          </>
        ) : (
          <>
            This invitation cannot be withdrawn after the additional account
            owner has completed the application.
          </>
        )}
      </p>
      <div className="u-text-right pc-u-mt">
        <RemoveAccount
          service={() => cancel(enrollmentId)}
          className="pc-btn"
          buttonText="Withdraw Invitation"
          componentName="Confirm Withdraw Invitation Modal"
          modalTitle={null}
          modalBody={<p>Are you sure you want to withdraw the invitation?</p>}
          modalCancelButtonText="Cancel"
          modalConfirmButtonText="Withdraw Invitation"
          onAccountRemoved={onWithdrawnInvitation}
        />
        <button
          type="button"
          onClick={onDone}
          className="pc-btn pc-btn--primary js-action-done"
        >
          Done
        </button>
      </div>
    </>
  );
}

InviteSecondaryAccountOwnerConfirmation.propTypes = {
  onDone: PropTypes.func.isRequired,
  onWithdrawnInvitation: PropTypes.func.isRequired,
  enrollmentId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
  expirationDate: PropTypes.string,
};

InviteSecondaryAccountOwnerConfirmation.defaultProps = {
  expirationDate: undefined,
};

export default InviteSecondaryAccountOwnerConfirmation;
