import _ from "underscore";
import { scaleTime, timeFormat, select } from "d3";
import Backbone from "backbone";
import chart from "libs/pcap/chart/chart";
import moment from "moment";
import * as DateUtils from "libs/pcap/utils/date2";
import * as TodayAccent from "libs/pcap/chart/utils/todayAccent";
import * as format from "libs/pcap/utils/format";
import template from "templates/partials/cashflow/lineGraph.html";
import isItFirstDayOfTheMonth from "libs/pcap/utils/isItFirstDayOfTheMonth";

var formatCurrency = format.formatCurrency;

var AXIS_TICK_SIZE = 5;

var DATA_INDEX_CUR_MONTH_INCOME = 0;
var DATA_INDEX_CUR_MONTH_SPENDING = 1;
var DATA_INDEX_CUR_MONTH_CASH_FLOW = 2;
var DATA_INDEX_PREV_MONTH_INCOME = 3;
var DATA_INDEX_PREV_MONTH_SPENDING = 4;
var DATA_INDEX_PREV_MONTH_CASH_FLOW = 5;

var DATE_FORMAT_LEGEND = "MMM YYYY";

var NOW = moment();

var ARIA_LABELS = {
  title: `Line graph of your $mode over time`,
  cashFlow: "cash flow",
  cashFlowDesc: "cash flow  - income minus expense - ",
  filteredByCategory: `filtered by category $category`,
  filteredByMerchant: `and by merchant $merchant`,
  desc: `compares $current and $previous $mode`
}

export default Backbone.View.extend({
  className: "cash-flow__line-chart",

  initialize: function (options) {
    this.filter = options.filter;
    this.data = options.data;
    this.xTicks = options.xTicks;
    this.xDomain = options.xDomain;
    this.xTickFormat = options.xTickFormat;
    this.render();
  },


  getModeDisplayName: function(withDescription = false) {
    const { incomeExpenseMode } = this.filter;
    const { cashFlowDesc, cashFlow } = ARIA_LABELS

    if (!incomeExpenseMode) {
      return withDescription? cashFlowDesc: cashFlow;
    }
    if(incomeExpenseMode === "expense"){
      return `${incomeExpenseMode}s`;
    }
    return  incomeExpenseMode;
  },

  getChartTitle: function() {
    const { categoryName, merchantName} = this.filter;
    const { title, filteredByCategory, filteredByMerchant } = ARIA_LABELS
    let chartTitle = title.replace("$mode", this.getModeDisplayName(true));

    if (categoryName) {
      chartTitle = `${chartTitle} ${filteredByCategory.replace("$category",categoryName)}`
    }
    if (merchantName) {
      chartTitle = `${chartTitle} ${filteredByMerchant.replace("$merchant",merchantName)}`
    }
    return chartTitle
  },

  getChartDesc: function() {
    const { current, previous} = this.getLegendLabels();
    const { desc } = ARIA_LABELS;
    return desc
      .replace("$current", current.toLowerCase())
      .replace("$previous",previous.toLowerCase())
      .replace("$mode", this.getModeDisplayName());
  },

  setXTicks: function (ticks) {
    this.xTicks = ticks;
  },

  setXDomain: function (domain) {
    this.xDomain = domain;
  },

  setXTickFormat: function (format) {
    this.xTickFormat = format;
  },

  render: function () {
    var legendLabels = this.getLegendLabels();
    var classNameLegendCurrent, classNameLegendPrevious;

    switch (this.filter.incomeExpenseMode) {
      case "income":
        classNameLegendCurrent = "chart-legend__item--cash-flow-income";
        classNameLegendPrevious = "chart-legend__item--last-month-income";
        break;
      case "expense":
        classNameLegendCurrent = "chart-legend__item--cash-flow-spending";
        classNameLegendPrevious = "chart-legend__item--last-month-expense";
        break;
      default:
        classNameLegendCurrent = "chart-legend__item--cash-flow";
        classNameLegendPrevious = "chart-legend__item--last-month-cashflow";
    }

    this.$el.html(
      template({
        classNameLegendCurrent: classNameLegendCurrent,
        currentMonthLabel: legendLabels.current,
        classNameLegendPrevious: classNameLegendPrevious,
        previousMonthLabel: legendLabels.previous,
        graphAltText: this.getChartTitle(),
      })
    );

    this.chartEl = this.$("svg")[0];

    this.chart = chart({
      xScale: scaleTime().clamp(true),
      type: "line",
      includeYZero: true,
      tooltip: {
        xFormat: timeFormat("Day %e"),
        yFormat: formatCurrency,
        className: "chart-tooltip--cash-flow chart-tooltip--small",
        legendClassName: "chart-legend--line",
      },
    });
    this.chart.xAxis.tickSizeInner(AXIS_TICK_SIZE);
    this.$("svg")
      .prepend(`<title id="cash-flow-line-chart-title"></title><desc id="cash-flow-line-chart-desc"></desc>`)
      .attr("aria-labelledby", "cash-flow-line-chart-title")
      .attr("aria-describedBy", "cash-flow-line-chart-desc");
    this.renderChart();
  },

  /**
   * Generates a pair of labels for the current month and previous month legend
   * based on the selected date range.
   * @returns {Object}  an object with `current` and `previous` keys.
   */
  getLegendLabels: function () {
    var data = this.data;
    var now = moment();
    var startDate = moment(this.filter.startDate, Date.API_FORMAT);
    var isCurrentMonthSelected = startDate.isSame(now, "month");

    var legends = {
      current: isCurrentMonthSelected
        ? "This Month"
        : startDate.format(DATE_FORMAT_LEGEND),
    };

    if (!_.isEmpty(data.previousMonthData)) {
      var previousStartDate = startDate.clone().subtract(1, "month");
      legends.previous = isCurrentMonthSelected
        ? "Last Month"
        : previousStartDate.format(DATE_FORMAT_LEGEND);
    }

    return legends;
  },

  getTodayDate: function (data) {
    /**
     * If it is first day of month, we fetch the data for the entire month as per product spec
     * Using `last` to get today date would return last day of the month, which is incorrect.
     * And hence the check.
     */
    return isItFirstDayOfTheMonth() && data[0] ? data[0] : _.last(data);
  },

  drawTodayAccent: function (data) {
    var body = select(this.chartEl).select(
      ".js-line-chart-container .chart__body"
    );
    var chartOptions = this.chart.options;
    var xScale = chartOptions.xScale;
    var yScale = chartOptions.yScale;
    var x = function (d) {
      return xScale(chartOptions.x(d));
    };
    var y = function (d) {
      return yScale(chartOptions.y(d));
    };

    _.each(
      data,
      function (config) {
        var todayDatum = this.getTodayDate(config.data);
        TodayAccent.drawLastDayLine({
          container: body,
          className: "cash-flow__last-day-line--" + config.className,
          datum: todayDatum,
          xAccessor: x,
          height: this.chart.innerHeight,
        });

        TodayAccent.drawLastDayPoint({
          container: body,
          className: "cash-flow__last-day-circle--" + config.className,
          datum: todayDatum,
          xAccessor: x,
          yAccessor: y,
          lastDayCircleRendered: function (className) {
            this.trigger("lastDayCircleRendered", className);
          }.bind(this),
        });

        TodayAccent.drawLastDayPoint({
          container: body,
          className: "cash-flow__lastMonth-last-day-circle " + config.className,
          datum: _.last(config.prevData),
          xAccessor: x,
          yAccessor: y,
          lastDayCircleRendered: function (className) {
            this.trigger("lastDayCircleRendered", className);
          }.bind(this),
        });
      }.bind(this)
    );
  },

  toggleLegend: function () {
    var chartLegend = this.$(".js-chart-legend");
    var currentMonthEl = chartLegend.find(".js-current-month");
    var previousMonthEl = chartLegend.find(".js-previous-month");

    var legendLabels = this.getLegendLabels();
    chartLegend.toggleClass("u-invisible", !legendLabels.previous);
    currentMonthEl.text(legendLabels.current);
    previousMonthEl.text(legendLabels.previous);

    if (this.filter.incomeExpenseMode === "expense") {
      currentMonthEl.toggleClass("chart-legend__item--cash-flow-income", false);
      currentMonthEl.toggleClass(
        "chart-legend__item--cash-flow-spending",
        true
      );
      previousMonthEl
        .removeClass(
          "chart-legend__item--last-month-income chart-legend__item--last-month-cashflow"
        )
        .addClass("chart-legend__item--last-month-expense");
    } else {
      currentMonthEl.toggleClass("chart-legend__item--cash-flow-income", true);
      currentMonthEl.toggleClass(
        "chart-legend__item--cash-flow-spending",
        false
      );
      // if we are in cashflow the previous month legend has other color
      if (this.filter.incomeExpenseMode) {
        previousMonthEl
          .removeClass(
            "chart-legend__item--last-month-expense chart-legend__item--last-month-cashflow"
          )
          .addClass("chart-legend__item--last-month-income");
      } else {
        previousMonthEl
          .removeClass(
            "chart-legend__item--last-month-expense chart-legend__item--last-month-income"
          )
          .addClass("chart-legend__item--last-month-cashflow");
      }
    }
  },

  isWithinRange: function (range, date) {
    var startDate = range[0];
    var endDate = range[1];
    return (
      date.isSame(startDate, "day") ||
      date.isSame(endDate, "day") ||
      date.isBetween(startDate, endDate, "day")
    );
  },

  renderChart: function () {
    var startDate = moment(this.filter.startDate);
    var endDate = moment(this.filter.endDate);

    // display the whole month if the current month is selected
    if (DateUtils.isCurrentMonthRange(startDate, endDate)) {
      endDate = startDate.clone().endOf("month");
    }

    var domain = [startDate, endDate];
    var currMonthData = this.data.currentMonthData;
    var prevMonthData = this.data.previousMonthData;
    if (
      !_.isEmpty(prevMonthData) &&
      (DateUtils.isMonthRange(startDate, endDate) ||
        DateUtils.isCurrentMonthRange(startDate, endDate))
    ) {
      var prevStartDate = startDate.clone().subtract(1, "month");
      var prevEndDate = prevStartDate.clone().endOf("month");

      if (endDate.date() >= prevEndDate.date()) {
        prevMonthData = prevMonthData.map(function (d) {
          return {
            x: d.x.clone().add(1, "month"),
            y: d.y,
          };
        });
      } else {
        domain = [prevStartDate, prevEndDate];
        currMonthData = currMonthData.map(function (d) {
          return {
            x: d.x.clone().subtract(1, "month"),
            y: d.y,
          };
        });
      }
      this.$("#cash-flow-line-chart-title").text(this.getChartTitle());
      this.$("#cash-flow-line-chart-desc").text(this.getChartDesc());
    }

    this.chart.setXDomain(domain);
    var xAxis = this.chart.xAxis;
    if (typeof this.xTicks === "function") {
      xAxis.tickValues(null).ticks(this.xTicks);
    } else {
      xAxis.tickValues(this.xTicks);
    }
    xAxis.tickFormat(this.xTickFormat);

    // Data represents 6 lines on the chart. See `DATA_INDEX_xxx` for reference.
    var chartData = [[], [], [], [], [], []];
    if (this.filter.incomeExpenseMode) {
      if (this.filter.incomeExpenseMode === "income") {
        chartData[DATA_INDEX_CUR_MONTH_INCOME] = currMonthData;
        chartData[DATA_INDEX_PREV_MONTH_INCOME] = prevMonthData;
      } else {
        chartData[DATA_INDEX_CUR_MONTH_SPENDING] = currMonthData;
        chartData[DATA_INDEX_PREV_MONTH_SPENDING] = prevMonthData;
      }
    } else {
      chartData[DATA_INDEX_CUR_MONTH_CASH_FLOW] = currMonthData;
      chartData[DATA_INDEX_PREV_MONTH_CASH_FLOW] = prevMonthData;
    }

    select(this.chartEl)
      .datum(chartData)
      .call(this.chart)
      .select(".js-chart-body");

    // hightlight the last day in series if the range is for the current month
    var isTodayInCurrentRange = this.isWithinRange([startDate, endDate], NOW);
    var dataAccent = [
      {
        data: isTodayInCurrentRange
          ? chartData[DATA_INDEX_CUR_MONTH_INCOME]
          : [],
        prevData: isTodayInCurrentRange
          ? chartData[DATA_INDEX_PREV_MONTH_INCOME]
          : [],
        className: "income",
      },
      {
        data: isTodayInCurrentRange
          ? chartData[DATA_INDEX_CUR_MONTH_SPENDING]
          : [],
        prevData: isTodayInCurrentRange
          ? chartData[DATA_INDEX_PREV_MONTH_SPENDING]
          : [],
        className: "spending",
      },
      {
        data: isTodayInCurrentRange
          ? chartData[DATA_INDEX_CUR_MONTH_CASH_FLOW]
          : [],
        prevData: isTodayInCurrentRange
          ? chartData[DATA_INDEX_PREV_MONTH_CASH_FLOW]
          : [],
        className: "cash-flow",
      },
    ];
    this.drawTodayAccent(dataAccent);
  },

  /**
   * Updates the chart with new data.
   * @param {Object}  data    The object in the following format:
   *                          ```
   *                          {
   *                            currentMonthData: [],
   *                            previousMonthData: []
   *                          }
   * @param {Object}  filter  The filter object. Used to update the legend.
   */
  update: function (data, filter) {
    this.data = data;
    if (filter) {
      this.filter = filter;
    }
    this.toggleLegend();
    this.renderChart();
  },
  remove: function () {
    this.chart?.destroy();
    Backbone.View.prototype.remove.apply(this, arguments);
  },
});
