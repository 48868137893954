import PropTypes from "prop-types";
import React from "react";
import noop from "utils/noop";
import { formatCurrency } from "libs/pcap/utils/format";
import { fromNow } from "libs/pcap/utils/fromNow";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";

/**
 * React implementation of Selectable Item component
 * https://devtrunk.pcapcloud.com/static/styleguide/dist/section-framework.html#kssref-framework-components-selectable-item
 */
class SelectableAccount extends React.Component {
  constructor(props) {
    super(props);
    this.handleEditIconClick = this.handleEditIconClick.bind(this);
  }

  handleEditIconClick(ev) {
    const { onEditIconClick, value } = this.props;
    if (onEditIconClick) {
      onEditIconClick(value, ev);
    }
  }

  renderWithBalanceAndTimeStamp() {
    return (
      <label
        className={`selectable-item ${this.props.className} ${
          this.props.isClosed ? "selectable-item--closed" : ""
        } qa-selectable-item`}
        data-hj-masked
      >
        <span className="selectable-item__checkbox">
          <input
            type="checkbox"
            aria-label={`${this.props.name || ""} ${
              this.props.info || ""
            }`.trim()}
            checked={this.props.checked}
            className="qa-selectable-checkbox"
            value={this.props.value}
            onChange={this.props.onChange || noop}
            disabled={this.props.disabled}
          />
        </span>
        <span className="selectable-item__body">
          <div className="l-spaced l-spaced--flush l-spaced--equal-height">
            <span className="u-clip-text">
              {this.props.name && (
                <span
                  className={`selectable-item__name qa-selectable-item-name u-preserve-case`}
                  title={this.props.name}
                >
                  {this.props.name}
                </span>
              )}
            </span>
            <span
              className="u-text-bold js-selectable-item-balance"
              data-hj-masked
            >
              {formatCurrency(this.props.balance, this.props.precision)}
            </span>
          </div>
          <div className="l-spaced l-spaced--flush l-spaced--equal-height">
            <span className="u-clip-text">
              {this.props.info && (
                <span
                  className={`selectable-item__info qa-selectable-item-info u-preserve-case`}
                  title={this.props.info}
                >
                  {this.props.info}
                </span>
              )}
            </span>
            <span>
              {this.props.isClosed
                ? "Closed"
                : fromNow(this.props.lastRefreshed)}
            </span>
          </div>
        </span>
      </label>
    );
  }

  render() {
    const { isClosed, info, showEditIcon } = this.props;

    if (this.props.showBalancesAndTimeStamp) {
      return this.renderWithBalanceAndTimeStamp();
    }

    let titleExtraClass = "";
    if (showEditIcon && this.props.tooltipText) {
      titleExtraClass = " selectable-item__info--with-2-icons";
    } else if (showEditIcon || this.props.tooltipText) {
      titleExtraClass = " selectable-item__info--with-1-icon";
    }

    return (
      <label
        className={`selectable-item ${this.props.className} ${
          isClosed ? "selectable-item--closed" : ""
        }`}
        data-hj-masked
      >
        <input
          type="checkbox"
          aria-label={`${this.props.name || ""} ${
            this.props.info || ""
          }`.trim()}
          checked={this.props.checked}
          value={this.props.value}
          onChange={this.props.onChange || noop}
          disabled={this.props.disabled}
          tabIndex="-1"
          className="pc-u-mr--"
        />
        <span className="selectable-item__body">
          {this.props.name && (
            <span
              className={`selectable-item__name u-preserve-case`}
              title={this.props.name}
            >
              {this.props.name}
            </span>
          )}
          {info && (
            <span>
              <span
                className={`selectable-item__info${titleExtraClass}${" "}u-preserve-case`}
                title={info}
              >
                {info}
              </span>
              <span className="selectable-item__icons-container">
                {showEditIcon && (
                  <button
                    aria-label="Edit account"
                    type="button"
                    onClick={this.handleEditIconClick}
                    className="selectable-item__edit-account-button pc-btn pc-btn--stripped pc-btn--tiny pc-u-ml--"
                    disabled={this.props.disabled}
                  >
                    <svg
                      className={`${
                        this.props.disabled
                          ? "icon-pencil--gray"
                          : "icon-pencil--primary"
                      } selectable-item__edit-account-icon`}
                      viewBox="0 0 12 12"
                    ></svg>
                  </button>
                )}
                {this.props.tooltipText && (
                  <InfoTooltipIcon
                    className={`${
                      showEditIcon
                        ? "selectable-item__editable-account-tooltip-icon"
                        : "pc-u-ml-"
                    }`}
                    title={this.props.tooltipText}
                  />
                )}
              </span>
            </span>
          )}
        </span>
      </label>
    );
  }
}

SelectableAccount.propTypes = {
  balance: PropTypes.number,
  precision: PropTypes.number,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  info: PropTypes.string,
  isClosed: PropTypes.bool,
  lastRefreshed: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  showBalancesAndTimeStamp: PropTypes.bool,
  tooltipText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showEditIcon: PropTypes.bool,
  onEditIconClick: PropTypes.func,
};

SelectableAccount.defaultProps = {
  balance: undefined,
  precision: 0,
  checked: false,
  className: "",
  disabled: false,
  info: undefined,
  isClosed: undefined,
  lastRefreshed: undefined,
  name: undefined,
  onChange: undefined,
  showBalancesAndTimeStamp: false,
  tooltipText: "",
  showEditIcon: false,
  onEditIconClick: undefined,
};

export default SelectableAccount;
