import React from "react";
import PropTypes from "prop-types";
import Modal from "components/modal/Modal.js";
import Input from "components/common/form/Input";
import Textarea from "components/common/form/Textarea";
import AbstractForm from "components/common/form/AbstractForm";

class EmailPreviewModal extends AbstractForm {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      isOpen,
      handleModalClosed,
      model,
      schema,
      RECIPIENTS_INPUT_PLACEHOLDER,
      handleInputChange,
    } = this.props;

    return (
      <Modal
        onClosed={handleModalClosed}
        isOpen={isOpen}
        componentName="Common Modal"
        className={`pc-modal pc-modal--medium referral-center__share-modal`}
        title={`<div class="referral-center__header-container u-preserve-case"><div class="referral-center__header qa-share-modal-header">Invitation email preview</div><div class="referral-center__subheader">Email will be sent from: <span>${window.username}</span></div></div>`}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const validation = this.validate();

            if (validation.valid === false) {
              e.stopPropagation();
              return;
            }
          }}
        >
          <div className="referral-center__email-preview-input">
            <label className="pc-label">To:</label>
            <Input
              ref={this.storeInputRef}
              type="text"
              className="referral-center__share-via-email-input input--huge"
              inputGroupClassName="referral-center__share-via-email-input-group"
              name="recipients"
              value={model.recipients}
              onChange={handleInputChange}
              validator={schema.properties.recipients}
              placeholder={RECIPIENTS_INPUT_PLACEHOLDER}
              sizeVariation="full"
              errorPlaceholder={true}
            />
          </div>
          <div className="referral-center__email-preview-input">
            <label className="pc-label">Subject:</label>
            <Input
              ref={this.storeInputRef}
              type="text"
              className="referral-center__share-via-email-input input--huge"
              inputGroupClassName="referral-center__share-via-email-input-group"
              name="subject"
              value={model.subject}
              onChange={handleInputChange}
              validator={schema.properties.subject}
              sizeVariation="full"
              errorPlaceholder={true}
            />
          </div>
          <div className="referral-center__email-preview-input">
            <label className="pc-label">Note:</label>
            <Textarea
              ref={this.storeInputRef}
              className="referral-center__email-preview-input-note"
              name="body"
              rows={3}
              value={model.body}
              onChange={handleInputChange}
              validator={schema.properties.body}
              errorPlaceholder={true}
            />
          </div>
          <div className="referral-center__email-preview-input">
            <input
              type="checkbox"
              className=""
              checked={model.reminder}
              name="reminder"
              onChange={handleInputChange}
            />{" "}
            Send my friend a reminder email in 3 days
          </div>
          <div className="l-spaced l-spaced--center pc-u-mt">
            <button
              className="pc-btn pc-btn--primary referral-center-send-btn qa-share-modal-send"
              type="submit"
            >
              Send Invitation
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

EmailPreviewModal.propTypes = {
  handleModalClosed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  model: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  RECIPIENTS_INPUT_PLACEHOLDER: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

EmailPreviewModal.defaultProps = {
  isOpen: false,
};

export default EmailPreviewModal;
