import { eligibility } from "../../../../utils/person";

export const updateSidebarSelectedAccount = () => {
  window.dispatchEvent(new CustomEvent("updateSidebar"));
};

// PAE mode is a strict participant emulator version of the app used by Empower support staff. Among other things,
// such users should not be allowed to see outside (non-Empower) accounts, or link accounts
// The `isEmulator` value checked here will be set by the Empower UI by the time out bundle
// is initialized.
export const isEmpowerPaeMode = () => {
  let integratedEligibility = eligibility();
  return (
    IS_EMPOWER &&
    integratedEligibility?.isEmulator &&
    integratedEligibility?.type === "PAE"
  );
};

// PRIVILEGED mode is an emulator mode like PAE, however it is less strict.
// Features that are visible to participants will also be visible in this mode.
// Any UI elements that, when interacted with, sends data to a service will be blocked/disabled
export const isEmpowerPrivilegedMode = () => {
  let integratedEligibility = eligibility();
  return (
    IS_EMPOWER &&
    integratedEligibility?.isEmulator &&
    integratedEligibility?.type === "PRIVILEGED"
  );
};

export const hasBankOrCCAccount = (accounts = []) => {
  return accounts.some(
    (account) =>
      account.productType === "BANK" || account.productType === "CREDIT_CARD"
  );
};

export const hasBankOrCCAccountWithNonZeroBalance = (accounts = []) => {
  return accounts.some(
    (account) =>
      (account.productType === "BANK" ||
        account.productType === "CREDIT_CARD") &&
      account.balance !== 0
  );
};
