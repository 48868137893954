import PropTypes from "prop-types";
import React from "react";
import PortfolioBalanceSummaryContainer from "components/portfolioBalanceSummary/PortfolioBalanceSummaryContainer";
import WidgetRowZeroState from "components/dashboard/WidgetRowZeroState";
import getZeroStateUserMessage from "libs/pcap/utils/getZeroStateUserMessage";

const DISPLAY_LOCATION__PORTFOLIO = "#/dashboard/portfolio";

export default class ThirdRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let zeroStateMessage = getZeroStateUserMessage(
      this.props.messages,
      DISPLAY_LOCATION__PORTFOLIO
    );

    if (zeroStateMessage) {
      return (
        <div className="dashboard__row">
          <div className="dashboard__cell">
            <div className="dashboard__item">
              <div className="pc-layout pc-layout--center">
                <div className="pc-layout__item pc-u-1/2">
                  <div className="dashboard-third-row__zero-state" />
                </div>
                <div className="pc-layout__item pc-u-1/2">
                  <WidgetRowZeroState
                    message={zeroStateMessage}
                    widgetName="Dashboard Portfolio Balance and Retirement Planning Row"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="dashboard__row">
        <div className="dashboard__cell">
          <div className="dashboard__item">
            <PortfolioBalanceSummaryContainer
              ace={this.props.dashboardMessages[DISPLAY_LOCATION__PORTFOLIO]}
            />
          </div>
        </div>
      </div>
    );
  }
}

ThirdRow.propTypes = {
  messages: PropTypes.array,
  dashboardMessages: PropTypes.object,
};
