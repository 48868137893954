/* eslint-disable camelcase */

import moment from "moment";
import { uniqueId } from "underscore";
import isProductionEnvironment from "libs/pcap/utils/isProductionEnvironment";
import objectPath from "object-path";

/**
 * Module specific constants
 */

const TALKABLE_TEST_CAMPAIGNS = {
  62797: 20, // Share with Friends - House Owners $20 - add_investment_acct_h20
  64851: 20, // Share with Friends - House Owners $20 (Tiered) - add_investment_acct_h20_tiered
  62796: 20, // Referral Center - House Owners $20 - referral_center_h
  64850: 20, // Referral Center - House Owners $20 (Tiered) - referral_center_h_tiered
  62618: 50, // Share with Friends - Clients $50 - add_investment_acct_c50
  64854: 50, // Share with Friends - Clients $50 (Tiered) - add_investment_acct_c50_tiered
  62616: 50, // Referral Center - Clients $50 - referral_center_c50
  65321: 50, // Referral Center - Clients $50 (Tiered) - referral_center_c50_tiered
  60886: 20, // Referral Center - Clients $20 - referral_center_c
  65319: 20, // Referral Center - Clients $20 (Tiered) - referral_center_c_tiered
  75879: 100, // Referral Center - Clients $100 - referral_center_c100
  60873: 20, // Referral Center - Users $20 - referral_center_b
  65320: 20, // Referral Center - Users $20 (Tiered) - referral_center_b_tiered
  59402: 20, // Share with Friends - Clients $20 - add_investment_acct_20_c
  64853: 20, // Share with Friends - Clients $20 (Tiered) - add_investment_acct_20_c_tiered
  58937: 20, // Share with Friends - Users $20 - add_investment_acct_20_b
  64852: 20, // Share with Friends - Users $20 (Tiered) - add_investment_acct_20_b_tiered
};

const TALKABLE_PROD_CAMPAIGNS = {
  62795: 20, // Referral Center - House Owners $20 - referral_center_h
  64801: 20, // Referral Center - House Owners $20 (Tiered) - referral_center_h_tiered
  62794: 20, // Share with Friends - House Owners $20 - add_investment_acct_h20
  64535: 20, // Share with Friends - House Owners $20 (Tiered) - add_investment_acct_h20_tiered
  62623: 50, // Share with Friends - Clients $50 - add_investment_acct_c50
  64847: 50, // Share with Friends - Clients $50 (Tiered) - add_investment_acct_c50_tiered
  62624: 50, // Referral Center - Clients $50 - referral_center_c50
  65324: 50, // Referral Center - Clients $50 (Tiered) - referral_center_c50_tiered
  62021: 20, // Referral Center - Clients $20 - referral_center_c
  65323: 20, // Referral Center - Clients $20 (Tiered) - referral_center_c_tiered
  75884: 100, // Referral Center - Clients $100 - referral_center_c100
  60895: 20, // Referral Center - Users $20 - referral_center_b
  65322: 20, // Referral Center - Users $20 (Tiered) - referral_center_b_tiered
  60084: 20, // Share with Friends - Clients $20 - add_investment_acct_20_c
  64848: 20, // Share with Friends - Clients $20 (Tiered) - add_investment_acct_20_c_tiered
  59401: 20, // Share with Friends - Clients $20 (Deprecated) - add_investment_acct_20_c_orig
  58934: 20, // Share with Friends - Users $20 - add_investment_acct_20_b
  64849: 20, // Share with Friends - Users $20 (Tiered) - add_investment_acct_20_b_tiered
};

const TALKABLE_CAMPAIGN_ID_TO_REWARDS_AMOUNT_MAP = isProductionEnvironment()
  ? TALKABLE_PROD_CAMPAIGNS
  : TALKABLE_TEST_CAMPAIGNS;
const UNIQUE_ID_PREFIX = "9999999999";
export const DEFAULT_REWARDS_AMOUNT = 20;
const API_DATA_DATE_FORMAT = "MM/DD/YYYY";

export const MESSAGE_SCROLL_TO_TOP = "MESSAGE_SCROLL_TO_TOP";
export const IFRAME_PAGE_IS_READY = "IFRAME_PAGE_IS_READY";
export const REFERRAL_EXPIRE_IN_DAYS = 30;
export const REFERRAL_GROUP_NAME = {
  LATEST_EMAIL_SHARES: "latest_email_shares",
};

export const FRIENDS_TABLE_TITLE_TOOLTIPS = {
  PENDING: "Invites that have yet to meet our referral program requirements",
  SUCCESSFUL: "Invites that met our referral program requirements",
  EXPIRED: `Invites that have expired who either did not register within ${REFERRAL_EXPIRE_IN_DAYS} days or did not add a qualified investment account`,
};

/**
 * Module specific utilities
 */

const selfIsIframed = window.parent !== window;

export function isReferralExpired(referral = {}) {
  const sharedAt = referral.shared_at;
  return (
    !sharedAt ||
    moment(sharedAt).isBefore(
      moment().subtract(REFERRAL_EXPIRE_IN_DAYS, "days")
    )
  );
}

export function sharedThisMonth(friend) {
  return moment(friend.shared_at).isSame(new Date(), "month");
}

export function kFormatter(number) {
  const thousand = 1000;
  return number >= thousand ? `${number / thousand}k` : number;
}

export function isEdgeBrowser() {
  return window.navigator.userAgent.includes("Edge");
}

/**
 * The Talkable iframe this module runs on need to let the parent window (our web app) know when
 * it's done loading so our app can remove the loader.
 */
export function messageParentThatIframeIsReady() {
  if (selfIsIframed && !isEdgeBrowser()) {
    // Edge is handled separately by Talkable share landing page
    window.parent.postMessage(IFRAME_PAGE_IS_READY, "*");
  }
}

export function sendEmailListToParent(emailList) {
  if (selfIsIframed) {
    window.parent.postMessage(
      JSON.stringify({ isEmailList: true, emailList }),
      "*"
    );
  }
}

export function processUnknownReferrals(data) {
  return data.filter((referral) => {
    if (!referral.isFromTalkable) {
      referral.id = uniqueId(UNIQUE_ID_PREFIX);
      referral.amount =
        TALKABLE_CAMPAIGN_ID_TO_REWARDS_AMOUNT_MAP[referral.campaignId] ||
        DEFAULT_REWARDS_AMOUNT;
      referral.allowed_to_send_new_offer = false;
      referral.shared_at = moment(referral.registeredDate, [
        API_DATA_DATE_FORMAT,
      ]).format();
      referral.approved = false;
      return true;
    }
    return false;
  });
}

export function getEmailListFromJson(data) {
  try {
    const obj = JSON.parse(data);
    if (obj.isEmailList && obj.emailList) {
      return obj.emailList;
    }
  } catch (ex) {
    /* Noop */
  }
}

export function getTieredRelatedData(friend) {
  const incentiveId = objectPath.get(friend, "incentive.identifier") || "";
  const isTieredLadderReferral = incentiveId.includes(
    "advocate_tiered_reward_2"
  );
  return {
    isTieredLadderReferral,
    isFromTieredCampaign: incentiveId.includes("advocate_tiered_reward"),
    tieredRewardAmount: isTieredLadderReferral
      ? objectPath.get(friend, "incentive.amount") || 0
      : 0,
  };
}

export function getRewardsAmountFromInvitations(referrals = []) {
  return referrals.reduce((sum, cur) => {
    let currentAmount = cur.amount || 0;
    const {
      isFromTieredCampaign,
      isTieredLadderReferral,
      tieredRewardAmount,
    } = getTieredRelatedData(cur);
    if (!currentAmount) {
      currentAmount = objectPath.get(cur, "incentives.referred.amount") || 0;
    }
    if (isFromTieredCampaign && isTieredLadderReferral && tieredRewardAmount) {
      currentAmount += tieredRewardAmount;
    }
    return sum + currentAmount;
  }, 0);
}
