import React from "react";
import PropTypes from "prop-types";
import BudgetingView from "./BudgetingView";
import Services from "services";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import parseResponseErrors from "libs/pcap/utils/response";
import { promisify } from "utils/service";
import { getTransactionSummaries } from "libs/pcap/utils/getTransactionSummaries";
import moment from "moment";
import DateUtils from "libs/pcap/utils/date";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import deepCopy from "deep-copy";

// define trailing year for API calls
// if today is end of the month , start date is first day of next month
// else, start date is one year from tomorrow
const START_DATE = moment()
  .subtract(1, "years")
  .add(1, "day")
  .format(DateUtils.API_FORMAT);
const END_DATE = moment().format(DateUtils.API_FORMAT);

export default class BudgetingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingChartData: true,
      showZeroState: false,
    };

    this.handleSave = this.handleSave.bind(this);
  }

  buildChartData(getTransactionsResp, getCategoriesResp) {
    //set view to zero state if invalid response data is returned
    if (
      !getTransactionsResp.transactions ||
      !getTransactionsResp.transactions.length ||
      !getCategoriesResp ||
      !getCategoriesResp.length
    ) {
      this.setState({
        spendingHistory: [],
        spendingAverage: 0,
        showZeroState: true,
        loadingChartData: false,
      });
      return;
    }

    const summaries = getTransactionSummaries({
      transactions: getTransactionsResp.transactions,
      startDate: START_DATE,
      endDate: END_DATE,
      categories: getCategoriesResp,
      includeBudgeting: true,
      includeCategories: true,
      includeAggregates: true,
    }).budgeting;

    this.setState({
      spendingHistory: summaries?.aggregates,
      spendingAverage: summaries?.average,
      loadingChartData: false,
    });
  }

  componentDidMount() {
    const {
      getTransactions,
      getCategories,
      getSpending,
      componentName,
    } = this.props;
    if (componentName) {
      ComponentAnalytics.trackView("Confirm Your Monthly Budget", {
        component: componentName,
      });
    }

    getSpending({
      intervalTypes: ["MONTH"],
      includeDetails: false,
    })
      .then((getUserSpendingResp) => {
        const monthInterval = getUserSpendingResp.intervals.find(
          (interval) => interval.type === "MONTH"
        );

        this.setState({
          monthlyBudget: monthInterval?.target || 0,
          loading: false,
        });
      })
      .catch((errors) => {
        this.setState({ errors });
      });

    //make separate API call for chart data to handle UX for slow server responses
    this.chartDataRequests = makeCancelablePromise(
      Promise.all([
        getTransactions({
          startDate: START_DATE,
          endDate: END_DATE,
        }),
        getCategories(),
      ])
    );

    this.chartDataRequests.promise.then(
      ([getTransactionsResp, getCategoriesResp]) => {
        this.buildChartData(deepCopy(getTransactionsResp), getCategoriesResp);
      },
      (err) => {
        this.setState({
          loading: false,
          errors: err,
        });
      }
    );
  }

  componentWillUnmount() {
    this.chartDataRequests.cancel();
  }

  handleSave(formModel) {
    const { onDone } = this.props;
    let { target } = formModel;

    target = parseFloat(target);

    new Promise((resolve, reject) => {
      Services.Spending.setTarget(
        {
          intervalType: "MONTH",
          target: target,
        },
        (err, response) => {
          const errors = parseResponseErrors(err, response);
          if (errors) {
            reject(errors);
            return;
          }
          resolve(response);
        },
        this
      );
    }).then(
      () => {
        onDone(target);
      },
      (errors) => {
        if (this.chartDataRequests.isCanceled()) {
          return;
        }
        this.setState({
          errors: errors,
          loading: false,
        });
      }
    );
  }

  render() {
    const {
      loading,
      loadingChartData,
      errors,
      spendingHistory,
      spendingAverage,
      monthlyBudget,
      showZeroState,
    } = this.state;

    if (loading) {
      return <LoadingOverlay active={loading} />;
    }

    if (errors) {
      return <Message className="pc-u-mb" severity="error" messages={errors} />;
    }

    return (
      <BudgetingView
        loadingChartData={loadingChartData}
        history={spendingHistory ? [spendingHistory] : []}
        monthlyBudget={monthlyBudget}
        average={spendingAverage}
        showZeroState={showZeroState}
        cancelButtonText={this.props.cancelButtonText}
        doneButtonText={this.props.doneButtonText}
        onCancel={this.props.onCancel}
        onDone={this.handleSave}
        showCancelButton={this.props.showCancelButton}
      />
    );
  }
}

BudgetingContainer.propTypes = {
  componentName: PropTypes.string,
  getTransactions: PropTypes.func,
  getCategories: PropTypes.func,
  getSpending: PropTypes.func,
  cancelButtonText: PropTypes.string,
  doneButtonText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool,
};

BudgetingContainer.defaultProps = {
  componentName: undefined,
  getTransactions: promisify(Services.Transactions.get),
  getCategories: promisify(Services.TransactionCategories.get),
  getSpending: promisify(Services.Spending.get),
  cancelButtonText: undefined,
  doneButtonText: undefined,
  showCancelButton: undefined,
};
