import React from "react";
import PropTypes from "prop-types";

const ExpandedOverviewTile = ({
  imgSrc,
  imgAlt,
  title,
  list,
  ctaText,
  ctaHref,
  ctaOnClick,
}) => {
  return (
    <div className="expanded-overview__tiles--tile pc-u-m-">
      <img src={`${window.staticUrl || ""}${imgSrc}`} alt={imgAlt} />
      <div className="expanded-overview__tiles--content">
        <h4 className="u-text-bold pc-u-mb- pc-u-mt-">{title}</h4>
        <ul className="pc-u-ml pc-u-mb- milli">
          {list.map((li) => (
            <li key={li} className="pc-u-mb-">
              {li}
            </li>
          ))}
        </ul>
        <a className="pc-btn" href={ctaHref} onClick={ctaOnClick}>
          {ctaText}
        </a>
      </div>
    </div>
  );
};

ExpandedOverviewTile.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaHref: PropTypes.string.isRequired,
  ctaOnClick: PropTypes.func.isRequired,
};

ExpandedOverviewTile.defaultProps = {};

export default ExpandedOverviewTile;
