/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import * as constants from "components/sidebar/constants";
import CopyPlanModal from "components/financialPlanner/CopyPlanModal";
import { promisify } from "utils/service";
import eventBus from "eventBus";
import { hasEligibleOrEnrolledAccounts } from "empower/utils/hasEligibleOrEnrolledAccounts";
import {
  getMtrEligibleAccounts,
  hasUnenrolledAllMtrEligibleAccounts,
} from "utils/account";
import RetirementPlannerEvents from "./RetirementPlannerEvents";

const allScope = { label: "All Assets", value: "ALL" };
const STRATEGY_SCOPE_OPTIONS = [
  {
    label: `Empower Managed Assets Only`,
    value: "PCAP",
  },
  allScope,
];

export default class CopyPlanModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      age: 0,
      lifeExpectancy: 0,
      analyticsEventBus: null,
      AMPLITUDE_EVENTS: null,
    };
    this.handleSave = this.handleSave.bind(this);
    this.requestSource = window.isAdvisorApp ? "ADVISOR" : "USER";
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  componentDidMount() {
    this.setState({ loading: true });
    const requests = [
      this.props.fetchPlans({ requestSource: this.requestSource }),
    ];

    if (window.isAdvisorApp && !IS_EMPOWER) {
      const getFunnelAttributes = function () {
        return Promise.resolve();
      };
      requests.push(
        this.props.fetchStrategies({
          requestSource: this.requestSource,
          includeAll: true,
        })
      );
      requests.push(getFunnelAttributes());
    }

    if (window.isAdvisorApp && IS_EMPOWER) {
      const fetchMaModelPortfolios = new Promise((resolve) => {
        this.props
          .fetchMaModelPortfolios()
          .then((response) => resolve(response))
          .catch(() => resolve({})); // Ignore error response
      });

      requests.push(fetchMaModelPortfolios);
      requests.push(this.props.getFunnelAttributes());
      requests.push(this.props.getAccounts());
    }

    Promise.all(requests)
      .then((responses) => {
        const [
          fetchPlanResponse,
          fetchStrategiesResponse,
          getFunnelAttributesResponse,
        ] = responses;

        const [plans, lifeExpectancy, age] =
          this.onFetchedPlans(fetchPlanResponse);

        if (window.isAdvisorApp && fetchStrategiesResponse) {
          let strategyScopeOptions = STRATEGY_SCOPE_OPTIONS;
          let accounts = [];

          if (IS_EMPOWER) {
            accounts = responses[3].accounts;
            strategyScopeOptions = [];

            if (hasEligibleOrEnrolledAccounts(getFunnelAttributesResponse)) {
              strategyScopeOptions.push({
                label: "Managed Assets (Eligible and Enrolled Accounts)",
                value: "MA",
              });
            }

            strategyScopeOptions.push(allScope);

            if (getMtrEligibleAccounts(accounts)?.length > 1) {
              strategyScopeOptions.push({
                label: "Custom",
                value: "CUSTOM_SELECTION",
              });
            }
          }

          const hasUnenrolledAllAccounts =
            accounts?.length > 0 &&
            hasUnenrolledAllMtrEligibleAccounts(accounts);
          const strategies = this.onFetchedStrategies(
            fetchStrategiesResponse,
            hasUnenrolledAllAccounts
          );
          this.setState({
            loading: false,
            plans,
            strategyOptions: strategies.strategyOptions,
            userStrategies: strategies.userStrategies,
            strategyScopeOptions,
            age,
            accounts,
            lifeExpectancy,
            errors: [],
          });
        } else {
          this.setState({
            loading: false,
            plans,
            age,
            lifeExpectancy,
            errors: [],
          });
        }
      })
      .catch((errors) => {
        this.setState({
          errors,
          loading: false,
        });
      });

    const analyticsEventBus = window.dashboardUtils?.eventBus;
    const AMPLITUDE_EVENTS = window.integratedSharedData?.AMPLITUDE_EVENTS ?? {
      SELECT_BUTTON: "select_button",
    };
    this.setState({ analyticsEventBus, AMPLITUDE_EVENTS });
  }

  onFetchedPlans(response) {
    const plans = window.isAdvisorApp
      ? response
      : response.filter(
          (p) =>
            p.result && p.result.whatIf && !p.result.whatIf.alternativeStrategy
        );
    const age = plans[0]?.result?.whatIf?.age;
    const lifeExpectancy = plans[0]?.result?.whatIf?.lifeExpectancy;

    const planOptions = plans.map((planRow) => {
      return {
        label: planRow.planName || "My Plan",
        value: planRow.planId,
      };
    });
    return [planOptions, lifeExpectancy, age];
  }

  onFetchedStrategies(response, hasUnenrolledAllAccounts = false) {
    const userStrategies = response.strategies || [];
    let strategies = [];

    if (IS_EMPOWER) {
      if (response && response.maModelPortfolios) {
        strategies = response.maModelPortfolios.map((modelPortfolioRow) => {
          return {
            label: `${modelPortfolioRow.displayName} (${modelPortfolioRow.description})`,
            value: modelPortfolioRow.name,
          };
        });
      }
      strategies.push({
        label: hasUnenrolledAllAccounts ? "Alternative" : "Recommended",
        value: "RECOMMENDED",
      });
    } else {
      strategies = response.allStrategies.map((strategyRow) => {
        return {
          label: strategyRow.label,
          value: strategyRow.name,
        };
      });
    }

    return { strategyOptions: strategies, userStrategies };
  }

  handleSave(newPlan) {
    const apiParams = Object.assign({}, newPlan, {
      requestSource: this.requestSource,
    });

    this.setState({ loading: true });
    this.props
      .clonePlan(apiParams)
      .then((response) => {
        this.setState({ loading: false });
        eventBus.trigger(
          "planlist:updated",
          constants.PLAN_LIST_SELECTION_TYPE_BY_ID,
          response.planId
        );

        if (this.props.onClosed) {
          this.props.onClosed();
        }
      })
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });

    if (IS_EMPOWER) {
      const { analyticsEventBus, AMPLITUDE_EVENTS } = this.state;
      analyticsEventBus?.dispatch(RetirementPlannerEvents.NEW_SCENARIO_SAVE);
      analyticsEventBus?.dispatchAmplitude({
        event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
        event_properties: {
          selection: RetirementPlannerEvents.NEW_SCENARIO_SAVE,
        },
      });
    }
  }

  render() {
    const { onClosed, checkPoorMarket, isGLWB } = this.props;
    return (
      <CopyPlanModal
        componentName="CopyPlanDialog"
        errors={this.state.errors}
        isOpen={this.props.isOpen}
        onSave={this.handleSave}
        loading={this.state.loading}
        onClosed={onClosed}
        plans={this.state.plans}
        strategyOptions={this.state.strategyOptions}
        strategyScopeOptions={this.state.strategyScopeOptions}
        userStrategies={this.state.userStrategies}
        isGLWB={isGLWB}
        age={this.state.age}
        lifeExpectancy={this.state.lifeExpectancy}
        checkPoorMarket={checkPoorMarket}
        accounts={this.state.accounts}
      />
    );
  }
}

CopyPlanModalContainer.defaultProps = {
  clonePlan: promisify(Services.myLife.clonePlan),
  fetchStrategies: promisify(Services.Strategy.get),
  fetchMaModelPortfolios: promisify(
    Services.AdviceProfile.getMaModelPortfolios
  ),
  getFunnelAttributes: promisify(Services.Profile.getFunnelAttributes),
  getAccounts: promisify(Services.Accounts.get),
  fetchPlans: promisify(Services.myLife.getPlans),
  checkPoorMarket: undefined,
  isGLWB: false,
  onClosed: undefined,
};

CopyPlanModalContainer.propTypes = {
  clonePlan: PropTypes.func,
  fetchStrategies: PropTypes.func,
  fetchMaModelPortfolios: PropTypes.func,
  fetchPlans: PropTypes.func,
  getFunnelAttributes: PropTypes.func,
  getAccounts: PropTypes.func,
  checkPoorMarket: PropTypes.bool,
  isGLWB: PropTypes.bool,
  onClosed: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};
