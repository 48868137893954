import PropTypes from "prop-types";
import React from "react";
import PostLoginActionsProvider from "./PostLoginActionsProvider";
import Services from "services";
import Mixpanel from "mixpanel";
import analytics from "analytics";
import _, { noop } from "underscore";
import { addOnClose } from "views/components/postLoginActions/VisibleUserMessages";
import moment from "moment";
import parseResponseErrors from "libs/pcap/utils/response";
import eventBus from "eventBus";
import AbstractForm from "components/common/form/AbstractForm";
import Textarea from "components/common/form/Textarea";
import IframeClient from "partner/iframeClient";
import { getJsonContent } from "views/components/postLoginActions/helpers";

class AdviceNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeAgo: "",
      hasAdvisor: false,
    };
    this.getCustomDisclaimerData = this.getCustomDisclaimerData.bind(this);
  }
  componentDidMount() {
    const { userMessage } = this.props;
    this.setState({ timeAgo: moment(userMessage.updatedTime).fromNow() });

    addOnClose(userMessage, () => {
      this.options.plaDetails = Object.assign(this.options.plaDetails || {}, {
        isClosedByApp: true,
      });
      this.closeClickHandler();
    });

    new Promise((resolve) => {
      // This is used in Communications bundle (viewUserAdvice page) in admin app to prevent CORS errors
      if (window.skipAdvisorApiCall === undefined) {
        Services.Advisor.get((error, response) => {
          const errors = parseResponseErrors(error, response);
          if (errors) {
            analytics.sendEngineeringEvent(
              "Error",
              "AdviceNotification.js: Services.Advisor.get: " +
                JSON.stringify(errors)
            );
          }
          var hasAdvisor = Boolean(
            response && response.spData && response.spData.name
          );
          this.setState({ hasAdvisor });
          resolve(userMessage);
        });
      } else {
        resolve(userMessage);
      }
    });
    // This will cause NotificationsContainer to mark this message as viewed
    eventBus.trigger(
      "postLoginActions:viewUserMessage",
      userMessage.userMessageId
    );

    if (userMessage && userMessage.template === "MEETING_STARTED") {
      if (typeof window.hj !== "undefined") {
        window.hj("trigger", "view_join_meeting_modal");
        window.hj("tagRecording", ["Join Meeting", window.userGuid]);
      }
    }

    if (IS_IFRAMED) {
      IframeClient.getInstance().triggerResize();
    }
  }

  componentDidUpdate() {
    if (IS_IFRAMED) {
      IframeClient.getInstance().triggerResize();
    }
  }

  getCustomDisclaimerData() {
    const jsonContent = getJsonContent(
      this.options && this.options.userMessage
    );
    return (jsonContent && jsonContent.disclaimer) || {};
  }

  render() {
    const {
      showFeedbackForm,
      showTimeAgo,
      userMessage,
      plaDetails,
      componentName,
    } = this.props;
    userMessage.disclaimer = this.getCustomDisclaimerData();
    userMessage.attributionSource = userMessage.template;
    return (
      <PostLoginActionsProvider {...this.props}>
        {({
          getButtons,
          userMessage: originalUserMessage,
          getResourceEmbedBlock,
        }) => {
          const userMessage = originalUserMessage;
          return (
            <div id="pc-advice-notification">
              <div className="pc-advice-notification__title-container">
                <h1
                  className="pc-advice-notification__title qa-pla-title"
                  dangerouslySetInnerHTML={{ __html: userMessage.title }}
                />
                {showTimeAgo && (
                  <span
                    className={`pc-advice-notification__time-ago-rebrand milli`}
                  >
                    {this.state.timeAgo}
                  </span>
                )}
              </div>
              <div className="pc-advice-notification__content qa-pla-body">
                {getResourceEmbedBlock(userMessage)}
                <div
                  dangerouslySetInnerHTML={{ __html: userMessage.summary }}
                />
              </div>
              <div className="pc-advice-notification__buttons-container qa-pla-footer pc-u-mb">
                {getButtons({
                  getButtonClassName: () =>
                    "pc-btn pc-btn--small pc-btn--primary pc-advice-notification_action-button u-preserve-case",
                })}
              </div>
              {this.state.hasAdvisor && showFeedbackForm && (
                <div
                  className={`pc-u-m pc-u-mb0 pc-advice-notification__comment-section-rebrand`}
                >
                  <FeedbackForm
                    plaDetails={plaDetails}
                    componentName={componentName}
                    userMessage={userMessage}
                  />
                </div>
              )}
            </div>
          );
        }}
      </PostLoginActionsProvider>
    );
  }
}

class FeedbackForm extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: this.props.model,
      resultMessage: "",
    };
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleFeedbackSubmit = this.handleFeedbackSubmit.bind(this);
  }

  componentDidMount() {
    this.focus();
  }

  handleSubmitClick(event) {
    if (event) {
      event.preventDefault();
    }
    const { model } = this.state;
    const validateResult = this.validate();
    if (validateResult.valid) {
      this.handleFeedbackSubmit(model.feedback);
    }
  }

  handleFeedbackSubmit(comment) {
    const { successMessage, componentName, plaDetails, userMessage } =
      this.props;
    Mixpanel.trackEvent(
      "Click on " + componentName + " Modal Send Feedback Button",
      _.extend(
        {
          component: componentName,
        },
        plaDetails
      )
    );

    if (comment) {
      Services.UserMessages.addComment(
        {
          userMessageId: userMessage.userMessageId,
          description: comment,
        },
        function (err, response) {
          const errors = parseResponseErrors(err, response);
          if (errors !== null && errors.length) {
            const message = _.first(errors);
            this.setState({ resultMessage: message });
            analytics.sendEngineeringEvent(
              "Error",
              "Services.UserMessages.addComment: " + JSON.stringify(errors)
            );
          } else {
            this.setState({ resultMessage: successMessage });
          }
        }.bind(this)
      );
    }
  }

  render() {
    const validator = FeedbackForm.validationSchema;
    return (
      <>
        {this.state.resultMessage === "" ? (
          <form onSubmit={this.handleSubmitClick}>
            <section>
              <div>
                <div className="pc-u-mb- u-text-bold">
                  Have a question? Send us a message. We&apos;re in your corner.
                </div>
                <Textarea
                  ref={this.storeInputRef}
                  id={"feedback"}
                  rows={2}
                  errorPlaceholder={true}
                  name={"feedback"}
                  validator={validator.feedback}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="u-text-center pc-u-mb-">
                <button
                  type="submit"
                  className="js-feedback-form-send pc-btn pc-btn--small pc-btn--primary"
                >
                  Ask an Advisor About This
                </button>
              </div>
            </section>
          </form>
        ) : (
          <div className="u-text-bold pc-u-pt+">
            <p className="pc-u-mt-">{this.state.resultMessage}</p>
          </div>
        )}
      </>
    );
  }
}

const EMPTY_VALIDATION_ERROR_MESSAGE = "Required";

AdviceNotification.propTypes = {
  componentName: PropTypes.string,
  userMessage: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func,
  onTitleChange: PropTypes.func,
  showFeedbackForm: PropTypes.bool,
  plaDetails: PropTypes.object,
  showTimeAgo: PropTypes.bool,
};

AdviceNotification.defaultProps = {
  componentName: "Advice Notification",
  onRequestClose: noop,
  onTitleChange: noop,
  showFeedbackForm: false,
  plaDetails: {},
  showTimeAgo: false,
};

FeedbackForm.validationSchema = {
  type: "object",
  feedback: {
    allowEmpty: false,
    type: "string",
    messages: { allowEmpty: EMPTY_VALIDATION_ERROR_MESSAGE },
  },
};

FeedbackForm.propTypes = {
  successMessage: PropTypes.string,
};

FeedbackForm.defaultProps = {
  successMessage:
    "Thank you for your question. Your advisor will be in contact with you shortly.",
};

export default AdviceNotification;
