import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import TransferDetailsBox from "../TransferDetailsBox";
import Message from "components/common/Message";
import { isOnUsInvestment } from "utils/account";
import { FREQUENCY_ONE_TIME } from "components/transferFunds/utils/constants";
import { FULL_MONTH_DATE_YEAR_FORMAT } from "libs/pcap/utils/date2";
import moment from "moment";
import { frequencyLabel } from "../../../../components/transferFunds/utils/labels";

export const ONE_TIME_CANCEL_INSTRUCTION = (
  <div>
    <p className="pc-u-mb-" data-testid="js-onetime-cancel-inst-para1">
      Deposits cannot be cancelled once initiated. Upon arrival, funds will be
      deposited into the default fund of your account until you make an
      investment selection.
    </p>
    <p data-testid="js-onetime-cancel-inst-para2">
      If you have previously set investment allocations or established an
      investment rebalance on your account, this investment will buy into your
      current investment allocations. Please see the Account Detail page for a
      full listing of your current investment allocations.
    </p>
  </div>
);
export const RECURRING_CANCEL_INSTRUCTION =
  "Future deposit requests can be canceled from the transfer funds page.";

const formatConsentLabel = (
  sourceAccount,
  targetAccount,
  model,
  personFullName
) => {
  const isCashTransfer = sourceAccount.isOnUsBank || targetAccount.isOnUsBank;
  const isInvestmentTransfer =
    isOnUsInvestment(sourceAccount) || isOnUsInvestment(targetAccount);
  const shouldDisplayEmpowerPersonalCashConsent =
    isCashTransfer && !isInvestmentTransfer;

  const isOnlyWithdrawal =
    sourceAccount.isOnUsBank && !targetAccount.isOnUsBank;
  const organizationNameCash = "Empower Personal Cash";
  const today = moment();
  const startDate = moment(model.startDate);
  let pendingConsentLabel;

  if (shouldDisplayEmpowerPersonalCashConsent) {
    if (isOnlyWithdrawal) {
      pendingConsentLabel =
        model.frequency === FREQUENCY_ONE_TIME ? (
          <div className="u-preserve-case">
            I, <span data-hj-masked>{personFullName}</span>, authorize UMB to
            initiate a single ACH electronic transfer as shown above on{" "}
            {today.format(FULL_MONTH_DATE_YEAR_FORMAT)}. This transfer will be
            swept from Program Accounts to your Funding Account and then
            transferred as shown above. The {organizationNameCash}™ Customer
            Account balance shown above is your Available Program Balance.
          </div>
        ) : (
          <div className="u-preserve-case">
            I, <span data-hj-masked>{personFullName}</span>, authorize UMB to
            initiate a {frequencyLabel(model.frequency)} recurring ACH
            electronic transfer as shown above beginning on{" "}
            {startDate.format(FULL_MONTH_DATE_YEAR_FORMAT)} until I cancel.
            We’ll process your transfer on the next business day if it falls on
            a weekend or holiday. These funds will be swept from Program
            Accounts to your Funding Account at UMB and then transferred as
            shown above. The {organizationNameCash} Customer Account balance
            shown above is your Available Program Balance.
          </div>
        );
    } else {
      pendingConsentLabel =
        model.frequency === FREQUENCY_ONE_TIME ? (
          <div className="u-preserve-case">
            I, <span data-hj-masked>{personFullName}</span>, authorize UMB to
            initiate a single ACH electronic transfer as shown above on{" "}
            {today.format(FULL_MONTH_DATE_YEAR_FORMAT)}. This transfer will be
            transferred into your Funding Account at UMB as shown above and then
            swept into Program Accounts. The {organizationNameCash}™ Customer
            Account balance shown above is your Available Program Balance.
          </div>
        ) : (
          <div className="u-preserve-case">
            I, <span data-hj-masked>{personFullName}</span>, authorize UMB to
            initiate a {frequencyLabel(model.frequency)} recurring ACH
            electronic transfer as shown above beginning on{" "}
            {startDate.format(FULL_MONTH_DATE_YEAR_FORMAT)} until I cancel.
            We’ll process your transfer on the next business day if it falls on
            a weekend or holiday. These funds will be transferred into your
            funding Account at UMB as shown above and then swept into Program
            Accounts. The {organizationNameCash} Customer Account balance shown
            above is your Available Program Balance.
          </div>
        );
    }
  }

  return pendingConsentLabel;
};

const TransferReview = (props) => {
  const {
    sourceAccount,
    targetAccount,
    model,
    onSubmit,
    onCancel,
    errors,
    showTitle,
    shouldShowTradingBlackoutWarningMsg,
    isTradingSuspended,
    personFullName,
  } = props;

  useEffect(() => {
    if (IS_EMPOWER)
      window.dashboardUtils?.eventBus.dispatch("review_your_request.page.load");
  }, []);

  const submitOnClick = useCallback(() => {
    onSubmit(model);
  }, [onSubmit, model]);

  const isContribution = isOnUsInvestment(targetAccount);
  const cancelInstructions =
    model.frequency === "ONE_TIME" || !model.frequency
      ? ONE_TIME_CANCEL_INSTRUCTION
      : RECURRING_CANCEL_INSTRUCTION;

  const consentLabel = formatConsentLabel(
    sourceAccount,
    targetAccount,
    model,
    personFullName
  );

  return (
    <div>
      {showTitle && (
        <h1
          className="nav-secondary__title"
          data-testid="js-transfer-review-title"
        >
          Review your request
        </h1>
      )}
      <p className="milli transfer-review__review-msg pc-u-mt">
        Please review the details below and click submit to process your
        request.
      </p>
      <Message severity="error" className="pc-u-mb" messages={errors} />
      <TransferDetailsBox
        sourceAccount={sourceAccount}
        targetAccount={targetAccount}
        model={model}
        showViewAccountNumberLink={true}
      />
      {isContribution && (
        <div
          className="milli pc-u-mt"
          data-testid="js-transfer-review-cancel-instructions"
        >
          {cancelInstructions}
        </div>
      )}
      {consentLabel && <div className="milli pc-u-mt">{consentLabel}</div>}
      {shouldShowTradingBlackoutWarningMsg && (
        <div className="pc-u-mb- pc-u-pt">
          Transactions cannot be conducted 10 minutes prior to market closure
          (3:50 PM EST).
        </div>
      )}
      <div className="pc-layout pc-layout--right pc-u-pt+">
        <button
          type="button"
          className="pc-btn back-btn js-transfer-review-cancel-btn"
          onClick={onCancel}
        >
          Back
        </button>
        <button
          type="button"
          className="pc-btn pc-btn--primary js-transfer-review-submit-btn"
          onClick={submitOnClick}
          disabled={isTradingSuspended}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        >
          Submit
        </button>
      </div>
    </div>
  );
};

TransferReview.propTypes = {
  sourceAccount: PropTypes.object.isRequired,
  targetAccount: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  errors: PropTypes.array,
  showTitle: PropTypes.bool,
  shouldShowTradingBlackoutWarningMsg: PropTypes.bool,
  isTradingSuspended: PropTypes.bool,
  personFullName: PropTypes.string,
};

TransferReview.defaultProps = {
  errors: undefined,
  showTitle: true,
  shouldShowTradingBlackoutWarningMsg: false,
  isTradingSuspended: false,
  personFullName: "",
};

export default TransferReview;
