/* eslint-disable react/display-name */

import React from "react";
import moment from "moment";
import { formatCurrency, formatNumber } from "libs/pcap/utils/format";
import DateUtils from "libs/pcap/utils/date";
import { compareAlpha } from "libs/pcap/table/data/compare";
import {
  compositeTimeComparator,
  compositeAlphaAndTimeComparator,
  compositeAmountAndTimeComparator,
  compositePriceAndTimeComparator,
  compositeQuantityAndTimeComparator,
} from "./sortingUtils";
import { isEmpty } from "underscore";
import amountSummaryAccessor from "./amountSummaryAccessor";
import TooltipIcon from "components/common/TooltipIcon";

export const template =
  '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner pc-u-p-- tooltip-inner--large"></div></div>';

const amountClassname =
  "pc-transactions-grid-cell--amount pc-transactions-grid-cell--amount-parent u-text-right qa-value";
const amountHeaderClassname =
  "pc-transactions-grid-cell--amount table__column--right qa-datagrid-amount";
const amountSummaryClassname =
  "pc-transactions-grid-cell--amount u-text-right qa-transactions-total-amount";

// eslint-disable-next-line react/prop-types
const EmpowerRow = ({ children, isEmpower }) => {
  if (!isEmpower) return children;
  return <div className="pc-transactions-grid-cell--empower">{children}</div>;
};

const createNewAccessor = (comparator, accessor) => {
  return (_, order) => comparator(accessor, order);
};

export default function transactionColumn(onTransactionDetails) {
  return [
    {
      header: "Date",
      accessor: (d) => d,
      formatter: (d) => (
        <EmpowerRow isEmpower={d.isEmpower}>
          {moment(d.isEmpower ? d.createdDate : d.transactionDate).format(
            DateUtils.DISPLAY_FORMAT
          )}
        </EmpowerRow>
      ),
      className: "pc-transactions-grid-cell--date qa-transaction-time",
      headerClassName: "pc-transactions-grid-cell--date qa-datagrid-date",
      summaryClassName: "pc-transactions-grid-cell--date",
      isSortable: true,
      sortOrder: "desc",
      summaryAccessor: () => "Total",
      comparator: createNewAccessor(compositeTimeComparator, (d) =>
        d.isEmpower ? d.createdDate : d.transactionDate
      ),
      isFilterable: false,
    },
    {
      header: "Action",
      accessor: (d) => d,
      titleAccessor: (d) => d.transactionType ?? d.label,
      className:
        "pc-transactions-grid-cell--account-name u-clip-text qa-account-name",
      headerClassName:
        "pc-transactions-grid-cell--account-name qa-datagrid-account-name",
      summaryClassName: "pc-transactions-grid-cell--account-name",
      isSortable: true,
      comparator: createNewAccessor(
        compositeAlphaAndTimeComparator,
        (d) => d.transactionType ?? d.label
      ),
      formatter: (d) => (
        <EmpowerRow isEmpower={d.isEmpower}>
          {d.transactionType ?? d.label}
        </EmpowerRow>
      ),
      style: {
        width: "15%",
      },
    },
    {
      header: "Description",
      accessor: (d) => d,
      titleAccessor: (d) => d.originalDescription,
      className:
        "pc-transactions-grid-cell--description u-clip-text qa-description",
      headerClassName:
        "pc-transactions-grid-cell--description qa-datagrid-description",
      summaryClassName: "pc-transactions-grid-cell--description",
      isSortable: true,
      comparator: createNewAccessor(
        compositeAlphaAndTimeComparator,
        (d) => d.description ?? "Details"
      ),
      filterOptionsComparator: compositeAlphaAndTimeComparator,
      isFilterable: false,
      formatter: function description(transaction) {
        if (transaction.isEmpower) {
          return (
            <button
              className="pc-btn pc-btn--link cancel-transfer-link js-cancel-btn qa-cancel-btn"
              data-testid="cancel-button"
              tabIndex={0}
              onClick={() => onTransactionDetails(transaction)}
              onKeyDown={(e) => {
                // Space bar or Enter
                if (e.key === " " || e.key === "Enter") {
                  e.preventDefault();
                  return onTransactionDetails(transaction);
                }
              }}
            >
              Details
            </button>
          );
        }

        if (!transaction.imageUrl) {
          return transaction.description;
        }

        return (
          <div className="transaction-description__container">
            <div className="transaction-description__left u-clip-text">
              {transaction.description}
            </div>{" "}
            <div className="transaction-description__right">
              <TooltipIcon
                title={`<img class="transaction-description__image" alt="Transaction Image" src="${transaction.imageUrl}" />`}
                tooltipProps={{ template }}
              />
            </div>
          </div>
        );
      },
    },
    {
      header: "Category",
      headerPluralityOverrides: {
        // See `ColumnFilterToggle.js`
        // `Category` has more complicated plural rules than just adding an `s` on the end.
        plural: "Categories",
      },
      accessor: (d) => d,
      className:
        "pc-transactions-grid-cell--category u-clip-text qa-category-name",
      headerClassName:
        "pc-transactions-grid-cell--category qa-data-grid-category",
      summaryClassName: "pc-transactions-grid-cell--category",
      isSortable: true,
      comparator: createNewAccessor(compositeAlphaAndTimeComparator, (d) => {
        if (d.isEmpower) {
          return d.category ?? "Requests";
        }

        return isEmpty(d.splits) ? d.categoryName : "";
      }),
      formatter: (d) => {
        if (d.isEmpower) {
          return (
            <EmpowerRow isEmpower={d.isEmpower}>
              {d.category ?? "Requests"}
            </EmpowerRow>
          );
        }
        return isEmpty(d.splits) ? d.categoryName : "";
      },
      filterAccessor: (d) => {
        if (d.isEmpower) {
          return [d.category ?? "Requests"];
        }
        return d.splits
          ? d.splits.map((s) => s.categoryName)
          : [d.categoryName];
      },
      filterOptionsComparator: compareAlpha,
      isFilterable: true,
      style: {
        width: "15%",
      },
    },
    {
      header: "Quantity",
      accessor: (d) => d.quantity,
      className: amountClassname,
      headerClassName: amountHeaderClassname,
      summaryClassName: amountSummaryClassname,
      isSortable: true,
      comparator: compositeQuantityAndTimeComparator,
      formatter: (v) => (v ? formatNumber(v) : ""),
      summaryFormatter: formatNumber,
      summaryAccessor: (transactions) =>
        transactions.reduce(
          (total, transaction) => total + (transaction.quantity ?? 0),
          0
        ),
      isFilterable: false,
      style: {
        width: "10%",
      },
    },
    {
      header: "Price",
      accessor: (d) => d.price,
      className: amountClassname,
      headerClassName: amountHeaderClassname,
      summaryClassName: amountSummaryClassname,
      isSortable: true,
      comparator: compositePriceAndTimeComparator,
      formatter: (v) => (v ? formatCurrency(v) : ""),
      summaryFormatter: formatCurrency,
      summaryAccessor: (transactions) =>
        transactions.reduce(
          (total, transaction) => total + (transaction.price ?? 0),
          0
        ),
      isFilterable: false,
      style: {
        width: "10%",
      },
    },
    {
      header: "Amount",
      accessor: (transaction) => transaction,
      className: amountClassname,
      headerClassName: amountHeaderClassname,
      summaryClassName: amountSummaryClassname,
      isSortable: true,
      comparator: compositeAmountAndTimeComparator,
      formatter: function amount(transaction) {
        const { amount } = transaction;
        if (transaction.isEmpower) {
          if (!amount) return "";
          return (
            <EmpowerRow isEmpower={true}>{formatCurrency(amount)}</EmpowerRow>
          );
        }

        const value = transaction.isCredit ? amount : amount * -1;
        return (
          <span
            title={transaction.isCredit ? "Credit" : "Debit"}
            className={value > 0 ? "positive" : ""}
          >
            {formatCurrency(value)}
          </span>
        );
      },
      summaryFormatter: formatCurrency,
      summaryAccessor: amountSummaryAccessor,
      isFilterable: false,
    },
  ];
}

export const buildDataForExportToCsvEmpowerColumns = function (transactions) {
  if (transactions != null) {
    return transactions
      .filter((t) => t.status !== "pending")
      .map((transaction) => {
        let accountName = transaction.transactionType;
        let description = transaction.description;
        let category = transaction.categoryName;
        let amount = transaction.isCredit
          ? transaction.amount
          : transaction.amount * -1;

        if (transaction.isEmpower) {
          accountName = transaction.label;
          description = "";
          category = transaction.category ?? "Requests";
          amount = transaction.amount;
        }

        const safeAccountName = `"${accountName}"`;
        const safeDescription = `"${description}"`;
        const safeCategoryName = `"${category}"`;

        return [
          transaction.transactionDate,
          safeAccountName,
          safeDescription,
          safeCategoryName,
          transaction.quantity,
          transaction.price,
          amount,
        ];
      });
  }
  return [];
};
