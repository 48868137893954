import PropTypes from "prop-types";
import React from "react";

export default function PercentChange({ context }) {
  let direction = "noChange";
  let sign = "";
  if (context < 0) {
    direction = "negative";
  } else if (context > 0) {
    direction = "positive";
    sign = "+";
  }
  let classNames = "tabular-numbers percent-change " + direction;
  return (
    <span className={classNames}>
      {sign}
      {context.toFixed(2)}%
    </span>
  ); // eslint-disable-line no-magic-numbers
}

PercentChange.defaultProps = {
  context: undefined,
};

PercentChange.propTypes = {
  context: PropTypes.number,
};
