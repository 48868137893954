var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button\n      type=\"button\"\n      class=\"pc-btn pc-btn--tiny pc-btn--primary js-account-action js-action-take-action disabled\"\n      value=\""
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0)) != null ? stack1 : "")
    + "\"\n    >\n      Take Action\n    </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button\n      type=\"button\"\n      class=\"pc-btn pc-btn--tiny pc-btn--primary js-account-action js-action-take-action\"\n      value=\""
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0)) != null ? stack1 : "")
    + "\"\n    >\n      Take Action\n    </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./site.html"),depth0,{"name":"templates/modules/sidebar/site","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"js-account-extras sidebar-account__extras is-hidden\">\n  <div class=\"sidebar-account__actions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPrivileged") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});