import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import analytics from "analytics";
import MarkAccountAsClosedView from "components/common/EditAccount/MarkAccountAsClosedView";
import { promisify } from "utils/service";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const isPrivileged = isEmpowerPrivilegedMode();
export default class MarkAccountAsClosedContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.handleMarkAsClosed = this.handleMarkAsClosed.bind(this);
  }

  handleMarkAsClosed(closedDate) {
    this.setState({ loading: true });
    this.props
      .closeAccount({
        accountId: this.props.model.accountId,
        closedDate: closedDate,
      })
      .then(() => {
        this.setState({ loading: false, errors: undefined });
        this.props.onMarkAsClosed();
        analytics.sendDataActionEvent(
          "Confirm Mark as Closed Click",
          "Account"
        );
      })
      .catch((errors) => {
        this.setState({ loading: false, errors });
      });
  }

  render() {
    const { model, onCancel } = this.props;
    const { loading, errors } = this.state;

    return (
      <MarkAccountAsClosedView
        model={model}
        onCancel={onCancel}
        loading={loading}
        errors={errors}
        onMarkAsClosed={this.handleMarkAsClosed}
        isPrivileged={isPrivileged}
      />
    );
  }
}

MarkAccountAsClosedContainer.defaultProps = {
  closeAccount: promisify(Services.Accounts.close),
};

MarkAccountAsClosedContainer.propTypes = {
  model: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onMarkAsClosed: PropTypes.func.isRequired,
  closeAccount: PropTypes.func,
};
