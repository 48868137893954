import React from "react";
import ReactDOM from "react-dom";
import objectPath from "object-path";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import Mixpanel from "mixpanel";
import ACESidebarNotification from "components/notifications/ACESidebarNotification";
import Account from "models/account";
import AccountView from "views/modules/sidebar/partials/account2";
import AccountGroupTemplate from "templates/modules/sidebar/accountGroup2.html";
import AccountGroupHeaderTemplate from "templates/modules/sidebar/accountGroupHeader2.html";
import AccountGroupHeaderMetadataTemplate from "templates/modules/sidebar/accountGroupHeaderMetadata.html";
import { getEmpowerAccountView } from "views/modules/sidebar/utils/empowerAccounts";
import { getGroupLabel } from "views/modules/sidebar/utils/empowerAccounts";
import moment from "moment";
import { scaleTime, extent, select } from "d3";
import markUserMessageAsViewed from "libs/pcap/utils/markUserMessageAsViewed";
import chart from "libs/pcap/chart/chart";
import DateUtil from "libs/pcap/utils/date";
import "collapse";
var NOW = moment();
var INTERVAL_HISTORIES = 90;
var COEFFICIENT_MIN_REDUCE = 0.03;

var AccountGroupView = Backbone.View.extend({
  className: "sidebar-account__group",
  tagName: "li",
  events: {
    "click .js-sidebar-account-group-label": "stopPropagation",
    "click .sidebar-account__group-header": "isSidebarAccountExpanded",
  },
  initialize: function (options) {
    this.options = options;
    this.balancesChart = chart({
      showXAxis: false,
      showYAxis: false,
      showXGrid: false,
      showYGrid: false,
      showYZeroLine: false,
      type: "area",
      xScale: scaleTime(),
    });

    this.empowerData = options.empowerData;
    // Is this the "In Progress ...." Group container?
    this.isInProgress =
      this.options.groupName === Account.PRODUCT_TYPES.IN_PROGRESS;

    this.$el.html(
      AccountGroupTemplate({
        groupName: options.groupName,
        panelName: options.groupName,
        label: Account.PRODUCT_TYPES_LABELS[options.groupName],
        groupClassName: this.isInProgress
          ? "sidebar-account__group-header--thin"
          : "",
      })
    );
    var groupName = this.options.groupName;
    var groupClassName = groupName
      ? groupName +
        " qa-" +
        groupName.toLowerCase().replace("_", "-") +
        "-accounts"
      : "";
    this.$el.addClass(groupClassName);
    $(options.elementContainer).append(this.$el);
    this.$list = this.$el.find("ul");
    this.$(".js-sidebar-account__group-header").html(
      AccountGroupHeaderTemplate(this.getComputedData())
    );

    this.chartEl = this.el.querySelector(".js-acc-group-balances-chart");
    this.sidebarPccLi = this.el.querySelector(".js-ace-sidebar-notification");

    this.model.on("change", this.updateMetadata, this);
    this.model.on("requestRenderAccount", this.renderAccounts, this);
    this.collection.on("reset", this.renderAccounts, this);
    this.collection.on("freshen", this.renderAccounts, this);
    this.collection.on("addAccounts", this.renderAccounts, this);

    if (this.isInProgress) {
      this.$el.addClass("inProgress");
    }
    this.accountViews = {};
    this.getUserMessagesWatch = Services.UserMessages.getV2.watch(
      this.checkForSidebarNotifications.bind(this)
    );
    this.render();
  },
  render: function () {
    this.updateMetadata();
    this.renderAccounts();
    return this;
  },
  updateMetadata: function () {
    this.$(".js-account-group-metadata").html(
      AccountGroupHeaderMetadataTemplate(this.getComputedData())
    );
  },
  renderAccount: function (account) {
    var isMoveItAccount = false;
    if (this.model.get("isEligibleForMoveIt")) {
      isMoveItAccount = Boolean(
        _.find(
          this.model.get("moveItAccounts"),
          (acc) => acc.accountId === account.get("accountId")
        )
      );
    }
    account.set({ isMoveItAccount: isMoveItAccount });
    if (isMoveItAccount && !this.model.get("hasSeenMoveItAccounts")) {
      this.model.set({ hasSeenMoveItAccounts: true });
      Mixpanel.trackEvent("View Move It Accounts", {
        component: "Sidebar",
      });
    }
    var view = this.accountViews[account.get("accountId")];
    // in-place updates, cache a reference to each account renderer by accountId and re-use
    // the renderer if it was already created
    if (typeof view == "object") {
      // exists, update it
      view.model.set(account.attributes);
      view.toBeRemoved = false;
    }

    // new, create it
    else if (IS_EMPOWER && account.get("empowerAttributes")) {
      getEmpowerAccountView(account, this.empowerData, (empowerAccountView) => {
        view = this.accountViews[account.get("accountId")] = empowerAccountView;
        this.$list.append(view.render().el);
        view.toBeRemoved = false;
      });
    } else {
      view = this.accountViews[account.get("accountId")] = new AccountView({
        model: account,
      });
      this.list = this.el.querySelector("ul");
      this.aceNode = this.el.querySelector("li.js-ace-sidebar-notification");
      this.list.insertBefore(view.render().el, this.aceNode);
      view.toBeRemoved = false;
    }
  },
  renderAccounts: function () {
    // mark all views for deletion
    _.each(
      this.accountViews,
      function (view) {
        view.toBeRemoved = true;
      },
      this
    );

    var ownedAccounts;
    ownedAccounts = this.collection.filter(this.fastlinkOwnsAccount, this);
    if (ownedAccounts?.length > 0) {
      // do not attempt to fetch for the "In Progress" box
      if (!this.isInProgress) {
        this.ownedAccounts = ownedAccounts;
        this.fetchGroupBalances();
      }
      this.$el.show();
      _.each(ownedAccounts, this.renderAccount, this);
    } else {
      this.$el.hide();
    }
    // remove views still marked for deletion
    _.each(
      this.accountViews,
      function (view) {
        if (view.toBeRemoved === true) {
          view.remove();
          delete this.accountViews[view.model.get("accountId")];
        }
      }.bind(this)
    );
  },

  fetchGroupBalances: function () {
    if (this.ownedAccounts) {
      Services.Histories.get(
        {
          userAccountIds: JSON.stringify(
            this.ownedAccounts.map(function (account) {
              return account.get("userAccountId");
            })
          ),
          startDate: NOW.clone()
            .subtract(INTERVAL_HISTORIES, "days")
            .format(DateUtil.API_FORMAT),
          endDate: NOW.format(DateUtil.API_FORMAT),
          interval: "DAY",
          types: JSON.stringify(["balances"]),
        },
        this.renderBalances.bind(this)
      );
    }
  },

  renderBalances: function (err, resp) {
    var data;
    if (err || !_.isEmpty(resp.spHeader.errors) || !resp.spData.histories) {
      data = [];
    } else {
      data = resp.spData.histories.map(function (d) {
        return {
          x: moment(d.date),
          y: Math.abs(d.aggregateBalance),
        };
      });
    }

    // Since we're not starting from 0 and there is no axes on the chart to reference the values by,
    // modified the Y domain to include a little more value on its lower side. This moves the minimum
    // value on the chart higher and visually look more appealing.
    var yDomain = extent(data, function (d) {
      return d.y;
    });
    var reducedMin = yDomain[0] - yDomain[0] * COEFFICIENT_MIN_REDUCE;
    if (reducedMin < 0) {
      reducedMin = 0;
    }
    yDomain[0] = reducedMin;

    this.balancesChart.options.yDomain = yDomain;
    select(this.chartEl).datum([data]).call(this.balancesChart);
  },

  /**
   * Fastlink version of ownsAccount. When an account is aggregated through Fastlink, we do not add them
   * to the 'Still Importing' section. We add them to their corresponding 'Product Type' sections. ie., a 'Cash' account
   * that is 'aggregating' and has fastLinkFlow set to 'NONE' is rendered in the 'Cash' section with the spinner
   * to give immediate feedback to the user. This method prevents 'aggregating' accounts from going into 'Still Importing.
   * Eventually this should replace ownsAccount() method
   *
   * This function determines if an account should be rendered in this account group or not.
   * Account group is a generic class that can render all kinds of account groups like: Cash, Investment, Loan, Mortgage.... Even In Progress
   * Since this component gets instanciated by accountList2.js which passes a configuration object including which kind of account group is
   * and the account 'collection' (including any kind of account), we need to perform a filter looking for the accounts that belong to this account group.
   *  @method  ownsAccount
   *  @param   {Object} account - Account model
   *  @returns {Boolean} True if the account belongs to this account group or false if it doesn't
   */
  fastlinkOwnsAccount: function (account) {
    // Do not show accounts marked as closed
    if (account.get("closedDate")) {
      return false;
    }

    var lastRefreshed = account.get("lastRefreshed");
    var nextAction = account.get("nextAction");

    // Hide PCB account when action is not PCB_FUND_NOW or NONE
    if (
      account.get("isOnUsBank") &&
      !(nextAction?.action === "PCB_FUND_NOW" || nextAction?.action === "NONE")
    ) {
      return false;
    }

    // account `productType` is "INVESTMENT" for empower and all other investment accounts
    // do not simply use {account.productType === this.options.accountGroup} mapping
    // - empower accounts should be grouped under "Empower Accounts" / dynamic getUIPreferences group name for empower app
    // - empower accounts should be grouped under "Investment Accounts" for pcap app
    const isEmpowerAccount = account.get("isEmpower");
    let accountProductType =
      isEmpowerAccount && IS_EMPOWER ? "EMPOWER" : account.get("productType");

    // If we are aggregating accounts linked through Fastlink, return here. Don't let them go through 'Still importing'
    if (nextAction?.fastLinkFlow && account.attributes?.aggregating) {
      account.set({ fastlinkAggregated: true });
      return accountProductType === this.options.groupName;
    }

    // If this is the "In progress account group"
    if (this.isInProgress && !account.get("fastlinkAggregated")) {
      // Include all the accounts that have never been aggregated (lastRefreshed === undefined) and are aggregating, blocked (nextAction === 'WAIT' || 'BLOCKED').
      // OR in BLACKOUT period. When an account is in error state after trying aggregating for the first time, it's lastRefreshed attribute will be
      // undefined, but the nextAction will be 'MORE_INFO'. In this case we should show the account in the needAttentionAccountGroup instead of
      // here. That is why we use nextAction === 'WAIT' in this filter.
      return (
        (typeof lastRefreshed === "undefined" || lastRefreshed < 0) &&
        (nextAction?.action === "WAIT" ||
          nextAction?.action === "BLOCKED" ||
          nextAction?.action === "BLACKOUT")
      );
    }

    return (
      accountProductType === this.options.groupName &&
      ((typeof lastRefreshed != "undefined" && lastRefreshed > 0) ||
        account.get("fastlinkAggregated"))
    );
  },

  stopPropagation: function (e) {
    e.stopPropagation();
    const eventBus = window.dashboardUtils
      ? window.dashboardUtils.eventBus
      : null;
    const eventName = "sidebar_category_header_click_event";
    if (eventBus) {
      eventBus.dispatch(eventName);
      eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
          "select_button",
        event_properties: { selection: eventName },
      });
    }
    this.navigate(e.target.dataset.href);
  },

  isSidebarAccountExpanded: function (e) {
    console.log(e);
    // When the label (span element) is focused, and the enter key is pressed
    // this function is invoked.
    const activeElement = document.activeElement;
    if (activeElement.localName === "span") {
      //This stops the expand/collapse
      e.stopPropagation();
      // Open the associated href from the span element
      this.navigate(activeElement.dataset.href);
    } else {
      // Due to accordion accessibility guidelines, each button must be enclosed in a heading
      // element of an appropriate level. The button is now a child of the heading element.
      const buttonClassName =
        this.$el.context.children[0]?.childNodes[1]?.className;

      // Sets the aria-expanded based on the collapsed class of the button
      this.$el.context.children[0].childNodes[1].ariaExpanded =
        buttonClassName.includes("collapsed") ? "true" : "false";
    }
  },

  getComputedData: function () {
    var label;
    var value;
    var isLiability = false;
    var isStillImporting = false;
    var isInstitutionalPartner = false;
    var isPCAPWidgetEligible = true;

    switch (this.options.groupName) {
      default:
        //Account.PRODUCT_TYPES.IN_PROGRESS
        label = "Still Importing";
        isStillImporting = true;
        break;
      case Account.PRODUCT_TYPES.CASH:
        label = "Cash";
        value = this.model.get("cashAccountsTotal");
        break;
      case Account.PRODUCT_TYPES.INVESTMENT:
        label = "Investment";
        value = this.model.get("investmentAccountsTotal");
        break;
      case Account.PRODUCT_TYPES.CREDIT:
        label = "Credit";
        value = this.model.get("creditCardAccountsTotal");
        isLiability = true;
        break;
      case Account.PRODUCT_TYPES.LOAN:
        label = "Loan";
        value = this.model.get("loanAccountsTotal");
        isLiability = true;
        break;
      case Account.PRODUCT_TYPES.MORTGAGE:
        label = "Mortgage";
        value = this.model.get("mortgageAccountsTotal");
        isLiability = true;
        break;
      case Account.PRODUCT_TYPES.OTHER_ASSET:
        label = "Other Asset";
        value = this.model.get("otherAssetAccountsTotal");
        break;
      case Account.PRODUCT_TYPES.OTHER_LIABILITY:
        label = "Other Liability";
        value = this.model.get("otherLiabilitiesAccountsTotal");
        isLiability = true;
        break;
      case Account.PRODUCT_TYPES.EMPOWER:
        label = getGroupLabel(this.empowerData?.accountGroupName);
        value = this.model.get("empowerAccountsTotal");
        isInstitutionalPartner = Boolean(this.empowerData?.accountGroupName);
        break;
    }
    // If the participant is not eligible for PCAP widgets, the account group title
    // should not take the PPT to the networth page. The group label is a static text instead of a link
    if (IS_EMPOWER && !window?.integratedEligibility?.showWidgets) {
      isPCAPWidgetEligible = false;
    }
    return {
      label: label,
      value: value,
      isLiability: isLiability,
      isStillImporting: isStillImporting,
      isInstitutionalPartner: isInstitutionalPartner,
      isPCAPWidgetEligible: isPCAPWidgetEligible,
    };
  },

  isAnyExtraFormVisible: function () {
    return _.some(this.accountViews, function (accountView) {
      return accountView.isExtraFormVisible;
    });
  },

  unmountSidebarNotification: function (messageId) {
    markUserMessageAsViewed(messageId);
    const props = {
      userMessage: {},
      handleClose: _.noop,
    };
    ReactDOM.render(
      React.createElement(ACESidebarNotification, props),
      this.sidebarPccLi
    );
  },

  checkForSidebarNotifications(err, response) {
    let sidebarNotifications = objectPath.get(response, "spData.userMessages");

    if (!sidebarNotifications) {
      return;
    }
    sidebarNotifications = sidebarNotifications.filter((el) => {
      return (
        el.displayLocations &&
        el.displayLocations.includes("#/sidebar/" + this.options.groupName) &&
        !el.lastViewedTime
      );
    });
    if (sidebarNotifications.length && ACESidebarNotification) {
      const props = {
        userMessage: sidebarNotifications[0],
        handleClose: this.unmountSidebarNotification.bind(this),
      };
      ReactDOM.render(
        React.createElement(ACESidebarNotification, props),
        this.sidebarPccLi
      );
    }
  },

  remove() {
    if (this.getUserMessagesWatch) {
      Services.UserMessages.getV2.unwatch(this.getUserMessagesWatch);
      this.getUserMessagesWatch = null;
    }

    ReactDOM.unmountComponentAtNode(this.sidebarPccLi);
    Backbone.View.prototype.remove.apply(this, arguments);
  },
  navigate(relativeUrl) {
    if (relativeUrl) {
      window.location.href = relativeUrl;
    }
  },
});

export default AccountGroupView;
