import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import Select from "components/common/form/Select";
import RadioGroup from "components/common/form/RadioGroup";
import { CURRENCY_FORMAT } from "components/common/form/formattingOptions";

class HomeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.handleYesNoRadioChange = this.handleYesNoRadioChange.bind(this);
  }

  findPromptField(fieldId) {
    const { customProduct } = this.props;
    return customProduct.prompts?.find((prompt) =>
      prompt.parts?.some((part) => part.id === fieldId)
    );
  }

  handleYesNoRadioChange(event, value) {
    const { onInputChange } = this.props;
    onInputChange(event, value === "Yes");
  }

  render() {
    const { model, storeInputRef, onInputChange, schema } = this.props;
    const { properties } = schema;

    const homeTypePrompts = this.findPromptField("propertyType");
    const homeTypePart = homeTypePrompts?.parts?.find(
      (part) => part.id === "propertyType"
    );

    const statePrompts = this.findPromptField("state");
    const statePart = statePrompts?.parts?.find((part) => part.id === "state");

    return (
      <>
        <div>
          <div className="pc-layout">
            {homeTypePrompts && (
              <div className="pc-form-group--small">
                <div className="pc-layout__item pc-u-1/3">
                  <label htmlFor="homeType" className="pc-label--tiny">
                    Home Type
                  </label>
                </div>

                <div className="pc-layout__item pc-u-2/3">
                  <Select
                    id="homeType"
                    className="input--tiny input--full"
                    name="propertyType"
                    onChange={onInputChange}
                    ref={storeInputRef}
                    value={model.propertyType}
                  >
                    {homeTypePart.validIds.map((id, idIndex) => {
                      return (
                        <option value={id} key={idIndex}>
                          {homeTypePart.validValues[idIndex]}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            )}
            <div className="pc-form-group--small">
              <div className="pc-layout__item pc-u-1/3">
                <label className="pc-label--tiny">Address</label>
              </div>

              <div className="pc-layout__item pc-u-2/3">
                <Input
                  autoComplete="off"
                  className="input--tiny input--full qa-addressline"
                  maxLength={40}
                  name="addressline"
                  onChange={onInputChange}
                  placeholder=""
                  ref={storeInputRef}
                  sizeVariation={"full"}
                  type="text"
                  validator={properties.addressline}
                  value={model.addressline}
                  ariaLabel="Address line"
                />
              </div>
            </div>
            <div className="pc-form-group--small">
              <div className="pc-layout__item pc-u-1/3">
                <label className="pc-label--tiny">City</label>
              </div>

              <div className="pc-layout__item pc-u-2/3">
                <Input
                  autoComplete="off"
                  className="input--tiny input--full qa-city"
                  maxLength={50}
                  name="city"
                  onChange={onInputChange}
                  placeholder=""
                  ref={storeInputRef}
                  sizeVariation={"full"}
                  type="text"
                  validator={properties.city}
                  value={model.city}
                  ariaLabel="City"
                />
              </div>
            </div>
            {statePrompts && (
              <div className="pc-form-group--small">
                <div className="pc-layout__item pc-u-1/3">
                  <label htmlFor="state" className="pc-label--tiny">
                    State
                  </label>
                </div>

                <div className="pc-layout__item pc-u-2/3">
                  <Select
                    id="state"
                    className="input--tiny input--full qa-state"
                    name="state"
                    onChange={onInputChange}
                    ref={storeInputRef}
                    validator={properties.state}
                    value={model.state}
                  >
                    {statePart.validIds.map((id, idIndex) => {
                      return (
                        <option value={id} key={idIndex}>
                          {statePart.validValues[idIndex]}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            )}
            <div className="pc-form-group--small">
              <div className="pc-layout__item pc-u-1/3">
                <label className="pc-label--tiny">Zip</label>
              </div>

              <div className="pc-layout__item pc-u-1/3">
                <Input
                  autoComplete="off"
                  className="input--tiny input--full qa-zip"
                  maxLength={40}
                  name="zip"
                  onChange={onInputChange}
                  placeholder=""
                  ref={storeInputRef}
                  sizeVariation={"full"}
                  type="text"
                  validator={properties.zip}
                  value={model.zip}
                  ariaLabel="Zip"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="edit-account__section-heading">
          <div>Home Value</div>
        </div>
        <div>
          <div className="pc-layout">
            <div className="pc-form-group--small">
              <div className="pc-layout__item pc-u-2/3">
                <label id="zestimate" className="pc-label--tiny">
                  Use{" "}
                  <a
                    href="https://www.zillow.com/zestimate"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Zestimate&reg;
                  </a>{" "}
                  for home value?
                </label>
              </div>
              <div className="pc-layout__item pc-u-1/3">
                <RadioGroup
                  className="pc-u-pr js-use-home-valuation qa-use-home-valuation"
                  containerClassName="l-spaced l-spaced--flush"
                  name="useHomeValuation"
                  onChange={this.handleYesNoRadioChange}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  ref={storeInputRef}
                  validator={null}
                  value={model.useHomeValuation ? "Yes" : "No"}
                  ariaLabelledById="zestimate"
                />
              </div>
            </div>
            {!model.useHomeValuation && (
              <div className="pc-form-group--small">
                <div className="pc-layout__item pc-u-1/3">
                  <label className="pc-label--tiny">Home Value</label>
                </div>

                <div className="pc-layout__item pc-u-2/3">
                  <Input
                    autoComplete="off"
                    className="input--tiny input--full qa-currentbalance"
                    formattingOptions={CURRENCY_FORMAT}
                    maxLength={15}
                    name="currentBalance"
                    onChange={onInputChange}
                    placeholder=""
                    prefix="$"
                    ref={storeInputRef}
                    sizeVariation={"full"}
                    type="text"
                    validator={properties.currentBalance}
                    value={model.currentBalance}
                    ariaLabel="Current Home Value"
                  />
                </div>
              </div>
            )}
            {model.zillowStatus === "INTERNAL_ERROR" &&
              model.useHomeValuation && (
                <div className="milli zillow-error">
                  Zestimate&reg; is temporarily unavailable.
                </div>
              )}
          </div>
          <div className="pc-u-pv- milli">
            Data is provided “as is” via the Zestimate API. &copy; Zillow, Inc.,
            2006-2021. By using Zestimate® for your home valuation, you agree to
            the Zillow{" "}
            <a
              href="https://bridgedataoutput.com/zillowterms"
              rel="noreferrer"
              target="_blank"
            ></a>
            Terms of Use. © Zillow, Inc., 2006-2021.
          </div>
        </div>
      </>
    );
  }
}

HomeDetails.propTypes = {
  customProduct: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  schema: PropTypes.object,
  storeInputRef: PropTypes.func.isRequired,
};

HomeDetails.defaultProps = {
  schema: {
    type: "object",
    properties: {
      addressline: {
        allowEmpty: false,
        required: true,
        type: "string",
      },
      city: {
        allowEmpty: false,
        required: true,
        type: "string",
      },
      state: {
        allowEmpty: false,
      },
      zip: {
        allowEmpty: false,
        required: true,
        type: "string",
      },
      currentBalance: {
        allowEmpty: false,
        required: true,
        type: "number",
      },
    },
  },
};

export default HomeDetails;
