export const linkAccountMessage = {
  networth: {
    title: "Let’s calculate your net worth",
    subTitle:
      // eslint-disable-next-line sonarjs/no-duplicate-string
      "Start by connecting investment, retirement, bank, and real estate accounts to calculate your assets and savings patterns, then credit cards and loans to understand your liabilities and spending habits.",
    successMessage:
      "Congratulations! You are on your way to better understanding your net worth.",
  },
  spending: {
    title: "Let’s understand your savings and spending",
    subTitle:
      "Start by connecting bank accounts and credit cards, followed by retirement and investment accounts to understand how your money flows.",
    successMessage:
      "Congratulations! You are on your way to better understanding your spending and saving.",
  },
  money: {
    title: "Let’s understand your net worth and your money flows",
    subTitle:
      "Start by connecting investment, retirement, bank, and real estate accounts to calculate your assets and savings patterns, then credit cards and loans to understand your liabilities and spending habits.",
    successMessage:
      "Congratulations! You are on your way to better understanding your net worth and money flows.",
  },
  retirement: {
    title:
      "Let’s understand where you stand so we can build a personalized plan",
    subTitle:
      "Start by connecting investment, retirement (401k, IRA), and bank accounts, followed by real estate to calculate your assets.",
    successMessage:
      "Congratulations! You are on your way to understanding where you stand so we can build a personalized plan.",
  },
  investment: {
    title: "Let’s analyze your portfolio",
    subTitle:
      "Start by connecting retirement (401k, IRA) and other investment accounts to review your allocation, risk, and fees.",
    successMessage:
      "Congratulations! You are on your way to better understanding your portfolio.",
  },
  other: {
    title: "Let’s understand your spending and saving",
    subTitle:
      "Start by connecting investment, retirement, bank, and real estate accounts to calculate your assets and savings patterns, then credit cards and loans to understand your liabilities and spending habits.",
    successMessage:
      "Congratulations! You are on your way to better understanding your spending and saving.",
  },
  default: {
    title: "Last step, link your accounts",
    subTitle:
      "Investments, 401ks, IRAs, Credit Cards, Banks, and Real Estate, all in one place.",
    successMessage: "",
  },
};
