import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import AccountNumberForm from "../AccountNumberForm";

const HELP_TEXT_DEPOSIT_VERIFICATION =
  "We will send two small deposits to your account below (amounts below $1), it will take 2-3 business days to appear in your account. Once you have the numbers, you can come back and complete the deposit verification.";

const organizationNameCash = "Empower Personal Cash";

class MicroDepositInitiate extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
      isMicroDepositInitiated: props.isMicroDepositInitiated,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onMicroDepositInitiated = this.onMicroDepositInitiated.bind(this);
  }

  componentDidMount() {
    this.focus();
  }

  handleSubmit(event) {
    const { model } = this.state;
    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.onConfirm(model, this.onMicroDepositInitiated);
    }
    event.preventDefault();
  }

  onMicroDepositInitiated() {
    this.setState({ isMicroDepositInitiated: true });
  }

  render() {
    const {
      onCancel,
      onBackToDashboard,
      onDone,
      errors,
      logoPath,
      accountName,
      balance,
      firmName,
      isIAVFailed,
      className,
    } = this.props;
    const { isMicroDepositInitiated } = this.state;

    if (isMicroDepositInitiated) {
      return (
        <section className="micro-deposit-initiated js-micro-deposit-initiated">
          <div className={className}>
            <p className="u-text-bold">
              Your account verification has been initiated.
            </p>
            <p>
              You will see two small deposits in this bank account within the
              next 2-3 business days.
            </p>
            <p>
              You will not be able to use this bank account to transfer funds
              into your {organizationNameCash}
              <sup>&trade;</sup> account until you receive the deposits in your
              bank account and enter the deposit amount to verify the account.
            </p>
          </div>
          <div className="u-text-right">
            <button
              type="button"
              className="js-micro-deposit-initiated-done pc-btn pc-btn--primary"
              onClick={onDone}
            >
              Choose a Different Account
            </button>
            <button
              type="button"
              className="js-micro-deposit-initiated-cancel pc-btn pc-btn--primary"
              onClick={onBackToDashboard}
            >
              Go Back to Dashboard
            </button>
          </div>
        </section>
      );
    }

    return (
      <>
        {isIAVFailed && (
          <label className="pc-help-block pc-help-block--small pc-help-block--error pc-u-mb js-error-cannot-verify-iav">
            We are having some trouble verifying this account. Let&apos;s try
            verifying a different way.
          </label>
        )}
        <AccountNumberForm
          logoPath={logoPath}
          accountName={accountName}
          firmName={firmName}
          balance={balance}
          helpText={HELP_TEXT_DEPOSIT_VERIFICATION}
          submitButtonText="Verify Account"
          refFunc={this.storeInputRef}
          onChange={this.handleInputChange}
          onSubmit={this.handleSubmit}
          onCancel={onCancel}
          errors={errors}
        />
      </>
    );
  }
}

MicroDepositInitiate.propTypes = {
  model: PropTypes.object,
  logoPath: PropTypes.string,
  accountName: PropTypes.string,
  firmName: PropTypes.string,
  errors: PropTypes.array,
  balance: PropTypes.number,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBackToDashboard: PropTypes.func.isRequired,
  isIAVFailed: PropTypes.bool,
  onDone: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MicroDepositInitiate.defaultProps = {
  isMicroDepositInitiated: false,
  isIAVFailed: false,
  accountName: undefined,
  firmName: undefined,
  className: undefined,
  balance: undefined,
};

export default MicroDepositInitiate;
