import PropTypes from "prop-types";
import React from "react";
import withTooltips from "components/higherOrderComponents/withTooltips";
import Input from "components/common/form/Input";
import PositiveCurrencyFormatter from "components/common/form/formatters/positiveCurrency";
import AbstractForm from "components/common/form/AbstractForm";
import { trackClick, trackEvent } from "components/common/ComponentAnalytics";
import RecurringTransfersLink from "components/transferFunds/RecurringTransfersLink";
import LoadingOverlay from "components/common/LoadingOverlay";
import { noop } from "underscore";

const COMPONENT_NAME = "Strategy Projection";
const removeJunkCharacters = new RegExp("[^0-9.]", ["g"]);
const currencyRegex = new RegExp("[0-9]*[.]?[0-9]{0,2}", ["g"]);

const changeFormatter = {
  format: (value) => value,
  unformat: (value) => {
    value = value.replace(removeJunkCharacters, "");
    const matches = String(value).match(currencyRegex);
    return matches.length ? matches[0] : "";
  },
};

const formatter = {
  format: (val) => {
    if (parseInt(val, 10) === parseFloat(val)) {
      return PositiveCurrencyFormatter.format(val, 0);
    }
    return PositiveCurrencyFormatter.format(val, 2);
  },
  unformat: (val) => {
    if (parseInt(val, 10) === parseFloat(val)) {
      return PositiveCurrencyFormatter.unformat(val, 0);
    }
    return PositiveCurrencyFormatter.unformat(val, 2);
  },
};

class ProjectionSummaryForm extends AbstractForm {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetContribution = this.resetContribution.bind(this);
    this.showContributionModal = this.showContributionModal.bind(this);
    this.handleContributionChange = this.handleContributionChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleOnLinkLoaded = this.handleOnLinkLoaded.bind(this);

    this.state = {
      model: {
        monthlyContribution: this.props.currentContribution
          ? formatter.format(this.props.currentContribution)
          : "",
      },
      loadingRecurringTransfersLink: true,
    };
  }

  handleInputChange(ev, value) {
    super.handleInputChange(ev, value);
  }

  handleContributionChange(event) {
    if (event) {
      event.preventDefault();
    }

    if (this.state.model.monthlyContribution === "") {
      this.setState(
        {
          model: {
            monthlyContribution: "0",
          },
        },
        () => {
          this.props.onContributionChange(0);
        }
      );
    } else if (
      formatter.unformat(this.state.model.monthlyContribution) !==
      this.props.newContribution
    ) {
      const contribution =
        this.props.currentContribution < 0
          ? -formatter.unformat(this.state.model.monthlyContribution)
          : formatter.unformat(this.state.model.monthlyContribution);
      this.setState(
        {
          model: {
            monthlyContribution: String(Math.abs(contribution)),
          },
        },
        () => {
          this.props.onContributionChange(contribution);
        }
      );
    }

    trackClick(event, COMPONENT_NAME);

    window.dashboardUtils?.eventBus.dispatch(
      "personal_strategy.projection_preview_contribution_change.click"
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    const contribution =
      this.props.currentContribution < 0
        ? -formatter.unformat(this.state.model.monthlyContribution)
        : formatter.unformat(this.state.model.monthlyContribution);

    trackClick(event, COMPONENT_NAME);
    window.dashboardUtils?.eventBus.dispatch(
      "personal_strategy.projection_submit_contribution_change.click"
    );
    this.props.onContributionSubmit(contribution);
  }

  resetContribution(event) {
    this.setState(
      {
        model: {
          monthlyContribution: this.props.currentContribution
            ? formatter.format(this.props.currentContribution)
            : "",
        },
      },
      this.props.onResetContributionChange
    );

    trackClick(event, COMPONENT_NAME);
    window.dashboardUtils?.eventBus.dispatch(
      "personal_strategy.projection_revert_contribution_change.click"
    );
  }

  handleBlur() {
    if (this.state.model.monthlyContribution === "") {
      this.setState(
        {
          model: {
            monthlyContribution: this.props.currentContribution
              ? formatter.format(this.props.currentContribution)
              : "",
          },
        },
        this.props.onResetContributionChange
      );
    } else {
      this.setState({
        model: {
          monthlyContribution: formatter.format(
            formatter.unformat(this.state.model.monthlyContribution)
          ),
        },
      });
    }
  }

  handleFocus() {
    this.setState({
      model: {
        monthlyContribution: changeFormatter.unformat(
          this.state.model.monthlyContribution
        ),
      },
    });
    trackEvent(COMPONENT_NAME, "Click on Strategy Contribution Input Field");
  }

  showContributionModal(event) {
    event.preventDefault();
    this.props.showContributionModal();
  }

  handleOnLinkLoaded() {
    this.setState({
      loadingRecurringTransfersLink: false,
    });
  }

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentContribution !== this.props.currentContribution) {
      this.setState({
        model: {
          monthlyContribution: this.props.currentContribution
            ? formatter.format(this.props.currentContribution)
            : "",
        },
      });
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      numberOfContributions,
      numberOfWithdrawals,
      currentContribution,
      newContribution,
      accountName,
    } = this.props;
    const monthlyContributionInput = formatter.unformat(
      this.state.model.monthlyContribution
    );
    const isDisableButtons =
      monthlyContributionInput === "" ||
      monthlyContributionInput === Math.abs(currentContribution);

    const hasNewContribution = newContribution !== undefined;
    const isCurrentContribution =
      !hasNewContribution || newContribution === currentContribution;

    let formHeaderText = "";
    formHeaderText += isCurrentContribution ? "Current" : "New";
    if (currentContribution < 0) {
      formHeaderText +=
        numberOfWithdrawals > 1 ? " Net Withdrawal" : " Withdrawal";
    } else {
      formHeaderText +=
        numberOfContributions > 1 ? " Net Contributions" : " Contribution";
    }

    const contributionTooltipText = `This number represents all monthly deposits and withdrawals for your Empower account(s).`;
    const netContributionTooltip = (
      <span
        ref={(el) => {
          this.props.storeTooltipRef(el, {
            container: "body",
            placement: "top",
          });
        }}
        className="icon__help-circled projection-summary__projection-sub-title-tooltip"
        data-toggle="tooltip"
        aria-label={contributionTooltipText}
        title={contributionTooltipText}
      />
    );

    const formClasses =
      "projection-summary-form js-projection-summary-form " +
      this.props.className;
    return (
      <>
        <LoadingOverlay active={this.state.loadingRecurringTransfersLink} />
        <div className={formClasses}>
          <div className="projection-summary-form__banner">
            Want to earn more? Increasing contributions can grow your portfolio
            faster.
          </div>
          <div
            className={`pc-u-mt-- js-projection-summary-form-header u-sentence-case`}
          >
            {formHeaderText}
            {isCurrentContribution &&
              (numberOfContributions > 0 || numberOfWithdrawals > 0) &&
              netContributionTooltip}
          </div>

          {accountName && (
            <div
              className="js-projection-summary-form-account-name"
              data-hj-masked
            >
              <strong className="pc-epsilon">{accountName}</strong>
            </div>
          )}

          <form
            onSubmit={this.handleContributionChange}
            className="pc-form-group pc-layout pc-layout--tiny projection-summary-form__input-row"
          >
            <div
              className={`pc-layout__item ${
                IS_EMPOWER ? "pc-u-1/2" : "pc-u-2/5"
              }`}
            >
              <div className="pc-input-group pc-input-group--small">
                <Input
                  prefix="$"
                  type="text"
                  className="pc-input-group__field"
                  name="monthlyContribution"
                  placeholder="0"
                  ref={this.storeInputRef}
                  formatter={changeFormatter}
                  maxLength={10}
                  onChange={this.handleInputChange}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  value={this.state.model.monthlyContribution}
                  sizeVariation="full"
                />
              </div>
            </div>
            <div
              className={`projection-summary-form__input-label ${
                IS_EMPOWER ? "pc-u-1/4" : "pc-u-2/5"
              }`}
            >
              <span>/month</span>
            </div>
            <div
              className={`${IS_EMPOWER ? "pc-u-1/4 u-text-right" : "pc-u-1/5"}`}
            >
              <button
                type="button"
                disabled={isDisableButtons}
                onClick={this.handleContributionChange}
                className="pc-btn pc-btn--tiny pc-btn--primary"
              >
                Preview
              </button>
            </div>
          </form>
          <div className="pc-zeta pc-u-mt">
            <RecurringTransfersLink onLoaded={this.handleOnLinkLoaded} />
          </div>
          {numberOfContributions + numberOfWithdrawals > 1 && (
            <div className="js-projection-summary-form-view-all-contributions">
              <button
                onClick={this.showContributionModal}
                className="pc-btn pc-btn--link"
              >
                View All{" "}
                {numberOfContributions ? "Contributions" : "Withdrawals"}
              </button>
            </div>
          )}
          {!isCurrentContribution && (
            <div className="projection-summary-form__footer-text">
              {currentContribution < 0
                ? "Current Withdrawal: "
                : "Current Contribution: "}
              ${formatter.format(currentContribution)}
            </div>
          )}
          <div className="projection-summary-form__footer">
            <button
              type="button"
              disabled={isDisableButtons}
              onClick={this.resetContribution}
              className="pc-btn pc-btn--tiny projection-summary-form__revert"
            >
              Revert
            </button>
            <button
              type="button"
              disabled={isDisableButtons}
              onClick={this.handleSubmit}
              className="pc-btn pc-btn--tiny pc-btn--primary"
            >
              Submit
            </button>
          </div>
        </div>
      </>
    );
  }
}

ProjectionSummaryForm.propTypes = {
  className: PropTypes.string,
  accountName: PropTypes.string,
  currentContribution: PropTypes.number,
  newContribution: PropTypes.number,
  numberOfContributions: PropTypes.number,
  numberOfWithdrawals: PropTypes.number,
  onContributionChange: PropTypes.func,
  onResetContributionChange: PropTypes.func,
  showContributionModal: PropTypes.func,
  onContributionSubmit: PropTypes.func,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
};

ProjectionSummaryForm.defaultProps = {
  allContributions: [],
  allWithdrawals: [],
  onContributionChange: noop,
  onContributionSubmit: noop,
  onResetContributionChange: noop,
};

export default withTooltips(ProjectionSummaryForm);
