import React from "react";
import PropTypes from "prop-types";

const LinkAccountVideo = ({ url }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <img
        src={`${
          window.staticUrl || ""
        }/static/img/linkAccount/LinkAccountsVideo.svg`}
        alt="link your accounts video"
      />
    </a>
  );
};

LinkAccountVideo.propTypes = {
  url: PropTypes.string,
};

LinkAccountVideo.defaultProps = {
  url: "",
};

export default LinkAccountVideo;
