import Services from "services";
import analytics from "analytics";
import parseResponseErrors from "libs/pcap/utils/response";

export const EVENT_GROUP_ID = window.csrf;

// Reference: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export const getUrlParams = (url) => {
  if (!url || !url.includes("?")) {
    return {};
  }
  const arr = url.split("?")[1].split("&");
  const params = {};
  for (let i = 0; i < arr.length; ++i) {
    let param = arr[i].split("=", 2);
    if (param.length === 1) {
      params[param[0]] = "";
    } else {
      params[param[0]] = decodeURIComponent(param[1].replace(/\+/g, " "));
    }
  }
  return params;
};

const removeEndingSlash = (str) => str.replace(/\/$/, "");

export const processComponent = (str) => {
  let component = str;
  const urlParams = getUrlParams(str);
  if (str.includes("?")) {
    component = str.split("?")[0];
  }
  return { component: removeEndingSlash(component), urlParams };
};

/*
 * {String} payload.eventName   Name of the event
 * {Number} payload.eventDate   Occurrence time of the event epoch time
 * {String} payload.component   Component name where the event occurred
 * {Object} payload.properties  Key value Map
 * {String} payload.groupId     A unique identifier to track a session, use window.csrf
 */
export const createTrackingEvent = (payload = {}) => {
  if (!payload.component) {
    payload.component = window.location.hash.replace("#/", "");
    if (payload.component === "") {
      return null;
    }
  }
  if (!payload.eventDate) {
    payload.eventDate = Math.floor(new Date().getTime() / 1000); // Unix timestamp is in seconds, so divide the ms by 1000
  }
  const { component, urlParams } = processComponent(payload.component);
  payload.component = component;
  payload.properties = Object.assign({}, payload.properties, urlParams);
  payload.groupId = EVENT_GROUP_ID;
  Services.UserEvent.createTrackingEvent(
    {
      eventName: payload.eventName,
      eventDate: payload.eventDate,
      component: payload.component,
      properties: payload.properties,
      groupId: payload.groupId,
    },
    (error, response) => {
      let errors = parseResponseErrors(error, response);
      if (errors) {
        analytics.sendEngineeringEvent(
          "Error",
          "`createTrackingEvent()` " + JSON.stringify(errors)
        );
      }
    }
  );
};

export const createViewTrackingEvent = (payload = {}) => {
  payload.eventName = "View";
  createTrackingEvent(payload);
};

export const createClickTrackingEvent = (payload = {}) => {
  payload.eventName = "Click";
  createTrackingEvent(payload);
};

export default createTrackingEvent;
