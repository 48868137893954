import PropTypes from "prop-types";
import React from "react";
import { isEmpty, first, last } from "underscore";
import ImportantDataPoints from "components/importantDataPoints/ImportantDataPoints";

const MIN_NUMBER_OF_DAYS_LONG_PERIOD = 90;

export default class ImportantDataPointsContainer extends React.Component {
  /**
   * Parses data to create a properly formatted list
   * @param {object} histories - history data to be parsed.
   * @returns {array} an array of objects to display
   */
  parseHistories(histories) {
    let SECOND_TO_LAST_INDEX_OFFSET = 2;

    const firstItem = first(histories);
    const secondToLast =
      histories.length === 1 && histories.length < SECOND_TO_LAST_INDEX_OFFSET
        ? first(histories)
        : histories[histories.length - SECOND_TO_LAST_INDEX_OFFSET];
    const lastItem = last(histories);

    let dataPoints = [];

    if (this.props.useActualTimeFrame) {
      dataPoints.push({
        name: `${histories.length}-days`,
        value: lastItem.aggregateBalance - firstItem.aggregateBalance,
      });
    } else if (histories.length >= MIN_NUMBER_OF_DAYS_LONG_PERIOD) {
      dataPoints.push({
        name: "90-days",
        value: lastItem.aggregateBalance - firstItem.aggregateBalance,
      });
    }
    if (histories.length) {
      dataPoints.push({
        name: "1-day",
        value: lastItem.aggregateBalance - secondToLast.aggregateBalance,
      });
    }
    return dataPoints;
  }

  render() {
    const { histories } = this.props;
    if (isEmpty(histories)) {
      return null;
    }

    const dataPoints = this.parseHistories(this.props.histories);
    if (dataPoints.length < 1) {
      return null;
    }

    return <ImportantDataPoints dataPoints={dataPoints} />;
  }
}

ImportantDataPointsContainer.propTypes = {
  histories: PropTypes.array,
  useActualTimeFrame: PropTypes.bool,
};

ImportantDataPointsContainer.defaultProps = {
  histories: [],
  useActualTimeFrame: false,
};
