/* eslint-disable react-hooks/rules-of-hooks */
import $ from "jquery";

import _ from "underscore";
import BaseDetailsView from "views/components/accountDetails/baseDetailsView";
import InvestmentTemplate2 from "templates/accountDetails/investment2.html";
import dollarAndCentsAmount from "templates/helpers/dollarAndCentsAmount";
import React from "react";
import ReactDOM from "react-dom";
import Recommendation401kContainer from "components/AccountDetails/Recommendation401k/Recommendation401kContainer";
import ClientRecommendation401kContainer from "components/AccountDetails/Recommendation401k/ClientRecommendation401kContainer";
import shouldShowRecommendationTab from "views/components/accountDetails/shouldShowRecommendationTab";
import "tooltip";
import mixpanel from "../../../libs/pcap/utils/mixpanel";

var MAX_LENGTH_FIRM_NAME = 35;
// For use with 4 tabs
var MAX_LENGTH_FIRM_NAME_SHORT = 25;
var MAX_LENGTH_NAME_SHORT = 30;
const MARGIN_RIGHT_LABELS_X = 34;

var useLiteHoldings = function (account, tabId) {
  return account && account.isPartner && tabId === "holdings";
};

var hideBalanceChart = function (isPartnerHoldings, tabId) {
  return !isPartnerHoldings && tabId !== "balance";
};

var hidePerformanceChart = function (isPartnerHoldings, tabId) {
  return isPartnerHoldings || tabId !== "holdings";
};

let getNewChartName = function (newChartName, isPartner) {
  if (newChartName === "holdings") {
    return isPartner ? "balanceChart" : "performanceChart";
  }
  if (newChartName === "summary") {
    return "balanceChart";
  }
  return newChartName + "Chart";
};

var InvestmentView = BaseDetailsView.extend(
  {
    initialize: function (options) {
      this.hasRecommendationTab = shouldShowRecommendationTab(
        this.model,
        options
      );
      BaseDetailsView.prototype.initialize.apply(this, arguments);
      this.getBalanceHistory(true);
      this.getCashflowsHistory(true);
      this.getPerformanceHistory(true);
    },
    render: function () {
      var data = this.model.toJSON();

      if (this.hasRecommendationTab) {
        data.cappedFirmName = this.capStringLength(
          data.firmName,
          MAX_LENGTH_FIRM_NAME_SHORT
        );
        data.cappedName = this.capStringLength(
          data.name,
          MAX_LENGTH_NAME_SHORT
        );
      } else {
        data.cappedFirmName = this.capStringLength(
          data.firmName,
          MAX_LENGTH_FIRM_NAME
        );
        data.cappedName = data.name;
      }

      data.tabId = this.options.tabId = this.options.tabId || "balance";
      data.canEdit =
        !data.isOnUs &&
        !data.isOnUs401K &&
        !data.isPartner &&
        !data.isOnUsRetirement;

      data.isLiteHoldings = useLiteHoldings(data, this.options.tabId);
      data.hideBalanceChart = hideBalanceChart(
        data.isLiteHoldings,
        this.options.tabId
      );
      data.hidePerformanceChart = hidePerformanceChart(
        data.isLiteHoldings,
        this.options.tabId
      );

      data.showRefetchTransaction = this.options.showRefetchTransaction;
      data.isRefetchTransactionEligible =
        this.options.isRefetchTransactionEligible;
      data.isEmpower = IS_EMPOWER;

      this.$el.html(InvestmentTemplate2(data));
      this.$(".js-recommendation-tab-container").toggleClass(
        "is-hidden",
        !this.hasRecommendationTab
      );
      this.$('[data-toggle="tooltip"]').tooltip();

      BaseDetailsView.prototype.render.call(this);

      if (data.tabId === "recommendation") {
        this.showRecommendation();
      } else if (
        !_.isUndefined(data.isOnUs401K) &&
        !_.isUndefined(data.userSiteId) &&
        data.isOnUs401K
      ) {
        // onus 401k partner link
        this.$el
          .find(".onUs401kpartnerLink")
          .attr("href", "/page/ltlogin/ltSsoGatekeeper?q=" + data.userSiteId)
          .show();
      } else {
        this.$el.find(".onUs401kpartnerLink").attr("href", "#").hide();
      }

      return this;
    },
    changeAccount: function (account) {
      this.hasRecommendationTab = shouldShowRecommendationTab(
        account,
        this.options
      );
      BaseDetailsView.prototype.changeAccount.call(this, account);

      this.getBalanceHistory();
      this.getCashflowsHistory();
      this.getPerformanceHistory();
    },
    updateContent: function () {
      BaseDetailsView.prototype.updateContent.call(this);
      var htmlText;

      // update balance
      htmlText = dollarAndCentsAmount(this.model.get("balance"), true);
      this.$el.find(".header > .balance").text(htmlText);

      // update priorBalance
      htmlText = dollarAndCentsAmount(this.model.get("priorBalance"), true);
      this.$el.find(".priorBalance.dataValue").text(htmlText);
    },
    updateView: function (newViewState) {
      BaseDetailsView.prototype.updateView.call(this, newViewState);

      if (newViewState.tabId === "recommendation") {
        this.showRecommendation();
      }
    },
    updateReopenedAccountDetails: function () {
      BaseDetailsView.prototype.updateReopenedAccountDetails.call(this);
      var htmlText;

      // update balance
      htmlText = dollarAndCentsAmount(this.model.get("balance"), true);
      this.$el.find(".header > .balance").text(htmlText);

      // update priorBalance
      htmlText = dollarAndCentsAmount(this.model.get("priorBalance"), true);
      this.$el.find(".priorBalance.dataValue").text(htmlText);
    },
    getHistory: function () {
      this.getBalanceHistory();
      this.getCashflowsHistory();
      this.getPerformanceHistory();
    },
    onBalanceFetched: function (err, response) {
      if (err == null) {
        BaseDetailsView.prototype.onBalanceFetched.call(this, err, response);

        // Render balance chart
        if (
          _.isUndefined(this.options.tabId) ||
          this.options.tabId === "balance" ||
          useLiteHoldings(this.model.attributes, this.options.tabId)
        ) {
          const balanceChartEl = this.$el.find("#balanceChart");
          const originalWidth = balanceChartEl.width();
          this.renderBalanceChart();
          if (balanceChartEl && originalWidth) {
            balanceChartEl.find("svg").css({ width: originalWidth });
            balanceChartEl.find(".startDate")?.css({ left: 0 });
            balanceChartEl
              .find(".endDate")
              ?.css({ "margin-right": MARGIN_RIGHT_LABELS_X });
          }
        }
      }
    },
    onCashFlowFetched: function (err, response) {
      if (err == null) {
        BaseDetailsView.prototype.onCashFlowFetched.call(this, err, response);

        // Render income chart
        if (this.options.tabId === "income") {
          this.renderIncomeChart();
        }
      }
    },
    onPerformanceFetched: function (err, response) {
      if (err == null) {
        BaseDetailsView.prototype.onPerformanceFetched.call(
          this,
          err,
          response
        );

        // Render performance chart
        if (
          this.options.tabId === "holdings" &&
          !useLiteHoldings(this.model.attributes, this.options.tabId)
        ) {
          this.renderPerformanceChart();
          window.dispatchEvent(new CustomEvent("pageloaded"));
        }
      }
    },

    displayChart: function (newChartName) {
      const isPartner =
        this.model && this.model.attributes && this.model.attributes.isPartner;

      this.$(".js-recommendation-shell").addClass("is-hidden");

      this.$(".visualization, .nav-secondary--feature-controls").removeClass(
        "is-hidden"
      );

      this.options.tabId = newChartName = newChartName || "balance";
      BaseDetailsView.prototype.displayChart.call(this, newChartName);
      this.defaultTabShown = this.options.tabId === "balance";

      var self = this;

      this.$(".js-account-details-tabs").each(function (i, el) {
        var tab = $(el);
        if (tab.hasClass(self.options.tabId + "Tab")) {
          tab.addClass("is-active");
        } else {
          tab.removeClass("is-active");
        }
      });

      newChartName = getNewChartName(newChartName);

      this.showTabSelected(newChartName);

      if (useLiteHoldings({ isPartner }, this.options.tabId)) {
        $(".nav-secondary--feature-controls", this.$el).addClass("hidden");
      } else {
        $(".nav-secondary--feature-controls", this.$el).removeClass("hidden");
      }

      this.getHistory();

      switch (this.options.tabId) {
        case "balance":
          mixpanel.trackEvent("View Account Details Investment Balance", {
            component: "investmentView",
          });
          break;
        case "holdings":
          mixpanel.trackEvent("View Account Details Investment Holdings", {
            component: "investmentView",
          });
          break;
        default:
          //income
          mixpanel.trackEvent("View Account Details Investment Income", {
            component: "investmentView",
          });
      }
      return false;
    },
    showRecommendation: function () {
      window.dispatchEvent(new CustomEvent("pageloaded"));
      this.$(".visualization, .nav-secondary--feature-controls").addClass(
        "is-hidden"
      );
      this.$(".js-account-details-tabs").removeClass("is-active");
      this.$(".js-account-details-tabs.recommendationTab").addClass(
        "is-active"
      );

      this.recommendationShell = this.$(".js-recommendation-shell").get(0);
      $(this.recommendationShell).removeClass("is-hidden");
      ReactDOM.render(
        this.options.isUserClient ? (
          <ClientRecommendation401kContainer
            userAccountId={parseInt(this.options.userAccountId, 10)}
          />
        ) : (
          <Recommendation401kContainer
            isInvestmentCheckupV2={this.options.isInvestmentCheckupV2}
            userAccountId={parseInt(this.options.userAccountId, 10)}
          />
        ),
        this.recommendationShell
      );
    },
    remove: function () {
      if (this.recommendationShell) {
        ReactDOM.unmountComponentAtNode(this.recommendationShell);
      }

      BaseDetailsView.prototype.remove.apply(this, arguments);
    },

    showTabSelected: function (newChartName) {
      const isPartner =
        this.model && this.model.attributes && this.model.attributes.isPartner;

      var self = this;

      $(".visualization", this.$el)
        .children()
        .each(function (i, el) {
          var chartElement = $(el);
          if (
            chartElement.attr("id") === newChartName &&
            chartElement.hasClass("hidden")
          ) {
            chartElement.removeClass("hidden");

            if (self.options.tabId === "balance") {
              self.renderBalanceChart();
            } else if (self.options.tabId === "income") {
              self.renderIncomeChart();
            } else if (self.options.tabId === "holdings") {
              if (isPartner) {
                self.renderBalanceChart();
              } else {
                self.renderPerformanceChart();
              }
            }
          } else if (
            chartElement.attr("id") !== newChartName &&
            !chartElement.hasClass("hidden")
          ) {
            chartElement.addClass("hidden");
          }
        });
    },
  },
  {
    useLiteHoldings,
    hideBalanceChart,
    hidePerformanceChart,
  }
);

export default InvestmentView;
