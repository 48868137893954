import React from "react";
import PropTypes from "prop-types";
import { pie, arc, scaleLinear, range, select } from "d3";

const RADIUS = 95;
const LABEL_SPACE = 30;
const TICK_SPACE = 8;
const THICKNESS = 16;
const MINIMUM_PIE_VALUE = 0;
const FULL_PIE_VALUE = 1;
const MULTIPLIER = 2;
const CHART_PADDING = 24;
const TICKS_RANGE = 360;
const TICK_HEIGHT = 6;
const TICK_LABEL_Y_OFFSET = 5;
const RADIANS = 0.0174532925;
const PI_DEGREES = 180;
const ARC_EDGE_CIRCLE_RADIUS = 2;
const SHIFT_ANGLE = Math.PI / 2;

export default class RingChart extends React.Component {
  constructor(props) {
    super(props);

    this.updateRingEdges = this.updateRingEdges.bind(this);
    this.pie = pie()
      .value((d) => d.value)
      .sort(null);

    this.currentArc = arc()
      .outerRadius(props.radius)
      .innerRadius(props.radius - props.thickness);
  }

  createRingsData(strongDialValue, lightDialValue, totalValue) {
    const {
      strongDialClassName,
      lightDialClassName,
      remainingDialClassName,
      exceededDialClassName,
    } = this.props;
    if (strongDialValue == null || totalValue == null) {
      return null;
    }

    const remaining = totalValue - Math.max(strongDialValue, lightDialValue);

    if (totalValue < strongDialValue) {
      return [
        {
          className: exceededDialClassName,
          value: FULL_PIE_VALUE,
        },
      ];
    }

    return [
      {
        className: strongDialClassName,
        value: Math.max(strongDialValue, MINIMUM_PIE_VALUE),
      },
      {
        className: lightDialClassName,
        value: Math.max(lightDialValue - strongDialValue, MINIMUM_PIE_VALUE),
      },
      {
        className: remainingDialClassName,
        value: Math.max(remaining, MINIMUM_PIE_VALUE),
      },
    ];
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  drawTicks(ticksContainer) {
    const { numberOfTicks, radius, tick, labelPadding } = this.props;
    let position, label;
    if (tick && tick.position !== null && tick.label) {
      position =
        tick.position >= numberOfTicks ? numberOfTicks - 1 : tick.position;
      label = tick.label;
    }

    const tickScale = scaleLinear()
      .range([0, TICKS_RANGE])
      .domain([0, numberOfTicks]);
    const tickStartRadius = radius + TICK_SPACE;
    const tickLabelRadius = radius + labelPadding;
    const ticks = ticksContainer
      .selectAll(".ring-chart__tick")
      .data(range(0, numberOfTicks, 1));
    ticks.exit().remove();
    ticks.call(this.updateTicks, position, tickStartRadius, tickScale);
    ticks
      .enter()
      .append("line")
      .call(this.updateTicks, position, tickStartRadius, tickScale);

    if (position !== null && label !== null) {
      // Draw tick label
      const tickLabel = ticksContainer
        .selectAll(".ring-chart__tick-label")
        .data(range(position, position + 1, 1));

      tickLabel.exit().remove();
      tickLabel.call(this.updateTickLabel, tickLabelRadius, tickScale, label);
      tickLabel
        .enter()
        .append("text")
        .classed("ring-chart__tick-label", true)
        .attr("text-anchor", "middle")
        .call(this.updateTickLabel, tickLabelRadius, tickScale, label);

      // Draw tick circle
      const tickCircle = ticksContainer
        .selectAll(".ring-chart__tick-circle")
        .data(range(position, position + 1, 1));

      tickCircle.exit().remove();
      tickCircle.call(this.updateTickCircle, tickStartRadius, tickScale);
      tickCircle
        .enter()
        .append("circle")
        .classed("ring-chart__tick-circle", true)
        .attr("r", ARC_EDGE_CIRCLE_RADIUS)
        .call(this.updateTickCircle, tickStartRadius, tickScale);
    }
  }

  addDefaultClasses(className) {
    return `js-ring-chart__path qa-${className} ${className}`;
  }

  // Order of the elements matters
  // to avoid overlapping of light corner circles with string corner circles
  reorderEdges(chart) {
    const { remainingDialClassName, lightDialClassName } = this.props;
    chart.selectAll(`.${lightDialClassName}`).lower();
    chart.selectAll(`.${remainingDialClassName}`).lower();
  }

  updateRingEdges(edge, angleModifier, classModifier) {
    const { radius, thickness, remainingDialClassName } = this.props;
    let radiusOfRoundEdge = thickness / 2;
    let centerOfTheArch = radius - thickness / 2;

    edge
      .attr("r", radiusOfRoundEdge)
      .attr(
        "class",
        (d) =>
          `${d.data.className} js-ring-chart__arc-${classModifier}-edge${
            d.data.className !== remainingDialClassName && d.data.value
              ? ""
              : " ring-chart__arc-edge--hidden"
          }`
      )
      .attr("cx", (d) => {
        return centerOfTheArch * Math.cos(d[angleModifier] - SHIFT_ANGLE);
      })
      .attr("cy", (d) => {
        return centerOfTheArch * Math.sin(d[angleModifier] - SHIFT_ANGLE);
      });
  }

  updateTicks(ticks, position, tickStartRadius, tickScale) {
    ticks
      .attr(
        "class",
        (d) =>
          `ring-chart__tick ring-chart__tick-${d}${
            d === position ? " ring-chart__tick--hidden" : ""
          }`
      )
      .attr("x1", 0)
      .attr("x2", 0)
      .attr("y1", tickStartRadius)
      .attr("y2", tickStartRadius - TICK_HEIGHT)
      .attr("transform", function (d) {
        return `rotate(${tickScale(d) + PI_DEGREES})`;
      });
  }

  updateTickLabel(tick, tickLabelRadius, tickScale, label) {
    tick
      .attr("x", function (d) {
        return tickLabelRadius * Math.sin(tickScale(d) * RADIANS);
      })
      .attr("y", function (d) {
        return (
          -tickLabelRadius * Math.cos(tickScale(d) * RADIANS) +
          TICK_LABEL_Y_OFFSET
        );
      })
      .text(label);
  }

  updateTickCircle(tickCircle, tickStartRadius, tickScale) {
    tickCircle
      .attr("cx", function (d) {
        return tickStartRadius * Math.sin(tickScale(d) * RADIANS);
      })
      .attr("cy", function (d) {
        return -tickStartRadius * Math.cos(tickScale(d) * RADIANS) + 0;
      });
  }

  renderEdges(chart, data) {
    const body = chart.data([this.pie(data)]);

    const startEdges = body
      .selectAll(".js-ring-chart__arc-start-edge")
      .data((d) => d);

    // exit
    startEdges.exit().remove();

    // update
    startEdges
      .attr("d", this.currentArc)
      .call(this.updateRingEdges, "startAngle", "start");

    // create
    startEdges
      .enter()
      .append("circle")
      .call(this.updateRingEdges, "startAngle", "start");

    const endEdges = body
      .selectAll(".js-ring-chart__arc-end-edge")
      .data((d) => d);

    endEdges.exit().remove();
    endEdges
      .attr("d", this.currentArc)
      .call(this.updateRingEdges, "endAngle", "end");
    endEdges
      .enter()
      .append("circle")
      .call(this.updateRingEdges, "endAngle", "end");

    this.reorderEdges(chart);
  }

  renderRings(chart, data) {
    if (data === null) {
      return;
    }
    const body = chart.data([this.pie(data)]);

    const arcs = body.selectAll(".js-ring-chart__path").data((d) => d);

    // exit
    arcs.exit().remove();

    // update
    arcs
      .attr("class", (d, i) => this.addDefaultClasses(data[i].className))
      .attr("d", this.currentArc);

    arcs.data(this.pie(data), function (d) {
      return d.data.id;
    });

    // enter
    arcs
      .enter()
      .append("path")
      .attr("class", (d, i) => this.addDefaultClasses(data[i].className))
      .attr("d", this.currentArc);

    this.renderEdges(chart, data);
  }

  renderChart() {
    const { totalValue, strongDialValue, lightDialValue, numberOfTicks } =
      this.props;

    const ringsData = this.createRingsData(
      strongDialValue,
      lightDialValue,
      totalValue
    );
    let chart = select(this.chart);

    if (numberOfTicks) {
      this.drawTicks(chart.select(".js-ring-chart__ticks"), this.currentArc);
    }

    this.renderRings(chart.select(".js-ring-chart"), ringsData);
  }

  render() {
    const {
      className,
      radius,
      numberOfTicks,
      chartPadding,
      labelPadding,
      ariaLabel,
    } = this.props;
    const labelRadius = radius + labelPadding;
    const radiusWithPadding = labelRadius + chartPadding;
    const translateXY = `translate(${radiusWithPadding},${radiusWithPadding})`;

    return (
      <svg
        width={radiusWithPadding * MULTIPLIER}
        height={radiusWithPadding * MULTIPLIER}
        className={className}
        aria-label={ariaLabel}
      >
        <g
          transform={translateXY}
          ref={(chart) => {
            this.chart = chart;
          }}
        >
          <g className="js-ring-chart" />
          {Boolean(numberOfTicks) && <g className="js-ring-chart__ticks" />}
        </g>
      </svg>
    );
  }
}

RingChart.propTypes = {
  totalValue: PropTypes.number.isRequired,
  strongDialValue: PropTypes.number.isRequired,
  lightDialValue: PropTypes.number.isRequired,
  numberOfTicks: PropTypes.number,
  tick: PropTypes.shape({
    position: PropTypes.number,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  strongDialClassName: PropTypes.string,
  lightDialClassName: PropTypes.string,
  remainingDialClassName: PropTypes.string,
  exceededDialClassName: PropTypes.string,
  className: PropTypes.string,
  radius: PropTypes.number,
  chartPadding: PropTypes.number,
  labelPadding: PropTypes.number,
  thickness: PropTypes.number,
  ariaLabel: PropTypes.string,
};

RingChart.defaultProps = {
  numberOfTicks: 0,
  tick: null,
  strongDialClassName: "ring-chart__strong-dial",
  lightDialClassName: "ring-chart__light-dial",
  remainingDialClassName: "ring-chart__remaining",
  exceededDialClassName: "ring-chart__strong-dial--exceeded",
  className: "ring-chart",
  radius: RADIUS,
  chartPadding: CHART_PADDING,
  labelPadding: LABEL_SPACE,
  thickness: THICKNESS,
  ariaLabel: "",
};
