import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import EditCategory from "components/modal/categoriesManager/EditCategory";
import RemoveCategory from "components/modal/categoriesManager/RemoveCategory";
import deepCopy from "deep-copy";

export default class EditCategoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleRemoveCategory = this.handleRemoveCategory.bind(this);
    this.handleShowRemove = this.handleShowRemove.bind(this);
    this.state = {
      isRemoving: false,
    };
  }

  handleEdit(category) {
    Services.TransactionCategories.update(
      category,
      function (err, updatedCategory) {
        if (updatedCategory.spHeader.errors) {
          this.props.onFail(err, updatedCategory);
        } else {
          updatedCategory.spData.isEdited = true;
          this.props.onSuccess(updatedCategory.spData);
        }
      }.bind(this)
    );
  }

  handleShowRemove() {
    this.setState({
      isRemoving: true,
    });
  }

  handleRemoveCategory() {
    Services.TransactionCategories.remove(
      { transactionCategoryId: this.props.category.transactionCategoryId },
      function (err, req) {
        if (!req.spHeader.errors && req.spHeader.success) {
          let removedCategory = deepCopy(this.props.category);
          removedCategory.isRemoved = true;
          this.props.onSuccess(removedCategory);
        } else {
          this.props.onFail(err, req);
        }
      }.bind(this)
    );
  }

  render() {
    if (this.state.isRemoving) {
      return (
        <RemoveCategory
          onCancel={this.props.onCancel}
          onRemoveCategory={this.handleRemoveCategory}
          errors={this.props.errors}
        />
      );
    }

    return (
      <EditCategory
        errors={this.props.errors}
        onCancel={this.props.onCancel}
        onEdit={this.handleEdit}
        onShowRemove={this.handleShowRemove}
        category={this.props.category}
      />
    );
  }
}

EditCategoryContainer.propTypes = {
  errors: PropTypes.array,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  category: PropTypes.object,
};
