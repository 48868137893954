// getCashFlow.js 0.0.1
// used to generate cashflows based on a set of transactions
// ravi gundlapalli

(function () {
  window.PCAP = window.PCAP || {};
  window.PCAP.NativeEventEmitter = {};
  window.PCAP.NativeEventEmitter.dispatchMouseEvent = function (
    object,
    eventName,
    bubble,
    cancelable
  ) {
    var eventObject;

    bubble = bubble ? bubble : true;
    cancelable = cancelable ? cancelable : false;

    if (document.createEvent) {
      eventObject = document.createEvent("MouseEvents");
      eventObject.initEvent(eventName, bubble, cancelable);
      object.dispatchEvent(eventObject);
    } else if (document.createEventObject) {
      eventObject = document.createEventObject();
      object.fireEvent("on" + eventName, eventObject);
    } else {
      throw new Error("unrecognized event model");
    }
  };
}.call(this));
