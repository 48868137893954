export const PLAN_LIST_SELECTION_TYPE_INITIAL = "INITIAL";
export const PLAN_LIST_SELECTION_TYPE_MASTER = "MASTER";
export const PLAN_LIST_SELECTION_TYPE_BY_ID = "BY_ID";
export const PLAN_LIST_SELECTION_TYPE_LAST = "LAST";

export const MAX_PLAN_COUNT = 25;
export const MIN_PLAN_COUNT_TO_COMPARE = 2;

export const VIEW_PLAN_LIST = "VIEW_PLAN_LIST";
export const COMPARE_PLAN_LIST = "COMPARE_PLAN_LIST";
