import PropTypes from "prop-types";
import React from "react";
import { formatCurrency } from "libs/pcap/utils/format";

function renderList(contributions) {
  return contributions.map((contribution) => {
    return (
      <li
        key={contribution.accountName + contribution.id}
        className="pc-layout__item projection-summary-contributions__contribution"
      >
        <div>{contribution.accountName}</div>
        <div>
          <strong>
            {formatCurrency(Math.abs(contribution.amount), 0) +
              " " +
              contribution.frequency}
          </strong>
        </div>
      </li>
    );
  });
}

const ProjectionSummaryAccountContributionsView = ({
  allContributions,
  allWithdrawals,
  onClose,
}) => {
  const contributionsList = renderList(allContributions);
  const withdrawalsList = renderList(allWithdrawals);

  return (
    <div>
      {allContributions.length > 0 && (
        <div>
          <div className="projection-summary-contributions__header">
            Contributions
          </div>
          <ul className="pc-layout">{contributionsList}</ul>
        </div>
      )}
      {allWithdrawals.length > 0 && (
        <div>
          <div className="projection-summary-contributions__header">
            Withdrawals
          </div>
          <ul className="pc-layout">{withdrawalsList}</ul>
        </div>
      )}
      <button
        type="button"
        className="pc-btn pc-btn--primary"
        onClick={onClose}
      >
        Done
      </button>
    </div>
  );
};

ProjectionSummaryAccountContributionsView.propTypes = {
  onClose: PropTypes.func,
  allContributions: PropTypes.array,
  allWithdrawals: PropTypes.array,
};

export default ProjectionSummaryAccountContributionsView;
