import PropTypes from "prop-types";
import React from "react";

const SingleStepBlock = ({ step, title, children, className } = {}) => {
  return (
    <fieldset className={`fieldset-step-block ${className || ""}`}>
      <legend className="fieldset-step-block__legend">
        <div className="fieldset-step-block__step">{step}</div>
        <div className="fieldset-step-block__title">{title}</div>
      </legend>
      <p className="fieldset-step-block__text">{children}</p>
    </fieldset>
  );
};

SingleStepBlock.propTypes = {
  step: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default SingleStepBlock;
