import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import Backbone from "backbone";
import template from "templates/modules/dashboard/dashboardNew.html";
import DashboardContainer from "components/dashboard/DashboardContainer";

export default Backbone.View.extend({
  el: "#dashboard",

  initialize(options) {
    this.useUnifiedMar = options.useUnifiedMar;
    this.firstTimeForecastExperience = options.firstTimeForecastExperience;
    this.gettingStartedOverviewData = options.gettingStartedOverviewData;
    this.render();
  },

  remove() {
    ReactDOM.unmountComponentAtNode(this.dashboardContainer);
    Backbone.View.prototype.remove.apply(this, arguments);
  },

  render() {
    this.$el.html(template());
    this.dashboardContainer = $("#dashboardWrapper").get(0);

    ReactDOM.render(
      React.createElement(DashboardContainer, {
        useUnifiedMar: this.useUnifiedMar,
        firstTimeForecastExperience: this.firstTimeForecastExperience,
        gettingStartedOverviewData: this.gettingStartedOverviewData,
      }),
      this.dashboardContainer
    );
    return this;
  },
});
