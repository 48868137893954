import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "libs/pcap/utils/format";
import Message from "../../common/Message/Message";
import { motion, AnimatePresence } from "framer-motion";

const RequiredMinimumDistribution = (props) => {
  const { loading, outstandingRmd, handleModalShow } = props;
  const messageBannerCopy = `Please make sure to take your full RMD by December 31st. Consult your tax professional if you have additional questions.`;

  if (loading) {
    return <p>Loading your estimated required minimum distribution (RMD)...</p>;
  }

  // Preference of AnimatePresence over previous usage of LazyMotion
  // for elements that are removed from DOM instead of just hiding
  return (
    <AnimatePresence>
      <motion.div
        className="required-minimum-distribution__mask"
        initial={{ height: 50 }}
        animate={{ height: "auto" }}
        exit={{ height: 50 }}
      >
        <span className="required-minimum-distribution__estimated-value pc-u-mb--">
          {formatCurrency(outstandingRmd)} is your{" "}
          <button
            className="required-minimum-distribution__disclaimer-button"
            onClick={handleModalShow}
          >
            estimated required minimum distribution (RMD)
          </button>
        </span>
        <Message
          listClassName="required-minimum-distribution__message-banner"
          messages={[messageBannerCopy]}
          severity="warning"
        />
      </motion.div>
    </AnimatePresence>
  );
};

RequiredMinimumDistribution.propTypes = {
  loading: PropTypes.bool,
  outstandingRmd: PropTypes.string,
  handleModalShow: PropTypes.func,
};

RequiredMinimumDistribution.defaultProps = {
  loading: false,
  outstandingRmd: "",
  handleModalShow: () => null,
};

export default RequiredMinimumDistribution;
