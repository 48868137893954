/* eslint-disable camelcase */

import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import { defer } from "underscore";
import BasicContentInterjection from "./BasicContentInterjection";
import {
  getCurrentFeatureName,
  getNamespace,
  removeInterjectionFromActiveQueue,
  addHandledInterjectionUserMessageId,
} from "../helpers";
import { trackClick, trackView } from "components/common/ComponentAnalytics";
import markUserMessageAsViewed from "libs/pcap/utils/markUserMessageAsViewed";

const MODAL_WIDTH = 800;

export default class BasicContentInterjectionModal extends React.Component {
  constructor({ interjection }) {
    super(...arguments);
    this.state = {
      showModal: true,
      componentName: "Interjection Modal - " + interjection.template,
    };
    this.onActionClick = this.onActionClick.bind(this);
    const currentFeatureName = getCurrentFeatureName(
      interjection.displayLocations
    );
    this.modalTitle = interjection.title;
    this.interjectionMixpanelEventData = {
      message_template: interjection.template,
      view_template: interjection.viewTemplate,
      feature_name: currentFeatureName,
    };
    trackView(this.state.componentName, this.interjectionMixpanelEventData);
  }

  componentDidMount() {
    defer(() => {
      markUserMessageAsViewed(this.props.interjection.userMessageId);
    });
  }

  onActionClick(actionTitle) {
    const { interjection, onModalClosed } = this.props;
    trackClick(
      null,
      this.state.componentName,
      `${actionTitle} Action Button`,
      this.interjectionMixpanelEventData
    );

    removeInterjectionFromActiveQueue(getNamespace(interjection));
    addHandledInterjectionUserMessageId(interjection.userMessageId);

    this.setState({ showModal: false }, () => {
      onModalClosed();
    });
  }

  render() {
    const { interjection } = this.props;
    return (
      <Modal
        componentName={this.state.componentName}
        title={this.modalTitle}
        onClosed={this.onActionClick.bind(this, "Close Modal")}
        // react-modal APIs
        contentLabel={this.state.componentName}
        isOpen={this.state.showModal}
        style={{ content: { width: MODAL_WIDTH } }}
        shouldCloseOnOverlayClick={false}
      >
        <BasicContentInterjection
          data={interjection}
          onActionClick={this.onActionClick}
        />
      </Modal>
    );
  }
}

BasicContentInterjectionModal.propTypes = {
  onModalClosed: PropTypes.func.isRequired,
  interjection: PropTypes.object.isRequired,
};
