var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-toggle='account-block-tooltip' title='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "'";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='notificationIcon expand sticky sidebar-account__status-icon sidebar-account__status-icon--info qa-sidebar-account-status-icon-info' data-toggle='tooltip' title='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "'></div>";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <div class='notificationIcon expand sticky'>\n                <div class=\"loader--dark loader__container--small loader__container u-full-center--abs\">\n                  <div class=\"loader__circle loader__circle--small loader__full-circle loader__full-circle--small\"></div>\n                  <div class=\"loader__circle loader__circle--small loader__spinning-circle loader__spinning-circle--small qa-loader-small\"></div>\n                </div>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"sidebar-account__value qa-sidebar-account-value js-empower-account-value\">\n          "
    + container.escapeExpression(__default(require("../../helpers/dollarAndCentsAmount.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"balance") : depth0),true,true,(depth0 != null ? lookupProperty(depth0,"isLiability") : depth0),false,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":26,"column":10},"end":{"line":26,"column":70}}}))
    + "\n        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"edit u-full-center--abs qa-sidebar-account-edit\">\n          <svg class=\"sidebar-account__edit-icon\" viewBox=\"0 0 9 9\" role=\"button\" title=\"Edit account\" alt=\"Edit account\">\n            <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon-pencil\"></use>\n          </svg>\n        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class='sidebar-account__detail-name qa-sidebar-account-detail-name' title='"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"originalName") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":44,"column":90},"end":{"line":44,"column":149}}})) != null ? stack1 : "")
    + "' data-hj-masked>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"originalName") : depth0), depth0));
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0));
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class='sidebar-account__status-message' title='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0))
    + "'>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0))
    + "</div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"empowerData") : depth0)) != null ? lookupProperty(stack1,"enrollmentInfo") : stack1)) != null ? lookupProperty(stack1,"nqEnrollmentDate") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":10},"end":{"line":75,"column":17}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"empowerData") : depth0)) != null ? lookupProperty(stack1,"enrollmentInfo") : stack1)) != null ? lookupProperty(stack1,"showEnrollmentDate") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":74,"column":19}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isNewHire") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":52,"column":14},"end":{"line":73,"column":21}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"sidebar-account__enrollment-info-new-hire\">\n                  <div class=\"expand sticky NONE sidebar-account__enroll-now-icon-container-new-hire \">\n                      <svg viewBox=\"0 0 48 41\">\n                        <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon__account-enroll-now\"></use>\n                      </svg>\n                  </div>\n                  <span class=\"sidebar-account__enroll-now-label-new-hire\" >Until 30 days after hire date</span>\n                </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"sidebar-account__enrollment-info\">\n                  <div class=\"expand sticky NONE sidebar-account__enroll-now-icon-container\">\n                      <svg viewBox=\"0 0 48 41\">\n                        <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon__account-enroll-now\"></use>\n                      </svg>\n                  </div>\n                  <span>Enrollment ends</span>\n                  <div class=\"sidebar-account__enrollment-end-date\">\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"empowerData") : depth0)) != null ? lookupProperty(stack1,"enrollmentInfo") : stack1)) != null ? lookupProperty(stack1,"nqEnrollmentDate") : stack1), depth0))
    + "\n                  </div>\n                </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "            <div class='notificationIcon expand sticky NONE sidebar-account__refresh'>\n              <svg class=\"\" viewBox=\"0 0 10 10\">\n                <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon-refresh\"></use>\n              </svg>\n            </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"action") : stack1),"!=","WAIT",{"name":"compare","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":12},"end":{"line":97,"column":24}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    return "              <div class='notificationIcon expand sticky NONE sidebar-account__refresh'>\n                <svg class=\"\" viewBox=\"0 0 10 10\">\n                  <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon-refresh\"></use>\n                </svg>\n              </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(__default(require("../../helpers/timestamp.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0),{"name":"timestamp","hash":{},"data":data,"loc":{"start":{"line":100,"column":12},"end":{"line":100,"column":39}}}))
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"empowerData") : depth0)) != null ? lookupProperty(stack1,"enrollmentInfo") : stack1)) != null ? lookupProperty(stack1,"isEligible") : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":8},"end":{"line":111,"column":15}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"empowerData") : depth0)) != null ? lookupProperty(stack1,"enrollmentInfo") : stack1)) != null ? lookupProperty(stack1,"showEnrollmentDate") : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":9},"end":{"line":110,"column":17}}})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    return "          <button class=\"pc-btn pc-btn--primary sidebar-account__enroll-now-button pc-btn--tiny js-enroll-now-button\">\n            Enroll now\n          </button>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"empowerData") : depth0)) != null ? lookupProperty(stack1,"enrollmentInfo") : stack1)) != null ? lookupProperty(stack1,"isSecondary") : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":8},"end":{"line":118,"column":8}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"empowerData") : depth0)) != null ? lookupProperty(stack1,"enrollmentInfo") : stack1)) != null ? lookupProperty(stack1,"isEligible") : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":11},"end":{"line":117,"column":18}}})) != null ? stack1 : "")
    + "        ";
},"38":function(container,depth0,helpers,partials,data) {
    return "           <button class=\"pc-btn sidebar-account__enroll-now-button sidebar-account__enroll-now-button--secondary pc-btn--tiny js-enroll-now-button\">\n             Enroll now\n           </button>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "        <a href=\"#\" class=\"pc-btn pc-btn--primary sidebar-account__rollover-now-button pc-btn--tiny js-rollover-button\">\n          Withdrawals &amp; rollovers\n        </a>\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"sidebar-account__progress-bar progressBar\">\n        <span class=\"progressMessage micro\">Establishing secure connection...</span>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"containerClassName") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","ERROR",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":169}}})) != null ? stack1 : "")
    + ">\n    <div class=\"sidebar-account__row\">\n      <div class=\"js-edit-account-container\"></div>\n      <div class=\"sidebar-account__first-col\">\n          "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","INFO",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":279}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","AGGREGATING",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":13,"column":22}}})) != null ? stack1 : "")
    + "      </div>\n\n      <div class=\"sidebar-account__second-col\">\n\n        <div class=\"sidebar-account__title js-sidebar-account-title qa-sidebar-account-title\">\n          "
    + alias2(__default(require("../../helpers/detailsLink2.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"firmName") : depth0),{"name":"detailsLink2","hash":{},"data":data,"loc":{"start":{"line":19,"column":10},"end":{"line":19,"column":35}}}))
    + "\n        </div>\n      </div>\n\n      <div class=\"sidebar-account__third-col\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"empowerAttributes") : depth0)) != null ? lookupProperty(stack1,"isPensionIncome") : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n\n    <div class=\"sidebar-account__row\">\n      <div class=\"sidebar-account__first-col\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isAccountEditable") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":40,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"sidebar-account__second-col sidebar-account__second-col--enroll pc-u-mb--\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":47,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"empowerData") : depth0)) != null ? lookupProperty(stack1,"enrollmentInfo") : stack1)) != null ? lookupProperty(stack1,"isNQ") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":76,"column":15}}})) != null ? stack1 : "")
    + "        \n      </div>\n      <div class=\"sidebar-account__third-col \">\n        <div class=\"sidebar-account__time js-sidebar-account__time\">\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","NONE",{"name":"compare","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":10},"end":{"line":87,"column":22}}})) != null ? stack1 : "")
    + "          <!--Since the server is not returning iconType for onUS, we have to verify manually. Also, we have to verify\n          that the account action is not \"WAIT\" so we don't show the refresh button when aggregating. This will be solved in PFM-2016 -->\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==",undefined,{"name":"compare","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":10},"end":{"line":98,"column":22}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":10},"end":{"line":101,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"empowerData") : depth0)) != null ? lookupProperty(stack1,"enrollmentInfo") : stack1)) != null ? lookupProperty(stack1,"isNQ") : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":104,"column":8},"end":{"line":118,"column":15}}})) != null ? stack1 : "")
    + "        \n      </div>\n    </div>\n\n    <div class=\"sidebar-account__row l-spaced--center\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showRollOverCTA") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":6},"end":{"line":128,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.noop,"inverse":container.program(42, data, 0),"data":data,"loc":{"start":{"line":131,"column":4},"end":{"line":135,"column":22}}})) != null ? stack1 : "")
    + "  </div>\n";
},"useData":true});