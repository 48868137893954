import _ from "underscore";
import clientOffset from "libs/pcap/utils/clientOffset";
import $ from "jquery";
import strangePoint from "libs/pcap/utils/strangePoint";
import Backbone from "backbone";
$.fn.hitTest = function (x, y) {
  var bounds = this.offset();
  bounds.right = bounds.left + this.outerWidth();
  bounds.bottom = bounds.top + this.outerHeight();
  return (
    x >= bounds.left &&
    x <= bounds.right &&
    y <= bounds.bottom &&
    y >= bounds.top
  );
};

/**
 * OverlayView is a view that does the general flyover pattern for mousing over bar/line graphs.
 * It should be applied to the div object that contains the graph - generally a < class="visualization" /> div.
 *
 * It is generalized in that there are no assumptions on the underlying data.
 *
 * The OverlayView looks for or creates the following dom patterns (not necessarily in this order):
 *
 * < class="tips">
 *     < class="tip-header" />
 *     < class="line" />
 *     < class="tipContent />
 *     < class="tipFooter" />
 * </ >
 *
 * Each of these can be turned on and off (initially) using the flags "showTipHeader", "showTipFooter", "showTipContent".
 *
 * The general pattern here is each of the three items are filled with content from a template,
 * which processes a data function. Both the templates and the data sources are functions, so in reality,
 * the effect is
 *
 * '$('.tips .tip-header').html(
 *    this.headerTemplate(
 *       this.headerTemplateData(percent)
 *    )
 *  );
 *
 *  with percent being a value 0..1 indicating the relative x-position of the mouse.
 *
 * and so on for content and footer.
 *
 * Through closure, these functions (usually just the data function) can return content based on the data source
 * for the graph.
 *
 * @type {string}
 * @private
 */

var _headerHTML =
  '<div class="defaultSeriesTip tip-header staticCenter"></div>';
var _lineHTML = '<div class="line"></div>';
var _footerHTML = '<div class="date tipFooter staticCenter"></div>';
var _contentHTML = '<div class="tipContent"></div>';
var _overlayHTML = '<div class="tipOverlay"></div>';

function _do(value, base, percent) {
  if (!value) {
    return false;
  } else if (_.isFunction(value)) {
    return value.call(base, percent);
  }
  return value;
}

export default Backbone.View.extend({
  events: {
    "mouseenter .tipOverlay": "initFlyover",
    "mouseleave .tipOverlay": "endFlyover",
    "mousemove  .tipOverlay": "updateFlyover",
  },

  showTipHeader: true,
  showTipFooter: true,
  showTipContent: true,

  clampLineX: function (x) {
    var border = this.options.hasOwnProperty("xBorder")
      ? this.options.xBorder
      : 50;
    return Math.max(border, Math.min(this.$el.outerWidth() - border, x));
  },

  clampY: function (u, border, border2) {
    border != 0;
    if (arguments.length < 3) {
      border2 = border;
    }

    var bottom = this.$el.outerHeight() - border2;
    return Math.max(border, Math.min(bottom, u));
  },

  initTips: function () {
    if (!this.$el.find(".tips").length) {
      this.$el.append('<div class="tips"></div> ');
    }
  },

  showElement: function (selector) {
    this.$el
      .find(selector)
      .css({ opacity: 0 })
      .show()
      .animate({ opacity: 1 }, 200);
  },

  /* ------------------ OVERLAY ---------------------- */

  initOverlay: function () {
    if (!this.$el.find(".tipOverlay").length) {
      this.$el.append(_overlayHTML);
    }
  },

  /* ------------------ FLYOVER ---------------------- */

  initFlyover: function () {
    this._overState = "over";
    this.$el.find(".tips").show();
    this.showElement(".tips .line");
    if (this.showTipHeader) this.showElement(".tips .tip-header");
    if (this.showTipFooter) this.showElement(".tips .tipFooter");
    if (this.showTipContent) this.showElement(".tips .tipContent");
    this.trigger("initTips");
  },

  IEmouseMove: function (e) {
    switch (this._overState) {
      case "over":
        if (!this.$el.find(".tipOverlay").hitTest(e.pageX, e.pageY)) {
          this._endFlyover();
        } else {
          //	console.log('delegating IEmm to updateFlyover');
          //	console.log('--- from document --');
          this.updateFlyover(e);
          return true;
        }
        break;

      case "out":
      default:
        if (this.$el.find(".tipOverlay").hitTest(e.pageX, e.pageY)) {
          this.initFlyover();
        }
    }
  },

  endFlyover: function (e) {
    this._endFlyover();
  },

  _endFlyover: function () {
    this.$el.find(".tips").hide();
    this.trigger("hideTips");
    this._overState = "out";
  },

  strangePoint: strangePoint(),

  updateFlyover: function (e) {
    this._overlayState = "over";
    var offset = clientOffset(e, this.$el);

    // copying the value from absolute offset to offset to minimize code changes
    offset.offsetX = offset.pageOffsetX;
    offset.offsetY = offset.pageOffsetY;

    //	console.log('update flyover: x =', offset.offsetX, '  y = ', offset.offsetY, JSON.stringify(offset));
    var percent = offset.offsetX / this.width;

    var point = {
      x: offset.offsetX,
      y: offset.offsetY,
      time: new Date().getTime(),
    };

    if (this.strangePoint(point)) {
      //	console.log('strange catch boom!');
      return;
    }

    if (this.showTipHeader) this.updateHeader(percent);
    if (this.showTipFooter) this.updateFooter(percent);
    if (this.showTipContent) this.updateContent(percent);
    this.trigger("showTips", offset);

    var self = this;
    var halfLength = this.width / 2;

    self.$el
      .find(".tips div.line")
      .css({ left: this.clampLineX(offset.offsetX) }); //  - $(this).outerWidth()

    if (offset.offsetX > halfLength) {
      // right half of the graph

      self.$el.find(".tips > div.staticCenter").each(function () {
        if (offset.offsetX > halfLength + $(this).outerWidth() / 2) {
          $(this).css({ left: offset.offsetX - $(this).outerWidth() });
        } else {
          $(this).css({ left: halfLength - $(this).outerWidth() / 2 });
        }
      });

      if (this.showTipContent) {
        self.$el.find(".tips .tipContent").each(function () {
          $(this).addClass("right");
          $(this).removeClass("left");
          var config = {
            left: offset.offsetX - ($(this).width() + 25),
            top: self.clampY(offset.offsetY, 25, $(this).height() + 15),
          };
          $(this).css(config);
        });
      }
    } else {
      // left half of the graph

      self.$el.find(".tips > div.staticCenter").each(function () {
        if (offset.offsetX < halfLength - $(this).outerWidth() / 2) {
          $(this).css({ left: offset.offsetX });
        } else {
          $(this).css({ left: halfLength - $(this).outerWidth() / 2 });
        }
      });

      if (this.showTipContent) {
        self.$el.find(".tips .tipContent").each(function () {
          $(this).addClass("left");
          $(this).removeClass("right");
          var config = {
            left: offset.offsetX + 5,
            top: self.clampY(offset.offsetY, 25, $(this).height() + 15),
          };
          $(this).css(config);
        });
      }
    }

    this.trigger("updateFlyover", offset, e);
  },

  /* ------------------ HEADER ---------------------- */

  initHeader: function () {
    if (!this.$el.find(".tips .defaultSeriesTip").length) {
      this.$el.find(".tips").append(_headerHTML);
    }
  },

  updateHeader: function (percent) {
    var data = _do(this.headerTemplateData, this, percent) || this,
      template = this.headerTemplate || _.identity;

    this.$el.find(".tips .tip-header").html(template(data));
  },

  /* ------------------ LINE ---------------------- */

  getLine: function () {
    return this.$el.find(".tips .line");
  },

  initLine: function () {
    if (!this.$el.find(".tips .line").length) {
      this.$el.find(".tips").append(_lineHTML);
    }

    this.$el.find(".tips .line").css({ height: this.$el.outerHeight() });
  },

  /* ------------------ FOOTER ---------------------- */

  initFooter: function () {
    if (!this.$el.find(".tips .tipFooter").length) {
      this.$el.find(".tips").append(_footerHTML);
    }
    var height = this.$el.outerHeight();

    this.$el.find(".tips .tipFooter").each(function () {
      var self = $(this);
      self.css({ bottom: -height });
    });
  },

  updateFooter: function (percent) {
    if (!this.footerTemplate) {
      return;
    }

    var data = _do(this.footerTemplateData, this, percent) || this,
      template = this.footerTemplate || _.identity;

    this.$el.find(".tips .tipFooter").html(template(data));
  },

  /* ------------------ CONTENT ---------------------- */

  initContent: function () {
    if (!this.$el.find(".tips > .tipContent").length) {
      this.$el.find(".tips").append(_contentHTML);
    } else {
      console.log("have content");
    }
  },

  updateContent: function (percent) {
    if (!this.contentTemplate) {
      return;
    }

    var data = _do(this.contentTemplateData, this, percent) || this,
      template = this.contentTemplate || _.identity;

    this.$el.find(".tips .tipContent").html(template(data));
  },

  /* ------------------ CLOSE ---------------------- */

  onClose: function () {
    if (this.boundIE) {
      $(document).off("mousemove", this.boundIE);
    }
  },
  /* ------------------ INITIALIZE ---------------------- */

  initialize: function (options) {
    this.options = options;
    this.width = this.$el.outerWidth();

    _.each(
      [
        "headerTemplate",
        "footerTemplate",
        "contentTemplate",
        "showContent",

        "showTipHeader",
        "showTipFooter",
        "showTipContent",

        "headerTemplateData",
        "contentTemplateData",
        "footerTemplateData",
      ],
      function (prop) {
        if (options.hasOwnProperty(prop)) {
          this[prop] = options[prop];
        }
      },
      this
    );

    this.points = [];
    this.initOverlay();
    this.initTips();
    this.initLine();
    if (this.showTipHeader) this.initHeader();
    if (this.showTipFooter) this.initFooter();
    if (this.showTipContent) this.initContent();

    this.endFlyover();
  },
});

function xxxx() {
  /*		function(view){

	 this.$el.unbind()
	 .mouseenter(function(){
	 self.$el.find('.tips > div:not(.focusedSeries)').show();
	 if(self.focused){
	 self.$el.find('.tips > div.focusedSeries').show();
	 }
	 _.each(self.chart.yLabels, function(el){
	 if(el.hiddenByDefault) el.animate({opacity: 1}, 200);
	 });
	 })
	 .mouseleave(function(e){
	 self.$el.find('.tips > div').hide();
	 _.each(self.chart.yLabels, function(el){
	 if(el.hiddenByDefault) el.animate({opacity: 0}, 200);
	 });
	 })

	//}
mousemove(function (e) {
	e.offsetX = (e.offsetX) ? e.offsetX : e.clientX - $('#portfolioPerformanceChart').offset().left;
	e.offsetY = (e.offsetY) ? e.offsetY : e.clientY - $('#portfolioPerformanceChart').offset().top;
	if (e.offsetY <= 60) {
		e.offsetY = 60;
	}
	self.$el.find('.tips .focusedSeries').css({top: e.offsetY + 10});
	self.$el.find('.tips .annotation').css({top: e.offsetY + 40});

	var percent = this.

	var hoverIndex = getHoverIndex(e.offsetX);
	self.$el.find('.tips .balances strong').html(self.histories[hoverIndex].aggregatePerformance + '%');
	self.$el.find('.tips .date').html($.pcap.dateFormatter(self.histories[hoverIndex].date));
	if (self.focused) {
		self.$el.find('.tips .focusedSeries label').html(self.series[self.focusedId][hoverIndex] + '%');
	}

	if (self.histories[hoverIndex][self.focusedId + 'Annotation'] != undefined) {
		self.$el.find('.tips .annotation').show();
	} else {
		self.$el.find('.tips .annotation').hide();
	}

	if (e.offsetX > (chartLength / 2)) {
		self.$el.find('.tips > div.line').each(function () {
			$(this).css({'left': e.offsetX - $(this).outerWidth()});
		});
		self.$el.find('.tips > div.staticCenter').each(function () {
			if (e.offsetX > (chartLength / 2) + ($(this).outerWidth() / 2)) {
				$(this).css({left: e.offsetX - $(this).outerWidth()});
			} else {
				$(this).css({left: (chartLength / 2) - ($(this).outerWidth() / 2)});
			}
		})
		self.$el.find('.tips .focusedSeries').css({'left': e.offsetX - self.$el.find('.tips .focusedSeries').outerWidth() - 20});
		self.$el.find('.tips .annotation').css({'left': e.offsetX - self.$el.find('.tips .annotation').outerWidth()})
	} else {
		self.$el.find('.tips > div:not(.staticCenter)').css({left: e.offsetX});
		self.$el.find('.tips > div.staticCenter').each(function () {
			if (e.offsetX < (chartLength / 2) - ($(this).outerWidth() / 2)) {
				$(this).css({left: e.offsetX});
			} else {
				$(this).css({left: (chartLength / 2) - ($(this).outerWidth() / 2)});
			}
		})
	}
}*/
}
