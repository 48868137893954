/* eslint-disable sonarjs/no-duplicate-string */
import * as customEvents from "libs/pcap/utils/customEvents";
import eventBus from "eventBus";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import moment from "moment";
import accounting from "accounting";
import Services from "services";
import PERSONALCAPITAL from "PERSONALCAPITAL";
import AccountsCollection from "collections/accounts";
import DateRangeSelector2 from "views/components/dateRangeSelectorView2";
import DateUtils from "libs/pcap/utils/date";
import Raphael from "raphael";
import * as COLORS from "libs/pcap/utils/colors";
import React from "react";
import ReactDOM from "react-dom";
import UnmountWithTimeout from "components/higherOrderComponents/unmountWithTimeout";
import Snackbar from "components/common/Snackbar";
import parseResponseErrors from "libs/pcap/utils/response";
import EditAccountContainer from "components/common/EditAccount/EditAccountContainer";
import IframeClient from "partner/iframeClient";
import { getAccountDetailsChartColors } from "libs/pcap/utils/productColors";
import { isEmpowerPrivilegedMode } from "../../modules/sidebar/utils/accountUtils";
const MONTHS_YEAR = 12;
const ANIMATION_DURATION = 200;
const KEYCODE_LEFT_ARROW = 37;
const KEYCODE_RIGHT_ARROW = 39;
const KEYCODE_DELETE = 46;
const KEYCODE_ZERO = 48;
const KEYCODE_NINE = 57;
const MAX_LENGTH_FIRM_NAME = 25;
const MAX_LENGTH_NAME = 40;
const SNACKBAR_TIMEOUT = 5000;
const DEFAULT_DAY_RANGE = 90;
const WIDTH_SIZE_OUTSIDE = 0.96;
const LABELS_MARGIN_RIGHT_OUTSIDE = 2;
const TRANSACTIONS_DISABLED_TOOLTIP =
  "Transaction refresh can be done once a week at most.";

const SnackbarWithTimeout = UnmountWithTimeout(Snackbar, SNACKBAR_TIMEOUT);
let iframeClientInstance = IframeClient.getInstance();
const isPrivileged = isEmpowerPrivilegedMode();

var BaseDetailsView = Backbone.View.extend({
  events: {
    "click .js-edit-account-button": "editAccount",
    "click .js-button-refetch-transactions": "refetchTransactions",
    "click .editable": "onEditableClick",
    "click .revert": "onRevertClick",
    "click .save": "onSaveClick",
    "click .cancel": "onCancelClick",
    "keypress :input.number": "isNumberKey",
  },
  minDatapoints: 4,
  ellipsis: "...",
  apiDateFormat: "YYYY-MM-DD",
  defaultTabShown: true,
  initialize: function (options) {
    this.onLoad = true;
    this.options = options;
    this.accounts = new AccountsCollection();
    this.fetchAccounts();
    this.model.bind("change", this.updateContent, this);
    this.render();
    this.setTransHolding(this.options.tabId);
    this.editAccountNode = document.createElement("div");
    this.editAccountNode.className =
      "js-account-details-edit-account-container";
    document.body.appendChild(this.editAccountNode);

    if (this.options.showRefetchTransaction) {
      this.$el.find(".js-button-refetch-transactions-wrapper").tooltip();
    }
  },
  fetchAccounts: function () {
    this.accountsWatchId = Services.Accounts.get.watch(
      this.onAccountsFetched,
      this
    );
  },

  // Refetch transactions and show a banner notification when
  // refetch is successful, else show the API errors.
  refetchTransactions: function (event) {
    $(event.currentTarget).attr("disabled", true);
    this.$el
      .find(".js-button-refetch-transactions-wrapper")
      .tooltip("destroy")
      .attr({
        title: TRANSACTIONS_DISABLED_TOOLTIP,
      })
      .attr({
        "aria-label": TRANSACTIONS_DISABLED_TOOLTIP,
      })
      .tooltip();

    if (
      this.options.showRefetchTransaction &&
      this.options.isRefetchTransactionEligible
    ) {
      Services.Accounts.refresh(
        {
          type: "refetch_transaction",
          accountId: this.model.get("accountId"),
        },
        (err, response) => {
          const errors = parseResponseErrors(err, response);
          let message = (
            <div>
              Retrieving Transactions... This can take up to 5 minutes, so we
              <br />
              will continue fetching in the background even if you leave this
              page
            </div>
          );

          if (errors) {
            message = (
              <div>
                {errors.map((error, index) => {
                  return (
                    <div key={`refetch-error-${index}`}>
                      {error}
                      <br />
                    </div>
                  );
                })}
              </div>
            );
            $(event.currentTarget).attr("disabled", false);
          } else {
            delete this.options.isRefetchTransactionEligible;
          }

          let container = document.createElement("div");
          document.body.appendChild(container);
          ReactDOM.render(
            <SnackbarWithTimeout isOpen={true} message={message} />,
            container
          );
        }
      );
    }
  },

  onAccountsFetched: function (err, response) {
    if (err === null) {
      if (this.accounts.length < 1) {
        this.accounts.reset(response.spData.accounts);
      } else {
        this.accounts.freshen(response.spData.accounts);
      }
    }
  },
  render: function () {
    this.dateRangeSelector = new DateRangeSelector2({
      el: ".dateSelector",
      initialStartDate: this.getStartDate(),
      initialEndDate: this.getEndDate(),
      initialNowDate: DateUtils.nowString(),
      isPrivileged: isPrivileged,
    });
    this.dateRangeSelector.on(
      "change:dateRange",
      this.onDateSelectorChange,
      this
    );

    var filter = PERSONALCAPITAL.get("transactionsFilter");
    if (filter) {
      filter = _.clone(filter);
    } else {
      filter = {};
    }

    filter.startDate = this.getStartDateObj().toDate();
    filter.endDate = this.getEndDateObj().toDate();
    PERSONALCAPITAL.set("transactionsFilter", filter);

    $(".toolTipped", this.$el).tipTip();

    this.$(".js-button-refetch-transactions").toggleClass(
      "disabled",
      isEmpowerPrivilegedMode()
    );

    this.refreshClosedAccountStatus();
    if (IS_IFRAMED) {
      iframeClientInstance.triggerResize();
    }
  },
  updateView: function (newViewState) {
    var self = this;
    Backbone.View.prototype.updateView.call(this, newViewState);

    if (
      !_.isUndefined(this.previousOptions) &&
      String(this.previousOptions.userAccountId) ===
        String(this.options.userAccountId)
    ) {
      this.displayChart(this.options.tabId);
    } else {
      const foundAccount = this.accounts.find(function (account) {
        return (
          String(account.get("userAccountId")) ===
          String(self.options.userAccountId)
        );
      });
      this.changeAccount(foundAccount);
    }

    if (!this.previousTabId || this.previousTabId !== newViewState.tabId) {
      this.setTransHolding(newViewState.tabId);
    }

    this.previousTabId = newViewState.tabId;

    // Adds a tooltip when the account details view is updated.
    if (this.options.showRefetchTransaction) {
      this.$el.find(".js-button-refetch-transactions-wrapper").tooltip();
    }
  },
  setTransHolding: function (tabState) {
    var tab = tabState === "holdings" ? "holdings" : "transactions";

    PERSONALCAPITAL.set("datagridTab", tab);
  },
  changeAccount: function (account) {
    this.model.unbind("change", this.updateContent);
    this.model = account;
    this.model.bind("change", this.updateContent, this);

    this.render();
    this.clearChartReferences();
  },
  displayChart: function () {
    var filter = PERSONALCAPITAL.get("transactionsFilter");
    if (filter) {
      filter = _.clone(filter);
    } else {
      filter = {};
    }

    PERSONALCAPITAL.set("transactionsFilter", filter);
  },

  clearChartReferences: function () {
    if (this.balanceChart) {
      delete this.balanceChart;
    }

    if (this.cashFlowChart) {
      delete this.cashFlowChart;
    }

    if (this.incomeChart) {
      delete this.incomeChart;
    }

    if (this.performanceChart) {
      delete this.performanceChart;
    }
  },

  updateReopenedAccountDetails: function () {
    const closedText = this.$el.find(".js-closed-text");
    if (closedText.hasClass("account-details-closed")) {
      closedText.removeClass("account-details-closed");
      this.$el
        .find(".js-edit-account-button")
        .removeClass("account-details-closed--icon");
      this.$el.find(".js-closed-text--hide").removeClass("is-hidden");
      this.$el.find(".js-available-balance").removeClass("is-hidden");
      this.$el.find(".js-closed-date").addClass("is-hidden");
    }
  },
  /*
   * If only account info has been updated, then only update specific DOM elements
   */
  updateContent: function () {
    // update firmName
    var htmlText = this.model.get("firmName");
    htmlText = this.capStringLength(htmlText, MAX_LENGTH_FIRM_NAME);
    this.$el.find(".js-account-details-firm-name").html(htmlText);

    // update name
    htmlText = this.model.get("name");
    htmlText = this.capStringLength(htmlText, MAX_LENGTH_NAME);
    this.$el.find(".js-account-details-account-name").html(htmlText);

    // If there's an input element that is still active, return it to non-edit mode
    if (
      this.previousInputID === "#firmNameInput" ||
      this.previousInputID === "#nameInput"
    ) {
      var previousInputElement = this.$el.find(this.previousInputID);
      previousInputElement.val(this.previousInputData);
      previousInputElement.parent().parent().hide();
    }
    this.refreshClosedAccountStatus(true);
  },
  refreshClosedAccountStatus: function (isUpdated) {
    if (this.model.get("closedDate")) {
      var closedDateSelector = this.$el.find(".js-closed-date");
      closedDateSelector.removeClass("is-hidden");
      this.$el
        .find(".js-closed-icon")
        .attr(
          "class",
          "account-details-info__tip account-details-closed--icon"
        );
      this.$el.find(".js-closed-text").addClass("account-details-closed");
      this.$el.find(".js-closed-text--hide").addClass("is-hidden");
      this.$el.find(".js-available-balance").addClass("is-hidden");
      let isEditable =
        !this.model.get("isOnUs") &&
        !this.model.get("isOnUs401K") &&
        !this.model.get("isOnUsBank") &&
        !this.model.get("isPartner");

      if (isEditable) {
        this.$el
          .find(".js-edit-account-button")
          .addClass("account-details-closed--icon");
      } else {
        this.$el.find(".js-edit-account-button").remove();
      }

      closedDateSelector.addClass("pc-layout__item");
      closedDateSelector.html(
        "Closed " +
          "<strong>" +
          moment(this.model.get("closedDate")).format(
            DateUtils.DISPLAY_FORMAT
          ) +
          "</strong>"
      );
      this.$el
        .find(
          ".loanInfo, .investmentInfo, .manualAccountInfo, .availableBalance, .creditInfo, .billButton"
        )
        .remove();
      return;
    }

    if (isUpdated) {
      this.updateReopenedAccountDetails();
    }
  },
  onClose: function () {
    this.removeWatch();
    if (this.editAccountNode) {
      ReactDOM.unmountComponentAtNode(this.editAccountNode);
      document.body.removeChild(this.editAccountNode);
      delete this.editAccountNode;
    }
  },
  removeWatch: function () {
    // generally, we do unwatch before we watch but this additional unwatching is done
    // when switching between different product types, this.watchID would be undefined
    // as it is a new instance of that product type view.
    if (this.accountsWatchId) {
      Services.Accounts.get.unwatch(this.accountsWatchId);
      delete this.accountsWatchId;
    }
    if (this.getBalanceHistoryWatchId) {
      Services.Histories.get.unwatch(this.getBalanceHistoryWatchId);
      delete this.getBalanceHistoryWatchId;
    }
    if (this.getCashflowHistoryWatch) {
      Services.Histories.get.unwatch(this.getCashflowHistoryWatch);
      delete this.getCashflowHistoryWatch;
    }
  },
  capStringLength: function (string, maxStringLength) {
    if (!string) return "";
    return string.length > maxStringLength
      ? string.substring(0, maxStringLength) + this.ellipsis
      : string;
  },
  getHistory: function () {
    /* noop */
  },
  closeEditAccountModal: function () {
    ReactDOM.unmountComponentAtNode(this.editAccountNode);
  },
  editAccount: function () {
    ReactDOM.render(
      <EditAccountContainer
        userAccountId={this.model.attributes.userAccountId}
        isOpen={true}
        onCancel={this.closeEditAccountModal.bind(this)}
        onSave={this.closeEditAccountModal.bind(this)}
      />,
      this.editAccountNode
    );
  },
  /*
   * When user clicks on an editable text, display editor
   */
  onEditableClick: function (event) {
    var targetElement = $(event.currentTarget),
      className = targetElement.attr("class").split(" ")[0],
      inputId = "#" + className + "Input";

    // If there's an input element that is still active, return it to non-edit mode
    if (
      !_.isUndefined(this.previousInputID) &&
      !_.isNull(this.previousInputID)
    ) {
      var previousInputElement = this.$el.find(this.previousInputID);
      previousInputElement.val(this.previousInputData);
      previousInputElement.parent().parent().hide();
    }

    // track current input element so it can be used
    // to reset the previous input element
    this.previousInputID = inputId;
    this.previousInputData = this.$el.find(inputId).val();

    // Display editor
    var editContainer = this.$el.find(
      '[class~="editContainer"][class~=' + className + "]"
    );
    var offset = targetElement.offset();
    offset.left -= 7;
    offset.top -=
      inputId === "#firmNameInput" ? 10 : 7; /* disable preexisting warning */ // eslint-disable-line no-magic-numbers
    editContainer.show();
    editContainer.offset(offset);
  },

  /*
   * On click of revert button, set text element to it's original value
   */
  onRevertClick: function () {
    if (
      typeof this.previousInputID != "undefined" &&
      this.previousInputID !== null
    ) {
      var className = this.previousInputID.replace(/(#|Input)/g, "");

      if (className === "name") {
        className = "originalName";
      } else if (className === "firmName") {
        className = "originalFirmName";
      }

      var originalValue = this.model.get(className);
      this.$el.find(this.previousInputID).val(originalValue);
    }
  },

  /*
   * On click of Save button, make API call to update account data and hide editor
   */
  onSaveClick: function () {
    if (
      typeof this.previousInputID != "undefined" &&
      this.previousInputID !== null
    ) {
      var propertyName = this.previousInputID.replace(/(#|Input)/g, ""),
        inputElement = this.$el.find(this.previousInputID),
        newText,
        maxStringLength;

      if (propertyName === "firmName") {
        maxStringLength = MAX_LENGTH_FIRM_NAME;
      } else if (propertyName === "name") {
        maxStringLength = MAX_LENGTH_NAME;
      }

      newText = this.capStringLength(inputElement.val(), maxStringLength);

      var htmlText = newText + '<span class="editIcon"></span>';
      this.$el.find(".editable." + propertyName).html(htmlText);

      // create generic object to pass as an argument to API call
      var accountObject = { accountId: this.model.get("accountId") };
      accountObject[propertyName] = inputElement.val();

      if (this.model.get("isManual")) {
        Services.Accounts.updateManual(accountObject);
      } else {
        Services.Accounts.update(accountObject);
      }

      inputElement.parent().parent().hide();

      this.previousInputID = null;
      this.previousInputData = null;
    }
  },

  /*
   * On click of cancel button, set text element to it's previous value
   * and hide editor
   */
  onCancelClick: function () {
    if (
      typeof this.previousInputID != "undefined" &&
      this.previousInputID !== null
    ) {
      var inputElement = this.$el.find(this.previousInputID);

      // reset data and hide editor
      inputElement.val(this.previousInputData);
      inputElement.parent().parent().hide();

      this.previousInputID = null;
      this.previousInputData = null;
    }
  },
  isNumberKey: function (event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode === KEYCODE_LEFT_ARROW || charCode === KEYCODE_RIGHT_ARROW) {
      return true;
    } else if (
      charCode !== KEYCODE_DELETE &&
      charCode > 31 && // eslint-disable-line no-magic-numbers
      (charCode < KEYCODE_ZERO || charCode > KEYCODE_NINE)
    ) {
      /* disable preexisting warning */
      return false;
    }
    return true;
  },

  /* ************** DATE METHODS ***************** */

  onDateSelectorChange: function (startDate, endDate) {
    this.onLoad = false;
    if (arguments.length > 1) {
      this.setStartDate(startDate);
      this.setEndDate(endDate);
    }

    var filter = PERSONALCAPITAL.get("transactionsFilter");
    if (!filter) {
      filter = {};
    }

    filter = _.clone(filter);

    filter.startDate = this.getStartDateObj().toDate();
    filter.endDate = this.getEndDateObj().toDate();
    PERSONALCAPITAL.set("transactionsFilter", filter);

    this.getHistory();
  },

  getStartDate: function (asTrans) {
    // If the account is closed, return startDate = closedDate - 90 days. If the account was not linked yet
    // in closedDate - 90 days, return linked date so we don't show days for which we don't have data
    if (this.model.get("closedDate") && this.onLoad) {
      let accountCreatedDate = moment.unix(
        this.model.get("createdDate") / 1000
      );
      let closedMinus90days = moment(
        this.model.get("closedDate"),
        DateUtils.API_FORMAT
      );
      closedMinus90days.subtract(DEFAULT_DAY_RANGE, "days");
      if (closedMinus90days.isSameOrBefore(accountCreatedDate)) {
        return accountCreatedDate.format(
          asTrans ? DateUtils.API_FORMAT : DateUtils.DISPLAY_FORMAT
        );
      }
      return closedMinus90days.format(
        asTrans ? DateUtils.API_FORMAT : DateUtils.DISPLAY_FORMAT
      );
    }

    return asTrans
      ? moment(this.options.startDate, DateUtils.DISPLAY_FORMAT).format(
          DateUtils.API_FORMAT
        )
      : this.options.startDate;
  },

  getStartDateObj: function () {
    var sd = this.getStartDate();
    return moment(sd, DateUtils.DISPLAY_FORMAT);
  },

  getEndDateObj: function () {
    var ed = this.getEndDate();
    return moment(ed, DateUtils.DISPLAY_FORMAT);
  },

  getEndDate: function (asTrans) {
    if (this.model.get("closedDate") && this.onLoad) {
      return moment(this.model.get("closedDate"), DateUtils.API_FORMAT).format(
        asTrans ? DateUtils.API_FORMAT : DateUtils.DISPLAY_FORMAT
      );
    }
    return asTrans
      ? moment(this.options.endDate, DateUtils.DISPLAY_FORMAT).format(
          DateUtils.API_FORMAT
        )
      : this.options.endDate;
  },

  getFormattedNoDataDateRange: function () {
    if (this.options.startDate && this.options.endDate) {
      return " between " + this.getStartDate() + " and " + this.getEndDate();
    }
  },

  setStartDate: function (dateString) {
    //@TODO: protective validation.

    this.options.startDate = dateString;
    var stateObject = { startDate: dateString };
    Backbone.View.prototype.saveInternalState.call(
      this,
      "accountDetails",
      stateObject,
      true,
      "userAccountId",
      this.options.userAccountId
    );
  },

  setEndDate: function (dateString) {
    //@TODO: protective validation.

    this.options.endDate = dateString;
    var stateObject = { endDate: dateString };
    Backbone.View.prototype.saveInternalState.call(
      this,
      "accountDetails",
      stateObject,
      true,
      "userAccountId",
      this.options.userAccountId
    );
  },

  /*
   * ----------- Chart Utility Functions -----------
   */

  generateXAxisLabels: function (dataSet, interval) {
    var labels = [];
    var date;
    var label;

    if (dataSet && interval === DateUtils.MONTH_INTERVAL) {
      for (const element of dataSet) {
        date = moment(element.date);
        if (date.month() === 0) {
          label = date.format(DateUtils.CHART_YEAR_INTERVAL_FORMAT);
        } else {
          label = date.format(DateUtils.CHART_MONTH_INTERVAL_FORMAT);
          // eslint-disable-next-line no-magic-numbers
          if (dataSet.length > 7) {
            /* disable preexisting warning */
            label = label.charAt(0);
          } else {
            label = label.toUpperCase();
          }
        }
        labels.push(label);
      }
    } else {
      labels = _.map(dataSet, function (datapoint) {
        date = moment(datapoint.date);
        label = date.format(DateUtils.DISPLAY_FORMAT);
        return label;
      });
    }

    return labels;
  },

  /*
   * ----------- Balance data methods -----------
   */
  getBalanceHistory: function (onLoad = false) {
    this.onLoad = onLoad;
    if (this.getBalanceHistoryWatchId) {
      Services.Histories.get.unwatch(this.getBalanceHistoryWatchId);
    }
    this.getBalanceHistoryWatchId = Services.Histories.get.watch(
      {
        userAccountIds: JSON.stringify([this.model.get("userAccountId")]),
        startDate: this.getStartDate(true),
        endDate: this.getEndDate(true),
        intervalType: DateUtils.DAY_INTERVAL,
        types: JSON.stringify([
          "balances",
          "dailychangeamount",
          "oneDaySummaries",
        ]),
      },
      this.onBalanceFetched,
      this
    );
  },
  onBalanceFetched: function (err, response) {
    if (err === null) {
      if (
        typeof response == "undefined" ||
        typeof response.spData == "undefined" ||
        typeof response.spData.histories == "undefined" ||
        response.spData.histories.length === 0
      ) {
        this.renderNoDataBalanceChart();
        return;
      }
      const accountCreatedDate = moment
        .unix(this.model.get("createdDate") / 1000)
        .startOf("day");
      if (moment().isSame(accountCreatedDate, "day")) {
        this.renderZeroStateBalanceChart();
        return;
      }
      let histories = _.filter(response.spData.histories, function (history) {
        const historyDate = moment(history.date, DateUtils.API_FORMAT);
        return (
          historyDate.isSameOrAfter(accountCreatedDate) &&
          !isNaN(history.aggregateBalance)
        );
      });
      if (histories.length <= 1) {
        this.renderNoDataBalanceChart();
        return;
      }
      this.$(".js-no-balance-data, .js-zero-data-for-balance-char").hide();
      delete this.priorDayBalance;
      this.balanceData = _.map(histories, this.createBalanceArrayElement, this);
      this.balanceSeries = _.map(this.balanceData, function (dataPoint) {
        return dataPoint.balance;
      });
    }
  },
  filterBalanceArray: function (dataPoint) {
    return !isNaN(dataPoint.aggregateBalance);
  },
  createBalanceArrayElement: function (dataPoint) {
    this.priorDayBalance = dataPoint.aggregateBalance;
    return {
      date: dataPoint.date,
      balance: dataPoint.aggregateBalance,
      change: dataPoint.aggregateDailyChangeAmount,
    };
  },
  renderBalanceChart: function () {
    if (!_.isArray(this.balanceData) || this.balanceData.length === 0) {
      return;
    }

    var balanceLegendData = this.balanceData,
      minMax = {
        min: _.min(this.balanceSeries),
        max: _.max(this.balanceSeries),
      };

    this.$el
      .find("#balanceChart > .startDate")
      .text(moment(balanceLegendData[0].date).format(DateUtils.DISPLAY_FORMAT));
    this.$el
      .find("#balanceChart > .endDate")
      .text(
        moment(balanceLegendData[balanceLegendData.length - 1].date).format(
          DateUtils.DISPLAY_FORMAT
        )
      );

    if (!this.balanceChart) {
      const balanceChartEl = this.$el.find("#balanceChart");
      if (balanceChartEl) {
        const width = parseInt(balanceChartEl.width() * WIDTH_SIZE_OUTSIDE, 10);
        const height =
          balanceChartEl.find("#balanceSVG").height() ??
          balanceChartEl.height();
        this.balanceChart = Raphael("balanceSVG", width, height);
      }
    }

    var productType = this.model.get("productType");
    const colors = getAccountDetailsChartColors();
    const color = colors[productType] || colors.ALL;
    this.balanceChart.clear();
    this.balanceChart
      .drawGrid({
        min: minMax.min,
        max: minMax.max,
        topPadding: 0.1,
        bottomPadding: 0.1,
        hideLabels: !IS_EMPOWER,
        showYLabelsOutside: true,
        yLabelPaddingRight: LABELS_MARGIN_RIGHT_OUTSIDE,
        rightPadding: 0.1,
      })
      .drawLineSeries({
        series: this.balanceSeries,
        fill: color,
        stroke: color,
        strokeWidth: 2,
      })
      .hoverGrid({
        pointHoverCallback: function (i) {
          var dataPoint = balanceLegendData[i];
          var l = $(".balance.legend");
          l.find(".balance label").html(
            moment(dataPoint.date).format("M-D-YYYY")
          );
          l.find(".change span").html(
            $.pcap.formatDollars(dataPoint.change, true)
          );
          l.find(".balance span").html(
            $.pcap.formatDollars(dataPoint.balance, true)
          );
        },
        mouseleave: function () {
          $(".balance.legend").fadeTo(ANIMATION_DURATION, 0);
        },
        mouseenter: function () {
          $(".balance.legend").fadeTo(ANIMATION_DURATION, 1);
        },
        parentId: "balanceSVG",
      })
      .drawBaseline();

    eventBus.trigger(customEvents.accountDetailsChartReady);
  },
  renderNoDataBalanceChart: function () {
    this.$(".js-account-details-no-data-date-range").text(
      this.getFormattedNoDataDateRange()
    );
    this.$(".js-no-balance-data").show();
  },
  renderZeroStateBalanceChart: function () {
    this.$(".js-zero-data-for-balance-chart").show();
  },
  fillDataGapForYear: function (data, propertyName) {
    var startingMonthInData = moment(data[0].date);
    var numericStartingMonth = Number(startingMonthInData.format("M"));

    for (var i = numericStartingMonth - 1; i >= 0; i--) {
      var dateString = startingMonthInData
        .month(i)
        .format(DateUtils.API_FORMAT);
      var interval = { date: dateString };
      interval[propertyName] = 0;

      data.unshift(interval);
    }
  },
  /*
   * ----------- cash flow data methods -----------
   */
  getCashflowsHistory: function (onLoad = false) {
    this.onLoad = onLoad;
    if (this.getCashflowHistoryWatch) {
      Services.Histories.get.unwatch(this.getCashflowHistoryWatch);
    }
    this.getCashflowHistoryInterval = DateUtils.getIntervalFromRange(
      this.getStartDate(),
      this.getEndDate()
    );
    this.getCashflowHistoryWatch = Services.Histories.get.watch(
      {
        userAccountIds: JSON.stringify([this.model.get("userAccountId")]),
        startDate: this.getStartDate(true),
        endDate: this.getEndDate(true),
        intervalType: this.getCashflowHistoryInterval,
        types: JSON.stringify(["cashflows"]),
      },
      this.onCashFlowFetched,
      this
    );
  },
  onCashFlowFetched: function (err, response) {
    if (err === null) {
      if (
        typeof response == "undefined" ||
        typeof response.spData == "undefined" ||
        typeof response.spData.histories == "undefined" ||
        response.spData.histories.length === 0
      ) {
        this.$el.find(".js-no-income-data").show();
        return;
      }
      this.$el.find(".js-no-income-data").hide();

      var requestedMonths = this.getEndDateObj().diff(
        this.getStartDateObj(),
        "months"
      );

      this.cashOutData = _.map(
        response.spData.histories,
        this.createCashOutArrayElement
      );
      if (
        requestedMonths === MONTHS_YEAR &&
        this.cashOutData.length < requestedMonths
      ) {
        this.fillDataGapForYear(this.cashOutData, "cashOut");
      }
      this.cashOutSeries = _.map(this.cashOutData, function (dataPoint) {
        return dataPoint.cashOut;
      });

      this.cashInData = _.map(
        response.spData.histories,
        this.createCashInArrayElement
      );
      if (
        requestedMonths === MONTHS_YEAR &&
        this.cashInData.length < requestedMonths
      ) {
        this.fillDataGapForYear(this.cashInData, "cashIn");
      }
      this.cashInSeries = _.map(this.cashInData, function (dataPoint) {
        return dataPoint.cashIn;
      });

      this.incomeData = _.map(
        response.spData.histories,
        this.createIncomeArrayElement
      );
      if (
        requestedMonths === MONTHS_YEAR &&
        this.incomeData.length < requestedMonths
      ) {
        this.fillDataGapForYear(this.incomeData, "income");
      }
      this.incomeSeries = _.map(this.incomeData, function (dataPoint) {
        return dataPoint.income;
      });
    }
  },
  createCashOutArrayElement: function (dataPoint) {
    return { date: dataPoint.date, cashOut: dataPoint.aggregateCashOut };
  },
  createCashInArrayElement: function (dataPoint) {
    return { date: dataPoint.date, cashIn: dataPoint.aggregateCashIn };
  },
  createIncomeArrayElement: function (dataPoint) {
    return { date: dataPoint.date, income: dataPoint.aggregateIncome };
  },
  renderIncomeChart: function () {
    var incomeLegendData = this.incomeData,
      minMax = { min: 0, max: _.max(this.incomeSeries) };

    if (!this.incomeChart) {
      this.incomeChart = Raphael("incomeSVG");
    }

    this.incomeChart.clear();
    this.incomeChart
      .drawGrid({
        min: minMax.min,
        max: minMax.max,
        topPadding: 0.1,
        bottomPadding: 0.1,
        hideLabels: false,
        rightPadding: 40,
        xLabels: this.generateXAxisLabels(
          incomeLegendData,
          this.getCashflowHistoryInterval
        ),
        container: "incomeSVG",
      })
      .drawBarSeries({
        series: this.incomeSeries,
        color: COLORS.POSITIVE,
        hoverTips: {
          show: true,
          formatter: function (val) {
            return accounting.formatMoney(val);
          },
        },
      })
      .drawBaseline();
    eventBus.trigger(customEvents.accountDetailsChartReady);
  },

  /*
   * ----------- Performance data methods -----------
   */
  getPerformanceHistory: function (onLoad = false) {
    this.onLoad = onLoad;
    if (this.getQuotesWatchId) {
      Services.Quotes.get.unwatch(this.getQuotesWatchId);
    }
    this.getQuotesWatchId = Services.Quotes.get.watch(
      {
        userAccountIds: JSON.stringify([this.model.get("userAccountId")]),
        startDate: this.getStartDate(true),
        endDate: this.getEndDate(true),
        includeYOUHistory: true,
      },
      this.onPerformanceFetched,
      this
    );
  },
  onPerformanceFetched: function (err, response) {
    if (err === null) {
      if (
        typeof response == "undefined" ||
        typeof response.spData == "undefined" ||
        typeof response.spData.histories == "undefined" ||
        response.spData.histories.length === 0
      ) {
        this.$(".js-no-performance-data").show();
        return;
      }
      this.$(".js-no-performance-data").hide();

      this.performanceData = [];

      // Temporary workaround for deficient API when it does not return YOU property
      _.each(
        response.spData.histories,
        function (dataPoint, index, histories) {
          var newDataPoint = { date: dataPoint.date };

          if (index === histories.length - 1 && _.isUndefined(dataPoint.YOU)) {
            newDataPoint.performance = histories[index - 1].YOU;
            this.performanceData.push(newDataPoint);
          } else if (!_.isUndefined(dataPoint.YOU)) {
            newDataPoint.performance = dataPoint.YOU;
            this.performanceData.push(newDataPoint);
          }
        }.bind(this)
      );

      this.performanceSeries = _.map(
        this.performanceData,
        function (dataPoint) {
          return dataPoint.performance;
        }
      );
    }
  },
  renderPerformanceChart: function () {
    if (!_.isArray(this.performanceData) || this.performanceData.length === 0) {
      return;
    }

    var performanceLegendData = this.performanceData,
      minMax = {
        min: _.min(this.performanceSeries),
        max: _.max(this.performanceSeries),
      };

    this.$el
      .find("#performanceChart > .startDate")
      .text(
        moment(performanceLegendData[0].date).format(DateUtils.DISPLAY_FORMAT)
      );
    this.$el
      .find("#performanceChart > .endDate")
      .text(
        moment(
          performanceLegendData[performanceLegendData.length - 1].date
        ).format(DateUtils.DISPLAY_FORMAT)
      );

    if (!this.performanceChart) {
      this.performanceChart = Raphael("performanceSVG");
    }

    this.performanceChart.clear();
    this.performanceChart
      .drawGrid({
        min: minMax.min,
        max: minMax.max,
        topPadding: 0.5,
        bottomPadding: 0.5,
        hideLabels: true,
        yLabelType: "percentage",
      })
      .drawLineSeries({
        series: this.performanceSeries,
        fill: "none",
        stroke: "#0088cc",
        strokeWidth: 2,
      })
      .hoverGrid({
        pointHoverCallback: function (i) {
          var dataPoint = performanceLegendData[i];
          var l = $(".performance.legend");
          l.find(".performance label").html(
            moment(dataPoint.date).format("M-D-YYYY")
          );
          l.find(".performance span").html(dataPoint.performance + "%");
        },
        mouseleave: function () {
          $(".performance.legend").fadeTo(ANIMATION_DURATION, 0);
        },
        mouseenter: function () {
          $(".performance.legend").fadeTo(ANIMATION_DURATION, 1);
        },
        parentId: "performanceSVG",
      })
      .drawBaseline()
      .drawZeroline();

    eventBus.trigger(customEvents.accountDetailsChartReady);
  },
});

export default BaseDetailsView;
