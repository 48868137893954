import React from "react";
import PropTypes from "prop-types";
import BankIcon from "svg-icons/TilesBankIcon.svg";
import CreditIcon from "svg-icons/TilesCreditIcon.svg";
import InvestmentIcon from "svg-icons/TilesInvestmentIcon.svg";
import LoanIcon from "svg-icons/TilesLoanIcon.svg";
import RetirementIcon from "svg-icons/TilesRetirementIcon.svg";
import HomeIcon from "svg-icons/TilesHomeIcon.svg";
import { noop } from "underscore";

const TILE_ICON_CLASS = "account-type-tiles__icon pc-u-mb--";

class AccountTypeTile extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = props.handleClick.bind(this, {
      link: props.link,
      title: props.title,
    });
  }

  tileIconSwitch(icon) {
    switch (icon) {
      case "BankIcon":
        return <BankIcon className={TILE_ICON_CLASS} />;
      case "CreditIcon":
        return <CreditIcon className={TILE_ICON_CLASS} />;
      case "InvestmentIcon":
        return <InvestmentIcon className={TILE_ICON_CLASS} />;
      case "LoanIcon":
        return <LoanIcon className={TILE_ICON_CLASS} />;
      case "RetirementIcon":
        return <RetirementIcon className={TILE_ICON_CLASS} />;
      case "HomeIcon":
        return <HomeIcon className={TILE_ICON_CLASS} />;
      default:
        return <img src="" alt="No icon found" />;
    }
  }

  render() {
    const { title, icon, handleMouseEnter, addMarginClassname } = this.props;
    let buttonClass = "account-type-tiles__tile pc-u-pb-- ";
    if (addMarginClassname) {
      buttonClass += " pc-u-mb-";
    }

    return (
      <>
        <button
          className={buttonClass}
          onClick={this.handleClick}
          onMouseEnter={handleMouseEnter}
        >
          {this.tileIconSwitch(icon)}
          <p className="account-type-tiles__text">{title}</p>
        </button>
      </>
    );
  }
}

AccountTypeTile.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
  handleClick: PropTypes.func,
  handleMouseEnter: PropTypes.func,
  addMarginClassname: PropTypes.bool,
};

AccountTypeTile.defaultProps = {
  title: "",
  icon: "",
  link: "",
  addMarginClassname: false,
  handleClick: noop,
  handleMouseEnter: noop,
};

export default AccountTypeTile;
