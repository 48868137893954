import moment from "moment";
import _ from "underscore";

/**
 * `buildCumulativeCashFlow` takes the cash flow object and returns a cumulative cash flow
 * required for drawing the cumulative line series
 *
 *
 * @param   {Array}     cashFlow              Cashflow.
 * @param   {String}    incomeExpenseMode     An optional param indicating the income or expense mode.
 * @return  {Array}     cumulativeCashFlow    Cumulative Cashflow.
 */

export default function buildCumulativeCashFlow(cashFlow, incomeExpenseMode) {
  if (!cashFlow) {
    return [];
  }
  var cumulativeCashFlow = cashFlow.map(function (data) {
    var y;
    if (incomeExpenseMode) {
      y = data[incomeExpenseMode === "income" ? "moneyIn" : "moneyOut"];
    } else {
      y = data.moneyIn - data.moneyOut;
    }

    return {
      x: moment(data.date),
      y: y,
    };
  });
  if (!_.isEmpty(cumulativeCashFlow)) {
    cumulativeCashFlow.reduce(function (previous, current) {
      current.y = previous.y + current.y;
      return current;
    });
  }
  return cumulativeCashFlow;
}
