var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./account2.html"),depth0,{"name":"templates/modules/sidebar/account2","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"js-account-extras sidebar-account__extras\">\n  <div class=\"js-report-text\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"submittedTicketText") : depth0), depth0))
    + "</div>\n  <br>\n</div>\n";
},"usePartial":true,"useData":true});