import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import eventBus from "eventBus";
import { subscribify, promisify } from "utils/service";
import Loading from "components/common/loading/Loading";
import * as constants from "components/sidebar/constants";
import PlannerControl from "components/planner/common/PlannerControl/PlannerControl";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import { replaceHash } from "libs/pcap/utils/location";

const REQUEST_SOURCE = "USER";
const { isAdvisorApp } = window;
const DEFAULT_PLANNER_CONTROL_HEIGHT = "80vh";

export default class PlannerControlContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      loading: false,
      activePlanId: props.activePlanId,
    };
    this.handlePlanSelected = this.handlePlanSelected.bind(this);
    this.updateScrollbarHeight = this.updateScrollbarHeight.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const {
      disabled,
      isComparisonDisabled,
      isNewScenarioDisabled,
      isGLWB,
      accounts,
      maModelPortfolios,
    } = this.props;

    const { plans, activePlanId, errors } = this.state;
    const plannerControl = (
      <div className="planner-control-container">
        <PlannerControl
          errors={errors}
          plans={plans}
          disabled={disabled}
          isComparisonDisabled={isComparisonDisabled}
          isNewScenarioDisabled={isNewScenarioDisabled}
          isGLWB={isGLWB}
          activePlanId={activePlanId}
          onPlanSelected={this.handlePlanSelected}
          accounts={accounts}
          maModelPortfolios={maModelPortfolios}
        />
      </div>
    );

    if (isAdvisorApp) {
      return plannerControl;
    }

    return (
      <PerfectScrollbar
        containerRef={(el) => {
          this.scrollbarEl = el;
        }}
      >
        {plannerControl}
      </PerfectScrollbar>
    );
  }

  handleSuccess(response) {
    this.setState({ loading: false });
    response.sort((a, b) => {
      if (a.isMasterPlan) {
        return -1;
      }

      if (b.isMasterPlan) {
        return 1;
      }

      return 0;
    });
    this.selectPlan(response, this.nextPlanId);
  }

  handleFailure(errors) {
    this.setState({
      errors,
      loading: false,
    });
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (!this.state.activePlanId) {
      this.nextSelectionType = constants.PLAN_LIST_SELECTION_TYPE_INITIAL;
    }

    this.fetchPlansSubscription = this.props.fetchPlans({
      requestSource: REQUEST_SOURCE,
    });
    this.fetchPlansSubscription.on("change", this.handleSuccess);
    this.fetchPlansCancelablePromise = makeCancelablePromise(
      this.fetchPlansSubscription.promise
    );
    this.fetchPlansCancelablePromise.promise
      .then(this.handleSuccess)
      .catch(this.handleFailure);

    window.addEventListener("resize", this.updateScrollbarHeight);
    this.updateScrollbarHeight();
    eventBus.on("planlist:updated", this.updateSelectionType, this);
    eventBus.on("editplan:planrenamed", this.setPlanName, this);
    eventBus.on("plan:published", this.refetchPlans, this);
  }

  componentWillUnmount() {
    eventBus.off("planlist:updated", this.updateSelectionType, this);
    eventBus.off("editplan:planrenamed", this.setPlanName, this);
    eventBus.off("plan:published", this.refetchPlans, this);
    if (this.fetchPlansCancelablePromise) {
      this.fetchPlansCancelablePromise.cancel();
    }

    if (this.fetchPlansSubscription) {
      this.fetchPlansSubscription.unwatch();
      this.fetchPlansSubscription.off("change");
    }
    window.removeEventListener("resize", this.updateScrollbarHeight);
  }

  // Needed after first complete render (with data loaded)
  componentDidUpdate() {
    this.updateScrollbarHeight();
  }

  updateScrollbarHeight() {
    if (this.scrollbarEl) {
      this.scrollbarEl.style.height = DEFAULT_PLANNER_CONTROL_HEIGHT;
    }
  }

  setPlanName(planId, planName) {
    let i;
    const plans = this.state.plans.slice();

    for (i = 0; i < plans.length; i++) {
      if (plans[i].planId === planId) {
        plans[i].planName = planName;
        break;
      }
    }

    this.setState({ plans });
  }

  handlePlanSelected(plan) {
    this.setState({ activePlanId: plan.planId });
    this.loadPlan(plan.planId);
  }

  refetchPlans() {
    promisify(Services.myLife.getPlans)()
      .then(this.handleSuccess)
      .catch(this.handleFailure);
  }

  loadPlan(planId, isFirstUseCompleted) {
    const { hash } = window.location;
    if (hash.includes("retirement-planner")) {
      const sectionRE = /retirement-planner\/?([A-Za-z]+)\??/;
      const match = sectionRE.exec(hash);
      const section = match ? match[1] : "index";
      let route = `/retirement-planner/${section}?planId=${planId}`;
      if (isFirstUseCompleted) {
        route += "&firstusecompleted=true";
      }
      replaceHash(route);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // No plan will be passed in from outside if it's not in the URL
    // i.e. the default case when loading the plain RP page
    if (!nextProps.activePlanId && this.state.plans.length) {
      this.handlePlanSelected(this.state.plans[0]);
      return;
    }

    if (nextProps.activePlanId !== this.state.activePlanId) {
      this.setState({ activePlanId: nextProps.activePlanId });
    }
  }

  // This determines the selection strategy for whenever the list gets
  // reloaded due to user action in the planner
  updateSelectionType(selectionType, planId) {
    this.nextSelectionType = selectionType;
    this.nextPlanId = planId;
  }

  selectPlan(plans, planId) {
    if (!plans || !plans.length) {
      return;
    }

    let newPlan;
    if (
      this.nextSelectionType === constants.PLAN_LIST_SELECTION_TYPE_MASTER ||
      this.nextSelectionType === constants.PLAN_LIST_SELECTION_TYPE_INITIAL
    ) {
      newPlan = plans[0]; // Master plan is always first
    } else if (
      this.nextSelectionType === constants.PLAN_LIST_SELECTION_TYPE_LAST
    ) {
      newPlan = plans[plans.length - 1];
    } else if (
      this.nextSelectionType === constants.PLAN_LIST_SELECTION_TYPE_BY_ID
    ) {
      for (let i = 0; i < plans.length; i++) {
        if (plans[i].planId === planId) {
          newPlan = plans[i];
          break;
        }
      }
    }

    // On initial load, highlight the plan in sidebar but don't trigger a page reload
    if (
      newPlan &&
      (this.nextSelectionType !== constants.PLAN_LIST_SELECTION_TYPE_INITIAL ||
        this.props.isFirstUseCompleted)
    ) {
      this.loadPlan(newPlan.planId, this.props.isFirstUseCompleted);
    }

    // Set selectionType to null in case a server change triggers another reload.
    // The selection should only update when there is an explicit reason to do so
    // e.g. the selected plan was deleted, a new plan was added or set as master plan
    this.nextSelectionType = null;

    // Ensure plans have propagated to PlanList before highlighting active plan
    this.setState({ plans }, () => {
      if (newPlan) {
        this.setState({ activePlanId: newPlan.planId });
      }
    });
  }
}

PlannerControlContainer.propTypes = {
  activePlanId: PropTypes.number,
  disabled: PropTypes.bool,
  isComparisonDisabled: PropTypes.bool,
  isNewScenarioDisabled: PropTypes.bool,
  isFirstUseCompleted: PropTypes.bool, // Marks the transition from first use to regular use
  isGLWB: PropTypes.bool,
  fetchPlans: PropTypes.func,
  accounts: PropTypes.array,
  maModelPortfolios: PropTypes.array,
};

PlannerControlContainer.defaultProps = {
  activePlanId: undefined,
  disabled: undefined,
  isComparisonDisabled: undefined,
  isNewScenarioDisabled: undefined,
  isFirstUseCompleted: undefined,
  isGLWB: false,
  fetchPlans: subscribify(Services.myLife.getPlans),
  accounts: [],
  maModelPortfolios: [],
};
