var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPrivileged") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":35,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":13,"column":22}}})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.noop,"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":20,"column":22}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"sidebar-account__actions\">\n          <button type=\"button\" class='js-account-cancel pc-btn pc-btn--tiny'>Cancel</button>\n          <button type=\"button\" class='js-account-submit pc-btn pc-btn--tiny pc-btn--primary js-account-action disabled'>Refresh Account</button>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <div class=\"sidebar-account__actions sidebar-account__actions--vertical\">\n        <button type=\"button\" class='js-account-submit pc-btn pc-btn--tiny pc-btn--primary js-account-action disabled'>Refresh Account</button>\n"
    + ((stack1 = container.invokePartial(require("./removeAccount2.html"),depth0,{"name":"templates/modules/sidebar/removeAccount2","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <button type=\"button\" class='js-account-cancel pc-btn pc-btn--tiny'>Cancel</button>\n      </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":6},"end":{"line":27,"column":24}}})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.noop,"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":28,"column":6},"end":{"line":34,"column":24}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"sidebar-account__actions\">\n          <button type=\"button\" class='js-account-cancel pc-btn pc-btn--tiny'>Cancel</button>\n          <button type=\"button\" class='js-account-submit pc-btn pc-btn--tiny pc-btn--primary js-account-action'>Refresh Account</button>\n        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"sidebar-account__actions sidebar-account__actions--vertical\">\n          <button type=\"button\" class='js-account-submit pc-btn pc-btn--tiny pc-btn--primary js-account-action'>Refresh Account</button>\n"
    + ((stack1 = container.invokePartial(require("./removeAccount2.html"),depth0,{"name":"templates/modules/sidebar/removeAccount2","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          <button type=\"button\" class='js-account-cancel pc-btn pc-btn--tiny'>Cancel</button>\n        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"sidebar-account__actions\">\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.noop,"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":38,"column":4},"end":{"line":40,"column":22}}})) != null ? stack1 : "")
    + "		<button type=\"button\" class='js-account-cancel pc-btn pc-btn--tiny'>Cancel</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPrivileged") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "")
    + "	</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./removeAccount2.html"),depth0,{"name":"templates/modules/sidebar/removeAccount2","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "		  <button type=\"button\" class='js-account-submit pc-btn pc-btn--tiny pc-btn--primary js-account-action' disabled>Fix Account</button>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "      <button type=\"button\" class='js-account-submit pc-btn pc-btn--tiny pc-btn--primary js-account-action'>Fix Account</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./site.html"),depth0,{"name":"templates/modules/sidebar/site","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"js-account-extras sidebar-account__extras is-hidden\">\n	<div class='sidebar-account__message'>"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":6,"column":1},"end":{"line":48,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});