/* eslint-disable max-nested-callbacks */
import React from "react";
import PropTypes from "prop-types";
import Services from "services";
import Dashboard from "components/dashboard/Dashboard";
import sortDashboardUserMessages from "libs/pcap/utils/sortDashboardUserMessages";
import { isEmpty, findWhere } from "underscore";
import Analytics from "analytics";
import IframeClient from "partner/iframeClient";
import { promisify, subscribify } from "utils/service";
import { isEmpowerPaeMode } from "views/modules/sidebar/utils/accountUtils";
import GettingStartedOverview from "views/components/firstUse/GettingStartedOverview/GettingStartedOverview";
import { trackView } from "components/common/ComponentAnalytics";

const iframeClient = IframeClient.getInstance();
const completenessSessionKey = "COMPLETENESS_METER_RESPONSE";
const completenessChangeDelay = 1000;

export default class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: null,
      messagesResolved: false,
      promisesResolved: false,
      numOfManualAccounts: 0,
      accounts: [],
      person: {},
    };
    this.callPromises = this.callPromises.bind(this);
    this.onGetUserMessagesFailure = this.onGetUserMessagesFailure.bind(this);
    this.onGetUserMessagesSuccess = this.onGetUserMessagesSuccess.bind(this);
    this.onGetCompletenessMeterFailure =
      this.onGetCompletenessMeterFailure.bind(this);
    this.onGetCompletenessMeterSuccess =
      this.onGetCompletenessMeterSuccess.bind(this);
    this.onAccountsFetched = this.onAccountsFetched.bind(this);
    this.onAccountsFailure = this.onAccountsFailure.bind(this);
  }

  getFunnelAttributesPromise() {
    return new Promise((resolve, reject) => {
      this.props
        .getFunnelAttributes()
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
          Analytics.sendEngineeringEvent(
            "Error",
            "DashboardContainer.js: Services.Profile.getFunnelAttributes: " +
              JSON.stringify(errors)
          );
        });
    });
  }

  onGetUserMessagesFailure() {
    if (this.isUnmounted) {
      return;
    }

    // Don't block the UI when we couldn't fetch user messages.
    // We don't want to report errors while fetching user messages either
    // as the messages are not the essential function of the application.
    this.setState({ loading: false, messagesResolved: true });
    AppRouter.removePreloader();
  }

  onGetUserMessagesSuccess(spData) {
    if (this.isUnmounted) {
      return;
    }

    let rawMessages = spData.userMessages || [];
    const referralBanner = findWhere(rawMessages, {
      template: "TALKABLE_REFERRAL_BANNER",
    });
    let allMessages = sortDashboardUserMessages(rawMessages, (message) => {
      if (!isEmpowerPaeMode()) {
        this.props.updateUserMessages({
          userMessageIds: JSON.stringify([message.userMessageId]),
          actions: '["markViewed"]',
        });
      }
    });

    this.setState({
      loading: false,
      messages: allMessages.messages,
      dashboardMessages: allMessages.dashboardMessages,
      referralBanner: referralBanner,
      messagesResolved: true,
    });

    AppRouter.removePreloader();
  }

  onGetCompletenessMeterFailure() {
    if (this.isUnmounted) {
      return;
    }

    this.setState({ completenessMeterData: null, promisesResolved: true });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  onGetCompletenessMeterSuccess(spData) {
    if (this.isUnmounted) {
      return;
    }

    const isEmptyResponse = isEmpty(spData.meterInfo);
    const isComplete = spData.meterInfo.scorePercentage === 100;
    const isInSessionStorage = Boolean(
      sessionStorage.getItem(completenessSessionKey)
    );

    if ((!isInSessionStorage && isComplete) || isEmptyResponse) {
      this.setState({
        completenessMeterData: null,
        promisesResolved: true,
      });
    } else if (isInSessionStorage) {
      const storedResponse = JSON.parse(
        sessionStorage.getItem(completenessSessionKey)
      );
      this.setState(
        {
          completenessMeterData: storedResponse,
          promisesResolved: true,
        },
        () => {
          setTimeout(() => {
            if (this.isUnmounted) {
              return;
            }
            this.setState(
              {
                completenessMeterData: spData.meterInfo,
              },
              () => {
                sessionStorage.setItem(
                  completenessSessionKey,
                  JSON.stringify(spData.meterInfo)
                );
                if (isComplete) {
                  if (this.getCompletenessMeterSubscription) {
                    this.getCompletenessMeterSubscription.unwatch();
                    this.getCompletenessMeterSubscription.off("change");
                  }
                  setTimeout(() => {
                    if (this.isUnmounted) {
                      return;
                    }

                    spData.meterInfo.showFinalAnimation = true;
                    this.setState({
                      completenessMeterData: spData.meterInfo,
                    });
                    sessionStorage.removeItem(completenessSessionKey);
                  }, completenessChangeDelay);
                }
              }
            );
          }, completenessChangeDelay);
        }
      );
    } else {
      sessionStorage.setItem(
        completenessSessionKey,
        JSON.stringify(spData.meterInfo)
      );
      this.setState({
        completenessMeterData: spData.meterInfo,
        promisesResolved: true,
      });
    }
  }

  onAccountsFetched(response) {
    if (this.isUnmounted) {
      return;
    }
    //Process the number of manual and externally linked accounts
    let accounts = [];
    let numOfManualAccounts = this.state.numOfManualAccounts;
    if (response.accounts?.length > 0) {
      // Get all the manual accounts that are not closed and not a PCAP cash account
      const manualAccounts = response.accounts.filter(
        (account) =>
          account.isManual === true &&
          account.isOnUs === false &&
          !account.closedDate
      );
      numOfManualAccounts = manualAccounts.length;
      accounts = response.accounts;
    }
    this.setState({
      numOfManualAccounts: numOfManualAccounts,
      accounts: accounts,
    });
  }

  onAccountsFailure() {
    if (this.isUnmounted) {
      return;
    }

    this.setState({
      promisesResolved: true,
      loading: false,
    });
  }

  callPromises() {
    Promise.all([
      this.getFunnelAttributesPromise(),
      this.props.getPerson(),
      this.props.getAccounts(),
    ])
      .then(([funnelAttributes, person, accounts]) => {
        if (this.isUnmounted) {
          return;
        }

        this.onAccountsFetched(accounts);
        this.setState({ person, promisesResolved: true, loading: false });

        const isClient = funnelAttributes.isClient;
        if (isClient) {
          return;
        }

        this.getCompletenessMeterSubscription =
          this.props.getCompletenessMeter();
        this.getCompletenessMeterSubscription.on(
          "change",
          this.onGetCompletenessMeterSuccess
        );

        this.getCompletenessMeterSubscription.promise
          .then(this.onGetCompletenessMeterSuccess)
          .catch(this.onGetCompletenessMeterFailure);
      })
      .catch((errors) => {
        if (this.isUnmounted) {
          return;
        }

        this.setState({
          loading: false,
          errors: errors,
          promisesResolved: true,
        });
      });

    this.getUserMessagesSubscription = this.props.getUserMessages();
    this.getUserMessagesSubscription.on(
      "change",
      this.onGetUserMessagesSuccess
    );
    this.getUserMessagesSubscription.promise
      .then(this.onGetUserMessagesSuccess)
      .catch(this.onGetUserMessagesFailure);
  }

  componentDidMount() {
    trackView("Dashboard");
    this.callPromises();
  }

  componentDidUpdate(_, prevState) {
    if (IS_IFRAMED && prevState.loading !== this.state.loading) {
      iframeClient.triggerResize();
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    if (this.getUserMessagesSubscription) {
      this.getUserMessagesSubscription.unwatch();
      this.getUserMessagesSubscription.off("change");
    }

    if (this.getCompletenessMeterSubscription) {
      this.getCompletenessMeterSubscription.unwatch();
      this.getCompletenessMeterSubscription.off("change");
    }
  }

  render() {
    const {
      loading,
      errors,
      messages,
      referralBanner,
      messagesResolved,
      promisesResolved,
      dashboardMessages,
      completenessMeterData,
      numOfManualAccounts,
      accounts,
      person,
    } = this.state;
    const {
      useUnifiedMar,
      firstTimeForecastExperience,
      gettingStartedOverviewData: {
        enableGettingStartedOverview,
        notesForAdvisor,
        personName,
        personAge,
      },
    } = this.props;

    if (loading) {
      return null;
    }
    const numOfExternalAccounts =
      accounts.length === numOfManualAccounts
        ? 0
        : accounts.length - numOfManualAccounts;

    return (
      <>
        <Dashboard
          useUnifiedMar={useUnifiedMar}
          messages={messages}
          dashboardMessages={dashboardMessages}
          completenessMeterData={completenessMeterData}
          errors={errors}
          referralBanner={referralBanner}
          dashboardCallsResolved={messagesResolved && promisesResolved}
          numOfExternalAccounts={numOfExternalAccounts}
          firstTimeForecastExperience={firstTimeForecastExperience}
          person={person}
        />
        {enableGettingStartedOverview && (
          <GettingStartedOverview
            notesForAdvisor={notesForAdvisor}
            personName={personName}
            personAge={personAge}
          />
        )}
      </>
    );
  }
}

DashboardContainer.propTypes = {
  gettingStartedOverviewData: PropTypes.object,
  useUnifiedMar: PropTypes.bool,
  firstTimeForecastExperience: PropTypes.bool,
  getFunnelAttributes: PropTypes.func,
  getUserMessages: PropTypes.func,
  updateUserMessages: PropTypes.func,
  getCompletenessMeter: PropTypes.func,
  getAccounts: PropTypes.func,
  getPerson: PropTypes.func,
};

DashboardContainer.defaultProps = {
  gettingStartedOverviewData: {},
  useUnifiedMar: false,
  firstTimeForecastExperience: false,
  getFunnelAttributes: promisify(Services.Profile.getFunnelAttributes),
  updateUserMessages: promisify(Services.UserMessages.updateV2),
  getUserMessages: subscribify(Services.UserMessages.getV2),
  getCompletenessMeter: subscribify(Services.CompletenessMeter.get),
  getAccounts: promisify(Services.Accounts.get),
  getPerson: promisify(Services.Person.get),
};
