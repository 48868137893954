import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import Input from "components/common/form/Input";
import Select from "components/common/form/Select";
import suffixes from "enums/nameSuffixes";
import objectPath from "object-path";
import getAvailableRelationships from "views/modules/enrollmentNew/utils/getAvailableRelationships";
import { FIRST_NAME } from "utils/patterns";

const emptyOption = { value: "", label: "" };
const suffixesWithEmpty = [emptyOption, ...suffixes];

function getValidRelationships(people) {
  let relationships = getAvailableRelationships(people);

  // Self isn't included in people, so must remove it manually
  relationships = relationships.filter((r) => r.value !== "SELF");
  // Need an empty option so it doesn't default to a specific relationship
  relationships = [emptyOption, ...relationships];

  return relationships;
}

const INPUTS = [
  {
    type: "text",
    fieldName: "name.firstName",
    label: "First Name",
    placeholder: "First",
    maxLength: 19,
    isRequired: true,
    validator: {
      type: "string",
      allowEmpty: false,
      pattern: FIRST_NAME.source,
      messages: {
        allowEmpty: "This field is required.",
        pattern: "Name may not include special characters or numbers.",
      },
      maxLength: 19,
    },
    isFullWidth: true,
  },
  {
    type: "text",
    fieldName: "name.middleName",
    label: "Middle Initial",
    placeholder: "M.I.",
    maxLength: 1,
    isRequired: false,
    isFullWidth: false,
  },
  {
    type: "text",
    fieldName: "name.lastName",
    label: "Last Name",
    placeholder: "Last",
    maxLength: 26,
    isRequired: true,
    validator: {
      type: "string",
      allowEmpty: false,
      pattern: FIRST_NAME.source,
      messages: {
        allowEmpty: "This field is required.",
        pattern: "Name may not include special characters or numbers.",
      },
      maxLength: 26,
    },
    isFullWidth: true,
  },
  {
    type: "select",
    fieldName: "name.suffix",
    label: "Suffix",
    options: suffixesWithEmpty,
    isRequired: false,
    isFullWidth: false,
  },
];

export default class AddPersonView extends AbstractForm {
  constructor(props) {
    super(props);

    this.state = {
      model: {},
    };

    this.getTextInput = this.getTextInput.bind(this);
    this.getSelectInput = this.getSelectInput.bind(this);
    this.getInputFormGroup = this.getInputFormGroup.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getTextInput(input, onChange, validator) {
    const validatorObj = input.validator || validator.requiredField;
    return (
      <Input
        type="text"
        name={`${input.fieldName}`}
        ref={this.storeInputRef}
        placeholder={input.placeholder}
        maxLength={input.maxLength}
        sizeVariation={input.isFullWidth ? "full" : ""}
        onChange={onChange}
        validator={input.isRequired ? validatorObj : null}
      />
    );
  }

  getSelectInput(input, onChange, validator) {
    return (
      <Select
        name={`${input.fieldName}`}
        ref={this.storeInputRef}
        value={""}
        options={input.options}
        className={input.isFullWidth ? "input--full" : ""}
        onChange={onChange}
        validator={input.isRequired ? validator.requiredField : null}
      />
    );
  }

  getInputFormGroup(input, index) {
    const { validator } = this.props;

    return (
      <div className="pc-form-group" key={index}>
        <div className="pc-layout pc-layout--small">
          <div className="pc-layout__item pc-u-1/3">
            <label title={input.isRequired ? "Required" : ""}>
              {input.label}
            </label>
          </div>
          <div className="pc-layout__item pc-u-2/3">
            {input.type === "select"
              ? this.getSelectInput(input, this.handleInputChange, validator)
              : this.getTextInput(input, this.handleInputChange, validator)}
          </div>
        </div>
      </div>
    );
  }

  handleInputChange(ev) {
    const model = this.state.model;

    objectPath.set(model, ev.target.name, ev.target.value);
    this.setState({ model });
  }

  handleSubmit(ev) {
    ev.preventDefault();

    const validateResult = this.validate();

    if (validateResult.valid) {
      this.props.onSubmit(this.state.model);
    }
  }

  render() {
    const { people, onCancel, isPrivileged } = this.props;
    const isDisabled = (IS_ADVISOR && IS_EMPOWER) ? false : isPrivileged;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={"pc-u-pb"}>
          {INPUTS.map(this.getInputFormGroup)}
          {/* Relationship select needs access to props, so it can't live in the constant*/}
          {this.getInputFormGroup({
            type: "select",
            fieldName: "relationship",
            label: "Relationship",
            options: getValidRelationships(people),
            isRequired: true,
            isFullWidth: true,
          })}
        </div>

        <div className="l-spaced l-spaced--right pc-modal__footer edit-account-footer">
          <button
            type="button"
            className="pc-btn js-btn-cancel"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`pc-btn pc-btn--primary ${isDisabled ? "is-disabled" : ""}`}
            aria-disabled={isDisabled}
            data-testid="add-person-view-save"
          >
            Save
          </button>
        </div>
      </form>
    );
  }
}

AddPersonView.propTypes = {
  people: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isPrivileged: PropTypes.bool,
};

AddPersonView.defaultProps = {
  validator: {
    requiredField: {
      type: "string",
      allowEmpty: false,
      messages: {
        allowEmpty: "This field is required.",
      },
    },
  },
  isPrivileged: true,
};
