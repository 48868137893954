/**
 * Converts the supplied array of [form fields](https://personalcapital.jira.com/wiki/spaces/PFA/pages/22085658/Account#FormField)
 * into an object map of the corresponding `name` - `value` pairs.
 *
 * Example:
  ```
    {
      label: "Name",
      parts: [
        {
          type: "TEXT",
          name: "firstName",
          value: "Garry"
        }
      ]
    },
    {
      label: "Age",
      parts: [
        {
          type: "TEXT",
          name: "age",
          value: "3"
        }
      ]
    }
  ```
 * becomes `{ "firstName": "Garry", "age": "3" }`.
 *
 * @param {Array} formFields an array of [form fields](https://personalcapital.jira.com/wiki/spaces/PFA/pages/22085658/Account#FormField).
 *                           *NOTE:* Form fields should be processed via `scripts/accessors/formField/mappers/toClient.js` first.
 * @returns {Object} name-value map
 */
export function mapFieldValuesByName(formFields) {
  return formFields
    .map((f) =>
      f.parts.reduce(
        (result, { name, value }) => ({ ...result, [name]: value }),
        {}
      )
    )
    .reduce((result, obj) => ({ ...result, ...obj }), {});
}
