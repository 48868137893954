import React from "react";
import moment from "moment";
import { formatCurrency } from "libs/pcap/utils/format";
import DateUtils from "libs/pcap/utils/date";
import { compareAlpha } from "libs/pcap/table/data/compare";
import {
  compositeTimeComparator,
  compositeAlphaAndTimeComparator,
  compositeAmountAndTimeComparator,
  compositeTagsAndTimeComparator,
  compositeDescriptionAndTimeComparator,
} from "./sortingUtils";
import { tagSearchAccessor } from "../searchAccessors/searchAccessors";
import InfoTooltipText from "components/common/InfoTooltipText";
import { isEmpty, flatten } from "underscore";
import amountSummaryAccessor from "./amountSummaryAccessor";
import TooltipIcon from "components/common/TooltipIcon";

export const template =
  '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner pc-u-p-- tooltip-inner--large"></div></div>';

export default [
  {
    header: "Date",
    accessor: (d) => d.transactionDate,
    formatter: (d) => moment(d).format(DateUtils.DISPLAY_FORMAT),
    className: "pc-transactions-grid-cell--date qa-transaction-time",
    headerClassName: "pc-transactions-grid-cell--date qa-datagrid-date",
    summaryClassName: "pc-transactions-grid-cell--date",
    isSortable: true,
    sortOrder: "desc",
    summaryAccessor: () => "Total",
    comparator: compositeTimeComparator,
    isFilterable: false,
  },
  {
    header: "Account",
    accessor: (d) => d.accountName,
    titleAccessor: (d) => d.accountName,
    className:
      "pc-transactions-grid-cell--account-name u-clip-text qa-account-name",
    headerClassName:
      "pc-transactions-grid-cell--account-name qa-datagrid-account-name",
    summaryClassName: "pc-transactions-grid-cell--account-name",
    isSortable: true,
    comparator: compositeAlphaAndTimeComparator,
    isFilterable: true,
    isFilterAlignedToTheRight: true,
  },
  {
    header: "Description",
    accessor: (d) => d,
    titleAccessor: (d) => d.originalDescription,
    className:
      "pc-transactions-grid-cell--description u-clip-text qa-description",
    headerClassName:
      "pc-transactions-grid-cell--description qa-datagrid-description",
    summaryClassName: "pc-transactions-grid-cell--description",
    isSortable: true,
    comparator: compositeDescriptionAndTimeComparator,
    filterOptionsComparator: compositeAlphaAndTimeComparator,
    isFilterable: false,
    formatter: function description(transaction) {
      if (!transaction.imageUrl) {
        return transaction.description;
      }

      return (
        <div className="transaction-description__container">
          <div className="transaction-description__left u-clip-text">
            {transaction.description}
          </div>{" "}
          <div className="transaction-description__right">
            <TooltipIcon
              title={`<img class="transaction-description__image" alt="Transaction Image" src="${transaction.imageUrl}" />`}
              tooltipProps={{ template }}
            />
          </div>
        </div>
      );
    },
  },
  {
    header: "Category",
    headerPluralityOverrides: {
      // See `ColumnFilterToggle.js`
      // `Category` has more complicated plural rules than just adding an `s` on the end.
      plural: "Categories",
    },
    accessor: (d) => (isEmpty(d.splits) ? d.categoryName : ""),
    className:
      "pc-transactions-grid-cell--category u-clip-text qa-category-name",
    headerClassName:
      "pc-transactions-grid-cell--category qa-data-grid-category",
    summaryClassName: "pc-transactions-grid-cell--category",
    isSortable: true,
    comparator: compositeAlphaAndTimeComparator,
    filterAccessor: (d) =>
      d.splits ? d.splits.map((s) => s.categoryName) : [d.categoryName],
    filterOptionsComparator: compareAlpha,
    isFilterable: true,
  },
  {
    header: "Tags",
    headerPluralityOverrides: {
      // See `ColumnFilterToggle.js`
      // Tags is already plural in the header, so we need the singular.
      singular: "Tag",
      // But after that, we can pluralize in the default way by adding `s`, so no need for a plural.
    },
    accessor: (d) => d,
    className: "pc-transactions-grid-cell--tags u-clip-text",
    headerClassName: "pc-transactions-grid-cell--tags qa-datagrid-tags",
    summaryClassName: "pc-transactions-grid-cell--tags",
    isSortable: true,
    comparator: compositeTagsAndTimeComparator,
    formatter: function tags(transaction) {
      //If the transaction is duplicate, then we should show duplicate here instead of tags
      if (transaction.isDuplicate) {
        return <span>(Duplicate)</span>;
      }

      if (!isEmpty(transaction.splits)) {
        return null;
      }

      if (!isEmpty(transaction.selectedTagNames)) {
        let tagsText = transaction.selectedTagNames[0];
        if (transaction.selectedTagNames.length > 1) {
          // eslint-disable-next-line no-magic-numbers
          tagsText = `${tagsText.slice(0, 4)}...,(${
            transaction.selectedTagNames.length
          })`;
        }
        return (
          <InfoTooltipText
            text={tagsText}
            className="qa-tag-name"
            tooltipContent={transaction.selectedTagNames.join(", ")}
            tooltipProps={{ placement: "left" }}
          ></InfoTooltipText>
        );
      }
      return "";
    },
    isFilterable: true,
    filterAccessor: (d) =>
      d.splits
        ? flatten(d.splits.map((s) => s.selectedTagNames))
        : d.selectedTagNames,
    // The list of tags displayed in the filter dropdown needs simpler sorting than the data column itself.
    filterOptionsComparator: compareAlpha,
  },
  {
    header: "Amount",
    accessor: (transaction) => transaction,
    className:
      "pc-transactions-grid-cell--amount pc-transactions-grid-cell--amount-parent u-text-right qa-value tabular-numbers",
    headerClassName:
      "pc-transactions-grid-cell--amount table__column--right qa-datagrid-amount",
    summaryClassName:
      "pc-transactions-grid-cell--amount u-text-right qa-transactions-total-amount",
    isSortable: true,
    comparator: compositeAmountAndTimeComparator,
    formatter: function amount(transaction) {
      const value = transaction.isCredit
        ? transaction.amount
        : transaction.amount * -1;
      return (
        <span
          title={transaction.isCredit ? "Credit" : "Debit"}
          className={value > 0 ? "positive" : ""}
        >
          {formatCurrency(value)}
        </span>
      );
    },
    summaryFormatter: formatCurrency,
    summaryAccessor: amountSummaryAccessor,
    isFilterable: false,
  },
];

export const buildDataForExportToCsvDefaultColumns = function (transactions) {
  if (transactions != null) {
    return transactions
      .filter((t) => t.status !== "pending")
      .map((transaction) => {
        const { isCredit, amount } = transaction;
        const signedAmount = isCredit ? amount : amount * -1;
        const tags = tagSearchAccessor(transaction, ", ");
        // prevent comma-separated tags string from breaking into cells
        const safeTags = tags ? `"${tags}"` : "";
        const safeAccountName = `"${transaction.accountName}"`;
        const safeDescription = `"${transaction.description}"`;
        const safeCategoryName = `"${transaction.categoryName}"`;
        return [
          transaction.transactionDate,
          safeAccountName,
          safeDescription,
          safeCategoryName,
          safeTags,
          signedAmount,
        ];
      });
  }
  return [];
};
