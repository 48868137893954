import Handlebars from "handlebars/runtime";
import moment from "moment";

export default function (timestamp, disableFromNow) {
  var output;
  if (timestamp != null) {
    var duration = moment.duration(new Date().getTime() - timestamp);
    if (duration.asHours() <= 24 && disableFromNow !== true) {
      output = '<span data-livestamp="' + timestamp / 1000 + '">';
      output += moment(timestamp).fromNow();
      output += "</span>";
    } else {
      output = moment(timestamp).format("M/D/YYYY");
    }
    return new Handlebars.SafeString(output);
  }

  return "";
}
