import Sticky from "waypoints/lib/shortcuts/sticky";
import _ from "underscore";

const MAIN_NAV_HEIGHT = 48;
const LEFT_OFFSET = 294;
const SECOND_NAV_HEIGHT = 61;
const TABLE_LOAD_TIME = 1000;
const ADVISOR_HEADER_OFFSET = 60;
const ADVISOR_APP_NAV_HEIGHT = 100;

/**
 * Adjusts horizontal position of the watched elements with fixed position
 * to follow the scroll of the page content.
 *
 * @class HorizontalScroller
 */
class HorizontalScroller {
  constructor(options) {
    // use a unique namespace to avoid the removal of event handlers
    // set by other instances of `HorizontalScroller` class
    this.id = _.uniqueId("HorizontalScroller");

    this.elements = new Set();
    this.offset = options.offset;
    this.window = $(window);

    this.scrollLeft = this.window.scrollLeft();
    this.window.on(`scroll.${this.id}`, () => {
      var currentScrollLeft = this.window.scrollLeft();
      // skip if scrolling vertically
      if (this.scrollLeft === currentScrollLeft) {
        return;
      }

      this.scrollLeft = currentScrollLeft;
      this.elements.forEach((el) => {
        let left;
        if (currentScrollLeft === 0) {
          // reset the position when there is no scroll
          left = null;
        } else {
          left = this.offset - currentScrollLeft + "px";
        }
        el.style.left = left;
      });
    });
  }

  watch(element) {
    // adjust the left position initially if the window has been already scrolled to the right
    if (this.scrollLeft !== 0) {
      element.style.left = this.offset - this.scrollLeft + "px";
    }
    this.elements.add(element);
  }

  unwatch(element) {
    this.elements.delete(element);
  }

  destroy() {
    this.elements.clear();
    this.window.off(`.${this.id}`);
    this.window = null;
  }
}

/**
 * Provides methods for affixing the navigational elements to the viewport.
 */
export default {
  // Make the fixed controller scroll horizontally
  initializeHorizontalScroller(el) {
    if (!this.horizontalScroller) {
      this.horizontalScroller = new HorizontalScroller({
        offset: LEFT_OFFSET,
      });
    }
    this.horizontalScroller.watch(el);
  },

  initializeAllFixedControls() {
    this.initializeSecondaryNav();
    this.initializeFeatureControls();
  },

  initializeSecondaryNav() {
    let secondaryNav = this.el.querySelector(".js-secondary-nav");
    if (secondaryNav && !this.stickySecondaryNav) {
      this.stickySecondaryNav = new Sticky({
        element: secondaryNav,
        stuckClass: "is-stuck",
        offset: window.isAdvisorApp ? ADVISOR_APP_NAV_HEIGHT : MAIN_NAV_HEIGHT,
      });
      this.initializeHorizontalScroller(secondaryNav);
    }
  },

  initializeFixedTableHeader() {
    if (this.el && this.el.querySelector) {
      let tableHeader = this.el.querySelector(".js-fixed-table-header");
      if (tableHeader && !this.stickyTableHeader) {
        $(tableHeader).addClass("is-hidden");
        this.stickyTableHeader = new Sticky({
          element: tableHeader,
          stuckClass: "is-stuck",
          offset: window.isAdvisorApp ? ADVISOR_HEADER_OFFSET : 0,
          handler: function (scrollDirection) {
            if (scrollDirection === "down") {
              this.$element.removeClass("is-hidden");
              let originalHeaderColumns = $(
                ".js-fixed-original-table-header th"
              );
              this.$element
                .find("th")
                .each(function (columnIndex, tableHeaderColumn) {
                  $(tableHeaderColumn).width(
                    $(originalHeaderColumns[columnIndex]).width()
                  );
                });
              return;
            }
            this.$element.addClass("is-hidden");
          },
          wrapper: false,
        });
        this.initializeHorizontalScroller(tableHeader);
        setTimeout(this.refreshStickyTableHeader.bind(this), TABLE_LOAD_TIME);
        return;
      }
    }
  },

  initializeFeatureControls() {
    let featureControls = this.el.querySelector(".js-feature-controls");
    if (featureControls && !this.stickyFeatureControls) {
      this.stickyFeatureControls = new Waypoint.Sticky({
        element: featureControls,
        stuckClass: "is-stuck",
        offset: MAIN_NAV_HEIGHT + SECOND_NAV_HEIGHT,
        handler: function (direction) {
          var featureControlSelector = this.stickyFeatureControls.$element;
          this.trigger(
            "featurecontrolstuck",
            direction,
            featureControlSelector
          );
        }.bind(this),
      });
      this.initializeHorizontalScroller(featureControls);
    }
  },

  /**
   * This method forces a recalculation of the trigger point of the feature controls.
   * If you make changes to the DOM, CSS, or anything that may effect the layout and positioning
   * of elements on the page, you need to call this method.
   */
  refreshStickyFeatureControls() {
    if (this.stickyFeatureControls) {
      this.stickyFeatureControls.waypoint.context.refresh();
    }
  },

  refreshStickyTableHeader() {
    if (this.stickyTableHeader) {
      this.stickyTableHeader.waypoint.context.refresh();
    }
  },

  destroyStickyTableHeader() {
    if (this.stickyTableHeader) {
      this.stickyTableHeader.destroy();
      this.stickyTableHeader = null;
    }
  },
  destroyFixedControls() {
    this.destroySecondaryNav();
    this.destroyFeatureControls();
  },

  destroySecondaryNav() {
    if (this.stickySecondaryNav) {
      this.stickySecondaryNav.destroy();
    }
    if (!this.stickyFeatureControls) {
      this.destroyHorizontalScroller();
    }
  },

  destroyFeatureControls() {
    if (this.stickyFeatureControls) {
      this.stickyFeatureControls.destroy();
    }
    if (!this.stickySecondaryNav) {
      this.destroyHorizontalScroller();
    }
  },

  destroyHorizontalScroller() {
    if (this.horizontalScroller) {
      this.horizontalScroller.destroy();
      this.horizontalScroller = null;
    }
  },

  /**
   * Disable and Enable functions are used when there are multiple views with duplicate waypoints
   * to prevent them from double/triple/quadruple triggering.
   */
  disableFixedControls() {
    if (this.stickySecondaryNav) {
      this.stickySecondaryNav.waypoint.disable();
      if (this.horizontalScroller) {
        this.horizontalScroller.unwatch(this.stickySecondaryNav.element);
      }
    }
    if (this.stickyFeatureControls) {
      this.stickyFeatureControls.waypoint.disable();
      this.horizontalScroller.unwatch(this.stickyFeatureControls.element);
    }
  },

  enableFixedControls() {
    if (this.stickySecondaryNav) {
      this.stickySecondaryNav.waypoint.enable();
      if (this.horizontalScroller) {
        this.horizontalScroller.watch(this.stickySecondaryNav.element);
      }
    }
    if (this.stickyFeatureControls) {
      this.stickyFeatureControls.waypoint.enable();
      if (this.horizontalScroller) {
        this.horizontalScroller.watch(this.stickyFeatureControls.element);
      }
    }
  },
};
