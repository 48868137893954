import PropTypes from "prop-types";
import React from "react";
import Message from "components/common/Message";
import AbstractForm from "components/common/form/AbstractForm";
import Input from "components/common/form/Input";
import { SET_MASTER_PLAN_VIEW } from "components/financialPlanner/financialPlannerConstants";
import { trackClick } from "components/common/ComponentAnalytics";
import { showMakePlanOfficial } from "./utils";

export default class EditPlanView extends AbstractForm {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSetPlanLinkClick = this.handleSetPlanLinkClick.bind(this);

    this.state = {
      model: {
        name: props.plan.planName || "My Plan",
      },
    };
  }

  handleSubmit(event) {
    event.preventDefault();

    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.renamePlan(this.state.model.name);
    }
  }

  handleDelete(e) {
    this.props.onDelete(this.props.plan.planId);

    // Track Delete button click event.
    trackClick(e, "Retirement Planner", "Delete Scenario");
  }

  render() {
    const { plan, errors, accounts, isGMWB } = this.props;
    const showMakeOfficialBtn = showMakePlanOfficial(
      plan,
      accounts,
      isGMWB,
      window.isAdvisorApp,
      IS_EMPOWER
    );

    return (
      <form onSubmit={this.handleSubmit}>
        {errors && <Message severity="error" messages={errors} />}
        <div className="pc-form-group pc-layout pc-u-mb+">
          <div className="pc-layout__item">
            <label>
              Name
              <Input
                type="text"
                name="name"
                className="js-plan-name"
                placeholder="plan name"
                ref={this.storeInputRef}
                maxLength={50}
                onChange={this.handleInputChange}
                value={this.state.model.name}
                sizeVariation="full"
                validator={this.props.schema.properties.value}
              />
            </label>
          </div>
          {showMakeOfficialBtn && (
            <button
              className="js-set-master-plan pc-u-mt pc-u-ml pc-btn pc-btn--link"
              onClick={this.handleSetPlanLinkClick}
            >
              Make this my Official Plan »
            </button>
          )}
        </div>
        <div className="u-text-right">
          <button
            type="button"
            className="pc-btn js-cancel-btn qa-cancel-btn"
            onClick={this.props.onClosed}
          >
            Cancel
          </button>
          {!this.props.plan.isMasterPlan && (
            <button
              type="button"
              className="pc-btn js-delete-btn qa-delete-btn"
              onClick={this.handleDelete}
            >
              Delete
            </button>
          )}
          <button
            type="submit"
            className="pc-btn pc-btn--primary js-done-btn qa-done-btn"
          >
            Done
          </button>
        </div>
      </form>
    );
  }

  handleSetPlanLinkClick(event) {
    event.preventDefault();
    this.props.onViewChange(SET_MASTER_PLAN_VIEW);

    // Track Make This Official button click event.
    trackClick(event, "Retirement Planner", "Make This Master Plan");
  }
}

EditPlanView.propTypes = {
  onClosed: PropTypes.func,
  onViewChange: PropTypes.func,
  onDelete: PropTypes.func,
  plan: PropTypes.object,
  errors: PropTypes.array,
  isGMWB: PropTypes.bool,
  accounts: PropTypes.array,
};

EditPlanView.defaultProps = {
  isGMWB: false,
  accounts: [],
  schema: {
    type: "object",

    properties: {
      value: {
        allowEmpty: false,
        type: "string",
      },
    },
  },
};
