var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<p>\n  A Customer Support Specialist will get back to you<br />\n  with an answer as soon as possible.\n</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<p>\n  Thank you for submitting your request. We are constantly working on adding<br />\n  support for new financial institutions, however, we cannot give you an ETA\n  for<br />\n  when this site will be added. Please check back periodically.\n</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"title\">We have received your request</h2>\n<div class=\"requestConfirmationImage\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isUserClient") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":15,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"buttonContainer\">\n  <a\n    class=\"selectDifferentInstitution pc-btn pc-u-ph+\"\n    href=\"#\"\n    >Link Another</a\n  >\n</div>\n<div class=\"buttonContainer\">\n  <a\n    class=\"allCustomAccountsButton pc-btn pc-u-ph+\"\n    href=\"#\"\n    >Add Manual Account</a\n  >\n</div>\n";
},"useData":true});