import PropTypes from "prop-types";
import React from "react";
import ChartReact from "libs/pcap/chart/chart--react";
import { max, select } from "d3";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { formatCurrency } from "libs/pcap/utils/format";
import { trackEvent } from "components/common/ComponentAnalytics";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";
import objectPath from "object-path";

const COMPONENT_NAME = "insights";
const MONTHS_PER_YEAR = 12;
const PROJECTED_BAR_LABEL_Y_OFFSET = 13;
const DESIRED_BAR_LABEL_Y_OFFSET = 7;
const RETIREMENT_SPENDING_GOAL_KEY = "RETIREMENT_SPENDING";

function xAccessor(item) {
  return item.x;
}

function yAccessor(item) {
  return item.y;
}

class RetirementPlanSpending extends React.Component {
  constructor(props) {
    super(props);
    const jsonContent =
      objectPath.get(props, "message.resources.0.jsonContent") || {};
    const retirementSpendingGoal =
      (jsonContent.goals || []).find(
        (goal) => goal.goalCategoryKey === RETIREMENT_SPENDING_GOAL_KEY
      ) || {};
    const monthlyProjectedSpending = Math.round(
      (jsonContent.annualProjectedSpending || 0) / MONTHS_PER_YEAR
    );
    const monthlyDesiredSpending = Math.round(
      (retirementSpendingGoal.annualAmount || 0) / MONTHS_PER_YEAR
    );
    this.state = {
      projectedBarChartData: [{ x: 0, y: monthlyProjectedSpending }],
      desiredBarChartData: [{ x: 0, y: monthlyDesiredSpending }],
      yDomain: [0, max([monthlyProjectedSpending, monthlyDesiredSpending])],
    };
  }

  componentDidMount() {
    const { isFirstRendered, message } = this.props;
    this.props[isFirstRendered ? "markAsImpressed" : "markAsViewed"](
      message.userMessageId
    );
    trackEvent(COMPONENT_NAME, "View Retirement Planner Forecast Insight");
    this.addBarAmountLabels();
  }

  addBarAmountLabels() {
    const projectedSpendingBar = select(
      ".insights-graph--retirement-plan-spending-projected .chart__bar"
    );
    const projectedSpendingAmount = projectedSpendingBar.datum().y;
    const projectedSpendingBarY = this.chartReactProjected.chart.yScale(
      projectedSpendingAmount
    );
    const desiredSpendingBar = select(
      ".insights-graph--retirement-plan-spending-desired .chart__bar"
    );
    const desiredSpendingBarAmount = desiredSpendingBar.datum().y;
    const desiredSpendingBarY = this.chartReactDesired.chart.yScale(
      desiredSpendingBarAmount
    );

    // Add projected spending bar label
    select(".insights-graph--retirement-plan-spending-projected")
      .append("text")
      .classed("insights-chart-label insights-chart-label--dark pc-delta", true)
      .attr("y", projectedSpendingBarY - PROJECTED_BAR_LABEL_Y_OFFSET)
      .attr("x", "50%")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "central") // Firefox does not support alignment-baseline
      .attr("dominant-baseline", "central") //  use dominant-baseline instead
      .text(formatCurrency(projectedSpendingAmount, 0));

    // Add desired spending bar label
    select(".insights-graph--retirement-plan-spending-desired")
      .append("text")
      .classed("insights-chart-label insights-chart-label--dark pc-delta", true)
      .attr("y", desiredSpendingBarY - DESIRED_BAR_LABEL_Y_OFFSET)
      .attr("x", "50%")
      .text(formatCurrency(desiredSpendingBarAmount, 0));
  }

  render() {
    const { message = {}, markAsViewed } = this.props;
    const shareChartProps = {
      type: "bar",
      interpolate: "monotone",
      showXGrid: false,
      showYGrid: false,
      showYAxis: false,
      showXAxis: false,
      showYZeroLine: false,
      margin: { bottom: 0, top: 0 },
      x: xAccessor,
      y: yAccessor,
      yDomain: this.state.yDomain,
    };

    return (
      <div className="insight__message">
        <div
          className="insight__header qa-insight-header"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div
          className="insight__summary"
          dangerouslySetInnerHTML={{ __html: message.summary }}
        />
        <div className="insight__summary">
          <div className="pc-layout pc-layout--flush">
            <div className="pc-layout__item pc-u-1/5 insight__chart-container insight__chart-container--retirement-plan-spending">
              <label className="retirement-plan-spending--top-label">
                Projected
              </label>
              <ChartReact
                className="insights-graph--retirement-plan-spending insights-graph--retirement-plan-spending-projected"
                ref={(el) => {
                  this.chartReactProjected = el;
                }}
                data={[this.state.projectedBarChartData]}
                {...shareChartProps}
              />
            </div>
            <div className="pc-layout__item pc-u-1/5 insight__chart-container insight__chart-container--retirement-plan-spending insight__chart-container--retirement-plan-desired-spending">
              <label className="retirement-plan-spending--top-label retirement-plan-spending--top-label-desired">
                Desired
              </label>
              <ChartReact
                className="insights-graph--retirement-plan-spending insights-graph--retirement-plan-spending-desired"
                ref={(el) => {
                  this.chartReactDesired = el;
                }}
                data={[this.state.desiredBarChartData]}
                {...shareChartProps}
              />
            </div>
          </div>
          <div className="retirement-plan-spending--bottom-label">
            Monthly Retirement Spending
            <br />
            Ability
            <InfoTooltipIcon title="An estimate of the amount you can spend in retirement with a low risk of depleting your portfolio. It includes expected income and portfolio withdrawals. We use the median projection to compare to the amount you plan to spend." />
          </div>
          <div className="insight__buttons">
            {getActionButtons({
              message,
              markAsViewed,
              appointmentReason: "Retirement Plan Spending",
            })}
          </div>
        </div>
      </div>
    );
  }
}

RetirementPlanSpending.propTypes = {
  message: PropTypes.object,
  markAsViewed: PropTypes.func,
  markAsImpressed: PropTypes.func,
  isFirstRendered: PropTypes.bool,
};

export default withTooltips(RetirementPlanSpending);
