/* eslint-disable camelcase */
import ReactDOM from "react-dom";
import React from "react";
import objectPath from "object-path";
import * as VisibleUserMessages from "views/components/postLoginActions/VisibleUserMessages";
import {
  renderContentComponent,
  renderNotification,
} from "./PostLoginActionsUtils";
import { trackUserMessageViewEvent } from "./helpers";
import { noop } from "underscore";
import isPostLoginActionPreview from "libs/pcap/utils/isPostLoginActionPreview";
import { getJsonContent } from "views/components/postLoginActions/helpers";
import {
  PCAP_CASH_MODAL_WIDTH,
  MODAL_TITLE_BLANK,
  MEDIUM_MODAL_WIDTH,
  IC_MODAL_WIDTH,
  DEFAULT_MODAL_WIDTH,
  EXCLUDE_USER_FROM_INTERJECTIONS,
  PREMIUM_SUBSCRIPTION_MODAL_WIDTH,
  EMPOWER_MODAL_WIDTH,
  EMPOWER_MODAL_MEDIUM_WIDTH,
  SMEDIUM_MODAL_WIDTH,
  LARGE_MODAL_WIDTH,
} from "views/components/postLoginActions/constants";

const CLASSNAME_LIGHT_TITLE_CENTERED = " pc-modal--light-title-centered";
const CLASSNAME_ADVICE_DISCLAIMER_CENTERED =
  " pc-modal--advice-disclaimer-centered";
const CLASSNAME_ADVICE_DISCLAIMER_RIGHT = " pc-modal--advice-disclaimer-right";
const SURVEY_MODAL_WIDTH = 770;

export const renderUserMessage = ({
  userMessage,
  showTimeAgo = false,
  showFeedbackForm = false,
  className = "",
  // eslint-disable-next-line sonarjs/cognitive-complexity
} = {}) => {
  const viewTemplate = userMessage.viewTemplate;
  const userMessageId = userMessage.userMessageId;
  const messageTemplateName = userMessage.template;
  const userMessageFirstDisplayLocation = objectPath.get(
    userMessage,
    "displayLocations.0"
  );

  VisibleUserMessages.add(userMessage);

  let modalComponentName = "PLA";
  let loadingModalTitle = "";
  if (className) {
    className = className + " ";
  }

  // Post Login Action detail info to be sent with events to Mixpanel
  const plaDetails = {
    view_template: viewTemplate || "DEFAULT",
    message_template: userMessage.template,
    user_message_id: userMessageId,
    user_message_title: userMessage.title,
  };
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (viewTemplate) {
    case "ADVISOR":
      require([
        "views/components/postLoginActions/AdvisorNotificationPLA",
      ], function ({ default: ContentComponent }) {
        const logo = true;
        className += "pc-modal--with-background-image";
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          IC_MODAL_WIDTH,
          logo
        );
      });
      break;
    case "CLASSIC": // Not used
      require([
        "views/components/postLoginActions/notificationView",
      ], function ({ default: CurrentNotificationView }) {
        renderNotification(
          CurrentNotificationView,
          viewTemplate,
          messageTemplateName,
          userMessage,
          plaDetails
        );
      });
      break;
    case "LINK_ACCOUNT":
      require([
        "views/components/postLoginActions/linkAccountNotificationView",
      ], function ({ default: CurrentNotificationView }) {
        renderNotification(
          CurrentNotificationView,
          viewTemplate,
          messageTemplateName,
          userMessage,
          plaDetails
        );
      });
      break;
    case "RETIREMENT_PLANNER": // Not used
      require([
        "views/components/postLoginActions/retirementPlannerView",
      ], function ({ default: CurrentNotificationView }) {
        renderNotification(
          CurrentNotificationView,
          viewTemplate,
          messageTemplateName,
          userMessage,
          plaDetails
        );
      });
      break;
    case "DYNAMIC_IC_PLA":
      modalComponentName = "Investment Checkup Lite PLA";
      loadingModalTitle = "Examining your portfolio...";
      className += "pc-modal--ghost-white";
      require([
        "views/components/postLoginActions/InvestmentCheckupLite/InvestmentCheckupLiteContainer",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          IC_MODAL_WIDTH
        );
      });
      break;
    case "FP_QUESTIONNAIRE":
      modalComponentName = "FP Topic Questionnaire PLA";
      require([
        "components/ClientJourney/TopicQuestionnaire/FPQuestionnaireModal",
      ], function ({ default: ContentComponent }) {
        const mountNode = document.createElement("div");
        document.body.appendChild(mountNode);
        const FPQuestionnaireModal = ContentComponent;

        ReactDOM.render(
          <FPQuestionnaireModal
            userType={
              messageTemplateName === "FP_QUESTIONNAIRE_QUALIFIED"
                ? "PROSPECT"
                : "NEW_CLIENT"
            }
            componentName="Client Journey Topic Questionnaire - PLA"
            isOpen={true}
            onDone={() => {
              ReactDOM.unmountComponentAtNode(mountNode);
            }}
          />,
          mountNode
        );
      });
      break;
    case "INVESTMENT_CHECKUP_OUTSIDE_ASSETS":
      loadingModalTitle = MODAL_TITLE_BLANK;
      className += "pc-modal--ghost-white pc-modal--iclite";
      require([
        "views/components/postLoginActions/InvestmentCheckupLite/InvestmentCheckupLiteContainer",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          IC_MODAL_WIDTH
        );
      });
      break;
    case "IN_APP_MODAL_URL":
      if (
        userMessageFirstDisplayLocation &&
        userMessageFirstDisplayLocation.indexOf("#") === 0
      ) {
        // Display Location must start with a #
        trackUserMessageViewEvent({ userMessage, plaDetails });
        window.location.href = userMessageFirstDisplayLocation;
      }
      return;
    case "IDLE_CASH_PLA":
      modalComponentName = "Idle Cash PLA";
      loadingModalTitle = MODAL_TITLE_BLANK;
      className += CLASSNAME_ADVICE_DISCLAIMER_CENTERED;
      require([
        "views/components/postLoginActions/IdleCashContainer",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          DEFAULT_MODAL_WIDTH
        );
      });
      break;
    case "FEE_ANALYZER_PLA":
      modalComponentName = "Fee Analyzer PLA";
      loadingModalTitle = MODAL_TITLE_BLANK;
      className += CLASSNAME_ADVICE_DISCLAIMER_CENTERED;
      require(["views/components/postLoginActions/FeeAnalyzerPLA"], function ({
        default: ContentComponent,
      }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          DEFAULT_MODAL_WIDTH
        );
      });
      break;
    case "CHECKLIST_PLA":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      className += CLASSNAME_ADVICE_DISCLAIMER_CENTERED;
      require(["views/components/postLoginActions/ChecklistPLA"], function ({
        default: ContentComponent,
      }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          DEFAULT_MODAL_WIDTH
        );
      });
      break;
    case "REFERRAL_CENTER_BLAST":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      className += CLASSNAME_ADVICE_DISCLAIMER_CENTERED;
      require(["views/components/postLoginActions/ReferralBlast"], function ({
        default: ContentComponent,
      }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          MEDIUM_MODAL_WIDTH
        );
      });
      break;
    case "SOCIAL_RESPONSIBLE_INVESTING":
      modalComponentName = userMessage.template;
      loadingModalTitle = userMessage.title;
      className +=
        CLASSNAME_ADVICE_DISCLAIMER_CENTERED + CLASSNAME_LIGHT_TITLE_CENTERED;
      require([
        "views/components/postLoginActions/SociallyResponsibleInvestingPLA",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          MEDIUM_MODAL_WIDTH
        );
      });
      break;
    case "BOX_LIST_TO_CTA":
      modalComponentName = `${userMessage.template}${
        userMessage.resources[0].jsonContent.ab
          ? "--" + userMessage.resources[0].jsonContent.ab
          : ""
      }`;
      loadingModalTitle = userMessage.title;
      className +=
        CLASSNAME_ADVICE_DISCLAIMER_CENTERED + CLASSNAME_LIGHT_TITLE_CENTERED;
      require(["views/components/postLoginActions/BoxListToCtaPLA"], function ({
        default: ContentComponent,
      }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          DEFAULT_MODAL_WIDTH
        );
      });
      break;
    case "PCAP_CASH_PLA":
      if (EXCLUDE_USER_FROM_INTERJECTIONS) {
        return;
      }
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      className += "pcap-cash-pla";
      require(["views/components/postLoginActions/PcapCashPLA"], function ({
        default: ContentComponent,
      }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          PCAP_CASH_MODAL_WIDTH
        );
      });
      break;
    case "SURVEY":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      className += "survey-pla";
      require(["views/components/postLoginActions/SurveyPLA"], function ({
        default: ContentComponent,
      }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          SURVEY_MODAL_WIDTH
        );
      });
      break;
    case "TEXT_WITH_IMG_WIDE":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      require([
        "views/components/postLoginActions/TextWithImgWidePLA/TextWithImgWidePLA",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          IC_MODAL_WIDTH
        );
      });
      break;
    case "TEXT_WITH_IMG_WIDE_EMPOWER":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      require([
        "views/components/postLoginActions/TextWithImgWideEmpowerPLA/TextWithImgWideEmpowerPLA",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          LARGE_MODAL_WIDTH
        );
      });
      break;

    case "TEXT_WITH_IMG_WIDE_V2":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      require([
        "views/components/postLoginActions/TextWithImgWideV2/TextWithImgWideV2",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          LARGE_MODAL_WIDTH
        );
      });
      break;
    case "SINGLE_IMAGE_PLA":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      require(["views/components/postLoginActions/SingleImagePLA"], function ({
        default: ContentComponent,
      }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          MEDIUM_MODAL_WIDTH
        );
      });
      break;
    case "MARKET_DROP_FOMO_PLA":
      modalComponentName = userMessage.template;
      loadingModalTitle = userMessage.title;
      className +=
        CLASSNAME_ADVICE_DISCLAIMER_CENTERED + CLASSNAME_LIGHT_TITLE_CENTERED;
      require(["views/components/postLoginActions/MarketDropFomo"], function ({
        default: ContentComponent,
      }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          DEFAULT_MODAL_WIDTH
        );
      });
      break;
    case "CUSTOM_APPOINTMENT_SCHEDULER":
      require(["libs/pcap/utils/customAppointmentSchedulerUtils"], function ({
        handleOneTimeCustomContent,
      }) {
        handleOneTimeCustomContent(userMessage);
      });
      break;
    case "TrackAccountsOrPickATimeModal":
      require([
        "components/interjection/TrackAccountsOrPickATime/TrackAccountsOrPickATimeModal",
      ], function ({ default: TrackAccountsOrPickATimeModal }) {
        let mountNode = document.createElement("div");
        document.body.appendChild(mountNode);
        ReactDOM.render(
          <TrackAccountsOrPickATimeModal
            onModalClosed={noop}
            interjection={userMessage}
          />,
          mountNode
        );
      });
      break;
    case "PREMIUM_SUBSCRIPTION":
      require([
        "views/components/postLoginActions/PremiumSubscription/PremiumConciergePLA",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        loadingModalTitle = MODAL_TITLE_BLANK;
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          PREMIUM_SUBSCRIPTION_MODAL_WIDTH
        );
      });
      break;
    case "PAY_WHAT_YOU_WANT":
      require([
        "views/components/postLoginActions/Subscription/PayWhatYouWantPLA",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        loadingModalTitle = MODAL_TITLE_BLANK;
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          IC_MODAL_WIDTH
        );
      });
      break;
    case "2007_MARKET_SURVEY":
      require([
        "views/components/postLoginActions/MarketSurvey/MarketSurveyPLA",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        loadingModalTitle = MODAL_TITLE_BLANK;
        className = "market-survey-pla";
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          DEFAULT_MODAL_WIDTH
        );
      });
      break;
    case "USER_QUIZ":
      require([
        "views/components/postLoginActions/UserQuiz/UserQuizPLA",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        loadingModalTitle = MODAL_TITLE_BLANK;
        className = "user-quiz-pla";
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          MEDIUM_MODAL_WIDTH
        );
      });
      break;
    case "USER_SURVEY":
      require([
        "views/components/postLoginActions/UserSurvey/UserSurveyPLA",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        loadingModalTitle = MODAL_TITLE_BLANK;
        className = "user-survey-pla";
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          MEDIUM_MODAL_WIDTH
        );
      });
      break;
    case "RECURRING_SURVEY":
      require([
        "views/components/postLoginActions/UserSurvey/RecurringSurvey/RecurringSurveyPLA",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        loadingModalTitle = MODAL_TITLE_BLANK;
        className = "recurring-survey";
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          DEFAULT_MODAL_WIDTH
        );
      });
      break;
    case "TEXT_WITH_PROJECTION_CHART":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      require([
        "views/components/postLoginActions/ProjectionChartPLA",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          IC_MODAL_WIDTH
        );
      });
      break;
    case "INVESTMENT_CHECKUP_OUTSIDE_ASSETS_2":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      className +=
        CLASSNAME_ADVICE_DISCLAIMER_CENTERED + CLASSNAME_LIGHT_TITLE_CENTERED;
      require([
        "views/components/postLoginActions/InvestmentCheckupOutsideAssets",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          MEDIUM_MODAL_WIDTH
        );
      });
      break;
    case "IDLE_CASH_PLA_2":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      className +=
        CLASSNAME_ADVICE_DISCLAIMER_CENTERED + CLASSNAME_LIGHT_TITLE_CENTERED;
      require([
        "views/components/postLoginActions/IdleCash/IdleCashPLA",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          DEFAULT_MODAL_WIDTH
        );
      });
      break;
    case "FOMO_PLA":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      className += CLASSNAME_ADVICE_DISCLAIMER_RIGHT;
      require(["views/components/postLoginActions/Fomo/FomoPLA"], function ({
        default: ContentComponent,
      }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          DEFAULT_MODAL_WIDTH
        );
      });
      break;
    case "MIGRATION_NEW_EXPERIENCE":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      require([
        "views/components/postLoginActions/NewExperience/NewExperiencePLA",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          EMPOWER_MODAL_WIDTH,
          undefined,
          undefined,
          undefined,
          "pc-modal__content qa-modal-content mignewexp-pla--content",
          !isPostLoginActionPreview
        );
      });
      break;
    case "NEWLY_TERMINATED_PARTICIPANT":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      require([
        "views/components/postLoginActions/NewlyTerminatedParticipant",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          EMPOWER_MODAL_MEDIUM_WIDTH,
          undefined,
          undefined,
          undefined,
          "pc-modal__content qa-modal-content pc-u-p+",
          !isPostLoginActionPreview
        );
      });
      break;
    case "LIST_WITH_TOOLTIP_AND_IMAGE":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      require([
        "views/components/postLoginActions/ListWithTooltipAndImage",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          EMPOWER_MODAL_MEDIUM_WIDTH,
          undefined,
          undefined,
          undefined,
          "pc-modal__content qa-modal-content pc-u-p+",
          !isPostLoginActionPreview
        );
      });
      break;
    case "EMPOWER_MTR_ENROLL":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      require([
        "views/components/postLoginActions/EmpowerMTREnroll",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          userMessage.resources[0]?.jsonContent?.modalWidth,
          undefined,
          undefined,
          undefined,
          "pc-modal__content qa-modal-content pc-u-p0",
          !isPostLoginActionPreview
        );
      });
      break;
    case "ACCOUNT_TYPE_AGGREGATION_PLA":
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      className += CLASSNAME_ADVICE_DISCLAIMER_CENTERED;
      require([
        "views/components/postLoginActions/AccountTypeAggregation/AccountTypeAggregationPLA",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          DEFAULT_MODAL_WIDTH
        );
      });
      break;
    case "USER_RESPONSE_PLA":
      require([
        "views/components/postLoginActions/Promo/PromoModal",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        const jsonContent = getJsonContent(userMessage);
        loadingModalTitle = jsonContent.modalTitle
          ? jsonContent.modalTitle
          : MODAL_TITLE_BLANK;
        className = "promo-pla";
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          SMEDIUM_MODAL_WIDTH
        );
      });
      break;
    case "USER_RESPONSE_SURVEY_PLA":
      require([
        "views/components/postLoginActions/UserResponseSurvey/UserResponseSurveyPLA",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        const jsonContent = getJsonContent(userMessage);
        loadingModalTitle = jsonContent.modalTitle
          ? jsonContent.modalTitle
          : MODAL_TITLE_BLANK;
        className = "user-response-survey-pla";
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          SMEDIUM_MODAL_WIDTH
        );
      });
      break;
    case "USER_RESPONSE_SURVEY_CUSTOM":
      require([
        "views/components/postLoginActions/UserResponseSurveyCustom/UserResponseSurveyCustom",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        const jsonContent = getJsonContent(userMessage);
        loadingModalTitle = jsonContent.modalTitle
          ? jsonContent.modalTitle
          : MODAL_TITLE_BLANK;
        className = "user-response-survey-custom-pla";
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          PCAP_CASH_MODAL_WIDTH
        );
      });
      break;
    case "USER_RESPONSE_GENERIC":
      require([
        "views/components/postLoginActions/UserResponseGeneric/UserResponseGeneric",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        const jsonContent = getJsonContent(userMessage);
        loadingModalTitle = jsonContent.modalTitle
          ? jsonContent.modalTitle
          : MODAL_TITLE_BLANK;
        className = "user-response-generic-pla";
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          EMPOWER_MODAL_MEDIUM_WIDTH
        );
      });
      break;
    case "USER_RESPONSE_ONE_PAGE_SURVEY":
      require([
        "views/components/postLoginActions/UserResponseOnePageSurvey/UserResponseOnePageSurvey",
      ], function ({ default: ContentComponent }) {
        modalComponentName = userMessage.template;
        const jsonContent = getJsonContent(userMessage);
        loadingModalTitle = jsonContent.modalTitle
          ? jsonContent.modalTitle
          : MODAL_TITLE_BLANK;
        className = "user-response-one-page-survey-pla";
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          PCAP_CASH_MODAL_WIDTH
        );
      });
      break;
    case "SINGLE_IMAGE_CENTER":
    default:
      modalComponentName = userMessage.template;
      loadingModalTitle = MODAL_TITLE_BLANK;
      className += "pc-advice-notification";
      require([
        "views/components/postLoginActions/AdviceNotification",
      ], function ({ default: ContentComponent }) {
        renderContentComponent(
          ContentComponent,
          modalComponentName,
          userMessage,
          plaDetails,
          loadingModalTitle,
          className,
          MEDIUM_MODAL_WIDTH,
          false,
          showTimeAgo,
          showFeedbackForm
        );
      });
  }

  trackUserMessageViewEvent({ userMessage, plaDetails });
};
