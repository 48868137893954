import React from "react";
export default function RetirementIcon() {
  return (
    <svg
      width="110px"
      height="110px"
      viewBox="0 0 110 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon_retirement</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Group3---Round-2-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="MTR-Enrollment_C" transform="translate(-75.000000, -62.000000)">
          <g id="icon_retirement" transform="translate(75.000000, 62.000000)">
            <g filter="url(#filter-1)" id="circle">
              <g transform="translate(0.000000, -0.000611)">
                <path
                  d="M110,54.9992369 C110,85.3747971 85.3759816,110 55,110 C24.6255445,110 0,85.3747971 0,54.9992369 C0,24.6252029 24.6255445,0 55,0 C85.3759816,0 110,24.6252029 110,54.9992369"
                  id="Fill-1"
                  fill="#F3F6F7"
                ></path>
              </g>
            </g>
            <g
              transform="translate(22.494522, 23.693083)"
              strokeLinecap="round"
              strokeWidth="1.5"
            >
              <path
                d="M33.2278222,38.707625 L33.2278222,59.0285972 C33.2278222,59.0285972 32.6959779,64.8356806 38.7515374,64.8356806 C38.7515374,64.8356806 43.269103,64.4522083 43.269103,60.7779028"
                stroke="#DB1830"
                strokeLinejoin="round"
              ></path>
              <path
                d="M16.7003746,32.692 C16.7330317,32.6186667 16.7703541,32.5483889 16.8045663,32.4765833 L16.5946278,32.4765833 C16.62884,32.5483889 16.6677175,32.6186667 16.7003746,32.692 Z"
                stroke="#002157"
              ></path>
              <path
                d="M50.3734219,32.692 C50.406079,32.6186667 50.4434014,32.5483889 50.4776136,32.4765833 L50.2676751,32.4765833 C50.3018873,32.5483889 50.3407648,32.6186667 50.3734219,32.692 Z"
                stroke="#002157"
              ></path>
              <path
                d="M33.536043,32.692 C33.5687001,32.6186667 33.6075776,32.5483889 33.6417898,32.4765833 L33.4318513,32.4765833 C33.4660635,32.5483889 33.5033859,32.6186667 33.536043,32.692 Z"
                stroke="#002157"
              ></path>
              <line
                x1="33.4312292"
                y1="0"
                x2="33.4312292"
                y2="2.29625"
                stroke="#DB1830"
                strokeLinejoin="round"
              ></line>
              <path
                d="M8.28090761,27.3149861 C11.9509437,27.3149861 15.1109069,29.4279028 16.5944723,32.4773472 L16.8044108,32.4773472 C18.2895313,29.4279028 21.4494946,27.3149861 25.1179755,27.3149861 C28.7880116,27.3149861 31.8095709,29.4279028 33.2931364,32.4773472 C34.7767018,29.4279028 38.2850074,27.3149861 41.9550435,27.3149861 C45.6250795,27.3149861 48.7834877,29.4279028 50.2670531,32.4773472 L50.4785467,32.4773472 C51.9621121,29.4279028 55.1220754,27.3149861 58.7905563,27.3149861 C61.9878419,27.3149861 64.8041281,28.9160972 66.4571994,31.3437361 C64.3282675,15.2058194 50.2997102,2.737625 33.2931364,2.737625 C15.9117834,2.737625 1.63596522,15.7604028 0,32.4131806 C1.49756132,29.3973472 4.63730826,27.3149861 8.28090761,27.3149861 Z"
                stroke="#002157"
                strokeLinejoin="round"
              ></path>
              <path
                d="M33.2931364,2.73777778 C33.2931364,2.73777778 20.3702552,7.72138889 16.5944723,32.4775"
                stroke="#002157"
                strokeLinejoin="round"
              ></path>
              <path
                d="M33.6424118,2.73777778 C33.6424118,2.73777778 46.5637379,7.72138889 50.3410758,32.4775"
                stroke="#002157"
                strokeLinejoin="round"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
