import $ from "jquery";
import _ from "underscore";
import Services from "services";
import Analytics from "analytics";
import objectPath from "object-path";

/**
 * Get user funnel attributes for use in ACE tests
 * @param {jQuery.Deferred} funnelAttributesDeferred  A promise that will resolve with response data when resolved and error when rejected
 */
function fetchFunnelAttributes(funnelAttributesDeferred) {
  Services.Profile.getFunnelAttributes(function (err, response) {
    if (err || !response || (response.spHeader && response.spHeader.errors)) {
      const errors = objectPath.get(response, "spHeader.errors", "[]");
      err = err || (_.first(errors) && _.first(errors).message);
      Analytics.sendEngineeringEvent(
        "Error",
        "aceTests.js: Services.Profile.getFunnelAttributes: " + err
      );
      funnelAttributesDeferred.reject(err);
    } else if (response && response.spData) {
      funnelAttributesDeferred.resolve(response.spData);
    }
  });
}

/**
 * Get user attributes for use in ACE tests
 * @param {jQuery.Deferred} personAttributesDeferred  A promise that will resolve with response data when resolved and error when rejected
 */
function fetchPersonAttributes(personAttributesDeferred) {
  Services.Person.get(function (err, response) {
    if (err || !response || (response.spHeader && response.spHeader.errors)) {
      const errors = objectPath.get(response, "spHeader.errors", "[]");
      err = err || (_.first(errors) && _.first(errors).message);
      Analytics.sendEngineeringEvent(
        "Error",
        "aceTests.js: Services.Profile.getFunnelAttributes: " + err
      );
      personAttributesDeferred.reject(err);
    } else if (response && response.spData) {
      personAttributesDeferred.resolve(response.spData);
    }
  });
}

window.AceTests = {
  funnelAttributesDeferred: $.Deferred(),
  personAttributesDeferred: $.Deferred(),

  /**
   * Prepare digital sales funnel and person data and place them in global
   * namespace so they can be accessed via GTM
   */
  initialize: function () {
    fetchFunnelAttributes(this.funnelAttributesDeferred);
    fetchPersonAttributes(this.personAttributesDeferred);
  },

  getFunnelAttributes: function () {
    return this.funnelAttributesDeferred;
  },

  getPersonAttributes: function () {
    return this.personAttributesDeferred;
  },
};

export default window.AceTests;
