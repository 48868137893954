import Services from "services";
import getEventTargetDataAttribute from "views/components/postLoginActions/getEventTargetDataAttribute";

const markUserMessageAsClicked = (userMessageId, callback) => {
  Services.UserMessage.markViewed(
    {
      userMessageIds: "[" + userMessageId + "]",
      actions: '["markClicked"]',
    },
    callback
  );
};

// Default click tracking to true unless trackingFlag is explicitly set to false
export const shouldMarkUserMessageAsClicked = (trackingFlag) => {
  return trackingFlag !== false && trackingFlag !== "false";
};

export const markUserMessageAsClickedBaseOnTrackingFlag = (
  userMessage,
  event,
  callback
) => {
  const trackingFlag = getEventTargetDataAttribute(event, "data-tracking-flag");
  if (
    userMessage &&
    userMessage.userMessageId &&
    shouldMarkUserMessageAsClicked(trackingFlag)
  ) {
    markUserMessageAsClicked(userMessage.userMessageId, callback);
  } else if (callback) {
    callback();
  }
};

export default markUserMessageAsClicked;
