import PropTypes from "prop-types";
import React from "react";
import _, { noop } from "underscore";
import moment from "moment";
import Services from "services";
import BudgetingSummary from "components/budgetingSummary/BudgetingSummary";
import parseResponseErrors from "libs/pcap/utils/response";
import { formatCurrency } from "libs/pcap/utils/format";
import "libs/pcap/utils/date";
import BudgetingSummaryEditModal from "components/budgetingSummary/BudgetingSummaryEditModal";
import DateUtils from "libs/pcap/utils/date";
import { getBudgetingSummary } from "libs/pcap/utils/getTransactionSummaries";
import { promisify } from "utils/service";
import { trackEvent } from "components/common/ComponentAnalytics";
import deepCopy from "deep-copy";
import ZeroStateWidget from "../dashboard/ZeroStateWidget";

const PROPER_DATE_FORMAT = DateUtils.API_FORMAT;
const NUMBER_OF_CATEGORIES_TO_DISPLAY = 6;
const NET_BOUNDARY = 999999;

export default class BudgetingSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.watcherIds = [];
    this.state = {
      errors: null,
      isModalOpen: false,
      loading: true,
      hover: null,
    };

    this.previousMonthDates = {
      startDate: moment().subtract(1, "month").startOf("month"),
      endDate: moment().subtract(1, "month").endOf("month"),
      todayLastMonth: moment().subtract(1, "month"),
    };
    this.currentMonthDates = {
      startDate: moment().startOf("month"),
      endDate: moment(),
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onCategoryMouseOver = this.onCategoryMouseOver.bind(this);
    this.onCategoryMouseOut = this.onCategoryMouseOut.bind(this);
  }

  handleEdit() {
    this.setState({
      isModalOpen: true,
    });
  }

  handleUpdate(newBudget) {
    trackEvent(
      "Set Monthly Budget From Dashboard Widget",
      "Update Budget Value",
      {
        // eslint-disable-next-line camelcase
        old_budget: this.state.target,
        // eslint-disable-next-line camelcase
        new_budget: newBudget,
      }
    );
    this.setState({
      isModalOpen: false,
      target: newBudget,
    });
  }

  onClose() {
    this.setState({
      isModalOpen: false,
    });
  }

  onCategoryMouseOver(category) {
    this.updateTakeaway(category);
  }

  onCategoryMouseOut() {
    this.resetTakeaway();
  }

  updateTakeaway(category) {
    let currentCategorySpending = category.amount,
      previousCategorySpending = this.getPreviousSpendingOfCategory(
        category.transactionCategoryId
      );
    this.setState({
      hover: {
        current: currentCategorySpending,
        previous: previousCategorySpending,
      },
      takeaway: {
        amount: currentCategorySpending,
        message: this.getTakeaway(
          currentCategorySpending,
          previousCategorySpending
        ),
      },
    });
  }

  resetTakeaway() {
    this.setState({
      hover: null,
      takeaway: {
        amount: this.state.current,
        message: this.state.currentTakeawayMessage,
      },
    });
  }

  getPreviousSpendingOfCategory(transactionCategoryId) {
    if (
      !transactionCategoryId ||
      !this.state.previous.monthBudgeting.categories
    ) {
      return 0;
    }
    let previousMonthCategory =
      this.state.previous.monthBudgeting.categories.find(
        (cat) => cat.transactionCategoryId === transactionCategoryId
      );
    return previousMonthCategory ? previousMonthCategory.amount : 0;
  }

  /*
   * @return {Array} A max array of 6 objects of category name and its total.
   * if there are more than 6 categories, we display the top 5 and
   * group the rest into `Other`
   */
  getTopSpendingCategories(categories) {
    let sortedCategories = _.sortBy(categories, "amount").reverse(),
      final6;

    if (sortedCategories.length > NUMBER_OF_CATEGORIES_TO_DISPLAY) {
      let top5 = _.first(sortedCategories, NUMBER_OF_CATEGORIES_TO_DISPLAY - 1),
        restAll = _.rest(sortedCategories, NUMBER_OF_CATEGORIES_TO_DISPLAY - 1);

      // push top5 to final6
      final6 = top5;
      // group the rest into `Other` and push to final6
      final6.push({
        name: "Other",
        amount: restAll.reduce((total, category) => total + category.amount, 0),
      });
    } else {
      final6 = sortedCategories;
    }
    return final6;
  }

  fetchTransactions(options, watchCallback) {
    return new Promise((resolve, reject) => {
      let watchId = Services.Transactions.get.watch(
        {
          startDate: options.startDate.format(PROPER_DATE_FORMAT),
          endDate: options.endDate.format(PROPER_DATE_FORMAT),
        },
        (err, response) => {
          const errors = parseResponseErrors(err, response);
          if (errors) {
            reject(errors);
            return;
          }
          if (this.isInitialFetchCompleted && _.isFunction(watchCallback)) {
            watchCallback(response.spData);
            return;
          }
          resolve(deepCopy(response.spData));
        },
        this
      );
      this.watcherIds.push(watchId);
    });
  }

  componentDidMount() {
    return Promise.all([
      this.fetchTransactions(
        this.currentMonthDates,
        this.watcherCallbackCurrentMonth.bind(this)
      ),
      this.fetchTransactions(
        this.previousMonthDates,
        this.watcherCallbackPreviousMonth.bind(this)
      ),
      this.props.fetchTransactionCategories(),
      this.props.fetchUserSpending({
        intervalTypes: ["MONTH"],
        includeDetails: false,
        includeValues: ["CURRENT", "TARGET"],
      }),
    ]).then(
      ([currentMonth, previousMonth, allCategories, userSpending]) => {
        if (this.unmounted) {
          return;
        }
        this.isInitialFetchCompleted = true;
        this.onFetched(
          currentMonth,
          previousMonth,
          allCategories,
          userSpending
        );
      },
      (errors) => {
        if (this.unmounted) {
          return;
        }
        this.setState(
          {
            loading: false,
            errors: errors,
          },
          this.props.onDCDashboardComponentLoaded
        );
      }
    );
  }

  componentWillUnmount() {
    this.unmounted = true;
    let watcherIds = this.watcherIds;
    _.each(watcherIds, (watcherId) => {
      Services.Transactions.get.unwatch(watcherId);
    });
  }

  getTakeaway(currentSpending, previousSpending) {
    let takeaway,
      netSpending = currentSpending - previousSpending;

    if (netSpending === 0) {
      takeaway = "Same as";
    } else if (netSpending > 0) {
      takeaway = formatCurrency(netSpending, 0) + " over";
    } else {
      takeaway = formatCurrency(netSpending * -1, 0) + " under";
    }
    return netSpending > NET_BOUNDARY || netSpending < -NET_BOUNDARY ? (
      <small>{takeaway}</small>
    ) : (
      <div>{takeaway}</div>
    );
  }

  getUserSpendingTarget(userSpending) {
    // return `target` from state, if it was set/updated by edit modal
    if (this.state.target) {
      return this.state.target;
    }

    if (!userSpending) {
      return;
    }
    let userSpendingMonth =
        _.findWhere(userSpending.intervals, { type: "MONTH" }) || {},
      target = userSpendingMonth.target;
    return target;
  }

  filterTransactionsByDate(startDate, endDate, transactions = []) {
    return transactions.filter((transaction) => {
      return moment(transaction.transactionDate, PROPER_DATE_FORMAT).isBetween(
        startDate,
        endDate,
        "day",
        "[]"
      );
    });
  }

  onFetched(currentMonth, previousMonth, allCategories, userSpending) {
    let currentMonthTransactionsSummaries = getBudgetingSummary({
        transactions: currentMonth.transactions || [],
        startDate: currentMonth.startDate,
        endDate: currentMonth.endDate,
        categories: allCategories,
        includeCategories: true,
      }),
      previousMonthTransactionsBudgetingSummaries = {
        sameTimeBudgeting: getBudgetingSummary({
          transactions: this.filterTransactionsByDate(
            this.previousMonthDates.startDate,
            this.previousMonthDates.todayLastMonth,
            previousMonth.transactions
          ),
          startDate: this.previousMonthDates.startDate,
          endDate: this.previousMonthDates.todayLastMonth,
          includeCategories: true,
          categories: allCategories,
        }),
        monthBudgeting: getBudgetingSummary({
          transactions: previousMonth.transactions,
          startDate: this.previousMonthDates.startDate,
          endDate: this.previousMonthDates.endDate,
          includeCategories: true,
          categories: allCategories,
        }),
      },
      topSpendingCategories = this.getTopSpendingCategories(
        (currentMonthTransactionsSummaries &&
          currentMonthTransactionsSummaries.categories) ||
          []
      ),
      target = this.getUserSpendingTarget(userSpending),
      takeawayMessage = this.getTakeaway(
        currentMonthTransactionsSummaries.total,
        previousMonthTransactionsBudgetingSummaries.sameTimeBudgeting.total || 0
      );

    this.setState(
      {
        currentMonthData: currentMonth,
        previousMonthData: previousMonth,
        allCategories: allCategories,
        userSpending: userSpending,
        categories: topSpendingCategories,
        current: currentMonthTransactionsSummaries.total,
        previous: previousMonthTransactionsBudgetingSummaries,
        target: target,
        takeaway: {
          amount: currentMonthTransactionsSummaries.total,
          message: takeawayMessage,
        },
        currentTakeawayMessage: takeawayMessage,
        loading: false,
        errors: null,
        hover: null,
      },
      this.props.onDCDashboardComponentLoaded
    );
  }

  watcherCallbackCurrentMonth(currentMonth) {
    const { previousMonthData, allCategories, userSpending } = this.state;

    this.onFetched(
      currentMonth,
      previousMonthData,
      allCategories,
      userSpending
    );
  }

  watcherCallbackPreviousMonth(previousMonth) {
    const { currentMonthData, allCategories, userSpending } = this.state;

    this.onFetched(
      currentMonthData,
      previousMonth,
      allCategories,
      userSpending
    );
  }

  render() {
    const {
      loading,
      errors,
      categories,
      current,
      previous,
      target,
      takeaway,
      hover,
      isModalOpen,
    } = this.state;
    const {
      chartLayoutClassName,
      legendLayoutClassName,
      ace,
      validBankOrCCAccount,
    } = this.props;

    if (!loading && !validBankOrCCAccount && window.integratedSharedData) {
      const translations = window.integratedSharedData?.translations;
      const zeroStateTranslations = translations?.get("zeroStateDashboard");

      return (
        <ZeroStateWidget
          displayName={zeroStateTranslations?.budgeting?.title}
          cta={zeroStateTranslations?.budgeting?.cta}
          link={"#/cash-flow/spending"}
          label={zeroStateTranslations?.budgeting?.label}
          tooltip={zeroStateTranslations?.budgeting?.tooltip}
          buttonAlign={"center"}
          backgroundName="budgeting"
        />
      );
    }

    return (
      <div>
        <BudgetingSummary
          loading={loading}
          errors={errors}
          categories={categories}
          current={current}
          previous={previous}
          target={target}
          takeaway={takeaway}
          onEditCallback={this.handleEdit}
          onUpdate={this.handleUpdate}
          onCategoryMouseOverCallback={this.onCategoryMouseOver}
          onCategoryMouseOutCallback={this.onCategoryMouseOut}
          hover={hover}
          chartLayoutClassName={chartLayoutClassName}
          legendLayoutClassName={legendLayoutClassName}
          ace={ace}
        />
        {isModalOpen && (
          <BudgetingSummaryEditModal
            isOpen={isModalOpen}
            onSave={this.handleUpdate}
            onClosed={this.onClose}
          />
        )}
      </div>
    );
  }
}

BudgetingSummaryContainer.propTypes = {
  ace: PropTypes.object,
  chartLayoutClassName: PropTypes.string,
  fetchTransactionCategories: PropTypes.func,
  fetchUserSpending: PropTypes.func,
  onDCDashboardComponentLoaded: PropTypes.func,
  legendLayoutClassName: PropTypes.string,
  validBankOrCCAccount: PropTypes.bool,
};

BudgetingSummaryContainer.defaultProps = {
  ace: undefined,
  chartLayoutClassName: "",
  fetchTransactionCategories: promisify(Services.TransactionCategories.get),
  fetchUserSpending: promisify(Services.Spending.get),
  onDCDashboardComponentLoaded: noop,
  legendLayoutClassName: "",
  validBankOrCCAccount: false,
};
