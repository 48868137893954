import Backbone from "backbone";
import "services";
import "log";
var SidebarModel = Backbone.Model.extend({
  defaults: {
    state: "minified",
  },
});
var sidebarModel = new SidebarModel();
sidebarModel.MINIFIED = "minified";
sidebarModel.EXPANDED = "expanded";
sidebarModel.COLLAPSED = "collapsed";
export default sidebarModel;
