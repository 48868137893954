/* eslint-disable sonarjs/no-duplicate-string, sonarjs/no-collapsible-if */
import $ from "jquery";
import Backbone from "backbone";
import "backbone.view";
import sidebarModel from "models/sidebar";
import TakeActionTemplate from "templates/modules/sidebar/takeAction.html";
import "tooltip";
import { isEmpowerPrivilegedMode } from "../utils/accountUtils";

const IS_OPEN_CLASSNAMES = " sidebar-account--is-open pc-bg-dark";
const ANIMATION_TIME = 300;

let RetailAccountView = Backbone.View.extend({
  tagName: "li",
  className: "js-account sidebar-account",
  events: {
    "click .js-action-take-action": "onTakeAction",
  },
  initialize: function () {
    if (this.model) {
      this.model.on("change", this.onModelChange, this);
    }
    sidebarModel.on("change:state", this.onSidebarStateChanged, this);
    sidebarModel.on("showSiteExtras", (userAccountId) => {
      if (parseInt(userAccountId, 10) === parseInt(this.userAccountId, 10)) {
        this.showExtras();
      }
    });
    var self = this;
    this.$el.on("click", function () {
      self.onViewClicked.apply(self, arguments);
    });
    this.isExtraFormVisible = false;
  },
  render: function () {
    var html,
      data = this.model.toJSON(),
      nextAction = data.nextAction || {};

    data.names = [this.model.get("name")];
    data.staticUrl = window.staticUrl;

    data.isPrivileged = isEmpowerPrivilegedMode();

    // eslint-disable-next-line sonarjs/no-small-switch
    switch (nextAction.action) {
      case "TAKE_ACTION":
        this.$el.addClass("error");
        html = TakeActionTemplate(data);
        break;
      default:
        break;
    }

    this.$el.html(html);

    this.postRender();

    return this;
  },
  postRender: function () {
    this.tooltipElement = this.$('[data-toggle="tooltip"]');
    this.tooltipElement.tooltip({
      placement: "right",
      container: this.$el,
      trigger: "hover click",
    });
  },
  onModelChange: function () {
    this.userAccountId = this.model.get("userAccountId");
    this.render();
  },
  onTakeAction: function (e) {
    let accountId;
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      accountId = e.target.value;
    }
    location.hash = `#/account-actions?userAccountId=${accountId}`;
    this.hideExtras();
  },
  onNotificationClicked: function (e) {
    if (e) {
      e.stopPropagation();
    }
  },
  onSidebarStateChanged: function (model, state) {
    if (state !== model.EXPANDED) {
      this.hideExtras();
      if (this.editing) {
        this.editing = false;
        this.render();
      }
    }
  },
  onViewClicked: function (e) {
    // if they didn't click on the notification icon or the edit icon
    if (
      !$(e.target).hasClass("notificationIcon") &&
      !$(e.target).hasClass("edit") &&
      !this.$el.hasClass("resolving")
    ) {
      // force them to resolve the issues
      this.setResolving();
    }
  },
  setResolving: function () {
    sidebarModel.trigger("requestState", sidebarModel.EXPANDED);
    this.showExtras();
  },
  showExtras: function () {
    this.isExtraFormVisible = true;
    this.$(".js-account-extras, .js-need-attention-site__accounts-names")
      .removeClass("is-hidden")
      .slideDown(ANIMATION_TIME, () => {
        const elementToFocus = this.$(".js-account-extras").find(
          'input[type="text"]:eq(0)'
        );
        if (elementToFocus.length) {
          elementToFocus.focus();
        } else {
          this.$(".js-account-extras").find(".js-account-action").focus();
        }

        sidebarModel.trigger("refreshScrollBar");
      });
    this.$el.addClass("resolving");
    if (this.$(".js-account-extras").length) {
      this.$el.addClass(IS_OPEN_CLASSNAMES);
    }
    if (this.model.get("nextAction")?.action === "MORE_INFO") {
      this.$el.addClass(`js-editing-form`);
    }
    sidebarModel.trigger("ensureElementIsVisible", this.$el);
  },
  hideExtras: function () {
    // promise allows changes to occur after slideUp animation
    return new Promise((resolve) => {
      this.isExtraFormVisible = false;
      this.credentialsModel = {};
      var extrasContainer = this.$(
        ".js-account-extras, .js-need-attention-site__accounts-names"
      );
      extrasContainer.slideUp(
        ANIMATION_TIME,
        function () {
          extrasContainer.addClass("is-hidden");
          sidebarModel.trigger("refreshScrollBar");
          this.$el.removeClass(
            `js-editing-form resolving ${IS_OPEN_CLASSNAMES}`
          );
          resolve();
        }.bind(this)
      );
    });
  },
  remove: function () {
    this.model.off("change");
    Backbone.View.prototype.remove.apply(this, arguments);
  },
});
export default RetailAccountView;
