import React from "react";
import PropTypes from "prop-types";
import ArcChart from "components/common/charts/ArcChart";
import { formatCurrency } from "libs/pcap/utils/format";
import FlexibleDollar from "components/common/FlexibleDollar";
import { SPACING_UNIT } from "constants/styles";

const RetirementWithdrawalsChart = (props) => {
  const isOverdrawn = props.currentWithdrawal > props.targetWithdrawal;
  const underArc = (
    <div className="retirement-withdrawals-chart__under-arc">
      <div
        className={
          isOverdrawn
            ? "retirement-withdrawals-chart__current-balance retirement-withdrawals-chart__current-balance--overdrawn"
            : "retirement-withdrawals-chart__current-balance"
        }
      >
        <FlexibleDollar
          value={props.currentWithdrawal}
          containerWidth={"140px"}
        />
      </div>
      {props.portfoliosCount !== 1 && (
        <div className="retirement-withdrawals-chart__detail">
          Withdrawn This Year of {formatCurrency(props.targetWithdrawal, 0)}{" "}
          Plan
        </div>
      )}
    </div>
  );

  return (
    <>
      {props.rmdIncome > 0 && props.isClient && (
        <div className="retirement-withdrawals-chart__rmd">
          <div>RMD Estimate</div>
          <div>{formatCurrency(props.rmdIncome, 0)}</div>
        </div>
      )}
      <ArcChart
        currentValues={[{ value: props.currentWithdrawal }]}
        targetValue={props.targetWithdrawal}
        arcWidth={SPACING_UNIT}
        className={`retirement-savings-summary-chart--withdrawals ${
          isOverdrawn ? "retirement-savings-summary-chart--overdrawn " : ""
        }`}
        underArcDisplay={underArc}
      />
    </>
  );
};

RetirementWithdrawalsChart.propTypes = {
  currentWithdrawal: PropTypes.number,
  targetWithdrawal: PropTypes.number,
  rmdIncome: PropTypes.number,
  portfoliosCount: PropTypes.number,
  isClient: PropTypes.bool,
};

RetirementWithdrawalsChart.defaultProps = {
  currentWithdrawal: undefined,
  targetWithdrawal: undefined,
  rmdIncome: 0,
  portfoliosCount: 1,
  isClient: false,
};

export default RetirementWithdrawalsChart;
