import PropTypes from "prop-types";
import React, { useEffect, forwardRef } from "react";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import withTooltips from "components/higherOrderComponents/withTooltips";
import {
  getResourceImage,
  getResourceImageObject,
  getJsonContent,
} from "views/components/postLoginActions/helpers";
import LinkAccount from "./SVGs/ACE_Link_Accnt";
import WebTools from "./SVGs/ACE_Web_Tools";
import ChangeJobs1 from "./SVGs/ACE_ChangeJobs1";
import ChangeJobs2 from "./SVGs/ACE_ChangeJobs2";
import ChangeJobs3 from "./SVGs/ACE_ChangeJobs3";
import RetirementIcon from "./SVGs/icon_retirement";
import CallCenterIcon from "./SVGs/icon_call_center";
import SecurityLockIcon from "./SVGs/icon_security_lock";
import UpdateInfoIcon from "./SVGs/icon_update_info";
import BeneficiaryIcon from "./SVGs/icon_beneficiary";
import CashChart from "./SVGs/Cash_Chart";

const svgMap = {
  EMPOWER_INSIGHT_LINK_MORE_ACCOUNTS: LinkAccount,
  EMPOWER_INSIGHT_COMPLETE_FINANCIAL_LOOK: LinkAccount,
  EMPOWER_INSIGHT_STAY_ON_TRACK: WebTools,
  EMPOWER_INSIGHT_RETIRE_PLANER_WIDGET_0001: WebTools,
  EMPOWER_INSIGHT_SIMPLIFY_YOUR_RETIREMENT: ChangeJobs1,
  EMPOWER_INSIGHT_HAVE_AN_OLD_401K: ChangeJobs2,
  EMPOWER_INSIGHT_CHANGING_JOBS_DC_WIDGET_0001: ChangeJobs3,
  EMPOWER_INSIGHT_CHANGING_JOBS_OR_RETIRING_2: ChangeJobs3,
  EMPOWER_INSIGHT_MTR_ENROLL_IRA_WIDGET_0001: RetirementIcon,
  EMPOWER_INSIGHT_MTR_ENROLL_FORMER_PLAN_WIDGET_0001: RetirementIcon,
  EMPOWER_INSIGHT_MTR_ENROLL_CURRENT_PLAN_WIDGET_0001: RetirementIcon,
  EMPOWER_INSIGHT_HERE_TO_HELP_WIDGET_0001: CallCenterIcon,
  EMPOWER_INSIGHT_SECURITY_GUARANTEE_WIDGET_0001: SecurityLockIcon,
  EMPOWER_INSIGHT_UPDATE_YOUR_INFORMATION: UpdateInfoIcon,
  EMPOWER_INSIGHT_BENEFICIARY_WIDGET_0001: BeneficiaryIcon,
  EMPOWER_INSIGHT_CONSOLIDATION_RETAIL_WIDGET_0001: ChangeJobs1,
  BENEFICIARY_WIDGET_0001: BeneficiaryIcon,
  CONSOLIDATION_RETAIL_WIDGET_S001: ChangeJobs1,
  MTR_ENROLL_IRA_WIDGET_0001: RetirementIcon,
  MTR_ENROLL_CURRENTPLAN_WIDGET_0001: RetirementIcon,
  MTR_ENROLL_FORMERPLAN_WIDGET_0001: RetirementIcon,
};

const svgIconMap = {
  LINK_ACCOUNT: LinkAccount,
  WEB_TOOLS: WebTools,
  CHANGE_JOBS_1: ChangeJobs1,
  CHANGE_JOBS_2: ChangeJobs2,
  CHANGE_JOBS_3: ChangeJobs3,
  RETIREMENT: RetirementIcon,
  CALL_CENTER: CallCenterIcon,
  SECURITY_LOCK: SecurityLockIcon,
  UPDATE_INFO: UpdateInfoIcon,
  BENEFICIARY: BeneficiaryIcon,
  CASH_CHART: CashChart,
};
const StandardEmpowerInsight = forwardRef((props, ref) => {
  const { message, isFirstRendered, markAsImpressed, markAsViewed } = props;

  const getActions = (message) => {
    const buttonClass = "pc-btn";

    return getActionButtons({ message, markAsViewed, buttonClass });
  };

  useEffect(() => {
    if (isFirstRendered) {
      markAsImpressed(message.userMessageId);
    } else {
      markAsViewed(message.userMessageId);
    }
    props.storeTooltipRef(
      document.getElementById("standardEmpowerInsightTooltip"),
      { container: "body", placement: "top" }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { widgetIcon, imageDescription } = getJsonContent(message) || {};
  const SVG = widgetIcon ? svgIconMap[widgetIcon] : svgMap[message.template];
  const imageSource = getResourceImage(message);
  const imageStyle = getResourceImageObject(message).jsonContent?.imageStyle;
  const imageAlt = getResourceImageObject(message).alt;

  return (
    <div className="empower_insight__message pc-u-m-" ref={ref}>
      <div className="pc-u-mb- pc-u-mt">
        {imageSource ? (
          <img
            src={imageSource}
            alt={imageAlt}
            style={{
              height: "90px",
              width: "90px",
              ...imageStyle,
            }}
          />
        ) : (
          SVG && <SVG />
        )}
      </div>
      {imageDescription && (
        <div
          className="empower_insight__image-description pc-u-mb"
          dangerouslySetInnerHTML={{ __html: imageDescription }}
        />
      )}
      <div
        className="empower_insight__header qa-insight-header u-text-semibold pc-u-mb"
        dangerouslySetInnerHTML={{ __html: message.title }}
      />
      <div
        className="empower_insight__summary pc-u-mb"
        dangerouslySetInnerHTML={{ __html: message.summary }}
      />
      <div className="empower_insight__actions pc-u-mb-">
        {getActions(message)}
      </div>
    </div>
  );
});

StandardEmpowerInsight.propTypes = {
  message: PropTypes.shape({
    userMessageId: PropTypes.number,
    template: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    resources: PropTypes.array,
    action: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  markAsImpressed: PropTypes.func,
  markAsViewed: PropTypes.func,
  isFirstRendered: PropTypes.bool,
  storeTooltipRef: PropTypes.func,
};

StandardEmpowerInsight.defaultProps = {
  markAsImpressed: undefined,
  markAsViewed: undefined,
  isFirstRendered: false,
  storeTooltipRef: false,
};

StandardEmpowerInsight.displayName = "StandardEmpowerInsight";

export default withTooltips(StandardEmpowerInsight);
