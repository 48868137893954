import AccountView from "views/modules/sidebar/partials/account2";
import moment from "moment";
import { isEmpty } from "underscore";

//determine if we should display edit pencil for TRS flex accounts
export const isTRSPensionGoal = (empowerAttributes) => {
  if (!empowerAttributes) return false;
  return Boolean(
    empowerAttributes.type === "TRS" &&
      empowerAttributes.isPensionIncome &&
      empowerAttributes.pensionIncomeGoalKey
  );
};

//returns redirect url for enroll now button
export const getNQEnrollRedirect = (groupId) => {
  return "participant/accounts/#/enrollment-summary/" + groupId;
};

// returns the Group Label for Empower accounts
export const getGroupLabel = (institutionalPartnerName) => {
  const isParticipantWeb = IS_EMPOWER && !window.isAdvisorApp;
  let groupLabel;

  if (isParticipantWeb) {
    const translation =
      window.integratedSharedData?.translations.get("titleList");
    groupLabel = translation?.participant?.groupLabel;
  } else {
    groupLabel = window.advisorAccountsGroupLabel;
  }

  // Look for groupLabel in ui-style-guide
  // Else use institutionalPartnerName.
  // If none found, use "Empower accounts' as label"
  if (groupLabel) {
    return groupLabel;
  } else if (window.isInstitutionalPartner) {
    return institutionalPartnerName;
  }
  return "Empower accounts";
};

/**
 *
 * Account Util Function `getEmpowerAccountSummary`
 *
 * Maps accounts from pcap to empower API data
 * - getAccounts2 (pcap)
 * - PublicServices.getAccountSummary (empower)
 *
 *  @param {Object} empowerAttributes field on getAccount2 account object level
 *  @param {Object} empowerAccountSummaries the PublicServices.getAccountSummary response data
 *  @returns {Object} Returns a single account summary if `empowerAccountSummaries[i]` found in the `@param empowerAccountSummaries`
 **/

export const getEmpowerAccountSummary = (
  empowerAttributes,
  empowerAccountSummaries
) => {
  if (
    !empowerAttributes ||
    !empowerAccountSummaries ||
    !(
      empowerAccountSummaries?.nonMonthlyAccounts ||
      empowerAccountSummaries?.monthlyAccounts
    )
  )
    return undefined;
  let accountSummary;
  accountSummary = empowerAccountSummaries.nonMonthlyAccounts?.find((acc) => {
    return (
      acc.individualId === empowerAttributes.contextId &&
      empowerAttributes.planId?.startsWith(acc.groupId)
    );
  });

  if (isEmpty(accountSummary)) {
    // eslint-disable-next-line sonarjs/no-identical-functions
    accountSummary = empowerAccountSummaries.monthlyAccounts?.find((acc) => {
      return (
        acc.individualId === empowerAttributes.contextId &&
        empowerAttributes.planId?.startsWith(acc.groupId)
      );
    });
  }

  return accountSummary;
};

/**
 * Account Util Function `getEmpowerAccountView`
 *
 * Builds an `AccountView` backbone view and adds custom empower data to `Account` model
 *
 * @param {Object} accountModel `Account` model created with getAccounts2 data expected to contain `empowerAttributes` data field
 * @param {Object} empowerAdditionalData merge other empower data with the account view data (currently expecting data from uiPreferences and getAccountSummary )
 * @param {Function} onViewReady required to return the AccountView after promise and API calls are resolved
 * @returns {undefined} returns `undefined`
 *
 *  Use callback `onViewReady(accountView)` to receive the empower account view object
 *
 * Resulting empower account view object `accountView` will contain:
 * - accountView.empowerAttributes { attributes added by getAccounts2 }
 * - accountView.empowerData { account summaries , account summary, context info }
 **/

export const getEmpowerAccountView = (
  accountModel,
  empowerAdditionalData,
  onViewReady
) => {
  const accountSummary = getEmpowerAccountSummary(
    accountModel.get("empowerAttributes"),
    empowerAdditionalData?.accountSummaries
  );

  // Build empowerData for an NQ plans or secondary/primary eligible or all other
  const empowerData = { ...empowerAdditionalData };
  empowerData.enrollmentInfo = {};
  empowerData.accountSummary = accountSummary;

  const isNQ =
    accountSummary?.groupDetails?.irsrlCode === "NQDC" ||
    accountSummary?.groupDetails?.irsrlCode === "NQSP";
  const isSecondaryEnrollment =
    accountSummary?.accountURL?.includes("participant/enroll/#/") || false;

  // setup all non eligible or eligible NQ plans
  if (isNQ) {
    // hard fail if `accountSummary.groupId` or anything in API response is missing
    window.PublicServices.isParticipantEnrollEligible(
      accountSummary.groupId
    ).then((response) => {
      const { nonQualEnrollmentEligibility } = response;

      const isNQPlanEligible =
        nonQualEnrollmentEligibility.participantEnrollmentEligible || false;

      const enrollmentDatePassed = moment(
        nonQualEnrollmentEligibility.enrollmentPeriodEndDate
      ).isBefore(moment.now(), "day");

      let lockHeedInput = {
        effDate: nonQualEnrollmentEligibility.enrollmentPeriodEndDate,
        eligible: isNQPlanEligible,
        planId: accountSummary.groupId,
        planName: accountSummary.name,
      };

      let eligiblePlan = [];
      eligiblePlan.push(lockHeedInput);

      let noLockheedData = {
        planNumber: lockHeedInput.planId,
        planName: lockHeedInput.planName,
        currentChainingOption: "",
        enrollmentEndDate: lockHeedInput.effDate,
        groupLockheedPlan: false,
      };

      empowerData.enrollmentInfo = {
        isNQ: true,
        isLockHeed: {},
        isEligible: isNQPlanEligible,
        showEnrollmentDate: !enrollmentDatePassed,
        nqEnrollmentDate: nonQualEnrollmentEligibility.enrollmentPeriodEndDate,
        redirectUrl: getNQEnrollRedirect(accountSummary.groupId),
      };

      window.PublicServices.fetchIsLockheed(eligiblePlan).then(
        (response) => {
          if (response.data && response.data.groupLockheedPlan) {
            empowerData.enrollmentInfo.isLockHeed = response.data;
            empowerData.enrollmentInfo.isLockHeed.planNumber =
              accountSummary.groupId;
          } else {
            empowerData.enrollmentInfo.isLockHeed = noLockheedData;
          }

          accountModel.set({ empowerData });

          const accountView = new AccountView({
            model: accountModel,
          });
          onViewReady(accountView);
        },
        () => {
          empowerData.enrollmentInfo.isLockHeed = noLockheedData;

          accountModel.set({ empowerData });

          const accountView = new AccountView({
            model: accountModel,
          });
          onViewReady(accountView);
        }
      );
    });
  }

  // setup enrollment eligible secondary or primary plans
  else if (isSecondaryEnrollment) {
    empowerData.enrollmentInfo = {
      isSecondary: true,
      isEligible: true,
      redirectUrl: accountSummary.accountURL,
    };
    accountModel.set({ empowerData });

    let accountView = new AccountView({ model: accountModel });
    onViewReady(accountView);
  }

  // all other cases use `accountURL` for account overview redirect
  else {
    accountModel.set({ empowerData });
    let accountView = new AccountView({
      model: accountModel,
    });
    onViewReady(accountView);
    return;
  }
};
