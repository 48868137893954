import moment from "moment-timezone";

export const tradingBlackoutPeriodFormat = (tradingBlackout) => {
  return {
    closeTime: tradingBlackout?.marketCloseTimeSecFromEpoch,
    startTime: tradingBlackout?.tradingBlackoutStartTimeSecFromEpoch,
    warningStartTime:
      tradingBlackout?.tradingBlackoutAlertStartTimeSecFromEpoch,
  };
};

// Trading Blackout Warning - Trade suspension Period
export const isBetweenSuspensionPeriod = (tradingBlackout) => {
  const startTime = tradingBlackout?.startTime;
  const closeTime = tradingBlackout?.closeTime;
  const currentTime = moment().unix();
  return moment(currentTime).isBetween(startTime, closeTime);
};

// Trading Blackout Warning - Trade Warning Period
export const isBetweenWarningPeriod = (tradingBlackout) => {
  const warningStartTime = tradingBlackout?.warningStartTime;
  const closeTime = tradingBlackout?.closeTime;
  const currentTime = moment().unix();
  return moment(currentTime).isBetween(warningStartTime, closeTime);
};

// Trading Blackout Warning - Before the Warning Period
export const isBeforeWarningPeriod = (tradingBlackout) => {
  const warningStartTime = tradingBlackout?.warningStartTime;
  const currentTime = moment().unix();
  return moment(currentTime).isBefore(warningStartTime);
};

// Trading Blackout Warning - Before the Suspend Period
export const isBeforeSuspendPeriod = (tradingBlackout) => {
  const startTime = tradingBlackout?.startTime;
  const currentTime = moment().unix();
  return moment(currentTime).isBefore(startTime);
};

// Trading Blackout Warning - Remaining to start Warning Period
export const remainingTimeToStartWarningPeriod = (tradingBlackout) => {
  const warningStartTime = tradingBlackout?.warningStartTime;
  const currentTime = moment().unix();
  return (warningStartTime - currentTime) * 1000;
};

// Trading Blackout Warning - Remaining to start Suspend Period
export const remainingTimeToStartSuspendPeriod = (tradingBlackout) => {
  const startTime = tradingBlackout?.startTime;
  const currentTime = moment().unix();
  return (startTime - currentTime) * 1000;
};

// Trading Blackout Warning - Remaining to end Suspend Period
export const remainingTimeToEndSuspendPeriod = (tradingBlackout) => {
  const endTime = tradingBlackout?.closeTime;
  const currentTime = moment().unix();
  return (endTime - currentTime) * 1000;
};
