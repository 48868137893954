/* eslint-disable camelcase */
import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import Services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import CategoriesManager from "components/modal/categoriesManager/CategoriesManager";
import AddCategoryContainer from "components/modal/categoriesManager/AddCategoryContainer";
import EditCategoryContainer from "components/modal/categoriesManager/EditCategoryContainer";
import ComponentAnalytics from "components/common/ComponentAnalytics";

export default class CategoriesManagerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: this.props.action,
    };
    this.onCancel = this.onCancel.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFail = this.onFail.bind(this);
    this.onShowAddCategory = this.onShowAddCategory.bind(this);
    this.onShowEditCategory = this.onShowEditCategory.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.fetchCategories();
  }

  fetchCategories() {
    this.setState({
      loading: true,
    });
    Services.TransactionCategories.get((err, response) => {
      const errors = parseResponseErrors(err, response);
      if (errors) {
        ComponentAnalytics.trackEngineeringEvent(
          "Categories Manager",
          "Error",
          `Services.TransactionCategories.get: ${err}`
        );
        this.setState({
          loading: false,
          message: { severity: "error", messages: errors },
          action: "Manager",
        });
        return;
      }

      this.setState({
        loading: false,
        categories: response.spData,
        action: this.props.action || "Manager",
      });
    });
  }

  onSuccess(category) {
    this.fetchCategories();

    if (category.isCreated) {
      this.setState({
        message: {
          severity: "info",
          messages: [category.name + " category created."],
        },
      });
    }
    if (category.isEdited) {
      this.setState({
        message: {
          severity: "info",
          messages: [category.name + " category edited."],
        },
      });
    }
    if (category.isRemoved) {
      this.setState({
        message: {
          severity: "info",
          messages: [category.name + " category removed."],
        },
      });
    }
  }

  onFail(err, response) {
    const errors = parseResponseErrors(err, response);
    this.setState({
      message: { severity: "error", messages: errors },
      action: "Manager",
      loading: false,
    });
  }

  onCancel() {
    this.fetchCategories();
  }

  onShowEditCategory(e) {
    this.setState({
      action: "Edit",
      category: this.getCategory(
        e.currentTarget.getAttribute("data-category-id")
      ),
      message: undefined,
    });
  }

  onShowAddCategory() {
    this.setState({
      action: "Add",
      message: undefined,
    });
  }

  getCategory(categoryId) {
    categoryId = parseInt(categoryId, 10);
    return this.state.categories.find(
      (category) => category.transactionCategoryId === categoryId
    );
  }

  render() {
    let contentLabel, modalTitle, modalClass;

    switch (this.state.action) {
      case "Add":
        contentLabel = "Add category";
        modalTitle = "Add category";
        modalClass = "pc-modal--small";
        break;
      case "Edit":
        contentLabel = "Edit category";
        modalTitle = "Edit category";
        modalClass = "pc-modal--small";
        break;
      default:
        contentLabel = "Manage categories";
        modalTitle = "Manage categories";
        modalClass = "pc-modal--large";
        break;
    }

    return (
      <Modal
        className={modalClass}
        componentName="CategoriesManager"
        contentLabel={contentLabel}
        title={modalTitle}
        onClosed={this.props.handleModalClosed || this.handleModalClosed}
        loading={this.state.loading}
        isOpen={this.props.isOpen}
      >
        {!this.state.loading && (
          <div>
            {this.state.action === "Manager" && (
              <CategoriesManager
                message={this.state.message}
                categories={this.state.categories}
                loading={this.state.loading}
                onShowEditCategory={this.onShowEditCategory}
                onShowAddCategory={this.onShowAddCategory}
              />
            )}

            {this.state.action === "Add" && (
              <AddCategoryContainer
                errors={this.state.errors}
                categories={this.state.categories}
                categoryName={this.props.categoryName}
                categoryType={this.props.categoryType}
                onCancel={this.props.onCancel || this.onCancel}
                onSuccess={this.props.onSuccess || this.onSuccess}
                onFail={this.onFail}
              />
            )}

            {this.state.action === "Edit" && (
              <EditCategoryContainer
                errors={this.state.errors}
                category={this.state.category}
                onCancel={this.onCancel}
                onSuccess={this.props.onSuccess || this.onSuccess}
                onFail={this.onFail}
              />
            )}
          </div>
        )}
      </Modal>
    );
  }
}

CategoriesManagerContainer.propTypes = {
  isOpen: PropTypes.bool,
  action: PropTypes.string,
  categoryName: PropTypes.string,
  categoryType: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  handleModalClosed: PropTypes.func,
};

CategoriesManagerContainer.defaultProps = {
  isOpen: undefined,
  categoryName: undefined,
  categoryType: undefined,
  onCancel: undefined,
  onSuccess: undefined,
  handleModalClosed: undefined,
  action: "Manager",
};
