import parseResponseErrors from "libs/pcap/utils/response";
import Services from "services";
import accountToClientModel from "accessors/account/mappers/toClient";
import { isEmpty, first, groupBy, flatten } from "underscore";
import {
  updateAccountToServer,
  createAccountToServer,
  stakeholdersToServer,
} from "accessors/account/mappers/toServer";

/**
 * The account accessor module handles client <-> server interactions for account model.
 * This additional service layer was introduced to simplify the representation of the account
 * model for the client-side and to handle multiple API calls within a single transaction.
 */

function fetchAccounts(options) {
  return new Promise((resolve, reject) => {
    const requestParams = {};
    if (options.userAccountId) {
      requestParams.userAccountIds = JSON.stringify([options.userAccountId]);
    }

    if (options.productTypes) {
      requestParams.productTypes = JSON.stringify(options.productTypes);
    }

    const request = options.productTypes
      ? Services.Accounts.getProductAccounts
      : Services.Accounts.get;
    request(requestParams, (err, response) => {
      let errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        return;
      }

      if (
        !response.spData ||
        (!options.productTypes && isEmpty(response.spData.accounts))
      ) {
        reject(["Could not find the account."]);
      } else {
        const accounts = response.spData.accounts.map(accountToClientModel);
        resolve(accounts);
      }
    });
  });
}

/**
 * Retrieves a list of person-account relations (including owners, beneficiaries, college savings recipients, and so on)
 * Optionally includes additional information based on `options` argument.
 *
 * @param {Array}   roles            Required. List of roles to retrieve person-account relations for e.g.
 *                                   'SECONDARY','PRIMARY','CUSTODIAN','GUARDIAN','BENEFICIARY'
 * @param {Number}  personId         Only return person-account relations for person with personId
 * @param {Number}  userAccountId    Only return person-account relations for account with userAccountId
 *
 *
 * @returns {Promise} The request promise.
 */
// eslint-disable-next-line no-unused-vars
export function fetchPersonAccounts(roles, personId, userAccountId) {
  let apiParams = { roles: JSON.stringify(roles) };
  if (personId) {
    apiParams.personId = personId;
  }
  if (userAccountId) {
    apiParams.userAccountId = userAccountId;
  }
  return new Promise((resolve, reject) => {
    Services.PersonAccounts.get(apiParams, (err, response) => {
      let errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        return;
      }
      resolve(response.spData ? response.spData.result : []);
    });
  });
}

function saveAccountInternal(account) {
  // If it has userAccountId it is an Update
  if (account.userAccountId) {
    const request = updateAccountToServer(account);
    return new Promise((resolve, reject) => {
      Services.Accounts.updateManual(request, (err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }

        // More than one account can be returned from API when the firm name is changed on one account
        const accounts =
          (response && response.spData && response.spData.accounts) || [];
        resolve(
          accountToClientModel(
            accounts.find((a) => a.userAccountId === account.userAccountId)
          )
        );
      });
    });
  }
  // Create
  const request = createAccountToServer(account);
  return new Promise((resolve, reject) => {
    Services.Accounts.create(request, function (err, response) {
      let errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        return;
      }

      resolve(
        accountToClientModel(response.spData && first(response.spData.accounts))
      );
    });
  });
}

export function savePersonAccounts(personAccounts, userAccountId, role) {
  personAccounts = personAccounts || [];
  personAccounts = personAccounts.map((b) =>
    Object.assign(b, { userAccountId, role })
  );
  return new Promise((resolve, reject) => {
    Services.PersonAccounts.save(
      {
        userAccountId,
        role,
        personAccounts: JSON.stringify(personAccounts),
      },
      (err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }

        resolve(response.spData.result);
      }
    );
  });
}

function mapOwnersByAccountId(ownersList) {
  const ownersMap = new Map();
  for (const b of ownersList) {
    let array = ownersMap.get(b.userAccountId);
    if (!array) {
      array = [];
    }
    array.push(b);
    ownersMap.set(b.userAccountId, array);
  }

  return ownersMap;
}

function getAccountsInternal(userAccountId, options) {
  const requests = [
    fetchAccounts({
      userAccountId,
      productTypes: options.productTypes,
    }),
  ];

  if (options.personRoles) {
    // Include userAccountId when supported
    requests.push(fetchPersonAccounts(options.personRoles));
  }

  return Promise.all(requests).then(([accounts, personAccounts]) => {
    if (personAccounts) {
      const ownersMap = mapOwnersByAccountId(personAccounts);
      accounts.forEach((a) => {
        a.stakeholders = groupBy(ownersMap.get(a.userAccountId), "role");
      });
    }

    return accounts.map(accountToClientModel);
  });
}

/**
 * Retrieves the account by `userAccountId`.
 * Optionally includes additional information based on `options` argument.
 *
 * @param {Number}  userAccountId             the account id
 * @param {Object}  [options]                 the options
 * @param {Array}   options.personRoles       List of roles to retrieve person-account relations for e.g.
 *                                            'SECONDARY','PRIMARY','CUSTODIAN','GUARDIAN','BENEFICIARY'
 *
 * @returns {Promise} The request promise.
 */
export function getAccount(userAccountId, options = {}) {
  return getAccountsInternal(userAccountId, options).then((accounts) =>
    first(accounts)
  );
}

/**
 * Retrieves all user accounts.
 * Optionally includes additional information based on `options` argument.
 *
 * @param {Object}  [options]                 the options
 * @param {Boolean} options.productTypes      array of strings of product account types
 * @param {Array}   options.personRoles       list of roles to retrieve person-account relations for
 *                                            e.g. 'SECONDARY','PRIMARY','CUSTODIAN','GUARDIAN','BENEFICIARY'
 *
 * @returns {Promise} The request promise.
 */
export function getAccounts(options = {}) {
  return getAccountsInternal(null, options);
}

/**
 * Remove all the accounts specified in the userAccountIds param
 *
 * @param {Array}  userAccountIds The userAccountIds of the accounts that you want to remove
 *
 * @returns {Promise} The request promise.
 */
export function removeAccounts(userAccountIds) {
  return new Promise((resolve, reject) => {
    Services.Accounts.remove(
      { userAccountIds: JSON.stringify(userAccountIds) },
      (err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }
        resolve(response);
      }
    );
  });
}

/**
 * Remove all the accounts specified in the accountIds param
 *
 * @param {Array}  accountIds The accountIds of the accounts that you want to remove
 *
 * @returns {Promise} The request promise.
 */
export function removeAccountsByAccountId(accountIds) {
  return new Promise((resolve, reject) => {
    Services.Accounts.remove(
      { accountIds: JSON.stringify(accountIds) },
      (err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }
        resolve(response);
      }
    );
  });
}

/**
 * Remove all the accounts specified in the accountIds param only for Manual Accounts
 *
 * @param {Array}  userAccountIds The userAccountIds of the accounts that you want to remove
 *
 * @returns {Promise} The request promise.
 */
export function removeManualAccountsByUserAccountId(userAccountIds) {
  return new Promise((resolve, reject) => {
    Services.Accounts.removeManualAccounts(
      { userAccountIds: JSON.stringify(userAccountIds) },
      (err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }
        resolve(response);
      }
    );
  });
}

/**
 * Remove all the acccounts that have account type disabled and are in the specified in the userAccountIds param
 *
 * @param {Array}  userAccountIds The userAccountIds of the accounts that you want to remove
 *
 * @returns {Promise} The request promise.
 */
export function removeDisabledAccount(userAccountIds) {
  return new Promise((resolve, reject) => {
    Services.Accounts.removeDisabledAccount(
      { userAccountIds: JSON.stringify(userAccountIds) },
      (err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }
        resolve(response);
      }
    );
  });
}

/**
 * Saves the provided account object.
 * Optionally processes the portions of the object specified by `options` argument.
 *
 * @param {Object}  account                   the account object
 * @param {Object}  options                   the options
 *
 * @returns {Promise} The request promise.
 */
export function saveAccount(account) {
  return new Promise((resolve, reject) => {
    saveAccountInternal(account)
      .then((accountSaved) => {
        const requests = [];
        const stakeholders = stakeholdersToServer(account);
        if (!isEmpty(stakeholders)) {
          // Present API behavior allows saving of only one role type at a time
          for (const [key, value] of Object.entries(stakeholders)) {
            requests.push(
              savePersonAccounts(value, accountSaved.userAccountId, key)
            );
          }
        }

        if (requests.length === 0) {
          resolve(accountSaved);
          return;
        }

        return Promise.all(requests)
          .then((responses) => {
            const personAccounts = flatten(responses);

            accountSaved.stakeholders = groupBy(personAccounts, "role");
            resolve(accountSaved);
          })
          .catch((errors) => {
            reject(errors);
          });
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}
