var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"menuitem\" aria-label=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\" class=\"menu__item "
    + ((stack1 = __default(require("../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"selectedQuickPick") : depths[1])) != null ? lookupProperty(stack1,"index") : stack1),"==",(depth0 != null ? lookupProperty(depth0,"index") : depth0),{"name":"compare","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":74},"end":{"line":22,"column":156}}})) != null ? stack1 : "")
    + "\">\n          <a href=\"#\" class=\"menu__action js-period-item qa-period-item\" data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</a>\n        </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected u-text-bold";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"empower-date-range__inputs\">\n      <!-- Set type to `search` to prevent LastPass from auto filling these fields -->\n      <label class=\"date-range__label qa-date-range-label\">Date range\n        <input\n          type=\"search\"\n          class=\"input input--tiny date-range__input date js-start-date qa-start-date\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fromString") : depth0), depth0))
    + "\"\n          name=\"date-range-start-date\"\n        />\n      </label>\n      <label>\n        <input\n          type=\"search\"\n          class=\"input input--tiny date-range__input date js-end-date qa-end-date\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"endString") : depth0), depth0))
    + "\"\n          name=\"date-range-end-date\"\n        />\n      </label>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"date-range__inputs\">\n      <label class=\"date-range__label from-date__label\" for=\"date-range-start-date\">\n        From\n        <input\n          type=\"search\"\n          class=\"input input--tiny date-range__input date js-start-date qa-start-date\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fromString") : depth0), depth0))
    + "\"\n          name=\"date-range-start-date\"\n          id=\"date-range-start-date\"\n          aria-label=\"from date\"\n        />\n      </label>\n      <!-- Set type to `search` to prevent LastPass from auto filling these fields -->\n      <label class=\"date-range__label qa-date-range-label\" for=\"date-range-end-date\"> to\n        <input\n          type=\"search\"\n          class=\"input input--tiny date-range__input date js-end-date qa-end-date\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"endString") : depth0), depth0))
    + "\"\n          name=\"date-range-end-date\"\n          id=\"date-range-end-date\"\n          aria-label=\"to date\"\n        />\n      </label>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset aria-label=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fieldsetLabel") : depth0), depth0))
    + "\">\n  <div class=\"date-range\">\n    <div class=\"dropdown qa-date-dropdown\">\n      <div\n      role=\"button\"\n      aria-controls=\"date-range-selector\"\n      class=\"empower-account-selector-container empower-date-selector-container js-date-selector-btn qa-date-selector-btn u-sentence-case\"\n      data-toggle=\"pc-dropdown\" tabindex=\"0\">\n        <div class=\"empower-account-selector-label u-sentence-case\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"buttonLabel") : depth0), depth0))
    + "</div>\n        <div class=\"empower-account-selector-indicator\">\n          <div class=\"pc-toggle pc-triangle-down--small\">\n          </div>\n        </div>\n      </div>\n      <ul \n        id=\"date-range-selector\" \n        class=\"menu menu--vertical menu--bordered menu--tiny date-range__list js-period-list qa-date-range-list u-text-left\" \n        role=\"menu\"\n        aria-label=\"select a time range\"\n        >\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"quickPicks") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":25,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":73,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</fieldset>\n";
},"useData":true,"useDepths":true});