// taken from stockOptionsCtrl $scope.vestingSchedules
export default [
  {
    value: 0,
    label: "1 year, vests monthly",
    vestingCliffMonth: 0,
    vestingIntervalMonth: 1,
    vestingTotalMonth: 12,
  },
  {
    value: 1,
    label: "4 year, vests monthly",
    vestingCliffMonth: 0,
    vestingIntervalMonth: 1,
    vestingTotalMonth: 48,
  },
  {
    value: 2,
    label: "4 year, vests monthly , 1 year cliff",
    vestingCliffMonth: 12,
    vestingIntervalMonth: 1,
    vestingTotalMonth: 48,
  },
  {
    value: 3,
    label: "5 year, vests monthly",
    vestingCliffMonth: 0,
    vestingIntervalMonth: 1,
    vestingTotalMonth: 60,
  },
  {
    value: 4,
    label: "5 year, vests monthly , 1 year cliff",
    vestingCliffMonth: 12,
    vestingIntervalMonth: 1,
    vestingTotalMonth: 60,
  },
  {
    isCustom: true,
    value: 5,
    label: "Custom Vesting Schedule",
    vestingCliffMonth: "",
    vestingIntervalMonth: 1,
    vestingTotalMonth: "",
  },
];
