/* eslint-disable no-magic-numbers */

const REBRAND_LIABILITY_COLORS_4 = ["#ff490d", "#ff8c40", "#ffd119", "#fef200"];

const REBRAND_LIABILITY_COLORS_7 = [
  "#d62029",
  "#ff6640",
  "#ff8c40",
  "#ffbb19",
  "#ffd119",
  "#fef200",
  "#feff82",
];

const REBRAND_LIABILITY_COLORS_15 = [
  "#d62029",
  "#ff490d",
  "#ff6640",
  "#ff8c40",
  "#ffbb19",
  "#ffd119",
  "#fef200",
  "#feff82",
  "#6ad1e3",
  "#295fa6",
  "#6a7ce6",
  "#5a50e6",
  "#a255d9",
  "#db3d72",
  "#ff6ba6",
];

const REBRAND_LIABILITY_COLORS_27 = [
  "#d62029",
  "#ff490d",
  "#f28c24",
  "#ff9e15",
  "#ffbb19",
  "#ffd119",
  "#fef200",
  "#ddf218",
  "#bee817",
  "#97d700",
  "#94eb49",
  "#9bff82",
  "#70f067",
  "#43e075",
  "#51c48c",
  "#3fd8b6",
  "#67e6d9",
  "#48c2e0",
  "#009cde",
  "#0077cc",
  "#0047bb",
  "#6a7ce6",
  "#5a50e6",
  "#8d5eeb",
  "#771d8b",
  "#db3d72",
  "#fd87d1",
];

const REBRAND_LIABILITY_COLORS_40 = [
  "#ff490d",
  "#ff6640",
  "#f28c24",
  "#ff9e15",
  "#ffbb19",
  "#ffd119",
  "#fef200",
  "#feff82",
  "#ddf218",
  "#bee817",
  "#ade014",
  "#97d700",
  "#94eb49",
  "#9bff82",
  "#70f067",
  "#45e650",
  "#49f26b",
  "#47d685",
  "#51c48c",
  "#3fd8b6",
  "#67e6d9",
  "#6ad1e3",
  "#48c2e0",
  "#2db4e0",
  "#418fde",
  "#0077cc",
  "#3754a5",
  "#295fa6",
  "#6a7ce6",
  "#5e65eb",
  "#5a50e6",
  "#8d5eeb",
  "#a365e6",
  "#771d8b",
  "#b522a9",
  "#d132af",
  "#db3d72",
  "#f25ebe",
  "#fd87d1",
  "#ff6ba6",
];

const REBRAND_ASSET_COLORS_4 = ["#0077cc", "#009cde", "#48c2e0", "#67e6d9"];

const REBRAND_ASSET_COLORS_7 = [
  "#0047bb",
  "#0077cc",
  "#009cde",
  "#48c2e0",
  "#67e6d9",
  "#51c48c",
  "#43e075",
];

const REBRAND_ASSET_COLORS_15 = [
  "#0047bb",
  "#0077cc",
  "#009cde",
  "#48c2e0",
  "#67e6d9",
  "#43e075",
  "#9bff82",
  "#97d700",
  "#bee817",
  "#feff82",
  "#ffd119",
  "#fd87d1",
  "#db3d72",
  "#771d8b",
  "#8d5eeb",
];

const REBRAND_ASSET_COLORS_27 = [
  "#51c48c",
  "#3fd8b6",
  "#67e6d9",
  "#48c2e0",
  "#009cde",
  "#0077cc",
  "#0047bb",
  "#6a7ce6",
  "#5a50e6",
  "#8d5eeb",
  "#771d8b",
  "#db3d72",
  "#fd87d1",
  "#ff6ba6",
  "#ff490d",
  "#f28c24",
  "#ff9e15",
  "#ffbb19",
  "#ffd119",
  "#fef200",
  "#ddf218",
  "#bee817",
  "#97d700",
  "#94eb49",
  "#9bff82",
  "#70f067",
  "#43e075",
];

const REBRAND_ASSET_COLORS_40 = [
  "#51c48c",
  "#3fd8b6",
  "#67e6d9",
  "#6ad1e3",
  "#48c2e0",
  "#2db4e0",
  "#418fde",
  "#0077cc",
  "#3754a5",
  "#295fa6",
  "#6a7ce6",
  "#5e65eb",
  "#5a50e6",
  "#8d5eeb",
  "#a365e6",
  "#771d8b",
  "#b522a9",
  "#d132af",
  "#db3d72",
  "#f25ebe",
  "#fd87d1",
  "#ff6ba6",
  "#ff264e",
  "#ff6640",
  "#f28c24",
  "#ff9e15",
  "#ffbb19",
  "#ffd119",
  "#fef200",
  "#feff82",
  "#ddf218",
  "#bee817",
  "#ade014",
  "#97d700",
  "#94eb49",
  "#9bff82",
  "#70f067",
  "#45e650",
  "#49f26b",
  "#47d685",
];

const rebrandColors = {
  assetColors4: REBRAND_ASSET_COLORS_4,
  assetColors7: REBRAND_ASSET_COLORS_7,
  assetColors15: REBRAND_ASSET_COLORS_15,
  assetColors27: REBRAND_ASSET_COLORS_27,
  assetColors40: REBRAND_ASSET_COLORS_40,
  liabilityColors4: REBRAND_LIABILITY_COLORS_4,
  liabilityColors7: REBRAND_LIABILITY_COLORS_7,
  liabilityColors15: REBRAND_LIABILITY_COLORS_15,
  liabilityColors27: REBRAND_LIABILITY_COLORS_27,
  liabilityColors40: REBRAND_LIABILITY_COLORS_40,
};

export default {
  getRebrandColorSet(numberOfItems, colorFamily) {
    let colorSet;

    if (numberOfItems <= 4) {
      colorSet = rebrandColors[colorFamily + "Colors4"];
    } else if (numberOfItems <= 7) {
      colorSet = rebrandColors[colorFamily + "Colors7"];
    } else if (numberOfItems <= 15) {
      colorSet = rebrandColors[colorFamily + "Colors15"];
    } else if (numberOfItems <= 27) {
      colorSet = rebrandColors[colorFamily + "Colors27"];
    } else {
      colorSet = rebrandColors[colorFamily + "Colors40"];
    }

    return colorSet;
  },
};
