import PropTypes from "prop-types";
import React from "react";
import PlanListRow from "components/sidebar/PlanListRow";
import eventBus from "eventBus";
import {
  PLAN_COMPARISON_SIDE_LEFT,
  PLAN_COMPARISON_SIDE_RIGHT,
} from "components/financialPlanner/financialPlannerConstants";

function renderPlan(plan, planProps) {
  const { activePlanIds } = planProps;
  return (
    <PlanListRow
      key={plan.planId}
      isEditable={false}
      isDraggable={true}
      plan={plan}
      isActive={activePlanIds.includes(plan.planId)}
    />
  );
}
export default class ComparePlanList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { leftPlanId: props.activePlanId, rightPlanId: null };
    eventBus.on("plancomparison:planselected", this.selectPlan, this);
    eventBus.on("plancomparison:planremoved", this.removePlan, this);
  }

  selectPlan(planId, side) {
    if (side === "left") {
      this.setState({ leftPlanId: planId });
    } else {
      this.setState({ rightPlanId: planId });
    }
  }

  removePlan(side) {
    if (side === PLAN_COMPARISON_SIDE_LEFT) {
      this.setState({ leftPlanId: null });
    }

    if (side === PLAN_COMPARISON_SIDE_RIGHT) {
      this.setState({ rightPlanId: null });
    }
  }

  componentWillUnmount() {
    eventBus.off("plancomparison:planselected", this.selectPlan);
    eventBus.off("plancomparison:planremoved", this.removePlan);
  }

  render() {
    const newProps = Object.assign(
      {
        activePlanIds: [this.state.leftPlanId, this.state.rightPlanId],
        renderPlan,
      },
      this.props
    );

    return this.props.render(newProps);
  }
}

ComparePlanList.propTypes = {
  masterPlan: PropTypes.array,
  alternativePlans: PropTypes.array,
  advisorPlans: PropTypes.array,
  activePlanId: PropTypes.number,
  plans: PropTypes.array,
  errors: PropTypes.array,
  render: PropTypes.func,
};
