/*---------------------------------------*/
// #OWNERSHIP TYPES
/*---------------------------------------*/

export const OWNERSHIP_TYPE_INDIVIDUAL = "INDIVIDUAL";
export const OWNERSHIP_TYPE_JOINT = "JOINT";
export const OWNERSHIP_TYPE_INDIVIDUAL_TOD = "INDIVIDUAL_TOD";
export const OWNERSHIP_TYPE_JOINT_TOD = "JOINT_TOD";

export default [
  { value: OWNERSHIP_TYPE_INDIVIDUAL, label: "Individual" },
  { value: OWNERSHIP_TYPE_JOINT, label: "Joint" },
  {
    value: OWNERSHIP_TYPE_INDIVIDUAL_TOD,
    label: "Individual Transfer on Death",
  },
  { value: OWNERSHIP_TYPE_JOINT_TOD, label: "Joint Transfer on Death" },
];
