import Backbone from "backbone";
import React from "react";
import ReactDOM from "react-dom";
import ReferralCenterContainer from "./ReferralCenterContainer";
import {
  getIsEnabledPromise,
  getFunnelAttributesPromise,
} from "components/shareWithFriends/ShareWithFriendsUtils";
import getAbFrameworkPromise from "libs/pcap/utils/getAbFrameworkPromise";
import { trackEvent } from "components/common/ComponentAnalytics";
import utils from "libs/pcap/utils/utils";
import Services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import { REFERRAL_GIFT_BOX_ICON_DISPLAY_LOCATION } from "views/modules/postLoginActions";

const COMPONENT_NAME = "Referral Center";

export default Backbone.View.extend({
  initialize: function (options) {
    this.$el = options.$el;

    const watchForUserMessages = function () {
      return new Promise((resolve, reject) => {
        Services.UserMessages.getV2.watch((error, response) => {
          const errors = parseResponseErrors(error, response);
          if (
            errors ||
            !response ||
            !response.spData ||
            !response.spData.userMessages
          ) {
            reject(errors);
          } else {
            resolve(response.spData.userMessages);
          }
        });
      });
    };

    Promise.all([
      getIsEnabledPromise(),
      getFunnelAttributesPromise(),
      getAbFrameworkPromise({
        path: "referralCenter",
      }),
      watchForUserMessages(),
    ]).then(
      ([isEnabled, funnelAttrs, referralCenterAbFrmwrk, userMessages]) => {
        let showReferralCenter = false;
        userMessages.forEach((msg) => {
          if (
            msg.displayLocations &&
            msg.displayLocations.includes(
              REFERRAL_GIFT_BOX_ICON_DISPLAY_LOCATION
            )
          ) {
            showReferralCenter = msg;
          }
        });
        if (
          !isEnabled ||
          funnelAttrs.isSuspicious ||
          !funnelAttrs.referralCampaign ||
          !showReferralCenter
        ) {
          this.render({
            ...options,
            isNotAvailable: true,
            funnelAttrs,
            referralCenterAbFrmwrk,
          });
          this.trigger("doneLoading");
          trackEvent(COMPONENT_NAME, "Attempt to View Referral Center", {
            isUserReferralEnabled: isEnabled,
            isSuspicious: funnelAttrs.isSuspicious,
            referralCampaign: funnelAttrs.referralCampaign,
          });
          return;
        }

        this.talkableReferralShortUrl = utils.ensureUrlHasHttps(
          funnelAttrs.talkableReferralShortUrl ||
            funnelAttrs.talkableReferralUrl
        );
        this.trigger("doneLoading");
        this.render({ ...options, funnelAttrs, referralCenterAbFrmwrk });
      }
    );
    $("#moduleContainer").scrollTop(0);
  },

  renderReactComponent: function (props) {
    const referralUrl = this.talkableReferralShortUrl;
    const referralContainerProps = {
      ...props,
      referralUrl,
    };

    if (!props.isNotAvailable) {
      $("body").addClass("referral-center-body");
    }
    ReactDOM.render(
      React.createElement(ReferralCenterContainer, {
        ...referralContainerProps,
      }),
      this.parentContainer
    );
    window.scrollTo(0, 0);
  },

  remove: function () {
    $("body").removeClass("referral-center-body");
    ReactDOM.unmountComponentAtNode(this.parentContainer);
    Backbone.View.prototype.remove.apply(this, arguments);
  },

  render: function (options) {
    const referralCenterWrapper = $("#referralCenter");
    this.parentContainer = referralCenterWrapper.get(0);
    this.renderReactComponent(options);
    return this;
  },

  updateView: function (options) {
    this.renderReactComponent(options);
  },
});
