import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { promisify } from "utils/service";
import Services from "services";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import TransferFundsWithdrawalTaxWithholding from "components/transferFunds/TransferFundsWithdrawalTaxWithholding";
import { isEqual, isUndefined, noop } from "underscore";

const TransferFundsWithdrawalTaxWithholdingContainer = (props) => {
  const {
    taxWithholdingsMinimumService,
    getTotalAmountAfterTaxForWithdrawalService,
    getPerson,
    model,
    storeInputRef,
    onChange,
    onEditFederalTaxWithholding,
  } = props;
  const {
    sourceAccountId,
    transferAmount,
    taxWithholdingFederal,
    taxWithholdingState,
  } = model;
  const cancelableTaxMinimumsService = useRef();
  const cancelableTotalAmountAfterTaxService = useRef();
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState();
  const [taxWithholdingsMinimum, setTaxWithholdingsMinimum] = useState();
  const [totalAmountAfterTax, setTotalAmountAfterTax] = useState();
  const [errors, setErrors] = useState();
  const [apiProps, setApiProps] = useState();

  const handleTotalAmountAfterTaxSuccess = (response) => {
    setTotalAmountAfterTax(response);
    setApiProps(response);

    setLoading(false);
    setErrors(undefined);
  };

  const handleFailure = (errors) => {
    if (
      cancelableTaxMinimumsService?.current?.isCanceled() ||
      isEqual(errors, { isCanceled: true })
    ) {
      return;
    }

    setLoading(false);
    setErrors(errors);
  };

  useEffect(() => {
    getPerson().then((response) => {
      setUserId(response.id);
    });
  }, [getPerson]);

  useEffect(() => {
    setApiProps({ ...totalAmountAfterTax, ...taxWithholdingsMinimum });
  }, [taxWithholdingsMinimum, totalAmountAfterTax]);

  useEffect(() => {
    if (userId) {
      const handleTaxWithholdingsMinimumSuccess = (response) => {
        setTaxWithholdingsMinimum(response);
        setLoading(false);
        setErrors(undefined);
      };

      setLoading(true);
      cancelableTaxMinimumsService.current = makeCancelablePromise(
        taxWithholdingsMinimumService({
          userAccountId: sourceAccountId,
          distributionAmount: transferAmount,
          userId,
        })
      );
      cancelableTaxMinimumsService.current.promise.then(
        handleTaxWithholdingsMinimumSuccess,
        handleFailure
      );

      return () => {
        if (cancelableTaxMinimumsService.current) {
          cancelableTaxMinimumsService.current.cancel();
        }
      };
    }
  }, [
    cancelableTaxMinimumsService,
    taxWithholdingsMinimumService,
    sourceAccountId,
    transferAmount,
    userId,
  ]);

  useEffect(() => {
    if (
      !isUndefined(taxWithholdingsMinimum?.stateTaxRateBase) &&
      !isUndefined(taxWithholdingsMinimum?.stateTaxRateType) &&
      !isUndefined(taxWithholdingFederal) &&
      !isUndefined(taxWithholdingState) &&
      parseFloat(taxWithholdingState) >= 0 &&
      parseFloat(taxWithholdingFederal) >= 0
    ) {
      setLoading(true);

      const requestParams = {
        distributionAmount: transferAmount,
        federalTaxRate: `${taxWithholdingFederal}`,
        stateTaxRateBase: taxWithholdingsMinimum.stateTaxRateBase,
        stateTaxRateType: taxWithholdingsMinimum.stateTaxRateType,
      };

      if (taxWithholdingsMinimum.stateTaxRateType === "PERCENTAGE") {
        requestParams.stateTaxRatePercentage = `${taxWithholdingState}`;
      } else {
        requestParams.stateTaxRateFlatAmount = `${taxWithholdingState}`;
      }

      cancelableTotalAmountAfterTaxService.current = makeCancelablePromise(
        getTotalAmountAfterTaxForWithdrawalService({ ...requestParams })
      );

      cancelableTotalAmountAfterTaxService.current.promise.then(
        handleTotalAmountAfterTaxSuccess,
        handleFailure
      );

      return () => {
        if (cancelableTotalAmountAfterTaxService.current) {
          cancelableTotalAmountAfterTaxService.current.cancel();
        }
      };
    }
  }, [
    taxWithholdingState,
    taxWithholdingFederal,
    taxWithholdingsMinimum?.stateTaxRateBase,
    taxWithholdingsMinimum?.stateTaxRateType,
    getTotalAmountAfterTaxForWithdrawalService,
    sourceAccountId,
    transferAmount,
  ]);

  return (
    <TransferFundsWithdrawalTaxWithholding
      errors={errors}
      loading={loading}
      taxWithholdingFederal={model.taxWithholdingFederal}
      taxWithholdingState={model.taxWithholdingState}
      federalTaxOptOut={model.federalTaxOptOut}
      stateTaxOptOut={model.stateTaxOptOut}
      stateTaxModified={model.stateTaxModified}
      storeInputRef={storeInputRef}
      handleChange={onChange}
      onEditFederalTaxWithholding={onEditFederalTaxWithholding}
      transferAmount={transferAmount}
      data-testid="js-transfer-funds-withdrawal-tax-withholding"
      {...apiProps}
    />
  );
};

export default TransferFundsWithdrawalTaxWithholdingContainer;

TransferFundsWithdrawalTaxWithholdingContainer.propTypes = {
  taxWithholdingsMinimumService: PropTypes.func,
  getTotalAmountAfterTaxForWithdrawalService: PropTypes.func,
  getPerson: PropTypes.func,
  model: PropTypes.object,
  storeInputRef: PropTypes.func,
  onChange: PropTypes.func,
  onEditFederalTaxWithholding: PropTypes.func,
};

TransferFundsWithdrawalTaxWithholdingContainer.defaultProps = {
  taxWithholdingsMinimumService: promisify(
    Services.Transfer.getTaxWithholdingsMinimum
  ),
  getTotalAmountAfterTaxForWithdrawalService: promisify(
    Services.Transfer.getTotalAmountAfterTaxForWithdrawal
  ),
  getPerson: promisify(Services.Person.get),
  model: undefined,
  storeInputRef: noop,
  onChange: noop,
  onEditFederalTaxWithholding: noop,
};
