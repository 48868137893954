/* eslint-disable react/prop-types, react/display-name */

import React from "react";
import FancySelect from "components/common/form/FancySelect";
import AccountOption from "./AccountOption";
import AccountBox from "components/AccountBox/AccountBox";
import PRODUCT_TYPE_LABEL_MAP from "components/common/form/AccountSelect/productTypeLabelMap";

export function accountsToOptions(
  accounts,
  { valuePropName = "userAccountId", stateType } = {}
) {
  if (accounts) {
    const options = accounts.map((a) => ({
      value: a[valuePropName],
      logoPath: a.logoPath,
      firmName: a.firmName,
      name: a.name,
      balance: a.balance,
      isOnUs: a.isOnUs,
      isOnUsBank: a.isOnUsBank,
      action: stateType && a.stateForTransfer[stateType].action,
      state: stateType && a.stateForTransfer[stateType].state,
      productType: a.productType,
      stateForTransfer: a.stateForTransfer,
      availableCash: a.availableCash,
      availableBalance: a.availableBalance,
    }));

    const uniqueProductTypes = Array.from(
      new Set(options.map((o) => o.productType))
    ).sort();

    // When there is only one type of product
    // we will not show groups in the drop-down
    if (uniqueProductTypes.length <= 1) {
      return options;
    }

    return uniqueProductTypes.map((productType) => ({
      label: PRODUCT_TYPE_LABEL_MAP[productType] || productType,
      options: options.filter((o) => o.productType === productType),
    }));
  }
}

function optionLabelFormatter(option) {
  return `${option.firmName} ${option.name} $${option.balance} balance`;
}

/**
 * AccountSelect is a form input for selecting an account.
 * @param {Object} props any param FancySelect accepts.
 * @return {React.ReactElement} react component.
 */
const AccountSelect = (props) => {
  const { forwardedRef } = props;
  return (
    <FancySelect
      {...props}
      isSearchable={false}
      ref={forwardedRef}
      getOptionLabel={props.getOptionLabel}
    />
  );
};

export const AccountBoxValueRenderer = (props) => {
  return <AccountBox className="account-box--option" {...props} />;
};

AccountSelect.defaultProps = {
  getOptionLabel: optionLabelFormatter,
  optionRenderer: AccountOption,
  valueRenderer: AccountBoxValueRenderer,
  className: "Select--account",
  menuClassName: "Select--account",
  maxMenuHeight: 440,
};

export default React.forwardRef((props, ref) => {
  return <AccountSelect {...props} forwardedRef={ref} />;
});
