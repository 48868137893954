import React from "react";
import Input from "components/common/form/Input";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import BaseHoldingsEditRow from "../BaseHoldingsEditRow";
import { formatCurrency } from "libs/pcap/utils/format";
import deepCopy from "deep-copy";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";
import { noop } from "underscore";

class AggregatedHoldingsEditRow extends BaseHoldingsEditRow {
  constructor(props) {
    super(props);
    this.handleFetchQuote = this.handleFetchQuote.bind(this);
    this.handleOnRevert = this.handleOnRevert.bind(this);
    this.tickerValidator = Object.assign(
      { conform: this.isNotExistingHolding },
      props.schema.properties.ticker
    );
  }

  handleFetchQuote(ev) {
    if (
      ev.target.value !== "" &&
      this.props.onFetchQuote &&
      this.isNotExistingHolding(ev.target.value)
    ) {
      let model = deepCopy(this.state.model);
      model.cusip = "";
      model.description = "";
      model.costBasis = "";
      model.ticker = ev.target.value;
      this.setState({
        model,
        errors: undefined,
        loading: true,
      });
      this.props
        .onFetchQuote(ev.target.value)
        .then((security) => {
          model.ticker = security.ticker;
          model.description = security.description;
          model.price = security.price;
          model.cusip = security.cusip;
          this.setState({
            model,
            loading: false,
          });
        })
        .catch((errors) => {
          this.setState({
            errors,
            loading: false,
          });
        });
    }
  }

  handleOnRevert() {
    const event = { target: { value: this.state.model.originalTicker } };
    this.handleFetchQuote(event);
  }

  showRevert() {
    const {
      originalTicker,
      ticker,
      originalDescription,
      description,
    } = this.state.model;
    return Boolean(
      originalTicker &&
        originalDescription &&
        (originalTicker !== ticker || originalDescription !== description)
    );
  }

  render() {
    const { columns, onCancel, schema } = this.props;
    const { model, loading } = this.state;

    const isPrivileged = isEmpowerPrivilegedMode();

    return (
      <form
        className="table__row--edit pc-bg-dark qa-edit-holding-form"
        onSubmit={isPrivileged ? noop : this.handleSubmit}
      >
        <LoadingOverlay active={loading} />
        <div className="holdings-table__edit-row-inner">
          <div
            className={`${columns[0].className} table__column table__column--holdings-edit-cell`}
          >
            <Input
              ref={this.storeInputRef}
              className={"qa-edit-holdings-ticker"}
              type="text"
              name="ticker"
              placeholder={"Ticker or CUSIP"}
              value={model.ticker}
              validator={this.tickerValidator}
              errorBlockClassName="table__row--edit"
              onChange={this.handleInputChange}
              onBlur={this.handleFetchQuote}
              sizeVariation="full"
              disabled={loading}
            />
          </div>
          <div
            className={`table__column table__column--holdings-edit-cell ${columns[1].className}`}
          >
            <div className="pc-u-pt--">{model.quantity}</div>
          </div>
          <div
            className={`table__column table__column--holdings-edit-cell ${columns[2].className}`}
          >
            <div className="pc-u-pt--">{formatCurrency(model.price)}</div>
          </div>
          <div
            className={`table__column table__column--holdings-edit-cell ${columns[3].className}`}
          >
            <div className="pc-u-pt--">
              {formatCurrency(
                model.price && model.quantity ? model.price * model.quantity : 0
              )}
            </div>
          </div>
        </div>
        <div className="holdings-table__edit-row-inner">
          <div
            className={`table__column table__column--holdings-edit-cell pc-u-pt0 ${columns[0].className}`}
          >
            <label className="pc-label">Description</label>
            <Input
              ref={this.storeInputRef}
              type="text"
              name="description"
              value={model.description}
              validator={schema.properties.description}
              errorBlockClassName="is-hidden"
              onChange={this.handleInputChange}
              sizeVariation="full"
              disabled={loading}
            />
            {this.state.errors && <Message messages={this.state.errors} />}
          </div>
          <div className="table__column table__column--aggregated-holdings-edit-actions">
            {this.showRevert() && (
              <button
                aria-disabled={isPrivileged}
                type="button"
                className={`pc-btn pc-btn--small qa-revert-holding ${isPrivileged ? "is-disabled" : ""}`}
                onClick={isPrivileged ? noop : this.handleOnRevert}
              >
                Revert
              </button>
            )}
            <button
              type="button"
              className="pc-btn pc-btn--small qa-close-edit-holding"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              aria-disabled={isPrivileged}
              type="submit"
              className={`pc-btn pc-btn--small pc-btn--primary qa-save-holding ${isPrivileged ? "is-disabled" : ""}`}
            >
              Done
            </button>
          </div>
        </div>
      </form>
    );
  }
}

AggregatedHoldingsEditRow.defaultProps = BaseHoldingsEditRow.defaultProps;
AggregatedHoldingsEditRow.propTypes = BaseHoldingsEditRow.propTypes;

export default AggregatedHoldingsEditRow;
