const UNDERSCORE_REGEXP = /_/g;
export const SOCIAL_SECURITY_INCOME = "SOCIAL_SECURITY_INCOME";
export const SPOUSE_SOCIAL_SECURITY_INCOME = "SPOUSE_SOCIAL_SECURITY_INCOME";
export const ANNUAL_SAVINGS = "ANNUAL_SAVINGS";
export const RETIREMENT_SPENDING = "RETIREMENT_SPENDING";
const RETIRED_SPOUSE_SPENDING = "RETIRED_SPOUSE_SPENDING";
const SOCIAL_SECURITY = "Social Security";
const EDUCATION = "EDUCATION";
export const PAYCHECK_ATTRIBUTION_SOURCE = "RETIREMENT_PAYCHECK_PROMO";
export const EXPENSE_SIGN = -1;

export function getEventIconClass(goalCategoryKey) {
  return goalCategoryKey
    .toLowerCase()
    .replace(UNDERSCORE_REGEXP, "-")
    .replace("spouse-", "");
}

export function getEventTitle(
  goalDescription,
  goalCategoryKey,
  personName,
  spouseName
) {
  let eventTitle = goalDescription;

  if (goalCategoryKey === SOCIAL_SECURITY_INCOME) {
    eventTitle = personName
      ? `${personName}'s ${SOCIAL_SECURITY}`
      : SOCIAL_SECURITY;
  } else if (goalCategoryKey === SPOUSE_SOCIAL_SECURITY_INCOME) {
    eventTitle = spouseName
      ? `${spouseName}'s ${SOCIAL_SECURITY}`
      : `Spouse's ${SOCIAL_SECURITY}`;
  } else if (goalCategoryKey === RETIREMENT_SPENDING) {
    eventTitle = "Retirement Spending";
  }

  return eventTitle;
}

// Sort goals chronologically, then by goal description alphabetically
export function sortGoals(a, b) {
  if (a.startAge < b.startAge) {
    return -1;
  }
  if (a.startAge > b.startAge) {
    return 1;
  }

  if (a.startAge === b.startAge) {
    if (a.goalDescription < b.goalDescription) {
      return -1;
    }
    if (a.goalDescription > b.goalDescription) {
      return 1;
    }
  }

  return 0;
}

/**
 * Augments a goal with its adjusted amount for the year - reflecting tax, COLA adjustment, etc
 *
 * @param {object} goal - The goal that needs an adjusted amount added to it
 * @param {object} yearDetails - The entry from the retirementPaycheck.detail array for the year in question
 *
 * @returns {object} - The goal augmented with field adjustedAnnualAmount
 */
export function addAdjustedAmountToGoal(goal, yearDetails) {
  let adjustedAnnualAmount;
  switch (goal.goalCategoryKey) {
    case RETIREMENT_SPENDING:
      adjustedAnnualAmount = yearDetails.retirementSpending;
      break;
    case SOCIAL_SECURITY_INCOME:
      adjustedAnnualAmount = yearDetails.ssa;
      break;
    case SPOUSE_SOCIAL_SECURITY_INCOME:
      adjustedAnnualAmount = yearDetails.spouseSsa;
      break;
    case EDUCATION:
    case RETIRED_SPOUSE_SPENDING:
      adjustedAnnualAmount = goal.annualAmount;
      break;
    default:
      adjustedAnnualAmount =
        (yearDetails.mapGoal2Amount &&
          yearDetails.mapGoal2Amount[goal.goalKey]) ||
        0;
      break;
  }

  if (goal.goalType === "EXPENSE") {
    adjustedAnnualAmount *= EXPENSE_SIGN;
  }
  return Object.assign({}, goal, { adjustedAnnualAmount });
}

export function humanizeTaxStatus(taxStatusKey) {
  switch (taxStatusKey) {
    case "taxable":
      return "Taxable Accounts";

    case "taxDeferred":
      return "Tax Deferred Accounts";

    case "taxFree":
      return "Tax Free Accounts";

    case "education":
      return "Education";

    default:
      return "Other";
  }
}

/*  There should be at most one RMD start icon appearing at once.
    The server determines the appropriate age for RMD and sends an rmdIncome value > 0 if it's an RMD age for self or spouse.
    We no longer maintain expected RMD start age in the UI, so if user is older than RMD age the icon will show for their current age.
*/
export function isRmdStartAge({ rmdIncome, spouseRmdIncome, age, spouseAge }) {
  spouseAge = spouseAge ?? 0;
  const isRmdIncomeYear = rmdIncome > 0;
  const isSpouseRmdIncomeYear = spouseRmdIncome > 0;

  if (age > spouseAge && isRmdIncomeYear) return true;
  if (spouseAge > age && isSpouseRmdIncomeYear) return true;

  //same age
  return isRmdIncomeYear || isSpouseRmdIncomeYear;
}
