import React from "react";

export default function PortfolioBalanceSummaryZeroState() {
  return (
    <section>
      <div className="pc-delta">Portfolio Balance</div>
      <p className="pc-help-block pc-help-block--small qa-portfolio-balance-zerostate-message">
        Data currently not available
      </p>
    </section>
  );
}
