import React, { useState } from "react";
import PropTypes from "prop-types";
import NotificationItem from "./NotificationItem";
import AdviceDisclaimerModal from "components/modal/AdviceDisclaimerModal";
import { noop } from "underscore";

const component = "Notifications";

const NotificationCenter = ({
  userMessages,
  trackDisclaimerClick,
  handleMessageClick,
}) => {
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const onDisclaimerClick = (event) => {
    event.preventDefault();
    if (!showDisclaimerModal) {
      trackDisclaimerClick(component);
    }
    setShowDisclaimerModal(!showDisclaimerModal);
  };

  return (
    <div className="notification-center__container">
      <div className="notification-center__header">
        <div className="notification-center__header-notifications qa-page-title">
          Notifications
        </div>
        <div className="notifications__disclaimer">
          <a
            className="pc-btn pc-btn--link"
            href="#"
            onClick={onDisclaimerClick}
          >
            Disclaimer
          </a>
          <AdviceDisclaimerModal
            componentName={component}
            isOpen={showDisclaimerModal}
            title="Notifications Disclaimer"
            onClosed={() => {
              setShowDisclaimerModal(false);
            }}
          />
        </div>
      </div>
      <div className="notification-center__content qa-notifications-content">
        {userMessages && userMessages.length ? (
          userMessages.map((message, index) => (
            <NotificationItem
              key={message.userMessageId || index}
              message={message}
              handleClick={handleMessageClick}
            />
          ))
        ) : (
          <div className="notification-center__zero-state">
            We do not have any notifications for you at this time. Please try
            again later.
          </div>
        )}
      </div>
    </div>
  );
};

NotificationCenter.defaultProps = {
  userMessages: undefined,
  handleMessageClick: undefined,
  trackDisclaimerClick: noop,
};

NotificationCenter.propTypes = {
  userMessages: PropTypes.array,
  handleMessageClick: PropTypes.func,
  trackDisclaimerClick: PropTypes.func,
};

export default NotificationCenter;
