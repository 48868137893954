/* eslint-disable no-invalid-this */
import Backbone from "backbone";
import analytics from "analytics";
import * as AccountsUtil from "utils/account";
import $ from "jquery";
import "jqueryui";
import React from "react";
import ReactDOM from "react-dom";
import EditAccountContainer from "components/common/EditAccount/EditAccountContainer";
import "modal";
import "tooltip";
import "libs/pcap/utils/utils";

const ANIMATION_TIME = 300;
var EditAccountView2 = Backbone.View.extend({
  events: {
    "click .modal-footer a": "preventDefault",
    "click .js-edit-login": "editLogin",
    "click .js-cancel-edit-login": "cancelEditLogin",
    "click .js-edit-login-toggle-optional-prompts": "toggleOptionalPrompts",
    // Remove account
    "click .js-remove-account": "confirmRemoveAccount",
    "click .js-cancel-remove-account": "cancelRemoveAccount",
    "click .js-do-remove-account": "doRemoveAccount",
  },
  defaultCustomAccountPrompts: [
    {
      isRequired: true,
      semantic: "USERNAME",
      parts: [
        {
          id: "name",
          characterSet: "ALPHANUMERIC",
          maxLength: 40,
          placeholderValue: "",
          type: "TEXT",
        },
      ],
      label: "Description",
      isUsername: true,
    },
    {
      isRequired: false,
      parts: [
        {
          id: "accountNumber",
          characterSet: "ALPHANUMERIC",
          maxLength: 40,
          placeholderValue: "",
          type: "TEXT",
        },
      ],
      label: "Account Number",
    },
    {
      isRequired: false,
      parts: [
        {
          id: "currentBalance",
          characterSet: "NUMERIC",
          maxLength: 40,
          placeholderValue: "",
          type: "TEXT",
        },
      ],
      label: "Current Balance",
    },
  ],
  initialize: function () {
    // Do nothing
  },
  /* Main entry into this module */
  edit: function (accountModel, options) {
    this.options = options || {};

    this.edited = false;
    this.model = accountModel;
    this.account = this.model.toJSON();
    this.account.staticUrl = window.staticUrl; // For use in templates
    if (!this.editAccountNode) {
      this.editAccountNode = document.querySelector(
        ".js-edit-account-container"
      );
    }
    ReactDOM.render(
      <EditAccountContainer
        userAccountId={this.account.userAccountId}
        isOpen={true}
        onCancel={this.closeEditAccountModal.bind(this)}
        onSave={this.closeEditAccountModal.bind(this)}
      />,
      this.editAccountNode
    );

    this.isFromSidebar = options && options.isFromSidebar;
    analytics.sendDataActionEvent(
      this.isFromSidebar ? "Sidebar Edit Click" : "Edit Click",
      "Account"
    );
  },
  closeEditAccountModal: function () {
    ReactDOM.unmountComponentAtNode(this.editAccountNode);
  },
  onPersonAccountsFetched: function (err, personAccounts) {
    let accountsUsedByMessages = [];
    if (personAccounts.spData && this.account) {
      let educationalGoalAccounts = personAccounts.spData.result.filter(
        (personAccount) =>
          this.account.userAccountId === personAccount.userAccountId
      );
      accountsUsedByMessages = AccountsUtil.getPersonAccountsTakeaways(
        educationalGoalAccounts
      );
    }

    if (accountsUsedByMessages.length) {
      // Disable checkbox only if it is not excluded.
      // Accounts used for educational goals can be added back to accounts for advice.
      if (!this.isExcludeFromHousehold) {
        this.$el
          .find('input[name="isExcludeFromHousehold"]')
          .attr({ disabled: true });
      }

      this.tooltipElement = this.$el.find(".js-icon__help-circled");
      let excludeAccountTooltip = "";
      excludeAccountTooltip = `<span class="tooltip-inner__header">This account cannot be excluded from advice, because it is being used to track savings for:</span><ul class="tooltip-inner__list-group">
                                  ${accountsUsedByMessages
                                    .map(
                                      (takeaway) =>
                                        `<li class="tooltip-inner__list-group__item">${takeaway}</li>`
                                    )
                                    .join("")}
                                </ul>`;
      this.tooltipElement.attr({
        "data-original-title": excludeAccountTooltip,
      });
    }
  },

  markAsEdited: function () {
    this.edited = true;
    this.$el
      .find(".pc-button.mod-primary.is-disabled:visible")
      .removeClass("is-disabled");
  },
  toggleOptionalPrompts: function () {
    this.optionalPromptsToggler.toggleClass("active");
    // reset inputs
    this.optionalPromptsContainer.find("input").val("");
    if (this.optionalPromptsToggler.hasClass("active")) {
      this.optionalPromptsContainer.slideDown(ANIMATION_TIME);
    } else {
      this.optionalPromptsContainer.slideUp(ANIMATION_TIME);
    }
  },

  confirmMarkAsClosed: function (e) {
    if (e) {
      e.preventDefault();
    }
    var confirmPrompt = this.$el
        .find(".js-confirm-mark-as-closed-prompt")
        .hide()
        .removeClass("hidden"),
      editAccount = this.$el.find(".js-edit-account-form");
    editAccount.hide(0);
    confirmPrompt.show(0);
    this.currentForm = confirmPrompt;
    analytics.sendDataActionEvent("Mark as Closed Click", "Account");
  },
  cancelMarkAsClosed: function () {
    var confirmPrompt = this.$el.find(".js-confirm-mark-as-closed-prompt"),
      editAccount = this.$el.find(".js-edit-account-form");
    confirmPrompt.hide(0);
    this.$el.find(".server-error").remove();
    editAccount.show(0);
    this.currentForm = editAccount;
    if (this.options.goDirectlyToMarkAsClosedModal) {
      this.$el.modal("hide");
    }
  },

  preventDefault: function (e) {
    if (!$(e.currentTarget).hasClass("visit-site")) {
      e.preventDefault();
    }
  },
});

var editAccountView2 = new EditAccountView2();
export default editAccountView2.edit.bind(editAccountView2);
