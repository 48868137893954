import React from "react";
import PropTypes from "prop-types";
import Table from "libs/pcap/table/Table";

const FundTransferReviewBox = ({ title, columns, data }) => {
  return (
    <div className="fund-transfer-review-box__container">
      <div className="fund-transfer-review-box__title">{title}</div>
      <div className="fund-transfer-review__body pc-u-p-">
        <Table
          className="fund-transfer-review-box__table"
          columns={columns}
          data={data}
        />
      </div>
    </div>
  );
};

FundTransferReviewBox.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
};

FundTransferReviewBox.defaultProps = {
  title: "",
  data: undefined,
  columns: [],
};

export default FundTransferReviewBox;
