/* eslint-disable no-magic-numbers */
import $ from "jquery";

import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import contentIframeTemplate from "templates/modules/contentIframe/contentIframe.html";
var contentIframeView = Backbone.View.extend({
  el: "#iframe",
  events: {},
  initialize: function (href) {
    var self = this;
    self.href = href;

    Services.Advisor.get(
      { includeAppointments: true },
      this.onAdvisorFetched,
      this
    );
  },
  render: function () {
    this.$el.html(
      contentIframeTemplate({
        organizationSupportUrl: EMPOWER_SUPPORT_URL,
      })
    );
    return this;
  },

  onAdvisorFetched: function (err, response) {
    var self = this;
    // render template
    self.render();
    // set iframe src
    var $iframe = self.$el.find("iframe"),
      iframeEl = $iframe[0],
      iframewindow = iframeEl.contentWindow
        ? iframeEl.contentWindow
        : iframeEl.contentDocument.defaultView,
      messageData = {
        eventName: "updateAppointmentUrl",
      };

    iframeEl.src = self.href;
    // set height
    var iframeHeight =
      $(window).height() -
      $("#menuContainer").height() -
      $("#footerContainer").height() -
      21;
    $iframe.css({ height: iframeHeight });

    // determine if appointment url needs to be updated
    if (
      !_.isUndefined(response.spData) &&
      (response.spData.status === "ASSIGNED" ||
        response.spData.status === "AWAITING_ASSIGNMENT")
    ) {
      var appointmentUrl = response.spData.appointment_url;
      messageData.message = {
        url: baseUrl.slice(0, -1) + appointmentUrl,
        label: "Schedule A Call",
      };
      // determine if there any scheduled appointments
      var appointments = response.spData.appointments;
      if (_.isArray(appointments) && appointments.length) {
        messageData.message.label = "Call Scheduled";
      }
    }

    // post message to frame on its load event
    $iframe.load(function () {
      iframewindow.postMessage(JSON.stringify(messageData), cmsUrl);

      setTimeout(function () {
        AppRouter.removePreloader();
      }, 1000);
    });
  },
});
export default contentIframeView;
