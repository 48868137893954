import PropTypes from "prop-types";
import React from "react";
import MarketMoversContainer from "components/marketMovers/MarketMoversContainer";
import InsightsContainer from "components/opportunitiesSummary/InsightsContainer";
import ReferralCenterWidget from "components/referralCenter/ReferralCenterWidget";
import DebtPaydownSummaryContainer from "components/debtPaydownSummary/DebtPaydownSummaryContainer";
import createInsightsWithBlog from "components/opportunitiesSummary/createInsightsWithBlog";
import ManualAccountRecaptureWelcome from "../opportunitiesSummary/ManualAccountRecaptureWelcome";

const DISPLAY_LOCATION__MARKET_MOVERS = "#/dashboard/market-movers";

export default class DashboardColumn extends React.Component {
  render() {
    const {
      dashboardMessages,
      useUnifiedMar,
      numOfExternalAccounts,
      dashboardCallsResolved,
    } = this.props;

    if (!dashboardCallsResolved) {
      return null;
    }

    return (
      <div className="dashboard__column">
        <div className="dashboard__cell dashboard__cell--column">
          <div className="dashboard__item dashboard__item--tall-5/2x qa-insight-widget">
            {useUnifiedMar && numOfExternalAccounts === 0 ? (
              <ManualAccountRecaptureWelcome />
            ) : (
              <InsightsContainer
                dashboardMessages={this.props.dashboardMessages}
                createInsights={createInsightsWithBlog}
              />
            )}
          </div>
        </div>
        <div className="dashboard__cell dashboard__cell--column">
          <div className="dashboard__item">
            <MarketMoversContainer
              ace={dashboardMessages?.[DISPLAY_LOCATION__MARKET_MOVERS]}
            />
          </div>
        </div>
        {this.props.referralMessage ? (
          <div className="dashboard__cell dashboard__cell--column">
            <div className="dashboard__item">
              <ReferralCenterWidget message={this.props.referralMessage} />
            </div>
          </div>
        ) : (
          <div className="dashboard__cell dashboard__cell--column">
            <div className="dashboard__item">
              <DebtPaydownSummaryContainer />
            </div>
          </div>
        )}
      </div>
    );
  }
}

DashboardColumn.propTypes = {
  dashboardMessages: PropTypes.object,
  referralMessage: PropTypes.object,
  numOfExternalAccounts: PropTypes.number,
  useUnifiedMar: PropTypes.bool,
  dashboardCallsResolved: PropTypes.bool,
};

DashboardColumn.defaultProps = {
  dashboardMessages: undefined,
  referralMessage: undefined,
  numOfExternalAccounts: 0,
  useUnifiedMar: false,
  dashboardCallsResolved: false,
};
