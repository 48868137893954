import React from "react";
import PropTypes from "prop-types";
import { noop } from "underscore";

const Tile = ({ label, icon, hoverIcon, isSelected, onClick }) => (
  <div
    className={`personalized-onboarding-tile-container ${
      isSelected ? "selected" : ""
    }`}
    data-testid="js-tile-container"
  >
    <div
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={0}
      className="personalized-onboarding-tile"
      checked={isSelected}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <img
        alt=""
        aria-hidden={true}
        className="personalized-onboarding-tile-img"
        src={icon}
        width="64"
        height="64"
      />
      <img
        alt=""
        aria-hidden={true}
        className="personalized-onboarding-tile-hover-over-img"
        src={hoverIcon}
        width="64"
        height="64"
      />
      <span className="personalized-onboarding-tile-label pc-u-mt-">
        {label}
      </span>
    </div>
  </div>
);

Tile.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  hoverIcon: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

Tile.defaultProps = {
  label: "",
  icon: "",
  hoverIcon: "",
  isSelected: false,
  onClick: noop,
};

export default Tile;
