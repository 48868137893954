import PropTypes from "prop-types";
import React from "react";

export default class AppWideBanner extends React.Component {
  constructor(props) {
    super(...arguments);
    this.state = { isOpen: props.isOpen };
    this.onClose = this.onClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //eslint-disable-line camelcase
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  onClose() {
    this.setState({ isOpen: false });
    if (this.props.onRemove) {
      this.props.onRemove();
    }
  }

  render() {
    const { title, text, actions, showDismiss } = this.props;
    const textClassName = `app-wide-banner__message pc-u-pb0 qa-app-wide-banner-text ${
      title ? "" : "app-wide-banner__message--no-title"
    }`;
    const dismissClassName = `app-wide-banner__close-x app-wide-banner__close-x--always-top qa-app-wide-banner-dismiss ${
      title ? "" : "app-wide-banner__close-x--no-title"
    }`;

    return this.state.isOpen ? (
      <section
        className={`app-wide-banner pc-u-p- ${this.props.className || ""}`}
      >
        <div className="app-wide-banner__content qa-app-wide-banner-content">
          {title && (
            <h3 className="app-wide-banner__title qa-app-wide-banner-title pc-u-mb0">
              {title}
            </h3>
          )}
          {text && <div className={textClassName}>{text}</div>}
          {showDismiss && (
            <span className={dismissClassName} onClick={this.onClose}>
              &times;
            </span>
          )}
          {Array.isArray(actions) && (
            <div className="u-text-right">
              {actions.map((a, i) => (
                <span className="pc-u-ml-" key={i}>
                  {a}
                </span>
              ))}
            </div>
          )}
        </div>
      </section>
    ) : null;
  }
}

AppWideBanner.defaultProps = {
  showDismiss: true,
  isOpen: true,
};

AppWideBanner.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.element),
  onRemove: PropTypes.func,
  showDismiss: PropTypes.bool,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};
