import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import AddCategory from "components/modal/categoriesManager/AddCategory";

export default class AddCategoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.onCreateCategory = this.onCreateCategory.bind(this);
  }

  onCreateCategory(category) {
    Services.TransactionCategories.create(
      category,
      function (err, newCategoryReturn) {
        if (newCategoryReturn.spHeader.errors) {
          this.props.onFail(err, newCategoryReturn);
        } else {
          newCategoryReturn.spData.isCreated = true;
          this.props.onSuccess(newCategoryReturn.spData);
        }
      }.bind(this)
    );
  }

  render() {
    return (
      <AddCategory
        errors={this.props.errors}
        categories={this.props.categories}
        onCancel={this.props.onCancel}
        onCreateCategory={this.onCreateCategory}
        categoryName={this.props.categoryName}
        categoryType={this.props.categoryType}
        categoryTypes={[
          { value: "INCOME", label: "Income" },
          { value: "EXPENSE", label: "Expense" },
          { value: "TRANSFER", label: "Transfer" },
        ]}
      />
    );
  }
}

AddCategoryContainer.propTypes = {
  errors: PropTypes.array,
  categories: PropTypes.array,
  categoryName: PropTypes.string,
  categoryType: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
};

AddCategoryContainer.defaultProps = {
  errors: undefined,
  categories: [],
  categoryName: undefined,
  categoryType: undefined,
  onCancel: undefined,
  onSuccess: undefined,
  onFail: undefined,
};
