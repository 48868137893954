import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import React from "react";
import ReactDOM from "react-dom";
import FirstUseQuestionsBaseView from "views/components/firstUse/firstUseQuestionsBaseView";
import AgeValidationModal from "views/components/firstUse/retirementAgeValidationModal";
import FirstUseQuestionsMadLibsTemplate from "templates/firstUse/firstUseQuestionsMadLibs.html";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import deepCopy from "deep-copy";
import MadLibsPromptModal from "views/components/firstUse/MadLibsPromptModal/MadLibsPromptModal";
import "jquery.mask";
import { formatCurrency } from "libs/pcap/utils/format";
import Mixpanel from "mixpanel";

var MIN_AGE = 18;
var MAX_AGE = 114;
var MIN_RETIREMENT_AGE = 18;
var MAX_RETIREMENT_AGE = 92;
const FIRST_USE_MODULE_NODE = "#firstUseModule";
const AGE = '[name="age"]';
const RETIREMENT_AGE = '[name="retirementAge"]';
const INVESTIBLE_ASSETS_VALUE = '[name="investableAssetsValue"]';
const INPUT_WRAP = ".inputWrap";
const QUESTIONNAIRE_VALIDATION_PROMPT = "Questionnaire Validation Prompt";
const MIN_AGE_1 = 25;
const MIN_INVESTIBLE_ASSET_1 = 1000;
const MIN_AGE_2 = 40;
const MIN_INVESTIBLE_ASSET_2 = 10000;
const HUNDRED_MILLION = 100000000;
const ONE_HUNDRED_TWENTY_THREE = 123;
const NINE_HUNDRED_EIGHTY_SEVEN = 987;

let ageConfirmed = false;

export default FirstUseQuestionsBaseView.extend({
  render: function (data) {
    this.delegateEvents(
      _.extend(_.clone(this.events), {
        "change .js-first-name": "nameInputBlurHandler",
      })
    );

    this.$el.html(FirstUseQuestionsMadLibsTemplate(data));

    this.$(AGE).mask("000");
    this.$(RETIREMENT_AGE).mask("00");
    this.$(INVESTIBLE_ASSETS_VALUE).mask("#,##0", {
      reverse: true,
      maxlength: false,
    });

    $(".js-first-use-skip-for-now").hide();
    return this;
  },

  nameInputBlurHandler: function (e) {
    var name = $.trim($(e.currentTarget).val());
    if (name) {
      this.$(".js-next-button").val("Welcome, " + name + "!");
    } else {
      this.$(".js-next-button").val("Next");
    }
  },

  removeErrors: function (e) {
    $(e.currentTarget).closest(INPUT_WRAP).removeClass("error");
  },

  sanitizeMaskedInputs: function (formData) {
    if (formData.investableAssetsValue) {
      formData.investableAssetsValue = this.$(
        INVESTIBLE_ASSETS_VALUE
      ).cleanVal();
    }
  },

  transformFormData: function (formData) {
    if (formData.age) {
      var currentYear = new Date().getFullYear();
      formData.birthday = { year: String(currentYear - formData.age) };
    }
    delete formData.age;
    var investableAssets = formData.investableAssetsValue;
    formData.investableAssetsAmount = investableAssets;
    formData.investableAssets = formData.investableAssets || {};
    formData.investableAssets.userInputInvestableAssets = investableAssets;
  },

  validateAge: function (age) {
    const errorClass = "error";
    if (!age) {
      this.$(AGE).siblings("label").text("Please enter a value.");
      this.$(AGE).closest(INPUT_WRAP).addClass(errorClass);
    } else if (age < MIN_AGE || age > MAX_AGE) {
      this.$(AGE)
        .siblings("label")
        .text(
          age < MIN_AGE ? "Must be over " + MIN_AGE : "Must be under " + MAX_AGE
        );
      this.$(AGE).closest(INPUT_WRAP).addClass(errorClass);
    }
  },

  validateRetirementAge: function (retirementAge) {
    const errorClass = "error";
    if (!retirementAge) {
      this.$(RETIREMENT_AGE).siblings("label").text("Please enter a value.");
      this.$(RETIREMENT_AGE).closest(INPUT_WRAP).addClass(errorClass);
    } else if (
      retirementAge < MIN_RETIREMENT_AGE ||
      retirementAge > MAX_RETIREMENT_AGE
    ) {
      this.$(RETIREMENT_AGE)
        .siblings("label")
        .text(
          retirementAge < MIN_RETIREMENT_AGE
            ? "Must be over " + MIN_RETIREMENT_AGE
            : "Must be under " + MAX_RETIREMENT_AGE
        );
      this.$(RETIREMENT_AGE).closest(INPUT_WRAP).addClass(errorClass);
    }
  },
  validateDataForContextualPrompt: function (age, investibleAsset) {
    const pattern1 = "^([0-9])\\1*$";
    const pattern2 = "^(1234)";
    const pattern3 = "^(9876)";
    return (
      investibleAsset.toString().match(pattern1) !== null ||
      investibleAsset.toString().match(pattern2) !== null ||
      investibleAsset.toString().match(pattern3) !== null ||
      (age > MIN_AGE_1 && investibleAsset < MIN_INVESTIBLE_ASSET_1) ||
      (age > MIN_AGE_2 && investibleAsset < MIN_INVESTIBLE_ASSET_2) ||
      investibleAsset <= 1 ||
      investibleAsset >= HUNDRED_MILLION ||
      investibleAsset === ONE_HUNDRED_TWENTY_THREE ||
      investibleAsset === NINE_HUNDRED_EIGHTY_SEVEN
    );
  },

  validate: function (formData) {
    var errorClass = "error";
    this.$("." + errorClass).removeClass(errorClass);
    this.validateAge(formData.age);
    this.validateRetirementAge(formData.retirementAge);
    if (
      !formData.name.firstName ||
      !formData.name.firstName.match(
        this.constructor.REGEX_FIRST_NAME_VALIDATOR
      )
    ) {
      this.$('[name="name[firstName]"]')
        .closest(INPUT_WRAP)
        .addClass(errorClass);
    }
    if (!formData.investableAssetsValue) {
      this.$(INVESTIBLE_ASSETS_VALUE)
        .closest(".inputWrap")
        .addClass(errorClass);
    }
    if (
      formData.name.firstName &&
      formData.age &&
      formData.retirementAge &&
      formData.investableAssetsValue
    ) {
      if (formData.retirementAge <= formData.age && !ageConfirmed) {
        this.renderAgeValidationModal();
        return false;
      }
      if (
        this.validateDataForContextualPrompt(
          formData.age,
          formData.investableAssetsValue
        ) &&
        !this.wasContextualPromptRendered
      ) {
        this.renderContextualPromptTestModal(formData.investableAssetsValue);
        this.formDataCopy = deepCopy(formData);
        this.wasContextualPromptRendered = true;
        Mixpanel.updateProfile(
          { TestGroupID: "InputReprompt_Test" },
          null,
          true
        );
        Mixpanel.trackEvent("InputReprompt_Test");
        return false;
      }
    }

    return !this.$("." + errorClass).length;
  },

  remove: function () {
    if (this.contextualPromptNode) {
      ReactDOM.unmountComponentAtNode(this.contextualPromptNode);
      this.contextualPromptNode.parentNode.removeChild(
        this.contextualPromptNode
      );
      this.contextualPromptNode = null;
    }
    FirstUseQuestionsBaseView.prototype.remove.apply(this, arguments);
    Backbone.View.prototype.remove.apply(this, arguments);
  },

  renderContextualPromptTestModal: function (investibleAsset) {
    this.firstUseModule = document.querySelector(FIRST_USE_MODULE_NODE);
    this.contextualPromptNode = document.createElement("div");
    this.contextualPromptNode.className = "contextual-prompt-node";
    this.firstUseModule.appendChild(this.contextualPromptNode);
    const summary = `The more accurate your household savings estimate is, the better our tools will work for you. Do you want to update your savings of ${formatCurrency(
      investibleAsset,
      0
    )}?`;
    ReactDOM.render(
      <MadLibsPromptModal
        title="Before you continue"
        summary={summary}
        updateButtonLabel="Update My Info"
        skipButtonLabel="Looks Good to Me"
        onUpdate={this.handleUpdateInfo.bind(this)}
        onSkip={this.handleSkipUpdate.bind(this)}
        componentName="Reprompt"
      />,
      this.contextualPromptNode
    );
  },

  handleChange: function () {
    this.$(INVESTIBLE_ASSETS_VALUE).val("");
    this.$(RETIREMENT_AGE).val("").focus();
    ComponentAnalytics.trackClick(
      null,
      "First Use",
      QUESTIONNAIRE_VALIDATION_PROMPT,
      {
        action: "Change",
      }
    );
  },

  handleNoChange: function () {
    ComponentAnalytics.trackClick(
      null,
      "First Use",
      QUESTIONNAIRE_VALIDATION_PROMPT,
      {
        action: "Everything Looks Good",
      }
    );
    this.submitHandler();
  },

  handleUpdateInfo: function () {
    this.$(INVESTIBLE_ASSETS_VALUE).val("").focus();
  },
  handleSkipUpdate: function () {
    this.submitHandler();
  },

  renderAgeValidationModal: function () {
    this.firstUseModule = document.querySelector(FIRST_USE_MODULE_NODE);
    this.ageValidationNode = document.createElement("div");
    this.ageValidationNode.className = "age-validation-container";
    this.firstUseModule.appendChild(this.ageValidationNode);
    ReactDOM.render(
      <AgeValidationModal
        handleYes={this.handleYes.bind(this)}
        handleNo={this.handleNo.bind(this)}
        onClosed={this.closeAgeValidationModal.bind(this)}
      />,
      this.ageValidationNode
    );
  },

  handleYes: function () {
    ageConfirmed = true;
    this.closeAgeValidationModal();
    this.submitHandler();
  },

  handleNo: function () {
    this.closeAgeValidationModal();
    this.$(RETIREMENT_AGE).val("").focus();
  },

  closeAgeValidationModal: function () {
    if (this.ageValidationNode) {
      ReactDOM.unmountComponentAtNode(this.ageValidationNode);
      this.ageValidationNode.parentNode.removeChild(this.ageValidationNode);
      this.ageValidationNode = null;
    }
  },
});
