import _ from "underscore";

/*------------------------------------*\
    #ACCOUNT TYPE MAPPERS
\*------------------------------------*/

function mapChildrenByParentValue(accountTypeGroups) {
  return _.chain(accountTypeGroups)
    .filter(function (group) {
      return group.children && group.children.length;
    })
    .indexBy("value")
    .mapObject(function (groups) {
      return groups.children;
    })
    .value();
}

export default {
  /**
   * Converts the array of account type groups into a map where the keys are
   * parent object's `value` and the values are `children` array.
   *
   * The objects not having `children` array will be ommited.
   *
   * @param  {Array} parents           The array of objects retrieved
   *                                   from `/api/account/getAccountTypes2` API.
   * @return {Object}                  The mapping.
   */
  mapChildrenByParentValue: mapChildrenByParentValue,

  /**
   * Converts the array of account type groups into a map where the keys are
   * account type `value` and the values are account subtypes retrieved from `children` array
   * of account types.
   *
   * The types not having child sub-types will be omitted.
   *
   * @param  {Array} accountTypeGroups The array of account type groups retrieved
   *                                   from `/api/account/getAccountTypes2` API.
   * @return {Object}                  The mapping.
   */
  mapAccountSubTypesByType: function (accountTypeGroups) {
    var accountTypes = _.chain(accountTypeGroups)
      .pluck("children") // collect account types (2D array)
      .compact() // cleanup records with no account types
      .flatten() // produce 1D array of account types
      .value();

    return mapChildrenByParentValue(accountTypes);
  },
};
