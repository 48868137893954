import PropTypes from "prop-types";
import React from "react";
import ChartReact from "libs/pcap/chart/chart--react";
import { scaleBand, scaleLinear, axisBottom, axisRight, select } from "d3";
import moment from "moment";
import formatChartCurrency from "libs/pcap/chart/format/currency";
import { formatCurrency } from "libs/pcap/utils/format";

const Y_AXIS_MARGIN_RIGHT = 48;
const X_AXIS_MARGIN_BOTTOM = 25;
const Y_AXIS_MARGIN_LEFT = 10;
const X_SCALE_PADDING = 0.5;
const Y_AXIS_TICK_COUNT = 5;
const NUMBER_OF_DAYS_IN_A_WEEK = 7;
// More than 15 days
const DAY_VIEW_CUT_OFF_IN_DAYS = 16;
// More than two months
const WEEK_VIEW_CUT_OFF_IN_MONTHS = 2;
// More than 12 months
const MONTH_VIEW_CUT_OFF_IN_MONTHS = 12;
// More than 18 months
const MONTH_SHORT_VIEW_CUT_OFF_IN_MONTHS = 18;
// More than 4 years
const YEAR_VIEW_CUT_OFF_IN_YEARS = 5;

const AVERAGE_LINE_HORIZONTAL_OFFSET = 16;
const AVERAGE_LABEL_VERTICAL_OFFSET = 13;
const AVERAGE_LABEL_HORIZONTAL_OFFSET = 45;

const xAccessor = (d) => d.date;
const yAccessor = (d) => d.amount;

function shouldHideValue(amount) {
  if (amount === 0) {
    return true;
  }
}

function yAxisFormatter(amount) {
  return formatChartCurrency(amount);
}

class CashManagerHistoryBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.xScale = scaleBand().padding(X_SCALE_PADDING);
    this.yScale = scaleLinear();

    this.xAxis = axisBottom(this.xScale).tickSizeOuter(0);
    this.yAxis = axisRight(this.yScale)
      .tickFormat(yAxisFormatter)
      .ticks(Y_AXIS_TICK_COUNT)
      .tickSizeOuter(0)
      .tickSizeInner(0);

    this.setXAxisFormat();
    this.yFormatter = yAxisFormatter;
  }

  // Previous implementation
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    if (
      nextProps.data === this.props.data &&
      nextProps.startDate === this.props.startDate &&
      nextProps.endDate === this.props.endDate
    ) {
      return;
    }

    this.setXAxisFormat();
    if (nextProps.average && nextProps.data.length) {
      this.drawAverageLine();
    }
  }

  setXAxisFormat() {
    const { startDate, endDate } = this.props;
    const daysDifference = endDate.diff(startDate, "days");
    if (daysDifference < DAY_VIEW_CUT_OFF_IN_DAYS) {
      this.xAxis.tickFormat((d) => d.format("D"));
      this.xFormatter = (d) => d.format("MMMM DD, YYYY");
    } else if (
      endDate.diff(startDate, "months") < WEEK_VIEW_CUT_OFF_IN_MONTHS
    ) {
      this.xAxis.tickFormat((d) => {
        if (d.date() % NUMBER_OF_DAYS_IN_A_WEEK === 0) {
          return d.date() === NUMBER_OF_DAYS_IN_A_WEEK &&
            daysDifference > startDate.daysInMonth()
            ? d.format("MMM D")
            : d.format("D");
        }

        return "";
      });
      this.xFormatter = (d) => d.format("MMMM DD, YYYY");
    } else if (
      endDate.diff(startDate, "months") < MONTH_VIEW_CUT_OFF_IN_MONTHS
    ) {
      this.xAxis.tickFormat((d) =>
        d.month() === 0 ? `J'${d.format("YY")}` : `${d.format("MMM")}`
      );
      this.xFormatter = (d) => d.format("MMMM YYYY");
    } else if (
      endDate.diff(startDate, "months") < MONTH_SHORT_VIEW_CUT_OFF_IN_MONTHS
    ) {
      this.xAxis.tickFormat((d) =>
        d.month() === 0 ? `J'${d.format("YY")}` : `${d.format("MMM")[0]}`
      );
      this.xFormatter = (d) => d.format("MMMM YYYY");
    } else if (endDate.diff(startDate, "years") < YEAR_VIEW_CUT_OFF_IN_YEARS) {
      this.xAxis.tickFormat((d) =>
        d.month() === 0 ? `J'${d.format("YY")}` : ""
      );
      this.xFormatter = (d) => d.format("MMMM YYYY");
    } else {
      this.xAxis.tickFormat((d) =>
        d.quarter() === 1 ? `J'${d.format("YY")}` : ""
      );
      this.xFormatter = (d) => `${d.format("Qo [Quarter] YYYY")}`;
    }
  }

  componentDidMount() {
    if (this.props.average && this.props.data.length) {
      this.drawAverageLine();
    }
  }

  drawAverageLine() {
    if (!this.props.data.length || !this.props.average) {
      return;
    }
    if (this.props.average <= 0) return;

    let { average } = this.props;

    const chartSelector = select(`.${this.props.className}`);

    //Clean up previous avg
    const avgLineNode = chartSelector.select(".js-barchart-average-line");

    const avgLabelNode = chartSelector.select(".js-barchart-average-label");

    const averageLineWidth = this.xScale.range()[1];
    if (avgLineNode.node()) {
      avgLineNode.node().remove();
    }

    if (avgLabelNode.node()) {
      avgLabelNode.node().remove();
    }

    //Render avg
    chartSelector
      .append("line")
      .classed(
        "js-barchart-average-line cash-manager-history-barchart__average-line",
        true
      )
      .attr("x1", 0)
      .attr("x2", averageLineWidth)
      .attr("y1", this.yScale(average) + AVERAGE_LINE_HORIZONTAL_OFFSET)
      .attr("y2", this.yScale(average) + AVERAGE_LINE_HORIZONTAL_OFFSET);
    chartSelector
      .append("text")
      .attr("x", AVERAGE_LABEL_HORIZONTAL_OFFSET)
      .attr("y", this.yScale(average) + AVERAGE_LABEL_VERTICAL_OFFSET)
      .attr("text-anchor", "middle")
      .attr(
        "class",
        "js-barchart-average-label cash-manager-history-barchart__average-label"
      )
      .text(`AVG: ${formatCurrency(average, 2)}`);
  }

  render() {
    const { ariaLabel, ariaDesc } = this.props;

    const margin = {
      right: Y_AXIS_MARGIN_RIGHT,
      bottom: X_AXIS_MARGIN_BOTTOM,
      left: Y_AXIS_MARGIN_LEFT,
    };

    const tooltip = {
      xFormat: this.xFormatter,
      yFormat: (amount) => formatCurrency(amount, 2),
      className: "chart-tooltip chart-tooltip--small",
      legendClassName:
        "chart-legend--box cash-manager-history-barchart__legend-box",
      shouldHideValue,
    };

    return (
      <ChartReact
        data={[this.props.data]}
        className={this.props.className}
        type="bar"
        xScale={this.xScale}
        yScale={this.yScale}
        xAxis={this.xAxis}
        yAxis={this.yAxis}
        showXGrid={false}
        showYZeroLine={true}
        margin={margin}
        x={xAccessor}
        y={yAccessor}
        tooltip={tooltip}
        barClassName={this.props.barClassName}
        ariaLabel={ariaLabel}
        ariaDesc={ariaDesc}
        id="cash-manager-history-bar-chart"
      />
    );
  }
}

CashManagerHistoryBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      date: PropTypes.objectOf(moment).isRequired,
    })
  ).isRequired,
  startDate: PropTypes.objectOf(moment).isRequired,
  endDate: PropTypes.objectOf(moment).isRequired,
  barClassName: PropTypes.func,
  className: PropTypes.string,
  average: PropTypes.number,
  ariaLabel: PropTypes.string,
  ariaDesc: PropTypes.string,
};

CashManagerHistoryBarChart.defaultProps = {
  barClassName: () => "cash-manager-history-barchart__bar",
  className: "cash-manager-history-barchart",
  average: undefined,
  ariaLabel: undefined,
  ariaDesc: undefined,
};

export default CashManagerHistoryBarChart;
