// Plan Comparison
export const PLAN_COMPARISON_SIDE_LEFT = "left";
export const PLAN_COMPARISON_SIDE_RIGHT = "right";

// Edit Plan modals
export const EDIT_PLAN_VIEW = "Edit Plan";
export const SET_MASTER_PLAN_VIEW = "Set Master Plan";

// Sidebar sections
export const PLANS_SECTION_OFFICIAL = "OFFICIAL";
export const PLANS_SECTION_ALTERNATIVE = "ALTERNATIVE";
export const PLANS_SECTION_ADVISOR = "ADVISOR";

// Advisors do not want to imply 100% chance of success since nothing in life is certain
export const MAX_RP_PERCENT = 99;
