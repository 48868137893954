import _ from "underscore";

/**
 * Iterates user messages to find and return Zero state message matching the display location
 * @param {array} messages            an array of user messages
 * @param {string} displayLocation    display location of the component.
 * @returns {object|undefined}        zero state message matching the display location
 */
export default function getZeroStateUserMessage(messages, displayLocation) {
  return _.find(messages, (message) => {
    return (
      message.category === "ZERO_STATE" &&
      message.displayLocations &&
      message.displayLocations.indexOf(displayLocation) > -1
    );
  });
}
