export function getAllocationColors() {
  if (IS_EMPOWER) {
    return [
      "#366b9d",
      "#6bdbd5",
      "#00b8d0",
      "#f5e000",
      "#e8663c",
      "#d33ea0",
      "#d8dfdf",
    ];
  }
  const styles = getComputedStyle(document.documentElement);
  return [
    styles.getPropertyValue("--color--cash"),
    styles.getPropertyValue("--color--international-bonds"),
    styles.getPropertyValue("--color--us-bonds"),
    styles.getPropertyValue("--color--international-stocks"),
    styles.getPropertyValue("--color--us-stocks"),
    styles.getPropertyValue("--color--alternatives"),
    styles.getPropertyValue("--color--unclassified"),
  ];
}

export function getHoldingsColors() {
  const styles = getComputedStyle(document.documentElement);
  return {
    YOU_INDEX: styles.getPropertyValue(
      "--holdings-index__highlight--you-index"
    ),
    ACTIVE: styles.getPropertyValue("--holdings-index__highlight--active"),
    SELECTED: styles.getPropertyValue("--holdings-index__highlight--selected"),
  };
}

export function getNetworthChartColors() {
  const styles = getComputedStyle(document.documentElement);
  return {
    ALL: styles.getPropertyValue("--networth-chart-all__color"),
    CASH: styles.getPropertyValue("--networth-chart-cash__color"),
    INVESTMENT: styles.getPropertyValue("--networth-chart-investment__color"),
    CREDIT: styles.getPropertyValue("--networth-chart-credit__color"),
    LOAN: styles.getPropertyValue("--networth-chart-loan__color"),
    MORTGAGE: styles.getPropertyValue("--networth-chart-mortgage__color"),
    OTHER_ASSETS: styles.getPropertyValue(
      "--networth-chart-other-assets__color"
    ),
    OTHER_LIABILITIES: styles.getPropertyValue(
      "--networth-chart-other-liabilities__color"
    ),
  };
}

/* Account Details chart colors mirror Networth chart colors.
 * We can use the same CSS variables
 */

export function getAccountDetailsChartColors() {
  const styles = getComputedStyle(document.documentElement);
  return {
    ALL: styles.getPropertyValue("--networth-chart-all__color"),
    BANK: styles.getPropertyValue("--networth-chart-cash__color"),
    INVESTMENT: styles.getPropertyValue("--networth-chart-investment__color"),
    CREDIT_CARD: styles.getPropertyValue("--networth-chart-credit__color"),
    LOAN: styles.getPropertyValue("--networth-chart-loan__color"),
    MORTGAGE: styles.getPropertyValue("--networth-chart-mortgage__color"),
  };
}
