/* eslint-disable sonarjs/no-duplicate-string, sonarjs/no-collapsible-if */
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import "backbone.view";
import BackboneReactView from "common/BackboneReactView";
import sidebarModel from "models/sidebar";
import React from "react";
import RemoveAccountContainer from "components/common/RemoveAccount/RemoveAccountContainer/RemoveAccountContainer";
import { removeAccountsByAccountId } from "accessors/account/accessor";
import ReactDOM from "react-dom";
import VisitSiteTemplate from "templates/modules/sidebar/visitSite2.html";
import ResumeLinkingTemplate from "templates/modules/sidebar/resumeLinking.html";
import RetirementErrorsTemplate from "templates/modules/sidebar/retirementErrors.html";
import InitiateRefreshTemplate from "templates/modules/sidebar/initiateRefresh3.html";
import SiteMFAFailWaitTemplate from "templates/modules/sidebar/siteMFAfailWait.html";
import MoreInfoTemplate from "templates/modules/sidebar/moreInfo3.html";
import ServerErrorsTemplate from "templates/modules/sidebar/serverErrors2.html";
import PromptsTemplate from "templates/modules/sidebar/prompts2.html";
import OnUsBankSetup from "templates/modules/sidebar/onUsBankSetup.html";
import AccountsCollection from "collections/accounts";
import parseResponseErrors from "libs/pcap/utils/response";
import "tooltip";
import OauthErrorsTemplate from "templates/modules/sidebar/oauthErrors.html";
import FastlinkContainerModal from "views/components/FastlinkContainer/Modal/FastlinkContainerModal";
import eventBus from "eventBus";
import { styleNextActionInstructions } from "utils/account";
import { isEmpowerPrivilegedMode } from "../utils/accountUtils";

import { breadcrumb } from "logger";

const IS_OPEN_CLASSNAMES = " sidebar-account--is-open pc-bg-dark";
const ANIMATION_TIME = 300;
const getUsernameMaxlength = function (accountData) {
  var loginFields = accountData ? accountData.loginFields : [];
  var loginField = _.find(loginFields, "isUsername");
  return loginField && loginField.parts.length > 0
    ? loginField.parts[0].maxLength
    : null;
};
const getPasswordMaxlength = function (accountData) {
  var loginFields = accountData ? accountData.loginFields : [];
  var loginField = _.find(loginFields, "isPassword");
  return loginField && loginField.parts.length > 0
    ? loginField.parts[0].maxLength
    : null;
};

let SiteView2 = Backbone.View.extend({
  tagName: "li",
  className: "js-account sidebar-account",
  events: {
    "click a.js-account-visit-site": "visitSite",
    "click .js-account-submit": "refreshAccount",
    "click .js-account-cancel": "cancel",
    "click button[data-action='retirement-error-cancel']": "cancel",
    "click .js-account-authorize": "authorizeAccount",
    "click .notificationIcon": "onNotificationClicked",
    "click .js-account-remove": "confirmRemoval",
    "click .js-action-remove-on-us-bank": "removeBlockedOnUsBank",
    "change select": "onSelectChanged",
    'click input[type="checkbox"]': "markAsEdited",
    "focus input": "removeInvalid",
    "click .js-oauth-account-connect": "renderAccountAuthenticationWizardModal",
    "click .js-action-on-us-bank-fund": "handleFundNowClick",
  },
  // This components has 2 key models:
  // this.model => Account collection that contains all the accounts in the site. This is so we capture the events in all accounts that belong to the site
  // this.model.at(0) => the first account in the collection, we use this so we can act on the account nextAction, trigger site refresh and handle user request
  // this.model.at(0) will always be defined, otherwise it means this site has no accounts and this component will be removed by NeedAttentionAccountGroup.js
  initialize: function (options) {
    this.userSiteId = options.userSiteId;
    this.userAccountIds = options.accounts.map((a) => a.get("userAccountId"));
    this.siteId = options.accounts.map((a) => a.get("siteId"));
    this.siteName = options.accounts.map((a) => a.get("firmName"));
    this.logoPath = options.accounts.map((a) => a.get("logoPath"));
    this.credentialsModel = {};
    this.model = new AccountsCollection();
    this.model.reset(options.accounts);
    breadcrumb({
      message: "Rendering site in error. Attaching prompts:",
      data: this.model.at(0).toJSON().nextAction.prompts,
    });
    this.model.on("change", this.onModelChange, this);
    this.model.on("editAccountModalLoginChangeSubmit", () => {
      this.$el.removeClass(`js-editing-form ${IS_OPEN_CLASSNAMES}`);
    });
    sidebarModel.on("change:state", this.onSidebarStateChanged, this);
    sidebarModel.on("showSiteExtras", (userSiteId) => {
      if (parseInt(userSiteId, 10) === parseInt(this.userSiteId, 10)) {
        this.showExtras();
      }
    });

    this.editing = false;
    this.isExtraFormVisible = false;

    Backbone.history.on("route", this.onViewingAccountDetails, this);
    this.onViewingAccountDetails();

    var self = this;
    this.$el.on("click", function () {
      self.onViewClicked.apply(self, arguments);
    });
  },
  render: function () {
    var html,
      data = this.model.at(0).toJSON(),
      nextAction = data.nextAction || {};

    data.names = this.model.map((a) => a.get("name"));
    //passing staticUrl for image references in template files
    data.staticUrl = window.staticUrl;
    this.edited = false;

    data.usernameMaxlength = getUsernameMaxlength(data);
    data.passwordMaxlength = getPasswordMaxlength(data);

    data.isPrivileged = isEmpowerPrivilegedMode();
    if (
      _.isNumber(data.usernameMaxlength) &&
      _.isNumber(data.passwordMaxlength)
    ) {
      data.loginFieldsMaxLengthAvailable = true;
    }
    data.isEmpower = IS_EMPOWER;

    if (this.$el.hasClass("js-editing-form")) {
      this.$(".firmName").text(data.firmName);
      this.$(".accountName").text(data.name);
      return;
    }
    this.$el.removeClass(
      `error caution refreshing editing normal refreshRequired resolving refreshable isOnUs ${IS_OPEN_CLASSNAMES}`
    );

    switch (nextAction.action) {
      case "VISIT_SITE":
        this.$el.addClass("error");
        html = VisitSiteTemplate(data);
        break;
      case "PAUSED_LINKING":
        data.isInBlackout = true;
        html = ResumeLinkingTemplate(data);
        break;
      case "RESUME_LINKING":
        this.$el.addClass("error");
        html = ResumeLinkingTemplate(data);
        break;
      case "INITIATE_REFRESH":
        this.$el.addClass("refreshRequired");
        html = InitiateRefreshTemplate(data);
        break;
      case "MIGRATE_OAUTH":
      case "OAUTH_RECONNECT":
        this.$el.addClass("error");
        html = OauthErrorsTemplate(data);
        break;
      case "WAIT":
        this.$el.addClass("error");
        html = SiteMFAFailWaitTemplate(data);
        break;
      case "RETIREMENT_CREDENTIALS_HAVE_CHANGED":
      case "RETIREMENT_INCORRECT_SECURITY_ANSWERS":
      case "RETIREMENT_ADDITIONAL_SECURITY_QUESTIONS":
      case "RETIREMENT_SET_UP_SECURITY_QUESTIONS":
      case "RETIREMENT_CLIENT_NEEDS_TO_CALL":
      case "RETIREMENT_CREDENTIALS_MUST_BE_RESET":
      case "RETIREMENT_INCORRECT_CREDENTIALS_PROVIDED":
      case "RETIREMENT_UNIQUE_FORWARDING_EMAIL_REMOVED":
      case "RETIREMENT_UNIQUE_FORWARDING_PHONE_REMOVED":
      case "RETIREMENT_UNIQUE_FORWARDING_PHONE_AND_EMAIL_REMOVED":
      case "RETIREMENT_DEFAULT_HANDLER":
        this.$el.addClass("error");
        html = RetirementErrorsTemplate({
          ...data,
          nextAction: {
            ...nextAction,
            instructions: styleNextActionInstructions(
              nextAction.instructions,
              nextAction.action
            ),
          },
        });
        break;
      case "PCB_FINISH_SETUP":
      case "PCB_SUBMITTED":
      case "PCB_IN_REVIEW":
      case "PCB_KBA_REVIEW":
      case "PCB_BLOCKED":
      case "PCB_FUND_NOW":
      case "PCB_FAILED":
        this.$el
          .addClass("sidebar-account--on-us-bank-setup")
          .removeClass("selected");
        // Empower Cash: Hide action buttons for SSO users
        if (IS_EMPOWER) {
          data.isSsoUser = window.integratedSharedData?.isInboundSSOUser;
        }
        html = OnUsBankSetup(Object.assign({}, data));
        break;
      default:
        // 'MORE_INFO'
        this.$el.addClass("error");
        html = this.getPromptsHtml(MoreInfoTemplate, data);
        break;
    }

    this.$el.html(html);

    this.postRender();

    return this;
  },
  getPromptsHtml: function (template, data) {
    return template(data);
  },
  postRender: function () {
    // disable any submit buttons where the starting inputs are already invalid
    this.validateInput(true);

    // Mark isPartner for partner account.
    if (this.model.at(0).get("isPartner")) {
      this.$el.addClass("isPartner");
    }

    // auto-expand if this account was never aggregated and has an MFA question to answer
    if (
      ((typeof this.model.at(0).get("lastRefreshed") === "undefined" ||
        this.refreshedAccount) &&
        this.model.at(0).get("nextAction")?.action === "MORE_INFO") ||
      this.model.at(0).get("nextAction")?.action === "INITIATE_REFRESH" ||
      this.model.at(0).get("nextAction")?.action === "VISIT_SITE" ||
      this.model.at(0).get("nextAction")?.action === "BLOCKED"
    ) {
      // this.refreshedAccount = false; PFA-6481 - once a users shows interest
      // in fixing an account during a session, always show them the prompts
      this.setResolving();
    }

    this.tooltipElement = this.$('[data-toggle="tooltip"]');
    this.tooltipElement.tooltip({
      placement: "right",
      container: this.$el,
      trigger: "hover click",
    });
  },
  onModelChange: function () {
    this.userAccountIds = this.model.map((a) => a.get("userAccountId"));
    this.render();
  },
  onViewingAccountDetails: function (something, route) {
    this.$el.removeClass("selected");
    if (route === "accountDetails" || something == null) {
      var hash = window.location.hash,
        selectedId;
      if (hash.match(/ua=/)) {
        // highlight by userAccountId
        selectedId = hash
          .match(/ua=[0-9]+/)[0]
          .substring(3); /* Previous impl (aka black magic)*/ //eslint-disable-line no-magic-numbers
      } else if (hash.match(/userProductId=/)) {
        // highlight by userProductId
        selectedId = hash
          .match(/userProductId=[0-9]+/)[0]
          .substring(14); /* Previous impl (aka black magic)*/ //eslint-disable-line no-magic-numbers
      } else if (hash.match(/a=/)) {
        selectedId = hash
          .match(/a=[0-9_]+/)[0]
          .substring(2); /* Previous impl (aka black magic)*/ //eslint-disable-line no-magic-numbers
      }
      if (
        !this.$el.hasClass("sidebar-account--on-us-bank-setup") &&
        selectedId &&
        this.userAccountIds.includes(parseInt(selectedId, 10))
      ) {
        this.$el.addClass("selected");
      }
    }
  },
  onNotificationClicked: function (e) {
    if (e) {
      e.stopPropagation();
    }
  },
  setResolving: function () {
    sidebarModel.trigger("requestState", sidebarModel.EXPANDED);
    this.showExtras();
  },
  onSelectChanged: function (e) {
    this.markAsEdited(e);
    $(e.currentTarget).blur();
  },

  confirmRemoval: function (e) {
    e.stopPropagation();
    this.isExtraFormVisible = false;

    if (!this.removeAccountModal) {
      this.removeAccountModal = document.createElement("div");
      this.removeAccountModal.className = "js-remove-account-modal";
      document.body.appendChild(this.removeAccountModal);
    }
    //Check if we are rendering an account in RESUME LINKING group.  Change the text for Remove Account modal accordingly
    const group = $(e.currentTarget).closest("li.sidebar-account__group");
    const resumeLinking = group?.hasClass("RESUME_LINKING");

    ReactDOM.render(
      <RemoveAccountContainer
        modalBody={
          resumeLinking ? (
            <p>Are you sure you do not wish to link your account?</p>
          ) : (
            <p>
              This permanently removes all transaction and historic data for
              this account. It will likely affect your aggregated balances, such
              as past net worth, and historic spending totals.
            </p>
          )
        }
        modalConfirmButtonText="Remove"
        modalCancelButtonText="Cancel"
        modalTitle="Remove Account"
        modalClassName="js-remove-account-modal"
        componentName="RemoveAccountModal"
        service={() =>
          removeAccountsByAccountId([this.model.at(0).get("accountId")])
        }
        onAccountRemoved={this.closeRemoveAccountModal.bind(this, true)}
        onCancel={this.closeRemoveAccountModal.bind(this, false)}
        isOpen={true}
      />,
      this.removeAccountModal
    );
  },
  closeRemoveAccountModal: function (isAccountRemoved) {
    if (this.removeAccountModal) {
      ReactDOM.unmountComponentAtNode(this.removeAccountModal);
    }

    if (isAccountRemoved) {
      sidebarModel.trigger("account:remove");
    }
  },
  removeBlockedOnUsBank: function ({ currentTarget }) {
    currentTarget.disabled = true;
    this.$(".js-on-us-bank-setup-error").addClass("is-hidden");
    const account = this.model.get(currentTarget.value);
    if (account) {
      account.remove((err, rs) => {
        const errors = parseResponseErrors(err, rs);
        if (errors) {
          this.$(".js-on-us-bank-setup-error")
            .text(_.first(errors))
            .removeClass("is-hidden");
          currentTarget.disabled = false;
          return;
        }
        sidebarModel.trigger("account:remove");
      });
    }
  },
  validateInput: function (doNotHighlight) {
    var valid = true;
    var visibleInputs = this.$("[name]").filter(":visible");
    _.each(visibleInputs, function (element) {
      var val = $(element).val(),
        inputValid =
          $(element).hasClass("optional") ||
          (val && typeof val == "string" && val.length > 0);

      if (doNotHighlight !== true && $(element).hasClass("blurred")) {
        if (inputValid) {
          $(element).closest("div").removeClass("inputError");
        } else {
          $(element).closest("div").addClass("inputError");
        }
      }

      if (!inputValid) {
        valid = false;
      }
    });

    if (valid) {
      this.$(".js-account-update").filter(":visible").removeAttr("disabled");
    } else {
      this.$(".js-account-update").filter(":visible").attr("disabled", "true");
    }
    return valid;
  },
  visitSite: function (e) {
    var data = this.model.at(0).toJSON(),
      nextAction = data.nextAction || {};
    data.showContactSuppot = !IS_EMPOWER;
    data.organizationSupportUrl = EMPOWER_SUPPORT_URL;
    if (nextAction.prompts && nextAction.prompts.length) {
      var html = this.getPromptsHtml(PromptsTemplate, data);
      this.$(".js-account-extras").html(html);
      this.$el.addClass("js-editing-form");
    } else {
      $(e.currentTarget)
        .addClass("is-hidden")
        .closest("li")
        .find(".js-account-submit")
        .removeClass("is-hidden");
    }
    return true;
  },

  refreshAccount: function (e) {
    if (e) {
      e.stopPropagation();
    }
    var data = this.model.at(0).toJSON(),
      nextAction = data.nextAction || {};
    this.refreshedAccount = true;
    if (nextAction.action === "VISIT_SITE") {
      this.model.at(0).refresh();
      return;
    }
    this.createFastlinkContainer(this.siteId[0], parseInt(this.userSiteId, 10));
  },
  cancel: function (e) {
    if (e == null || !$(e.currentTarget).hasClass("disabled")) {
      e.stopPropagation();
      if (this.$el.hasClass("editing")) {
        this.cancelEdits();
        this.$el.removeClass(`js-editing-form ${IS_OPEN_CLASSNAMES}`);
      } else {
        this.hideExtras();
        sidebarModel.trigger("account:cancel");
      }
    }
  },
  cancelEdits: function () {
    this.editing = false;
    this.render();
  },
  onAccountUpdated: function (err, response) {
    if (this.processErrors(response)) {
      this.$("button").removeAttr("disabled");
      this.$(".js-account-update").text("Save");
      this.editing = true;
    }
  },
  renderAccountAuthenticationWizardModal() {
    this.createFastlinkContainer(this.siteId[0], parseInt(this.userSiteId, 10));
  },
  authorizeAccount: function (e) {
    e.stopPropagation();
    this.createFastlinkContainer();
  },
  onAccountAuthorized: function (err, response) {
    if (this.processErrors(response)) {
      this.$(".js-account-authorize").removeAttr("disabled");
      this.$(".js-account-authorize").text("Submit");
    } else {
      this.hideExtras();
    }
  },
  onSidebarStateChanged: function (model, state) {
    if (state !== model.EXPANDED) {
      this.hideExtras();
      if (this.editing) {
        this.editing = false;
        this.render();
        //   this.renderPrompts();
      }
    }
  },
  onViewClicked: function (e) {
    // if they didn't click on the notification icon or the edit icon
    if (
      !$(e.target).hasClass("notificationIcon") &&
      !$(e.target).hasClass("edit") &&
      !this.$el.hasClass("resolving") &&
      !this.$el.hasClass("editing")
    ) {
      // force them to resolve the issues
      this.setResolving();
    }
  },
  markAsEdited: function () {
    this.edited = true;
    this.$("a.update:disabled").removeAttr("disabled");
  },
  showExtras: function () {
    this.isExtraFormVisible = true;
    this.$(".js-account-extras, .js-need-attention-site__accounts-names")
      .removeClass("is-hidden")
      .slideDown(ANIMATION_TIME, () => {
        const elementToFocus = this.$(".js-account-extras").find(
          'input[type="text"]:eq(0)'
        );
        if (elementToFocus.length) {
          elementToFocus.focus();
        } else {
          this.$(".js-account-extras").find(".js-account-action").focus();
        }

        sidebarModel.trigger("refreshScrollBar");
      });
    this.$el.addClass("resolving");
    if (this.$(".js-account-extras").length) {
      this.$el.addClass(IS_OPEN_CLASSNAMES);
    }
    if (this.model.at(0).get("nextAction")?.action === "MORE_INFO") {
      this.$el.addClass(`js-editing-form`);
    }
    sidebarModel.trigger("ensureElementIsVisible", this.$el);
  },
  hideExtras: function () {
    // promise allows changes to occur after slideUp animation
    return new Promise((resolve) => {
      this.isExtraFormVisible = false;
      this.credentialsModel = {};
      var extrasContainer = this.$(
        ".js-account-extras, .js-need-attention-site__accounts-names"
      );
      extrasContainer.slideUp(
        ANIMATION_TIME,
        function () {
          extrasContainer.addClass("is-hidden");
          sidebarModel.trigger("refreshScrollBar");
          this.$el.removeClass(
            `js-editing-form resolving ${IS_OPEN_CLASSNAMES}`
          );
          resolve();
        }.bind(this)
      );
    });
  },
  processErrors: function (response) {
    if (
      response &&
      response.spHeader &&
      response.spHeader.errors &&
      _.isArray(response.spHeader.errors)
    ) {
      _.each(
        response.spHeader.errors,
        function (error) {
          var target = _.isUndefined(error.details)
            ? null
            : this.$('[name="' + error.details.fieldName + '"]');
          if (target) {
            target.closest("div").append(ServerErrorsTemplate(error));
          } else {
            this.$(".sidebar-account__actions").prepend(
              ServerErrorsTemplate(error)
            );
          }
        }.bind(this)
      );
      return true; // when we have errors
    }
    return false; // when we have no errors
  },
  remove: function () {
    this.model.off("change");
    this.model.off("editAccountModalLoginChangeSubmit");
    Backbone.View.prototype.remove.apply(this, arguments);
  },
  createFastlinkContainer: function (siteId, userSiteId) {
    let data = this.model.at(0).toJSON();
    let nextAction = data.nextAction || {};

    this.fastlinkContainerModal = new BackboneReactView({
      component: FastlinkContainerModal,
      componentProps: {
        isOpen: true,
        onCancel: this.handleFastlinkClose.bind(this),
        onClose: this.handleFastlinkClose.bind(this),
        onSuccess: this.handleFastlinkSuccess.bind(this),
        flow: nextAction?.fastLinkFlow || "EDIT",
        siteId: siteId || data?.siteId,
        userSiteId: userSiteId || data?.userSiteId,
      },
    });
  },
  handleFastlinkClose() {
    if (this.fastlinkContainerModal) {
      this.fastlinkContainerModal.remove();
      this.fastlinkContainerModal = null;
    }
  },

  handleFastlinkSuccess(data) {
    if (this.fastlinkContainerModal) {
      this.fastlinkContainerModal.remove();
      this.fastlinkContainerModal = null;
      console.log("Site2.js   handleFastlinkSuccess data: ", data);
      this.hideExtras();
      sidebarModel.trigger("account:authorize");
      eventBus.trigger("FASTLINK_ACCOUNT_LINKED", data.accounts);
    }
  },

  handleFundNowClick() {
    window.dashboardUtils?.eventBus.dispatch(
      "cash.account_sidebar_fund_now.click"
    );
  },
});
export default SiteView2;
