import PropTypes from "prop-types";
import React from "react";
import SingleStepBlock from "./SingleStepBlock";

const StepsSection = ({ onContinueClick } = {}) => {
  return (
    <div className="fieldset-steps pc-layout pc-layout--auto">
      <h1 className="fieldset-steps__title">How Does It Work?</h1>
      <SingleStepBlock
        className="pc-layout__item pc-u-1/4"
        step={1}
        title="Link accounts"
      >
        Link your external financial accounts to get a comprehensive view of
        your financial life.
      </SingleStepBlock>
      <SingleStepBlock
        className="pc-layout__item pc-u-1/4 fieldset-steps__middle-step"
        step={2}
        title="Talk with an advisor"
      >
        An advisor will reach out to you to discuss your financial goals and
        priorities.
      </SingleStepBlock>
      <SingleStepBlock
        className="pc-layout__item pc-u-1/4"
        step={3}
        title="Create your plan"
      >
        You'll get a comprehensive assessment of your entire financial life.
      </SingleStepBlock>
      <div className="pc-layout__item pc-u-1/8 fieldset-steps__button-container">
        <button
          type="button"
          className="pc-btn fieldset-steps__button"
          onClick={onContinueClick}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

StepsSection.propTypes = {
  onContinueClick: PropTypes.func,
};

export default StepsSection;
