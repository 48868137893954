import React from "react";
import PropTypes from "prop-types";
import Message from "components/common/Message";
import Loading from "components/common/loading/Loading";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import EmergencyFundSummaryChart from "components/emergencyFundSummary/EmergencyFundSummaryChart";
import { formatCurrency } from "libs/pcap/utils/format";
import { first, isEmpty } from "underscore";
import { trackEvent } from "components/common/ComponentAnalytics";
import { handleAmplitudeEvent } from "../../empower/utils/eventUtils";

const EMERGENCY_FUND_URL = "#/savings-planner/emergency-fund";
const TOOLTIP_TEXT =
  "Emergency fund savings is the sum of your linked bank accounts";
const COMPONENT_NAME = "Emergency Fund";
export default class EmergencyFundSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: window.location.href,
    };
    this.handleTitleClick = this.handleTitleClick.bind(this);
    this.location = this.state.location.split("#/")[1] || "";
  }
  handleTitleClick(e) {
    const { isInPostLoginAction, onButtonClick } = this.props;
    trackEvent(COMPONENT_NAME, "Click on Emergency Fund Widget");
    const location = this.location.split("/")[0];
    if (IS_EMPOWER) {
      if (location === "dashboard") {
        window.dashboardUtils?.eventBus.dispatch(
          "integrated.pageView.emergency-fund"
        );
        window.dashboardUtils?.eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
          event_properties: {
            selection: "widgets_emergency_fund_clicked_event",
          },
        });
      } else if (location === "savings-planner") {
        window.dashboardUtils?.eventBus.dispatch(
          "widgets_emergency_fund_clicked_event"
        );
      }
    }
    if (isInPostLoginAction && typeof onButtonClick === "function") {
      onButtonClick(e, {});
    }
  }
  handleTooltipMouseOver() {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "EmergencyFund.widgets_mouseOver_tooltip_event"
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
        event_properties: {
          selection: "EmergencyFund.widgets_mouseOver_tooltip_event",
        },
      });
    }
  }

  handleTooltipClick = () => {
    const name = "Emergency Fund";
    const element = "SELECT_BUTTON";
    const section = "Widget.toolTip";
    const payload = { widget: name };

    handleAmplitudeEvent(element, section, payload);
  };

  render() {
    const { chartTitle, isInPostLoginAction, showAddAccounts } = this.props;

    if (this.props.loading) {
      return <Loading />;
    }

    if (this.props.errors) {
      return <Message severity="error" messages={this.props.errors} />;
    }

    const summary = first(this.props.summaries);

    const empowerSharedData = window.integratedSharedData;
    const tooltip =
      empowerSharedData?.translations?.get("zeroStateDashboard")?.emergencyFund
        ?.tooltip;
    const tooltipClass = tooltip ? "with-tooltip" : "";

    if (this.props.plannerStage === "NO_ACCOUNT") {
      const title = first(this.props.titles);
      const action = first(this.props.actions);
      return (
        <section className="vertical-align u-text-center">
          <div className="js-emergency-fund-zero-state-title pc-gamma qa-emergency-fund-zero-state-title">
            {title}
          </div>
          <div className="emergency-fund-summary__summary centi">{summary}</div>
          {!isEmpty(action) && showAddAccounts && (
            <a href={action.url} className="pc-btn pc-btn--primary">
              {action.label}
            </a>
          )}
        </section>
      );
    }

    const emergencyFundBalance = formatCurrency(
      this.props.emergencyFundBalance,
      0
    );
    const title =
      isInPostLoginAction && chartTitle ? chartTitle : "Emergency Fund";

    const svgChartAriaLabel = `Emergency fund is ${emergencyFundBalance}. ${summary}`;
    const widgetClass = "summary-widget__emergency-fund";

    return (
      <section
        className={`summary-widget ${tooltipClass} ${widgetClass}`}
        alt="data visualization on emergency funds available."
      >
        <div className="summary-widget__header">
          <div className="l-spaced l-spaced--flush">
            <div className="pc-delta header-container">
              <h2 className="pc-u-mb0">
                <a
                  href={EMERGENCY_FUND_URL}
                  onClick={this.handleTitleClick}
                  aria-label={`Go to ${title}`}
                  className="js-summary-widget__title summary-widget__title--link qa-emergency-fund-widget-title"
                >
                  {title}
                </a>
              </h2>
              {tooltip && (
                <InfoTooltipIcon
                  parentClassName={widgetClass}
                  tooltipProps={{
                    trigger: "click",
                    role: "alert",
                  }}
                  onClick={this.handleTooltipClick}
                  onMouseOver={this.handleTooltipMouseOver}
                  title={tooltip || TOOLTIP_TEXT}
                  container={false}
                  className={"pc-u-ml-"}
                />
              )}
              {!tooltip && (
                <InfoTooltipIcon
                  parentClassName={widgetClass}
                  onMouseOver={this.handleTooltipMouseOver}
                  title={TOOLTIP_TEXT}
                  className={`pc-u-ml--`}
                  tooltipProps={{ role: "alert" }}
                />
              )}
            </div>
            <div className="pc-delta u-text-primary js-emergency-fund-current-balance qa-emergency-fund-current-balance">
              {emergencyFundBalance || ""}
            </div>
          </div>
        </div>
        <EmergencyFundSummaryChart
          data={this.props.bankBalances}
          recommendationMax={this.props.recommendationMax}
          recommendationMin={this.props.recommendationMin}
          balanceSelectorPrefix={
            isInPostLoginAction ? ".pcap-cash-pla__container" : ""
          }
          svgChartAriaLabel={svgChartAriaLabel}
        />
        {!isInPostLoginAction && (
          <div className="emergency-fund-summary__summary milli">{summary}</div>
        )}
      </section>
    );
  }
}

EmergencyFundSummary.propTypes = {
  loading: PropTypes.bool,
  isInPostLoginAction: PropTypes.bool,
  onButtonClick: PropTypes.func,
  errors: PropTypes.array,
  emergencyFundBalance: PropTypes.number,
  bankBalances: PropTypes.array,
  actions: PropTypes.array,
  summaries: PropTypes.array,
  chartTitle: PropTypes.string,
  titles: PropTypes.array,
  recommendationMax: PropTypes.number,
  recommendationMin: PropTypes.number,
  plannerStage: PropTypes.string,
  showAddAccounts: PropTypes.bool,
};

EmergencyFundSummary.defaultProps = {
  loading: false,
  isInPostLoginAction: false,
  onButtonClick: undefined,
  errors: null,
  emergencyFundBalance: undefined,
  bankBalances: [],
  actions: [],
  summaries: [],
  chartTitle: undefined,
  titles: [],
  recommendationMin: 0,
  recommendationMax: 0,
  plannerStage: undefined,
  showAddAccounts: true,
};
