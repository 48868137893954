import React from "react";
import PropTypes from "prop-types";
import Services from "services";
import { promisify } from "utils/service";
import CancelRecurringTransfer from "components/transferFunds/CancelRecurringTransfer";
import ComponentAnalytics from "components/common/ComponentAnalytics";

class CancelRecurringTransferContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleDeleteTransfer = this.handleDeleteTransfer.bind(this);
    this.handleOnCancel = this.handleOnCancel.bind(this);
  }

  handleDeleteTransfer() {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "cancel_recurring_request.cancel_button.click"
      );
    }
    ComponentAnalytics.trackClick(
      null,
      "Cancel Recurring Transfer Confirmation",
      "Cancel Transfer"
    );

    this.setState({ loading: true });
    const { id, sourceAccountId, targetAccountId } = this.props.transfer;
    this.props
      .deleteTransfer({ id, sourceAccountId, targetAccountId })
      .then((response) => {
        this.setState({ loading: false });
        this.props.onDeleted(response);
      })
      .catch((errors) => {
        this.setState({ loading: false, errors });
      });
  }

  handleOnCancel() {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "cancel_recurring_request.dont_cancel_button.click"
      );
    }
    this.props.onCancel();
  }

  render() {
    const { transfer } = this.props;
    return (
      <CancelRecurringTransfer
        transfer={transfer}
        onCancel={this.handleOnCancel}
        onSubmit={this.handleDeleteTransfer}
        loading={this.state.loading}
        errors={this.state.errors}
      />
    );
  }
}

CancelRecurringTransferContainer.propTypes = {
  deleteTransfer: PropTypes.func,
  transfer: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
};

CancelRecurringTransferContainer.defaultProps = {
  deleteTransfer: promisify(Services.Transfer.deleteTransferInstruction),
};

export default CancelRecurringTransferContainer;
