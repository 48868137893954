import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import ReactDOM from "react-dom";
import withAdviceDisclaimer from "components/higherOrderComponents/withAdviceDisclaimer";
import { MODAL_TITLE_BLANK } from "views/components/postLoginActions/constants";
import { getJsonContent } from "views/components/postLoginActions/helpers";
import { trackEvent } from "components/common/ComponentAnalytics";

const ModalWithAdviceDisclaimer = withAdviceDisclaimer(Modal);

export default class PostLoginActionModal extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      showModal: true,
      modalTitle: this.props.loadingModalTitle,
      onBeforeClose: undefined,
    };

    this.closeModal = this.closeModal.bind(this);
    this.shouldModalClose = this.shouldModalClose.bind(this);
    this.onRequestClose = this.onRequestClose.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.saveState = this.saveState.bind(this);
  }

  closeModal() {
    const { userMessage, plaDetails } = this.props;
    this.setState(
      {
        showModal: false,
      },
      () => {
        // Clean up after user closes the PLA modal
        const mountNode = this.props.mountNode;
        if (mountNode) {
          ReactDOM.unmountComponentAtNode(mountNode);
          mountNode.parentNode.removeChild(mountNode);
        }
        trackEvent(
          null,
          "Close Post Login Action",
          Object.assign({}, plaDetails, {
            component: userMessage.template,
          })
        );
      }
    );

    if (IS_EMPOWER) {
      // Notify Empower the PLA can be shown now
      window.dispatchEvent(new CustomEvent("modal-close"));
      console.log("Dispatched `modal-close` event");
    }
  }

  shouldModalClose() {
    const { onBeforeClose } = this.state;
    if (onBeforeClose) {
      onBeforeClose({ onDone: this.closeModal });
      return false;
    }
    return true;
  }

  onRequestClose() {
    if (this.shouldModalClose()) {
      this.closeModal();
    }
  }

  onTitleChange(title) {
    this.setState({ modalTitle: title });
  }

  saveState(state) {
    this.setState({ modalState: state });
  }

  render() {
    const { modalTitle } = this.state;
    const {
      width,
      modalComponentName,
      className,
      userMessage,
      plaDetails,
      logo,
      showTimeAgo,
      showFeedbackForm,
      loadingModalTitle,
      contentClassName,
      showLoader,
    } = this.props;
    let isModalTitleLoading =
      loadingModalTitle === MODAL_TITLE_BLANK || modalTitle === undefined;
    const ModalContentComponent = this.props.contentComponent;
    const jsonContent = getJsonContent(userMessage);
    const hideTitleBar =
      modalTitle === MODAL_TITLE_BLANK ||
      modalTitle === "PERSONAL_CAPITAL_LOGO" ||
      isModalTitleLoading ||
      Boolean(logo);
    const ModalWrapper =
      IS_EMPOWER || window.isEmpowerPLA ? Modal : ModalWithAdviceDisclaimer;

    return (
      <ModalWrapper
        componentName={modalComponentName}
        className={`qa-pla-modal pc-modal--post-login-action ${className} ${
          hideTitleBar ? "pc-modal--without-title-bar" : ""
        }`}
        title={modalTitle}
        userMessage={userMessage}
        customDisclaimerData={jsonContent && jsonContent.disclaimer}
        methodology={jsonContent && jsonContent.methodology}
        onClosed={this.closeModal}
        shouldModalClose={this.shouldModalClose}
        // react-modal APIs
        shouldCloseOnOverlayClick={false}
        contentLabel={modalComponentName}
        isOpen={this.state.showModal}
        style={{ content: { width } }}
        logo={logo}
        contentClassName={contentClassName}
        loading={showLoader && !this.state.modalState?.fetched}
      >
        <ModalContentComponent
          componentName={modalComponentName}
          userMessage={userMessage}
          plaDetails={plaDetails}
          onTitleChange={this.onTitleChange}
          onRequestClose={this.onRequestClose}
          showTimeAgo={showTimeAgo}
          showFeedbackForm={showFeedbackForm}
          setOnBeforeClose={(func) => this.setState({ onBeforeClose: func })}
          modalState={this.state.modalState}
          saveState={this.saveState}
        />
      </ModalWrapper>
    );
  }
}

PostLoginActionModal.defaultProps = {
  modalComponentName: "",
  contentComponent: undefined,
  className: undefined,
  plaDetails: undefined,
  loadingModalTitle: undefined,
  width: undefined,
  mountNode: undefined,
  logo: undefined,
  showTimeAgo: false,
  showFeedbackForm: false,
  contentClassName: undefined,
  showLoader: false,
};

PostLoginActionModal.propTypes = {
  modalComponentName: PropTypes.string,
  contentComponent: PropTypes.any,
  className: PropTypes.string,
  userMessage: PropTypes.object.isRequired,
  plaDetails: PropTypes.object,
  loadingModalTitle: PropTypes.string,
  width: PropTypes.number,
  mountNode: PropTypes.object,
  logo: PropTypes.bool,
  showTimeAgo: PropTypes.bool,
  showFeedbackForm: PropTypes.bool,
  contentClassName: PropTypes.string,
  showLoader: PropTypes.bool,
};
