// used to generate the takeaways for cashflow view
import moment from "moment";

import _ from "underscore";
import * as format from "libs/pcap/utils/format";
import * as GetTransactionSummaries from "libs/pcap/utils/getTransactionSummaries";
var formatCurrency = format.formatCurrency;

var THIS_MONTH = 0,
  ONE_MONTH = 1,
  UNDER_TWO_MONTHS = 2,
  THIS_YEAR = 3,
  LAST_YEAR = 4,
  TWO_MONTHS_OR_MORE = 5,
  A_YEAR = 6,
  DRILL_DOWN_LEVEL_CATEGORY = 1,
  DRILL_DOWN_LEVEL_MERCHANT = 2,
  TWO_MONTHS = 2,
  PERCENTAGE_NUMBER = 100,
  DATE_FORMAT = "YYYY-M-D",
  DATE_FORMAT_MONTH_YEAR = "MMMM YYYY",
  now = moment(),
  PRECISION = 2;

/**
 * Return the category with cashflow information that match the input parameter categoryId
 * It will search inside cashflow object depending the incomeExpenseMode
 * @param {object} cashflow - Cashflow object containing transactions, categories and merchants.
 * @param {string} categoryId - the category id to search
 * @param {string} incomeExpenseMode - income, expense or undefined for cashflow
 * @returns {object} category
 */
function getCategory(cashflow, categoryId, incomeExpenseMode) {
  return (incomeExpenseMode === "income"
    ? cashflow.incomeCategories
    : cashflow.expenseCategories
  )
    .filter(function (category) {
      return (
        parseInt(category.transactionCategoryId, 10) ===
        parseInt(categoryId, 10)
      );
    })
    .pop();
}

/**
 * Return the transactions in the input parameter categoryId
 * @param {object} transactions - List of transactions to search in
 * @param {string} categoryId - The category id to search transactions
 * @returns {Array} transactions - Transactions in category
 */
function getCategoryTransactions(transactions, categoryId) {
  return transactions.filter(function (transaction) {
    return parseInt(transaction.categoryId, 10) === parseInt(categoryId, 10);
  });
}

/**
 * Return the transactions in the input parameter merchantId
 * @param {object} transactions - List of transactions to search in
 * @param {string} merchantId - The merchantId to search transactions
 * @returns {Array} transactions - Transactions in category
 */
function getMerchantTransactions(transactions, merchantId) {
  return transactions.filter(function (transaction) {
    return transaction.merchantId === merchantId;
  });
}

/**
 * Return the merchant with cashflow information that match the input parameter merchantId
 * It will search inside cashflow object depending the incomeExpenseMode
 * @param {object} cashflow - Cashflow object containing transactions, categories and merchants.
 * @param {string} merchantId - the merchant id to search
 * @param {string} incomeExpenseMode - income, expense or undefined for cashflow
 * @returns {object} merchant
 */
function getMerchant(cashflow, merchantId, incomeExpenseMode) {
  var merchantList =
    incomeExpenseMode === "income"
      ? cashflow.incomeMerchants
      : cashflow.expenseMerchants;
  var found;
  //get merchant
  for (var merchant in merchantList) {
    // eslint-disable-next-line no-prototype-builtins
    if (merchantList.hasOwnProperty(merchant)) {
      found = merchantList[merchant].filter(function (merch) {
        return merch.id === merchantId;
      });

      if (found.length > 0) {
        return found.pop();
      }
    }
  }
}

/**
 * Return the kind of period, i.e. This month, This year, Last year, Under two months, Two months or more.
 * @param {string} strDate - (YYYY-MM--DD) start date
 * @param {string} endDate - (YYYY-MM--DD) end date
 * @returns {Number} - Number that match the period constants
 */
function getPeriod(strDate, endDate) {
  strDate = moment(strDate, DATE_FORMAT);
  endDate = moment(endDate, DATE_FORMAT);

  //Check this month
  if (
    endDate.isSame(now, "day") &&
    strDate.isSame(now.clone().startOf("month"), "day")
  ) {
    return THIS_MONTH;
    //Check one month
  } else if (
    strDate.isSame(endDate.clone().startOf("month"), "day") &&
    endDate.isSame(strDate.clone().endOf("month"), "day")
  ) {
    return ONE_MONTH;
    //Check this year
  } else if (
    strDate.isSame(now.clone().startOf("year"), "day") &&
    endDate.isSame(now, "day")
  ) {
    return THIS_YEAR;
    //Check last year
  } else if (
    now.clone().subtract(1, "year").startOf("year").isSame(strDate, "day") &&
    now.clone().subtract(1, "year").endOf("year").isSame(endDate, "day")
  ) {
    return LAST_YEAR;
    //Check a complete year
  } else if (
    strDate.isSame(strDate.clone().startOf("year"), "day") &&
    endDate.isSame(strDate.clone().endOf("year"), "day")
  ) {
    return A_YEAR;
  }

  //Check custom under two months
  if (strDate.isSame(strDate.clone().startOf("month"), "day")) {
    //the beginning of a month
    if (
      strDate
        .clone()
        .add(TWO_MONTHS, "month")
        .subtract(1, "day")
        .isAfter(endDate, "day")
    ) {
      return UNDER_TWO_MONTHS;
    }
  } else if (strDate.clone().add(TWO_MONTHS, "month").isAfter(endDate, "day")) {
    return UNDER_TWO_MONTHS;
  }
  //More than two months
  return TWO_MONTHS_OR_MORE;
}

/**
 * Given a cashflow array, returns monthly cumulative cashflows (moneyIn, moneyOut, cashflow)
 * @param {object} cashflow - Cashflow array, containing moneyIn (and/or) moneyOut
 * @returns {Array} cumulative cashflows
 */
function getCumulativeCashFlow(cashflow) {
  var cumulativeMoneyIn = 0,
    cumulativeMoneyOut = 0,
    cumulativeCashFlow = 0,
    previusFlow;
  return cashflow.map(function (flow) {
    if (!previusFlow) {
      previusFlow = flow;
    }
    //If is a different month reset cumulatives
    if (moment(previusFlow.date).month() !== moment(flow.date).month()) {
      cumulativeMoneyIn = 0;
      cumulativeMoneyOut = 0;
      cumulativeCashFlow = 0;
      previusFlow = flow;
    }
    //Calc cumulative
    cumulativeMoneyIn += flow.moneyIn ? flow.moneyIn : 0;
    cumulativeMoneyOut += flow.moneyOut ? flow.moneyOut : 0;
    if (flow.moneyIn && flow.moneyOut) {
      cumulativeCashFlow += flow.moneyIn - flow.moneyOut;
    } else if (flow.moneyIn) {
      cumulativeCashFlow += flow.moneyIn;
    } else {
      cumulativeCashFlow -= flow.moneyOut;
    }
    return {
      date: flow.date,
      cumulativeMoneyIn: cumulativeMoneyIn,
      cumulativeMoneyOut: cumulativeMoneyOut,
      cumulativeCashFlow: cumulativeCashFlow,
    };
  });
}

/**
 * Given a cashflow array, return the cumulative moneyIn, moneyOut and cashflow that match the date provided
 * @param {Array} cashflow - Array with cashflows
 * @param {string} date - (YYYY-MM-DD) Date to get cumulative data
 * @returns {object} cumulativeCashFlow - Object with cumulative moneyIn, moneyOut and CashFlow
 */
function getCumulative(cashflow, date) {
  return getCumulativeCashFlow(cashflow)
    .filter(function (element) {
      return element.date === date;
    })
    .pop();
}

/**
 * Returns cumulative cashflow object between the dates passed by param.
 * @param {Array} cashflow - cashflow array coming from cashflow API
 * @param {date} startDate - moment.js alike
 * @param {date} endDate - moment.js alike
 * @returns {object} cumulative cashflow
 */
function getCumulativeBetweenDates(cashflow, startDate, endDate) {
  var filteredCashflow = cashflow.filter(function (element) {
    return (
      (moment(element.date).isSame(startDate) ||
        moment(element.date).isAfter(startDate)) &&
      (moment(element.date).isSame(endDate) ||
        moment(element.date).isBefore(endDate))
    );
  });
  return getCumulativeCashFlow(filteredCashflow);
}

/**
 * Return the end of last month if the today date is the end of current month OR return this time last month (same day number last month)
 * @returns {string} return date - (YYYY-MM-DD) the end of last month or this time last month
 */
function getLastMonthEndDate() {
  var date = now.clone();
  return (date.isSame(date.clone().endOf("month"), "day")
    ? date.subtract(1, "month").endOf("month")
    : date.subtract(1, "month")
  ).format("YYYY-MM-DD");
}

/**
 * Get cashflow monthly average from cashflow array passed by param
 * @param {Array} transactions - Transactions array
 * @param {string} startDate - the initial date (DATE_FORMAT) to calculate the average factor
 * @param {string} endDate - the end date (DATE_FORMAT) to calculate the average factor
 * @returns {Number} average - the monthly average value
 */
function getCashFlowMonthlyAverage(transactions, startDate, endDate) {
  var incomeTransactions = [],
    expenseTransactions = [];
  for (var i = 0; i < transactions.length; i++) {
    var transaction = transactions[i];
    // include transactions that belong to cash manager only
    if (transaction.includeInCashManager) {
      if (transaction.isCredit) {
        incomeTransactions.push(transaction);
      } else {
        expenseTransactions.push(transaction);
      }
    }
  }

  var monthlyAverageIn = GetTransactionSummaries.getAverageAmount(
    startDate,
    endDate,
    incomeTransactions,
    "MONTH"
  );
  var monthlyAverageOut = GetTransactionSummaries.getAverageAmount(
    startDate,
    endDate,
    expenseTransactions,
    "MONTH"
  );

  return {
    monthlyAverageIn: monthlyAverageIn,
    monthlyAverageOut: monthlyAverageOut,
    cashflowAverage: monthlyAverageIn - monthlyAverageOut,
  };
}

/**
 * Given input object, return merchants takeaways
 * @param {object} options - Object with params
 * @param {string} options.startDate - The start date to calc the period for getting takeaways
 * @param {string} options.endDate - The start date to calc the period for getting takeaways
 * @param {object} options.current - The current cashflow object with transactions, the result of cashflow lib
 * @param {string} options.incomeExpenseMode - The income expense mode which we want to get takeaways: 'income', 'expense'
 * @param {Number} options.period - The selected period to get takeaways
 * @param {object} [options.previus] - The previus cashflow object with transactions, the result of cashflow lib
 * @param {string} [options.merchantId] - The merchantId to get specific merchant takeaways
 * @param {Number} [options.categoryId] - The categoryId to get specific category takeaways
 * @returns {object} takeAways - object with firstTakeAway and secondTakeAway
 */

function getMerchantTakeAway(options) {
  /* Disabled to keep consistency and readability on this Lib */
  var category,
    percentage,
    value,
    previusMonthMerchant,
    firstTakeAway,
    secondTakeAway;
  //Get merchant
  var merchant = getMerchant(
    options.current,
    options.merchantId,
    options.incomeExpenseMode
  );
  category = getCategory(
    options.current,
    options.categoryId,
    options.incomeExpenseMode
  );

  if (merchant) {
    percentage = (merchant.amount * PERCENTAGE_NUMBER) / category.amount;
    switch (options.period) {
      case ONE_MONTH:
        firstTakeAway = _.template(
          "<% print(percentage.toFixed(1)) %>% of all <% print(category) %>."
        )({ percentage: percentage, category: category.name });
        if (
          options.previus &&
          (options.previus.incomeCategories ||
            options.previus.expenseCategories)
        ) {
          previusMonthMerchant = getMerchant(
            options.previus,
            options.merchantId,
            options.incomeExpenseMode
          );
        }

        if (previusMonthMerchant) {
          value = merchant.amount - previusMonthMerchant.amount;
        } else if (merchant) {
          value = merchant.amount;
        } else {
          value = 0;
        }

        if (value === 0) {
          secondTakeAway = _.template(
            "Same <% print(earnedSpentKeyword) %> as <% print(monthYear) %>."
          )({
            earnedSpentKeyword: options.earnedSpentKeyword,
            monthYear: moment(options.startDate)
              .subtract(1, "month")
              .format(DATE_FORMAT_MONTH_YEAR),
          });
        } else if (value > 0) {
          secondTakeAway = _.template(
            "<% print(value) %> <% print(earnedSpentKeyword) %> over <% print(monthYear) %>."
          )({
            earnedSpentKeyword: options.earnedSpentKeyword,
            value: formatCurrency(value, options.precision),
            monthYear: moment(options.startDate)
              .subtract(1, "month")
              .format(DATE_FORMAT_MONTH_YEAR),
          });
        } else {
          value = formatCurrency(-value, options.precision);
          secondTakeAway = _.template(
            "<% print(value) %> <% print(earnedSpentKeyword) %> under <% print(monthYear) %>."
          )({
            earnedSpentKeyword: options.earnedSpentKeyword,
            value: value,
            monthYear: moment(options.startDate)
              .subtract(1, "month")
              .format(DATE_FORMAT_MONTH_YEAR),
          });
        }
        break;
      case UNDER_TWO_MONTHS:
        firstTakeAway = _.template(
          "<% print(percentage.toFixed(1)) %>% of all <% print(category) %>."
        )({ percentage: percentage, category: category.name });
        break;
      case TWO_MONTHS_OR_MORE:
      case THIS_YEAR:
      case LAST_YEAR:
      case A_YEAR:
        var transactions = getMerchantTransactions(
          options.current.transactions,
          options.merchantId
        );
        value = getCashFlowMonthlyAverage(
          transactions,
          options.startDate,
          options.endDate
        );
        value =
          options.incomeExpenseMode === "income"
            ? value.monthlyAverageIn
            : value.monthlyAverageOut;

        firstTakeAway = _.template(
          "<% print(percentage.toFixed(1)) %>% of all <% print(category) %>."
        )({ percentage: percentage, category: category.name });
        secondTakeAway = _.template(
          "<% print(value) %> <% print(earnedSpentKeyword) %> per month on average."
        )({
          earnedSpentKeyword: options.earnedSpentKeyword,
          value: formatCurrency(value, options.precision),
        });
        break;
      default:
        //this month
        firstTakeAway = _.template(
          "<% print(percentage.toFixed(1)) %>% of all <% print(category) %>."
        )({ percentage: percentage, category: category.name });
        if (
          options.previus &&
          (options.previus.incomeCategories ||
            options.previus.expenseCategories)
        ) {
          previusMonthMerchant = getMerchant(
            options.previus,
            options.merchantId,
            options.incomeExpenseMode
          );
        }
        value = previusMonthMerchant
          ? merchant.amount - previusMonthMerchant.amount
          : merchant.amount;
        if (value === 0) {
          secondTakeAway = _.template(
            "Same <% print(earnedSpentKeyword) %> as this time last month."
          )({ earnedSpentKeyword: options.earnedSpentKeyword });
        } else if (value > 0) {
          secondTakeAway = _.template(
            "<% print(value) %> <% print(earnedSpentKeyword) %> over this time last month."
          )({
            earnedSpentKeyword: options.earnedSpentKeyword,
            value: formatCurrency(value, options.precision),
          });
        } else {
          value = formatCurrency(-value, options.precision);
          secondTakeAway = _.template(
            "<% print(value) %> <% print(earnedSpentKeyword) %> under this time last month."
          )({ earnedSpentKeyword: options.earnedSpentKeyword, value: value });
        }
        break;
    }
  }

  return {
    firstTakeAway: firstTakeAway ? firstTakeAway : "",
    secondTakeAway: secondTakeAway ? secondTakeAway : "",
  };
}

/**
 * Given input object, return category takeaways
 * @param {object} options - Object with params
 * @param {string} options.startDate - The start date to calc the period for getting takeaways
 * @param {string} options.endDate - The start date to calc the period for getting takeaways
 * @param {object} options.current - The current cashflow object with transactions, the result of cashflow lib
 * @param {string} options.incomeExpenseMode - The income expense mode which we want to get takeaways: 'income', 'expense'
 * @param {Number} options.period - The selected period to get takeaways
 * @param {object} [options.previus] - The previus cashflow object with transactions, the result of cashflow lib
 * @param {Number} [options.categoryId] - The categoryId to get specific category takeaways
 * @returns {object} takeAways - object with firstTakeAway and secondTakeAway
 */
function getCategoryTakeAway(options) {
  var previusMonthCategory,
    value,
    firstTakeAway,
    secondTakeAway,
    category = getCategory(
      options.current,
      options.categoryId,
      options.incomeExpenseMode
    );
  if (category) {
    switch (options.period) {
      case ONE_MONTH:
        if (
          options.previus &&
          (options.previus.incomeCategories ||
            options.previus.expenseCategories)
        ) {
          previusMonthCategory = getCategory(
            options.previus,
            options.categoryId,
            options.incomeExpenseMode
          );
          value =
            category.amount -
            (previusMonthCategory ? previusMonthCategory.amount : 0);
        } else {
          value = category.amount;
        }

        firstTakeAway = _.template(
          "<% print(percentage.toFixed(1)) %>% of all <% print(incomeExpensesKeyword) %>."
        )({
          incomeExpensesKeyword: options.incomeExpensesKeyword,
          percentage: category.percent,
        });

        if (value === 0) {
          secondTakeAway = _.template(
            "Same <% print(earnedSpentKeyword) %> as <% print(monthYear) %>."
          )({
            earnedSpentKeyword: options.earnedSpentKeyword,
            monthYear: moment(options.startDate)
              .subtract(1, "month")
              .format(DATE_FORMAT_MONTH_YEAR),
          });
        } else if (value > 0) {
          secondTakeAway = _.template(
            "<% print(value) %> <% print(earnedSpentKeyword) %> over <% print(monthYear) %>."
          )({
            earnedSpentKeyword: options.earnedSpentKeyword,
            value: formatCurrency(value, options.precision),
            monthYear: moment(options.startDate)
              .subtract(1, "month")
              .format(DATE_FORMAT_MONTH_YEAR),
          });
        } else {
          value = formatCurrency(-value, options.precision);
          secondTakeAway = _.template(
            "<% print(value) %> <% print(earnedSpentKeyword) %> under <% print(monthYear) %>."
          )({
            earnedSpentKeyword: options.earnedSpentKeyword,
            value: value,
            monthYear: moment(options.startDate)
              .subtract(1, "month")
              .format(DATE_FORMAT_MONTH_YEAR),
          });
        }
        break;
      case UNDER_TWO_MONTHS:
        firstTakeAway = _.template(
          "<% print(percentage.toFixed(1)) %>% of all <% print(incomeExpensesKeyword) %>."
        )({
          incomeExpensesKeyword: options.incomeExpensesKeyword,
          percentage: category.percent,
        });
        break;
      case TWO_MONTHS_OR_MORE:
      case THIS_YEAR:
      case LAST_YEAR:
      case A_YEAR:
        var transactions = getCategoryTransactions(
          options.current.transactions,
          options.categoryId
        );
        value = getCashFlowMonthlyAverage(
          transactions,
          options.startDate,
          options.endDate
        );
        value =
          options.incomeExpenseMode === "income"
            ? value.monthlyAverageIn
            : value.monthlyAverageOut;
        firstTakeAway = _.template(
          "<% print(percentage.toFixed(1)) %>% of all <% print(incomeExpensesKeyword) %>."
        )({
          percentage: category.percent,
          incomeExpensesKeyword: options.incomeExpensesKeyword,
        });
        secondTakeAway = _.template(
          "<% print(value) %> <% print(earnedSpentKeyword) %> per month on average."
        )({
          earnedSpentKeyword: options.earnedSpentKeyword,
          value: formatCurrency(value, options.precision),
        });
        break;
      default:
        //this month
        previusMonthCategory = getCategory(
          options.previus,
          options.categoryId,
          options.incomeExpenseMode
        );
        if (previusMonthCategory) {
          var previusPeriodMoney = getCumulative(
            previusMonthCategory.cashFlow,
            getLastMonthEndDate()
          );
          value =
            options.incomeExpenseMode === "income"
              ? category.amount - previusPeriodMoney.cumulativeMoneyIn
              : category.amount - previusPeriodMoney.cumulativeMoneyOut;
        } else {
          value = category.amount;
        }

        firstTakeAway = _.template(
          "<% print(percentage.toFixed(1)) %>% of all <% print(incomeExpensesKeyword) %>."
        )({
          percentage: category.percent,
          incomeExpensesKeyword: options.incomeExpensesKeyword,
        });

        if (value === 0) {
          secondTakeAway = _.template(
            "Same <% print(earnedSpentKeyword) %> as this time last month."
          )({ earnedSpentKeyword: options.earnedSpentKeyword });
        } else if (value > 0) {
          secondTakeAway = _.template(
            "<% print(value) %> <% print(earnedSpentKeyword) %> over this time last month."
          )({
            earnedSpentKeyword: options.earnedSpentKeyword,
            value: formatCurrency(value, options.precision),
          });
        } else {
          value = formatCurrency(-value, options.precision);
          secondTakeAway = _.template(
            "<% print(value) %> <% print(earnedSpentKeyword) %> under this time last month."
          )({ earnedSpentKeyword: options.earnedSpentKeyword, value: value });
        }
        break;
    }
  }
  return {
    firstTakeAway: firstTakeAway ? firstTakeAway : "",
    secondTakeAway: secondTakeAway ? secondTakeAway : "",
  };
}

/**
 * Given input object, return top level takeaways
 * @param {object} options - Object with params
 * @param {string} options.startDate - The start date to calc the period for getting takeaways
 * @param {string} options.endDate - The start date to calc the period for getting takeaways
 * @param {object} options.current - The current cashflow object with transactions, the result of cashflow lib
 * @param {string} options.incomeExpenseMode - The income expense mode which we want to get takeaways: 'income', 'expense'
 * @param {Number} options.period - The selected period to get takeaways
 * @param {object} [options.previus] - The previus cashflow object with transactions, the result of cashflow lib
 * @returns {object} takeAways - object with firstTakeAway and secondTakeAway
 */
function getTopLevelTakeAway(options) {
  var value, firstTakeAway, previusPeriodMoney;
  switch (options.period) {
    case UNDER_TWO_MONTHS:
      break;
    case ONE_MONTH:
      if (options.previus) {
        value =
          options.incomeExpenseMode === "income"
            ? options.current.moneyIn - options.previus.moneyIn
            : options.current.moneyOut - options.previus.moneyOut;
      } else {
        value =
          options.incomeExpenseMode === "income"
            ? options.current.moneyIn
            : options.current.moneyOut;
      }

      if (value === 0) {
        firstTakeAway = _.template(
          "Same <% print(earnedSpentKeyword) %> as <% print(monthYear) %>."
        )({
          earnedSpentKeyword: options.earnedSpentKeyword,
          monthYear: moment(options.startDate)
            .subtract(1, "month")
            .format(DATE_FORMAT_MONTH_YEAR),
        });
      } else if (value > 0) {
        firstTakeAway = _.template(
          "<% print(value) %> <% print(earnedSpentKeyword) %> over <% print(monthYear) %>."
        )({
          earnedSpentKeyword: options.earnedSpentKeyword,
          value: formatCurrency(value, options.precision),
          monthYear: moment(options.startDate)
            .subtract(1, "month")
            .format(DATE_FORMAT_MONTH_YEAR),
        });
      } else {
        value = formatCurrency(-value);
        firstTakeAway = _.template(
          "<% print(value) %> <% print(earnedSpentKeyword) %> under <% print(monthYear) %>."
        )({
          earnedSpentKeyword: options.earnedSpentKeyword,
          value: value,
          monthYear: moment(options.startDate)
            .subtract(1, "month")
            .format(DATE_FORMAT_MONTH_YEAR),
        });
      }
      break;
    case TWO_MONTHS_OR_MORE:
    case THIS_YEAR:
    case LAST_YEAR:
    case A_YEAR:
      value = getCashFlowMonthlyAverage(
        options.current.transactions,
        options.startDate,
        options.endDate
      );
      value =
        options.incomeExpenseMode === "income"
          ? value.monthlyAverageIn
          : value.monthlyAverageOut;
      firstTakeAway = _.template(
        "<% print(avgValue) %> <% print(earnedSpentKeyword) %> per month on average."
      )({
        earnedSpentKeyword: options.earnedSpentKeyword,
        avgValue: formatCurrency(value, options.precision),
      });
      break;
    default:
      //this month
      if (options.previus && options.previus.cashFlow) {
        previusPeriodMoney = getCumulative(
          options.previus.cashFlow,
          getLastMonthEndDate()
        );
        value =
          options.incomeExpenseMode === "income"
            ? options.current.moneyIn - previusPeriodMoney.cumulativeMoneyIn
            : options.current.moneyOut - previusPeriodMoney.cumulativeMoneyOut;
      } else {
        value =
          options.incomeExpenseMode === "income"
            ? options.current.moneyIn
            : options.current.moneyOut;
      }

      if (value === 0) {
        firstTakeAway = _.template(
          "Same <% print(earnedSpentKeyword) %> as this time last month."
        )({ earnedSpentKeyword: options.earnedSpentKeyword });
      } else if (value > 0) {
        firstTakeAway = _.template(
          "<% print(value) %> <% print(earnedSpentKeyword) %> over this time last month."
        )({
          earnedSpentKeyword: options.earnedSpentKeyword,
          value: formatCurrency(value, options.precision),
        });
      } else {
        value = formatCurrency(-value, options.precision);
        firstTakeAway = _.template(
          "<% print(value) %> <% print(earnedSpentKeyword) %> under this time last month."
        )({ earnedSpentKeyword: options.earnedSpentKeyword, value: value });
      }
      break;
  }
  // use only second takeaway slot, so relevant information stays on the correct side.
  return {
    firstTakeAway: "",
    secondTakeAway: firstTakeAway,
  };
}

var takeAwaysTemplates = {
  /**
   * Given a drillDownLevel income/expense, call the template function
   * @param {object} options - Object with params
   * @param {string} options.startDate - The start date to calc the period for getting takeaways
   * @param {string} options.endDate - The start date to calc the period for getting takeaways
   * @param {object} options.current - The current cashflow object with transactions, the result of cashflow lib
   * @param {string} options.incomeExpenseMode - The income expense mode which we want to get takeaways: 'income', 'expense'
   * @param {Number} options.period - The selected period to get takeaways
   * @param {object} [options.previus] - The previus cashflow object with transactions, the result of cashflow lib
   * @param {string} [options.merchantId] - The merchantId to get specific merchant takeaways
   * @param {Number} [options.categoryId] - The categoryId to get specific category takeaways
   * @returns {object} takeAways - object with firstTakeAway and secondTakeAway
   */
  getIncomeExpenseTakeAway: function (options) {
    var getDrillDownTakeawaysOptions = {
      current: options.current,
      previus: options.previus,
      merchantId: options.merchantId ? options.merchantId : null,
      categoryId: options.categoryId ? options.categoryId : null,
      startDate: options.startDate,
      endDate: options.endDate,
      incomeExpenseMode: options.incomeExpenseMode
        ? options.incomeExpenseMode
        : null,
      period: options.period,
    };

    if (options.incomeExpenseMode) {
      getDrillDownTakeawaysOptions.earnedSpentKeyword =
        options.incomeExpenseMode === "income" ? "earned" : "spent";
      getDrillDownTakeawaysOptions.incomeExpensesKeyword =
        options.incomeExpenseMode === "income" ? "income" : "expenses";
    }

    switch (options.drillDownLevel) {
      case DRILL_DOWN_LEVEL_MERCHANT:
        return getMerchantTakeAway(getDrillDownTakeawaysOptions);
      case DRILL_DOWN_LEVEL_CATEGORY:
        return getCategoryTakeAway(getDrillDownTakeawaysOptions);
      default:
        //top level
        return getTopLevelTakeAway(getDrillDownTakeawaysOptions);
    }
  },
  /**
   * Given a start date and end date generate the template
   * @param {object} options - Object with params
   * @param {string} options.startDate - The start date to calc the period for getting takeaways
   * @param {string} options.endDate - The start date to calc the period for getting takeaways
   * @param {object} options.current - The current cashflow object with transactions, the result of cashflow lib
   * @param {Number} options.period - The selected period to get takeaways
   * @param {object} [options.previus] - The previus cashflow object with transactions, the result of cashflow lib
   * @returns {object} takeAways - object with firstTakeAway and secondTakeAway
   */
  getCashFlowTakeAway: function (options) {
    var value, firstTakeAway, secondTakeAway;
    switch (options.period) {
      case UNDER_TWO_MONTHS:
        firstTakeAway = _.template(
          "Cash Flow <% print(startDate) %> to <% print(endDate) %>: <% print(value) %>"
        )({
          startDate: moment(options.startDate).format("M/D/YYYY"),
          endDate: moment(options.endDate).format("M/D/YYYY"),
          value: formatCurrency(options.current.netCashFlow, options.precision),
        });
        break;
      case ONE_MONTH:
        firstTakeAway = _.template(
          "Cash Flow <% print(month) %>: <% print(value) %>"
        )({
          month: moment(options.startDate).format(DATE_FORMAT_MONTH_YEAR),
          value: formatCurrency(options.current.netCashFlow, options.precision),
        });
        if (options.previus && options.previus.netCashFlow) {
          value = options.current.netCashFlow - options.previus.netCashFlow;
        } else {
          value = options.current.netCashFlow;
        }

        if (value === 0) {
          secondTakeAway = _.template("Same as <% print(month) %>.")({
            month: moment(options.startDate)
              .subtract(1, "month")
              .format(DATE_FORMAT_MONTH_YEAR),
          });
        } else if (value > 0) {
          secondTakeAway = _.template(
            "<% print(value) %> over <% print(month) %>."
          )({
            value: formatCurrency(value, options.precision),
            month: moment(options.startDate)
              .subtract(1, "month")
              .format(DATE_FORMAT_MONTH_YEAR),
          });
        } else {
          value = formatCurrency(-value, options.precision);
          secondTakeAway = _.template(
            "<% print(value) %> under <% print(month) %>."
          )({
            value: value,
            month: moment(options.startDate)
              .subtract(1, "month")
              .format(DATE_FORMAT_MONTH_YEAR),
          });
        }
        break;
      case TWO_MONTHS_OR_MORE:
        firstTakeAway = _.template(
          "Cash Flow <% print(startDate) %> to <% print(endDate) %>: <% print(value) %>"
        )({
          startDate: moment(options.startDate).format("M/D/YYYY"),
          endDate: moment(options.endDate).format("M/D/YYYY"),
          value: formatCurrency(options.current.netCashFlow, options.precision),
        });
        secondTakeAway = _.template("Monthly average: <% print(value) %>")({
          value: formatCurrency(
            getCashFlowMonthlyAverage(
              options.current.transactions,
              options.startDate,
              options.endDate
            ).cashflowAverage,
            options.precision
          ),
        });
        break;
      case THIS_YEAR:
      case LAST_YEAR:
      case A_YEAR:
        if (options.period === THIS_YEAR) {
          firstTakeAway = _.template("Cash Flow this year: <% print(value) %>")(
            {
              value: formatCurrency(
                options.current.netCashFlow,
                options.precision
              ),
            }
          );
        } else if (options.period === LAST_YEAR) {
          firstTakeAway = _.template("Cash Flow last year: <% print(value) %>")(
            {
              value: formatCurrency(
                options.current.netCashFlow,
                options.precision
              ),
            }
          );
        } else {
          //a year
          firstTakeAway = _.template(
            "Cash Flow <% print(year) %>: <% print(value) %>"
          )({
            year: moment(options.startDate).format("YYYY"),
            value: formatCurrency(
              options.current.netCashFlow,
              options.precision
            ),
          });
        }

        var thisMonthCumulative = getCumulativeBetweenDates(
          options.current.cashFlow,
          now.clone().startOf("month"),
          now
        );
        if (thisMonthCumulative) {
          secondTakeAway = _.template("Monthly average: <% print(value) %>")({
            value: formatCurrency(
              getCashFlowMonthlyAverage(
                options.current.transactions,
                options.startDate,
                options.endDate
              ).cashflowAverage,
              options.precision
            ),
          });
        }
        break;
      default:
        //this month
        firstTakeAway = _.template("Cash flow this month: <% print(value) %>")({
          value: formatCurrency(options.current.netCashFlow, options.precision),
        });

        if (options.previus && options.previus.cashFlow) {
          var cumulative = getCumulative(
            options.previus.cashFlow,
            getLastMonthEndDate()
          );
          value = options.current.netCashFlow - cumulative.cumulativeCashFlow;
        } else {
          value = options.current.netCashFlow;
        }

        if (value === 0) {
          secondTakeAway = _.template("Same as this time last month.")();
        } else if (value > 0) {
          secondTakeAway = _.template(
            "<% print(value) %> over this time last month."
          )({ value: formatCurrency(value, options.precision) });
        } else {
          value = formatCurrency(-value, options.precision);
          secondTakeAway = _.template(
            "<% print(value) %> under this time last month."
          )({ value: value });
        }
        break;
    }
    return {
      firstTakeAway: firstTakeAway ? firstTakeAway : "",
      secondTakeAway: secondTakeAway ? secondTakeAway : "",
    };
  },
};

/**
 * Public API function, wrap up the inner functionality to get takeAways
 * @param {object} options - Object with params
 * @param {string} options.startDate - The start date to calc the period for getting takeaways
 * @param {string} options.endDate - The start date to calc the period for getting takeaways
 * @param {object} options.current - The current cashflow object with transactions, the result of cashflow lib
 * @param {string} [options.incomeExpenseMode] - The income expense mode which we want to get takeaways: 'income', 'expense'
 * @param {object} [options.previus] - The previus cashflow object with transactions, the result of cashflow lib
 * @param {string} [options.merchantId] - The merchantId to get specific merchant takeaways
 * @param {Number} [options.categoryId] - The categoryId to get specific category takeaways
 * @param {Number} [options.precision] - Number of decimal places for take away amount
 * @returns {object} takeAways - object with firstTakeAway and secondTakeAway
 */
function getTakeaways(options) {
  var params = {
    previus: options.prevCashFlow,
    current: options.currentCashFlow,
    startDate: options.startDate,
    endDate: options.endDate,
    drillDownLevel: options.drillDownLevel,
    incomeExpenseMode: options.incomeExpenseMode,
    categoryId: options.categoryId,
    merchantId: options.merchantId,
    period: getPeriod(options.startDate, options.endDate),
    precision: _.isUndefined(options.precision) ? PRECISION : options.precision,
  };
  return options.incomeExpenseMode
    ? takeAwaysTemplates.getIncomeExpenseTakeAway(params)
    : takeAwaysTemplates.getCashFlowTakeAway(params);
}

export default {
  getTakeaways: getTakeaways,
  now: now,
  getCashFlowMonthlyAverage: getCashFlowMonthlyAverage,
};
