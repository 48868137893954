import _ from "underscore";
import objectPath from "object-path";
import { DISPLAY_LOCATION__ACE_BLOCK } from "components/aceBlock/AceBlock";

/**
 * Iterates user messages to sort messages into Ace Messages and Other messages.
 * @param {array} rawMessages                   an array of user messages
 * @param {function} dashboardMessageCallback   function to execute when aceMessage is dismissed
 * @returns {object}                            object.aceMessages is dictionary of messages indexed by display displayLocations
 *                                              object.messages is array of all other messages
 */
export default function sortDashboardUserMessages(
  rawMessages,
  dashboardMessageCallback
) {
  const allMessages = { messages: [], dashboardMessages: {} };
  const dashboardMessages = allMessages.dashboardMessages;

  _.each(rawMessages, (message) => {
    const message1stDisplayLocation =
      objectPath.get(message, "displayLocations.0") || "";
    const isDashboardMessage =
      message1stDisplayLocation.indexOf("#/dashboard") > -1;

    if (
      isDashboardMessage &&
      message.category !== "ZERO_STATE" &&
      !message.isStale
    ) {
      if (typeof dashboardMessageCallback === "function") {
        message.dismissFunction = function () {
          dashboardMessageCallback(message);
        };
      }

      if (message1stDisplayLocation) {
        if (message1stDisplayLocation === DISPLAY_LOCATION__ACE_BLOCK) {
          if (dashboardMessages[message1stDisplayLocation]) {
            // There are more than one ACE Block user messages, use the one with the highest rank
            const previousMsgRank =
              objectPath.get(
                dashboardMessages,
                `${message1stDisplayLocation}.rank`
              ) || 0;
            const currentMsgRank = message.rank || 0;
            if (currentMsgRank > previousMsgRank) {
              dashboardMessages[message1stDisplayLocation] = message;
            }
          } else {
            dashboardMessages[message1stDisplayLocation] = message;
          }
        } else if (!dashboardMessages[message1stDisplayLocation]) {
          // Show only the first message in each unique display location
          dashboardMessages[message1stDisplayLocation] = message;
        }
      }
    } else if (isDashboardMessage && message.category === "ZERO_STATE") {
      allMessages.messages.push(message);
    } else if (!message.isStale && !message.lastViewedTime) {
      allMessages.messages.push(message);
    }
  });
  return allMessages;
}
