import PropTypes from "prop-types";
import React from "react";
import CompletenessMeterContainer from "components/completenessMeter/CompletenessMeterContainer";
import { isEmpty } from "underscore";

export default class TopRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { completenessMeterData } = this.props;
    return (
      <div>
        {" "}
        {completenessMeterData && !isEmpty(completenessMeterData) ? (
          <div className="dashboard__top-row">
            <div className="dashboard__row">
              <div className="dashboard__cell">
                <div className="dashboard__item dashboard__item--short">
                  <CompletenessMeterContainer data={completenessMeterData} />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

TopRow.propTypes = {
  completenessMeterData: PropTypes.object,
};

TopRow.defaultProps = {
  completenessMeterData: undefined,
};
