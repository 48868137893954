var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./site.html"),depth0,{"name":"templates/modules/sidebar/site","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"js-account-extras sidebar-account__extras is-hidden\">\n  <div class=\"sidebar-account__message\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0))
    + "</div>\n  <div class=\"sidebar-account__actions\">\n    <button type=\"button\" class='js-account-cancel pc-btn pc-btn--tiny js-account-action'>Cancel</button>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});