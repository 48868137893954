import { normalizeForDisplay } from "libs/pcap/utils/humanize";
import React from "react";
import { formatPercent } from "libs/pcap/utils/format";

const DEBT_PRODUCT_LABELS = {
  CREDIT_CARD: "Credit Card",
  LOAN: "Loan",
  MORTGAGE: "Mortgage",
};

export const SAVINGS_PLANNER_SUCCESS_PROBABILITIES = {
  SP50: "SP50",
  SP70: "SP70",
  SP90: "SP90",
};

export const DISCLAIMER_EMPOWER =
  "The calculations in the Savings planner are based solely on information provided by the user data based on the accounts that you have aggregated on your homepage, historical market returns and the assumptions shown or selected. Because there is no way to know all information about your finances or your personal situation, analysis may be incomplete or inaccurate. All insights provided are for illustrative purposes only and you should not rely on this information as the primary basis of your investment, financial, or tax planning decisions. No representations, warranties or guarantees are made as to the accuracy of any estimates or calculations. We are not liable for any damages or costs of any type arising out of or in any way connected with your use of this tool. The information provided in the Savings planner does not serve, either directly or indirectly, as legal, financial or tax advice and you should always consult a qualified professional legal, financial and/or tax advisor for your specific situation.";

export const ADVISORY_TITLE_EMPOWER = "For Advisory Clients";

export const ADVISORY_TEXT_EMPOWER =
  "If you are a customer utilizing My Total Retirement™, Comprehensive Financial Planning, or managed account services offered by an adviser firm partner, you can generate personalized account-level savings targets. After collecting your household income, account ownership, account type, and employer plan details, your personalized account-level savings targets can be generated by applying contribution limits and assumptions about the long-term advantages of investing in various account types. For example if eligible, tax efficient accounts are prioritized above taxable brokerage accounts.";

export const totalDebtBalance = (accounts) => {
  return accounts.reduce((total, account) => total + (account.balance || 0), 0);
};

export function getTaxType(account) {
  if (account.accountCategoryType) {
    return normalizeForDisplay(account.accountCategoryType, "_");
  }

  // Default
  return "Other";
}

export function getDebtProductType(account) {
  return DEBT_PRODUCT_LABELS[account.productType] || "Other";
}

export const SavingsPlannerSections = {
  RETIREMENT_SAVINGS: "retirement",
  EMERGENCY_FUND: "emergency-fund",
  DEBT_PAYDOWN: "debt-paydown",
};

export function parseAccountOwners(account) {
  const { personAccounts } = account;
  if (personAccounts && personAccounts.length) {
    const names = personAccounts
      .map((pa) => {
        if (pa.personName && pa.personName.firstName) {
          return pa.personName.firstName;
        }
        return null;
      })
      .filter((name) => name); // Remove any account holders who don't have a first name defined

    return names.join(" and ");
  }

  return "";
}

export function getAccountTypeInfo(
  account,
  isClient = false,
  accountLabels = []
) {
  const { accountErrors, accountType } = account;
  const { MISSING_ACCOUNT_TYPE: missingAccountTypeMessage } =
    accountErrors || {};

  if (missingAccountTypeMessage) {
    if (isClient) {
      return (
        <span
          className={
            "pc-help-block--error savings-planner-table_account-type-missing"
          }
        >
          {missingAccountTypeMessage}
        </span>
      );
    }
    return "n/a";
  }

  const accountLabel = accountType
    ? accountLabels.find((a) => a.value === accountType.type)?.label
    : undefined;

  return (
    accountLabel || normalizeForDisplay((accountType && accountType.type) || "")
  );
}

export function getAccountOwnerInfo(account, isClient) {
  const { accountErrors } = account;
  const { MISSING_ACCOUNT_OWNER: missingOwnerMessage } = accountErrors || {};

  if (missingOwnerMessage) {
    if (isClient) {
      return (
        <span className={"pc-help-block--error"}>{missingOwnerMessage}</span>
      );
    }
    return "n/a";
  }

  return parseAccountOwners(account);
}

export function getAccountAprInfo(account, isClient) {
  const { accountErrors, interestRate } = account;
  const { MISSING_APR: missingAprMessage } = accountErrors || {};

  if (missingAprMessage) {
    if (isClient) {
      return (
        <span className={"pc-help-block--error"}>{missingAprMessage}</span>
      );
    }

    return "n/a";
  }

  return <span>{formatPercent(interestRate)}</span>;
}

export const SavingsHistoryPeriod = {
  TIME_PERIOD_THIS_YEAR: "YTD",
  LABEL_THIS_YEAR: "Saved This Year",
  LABEL_THIS_YEAR_RETIRED: "Withdrawn This Year",
  TIME_PERIOD_LAST_YEAR: "LAST_YEAR",
  LABEL_LAST_YEAR: "Saved Last Year",
  LABEL_LAST_YEAR_RETIRED: "Withdrawn Last Year",
};

export function buildTooltipText(
  isRetired,
  timePeriod = SavingsHistoryPeriod.TIME_PERIOD_THIS_YEAR,
  hasTarget = true
) {
  const isThisYear = timePeriod === SavingsHistoryPeriod.TIME_PERIOD_THIS_YEAR;
  let tooltipText =
    "Retirement savings is made up of this year's net inflows to investment accounts.";

  if (isRetired && isThisYear) {
    tooltipText =
      "The amount withdrawn is made up of this year’s net outflows from investment accounts.";
    if (hasTarget)
      tooltipText +=
        " The Plan amounts are the suggested targets per account tax status based on the inputs and assumptions in Retirement Planner.";
  }
  return tooltipText;
}

export const sortByMissingInfo = (nextAccount, firstAccount) => {
  if (nextAccount && firstAccount) {
    return (
      Object.keys(firstAccount.accountErrors).length -
      Object.keys(nextAccount.accountErrors).length
    );
  }
  return 0;
};

export const sortByIsEmpowerFlag = (nextAccount, firstAccount) => {
  if (nextAccount && firstAccount) {
    return (
      Number(Boolean(firstAccount.isEmpower)) -
      Number(Boolean(nextAccount.isEmpower))
    );
  }
  return 0;
};

export const INVESTMENT_CONTRIBUTION_LIMIT = {
  CALCULATED_PREFERENCES: {
    USER: "USER_INPUT",
    CALCULATED: "CALCULATED",
    NONE: "NONE",
  },
};

export const hasUserOverride = (calculatedValuePreference) => {
  return (
    INVESTMENT_CONTRIBUTION_LIMIT.CALCULATED_PREFERENCES.USER.localeCompare(
      calculatedValuePreference
    ) === 0
  );
};

export const getSavingsTableIndicatorClass = (taxCategory) => {
  return `savings-planner__indicator-column--${taxCategory
    .toLocaleLowerCase()
    .replace(" ", "-")
    .replace("_", "-")}`;
};

export const priorityComparator = (accessor) => {
  return (a, b) => {
    const missingInfoSort = sortByMissingInfo(accessor(a), accessor(b));

    if (missingInfoSort) {
      return missingInfoSort;
    }

    const firstPriority = accessor(a).nextDollarPriority.value;
    const nextPriority = accessor(b).nextDollarPriority.value;
    return firstPriority - nextPriority;
  };
};
