import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import { formatCurrency } from "libs/pcap/utils/format";
import { fromNow } from "libs/pcap/utils/fromNow";
import DatePickerInput from "components/common/form/DatePickerInput";
import moment from "moment";
import deepCopy from "deep-copy";
import {
  DISPLAY_FORMAT,
  toApiFormatDateIfValid,
  toDisplayFormatDateIfValid,
} from "libs/pcap/utils/date2";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
export default class MarkAccountAsClosedView extends AbstractForm {
  constructor(props) {
    super(props);

    const model = deepCopy(props.model);
    model.closedDate = moment().format(DISPLAY_FORMAT);

    this.state = { model };

    this.isValidCloseDate = this.isValidCloseDate.bind(this);
    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  isValidCloseDate(currentDate) {
    const createdDate = moment(this.state.model.createdDate).startOf("day");
    const today = moment().endOf("day");
    return (
      currentDate.isSameOrAfter(createdDate) &&
      currentDate.isSameOrBefore(today)
    );
  }

  handleDateInputChange(event, value) {
    const { model } = this.state;
    /* We used to accept account closedDate in API_FORMAT, store and validate it in API_FORMAT
     * and send it up stream to the server. We now accept dates in DISPLAY_FORMAT,
     * but we send it upstream in API_FORMAT. modelClosedDate is
     * initialized with a value in DISPLAY_FORMAT.
     */
    model.closedDate = toDisplayFormatDateIfValid({
      date: value,
      currentFormat: DISPLAY_FORMAT,
    });
    this.setState({ model });
  }

  handleSubmit() {
    const { onMarkAsClosed } = this.props;
    const { model } = this.state;

    const validationResult = this.validate();

    if (validationResult.valid) {
      const apiFormatClosedDate = toApiFormatDateIfValid({
        date: model.closedDate,
        currentFormat: DISPLAY_FORMAT,
      });
      onMarkAsClosed(apiFormatClosedDate);
    }
  }

  render() {
    const { loading, errors, onCancel } = this.props;
    const { model } = this.state;
    let isCreditAccount = model.productType === "CREDIT_CARD";
    let balance = model.balance;
    if (isCreditAccount) {
      balance *= -1;
    }
    return (
      <div>
        <LoadingOverlay active={loading} />
        {model.logoPath && (
          <div className="edit-account__logo-container">
            <img src={model.logoPath} className="u-full-center--abs" />
          </div>
        )}
        <div className="l-spaced l-spaced--flush edit-account__text--info">
          <div className="u-text-bold">{model.firmName}</div>
          <div className="u-text-bold">{formatCurrency(balance)}</div>
        </div>
        <div className="l-spaced l-spaced--flush edit-account__text--info">
          <div>{model.name}</div>
          <div>{fromNow(model.lastRefreshed)}</div>
        </div>
        <div className="pc-u-mv-">
          This stops importing new data for this account, but keeps its historic
          data and transactions. The account balance will be $0 as of the date
          you select and any transactions after that date will be removed. The
          account will be{" "}
          <span className="u-text-bold">permanently hidden</span> unless you
          view a time period before this date.
        </div>
        <div className="pc-u-mv">
          <div className="pc-layout pc-layout--flushed">
            <div className="pc-layout__item pc-u-2/5 pc-u-pt--">
              <label htmlFor="accountClosedDate" className="pc-u-mr--">
                Mark as closed as of
              </label>
            </div>
            <div className="pc-layout__item pc-u-3/5 pc-u-pl0">
              <DatePickerInput
                name="closedDate"
                id="closedDate"
                validator={DatePickerInput.getValidator({
                  allowEmpty: false,
                  isAllowedDate: this.isValidCloseDate,
                })}
                ref={this.storeInputRef}
                isAllowedDate={this.isValidCloseDate}
                displayDateFormat={DISPLAY_FORMAT}
                onChange={this.handleDateInputChange}
                position="top"
                value={model.closedDate}
              />
            </div>
          </div>
        </div>
        <Message className="pc-u-mb" severity="error" messages={errors} />
        <div className="l-spaced l-spaced--right">
          <button
            type="button"
            className="pc-btn js-btn-cancel"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="pc-btn pc-btn--danger js-btn-mark-closed qa-btn-mark-closed"
            onClick={this.handleSubmit}
            disabled={this.props.isPrivileged}
            aria-disabled={this.props.isPrivileged}
          >
            Mark as Closed
          </button>
        </div>
      </div>
    );
  }
}

MarkAccountAsClosedView.propTypes = {
  model: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  onMarkAsClosed: PropTypes.func.isRequired,
  isPrivileged: PropTypes.bool,
};

MarkAccountAsClosedView.defaultProps = {
  loading: false,
  errors: undefined,
  schema: {
    type: "object",
    properties: {
      closedDate: {
        allowEmpty: false,
        type: "string",
      },
    },
  },
  isPrivileged: false,
};
