import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { isArray, isEmpty, noop } from "underscore";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import getAceMessageActionClassNameByType from "libs/pcap/utils/getAceMessageActionClassNameByType";
import { markUserMessageAsClickedBaseOnTrackingFlag } from "libs/pcap/utils/markUserMessageAsClicked";
import markUserMessageAsDismissed from "libs/pcap/utils/markUserMessageAsDismissed";
import markUserMessageAsImpressed from "libs/pcap/utils/markUserMessageAsImpressed";
import markUserMessageAsViewed from "libs/pcap/utils/markUserMessageAsViewed";
import { getUserMessageActionTrackingFlag } from "views/components/postLoginActions/helpers";
import Modal from "components/modal/Modal";

/**
 * When the banner is closed, we mark the message as viewed. After dormant days have passed, ACE will re-send the user message.
 * To create an ACE block banner that disappears on click and re-appears after X days:
 *
 * In the ACE message template:
 * 1) In `Dormant Days` field, add the number of days desired to bring the banner back after being closed
 * 2) In `Summary` field, add a visible, styled close button that calls $(".ace-block__buttons .js-btn-dismiss").click() on click
 * 3) Add a `Close Banner` action button (will be hidden) with `url` field as blank and `type` as `dismiss`
 */

export const DISPLAY_LOCATION__ACE_BLOCK = "#/dashboard/ace-block";
const CLASS_CLOSE_BLOCK = "js-btn-dismiss";

export const btnLabelToKey = (label) => label.replace(/\s/g, "-").toUpperCase();

export default class AceBlock extends React.Component {
  constructor() {
    super(...arguments);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  componentDidMount() {
    $(".ace-block__close").on("click", (event) => {
      event.stopPropagation(); // Prevent containing div from catching the click event
    });
  }

  trackViewEventOnce() {
    if (!this.viewEventTracked) {
      const { userMessage } = this.props;
      markUserMessageAsImpressed(userMessage.userMessageId);
      ComponentAnalytics.trackView(this.props.componentName, {
        template: userMessage && userMessage.template,
      });
      this.viewEventTracked = true;
    }
  }

  onButtonClick(event) {
    const { onRemove, componentName, userMessage } = this.props;
    const button = event.currentTarget;
    const isCloseAceBlockAction =
      button.className && button.className.includes(CLASS_CLOSE_BLOCK);

    if (isCloseAceBlockAction) {
      // Tell ACE to not send this user message down again until after dormant number of days
      markUserMessageAsDismissed(userMessage.userMessageId);
      onRemove();
    } else {
      markUserMessageAsClickedBaseOnTrackingFlag(userMessage, event);
      markUserMessageAsViewed(userMessage.userMessageId);
    }

    ComponentAnalytics.trackButtonClick(event, componentName, null, {
      template: userMessage && userMessage.template,
    });
  }

  getButtonRow() {
    const { userMessage } = this.props;
    let buttonRow = null;

    if (userMessage && isArray(userMessage.action)) {
      const buttons = userMessage.action.map((button) => {
        const isCloseButton =
          (button.label || "").toLowerCase().includes("close") ||
          button.type === "dismiss";
        let buttonComponent = null;
        if (button.label) {
          const buttonKey = button.key || btnLabelToKey(button.label);
          const inlineButtonProps = isCloseButton
            ? {}
            : {
                "data-attribution-source": `${userMessage.template}--${buttonKey}`,
              };
          const buttonUrlIsClose =
            String(button.url).toUpperCase().indexOf("CLOSE") === 0;
          if (button.url && !buttonUrlIsClose) {
            buttonComponent = (
              <a
                {...inlineButtonProps}
                key={buttonKey}
                data-tracking-flag={getUserMessageActionTrackingFlag(button)}
                className={`${getAceMessageActionClassNameByType(
                  button.type
                )} pc-btn--tiny`}
                href={button.url}
                onClick={this.onButtonClick}
              >
                {button.label}
              </a>
            );
          } else {
            buttonComponent = (
              <button
                {...inlineButtonProps}
                type="button"
                key={buttonKey}
                data-tracking-flag={
                  isCloseButton
                    ? false
                    : getUserMessageActionTrackingFlag(button)
                }
                className={`${getAceMessageActionClassNameByType(
                  button.type
                )} pc-btn--tiny`}
                onClick={this.onButtonClick}
              >
                {button.label}
              </button>
            );
          }
        }
        return buttonComponent;
      });
      buttonRow = <div className="ace-block__buttons">{buttons}</div>;
    }

    return buttonRow;
  }

  render() {
    const { userMessage } = this.props;
    if (isEmpty(userMessage)) {
      return null;
    }

    this.trackViewEventOnce();

    const bannerHtml = userMessage && userMessage.summary;
    const buttonRow = this.getButtonRow();

    return (
      <section className="ace-block pc-u-mb-">
        <div
          className="ace-block__content qa-ace-block-content"
          dangerouslySetInnerHTML={{ __html: bannerHtml }}
        />
        {buttonRow}
      </section>
    );
  }
}

AceBlock.propTypes = {
  // Component name to use when tracking analytics events
  componentName: PropTypes.string,
  // A user message object from getUserMessages API
  userMessage: PropTypes.object,
  // Callback to close/remove the ACE block
  onRemove: PropTypes.func.isRequired,
  onMarkAsViewed: PropTypes.func.isRequired,
};

AceBlock.defaultProps = {
  componentName: "ACE Block",
  userMessage: {},
};

export function isAceBlockUserMessage(userMessage) {
  return Boolean(
    userMessage &&
      userMessage.displayLocations &&
      userMessage.displayLocations.includes(DISPLAY_LOCATION__ACE_BLOCK)
  );
}

export function renderAceBlockToNode(userMessage, mountNode) {
  if (!mountNode) {
    throw Error("`mountNode` is required");
  }
  ReactDOM.render(
    React.createElement(AceBlock, {
      userMessage,
      onMarkAsViewed: noop,
      onRemove: noop,
    }),
    mountNode
  );
}

export function ModalWithAceBlock({ userMessage, onClose }) {
  return (
    <Modal
      componentName=""
      className="pc-modal--huge pc-modal--bare"
      onClosed={null}
      // react-modal APIs
      contentLabel=""
      isOpen={true}
    >
      <AceBlock
        userMessage={userMessage}
        onMarkAsViewed={noop}
        onRemove={onClose}
      />
      <div className="u-text-center">
        <button className="pc-btn pc-btn--small" onClick={onClose}>
          Close
        </button>
      </div>
    </Modal>
  );
}

ModalWithAceBlock.propTypes = {
  userMessage: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export function renderAceBlockToModal(userMessage) {
  const mountNode = document.createElement("div");
  document.body.appendChild(mountNode);
  const onClose = () => {
    ReactDOM.unmountComponentAtNode(mountNode);
    mountNode.parentNode.removeChild(mountNode);
  };
  ReactDOM.render(
    React.createElement(ModalWithAceBlock, { userMessage, onClose }),
    mountNode
  );
  return null;
}
