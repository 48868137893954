var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"apr") : depth0), depth0))
    + "%";
},"3":function(container,depth0,helpers,partials,data) {
    return "-";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"nav-secondary js-secondary-nav\">\n  <div class=\"l-spaced l-spaced--flush pc-u-mb-\">\n    <div class=\"pc-layout pc-layout--auto pc-layout--flush pc-layout--middle\">\n      <div class=\"pc-layout__item js-closed-text\">\n        <h1\n          class=\"u-preserve-case nav-secondary__title qa-page-title js-account-details-firm-name \"\n          title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"firmName") : depth0), depth0))
    + "\"\n          data-hj-masked\n        >\n          "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cappedFirmName") : depth0), depth0))
    + "\n        </h1>\n      </div>\n      <div class=\"pc-layout__item\">\n        <button\n          type=\"button\"\n          class=\"pc-btn pc-btn--tiny pc-btn--stripped js-edit-account-button\"\n          title=\"Edit account\"\n          aria-label=\"Edit account\"\n        >\n          <svg class=\"account-details-edit-account-icon\" viewBox=\"0 0 16 16\">\n            <use xlink:href=\"#icon-pencil\"></use>\n          </svg>\n        </button>\n      </div>\n    </div>\n  </div>\n  <div class=\"l-spaced l-spaced--flush\">\n    <div\n      class=\"account-details-account-name js-account-details-account-name js-closed-text\"\n      title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n      data-hj-masked\n    >\n      "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cappedName") : depth0), depth0))
    + "\n    </div>\n    <div class=\"account-details-info\">\n      <span class=\"js-closed-text\">Balance</span\n      ><span\n        class=\"account-details-info__value js-credit-account-balance js-closed-text\"\n        >"
    + alias2(__default(require("../helpers/dollarAndCentsAmount.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"balance") : depth0),true,true,(depth0 != null ? lookupProperty(depth0,"isLiability") : depth0),{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":39,"column":9},"end":{"line":39,"column":63}}}))
    + "</span\n      >\n    </div>\n  </div>\n</div>\n<div class=\"nav-secondary nav-secondary--feature-controls\">\n  <div class=\"l-spaced l-spaced--flush\">\n    <div class=\"pc-layout pc-layout--auto centi\">\n      <div class=\"account-details-info pc-layout__item js-available-balance qa-available-balance\">\n        Available Credit<span\n          class=\"account-details-info__value js-credit-available-credit\"\n          >"
    + alias2(__default(require("../helpers/dollarAndCentsAmount.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"availableCredit") : depth0),false,true,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":50,"column":11},"end":{"line":50,"column":62}}}))
    + "</span\n        >\n      </div>\n      <div class=\"account-details-info pc-layout__item js-closed-date qa-closed-date is-hidden\">\n      </div>\n      <div class=\"account-details-info pc-layout__item js-closed-text--hide\">\n        APR<span\n          class=\"account-details-info__value js-credit-account-details-apr\"\n          >"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"apr") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":58,"column":11},"end":{"line":58,"column":46}}})) != null ? stack1 : "")
    + "</span\n        >\n      </div>\n    </div>\n    <div class=\"pc-layout pc-layout--auto pc-layout--small\">\n      <div class=\"pc-layout__item\">\n        <div class=\"dropdown\">\n          <button\n            type=\"button\"\n            class=\"pc-btn pc-btn--tiny js-account-details-bill js-account-details-bill--no-due is-hidden\"\n            data-toggle=\"pc-dropdown\"\n            disabled\n          >\n            No Bill Due\n          </button>\n          <button\n            type=\"button\"\n            class=\"pc-btn pc-btn--tiny js-account-details-bill js-account-details-bill--due is-hidden\"\n            data-toggle=\"pc-dropdown\"\n          >\n            <svg\n              class=\"account-details-bill-icon account-details-bill-icon--unpaid\"\n              viewBox=\"0 0 24 24\"\n            >\n              <use xlink:href=\"#pc-icon__alarm\"></use>\n            </svg>\n            Bill Due\n          </button>\n          <button\n            type=\"button\"\n            class=\"pc-btn pc-btn--tiny js-account-details-bill js-account-details-bill--paid is-hidden\"\n            data-toggle=\"pc-dropdown\"\n          >\n            <svg\n              class=\"account-details-bill-icon account-details-bill-icon--paid\"\n              viewBox=\"0 0 24 24\"\n            >\n              <use xlink:href=\"#pc-icon__done\"></use>\n            </svg>\n            Bill Paid\n          </button>\n          <div\n            class=\"account-details-bill-form menu menu--bordered menu--right js-account-details-bill-reminder-container\"\n          ></div>\n        </div>\n      </div>\n"
    + ((stack1 = container.invokePartial(require("../partials/refetchTransactions.html"),depth0,{"name":"templates/partials/refetchTransactions","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <div class=\"pc-layout__item\">\n        <div class=\"dateSelector\"></div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class=\"frame\">\n  <div class=\"visualization offset\">\n    <div id=\"balanceChart\" class=\"chart qa-balance-chart\">\n      <div id=\"balanceSVG\" class=\"raphaelChart qa-balance-svg\"></div>\n      <div class=\"balance legend\">\n        <table>\n          <tr>\n            <td class=\"balance\">\n              <label></label>\n              <span></span>\n            </td>\n            <td class=\"change\">\n              <label>1-Day Change</label>\n              <span></span>\n            </td>\n          </tr>\n        </table>\n      </div>\n      <div class=\"startDate\"></div>\n      <div class=\"endDate\"></div>\n      <div class=\"noData js-no-balance-data\">\n        <table>\n          <tr>\n            <td>\n              <p class=\"u-text-bold pc-u-mb0\">\n                No balance changes to display<span\n                  class=\"js-account-details-no-data-date-range\"\n                ></span>\n              </p>\n              <p>\n                Select a different time frame to see how your balance changes\n                over time\n              </p>\n            </td>\n          </tr>\n        </table>\n      </div>\n      <div\n        class=\"noData js-zero-data-for-balance-chart qa-zero-data-balance-chart\"\n      >\n        <table>\n          <tr>\n            <td>\n              <svg class=\"account-details__zero-data-balance-chart-icon\">\n                <use xlink:href=\"#pc-icon__sun\" />\n              </svg>\n              <p class=\"u-text-bold pc-u-mb0\">We only have today’s balance.</p>\n              <p>Return tomorrow to see how your balance changes over time.</p>\n            </td>\n          </tr>\n        </table>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});