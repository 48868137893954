import React from "react";
import PropTypes from "prop-types";
import MicroDepositInitiate from "components/transferFunds/MicroDepositInitiate/MicroDepositInitiate";
import Services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import LoadingOverlay from "components/common/LoadingOverlay";
import { trackEvent } from "components/common/ComponentAnalytics";
import { getSource } from "components/common/attributionStore";
import { noop } from "underscore";

export default class MicroDepositInitiateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errors: props.errors,
    };
    this.handleOnConfirm = this.handleOnConfirm.bind(this);
  }

  componentDidMount() {
    this.props.onMounted("Bank Verification");
  }

  handleOnConfirm(model, callback) {
    this.setState({ loading: true });
    const source = getSource();
    trackEvent("Transfer Funds", "Micro Deposits Initiated", {
      // eslint-disable-next-line camelcase
      fi_name: this.props.firmName,
      source,
    });
    Services.PCBMicroDeposit.initiate(
      Object.assign({}, model, { source }),
      (err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          this.setState({
            errors,
            loading: false,
          });
          return;
        }
        this.setState(
          {
            loading: false,
          },
          () => {
            callback();
          }
        );
      }
    );
  }

  render() {
    const {
      isIAVFailed,
      userAccountId,
      logoPath,
      onCancel,
      onDone,
      onBackToDashboard,
      accountName,
      firmName,
    } = this.props;
    const { loading, errors } = this.state;
    const model = {
      userAccountId,
    };
    return (
      <>
        <LoadingOverlay active={loading} />
        <MicroDepositInitiate
          model={model}
          accountName={accountName}
          firmName={firmName}
          logoPath={logoPath}
          errors={errors}
          onConfirm={this.handleOnConfirm}
          onCancel={onCancel}
          onBackToDashboard={onBackToDashboard}
          onDone={onDone}
          isIAVFailed={isIAVFailed}
        />
      </>
    );
  }
}

MicroDepositInitiateContainer.propTypes = {
  userAccountId: PropTypes.number.isRequired,
  logoPath: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onBackToDashboard: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  isIAVFailed: PropTypes.bool,
  firmName: PropTypes.string,
  accountName: PropTypes.string,
  errors: PropTypes.array,
  onMounted: PropTypes.func,
};

MicroDepositInitiateContainer.defaultProps = {
  logoPath: "",
  isIAVFailed: false,
  firmName: undefined,
  accountName: undefined,
  errors: undefined,
  onMounted: noop,
};
