/* eslint-disable no-magic-numbers */

/**
 * Turn a given number into its nth name (e.g. 1 => 1st, 22 => 22nd, 99 => 99th)
 * https://ecommerce.shopify.com/c/ecommerce-design/t/ordinal-number-in-javascript-1st-2nd-3rd-4th-29259
 *
 * @param  {Number} n      The value to format.
 * @return {String}        the formatted string.
 */
export default function getOrdinal(n) {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
