import { roundDecimal, floorDecimal } from "libs/pcap/utils/decimalAdjust";

/*-----------------*\
 Format
 \*-----------------*/

const PRECISION = 2;
const REGEX_GROUPING = /\B(?=(\d{3})+(?!\d))/g;
const REGEX_TRAILING_ZEROES = /0+$/;

/**
 * Formats the number to human notation "," for thousands and "." for decimals
 *
 * @param {Number} value      The absolute value to format.
 * @param {Number} precision  Decimal precision. The default is 2.
 *                            NOTE: values are floored
 *                            https://personalcapital.jira.com/wiki/display/PFA/Numerical+Display+Rules
 * @param {Bool} withTrailingZeros  True by default, will include all the trailing zeroes returned by toFixed.
 *                                  If false it will remove the trailing zeroes.
 *                                  I.e. when formatNumber(0.0010, 6, true) will return '0.001000'
 *                                  I.e. when formatNumber(0.0010, 6, false) will return '0.001'
 * @return {String}           the formatted string.
 */
function internalFormatNumber(
  value,
  precision = PRECISION,
  withTrailingZeros = true
) {
  value = floorDecimal(value, precision);
  value = Math.abs(value).toFixed(precision);
  var [intPart, decimal] = value.split(".");

  var formatedIntPart = intPart.replace(REGEX_GROUPING, ",");

  if (!withTrailingZeros && decimal) {
    decimal = decimal.replace(REGEX_TRAILING_ZEROES, "");
  }

  return decimal ? formatedIntPart + "." + decimal : formatedIntPart;
}

/**
 * Formats the number to currency.
 *
 * @param {Number} value      The value to format.
 * @param {Number} precision  Decimal precision. The default is 2.
 *                            NOTE: values are floored
 *                            https://personalcapital.jira.com/wiki/display/PFA/Numerical+Display+Rules
 * @param {Bool} withTrailingZeros  True by default, will include all the trailing zeroes returned by toFixed.
 *                                  If false it will remove the trailing zeroes.
 *                                  I.e. when formatNumber(0.0010, 6, true) will return '0.001000'
 *                                  I.e. when formatNumber(0.0010, 6, false) will return '0.001'
 * @return {String}           the formatted string.
 */
export function formatNumber(value, precision, withTrailingZeros) {
  if (isNaN(value) || value === null) {
    return NaN;
  }
  var formattedValue = internalFormatNumber(
    value,
    precision,
    withTrailingZeros
  );
  var sign = value < 0 ? "-" : "";

  return sign + formattedValue;
}

/**
 * Formats the number to percent format.
 *
 * @param {Number} value      The value to format.
 * @param {Object} options    options.precision - Decimal precision. The default is 2.
 *                            options.showSymbol - boolean to show `%` or not. Default is true
 *                            NOTE: values are rounded
 *                            https://personalcapital.jira.com/wiki/display/PFA/Numerical+Display+Rules
 * @return {String}           the formatted string.
 */
export function formatPercent(value, options) {
  let formatOptions = { precision: PRECISION, showSymbol: true };
  Object.assign(formatOptions, options);
  var symbol = formatOptions.showSymbol ? "%" : "";
  if (isNaN(value) || value === null) {
    return `-- ${symbol}`;
  }
  value = roundDecimal(value, formatOptions.precision);
  var sign = value < 0 ? "-" : "";
  value = Math.abs(value).toFixed(formatOptions.precision);
  return sign + String(value).replace(REGEX_GROUPING, ",") + symbol;
}

/**
 * Formats the number to US Currency.
 *
 * @param {Number} value      The value to format.
 * @param {Number} precision  Decimal precision. The default is 2.
 *                            NOTE: values are floored
 *                            https://personalcapital.jira.com/wiki/display/PFA/Numerical+Display+Rules
 * @param {Bool} withTrailingZeros  True by default, will include all the trailing zeroes returned by toFixed.
 *                                  If false it will remove the trailing zeroes.
 *                                  I.e. when formatCurrency(0.0010, 6, true) will return '$0.001000'
 *                                  I.e. when formatCurrency(0.0010, 6, false) will return '$0.001'
 * @return {String}           the formatted string.
 */
export function formatCurrency(value, precision, withTrailingZeros) {
  if (isNaN(value) || value === null) {
    return "$ --";
  }
  var formattedValue = internalFormatNumber(
    value,
    precision,
    withTrailingZeros
  );
  var sign = value < 0 ? "-" : "";

  return sign + "$" + formattedValue;
}

/**
 * Format number to a negative if needed based on arguments
 *
 * @param {Number} value
 */
export function formatNegativeNumber(value, precision, withTrailingZeros) {
  if (isNaN(value) || value === null) {
    return "$ --";
  }

  var formattedValue = internalFormatNumber(
    value,
    precision,
    withTrailingZeros
  );

  return "-" + formattedValue;
}
