import React, { useState } from "react";
import PropTypes from "prop-types";
import WidgetRowZeroState from "components/dashboard/WidgetRowZeroState";
import BudgetingView from "../BudgetingView/BudgetingView";
import BudgetingMockData from "./BudgetingMockData";

export default function BudgetingExperienceZeroState({
  zeroStateMessage,
  componentName,
  title,
  sectionClassName,
}) {
  const [mockData] = useState(BudgetingMockData);

  if (IS_EMPOWER) {
    document.querySelector("#moduleContainer").dispatchEvent(
      new CustomEvent("zeroState", {
        detail: { page: "budgeting", close: false },
      })
    );

    return (
      <div className="moduleFrame">
        {mockData && <BudgetingView {...mockData} />}
      </div>
    );
  }

  return (
    <section className={sectionClassName}>
      <div className="moduleFrame">
        <div className="nav-secondary">
          <h1 className="nav-secondary__title qa-page-title">{title}</h1>
        </div>
        <div className="pc-layout pc-layout--center">
          <div className="pc-layout__item pc-u-1/2">
            <div
              className="dashboard-second-row__zero-state"
              role="img"
              aria-label="Sample budgeting chart"
            />
          </div>
          <div className="pc-layout__item pc-u-1/2">
            <WidgetRowZeroState
              message={zeroStateMessage}
              widgetName={componentName}
              sendInfoEvent={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

BudgetingExperienceZeroState.propTypes = {
  zeroStateMessage: PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    action: PropTypes.array,
  }),
  componentName: PropTypes.string,
  title: PropTypes.string,
  sectionClassName: PropTypes.string,
};

BudgetingExperienceZeroState.defaultProps = {
  zeroStateMessage: {
    title: "Link a bank or credit card account to get started",
    summary:
      "Track spending, set a monthly budget, compare monthly spending and view top spending categories.",
    action: [{ title: "Link account", url: "#/accounts/add" }],
  },
  componentName: "Budgeting",
  title: "Budgeting",
  sectionClassName: "budgeting__view",
};
