import PropTypes from "prop-types";
import React from "react";
import ConfirmModal from "common/ConfirmModal/ConfirmModal";

const EmpowerWithdrawManagedModal = (props) => {
  const { onCancel, onConfirm, onClosed } = props;

  return (
    <ConfirmModal
      title={
        "You are requesting to withdraw 90% or more of your available balance."
      }
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelLabel={"Cancel"}
      confirmLabel={"Close account"}
      isOpen={true}
      showCloseButton={true}
      onClosed={onClosed}
    >
      <ul className="empower-withdraw-managed-modal--list">
        <li className="empower-withdraw-managed-modal--list-item">
          If you want to withdraw this amount and keep the account open, please
          call us at 877-788-6261 to process this request. We are available
          weekdays 7 a.m. to 7 p.m. Central time.
        </li>
        <li className="empower-withdraw-managed-modal--list-item">
          If you want to close the account, click the button below and we will
          close your account.
        </li>
      </ul>
      <div className="empower-withdraw-managed-modal--reminder">
        By clicking <em>Close account</em>, I understand the terms and
        conditions. Any remaining funds will be sent to my address on file.
      </div>
    </ConfirmModal>
  );
};

EmpowerWithdrawManagedModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
};

export default EmpowerWithdrawManagedModal;
