import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import FieldSet from "components/common/FieldSet";
import Input from "components/common/form/Input";

const isModelEmpty = (model) => {
  return (
    Object.values(model).length === 0 ||
    (Object.values(model).length === 1 && Object.values(model)[0] === undefined)
  );
};

export default class SurveyView extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
      isDisabled: true,
      customInputValue: props.customInputValue,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  handleSubmit() {
    this.props.onSubmit(this.state.model, this.state.customInputValue);
    this.setState({ isDisabled: true });
  }

  handleFieldChange() {
    this.handleInputChange.apply(this, arguments);

    if (isModelEmpty(this.state.model)) {
      this.setState({ isDisabled: true });
    } else {
      this.setState({ isDisabled: false });
    }
  }

  onInputChange(event) {
    const value = event.target.value;
    this.props.handleCustomInputFieldChange(
      value,
      this.props.customInputField.value
    );

    if (value !== "") this.setState({ isDisabled: false });

    if (value === "" && isModelEmpty(this.state.model))
      this.setState({ isDisabled: true });
  }

  render() {
    const {
      imageSource,
      fields,
      title,
      summary,
      buttonClassName,
      userResponseOptionClassName,
      customInputField,
    } = this.props;
    const bodyQuestionClassName = imageSource ? "pc-u-4/7" : "";

    // Need to remove validation since custom field is not part of FieldSet
    if (customInputField) {
      const part = fields[0].parts[0];
      part.validator.allowEmpty = true;
      part.validator.required = false;
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="pc-advice-notification__title-container">
          <h1
            className="pc-advice-notification__title qa-pla-title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
        {Boolean(summary) && (
          <summary
            className="pc-u-mt pc-u-mb-"
            dangerouslySetInnerHTML={{ __html: summary }}
          ></summary>
        )}
        <section className="pc-layout pc-u-mt-">
          <FieldSet
            fieldsConfig={fields}
            model={this.state.model}
            refFunc={this.storeInputRef}
            onChange={this.handleFieldChange}
            shallowModelPropertyPath={true}
            className={`pc-layout__item ${bodyQuestionClassName} ${userResponseOptionClassName} user-response-survey__body-question`}
          />
          {customInputField && (
            <div className="user-response__options--custom_input_container">
              <label htmlFor={"customInput"} className="pc-label">
                {customInputField.label}
              </label>
              <div className="user-response__options--custom_input">
                <Input
                  id="customInput"
                  className="input--tiny qa-firm-name"
                  type="text"
                  name={customInputField.label}
                  sizeVariation="full"
                  placeholder={customInputField.placeholder}
                  onChange={this.onInputChange}
                  value={this.state.customInputValue}
                  ref={this.storeInputRef}
                  maxLength={1000}
                />
              </div>
            </div>
          )}
          {Boolean(imageSource) && (
            <img
              src={imageSource}
              alt=""
              className="user-response-survey__body-image pc-layout__item  pc-u-3/7"
            />
          )}
        </section>
        <div className={`${buttonClassName} pc-u-mb--`}>
          <button
            type="button"
            className={`pc-btn pc-btn--primary`}
            disabled={this.state.isDisabled}
            onClick={this.handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}

SurveyView.propTypes = {
  componentName: PropTypes.string,
  fields: PropTypes.array.isRequired,
  imageSource: PropTypes.string,
  model: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  plaDetails: PropTypes.object,
  summary: PropTypes.string,
  title: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string,
  userResponseOptionClassName: PropTypes.string,
  customInputField: PropTypes.object,
};

SurveyView.defaultProps = {
  componentName: "",
  imageSource: "",
  plaDetails: {},
  summary: "",
  buttonClassName: "u-text-center",
  userResponseOptionClassName: "",
  customInputField: undefined,
};
