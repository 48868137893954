export const ENROLLMENT_CONSENTS = [
  "PRIVACY_POLICY",
  "PROGRAM_AGREEMENT",
  "ELECTRONIC_RECORDS",
];

export const ADDITIONAL_CONSENT = "ADDITIONAL_CONSENT";

export function isFullyConsented(userConsentedTo, isEmpower = false) {
  return (
    userConsentedTo &&
    userConsentedTo.includes(ENROLLMENT_CONSENTS[0]) &&
    userConsentedTo.includes(ENROLLMENT_CONSENTS[1]) &&
    userConsentedTo.includes(ENROLLMENT_CONSENTS[2]) &&
    (isEmpower ? userConsentedTo.includes(ADDITIONAL_CONSENT) : true)
  );
}
