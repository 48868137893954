import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import Owners from "./Owners";
import Beneficiaries from "./Beneficiaries";
import { isEqual } from "underscore";
import Message from "components/common/Message";

export default class EditStakeholdersView extends AbstractForm {
  constructor(props) {
    super(props);

    this.state = {
      model: { stakeholders: this.props.stakeholders },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(ev) {
    ev.preventDefault();
    if (this.props.isPrivileged) {
      return;
    }
    const validateResult = this.validate();

    if (validateResult.valid) {
      this.props.onSubmit(this.state.model.stakeholders);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(prevState.model.stakeholders, nextProps.stakeholders)) {
      return { model: { stakeholders: nextProps.stakeholders } };
    }

    return null;
  }

  render() {
    const {
      stakeholdersType,
      people,
      onAddPersonClick,
      onCancel,
      errors,
      isPrivileged,
    } = this.props;
    const { model } = this.state;

    if (errors) {
      return <Message severity="error" messages={errors} />;
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={"pc-u-pb"}>
          {stakeholdersType === "owners" && (
            <Owners
              name="stakeholders"
              ref={this.storeInputRef}
              value={model.stakeholders}
              people={people}
              onChange={this.handleInputChange}
              onAddPersonClick={onAddPersonClick}
              validator={this.props.schema.properties.stakeholders.items}
            />
          )}

          {stakeholdersType === "beneficiaries" && (
            <Beneficiaries
              name="stakeholders"
              ref={this.storeInputRef}
              value={model.stakeholders}
              people={people}
              onChange={this.handleInputChange}
              onAddPersonClick={onAddPersonClick}
              validator={this.props.schema.properties.stakeholders.items}
            />
          )}
        </div>
        <div
          className={`l-spaced l-spaced--right pc-modal__footer edit-account-footer${
            IS_EMPOWER ? " pc-u-mb--" : ""
          }`}
        >
          <button
            type="button"
            className="pc-btn js-btn-cancel"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`pc-btn pc-btn--primary ${
              isPrivileged ? "is-disabled" : ""
            }`}
            aria-disabled={isPrivileged}
          >
            Save
          </button>
        </div>
      </form>
    );
  }
}

EditStakeholdersView.propTypes = {
  stakeholdersType: PropTypes.oneOf(["owners", "beneficiaries"]).isRequired,
  stakeholders: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddPersonClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.array,
  isPrivileged: PropTypes.bool,
};

EditStakeholdersView.defaultProps = {
  errors: undefined,
  schema: {
    properties: {
      stakeholders: {
        type: "array",
        items: {
          type: "object",
          properties: {
            personId: {
              type: "number",
              required: true,
              allowEmpty: false,
              messages: {
                type: "required",
                required: "required",
                allowEmpty: "required",
              },
            },
            percentage: {
              type: "number",
              required: true,
              allowEmpty: false,
              exclusiveMinimum: 0,
              maximum: 100,
              divisibleBy: 1,

              messages: {
                type: "numbers only",
                required: "required",
                allowEmpty: "required",
                exclusiveMinimum: "1-100 only",
                maximum: "1-100 only",
                divisibleBy: "round numbers only",
              },
            },
          },
        },
      },
    },
  },
  isPrivileged: false,
};
