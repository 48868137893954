import React from "react";
import PropTypes from "prop-types";
import Table from "libs/pcap/table/tableClient/TableClient";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import { formatCurrency } from "libs/pcap/utils/format";
import {
  getAccountAprInfo,
  getDebtProductType,
  sortByMissingInfo,
} from "components/savingsPlanner/SavingsPlannerUtils";
import { noop } from "underscore";

const MAXIMUM_APR = 100;
const CREDIT_CARD = "CREDIT_CARD";
const CREDIT_CARD_TOOLTIP =
  "Credit Cards with at least 2 interest charges over the past 90 days are included here.";

// Sort accounts with missing APR highest, then sort by APR
function getAprSortOrder({ interestRate }) {
  return interestRate || MAXIMUM_APR + 1;
}

function debtPaydownComparator(accessor) {
  return (a, b) => {
    const missingInfoSort = sortByMissingInfo(accessor(a), accessor(b));

    if (missingInfoSort) {
      return missingInfoSort;
    }

    const aAprSort = getAprSortOrder(accessor(a));
    const bAprSort = getAprSortOrder(accessor(b));

    if (aAprSort < bAprSort) {
      return 1;
    }

    if (aAprSort > bAprSort) {
      return -1;
    }

    let aFirm = accessor(a).firmName.toUpperCase();
    let bFirm = accessor(b).firmName.toUpperCase();

    return aFirm.localeCompare(bFirm);
  };
}

export default class DebtPaydownTable extends React.Component {
  constructor(props) {
    super(props);

    this.handleInterestGA = this.handleInterestGA.bind(this);
    this.columns = [
      {
        header: "Account",
        className:
          "table__column--clipped table__column--debt-paydown-account-name",
        accessor: (account) => account,
        sortOrder: "desc",
        comparator: debtPaydownComparator,
        formatter: (account) => {
          return (
            <>
              <div className="savings-planner-table__firm-name">
                {account.firmName}
              </div>
              <div className="savings-planner-table__row-detail">
                {account.accountName}
              </div>
            </>
          );
        },
        summaryAccessor: () => "All Debt",
      },
      {
        header: "Type",
        className:
          "table__column--clipped table__column--debt-paydown-product-type",
        accessor: (account) => account,
        formatter: (account) => {
          return (
            <div>
              {getDebtProductType(account)}
              {account.productType === CREDIT_CARD && (
                <InfoTooltipIcon
                  className="pc-u-ml--"
                  title={CREDIT_CARD_TOOLTIP}
                />
              )}
            </div>
          );
        },
        summaryAccessor: () => "",
      },
      {
        header: "Interest Rate",
        className:
          "table__column--clipped table__column--right table__column--debt-paydown-apr",
        accessor: (account) => account,

        formatter: (account) => (
          <div
            className="savings-planner-table__editable-cell"
            role="button"
            tabIndex="0"
            aria-label={`Interrest rate is ${
              getAccountAprInfo(account, window.userHasOnusAccounts).props
                ?.children
            } for ${account.firmName} ${account.accountName} - Edit account`}
            onClick={() => {
              this.handleInterestGA(account);
            }}
            onKeyDown={(e) => {
              if (["Enter", " "].includes(e.key)) {
                e.preventDefault();
                this.handleInterestGA(account);
              }
            }}
          >
            <div className={"savings-planner-table__account-type"}>
              {getAccountAprInfo(account, window.userHasOnusAccounts)}
            </div>
          </div>
        ),
        summaryAccessor: () => "",
      },
      {
        header: "Balance Change YTD",
        className:
          "table__column--clipped table__column--right table__column--debt-paydown-balance-change-ytd",
        accessor: (account) => account.balanceChange,
        formatter: (amount) => {
          return amount == null ? "- " : formatCurrency(amount, 2);
        },
        summaryAccessor: (data) =>
          data.reduce((total, row) => total + row.balanceChange, 0),
        summaryFormatter: (totalBalanceChange) =>
          formatCurrency(totalBalanceChange, 2),
      },
      {
        header: "Balance",
        className:
          "table__column--clipped table__column--right table__column--debt-paydown-balance",
        accessor: (account) => account.balance,
        formatter: (amount) => {
          return amount == null ? "- " : formatCurrency(amount, 2);
        },
        summaryAccessor: (data) =>
          data.reduce((total, row) => total + row.balance, 0),
        summaryFormatter: (totalBalance) => formatCurrency(totalBalance, 2),
      },
    ];
  }

  handleInterestGA(account) {
    window.dashboardUtils?.eventBus.dispatch(
      `debt_paydown_page.interest_rate_link.click`
    );
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type:
        window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_LINK,
      event_properties: {
        selection: "debt_paydown_page.interest_rate_link.click",
      },
    });
    this.props.onEditAccountClick(account.userAccountId);
  }

  render() {
    return (
      <Table
        columns={this.columns}
        data={this.props.accounts}
        className="table--primary centi debt-paydown__accounts-table tabular-numbers qa-debt-paydown-accounts-table"
        summaryRowPosition="TOP"
        summaryRowClassName="table__row--summary--underlined"
        headerRowClassName="savings-planner__table-header-row"
        rowClassName={(account) =>
          account.closedDate ? "table__row--closed-account" : ""
        }
        paginator={null}
      />
    );
  }
}

DebtPaydownTable.propTypes = {
  accounts: PropTypes.array,
  onEditAccountClick: PropTypes.func,
};

DebtPaydownTable.defaultProps = {
  accounts: [],
  onEditAccountClick: noop,
};
