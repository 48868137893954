export const allowedProductTypes = [
  "BANK",
  "INVESTMENT",
  "CREDIT_CARD",
  "LOAN",
  "MORTGAGE",
  "MANUAL",
  "SITE",
];
export const allowedSubProductTypes = ["RETIREMENT"];
export const manualProductTypes = [
  "HOME",
  "CAR",
  "JEWELRY",
  "ART",
  "CUSTOM-STOCK-OPTIONS",
  "MANUAL-INVESTMENT-HOLDINGS",
  "CRYPTOCURRENCY-BETA",
  "MANUAL-BANK",
  "MANUAL-CREDIT-CARD",
  "MANUAL-LOAN",
  "MANUAL-MORTGAGE",
  "OTHER-ASSET",
  "OTHER-LIABILITY",
];

const REGEX_CREDIT_DASH_CARD = /credit-card/gi;
const SERVER_CREDIT_CARD = "credit_card";

export const transformPath = (path) => {
  path = (path || "")
    .replace(REGEX_CREDIT_DASH_CARD, SERVER_CREDIT_CARD)
    .toUpperCase();
  // Re-route all #/accounts/add/[manual-product-type] traffic to #/accounts/add/manual/[manual-product-type]
  if (manualProductTypes.includes(path)) {
    path = `MANUAL/${path}`;
  }
  return path;
};

export const getProductTypeArray = (path) => {
  const origProductTypes = (path || "").toUpperCase().split(" ");
  const productTypeArray = [];
  origProductTypes.forEach((productType) => {
    if (allowedProductTypes.includes(productType)) {
      productTypeArray.push(productType);
    }
  });
  return productTypeArray;
};

export default {
  allowedProductTypes,
  allowedSubProductTypes,
  manualProductTypes,
  transformPath,
  getProductTypeArray,
};
