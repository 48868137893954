import _ from "underscore";
var increments = [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 1.2, 1.05, 2];

export default function (chartMin, chartMax, targetDivisor) {
  var yRange = chartMax - chartMin;

  var figureCount = Math.round(yRange).toString().length;
  var multiplier = Math.pow(10, figureCount);

  if (!targetDivisor) targetDivisor = 5;
  var delta = 1000;

  var closestIndex = _.reduce(
    increments,
    function (closest, value, i) {
      value = yRange / (value * multiplier);

      if (Math.abs(targetDivisor - value) < delta) {
        delta = Math.abs(targetDivisor - value);
        return i;
      }
      return closest;
    },
    0
  );

  var increment = increments[closestIndex] * multiplier;

  var zeroMin = increment * Math.floor(chartMin / increment);
  var zeroMax = increment * Math.ceil(chartMax / increment);
  var steps = Math.ceil(yRange / increment);
  var rangeMax = increment * steps + chartMin;

  return {
    initial: {
      min: chartMin,
      max: chartMax,
      divisor: targetDivisor,
    },
    increment: increment,
    zeroBasedExtents: {
      min: zeroMin,
      max: zeroMax,
      increments: _.uniq(
        _.range(zeroMin, zeroMax, increment).concat([zeroMax])
      ),
    },
    rangeBasedExtents: {
      min: chartMin,
      max: rangeMax,
      increments: _.uniq(
        _.range(chartMin, rangeMax, increment).concat([rangeMax])
      ),
    },
  };
}
