var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"chart-legend chart-legend--box chart-legend--cash-flow\">\n  <li class=\"chart-legend__item chart-legend__item--cash-flow-income\">Income</li>\n  <li class=\"chart-legend__item chart-legend__item--cash-flow-spending\">Expense</li>\n</ul>\n<svg class=\"svg-chart svg-chart--cash-flow\" aria-label=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"graphAltText") || (depth0 != null ? lookupProperty(depth0,"graphAltText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"graphAltText","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":5,"column":72}}}) : helper)))
    + "\"></svg>\n";
},"useData":true});