import { get } from "object-path";
import Services from "services";
import { isEmpty } from "underscore";
import { promisify } from "utils/service";

/**
 * Matches joint account types.
 * @type {RegExp}
 */
const REGX_JOINT = /JOINT/;

const EMPLOYER_SPONSORED_RETIREMENT_ACCOUNT_TYPES = [
  "401K",
  "401A",
  "403B",
  "457",
  "OTHER_PENSION_PLAN",
];

const IS_CURRENT_EMPLOYER_NO = "N";

/**
 * Checks if the account can be used for transfer funds.
 * Uses `getAccountStateForTransfer` API response.
 *
 * @param   {Object}  account the account
 * @returns {Boolean}         the boolean flag
 */
export function isTransferEligible({ state }) {
  return state !== "BLOCKED";
}

/**
 * Checks if the account is joint based on the provided ownership type.
 *
 * @param   {String}  ownershipType the ownership type
 * @returns {Boolean}               the boolean flag
 */
export function isJointAccount(ownershipType) {
  return REGX_JOINT.test(ownershipType);
}

/**
 * Verifies whether we need to specify owner percentage on the
 * specified account.
 *
 * @param  {String} accountGroup    The account type group.
 * @param  {String} jointAccountType The type of joint account type, if applicable
 * @return {Boolean}                `true` if ownership shares are supported on the account.
 */
export function isOwnerShareSupported(accountGroup, jointAccountType) {
  return (
    accountGroup === "BUSINESS" || jointAccountType === "TENANTS_IN_COMMON"
  );
}

/**
 *
 * @param {String} accountTypeGroup the account type group
 * @param {String} ownershipType    the ownership type
 * @returns {Boolean}               the boolean flag
 */
export function isTrust(accountTypeGroup, ownershipType) {
  return Boolean(
    (accountTypeGroup && accountTypeGroup.toLowerCase() === "trust") ||
      (ownershipType && ownershipType.toLowerCase() === "trust")
  );
}

/**
 * Checks if the account is an employer sponsored retirement account.
 *
 * @param   {String}  accountType the account type
 * @param   {String}  subType the account subtype
 * @returns {Boolean}             the boolean flag
 */
export function isEmployerSponsoredRetirementAccount(accountType, subType) {
  return (
    EMPLOYER_SPONSORED_RETIREMENT_ACCOUNT_TYPES.indexOf(accountType) !== -1 ||
    (accountType === "IRA" && (subType === "SEP" || subType === "SIMPLE"))
  );
}

/**
 * Returns educational takeaways for person accounts.
 *
 * @param   {Array}   personAccounts array of person account objects.
 * @returns {Array}   array of strings.
 */
export function getPersonAccountsTakeaways(personAccounts = []) {
  let personAccountsTakeaways = [];
  personAccounts.forEach(function (educationalGoalAccount) {
    let goalType =
        educationalGoalAccount.role === "COLLEGE_SAVING"
          ? "higher education goal"
          : "pre-college goal",
      personName =
        educationalGoalAccount.personName &&
        educationalGoalAccount.personName.firstName
          ? `${educationalGoalAccount.personName.firstName}'s`
          : "Someone's";
    personAccountsTakeaways.push(`${personName} ${goalType}`);
  });
  return personAccountsTakeaways;
}

/**
 * Verifies if the account is with the current employer. Usually for retirement plans.
 *
 * @param {Object} account object
 * @returns {Boolean} flag indicating that the supplied account is with the current employer.
 */
export function isWithCurrentEmployer(account) {
  // the account is considered to be with the current employer if the flag is not set
  return (
    get(account, "additionalAttributes.isCurrentEmployer") !==
    IS_CURRENT_EMPLOYER_NO
  );
}

export function extractOwnershipTypes(value, accountTypes) {
  const item = accountTypes.find((d) => d.value === value);
  if (item && item.attributes && item.attributes.availableOwnerships) {
    const ownerships = item.attributes.availableOwnerships;
    return typeof ownerships === "string" ? JSON.parse(ownerships) : ownerships;
  }
}

export function getOwnershipTypes(accountTypeGroup, accountTypeNew, typesMap) {
  if (typesMap) {
    const accountTypes = typesMap[accountTypeGroup];
    if (accountTypeNew && accountTypes) {
      return extractOwnershipTypes(accountTypeNew, accountTypes);
    }
  }
}

/**
 * Determine the match limit type for the radio buttons, based on which field currently has data.
 *
 * @param {?number} maxMatchDollarValue max dollar value or undefined.
 * @param {?number} maxMatchPercentOfSalary max percent or undefined.
 * @returns {string} The employer match limit type.
 */
export function getEmployerMatchLimitType(
  maxMatchDollarValue,
  maxMatchPercentOfSalary
) {
  const isPercentLimitActive =
    !maxMatchDollarValue && Boolean(maxMatchPercentOfSalary);
  const areBothLimitsActive =
    Boolean(maxMatchDollarValue) && Boolean(maxMatchPercentOfSalary);

  if (isPercentLimitActive) {
    return "percent";
  } else if (areBothLimitsActive) {
    // this will require special one-time handling
    return "both";
  }

  // when neither are active, default to dollar
  return "dollar";
}

export const STATE_VERIFIED = "VERIFIED";
export const STATE_PENDING = "PENDING";
export const STATE_LOCKED = "LOCKED";
export const STATE_BLOCKED = "BLOCKED";
export const ACTION_WAIT_IAV = "WAIT_IAV";
export const ACTION_INITIATE_IAV = "INITIATE_IAV";
export const ACTION_INITIATE_MICRO_DEPOSIT = "INITIATE_MICRO_DEPOSIT";
export const ACTION_VERIFY_MICRO_DEPOSIT = "VERIFY_MICRO_DEPOSIT";
export const ACTION_REQUIRE_ACCOUNT_NUMBER = "REQUIRE_ACCOUNT_NUMBER";
export const ACTION_FIX_AGGREGATION_ERROR = "FIX_AGGREGATION_ERROR";
export const ACTION_AGGREGATION_IN_PROGRESS = "AGGREGATION_IN_PROGRESS";
export const ACTION_AGGREGATE_ACCOUNT = "AGGREGATE_ACCOUNT";
export const ACTION_CLASSIFY_ACCOUNT = "CLASSIFY_ACCOUNT";

export const MAX_INTEREST_DIGITS = 5; // 4 to support decimal values such as 66.66

const ACCOUNT_GROUPS_BENEFICIARIES = [
  "ANNUITY",
  "BUSINESS",
  "EDUCATIONAL",
  "ESOP",
  "ESTATE",
  "LIFE_INSURANCE",
  "RETIREMENT",
  "TRUST",
];

/**
 * Verifies whether we need to collect information about beneficiaries
 * on the specified account.
 *
 * @param  {String} accountGroup      The account type group.
 * @param  {String} ownershipType     The type of the account's ownership.
 * @param  {Boolean} isEsog           Whether account is an ESOG (stock option) account or not.
 * @param  {Boolean} isEmpowerAccount Whether account is an Empower account
 * @return {Boolean}                  `true` if beneficiaries are supported on the account.
 */
export function isBeneficiarySupported(
  accountGroup,
  ownershipType,
  isEsog = false,
  isEmpowerAccount = false
) {
  return (
    !isEmpowerAccount &&
    !isEsog &&
    (ACCOUNT_GROUPS_BENEFICIARIES.includes(accountGroup) ||
      ownershipType === "INDIVIDUAL_TOD" ||
      ownershipType === "JOINT_TOD" ||
      (ownershipType === "INDIVIDUAL" && accountGroup === "BANK") ||
      (ownershipType === "JOINT" && accountGroup === "BANK"))
  );
}

/**
 * Retrieves the available balance based on the account type.
 *
 * @param  {Object} account   The account account.
 * @return {Number}           The available balance on the account.
 */
export function getAvailableBalance(account) {
  return account.productType === "INVESTMENT"
    ? account.availableCash
    : account.availableBalance;
}

export function isOnUs(account) {
  if (!account) {
    return false;
  }

  return account.isOnUs || account.isOnUsBank;
}

export function isOnUsInvestment(account) {
  if (!account) {
    return false;
  }

  return account.isOnUs && !account.isOnUsBank;
}

export function isOnUsBank(account) {
  if (!account) {
    return false;
  }

  return account.isOnUsBank;
}

/**
 * Determines if a transfer between two accounts is an ACAT transfer.
 *
 * @param  {Object} sourceAccount The from account.
 * @param  {Object} targetAccount The to account.
 * @return {Boolean} `true` if both accounts are `INVESTMENT` type.
 */
export function isAcatTransfer(sourceAccount, targetAccount) {
  if (sourceAccount == null || targetAccount == null) {
    return;
  }

  return (
    sourceAccount.productType === "INVESTMENT" &&
    targetAccount.productType === "INVESTMENT"
  );
}

/**
 * Finds an account by `userAccountId`.
 *
 * @param  {Number} userAccountId The user account id.
 * @param  {Array} accounts The list of account.
 * @return {Object} the matching account object.
 */
export function findAccountByUserAccountId(userAccountId, accounts = []) {
  if (userAccountId == null) {
    return;
  }

  return accounts.find((a) => a.userAccountId === userAccountId);
}

export function isAccountInheritedIRA(accountSubType, accountTypeNew) {
  return (
    (accountSubType === "INHERITED_TRADITIONAL" ||
      accountSubType === "INHERITED_ROTH") &&
    accountTypeNew === "IRA"
  );
}

export function isBankAccount(account) {
  return account?.productType === "BANK";
}

export function isRetirementAccount(account) {
  return account?.accountTypeGroup === "RETIREMENT";
}

export function isIraAccountByType(account, subtype) {
  return (
    isRetirementAccount(account) &&
    account?.accountTypeNew === "IRA" &&
    account?.accountTypeSubtype === subtype
  );
}

/**
 * Checks if this account is an empower account, with empower attributes type
 * retail and if is an ira retirement account with added subtype that is set
 * as parameter
 *
 * @param   {Object}  account the account
 * @param   {String}  subtype the account
 * @returns {Boolean}         the boolean flag
 */
export function isEmpowerRetailIraAccountByType(account, subtype) {
  return (
    account?.isEmpower &&
    !isEmpty(account?.empowerAttributes) &&
    account?.empowerAttributes?.type === "RETAIL" &&
    isRetirementAccount(account) &&
    account?.accountTypeNew === "IRA" &&
    account?.accountTypeSubtype === subtype
  );
}

export function isIraAccount(account) {
  return isRetirementAccount(account) && account?.accountTypeNew === "IRA";
}

export function hasIraAccount(accounts) {
  if (!accounts?.length) {
    return false;
  }
  const iraAccountsLength = accounts.filter(isIraAccount)?.length ?? 0;
  return iraAccountsLength > 0;
}

export function hasIraAccountOnly(accounts) {
  if (!accounts?.length) {
    return false;
  }
  const iraAccountsLength = accounts.filter(isIraAccount)?.length ?? 0;
  return accounts.length === iraAccountsLength;
}

export function updateToReestablishingConnection(requestProps) {
  return promisify(Services.Retirement.updateToReestablishingConnection)(
    requestProps
  );
}

export async function clientHasTakenActionOnFeeXAccount(userId, userAccountId) {
  const requestProps = { userId, userAccountId };
  try {
    await promisify(Services.Retirement.actionTakenByClient)(requestProps);
    await updateToReestablishingConnection(requestProps);
    return { success: true, errors: null };
  } catch (errors) {
    return Promise.reject({ success: false, errors });
  }
}

export function styleNextActionInstructions(instructions, action) {
  const retirementErrorLinkSelector = "a[data-action='retirement-error-link']";
  const instructionsHtml = $(instructions);
  const buttonWrapper = instructionsHtml.next("div");

  //style incoming cancel and action buttons
  if (buttonWrapper.length) {
    buttonWrapper.addClass("sidebar-account__actions");
    buttonWrapper
      .find(retirementErrorLinkSelector)
      .addClass("pc-btn pc-btn--tiny pc-btn--primary pc-u-ml--");
    buttonWrapper
      .find("button[data-action='retirement-error-cancel']")
      .addClass("pc-btn pc-btn--tiny pc-u-mr--");
    instructions =
      instructionsHtml.prop("outerHTML") + buttonWrapper.prop("outerHTML");
  }

  //fiLink coming from RETIREMENT_CREDENTIALS_MUST_BE_RESET should open in a new tab
  if (action === "RETIREMENT_CREDENTIALS_MUST_BE_RESET") {
    buttonWrapper
      .find(retirementErrorLinkSelector)
      .attr("rel", "noopener noreferrer")
      .attr("target", "_blank");
    instructions =
      instructionsHtml.prop("outerHTML") + buttonWrapper.prop("outerHTML");
  }

  return instructions;
}

export function isMtrEligible(account) {
  if (!account || !account.empowerAttributes) return false;
  return Boolean(account.empowerAttributes.offerMtr);
}

export function isMtrEnrolled(account) {
  if (!account || !account.empowerAttributes) return false;
  return account.empowerAttributes.maEnrollmentStatus === "MTR";
}

export function isOnlineAdviceEnrolled(account) {
  if (!account || !account.empowerAttributes) return false;
  return account.empowerAttributes.maEnrollmentStatus === "ONLINE_ADVICE";
}

export function isNotMtrEnrolled(account) {
  return !isMtrEnrolled(account);
}

export function hasMtrEligibleAccounts(accounts) {
  return accounts?.some(isMtrEligible);
}

export function getMtrEligibleAccounts(accounts) {
  return accounts?.filter(isMtrEligible);
}

export function getUnenrolledMtrAccounts(accounts) {
  return accounts?.filter(isNotMtrEnrolled);
}

export function hasUnenrolledMtrEligibleAccounts(accounts) {
  return accounts?.some(
    (account) => isMtrEligible(account) && !isMtrEnrolled(account)
  );
}

export function hasEnrolledAllMtrEligibleAccounts(accounts) {
  return accounts.filter(isMtrEligible).every(isMtrEnrolled);
}

export function hasUnenrolledAllMtrEligibleAccounts(accounts) {
  return accounts.filter(isMtrEligible).every(isNotMtrEnrolled);
}

export function getUnenrolledMtrEligibleAccounts(accounts) {
  return accounts?.filter(
    (account) => isMtrEligible(account) && !isMtrEnrolled(account)
  );
}
