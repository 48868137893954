import React from "react";
import PropTypes from "prop-types";

export default class RemoveWithConfirmationButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleOnConfirmRemove = this.handleOnConfirmRemove.bind(this);
  }

  toggleDialog() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleOnConfirmRemove() {
    this.toggleDialog();
    this.props.onConfirm();
  }

  render() {
    const {
      removePromptLabel,
      containerClassName,
      removePromptClassName,
      // eslint
      children,
      removeButtonClassName,
      isPrivileged,
    } = this.props;
    return (
      <div
        className={`pc-remove-confirmation__container ${containerClassName}`}
      >
        <button
          type="button"
          onClick={this.toggleDialog}
          className={`pc-btn qa-remove-confirmation-button ${removeButtonClassName}`}
        >
          {children || "Remove"}
        </button>
        {this.state.isOpen && (
          <div
            className={`pc-remove-confirmation__dialog qa-remove-confirmation-dialog ${removePromptClassName}`}
          >
            <div className={"pc-remove-confirmation__prompt"}>
              {removePromptLabel}
            </div>
            <button
              className="pc-btn pc-btn--small qa-remove-confirmation-dialog-cancel-btn"
              onClick={this.toggleDialog}
            >
              Cancel
            </button>
            <button
              className={
                "pc-btn pc-btn--small pc-btn--danger qa-remove-confirmation-dialog-confirm-btn" +
                (isPrivileged ? " disabled" : "")
              }
              onClick={this.handleOnConfirmRemove}
            >
              Remove
            </button>
          </div>
        )}
      </div>
    );
  }
}

RemoveWithConfirmationButton.propTypes = {
  containerClassName: PropTypes.string,
  removeButtonClassName: PropTypes.string,
  removePromptClassName: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
  removePromptLabel: PropTypes.string,
  isPrivileged: PropTypes.bool,
};

RemoveWithConfirmationButton.defaultProps = {
  containerClassName: "",
  removePromptClassName: "",
  removeButtonClassName: "",
  children: null,
  removePromptLabel: "Are you sure you want to remove this?",
  isPrivileged: false,
};
