import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "libs/pcap/utils/format";
import { fromNow } from "libs/pcap/utils/fromNow";
import deepCopy from "deep-copy";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";

export default class ReopenAccountView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { model: deepCopy(props.model) };
  }

  render() {
    const { model } = this.state;
    let isCreditAccount = model.productType === "CREDIT_CARD";
    let balance = model.balance;
    if (isCreditAccount) {
      balance *= -1;
    }

    return (
      <div>
        <LoadingOverlay active={this.props.loading} />
        {model.logoPath && (
          <div className="edit-account__logo-container">
            <img src={model.logoPath} className="u-full-center--abs" />
          </div>
        )}
        <div className="l-spaced l-spaced--flush edit-account__text--info">
          <div className="u-text-bold">{model.firmName}</div>
          <div className="u-text-bold">{formatCurrency(balance)}</div>
        </div>
        <div className="l-spaced l-spaced--flush edit-account__text--info">
          <div>{model.name}</div>
          <div>{fromNow(model.lastRefreshed)}</div>
        </div>
        <div className="pc-u-mv-">
          This will fetch the most recent balance, and we will attempt to fetch
          any transactions between the closed date and today. If enough time has
          passed, however, those transactions may no longer be available from
          your institution.
        </div>
        {this.props.errors && (
          <Message
            className="pc-u-mb"
            severity="error"
            messages={this.props.errors}
          />
        )}
        <div className="l-spaced l-spaced--right">
          <button
            type="button"
            className="pc-btn js-btn-cancel"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="pc-btn pc-btn--primary js-btn-reopen qa-btn-reopen"
            onClick={this.props.onReopen}
          >
            Reopen
          </button>
        </div>
      </div>
    );
  }
}

ReopenAccountView.propTypes = {
  model: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReopen: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
};

ReopenAccountView.defaultProps = {
  loading: false,
  errors: undefined,
};
