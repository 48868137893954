/* eslint-disable no-magic-numbers */

import { timeDay, timeWeek, timeFormat } from "d3";

import CashFlowBaseView from "views/modules/cashFlowV2/cashFlowBaseView";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Donut from "libs/pcap/visualizations/donut";
import CashFlowState from "stateModels/cashFlowState";
import Formats from "formats";
import CashFlowIncomeExpenseTemplate from "templates/cashFlowIncomeExpense.html";
import CashManagerHistoryBarChart from "../../../components/budgeting/CashManagerHistoryBarChart";
import React from "react";
import ReactDOM from "react-dom";
import LineGraph from "views/components/cashFlow/lineGraph";
import DonutLegendMixin from "views/components/cashFlow/donutLegendMixin";
import BreadcrumbMixin from "views/components/cashFlow/breadcrumbMixin";
import moment from "moment";
import Raphael from "raphael";
import dollarAndCentsAmount from "templates/helpers/dollarAndCentsAmount";
import { isDiffLessThanAMonth } from "libs/pcap/utils/date2";
import mixpanel from "../../../libs/pcap/utils/mixpanel";
import { replaceHash } from "libs/pcap/utils/location";
import DateUtils from "../../../libs/pcap/utils/date";
import takeaways from "takeAways";

var ANIMATION_DURATION = 500;

var DRILL_DOWN_LEVEL_ALL = 0;
var DRILL_DOWN_LEVEL_CATEGORY = 1;
var DRILL_DOWN_LEVEL_MERCHANT = 2;

var PATH_LENGTH_ALL = 1;
var PATH_LENGTH_CATEGORY = 2;
var PATH_LENGTH_MERCHANT = 3;

const ARIA_LABELS = {
  drilldownLevel: {
    0: "Showing all $mode ",
    1: "Showing $mode for category $category",
    2: "Showing $mode for category $category and merchant $merchant",
  },
  barChart: {
    title: "Bar graph of $mode by month",
    description: {
      0: "all $mode",
      1: "$mode for category $category",
      2: "$mode for category $category and merchant $merchant",
    },
  }
};

export default CashFlowBaseView.extend({
  el: "#cashFlowIncomeExpense",

  initialize: function () {
    _.extend(this.events, CashFlowBaseView.prototype.events);
    CashFlowBaseView.prototype.initialize.apply(this, arguments);

    this.$el.addClass("cash-flow");

    _.extend(this, DonutLegendMixin);
    _.extend(this, BreadcrumbMixin);

    this.incomeExpenseMode = "expense";
    this.drilldownLevel = 0;
    this.dataTransactionsCurrPeriod = {};
    this.onInitDrilldown = false;
    this.fetchTransTimeout = null;

    // Use url path to set drilldown state
    if (!_.isUndefined(this.options.path) && this.options.path !== "") {
      this.pathNodes = this.options.path.split("/");

      if (this.pathNodes.length >= 1) {
        this.incomeExpenseMode = this.pathNodes[0];
      }

      if (this.pathNodes.length >= PATH_LENGTH_CATEGORY) {
        if (this.pathNodes.length === PATH_LENGTH_CATEGORY) {
          this.drilldownLevel = 1;
          this.onInitDrilldown = true;
        }
        this.selectedCategoryId = this.pathNodes[1];
      }

      if (this.pathNodes.length === PATH_LENGTH_MERCHANT) {
        this.drilldownLevel = DRILL_DOWN_LEVEL_MERCHANT;
        this.onInitDrilldown = true;
        this.selectedMerchantId = this.pathNodes[2] || "";
      }
    }

    // Sync this.options with CashFlowState
    this.trackViewState("cashFlow", CashFlowState, false);
    this.on("change:categoryState", this.updateGraph, this);

    this.render();
    this.initializeAllFixedControls();
    this.on("change:categoryState", this.renderTakeaways, this);
    this.on("change:categoryState", this.updateSRInfo, this);

    this.fetchData();

    this.on(
      "featurecontrolstuck",
      function (direction, featureControlSelector) {
        var offset = 0;
        if (direction === "up") {
          offset = window.scrollY;
          if (offset === undefined) {
            offset = window.document.documentElement.scrollTop;
          }
        }
        this.dateRangeSelector.setPosition(offset, featureControlSelector);
      }.bind(this)
    );
  },

  /**
   * Returns the chart class to be displayed for the current state of the page.
   * Line chart class will is returned if any full month or the current month is selected.
   * Otherwise, the bar chart class is returned.
   *
   * @returns {Backbone.View} The chart class.
   */
  getChartClass: function () {
    return this.isCompleteOrCurrentMonthSelected() ||
      isDiffLessThanAMonth(this.getStartDateObj(), this.getEndDateObj())
      ? LineGraph
      : CashManagerHistoryBarChart;
  },

  /**
   * Specifies the weekly interval for line chart X axis ticks.
   *
   * Since we display data series from two months using the same axis
   * and we don't want to cut off the last data point of the longer month,
   * the axis is created for the longer month. However, the ticks (Sundays)
   * still need to be displayed for the current month.
   * The method forces the axis to display the Sundays of the current month.
   *
   * @returns {Function|Array}  The weekly interval function if the current month
   *                            is longer than the previous month.
   *                            Otherwise, the array of specific dates to be displayed
   *                            on the axis. The dates represent Sundays of the current
   *                            month transposed to the previous month, so that there is
   *                            an illusion of displaying the current month's axis.
   */
  getLineChartXTicks: function () {
    var startDate = this.getStartDateObj();
    var endDate = this.getEndDateObj();
    var currEndDate = startDate.clone().endOf("month");
    var prevStartDate = startDate.clone().subtract(1, "month");
    var prevEndDate = prevStartDate.clone().endOf("month");
    const daysDiff = endDate.diff(startDate, "days");
    const startMonthDays = currEndDate.date();

    // When dates difference is less than a month
    // and selected range is not complete month
    if (daysDiff < startMonthDays && !this.isCompleteOrCurrentMonthSelected()) {
      // When number of data points is less than half of number days in a month
      // show one ticker per day else show 1 ticker per 2 days.
      return timeDay.every(daysDiff < startMonthDays / 2 ? 1 : 2);
    }

    if (startMonthDays >= prevEndDate.date()) {
      return timeWeek.every(1);
    }

    var sundays = timeWeek.every(1).range(startDate, endDate);
    return sundays.map(function (sunday) {
      return moment(sunday).subtract(1, "month");
    });
  },

  /**
   * Specifies the `d` format for line chart X axis ticks.
   *
   * NOTE: We can't display the month part on the ticks because an axis for the previous month
   * is displayed sometimes.
   *
   * @returns {Function} The formatter function.
   */
  getLineChartXTickFormat: function () {
    return timeFormat("%d");
  },

  updateView: function (newViewState) {
    Backbone.View.prototype.updateView.call(this, newViewState);

    var self = this;
    var incomeExpenseModeChanged = false;
    var categoryChanged = false;

    if (!_.isUndefined(this.options.path) && this.options.path !== "") {
      this.pathNodes = this.options.path.split("/");

      incomeExpenseModeChanged = this.pathNodes[0] !== this.incomeExpenseMode;

      if (this.pathNodes.length > 0) {
        if (this.pathNodes.length >= PATH_LENGTH_ALL) {
          if (
            this.pathNodes[0] === "income" ||
            this.pathNodes[0] === "expense"
          ) {
            this.incomeExpenseMode = this.pathNodes[0];
          } else {
            throw new Error(this.pathNodes[0] + "is not a valid tab mode.");
          }
        }

        if (this.pathNodes.length >= PATH_LENGTH_CATEGORY) {
          this.selectedCategory = this.getCategory(this.pathNodes[1]);

          if (_.isUndefined(this.selectedCategory)) {
            throw new Error(this.pathNodes[1] + "is not a valid categoryId");
          } else if (
            _.isUndefined(this.selectedCategoryId) ||
            this.selectedCategoryId !== this.pathNodes[1]
          ) {
            this.selectedCategoryId = this.pathNodes[1];
            categoryChanged = true;
          }
        }

        if (this.pathNodes.length === PATH_LENGTH_MERCHANT) {
          this.selectedMerchant = this.getMerchant(this.pathNodes[2]);

          if (_.isUndefined(this.selectedMerchant)) {
            throw new Error(this.pathNodes[2] + "is not a valid merchantId");
          } else {
            this.selectedMerchantId = this.pathNodes[2] || "";
          }
        }
      }
    } else {
      this.pathNodes = [];
      if (this.incomeExpenseMode === "income") {
        this.incomeExpenseMode = "expense";
        incomeExpenseModeChanged = true;
      }
    }

    if (this.pathNodes.length === PATH_LENGTH_ALL) {
      this.selectedCategory = null;
      this.selectedCategoryId = null;
      this.selectedMerchant = null;
      this.selectedMerchantId = null;
    } else if (this.pathNodes.length === PATH_LENGTH_CATEGORY) {
      this.selectedMerchant = null;
      this.selectedMerchantId = null;
    }

    // Sync this.options with CashFlowState
    this.trackViewState("cashFlow", CashFlowState, false);

    if (incomeExpenseModeChanged) {
      this.changeIncomeExpense();
    } else if (this.pathNodes.length <= PATH_LENGTH_ALL) {
      var drillDownLevel = this.getDrilldownLevel();

      if (drillDownLevel === DRILL_DOWN_LEVEL_CATEGORY) {
        if (this.selectedCategoryId) {
          this.donut.simulateClickToDrillUp();
          this.fadeOutLegend();
        } else {
          var breadcrumbsElement = this.$el.find(".breadcrumbs");
          var legendDonut = this.$el.find(".legendDonut");
          var fadeInElements = function () {
            self.selectedCategory = undefined;
            self.donut.buildCategoryDonut(
              self.getCurrentCategories(),
              self.incomeExpenseMode,
              self.drilldownLevel,
            );
            self.renderBreadcrumb(0);
            self.renderLegend(self.getCurrentCategories());
            self.renderDonutLabels();
            breadcrumbsElement.fadeTo(ANIMATION_DURATION, 1);
            legendDonut.fadeTo(ANIMATION_DURATION, 1);
            self.updateGraph();
            self.emitCategoryState();
          };

          this.setDrilldownLevel(0);
          breadcrumbsElement.fadeTo(ANIMATION_DURATION, 0);
          legendDonut.fadeTo(ANIMATION_DURATION, 0, fadeInElements);
        }
      } else {
        this.$el.find(".legendDonut .legend").show();
        if (
          drillDownLevel === DRILL_DOWN_LEVEL_MERCHANT &&
          this.selectedCategoryId
        ) {
          this.donut.simulateClickToDrillUp();
        } else if (
          drillDownLevel === DRILL_DOWN_LEVEL_MERCHANT &&
          !this.selectedCategoryId
        ) {
          this.donut.returnToCategories();
        }
      }
    } else if (this.pathNodes.length === PATH_LENGTH_CATEGORY) {
      var merchantList =
        this.incomeExpenseMode === "income"
          ? this.dataTransactionsCurrPeriod.incomeMerchants
          : this.dataTransactionsCurrPeriod.expenseMerchants;

      if (merchantList) {
        var categoryMerchants = merchantList[this.selectedCategoryId];
        categoryMerchants = this.filterArrayByAmount(categoryMerchants);
        categoryMerchants = this.orderArrayByAmount(categoryMerchants);
        this.setMerchants(categoryMerchants);

        if (this.getDrilldownLevel() === 0) {
          this.setDrilldownLevel(1);
          this.donut.drillDownToMerchantDonut(
            this.selectedCategoryId,
            this.getMerchants(),
            this.incomeExpenseMode
          );
        } else if (categoryChanged) {
          this.setDrilldownLevel(1);
          this.$el.find(".legendDonut .legend").show();
          this.donut.switchCategory(
            this.selectedCategoryId,
            this.getMerchants(),
            this.incomeExpenseMode
          );
        } else {
          this.$el.find(".legendDonut .legend").show();
          this.renderBreadcrumb(1);
          this.renderDonutLabels();
          this.emitCategoryState();
        }
      }
    } else if (this.pathNodes.length === PATH_LENGTH_MERCHANT) {
      if (this.getDrilldownLevel() === DRILL_DOWN_LEVEL_MERCHANT) {
        this.renderBreadcrumb(this.getDrilldownLevel());
      } else {
        this.setDrilldownLevel(DRILL_DOWN_LEVEL_MERCHANT);
        this.renderBreadcrumb(this.getDrilldownLevel());
      }
      this.emitCategoryState();
    }

    if (this.dataGridV2) {
      let transactions = [];
      switch (this.incomeExpenseMode) {
        case "income":
        case "expense":
          transactions = this.getFilteredTransactions();
          break;
        default:
          transactions = this.dataTransactionsCurrPeriod?.transactions || [];
      }

      this.dataGridV2.update({
        transactions,
      });
    }
  },

  /* ************** GETTER AND SETTER METHODS ***************** */
  setDrilldownLevel: function (value) {
    if (value === this.drilldownLevel) {
      return;
    }
    this.previousLevel = this.drilldownLevel;
    this.drilldownLevel = value;
  },
  getDrilldownLevel: function () {
    return this.drilldownLevel;
  },

  getCategory: function (categoryId) {
    return _.find(
      this.getCurrentCategories(),
      function (category) {
        return category.transactionCategoryId === categoryId;
      },
      this
    );
  },
  setCurrentCategories: function (value) {
    this.previousCategories = this.currentCategories;
    this.currentCategories = value;
  },
  getCurrentCategories: function () {
    return this.currentCategories || [];
  },

  getMerchant: function (merchantId) {
    return _.find(
      this.getMerchants(),
      function (merchant) {
        return merchant.id === merchantId;
      },
      this
    );
  },
  setMerchants: function (value) {
    this.previousMerchants = this.merchants;
    this.merchants = value;
  },
  getMerchants: function () {
    return this.merchants;
  },

  updateSRInfo: function () {
    const drilldownLevel = this.getDrilldownLevel();
    let SRInfo = ARIA_LABELS.drilldownLevel[drilldownLevel].replace("$mode",this.getModeDisplayName());
    if (drilldownLevel>0){
      SRInfo = SRInfo.replace("$category", this.selectedCategory?.name)
    }
    if (drilldownLevel>1){
      SRInfo = SRInfo.replace("$merchant", this.selectedMerchant?.name)
    }

    this.setSRInfo(SRInfo);
    this.donut.setDrilldownLevel(drilldownLevel);
    setTimeout(() => this.updateSRWithChartInfo(),500);

  },

  /* ************** RENDERING METHODS ***************** */
  render: function () {
    if (this.killed) {
      return;
    }

    this.removeAccountSelector();
    this.removeDateRangeSelector();

    this.$el.html(
      CashFlowIncomeExpenseTemplate({
        incomeExpenseMode: this.incomeExpenseMode,
        isEmpower: IS_EMPOWER,
      })
    );

    this.renderDateRangeSelector();
    this.renderAccountSelector();
    window.dispatchEvent(new CustomEvent("pageloaded"));
    return this;
  },

  /*
   * renderDonut renders the donut based on selection parameters.
   *
   * @param	transactionData An optional parameter.  If it exists, then this method is invoked as a result of an API call.
   */
  renderDonut: function (transactionData, renderRelatedElements) {
    renderRelatedElements = renderRelatedElements || true;

    if (!_.isEmpty(transactionData)) {
      this.expenseCategories = transactionData.expenseCategories.slice();
      this.expenseCategories = this.filterArrayByAmount(this.expenseCategories);
      this.expenseCategories = this.orderArrayByAmount(this.expenseCategories);

      this.incomeCategories = transactionData.incomeCategories.slice();
      this.incomeCategories = this.filterArrayByAmount(this.incomeCategories);
      this.incomeCategories = this.orderArrayByAmount(this.incomeCategories);
    }

    this.setCurrentCategories(
      this.incomeExpenseMode === "income"
        ? this.incomeCategories
        : this.expenseCategories
    );

    if (this.donut) {
      if (this.previousIncomeExpenseMode === this.incomeExpenseMode) {
        this.donut.updateCategoryDonut(
          this.getCurrentCategories(),
          this.incomeExpenseMode,
          this.drilldownLevel
        );
      } else {
        this.donut.buildCategoryDonut(
          this.getCurrentCategories(),
          this.incomeExpenseMode,
          this.drilldownLevel
        );
      }
    } else {
      var container = this.$el.find(".donutSVG");
      this.donut = new Donut(
        Raphael(container[0]),
        160,
        137,
        112,
        82,
        this.getCurrentCategories(),
        this.incomeExpenseMode,
        this.drilldownLevel
      ); /* disable preexisting warning */ // eslint-disable-line no-magic-numbers

      this.donut.on("categorySelected", this.onCategorySelected, this);
      this.donut.on("categorySelected", this.fadeOutLegend, this);
      this.donut.on(
        "drillDownToMerchantComplete",
        this.onDrillDownToMerchant,
        this
      );
      this.donut.on("drillDownToMerchantComplete", this.fadeInLegend, this);
      this.donut.on(
        "drillUpToCategory",
        this.removeMerchantLegendEventListeners,
        this
      );
      this.donut.on("drillUpToCategory", this.fadeOutLegend, this);
      this.donut.on(
        "drillUpToCategoryComplete",
        this.onDrillUpToCategory,
        this
      );
      this.donut.on("drillUpToCategoryComplete", this.fadeInLegend, this);
      this.donut.on("merchantSelected", this.onMerchantSelected, this);
      this.donut.on("sliceMouseover", this.onSliceMouseover, this);
      this.donut.on("donutMouseout", this.onDonutMouseout, this);
      this.donut.on("ariaDataUpdated", this.updateSRWithChartInfo, this);

      this.updateSRWithChartInfo();
    }

    if (renderRelatedElements) {
      this.renderLegend(this.getCurrentCategories());
      this.renderBreadcrumb(this.getDrilldownLevel());
      this.renderDonutLabels();
    }
    this.previousIncomeExpenseMode = this.incomeExpenseMode;
  },
  onCategorySelected: function (transactionCategoryId) {
    replaceHash(
      `${this.options.baseUrl}/${this.incomeExpenseMode}/${transactionCategoryId}`
    );
  },
  onDrillUpToCategory: function () {
    this.selectedCategory = undefined;
    this.selectedCategoryId = undefined;
    this.setDrilldownLevel(0);

    if (this.incomeExpenseMode === "income") {
      this.renderLegend(this.incomeCategories);
    } else {
      this.renderLegend(this.expenseCategories);
    }

    this.renderBreadcrumb(this.getDrilldownLevel());
    this.renderDonutLabels();
    this.emitCategoryState();
    replaceHash(`${this.options.baseUrl}/${this.incomeExpenseMode}`);
  },
  onDrillDownToMerchant: function () {
    if (
      this.getDrilldownLevel() === DRILL_DOWN_LEVEL_MERCHANT &&
      !this.selectedCategoryId
    ) {
      this.renderLegend(this.getMerchants());
      this.renderDonutLabels();
      this.renderBreadcrumb(this.getDrilldownLevel());
    } else if (
      this.getDrilldownLevel() === DRILL_DOWN_LEVEL_MERCHANT &&
      this.selectedCategoryId
    ) {
      this.renderBreadcrumb(this.getDrilldownLevel());
      this.renderLegend(this.getMerchants());
      this.renderDonutLabels();
    } else {
      this.renderBreadcrumb(this.getDrilldownLevel());
      this.renderLegend(this.getMerchants());
      this.renderDonutLabels();

      this.emitCategoryState();
    }
  },
  onMerchantSelected: function (merchantId) {
    replaceHash(
      `${this.options.baseUrl}/${this.incomeExpenseMode}/${this.selectedCategoryId}/${merchantId}`
    );
  },
  onSliceMouseover: function (sliceId) {
    var self = this;
    _.each(this.legendItems, function (legendItem) {
      var legendItemJQuery = $(legendItem);
      var legendItemSlideId = legendItem[0].getAttribute("data-sliceid");
      if (legendItemSlideId === sliceId) {
        var mockEvent = { currentTarget: legendItem[0] };
        self.renderDonutLabels(mockEvent);
        if (!legendItemJQuery.hasClass("highlight")) {
          legendItemJQuery.addClass("highlight");
        }
      } else if (legendItemJQuery.hasClass("highlight")) {
        legendItemJQuery.removeClass("highlight");
      }
    });
  },
  onDonutMouseout: function () {
    this.renderDonutLabels();
    this.$el.find(".legendDonut").find(".legend").trigger("mouseout");
  },

  renderDonutLabels: function (event) {
    var sliceId, slice;
    var drillDownLevel = this.getDrilldownLevel();
    if (drillDownLevel === DRILL_DOWN_LEVEL_ALL) {
      if (event) {
        sliceId = event.currentTarget.getAttribute("data-sliceid");
        slice = _.findWhere(this.getCurrentCategories(), {
          sliceId: sliceId,
        });
      } else {
        if (this.incomeExpenseMode === "income") {
          this.$el
            .find(".labelAmount")
            .removeClass("expense qa-expense-amount");
          this.$el.find(".labelAmount").addClass("income qa-income-amount");
        } else {
          this.$el.find(".labelAmount").removeClass("income qa-income-amount");
          this.$el.find(".labelAmount").addClass("expense qa-expense-amount");
        }
        slice = {
          name: this.incomeExpenseMode === "income" ? "Income" : "Expenses",
          amount:
            this.incomeExpenseMode === "income"
              ? this.dataTransactionsCurrPeriod.moneyIn
              : this.dataTransactionsCurrPeriod.moneyOut,
        };
      }
    } else if (drillDownLevel === DRILL_DOWN_LEVEL_CATEGORY) {
      if (event) {
        sliceId = event.currentTarget.getAttribute("data-sliceid");
        slice = _.findWhere(this.getMerchants(), { sliceId: sliceId });
      } else {
        slice = {
          name: this.selectedCategory ? this.selectedCategory.name : "",
          amount: this.selectedCategory ? this.selectedCategory.amount : 0,
        };
      }
    } else if (drillDownLevel === DRILL_DOWN_LEVEL_MERCHANT) {
      if (event) {
        sliceId = event.currentTarget.getAttribute("data-sliceid");
        slice = _.findWhere(this.getMerchants(), { sliceId: sliceId });
      } else {
        slice = {
          name: this.selectedMerchant ? this.selectedMerchant.name : "",
          amount: this.selectedMerchant ? this.selectedMerchant.amount : 0,
        };
      }
    }

    if (slice) {
      this.$el.find(".labelName").text(slice.name);
      var formattedAmount = dollarAndCentsAmount(
        slice.amount,
        true,
        true,
        this.incomeExpenseMode === "expense",
        true
      );
      this.$el.find(".labelAmount").html(formattedAmount);
    }
  },

  /* ************** DRILLDOWN METHODS ***************** */
  removeMerchantLegendEventListeners: function () {
    this.removeLegendEventListeners(this.getMerchants());
  },
  removeLegendEventListeners: function (slices) {
    var self = this;

    _.each(slices, function (slice) {
      var item = self.$el.find('[data-sliceid="' + slice.sliceId + '"]');
      item.unbind();
    });

    this.$el.find(".legendDonut > .legend").unbind();
  },

  /* ************** CATEGORY AND MERCHANT DATA METHODS ***************** */
  filterArrayByAmount: function (array) {
    return _.filter(array, function (item) {
      return _.isNumber(item.amount) && item.amount > 0;
    });
  },
  orderArrayByAmount: function (array) {
    return _.sortBy(array, "amount").reverse();
  },
  emitCategoryState: function () {
    var categoryId =
      this.drilldownLevel > 0
        ? this.selectedCategory.transactionCategoryId
        : null;
    var merchantId =
      this.drilldownLevel === DRILL_DOWN_LEVEL_MERCHANT
        ? this.selectedMerchantId
        : null;

    this.trigger("change:categoryState", categoryId, merchantId);
  },

  /* *************** INCOME/EXPENSE *********************** */
  changeIncomeExpense: function () {
    var incomeExpenseModeChange = false;
    var tabs = this.$el.find(".js-cash-flow-tabs");
    _.each(
      tabs,
      function (element) {
        element = $(element);
        if (
          element.hasClass(this.incomeExpenseMode) &&
          !element.hasClass("is-active")
        ) {
          element.addClass("is-active qa-active");
          element.attr("aria-current",true);
          incomeExpenseModeChange = true;
        } else if (element.hasClass("is-active")) {
          element.removeClass("is-active qa-active");
          element.attr("aria-current",false);

        }
      }.bind(this)
    );

    if (this.incomeExpenseMode === "income") {
      mixpanel.trackEvent("View Cash Flow Income", {
        component: "cashFlowIncomeExpenseView",
      });
    } else {
      //expense
      mixpanel.trackEvent("View Cash Flow Expense", {
        component: "cashFlowIncomeExpenseView",
      });
    }

    if (incomeExpenseModeChange) {
      this.setDrilldownLevel(0);
      this.selectedCategory = null;
      this.selectedCategoryId = null;
      this.selectedMerchantId = null;

      this.graphFilter.incomeExpenseMode = this.incomeExpenseMode;
      this.graphFilter.categoryId = this.selectedCategoryId;
      this.graphFilter.merchant = this.selectedMerchantId;

      this.updateGraph();
      this.transitionDonutAndBreadcrumb(this.incomeExpenseMode);
      this.renderTakeaways();
    }

    window.dashboardUtils?.eventBus.dispatch("CashFlow.tab_click_event", {
      tab: this.incomeExpenseMode,
    });
  },

  transitionDonutAndBreadcrumb: function () {
    var self = this;
    var breadcrumbsElement = this.$el.find(".breadcrumb");
    var legendDonut = this.$el.find(".legendDonut");
    var fadeInElements = function () {
      self.renderDonut();
      breadcrumbsElement.fadeTo(ANIMATION_DURATION, 1);
      legendDonut.fadeIn(ANIMATION_DURATION, function () {
        self.trigger("donutLegendRendered");
      });
    };
    breadcrumbsElement.fadeTo(ANIMATION_DURATION, 0);
    legendDonut.fadeOut(ANIMATION_DURATION, fadeInElements);
  },

  getLevelHistoryData: function (cashflow, filter) {
    let chartSeries = [];
    let average = 0;

    if (filter.merchant) {
      var merchantCategories = cashflow[this.incomeExpenseMode + "Merchants"];
      var merchantData = merchantCategories[filter.categoryId];
      if (merchantData) {
        var merchantInfo = _.find(merchantData, function (d) {
          return d.id === filter.merchant;
        });
        if (merchantInfo) {
          chartSeries = merchantInfo.cashFlow || [];
          average = takeaways.getCashFlowMonthlyAverage(
            merchantInfo.transactions,
            filter.startDate,
            filter.endDate
          );
        }
      }
    } else if (filter.categoryId) {
      let found = cashflow[this.incomeExpenseMode + "Categories"].find(
        (cat) => cat?.transactionCategoryId === filter.categoryId
      );
      if (found) {
        chartSeries = found.cashFlow || [];
        average = takeaways.getCashFlowMonthlyAverage(
          found.transactions,
          filter.startDate,
          filter.endDate
        );
      }
    } else {
      chartSeries = cashflow.cashFlow || [];

      average = takeaways.getCashFlowMonthlyAverage(
        cashflow.transactions,
        filter.startDate,
        filter.endDate
      );
    }
    chartSeries = chartSeries.map((d) => {
      return {
        amount: this.incomeExpenseMode === "income" ? d.moneyIn : d.moneyOut,
        date: moment(d.date, DateUtils.API_FORMAT),
        incomeExpenseMode: this.incomeExpenseMode,
      };
    });
    average =
      this.incomeExpenseMode === "income"
        ? average.monthlyAverageIn
        : average.monthlyAverageOut;
    return {
      chartSeries,
      average,
    };
  },

  // line graph and bar graph has different data points
  buildBarGraphData: function () {
    var filter = this.getTransactionsFilter();
    let cashflowData = this.getCashFlow(
      this.dataTransactionsCurrPeriod,
      filter,
      "MONTH"
    );
    return this.getLevelHistoryData(cashflowData, filter);
  },

  getBarChartClass: function (d) {
    return d.incomeExpenseMode === "income"
      ? "cashflow-history-barchart__income"
      : "cashflow-history-barchart__expense";
  },

  getModeDisplayName: function () {
    return this.incomeExpenseMode === "income" ? "income" : "expenses";
  },

  initializeBarGraph: function () {
    let { chartSeries, average } = this.buildBarGraphData();

    if (this.barGraphEl) {
      ReactDOM.unmountComponentAtNode(this.barGraphEl);
    } else {
      this.barGraphEl = this.$(".js-bar-chart-container").get(0);
    }

    const { title, description} = ARIA_LABELS.barChart;
    const drillDownLevel = this.getDrilldownLevel();
    const ariaLabel = title.replace("$mode",this.getModeDisplayName());
    const ariaDesc = description[drillDownLevel]
      .replace("$mode",this.getModeDisplayName())
      .replace("$category", this.selectedCategory?.name || "")
      .replace("$merchant", this.selectedMerchant?.name || "");

    ReactDOM.render(
      <CashManagerHistoryBarChart
        data={chartSeries}
        average={average}
        startDate={
          chartSeries[0]?.date
            ? moment(chartSeries[0]?.date, DateUtils.API_FORMAT)
            : moment()
        }
        endDate={
          chartSeries[chartSeries.length - 1]?.date
            ? moment(
                chartSeries[chartSeries.length - 1].date,
                DateUtils.API_FORMAT
              )
            : moment()
        }
        barClassName={this.getBarChartClass}
        ariaLabel={ariaLabel}
        ariaDesc={ariaDesc}
      />,
      this.barGraphEl
    );
  },

  mixinAmount: function (target) {
    target.amount = function (value) {
      // var dollarValue = Formats.filters.dollar_no_cents(value);
      var dollarValue = dollarAndCentsAmount(value, true, true);
      target.find(".amount").text(dollarValue);
    };
  },
  mixinPercent: function (target) {
    target.percent = function (value) {
      var percentage = Formats.filters.percent(value);
      target.find(".amount").text(percentage);
    };
  },
  mixinLabel: function (target) {
    target.label = function (value) {
      target.find("label").text(value);
    };
  },
  mixinPip: function (target) {
    target.pip = function (show) {
      var pip = target.find(".pip");
      if (show) {
        pip.show();
      } else {
        pip.hide();
      }
    };
  },
  getCashLegendCell: function (name) {
    var legend = this.$el.find(".cashLegend");
    var cell = legend.find("." + name);

    if (!cell.amount) {
      this.mixinAmount(cell);
    }
    if (!cell.percent) {
      this.mixinPercent(cell);
    }
    if (!cell.label) {
      this.mixinLabel(cell);
    }
    if (!cell.pip) {
      this.mixinPip(cell);
    }

    return cell;
  },
  renderCashLegend: function (transData) {
    this.getCashLegendCell("income").amount(transData.moneyIn);
    this.getCashLegendCell("expense").amount(transData.moneyOut);
    this.getCashLegendCell("total").amount(
      transData.moneyIn - transData.moneyOut
    );
  },
  setIncomeExpenseForCashLegend: function (incomeExpense) {
    _.each(
      ["income", "expense"],
      function (state) {
        this.getCashLegendCell(state).pip(incomeExpense === state);

        var percent = this.getCashLegendCell("percent");
        var average = this.getCashLegendCell("average");

        average.label(
          this.incomeExpenseMode === "income"
            ? "Income On Average"
            : "Expense on Average"
        );

        switch (this.getDrilldownLevel()) {
          case 0:
            break;
          case DRILL_DOWN_LEVEL_CATEGORY:
            if (this.incomeExpenseMode === "income") {
              percent.label("Of All Income");
            } else {
              percent.label("Of All Expenses");
            }
            break;
          case DRILL_DOWN_LEVEL_MERCHANT:
            var cat = this.getCategory();
            if (cat) {
              if (this.selectedMerchant) {
                percent.label("Of " + cat.name);
                cat = _.find(
                  this.getMerchants(),
                  function (m) {
                    return m.name === this.selectedMerchant;
                  }.bind(this)
                );
              }
            }
            break;
          default:
        }
      }.bind(this)
    );
  },
  sendCategoryStateToCashLegend: function (categoryId, merchant) {
    var percent = this.getCashLegendCell("percent");
    var average = this.getCashLegendCell("average");

    if (categoryId && this.getCurrentCategories().length > 0) {
      percent.show();
      average.show();

      if (this.incomeExpenseMode === "income") {
        percent.label("Of All Income");
      } else {
        percent.label("Of All Expenses");
      }

      this.getCashLegendCell("income").hide();
      this.getCashLegendCell("expense").hide();
      this.getCashLegendCell("total").hide();
      var cat = this.getCategory(categoryId);

      if (cat) {
        if (merchant) {
          percent.label("Of " + cat.name);
          cat = _.find(this.getMerchants(), function (m) {
            return m.name === merchant;
          });
        }
      }

      if (cat) {
        percent.percent(cat.percent);
        average.amount(cat.average);
      } else {
        percent.percent(0);
        average.amount(0);
      }
    } else if (!categoryId && this.selectedCategory) {
      percent.percent(0);
      average.amount(0);
    } else {
      percent.hide();
      average.hide();
      // WEB-678: if top level was not initially rendered, render the cash legend as we drill up
      this.renderCashLegend(this.dataTransactionsCurrPeriod);

      this.getCashLegendCell("income").show();
      this.getCashLegendCell("expense").show();
      this.getCashLegendCell("total").show();
    }
  },

  onTransactionsReceived: function () {
    CashFlowBaseView.prototype.onTransactionsReceived.apply(this, arguments);
    this.updateViewWithData();
  },

  updateViewWithData: function () {
    if (this.getDrilldownLevel() === 0) {
      this.renderDonut(this.dataTransactionsCurrPeriod);
    } else if (!_.isEmpty(this.dataTransactionsCurrPeriod)) {
      this.expenseCategories =
        this.dataTransactionsCurrPeriod.expenseCategories.slice();
      this.expenseCategories = this.filterArrayByAmount(this.expenseCategories);
      this.expenseCategories = this.orderArrayByAmount(this.expenseCategories);

      this.incomeCategories =
        this.dataTransactionsCurrPeriod.incomeCategories.slice();
      this.incomeCategories = this.filterArrayByAmount(this.incomeCategories);
      this.incomeCategories = this.orderArrayByAmount(this.incomeCategories);

      this.setCurrentCategories(
        this.incomeExpenseMode === "income"
          ? this.incomeCategories
          : this.expenseCategories
      );
      var selectedCategory = _.find(
        this.getCurrentCategories(),
        function (category) {
          return category.transactionCategoryId === this.selectedCategoryId;
        }.bind(this)
      );
      var drillUp = "none";

      // zero state logic at selected category level
      if (this.getCurrentCategories().length === 0 || !selectedCategory) {
        this.selectedCategoryId = undefined;
        if (!this.onInitDrilldown) {
          drillUp = 0;
        }
      } else if (!this.selectedCategoryId) {
        this.selectedCategoryId = this.selectedCategory.transactionCategoryId;
      } else if (selectedCategory) {
        this.selectedCategory = selectedCategory;
      }

      var merchantList =
        this.incomeExpenseMode === "income"
          ? this.dataTransactionsCurrPeriod.incomeMerchants
          : this.dataTransactionsCurrPeriod.expenseMerchants;

      var categoryMerchants = merchantList[this.selectedCategoryId];
      categoryMerchants = this.filterArrayByAmount(categoryMerchants);
      categoryMerchants = this.orderArrayByAmount(categoryMerchants);
      this.setMerchants(categoryMerchants);

      // zero state logic at selected merchant level
      if (this.getDrilldownLevel() === DRILL_DOWN_LEVEL_MERCHANT) {
        if (categoryMerchants.length === 0 || !this.selectedCategoryId) {
          this.selectedMerchantId = undefined;
          if (!this.onInitDrilldown && this.selectedCategoryId) {
            drillUp = 1;
          }
        } else if (!this.selectedMerchantId) {
          this.selectedMerchantId = this.selectedMerchant
            ? this.selectedMerchant.id
            : "";
          this.selectedMerchant = this.getMerchant(this.selectedMerchantId);
        } else if (this.selectedMerchantId) {
          this.selectedMerchant = this.getMerchant(this.selectedMerchantId);
          if (!this.selectedMerchant) {
            this.selectedMerchantId = undefined;
            drillUp = 1;
          }
        }
      }

      if (this.onInitDrilldown) {
        this.selectedCategory = this.getCategory(this.selectedCategoryId);

        if (this.selectedCategory) {
          this.renderDonut(this.dataTransactionsCurrPeriod, false);
        } else {
          this.setDrilldownLevel(0);
          this.renderDonut(this.dataTransactionsCurrPeriod);
          replaceHash(`${this.options.baseUrl}/${this.incomeExpenseMode}`);
        }

        if (
          this.selectedCategory ||
          (this.getDrilldownLevel() === DRILL_DOWN_LEVEL_MERCHANT &&
            this.selectedMerchant)
        ) {
          this.donut.nonAnimateDrillDownToMerchantDonut(
            this.selectedCategoryId,
            this.getMerchants(),
            this.incomeExpenseMode
          );
          this.renderBreadcrumb(this.getDrilldownLevel());

          if (this.getDrilldownLevel() === 0) {
            this.renderLegend(this.getCurrentCategories());
          } else {
            this.renderLegend(this.getMerchants());
          }

          this.renderDonutLabels();

          if (!this.onInitDrilldown) {
            this.emitCategoryState();
          }
        }
        this.onInitDrilldown = false;
      } else {
        this.donut.nonAnimateUpdateCategoryDonut(
          this.getCurrentCategories(),
          this.incomeExpenseMode
        );
        if (drillUp === 1) {
          this.setDrilldownLevel(1);
        }

        // `donut.updateMerchantDonut` triggers `drillDownToMerchantComplete` event when the animation completes.
        // Wait for it to avoid race condition in `updateView`.
        this.donut.once(
          "drillDownToMerchantComplete",
          function () {
            if (drillUp === 0) {
              replaceHash(`${this.options.baseUrl}/${this.incomeExpenseMode}`);
            } else if (drillUp === 1) {
              replaceHash(
                `${this.options.baseUrl}/${this.incomeExpenseMode}/${this.selectedCategoryId}`
              );
            }
          }.bind(this)
        );
        this.donut.updateMerchantDonut(
          this.getMerchants(),
          this.incomeExpenseMode,
          this.getDrilldownLevel()
        );
      }
    }
  },
});
