import _ from "underscore";

// Disable `Tax Bill Reduced` for now because the API projected number is per year only
const RECOMMENDATION_KEYS = [
  "FUND_FEE",
  "IDLE_CASH",
  "ASSET_LOCATION" /* 'HARVESTING_BENEFIT' */,
];
const KEY_HARVESTING_BENEFIT = "HARVESTING_BENEFIT";
const KEY_IDLE_CASH = "IDLE_CASH";
const DEFAULT_LIFE_EXPECTANCY = 92;

const SELLING_POINT_LABELS_MAP = {
  FUND_FEE: "Fees Saved",
  IDLE_CASH: "Portfolio Growth",
  ASSET_LOCATION: "Asset Location",
  HARVESTING_BENEFIT: "Tax Bill Reduced",
};

export function getTransferableAccounts(accounts) {
  const results = _.filter(accounts, (account) => {
    const isTransferEligible = account.isTransferEligible;
    const isClosedAccount = Boolean(account.closedDate);
    const isEducationalAccount = account.accountTypeGroup === "EDUCATIONAL";
    const isNotZeroBalance = account.balance > 1;
    return (
      isTransferEligible &&
      !isClosedAccount &&
      !isEducationalAccount &&
      isNotZeroBalance
    );
  });
  return _.sortBy(results, "balance").reverse();
}

export function removeUnusedAccountProperties(accounts) {
  return _.map(accounts, (account) => ({
    accountId: account.accountId,
    firmName: account.firmName,
    originalName: account.originalName,
    userAccountId: account.userAccountId,
    logoPath: account.logoPath,
    balance: account.balance,
  }));
}

export function parseGoals(personDetails, myLifeGoalsData) {
  const goals = [];
  const currentAge = personDetails.age;
  const retirementAge = personDetails.retirementAge;
  const lifeExpectancy =
    personDetails.lifeExpectancy || DEFAULT_LIFE_EXPECTANCY;
  const retirementSpendingGoal = _.find(myLifeGoalsData.goals, (goal) => {
    return goal.goalCategoryKey === "RETIREMENT_SPENDING";
  });
  const hasRetired = currentAge >= retirementAge;
  if (
    !retirementAge ||
    !lifeExpectancy ||
    !retirementSpendingGoal ||
    hasRetired
  ) {
    return null;
  }

  const retirementSpendingGoalAmount = retirementSpendingGoal.annualAmount;
  goals.push({
    amountNeeded:
      retirementSpendingGoalAmount * (lifeExpectancy - retirementAge),
    name: "Retirement Spending",
  });

  return goals;
}

export function getProjectedResultAmount(myLifeData) {
  const thermometer =
    (myLifeData && myLifeData.thermometer && myLifeData.thermometer.data) || {};
  let projectedResultAmount = thermometer.projectedTargetPortfolioValue;
  if (
    !projectedResultAmount ||
    isNaN(projectedResultAmount) ||
    projectedResultAmount < 0
  ) {
    projectedResultAmount = 0;
  }
  return projectedResultAmount;
}

export function getSellingPoints(recommendationData, retirementAge) {
  const sellingPoints = [];
  RECOMMENDATION_KEYS.forEach((key) => {
    const recommendation = recommendationData[key];
    if (recommendation) {
      let amount = 0;
      if (key === KEY_HARVESTING_BENEFIT) {
        amount =
          recommendation.subheader &&
          recommendation.subheader.annualBenefitHigh;
      } else {
        const seriesDataArrayIndex = key === KEY_IDLE_CASH ? 1 : 0;
        const seriesData =
          recommendation.series &&
          recommendation.series[seriesDataArrayIndex] &&
          recommendation.series[seriesDataArrayIndex].data;
        const retirementAgeData = _.find(seriesData, (data) => {
          return data.x === retirementAge;
        });
        amount = retirementAgeData && retirementAgeData.y;
      }
      sellingPoints.push({
        amount,
        label: SELLING_POINT_LABELS_MAP[key],
        summary: recommendation.takeaway,
        details: recommendation.explanation,
      });
    }
  });
  return sellingPoints;
}

export function parseProjections(
  myLifeData,
  recommendationData,
  initialInvestmentAmount
) {
  const retirementAge =
    myLifeData && myLifeData.profile && myLifeData.profile.retirementAge;
  const isZeroInitialInvestment = !initialInvestmentAmount;
  return {
    retirementAge,
    initialInvestmentAmount,
    projectedResultAmount: isZeroInitialInvestment
      ? 0
      : getProjectedResultAmount(myLifeData),
    sellingPoints: isZeroInitialInvestment
      ? []
      : getSellingPoints(recommendationData, retirementAge),
    disclaimerText: myLifeData.footer,
  };
}

export function calculateTotalAmounts(selectedAccounts) {
  selectedAccounts = selectedAccounts || new Set();
  const selectedAccountsArray = [...selectedAccounts]; // Convert from Set to regular Array
  return selectedAccountsArray.reduce(
    (acc, account) => acc + parseFloat(account.balance),
    0
  );
}

export default {
  getTransferableAccounts,
  removeUnusedAccountProperties,
  parseGoals,
  parseProjections,
  calculateTotalAmounts,
};
