import PropTypes from "prop-types";
import React from "react";
import mixpanel from "mixpanel";
import { formatNumber } from "libs/pcap/utils/format";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import { getEventData } from "components/opportunitiesSummary/InsightsUtils";

class RetirementPlanReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expectedValue:
        this.props.message.resources[0] &&
        formatNumber(
          this.props.message.resources[0].jsonContent.projectedValue,
          0
        ),
    };
  }

  generateSteps() {
    const steps = [
      "Added retirement account",
      "Added spending goals",
      "Review retirement plan",
    ];
    return steps.map((step, i) => {
      return (
        <div
          key={`step-${i}`}
          className="retirement-plan-review-insight__step-container"
        >
          <div className="retirement-plan-review-insight__step-name">
            Step {i + 1}
          </div>
          <div className="retirement-plan-review-insight__step-description">
            {step}
          </div>
        </div>
      );
    });
  }

  componentDidMount() {
    mixpanel.trackEvent(
      `View Insight`,
      getEventData({ message: this.props.message })
    );

    if (this.props.isFirstRendered) {
      this.props.markAsImpressed(this.props.message.userMessageId);
    } else {
      this.props.markAsViewed(this.props.message.userMessageId);
    }
  }

  render() {
    const { message = {}, markAsViewed } = this.props;
    return (
      <div className="insight__message">
        <div
          className="insight__header qa-insight-header u-sentence-case"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div className="retirement-plan-review-insight__expected-value-title u-sentence-case">
          Expected Value
        </div>
        <div className="retirement-plan-review-insight__expected-value">
          ${this.state.expectedValue}
        </div>
        <div>{this.generateSteps()}</div>
        <div
          className="insight__summary"
          dangerouslySetInnerHTML={{ __html: message.summary }}
        />
        <div className="insight__buttons">
          {getActionButtons({
            message,
            markAsViewed,
            appointmentReason: "Retirement Plan Review",
          })}
        </div>
      </div>
    );
  }
}

RetirementPlanReview.propTypes = {
  message: PropTypes.object,
  markAsViewed: PropTypes.func,
  markAsImpressed: PropTypes.func,
  isFirstRendered: PropTypes.bool,
};

export default RetirementPlanReview;
