import { dateStringToStruct } from "libs/pcap/utils/date2";
import { isJointAccount } from "utils/account";

export default function (model) {
  let rs = Object.assign({}, model);

  if (model["trust.birthday"]) {
    rs["trust.birthday"] = JSON.stringify(
      dateStringToStruct(model["trust.birthday"])
    );
  }

  if (model["trust.taxIdType"] === "SSN") {
    delete rs["trust.taxId.taxId1"];
    delete rs["trust.taxId.taxId2"];
  }

  if (model["trust.taxIdType"] === "TIN") {
    delete rs["trust.ssn.ssn1"];
    delete rs["trust.ssn.ssn2"];
    delete rs["trust.ssn.ssn3"];
  }

  if (model["trust.trustees"]) {
    rs["trust.trustees"] = JSON.stringify(model["trust.trustees"]);
  }

  if (model.contributionType) {
    rs.contributionType = JSON.stringify(model.contributionType);
  }

  if (!isJointAccount(model.ownershipType)) {
    delete rs.secondaryOwnerId;
  }

  return rs;
}
