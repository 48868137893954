// Personal Capital utility class for date-related methods and properties
// Adrian

// ------------ DEPRECATED ---------------
// Use date2.js

import moment from "moment";

import _ from "underscore";
var DateUtil = {};

DateUtil.DAY_INTERVAL = "DAY";
DateUtil.WEEK_INTERVAL = "WEEK";
DateUtil.MONTH_INTERVAL = "MONTH";

DateUtil.DISPLAY_FORMAT = "M/D/YYYY";
DateUtil.DISPLAY_LONG_FORMAT = "M/D/YYYY h:mm a";
DateUtil.API_FORMAT = "YYYY-MM-DD";
DateUtil.CHART_MONTH_INTERVAL_FORMAT = "MMM";
DateUtil.CHART_YEAR_INTERVAL_FORMAT = "[']YY";
DateUtil.CHART_MONTH_YEAR_FORMAT = "MMMM, YYYY";
DateUtil.CHART_MONTH_DATE_YEAR_FORMAT = "MMM DD, YYYY";

const FOURTEEN_DAYS = 14;
const SIXTY_DAYS = 60;

DateUtil.nowString = function () {
  return moment().format(DateUtil.DISPLAY_FORMAT);
};

/*
 * This method recommends an interval value based on the number of days in the provided range.
 *
 * @param	startDate A string representation of the start date in the form of "MM/DD/YY"
 * @param	endDate A string representation of the end date in the form of "MM/DD/YY"
 *
 * @return	interval A string that is either DAY, WEEK, or MONTH.
 */
DateUtil.getIntervalFromRange = function (startDate, endDate) {
  startDate = moment(startDate, DateUtil.DISPLAY_FORMAT);
  endDate = moment(endDate, DateUtil.DISPLAY_FORMAT);
  var days = endDate.diff(startDate, "days");
  days = Math.abs(days);
  var interval;
  if (days < FOURTEEN_DAYS) {
    interval = DateUtil.DAY_INTERVAL;
  } else if (days < SIXTY_DAYS) {
    interval = DateUtil.WEEK_INTERVAL;
  } else {
    interval = DateUtil.MONTH_INTERVAL;
  }

  return interval;
};

/*
 * This method compares the given date in relation to today.  If the date value is the
 * same as today, only the formatted time value is returned.  If it's older than today,
 * the return value includes the date.
 *
 * @param	date/number Either a date object or a number that represents unix timestamp.
 *
 * @return	A string that is contains the time and possibly the date, or an empty string if input is invalid.
 */
DateUtil.formatDateTime = function (date) {
  var dateWrapper, result;

  if (_.isNumber(date)) {
    dateWrapper = moment(date);
  } else if (_.isDate(date)) {
    dateWrapper = moment(date);
  } else {
    result = "";
  }

  if (dateWrapper) {
    var today = moment();

    if (moment(dateWrapper).isSame(today, "day")) {
      result = dateWrapper.format("h:mmA");
    } else {
      result = dateWrapper.format("M/D/YYYY h:mmA");
    }
  }

  return result;
};

export default DateUtil;

export const {
  DAY_INTERVAL,
  WEEK_INTERVAL,
  MONTH_INTERVAL,
  DISPLAY_FORMAT,
  DISPLAY_LONG_FORMAT,
  API_FORMAT,
  CHART_MONTH_INTERVAL_FORMAT,
  CHART_YEAR_INTERVAL_FORMAT,
  CHART_MONTH_YEAR_FORMAT,
  CHART_MONTH_DATE_YEAR_FORMAT,
  nowString,
  getIntervalFromRange,
  formatDateTime,
} = DateUtil;
