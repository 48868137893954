/* eslint-disable no-invalid-this */
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import log from "log";
import { isEmpowerPaeMode } from "views/modules/sidebar/utils/accountUtils";

var Account = Backbone.Model.extend({
  idAttribute: "accountId",
  refresh: function () {
    Services.Accounts.refresh(
      {
        accountId: this.get("accountId"),
      },
      this.onRefreshed,
      this
    );
  },
  onRefreshed: function (err, data) {
    if (err) {
      log(err);
    } else if (
      data &&
      data.spData &&
      data.spData.accounts &&
      data.spData.accounts.length > 0
    ) {
      this.processAccountUpdate(data.spData.accounts);
    }
  },
  update: function (attributes, callback, scope) {
    attributes = attributes || {};
    attributes.isManual = this.get("isManual");
    attributes.isManualPortfolio = this.get("isManualPortfolio");
    attributes.isEsog = this.get("isEsog");
    attributes.accountId = this.get("accountId");
    // DIRTY API MASSAGE HACK
    if (attributes.isEsog === true) {
      attributes.accountType = this.get("productType");
      if (
        !_.isUndefined(attributes.stopVestingDate) &&
        attributes.stopVestingDate == null
      ) {
        delete attributes.stopVestingDate;
      }
    }
    // MORE DIRTY API MASSAGE HACKS
    if (
      attributes.isManual === true &&
      attributes.isEsog === false &&
      attributes.isManualPortfolio === false
    ) {
      attributes.accountType = this.get("productType");
      attributes.productType = this.get("productType");
    }

    var updateCallback = function () {
      this.onUpdated.apply(this, arguments);
      if (callback) {
        callback.apply(scope, arguments);
      }
    };

    if (attributes.isManual)
      Services.Accounts.updateManual(attributes, updateCallback, this);
    else Services.Accounts.update(attributes, updateCallback, this);
  },
  onUpdated: function (err, data) {
    if (err) {
      log(err);
    } else if (
      data &&
      data.spData &&
      data.spData.accounts &&
      data.spData.accounts.length > 0
    ) {
      this.processAccountUpdate(data.spData.accounts);
    }
  },
  authorize: function (credentials, hasUpdateCredentials, callback, scope) {
    Services.Accounts.updateCredentials(
      {
        accountId: this.get("accountId"),
        credentials: JSON.stringify(credentials),
        hasUpdateCredentials: hasUpdateCredentials,
      },
      function () {
        this.onAuthorized.apply(this, arguments);
        if (callback) {
          callback.apply(scope, arguments);
        }
      },
      this
    );
  },
  onAuthorized: function (err, data) {
    if (err) {
      log(err);
    } else if (
      data &&
      data.spData &&
      data.spData.accounts &&
      data.spData.accounts.length > 0
    ) {
      this.processAccountUpdate(data.spData.accounts);
    }
  },
  processAccountUpdate: function (accounts) {
    var accountId = this.get("accountId");
    var account = _.find(accounts, function (account) {
      // eslint-disable-next-line eqeqeq
      return accountId == account.accountId;
    });
    if (account) {
      this.set(account);
    }
  },
  remove: function (callback, scope) {
    Services.Accounts.remove(
      {
        accountIds: JSON.stringify([this.get("accountId")]),
      },
      function () {
        if (callback) {
          callback.apply(scope, arguments);
        }
        this.onRemoved.apply(this, arguments);
      },
      this
    );
  },
  onRemoved: function () {
    this.trigger("removed", this);
  },
  isPlainManualAccount: function () {
    return (
      this.get("isManual") === true &&
      this.get("isManualPortfolio") === false &&
      this.get("isEsog") === false
    );
  },
});

// Hide outside accounts for Empower app running in "emulator" (support) mode
if (isEmpowerPaeMode()) {
  Account.PRODUCT_TYPES = { EMPOWER: "EMPOWER" };
} else {
  Account.PRODUCT_TYPES = {
    ...(IS_EMPOWER ? { EMPOWER: "EMPOWER" } : {}), // Empower accounts should show at the top, in empower app.
    IN_PROGRESS: "IN_PROGRESS",
    CASH: "BANK",
    INVESTMENT: "INVESTMENT",
    CREDIT: "CREDIT_CARD",
    LOAN: "LOAN",
    MORTGAGE: "MORTGAGE",
    OTHER_ASSET: "OTHER_ASSETS",
    OTHER_LIABILITY: "OTHER_LIABILITIES",
  };
}

Account.PRODUCT_TYPES_LABELS = {
  IN_PROGRESS: "Still importing",
  BANK: "Cash",
  INVESTMENT: "Investment",
  CREDIT_CARD: "Credit",
  LOAN: "Loan",
  MORTGAGE: "Mortgage",
  OTHER_ASSETS: "Other asset",
  OTHER_LIABILITIES: "Other liability",
  EMPOWER: "Empower accounts",
  NEEDS_ATTENTION: "Needs attention",
  RESUME_LINKING: "Resume linking",
};

export default Account;
