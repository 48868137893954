import _ from "underscore";
import BaseStateModel from "stateModels/baseStateModel";
function CashFlowState() {
  this.baseUrl = "/cash-flow";
  this.internalStateParams = ["startDate", "endDate", "userAccountIds"];

  // Define default values
  this.userAccountIds = "all";
}

CashFlowState.prototype = new BaseStateModel();
CashFlowState.prototype.constructor = CashFlowState;

export default CashFlowState;
