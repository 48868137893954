/* eslint-disable no-console */

import { isArray, isEmpty } from "underscore";
import deepCopy from "deep-copy";

/**
 * A filter function to be called with datapoints, search text and a configuration Array containing the searchAccessors to match the datapoint attributes against the input text.
 *
 *
 * @param {String} text - The text to look out in the datapoint attributes
 * @param {Array} transactions - The collection to filter
 * @param {Array} searchConfig - An array of `accessors` functions. Each accessor will be called with the datapoint as parameter and should return an string to match the
 *                               `text` passed by param. This comparation is not case sensitive.
 * @returns {Array} transactions
 */
export default function transactionsGridSearchIterator(
  text,
  transactions,
  searchConfig
) {
  if (
    text &&
    transactions &&
    searchConfig &&
    isArray(transactions) &&
    isArray(searchConfig)
  ) {
    const hasSplits = transactions.some((d) => !isEmpty(d.splits));

    // Clone when at least one transaction has splits, this
    // will make sure that when search is cleared filtered splits are shown again.
    let filteredTransactions = hasSplits
      ? deepCopy(transactions)
      : transactions;

    filteredTransactions = filteredTransactions.filter((transaction) => {
      const values = searchConfig.map((accessor) => accessor(transaction));
      if (transaction.isBeingMultiEdited) {
        return true;
      }
      return values.some((value) => {
        return String(value).toLowerCase().includes(text.toLowerCase());
      });
    });

    const transactionsWithSplits = filteredTransactions.filter(
      (d) => !isEmpty(d.splits)
    );

    // For all transactions with splits, filter out splits
    // based on search criteria.
    if (!isEmpty(transactionsWithSplits)) {
      transactionsWithSplits.forEach((t) => {
        t.splits = t.splits.map((split) => {
          const values = searchConfig.map((accessor) => accessor(split, t));
          split.filterSplit = !values.some((value) => {
            return String(value).toLowerCase().includes(text.toLowerCase());
          });
          return split;
        });
      });
    }

    // This will filter out a transaction that has splits but
    // all the splits are filtered out by the search.
    // This will avoid rendering parent transaction when all the splits are
    // filtered out of a transaction.
    filteredTransactions = filteredTransactions.filter(
      (ft) => !ft.hasSplits || (ft.hasSplits && !isEmpty(ft.splits))
    );
    return filteredTransactions;
  }

  if (!isArray(searchConfig)) {
    console.error(
      "Warning: Using transactionsGridSearchIterator with incorrect parameters, searchConfig should be array."
    );
  }
  if (transactions !== undefined && !isArray(transactions)) {
    console.error(
      "Warning: Using transactionsGridSearchIterator with incorrect parameters, transactions should be array or undefined."
    );
  }

  return transactions;
}
