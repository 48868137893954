import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import Loading from "components/common/loading/Loading";
import Message from "components/common/Message";
import { isEqual, noop } from "underscore";

/**
 * `HoldingsFetcher` Makes a request to fetch and watch holdings based on the prop.userAccountIds and
 * renders the component passed in props.Component passing the fetched holdings from the API in the holdings prop.
 * It also proxies all the other props passed to this by using the spread operator ie. <Component {...this.props} holdings....
 *
 * If holdings are provided by props, this component will throw an alert.
 *
 */
export default class HoldingsFetcher extends React.Component {
  constructor(props) {
    super(props);
    if (props.userAccountIds && props.holdings) {
      //eslint-disable-line react/prop-types
      console.warn(
        "HoldingsFetcher was initialized with an" + //eslint-disable-line no-console
          " array of holdings and a userAccountIds, this is an invalid operation since passing " +
          "userAccountIds will make this component fetch its own holdings. These props are exclusive from each other"
      );
    }
    this.state = {
      loading: true,
      errors: [],
    };
  }

  componentDidMount() {
    this.fetchAndWatchHoldings();
  }

  componentDidUpdate() {
    if (
      !isEqual(this.state.prevPropUserAccountIds, this.props.userAccountIds)
    ) {
      this.fetchAndWatchHoldings();
    }
  }

  componentWillUnmount() {
    if (this.watchedHoldingsId) {
      Services.Holdings.get.unwatch(this.watchedHoldingsId);
    }
  }

  fetchAndWatchHoldings() {
    if (this.watchedHoldingsId) {
      Services.Holdings.get.unwatch(this.watchedHoldingsId);
      delete this.watchedHoldingsId;
    }
    this.setState({
      loading: true,
      prevPropUserAccountIds: this.props.userAccountIds,
    });
    this.watchedHoldingsId = Services.Holdings.get.watch(
      {
        userAccountIds: this.props.userAccountIds,
      },
      (err, holdingsResponse) => {
        const errors = parseResponseErrors(err, holdingsResponse);
        if (errors) {
          this.setState({
            errors,
            loading: false,
          });
          return;
        }

        this.setState(
          {
            holdings: holdingsResponse.spData.holdings || [],
            loading: false,
          },
          this.props.onFinishLoading
        );
      }
    );
  }

  render() {
    if (this.state.loading && this.props.showLoader) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else if (this.state.loading && !this.props.showLoader) {
      return <div />;
    }

    if (this.state.errors && this.state.errors.length) {
      return <Message severity="error" messages={this.state.errors} />;
    }

    const { Component, dataFilter } = this.props;
    return (
      <Component
        {...this.props}
        holdings={
          dataFilter ? dataFilter(this.state.holdings) : this.state.holdings
        }
      />
    );
  }
}

HoldingsFetcher.defaultProps = {
  userAccountIds: [],
  onFinishLoading: noop,
  showLoader: false,
  dataFilter: undefined,
};

HoldingsFetcher.propTypes = {
  userAccountIds: PropTypes.string,
  Component: PropTypes.elementType.isRequired,
  onFinishLoading: PropTypes.func,
  dataFilter: PropTypes.func,
  showLoader: PropTypes.bool,
};
