import PropTypes from "prop-types";
import React from "react";
import _, { noop } from "underscore";
import moment from "moment";
import Services from "services";
import CashFlowSummary from "components/cashFlowSummary2/CashFlowSummary";
import parseResponseErrors from "libs/pcap/utils/response";
import { formatCurrency } from "libs/pcap/utils/format";
import { API_FORMAT } from "libs/pcap/utils/date2";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import { getTransactionSummaries } from "libs/pcap/utils/getTransactionSummaries";
import deepCopy from "deep-copy";
import ZeroStateWidget from "../dashboard/ZeroStateWidget";

const PROPER_DATE_FORMAT = API_FORMAT;
const NUMBER_OF_CATEGORIES_TO_DISPLAY = 6;

export default class CashFlowSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.watcherIds = [];
    this.state = {
      loading: true,
      errors: null,
    };
  }

  getFilterForThisYear() {
    return {
      startDate: moment().startOf("year").format(PROPER_DATE_FORMAT),
      endDate: moment().format(PROPER_DATE_FORMAT),
      includeTransactions: false,
      accountIds: this.accountIds,
    };
  }

  getFilterForCurrentMonth() {
    let endDate = moment();
    return {
      startDate: endDate.clone().startOf("month").format(PROPER_DATE_FORMAT),
      endDate: endDate.format(PROPER_DATE_FORMAT),
      accountIds: this.accountIds,
    };
  }

  getFilterForPreviousMonth() {
    let lastMonth = moment().subtract(1, "month").startOf("month");
    return {
      startDate: lastMonth.format(PROPER_DATE_FORMAT),
      endDate: lastMonth.clone().endOf("month").format(PROPER_DATE_FORMAT),
      accountIds: this.accountIds,
    };
  }

  fetchTransactions(options) {
    return makeCancelablePromise(
      new Promise((resolve, reject) => {
        Services.Transactions.get(
          {
            startDate: options.startDate,
            endDate: options.endDate,
            userAccountIds: JSON.stringify(options.accountIds),
          },
          (err, response) => {
            const errors = parseResponseErrors(err, response);
            if (errors) {
              reject(errors);
              return;
            }

            resolve(deepCopy(response.spData));
          },
          this
        );
      })
    );
  }

  fetchTransactionsForCurrentMonth() {
    return this.fetchTransactions(this.getFilterForCurrentMonth());
  }

  fetchTransactionsForPreviousMonth() {
    return this.fetchTransactions(this.getFilterForPreviousMonth());
  }

  fetchCashFlowForYear() {
    return this.fetchTransactions(this.getFilterForThisYear());
  }

  fetchTransactionCategories() {
    return makeCancelablePromise(
      new Promise((resolve, reject) => {
        Services.TransactionCategories.get((err, response) => {
          const errors = parseResponseErrors(err, response);
          if (errors) {
            reject(errors);
            return;
          }
          resolve(response.spData);
        }, this);
      })
    );
  }

  getCashFlow(data, transactionCategories) {
    if (!data) {
      return {};
    }
    const { transactions = [], startDate, endDate } = data;
    return getTransactionSummaries({
      transactions,
      startDate,
      endDate,
      categories: transactionCategories,
      intervalType: "DAY",
      includeAggregates: true,
      includeCategoryAggregates: true,
      includeCashFlow: true,
    }).cashFlowSummary;
  }

  getYTDTakeaway(ytd) {
    if (!ytd) {
      return;
    }
    let ytdCashFlow = ytd.netCashFlow,
      takeAway;

    if (ytdCashFlow > 0) {
      takeAway = _.template("Up <% print(value) %> so far this year.")({
        value: formatCurrency(ytdCashFlow, 0),
      });
    } else if (ytdCashFlow < 0) {
      takeAway = _.template("Down <% print(value) %> so far this year.")({
        value: formatCurrency(-ytdCashFlow, 0),
      });
    }
    return takeAway;
  }

  getTopCategories(categories) {
    let orderedCategories = _.sortBy(categories, "amount").reverse(),
      topCategories = orderedCategories;

    if (orderedCategories.length > NUMBER_OF_CATEGORIES_TO_DISPLAY) {
      let allOtherCategories = _.rest(
        orderedCategories,
        NUMBER_OF_CATEGORIES_TO_DISPLAY - 1
      );

      topCategories = _.first(
        orderedCategories,
        NUMBER_OF_CATEGORIES_TO_DISPLAY - 1
      );
      topCategories.push({
        name: "Other",
        amount: allOtherCategories.reduce((initialValue, category) => {
          return initialValue + category.amount;
        }, 0),
      });
    }

    return topCategories;
  }

  getBarStacks(type, categories, remaining) {
    let stacks = {
        type: type,
      },
      topCategories = this.getTopCategories(categories);

    _.each(topCategories, function (category) {
      stacks[category.name] = category.amount;
    });
    stacks.remaining = remaining;
    return stacks;
  }

  componentDidMount() {
    this.accountsWatcher = Services.Accounts.get.watch(
      {},
      (err, response) => {
        const errors = parseResponseErrors(err, response);
        if (errors) {
          this.setState(
            {
              loading: false,
              errors: errors,
            },
            this.props.onDCDashboardComponentLoaded
          );
          return;
        }

        this.accountIds =
          response.spData && response.spData.accounts
            ? response.spData.accounts
                .filter((a) => !a.isExcludeFromHousehold && a.userAccountId)
                .map((a) => a.userAccountId)
            : [];
        this.cancelablePrevMonthTranPromise =
          this.fetchTransactionsForPreviousMonth();
        this.cancelableCurMonthTranPromise =
          this.fetchTransactionsForCurrentMonth();
        this.cancelableYearCashFlowPromise = this.fetchCashFlowForYear();
        this.cancelableCategoryPromise = this.fetchTransactionCategories();

        return Promise.all([
          this.cancelablePrevMonthTranPromise.promise,
          this.cancelableCurMonthTranPromise.promise,
          this.cancelableYearCashFlowPromise.promise,
          this.cancelableCategoryPromise.promise,
        ]).then(
          ([
            lastMonthTransactions,
            curMonthTransactions,
            ytdTransactions,
            transactionCategories,
          ]) => {
            this.onFetched(
              lastMonthTransactions,
              curMonthTransactions,
              ytdTransactions,
              transactionCategories
            );
          },
          (errors) => {
            if (
              (this.cancelableCategoryPromise &&
                this.cancelableCategoryPromise.isCanceled()) ||
              (this.cancelableCurMonthTranPromise &&
                this.cancelableCurMonthTranPromise.isCanceled()) ||
              (this.cancelablePrevMonthTranPromise &&
                this.cancelablePrevMonthTranPromise.isCanceled()) ||
              (this.cancelableYearCashFlowPromise &&
                this.cancelableYearCashFlowPromise.isCanceled())
            ) {
              return;
            }
            this.setState(
              {
                loading: false,
                errors: errors,
              },
              this.props.onDCDashboardComponentLoaded
            );
          }
        );
      },
      this
    );
  }

  componentWillUnmount() {
    if (this.cancelablePrevMonthTranPromise) {
      this.cancelablePrevMonthTranPromise.cancel();
    }
    if (this.cancelableCurMonthTranPromise) {
      this.cancelableCurMonthTranPromise.cancel();
    }
    if (this.cancelableYearCashFlowPromise) {
      this.cancelableYearCashFlowPromise.cancel();
    }
    if (this.cancelableCategoryPromise) {
      this.cancelableCategoryPromise.cancel();
    }
    Services.Accounts.get.unwatch(this.accountsWatcher);
    delete this.accountsWatcher;
  }

  onFetched(
    lastMonthTransactions,
    curMonthTransactions,
    ytdTransactions,
    transactionCategories
  ) {
    let lastMonthTransactionsCashFlow = this.getCashFlow(
        lastMonthTransactions,
        transactionCategories
      ),
      curMonthTransactionsCashFlow = this.getCashFlow(
        curMonthTransactions,
        transactionCategories
      ),
      ytdCashFlow = this.getCashFlow(ytdTransactions, transactionCategories),
      takeaway = this.getYTDTakeaway(ytdCashFlow),
      income = curMonthTransactionsCashFlow.moneyIn,
      expense = curMonthTransactionsCashFlow.moneyOut,
      incomeLast = lastMonthTransactionsCashFlow.moneyIn,
      expenseLast = lastMonthTransactionsCashFlow.moneyOut,
      maxAmount = Math.max(income, expense, incomeLast, expenseLast),
      incomeBarChartStacks = this.getBarStacks(
        "income",
        curMonthTransactionsCashFlow.incomeCategories,
        Math.abs(maxAmount - income)
      ),
      expenseBarChartStacks = this.getBarStacks(
        "expense",
        curMonthTransactionsCashFlow.expenseCategories,
        Math.abs(maxAmount - expense)
      );

    if (
      (this.cancelableCategoryPromise &&
        this.cancelableCategoryPromise.isCanceled()) ||
      (this.cancelableCurMonthTranPromise &&
        this.cancelableCurMonthTranPromise.isCanceled()) ||
      (this.cancelablePrevMonthTranPromise &&
        this.cancelablePrevMonthTranPromise.isCanceled()) ||
      (this.cancelableYearCashFlowPromise &&
        this.cancelableYearCashFlowPromise.isCanceled())
    ) {
      return;
    }

    this.setState(
      {
        lastMonthTransactionsCashFlow: lastMonthTransactionsCashFlow,
        curMonthTransactionsCashFlow: curMonthTransactionsCashFlow,
        ytdTransactions: ytdTransactions,
        transactionCategories: transactionCategories,
        value: curMonthTransactionsCashFlow.netCashFlow,
        dateFilter: {
          startDate: lastMonthTransactionsCashFlow.startDate,
          endDate: lastMonthTransactionsCashFlow.endDate,
        },
        income: income,
        expense: expense,
        incomeLast: incomeLast,
        expenseLast: expenseLast,
        incomeBarChartStacks: incomeBarChartStacks,
        expenseBarChartStacks: expenseBarChartStacks,
        takeaway: takeaway,
        loading: false,
        errors: null,
      },
      this.props.onDCDashboardComponentLoaded
    );
  }

  render() {
    let {
      loading,
      errors,
      value,
      dateFilter,
      chartData,
      takeaway,
      income,
      expense,
      incomeLast,
      expenseLast,
      incomeBarChartStacks,
      expenseBarChartStacks,
      transactionCategories,
    } = this.state;

    if (
      !loading &&
      !this.props.validBankOrCCAccount &&
      window.integratedSharedData
    ) {
      const translations = window.integratedSharedData?.translations;
      const zeroStateTranslations = translations?.get("zeroStateDashboard");

      return (
        <ZeroStateWidget
          displayName={zeroStateTranslations?.cashFlow?.title}
          cta={zeroStateTranslations?.cashFlow?.cta}
          link={"#/cash-flow"}
          label={zeroStateTranslations?.cashFlow?.label}
          tooltip={zeroStateTranslations?.cashFlow?.tooltip}
          buttonAlign={"center"}
          backgroundName="cashflow"
        />
      );
    }

    return (
      <CashFlowSummary
        loading={loading}
        errors={errors}
        value={value}
        dateFilter={dateFilter}
        chartData={chartData}
        takeaway={takeaway}
        income={income}
        expense={expense}
        incomeLast={incomeLast}
        expenseLast={expenseLast}
        incomeBarChartStacks={incomeBarChartStacks}
        expenseBarChartStacks={expenseBarChartStacks}
        transactionCategories={transactionCategories}
        ace={this.props.ace}
        labelText="This Month"
        barHeight={48}
      />
    );
  }
}

CashFlowSummaryContainer.propTypes = {
  onDCDashboardComponentLoaded: PropTypes.func,
  ace: PropTypes.object,
  validBankOrCCAccount: PropTypes.bool,
};

CashFlowSummaryContainer.defaultProps = {
  onDCDashboardComponentLoaded: noop,
  ace: undefined,
  validBankOrCCAccount: false,
};
