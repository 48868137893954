import PropTypes from "prop-types";
import React from "react";
import _, { noop } from "underscore";
import analytics from "analytics";
import mixpanel from "mixpanel";

/**
 * `Zero State` displays text and buttons handed from server.
 *
 */
export default class WidgetRowZeroState extends React.Component {
  trackButtonClick(label, index) {
    let clickNumberText = "";
    if (index) {
      clickNumberText = " " + (index + 1);
    }
    analytics.sendEngagementEvent(
      "Notification Action Click" + clickNumberText,
      this.props.message.template
    );
    if (this.props.sendInfoEvent) {
      analytics.sendInfoEvent(
        `Click ${label} for ${this.props.widgetName}`,
        this.props.widgetName
      );
    }
    mixpanel.trackEvent(
      `Click on Dashboard Zero State Notification '${label}' Button in ${this.props.widgetName}`,
      {
        component: this.props.widgetName,
      }
    );
  }

  buttonComponent(title, link, key) {
    return (
      <div key={key}>
        <a
          href={link}
          onClick={() => {
            this.trackButtonClick(title, key);
          }}
          className="pc-btn pc-btn--primary qa-dashboard-zero-state-action-button"
        >
          {title}
        </a>
      </div>
    );
  }

  componentDidMount() {
    analytics.sendEngagementEvent(
      "Viewed Dashboard Notification",
      this.props.message.template
    );
    mixpanel.trackEvent(
      "View Dashboard Zero State Notification in " + this.props.widgetName,
      {
        component: this.props.widgetName,
      }
    );
    this.props.onDCDashboardComponentLoaded();
  }

  render() {
    let buttons = _.map(this.props.message.action, (button, index) => {
      return this.buttonComponent(button.title, button.url, index);
    });

    return (
      <section className="dashboard-zero-state qa-dashboard-zero-state">
        <h3 className="dashboard-zero-state__title qa-dashboard-zero-state-title">
          {this.props.message.title}
        </h3>
        <p
          className="dashboard-zero-state__summary qa-dashboard-zero-state-summary"
          dangerouslySetInnerHTML={{ __html: this.props.message.summary }}
        />
        <div>{buttons}</div>
      </section>
    );
  }
}

WidgetRowZeroState.defaultProps = {
  message: undefined,
  widgetName: "",
  onDCDashboardComponentLoaded: noop,
  sendInfoEvent: false,
};

WidgetRowZeroState.propTypes = {
  message: PropTypes.object,
  widgetName: PropTypes.string,
  onDCDashboardComponentLoaded: PropTypes.func,
  sendInfoEvent: PropTypes.bool,
};
