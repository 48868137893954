import abFrameworkProperties from "./abFrameworkProperties";
import objectPath from "object-path";

const MAX_ATTEMPTS = 20;
const DELAY = 50;
const PATH = "isLoaded";

export default function getAbFrameworkPromise({
  path = PATH,
  maxAttempts = MAX_ATTEMPTS,
  delay = DELAY,
} = {}) {
  let currentAttemptCount = 1;
  return new Promise((resolve) => {
    if (
      objectPath.get(abFrameworkProperties, `${path}.isLoaded`) ||
      currentAttemptCount >= maxAttempts
    ) {
      resolve(objectPath.get(abFrameworkProperties, path) || {});
    } else {
      let intervalTimer = window.setInterval(() => {
        if (
          objectPath.get(abFrameworkProperties, `${path}.isLoaded`) ||
          currentAttemptCount >= maxAttempts
        ) {
          clearInterval(intervalTimer);
          resolve(objectPath.get(abFrameworkProperties, path) || {});
        }
        currentAttemptCount++;
      }, delay);
    }
  });
}
