/* eslint-disable sonarjs/no-duplicate-string, sonarjs/no-small-switch, sonarjs/no-collapsible-if, sonarjs/cognitive-complexity */

import $ from "jquery";
import Backbone from "backbone";
import sidebarModel from "models/sidebar";
import AccountsView from "views/modules/sidebar/components/accountsView";
import { MQ_BREAKPOINT_WIDE } from "constants/styles";
import TransitionEndEvents from "libs/pcap/utils/transitionEndEvent";
import PlannerControlContainer from "components/planner/common/PlannerControl/PlannerControlContainer";
import React from "react";
import ReactDOM from "react-dom";
import eventBus from "eventBus";
import { handleAmplitudeEvent } from "../../../empower/utils/eventUtils";

const bodyEl = $(document.body);
const SIDEBAR_ACCOUNTS = "ACCOUNTS";
const SIDEBAR_PLANS = "PLANS";

export default Backbone.View.extend({
  el: ".js-sidebar",

  events: {
    "click .js-action-sidebar-open": "open",
    "click .js-action-sidebar-collapse": "collapse",
    "click .js-action-sidebar-pin": "togglePin",
    "click .js-sidebar-tab": "onTabClick",
    "statusChanged #js-sidebar-view-container": "onStatusChange",
    [TransitionEndEvents + " .sidebar__body"]: "refreshLayout",
    "click .sidebar__add-account": "clickedAddAccount",
    "click .sidebar__networth-link": "clickedNetworth",
    "click .sidebar__add-account-button": "clickedAlinkAccount",
    "click .INVESTMENT .sidebar-account__header":
      "clickedINVESTMENTSidebarAccountHeader",
    "click .EMPOWER .sidebar-account__header":
      "clickedEMPOWERSidebarAccountHeader",
  },

  initialize: function () {
    this.changing = false;
    this.sidebarPinIcon = this.$(".js-action-sidebar-pin");
    this.collapsedErrorIcon = this.$(".js-logo__status");
    this.didUserPin = false;
    this.isActionRequired = false;
    this.isManuallyClosed = false;
    this.accountsView = new AccountsView(
      this,
      this.$("#js-sidebar-view-container")
    );
    this.accountsView.on("accountsFetched", this.dataFetched, this);
    this.render();

    if ($("#js-sidebar-plans-container").length === 0) {
      $("#js-sidebar-view-container").append(
        '<div id="js-sidebar-plans-container" class="is-hidden">'
      );
    }

    this.PLANS_SIDEBAR_NODE = document.getElementById(
      "js-sidebar-plans-container"
    );
    this.plansState = { activePlanId: null, disabled: false };

    const hash = window.location.hash;
    if (hash.match(/retirement-planner/) || hash.match(/education/)) {
      if (hash.match(/education/)) {
        this.plansState.disabled = true;
      }

      let planParam = hash.match(/planId=([0-9]+)/);
      if (planParam) {
        this.plansState.activePlanId = parseInt(planParam[1], 10);
      }

      this.showTabs();
      this.highlightTab("Scenarios");
      this.renderSidebarPlans();
    } else {
      this.renderSidebarAccounts();
    }

    //subscribe to events
    sidebarModel.on("requestState", this.onSidebarStateRequested, this);
    sidebarModel.on("change:state", this.onSidebarStateChanged, this);
    sidebarModel.on(
      "account:cancel account:authorize account:remove",
      this.tryOpen,
      this
    );
    sidebarModel.on("refreshScrollBar", this.updateScroller, this);
    eventBus.on("planner:firstusecomplete", this.initializePlansSidebar, this);
    eventBus.on("planner:alertprompt", this.renderSidebarPlans, this);
    eventBus.on("planner:forecastloading", this.renderSidebarPlans, this);

    $(".main-container").on(
      String(TransitionEndEvents),
      function (e) {
        this.refreshLayout(e);
      }.bind(this)
    );
  },

  highlightTab: function (tabName) {
    $(".js-sidebar-tab").removeClass("is-active");
    $('.js-sidebar-tab[data-id="' + tabName + '"]').addClass("is-active");
  },

  onTabClick: function (event) {
    $(".js-sidebar-tab").removeClass("is-active");
    $(event.target).addClass("is-active");

    if (event.target.dataset) {
      switch (event.target.dataset.id) {
        case "Accounts":
          this.renderSidebarAccounts();
          break;
        case "Scenarios":
          this.showPlansContainer();
          this.renderSidebarPlans();
          break;
        default:
      }
    }

    if (IS_IFRAMED) {
      if (this.currentView === SIDEBAR_ACCOUNTS) {
        this.accountsView.adjustScroller();
      }
    }
  },

  onStatusChange: function (event) {
    this.isActionRequired = event.status === "ACTION_REQUIRED";
    this.collapsedErrorIcon.toggleClass(
      "logo__status--visible",
      this.isActionRequired
    );
  },

  showTabs: function () {
    this.$(".sidebar__tabs").removeClass("is-hidden");
    this.tabsDisplayed = true;
    if (this.currentView === SIDEBAR_ACCOUNTS) {
      this.accountsView.adjustScroller();
    }
  },

  hideTabs: function () {
    this.$(".sidebar__tabs").addClass("is-hidden");
    this.tabsDisplayed = false;
    if (this.currentView === SIDEBAR_ACCOUNTS) {
      this.accountsView.adjustScroller();
    }
  },
  clickedNetworth: function () {
    if (IS_EMPOWER) {
      const eventBus = window.dashboardUtils
        ? window.dashboardUtils.eventBus
        : null;
      const eventName = "sidebar_networth_clicked_event";
      if (eventBus) {
        eventBus.dispatch(eventName);
        eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
            "select_button",
          event_properties: { selection: eventName },
        });
      }
    }
  },
  clickedAddAccount: function () {
    if (IS_EMPOWER) {
      const eventBus = window.dashboardUtils
        ? window.dashboardUtils.eventBus
        : null;
      const eventName = "sidebar_add_clicked_event";
      if (eventBus) {
        eventBus.dispatch(eventName);
        eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
            "select_button",
          event_properties: { selection: eventName },
        });
      }
    }
  },
  clickedAlinkAccount: function () {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "sidebar_link_another_account_clicked_event"
      );
      handleAmplitudeEvent(
        "SELECT_BUTTON",
        "sidebar_link_another_account_clicked_event"
      );
    }
  },
  clickedINVESTMENTSidebarAccountHeader: function () {
    if (IS_EMPOWER) {
      const eventBus = window.dashboardUtils
        ? window.dashboardUtils.eventBus
        : null;
      const eventName = "sidebar_investment_clicked_event";
      if (eventBus) {
        eventBus.dispatch(eventName);
        eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
            "select_button",
          event_properties: { selection: eventName },
        });
      }
    }
  },
  clickedEMPOWERSidebarAccountHeader: function () {
    if (IS_EMPOWER) {
      const eventBus = window.dashboardUtils
        ? window.dashboardUtils.eventBus
        : null;
      const eventName = "sidebar_account_summary_clicked_event";
      if (eventBus) {
        eventBus.dispatch(eventName);
        eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
            "select_button",
          event_properties: { selection: eventName },
        });
      }
    }
  },
  hasTabs: function () {
    return this.tabsDisplayed;
  },

  hidePlansSidebar: function () {
    this.hideTabs();
    this.unmountPlansSidebar();
    this.highlightTab("Accounts");
    this.renderSidebarAccounts();
  },

  onSidebarStateChanged: function () {
    this.changing = false;
  },

  dataFetched: function () {
    if (this.showCollapsedView) {
      return;
    }

    this.open();
  },

  onSidebarStateRequested: function (state) {
    if (!this.changing) {
      switch (state) {
        case sidebarModel.EXPANDED:
          if (sidebarModel.get("state") !== sidebarModel.EXPANDED) {
            this.changing = true;
            if (window.innerWidth <= MQ_BREAKPOINT_WIDE && !IS_EMPOWER) {
              this.pinSideBar();
            } else {
              this.expand();
            }
            if (this.isManuallyClosed) {
              this.isManuallyClosed = false;
            }
          }
          break;
        default:
      }
    }
  },

  removeInitialState: function () {
    if (typeof this.isPreviouslyOpened == "undefined") {
      this.isPreviouslyOpened = true;
    }
  },

  render: function () {
    return this;
  },

  togglePin: function (e) {
    e.preventDefault();

    this.didUserPin = !this.didUserPin;

    if (this.isPinned()) {
      this.unPinSideBar();
    } else {
      if (IS_EMPOWER) return;
      this.pinSideBar();
    }
  },

  pinSideBar: function () {
    if (!this.isPinned()) {
      bodyEl
        .removeClass("sidebar--collapsed sidebar--expanded")
        .toggleClass("sidebar--opened");
      if (this.sidebarPinIcon) {
        this.sidebarPinIcon.addClass("sidebar__pin--pinned");
      }
      sidebarModel.set({ state: sidebarModel.MINIFIED });
      this.removeInitialState();
    }
  },

  unPinSideBar: function () {
    this.didUserPin = false;
    this.sidebarPinIcon.removeClass("sidebar__pin--pinned");
    // Remove open so it collapses, this case only apply to small viewport
    // since the pin icon only show up on small breakpoint
    bodyEl.removeClass("sidebar--opened");
    this.tryOpen();
  },

  tryOpen: function () {
    if (!this.isAnyExtraFormVisible()) {
      // If it was pinned by the user
      if (this.isPinned()) {
        if (this.didUserPin && !IS_EMPOWER) {
          this.pinSideBar();
        } else {
          this.unPinSideBar();
        }
      }
      this.open();
    }
  },

  open: function (e) {
    if (this.isManuallyClosed) {
      this.isManuallyClosed = false;
    }

    // if triggered by a click, prevent navigation
    if (e) {
      e.preventDefault();
    }

    this.accountsView.setNetWorthEllipseState(true);

    bodyEl.removeClass("sidebar--collapsed sidebar--expanded");
    if (!this.isAnyExtraFormVisible()) {
      sidebarModel.set({ state: sidebarModel.MINIFIED });
    }

    this.removeInitialState();
  },

  isPinned: function () {
    return this.sidebarPinIcon.hasClass("sidebar__pin--pinned");
  },

  expand: function () {
    bodyEl
      .removeClass("sidebar--collapsed sidebar--opened")
      .addClass("sidebar--expanded");
    sidebarModel.set({ state: sidebarModel.EXPANDED });

    this.removeInitialState();
  },

  collapse: function (e) {
    // adds extra protection for empower to avoid expand and collapse functionality
    if (IS_EMPOWER) return;

    // When sidebar is manually closed once
    // Should not clear the flag unless the user manually opens it.
    this.isManuallyClosed = this.isManuallyClosed || Boolean(e);
    if (e) {
      e.preventDefault();
    }

    bodyEl
      .removeClass("sidebar--opened sidebar--expanded")
      .addClass("sidebar--collapsed");
    this.$(".js-action-sidebar-pin").removeClass("sidebar__pin--pinned");
    sidebarModel.set({ state: sidebarModel.COLLAPSED });
    this.removeInitialState();
  },

  toggleEditAccounts: function () {
    this.openIfClosed();

    if (this.currentView !== SIDEBAR_ACCOUNTS) {
      this.renderSidebarAccounts();
    }

    if (sidebarModel.get("state") === sidebarModel.MINIFIED) {
      this.$("#js-sidebar-accounts").addClass("editActivated");
      this.expand();
    } else {
      this.$("#js-sidebar-accounts").removeClass("editActivated");
      this.open();
    }
    this.removeInitialState();
  },

  openIfClosed: function () {
    if ($(document.body).hasClass("sidebar--collapsed")) {
      this.open();
    }
  },

  renderSidebarAccounts: function () {
    this.currentView = SIDEBAR_ACCOUNTS;
    this.PLANS_SIDEBAR_NODE.classList.add("is-hidden");
    this.accountsView.show();
  },

  showPlansContainer: function () {
    this.PLANS_SIDEBAR_NODE.classList.remove("is-hidden");
  },

  renderSidebarPlans: function (props) {
    this.currentView = SIDEBAR_PLANS;
    this.accountsView.hide();

    if (props) {
      this.plansState = Object.assign(this.plansState || {}, props);
    }

    if (!props || !props.isFirstUseCompleted) {
      this.plansState.isFirstUseCompleted = false;
    }

    ReactDOM.render(
      <PlannerControlContainer {...this.plansState} />,
      this.PLANS_SIDEBAR_NODE
    );
  },

  unmountPlansSidebar: function () {
    ReactDOM.unmountComponentAtNode(this.PLANS_SIDEBAR_NODE);
    this.plansState = {};
  },

  refreshLayout: function (e) {
    if ($(e.target).is(".sidebar__body, .main-container")) {
      if (this.currentView === SIDEBAR_ACCOUNTS) {
        this.accountsView.adjustNetWorthFontSize();
      }
      eventBus.trigger("sidebar:resized");
    }
  },

  isAnyExtraFormVisible: function () {
    return (
      this.currentView === SIDEBAR_ACCOUNTS &&
      this.accountsView.isAnyExtraFormVisible()
    );
  },

  updateScroller: function () {
    if (this.currentView === SIDEBAR_ACCOUNTS) {
      this.accountsView.adjustScroller();
    }
  },

  initializePlansSidebar: function () {
    this.showTabs();
    $(".js-sidebar-tab").removeClass("is-active");
    $('.js-sidebar-tab[data-id="Scenarios"]').addClass("is-active");
    this.showPlansContainer();
    this.renderSidebarPlans({ isFirstUseCompleted: true });
  },
});
