import _ from "underscore";

const ERROR_NUMBER_28 = 28;
const ERROR_NUMBER_31 = 31;
const ERROR_NUMBER_35 = 35;
const ERROR_NUMBER_39 = 39;

/**
 * Converts an array of strings to a string
 *
 * @param {Array} anArray is an array of strings.
 *
 * @return {String} out put string will be comma separated
 * and `and` will be added for the last string in the array
 */
const convertArrayToString = function (anArray) {
  if (!_.isArray(anArray)) {
    return;
  }

  return anArray.length > 1
    ? `${anArray.slice(0, -1).join(", ")} and ${anArray.slice(-1)}`
    : anArray.join("");
};

/*
 * Compares the elements of two arrays that contain objects
 *
 * @param array1 Array
 * @param array2 Array
 * @param idProperties An array of property names that will be used to create a unique key
 *
 * @return An object with the following properties:
 *			hasChanges Boolean
 *			addedItems Array An array of items that are in array2, but not in array1
 *			deletedItems Array An array of items that are not in array2, but are in array1
 *			changedItems Array An array of items that are in both array1 and array2, but whose content are different
 *			unchangedItems Array An array of items that are in both array1 and array2 and whose contents are the same
 */
const compareArrays = function (array1, array2, idProperties) {
  if (arguments.length < 2) {
    throw new Error(
      "You did not supply proper arguments to this function",
      "array.js",
      ERROR_NUMBER_28
    );
  }

  if (!_.isArray(array1)) {
    throw new Error(
      "1st argument is not an array",
      "array.js",
      ERROR_NUMBER_31
    );
  }

  if (!_.isArray(array2)) {
    throw new Error(
      "2nd argument is not an array",
      "array.js",
      ERROR_NUMBER_35
    );
  }

  if (
    !_.isUndefined(idProperties) &&
    (!_.isArray(idProperties) || idProperties.length === 0)
  ) {
    throw new Error(
      "3rd argument needs to be an array of property names",
      "array.js",
      ERROR_NUMBER_39
    );
  }

  let lookup1 = {};
  let lookup2 = {};
  let inBoth = {};
  let addedItems = [];
  let deletedItems = [];
  let changedItems = [];
  let unchangedItems = [];
  let key;
  let i;

  // if array elements are primitives
  if (
    _.isString(array1[0]) ||
    _.isNumber(array1[0]) ||
    _.isString(array2[0]) ||
    _.isNumber(array2[0])
  ) {
    // search for items added
    for (i = 0; i < array2.length; i++) {
      let matchElement = _.find(array1, function (element) {
        return element == array2[i];
      });

      if (typeof matchElement == "undefined") {
        addedItems.push(array2[i]);
      }
    }

    // search for items deleted
    for (i = 0; i < array1.length; i++) {
      let matchElement = _.find(array2, function (element) {
        return element == array1[i];
      });

      if (typeof matchElement == "undefined") {
        deletedItems.push(array1[i]);
      }
    }
  } else {
    // for complex objects
    let getPropertyValues = function (object) {
      let propertyValues = [];

      for (i = 0; i < idProperties.length; i++) {
        propertyValues.push(object[idProperties[i]]);
      }

      return propertyValues;
    };

    let createLookup = function (array, lookup) {
      _.each(array, function (item) {
        key = getPropertyValues(item).join("-");
        lookup[key] = item;
      });
    };

    createLookup(array1, lookup1);
    createLookup(array2, lookup2);

    // search for items added
    for (key in lookup2) {
      if (typeof lookup1[key] == "undefined") {
        addedItems.push(lookup2[key]);
      } else {
        inBoth[key] = lookup2[key];
      }
    }

    // search for items deleted
    for (key in lookup1) {
      if (typeof lookup2[key] == "undefined") {
        deletedItems.push(lookup1[key]);
      } else {
        inBoth[key] = null;
      }
    }

    // search for changed items
    for (key in inBoth) {
      if (_.isEqual(lookup1[key], lookup2[key])) {
        unchangedItems.push(lookup1[key]);
      } else {
        changedItems.push(lookup2[key]);
      }
    }
  }

  return {
    hasChanges:
      addedItems.length > 0 ||
      deletedItems.length > 0 ||
      changedItems.length > 0,
    addedItems: addedItems,
    deletedItems: deletedItems,
    changedItems: changedItems,
    unchangedItems: unchangedItems,
  };
};

export default {
  convertArrayToString,
  compareArrays,
};
