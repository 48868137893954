import React from "react";
export default function UpdateInfoIcon() {
  return (
    <svg
      width="110px"
      height="110px"
      viewBox="0 0 110 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon_update_info</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Group3---Round-2-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Update-profile" transform="translate(-75.000000, -62.000000)">
          <g
            id="marketing/security/link_01-copy-78"
            transform="translate(75.000000, 62.000000)"
          >
            <g filter="url(#filter-1)" id="circle">
              <g transform="translate(0.000000, -0.000611)">
                <path
                  d="M110,54.9992369 C110,85.3747971 85.3759816,110 55,110 C24.6255445,110 0,85.3747971 0,54.9992369 C0,24.6252029 24.6255445,0 55,0 C85.3759816,0 110,24.6252029 110,54.9992369"
                  id="Fill-1"
                  fill="#F3F6F7"
                ></path>
              </g>
            </g>
            <g transform="translate(26.079077, 28.836653)" strokeWidth="1.5">
              <path
                d="M57.2332795,53.777625 L2.90946432,53.777625 C1.30313589,53.777625 0.000623211807,52.5004028 0.000623211807,50.9267917 L0.000623211807,14.6894306 C0.000623211807,13.1142917 1.30313589,11.8370694 2.90946432,11.8370694 L57.2332795,11.8370694 C58.8396079,11.8370694 60.1421206,13.1142917 60.1421206,14.6894306 L60.1421206,50.9267917 C60.1421206,52.5004028 58.8396079,53.777625 57.2332795,53.777625 Z"
                stroke="#002157"
              ></path>
              <path
                d="M0,8.37986111 L0,2.61861111 C0,0.0519444444 1.37574006,0 2.90884111,0 L15.5896434,0 L19.5672927,5.26013889 L57.2326563,5.26013889 C58.8405428,5.26013889 60.1430554,6.53736111 60.1430554,8.1125 L60.1430554,14.6895833"
                stroke="#002157"
              ></path>
              <path
                d="M35.4771111,32.9391944 C35.0611172,32.8444722 34.6357752,32.7803056 34.1932948,32.7803056 L32.7661398,32.7803056 C34.5391774,31.70475 35.7669046,29.5551667 35.7669046,27.0465556 C35.7669046,23.4715556 33.3021019,20.5703056 30.2623863,20.5703056 C27.2226707,20.5703056 24.7594261,23.4715556 24.7594261,27.0465556 C24.7594261,29.5551667 25.9855953,31.70475 27.7601909,32.7803056 L26.6461998,32.7803056 C26.1164698,32.7803056 25.6147843,32.8765556 25.125563,33.011 C22.749568,33.671 20.9858786,35.7976667 20.9858786,38.3307222 L20.9858786,42.031 L39.853616,42.031 L39.853616,38.3307222 C39.853616,35.7121111 37.9761905,33.5136389 35.4771111,32.9391944 Z"
                stroke="#DB1830"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
