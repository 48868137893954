var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"pc-u-mb0 pc-u-mt0\">\n  <button\n    class=\"sidebar-account__group-header "
    + alias4(((helper = (helper = lookupProperty(helpers,"groupClassName") || (depth0 != null ? lookupProperty(depth0,"groupClassName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"groupClassName","hash":{},"data":data,"loc":{"start":{"line":3,"column":41},"end":{"line":3,"column":59}}}) : helper)))
    + " js-sidebar-account__group-header qa-sidebar-account-group-header\"\n    role=\"button\"\n    data-toggle=\"pc-collapse\"\n    data-target=\"#js-collapsible-panel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelName") || (depth0 != null ? lookupProperty(depth0,"panelName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelName","hash":{},"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":52}}}) : helper)))
    + "\"\n    aria-expanded=\"true\"\n    aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":25}}}) : helper)))
    + "\"\n    aria-controls=\"js-collapsible-panel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelName") || (depth0 != null ? lookupProperty(depth0,"panelName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelName","hash":{},"data":data,"loc":{"start":{"line":9,"column":40},"end":{"line":9,"column":53}}}) : helper)))
    + "\"\n  ></button>\n  </h2>\n  <div id=\"js-collapsible-panel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelName") || (depth0 != null ? lookupProperty(depth0,"panelName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelName","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":45}}}) : helper)))
    + "\" class=\"in\" style=\"height:auto\">\n    <ul class=\"collapsible__body collapse in\">\n      <li class=\"js-ace-sidebar-notification sidebar__ace-notification\"></li>\n    </ul>\n  </div>\n  ";
},"useData":true});