import ComponentAnalytics from "components/common/ComponentAnalytics";

export const COMPONENT_NAME = "Getting Started Page";

const PERSON_AGE_THRESHOLD = 55;

export const INVESTMENT_CHECKUP = {
  imgSrc: "/static/img/icons/investment-checkup-chart.svg",
  imgAlt: "investment checkup chart",
  title: "Make your portfolio work harder",
  list: [
    "Understand your risk vs. returns",
    "Review your ideal allocation",
    "Expose costly funds and hidden fees",
  ],
  ctaText: "Find opportunities",
  ctaHref: "#/investment-checkup",
  ctaOnClick: () =>
    ComponentAnalytics.trackClick(
      null,
      COMPONENT_NAME,
      "Find opportunities Button"
    ),
};

export const NET_WORTH = {
  imgSrc: "/static/img/icons/net-worth-graphic.svg",
  imgAlt: "net worth",
  title: "Understand and track your net worth",
  list: [
    "A simple way to measure your financial health",
    "The difference between everything you owe and own",
    "Track with your financials goals when monitored over time",
  ],
  ctaText: "Calculate net worth",
  ctaHref: "#/net-worth",
  ctaOnClick: () =>
    ComponentAnalytics.trackClick(
      null,
      COMPONENT_NAME,
      "Calculate Net Worth Button"
    ),
};

export const CASH_FLOW = {
  imgSrc: "/static/img/icons/expense-graphic.svg",
  imgAlt: "cash flow",
  title: "Spend and save smarter",
  list: [
    "See all of your balances and transactions in one place",
    "Understand where you spend and where you can save",
    "Set your budget in order to reach your savings goals",
  ],
  ctaText: "Get to know your money",
  ctaHref: "#/cash-flow",
  ctaOnClick: () =>
    ComponentAnalytics.trackClick(
      null,
      COMPONENT_NAME,
      "Get To Know Your Money Button"
    ),
};

export const PORTFOLIO = {
  imgSrc: "/static/img/icons/allocation-graphic.svg",
  imgAlt: "portfolio",
  title: "Track your investments",
  list: [
    "Analyze performance across accounts",
    "Track changes in balances and holdings",
    "Understand and drill into your allocation",
  ],
  ctaText: "Analyze portfolio",
  ctaHref: "#/portfolio",
  ctaOnClick: () =>
    ComponentAnalytics.trackClick(
      null,
      COMPONENT_NAME,
      "Analyze Portfolio Button"
    ),
};

const RETIREMENT_PLANNER = {
  imgSrc: "/static/img/icons/retirement-planner-graphic.svg",
  imgAlt: "retirement planner",
  ctaText: "Run retirement planner",
  ctaHref: "#/retirement-planner",
  ctaOnClick: () =>
    ComponentAnalytics.trackClick(
      null,
      COMPONENT_NAME,
      "Run Retirement Planner Button"
    ),
};

export const retirementPlannerGenerator = (personAge) => ({
  ...RETIREMENT_PLANNER,
  title:
    personAge > PERSON_AGE_THRESHOLD
      ? "Get the most out of your retirement"
      : "Design the financial future you deserve",
  list:
    personAge > PERSON_AGE_THRESHOLD
      ? [
          "Plan for life's biggest moments and goals",
          "See how much you need to retire vs. where you stand",
          "Run retirement scenarios and simulations",
        ]
      : [
          "Plan for life's biggest moments and goals",
          "See how much you need in order to retire and where you currently stand",
          "Run retirement scenarios and simulations",
        ],
});

export const GOALS = {
  UNDERSTAND_NET_WORTH: "Understand and track my net worth",
  SET_SAVINGS_GOALS: "Set savings goals and track my budget",
  MONITOR_INVESTMENTS: "Monitor and track my investments",
  OPTIMIZE_STRATEGY: "Optimize my investment strategy and reduce fees",
  CONFIDENT_RETIREMENT: "Feel more confident about my retirement",
  PLAN_LIFE_EVENTS:
    "Plan for life financial events (home purchase, education, etc.)",
};
