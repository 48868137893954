import _ from "underscore";
import BaseStateModel from "stateModels/baseStateModel";
function NetWorthState() {
  this.baseUrl = "/net-worth";
}

NetWorthState.prototype = new BaseStateModel();
NetWorthState.prototype.constructor = NetWorthState;

export default NetWorthState;
