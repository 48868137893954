var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <li id=\"sidebar__aggregation-helper\">\n  </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <li class=\"sidebar__add-account-button-container\">\n    <a\n      href=\"#/accounts/add\"\n      class=\"pc-btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"ADD_ACCOUNT_BTN_CLASS") || (depth0 != null ? lookupProperty(depth0,"ADD_ACCOUNT_BTN_CLASS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ADD_ACCOUNT_BTN_CLASS","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":45}}}) : helper)))
    + " sidebar__add-account-button\"\n      aria-label=\"Add an account\"\n    >\n      <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"linkAccountMessage") || (depth0 != null ? lookupProperty(depth0,"linkAccountMessage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkAccountMessage","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":34}}}) : helper)))
    + "</span>\n    </a>\n  </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":14,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});