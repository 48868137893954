import React from "react";
import Recommendation401kView from "components/AccountDetails/Recommendation401k/Recommendation401kView";
import objectPath from "object-path";
import PropTypes from "prop-types";
import Services from "services";
import { promisify } from "utils/service";
import { trackEvent } from "components/common/ComponentAnalytics";

export default class Recommendation401kContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allocationSet: null,
      errors: null,
      loading: true,
    };
  }

  render() {
    return (
      <Recommendation401kView
        allocationSet={this.state.allocationSet}
        errors={this.state.errors}
        loading={this.state.loading}
        advisorData={this.state.advisorData}
      />
    );
  }

  componentDidMount() {
    const investmentCheckupAPI = this.props.isInvestmentCheckupV2
      ? Services.InvestmentCheckup.getV2
      : Services.InvestmentCheckup.get;
    const fetchAllocations = promisify(investmentCheckupAPI);
    const fetchAdvisor = promisify(Services.Advisor.get);

    Promise.all([
      fetchAllocations({
        sections: JSON.stringify(["IC_ALLOCATION_CLASSIFICATION"]),
        userAccountIds: JSON.stringify([this.props.userAccountId]),
      }),
      fetchAdvisor(),
    ])
      .then(([icResults, advisorData]) => {
        const allocationSet = objectPath.get(
          icResults,
          "allocation.assetClassAllocation.data.allocationSet"
        );
        if (allocationSet) {
          this.setState({
            loading: false,
            allocationSet,
            advisorData,
          });
        } else {
          // Unable to perform comparison; Show message stating so.
          const takeaway = objectPath.get(icResults, "allocation.mainTakeaway");
          trackEvent(
            "Recommendation 401k",
            "Unable to classify assets for 401k Comparison"
          );
          this.setState({ loading: false, allocationSet, errors: [takeaway] });
        }
      })
      .catch((errors) => {
        this.setState({ loading: false, errors });
      });
  }
}

Recommendation401kContainer.propTypes = {
  isInvestmentCheckupV2: PropTypes.bool,
  userAccountId: PropTypes.number.isRequired,
};

Recommendation401kContainer.defaultProps = {
  isInvestmentCheckupV2: true,
};
