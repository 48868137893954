/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import PropTypes from "prop-types";
import NotificationItem from "./NotificationItem";
import AdviceDisclaimerModal from "components/modal/AdviceDisclaimerModal";
import { noop } from "underscore";

const component = "App Menu Notifications Dropdown";

const NavBarNotifications = ({
  userMessages,
  trackDisclaimerClick,
  handleMessageClick,
  maxMessagesToShow,
  componentName,
  handleActionLinkClick,
  handleViewAllClick,
}) => {
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const onDisclaimerClick = (event) => {
    event.preventDefault();
    if (!showDisclaimerModal) {
      trackDisclaimerClick(component);
    }
    setShowDisclaimerModal(!showDisclaimerModal);
  };

  const sentenceCaseClassName = "u-sentence-case";

  return (
    <div className="nav-bar-notifications__dropdown">
      <div className="nav-bar-notifications__dropdown-header">
        <div
          className={`nav-bar-notifications__dropdown-header-notifications ${sentenceCaseClassName}`}
        >
          Notifications
        </div>
        <div
          className={`pc-bg-dark notifications__disclaimer notifications__disclaimer--rebranding`}
        >
          <a
            className={`pc-btn pc-btn--link`}
            href="#"
            onClick={onDisclaimerClick}
          >
            Disclaimer
          </a>
          <AdviceDisclaimerModal
            componentName={component}
            isOpen={showDisclaimerModal}
            title="Notifications Disclaimer"
            onClosed={() => {
              setShowDisclaimerModal(false);
            }}
          />
        </div>
      </div>
      <div className="nav-bar-notifications__dropdown-content">
        {userMessages && userMessages.length ? (
          userMessages
            .slice(0, maxMessagesToShow)
            .map((message) => (
              <NotificationItem
                key={message.userMessageId}
                message={message}
                handleClick={handleMessageClick}
                handleActionLinkClick={handleActionLinkClick}
                componentName={componentName}
              />
            ))
        ) : (
          <div className="nav-bar-notifications__dropdown-zero-state u-preserve-case">
            No new notifications
          </div>
        )}
      </div>
      <div
        className={`pc-bg-dark nav-bar-notifications__dropdown-footer nav-bar-notifications__dropdown-footer--rebranding`}
      >
        <a
          onClick={handleViewAllClick}
          href="#/notifications"
          className={`pc-btn pc-btn--link notifications-dropdown__footer-link`}
        >
          View All
        </a>
      </div>
    </div>
  );
};

NavBarNotifications.defaultProps = {
  componentName: "NavBar Notifications",
  userMessages: undefined,
  maxMessagesToShow: undefined,
  handleMessageClick: undefined,
  handleViewAllClick: undefined,
  handleActionLinkClick: undefined,
  trackDisclaimerClick: noop,
};

NavBarNotifications.propTypes = {
  userMessages: PropTypes.array,
  maxMessagesToShow: PropTypes.number,
  handleMessageClick: PropTypes.func,
  handleViewAllClick: PropTypes.func,
  trackDisclaimerClick: PropTypes.func,
  handleActionLinkClick: PropTypes.func,
  componentName: PropTypes.string,
};

export default NavBarNotifications;
