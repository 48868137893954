import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import FancySelect from "components/common/form/FancySelect";
import FancySelectUtils from "components/common/form/FancySelect/FancySelectUtils";
import { noop, isEqual, isEmpty } from "underscore";
import { components } from "react-select";
import ManageTagsModal from "components/TransactionsGridV3/ManageTags/Modal";
import CreateUpdateTagModal from "components/TransactionsGridV3/ManageTags/Modal/CreateUpdateTagModal";
import deepCopy from "deep-copy";
import ConfirmModal from "common/ConfirmModal/ConfirmModal";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const isPrivileged = isEmpowerPrivilegedMode();
const MINIMUM_CHARACTERS_LIMIT = 3;
const MAXIMUM_CUSTOM_TAGS_LIMIT = 30;
const NON_ALPHANUMERIC_CHARACTERS = /[^a-zA-Z0-9_ ]/gi;

class InputTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      showCreateUpdateTag: false,
      showDeleteTag: false,
      showManageTags: false,
    };

    this.handleClearTags = this.handleClearTags.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleFooterCTAClick = this.handleFooterCTAClick.bind(this);
    this.handleManageTagsClick = this.handleManageTagsClick.bind(this);
    this.handleManageTagsClose = this.handleManageTagsClose.bind(this);
    this.handleShowCreateUpdateTag = this.handleShowCreateUpdateTag.bind(this);
    this.handleShowDeleteTag = this.handleShowDeleteTag.bind(this);
    this.handleCreateTag = this.handleCreateTag.bind(this);
    this.handleUpdateTag = this.handleUpdateTag.bind(this);
    this.handleDeleteTag = this.handleDeleteTag.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleCloseShowCreateUpdateTag =
      this.handleCloseShowCreateUpdateTag.bind(this);
    this.handleCloseShowDeleteTag = this.handleCloseShowDeleteTag.bind(this);
    this.Input = (props) => (
      <components.Input
        {...props}
        maxLength={35}
        onKeyPress={this.handleKeyPress}
      />
    );
  }

  getIsManageTags() {
    const { searchInput, searchResultCount } = this.state;
    const { tags } = this.props;
    if (
      !searchInput ||
      searchInput.length < MINIMUM_CHARACTERS_LIMIT ||
      searchResultCount ||
      tags.filter((t) => t.isCustom).length >= MAXIMUM_CUSTOM_TAGS_LIMIT
    ) {
      return true;
    }

    return false;
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();

      if (this.getIsManageTags()) {
        return;
      }

      this.handleFooterCTAClick();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.value) &&
      !isEqual(nextProps.value, prevState.prevPropValue) &&
      !isEqual(nextProps.value, prevState.value)
    ) {
      return {
        value: deepCopy(nextProps.value),
        prevPropValue: deepCopy(nextProps.value),
      };
    }

    return null;
  }

  handleCreateTag(tagName) {
    const onCreateTagPromise = this.props.onCreateTag(tagName);
    onCreateTagPromise.then(this.handleCloseShowCreateUpdateTag);
  }

  handleUpdateTag(tag) {
    const onUpdateTagPromise = this.props.onUpdateTag(tag);
    onUpdateTagPromise.then(this.handleCloseShowCreateUpdateTag);
  }

  handleDeleteTag() {
    const onDeleteTagPromise = this.props.onDeleteTag(
      this.state.selectedTag.value
    );
    onDeleteTagPromise.then(this.handleCloseShowDeleteTag);
  }

  handleShowCreateUpdateTag(tag) {
    this.setState({
      showManageTags: false,
      showCreateUpdateTag: true,
      selectedTag: tag,
    });
  }

  handleCloseShowCreateUpdateTag() {
    this.setState({
      showManageTags: true,
      showCreateUpdateTag: false,
      selectedTag: undefined,
    });
    this.props.onCUDTagClosed();
  }

  handleShowDeleteTag(tag) {
    this.setState({
      showManageTags: false,
      showDeleteTag: true,
      selectedTag: tag,
    });
  }

  handleCloseShowDeleteTag() {
    this.setState({
      showManageTags: true,
      showDeleteTag: false,
      selectedTag: undefined,
    });
    this.props.onCUDTagClosed();
  }

  handleClearTags() {
    this.setState({ value: [] });
    this.props.onTagsModified([]);
  }

  handleChange({ target: { value } }) {
    this.setState({ value, searchInput: "" }, () => {
      this.props.onTagsModified(value);
    });
  }

  handleManageTagsClick() {
    this.setState({ showManageTags: true });
    this.props.onManageTagsClick();
  }

  handleManageTagsClose() {
    this.setState({ showManageTags: false });
    this.props.onManageTagsClosed();
  }

  handleFooterCTAClick() {
    const { searchInput } = this.state;
    if (this.getIsManageTags()) {
      this.handleManageTagsClick();
      return;
    }

    this.props.onCreateTag(searchInput).then((tag) => {
      const { value: valueFromState } = this.state;
      const value = Object.assign([], valueFromState);
      value.push(tag.tagId);
      this.setState({ searchInput: "" });
      this.handleChange({
        target: { value },
      });
    });
  }

  handleMenuClose() {
    this.setState({ searchInput: "" });
  }

  handleSearch(data) {
    const { searchInput, searchResultCount } = this.state;
    if (
      data.searchInput !== searchInput ||
      data.resultCount !== searchResultCount
    ) {
      this.setState({
        searchInput: data.searchInput,
        searchResultCount: data.resultCount,
      });
    }
  }

  render() {
    const { loading, errors, tags, isDisabled, className, selectInputId } =
      this.props;
    
    const {
      value,
      searchInput,
      searchResultCount,
      showManageTags,
      showCreateUpdateTag,
      showDeleteTag,
      selectedTag,
    } = this.state;
    let inputValue = searchInput
      ? searchInput.replace(NON_ALPHANUMERIC_CHARACTERS, "")
      : "";

    const inManageMode = showManageTags || showCreateUpdateTag || showDeleteTag;
    const disableCreateButton =
      tags.filter((t) => t.isCustom).length >= MAXIMUM_CUSTOM_TAGS_LIMIT;

    const belowButtonLabel =
      disableCreateButton ||
      !inputValue ||
      inputValue.length < MINIMUM_CHARACTERS_LIMIT ||
      searchResultCount
        ? "Manage Tags"
        : `"${inputValue}" (Create tag)`;
    const fancySelectComponents = {
      Input: this.Input,
      ClearIndicator: () => (
        <button
          className="Select__clear-indicator Select__clear-indicator-with-no-dropdown-indicator Select__clear-indicator-override-button"
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={this.handleClearTags}
          title="Clear tags"
          aria-label="Clear tags"
        >
          X
        </button>
      ),
    };

    if (!isEmpty(value)) {
      fancySelectComponents.DropdownIndicator = () => null;
    }

    const manageTagsProps = {
      loading,
      errors,
      tags,
      onShowCreateTag: this.handleShowCreateUpdateTag,
      onShowUpdateTag: this.handleShowCreateUpdateTag,
      onShowDeleteTag: this.handleShowDeleteTag,
      disableCreateButton,
      onCancel: this.handleManageTagsClose,
    };

    return (
      <>
        <LoadingOverlay
          modifier="medium"
          active={inManageMode ? false : loading}
        />
        <Message
          severity="error"
          listClassName="pc-u-mb--"
          messages={inManageMode ? [] : errors}
        />
        <FancySelect
          name="selectTransactionTags"
          selectInputId={selectInputId}
          placeholder="Select tag here..."
          className={`${className} input-tags qa-input-tags`}
          value={value}
          isMulti={true}
          isClearable={true}
          isSearchable={true}
          isDisabled={isDisabled}
          onSearch={this.handleSearch}
          onMenuClose={this.handleMenuClose}
          closeMenuOnSelect={false}
          options={tags}
          onChange={this.handleChange}
          inputValue={inputValue}
          matchFromStart={false}
          components={fancySelectComponents}
          menuFooterComponent={
            <FancySelectUtils.MenuFooterButton
              className="qa-manage-tags__button"
              label={belowButtonLabel}
              onClick={this.handleFooterCTAClick}
            />
          }
        />
        <ManageTagsModal {...manageTagsProps} isOpen={showManageTags} />
        {showCreateUpdateTag && (
          <CreateUpdateTagModal
            model={
              isEmpty(selectedTag)
                ? {}
                : { customTagName: selectedTag.label, tagId: selectedTag.value }
            }
            loading={loading}
            errors={errors}
            onCancel={this.handleCloseShowCreateUpdateTag}
            onCreateTag={this.handleCreateTag}
            onUpdateTag={this.handleUpdateTag}
          />
        )}
        {showDeleteTag && (
          <ConfirmModal
            onConfirm={this.handleDeleteTag}
            onCancel={this.handleCloseShowDeleteTag}
            loading={loading}
            confirmLabel="Remove"
            title="Delete Tag"
            isOpen={true}
            confirmButtonClassName="pc-btn--danger"
            disabled={isPrivileged}
          >
            <>
              <Message severity="error" messages={errors} />
              <p>
                Are you sure? The tag <strong>{selectedTag.label}</strong> will
                be removed from all transactions.
              </p>
            </>
          </ConfirmModal>
        )}
      </>
    );
  }
}

InputTags.propTypes = {
  selectInputId: PropTypes.string,
  tags: PropTypes.array.isRequired,
  onCreateTag: PropTypes.func,
  onTagsModified: PropTypes.func,
  value: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  errors: PropTypes.array,
  // Props to Manage tags modal
  onUpdateTag: PropTypes.func,
  onDeleteTag: PropTypes.func,
  onManageTagsClick: PropTypes.func,
  onCUDTagClosed: PropTypes.func,
  onManageTagsClosed: PropTypes.func,
};

InputTags.defaultProps = {
  selectInputId: undefined,
  loading: false,
  className: "Select--small",
  isDisabled: false,
  errors: undefined,
  onCreateTag: noop,
  onTagsModified: noop,
  onUpdateTag: noop,
  onDeleteTag: noop,
  onManageTagsClick: noop,
  onCUDTagClosed: noop,
  onManageTagsClosed: noop,
};

export default InputTags;
