/* eslint-disable no-underscore-dangle */
import Raphael from "raphael";
import eventBus from "eventBus";
import * as customEvents from "libs/pcap/utils/customEvents";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import ClosedAccountsFilter from "closedAccountsFilter";
import Questionnaire from "models/questionnaire";
import _401kTemplate2 from "templates/modules/_401k/_401k2.html";
import _401kConfigureAccountsTemplate from "templates/modules/_401k/_401kConfigureAccounts.html";
import sliders401kTemplate from "templates/partials/sliders401k.html";
import _401kQuestionnaireView from "views/modules/_401k/_401kQuestionnaireView";
import AccountSelector2 from "views/components/accountSelectorFactory2";
import Mixpanel from "mixpanel";
import FixedControlsMixin from "mixins/fixFeatureControls";
import ContactAdvisorMixin from "mixins/contactAdvisor";
import parseResponseErrors from "libs/pcap/utils/response";
import * as appointmentUtils from "libs/pcap/utils/appointmentUtils";
import abFrameworkProperties from "libs/pcap/utils/abFrameworkProperties";
import IframeClient from "partner/iframeClient";
import NoopFixedControlsMixin from "mixins/noopFixFeatureControls/index";
import * as COLORS from "libs/pcap/utils/colors";
const RETIREMENT_FEE_ANALYZER_CHART_COLORS =
  COLORS.RETIREMENT_FEE_ANALYZER_CHART_COLORS;

var _401kView = Backbone.View.extend({
  el: "#_401k",
  accountsToUpdateAs401k: [],
  haveNonTaxableAccounts: false,
  events: {
    "click a.editQuestionnaire": "toggleEditQuestionnaire",
    "click a.advancedControlsToggle": "toggleAdvancedParameters",
    "click .js-how-we-calculate-results-link":
      "onHowWeCalcualteResultsLinkClick",
    "click .js-link-account-btn": "onLinkAccountBtnClick",
    "click .js-talk-to-an-advisor-btn": "onTalkToAnAdvisorBtnClick",
  },
  initialize: function (options) {
    this.options = options;
    var self = this;

    // Code change for WEB-1381, slider values needs to call updatePerson API instead of get401kFundFees.
    // Using a pattern similar to _401kQuestionaireView.js, that uses 'Questionaire" model as the param for updatePerson API
    this.modelLoaded = false;
    this.accountsLoaded = false;
    this.model = Questionnaire;
    this.model.off("change", this.onModelChange);
    this.model.on("change", this.onModelChange, this);
    this.model.fetch();
    this.selectedAccountIds = [];

    // bind to questionnaire/assumptions events
    eventBus.bind(
      customEvents.employerPlanQuestionnaireUpdated,
      this.assumptionsUpdated,
      this
    );

    var get401kQuestionnaireDeferred = $.Deferred();
    var getAdvisorDeferred = $.Deferred();

    Services._401kQuestionnaire.get({}, function (err, response) {
      var errors = parseResponseErrors(err, response);
      if (errors) {
        get401kQuestionnaireDeferred.reject(errors);
        return;
      }
      get401kQuestionnaireDeferred.resolve(response);
    });

    Services.Advisor.get(function (err, response) {
      var errors = parseResponseErrors(err, response);
      if (errors) {
        getAdvisorDeferred.reject(errors);
        return;
      }
      getAdvisorDeferred.resolve(response);
    });

    $.when(get401kQuestionnaireDeferred, getAdvisorDeferred).done(
      function (_401kQuestionnaireData, advisorData) {
        self.questionaire = self.formatPercentages(
          $.extend(true, {}, _401kQuestionnaireData.spData)
        );
        // passing staticUrl for image references in template files
        self.questionaire.staticUrl = window.staticUrl;
        self.questionaire.advisorAppointmentUrl =
          appointmentUtils.addReasonToAppointmentUrl(
            (advisorData.spData && advisorData.spData.appointment_url) || "",
            "Fee Analyzer"
          );
        self.questionaire.openAdvisorAppointmentInNewTab =
          self.questionaire.advisorAppointmentUrl.indexOf("http") === 0;

        // To hide `Talk to an Advisor` button when page is iframed.
        self.questionaire.showTalkToAnAdvisor = !IS_IFRAMED;
        self.questionaire.organizationSupportUrl = EMPOWER_SUPPORT_URL;

        self.$el.html(_401kTemplate2(self.questionaire));
        self.render();

        $(".editAssumptions").append(
          new _401kQuestionnaireView({ className: "questionnaireContainer" })
            .$el
        );
        $(".questionnaireContainer", self.$el).height(0);

        // account selector
        self.accountSelector = new AccountSelector2({
          el: ".retirementFeeAccountSelector",
          groupBy: AccountSelector2.GROUP_BY_TAXABLE_TYPE,
          closedAccountsFilter: function (accountsArray) {
            return ClosedAccountsFilter.filter(accountsArray);
          },
        });

        self.accountSelector.on(
          "selectedUserAccountIds",
          function (ids) {
            self.selectedAccountIds = ids;
            self.requestAndBuildChart();
            Services.Holdings.get(
              { userAccountIds: JSON.stringify(ids) },
              function (err, data) {
                self.renderTables(data);
                self.triggerIframeResize();
              }
            );
          },
          this
        );

        // determine if account has 401ks, if not show the zero state
        self.accountWatcherId = Services.Accounts.get.watch(
          {},
          function (err, data) {
            // set timeSeries object to a falsy value(false/null/undefined) as we are replacing the template containing timeseries object
            // and the corresponding raphel object needs to recreated again when needed
            self.timeSeries = false;
            self.accountsLoaded = true;

            // If user has account.isTaxDeferredOrNonTaxable=true for at least one account, show active state
            // Else, show zero state
            var hasNonTaxableAccounts = false;
            if (self.selectedAccountIds.length < 1) {
              self.selectedAccountIds = [];
              _.each(data.spData.accounts, function (val) {
                if (
                  val &&
                  !_.isUndefined(val.isTaxDeferredOrNonTaxable) &&
                  val.isTaxDeferredOrNonTaxable == true
                ) {
                  hasNonTaxableAccounts = true;
                  self.selectedAccountIds.push(val.userAccountId);
                }
              });
              self.accountSelector.setSelectedUserAccountIds(
                self.selectedAccountIds
              );
            } else {
              hasNonTaxableAccounts = true;
            }

            if (hasNonTaxableAccounts) {
              self.$el.find(".zeroState").hide();
              self.$el.find(".activeModule").show();
              self.haveNonTaxableAccounts = true;
              self.accountSelector.setSelectedUserAccountIds(
                self.selectedAccountIds
              );
              self.accountSelector.trigger(
                "selectedUserAccountIds",
                self.selectedAccountIds
              );
            } else {
              AppRouter.removePreloader();
              self.$el.find(".zeroState").show();
              self.$el.find(".activeModule").hide();
              self.haveNonTaxableAccounts = false;
              var obj = {
                hasInvestmentAccounts: false,
                investmentAccounts: _.filter(
                  data.spData.accounts,
                  function (account) {
                    // determine if userAccountId exists and is a number and action.next is NONE
                    // if(typeof account.userAccountId == 'number' && account.userAccountId > 0 && account.productType == 'INVESTMENT' && account.is401KEligible == true){
                    if (
                      typeof account.userAccountId == "number" &&
                      account.userAccountId > 0 &&
                      account.isTaxDeferredOrNonTaxable == true
                    ) {
                      return account;
                    }
                  }
                ),
              };
              if (obj.investmentAccounts.length > 0) {
                self.$el
                  .find(".zeroState .investmentAccounts")
                  .html(_401kConfigureAccountsTemplate(obj));
              }
            }
            self.triggerIframeResize();
          }
        );
      }.bind(this)
    );

    if (
      abFrameworkProperties.showEmailAdvisorLauncherIn &&
      abFrameworkProperties.showEmailAdvisorLauncherIn.feeAnalyzer
    ) {
      this.renderEmailAdvisorLauncher("fee-analyzer");
    }
  },

  onClose: function () {
    this.unmountEmailAdvisorLauncher();
  },

  remove: function () {
    if (!_.isUndefined(this.accountWatcherId)) {
      Services.Accounts.get.unwatch(this.accountWatcherId);
    }

    if (this.accountSelector) {
      this.accountSelector.off("selectedUserAccountIds");
      this.accountSelector.close();
      delete this.accountSelector;
    }

    Backbone.View.prototype.remove.apply(this, arguments);
  },

  onModelChange: function (event) {
    this.modelLoaded = true;
    this.requestAndBuildChart();
  },

  render: function () {
    var self = this;
    self.buildSlider(".parameters .slider, .fundsWithoutExpense .slider");
    $(".toolTipped", this.$el).tipTip();

    eventBus.once(
      "moduledisplayed",
      function () {
        this.initializeAllFixedControls();
      }.bind(this)
    );

    return this;
  },
  showAddAccount: function () {
    this._401kAddAccountView.init();
    $.colorbox({
      inline: true,
      href: "#addAccount401k",
      width: 404,
      height: 510,
      transition: "fade",
      overlayClose: false,
      escKey: false,
      onLoad: function () {
        $("#cboxClose").remove();
      },
      onClosed: function () {
        AppRouter.navigate("#/401k/");
      },
    });
  },
  toggleEditQuestionnaire: function (e) {
    e.preventDefault();
    var target = $(e.currentTarget);
    var mode = target.data("mode");
    if (mode == "closed") {
      $(".editAssumptions", this.$el).css("border-bottom", "1px solid #abb0b2");
      $(".editQuestionnaire", this.$el)
        // .removeClass('gray')
        .text("Done");
      $(".questionnaireContainer", this.$el).animate(
        { height: "140px" },
        500,
        function () {
          $(".editQuestionnaire", this.$el).data("mode", "opened");
        }
      );
      Mixpanel.trackEvent("Click on Edit Assumptions Button", {
        component: "401K_ASSUMPTIONS_PANEL",
      });
    } else {
      $(".editAssumptions form").trigger("submit");
      Mixpanel.trackEvent("Click on Done Button", {
        component: "401K_ASSUMPTIONS_PANEL",
      });
    }
  },
  toggleAdvancedParameters: function (e) {
    e.preventDefault();
    var target = $(e.currentTarget);
    if (target.hasClass("off")) {
      $(".advancedControls").slideDown(500);
      target.removeClass("off").addClass("on");
    } else {
      $(".advancedControls").slideUp(500);
      target.removeClass("on").addClass("off");
    }
  },
  onHowWeCalcualteResultsLinkClick: function () {
    Mixpanel.trackEvent("Click on How We Calculate Your Results Link", {
      component: "401K",
    });
  },
  onLinkAccountBtnClick: function () {
    Mixpanel.trackEvent("Click on Link Account Button", {
      component: "401K",
    });
  },
  onTalkToAnAdvisorBtnClick: function () {
    Mixpanel.trackEvent("Click on Talk to an Advisor Button", {
      component: "401K",
    });
  },
  renderMetrics: function (data) {
    var self = this;
    if (typeof data.spData.dataPoints != "undefined") {
      $(".currentAge", self.$el).text(
        "AGE " + _.first(data.spData.dataPoints).age
      );
      $(".retirementAge", self.$el).text(
        "AGE " + _.last(data.spData.dataPoints).age
      );

      var lostTofees = Math.round(
        _.last(data.spData.dataPoints).percentageEarningsLostToFees
      );
      $("#percentageLeft span").html(lostTofees + "<sup>%</sup>");

      $("#feesWheel, #_401kEquityLine").html("");
      self.feesWheel = Raphael("feesWheel").percentageWheel(100 - lostTofees);
      // _401kEquityLine(data.spData.dataPoints, self.feesWheel);

      var portfolioValueNoFees,
        portfolioValueWithFee,
        totalContribution,
        leftAfterFees;
      function setSeries(inputData) {
        portfolioValueNoFees = _.map(inputData, function (val) {
          return parseInt(val.portfolioValueNoFees);
        });
        portfolioValueWithFee = _.map(inputData, function (val) {
          return parseInt(val.portfolioValueWithFees);
        });
        totalContribution = _.map(inputData, function (val) {
          return parseInt(val.cumulativeContribution);
        });
        leftAfterFees = Math.round(
          _.last(inputData).percentageEarningsLostToFees
        );
      }
      setSeries(data.spData.dataPoints);

      self.timeSeries = Raphael("_401kEquityLine")
        .drawGrid({
          paper: this,
          max: _.max(portfolioValueNoFees),
          min: _.min(portfolioValueNoFees),
          topPadding: 0,
          bottomPadding: 0,
          hideLabels: false,
          alignLabels: "left",
        })
        .drawLineSeries({
          series: portfolioValueNoFees,
          type: "areaGradient",
          fill: COLORS.NEGATIVE,
          stroke: "none",
          smooth: true,
        })
        .drawLineSeries({
          series: portfolioValueWithFee,
          type: "areaGradient",
          fill: RETIREMENT_FEE_ANALYZER_CHART_COLORS.EARNINGS,
          stroke: "#fff",
          smooth: true,
        })
        .drawLineSeries({
          series: totalContribution,
          type: "areaGradient",
          fill: RETIREMENT_FEE_ANALYZER_CHART_COLORS.CONTRIBUTIONS,
          stroke: "#fff",
          smooth: true,
        })
        .hoverGrid({
          container: "equityLineGrid",
          continuousCallback: function (e) {
            $(".ageTip")
              .show()
              .css({ left: e.layerX - 40 });
          },
          pointHoverCallback: function (i) {
            var dataPoint = data.spData.dataPoints[i];
            $("td.totalFees span").html(
              $.pcap.formatDollars(parseFloat(dataPoint.fees), false)
            );
            $("td.earnings span").html(
              $.pcap.formatDollars(
                dataPoint.portfolioValueWithFees -
                  dataPoint.cumulativeContribution,
                false
              )
            );
            $("td.contributions span").html(
              $.pcap.formatDollars(dataPoint.cumulativeContribution, false)
            );
            $(".ageTip span").text(dataPoint.age);
            self.feesWheel.update(
              Math.round(100 - dataPoint.percentageEarningsLostToFees),
              false
            );
          },
          mouseleave: function () {
            $(".ageTip").fadeOut(200);
            self.feesWheel.update(Math.round(100 - leftAfterFees), false, 50);
            $("#metrics td span").each(function () {
              $(this).html($(this).data("origin"));
            });
          },
          mouseenter: function () {
            $(".ageTip").fadeIn(200);
          },
          parentId: "equityLineGrid",
        })
        .drawBaseline()
        .moveYLabelsToFront();

      // Insert spData.annualLoss data into the benchmark indicator graphic
      var annualLoss = data.spData.annualLoss;
      $(".yourFeesIndicator").css(
        "height",
        Math.min(parseFloat(annualLoss) * 50, 150)
      );
      $(".yourFeesIndicator .feesValue").html(annualLoss + "<sup>%</sup>");

      var indicatorClass;
      if (parseFloat(annualLoss) < 1.0) {
        indicatorClass = "indicator_low";
      } else if (parseFloat(annualLoss) == 1.0) {
        indicatorClass = "indicator_medLow";
      } else if (parseFloat(annualLoss) < 2.0) {
        indicatorClass = "indicator_med";
      } else if (parseFloat(annualLoss) == 2.0) {
        indicatorClass = "indicator_medHigh";
      } else {
        indicatorClass = "indicator_high";
      }
      $(".yourFeesIndicator").attr(
        "class",
        "yourFeesIndicator " + indicatorClass
      );

      var contributions = parseFloat(
        _.last(data.spData.dataPoints).cumulativeContribution
      );
      var earnings =
        parseFloat(_.last(data.spData.dataPoints).portfolioValueWithFees) -
        contributions;
      var totalFees = $.pcap.formatDollars(
        parseFloat(data.spData.amountLostInFees),
        false
      );
      $("td.totalFees span").html(totalFees).data("origin", totalFees);
      $("td.earnings span")
        .html($.pcap.formatDollars(earnings, false))
        .data("origin", $.pcap.formatDollars(earnings, false));
      $("td.contributions span")
        .html($.pcap.formatDollars(contributions, false))
        .data("origin", $.pcap.formatDollars(contributions, false));
    }

    AppRouter.removePreloader();
  },
  renderTables: function (data) {
    var self = this;
    var withExpense = [],
      fundsWithoutExpense = [],
      othersWithoutExpense = [];
    _.each(data.spData.holdings, function (val) {
      if (val.fundFees) {
        withExpense.push(val);
      } else if (val.holdingType == "Fund" || val.holdingType == "ETF") {
        fundsWithoutExpense.push(val);
      } else {
        othersWithoutExpense.push(val);
      }
    });
    function getNameAndDescription(val) {
      var description = $.pcap.truncateString(val.description, 40);
      if (val.ticker) {
        return (
          "<div><strong>" +
          val.ticker +
          "</strong><span class='pc-datagrid__row-description'>" +
          val.description +
          "</span></div>"
        );
      }
      return "<div><strong>" + description + "</strong><span>--</span></div>";
    }

    withExpense = _.map(withExpense, function (val) {
      return [
        getNameAndDescription(val),
        val.holdingType,
        $.pcap.formatDollars(val.value, false),
        $.pcap.formatLegacyPercentage(val.fundFees, 2),
        $.pcap.formatDollars(val.feesPerYear, false),
      ];
    });
    fundsWithoutExpense = _.map(fundsWithoutExpense, function (val) {
      return [
        getNameAndDescription(val),
        val.holdingType,
        $.pcap.formatDollars(val.value, false),
        "--",
        $.pcap.formatDollars(
          val.value * (self.questionaire.expenseRatio / 10000),
          false
        ),
      ];
    });
    othersWithoutExpense = _.map(othersWithoutExpense, function (val) {
      return [
        getNameAndDescription(val),
        val.holdingType,
        $.pcap.formatDollars(val.value, false),
        "--",
        "--",
      ];
    });
    var columns = [
      {
        title: '<span class="pc-datagrid__sort-action">Funds</span>',
        className:
          "pc-datagrid__cell funds pc-datagrid__cell--401k-fee-analizer-funds",
      },
      {
        title:
          '<span class="pc-datagrid__sort-action pc-datatable__right-header">Type</span>',
        className:
          "pc-datagrid__cell pc-datagrid__cell--align-right pc-datagrid__cell--401k-fee-analizer-type",
      },
      {
        title:
          '<span class="pc-datagrid__sort-action pc-datatable__right-header">Value</span>',
        className:
          "pc-datagrid__cell pc-datagrid__cell--align-right pc-datagrid__cell--401k-fee-analizer-value",
        type: "dollar",
      },
      {
        title:
          '<span class="pc-datagrid__sort-action pc-datatable__right-header">Expense Ratio</span>',
        className:
          "pc-datagrid__cell pc-datagrid__cell--align-right pc-datagrid__cell--401k-fee-analizer-expense",
      },
      {
        title:
          '<span class="pc-datagrid__sort-action pc-datatable__right-header">Fees/YR</span>',
        className:
          "pc-datagrid__cell pc-datagrid__cell--align-right pc-datagrid__cell--401k-fee-analizer-fees",
        type: "dollar",
      },
    ];
    if (withExpense.length > 0) {
      $(".fundsWithExpense", self.$el).show();
      var table = $("#fundsWithExpenseRatio").DataTable({
        searching: false,
        retrieve: true,
        paging: false,
        info: false,
        data: withExpense,
        columns: columns,
        oClasses: {
          sSortAsc: "datatable-sort--asc",
          sSortDesc: "datatable-sort--desc",
        },
        fnRowCallback: function (nRow) {
          nRow.className = "pc-datagrid__row";
          return nRow;
        },
        initComplete: function () {
          $("#fundsWithExpenseRatio thead").addClass(
            "pc-datagrid__row--header"
          );
        },
      });
      table.clear();
      table.rows.add(withExpense).draw();
    } else {
      $(".fundsWithExpense", self.$el).hide();
    }
    if (fundsWithoutExpense.length > 0) {
      $(".fundsWithoutExpense", self.$el).show();
      var table2 = $("#fundsWithoutExpenseRatio").DataTable({
        searching: false,
        retrieve: true,
        paging: false,
        info: false,
        data: fundsWithoutExpense,
        columns: columns,
        oClasses: {
          sSortAsc: "datatable-sort--asc",
          sSortDesc: "datatable-sort--desc",
        },
        fnRowCallback: function (nRow) {
          nRow.className = "pc-datagrid__row";
          return nRow;
        },
        initComplete: function () {
          $("#fundsWithoutExpenseRatio thead").addClass(
            "pc-datagrid__row--header"
          );
        },
      });
      table2.clear();
      table2.rows.add(fundsWithoutExpense).draw();
    } else {
      $(".fundsWithoutExpense", self.$el).hide();
    }

    if (othersWithoutExpense.length > 0) {
      $(".othersWithoutExpense", self.$el).show();
      var table3 = $("#othersWithoutExpenseRatio").DataTable({
        searching: false,
        retrieve: true,
        paging: false,
        info: false,
        data: othersWithoutExpense,
        columns: [
          {
            title:
              '<span class="pc-datagrid__sort-action">Other Holdings</span>',
            className:
              "pc-datagrid__cell funds pc-datagrid__cell--401k-other-funds",
          },
          {
            title:
              '<span class="pc-datagrid__sort-action pc-datatable__right-header">Type</span>',
            className:
              "pc-datagrid__cell pc-datagrid__cell--align-right pc-datagrid__cell--401k-fee-analizer-type",
          },
          {
            title:
              '<span class="pc-datagrid__sort-action pc-datatable__right-header">Value</span>',
            className:
              "pc-datagrid__cell pc-datagrid__cell--align-right pc-datagrid__cell--401k-fee-analizer-value",
            type: "dollar",
          },
          {
            title:
              '<span class="pc-datagrid__sort-action pc-datatable__right-header">Expense Ratio</span>',
            className:
              "pc-datagrid__cell pc-datagrid__cell--align-right pc-datagrid__cell--401k-fee-analizer-expense",
          },
          {
            title:
              '<span class="pc-datagrid__sort-action pc-datatable__right-header">Fees/YR</span>',
            className:
              "pc-datagrid__cell pc-datagrid__cell--align-right pc-datagrid__cell--401k-fee-analizer-fees",
            type: "dollar",
          },
        ],
        oClasses: {
          sSortAsc: "datatable-sort--asc",
          sSortDesc: "datatable-sort--desc",
        },
        fnRowCallback: function (nRow) {
          nRow.className = "pc-datagrid__row";
          return nRow;
        },
        initComplete: function () {
          $("#othersWithoutExpenseRatio thead").addClass(
            "pc-datagrid__row--header"
          );
        },
      });
      table3.clear();
      table3.rows.add(othersWithoutExpense).draw();
    } else {
      $(".othersWithoutExpense", self.$el).hide();
    }
  },

  adjustExpenseManualExpenseRatio: function (e, ui) {
    var expenseRatio = Math.round(ui.value / 10) / 1000;
    $("#fundsWithoutExpenseRatio td.fees").each(function () {
      var value = $.pcap.stripDollarFormatting(
        $(this).siblings(".value").text()
      );
      $(this).text($.pcap.formatDollars(value * expenseRatio, false));
    });
  },
  buildSlider: function (selector) {
    var self = this;
    function buildRangeLabels(min, max, formatter, maxLabel) {
      var maxString = formatter(max);
      if (maxLabel) {
        maxString = maxLabel;
      }
      return (
        '<span class="min">' +
        formatter(min) +
        '</span><span class="max">' +
        maxString +
        "</span>"
      );
    }
    this.$el.find(selector).each(function () {
      var min = parseFloat($(this).data("min"));
      var max = parseFloat($(this).data("max"));
      var maxLabel = $(this).data("max_label");
      var callback = $(this).data("callback");
      var formatter = self.sliderFormatters[$(this).data("formatter")];

      if ($(this).data("val") > max) {
        $(this).data("val", max);
      }

      $(this)
        .slider({
          range: "min",
          value: $(this).data("val"),
          min: min,
          max: max,
          slide: function (event, ui) {
            $(event.target).find("span.tip").text(formatter(ui.value));
          },
          change: function (event, ui) {
            if (formatter == "dollars") {
              // round to 110s
              self.questionaire[$(this).data("property")] =
                Math.round(ui.value / 100) * 100;
            } else {
              self.questionaire[$(this).data("property")] = ui.value;
            }

            self.requestAndBuildChart();
            if (callback) {
              self[callback](event, ui);
            }
          },
        })
        .find(".ui-slider-handle")
        .mousedown(function (e) {
          $(this).addClass("activated");
        })
        .html(
          '<span class="tip">' + formatter($(this).data("val")) + "</span>"
        );
      $(buildRangeLabels(min, max, formatter, maxLabel)).insertBefore(
        $(this).find(".ui-slider-handle")
      );
    });

    $("body").mouseup(function (e) {
      $(".ui-slider-handle").removeClass("activated");
    });
  },
  sliderFormatters: {
    dollars: function (val) {
      return $.pcap.formatDollars(Math.round(val / 100) * 100, false);
    },
    percentage: function (val) {
      return (val / 100).toFixed(1) + "%";
    },
    basisPoints: function (val) {
      return (val / 100.0).toFixed(2) + "%";
    },
    basisPointsToTenths: function (val) {
      return (Math.round(val / 10) / 10).toFixed(1) + "%";
    },
    none: function (val) {
      return val;
    },
  },
  formatPercentages: function (collection) {
    // transform percentages for slider ranges
    collection.maximumAnnualGrowth *= 100;
    collection.minimumAnnualGrowth *= 100;
    collection.maximumExpenseRatio *= 100;
    collection.minimumExpenseRatio *= 100;
    collection.expenseRatio *= 100;
    collection.annualPerformance *= 100;
    // transform admin fees basis points
    collection.maximumPlanAdministrationFees *= 100;
    collection.minimumPlanAdministrationFees *= 100;
    collection.planAdminFees *= 100;
    return collection;
  },
  requestAndBuildChart: function () {
    if (!this.modelLoaded || !this.accountsLoaded) {
      return;
    }

    /*
     * IE8 needed the second param to be empty object
     * http://stackoverflow.com/questions/2569722/jquery-extend-not-working-in-internet-explorer-but-works-in-firefox
     */
    var q = $.extend(true, {}, this.questionaire);

    var changes = {
      contributionPerYear: q.contributionPerYear,
      annualPerformance: Math.round(q.annualPerformance / 10) / 10,
      employeeContributionPerYear: q.employeeContributionPerYear,
      planAdminFee: Math.round(q.planAdminFees) / 100,
      defaultExpenseRatio: q.expenseRatio / 100,
    };

    this.model.set(changes);

    var params = {
      personId: this.model.get("id"),
      person: JSON.stringify(this.model.attributes),
      source: "401k",
    };

    Services.Person.update(params, this.onUpdatePerson, this);
  },

  onUpdatePerson: function (error, response) {
    var self = this;

    if (typeof response.spData != "undefined") {
      Services.Get401kFees.get(
        { userAccountIds: JSON.stringify(self.selectedAccountIds) },
        function (error, response) {
          if (typeof response.spData != "undefined") {
            this.renderMetrics(response);
            this.triggerIframeResize();
          }
        },
        this
      );
    }
  },

  requestAndBuildChartFromQuestionnaire: function () {
    // render the feature page only if there are any 401k accounts
    // TODO: have some sort of account model/service that could
    //      determine if there are any 401k accounts rather than
    //      view property like this.
    if (this.haveNonTaxableAccounts == false) {
      return;
    }
    var self = this;
    Services.Get401kFees.get(
      { userAccountIds: JSON.stringify(self.selectedAccountIds) },
      function (err, data) {
        self.questionaire = self.formatPercentages(
          $.extend(true, {}, data.spData)
        );
        self.$el
          .find(".parameters")
          .html(sliders401kTemplate(self.questionaire));
        $(".toolTipped", this.$el).tipTip();
        self.buildSlider(".parameters .slider");
        self.renderMetrics(data);
        self.triggerIframeResize();
      }
    );
  },
  assumptionsUpdated: function (assumptions) {
    // get the fees based on updated assumptions
    $(".editQuestionnaire", this.$el).text("Edit Assumptions");
    $(".questionnaireContainer", this.$el).animate(
      { height: "0" },
      500,
      function () {
        $(".editAssumptions", this.$el).css("border-bottom", "1px solid #fff");
        $(".submitForm", this.$el).hide();
        $(".editQuestionnaire", this.$el)
          .css("border-bottom", "1px solid #fff")
          .addClass("gray")
          .data("mode", "closed");
      }
    );
    this.requestAndBuildChartFromQuestionnaire();
  },
  triggerIframeResize() {
    if (IS_IFRAMED) {
      IframeClient.getInstance().triggerResize();
    }
  },
});

if (IS_IFRAMED) {
  _.extend(_401kView.prototype, NoopFixedControlsMixin);
} else {
  _.extend(_401kView.prototype, FixedControlsMixin);
}

_.extend(_401kView.prototype, ContactAdvisorMixin);

export default _401kView;
