import React from "react";
import Input from "components/common/form/Input";
import Select from "components/common/form/Select";

function getQaHook(fieldPartId) {
  if (!fieldPartId) {
    return "";
  }

  return `qa-${fieldPartId.toLowerCase()}`;
}

/**
 * Generate the jsx for each field part passed to this function.
 *
 * @param {object} part The part for which to render jsx.
 * @param {number} partIndex The index to use as the part's key.
 * @param {boolean} isPassword Is the part part of a password field?
 * @param {boolean} isRequired Is the part part of required field?
 * @param {Element} inputRef the form element.
 * @param {function} onChange Callback
 * @returns {object} The jsx for the part.
 */
const renderFieldPart = (
  part,
  partIndex,
  isPassword,
  isRequired,
  inputRef,
  onChange
) => {
  switch (part.type) {
    case "IMAGE":
      return (
        <div className="captcha" key={partIndex}>
          <img src={`data:image/png;base64,${part.image}`} />
        </div>
      );
    case "OPTIONS":
      return (
        <Select
          className="input--tiny input--full"
          key={partIndex}
          name={part.id}
        >
          {part.validIds.map((id, idIndex) => {
            return (
              <option value={id} key={idIndex}>
                {part.validValues[idIndex]}
              </option>
            );
          })}
        </Select>
      );
    case "TEXT":
    default:
      return (
        <Input
          key={partIndex}
          type={
            isPassword === true ||
            part.type === "IF_PASSWORD" ||
            part.type === "IF_CUSTOM" ||
            part.type === "PASSWORD"
              ? "password"
              : "text"
          }
          className={`input--tiny ${getQaHook(part.id)}`}
          name={part.id}
          maxLength={part.maxLength}
          sizeVariation={"full"}
          placeholder={part.placeholderValue || ""}
          autoComplete="off"
          ref={inputRef}
          onChange={onChange}
          validator={
            isRequired
              ? {
                  type: part.characterSet === "NUMERIC" ? "number" : "string",
                  required: true,
                  allowEmpty: false,
                }
              : null
          }
          value={part.value ? part.value : ""}
        />
      );
  }
};

/**
 * Generate the jsx for each field passed to this function.
 *
 * @param {object} field The field for which to generate jsx.
 * @param {number} fieldIndex The index to use as the field's key.
 * @param {Element} inputRef the form element
 * @param {function} onChange Callback
 * @param {boolean} showRequiredMarker Show asterisk for required fields on form load or not
 * @returns {*} The jsx for the field.
 */
export const renderAccountField = (
  field,
  fieldIndex,
  inputRef,
  onChange,
  showRequiredMarker = false
) => {
  const { isPassword, isRequired } = field;

  const parts = field.parts.map((part, partIndex) => {
    return renderFieldPart(
      part,
      partIndex,
      isPassword,
      isRequired,
      inputRef,
      onChange
    );
  });

  return (
    <div
      className={`pc-layout--flush pc-form-group js-edit-account-field-${
        isRequired ? "required" : "optional"
      }`}
      key={fieldIndex}
    >
      <div className="pc-layout__item pc-u-1/3">
        <label
          className="pc-label--tiny"
          title={showRequiredMarker && isRequired ? "Required" : ""}
        >
          {field.label}
        </label>
      </div>

      <div className="pc-layout__item pc-u-2/3 js-field-parts">{parts}</div>
    </div>
  );
};
