import PropTypes from "prop-types";
import React, { Component } from "react";
import Modal from "components/modal/Modal";
import { noop } from "underscore";
import ConfirmView from "common/ConfirmModal/ConfirmView";

class ConfirmModal extends Component {
  // TODO close on x click
  constructor(props) {
    super(props);
    this.setFocus = this.setFocus.bind(this);
  }

  setFocus() {
    if (this.confirmButton) {
      this.confirmButton.focus();
    }
  }

  render() {
    const {
      title,
      isOpen,
      className,
      children,
      onCancel,
      cancelLabel,
      onConfirm,
      confirmLabel,
      loading,
      showConfirmationButton,
      confirmButtonClassName,
      onClickContainer,
      showCloseButton,
      onClosed,
      disabled
    } = this.props;

    return (
      // TODO reference Jira ticket to address eslint disabling below
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div onClick={onClickContainer} className="js-confirm-modal-container">
        <Modal
          className="pc-modal pc-modal--small qa-confirm-modal"
          componentName="ConfirmModal"
          title={title}
          contentLabel="Confirm Modal"
          isOpen={isOpen}
          onAfterOpen={this.setFocus}
          shouldCloseOnOverlayClick={false}
          loading={loading}
          showCloseButton={showCloseButton}
          onClosed={onClosed}
        >
          <ConfirmView
            className={className}
            content={children}
            onCancel={onCancel}
            cancelLabel={cancelLabel}
            onConfirm={onConfirm}
            confirmLabel={confirmLabel}
            showConfirmationButton={showConfirmationButton}
            confirmButtonClassName={confirmButtonClassName}
            buttonRef={(el) => {
              this.confirmButton = el;
            }}
            disabled={!!disabled}
          />
        </Modal>
      </div>
    );
  }
}

ConfirmModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  confirmButtonClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickContainer: PropTypes.func,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  showConfirmationButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  onClosed: PropTypes.func,
  disabled: PropTypes.bool
};

ConfirmModal.defaultProps = {
  className: undefined,
  title: undefined,
  isOpen: false,
  onCancel: noop,
  onClickContainer: noop,
  onConfirm: noop,
  cancelLabel: "Cancel",
  confirmButtonClassName: "pc-btn--primary",
  confirmLabel: "Confirm",
  children: undefined,
  loading: false,
  showConfirmationButton: true,
  showCloseButton: false,
  onClosed: noop,
};

export default ConfirmModal;
