import React from "react";
import PropTypes from "prop-types";
import RadioGroup from "components/common/form/RadioGroup";
import FancySelect from "components/common/form/FancySelect";
import CheckboxGroup from "components/common/form/CheckboxGroup";
import SelectGroup from "components/common/form/SelectGroup";
import DatePickerInput from "components/common/form/DatePickerInput";
import Input from "components/common/form/Input";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import InfoTooltipText from "components/common/InfoTooltipText";
import { isFunction, noop } from "underscore";
import { DISPLAY_FORMAT } from "libs/pcap/utils/date2";
import { get } from "object-path";
import ButtonOptions from "components/common/ButtonOptions/ButtonOptions";
import AdvisorMessageBox from "../../../empower/components/AdvisorMessageBox/AdvisorMessageBox";
import { get as getValue } from "underscore";
import ButtonSelect from "components/common/form/ButtonSelect";
import ExpandMoreIcon from "svg-icons/ExpandMore.svg";
import { components } from "react-select";

function getModelValue(model, path, isShallow) {
  if (isShallow) {
    return model[path];
  }

  return get(model, path);
}

const PERSON_IDS = [
  "primaryOwnerId",
  "secondaryOwnerId",
  "custodian",
  "minorName",
  "executorName",
  "wardName",
  "guardianName",
];

const isPersonSelect = (id) => {
  return PERSON_IDS.includes(id);
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ExpandMoreIcon className="expand-more-icon" />
    </components.DropdownIndicator>
  );
};

const SelectInput = (props) => {
  return (
    <components.Input
      {...props}
      aria-required={props.selectProps.validator.required}
    />
  );
};

SelectInput.propTypes = {
  selectProps: PropTypes.oneOfType([
    PropTypes.shape({
      validator: PropTypes.shape({
        required: PropTypes.bool,
      }),
    }),
    PropTypes.any,
  ]),
};

SelectInput.defaultProps = {
  selectProps: {},
};

/**
 * Renders a set of fields dynamically based the configuration provided in `fieldsConfig` prop.
 * The format of the configuration object is defined
 * [here](https://personalcapital.jira.com/wiki/spaces/PFA/pages/22085658/Account#FormField).
 *
 * By default, the layout of the form is **vertical**. The label is on top of the input field.
 * Pass `className="field-set--horizontal"` to change the layout to **horizontal**. That moves
 * the label to the same line as the inputs.
 *
 * @param {Object} props properties for the component.
 * @param {Object} props.model the reference to the model object.
 *                             This is where the values on the form are set to or read from.
 * @param {Object} props.fieldConfig the configuration of the fields on the form.
 *                                   [Format](https://personalcapital.jira.com/wiki/spaces/PFA/pages/22085658/Account#FormField).
 * @param {Function} props.refFunc the to retrieve the reference to a field on the form.
 * @param {Function} props.onChange the on change callback.
 * @param {String} props.className an optional class
 * @param {Boolean} props.addTooltipToSelectOptions when true tooltips will be added to select options
 * @param {Element} props.customOptionsElement a React element used to render the custom options
 * @param {String} props.inputNamePrefix an identifier for the input field
 * @param {Function} propts.onInit the on init callback
 * @returns {React.ReactNode} the component.
 */
export default function FieldSet({
  model,
  fieldsConfig,
  refFunc,
  onChange,
  className,
  labelClassName,
  shallowModelPropertyPath,
  addTooltipToSelectOptions,
  onClick,
  customOptionsElement,
  inputNamePrefix,
  CustomAccountNumberComponent,
  onInit,
  shouldFormatLabel,
  ariaLabelledById,
  nonPrimaryButtonClass,
}) {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return fieldsConfig.map((f, index) => {
    const setFieldKey = () => f.key ?? f.label.concat("_", index);
    const fieldKey = setFieldKey();

    if (f.depends) {
      const { name, operation, value } = f.depends;
      const modelValue = getModelValue(model, name, shallowModelPropertyPath);
      switch (operation) {
        case "=": {
          if (value !== modelValue) {
            return null;
          }
          break;
        }
        case "=~": {
          const reg = new RegExp(value);
          if (!reg.test(modelValue)) {
            return null;
          }
          break;
        }
        default:
      }
    }

    return (
      <Field
        key={fieldKey}
        className={`${
          className ?? ""
        } field_set__${f.parts[0].type.toLowerCase()}--${f.parts[0].name.replace(
          /\./g,
          "-"
        )} ${
          // eslint-disable-next-line no-nested-ternary
          f.parts[0].type === "CHECKBOX" && f.parts[0].options
            ? "field-set__checkbox--group"
            : f.parts[0].type === "CHECKBOX"
            ? "field-set__checkbox--input"
            : ""
        }`}
        labelClassName={labelClassName}
        fieldConfig={f}
        model={model}
        shallowModelPropertyPath={shallowModelPropertyPath}
        refFunc={refFunc}
        onChange={onChange}
        addTooltipToSelectOptions={addTooltipToSelectOptions}
        onClick={onClick}
        customOptionsElement={customOptionsElement}
        inputNamePrefix={inputNamePrefix}
        CustomAccountNumberComponent={CustomAccountNumberComponent}
        onInit={onInit}
        shouldFormatLabel={shouldFormatLabel}
        ariaLabelledById={
          ariaLabelledById ? `${ariaLabelledById}-${f.parts[0]?.name}` : null
        }
        nonPrimaryButtonClass={nonPrimaryButtonClass}
      />
    );
  });
}

const getOptionsWithTooltip = (options) => {
  return options.map((option) => ({
    value: option.value,
    label: (
      <InfoTooltipText
        className=""
        tooltipContent={option.label}
        text={<div className="u-clip-text">{option.label}</div>}
        WrapperElement="div"
      ></InfoTooltipText>
    ),
  }));
};

/**
 *  Evaluates string expression.
 *
 * @param {object} model - Data model.
 * @param {string} expression - The expression string to evaluate.
 * @returns {bool} - The result evaluation of expression (True/False).
 */
const getConformByExpression = (model, expression) => {
  const variableRegex = /[a-zA-Z_$][a-zA-Z_$0-9.]*/g;
  const variableNames = new Set(expression.match(variableRegex));

  variableNames.forEach((variableName) => {
    const propertyValue = getValue(model, variableName.split("."));
    expression = expression
      .split(variableName)
      .join(propertyValue ? `"${propertyValue}"` : `""`);
  });

  const code = `return ${expression};`;
  /* eslint-disable no-new-func */
  const evaluator = new Function(code);

  return evaluator();
};

function Field({
  model,
  fieldConfig,
  refFunc,
  onChange,
  className,
  labelClassName,
  shallowModelPropertyPath,
  addTooltipToSelectOptions,
  onClick,
  customOptionsElement,
  inputNamePrefix,
  CustomAccountNumberComponent,
  onInit,
  shouldFormatLabel,
  ariaLabelledById,
  nonPrimaryButtonClass,
}) {
  const isMultiPart = fieldConfig.parts.length > 1;

  if (
    fieldConfig.label.toLowerCase() === "account number" &&
    CustomAccountNumberComponent
  ) {
    const part = fieldConfig.parts[0];

    return (
      <CustomAccountNumberComponent
        model={model}
        validator={part.validator}
        refFunc={refFunc}
        onChange={onChange}
      />
    );
  }

  let customComponents = {};
  if (className.includes("account-opening-v2")) {
    customComponents = { DropdownIndicator, Input: SelectInput };
  }

  return (
    <>
      {fieldConfig.verbatimMessage ? (
        <AdvisorMessageBox message={fieldConfig.verbatimMessage} />
      ) : null}
      <div
        className={`field-set ${
          isMultiPart ? className.split(".")[0] : className
        } qa-input-field`}
      >
        <label
          htmlFor={fieldConfig.parts[0]?.name}
          className="pc-label pc-u-mb- field-set__label"
          {...(ariaLabelledById ? { id: ariaLabelledById } : {})}
        >
          {fieldConfig.label}
          {fieldConfig.informationalText && (
            <InfoTooltipIcon title={fieldConfig.informationalText} />
          )}
        </label>
        <FieldBody isMultiPart={isMultiPart}>
          {
            // eslint-disable-next-line sonarjs/cognitive-complexity
            fieldConfig.parts.map((part, index) => {
              const value = getModelValue(
                model,
                part.name,
                shallowModelPropertyPath
              );
              const identifier = inputNamePrefix
                ? inputNamePrefix.concat(".", part.name)
                : part.name;

              let extraProps = {};

              if (part.formattingOptions) extraProps.onInit = onInit;
              if (part.validator?.continueValidation) {
                const exp = JSON.parse(part.validator.continueValidation);
                let stringExpression = exp[0].expression;
                const conform = () =>
                  !getConformByExpression(model, stringExpression);
                part.validator = {
                  ...part.validator,
                  conform: conform,
                  messages: {
                    ...part.validator.messages,
                    conform: exp[0].message,
                  },
                };
              }
              switch (part.type) {
                case "RADIO":
                  return (
                    <RadioGroup
                      key={identifier.concat("_", index)}
                      id={identifier}
                      name={identifier}
                      value={value}
                      options={part.options}
                      validator={part.validator}
                      helpText={fieldConfig.hint}
                      disabled={!part.isEnabled}
                      ref={refFunc}
                      onChange={onChange}
                      labelClassName={labelClassName}
                      shouldFormatLabel={shouldFormatLabel}
                      {...(ariaLabelledById ? { ariaLabelledById } : {})}
                    />
                  );
                case "OPTIONS":
                  return (
                    <FancySelect
                      containerClassName={
                        isMultiPart
                          ? "field-set__multipart-item"
                          : "field-set__options"
                      }
                      key={identifier.concat("_", index)}
                      id={identifier}
                      name={identifier}
                      value={value}
                      options={
                        addTooltipToSelectOptions
                          ? getOptionsWithTooltip(part.options)
                          : part.options
                      }
                      validator={part.validator}
                      helpText={fieldConfig.hint}
                      ref={refFunc}
                      onChange={onChange}
                      isDisabled={!part.isEnabled}
                      placeholder={part.placeholder}
                      menuFooterComponent={
                        isPersonSelect(part.name) ? (
                          <div className="person-selector-footer">
                            <a
                              href="#/settings/profile/add-person"
                              className="pc-btc pc-btn--link pc-btn--tiny"
                            >
                              Add Household Member
                            </a>
                          </div>
                        ) : null
                      }
                      {...(ariaLabelledById
                        ? { inputId: ariaLabelledById }
                        : {})}
                      components={customComponents}
                      selectLabel={part.label}
                    />
                  );
                case "MULTI_OPTIONS":
                  return (
                    <SelectGroup
                      key={identifier.concat("_", index)}
                      id={identifier}
                      name={identifier}
                      value={value}
                      options={part.options}
                      validator={part.validator}
                      helpText={fieldConfig.hint}
                      ref={refFunc}
                      onChange={onChange}
                      disabled={!part.isEnabled}
                    />
                  );
                case "CUSTOM_OPTIONS":
                  return isFunction(customOptionsElement)
                    ? customOptionsElement(part, value, index)
                    : customOptionsElement;
                case "CHECKBOX":
                  if (part.options === undefined) {
                    return (
                      <Input
                        type={part.type}
                        key={identifier.concat("_", index)}
                        id={identifier}
                        checked={value}
                        value={value?.toString() || ""}
                        name={identifier}
                        helpText={fieldConfig.hint}
                        validator={part.validator}
                        ref={refFunc}
                        onChange={onChange}
                        disabled={!part.isEnabled}
                      />
                    );
                  }
                  return (
                    <CheckboxGroup
                      key={identifier.concat("_", index)}
                      id={identifier}
                      name={identifier}
                      value={value}
                      options={part.options}
                      validator={part.validator}
                      helpText={fieldConfig.hint}
                      ref={refFunc}
                      onChange={onChange}
                      disabled={!part.isEnabled}
                    />
                  );
                case "DATE":
                  return (
                    <DatePickerInput
                      key={identifier.concat("_", index)}
                      id={identifier}
                      name={identifier}
                      value={value}
                      helpText={fieldConfig.hint}
                      ref={refFunc}
                      onChange={onChange}
                      validator={Object.assign(
                        {},
                        part.validator,
                        DatePickerInput.getValidator(
                          Object.assign(
                            {
                              valueDateFormat: DISPLAY_FORMAT,
                            },
                            part.validator
                          )
                        )
                      )}
                      displayDateFormat={DISPLAY_FORMAT}
                      disabled={!part.isEnabled}
                    />
                  );
                case "IMAGE":
                  return (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                      key={identifier.concat("_", index)}
                      src={`data:image/png;base64,${part.image}`}
                    />
                  );
                case "BUTTON_OPTIONS":
                  return (
                    <ButtonOptions
                      key={identifier.concat("_", index)}
                      id={identifier}
                      name={identifier}
                      options={part.options}
                      onClick={onClick}
                      unsorted={part.unsorted}
                      value={value}
                      nonPrimaryButtonClass={nonPrimaryButtonClass}
                    />
                  );
                case "BUTTON_SELECT":
                  return (
                    <ButtonSelect
                      btnClassName={`button-select__item--large u-preserve-case button-select__item--rebrand`}
                      id={identifier}
                      inputType={part.inputType}
                      key={identifier.concat("_", index)}
                      multiple={part.multiple}
                      name={identifier}
                      onChange={onChange}
                      options={part.options}
                      ref={refFunc}
                      showRadio={part.showRadio}
                      validator={part.validator}
                      value={value}
                    />
                  );
                case "TEXT":
                case "PASSWORD":
                default:
                  return (
                    <Input
                      type={part.type === "PASSWORD" ? "password" : "text"}
                      sizeVariation={"full"}
                      containerClassName={
                        isMultiPart ? "field-set__multipart-item" : undefined
                      }
                      key={identifier.concat("_", index)}
                      id={identifier}
                      name={identifier}
                      value={value || ""}
                      validator={part.validator}
                      maxLength={part.maxLength}
                      helpText={fieldConfig.hint}
                      ref={refFunc}
                      onChange={onChange}
                      disabled={!part.isEnabled}
                      placeholder={part.placeholder}
                      formattingOptions={part.formattingOptions}
                      errorBlockClassName={"u-sentence-case"}
                      {...extraProps}
                    />
                  );
              }
            })
          }
        </FieldBody>
      </div>
    </>
  );
}

Field.propTypes = {
  model: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  refFunc: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  shallowModelPropertyPath: PropTypes.bool,
  addTooltipToSelectOptions: PropTypes.bool,
  onClick: PropTypes.func,
  customOptionsElement: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  inputNamePrefix: PropTypes.string,
  CustomAccountNumberComponent: PropTypes.func,
  onInit: PropTypes.func,
  shouldFormatLabel: PropTypes.bool,
  ariaLabelledById: PropTypes.string,
  nonPrimaryButtonClass: PropTypes.string,
};

Field.defaultProps = {
  refFunc: noop,
  onChange: noop,
  // It only use for 'ButtonOptions' type
  onClick: noop,
  className: "",
  labelClassName: undefined,
  // When `true`, turns off the default behavior where the input names on the form
  // are treated as the path to the deep properties on the model.
  shallowModelPropertyPath: false,
  addTooltipToSelectOptions: false,
  customOptionsElement: null,
  inputNamePrefix: null,
  CustomAccountNumberComponent: undefined,
  onInit: noop,
  shouldFormatLabel: true,
  ariaLabelledById: undefined,
  nonPrimaryButtonClass: "",
};

function FieldBody({ isMultiPart, children }) {
  return isMultiPart ? (
    <div className="field-set__multipart-body">{children}</div>
  ) : (
    children
  );
}

FieldBody.propTypes = {
  isMultiPart: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
