import React from "react";
import PropTypes from "prop-types";
import RemoveAccountContainer from "./RemoveAccountContainer";

const organizationNameCash = "Empower Personal Cash";
export default class RemoveAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleAccountRemoved = this.handleAccountRemoved.bind(this);
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleAccountRemoved() {
    this.setState({ isOpen: false });
    this.props.onAccountRemoved();
  }

  render() {
    const { isOpen } = this.state;

    const removeAccountContainerProps = Object.assign({}, this.props, {
      isOpen,
      onCancel: this.handleClose,
    });

    return (
      <>
        <button
          type="button"
          className={`js-cta_remove-button pc-btn ${this.props.className}`}
          onClick={this.handleOpen}
          aria-label={this.props.buttonText}
          title={this.props.buttonText}
        >
          {this.props.buttonText}
        </button>
        <RemoveAccountContainer
          {...removeAccountContainerProps}
          onAccountRemoved={this.handleAccountRemoved}
        />
      </>
    );
  }
}

RemoveAccount.propTypes = {
  componentName: PropTypes.string.isRequired,
  service: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  modalCancelButtonText: PropTypes.string,
  modalConfirmButtonText: PropTypes.string,
  modalBody: PropTypes.element,
  modalTitle: PropTypes.string,
  onAccountRemoved: PropTypes.func,
  className: PropTypes.string,
};

RemoveAccount.defaultProps = {
  className: "",
  buttonText: "Cancel application",
  modalCancelButtonText: "No, go back",
  modalConfirmButtonText: "Yes, cancel",
  modalBody: (
    <p>
      Are you sure you would like to cancel your {organizationNameCash}
      <sup>&trade;</sup> application?
    </p>
  ),
  modalTitle: "Cancel Application",
  onAccountRemoved: () => {
    location.href = DASHBOARD_URL;
  },
};
