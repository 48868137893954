import React from "react";
/**
 * Higher order component that wraps around another component to provide the functionality of self unmounting based on the timeout passed by prop
 *
 * Usage: const SelfUnmountToastNotification = selfUnmount(ToastNotification);
 * Then use <SelfUnmountToastNotification /> instead of <ToastNotification />
 * @param {Component} WrappedComponent  The component to provide the self unmoun for
 * @param {Number} timeout  The timeout for hte component to unmount in ms
 * @returns {Component} The wrapped component that will be able use these tooltip features
 */
function unmountWithTimeout(WrappedComponent, timeout) {
  return class wrappedComponentUnmountWithTimeout extends React.Component {
    constructor() {
      super(...arguments);
      this.state = {
        isDisplayed: true,
      };
    }

    componentDidMount() {
      setTimeout(() => {
        this.setState({ isDisplayed: false });
      }, timeout);
    }

    render() {
      return (
        <div>
          {this.state.isDisplayed && <WrappedComponent {...this.props} />}
        </div>
      );
    }
  };
}

export default unmountWithTimeout;
