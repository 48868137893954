import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import Input from "components/common/form/Input";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import React from "react";
import deepCopy from "deep-copy";
import Column from "libs/pcap/table/Column";
import Mixpanel from "mixpanel";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

export default class StockOptionsEditRow extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: deepCopy(props.model),
      loading: false,
      formUpdated: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidMount() {
    let grantNameInput = this.inputElements.find(
      (input) => input.props.name === "grantName"
    );
    if (grantNameInput) {
      grantNameInput.focus();
    }
  }

  handleSubmit(ev) {
    ev.preventDefault();
    if (this.validate().valid) {
      this.setState({ loading: true });
      this.props
        .onSubmit(this.state.model)
        .then((holding) => {
          Mixpanel.trackEvent("Submitted Grant edit form", {
            component: "Stock Options",
          });
          if (this.props.onSaved) {
            this.props.onSaved(holding);
          }
          this.setState({ loading: false });
        })
        .catch((errors) => {
          this.setState({ errors, loading: false });
        });
    }
  }

  handleRemove(data) {
    this.setState({ loading: true });
    this.props
      .onRemove(data)
      .then(() => this.setState({ loading: false }))
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });
  }

  render() {
    const { columns, onCancel, schema, data } = this.props;
    const { model, loading } = this.state;
    const isPrivileged = isEmpowerPrivilegedMode();
    return (
      <form
        className="table__row--edit pc-bg-dark qa-add-holding-form"
        onSubmit={this.handleSubmit}
      >
        {this.state.errors && (
          <Message className="pc-u-mh-" messages={this.state.errors} />
        )}
        <LoadingOverlay active={loading} />
        <div className="stock-options-table__edit-row-inner">
          <div className={`table__column ${columns[0].className}`}>
            <Input
              ref={this.storeInputRef}
              className={"qa-add-grant-grant-name"}
              type="text"
              name="grantName"
              placeholder={"Grant name"}
              value={model.grantName}
              validator={schema.properties.grantName}
              errorBlockClassName="is-hidden"
              onChange={function () {
                this.handleInputChange(...arguments);
                this.setState({ formUpdated: true });
              }.bind(this)}
              sizeVariation="full"
            />
          </div>
          <div className={`table__column ${columns[1].className}`}>
            <Input
              ref={this.storeInputRef}
              type="number"
              name="vestedShares"
              placeholder={"Vested shares"}
              value={model.vestedShares}
              validator={schema.properties.totalShares}
              errorBlockClassName="is-hidden"
              onChange={function () {
                this.handleInputChange(...arguments);
                this.setState({ formUpdated: true });
              }.bind(this)}
              sizeVariation="full"
            />
          </div>
          <Column data={model} {...columns[2]} />
          <Column data={model} {...columns[3]} />
          <Column data={model} {...columns[4]} />
          <Column data={model} {...columns[5]} />
          <Column data={model} {...columns[6]} />
        </div>

        <div className="stock-options-table__edit-row-inner">
          <div className="table__column table__column--stock-options-edit-actions">
            <button
              type="button"
              className={`pc-btn pc-btn--small  pc-btn--danger qa-remove-stock-options ${
                isPrivileged ? "is-disabled" : ""
              }`}
              onClick={() => this.handleRemove(data)}
            >
              Remove
            </button>
            <button
              type="button"
              className="pc-btn pc-btn--small qa-close-edit-stock-options"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`pc-btn pc-btn--small pc-btn--primary qa-save-stock-option ${
                isPrivileged ? "is-disabled" : ""
              }`}
              disabled={!this.state.formUpdated}
            >
              Done
            </button>
          </div>
        </div>
      </form>
    );
  }
}

StockOptionsEditRow.defaultProps = {
  schema: {
    type: "object",
    properties: {
      grantName: {
        required: true,
        type: "string",
        allowEmpty: false,
      },
      vestedShares: {
        required: true,
        type: "number",
        allowEmpty: false,
      },
    },
  },
};

StockOptionsEditRow.propTypes = {
  model: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onSaved: PropTypes.func,
  accountName: PropTypes.string,
};
