const CITIZENSHIP_STATUS_US_CITIZEN = "US_CITIZEN";
const COUNTRY_US = "US";

/**
 * Verifies that the supplied person model is *not* a US citizen.
 * The citizenship status must be defined.
 *
 * @param {Object} person the person object
 * @param {String} person.citizenshipStatus the citizenship status
 * @return {Boolean} `true` if `citizenshipStatus` is defined and
 *                    set to anything except "US_CITIZEN", `false`
 *                    otherwise.
 */
export function nonUsCitizen({ citizenshipStatus }) {
  return (
    citizenshipStatus != null &&
    citizenshipStatus !== CITIZENSHIP_STATUS_US_CITIZEN
  );
}

/**
 * Verifies that the supplied person model is a US citizen who was
 * born outside of the US.
 * The citizenship status and country of birth must be defined.
 *
 * @param {Object} person the person object
 * @param {String} person.citizenshipStatus the citizenship status
 * @return {Boolean} `true` if `citizenshipStatus` is defined and
 *                    set to anything except "US_CITIZEN", `false`
 *                    otherwise.
 */
export function citizenBornOutsideOfUs({ countryOfBirth, citizenshipStatus }) {
  if (!countryOfBirth) {
    return false;
  }

  return (
    countryOfBirth !== COUNTRY_US &&
    citizenshipStatus === CITIZENSHIP_STATUS_US_CITIZEN
  );
}

export const eligibility = () => {
  if (window) {
    if (window.integratedEligibility) {
      return window.integratedEligibility;
    }
    if (window.eligibility) {
      return window.eligibility;
    }
  }

  if (global) {
    if (global.integratedEligibility) {
      return global.integratedEligibility;
    }
    if (global.eligibility) {
      return global.eligibility;
    }
  }
};
