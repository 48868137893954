/* global modalStack */
/* eslint-disable camelcase */
import Backbone from "backbone";
import { find, noop } from "underscore";
import deepCopy from "deep-copy";
import { USER_MSG_TYPE } from "components/interjection/helpers";
import {
  getUserMessagesPromise,
  getPostLoginActionPromise,
  getUIPreferencesPromise,
  renderPostLoginAction,
  screenUserMessagesFor,
  getLatestUserMessages,
} from "views/components/postLoginActions/PostLoginActionsUtils";
import { injectReferralPromotionIntoNavBar } from "views/components/postLoginActions/helpers";
import {
  SESSION_WIDE_CUSTOM_APPT_SCHEDULER,
  handlePersistentCustomContent,
} from "libs/pcap/utils/customAppointmentSchedulerUtils";

export const REFERRAL_GIFT_BOX_ICON_DISPLAY_LOCATION = "#/web-app-menu";

const isAppointmentHash = () =>
  window.location.hash.includes("#/appointment") ||
  window.location.hash.includes("#appointment");

/**
 * Called from router.js > initApp()
 */
const PostLoginActionsView = Backbone.View.extend({
  initialize: function () {
    const getUserMsgsPromise = getUserMessagesPromise().then((userMessages) => {
      let showReferralPromotionInNavBar = false;
      userMessages.forEach((msg) => {
        if (
          msg.displayLocations &&
          msg.displayLocations.includes(REFERRAL_GIFT_BOX_ICON_DISPLAY_LOCATION)
        ) {
          showReferralPromotionInNavBar = msg;
        } else if (msg.viewTemplate === SESSION_WIDE_CUSTOM_APPT_SCHEDULER) {
          handlePersistentCustomContent(msg);
        }
      });
      if (showReferralPromotionInNavBar) {
        injectReferralPromotionIntoNavBar(showReferralPromotionInNavBar);
      }
      return userMessages;
    });

    Promise.all([
      getPostLoginActionPromise(),
      getUserMsgsPromise,
      getUIPreferencesPromise(),
    ])
      .then(([postLoginActionData, userMessages, features]) => {
        const userMessage = find(
          userMessages,
          (msg) => msg.userMessageId == postLoginActionData.userMessageId // eslint-disable-line eqeqeq
        );

        if (userMessage) {
          // getPostLoginAction API returned a matching PLA userMessageId
          const renderPostLoginActionOptions = {
            userMessage,
            userMessages,
            features,
          };
          // PLA implementation for empower.
          if (IS_EMPOWER) {
            // eslint-disable-next-line no-magic-numbers
            modalStack.addModal("PLA", 10, () =>
              renderPostLoginAction(renderPostLoginActionOptions)
            );
            console.log("Invoked `addModal` method");

            return;
          }

          if (isAppointmentHash()) {
            // If user logged in via the /app-features/appointment deep link, show PLA after appointment modal close
            window.AppRouter.showPlaOnAppointmentModalClose = () => {
              renderPostLoginAction(renderPostLoginActionOptions);
            };
          } else {
            renderPostLoginAction(renderPostLoginActionOptions);
          }
        } else {
          // No PLA was shown, screen for any interjections
          screenUserMessagesFor({
            screenFor: [
              USER_MSG_TYPE.INTERJECTION,
              USER_MSG_TYPE.APP_WIDE_BANNER,
              USER_MSG_TYPE.USER_RESPONSE_BANNER,
            ],
            userMessages: deepCopy(userMessages),
            showBlankDisplayLocationInterjection: true,
            features,
            isFromInitApp: true,
          });
        }
      })
      .catch(noop); // Errors are handled in promise creators below
  },

  utils: { getLatestUserMessages }, // Provide PLA utils access to mainServices.js
});

export default PostLoginActionsView;
