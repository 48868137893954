/* eslint-disable sonarjs/no-duplicate-string, sonarjs/cognitive-complexity, sonarjs/no-inverted-boolean-check */
import React from "react";
import ReactDOM from "react-dom";
import Backbone from "backbone";
import _ from "underscore";
import { promisify } from "utils/service";
import SidebarAccountsTemplate from "templates/modules/sidebar/accountsView.html";
import AccountsListView2 from "views/modules/sidebar/components/accountsList2";
import PerfectScrollbar from "perfect-scrollbar";
import Services from "services";
import Analytics from "analytics";
import ZeroStateTemplate from "templates/modules/sidebar/zeroState2.html";
import "textfill";
import parseResponseErrors from "libs/pcap/utils/response";
import Message from "components/common/Message";
import { assetUpdatesCompleted } from "empower/utils/liat/otherAssetsUtils";
import { componentLoaded } from "empower/utils/componentLoading";

export const AGGREGATION_LEVEL_DEFAULT = "ALL";

export default Backbone.View.extend({
  initialize: function (parentView, containerElement) {
    this.empowerData = {};
    this.accountsList = {};
    this.containerElement = containerElement;
    this.parentView = parentView;

    this.containerElement.append(SidebarAccountsTemplate());
    this.zeroStateContainerEl = this.$("#js-sidebar-zero-state");

    if (IS_EMPOWER) {
      // fetch initial user account context
      // fetch dynamic empower accounts group name
      Promise.all([
        promisify(Services.Profile.getUIPreferences, true)(),
        window.PublicServices.getAccountSummary(),
      ])
        .then(
          ([
            { spData: uiPreferencesResponse, spHeader },
            getAccountSummaryRep,
          ]) => {
            this.empowerData.accountSummaries = getAccountSummaryRep;

            if (spHeader.success) {
              this.empowerData.accountAccucode =
                spHeader.participantContext?.sponsorAcCuCode;
              this.empowerData.accountContextId =
                spHeader.participantContext?.indId;
              this.empowerData.accountGroupName =
                uiPreferencesResponse.participantPreferences?.institutionalPartnerName;
              this.empowerData.aggregationLevel =
                uiPreferencesResponse.participantPreferences
                  ?.aggregationLevel || AGGREGATION_LEVEL_DEFAULT;
              Services.Accounts.get.watch(
                this.onAccountsFetched.bind(this),
                this
              );
              this.createAccountsListView({ empowerData: this.empowerData });
            }
          }
        )
        .catch(() => {
          //Ignore error apis
        });
    } else {
      Services.Accounts.get.watch(this.onAccountsFetched.bind(this), this);
      this.createAccountsListView();
    }
  },

  onAccountsListRendered: function () {
    this.adjustScroller();
    this.adjustNetWorthFontSize();
  },

  onAccountsListDataFetched: function () {
    this.trigger("accountsFetched");
  },

  createAccountsListView: function (data) {
    this.accountsList = new AccountsListView2("#js-sidebar-accounts", {
      empowerData: data?.empowerData || {},
    });
    this.accountsList.on("rendered", this.onAccountsListRendered, this);
    this.accountsList.on(
      "accountsFetched",
      this.onAccountsListDataFetched,
      this
    );
  },

  initializeScroller: function () {
    this.sidebarAccountsScroll = document.getElementById("js-sidebar-accounts");
    this.fixScrollerHeight();

    this.ps = new PerfectScrollbar(this.sidebarAccountsScroll, {
      wheelSpeed: 2,
      // This makes the parent scrollable element (window) scroll when the user scrolls
      // the sidebar and the scrollbar reaches the end of the side.
      // This behavior is needed for the use case where PCAP app is embedded on a partners website
      // where the sidebar is expanded all the way to the bottom of the page as oppose to the
      // window height on the regular PCAP dashboard. Because of that, there is no way to scroll through
      // all accounts on the sidebar without this setting.
      // The behavior is acceptable in both cases but *required* only within an iframe.
      wheelPropagation: true,
    });

    $(window).resize(this.resizeAccountsSidebar.bind(this));
  },

  resizeAccountsSidebar: function () {
    this.fixScrollerHeight();
    this.updateScroller();
  },

  updateScroller: function () {
    this.ps.update();
  },

  adjustScroller: function () {
    if (this.sidebarAccountsScroll) {
      this.fixScrollerHeight();
      this.updateScroller();
    } else {
      this.initializeScroller();
    }
  },

  fixScrollerHeight: function () {
    // The sidebar will scroll with the page in Empower app
    if (IS_EMPOWER) {
      return;
    }
    // Goal: In this sidebar view we want to display the header, net worth section,
    // and (optionally) tabs as fixed-height components.
    // The remaining height in the sidebar will be used to display the accounts list,
    // including the "Link another account" button, adding a scrollbar if necessary.
    // This function adjusts the accounts list height to fill only that remaining height,
    // causing the scrollbar to display correctly.
    // Side note: The net worth's text adjusts dynamically and is not always ready on
    // sidebar initialization. Make sure this function is called after it is rendered.

    // DOM queries and size calculations are grouped into separate buckets
    // as a performance optimization.
    const sidebar = $("#sidebarContent");
    const accounts = this.$("#js-sidebar-accounts");
    const tabs = $(".sidebar__tabs");
    const tabsHidden = tabs.hasClass("is-hidden");
    const header = $(".js-sidebar__header");
    const networth = $(".js-sidebar-networth-container");
    const footer = $(".js-sidebar-footer");

    const sidebarHeight = sidebar.height();
    const tabsHeight = tabsHidden ? 0 : tabs.outerHeight(true) || 0;
    const headerHeight = header.outerHeight(true) || 0;
    const networthHeight = networth.outerHeight(true) || 0;
    const footerHeight = footer.outerHeight(true) || 0;

    let newHeight =
      sidebarHeight - tabsHeight - headerHeight - networthHeight - footerHeight;

    accounts.height(newHeight);
  },

  renderError: function (errors) {
    if (!this.errorContainerEl) {
      this.errorContainerEl = this.$("#js-sidebar-error-state");
    }
    ReactDOM.render(
      React.createElement(Message, {
        severity: "error",
        messages: errors,
      }),
      this.errorContainerEl.get(0)
    );
    this.errorContainerEl.show();
  },

  onAccountsFetched: function (err, response) {
    let errors = parseResponseErrors(err, response);
    if (errors) {
      this.renderError(errors);
      if (IS_EMPOWER) {
        componentLoaded("sidebar");
      }

      return;
    }
    if (IS_EMPOWER) {
      assetUpdatesCompleted("assets");
    }
    let sidebarAccountsEl = this.containerElement.find("#js-sidebar-accounts");
    let netWorthContainer = this.containerElement.find(
      ".js-sidebar-networth-container"
    );

    let data = response.spData;

    // If there's no account, OR all the accounts returned are closed
    if (
      (response.spData.accounts && response.spData.accounts.length === 0) ||
      response.spData.accounts.filter((account) => {
        return account.closedDate && account.closedDate !== "";
      }).length === response.spData.accounts.length
    ) {
      // Doesn't have accounts
      sidebarAccountsEl.hide();
      netWorthContainer.addClass("is-hidden");
      this.renderZeroState();
    } else {
      // Have accounts
      if (this.getUserMessagesWatch) {
        Services.UserMessages.getV2.unwatch(this.getUserMessagesWatch);
        this.getUserMessagesWatch = null;
      }

      this.hideZeroState();

      if (this.errorContainerEl) {
        ReactDOM.unmountComponentAtNode(this.errorContainerEl);
        this.errorContainerEl.hide();
      }
      sidebarAccountsEl.show();
      netWorthContainer.removeClass("is-hidden");

      this.changeStatusIcon(data);
      const hasAggregationEnabled = !(
        this.empowerData.aggregationLevel === "NONE"
      );
      if (!this.sidebarNetWorthSection && hasAggregationEnabled) {
        this.sidebarNetWorthSection = this.containerElement.find(
          ".js-networth-section"
        );
        this.sidebarNetWorthAmount = this.sidebarNetWorthSection.find(
          ".js-sidebar-networth-amount"
        );
      }

      this.adjustNetWorthFontSize();
    }
  },
  isAnyExtraFormVisible() {
    return this.accountsList.isAnyExtraFormVisible();
  },

  renderZeroState: function () {
    //Default template data
    let templateData = {
      title: "No Accounts Yet",
      summary:
        "Your accounts will be listed here for quick access. Link your banks, brokerages, credit cards, and mortgage to see your net worth.",
      action: [{ label: "Link Account", url: "#/accounts/add" }],
    };
    this.showZeroState(templateData);

    //Get the zero state message
    if (!this.getUserMessagesWatch) {
      this.getUserMessagesWatch = Services.UserMessages.getV2.watch(
        (err, response) => {
          if (response.spHeader && response.spHeader.errors) {
            err = _.first(response.spHeader.errors).message;
            Analytics.sendEngineeringEvent(
              "Error",
              "Services.UserMessages.getV2: " + err
            );
          }

          if (err) {
            Analytics.sendEngineeringEvent(
              "Error",
              "Services.UserMessages.getV2: " + err
            );
          } else {
            //Find the zero state template data
            templateData = response.spData.userMessages.find((value) => {
              return value.template === "NETWORTH_ZERO_STATE_SIDEBAR";
            });

            if (templateData) {
              this.showZeroState(templateData);
            }
          }
        }
      );
    }
  },

  showZeroState: function (templateData) {
    this.zeroStateContainerEl.html(
      ZeroStateTemplate({
        title: templateData.title,
        summary: templateData.summary,
        action: _.first(templateData.action),
      })
    );

    this.zeroStateContainerEl.removeClass("hidden");
  },

  hideZeroState: function () {
    this.zeroStateContainerEl.addClass("hidden");
  },

  changeStatusIcon: function (data) {
    var sidebarLoader = $(".js-sidebar-loader");
    var sidebarExpander = $(".js-sidebar-expand");
    // If there is 1 or more accounts aggregating toggle the icon to the animated one
    if (
      data.accounts.filter((account) => {
        return account.nextAction?.action === "WAIT";
      }).length > 0
    ) {
      // Start animation
      sidebarLoader.removeClass("hidden");
      sidebarExpander.addClass("hidden");
    } else {
      // Stop animation
      sidebarLoader.addClass("hidden");
      sidebarExpander.removeClass("hidden");
    }

    // If there is 1 or more accounts that are not closed AND needs more info OR needs to visit the site,
    // return ACTION_REQUIRED. This will toggle the red circle on the sidebar icon.
    let status;
    if (
      data.accounts.filter((account) => {
        return (
          !account.closedDate &&
          (account.nextAction?.action === "MORE_INFO" ||
            account.nextAction?.action === "VISIT_SITE")
        );
      }).length > 0
    ) {
      status = "ACTION_REQUIRED";
    } else {
      status = "OK";
    }

    this.containerElement.trigger({ type: "statusChanged", status: status });
  },

  adjustNetWorthFontSize: function () {
    if (this.sidebarNetWorthAmount) {
      this.setNetWorthEllipseState(false);
    }

    if (this.sidebarNetWorthSection) {
      this.sidebarNetWorthSection.textfill({
        innerTag: ".js-sidebar-networth-amount",
      });
    }

    this.adjustScroller();
  },

  show: function () {
    this.$("#js-sidebar-accounts-container").removeClass("is-hidden");
  },

  hide: function () {
    this.$("#js-sidebar-accounts-container").addClass("is-hidden");
  },

  setNetWorthEllipseState(isEllipsed) {
    if (this.sidebarNetWorthAmount) {
      if (isEllipsed) {
        this.sidebarNetWorthAmount.addClass(
          "sidebar__networth-amount--ellipsed"
        );
      } else {
        this.sidebarNetWorthAmount.removeClass(
          "sidebar__networth-amount--ellipsed"
        );
      }
    }
  },

  remove: function () {
    if (this.getUserMessagesWatch) {
      Services.UserMessages.getV2.unwatch(this.getUserMessagesWatch);
      this.getUserMessagesWatch = null;
    }

    if (this.errorContainerEl) {
      ReactDOM.unmountComponentAtNode(this.errorContainerEl);
    }
    Backbone.View.prototype.remove.apply(this, arguments);
  },
});
