import React from "react";
import PropTypes from "prop-types";
import Table from "libs/pcap/table/Table";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import { DEFAULT_COLUMNS } from "components/transferFunds/utils/recurringTransfersTable";
import {
  STAGE_PENDING,
  STAGE_INACTIVE,
} from "components/transferFunds/utils/constants";
import withTooltips from "components/higherOrderComponents/withTooltips";
import Row from "libs/pcap/table/Row";

const getClassNameForRow = (data) => {
  switch (data.state) {
    case STAGE_PENDING:
      return "recurring-transfers-table__row--pending";
    case STAGE_INACTIVE:
      return "recurring-transfers-table__row--disabled";
    default:
      return "recurring-transfers-table__row";
  }
};

const getRowTitle = (data) => {
  const organizationMailCash = "cash@personalwealth.empower.com";
  switch (data.state) {
    case STAGE_PENDING:
      return "This transfer is pending. Pending requests can take a few days to be processed.";
    case STAGE_INACTIVE:
      return `This transfer has been deactivated, please email ${organizationMailCash} to fix this transfer.`;
    default:
      return null;
  }
};

const RecurringTransfersTable = (props) => {
  if (props.loading) {
    return <LoadingOverlay active={props.loading} />;
  }
  return (
    <div className="recurring-transfers-table">
      <Message severity="error" messages={props.fetchDataErrors} />
      <Table
        className="qa-recurring-transfers-table"
        headerRowClassName="u-text-bold"
        data={props.transfers}
        columns={props.columns}
        zeroState="You have no recurring transfers set up at the moment."
        rowClassName={getClassNameForRow}
        rowTitle={getRowTitle}
        Row={withTooltips(Row)}
      />
    </div>
  );
};

RecurringTransfersTable.defaultProps = {
  columns: DEFAULT_COLUMNS,
  loading: false,
  fetchDataErrors: undefined,
};

RecurringTransfersTable.propTypes = {
  transfers: PropTypes.array.isRequired,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  fetchDataErrors: PropTypes.array,
};

export default RecurringTransfersTable;
