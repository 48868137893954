import PropTypes from "prop-types";
import React from "react";
import DocumentUploaderContainer from "components/common/DocumentUploader/DocumentUploaderContainer";
import { MAX_DV_FILE_SIZE } from "components/common/DocumentUploader/utils/constants";
import Modal from "components/modal/Modal";

const DocumentVaultUploadModal = ({ onCancel, onDone, isOpen }) => {
  const fileTypeRegex = /(\.|\/)(gif|jpe?g|png|pdf|tiff|bmp|doc|docx|rtf|txt|xls|xlsx|csv)$/i;
  const DOCUMENT_VAULT_API_ENDPOINT =
    window.baseUrl + "api/filecabinet/uploadDocument";

  const typeValidator = (filename) => {
    return fileTypeRegex.test(filename);
  };

  const errorFormatter = () => {
    return `Unsupported file type. Please refer to the list of supported types before trying again.`;
  };

  return (
    <Modal
      componentName="DocumentVaultUploadModal"
      title="Upload a Document"
      isOpen={isOpen}
      onClosed={onCancel}
      className="pc-modal"
      shouldCloseOnOverlayClick={false}
    >
      <DocumentUploaderContainer
        maxFileSize={MAX_DV_FILE_SIZE}
        fileTypeValidator={typeValidator}
        typeErrorMessage={errorFormatter}
        uploadAPIEndpoint={DOCUMENT_VAULT_API_ENDPOINT}
        requestParams={{
          documentType: "ENROLLMENT",
        }}
        onCancel={onCancel}
        onDone={onDone}
      />
    </Modal>
  );
};

DocumentVaultUploadModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default DocumentVaultUploadModal;
