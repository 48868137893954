/* eslint-disable no-unused-expressions */

import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import AbstractForm from "../../../common/form/AbstractForm";
import { isEqual } from "underscore";
import Input from "components/common/form/Input";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import deepCopy from "deep-copy";
import { isEmpowerPrivilegedMode } from "../../../../views/modules/sidebar/utils/accountUtils";

const isPrivileged = isEmpowerPrivilegedMode();
const MAXLENGTH_TAG_NAME = 35;
const ALPHANUMERIC_CHARACTERS = /^[a-zA-Z0-9_][a-zA-Z0-9_ ]*$/;

class CreateUpdateTagModal extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.model, prevState.prevPropModel)) {
      return {
        model: nextProps.model,
        prevPropModel: deepCopy(nextProps.model),
      };
    }

    return null;
  }

  handleSubmit(ev) {
    const { model, onCreateTag, onUpdateTag } = this.props;
    const { model: formValue } = this.state;
    const isCreate = !model.tagId;
    const validationResult = this.validate();

    if (ev) {
      ev.stopPropagation();
      ev.preventDefault();
    }

    if (validationResult.valid) {
      // Save only when there is any change in the model.
      if (this.checkIfFormHasChanged()) {
        isCreate
          ? onCreateTag(formValue.customTagName)
          : onUpdateTag(formValue);
        return;
      }

      this.props.onCancel();
    }
  }

  render() {
    const { loading, errors, onCancel, model, validator } = this.props;
    const isCreate = !model.tagId;
    return (
      <Modal
        componentName="CreateUpdateTagModal"
        title={isCreate ? "Create Tag" : "Update Tag"}
        isOpen={true}
        onClosed={onCancel}
        className="pc-modal pc-modal--small qa-manage-tags__create-update-modal"
        shouldCloseOnOverlayClick={false}
      >
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <section>
            <Message className="pc-u-mb" severity="error" messages={errors} />
            <div className="pc-layout pc-layout--small pc-form-group">
              <LoadingOverlay active={loading} />
              <div className="pc-layout__item pc-u-1/1">
                <label className="pc-label" htmlFor="customTagName">
                  Name
                </label>
                <Input
                  ref={this.storeInputRef}
                  type="text"
                  id="customTagName"
                  sizeVariation="full"
                  name="customTagName"
                  value={model.customTagName}
                  onChange={this.handleInputChange}
                  validator={validator.properties.customTagName}
                  maxLength={MAXLENGTH_TAG_NAME}
                  autoFocus={true}
                  data-hj-masked
                />
              </div>
            </div>
            <div className="u-text-right">
              <button
                type="button"
                className="js-create-update__tag-cancel pc-btn"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="js-create-update__tag-done qa-create-update__tag-done pc-btn pc-btn--primary"
                aria-disabled={isPrivileged}
                disabled={isPrivileged}
              >
                Save
              </button>
            </div>
          </section>
        </form>
      </Modal>
    );
  }
}

CreateUpdateTagModal.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onCreateTag: PropTypes.func.isRequired,
  onUpdateTag: PropTypes.func.isRequired,
  model: PropTypes.object,
  validator: PropTypes.object,
};

CreateUpdateTagModal.defaultProps = {
  loading: false,
  errors: undefined,
  model: { customTagName: "" },
  validator: {
    type: "object",
    properties: {
      customTagName: {
        type: "string",
        allowEmpty: false,
        maxLength: MAXLENGTH_TAG_NAME,
        minLength: 3,
        messages: {
          minLength: "Tag name is too short (minimum is 3 characters).",
          maxLength: "Tag name is too long (maximum is 35 characters).",
          allowEmpty: "Tag name is required.",
          pattern:
            "Should not start with a space and special characters are not allowed.",
        },
        pattern: ALPHANUMERIC_CHARACTERS,
      },
    },
  },
};

export default CreateUpdateTagModal;
