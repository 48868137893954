import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { trackView } from "components/common/ComponentAnalytics";
import ShareLink from "./ShareLink";
import ShareViaEmail from "./ShareViaEmail";
import Snackbar from "components/common/Snackbar";

const ReferralCenter = ({ copyData, componentName }) => {
  const [confirmationToastMessage, setConfirmationToastMessage] = useState("");
  useEffect(() => {
    trackView(componentName);
  }, [componentName]);

  return (
    <div className="referral-center qa-referral-center">
      <Snackbar
        isOpen={confirmationToastMessage !== ""}
        message={confirmationToastMessage}
      />
      <div className="referral-center-content">
        <h1
          className="referral-center__headline qa-title"
          dangerouslySetInnerHTML={{ __html: copyData.headline }}
        />
        <h2
          className="referral-center__description qa-offer-description"
          dangerouslySetInnerHTML={{ __html: copyData.description }}
        />
        <div className="referral-center__share-container">
          <ShareViaEmail
            subject={copyData.emailSubject}
            body={copyData.emailBody}
            confirmationToastMessage={confirmationToastMessage}
            setConfirmationToastMessage={setConfirmationToastMessage}
          />
          <ShareLink
            componentName={componentName}
            text={copyData.footnote}
            contentToCopy={copyData.contentToCopy}
          />
        </div>
        <h3 className="referral-center__disclaimer pc-u-pl pc-u-pr">
          {copyData.disclaimer}
          {copyData.disclaimerLinkUrl && copyData.disclaimerLinkText && (
            <a
              className="qa-referral-center-disclaimer-link"
              target="_blank"
              rel="noopener noreferrer"
              href={copyData.disclaimerLinkUrl}
            >
              &nbsp;{copyData.disclaimerLinkText}
            </a>
          )}
        </h3>
      </div>
    </div>
  );
};

ReferralCenter.propTypes = {
  componentName: PropTypes.string,
  copyData: PropTypes.object.isRequired,
};

ReferralCenter.defaultProps = {
  componentName: "Referral Center",
};

export default ReferralCenter;
