import React, { Component } from "react";
import PropTypes from "prop-types";
import { trackView } from "components/common/ComponentAnalytics";

export default class AccountStatus extends Component {
  componentDidMount() {
    if (this.props.trackingPageName && this.props.trackingComponentName) {
      trackView(this.props.trackingPageName, {
        component: this.props.trackingComponentName,
        // eslint-disable-next-line camelcase
        is_secondary_enrollment: this.props.isSecondaryOwnerEnrollment,
      });
    }
  }

  render() {
    const rebrandClass = "account-confirmation-rebrand ";
    return (
      <div
        className={`${
          IS_EMPOWER ? `` : `pc-u-mt pc-u-pl+ pc-u-pr+`
        } qa-page-status account-confirmation ${rebrandClass}${
          this.props.className
        }`}
      >
        <div className="account-confirmation__body">
          <div
            className={`${
              IS_EMPOWER
                ? `nav-secondary__title pc-u-mb`
                : `account-confirmation__title pc-u-mb-- `
            } js-account-status-title qa-page-status-title`}
          >
            {this.props.title}
          </div>
          {IS_EMPOWER && (
            <p className="u-text-bold pc-u-mb">
              Your account verification has been initiated.
            </p>
          )}
          <div className="js-account-status-subtitle">
            {this.props.subTitle}
          </div>
        </div>
        <div className="js-account-status-footer">{this.props.footer}</div>
      </div>
    );
  }
}

AccountStatus.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  footer: PropTypes.element,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  trackingComponentName: PropTypes.string,
  trackingPageName: PropTypes.string,
  isSecondaryOwnerEnrollment: PropTypes.bool,
  className: PropTypes.string,
};

AccountStatus.defaultProps = {
  footer: (
    <div className="u-text-right">
      <a href={DASHBOARD_URL} className="pc-btn pc-btn--primary">
        Dashboard
      </a>
    </div>
  ),
  title: undefined,
  subTitle: undefined,
  trackingComponentName: undefined,
  trackingPageName: undefined,
  isSecondaryOwnerEnrollment: false,
  className: "",
};
