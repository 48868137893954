import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import Loading from "components/common/loading/Loading";
import IndexSummary from "components/indexSummary/IndexSummary";
import CtaOverlay from "components/common/CtaOverlay";

function mapIndexToSummary(index, isYouIndex, key) {
  let classNames = "market-movers-summary__index";
  if (isYouIndex) {
    classNames += " market-movers-summary__index--primary";
  }
  return (
    <div className={classNames} key={key}>
      <IndexSummary
        label={index.label}
        histories={index.histories}
        changeToday={index.changeToday}
        isPrimary={isYouIndex}
      />
    </div>
  );
}

export default class MarketMoversSummary extends React.Component {
  constructor(props) {
    super(props);
  }

  handleGaEvent() {
    window.dashboardUtils?.eventBus.dispatch(
      "market_movers_widget_title_click_event"
    );
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type: window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
      event_properties: {
        selection: "market_movers_widget_title_click_event",
      },
    });
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    let aceOverlay;
    if (this.props.ace) {
      aceOverlay = (
        <CtaOverlay
          message={this.props.ace}
          widgetName="Market Movers Summary"
        />
      );
    }

    let summaries = _.map(this.props.otherIndices, (index, key) =>
      mapIndexToSummary(index, false, key)
    );

    // only display YouIndex if there are histories
    let youIndex;
    if (this.props.youIndex) {
      youIndex = mapIndexToSummary(this.props.youIndex, true);
    }

    return (
      <section className="summary-widget market-movers-summary">
        <div className="summary-widget__header">
          <div onClick={this.handleGaEvent} className="pc-delta">
            <h2 className="pc-u-mb0 header-container">
              <a
                href={`${PORTFOLIO_URL}/holdings`}
                className="summary-widget__title--link qa-market-widget-title"
                aria-label="Go to Holdings"
              >
                Market Movers
              </a>
            </h2>
          </div>
        </div>
        <a
          href={`${PORTFOLIO_URL}/holdings`}
          aria-label="Go to holdings"
          className="summary-widget__body market-movers-summary__body qa-market-movers-summary-body"
        >
          <div
            role="presentation"
            className="summary-widget__body market-movers-summary__body"
          >
            {youIndex}
            {summaries}
          </div>
        </a>
        {aceOverlay}
      </section>
    );
  }
}

MarketMoversSummary.propTypes = {
  loading: PropTypes.bool,
  youIndex: PropTypes.object,
  otherIndices: PropTypes.array,
  ace: PropTypes.object,
};
