const nonWhitespace = new RegExp(/\S/);

export function hasPrintableCharacters(str) {
  return nonWhitespace.test(str);
}

export const buildEmpowerRetirementAgeValidator = (currentAge) => {
  const MINIMUM_RETIREMENT_AGE = 50;
  const DEFAULT_MAX_RETIREMENT_AGE_EMPOWER = 85;
  const CURRENT_AGE_OFFSET = 6;
  const maxAge =
    currentAge + CURRENT_AGE_OFFSET >= DEFAULT_MAX_RETIREMENT_AGE_EMPOWER
      ? currentAge + CURRENT_AGE_OFFSET
      : DEFAULT_MAX_RETIREMENT_AGE_EMPOWER;

  return {
    type: "number",
    maxLength: 2,
    minimum: MINIMUM_RETIREMENT_AGE,
    maximum: maxAge,
    required: true,
    messages: {
      type: "Please enter a valid number.",
      required: "This field is required.",
      minimum: `Must be between ${MINIMUM_RETIREMENT_AGE} and ${maxAge} (inclusive).`,
      maximum: `Must be between ${MINIMUM_RETIREMENT_AGE} and ${maxAge} (inclusive).`,
    },
  };
};

export const buildNumberOfYearsEmployedValidator = {
  type: "number",
  maxLength: 2,
  minimum: 0,
  maximum: 99,
  required: true,
  messages: {
    type: "Please enter a valid number.",
    required: "This field is required.",
    minimum: `Must be between 0 and 99 (inclusive).`,
    maximum: `Must be between 0 and 99 (inclusive).`,
  },
};
