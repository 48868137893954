import React from "react";
import PropTypes from "prop-types";
import noop from "utils/noop";
import AbstractForm from "components/common/form/AbstractForm";
import Input from "components/common/form/Input";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import { CURRENCY_FORMAT } from "components/common/form/formattingOptions";
import InputWithAutocomplete from "components/common/form/InputWithAutocomplete";
import { debounce } from "underscore";
import AccountTypeSelector from "./AccountTypeSelector";

class AddManualAccountForm extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: {},
    };
    this.handleFirmNameChange = this.handleFirmNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onFirmNameAutocompleteSelect =
      this.onFirmNameAutocompleteSelect.bind(this);
  }

  handleFirmNameChange(ev, value) {
    this.handleInputChange(ev, value);
    const firmName = value ?? ev?.target?.value;

    const debounceMs = 200;
    this.debouncedOnFirmNameChange?.cancel();
    this.debouncedOnFirmNameChange = debounce(
      () => this.props.onFirmNameChange(firmName),
      debounceMs
    );

    this.debouncedOnFirmNameChange();
  }

  handleSave() {
    if (this.props.isPrivileged) {
      return;
    }
    const { valid } = this.validate();
    if (valid) {
      this.props.onSave(this.state.model);
    }
  }

  onFirmNameAutocompleteSelect(value) {
    this.handleInputChange(
      {
        target: {
          name: "firmName",
          value,
        },
      },
      value
    );
  }

  render() {
    const {
      accountTypeGroups,
      firmNameSuggestions,
      loading,
      errors,
      onCancel,
      isPrivileged,
    } = this.props;
    const { model } = this.state;
    const validator = AddManualAccountForm.validationSchema;

    return (
      <>
        <Message className="pc-u-mb" severity="error" messages={errors} />
        <LoadingOverlay active={loading} />
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/3">
            <label htmlFor="firmName" className="pc-label pc-u-mr">
              Where is the account held?
            </label>
          </div>
          <div className="pc-layout__item pc-u-1/2">
            <InputWithAutocomplete
              autocompleteSuggestions={firmNameSuggestions}
              id="firmName"
              name="firmName"
              onAutocompleteSelect={this.onFirmNameAutocompleteSelect}
              onChange={this.handleFirmNameChange}
              ref={this.storeInputRef}
              sizeVariation="full"
              type="text"
              validator={validator.firmName}
              value={model.firmName || ""}
              maxLength={200}
              containerClassName={"add-manual-account-form"}
            />
          </div>
        </div>
        <AccountTypeSelector
          accountTypeGroups={accountTypeGroups}
          model={model}
          onInputChange={this.handleInputChange}
          storeInputRef={this.storeInputRef}
        />
        <div className="pc-layout pc-u-mt">
          <div className="pc-layout__item pc-u-1/3">
            <label htmlFor="currentBalance" className="pc-label pc-u-mr">
              Approx. Balance
            </label>
          </div>
          <div className="pc-layout__item pc-u-1/2">
            <Input
              formattingOptions={CURRENCY_FORMAT}
              id="currentBalance"
              name="currentBalance"
              onChange={this.handleInputChange}
              prefix="$"
              ref={this.storeInputRef}
              sizeVariation="full"
              type="text"
              validator={validator.currentBalance}
              value={model.currentBalance || ""}
              maxLength={19}
              ariaLabel="Approximate Balance"
            />
          </div>
        </div>
        <div className="u-text-right pc-u-mt">
          <button type="button" className="pc-btn js-cancel" onClick={onCancel}>
            Cancel
          </button>
          <button
            className={`pc-btn pc-btn--primary js-save ${
              isPrivileged ? "is-disabled" : ""
            }`}
            onClick={this.handleSave}
          >
            Save
          </button>
        </div>
      </>
    );
  }
}

AddManualAccountForm.propTypes = {
  accountTypeGroups: PropTypes.array,
  errors: PropTypes.array,
  firmNameSuggestions: PropTypes.array,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onFirmNameChange: PropTypes.func,
  onSave: PropTypes.func,
  isPrivileged: PropTypes.bool,
};

AddManualAccountForm.defaultProps = {
  accountTypeGroups: [],
  errors: [],
  firmNameSuggestions: [],
  loading: false,
  onCancel: noop,
  onFirmNameChange: noop,
  onSave: noop,
  isPrivileged: false,
};

const EMPTY_VALIDATION_ERROR_MESSAGE = "this field is required";
const VALIDATION_REQUIRED = {
  allowEmpty: false,
  required: true,
  messages: {
    allowEmpty: EMPTY_VALIDATION_ERROR_MESSAGE,
    required: EMPTY_VALIDATION_ERROR_MESSAGE,
  },
};
const VALIDATION_REQUIRED_NUMBER = {
  ...VALIDATION_REQUIRED,
  type: "number",
  maximum: 99999999.99,
  messages: {
    ...VALIDATION_REQUIRED.messages,
    maximum: "Balance cannot be greater than $99,999,999.99",
  },
};
const VALIDATION_REQUIRED_STRING = {
  ...VALIDATION_REQUIRED,
  type: "string",
};

AddManualAccountForm.validationSchema = {
  firmName: VALIDATION_REQUIRED,
  accountTypeGroup: VALIDATION_REQUIRED_STRING,
  accountTypeNew: VALIDATION_REQUIRED_STRING,
  accountTypeSubtype: VALIDATION_REQUIRED_STRING,
  currentBalance: VALIDATION_REQUIRED_NUMBER,
};

export default AddManualAccountForm;
