import React from "react";
import PropTypes from "prop-types";
import Mixpanel from "mixpanel";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import AccountTypeTile from "./AccountTypeTile";

class AccountTypeTilesContainer extends React.Component {
  handleClick({ link, title }) {
    Mixpanel.trackEvent(`Clicked on ${title} tile`, { component: "Sidebar" });
    if (window.ga) {
      window.ga("send", "event", {
        title: `${title} tile`,
        component: "Sidebar",
      });
    }
    const prevUrl = window.location.hash;
    window.location.hash = link;
    if (prevUrl !== "#/dashboard/retirement-income") {
      window.history.pushState(
        { prevUrl: prevUrl },
        null,
        window.location.href
      );
    }
  }

  render() {
    const { loading, errors, tileList } = this.props;
    let tiles = [];
    tileList.forEach((tile) => {
      tiles.push(
        <AccountTypeTile
          key={tile.title}
          name={tile.title}
          title={tile.title}
          icon={tile.icon}
          handleClick={this.handleClick}
          link={tile.link}
          addMarginClassname={tile?.addMarginClassname}
        />
      );
    });
    return (
      <>
        <div className="account-type-tiles__container">
          <Message className="pc-u-mb" severity="error" messages={errors} />
          <LoadingOverlay active={loading} />
          {tiles}
        </div>
      </>
    );
  }
}

AccountTypeTilesContainer.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  tileList: PropTypes.array,
};

AccountTypeTilesContainer.defaultProps = {
  loading: false,
  errors: undefined,
  tileList: [],
};

export default AccountTypeTilesContainer;
