import React from "react";
import PropTypes from "prop-types";
import Table from "libs/pcap/table/Table";
import { formatPercent } from "libs/pcap/utils/format";

const COLUMNS = [
  {
    header: "Fund",
    accessor: (d) => d.name,
    style: {
      width: "75%",
    },
  },
  {
    header: "Allocation",
    accessor: (d) => d.value,
    formatter: (v) => formatPercent(v, { precision: 1 }),
    style: {
      width: "25%",
    },
    className: "table__column--right",
  },
];

const FundAllocationTable = ({
  accessors,
  formatters,
  headers,
  data,
  className,
  headerRowClassName,
  tableColumns,
}) => {
  let columns = tableColumns || COLUMNS;
  if (headers) {
    columns = columns.map((col, i) => {
      const header = headers[i];
      if (header) {
        return Object.assign({}, col, { header });
      }
      return col;
    });
  }
  if (accessors) {
    columns = columns.map((col, i) => {
      const accessor = accessors[i];
      if (accessor) {
        return Object.assign({}, col, { accessor });
      }
      return col;
    });
  }
  if (formatters) {
    columns = columns.map((col, i) => {
      const formatter = formatters[i];
      if (formatter) {
        return Object.assign({}, col, { formatter });
      }
      return col;
    });
  }

  return (
    <Table
      className={className}
      headerRowClassName={headerRowClassName}
      data={data}
      columns={columns}
    />
  );
};

FundAllocationTable.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  accessors: PropTypes.array,
  formatters: PropTypes.array,
  className: PropTypes.string,
  headerRowClassName: PropTypes.string,
  tableColumns: PropTypes.array,
};

FundAllocationTable.defaultProps = {
  data: [],
  headers: undefined,
  accessors: undefined,
  formatters: undefined,
  className: "centi table--no-bottom-underline",
  headerRowClassName: "u-text-bold",
  tableColumns: undefined,
};

export default FundAllocationTable;
