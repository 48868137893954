import React, { Component } from "react";
import PropTypes from "prop-types";
import Services from "services";
import moment from "moment";
import { fetchJsonPromise } from "../../../utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import parseResponseErrors from "libs/pcap/utils/response";
import DocumentVaultTable from "components/documentVault/DocumentVaultTable";
import DocumentVaultFormsView from "components/documentVault/DocumentVaultFormsView";
import DateRangeSelector from "views/components/dateRangeSelectorView2";
import DateUtils from "libs/pcap/utils/date";
import LoadingOverlay from "components/common/LoadingOverlay";
import DocumentVaultUploadModal from "components/documentVault/DocumentVaultUploadModal";
import DocumentVaultDeleteConfirmModal from "../DocumentVaultDeleteConfirm/Modal";
import DocumentUploadModal from "empower/components/DocumentUploadModal";
import { trackClick, trackEvent } from "components/common/ComponentAnalytics";
import deepCopy from "deep-copy";

const COMPONENT_NAME = "Document Vault";

const TAX = "TAX";
const STATEMENTS = "STATEMENTS";
const FORMS = "FORMS";
const UPLOADED = "UPLOADED";
const OTHER = "OTHER";
let displaySelector;

const DOC_TYPES = {
  PROPOSAL: "PROPOSAL",
  TRADE_CONFIRM: "TRADE_CONFIRM",
  MONTHLY_STATEMENT: "MONTHLY_STATEMENT",
  CONTRACT: "CONTRACT",
  REPORT: "REPORT",
  ENROLLMENT: "ENROLLMENT",
  PERSHING_TAX: "PERSHING_TAX_FILE",
  PCB_TAX: "PCB_TAX_FILE",
  PERSHING_STATEMENT: "PERSHING_STATEMENT",
  RETIREMENT_ACCOUNT_FUND_OPTIONS: "RETIREMENT_ACCOUNT_FUND_OPTIONS",
  USER_MILESTONE: "USER_MILESTONE",
  PERSHING_CONFIRMS: "PERSHING_CONFIRMS",
  PERSHING_NOTIFICATIONS: "PERSHING_NOTIFICATIONS",
  PCC_STATEMENT: "PCC_STATEMENT",

  // 10 documentTypes that the API is looking to load documents vaults page.
  // Make sure to update this list, if a new document type is added in the API.
  RETAIL_ENROLLMENT_REQUEST_FORM: "RETAIL_ENROLLMENT_REQUEST_FORM",
  RETAIL_INCOMING_TRANSFER_ROLLOVER_REQUEST_FORM:
    "RETAIL_INCOMING_TRANSFER_ROLLOVER_REQUEST_FORM",
  RETAIL_MONEY_MOVEMENT_REQUEST_FORM: "RETAIL_MONEY_MOVEMENT_REQUEST_FORM",
  RETAIL_PERSONAL_INFORMATION_CHANGE_REQUEST_FORM:
    "RETAIL_PERSONAL_INFORMATION_CHANGE_REQUEST_FORM",
  RETAIL_BENEFICIARY_DESIGNATION_REQUEST_FORM:
    "RETAIL_BENEFICIARY_DESIGNATION_REQUEST_FORM",
  RETAIL_LEGAL_DOCUMENT: "RETAIL_LEGAL_DOCUMENT",
  RETAIL_STATEMENT: "RETAIL_STATEMENT",
  RETAIL_VOIDED_CHECK: "RETAIL_VOIDED_CHECK",
  RETAIL_TRUSTED_CONTACT_DESIGNATION_REQUEST_FORM:
    "RETAIL_TRUSTED_CONTACT_DESIGNATION_REQUEST_FORM",
  RETAIL_OTHER_DOCUMENT: "RETAIL_OTHER_DOCUMENT",
};

const TAB_DOCS = {
  TAX: [DOC_TYPES.PERSHING_TAX, DOC_TYPES.PCB_TAX],
  STATEMENTS: [DOC_TYPES.REPORT, DOC_TYPES.PCC_STATEMENT],
  UPLOADED: [
    DOC_TYPES.RETAIL_OTHER_DOCUMENT,
    DOC_TYPES.ENROLLMENT,
    DOC_TYPES.RETIREMENT_ACCOUNT_FUND_OPTIONS,
    DOC_TYPES.USER_MILESTONE,
  ],
};

const EMPOWER_TAB_DOCS = {
  TAX: [DOC_TYPES.PERSHING_TAX, DOC_TYPES.PCB_TAX],
  STATEMENTS: [
    DOC_TYPES.PERSHING_STATEMENT,
    DOC_TYPES.REPORT,
    DOC_TYPES.PCC_STATEMENT,
  ],
  UPLOADED: [
    DOC_TYPES.RETAIL_ENROLLMENT_REQUEST_FORM,
    DOC_TYPES.RETAIL_INCOMING_TRANSFER_ROLLOVER_REQUEST_FORM,
    DOC_TYPES.RETAIL_MONEY_MOVEMENT_REQUEST_FORM,
    DOC_TYPES.RETAIL_PERSONAL_INFORMATION_CHANGE_REQUEST_FORM,
    DOC_TYPES.RETAIL_BENEFICIARY_DESIGNATION_REQUEST_FORM,
    DOC_TYPES.RETAIL_LEGAL_DOCUMENT,
    DOC_TYPES.RETAIL_STATEMENT,
    DOC_TYPES.RETAIL_VOIDED_CHECK,
    DOC_TYPES.RETAIL_TRUSTED_CONTACT_DESIGNATION_REQUEST_FORM,
    DOC_TYPES.RETAIL_OTHER_DOCUMENT,
    DOC_TYPES.ENROLLMENT,
    DOC_TYPES.RETIREMENT_ACCOUNT_FUND_OPTIONS,
    DOC_TYPES.USER_MILESTONE,
  ],
  OTHER: [
    DOC_TYPES.PERSHING_CONFIRMS,
    DOC_TYPES.PERSHING_NOTIFICATIONS,
    DOC_TYPES.RETAIL_OTHER_DOCUMENT,
    DOC_TYPES.ENROLLMENT,
    DOC_TYPES.RETIREMENT_ACCOUNT_FUND_OPTIONS,
    DOC_TYPES.USER_MILESTONE,
  ],
};

class DocumentVaultContainer extends Component {
  static getDerivedStateFromProps(props, state) {
    const { tab } = props;
    if (tab !== state.selectedTab) {
      return {
        selectedTab: tab,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);

    const now = moment();

    this.state = {
      selectedTab: props.tab,
      loadingDocVaultFormMessage: true,
      documents: [],
      startDate: now.clone().startOf("year"),
      endDate: now,
      loading: true,
      showUploadModal: false,
      showDeleteModal: false,
      deleteData: {},
    };

    this.onDateRangeChange = this.onDateRangeChange.bind(this);
    this.openUploadModal = this.openUploadModal.bind(this);
    this.closeUploadModal = this.closeUploadModal.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.triggerInitializeDateRangeSelector();
    this.loadDocuments();

    const integratedSharedData = window.integratedSharedData;

    if (integratedSharedData) {
      const translations = integratedSharedData.translations.get(
        "integratedDocVaultForms"
      );

      this.setState({
        formMessaging: translations,
      });
    } else {
      let getStyleGuide;

      getStyleGuide = window.styleGuideUrl
        ? fetchJsonPromise(window.styleGuideUrl)
        : // this is for local and pcap demo environments only
          fetchJsonPromise(
            `${window.staticUrl}/static/mockData/empower/style_guide.json`
          );

      if (getStyleGuide) {
        this.getStyleGuidePromise = makeCancelablePromise(getStyleGuide);
      }
      this.setState({ loadingDocVaultFormMessage: true });
      this.getStyleGuidePromise.promise
        .then((styleGuideResp) => {
          this.setState({
            formMessaging: deepCopy(styleGuideResp.integratedDocVaultForms),
            loadingDocVaultFormMessage: false,
          });
        })
        .catch((errors) => {
          this.setState({ errors, loadingDocVaultFormMessage: false });
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.triggerInitializeDateRangeSelector();

    if (prevState.selectedTab !== this.state.selectedTab) {
      this.loadDocuments();
    }
  }

  componentWillUnmount() {
    this.destroyDateRangeSelector();

    if (this.getStyleGuidePromise) {
      this.getStyleGuidePromise.cancel();
    }
  }

  getSelectedAccountId() {
    let hash = window.location.hash;
    hash = hash.substring(hash.indexOf("=") + 1);
    return Number(hash);
  }

  loadDocuments() {
    this.setState({
      loading: true,
    });

    this.fetchDocuments()
      .then((docs) => {
        const selectedAccountId = this.getSelectedAccountId();
        const docsByAccountId = docs.filter((doc) => {
          if (doc.userAccountId) {
            return doc.userAccountId === selectedAccountId;
          }
          return doc;
        });
        //if it is Empower App, load the document by userAccountId. If not, then load all the docs.
        const isAccountSpecific = window.location.hash.includes("?ua=");
        this.setState({
          documents: IS_EMPOWER && isAccountSpecific ? docsByAccountId : docs,
          loading: false,
        });
      })
      .catch((errors) => {
        this.setState({
          documents: [],
          errors,
          loading: false,
        });
      });
  }

  fetchDocuments() {
    const { selectedTab, startDate, endDate } = this.state;

    return new Promise((resolve, reject) => {
      Services.Documents.get(
        {
          documentTypes: JSON.stringify(
            IS_EMPOWER ? EMPOWER_TAB_DOCS[selectedTab] : TAB_DOCS[selectedTab]
          ),
          startDate: startDate.format(DateUtils.API_FORMAT),
          endDate: endDate.format(DateUtils.API_FORMAT),
        },
        (err, response) => {
          const errors = parseResponseErrors(err, response);

          if (errors) {
            reject(errors);
            return;
          }

          return resolve(response.spData && response.spData.edocumentData);
        }
      );
    });
  }

  deleteDocument(edocumentId) {
    return new Promise((resolve, reject) => {
      Services.Documents.delete({ edocumentId }, (err, response) => {
        const errors = parseResponseErrors(err, response);

        if (errors) {
          reject(errors);
          return;
        }

        return resolve(response.spData);
      });
    });
  }

  triggerInitializeDateRangeSelector() {
    const { dateRangeSelectorInitialized, loading } = this.state;

    if (!dateRangeSelectorInitialized && !loading) {
      this.initializeDateRangeSelector();
    }
  }

  initializeDateRangeSelector() {
    const { startDate, endDate } = this.state;

    if (!this.dateRangeSelector && this.dateRangeSelectorElement) {
      this.dateRangeSelector = new DateRangeSelector({
        el: this.dateRangeSelectorElement,
        initialStartDate: startDate.format(DateUtils.DISPLAY_FORMAT),
        initialEndDate: endDate.format(DateUtils.DISPLAY_FORMAT),
        initialNowDate: moment().format(DateUtils.DISPLAY_FORMAT),
        quickPicks: [
          { value: "y,0s", label: "This Year", index: 0 },
          { value: "y,-1s;y,-1e", label: "Last Year", index: 1 },
        ],
      });
      this.dateRangeSelector.on(
        "change:dateRange",
        this.onDateRangeChange,
        this
      );

      this.setState({
        dateRangeSelectorInitialized: true,
      });
    }
  }

  destroyDateRangeSelector() {
    if (this.dateRangeSelector) {
      this.dateRangeSelector.off("change:dateRange");
      this.dateRangeSelector.close();
      this.dateRangeSelector = null;
    }
  }

  openUploadModal(e) {
    trackClick(e, COMPONENT_NAME, "Click on Upload Document");
    if (!IS_EMPOWER) {
      AppRouter.navigate("#/documents/uploaded");
    }
    this.setState({
      showUploadModal: true,
    });
  }

  closeUploadModal() {
    this.setState({
      showUploadModal: false,
    });
    const selectedAccountId = this.getSelectedAccountId();
    if (IS_EMPOWER) {
      window.location = "#/documents/uploaded?ua=" + selectedAccountId;
    }
    this.loadDocuments();
  }

  openDeleteModal(e) {
    trackClick(e, COMPONENT_NAME, "Click on Delete Document", {
      edocumentId: e.currentTarget.dataset.docid,
    });
    e.preventDefault();

    this.setState({
      showDeleteModal: true,
      deleteData: {
        edocumentId: e.currentTarget.dataset.docid,
        documentTitle: e.currentTarget.dataset.filename,
      },
    });
  }

  closeDeleteModal() {
    this.setState({
      showDeleteModal: false,
    });
  }

  handleDelete() {
    const docId = this.state.deleteData.edocumentId;

    this.setState({ loading: true });
    this.deleteDocument(docId)
      .then(() => {
        this.setState({
          loading: false,
          showDeleteModal: false,
          deleteData: {},
        });
        this.loadDocuments();
      })
      .catch((errors) => {
        this.setState({
          errors,
          loading: false,
          showDeleteModal: false,
          deleteData: {},
        });
      });
  }

  onDateRangeChange(startDate, endDate) {
    trackEvent(COMPONENT_NAME, "Document Date Range Changed", {
      startDate,
      endDate,
    });
    const start = moment(startDate, DateUtils.DISPLAY_FORMAT);
    const end = moment(endDate, DateUtils.DISPLAY_FORMAT);
    this.setState(
      {
        loading: true,
        startDate: start,
        endDate: end,
      },
      () => {
        this.loadDocuments();
      }
    );
  }

  render() {
    const {
      documents,
      selectedTab,
      loading,
      showUploadModal,
      showDeleteModal,
      deleteData,
      formMessaging,
    } = this.state;

    if (selectedTab === "FORMS") {
      displaySelector = "none";
    } else {
      displaySelector = "";
    }

    const isAccountSpecific = window.location.hash.indexOf("?ua=") !== -1;

    const selectedAccountId =
      IS_EMPOWER && !window.isAdvisorApp && isAccountSpecific
        ? "?ua=" + this.getSelectedAccountId()
        : "";

    return (
      <>
        {IS_EMPOWER ? (
          <DocumentUploadModal
            isOpen={showUploadModal}
            onCancel={this.closeUploadModal}
            onDone={this.closeUploadModal}
          />
        ) : (
          <DocumentVaultUploadModal
            isOpen={showUploadModal}
            onCancel={this.closeUploadModal}
            onDone={this.closeUploadModal}
          />
        )}
        <DocumentVaultDeleteConfirmModal
          isOpen={showDeleteModal}
          onCancel={this.closeDeleteModal}
          onDelete={this.handleDelete}
          filename={deleteData.documentTitle}
        />
        <div
          className="document-vault-container qa-document-vault-container"
          ref={(el) => {
            this.el = el;
          }}
        >
          <LoadingOverlay active={loading} />
          <div className="nav-secondary l-spaced l-spaced--flush js-secondary-nav qa-nav-secondary">
            <h1 className="nav-secondary__title qa-page-title">
              Document Vault
            </h1>
            <nav>
              <ul className="pc-layout pc-layout--auto pc-layout--small">
                <li className="pc-layout__item">
                  <a
                    className={`pc-btn pc-btn--bare qa-document-vault-container-nav-btn ${
                      selectedTab === STATEMENTS ? "is-active" : ""
                    }`}
                    href={`#/documents/statements${selectedAccountId}`}
                  >
                    Statements
                  </a>
                </li>
                {IS_EMPOWER && (
                  <li className="pc-layout__item">
                    <a
                      className={`pc-btn pc-btn--bare qa-document-vault-container-nav-btn ${
                        selectedTab === FORMS ? "is-active" : ""
                      }`}
                      href={`#/documents/forms${selectedAccountId}`}
                    >
                      Forms
                    </a>
                  </li>
                )}
                <li className="pc-layout__item">
                  <a
                    className={`pc-btn pc-btn--bare qa-document-vault-container-nav-btn ${
                      selectedTab === TAX ? "is-active" : ""
                    }`}
                    href={`#/documents/tax${selectedAccountId}`}
                  >
                    Tax
                  </a>
                </li>
                {IS_EMPOWER && (
                  <li className="pc-layout__item">
                    <a
                      className={`pc-btn pc-btn--bare qa-document-vault-container-nav-btn ${
                        selectedTab === OTHER ? "is-active" : ""
                      }`}
                      href={`#/documents/other${selectedAccountId}`}
                    >
                      Other
                    </a>
                  </li>
                )}
                <li className="pc-layout__item">
                  <a
                    className={`pc-btn pc-btn--bare qa-document-vault-container-nav-btn ${
                      selectedTab === UPLOADED ? "is-active" : ""
                    }`}
                    href={`#/documents/uploaded${selectedAccountId}`}
                  >
                    Uploaded
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="sticky-wrapper">
            <div className="nav-secondary nav-secondary--feature-controls l-spaced l-spaced--flush js-feature-controls">
              <div className="upload-document-wrapper">
                <a
                  className="pc-btn pc-btn--small pc-btn--bare js-document-vault-dm-link qa-document-vault-dm-link"
                  onClick={this.openUploadModal}
                >
                  Upload Document
                </a>
              </div>
              <div
                style={{ display: `${displaySelector}` }}
                className="dateSelector js-document-vault-date-selector"
                ref={(el) => {
                  this.dateRangeSelectorElement = el;
                }}
              />
            </div>
          </div>
          {selectedTab === "FORMS" ? (
            <DocumentVaultFormsView formMessaging={formMessaging} />
          ) : (
            <DocumentVaultTable
              documents={documents}
              onDeleteClick={this.openDeleteModal}
              activeTab={selectedTab}
              renderZeroStateMessage={() => {
                return (
                  <div className="pc-beta u-text-center document-vault-table-zero-state pc-u-mt+ js-document-vault-table-zero-state">
                    No documents are available
                  </div>
                );
              }}
            />
          )}
        </div>
      </>
    );
  }
}

DocumentVaultContainer.propTypes = {
  tab: PropTypes.string,
};

DocumentVaultContainer.defaultProps = {
  tab: STATEMENTS,
};

export default DocumentVaultContainer;
