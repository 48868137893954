import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import breadcrumbTemplate from "templates/partials/breadcrumb2.html";
var breadcrumbView2 = Backbone.View.extend({
  initialize: function (options) {
    this.breadCrumbSize = options.size || "tiny";
    this.breadcrumbModel = new Backbone.Model({
      breadcrumbs: options.breadcrumbs,
    });
    this.listenTo(this.breadcrumbModel, "change:breadcrumbs", this.render);
    this.render();
  },

  /**
   * @param {array} breadcrumbs - an array of links or spans to render
   */
  update: function (breadcrumbs) {
    this.breadcrumbModel.set("breadcrumbs", breadcrumbs);
  },

  render: function () {
    this.$el.html(
      breadcrumbTemplate({
        size: this.breadCrumbSize,
        breadcrumbs: this.breadcrumbModel.get("breadcrumbs"),
      })
    );
  },
});

export default breadcrumbView2;
