/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { noop } from "underscore";
import PropTypes from "prop-types";
import Checkbox from "components/common/form/Checkbox";
import Mixpanel from "mixpanel";

export const ApplyTransactionRuleContainer = ({
  model,
  originalModel,
  categories,
  handleSubmit,
  handleCloseModal,
}) => {
  const [applyToPreviousTransactions, setApplyToPreviousTransactions] =
    useState(false);

  const getCategoryName = (categoryId, categories) => {
    const categoryIdInt = parseInt(categoryId, 10);
    const selectCategory = categories.find((category) => {
      return category.transactionCategoryId === categoryIdInt;
    });
    return selectCategory && selectCategory.name;
  };

  const checkboxHandler = () => {
    setApplyToPreviousTransactions(!applyToPreviousTransactions);
  };

  const onSubmit = (shouldApplyToFutureTransactions, ev) => {
    if (applyToPreviousTransactions) {
      Mixpanel.trackEvent("Category Rules - Applied to Past Transactions");
    }
    if (shouldApplyToFutureTransactions) {
      Mixpanel.trackEvent("Category Rules - Applied to Future Transactions");
    } else {
      Mixpanel.trackEvent("Category Rules - Applied to Single Transaction");
    }
    handleSubmit(
      ev,
      shouldApplyToFutureTransactions,
      applyToPreviousTransactions
    );
    handleCloseModal();
  };

  Mixpanel.trackEvent("Category Rules - Prompted");

  const checkboxClassname = IS_EMPOWER
    ? "apply-transaction-rule-empower__checkbox"
    : "apply-transaction-rule__checkbox";

  return (
    <div>
      <div data-testid="apply-transaction-rule__text">
        Do you always want to rename &quot;{originalModel.description}&quot; to{" "}
        <strong>{model.description}</strong> and categorize as{" "}
        <strong>{getCategoryName(model.categoryId, categories)}</strong>?
      </div>
      <div className="apply-transaction-rule__checkbox-container">
        <Checkbox
          label=""
          name="apply-rule-checkbox"
          checked={applyToPreviousTransactions}
          onChange={checkboxHandler}
          labelClassName={`pc-label pc-label--inline ${checkboxClassname}`}
        />
        <div>Apply changes to past transactions</div>
      </div>
      <div className="apply-transaction-rule__button-container">
        <button
          disabled={applyToPreviousTransactions}
          onClick={(ev) => onSubmit(false, ev)}
          className="pc-btn"
        >
          No
        </button>
        <button
          className="pc-btn pc-btn--primary"
          onClick={(ev) => onSubmit(true, ev)}
        >
          Yes
        </button>
      </div>
    </div>
  );
};

ApplyTransactionRuleContainer.propTypes = {
  model: PropTypes.object,
  originalModel: PropTypes.object,
  categories: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleCloseModal: PropTypes.func,
};

ApplyTransactionRuleContainer.defaultProps = {
  model: {},
  originalModel: {},
  categories: [],
  handleSubmit: noop,
  handleCloseModal: noop,
};

export default ApplyTransactionRuleContainer;
