/* eslint-disable sonarjs/no-duplicate-string, sonarjs/cognitive-complexity*/
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import AccountsCollection from "collections/accounts";
import DatagridView2 from "views/components/accountDetails/DatagridView2";
import CashView from "views/components/accountDetails/cashView";
import CreditCardView from "views/components/accountDetails/creditCardView";
import InvestmentView from "views/components/accountDetails/investmentView";
import ManualPortfolioView from "views/components/accountDetails/manualPortfolioView";
import LoanMortgageView from "views/components/accountDetails/loanMortgageView";
import AccountDetailState from "stateModels/accountDetailState";
import AccountDetailsTemplate from "templates/accountDetails.html";
import EventBus from "eventBus";
import AppWideBanner from "../../components/appWideBanner/AppWideBanner";
import React from "react";
import ReactDOM from "react-dom";
import AppOverlay from "appOverlay";
import Mixpanel from "mixpanel";

const DELAY_PARTNER_SUBMIT = 1500;
const TEXT_WAITING_TO_FETCH_365_DAYS_OF_TRANSACTIONS =
  "We are working to retrieve up to a year of transactions for this account. Please check back later.";
const BANNER_TIMEOUT = 30000;
const BANNER_SLIDE_UP_DURATION = 300;
const DEFAULT_SUBSCRIBE_NEXT_ACTION_MESSAGE =
  "We are having some trouble updating this site. These issues are usually related to problems with a financial institution's website and resolve themselves in a few days. We can notify you when this connection is restored.";

var AccountDetailsView = Backbone.View.extend({
  el: "#accountDetails",
  events: {
    "click .js-notify-me": "notifyMeWhenUpdatedClick",
  },
  initialize: function (options) {
    this.options = options;
    this.render();
    this.collection = new AccountsCollection();
    this.collection.on("removed", this.onAccountRemoved, this);
    this.fetchAccounts();
  },
  onClose: function () {
    this.collection.off("removed", this.onAccountRemoved, this);
    if (this.accountsWatchId) {
      Services.Accounts.get.unwatch(this.accountsWatchId);
      delete this.accountsWatchId;
    }

    if (this.currentProductView) {
      this.currentProductView.close();
    }

    this.destroyHeaderBanner();

    if (!IS_IFRAMED) {
      EventBus.trigger("viewClosing");
    }
  },
  render: function () {
    this.$el.html(AccountDetailsTemplate());
    this.dataGridDOMcontainer = this.$(".datagridSection").get(0);
    return this;
  },
  fetchAccounts: function () {
    this.accountsWatchId = Services.Accounts.get.watch(
      this.onAccountsFetched,
      this
    );
  },
  onAccountsFetched: function (err, response) {
    if (err === null) {
      if (this.collection.length < 1) {
        this.collection.reset(response.spData.accounts);
      } else {
        this.collection.freshen(response.spData.accounts);
      }
      this.showAccountView();
    }
  },
  updateView: function (newViewState) {
    Backbone.View.prototype.updateView.call(this, newViewState);

    this.showAccountView();
  },

  initializeView: function (account, viewState) {
    var productType = account.get("productType");
    var isManualPortfolio = account.get("isManualPortfolio");

    this.$el.find(".detailsSection").html('<div class="content"></div>');
    var childDiv = this.$el.find(".content"),
      viewConfiguration = _.extend({ el: childDiv, model: account }, viewState);
    switch (productType) {
      case "BANK":
        this.currentProductView = new CashView(viewConfiguration);
        break;
      case "CREDIT_CARD":
        this.currentProductView = new CreditCardView(viewConfiguration);
        break;
      case "INVESTMENT":
        if (isManualPortfolio) {
          this.currentProductView = new ManualPortfolioView(viewConfiguration);
        } else {
          viewConfiguration.isUserAggQual = this.options.isUserAggQual;
          this.currentProductView = new InvestmentView(viewConfiguration);
        }
        break;
      case "LOAN":
      case "MORTGAGE":
        this.currentProductView = new LoanMortgageView(viewConfiguration);
        break;
      default:
        /* When a "closed" Other asset/Other liability account is reopened from the Edit account modal,
         * we get here. We don't come here in any other scenario.
         * Borrowing the LoanMortgageView to display the details. Since these are manual
         * accounts, there is no transaction to show in the data grid.
         */
        this.currentProductView = new LoanMortgageView(viewConfiguration);
    }
  },

  showHeaderBanner: function (content, actions) {
    this.destroyBannerTimeout();
    this.fetchingHeaderBanner = this.$(
      ".js-account-details-banner-container"
    )[0];
    $(this.fetchingHeaderBanner).show();
    ReactDOM.render(
      <AppWideBanner
        isOpen={true}
        className={"app-wide-banner--dark"}
        text={content}
        actions={actions}
      />,
      this.fetchingHeaderBanner
    );
    this.bannerTimeOut = setTimeout(() => {
      $(this.fetchingHeaderBanner).slideUp(BANNER_SLIDE_UP_DURATION, () =>
        ReactDOM.unmountComponentAtNode(this.fetchingHeaderBanner)
      );
    }, BANNER_TIMEOUT);
  },

  destroyHeaderBanner: function () {
    this.destroyBannerTimeout();
    if (
      this.fetchingHeaderBanner &&
      this.fetchingHeaderBanner.children.length > 0
    ) {
      ReactDOM.unmountComponentAtNode(this.fetchingHeaderBanner);
    }
  },

  destroyBannerTimeout: function () {
    if (this.bannerTimeOut) {
      clearTimeout(this.bannerTimeOut);
    }
  },

  showAccountView: function () {
    var account,
      self = this;

    this.account = account = this.collection.find(function (account) {
      return (
        String(account.get("userAccountId")) === self.options.userAccountId
      );
    });

    // if account has been deleted, then navigate to dashboard
    if (!account) {
      window.location.href = DASHBOARD_URL;
      return;
    }

    if (
      !_.isUndefined(this.previousAccount) &&
      _.isEqual(account.attributes, this.previousAccount.attributes) &&
      _.isEqual(this.options, this.previousOptions) &&
      this.currentProductType === account.get("productType")
    ) {
      return;
    }

    var viewStateResult = Backbone.View.prototype.trackViewState.call(
      this,
      "accountDetails",
      AccountDetailState,
      true,
      "userAccountId"
    );
    var viewState = viewStateResult.viewState;

    // showRefetchTransaction and isRefetchTransactionEligible
    // determines whether to show, enable or disable the refetch button.
    viewState.showRefetchTransaction =
      account &&
      !account.get("isManual") &&
      !account.get("isOnUs") &&
      !account.get("closedDate") &&
      !account.get("isOnUsBank") &&
      !account.get("isOnUsRetirement");
    viewState.isRefetchTransactionEligible =
      account &&
      account.get("isRefetchTransactionEligible") &&
      ["NONE", "INFO", "WARNING"].includes(account.get("nextAction")?.action);

    var productType = account.get("productType");
    var isManualPortfolio = account.get("isManualPortfolio");

    if (
      this.currentProductType !== productType ||
      this.previousAccount.get("userAccountId") !== account.get("userAccountId")
    ) {
      AppOverlay.show();
    }

    // If we're trying to render a manual portfolio, and there is already a manual portfolio rendered, then update the view
    if (
      isManualPortfolio &&
      !_.isUndefined(this.currentIsManualPortfolio) &&
      this.currentIsManualPortfolio
    ) {
      this.currentProductView.updateView(viewState);
      // If the view is open in an aggregated account, and we're trying to render another aggregated account, then update the view
    } else if (
      !_.isUndefined(this.currentProductType) &&
      this.currentProductType === productType &&
      !_.isUndefined(this.currentIsManualPortfolio) &&
      !this.currentIsManualPortfolio &&
      !isManualPortfolio
    ) {
      this.currentProductView.updateView(viewState);
    } else {
      // If the view is open in an aggregated account and we're trying to render a manual or viceversa, close the child view
      if (typeof this.currentProductView != "undefined") {
        this.currentProductView.close();
      }

      if (
        ((productType === "INVESTMENT" && this.currentProductType === "BANK") ||
          (productType === "BANK" &&
            this.currentProductType === "INVESTMENT")) &&
        this.previousAccount &&
        this.previousAccount.attributes &&
        this.previousAccount.attributes.userAccountId.toString() ===
          this.options.userAccountId
      ) {
        // User has grouped an account as investment, or reversed such a grouping
        // Blank out tab so that the correct one will be selected
        viewState.tabId = null;
      }

      this.initializeView(account, viewState);
    }

    switch (productType) {
      case "BANK":
        this.options.tabId = viewState.tabId ? viewState.tabId : "cashflow";
        break;
      case "CREDIT_CARD":
        this.options.tabId = viewState.tabId ? viewState.tabId : "charges";
        break;
      case "INVESTMENT":
        //If the account is a manual account, it should set tabId to `holdings`
        if (viewState.tabId) {
          this.options.tabId = viewState.tabId;
        } else if (isManualPortfolio) {
          this.options.tabId = "holdings";
        } else {
          this.options.tabId = "balance";
        }

        break;
      case "LOAN":
      case "MORTGAGE":
        this.options.tabId = viewState.tabId ? viewState.tabId : "balance";
        break;
      default:
    }
    this.currentProductType = productType;
    this.currentIsManualPortfolio = isManualPortfolio;
    if (this.options.tabId === "recommendation") {
      this.$(".datagridSection").addClass("is-hidden");
    } else {
      this.renderDatagrid();
    }
    this.previousAccount = account;
    this.previousOptions = _.clone(this.options);

    if (
      account.get("is365DayTransactionEligible") &&
      !account.get("closedDate")
    ) {
      this.showHeaderBanner(TEXT_WAITING_TO_FETCH_365_DAYS_OF_TRANSACTIONS);
    } else {
      this.destroyHeaderBanner();
    }
    // If the user is not subscribed for notification and the account is not closed
    if (
      account?.attributes?.nextAction?.hasUserSubscribedToNotification ===
        false &&
      !account.get("closedDate")
    ) {
      const actions = [
        <button
          className="pc-btn pc-btn--tiny pc-btn--primary js-notify-me"
          key="Notify Me"
          disabled={
            account?.attributes?.nextAction?.hasUserSubscribedToNotification
          }
        >
          Notify Me
        </button>,
      ];

      this.showHeaderBanner(
        account?.attributes?.nextAction?.nextActionMessage === undefined
          ? DEFAULT_SUBSCRIBE_NEXT_ACTION_MESSAGE
          : account.attributes.nextAction.nextActionMessage,
        actions
      );
    }
  },
  renderDatagrid: function () {
    this.$(".datagridSection").removeClass("is-hidden");
    let props = {
      tabId: this.options.tabId,
      account: this.account,
      transactionCategoryRulesFlag: this.options.transactionCategoryRulesFlag,
      manualTransactionsFlag: this.options.manualTransactionsFlag,
      //Manual accounts are not eligible for adding manual transactions
      eligibleForManualTransactions: this.account.get(
        "isManualTransactionAllowed"
      ),
    };
    ReactDOM.render(
      React.createElement(DatagridView2, props),
      this.dataGridDOMcontainer
    );
  },
  remove() {
    ReactDOM.unmountComponentAtNode(this.dataGridDOMcontainer);
    Backbone.View.prototype.remove.apply(this, arguments);
  },
  onAccountRemoved: function (account) {
    if (account) {
      var accountDetailsUrl =
        "accounts/details?a=" + account.get("userAccountId");
      if (window.location.href.indexOf(accountDetailsUrl) !== -1) {
        window.location.href = DASHBOARD_URL;
      }
    }
  },
  navigateTo401kPartner: function (e) {
    e.preventDefault();
    // get partner credentials for user
    Services.OnUs401KSiteCredential.get(
      { userSiteId: this.account.get("userSiteId") },
      function (err, response) {
        var username = response.spData.userName,
          password = response.spData.password;
        // initiate sso with partner
        Services.OnUs401kPartnerSSO.get(
          { USERID: username },
          function (err, response) {
            if (response.spData.gatekeeperposted === "OK") {
              var cookieFrame = $("#onUs401kPartnerCookieFrame");
              // generate partner sso cookie
              var cookieForm = cookieFrame.contents().find("form");
              cookieForm.find("#USERID").val(username);
              cookieForm.submit();
              // wait for cookie to be set
              setTimeout(function () {
                // submit partner credentials for user
                var signinForm = $("#partnerLoginForm");
                signinForm.find("#USERID").val(username);
                signinForm.find("#PASSWDTXT").val(password);
                signinForm.submit();
              }, DELAY_PARTNER_SUBMIT);
            }
          }
        );
      }
    );
  },
  notifyMeWhenUpdatedClick: function (e) {
    e.target.disabled = true;
    Services.Profile.updateUserSitePreferences({
      userSiteId: this.account.get("userSiteId"),
      subscribeForNotification: true,
    });
    Mixpanel.trackEvent("Click Notify Me", {
      component: "Account Details",
    });
  },
});

export default AccountDetailsView;
