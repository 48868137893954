import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import AddManualAccountContainer from "empower/components/AddManualAccountContainer";
import noop from "utils/noop";

const AddManualAccountModal = ({ isOpen, onCancel, onSave }) => {
  return (
    <Modal
      componentName="AddManualAccountModal"
      title="Add Account to Transfer"
      isOpen={isOpen}
      onClosed={onCancel}
      className="pc-modal pc-modal--large"
      shouldCloseOnOverlayClick={false}
    >
      <AddManualAccountContainer onCancel={onCancel} onSave={onSave} />
    </Modal>
  );
};

AddManualAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

AddManualAccountModal.defaultProps = {
  onCancel: noop,
  onSave: noop,
};

export default AddManualAccountModal;
