import PropTypes from "prop-types";
import React from "react";
import ChartReact from "libs/pcap/chart/chart--react";
import { format, select, selectAll } from "d3";
import {
  circleRight,
  circleLeft,
  defaultSubject,
} from "common/annotationTypes";
import _ from "underscore";
import { annotation } from "d3-svg-annotation";
import { formatCurrency } from "libs/pcap/utils/format";
import { roundDecimal } from "libs/pcap/utils/decimalAdjust";

function xAccessor(item) {
  return item.x;
}

function yAccessor(item) {
  return item.y;
}

class InvestableCashAreaChart extends React.Component {
  constructor(props) {
    super(props);

    const baseInvestment = _.first(
      props.message.resources[0].jsonContent.chartData[0].data
    ).y;

    const investedCashData = _.map(
      props.message.resources[0].jsonContent.chartData[0].data,
      (num) => {
        const number = Object.assign({}, num);
        number.y = number.y - baseInvestment;
        return number;
      }
    );

    const idleCashData = _.map(
      props.message.resources[0].jsonContent.chartData[1].data,
      (num) => {
        const number = Object.assign({}, num);
        number.y = number.y - baseInvestment;
        return number;
      }
    );

    this.state = {
      investedCashData,
      idleCashData,
      timeLength: idleCashData.length - 1,
      retired: idleCashData.length - 1 > 25, // eslint-disable-line no-magic-numbers
      investedCash: props.message.resources[0].jsonContent.chartData[0].data,
      idleCash: props.message.resources[0].jsonContent.chartData[1].data,
    };
  }

  componentDidMount() {
    this.renderAnnotations();
  }

  renderAnnotations() {
    const { disableAnimations } = this.props;
    const chart = this.chartReact.chart;
    const precision = -2;
    const annotations = [
      {
        type: circleRight,
        className: `js-cost-of-cash js-cost-of-cash--base ${
          disableAnimations ? "" : "coc-animation"
        }`,
        note: {
          label: "$" + format(".3s")(this.state.investedCash[0].y),
          title: "Cash investment",
          wrap: 50,
          padding: 0,
        },
        x: chart.options.xScale(this.state.investedCashData[0].x),
        y: chart.options.yScale(this.state.investedCashData[0].y),
        dy: -1,
        subject: defaultSubject,
      },
      {
        type: circleLeft,
        className: `pc-annotation pc-annotation--positive pc-annotation--right js-cost-of-cash js-cost-of-cash--invested ${
          disableAnimations ? "" : "coc-animation"
        } ${this.state.retired ? "pc-annotation--retired" : ""}`,
        note: {
          label: formatCurrency(
            roundDecimal(
              this.state.investedCash[this.state.timeLength].y,
              precision
            ),
            0
          ),
          title: this.state.retired
            ? `Year ${
                new Date().getFullYear() + this.state.timeLength
              } (Retirement)`
            : "Year " + this.state.timeLength,
          wrap: 60,
          padding: 0,
        },
        x: chart.options.xScale(_.last(this.state.investedCashData).x),
        y: chart.options.yScale(_.last(this.state.investedCashData).y),
        dy: -3,
        subject: defaultSubject,
      },
      {
        type: circleLeft,
        className: `pc-annotation pc-annotation--negative pc-annotation--right js-cost-of-cash js-cost-of-cash--inflation ${
          disableAnimations ? "" : "coc-animation"
        }`,
        note: {
          label: formatCurrency(
            roundDecimal(
              this.state.idleCash[this.state.idleCash.length - 1].y,
              precision
            ),
            0
          ),
          padding: 0,
        },
        x: chart.options.xScale(_.last(this.state.idleCashData).x),
        y: chart.options.yScale(_.last(this.state.idleCashData).y),
        dy: 12,
        subject: defaultSubject,
      },
    ];

    const makeAnnotations = annotation().annotations(annotations);

    select(".insights-graph--cost-of-cash .js-chart-body")
      .append("g")
      .attr("class", "annotation-group")
      .call(makeAnnotations);
    selectAll(".annotation-note-title").attr("font-size", "10");
    makeAnnotations.updateText();

    if (!this.props.chartOnly) {
      select(".js-cost-of-cash--base")
        .attr("data-toggle", "tooltip")
        .attr(
          "data-original-title",
          "Current cash in bank and investment accounts minus your recommended emergency fund."
        );
      select(".js-cost-of-cash--invested")
        .attr("data-toggle", "tooltip")
        .attr(
          "data-original-title",
          `Expected value of invested cash over ${this.state.timeLength} years in your target portfolio.`
        );
      select(".js-cost-of-cash--inflation")
        .attr("data-toggle", "tooltip")
        .attr(
          "data-original-title",
          `Expected value of idle cash due to inflation over ${this.state.timeLength} years.`
        );

      selectAll(".pc-annotation--right")
        .selectAll(".annotation-note-label")
        .each(function () {
          const specs = this.getBBox(); // eslint-disable-line no-invalid-this
          select(this.parentNode)
            .append("line") // eslint-disable-line no-invalid-this
            .attr("x1", 1)
            .attr("x2", -(specs.width - 1))
            .attr("y1", specs.y + specs.height - 2)
            .attr("y2", specs.y + specs.height - 2)
            .attr("stroke-width", "1")
            .attr("stroke", "#333e48")
            .attr("stroke-linecap", "round")
            .attr("stroke-dasharray", "1, 2");
        });

      select(".js-cost-of-cash--base")
        .selectAll(".annotation-note-label")
        .each(function () {
          const specs = this.getBBox(); // eslint-disable-line no-invalid-this
          select(this.parentNode)
            .append("line") // eslint-disable-line no-invalid-this
            .attr("x1", 1)
            .attr("x2", specs.width - 1)
            .attr("y1", specs.y + specs.height - 2)
            .attr("y2", specs.y + specs.height - 2)
            .attr("stroke-width", "1")
            .attr("stroke", "#333e48")
            .attr("stroke-linecap", "round")
            .attr("stroke-dasharray", "1, 2");
        });
    }

    _.forEach(document.getElementsByClassName("js-cost-of-cash"), (el) => {
      this.props.storeTooltipRef(el, { container: "body", placement: "top" });
    });
  }

  render() {
    const { disableAnimations } = this.props;
    return (
      <div className="insight__chart-container insight__chart-container--cost-of-cash">
        {!disableAnimations && <div className="coc-animation__main" />}
        {!disableAnimations && <div className="coc-animation__opacity" />}
        <ChartReact
          ref={(el) => {
            this.chartReact = el;
          }}
          className="insights-graph--cost-of-cash"
          data={[this.state.investedCashData, this.state.idleCashData]}
          type="area"
          showXGrid={false}
          showYGrid={false}
          showYAxis={false}
          showXAxis={false}
          x={xAccessor}
          y={yAccessor}
          margin={{
            top: this.state.retired ? 56 : 38, // eslint-disable-line no-magic-numbers
            bottom: 24,
            left: 8,
            right: 8,
          }}
          disableAnimations={disableAnimations}
        />
      </div>
    );
  }
}

InvestableCashAreaChart.propTypes = {
  message: PropTypes.object,
  disableAnimations: PropTypes.bool,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
};

export default InvestableCashAreaChart;
