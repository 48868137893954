import React, { Component } from "react";
import PropTypes from "prop-types";

class ButtonGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selectedValue,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.selected !== nextProps.selectedValue) {
      this.setState({
        selected: nextProps.selectedValue,
      });
    }
  }

  renderButtons() {
    const { options, buttonClass } = this.props;
    const { selected } = this.state;

    if (options && options.length > 0) {
      return options.map((opt) => {
        const activeCls = selected === opt.value ? "active" : "";
        return (
          <button
            key={opt.value}
            className={`pc-btn-group__button ${buttonClass} ${activeCls} qa-${opt.value
              .toLowerCase()
              .replace(/_+/g, "-")}`}
            type="button"
            value={opt.value}
            disabled={this.props.disabled}
            onClick={() => this.handleClick(opt.value)}
          >
            {opt.label}
          </button>
        );
      });
    }

    return <div />;
  }

  handleClick(value) {
    if (this.state.selected !== value) {
      this.setState({
        selected: value,
      });
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <div className={`pc-btn-group ${this.props.className}`}>
        {this.renderButtons()}
      </div>
    );
  }
}

ButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  buttonClass: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ButtonGroup.defaultProps = {
  className: "",
  buttonClass: "",
  selectedValue: undefined,
  onChange: undefined,
  disabled: false,
};

export default ButtonGroup;
