export const EMPTY_VALIDATION_ERROR_MESSAGE = "Required";
export const GREATER_THAN_ZERO_MESSAGE = "Invalid Value";

export const VALIDATION_REQUIRED = {
  allowEmpty: false,
  required: true,
  messages: {
    allowEmpty: EMPTY_VALIDATION_ERROR_MESSAGE,
    required: EMPTY_VALIDATION_ERROR_MESSAGE,
  },
};

export const VALIDATION_REQUIRED_STRING = {
  ...VALIDATION_REQUIRED,
  type: "string",
};

export const VALIDATION_DENY_EMPTY = {
  allowEmpty: false,
  messages: {
    allowEmpty: EMPTY_VALIDATION_ERROR_MESSAGE,
  },
};

export const VALIDATION_REQUIRED_NUMBER = {
  ...VALIDATION_REQUIRED,
  type: "number",
};

export const VALIDATION_REQUIRED_GREATER_THAN_OR_EQUAL_ZERO = {
  ...VALIDATION_REQUIRED,
  conform: (v) => v >= 0,
  messages: {
    conform: GREATER_THAN_ZERO_MESSAGE,
  },
};
