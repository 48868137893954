import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";

const DisclaimerModal = (props) => {
  const { componentName, isOpen, onClosed = {} } = props;
  const modalComponentName = `${componentName} Disclaimer`;

  const organizationName = "Empower";
  const advisorsOrganizationName = "Empower's advisory services";
  const subsidiaryOrganizationName =
    "Empower Annuity Insurance Company of America";

  return (
    <Modal
      className="pc-modal pc-modal--huge"
      componentName={modalComponentName}
      contentLabel={modalComponentName}
      isOpen={isOpen}
      onClosed={onClosed}
      title={'Personalized Financial Goals and Plan ("Plan") - Disclosure'}
      overlayClassName="pc-overlay--transparent"
    >
      <p>
        {`The Personalized Financial Goals and Plan (the "Plan") is a diagnostic tool intended to review your current financial situation and suggest potential planning ideas and concepts that may be of benefit. Its purpose is to illustrate how certain financial planning principles may help you achieve your financial goals. This Plan provides broad and general guidelines on the advantages of certain financial planning concepts and does not constitute a recommendation of any particular technique. The term "plan" or "planning," when used herein, does not imply that a recommendation has been made to implement one or more financial plans or make a particular investment. Nor does the plan or report provide legal, accounting, tax or other advice. Rather, the Plan provides a summary of certain potential financial strategies. You are encouraged to seek advice from your professional accounting, tax and legal advisors.`}
      </p>
      <p>
        The analyses and information provided in the Plan are based upon
        information and assumptions that will be provided by you, which we
        assume to be complete and accurate.
      </p>
      <p>
        Past performance is not a guarantee of future return. The investment
        choices shown in the Plan could lose value and are not guaranteed{" "}
        <em>as to future results.</em> All investments involve risk, including
        the potential for loss of principal.
      </p>
      <p>
        The delivery and acceptance of the Plan in and of itself does not result
        in an ongoing adviser-client relationship between {organizationName} and
        you. {advisorsOrganizationName} acts in its capacity as a fiduciary up
        to the time of delivery of the Plan. Delivery of a Plan constitutes
        termination of an advisory relationship between you and{" "}
        {organizationName}.
      </p>
      <p>
        {`Any reference to the advisory services refers to Empower Advisory Group, LLC, a subsidiary of ${subsidiaryOrganizationName}. Empower Advisory Group, LLC is a registered investment advisor with the Securities Exchange Commission ("SEC"). SEC registration does not imply a certain level of skill or training.`}
      </p>

      <div className="u-text-right">
        <button className="pc-btn pc-btn--primary" onClick={onClosed}>
          Close
        </button>
      </div>
    </Modal>
  );
};

DisclaimerModal.propTypes = {
  componentName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClosed: PropTypes.func,
};

DisclaimerModal.defaultProps = {
  componentName: undefined,
  isOpen: undefined,
  onClosed: undefined,
};

export default DisclaimerModal;
