import PropTypes from "prop-types";
import React from "react";
import { trackView } from "components/common/ComponentAnalytics";

export default class CongratsPage extends React.Component {
  componentDidMount() {
    const { componentName } = this.props;
    trackView("Congratulations Page", { componentName });
  }

  render() {
    return (
      <div className="fin-plan-offer__page fin-plan-offer__page-text-only">
        <h1 className="fin-plan-offer__congrats-title pc-u-mt++">
          Congratulations!
        </h1>
        <h2 className="fin-plan-offer__title pc-u-mb++ pc-u-ml pc-u-mr">
          You qualified for a free financial plan. One of our experts will reach
          out to you to get started.
        </h2>
      </div>
    );
  }
}

CongratsPage.propTypes = {
  componentName: PropTypes.string,
};
