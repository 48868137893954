/* eslint-disable no-magic-numbers, array-callback-return */
import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import AbstractForm from "components/common/form/AbstractForm";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import { CategorySelector } from "./CategorySelector";
import InputTagsContainer from "components/common/InputTags/Container";
import { isEqual, clone, isEmpty } from "underscore";
import deepCopy from "deep-copy";
import { promisify } from "utils/service";
import Services from "services";
import moment from "moment";
import { isEmpowerPrivilegedMode } from "../../views/modules/sidebar/utils/accountUtils";
import DatePickerInput from "components/common/form/DatePickerInput";
import { DISPLAY_FORMAT } from "libs/pcap/utils/date2";
import FancySelect from "../common/form/FancySelect/FancySelect";
import { CURRENCY_FORMAT } from "components/common/form/formattingOptions";

const MAX_TRANSACTION_AMOUNT_DIGITS = 12;
const NUM_OF_DAYS_IN_YEAR = 365;

const isPrivileged = isEmpowerPrivilegedMode();
class ManualTransactionRow extends AbstractForm {
  //TODO Cleanup all unused state, eslint errors, Issue with displaying MANUAL_TRANSACTION tag.
  constructor(props) {
    super(props);
    this.state = {
      originalModel: deepCopy(props.model),
      model: deepCopy(props.model),
      categories: [],
      tags: [],
      loading: false,
      accountDropDownData: [],
      currentSelectedAccount: props.currentSelectedAccount,
      userAccountId: "",
      currentSelectedAccountCreateDate: props.currentSelectedAccountCreateDate,
      manualTransactionDate: "",
      isDuplicate: false,
      categoryId: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCategoryCreated = this.handleCategoryCreated.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.isValidCloseDate = this.isValidCloseDate.bind(this);
    this.isValidStartDate = this.isValidStartDate.bind(this);
    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.fetchAccounts = promisify(Services.Accounts.get);
    this.formatAccounts = this.formatAccounts.bind(this);
    this.handleCancelManualTransactionContainer =
      this.handleCancelManualTransactionContainer.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    if (!isEqual(nextProps.categories, prevState.prevPropsCategories)) {
      newState.categories = nextProps.categories;
      newState.prevPropsCategories = nextProps.categories;
    }

    if (!isEqual(nextProps.tags, prevState.prevPropsTags)) {
      newState.tags = nextProps.tags;
      newState.prevPropsTags = nextProps.tags;
    }

    return isEmpty(newState) ? null : newState;
  }

  componentDidMount() {
    // format accounts for dropdown
    // createdDate details for each account
    this.formatAccounts(this.props.accountsList);

    if (this.state.tags.length) {
      const { model } = this.state;
      let manualTransactionTagObject = this.state.tags.filter((tag) => {
        if (tag.tagName === "MANUAL_TRANSACTION") {
          return tag;
        }
      });
      model.selectedTags = [manualTransactionTagObject[0].tagId];
      this.setState({ model });
    }
  }

  handleTagsChange(selectedTagIds) {
    let { model } = this.state;
    model.selectedTags = selectedTagIds;
    this.setState({ model });
  }

  handleCategoryCreated(category) {
    let { model } = this.state;
    let categories = clone(this.state.categories);
    model.categoryId = category.transactionCategoryId;
    model.categoryName = category.name;
    categories.push(category);
    this.setState({ model, categories });
  }

  handleAccountChange(ev) {
    this.handleInputChange(ev, ev.target.value);
    //TODO Fix this. Use only one of these fields
    this.setState({
      currentSelectedAccount: ev.target.value,
      userAccountId: ev.target.value,
    });
    const accountDetails = this.state.accountDropDownData.filter(
      (obj) => obj.value === ev.target.value
    );

    /* after account selection setting range for date picker  */
    this.setState({
      currentSelectedAccountCreateDate: accountDetails[0].createdDate,
    });
    this.setState({
      manualTransactionDate: "",
    });
  }

  formatAccounts(accounts) {
    const accountObject = [];
    accounts?.map((item) => {
      if (item.infoSource === "YODLEE" && !item.closedDate) {
        let accountDetails = {
          label: item.name,
          value: item.userAccountId,
          createdDate: item.createdDate,
          userAccountId: item.userAccountId,
        };
        accountObject.push(accountDetails);
      }
    });
    this.setState({
      accountDropDownData: accountObject,
    });
  }

  handleCancelManualTransactionContainer() {
    this.props.onCancel();
  }

  isValidCloseDate(currentDate, accountCreatedDate = "") {
    if (!this.state.currentSelectedAccount && !accountCreatedDate) {
      const createdDate = moment(this.state.model.createdDate).startOf("day");
      const today = moment().endOf("day");
      return (
        currentDate.isSameOrAfter(createdDate) &&
        currentDate.isSameOrBefore(today)
      );
    }
  }

  isValidStartDate(currentDate) {
    const createdDate = moment(this.state.currentSelectedAccountCreateDate)
      .subtract(NUM_OF_DAYS_IN_YEAR, "days")
      .calendar();
    const today = moment().endOf("day");
    return (
      currentDate.isSameOrAfter(createdDate) &&
      currentDate.isSameOrBefore(today)
    );
  }

  handleDateInputChange(event) {
    const transactionDate = moment(event.target.value).format(DISPLAY_FORMAT);
    this.setState({
      manualTransactionDate: transactionDate,
    });
  }

  validateAllFields() {
    //TODO  Fix this properly. Add error class to fields or something like that
    //  if (this.validate().valid) {
    this.setState({ errors: [] });
    const errors = [];
    const { model } = this.state;
    if (!model.manualTransactionDate) {
      errors.push("Date must be selected");
    }
    if (!model.userAccountId) {
      errors.push("Account must be selected");
    }
    if (!model.description || !model.description.trim()) {
      errors.push("Description is required");
    }
    if (!model.categoryId) {
      errors.push("Category must be selected");
    }
    if (!model.amount) {
      errors.push("Amount can not be empty");
    }
    if (errors.length > 0) {
      this.setState({ errors });
      return false;
    }

    return true;
    //  }
  }
  // need to update wrt add manual transaction api
  handleSubmit(ev) {
    ev.preventDefault();
    if (this.validateAllFields()) {
      this.setState({ loading: true });
      const { model } = this.state;

      let requestModel = {
        transactionDate: moment(model.manualTransactionDate).format(
          "YYYY-MM-DD"
        ),
        //For account details page, get the userAccountId from the account prop
        userAccountId: parseInt(model.userAccountId, 10),
        description: model.description,
        transactionCategoryId: parseInt(model.categoryId, 10),
        amount: parseFloat(model.amount),
        customTags: JSON.stringify(deepCopy(model.selectedTags)),
      };

      this.props
        .onAddManualTransaction(requestModel)
        .then((newTransaction) => {
          this.setState({ loading: false });
          this.props.onSavedManualTransaction([newTransaction]);
          this.props.onCancel();
        })
        .catch((errors) => {
          this.setState({ errors, loading: false });
        });
    }
  }

  render() {
    const { columns, schema, accountForManualTransaction } = this.props;
    const {
      model,
      categories,
      tags,
      loading,
      errors,
      accountDropDownData,
      currentSelectedAccount,
    } = this.state;

    // Make sure we keep `CategorySelector` options sorted as custom categories are added.
    const categoryColumn = columns.find((col) => col.header === "Category");
    const categoryComparatorAsc = categoryColumn.comparator(
      (cat) => cat.name,
      "asc"
    );
    const sortedCategories = clone(categories).sort(categoryComparatorAsc);
    const accountName = accountForManualTransaction?.get("name");

    // TODO Fix the datepicker for account details page
    if (isEmpty(model.splits)) {
      return (
        <>
          <form
            className="table__row--edit pc-u-pt- pc-bg-dark qa-edit-transaction-form js-edit-transaction-form"
            onSubmit={this.handleSubmit}
          >
            {errors && (
              <Message className="pc-u-mh- pc-u-mb--" messages={errors} />
            )}
            <LoadingOverlay active={loading} />
            <div className="pc-transactions-grid__edit-row-inner">
              <div
                className={`table__column pc-transactions-grid-manual-cell--date qa-transaction-time`}
              >
                {/* Date picker component for manual transaction date  */}
                <DatePickerInput
                  name="manualTransactionDate"
                  id="manualTransactionDate"
                  className="pc-transaction-grid_transaction-date"
                  validator={DatePickerInput.getValidator({
                    required: true,
                    allowEmpty: false,
                    isAllowedDate:
                      currentSelectedAccount || model.currentSelectedAccount
                        ? this.isValidStartDate
                        : this.isValidCloseDate,
                  })}
                  ref={this.storeInputRef}
                  isAllowedDate={
                    currentSelectedAccount || model.currentSelectedAccount
                      ? this.isValidStartDate
                      : this.isValidCloseDate
                  }
                  displayDateFormat={DISPLAY_FORMAT}
                  onChange={this.handleInputChange}
                  value={model.manualTransactionDate}
                  disabled={!model.userAccountId}
                />
              </div>
              {accountForManualTransaction ? (
                <div className="table__column pc-transactions-grid-manual-cell--account-name u-clip-text qa-account-name">
                  {accountName}
                </div>
              ) : null}
              {this.props?.accountsList?.length > 0 ? (
                <div
                  className={
                    "table__column pc-transactions-grid-manual-cell--account-name u-clip-text qa-account-name"
                  }
                  style={{ overflow: "visible" }}
                >
                  <FancySelect
                    className="pc-transactions-grid--accountSelect-AddManual "
                    name="userAccountId"
                    isSearchable={false}
                    options={accountDropDownData}
                    onChange={(value) => {
                      this.handleAccountChange(value);
                    }}
                    value={model.userAccountId}
                    validator={schema.properties.account}
                    placeholder={"Account"}
                  />
                </div>
              ) : null}
              <div
                className={`table__column pc-transactions-grid-manual-cell--description u-clip-text qa-description`}
              >
                <Input
                  id="description"
                  ref={this.storeInputRef}
                  type="text"
                  value={model.description}
                  className="pc-transactions-grid--description-AddManual"
                  name="description"
                  onChange={this.handleInputChange}
                  placeholder={"Description"}
                  validator={schema.properties.description}
                  sizeVariation="full"
                  maxLength={100}
                />
              </div>

              <div
                className={`table__column pc-transactions-grid-manual-cell--category u-clip-text qa-category-name`}
                style={{ overflow: "visible" }}
              >
                <CategorySelector
                  className="pc-transactions-grid--categorySelect-AddManual"
                  options={sortedCategories}
                  name="categoryId"
                  onChange={this.handleInputChange}
                  onCategoryCreated={this.handleCategoryCreated}
                  value={model.categoryId}
                  validator={schema.properties.categoryId}
                />
              </div>
              <div
                className={`table__column pc-transactions-grid-manual-cell--amount pc-transactions-grid-cell--amount-parent qa-value tabular-numbers`}
              >
                <Input
                  id="amount"
                  ref={this.storeInputRef}
                  type="text"
                  value={model.amount}
                  className="pc-transactions-grid--amount-AddManual"
                  name="amount"
                  onChange={this.handleInputChange}
                  prefix="$"
                  formattingOptions={CURRENCY_FORMAT}
                  validator={schema.properties.amount}
                  ariaLabel="Transaction amount"
                  maxLength={MAX_TRANSACTION_AMOUNT_DIGITS}
                />
              </div>
            </div>
            <div className="pc-transactions-grid__edit-row-inner">
              <div
                className={`table__column pc-transactions-grid-manual-cell--date pc-transactions-grid-manual-dummy-column qa-transaction-time`}
              ></div>
              <div
                className={`table__column pc-transactions-grid-manual-cell--account-name pc-transactions-grid-manual-dummy-column u-clip-text u-text-right  qa-account-name`}
                style={{ overflow: "visible" }}
              >
                <label htmlFor="transactionEditTag">Tags</label>
              </div>
              <div className="table__column pc-transactions-grid-manual-cell--tags pc-transactions-grid-manual-dummy-column">
                <InputTagsContainer
                  tags={tags}
                  onTagsModified={this.handleTagsChange}
                  value={model.selectedTags}
                  selectInputId="transactionEditTag"
                  validator={schema.properties.tags}
                />
              </div>
              {/* </div> */}
            </div>
            <div className="pc-layout pc-u-p-">
              <div className="pc-layout__item pc-u-1/2" />
              <div className="pc-layout__item pc-u-1/2 pc-layout--right">
                <button
                  type="button"
                  className="pc-btn pc-btn--cancel pc-btn--small qa-close-edit-transaction"
                  onClick={this.handleCancelManualTransactionContainer}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="pc-btn pc-btn--small pc-btn--primary qa-save-transaction"
                  aria-disabled={isPrivileged}
                  disabled={isPrivileged}
                >
                  Done
                </button>
              </div>
            </div>
          </form>
        </>
      );
    }
  }
}

ManualTransactionRow.defaultProps = {
  schema: {
    type: "object",
    properties: {
      manualTransactionDate: {
        required: true,
        type: "string",
        allowEmpty: false,
        messages: {
          required: "Date is required.",
          allowEmpty: "Date is required.",
        },
      },
      description: {
        required: true,
        type: "string",
        allowEmpty: false,
        conform: (str) => /.*\S.*/.test(str),
        messages: {
          required: "Description is required.",
          allowEmpty: "Description is required.",
          conform: "Description cannot be empty",
        },
      },
      // TODO Get these validators to work
      categoryId: {
        required: true,
        type: "number",
        allowEmpty: false,
        conform: (v) => v !== undefined,
        messages: {
          required: "Please select a category.",
          conform: "Please select a category",
        },
      },
      account: {
        required: true,
        allowEmpty: false,
        conform: (v) => v !== undefined,
        messages: {
          required: "Please select an account.",
          allowEmpty: "Please select an account.",
        },
      },
      amount: {
        required: true,
        allowEmpty: false,
        conform: (v) => v !== 0,
        messages: {
          required: "Amount is required.",
          allowEmpty: "Amount is required.",
          conform: "Amount is required",
        },
      },
      tags: {
        required: true,
        allowEmpty: false,
        messages: {
          required: "Please select a tag.",
          allowEmpty: "Please select a tag.",
        },
      },
    },
  },
  model: {
    manualTransactionDate: moment().endOf("day").format(DISPLAY_FORMAT),
    userAccountId: undefined,
    description: undefined,
    categoryId: undefined,
    amount: undefined,
    isDuplicate: false,
    selectedTags: [],
    currentSelectedAccount: "",
    currentSelectedAccountCreateDate: "",
  },
  accountsList: undefined,
  accountForManualTransaction: undefined,
};

ManualTransactionRow.propTypes = {
  categories: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  model: PropTypes.object,
  columns: PropTypes.array.isRequired,
  accountsList: PropTypes.array,
  accountForManualTransaction: PropTypes.object,
  onAddManualTransaction: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSavedManualTransaction: PropTypes.func.isRequired,
};

export default ManualTransactionRow;
