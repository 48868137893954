import PropTypes from "prop-types";
import React from "react";
import PersonSelect from "common/PersonSelect/PersonSelect";
import Input from "components/common/form/Input";
import {
  HUNDRED_PERCENT,
  getAllOwners,
  isRelativeValidSelectorOption,
  getTotalPercentage,
  getOverUnderText,
} from "./stakeholdersUtils";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PositiveCurrencyFormatter from "components/common/form/formatters/positiveCurrency";
import objectPath from "object-path";
import deepCopy from "deep-copy";
import { isEmpty } from "underscore";

const EMPTY_PRIMARY_OWNER = {
  personId: null,
  percentage: "",
  role: "PRIMARY",
};
const EMPTY_SECONDARY_OWNER = {
  personId: null,
  percentage: "",
  role: "SECONDARY",
};

const NEW_PERSON_ID = -1;

export default class Owners extends React.Component {
  constructor(props) {
    super(props);

    const value = props.value;

    if (isEmpty(value.PRIMARY)) {
      value.PRIMARY.push(deepCopy(EMPTY_PRIMARY_OWNER));
    }

    this.state = {
      value,
    };

    this.ownerInputs = new Map();

    this.validatePercentage = this.validatePercentage.bind(this);
    this.storeOwnerInputRef = this.storeOwnerInputRef.bind(this);
    this.handleOwnerAdd = this.handleOwnerAdd.bind(this);
    this.handleOwnerChange = this.handleOwnerChange.bind(this);
    this.handleOwnerRemove = this.handleOwnerRemove.bind(this);
    this.handlePercentageChange = this.handlePercentageChange.bind(this);
    this.handlePercentageBlur = this.handlePercentageBlur.bind(this);
    this.renderOwnerFormGroup = this.renderOwnerFormGroup.bind(this);
    this.renderPrimaryOwnerFormGroup = this.renderPrimaryOwnerFormGroup.bind(
      this
    );
    this.renderSecondaryOwnerFormGroup = this.renderSecondaryOwnerFormGroup.bind(
      this
    );
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  triggerChangeEvent(value) {
    // send a simple object that looks like an event
    this.props.onChange({
      target: {
        name: this.props.name,
        value,
      },
    });
  }

  handleOwnerAdd(ev) {
    ev.preventDefault();

    const value = this.state.value;

    if (value.PRIMARY.length) {
      value.SECONDARY.push(deepCopy(EMPTY_SECONDARY_OWNER));
    } else {
      value.PRIMARY.push(deepCopy(EMPTY_PRIMARY_OWNER));
    }

    this.setState({ value }, this.validatePercentage);
    this.triggerChangeEvent(value);
  }

  handleOwnerChange(ev) {
    const name = ev.target.name;
    const personId = parseInt(ev.target.value, 10);
    let index;
    if (name.includes("PRIMARY")) {
      index = 0;
    } else {
      const indexRegex = /SECONDARY\.(\d+)\.personId/;
      const matches = indexRegex.exec(name);
      // SECONDARY owners start from index 1, after the PRIMARY, so add 1 to get the field index
      index = parseInt(matches[1], 10) + 1;
    }

    const value = this.state.value;
    objectPath.set(value, name, personId);

    this.setState({ value });
    this.triggerChangeEvent(value);

    if (personId === NEW_PERSON_ID) {
      this.props.onAddPersonClick(index);
    }
  }

  handlePercentageChange(ev) {
    const el = ev.target;
    const name = el.name;
    let percentage = parseInt(el.value, 10);
    if (isNaN(percentage)) {
      percentage = "";
    }

    const value = this.state.value;
    objectPath.set(value, name, percentage);

    this.setState({ value, dirty: true }, () => {
      // the validation of the total starts after the user leaves the input field
      if (this.state.validationStarted) {
        this.validatePercentage();
      }
    });
    this.triggerChangeEvent(value);
  }

  handlePercentageBlur(ev) {
    const reactEl = this.ownerInputs.get(ev.target.name);
    if (reactEl.state.dirty) {
      this.setState({ validationStarted: true });
      this.validatePercentage();
    }
  }

  handleOwnerRemove(ev) {
    const value = this.state.value;
    // Can only remove secondary owners, not primary
    value.SECONDARY.splice(parseInt(ev.target.dataset.index, 10), 1);
    this.setState({ value }, this.validatePercentage);

    this.triggerChangeEvent(value);
  }

  /**
   * Validates the percentage total. Must not exceed 100%.
   *
   * @returns {Object}  the validation result consisting of an array of errors and a boolean
   *                    flag indicating that the value is valid {errors, valid}.
   */
  validatePercentage() {
    const { value } = this.state;
    const allOwners = getAllOwners(value);
    const result = {
      valid: true,
      errors: null,
    };
    // valid if there is no form fields for owners (PRIMARY must exist if any do)
    if (value.PRIMARY.length) {
      // Don't validate the fieldset as the whole until all fields are populated
      let incomplete = allOwners.find((b) => b.percentage === "");
      if (!incomplete) {
        const totalPercent = getTotalPercentage(allOwners);

        if (totalPercent > HUNDRED_PERCENT) {
          result.errors = ["The total for all owners must not exceed 100%"];
          result.valid = false;
        }
      }
    }

    this.setState(result);

    return result;
  }

  /**
   * Validate method for compatibility with `AbstractForm`.
   * Validates child inputs and the percentage sum.
   *
   * @return {Object} The validation result
   */
  validate() {
    let errors = [];
    if (this.ownerInputs.size > 0) {
      errors = Array.from(this.ownerInputs.values())
        .map((el) => {
          const result = el.validate();
          if (!result.valid) {
            return result.errors;
          }
          return [];
        })
        .reduce((acc, cur) => acc.concat(cur), []);
    }

    const percentageResult = this.validatePercentage();
    if (!percentageResult.valid) {
      errors = errors.concat(percentageResult.errors);
    }

    // combined validation results of individual fields and percentage
    return {
      valid: errors.length === 0,
      errors: errors.length > 0 ? errors : undefined,
    };
  }

  storeOwnerInputRef(name, el) {
    if (el) {
      this.ownerInputs.set(name, el);
    } else {
      this.ownerInputs.delete(name);
    }
  }

  /**
   * Generate JSX fragment containing the owner selector and percent input for a given owner, index, and owner type.
   *
   * @param {object} owner The currently selected owner for this form group.
   * @param {number} index The index of the owner, used in event handling to update state.
   * @param {string} ownerType Whether the owner is primary or secondary.
   *
   * @returns {*} JSX fragment.
   */
  renderOwnerFormGroup(owner, index, ownerType) {
    const { people, validator } = this.props;
    const { value } = this.state;

    const allOwners = getAllOwners(value);
    const validRelatives = people.filter((person) =>
      isRelativeValidSelectorOption(person, owner, allOwners)
    );

    return (
      <CSSTransition
        key={`ownerItem${index}`}
        classNames="transition-form-group--inline"
        timeout={250}
      >
        <div>
          <div className="pc-form-group">
            <div className="pc-layout pc-layout--top">
              <div className="pc-layout__item pc-u-2/3">
                <PersonSelect
                  name={`${ownerType}.${index}.personId`}
                  people={validRelatives}
                  value={owner.personId}
                  onChange={this.handleOwnerChange}
                  ref={this.storeOwnerInputRef}
                  validator={validator.properties.personId}
                  isAddNewPersonEnabled={true}
                  menuPortalTarget={document.body}
                />
              </div>
              <div className="pc-layout__item pc-u-1/3">
                <div className="pc-layout pc-layout--small">
                  <div className="pc-layout__item pc-u-3/4">
                    <Input
                      type="text"
                      name={`${ownerType}.${index}.percentage`}
                      ref={(el) =>
                        this.storeOwnerInputRef(
                          `${ownerType}.${index}.percentage`,
                          el
                        )
                      }
                      placeholder="%"
                      maxLength={3}
                      className={`qa-owner-${ownerType.toLowerCase()}-input-${index} js-owner-${ownerType.toLowerCase()}-input-${index}`}
                      formatter={PositiveCurrencyFormatter}
                      value={owner.percentage}
                      sizeVariation="full"
                      onChange={this.handlePercentageChange}
                      onBlur={this.handlePercentageBlur}
                      validator={validator.properties.percentage}
                    />
                  </div>
                  {ownerType === "SECONDARY" && (
                    <div className="pc-layout__item pc-u-1/4">
                      <button
                        type="button"
                        className={`pc-btn pc-btn--stripped qa-owner-secondary-remove-btn-${index} js-owner-secondary-remove-btn-${index}`}
                        onClick={this.handleOwnerRemove}
                        data-index={index}
                        title="Remove owner"
                      >
                        ×
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }

  renderPrimaryOwnerFormGroup(owner, index) {
    return this.renderOwnerFormGroup(owner, index, "PRIMARY");
  }

  renderSecondaryOwnerFormGroup(owner, index) {
    return this.renderOwnerFormGroup(owner, index, "SECONDARY");
  }

  render() {
    const { value, errors } = this.state;

    const allOwners = getAllOwners(value);
    const error = errors && errors[0];

    return (
      <div>
        <div>
          <h2 className="pc-delta heading--emphasized">Account Owners</h2>
          <CSSTransition
            in={Boolean(error)}
            unmountOnExit={true}
            classNames="transition-height"
            timeout={250}
          >
            <div className="edit-account-modal__owner-section-error">
              <label className="pc-help-block pc-help-block--small pc-help-block--error">
                {error}
              </label>
            </div>
          </CSSTransition>
          <div>
            <TransitionGroup>
              {value.PRIMARY.map(this.renderPrimaryOwnerFormGroup)}
            </TransitionGroup>
          </div>
          <div>
            <TransitionGroup>
              {value.SECONDARY.map(this.renderSecondaryOwnerFormGroup)}
            </TransitionGroup>
          </div>
          <div className="pc-form-group">
            <div className="pc-layout pc-layout">
              <div className="pc-layout__item pc-u-2/3">
                <button
                  className="qa-add-owner js-add-owner pc-btn pc-btn--tiny"
                  type="button"
                  onClick={this.handleOwnerAdd}
                >
                  Add New Owner
                </button>
              </div>
              <div className="pc-layout__item pc-u-1/3">
                <span className="pc-help-block--inline-block pc-help-block--small js-percentage-over-under-text">
                  {getOverUnderText(allOwners, false)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Owners.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  people: PropTypes.array.isRequired,
  validator: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onAddPersonClick: PropTypes.func.isRequired,
};

Owners.defaultProps = {
  value: { PRIMARY: [], SECONDARY: [] },
  validator: { properties: {} },
};
