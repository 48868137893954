import React from "react";

const Education = (props) => (
  <svg
    width={848}
    height={411}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ background: "#fff" }}
    {...props}
  >
    <title>ALL-Education</title>
    <defs>
      <path id="education-b" d="M0 0h217v58H0z" />
      <filter
        x="-3%"
        y="-9.5%"
        width="106%"
        height="122.4%"
        filterUnits="objectBoundingBox"
        id="education-a"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0.2 0 0 0 0 0.243137255 0 0 0 0 0.282352941 0 0 0 0.4 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          d="M418.234 36.904l.001-.08a6.848 6.848 0 0 1 6.728 6.966l-1.12 64.19-6.848-.12 1.031-59.055a4.281 4.281 0 0 0-3.497-4.283c-42.698 1.428-81.763 17.658-112.12 43.789l-4.977-5.779c32.565-28.026 74.763-45.1 120.802-45.628z"
          fill="#FFC708"
        />
        <path
          d="M549.974 89.158l.057-.057a6.848 6.848 0 0 1 0 9.684l-45.397 45.396-4.842-4.842 41.765-41.764c1.5-1.501 1.654-3.84.46-5.511-29.295-26.455-65.137-41.667-101.96-45.635l.804-7.582c39.596 4.271 78.099 21.041 109.113 50.31z"
          fill="#008A00"
        />
        <path
          d="M609.248 225.163l.09.002a6.848 6.848 0 0 1-7.025 6.666l-64.178-1.68.179-6.846 59.044 1.546a4.281 4.281 0 0 0 4.291-3.353c-1.19-42.492-17.064-81.488-42.827-111.934l5.825-4.922c27.617 32.645 44.304 74.737 44.6 120.52z"
          fill="#693277"
        />
        <path
          d="M550.878 362.984l.054.06a6.848 6.848 0 0 1-9.671-.507l-42.959-47.71 5.09-4.582 39.521 43.893a4.281 4.281 0 0 0 5.48.748c30.63-30.541 48.189-69.64 52.293-109.982l7.584.798c-4.41 43.248-23.685 85.119-57.392 117.282z"
          fill="#B3CBEA"
        />
        <path
          d="M352.558 303.098c-18.858-16.445-31.445-39.9-33.7-66.303l47.69-5.04c.961 14.182 7.68 26.786 17.837 35.491l-31.827 35.852z"
          fill="#FF9E15"
        />
        <path
          d="M318.245 230.356c-1.338-28.02 9.164-56.302 30.804-76.815l33.793 34.035c-11.798 10.732-17.497 25.827-16.7 40.748l-47.897 2.032z"
          fill="#999EA3"
        />
        <path
          d="M353.628 148.9c18.682-16.324 43.44-25.688 70.046-24.413l-1.257 47.94c-14.145-.953-27.344 3.918-37.244 12.567L353.628 148.9z"
          fill="#FFC708"
        />
        <path
          d="M431.619 124.644c22.756 2.346 44.755 12.514 61.613 30.197l-34.152 33.656c-8.8-9.503-20.422-14.951-32.449-16.177l4.988-47.676z"
          fill="#008A00"
        />
        <path
          d="M498.537 160.131c15.957 18.513 25.127 42.885 23.993 69.073l-47.918-1.512c.743-13.872-4.108-26.792-12.61-36.524l36.535-31.037z"
          fill="#693277"
        />
        <path
          d="M522.449 236.41c-2.25 24.996-13.916 49.117-34.389 66.594l-31.484-36.153c10.967-9.2 17.14-22.054 18.18-35.334l47.693 4.894z"
          fill="#B3CBEA"
        />
        <path
          d="M290.122 362.99l-.054.06a6.848 6.848 0 0 0 9.672-.507l42.958-47.71-5.09-4.582-39.52 43.893a4.281 4.281 0 0 1-5.48.748c-30.493-30.404-48.032-69.29-52.238-109.44l-7.582.816c4.518 43.05 23.773 84.698 57.334 116.722z"
          fill="#FF9E15"
        />
        <path
          d="M283.157 97.032l-.056-.056a6.848 6.848 0 0 1 9.684 0l45.396 45.396-4.842 4.842-41.764-41.764a4.281 4.281 0 0 0-5.511-.46c-32.788 36.307-48.305 82.67-46.552 128.497l-7.62.314c-1.883-48.983 15.206-98.559 51.265-136.769z"
          fill="#C3C5C8"
        />
      </g>
      <g fillRule="nonzero">
        <g fill="#FFF" transform="translate(479 248)">
          <path d="M2.476 22.524h1.238V25H2.476zM8.667 22.524h1.238V25H8.667z" />
          <path d="M2.476 22.524h7.429v1.238H2.476zM4.952 13.857l3.715 3.714v3.715h-.62V18.19l-.495-.619L4.705 14.6s-1.238-.99-1.857-.495c-.62.495 2.723 3.466 2.723 3.466l-.619.62-2.476-2.477s-1.238-1.362.124-2.228c1.114-.867 2.352.371 2.352.371z" />
          <path d="M.669 16.313l4.212 4.212-.473.473S.318 17.08 0 16.333V8.905h.669v7.408zM2.476 8.905h.67v3.714h-.67zM0 8.905a1.555 1.555 0 1 1 3.11 0h-.668a.887.887 0 1 0-1.773 0H0z" />
          <g>
            <path d="M22.286 22.524h-1.238V25h1.238zM16.095 22.524h-1.238V25h1.238z" />
            <path d="M22.286 22.524h-7.43v1.238h7.43zM19.81 13.857l-3.715 3.714v3.715h.62V18.19l.495-.619 2.847-2.971s1.238-.99 1.857-.495c.62.495-2.724 3.466-2.724 3.466l.62.62 2.476-2.477s1.238-1.362-.124-2.228c-1.114-.867-2.352.371-2.352.371z" />
            <path d="M24.093 16.313l-4.213 4.212.473.473s4.09-3.918 4.409-4.665V8.905h-.669v7.408zM22.286 8.905h-.67v3.714h.67zM24.762 8.905a1.555 1.555 0 1 0-3.111 0h.669a.887.887 0 1 1 1.773 0h.669z" />
          </g>
          <circle cx={12.381} cy={7.667} r={7.457} />
        </g>
        <path
          d="M494.826 258.193c0 .644-.259 1.162-.777 1.554-.518.39-1.243.625-2.175.702v1.247h-.926v-1.223c-1.099-.02-2.062-.194-2.891-.523v-1.605c.392.174.864.328 1.415.462a8.336 8.336 0 0 0 1.476.237v-1.885l-.452-.158c-.892-.316-1.524-.66-1.895-1.03-.372-.372-.558-.83-.558-1.378 0-.588.256-1.072.767-1.45.511-.38 1.224-.61 2.138-.69v-.931h.926v.906c1.031.04 1.964.227 2.797.56l-.635 1.422a7.975 7.975 0 0 0-2.162-.474v1.794c.878.304 1.503.568 1.875.79.371.223.644.469.817.736.173.268.26.58.26.937zm-4.933-3.748c0 .15.061.273.184.37.122.096.32.196.593.298v-1.197c-.518.064-.777.24-.777.53zm3.286 4.29c0-.135-.069-.249-.205-.342a2.892 2.892 0 0 0-.614-.288v1.197c.546-.074.819-.263.819-.567z"
          fill="#B3CBEA"
        />
      </g>
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M483 200.444h4.333l2.89-3.61 2.888 6.5 2.889-7.945L498.889 212l2.889-26 2.889 14.444H509"
      />
      <g transform="translate(379 133)" fill="#FFF">
        <ellipse fillRule="nonzero" cx={7.169} cy={17.441} rx={6.5} ry={2} />
        <path
          d="M1.245 19.824c.98.744 3.352 1.27 6.124 1.27s5.144-.526 6.124-1.27c.31.235.48.492.48.762 0 1.122-2.957 2.032-6.604 2.032-3.647 0-6.604-.91-6.604-2.032 0-.27.17-.527.48-.762zM1.245 22.882c.98.745 3.352 1.27 6.124 1.27s5.144-.525 6.124-1.27c.31.236.48.493.48.762 0 1.123-2.957 2.032-6.604 2.032-3.647 0-6.604-.91-6.604-2.032 0-.27.17-.526.48-.762zM1.245 25.941c.98.745 3.352 1.27 6.124 1.27s5.144-.525 6.124-1.27c.31.236.48.493.48.762 0 1.122-2.957 2.032-6.604 2.032-3.647 0-6.604-.91-6.604-2.032 0-.27.17-.526.48-.762z"
          fillRule="nonzero"
        />
        <text
          transform="translate(13)"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={15}
          fontWeight="bold"
        >
          <tspan x={0} y={16.647}>
            %
          </tspan>
        </text>
      </g>
      <path
        d="M453.2 163.478l2.73-10.191-4.668-1.25c.883-3.296 3.151-5.552 6.19-4.739 3.063.823 3.872 3.903 2.99 7.198l-3.747-1.004 9.865 2.643c.883-3.295-.078-6.416-9.103-8.835 5.157 1.383 6.927 4.72 6.044 8.016l-6.806-1.824-2.7 10.078c5.061.638 10.005 2.18 10.005 2.859 0 .867-4.925 1.571-11 1.571s-11-.704-11-1.571c0-.868 1.782-3.143 7.857-3.143 1.07 0 2.203.07 3.342.192zm2.73-10.191l-10.787-2.89c.883-3.296 3.276-5.518 12.3-3.1-5.157-1.381-8.358.624-9.24 3.92l7.727 2.07z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <g transform="translate(336.263 186.47)" fill="#FFF" fillRule="nonzero">
        <path d="M1.171 12.627h1.562l.39 4.736H.782zM1.171 8.681h1.562v3.157H1.171z" />
        <ellipse cx={1.952} cy={12.627} rx={1.562} ry={1.578} />
        <path d="M0 9.47l14.058-7.892L0 9.47zM13.067 15.669l-.571.115-.572-.115c-.405-1.744-1.953-3.042-3.802-3.042-.679 0-1.318.176-1.874.484v-1.43L0 8.838 12.496.79 24.99 8.84l-6.247 2.841v1.43a3.856 3.856 0 0 0-1.875-.483c-1.848 0-3.397 1.298-3.802 3.042zM7.497 8.68v1.24c1.667-.826 3.333-1.24 4.999-1.24 1.666 0 3.332.414 4.998 1.24v-1.24c-1.666-.827-3.332-1.24-4.998-1.24-1.666 0-3.332.413-4.999 1.24z" />
      </g>
      <g fill="#FFF" fillRule="nonzero">
        <path d="M344.584 259.832l-.493-1.786.796-.259s.891.338 1.756.819a11.164 11.164 0 0 1 4.434-.932c4.379 0 7.095 1.526 7.095 5.305 0 1.496-.399 2.737-1.34 3.641l-.499 2.261-1.208 2.214h-1.358l-.205-1.102v-1.408s-.176-.203-.354-.45c-.638.09-1.347.136-2.13.136-.22 0-.588-.034-1.053-.106l-.86 1.086-.745 1.844h-1.384l.077-1.517s-.32-.61-.402-1.01c-.05-.25-.302-.92-.484-1.389-.623-.197-1.792-.215-2.086-.676-.165-.26-.948-.263-1.128-.489-.18-.225-.129-.306-.129-.306-.022-.014.374-.19.684-.466.309-.276.575-.533.493-.528-.069.005-.38.32-.99.616s-1.287.46-1.36.435a.48.48 0 0 1-.201-.125c-.774-.834-1.01-2.565-1.01-3.129 0-.159.221-.38.442-.346.22.034.517.011.769-.027.589-.09 1.133-.263 1.224-.42.4-.688.955-1.329 1.649-1.886zm3.635-.087v.444c0 .042.037.077.082.06 0 0 1.372-.527 2.826-.527s2.76.52 2.76.52c.061.023.097-.003.097-.053v-.444a.266.266 0 0 0-.193-.247s-1.263-.333-2.69-.333c-1.426 0-2.689.333-2.689.333a.261.261 0 0 0-.193.247z" />
        <path d="M357.416 262.253c.905 0 1.663-.322 1.861-1.053.367.252.594.607.594 1.001 0 .763-.855 1.382-1.908 1.382-.353 0-.963-.19-.963-.19l.205-1.192s.117.052.211.052z" />
        <path
          d="M350.698 256.732c-1.546 0-2.798-1.277-2.798-2.85 0-1.574 1.252-2.85 2.798-2.85 1.545 0 2.797 1.276 2.797 2.85 0 1.573-1.252 2.85-2.797 2.85zm0-.659c1.186 0 2.148-.98 2.148-2.191 0-1.211-.962-2.192-2.148-2.192-1.186 0-2.148.98-2.148 2.192 0 1.21.962 2.191 2.148 2.191z"
          stroke="#FFF"
          strokeWidth={0.2}
        />
        <path fillOpacity={0} d="M338 249h24v24h-24z" />
      </g>
      <g>
        <path
          d="M239.4 233.4L421 226 293.893 97.938a8 8 0 0 0-11.68.347c-13.989 15.88-24.64 33.294-31.955 52.242-8.294 21.482-11.913 49.106-10.858 82.873z"
          fill="#C3C5C8"
          fillRule="nonzero"
          opacity={0.2}
        />
        <g
          opacity={0.5}
          fill="#000000"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={12}
          fontWeight="bold"
        >
          <text transform="translate(443 69)">
            <tspan x={0} y={13}>
              Retirement
            </tspan>
          </text>
          <text transform="translate(443 69)">
            <tspan x={0} y={35}>
              Plan
            </tspan>
          </text>
        </g>
        <text
          transform="translate(521 280)"
          opacity={0.5}
          fill="#000000"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={12}
          fontWeight="bold"
        >
          <tspan x={0.445} y={13}>
            Insurance
          </tspan>
        </text>
        <text
          transform="translate(511 155)"
          opacity={0.5}
          fill="#000000"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={12}
          fontWeight="bold"
        >
          <tspan x={0} y={13}>
            Investments
          </tspan>
        </text>
        <g
          opacity={0.5}
          fill="#333E48"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={12}
          fontWeight="bold"
        >
          <text transform="translate(334 69)">
            <tspan x={0} y={13}>
              Taxes and
            </tspan>
          </text>
          <text transform="translate(334 69)">
            <tspan x={0} y={35}>
              Fees
            </tspan>
          </text>
        </g>
        <g
          fill="#333E48"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={12}
          fontWeight="bold"
        >
          <text transform="translate(258 155)">
            <tspan x={0} y={13}>
              Education
            </tspan>
          </text>
          <text transform="translate(258 155)">
            <tspan x={0} y={35}>
              Savings
            </tspan>
          </text>
        </g>
        <text
          transform="translate(266 280)"
          opacity={0.5}
          fill="#333E48"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={12}
          fontWeight="bold"
        >
          <tspan x={0.033} y={13}>
            401K Plan
          </tspan>
        </text>
      </g>
      <path d="M0 0h848v411H0z" />
      <g>
        <g transform="translate(15 110)">
          <use
            fill="#000"
            filter="url(#education-a)"
            xlinkHref="#education-b"
          />
          <use fill="#407CCA" xlinkHref="#education-b" />
        </g>
        <path fill="#407CCA" d="M238 156l-6-6v12z" />
        <text
          fontFamily="OpenSans-Semibold, Open Sans"
          fontSize={12}
          fontWeight={500}
          fill="#FFF"
          transform="translate(15 110)"
        >
          <tspan x={12} y={25}>
            What is the best way to save for
          </tspan>
          <tspan x={12} y={42}>
            educational expenses?
          </tspan>
        </text>
      </g>
      <g transform="translate(323 169)">
        <circle
          fill="#FFF"
          fillRule="nonzero"
          cx={97.748}
          cy={56.748}
          r={55.843}
        />
        <path stroke="#407CCA" d="M75.5 115.5h42v33h-42z" />
        <path
          fill="#407CCA"
          fillRule="nonzero"
          d="M91 120h15v3H91zM91 128.5h15v3H91zM91 136h15v3H91zM88 131.491c0 1.014-.382 1.829-1.145 2.444-.764.615-1.833.983-3.208 1.104V137h-1.364v-1.922c-1.62-.032-3.041-.306-4.263-.823v-2.525c.578.275 1.273.517 2.087.727.813.21 1.538.335 2.176.373v-2.964l-.667-.25c-1.315-.496-2.246-1.037-2.794-1.62-.548-.584-.822-1.306-.822-2.166 0-.925.377-1.685 1.13-2.281.754-.596 1.805-.958 3.153-1.086V121h1.364v1.425c1.521.064 2.895.357 4.124.88l-.936 2.238a11.135 11.135 0 0 0-3.188-.746v2.821c1.295.478 2.217.893 2.764 1.243.548.351.95.737 1.206 1.158.255.42.383.911.383 1.472zm-7.273-5.893c0 .235.09.428.271.58.18.153.472.309.875.47v-1.883c-.764.101-1.146.379-1.146.833zm4.844 6.745c0-.211-.1-.39-.302-.536-.201-.147-.503-.298-.905-.454v1.882c.805-.116 1.207-.413 1.207-.892zM108.449 156.438l-9.725 7.862-6.14-5.493a.594.594 0 0 0-.838 0l-11.754 7.961a.59.59 0 0 0 0 .836c.231.23.606.23.838 0l11.335-7.543 6.14 5.493c.232.23.607.23.839 0l10.068-8.207 1.936 2.308 3.158-7.31-7.747 1.841 1.89 2.252zM68 171v-24.272L70.371 142h23.712l2.371 4.728H127V171H68zM120 111h2v30h-2z"
        />
        <path
          fill="#407CCA"
          fillRule="nonzero"
          d="M121 111v2H82v-2zM124 107h2v28h-2zM124 107v2H87v-2z"
        />
        <g
          fill="#407CCA"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={28}
          fontWeight="bold"
        >
          <text transform="translate(0 188)">
            <tspan x={0} y={30}>
              Financial Plan
            </tspan>
          </text>
        </g>
        <path
          stroke="#407CCA"
          strokeWidth={0.5}
          fill="#407CCA"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M109 122.857l2 2.143 4-4.286-.667-.714-3.333 3.571-1.333-1.428zM109 130.857l2 2.143 4-4.286-.667-.714-3.333 3.571-1.333-1.428zM109 138.857l2 2.143 4-4.286-.667-.714-3.333 3.571-1.333-1.428z"
        />
      </g>
    </g>
  </svg>
);

export default Education;
