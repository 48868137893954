/* cSpell:disable */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Services from "services";
import EnrollmentServices from "enrollmentServices";
import { promisify } from "utils/service";
import { isEmpty, noop } from "underscore";
import { getDocumentEndpoint } from "empower/utils/enrollmentUtils";
import parseResponseErrors from "libs/pcap/utils/response";
import {
  DOCUMENT_ENDPOINT_PATHS,
  INPUT_FILE_TYPES,
} from "components/common/DocumentUploader/utils/constants";
import YodleeStatementFetcher from "../YodleeStatementFetcher/YodleeStatementFetcher";
import DocumentUploaderContainer from "components/common/DocumentUploader/DocumentUploaderContainer";
import DocumentUploaderHeader from "components/common/DocumentUploader/DocumentUploaderHeader";
import Message from "components/common/Message";
import WarningIcon from "svg-icons/Warning.svg";
import { subscribify } from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import _ from "underscore";

const YodleeStatementFetchContainer = ({
  requestParams,
  getAccounts,
  fetchStatements,
  watchAccounts,
  removeStatement,
  onSelectButtonClick,
  onFileUpload,
  onFileUploaded,
  onFileUploadError,
  statementFetchCopy,
  isYodleeAllowed,
  lastStatement,
}) => {
  const { userAccountId } = requestParams;
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState();
  const [statement, setStatement] = React.useState();
  const [document, setDocument] = React.useState({});
  const [showDocumentUpload, setShowDocumentUpload] = React.useState(false);
  const [yodleeFetchFailed, setYodleeFetchFailed] = React.useState(false);

  const subscriptionTimerRef = React.useRef();

  const onFileSelect = (file, callback) => {
    setStatement(null);
    callback(file);
  };

  const handleRefreshTimeout = useCallback((unwatchSubscription) => {
    const fetchDurationTimer = 60000;
    const refreshStatementTimeoutCallback = () => {
      unwatchSubscription();
      setLoading(false);
      setShowDocumentUpload(true);
      setYodleeFetchFailed(true);
    };
    subscriptionTimerRef.current = setTimeout(
      refreshStatementTimeoutCallback,
      fetchDurationTimer
    );
  }, []);

  const handleDocumentUploadedCallback = useCallback(
    (doc) => {
      let statementArray = [];
      if (doc) {
        statementArray = [
          {
            complete: true,
            document: doc,
          },
        ];
      }
      onFileUploaded(undefined, statementArray);
    },
    [onFileUploaded]
  );

  React.useEffect(() => {
    if (!isEmpty(lastStatement)) {
      const doc = {
        ...lastStatement,
        name: lastStatement.documentTitle,
        type: lastStatement.documentExtension,
        url: getDocumentEndpoint(
          `servlet/edocs/getEdocsById?edocumentId=${lastStatement.id}`
        ),
      };
      setStatement(doc);
      setLoading(false);
      handleDocumentUploadedCallback(doc);
    }
  }, [lastStatement]);

  const handleDocumentFound = useCallback(
    (statement) => {
      const doc = {
        ...statement,
        name: statement.documentTitle,
        type: statement.documentExtension,
        url: getDocumentEndpoint(
          `servlet/edocs/getEdocsById?edocumentId=${statement.id}`
        ),
      };
      setDocument(doc);
      setLoading(false);
      handleDocumentUploadedCallback(doc);
    },
    [handleDocumentUploadedCallback]
  );

  const handleStatementFetchStatus = useCallback(
    (response, unwatchSubscription) => {
      const account = response.accounts[0];
      const { iconType } = account.nextActionStatement;
      if (iconType === "ERROR") {
        setErrors([account?.nextActionStatement?.nextActionMessage]);
        setShowDocumentUpload(true);
        setLoading(false);
        unwatchSubscription();
      } else if (iconType === "SUCCESS") {
        const statement = _.last(account.accountStatements);
        if (statement) {
          handleDocumentFound(statement);
        } else {
          setShowDocumentUpload(true);
          setYodleeFetchFailed(true);
        }
        unwatchSubscription();
      }
    },
    [handleDocumentFound]
  );

  const handleStatementFetchError = (err, response) => {
    const errors = parseResponseErrors(err, response);
    setErrors(errors);
    setShowDocumentUpload(true);
    setLoading(false);
  };

  const onRemoveStatementAccount = (e, doc) => {
    e.preventDefault();
    removeStatement({ statementId: doc?.id })
      .then(() => {
        setErrors(null);
        setDocument({});
        handleDocumentUploadedCallback();
        setShowDocumentUpload(true);
      })
      .catch((err) => setErrors([err]));
  };

  const onCancelYodleeStatement = (e) => {
    e.preventDefault();
    setErrors(null);
    setShowDocumentUpload(true);
    setLoading(false);
  };

  const handleFileUpload = (doc, data) => {
    setErrors(null);
    onFileUpload(doc, data);
  };

  const handleFileUploaded = (doc, data) => {
    const recentDoc = data?.[0]?.document;
    if (recentDoc) {
      const mapDoc = {
        ...recentDoc,
        name: recentDoc.documentTitle,
        type: recentDoc.extensionType,
        id: recentDoc.edocumentId,
        url: getDocumentEndpoint(
          `servlet/edocs/getEdocsById?edocumentId=${recentDoc.edocumentId}`
        ),
      };

      setDocument(mapDoc);
      setLoading(false);
      setShowDocumentUpload(true);
      onFileUploaded(doc, data);
    }
  };

  const uploadAPIEndpoint = getDocumentEndpoint(
    DOCUMENT_ENDPOINT_PATHS.EMPOWER_DOCUMENT
  );

  const retailRequestParams = {
    ...requestParams,
    documentType: "RETAIL_STATEMENT",
  };

  const UPLOAD_STATUS_ICON_SET = {
    BODY_ICON: "#icon__file-upload",
    SUCCESS: "#icon__success-upload",
    FAIL: "#icon__fail-upload",
  };

  React.useEffect(() => {
    if (!isYodleeAllowed) {
      setShowDocumentUpload(true);
      return;
    }

    let getAccountsSubscription;
    let getAccountsCancelablePromise;
    setLoading(true);

    const unwatchSubscription = () => {
      getAccountsSubscription?.unwatch?.();
      getAccountsSubscription?.off?.("change");
      if (subscriptionTimerRef.current) {
        clearTimeout(subscriptionTimerRef.current);
        subscriptionTimerRef.current = undefined;
      }
    };

    const getAccountInputs = {
      userAccountIds: JSON.stringify([userAccountId]),
      includeOwners: "true",
      includeStatements: "true",
    };

    const waitForStatementFetch = () => {
      const handleStatementFetchSuccess = (response) =>
        handleStatementFetchStatus(response, unwatchSubscription);

      getAccountsSubscription = watchAccounts(getAccountInputs);
      getAccountsSubscription.on("change", handleStatementFetchSuccess);
      getAccountsCancelablePromise = makeCancelablePromise(
        getAccountsSubscription.promise
      );
      getAccountsCancelablePromise.promise
        .then(handleStatementFetchSuccess)
        .catch(handleStatementFetchError);
    };

    const initialGetAccountsPromise = makeCancelablePromise(
      getAccounts(getAccountInputs)
    );
    initialGetAccountsPromise.promise
      .then((response) => {
        const account = response.accounts[0];
        const statement = _.last(account.accountStatements);
        if (statement) {
          handleDocumentFound(statement);
        } else {
          if (account.nextActionStatement.action !== "WAIT") {
            fetchStatements({ userAccountIds: [userAccountId] });
          }
          waitForStatementFetch();
          handleRefreshTimeout(unwatchSubscription);
        }
      })
      .catch((err, response) => {
        handleStatementFetchError(err, response);
      });

    return () => {
      initialGetAccountsPromise?.cancel?.();
      getAccountsCancelablePromise?.cancel?.();
      unwatchSubscription();
    };
  }, [
    fetchStatements,
    getAccounts,
    handleDocumentFound,
    handleDocumentUploadedCallback,
    handleRefreshTimeout,
    handleStatementFetchStatus,
    isYodleeAllowed,
    userAccountId,
    watchAccounts,
  ]);

  const documentTypesList = INPUT_FILE_TYPES.ALL.split(",");
  let fileStatement = null;
  if (!isEmpty(statement)) {
    fileStatement = { file: statement, complete: true };
  }

  return (
    <div className="yodlee-statement-fetch">
      <div className="yodlee-statement-fetch__container">
        <Message className="pc-u-mb" severity="error" messages={errors} />
        {yodleeFetchFailed && (
          <p>
            <WarningIcon className="yodlee-statement-fetch__warning-icon pc-u-mr-" />
            We were unable to retrieve your statement and need your help.
          </p>
        )}
        {statementFetchCopy && (
          <div className="pc-layout pc-u-pb-- pc-u-mt">
            <DocumentUploaderHeader
              documentTypesList={documentTypesList}
              maxFileSize={"5MB"}
              headerCopy={statementFetchCopy}
            />
          </div>
        )}
        <div className="account-details-form__file-upload">
          {showDocumentUpload ? (
            <DocumentUploaderContainer
              multiple={false}
              files={fileStatement ? [fileStatement] : undefined}
              onSelectButtonClick={onSelectButtonClick}
              onFileUpload={handleFileUpload}
              onFileUploaded={handleFileUploaded}
              onFileUploadError={onFileUploadError}
              uploadAPIEndpoint={uploadAPIEndpoint}
              requestParams={retailRequestParams}
              uploadIconSet={UPLOAD_STATUS_ICON_SET}
              onFileSelect={onFileSelect}
            />
          ) : (
            <YodleeStatementFetcher
              document={document}
              loading={loading}
              onCancel={onCancelYodleeStatement}
              onRemove={onRemoveStatementAccount}
            />
          )}
        </div>
      </div>
    </div>
  );
};

YodleeStatementFetchContainer.propTypes = {
  requestParams: PropTypes.object.isRequired,
  getAccounts: PropTypes.func,
  watchAccounts: PropTypes.func,
  fetchStatements: PropTypes.func,
  removeStatement: PropTypes.func,
  onSelectButtonClick: PropTypes.func,
  onFileUpload: PropTypes.func,
  onFileUploaded: PropTypes.func,
  onFileUploadError: PropTypes.func,
  statementFetchCopy: PropTypes.string,
  isYodleeAllowed: PropTypes.bool,
  lastStatement: PropTypes.object,
};

YodleeStatementFetchContainer.defaultProps = {
  getAccounts: promisify(Services.Accounts.get),
  watchAccounts: subscribify(Services.Accounts.get),
  fetchStatements: promisify(
    Services.Accounts.refreshAccountsWithExtendedDetails
  ),
  removeStatement: promisify(EnrollmentServices.Statements.delete),
  onSelectButtonClick: noop,
  onFileUpload: noop,
  onFileUploaded: noop,
  onFileUploadError: noop,
  statementFetchCopy: undefined,
  isYodleeAllowed: false,
  lastStatement: {},
};

export default YodleeStatementFetchContainer;
