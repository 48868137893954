import React from "react";
import PropTypes from "prop-types";
import RemoveAccountModal from "components/common/RemoveAccount/RemoveAccountModal";
import { accountUpdateCompleted } from "empower/utils/liat/otherAssetsUtils";

export default class RemoveAccountContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      loading: false,
    };

    this.handleRemoveAccount = this.handleRemoveAccount.bind(this);
  }

  handleRemoveAccount() {
    this.setState({ loading: true });
    this.props
      .service()
      .then((response) => {
        this.setState({
          loading: false,
        });
        this.props.onAccountRemoved();
        if (IS_EMPOWER) {
          if (response.spData && response.spData.accounts[0]) {
            const { productType } = response.spData.accounts[0];
            if (productType === "BANK") {
              accountUpdateCompleted("bankaccountremoved");
            } else if (productType === "CREDIT_CARD") {
              accountUpdateCompleted("creditcardaccountremoved");
            }
          }
        }
      })
      .catch((errors) => {
        this.setState({
          errors,
          loading: false,
        });
      });
  }

  render() {
    const { loading, errors } = this.state;
    const removeAccountModalProps = Object.assign({}, this.props, {
      loading,
      errors,
      onRemoveAccount: this.handleRemoveAccount,
    });

    return <RemoveAccountModal {...removeAccountModalProps} />;
  }
}

RemoveAccountContainer.propTypes = {
  service: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccountRemoved: PropTypes.func.isRequired,
  componentName: PropTypes.string.isRequired,
};
