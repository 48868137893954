var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<img aria-hidden=\"true\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"logoPath") || (depth0 != null ? lookupProperty(depth0,"logoPath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"logoPath","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":44}}}) : helper)))
    + "\" />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<h2>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":6,"column":7},"end":{"line":6,"column":15}}}) : helper)))
    + "</h2>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "			<div class=\"table-cell last\"><a href=\"#\" class=\"pc-btn pc-btn--primary pc-btn--full submitCustomAccount active submitForm qa-save-btn\">Done</a></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"logoShell\">\n	<div class=\"logo\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"logoPath") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":7,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n<div class=\"scroller\">\n	<form class=\"customAccountsCreation qa-custom-account-creation\" autocomplete=\"off\">\n		<h2 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":28}}}) : helper)))
    + "</h2>\n		<input type=\"hidden\" name=\"productId\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"productId") || (depth0 != null ? lookupProperty(depth0,"productId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"productId","hash":{},"data":data,"loc":{"start":{"line":13,"column":47},"end":{"line":13,"column":60}}}) : helper)))
    + "\" />\n		<input type=\"hidden\" name=\"productName\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":49},"end":{"line":14,"column":57}}}) : helper)))
    + "\" />\n    <div class=\"js-account-credentials\">\n    </div>\n		<div class=\"formElement buttonContainer buttonContainer--full actions twoColumns\">\n        <div class=\"table-cell first\"><a href=\"#\" class=\"pc-btn pc-btn--full back\">Back</a></div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmulatorMode") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "		</div>\n		<input type=\"submit\" class=\"offscreen\" />\n	</form>\n</div>\n";
},"useData":true});