import React from "react";
import PropTypes from "prop-types";
import ChartReact from "libs/pcap/chart/chart--react";
import { formatCurrency } from "libs/pcap/utils/format";
import { scaleLinear, axisBottom, extent, select, timeFormat } from "d3";
import moment from "moment";
import { first, last, noop } from "underscore";

const Y_DOMAIN_PADDING_FACTOR = 0.02;
const xScale = scaleLinear();
const xAxis = axisBottom(xScale);

function buildYDomain(debtSeries) {
  const minDebt = Math.min(...debtSeries.map((d) => d.totalLiabilities));
  const maxDebt = Math.max(...debtSeries.map((d) => d.totalLiabilities));

  if (!minDebt && !maxDebt) {
    return undefined;
  }

  return extent([
    Math.max(0, minDebt - Y_DOMAIN_PADDING_FACTOR * minDebt),
    (1 + Y_DOMAIN_PADDING_FACTOR) * maxDebt,
  ]);
}

const DebtPaydownChart = (props) => {
  const {
    debtBalances,
    isZeroState,
    chartContainerClassName,
    chartClassName,
    rightPadding,
    tabIndex,
    onClick,
    onKeyDown,
    ariaLabel,
  } = props;
  xAxis
    .tickValues([
      moment(first(debtBalances).date),
      moment(last(debtBalances).date),
    ])
    .tickFormat(function (d, i) {
      select(this).style("text-anchor", i === 0 ? "start" : "end"); // eslint-disable-line no-invalid-this
      return d.format("M/D/YYYY");
    });

  const yDomain = buildYDomain(props.debtBalances);
  let yAxisMargin = { left: 0 };
  if (IS_EMPOWER) {
    yAxisMargin.right = rightPadding;
  }

  return (
    <div>
      <div className={chartContainerClassName}>
        <ChartReact
          className={`js-debt-paydown-chart ${chartClassName} ${
            isZeroState ? `debt-paydown__chart--zero-state` : ""
          }`}
          data={[debtBalances]}
          type="area"
          x={(d) => moment(d.date)}
          y={(d) => d.totalLiabilities}
          interpolate="monotone"
          showYGrid={false}
          margin={yAxisMargin}
          xAxis={xAxis}
          xScale={xScale}
          tooltip={
            isZeroState
              ? undefined
              : {
                  xFormat: timeFormat("%B %e"),
                  yFormat: formatCurrency,
                  className: "chart-tooltip--small",
                }
          }
          showYAxis={!isZeroState}
          yDomain={yDomain}
          ariaLabel={ariaLabel}
          tabIndex={tabIndex}
          onClick={onClick}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
};

DebtPaydownChart.propTypes = {
  debtBalances: PropTypes.array,
  isZeroState: PropTypes.bool,
  chartContainerClassName: PropTypes.string,
  chartClassName: PropTypes.string,
  rightPadding: PropTypes.number,
  ariaLabel: PropTypes.string,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
};
DebtPaydownChart.defaultProps = {
  debtBalances: [],
  isZeroState: false,
  chartContainerClassName: "debt-paydown__chart-container",
  chartClassName: "debt-paydown__chart",
  rightPadding: 40,
  ariaLabel: null,
  tabIndex: null,
  onClick: noop,
  onKeyDown: noop,
};

export default DebtPaydownChart;
