var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "aria-current=\"true\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "is-active qa-active";
},"5":function(container,depth0,helpers,partials,data) {
    return "pc-u-pt";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"moduleFrame appTemplate\">\n    <div class=\"nav-secondary l-spaced l-spaced--flush js-secondary-nav\">\n      <h1 class=\"nav-secondary__title qa-page-title\">Cash Flow</h1>\n      <nav>\n        <ul class=\"pc-layout pc-layout--auto pc-layout--small\">\n          <li class=\"pc-layout__item\">\n            <a class=\"cashflow pc-btn pc-btn--bare js-cash-flow-tabs qa-cash-flow-tab\" href=\"#/cash-flow\" aria-current=\"false\">Cash Flow</a>\n          </li>\n          <li class=\"pc-layout__item\">\n            <a "
    + ((stack1 = __default(require("./helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"incomeExpenseMode") : depth0),"==","income",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":15},"end":{"line":10,"column":90}}})) != null ? stack1 : "")
    + " class=\"income pc-btn pc-btn--bare js-cash-flow-tabs qa-income-tab "
    + ((stack1 = __default(require("./helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"incomeExpenseMode") : depth0),"==","income",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":157},"end":{"line":10,"column":232}}})) != null ? stack1 : "")
    + "\" href=\"#/cash-flow/income\">Income</a>\n          </li>\n          <li class=\"pc-layout__item\">\n            <a "
    + ((stack1 = __default(require("./helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"incomeExpenseMode") : depth0),"==","expense",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":15},"end":{"line":13,"column":91}}})) != null ? stack1 : "")
    + " class=\"expense pc-btn pc-btn--bare js-cash-flow-tabs qa-expense-tab "
    + ((stack1 = __default(require("./helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"incomeExpenseMode") : depth0),"==","expense",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":160},"end":{"line":13,"column":236}}})) != null ? stack1 : "")
    + "\" href=\"#/cash-flow/expense\">Expense</a>\n          </li>\n        </ul>\n      </nav>\n  </div>\n	<div class=\"frame\">\n		<div class=\"nav-secondary nav-secondary--feature-controls l-spaced l-spaced--flush js-feature-controls\">\n      <nav class=\"js-breadcrumb\"></nav>\n			<div class=\"pc-layout pc-layout--auto pc-layout--small\">\n        <div class=\"pc-layout__item\">\n          <div class=\"accountSelector\"></div>\n        </div>\n        <div class=\"pc-layout__item\">\n          <div class=\"dateSelector\"></div>\n        </div>\n			</div>\n		</div>\n		<div class=\"visualization offset\">\n			<div class=\"cash-flow__bar-chart__container--income-expense barSVG js-bar-chart-container\"></div>\n      <div class=\"legendDonut\">\n        <div class=\"donut\">\n          <div class=\"donutSVG\">\n          </div>\n          <table aria-live=\"assertive\" aria-atomic=\"true\">\n            <tr>\n              <td class=\"labelName\">\n\n              </td>\n            </tr>\n            <tr>\n              <td class=\"labelAmount expense qa-label-amount\">\n\n              </td>\n            </tr>\n          </table>\n        </div>\n        <table>\n          <tr>\n            <td class=\"legend\">\n\n            </td>\n          </tr>\n        </table>\n      </div>\n      <div class=\"cash-flow__line-chart__container cash-flow__line-chart__container--income-expense js-line-chart-container\"></div>\n		</div>\n    <div class=\"takeAways "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":26},"end":{"line":59,"column":57}}})) != null ? stack1 : "")
    + " qa-takeAways\">\n      <div class=\"firstTakeAway\" aria-live=\"assertive\" >\n      </div>\n      <div class=\"secondTakeAway\" aria-live=\"assertive\" >\n      </div>\n    </div>\n    <div class=\"gridFrame offset "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":33},"end":{"line":65,"column":64}}})) != null ? stack1 : "")
    + "\"></div>\n    <div id=\"cash-flow__info\" class=\"sr-only\" aria-live=\"assertive\"></div>\n	</div>\n</div>\n\n<div id=\"debugBarGraph\" style=\"display: none\">\n    <p>\n        <b>CatId</b>\n        <span class=\"catIds\"></span>\n    </p>\n    <p>\n        <b>Max Value</b>\n        <span class=\"mv\"></span>\n    </p>\n    <p>\n        <b>Merchant</b>\n        <span class=\"merchant\"></span>\n    </p>\n    <p>\n        <b>Emphasis</b>\n        <span class=\"emphasis\"></span>\n    </p>\n</div>\n";
},"useData":true});