import PropTypes from "prop-types";
import React from "react";
import mixpanel from "mixpanel";
import {
  getActionButtons,
  getEventData,
} from "components/opportunitiesSummary/InsightsUtils";

const MAX_MESSAGE_HEIGHT = 640;
const OVERFLOW_BUTTON_HEIGHT = 98;

export default class BasicInsight extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cutOff: false,
    };
  }

  componentDidMount() {
    if (this.messageContainer.clientHeight > MAX_MESSAGE_HEIGHT) {
      this.summaryContainer.classList.add("insight__summary--overflow");
      this.summaryContainer.style.maxHeight =
        MAX_MESSAGE_HEIGHT -
        this.headerContainer.clientHeight -
        OVERFLOW_BUTTON_HEIGHT +
        "px";
      this.setState({
        cutOff: true,
      });
    }
    mixpanel.trackEvent(
      `View Insight`,
      getEventData({ message: this.props.message })
    );

    if (this.props.isFirstRendered) {
      this.props.markAsImpressed(this.props.message.userMessageId);
    } else {
      this.props.markAsViewed(this.props.message.userMessageId);
    }
  }

  getActions(message) {
    const { markAsViewed } = this.props;
    const buttonClass = "insight__action";
    if (message.explanation) {
      return (
        <a href="#/notifications" className={buttonClass}>
          Read Full Message
        </a>
      );
    }

    if (this.state.cutOff) {
      return (
        <a
          onClick={() => {
            mixpanel.trackEvent(
              `Click Insight`,
              getEventData({ message, buttonLabel: "See More" })
            );
          }}
          href="#/notifications"
          className={buttonClass}
        >
          See More
        </a>
      );
    }

    return getActionButtons({ message, markAsViewed, buttonClass });
  }

  render() {
    const { message } = this.props;
    let summaryContent = message.explanation || message.summary;
    summaryContent = this.state.cutOff
      ? '<div class="insight__summary-overflow-fade"></div>' + summaryContent
      : summaryContent;

    return (
      <div
        ref={(el) => {
          this.messageContainer = el;
        }}
        className="insight__message"
      >
        <div
          ref={(el) => {
            this.headerContainer = el;
          }}
          className="insight__header qa-insight-header"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div
          ref={(el) => {
            this.summaryContainer = el;
          }}
          className="insight__summary"
          dangerouslySetInnerHTML={{ __html: summaryContent }}
        />
        <div className="insight__actions">{this.getActions(message)}</div>
      </div>
    );
  }
}

BasicInsight.propTypes = {
  message: PropTypes.object,
  markAsImpressed: PropTypes.func,
  markAsViewed: PropTypes.func,
  isFirstRendered: PropTypes.bool,
};

BasicInsight.defaultProps = {
  message: undefined,
  markAsImpressed: undefined,
  markAsViewed: undefined,
  isFirstRendered: false,
};
