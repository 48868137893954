import _ from "underscore";
import accounting from "accounting";
import moment from "moment";
import daca from "templates/helpers/dollarAndCentsAmount";

var Formats = {
  filters: {
    number: function (number, places) {
      if (arguments.length < 2) places = 2;
      return new Number(number).toFixed(places);
    },

    skip: function (transactions, skip) {
      return transactions.slice(skip);
    },

    pending: function (transactions) {
      var sets = _.reduce(
        _.compact(transactions),
        function (sets, trans) {
          if (trans.hasOwnProperty("pending")) {
            if (trans.pending) {
              sets.pending.push(trans);
            } else {
              sets.normal.push(trans);
            }
          } else if (trans.status == "pending") {
            sets.pending.push(trans);
          } else {
            sets.normal.push(trans);
          }
          return sets;
        },
        { pending: [], normal: [] }
      );

      return sets.pending.concat(sets.normal);
    },

    dollar_no_cents: function (amount, negative) {
      // 	function dollarAndCentsAmount(number, dash, includeCurrencySymbol, isNegative, noCents)
      return daca(amount, true, true, negative, true);
    },
    dollar: function (amount, negative) {
      return daca(amount, true, true, negative);
    },

    percent: function (amount) {
      return accounting.formatMoney(amount);
    },

    cut: function (value, max, tail, wordwise) {
      if (!max) max = 30;
      try {
        max = parseInt(max, 10);
        if (!max) return value;
        if (value.length <= max) return value;

        value = value.substr(0, max);
        if (wordwise) {
          var lastspace = value.lastIndexOf(" ");
          if (lastspace != -1) {
            value = value.substr(0, lastspace);
          }
        }

        return value + (tail || " …");
      } catch (e) {
        return value;
      }
    },
  },
};

export default Formats;
