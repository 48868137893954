export const DELEGATE_STATUS_MAP = {
  ACTIVE: "Active",
  EXPIRED: "Invite Expired",
  LOCKED: "Locked",
  REQUIRES_VERIFICATION: "Invited",
};
export const DELEGATE_STATUS_REQUIRES_VERIFICATION = "REQUIRES_VERIFICATION";
export const DELEGATE_STATUS_EXPIRED = "EXPIRED";
export const DELEGATE_STATUS_ACTIVE = "ACTIVE";
export const DELEGATE_STATUS_LOCKED = "LOCKED";
export const DELEGATE_STATUS_FRAUD_FREEZE = "FRAUD_FREEZE";
export const DELEGATE_ACTION_RESEND = "RESEND";
export const DELEGATE_ACTION_REMOVE = "REMOVE";

export const STATUS_PCAP_USER = [
  DELEGATE_STATUS_ACTIVE,
  DELEGATE_STATUS_LOCKED,
  DELEGATE_STATUS_FRAUD_FREEZE,
];
