import React from "react";
import PropTypes from "prop-types";
import { renderAccountField } from "../EditAccountUtils";

const CustomProductDetails = ({
  model,
  customProduct,
  onInputChange,
  storeInputRef,
}) => {
  return (
    <div>
      {customProduct.prompts &&
        customProduct.prompts.map((field, i) => {
          field.parts[0].value = model[field.parts[0].id];
          return renderAccountField(field, i, storeInputRef, onInputChange);
        })}
    </div>
  );
};

CustomProductDetails.propTypes = {
  model: PropTypes.object.isRequired,
  customProduct: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  storeInputRef: PropTypes.func.isRequired,
};

export default CustomProductDetails;
