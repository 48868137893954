import { hasMtrEligibleAccounts } from "utils/account";

export function showMakePlanOfficial(
  plan,
  accounts,
  isGMWB,
  isAdvisor,
  isEmpower
) {
  if (!plan) return false;

  const whatIf = plan.result.whatIf;
  const alternativeStrategy = whatIf?.alternativeStrategy;
  const alternativeStrategyScope = whatIf?.alternativeStrategyScope;
  const hasSimulatedRecession = Boolean(whatIf?.marketScenario);
  const isDraft = plan.planStatus === "DRAFT";

  const showAdvisorMakeOfficial =
    !plan.isMasterPlan &&
    !isDraft &&
    !alternativeStrategy &&
    !hasSimulatedRecession;

  if (isAdvisor && isEmpower) {
    return (
      showAdvisorMakeOfficial ||
      //alt strategy exists and is scoped to MA, and the user is non-GMWB and has unenrolled MTR accounts
      (!plan.isMasterPlan &&
        !isDraft &&
        !hasSimulatedRecession &&
        !isGMWB &&
        hasMtrEligibleAccounts(accounts) &&
        alternativeStrategy &&
        alternativeStrategyScope !== "ALL")
    );
  }

  if (isAdvisor) {
    return showAdvisorMakeOfficial;
  }

  return (
    //client created scenarios don't have a planStatus since there's no draft state
    !plan.planStatus &&
    !plan.isMasterPlan &&
    !alternativeStrategy &&
    !hasSimulatedRecession
  );
}
