import { isEmpty } from "underscore";
import { isFullyConsented } from "utils/pcb";

export default function (model, isEnabledCashOnboardingApiV2) {
  let enrollmentObj = Object.assign({}, model);

  if (enrollmentObj.isSubjectToBackupWithholding) {
    enrollmentObj.isSubjectToBackupWithholding =
      enrollmentObj.isSubjectToBackupWithholding === "Y";
  }

  if (!isEmpty(model.userConsentedTo)) {
    enrollmentObj.isAccountUmbConsented = isFullyConsented(
      model.userConsentedTo
    );
    delete enrollmentObj.userConsentedTo;
  }

  if (
    isEnabledCashOnboardingApiV2 === false &&
    model.accountUsageIntent &&
    model.accountUsageIntent.accountUsageGoal !== "OTHER"
  ) {
    enrollmentObj.accountUsageIntent = Object.assign(
      {},
      model.accountUsageIntent
    );
    delete enrollmentObj.accountUsageIntent.depositFrequency;
    delete enrollmentObj.accountUsageIntent.withdrawalFrequency;
  }

  if (enrollmentObj.personRoles) {
    enrollmentObj.personRoles = JSON.stringify(enrollmentObj.personRoles);
  }

  if (enrollmentObj.accountUsageIntent) {
    enrollmentObj.accountUsageIntent = JSON.stringify(
      enrollmentObj.accountUsageIntent
    );
  }

  return enrollmentObj;
}
