/* eslint-disable camelcase */
import {
  shouldSkipGetPostLoginActionCall,
  getJsonContent,
  trackInterjectionUserMessageReceived,
  isThereAnActiveModal,
} from "views/components/postLoginActions/helpers";
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { first, noop, debounce } from "underscore";
import Services from "services";
import analytics from "analytics";
import objectPath from "object-path";
import deepCopy from "deep-copy";
import * as VisibleUserMessages from "views/components/postLoginActions/VisibleUserMessages";
import parseResponseErrors from "libs/pcap/utils/response";
import sortUserMessagesByRank from "libs/pcap/utils/sortUserMessagesByRank";
import PostLoginActionModal from "components/modal/PostLoginActionModal";
import AceAppWideBannerView from "components/appWideBanner/AceAppWideBannerView";
import { isAppWideBannerUserMessage } from "components/appWideBanner/AceAppWideBanner";
import { getApptModalClassName } from "components/scheduling/AppointmentModal/AppointmentModal";
import useWindowSize from "components/hooks/useWindowSize";
import {
  getNextActiveInterjection,
  checkForActiveInterjection,
} from "components/interjection/InterjectionUtils";
import {
  addInterjectionToActiveQueue,
  isInterjectionUserMessage,
  USER_MSG_TYPE,
  isUserResponseBannerUserMessage,
} from "components/interjection/helpers";
import Modal from "components/modal/Modal";
import getAppointmentModalTitle from "views/components/postLoginActions/getAppointmentModalTitle";
import { AppointmentTimeContainer } from "components/scheduling/pages/AppointmentTime/AppointmentTimeStoriesUtils";
import { renderUserMessage } from "views/components/postLoginActions/RenderUserMessageUtils";

const MILLISECONDS_IN_A_SECOND = 1000;
const IS_PRODUCTION_ENVIRONMENT =
  window.location.host.toLowerCase() === "home.personalcapital.com";
const CURRENT_USER_EMAIL = (
  typeof window.username === "string"
    ? window.username
    : "user@personalcapital.com"
).toLowerCase();

const EXCLUDE_USER_FROM_INTERJECTIONS =
  !IS_PRODUCTION_ENVIRONMENT &&
  (CURRENT_USER_EMAIL.indexOf("testqa") === 0 ||
    CURRENT_USER_EMAIL.indexOf("qatest") === 0) &&
  CURRENT_USER_EMAIL.includes("@personalcapital.com"); // testqa***@personalcapital.com or qatest***@personalcapital.com

function normalizeCustomContent(originalCustomContent) {
  // `originalCustomContent` is either 1) ACE advice message object, or 2) GTM defined `PCAP.abFrameworkProperties.firstuse.madLibsQQInterjection`
  if (!originalCustomContent) {
    return originalCustomContent;
  }
  // `copyFrom` is either 1) ACE advice message's `jsonContent` object, or 2) GTM defined `PCAP.abFrameworkProperties.firstuse.madLibsQQInterjection`
  const copyFrom =
    objectPath.get(originalCustomContent, "resources.0.jsonContent") ||
    originalCustomContent;
  return Object.assign(
    {},
    copyFrom.isNew2019Version
      ? {
          isNew2019Version: copyFrom.isNew2019Version,
          titleIcon: copyFrom.titleIcon,
          titleBackground: copyFrom.titleBackground,
          headerAnimation: copyFrom.headerAnimation,
        }
      : {},
    {
      modalTitle: copyFrom.modalTitle || originalCustomContent.title,
      title: copyFrom.title || originalCustomContent.title,
      image: copyFrom.image,
      teaser: copyFrom.teaser,
      description: copyFrom.description,
      topContent: originalCustomContent.summary || copyFrom.topContent,
      bottomContent: copyFrom.bottomContent,
      // Meta Data
      attributionSource:
        copyFrom.attributionSource || originalCustomContent.template,
      userMessageId: originalCustomContent.userMessageId,
      skipMarkAsViewed: copyFrom.skipMarkAsViewed,
      showDisclaimerLink: Boolean(copyFrom.showDisclaimerLink),
      disclaimer: copyFrom.disclaimer,
      headerClassName: copyFrom.headerClassName,
      showTypeSelector: copyFrom.showTypeSelector,
      isMTREnrollment: copyFrom.isMTREnrollment,
      titleIcon: copyFrom.titleIcon,
      footerClassName: copyFrom.footerClassName,
      titleClassName: copyFrom.titleClassName,
    }
  );
}

export function getPostLoginActionPromise() {
  if (shouldSkipGetPostLoginActionCall(window.redirectTo || "")) {
    return new Promise((resolve) => {
      resolve({});
    });
  }

  return new Promise((resolve, reject) => {
    Services.PostLoginAction.get((error, response) => {
      const errors = parseResponseErrors(error, response);
      if (
        errors ||
        !response ||
        !response.spData ||
        response.spData.action !== "ADVICE"
      ) {
        if (errors) {
          analytics.sendEngineeringEvent(
            "Error",
            "js Services.PostLoginAction.get: " + JSON.stringify(errors)
          );
          reject(errors);
        } else {
          resolve({}); // When no PLA user message is specified, we still want to resolve the promise so we can process non-PLA user messages
        }
      } else {
        const userMessageId =
          response.spData.actionDetail &&
          response.spData.actionDetail.userMessageId;
        const messageTemplateName =
          response.spData.actionDetail &&
          response.spData.actionDetail.messageTemplateName;
        if (userMessageId) {
          resolve({ userMessageId, messageTemplateName });
        }
      }
    });
  });
}

export function getUserMessagesPromise({ getUncache = false } = {}) {
  return new Promise((resolve, reject) => {
    Services.UserMessages[getUncache ? "getUncache" : "getV2"](
      (error, response) => {
        const errors = parseResponseErrors(error, response);
        if (
          errors ||
          !response ||
          !response.spData ||
          !response.spData.userMessages
        ) {
          analytics.sendEngineeringEvent(
            "Error",
            "js Services.UserMessages.getV2: " + JSON.stringify(errors)
          );
          reject(errors);
        } else {
          resolve(response.spData.userMessages);
        }
      }
    );
  });
}

export function getUIPreferencesPromise() {
  return new Promise((resolve, reject) => {
    Services.Profile.getUIPreferences(function (error, response) {
      const errors = parseResponseErrors(error, response);
      if (
        errors ||
        !response ||
        !response.spData ||
        !response.spData.features
      ) {
        analytics.sendEngineeringEvent(
          "Error",
          "js Services.Profile.getUIPreferences: " + JSON.stringify(errors)
        );
        reject(errors);
      } else {
        resolve(response.spData.features);
      }
    });
  });
}

let renderNotificationTimer;
let renderContentComponentTimer;

export function renderNotification(
  CurrentNotificationView,
  viewTemplate,
  messageTemplateName,
  userMessage,
  plaDetails
) {
  const jsonContent = getJsonContent(userMessage) || {};
  const showModal = () => {
    if (renderNotificationTimer) {
      clearTimeout(renderNotificationTimer);
    }
    new CurrentNotificationView({
      viewTemplate,
      messageTemplateName,
      userMessage,
      plaDetails,
      componentName: "PLA",
    });
  };
  if (jsonContent.delay && typeof jsonContent.delay.seconds === "number") {
    if (renderNotificationTimer) {
      clearTimeout(renderNotificationTimer);
    }
    renderNotificationTimer = setTimeout(
      showModal,
      jsonContent.delay.seconds * MILLISECONDS_IN_A_SECOND
    );
  } else {
    showModal();
  }
}

export function renderContentComponent(
  ContentComponent,
  modalComponentName,
  userMessage,
  plaDetails,
  loadingModalTitle,
  className,
  width,
  logo = false,
  showTimeAgo,
  showFeedbackForm,
  contentClassName,
  showLoader
) {
  const jsonContent = getJsonContent(userMessage) || {};
  const showModal = () => {
    if (renderContentComponentTimer) {
      clearTimeout(renderContentComponentTimer);
    }
    let mountNode = document.createElement("div");
    document.body.appendChild(mountNode);
    ReactDOM.render(
      React.createElement(PostLoginActionModal, {
        modalComponentName,
        contentComponent: ContentComponent,
        userMessage,
        plaDetails,
        loadingModalTitle,
        className,
        width,
        logo,
        showTimeAgo,
        showFeedbackForm,
        mountNode,
        contentClassName,
        showLoader,
      }),
      mountNode
    );
  };
  if (jsonContent.delay && typeof jsonContent.delay.seconds === "number") {
    if (renderContentComponentTimer) {
      clearTimeout(renderContentComponentTimer);
    }
    renderContentComponentTimer = setTimeout(
      showModal,
      jsonContent.delay.seconds * MILLISECONDS_IN_A_SECOND
    );
  } else {
    showModal();
  }
}

export const postLoginActionViews = { aceAppWideBannerView: null };
export function handleAppWideBanner(userMessage) {
  let aceAppWideBannerView = postLoginActionViews.aceAppWideBannerView;
  if (!aceAppWideBannerView) {
    aceAppWideBannerView = new AceAppWideBannerView();
    postLoginActionViews.aceAppWideBannerView = aceAppWideBannerView;
    aceAppWideBannerView.on("closed", () => {
      delete postLoginActionViews.aceAppWideBannerView;
    });
  }
  aceAppWideBannerView.show(userMessage);
}

const ENROLLMENT_PAGE_HASH_WITH_SLASH = "#/open-bank-account";
const ENROLLMENT_PAGE_HASH_WITHOUT_SLASH = "#open-bank-account";
export function isOnEnrollmentPage() {
  return (
    window.location.hash.indexOf(ENROLLMENT_PAGE_HASH_WITH_SLASH) === 0 ||
    window.location.hash.indexOf(ENROLLMENT_PAGE_HASH_WITHOUT_SLASH) === 0
  );
}

/**
 * There are 3 entry points to this util
 * 1) router.js > initApp() > new PostLoginActionsView() > initialize() > has getPostLoginAction > renderPostLoginAction() > here
 * 2) router.js > initApp() > new PostLoginActionsView() > initialize() > no getPostLoginAction > here
 * 3) mainServices.js > listens to api spHeader for > marketingServerChanges() > getLatestUserMessages() > here
 */
export function screenUserMessagesFor({
  screenFor = [],
  userMessages = [],
  showBlankDisplayLocationInterjection = false,
} = {}) {
  const appWideBanners = [];
  const screenForAppWideBanners = screenFor.includes(
    USER_MSG_TYPE.APP_WIDE_BANNER
  );
  const screenForInterjections =
    screenFor.includes(USER_MSG_TYPE.INTERJECTION) &&
    !EXCLUDE_USER_FROM_INTERJECTIONS &&
    !isOnEnrollmentPage();

  const screenForUserResponseBanners =
    screenFor.includes(USER_MSG_TYPE.USER_RESPONSE_BANNER) &&
    !EXCLUDE_USER_FROM_INTERJECTIONS;

  userMessages.forEach((msg) => {
    // Handle new interjections
    if (
      (screenForInterjections &&
        isInterjectionUserMessage(msg) &&
        !msg.lastViewedTime) ||
      (screenForUserResponseBanners &&
        isUserResponseBannerUserMessage(msg) &&
        !msg.lastViewedTime)
    ) {
      addInterjectionToActiveQueue(msg);
      trackInterjectionUserMessageReceived(msg);
    }

    // Handle app wide banners
    if (screenForAppWideBanners && isAppWideBannerUserMessage(msg)) {
      appWideBanners.push(msg);
    }
  });
  if (appWideBanners.length) {
    const sortedAppWideBanners = sortUserMessagesByRank(appWideBanners);
    handleAppWideBanner(first(sortedAppWideBanners));
  }

  if (
    screenForInterjections &&
    (isThereAnActiveModal() || !VisibleUserMessages.isEmpty())
  ) {
    const userMessage =
      getNextActiveInterjection({
        showBlankDisplayLocationInterjection: true,
      }) || {};
    // MEETING_STARTED has the highest priority over all other modals
    const isMeetingStarted = userMessage.template === "MEETING_STARTED";
    if (isMeetingStarted) {
      // Remove all visible PLAs and Interjections, other types of modals will stay as is
      if (!VisibleUserMessages.isEmpty()) {
        VisibleUserMessages.markUnviewedAndRemoveAll();
      }
    } else {
      return; // Do not render interjections
    }
  }

  if (screenForInterjections || screenForUserResponseBanners) {
    checkForActiveInterjection({ showBlankDisplayLocationInterjection });
  }
}

export function renderPostLoginAction({
  userMessage,
  userMessages,
  features,
} = {}) {
  const options = { userMessage, features };
  renderUserMessage(options);

  screenUserMessagesFor({
    screenFor: [
      USER_MSG_TYPE.INTERJECTION,
      USER_MSG_TYPE.APP_WIDE_BANNER,
      USER_MSG_TYPE.USER_RESPONSE_BANNER,
    ],
    userMessages: deepCopy(userMessages),
    showBlankDisplayLocationInterjection: false,
    features,
    isFromInitApp: true,
  });
}

export const getLatestUserMessages = debounce(
  ({
    screenFor = [],
    showBlankDisplayLocationInterjection = false,
    isFromServerChangeEvent = false,
  } = {}) => {
    getUserMessagesPromise({ getUncache: true }).then((userMessages) => {
      screenUserMessagesFor({
        screenFor,
        userMessages,
        showBlankDisplayLocationInterjection,
        isFromServerChangeEvent,
      });
    });
  },
  100,
  true
);

const CustomAppointmentModal = ({ customContent, onNext }) => {
  return (
    <Modal
      componentName="Mad Libs Interjection"
      title={getAppointmentModalTitle({ customContent })}
      className={getApptModalClassName(useWindowSize(), customContent)}
      isOpen={true}
      onClosed={noop}
      shouldCloseOnOverlayClick={false}
      overlay
    >
      <AppointmentTimeContainer
        isThreeTimeSlotsMode={true}
        appointmentType="PORTFOLIO_REVIEW"
        duration={30}
        appointmentId="7"
        onNext={onNext}
        customContent={customContent}
      />
    </Modal>
  );
};

CustomAppointmentModal.propTypes = {
  customContent: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
};

export function renderMadLibsInterjection({ userMessage, onNext = noop } = {}) {
  const customContent = { data: normalizeCustomContent(userMessage) };
  let mountNode = document.createElement("div");
  document.body.appendChild(mountNode);
  ReactDOM.render(
    <CustomAppointmentModal customContent={customContent} onNext={onNext} />,
    mountNode
  );
}
