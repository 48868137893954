import React from "react";
import PropTypes from "prop-types";

const Breadcrumbs = (props) => {
  const { items, className } = props;
  return (
    <nav>
      <ul className={`pc-breadcrumb qa-breadcrumb ${className}`}>
        {items.map((listItem, key) => {
          return (
            <li key={key} className="pc-breadcrumb__item">
              {listItem.link ? (
                <a
                  className="pc-breadcrumb__node"
                  href={listItem.link}
                  title={listItem.label}
                >
                  <span className="pc-breadcrumb__text qa-pc-breadcrumb__text">
                    {listItem.label}
                  </span>
                </a>
              ) : (
                <span className="pc-breadcrumb__node">
                  <span className="pc-breadcrumb__text qa-pc-breadcrumb__text">
                    {listItem.label}
                  </span>
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  className: "",
};

export default Breadcrumbs;
