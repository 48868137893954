import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

const IMAGE_BASE_URL = IS_ADVISOR
  ? `${window.staticUrl || ""}/static/img/icons/`
  : `${window.baseUrl}static/img/icons/`;
const LOGO_TO_SHOW = "empower-simplified-logo.svg";
const LOGO_URL = IMAGE_BASE_URL + LOGO_TO_SHOW;
const NON_OAUTH_MESSAGE =
  "Linking an account is simple, secure, and takes a couple of minutes. You will need the following information:";
export default class WhatToExpectTest extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { logoPath, name, onBack, isOAuth, onContinue } = this.props;
    const OAUTH_MESSAGE =
      "Linking an account is simple, secure, and takes a couple of minutes. You'll be redirected to " +
      name +
      " and then we'll bring you right back. You will need the following information:";
    return (
      <div className="expectation-test__container pc-u-mt+">
        <div className="subLogo scroller">
          <div className="container">
            <div className="expectation-test__header-container pc-u-p">
              <div className="expectation-test__header l-spaced l-spaced--center">
                <div className="expectation-test__logo-container l-spaced pc-u-p--">
                  <img
                    className="expectation-test-pcap-logo"
                    src={LOGO_URL}
                    alt="Personal Capital"
                  />
                </div>
                <div className="expectation-test__link-container l-spaced">
                  <div className="dotted-line"></div>
                  <div className="expectation-test__link-icon__container l-spaced">
                    <img
                      className="expectation-test__link-icon"
                      src={IMAGE_BASE_URL + "swap-calls-icon.svg"}
                      alt="Link Account icon"
                    />
                  </div>
                  <div className="dotted-line"></div>
                  <div></div>
                </div>
                <div className="expectation-test__logo-container l-spaced pc-u-p--">
                  {logoPath && (
                    <>
                      <img
                        className="expectation-test__fi-logo"
                        src={logoPath}
                        alt={name}
                      />
                    </>
                  )}
                  {!logoPath && (
                    <>
                      <span className="centi">{name}</span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="expectation-test__content pc-u-pr++ pc-u-pl++">
              <h2 className="expectation-test__text pc-u-mt">
                Connecting your account
              </h2>
              {isOAuth && (
                <>
                  <p className="expectation-test__message">{OAUTH_MESSAGE}</p>
                </>
              )}

              {!isOAuth && (
                <>
                  <p className="expectation-test__message">
                    {NON_OAUTH_MESSAGE}
                  </p>
                </>
              )}
              <ul>
                <li>Username and Password</li>
                <li>
                  Access to phone or email to complete Multi-Factor
                  Authentication
                </li>
              </ul>

              <div className="expectation-test__footer l-spaced l-spaced--baseline l-spaced--flush">
                <div className="expectation-test__footer__info-link">
                  <img
                    width="15px"
                    className="expectation-test__footer__security-icon"
                    src={IMAGE_BASE_URL + "lock--gray.svg"}
                    alt="Information Security Icon"
                  />
                  <a
                    className="milli pc-u-ml--"
                    href="https://www.empower.com/empower-difference/security?utm_source=pcap&utm_medium=referral"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn about our security
                  </a>
                </div>
                <div className="buttonContainer l-spaced l-spaced--right pc-u-mt++">
                  <button
                    type="button"
                    className="pc-btn qa-action-back"
                    onClick={onBack}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="pc-btn pc-btn--primary qa-action-continue"
                    onClick={onContinue}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WhatToExpectTest.propTypes = {
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  name: PropTypes.string.isRequired,
  logoPath: PropTypes.string,
  isOAuth: PropTypes.bool,
};

WhatToExpectTest.defaultProps = {
  onBack: noop,
  onContinue: noop,
  logoPath: undefined,
  isOAuth: false,
};
