import Account from "models/account";
import BaseAccountSelectorView from "views/components/accountSelector/baseAccountSelectorView2";
import AccountSelectorTemplateRebrand from "templates/components/accountSelector/home_rebrand/taxableAccountSelector2.html";
import Mixpanel from "mixpanel";
import _ from "underscore";
import * as AccountConstants from "constants/account";
var TaxableAccountSelectorView = BaseAccountSelectorView.extend({
  events: _.extend(
    { "click .js-checkbox-group-on-us": "toggleOnUs" },
    BaseAccountSelectorView.prototype.events
  ),
  initialize: function (options) {
    // one time use flag to exclude on initial render, only if no changes were made
    this.excludeOnUs = options && options.excludeOnUs === true;
    this.hasChanges = false;

    this.onUsSelected = !this.excludeOnUs;
    this.onUsAccountIds = [];
    this.menuListLength = 0;
    BaseAccountSelectorView.prototype.initialize.apply(this, arguments);
  },

  createGroupNames: function (groups, groupLabels) {
    groups.splice(0, groups.length, "TAXABLE", "NON_TAXABLE");

    //eslint-disable-next-line guard-for-in
    for (var propertyName in groupLabels) {
      delete groupLabels[propertyName];
    }
    groupLabels.TAXABLE = "Taxable";
    groupLabels.NON_TAXABLE = "Tax advantaged";
  },

  render: function () {
    var accountsArray = _.isFunction(this.closedAccountsFilter)
      ? this.closedAccountsFilter(this.accountsCollection.models)
      : this.accountsCollection.models;
    this.accountsLength = accountsArray.length;

    //update checked account ids array, filter out on us accounts
    if (this.excludeOnUs) {
      this.checkedUserAccountIds = this.filterCheckedOnUsAccounts(
        this.groups,
        accountsArray
      );
    }

    this.accountData = this.generateAccountDataForMenu(
      this.groups,
      accountsArray
    );

    if (this.accountData && this.accountData.length) {
      this.allAccountsSelected = this.accountData.every(
        (groupData) => groupData.checked
      );

      if (this.excludeOnUs) {
        this.setOnUsAccountsCheck(this.accountData, false);
      }
    }
    var accountsTemplateData = {
      allAccounts: this.allAccountsSelected,
      buttonLabel: this.buttonLabel(),
      accountGroups: this.accountData,
      showOnUsToggle: !IS_EMPOWER && this.onUsAccountIds.length > 0,
      onUsSelected: this.onUsSelected,
      selectAllOnUsLabel: AccountConstants.ONUS_INVEST_NAME,
      showBalances: this.showBalances,
    };

    const template = AccountSelectorTemplateRebrand;
    this.$el.html(template(accountsTemplateData));
    this.toggleDoneButton();
    return this;
  },

  setOnUsAccountsCheck(accountGroups, checkValue) {
    let onUsAccountIds = [];
    _.each(accountGroups, (accountGroup) => {
      _.each(accountGroup.accounts, (account) => {
        if (this.isAccountOnUs(account)) {
          account.checked = checkValue;
          onUsAccountIds.push(account.userAccountId);
        }
      });
    });

    this.selectedUserAccountIds = this.selectedUserAccountIds.filter(function (
      onUsAccounts
    ) {
      return !onUsAccountIds.includes(onUsAccounts);
    });
  },

  filterCheckedOnUsAccounts: function (groups, accountsArray) {
    var excludeIds = [];
    _.each(
      groups,
      function (groupName) {
        // eslint-disable-next-line no-invalid-this
        var accounts = this.groupAccountsBy(groupName, accountsArray);
        _.each(accounts, (account) => {
          if (this.isAccountOnUs(account.attributes)) {
            excludeIds.push(account.attributes.userAccountId);
          }
        });
      }.bind(this)
    );
    var filteredIds = [];
    _.each(this.checkedUserAccountIds, function (nextId) {
      if (!_.contains(excludeIds, nextId)) {
        filteredIds.push(nextId);
      }
    });
    return filteredIds;
  },

  mapAccountToMenuObject: function (account) {
    const baseAccountProperties =
      BaseAccountSelectorView.prototype.mapAccountToMenuObject.apply(
        this,
        arguments
      );

    return {
      ...baseAccountProperties,
      isOnUs: account.get("isOnUs"),
      isOnUsRetirement: account.get("isOnUsRetirement"),
    };
  },
  buttonLabel: function () {
    if (
      this.checkedUserAccountIds.length === this.onUsAccountIds.length &&
      this.areAllOpenOnUsAccountsChecked()
    ) {
      return `All ${AccountConstants.ONUS_INVEST_NAME} Accounts`;
    }
    return BaseAccountSelectorView.prototype.buttonLabel.call(this);
  },

  filterAccountsFromDropdownResult: function (accountsCollection) {
    return accountsCollection.filter(function (account) {
      if (account.get("productType") !== Account.PRODUCT_TYPES.INVESTMENT) {
        //eslint-disable-line eqeqeq
        return false;
      } else if (
        this.excludeManualAccounts === true &&
        account.get("isManual") === true
      ) {
        return false;
      }

      return true;
    }, this);
  },

  groupAccountsBy: function (groupName, accounts) {
    return _.filter(
      accounts,
      function (account) {
        var groupMatch = false;
        if (
          (account.get("isTaxDeferredOrNonTaxable") === false &&
            groupName === "TAXABLE") ||
          (account.get("isTaxDeferredOrNonTaxable") === true &&
            groupName === "NON_TAXABLE")
        ) {
          groupMatch = true;
        }

        if (this.excludeManualAccounts === true) {
          return (
            groupMatch === true &&
            account.get("isManual") === false &&
            account.get("userAccountId") !== undefined &&
            account.get("productType") === Account.PRODUCT_TYPES.INVESTMENT
          ); //eslint-disable-line eqeqeq
        }
        return (
          groupMatch === true &&
          account.get("userAccountId") !== undefined &&
          account.get("productType") === Account.PRODUCT_TYPES.INVESTMENT
        ); //eslint-disable-line eqeqeq
      }.bind(this)
    );
  },
  onAccountCollectionChange: function () {
    this.onUsAccountIds = this.filterAccountsFromDropdownResult(
      this.accountsCollection
    )
      .filter((account) => this.isAccountOnUs(account.attributes))
      .map((account) => account.get("userAccountId"));

    BaseAccountSelectorView.prototype.onAccountCollectionChange.apply(
      this,
      arguments
    );
  },
  toggleAllAccounts: function (event) {
    this.allAccountsSelected = this.$el
      .find(event.currentTarget)
      .prop("checked");
    this.hasChanges = true;
    this.$el.find(":checkbox").prop("checked", this.allAccountsSelected);

    this.onUsSelected = this.allAccountsSelected;

    if (this.allAccountsSelected) {
      this.checkedUserAccountIds = this.cleanedUserAccountIds.slice(0);
    } else {
      this.checkedUserAccountIds = [];
    }

    this.toggleDoneButton();
    Mixpanel.trackEvent("Toggle All Accounts in Taxable Account Selector", {
      component: "Account Selector",
    });
  },

  toggleOnUs: function () {
    this.onUsSelected = !this.onUsSelected;

    _.each(
      this.cleanedUserAccountIds,
      function (userAccountId) {
        var account = this.accountsCollection.get(userAccountId);
        if (
          this.isAccountOnUs(account.attributes) &&
          !account.get("closedDate")
        ) {
          this.$el
            .find(".uaid" + userAccountId)
            .prop("checked", this.onUsSelected);
        }
      }.bind(this)
    );

    // update state of group checkboxes
    var groups = this.$el.find(".js-account-group");
    _.each(groups, function (groupElement) {
      var groupJquery = $(groupElement);
      var checkGroup =
        groupJquery.find(".js-checkbox-account").not(":checked").length === 0;
      groupJquery.find(".js-checkbox-group").prop("checked", checkGroup);
    });

    this.updateCheckedUserAccountIds();

    Mixpanel.trackEvent(
      `Toggle All ${AccountConstants.ONUS_INVEST_NAME} Accounts in Taxable Account Selector`,
      {
        component: "Account Selector",
      }
    );
  },

  updateCheckedUserAccountIds: function () {
    BaseAccountSelectorView.prototype.updateCheckedUserAccountIds.call(this);
    this.onUsSelected = this.areAllOpenOnUsAccountsChecked();
    this.hasChanges = true;
    this.$el
      .find(".js-checkbox-group-on-us")
      .prop("checked", this.onUsSelected);
  },

  /***
   * Returns true only if ALL the open OnUs accounts are checked
   * @returns {boolean} true if all open on us accounts are checked
   */
  areAllOpenOnUsAccountsChecked: function () {
    const checkedAccounts = this.$(".js-checkbox-on-us:checked");
    if (this.onUsAccountIds.length === 0) {
      return false;
    }
    return this.onUsAccountIds.length === checkedAccounts.length;
  },

  onDone: function (event) {
    this.onUsSelected = this.$el
      .find(".js-checkbox-group-on-us")
      .prop("checked");
    if (this.hasChanges) {
      this.excludeOnUs = false;
    }
    BaseAccountSelectorView.prototype.onDone.call(this, event);
  },
});

export default TaxableAccountSelectorView;
