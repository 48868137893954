import _ from "underscore";
function isPositive(grant) {
  return grant.totalValue >= 0;
}

export default function (grants) {
  grants = _.sortBy(grants, function (grant) {
    return grant.totalValue;
  });
  var positiveGrants = _.filter(grants, isPositive);
  positiveGrants = _.sortBy(positiveGrants, function (g) {
    return parseFloat(g.totalValue) * -1;
  });
  var negativeGrants = _.reject(grants, isPositive);
  negativeGrants = _.sortBy(negativeGrants, function (g) {
    return parseFloat(g.totalValue);
  });

  grants = positiveGrants.concat(negativeGrants);

  return grants;
}
