import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import ProjectionSummaryAccountContributionsView from "components/projectionSummary/ProjectionSummaryAccountContributionsView";

const COMPONENT_NAME = "Projection Summary Account Contributions Modal";

const ProjectionSummaryAccountContributionsContainer = ({
  allContributions,
  allWithdrawals,
  onClose,
  isOpen,
}) => {
  let title = "";
  if (allWithdrawals.length && allContributions.length) {
    title = "Current Withdrawals and Contributions";
  } else if (allWithdrawals.length) {
    title = "Current Withdrawals";
  } else {
    title = "Current Contributions";
  }
  return (
    <Modal
      componentName={COMPONENT_NAME}
      contentLabel={title}
      title={title}
      onClosed={onClose}
      isOpen={isOpen}
      className="pc-modal--small"
    >
      <ProjectionSummaryAccountContributionsView
        componentName={COMPONENT_NAME}
        onClose={onClose}
        allContributions={allContributions}
        allWithdrawals={allWithdrawals}
      />
    </Modal>
  );
};

ProjectionSummaryAccountContributionsContainer.propTypes = {
  allContributions: PropTypes.array,
  allWithdrawals: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ProjectionSummaryAccountContributionsContainer.defaultProps = {
  allContributions: [],
  allWithdrawals: [],
};

export default ProjectionSummaryAccountContributionsContainer;
