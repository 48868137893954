var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <ul class=\"pc-layout pc-layout--auto\">\n          <li class=\"pc-layout__item\"><a href=\"https://www.empower.com/hpc/privacy-policy\" target=\"_blank\">Privacy</a></li>\n          <li class=\"pc-layout__item\"><a href=\"https://www.empower.com/hpc/terms-of-use\" target=\"_blank\">Terms of Service</a></li>\n          <li class=\"pc-layout__item\"><a href=\"https://docs.empower.com/Disclosures/hpc-notices.pdf\" target=\"_blank\">Notices</a></li>\n          <li class=\"pc-layout__item\"><a href=\"https://"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"organizationSupportUrl") || (depth0 != null ? lookupProperty(depth0,"organizationSupportUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"organizationSupportUrl","hash":{},"data":data,"loc":{"start":{"line":10,"column":55},"end":{"line":10,"column":81}}}) : helper)))
    + "/home\" target=\"_blank\">Help &amp; FAQ</a></li>\n          <li class=\"pc-layout__item\"><a href=\"https://www.empower.com/about-empower\" target=\"_blank\">Company</a></li>\n        </ul>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<footer id=\"footerContainer\" class=\"footer footer--dfa pc-u-ph pc-u-ml milli\">\n  <div class=\"main-container\">\n    <div class=\"main-container__inner\">\n      <div class=\"l-spaced l-spaced--flush\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hidePersonalCapitalLinks") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "          <div class=\"u-text-left\">\n            &copy; <span id=\"js-footer-year\"></span><script>document.getElementById('js-footer-year').appendChild(document.createTextNode(new Date().getFullYear()))</script> Empower Annuity Insurance Company of America. All Rights Reserved. <br>Advisory services by Empower Advisory Group, LLC.:<a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://docs.empower.com/eag/full-disclosures.pdf\">read disclosures</a>\n          </div>\n      </div>\n    </div>\n  </div>\n</footer>\n";
},"useData":true});