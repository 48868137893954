import React from "react";
import PropTypes from "prop-types";
import FundToFundRequestDetailPage from "../FundToFundRequestDetailPage";
import LoadingOverlay from "components/common/LoadingOverlay";
import Services from "services";
import noop from "utils/noop";
import { promisify } from "utils/service";
import { getGainLossPercentage } from "../../../empower/components/manageInvestmentFlow/utils";

export default class RequestDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const { getFundOptions, getHoldings, userAccountId, transaction } =
      this.props;
    this.setState({ loading: true });

    Promise.all([
      getFundOptions({ userAccountId, productOfferings: ["DIY"] }),
      getHoldings({ userAccountIds: JSON.stringify([userAccountId]) }),
    ])
      .then(([fundOptionsResp, holdingsResp]) => {
        console.log(holdingsResp);
        const activeFunds = fundOptionsResp?.funds;
        const holdings = holdingsResp?.holdings;
        transaction.to.map((toFund) => {
          const matchFund = activeFunds.find(
            (fund) => toFund.cusip === fund.cusip
          );

          const holdingFund = holdings.find(
            (fund) => toFund.cusip === fund.cusip
          );
          if (matchFund) {
            toFund.legalName = matchFund.legalName;
            toFund.investmentOverviewLink = matchFund.investmentOverviewLink;
            toFund.shortTermUnrealizedGainLoss =
              holdingFund?.shortTermUnrealizedGainLoss;
            toFund.longTermUnrealizedGainLoss =
              holdingFund?.longTermUnrealizedGainLoss;
            toFund.totalUnrealizedGainLoss =
              holdingFund?.totalUnrealizedGainLoss;
            toFund.totalGainLossPercentage = getGainLossPercentage(
              holdingFund?.value,
              holdingFund?.costBasis
            );
          }

          return toFund;
        });

        transaction.from.map((fromFund) => {
          const matchFund = activeFunds.find(
            (fund) => fromFund.cusip === fund.cusip
          );
          const holdingFund = holdings.find(
            (fund) => fromFund.cusip === fund.cusip
          );
          if (matchFund) {
            fromFund.legalName = matchFund.legalName;
            fromFund.investmentOverviewLink = matchFund.investmentOverviewLink;
            fromFund.investmentOverviewLink = matchFund.investmentOverviewLink;
            fromFund.shortTermUnrealizedGainLoss =
              holdingFund?.shortTermUnrealizedGainLoss;
            fromFund.longTermUnrealizedGainLoss =
              holdingFund?.longTermUnrealizedGainLoss;
            fromFund.totalUnrealizedGainLoss =
              holdingFund?.totalUnrealizedGainLoss;
            fromFund.totalGainLossPercentage = getGainLossPercentage(
              holdingFund?.value,
              holdingFund?.costBasis
            );
          }

          return fromFund;
        });
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        const errors = err?.errors ?? err;
        this.setState({
          loading: false,
          errors,
        });
      });
  }

  render() {
    const { transaction, hideTransactionsDetails } = this.props;

    return (
      <>
        <LoadingOverlay active={this.state.loading} />
        <ul className={`pc-breadcrumb qa-breadcrumb`}>
          <li className="pc-breadcrumb__item">
            <a
              className="pc-breadcrumb__node"
              onClick={hideTransactionsDetails}
            >
              <span className="pc-breadcrumb__text qa-pc-breadcrumb__text">
                Transactions
              </span>
            </a>
          </li>
          <li className="pc-breadcrumb__item">
            <span className="pc-breadcrumb__node">
              <span className="pc-breadcrumb__text qa-pc-breadcrumb__text">
                {transaction.label}
              </span>
            </span>
          </li>
        </ul>
        {(transaction.label === "Rebalance" ||
          transaction.label === "Fund to fund trade") && (
          <FundToFundRequestDetailPage transaction={transaction} />
        )}
        <div className="pc-u-mt">
          <button
            type="button"
            className="request-detail__back-btn js-back-btn"
            onClick={hideTransactionsDetails}
          >
            Back
          </button>
        </div>
      </>
    );
  }
}

RequestDetailContainer.propTypes = {
  transaction: PropTypes.object,
  hideTransactionsDetails: PropTypes.func,
  getFundOptions: PropTypes.func,
  getHoldings: PropTypes.func,
  userAccountId: PropTypes.number,
};

RequestDetailContainer.defaultProps = {
  transaction: undefined,
  hideTransactionsDetails: noop,
  getFundOptions: promisify(Services.EmpowerInvestment.fundOptions),
  getHoldings: promisify(Services.Holdings.get),
  userAccountId: undefined,
};
