import PropTypes from "prop-types";
import React from "react";
import TableClient from "libs/pcap/table/tableClient/TableClient";
import { compareAlpha, compareNum } from "libs/pcap/table/data/compare";
import Row from "libs/pcap/table/Row";
import { formatCurrency } from "libs/pcap/utils/format";
import { isEqual } from "underscore";
import StockOptionsAddRow from "./StockOptionsAddRow";
import StockOptionsEditRow from "./StockOptionsEditRow";
import Mixpanel from "mixpanel";

export const defaultColumns = [
  {
    header: "Grant",
    accessor: (d) => d.grantName,
    className: "pc-datagrid__cell--stock-opt-grant qa-grant-name",
    headerClassName:
      "pc-datagrid__cell--stock-opt-grant qa-grant-name-col-header",
    summaryClassName:
      "pc-datagrid__cell--stock-opt-grant u-text-bold qa-grant-name-col-footer",
    isSortable: true,
    summaryAccessor: () => "Grant Total",
    comparator: compareAlpha,
  },
  {
    header: "Vested",
    accessor: (d) => parseFloat(d.vestedShares),
    className:
      "table__column--right pc-datagrid__cell--stock-opt-vested-num qa-grant-vested",
    headerClassName:
      "table__column--right pc-datagrid__cell--stock-opt-vested-num qa-grant-vested-col-header",
    summaryClassName:
      "table__column--right pc-datagrid__cell--stock-opt-vested-num u-text-bold qa-grant-vested",
    isSortable: true,
    comparator: compareNum,
    summaryAccessor: (data) => {
      return data.reduce((total, stockOptionHolding) => {
        return total + stockOptionHolding.vestedShares;
      }, 0);
    },
  },
  {
    header: "Unvested",
    accessor: (d) => parseFloat(d.unvestedShares),
    className:
      "table__column--right pc-datagrid__cell--stock-opt-unvested-num qa-grant-unvested",
    headerClassName:
      "table__column--right pc-datagrid__cell--stock-opt-unvested-num qa-grant-unvested-col-header",
    summaryClassName:
      "table__column--right pc-datagrid__cell--stock-opt-unvested-num u-text-bold qa-grant-unvested-col-footer",
    summaryAccessor: (data) => {
      return data.reduce((total, stockOptionHolding) => {
        return total + stockOptionHolding.unvestedShares;
      }, 0);
    },
    isSortable: true,
    comparator: compareNum,
  },
  {
    header: "Exercise Price",
    accessor: (d) => parseFloat(d.exercisePrice),
    className:
      "table__column--right pc-datagrid__cell--stock-opt-exercise-price qa-grant-exercise-price",
    headerClassName:
      "table__column--right pc-datagrid__cell--stock-opt-exercise-price qa-grant-exercise-price-col-header",
    summaryClassName:
      "table__column--right pc-datagrid__cell--stock-opt-exercise-price",
    formatter: formatCurrency,
    isSortable: true,
    comparator: compareNum,
  },
  {
    header: " Vested",
    accessor: (d) => parseFloat(d.vestedValue),
    className:
      "table__column--right pc-datagrid__cell--stock-opt-vested-val qa-stock-option-vested-val",
    headerClassName:
      "table__column--right pc-datagrid__cell--stock-opt-vested-val qa-stock-option-vested-val-col-header",
    summaryClassName:
      "table__column--right pc-datagrid__cell--stock-opt-vested-val u-text-bold qa-stock-option-vested-val-col-footer",
    formatter: formatCurrency,
    summaryAccessor: (data) => {
      return data.reduce((total, stockOptionHolding) => {
        return total + parseFloat(stockOptionHolding.vestedValue);
      }, 0);
    },
    summaryFormatter: formatCurrency,
    isSortable: true,
    comparator: compareNum,
  },
  {
    header: " Unvested",
    accessor: (d) => parseFloat(d.unvestedValue),
    className:
      "table__column--right pc-datagrid__cell--stock-opt-unvested-val qa-grant-unvested",
    headerClassName:
      "table__column--right pc-datagrid__cell--stock-opt-unvested-val qa-grant-unvested-col-header",
    summaryClassName:
      "table__column--right pc-datagrid__cell--stock-opt-unvested-val u-text-bold qa-grant-unvested-col-footer",
    formatter: formatCurrency,
    summaryAccessor: (data) => {
      return data.reduce((total, stockOptionHolding) => {
        return total + parseFloat(stockOptionHolding.unvestedValue);
      }, 0);
    },
    summaryFormatter: formatCurrency,
    isSortable: true,
    comparator: compareNum,
  },
  {
    header: "Total",
    accessor: (d) => parseFloat(d.totalValue),
    className:
      "table__column--right pc-datagrid__cell--stock-opt-total-val qa-stock-option-value",
    headerClassName:
      "table__column--right pc-datagrid__cell--stock-opt-total-val qa-stock-options-value-col-header",
    summaryClassName:
      "table__column--right pc-datagrid__cell--stock-opt-total-val u-text-bold",
    formatter: formatCurrency,
    isSortable: true,
    comparator: compareNum,
    summaryFormatter: formatCurrency,
    sortOrder: "asc",
    summaryAccessor: (data) => {
      return data.reduce((total, stockOptionHolding) => {
        return total + parseFloat(stockOptionHolding.totalValue);
      }, 0);
    },
  },
];

export class StockOptionsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleRowClick = this.handleRowClick.bind(this);
    this.getRow = this.getRow.bind(this);
    this.handleCloseEditor = this.handleCloseEditor.bind(this);
    this.handleAddStockOptionClick = this.handleAddStockOptionClick.bind(this);
    this.newGrantForm = this.newGrantForm.bind(this);
  }

  componentDidMount() {
    const { stockOptionHoldings } = this.props;
    if (!stockOptionHoldings || stockOptionHoldings.length === 0) {
      this.setState({
        newStockOptionHolding: {},
      });
    }
  }

  handleRowClick(ev, data) {
    this.setState(
      { stockOptionHoldingBeingEdited: data, newStockOptionHolding: undefined },
      () => {
        if (this.props.onRowClick) {
          this.props.onRowClick(data);
        }
      }
    );
  }

  handleCloseEditor() {
    this.setState({
      stockOptionHoldingBeingEdited: undefined,
      newStockOptionHolding: undefined,
    });
  }

  handleAddStockOptionClick() {
    Mixpanel.trackEvent("Click on Add Grant button", {
      component: "Stock Options",
    });
    this.setState({
      stockOptionHoldingBeingEdited: undefined,
      newStockOptionHolding: {},
    });
  }

  getRow(props) {
    if (this.state.newStockOptionHolding && props.index === 0) {
      let AddStockOptionsHoldingRow = this.props.AddStockOptionsHoldingRow;
      return [
        <AddStockOptionsHoldingRow
          {...props}
          className={`qa-datagrid-row qa-investing-form ${
            props.className || ""
          }`}
          columns={this.props.columns}
          model={this.state.newStockOptionHolding}
          onCancel={this.handleCloseEditor}
          onSubmit={this.props.onCreateStockOptionsHolding}
          accountName={this.props.accountName}
          security={this.props.security}
          account={this.props.account}
          onSaved={() => {
            this.handleCloseEditor();
          }}
          key={"newStockOptionHoldingRow"}
        />,
        <Row {...props} key={props.index} />,
      ];
    }
    if (
      this.state.stockOptionHoldingBeingEdited &&
      isEqual(this.state.stockOptionHoldingBeingEdited, props.data)
    ) {
      let EditStockOptionsHoldingRow = this.props.EditStockOptionsHoldingRow;
      return (
        <EditStockOptionsHoldingRow
          {...props}
          columns={this.props.columns}
          className={`qa-grant-edit-form ${props.className || ""}`}
          model={this.state.stockOptionHoldingBeingEdited}
          onCancel={this.handleCloseEditor}
          onSubmit={this.props.onUpdateStockOptionsHolding}
          onRemove={this.props.onRemoveStockOptionsHolding}
          key={"editStockOptionHoldingRow"}
          onSaved={() => {
            /* NOOP */
          }}
        />
      );
    }

    return (
      <Row
        {...props}
        className={`qa-datagrid-row qa-grant-row ${props.className || ""}`}
      />
    );
  }

  newGrantForm(props) {
    let AddStockOptionsHoldingRow = this.props.AddStockOptionsHoldingRow;
    return (
      <AddStockOptionsHoldingRow
        {...props}
        className={`qa-datagrid-row qa-investing-form ${props.className || ""}`}
        columns={this.props.columns}
        model={this.state.newStockOptionHolding}
        onCancel={this.handleCloseEditor}
        onSubmit={this.props.onCreateStockOptionsHolding}
        accountName={this.props.accountName}
        security={this.props.security}
        onSaved={() => {
          this.handleCloseEditor();
        }}
        key={"newStockOptionHoldingRow"}
      />
    );
  }

  render() {
    let handleRowClick;
    let {
      className,
      isEditable,
      zeroState,
      stockOptionHoldings,
      showAddGrant,
    } = this.props;
    // enables row clicks if the component is initialized as `editable` and we're not editing a row at the moment
    if (isEditable) {
      handleRowClick = this.handleRowClick;
      className += " table--actionable";
    }
    const showZeroStateForm =
      !stockOptionHoldings ||
      (stockOptionHoldings.length === 0 && this.state.newStockOptionHolding);
    return (
      <div className={this.props.containerClassName}>
        {showAddGrant && (
          <button
            type="button"
            className="pc-btn pc-btn--small pc-u-ml-- pc-u-mt- qa-add-grant-button"
            onClick={this.handleAddStockOptionClick}
          >
            Add Grant
          </button>
        )}
        <TableClient
          {...this.props}
          data={stockOptionHoldings}
          onRowClick={handleRowClick}
          Row={this.getRow}
          tableClassName={`table--primary table__body--primary pc-stock-options-grid qa-datagrid-rows centi ${className}`}
          className="stock-options-grid-table-client-container js-stock-options-grid-table-container"
          zeroState={() => <div></div>}
        />
        {showZeroStateForm && this.newGrantForm(this.props)}
        {!stockOptionHoldings ||
          (stockOptionHoldings.length === 0 &&
            !this.state.newStockOptionHolding && (
              <>
                {typeof zeroState === "string" && (
                  <div className="pc-u-mt pc-block--center qa-stock-options-grid-zero-state">
                    <strong>{zeroState}</strong>
                  </div>
                )}
                {typeof zeroState === "function" && zeroState(this.props)}
                {typeof zeroState === "object" && zeroState}
              </>
            ))}
      </div>
    );
  }
}

StockOptionsGrid.propTypes = {
  stockOptionHoldings: PropTypes.array.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  headerRowClassName: PropTypes.string,
  onRowClick: PropTypes.func,
  onUpdateStockOptionsHolding: PropTypes.func,
  onCreateStockOptionsHolding: PropTypes.func,
  onRemoveStockOptionsHolding: PropTypes.func,
  columns: PropTypes.array,
  isEditable: PropTypes.bool,
  showAddGrant: PropTypes.bool,
  paginator: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  zeroState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  AddStockOptionsHoldingRow: PropTypes.oneOfType([PropTypes.func]),
  EditStockOptionsHoldingRow: PropTypes.oneOfType([PropTypes.func]),
  accountName: PropTypes.string,
  security: PropTypes.object,
  account: PropTypes.object,
};

StockOptionsGrid.defaultProps = {
  columns: defaultColumns,
  showAddGrant: true,
  isEditable: false,
  zeroState: "No stock options holdings match your search criteria",
  className: "",
  accountName: "",
  containerClassName: undefined,
  headerRowClassName: undefined,
  onRowClick: undefined,
  onUpdateStockOptionsHolding: undefined,
  onCreateStockOptionsHolding: undefined,
  onRemoveStockOptionsHolding: undefined,
  AddStockOptionsHoldingRow: StockOptionsAddRow,
  EditStockOptionsHoldingRow: StockOptionsEditRow,
  security: undefined,
  paginator: {
    stepSize: 100,
    start: 0,
    className: "stock-options-grid-paginator-container",
  },
  account: {},
};
