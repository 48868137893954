import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import RemoveAccount from "components/common/RemoveAccount";
import { FULL_MONTH_DATE_YEAR_FORMAT } from "libs/pcap/utils/date2";
import moment from "moment";

const organizationMailCash = "cash@personalwealth.empower.com";

const AccountInformation = (props) => {
  const {
    accountNumber,
    routingNumber,
    primaryOwner,
    secondaryOwner,
    expirationDate,
    serviceWithdrawInvite,
    onWithdrawnInvitation,
    loading,
    errors,
    onDone,
  } = props;
  const showMaskedAccountHelpText = isNaN(accountNumber);
  return (
    <>
      <Message className="pc-u-mb" severity="error" messages={errors} />
      <LoadingOverlay active={loading} />
      <h2 className="pc-delta pc-u-mb0">Routing Number</h2>
      <div className="pc-u-mb-">
        <strong data-hj-masked>{routingNumber}</strong>
      </div>
      <h2 className="pc-delta pc-u-mb0">Account Number</h2>
      <div className={`pc-u-mb-${showMaskedAccountHelpText ? "-" : ""}`}>
        <strong data-hj-masked>{accountNumber}</strong>
      </div>
      {showMaskedAccountHelpText && (
        <div className="account-information__help-block pc-u-mb-">
          To access your account number contact us at{" "}
          <a
            href={"mailto:" + organizationMailCash}
            className="js-link-cash-support"
            target="_blank"
            rel="noopener noreferrer"
          >
            {organizationMailCash}
          </a>
        </div>
      )}
      <h2 className="pc-delta pc-u-mb0">Account Owners</h2>
      <div>
        <strong data-hj-masked>{primaryOwner}</strong>
      </div>
      {secondaryOwner && (
        <div className="l-spaced l-spaced--flush l-spaced--nowrap pc-u-mb--">
          <strong data-hj-masked>{secondaryOwner}</strong>
          {serviceWithdrawInvite && (
            <RemoveAccount
              service={serviceWithdrawInvite}
              buttonText="Withdraw Invitation"
              className="pc-btn--stripped pc-u-p0"
              modalTitle={null}
              componentName="Confirm Withdraw Invitation Modal"
              modalBody={
                <p>Are you sure you want to withdraw the invitation?</p>
              }
              modalCancelButtonText="Cancel"
              modalConfirmButtonText="Withdraw Invitation"
              onAccountRemoved={onWithdrawnInvitation}
            />
          )}
        </div>
      )}
      {serviceWithdrawInvite && (
        <div className="account-information__help-block">
          {expirationDate ? (
            <>
              Invite expires{" "}
              {moment(expirationDate).format(FULL_MONTH_DATE_YEAR_FORMAT)}. This
              invitation cannot be withdrawn after the additional account owner
              has completed the application.
            </>
          ) : (
            <>
              This invitation cannot be withdrawn after the additional account
              owner has completed the application.
            </>
          )}
        </div>
      )}
      <div className="u-text-right pc-u-mt">
        <button
          type="button"
          className="pc-btn pc-btn--primary js-action-done"
          onClick={onDone}
        >
          Done
        </button>
      </div>
    </>
  );
};

AccountInformation.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  routingNumber: PropTypes.string.isRequired,
  primaryOwner: PropTypes.string.isRequired,
  secondaryOwner: PropTypes.string,
  expirationDate: PropTypes.string,
  serviceWithdrawInvite: PropTypes.func,
  onWithdrawnInvitation: PropTypes.func,
  onDone: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
};

AccountInformation.defaultProps = {
  loading: false,
  errors: undefined,
  secondaryOwner: undefined,
  expirationDate: undefined,
  serviceWithdrawInvite: undefined,
  onWithdrawnInvitation: undefined,
};

export default AccountInformation;
