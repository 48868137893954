var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-val=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"retirementAge") : depth0), depth0))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"questionnaire401kForm\">\n	<div class=\"row\">\n		<div class=\"formElement retirement-fee__birth-date u-preserve-case\">\n			<label>Date of birth</label>\n			<input type=\"text\" id=\"birthMonth\" placeholder=\"MM\" class=\"input-small\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"birthday") : depth0)) != null ? lookupProperty(stack1,"month") : stack1), depth0))
    + "\" maxlength=\"2\" data-hj-masked>\n			<input type=\"text\" id=\"birthDay\" placeholder=\"DD\" class=\"input-small\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"birthday") : depth0)) != null ? lookupProperty(stack1,"day") : stack1), depth0))
    + "\" maxlength=\"2\" data-hj-masked>\n			<input type=\"text\" id=\"birthYear\" placeholder=\"YYYY\" class=\"input-small\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"birthday") : depth0)) != null ? lookupProperty(stack1,"year") : stack1), depth0))
    + "\" maxlength=\"4\" data-hj-masked>\n		</div>\n		<div class=\"formElement u-preserve-case\" data-is_required=\"true\">\n			<label>Retirement age</label>\n			<div class=\"slider retirement\"\n				data-max=\"80\"\n				data-min=\"18\"\n				"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"retirementAge") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":14,"column":60}}})) != null ? stack1 : "")
    + "\n				data-property= \"retirementAge\"\n				data-formatter=\"none\">\n			</div>\n		</div>\n	</div>\n	<div class=\"row\">\n		<div class=\"formElement retirement-fee__company-size u-preserve-case\" data-is_required=\"true\">\n			<label>Your company size <span title=\"<p>Company size is a relatively good indicator of administrative fees.</p>\"\n			  class=\"toolTipped\">Tip</span></label>\n			<select class=\"companySize u-preserve-case\">\n				<option value=\"\">Select your company size</option>\n				<option value=\"LESS_THAN_500\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"companySize") : depth0),"==","LESS_THAN_500",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":34},"end":{"line":26,"column":110}}})) != null ? stack1 : "")
    + ">1-499 Employees</option>\n				<option value=\"LESS_THAN_1000\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"companySize") : depth0),"==","LESS_THAN_1000",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":35},"end":{"line":27,"column":112}}})) != null ? stack1 : "")
    + ">500-999 Employees</option>\n				<option value=\"LESS_THAN_5000\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"companySize") : depth0),"==","LESS_THAN_5000",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":35},"end":{"line":28,"column":112}}})) != null ? stack1 : "")
    + ">1000-4999 Employees</option>\n				<option value=\"LESS_THAN_10000\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"companySize") : depth0),"==","LESS_THAN_10000",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":36},"end":{"line":29,"column":114}}})) != null ? stack1 : "")
    + ">5000-9999 Employees</option>\n				<option value=\"GREATER_THAN_10000\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"companySize") : depth0),"==","GREATER_THAN_10000",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":39},"end":{"line":30,"column":120}}})) != null ? stack1 : "")
    + ">More than 10,000 Employees</option>\n			</select>\n		</div>\n		<div class=\"formElement\" data-is_required=\"true\">\n			<label class=\"u-preserve-case\">Risk tolerance</label>\n			<select class=\"riskTolerance u-preserve-case\">\n			  <option value=\"\">Select a Risk tolerance</option>\n			  <option value=\"HIGH_RISK\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"userRiskTolerance") : depth0),"==","HIGH_RISK",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":31},"end":{"line":37,"column":109}}})) != null ? stack1 : "")
    + ">Very aggressive</option>\n			  <option value=\"MEDIUM_RISK\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"userRiskTolerance") : depth0),"==","MEDIUM_RISK",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":33},"end":{"line":38,"column":113}}})) != null ? stack1 : "")
    + ">Aggressive</option>\n			  <option value=\"SOME_RISK\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"userRiskTolerance") : depth0),"==","SOME_RISK",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":31},"end":{"line":39,"column":109}}})) != null ? stack1 : "")
    + ">Moderate</option>\n			  <option value=\"LOW_RISK\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"userRiskTolerance") : depth0),"==","LOW_RISK",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":30},"end":{"line":40,"column":107}}})) != null ? stack1 : "")
    + ">Conservative</option>\n			  <option value=\"NO_RISK\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"userRiskTolerance") : depth0),"==","NO_RISK",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":29},"end":{"line":41,"column":105}}})) != null ? stack1 : "")
    + ">Very conservative</option>\n			</select>\n		</div>\n	</div>\n	<div class=\"row actions\">\n		<input class=\"spouseContributing\" type=\"checkbox\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"secondPersonContributing") : depth0),"==",true,{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":52},"end":{"line":46,"column":118}}})) != null ? stack1 : "")
    + "/> <span>My spouse contributes to a 401k</span>\n		<span title=\"<p>The government allows a maximum contribution amount per individual. If your spouse contributes, we'll tell our system to allow a higher maximum.\n					You can also add their 401k to see fees on your retirement together.</p>\"\n			  class=\"toolTipped\">Tip</span>\n	</div>\n	<div class=\"row actions\">\n		<a class=\"button submitForm a\" href=\"#\">Submit</a>\n		<input type=\"submit\" class=\"offscreen\" />\n	</div>\n</form>\n";
},"useData":true});