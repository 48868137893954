import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import { MAX_INTEREST_DIGITS } from "utils/account";
import { POSITIVE_CURRENCY_FORMAT } from "components/common/form/formattingOptions";

const SimpleInterestRateAccountDetails = (props) => {
  const { model, onInputChange, storeInputRef, schema } = props;
  const fieldName =
    model.productType === "CREDIT_CARD" ? "apr" : "interestRate";

  return (
    <div>
      <div className="pc-u-mb-">
        <div className="u-text-bold">{model.firmName}</div>
        <div className="edit-account__text--info">{model.name}</div>
      </div>
      <div className="pc-layout">
        <div className="pc-layout__item pc-u-1/3">
          <div>Interest Rate</div>
          <div>
            <Input
              className="input--tiny qa-interest-rate"
              type="text"
              name={fieldName}
              sizeVariation="full"
              onChange={onInputChange}
              value={model[fieldName]}
              ref={storeInputRef}
              formattingOptions={POSITIVE_CURRENCY_FORMAT}
              validator={schema.properties.interestRate}
              maxLength={MAX_INTEREST_DIGITS}
              suffix={"%"}
              ariaLabel="Interest rate"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SimpleInterestRateAccountDetails.propTypes = {
  model: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  storeInputRef: PropTypes.func.isRequired,
  schema: PropTypes.object,
};

SimpleInterestRateAccountDetails.defaultProps = {
  schema: {
    type: "object",
    properties: {
      interestRate: {
        type: "number",
        exclusiveMinimum: 0,
        maximum: 100,
        messages: {
          type: "numbers only",
          exclusiveMinimum: "positive numbers only",
          maximum: "100 maximum",
        },
      },
    },
  },
};

export default SimpleInterestRateAccountDetails;
