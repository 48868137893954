import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { noop } from "underscore";
import Message from "components/common/Message";
import DocumentUploaderContainer from "components/common/DocumentUploader/DocumentUploaderContainer";
import { DOCUMENT_ENDPOINTS } from "components/common/DocumentUploader/utils/constants";
import AccountBox from "components/AccountBox/AccountBox";
import { trackEvent } from "components/common/ComponentAnalytics";
import { getSource } from "components/common/attributionStore";
import {
  TRANSFER_TYPE_CONTRIBUTE,
  TRANSFER_TYPE_WITHDRAW,
} from "components/transferFunds/utils/constants";

const UploadStatement = (props) => {
  const {
    userAccountId,
    firmName,
    accountName,
    logoPath,
    balance,
    onMounted,
    className,
    onBack,
    onContinue,
    showAccountBox,
    transferType,
    uploadAPIEndpoint,
    statementFetchCopy,
    requestParams,
    CustomUploader,
    isYodleeAllowed,
  } = props;

  useEffect(() => onMounted("Upload A Recent Statement"), [onMounted]);

  const [errors, setErrors] = useState();
  const [statements, setStatements] = useState(props.statements ?? []);

  function handleSelectClick() {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "upload_statements.select_files_button.click"
      );
    }
  }

  const handleUpload = useCallback(() => {
    trackEvent("Transfer Funds", "Statement Upload Initiated", {
      source: getSource(),
    });
  }, []);

  const handleUploaded = useCallback(
    (_, statements) => {
      setErrors(undefined);
      setStatements(statements);
      trackEvent("Transfer Funds", "Statement Upload Completed", {
        source: getSource(),
      });
    },
    [setStatements]
  );

  const handleContinue = useCallback(() => {
    if (statements.some((f) => f.complete)) {
      setErrors(undefined);
      onContinue(statements);
      if (IS_EMPOWER) {
        window.dashboardUtils?.eventBus.dispatch(
          "upload_statements.continue_button.click"
        );
        window.dashboardUtils?.eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
          event_properties: {
            selection: "upload_statements.continue_button.click",
          },
        });
      }
    } else {
      setErrors(["Please upload a statement."]);
    }
  }, [statements, onContinue, setErrors]);

  const uploadRequestParams = Object.assign(
    {},
    { userAccountId },
    requestParams
  );

  return (
    <>
      <div className={className}>
        <Message severity="error" messages={errors} />
        {showAccountBox && (
          <div>
            <div className="pc-layout--middle pc-layout--small pc-form-group pc-u-pt">
              <div className="pc-layout__item pc-u-2/7">
                {transferType === TRANSFER_TYPE_WITHDRAW && (
                  <label className="pc-label js-upload-statement-label">
                    To
                  </label>
                )}
                {transferType === TRANSFER_TYPE_CONTRIBUTE && (
                  <label className="pc-label js-upload-statement-label">
                    From
                  </label>
                )}
              </div>
              <div className="pc-layout__item pc-u-5/7">
                <AccountBox
                  showLogo={true}
                  logoPath={logoPath}
                  firmName={firmName}
                  name={accountName}
                  balance={balance}
                />
              </div>
            </div>
            <hr className="transfer-funds__divider pc-u-mv" />
          </div>
        )}
        <div className="pc-layout--right pc-layout--small">
          <div className="pc-layout__item pc-u-5/7">
            <CustomUploader
              uploadAPIEndpoint={uploadAPIEndpoint}
              files={statements}
              requestParams={uploadRequestParams}
              onSelectButtonClick={handleSelectClick}
              onFileUpload={handleUpload}
              onFileUploaded={handleUploaded}
              multiple={false}
              statementFetchCopy={statementFetchCopy}
              isYodleeAllowed={isYodleeAllowed}
            />
          </div>
        </div>
      </div>
      <div className="account-number-form-view__buttons pc-u-mt">
        <button
          type="button"
          className="pc-btn js-upload-statement-action-back"
          onClick={onBack}
        >
          Back
        </button>
        <button
          type="button"
          className="pc-btn pc-btn--primary js-upload-statement-action-continue"
          disabled={!statements.some((f) => f.complete)}
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>
    </>
  );
};

UploadStatement.propTypes = {
  statements: PropTypes.array,
  userAccountId: PropTypes.number.isRequired,
  accountName: PropTypes.string.isRequired,
  firmName: PropTypes.string.isRequired,
  logoPath: PropTypes.string,
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  onMounted: PropTypes.func,
  showAccountBox: PropTypes.bool,
  //Not made this PropType as required because it is used in ExternalAccountDetails but not using AccountBox
  transferType: PropTypes.number,
  uploadAPIEndpoint: PropTypes.string,
  statementFetchCopy: PropTypes.string,
  requestParams: PropTypes.object,
  CustomUploader: PropTypes.func,
  isYodleeAllowed: PropTypes.bool,
};

UploadStatement.defaultProps = {
  statements: undefined,
  className: undefined,
  logoPath: undefined,
  balance: undefined,
  onBack: noop,
  onContinue: noop,
  onMounted: noop,
  showAccountBox: true,
  transferType: undefined,
  uploadAPIEndpoint: DOCUMENT_ENDPOINTS.STATEMENT,
  statementFetchCopy: undefined,
  requestParams: undefined,
  CustomUploader: DocumentUploaderContainer,
  isYodleeAllowed: false,
};

export default UploadStatement;
