import React from "react";
import PropTypes from "prop-types";
import Services from "services";
import { subscribify } from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import EmergencyFundSummary from "components/emergencyFundSummary/EmergencyFundSummary";
import Message from "components/common/Message";
import { noop } from "underscore";
import ZeroStateWidget from "../dashboard/ZeroStateWidget";
export default class EmergencyFundSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
  }

  render() {
    const {
      loading,
      titles,
      actions,
      summaries,
      bankBalances,
      emergencyFundBalance,
      recommendationMin,
      recommendationMax,
      plannerStage,
      errors,
    } = this.state;
    const { isInPostLoginAction, chartTitle } = this.props;

    if (errors?.length) {
      return <Message className="pc-u-mb" severity="error" messages={errors} />;
    }

    if (
      !loading &&
      !this.props.validBankOrCCAccount &&
      window.integratedSharedData
    ) {
      const translations = window.integratedSharedData?.translations;
      const zeroStateTranslations = translations?.get("zeroStateDashboard");

      return (
        <ZeroStateWidget
          displayName={zeroStateTranslations?.emergencyFund?.title}
          cta={zeroStateTranslations?.emergencyFund?.cta}
          link={"#/savings-planner/emergency-fund"}
          label={zeroStateTranslations?.emergencyFund?.label}
          tooltip={zeroStateTranslations?.emergencyFund?.tooltip}
          buttonAlign={"center"}
          buttonClass={"btn__emergencyFunds"}
          widgetHeaderClass={"header__emergencyFunds"}
          backgroundName="emergencyfund"
        />
      );
    }

    return (
      <EmergencyFundSummary
        loading={loading}
        isInPostLoginAction={isInPostLoginAction}
        onButtonClick={this.props.onButtonClick}
        bankBalances={bankBalances}
        emergencyFundBalance={emergencyFundBalance}
        chartTitle={chartTitle}
        titles={titles}
        actions={actions}
        summaries={summaries}
        recommendationMin={recommendationMin}
        recommendationMax={recommendationMax}
        plannerStage={plannerStage}
        showAddAccounts={this.props.showAddAccounts}
      />
    );
  }

  handleFailure(errors) {
    this.setState(
      {
        loading: false,
        errors,
      },
      this.props.onDCDashboardComponentLoaded
    );
  }

  handleSuccess(response) {
    const { result } = response;
    const { plannerStage, accountInfos } = response.whatIf;
    this.setState(
      {
        accounts: accountInfos.filter(
          (a) =>
            a.productType === "BANK" &&
            a.accountCategoryType === "TAXABLE" &&
            a.cashBalance > 0
        ),
        emergencyFundBalance: result.emergencyFundCurrent,
        bankBalances: result.cashInBankAccountsHistories,
        titles: result.titles,
        actions: result.actions,
        summaries: result.summaries,
        recommendationMin: result.emergencyFundTargetFrom,
        recommendationMax: result.emergencyFundTargetTo,
        loading: false,
        plannerStage,
        errors: [],
      },
      this.props.onDCDashboardComponentLoaded
    );
  }

  componentDidMount() {
    this.getEmergencyFundSavingsSubscription =
      this.props.fetchEmergencyFundSavings();
    this.getEmergencyFundSavingsSubscription.on("change", this.handleSuccess);
    this.fetchEmergencyFundSavingsCancelablePromise = makeCancelablePromise(
      this.getEmergencyFundSavingsSubscription.promise
    );
    this.fetchEmergencyFundSavingsCancelablePromise.promise
      .then(this.handleSuccess)
      .catch(this.handleFailure);
  }

  componentWillUnmount() {
    if (this.fetchEmergencyFundSavingsCancelablePromise) {
      this.fetchEmergencyFundSavingsCancelablePromise.cancel();
    }

    if (this.getEmergencyFundSavingsSubscription) {
      this.getEmergencyFundSavingsSubscription.unwatch();
      this.getEmergencyFundSavingsSubscription.off("change");
    }
  }
}

EmergencyFundSummaryContainer.propTypes = {
  isInPostLoginAction: PropTypes.bool,
  onButtonClick: PropTypes.func,
  chartTitle: PropTypes.string,
  fetchEmergencyFundSavings: PropTypes.func,
  onDCDashboardComponentLoaded: PropTypes.func,
  showAddAccounts: PropTypes.bool,
  validBankOrCCAccount: PropTypes.bool,
};

EmergencyFundSummaryContainer.defaultProps = {
  isInPostLoginAction: false,
  onButtonClick: undefined,
  chartTitle: undefined,
  fetchEmergencyFundSavings: subscribify(Services.EmergencySavings.get),
  onDCDashboardComponentLoaded: noop,
  showAddAccounts: true,
  validBankOrCCAccount: false,
};
