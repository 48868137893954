// --------------------- DEPRECATED ---------------------
// Import the dependencies directly where they are needed.

import "slimscroll";

import "jquery.colorbox";
import "tiptip";
import "datatables";
import "jqueryDatatableGrouping";
import "placeholder";
import "modal";
import "dropdown";
import "libs/pcap/utils/utils";
import "libs/pcap/utils/dataTablesExt";
import "libs/pcap/utils/nativeEventEmitter";
import "libs/pcap/utils/date";
import "libs/pcap/utils/array";
import "raphael";
import "libs/pcap/visualizations/perspective";
import "libs/pcap/visualizations/percentageWheel";
// temporary fix to set up zendesk sso url corresponding to an evironment
if (typeof zendeskSSOURL != "undefined") {
  $("#menuContainer .suportSubmenu").attr("href", zendeskSSOURL);
}
