import React from "react";
import PropTypes from "prop-types";

export default class Snackbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: props.isOpen };

    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    if (this.state.isOpen) {
      return (
        <div className={this.props.className}>
          <div className="snackbar__message">{this.props.message}</div>
          <div onClick={this.handleClose} className="snackbar__close-button">
            ×
          </div>
        </div>
      );
    }

    return null;
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }
}

Snackbar.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

Snackbar.defaultProps = {
  message: null,
  className: "snackbar",
  isOpen: false,
};
