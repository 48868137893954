import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { timeFormat, select, transition, max, selectAll } from "d3";
import PcapChart from "libs/pcap/chart/chart";
import { formatCurrency } from "libs/pcap/utils/format";

const CHART_XSCALE_PADDING_INNER = 0.2;
const DISPLAY_ALL_TICK_LABELS_LIMIT = 5;
const INTERVAL_DISPLAYED_TICK_LABELS = 2;

const tickMonthFormatter = timeFormat("%b");

function getKeyAccessor(item) {
  return item.date;
}

/**
 * @param {object} item - data point
 * Given the date, parse it and return the 3-letter
 * @returns {function} the X Accessor function for the D3 wrapper
 */
function xAccessor(item) {
  return moment(item.date);
}

/**
 * @param {object} item - data point
 * @returns {function} the Y Accessor function for the D3 wrapper.
 */
function yAccessor(item) {
  return item.aggregateCashBalance;
}

function getPaddingOuter(numberOfDataPoints) {
  return numberOfDataPoints > DISPLAY_ALL_TICK_LABELS_LIMIT ? 0 : 1;
}

/**
 * Tick Format function.
 * @param {object} momentDate - date wrapped in MomentJS
 * @param {int} index - index within the loop
 * @param {list} list - the list that we are looping through
 * @return {string} formatted string
 */
function xAxisTickFormatter(momentDate, index, list) {
  if (
    list.length <= DISPLAY_ALL_TICK_LABELS_LIMIT ||
    index % INTERVAL_DISPLAYED_TICK_LABELS === 0
  ) {
    return tickMonthFormatter(momentDate);
  }
  return "";
}

export default class InvestableCashSummaryChart extends React.Component {
  componentDidMount() {
    this.renderChart();
  }

  /**
   * Post-update operations to re-do up d3 graph
   */
  componentDidUpdate() {
    this.updateChart();
  }

  /**
   * Render the D3 chart
   */
  renderChart() {
    const data = this.props.data;

    this.chart = PcapChart({
      type: "bar",
      showXGrid: false,
      showYGrid: false,
      showXAxis: true,
      showYAxis: false,
      key: getKeyAccessor,
      x: xAccessor,
      y: yAccessor,
      margin: {
        left: 5,
        right: 5,
        bottom: 25,
      },
    });

    this.chart.xAxis.tickFormat(xAxisTickFormatter);
    this.chart.xScale.paddingInner(CHART_XSCALE_PADDING_INNER);
    this.chart.xScale.paddingOuter(getPaddingOuter(data.length));

    const container = select(this.container);

    container.datum([data]).call(this.chart);

    this.chartBody = select(this.container).selectAll(".js-chart-body");

    this.renderBackground(data);
    this.highlightLastBar();
    if (data && data.length > 0) {
      this.attachMouseEvents(data);
    }
  }

  renderBackgroundBars(context) {
    const hasInheritedTransition = context instanceof transition;
    const selection = hasInheritedTransition ? context.selection() : context;

    const xScale = this.chart.xScale;
    const yScale = this.chart.yScale;
    const scaledXAccessor = (d) => {
      return xScale(xAccessor(d));
    };

    const yMax = max(this.props.data, (item) => item.aggregateCashBalance);
    const barHeight = yScale(0) - yScale(yMax);

    const bars = selection.selectAll("rect").data((d) => d);

    // update
    bars
      .transition(transition())
      .attr("x", scaledXAccessor)
      .attr("y", yScale(yMax))
      .attr("height", barHeight)
      .attr("width", xScale.bandwidth());

    // create
    bars
      .enter()
      .append("rect")
      .attr("class", "investable-cash-summary__chart-background-bar")
      .attr("x", scaledXAccessor)
      .attr("y", yScale(yMax))
      .attr("height", barHeight)
      .attr("width", xScale.bandwidth());

    // remove
    bars.exit().remove();
  }

  renderBackground(data) {
    const chartBackground = this.chartBody
      .selectAll(".js-investable-cash-summary__chart-background")
      .data([data]);

    chartBackground.call(this.renderBackgroundBars.bind(this));
    chartBackground.exit().remove();

    chartBackground
      .enter()
      .append("g")
      .classed(
        "investable-cash-summary__chart-background js-investable-cash-summary__chart-background qa-investable-cash-summary__chart-background",
        true
      )
      .call(this.renderBackgroundBars.bind(this))
      .lower();
  }

  /**
   * Update the D3 chart
   */
  updateChart() {
    const data = this.props.data;
    this.chart.xScale.paddingOuter(getPaddingOuter(data.length));
    select(this.container).datum([data]).call(this.chart);

    this.renderBackground(data);
    this.highlightLastBar();
    if (data && data.length > 0) {
      this.attachMouseEvents(data);
    }
  }

  attachMouseEvents(data) {
    const currentMonthBalance = data[data.length - 1].aggregateCashBalance;
    const el = document.querySelector(".js-investable-cash-current-balance");

    let bars = selectAll(".js-investable-cash-summary-chart .js-chart-bar").on(
      "mouseover.investableCash",
      function (ev) {
        el.innerHTML = formatCurrency(ev.aggregateCashBalance, 0);
        bars.classed("chart__bar--active", false);
        this.setAttribute(
          "class",
          this.getAttribute("class") + " chart__bar--active"
        ); /* `this` is the target element */ // eslint-disable-line no-invalid-this, consistent-this
      }
    );
    selectAll(".js-investable-cash-summary-chart").on(
      "mouseleave.investableCash",
      function () {
        el.innerHTML = formatCurrency(currentMonthBalance, 0);
        bars.classed("chart__bar--active", false);
        this.highlightLastBar();
      }.bind(this)
    );
  }

  highlightLastBar() {
    const lastBar = document.querySelector(
      ".js-investable-cash-summary-chart .js-chart-bar:last-child"
    );
    if (lastBar) {
      lastBar.setAttribute(
        "class",
        lastBar.getAttribute("class") + " chart__bar--active"
      );
    }
  }

  render() {
    return (
      <svg
        width="100%"
        height="100%"
        className="summary-widget__body summary-widget__chart investable-cash-summary__chart js-investable-cash-summary-chart"
        ref={(container) => {
          this.container = container;
        }}
      />
    );
  }
}

InvestableCashSummaryChart.propTypes = {
  data: PropTypes.array,
};
