import PropTypes from "prop-types";
import React from "react";
import Message from "components/common/Message";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const isPrivileged = isEmpowerPrivilegedMode();
export default class RemoveCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removing: false,
    };

    this.handleRemove = this.handleRemove.bind(this);
  }

  handleRemove() {
    this.setState({
      removing: true,
    });
    this.props.onRemoveCategory();
  }

  render() {
    if (this.props.errors) {
      return <Message severity="error" messages={this.props.errors} />;
    }

    return (
      <div>
        <p>Are you sure you want to delete this category?</p>
        <p>
          Any transactions assigned to this category will revert to their
          original category.
        </p>
        {!this.state.removing && (
          <div className="pc-layout--right pc-u-mb-">
            <button
              type="button"
              className="pc-btn"
              onClick={this.props.onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="pc-btn pc-btn--danger qa-custom-category-remove-confirmation"
              onClick={this.handleRemove}
              aria-disabled={isPrivileged}
              disabled={isPrivileged}
            >
              Remove
            </button>
          </div>
        )}
        {this.state.removing && (
          <div className="pc-layout--right pc-u-mb-">
            <button type="button" className="pc-btn pc-btn--danger" disabled>
              Removing...
            </button>
          </div>
        )}
      </div>
    );
  }
}

RemoveCategory.propTypes = {
  errors: PropTypes.array,
  onCancel: PropTypes.func,
  onRemoveCategory: PropTypes.func,
};
