export const accounts = [
  {
    userAccountId: 1234,
    firmName: "Home mortgage",
    accountName: "Loan account",
    productType: "LOAN",
    balance: 220000,
    cashBalance: 0,
    interestRate: 3,
    ytdAccountSavings: {
      source: "CALCULATED",
      value: 0,
    },
    accountErrors: {},
    balanceChange: 220000,
    isHighYieldDebt: true,
  },
  {
    userAccountId: 4567,
    firmName: "Credit card",
    accountName: "Credit Card – Ending in 1111",
    productType: "CREDIT CARD",
    balance: 1777.1,
    cashBalance: 0,
    interestRate: 22,
    ytdAccountSavings: {
      source: "CALCULATED",
      value: 0,
    },
    accountErrors: {
      MISSING_APR: "Missing",
    },
    balanceChange: -777.1,
    isHighYieldDebt: true,
  },
];
