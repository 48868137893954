import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Modal from "components/modal/Modal";
import AppointmentContainer from "../AppointmentContainer";
import withAppointmentCustomContent from "components/higherOrderComponents/withAppointmentCustomContent";
import getAppointmentModalTitle from "views/components/postLoginActions/getAppointmentModalTitle";
import { noop, isEmpty } from "underscore";
import useWindowSize from "components/hooks/useWindowSize";
import useSchedulerInteractionTracker from "components/scheduling/AppointmentModal/useSchedulerInteractionTracker";
import eventBus from "eventBus";

const DEFAULT_COMPONENT_NAME = "Appointment Scheduler Modal";
const CONDENSED_STYLE_HEIGHT_THRESHOLD = 850;

export const getApptModalClassName = (windowSize, customContent) => {
  let className = "pc-modal--appointment qa-pc-modal--appointment";
  if (customContent && !isEmpty(customContent.data)) {
    className += " pc-modal--without-title-bar";
  }
  if (windowSize.height < CONDENSED_STYLE_HEIGHT_THRESHOLD) {
    className += " pc-modal--appointment-condensed";
  }
  return className;
};

const AppointmentModal = ({
  isClient,
  onClosed,
  customContent,
  isFirstUseAppointment,
  isMadLibsInterjectionReviewModal,
  componentName,
  advisorData,
  type,
  subtypes,
  isTopicPreselected,
  duration,
  is401kEnrollment,
}) => {
  const [modalTitle, setModalTitle] = useState(
    getAppointmentModalTitle({ customContent, isClient })
  );
  const [eventTrackingSharedProperties, setEventTrackingSharedProperties] =
    useState({});
  const modalEl = useRef();

  // Track whether the user had a meaningful interaction with the scheduler
  const interactedWithScheduler = useSchedulerInteractionTracker();

  useEffect(() => {
    // Pass shared tracking data from child components directly to here
    eventBus.on("eventTrackingSharedProperties", (sharedProps) => {
      setEventTrackingSharedProperties(sharedProps);
    });
    return () => {
      eventBus.off("eventTrackingSharedProperties");
    };
  }, []);
  return (
    <Modal
      ref={modalEl}
      componentName={componentName}
      contentLabel={componentName}
      title={modalTitle}
      className={getApptModalClassName(useWindowSize(), customContent)}
      isOpen={true}
      shouldCloseOnOverlayClick={false}
      onClosed={onClosed}
      shouldModalClose={() => true}
      closeModalEventCustomProps={Object.assign(
        { interactedWithScheduler },
        eventTrackingSharedProperties
      )}
    >
      <AppointmentContainer
        isClient={isClient}
        customContent={Object.assign(
          {
            isFirstUseAppointment,
            isMadLibsInterjectionReviewModal,
          },
          customContent
        )}
        componentName={componentName}
        advisorData={advisorData}
        setModalTitle={setModalTitle}
        type={type}
        subtypes={subtypes}
        isTopicPreselected={isTopicPreselected}
        duration={duration}
        is401kEnrollment={is401kEnrollment}
        onDone={onClosed}
      />
    </Modal>
  );
};

AppointmentModal.propTypes = {
  is401kEnrollment: PropTypes.bool,
  onClosed: PropTypes.func,
  customContent: PropTypes.object,
  isFirstUseAppointment: PropTypes.bool,
  isMadLibsInterjectionReviewModal: PropTypes.bool,
  componentName: PropTypes.string,
  isClient: PropTypes.bool,
  advisorData: PropTypes.object,
  type: PropTypes.string,
  subtypes: PropTypes.array,
  isTopicPreselected: PropTypes.bool,
  duration: PropTypes.number,
};

AppointmentModal.defaultProps = {
  is401kEnrollment: false,
  componentName: DEFAULT_COMPONENT_NAME,
  isClient: false,
  advisorData: {},
  onClosed: noop,
  customContent: {},
  isFirstUseAppointment: false,
  isMadLibsInterjectionReviewModal: false,
  type: undefined,
  subtypes: undefined,
  duration: undefined,
  isTopicPreselected: false,
};

export default withAppointmentCustomContent(AppointmentModal);
