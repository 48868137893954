var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"l-spaced l-spaced--flush\">\n  <div class=\"net-worth-bar__name qa-net-worth-bar-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n  <div class=\"net-worth-bar__value js-net-worth-bar-value tabular-numbers\">"
    + alias2(__default(require("../../helpers/formatCurrency.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),0,{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":3,"column":75},"end":{"line":3,"column":101}}}))
    + "</div>\n</div>\n<div class=\"net-worth-bar "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"className") : depth0), depth0))
    + " qa-net-worth-bar\">\n  <div class=\"net-worth-bar__body\">\n    <div class=\"net-worth-bar__filler-container\">\n      <div class=\"net-worth-bar__filler js-net-worth-bar-filler\"></div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});