import { useEffect, useState } from "react";
import { debounce } from "underscore";

const useWindowSize = () => {
  const [state, setState] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const onWindowResize = debounce(() => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  return state;
};

export default useWindowSize;
