import React from "react";
import PropTypes from "prop-types";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";

const PersonalStrategyTradesWidget = (props) => {
  const {
    errors,
    loading,
    totalTradesYTD,
    thisMonthTrades,
    titleClassName,
    lastTransactionTradeReason,
  } = props;

  if (errors) {
    return <Message severity="error" messages={errors} />;
  }

  const onTradesLinkClicked = () => {
    window.dashboardUtils?.eventBus.dispatch(
      "personal_strategy.trades_button.click"
    );
    window.location.href = "#/strategy/trades";
  };

  return (
    <>
      <div className="summary-widget qa-personal-strategy-trades-widget">
        <LoadingOverlay active={loading} className="pc-overlay--solid" />
        <div className={`summary-widget__header u-preserve-case`}>
          <div className="l-spaced l-spaced--flush">
            <h2 className="personal-strategy-trades-widget-headers__title">
              <button
                onClick={onTradesLinkClicked}
                className={`pc-btn pc-btn--stripped js-personal-strategy-trades-widget-title summary-widget__child-column-title--link
                ${titleClassName}`}
              >
                Trades
              </button>
            </h2>
            <h2 className="personal-strategy-trades-widget-headers__title personal-strategy-trades-widget-headers__title--right u-text-right">
              {totalTradesYTD}
            </h2>
          </div>
          <div className="l-spaced l-spaced--flush">
            <p className="personal-strategy-trades-widget-headers__sub-title">
              {thisMonthTrades}{" "}
              <span
                className={`u-sentence-case personal-strategy-widget__sub-title`}
              >
                This Month
              </span>
            </p>
            <p
              className={`u-sentence-case personal-strategy-trades-widget-headers__sub-title personal-strategy-trades-widget-headers__sub-title--right personal-strategy-widget__sub-title`}
            >
              This Year
            </p>
          </div>
        </div>
        {lastTransactionTradeReason && (
          <div className="js-personal-strategy-trades-widget-body">
            <p className="u-sentence-case personal-strategy-trades-widget-body__title">
              Last Transaction
            </p>
            <h4 className="personal-strategy-trades-widget-body__last-transaction-name u-text-bold">
              {lastTransactionTradeReason.name}
            </h4>
            <p className="personal-strategy-trades-widget-body__last-transaction-description">
              {lastTransactionTradeReason.description}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

PersonalStrategyTradesWidget.propTypes = {
  titleClassName: PropTypes.string,
  errors: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  thisMonthTrades: PropTypes.number,
  totalTradesYTD: PropTypes.number,
  lastTransactionTradeReason: PropTypes.object,
};

PersonalStrategyTradesWidget.defaultProps = {
  titleClassName: "",
  errors: undefined,
  thisMonthTrades: 0,
  totalTradesYTD: 0,
  lastTransactionTradeReason: undefined,
};

export default PersonalStrategyTradesWidget;
