export const HUNDRED_PERCENT = 100;

/***
 * Get an array of all applicable owners. This function prevents undefined from being added to the array, which causes errors.
 *
 * @param {object} stakeholders the assigned stakeholders for the account.
 * @returns {array} as many primary and secondary owners as exist, or an empty array.
 */
export function getAllOwners(stakeholders) {
  let owners = stakeholders.PRIMARY || [];

  if (stakeholders.SECONDARY) {
    owners = owners.concat(stakeholders.SECONDARY);
  }

  return owners;
}

/**
 * Relatives should not be included in selectors when they are already active Stakeholder in another selector. The current selection should remain visible, however.
 *
 * @param {object} relative The person to validate.
 * @param {object} currentStakeholder The Stakeholder already selected in this selector.
 * @param {array} activeStakeholders The Stakeholder selected in all selectors.
 *
 * @returns {boolean} Is the relative valid to include in the selector?
 */
export function isRelativeValidSelectorOption(
  relative,
  currentStakeholder,
  activeStakeholders
) {
  if (relative.id === currentStakeholder.personId) {
    return true;
  }

  const isRelativeAnActiveStakeholder = activeStakeholders.find(
    (activeStakeholder) => relative.id === activeStakeholder.personId
  );

  return !isRelativeAnActiveStakeholder;
}

/**
 * Get the sum of all currently-allocated Stakeholder. Skip values which are empty or otherwise invalid, so the result is always a number.
 *
 * @param {array} activeStakeholders The array which contains the percentage data to total.
 *
 * @returns {number} The total value of all percentage inputs.
 */
export function getTotalPercentage(activeStakeholders) {
  let totalPercentage = 0;

  for (const stakeholder of activeStakeholders) {
    const currentPercentage = parseInt(stakeholder.percentage, 10);

    if (!isNaN(currentPercentage)) {
      totalPercentage += currentPercentage;
    }
  }

  return totalPercentage;
}

/**
 * Generate a string updating the user how close they are to 100%, which can be displayed beneath the percentage inputs. Also includes the option to enable one or the other
 *
 * @param {array} activeStakeholders The array which contains the percentage data to total.
 * @param {boolean} isUnderEnabled Whether to return text for values under 100%
 *
 * @returns {string} The helpful text, or an empty string.
 */
export function getOverUnderText(activeStakeholders, isUnderEnabled = true) {
  const totalPercentage = getTotalPercentage(activeStakeholders);

  if (totalPercentage > HUNDRED_PERCENT) {
    return `Subtract ${totalPercentage - HUNDRED_PERCENT}%`;
  }

  if (isUnderEnabled && totalPercentage < HUNDRED_PERCENT) {
    return `Add ${HUNDRED_PERCENT - totalPercentage}%`;
  }

  return "";
}
