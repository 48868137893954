export const holdingAndAccountNameComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }
    let aVal = a.ticker || "";
    let bVal = b.ticker || "";

    return (
      aVal.localeCompare(bVal) ||
      (a.accountName || "").localeCompare(b.accountName || "")
    );
  };
};

export const numberAndAccountNameComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }

    return (
      accessor(a) - accessor(b) ||
      (a.accountName || "").localeCompare(b.accountName || "")
    );
  };
};
