import React, { useState } from "react";
import PropTypes from "prop-types";
import noop from "utils/noop";
import Modal from "components/modal/Modal";
import TradingBlackoutConfirmModal from "../TradingBlackoutConfirmModal";

const TradingBlackoutModal = ({ isOpen, onClose, modalMsg }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  return (
    <>
      <Modal
        componentName="TradingBlackoutModal"
        isOpen={isOpen}
        onClosed={onClose}
        className="pc-modal pc-modal--smedium"
        shouldCloseOnOverlayClick={false}
        showCloseButton={true}
      >
        <div>
          <div className="pc-u-mt">
            {modalMsg}
            <button
              className="pc-btn pc-btn--link js-link-btn"
              type="button"
              onClick={() => {
                setShowConfirmModal(true);
                onClose();
              }}
              aria-label="Opens the New York Stock Exchange early closure dialog"
            >
              New York Stock Exchange early closure
            </button>
            ).
          </div>
          <div className="l-spaced l-spaced--right">
            <button
              className="pc-btn pc-btn--primary pc-u-ph js-ok-btn qa-ok-btn"
              type="button"
              onClick={onClose}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
      <TradingBlackoutConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />
    </>
  );
};

TradingBlackoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  modalMsg: PropTypes.string,
};

TradingBlackoutModal.defaultProps = {
  onClose: noop,
  modalMsg:
    "Trades are not accepted between 3:50-4:00 pm ET (or 10 minutes before the ",
};

export default TradingBlackoutModal;
