var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"post-login-dialog-body-content\">\n      <ul class=\"get__referral__link__rounded-list\">\n        <li>Copy your personalized referral link below</li>\n        <li>Share your link with family & friends</li>\n        <li>\n          When they become a client<br /><span\n            class=\"get__referral__link__offer-text\"\n            >you both get up to 6 FREE months of wealth management</span\n          >\n        </li>\n      </ul>\n      <div class=\"get__referral__link__invite-link-section\">\n        <div class=\"pc-input-append\">\n          <input\n            class=\"pc-input-control js-invite-link\"\n            value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inviteLink") || (depth0 != null ? lookupProperty(depth0,"inviteLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inviteLink","hash":{},"data":data,"loc":{"start":{"line":29,"column":19},"end":{"line":29,"column":33}}}) : helper)))
    + "\"\n            data-lpignore=\"true\"\n          />\n          <input\n            id=\"js-content-to-copy\"\n            class=\"u-hidden-visually\"\n            value=\"The future of investing is "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":35,"column":46},"end":{"line":35,"column":68}}}) : helper))) != null ? stack1 : "")
    + "! It's free to try and if you become a client we'll both get 6 months free! "
    + alias4(((helper = (helper = lookupProperty(helpers,"inviteLink") || (depth0 != null ? lookupProperty(depth0,"inviteLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inviteLink","hash":{},"data":data,"loc":{"start":{"line":35,"column":144},"end":{"line":35,"column":158}}}) : helper)))
    + "\"\n          />\n          <div class=\"pc-input-append-addon\">\n            <button\n              class=\"get__referral__link__button-clipboard js-action-button js-action-copy-link\"\n              type=\"button\"\n              data-clipboard-target=\"#js-content-to-copy\"\n              data-toggle=\"tooltip\"\n              data-placement=\"bottom\"\n              data-trigger=\"manual\"\n              title=\"Copied\"\n              data-analytics-action=\"Copy Link\"\n            >\n              <img\n                src=\"/static/img/bootstrap/clippy.svg\"\n                width=\"13\"\n                alt=\"Copy to clipboard\"\n              />\n            </button>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"get__referral__link__actions\">\n      <a\n        href=\"mailto:?subject="
    + alias4(((helper = (helper = lookupProperty(helpers,"fullName") || (depth0 != null ? lookupProperty(depth0,"fullName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fullName","hash":{},"data":data,"loc":{"start":{"line":60,"column":30},"end":{"line":60,"column":42}}}) : helper)))
    + " thinks you should try "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":60,"column":65},"end":{"line":60,"column":87}}}) : helper))) != null ? stack1 : "")
    + "&body="
    + alias4(((helper = (helper = lookupProperty(helpers,"fullName") || (depth0 != null ? lookupProperty(depth0,"fullName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fullName","hash":{},"data":data,"loc":{"start":{"line":60,"column":93},"end":{"line":60,"column":105}}}) : helper)))
    + " uses "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":60,"column":111},"end":{"line":60,"column":133}}}) : helper))) != null ? stack1 : "")
    + " and thought you'd be interested in learning more about their free tools and wealth management service."
    + alias4(((helper = (helper = lookupProperty(helpers,"BR") || (depth0 != null ? lookupProperty(depth0,"BR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BR","hash":{},"data":data,"loc":{"start":{"line":60,"column":236},"end":{"line":60,"column":242}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"BR") || (depth0 != null ? lookupProperty(depth0,"BR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BR","hash":{},"data":data,"loc":{"start":{"line":60,"column":242},"end":{"line":60,"column":248}}}) : helper)))
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":60,"column":248},"end":{"line":60,"column":270}}}) : helper))) != null ? stack1 : "")
    + " combines innovative technology and registered financial advisors to help you manage your financial life with confidence. Our free online tools let you see all of your accounts in one place, with simple graphics and analytics that illustrate your finances with unprecedented clarity. If you’d like help with your investments or financial plan, our wealth management costs less than most traditional advisors, and as fiduciaries, our advisors are always legally bound to act in your best interest."
    + alias4(((helper = (helper = lookupProperty(helpers,"BR") || (depth0 != null ? lookupProperty(depth0,"BR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BR","hash":{},"data":data,"loc":{"start":{"line":60,"column":766},"end":{"line":60,"column":772}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"BR") || (depth0 != null ? lookupProperty(depth0,"BR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BR","hash":{},"data":data,"loc":{"start":{"line":60,"column":772},"end":{"line":60,"column":778}}}) : helper)))
    + "It's free to sign up for our tools, and there is never any obligation. If you decide to open a managed account with "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":60,"column":894},"end":{"line":60,"column":916}}}) : helper))) != null ? stack1 : "")
    + ", both you and "
    + alias4(((helper = (helper = lookupProperty(helpers,"fullName") || (depth0 != null ? lookupProperty(depth0,"fullName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fullName","hash":{},"data":data,"loc":{"start":{"line":60,"column":931},"end":{"line":60,"column":943}}}) : helper)))
    + " will receive up to six months of free portfolio management as a thank you."
    + alias4(((helper = (helper = lookupProperty(helpers,"BR") || (depth0 != null ? lookupProperty(depth0,"BR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BR","hash":{},"data":data,"loc":{"start":{"line":60,"column":1018},"end":{"line":60,"column":1024}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"BR") || (depth0 != null ? lookupProperty(depth0,"BR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BR","hash":{},"data":data,"loc":{"start":{"line":60,"column":1024},"end":{"line":60,"column":1030}}}) : helper)))
    + "To get started, just click the link below!"
    + alias4(((helper = (helper = lookupProperty(helpers,"BR") || (depth0 != null ? lookupProperty(depth0,"BR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BR","hash":{},"data":data,"loc":{"start":{"line":60,"column":1072},"end":{"line":60,"column":1078}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"BR") || (depth0 != null ? lookupProperty(depth0,"BR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BR","hash":{},"data":data,"loc":{"start":{"line":60,"column":1078},"end":{"line":60,"column":1084}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"inviteLink") || (depth0 != null ? lookupProperty(depth0,"inviteLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inviteLink","hash":{},"data":data,"loc":{"start":{"line":60,"column":1084},"end":{"line":60,"column":1098}}}) : helper)))
    + "\"\n        class=\"pc-btn pc-btn--primary js-action-button\"\n        data-analytics-action=\"Email Link\"\n        >Email Link</a\n      >\n      <div class=\"get__referral__link__terms\">\n        <a\n          class=\"js-action-button\"\n          data-analytics-action=\"Terms and Conditions\"\n          href="
    + alias4(((helper = (helper = lookupProperty(helpers,"termsUrl") || (depth0 != null ? lookupProperty(depth0,"termsUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"termsUrl","hash":{},"data":data,"loc":{"start":{"line":69,"column":15},"end":{"line":69,"column":27}}}) : helper)))
    + "\n          target=\"blank\"\n          >Terms and Conditions</a\n        >\n      </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"get__referral__link post-login-dialog\">\n  <div class=\"post-login-dialog-header\">\n    <div class=\"post-login-dialog__logo-wrapper\">\n      <svg class=\"icon-logo-text icon-logo-text--white\">\n        <use xlink:href=\"#emp-logo-proposal-admin-light\"></use>\n      </svg>\n    </div>\n    <a href=\"#\" class=\"post-login-dialog-header-close-button js-close\">×</a>\n  </div>\n  <div class=\"post-login-dialog-body\">\n    <h2 class=\"post-login-dialog-body-title\">Refer A Friend</h2>\n    <div class=\"error js-error-block\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"inviteLink") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":75,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"useData":true});