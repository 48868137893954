import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { promisify } from "utils/service";
import Services from "services";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import RequiredMinimumDistribution from "components/transferFunds/RequiredMinimumDistribution";

const RequiredMinimumDistributionContainer = (props) => {
  const { userAccountId, handleModalShow, getPerson } = props;
  const { getRequiredMinimumDistributionsService } = props;
  const cancelableService = useRef();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [outstandingRmdValue, setOutstandingRmdValue] = useState("");
  const [cachedRmdValues, setCachedRmdValues] = useState({});
  const [userId, setUserId] = useState();

  const handleFailure = () => {
    if (cancelableService.current.isCanceled()) {
      return;
    }
    setLoading(false);
    setError(true);
  };

  useEffect(() => {
    getPerson().then((response) => {
      setUserId(response.id);
    });
  }, [getPerson]);

  useEffect(() => {
    if (!userId) return;
    const previousRmdValueForUserAccountId = cachedRmdValues[userAccountId];

    // Early return to avoid API calls if cached value already exists
    if (previousRmdValueForUserAccountId) {
      return setOutstandingRmdValue(previousRmdValueForUserAccountId);
    }
    setLoading(true);

    const requestParams = {
      userAccountId: userAccountId,
      userId,
    };

    const handleSuccess = ({ outstandingRmd }) => {
      setLoading(false);
      if (parseFloat(outstandingRmd) === 0) {
        return;
      }

      handleModalShow();
      setOutstandingRmdValue(outstandingRmd);
      setCachedRmdValues({
        ...cachedRmdValues,
        [userAccountId]: outstandingRmd,
      });
    };

    cancelableService.current = makeCancelablePromise(
      getRequiredMinimumDistributionsService(requestParams)
    );

    cancelableService.current.promise.then(handleSuccess, handleFailure);

    return () => {
      if (cancelableService.current) {
        cancelableService.current.cancel();
      }
    };
  }, [
    cachedRmdValues,
    cancelableService,
    getRequiredMinimumDistributionsService,
    handleModalShow,
    userAccountId,
    userId,
  ]);

  const initialState = !error && outstandingRmdValue === undefined;
  const displayableRmdValue =
    outstandingRmdValue && parseFloat(outstandingRmdValue) !== 0;

  return (
    (initialState || loading || displayableRmdValue) && (
      <RequiredMinimumDistribution
        loading={loading}
        outstandingRmd={outstandingRmdValue}
        handleModalShow={handleModalShow}
      />
    )
  );
};

export default RequiredMinimumDistributionContainer;

RequiredMinimumDistributionContainer.propTypes = {
  getPerson: PropTypes.func,
  userAccountId: PropTypes.number.isRequired,
  handleModalShow: PropTypes.func,
  getRequiredMinimumDistributionsService: PropTypes.func,
};

RequiredMinimumDistributionContainer.defaultProps = {
  handleModalShow: () => null,
  getPerson: promisify(Services.Person.get),
  getRequiredMinimumDistributionsService: promisify(
    Services.Transfer.getRequiredMinimumDistributions
  ),
};
