import PropTypes from "prop-types";
import React from "react";
import FastlinkContainer from "views/components/FastlinkContainer";
import Modal from "components/modal/Modal";
import ConnectionWarning from "svg-icons/ConnectionWarning.svg";

const FastlinkContainerModal = ({
  onCancel,
  isOpen,
  onSuccess,
  onClose,
  siteId,
  flow,
  userSiteId,
  title,
  shouldModalClose,
  belowSuccessThresholdWarning,
}) => {
  const handleOnCancel = () => {
    window.fastlink?.close();
    onCancel();
  };

  return (
    <Modal
      componentName="FastlinkContainerModal"
      title={title}
      isOpen={isOpen}
      onClosed={handleOnCancel}
      className="pc-modal pc-modal--small"
      shouldCloseOnOverlayClick={false}
      shouldModalClose={shouldModalClose}
    >
      <div className="fastlink__container">
        <FastlinkContainer
          onSuccess={onSuccess}
          onClose={onClose}
          siteId={siteId}
          flow={flow}
          userSiteId={userSiteId}
        />
        {belowSuccessThresholdWarning && (
          <div className="fastlink__connectivity-message-container">
            <div className="fastlink__connectivity-icon">
              <ConnectionWarning />
            </div>
            <div className="fastlink__connectivity-message">
              {belowSuccessThresholdWarning}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

FastlinkContainerModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  flow: PropTypes.string,
  userSiteId: PropTypes.number,
  title: PropTypes.string,
  shouldModalClose: PropTypes.func,
  belowSuccessThresholdWarning: PropTypes.string,
};

FastlinkContainerModal.defaultProps = {
  flow: "ADD",
  userSiteId: undefined,
  title: "Link Your Account",
  shouldModalClose: undefined,
  belowSuccessThresholdWarning: "",
};
export default FastlinkContainerModal;
