import PropTypes from "prop-types";
import React from "react";
import NetWorthSummaryContainer from "components/netWorthSummary/NetWorthSummaryContainer";
import WidgetRowZeroState from "components/dashboard/WidgetRowZeroState";
import getZeroStateUserMessage from "libs/pcap/utils/getZeroStateUserMessage";

const DISPLAY_LOCATION__NET_WORTH = "#/dashboard/net-worth";

export default class FirstRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let zeroStateMessage = getZeroStateUserMessage(
      this.props.messages,
      DISPLAY_LOCATION__NET_WORTH
    );

    if (zeroStateMessage) {
      return (
        <div className="dashboard__row">
          <div className="dashboard__cell">
            <div className="dashboard__item">
              <div className="pc-layout pc-layout--center">
                <div className="pc-layout__item pc-u-1/2">
                  <div className="dashboard-first-row__zero-state" />
                </div>
                <div className="pc-layout__item pc-u-1/2">
                  <WidgetRowZeroState
                    message={zeroStateMessage}
                    widgetName="Dashboard Net Worth Row"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="dashboard__row">
        <div className="dashboard__cell">
          <div className="dashboard__item">
            <NetWorthSummaryContainer
              ace={this.props.dashboardMessages[DISPLAY_LOCATION__NET_WORTH]}
            />
          </div>
        </div>
      </div>
    );
  }
}

FirstRow.propTypes = {
  messages: PropTypes.array,
  dashboardMessages: PropTypes.object,
};
