import React from "react";
import PropTypes from "prop-types";
import retirementPlanWidgetData from "./retirementPlanWidgetData";
import InterviewChart from "../planner/interview/InterviewChart/InterviewChart";
import Mixpanel from "mixpanel";

const BUTTON_TEXT = "Go to Retirement Planner";

export const MarRetirementChartWidget = ({
  age,
  retirementAge,
  lifeExpectancy,
}) => {
  function onHandleCreateRP() {
    Mixpanel.trackEvent("Click_Retirement_Planner_Widget", {
      component: "Manual Account Recapture Retirement Chart",
    });
    window.location.href = "#/retirement-planner";
  }
  return (
    <React.Fragment>
      <h2 className="dashboard__retirement-planner-title">
        Create your retirement plan
      </h2>
      <InterviewChart
        age={age}
        retirementAge={retirementAge}
        lifeExpectancy={lifeExpectancy}
        timeSeries={retirementPlanWidgetData.timeSeries}
      />
      <p className="dashboard__retirement-planner-description">
        We have started working on your retirement plan with some basic
        information. Add income events and spending goals to tailor your plan to
        your future needs.
      </p>
      <div className="dashboard__button-container">
        <button
          // type="button"
          className="pc-btn pc-btn--primary"
          onClick={onHandleCreateRP}
        >
          {BUTTON_TEXT}
        </button>
      </div>
    </React.Fragment>
  );
};

MarRetirementChartWidget.propTypes = {
  age: PropTypes.number,
  retirementAge: PropTypes.number,
  lifeExpectancy: PropTypes.number,
};

MarRetirementChartWidget.defaultProps = {
  age: 35,
  retirementAge: 65,
  lifeExpectancy: 99,
};

export default MarRetirementChartWidget;
