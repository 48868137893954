import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Question from "./Question";
import Mixpanel from "mixpanel";
import splunkLog from "libs/pcap/utils/splunkLog";
import { defer } from "underscore";
import { trackButtonClick } from "components/common/ComponentAnalytics";

const UserQuiz = ({
  componentName,
  plaDetails,
  jsonContent,
  saveState,
  modalState,
  setOnBeforeClose,
  onTitleChange,
  onRequestClose,
  getButtons,
}) => {
  const constants = {
    ACTION_BUTTON_CLICKED: "--button-clicked",
    SUBMIT_BUTTON: jsonContent.submitLabel,
    NEXT_QUESTION: jsonContent.nextQuestionLabel,
  };
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentValue, setCurrentValue] = useState("");
  const [numOfCorrectAnswers, setNumOfCorrectAnswers] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  useEffect(() => {
    if (!modalState || !modalState.model) {
      saveState({
        model: {
          "form-name": componentName,
          userGuid: window.userGuid,
          numOfCorrectAnswers,
        },
      });
    }
  }, []);

  useEffect(() => {
    onTitleChange(jsonContent.modalTitleHtml);
  }, []);

  useEffect(() => {
    if (modalState.currentQuestion !== undefined) {
      setCurrentQuestion(modalState.currentQuestion);
    }
    if (modalState.isSubmitted !== undefined) {
      setIsSubmitted(modalState.isSubmitted);
    }
    if (modalState.currentValue !== undefined) {
      setCurrentValue(modalState.currentValue);
    }
  }, []);

  useEffect(() => {
    if (setOnBeforeClose) {
      setOnBeforeClose(({ onDone }) => {
        Mixpanel.trackEvent(
          `Splunk Log ${componentName} Answers`,
          Object.assign({}, plaDetails, modalState.model)
        );
        splunkLog(modalState.model, { onDone });
      });
    }
  }, [componentName, JSON.stringify(modalState), isComplete]);

  const handleSubmit = () => {
    setIsSubmitted(true);
    $(".user-quiz-pla").addClass("user-quiz-pla__black-close-button");
    onTitleChange("");
    const answerIndex = jsonContent.questions[
      currentQuestion
    ].choices.findIndex((choice) => choice.text === currentValue);
    let newNumOfCorrectAnswers = numOfCorrectAnswers;
    if (jsonContent.questions[currentQuestion].choices[answerIndex].isCorrect) {
      newNumOfCorrectAnswers = numOfCorrectAnswers + 1;
    }
    setNumOfCorrectAnswers(newNumOfCorrectAnswers);
    saveState({
      currentQuestion,
      isSubmitted: true,
      currentValue,
      model: {
        ...modalState.model,
        [currentQuestion + 1]: answerIndex + 1,
        numOfCorrectAnswers: newNumOfCorrectAnswers,
        [`question_${currentQuestion + 1}_submit${
          constants.ACTION_BUTTON_CLICKED
        }`]: constants.SUBMIT_BUTTON,
      },
    });

    Mixpanel.trackEvent(
      `Click on ${componentName} - ${constants.SUBMIT_BUTTON}`,
      {
        component: componentName,
        question: currentQuestion,
      }
    );
  };

  const handleNextQuestion = () => {
    saveState({
      currentQuestion: currentQuestion + 1,
      isSubmitted: false,
      currentValue,
      model: {
        ...modalState.model,
        [`question_${currentQuestion + 1}_next_question${
          constants.ACTION_BUTTON_CLICKED
        }`]: constants.NEXT_QUESTION,
      },
    });
    setCurrentQuestion(currentQuestion + 1);
    setIsSubmitted(false);
  };

  const handleCtaClick = (ev) => {
    saveState({
      currentQuestion,
      isSubmitted: true,
      currentValue,
      model: {
        ...modalState.model,
        [`question_${currentQuestion + 1}_CTA${
          constants.ACTION_BUTTON_CLICKED
        }`]: jsonContent.ctaText,
      },
    });
    setIsComplete(true);
    trackButtonClick(ev, componentName, jsonContent.ctaText);
  };

  useEffect(() => {
    if (isComplete) {
      defer(onRequestClose);
    }
  }, [isComplete]);

  return (
    <div className="user-quiz pc-u-mb-- pc-u-ml- pc-u-mr-">
      <Question
        question={jsonContent.questions[currentQuestion]}
        handleSubmit={handleSubmit}
        submitLabel={jsonContent.submitLabel}
        handleNextQuestion={handleNextQuestion}
        nextQuestionLabel={jsonContent.nextQuestionLabel}
        correctLabel={jsonContent.correctLabel}
        incorrectLabel={jsonContent.incorrectLabel}
        isSubmitted={isSubmitted}
        showCta={currentQuestion === jsonContent.questions.length - 1}
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        handleCtaClick={handleCtaClick}
        aboveCtaHtmlContent={jsonContent.aboveCtaHtmlContent}
        ctaText={jsonContent.ctaText}
        ctaUrl={jsonContent.ctaUrl}
        isInterjection={plaDetails.is_interjection}
        getButtons={getButtons}
      />
    </div>
  );
};

UserQuiz.propTypes = {
  componentName: PropTypes.string,
  plaDetails: PropTypes.object,
  jsonContent: PropTypes.object,
  saveState: PropTypes.func.isRequired,
  modalState: PropTypes.object,
  setOnBeforeClose: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getButtons: PropTypes.func,
};

UserQuiz.defaultProps = {
  componentName: "Market Survey",
  plaDetails: {},
  jsonContent: {},
  modalState: {},
  getButtons: () => null,
};

export default UserQuiz;
