/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import PlanList from "components/sidebar/PlanList";
import CopyPlanModalContainer from "components/financialPlanner/CopyPlanModalContainer";
import eventBus from "eventBus";
import { trackClick } from "components/common/ComponentAnalytics";
import {
  MAX_PLAN_COUNT,
  MIN_PLAN_COUNT_TO_COMPARE,
  VIEW_PLAN_LIST,
  COMPARE_PLAN_LIST,
} from "components/sidebar/constants";
import RetirementPlannerEvents from "../../../financialPlanner/RetirementPlannerEvents";

export default class PlannerControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isComparisonView: false,
      isModalOpen: false,
      analyticsEventBus: null,
      AMPLITUDE_EVENTS: null,
    };

    this.onClosed = this.onClosed.bind(this);
    this.openCopyPlanModal = this.openCopyPlanModal.bind(this);
    this.toggleComparisonView = this.toggleComparisonView.bind(this);
  }

  render() {
    const {
      plans,
      disabled,
      activePlanId,
      isComparisonDisabled,
      isNewScenarioDisabled,
      isGLWB,
    } = this.props;

    const { isModalOpen, isComparisonView } = this.state;
    let compareButtonClassName = `js-compare-plan-btn pc-btn pc-u-mb centi${
      isComparisonView ? " pc-btn--primary" : ""
    }`;

    return (
      <div>
        <PlanList
          plans={plans}
          errors={this.props.errors}
          activePlanId={activePlanId}
          disabled={disabled}
          onPlanSelected={this.props.onPlanSelected}
          viewType={isComparisonView ? COMPARE_PLAN_LIST : VIEW_PLAN_LIST}
          isGMWB={this.props.isGLWB}
          accounts={this.props.accounts}
          maModelPortfolios={this.props.maModelPortfolios}
        />
        <div className="planner-control__buttons-container">
          <button
            type="button"
            className={compareButtonClassName}
            disabled={
              disabled ||
              isComparisonDisabled ||
              plans.length < MIN_PLAN_COUNT_TO_COMPARE
            }
            title={
              plans.length < MIN_PLAN_COUNT_TO_COMPARE
                ? `Enter at least ${MIN_PLAN_COUNT_TO_COMPARE} scenarios`
                : ""
            }
            onClick={this.toggleComparisonView}
          >
            {isComparisonView ? "Done Comparing" : "Compare Scenarios"}
          </button>
          <br />
          {!isComparisonView && (
            <button
              type="button"
              className="pc-btn js-new-scenario-btn qa-new-scenario-btn pc-u-mb centi"
              disabled={
                disabled ||
                isNewScenarioDisabled ||
                plans.length >= MAX_PLAN_COUNT
              }
              onClick={this.openCopyPlanModal}
              title={
                plans.length >= MAX_PLAN_COUNT
                  ? `A maximum of ${MAX_PLAN_COUNT} scenarios are allowed`
                  : ""
              }
            >
              New Scenario
            </button>
          )}
        </div>
        {isModalOpen && (
          <CopyPlanModalContainer
            onClosed={this.onClosed}
            isOpen={true}
            isGLWB={isGLWB}
          />
        )}
      </div>
    );
  }

  onClosed() {
    this.setState({
      isModalOpen: false,
    });
  }

  componentDidMount() {
    eventBus.on("planlist:planmademaster", () => {
      this.setState({ isComparisonView: false });
    });

    const analyticsEventBus = window.dashboardUtils?.eventBus;
    const AMPLITUDE_EVENTS = window.integratedSharedData?.AMPLITUDE_EVENTS ?? {
      SELECT_BUTTON: "select_button",
    };
    this.setState({ analyticsEventBus, AMPLITUDE_EVENTS });
  }

  componentWillUnmount() {
    eventBus.off("planlist:planmademaster");
  }

  openCopyPlanModal(e) {
    this.setState({ isModalOpen: true });

    // Track New Scenario button click on MixPanel
    trackClick(e, "Retirement Planner");

    if (IS_EMPOWER) {
      const { analyticsEventBus, AMPLITUDE_EVENTS } = this.state;
      analyticsEventBus?.dispatch(RetirementPlannerEvents.NEW_SCENARIO);
      analyticsEventBus?.dispatchAmplitude({
        event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
        event_properties: {
          selection: RetirementPlannerEvents.NEW_SCENARIO,
        },
      });
    }
  }

  toggleComparisonView(e) {
    const nextIsComparisonView = !this.state.isComparisonView;
    this.setState({ isComparisonView: nextIsComparisonView });

    eventBus.trigger(
      "planner:viewselected",
      nextIsComparisonView ? "comparison" : "index"
    );

    if (nextIsComparisonView) {
      eventBus.trigger("sidebar:comparisoninitialized", this.getActivePlan());
    }

    // Track Compare Scenarios button click on MixPanel
    trackClick(e, "Retirement Planner");

    if (IS_EMPOWER) {
      const { analyticsEventBus, AMPLITUDE_EVENTS } = this.state;
      analyticsEventBus?.dispatch(RetirementPlannerEvents.COMPARE_SCENARIOS);
      analyticsEventBus?.dispatchAmplitude({
        event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
        event_properties: {
          selection: RetirementPlannerEvents.COMPARE_SCENARIOS,
        },
      });
    }
  }

  getActivePlan() {
    return this.props.plans.find(
      (plan) => plan.planId === this.props.activePlanId
    );
  }
}

PlannerControl.propTypes = {
  errors: PropTypes.array,
  plans: PropTypes.array,
  disabled: PropTypes.bool,
  isComparisonDisabled: PropTypes.bool,
  isNewScenarioDisabled: PropTypes.bool,
  isGLWB: PropTypes.bool,
  onPlanSelected: PropTypes.func,
  activePlanId: PropTypes.number,
  accounts: PropTypes.array,
  maModelPortfolios: PropTypes.array,
};

PlannerControl.defaultProps = {
  errors: undefined,
  plans: undefined,
  disabled: false,
  onPlanSelected: undefined,
  activePlanId: undefined,
  isComparisonDisabled: false,
  isNewScenarioDisabled: false,
  isGLWB: false,
  accounts: [],
  maModelPortfolios: [],
};
