var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"js-report-text\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"js-report-text\">We are having some trouble updating this site. These issues are usually related to problems with a financial institution's website and resolve themselves in a few days. We can notify you when this connection is restored.</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./account2.html"),depth0,{"name":"templates/modules/sidebar/account2","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"js-account-extras sidebar-account__extras qa-user-can-subscribe\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":8,"column":9}}})) != null ? stack1 : "")
    + "  <br>\n  <div class=\"sidebar-account__actions\">\n    <button type=\"button\" class='js-account-cancel pc-btn pc-btn--tiny js-account-action'>Cancel</button>\n    <button class=\"pc-btn pc-btn--tiny pc-btn--primary js-notify-me qa-user-can-subscribe__notify-me\">Notify Me</button>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});