import Services from "services";
import modelToServer from "accessors/person/mappers/toServer";
import modelToClient from "accessors/person/mappers/toClient";
import parseResponseErrors from "libs/pcap/utils/response";
import { isEmpty } from "underscore";

/**
 * Saves the provided person model.
 * Optionally processes the portions of the object specified by `options` argument.
 *
 * @param {Object}  model                     the person object
 * @param {Object}  options                   the options
 * @param {String}  options.relationship      the person relationship with `SELF`
 * @param {String}  options.source            the source from where it is being updated
 * @param {String}  options.comment           a comment explaining the update
 *
 * @returns {Promise} The request promise.
 */
export function updatePerson(model, options = {}) {
  return new Promise((resolve, reject) => {
    Services.Person.update(modelToServer(model, options), (err, response) => {
      let errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        return;
      }
      resolve(modelToClient(response.spData));
    });
  });
}

/**
 * Creates the provided person model.
 * Optionally processes the portions of the object specified by `options` argument.
 *
 * @param {Object}  model                     the person object
 * @param {Object}  options                   the options
 * @param {String}  options.relationship      the person relationship with `SELF`
 * @param {String}  options.source            the source from where it is being updated
 *
 * @returns {Promise} The request promise.
 */
export function createPerson(model, options = {}) {
  return new Promise((resolve, reject) => {
    Services.Person.create(modelToServer(model, options), (err, response) => {
      let errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        return;
      }
      resolve(modelToClient(response.spData));
    });
  });
}

/**
 * Deletes the provided person.
 *
 * @param {Object}  model                     the person object
 *
 * @returns {Promise} The request promise.
 */

export function deletePerson(model) {
  return new Promise((resolve, reject) => {
    Services.Person.delete({ personId: model.id }, (err, response) => {
      let errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        return;
      }
      // Note: This is undefined. response.spHeader.personId returns the self person id every time.
      resolve(response.spData);
    });
  });
}

/**
 * Retrieves the primary (`SELF`) person model.
 *
 * @returns {Promise} The request promise.
 */

export function getPerson() {
  return new Promise((resolve, reject) => {
    Services.Person.get((err, response) => {
      let errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        return;
      }
      resolve(modelToClient(response.spData));
    });
  });
}

/**
 * Retrieves all persons.
 *
 * @returns {Promise} The request promise.
 */

export function getPersons() {
  return new Promise((resolve, reject) => {
    Services.Person.getAll((err, response) => {
      let errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        return;
      }
      let persons = response.spData;
      if (!isEmpty(persons)) {
        persons = persons.map(modelToClient);
      }
      resolve(persons);
    });
  });
}

/**
 * Retrieves all persons along with spHeader.
 *
 * @returns {Promise} The request promise.
 */

export function getPersonsWithHeader() {
  return new Promise((resolve, reject) => {
    Services.Person.getAll((err, response) => {
      let errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        return;
      }
      let persons = response.spData;
      if (!isEmpty(persons)) {
        persons = persons.map(modelToClient);
      }
      resolve({ persons: persons, personsHeader: response.spHeader });
    });
  });
}
