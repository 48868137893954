import React from "react";
export default function ChangeJobs3() {
  return (
    <svg
      width="110px"
      height="110px"
      viewBox="0 0 110 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ACE_ChangeJobs3</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="ACE_ChangeJobs_DC_A-With-web"
          transform="translate(-75.000000, -62.000000)"
        >
          <g id="ACE_ChangeJobs3" transform="translate(75.000000, 62.000000)">
            <g filter="url(#filter-1)" id="circle">
              <g transform="translate(0.000000, -0.000611)">
                <path
                  d="M110,54.9992369 C110,85.3747971 85.3759816,110 55,110 C24.6255445,110 0,85.3747971 0,54.9992369 C0,24.6252029 24.6255445,0 55,0 C85.3759816,0 110,24.6252029 110,54.9992369"
                  id="Fill-1"
                  fill="#FDF8E7"
                ></path>
              </g>
            </g>
            <g
              transform="translate(34.239174, 21.006333)"
              strokeLinecap="square"
              strokeWidth="1.5"
            >
              <path
                d="M13.2012454,42.4691667 L13.2012454,38.6925 C13.2012454,35.8218056 13.7974809,33.4002778 14.9915086,31.4233333 C16.1839796,29.4479167 18.3027172,27.5229167 21.344608,25.6483333 C23.7622417,24.1556944 25.4980187,22.8097222 26.5597226,21.6088889 C27.618313,20.4095833 28.149165,19.0315278 28.149165,17.4808333 C28.149165,16.2509722 27.580951,15.2777778 26.4476366,14.5597222 C25.3127654,13.8431944 23.8369658,13.4826389 22.0171243,13.4826389 C17.5118879,13.4826389 12.2469573,15.0516667 6.22077554,18.1836111 L0,6.23638889 C7.42881404,2.07930556 15.1845457,0 23.2703085,0 C29.922304,0 35.1452024,1.43763889 38.9312199,4.30527778 C42.720351,7.17291667 44.6164733,11.0825 44.6164733,16.0294444 C44.6164733,19.5738889 43.7727144,22.6401389 42.0883102,25.23125 C40.4023493,27.8223611 37.7091707,30.2759722 34.0103312,32.5875 C30.8765921,34.5797222 28.9150863,36.0341667 28.124257,36.9569444 C27.3334277,37.8781944 26.9395698,38.9705556 26.9395698,40.2279167 L26.9395698,42.4691667 L13.2012454,42.4691667 Z"
                stroke="#002157"
              ></path>
              <path
                d="M11.2764789,57.839375 C11.2764789,55.144375 12.075092,53.068125 13.6707614,51.6045139 C15.2664308,50.1409028 17.6155675,49.4060417 20.7181715,49.4060417 C23.7009057,49.4060417 25.9924427,50.1470139 27.5881121,51.6259028 C29.1837815,53.1032639 29.9823946,55.1749306 29.9823946,57.839375 C29.9823946,60.5038194 29.1542032,62.5678472 27.4993773,64.0314583 C25.8414379,65.4950694 23.5825927,66.226875 20.7181715,66.226875 C17.7665723,66.226875 15.4532409,65.5042361 13.7828474,64.054375 C12.112454,62.6029861 11.2764789,60.5328472 11.2764789,57.839375 Z"
                stroke="#DB1830"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
