import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import { generateTimeline } from "./generateTimeline";
import noop from "utils/noop";

const POSSIBLE_IN_PROGRESS_EVENTS = ["In Progress", "Request Initiated"];

class TransferTimeline extends React.Component {
  constructor(props) {
    super(props);

    this.tooltip = React.createRef();

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    if (this.tooltip && this.tooltip.current)
      this.tooltip.current.style.display = "flex";
  }

  handleMouseLeave() {
    if (this.tooltip && this.tooltip.current)
      this.tooltip.current.style.display = "none";
  }

  render() {
    let { loading, errors, timeline, showMessage } = this.props;

    timeline = timeline.map(({ state, ...rest }) => {
      switch (state) {
        case "PAST":
          rest.className = "success";
          break;
        case "CURRENT":
          rest.className = POSSIBLE_IN_PROGRESS_EVENTS.includes(rest.label)
            ? "in-progress"
            : "requires-action";
          break;
        case "FUTURE":
          rest.className = "pending";
          break;
        // no default
      }

      return { state, ...rest };
    });

    return (
      <>
        <Message className="pc-u-mb" severity="error" messages={errors} />
        <LoadingOverlay active={loading} />
        {!errors &&
          generateTimeline(
            timeline,
            this.handleMouseEnter,
            this.handleMouseLeave,
            this.tooltip,
            showMessage
          )}
      </>
    );
  }
}

TransferTimeline.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  timeline: PropTypes.array,
  showMessage: PropTypes.func,
};

TransferTimeline.defaultProps = {
  loading: false,
  errors: undefined,
  timeline: undefined,
  showMessage: noop,
};

export default TransferTimeline;
