import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import StockOptions from "components/stockOptions/StockOptions";
import grantBarGraphView from "views/modules/stockOptions/components/barGraph";
import historyData from "views/modules/stockOptions/components/historyData";

export default class StockOptionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lastHistoryJSON: "",
      updatingGraph: false,
    };

    this.getGrantHistories = this.getGrantHistories.bind(this);
    this.getAccounts = this.getAccounts.bind(this);
    this.handleGetGrantHistoriesSuccess = this.handleGetGrantHistoriesSuccess.bind(
      this
    );
    this.handleGetAccountsSuccess = this.handleGetAccountsSuccess.bind(this);
    this.handleGetSecurityDataSuccess = this.handleGetSecurityDataSuccess.bind(
      this
    );
    this.handleGetCustomProductsSuccess = this.handleGetCustomProductsSuccess.bind(
      this
    );
    this.handleFailure = this.handleFailure.bind(this);
    this.setUpdatingGraph = this.setUpdatingGraph.bind(this);
  }

  componentDidMount() {
    const { ua } = this.props;
    this.setState({ ua });
    this.getGrantHistories({ ua });
    this.getAccounts({ ua });
  }

  componentDidUpdate(props) {
    if (props.ua !== this.props.ua) {
      this.getGrantHistories({ ua: this.props.ua });
      this.getAccounts({ ua: this.props.ua });
    }
  }

  getAccounts({ ua }) {
    const { getAccountsService } = this.props;
    if (this.accountsWatch) {
      getAccountsService.unwatch(this.accountsWatch);
    }
    this.accountsWatch = getAccountsService.watch(
      { userAccountIds: JSON.stringify([parseInt(ua, 10)]) },
      (err, data) => {
        this.handleGetAccountsSuccess(data);
      }
    );
  }

  getGrantHistories({ currentPrice, whatIfPrice }) {
    const { ua, getGrantHistoriesService } = this.props;
    if (this.grantWatch) {
      getGrantHistoriesService.unwatch(this.grantWatch);
    }

    let filter = {
      userAccountId: ua,
    };
    if (currentPrice) {
      filter.currentPrice = currentPrice;
    }
    if (whatIfPrice) {
      filter.whatIfPrice = whatIfPrice;
    }
    this.grantWatch = getGrantHistoriesService.watch(filter, (err, data) => {
      this.handleGetGrantHistoriesSuccess(data);
    });
  }

  getCustomProducts(account, handleSuccess) {
    Services.CustomProducts.get({ productId: account.productId }, function (
      err,
      data
    ) {
      handleSuccess(data);
    });
  }

  handleGetCustomProductsSuccess(data) {
    this.setState({ customProducts: data.spData });
  }

  getSecurityData(account, handleSuccess) {
    const search = { resolveSearchTerm: account.ticker };
    Services.Securities.get(search, function (err, data) {
      handleSuccess(data);
    });
  }

  handleGetSecurityDataSuccess(data) {
    this.setState({ securityData: data.spData });
  }

  handleGetAccountsSuccess(response) {
    const account = response?.spData?.accounts[0];
    this.setState({
      errors: undefined,
      account,
    });
    if (account) {
      this.getSecurityData(account, this.handleGetSecurityDataSuccess);
      if (!this.state.customProducts) {
        this.getCustomProducts(account, this.handleGetCustomProductsSuccess);
      }
    }
  }

  handleGetGrantHistoriesSuccess(response) {
    const spData = response.spData;
    this.updateGraph(spData);
    this.setState({
      loading: false,
      errors: undefined,
      grants: spData.grants,
      histories: spData.histories,
      summaries: spData.summaries,
      grantHistoriesData: spData,
    });
  }

  handleFailure(errors) {
    if (this.cancelableService.isCanceled()) {
      return;
    }

    this.setState({
      loading: false,
      errors: errors,
    });
  }

  updateGraph(data) {
    var ele = $(".grant-graph-placeholder");
    if (!data || !ele || ele.length === 0) {
      return ele.empty();
    }
    if (!this.graphView) {
      this.graphView = new grantBarGraphView({ el: ele });
    }
    var fullHistory = historyData(data, true);
    var shortHistory = historyData(data);
    if (fullHistory && fullHistory.length) {
      var json = JSON.stringify(fullHistory);
      if (json !== this.state.lastHistoryJSON) {
        this.graphView.render(fullHistory, shortHistory);
        this.setState({ lastHistoryJSON: json });
      }
    } else {
      this.setState({ lastHistoryJSON: "" });
      delete this.graphView;
      ele.empty();
    }
    this.setState({ updateGraph: false });
  }

  setUpdatingGraph(value) {
    this.setState({ updatingGraph: value });
  }

  componentWillUnmount() {
    const { getGrantHistoriesService, getAccountsService } = this.props;
    if (this.accountsWatch) {
      getAccountsService.unwatch(this.accountsWatch);
    }
    if (this.grantWatch) {
      getGrantHistoriesService.unwatch(this.grantWatch);
    }
  }

  render() {
    const { errors, loading } = this.state;
    const { ua } = this.props;
    return (
      <StockOptions
        errors={errors}
        loading={loading}
        account={this.state.account}
        grants={this.state.grants}
        securityData={this.state.securityData}
        getGrantHistories={this.getGrantHistories}
        ua={ua}
        updatingGraph={this.state.updatingGraph}
        setUpdatingGraph={this.setUpdatingGraph}
      />
    );
  }
}

StockOptionsContainer.propTypes = {
  ua: PropTypes.string.isRequired,
  getGrantHistoriesService: PropTypes.func,
  getAccountsService: PropTypes.func,
};

StockOptionsContainer.defaultProps = {
  getGrantHistoriesService: Services.GrantHistories.get,
  getAccountsService: Services.Accounts.get,
};
