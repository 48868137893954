import PropTypes from "prop-types";
import React from "react";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import mixpanel from "mixpanel";
import { getEventData } from "components/opportunitiesSummary/InsightsUtils";

const MAX_LINE_LENGTH = 18;

class PortfolioReview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openingStatement:
        this.props.message.resources[0].jsonContent.openingStatement,
      advisorData: this.props.message.resources[0].jsonContent.advisorData,
    };
  }

  componentDidMount() {
    mixpanel.trackEvent(
      `View Insight`,
      getEventData({ message: this.props.message })
    );
    if (this.props.isFirstRendered) {
      this.props.markAsImpressed(this.props.message.userMessageId);
    } else {
      this.props.markAsViewed(this.props.message.userMessageId);
    }

    this.props.storeTooltipRef(
      document.getElementById("portfolioReviewDisclaimer"),
      { container: "body", placement: "top" }
    );
  }

  render() {
    const { message } = this.props;
    const advisorString = `${this.state.advisorData.advisorName} ${
      this.state.advisorData.isCFP === "true" ? " CFP®" : ""
    }`;
    const longName = advisorString.length > MAX_LINE_LENGTH;
    return (
      <div className="insight__message">
        <div
          className="insight__header qa-insight-header u-sentence-case"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div className="insight__summary portfolio-review__opening-statement">
          {this.state.openingStatement}
        </div>
        <div className="portfolio-review__advisor-section">
          <div
            className={`u-sentence-case portfolio-review__advisor-section-header-rebrand`}
          >
            Portfolio Review
          </div>
          <div className="pc-flag pc-flag--flush pc-flag--rev">
            <div className="pc-flag__img pc-u-pr--">
              <img
                className="portfolio-review__flag-img"
                src={`https://home.personalcapital.com/advisor/images/${this.state.advisorData.advisorID}-square.jpg`}
              />
            </div>
            <div className="pc-flag__body portfolio-review__flag-body pc-u-pl--">
              <div className="js-portfolio-review-advisor-name">
                {advisorString}
              </div>
              <div>{this.state.advisorData.advisorPhone}</div>
              <div>{this.state.advisorData.advisorHours}</div>
            </div>
          </div>
        </div>
        <div
          className={`insight__summary insight__summary--portfolio-review ${
            longName ? "insight__summary--portfolio-review-thin" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: message.summary }}
        />
        <div className="insight__buttons">
          {getActionButtons({
            message,
            markAsViewed: this.props.markAsViewed,
            buttonClass: "pc-btn pc-btn--full pc-btn--primary",
            appointmentReason: "Portfolio Review",
          })}
        </div>
      </div>
    );
  }
}

PortfolioReview.propTypes = {
  message: PropTypes.object.isRequired,
  markAsImpressed: PropTypes.func,
  markAsViewed: PropTypes.func,
  isFirstRendered: PropTypes.bool,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
};
PortfolioReview.defaultProps = {
  markAsImpressed: undefined,
  markAsViewed: undefined,
  isFirstRendered: undefined,
  storeTooltipRef: false,
};

export default withTooltips(PortfolioReview);
