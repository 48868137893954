import PropTypes from "prop-types";
import React from "react";
import SelectableItem from "components/common/multiselectDropdown/SelectableItem";

const SelectableItemsList = (props) => {
  const { items, onChange, className, classNameItem } = props;
  return (
    <div className={className}>
      {items.map((item, i) => {
        return (
          <props.selectableItemType
            key={i}
            {...item}
            onChange={onChange}
            className={`${classNameItem} pc-u-mb--`}
            showBalancesAndTimeStamp={props.showBalancesAndTimeStamp}
          />
        );
      })}
    </div>
  );
};

SelectableItemsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(SelectableItem.propTypes)),
  className: PropTypes.string,
  classNameItem: PropTypes.string,
  onChange: PropTypes.func,
  showBalancesAndTimeStamp: PropTypes.bool,
  selectableItemType: PropTypes.func,
};

SelectableItemsList.defaultProps = {
  classNameItem: "",
  showBalancesAndTimeStamp: false,
  selectableItemType: SelectableItem,
};

export default SelectableItemsList;
