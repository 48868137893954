import $ from "jquery";
import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import Services from "services";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import EmailAdvisor from "components/contactAdvisor/emailAdvisor/EmailAdvisor";

const ADVISOR_TIME_TRADE_LINK = "https://www.timetrade.com/book/95svk";
const EMAIL_DESTINATION_TYPE = "DSF";

export default class EmailAdvisorContainer extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      errorMessage: null,
    };
  }

  onRequestSendEmail(emailBody) {
    if (!emailBody) {
      throw new Error("emailBody is required");
    }

    const { componentName, featureName } = this.props;
    const emailSubject = `New Email from Contact Advisor Launcher in ${featureName}`;
    const sendEmailDeferred = $.Deferred();

    Services.EmailSupport.send(
      {
        subject: emailSubject,
        description: emailBody,
        destination: EMAIL_DESTINATION_TYPE,
      },
      (err, response) => {
        if (!err && response && response.spData && response.spData.isSuccess) {
          sendEmailDeferred.resolve();
        } else {
          const error =
            err ||
            (response.spHeader &&
              _.first(response.spHeader.errors || [{}]).message) ||
            "An error has occurred.";
          sendEmailDeferred.reject(error);
        }
      }
    );

    sendEmailDeferred
      .done(() => {
        this.props.onEmailAdvisorDone({ emailSent: true });
        ComponentAnalytics.trackEvent(
          componentName,
          "Contact Advisor Email Sent Successfully",
          {
            email_subject: emailSubject, // eslint-disable-line camelcase
          }
        );
      })
      .fail((err) => {
        ComponentAnalytics.trackEngineeringEvent(
          componentName,
          "Error",
          `Services.EmailSupport.send: ${err}`
        );
        ComponentAnalytics.trackEvent(
          componentName,
          "View Contact Advisor Email Send Error",
          {
            email_subject: emailSubject, // eslint-disable-line camelcase
            error: err,
          }
        );
        this.setState({ errorMessage: err });
      });
  }

  render() {
    return (
      <EmailAdvisor
        {...this.props}
        onRequestSendEmail={this.onRequestSendEmail.bind(this)}
        userFirstName={window.usernameFirst}
        scheduleACallLink={ADVISOR_TIME_TRADE_LINK}
        errorMessage={this.state.errorMessage}
      />
    );
  }
}

EmailAdvisorContainer.propTypes = {
  // The web app feature the user is currently on
  featureName: PropTypes.string.isRequired,
  // Component name to use when tracking analytics events
  componentName: PropTypes.string.isRequired,
  // Callback function to run when this module is done (e.g. user clicked on a link or submitted an email)
  onEmailAdvisorDone: PropTypes.func.isRequired,
};
