import React from "react";
import InsightFactory from "components/opportunitiesSummary/InsightFactory";
import InsightBlogContainer from "components/insightBlog/InsightBlogContainer";

const MAX_NUMBER_OF_INSIGHTS = 5;
const REGEX_SEARCH_ALL_WHITE_SPACES = /\s/g;
const createInsightsWithBlog = (
  messages = [],
  markAsViewed,
  markAsImpressed,
  handleWidgetException,
  isAddFundsEligible
) => {
  const sharedProps = {
    markAsViewed,
    markAsImpressed,
    handleWidgetException,
    isAddFundsEligible,
  };
  let insights = messages.map((message, i) => {
    const key = `${message.template.replace(
      REGEX_SEARCH_ALL_WHITE_SPACES,
      "-"
    )}-${i}`;
    return (
      <InsightFactory
        key={key}
        message={message}
        isFirstRendered={i === 0}
        {...sharedProps}
      />
    );
  });
  if (insights.length < MAX_NUMBER_OF_INSIGHTS) {
    insights.push(<InsightBlogContainer />);
  }
  return insights;
};

export default createInsightsWithBlog;
