import {
  FREQUENCY_WEEKLY,
  FREQUENCY_SEMI_MONTHLY,
  FREQUENCY_MONTHLY,
  FREQUENCY_QUARTERLY,
  FREQUENCY_YEARLY,
  OPTIONS_DAY_OF_WEEK,
  FREQUENCY_ONE_TIME,
  DELIVERY_METHODS,
} from "components/transferFunds/utils/constants";

export const frequencyLabel = (frequency) => {
  let label;
  switch (frequency) {
    case FREQUENCY_ONE_TIME:
      label = "One Time";
      break;
    case FREQUENCY_WEEKLY:
      label = "Weekly";
      break;
    case FREQUENCY_SEMI_MONTHLY:
      label = "Twice Per Month";
      break;
    case FREQUENCY_MONTHLY:
      label = "Monthly";
      break;
    case FREQUENCY_QUARTERLY:
      label = "Quarterly";
      break;
    case FREQUENCY_YEARLY:
      label = "Annually";
      break;
    default:
      label = frequency;
      break;
  }

  return label;
};

export function scheduleLabel(schedule) {
  const selectedSchedule = OPTIONS_DAY_OF_WEEK.find(
    (option) => option.value === schedule
  );

  if (!selectedSchedule) {
    return schedule;
  }
  return selectedSchedule.label;
}

export function deliveryMethodLabel(deliveryMethod) {
  const selectedDeliveryMethod = DELIVERY_METHODS.find(
    (option) => option.value === deliveryMethod
  );

  if (!selectedDeliveryMethod) {
    return deliveryMethod;
  }
  return selectedDeliveryMethod.label;
}
