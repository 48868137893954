import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import { formatCurrency } from "libs/pcap/utils/format";
import Loading from "components/common/loading/Loading";
import Message from "components/common/Message";
import CtaOverlay from "components/common/CtaOverlay";
import CashFlowBarChart from "components/cashFlowSummary2/CashFlowBarChart";
import GenericProgressBar from "components/common/GenericProgressBar";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";
import { handleAmplitudeEvent } from "../../empower/utils/eventUtils";

/**
 * `cashFlow` displays the cashflow chart for current month.
 *
 * Initialization options:
 *
 * @param  {Object}   props                 Initialization options.
 * @param  {Number}   props.value           Cashflow for current month.
 * @param  {String}   props.takeaway        An object of last month and last year takeaways
 */
export default class CashFlowSummary extends React.Component {
  goToPage(pageHash) {
    window.location.href = pageHash;
  }

  getCategoryId(categoryName) {
    return _.findWhere(this.props.transactionCategories, {
      name: categoryName,
    });
  }

  onCategoryClick(categoryName, categoryType) {
    const category = this.getCategoryId(categoryName);
    let url = `${CASH_FLOW_URL}/${categoryType}`;

    if (category) {
      url += "/" + category.transactionCategoryId;
    }
    this.goToPage(url);
  }
  handleTitleClick() {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "widgets_cash_flow_clicked_event"
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
        event_properties: {
          selection: "widgets_cash_flow_clicked_event",
        },
      });
    }
  }

  handleTooltipClick = () => {
    const name = "Cash Flow";
    const element = "SELECT_BUTTON";
    const section = "Widget.toolTip";
    const payload = { widget: name };

    handleAmplitudeEvent(element, section, payload);
  };

  render() {
    const {
      loading,
      errors,
      ace,
      className,
      labelText,
      incomeLast,
      expenseLast,
      expense,
      income,
      takeaway,
      incomeBarChartStacks,
      expenseBarChartStacks,
      barHeight,
    } = this.props;
    const maxValue = Math.max(income, expense, expenseLast, incomeLast);
    const cashFlow = income - expense;
    const cashFlowBarChartClass = "pc-u-mt";
    let formattedIncomeValue = "";
    let formattedExpenseValue = "";
    let formattedCashflow = "";
    let showCents = false;

    const empowerSharedData = window.integratedSharedData;
    const tooltip =
      empowerSharedData?.translations?.get("zeroStateDashboard")?.cashFlow
        ?.tooltip;
    const tooltipClass = tooltip ? "with-tooltip" : "";
    const widgetClass = "qa-cash-flow-widget";

    if (
      (income < 1 && income !== 0) ||
      (expense < 1 && expense !== 0) ||
      (cashFlow < 1 && cashFlow !== 0)
    ) {
      formattedIncomeValue = formatCurrency(income);
      formattedExpenseValue = formatCurrency(expense > 0 ? -expense : expense);
      formattedCashflow = formatCurrency(cashFlow);
      showCents = true;
    } else {
      formattedIncomeValue = formatCurrency(income, 0);
      formattedExpenseValue = formatCurrency(
        expense > 0 ? -expense : expense,
        0
      );
      formattedCashflow = formatCurrency(cashFlow, 0);
    }

    if (loading) {
      return <Loading />;
    }

    if (errors) {
      return <Message severity="error" messages={errors} />;
    }

    let aceOverlay;
    if (ace) {
      aceOverlay = <CtaOverlay message={ace} widgetName="Cash Flow Summary" />;
    }

    return (
      <section
        className={`summary-widget ${className} ${tooltipClass} ${widgetClass}`}
      >
        {aceOverlay}
        <svg className="u-hidden-visually">
          <defs xmlns="http://www.w3.org/2000/svg">
            <filter id="dropshadow" height="120%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
              <feOffset dx="0" dy="0.5" result="offsetblur" />
              <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
        </svg>
        <div className="summary-widget__header">
          <div className="l-spaced l-spaced--flush">
            <div className="pc-delta qa-cash-flow-summary-title u-sentence-case header-container">
              <h2 className="pc-u-mb0">
                <a
                  aria-label="Go to Cash flow"
                  href={CASH_FLOW_URL}
                  className="summary-widget__title--link"
                  onClick={this.handleTitleClick}
                >
                  Cash Flow
                </a>
              </h2>
              {tooltip && (
                <InfoTooltipIcon
                  title={tooltip}
                  container={false}
                  parentClassName={widgetClass}
                  tooltipProps={{
                    trigger: "click",
                    role: "alert",
                  }}
                  onClick={this.handleTooltipClick}
                />
              )}
            </div>
            <span
              aria-label={
                "Last 30 Days income minus expense " + formattedCashflow
              }
              className="pc-delta u-text-primary tile__highlighted js-cash-flow-summary-value qa-cash-flow-summary-value"
            >
              {formattedCashflow}
            </span>
          </div>
          <div className="l-spaced l-spaced--right l-spaced--flush">
            <div className="pc-help-block milli qa-cash-flow-date-range">
              {labelText || "This Month"}
            </div>
          </div>
        </div>
        <div className="summary-widget__body">
          <div className="cash-flow-summary-bar cash-flow-summary-bar--income">
            <div className="u-sentence-case">
              <span className="cash-flow-summary-bar__title-container pc-u-pr0">
                <a
                  href={`${CASH_FLOW_URL}/income`}
                  className="cash-flow-summary-bar__title js-cash-flow-summary-income-bar-title qa-this-month-income-bar-title"
                  ref={(input) => {
                    this.incomeBarTitleNode = input;
                  }}
                >
                  Income This Month{" "}
                  <span
                    className="u-text-bold qa-cash-flow-summary-income-bar-amount"
                    tabIndex="0"
                    aria-label={"Last 30 Days Income " + formattedIncomeValue}
                    ref={(input) => {
                      this.incomeBarValueNode = input;
                    }}
                  >
                    {formattedIncomeValue}
                  </span>
                </a>
              </span>
            </div>
            <div className="cash-flow-summary-bar-chart js-cash-flow-summary-bar-chart qa-this-month-income-chart">
              <CashFlowBarChart
                showCents={showCents}
                onChartCategoryClick={this.onCategoryClick.bind(this)}
                stacks={incomeBarChartStacks}
                barHeight={barHeight}
              />
            </div>
            {incomeLast !== undefined && incomeLast !== 0 ? (
              <>
                <div className="chart-legend chart-legend--box milli">
                  <GenericProgressBar
                    className="income-bar qa-last-month-income-bar pc-u-mt--"
                    name="Last Month"
                    value={incomeLast}
                    total={maxValue}
                    isAbsoluteValue={true}
                  />
                </div>
              </>
            ) : null}
          </div>
          <div
            className={`cash-flow-summary-bar cash-flow-summary-bar--expense js-cash-flow-summary-bar-chart ${cashFlowBarChartClass}`}
          >
            <div className="u-sentence-case">
              <span className="cash-flow-summary-bar__title-container pc-u-pr0">
                <a
                  href={`${CASH_FLOW_URL}/expense`}
                  className="cash-flow-summary-bar__title js-cash-flow-summary-expense-bar-title qa-this-month-expense-bar-title"
                  ref={(input) => {
                    this.expenseBarTitleNode = input;
                  }}
                >
                  Expenses This Month{" "}
                  <span
                    className="u-text-bold qa-cash-flow-summary-expense-bar-amount"
                    tabIndex="0"
                    aria-label={"Last 30 Days Expense " + formattedExpenseValue}
                    ref={(input) => {
                      this.expenseBarValueNode = input;
                    }}
                  >
                    {formattedExpenseValue}
                  </span>
                </a>
              </span>
            </div>
            <div className="cash-flow-summary-bar-chart qa-this-month-expense-chart">
              <CashFlowBarChart
                showNegativeValues={true}
                showCents={showCents}
                onChartCategoryClick={this.onCategoryClick.bind(this)}
                stacks={expenseBarChartStacks}
                barHeight={barHeight}
              />
            </div>
            {expenseLast !== undefined && expenseLast !== 0 ? (
              <GenericProgressBar
                className="expense-bar qa-last-month-expense-bar pc-u-mt--"
                name="Last Month"
                value={-expenseLast}
                total={maxValue}
                isAbsoluteValue={true}
              />
            ) : null}
          </div>
        </div>
        <div className="summary-widget__footer">
          {takeaway && (
            <div
              className="pc-help-block milli u-clip-text qa-cash-flow-summary-takeaway"
              title={takeaway}
            >
              {takeaway}
            </div>
          )}
        </div>
      </section>
    );
  }
}

CashFlowSummary.defaultProps = {
  barHeight: 48,
  loading: true,
  errors: undefined,
  takeaway: undefined,
  ace: undefined,
  income: undefined,
  expense: undefined,
  incomeLast: undefined,
  expenseLast: undefined,
  incomeBarChartStacks: undefined,
  expenseBarChartStacks: undefined,
  transactionCategories: undefined,
  labelText: undefined,
  className: undefined,
};

CashFlowSummary.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  takeaway: PropTypes.string,
  ace: PropTypes.object,
  income: PropTypes.number,
  expense: PropTypes.number,
  incomeLast: PropTypes.number,
  expenseLast: PropTypes.number,
  incomeBarChartStacks: PropTypes.object,
  expenseBarChartStacks: PropTypes.object,
  transactionCategories: PropTypes.array,
  labelText: PropTypes.string,
  barHeight: PropTypes.number,
  className: PropTypes.string,
};
