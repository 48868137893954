import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Services from "services";
import { promisify } from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";

const hasTransferErrors = (transferInstructions) => {
  return transferInstructions.length === 0;
};

function handleViewRecurringRequest() {
  if (IS_EMPOWER) {
    window.dashboardUtils?.eventBus.dispatch(
      "deposits_withdrawals.view_recurring_link.click"
    );
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type: window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_LINK,
      event_properties: {
        selection: "deposits_withdrawals.view_recurring_link.click",
      },
    });
  }
}

/**
 *  RecurringTransfersLink access point for recurring transfers feature
 *
 * @param {Function} onLoaded (optional) callback executed once did mount API calls respond with error or successful response
 *
 **/

const RecurringTransfersLink = (props) => {
  const [transferInstructions, setTransferInstructions] = useState(null);
  const [serverErrors, setServerErrors] = useState(null);
  useEffect(() => {
    let fetchInstructions;
    fetchInstructions = makeCancelablePromise(
      props.fetchTransferInstructions()
    );
    fetchInstructions.promise
      .then((instructionsResponse) => {
        props.onLoaded?.();
        setTransferInstructions(instructionsResponse);
      })
      .catch((errors) => {
        props.onLoaded?.();
        setServerErrors(errors);
      });

    return () => {
      if (fetchInstructions && fetchInstructions.cancel) {
        fetchInstructions.cancel();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!transferInstructions || !transferInstructions.length || serverErrors) {
    return null;
  }

  return (
    <>
      <a
        href={props.url}
        onClick={handleViewRecurringRequest}
        className={`js-recurring-transfers-link qa-recurring-transfers-link ${props.className}`}
      >
        View Recurring Requests
      </a>
      {
        /* TODO implement error indicator */
        hasTransferErrors(transferInstructions) ? (
          <div>error indicator</div>
        ) : null
      }
    </>
  );
};

RecurringTransfersLink.propTypes = {
  fetchTransferInstructions: PropTypes.func,
  onLoaded: PropTypes.func,
  url: PropTypes.string,
  className: PropTypes.string,
};

RecurringTransfersLink.defaultProps = {
  fetchTransferInstructions: promisify(
    Services.Transfer.getTransferInstruction
  ),
  onLoaded: undefined,
  url: RECURRING_TRANSFERS_URL, // eslint-disable-line no-undef
  className: "",
};

export default RecurringTransfersLink;
