/* eslint-disable react/require-default-props */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "libs/pcap/utils/format";
import withTooltips from "components/higherOrderComponents/withTooltips";

const organizationName = "investment";

const DEFAULT_TITLE_TOOLTIP = `This refers to the median value of your ${organizationName} portfolio at the end of the visible time frame and does not consider outside accounts. This value is the median projection after running 5,000 Monte Carlo projections using historical long term asset class returns and varies from your Retirement Planner projection in a few ways. <a target='_blank' href=${PERSONAL_CAPITAL_RETIREMENT_PLANNER_LINK_REBRANDED}>Learn more</a>.`;
const DEFAULT_ARIA_LABEL_TOOLTIP = `This refers to the median value of your ${organizationName} portfolio at the end of the visible time frame and does not consider outside accounts. This value is the median projection after running 5,000 Monte Carlo projections using historical long term asset class returns and varies from your Retirement Planner projection in a few ways. Learn more at ${PERSONAL_CAPITAL_RETIREMENT_PLANNER_LINK_REBRANDED}`;

class ProjectionSummaryHeader extends Component {
  render() {
    const {
      endIsRetirement,
      currentValue,
      additionalValue,
      isWithdrawal,
      storeTooltipRef,
      titleTooltip,
      ariaLabelTooltip,
    } = this.props;
    const additionalValueClassname =
      "projection-summary__projection-value--" +
      (additionalValue > 0 ? "gain" : "loss") +
      " js-projection-summary-projection-value";

    return (
      <div className="l-spaced l-spaced--flush pc-u-mb--">
        <div>
          <div className="projection-summary__projection-value js-projection-summary-projection-value">
            {formatCurrency(currentValue, 0)}
          </div>
          <div
            className={`projection-summary__projection-sub-title u-sentence-case`}
          >
            <span>
              {endIsRetirement ? "Median at Retirement" : "Median in 20 years"}
            </span>
            <span
              ref={(el) => {
                storeTooltipRef(el, {
                  html: true,
                  trigger: "tooltipClick",
                  placement: "bottom",
                });
              }}
              className="projection-summary__projection-tooltip-container"
              data-toggle="tooltip"
              aria-label={ariaLabelTooltip}
              title={titleTooltip}
              role="tooltip"
            >
              <svg
                className="projection-summary__projection-tooltip-svg icon__help-circled"
                role="img"
                aria-label="Help icon"
                viewBox="0 0 12 12"
              />
            </span>
          </div>
        </div>
        {Boolean(additionalValue) && (
          <div className="projection-summary__projection-values-divider" />
        )}
        {Boolean(additionalValue) && (
          <div>
            <div className={additionalValueClassname}>
              {formatCurrency(Math.abs(additionalValue), 0)}{" "}
              <span className="projection-summary__projection-value-parity">
                {additionalValue > 0 ? "More" : "Less"}
              </span>
            </div>
            <div className="projection-summary__projection-sub-title">
              With New {isWithdrawal ? "Withdrawal" : "Contribution"}
            </div>
          </div>
        )}
      </div>
    );
  }
}

ProjectionSummaryHeader.propTypes = {
  currentValue: PropTypes.number,
  additionalValue: PropTypes.number,
  isWithdrawal: PropTypes.bool,
  endIsRetirement: PropTypes.bool,
  storeTooltipRef: PropTypes.func,
  titleTooltip: PropTypes.string,
  ariaLabelTooltip: PropTypes.string,
};

ProjectionSummaryHeader.defaultProps = {
  titleTooltip: DEFAULT_TITLE_TOOLTIP,
  ariaLabelTooltip: DEFAULT_ARIA_LABEL_TOOLTIP,
};

export default withTooltips(ProjectionSummaryHeader);
