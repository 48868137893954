import React from "react";
import PropTypes from "prop-types";
import PieChart from "components/common/charts/PieChart";
import Table from "libs/pcap/table/Table";
import Modal from "components/modal/Modal";
import Loading from "components/common/loading/Loading";
import { trackClick } from "components/common/ComponentAnalytics";
import AppointmentContainer from "components/scheduling/AppointmentContainer";
import {
  RECOMMENDATION_PIE_PROPS,
  SECTOR_ALLOCATION_TABLE_COLUMNS,
  getSectorArcClasses,
  prepareDataForSectorPieChart,
  prepareDataForSectorTable,
} from "components/AccountDetails/Recommendation401k/Recommendation401kUtils";

const RECOMMENDATION_401K_SOURCE = "recommendation401k";

export default class Recommendation401kView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isModalOpen: true };
    this.modalContainer = document.querySelector(".js-recommendation-shell");

    this.getModalContainer = this.getModalContainer.bind(this);
    this.handleContactAdvisorClick = this.handleContactAdvisorClick.bind(this);
  }

  handleContactAdvisorClick(e) {
    this.setState({ isModalOpen: true });
    trackClick(e, "Recommendation 401k Contact Advisor Button");
  }

  getModalContainer() {
    return this.modalContainer;
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }
    if (this.props.errors) {
      return (
        <div className="l-spaced u-text-bold pc-u-mt+">
          {this.props.errors[0]}
        </div>
      );
    }

    const userPieData =
      prepareDataForSectorPieChart(
        this.props.allocationSet,
        "userAllocation"
      ) || [];
    const targetPieData =
      prepareDataForSectorPieChart(
        this.props.allocationSet,
        "targetAllocation"
      ) || [];
    const tableData = prepareDataForSectorTable(this.props.allocationSet);
    const premiumIcon = (
      <svg className="pc-modal--recommendation-401k__icon">
        <use xlinkHref="#pc-icon__diamond" />
      </svg>
    );

    return (
      <div className="recommendation-401k__frame js-401k-recommendation-frame">
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/2">
            <h2 className="recommendation-401k__header">
              Align Your 401k to Your Strategy
            </h2>
            We will recommend the funds within your 401k that closely align with
            your alternative allocation. This creates more cohesion across all
            of your household accounts, improving your odds of achieving your
            retirement goals.
            <br />
            <button
              type="button"
              className="pc-btn pc-u-mt--"
              onClick={this.handleContactAdvisorClick}
            >
              Contact Advisor
            </button>
          </div>
          <div className="pc-layout__item pc-u-1/4">
            <PieChart
              {...RECOMMENDATION_PIE_PROPS}
              datum={userPieData}
              getArcClasses={getSectorArcClasses}
            />
          </div>
          <div className="pc-layout__item pc-u-1/4">
            <PieChart
              {...RECOMMENDATION_PIE_PROPS}
              datum={targetPieData}
              getArcClasses={getSectorArcClasses}
            />
          </div>
        </div>
        <div className="pc-u-mt">
          <Table
            columns={SECTOR_ALLOCATION_TABLE_COLUMNS}
            data={tableData}
            headerRowClassName="recommendation-401k-header-cell"
          />
        </div>
        {this.state.isModalOpen && (
          <Modal
            componentName="401k Recommendation Premium Notice"
            contentLabel="401k Recommendation Premium Notice"
            title="Premium Service"
            overlayClassName="pc-modal--recommendation-401k__overlay"
            className="pc-modal--promo pc-modal--recommendation-401k"
            parentSelector={this.getModalContainer}
            isOpen={true}
            icon={premiumIcon}
          >
            <div>
              Exclusively for clients, we offer a service to recommend the funds
              within your 401k that most closely align with your alternative
              allocation. This creates more cohesion across all of your
              household accounts, improving your odds of achieving your
              retirement goals.
              <p />
              Have one of our advisors call you if you&apos;d like help choosing
              the funds in your company’s 401k.
              <div className="pc-u-mt-">
                <AppointmentContainer
                  isClient={false}
                  advisorData={this.props.advisorData}
                  source={RECOMMENDATION_401K_SOURCE}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

Recommendation401kView.propTypes = {
  allocationSet: PropTypes.object,
  errors: PropTypes.array,
  loading: PropTypes.bool,
  advisorData: PropTypes.object,
};

Recommendation401kView.defaultProps = {
  allocationSet: undefined,
  errors: undefined,
  loading: false,
  advisorData: {},
};
