import React, { useEffect, useState } from "react";
import TransferFunds from "../../../../components/transferFunds/TransferFunds/TransferFunds";
import PropTypes from "prop-types";
import { noop } from "underscore";
import { TRANSFER_TYPE_WITHDRAW } from "../../../../components/transferFunds/utils/constants";

const TransferDetails = (props) => {
  const { showTitle, transferType, onModelChange } = props;
  const [model, setModel] = useState(props.model);

  // Empty dependency array to run only on initial load, similar to componentDidMount
  useEffect(() => {
    window.dashboardUtils?.eventBus.dispatch("select_accounts.page.load");
  }, []);

  useEffect(() => {
    setModel(props.model);
  }, [props.model]);

  const title =
    transferType === TRANSFER_TYPE_WITHDRAW
      ? "Withdraw funds"
      : "Select accounts";

  const handleOnModelChange = (value) => {
    setModel(value);
    onModelChange(value);
  };

  return (
    <div>
      {showTitle && (
        <h1 className="nav-secondary__title js-transfer-details-title">
          {title}
        </h1>
      )}
      <div className="transfer-details__review-msg pc-u-mt- pc-u-mb">
        Enter details below to process your request.
      </div>

      <TransferFunds
        {...props}
        model={model}
        transferType={transferType}
        shouldShowCryptoWarning={false}
        onModelChange={handleOnModelChange}
      />
    </div>
  );
};

TransferDetails.propTypes = {
  errors: PropTypes.array,
  sourceAccounts: PropTypes.array,
  targetAccounts: PropTypes.array,
  model: PropTypes.object,
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onLinkAccount: PropTypes.func,
  onSourceAccountChange: PropTypes.func,
  onTargetAccountChange: PropTypes.func,
  stateType: PropTypes.string,
  hasRecurringTransferEstablished: PropTypes.bool,
  onModelChange: PropTypes.func,
  hasOneTimeFrequency: PropTypes.bool,
  isToDropDownDisabled: PropTypes.bool,
  isFromDropDownDisabled: PropTypes.bool,
  showTitle: PropTypes.bool,
  transferType: PropTypes.number.isRequired,
  shouldShowTradingBlackoutWarningMsg: PropTypes.bool,
};

TransferDetails.defaultProps = {
  model: {},
  onSourceAccountChange: noop,
  onTargetAccountChange: noop,
  onLinkAccount: undefined,
  errors: [],
  sourceAccounts: [],
  targetAccounts: [],
  stateType: undefined,
  hasRecurringTransferEstablished: false,
  onModelChange: noop,
  onBack: noop,
  hasOneTimeFrequency: undefined,
  isToDropDownDisabled: undefined,
  isFromDropDownDisabled: undefined,
  showTitle: true,
  shouldShowTradingBlackoutWarningMsg: false,
};

export default TransferDetails;
