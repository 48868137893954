import { easeQuadOut, easeBackOut } from "d3";
import _ from "underscore";

/**
 * Draws a line for today's data point in the chart.
 *
 * @param {Object}          options         Initialization options.
 * @returns {Function}                      A set of two functions to draw line and a circle.
 */

const ANIMATION_ACCENT_DELAY = 500;
const ANIMATION_LINE_OFFSET = 10;
const ANIMATION_POINT_DELAY = 1000;
const ANIMATION_POINT_OVERSHOOT = 2.2;

const ACCENT_RADIUS = 3;

export function drawLastDayLine(options) {
  const data = [];
  const noTransitions = options.noTransitions;
  if (options.datum) {
    data.push(options.datum);
  }
  const line = options.container.selectAll("." + options.className).data(data);
  // update
  line
    .transition()
    .style("opacity", 1)
    .attr("x1", options.xAccessor)
    .attr("x2", options.xAccessor);

  // create
  line
    .enter()
    .append("line")
    .style("opacity", noTransitions ? 1 : 0)
    .classed(options.className, true)
    .attr("x1", function (d) {
      return options.xAccessor(d) - (noTransitions ? 0 : ANIMATION_LINE_OFFSET);
    })
    .attr("y1", 0)
    .attr("x2", function (d) {
      return options.xAccessor(d) - (noTransitions ? 0 : ANIMATION_LINE_OFFSET);
    })
    .attr("y2", options.height)
    .transition()
    .delay(noTransitions ? 0 : ANIMATION_ACCENT_DELAY)
    .ease(easeQuadOut)
    .attr("x1", options.xAccessor)
    .attr("x2", options.xAccessor)
    .style("opacity", 1);

  // remove
  line.exit().transition().style("opacity", 0).remove();
}

export function drawLastDayPoint(options) {
  const data = [];
  if (options.datum) {
    data.push(options.datum);
  }
  const line = options.container.selectAll("." + options.className).data(data);
  // update
  line
    .transition()
    .attr("r", ACCENT_RADIUS)
    .attr("cx", options.xAccessor)
    .attr("cy", options.yAccessor);

  // create
  line
    .enter()
    .append("circle")
    .classed(options.className, true)
    .attr("r", 0)
    .attr("cx", options.xAccessor)
    .attr("cy", options.yAccessor)
    .transition()
    .ease(easeBackOut.overshoot(ANIMATION_POINT_OVERSHOOT))
    .delay(ANIMATION_POINT_DELAY)
    .attr("r", ACCENT_RADIUS)
    .on("end", function () {
      if (_.isFunction(options.lastDayCircleRendered)) {
        options.lastDayCircleRendered(options.className);
      }
    });

  // remove
  line.exit().transition().style("opacity", 0).remove();
}

export function drawLastDayDate(options) {
  const data = [];
  if (options.datum) {
    data.push(options.datum);
  }
  const text = options.container.selectAll("." + options.className).data(data);
  // update
  text
    .transition()
    .style("opacity", 1)
    .attr("x", options.xAccessor)
    .attr("y", options.yAccessor);

  // create
  text
    .enter()
    .append("text")
    .style("opacity", 0)
    .classed(options.className, true)
    .attr("x", options.xAccessor)
    .attr("y", options.yAccessor)
    .text(options.textAccessor)
    .transition()
    .ease(easeQuadOut)
    .delay(ANIMATION_POINT_DELAY)
    .style("opacity", 1);

  // remove
  text.exit().transition().style("opacity", 0).remove();
}
