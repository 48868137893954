import moment from "moment";
import { defaults, where, findWhere, groupBy, map, each } from "underscore";
import DateUtils from "libs/pcap/utils/date";
import memoizeOne from "memoize-one";
import { isEmpty, uniq } from "underscore";
import deepCopy from "deep-copy";

const DATE_FORMAT = DateUtils.API_FORMAT;
const MONEY_IN_LABEL = "moneyIn";
const MONEY_OUT_LABEL = "moneyOut";
const CASH_FLOW_LABEL = "cashFlow";
const AGGREGATES_LABEL = "aggregates";
const INTERVAL_TYPE_DAY = "DAY";
const INTERVAL_TYPE_WEEK = "WEEK";
const INTERVAL_TYPE_MONTH = "MONTH";
const INTERVAL_TYPE_QUARTER = "QUARTER";
const INTERVAL_TYPES = [
  INTERVAL_TYPE_DAY,
  INTERVAL_TYPE_WEEK,
  INTERVAL_TYPE_MONTH,
  INTERVAL_TYPE_QUARTER,
];
const MULTIPLICATION_FACTOR_INTERVAL_DAY = 1;
const MULTIPLICATION_FACTOR_INTERVAL_MONTH = 30.4375;
const DECIMAL_PRECISION = 2;
const DECIMAL_PRECISION_PERCENT = 4;
const PERCENTAGE_OF_100 = 100;
const YEAR_VIEW_CUT_OFF_IN_YEARS = 5;
const REIMBURSABLE = 100003;

const DEFAULT_OPTIONS = {
  includeCashFlow: false,
  includeIncome: false,
  includeExpense: false,
  includeBudgeting: false,
  includeAggregates: false,
  includeAggregateTransactions: false,
  includeCategories: true,
  includeCategoryTransactions: false,
  includeCategoryAggregates: false,
  includeCategoryAggregateTransactions: false,
  includeMerchants: false,
  includeMerchantTransactions: false,
  includeMerchantAggregates: false,
  includeMerchantAggregateTransactions: false,
};

export function getNumberOfDays(startDate, endDate) {
  return endDate.diff(startDate, "days") + 1; // inclusive of end date
}

export function getIntervalType(startDate, endDate) {
  switch (true) {
    case endDate.diff(startDate, "months") < 2:
      return INTERVAL_TYPE_DAY;
    case endDate.diff(startDate, "years") < YEAR_VIEW_CUT_OFF_IN_YEARS:
      return INTERVAL_TYPE_MONTH;
    default:
      return INTERVAL_TYPE_QUARTER;
  }
}

export function getMultiplicationFactor(intervalType) {
  switch (intervalType) {
    case INTERVAL_TYPE_DAY:
      return MULTIPLICATION_FACTOR_INTERVAL_DAY;
    default:
      return MULTIPLICATION_FACTOR_INTERVAL_MONTH;
  }
}

export function getMomentShorthands(intervalType) {
  switch (intervalType) {
    case INTERVAL_TYPE_DAY:
      return "d";
    case INTERVAL_TYPE_WEEK:
      return "w";
    case INTERVAL_TYPE_MONTH:
      return "M";
    default:
      return "Q";
  }
}

export function getDateIntervals(startDate, endDate, intervalType) {
  const intervalTypeShortHands = getMomentShorthands(intervalType);
  let intervals = [];
  let interval = startDate.startOf(intervalTypeShortHands);
  while (interval <= endDate) {
    intervals.push(interval.format(DATE_FORMAT));
    interval = moment(interval).add(1, intervalTypeShortHands);
  }
  return intervals;
}

export function findAggregateIntervalForTheTransaction(
  transactionDate,
  aggregateIntervals,
  intervalType
) {
  let roundedTransactionDate = moment(transactionDate, DATE_FORMAT)
    .startOf(getMomentShorthands(intervalType))
    .format(DATE_FORMAT);
  return findWhere(aggregateIntervals, { date: roundedTransactionDate });
}

const getAmountLabel = (isIncome) => {
  return isIncome ? MONEY_IN_LABEL : MONEY_OUT_LABEL;
};

const getAggregatesLabel = (includeCashFlow) => {
  return includeCashFlow ? CASH_FLOW_LABEL : AGGREGATES_LABEL;
};

export const getAggregatesByDateInterval = memoizeOne(
  ({
    transactions,
    dateIntervals,
    intervalType,
    includeAggregateTransactions,
    isIncome,
    includeCashFlow,
    transactionCategoryId,
    deductCreditTransactions = false,
  } = {}) => {
    let amountLabel = getAmountLabel(isIncome);
    let aggregateIntervals = dateIntervals.map((date) => {
      const aggregateInterval = {
        date,
        amount: 0,
      };
      if (includeCashFlow) {
        aggregateInterval[amountLabel] = 0;
      }

      if (includeAggregateTransactions) {
        aggregateInterval.transactions = [];
      }
      return aggregateInterval;
    });
    transactions.forEach((transaction) => {
      const hasCategoryIdOrTransactionCategoryId = Boolean(
        transaction.categoryId || transactionCategoryId
      );
      const aggregateInterval = findAggregateIntervalForTheTransaction(
        transaction.transactionDate,
        aggregateIntervals,
        intervalType
      );
      let transactionAmount;
      if (
        hasCategoryIdOrTransactionCategoryId &&
        !isEmpty(transaction.splits)
      ) {
        transactionAmount = transaction.splits
          .filter((ts) => !ts.excludeSplit)
          .reduce((initialValue, s) => initialValue + s.amount, 0);
      } else {
        transactionAmount = transaction.amount;
      }

      if (aggregateInterval && typeof transactionAmount === "number") {
        if (deductCreditTransactions && transaction.isCredit) {
          transactionAmount = transactionAmount * -1;
        }
        aggregateInterval.amount = Number(
          (aggregateInterval.amount + transactionAmount).toFixed(
            DECIMAL_PRECISION
          )
        );

        if (includeCashFlow) {
          aggregateInterval[amountLabel] = aggregateInterval.amount;
        }

        if (includeAggregateTransactions) {
          aggregateInterval.transactions.push(transaction);
        }
      }
    });
    return aggregateIntervals;
  }
);

export function getTotal(transactions, deductCreditTransactions) {
  return transactions.reduce((initialValue, transaction) => {
    let transactionAmount = transaction.amount;
    if (deductCreditTransactions && transaction.isCredit) {
      transactionAmount = -1 * transactionAmount;
    }
    return initialValue + transactionAmount;
  }, 0);
}

export function getTotal2(transactions, deductCreditTransactions, categoryId) {
  return transactions.reduce((initialValue, transaction) => {
    let transactionAmount;
    if (isEmpty(transaction.splits)) {
      transactionAmount = transaction.amount;
    } else {
      transactionAmount = transaction.splits
        .filter(
          (s) =>
            !s.excludeSplit &&
            (categoryId == null || s.categoryId === categoryId)
        )
        .reduce((iv, s) => iv + s.amount, 0);
    }

    if (deductCreditTransactions && transaction.isCredit) {
      transactionAmount = -1 * transactionAmount;
    }
    return initialValue + transactionAmount;
  }, 0);
}

/**
 * Sets excludeSplit for a split when a particular category is selected (categoryId)
 * splits with other categories must be excluded.
 * A split which is already excluded (reimbursable transaction) needs to remain excluded.
 * `excludeSplit` will be used in Transactions Grid in to Hide/Show a split when a category is selected in the page.
 * `excludeSplit` will be used to calculate the summary total of transactions grid.
 * `excludeSplit` will be used in this library to calculate the category level aggregates.
 * @param  {Array} transactions array of transactions to be modified.
 * @param  {Number} categoryId category ID that needs to be included
 */
export const setExcludeSplit = (transactions, categoryId) => {
  transactions.forEach((transaction) => {
    if (!isEmpty(transaction.splits) && categoryId) {
      transaction.splits.forEach((split) => {
        split.excludeSplit =
          split.excludeSplit || split.categoryId !== categoryId;
      });
    }
  });
};

/**
 * Sets excludeSplit for a split when a split has reimbursable tag.
 * `excludeSplit` will be used in budgeting page to hide reimbursable splits.
 * `excludeSplit` will be used to exclude the split while aggregating the transactions amount.
 * @param  {Array} transactions array of transactions to be modified.
 */
export const excludeReimbursable = (transactions) => {
  transactions.forEach((transaction) => {
    if (!isEmpty(transaction.splits)) {
      transaction.splits.forEach((split) => {
        split.excludeSplit =
          split.customTags?.systemTags?.includes(REIMBURSABLE) || false;
      });
    }
  });
};

export function isThisMonth(startDate) {
  return startDate.isSame(moment().startOf("month"), "day");
}

export function isWholeMonth(startDate, endDate) {
  return (
    startDate.isSame(startDate.clone().startOf("month"), "day") &&
    endDate.isSame(startDate.clone().endOf("month"), "day")
  );
}

const getTransactionGroupsByCategory = memoizeOne((groupedBySplits) => {
  const transactionGroupsByCategory = {};
  Object.keys(groupedBySplits).forEach((groupKey) => {
    if (groupKey.includes(",")) {
      groupKey.split(",").forEach((categoryId) => {
        if (transactionGroupsByCategory[categoryId] == null) {
          transactionGroupsByCategory[categoryId] = [];
        }

        // Same transaction is included in different category groups if it is split
        // cloning transaction so that excludeSplits flag affects only transaction for a particular category.
        transactionGroupsByCategory[categoryId] = uniq(
          transactionGroupsByCategory[categoryId].concat(
            deepCopy(groupedBySplits[groupKey])
          ),
          // eslint-disable-next-line max-nested-callbacks
          (transaction) => transaction.userTransactionId
        );
      });
    } else {
      if (transactionGroupsByCategory[groupKey] == null) {
        transactionGroupsByCategory[groupKey] = [];
      }
      transactionGroupsByCategory[groupKey] = uniq(
        transactionGroupsByCategory[groupKey].concat(
          deepCopy(groupedBySplits[groupKey])
        ),
        (transaction) => transaction.userTransactionId
      );
    }
  });
  return transactionGroupsByCategory;
});

export const getCategorySummaries = memoizeOne(
  ({
    transactions,
    categories,
    groupTotal,
    averageFactor,
    deductCreditTransactions,
    dateIntervals,
    intervalType,
    includeCategoryTransactions,
    includeCategoryAggregates,
    includeCategoryAggregateTransactions,
    includeCashFlow,
    isIncome,
  } = {}) => {
    let amountLabel = getAmountLabel(isIncome);
    const groupedBySplits = groupBy(transactions, (t) =>
      t.splits == null
        ? [t.categoryId]
        : t.splits.filter((s) => !s.excludeSplit).map((s) => s.categoryId)
    );
    const filteredGroupedBySplits = Object.fromEntries(
      Object.entries(groupedBySplits).filter(([key]) => Boolean(key))
    );
    const transactionGroupsByCategory = getTransactionGroupsByCategory(
      filteredGroupedBySplits
    );

    return map(transactionGroupsByCategory, (transactionGroup, categoryId) => {
      const transactionCategoryId = parseInt(categoryId, 10);
      const category =
        findWhere(categories, {
          transactionCategoryId,
        }) || {};
      setExcludeSplit(transactionGroup, transactionCategoryId);
      const categoryTotal = getTotal2(
        transactionGroup,
        deductCreditTransactions,
        transactionCategoryId
      );
      const summary = {
        id: categoryId,
        transactionCategoryId: categoryId,
        name: category.name,
        originalName: category.originalName,
        amount: Number(categoryTotal.toFixed(DECIMAL_PRECISION)),
        average: Number(
          (categoryTotal * averageFactor).toFixed(DECIMAL_PRECISION)
        ),
        percent: Number(
          ((categoryTotal / groupTotal) * PERCENTAGE_OF_100).toFixed(
            DECIMAL_PRECISION_PERCENT
          )
        ),
        numberOfTransactions: transactionGroup.length,
      };

      if (includeCashFlow) {
        summary[amountLabel] = summary.amount;
      }

      if (includeCategoryTransactions) {
        summary.transactions = transactionGroup;
      }

      if (includeCategoryAggregates) {
        summary[getAggregatesLabel(includeCashFlow)] =
          getAggregatesByDateInterval({
            transactions: transactionGroup,
            dateIntervals,
            intervalType,
            deductCreditTransactions,
            includeCashFlow,
            isIncome,
            transactionCategoryId,
            includeAggregateTransactions: includeCategoryAggregateTransactions,
          });
      }
      return summary;
    });
  }
);

export const getMerchantsGroupedByCategory = memoizeOne(
  ({
    transactions,
    averageFactor,
    dateIntervals,
    intervalType,
    deductCreditTransactions,
    includeMerchantTransactions,
    includeMerchantAggregates,
    includeCashFlow,
    isIncome,
    includeMerchantAggregateTransactions,
  } = {}) => {
    // This will create a map of all categories used in transactions
    // considers category Ids in splits if transaction is split.
    // filters any category that is excluded.
    const groupedBySplits = groupBy(transactions, (t) =>
      t.splits == null
        ? [t.categoryId]
        : t.splits.filter((s) => !s.excludeSplit).map((s) => s.categoryId)
    );
    const transactionGroupsByCategory =
      getTransactionGroupsByCategory(groupedBySplits);

    const merchantsByCategory = {};
    let amountLabel = getAmountLabel(isIncome);
    each(transactionGroupsByCategory, (transactionGroup, categoryId) => {
      const transactionCategoryId = parseInt(categoryId, 10);
      const transactionGroupsByMerchantForACategory = groupBy(
        transactionGroup,
        "merchantId"
      );
      setExcludeSplit(transactionGroup, transactionCategoryId);
      const categoryTotal = getTotal2(
        transactionGroup,
        deductCreditTransactions,
        transactionCategoryId
      );
      const merchantSummaries = map(
        transactionGroupsByMerchantForACategory,
        (transactionGroupByMerchant, merchantId) => {
          const merchantTotal = getTotal2(
            transactionGroupByMerchant,
            deductCreditTransactions,
            transactionCategoryId
          );
          const merchantSummary = {
            id: merchantId,
            name: transactionGroupByMerchant[0].description,
            originalName: transactionGroupByMerchant[0].originalDescription,
            amount: Number(merchantTotal.toFixed(DECIMAL_PRECISION)),
            average: Number(
              (merchantTotal * averageFactor).toFixed(DECIMAL_PRECISION)
            ),
            percent: Number(
              ((merchantTotal / categoryTotal) * PERCENTAGE_OF_100).toFixed(
                DECIMAL_PRECISION_PERCENT
              )
            ),
            numberOfTransactions: transactionGroupByMerchant.length,
          };

          if (includeCashFlow) {
            merchantSummary[amountLabel] = merchantSummary.amount;
          }

          if (includeMerchantTransactions) {
            merchantSummary.transactions = transactionGroupByMerchant;
          }
          if (includeMerchantAggregates) {
            merchantSummary[getAggregatesLabel(includeCashFlow)] =
              getAggregatesByDateInterval({
                transactions: transactionGroupByMerchant,
                dateIntervals,
                intervalType,
                deductCreditTransactions,
                includeCashFlow,
                isIncome,
                transactionCategoryId,
                includeAggregateTransactions:
                  includeMerchantAggregateTransactions,
              });
          }
          return merchantSummary;
        }
      );
      merchantsByCategory[categoryId] = merchantSummaries;
    });
    return merchantsByCategory;
  }
);

export function getIncomeSummary(options) {
  const {
    transactions,
    averageFactor,
    dateIntervals,
    intervalType,
    categories,
    includeAggregates,
    includeAggregateTransactions,
    includeCategories,
    includeCategoryTransactions,
    includeCategoryAggregates,
    includeCategoryAggregateTransactions,
    includeMerchants,
    includeMerchantTransactions,
    includeMerchantAggregates,
    includeCashFlow,
    includeMerchantAggregateTransactions,
  } = options;
  const filteredTransactions = where(transactions, {
    includeInCashManager: true,
    isCredit: true,
  });
  const isIncome = true;
  const total = Number(
    filteredTransactions
      .reduce(
        (initialValue, transaction) => initialValue + transaction.amount,
        0
      )
      .toFixed(DECIMAL_PRECISION)
  );
  const average = Number((total * averageFactor).toFixed(DECIMAL_PRECISION));
  const deductCreditTransactions = false;

  const summary = {
    transactions: filteredTransactions,
    total,
    average,
  };
  if (includeAggregates) {
    summary[getAggregatesLabel(includeCashFlow)] = getAggregatesByDateInterval({
      transactions: filteredTransactions,
      dateIntervals,
      intervalType,
      includeAggregateTransactions,
      includeCashFlow,
      isIncome,
      deductCreditTransactions,
    });
  }

  if (includeCategories) {
    summary.categories = getCategorySummaries({
      transactions: filteredTransactions,
      categories,
      groupTotal: total,
      averageFactor,
      deductCreditTransactions,
      dateIntervals,
      intervalType,
      includeCategoryTransactions,
      includeCategoryAggregates,
      includeCashFlow,
      isIncome,
      includeCategoryAggregateTransactions,
    });
  }
  if (includeMerchants) {
    summary.merchants = getMerchantsGroupedByCategory({
      transactions: filteredTransactions,
      averageFactor,
      deductCreditTransactions,
      includeMerchantTransactions,
      dateIntervals,
      intervalType,
      includeMerchantAggregates,
      includeCashFlow,
      isIncome,
      includeMerchantAggregateTransactions,
    });
  }
  return summary;
}

export function getExpenseSummary(options) {
  const {
    transactions,
    averageFactor,
    dateIntervals,
    intervalType,
    categories,
    includeAggregates,
    includeAggregateTransactions,
    includeCategories,
    includeCategoryTransactions,
    includeCategoryAggregates,
    includeCategoryAggregateTransactions,
    includeMerchants,
    includeMerchantTransactions,
    includeMerchantAggregates,
    includeCashFlow,
    includeMerchantAggregateTransactions,
  } = options;
  const filteredTransactions = where(transactions, {
    includeInCashManager: true,
    isCredit: false,
  });
  const total = Number(
    filteredTransactions
      .reduce(
        (initialValue, transaction) => initialValue + transaction.amount,
        0
      )
      .toFixed(DECIMAL_PRECISION)
  );
  const average = Number((total * averageFactor).toFixed(DECIMAL_PRECISION));
  const deductCreditTransactions = false;

  const summary = {
    transactions: filteredTransactions,
    total,
    average,
  };
  if (includeAggregates) {
    summary[getAggregatesLabel(includeCashFlow)] = getAggregatesByDateInterval({
      transactions: filteredTransactions,
      dateIntervals,
      intervalType,
      includeAggregateTransactions,
      includeCashFlow,
      deductCreditTransactions,
    });
  }
  if (includeCategories) {
    summary.categories = getCategorySummaries({
      transactions: filteredTransactions,
      categories,
      groupTotal: total,
      averageFactor,
      deductCreditTransactions,
      dateIntervals,
      intervalType,
      includeCategoryTransactions,
      includeCategoryAggregates,
      includeCashFlow,
      includeCategoryAggregateTransactions,
    });
  }
  if (includeMerchants) {
    summary.merchants = getMerchantsGroupedByCategory({
      transactions: filteredTransactions,
      averageFactor,
      deductCreditTransactions,
      includeMerchantTransactions,
      dateIntervals,
      intervalType,
      includeMerchantAggregates,
      includeCashFlow,
      includeMerchantAggregateTransactions,
    });
  }
  return summary;
}

export function getBudgetingSummary(options) {
  const {
    transactions,
    averageFactor,
    dateIntervals,
    intervalType,
    categories,
    includeAggregates,
    includeAggregateTransactions,
    includeCategories,
    includeCategoryTransactions,
    includeCategoryAggregates,
    includeCategoryAggregateTransactions,
    includeMerchants,
    includeMerchantTransactions,
    includeMerchantAggregates,
    includeMerchantAggregateTransactions,
  } = options;
  // This excludes
  // 1. Reimbursable transactions (isSpending false)
  // 2. Transactions with income type category assigned (isSpending false)
  const filteredTransactions = where(transactions, {
    includeInCashManager: true,
    isSpending: true,
  });
  const deductCreditTransactions = true;
  let transactionsTotal;
  // Exclude Reimbursable splits before calculating the total.
  // This will set `excludeSplit` which will be used by UI to hide / show a split in budgeting page.
  excludeReimbursable(filteredTransactions);
  transactionsTotal = getTotal2(filteredTransactions, deductCreditTransactions);
  const total = Number(transactionsTotal.toFixed(DECIMAL_PRECISION));

  const average = Number((total * averageFactor).toFixed(DECIMAL_PRECISION));

  const summary = {
    transactions: filteredTransactions,
    total,
    average,
  };
  if (includeAggregates) {
    summary.aggregates = getAggregatesByDateInterval({
      transactions: filteredTransactions,
      dateIntervals,
      intervalType,
      includeAggregateTransactions,
      deductCreditTransactions,
    });
  }
  if (includeCategories) {
    summary.categories = getCategorySummaries({
      transactions: filteredTransactions,
      categories,
      groupTotal: total,
      averageFactor,
      deductCreditTransactions,
      dateIntervals,
      intervalType,
      includeCategoryTransactions,
      includeCategoryAggregates,
      includeCategoryAggregateTransactions,
    });
  }
  if (includeMerchants) {
    summary.merchants = getMerchantsGroupedByCategory({
      transactions: filteredTransactions,
      averageFactor,
      deductCreditTransactions,
      includeMerchantTransactions,
      dateIntervals,
      intervalType,
      includeMerchantAggregates,
      includeMerchantAggregateTransactions,
    });
  }

  return summary;
}

const getCashflowSummary = (transactionSummaries) => {
  const { income, expense, startDate, endDate, intervalType } =
    transactionSummaries;
  const {
    cashFlow: incomeCashFlow,
    categories: incomeCategories,
    merchants: incomeMerchants,
  } = income;
  const {
    cashFlow: expenseCashFlow,
    categories: expenseCategories,
    merchants: expenseMerchants,
  } = expense;
  const cashFlowSummary = {
    moneyIn: income.total,
    moneyOut: expense.total,
    averageIn: income.average,
    averageOut: expense.average,
    netCashFlow: income.total - expense.total,
    startDate,
    endDate,
    intervalType,
    incomeCategories,
    expenseCategories,
    expenseMerchants,
    incomeMerchants,
    income,
    expense,
    transactions: [...income.transactions, ...expense.transactions],
  };

  cashFlowSummary.cashFlow = incomeCashFlow.map((ia, index) => {
    return {
      date: ia.date,
      moneyIn: ia.moneyIn,
      moneyOut: expenseCashFlow[index].moneyOut,
    };
  });

  return cashFlowSummary;
};

const getCategoryBasedIncome = (options) => {
  const {
    transactions,
    averageFactor,
    dateIntervals,
    intervalType,
    categories,
    includeAggregates,
    includeAggregateTransactions,
    includeCategories,
    includeCategoryTransactions,
    includeCategoryAggregates,
    includeCategoryAggregateTransactions,
    includeMerchants,
    includeMerchantTransactions,
    includeMerchantAggregates,
    includeCashFlow,
    includeMerchantAggregateTransactions,
  } = options;
  const isIncome = true;
  const filteredTransactions = where(transactions, {
    includeInCashManager: true,
    categoryType: "INCOME",
  });
  const total = Number(
    filteredTransactions
      .reduce((initialValue, transaction) => {
        if (transaction.isCredit) {
          return initialValue + transaction.amount;
        }
        return initialValue - transaction.amount;
      }, 0)
      .toFixed(DECIMAL_PRECISION)
  );
  const average = Number((total * averageFactor).toFixed(DECIMAL_PRECISION));
  const summary = { transactions: filteredTransactions, total, average };
  const deductCreditTransactions = false;

  if (includeAggregates) {
    summary[getAggregatesLabel(includeCashFlow)] = getAggregatesByDateInterval({
      transactions: filteredTransactions,
      dateIntervals,
      intervalType,
      includeAggregateTransactions,
      includeCashFlow,
      isIncome,
      deductCreditTransactions,
    });
  }
  if (includeCategories) {
    summary.categories = getCategorySummaries({
      transactions: filteredTransactions,
      categories,
      groupTotal: total,
      averageFactor,
      deductCreditTransactions,
      dateIntervals,
      intervalType,
      includeCategoryTransactions,
      includeCategoryAggregates,
      includeCategoryAggregateTransactions,
    });
  }
  if (includeMerchants) {
    summary.merchants = getMerchantsGroupedByCategory({
      transactions: filteredTransactions,
      averageFactor,
      deductCreditTransactions,
      includeMerchantTransactions,
      dateIntervals,
      intervalType,
      includeMerchantAggregates,
      includeMerchantAggregateTransactions,
    });
  }
  return summary;
};

const getCategoryBasedExpense = (options) => {
  const {
    transactions,
    averageFactor,
    dateIntervals,
    intervalType,
    categories,
    includeAggregates,
    includeAggregateTransactions,
    includeCategories,
    includeCategoryTransactions,
    includeCategoryAggregates,
    includeCategoryAggregateTransactions,
    includeMerchants,
    includeMerchantTransactions,
    includeMerchantAggregates,
    includeCashFlow,
    includeMerchantAggregateTransactions,
  } = options;
  const filteredTransactions = where(transactions, {
    includeInCashManager: true,
    categoryType: "EXPENSE",
  });
  const total = Number(
    filteredTransactions
      .reduce((initialValue, transaction) => {
        if (transaction.isCredit) {
          return initialValue - transaction.amount;
        }
        return initialValue + transaction.amount;
      }, 0)
      .toFixed(DECIMAL_PRECISION)
  );
  const average = Number((total * averageFactor).toFixed(DECIMAL_PRECISION));
  const summary = { transactions: filteredTransactions, total, average };
  const deductCreditTransactions = false;

  if (includeAggregates) {
    summary[getAggregatesLabel(includeCashFlow)] = getAggregatesByDateInterval({
      transactions: filteredTransactions,
      dateIntervals,
      intervalType,
      includeAggregateTransactions,
      includeCashFlow,
      deductCreditTransactions,
    });
  }
  if (includeCategories) {
    summary.categories = getCategorySummaries({
      transactions: filteredTransactions,
      categories,
      groupTotal: total,
      averageFactor,
      deductCreditTransactions,
      dateIntervals,
      intervalType,
      includeCategoryTransactions,
      includeCategoryAggregates,
      includeCategoryAggregateTransactions,
    });
  }
  if (includeMerchants) {
    summary.merchants = getMerchantsGroupedByCategory({
      transactions: filteredTransactions,
      averageFactor,
      deductCreditTransactions,
      includeMerchantTransactions,
      dateIntervals,
      intervalType,
      includeMerchantAggregates,
      includeMerchantAggregateTransactions,
    });
  }
  return summary;
};

export function getAverageAmount(
  startDate,
  endDate,
  transactions,
  intervalType
) {
  const startDateObject = moment(startDate, DATE_FORMAT);
  if (!startDateObject.isValid()) {
    throw new Error(
      "`startDate` is required and must be a valid date string in the format: " +
        DATE_FORMAT
    );
  }

  const endDateObject = moment(endDate, DATE_FORMAT);
  if (!endDateObject.isValid()) {
    throw new Error(
      "`endDate` is required and must be a valid date string in the format: " +
        DATE_FORMAT
    );
  }

  const averageFactor =
    getMultiplicationFactor(intervalType) /
    getNumberOfDays(startDateObject, endDateObject);

  const totalAmount = getTotal(transactions);
  const averageAmount = totalAmount * averageFactor;
  if (typeof averageAmount != "number") {
    return NaN;
  }

  return Number(averageAmount.toFixed(DECIMAL_PRECISION));
}

export const getTransactionSummaries = memoizeOne((options) => {
  if (!options || typeof options !== "object") {
    throw new Error("`options` must be an object");
  }

  const { transactions, startDate, endDate, categories } = options;
  if (!transactions || !Array.isArray(transactions)) {
    throw new Error("`transactions` is required and must be an array");
  }

  const startDateObject = moment(startDate, DATE_FORMAT);
  if (!startDateObject.isValid()) {
    throw new Error(
      "`startDate` is required and must be a valid date string in the format: " +
        DATE_FORMAT
    );
  }

  const endDateObject = moment(endDate, DATE_FORMAT);
  if (!endDateObject.isValid()) {
    throw new Error(
      "`endDate` is required and must be a valid date string in the format: " +
        DATE_FORMAT
    );
  }
  const numberOfDays = getNumberOfDays(startDateObject, endDateObject);
  if (numberOfDays < 1) {
    throw new Error("`startDate` must be less than `endDate`");
  }

  if (!categories || typeof categories !== "object") {
    throw new Error("`categories` is required and must be an object");
  }

  let intervalType = options.intervalType;
  if (
    typeof intervalType === "string" &&
    !INTERVAL_TYPES.includes(intervalType)
  ) {
    throw new Error(
      "`intervalType` must be one of these strings: " +
        INTERVAL_TYPES.join(", ")
    );
  }
  if (!intervalType) {
    intervalType = getIntervalType(startDateObject, endDateObject);
  }

  // For cashflow Income and Expense are needed.
  if (options.includeCashFlow) {
    options.includeIncome = true;
    options.includeExpense = true;
  }

  defaults(options, DEFAULT_OPTIONS);
  const summaryOptions = {
    transactions,
    intervalType,
    categories,
    includeAggregates: options.includeAggregates,
    includeAggregateTransactions: options.includeAggregateTransactions,
    includeCategories: options.includeCategories,
    dateIntervals: getDateIntervals(
      startDateObject,
      endDateObject,
      intervalType
    ),
    averageFactor: getMultiplicationFactor(intervalType) / numberOfDays,
    includeCategoryTransactions: options.includeCategoryTransactions,
    includeCategoryAggregates: options.includeCategoryAggregates,
    includeCategoryAggregateTransactions:
      options.includeCategoryAggregateTransactions,
    includeMerchants: options.includeMerchants,
    includeMerchantTransactions: options.includeMerchantTransactions,
    includeMerchantAggregates: options.includeMerchantAggregates,
    includeCashFlow: options.includeCashFlow,
    includeMerchantAggregateTransactions:
      options.includeMerchantAggregateTransactions,
  };

  let transactionSummaries = { transactions, startDate, endDate, intervalType };
  if (options.includeIncome) {
    if (options.includeCategoryBasedCashFlow) {
      transactionSummaries.income = getCategoryBasedIncome(summaryOptions);
    } else {
      transactionSummaries.income = getIncomeSummary(summaryOptions);
    }
  }

  if (options.includeExpense) {
    if (options.includeCategoryBasedCashFlow) {
      transactionSummaries.expense = getCategoryBasedExpense(summaryOptions);
    } else {
      transactionSummaries.expense = getExpenseSummary(summaryOptions);
    }
  }

  if (options.includeCashFlow) {
    transactionSummaries.cashFlowSummary =
      getCashflowSummary(transactionSummaries);
  }

  if (options.includeBudgeting) {
    transactionSummaries.budgeting = getBudgetingSummary(summaryOptions);
  }

  return transactionSummaries;
});
