import moment from "moment";
import { compositeComparator } from "../../../common/comparators/composerUtil";
import objectPath from "object-path";
import { isEmpty } from "underscore";
import { API_FORMAT } from "libs/pcap/utils/date2";

const BUSINESS_TAG_ID = 100001;
const pendingComparator = (accessor, order) => {
  return (a, b) => {
    // pin pending transactions to the top regardless of order.
    const isPendingA = Number(a.status === "pending");
    const isPendingB = Number(b.status === "pending");

    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }

    return isPendingB - isPendingA;
  };
};

export const timeComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }

    return (
      moment(a.transactionDate, API_FORMAT) -
      moment(b.transactionDate, API_FORMAT)
    );
  };
};

const userTransactionIdComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }

    return a.userTransactionId - b.userTransactionId;
  };
};

const alphaComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }
    let aVal = accessor(a).toUpperCase();
    let bVal = accessor(b).toUpperCase();

    return aVal.localeCompare(bVal);
  };
};

const transactionAmountComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }
    let amountA = a.amount || 0;
    let amountB = b.amount || 0;

    const aVal = a.isCredit ? amountA : amountA * -1;
    const bVal = b.isCredit ? amountB : amountB * -1;

    return aVal - bVal;
  };
};

const transactionDescriptionComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }
    let aVal = accessor(a).description.toUpperCase();
    let bVal = accessor(b).description.toUpperCase();

    return aVal.localeCompare(bVal);
  };
};

const priceAndTimeComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }

    return (a.price || 0) - (b.price || 0);
  };
};

const quantityComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }
    return (a.quantity || 0) - (b.quantity || 0);
  };
};

const tagAndTimeComparator = (accessor, order) => {
  return (a, b) => {
    if (order === "desc") {
      const temp = a;
      a = b;
      b = temp;
    }

    const systemTagsA = objectPath.get(a, "customTags.systemTags", []);
    const systemTagsB = objectPath.get(b, "customTags.systemTags", []);
    const userTagsA = objectPath.get(a, "customTags.userTags", []);
    const userTagsB = objectPath.get(b, "customTags.userTags", []);

    let singleTagSortOrderA;
    let singleTagSortOrderB;

    if (
      systemTagsA.length === 1 &&
      systemTagsB.length === 1 &&
      isEmpty(userTagsA) &&
      isEmpty(userTagsB)
    ) {
      singleTagSortOrderA = systemTagsA[0] - BUSINESS_TAG_ID;
      singleTagSortOrderB = systemTagsB[0] - BUSINESS_TAG_ID;
    }

    return (
      systemTagsA.length +
        userTagsA.length -
        (systemTagsB.length + userTagsB.length) ||
      singleTagSortOrderA - singleTagSortOrderB ||
      moment(a.transactionDate, API_FORMAT) -
        moment(b.transactionDate, API_FORMAT)
    );
  };
};

export const compositeTimeComparator = (accessor, order) => {
  return compositeComparator([
    pendingComparator(accessor, order),
    timeComparator(accessor, order),
    userTransactionIdComparator(accessor, order),
  ]);
};

export const compositeAlphaAndTimeComparator = (accessor, order) => {
  return compositeComparator([
    pendingComparator(accessor, order),
    alphaComparator(accessor, order),
    timeComparator(accessor, order),
  ]);
};

export const compositeAmountAndTimeComparator = (accessor, order) => {
  return compositeComparator([
    pendingComparator(accessor, order),
    transactionAmountComparator(accessor, order),
    timeComparator(accessor, order),
  ]);
};

export const compositeDescriptionAndTimeComparator = (accessor, order) => {
  return compositeComparator([
    pendingComparator(accessor, order),
    transactionDescriptionComparator(accessor, order),
    timeComparator(accessor, order),
  ]);
};

export const compositePriceAndTimeComparator = (accessor, order) => {
  return compositeComparator([
    pendingComparator(accessor, order),
    priceAndTimeComparator(accessor, order),
    timeComparator(accessor, order),
  ]);
};

export const compositeQuantityAndTimeComparator = (accessor, order) => {
  return compositeComparator([
    pendingComparator(accessor, order),
    quantityComparator(accessor, order),
    timeComparator(accessor, order),
  ]);
};

export const compositeTagsAndTimeComparator = (accessor, order) => {
  return compositeComparator([
    pendingComparator(accessor, order),
    tagAndTimeComparator(accessor, order),
  ]);
};
