import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import EditPlanView from "components/financialPlanner/EditPlanView";
import SetMasterPlanView from "components/financialPlanner/SetMasterPlanView";
import {
  EDIT_PLAN_VIEW,
  SET_MASTER_PLAN_VIEW,
} from "components/financialPlanner/financialPlannerConstants";

const EditPlanModal = (props) => {
  let modalTitle;
  let currentView = null;

  if (props.viewType === EDIT_PLAN_VIEW) {
    modalTitle = "Edit Scenario";
    currentView = (
      <EditPlanView
        errors={props.errors}
        renamePlan={props.renamePlan}
        plan={props.plan}
        onDelete={props.onDelete}
        onClosed={props.onClosed}
        onViewChange={props.onViewChange}
        isGMWB={props.isGMWB}
        accounts={props.accounts}
      />
    );
  } else if (props.viewType === SET_MASTER_PLAN_VIEW) {
    modalTitle = "Change Official Plan";
    currentView = (
      <SetMasterPlanView
        plan={props.plan}
        makePlanMaster={props.makePlanMaster}
        onClosed={props.onClosed}
        planComparison={props.planComparison}
        maModelPortfolios={props.maModelPortfolios}
        isGMWB={props.isGMWB}
        accounts={props.accounts}
      />
    );
  }

  return (
    <Modal
      componentName={props.componentName}
      contentLabel={modalTitle}
      title={modalTitle}
      loading={props.loading}
      onClosed={props.onClosed}
      isOpen={props.isOpen}
      className="pc-modal--small qa-edit-scenario-modal"
      overlayClassName="forecast-react-modal"
    >
      {currentView}
    </Modal>
  );
};

EditPlanModal.propTypes = {
  componentName: PropTypes.string,
  errors: PropTypes.array,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  makePlanMaster: PropTypes.func,
  onClosed: PropTypes.func,
  onDelete: PropTypes.func,
  onViewChange: PropTypes.func,
  plan: PropTypes.object.isRequired,
  planComparison: PropTypes.object,
  renamePlan: PropTypes.func,
  viewType: PropTypes.string,
  isGMWB: PropTypes.bool,
  maModelPortfolios: PropTypes.array,
  accounts: PropTypes.array,
};

EditPlanModal.defaultProps = {
  accounts: [],
};

export default EditPlanModal;
