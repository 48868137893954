import React from "react";
import PropTypes from "prop-types";

const DocumentVaultDeleteConfirm = (props) => {
  const { onCancel, onDelete, filename } = props;
  return (
    <>
      <p className="u-text-bold">You are about to delete this document:</p>
      <p>{filename}</p>
      <p>
        By deleting this document, you won&apos;t be able to see it again. If in
        the future you want to check this document, you need to upload it.
      </p>
      <div className="u-text-right pc-u-mt">
        <button
          type="button"
          className="pc-btn js-document-vault-delete-cancel"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="pc-btn pc-btn--primary js-document-vault-delete-confirm qa-document-vault-delete-confirm"
          onClick={onDelete}
        >
          Delete
        </button>
      </div>
    </>
  );
};

DocumentVaultDeleteConfirm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
};

DocumentVaultDeleteConfirm.defaultProps = {};

export default DocumentVaultDeleteConfirm;
