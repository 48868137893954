import PropTypes from "prop-types";
import React from "react";
import PostLoginActionsProvider from "./PostLoginActionsProvider";
import ProjectionSummaryContainer from "components/projectionSummary/ProjectionSummaryContainer.js";

const ProjectionChartPLA = (props) => {
  return (
    <PostLoginActionsProvider {...props}>
      {({ getButtons, userMessage, jsonContent }) => (
        <div>
          <div className="text-with-projection-chart__container">
            <div className="text-with-projection-chart__content">
              <h1
                className="text-with-projection-chart__title"
                dangerouslySetInnerHTML={{ __html: userMessage.title }}
              />
              <div
                className="pc-u-mb"
                dangerouslySetInnerHTML={{ __html: userMessage.summary }}
              />
              {getButtons({
                getButtonClassName: () =>
                  "simple-image__cta pc-btn pc-btn--small pc-btn--primary",
              })}
            </div>
            <div>
              <ProjectionSummaryContainer
                title={null}
                hideForm={true}
                newContributionAmount={jsonContent.newContributionAmount}
              />
            </div>
          </div>
        </div>
      )}
    </PostLoginActionsProvider>
  );
};

ProjectionChartPLA.propTypes = {
  componentName: PropTypes.string,
  userMessage: PropTypes.object,
};

ProjectionChartPLA.defaultProps = {
  componentName: "",
  userMessage: PropTypes.object,
};

export default ProjectionChartPLA;
