/**
 * Chains a comparator function to another comparator
 * and returns the result of the first comparator, unless
 * the first comparator returns 0, in which case the
 * result of the second comparator is used.
 *
 * @param {function} first - a function with the first comparator to be chained
 * @param {function} next - a function with the next comparator to chain
 * @returns {function} The chained comparators.
 */
export const makeChainedComparator = (first, next) => {
  return function (a, b) {
    var result = first(a, b);
    if (result !== 0) return result;
    return next(a, b);
  };
};

/**
 * Given an array of comparators, returns a new comparator with
 * descending priority such that
 * the next comparator will only be used if the precending on returned
 * 0 (ie, found the two objects to be equal)
 *
 * Allows multiple sorts to be used simply. For example,
 * sort by column a, then sort by column b, then sort by column c
 *
 * @param {array} comparators - an array with the comparator functions to chain
 * @returns {function} The chained comparators.
 */
export const compositeComparator = (comparators) => {
  return comparators.reduceRight(function (memo, comparator) {
    return makeChainedComparator(comparator, memo);
  });
};
