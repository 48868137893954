import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AccountStatus from "components/transferFunds/AccountStatus";

export default function MicroDepositInitiatedStatus({
  onChooseDifferentAccount,
  subTitle,
  title,
}) {
  useEffect(() => {
    if (IS_EMPOWER && title === "Bank verification")
      window.dashboardUtils?.eventBus.dispatch("bank_verification.page.load");
  }, [title]);

  return (
    <AccountStatus
      title={title}
      subTitle={subTitle}
      trackingComponentName="Transfer Funds"
      trackingPageName="Micro Deposit Initiated Status"
      footer={
        <div className="u-text-right">
          <a href={DASHBOARD_URL} className="pc-btn">
            Go back to Dashboard
          </a>
          <button
            type="button"
            onClick={onChooseDifferentAccount}
            className="pc-btn"
          >
            Choose a Different Account
          </button>
        </div>
      }
    />
  );
}

MicroDepositInitiatedStatus.propTypes = {
  title: PropTypes.string,
  onChooseDifferentAccount: PropTypes.func.isRequired,
  subTitle: PropTypes.string,
};

MicroDepositInitiatedStatus.defaultProps = {
  title: "We need to verify your account...",
  subTitle: "",
};
