import $ from "jquery";
import objectPath from "object-path";

const URL_PCAP_CASH_PLA_DISCLAIMER_DATA =
  "https://www.personalcapital.com/api/data/pcb-numbers?callback=?";

// Get remote PCAP Cash live data once and save it in a promise for reuse throughout the app
let pcapCashLiveDataPromise;

export const getPcapCashLiveDataPromise = () => {
  if (!pcapCashLiveDataPromise) {
    pcapCashLiveDataPromise = new Promise((resolve, reject) => {
      $.getJSON(URL_PCAP_CASH_PLA_DISCLAIMER_DATA, {}, (data) => {
        const fetchedData = objectPath.get(data, "spData");
        if (fetchedData) {
          resolve(fetchedData);
        } else {
          reject(null);
        }
      });
    });
  }
  return pcapCashLiveDataPromise;
};

export const prefetchPcapCashLiveData = () =>
  pcapCashLiveDataPromise || getPcapCashLiveDataPromise();

export const isPcapCashPostLoginAction = (userMessage) =>
  userMessage && userMessage.viewTemplate === "PCAP_CASH_PLA";
