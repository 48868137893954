import React from "react";
import PropTypes from "prop-types";
import { promisify } from "utils/service";
import Services from "services";
import { noop } from "underscore";
import TransferFundsContainer from "empower/components/TransferFunds/TransferFundsContainer";
import { TRANSFER_TYPE_CUSTOM } from "components/transferFunds/utils/constants";
import {
  STEP_TRANSFER_FUNDS,
  STEP_REVIEW,
  STEP_CONFIRMATION,
} from "empower/components/TransferFunds/TransferFundsWizard/constants";

const EDIT_TRANSFERS_WIZARD_FLOW = [
  STEP_TRANSFER_FUNDS,
  STEP_REVIEW,
  STEP_CONFIRMATION,
];

const EditRecurringTransfersContainer = (props) => {
  const {
    transfer,
    onCancel,
    serviceUpdateInstruction,
    onTransferFundSubmitted,
  } = props;

  return (
    <TransferFundsContainer
      model={transfer}
      transferType={TRANSFER_TYPE_CUSTOM}
      onBack={onCancel}
      onClose={onTransferFundSubmitted}
      isToDropDownDisabled={true}
      isFromDropDownDisabled={true}
      hasOneTimeFrequency={false}
      serviceSubmitTransfer={serviceUpdateInstruction}
      trackEventSubmit={noop}
      trackEventSubmitErrors={noop}
      pageBuilder={null}
      hasRecurringTransferEstablished={false}
      wizardPages={EDIT_TRANSFERS_WIZARD_FLOW}
    />
  );
};

EditRecurringTransfersContainer.defaultProps = {
  serviceUpdateInstruction: promisify(
    Services.Transfer.updateTransferInstruction,
    true
  ),
  onTransferFundSubmitted: undefined,
};

EditRecurringTransfersContainer.propTypes = {
  serviceUpdateInstruction: PropTypes.func,
  transfer: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onTransferFundSubmitted: PropTypes.func,
};

export default EditRecurringTransfersContainer;
