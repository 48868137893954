import _ from "underscore";
import {
  timeMonth,
  timeYear,
  line as lineD3,
  transition as transitionD3,
  easeCubicIn,
  interpolateArray,
  easeCubicOut,
  easeSinOut,
  easeBackOut,
  select,
} from "d3";
import Backbone from "backbone";
import chart from "libs/pcap/chart/chart";
import line from "libs/pcap/chart/series/line";
import moment from "moment";
import template from "templates/partials/cashflow/cashFlowBarGraph.html";
import { formatCurrency } from "../../../libs/pcap/utils/format";

var ANIMATION_DURATION = 250;
var ANIMATION_DURATION_UPDATE = 500;
var ANIMATION_DELAY_NET_LINE_CREATE = 1000;
var ANIMATION_DELAY_NET_LINE_UPDATE = 400;
var ANIMATION_POINT_OVERSHOOT = 2.2;
var TWO_YEARS = 25;

var ACCENT_RADIUS = 3;
var BARS_INNER_PADDING = 0.2;
var HALF = 2;
var AXIS_TICK_SIZE = 5;

var ARIA_LABELS = {
  chartTitle: "Bar graph of income and expenses by month",
  chartDescription: "Income as green bars, expense as orange bars, cash flow as gray line",
}

function buildIncomeCashFlowDataSeries(data) {
  return data.map(function (data) {
    return {
      x: moment(data.date),
      y: data.moneyIn,
    };
  });
}

function buildExpenseCashFlowDataSeries(data) {
  return data.map(function (data) {
    return {
      x: moment(data.date),
      y: -data.moneyOut,
    };
  });
}

function buildNetCashFlowDataSeries(data) {
  return data.map(function (data) {
    return {
      x: moment(data.date),
      y: data.moneyIn - data.moneyOut,
    };
  });
}

function zeroArray(d) {
  return d.map(function (d) {
    return {
      x: d.x,
      y: 0,
    };
  });
}

export default Backbone.View.extend({
  className: "cash-flow__bar-chart",

  initialize: function (options) {
    this.data = options.data;
    this.chartTitle = ARIA_LABELS.chartTitle;
    this.chartDescription = ARIA_LABELS.chartDescription;
    this.render();
  },

  render: function () {
    this.$el.html(
      template({
        graphAltText: this.chartTitle,
      })
    );
    this.chartEl = this.$("svg")[0];

    this.chart = chart({
      type: "bar",
      key: function (d) {
        return d.x.toISOString();
      },
      tooltip: {
        xFormat: (d) => d.format("MMMM, YYYY"),
        yFormat: formatCurrency,
      },
    });

    this.chart.xScale.padding(BARS_INNER_PADDING);
    this.netShape = line({
      x: this.chart.options.x,
      y: this.chart.options.y,
      yScale: this.chart.options.yScale,
    });

    this.drawChart();

  },

  /**
   * Transitions the net line to 0.
   *
   * This method is used to hide the net line before the update animation for the chart starts.
   * The net line will apear again as part of the chart update.
   *
   * @returns {$.Deferred} the deferred object which is resolved when the transition is finished
   */
  hideNetLine: function () {
    var xScale = this.chart.options.xScale;
    var yScale = this.chart.options.yScale;
    var x = this.chart.x();
    var y = this.chart.y();
    var yScaledAccessor = function (d) {
      return yScale(y(d));
    };
    var barCenter = xScale.bandwidth() / HALF;
    var xScaledAccessor = function (d) {
      return xScale(x(d)) + barCenter;
    };

    var netLine = lineD3().x(xScaledAccessor).y(yScaledAccessor);

    var paths = this.chartBody.selectAll(".cash-flow__net-line");
    var circles = this.chartBody.selectAll(".cash-flow__net-accent");

    var pathTransition = $.Deferred();
    var circleTransition = $.Deferred();

    var transition = transitionD3()
      .ease(easeCubicIn)
      .duration(ANIMATION_DURATION);

    paths
      .transition(transition)
      .style("opacity", 0)
      // transition the path to 0
      .tween("attr.d", function (d) {
        var self = this; /* `this` is the current element */ // eslint-disable-line no-invalid-this
        var i = interpolateArray(d, zeroArray(d));
        return function (t) {
          self.setAttribute("d", netLine(i(t)));
        };
      })
      .remove()
      .on(
        "end",
        _.after(paths.size(), function () {
          pathTransition.resolve();
        })
      );

    circles
      .transition(transition)
      .duration(ANIMATION_DURATION / HALF)
      .style("opacity", 0)
      .attr("cy", function (d) {
        return yScale(d.y / HALF);
      })
      .remove()
      .on(
        "end",
        _.after(circles.size(), function () {
          circleTransition.resolve();
        })
      );

    return $.when(pathTransition, circleTransition);
  },

  /**
   * Draws the net of income minus expense.
   *
   * @param {Array}         data      the array of net points.
   */
  drawNetLine: function (data) {
    //console.trace('drawNetLine', data);
    var y = this.chart.y();
    var yScale = this.chart.options.yScale;
    var yScaledAccessor = function (d) {
      return yScale(y(d));
    };
    var x = this.chart.x();
    var xScale = this.chart.options.xScale;
    var barCenter = xScale.bandwidth() / HALF;
    var xScaledAccessor = function (d) {
      return xScale(x(d)) + barCenter;
    };
    var line = lineD3().x(xScaledAccessor).y(yScaledAccessor);

    var path = this.chartBody.selectAll(".cash-flow__net-line").data([data]);

    var circles = this.chartBody.selectAll(".cash-flow__net-accent").data(data);

    var updateTransition = transitionD3()
      .delay(ANIMATION_DELAY_NET_LINE_UPDATE)
      .duration(ANIMATION_DURATION_UPDATE);

    // update
    path.transition(updateTransition).attr("d", line);

    circles
      .transition(updateTransition)
      .attr("cx", xScaledAccessor)
      .attr("cy", yScaledAccessor);

    path.exit().remove();
    circles.exit().remove();

    var createTransition = transitionD3()
      .delay(ANIMATION_DELAY_NET_LINE_CREATE)
      .duration(ANIMATION_DURATION);
    var createAccentTransition = createTransition.transition();

    // create
    path
      .enter()
      .append("path")
      .style("opacity", 0)
      .classed("cash-flow__net-line", true)
      .transition(createTransition)
      .ease(easeCubicOut)
      .style("opacity", 1)
      .tween("attr.d", function (d) {
        var self = this; /* `this` is the current element */ // eslint-disable-line no-invalid-this
        var i = interpolateArray(zeroArray(d), d);
        return function (t) {
          self.setAttribute("d", line(i(t)) || "");
        };
      });

    var t = circles
      .enter()
      .append("circle")
      .classed("cash-flow__net-accent", true)
      .attr("r", 0)
      .attr("cx", xScaledAccessor)
      .attr("cy", yScaledAccessor)
      .transition(createAccentTransition);

    t.delay(function (d, i) {
      return ANIMATION_DURATION * easeSinOut(i / t.size());
    })
      .ease(easeBackOut.overshoot(ANIMATION_POINT_OVERSHOOT))
      .attr("r", ACCENT_RADIUS);
  },

  drawChart: function () {
    var data = this.data.cashFlow;
    const numberOfDataPoints = data?.length || 0;
    if (numberOfDataPoints <= TWO_YEARS) {
      this.chart.xAxis
        .ticks(timeMonth.every(1))
        .tickFormat((d) => {
          if (d.month() === 0) {
            return `${d.format("MMM'YY")}`;
          }

          return `${d.format("MMM")}`;
        })
        .tickSizeInner(AXIS_TICK_SIZE);
    } else {
      this.chart.xAxis
        .ticks(timeYear)
        .tickFormat((d) => {
          if (d.month() === 0) {
            return `Jan'${d.format("YY")}`;
          }

          return "";
        })
        .tickSizeInner(AXIS_TICK_SIZE);
    }

    this.chartBody = select(this.chartEl)
      .datum([
        buildIncomeCashFlowDataSeries(data),
        buildExpenseCashFlowDataSeries(data),
      ])
      .call(this.chart)
      .select(".js-chart-body");

    var net = buildNetCashFlowDataSeries(data);
    this.drawNetLine(net);
    if (this.$("svg").find("#cash-flow-bar-chart-title").length === 0) {
      this.$("svg").prepend(`<title id="cash-flow-bar-chart-title">${this.chartTitle}</title><desc id="cash-flow-bar-chart-desc">${this.chartDescription}</desc>`)
      this.$("svg").attr("aria-labelledby", "cash-flow-bar-chart-title")
      this.$("svg").attr("aria-describedby", "cash-flow-bar-chart-desc");
    }
  },

  /*
   * Verifies if the incoming data is of the same domain as the existing one.
   * The domain is considered the same if:
   *    1. The data sets are the same.
   *    2. The data sets are empty.
   *    3. The data sets are of the same length and the date of the first and last datum match.
   *
   * @param   {Array}   data  the data array to check against the instance data.
   * @returns {Boolean} the boolean flag
   */
  isSameDomain: function (data) {
    var thisCashFlow = this.data.cashFlow;
    var thatCashFlow = data.cashFlow;
    return (
      thisCashFlow === thatCashFlow ||
      (_.isEmpty(thisCashFlow) && _.isEmpty(thatCashFlow)) ||
      (thisCashFlow.length === thatCashFlow.length &&
        _.first(thisCashFlow).date === _.first(thatCashFlow).date &&
        _.last(thisCashFlow).date === _.last(thatCashFlow).date)
    );
  },

  /**
   * Updates the chart with new data.
   * @param {Array}  data    Cash flow data.
   */
  update: function (data) {
    var isSameDomain = this.isSameDomain(data);
    this.data = data;
    if (isSameDomain) {
      this.drawChart();
    } else {
      $.when(this.hideNetLine()).done(
        function () {
          this.drawChart();
        }.bind(this)
      );
    }
  },
});
