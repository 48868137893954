import React from "react";
import PropTypes from "prop-types";
import InfoTooltipText from "components/common/InfoTooltipText";

const EditAccountFooter = (props) => {
  return (
    <div className="l-spaced l-spaced--flush pc-modal__footer edit-account-footer pc-u-ph pc-u-pv--">
      <div className="edit-account-footer__left">
        {props.hasMarkAsClosed && (
          <div className="js-mark-closed qa-mark-closed">
            <button
              type="button"
              className="pc-btn pc-btn--edit-account-link milli u-text-bold"
              onClick={props.onMarkAsClosed}
            >
              Mark account as closed
            </button>
          </div>
        )}
        {props.hasRemove && (
          <div className="js-remove-account qa-remove-account">
            {props.isRemoveDisabled ? (
              <InfoTooltipText
                text="Remove account"
                tooltipContent={props.removeDisabledReason}
                className="u-text-bold"
              />
            ) : (
              <button
                type="button"
                className="pc-btn pc-btn--edit-account-link milli u-text-bold"
                disabled={props.isRemoveDisabled}
                onClick={props.onRemove}
              >
                Remove account
              </button>
            )}
          </div>
        )}
      </div>
      <div className="edit-account-footer__right">
        <button
          type="button"
          className="pc-btn pc-btn--cancel qa-edit-account-cancel"
          onClick={props.onCancel}
        >
          Cancel
        </button>
        {props.hasSave && (
          <button
            type="submit"
            className={`pc-btn pc-btn--primary js-save-account qa-save-account ${(props.isSaveDisabled || props.isPrivileged )? "is-disabled" : ""}`}
            onClick={props.onSave}
            aria-disabled={props.isSaveDisabled || props.isPrivilegedd} 
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};

EditAccountFooter.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onMarkAsClosed: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  hasMarkAsClosed: PropTypes.bool,
  hasRemove: PropTypes.bool,
  hasSave: PropTypes.bool,
  isRemoveDisabled: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  removeDisabledReason: PropTypes.string,
  isPrivileged: PropTypes.bool,
};

EditAccountFooter.defaultProps = {
  hasMarkAsClosed: true,
  hasRemove: true,
  hasSave: true,
  isRemoveDisabled: false,
  removeDisabledReason: "",
  isSaveDisabled: false,
  isPrivileged: false,
};

export default EditAccountFooter;
