import Services from "services";

const markUserMessageAsImpressed = (id) => {
  Services.UserMessages.updateV2({
    userMessageIds: "[" + id + "]",
    actions: '["markImpressed"]',
  });
};

export default markUserMessageAsImpressed;
