/* eslint-disable camelcase, no-magic-numbers */

import Raphael from "raphael";

import $ from "jquery";
import * as COLORS from "libs/pcap/utils/colors";

(function (Raphael) {
  Raphael.fn.percentageWheel = function (initVal) {
    this.customAttributes.arc = function (xloc, yloc, value, total, R) {
      var alpha = (360 / total) * value,
        a = ((90 - alpha) * Math.PI) / 180,
        x = xloc + R * Math.cos(a),
        y = yloc - R * Math.sin(a),
        path;
      if (total === value) {
        path = [
          ["M", xloc, yloc - R],
          ["A", R, R, 0, 1, 1, xloc - 0.01, yloc - R],
        ];
      } else {
        path = [
          ["M", xloc, yloc - R],
          ["A", R, R, 0, Number(alpha > 180), 1, x, y],
        ];
      }
      return {
        path: path,
      };
    };

    this.circle(78, 78, 75).attr({ fill: "#ccc", stroke: "none" });
    this.circle(78, 78, 73).attr({ fill: COLORS.NEGATIVE, stroke: "none" });

    var my_arc = this.path().attr({
      stroke: "#fff",
      "stroke-width": 48,
      arc: [78, 78, initVal, 100, 50],
    });

    this.circle(78, 78, 55).attr({ fill: "#fff", stroke: "#ccc" });

    $("#wheelContainer .percentage").text(initVal + "%");

    return {
      update: function (val, bounce, speed) {
        var bounceString = "";
        speed = speed || 300;
        if (bounce) {
          bounceString = "bounce";
        }
        my_arc.stop().animate(
          {
            arc: [78, 78, val, 100, 50],
          },
          speed,
          bounceString
        );
        $("#percentageLeft span").html(100 - val + "<sup>%</sup>");
      },
    };
  };
})(Raphael);
