/* eslint-disable no-negated-condition, no-invalid-this, no-underscore-dangle */
import _ from "underscore";
import Backbone from "backbone";
import Account from "models/account";
var AccountsCollection = Backbone.Collection.extend({
  model: Account,
  idAttribute: "accountId",
  initialize: function () {
    this.on("remove", this.removeAccount);
  },
  removeAccount: function (account) {
    account.trigger("removed", account);
  },
  comparator: function (account) {
    var firmName = !_.isUndefined(account.get("firmName"))
        ? account.get("firmName").toLowerCase()
        : account.get("firmName"),
      name = !_.isUndefined(account.get("name"))
        ? account.get("name").toLowerCase()
        : account.get("name");
    return firmName + name;
  },
  freshen: function (objects) {
    // modified from: http://stackoverflow.com/questions/8211888/merge-backbone-collection-with-server-response
    var model;
    // Mark all for removal
    this.each(function (m) {
      m._remove = true;
    });
    // Apply each object
    _(objects).each(function (attrs) {
      model = this.get(attrs[this.idAttribute]);
      if (model) {
        model.set(attrs); // existing model
        delete model._remove;
      } else {
        this.add(attrs); // new model
      }
    }, this);
    // Now check for any that are still marked for removal
    var toRemove = this.filter(function (m) {
      return m._remove;
    });
    _(toRemove).each(function (m) {
      this.remove(m);
    }, this);
    this.trigger("freshen", this);
  },
  addAccounts: function (objects) {
    // Add each object
    _(objects).each(function (attrs) {
      this.add(attrs); // new model
    }, this);
    this.trigger("addAccounts", this);
  },
});
export default AccountsCollection;
