import PropTypes from "prop-types";
import React from "react";
import WhatToExpectTest from "../WhatToExpectTest";
import Modal from "components/modal/Modal";

const WhatToExpectTestModal = ({
  onContinue,
  onBack,
  isOpen,
  name,
  logoPath,
  isOAuth,
}) => {
  return (
    <Modal
      componentName="WhatToExpectTestModal"
      isOpen={isOpen}
      onClosed={onBack}
      className="pc-modal expectation-modal"
      shouldCloseOnOverlayClick={false}
    >
      <WhatToExpectTest
        onContinue={onContinue}
        onBack={onBack}
        name={name}
        logoPath={logoPath}
        isOAuth={isOAuth}
      />
    </Modal>
  );
};

WhatToExpectTestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  name: PropTypes.string,
  logoPath: PropTypes.string,
  isOAuth: PropTypes.bool,
};

WhatToExpectTestModal.defaultProps = {
  name: undefined,
  logoPath: undefined,
  isOAuth: false,
};
export default WhatToExpectTestModal;
