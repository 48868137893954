import PropTypes from "prop-types";
import React from "react";
import { promisify, subscribify } from "utils/service";
import Services from "services";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import InputTags from "components/common/InputTags";
import { isEmpty, noop, isEqual } from "underscore";
import tagsToClient from "components/common/InputTags/tagsToClient";
import { trackEvent } from "components/common/ComponentAnalytics";
import { isEmpowerPrivilegedMode } from "../../../../views/modules/sidebar/utils/accountUtils";

const isPrivileged = isEmpowerPrivilegedMode();

export default class InputTagsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tags: tagsToClient(props.tags),
    };

    this.handleFailure = this.handleFailure.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleCreateTag = this.handleCreateTag.bind(this);
    this.handleUpdateTag = this.handleUpdateTag.bind(this);
    this.handleDeleteTag = this.handleDeleteTag.bind(this);
    this.handleCreateUpdateTagClosed =
      this.handleCreateUpdateTagClosed.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.tags)) {
      const tags = tagsToClient(nextProps.tags);
      if (!isEqual(tags, prevState.tags)) {
        return {
          tags,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    if (isEmpty(this.props.tags)) {
      this.setState({ loading: true });
      if (this.getTransactionsTagsSubscription) {
        this.getTransactionsTagsSubscription.unwatch();
        this.getTransactionsTagsSubscription.off("change");
      }

      this.getTransactionsTagsSubscription = this.props.getTags();

      this.getTransactionsTagsSubscription.on("change", this.handleSuccess);

      this.fetchTransactionTagsCancelablePromise = makeCancelablePromise(
        this.getTransactionsTagsSubscription.promise
      );

      this.fetchTransactionTagsCancelablePromise.promise
        .then(this.handleSuccess)
        .catch(this.handleFailure);
    }
  }

  handleSuccess(tags) {
    this.setState({
      loading: false,
      tags: tagsToClient(tags),
      errors: undefined,
    });
  }

  handleCreateUpdateTagClosed() {
    if (!isEmpty(this.state.errors)) {
      this.setState({ errors: [] });
    }
  }

  handleCreateTag(tagName) {
    if (isPrivileged) {
      return;
    }

    this.setState({ loading: true });
    const createTagPromise = this.props.createTag({ tagName });
    createTagPromise
      .then(() => {
        trackEvent("Custom Tags", "Create Tag", {
          // eslint-disable-next-line camelcase
          custom_tag_name: tagName,
        });
        this.setState({ loading: false });
      })
      .catch(this.handleFailure);
    return createTagPromise;
  }

  handleUpdateTag(tag) {
    this.setState({ loading: true });
    const { tagId, customTagName: tagName } = tag;
    const updateTagPromise = this.props.updateTag({ tagId, tagName });
    updateTagPromise
      .then(() => {
        const tagOld = this.state.tags.find((t) => t.value === tag.tagId);
        trackEvent("Custom Tags", "Update Tag", {
          // eslint-disable-next-line camelcase
          custom_tag_name: tag.tagName,
          // eslint-disable-next-line camelcase
          old_custom_tag_name: tagOld ? tagOld.label : "",
        });
        this.setState({ loading: false });
      })
      .catch(this.handleFailure);
    return updateTagPromise;
  }

  handleDeleteTag(tagId) {
    this.setState({ loading: true });
    const deleteTagPromise = this.props.deleteTag({ tagId });
    deleteTagPromise
      .then(() => {
        const tag = this.state.tags.find((t) => t.value === tagId);
        trackEvent("Custom Tags", "Delete Tag", {
          // eslint-disable-next-line camelcase
          custom_tag_name: tag ? tag.label : "",
        });
        this.setState({ loading: false });
      })
      .catch(this.handleFailure);
    return deleteTagPromise;
  }

  handleFailure(errors) {
    if (
      this.fetchTransactionTagsCancelablePromise &&
      this.fetchTransactionTagsCancelablePromise.isCanceled()
    ) {
      return;
    }

    this.setState({
      loading: false,
      errors: errors,
    });
  }

  componentWillUnmount() {
    if (this.fetchTransactionTagsCancelablePromise) {
      this.fetchTransactionTagsCancelablePromise.cancel();
    }

    if (this.getTransactionsTagsSubscription) {
      this.getTransactionsTagsSubscription.unwatch();
      this.getTransactionsTagsSubscription.off("change");
    }
  }

  render() {
    const { errors, loading, tags } = this.state;
    const { value, onTagsModified, className, isDisabled, selectInputId } =
      this.props;

    return (
      <InputTags
        errors={errors}
        loading={loading}
        onCreateTag={this.handleCreateTag}
        onUpdateTag={this.handleUpdateTag}
        onDeleteTag={this.handleDeleteTag}
        onCUDTagClosed={this.handleCreateUpdateTagClosed}
        tags={tags}
        onTagsModified={onTagsModified}
        value={value}
        className={className}
        isDisabled={isDisabled}
        selectInputId={selectInputId}
      />
    );
  }
}

InputTagsContainer.propTypes = {
  selectInputId: PropTypes.string,
  getTags: PropTypes.func,
  createTag: PropTypes.func,
  updateTag: PropTypes.func,
  deleteTag: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onTagsModified: PropTypes.func,
  value: PropTypes.array,
  tags: PropTypes.array,
};

InputTagsContainer.defaultProps = {
  selectInputId: undefined,
  className: undefined,
  isDisabled: false,
  getTags: subscribify(Services.Tags.get),
  createTag: promisify(Services.Tags.create),
  updateTag: promisify(Services.Tags.update),
  deleteTag: promisify(Services.Tags.delete),
  onTagsModified: noop,
  value: [],
  tags: [],
};
