import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import Loading from "components/common/loading/Loading";
import Message from "components/common/Message";
import InvestableCashSummaryChart from "components/investableCashSummary/InvestableCashSummaryChart";
import { formatCurrency } from "libs/pcap/utils/format";
import CtaOverlay from "components/common/CtaOverlay";
import withTooltips from "components/higherOrderComponents/withTooltips";

/**
 * @param {array} histories - an array of history data points.
 * @return {int} The current amount of money
 */
function getCurrentBalance(histories) {
  if (histories.length === 0) {
    return null;
  }
  return _.last(histories).aggregateCashBalance;
}

class InvestableCashSummary extends React.Component {
  render() {
    const {
      loading,
      errors,
      ace,
      histories,
      title,
      storeTooltipRef,
    } = this.props;

    if (loading === true) {
      return <Loading />;
    }

    if (errors) {
      return <Message severity="error" messages={errors} />;
    }

    let aceOverlay;
    if (ace) {
      aceOverlay = (
        <CtaOverlay message={ace} widgetName="Investable Cash Summary" />
      );
    }

    const currentBalanceFormatted = formatCurrency(
      getCurrentBalance(histories),
      0
    );

    return (
      <section className="summary-widget">
        <div className="summary-widget__header">
          <div className="l-spaced l-spaced--flush">
            <div tabIndex="0" className="pc-delta qa-investable-widget-title">
              {title}
            </div>
            <span
              ref={(el) => {
                storeTooltipRef(el, { container: "body", placement: "top" });
              }}
              className="icon__help-circled"
              data-toggle="tooltip"
              tabIndex="0"
              aria-label="Investable cash is made up of cash in bank and investment accounts. It does not include cash inside of mutual funds or ETF's."
              title="Investable cash is made up of cash in bank and investment accounts. It does not include cash inside of mutual funds or ETF's."
            />
          </div>
          <div
            tabIndex="0"
            className="pc-delta u-text-primary js-investable-cash-current-balance qa-investable-cash-current-balance"
          >
            {currentBalanceFormatted || ""}
          </div>
        </div>
        <InvestableCashSummaryChart data={histories} />
        {aceOverlay}
      </section>
    );
  }
}

InvestableCashSummary.propTypes = {
  loading: PropTypes.bool,
  histories: PropTypes.array,
  errors: PropTypes.array,
  ace: PropTypes.object,
  title: PropTypes.string,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
};

InvestableCashSummary.defaultProps = {
  title: "Investable Cash",
};

export default withTooltips(InvestableCashSummary);
