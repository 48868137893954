import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import EditLoginView from "components/common/EditAccount/EditLoginView";
import { promisify } from "utils/service";

export default class EditLoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCredentialsUpdate = this.handleCredentialsUpdate.bind(this);
    this.updateCredentials = promisify(Services.Accounts.updateCredentials);
  }

  handleCredentialsUpdate(credentials) {
    this.updateCredentials({
      accountId: this.props.account.accountId,
      credentials: JSON.stringify(credentials),
      hasUpdateCredentials: true,
    }).then(() => {
      this.props.onCredentialsUpdate();
    });
  }

  render() {
    return (
      <EditLoginView
        account={this.props.account}
        onCancel={this.props.onCancel}
        onSubmit={this.handleCredentialsUpdate}
      />
    );
  }
}

EditLoginContainer.propTypes = {
  account: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCredentialsUpdate: PropTypes.func.isRequired,
};
