var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./removeAccount2.html"),depth0,{"name":"templates/modules/sidebar/removeAccount2","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./account2.html"),depth0,{"name":"templates/modules/sidebar/account2","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"js-account-extras sidebar-account__extras is-hidden\">\n  <div class='sidebar-account__message'>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n  <div class=\"sidebar-account__actions\">\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.noop,"inverse":container.program(1, data, 0),"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":22}}})) != null ? stack1 : "")
    + "    <button type=\"button\" class='js-account-cancel pc-btn pc-btn--tiny'>Cancel</button>\n    <button type=\"button\" class='js-account-close pc-btn pc-btn--primary pc-btn--tiny'>Close Account</button>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});