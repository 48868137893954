import PropTypes from "prop-types";
import React from "react";
import {
  getPersons,
  createPerson,
  updatePerson,
} from "accessors/person/accessor";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import InviteSecondaryAccountOwner from "../InviteSecondaryAccountOwner";
import { isEqual, noop } from "underscore";
import { fullName } from "libs/pcap/utils/person";
import DelegateAccessConsent from "components/common/SelectPersonInput/DelegateAccessConsent";
import DelegateAccessInviteValidator from "components/common/SelectPersonInput/DelegateAccessInviteValidator";

function isNewPerson(id) {
  return id === -1 || id == null;
}

export default class InviteDelegateUserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.loggedInPersonId = window.personId;
    this.state = {
      loading: true,
    };

    this.handleInvite = this.handleInvite.bind(this);
  }

  componentDidMount() {
    this.fetchDataRequest = makeCancelablePromise(getPersons());
    this.fetchDataRequest.promise.then(
      (people) => {
        this.setState({
          loggedInPerson: people.find((p) => p.id === this.loggedInPersonId),
          people,
          loading: false,
        });
      },
      (errors) => {
        if (this.fetchDataRequest.isCanceled()) {
          return;
        }

        this.setState({
          errors,
          loading: false,
        });
      }
    );
  }

  handleInvite(person) {
    this.setState({ errors: undefined, loading: true });
    this.savePerson(person)
      .then((person) => {
        const { onPersonSaved, sendInviteService } = this.props;
        onPersonSaved(person);
        return sendInviteService({ personId: person.id });
      })
      .then((sendInviteRs) => {
        const { onInviteSent } = this.props;
        this.setState({ loading: false });
        onInviteSent(sendInviteRs);
      })
      .catch((errors) => {
        this.setState({
          errors,
          loading: false,
        });
      });
  }

  savePerson(person) {
    const additionalParams = {
      source: "Settings",
    };

    const { id } = person;

    if (isNewPerson(id)) {
      return createPerson(
        // reset client-side id
        Object.assign({}, person, { id: undefined }),
        additionalParams
      );
    }

    const origPerson = this.state.people.find((p) => p.id === id);
    if (isEqual(person, origPerson)) {
      return Promise.resolve(person);
    }
    return updatePerson(person, additionalParams);
  }

  componentWillUnmount() {
    if (this.fetchDataRequest) {
      this.fetchDataRequest.cancel();
    }
  }

  render() {
    const { errors, loading, people, loggedInPerson } = this.state;
    const { onCancel, selectOptionsFilter } = this.props;
    return (
      <InviteSecondaryAccountOwner
        household={people}
        // The logged in person is always the primary owner.
        // We cannot select the same person as the secondary owner.
        selectOptions={people && people.filter(selectOptionsFilter)}
        errors={errors}
        loading={loading}
        onCancel={onCancel}
        onInvite={this.handleInvite}
        consentLabel={
          <DelegateAccessConsent
            applicantName={loggedInPerson && fullName(loggedInPerson)}
          />
        }
        selectPersonValidator={DelegateAccessInviteValidator}
        selectDropdownHeading="Select a Person"
        selectDropdownPlaceholder="Select a Person or Add New"
      />
    );
  }
}

InviteDelegateUserContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  // The service function receives `{ personId: <id> }` as an input.
  // Must return a Promise.
  sendInviteService: PropTypes.func.isRequired,
  onPersonSaved: PropTypes.func,
  onInviteSent: PropTypes.func,
  selectOptionsFilter: PropTypes.func,
};

InviteDelegateUserContainer.defaultProps = {
  onPersonSaved: noop,
  onInviteSent: noop,
  selectOptionsFilter: (p) => p.id !== window.personId,
};
