var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"set\">\n	<div class=\"section\">\n		<label>Annual Contributions <span title=\"<p>We use your estimated future contributions to calculate the fees you will pay over the lifetime of your 401k.\n													We assume an annual contribution increase of 3% to match inflation.</p>\"\n			  class=\"toolTipped\">Tip</span>\n		</label>\n		<div class=\"slider\"\n			data-max=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maximumAnnualContribution") || (depth0 != null ? lookupProperty(depth0,"maximumAnnualContribution") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maximumAnnualContribution","hash":{},"data":data,"loc":{"start":{"line":8,"column":13},"end":{"line":8,"column":42}}}) : helper)))
    + "\"\n			data-min=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"minimumAnnualContribution") || (depth0 != null ? lookupProperty(depth0,"minimumAnnualContribution") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minimumAnnualContribution","hash":{},"data":data,"loc":{"start":{"line":9,"column":13},"end":{"line":9,"column":42}}}) : helper)))
    + "\"\n			data-val=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"contributionPerYear") || (depth0 != null ? lookupProperty(depth0,"contributionPerYear") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contributionPerYear","hash":{},"data":data,"loc":{"start":{"line":10,"column":13},"end":{"line":10,"column":36}}}) : helper)))
    + "\"\n			data-property= \"contributionPerYear\"\n			data-formatter=\"dollars\">\n		</div>\n	</div>\n	<div class=\"section\">\n		<label>Annual Employer Match</label>\n		<div class=\"slider\"\n			data-max=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maximumEmployeeMatch") || (depth0 != null ? lookupProperty(depth0,"maximumEmployeeMatch") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maximumEmployeeMatch","hash":{},"data":data,"loc":{"start":{"line":18,"column":13},"end":{"line":18,"column":37}}}) : helper)))
    + "\"\n			data-min=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"minimumEmployeeMatch") || (depth0 != null ? lookupProperty(depth0,"minimumEmployeeMatch") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minimumEmployeeMatch","hash":{},"data":data,"loc":{"start":{"line":19,"column":13},"end":{"line":19,"column":37}}}) : helper)))
    + "\"\n			data-val=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"employeeContributionPerYear") || (depth0 != null ? lookupProperty(depth0,"employeeContributionPerYear") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"employeeContributionPerYear","hash":{},"data":data,"loc":{"start":{"line":20,"column":13},"end":{"line":20,"column":44}}}) : helper)))
    + "\"\n			data-property=\"employeeContributionPerYear\"\n			data-formatter=\"dollars\">\n		</div>\n	</div>\n	<div class=\"section\">\n		<label>Annual Growth</label>\n		<div class=\"slider\"\n			data-max=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maximumAnnualGrowth") || (depth0 != null ? lookupProperty(depth0,"maximumAnnualGrowth") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maximumAnnualGrowth","hash":{},"data":data,"loc":{"start":{"line":28,"column":13},"end":{"line":28,"column":36}}}) : helper)))
    + "\"\n			data-min=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"minimumAnnualGrowth") || (depth0 != null ? lookupProperty(depth0,"minimumAnnualGrowth") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minimumAnnualGrowth","hash":{},"data":data,"loc":{"start":{"line":29,"column":13},"end":{"line":29,"column":36}}}) : helper)))
    + "\"\n			data-val=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"annualPerformance") || (depth0 != null ? lookupProperty(depth0,"annualPerformance") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"annualPerformance","hash":{},"data":data,"loc":{"start":{"line":30,"column":13},"end":{"line":30,"column":34}}}) : helper)))
    + "\"\n			data-property=\"annualPerformance\"\n			data-formatter=\"percentage\">\n		</div>\n	</div>\n	<div class=\"section\">\n		<label>Additional Investment Fees <span title=\"<p>You may be paying Additional investment related fees on top of the expense ratios of your investment options. If you are not sure, or to confirm, please refer to the 404(a)(5) 401(k) participant fee disclosure form you should have received from your employer or retirement plan provider.</p>\"\n			  class=\"toolTipped\">Tip</span>\n		</label>\n		<div class=\"slider negativeRange\"\n			data-max=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maximumPlanAdministrationFees") || (depth0 != null ? lookupProperty(depth0,"maximumPlanAdministrationFees") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maximumPlanAdministrationFees","hash":{},"data":data,"loc":{"start":{"line":40,"column":13},"end":{"line":40,"column":46}}}) : helper)))
    + "\"\n			data-min=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"minimumPlanAdministrationFees") || (depth0 != null ? lookupProperty(depth0,"minimumPlanAdministrationFees") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minimumPlanAdministrationFees","hash":{},"data":data,"loc":{"start":{"line":41,"column":13},"end":{"line":41,"column":46}}}) : helper)))
    + "\"\n			data-val=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"planAdminFees") || (depth0 != null ? lookupProperty(depth0,"planAdminFees") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"planAdminFees","hash":{},"data":data,"loc":{"start":{"line":42,"column":13},"end":{"line":42,"column":30}}}) : helper)))
    + "\"\n			data-property=\"planAdminFees\"\n			data-formatter=\"basisPoints\">\n		</div>\n	</div>\n</div>\n";
},"useData":true});