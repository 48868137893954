import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import Services from "services";
import NetWorthSummary from "components/netWorthSummary/NetWorthSummary";
import { API_FORMAT } from "libs/pcap/utils/date";
import { isEmpty, first, last, noop, isUndefined } from "underscore";
import { promisify, subscribify } from "utils/service";
import ZeroStateWidget from "../dashboard/ZeroStateWidget";

const NUM_DAYS_PAST_PERIOD = 90; // number of days to filter against.
const DEFAULT_DATE_RANGE = "90_DAYS";
const ONE_YEAR_DATE_RANGE = "1_YEAR";

function getDefaultFetchConfig(uiPreferenceDateRange) {
  let startDate;
  if (uiPreferenceDateRange === DEFAULT_DATE_RANGE) {
    startDate = moment()
      .startOf("day")
      .subtract(NUM_DAYS_PAST_PERIOD - 1, "days")
      .format(API_FORMAT);
  } else {
    startDate = moment()
      .startOf("day")
      .subtract(1, "years")
      .add(1, "days")
      .format(API_FORMAT);
  }

  return {
    startDate,
    endDate: moment().format(API_FORMAT),
    interval: "DAY",
    types: JSON.stringify(["networth", "balances"]),
    includeNetworthCategoryDetails: true,
  };
}

export default class NetWorthSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      loading: true,
    };

    this.onGetHistoriesSuccess = this.onGetHistoriesSuccess.bind(this);
    this.onFailure = this.onFailure.bind(this);
  }

  componentDidMount() {
    const { getHistories, getUIPreferences, getHistories2 } = this.props;
    getUIPreferences()
      .then((uiPreferences) => {
        if (this.isUnmounted) {
          return;
        }

        const uiPreferenceDateRange =
          uiPreferences?.defaultDateRanges?.NETWORTH_INVESTING ||
          DEFAULT_DATE_RANGE;

        this.getHistoriesSubscription =
          uiPreferenceDateRange === ONE_YEAR_DATE_RANGE
            ? getHistories2(getDefaultFetchConfig(uiPreferenceDateRange))
            : getHistories(getDefaultFetchConfig(uiPreferenceDateRange));
        this.getHistoriesSubscription.on("change", this.onGetHistoriesSuccess);
        this.setState({
          uiPreferenceDateRange,
        });

        this.getHistoriesSubscription.promise
          .then(this.onGetHistoriesSuccess)
          .catch(this.onFailure);
      })
      .catch(this.onFailure);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    if (this.getUserMessagesSubscription) {
      this.getUserMessagesSubscription.unwatch();
      this.getUserMessagesSubscription.off("change");
    }
  }

  onFailure(errors) {
    if (this.isUnmounted) {
      return;
    }

    this.setState(
      {
        loading: false,
        errors: errors,
      },
      this.props.onDCDashboardComponentLoaded
    );
  }

  onGetHistoriesSuccess({ networthHistories = [] }) {
    if (this.isUnmounted) {
      return;
    }

    const firstItem = first(networthHistories) || {};
    const lastItem = last(networthHistories) || {};

    let changeLabelDays;

    if (networthHistories.length > 1) {
      changeLabelDays = `${networthHistories.length}-days`;
    } else {
      changeLabelDays = `${networthHistories.length}-day`;
    }

    this.setState(
      {
        changeLabel:
          this.state.uiPreferenceDateRange === DEFAULT_DATE_RANGE
            ? "90-day"
            : changeLabelDays,
        histories: networthHistories,
        netWorth: lastItem.networth,
        changeToday: lastItem.oneDayNetworthChange,
        change: lastItem.networth - firstItem.networth,
        errors: null,
        loading: false,
      },
      this.props.onDCDashboardComponentLoaded
    );
  }

  render() {
    const {
      histories,
      loading,
      errors,
      netWorth,
      changeLabel,
      change,
      changeToday,
    } = this.state;

    const { validNonZeroBankOrCCAccount } = this.props;
    const translations = window.integratedSharedData?.translations;

    if (
      !loading &&
      netWorth === 0 &&
      validNonZeroBankOrCCAccount === false &&
      window.integratedSharedData
    ) {
      if (!translations) return null;

      const zeroStateTranslations = translations.get("zeroStateDashboard");

      return (
        <ZeroStateWidget
          displayName={zeroStateTranslations?.netWorth?.title}
          cta={zeroStateTranslations?.netWorth?.cta}
          link={"#/net-worth"}
          label={zeroStateTranslations?.netWorth?.label}
          tooltip={zeroStateTranslations?.netWorth?.tooltip}
          buttonAlign={"right"}
          backgroundName="networth"
        />
      );
    }

    return (
      <NetWorthSummary
        errors={errors}
        ace={this.props.ace}
        loading={loading}
        histories={histories}
        netWorth={netWorth}
        changeLabel={changeLabel}
        change={change}
        changeToday={changeToday}
      />
    );
  }
}

NetWorthSummaryContainer.propTypes = {
  getUIPreferences: PropTypes.func,
  getHistories: PropTypes.func,
  getHistories2: PropTypes.func,
  ace: PropTypes.object,
  zeroState: PropTypes.element,
  onDCDashboardComponentLoaded: PropTypes.func,
  hasValidBankOrCCAccount: PropTypes.bool,
  validNonZeroBankOrCCAccount: PropTypes.bool,
};

NetWorthSummaryContainer.defaultProps = {
  getUIPreferences: promisify(Services.Profile.getUIPreferences),
  getHistories: subscribify(Services.Histories.get),
  getHistories2: subscribify(Services.Histories.getV2),
  ace: undefined,
  zeroState: null,
  onDCDashboardComponentLoaded: noop,
  validNonZeroBankOrCCAccount: false,
};
