import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import Input from "components/common/form/Input";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import { POSITIVE_CURRENCY_FORMAT } from "components/common/form/formattingOptions";
import { formatCurrency } from "libs/pcap/utils/format";
import React from "react";
import moment from "moment";
import DateUtil from "libs/pcap/utils/date";
import DatePickerInput from "components/common/form/DatePickerInput";
import Select from "components/common/form/Select";
import vestingSchedules from "enums/stockOptionsVestingSchedule";
import { noop } from "underscore";
import deepCopy from "deep-copy";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const MIN_START_VESTING_YEAR = "1930-01-01";

export const BaseModel = {
  grantName: undefined,
  vestingStartDate: moment().format(DateUtil.DISPLAY_FORMAT),
  totalShares: undefined,
  unvestedShares: undefined,
  currentPrice: undefined,
  exercisePrice: undefined,
  cliffShares: 0,
  label: "1 year, vests monthly",
  vestingIntervalMonth: 1,
  vestingTotalMonth: 12,
  vestingCliffMonth: 0,
};

const isValidStartVestingDate = (value) => {
  const minimumDateValue = moment(MIN_START_VESTING_YEAR);
  return value.isSameOrAfter(minimumDateValue);
};

const isPrivileged = isEmpowerPrivilegedMode();

export default class StockOptionsAddRow extends AbstractForm {
  constructor(props) {
    super(props);
    // Set default grant name
    let model = Object.assign({}, BaseModel, props.model);

    if (props.security) {
      model.currentPrice = props.security.currentPrice;
    }

    this.state = {
      model,
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVestingScheduleChange =
      this.handleVestingScheduleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  // set a grant name on initial render
  componentDidUpdate(prevProps) {
    if (prevProps.account.name !== this.props.account.name) {
      const model = this.state.model;
      model.grantName = `${this.props.account.name || "Manual Stock"} - ${
        model.vestingStartDate
      }`;
      this.setState({ model });
    }
  }

  componentDidMount() {
    const model = this.state.model;
    // set a grant name on initial render
    model.grantName = `${this.props.account.name || "Manual Stock"} - ${
      model.vestingStartDate
    }`;
    this.setState({ model });
    let tickerInput = this.inputElements.find(
      (input) => input.props.name === "totalShares"
    );
    if (tickerInput) {
      tickerInput.focus();
    }
  }

  handleVestingScheduleChange(e) {
    let { state } = this;
    const vestingSchedule = vestingSchedules[e.target.value];
    state.model.vestingIntervalMonth = vestingSchedule.vestingIntervalMonth;
    state.model.vestingTotalMonth = vestingSchedule.vestingTotalMonth;
    state.model.vestingCliffMonth = vestingSchedule.vestingCliffMonth;
    state.showCustomScheduleInputs = vestingSchedule.isCustom || false;
    state.model.label = vestingSchedule.label;

    this.setState(state);
  }

  handleDateChange(e) {
    this.handleInputChange.apply(this, arguments);
    let value = e.target.value;
    const model = deepCopy(this.state.model);
    model.grantName = `${this.props.account.name} - ${value}`;
    this.setState({ model });
  }

  handleSubmit(ev) {
    ev.preventDefault();
    if (this.validate().valid) {
      this.setState({ loading: true });
      const model = deepCopy(this.state.model);
      model.vestingStartDate = moment(model.vestingStartDate).format(
        DateUtil.API_FORMAT
      );
      this.props
        .onSubmit(model)
        .then((holding) => {
          if (this.props.onSaved) {
            this.props.onSaved(holding);
          }
        })
        .catch((errors) => {
          this.setState({ errors, loading: false });
        });
    } else if (!this.state.model.vestingStartDate) {
      let state = this.state;
      this.setState(state);
    }
  }

  render() {
    const { columns, onCancel, schema, security } = this.props;
    const { model, loading, showCustomScheduleInputs } = this.state;
    return (
      <form
        className="table__row--edit pc-bg-dark qa-add-holding-form"
        onSubmit={this.handleSubmit}
      >
        {this.state.errors && (
          <Message className="pc-u-mh-" messages={this.state.errors} />
        )}
        <LoadingOverlay active={loading} />
        <div className="stock-options-table__edit-row-inner">
          <div className={`table__column ${columns[0].className}`}>
            <Input
              ref={this.storeInputRef}
              className={"qa-add-grant-grant-name"}
              type="text"
              name="grantName"
              placeholder={"Grant name"}
              value={model.grantName}
              validator={schema.properties.grantName}
              errorBlockClassName="is-hidden"
              onChange={this.handleInputChange}
              sizeVariation="full"
            />
          </div>
          <div
            className={`table__column pc-datagrid__cell--stock-opt-edit-total-num`}
          >
            <Input
              ref={this.storeInputRef}
              type="number"
              name="totalShares"
              placeholder={"Total shares"}
              value={model.totalShares}
              validator={schema.properties.totalShares}
              errorBlockClassName="is-hidden"
              onChange={this.handleInputChange}
              sizeVariation="full"
            />
          </div>
          <div className={`table__column ${columns[3].className}`}>
            <Input
              ref={this.storeInputRef}
              type="text"
              name="exercisePrice"
              placeholder={"Exercise price"}
              prefix={"$"}
              formattingOptions={POSITIVE_CURRENCY_FORMAT}
              value={model.exercisePrice}
              validator={schema.properties.exercisePrice}
              errorBlockClassName="is-hidden"
              onChange={this.handleInputChange}
              sizeVariation="full"
            />
          </div>
          <div
            className={
              "table__column pc-datagrid__cell--stock-opt-edit-current-price"
            }
          >
            <label htmlFor="currentPrice" className="pc-label pc-label--inline">
              Current Price
            </label>
            {security && security.currentPrice ? (
              <label
                className={"u-text-bold qa-stock-option-current-price-label"}
              >
                {" "}
                {formatCurrency(model.currentPrice)}
              </label>
            ) : (
              <Input
                ref={this.storeInputRef}
                type="text"
                name="currentPrice"
                prefix={"$"}
                formattingOptions={POSITIVE_CURRENCY_FORMAT}
                value={model.exercisePrice}
                validator={schema.properties.exercisePrice}
                errorBlockClassName="is-hidden"
                onChange={this.handleInputChange}
                containerClassName={"input--inline-block pc-u-ml--"}
              />
            )}
          </div>
        </div>
        <div className="stock-options-table__edit-row-inner">
          <div className={`table__column pc-u-pt0 ${columns[0].className}`}>
            <label className="pc-label">Vesting Start Date</label>
            <DatePickerInput
              name="vestingStartDate"
              className="qa-vesting-start-date input--full"
              sizeVariation="full"
              ref={this.storeInputRef}
              validator={this.props.schema.properties.vestingStartDate}
              displayDateFormat={DateUtil.DISPLAY_FORMAT}
              onChange={this.handleDateChange}
              position="bottom"
              value={model.vestingStartDate}
              isAllowedDate={isValidStartVestingDate}
            />
          </div>
          <div className="table__column pc-u-pt0 pc-datagrid__cell--stock-opt-edit-vesting-schedule">
            <label className="pc-label u-text-left">Vesting Schedule</label>
            <Select
              name="vestingSchedule"
              className="input input--full qa-vesting-schedule"
              onChange={this.handleVestingScheduleChange}
              options={vestingSchedules}
              ref={this.storeInputRef}
            />
          </div>
        </div>
        {showCustomScheduleInputs && (
          <div className="stock-options-table__edit-row-inner qa-customvesting-schedule-fields">
            <div className={`table__column ${columns[0].className}`}>
              <label className="pc-label u-text-left">
                Vesting Interval (months)
              </label>
              <Input
                ref={this.storeInputRef}
                className={"qa-add-grant-grant-name"}
                type="number"
                name="vestingIntervalMonth"
                value={model.vestingIntervalMonth}
                validator={schema.properties.vestingIntervalMonth}
                errorBlockClassName="is-hidden"
                onChange={this.handleInputChange}
                sizeVariation="full"
              />
            </div>
            <div
              className={`table__column pc-datagrid__cell--stock-opt-edit-total-num`}
            >
              <label className="pc-label u-text-left">
                Months Until Fully Vested
              </label>
              <Input
                ref={this.storeInputRef}
                type="number"
                name="vestingTotalMonth"
                value={model.vestingTotalMonth}
                validator={schema.properties.vestingTotalMonth}
                errorBlockClassName="is-hidden"
                onChange={this.handleInputChange}
                sizeVariation="full"
              />
            </div>
            <div
              className={`table__column pc-datagrid__cell--stock-opt-edit-cliff-length`}
            >
              <label className="pc-label u-text-left">
                Length of Cliff (months)
              </label>
              <Input
                ref={this.storeInputRef}
                type="number"
                name="vestingCliffMonth"
                value={model.vestingCliffMonth}
                validator={schema.properties.vestingCliffMonth}
                errorBlockClassName="is-hidden"
                onChange={this.handleInputChange}
                sizeVariation="full"
              />
            </div>
            <div
              className={`table__column pc-datagrid__cell--stock-opt-edit-shares-at-cliff`}
            >
              <label className="pc-label u-text-left">
                Shares Granted at Cliff
              </label>
              <Input
                ref={this.storeInputRef}
                type="number"
                name="cliffShares"
                value={model.cliffShares}
                validator={schema.properties.cliffShares}
                errorBlockClassName="is-hidden"
                onChange={this.handleInputChange}
                sizeVariation="full"
              />
            </div>
          </div>
        )}
        <div className="stock-options-table__edit-row-inner">
          <div className="table__column table__column--stock-options-edit-actions">
            <button
              type="button"
              className="pc-btn pc-btn--small qa-close-add-stock-options"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`pc-btn pc-btn--small pc-btn--primary qa-save-stock-option ${
                isPrivileged ? "is-disabled" : ""
              }`}
            >
              Done
            </button>
          </div>
        </div>
      </form>
    );
  }
}

StockOptionsAddRow.defaultProps = {
  account: {},
  onSubmit: noop,
  schema: {
    type: "object",
    properties: {
      grantName: {
        required: true,
        type: "string",
        allowEmpty: false,
      },
      vestingStartDate: DatePickerInput.getValidator({
        valueDateFormat: DateUtil.DISPLAY_FORMAT,
        allowEmpty: false,
        isAllowedDate: isValidStartVestingDate,
      }),
      totalShares: {
        required: true,
        type: "number",
        allowEmpty: false,
        maximum: 99999999.99,
        messages: {
          maximum: "Total shares cannot be greater than 99,999,999.99",
        },
      },
      currentPrice: {
        required: true,
        type: "number",
        allowEmpty: false,
      },
      exercisePrice: {
        required: true,
        type: "number",
        allowEmpty: false,
      },
      cliffShares: {
        required: true,
        type: "number",
        allowEmpty: false,
      },
      vestingIntervalMonth: {
        required: true,
        type: "number",
        allowEmpty: false,
      },
      vestingTotalMonth: {
        required: true,
        type: "number",
        allowEmpty: false,
      },
      vestingCliffMonth: {
        required: true,
        type: "number",
        allowEmpty: false,
      },
    },
  },
};

StockOptionsAddRow.propTypes = {
  model: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onSaved: PropTypes.func,
  security: PropTypes.object,
  accountName: PropTypes.string,
  account: PropTypes.object,
};
