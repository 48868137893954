const TRANSFER_FUNDS_REQUEST = "TRANSFER_FUNDS_REQUEST";
export function storeTransferRequest(request) {
  window.sessionStorage.setItem(
    TRANSFER_FUNDS_REQUEST,
    JSON.stringify(request)
  );
}

export function restoreTransferRequest() {
  let transferFundsRequest;
  try {
    transferFundsRequest = JSON.parse(
      sessionStorage.getItem(TRANSFER_FUNDS_REQUEST)
    );
  } catch (errors) {
    transferFundsRequest = undefined;
  }
  sessionStorage.removeItem(TRANSFER_FUNDS_REQUEST);
  return transferFundsRequest === null ? undefined : transferFundsRequest;
}
