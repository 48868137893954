import { formatCurrency } from "libs/pcap/utils/format";
import { isObject } from "underscore";

export default function (value, precision) {
  // Handlebars sends an object as the last argument.
  // If `precision` is an object, that means we haven't passed the parameter
  if (isObject(precision)) {
    precision = 0;
  }
  return formatCurrency(value, precision);
}
