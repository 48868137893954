import { isEmpty } from "underscore";
import deepCopy from "deep-copy";

const REGEX_DEPENDS = /^([\w,.,[,\]]+)((?:>=)|(?:<=)|(?:=~)|[>,<,=])(.+)$/;
const REGEX_QUOTED_STR = /^"(.+)"$/;
const REGEX_REGEX = /^\/(.+)\/$/;

function hasOptions(fieldType) {
  return (
    fieldType === "OPTIONS" ||
    fieldType === "RADIO" ||
    fieldType === "CHECKBOX" ||
    fieldType === "MULTI_OPTIONS" ||
    fieldType === "BUTTON_OPTIONS" ||
    fieldType === "BUTTON_SELECT"
  );
}

function getRequiredValidationMessage(fieldType) {
  if (hasOptions(fieldType)) {
    return {
      required: "must be selected",
      allowEmpty: "must be selected",
    };
  }
}

function parseDependsExpression(depends) {
  const res = REGEX_DEPENDS.exec(depends);
  if (!res) {
    return;
  }

  let value;
  if (isNaN(res[3])) {
    // Remove quotes around the string left-hand value.
    const resQuoted = REGEX_QUOTED_STR.exec(res[3]);
    if (resQuoted) {
      value = resQuoted[1];
    }

    // Removes `/` around the regex value
    const resRegex = REGEX_REGEX.exec(res[3]);
    if (resRegex) {
      value = resRegex[1];
    }
  } else {
    value = parseFloat(res[3]);
  }

  if (value) {
    return {
      name: res[1],
      operation: res[2],
      value,
    };
  }
}

function partsToOptions(part) {
  if (hasOptions(part.type) && part.validIds) {
    let options = [];
    part.validIds.forEach((value, i) => {
      if (part.validTypes) {
        options.push({
          value,
          label: part.validValues[i],
          type: part.validTypes[i],
        });

        if (part?.descriptions) options[i].description = part.descriptions[i];
      } else {
        options.push({ value, label: part.validValues[i] });

        if (part?.descriptions) options[i].description = part.descriptions[i];
      }
    });

    return options;
  }
}

function partsToFieldDefinition(prompt, options = {}) {
  const { optionsAsRadio } = options;

  const updateNonServedValidator = (
    isPromptRequired,
    validator,
    currentPart
  ) => {
    if (isPromptRequired) {
      Object.assign(validator, {
        required: true,
        allowEmpty: false,
        messages: getRequiredValidationMessage(currentPart.type),
      });
    }

    if (currentPart.minLength) validator.minLength = currentPart.minLength;

    if (currentPart.maxLength) validator.maxLength = currentPart.maxLength;

    if (currentPart.minValue) validator.minimum = currentPart.minValue;

    if (currentPart.maxValue) validator.maximum = currentPart.maxValue;

    return validator;
  };

  const updateValidator = (initialValidator, type, hasContinueValidation) => {
    const validator = deepCopy(initialValidator);

    if (validator.required) {
      Object.assign(validator, {
        // TODO: Remove `type` once it comes from the server for every screen
        type: "string",
        messages: getRequiredValidationMessage(type),
      });
    }

    if (hasContinueValidation)
      validator.continueValidation = hasContinueValidation;

    validator.pattern = new RegExp(validator.pattern.replace(/\\\\/g, "\\"));

    return validator;
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  return prompt.parts.map((p) => {
    const part = {
      name: p.id,
      value: p.value ?? p.valueArray,
      type: p.type,
      validator: isEmpty(p.validator)
        ? updateNonServedValidator(prompt.isRequired, { type: "string" }, p)
        : updateValidator(p.validator, p.type, prompt.continueValidation),
      isEnabled: p.isEnabled == null ? true : p.isEnabled,
      placeholder: p.placeholderValue,
    };

    if (p.maxLength) part.maxLength = p.maxLength;

    if (!isEmpty(p.formattingOptions)) {
      const isStringValidJSON = (string) => {
        try {
          JSON.parse(string);
        } catch (error) {
          return false;
        }

        return true;
      };

      if (isStringValidJSON(p.formattingOptions))
        part.formattingOptions = JSON.parse(p.formattingOptions);
    }

    if (p.type === "CHECKBOX" && p.validIds) part.validator.type = "array";

    if (optionsAsRadio && p.type === "OPTIONS") part.type = "RADIO";

    if (p.type === "IMAGE") part.image = p.image;

    if (
      p.type === "MULTI_OPTIONS" ||
      (p.type === "BUTTON_SELECT" && p.multiple)
    ) {
      Object.assign(part.validator, {
        type: "array",
        maxItems: p.maxItems,
      });
    }

    if (p.type === "BUTTON_SELECT") {
      part.descriptions = p.descriptions;
      part.inputType = p.inputType;
      part.multiple = p.multiple;
      part.showRadio = p.showRadio;
    }

    if (p.type === "BUTTON_OPTIONS") part.unsorted = p.unsorted;

    if (p.characterSet === "NUMERIC") part.validator.type = "number";

    part.options = partsToOptions(p);

    return part;
  });
}

function toFieldDefinition(prompt, options = {}) {
  const fieldDefinition = {
    label: prompt.label,
    hint: prompt.hint,
    informationalText: prompt.informationalText,
    verbatimId: prompt.verbatimId,
  };

  if (prompt.depends) {
    fieldDefinition.depends = parseDependsExpression(prompt.depends);
  }

  if (prompt.parts) {
    fieldDefinition.parts = partsToFieldDefinition(prompt, options);
  }

  return fieldDefinition;
}

/**
 * Converts the response of formFieldPart spec https://personalcapital.jira.com/wiki/spaces/PFA/pages/1068040532/Forms
 * to the client-friendly format.
 *
 * @param {Object} formFields `formFieldPart` a object matching the formFieldPart spec.
 * @param {Object} options optional options object
 * @param {Boolean} options.optionsAsRadio a boolean flag indicating that the input type "OPTIONS" should be treated as radio-buttons
 * @returns {Object} client-friendly version of the response.
 */
export default function (formFields, options) {
  let result = Object.assign({}, formFields);
  if (formFields.prompts) {
    result.prompts = formFields.prompts.map((p) =>
      toFieldDefinition(p, options)
    );
  }

  return result;
}
