import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import { formatCurrency } from "libs/pcap/utils/format";
import { fromNow } from "libs/pcap/utils/fromNow";
import DatePickerInput from "components/common/form/DatePickerInput";
import moment from "moment";
import deepCopy from "deep-copy";
import {
  toApiFormatDateIfValid,
  toDisplayFormatDateIfValid,
  DISPLAY_FORMAT,
} from "libs/pcap/utils/date2";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";

export default class ChangeClosedDateView extends AbstractForm {
  constructor(props) {
    super(props);

    const model = deepCopy(props.model);

    this.state = { model };

    this.isValidCloseDate = this.isValidCloseDate.bind(this);
    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  isValidCloseDate(currentDate) {
    const createdDate = moment(this.state.model.createdDate).startOf("day");
    const today = moment().endOf("day");
    return (
      currentDate.isSameOrAfter(createdDate) &&
      currentDate.isSameOrBefore(today)
    );
  }

  handleDateInputChange(event, value) {
    const { model } = this.state;
    /* We used to accept account closedDate in API_FORMAT, store and validate it in API_FORMAT
     * and send it up stream to the server. We now accept dates in DISPLAY_FORMAT,
     * but we send it upstream in API_FORMAT. modelClosedDate is
     * initialized with a value in DISPLAY_FORMAT.
     */
    model.closedDate = toDisplayFormatDateIfValid({
      date: value,
      currentFormat: DISPLAY_FORMAT,
    });
    this.setState({ model });
  }

  handleSubmit() {
    const { model } = this.state;

    const validationResult = this.validate();

    if (validationResult.valid) {
      const apiFormatClosedDate = toApiFormatDateIfValid({
        date: model.closedDate,
        currentFormat: DISPLAY_FORMAT,
      });
      this.props.onChangeClosingDate(apiFormatClosedDate);
    }
  }

  render() {
    const { model } = this.state;
    let isCreditAccount = model.productType === "CREDIT_CARD";
    let balance = model.balance;
    if (isCreditAccount) {
      balance *= -1;
    }

    return (
      <div>
        <LoadingOverlay active={this.props.loading} />
        {model.logoPath && (
          <div className="edit-account__logo-container">
            <img src={model.logoPath} className="u-full-center--abs" />
          </div>
        )}
        <div className="l-spaced l-spaced--flush edit-account__text--info">
          <div className="u-text-bold">{model.firmName}</div>
          <div className="u-text-bold">{formatCurrency(balance)}</div>
        </div>
        <div className="l-spaced l-spaced--flush edit-account__text--info">
          <div>{model.name}</div>
          <div>{fromNow(model.lastRefreshed)}</div>
        </div>
        <div className="pc-u-mv-">
          The account balance will be $0 as of the date you select and any
          transactions after that date will be removed. Please note that if you
          are moving the date forward, everything past the previous close date
          was already removed, so the last known balance will be used to fill
          the gap. We will attempt to fetch any missing transactions assuming
          your login credentials are unchanged, but if enough time has passed,
          those transactions may no longer be available from your institution.
        </div>
        <div className="pc-u-mv">
          <div className="pc-layout pc-layout--flushed">
            <div className="pc-layout__item pc-u-2/5 pc-u-pt--">
              <label htmlFor="accountClosedDate" className="pc-u-mr--">
                Select a new date
              </label>
            </div>
            <div className="pc-layout__item pc-u-3/5 pc-u-pl0">
              <DatePickerInput
                name="closedDate"
                id="closedDate"
                validator={DatePickerInput.getValidator({
                  allowEmpty: false,
                  isAllowedDate: this.isValidCloseDate,
                })}
                ref={this.storeInputRef}
                isAllowedDate={this.isValidCloseDate}
                displayDateFormat={DISPLAY_FORMAT}
                onChange={this.handleDateInputChange}
                position="top"
                value={model.closedDate}
              />
            </div>
          </div>
        </div>
        {this.props.errors && (
          <Message
            className="pc-u-mb"
            severity="error"
            messages={this.props.errors}
          />
        )}
        <div className="l-spaced l-spaced--right">
          <button
            type="button"
            className="pc-btn js-btn-cancel"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="pc-btn pc-btn--danger js-btn-mark-closed qa-btn-mark-closed"
            onClick={this.handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

ChangeClosedDateView.propTypes = {
  model: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onChangeClosingDate: PropTypes.func.isRequired,
  errors: PropTypes.array,
};

ChangeClosedDateView.defaultProps = {
  loading: false,
  schema: {
    type: "object",
    properties: {
      closedDate: {
        allowEmpty: false,
        type: "string",
      },
    },
  },
};
