import React from "react";
import PropTypes from "prop-types";
import AccountDetails from "components/transferFunds/AccountDetails/AccountDetails";
import { noop } from "underscore";

const Disclosure = () => {
  return (
    <div>
      <div className="account-details__section__row">
        <div className="pc-u-2/7">&nbsp;</div>
        <div className="pc-u-5/7">
          <p className="pc-u-mb-">
            Find routing and account numbers on the bottom of a check:
          </p>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            src={`${window.staticUrl}/static/img/accountDetails/check.png`}
            className="account-details__section__row--check-image"
          />
        </div>
      </div>
      <p className="pc-u-mt+">
        Payments can only be made to/from a U.S. financial institution. By
        entering banking information, you authorize us to access public and
        proprietary records to validate that you are the accurate owner. This
        process will not affect your credit. For security reasons, you may have
        to wait up to a day before you can use this account for withdrawal.
      </p>
      <p className="pc-u-mt+">
        I authorize the ACH debit and/or ACH credit of my account at the
        designated financial institution pursuant to the information provided
        and the terms of the{" "}
        <a
          href="https://docs.empower-retirement.com/EE/Default/DOCS/ACH-Agreement.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Automated Clearing House (ACH) Authorization Agreement (ACH Agreement)
        </a>
        . I understand this authorization will remain in full force and effect
        in accordance with the terms of the ACH Agreement.
      </p>
      <p className="pc-u-mt+">
        To complete the authorization process, click “Continue”. By clicking
        “Continue”, I agree I have read the ACH Agreement and am consenting to
        the terms of the ACH Agreement. It is recommended that you print a copy
        of this authorization page and ACH agreement and maintain for your
        records.
      </p>
    </div>
  );
};

const AccountDetail = (props) => {
  if (props.account.productType === "BANK") {
    props = { ...props, CustomFooter: Disclosure };
  }
  return (
    <div>
      <h1 className="nav-secondary__title">Account details</h1>
      <div className="account-detail__review-msg pc-u-pt- pc-u-pb">
        Review the details below to process your request.
      </div>
      <AccountDetails {...props} />
    </div>
  );
};

AccountDetail.propTypes = {
  model: PropTypes.object,
  formFields: PropTypes.array,
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  errors: PropTypes.array,
  className: PropTypes.string,
  account: PropTypes.object,
  transferType: PropTypes.number.isRequired,
};

AccountDetail.defaultProps = {
  model: {},
  onBack: noop,
  errors: [],
  formFields: [],
  className: undefined,
  account: undefined,
};

export default AccountDetail;
