import React from "react";
import PropTypes from "prop-types";
import FieldSet from "../../FieldSet";
import memoizeOne from "memoize-one";
import toFieldDefinition from "accessors/formField/mappers/toClient";
import AbstractForm from "../../form/AbstractForm";
import { ModalContext } from "components/modal/ModalContext";

const buildFormFields = memoizeOne(function (formFields) {
  formFields.forEach((ff) => {
    delete ff.hint;
    if (ff.parts) {
      ff.parts.forEach((p) => {
        if (p.placeholderValue === undefined || p.placeholderValue === "") {
          p.placeholderValue = ff.isRequired ? "Required" : "Optional";
        }
      });
    }
  });
  return toFieldDefinition({ prompts: formFields }).prompts;
});

export default class AccountCredentialFields extends AbstractForm {
  render() {
    const {
      model,
      onChange,
      fields,
      className,
      storeInputRef,
      addTooltipToSelectOptions,
    } = this.props;
    // ModalContext.Provider is set since this component may render in modals.
    // Setting this context makes the fancy selects rendered by fieldset compatible
    // with our modals (attaches the results to the body) if this is not provided,
    // you wont be able to scroll through the result box.
    return (
      <ModalContext.Provider value={true}>
        <FieldSet
          model={model}
          fieldsConfig={buildFormFields(fields)}
          refFunc={storeInputRef ? storeInputRef : this.storeInputRef}
          onChange={onChange}
          shallowModelPropertyPath={true}
          className={className}
          addTooltipToSelectOptions={addTooltipToSelectOptions}
        />
      </ModalContext.Provider>
    );
  }
}

AccountCredentialFields.propTypes = {
  model: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  className: PropTypes.string,
  addTooltipToSelectOptions: PropTypes.bool,
};

AccountCredentialFields.defaultProps = {
  className: "",
  addTooltipToSelectOptions: false,
};
