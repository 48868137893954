import PropTypes from "prop-types";
import React from "react";
import { trackButtonClick } from "components/common/ComponentAnalytics";
import Mixpanel from "mixpanel";
import {
  getPlaAttributionSource,
  getResourceImage,
  getResourceEmbedBlock,
  getResourceImageBlock,
  getImageBlock,
  getJsonContent,
  getUserMessageActionTrackingFlag,
  getResourceImageObject,
} from "./helpers";
import getEventTargetDataAttribute from "./getEventTargetDataAttribute";
import { setSource } from "components/common/attributionStore";
import { markUserMessageAsClickedBaseOnTrackingFlag } from "libs/pcap/utils/markUserMessageAsClicked";
import {
  addOnClose,
  remove,
} from "views/components/postLoginActions/VisibleUserMessages";
import objectPath from "object-path";

const DEFAULT_BUTTON_CLASS_NAME = "pc-btn pc-btn--primary u-preserve-case";
const defaultGetButtonClassName = () => DEFAULT_BUTTON_CLASS_NAME;

export default class PostLoginActionsProvider extends React.Component {
  constructor() {
    super(...arguments);
    this.getListItems = this.getListItems.bind(this);
    this.getButtons = this.getButtons.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  componentDidMount() {
    const {
      updateModalTitle = false,
      onTitleChange,
      userMessage,
      onRequestClose,
    } = this.props;
    if (updateModalTitle && onTitleChange && userMessage.title) {
      onTitleChange(userMessage.title);
    }
    addOnClose(userMessage, () => {
      if (onRequestClose) {
        onRequestClose();
      }
    });
  }

  componentWillUnmount() {
    const { userMessage } = this.props;
    remove(userMessage && userMessage.userMessageId);
  }

  getListItems(items, { containerClassName = "", itemClassName = "" }) {
    return items.map((item, i) => {
      return (
        <div key={item + i}>
          <div className={containerClassName}>
            <div className={itemClassName}>{item}</div>
          </div>
        </div>
      );
    });
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  getButtons({
    getButtonClassName = defaultGetButtonClassName,
    ref,
    customizedComponentName,
  } = {}) {
    const { userMessage, dcOrRetail } = this.props;
    if (userMessage.action) {
      return userMessage.action.map((action, index) => {
        let actionUrl = objectPath.get(action, "url");
        const actionLabel = objectPath.get(action, "label");
        const buttonUrlIsClose =
          String(actionUrl).toUpperCase().indexOf("CLOSE") === 0;
        if (!actionUrl && !actionLabel) {
          return undefined;
        }
        if (dcOrRetail === "dc" && actionUrl.dc) {
          actionUrl = actionUrl.dc;
        }
        if (dcOrRetail === "retail" && actionUrl.retail) {
          actionUrl = actionUrl.retail;
        }
        let buttonClassNames = getButtonClassName(action);
        buttonClassNames = buttonClassNames.replace("pc-btn--small", "");

        return (
          <a
            key={index}
            ref={ref}
            className={`qa-pla-btn-${index} ${buttonClassNames}`}
            target={`${action.type === "TAB" ? "_blank" : "_self"}`}
            data-attribution-source={getPlaAttributionSource(
              userMessage,
              action
            )}
            data-tracking-flag={getUserMessageActionTrackingFlag(action)}
            onClick={(e) =>
              this.onButtonClick(e, {
                customizedComponentName,
                buttonUrlIsClose,
              })
            }
            href={actionUrl}
          >
            {actionLabel}
          </a>
        );
      });
    }
    return null;
  }

  onButtonClick(event, { customizedComponentName, buttonUrlIsClose }) {
    if (buttonUrlIsClose) {
      event.preventDefault();
    }
    const { componentName, onRequestClose, userMessage } = this.props;
    markUserMessageAsClickedBaseOnTrackingFlag(userMessage, event);
    const attributionSource = getEventTargetDataAttribute(
      event,
      "data-attribution-source"
    );
    if (attributionSource) {
      setSource(attributionSource);
    }
    trackButtonClick(event, "Post Login", null, {
      component: componentName,
      user_message_id: userMessage.userMessageId, // eslint-disable-line camelcase
      user_message_title: userMessage.title, // eslint-disable-line camelcase
      message_template: userMessage.template, // eslint-disable-line camelcase
      view_template: userMessage.viewTemplate, // eslint-disable-line camelcase
    });

    // This is new format for reporting to the mixpanel, The old one is still needed to track old reports
    Mixpanel.trackEvent(
      `Click on ${
        customizedComponentName ? customizedComponentName : componentName
      } - ${event.target.text}`,
      {
        component: componentName,
        user_message_id: userMessage.userMessageId, // eslint-disable-line camelcase
        user_message_title: userMessage.title, // eslint-disable-line camelcase
        message_template: userMessage.template, // eslint-disable-line camelcase
        view_template: userMessage.viewTemplate, // eslint-disable-line camelcase
      }
    );

    if (onRequestClose) {
      onRequestClose();
    }
  }

  render() {
    const { userMessage, children } = this.props;
    const imageSource = getResourceImage(userMessage);
    const jsonContent = getJsonContent(userMessage) || {};
    const imageWidth =
      getResourceImageObject(userMessage).jsonContent?.imageWidth;
    const imageStyle =
      getResourceImageObject(userMessage).jsonContent?.imageStyle;

    return children({
      imageSource,
      imageWidth,
      imageStyle,
      getImageBlock,
      getResourceEmbedBlock,
      getResourceImageBlock,
      jsonContent,
      getPlaAttributionSource,
      userMessage,
      getListItems: this.getListItems,
      getButtons: this.getButtons,
      onButtonClick: this.onButtonClick,
    });
  }
}

PostLoginActionsProvider.propTypes = {
  componentName: PropTypes.string,
  userMessage: PropTypes.object,
  onRequestClose: PropTypes.func,
  onTitleChange: PropTypes.func,
  updateModalTitle: PropTypes.bool,
  children: PropTypes.func.isRequired,
  dcOrRetail: PropTypes.string,
};

PostLoginActionsProvider.defaultProps = {
  componentName: undefined,
  userMessage: undefined,
  onRequestClose: undefined,
  onTitleChange: undefined,
  updateModalTitle: undefined,
  dcOrRetail: "",
};
