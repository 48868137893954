/* eslint-disable camelcase */
import React from "react";
import NavBarNotifications from "./NavBarNotifications";
import Services from "services";
import _ from "underscore";
import {
  filterUnreadNotifications,
  isUnreadNotification,
} from "utils/userMessage";
import moment from "moment";
import EventBus from "eventBus";
import PropTypes from "prop-types";
import NotificationCenter from "./NotificationCenter";
import deepCopy from "deep-copy";
import {
  isAppointmentUrl,
  addReasonToAppointmentUrl,
} from "libs/pcap/utils/appointmentUtils";
import { trackEvent } from "components/common/ComponentAnalytics";
import analytics from "analytics";
import { VIEW_TEMPLATES_TO_SKIP } from "libs/pcap/utils/customAppointmentSchedulerUtils";
import { getJsonContent } from "views/components/postLoginActions/helpers";
import { renderUserMessage } from "views/components/postLoginActions/RenderUserMessageUtils";
import { isEmpowerPaeMode } from "views/modules/sidebar/utils/accountUtils";

import IframeClient from "partner/iframeClient";

const NOTIFICATIONS = "#/notifications";
const iframeClientInstance = IframeClient.getInstance();

const getPlaDetails = (userMessage) => ({
  template: userMessage.template,
  message_template: userMessage.template,
  view_template: userMessage.viewTemplate,
  user_message_id: userMessage.userMessageId,
  user_message_title: userMessage.title,
});

class BannerNotificationsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unreadCount: 0,
      dropdownOpen: false,
      userMessages: [],
      unreadMessages: [],
      maxMessagesToShow: 5,
    };
    this.handleIconMouseEnter = this.handleIconMouseEnter.bind(this);
    this.handleIconMouseLeave = this.handleIconMouseLeave.bind(this);
    this.onViewPostLoginActionUserMessage =
      this.onViewPostLoginActionUserMessage.bind(this);
    this.handleMessageClick = this.handleMessageClick.bind(this);
    this.markMessageAsViewed = this.markMessageAsViewed.bind(this);
    this.displayNotificationModal = this.displayNotificationModal.bind(this);
    this.trackNotificationsClick = this.trackNotificationsClick.bind(this);
    this.trackHoverOverAppMenuNotificationsIcon =
      this.trackHoverOverAppMenuNotificationsIcon.bind(this);
    this.userMessagesWatch = this.userMessagesWatch.bind(this);
    this.trackViewAllClick = this.trackViewAllClick.bind(this);
    this.trackDisclaimerClick = this.trackDisclaimerClick.bind(this);
    this.handleActionLinkClick = this.handleActionLinkClick.bind(this);
    this.handleViewAllClick = this.handleViewAllClick.bind(this);
  }

  componentDidMount() {
    this.getUserMessagesWatch = Services.UserMessages.getV2.watch(
      this.userMessagesWatch
    );
    EventBus.on(
      "postLoginActions:viewUserMessage",
      this.onViewPostLoginActionUserMessage,
      this
    );
  }

  componentDidUpdate() {
    if (IS_IFRAMED) {
      iframeClientInstance.triggerResize();
    }
  }

  componentWillUnmount() {
    if (this.getUserMessagesWatch) {
      Services.UserMessages.getV2.unwatch(this.getUserMessagesWatch);
      this.getUserMessagesWatch = null;
    }

    EventBus.off(
      "postLoginActions:viewUserMessage",
      this.onViewPostLoginActionUserMessage,
      this
    );
  }

  userMessagesWatch(err, response) {
    if (err == null) {
      if (
        typeof response == "undefined" ||
        typeof response.spData == "undefined"
      ) {
        return;
      }
      if (response.spData.enabled) {
        //filter new unread notifications to show in dropdown
        let templateObject = {};
        const adviceSet = response.spData.userMessages;
        _.each(adviceSet, function (el) {
          el.timeAgo = moment(el.updatedTime).fromNow();
          el.viewed = Boolean(el.lastViewedTime);
        });
        templateObject.validSet = filterUnreadNotifications(adviceSet);
        const unreadCount = templateObject.validSet.length;

        this.setState({ unreadCount, unreadMessages: templateObject.validSet });
      }

      //filter notifications to show on a notifications page
      var userMessages =
        (response && response.spData && response.spData.userMessages) || [];
      var userMessagesWithoutLastViewedTimes = deepCopy(userMessages);

      userMessagesWithoutLastViewedTimes.forEach(function (v) {
        delete v.lastViewedTime;
      });
      if (
        this.previousUserMessages &&
        _.isEqual(this.previousUserMessages, userMessagesWithoutLastViewedTimes)
      ) {
        return;
      }
      this.previousUserMessages = userMessagesWithoutLastViewedTimes;
      userMessages = _.filter(userMessages, function (message) {
        const isInterjection = message.category !== "INTERJECTION";
        const hasProperDisplayLocation =
          !message.displayLocations ||
          message.displayLocations.indexOf(location.hash) > -1;
        const skipUserMessage = VIEW_TEMPLATES_TO_SKIP.includes(
          message.viewTemplate
        );
        return isInterjection && hasProperDisplayLocation && !skipUserMessage;
      });
      if (userMessages.length > 0) {
        _.each(userMessages, function (el) {
          el.timeAgo = moment(el.updatedTime).fromNow();
          el.viewed = !(
            typeof el.lastViewedTime == "undefined" || el.lastViewedTime === ""
          );
          el.read = !isUnreadNotification(el);
          if (el.action && el.action.length) {
            // eslint-disable-next-line max-nested-callbacks
            _.each(el.action, function (action) {
              if (isAppointmentUrl(action.url)) {
                action.url = addReasonToAppointmentUrl(
                  action.url,
                  "Investment Checkup"
                );
              }
            });
          }
        });
      }
      this.setState({ userMessages });
    }

    if (window.location.hash.includes(NOTIFICATIONS)) {
      AppRouter.removePreloader();
    }
  }

  handleIconMouseEnter() {
    this.setState({ dropdownOpen: true });
    if (!this.props.isAdvisorApp) {
      this.trackHoverOverAppMenuNotificationsIcon();
    }
  }

  handleIconMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  onViewPostLoginActionUserMessage(userMessageId) {
    const forceFetchUserMessagesAfterwards = true;
    if (!this.props.isAdvisorApp) {
      this.markMessageAsViewed(userMessageId, forceFetchUserMessagesAfterwards);
    }
  }

  handleMessageClick(e, message, componentName) {
    if (!this.props.isAdvisorApp) {
      this.markMessageAsViewed(message.userMessageId);
      this.trackNotificationsClick(message, componentName);
    }
    this.displayNotificationModal(message);
  }

  markMessageAsViewed(userMessageId, forceFetchUserMessagesAfterwards) {
    if (!isEmpowerPaeMode()) {
      Services.UserMessages.updateV2(
        {
          userMessageIds: "[" + userMessageId + "]",
          actions: '["markViewed"]',
        },
        forceFetchUserMessagesAfterwards
          ? function (err, response) {
              if (
                !err &&
                response &&
                response.spHeader &&
                response.spHeader.success
              ) {
                Services.cache.clear("/api/message/getUserMessages");
                Services.UserMessages.getV2(); // Force the watchers to refresh user message UI
              }
            }
          : null
      );
    }
  }

  displayNotificationModal(userMessage) {
    const jsonContent = getJsonContent(userMessage);
    const showFeedbackForm = jsonContent ? jsonContent.showFeedbackForm : true;
    renderUserMessage({
      userMessage,
      showTimeAgo: true,
      showFeedbackForm,
      className: "pc-modal--notifications-center",
    });
  }

  trackNotificationsClick(message, componentName) {
    analytics.sendEngagementEvent("View Notification Click", message.template);
    if (componentName === "NavBar Notifications") {
      trackEvent(
        "App Menu Notifications Dropdown",
        "Click on App Menu Notifications Drop Down Menu Item",
        getPlaDetails(message)
      );
    } else {
      trackEvent(
        "App Menu Notification Center",
        "Click on Notifications Center Notification Item",
        getPlaDetails(message)
      );
    }
  }

  handleViewAllClick() {
    this.handleIconMouseLeave();
    this.trackViewAllClick();
  }

  trackViewAllClick() {
    if (!this.props.isAdvisorApp) {
      analytics.sendEngagementEvent("View All Notifications Click");
      trackEvent(
        "App Menu Notifications Dropdown",
        "Click on View All Link in App Menu Notifications Drop Down"
      );
    }
  }

  trackHoverOverAppMenuNotificationsIcon() {
    var eventName = "Hover Over and View App Menu Notifications Drop Down";
    analytics.sendInfoEvent(eventName, "Notifications");
    trackEvent("App Menu Notifications Dropdown", eventName, {
      ignoreNextEventName: eventName,
    });
  }

  trackDisclaimerClick(component) {
    if (!this.props.isAdvisorApp) {
      if (component === "App Menu Notifications Dropdown") {
        analytics.sendInfoEvent(
          "Disclaimer (App Header) Click",
          "Notifications"
        );
        trackEvent(
          "App Menu Notifications Dropdown",
          "Click on Disclaimer Link in App Menu Notifications Drop Down"
        );
      }
      if (component === "Notifications") {
        analytics.sendInfoEvent("Disclaimer Click", "Notifications");
        trackEvent(
          "Notification Center",
          "Click on Disclaimer Link in Notification Center"
        );
      }
    }
  }

  handleActionLinkClick(e, message, ind) {
    e.stopPropagation();
    if (!this.props.isAdvisorApp) {
      const gaEventName = `Notification Action Click ${ind + 1}`;
      analytics.sendEngagementEvent(gaEventName, message.template);
    }
  }

  render() {
    return this.props.isNotificationCenter ? (
      <NotificationCenter
        userMessages={this.state.userMessages}
        handleMessageClick={this.handleMessageClick}
        trackDisclaimerClick={this.trackDisclaimerClick}
      />
    ) : (
      <div
        onMouseEnter={this.handleIconMouseEnter}
        onMouseLeave={this.handleIconMouseLeave}
        className="notifications-container"
      >
        <a
          id="openAdviceStream"
          href="#/notifications"
          className="qa-notification-menu-button"
        >
          <div
            className={`appmenu-notifications-button-icon nav-bar-notifications__icon ${
              this.state.dropdownOpen
                ? "nav-bar-notifications__icon-active"
                : ""
            }`}
          >
            <div className="appmenu-notifications-button-icon-bell">
              <svg viewBox="0 0 18 18">
                <use xlinkHref="#pc-icon__notifications" />
              </svg>
            </div>
            {this.state.unreadCount > 0 && (
              <div
                className={`nav-bar-notifications__icon-counter qa-notifications-count ${
                  this.state.dropdownOpen
                    ? "nav-bar-notifications__icon-counter-active"
                    : ""
                }`}
              >
                {this.state.unreadCount}
              </div>
            )}
          </div>
        </a>
        {this.state.dropdownOpen && (
          <NavBarNotifications
            userMessages={this.state.unreadMessages}
            maxMessagesToShow={this.state.maxMessagesToShow}
            handleMessageClick={this.handleMessageClick}
            handleViewAllClick={this.handleViewAllClick}
            trackDisclaimerClick={this.trackDisclaimerClick}
            handleActionLinkClick={this.handleActionLinkClick}
          />
        )}
      </div>
    );
  }
}

BannerNotificationsContainer.defaultProps = {
  isNotificationCenter: false,
  isAdvisorApp: false,
};

BannerNotificationsContainer.propTypes = {
  isNotificationCenter: PropTypes.bool,
  isAdvisorApp: PropTypes.bool,
};

export default BannerNotificationsContainer;
