var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"get__referral__link post-login-dialog\">\n  <div class=\"post-login-dialog-header\">\n    <div class=\"post-login-dialog__logo-wrapper\">\n      <svg class=\"icon-logo-text icon-logo-text--white\">\n        <use xlink:href=\"#emp-logo-proposal-admin-light\"></use>\n      </svg>\n    </div>\n    <a href=\"#\" class=\"post-login-dialog-header-close-button js-close\">×</a>\n  </div>\n  <div class=\"referral__call-to-action\">Improve someone else’s life with our free tools. Share "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":10,"column":95},"end":{"line":10,"column":117}}}) : helper))) != null ? stack1 : "")
    + " today.</div>\n  <div class=\"get__referral__link__invite-link-section\">\n    <div class=\"pc-input-append\">\n      <input id='js-non-client-referral-link' class=\"pc-input-control js-invite-link\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inviteLink") || (depth0 != null ? lookupProperty(depth0,"inviteLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inviteLink","hash":{},"data":data,"loc":{"start":{"line":13,"column":93},"end":{"line":13,"column":107}}}) : helper)))
    + "\" data-lpignore=\"true\" />\n      <div class=\"pc-input-append-addon\">\n        <input id=\"js-content-to-copy\" class=\"u-hidden-visually\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inviteLink") || (depth0 != null ? lookupProperty(depth0,"inviteLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inviteLink","hash":{},"data":data,"loc":{"start":{"line":15,"column":72},"end":{"line":15,"column":86}}}) : helper)))
    + "\" />\n        <button\n          class=\"get__referral__link__button-clipboard js-action-button js-action-copy-link\"\n          type=\"button\"\n          data-clipboard-target=\"#js-content-to-copy\"\n          data-toggle=\"tooltip\"\n          data-placement=\"bottom\"\n          data-trigger=\"manual\"\n          title=\"Copied\"\n          data-analytics-action=\"Copy Link\"\n        ><img src=\"/static/img/bootstrap/clippy.svg\" width=\"13\" alt=\"Copy to clipboard\"></button>\n      </div>\n    </div>\n  </div>\n  <div class=\"referral__referral-icons-container\">\n    <a target=\"_blank\" id=\"Refer-Through-Facebook\" class=\"share-icon__container js-social-button\">\n      <img class=\"share-icon__image\" src=\"/static/img/referral/facebook.svg\">\n      <div class=\"share-icon__text\">Share</div>\n    </a>\n    <a target=\"_blank\" id=\"Refer-Through-LinkedIn\" class=\"share-icon__container js-social-button\">\n      <img class=\"share-icon__image\" src=\"/static/img/referral/in.svg\">\n      <div class=\"share-icon__text\">Share</div>\n    </a>\n    <a target=\"_blank\" id=\"Refer-Through-Twitter\" class=\"share-icon__container js-social-button\">\n      <img class=\"share-icon__image\" src=\"/static/img/referral/tweet.svg\">\n      <div class=\"share-icon__text\">Tweet</div>\n    </a>\n    <a target=\"_blank\" id='Refer-Through-Mail' class=\"share-icon__container js-social-button\">\n      <img class=\"share-icon__image\" src=\"/static/img/referral/email.svg\">\n      <div class=\"share-icon__text\">Email</div>\n    </a>\n  </div>\n</div>\n";
},"useData":true});