import $ from "jquery";
import _ from "underscore";
import Services from "services";
import BaseDetailsView from "views/components/accountDetails/baseDetailsView";
import LoanMortgageTemplate2 from "templates/accountDetails/loanMortgage2.html";
import BillReminderTemplate2 from "templates/accountDetails/billReminder2.html";
import dollarAndCentsAmount from "templates/helpers/dollarAndCentsAmount";
import "dropdown";
import mixpanel from "../../../libs/pcap/utils/mixpanel";
import { isEmpowerPrivilegedMode } from "../../modules/sidebar/utils/accountUtils";

var MAX_LENGTH_FIRM_NAME = 45;
var MAX_LENGTH_NAME = 40;
const LOAN_PRODUCT_TYPE = "LOAN";
const MARGIN_RIGHT_LABELS_X = 34;
const isPrivileged = isEmpowerPrivilegedMode();

var LoanMortgageView = BaseDetailsView.extend({
  events: _.extend(
    {
      "click .js-action-mark-as-paid": "handleMarkAsPaidClick",
    },
    BaseDetailsView.prototype.events
  ),

  initialize: function (options) {
    this.options = options;
    BaseDetailsView.prototype.initialize.apply(this, arguments);
    this.getBalanceHistory(true);
    this.getBill();
    if (this.model.get("productType") === LOAN_PRODUCT_TYPE) {
      mixpanel.trackEvent("View Account Details Loan", {
        component: "loanMortgageView",
      });
    } else {
      mixpanel.trackEvent("View Account Details Mortgage", {
        component: "loanMortgageView",
      });
    }
  },

  render: function () {
    var data = this.model.toJSON();
    data.cappedFirmName = this.capStringLength(
      data.firmName,
      MAX_LENGTH_FIRM_NAME
    );
    data.cappedName = data.name;
    data.interestRate = Number(this.model.get("interestRate"));
    this.options.tabId = "balance";
    data.showRefetchTransaction = this.options.showRefetchTransaction;
    data.isRefetchTransactionEligible =
      this.options.isRefetchTransactionEligible;
    this.$el.html(LoanMortgageTemplate2(data));
    BaseDetailsView.prototype.render.call(this);
    return this;
  },

  changeAccount: function (account) {
    BaseDetailsView.prototype.changeAccount.call(this, account);

    this.render();
    this.getBalanceHistory();
    this.getBill();
  },
  resetClosedAccountData: function () {
    BaseDetailsView.prototype.resetClosedAccountData.call(this);
    var htmlText;

    // update balance
    htmlText = dollarAndCentsAmount(this.model.get("balance"), true, true);
    this.$el.find(".balance > .account-details-info__value").text(htmlText);

    // update interestRate
    htmlText = this.model.get("interestRate");
    this.$el
      .find(".interest-rate > .account-details-info__value")
      .html(
        typeof htmlText === "number"
          ? `${htmlText}%`
          : this.addPercentSymbol(htmlText)
      );
  },

  /*
   * If only account info has been updated, then only update specific DOM elements
   */
  updateContent: function () {
    BaseDetailsView.prototype.updateContent.call(this);
    var htmlText;
    // update balance
    htmlText = dollarAndCentsAmount(this.model.get("balance"), true, true);
    this.$el.find(".balance .account-details-info__value").text(htmlText);

    // update interestRate
    htmlText = this.model.get("interestRate");
    this.$el
      .find(".interest-rate .account-details-info__value")
      .html(
        typeof htmlText === "number"
          ? `${htmlText}%`
          : this.addPercentSymbol(htmlText)
      );
  },

  /*
   * Override method defined in baseDetailsView
   */

  onEditableClick: function (event) {
    var targetElement = $(event.currentTarget),
      className = targetElement.attr("class").split(" ")[0],
      inputId = "#" + className + "Input";

    if (inputId === "#interestRateInput") {
      // If there's an input element that is still active, return it to non-edit mode
      if (
        !_.isUndefined(this.previousInputID) &&
        !_.isNull(this.previousInputID)
      ) {
        var previousInputElement = this.$el.find(this.previousInputID);
        previousInputElement.val(this.previousInputData);
        previousInputElement.parent().parent().hide();
      }
      // track current input element so it can be used
      // to reset the previous input element

      this.previousInputID = inputId;
      this.previousInputData = this.$el.find(inputId).val();

      // Display editor

      var editContainer = this.$el.find(
        '[class~="editContainer"][class~=' + className + "]"
      );
      var offset = targetElement.offset();
      offset.left -= 7;
      offset.top -= 9;

      editContainer.show();
      editContainer.offset(offset);
    } else {
      BaseDetailsView.prototype.onEditableClick.call(this, event);
    }
  },

  /*
   * Override method defined in baseDetailsView
   */
  onSaveClick: function () {
    if (
      typeof this.previousInputID !== "undefined" &&
      this.previousInputID !== null
    ) {
      var propertyName = this.previousInputID.replace(/(#|Input)/g, ""),
        inputElement = this.$el.find(this.previousInputID),
        newText,
        maxStringLength,
        accountObject = { accountId: this.model.get("accountId") },
        isManual = this.model.get("isManual");

      if (propertyName === "interestRate") {
        accountObject[propertyName] = inputElement.val();

        newText = inputElement.val() + "%";
      } else {
        if (propertyName === "firmName") {
          maxStringLength = MAX_LENGTH_FIRM_NAME;
        } else if (propertyName === "name") {
          maxStringLength = MAX_LENGTH_NAME;
        }

        // create generic object to pass as an argument to API call
        accountObject.isManualPortfolio = this.model.get("isManualPortfolio");
        accountObject[propertyName] = inputElement.val();

        newText = this.capStringLength(inputElement.val(), maxStringLength);
      }

      if (isManual) {
        Services.Accounts.updateManual(accountObject);
      } else {
        Services.Accounts.update(accountObject);
      }

      var htmlText = newText + '<div><span class="editIcon"></span></div>';
      this.$el.find(".editable." + propertyName).html(htmlText);

      inputElement.parent().parent().hide();

      this.previousInputID = null;
      this.previousInputData = null;
    }
  },

  getHistory: function () {
    this.getBalanceHistory();
  },

  onBalanceFetched: function (err, response) {
    if (err == null) {
      BaseDetailsView.prototype.onBalanceFetched.call(this, err, response);

      const balanceChartEl = this.$el.find("#balanceChart");
      const originalWidth = balanceChartEl.width();
      this.renderBalanceChart();
      if (balanceChartEl && originalWidth) {
        balanceChartEl.find("svg").css({ width: originalWidth });
        balanceChartEl.find(".startDate")?.css({ left: 0 });
        balanceChartEl
          .find(".endDate")
          ?.css({ "margin-right": MARGIN_RIGHT_LABELS_X });
      }
    }
  },

  /*
   * Just method stub that is used to set tabId when invoked by updateView() in BaseDetailsView.
   * This tabId needs to be set here so that is available when stating view state.
   */

  displayChart: function () {
    this.options.tabId = "balance";
  },

  removeWatch: function () {
    BaseDetailsView.prototype.removeWatch.call(this);
    if (this.getBillReminderWatchId) {
      Services.BillReminders.get.unwatch(this.getBillReminderWatchId);
      delete this.getBillReminderWatchId;
    }
  },

  getBill: function () {
    //TODO: ADD UNWATCH
    Services.BillReminders.get.watch(this.onBillsFetched, this);
  },

  onBillsFetched: function (err, response) {
    if (err === null) {
      this.bill = _.find(response.spData, this.findAccountBill, this);

      this.$el.remove(".billContainer");

      if (typeof this.bill === "undefined" || this.bill === null) {
        this.bill = { status: "NO_BILL" };
      } else {
        this.bill.loginUrl = this.model.get("loginUrl");
      }

      if (!this.bill.status) {
        this.bill.status = "NOT_PAID";
      }

      this.updateBillDropdown();
    }
  },
  updateBillDropdown: function () {
    this.updateBillButton();
    this.$(".js-account-details-bill-reminder-container").html(
      BillReminderTemplate2(this.bill, isPrivileged)
    );
  },
  updateBillButton: function () {
    var billButtons = this.$el
      .find(".js-account-details-bill")
      .addClass("is-hidden");
    var currentButtonSelector;
    switch (this.bill.status) {
      case "NO_BILL":
        currentButtonSelector = ".js-account-details-bill--no-due";
        break;
      case "PAID":
        currentButtonSelector = ".js-account-details-bill--paid";
        break;
      case "NOT_PAID":
        currentButtonSelector = ".js-account-details-bill--due";
        break;
      default:
        currentButtonSelector = "";
        break;
    }
    billButtons.filter(currentButtonSelector).removeClass("is-hidden");
  },

  findAccountBill: function (bill) {
    return bill.userAccountId === this.model.get("userAccountId");
  },
  handleMarkAsPaidClick: function () {
    Services.BillReminders.update(
      {
        billPaymentId: this.bill.billPaymentId,
        status: "PAID",
      },
      this.onBillUpdate,
      this
    );
  },

  onBillUpdate: function (err, response) {
    if (_.isUndefined(err) || _.isNull(err)) {
      this.bill.status = response.spData.status;
      this.updateBillDropdown();
    }
  },
  addPercentSymbol: function (value) {
    if (value?.endsWith("%")) {
      return value;
    }
    return value + "%";
  },
});

export default LoanMortgageView;
