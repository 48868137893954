import PropTypes from "prop-types";
import React from "react";
import _401k from "./facts/_401k";
import Education from "./facts/Education";
import Insurance from "./facts/Insurance";
import Investments from "./facts/Investments";
import Retirement from "./facts/Retirement";
import Taxes from "./facts/Taxes";
import { trackEvent } from "components/common/ComponentAnalytics";
import utils from "libs/pcap/utils/utils";

const TRANSPARENT_PIXEL =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
const DEFAULT_WHEEL_IMAGE = Retirement;
const WHEEL_IMAGE_MAP = {
  "401k": _401k,
  education: Education,
  insurance: Insurance,
  investment: Investments,
  retirement: Retirement,
  taxes: Taxes,
};

export default class FinancialPlanWheel extends React.Component {
  constructor() {
    super(...arguments);
    this.state = { WheelImageComponent: DEFAULT_WHEEL_IMAGE };
    this.onMouseOverPie = this.onMouseOverPie.bind(this);
  }

  onMouseOverPie(event) {
    const name = event.target.id.replace("fin-plan-wheel__", "");
    const NewWheelImage = WHEEL_IMAGE_MAP[name];
    if (this.state.WheelImageComponent !== NewWheelImage) {
      this.setState({ WheelImageComponent: NewWheelImage });
      trackEvent(
        this.props.componentName,
        `View Financial Plan Wheel - ${utils.capitalizeFirstLetter(name)}`
      );
    }
  }

  render() {
    const { className } = this.props;
    const { WheelImageComponent } = this.state;
    return (
      <div className={`fin-plan-offer__wheel ${className}`}>
        <WheelImageComponent />
        <img
          src={TRANSPARENT_PIXEL}
          useMap="#fin-plan-wheel-image-map"
          className="fin-plan-offer__wheel-image-map"
        />
        <map name="fin-plan-wheel-image-map">
          <area
            onMouseOver={this.onMouseOverPie}
            id="fin-plan-wheel__401k"
            alt=""
            title=""
            href="#"
            coords="366,232,234,247,238,279,251,309,266,338,295,366,386,267,372,252"
            shape="poly"
          />
          <area
            onMouseOver={this.onMouseOverPie}
            id="fin-plan-wheel__education"
            alt=""
            title=""
            href="#"
            coords="365,227,231,232,234,192,245,156,262,123,286,92,382,187,369,204"
            shape="poly"
          />
          <area
            onMouseOver={this.onMouseOverPie}
            id="fin-plan-wheel__taxes"
            alt=""
            title=""
            href="#"
            coords="384,182,298,82,322,63,348,51,384,39,424,36,422,168,403,171"
            shape="poly"
          />
          <area
            onMouseOver={this.onMouseOverPie}
            id="fin-plan-wheel__retirement"
            alt=""
            title=""
            href="#"
            coords="427,168,440,37,473,44,504,55,531,71,554,92,463,186,444,175"
            shape="poly"
          />
          <area
            onMouseOver={this.onMouseOverPie}
            id="fin-plan-wheel__investment"
            alt=""
            title=""
            href="#"
            coords="465,189,474,206,477,227,610,231,608,196,601,170,587,136,563,102"
            shape="poly"
          />
          <area
            onMouseOver={this.onMouseOverPie}
            id="fin-plan-wheel__insurance"
            alt=""
            title=""
            href="#"
            coords="478,232,471,250,457,265,549,370,571,337,591,307,604,274,609,243"
            shape="poly"
          />
        </map>
      </div>
    );
  }
}

FinancialPlanWheel.propTypes = {
  componentName: PropTypes.string,
  className: PropTypes.string,
};
