var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"advisorAppointmentUrl") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"openAdvisorAppointmentInNewTab") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":48},"end":{"line":26,"column":109}}})) != null ? stack1 : "")
    + "class=\"pc-btn pc-btn--primary _401k-fee-analyzer__talk-to-an-advisor-btn js-talk-to-an-advisor-btn\" data-attribution-source=\"RETIREMENT_FEE_ANALYZER--TALK_TO_AN_ADVISOR\">Talk to an Advisor</a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "target=\"_blank\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"activeModule hidden moduleFrame appTemplate\">\n  <div class=\"nav-secondary l-spaced l-spaced--flush js-secondary-nav\">\n    <h1 class=\"nav-secondary__title qa-page-title\">\n        Retirement fee analyzer\n    </h1>\n    <div></div>\n  </div>\n	<div class=\"frame\">\n    <div class=\"nav-secondary nav-secondary--feature-controls l-spaced l-spaced--flush js-feature-controls\">\n      <div></div>\n      <div class=\"accountSelector retirementFeeAccountSelector\"></div>\n    </div>\n		<div class=\"topFold\">\n			<div class=\"controls\">\n\n			</div>\n			<div id=\"marquee\" class=\"qa-retirement-fee-analyzer-charts\">\n				<div class=\"aside\">\n					<div class=\"benchmarkIndicator\">\n						<div class=\"yourFeesIndicator\">\n							<div class=\"feesValue\"><sup>%</sup></div>\n						</div>\n						<p class=\"benchmarkIndicatorLabel\">Your Annual<br/>Fees</p>\n					</div>\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"advisorAppointmentUrl") : depth0),"&&",(depth0 != null ? lookupProperty(depth0,"showTalkToAnAdvisor") : depth0),{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":10},"end":{"line":27,"column":22}}})) != null ? stack1 : "")
    + "				</div>\n				<div class=\"visualizations\">\n					<div class=\"chart\">\n						<div id=\"percentageLeft\">\n							<span></span>\n							<p>Of Earnings<br />Lost to Fees</p>\n						</div>\n						<div id=\"feesWheel\"></div>\n						<div id=\"_401kEquityLine\">\n\n						</div>\n						<div id=\"equityLineGrid\">\n\n						</div>\n					</div>\n					<div>\n						<label class=\"ageTip\">AGE <span>50</span></label>\n						<label class=\"currentAge\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"age") : depth0), depth0))
    + "</label>\n						<label class=\"retirementAge\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"retirementAge") : depth0), depth0))
    + "</label>\n					</div>\n					<table id=\"metrics\">\n						<tr>\n							<td class=\"contributions\">\n								<label>Contributions</label>\n								<span></span>\n							</td>\n							<td class=\"earnings\">\n								<label>Earnings</label>\n								<span></span>\n							</td>\n							<td class=\"totalFees\">\n								<label>Total Fees</label>\n								<span></span>\n							</td>\n						</tr>\n					</table>\n				</div>\n			</div>\n			<div class=\"editAssumptions\">\n			</div>\n			<div class=\"editAssumptionButtonArea\">\n				<a class=\"button a editQuestionnaire gray\" href=\"#\" data-mode=\"closed\">Edit Assumptions</a>\n			</div>\n			<div class=\"parameters\">\n"
    + ((stack1 = container.invokePartial(require("../../partials/home_rebrand/sliders401k.html"),depth0,{"name":"templates/partials/home_rebrand/sliders401k","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "			</div>\n		</div>\n		<div class=\"tables fee-analizer-grids tabular-numbers qa-retirement-fee-analyzer-grids\">\n			<div class=\"fundsWithExpense hidden\">\n				<table id=\"fundsWithExpenseRatio\" class=\"pc-datagrid pc-datagrid--actionable pc-datagrid--datatables\"></table>\n			</div>\n			<div class=\"fundsWithoutExpense hidden\">\n				<div class=\"section\">\n					<p>\n						<strong>Investments for which we have no data:</strong> These tend to include payroll provider or insurance\n						company plans. These investments can have expensive front- or back-loaded fees. They also\n						tend to have expense ratios ranging from 1.4% to as high as 4%. Use the slider to indicate your\n						estimated range and see what it means for your total 401k value over time.\n					</p>\n					<div class=\"expenseRatioSlider\">\n						<label>Expense Ratio</label>\n						<div class=\"slider negativeRange\"\n							data-max=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"maximumExpenseRatio") : depth0), depth0))
    + "\"\n							data-min=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"minimumExpenseRatio") : depth0), depth0))
    + "\"\n							data-val=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"expenseRatio") : depth0), depth0))
    + "\"\n							data-property=\"expenseRatio\"\n							data-formatter=\"basisPointsToTenths\"\n							data-callback=\"adjustExpenseManualExpenseRatio\">\n						</div>\n					</div>\n				</div>\n				<table id=\"fundsWithoutExpenseRatio\" class=\"pc-datagrid pc-datagrid--actionable pc-datagrid--datatables\"></table>\n			</div>\n			<div class=\"othersWithoutExpense\">\n				<div class=\"section\">\n					<p>\n						<strong>Other holdings:</strong> These holdings are in your accounts but do not have ongoing fees, so they are not included in the fee projection.\n					</p>\n				</div>\n				<table id=\"othersWithoutExpenseRatio\" class=\"pc-datagrid pc-datagrid--actionable pc-datagrid--datatables\"></table>\n			</div>\n		</div>\n	</div>\n	<div class=\"support\">\n		<a target=\"_blank\" class=\"js-how-we-calculate-results-link\" href=\"https://"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"organizationSupportUrl") : depth0), depth0))
    + "/hc/en-us/articles/201169600-Retirement-Fee-Analyzer-Calculations-Overview\">How We Calculate Your Results</a>\n	</div>\n</div>\n\n<div class=\"zeroState moduleFrame hidden\">\n	<div class=\"header\">\n		<h2 class=\"title\">Retirement Fee Analyzer</h2>\n	</div>\n	<div class=\"frame\">\n		<div>\n			<h3>See the impact of fund fees on your <br/>future by linking your retirement <br/>accounts.</h3>\n			<div class=\"buttonContainer\">\n				<a class=\"pc-button mod-call-to-action js-link-account-btn\" href=\"#/accounts/add/investment/retirement\">Link Account</a>\n			</div>\n			<p id=\"zeroState_disclaimer\">If you've already linked a retirement account, <br/>update the <a href=\"https://"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"organizationSupportUrl") : depth0), depth0))
    + "/hc/en-us/articles/201169630-How-do-I-link-my-401k-account-for-the-401k-Analyzer-feature-\" target=\"_blank\">account type</a> if it was classified incorrectly.</p>\n		</div>\n		<div>\n			<img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"staticUrl") : depth0), depth0))
    + "/static/img/_401k/zeroStateGraphic.png\" />\n		</div>\n	</div>\n</div>\n"
    + ((stack1 = container.invokePartial(require("../../partials/footer.html"),depth0,{"name":"templates/partials/footer","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true});