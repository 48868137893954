import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

export default class AccountBlackoutPeriod extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { logoPath, name, instructions, onBack } = this.props;
    const blackoutMessage = `The connection to ${name} is currently down, but will be available soon. Please try again later.`;
    return (
      <div className="blackout-period__container pc-u-mt+">
        <div className="subLogo scroller">
          <div className="container">
            <div className="headerCopy">
              {logoPath && (
                <>
                  <img className="logo" src={logoPath} alt={name} />
                </>
              )}
            </div>
            <h2 className="blackout-period__text pc-u-mt">{blackoutMessage}</h2>

            {instructions && (
              <>
                <div className="add-account__instructions">{instructions}</div>
              </>
            )}

            <div className="buttonContainer l-spaced l-spaced--right pc-u-mt++">
              <button
                type="button"
                className="pc-btn qa-btn-back"
                onClick={onBack}
              >
                Link Another Account
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccountBlackoutPeriod.propTypes = {
  onBack: PropTypes.func,
  name: PropTypes.string.isRequired,
  logoPath: PropTypes.string,
  instructions: PropTypes.string,
};

AccountBlackoutPeriod.defaultProps = {
  onBack: noop,
  logoPath: undefined,
  instructions: undefined,
};
