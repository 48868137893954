var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"pc-btn pc-btn--tiny pc-btn--stripped js-edit-account-button\"\n          title=\"Edit account\" aria-label=\"Edit account\">\n          <svg class=\"account-details-edit-account-icon\" viewBox=\"0 0 16 16\">\n            <use xlink:href=\"#icon-pencil\"></use>\n          </svg>\n        </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class=\"pc-layout__item\">\n            <a class=\"summaryTab pc-btn pc-btn--bare qa-account-detail-summary-tab js-account-details-tabs "
    + ((stack1 = __default(require("../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tabId") : depth0),"==","summary",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":107},"end":{"line":22,"column":161}}})) != null ? stack1 : "")
    + "\"\n              href=\"#/accounts/details?ua="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "&tabId=summary\">Summary</a>\n          </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "is-active";
},"6":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"nav-secondary js-secondary-nav\">\n  <div class=\"l-spaced l-spaced--flush pc-u-mb-\">\n    <div class=\"pc-layout pc-layout--auto pc-layout--flush pc-layout--middle\">\n      <div class=\"pc-layout__item js-closed-text\">\n        <h1 class=\"u-preserve-case nav-secondary__title qa-page-title js-account-details-firm-name \" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"firmName") : depth0), depth0))
    + "\" data-hj-masked>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cappedFirmName") : depth0), depth0))
    + "</h1>\n      </div>\n      <div class=\"pc-layout__item\">\n"
    + ((stack1 = __default(require("../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"canEdit") : depth0),"==",true,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":15,"column":20}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <nav>\n      <ul class=\"pc-layout pc-layout--auto pc-layout--small\">\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0), depth0),{"name":"isEmpower","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":25,"column":22}}})) != null ? stack1 : "")
    + "        <li class=\"pc-layout__item\">\n          <a class=\"balanceTab pc-btn pc-btn--bare qa-account-detail-balance-tab js-account-details-tabs "
    + ((stack1 = __default(require("../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"tabId") : depth0),"==","balance",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":105},"end":{"line":27,"column":159}}})) != null ? stack1 : "")
    + "\"\n            href=\"#/accounts/details?ua="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "&tabId=balance\">Balance</a>\n        </li>\n        <li class=\"pc-layout__item\">\n          <a class=\"incomeTab pc-btn pc-btn--bare qa-account-detail-income-tab js-account-details-tabs "
    + ((stack1 = __default(require("../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"tabId") : depth0),"==","income",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":103},"end":{"line":31,"column":156}}})) != null ? stack1 : "")
    + "\"\n            href=\"#/accounts/details?ua="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "&tabId=income\">Income</a>\n        </li>\n        <li class=\"pc-layout__item\">\n          <a class=\"holdingsTab pc-btn pc-btn--bare qa-account-detail-holdings-tab js-account-details-tabs "
    + ((stack1 = __default(require("../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"tabId") : depth0),"==","holdings",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":107},"end":{"line":35,"column":162}}})) != null ? stack1 : "")
    + "\"\n            href=\"#/accounts/details?ua="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "&tabId=holdings\">Holdings</a>\n        </li>\n        <li class=\"pc-layout__item js-recommendation-tab-container is-hidden\">\n            <a class=\"recommendationTab pc-btn pc-btn--bare js-account-details-tabs "
    + ((stack1 = __default(require("../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"tabId") : depth0),"==","recommendation",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":84},"end":{"line":39,"column":145}}})) != null ? stack1 : "")
    + "\"\n             href=\"#/accounts/details?ua="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "&tabId=recommendation\">Advice</a>\n        </li>\n      </ul>\n    </nav>\n  </div>\n  <div class=\"l-spaced l-spaced--flush\">\n    <div class=\"account-details-account-name js-account-details-account-name js-closed-text\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" data-hj-masked>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cappedName") : depth0), depth0))
    + "</div>\n    <div class=\"account-details-info\">\n      <span class=\"js-closed-text\">Balance</span>\n      <svg class=\"icon__help-circled js-closed-icon\" viewBox=\"0 0 16 16\" data-container=\"body\" data-toggle=\"tooltip\" data-placement=\"auto\"\n        title=\"Balance is the account holdings with the most recent delayed quote.\">\n      </svg>\n      <span class=\"account-details-info__value js-closed-text\">"
    + alias2(__default(require("../helpers/dollarAndCentsAmount.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"balance") : depth0),true,true,(depth0 != null ? lookupProperty(depth0,"isLiability") : depth0),{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":52,"column":63},"end":{"line":52,"column":117}}}))
    + "</span>\n    </div>\n  </div>\n</div>\n\n<div class=\"nav-secondary nav-secondary--feature-controls "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isLiteHoldings") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":58},"end":{"line":57,"column":93}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"l-spaced l-spaced--flush\">\n    <div class=\"pc-layout pc-layout--auto centi\">\n      <div class=\"account-details-info pc-layout__item js-available-balance qa-available-balance\">\n        Prior Balance\n        <svg class=\"icon__help-circled\" viewBox=\"0 0 16 16\" data-container=\"body\"  data-toggle=\"tooltip\" data-placement=\"auto\"\n          title=\"Prior Balance is the last balance we received from your financial institution website.\">\n        </svg>\n        <span class=\"account-details-info__value\">"
    + alias2(__default(require("../helpers/dollarAndCentsAmount.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"priorBalance") : depth0),false,true,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":65,"column":50},"end":{"line":65,"column":98}}}))
    + "</span>\n      </div>\n      <div class=\"account-details-info pc-layout__item js-closed-date qa-closed-date is-hidden\">\n      </div>\n    </div>\n    <div class=\"pc-layout pc-layout--auto pc-layout--small\">\n"
    + ((stack1 = container.invokePartial(require("../partials/refetchTransactions.html"),depth0,{"name":"templates/partials/refetchTransactions","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <div class=\"pc-layout__item\">\n        <div class=\"dateSelector\"></div>\n      </div>\n    </div>\n  </div>\n</div>\n\n<div class=\"frame\">\n	<div class=\"visualization offset\">\n		<div id=\"balanceChart\" class=\"chart "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hideBalanceChart") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":38},"end":{"line":81,"column":75}}})) != null ? stack1 : "")
    + " qa-balance-chart\">\n			<div id=\"balanceSVG\" class=\"raphaelChart qa-balance-svg\"></div>\n			<div class=\"balance legend\">\n				<table>\n					<tr>\n						<td class=\"balance\">\n							<label></label>\n							<span></span>\n						</td>\n						<td class=\"change\">\n							<label>1-Day Change</label>\n							<span></span>\n						</td>\n					</tr>\n				</table>\n			</div>\n			<div class=\"startDate\"></div>\n			<div class=\"endDate\"></div>\n      <div class=\"noData js-no-balance-data\">\n        <table>\n          <tr>\n            <td>\n              <p class=\"u-text-bold pc-u-mb0\">No balance changes to display<span class=\"js-account-details-no-data-date-range\"></span></p>\n              <p>Select a different time frame to see how your balance changes over time</p>\n            </td>\n          </tr>\n        </table>\n      </div>\n      <div class=\"noData js-zero-data-for-balance-chart qa-zero-data-balance-chart\">\n        <table>\n          <tr>\n            <td>\n              <svg class='account-details__zero-data-balance-chart-icon'>\n                <use xlink:href=\"#pc-icon__sun\" />\n              </svg>\n              <p class=\"u-text-bold pc-u-mb0\">We only have today’s balance.</p>\n              <p>Return tomorrow to see how your balance changes over time.</p>\n            </td>\n          </tr>\n        </table>\n      </div>\n		</div>\n		<div id=\"incomeChart\" class=\"chart  "
    + ((stack1 = __default(require("../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"tabId") : depth0),"!=","income",{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":38},"end":{"line":123,"column":88}}})) != null ? stack1 : "")
    + " qa-income-chart\">\n			<div id=\"incomeSVG\" class=\"raphaelChart qa-income-svg\"></div>\n      <div class=\"noData js-no-income-data\">\n        <table>\n          <tr>\n            <td>There is no data to display for this timeframe.</td>\n          </tr>\n        </table>\n      </div>\n		</div>\n		<div id=\"performanceChart\" class=\"chart "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hidePerformanceChart") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":42},"end":{"line":133,"column":83}}})) != null ? stack1 : "")
    + " qa-performance-chart\">\n			<div id=\"performanceSVG\" class=\"raphaelChart qa-performance-svg\"></div>\n			<div class=\"performance legend\">\n				<table>\n					<tr>\n						<td class=\"performance\">\n							<label></label>\n							<span></span>\n						</td>\n						<td class=\"change\">\n							<label>1-Day Change</label>\n							<span></span>\n						</td>\n					</tr>\n				</table>\n			</div>\n			<div class=\"startDate\"></div>\n			<div class=\"endDate\"></div>\n      <div class=\"noData js-no-performance-data\">\n        <table>\n          <tr>\n            <td>There is no data to display for this timeframe.</td>\n          </tr>\n        </table>\n      </div>\n		</div>\n  </div>\n  <div class=\"js-recommendation-shell recommendation-shell is-hidden\">\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});