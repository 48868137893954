import PropTypes from "prop-types";
import React from "react";
import BaseInput from "components/common/form/BaseInput";
import Checkbox from "components/common/form/Checkbox";
import CompositeInput from "components/common/form/CompositeInput";

/**
 * `CheckboxGroup` component renders a list of checkboxes and provides
 * the validation support.
 * Validator should be provided via `validator` attribute in the format
 * https://github.com/flatiron/revalidator#schema
 *
 * Example:
 * ```
    {
      required: true
    }
 * ```
 *
 * `CheckboxGroup` options can be defined as an array of objects via `options` prop:
 * ```
    <CheckboxGroup
      options={[ {value: 'example_value', label: 'Example Option'}, ... ]}
    />
 * ```
 *
 * `CheckboxGroup` value an array of strings corresponding to the `value` attribute on
 * the option. Specify via `value` prop:
 * ```
    <CheckboxGroup
      options={[ {value: 'example_value', label: 'Example Option'}, ... ]}
      value={['example_value']}
    />
 * ```
 *
 * @export CheckboxGroup
 * @class CheckboxGroup
 * @extends {CompositeInput}
 */
export default class CheckboxGroup extends CompositeInput {
  constructor({ value }) {
    super(...arguments);

    // Override the default `value` of an empty string coming from `BaseInput` constructor.
    this.state.value = value;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleChange({ target }) {
    let value = this.state.value ?? [];
    if (target.checked) {
      value.push(target.value);
    } else {
      value = value.filter((v) => v !== target.value);
    }

    // We keep the value as `undefined` instead of an empty array to make the "required" validation work.
    if (!value.length) {
      value = undefined;
    }

    this.handleChangeWithValue(value);
  }

  render() {
    const {
      options,
      name,
      disabled,
      inputClassName,
      labelClassName,
      containerClassName,
      helpText,
    } = this.props;
    const { value, valid } = this.state;
    const className = `radio-group ${this.props.className} ${
      valid ? "" : "radio-group--error"
    }`;

    return (
      <div className={className}>
        <div className={containerClassName}>
          {options.map((o) => (
            <Checkbox
              name={name}
              value={o.value}
              label={o.label}
              checked={value?.includes(o.value)}
              checkboxClassName={inputClassName}
              labelClassName={labelClassName}
              disabled={disabled || o.disabled}
              key={o.value}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
          ))}
        </div>
        {helpText && (
          <label className="pc-help-block pc-help-block--small">
            {helpText}
          </label>
        )}
        {this.getErrorBlock({ placeholder: this.props.errorPlaceholder })}
      </div>
    );
  }
}

CheckboxGroup.propTypes = Object.assign({}, BaseInput.propTypes, {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  value: PropTypes.array,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  errorPlaceholder: PropTypes.bool,
  options: PropTypes.array,
});

CheckboxGroup.defaultProps = Object.assign({}, BaseInput.defaultProps, {
  className: "",
  containerClassName: "",
  options: [],
});
