import PropTypes from "prop-types";
import React from "react";
import PersonSelect from "common/PersonSelect/PersonSelect";
import Input from "components/common/form/Input";
import {
  HUNDRED_PERCENT,
  isRelativeValidSelectorOption,
  getTotalPercentage,
  getOverUnderText,
} from "./stakeholdersUtils";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PositiveCurrencyFormatter from "components/common/form/formatters/positiveCurrency";
import objectPath from "object-path";
import deepCopy from "deep-copy";
import { isEmpty } from "underscore";

const EMPTY_BENEFICIARY = {
  personId: null,
  percentage: "",
  role: "BENEFICIARY",
};

const NEW_PERSON_ID = -1;

export default class Beneficiaries extends React.Component {
  constructor(props) {
    super(props);

    const value = props.value;

    if (isEmpty(value.BENEFICIARY)) {
      value.BENEFICIARY.push(deepCopy(EMPTY_BENEFICIARY));
    }

    this.state = {
      value,
    };

    this.beneficiaryInputs = new Map();

    this.validatePercentage = this.validatePercentage.bind(this);
    this.storeBeneficiaryInputRef = this.storeBeneficiaryInputRef.bind(this);
    this.handleBeneficiaryAdd = this.handleBeneficiaryAdd.bind(this);
    this.handleBeneficiaryChange = this.handleBeneficiaryChange.bind(this);
    this.handleBeneficiaryRemove = this.handleBeneficiaryRemove.bind(this);
    this.handlePercentageChange = this.handlePercentageChange.bind(this);
    this.handlePercentageBlur = this.handlePercentageBlur.bind(this);
    this.renderBeneficiaryFormGroup =
      this.renderBeneficiaryFormGroup.bind(this);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  triggerChangeEvent(value) {
    // send a simple object that looks like an event
    this.props.onChange({
      target: {
        name: this.props.name,
        value,
      },
    });
  }

  handleBeneficiaryAdd(ev) {
    ev.preventDefault();

    const value = this.state.value;
    value.BENEFICIARY.push(deepCopy(EMPTY_BENEFICIARY));

    this.setState({ value }, this.validatePercentage);
    this.triggerChangeEvent(value);
  }

  handleBeneficiaryChange(ev) {
    const name = ev.target.name;
    const personId = parseInt(ev.target.value, 10);
    const indexRegex = /BENEFICIARY\.(\d+)\.personId/;
    const matches = indexRegex.exec(name);
    const index = parseInt(matches[1], 10);

    const value = this.state.value;
    objectPath.set(value, name, personId);

    this.setState({ value });
    this.triggerChangeEvent(value);

    if (personId === NEW_PERSON_ID) {
      this.props.onAddPersonClick(index);
    }
  }

  handlePercentageChange(ev) {
    const name = ev.target.name;
    let percentage = parseInt(ev.target.value, 10);
    if (isNaN(percentage)) {
      percentage = "";
    }

    const value = this.state.value;
    objectPath.set(value, name, percentage);

    this.setState({ value, dirty: true }, () => {
      // the validation of the total starts after the user leaves the input field
      if (this.state.validationStarted) {
        this.validatePercentage();
      }
    });
    this.triggerChangeEvent(value);
  }

  handlePercentageBlur(ev) {
    const reactEl = this.beneficiaryInputs.get(ev.target.name);
    if (reactEl.state.dirty) {
      this.setState({ validationStarted: true });
      this.validatePercentage();
    }
  }

  handleBeneficiaryRemove(ev) {
    const value = this.state.value;
    value.BENEFICIARY.splice(parseInt(ev.target.dataset.index, 10), 1);

    this.setState({ value }, this.validatePercentage);

    this.triggerChangeEvent(value);
  }

  /**
   * Validates the percentage total.
   *
   * @returns {Object}  the validation result consisting of an array of errors and a boolean
   *                    flag indicating that the value is valid {errors, valid}.
   */
  validatePercentage() {
    const { value } = this.state;
    const result = {
      valid: true,
      errors: null,
    };

    // valid if there is no form fields for beneficiaries
    if (value.BENEFICIARY.length) {
      // Don't validate the fieldset as the whole until all fields are populated
      let incomplete = value.BENEFICIARY.find((b) => b.percentage === "");
      if (!incomplete) {
        const totalPercent = getTotalPercentage(value.BENEFICIARY);
        if (totalPercent !== HUNDRED_PERCENT) {
          result.errors = [
            "The total for all primary beneficiaries must equal 100%",
          ];
          result.valid = false;
        }
      }
    }

    this.setState(result);

    return result;
  }

  /**
   * Validate method for compatibility with `AbstractForm`.
   * Validates child inputs and the percentage sum.
   *
   * @return {Object} The validation result
   */
  validate() {
    let errors = [];
    if (this.beneficiaryInputs.size > 0) {
      errors = Array.from(this.beneficiaryInputs.values())
        .map((el) => {
          const result = el.validate();
          if (!result.valid) {
            return result.errors;
          }
          return [];
        })
        .reduce((acc, cur) => acc.concat(cur), []);
    }

    const percentageResult = this.validatePercentage();
    if (!percentageResult.valid) {
      errors = errors.concat(percentageResult.errors);
    }

    // combined validation results of individual fields and percentage
    return {
      valid: errors.length === 0,
      errors: errors.length > 0 ? errors : undefined,
    };
  }

  storeBeneficiaryInputRef(name, el) {
    if (el) {
      this.beneficiaryInputs.set(name, el);
    } else {
      this.beneficiaryInputs.delete(name);
    }
  }

  /**
   * Generate JSX fragment containing the beneficiary selector and percent input for a given beneficiary and index.
   *
   * @param {object} beneficiary The currently selected beneficiary for this form group.
   * @param {number} index The index of the Beneficiary, used in event handling to update state.
   *
   * @returns {*} JSX fragment.
   */
  renderBeneficiaryFormGroup(beneficiary, index) {
    const { people, validator } = this.props;
    const { value } = this.state;

    const validRelatives = people.filter((person) =>
      isRelativeValidSelectorOption(person, beneficiary, value.BENEFICIARY)
    );

    return (
      <CSSTransition
        key={`beneItem${index}`}
        classNames="transition-form-group--inline"
        timeout={250}
      >
        <div>
          <div className="pc-form-group">
            <div className="pc-layout pc-layout--top">
              <div className="pc-layout__item pc-u-2/3">
                <PersonSelect
                  name={`BENEFICIARY.${index}.personId`}
                  people={validRelatives}
                  value={beneficiary.personId}
                  onChange={this.handleBeneficiaryChange}
                  ref={this.storeBeneficiaryInputRef}
                  validator={validator.properties.personId}
                  isAddNewPersonEnabled={true}
                  menuPortalTarget={document.body}
                />
              </div>
              <div className="pc-layout__item pc-u-1/3">
                <div className="pc-layout pc-layout--small">
                  <div className="pc-layout__item pc-u-3/4">
                    <Input
                      type="text"
                      name={`BENEFICIARY.${index}.percentage`}
                      ref={(el) =>
                        this.storeBeneficiaryInputRef(
                          `BENEFICIARY.${index}.percentage`,
                          el
                        )
                      }
                      placeholder="%"
                      maxLength={3}
                      className={`qa-beneficiary-input-${index} js-beneficiary-input-${index}`}
                      // TODO replace with cleave
                      formatter={PositiveCurrencyFormatter}
                      value={beneficiary.percentage}
                      sizeVariation="full"
                      onChange={this.handlePercentageChange}
                      onBlur={this.handlePercentageBlur}
                      validator={validator.properties.percentage}
                    />
                  </div>
                  <div className="pc-layout__item pc-u-1/4">
                    <button
                      type="button"
                      className={`pc-btn pc-btn--stripped qa-beneficiary-remove-btn-${index} js-beneficiary-remove-btn-${index}`}
                      onClick={this.handleBeneficiaryRemove}
                      data-index={index}
                      title={"Remove beneficiary"}
                    >
                      ×
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }

  render() {
    const { value, errors } = this.state;

    const error = errors && errors[0];

    return (
      <div>
        <div>
          <h2 className="pc-delta heading--emphasized">
            Primary Beneficiaries
          </h2>
          <CSSTransition
            in={Boolean(error)}
            unmountOnExit={true}
            classNames="transition-height"
            timeout={250}
          >
            <div className="edit-account-modal__beneficiary-section-error">
              <label
                className={`pc-help-block pc-help-block--small pc-help-block--error pc-help-block--error-bold`}
              >
                {error}
              </label>
            </div>
          </CSSTransition>
          <div>
            <TransitionGroup>
              {value.BENEFICIARY.map(this.renderBeneficiaryFormGroup)}
            </TransitionGroup>
            <div className="pc-form-group">
              <div className="pc-layout pc-layout">
                <div className="pc-layout__item pc-u-2/3">
                  <button
                    className="qa-add-beneficiary js-add-beneficiary pc-btn pc-btn--tiny"
                    type="button"
                    onClick={this.handleBeneficiaryAdd}
                  >
                    Add New Beneficiary
                  </button>
                </div>
                <div className="pc-layout__item pc-u-1/3">
                  <span className="pc-help-block--inline-block pc-help-block--small js-percentage-over-under-text">
                    {getOverUnderText(value.BENEFICIARY)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Beneficiaries.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  people: PropTypes.array.isRequired,
  validator: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onAddPersonClick: PropTypes.func.isRequired,
};

Beneficiaries.defaultProps = {
  value: { BENEFICIARY: [] },
  validator: { properties: {} },
};
