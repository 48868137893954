import React from "react";

import EnrollmentServices from "enrollmentServices";
import parseResponseErrors from "libs/pcap/utils/response";
import AppOverlay from "appOverlay";

const REQUIRES_TOOLTIP = 4;
const IN_PROGRESS = "in-progress";
const REQUIRES_ACTION = "requires-action";

const triggerDocusign = (documentId, actionType, showMessage) => {
  if (!documentId) return;

  AppOverlay.show();
  EnrollmentServices.Enrollment.getDocusignLink(
    { documentId, callbackPage: "TRANSFER_MANAGER" },
    (err, response) => {
      AppOverlay.hide();
      const errors = parseResponseErrors(err, response);
      if (errors) {
        showMessage({
          title: "Error",
          text: errors,
        });
      } else if (response && response.spData) {
        if (actionType === "SIGN_NOW")
          window.location.href = response.spData.eSignatureUrl;
        else if (actionType === "RESEND_EMAIL")
          showMessage({
            title: "Email Sent",
            text: "A new email has been sent to the secondary signer requesting their signature.  Please have them check their inbox and complete the signing process.",
          });
      }
    }
  );
};

const generateDefaultTimeline = (timeline, showMessage) => (
  <ul className="transfer-timeline">
    {timeline.map(
      ({ className, label, description, action = {} }, index, tl) => (
        <li
          key={index}
          className={`transfer-timeline__event${
            className !== IN_PROGRESS || className !== REQUIRES_ACTION
              ? ` transfer-timeline__event--${className}`
              : ""
          } js-transfer-timeline__event--${className}`}
        >
          {className === IN_PROGRESS || className === REQUIRES_ACTION ? (
            <div className="l-spaced l-spaced--left">
              <p className="transfer-timeline__action">
                <svg
                  className={`transfer-timeline-indicator`}
                  aria-label={label}
                >
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`#transfer-timeline-${className}-indicator`}
                  ></use>
                </svg>
              </p>
              <p className="transfer-timeline__event-type">{label}</p>
            </div>
          ) : (
            <p className="transfer-timeline__event-type">{label}</p>
          )}
          <div
            className={`transfer-timeline__event-data${
              tl.length - 1 === index
                ? ``
                : ` transfer-timeline__event-data--${className}`
            }`}
          >
            {className === REQUIRES_ACTION ? (
              <button
                className="pc-btn"
                onClick={() =>
                  triggerDocusign(action?.entityId, action?.entity, showMessage)
                }
                disabled={IS_ADVISOR}
              >
                {action?.label}
              </button>
            ) : (
              description
            )}
          </div>
        </li>
      )
    )}
  </ul>
);

const generateTimelineWithTooltip = (
  timeline,
  handleMouseEnter,
  handleMouseLeave,
  ref
) => (
  <ul className="transfer-timeline">
    <li
      className={`transfer-timeline__event transfer-timeline__event--${timeline[0].className} js-transfer-timeline__event--${timeline[0].className}`}
    >
      <p className="transfer-timeline__event-type">{timeline[0].label}</p>
      <div
        className={`transfer-timeline__event-data transfer-timeline__event-data--${timeline[0].className}`}
      >
        {timeline[0].description}
        <i
          className="transfer-timeline__tooltip js-transfer-timeline__tooltip"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <div
          className="transfer-timeline__tooltip-text js-transfer-timeline__tooltip-text"
          ref={ref}
        >
          {timeline[1]?.description}
        </div>
      </div>
    </li>
    <li
      className={`transfer-timeline__event${
        timeline[2].className !== IN_PROGRESS ||
        timeline[2].className !== REQUIRES_ACTION
          ? ` transfer-timeline__event--${timeline[2].className}`
          : ""
      } js-transfer-timeline__event--${timeline[2].className}`}
    >
      {timeline[2].className === IN_PROGRESS ||
      timeline[2].className === REQUIRES_ACTION ? (
        <div className="l-spaced l-spaced--left">
          <p className="transfer-timeline__action">
            <svg
              className={`transfer-timeline-indicator`}
              aria-label={timeline[2].label}
            >
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref={`#transfer-timeline-${timeline[2].className}-indicator`}
              ></use>
            </svg>
          </p>
          <p className="transfer-timeline__event-type">{timeline[2].label}</p>
        </div>
      ) : (
        <p className="transfer-timeline__event-type">{timeline[2].label}</p>
      )}
      <div
        className={`transfer-timeline__event-data transfer-timeline__event-data--${timeline[2].className}`}
      >
        {timeline[2].description}
      </div>
    </li>
    <li
      className={`transfer-timeline__event transfer-timeline__event--${timeline[3].className} js-transfer-timeline__event--${timeline[3].className}`}
    >
      <p className="transfer-timeline__event-type">{timeline[3].label}</p>
      <div className="transfer-timeline__event-data">
        {timeline[3].description}
      </div>
    </li>
  </ul>
);

export const generateTimeline = (
  timeline,
  handleMouseEnter,
  handleMouseLeave,
  ref,
  showMessage
) => {
  if (timeline.length < REQUIRES_TOOLTIP)
    return generateDefaultTimeline(timeline, showMessage);
  return generateTimelineWithTooltip(
    timeline,
    handleMouseEnter,
    handleMouseLeave,
    ref
  );
};
