var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='sidebar-account__detail-name'>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"firmName") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n    <div class='sidebar-account__message'>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='sidebar-account__message'>"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"instructions") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"sidebar-account__mfa-timer-container micro js-mfa-timer-container hide\">Time Remaining: <span class=\"centi js-mfa-timer qa-mfa-timer\"></span></div>\n"
    + ((stack1 = container.invokePartial(require("./prompts3.html"),depth0,{"name":"templates/modules/sidebar/prompts3","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='instructions'>"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"instructions") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./site.html"),depth0,{"name":"templates/modules/sidebar/site","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"js-account-extras sidebar-account__extras is-hidden\">\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"action") : stack1),"==","ONUS_ACCOUNT_IN_REVIEW",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":7,"column":16}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"action") : stack1),"!=","ONUS_ACCOUNT_IN_REVIEW",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":16,"column":16}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});