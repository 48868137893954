import PropTypes from "prop-types";
import React from "react";
import ChartReact from "libs/pcap/chart/chart--react";
import { min, max, curveMonotoneX } from "d3";
import { last } from "underscore";
import { trackEvent } from "components/common/ComponentAnalytics";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";

const COMPONENT_NAME = "insights";
const Y_AXIS_TICK_COUNT = 3;

function xAccessor(item) {
  return item.x;
}

function yAccessor(item) {
  return item.y;
}

function getUserAndTargetAllocationSeries(cashflow) {
  const userAllocationSeries = [];
  const targetAllocationSeries = [];
  for (let data of cashflow) {
    userAllocationSeries.push({
      x: data.age,
      y: data.investmentMedian,
    });
    targetAllocationSeries.push({
      x: data.age,
      y: data.targetInvestmentMedian,
    });
  }

  return { userAllocationSeries, targetAllocationSeries };
}

class RetirementPlannerForecast extends React.Component {
  constructor(props) {
    super(props);
    const jsonContent =
      props.message &&
      props.message.resources &&
      props.message.resources[0] &&
      props.message.resources[0].jsonContent;
    const { userAllocationSeries, targetAllocationSeries } =
      getUserAndTargetAllocationSeries(jsonContent.cashflow);
    this.state = {
      startAge: min(targetAllocationSeries, (item) => item.x),
      endAge: last(userAllocationSeries).x,
      userAllocationSeries,
      targetAllocationSeries,
      yDomain: [
        0,
        max(
          targetAllocationSeries.concat(userAllocationSeries),
          (item) => item.y
        ),
      ],
    };
  }

  componentDidMount() {
    const { isFirstRendered, message } = this.props;
    this.props[isFirstRendered ? "markAsImpressed" : "markAsViewed"](
      message.userMessageId
    );
    trackEvent(COMPONENT_NAME, "View Retirement Planner Forecast Insight");
  }

  render() {
    const { message = {}, markAsViewed } = this.props;
    return (
      <div className="insight__message">
        <div
          className="insight__header qa-insight-header"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div
          className="insight__summary"
          dangerouslySetInnerHTML={{ __html: message.summary }}
        />
        <div className="insight__summary">
          <div className="insight__chart-container insight__chart-container--retirement-plan-forecast">
            <ChartReact
              ref={(el) => {
                this.chartReact = el;
              }}
              className="insights-graph--retirement-plan-forecast"
              data={[
                this.state.targetAllocationSeries,
                this.state.userAllocationSeries,
              ]}
              type="area"
              curve={curveMonotoneX}
              interpolate="monotone"
              showXGrid={false}
              showXAxis={false}
              showYGrid={false}
              showYAxis={true}
              showYZeroLine={true}
              x={xAccessor}
              y={yAccessor}
              yDomain={this.state.yDomain}
              yAxisTicks={Y_AXIS_TICK_COUNT}
              margin={{ right: 40, left: 0, bottom: 3, top: 13 }}
            />
            <div className="l-spaced l-spaced--flush tax-loss-harvesting__axis-labels">
              <div>Age {this.state.startAge}</div>
              <div className="retirement-plan-forecast__axis-year">
                Age {this.state.endAge}
              </div>
            </div>
            <div className="retirement-plan-forecast__legend">
              Current Allocation
            </div>
            <div className="retirement-plan-forecast__legend retirement-plan-forecast__legend--target">
              Alternative allocation
            </div>
          </div>
        </div>
        <div className="insight__buttons">
          {getActionButtons({
            message,
            markAsViewed,
            appointmentReason: "Retirement Plan Forecast",
          })}
        </div>
      </div>
    );
  }
}

RetirementPlannerForecast.propTypes = {
  message: PropTypes.object,
  markAsViewed: PropTypes.func,
  markAsImpressed: PropTypes.func,
  isFirstRendered: PropTypes.bool,
};

export default RetirementPlannerForecast;
