import React from "react";
import Tile from "../Tile/Tile";
import Textarea from "components/common/form/Textarea";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import { noop } from "underscore";

class GoalsQuestionnaire extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        options: [],
      },
    };
    this.handleTileClick = this.handleTileClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
  }

  handleTileClick(tile) {
    const { model } = this.state;
    if (model.options.find((item) => item.key === tile.key)) {
      model.options = model.options.filter(function (item) {
        return item.key !== tile.key;
      });
    } else {
      model.options.push(tile);
    }
    this.setState({ model });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmit(this.state.model);
  }
  handleSkip() {
    this.props.onSubmit(this.state.model, true);
  }
  isSelected(tile) {
    return Boolean(
      this.state.model.options.find((item) => item.key === tile.key)
    );
  }
  render() {
    const { title, subTitle, options, textAreaData, hasSkipButton } =
      this.props;
    const { model } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="goals-questionnaire pc-u-pt+">
          <h1 className="goals-questionnaire__main-title u-text-center">
            {title}
          </h1>
          <p className="u-text-center pc-u-mb">{subTitle}</p>
          <div className="goals-questionnaire__tile-container">
            {options?.map((tile, index) => (
              <div
                className="goals-questionnaire__tile-box"
                key={`${index}-${tile.key}`}
              >
                <Tile
                  icon={tile?.icon}
                  hoverIcon={tile?.hoverIcon}
                  label={tile.label}
                  isSelected={this.isSelected(tile)}
                  onClick={() => this.handleTileClick(tile)}
                />
              </div>
            ))}
          </div>
          {textAreaData && (
            <>
              <h2 className="pc-u-mt pc-u-mb-">{textAreaData.label}</h2>
              <Textarea
                rows={textAreaData.rows}
                placeholder={textAreaData.placeholder}
                className="goals-questionnaire__comment"
                onChange={this.handleInputChange}
                name={"otherText"}
                value={model.otherText}
                ref={this.storeInputRef}
              />
            </>
          )}
          <div className="u-text-right pc-u-mb+">
            {hasSkipButton && (
              <button
                type="button"
                className="pc-btn pc-btn--small pc-u-ph- js-submit pc-u-mt"
                onClick={this.handleSkip}
              >
                Skip
              </button>
            )}
            <button
              type="submit"
              className="pc-btn pc-btn--small pc-btn--primary pc-u-ph- js-submit pc-u-mt"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    );
  }
}
export default GoalsQuestionnaire;

GoalsQuestionnaire.PropTypes = {
  options: PropTypes.object.required,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  textAreaData: PropTypes.object,
  onsubmit: PropTypes.func,
  hasSkipButton: PropTypes.bool,
};

GoalsQuestionnaire.defaultProps = {
  label: "",
  icon: "",
  isSelected: false,
  onsubmit: noop,
  textAreaData: null,
  hasSkipButton: false,
};
