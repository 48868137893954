import $ from "jquery";
import "datatables";
// eslint-disable-next-line no-useless-escape
var REGEX_NUMBER_CELL = /(?:<.*>)*(\-*\d+.*\d+)(?:<\/)*/;

//datatables sorting
$.extend($.fn.dataTableExt?.oSort, {
  "dollar-asc": function (a, b) {
    var af = $.pcap.stripDollarFormatting(a);
    var bf = $.pcap.stripDollarFormatting(b);
    if (af < bf) {
      return -1;
    } else if (af > bf) {
      return 1;
    }
    return 0;
  },

  "dollar-desc": function (a, b) {
    var af = $.pcap.stripDollarFormatting(a);
    var bf = $.pcap.stripDollarFormatting(b);
    if (af < bf) {
      return 1;
    } else if (af > bf) {
      return -1;
    }
    return 0;
  },
});
//datatables sorting
$.extend($.fn.dataTableExt?.oSort, {
  "percent-asc": function (a, b) {
    if (a[0] === "<") {
      a = 0.005; /* Previus impl */ //eslint-disable-line no-magic-numbers
    }
    if (b[0] === "<") {
      b = 0.005; /* Previus impl */ //eslint-disable-line no-magic-numbers
    }
    var af = parseFloat(a);
    var bf = parseFloat(b);
    if (af < bf) {
      return -1;
    } else if (af > bf) {
      return 1;
    }
    return 0;
  },

  "percent-desc": function (a, b) {
    if (a[0] === "<") {
      a = 0.005; /* Previus impl */ //eslint-disable-line no-magic-numbers
    }
    if (b[0] === "<") {
      b = 0.005; /* Previus impl */ //eslint-disable-line no-magic-numbers
    }
    var af = parseFloat(a);
    var bf = parseFloat(b);
    if (af < bf) {
      return 1;
    } else if (af > bf) {
      return -1;
    }
    return 0;
  },
});

function sortNumberCellAsc(a, b) {
  var resultA = REGEX_NUMBER_CELL.exec(a);
  a = resultA === "<span>-</span>" ? a : 0;
  a = resultA ? resultA[1] : a;
  var resultB = REGEX_NUMBER_CELL.exec(b);
  b = resultA === "<span>-</span>" ? b : 0;
  b = resultB ? resultB[1] : b;
  var af = parseFloat(a);
  var bf = parseFloat(b);
  if (af < bf) {
    return -1;
  } else if (af > bf) {
    return 1;
  }
  return 0;
}

function sortNumberCellDesc(a, b) {
  var resultA = REGEX_NUMBER_CELL.exec(a);
  a = resultA === "<span>-</span>" ? a : 0;
  a = resultA ? resultA[1] : a;
  var resultB = REGEX_NUMBER_CELL.exec(b);
  b = resultA === "<span>-</span>" ? b : 0;
  b = resultB ? resultB[1] : b;
  var af = parseFloat(a);
  var bf = parseFloat(b);
  if (af < bf) {
    return 1;
  } else if (af > bf) {
    return -1;
  }
  return 0;
}

function sortNumberWrappedCellAsc(a, b) {
  var af = $.pcap.stripDollarFormatting($(a).text());
  var bf = $.pcap.stripDollarFormatting($(b).text());

  // If the dollar amount is not a number we want to keep
  // the account on the bottom of the gird.
  var parsed = parseFloat($(a).text().replace(/\$|,/g, ""));
  if (isNaN(parsed)) {
    return 1;
  }

  if (af < bf) {
    return -1;
  } else if (af > bf) {
    return 1;
  }
  return 0;
}

function sortNumberWrappedCellDesc(a, b) {
  var af = $.pcap.stripDollarFormatting($(a).text());
  var bf = $.pcap.stripDollarFormatting($(b).text());

  if (af < bf) {
    return 1;
  } else if (af > bf) {
    return -1;
  }
  return 0;
}

function sortNumberWrappedCellDollarAndPercentageAsc(a, b) {
  var af = $.pcap.stripDollarFormatting(a);
  var bf = $.pcap.stripDollarFormatting(b);
  if (af < bf) {
    return -1;
  } else if (af > bf) {
    return 1;
  }
  return 0;
}

function sortNumberWrappedCellDollarAndPercentageDesc(a, b) {
  var af = $.pcap.stripDollarFormatting(a);
  var bf = $.pcap.stripDollarFormatting(b);

  if (af < bf) {
    return 1;
  } else if (af > bf) {
    return -1;
  }
  return 0;
}

$.extend($.fn.dataTableExt?.oSort, {
  "formattedPercent-asc": sortNumberCellAsc,
  "formattedPercent-desc": sortNumberCellDesc,
  "formattedDollar-asc": sortNumberCellAsc,
  "formattedDollar-desc": sortNumberCellDesc,
  "formattedWrappedDollar-asc": sortNumberWrappedCellAsc,
  "formattedWrappedDollar-desc": sortNumberWrappedCellDesc,
  "formattedWrappedDollarAndPercentage-asc":
    sortNumberWrappedCellDollarAndPercentageAsc,
  "formattedWrappedDollarAndPercentage-desc":
    sortNumberWrappedCellDollarAndPercentageDesc,
});

$.extend($.fn.dataTableExt?.oSort, {
  "devicesDateTime-asc": function (a, b) {
    a = Number(a.substr(21, 13)); /* Previus impl */ //eslint-disable-line no-magic-numbers
    b = Number(b.substr(21, 13)); /* Previus impl */ //eslint-disable-line no-magic-numbers
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    return 0;
  },

  "devicesDateTime-desc": function (a, b) {
    a = Number(a.substr(21, 13)); /* Previus impl */ //eslint-disable-line no-magic-numbers
    b = Number(b.substr(21, 13)); /* Previus impl */ //eslint-disable-line no-magic-numbers
    if (a < b) {
      return 1;
    } else if (a > b) {
      return -1;
    }
    return 0;
  },
});
