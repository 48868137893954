import React from "react";
import PropTypes from "prop-types";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import RecurringTransfersTable from "components/transferFunds/RecurringTransfersTable";

const CancelRecurringTransfer = (props) => {
  if (props.loading) {
    return <LoadingOverlay active={props.loading} />;
  }

  return (
    <div className="qa-cancel-recurring-transfer">
      <Message severity="error" messages={props.errors} />
      <p>
        Are you sure you want to cancel this recurring request? Note:
        Cancellations may take up to 3 business days to process. If your next
        recurring request is scheduled to occur within that time, the request
        may not be able to be canceled.
      </p>
      <RecurringTransfersTable transfers={[props.transfer]} />
      <div className="pc-u-mt u-text-right">
        <button
          type="button"
          className="pc-btn js-cancel-btn"
          onClick={props.onCancel}
        >
          {"Don't Cancel"}
        </button>
        <button
          type="button"
          className="pc-btn pc-btn--primary js-confirm-btn qa-action-confirm"
          onClick={props.onSubmit}
        >
          Cancel Request
        </button>
      </div>
    </div>
  );
};

CancelRecurringTransfer.defaultProps = {
  loading: false,
  errors: [],
};

CancelRecurringTransfer.propTypes = {
  transfer: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
};

export default CancelRecurringTransfer;
