import React from "react";
export default function CallCenterIcon() {
  return (
    <svg
      width="110px"
      height="110px"
      viewBox="0 0 110 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon_call_center</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Group3---Round-2-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="We're-here-to-help"
          transform="translate(-75.000000, -62.000000)"
        >
          <g
            id="marketing/security/link_01-copy-78"
            transform="translate(75.000000, 62.000000)"
          >
            <g filter="url(#filter-1)" id="circle">
              <g transform="translate(0.000000, -0.000611)">
                <path
                  d="M110,54.9992369 C110,85.3747971 85.3759816,110 55,110 C24.6255445,110 0,85.3747971 0,54.9992369 C0,24.6252029 24.6255445,0 55,0 C85.3759816,0 110,24.6252029 110,54.9992369"
                  id="Fill-1"
                  fill="#F3F6F7"
                ></path>
              </g>
            </g>
            <g transform="translate(17.315681, 22.749720)">
              <path
                d="M33.3869861,11.7036552 C31.9386528,20.0483774 24.6786528,26.3993497 15.918375,26.3993497 C15.3072639,26.3993497 14.7037917,26.3703219 14.1079583,26.3092108 C13.7137917,28.2449052 13.5549028,30.2661552 13.6664306,32.343933 C14.3142083,44.320183 24.2386528,54.1040719 36.2210139,54.5837941 C49.674625,55.1246274 60.7510139,44.3782385 60.7510139,31.0437941 C60.7510139,29.428933 60.5875417,27.8522663 60.2774028,26.3275441 C59.8022639,26.3504608 59.3332361,26.3993497 58.8519861,26.3993497 C47.9680972,26.3993497 38.4867083,20.4822663 33.3869861,11.7036552 Z"
                stroke="#002157"
                strokeWidth="1.5"
              ></path>
              <path
                d="M7.45356944,36.2435858 L7.45356944,30.4105302 C7.45356944,14.9081691 19.047875,1.48358577 34.4875972,0.119280215 C52.1288472,-1.43599756 66.9284306,12.4224747 66.9284306,29.738308 L66.9284306,38.5092802 C66.9284306,51.5351135 56.2034306,59.6369191 42.5542639,63.4288635"
                stroke="#002157"
                strokeWidth="1.5"
                strokeLinecap="round"
              ></path>
              <path
                d="M69.840375,42.2353774 L71.4689861,42.2353774 C73.071625,42.2353774 74.3824583,40.9260719 74.3824583,39.3249608 L74.3824583,26.9835719 C74.3824583,25.3824608 73.071625,24.0700997 71.4689861,24.0700997 L69.840375,24.0700997 C68.2392639,24.0700997 66.9284306,25.3824608 66.9284306,26.9835719 L66.9284306,39.3249608 C66.9284306,40.9260719 68.2392639,42.2353774 69.840375,42.2353774 Z"
                stroke="#DB1830"
                strokeWidth="1.5"
                strokeLinecap="square"
              ></path>
              <path
                d="M2.91194444,42.2353774 L4.54055556,42.2353774 C6.14319444,42.2353774 7.45402778,40.9260719 7.45402778,39.3249608 L7.45402778,26.9835719 C7.45402778,25.3824608 6.14319444,24.0700997 4.54055556,24.0700997 L2.91194444,24.0700997 C1.31083333,24.0700997 0,25.3824608 0,26.9835719 L0,39.3249608 C0,40.9260719 1.31083333,42.2353774 2.91194444,42.2353774 Z"
                stroke="#DB1830"
                strokeWidth="1.5"
                strokeLinecap="square"
              ></path>
              <path
                d="M36.5067083,63.5697247 C36.5067083,64.2083358 35.9872639,64.7247247 35.3486528,64.7247247 C34.7100417,64.7247247 34.1936528,64.2083358 34.1936528,63.5697247 C34.1936528,62.9311135 34.7100417,62.4147247 35.3486528,62.4147247 C35.9872639,62.4147247 36.5067083,62.9311135 36.5067083,63.5697247 Z"
                fill="#DB1830"
              ></path>
              <path
                d="M28.4400417,63.5697247 C28.4400417,64.2083358 27.9236528,64.7247247 27.2850417,64.7247247 C26.6464306,64.7247247 26.1269861,64.2083358 26.1269861,63.5697247 C26.1269861,62.9311135 26.6464306,62.4147247 27.2850417,62.4147247 C27.9236528,62.4147247 28.4400417,62.9311135 28.4400417,63.5697247 Z"
                fill="#DB1830"
              ></path>
              <path
                d="M20.373375,63.5697247 C20.373375,64.2083358 19.8539306,64.7247247 19.2153194,64.7247247 C18.5767083,64.7247247 18.0603194,64.2083358 18.0603194,63.5697247 C18.0603194,62.9311135 18.5767083,62.4147247 19.2153194,62.4147247 C19.8539306,62.4147247 20.373375,62.9311135 20.373375,63.5697247 Z"
                fill="#DB1830"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
