import React from "react";
import Modal from "components/modal/Modal";

const componentName = "Canadian Onboarding Modal Message";

export default class ForeignerOnboardingModalMessage extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      showModal: true,
    };
  }

  onModalClosed() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const modalTitle = "US only service";
    return (
      <Modal
        componentName={componentName}
        title={modalTitle}
        onClosed={this.onModalClosed.bind(this)}
        // react-modal APIs
        contentLabel={componentName}
        isOpen={this.state.showModal}
        isTitleSentenceCase={false}
      >
        <p>
          At this time we only support US Banks & Financial Institutions. You
          can search and link US institutions below. Canadian financial
          institutions are not supported at this time, but we will notify you
          when {EMPOWER_PERSONAL_DASHBOARD} is available in Canada.
        </p>
      </Modal>
    );
  }
}
