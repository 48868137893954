import _ from "underscore";
import BaseStateModel from "stateModels/baseStateModel";
import moment from "moment";
import DateUtil from "libs/pcap/utils/date";
function AccountDetailState(userAccountId) {
  this.userAccountId = userAccountId;
  this.baseUrl = "/accounts/details?ua=";
  this.optionalUrlParams = ["tabId"];

  // Define default values
  this.startDate = moment(DateUtil.nowString(), DateUtil.DISPLAY_FORMAT)
    .subtract(89, "days")
    .format(DateUtil.DISPLAY_FORMAT);

  this.endDate = DateUtil.nowString();
}

AccountDetailState.prototype = new BaseStateModel();
AccountDetailState.prototype.constructor = AccountDetailState;

export default AccountDetailState;
