import PropTypes from "prop-types";
import React, { Component } from "react";
import { noop } from "jquery";

/**
 * `ButtonOptions` component renders a list of buttons
 * It Sorts the buttons based on their type(Primary, secondary), It always shows the primary button on the right
 *
 * @export ButtonOptions
 * @class ButtonOptions
 * @extends {React.Component}
 */
export default class ButtonOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.value,
    };
  }

  sortOptions(options) {
    const primaryObject = options.find((o) => o.type === "PRIMARY");
    const sortedOptions = options.filter((o) => o.type !== "PRIMARY");
    sortedOptions.push(primaryObject);
    return sortedOptions;
  }

  onClick(option, event) {
    this.setState({ selected: option.value });
    this.props?.onClick?.(option, event);
  }

  render() {
    const { options, name, unsorted, nonPrimaryButtonClass } = this.props;
    const { selected } = this.state;
    const className = `button-options ${this.props.className} pc-u-mt`;
    const sortedOptions = unsorted ? options : this.sortOptions(options);

    return (
      <div className={className}>
        {sortedOptions.map((o) => (
          <button
            key={o.value}
            value={o.value}
            name={name}
            type="submit"
            className={`pc-btn ${
              o.type === "PRIMARY" ? "pc-btn--primary" : nonPrimaryButtonClass
            } ${o.value === selected ? "is-selected" : ""}`}
            onClick={(event) => this.onClick(o, event)}
          >
            {o.label}
          </button>
        ))}
      </div>
    );
  }
}

ButtonOptions.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  options: PropTypes.array,
  unsorted: PropTypes.bool,
  value: PropTypes.string,
  nonPrimaryButtonClass: PropTypes.string,
};

ButtonOptions.defaultProps = {
  className: "",
  name: "",
  onClick: noop,
  options: [],
  unsorted: false,
  value: "",
  nonPrimaryButtonClass: "",
};
