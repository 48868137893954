import React from "react";
import memoizeOne from "memoize-one";
import PropTypes from "prop-types";
import AbstractForm from "../../form/AbstractForm";
import Input from "../../form/Input";
import Select from "../../form/Select";
import States from "../../../../enums/states";
import deepCopy from "deep-copy";
import { POSITIVE_CURRENCY_FORMAT } from "../../form/formattingOptions";
import { handleAmplitudeEvent } from "../../../../empower/utils/eventUtils";

const sentenceCase = "u-sentence-case";
const MAX_PROPERTY_VALUE = 99999999.99;

const PROPERTY_TYPES = [
  {
    value: "PRIMARY_RESIDENCE",
    label: "Primary residence",
  },
  {
    value: "VACATION_HOME",
    label: "Vacation home",
  },
  {
    value: "INVESTMENT_PROPERTY",
    label: "Investment property",
  },
];
const ADDRESS_MAX_LENGTH = 40;
const VALIDATION_SCHEMA = {
  propertyType: {
    required: true,
    type: "string",
    allowEmpty: false,
    messages: {
      allowEmpty: "Please select a property type.",
    },
  },
  addressline: {
    required: true,
    type: "string",
    allowEmpty: false,
    messages: {
      allowEmpty: "Please enter address.",
    },
  },
  city: {
    required: true,
    type: "string",
    allowEmpty: false,
    messages: {
      allowEmpty: "Please enter city.",
    },
  },
  state: {
    required: true,
    type: "string",
    allowEmpty: false,
    messages: {
      allowEmpty: "Please select state.",
    },
  },
  zip: {
    required: true,
    type: "number",
    allowEmpty: false,
    messages: {
      allowEmpty: "Please enter zip code.",
      type: "Please enter numeric value.",
    },
  },
};

const CURRENT_BALANCE_VALIDATOR = {
  required: true,
  allowEmpty: false,
  conform: (v) => v.trim().split(",").join("") <= MAX_PROPERTY_VALUE,
  type: "string",
  messages: {
    allowEmpty: "Please enter current value.",
    conform: "Must be less than or equal to $99,999,999.99",
  },
};

let getValidator = memoizeOne((withCurrentBalance) => {
  if (!withCurrentBalance) {
    return VALIDATION_SCHEMA;
  }
  let validator = deepCopy(VALIDATION_SCHEMA);
  validator.currentBalance = CURRENT_BALANCE_VALIDATOR;
  return validator;
});

const getImageBaseUrl = () =>
  IS_ADVISOR
    ? `${window.staticUrl || ""}/static/img/`
    : `${window.baseUrl}static/img/`;

export default class ZillowCredentialFields extends AbstractForm {
  constructor(props) {
    super(props);

    this.state = {
      isBalanceFieldVisible: true,
    };
    this.handleUseHomeValuationChange =
      this.handleUseHomeValuationChange.bind(this);
  }

  handleUseHomeValuationChange(ev, value) {
    this.setState({
      isBalanceFieldVisible: !this.state.isBalanceFieldVisible,
    });
    this.props.onChange(ev, value);
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "modal_link_your_account_current_value_clicked_event"
      );
      handleAmplitudeEvent(
        "SELECT_BUTTON",
        "modal_link_your_account_current_value_clicked_event"
      );
    }
  }

  render() {
    const { model, onChange, inputValuesClassName } = this.props;
    const { isBalanceFieldVisible } = this.state;
    let validator = getValidator(isBalanceFieldVisible);
    const homeValuationValue =
      model.useHomeValuation === undefined
        ? ""
        : model.useHomeValuation.toString();

    let zestimateClassName = this.props.inputValuesClassName
      ? "pc-layout pc-layout--flush " + this.props.inputValuesClassName
      : "pc-layout pc-layout--flush";

    return (
      <div className={"milli"}>
        <div className={inputValuesClassName}>
          <label className={`pc-label ${sentenceCase}`} htmlFor="propertyType">
            Property Type
          </label>
          <Select
            className="input input--full qa-propertytype"
            name="propertyType"
            id="propertyType"
            options={PROPERTY_TYPES}
            ref={this.storeInputRef}
            onChange={onChange}
            value={model.propertyType}
            validator={validator.propertyType}
          />
        </div>
        <div className={inputValuesClassName}>
          <label className="pc-label" htmlFor="addressline">
            Address
          </label>
          <Input
            className="qa-addressline"
            ref={this.storeInputRef}
            type="text"
            id="addressline"
            placeholder="Required"
            sizeVariation="full"
            name="addressline"
            onChange={onChange}
            validator={validator.addressline}
            maxLength={ADDRESS_MAX_LENGTH}
            value={model.addressline}
          />
        </div>
        <div className={inputValuesClassName}>
          <label className="pc-label" htmlFor="city">
            City
          </label>
          <Input
            className={"qa-city"}
            ref={this.storeInputRef}
            placeholder="Required"
            type="text"
            id="city"
            sizeVariation="full"
            name="city"
            onChange={onChange}
            validator={validator.city}
            value={model.city}
          />
        </div>
        <div className={zestimateClassName}>
          <div className="pc-layout__item pc-u-1/2">
            <label className="pc-label" htmlFor="state">
              State
            </label>
            <Select
              id="state"
              name="state"
              ref={this.storeInputRef}
              value={model.state}
              placeholder="Select One"
              className="add-account__zillow-state-select qa-state  pc-u-mr-"
              onChange={onChange}
              validator={validator.state}
              options={States}
            />
          </div>
          <div className="pc-layout__item pc-u-1/2">
            <label className="pc-label" htmlFor="subject">
              Zip
            </label>
            <Input
              className="qa-zip"
              ref={this.storeInputRef}
              type="number"
              id="zip"
              sizeVariation="full"
              placeholder="Required"
              name="zip"
              onChange={onChange}
              validator={validator.zip}
              value={model.zip}
            />
          </div>
        </div>
        <div className="pc-layout pc-layout--flush">
          <div className="pc-layout__item pc-u-1/2">
            <div>
              <label
                className={`pc-label ${sentenceCase}`}
                htmlFor="useHomeValuation"
              >
                Current Value
              </label>
              <Input
                containerClassName="input--inline-block pc-u-mr--"
                className="qa-use-home-valuation"
                ref={this.storeInputRef}
                type="checkbox"
                id="useHomeValuation"
                name="useHomeValuation"
                onChange={this.handleUseHomeValuationChange}
                value={homeValuationValue}
                checked={model.useHomeValuation}
                labelledby="zestimate"
              />
              <label id="zestimate">
                Use{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://www.zillow.com/zestimate"
                  target="_blank"
                >
                  Zestimate®
                </a>{" "}
                for home value
              </label>
            </div>
          </div>
          <div
            className="pc-layout__item pc-u-1/2"
            style={{ verticalAlign: "bottom", padding: "6px" }}
          >
            <img
              src={
                getImageBaseUrl() +
                "linkAccount/Zillow_Wordmark_Blue_RGB_lrg.png"
              }
              alt="Real Estate on Zillow"
              style={{ maxWidth: "80px", maxHeight: "40px" }}
            />
          </div>
          <div className="milli pc-layout__item homeValuationCopyright">
            By using Zestimate® for your home valuation, you agree to the Zillow{" "}
            <a
              href="https://bridgedataoutput.com/zillowterms"
              rel="noreferrer"
              target="_blank"
            >
              Terms of Use.
            </a>{" "}
            &copy; Zillow, Inc., 2006-2021
          </div>
        </div>
        {isBalanceFieldVisible && (
          <div>
            <Input
              className="qa-currentbalance"
              ref={this.storeInputRef}
              type="text"
              id="currentBalance"
              sizeVariation="full"
              name="currentBalance"
              onChange={onChange}
              placeholder="Required"
              validator={validator.currentBalance}
              value={model.currentBalance}
              formattingOptions={POSITIVE_CURRENCY_FORMAT}
              prefix="$"
              ariaLabel="Current Home Value"
            />
          </div>
        )}
      </div>
    );
  }
}

ZillowCredentialFields.propTypes = {
  model: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  inputValuesClassName: PropTypes.string,
};
