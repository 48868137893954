var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"js-first-use-questions-form first-use-questionnaire first-use-questionnaire--align-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"textAlign") : stack1), depth0))
    + "\" method=\"post\" action=\"#\">\n  <h2 class=\"first-use-questionnaire__title\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "</h2>\n  <h3 class=\"first-use-quesitonnaire__subtitle\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"intro") : stack1), depth0)) != null ? stack1 : "")
    + "</h3>\n  <div class=\"first-use-questionnaire__form-body\">\n    "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"fieldLabels") : stack1)) != null ? lookupProperty(stack1,"name") : stack1)) != null ? lookupProperty(stack1,"pre") : stack1), depth0)) != null ? stack1 : "")
    + "\n    <div class=\"inputWrap\">\n      <input\n        type=\"text\"\n        class=\"firstName js-first-name\"\n        name=\"name[firstName]\"\n        value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + "\"\n        data-lpignore=\"true\"\n        data-hj-masked\n      /><label>What will we call you?</label>\n    </div>\n    "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"fieldLabels") : stack1)) != null ? lookupProperty(stack1,"age") : stack1)) != null ? lookupProperty(stack1,"pre") : stack1), depth0)) != null ? stack1 : "")
    + "\n    <div class=\"inputWrap\">\n      <input\n        type=\"text\"\n        name=\"age\"\n        class=\"age\"\n        value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"age") || (depth0 != null ? lookupProperty(depth0,"age") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"age","hash":{},"data":data,"loc":{"start":{"line":22,"column":15},"end":{"line":22,"column":22}}}) : helper)))
    + "\"\n        maxlength=\"3\"\n        data-hj-masked\n      /><label></label>\n    </div>\n    "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"fieldLabels") : stack1)) != null ? lookupProperty(stack1,"age") : stack1)) != null ? lookupProperty(stack1,"post") : stack1), depth0)) != null ? stack1 : "")
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"fieldLabels") : stack1)) != null ? lookupProperty(stack1,"retirementAge") : stack1)) != null ? lookupProperty(stack1,"pre") : stack1), depth0)) != null ? stack1 : "")
    + "\n    <div class=\"inputWrap\">\n      <input\n        type=\"text\"\n        class=\"retirementAge\"\n        name=\"retirementAge\"\n        value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"retirementAge") || (depth0 != null ? lookupProperty(depth0,"retirementAge") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"retirementAge","hash":{},"data":data,"loc":{"start":{"line":33,"column":15},"end":{"line":33,"column":32}}}) : helper)))
    + "\"\n        maxlength=\"2\"\n        data-hj-masked\n      />"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"fieldLabels") : stack1)) != null ? lookupProperty(stack1,"retirementAge") : stack1)) != null ? lookupProperty(stack1,"post") : stack1), depth0)) != null ? stack1 : "")
    + "<label></label>\n    </div>\n    "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"fieldLabels") : stack1)) != null ? lookupProperty(stack1,"assetValue") : stack1)) != null ? lookupProperty(stack1,"pre") : stack1), depth0)) != null ? stack1 : "")
    + "\n    <div class=\"inputWrap\">\n      <input\n        class=\"investibleAssets\"\n        name=\"investableAssetsValue\"\n        value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"investableAssets") : depth0)) != null ? lookupProperty(stack1,"userInputInvestableAssets") : stack1), depth0))
    + "\"\n        type=\"text\"\n        maxlength=\"11\"\n        data-lpignore=\"true\"\n        data-hj-masked\n      /><label>Please enter a value. </label>\n    </div>\n    "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"fieldLabels") : stack1)) != null ? lookupProperty(stack1,"assetValue") : stack1)) != null ? lookupProperty(stack1,"post") : stack1), depth0)) != null ? stack1 : "")
    + "<span\n      class=\"pc-u-ml-- icon__help-circled\"\n      data-toggle=\"tooltip\"\n      title\n      data-original-title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"madLibs") : depth0)) != null ? lookupProperty(stack1,"fieldLabels") : stack1)) != null ? lookupProperty(stack1,"tooltipContent") : stack1), depth0))
    + "\"\n    ></span>\n  </div>\n  <div class=\"actions\">\n    <input\n      class=\"pc-btn pc-btn--primary first-use-questionnaire__form-submit-button submit js-next-button\"\n      type=\"submit\"\n      value=\"Next\"\n    />\n  </div>\n</form>\n";
},"useData":true});