/* eslint-disable no-underscore-dangle, no-invalid-this, consistent-this, no-magic-numbers */
import eventBus from "eventBus";

import * as customEvents from "libs/pcap/utils/customEvents";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import moment from "moment";
import Questionnaire from "models/questionnaire";
import _401kQuestionnaireTemplateRebrand from "templates/modules/_401k/home_rebrand/_401kQuestionnaire.html";
var _401kQuestionnaireView = Backbone.View.extend({
  events: {
    "click a.submitForm": "submitForm",
    "submit .questionnaire401kForm": "submit401kQuestionnaire",
  },
  initialize: function () {
    this.model = Questionnaire;
    this.render();
    this.buildSlider();
    //this.addFocusOutListeners();
    this.model.on("change", this.onQuestionnaireChange, this);
    this.model.fetch();
  },
  onQuestionnaireChange: function () {
    this.render();
    this.buildSlider();
  },
  addFocusOutListeners: function () {
    $(".formElement:visible", this.$el).each(function () {
      if ($(this).data("is_required")) {
        $(this).find("input, select").focusout(this.onFocusOut);
      }
    });
  },
  onFocusOut: function (event) {
    if ($(event.target).val() === "") {
      $(event.target).parents(".formElement").addClass("invalid");
    } else {
      $(event.target).parents(".formElement").removeClass("invalid");
    }
  },
  render: function () {
    var data = this.model.toJSON();
    var template = _401kQuestionnaireTemplateRebrand;
    this.$el.html(template(data));
    $(".toolTipped", this.$el).tipTip();
    return this;
  },
  buildSlider: function () {
    var self = this;
    function buildRangeLabels(min, max, formatter) {
      return (
        '<span class="min">' +
        formatter(min) +
        '</span><span class="max">' +
        formatter(max) +
        "</span>"
      );
    }
    this.$el.find(".retirement").each(function () {
      var min = parseFloat($(this).data("min"));
      var max = parseFloat($(this).data("max"));
      var formatter = self.sliderFormatters[$(this).data("formatter")];
      $(this)
        .slider({
          range: "min",
          value: $(this).data("val"),
          min: min,
          max: max,
          slide: function (event, ui) {
            $(event.target).find("span.tip").text(formatter(ui.value));
          },
        })
        .find(".ui-slider-handle")
        .html(
          '<span class="tip">' + formatter($(this).data("val")) + "</span>"
        );
      $(buildRangeLabels(min, max, formatter)).insertBefore(
        $(this).find(".ui-slider-handle")
      );
    });
  },
  sliderFormatters: {
    none: function (val) {
      return val;
    },
    dollars: function (val) {
      return $.pcap.formatDollars(val, false);
    },
    percentage: function (val) {
      return (val / 100).toFixed(1) + "%";
    },
    basisPoints: function (val) {
      return (val / 100.0).toFixed(2) + "%";
    },
  },
  submitForm: function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.$el.find("form").trigger("submit");
  },
  submit401kQuestionnaire: function (e) {
    var self = this;
    e.preventDefault();
    self.validateAndSubmit(e.currentTarget, function (model, self) {
      //form elements
      var changes = {
        birthday: {
          month: self.$el.find("#birthMonth").val(),
          day: self.$el.find("#birthDay").val(),
          year: self.$el.find("#birthYear").val(),
        },
        retirementAge: Number($(".retirement", self.$el).slider("value")),
        companySize: $(".companySize", self.$el).val(),
        userRiskTolerance: $(".riskTolerance", self.$el).val(),
        secondPersonContributing: $(".spouseContributing", self.$el).is(
          ":checked"
        ),
      };
      model.set(changes);

      /*model.save(null, {
                  success: function(model, response){
                      self._401kQuestionnaireUpdated(response);
                  }
              });*/

      var params = {
        personId: model.get("id"),
        person: JSON.stringify(model.attributes),
        source: "401k",
      };

      Services.Person.update(params, self._401kQuestionnaireUpdated, self);
    });
  },
  validateAndSubmit: function (formEl, callback) {
    //validate
    var self = this;
    var valid = true;
    // required field validation
    $(".formElement:visible", this.$el).each(function () {
      if ($(this).data("is_required")) {
        $(this)
          .find("input, select")
          .each(function () {
            if ($.trim($(this).val()) === "") {
              valid = false;
              $(this).parents(".formElement").addClass("invalid");
            } else {
              $(this).parents(".formElement").removeClass("invalid");
            }
          });
      }
    });
    if (!valid) {
      return;
    }

    // birthday validation

    var year = $.trim(self.$el.find("#birthYear").val()),
      month = $.trim(self.$el.find("#birthMonth").val()) - 1,
      day = $.trim(self.$el.find("#birthDay").val()),
      birthday = moment([Number(year), Number(month), Number(day), 0, 0, 0]),
      todayObj = moment(),
      today = moment([
        todayObj.year(),
        todayObj.month(),
        todayObj.date(),
        0,
        0,
        0,
      ]);

    valid =
      year !== "" &&
      month !== "" &&
      day !== "" &&
      birthday.isValid() &&
      birthday.year() >= 1900 &&
      today.diff(birthday, "days") >= 0;

    if (valid) {
      self.$el
        .find("#birthYear")
        .parents(".formElement")
        .removeClass("invalid");
    } else {
      self.$el.find("#birthYear").parents(".formElement").addClass("invalid");
    }

    if (valid) {
      //				$(formEl, self.$el).find('.submitForm').text('submitting...');
      callback(this.model, self);
    }
  },
  _401kQuestionnaireUpdated: function (err, response) {
    if (typeof response.spData != "undefined") {
      //				$(formEl, self.$el).find('.submitForm').text('Submit');
      eventBus.trigger(
        customEvents.employerPlanQuestionnaireUpdated,
        response.spData
      );
      this.onClose();
    }
    //else display error
  },
  onClose: function () {
    this.$el.colorbox.close();
  },
});
export default _401kQuestionnaireView;
