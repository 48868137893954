import React from "react";
import PropTypes from "prop-types";
import AccountStatus from "components/transferFunds/AccountStatus/AccountStatus";
import { ACCOUNT_TYPE_ONUS, TRANSFER_TYPE_WITHDRAW } from "../utils/constants";
import { isOnUsBank, isOnUsInvestment } from "utils/account";

const LOWER_LIMIT = 9999;
const UPPER_LIMIT = 49999;
const taxesClassName = "account-confirmation--taxes";

function DefaultActions() {
  const url = window.isAdvisorApp ? NETWORTH_URL : DASHBOARD_URL;
  return (
    <div className="u-text-right">
      <a href={url} className="pc-btn">
        Dashboard
      </a>
    </div>
  );
}

function ScheduleActions() {
  const url = APPOINTMENT_URL;
  return (
    <div className="u-text-right">
      <a href={url} className="pc-btn">
        Schedule a Call
      </a>
    </div>
  );
}

function shouldDisplayEmpowerPersonalCashCopy(sourceAccount, targetAccount) {
  const isCashTransfer = isOnUsBank(sourceAccount) || isOnUsBank(targetAccount);
  const isInvestmentTransfer =
    isOnUsInvestment(sourceAccount) || isOnUsInvestment(targetAccount);
  return isCashTransfer && !isInvestmentTransfer;
}

function getConfirmationProps({
  docusignResult,
  transferType,
  sourceType,
  amount,
  isAcatTransfer,
  sourceAccount,
  targetAccount,
}) {
  let result = {
    className: "",
    title: (
      <p className="pc-beta pc-u-mt- pc-u-mb- u-text-bold">
        Your request has been received and will be processed shortly.
      </p>
    ),
    subTitle: (
      <>
        <p className="centi pc-u-mb0 js-transaction-reference">
          The estimated completion timeframe is 2-5 business days.
        </p>
        <p className="centi pc-u-mb0">
          If you have any questions regarding this transfer please contact your
          advisor or our support team.
        </p>
      </>
    ),
    footer: <DefaultActions />,
  };

  if (docusignResult === false) {
    result.title = (
      <p className="pc-beta pc-u-mt- pc-u-mb+ u-text-bold">
        Your request cannot be processed as you did not complete the required
        Docusign signatures.
      </p>
    );
    result.subTitle = undefined;
    return result;
  }

  if (
    transferType === TRANSFER_TYPE_WITHDRAW &&
    sourceType === ACCOUNT_TYPE_ONUS &&
    !isAcatTransfer
  ) {
    result.title = (
      <p className="pc-beta pc-u-mt- pc-u-mb- u-text-bold">
        Withdrawals from your accounts may have tax implications.
      </p>
    );

    if (amount <= LOWER_LIMIT) {
      result.subTitle = (
        <>
          <p className="centi pc-u-mb0 js-transaction-reference">
            Your request has been received and will be processed shortly. The
            estimated completion timeframe is 2-5 business days. If you have any
            questions regarding this transfer please contact our support team or
            your advisor.
          </p>
        </>
      );
    } else if (amount > LOWER_LIMIT && amount <= UPPER_LIMIT) {
      result.subTitle = (
        <>
          <p className="centi pc-u-mb0 js-transaction-reference">
            Distributions from your portfolio may require selling holdings which
            could trigger tax consequences. Your advisor will review your
            request before processing. The estimated completion timeframe is 2-5
            business days. If you wish to proactively reach out, feel free to
            schedule a call using the below link.
          </p>
        </>
      );
      result.className = taxesClassName;
      result.footer = <ScheduleActions />;
    } else if (amount > UPPER_LIMIT) {
      result.subTitle = (
        <>
          <p className="centi pc-u-mb0 js-transaction-reference">
            Distributions from your portfolio may require selling holdings which
            could trigger tax consequences. Your advisor will reach out to
            confirm the transfer details before processing. The estimated
            completion timeframe is 2-5 business days. If you wish to
            proactively reach out, feel free to schedule a call using the below
            link.
          </p>
        </>
      );
      result.className = taxesClassName;
      result.footer = <ScheduleActions />;
    }
  } else if (
    shouldDisplayEmpowerPersonalCashCopy(sourceAccount, targetAccount)
  ) {
    if (transferType === TRANSFER_TYPE_WITHDRAW) {
      result.title = (
        <p className="pc-beta pc-u-mt- pc-u-mb- u-text-bold">
          Your request has been received and will be processed shortly.
        </p>
      );
      result.subTitle = (
        <>
          <p className="centi pc-u-mb0">
            <strong>Debits</strong> (Withdrawals) requested from your Cash
            account will be received by the other institution within 1-2
            business days from the processing date.
          </p>
          <p className="centi pc-u-mb0 pc-u-mt--">
            Please contact your other Bank to confirm when those funds will
            become available once received.
          </p>
        </>
      );
    } else {
      result.title = (
        <p className="pc-beta pc-u-mt- pc-u-mb- u-text-bold">
          Your request has been received and will be processed shortly.
        </p>
      );
      result.subTitle = (
        <>
          <p className="centi pc-u-mb0 js-transaction-reference">
            <strong>Credits</strong> (Deposits) made to your Cash account will
            become available for withdrawal within 4 business days from the
            processing date.
          </p>
          <p className="centi pc-u-mb0 pc-u-mt--">
            Deposits made from newly linked accounts may be on hold for up to 5
            business days.
          </p>
        </>
      );
    }
  } else if (transferType === TRANSFER_TYPE_WITHDRAW && isAcatTransfer) {
    result.className = taxesClassName;
    result.title = (
      <p className="pc-beta pc-u-mt- pc-u-mb- u-text-bold">
        This transaction may have tax consequences.
      </p>
    );

    result.subTitle = (
      <p className="centi pc-u-mb0">
        Your advisor will contact you as soon as possible to confirm your
        request.
      </p>
    );
  }

  return result;
}
export default function TransferConfirmation(props) {
  const confirmationProps = getConfirmationProps(props);

  return (
    <div>
      <AccountStatus
        subTitleClassName="pc-u-mb"
        trackingComponentName="Transfer Funds"
        trackingPageName="Transfer Funds Confirmation"
        {...confirmationProps}
      />
    </div>
  );
}

TransferConfirmation.propTypes = {
  docusignResult: PropTypes.bool,
  sourceType: PropTypes.string,
  transferType: PropTypes.number,
  amount: PropTypes.number,
  isAcatTransfer: PropTypes.bool,
  sourceAccount: PropTypes.object,
  targetAccount: PropTypes.object,
};

TransferConfirmation.defaultProps = {
  docusignResult: undefined,
  sourceType: undefined,
  transferType: undefined,
  amount: 0,
  isAcatTransfer: false,
  sourceAccount: undefined,
  targetAccount: undefined,
};
