export function findField(name, fields) {
  if (fields == null) {
    return;
  }

  for (const f of fields) {
    const field = f.parts.find((p) => p.name === name);
    if (field) {
      return f;
    }
  }
}

function findFieldPart(name, fields) {
  if (fields == null) {
    return;
  }

  for (const f of fields) {
    const field = f.parts.find((p) => p.name === name);
    if (field) {
      return field;
    }
  }
}

/**
 * Adds the `key` attribute to the field matched by the supplied `fieldName`.
 *
 * NOTE: Modifies the supplied `formFields` by reference.
 *
 * @param {String} fieldName the name of the field to update
 * @param {String} key the key of the field to set
 * @param {Array} formFields the array of the fields to update
 * @returns {Array} the array of form fields.
 */
export function addKey(fieldName, key, formFields) {
  const field = findField(fieldName, formFields);

  if (!field) {
    return formFields;
  }

  field.key = key;

  return formFields;
}

/**
 * Adds a "not equal to" validator to the supplied `formFields`. The validator is checking the equality to the `value`.
 *
 * NOTE: Modifies the supplied `formFields` by reference.
 *
 * @param {String} fieldName the name of the field to update
 * @param {*} value the value which should be used in the validator as a "not equal to the value".
 * @param {String} message the error message for the validator
 * @param {Array} formFields the array of form fields to update
 * @returns {Array} the array of form fields.
 */
export function addNotEqualValidator(fieldName, value, message, formFields) {
  const fieldPart = findFieldPart(fieldName, formFields);

  if (!fieldPart) {
    return formFields;
  }

  const validator = {
    ...fieldPart.validator,
    ...{
      conform: (v) => {
        if (v == null) {
          return true;
        }
        if (value == null) {
          return true;
        }
        return v !== value;
      },
    },
  };

  validator.messages = {
    conform: message,
    ...fieldPart.validator?.messages,
  };

  fieldPart.validator = validator;

  return formFields;
}

/**
 * Removes the "not equal to" validator if defined on the supplied `formFields`.
 *
 * NOTE: Modifies the supplied `formFields` by reference.
 *
 * @param {String} fieldName the name of the field to update
 * @param {Array} formFields the array of form fields to update
 * @returns {Array} the array of form fields.
 */
export function removeNotEqualValidator(fieldName, formFields) {
  const fieldPart = findFieldPart(fieldName, formFields);

  if (!fieldPart?.validator) {
    return formFields;
  }

  const validator = { ...fieldPart.validator };
  delete validator.conform;
  delete validator.messages?.conform;

  fieldPart.validator = validator;

  return formFields;
}

/**
 * Added the validator to the supplied `formFields` to validate the masked account number for a valid number when it was edited and to skip the validation when it is not edited.
 *
 * NOTE: Modifies the supplied `formFields` by reference.
 *
 * @param {Array} formFields the array of form fields to update
 * @returns {Array} the array of form fields.
 */
export function addMaskedAccountNumberValidator(formFields) {
  const fieldPart = findFieldPart("accountNumber", formFields);
  const REGEX_NON_DIGITS = /^\d*(\.\d+)?$/;
  const regex = new RegExp(REGEX_NON_DIGITS);
  if (!fieldPart) {
    return formFields;
  }
  delete fieldPart.validator?.type;

  const validator = {
    ...fieldPart.validator,
    ...{
      conform: (v) => {
        if (v == null || v === "") {
          return true;
        }
        return v === fieldPart.value || regex.test(v);
      },
    },
  };
  validator.messages = {
    conform: "should be number",
    ...fieldPart.validator?.messages,
  };

  fieldPart.validator = validator;

  return formFields;
}
