/* eslint-disable sonarjs/cognitive-complexity */
import React from "react";
import Column from "libs/pcap/table/Column";
import Input from "components/common/form/Input";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import { CategorySelector } from "./CategorySelector";
import InputTagsContainer from "components/common/InputTags/Container";
import TransactionEditRow from "./TransactionEditRow";
import { formatCurrency, formatNegativeNumber } from "libs/pcap/utils/format";
import { clone, isEmpty } from "underscore";
import SplitYourTransactionModal from "components/TransactionsGridV3/SplitYourTransaction/Modal";
import getCategoryType from "components/TransactionsGridV3/getCategoryType";
import TransactionImageUploadModal from "components/TransactionImageUploadModal";
import EditTransactionImageModal from "components/common/EditTransactionImage/Modal";
import { noop } from "underscore";
import ApplyTransactionRuleModal from "./ApplyTransactionRule/ApplyTransactionRuleModal";
import getTransactionEditedMessage from "./getTransactionEditedMessage";
import DatePickerInput from "components/common/form/DatePickerInput";
import { DISPLAY_FORMAT } from "libs/pcap/utils/date2";
import { CURRENCY_FORMAT } from "components/common/form/formattingOptions";

const MAX_TRANSACTION_AMOUNT_DIGITS = 12;
class TransactionEditRowAccountDetailsCashflow extends TransactionEditRow {
  render() {
    const { columns, onClose, schema, onSaved, isPrivileged } = this.props;
    const {
      originalModel,
      model,
      categories,
      tags,
      loading,
      errors,
      showSplitTransaction,
      showTransactionImageUploader,
      showEditTransactionImageModal,
      showApplyTransactionRuleModal,
    } = this.state;
    // Make sure we keep `CategorySelector` options sorted as custom categories are added.
    const categoryColumn = columns.find((col) => col.header === "Category");
    const categoryComparatorAsc = categoryColumn.comparator(
      (cat) => cat.name,
      "asc"
    );
    const sortedCategories = clone(categories).sort(categoryComparatorAsc);
    const isUnCategorizedCategory =
      getCategoryType(model.categoryId, categories) === "UNCATEGORIZED";
    let splitTransactionTitle = "Split this transaction.";

    if (model.isDuplicate) {
      splitTransactionTitle = "Duplicate transaction cannot be split.";
    } else if (isUnCategorizedCategory) {
      splitTransactionTitle = "Uncategorized transaction cannot be split.";
    }
    const transactionEditedMessage = getTransactionEditedMessage(
      originalModel,
      categories
    );

    if (isEmpty(model.splits)) {
      return (
        <>
          <form
            className="table__row--edit pc-bg-dark pc-u-pt- qa-edit-transaction-form js-edit-transaction-form"
            onSubmit={isPrivileged ? noop : this.handlePreSubmit}
          >
            {this.props.errors && (
              <Message
                className="pc-u-mh- pc-u-mb--"
                messages={this.props.errors}
              />
            )}
            {errors && (
              <Message className="pc-u-mh- pc-u-mb--" messages={errors} />
            )}
            <LoadingOverlay active={loading} />
            <div className="pc-transactions-grid__edit-row-inner">
              <div
                className={`table__column pc-transactions-grid pc-transactions-grid-cell--date`}
              >
                {this.props.transactionCategoryRulesFlag ? (
                  <DatePickerInput
                    name="transactionDate"
                    className="pc-transaction-grid_transaction-date"
                    ref={this.storeInputRef}
                    validator={DatePickerInput.getValidator({
                      valueDateFormat: DISPLAY_FORMAT,
                      allowEmpty: true,
                      isAllowedDate: this.isValidStartDate,
                    })}
                    displayDateFormat={DISPLAY_FORMAT}
                    onChange={this.handleDateChange}
                    position="top"
                    value={model.transactionDate}
                    isAllowedDate={this.isValidStartDate}
                  />
                ) : (
                  <label>{model.transactionDate}</label>
                )}
              </div>
              <div className={`table__column ${columns[1].className}`}>
                <Input
                  ref={this.storeInputRef}
                  containerClassName={
                    model.originalDescription === model.description
                      ? undefined
                      : "pc-transactions-grid__edit-row-description-input qa-edit-row-description-input"
                  }
                  type="text"
                  name="description"
                  value={model.description}
                  validator={schema.properties.description}
                  errorBlockClassName="is-hidden"
                  onChange={this.handleInputChange}
                  sizeVariation="full"
                />
                {model.originalDescription !== model.description && (
                  <InfoTooltipIcon
                    className="icon__help-circled--white pc-u-ml-- qa-transaction-edit-original-description-tooltip"
                    title={`<div>Original description:</div>${model.originalDescription}<div>Double click to use original</div>`}
                    onDoubleClick={this.handleRevertToOriginalDescription}
                  />
                )}
              </div>
              <div
                className={`table__column ${columns[2].className}`}
                style={{ overflow: "visible" }}
              >
                <CategorySelector
                  options={sortedCategories}
                  name="categoryId"
                  onChange={this.handleInputChange}
                  onCategoryCreated={this.handleCategoryCreated}
                  value={model.categoryId}
                  validator={schema.properties.categoryId}
                />
              </div>
              {model.isManual ? (
                <>
                  <div className={`table__column ${columns[3].className}`} />
                  <div
                    className={
                      "table__column pc-transactions-grid-cell-edit-transaction-amount pc-transactions-grid-cell--amount-parent qa-edit-transactions-cell--amount tabular-numbers"
                    }
                  >
                    <Input
                      id="amount"
                      ref={this.storeInputRef}
                      type="text"
                      value={
                        model.isCredit
                          ? formatCurrency(model.amount)
                          : formatNegativeNumber(model.amount)
                      }
                      name="amount"
                      onChange={this.handleInputChange}
                      prefix="$"
                      formattingOptions={CURRENCY_FORMAT}
                      validator={this.props.schema.properties.amount}
                      ariaLabel="Transaction amount"
                      maxLength={MAX_TRANSACTION_AMOUNT_DIGITS}
                      className={"input--account-details-manual-amount"}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={`table__column ${columns[3].className}`} />
                  <Column
                    data={model}
                    {...columns[4]}
                    formatter={(transaction) => {
                      const value = transaction.isCredit
                        ? formatCurrency(transaction.amount)
                        : formatNegativeNumber(transaction.amount);
                      return <span>{value}</span>;
                    }}
                  />
                </>
              )}
            </div>
            {this.props.transactionCategoryRulesFlag &&
              model?.isModifiedByRule && (
                <div className="pc-transactions-grid__edit-row-inner">
                  <div className={`${columns[0].className}`} />
                  <div
                    className={`${columns[1].className} pc-transactions-grid__rule-applied-message`}
                  >
                    {transactionEditedMessage}
                  </div>
                </div>
              )}
            <div className="pc-transactions-grid__edit-row-inner">
              <div
                className={`table__column pc-u-p0 u-text-right ${columns[0].className}`}
              >
                Tags
              </div>
              <div className="pc-form-group--small pc-layout pc-layout--middle pc-u-mb0 pc-transactions-grid__edit-row-tags-container">
                <div className="pc-layout__item pc-u-1/2">
                  <InputTagsContainer
                    tags={tags}
                    onTagsModified={this.handleTagsChange}
                    value={model.selectedTagIds}
                  />
                </div>
              </div>
            </div>
            <div className="pc-layout pc-u-p-">
              {model.isManual ? (
                <div className="pc-layout__item pc-u-1/2" />
              ) : (
                <div className="pc-layout__item pc-u-1/2">
                  <label className="pc-label pc-label--inline">
                    <Input
                      ref={this.storeInputRef}
                      type="checkbox"
                      name="isDuplicate"
                      checked={model.isDuplicate}
                      onChange={this.handleInputChange}
                      containerClassName={"input--inline-block"}
                      disabled={isPrivileged}
                    />
                    Hide this duplicate
                  </label>
                  <InfoTooltipIcon
                    className="icon__help-circled--white pc-u-ml--"
                    title={
                      "Marking a transaction as a duplicate will stop counting that transaction in reporting but display it in the account it came from in case you need to view or unmark it."
                    }
                  />
                </div>
              )}
              <div className="pc-layout__item pc-u-1/2 pc-layout--right">
                {model.isManual && (
                  <button
                    type="button"
                    className="pc-btn pc-btn--danger pc-btn--small js-delete-transaction qa-delete-transaction"
                    onClick={this.handleDeleteTransaction}
                  >
                    Delete
                  </button>
                )}
                {!model.imageUrl && (
                  <button
                    type="button"
                    className={
                      "pc-btn pc-btn--small js-add-transaction-image qa-add-transaction-image" +
                      (isPrivileged ? " disabled" : "")
                    }
                    onClick={this.handleShowTransactionImageUploader}
                  >
                    Add Image
                  </button>
                )}
                {model.imageUrl && (
                  <button
                    type="button"
                    className={
                      "pc-btn pc-btn--small js-edit-transaction-image qa-edit-transaction-image" +
                      (isPrivileged ? " disabled" : "")
                    }
                    onClick={this.handleEditImageClick}
                  >
                    Edit Image
                  </button>
                )}
                {!model.isManual && !model.hideSplitTransaction && (
                  <button
                    type="button"
                    className="pc-btn pc-btn--small js-split-transaction qa-split-transaction "
                    onClick={this.handleSplitTransactionClick}
                    disabled={model.isDuplicate || isUnCategorizedCategory}
                    title={splitTransactionTitle}
                  >
                    Split Transaction
                  </button>
                )}
                <button
                  type="button"
                  className="pc-btn pc-btn--cancel pc-btn--small qa-close-edit-transaction"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  aria-disabled={isPrivileged}
                  type="submit"
                  className={`pc-btn pc-btn--small pc-btn--primary qa-save-transaction ${
                    isPrivileged ? "is-disabled" : ""
                  }`}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
          {showSplitTransaction && (
            <SplitYourTransactionModal
              onCancel={this.handleCancelSplitTransaction}
              onSaved={onSaved}
              isOpen={showSplitTransaction}
              model={model}
              categories={sortedCategories}
              onCategoryCreated={this.handleCategoryCreated}
              tags={tags}
              onFileUploaded={this.handleFileUploaded}
              onDeleteTransactionImage={this.handleDeleteTransactionImage}
              imageErrors={errors}
              imageLoading={loading}
              isPrivileged={isPrivileged}
            />
          )}
          {showTransactionImageUploader && (
            <TransactionImageUploadModal
              onDone={this.handleCloseTransactionImageUploader}
              userTransactionId={model.userTransactionId}
              title={model.imageUrl ? "Replace Image" : undefined}
              onFileUploaded={this.handleFileUploaded}
            />
          )}
          {showEditTransactionImageModal && (
            <EditTransactionImageModal
              onCancel={this.handleCloseTransactionImageUploader}
              imageUrl={model.imageUrl}
              onDelete={this.handleDeleteTransactionImage}
              onReplace={this.handleShowTransactionImageUploader}
              loading={loading}
              errors={errors}
            />
          )}
          {showApplyTransactionRuleModal &&
            this.props.transactionCategoryRulesFlag && (
              <ApplyTransactionRuleModal
                model={model}
                originalModel={originalModel}
                isOpen={showApplyTransactionRuleModal}
                categories={categories}
                handleSubmit={this.handleSubmit}
                handleCloseModal={this.handleCloseModal}
              />
            )}
        </>
      );
    }

    return (
      <SplitYourTransactionModal
        onCancel={onClose}
        onSaved={onSaved}
        isOpen={showSplitTransaction}
        model={model}
        categories={sortedCategories}
        onCategoryCreated={this.handleCategoryCreated}
        tags={tags}
        onFileUploaded={this.handleFileUploaded}
        imageErrors={errors}
        imageLoading={loading}
        onDeleteTransactionImage={this.handleDeleteTransactionImage}
      />
    );
  }
}

export default TransactionEditRowAccountDetailsCashflow;
