import React from "react";
import PostLoginActionsProvider from "../PostLoginActionsProvider";
import UserQuiz from "./UserQuiz";
import objectPath from "object-path";

const UserQuizPLA = (props) => {
  return (
    <PostLoginActionsProvider {...props}>
      {({ getButtons, jsonContent }) => (
        <UserQuiz
          {...props}
          jsonContent={jsonContent}
          componentName={objectPath.get(props, "userMessage.template")}
          getButtons={getButtons}
        />
      )}
    </PostLoginActionsProvider>
  );
};

export default UserQuizPLA;
