import React from "react";
import { formatCurrency, formatPercent } from "libs/pcap/utils/format";

const getClassName = (value) => {
  if (value === 0) {
    return "";
  }

  return value > 0 ? "positive" : "negative";
};

export const positiveNegativeColoredCurrency = (value) => {
  const positiveSign = value > 0 ? "+" : "";
  return (
    <span className={getClassName(value)}>
      {positiveSign + formatCurrency(value)}
    </span>
  );
};

export const positiveNegativeColoredPercentage = (value) => {
  const positiveSign = value > 0 ? "+" : "";
  return (
    <span className={getClassName(value)}>
      {positiveSign + formatPercent(value)}
    </span>
  );
};
