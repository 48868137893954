import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import AccountNumberForm from "./AccountNumberForm";

class AccountNumberFormComponent extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.focus();
  }

  handleSubmit(event) {
    const { model } = this.state;
    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.onSubmit(model);
    }
    event.preventDefault();
  }

  render() {
    const {
      onCancel,
      errors,
      warnings,
      logoPath,
      balance,
      accountName,
      firmName,
      helpText,
      className,
    } = this.props;
    const { model } = this.state;
    return (
      <AccountNumberForm
        logoPath={logoPath}
        model={model}
        accountName={accountName}
        balance={balance}
        firmName={firmName}
        helpText={helpText}
        refFunc={this.storeInputRef}
        onChange={this.handleInputChange}
        onSubmit={this.handleSubmit}
        onCancel={onCancel}
        errors={errors}
        warnings={warnings}
        className={className}
      />
    );
  }
}

AccountNumberFormComponent.propTypes = {
  model: PropTypes.object,
  logoPath: PropTypes.string,
  accountName: PropTypes.string,
  firmName: PropTypes.string,
  helpText: PropTypes.string,
  balance: PropTypes.number,
  errors: PropTypes.array,
  warnings: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AccountNumberFormComponent.defaultProps = {
  model: {},
  isIAVFailed: false,
  accountName: undefined,
  logoPath: undefined,
  helpText: undefined,
  balance: undefined,
  firmName: undefined,
  errors: undefined,
  warnings: undefined,
  className: undefined,
};

export default AccountNumberFormComponent;
