import _ from "underscore";
import moment from "moment";
import colorizeGrants from "views/modules/stockOptions/components/colorizeGrants";
import Formats from "formats";

export default function historyData(spData, weekly) {
  if (!(spData && spData.grants && spData.grants.length)) {
    //console.log('historyData: strange ', spData);
    return [];
  }

  colorizeGrants(spData.grants);

  var bars = _.map(spData.histories, function (history) {
    /**
     * firt we generate a set of data for each week
     * with an array of objects, one for each grant
     * that has the format
     * { grantId: int value: float, grant: {grant}, color: string }
     */

    var out = {
      month: history.date,
      date: moment(history.date, "YYYY-MM-DD"),
      values: [],
    };

    out.ym = out.date.format("YYYY-MM");
    out.unix = out.date.unix();

    if (history.hasOwnProperty("whatIfValue")) {
      out.whatIfValue = history.whatIfValue;
    }

    _.each(spData.grants, function (grant) {
      var grantId = parseInt(grant.grantId);
      var item = {
        value: parseFloat(history[grantId]) || 0,
        grantId: grantId,
        total: parseFloat(grant.totalValue),
        grant: grant,
        color: grant.color,
      };

      item.valueString = Formats.filters.dollar_no_cents(item.value);
      out.values.push(item);
    });

    out.values = _.sortBy(out.values, "total");
    return out;
  });

  /**
   * to ensure that  the last month has the final summation we add a last month entry that is the same
   * as the last entry, only for next month.
   *
   * Since the below entry harvests only the first entry for each month.
   *
   * @type {*}
   */

  if (bars.length) {
    var last_value = _.clone(_.last(bars));
    last_value.date = moment(last_value.month, "YYYY-MM-DD");
    last_value.date.add(1, "months");
    last_value.date.date(1);
    last_value.month = last_value.date.format("YYYY-MM-DD");
    last_value.ym = last_value.date.format("YYYY-MM");

    if (!weekly) {
      // getting the first value for each month only

      var bars_by_ym = _.groupBy(bars, "ym");
      bars = _.sortBy(
        _.compact(
          _.map(bars_by_ym, function (values, ym) {
            return _.sortBy(values, "unix").unshift();
          })
        ),
        "unix"
      );
    }

    return bars.concat([last_value]);
  }
  return [];
}
