import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import Message from "components/common/Message";
import FieldSet from "components/common/FieldSet";
import { trackView } from "components/common/ComponentAnalytics";
import AccountBox from "components/AccountBox/AccountBox";
import ReenterAccountNumberInput from "components/transferFunds/ReenterAccountNumberInput";
import memoizeOne from "memoize-one";
import { isJointAccount } from "utils/account";
import {
  addKey,
  addNotEqualValidator,
  removeNotEqualValidator,
  addMaskedAccountNumberValidator,
  findField,
} from "./formFields";
import {
  TRANSFER_TYPE_CONTRIBUTE,
  TRANSFER_TYPE_WITHDRAW,
} from "components/transferFunds/utils/constants";

const MSG_UNIQUE_PERSON =
  "This person is already selected as an account owner.";

const buildFormFields = memoizeOne(function (
  formFields,
  primaryOwnerId,
  secondaryOwnerId,
  ownershipType
) {
  // Change the key of the owner input to the value of the opposite owner input.
  // This is needed to prevent the validation message from appearing on the owner input
  // the user is not interacting with.
  addKey("primaryOwnerId", `prim-${secondaryOwnerId}`, formFields);
  addKey("secondaryOwnerId", `sec-${primaryOwnerId}`, formFields);

  if (isJointAccount(ownershipType)) {
    addNotEqualValidator(
      "primaryOwnerId",
      secondaryOwnerId,
      MSG_UNIQUE_PERSON,
      formFields
    );
    addNotEqualValidator(
      "secondaryOwnerId",
      primaryOwnerId,
      MSG_UNIQUE_PERSON,
      formFields
    );
  } else {
    removeNotEqualValidator("primaryOwnerId", formFields);
    removeNotEqualValidator("secondaryOwnerId", formFields);
  }
  if (findField("accountNumber", formFields)) {
    addMaskedAccountNumberValidator(formFields);
  }
  return formFields;
});

class AccountDetails extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.focus();
    trackView("Transfer Funds", {
      component: "Account Details",
    });
  }

  handleSubmit(event) {
    const { model } = this.state;
    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.onContinue(model);
    }
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "account_details.continue_button.click"
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
        event_properties: {
          selection: "account_details.continue_button.click",
        },
      });
    }
    event.preventDefault();
  }

  render() {
    const {
      onBack,
      errors,
      formFields,
      className,
      shallowModelPropertyPath,
      account,
      transferType,
      CustomFooter,
    } = this.props;
    const { model } = this.state;

    const fields = buildFormFields(
      formFields,
      model.primaryOwnerId,
      model.secondaryOwnerId,
      model.ownershipType
    );

    return (
      <form onSubmit={this.handleSubmit} className="transfer-account-details">
        <section className="qa-transfer-funds-account-details">
          <div className={className}>
            <Message className="pc-u-mb" severity="error" messages={errors} />
            {account && (
              <div>
                <div className="pc-layout--middle pc-layout--small pc-form-group">
                  <div className="pc-layout__item pc-u-2/7">
                    {transferType === TRANSFER_TYPE_WITHDRAW && (
                      // eslint-disable-next-line jsx-a11y/label-has-associated-control
                      <label className="pc-label js-account-details-label">
                        To
                      </label>
                    )}
                    {transferType === TRANSFER_TYPE_CONTRIBUTE && (
                      // eslint-disable-next-line jsx-a11y/label-has-associated-control
                      <label className="pc-label js-account-details-label">
                        From
                      </label>
                    )}
                  </div>
                  <div className="pc-layout__item pc-u-5/7 pc-u-pr pc-u-pl--">
                    <AccountBox
                      showLogo={true}
                      logoPath={account.logoPath}
                      firmName={account.firmName}
                      name={account.accountName}
                      balance={account.balance}
                    />
                  </div>
                </div>
                <hr className="transfer-funds__divider pc-u-mv" />
              </div>
            )}
            <span data-hj-masked>
              <FieldSet
                className="field-set--horizontal field-set--accountDetails"
                fieldsConfig={fields}
                model={model}
                refFunc={this.storeInputRef}
                onChange={this.handleInputChange}
                shallowModelPropertyPath={shallowModelPropertyPath}
                CustomAccountNumberComponent={ReenterAccountNumberInput}
                ariaLabelledById="AccountDetailsId"
              />
            </span>
            {CustomFooter && <CustomFooter />}
          </div>
          <div className="pc-modal__footer pc-modal__footer--transfer-funds">
            <button type="button" className="pc-btn" onClick={onBack}>
              Back
            </button>
            <button
              type="submit"
              className="pc-btn pc-btn--primary qa-transfer-funds-next"
            >
              Continue
            </button>
          </div>
        </section>
      </form>
    );
  }
}

AccountDetails.propTypes = {
  model: PropTypes.object,
  formFields: PropTypes.array,
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  errors: PropTypes.array,
  className: PropTypes.string,
  account: PropTypes.object,
  transferType: PropTypes.number.isRequired,
  CustomFooter: PropTypes.func,
};

AccountDetails.defaultProps = {
  model: {},
  isSecondaryOwnerEnrollment: false,
  formFields: [],
  className: undefined,
  // Overrides the default prop value on `AbstractForm`.
  // eslint-disable-next-line react/default-props-match-prop-types
  shallowModelPropertyPath: true,
  account: undefined,
  CustomFooter: undefined,
};

export default AccountDetails;
