var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div\n        class=\"notificationIcon expand sticky sidebar-account__status-icon sidebar-account__status-icon--info qa-sidebar-pcb-account-status-icon-info js-pcb-account-status-icon\"\n        data-toggle=\"tooltip\"\n        title=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "\"\n      ></div>\n      ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n      <div\n        class=\"notificationIcon expand sticky sidebar-account__status-icon sidebar-account__status-icon--warning qa-sidebar-pcb-account-status-icon-warning js-pcb-account-status-icon\"\n        data-toggle=\"tooltip\"\n        title=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "\"\n      ></div>\n      ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n      <div\n        class=\"notificationIcon expand sticky sidebar-account__status-icon sidebar-account__status-icon--error qa-sidebar-pcb-account-status-icon-error js-pcb-account-status-icon\"\n        data-toggle=\"tooltip\"\n        title=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "\"\n      ></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " - "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " - "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"originalName") : depth0), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " Balance: "
    + container.escapeExpression(__default(require("../../helpers/dollarAndCentsAmount.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"balance") : depth0),false,true,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":33,"column":115},"end":{"line":33,"column":159}}}));
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " - "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"action") : stack1),"===","PCB_FUND_NOW",{"name":"compare","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":56,"column":20}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"action") : stack1),"===","PCB_FINISH_SETUP",{"name":"compare","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":21},"end":{"line":64,"column":20}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"action") : stack1),"===","PCB_BLOCKED",{"name":"compare","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":21},"end":{"line":72,"column":20}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a\n          href=\"#/transfer-funds?transferType=1&to="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "\"\n          data-attribution-source=\"fund-now-sidebar\"\n          class=\"pc-btn pc-btn--primary pc-btn--tiny pc-btn--nowrap js-action-on-us-bank-fund\"\n        >\n          Fund Now\n        </a>\n        ";
},"20":function(container,depth0,helpers,partials,data) {
    return "\n        <a\n          href=\"#/open-bank-account\"\n          data-attribution-source=\"finish-setup-sidebar\"\n          class=\"pc-btn pc-btn--primary pc-btn--tiny pc-btn--nowrap js-action-on-us-bank-finish-setup\"\n        >\n          Finish Setup\n        </a>\n        ";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <button\n          type=\"button\"\n          value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"accountId") : depth0), depth0))
    + "\"\n          class=\"pc-btn pc-btn--primary pc-btn--tiny pc-btn--nowrap js-action-remove-on-us-bank\"\n        >\n          Remove\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class=\"sidebar-account__header qa-sidebar-account-header js-on-us-bank-setup qa-cash-account\"\n>\n  <div\n    class=\"sidebar-account__message pc-u-ml pc-u-mb-- u-text-danger is-hidden js-on-us-bank-setup-error\"\n  ></div>\n  <div class=\"sidebar-account__row\">\n    <div class=\"sidebar-account__first-col\">\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","INFO",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":15,"column":18}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","WARNING",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":19},"end":{"line":21,"column":18}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","ERROR",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":19},"end":{"line":27,"column":18}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"sidebar-account__second-col\">\n      <div\n        class=\"sidebar-account__title js-sidebar-account-title qa-sidebar-account-title\" role=\"button\"\n        aria-label='"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"firmName") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":33,"column":32},"end":{"line":33,"column":89}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"balance") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":90},"end":{"line":33,"column":166}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":167},"end":{"line":33,"column":237}}})) != null ? stack1 : "")
    + "'\n      >\n        "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"firmName") : depth0), depth0))
    + "\n    </div>\n      <div\n        class=\"sidebar-account__detail-name qa-sidebar-account-detail-name js-pcb-account-status\"\n      >\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSsoUser") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n\n    <div class=\"sidebar-account__third-col\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSsoUser") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":6},"end":{"line":73,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>\n";
},"useData":true});