import React from "react";
import Input from "components/common/form/Input";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import BaseHoldingsEditRow from "../BaseHoldingsEditRow";
import { formatCurrency } from "libs/pcap/utils/format";
import { POSITIVE_CURRENCY_FORMAT } from "components/common/form/formattingOptions";
import RemoveWithConfirmationButton from "../../common/RemoveWithConfirmationButton/RemoveWithConfirmationButton";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

class ManualHoldingsEditRow extends BaseHoldingsEditRow {
  render() {
    const { columns, onCancel, schema } = this.props;
    const { model, loading } = this.state;
    const isUserManualHolding =
      model.source === "USER" && model.holdingType !== "Cash";

    return (
      <form
        className="table__row--edit pc-bg-dark qa-edit-holding-form"
        onSubmit={this.handleSubmit}
      >
        <LoadingOverlay active={loading} />
        <div className="holdings-table__edit-row-inner">
          <div
            className={`${columns[0].className} pc-holdings-grid-account-details-cell--holding-name table__column table__column--holdings-edit-cell`}
          >
            <div className={"pc-u-p--"}>{model.ticker}</div>
          </div>
          <div
            className={`table__column table__column--holdings-edit-cell ${columns[1].className}`}
          >
            <Input
              ref={this.storeInputRef}
              type="text"
              name="quantity"
              placeholder={"Shares"}
              value={model.quantity}
              validator={schema.properties.quantity}
              errorBlockClassName="is-hidden"
              onChange={this.handleInputChange}
              sizeVariation="full"
              disabled={loading}
              formattingOptions={POSITIVE_CURRENCY_FORMAT}
            />
          </div>
          <div
            className={`table__column table__column--holdings-edit-cell ${columns[2].className}`}
          >
            {isUserManualHolding ? (
              <Input
                ref={this.storeInputRef}
                type="text"
                name="price"
                className="js-price__editable"
                placeholder={"Price"}
                value={model.price}
                validator={schema.properties.price}
                errorBlockClassName="is-hidden"
                onChange={this.handleInputChange}
                sizeVariation="full"
                disabled={loading}
                prefix={"$"}
                formattingOptions={POSITIVE_CURRENCY_FORMAT}
              />
            ) : (
              <div className="pc-u-pt-- js-price__readonly">
                {formatCurrency(model.price)}
              </div>
            )}
          </div>
          <div
            className={`table__column table__column--holdings-edit-cell ${columns[3].className}`}
          >
            <div className="pc-u-pt--">
              {formatCurrency(
                model.price && model.quantity ? model.price * model.quantity : 0
              )}
            </div>
          </div>
        </div>
        <div className="holdings-table__edit-row-inner">
          <div
            className={`table__column table__column--holdings-edit-cell pc-u-pt0 ${columns[0].className} pc-holdings-grid-account-details-cell--holding-name`}
          >
            <label className="pc-label">Description</label>
            <Input
              ref={this.storeInputRef}
              type="text"
              name="description"
              value={model.description}
              validator={schema.properties.description}
              errorBlockClassName="is-hidden"
              onChange={this.handleInputChange}
              sizeVariation="full"
              disabled={loading}
            />
            {this.state.errors && <Message messages={this.state.errors} />}
          </div>
          <div
            className={`table__column table__column--holdings-edit-cell pc-u-pt0 ${columns[1].className} pc-holdings-grid-cell--holding-shares--editing`}
          >
            <label className="pc-label u-text-left">Cost Basis</label>
            <Input
              ref={this.storeInputRef}
              type="text"
              name="costBasis"
              value={model.costBasis}
              placeholder={"Optional"}
              validator={schema.properties.costBasis}
              errorBlockClassName="is-hidden"
              onChange={this.handleInputChange}
              sizeVariation="full"
              disabled={loading}
              prefix={"$"}
              formattingOptions={POSITIVE_CURRENCY_FORMAT}
            />
          </div>
          <div className="table__column table__column--holdings-edit-actions">
            {(model.cusip || model.ticker) && (
              <RemoveWithConfirmationButton
                onConfirm={this.handleOnRemove}
                removeButtonClassName="pc-btn--small pc-btn--danger"
                containerClassName="pc-holdings-grid__remove-confirmation-container"
                removePromptLabel="Are you sure you want to remove this holding?"
                removePromptClassName="pc-bg-dark"
                isPrivileged={isEmpowerPrivilegedMode()}
              />
            )}
            <button
              type="button"
              className="pc-btn pc-btn--small qa-close-edit-holding"
              onClick={onCancel}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="pc-btn pc-btn--small pc-btn--primary qa-save-holding"
              disabled={loading || isEmpowerPrivilegedMode()}
            >
              Done
            </button>
          </div>
        </div>
      </form>
    );
  }
}

ManualHoldingsEditRow.defaultProps = BaseHoldingsEditRow.defaultProps;
ManualHoldingsEditRow.propTypes = BaseHoldingsEditRow.propTypes;

export default ManualHoldingsEditRow;
