import React from "react";
import PropTypes from "prop-types";

const DelegateAccessConsent = ({ applicantName }) => (
  <div className="pc-u-ml u-preserve-case">
    I{applicantName ? `, ${applicantName},` : ""} understand that additional
    user will have the ability to view and modify all information, including
    linked accounts and profile information, within my{" "}
    {EMPOWER_PERSONAL_DASHBOARD}
    .
    <br />
    <a
      href={DASHBOARD_ACCESS_RIGHTS_LINK_REBRANDED}
      className="milli u-preserve-case"
      target="_blank"
      rel="noopener noreferrer"
    >
      {"Full disclosure"}
    </a>
  </div>
);

DelegateAccessConsent.propTypes = {
  applicantName: PropTypes.string,
};

DelegateAccessConsent.defaultProps = {
  applicantName: "",
};

export default DelegateAccessConsent;
