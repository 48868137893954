/* eslint-disable camelcase, no-underscore-dangle */

import Backbone from "backbone";
import Services from "services";
import { defer, isArray } from "underscore";
import getUrlParameterByName from "libs/pcap/utils/getUrlParameterByName";
import parseResponseErrors from "libs/pcap/utils/response";
import analytics from "analytics";
import getAbFrameworkPromise from "libs/pcap/utils/getAbFrameworkPromise";
import {
  MESSAGE_SCROLL_TO_TOP,
  IFRAME_PAGE_IS_READY,
  getEmailListFromJson,
  processUnknownReferrals,
} from "./ReferralCenterUtils";
import {
  getIsEnabledPromise,
  getFunnelAttributesPromise,
} from "components/shareWithFriends/ShareWithFriendsUtils";
import { EXCLUDE_USER_FROM_INTERJECTIONS } from "views/components/postLoginActions/constants";
import { trackEvent } from "components/common/ComponentAnalytics";
import utils from "libs/pcap/utils/utils";

const CAMPAIGN_SOURCE_URL_PARAM_NAME = "source";
const REQUIRED_DIV_ID_FOR_TALKABLE = "talkable-offer";
const TRIGGER_RENDER_DELAY = 500;
const TOP_OF_PAGE = [0, 0];
const REFERRAL_CENTER_HASH = "#/referral-center";
const COMPONENT_NAME = "Referral Center";
const MESSAGE_REFERRAL_CENTER_NOT_AVAILABLE =
  "Referral Center is not available";

export default Backbone.View.extend({
  initialize(options = {}) {
    this.$el = options.$el;
    this.render();

    // Talkable iframe will post a ready message when everything is done loading
    this.handleIframeMessage = this.handleIframeMessage.bind(this);
    window.addEventListener("message", this.handleIframeMessage);

    // Defer is required to load Talkable iframe correctly
    defer(() => {
      Promise.all([
        getIsEnabledPromise(),
        getFunnelAttributesPromise(),
        getAbFrameworkPromise({
          path: "referralCenter",
        }),
      ]).then(([isEnabled, funnelAttrs, referralCenter]) => {
        if (
          !isEnabled ||
          funnelAttrs.isSuspicious ||
          !funnelAttrs.referralCampaign
        ) {
          this.render(MESSAGE_REFERRAL_CENTER_NOT_AVAILABLE);
          this.trigger("doneLoading");
          trackEvent(COMPONENT_NAME, "Attempt to View Referral Center", {
            isUserReferralEnabled: isEnabled,
            isSuspicious: funnelAttrs.isSuspicious,
            referralCampaign: funnelAttrs.referralCampaign,
          });
          return;
        }

        const talkableOptions = Object.assign(
          {
            talkableCampaignTags: funnelAttrs.referralCampaign,
            talkableReferralShortUrl: utils.ensureUrlHasHttps(
              funnelAttrs.talkableReferralShortUrl ||
                funnelAttrs.talkableReferralUrl
            ),
            promoTermsUrl: referralCenter.promoTermsUrl || "",
            customize:
              referralCenter.customize && !EXCLUDE_USER_FROM_INTERJECTIONS,
          },
          options || {}
        );
        this.setupTalkableIframe(talkableOptions);
      });
    });
  },

  handleIframeMessage(event) {
    const data = event && event.data;
    if (data === IFRAME_PAGE_IS_READY) {
      window.setTimeout(() => {
        this.trigger("doneLoading");
      }, TRIGGER_RENDER_DELAY);
    } else if (data === MESSAGE_SCROLL_TO_TOP) {
      window.scrollTo(...TOP_OF_PAGE);
    } else {
      const emailList = getEmailListFromJson(data);
      if (isArray(emailList)) {
        this.getReferralsNotKnownToTalkable(emailList);
      }
    }
  },

  getReferralsNotKnownToTalkable(emailList) {
    Services.Profile.getReferredUsers(
      { emails: JSON.stringify(emailList) },
      (err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          analytics.sendEngineeringEvent(
            "Error",
            "`ReferralCenterView.getReferralsNotKnownToTalkable()` " +
              JSON.stringify(errors)
          );
          return;
        }
        const data = response && response.spData;
        this.sendUnknownReferralsToTalkable(data);
      }
    );
  },

  sendUnknownReferralsToTalkable(data) {
    if (!this.talkableIframe) {
      this.talkableIframe = $(`#${REQUIRED_DIV_ID_FOR_TALKABLE} iframe`).get(0);
    }
    if (this.talkableIframe?.contentWindow?.postMessage) {
      this.talkableIframe.contentWindow.postMessage(
        JSON.stringify({
          isEmailList: true,
          emailList: processUnknownReferrals(data),
        }),
        "*"
      );
    }
  },

  setupTalkableIframe(options) {
    delete window._talkableq;

    window._talkableq = window._talkableq || [];
    window._talkableq.push([
      "init",
      { site_id: options && options.talkableSiteId },
    ]);

    window._talkableq.push([
      "authenticate_customer",
      {
        email: options.email,
        first_name: options.firstName,
        last_name: options.lastName,
        person_custom_properties: {
          user_guid: options.userGuid,
          refer_from: REFERRAL_CENTER_HASH,
          source: getUrlParameterByName(CAMPAIGN_SOURCE_URL_PARAM_NAME),
          base_url: options.baseUrl,
          bundle_version: options.bundleVersion,
          // Pass below as String because Talkable does not properly handle Boolean values
          customize: String(options.customize),
          promo_terms_url: options.promoTermsUrl,
          talkable_referral_short_url: options.talkableReferralShortUrl,
        },
      },
    ]);

    window._talkableq.push([
      "register_affiliate",
      {
        campaign_tags: options && options.talkableCampaignTags,
      },
    ]);
  },

  remove() {
    window.removeEventListener("message", this.handleIframeMessage);
    this.$el.remove();
    Backbone.View.prototype.remove.apply(this, arguments);
  },

  render(message = "") {
    this.$el.html(
      `<div id="${REQUIRED_DIV_ID_FOR_TALKABLE}" class="qa-talkable-offer">${message}</div>`
    );
    return this;
  },
});
