var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-toggle='account-block-tooltip' title='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "'";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='notificationIcon expand sticky sidebar-account__status-icon sidebar-account__status-icon--warning qa-sidebar-account-status-icon-warning' data-toggle='tooltip' title='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "'></div>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='notificationIcon expand sticky sidebar-account__status-icon sidebar-account__status-icon--info qa-sidebar-account-status-icon-info' data-toggle='tooltip' title='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0))
    + "'></div>";
},"7":function(container,depth0,helpers,partials,data) {
    return "          <div class='notificationIcon expand sticky'>\n            <div class=\"loader--dark loader__container--small loader__container u-full-center--abs\">\n              <div class=\"loader__circle loader__circle--small loader__full-circle loader__full-circle--small\"></div>\n              <div class=\"loader__circle loader__circle--small loader__spinning-circle\"></div>\n            </div>\n          </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='sidebar-account__detail-name' title='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"originalName") : depth0), depth0))
    + "' data-hj-masked>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='sidebar-account__status-message' title='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0))
    + "'>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0))
    + "</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "          <div class='notificationIcon expand sticky NONE sidebar-account__refresh'>\n            <svg class=\"\" viewBox=\"0 0 10 10\">\n              <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon-refresh\"></use>\n            </svg>\n          </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(__default(require("../../helpers/timestamp.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0),{"name":"timestamp","hash":{},"data":data,"loc":{"start":{"line":54,"column":10},"end":{"line":54,"column":37}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"sidebar-account__progress-bar progressBar\">\n    <span class=\"progressMessage micro\">Establishing secure connection...</span>\n  </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='instructions'>"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"instructions") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sidebar-account__header qa-sidebar-account-header\" "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","ERROR",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":63},"end":{"line":1,"column":196}}})) != null ? stack1 : "")
    + ">\n  <div class=\"sidebar-account__row\">\n    <div class=\"sidebar-account__first-col\">\n        "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","WARNING",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":286}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","INFO",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":277}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","AGGREGATING",{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":13,"column":20}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"sidebar-account__second-col\">\n      <div class=\"sidebar-account__title js-sidebar-account-title\">\n        "
    + alias2(__default(require("../../helpers/detailsLink2.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"firmName") : depth0),{"name":"detailsLink2","hash":{},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":33}}}))
    + "\n      </div>\n    </div>\n\n    <div class=\"sidebar-account__third-col\">\n      <div class=\"sidebar-account__value\">\n        "
    + alias2(__default(require("../../helpers/dollarAndCentsAmount.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"balance") : depth0),true,false,(depth0 != null ? lookupProperty(depth0,"isLiability") : depth0),false,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":69}}}))
    + "\n      </div>\n    </div>\n  </div>\n\n  <div class=\"sidebar-account__row\">\n    <div class=\"sidebar-account__first-col\">\n      <div class=\"edit u-full-center--abs\">\n        <svg class=\"sidebar-account__edit-icon\" viewBox=\"0 0 9 9\" role=\"button\" title=\"Edit account\" alt=\"Edit account\">\n          <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon-pencil\"></use>\n        </svg>\n      </div>\n    </div>\n    <div class=\"sidebar-account__second-col\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":38,"column":6},"end":{"line":42,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"sidebar-account__third-col\">\n      <div class=\"sidebar-account__time\">\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","NONE",{"name":"compare","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":8},"end":{"line":52,"column":20}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":8},"end":{"line":55,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</div>\n\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias3((depth0 != null ? lookupProperty(depth0,"lastRefreshed") : depth0), depth0),{"name":"lastRefreshed","hash":{},"fn":container.noop,"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":61,"column":0},"end":{"line":65,"column":18}}})) != null ? stack1 : "")
    + "\n\n<div class=\"js-account-extras sidebar-account__extras is-hidden\">\n    <div class='sidebar-account__message'>\n      "
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"nextActionMessage") : stack1), depth0)) != null ? stack1 : "")
    + "\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"instructions") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":4},"end":{"line":74,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"sidebar-account__actions\">\n"
    + ((stack1 = container.invokePartial(require("./removeAccount2.html"),depth0,{"name":"templates/modules/sidebar/removeAccount2","data":data,"indent":"    \t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    	<button type=\"button\" class='js-account-cancel pc-btn pc-btn--tiny'>Cancel</button>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});