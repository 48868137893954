import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import AbstractForm from "components/common/form/AbstractForm";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import { CategorySelector } from "./CategorySelector";
import { isEqual } from "underscore";
import InputTagsContainer from "components/common/InputTags/Container";
import mixpanel from "../../libs/pcap/utils/mixpanel";
import { isEmpowerPrivilegedMode } from "../../views/modules/sidebar/utils/accountUtils";
import { noop } from "underscore";

const isPrivileged = isEmpowerPrivilegedMode();

class TransactionMultiEditForm extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        description: "",
        categoryId: "",
        isDuplicate: false,
        tags: [],
      },
      loading: false,
      categories: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCategoryCreated = this.handleCategoryCreated.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.categories, prevState.prevPropsCategories)) {
      return {
        categories: nextProps.categories,
        prevPropsCategories: nextProps.categories,
      };
    }
    return null;
  }

  handleSubmit(ev) {
    ev.preventDefault();
    if (this.validate().valid) {
      // Dispatch event bus
      window.dashboardUtils?.eventBus.dispatch(
        "BudgetingWidget.edit_multiple_transactions_event"
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
          "select_button",
        event_properties: {
          selection: `BudgetingWidget.edit_multiple_transactions_event`,
        },
      });
      this.trackUpdateInMixpanel(this.state.model);
      this.setState({ loading: true });
      this.props.onSubmit(this.state.model).catch((errors) => {
        this.setState({ errors, loading: false });
      });
    }
  }

  trackUpdateInMixpanel(updates) {
    if (updates.description) {
      mixpanel.trackEvent("Edit Transaction Multi Mode - Description Updated", {
        component: "TransactionMultiEditForm",
      });
    }

    if (updates.categoryId) {
      mixpanel.trackEvent("Edit Transaction Multi Mode - Category Updated", {
        component: "TransactionMultiEditForm",
      });
    }

    if (updates.tags && updates.tags.length) {
      mixpanel.trackEvent("Edit Transaction Multi Mode - Tags Updated", {
        component: "TransactionMultiEditForm",
      });
    }
  }

  handleTagsChange(tags) {
    let { model } = this.state;
    model.tags = tags;
    this.setState(model);
  }

  handleCategoryCreated(newCategory) {
    let { categories, model } = this.state;
    model.categoryId = newCategory.transactionCategoryId;
    // Manually update categories in the state so the UI refreshes
    // right away instead of waiting for the watcher to fire.
    categories.push(newCategory);
    this.setState({ model, categories });
  }

  render() {
    const { model, categories } = this.state;
    const { className, tags, isPrivileged } = this.props;
    return (
      <form
        className={`table__row--edit pc-bg-dark pc-u-p- qa-multi-edit-transaction-form ${className}`}
        onSubmit={isPrivileged ? noop : this.handleSubmit}
      >
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/2">
            <h4 className="u-text-bold pc-u-mb-- pc-label--inline">
              Edit Multiple Transactions
            </h4>
            <div className="qa-transaction-multi-edit-number-of-transactions-selected centi pc-label--inline pc-u-ml-">
              {this.props.numberOfTransactionsSelected
                ? this.props.numberOfTransactionsSelected
                : "None"}{" "}
              selected
            </div>
          </div>
          <div className="pc-layout__item pc-u-1/2 u-text-right">
            <label
              htmlFor="hideDuplicates"
              className="pc-label pc-label--inline centi"
            >
              <Input
                ref={this.storeInputRef}
                type="checkbox"
                name="isDuplicate"
                checked={this.state.model.isDuplicate}
                onChange={this.handleInputChange}
                containerClassName={"input--inline-block"}
                disabled={isPrivileged}
                id="hideDuplicates"
                ariaLabel="Hide these duplicates"
              />
              Hide these duplicates
            </label>
            <InfoTooltipIcon
              className="icon__help-circled--white pc-u-ml--"
              tooltipProps={{ placement: "left" }}
              title={
                "Marking a transaction as a duplicate will stop counting that transaction in reporting but display it in the account it came from in case you need to view or unmark it."
              }
            />
          </div>
        </div>

        <div className="centi">
          {this.state.errors && (
            <Message
              className="pc-u-mh- pc-u-mb--"
              messages={this.state.errors}
            />
          )}
          <LoadingOverlay active={this.state.loading} />
          <div className="pc-layout pc-layout--small">
            <div className="pc-layout__item pc-u-1/3">
              <label htmlFor="transactionMultiEditTags" className="pc-label">
                Tags
              </label>
            </div>
            <div className="pc-layout__item pc-u-2/3">
              <div className="pc-layout pc-layout--small">
                <div className="pc-layout__item pc-u-1/3">
                  <label
                    htmlFor="transactionMultiEditDescription"
                    className="pc-label"
                  >
                    Description
                  </label>
                </div>
                <div className="pc-layout__item pc-u-1/3">
                  <label
                    htmlFor="multiEditCategorySelector"
                    className="pc-label"
                  >
                    Category
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="pc-layout pc-layout--small">
            <div className="pc-layout__item pc-u-1/3">
              <InputTagsContainer
                tags={tags}
                onTagsModified={this.handleTagsChange}
                selectInputId="transactionMultiEditTags"
              />
            </div>
            <div className="pc-layout__item pc-u-2/3">
              <div className="pc-layout pc-layout--small">
                <div className="pc-layout__item pc-u-1/3">
                  <Input
                    id="transactionMultiEditDescription"
                    ref={this.storeInputRef}
                    type="text"
                    name="description"
                    value={model.description}
                    errorBlockClassName="is-hidden"
                    onChange={this.handleInputChange}
                    sizeVariation="full"
                  />
                </div>
                <div className="pc-layout__item pc-u-1/3">
                  <CategorySelector
                    id="multiEditCategorySelector"
                    options={categories}
                    className={"input--full pc-u-p0"}
                    name="categoryId"
                    onChange={this.handleInputChange}
                    onCategoryCreated={this.handleCategoryCreated}
                    value={model.categoryId}
                  />
                </div>
                <div className="pc-layout__item pc-u-1/3 u-text-right">
                  <button
                    type="button"
                    className="pc-btn pc-btn--small qa-close-multi-edit-transaction"
                    onClick={this.props.onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="pc-btn pc-btn--small pc-btn--primary qa-multi-edit-save-transaction"
                    disabled={
                      this.props.numberOfTransactionsSelected === 0 ||
                      isPrivileged
                    }
                    aria-disabled={isPrivileged}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

TransactionMultiEditForm.propTypes = {
  categories: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  numberOfTransactionsSelected: PropTypes.number,
  className: PropTypes.string,
  isPrivileged: PropTypes.bool,
};

TransactionMultiEditForm.defaultProps = {
  className: "",
  isPrivileged: isPrivileged,
};

export default TransactionMultiEditForm;
