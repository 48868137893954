import PropTypes from "prop-types";
import React from "react";
import { StockOptionsGrid, defaultColumns } from "./StockOptionsGrid";
import Services from "services";
import analytics from "analytics";
import StockOptionsAddRow from "./StockOptionsAddRow";
import StockOptionsEditRow from "./StockOptionsEditRow";
import deepCopy from "deep-copy";
import { noop } from "underscore";

/**
 * Extracts error message from spHeader.errors
 * { sagePageErrorType: "INTERNAL_ERROR", details: { detail: "Vested shares input:501 ...etc" } }
 * @param {Array} errors old arguments
 * @returns {String} detailed error message
 */
const getStockOptionsApiError = (errors) => {
  const errorObject = errors[0];

  if (errorObject) {
    return errorObject?.details?.detail;
  }
};

export default class StockOptionsGridContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnCreateStockOptionsHolding =
      this.handleOnCreateStockOptionsHolding.bind(this);
    this.handleOnUpdateStockOptionsHolding =
      this.handleOnUpdateStockOptionsHolding.bind(this);
    this.handleOnRemoveStockOptionsHolding =
      this.handleOnRemoveStockOptionsHolding.bind(this);
    this.state = {};
    // Attempt to fetch the security information if the company is publicly traded
    if (props.accountSymbol !== undefined) {
      this.state = {
        loading: true,
      };
      this.fetchQuote(props.accountSymbol);
    }
  }

  handleOnCreateStockOptionsHolding(grant) {
    const { setUpdatingGraph } = this.props;
    setUpdatingGraph(true);
    return new Promise((resolve, reject) => {
      let payload = deepCopy(grant);
      payload.userAccountId = this.props.userAccountId;
      // No idea what this does but is needed for the server API to save the stock option grant
      payload.__version = 1; //eslint-disable-line no-underscore-dangle
      Services.Grants.create(payload, (err, response) => {
        if (response.spHeader.success) {
          resolve(response.spData);
        }
        analytics.sendEngineeringEvent(
          "Error",
          "Stock options grid create grant " +
            JSON.stringify(response) +
            JSON.stringify(err)
        );
        setUpdatingGraph(false);
        if (response.spHeader.errors.length > 0) {
          reject([getStockOptionsApiError(response.spHeader.errors)]);
        }
      });
    });
  }

  handleOnUpdateStockOptionsHolding(grant) {
    const { setUpdatingGraph } = this.props;
    setUpdatingGraph(true);
    return new Promise((resolve, reject) => {
      const payload = {
        vestedShares: grant.vestedShares,
        grantName: grant.grantName,
        grantId: grant.grantId,
      };
      Services.GrantHistories.update(payload, function (err, response) {
        if (response.spHeader.success) {
          resolve(response.spData);
        }
        analytics.sendEngineeringEvent(
          "Error",
          "Stock options grid update grant " +
            JSON.stringify(response) +
            JSON.stringify(err)
        );
        setUpdatingGraph(false);
        if (response.spHeader.errors.length > 0) {
          reject([getStockOptionsApiError(response.spHeader.errors)]);
        }
      });
    });
  }

  handleOnRemoveStockOptionsHolding(grant) {
    const { setUpdatingGraph } = this.props;
    setUpdatingGraph(true);
    return new Promise((resolve, reject) => {
      Services.Grants.remove({ grantId: grant.grantId }, (err, response) => {
        if (response.spHeader.success) {
          resolve(response.spData);
        }
        analytics.sendEngineeringEvent(
          "Error",
          "Stock options grid remove grant " +
            JSON.stringify(response) +
            JSON.stringify(err)
        );
        setUpdatingGraph(false);
        if (response.spHeader.errors.length > 0) {
          reject([getStockOptionsApiError(response.spHeader.errors)]);
        }
      });
    });
  }

  fetchQuote(ticker) {
    Services.Securities.get({ resolveSearchTerm: ticker }, (err, response) => {
      if (response.spData && response.spData.symbol) {
        this.setState({ security: response.spData, loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <StockOptionsGrid
        {...this.props}
        security={this.state.security}
        onUpdateStockOptionsHolding={this.handleOnUpdateStockOptionsHolding}
        onCreateStockOptionsHolding={this.handleOnCreateStockOptionsHolding}
        onRemoveStockOptionsHolding={this.handleOnRemoveStockOptionsHolding}
      />
    );
  }
}

StockOptionsGridContainer.propTypes = {
  stockOptionHoldings: PropTypes.array.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  headerRowClassName: PropTypes.string,
  onRowClick: PropTypes.func,
  columns: PropTypes.array,
  isEditable: PropTypes.bool,
  showAddGrant: PropTypes.bool,
  paginator: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  zeroState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  AddStockOptionsHoldingRow: PropTypes.oneOfType([PropTypes.func]),
  EditStockOptionsHoldingRow: PropTypes.oneOfType([PropTypes.func]),
  userAccountId: PropTypes.string,
  accountName: PropTypes.string,
  accountSymbol: PropTypes.string,
  setUpdatingGraph: PropTypes.func,
};

StockOptionsGridContainer.defaultProps = {
  columns: defaultColumns,
  showAddGrant: true,
  isEditable: false,
  zeroState: "No stock options holdings match your search criteria",
  className: "",
  accountName: "Manual Stock",
  containerClassName: undefined,
  headerRowClassName: undefined,
  onRowClick: undefined,
  AddStockOptionsHoldingRow: StockOptionsAddRow,
  EditStockOptionsHoldingRow: StockOptionsEditRow,
  userAccountId: undefined,
  accountSymbol: undefined,
  paginator: {
    stepSize: 100,
    start: 0,
    className: "stock-options-grid-paginator-container",
  },
  setUpdatingGraph: noop,
};
