import PropTypes from "prop-types";
import React from "react";
import Loading from "components/common/loading/Loading";
import ProgressMeter from "components/common/ProgressMeter/ProgressMeter";
import TipCarousel from "components/common/TipCarousel/TipCarousel";
import { noop } from "underscore";

const CompletenessMeter = (props) => {
  const {
    loading,
    tipData,
    stepData,
    tipIndex,
    stepIndex,
    onUnavailableHover,
    areMultipleTips,
    onNextTipClick,
    onTipActionClick,
    isCompleted,
    showFinalAnimation,
    showAddAccounts,
  } = props;
  if (loading) {
    return <Loading />;
  }

  if (showFinalAnimation) {
    return (
      <div className="pc-layout">
        <div className="pc-layout__item">
          <div className="completeness-meter-animation">
            <div className="completeness-meter-animation-text">
              <div className="completeness-meter-animation-text__title">
                Congratulations for completing all the steps!
              </div>
              <div className="completeness-meter-animation-text__summary">
                We hope you have a clearer understanding of your finances and
                you are better prepared for your future goals.
              </div>
            </div>
            <div className="completeness-meter-animation-imgs">
              <img
                className="completeness-meter-animation-imgs__trophy"
                src={`${
                  window.staticUrl || ""
                }/static/img/completenessMeter/trophy.svg`}
                alt=""
              />
              <img
                className="completeness-meter-animation-imgs__confetti"
                src={`${
                  window.staticUrl || ""
                }/static/img/completenessMeter/confetti.gif`}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pc-layout">
      <div className="pc-layout__item pc-u-1/3">
        <ProgressMeter
          data={stepData}
          isCompleted={isCompleted}
          activeIndex={stepIndex}
        />
      </div>
      <div className="pc-layout__item pc-u-2/3">
        <TipCarousel
          component={"COMPLETENESS_METER"}
          areMultipleTips={areMultipleTips}
          data={tipData}
          onUnavailableHover={onUnavailableHover}
          isCompleted={isCompleted}
          activeIndex={tipIndex}
          onNextTipClick={onNextTipClick}
          onActionClick={onTipActionClick}
          stepData={stepData}
          showAddAccounts={showAddAccounts}
        />
      </div>
    </div>
  );
};

CompletenessMeter.propTypes = {
  tipData: PropTypes.object,
  tipIndex: PropTypes.number,
  stepData: PropTypes.object,
  stepIndex: PropTypes.string,
  onTipActionClick: PropTypes.func,
  onNextTipClick: PropTypes.func,
  loading: PropTypes.bool,
  isCompleted: PropTypes.bool,
  showFinalAnimation: PropTypes.bool,
  onUnavailableHover: PropTypes.func,
  areMultipleTips: PropTypes.bool,
  showAddAccounts: PropTypes.bool,
};

CompletenessMeter.defaultProps = {
  tipData: undefined,
  tipIndex: undefined,
  stepData: undefined,
  stepIndex: undefined,
  onTipActionClick: noop,
  onNextTipClick: noop,
  loading: undefined,
  isCompleted: undefined,
  showFinalAnimation: undefined,
  onUnavailableHover: noop,
  areMultipleTips: undefined,
  showAddAccounts: true,
};

export default CompletenessMeter;
