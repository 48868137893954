import _ from "underscore";
import orderGrants from "views/modules/stockOptions/components/orderGrants";
import Colors from "colorArrays";

/**
 *
 * @param {Number} n number of grants
 * @param {boolean} negative is negative or not
 * @returns {Array} Asset or Liability color array
 */
function grantColorSet(n, negative) {
  return negative
    ? Colors.getColorSet(n, "liability")
    : Colors.getColorSet(n, "asset");
}

/**
 *
 * @param {Array} grants array of grants
 * @returns {Array} Sorted grants with appropriate colors
 */
function colorizeGrants(grants) {
  if (!grants) return;

  /**
   * mapping grants to colors
   *
   * @type {Object}
   */

  var colors = grantColorSet(grants.length);
  var negColors = grantColorSet(grants.length, true);

  var assetIndex = 0,
    liabilityIndex = 0;

  grants = orderGrants(grants);

  _.each(grants, function (grant, index) {
    grant.color =
      grant.totalValue >= 0
        ? colors[assetIndex++]
        : negColors[liabilityIndex++];
    grant.order = index;
  });

  return grants;
}

export default colorizeGrants;
