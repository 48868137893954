import PropTypes from "prop-types";
import React from "react";
import PieChart, { getArcClasses } from "components/common/charts/PieChart";
import { formatCurrency } from "libs/pcap/utils/format";
import objectPath from "object-path";

const transformData = (data) => {
  const results = [];
  const totalAmount = data.reduce(
    (total, item) => total + objectPath.get(item, "data.0.y"),
    0
  );
  data.forEach((item) => {
    const amount = objectPath.get(item, "data.0.y");
    results.push({
      id: item.id,
      value: Math.round((amount / totalAmount) * 100),
      name: `${item.description}<br/>${formatCurrency(amount, 0)}`,
      description: item.description,
    });
  });
  return results;
};

const InvestableCashPieChart = ({ data, padAngle, chartFooter }) => {
  const datum = transformData(data);
  return (
    <div className="investable-cash-pie-chart-container">
      <div className="investable-cash-pie-chart-container__labels l-spaced l-spaced--flush">
        {Boolean(objectPath.get(datum, "1.description")) && (
          <label
            className={`chart__label--${objectPath.get(datum, "1.id")}`}
            dangerouslySetInnerHTML={{
              __html: objectPath.get(datum, "1.name"),
            }}
          />
        )}
        {Boolean(objectPath.get(datum, "0.description")) && (
          <label
            className={`chart__label--${objectPath.get(datum, "0.id")}`}
            dangerouslySetInnerHTML={{
              __html: objectPath.get(datum, "0.name"),
            }}
          />
        )}
      </div>
      <PieChart
        height={"100%"}
        width={"100%"}
        chartClassName=""
        datum={datum}
        padAngle={padAngle}
        innerRadius={0}
        getArcClasses={getArcClasses}
      />
      {Boolean(chartFooter) && (
        <div className="investable-cash-pie-chart-container__labels investable-cash-pie-chart-container__labels-bottom l-spaced l-spaced--flush">
          <label
            dangerouslySetInnerHTML={{
              __html: chartFooter,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default InvestableCashPieChart;

InvestableCashPieChart.propTypes = {
  data: PropTypes.array.isRequired,
  padAngle: PropTypes.number,
  chartFooter: PropTypes.string,
};

InvestableCashPieChart.defaultProps = {
  padAngle: 0,
  chartFooter: "",
};
