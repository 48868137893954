import React from "react";
import PropTypes from "prop-types";
import Lock from "svg-icons/Lock.svg";
import AccountTypeTileContainer from "empower/components/AccountTypeTilesContainer/AccountTypeTilesContainer";
import LinkAccountVideoContainer from "empower/components/LinkAccountVideoContainer/LinkAccountVideoContainer";
import { fetchJsonPromise } from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import InfoTooltipText from "components/common/InfoTooltipText";

// TODO These constants are needed for the next release of this feature. Leave it here for now
// const messageTitleSome = "You're doing a fantastic job!";
// const messageDetailsSome = (
//   <span>
//     Continue linking <b>your accounts</b> to get a full view of your finances.
//   </span>
// );
// const messageTitleMany = "You're getting the picture!";
// const MANY_LINKED_ACCOUNTS = 1000;

const NO_LINKED_ACCOUNTS = 0;

class AggregationHelper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      encryptionModalOpen: false,
      videoUrl: "",
      loadingVideoUrl: true,
      tileList: [],
      labels: {},
      tooltipDescription: "",
    };
  }

  componentDidMount() {
    const integratedSharedData = window.integratedSharedData;

    if (integratedSharedData) {
      const tileList = integratedSharedData.translations?.get("tilesList");
      const labels = integratedSharedData.translations?.get(
        "aggregationHelperLabels"
      );
      const translations = integratedSharedData.translations?.get(
        "howtoLinkAccountsVideo"
      );
      this.setState({
        videoUrl: translations?.url,
        loadingVideoUrl: false,
        tileList,
        labels,
      });
    } else {
      let getStyleGuide;
      getStyleGuide = window.styleGuideUrl
        ? fetchJsonPromise(window.styleGuideUrl)
        : // this is for local and pcap demo environments only
          fetchJsonPromise(
            `${window.staticUrl}/static/mockData/empower/style_guide.json`
          );

      if (getStyleGuide) {
        this.getStyleGuidePromise = makeCancelablePromise(getStyleGuide);
      }
      this.setState({ loadingVideoUrl: true });
      this.getStyleGuidePromise.promise
        .then((styleGuideResp) => {
          this.setState({
            videoUrl: styleGuideResp.howtoLinkAccountsVideo,
            loadingVideoUrl: false,
            tileList: styleGuideResp?.tilesList,
            labels: styleGuideResp?.aggregationHelperLabels,
          });
        })
        .catch((errors) => {
          this.setState({ errors, loadingVideoUrl: false });
        });
    }
  }

  render() {
    const { aggregatedAccounts } = this.props;
    let title = this.state.labels?.title,
      details = this.state.labels?.desc,
      linkAccountMessage = this.state.labels?.existingAccountButtonText;

    if (aggregatedAccounts === NO_LINKED_ACCOUNTS) {
      linkAccountMessage = this.state.labels?.newAccountButtonText;
    }

    const handleOnFocus = () => {
      this.setState({ tooltipDescription: this.state.labels?.securityTooltip });
    };

    const handleOnBlur = () => {
      this.setState({ tooltipDescription: "" });
    };

    return (
      <div className="aggregation-helper">
        <div className="aggregation-helper__message-title">
          <span>{title}</span>
        </div>
        <div className="aggregation-helper__message-details">
          <span dangerouslySetInnerHTML={{ __html: details }} />
        </div>
        {this.state.videoUrl && (
          <LinkAccountVideoContainer
            loading={this.state.loadingVideoUrl}
            url={this.state.videoUrl}
            errors={this.state.errors}
          />
        )}
        <AccountTypeTileContainer tileList={this.state.tileList} />
        <button
          className="pc-btn pc-btn--primary aggregation-helper__link-account"
          onClick={this.props.onLinkAnAccount}
        >
          {linkAccountMessage}
        </button>
        <div className="aggregation-helper__encryption">
          <Lock alt="Lock icon" aria-label="Lock" />
          <InfoTooltipText
            text={this.state.labels?.securityText}
            handleFocus={handleOnFocus}
            handleBlur={handleOnBlur}
            className="pc-u-ml-- aggregation-helper__security-text"
            tooltipContent={this.state.labels?.securityTooltip}
            tooltipProps={{
              className: "aggregation-helper__tooltip-text",
            }}
          />
          <div
            aria-live="polite"
            className="sr-only"
            dangerouslySetInnerHTML={{ __html: this.state.tooltipDescription }}
          ></div>
        </div>
      </div>
    );
  }
}

AggregationHelper.propTypes = {
  aggregatedAccounts: PropTypes.number,
  onLinkAnAccount: PropTypes.func,
};

AggregationHelper.defaultProps = {
  aggregatedAccounts: 0,
  onLinkAnAccount: () => {
    if (window.ga) window.ga("send", "event", "Link account tile");
    window.location.hash = "#/accounts/add";
  },
};

export default AggregationHelper;
