import PropTypes from "prop-types";
import React, { Component } from "react";
import Message from "components/common/Message";
import {
  hasMtrEligibleAccounts,
  hasUnenrolledMtrEligibleAccounts,
} from "utils/account";

export const REDIRECT_MTR_MESSAGE =
  "by selecting save, you will be redirected to enrollment";

export default class SetMasterPlanView extends Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
  }

  renderComparisonTable() {
    const comp = this.props.planComparison;

    const tableRows = Object.keys(this.props.planComparison).map((attr, i) => {
      return (
        <tr key={i}>
          <td>{comp[attr].label}</td>
          <td className="plan-comparison-table__value-cell">
            {comp[attr].oldValue}
          </td>
          <td className="plan-comparison-table__value-cell">
            {comp[attr].newValue}
          </td>
        </tr>
      );
    });

    return (
      <table className="plan-comparison-table">
        <thead>
          <tr className="plan-comparison-table__header">
            <td />
            <td>Old</td>
            <td>New</td>
          </tr>
        </thead>

        <tbody>{tableRows}</tbody>
      </table>
    );
  }

  renderMtrStrategyChangeMessageOrNull() {
    if (
      !IS_EMPOWER ||
      !window.isAdvisorApp ||
      !hasMtrEligibleAccounts(this.props.accounts) ||
      this.props.isGMWB
    ) {
      return null;
    }

    const whatIf = this.props.plan.result?.whatIf;
    const alternativeStrategy = whatIf?.alternativeStrategy;
    const children = [];

    if (hasUnenrolledMtrEligibleAccounts(this.props.accounts)) {
      children.push(
        <p key={"redirect-after-save-msg"}>{REDIRECT_MTR_MESSAGE}</p>
      );
    }

    if (
      whatIf?.alternativeStrategyScope &&
      whatIf?.alternativeStrategyScope !== "ALL"
    ) {
      const altStrategyDisplayName =
        alternativeStrategy === "RECOMMENDED"
          ? "Recommended"
          : this.props.maModelPortfolios.find(
              (mp) => mp.name === alternativeStrategy
            )?.displayName;

      children.push(
        <p key="plan-change-msg">{`by selecting save, it will change your model portfolio to ${
          altStrategyDisplayName ?? alternativeStrategy
        }`}</p>
      );
    }

    return <div className="mtr-strategy-change-msg-container">{children}</div>;
  }

  handleSave() {
    this.props.makePlanMaster();
  }

  render() {
    if (!this.props.planComparison) {
      return null;
    }

    const isPlanDiffEmpty = Object.keys(this.props.planComparison).length === 0;
    const planName = this.props.plan.planName || "My Plan";
    let message;
    if (isPlanDiffEmpty) {
      message = (
        <div>
          Click "Save" to confirm{" "}
          <span className="edit-plan-dialog__official-candidate">
            {planName}
          </span>{" "}
          as your official plan.
        </div>
      );
    } else {
      message = (
        <div>
          Your official financial plan is synced to your profile information. By
          making{" "}
          <span className="edit-plan-dialog__official-candidate">
            {planName}
          </span>{" "}
          your official financial plan, the following changes will be made to
          your profile:
        </div>
      );
    }

    return (
      <div>
        {this.props.errors && (
          <Message severity="error" messages={this.props.errors} />
        )}
        {message}
        <div>{!isPlanDiffEmpty && this.renderComparisonTable()}</div>
        {this.renderMtrStrategyChangeMessageOrNull()}
        <div className="u-text-right">
          <button
            type="button"
            className="pc-btn pc-u-mt"
            onClick={this.props.onClosed}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="pc-btn pc-btn--primary pc-u-mt"
            onClick={this.handleSave}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

SetMasterPlanView.propTypes = {
  onClosed: PropTypes.func,
  makePlanMaster: PropTypes.func,
  plan: PropTypes.object,
  planComparison: PropTypes.object,
  errors: PropTypes.array,
  isGMWB: PropTypes.bool,
  maModelPortfolios: PropTypes.array,
  accounts: PropTypes.array,
};

SetMasterPlanView.defaultProps = {
  isGMWB: undefined,
  maModelPortfolios: [],
  accounts: [],
};
