/* eslint-disable no-underscore-dangle, sonarjs/no-duplicate-string */
import eventBus from "eventBus";
import * as customEvents from "libs/pcap/utils/customEvents";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import AddAccountView2 from "views/components/addAccount2";
import _401kQuestionnaireView from "views/modules/_401k/_401kQuestionnaireView";
import LinkAccountTemplate from "templates/modal-windows/linkAccount.html";
import Mixpanel from "mixpanel";
import productTypes from "libs/pcap/utils/productTypes";
import { accountUpdateCompleted } from "empower/utils/liat/otherAssetsUtils";
import "jquery.colorbox";

var pathToTitleMapping = {
  BANK: "Bank",
  INVESTMENT: "Investment",
  CREDIT_CARD: "Credit Card",
  LOAN: "Loan",
  MORTGAGE: "Mortgage",
  MANUAL: "Manual",
  RETIREMENT: "Retirement",
};

export default Backbone.View.extend({
  el: "#linkAccountModal",
  events: {
    "click a.closeModal": "closeLinkAccount",
    "click a.skip401kQuestionnaire": "closeLinkAccount",
  },
  initialize: function (path, params) {
    var self = this;
    self.newAccountCreated = false;
    self.accountFlowCompleted = false;
    path = productTypes.transformPath(path);
    this.isFromCashMarketing = params.source === "cash-marketing";
    this.isOnboardingRP = params.source === "customretirement";

    if (IS_EMPOWER && params.aggregationLevel === "MANUAL_ONLY") {
      self.subProductType = "MANUAL";
      self.productType = "MANUAL";
      self.options = params.options || {};
    } else {
      self.subProductType = self.getSubProductType(path);
      self.productType = self.getProductType(path);
    }
    self.siteId = self.getSiteId(path);
    self.render({
      title:
        params.aggregationLevel === "MANUAL_ONLY"
          ? ""
          : self.getProductTitle(path),
    });
    self.show();
    var linkAccountContainer = self.$el.find(".linkAccountContainer");
    var showDefaultSuccessView = self.subProductType !== "RETIREMENT";
    var showManualAccounts = self.subProductType !== "RETIREMENT";
    const addAccountViewParams = {
      aggregationLevel: params.aggregationLevel,
      suggestionsMatrix: "4x3",
      height: 600,
      productType: self.productType,
      subProductType: self.subProductType,
      siteId: self.siteId,
      showDefaultSuccessView: showDefaultSuccessView,
      showManualAccounts: showManualAccounts,
      cryptoFeatureFlag: params.cryptoFeatureFlag,
      isManualOnly: params.aggregationLevel === "MANUAL_ONLY" || false,
      isResumeLinkingEnabled: params.isResumeLinkingEnabled,
      isFromCashMarketing: this.isFromCashMarketing,
      isDynamicPopularTilesEnabled: params.isDynamicPopularTilesEnabled,
      useUnifiedMar: params.useUnifiedMar,
      isConnectivityTrackerEnabled: params.isConnectivityTrackerEnabled,
    };
    self.addAccountView = new AddAccountView2(
      linkAccountContainer,
      addAccountViewParams
    );

    eventBus.on("linkAccount:created", self.onAccountCreated, self);
    eventBus.on("linkAccount:done", self.onCloseLinkAccount, self);
    eventBus.on("linkAccount:cancel", self.onCancelLinkAccount, self);
    eventBus.on(
      "linkAccount:manualAccountContinue",
      self.onManualAccountContinue,
      self
    );
    //bind to questionnaire/assumptions events
    eventBus.on(
      customEvents.employerPlanQuestionnaireUpdated,
      self.onCloseLinkAccount,
      self
    );

    if (params) {
      if (params.onClosed) this.onClosed = params.onClosed;
      if (params.beforeClosed) this.beforeClosed = params.beforeClosed;
    }
  },
  render: function (data) {
    this.$el.html(LinkAccountTemplate(data));
    return this;
  },
  remove: function () {
    var self = this;
    if (this.newAccountCreated) {
      this.accountFlowCompleted = true;
    }
    //unsubscribe
    eventBus.off("linkAccount:created", self.onAccountCreated);
    eventBus.off("linkAccount:done", self.onCloseLinkAccount);
    eventBus.off(
      "linkAccount:manualAccountContinue",
      self.onManualAccountContinue
    );
    eventBus.off(
      "linkAccount:manualAccountContinue",
      self.onManualAccountContinue
    );
    eventBus.off(
      customEvents.employerPlanQuestionnaireUpdated,
      self.onCloseLinkAccount
    );

    this.addAccountView.remove();
    Backbone.View.prototype.remove.apply(this, arguments);
  },
  show: function () {
    $.colorbox({
      inline: true,
      href: this.$el,
      transition: "none",
      overlayClose: false,
      escKey: false,
    });
  },
  closeLinkAccount: function (e) {
    e.preventDefault();
    this.onCloseLinkAccount(this.options || {});
    eventBus.trigger("linkAccount:renderScheduleAppointmentModal");
  },
  onAccountCreated: function (account) {
    if (_.isUndefined(account)) {
      return;
    }
    if (IS_EMPOWER) {
      const { productType } = account;
      if (productType === "BANK") {
        accountUpdateCompleted("bankaccountlinked");
      } else if (productType === "CREDIT_CARD") {
        accountUpdateCompleted("creditcardaccountlinked");
      }
    }
    if (this.subProductType === "RETIREMENT" && !account.isManual) {
      // hide link account widget
      this.$(".linkAccountContainer").hide();
      // show 401k questionnaire
      this.show401kQuestionnaire();
    }
    this.newAccountCreated = true;
  },

  /* eslint-disable sonarjs/cognitive-complexity */
  onCloseLinkAccount: function (options) {
    $.colorbox.close();

    if (IS_EMPOWER && this.beforeClosed) {
      this.beforeClosed();
    }

    this.remove();

    if (!options || !options.skipHistory) {
      var mainAppContentIsBlank = $("#moduleContainer").height() === 0;

      if (this.isOnboardingRP) {
        window.location.href = "#/retirement-planner";
        return;
      }
      if (mainAppContentIsBlank) {
        window.location.href = DASHBOARD_URL;
      } else if (
        window.location.hash === "#/accounts/add?skipAccountOrSavings"
      ) {
        if (this.newAccountCreated) {
          // stay on same page
          this.newAccountCreated = false;
        } else {
          // user created new acct then clicked back
          window.history.back();
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (IS_EMPOWER) {
          this.onClosed();
        } else {
          window.history.back();
        }
      }
    }

    Mixpanel.trackEvent("Close Link Account Modal", {
      is_first_use: this.isFirstUseScreen, // eslint-disable-line camelcase
    });
  },

  onCancelLinkAccount: function () {
    $.colorbox.close();
    this.remove();
    window.location.href = DASHBOARD_URL;
  },

  getProductType: function (path) {
    if (_.isUndefined(path)) {
      return "";
    }
    const productType = (path.split("/")[0] || "")
      .toUpperCase()
      .replace("+", " "); // Convert '+' to ' ' just in case
    if (productType.includes(" ")) {
      // e.g. #/accounts/add/LOAN+MORTGAGE where '+' is usually auto converted to ' '
      return productTypes.getProductTypeArray(productType);
    }
    return productTypes.allowedProductTypes.includes(productType)
      ? productType
      : "";
  },
  getSiteId: function (path) {
    if (this.productType === "site") {
      return path.split("/")[1];
    }
    return 0;
  },
  getSubProductType: function (path) {
    if (_.isUndefined(path)) {
      return "";
    }
    const subProductType = (path.split("/")[1] || "").toUpperCase();
    return [
      ...productTypes.allowedSubProductTypes,
      ...productTypes.manualProductTypes,
    ].includes(subProductType)
      ? subProductType
      : "";
  },
  getProductTitle: function (path) {
    if (_.isUndefined(path)) {
      return "";
    }
    var productTitle;
    if (this.subProductType.length > 0) {
      productTitle = pathToTitleMapping[this.subProductType];
    } else {
      productTitle = pathToTitleMapping[path.split("/")[0]];
    }
    return _.isString(productTitle) ? productTitle : "";
  },
  show401kQuestionnaire: function () {
    var self = this;
    //determine if the  questionnaire has been answered by user or not
    Services._401kQuestionnaire.get(function (err, data) {
      //eslint-disable-line no-underscore-dangle
      if (typeof data.spData != "undefined") {
        var showQuestionnaire = data.spData.isQuestionnaire;
        if (showQuestionnaire === true) {
          //$('.linkAccountTitle', self.$el).html('Connecting to your 401k provider');
          $(".linkAccount401kQuestionnaire .container", self.$el).html(
            new _401kQuestionnaireView().$el
          );
          $(".linkAccount401kQuestionnaire", self.$el).show();
        } else {
          self.onCloseLinkAccount();
        }
      }
    });
  },
  onManualAccountContinue: function (href) {
    if (_.isString(href)) {
      $.colorbox.close();
      this.remove();
      window.location.replace(href);
    } else {
      this.onCloseLinkAccount();
    }
  },
});
