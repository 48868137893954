import PropTypes from "prop-types";
import React from "react";
import { promisify } from "utils/service";
import Services from "services";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import { isEmpty } from "underscore";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import Mixpanel from "mixpanel";
import deepCopy from "deep-copy";

export default class FastlinkContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.handleAuthenticationDetailsFailure =
      this.handleAuthenticationDetailsFailure.bind(this);
    this.handleAuthenticationDetailsSuccess =
      this.handleAuthenticationDetailsSuccess.bind(this);
    this.handleFastlinkOnSuccess = this.handleFastlinkOnSuccess.bind(this);
    this.handleFastlinkOnError = this.handleFastlinkOnError.bind(this);
    this.handleFastlinkOnClose = this.handleFastlinkOnClose.bind(this);
    this.linkAccount = this.linkAccount.bind(this);
    this.handleFastlinkOnEvent = this.handleFastlinkOnEvent.bind(this);
  }

  linkAccount(data, callback) {
    console.log("linkAccount: ", JSON.stringify(data));
    data.flow = this.props.flow;
    this.props
      .linkAccount({ ...data })
      .then((response) => {
        if (callback) {
          callback(response);
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errors: error,
        });
      });
  }

  handleFastlinkOnEvent(data) {
    console.log("handleFastlinkOnEvent: ", data);
    Mixpanel.trackEvent(`Fastlink OnEvent ${data?.eventName}`);
    if (data.eventName && data.eventType) {
      const eventProps = deepCopy(data);
      eventProps.customProps = JSON.stringify(eventProps.customProps);
      eventProps.props = JSON.stringify(eventProps.props);
      this.props
        .events(eventProps)
        .then(() => console.log("Events api success" + data))
        .catch((errors) => {
          console.log("Events api error: ", errors);
        });
    }
    if (data?.action === "linkAnotherSite") {
      window.fastlink?.close();
      this.props.onClose();
    }
  }

  handleFastlinkOnError(data) {
    console.log("handleFastlinkOnError: ", data);
    this.linkAccount(data);
  }

  handleFastlinkOnClose(data) {
    console.log("handleFastlinkOnClose: ", data);
    window.fastlink?.close();
    this.linkAccount(data, this.props.onClose);
  }

  async handleFastlinkOnSuccess(data) {
    console.log("handleFastlinkOnSuccess: ", data);
    window.fastlink?.close();
    this.linkAccount(data, this.props.onSuccess);
  }

  handleAuthenticationDetailsSuccess(response) {
    this.setState({
      loading: false,
      errors: undefined,
      data: response,
    });

    window.fastlink?.open(
      {
        fastLinkURL: response.fastlinkUrl,
        accessToken: response.accessToken,
        params: {
          providerId: response.providerId,
          providerAccountId: response.providerAccountId,
          flow: response.flow,
          configName: response.configName?.default,
          iframeResize: false,
        },
        forceIframe: true,
        onSuccess: this.handleFastlinkOnSuccess,
        onError: this.handleFastlinkOnError,
        onClose: this.handleFastlinkOnClose,
        onEvent: this.handleFastlinkOnEvent,
      },
      "js-fastlink-container"
    );
  }

  handleAuthenticationDetailsFailure(errors) {
    console.log("handleAuthenticationDetailsFailure: ", errors);
    if (this.getAuthenticationDetailsCancelableService.isCanceled()) {
      return;
    }

    this.setState({
      loading: false,
      errors: errors,
    });
  }

  componentDidMount() {
    const { siteId, userSiteId, flow } = this.props;
    const params = {
      flow: flow,
    };
    if (flow === "ADD") {
      params.siteId = siteId;
    } else {
      params.userSiteId = userSiteId;
    }
    this.getAuthenticationDetailsCancelableService = makeCancelablePromise(
      this.props.getAuthenticationDetails(params)
    );
    this.getAuthenticationDetailsCancelableService.promise.then(
      this.handleAuthenticationDetailsSuccess,
      this.handleAuthenticationDetailsFailure
    );
  }

  componentWillUnmount() {
    if (this.getAuthenticationDetailsCancelableService) {
      this.getAuthenticationDetailsCancelableService.cancel();
    }
  }

  render() {
    const { errors, loading } = this.state;
    if (loading) {
      return <LoadingOverlay active={loading} />;
    }
    if (!isEmpty(errors)) {
      return <Message className="pc-u-mb" severity="error" messages={errors} />;
    }
    return <div id="js-fastlink-container"></div>;
  }
}

FastlinkContainer.propTypes = {
  getAuthenticationDetails: PropTypes.func,
  linkAccount: PropTypes.func,
  events: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  flow: PropTypes.string,
  siteId: PropTypes.number.isRequired,
  userSiteId: PropTypes.number,
};

FastlinkContainer.defaultProps = {
  getAuthenticationDetails: promisify(
    Services.Fastlink.getAuthenticationDetails
  ),
  linkAccount: promisify(Services.Fastlink.linkAccount, true),
  events: promisify(Services.Fastlink.events, true),
  flow: "ADD",
  userSiteId: undefined,
};
