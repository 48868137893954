import PropTypes from "prop-types";
import React from "react";
import { formatCurrency, formatNumber } from "libs/pcap/utils/format";
import { isEmpty, uniqueId } from "underscore";

export default class ImportantDataPoints extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { dataPoints, isPercentage } = this.props;
    if (isEmpty(dataPoints)) {
      // this returns and renders this HTML comment: <!-- react-empty: 1 -->
      return null;
    }

    const NAME_CLASS_NAME = "important-data-points__name";
    const VALUE_CLASS_NAME = "important-data-points__value";

    return (
      <div
        className="important-data-points qa-important-data-points l-spaced l-spaced--flush pc-u-ml--"
        aria-label="Important data points"
      >
        {dataPoints.map(function (item, index) {
          const key = uniqueId("important-data-points");
          const isNegativeValue = item.value < 0;
          let nameClassName =
            "pc-zeta " +
            NAME_CLASS_NAME +
            " qa-important-data-points-name-" +
            index;
          let valueClassName =
            "pc-zeta " +
            VALUE_CLASS_NAME +
            " qa-important-data-points-value-" +
            index;
          if (isNegativeValue) {
            valueClassName += " important-data-points__value--negative";
          }
          const displayValue = isPercentage
            ? formatNumber(item.value, 2) + "%"
            : (isNegativeValue ? "" : "+") + formatCurrency(item.value, 0);
          return (
            <div
              key={`${index}-${item.value || "item"}`}
              className={`js-important-data-points-${index}`}
            >
              <div
                key={`idpName${index}`}
                id={`${key}-name`}
                className={`${nameClassName}`}
                aria-label={item.name + " change " + displayValue}
              >
                {item.name}
              </div>
              <div
                key={`idpValue${index}`}
                id={`${key}-value`}
                className={valueClassName}
              >
                {displayValue}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

ImportantDataPoints.propTypes = {
  dataPoints: PropTypes.array,
  isPercentage: PropTypes.bool,
};

ImportantDataPoints.defaultProps = {
  dataPoints: [],
  isPercentage: false,
};
