import React from "react";
export default function ChangeJobs1() {
  return (
    <svg
      width="110px"
      height="110px"
      viewBox="0 0 110 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ACE_ChangeJobs1</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="ACE_ChangeJobs_Retail_A-With-web"
          transform="translate(-75.000000, -62.000000)"
        >
          <g id="ACE_ChangeJobs1" transform="translate(75.000000, 62.000000)">
            <g filter="url(#filter-1)" id="circle">
              <g transform="translate(0.000000, -0.000611)">
                <path
                  d="M110,54.9992369 C110,85.3747971 85.3759816,110 55,110 C24.6255445,110 0,85.3747971 0,54.9992369 C0,24.6252029 24.6255445,0 55,0 C85.3759816,0 110,24.6252029 110,54.9992369"
                  id="Fill-1"
                  fill="#FDF8E7"
                ></path>
              </g>
            </g>
            <g
              transform="translate(24.596932, 24.096417)"
              strokeLinecap="round"
              strokeWidth="1.5"
            >
              <path
                d="M63.2140528,30.9634722 C63.2140528,48.0638889 49.0628633,61.9269444 31.6070264,61.9269444 C14.1511895,61.9269444 0,48.0638889 0,30.9634722 C0,13.8630556 14.1511895,0 31.6070264,0 C49.0628633,0 63.2140528,13.8630556 63.2140528,30.9634722 Z"
                stroke="#002157"
              ></path>
              <polyline
                stroke="#DB1830"
                points="16.103723 34.7396806 28.5737375 45.6663472 47.1103298 16.2596806"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
