import _ from "underscore";
import $ from "jquery";
import utils from "libs/pcap/utils/utils";
$.pcap.clientOffset = function (e, el) {
  /**
          note - this function returns two sets of values:
          offsetX and offsetY based on clientX/Y,
          and pageOffsetX/Y based on absolute/page offset X/Y
          */

  var out = {
    offsetX: e.offsetX ? e.offsetX : e.clientX - el.offset().left,
    pageOffsetX: e.pageX - el.offset().left,
    offsetY: e.offsetY ? e.offsetY : e.clientY - el.offset().top,
    pageOffsetY: e.pageY - el.offset().top,
  };

  out.percentWidth = out.pageOffsetX / el.width();
  out.percentHeight = out.pageOffsetY / el.height();

  return out;
};

export default $.pcap.clientOffset;
