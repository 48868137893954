import PropTypes from "prop-types";
import React from "react";
import {
  getPersons,
  createPerson,
  updatePerson,
} from "accessors/person/accessor";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import InviteSecondaryAccountOwner from "../InviteSecondaryAccountOwner";
import InviteSecondaryAccountOwnerConfirmation from "../InviteSecondaryAccountOwnerConfirmation";
import { isEqual, noop } from "underscore";
import { fullName } from "libs/pcap/utils/person";
import Services from "services";
import { promisify } from "utils/service";
import LoadingOverlay from "components/common/LoadingOverlay";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import SelectPersonInput from "components/common/SelectPersonInput";
import JointAccountConsent from "components/common/SelectPersonInput/JointAccountConsent";
import { trackView, trackClick } from "components/common/ComponentAnalytics";
import { getAccount } from "accessors/account/accessor";
import objectPath from "object-path";

const TOOLTIP_JOINT_ACCOUNT_SELF_USER = `Only the primary user can be added to this account.`;

function isNewPerson(id) {
  return id === -1 || id == null;
}

export default class InviteSecondaryAccountOwnerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.loggedInPersonId = window.personId;
    this.isDelegateUser = window.isDelegate;

    this.state = {
      initializing: true,
    };

    this.handleInvite = this.handleInvite.bind(this);
    this.handleWithdrawnInvitation = this.handleWithdrawnInvitation.bind(this);
  }

  componentDidMount() {
    const { fetchPcbAccountInfo, fetchDelegates, userAccountId } = this.props;
    this.fetchDataRequest = makeCancelablePromise(
      Promise.all([
        getPersons(),
        fetchPcbAccountInfo({
          userAccountId,
        }),
        fetchDelegates(),
        getAccount(userAccountId, {
          personRoles: ["BENEFICIARY"],
        }),
      ])
    );

    this.fetchDataRequest.promise.then(
      ([people, accountInfo, delegateUsers, beneficiariesInfo]) => {
        const beneficiaries = objectPath
          .get(beneficiariesInfo, "stakeholders.BENEFICIARY", [])
          .map((b) => b.personId);
        this.setState({
          loggedInPerson: people.find((p) => p.id === this.loggedInPersonId),
          people,
          beneficiaries,
          delegateUsers,
        });

        if (this.isDelegateUser) {
          this.setState({
            primaryDashboardUser: people.find((p) => p.relationship === "SELF"),
          });
        }

        this.setStateInvitedPerson(accountInfo, people);
        this.setState({ initializing: false });
      },
      (errors) => {
        if (this.fetchDataRequest.isCanceled()) {
          return;
        }

        this.setState({
          errors,
          initializing: false,
        });
      }
    );
  }

  setStateInvitedPerson(accountInfo, household) {
    const { owners } = accountInfo;
    const secondaryOwner = owners.find((o) => o.personRole === "SECONDARY");
    if (secondaryOwner) {
      const invitedPerson = household.find(
        (p) => p.id === secondaryOwner.personId
      );
      this.setState({
        displayConfirmation: true,
        invitedPerson,
        enrollmentId: secondaryOwner.enrollmentId,
        inviteExpiration: secondaryOwner.inviteExpiration,
      });
    }
  }

  handleInvite(person) {
    this.setState({ errors: undefined, loading: true });
    this.savePerson(person)
      .then((person) => {
        const { onPersonSaved, sendInviteService } = this.props;
        onPersonSaved(person);
        return sendInviteService({ personId: person.id });
      })
      .then((sendInviteRs) => {
        const { onInviteSent, fetchPcbAccountInfo, userAccountId } = this.props;
        onInviteSent(sendInviteRs);
        return fetchPcbAccountInfo({
          userAccountId,
        });
      })
      .then((accountInfo) => {
        const { owners } = accountInfo;
        const secondaryOwner = owners.find((o) => o.personRole === "SECONDARY");

        trackView("An Invite Has Been Sent!", {
          component: "Add Account Owner",
        });
        this.setState({
          loading: false,
          displayConfirmation: true,
          invitedPerson: person,
          enrollmentId: secondaryOwner.enrollmentId,
          inviteExpiration: secondaryOwner.inviteExpiration,
        });
      })
      .catch((errors) => {
        this.setState({
          errors,
          loading: false,
        });
      });
  }

  handleWithdrawnInvitation() {
    trackClick(null, "Add Account Owner", "Withdraw Invitation");
    this.props.onWithdrawnInvitation.apply(null, arguments);
  }

  savePerson(person) {
    const additionalParams = {
      source: "PCB",
    };

    const { id } = person;

    if (isNewPerson(id)) {
      return createPerson(
        // reset client-side id
        Object.assign({}, person, { id: undefined }),
        additionalParams
      );
    }

    const origPerson = this.state.people.find((p) => p.id === id);
    if (isEqual(person, origPerson)) {
      return Promise.resolve(person);
    }
    return updatePerson(person, additionalParams);
  }

  componentWillUnmount() {
    if (this.fetchDataRequest) {
      this.fetchDataRequest.cancel();
    }
  }

  render() {
    const {
      errors,
      loading,
      initializing,
      people,
      loggedInPerson,
      displayConfirmation,
      invitedPerson,
      inviteExpiration,
      enrollmentId,
      beneficiaries,
      delegateUsers,
    } = this.state;
    const { onCancel, onDone } = this.props;
    if (initializing) {
      return <LoadingOverlay active={true} />;
    }
    const selectPersonValidator = Object.assign(
      {},
      SelectPersonInput.defaultProps.validator
    );

    selectPersonValidator.properties.id.conform = (id) => {
      return !beneficiaries.includes(id);
    };

    selectPersonValidator.properties.id.messages.conform =
      "Account beneficiaries cannot be added as additional owner.";

    return (
      <>
        {displayConfirmation ? (
          <InviteSecondaryAccountOwnerConfirmation
            name={fullName(invitedPerson)}
            emailAddress={invitedPerson.emailAddress}
            expirationDate={inviteExpiration}
            enrollmentId={enrollmentId}
            onDone={onDone}
            onWithdrawnInvitation={this.handleWithdrawnInvitation}
          />
        ) : (
          <InviteSecondaryAccountOwner
            selectPersonValidator={selectPersonValidator}
            model={{
              person: this.state.primaryDashboardUser,
            }}
            household={people}
            // The logged in person is always the primary owner.
            // We cannot select the same person as the secondary owner.
            selectOptions={
              people && people.filter((p) => p.id !== this.loggedInPersonId)
            }
            consentLabel={
              <JointAccountConsent
                applicantName={loggedInPerson && fullName(loggedInPerson)}
                isDelegate={this.isDelegateUser}
              />
            }
            selectDropdownHeading={
              this.isDelegateUser ? (
                <>
                  Additional Account Owner
                  <InfoTooltipIcon
                    className="pc-u-ml--"
                    title={TOOLTIP_JOINT_ACCOUNT_SELF_USER}
                  />
                </>
              ) : (
                SelectPersonInput.defaultProps.selectDropdownHeading
              )
            }
            isDelegateUser={this.isDelegateUser}
            delegateUsers={delegateUsers}
            errors={errors}
            loading={loading}
            onCancel={onCancel}
            onInvite={this.handleInvite}
          />
        )}
      </>
    );
  }
}

InviteSecondaryAccountOwnerContainer.propTypes = {
  userAccountId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func,
  onWithdrawnInvitation: PropTypes.func,
  // The service function receives `{ personId: <id> }` as an input.
  // Must return a Promise.
  sendInviteService: PropTypes.func.isRequired,
  fetchPcbAccountInfo: PropTypes.func,
  fetchDelegates: PropTypes.func,
  onPersonSaved: PropTypes.func,
  onInviteSent: PropTypes.func,
};

InviteSecondaryAccountOwnerContainer.defaultProps = {
  onDone: noop,
  onPersonSaved: noop,
  onInviteSent: noop,
  onWithdrawnInvitation: noop,
  fetchPcbAccountInfo: promisify(Services.PCBAccount.accountInfo),
  fetchDelegates: promisify(Services.Delegate.get),
};
