import PropTypes from "prop-types";
import React from "react";
import Message from "components/common/Message";
import Input from "components/common/form/Input";
import AbstractForm from "components/common/form/AbstractForm";
import deepCopy from "deep-copy";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const isPrivileged = isEmpowerPrivilegedMode();
export default class EditCategory extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      model: {
        category: deepCopy(this.props.category),
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRevertToOriginalDescription =
      this.handleRevertToOriginalDescription.bind(this);
  }

  handleSubmit(event) {
    const validateResult = this.validate();
    if (validateResult.valid) {
      if (this.checkIfFormHasChanged()) {
        this.setState({
          editing: true,
        });
        this.props.onEdit(this.state.model.category);
      } else {
        this.props.onCancel();
      }
    }
    event.preventDefault();
    event.stopPropagation();
  }

  handleRevertToOriginalDescription() {
    let { model } = this.state;
    model.category.name = model.category.originalName;
    this.setState({ model });
  }

  render() {
    if (this.props.errors) {
      return <Message severity="error" messages={this.props.errors} />;
    }

    const category = this.state.model.category;
    const { name, isEditable, isCustom, isOverride } = category;
    const shouldShowRevert =
      category.name !== category.originalName && isEditable && isOverride;
    return (
      <form
        ref={(formContainer) => {
          this.formContainer = formContainer;
        }}
        onSubmit={this.handleSubmit}
        className={"qa-edit-category-form"}
      >
        <div className="pc-form-group">
          <label htmlFor="categoryName" className="pc-label" title="Required">
            Name
          </label>
          <Input
            id="categoryName"
            type="text"
            name="category.name"
            ref={this.storeInputRef}
            sizeVariation="full"
            maxLength={100}
            className="input input--full"
            containerClassName={
              shouldShowRevert
                ? "categories-manager__edit-name-input--revert qa-edit-category-name"
                : "qa-edit-category-name"
            }
            onChange={this.handleInputChange}
            placeholder="Category name"
            value={name}
            validator={this.props.schema.properties.name}
          />
          {shouldShowRevert && (
            <InfoTooltipIcon
              className="icon__help-circled pc-u-ml-- qa-category-edit-original-name-tooltip"
              title={`<div>Original name:</div>${category.originalName}<div>Double click to use original</div>`}
              onDoubleClick={this.handleRevertToOriginalDescription}
            />
          )}
        </div>
        <div className="pc-layout pc-u-mb-">
          {!this.state.editing && (
            <div>
              <div className="pc-layout__item pc-u-1/2">
                {isEditable && isCustom && (
                  <button
                    type="button"
                    className="pc-btn categories-manager__remove qa-custom-category-remove"
                    onClick={this.props.onShowRemove}
                  >
                    {" "}
                    <svg
                      className="categories-manager__remove-icon"
                      viewBox="0 0 24 24"
                    >
                      <use
                        xmlns="http://www.w3.org/1999/xlink"
                        xlinkHref="#pc-icon__remove"
                      />
                    </svg>
                    Remove
                  </button>
                )}
              </div>
              <div className="pc-layout__item pc-layout--right pc-u-1/2">
                <button
                  type="button"
                  className="pc-btn qa-custom-category-cancel"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </button>
                <button
                  className="pc-btn pc-btn--primary qa-custom-category-done"
                  aria-disabled={isPrivileged}
                  disabled={isPrivileged}
                >
                  Done
                </button>
              </div>
            </div>
          )}
          {this.state.editing && (
            <div className="pc-layout__item pc-layout--right">
              <button type="button" className="pc-btn pc-btn--primary" disabled>
                Updating...
              </button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

EditCategory.propTypes = {
  errors: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  category: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onShowRemove: PropTypes.func,
};

// Static
EditCategory.defaultProps = {
  schema: {
    type: "object",
    properties: {
      name: {
        allowEmpty: false,
        type: "string",
        messages: {
          allowEmpty: "Please enter the category name.",
        },
      },
    },
  },
};
