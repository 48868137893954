import PropTypes from "prop-types";
import React, { useRef, useCallback } from "react";
import { formatCurrency } from "libs/pcap/utils/format";
import {
  ACTION_FIX_AGGREGATION_ERROR,
  ACTION_AGGREGATION_IN_PROGRESS,
  ACTION_VERIFY_MICRO_DEPOSIT,
  ACTION_AGGREGATE_ACCOUNT,
} from "utils/account";
import "tooltip";
import InfoTooltipText from "components/common/InfoTooltipText";
import DefaultLogoFI from "svg-icons/DefaultLogoFI.svg";

const PCAP_REBRAND_LOGO_SVG_ID = "#emp-logo-app-flag-large";

function Logo({ isPcap, logoPath, firmName }) {
  const PCAP_LOGO_SVG = PCAP_REBRAND_LOGO_SVG_ID;
  if (isPcap) {
    return (
      <div className="account-box__img">
        <svg
          aria-label="Empower logo"
          className="account-box__fi-pcap-logo js-pcap-fi-logo-icon"
        >
          <use xlinkHref={PCAP_LOGO_SVG} />
        </svg>
      </div>
    );
  } else if (logoPath) {
    return (
      <div className="account-box__img">
        <img
          src={logoPath}
          alt={`${firmName || ""} logo`}
          className="account-box__fi-logo"
        />
      </div>
    );
  }

  return (
    <div className="account-box__img">
      <DefaultLogoFI
        aria-label="Generic financial institution logo"
        className="account-box__fi-logo"
      />
    </div>
  );
}

Logo.propTypes = {
  firmName: PropTypes.string,
  logoPath: PropTypes.string,
  isPcap: PropTypes.bool,
};

Logo.defaultProps = {
  firmName: "",
  logoPath: "",
  isPcap: false,
};

export default function AccountBox({
  logoPath,
  firmName,
  name,
  balance,
  className,
  isOnUs,
  isOnUsBank,
  showLogo,
  SecondColumn,
  action,
  productType,
  availableCash,
  showAvailableCash,
  availableBalance,
  showAvailableBalance,
}) {
  const tooltip = useRef(null);

  const refIcon = useCallback((el) => {
    if (tooltip.current) {
      $(tooltip.current).tooltip("destroy");
    }

    if (el) {
      $(el).tooltip({ container: "body", placement: "auto" });
    }
    tooltip.current = el;
  }, []);

  let stateIcon;
  switch (action) {
    case ACTION_FIX_AGGREGATION_ERROR:
      stateIcon = (
        <div
          ref={refIcon}
          className="account-box__icon account-box__icon--error"
          data-toggle="tooltip"
          title="You will need to fix this account's connection to use it for transfers."
        />
      );
      break;
    case ACTION_AGGREGATE_ACCOUNT:
      stateIcon = (
        <div
          ref={refIcon}
          className="account-box__icon account-box__icon--warning"
          data-toggle="tooltip"
          title="This manual account will need to be linked to be used for transfers."
        />
      );
      break;
    case ACTION_VERIFY_MICRO_DEPOSIT:
      stateIcon = (
        <div
          ref={refIcon}
          className="account-box__icon account-box__icon--warning"
          data-toggle="tooltip"
          title="You might need to verify this account if you want to transfer to this account."
        />
      );
      break;
    case ACTION_AGGREGATION_IN_PROGRESS:
      stateIcon = (
        <div className="account-box__icon account-box__icon--loading" />
      );
      break;
    default:
  }

  return (
    <div className={`account-box ${className}`}>
      {showLogo && (
        <Logo
          logoPath={logoPath}
          firmName={firmName}
          isPcap={isOnUs || isOnUsBank}
        />
      )}
      <div className="account-box__icon-container">{stateIcon}</div>
      <div className="account-box__body">
        <div
          className="account-box__title qa-account-box__title"
          title={firmName}
        >
          {firmName}
        </div>
        <div
          className="account-box__sub-title qa-account-box__subtitle"
          title={name}
          data-hj-masked
        >
          {name}
        </div>
      </div>
      <div className="account-box__second-column">
        <SecondColumn
          balance={balance}
          productType={productType}
          availableCash={availableCash}
          showAvailableCash={showAvailableCash}
          isOnUsBank={isOnUsBank}
          availableBalance={availableBalance}
          showAvailableBalance={showAvailableBalance}
        />
      </div>
    </div>
  );
}

AccountBox.propTypes = {
  className: PropTypes.string,
  firmName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logoPath: PropTypes.string,
  action: PropTypes.string,
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOnUs: PropTypes.bool,
  isOnUsBank: PropTypes.bool,
  showLogo: PropTypes.bool,
  SecondColumn: PropTypes.func,
  productType: PropTypes.string,
  showAvailableCash: PropTypes.bool,
  availableCash: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showAvailableBalance: PropTypes.bool,
  availableBalance: PropTypes.number,
};

const DefaultSecondColumn = ({
  balance,
  productType,
  availableCash,
  showAvailableCash,
  availableBalance,
  showAvailableBalance,
  isOnUsBank,
}) => (
  <>
    <div className="account-box__balance">{formatCurrency(balance)}</div>
    {productType === "INVESTMENT" && showAvailableCash && (
      <InfoTooltipText
        className="account-box__available-cash-sub-title"
        tooltipContent="Available Cash"
        text={formatCurrency(availableCash)}
      ></InfoTooltipText>
    )}
    {isOnUsBank && showAvailableBalance && balance !== availableBalance && (
      <InfoTooltipText
        className="account-box__available-cash-sub-title"
        tooltipContent="Available Balance"
        text={formatCurrency(availableBalance)}
      ></InfoTooltipText>
    )}
  </>
);
DefaultSecondColumn.propTypes = {
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productType: PropTypes.string,
  showAvailableCash: PropTypes.bool,
  // The cash balance portion on the investment account
  availableCash: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOnUsBank: PropTypes.bool,
  showAvailableBalance: PropTypes.bool,
  // The available balance portion on the cash account
  availableBalance: PropTypes.number,
};

DefaultSecondColumn.defaultProps = {
  availableCash: AccountBox.propTypes.availableCash,
  productType: AccountBox.propTypes.productType,
  showAvailableCash: AccountBox.propTypes.showAvailableCash,
  showAvailableBalance: AccountBox.propTypes.showAvailableBalance,
  isOnUsBank: AccountBox.propTypes.isOnUsBank,
  availableBalance: AccountBox.propTypes.availableBalance,
};

AccountBox.defaultProps = {
  className: "",
  showLogo: true,
  SecondColumn: DefaultSecondColumn,
  logoPath: "",
  action: "",
  isOnUs: false,
  isOnUsBank: false,
  balance: 0,
  availableCash: 0,
  availableBalance: 0,
  productType: "",
  showAvailableCash: true,
  showAvailableBalance: true,
};
