import moment from "moment";
import DateUtils from "libs/pcap/utils/date";
import { isEmpty } from "underscore";
import { formatCurrency, formatNumber } from "libs/pcap/utils/format";

export function dateSearchAccessor(transaction, parentTransaction) {
  if (parentTransaction && parentTransaction.transactionDate) {
    return moment(
      parentTransaction.transactionDate,
      DateUtils.API_FORMAT
    ).format(DateUtils.DISPLAY_FORMAT);
  }

  return moment(transaction.transactionDate, DateUtils.API_FORMAT).format(
    DateUtils.DISPLAY_FORMAT
  );
}

export function accountNameSearchAccessor(transaction, parentTransaction) {
  if (parentTransaction && parentTransaction.accountName) {
    return parentTransaction.accountName;
  }

  return (transaction && transaction.accountName) || "";
}

export function descriptionSearchAccessor(transaction, parentTransaction) {
  if (transaction?.isEmpower) {
    return "Details";
  }

  if (parentTransaction && parentTransaction.description) {
    return parentTransaction.description;
  }

  return (transaction && transaction.description) || "";
}

export function categoryNameSearchAccessor(transaction = {}, delimiter = ", ") {
  if (transaction == null) {
    return "";
  }

  if (transaction.isEmpower) {
    return transaction?.category ?? "Requests";
  }

  if (isEmpty(transaction.splits)) {
    return transaction.categoryName || "";
  }

  return transaction.splits.map((s) => s.categoryName).join(delimiter);
}

export function tagSearchAccessor(transaction = {}, delimiter = ", ") {
  if (transaction == null) {
    return "";
  }

  if (isEmpty(transaction.splits)) {
    return isEmpty(transaction.selectedTagNames)
      ? ""
      : transaction.selectedTagNames.join(delimiter);
  }

  return transaction.splits
    .map((s) => s.selectedTagNames.join(delimiter))
    .join(delimiter);
}

export function unformattedAmountSearchAccessor(transaction, delimiter = ", ") {
  if (transaction == null || transaction.amount == null) {
    return "";
  }

  const amount = transaction.isCredit
    ? transaction.amount
    : transaction.amount * -1;
  const amountString = amount.toFixed(2);

  if (isEmpty(transaction.splits)) {
    return amountString;
  }

  return `${amountString}, ${transaction.splits
    .map((s) => {
      const amount = s.isCredit ? s.amount : -s.amount;
      return amount.toFixed(2);
    })
    .join(delimiter)}`;
}

export function formattedAmountSearchAccessor(transaction, delimiter = ", ") {
  if (transaction == null || transaction.amount == null) {
    return "";
  }

  const amount = transaction.isCredit
    ? transaction.amount
    : transaction.amount * -1;
  const amountString = formatCurrency(amount.toFixed(2));

  if (isEmpty(transaction.splits)) {
    return amountString;
  }

  return `${amountString}, ${transaction.splits
    .map((s) => {
      const amount = s.isCredit ? s.amount : -s.amount;
      return formatCurrency(amount.toFixed(2));
    })
    .join(delimiter)}`;
}

export function actionSearchAccessor(transaction) {
  return (transaction && transaction.transactionType) || "";
}

export function empowerActionSearchAccessor(transaction) {
  return transaction?.investmentType ?? transaction?.label ?? "";
}

export function quantitySearchAccessor(transaction) {
  return (transaction && transaction.quantity) || 0;
}

export function formattedQuantitySearchAccessor(transaction) {
  const quantity = transaction?.quantity ?? 0;
  return formatNumber(quantity);
}

export function unformattedPriceSearchAccessor(transaction) {
  return (transaction && transaction.price) || 0;
}
export function formattedPriceSearchAccessor(transaction) {
  return formatCurrency((transaction && transaction.price) || 0);
}
