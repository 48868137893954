var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sidebar__networth-section js-networth-section qa-sidebar-networth\">\n<div class=\"sidebar__header-buttons l-spaced l-spaced--flush\">\n  <h1 class=\"pc-u-mb0 pc-u-mt0\">\n  <a\n    class=\"sidebar__networth-link sidebar__networth-link qa-sidebar-networth-link\"\n    href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"NETWORTH_URL") || (depth0 != null ? lookupProperty(depth0,"NETWORTH_URL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NETWORTH_URL","hash":{},"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":26}}}) : helper)))
    + "\"\n    aria-label=\"Go to Net worth\"\n    >Net Worth</a\n  >\n  </h1>\n  <a\n  href=\"#/accounts/add\"\n  class=\"sidebar__add-account qa-sidebar-add-account "
    + alias4(((helper = (helper = lookupProperty(helpers,"ADD_ACCOUNT_BTN_CLASS") || (depth0 != null ? lookupProperty(depth0,"ADD_ACCOUNT_BTN_CLASS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ADD_ACCOUNT_BTN_CLASS","hash":{},"data":data,"loc":{"start":{"line":13,"column":53},"end":{"line":13,"column":78}}}) : helper)))
    + "\"\n  aria-label=\"Add an account (Opens dialog)\"\n  >\n    <span class=\"u-text-bold\">+</span>\n  </a>\n</div>\n  <span\n    class=\"sidebar__networth-amount qa-sidebar_networth_amount js-sidebar-networth-amount\"\n  ></span>\n  <div class=\"js-sidebar-assets sidebar__assets-bar pc-u-mb--\"></div>\n  <div class=\"js-sidebar-liabilities pc-u-mb--\"></div>\n</div>\n";
},"useData":true});