import React from "react";
import PropTypes from "prop-types";
import FundTransferBox from "../../../empower/components/manageInvestmentFlow/common/FundTransferBox";
import moment from "moment-timezone";
import FundTransferReviewBox from "../../../empower/components/manageInvestmentFlow/common/FundTransferReviewBox";
import { mapFundsTable } from "../../../empower/components/manageInvestmentFlow/utils";
import { getDate } from "../../../empower/components/manageInvestmentFlow/utils";
import CheckMarkIcon from "svg-icons/CheckMark.svg";

const FundToFundRequestDetailPage = ({ transaction = {} }) => {
  const newTransaction = { ...transaction };
  let isCash;
  let sourceFundsTable;
  let destinationFundsTable;
  const momentDate = moment(newTransaction.createdDate)
    .tz("America/Denver")
    .format("dddd, MMMM D, YYYY, h:mm a");

  if (newTransaction.type === "fund_to_fund") {
    /* cspell:disable-next-line */
    isCash = transaction.from?.[0]?.cusip === "CASHUSD00";
    if (isCash) {
      newTransaction.from[0].changePercent =
        newTransaction.from[0].dollarAmountAllocation;
      newTransaction.to?.forEach((ele, i) => {
        newTransaction.to[i].allocationPercent = ele.dollarAmountAllocation;
      });
    }
  } else {
    sourceFundsTable = mapFundsTable(
      newTransaction.from,
      true,
      undefined,
      false
    );
    destinationFundsTable = mapFundsTable(
      newTransaction.to,
      false,
      undefined,
      false
    );
  }

  return (
    <div className="transfer-fund-confirm-changes">
      <div className="l-spaced l-spaced--left">
        <CheckMarkIcon className="transfer-fund-confirm-changes__icon--select" />

        <div className="transfer-fund-desc-box">
          <>
            <span>Your investment mix has been updated as of </span>
            <span className="u-text-bold">
              <time>{getDate(momentDate)}</time> Mountain time.
            </span>
          </>
        </div>
      </div>
      <div className="pc-u-mt-">
        {newTransaction.label === "Fund to fund trade" && (
          <FundTransferBox
            title={`Transfer 1`}
            includeGainLoss={false}
            transferData={newTransaction}
            isCash={isCash}
          />
        )}
        {newTransaction.label === "Rebalance" && (
          <div>
            <FundTransferReviewBox
              title="FROM"
              columns={sourceFundsTable.columns}
              data={sourceFundsTable.data}
            />
            <div className="pc-u-mt+">
              <FundTransferReviewBox
                title="TO"
                columns={destinationFundsTable.columns}
                data={destinationFundsTable.data}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

FundToFundRequestDetailPage.propTypes = {
  transaction: PropTypes.object,
};

FundToFundRequestDetailPage.defaultProps = {
  transaction: undefined,
};

export default FundToFundRequestDetailPage;
