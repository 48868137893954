import React from "react";
import PropTypes from "prop-types";

function DocumentVaultFormsView(props) {
  const { formMessaging } = props;

  return (
    <div className="container-fluid">
      <p>{formMessaging.message1}</p>
      <p
        className="pc-u-mt"
        dangerouslySetInnerHTML={{ __html: formMessaging.message2 }}
      ></p>
      <div className="row ">
        <div className="col-md-3 u-text-bold">
          {formMessaging.column1heading}
        </div>
        <div className="col-md-1 u-text-bold">OR</div>
        <div className="col-md-3 u-text-bold">
          {formMessaging.column2heading}
        </div>
        <div className="col-md-1 u-text-bold">OR</div>
        <div className="col-md-3 u-text-bold">
          {formMessaging.column3heading}
        </div>
      </div>
      <div className="row sidebar__assets-bar">
        <div className="col-md-3">{formMessaging.column1Message}</div>
        <div className="col-md-1"></div>
        <div
          className="col-md-3"
          dangerouslySetInnerHTML={{ __html: formMessaging.column2Message }}
        ></div>
        <div className="col-md-1"></div>
        <div
          className="col-md-3"
          dangerouslySetInnerHTML={{ __html: formMessaging.column3Message }}
        ></div>
      </div>
      <div className="row pc-u-mt">
        <div className="col-md-12">{formMessaging.message3}</div>
      </div>
    </div>
  );
}

DocumentVaultFormsView.propTypes = {
  formMessaging: PropTypes.object.isRequired,
};

export default DocumentVaultFormsView;
