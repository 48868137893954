import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import StockOptionsNav from "./components/StockOptionsNav";
import StockOptionsNav2 from "./components/StockOptionsNav2";
import moment from "moment";
import DateUtil from "libs/pcap/utils/date";
import { debounce, noop } from "underscore";
import GrantsGraph from "./components/GrantsGraph";
import StockOptionsGridContainer from "./stockOptionsGrid/StockOptionsGridContainer";
import { isEmpowerPrivilegedMode } from "../../views/modules/sidebar/utils/accountUtils";

const WATCHER_DELAY_SHORT = 600;

const StockOptions = (props) => {
  const [useWhatIfPrice, setUseWhatIfPrice] = useState(false);
  const [whatIfPrice, setWhatIfPrice] = useState(null);

  const {
    loading,
    errors,
    account,
    grants,
    securityData,
    getGrantHistories,
    ua,
    updatingGraph,
    setUpdatingGraph,
  } = props;
  const accountInfo = grants.reduce(
    (acc, el) => {
      acc.vestedAmount += parseFloat(el.vestedValue);
      acc.totalValue += parseFloat(el.totalValue);
      return acc;
    },
    {
      vestedAmount: 0,
      totalValue: 0,
    }
  );
  const isPrivileged = isEmpowerPrivilegedMode();
  const isPublic = securityData.currentPrice > 0;
  const formatDate = function (str) {
    return str && moment(str).format(DateUtil.DISPLAY_FORMAT);
  };
  const stockPrice = securityData.currentPrice || 0;

  const handleCurrentPriceChange = debounce((target) => {
    const currentPrice = target.value;
    getGrantHistories({ currentPrice });
  }, WATCHER_DELAY_SHORT);

  const handleUseWhatIfPrice = () => {
    const newValue = !useWhatIfPrice;
    if (newValue && whatIfPrice) {
      getGrantHistories({ whatIfPrice });
    } else {
      getGrantHistories({});
    }
    setUseWhatIfPrice(newValue);
  };

  const handleWhatIfPriceChange = debounce((target) => {
    const whatIfPrice = target.value;
    setWhatIfPrice(whatIfPrice);
    if (useWhatIfPrice) {
      getGrantHistories({ whatIfPrice });
    }
  }, WATCHER_DELAY_SHORT);

  return (
    <>
      <Message className="pc-u-mb" severity="error" messages={errors} />
      <LoadingOverlay active={loading} />
      {!loading && (
        <StockOptionsNav
          account={account}
          vestedAmount={accountInfo.vestedAmount}
          isPublic={isPublic}
        />
      )}
      {!loading && (
        <StockOptionsNav2
          totalValue={accountInfo.totalValue}
          closedDate={formatDate(account.closedDate)}
          stockPrice={stockPrice}
          isPublic={isPublic}
          handleCurrentPriceChange={handleCurrentPriceChange}
          handleUseWhatIfPrice={handleUseWhatIfPrice}
          handleWhatIfPriceChange={handleWhatIfPriceChange}
          grants={grants}
          isPrivileged={isPrivileged}
        />
      )}
      <GrantsGraph
        showZeroState={!grants || grants.length === 0}
        loading={loading}
        updatingGraph={updatingGraph}
        grants={grants}
      />
      {!loading && (
        <StockOptionsGridContainer
          userAccountId={ua}
          stockOptionHoldings={grants}
          isEditable={true}
          account={account}
          setUpdatingGraph={setUpdatingGraph}
        />
      )}
    </>
  );
};

StockOptions.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.any,
  account: PropTypes.object,
  grants: PropTypes.array,
  securityData: PropTypes.object,
  getGrantHistories: PropTypes.func,
  ua: PropTypes.string.isRequired,
  updatingGraph: PropTypes.bool,
  setUpdatingGraph: PropTypes.func,
};

StockOptions.defaultProps = {
  loading: false,
  errors: undefined,
  account: {},
  grants: [],
  securityData: {},
  getGrantHistories: undefined,
  setUpdatingGraph: noop,
  updatingGraph: false,
};

export default StockOptions;
