import objectPath from "object-path";

const getAppointmentModalTitle = ({ customContent, isClient } = {}) => {
  return (
    objectPath.get(customContent, "data.modalTitle") ||
    (isClient ? "Schedule Your Call" : "Talk to a financial advisor")
  );
};

export default getAppointmentModalTitle;
