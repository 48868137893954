/* eslint-disable sonarjs/cognitive-complexity */
import React from "react";
import Column from "libs/pcap/table/Column";
import Input from "components/common/form/Input";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import { CategorySelector } from "./CategorySelector";
import InputTagsContainer from "components/common/InputTags/Container";
import TransactionEditRow from "./TransactionEditRow";
import { formatCurrency, formatNegativeNumber } from "libs/pcap/utils/format";
import { clone } from "underscore";
import memoizeOne from "memoize-one";
import TransactionImageUploadModal from "components/TransactionImageUploadModal";
import EditTransactionImageModal from "components/common/EditTransactionImage/Modal";
import ApplyTransactionRuleModal from "./ApplyTransactionRule/ApplyTransactionRuleModal";
import { noop } from "underscore";
import getTransactionEditedMessage from "./getTransactionEditedMessage";
import DatePickerInput from "components/common/form/DatePickerInput";
import { DISPLAY_FORMAT } from "libs/pcap/utils/date2";
import { CURRENCY_FORMAT } from "components/common/form/formattingOptions";

const MAX_TRANSACTION_AMOUNT_DIGITS = 12;
const getSortedCategories = memoizeOne((columns, categories) => {
  const categoryColumn = columns.find((col) => col.header === "Category");
  const categoryComparatorAsc = categoryColumn.comparator(
    (cat) => cat.name,
    "asc"
  );
  return clone(categories).sort(categoryComparatorAsc);
});

export default class TransactionEditRowAccountDetailsBalancesAndIncome extends TransactionEditRow {
  render() {
    const {
      columns,
      onClose,
      schema,
      isPrivileged,
      transactionCategoryRulesFlag,
    } = this.props;
    const {
      originalModel,
      model,
      categories,
      tags,
      loading,
      errors,
      showTransactionImageUploader,
      showEditTransactionImageModal,
      showApplyTransactionRuleModal,
    } = this.state;
    // Make sure we keep `CategorySelector` options sorted as custom categories are added.

    const sortedCategories = getSortedCategories(columns, categories);
    const transactionEditedMessage = getTransactionEditedMessage(
      originalModel,
      categories
    );

    return (
      <>
        <form
          className="table__row--edit pc-bg-dark pc-u-pt- qa-edit-transaction-form"
          onSubmit={isPrivileged ? noop : this.handlePreSubmit}
        >
          <Message className="pc-u-mh- pc-u-mb--" messages={errors} />
          <LoadingOverlay active={loading} />
          <div className="pc-transactions-grid__edit-row-inner">
            <div
              className={`table__column pc-transactions-grid pc-transactions-grid-cell--date`}
            >
              {transactionCategoryRulesFlag ? (
                <DatePickerInput
                  name="transactionDate"
                  className="pc-transaction-grid_transaction-date"
                  ref={this.storeInputRef}
                  validator={DatePickerInput.getValidator({
                    valueDateFormat: DISPLAY_FORMAT,
                    allowEmpty: true,
                    isAllowedDate: this.isValidStartDate,
                  })}
                  displayDateFormat={DISPLAY_FORMAT}
                  onChange={this.handleDateChange}
                  position="top"
                  value={model.transactionDate}
                  isAllowedDate={this.isValidStartDate}
                />
              ) : (
                <label>{model.transactionDate}</label>
              )}
            </div>
            <Column data={model} {...columns[1]} />
            <div
              className={`table__column ${columns[2].className.replace(
                "u-clip-text",
                ""
              )}`}
            >
              <Input
                ref={this.storeInputRef}
                containerClassName={
                  model.originalDescription === model.description
                    ? undefined
                    : "pc-transactions-grid__edit-row-description-input--balances-income qa-edit-row-description-input"
                }
                type="text"
                name="description"
                value={model.description}
                validator={schema.properties.description}
                errorBlockClassName="is-hidden"
                onChange={this.handleInputChange}
                sizeVariation="full"
              />
              {model.originalDescription !== model.description && (
                <InfoTooltipIcon
                  className="icon__help-circled--white pc-u-ml-- qa-transaction-edit-original-description-tooltip"
                  title={`<div>Original description:</div>${model.originalDescription}<div>Double click to use original</div>`}
                  onDoubleClick={this.handleRevertToOriginalDescription}
                />
              )}
            </div>
            <div
              className={`table__column ${columns[3].className} pc-transactions-grid-cell--balance-income-account-details-category--edit`}
            >
              <CategorySelector
                options={sortedCategories}
                name="categoryId"
                onChange={this.handleInputChange}
                onCategoryCreated={this.handleCategoryCreated}
                value={model.categoryId}
                validator={schema.properties.categoryId}
              />
            </div>
            <div className={columns[5].className}></div>
            <div className={columns[6].className}></div>
            {model.isManual ? (
              <>
                <div
                  className={
                    "table__column pc-transactions-grid-cell-edit-transaction-amount pc-transactions-grid-cell--amount-parent qa-edit-transactions-cell--amount tabular-numbers"
                  }
                >
                  <Input
                    id="amount"
                    ref={this.storeInputRef}
                    type="text"
                    value={
                      model.isCredit
                        ? formatCurrency(model.amount)
                        : formatNegativeNumber(model.amount)
                    }
                    name="amount"
                    onChange={this.handleInputChange}
                    prefix="$"
                    formattingOptions={CURRENCY_FORMAT}
                    validator={this.props.schema.properties.amount}
                    ariaLabel="Transaction amount"
                    maxLength={MAX_TRANSACTION_AMOUNT_DIGITS}
                    className={"input--account-details-manual-amount"}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={columns[7].className}></div>
                <Column
                  data={model}
                  {...columns[7]}
                  formatter={(transaction) => {
                    const value = transaction.isCredit
                      ? formatCurrency(transaction.amount)
                      : formatNegativeNumber(transaction.amount);
                    return <span>{value}</span>;
                  }}
                />
              </>
            )}
          </div>
          {transactionCategoryRulesFlag && model?.isModifiedByRule && (
            <div className="pc-transactions-grid__edit-row-inner">
              <div className={`${columns[0].className}`} />
              <div className={`${columns[1].className}`} />
              <div
                className={`${columns[2].className} pc-transactions-grid__rule-applied-message`}
              >
                {transactionEditedMessage}
              </div>
            </div>
          )}
          <div className="pc-transactions-grid__edit-row-inner">
            <div className={`table__column ${columns[0].className}`} />
            <div
              className={`table__column u-text-right pc-u-p0 ${columns[1].className}`}
            >
              Tags
            </div>
            <div className="pc-form-group--small pc-layout pc-layout--middle pc-u-mb0 pc-transactions-grid__edit-row-tags-container">
              <div className="pc-layout__item pc-u-5/9">
                <InputTagsContainer
                  tags={tags}
                  onTagsModified={this.handleTagsChange}
                  value={model.selectedTagIds}
                />
              </div>
            </div>
          </div>
          <div className="pc-layout pc-u-p-">
            {model.isManual ? (
              <div className="pc-layout__item pc-u-1/2" />
            ) : (
              <div className="pc-layout__item pc-u-1/2">
                <label className="pc-label pc-label--inline">
                  <Input
                    ref={this.storeInputRef}
                    type="checkbox"
                    name="isDuplicate"
                    checked={model.isDuplicate}
                    onChange={this.handleInputChange}
                    containerClassName="input--inline-block"
                    disabled={isPrivileged}
                  />
                  Hide this duplicate
                </label>
                <InfoTooltipIcon
                  className="icon__help-circled--white pc-u-ml--"
                  title="Marking a transaction as a duplicate will stop counting that transaction in reporting but display it in the account it came from in case you need to view or unmark it."
                />
              </div>
            )}
            <div className="pc-layout__item pc-u-1/2 pc-layout--right">
              {model.isManual && (
                <button
                  type="button"
                  className="pc-btn pc-btn--danger pc-btn--small js-delete-transaction qa-delete-transaction"
                  onClick={this.handleDeleteTransaction}
                >
                  Delete
                </button>
              )}
              {!model.imageUrl && (
                <button
                  type="button"
                  className={`pc-btn pc-btn--small js-add-transaction-image qa-add-transaction-image ${
                    isPrivileged ? "disabled" : ""
                  }`}
                  onClick={this.handleShowTransactionImageUploader}
                >
                  Add Image
                </button>
              )}
              {model.imageUrl && (
                <button
                  type="button"
                  className={`pc-btn pc-btn--small js-edit-transaction-image qa-edit-transaction-image ${
                    isPrivileged ? "disabled" : ""
                  }`}
                  onClick={this.handleEditImageClick}
                >
                  Edit Image
                </button>
              )}
              <button
                type="button"
                className="pc-btn pc-btn--cancel pc-btn--small qa-close-edit-transaction"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                aria-disabled={isPrivileged}
                type="submit"
                className={`pc-btn pc-btn--small pc-btn--primary qa-save-transaction ${
                  isPrivileged ? "is-disabled" : ""
                }`}
              >
                Save
              </button>
            </div>
          </div>
        </form>
        {showTransactionImageUploader && (
          <TransactionImageUploadModal
            onDone={this.handleCloseTransactionImageUploader}
            userTransactionId={model.userTransactionId}
            title={model.imageUrl ? "Replace Image" : undefined}
            onFileUploaded={this.handleFileUploaded}
          />
        )}
        {showEditTransactionImageModal && (
          <EditTransactionImageModal
            onCancel={this.handleCloseTransactionImageUploader}
            imageUrl={model.imageUrl}
            onDelete={this.handleDeleteTransactionImage}
            onReplace={this.handleShowTransactionImageUploader}
            loading={loading}
            errors={errors}
          />
        )}
        {showApplyTransactionRuleModal && transactionCategoryRulesFlag && (
          <ApplyTransactionRuleModal
            model={model}
            originalModel={originalModel}
            isOpen={showApplyTransactionRuleModal}
            categories={categories}
            handleSubmit={this.handleSubmit}
            handleCloseModal={this.handleCloseModal}
          />
        )}
      </>
    );
  }
}
