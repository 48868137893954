import React, { useState } from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "libs/pcap/utils/format";
import {
  frequencyLabel,
  scheduleLabel,
  deliveryMethodLabel,
} from "../../../../components/transferFunds/utils/labels";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import Modal from "components/modal/Modal";
import AccountNumberFormView from "components/transferFunds/AccountNumberFormView";
import {
  isOnUs,
  isOnUsInvestment,
  isRetirementAccount,
  isBankAccount,
} from "utils/account";
import moment from "moment";
import {
  FREQUENCY_WEEKLY,
  FREQUENCY_MONTHLY,
  FREQUENCY_YEARLY,
} from "components/transferFunds/utils/constants";
import { formatPercent } from "../../../../libs/pcap/utils/format";

// New rows should be added with SimpleRowItem to prevent Cognitive Complexity warnings
// Conversion of older rows dependent on refactoring associated older tests to React Testing Library
const SimpleRowItem = (props) => {
  if (!props.shouldDisplay) return null;

  const { label, labelJSClasses, data, dataJSClasses } = props;
  return (
    <div className="transfer-details-box__item">
      <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
        <span className={`pc-label u-text-bold ${labelJSClasses}`}>
          {label}
        </span>
      </div>
      <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
        <span className={`pc-label ${dataJSClasses}`}>{data}</span>
      </div>
    </div>
  );
};

SimpleRowItem.propTypes = {
  shouldDisplay: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  labelJSClasses: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataJSClasses: PropTypes.string,
};

SimpleRowItem.defaultProps = {
  labelJSClasses: "",
  data: "",
  dataJSClasses: "",
};

const formatTaxWithholdingState = ({
  stateTaxRateType = "PERCENTAGE",
  taxWithholdingState = 0,
}) => {
  let formattedAmount = "";
  if (stateTaxRateType === "PERCENTAGE") {
    formattedAmount = formatPercent(taxWithholdingState);
  } else {
    formattedAmount = formatCurrency(taxWithholdingState);
  }

  return formattedAmount;
};

const TransferDetailsBox = ({
  sourceAccount,
  targetAccount,
  model,
  showViewAccountNumberLink,
}) => {
  const [showAccountDetailsModal, setShowAccountDetailsModal] = useState(false);
  const externalAccount = isOnUs(sourceAccount) ? targetAccount : sourceAccount;
  const shouldDisplayTaxWithholdings =
    !model.id &&
    isOnUsInvestment(sourceAccount) &&
    isRetirementAccount(sourceAccount) &&
    isBankAccount(targetAccount);

  const formatAmountReceived = () => {
    const formatAmount =
      model.stateTaxRateType === "AMOUNT"
        ? model.transferAmount *
            (1 - (model.taxWithholdingFederal || 0) / 100) -
          (model.taxWithholdingState || 0)
        : model.transferAmount *
          (1 -
            ((model.taxWithholdingFederal || 0) / 100 +
              (model.taxWithholdingState || 0) / 100));

    let returnString = formatCurrency(formatAmount, 2);

    if (model.frequency === FREQUENCY_WEEKLY) {
      returnString = returnString.concat(" per week");
      if (model.schedule)
        returnString = returnString.concat(
          ` on ${
            model.schedule.charAt(0) + model.schedule.slice(1).toLowerCase()
          }s`
        );
    }

    if (model.frequency === FREQUENCY_MONTHLY) {
      returnString = returnString.concat(" per month");
      if (model.startDate)
        returnString = returnString.concat(
          ` on the ${moment(model.startDate).format("Do")}`
        );
    }

    if (model.frequency === FREQUENCY_YEARLY) {
      returnString = returnString.concat(" per year");
      if (model.startDate)
        returnString = returnString.concat(
          ` on ${moment(model.startDate).format("M/D")}`
        );
    }

    return returnString;
  };

  const renderViewAccountNumberLink = (type) => {
    const VIEW_ACCOUNT_NUMBER_TEXT = `Use this link to verify the Account and routing number for the selected non Empower Account.`;
    const selectedId = model[`${type}AccountId`];
    if (selectedId && externalAccount?.userAccountId === selectedId) {
      return (
        <div>
          <button
            className={"pc-btn pc-btn--link"}
            type="button"
            onClick={() => {
              setShowAccountDetailsModal(true);
            }}
          >
            View Account / Routing Information
          </button>
          <InfoTooltipIcon title={VIEW_ACCOUNT_NUMBER_TEXT} />
        </div>
      );
    }

    return null;
  };

  const AccountNumberViewModal = () => (
    <Modal
      componentName="accountNumberViewModal"
      title="Account details"
      isOpen={true}
      onClosed={() => {
        setShowAccountDetailsModal(false);
      }}
      className="pc-modal pc-modal--medium"
      shouldCloseOnOverlayClick={false}
      showCloseButton={true}
    >
      <AccountNumberFormView
        firmName={externalAccount.firmName}
        accountName={externalAccount.name}
        logoPath={externalAccount.logoPath}
        balance={externalAccount.balance}
        accountNumber={externalAccount.accountNumber}
        routingNumber={externalAccount.routingNumber}
        onClose={() => setShowAccountDetailsModal(false)}
        isEmpowerTransferReviewModal={true}
      />
    </Modal>
  );

  return (
    <div className="transfer-details-box pc-layout">
      {showAccountDetailsModal && <AccountNumberViewModal />}
      <div className="transfer-details-box__item">
        <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
          <span className="pc-label u-text-bold js-transfer-from-label">
            FROM:
          </span>
        </div>
        <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
          <span className="pc-label u-text-bold js-transfer-from-data">
            {sourceAccount.firmName}
          </span>
          <span className="pc-label transfer-details-box__text-sub">
            {sourceAccount.name}
          </span>
          {showViewAccountNumberLink && (
            <span>{renderViewAccountNumberLink("source")}</span>
          )}
        </div>
      </div>
      <div className="transfer-details-box__item">
        <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
          <span className="pc-label u-text-bold js-transfer-to-label">TO:</span>
        </div>
        <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
          <span className="pc-label u-text-bold  js-transfer-to-data">
            {targetAccount.firmName}
          </span>
          <span className="pc-label transfer-details-box__text-sub">
            {targetAccount.name}
          </span>
          {showViewAccountNumberLink && (
            <span>{renderViewAccountNumberLink("target")}</span>
          )}
        </div>
      </div>
      {model.frequency && (
        <div className="transfer-details-box__item">
          <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
            <span className="pc-label u-text-bold js-transfer-frequency-label">
              FREQUENCY:
            </span>
          </div>
          <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
            <span className="pc-label js-transfer-frequency-data">
              {frequencyLabel(model.frequency)}
            </span>
          </div>
        </div>
      )}
      {model.acatTransferType && (
        <div className="transfer-details-box__item">
          <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
            <span className="pc-label u-text-bold js-transfer-acat-type-label">
              AMOUNT:
            </span>
          </div>
          <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
            <span className="pc-label transfer-details-box__span-acatTransferType js-transfer-acat-type-data">
              {model.acatTransferType.toLowerCase()}
            </span>
          </div>
        </div>
      )}
      {model.startDate && (
        <div className="transfer-details-box__item">
          <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
            <span className="pc-label u-text-bold  js-transfer-start-date-label">
              START DATE:
            </span>
          </div>
          <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
            <span className="pc-label js-transfer-start-date-data">
              {model.startDate}
            </span>
          </div>
        </div>
      )}
      {model.schedule && (
        <div className="transfer-details-box__item">
          <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
            <span className="pc-label u-text-bold js-transfer-schedule-label">
              DAY OF WEEK:
            </span>
          </div>
          <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
            <span className="pc-label js-transfer-schedule-data">
              {scheduleLabel(model.schedule)}
            </span>
          </div>
        </div>
      )}
      {model.transferAmount && (
        <div className="transfer-details-box__item">
          <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
            <span className="pc-label u-text-bold js-transfer-amt-label">
              AMOUNT:
            </span>
          </div>
          <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
            <span className="pc-label--inline  js-transfer-amt-data">
              {formatCurrency(model.transferAmount)}
            </span>
          </div>
        </div>
      )}
      <SimpleRowItem
        shouldDisplay={Boolean(model.contributionYear)}
        label={"CONTRIBUTION YEAR:"}
        data={model.contributionYear}
      />
      {model.acatTransferType === "PARTIAL" && model.instructions && (
        <div className="transfer-details-box__item">
          <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
            <span className="pc-label u-text-bold js-transfer-inst-label">
              INSTRUCTIONS:
            </span>
          </div>
          <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
            <span className="pc-label--inline js-transfer-inst-data">
              {model.instructions}
            </span>
          </div>
        </div>
      )}
      {model.deliveryMethod && (
        <div className="transfer-details-box__item">
          <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
            <span className="pc-label u-text-bold js-transfer-delivery-label">
              DELIVERY METHOD:
            </span>
          </div>
          <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
            <span className="pc-label--inline js-transfer-delivery-data">
              {deliveryMethodLabel(model.deliveryMethod)}
            </span>
          </div>
        </div>
      )}
      {shouldDisplayTaxWithholdings && (
        <>
          <div className="transfer-details-box__item">
            <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
              <span className="pc-label u-text-bold">TAX WITHHOLDING:</span>
            </div>
            <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
              <span className="pc-label">
                Federal {model.taxWithholdingFederal || 0}%
              </span>
              <span className="pc-label">
                State {formatTaxWithholdingState(model)}
              </span>
            </div>
          </div>
          <div className="transfer-details-box__item">
            <div className="transfer-details-box__label pc-layout__item pc-u-2/5">
              <span className="pc-label u-text-bold">AMOUNT YOU RECEIVE:</span>
            </div>
            <div className="transfer-details-box__data pc-layout__item pc-u-3/5">
              <span className="pc-label">{formatAmountReceived()}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

TransferDetailsBox.propTypes = {
  sourceAccount: PropTypes.object.isRequired,
  targetAccount: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  showViewAccountNumberLink: PropTypes.bool,
};

TransferDetailsBox.defaultProps = {
  showViewAccountNumberLink: false,
};

export default TransferDetailsBox;
