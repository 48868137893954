/* eslint-disable jsx-a11y/label-has-associated-control */
// TODO Fix a11y errors in a future ticket
import PropTypes from "prop-types";
import React from "react";
import Message from "components/common/Message";
import AbstractForm from "components/common/form/AbstractForm";
import Input from "components/common/form/Input";
import Select from "components/common/form/Select";
import { CSSTransition } from "react-transition-group";
import FancySelect from "components/common/form/FancySelect/FancySelect";
import Checkbox from "components/common/form/Checkbox";
import marketScenarios from "enums/marketScenarios";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";
import RadioGroup from "components/common/form/RadioGroup";
import AccountsSelector from "components/AccountsSelector/AccountsSelector";
import { isMtrEnrolled, isMtrEligible } from "utils/account";
import {
  RECOMMEND_INTO_GMWB_OPTIONS,
  getValue,
} from "empower/enums/GMWB/GMWBQuestionnaireOptions";

const APPLY_RETURNS_FROM =
  "The recession simulation is calculated using actual market returns of asset classes from the beginning of the selected recessionary period through the recovery period for the years listed.";

const RECOMMENDED_STRATEGY = "RECOMMENDED";

const OPTIONS_VALIDATION_MESSAGE = "Please select an option";

export default class CopyPlanView extends AbstractForm {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMarketScenarioAgeChange =
      this.handleMarketScenarioAgeChange.bind(this);
    this.handleMarketScenarioChange =
      this.handleMarketScenarioChange.bind(this);
    this.validateMaximumMarketScenarioAge =
      this.validateMaximumMarketScenarioAge.bind(this);
    const defaultPlan = props.planOptions && props.planOptions[0].value;
    const mtrAccounts = this.props.accounts.filter(isMtrEligible);

    const model = {
      poorScenario: props.checkPoorMarket,
      myLifePlanId: defaultPlan, // the plan id of the plan that is being copied
      newMyLifePlanName: "",
      marketScenarioStartingAge: "",
      marketScenario: marketScenarios[0].value,
    };
    if (window.isAdvisorApp) {
      model.myLifePlanStatus = "DRAFT";
      model.myLifePlanStrategyName = "";
      model.myLifePlanStrategyScope = "";
      if (props.isGLWB) {
        model.recommendIntoGmwb = "YES";
      }
    }

    this.state = {
      model,
      hasModifiedStrategy: false,
      hasPastMarketReturns: false,
      isSelectingAccounts: false,
      mtrAccounts,
      selectedAccounts: mtrAccounts,
      startingAgeValidator: {
        type: "number",
        allowEmpty: false,
        required: true,
        minimum: this.props.age,
        messages: {
          type: "Please enter a valid number.",
          required: "This field is required.",
          minimum: `Minimum ${this.props.age}`,
        },
      },
    };
  }

  handleSubmit(event) {
    event.preventDefault();

    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.onSave(this.state.model);
    }
  }

  handleHasModifiedStrategyChange(event) {
    const stateUpdate = { hasModifiedStrategy: event.target.checked };

    const { model } = this.state;
    // Clear out any selected values
    if (stateUpdate.hasModifiedStrategy) {
      model.myLifePlanStrategyName = IS_EMPOWER
        ? RECOMMENDED_STRATEGY
        : (this.props.userStrategies.length &&
            this.props.userStrategies[0].name) ||
          "";
      model.myLifePlanStrategyScope = "ALL";
    } else {
      model.myLifePlanStrategyName = "";
      model.myLifePlanStrategyScope = "";
      stateUpdate.model = model;
    }

    if (
      stateUpdate.hasModifiedStrategy === false &&
      this.state.isSelectingAccounts
    ) {
      stateUpdate.isSelectingAccounts = false;
      model.accountsToAdviceByMa = undefined;
    }

    this.setState(stateUpdate);
  }

  validateMaximumMarketScenarioAge(scenario) {
    const { lifeExpectancy } = this.props;
    const { startingAgeValidator } = this.state;
    const marketScenarioDuration = marketScenarios.find(
      (sc) => sc.value === scenario
    ).duration;
    startingAgeValidator.maximum = lifeExpectancy - marketScenarioDuration;
    startingAgeValidator.messages.maximum = `Maximum ${
      lifeExpectancy - marketScenarioDuration
    }`;
    this.setState({ startingAgeValidator });
  }

  handleInputChange(event, value) {
    const inputFieldName = event.target?.name ?? event;
    const newModel = { ...this.state.model };
    let isSelectingAccounts = false;

    if (inputFieldName !== "myLifePlanStrategyScope") {
      return AbstractForm.prototype.handleInputChange.apply(this, arguments);
    }

    if (value === "CUSTOM_SELECTION") {
      isSelectingAccounts = true;
      newModel.accountsToAdviceByMa = `[${this.state.mtrAccounts
        .map((a) => a.userAccountId)
        .join(",")}]`;
    } else if (newModel.accountsToAdviceByMa) {
      delete newModel.accountsToAdviceByMa;
    }

    newModel.myLifePlanStrategyScope = value;
    this.setState({ isSelectingAccounts, model: newModel });
  }

  handleMarketScenarioAgeChange() {
    const { model } = this.state;
    this.validateMaximumMarketScenarioAge(model.marketScenario);
    this.handleInputChange.apply(this, arguments);
  }

  handleMarketScenarioChange(event) {
    this.validateMaximumMarketScenarioAge(event.target.value);
    this.handleInputChange.apply(this, arguments);
  }

  handleDoneSelectingAccounts(selectedAccounts) {
    const newModel = { ...this.state.model };
    const selectedAccountIds = selectedAccounts
      .map((a) => a.userAccountId)
      .join(",");
    newModel.accountsToAdviceByMa = `[${selectedAccountIds}]`;
    this.setState({ selectedAccounts, model: newModel });
  }

  render() {
    const { userStrategies, strategyScopeOptions } = this.props;
    const {
      model,
      hasModifiedStrategy,
      isSelectingAccounts,
      mtrAccounts,
      selectedAccounts,
    } = this.state;

    return (
      <form
        className="retirement-planner__copy-plan"
        onSubmit={this.handleSubmit}
      >
        {this.props.errors && (
          <Message severity="error" messages={this.props.errors} />
        )}
        <div className="pc-u-mt pc-form-group pc-layout">
          <div className="pc-layout__item pc-u-1/2">
            <label className="pc-label u-sentence-case">
              Name
              <Input
                type="text"
                name="newMyLifePlanName"
                placeholder="new plan name"
                ref={this.storeInputRef}
                maxLength={50}
                onChange={this.handleInputChange}
                value={model.name}
                sizeVariation="full"
                validator={this.props.validator.value}
                className="qa-plan-name-input"
              />
            </label>
          </div>
          <div className="pc-layout__item pc-u-1/2">
            <label className="pc-label u-sentence-case">
              Copied From
              <Select
                name="myLifePlanId"
                className="input input--full qa-plan-list"
                onChange={this.handleInputChange}
                options={this.props.planOptions}
                ref={this.storeInputRef}
                value={model.copiedFrom}
              />
            </label>
          </div>
        </div>
        {window.isAdvisorApp && (
          <div>
            <label className="pc-label pc-u-mb--">
              <input
                type="checkbox"
                checked={this.state.hasModifiedStrategy}
                onChange={this.handleHasModifiedStrategyChange.bind(this)}
                className="js-model-change-checkbox qa-model-change-checkbox"
              />
              <span className="pc-label--inline u-sentence-case">
                Model Strategy Change
              </span>
            </label>
            <CSSTransition
              in={hasModifiedStrategy}
              classNames="transition-model-strategy-change-section"
              unmountOnExit={true}
              timeout={250}
            >
              <div>
                <div>
                  <label className="pc-label">
                    <span className="pc-label u-sentence-case">
                      Strategy Risk and Return
                    </span>
                    <Select
                      name="myLifePlanStrategyName"
                      className="input input--full qa-plan-strategy-name js-strategy"
                      onChange={this.handleInputChange}
                      options={this.props.strategyOptions}
                      ref={this.storeInputRef}
                      value={
                        IS_EMPOWER
                          ? model.myLifePlanStrategyName || RECOMMENDED_STRATEGY
                          : model.myLifePlanStrategyName
                      }
                    />
                  </label>
                </div>
                {!IS_EMPOWER && (
                  <div className="js-current-strategy copy-plan__strategy-list">
                    {userStrategies.map((strategy, i) => {
                      return (
                        <div
                          key={strategy.name}
                          className="copy-plan-modal__strategy-list-row"
                        >
                          <div className="copy-plan__strategy-list-cell u-sentence-case">
                            {i === 0 ? "Current Strategy:" : ""}
                          </div>
                          <div className="copy-plan__strategy-list-cell">
                            {strategy.label}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="js-apply-strategy-to pc-u-mv">
                  <label className="pc-label u-sentence-case">
                    Apply Strategy To
                    <Select
                      name="myLifePlanStrategyScope"
                      className="input input--full qa-plan-strategy-scope"
                      onChange={this.handleInputChange}
                      options={strategyScopeOptions}
                      ref={this.storeInputRef}
                      value={model.myLifePlanStrategyScope}
                    />
                  </label>
                </div>
                <div className="copy-plan__account-selector-container">
                  {isSelectingAccounts && (
                    <>
                      <label className="pc-label u-sentence-case">
                        Select Account(s)
                      </label>
                      <AccountsSelector
                        accounts={mtrAccounts}
                        selectedAccounts={selectedAccounts}
                        onDone={this.handleDoneSelectingAccounts.bind(this)}
                        menuPlacement="top"
                        getIsAccountDisabled={isMtrEnrolled}
                        showSelectAll={false}
                        selectClassName="copy-plan__account-selector Select Select-multiple"
                      />
                    </>
                  )}
                </div>
              </div>
            </CSSTransition>
          </div>
        )}
        <div className="pc-u-mt pc-form-group pc-layout">
          <div className="pc-layout__item pc-u-mb-">
            <Checkbox
              ref={this.storeInputRef}
              name="poorScenario"
              value={true}
              className="js-simulate-past-market-returns"
              checkboxClassName="qa-simulate-past-market-returns"
              label="Simulate Past Recessions"
              labelTextClassName="pc-label--inline u-sentence-case"
              checked={model.poorScenario}
              onChange={this.handleInputChange}
            />
          </div>
          {model.poorScenario && (
            <div className="js-past-market-returns">
              <div className="pc-layout__item pc-u-1/2">
                <label className="pc-label u-sentence-case">
                  Starting From Age
                  <Input
                    type="text"
                    name="marketScenarioStartingAge"
                    className="js-market-scenario-starting-age qa-market-scenario-starting-age"
                    placeholder="When you apply"
                    ref={this.storeInputRef}
                    maxLength={3}
                    onChange={this.handleMarketScenarioAgeChange}
                    value={model.age}
                    sizeVariation="full"
                    validator={this.state.startingAgeValidator}
                  />
                </label>
              </div>
              <div className="pc-layout__item pc-u-1/2">
                <label className="pc-label u-sentence-case">
                  Apply Returns From
                  <InfoTooltipIcon title={APPLY_RETURNS_FROM} parentClassName='retirement-planner__copy-plan'/>
                  <FancySelect
                    ref={this.storeInputRef}
                    name="marketScenario"
                    className="js-market-scenario qa-market-scenario"
                    onChange={this.handleMarketScenarioChange}
                    isSearchable={false}
                    options={marketScenarios}
                    value={model.marketScenario}
                  />
                </label>
              </div>
            </div>
          )}
        </div>
        {IS_EMPOWER && window.isAdvisorApp && this.props.isGLWB && (
          <div>
            <h2 className="pc-label pc-form-group--small u-preserve-case">
              GLWB
            </h2>
            <RadioGroup
              ref={this.storeInputRef}
              className="pc-form-group js-recommend-into-gmwb-radio-group "
              labelClassName="u-preserve-case pc-u-mb-"
              name="recommendIntoGmwb"
              value={model.recommendIntoGmwb}
              validator={{
                type: "string",
                enum: RECOMMEND_INTO_GMWB_OPTIONS.map(getValue),
                required: true,
                allowEmpty: false,
                message: OPTIONS_VALIDATION_MESSAGE,
              }}
              options={RECOMMEND_INTO_GMWB_OPTIONS}
              onChange={this.handleInputChange}
              shouldFormatLabel={false}
            />
          </div>
        )}
        <div className="u-text-right">
          <button
            type="button"
            className="pc-btn js-cancel-btn"
            onClick={this.props.onClosed}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="pc-btn pc-btn--primary qa-new-scenario-done-btn"
          >
            Done
          </button>
        </div>
      </form>
    );
  }
}

CopyPlanView.propTypes = {
  checkPoorMarket: PropTypes.bool,
  onClosed: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  planOptions: PropTypes.array,
  strategyScopeOptions: PropTypes.array,
  strategyOptions: PropTypes.array,
  userStrategies: PropTypes.array,
  isGLWB: PropTypes.bool,
  validator: PropTypes.object,
  accounts: PropTypes.array,
};

CopyPlanView.defaultProps = {
  checkPoorMarket: false,
  validator: {
    type: "object",
    value: {
      type: "string",
      allowEmpty: false,
      required: true,
    },
    copiedFrom: {
      value: {
        type: "string",
        allowEmpty: false,
      },
    },
  },
  strategyOptions: [],
  strategyScopeOptions: [],
  userStrategies: [],
  isGLWB: false,
  accounts: [],
};
