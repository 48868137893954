import PropTypes from "prop-types";
import React from "react";
import BasicInsight from "components/opportunitiesSummary/BasicInsight";
import TaxLossHarvesting from "components/opportunitiesSummary/TaxLossHarvesting";
import RetirementFeeAnalyzer from "components/opportunitiesSummary/RetirementFeeAnalyzer";
import InvestibleCash from "components/opportunitiesSummary/InvestibleCash";
import MyStrategy from "components/opportunitiesSummary/MyStrategy";
import Scorecard from "components/opportunitiesSummary/Scorecard";
import PortfolioReview from "components/opportunitiesSummary/PortfolioReview";
import InvestmentCheckupContainer from "components/opportunitiesSummary/InvestmentCheckup/InvestmentCheckupContainer";
import SingleStockConcentration from "components/opportunitiesSummary/SingleStockConcentration";
import TacticalWeighting from "components/opportunitiesSummary/TacticalWeighting";
import RetirementPlanReview from "components/opportunitiesSummary/RetirementPlanReview";
import RetirementPlanForecast from "components/opportunitiesSummary/RetirementPlanForecast";
import RetirementPlanSpending from "components/opportunitiesSummary/RetirementPlanSpending";
import Projection from "components/opportunitiesSummary/Projection";
import { noop } from "underscore";
import StandardEmpowerInsight from "./EmpowerInsights/StandardEmpowerInsight";
import ManualAccountRecaptureWelcome from "components/opportunitiesSummary/ManualAccountRecaptureWelcome";

const DEFAULT_WIDGET = BasicInsight;

export const messageTemplateToWidgetMap = {
  TAX_LOSS_HARVESTING: TaxLossHarvesting,
  FEE_ANALYZER: RetirementFeeAnalyzer,
  INVESTIBLE_CASH_INSIGHT: InvestibleCash,
  INVESTIBLE_CASH_INSIGHT_2: InvestibleCash,
  MY_STRATEGY_INSIGHT: MyStrategy,
  INVESTMENT_CHECKUP_INSIGHT: InvestmentCheckupContainer, // Advice removed from PROD
  NON_CLIENT_SCORECARD_INSIGHT: Scorecard,
  PORTFOLIO_REVIEW_INSIGHT: PortfolioReview,
  SINGLE_STOCK_INSIGHT: SingleStockConcentration,
  TACTICAL_WEIGHTING_OPPORTUNITY: TacticalWeighting,
  RETIREMENT_PLAN_REVIEW_INSIGHT: RetirementPlanReview,
  RETIREMENT_PLAN_FORECAST_INSIGHT: RetirementPlanForecast,
  RETIREMENT_PLAN_SPENDING_INSIGHT: RetirementPlanSpending,
  PROJECTION_INSIGHT: Projection,
  MANUAL_ACCOUNT_RECAPTURE_WELCOME: ManualAccountRecaptureWelcome,
};

export const getWidgetByMessageTemplate = (messageTemplate, viewTemplate) => {
  if (
    messageTemplate?.includes("EMPOWER_INSIGHT_") ||
    viewTemplate === "EMPOWER_DEFAULT_INSIGHT"
  ) {
    return StandardEmpowerInsight;
  }

  return messageTemplateToWidgetMap[messageTemplate] || DEFAULT_WIDGET;
};

export default class InsightFactory extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.props.handleWidgetException(this.props.message, error, info);
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    const {
      message,
      markAsViewed,
      markAsImpressed,
      isFirstRendered,
      isAddFundsEligible,
    } = this.props;
    const InsightWidget = getWidgetByMessageTemplate(
      message.template,
      message.viewTemplate
    );
    return (
      <InsightWidget
        message={message}
        markAsViewed={markAsViewed}
        markAsImpressed={markAsImpressed}
        isFirstRendered={isFirstRendered}
        isAddFundsEligible={isAddFundsEligible}
      />
    );
  }
}

InsightFactory.propTypes = {
  message: PropTypes.object,
  markAsViewed: PropTypes.func,
  markAsImpressed: PropTypes.func,
  handleWidgetException: PropTypes.func,
  isFirstRendered: PropTypes.bool,
  isAddFundsEligible: PropTypes.bool,
};
InsightFactory.defaultProps = {
  message: undefined,
  markAsViewed: noop,
  markAsImpressed: noop,
  handleWidgetException: noop,
  isFirstRendered: false,
  isAddFundsEligible: undefined,
};
