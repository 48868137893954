import _ from "underscore";
import deepCopy from "deep-copy";

function addProductToChildren(parent) {
  parent.children = parent.children.map((c) => {
    if (!c.parent) {
      c.product = parent.product ?? parent.value;
      if (c.children) {
        c = addProductToChildren(c);
      }
    }
    return c;
  });
  return parent;
}

/**
 * Returns an array of objects assembled from `children`
 * properties of the `parent` array elements.
 * @param     {Array} parents   The array of parent elements.
 * @returns   {Array}           The array of children.
 */
export function getChildren(parents) {
  return _.chain(deepCopy(parents))
    .map(addProductToChildren)
    .pluck("children")
    .flatten()
    .sortBy("order")
    .value();
}

/**
 * Converts the array of parent objects into a map where the keys are
 * parent object's `value` and the values are `children` array.
 *
 * @param  {Array} parents           The array of objects.
 * @return {Object}                  The mapping.
 */
export function mapChildrenByParentValue(parents) {
  const accountTypesByGroup = {};
  const uniqueParents = _.chain(parents).uniq("value").pluck("value").value();

  _.each(uniqueParents, function (parentName) {
    const groupAccountTypes = _.chain(parents)
      .filter(function (accountGroup) {
        return accountGroup.value === parentName;
      })
      .pluck("children")
      .compact()
      .flatten()
      .uniq("value")
      .sortBy("order")
      .value();

    accountTypesByGroup[parentName] = groupAccountTypes;
  });

  return accountTypesByGroup;
}

function getChildrenDifference(children, newChildren) {
  return children.children.filter(
    (c) => !newChildren.children.some((nc) => nc.value === c.value)
  );
}

function mapAccountGroups(allAccountGroups) {
  const accountGroups = [];
  const groupsMapped = new Set();
  allAccountGroups.forEach((accountGroup) => {
    if (!groupsMapped.has(accountGroup.value)) {
      const groups = allAccountGroups.filter(
        (g) => g.value === accountGroup.value
      );
      const groupsMerged = groups.reduce((acc, next) => {
        delete next.product;
        if (acc?.children) {
          const children = getChildrenDifference(acc, next);
          if (children) {
            next.children = next.children.concat(children);
          }
        }
        return next;
      }, {});
      accountGroups.push(groupsMerged);
      groupsMapped.add(accountGroup.value);
    }
  });

  return accountGroups;
}

export function filterAccountTypes(accountTypes) {
  const productTypes = accountTypes.filter(({ value }) =>
    ["BANK", "INVESTMENT"].includes(value)
  );

  const allAccountGroups = getChildren(productTypes);
  return mapAccountGroups(allAccountGroups);
}

export function filterAccountTypesForAccount(accountTypes, account) {
  const productTypes = accountTypes.filter(
    ({ value }) => account.productType === value
  );

  const allAccountGroups = getChildren(productTypes);
  return mapAccountGroups(allAccountGroups);
}
