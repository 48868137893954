import _ from "underscore";
import BaseStateModel from "stateModels/baseStateModel";
function ProfileState() {
  this.baseUrl = "/profile";
}

ProfileState.prototype = new BaseStateModel();
ProfileState.prototype.constructor = ProfileState;

export default ProfileState;
