import PropTypes from "prop-types";
import React from "react";
import PostLoginActionsProvider from "../PostLoginActionsProvider";
import { getPostLoginActionChart } from "views/components/postLoginActions/helpers";

const DEFAULT_IMAGE_WIDTH = 250;

const TextWithImgWidePLA = (props) => {
  return (
    <PostLoginActionsProvider {...props}>
      {({ getButtons, imageSource, imageWidth, imageStyle, userMessage }) => (
        <div>
          <div className="text-with-image__container">
            <div className="text-with-image__content">
              <h1
                className="text-with-image__title"
                dangerouslySetInnerHTML={{ __html: userMessage.title }}
              />
              <div
                className="pc-u-mb"
                dangerouslySetInnerHTML={{ __html: userMessage.summary }}
              />
              {getButtons({
                getButtonClassName: () =>
                  "simple-image__cta pc-btn pc-btn--small pc-btn--primary",
              })}
            </div>
            <div>
              {Boolean(imageSource) && (
                <img
                  width={imageWidth ?? DEFAULT_IMAGE_WIDTH}
                  style={imageStyle ? imageStyle : {}}
                  src={imageSource}
                  alt=""
                />
              )}
              {!imageSource && getPostLoginActionChart(userMessage)}
            </div>
          </div>
        </div>
      )}
    </PostLoginActionsProvider>
  );
};

TextWithImgWidePLA.propTypes = {
  componentName: PropTypes.string,
  userMessage: PropTypes.object,
  onRequestClose: PropTypes.func,
  onTitleChange: PropTypes.func,
};

TextWithImgWidePLA.defaultProps = {
  componentName: undefined,
  userMessage: undefined,
  onRequestClose: undefined,
  onTitleChange: undefined,
};

export default TextWithImgWidePLA;
