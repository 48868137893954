import React from "react";
import PropTypes from "prop-types";
import FundAllocationComparison from "components/FundAllocationComparison";
import FundAllocationTable from "components/FundAllocationTable/FundAllocationTable";
import { formatPercent } from "libs/pcap/utils/format";
import PieChart from "components/common/charts/PieChart";
import Table from "libs/pcap/table/Table";
import ExportCsvButton from "components/common/ExportCsvButton/ExportCsvButton";
import Modal from "components/modal/Modal";
import {
  STAGES,
  RECOMMENDATION_PIE_PROPS,
  prepareDataForSectorPieChart,
  prepareDataForSectorTable,
  prepareDataForHoldingsTable,
  SECTOR_ALLOCATION_TABLE_COLUMNS,
  HOLDING_TABLE_COLUMNS,
  getSectorArcClasses,
  prepareDataForHoldingsPieChart,
} from "components/AccountDetails/Recommendation401k/Recommendation401kUtils";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const ClientRecommendation401kView = ({
  plannerStage,
  userHoldings,
  recommendedHoldings,
  hasSignificantDiffHoldings,
  title,
  takeaway,
  note,
  action,
  allocationSet,
  onDisclaimerClick,
  onDisclaimerClose,
  isDisclaimerModalOpen,
}) => {
  let hasSplitTakeaway = false,
    userPieData = [],
    targetPieData = [],
    tableData = [],
    tableColumns,
    arcClassFn,
    pieClassName,
    // Manual investment accounts have an empty cash holding by default, make sure the account has at least one non-empty holding
    hasUserHoldings =
      userHoldings &&
      userHoldings.length > 0 &&
      userHoldings.some((uh) => !(uh.description === "Cash" && !uh.value)),
    hasTable = false,
    hasFundComparison = false,
    hasSingleFundListing = false;
  if (
    (plannerStage === STAGES.FUND_OPTIONS_NOT_AVAILABLE ||
      plannerStage === STAGES.FUND_RECOMMENDATION_IN_REVIEW) &&
    hasUserHoldings
  ) {
    hasSplitTakeaway = hasTable = true;
    userPieData =
      prepareDataForSectorPieChart(allocationSet, "userAllocation") || [];
    targetPieData =
      prepareDataForSectorPieChart(allocationSet, "targetAllocation") || [];
    tableColumns = SECTOR_ALLOCATION_TABLE_COLUMNS;
    tableData = prepareDataForSectorTable(allocationSet);
    arcClassFn = getSectorArcClasses;
    pieClassName = RECOMMENDATION_PIE_PROPS.chartClassName;
  } else if (plannerStage === STAGES.FUND_RECOMMENDATION_REVIEWED) {
    if (hasUserHoldings) {
      if (hasSignificantDiffHoldings) {
        hasFundComparison = true;
      } else {
        // Fewer than four differences
        hasSplitTakeaway = true;
        hasTable = true;
        userPieData = prepareDataForHoldingsPieChart(userHoldings) || [];
        targetPieData =
          prepareDataForHoldingsPieChart(recommendedHoldings) || [];
        tableData = prepareDataForHoldingsTable(
          userHoldings,
          recommendedHoldings
        );
        tableColumns = HOLDING_TABLE_COLUMNS;
        pieClassName = "recommendation-401k__stock-pie-chart";
      }
    } else {
      // User holdings not defined, just show one table with recommendation
      hasFundComparison = false;
      hasSingleFundListing = true;
    }
  }

  const organizationName = "Empower";

  return (
    <div className="pc-u-pt- pc-u-ph-- qa-client-rec-view">
      <div className="pc-layout">
        <div
          className={`pc-layout__item ${
            hasSplitTakeaway ? "pc-u-1/2" : "pc-u-1/1"
          }`}
        >
          <div
            className="pc-beta pc-u-mb- js-rec-title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <div
            className="pc-delta pc-u-mb-- js-rec-takeaway"
            dangerouslySetInnerHTML={{ __html: takeaway }}
          ></div>
          <div className="pc-delta pc-u-mb- js-rec-note">{note}</div>
          {action &&
            action.length > 0 &&
            (action.length === 2 ? (
              <div className="pc-u-mv qa-rec-actions">
                <a href={action[0].url} className="pc-btn pc-btn--primary">
                  {action[0].label}
                </a>
                <a href={action[1].url} className="pc-btn">
                  {action[1].label}
                </a>
              </div>
            ) : (
              <div className="pc-u-mv">
                <a href={action[0].url} className="pc-btn pc-btn--primary">
                  {action[0].label}
                </a>
              </div>
            ))}
        </div>
        {hasSplitTakeaway && (
          <>
            <div className="pc-layout__item pc-u-1/4 u-text-center">
              <PieChart
                {...RECOMMENDATION_PIE_PROPS}
                datum={userPieData}
                getArcClasses={arcClassFn}
                chartClassName={pieClassName}
              />
              <label>Current Allocation</label>
            </div>
            <div className="pc-layout__item pc-u-1/4 u-text-center">
              <PieChart
                {...RECOMMENDATION_PIE_PROPS}
                datum={targetPieData}
                getArcClasses={arcClassFn}
                chartClassName={pieClassName}
              />
              <label>Alternative allocation</label>
            </div>
          </>
        )}
      </div>
      {hasTable && (
        <div className="pc-u-mt">
          <Table
            columns={tableColumns}
            data={tableData}
            headerRowClassName="recommendation-401k-header-cell"
          />
        </div>
      )}
      {hasFundComparison && (
        <FundAllocationComparison
          currentAllocation={userHoldings}
          currentAllocationHeaders={["Current Funds", "Allocation"]}
          newAllocation={recommendedHoldings}
          newAllocationHeaders={["Recommended Funds", "Allocation"]}
          accessors={[(d) => d, (d) => d.value]}
          formatters={[
            function holdingFormatter(v) {
              return (
                <>
                  <div className="u-text-bold">{v.name}</div>
                  <div className="milli">{v.description}</div>
                </>
              );
            },
            (v) => formatPercent(v, { precision: 1 }),
          ]}
        />
      )}
      {hasSingleFundListing && (
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/2">
            <FundAllocationTable
              data={recommendedHoldings}
              accessors={[(d) => d, (d) => d.value]}
              formatters={[
                function holdingFormatter(v) {
                  return (
                    <>
                      <div className="u-text-bold">{v.name}</div>
                      <div className="milli">{v.description}</div>
                    </>
                  );
                },
                (v) => formatPercent(v, { precision: 1 }),
              ]}
            />
          </div>
        </div>
      )}
      {plannerStage === STAGES.FUND_RECOMMENDATION_REVIEWED && (
        <div
          className={`pc-u-mt- ${hasSingleFundListing ? "" : "u-text-right"}`}
        >
          <ExportCsvButton
            headers={["Ticker", "Fund Name", "Allocation %"]}
            fileNameBase="PersonalCapital_Recommendation"
            fileNameTimestampFormat=""
            data={recommendedHoldings.map((rh) => [
              rh.name,
              rh.description,
              rh.value,
            ])}
            footerText={`The fund allocations provided to you for your retirement assets are subject
            to the rules and regulations of the Employee Retirement Income Security Act of 1974 (ERISA).
            Any information provided to you for these assets represents a courtesy extended to you and
            is not meant to imply ${organizationName} manages these assets or is responsible in
            any way for your investment selection. With regard to these assets, ${organizationName} is not responsible for ongoing rebalancing, subsequent
            notification to you regarding changes in our opinions, or periodic monitoring. The available
            fund choices you provided to us form the basis for our preferred allocation. ${organizationName} does not verify the completeness or accuracy of the fund choices provided. The
            investment choices shown could lose value and are not guaranteed to perform similarly to any
            other strategy. While we generally prefer lower cost funds, the selections may not represent
            the lowest cost option. The selections were made based on information available at the time of
            the recommendation and we will not provide updates or ongoing advice for these allocations
            (other than upon request). Past performance is not a guarantee of future return, nor is it
            necessarily indicative of future performance. All investments involve risk, including the
            potential for loss of principal.`}
            isPrivileged={isEmpowerPrivilegedMode()}
          />
        </div>
      )}
      <div className="u-text-right pc-u-mt">
        <button
          type="button"
          className="centi pc-btn--link"
          onClick={onDisclaimerClick}
        >
          Disclaimer
        </button>
      </div>
      {isDisclaimerModalOpen && (
        <Modal
          componentName="Client401kRecommendationDisclaimer"
          contentLabel="Disclaimer"
          title="Disclaimer"
          isOpen={true}
          onClosed={onDisclaimerClose}
          className="pc-modal"
        >
          <div className="centi">
            The fund allocations provided to you for your retirement assets are
            subject to the rules and regulations of the Employee Retirement
            Income Security Act of 1974 (ERISA). Any information provided to you
            for these assets represents a courtesy extended to you and is not
            meant to imply {organizationName} manages these assets or is
            responsible in any way for your investment selection. With regard to
            these assets, {organizationName} is not responsible for ongoing
            rebalancing, subsequent notification to you regarding changes in our
            opinions, or periodic monitoring. The available fund choices you
            provided to us form the basis for our preferred allocation. Personal
            Capital Advisors does not verify the completeness or accuracy of the
            fund choices provided. The investment choices shown could lose value
            and are not guaranteed to perform similarly to any other strategy.
            While we generally prefer lower cost funds, the selections may not
            represent the lowest cost option. The selections were made based on
            information available at the time of the recommendation and we will
            not provide updates or ongoing advice for these allocations (other
            than upon request). Past performance is not a guarantee of future
            return, nor is it necessarily indicative of future performance. All
            investments involve risk, including the potential for loss of
            principal.
          </div>
        </Modal>
      )}
    </div>
  );
};

ClientRecommendation401kView.propTypes = {
  plannerStage: PropTypes.string.isRequired,
  allocationSet: PropTypes.object,
  hasSignificantDiffHoldings: PropTypes.bool,
  userHoldings: PropTypes.array,
  recommendedHoldings: PropTypes.array,
  title: PropTypes.string,
  takeaway: PropTypes.string,
  note: PropTypes.string,
  action: PropTypes.array,
  isDisclaimerModalOpen: PropTypes.bool,
  onDisclaimerClick: PropTypes.func.isRequired,
  onDisclaimerClose: PropTypes.func.isRequired,
};

ClientRecommendation401kView.defaultProps = {
  userHoldings: [],
  recommendedHoldings: [],
  allocationSet: {},
  hasSignificantDiffHoldings: false,
  title: "",
  takeaway: "",
  note: "",
  action: undefined,
  isDisclaimerModalOpen: false,
};

export default ClientRecommendation401kView;
