var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"l-spaced l-spaced--flush l-spaced--top pc-u-mb-\">\n  <div>Minimum Due</div>\n  <div class=\"account-details-bill-form__value-column\">\n    <strong>"
    + alias2(__default(require("../helpers/dollarAndCentsAmount.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"minAmountDue") : depth0),true,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":54}}}))
    + "</strong>\n    <div class=\"account-details-bill-form__value-secondary\">"
    + alias2(__default(require("../helpers/mmDDYYString.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"dueDate") : depth0),{"name":"mmDDYYString","hash":{},"data":data,"loc":{"start":{"line":6,"column":60},"end":{"line":6,"column":84}}}))
    + "</div>\n  </div>\n</div>\n<div class=\"l-spaced l-spaced--flush l-spaced--top pc-u-mb-\">\n  <div>\n    <div>Statement Balance</div>\n    <div class=\"account-details-bill-form__value-secondary\"><a href=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"loginUrl") : depth0), depth0))
    + "\" target=\"_blank\" class=\"nonCloseElement\" data-hj-masked>Go to Website</a></div>\n  </div>\n  <strong class=\"account-details-bill-form__value-column\">"
    + alias2(__default(require("../helpers/dollarAndCentsAmount.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"statementBalance") : depth0),true,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":14,"column":58},"end":{"line":14,"column":104}}}))
    + "</strong>\n</div>\n<div class=\"l-spaced l-spaced--flush l-spaced--top pc-u-mb-\">\n  <div>Last Payment</div>\n  <div class=\"account-details-bill-form__value-column\">\n    <strong>"
    + alias2(__default(require("../helpers/dollarAndCentsAmount.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"lastPaymentAmount") : depth0),true,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":59}}}))
    + "</strong>\n    <div class=\"account-details-bill-form__value-secondary\">"
    + alias2(__default(require("../helpers/mmDDYYString.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"lastPaymentDate") : depth0),{"name":"mmDDYYString","hash":{},"data":data,"loc":{"start":{"line":20,"column":60},"end":{"line":20,"column":92}}}))
    + "</div>\n  </div>\n</div>\n<div class=\"u-text-right\">\n"
    + ((stack1 = __default(require("../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"status") : depth0),"==","NOT_PAID",{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":2},"end":{"line":30,"column":14}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPrivileged") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":29,"column":11}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "    <button type=\"button\" class=\"pc-btn pc-btn--primary pc-btn--small js-action-mark-as-paid\" disabled>Mark As Paid</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <button type=\"button\" class=\"pc-btn pc-btn--primary pc-btn--small js-action-mark-as-paid\">Mark As Paid</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "  No bills due at this time.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"status") : depth0),"!=","NO_BILL",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":32,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"status") : depth0),"==","NO_BILL",{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":0},"end":{"line":35,"column":12}}})) != null ? stack1 : "");
},"useData":true});