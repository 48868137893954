const HASH_FIRST_USE = "#/firstuse";

export default function getPreviousRouteHash({
  stepsToGoBack = 1,
  hashesToReplaceWithDashboard = [],
} = {}) {
  // If previous hash is first use, default to dashboard
  hashesToReplaceWithDashboard.push(HASH_FIRST_USE);

  const history = window.AppRouter.history,
    previousRoute = history[history.length - stepsToGoBack],
    replaceWithDashboard =
      !previousRoute ||
      hashesToReplaceWithDashboard.find((hash) =>
        previousRoute.hash.includes(hash)
      );

  return replaceWithDashboard ? DASHBOARD_URL : previousRoute.hash;
}
