import PropTypes from "prop-types";
import React from "react";
import mixpanel from "mixpanel";
import ChartReact from "libs/pcap/chart/chart--react";
import { format, select, selectAll, curveMonotoneX } from "d3";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { circleRight, defaultSubject } from "common/annotationTypes";
import { annotation } from "d3-svg-annotation";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import { getEventData } from "components/opportunitiesSummary/InsightsUtils";

import _ from "underscore";
const CHART_OFFSET = 7;
const tinyMargin = 6;

function xAccessor(item) {
  return item.x;
}

function yAccessor(item) {
  return item.y;
}

class TacticalWeighting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gainStatement:
        this.props.message.resources[0].jsonContent.gainStatement || "",
      withTacticalWeighting:
        this.props.message.resources[0].jsonContent.chartData.chartData[1]
          .data || [],
      withoutTacticalWeighting:
        this.props.message.resources[0].jsonContent.chartData.chartData[0]
          .data || [],
    };
  }

  componentDidMount() {
    this.renderAnnotations();

    mixpanel.trackEvent(
      `View Insight`,
      getEventData({ message: this.props.message })
    );

    if (this.props.isFirstRendered) {
      this.props.markAsImpressed(this.props.message.userMessageId);
    } else {
      this.props.markAsViewed(this.props.message.userMessageId);
    }
  }

  renderAnnotations() {
    const chart = this.chartReact.chart;

    const annotations = [
      {
        type: circleRight,
        className:
          "pc-annotation--hoverable pc-annotation--neutral js-tactical-weighting js-tactical-weighting--base",
        note: {
          label: "$" + format(".3s")(this.state.withTacticalWeighting[0].y),
          wrap: 50,
          padding: 0,
        },
        x: chart.options.xScale(this.state.withTacticalWeighting[0].x),
        y: chart.options.yScale(this.state.withTacticalWeighting[0].y),
        dy: -6,
        dx: -6,
        subject: defaultSubject,
      },
      {
        type: circleRight,
        className:
          "pc-annotation--hoverable pc-annotation--primary js-tactical-weighting js-tactical-weighting--invested",
        note: {
          label:
            "$" + format(".3s")(_.last(this.state.withTacticalWeighting).y),
          wrap: 50,
          padding: 0,
        },
        x: chart.options.xScale(_.last(this.state.withTacticalWeighting).x),
        y: chart.options.yScale(_.last(this.state.withTacticalWeighting).y),
        dy: -1,
        dx: 2,
        subject: defaultSubject,
      },
      {
        type: circleRight,
        className:
          "pc-annotation--hoverable pc-annotation--neutral js-tactical-weighting js-tactical-weighting--sp",
        note: {
          label:
            "$" + format(".3s")(_.last(this.state.withoutTacticalWeighting).y),
          wrap: 50,
          padding: 0,
        },
        x: chart.options.xScale(_.last(this.state.withoutTacticalWeighting).x),
        y: chart.options.yScale(_.last(this.state.withoutTacticalWeighting).y),
        dy: -1,
        dx: 2,
        subject: defaultSubject,
      },
    ];

    const makeAnnotations = annotation().annotations(annotations);

    select(".insights-graph--tactical-weighting .js-chart-body")
      .append("g")
      .attr("class", "annotation-group")
      .call(makeAnnotations);
    selectAll(".annotation-note-title").attr("font-size", "10");
    makeAnnotations.updateText();

    selectAll(".annotation-note-content").each(function () {
      const specs = this.getBBox(); // eslint-disable-line no-invalid-this
      select(this)
        .append("line") // eslint-disable-line no-invalid-this
        .attr("x1", 1)
        .attr("x2", specs.width - 1)
        .attr("y1", specs.y + specs.height - tinyMargin)
        .attr("y2", specs.y + specs.height - tinyMargin)
        .attr("stroke-width", "1")
        .attr("stroke", "#333e48")
        .attr("stroke-linecap", "round")
        .attr("stroke-dasharray", "1, 2");
    });

    select(".js-tactical-weighting--base")
      .attr("data-toggle", "tooltip")
      .attr("data-original-title", "Value of your total US Stock Portfolio");
    select(".js-tactical-weighting--invested")
      .attr("data-toggle", "tooltip")
      .attr(
        "data-original-title",
        `Growth of your US Stock Portfolio with Empower backtested from 1990`
      );
    select(".js-tactical-weighting--sp")
      .attr("data-toggle", "tooltip")
      .attr(
        "data-original-title",
        "Growth of US Stock Portfolio in the S&P 500 backtested from 1990"
      );

    _.forEach(
      document.getElementsByClassName("js-tactical-weighting"),
      (el) => {
        this.props.storeTooltipRef(el, { container: "body", placement: "top" });
      }
    );
  }

  render() {
    const { message = {}, markAsViewed } = this.props;
    return (
      <div className="insight__message">
        <div
          className="insight__header qa-insight-header"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div
          className="insight__summary"
          dangerouslySetInnerHTML={{ __html: message.summary }}
        />
        <div className="insight__summary">
          <div className="insight__chart-container insight__chart-container--tactical-weighting">
            <ChartReact
              ref={(el) => {
                this.chartReact = el;
              }}
              className="insights-graph--tactical-weighting"
              data={[
                this.state.withTacticalWeighting,
                this.state.withoutTacticalWeighting,
              ]}
              type="area"
              curve={curveMonotoneX}
              interpolate="monotone"
              showXGrid={false}
              showXAxis={false}
              showYGrid={false}
              showYAxis={false}
              x={xAccessor}
              y={yAccessor}
              margin={{ right: 50, left: CHART_OFFSET, bottom: 10, top: 20 }}
            />
            <div className="l-spaced l-spaced--flush tax-loss-harvesting__axis-labels">
              <div>1990</div>
              <div className="tactical-weighting__axis-year">
                {new Date().getFullYear() - 1}
              </div>
            </div>
            <div className="tactical-weighting__legend">
              Personal Strategy<sup>®</sup>
            </div>
            <div className="tactical-weighting__legend tactical-weighting__legend--sp">
              S&P 500
            </div>
          </div>
        </div>
        <div
          className="insight__summary"
          dangerouslySetInnerHTML={{ __html: this.state.gainStatement }}
        />
        {getActionButtons({
          message,
          markAsViewed,
          appointmentReason: "Tactical Weighting",
        })}
      </div>
    );
  }
}

TacticalWeighting.propTypes = {
  message: PropTypes.object,
  markAsViewed: PropTypes.func,
  markAsImpressed: PropTypes.func,
  isFirstRendered: PropTypes.bool,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
};

export default withTooltips(TacticalWeighting);
