import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import analytics from "analytics";
import moment from "moment";
import _ from "underscore";
import MarketMoversSummary from "components/marketMovers/MarketMoversSummary";
import { API_FORMAT } from "libs/pcap/utils/date";
import { promisify, subscribify } from "utils/service";
import Message from "components/common/Message";

const NUM_DAYS_PAST_PERIOD = 90;
const DEFAULT_DATE_RANGE = "90_DAYS";
const ONE_YEAR_DATE_RANGE = "1_YEAR";

function getDefaultFetchConfig(uiPreferenceDateRange, userAccountIds) {
  let startDate;
  if (uiPreferenceDateRange === DEFAULT_DATE_RANGE) {
    startDate = moment()
      .startOf("day")
      .subtract(NUM_DAYS_PAST_PERIOD - 1, "days")
      .format(API_FORMAT);
  } else {
    startDate = moment()
      .startOf("day")
      .subtract(1, "years")
      .add(1, "days")
      .format(API_FORMAT);
  }

  return {
    userAccountIds,
    startDate,
    endDate: moment().format(API_FORMAT),
    intervalType: "DAY",
    includeHistory: true,
    includeYOUHistory: true,
  };
}

export default class MarketMoversContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: null,
    };
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
  }

  getStockIndices(data) {
    let indicesData = _.isUndefined(data.latestQuotes) ? {} : data.latestQuotes;
    let histories =
      _.isUndefined(data) && !_.isUndefined(data.histories)
        ? []
        : data.histories;

    let allStockIndices = {};

    let hasYouIndex = _.find(histories, (history) => {
      return history.YOU;
    });

    // only fill in youIndex data if you have at least 1 day of youIndex history
    if (hasYouIndex) {
      allStockIndices.youIndex = _.isUndefined(data.latestPortfolio)
        ? {}
        : data.latestPortfolio;
      allStockIndices.youIndex.histories = this.getHistoriesForIndex(
        histories,
        "YOU"
      );
      allStockIndices.youIndex.changeToday =
        allStockIndices.youIndex.percentChange;
      allStockIndices.youIndex.label = "You Index®";
    }

    // predefined stock indices based on design requirement.
    var stockIndices = [
      {
        ticker: "^INX",
        label: "S&P 500",
        previousClose: "",
        percentChange: "",
      },
      {
        ticker: "^PC_US_STOCKS",
        label: "US stock",
        previousClose: "",
        percentChange: "",
      },
      {
        ticker: "^PC_INTL_STOCK",
        label: "Foreign stock",
        previousClose: "",
        percentChange: "",
        tooltip:
          "Foreign shows the return of VEU, an ETF tracking the FTSE All World Ex US Index, a market cap weighted index of all major developed and emerging stock markets outside of the US. Tracking the movement of this index helps you understand the performance of global equity markets.",
      },
      {
        ticker: "^PC_US_BONDS",
        label: "US bond",
        previousClose: "",
        percentChange: "",
        tooltip:
          "US Bond shows the performance of AGG, an ETF tracking the Barclays US Aggregate Bond Market index. Movement in this index shows you how your portfolio is stacking up against movements in the US Bond market and how changes in interest rates impact bond prices.",
      },
    ];

    // get latest values from server for the above indices.
    _.each(
      stockIndices,
      function (stockIndex) {
        var indexData = _.find(indicesData, function (indexData) {
          return indexData.ticker === stockIndex.ticker;
        });
        stockIndex.changeToday = indexData.percentChange;
        stockIndex.histories = this.getHistoriesForIndex(
          histories,
          stockIndex.ticker
        );
      }.bind(this)
    );

    allStockIndices.others = stockIndices;

    return allStockIndices;
  }

  getHistoriesForIndex(histories, ticker) {
    return _.map(histories, function (history) {
      var newHistory = { date: history.date, value: 0 };

      if (!_.isUndefined(history[ticker])) {
        newHistory.value = history[ticker];
      }
      return newHistory;
    });
  }

  componentDidMount() {
    const { getUIPreferences, getQuotes, getQuotes2 } = this.props;
    getUIPreferences()
      .then((uiPreferences) => {
        if (this.isisUnmounted) {
          return;
        }

        const uiPreferenceDateRange =
          uiPreferences?.defaultDateRanges?.NETWORTH_INVESTING ||
          DEFAULT_DATE_RANGE;

        this.getQuotesSubscription =
          uiPreferenceDateRange === ONE_YEAR_DATE_RANGE
            ? getQuotes2(
                getDefaultFetchConfig(
                  uiPreferenceDateRange,
                  JSON.stringify(this.selectedAccountIds)
                )
              )
            : getQuotes(
                getDefaultFetchConfig(
                  uiPreferenceDateRange,
                  JSON.stringify(this.selectedAccountIds)
                )
              );
        this.getQuotesSubscription.on("change", this.onGetHistoriesSuccess);
        this.setState({
          uiPreferenceDateRange,
        });

        this.getQuotesSubscription.promise
          .then(this.handleSuccess)
          .catch(this.handleFailure);
      })
      .catch(this.handleFailure);
  }

  componentWillUnmount() {
    this.isUnmounted = true;

    if (this.getQuotesSubscription) {
      this.getQuotesSubscription.unwatch();
      this.getQuotesSubscription.off("change");
    }
  }

  handleFailure(errors) {
    if (this.isUnmounted) {
      return;
    }

    this.setState(
      {
        loading: false,
        errors,
      },
      this.props.onDCDashboardComponentLoaded
    );
    analytics.sendEngineeringEvent(
      "Error",
      "`MarketMoversContainer()` " + JSON.stringify(errors)
    );
  }

  handleSuccess(quotes) {
    if (this.isUnmounted) {
      return;
    }

    let stockIndices = this.getStockIndices(quotes);

    this.setState(
      {
        loading: false,
        errors: [],
        youIndex: stockIndices.youIndex,
        otherIndices: stockIndices.others,
      },
      this.props.onDCDashboardComponentLoaded
    );
  }

  render() {
    const { errors } = this.state;
    return (
      <>
        <Message severity="error" messages={errors} />
        <MarketMoversSummary
          loading={this.state.loading}
          youIndex={this.state.youIndex}
          otherIndices={this.state.otherIndices}
          ace={this.props.ace}
        />
      </>
    );
  }
}

MarketMoversContainer.propTypes = {
  getUIPreferences: PropTypes.func,
  getQuotes: PropTypes.func,
  getQuotes2: PropTypes.func,
  ace: PropTypes.object,
  onDCDashboardComponentLoaded: PropTypes.func,
};

MarketMoversContainer.defaultProps = {
  getUIPreferences: promisify(Services.Profile.getUIPreferences),
  getQuotes: subscribify(Services.Quotes.get),
  getQuotes2: subscribify(Services.Quotes.getV2),
  ace: undefined,
  onDCDashboardComponentLoaded: _.noop,
};
