import React from "react";
import PropTypes from "prop-types";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";

import Table from "libs/pcap/table/Table";
import TransferTimeline from "components/transferManager/TransferTimeline";
import withTooltips from "components/higherOrderComponents/withTooltips";

import noop from "utils/noop";

const columns = [
  {
    header: "From",
    style: {
      width: "25%",
    },
  },
  {
    header: "To",
    style: {
      width: "25%",
    },
  },
  {
    header: "Amount",
    style: {
      width: "20%",
    },
  },
  {
    header: "Timeline",
    style: {
      width: "30%",
    },
  },
];

/* eslint-disable react/display-name, react/prop-types */
const ActiveTransferRow =
  (showMessage, storeTooltipRef) =>
  ({ data }) =>
    (
      <div
        className="active-transfers-table__row "
        data-refid={data.referenceNumber}
      >
        <div className="l-spaced l-spaced--flush l-spaced--top active-transfers-table__wrapper">
          <div className="table__column active-transfers-table__row-cell active-transfers-table__row-cell--from js-active-transfers-table__row-cell--from">
            <div className="u-text-bold js-active-transfers-table__source-firm-name">
              {data.sourceAccountFirmName}
            </div>
            <span className="js-active-transfers-table__source-name">
              {data.sourceAccountName}
            </span>
          </div>
          <div className="table__column active-transfers-table__row-cell active-transfers-table__row-cell--to js-active-transfers-table__row-cell--to">
            <div className="u-text-bold js-active-transfers-table__target-firm-name">
              {data.targetAccountFirmName}
            </div>
            <span className="js-active-transfers-table__target-name">
              {data.targetAccountName}
            </span>
          </div>
          <div className="table__column active-transfers-table__row-cell active-transfers-table__row-cell--amount js-active-transfers-table__row-cell--amount">
            <div className="l-spaced l-spaced--flush">
              <p>{data.transferAmount}</p>
              <p>
                {data.isRecurring === true && (
                  <svg
                    ref={(el) => {
                      storeTooltipRef(el, {
                        placement: "auto",
                      });
                    }}
                    className="icon__calendar js-icon__calendar"
                    data-toggle="tooltip"
                    title="This is a recurring transfer."
                    aria-label="This is a recurring transfer."
                  >
                    <use
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="#icon-calendar"
                    />
                  </svg>
                )}
              </p>
            </div>
          </div>
          {data.notes ? (
            <div className="active-transfers-table__notes js-active-transfers-table__notes">
              <div className="active-transfers-table__notes--label">Notes</div>
              <span className="js-active-transfer__notes">{data.notes}</span>
            </div>
          ) : null}
        </div>
        <div className="table__column active-transfers-table__row-cell active-transfers-table__row-cell--timeline js-active-transfers-table__row-cell--timeline">
          <TransferTimeline
            timeline={data.timeline}
            showMessage={showMessage}
          />
        </div>
      </div>
    );

const ActiveTransfersTable = React.forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ transfers, loading, errors, showMessage, storeTooltipRef }, _ref) => {
    if (loading) {
      return <LoadingOverlay active={loading} />;
    }

    const Row = ActiveTransferRow(showMessage, storeTooltipRef);
    Row.displayName = "ActiveTransferRow";

    return (
      <>
        <Message severity="error" messages={errors} />
        <Table columns={columns} data={transfers} Row={Row} />
      </>
    );
  }
);

ActiveTransfersTable.defaultProps = {
  loading: false,
  errors: undefined,
  showMessage: noop,
  storeTooltipRef: noop,
};

ActiveTransfersTable.propTypes = {
  transfers: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  showMessage: PropTypes.func,
  storeTooltipRef: PropTypes.func,
};

export default withTooltips(ActiveTransfersTable);
export { ActiveTransfersTable };
