import PropTypes from "prop-types";
import React from "react";
import { Tooltip } from "react-lightweight-tooltip";

const leftStyles = {
  wrapper: {
    position: "relative",
    display: "inline-block",
    zIndex: "98",
    color: "#555",
    cursor: "normal",
  },
  tooltip: {
    position: "absolute",
    zIndex: "99",
    background: "#000",
    bottom: "auto",
    left: "auto",
    right: "100%",
    padding: "12px",
    marginBottom: "0",
    marginTop: "-12px",
    marginRight: "9px",
    WebkitTransform: "translateY(-50%)",
    msTransform: "translateY(-50%)",
    OTransform: "translateY(-50%)",
    transform: "translateY(-50%)",
    boxShadow: "3px 3px 20px -3px rgba(0, 0, 0, 0.75)",
  },
  content: {
    background: "#407cca",
    color: "#fff",
    fontSize: "12px",
    padding: "0",
    whiteSpace: "normal",
  },
  arrow: {
    position: "absolute",
    width: "0",
    height: "0",
    right: "-12px",
    top: "50%",
    left: "auto",
    marginTop: "-12px",
    borderLeft: "solid #000 12px",
    borderBottom: "solid transparent 12px",
    borderTop: "solid transparent 12px",
  },
  gap: {
    position: "absolute",
    width: "0",
    height: "0",
    bottom: "0",
  },
};

export default class ClickableTooltip extends React.Component {
  render() {
    return (
      <Tooltip styles={leftStyles} content={this.props.content}>
        {this.props.children}
      </Tooltip>
    );
  }
}

ClickableTooltip.propTypes = {
  content: PropTypes.array,
  children: PropTypes.object,
};
