export const DEFAULT_MODAL_WIDTH = 500;
export const MEDIUM_MODAL_WIDTH = 630;
export const SMEDIUM_MODAL_WIDTH = 600;
export const LARGE_MODAL_WIDTH = 760;
export const PCAP_CASH_MODAL_WIDTH = 730;
export const IC_MODAL_WIDTH = 840;
export const REFERRAL_CENTER_HASH = "#/referral-center";
export const MODAL_TITLE_BLANK = " ";
export const PREMIUM_SUBSCRIPTION_MODAL_WIDTH = 920;
export const EMPOWER_MODAL_WIDTH = 900;
export const EMPOWER_MODAL_MEDIUM_WIDTH = 650;

export const IS_PRODUCTION_ENVIRONMENT =
  window.location.host.toLowerCase() === "home.personalcapital.com";
export const CURRENT_USER_EMAIL = (
  typeof window.username === "string"
    ? window.username
    : "user@personalcapital.com"
).toLowerCase();

export const EXCLUDE_USER_FROM_INTERJECTIONS =
  !IS_PRODUCTION_ENVIRONMENT &&
  (CURRENT_USER_EMAIL.indexOf("testqa") === 0 ||
    CURRENT_USER_EMAIL.indexOf("qatest") === 0) &&
  CURRENT_USER_EMAIL.includes("@personalcapital.com"); // testqa***@personalcapital.com or qatest***@personalcapital.com
