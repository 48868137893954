import $ from "jquery";
import objectPath from "object-path";

/*
 * AbFramework properties
 */

// Get PCAP namespace
let PCAP = window.PCAP || {};
let originalAbFrameworkProperties = PCAP.abFrameworkProperties || {};

// flags for abFramework
const abFrameworkProperties = {
  shareWithFriends: {
    // Talkable referral program
    // Server side feature flag is passed from api/profile/getUIPreferences as USER_REFERRAL_PROGRAM
    shareOnLogoutUserStages: {
      D: "add_investment_acct_20_b",
      E: "add_investment_acct_20_c",
      F: "add_investment_acct_20_c",
      G: "add_investment_acct_20_c",
      H: "add_investment_acct_20_c",
    },
  },
  showMessageAdvisorInAppt: {
    enable: false,
  },
  firstuse: {
    hideSkipLinkAccount: false,
    madLibsQQInterjection: false,
    showLinkAccountUnderMadLibsQQInterjection: false,
  },
  moveIt: {
    show: true,
    balanceCutOff: 25000,
    numberOfAccounts: 3,
  },
  showEmailAdvisorLauncherIn: {
    investmentCheckup: false,
    feeAnalyzer: false,
    retirementPlanner: false,
    onlineShopping: false,
  },
  skipPhoneNumberOnRegTest: {
    isRunning: false,
  },
  userMessages: {
    hideLearnMore: false,
  },
  linkAccount: {
    highlightTopSites: false,
  },
  completenessMeter: {
    date: "2018-08-14",
    legacyUserGuids: "",
    newUserGuids: "",
  },
};

// Merge abFrameworkProperties defined before this module is loaded (e.g. via Google Tag Manager) to what's defined here
PCAP.abFrameworkProperties = $.extend(
  /* deep copy */ true,
  abFrameworkProperties,
  originalAbFrameworkProperties
);
window.PCAP = PCAP;

export const getAbFrameworkData = (key) => {
  return objectPath.get(abFrameworkProperties, key);
};

export const getGtmConstant = (key) => {
  return (
    abFrameworkProperties.gtmConstants &&
    abFrameworkProperties.gtmConstants[key]
  );
};

export default abFrameworkProperties;
