import PropTypes from "prop-types";
import React from "react";
import AdviceDisclaimerModal from "components/modal/AdviceDisclaimerModal";
import objectPath from "object-path";
import { trackClick } from "components/common/ComponentAnalytics";
import { isPcapCashPostLoginAction } from "views/components/postLoginActions/pcapCashPlaUtils";

/**
 * Higher order component that wraps around another Modal component to provide
 * the `Advice Disclaimer` link, which will show AdviceDisclaimerModal on click.
 *
 * Usage: const ModalWithAdviceDisclaimer = withAdviceDisclaimer(Modal);
 * Then use <ModalWithAdviceDisclaimer /> instead of <Modal />
 *
 * @param {Component} WrappedComponent  The component to provide advice disclaimer link for
 * @returns {Component} The wrapped component that will have the advice disclaimer link
 */

function withAdviceDisclaimer(WrappedComponent) {
  class WrappedComponentWithDisclaimer extends React.Component {
    constructor() {
      super(...arguments);
      this.state = {
        showPlaModal: true,
        showDisclaimerModal: false,
        showMethodologyModal: false,
      };
      this.onAdviceClaimerClick = this.onAdviceClaimerClick.bind(this);
      this.onAdviceDisclaimerModalClosed =
        this.onAdviceDisclaimerModalClosed.bind(this);
      this.onMethodologyClick = this.onMethodologyClick.bind(this);
      this.onMethodologyModalClosed = this.onMethodologyModalClosed.bind(this);
    }

    onAdviceClaimerClick(ev) {
      const { modalComponentName, customDisclaimerData } = this.props; // eslint-disable-line react/prop-types
      if (objectPath.get(customDisclaimerData, "link")) {
        trackClick(ev, modalComponentName, "Disclaimer Link", {
          disclaimerLink: objectPath.get(customDisclaimerData, "link"),
        });
        return;
      }
      ev.preventDefault();
      this.setState({
        showPlaModal: false,
        showDisclaimerModal: true,
      });
      trackClick(ev, modalComponentName, "Disclaimer Link", {
        disclaimerLink: "#",
      });
    }

    onAdviceDisclaimerModalClosed() {
      this.setState({
        showPlaModal: true,
        showDisclaimerModal: false,
      });
    }

    getDisclaimerLinkText() {
      const { customDisclaimerData, userMessage } = this.props;
      const customDisclaimerDataTitle = objectPath.get(
        customDisclaimerData,
        "title"
      );
      const customDisclaimerDataWebTitle = objectPath.get(
        customDisclaimerData,
        "webTitle"
      );
      let disclaimerLinkText = "Advice Disclaimer";
      if (customDisclaimerDataTitle) {
        if (customDisclaimerDataWebTitle) {
          disclaimerLinkText = customDisclaimerDataWebTitle; // title is used as the link text, it is used when we need different title for mobile and web, mobile uses title, and web uses webTitle
        } else {
          disclaimerLinkText = customDisclaimerDataTitle; // `title` is used as the `link text`
        }
      } else if (
        !customDisclaimerDataTitle &&
        isPcapCashPostLoginAction(userMessage)
      ) {
        disclaimerLinkText = "Disclosures"; // Custom PCAP Cash PLAs disclaimer link text
      }
      return disclaimerLinkText;
    }

    onMethodologyClick(ev) {
      ev.preventDefault();
      this.setState({
        showPlaModal: false,
        showMethodologyModal: true,
      });
    }

    onMethodologyModalClosed() {
      this.setState({
        showPlaModal: true,
        showMethodologyModal: false,
      });
    }

    render() {
      const {
        modalComponentName,
        customDisclaimerData,
        userMessage,
        children,
        disclaimerClassName,
        methodology,
      } = this.props;
      const disclaimerLinkText = this.getDisclaimerLinkText();
      const methodologyLinkText = methodology?.title;
      const disclaimerHref =
        objectPath.get(customDisclaimerData, "link") || "#";
      const disclaimerTarget = objectPath.get(customDisclaimerData, "link")
        ? "_blank"
        : "_self";
      const footnote = customDisclaimerData?.footnote;
      const disclaimernlinkClass = customDisclaimerData?.isUnderlineLink
        ? "pc-modal--advice-disclaimer-link-underline"
        : "pc-modal--advice-disclaimer-link";
      if (this.state.showDisclaimerModal) {
        return (
          <AdviceDisclaimerModal
            componentName={modalComponentName}
            userMessage={userMessage}
            customDisclaimerData={customDisclaimerData}
            disclaimerClassName={disclaimerClassName}
            isOpen={true}
            onClosed={this.onAdviceDisclaimerModalClosed}
            title={disclaimerLinkText.replace("*", "")}
          />
        );
      }

      if (this.state.showMethodologyModal) {
        return (
          <AdviceDisclaimerModal
            componentName={modalComponentName}
            userMessage={userMessage}
            customDisclaimerData={methodology}
            isOpen={true}
            onClosed={this.onMethodologyModalClosed}
            title={methodology.title}
          />
        );
      }

      return (
        <WrappedComponent
          ref={(el) => {
            this.wrappedComponent = el;
          }}
          {...this.props}
        >
          {children}
          <div className="pc-modal--advice-disclaimer-container">
            <div className="pc-modal--advice-disclaimer">
              <a
                className={`${disclaimernlinkClass} js-advice-disclaimer-link`}
                target={disclaimerTarget}
                href={disclaimerHref}
                onClick={this.onAdviceClaimerClick}
              >
                {disclaimerLinkText}
              </a>
              {methodology && (
                <>
                  <span> | </span>
                  <button
                    className="pc-modal--advice-disclaimer-link js-advice-methodology-link"
                    target={methodology}
                    onClick={this.onMethodologyClick}
                  >
                    {methodologyLinkText}
                  </button>
                </>
              )}
            </div>
            {Boolean(footnote) && (
              <div
                className="pc-modal--advice-disclaimer-footnote js-advice-disclaimer-footnote"
                dangerouslySetInnerHTML={{ __html: footnote }}
              />
            )}
          </div>
        </WrappedComponent>
      );
    }
  }

  WrappedComponentWithDisclaimer.propTypes = WrappedComponent.PropTypes;

  return WrappedComponentWithDisclaimer;
}

withAdviceDisclaimer.defaultProps = {
  modalComponentName: "",
  userMessage: undefined,
  customDisclaimerData: undefined,
  children: undefined,
  className: undefined,
  methodology: undefined,
};

withAdviceDisclaimer.propTypes = {
  modalComponentName: PropTypes.string,
  userMessage: PropTypes.object,
  customDisclaimerData: PropTypes.string,
  children: PropTypes.node,
  disclaimerClassName: PropTypes.string,
  methodology: PropTypes.object,
};

export default withAdviceDisclaimer;
