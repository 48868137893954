import TaxableAccountSelectorView from "./taxableAccountSelectorView2";

export default TaxableAccountSelectorView.extend({
  isAccountOnUs: function (account) {
    if (!account) return false;
    //don't treat isOnUsRetirement as onUs
    return account.isOnUs;
  },
  mapAccountToMenuObject: function () {
    const baseAccountProperties =
      TaxableAccountSelectorView.prototype.mapAccountToMenuObject.apply(
        this,
        arguments
      );

    return {
      ...baseAccountProperties,
      //this will result in isOnUsRetirement accounts having regular account class names and they'll
      //be accounted for in the correct group checkboxes
      isOnUsRetirement: undefined,
    };
  },
  accountIsExcludedFromSelection: function (account) {
    return (
      //don't check feeX accounts by default
      account.get("isOnUsRetirement") === true ||
      account.get("isExcludeFromHousehold")
    );
  },
  onAccountCollectionChange: function () {
    //keep track of feeXAccountIds so we can re-check on remove
    this.feeXAccountIds = this.filterAccountsFromDropdownResult(
      this.accountsCollection
    )
      .filter((account) => account.get("isOnUsRetirement"))
      .map((account) => account.get("userAccountId"));

    TaxableAccountSelectorView.prototype.onAccountCollectionChange.call(this);
  },
  remove: function () {
    TaxableAccountSelectorView.prototype.remove.apply(this, arguments);
    //add feeX accounts back to selected ids when we move away from performance tab
    const newSelectedUserAccountIds = new Set([
      ...this.selectedUserAccountIds,
      ...(this.feeXAccountIds || []),
    ]);

    return Array.from(newSelectedUserAccountIds);
  },
});
