import React from "react";
import PropTypes from "prop-types";
import MicroDepositVerify from "components/transferFunds/MicroDepositVerify";
import Services from "services";
import { first, noop } from "underscore";
import { promisify } from "utils/service";
import { trackEvent } from "components/common/ComponentAnalytics";
import { getSource } from "components/common/attributionStore";

export default class MicroDepositVerifyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errors: [],
    };

    this.handleOnVerify = this.handleOnVerify.bind(this);
  }

  componentDidMount() {
    this.props.onMounted("Verify Account");
  }

  handleOnVerify(model) {
    this.setState({ loading: true });
    const { firmName } = this.props;
    const source = getSource();
    trackEvent("Transfer Funds", "Micro Deposits Submitted", {
      // eslint-disable-next-line camelcase
      fi_name: firmName,
      source,
    });
    this.props
      .verifyPCBMicroDeposit(Object.assign({}, model, { source }))
      .then(() => {
        trackEvent("Transfer Funds", "Micro Deposits Confirmed", {
          // eslint-disable-next-line camelcase
          fi_name: firmName,
          source,
        });
        this.setState({ errors: [], loading: false });
        this.props.onVerify();
      })
      .catch((errors) => {
        trackEvent("Transfer Funds", "Micro Deposits Rejected", {
          // eslint-disable-next-line camelcase
          fi_name: firmName,
          error: first(errors),
          source,
        });
        this.setState({ errors, loading: false });
      });
  }

  render() {
    const { userAccountId, onCancel } = this.props;
    const { loading, errors } = this.state;
    const model = {
      userAccountId,
    };

    return (
      <MicroDepositVerify
        model={model}
        errors={errors}
        onVerify={this.handleOnVerify}
        loading={loading}
        onCancel={onCancel}
      />
    );
  }
}

MicroDepositVerifyContainer.propTypes = {
  userAccountId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  verifyPCBMicroDeposit: PropTypes.func,
  firmName: PropTypes.string,
  onMounted: PropTypes.func,
};

MicroDepositVerifyContainer.defaultProps = {
  verifyPCBMicroDeposit: promisify(Services.PCBMicroDeposit.verify),
  firmName: undefined,
  onMounted: noop,
};
