export default function search(text, data, searchConfig) {
  if (text && data && searchConfig) {
    return data.filter((datum) => {
      const values = searchConfig.map((accessor) => accessor(datum));
      return values.some((value) => {
        return String(value).toLowerCase().includes(text.toLowerCase());
      });
    });
  }
  return data;
}
