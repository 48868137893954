/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { VALIDATION_REQUIRED_STRING } from "empower/validations";
import FancySelect from "components/common/form/FancySelect";
import isEmpty from "libs/pcap/utils/isEmpty";
import noop from "utils/noop";
import {
  getChildren,
  mapChildrenByParentValue,
} from "empower/components/AddManualAccountContainer/utils";

function AccountTypeSelector({
  accountTypeGroups,
  model,
  onInputChange,
  storeInputRef,
  classContainer,
  classLabel,
  disabled,
}) {
  const [accountTypes, setAccountTypes] = useState([]);
  const [accountSubtypes, setAccountSubtypes] = useState([]);

  const validator = AccountTypeSelector.validationSchema;

  const accounts = React.useMemo(() => {
    const typesByGroup = mapChildrenByParentValue(accountTypeGroups);
    const allAccountTypes = getChildren(accountTypeGroups);
    const subTypesByType = mapChildrenByParentValue(allAccountTypes);

    return {
      typesByGroup,
      subTypesByType,
    };
  }, [accountTypeGroups]);

  React.useEffect(() => {
    if (
      model?.accountTypeGroup &&
      accounts.typesByGroup[model.accountTypeGroup]
    ) {
      setAccountTypes(accounts.typesByGroup[model.accountTypeGroup]);
    }

    if (
      model?.accountTypeNew &&
      accounts.subTypesByType[model.accountTypeNew]
    ) {
      setAccountSubtypes(accounts.subTypesByType[model.accountTypeNew]);
    }
  }, [accounts]);

  const onAccountTypeGroupSelect = (ev, value) => {
    const group = value ?? ev.target.value;
    const types = accounts.typesByGroup[group];

    setAccountTypes(types);
    setAccountSubtypes(null);

    const accountTypeNew = types?.length === 1 ? types[0].value : null;
    const accountTypeSubtype = null;
    onInputChange(ev, value);
    onInputChange(
      {
        target: {
          name: "accountTypeNew",
          value: accountTypeNew,
        },
      },
      accountTypeNew
    );
    onInputChange(
      {
        target: {
          name: "accountTypeSubtype",
          value: accountTypeSubtype,
        },
      },
      accountTypeSubtype
    );
  };

  const onAccountTypeNewSelect = (ev, value) => {
    const type = value ?? ev.target.value;
    const subTypes = accounts.subTypesByType[type];

    setAccountSubtypes(subTypes);

    const accountTypeSubType =
      subTypes?.length === 1 ? subTypes[0].value : null;

    onInputChange(ev, value);
    onInputChange(
      {
        target: {
          name: "accountTypeSubtype",
          value: accountTypeSubType,
        },
      },
      accountTypeSubType
    );
  };

  return (
    <div className="pc-layout pc-u-mt">
      <div className={`pc-layout__item ${classLabel}`}>
        <label htmlFor="accountTypeGroup" className="pc-label">
          What type of account?
        </label>
      </div>
      <div className={`pc-layout__item ${classContainer}`}>
        <FancySelect
          id="accountTypeGroup"
          isDisabled={disabled}
          menuPortalTarget={document.body}
          name="accountTypeGroup"
          onChange={onAccountTypeGroupSelect}
          options={accountTypeGroups}
          ref={storeInputRef}
          sizeVariation="full"
          validator={validator.accountTypeGroup}
          value={model.accountTypeGroup}
        />
        {!isEmpty(accountTypes) &&
          model.accountTypeGroup !== model.accountTypeNew && (
            <FancySelect
              containerClassName="pc-u-mt"
              id="accountTypeNew"
              isDisabled={disabled}
              menuPortalTarget={document.body}
              name="accountTypeNew"
              onChange={onAccountTypeNewSelect}
              options={accountTypes}
              ref={storeInputRef}
              sizeVariation="full"
              validator={validator.accountTypeNew}
              value={model.accountTypeNew}
            />
          )}
        {!isEmpty(accountSubtypes) &&
          model.accountType !== model.accountTypeSubtype && (
            <FancySelect
              containerClassName="pc-u-mt"
              id="accountTypeSubtype"
              isDisabled={disabled}
              menuPortalTarget={document.body}
              name="accountTypeSubtype"
              onChange={onInputChange}
              options={accountSubtypes}
              ref={storeInputRef}
              sizeVariation="full"
              validator={validator.accountTypeSubtype}
              value={model.accountTypeSubtype}
            />
          )}
      </div>
    </div>
  );
}

AccountTypeSelector.propTypes = {
  accountTypeGroups: PropTypes.array,
  model: PropTypes.object,
  onInputChange: PropTypes.func,
  storeInputRef: PropTypes.func,
  classContainer: PropTypes.string,
  classLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

AccountTypeSelector.defaultProps = {
  accountTypeGroups: [],
  model: {},
  onInputChange: noop,
  storeInputRef: noop,
  classContainer: "pc-u-1/2",
  classLabel: "pc-u-1/3",
  disabled: false,
};

AccountTypeSelector.validationSchema = {
  accountTypeGroup: VALIDATION_REQUIRED_STRING,
  accountTypeNew: VALIDATION_REQUIRED_STRING,
  accountTypeSubtype: VALIDATION_REQUIRED_STRING,
};

export default AccountTypeSelector;
