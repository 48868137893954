import PropTypes from "prop-types";
import React from "react";
import SplitYourTransaction from "components/TransactionsGridV3/SplitYourTransaction";
import { promisify } from "utils/service";
import Services from "services";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import BackboneReactView from "common/BackboneReactView";
import SplitYourTransactionToast from "../Toast";
import { isEmpty, noop } from "underscore";
import { isEmpowerPrivilegedMode } from "../../../../views/modules/sidebar/utils/accountUtils";

const isPrivileged = isEmpowerPrivilegedMode();

export default class SplitYourTransactionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      firstTimeTransactionSplit: true,
      errors: [],
    };

    this.handleUpdateTransaction = this.handleUpdateTransaction.bind(this);
    this.handleSplitTransaction = this.handleSplitTransaction.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getUIPreferences()
      .then((uiPreferences) => {
        if (this.isisUnmounted) {
          return;
        }

        this.setState({
          loading: false,
          errors: [],
          firstTimeTransactionSplit: Boolean(
            uiPreferences?.uiPreferences?.firstTimeTransactionSplit
          ),
        });
      })
      .catch((errors) => {
        if (this.isisUnmounted) {
          return;
        }

        this.setState({ loading: false, errors });
      });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  handleUpdateTransaction(requestModel, onlySaveTransactionIsCalled) {
    this.setState({ loading: true });
    return this.props
      .updateUserTransactions(requestModel)
      .then((transactions) => {
        if (onlySaveTransactionIsCalled) {
          this.setState({
            loading: false,
            errors: undefined,
          });
          this.props.onCancel();
          this.props.onSaved(transactions);
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
          errors,
        });
      });
  }

  handleSplitTransaction(requestModel) {
    this.setState({ loading: true });
    const splits = JSON.parse(requestModel.splits);
    const isUnSplit = isEmpty(splits);
    ComponentAnalytics.trackEvent(
      "Split Your Transaction",
      `${isUnSplit ? "Un" : ""}Split Transaction`,
      {
        // eslint-disable-next-line camelcase
        number_of_splits: splits.length,
        // eslint-disable-next-line camelcase
        category_name: requestModel.categoryName,
      }
    );
    return this.props
      .updateTransactionSplits(requestModel)
      .then((transaction) => {
        this.setState({
          loading: false,
          errors: undefined,
        });
        this.props.onCancel();
        this.props.onSaved([transaction]);
        if (!isUnSplit && this.state.firstTimeTransactionSplit) {
          if (window.hj) {
            window.hj("trigger", "hj-split-transaction");
          }
          this.props.updateUIPreferences({ firstTimeTransactionSplit: false });
          const moduleContainer = document.getElementById("moduleContainer");
          if (moduleContainer) {
            let container = document.createElement("div");
            moduleContainer.appendChild(container);
            new BackboneReactView({
              el: container,
              component: SplitYourTransactionToast,
            });
          }
        }
      })
      .catch((errors) => {
        ComponentAnalytics.trackEvent(
          "Split Your Transaction",
          `Split Transaction Failed`,
          {
            errors: JSON.stringify(errors),
          }
        );
        this.setState({
          loading: false,
          errors,
        });
      });
  }

  render() {
    const { errors, loading } = this.state;
    const {
      onCancel,
      model,
      categories,
      tags,
      onCategoryCreated,
      onDeleteTransactionImage,
      onFileUploaded,
      imageErrors,
      imageLoading,
      isPrivileged,
    } = this.props;
    return (
      <SplitYourTransaction
        errors={errors}
        loading={loading}
        imageErrors={imageErrors}
        imageLoading={imageLoading}
        onCancel={onCancel}
        onUpdate={this.handleUpdateTransaction}
        onSplit={this.handleSplitTransaction}
        onUnSplit={this.handleSplitTransaction}
        model={model}
        categories={categories}
        tags={tags}
        onCategoryCreated={onCategoryCreated}
        onDeleteTransactionImage={onDeleteTransactionImage}
        onFileUploaded={onFileUploaded}
        isPrivileged={isPrivileged}
      />
    );
  }
}

SplitYourTransactionContainer.propTypes = {
  model: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  onCategoryCreated: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  updateUserTransactions: PropTypes.func,
  updateTransactionSplits: PropTypes.func,
  getUIPreferences: PropTypes.func,
  updateUIPreferences: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onDeleteTransactionImage: PropTypes.func,
  onFileUploaded: PropTypes.func,
  imageLoading: PropTypes.bool,
  imageErrors: PropTypes.array,
  isPrivileged: PropTypes.bool,
};

SplitYourTransactionContainer.defaultProps = {
  updateUserTransactions: promisify(Services.Transactions.updates2),
  getUIPreferences: promisify(Services.Profile.getUIPreferences),
  updateUIPreferences: promisify(Services.Profile.updateUIPreferences),
  updateTransactionSplits: promisify(
    Services.Transactions.updateTransactionSplits
  ),
  onDeleteTransactionImage: noop,
  onFileUploaded: noop,
  imageLoading: false,
  imageErrors: undefined,
  isPrivileged: isPrivileged,
};
