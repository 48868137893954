import PropTypes from "prop-types";
import React from "react";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import mixpanel from "mixpanel";
import { getEventData } from "components/opportunitiesSummary/InsightsUtils";

const MAX_STOCKS_SHOWN = 3;
const PERCENTAGE_MULTIPLIER = 100;

class SingleStockConcentration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stocks: this.props.message.resources[0].jsonContent.concentratedStocks,
      numberOfStocks:
        this.props.message.resources[0].jsonContent.numberOfConcentratedStocks,
      totalConcentration:
        this.props.message.resources[0].jsonContent
          .totalConcentratedStockPercentage,
    };
  }

  componentDidMount() {
    mixpanel.trackEvent(
      `View Insight`,
      getEventData({ message: this.props.message })
    );
    if (this.props.isFirstRendered) {
      this.props.markAsImpressed(this.props.message.userMessageId);
    } else {
      this.props.markAsViewed(this.props.message.userMessageId);
    }

    this.props.storeTooltipRef(
      document.getElementById("portfolioReviewDisclaimer"),
      { container: "body", placement: "top" }
    );
  }

  generateTopMessage(stockLength) {
    if (stockLength === 1) {
      return `Currently your portfolio has a high concentration of ${this.state.stocks[0].ticker} stock, which means you're not as well diversified as you could be.`;
    }
    if (stockLength === 2) {
      return `Currently your portfolio has a high concentration of stocks with tickers ${this.state.stocks[0].ticker} and ${this.state.stocks[1].ticker}, which means you're not as well diversified as you could be.`;
    }
    return "Currently your portfolio has high single stock exposure, which means you're not as well diversified as you could be.";
  }

  generateBottomMessage(stockLength) {
    if (stockLength === 1) {
      return `You have ${this.state.totalConcentration}% of your investable assets in ${this.state.stocks[0].ticker}, which can increase your volatility and expose you to a high amount of sector and company-specific risk.`;
    }
    return `You have ${this.state.totalConcentration}% of your investable assets in ${stockLength} companies' stocks, which can increase your volatility and expose you to a high amount of sector and company-specific risk.`;
  }

  generateBarChart(stockLength) {
    if (stockLength > MAX_STOCKS_SHOWN) {
      return (
        <div
          style={{ height: "100%" }}
          className="single-stock-concentration__bar-segment"
        >
          <span className="single-stock-concentration__bar-text">
            {this.state.totalConcentration}%
          </span>
        </div>
      );
    }
    return this.state.stocks.map((stock, i) => {
      const barHeight =
        (stock.percentageOfYourPortfolio / this.state.totalConcentration) *
        PERCENTAGE_MULTIPLIER;
      return (
        <div
          key={"bar" + stock.ticker}
          style={{ height: barHeight + "%" }}
          className={`single-stock-concentration__bar-segment single-stock-concentration__bar-segment--${i}`}
        >
          <span className="single-stock-concentration__bar-text">
            {stock.percentageOfYourPortfolio}%
          </span>
        </div>
      );
    });
  }

  generateLegend(stockLength) {
    if (stockLength > MAX_STOCKS_SHOWN) {
      return <div className="single-stock-concentration__phantom-label" />;
    }
    return (
      <div className="single-stock-concentration__legends">
        {this.state.stocks.map((stock, i) => {
          return (
            <div
              key={"legend" + stock.ticker}
              className={`single-stock-concentration__legend single-stock-concentration__legend--${i}`}
            >
              {stock.ticker}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { message } = this.props;
    return (
      <div className="insight__message">
        <div
          className="insight__header qa-insight-header u-sentence-case"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div className="insight__summary pc-u-pb-">
          {this.generateTopMessage(this.state.numberOfStocks)}
        </div>
        <div className="single-stock-concentration__gradient-section">
          <div className="insight__summary insight__summary--single-stock-concentration">
            {this.generateBottomMessage(this.state.numberOfStocks)}
          </div>
          <div className="single-stock-concentration__bar">
            {this.generateBarChart(this.state.numberOfStocks)}
          </div>
          <div className="single-stock-concentration__bottom-bar" />
        </div>
        {this.generateLegend(this.state.numberOfStocks)}
        <div
          className="insight__summary"
          dangerouslySetInnerHTML={{ __html: message.summary }}
        />
        <div className="insight__buttons">
          {getActionButtons({
            message,
            markAsViewed: this.props.markAsViewed,
            appointmentReason: "Single Stock Concentration",
          })}
        </div>
      </div>
    );
  }
}

SingleStockConcentration.propTypes = {
  message: PropTypes.object,
  markAsImpressed: PropTypes.func,
  markAsViewed: PropTypes.func,
  isFirstRendered: PropTypes.bool,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
};

export default withTooltips(SingleStockConcentration);
