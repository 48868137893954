import PropTypes from "prop-types";
import React from "react";
import mixpanel from "mixpanel";
import ChartReact from "libs/pcap/chart/chart--react";
import { formatNumber } from "libs/pcap/utils/format";
import { format, select, selectAll, curveMonotoneX } from "d3";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { circleRight, defaultSubject } from "common/annotationTypes";
import { annotation } from "d3-svg-annotation";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import { getEventData } from "components/opportunitiesSummary/InsightsUtils";

import _ from "underscore";
const CHART_OFFSET = 7;

function xAccessor(item) {
  return item.x;
}

function yAccessor(item) {
  return item.y;
}

class TaxLossHarvesting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      withTLH: this.props.message.resources[0].jsonContent.chartData[0].data,
      withoutTLH: this.props.message.resources[0].jsonContent.chartData[1].data,
    };
  }

  componentDidMount() {
    this.renderAnnotations();
    const areachart = this.chartReact.chart;
    const TaxLossHarvestingDetailsCoords = {
      x: areachart.options.xScale(_.last(this.state.withoutTLH).x) - 1, // eslint-disable-line no-undef
      y: areachart.options.yScale(_.last(this.state.withoutTLH).y) - 70, // eslint-disable-line no-magic-numbers
    };

    const TaxLossHarvestingDetails = document.getElementById(
      "TaxLossHarvestingDetails"
    );
    TaxLossHarvestingDetails.style.left =
      TaxLossHarvestingDetailsCoords.x + CHART_OFFSET + "px";
    TaxLossHarvestingDetails.style.top =
      TaxLossHarvestingDetailsCoords.y + "px";

    mixpanel.trackEvent(
      `View Insight`,
      getEventData({ message: this.props.message })
    );

    if (this.props.isFirstRendered) {
      this.props.markAsImpressed(this.props.message.userMessageId);
    } else {
      this.props.markAsViewed(this.props.message.userMessageId);
    }
  }

  renderAnnotations() {
    const chart = this.chartReact.chart;

    const annotations = [
      {
        type: circleRight,
        className:
          "js-cost-of-cash js-cost-of-cash--base tax-loss-harvesting__starting-point",
        note: {
          label: "$" + format(".3s")(this.state.withTLH[0].y),
          title: `Taxable accounts`,
          wrap: 50,
          padding: 0,
        },
        x: chart.options.xScale(this.state.withTLH[0].x),
        y: chart.options.yScale(this.state.withTLH[0].y),
        dy: -1,
        dx: -6,
        subject: defaultSubject,
      },
    ];

    const makeAnnotations = annotation().annotations(annotations);

    select(".insights-graph--tax-loss-harvesting .js-chart-body")
      .append("g")
      .attr("class", "annotation-group")
      .call(makeAnnotations);
    selectAll(".annotation-note-title").attr("font-size", "10");
    makeAnnotations.updateText();

    select(".js-cost-of-cash--base")
      .attr("data-toggle", "tooltip")
      .attr(
        "data-original-title",
        "Your total invested cash in taxable accounts (does not include retirement or tax-advantaged accounts)."
      );

    const noteBox = select(".annotation-note-label").node().getBBox();
    select(".annotation-note-content")
      .append("line")
      .attr("x1", 0)
      .attr("x2", noteBox.width)
      .attr("y1", noteBox.y + noteBox.height - 2)
      .attr("y2", noteBox.y + noteBox.height - 2)
      .attr("stroke-width", "1")
      .attr("stroke", "#333e48")
      .attr("stroke-linecap", "round")
      .attr("stroke-dasharray", "1, 2");

    _.forEach(document.getElementsByClassName("js-cost-of-cash"), (el) => {
      this.props.storeTooltipRef(el, { container: "body", placement: "top" });
    });
  }

  render() {
    const { message = {}, markAsViewed } = this.props;
    return (
      <div className="insight__message">
        <div
          className="insight__header qa-insight-header u-sentence-case"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div className="insight__summary">
          <div className="insight__chart-container insight__chart-container--tax-loss-harvesting">
            <div
              id="TaxLossHarvestingDetails"
              className="tax-loss-harvesting__label-container"
            >
              <div className="dot dot--tax-loss-harvesting" />
              <div className="dot dot--no-tax-loss-harvesting dot--negative" />
              <div className="tax-loss-harvesting__label tax-loss-harvesting__label--recommended">
                <div
                  ref={(el) => {
                    this.props.storeTooltipRef(el, {
                      container: "body",
                      placement: "top",
                    });
                  }}
                  data-toggle="tooltip"
                  data-original-title="Expected Taxable Portfolio Value with Tax Loss Harvesting."
                  className="tax-loss-harvesting__label-text"
                >
                  ${formatNumber(_.last(this.state.withTLH).y, 0)}
                </div>
              </div>
              <div className="tax-loss-harvesting__label tax-loss-harvesting__label--no-tax-loss-harvesting">
                <div
                  ref={(el) => {
                    this.props.storeTooltipRef(el, {
                      container: "body",
                      placement: "top",
                    });
                  }}
                  data-toggle="tooltip"
                  data-original-title="Expected Taxable Portfolio Value without Tax Loss Harvesting."
                  className="tax-loss-harvesting__label-text"
                >
                  ${formatNumber(_.last(this.state.withoutTLH).y, 0)}
                </div>
              </div>
            </div>
            <ChartReact
              ref={(el) => {
                this.chartReact = el;
              }}
              className="insights-graph--tax-loss-harvesting"
              data={[this.state.withoutTLH]}
              type="area"
              curve={curveMonotoneX}
              interpolate="monotone"
              showXGrid={false}
              showXAxis={false}
              showYGrid={false}
              showYAxis={false}
              x={xAccessor}
              y={yAccessor}
              margin={{ right: CHART_OFFSET, left: CHART_OFFSET, bottom: 10 }}
            />
            <div className="l-spaced l-spaced--flush tax-loss-harvesting__axis-labels">
              <div className="u-sentence-case">Current Year</div>
              <div className="tax-loss-harvesting__axis-year u-sentence-case">
                {this.state.withoutTLH.length - 1} Years
              </div>
            </div>
          </div>
        </div>
        <div
          className="insight__summary"
          dangerouslySetInnerHTML={{ __html: message.summary }}
        />
        <div className="insight__buttons">
          {getActionButtons({
            message,
            markAsViewed,
            appointmentReason: "Tax Loss Harvesting",
          })}
        </div>
      </div>
    );
  }
}

TaxLossHarvesting.propTypes = {
  message: PropTypes.object,
  markAsViewed: PropTypes.func,
  markAsImpressed: PropTypes.func,
  isFirstRendered: PropTypes.bool,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
};

export default withTooltips(TaxLossHarvesting);
