import React from "react";
import PropTypes from "prop-types";

const JointAccountConsent = ({ applicantName, isDelegate }) => (
  <div className="pc-u-ml u-preserve-case">
    I{applicantName ? `, ${applicantName},` : ""} understand that I and each
    additional account holder:
    <ol className="pc-u-ml pc-u-pl-- pc-u-mb--">
      <li>
        will have full authority to make deposits and withdrawals and give other
        instructions related to the joint account;{isDelegate && " and"}
      </li>
      <li>
        will be jointly and severally liable for obligations relating to the
        joint account;{!isDelegate && " and"}
      </li>
      {!isDelegate && (
        <li>
          can view and modify all information, including linked accounts and
          profile information, within my{" "}
          {EMPOWER_PERSONAL_DASHBOARD}
          .
        </li>
      )}
    </ol>
    <a
      href={JOINT_ACCOUNT_HOLDER_ACCESS_RIGHTS_LINK_REBRANDED}
      className="pc-u-ml- milli"
      target="_blank"
      rel="noopener noreferrer"
    >
      {"Full disclosure"}
    </a>
  </div>
);

JointAccountConsent.propTypes = {
  applicantName: PropTypes.string,
  isDelegate: PropTypes.bool,
};

JointAccountConsent.defaultProps = {
  applicantName: "",
  isDelegate: false,
};

export default JointAccountConsent;
