import React from "react";
import PropTypes from "prop-types";
import FundAllocationTable from "components/FundAllocationTable";

const FundAllocationComparison = ({
  currentAllocation,
  newAllocation,
  currentAllocationHeaders,
  newAllocationHeaders,
  accessors,
  formatters,
}) => {
  return (
    <div className="pc-layout">
      <div className="pc-layout__item pc-u-4/9">
        <FundAllocationTable
          className="qa-fund-allocation-current"
          data={currentAllocation}
          headers={currentAllocationHeaders}
          formatters={formatters}
          accessors={accessors}
        />
      </div>
      <div className="pc-layout__item pc-u-1/9 u-text-center">
        <div className="fund-allocation-arrow">
          <div className="fund-allocation-arrow__body" />
          <div className="fund-allocation-arrow__tip" />
        </div>
      </div>
      <div className="pc-layout__item pc-u-4/9">
        <FundAllocationTable
          className="qa-fund-allocation-new"
          data={newAllocation}
          headers={newAllocationHeaders}
          formatters={formatters}
          accessors={accessors}
        />
      </div>
    </div>
  );
};

FundAllocationComparison.propTypes = {
  currentAllocation: PropTypes.array,
  newAllocation: PropTypes.array,
  currentAllocationHeaders: PropTypes.array,
  newAllocationHeaders: PropTypes.array,
  formatters: PropTypes.arrayOf(PropTypes.func),
  accessors: PropTypes.arrayOf(PropTypes.func),
};

FundAllocationComparison.defaultProps = {
  currentAllocation: [],
  newAllocation: [],
  formatters: undefined,
  accessors: undefined,
  currentAllocationHeaders: ["Current Funds"],
  newAllocationHeaders: ["Funds for New Investment Mix"],
};

export default FundAllocationComparison;
