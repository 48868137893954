import memoizeOne from "memoize-one";

export const SYSTEM_TAGS = {
  TAX_RELATED: "Tax Related",
  BUSINESS: "Business",
  REIMBURSABLE: "Reimbursable",
  MEDICAL: "Medical",
  MANUAL_TRANSACTION: "Manual Transaction",
};

export default memoizeOne((tags) => {
  let updatedTags = Object.assign([], tags);

  updatedTags = updatedTags.map((tag) => ({
    value: tag.tagId,
    label: tag.isCustom ? tag.tagName : SYSTEM_TAGS[tag.tagName],
    isCustom: tag.isCustom,
  }));

  updatedTags.sort((tagA, tagB) => {
    // Sort in ascending order when both tags are custom tags
    if (tagA.isCustom && tagB.isCustom) {
      const aName = tagA.label?.toLowerCase();
      const bName = tagB.label?.toLowerCase();

      if (aName > bName) {
        return 1;
      } else if (aName < bName) {
        return -1;
      }

      return 0;
    }

    if (tagA.isCustom) {
      return 1;
    }

    if (tagB.isCustom) {
      return -1;
    }

    // When both are system tags sort based on tag id.
    return tagA.value - tagB.value;
  });

  return updatedTags;
});
