/* eslint-disable no-useless-escape */
import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import AbstractForm from "components/common/form/AbstractForm";
import EmailPreviewModal from "./EmailPreviewModal";
import Services from "services";
import parseResponseErrors from "libs/pcap/utils/response";

const RECIPIENTS_INPUT_PLACEHOLDER =
  "Enter your friends’ emails separated by commas";
const PREVIEW_LABEL = "Preview Email";
const SEND_LABEL = "Send Invitation";
const CONFIRMATION_MESSAGE = `Thank you for sharing ${EMPOWER_PERSONAL_DASHBOARD} with your friends and family!`;
const CLOSE_TOAST_DELAY = 2500;

const getInitialState = (props) => ({
  recipients: "",
  subject: props.subject,
  body: props.body,
  reminder: props.reminder,
});

class ShareViaEmail extends AbstractForm {
  constructor(props) {
    super(props);

    this.state = {
      model: getInitialState(props),
      isEmailPreviewModalOpen: false,
    };

    this.handleEmailPreviewOpen = this.handleEmailPreviewOpen.bind(this);
    this.handleEmailPreviewClose = this.handleEmailPreviewClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  sendEmail() {
    return new Promise((resolve, reject) => {
      Services.Referral.shareViaEmail(
        { ...this.state.model },
        (err, response) => {
          const errors = parseResponseErrors(err, response);
          if (errors) {
            reject(errors[0]);
            return;
          }
          resolve(response);
        }
      );
    });
  }

  handleSubmit(e) {
    const { setConfirmationToastMessage } = this.props;
    if (e) {
      e.preventDefault();
    }
    const validation = this.validate();

    if (validation.valid === false) {
      return;
    }

    this.setState({ isEmailPreviewModalOpen: false });
    this.sendEmail()
      .then(() => {
        setConfirmationToastMessage(CONFIRMATION_MESSAGE);
        setTimeout(() => {
          setConfirmationToastMessage("");
          AppRouter.navigate(DASHBOARD_URL);
        }, CLOSE_TOAST_DELAY);
      })
      .catch((error) => {
        setConfirmationToastMessage(error);
        setTimeout(() => {
          setConfirmationToastMessage("");
        }, CLOSE_TOAST_DELAY);
      });
  }

  handleEmailPreviewOpen() {
    this.setState({ isEmailPreviewModalOpen: true });
  }

  handleEmailPreviewClose() {
    this.setState({ isEmailPreviewModalOpen: false });
  }

  render() {
    const { schema } = this.props;
    return (
      <form
        className="referral-center__share-via-email"
        onSubmit={this.handleSubmit}
      >
        <label className={`pc-label u-sentence-case`}>
          Invite Your Friends
        </label>
        <Input
          ref={this.storeInputRef}
          type="text"
          className="referral-center__share-via-email-input input--huge qa-recipients-input"
          inputGroupClassName="referral-center__share-via-email-input-group"
          name="recipients"
          value={this.state.model.recipients}
          onChange={this.handleInputChange}
          validator={schema.properties.recipients}
          placeholder={RECIPIENTS_INPUT_PLACEHOLDER}
          sizeVariation="full"
          errorPlaceholder={true}
        />
        <div
          className={`referral-center__share-via-email-buttons referral-center__share-via-email-buttons-rebrand`}
        >
          <button
            type="button"
            className={`pc-btn qa-preview-email`}
            onClick={this.handleEmailPreviewOpen}
          >
            {PREVIEW_LABEL}
          </button>
          <button
            type="submit"
            className={`pc-btn pc-btn--primary qa-send-invite`}
          >
            {SEND_LABEL}
          </button>
        </div>
        <EmailPreviewModal
          handleInputChange={this.handleInputChange}
          handleModalClosed={this.handleEmailPreviewClose}
          isOpen={this.state.isEmailPreviewModalOpen}
          RECIPIENTS_INPUT_PLACEHOLDER={RECIPIENTS_INPUT_PLACEHOLDER}
          model={this.state.model}
          schema={this.props.schema}
        />
      </form>
    );
  }
}

ShareViaEmail.propTypes = {
  recipients: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.string,
  reminder: PropTypes.bool,
  confirmationToastMessage: PropTypes.string.isRequired,
  setConfirmationToastMessage: PropTypes.func.isRequired,
};

const requiredStringField = {
  required: true,
  type: "string",
  allowEmpty: false,
  messages: {
    required: "This field is required",
    allowEmpty: "This field is required",
  },
};

const validateEmailList = (emailList) => {
  const emails = emailList.replace(/\s/g, "").split(",");
  let valid = true;
  var regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  for (var i = 0; i < emails.length; i++) {
    if (emails[i] === "" || !regex.test(emails[i])) {
      valid = false;
    }
  }
  return valid;
};

ShareViaEmail.defaultProps = {
  recipients: "",
  subject: "",
  body: "",
  reminder: true,
  schema: {
    type: "object",
    properties: {
      recipients: {
        required: true,
        type: "string",
        allowEmpty: false,
        conform: validateEmailList,
        messages: {
          conform: "Please enter a valid email or list of emails.",
        },
      },
      subject: requiredStringField,
      body: requiredStringField,
      reminder: {
        required: true,
        type: "bool",
      },
    },
  },
};

export default ShareViaEmail;
