import React from "react";
export default function ChangeJobs2() {
  return (
    <svg
      width="110px"
      height="110px"
      viewBox="0 0 110 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ACE_ChangeJobs2</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="ACE_ChangeJobs_Retail_B-Without-web"
          transform="translate(-75.000000, -62.000000)"
        >
          <g id="ACE_ChangeJobs2" transform="translate(75.000000, 62.000000)">
            <g filter="url(#filter-1)" id="circle">
              <g transform="translate(0.000000, -0.000611)">
                <path
                  d="M110,54.9992369 C110,85.3747971 85.3759816,110 55,110 C24.6255445,110 0,85.3747971 0,54.9992369 C0,24.6252029 24.6255445,0 55,0 C85.3759816,0 110,24.6252029 110,54.9992369"
                  id="Fill-1"
                  fill="#FDF8E7"
                ></path>
              </g>
            </g>
            <g transform="translate(23.137093, 22.462458)" strokeWidth="1.5">
              <polyline
                stroke="#DB1830"
                strokeLinecap="round"
                points="14.5404059 23.5768194 21.4235395 30.6825139 33.0161855 16.0418194"
              ></polyline>
              <path
                d="M42.6559931,23.3635417 C42.6559931,33.6072917 34.2025038,41.9122917 23.7775183,41.9122917 C13.350978,41.9122917 4.90059832,33.6072917 4.90059832,23.3635417 C4.90059832,13.1182639 13.350978,4.81326389 23.7775183,4.81326389 C34.2025038,4.81326389 42.6559931,13.1182639 42.6559931,23.3635417 Z"
                stroke="#002157"
              ></path>
              <path
                d="M36.0419968,43.3827778 C32.4612747,45.5063889 28.2648488,46.7255556 23.7776738,46.7255556 C10.6457682,46.7255556 0,36.2663889 0,23.3627778 C0,10.4591667 10.6457682,0 23.7776738,0 C36.9095795,0 47.5537928,10.4591667 47.5537928,23.3627778 C47.5537928,27.56875 46.4234476,31.5165278 44.4426226,34.9265278"
                stroke="#002157"
              ></path>
              <polygon
                stroke="#002157"
                points="41.3334737 45.8848194 47.6491025 39.6789861 66.465385 58.3514861 60.1513111 64.5573194"
              ></polygon>
              <polyline
                stroke="#002157"
                points="41.9362727 44.4763611 38.7395881 41.3749722 42.8504996 37.3355278 46.0471842 40.4369167"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
