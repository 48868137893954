import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";

const BaseModel = {
  userAccountId: "",
  ticker: "",
  cusip: "",
  description: "",
  quantity: "",
  price: "",
  value: "",
  source: "USER",
  costBasis: "",
};

class BaseHoldingsAddRow extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: Object.assign({}, BaseModel, props.model),
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.isNotExistingHolding = this.isNotExistingHolding.bind(this);
  }

  componentDidMount() {
    let tickerInput = this.inputElements.find(
      (input) => input.props.name === "ticker"
    );
    if (tickerInput) {
      tickerInput.focus();
    }
  }

  handleSubmit(ev) {
    ev.preventDefault();
    if (this.validate().valid) {
      this.setState({ loading: true });
      let model = this.state.model;
      model.value = model.price * model.quantity;
      this.props
        .onSubmit(this.state.model)
        .then((holding) => {
          if (this.props.onSaved) {
            this.props.onSaved(holding);
          }
          this.setState({ loading: false });
        })
        .catch((errors) => {
          this.setState({ errors, loading: false });
        });
    }
  }

  isNotExistingHolding(v) {
    // Empty field
    if (!v) {
      return true;
    }
    // Does the holding already exist?
    return !this.props.holdings.find((h) => h.ticker === v);
  }
  render() {
    return "NOOP"; /* NOOP */
  }
}

BaseHoldingsAddRow.defaultProps = {
  holdings: [],
  schema: {
    type: "object",
    properties: {
      ticker: {
        required: true,
        type: "string",
        allowEmpty: false,
        messages: {
          required: "Ticker is required.",
          conform: "This security already exists for this account.",
          allowEmpty: "Please enter a ticker",
        },
      },
      quantity: {
        required: true,
        type: "number",
        allowEmpty: false,
        minimum: 0,
        messages: {
          required: "Please enter a quantity.",
          allowEmpty: "Please enter a quantity.",
          type: "Must be a numeric value.",
          minimum: "Please enter a quantity greater than 0.",
        },
      },
      price: {
        required: true,
        type: "number",
        allowEmpty: false,
        minimum: 0,
        messages: {
          required: "Please enter a price.",
          type: "Must be a numeric value.",
          allowEmpty: "Please enter a price.",
          minimum: "Please enter a price greater than 0.",
        },
      },
      description: {
        required: false,
        type: "string",
      },
      costBasis: {
        required: false,
        type: "number",
        minimum: 0,
        messages: {
          type: "Must be a numeric value.",
          minimum: "Please enter a costBasis greater than 0.",
        },
      },
    },
  },
};

BaseHoldingsAddRow.propTypes = {
  model: PropTypes.object.isRequired,
  holdings: PropTypes.array,
  columns: PropTypes.array.isRequired,
  errors: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onFetchQuote: PropTypes.func,
  onSaved: PropTypes.func,
};

export default BaseHoldingsAddRow;
