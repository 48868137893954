import Services from "services";
import modelToServer from "./mappers/toServer";
import { promisify } from "utils/service";
import { trackEvent } from "components/common/ComponentAnalytics";

const UPDATE_ENROLLMENT_V2_ACTION = "updateV2";

function saveInternal(action, model, options) {
  trackEvent("Open Cash Account Accessor", "Log Person roles", {
    personRoles: JSON.stringify(model.personRoles),
  });

  const organizationMailCash = "cash@personalwealth.empower.com";
  // Logging an mixpanel to debug a prod issue where person ID is -1.
  if (model.personRoles && model.personRoles["-1"] === "PRIMARY") {
    trackEvent(
      "Open PCB Account",
      `Invalid Person ID detected while ${action.slice(0, -1)}ing`,
      {
        personRoles: Object.keys(model.personRoles),
      }
    );

    return Promise.reject([
      `Invalid Person ID. Please try again later or contact the support team at ${organizationMailCash}.`,
    ]);
  }

  const params = Object.assign(
    modelToServer(model, Boolean(options?.isEnabledCashOnboardingApiV2)),
    options
  );
  return promisify(Services.PCBEnrollment[action])(params);
}

/**
 * Creates the provided enrollment model.
 * Optionally processes the portions of the object specified by `options` argument.
 *
 * @param {Object}  model                     the enrollment object
 * @param {Object}  options                   the options
 * @param {String}  options.source            the source from where it is being updated
 *
 * @returns {Promise} The request promise.
 */
export function create(model, options) {
  return saveInternal("create", model, options);
}

/**
 * Updates the provided enrollment model.
 * Optionally processes the portions of the object specified by `options` argument.
 *
 * @param {Object}  model                     the enrollment object
 * @param {Object}  options                   the options
 * @param {String}  options.source            the source from where it is being updated
 *
 * @returns {Promise} The request promise.
 */
export function update(model, options) {
  return saveInternal("update", model, options);
}

/**
 * Creates/Updates the provided enrollment model.
 * The action is defined by the presence of `model.id` property.
 * Optionally processes the portions of the object specified by `options` argument.
 *
 * @param {Object}  model                                 the enrollment object
 * @param {Object}  options                               the options
 * @param {String}  options.source                        the source from where it is being updated
 * @param {Boolean}  options.isEnabledCashOnboardingApiV2  the cash onboarding API v2 is enabled
 *
 * @returns {Promise} The request promise.
 */
export function save(model, options) {
  const updateActionName = options?.isEnabledCashOnboardingApiV2
    ? UPDATE_ENROLLMENT_V2_ACTION
    : "update";
  const action = model.id == null ? "create" : updateActionName;
  return saveInternal(action, model, options);
}

export function cancel(id) {
  return promisify(Services.PCBEnrollment.cancel)({
    id,
  });
}
