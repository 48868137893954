import Services from "services";

const markUserMessageAsViewed = (userMessageId, callback) => {
  Services.UserMessage.markViewed(
    {
      userMessageIds: "[" + userMessageId + "]",
      actions: '["markViewed"]',
    },
    callback
  );
};

export default markUserMessageAsViewed;
