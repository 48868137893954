import { API_FORMAT, DISPLAY_FORMAT } from "libs/pcap/utils/date2";
import moment from "moment";
import {
  FREQUENCY_ONE_TIME,
  MODE_RECURRING,
  MODE_ONE_TIME,
} from "components/transferFunds/utils/constants";
import { isUndefined } from "underscore";

/**
 * Takes a model object from Transfer Funds and formats the properties for sending to the server
 *
 * @param {Object} model the model object containing transfer details
 * @return {Object} the formatted model
 */
export default function (model) {
  let updatedModel = Object.assign({}, model);

  if (updatedModel.startDate) {
    updatedModel.startDate = moment(model.startDate, DISPLAY_FORMAT).format(
      API_FORMAT
    );
  }

  if (
    !updatedModel.frequency ||
    updatedModel.frequency === FREQUENCY_ONE_TIME
  ) {
    delete updatedModel.frequency;
    updatedModel.mode = MODE_ONE_TIME;
  } else {
    updatedModel.mode = MODE_RECURRING;
  }

  //Deleting the unwanted fields for API request
  delete updatedModel.nextTransferDate;
  delete updatedModel.state;
  delete updatedModel.actions;
  delete updatedModel.statements;

  // Fields aren't required for API request, so delete them if they're undefined.
  // If value is '0', it should be included in the model.
  if (isUndefined(updatedModel.taxWithholdingState))
    delete updatedModel.taxWithholdingState;
  if (isUndefined(updatedModel.taxWithholdingFederal))
    delete updatedModel.taxWithholdingFederal;

  return updatedModel;
}
