/* eslint-disable react/display-name */
import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import Services from "services";
import moment from "moment";
import "libs/pcap/utils/date";
import parseResponseErrors from "libs/pcap/utils/response";
import mixpanel from "mixpanel";
import DividendsReceivedWidget from "components/dividendsReceived/DividendsReceivedWidget";
import DateUtil from "libs/pcap/utils/date";
import { getEventData } from "components/opportunitiesSummary/InsightsUtils";
import deepCopy from "deep-copy";

const PROPER_DATE_FORMAT = DateUtil.API_FORMAT;

export const renderButtons =
  (currentParentComponent, message) => (title, btnLabel, parentComponent) => {
    const renderedInInsights = currentParentComponent === "Insight Widget";
    return (
      <div className="dividends-received__button-container">
        <div>{title}</div>
        <a
          href={
            IS_EMPOWER && !IS_ADVISOR
              ? ENROLL_URL_PREFIX + "/select-accounts"
              : "/page/enrollment/start#/select-accounts"
          }
          data-attribution-source={`${parentComponent}_Open_An_Account`}
          onClick={() => {
            mixpanel.trackEvent(
              renderedInInsights
                ? "Click Insight"
                : `Click on Action: "${title}" button in the Dividends Received`,
              renderedInInsights
                ? getEventData({ message, buttonLabel: btnLabel })
                : { component: currentParentComponent }
            );
          }}
          className="pc-btn pc-btn--primary pc-btn--tiny"
        >
          {btnLabel}
        </a>
      </div>
    );
  };

class DividendsReceived extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      normalDividends: 0,
      taxAdvantagedDividends: 0,
      totalDividends: 0,
      normalDividendsMonthly: 0,
      taxAdvantagedDividendsMonthly: 0,
      totalDividendsMonthly: 0,
      loading: true,
      noTaxAdvantaged: true,
      noTaxable: true,
      isRetired: false,
    };

    this.onAccountsFetched = this.onAccountsFetched.bind(this);
  }

  componentDidMount() {
    mixpanel.trackEvent("View Strategy Dividends", {
      component: "DividendsReceivedContainer",
    });
    this.watchedAccountsService = Services.Accounts.get.watch(
      this.onAccountsFetched,
      this
    );

    mixpanel.trackEvent(
      `View Dividends Received in ${this.props.parentComponent}`,
      {
        component: this.props.parentComponent,
      }
    );
  }

  onAccountsFetched(err, response) {
    const errors = parseResponseErrors(err, response);
    if (errors) {
      this.setState({
        loading: false,
        errors: errors,
      });
      return;
    }

    const allAccounts =
      (response && response.spData && response.spData.accounts) || [];
    const onUsAccounts = allAccounts.filter(
      (account) => account.isOnUs || account.isOnUsRetirement
    );

    _.each(onUsAccounts, (account) => {
      if (account.isTaxDeferredOrNonTaxable) {
        this.setState({
          noTaxAdvantaged: false,
        });
      } else {
        this.setState({
          noTaxable: false,
        });
      }
    });

    const onUsAccountIds = onUsAccounts.map((account) => account.userAccountId);

    return Promise.all([
      this.fetchTransactionCategories(),
      this.fetchTransactions(onUsAccountIds),
      this.fetchRetirementStatus(),
    ]).then(
      ([
        categoriesResponse,
        transactionsResponse,
        retirementStatusResponse,
      ]) => {
        this.onFetched(
          categoriesResponse,
          transactionsResponse,
          retirementStatusResponse
        );
      },
      (errors) => {
        this.setState({
          loading: false,
          errors: errors,
        });
      }
    );
  }

  fetchRetirementStatus() {
    return new Promise((resolve, reject) => {
      Services.Person.get((err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }
        resolve(response.spData.isRetired);
      });
    });
  }

  fetchTransactionCategories() {
    return new Promise((resolve, reject) => {
      Services.TransactionCategories.get((err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }
        resolve(response);
      });
    });
  }

  fetchTransactions(onUsAccountIds) {
    let endDate = moment();

    return new Promise((resolve, reject) => {
      Services.Transactions.get(
        {
          startDate: endDate.clone().startOf("year").format(PROPER_DATE_FORMAT),
          endDate: endDate.format(PROPER_DATE_FORMAT),
          userAccountIds: JSON.stringify(onUsAccountIds),
        },
        (err, response) => {
          let errors = parseResponseErrors(err, response);
          if (errors) {
            reject(errors);
            return;
          }
          resolve(deepCopy(response));
        }
      );
    });
  }

  onFetched(
    categoriesResponse,
    transactionsResponse,
    retirementStatusResponse
  ) {
    let endDate = new moment();
    const transactionCategories = categoriesResponse.spData || [];
    const DividendKey = transactionCategories.find((category) =>
      ["DIVIDEND_RECEIVED"].includes(category.transactionCategoryKey)
    ).transactionCategoryId;
    const TaxAdvantageDividendKey = transactionCategories.find((category) =>
      ["DIVIDEND_RECEIVED_TAX_ADVANTAGED"].includes(
        category.transactionCategoryKey
      )
    ).transactionCategoryId;

    const transactions =
      (transactionsResponse.spData &&
        transactionsResponse.spData.transactions) ||
      [];
    let normalDividends = 0;
    let normalDividendsMonthly = 0;
    let taxAdvantagedDividends = 0;
    let taxAdvantagedDividendsMonthly = 0;
    _.each(transactions, (transaction) => {
      if (transaction.categoryId === DividendKey) {
        normalDividends += transaction.amount;
        if (
          transaction.transactionDate >=
          endDate.clone().startOf("month").format(PROPER_DATE_FORMAT)
        ) {
          normalDividendsMonthly += transaction.amount;
        }
      }
      if (transaction.categoryId === TaxAdvantageDividendKey) {
        taxAdvantagedDividends += transaction.amount;
        if (
          transaction.transactionDate >=
          endDate.clone().startOf("month").format(PROPER_DATE_FORMAT)
        ) {
          taxAdvantagedDividendsMonthly += transaction.amount;
        }
      }
    });
    this.setState(
      {
        normalDividends,
        taxAdvantagedDividends,
        normalDividendsMonthly,
        taxAdvantagedDividendsMonthly,
        totalDividends: normalDividends + taxAdvantagedDividends,
        totalDividendsMonthly:
          normalDividendsMonthly + taxAdvantagedDividendsMonthly,
        loading: false,
        isRetired: retirementStatusResponse,
      },
      () => {
        this.props.finishedLoading?.();
      }
    );
  }

  componentWillUnmount() {
    if (this.watchedAccountsService) {
      Services.Accounts.get.unwatch(this.watchedAccountsService);
    }
  }

  render() {
    return (
      <DividendsReceivedWidget
        titleClassName={
          this.props.parentComponent === "Activity Widget" ? "" : "u-text-bold"
        }
        loading={this.state.loading}
        noTaxAdvantaged={this.state.noTaxAdvantaged}
        noTaxable={this.state.noTaxable}
        isRetired={this.state.isRetired}
        totalDividends={this.state.totalDividends}
        totalDividendsMonthly={this.state.totalDividendsMonthly}
        normalDividends={this.state.normalDividends}
        taxAdvantagedDividends={this.state.taxAdvantagedDividends}
        renderButtons={renderButtons(
          this.props.parentComponent,
          this.props.message
        )}
        isEmpower={IS_EMPOWER}
      />
    );
  }
}

DividendsReceived.propTypes = {
  finishedLoading: PropTypes.func,
  message: PropTypes.object,
  parentComponent: PropTypes.string,
};

DividendsReceived.defaultProps = {
  finishedLoading: undefined,
  message: undefined,
  parentComponent: undefined,
};

export default DividendsReceived;
