import React from "react";
import PropTypes from "prop-types";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";

const DocumentUploaderHeader = ({
  className,
  documentTypesList,
  maxFileSize,
  headerCopy,
}) => {
  const listTypes = documentTypesList.join(", ");
  return (
    <div
      className={`document-uploader-header pc-layout__item l-spaced pc-u-mb- ${className}`}
    >
      <div className="document-uploader-header__description">
        <p
          className="pc-u-mb0"
          dangerouslySetInnerHTML={{ __html: headerCopy }}
        />
      </div>
      <div className="document-uploader-header__info-tooltips">
        <p className="pc-u-mb0">
          Maximum file size: {maxFileSize}. File types:{" "}
          <InfoTooltipIcon
            title={`Supported file types include:
              ${listTypes}.
              <br><br>
              Encrypted files, DocuSign PDFs and embedded attachments are not supported.
            `}
          />
        </p>
        <p className="pc-u-mb0">
          Document photography tips{" "}
          <InfoTooltipIcon
            title={`1.Adequate Lightning: Use flash in low light.
              <br><br>
              2.Reduce Shadows: Aim camera toward the light instead of against.
              <br><br>
              3.Zoom in: Fill camera frame with as much of the document as possible.
              <br><br>
              4.Signature: (If any) Capture entire signature within the frame.
            `}
          />
        </p>
      </div>
    </div>
  );
};

DocumentUploaderHeader.propTypes = {
  className: PropTypes.string,
  documentTypesList: PropTypes.array,
  maxFileSize: PropTypes.string,
  headerCopy: PropTypes.node,
};

DocumentUploaderHeader.defaultProps = {
  className: "",
  documentTypesList: [],
  maxFileSize: "",
  headerCopy: "",
};

export default DocumentUploaderHeader;
