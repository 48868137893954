import React from "react";
import { formatCurrency } from "libs/pcap/utils/format";
import InfoTooltipText from "components/common/InfoTooltipText";
import { isEmpty } from "underscore";

export default [
  {
    className: "pc-transactions-grid-cell--date",
  },
  {
    className:
      "pc-transactions-grid-cell--cashflow-account-details-description u-text-right pc-u-pr0",
    formatter: function description() {
      return (
        <svg className="split-transaction__view-split-icon icon-svg">
          <use href="#icon-split-transaction"></use>
        </svg>
      );
    },
  },
  {
    accessor: (d) => d.categoryName,
    className: "pc-transactions-grid-cell--category u-clip-text",
    isFilterable: true,
  },
  {
    accessor: (d) => d,
    className: "pc-transactions-grid-cell--tags u-clip-text",
    formatter: function tags(transaction) {
      //If the transaction is duplicate, then we should show duplicate here instead of tags
      if (transaction.isDuplicate) {
        return <span>(Duplicate)</span>;
      }

      if (!isEmpty(transaction.selectedTagNames)) {
        let tagsText = transaction.selectedTagNames[0];
        if (transaction.selectedTagNames.length > 1) {
          // eslint-disable-next-line no-magic-numbers
          tagsText = `${tagsText.slice(0, 4)}...,(${
            transaction.selectedTagNames.length
          })`;
        }
        return (
          <InfoTooltipText
            text={tagsText}
            className="qa-tag-name"
            tooltipContent={transaction.selectedTagNames.join(", ")}
            tooltipProps={{ placement: "left" }}
          ></InfoTooltipText>
        );
      }
      return "";
    },
  },
  {
    header: "Amount",
    accessor: (transaction) => transaction,
    className: "pc-transactions-grid-cell--amount u-text-right",
    formatter: function amount(transaction) {
      const value = transaction.isCredit
        ? transaction.amount
        : transaction.amount * -1;
      return (
        <span
          title={transaction.isCredit ? "Credit" : "Debit"}
          className={value > 0 ? "positive" : ""}
        >
          {formatCurrency(value)}
        </span>
      );
    },
  },
];
