import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { noop } from "underscore";
import TollGateHeader from "./TollGateHeader";
import TollGateBottomImage from "./TollGateBottomImage";
import AppointmentContainer from "components/scheduling/AppointmentContainer/AppointmentContainer";
import withAppointmentCustomContent from "components/higherOrderComponents/withAppointmentCustomContent";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import ReferralHeader from "./referralHeader";
import {
  AB_TEST_GROUP_A,
  AB_TEST_GROUP_B,
} from "libs/pcap/utils/getABTestGroupForCurrentUser";

export const TOLL_GATE_OFF_RAMP_TITLE = "Confirm free review and get started";
export const TOLL_GATE_OFF_RAMP_HEADER = `You are getting a free review from a financial professional to help you get the most out of your experience.`;
export const TOLL_GATE_OFF_RAMP_SUBHEADER = `Our financial professional tools are easy to use, but they truly shine when combined with our financial knowledge. We'll review your current situation and then share our personalized assessment focusing on opportunities at hand, yours to keep and completely free.`;
export const REFERRAL_ONBOARDING_TITLE =
  "As a referral, you're entitled to special rewards!";
export const REFERRAL_ONBOARDING_SUB_TITLE =
  "You were referred by one of our clients";

export const ReferralOnboardingContent = (
  <p>
    Connect with their advisor for a comprehensive financial analysis. If you
    become a client with $100k or more under management, you&apos;ll receive a
    free trial of our advisory services for up to 6 months.
    <a
      href="https://docs.empower.com/epw/client-referral-program.pdf"
      target="_blank"
      rel="noreferrer"
    >
      *(Terms and Conditions apply)
    </a>
  </p>
);

const TollGateOffRampPageView = ({
  advisorData,
  componentName,
  duration,
  hasOnboardingAppointmentPage,
  isClient,
  isFirstUseAppointment,
  isMadLibsInterjectionReviewModal,
  isTopicPreselected,
  is401kEnrollment,
  onClose,
  referralOnboardingTest,
  referrerName,
  subtypes,
  type,
}) => {
  let referralSubTitle = "";
  if (referralOnboardingTest === AB_TEST_GROUP_B) {
    if (referrerName) {
      referralSubTitle = REFERRAL_ONBOARDING_SUB_TITLE + `, ${referrerName}.`;
    } else {
      referralSubTitle = REFERRAL_ONBOARDING_SUB_TITLE + ".";
    }
  }
  const AppointmentContainerWithCustomContent =
    withAppointmentCustomContent(AppointmentContainer);

  useEffect(() => {
    if (referralOnboardingTest === AB_TEST_GROUP_A) {
      ComponentAnalytics.trackView("Appointment Scheduler Modal Ref_On");
    } else if (referralOnboardingTest === AB_TEST_GROUP_B) {
      ComponentAnalytics.trackView("Appointment Scheduler Modal Ref_Ont");
    } else {
      ComponentAnalytics.trackView("Appointment Scheduler Modal");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickIcon = (iconName) => {
    if (referralOnboardingTest === AB_TEST_GROUP_A) {
      ComponentAnalytics.trackClick(
        null,
        "TollGateOffRampPageView Ref_On",
        `${iconName} Icon on Schedule Appointment`
      );
    } else if (referralOnboardingTest === AB_TEST_GROUP_B) {
      ComponentAnalytics.trackClick(
        null,
        "TollGateOffRampPageView Ref_Ont",
        `${iconName} Icon on Schedule Appointment`
      );
    } else {
      ComponentAnalytics.trackClick(
        null,
        "TollGateOffRampPageView",
        `${iconName} Icon on Schedule Appointment`
      );
    }
  };

  return (
    <div className="toll_gate_off_ramp__container toll_gate_off_ramp__container--responsive">
      {referralOnboardingTest === AB_TEST_GROUP_B ? (
        <ReferralHeader
          referralSubTitle={referralSubTitle}
          referralContent={ReferralOnboardingContent}
          title={REFERRAL_ONBOARDING_TITLE}
        />
      ) : (
        <TollGateHeader
          accordionHeader={TOLL_GATE_OFF_RAMP_HEADER}
          accordionText={TOLL_GATE_OFF_RAMP_SUBHEADER}
          expandedByDefault={false}
          title={TOLL_GATE_OFF_RAMP_TITLE}
        />
      )}

      <AppointmentContainerWithCustomContent
        advisorData={advisorData}
        componentName={componentName}
        duration={duration}
        hasOnboardingAppointmentPage={hasOnboardingAppointmentPage}
        isClient={isClient}
        isFirstUseAppointment={isFirstUseAppointment}
        isMadLibsInterjectionReviewModal={isMadLibsInterjectionReviewModal}
        isTopicPreselected={isTopicPreselected}
        is401kEnrollment={is401kEnrollment}
        onDone={onClose}
        referralOnboardingTest={referralOnboardingTest}
        subtypes={subtypes}
        type={type}
      />
      <TollGateBottomImage onClickIcon={handleClickIcon} />
    </div>
  );
};

export default TollGateOffRampPageView;

TollGateOffRampPageView.propTypes = {
  advisorData: PropTypes.object,
  componentName: PropTypes.string,
  duration: PropTypes.number,
  hasOnboardingAppointmentPage: PropTypes.bool,
  isClient: PropTypes.bool,
  isFirstUseAppointment: PropTypes.bool,
  isMadLibsInterjectionReviewModal: PropTypes.bool,
  isTopicPreselected: PropTypes.bool,
  is401kEnrollment: PropTypes.bool,
  onClose: PropTypes.func,
  referralOnboardingTest: PropTypes.string,
  referrerName: PropTypes.string,
  subtypes: PropTypes.array,
  type: PropTypes.string,
};

TollGateOffRampPageView.defaultProps = {
  advisorData: {},
  componentName: "Appointment Scheduler Modal",
  duration: undefined,
  hasOnboardingAppointmentPage: false,
  isClient: false,
  isFirstUseAppointment: false,
  isMadLibsInterjectionReviewModal: false,
  isTopicPreselected: false,
  is401kEnrollment: false,
  onClose: noop,
  referralOnboardingTest: "",
  referrerName: "",
  subtypes: undefined,
  type: undefined,
};
