import PropTypes from "prop-types";
import React from "react";
import { promisify } from "utils/service";
import Services from "services";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import SuggestExistingSites from "components/SuggestExistingSites";
import { noop } from "lodash";

export default class SuggestExistingSitesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.handleFailure = this.handleFailure.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    this.cancelableService = makeCancelablePromise(
      this.props.getAccountsBySite({ siteId: this.props.siteId })
    );
    this.cancelableService.promise.then(this.handleSuccess, this.handleFailure);
  }

  handleSuccess(response) {
    if (response.accounts && response.accounts.length > 0) {
      this.setState({
        loading: false,
        errors: undefined,
        accounts: response.accounts,
      });
    } else {
      this.props.onLinkNewAccount();
    }
  }

  handleFailure(errors) {
    if (this.cancelableService.isCanceled()) {
      return;
    }

    this.setState({
      loading: false,
      errors: errors,
    });
  }

  componentWillUnmount() {
    if (this.cancelableService) {
      this.cancelableService.cancel();
    }
  }

  render() {
    const { errors, loading, accounts } = this.state;
    const { onUpdateSite, onLinkNewAccount, siteId, onBack } = this.props;
    return (
      <SuggestExistingSites
        errors={errors}
        loading={loading}
        accounts={accounts}
        onUpdateSite={onUpdateSite}
        onLinkNewAccount={onLinkNewAccount}
        siteId={siteId}
        onBack={onBack}
      />
    );
  }
}

SuggestExistingSitesContainer.propTypes = {
  getAccountsBySite: PropTypes.func,
  onUpdateSite: PropTypes.func,
  onLinkNewAccount: PropTypes.func,
  onBack: PropTypes.func,
  siteId: PropTypes.number.isRequired,
};

SuggestExistingSitesContainer.defaultProps = {
  getAccountsBySite: promisify(Services.Accounts.getAccountsBySite),
  onUpdateSite: noop,
  onLinkNewAccount: noop,
  onBack: noop,
};
