import React from "react";
import LinkAccountVideo from "./LinkAccountVideo/LinkAccountVideo";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import PropTypes from "prop-types";

const LinkAccountVideoContainer = ({ errors, loading, url }) => {
  return (
    <div className="link-account-video__container">
      <Message className="pc-u-mb" severity="error" messages={errors} />
      <LoadingOverlay active={loading} />
      <LinkAccountVideo url={url}></LinkAccountVideo>
    </div>
  );
};

LinkAccountVideoContainer.propTypes = {
  errors: PropTypes.array,
  loading: PropTypes.bool,
  url: PropTypes.string,
};

LinkAccountVideoContainer.defaultProps = {
  errors: [],
  loading: false,
  url: "https://empower.wistia.com/medias/w5lfbgwc6f",
};

export default LinkAccountVideoContainer;
