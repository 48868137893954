import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import memoizeOne from "memoize-one";
import { noop } from "underscore";

import LoadingOverlay from "components/common/LoadingOverlay";
import withTooltips from "components/higherOrderComponents/withTooltips";

import Search from "libs/pcap/table/Search";
import TableClient from "libs/pcap/table/tableClient/TableClient";
import { compareNum } from "libs/pcap/table/data/compare";

import searchData from "libs/pcap/table/data/search";

export const searchConfig = [
  (d) => moment(d.completedDate).format("M/D/YYYY hh:mmA"),
  (d) => d.sourceAccountName,
  (d) => d.sourceAccountFirmName,
  (d) => d.targetAccountName,
  (d) => d.targetAccountFirmName,
  (d) => d.transferAmount,
  (d) => d.isRecurring,
];

export const getFilteredTransfers = (searchInput, transfers, searchConfig) =>
  searchData(searchInput, transfers, searchConfig);

const memoizedGetFilteredTransfers = memoizeOne(getFilteredTransfers);

const tableColumns = (storeTooltipRef) => [
  {
    header: "Completed Date",
    accessor: (d) => d.completedDate,
    formatter: (d) => moment(d).format("M/D/YYYY"),
    isSortable: true,
    sortOrder: "desc",
    comparator: compareNum,
    className: "transfer-manager-table__date-column",
  },
  {
    header: "From",
    accessor: ({ sourceAccountName, sourceAccountFirmName }) => ({
      sourceAccountName,
      sourceAccountFirmName,
    }),
    // eslint-disable-next-line react/display-name, react/prop-types
    formatter: ({ sourceAccountName, sourceAccountFirmName }) => (
      <>
        <div className="u-text-bold">{sourceAccountFirmName}</div>
        {sourceAccountName}
      </>
    ),
    isSortable: false,
    className: "transfer-manager-table__from-column",
  },
  {
    header: "To",
    accessor: ({ targetAccountName, targetAccountFirmName }) => ({
      targetAccountName,
      targetAccountFirmName,
    }),
    // eslint-disable-next-line react/display-name, react/prop-types
    formatter: ({ targetAccountName, targetAccountFirmName }) => (
      <>
        <div className="u-text-bold">{targetAccountFirmName}</div>
        {targetAccountName}
      </>
    ),
    isSortable: false,
    className: "transfer-manager-table__to-column",
  },
  {
    header: "Amount",
    accessor: ({ transferAmount, isRecurring }) => ({
      transferAmount,
      isRecurring,
    }),
    // eslint-disable-next-line react/display-name, react/prop-types
    formatter: ({ isRecurring, transferAmount }) => (
      <div className="l-spaced l-spaced--flush">
        <p>
          {isRecurring === true && (
            <svg
              ref={(el) => {
                storeTooltipRef(el, {
                  placement: "auto",
                });
              }}
              className="icon__calendar js-icon__calendar"
              data-toggle="tooltip"
              title="This is a recurring transfer."
            >
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref="#icon-calendar"
              ></use>
            </svg>
          )}
        </p>
        <p>{transferAmount}</p>
      </div>
    ),
    className: "transfer-manager-table__amount-column",
  },
];

class TransferManagerHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: "",
    };

    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(_results, input) {
    this.setState({
      searchInput: input,
    });
  }

  render() {
    const { loading, transfers, storeTooltipRef } = this.props;
    const { searchInput } = this.state;

    const filteredTransfers = memoizedGetFilteredTransfers(
      searchInput,
      transfers,
      searchConfig
    );

    if (loading) return <LoadingOverlay active={loading} />;

    return (
      <div>
        <Search
          searchConfig={searchConfig}
          containerClassName="transfer-manager-table__search-container"
          label="Search Transfers"
          className="transfer-manager-table__search"
          data={transfers}
          searchInput={this.state.searchInput}
          onSearch={this.handleSearch}
        />
        <TableClient
          zeroState="No transfers to display."
          className="transfer-manager-table-client-container js-transfer-manager-table-client-container"
          headerRowClassName="u-text-bold"
          columns={tableColumns(storeTooltipRef)}
          paginator={{
            start: 0,
            stepSize: 100,
            className: "transfer-manager-paginator-container",
          }}
          data={filteredTransfers}
        />
      </div>
    );
  }
}

TransferManagerHistory.propTypes = {
  loading: PropTypes.bool,
  transfers: PropTypes.array,
  storeTooltipRef: PropTypes.func,
};

TransferManagerHistory.defaultProps = {
  loading: false,
  transfers: [],
  storeTooltipRef: noop,
};

export default withTooltips(TransferManagerHistory);
export { TransferManagerHistory };
