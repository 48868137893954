import { dateStringToStruct } from "libs/pcap/utils/date2";

const SSN_PARTS_NUMBER = 3;

function ssnStringToStruct(ssnString) {
  const ssnParts = ssnString.split("-");
  if (ssnParts.length === SSN_PARTS_NUMBER) {
    return {
      ssn1: ssnParts[0],
      ssn2: ssnParts[1],
      ssn3: ssnParts[2],
    };
  }
}

/**
 * Takes a person model object and converts to API request format for create and update person actions.
 *
 * @param {Object}  model                     the person object
 * @param {Object}  options                   the options
 * @param {String}  options.relationship      the person relationship with `SELF`
 * @param {String}  options.source            the source from where it is being updated
 *
 *
 * @return {Object} the request object
 */

const RELATIONSHIPS_OTHER_THAN_SELF = ["SPOUSE", "SON", "DAUGHTER", "CHILD"];

export default function (model, options = {}) {
  const { relationship, source, refreshStrategy, comment } = options;
  let personAttrs = Object.assign({}, model);

  if (relationship && RELATIONSHIPS_OTHER_THAN_SELF.includes(relationship)) {
    personAttrs.relationship = relationship;
  }

  if (model.taxpayerIdNumber) {
    personAttrs.ssn = ssnStringToStruct(model.taxpayerIdNumber);
    delete personAttrs.taxpayerIdNumber;
  }

  if (model.governmentIdsMap) {
    personAttrs.governmentIds = Object.entries(model.governmentIdsMap).map(
      ([idType, id]) =>
        Object.assign({}, id, {
          type: idType,
          expirationDate: dateStringToStruct(id.expirationDate),
        })
    );
    delete personAttrs.governmentIdsMap;
  }

  if (!model.hasMailingAddress) {
    personAttrs.mailingAddress = null;
  }
  delete personAttrs.hasMailingAddress;

  if (model.patriotActGovtOfficial != null) {
    personAttrs.patriotActGovtOfficial = model.patriotActGovtOfficial === "Y";
  }

  const personObj = {
    person: JSON.stringify(personAttrs),
  };

  if (model.id) {
    personObj.personId = model.id;
  } else {
    // `personRole` is used only in `create` api. The possible values are `OTHER` and `SELF`.
    // Since we never create `SELF` from UI, it is always `OTHER`.
    personObj.personRole = "OTHER";
  }

  if (source) {
    personObj.source = source;
  }

  if (comment) {
    personObj.comment = comment;
  }

  if (refreshStrategy != null) {
    personObj.refreshStrategy = refreshStrategy;
  }

  return personObj;
}
