import React from "react";
import PropTypes from "prop-types";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";

const ZeroStateWidget = ({
  displayName,
  cta,
  link,
  label,
  tooltip,
  buttonAlign,
  buttonClass,
  widgetHeaderClass,
  backgroundName,
}) => {
  const eventBus = window.empower?.eventBus;
  const amplitudeEvents = window.integratedSharedData?.AMPLITUDE_EVENTS;
  const eventBusPayload = { widget: displayName };
  const ZERO_STATE_WIDGET = "ZeroStateWidget";
  const backgroundClass = `${backgroundName}__with-background`;

  const dispatchEventBus = (section) => {
    eventBus?.dispatchAmplitude({
      event_type: amplitudeEvents?.SELECT_BUTTON,
      event_properties: {
        selection: `${ZERO_STATE_WIDGET}${section}`,
        eventBusPayload,
      },
    });
  };

  const handleCtaClick = () => {
    dispatchEventBus(".ctaButton");
    window.location.href = link;
  };

  const handleHeaderClick = () => {
    dispatchEventBus(".header");
  };

  const handleTooltipClick = () => {
    dispatchEventBus(".tooltip");
  };

  return (
    <section
      className={`summary-widget summary-widget__zero-state ${backgroundClass}`}
    >
      <div
        className={`summary-widget__header ${widgetHeaderClass}`}
        onClick={handleHeaderClick}
      >
        <div className="l-spaced l-spaced--flush zero-state__header-container">
          <h2 className="pc-delta pc-u-mb-">
            <a
              onClick={handleHeaderClick}
              href={link}
              className="summary-widget__title--link"
              aria-label={label}
            >
              {displayName}
            </a>
          </h2>
          <InfoTooltipIcon
            title={tooltip}
            container={false}
            parentClassName={backgroundClass}
            tooltipProps={{ trigger: "click", role: "alert" }}
            onClick={handleTooltipClick}
          />
        </div>
      </div>
      <div className={`zero-state__cta ${buttonAlign}`}>
        <button onClick={handleCtaClick} className={`pc-btn ${buttonClass}`}>
          {cta}
        </button>
      </div>
    </section>
  );
};

ZeroStateWidget.defaultProps = {
  tooltip: "",
  buttonAlign: "",
  buttonClass: "",
  widgetHeaderClass: "",
};

ZeroStateWidget.propTypes = {
  displayName: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  buttonAlign: PropTypes.string,
  buttonClass: PropTypes.string,
  widgetHeaderClass: PropTypes.string,
  backgroundName: PropTypes.string.isRequired,
};

export default ZeroStateWidget;
