import PropTypes from "prop-types";
import React from "react";
import withTooltips from "components/higherOrderComponents/withTooltips";
import { CSSTransition } from "react-transition-group";
import Services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import ClickableTooltip from "components/common/ClickableTooltip";
import Mixpanel from "mixpanel";
import { noop } from "underscore";

const FADE_LENGTH = 200;

class TipCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      recentlySkipped: false,
    };

    this.onNextClick = this.onNextClick.bind(this);
  }

  updateMeterInfo(preferenceData) {
    return new Promise((resolve, reject) => {
      Services.CompletenessMeter.update(preferenceData, (err, response) => {
        let errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }
        resolve(response.spData.uiPreferences);
      });
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    this.setState({ recentlySkipped: false });
  }

  onSkipButtonClick(action) {
    Mixpanel.trackEvent("Click on Completeness Meter Skip Tip", {
      "From Step": action.title,
      "Action Key": action.key,
      "Current Score": this.props.stepData.scorePercentage,
    });

    this.updateMeterInfo({ key: this.props.data.key, action: "SKIP_TIP" }).then(
      () => {
        this.setState({ recentlySkipped: true });
      }
    );
  }

  onNextClick(e, action) {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.onNextTipClick(e, action);
      this.setState({ show: true });
    }, FADE_LENGTH);
  }

  renderAction(action, status, unavailableReason, skipText, showAddAccounts) {
    const isUnavailable = status === "UNAVAILABLE";
    const buttonClass = "pc-btn pc-btn--tiny";

    return (
      showAddAccounts && (
        <div>
          <a
            href={action.url}
            onClick={(e) => {
              this.props.onActionClick(e, isUnavailable, action);
            }}
            data-attribution-source={
              this.props.component || "COMPLETENESS_METER"
            }
            className={`js-tip-action ${buttonClass}${
              isUnavailable || this.state.recentlySkipped
                ? " disabled js-tip-action--disabled"
                : ""
            }`}
          >
            {action.title}
          </a>
          <div
            className={`tip-carousel-action__info${
              isUnavailable ? " js-tip-unavailable" : " hidden"
            }`}
            onMouseEnter={(e) => {
              this.props.onUnavailableHover(e, action, unavailableReason);
            }}
          >
            {isUnavailable && unavailableReason && (
              <span
                ref={(el) => {
                  this.props.storeTooltipRef(el, {
                    container: "body",
                    placement: "top",
                  });
                }}
                className="icon__help-circled js-tip-unavailable-tooltip"
                tabIndex="0"
                aria-label={unavailableReason}
                title={unavailableReason}
              >
                {" "}
              </span>
            )}
          </div>
          {skipText && (
            <ClickableTooltip
              content={[
                <div key={skipText}>
                  <div className="u-text-left">{skipText}</div>
                  <button
                    type="button"
                    onClick={() => {
                      this.onSkipButtonClick(action);
                    }}
                    className={`pc-btn pc-btn--tiny skip-pc-btn--tiny-rebranding`}
                    disabled={this.state.recentlySkipped}
                  >
                    {this.state.recentlySkipped ? "Skipped" : "Skip"}
                  </button>
                </div>,
              ]}
            >
              <span className="tip-carousel-action__info">
                <svg className="icon-svg icon--small">
                  <use href="#icon-tooltip-info"></use>
                </svg>
              </span>
            </ClickableTooltip>
          )}
        </div>
      )
    );
  }

  render() {
    const { data, areMultipleTips } = this.props;
    if (!data) {
      return null;
    }

    const action = data.actions[0];

    return (
      <div>
        <CSSTransition
          in={this.state.show}
          timeout={200}
          classNames="tips-carousel-animation"
        >
          {() => {
            return (
              <div className="tips-carousel js-tip" key={data.summary}>
                <div className="tips-carousel__tip">
                  <div className="tips-carousel__tip-title js-tip-title">
                    {data.stepTitle}:
                  </div>
                  <div className="tips-carousel__tip-summary js-tip-summary">
                    {data.summary}
                  </div>
                </div>
                <div className="tip-carousel-actions">
                  <div
                    className={`js-tip-next tip-carousel-action__next${
                      areMultipleTips
                        ? ""
                        : " tip-carousel-action__next--hidden js-tip-next--hidden"
                    }`}
                    onClick={(e) => {
                      this.onNextClick(e, action);
                    }}
                  >
                    Next Tip
                  </div>
                  {this.renderAction(
                    action,
                    data.status,
                    data.unavailableReason,
                    data.skipText,
                    this.props.showAddAccounts
                  )}
                </div>
              </div>
            );
          }}
        </CSSTransition>
      </div>
    );
  }
}

TipCarousel.propTypes = {
  data: PropTypes.object,
  stepData: PropTypes.object,
  onActionClick: PropTypes.func,
  onNextTipClick: PropTypes.func,
  storeTooltipRef: PropTypes.func,
  onUnavailableHover: PropTypes.func,
  component: PropTypes.string,
  areMultipleTips: PropTypes.bool,
  showAddAccounts: PropTypes.bool,
};

TipCarousel.defaultProps = {
  data: undefined,
  stepData: undefined,
  onActionClick: noop,
  onNextTipClick: noop,
  storeTooltipRef: noop,
  onUnavailableHover: noop,
  component: "",
  areMultipleTips: undefined,
  showAddAccounts: true,
};

export default withTooltips(TipCarousel);
