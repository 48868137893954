var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <ul class=\"menu js-account-group\">\n        <li class=\"menu__item menu__item--box menu__item--group\">\n          <label class=\"u-preserve-case\">\n          <input type=\"checkbox\" class=\"js-checkbox-group qa-checkbox-group\n            pc-u-mr-- u-preserve-case\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),"==",true,{"name":"compare","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":39},"end":{"line":32,"column":47}}})) != null ? stack1 : "")
    + "\n            value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"/>"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n            </label>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"accounts") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":62,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li\n          class=\"menu__item menu__item--box "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closedDate") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":44},"end":{"line":38,"column":94}}})) != null ? stack1 : "")
    + "\"\n          data-useraccountid=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "\"\n        >\n          <label class=\"selectable-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closedDate") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":40},"end":{"line":41,"column":93}}})) != null ? stack1 : "")
    + "\" data-hj-masked>\n            <span class=\"selectable-item__checkbox\">\n              <input type=\"checkbox\" class=\"uaid"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "\n              js-checkbox-account\" "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),"==",true,{"name":"compare","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":35},"end":{"line":45,"column":49}}})) != null ? stack1 : "")
    + " value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "\"\n              />\n            </span>\n            <span class=\"selectable-item__body\">\n              <span class=\"selectable-item__name u-preserve-case\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"firmName") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"showBalances") : depths[2]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":54,"column":23}}})) != null ? stack1 : "")
    + "              </span>\n              <span class=\"selectable-item__info qa-selectable-item-info u-preserve-case\"\n                >"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span\n              >\n            </span>\n          </label>\n        </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "menu__item--disabled";
},"7":function(container,depth0,helpers,partials,data) {
    return "selectable-item--closed";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <span class=\"selectable-item__balance qa-selectable-item-balance\">\n                    "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"balance") : depth0), depth0))
    + "\n                  </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dropdown js-account-selector-dropdown qa-account-selector-dropdown\">\n    <div\n        class=\"empower-account-selector-container js-account-selector-btn qa-account-selector-btn u-sentence-case\"\n        data-toggle=\"pc-dropdown\"\n        role=\"button\"\n        tabindex=\"0\"\n    >\n      <div class=\"empower-account-selector-label u-sentence-case\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"buttonLabel") : depth0), depth0))
    + "</div>\n      <div class=\"empower-account-selector-indicator\">\n        <div class=\"pc-toggle pc-triangle-down--small\">\n        </div>\n      </div>\n    </div>\n  <ul\n    class=\"menu menu--vertical menu--bordered menu--tiny js-account-selector-menu menu--right\"\n  >\n    <li class=\"menu__item menu__item--box menu__header "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"preserveCaseRebranding") : depth0), depth0))
    + "\">\n      <label class=\"u-preserve-case\">\n        <input type=\"checkbox\" class=\"js-checkbox-all qa-checkbox-all pc-u-mr--\"\n        "
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"allAccounts") : depth0),"==",true,{"name":"compare","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":71}}})) != null ? stack1 : "")
    + "/>All\n        accounts\n      </label>\n    </li>\n\n    <li class=\"menu__item menu__item--accounts-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"accountGroups") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":6},"end":{"line":64,"column":15}}})) != null ? stack1 : "")
    + "    </li>\n    <li class=\"menu__item menu__footer pc-bg-dark\">\n      <div class=\"u-text-right\">\n        <button class=\"pc-btn pc-btn--small js-cancel-button\">Cancel</button>\n        <button\n          class=\"pc-btn pc-btn--small pc-btn--primary js-done-button qa-done-button\"\n        >\n          Done\n        </button>\n      </div>\n    </li>\n  </ul>\n</div>\n";
},"useData":true,"useDepths":true});