import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AccountBox from "components/AccountBox/AccountBox";
import { noop } from "underscore";

export default function IncompleteAccount(props) {
  const {
    firmName,
    accountName,
    logoPath,
    balance,
    onBack,
    onConfirm,
    onMounted,
    className,
    displayName,
    confirmLabel,
    children,
    action,
  } = props;

  useEffect(() => onMounted(displayName), [onMounted, displayName]);
  return (
    <>
      {IS_EMPOWER && (
        <h1 className="nav-secondary__title pc-u-mb">{displayName}</h1>
      )}
      <div className={className}>
        <AccountBox
          showLogo={true}
          logoPath={logoPath}
          firmName={firmName}
          name={accountName}
          balance={balance}
          action={action}
          className="pc-u-2/3 pc-u-mb+"
        />
        {children}
      </div>
      <div className="u-text-right">
        <button
          type="button"
          className="pc-btn js-action-back"
          onClick={onBack}
        >
          Back to Transfer
        </button>
        {onConfirm && (
          <button
            type="button"
            className="pc-btn pc-btn--primary js-action-confirm"
            onClick={onConfirm}
          >
            {confirmLabel}
          </button>
        )}
      </div>
    </>
  );
}

IncompleteAccount.propTypes = {
  accountName: PropTypes.string.isRequired,
  firmName: PropTypes.string.isRequired,
  logoPath: PropTypes.string,
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  action: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.string,
  onMounted: PropTypes.func,
  className: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  children: PropTypes.node,
};

IncompleteAccount.defaultProps = {
  logoPath: undefined,
  balance: undefined,
  className: undefined,
  action: undefined,
  onMounted: noop,
  onConfirm: undefined,
  confirmLabel: "Confirm",
  children: undefined,
};

export function MissingAccountType() {
  return (
    <p>
      For security purposes, in order to transfer money using this account, we
      need to know the account type. This can be fixed by editing the account
      details.
    </p>
  );
}

export function ManualAccount() {
  return (
    <>
      <p>
        For security purposes, in order to transfer money using this account, it
        needs to be linked to your dashboard. Please click the link account
        button to start this process.
      </p>
      <p>
        For more information on transferring from manual accounts please visit
        our{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://${EMPOWER_SUPPORT_URL}/hc/en-us/articles/360053409213-Can-my-Cash-account-accept-Deposit-or-Withdrawal-requests-from-manually-added-accounts-`}
        >
          FAQ
        </a>
        .
      </p>
    </>
  );
}

export function AggregationError() {
  const organizationMailCash = "cash@personalwealth.empower.com";
  return (
    <>
      <p>
        For security purposes, in order to transfer money using this account,
        the account connection needs to be fixed. This can be fixed using the
        account list on the left side of your dashboard.
      </p>
      <p>
        For help using this account for transfers please contact our Cash
        support team at{" "}
        <a
          href={"mailto:" + organizationMailCash}
          rel="noopener noreferrer"
          target="_blank"
        >
          {organizationMailCash}
        </a>
        .
      </p>
    </>
  );
}
