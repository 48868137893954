/* eslint-disable camelcase */
import $ from "jquery";
import React from "react";
import { REFERRAL_CENTER_HASH } from "views/components/postLoginActions/constants";
import eventBus from "eventBus";
import Mixpanel from "mixpanel";
import EmergencyFundSummaryContainer from "components/emergencyFundSummary/EmergencyFundSummaryContainer";
import InvestableCashSummaryContainer from "components/investableCashSummary/InvestableCashSummaryContainer";
import InvestableCashSummary from "components/investableCashSummary/InvestableCashSummary";
import InvestableCashPieChart from "views/components/postLoginActions/charts/InvestableCashPieChart";
import TargetedBankBarChart from "views/components/postLoginActions/charts/TargetedBankBarChart";
import objectPath from "object-path";
import analytics from "analytics";
import kebabOrSnakeToCamelCase from "./kebabOrSnakeToCamelCase";
import { isEmpty } from "underscore";

export const CHART_TYPE_TO_COMPONENT_MAP = {
  // eslint-disable-next-line react/display-name
  BAR_CHART_EMERGENCY_FUND: (jsonContent, onButtonClick) => (
    <EmergencyFundSummaryContainer
      chartTitle={jsonContent.chartTitle}
      isInPostLoginAction
      onButtonClick={onButtonClick}
    />
  ),
  // eslint-disable-next-line react/display-name
  BAR_CHART_INVESTABLE_CASH: (jsonContent) => {
    return jsonContent.chartData ? (
      <InvestableCashSummary
        title={jsonContent.chartTitle}
        histories={jsonContent.chartData}
      />
    ) : (
      <InvestableCashSummaryContainer title={jsonContent.chartTitle} />
    );
  },
  // eslint-disable-next-line react/display-name
  PIE_CHART_INVESTABLE_CASH: (jsonContent) => (
    <InvestableCashPieChart
      data={jsonContent.chartData}
      chartFooter={jsonContent.chartFooter}
    />
  ),
  // eslint-disable-next-line react/display-name
  BAR_CHART_TARGETED_BANK: (jsonContent) => (
    <TargetedBankBarChart
      data={jsonContent.chartData}
      chartAxisType={jsonContent.chartAxisType}
      chartFooter={jsonContent.chartFooter}
    />
  ),
};
// Use the advice message name as the attribution source, appending action key
export function getPlaAttributionSource(userMessage, action = {}) {
  // `label` has been depreciated in the new ACE 3.0 message editor, but getUserMessages API continues to return it with title value
  return `${userMessage.template}${
    action && action.key ? "--" + action.key : ""
  }`;
}

// Returns the first resource object with non-empty jsonContent
export function getJsonContent(userMessage = {}) {
  const resources = userMessage.resources || [];
  const msgWithJsonContent =
    resources.find((resource) => resource.jsonContent) || {};
  return msgWithJsonContent.jsonContent;
}

// Returns the web image if there is any otherwise it returns first resource object with type of IMAGE and non-empty url
export function getResourceImageObject(userMessage) {
  const webImage =
    (userMessage.resources || []).find(
      (resource) =>
        resource.type === "IMAGE" &&
        resource.url &&
        resource.size === "WEB_LARGE"
    ) || {};

  const defaultImage =
    (userMessage.resources || []).find(
      (resource) => resource.type === "IMAGE" && resource.url
    ) || {};
  return isEmpty(webImage) ? defaultImage : webImage;
}

// Returns the first resource object with type of EMBED and non-empty url
export function getResourceEmbedObject(userMessage) {
  return (
    (userMessage.resources || []).find(
      (resource) => resource.type === "EMBED" && resource.url
    ) || {}
  );
}

// Returns the first resource object url with type of IMAGE and non-empty url
export function getResourceImage(userMessage) {
  return getResourceImageObject(userMessage).url;
}

export function transformCssStylesToJsStyles(styles) {
  const jsStyleObj = {};
  Object.keys(styles).forEach((key) => {
    const hasDash = key.includes("-");
    const keyToUse = hasDash ? kebabOrSnakeToCamelCase(key) : key;
    jsStyleObj[keyToUse] = styles[key];
  });
  return jsStyleObj;
}

export function getResourceEmbedBlock(userMessage) {
  const embed = getResourceEmbedObject(userMessage) || {};
  if (embed.url) {
    return <div dangerouslySetInnerHTML={{ __html: embed.url }} />;
  }
  return null;
}

export function getResourceImageBlock(userMessage) {
  const image = getResourceImageObject(userMessage) || {};
  if (image.url) {
    return (
      <div className="u-text-center pc-u-mb">
        <img src={image.url} alt={image.alt || ""} />
      </div>
    );
  }
  return null;
}

export function getImageBlock(userMessage) {
  const image = getResourceImageObject(userMessage) || {};
  const inlineProps = {};
  inlineProps.style = transformCssStylesToJsStyles(
    objectPath.get(image, "jsonContent.style") || {}
  );
  if (!image.url) {
    return null;
  }
  const jsonContent = image.jsonContent || {};
  return (
    <>
      {Boolean(jsonContent.aboveImageContent) && (
        <div
          dangerouslySetInnerHTML={{ __html: jsonContent.aboveImageContent }}
        />
      )}
      <img src={image.url} alt={image.alt || ""} {...inlineProps} />
      {Boolean(jsonContent.belowImageContent) && (
        <div
          dangerouslySetInnerHTML={{ __html: jsonContent.belowImageContent }}
        />
      )}
    </>
  );
}

// Per ACE-3877, do not call getPostLoginAction API if user comes from `app-features/open-bank-account` or `/app-features/accounts/add`
export function shouldSkipGetPostLoginActionCall(redirectTo) {
  return (
    redirectTo.indexOf("/page/login/app#/open-bank-account") === 0 ||
    redirectTo.indexOf("/page/login/app#/accounts/add") === 0
  );
}

export function injectReferralPromotionIntoNavBar(userMessage) {
  const action = (userMessage.action && userMessage.action[0]) || {};
  const menuLabel = action.label || "Get $20";
  let menuUrl = action.url || REFERRAL_CENTER_HASH;
  if (!menuUrl.includes("source=")) {
    menuUrl += `${menuUrl.includes("?") ? "&" : "?"}source=web-app-menu`;
  }
  $("#js-refer-a-friend-menu-item").append(
    $(`
    <a href="${menuUrl}" class="menu__action menu__action--referral-gift-box u-preserve-case">
      <img class="icon-refer-a-friend-gift" src="${
        window.staticUrl || ""
      }/static/img/icons/refer-a-friend--gift.svg" alt="">${menuLabel}
    </a>
  `)
  );
}

export function isThereAnActiveModal() {
  return (
    Boolean($(".pc-modal")[0]) ||
    Boolean($('.modal[style="display: block;"]')[0])
  );
}

export function trackInterjectionUserMessageReceived(msg) {
  if (!msg.lastViewedTime) {
    Mixpanel.trackEvent("Receive Unread Interjection User Message", {
      is_registration_session: window.isRegistrationSession,
      message_template: msg.template,
    });
  }
}

export function trackUserMessageViewEvent({ userMessage, plaDetails } = {}) {
  const messageTemplateName = userMessage.template;
  eventBus.trigger(
    // This will cause NotificationsContainer to mark this message as viewed
    "postLoginActions:viewUserMessage",
    userMessage.userMessageId
  );
  analytics.sendPageView("/post-login-action", messageTemplateName);
  analytics.sendEngagementEvent(
    "View Post Login Action Item",
    messageTemplateName
  );
  Mixpanel.trackEvent("View Post Login Action", plaDetails);
}

export function getPostLoginActionChart(userMessage, onButtonClick) {
  const jsonContent = getJsonContent(userMessage);
  if (CHART_TYPE_TO_COMPONENT_MAP[jsonContent.chartType]) {
    return CHART_TYPE_TO_COMPONENT_MAP[jsonContent.chartType](
      jsonContent,
      onButtonClick
    );
  }
  return null;
}

export function getUserName() {
  return window.usernameFirst || window.usernameLast || "User";
}

export function getUserMessageActionTrackingFlag(trackingFlag) {
  return Boolean(trackingFlag !== false && trackingFlag !== "false");
}

export function normalizeUserMessageActionTrackingFlags(userMessage) {
  if (!userMessage || !userMessage.action || !userMessage.action.length) {
    return;
  }
  userMessage.action.forEach((action) => {
    action.trackingFlag = getUserMessageActionTrackingFlag(action.trackingFlag);
  });
}

export function isMadLibsInterjection(userMessage) {
  return [
    "CUSTOM_APPOINTMENT_SCHEDULER",
    "SESSION_WIDE_CUSTOM_APPT_SCHEDULER",
  ].includes(userMessage.viewTemplate);
}
