import React from "react";
import PropTypes from "prop-types";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import InnerImageZoom from "react-inner-image-zoom";

const EditTransactionImageModal = (props) => {
  const { errors, loading, onCancel, onDelete, onReplace, imageUrl } = props;

  // Create a new image tag to render in a canvas element
  // to convert it to blob
  function toDataURL(src, callback, outputFormat) {
    const img = new Image();
    img.crossOrigin = "Use-Credentials";
    /* istanbul ignore next */
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.src = src;
  }

  /* istanbul ignore next */
  // Create a anchor tag with the image blob to download it
  function forceDownload(blob) {
    const anchorTag = document.createElement("a");
    anchorTag.download = "Transaction Image";
    anchorTag.href = blob;
    document.body.appendChild(anchorTag);
    anchorTag.click();
    anchorTag.remove();
  }

  // On integraged enviroment (Empower + PCAP), the anchor tag redirect to the img url
  // This is caused by a policy security: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-download
  // This funcion try to convert the image to blob and download it
  const handleOnDownload = (event) => {
    const imageUrlObj = new URL(imageUrl);
    if (window.location.host === imageUrlObj.host) return;

    // Do manually download
    event.preventDefault();
    return toDataURL(imageUrl, forceDownload);
  };

  return (
    <div className="u-text-center">
      <Message className="pc-u-mb" severity="error" messages={errors} />
      <LoadingOverlay active={loading} />
      <InnerImageZoom
        className="transaction-image__zoom"
        src={imageUrl}
        alt="Transaction Image"
      />
      <div className="l-spaced l-spaced--flush pc-u-mt">
        <div>
          <button
            type="button"
            className="pc-btn js-delete-transaction-image qa-delete-transaction-image pc-btn--danger"
            onClick={onDelete}
          >
            Delete
          </button>
          <a
            href={imageUrl}
            className="pc-btn js-download-transaction-image"
            onClick={handleOnDownload}
            download
          >
            Download
          </a>
        </div>
        <div>
          <button
            type="button"
            className="pc-btn js-replace-transaction-image"
            onClick={onReplace}
          >
            Replace Image
          </button>
          <button
            type="button"
            className="pc-btn pc-btn--primary js-image-transaction-done"
            onClick={onCancel}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

EditTransactionImageModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  imageUrl: PropTypes.string,
  errors: PropTypes.array,
};

EditTransactionImageModal.defaultProps = {
  errors: undefined,
  loading: false,
  imageUrl: "",
};

export default EditTransactionImageModal;
