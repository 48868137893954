import PropTypes from "prop-types";
import React from "react";
import FinancialPlanWheel from "./FinancialPlanWheel";
import { trackClick } from "components/common/ComponentAnalytics";

const CURRENT_PAGE = 1;

export default class FactPage extends React.Component {
  constructor() {
    super(...arguments);
    this.onContinueClick = this.onContinueClick.bind(this);
    this.onExternalLinkClick = this.onExternalLinkClick.bind(this);
  }

  onContinueClick(event) {
    trackClick(event, this.props.componentName);
    this.props.onContinueClick(CURRENT_PAGE);
  }

  onExternalLinkClick(event) {
    trackClick(event, this.props.componentName, "BrightPlan.com Blog Link");
  }

  render() {
    const { componentName } = this.props;
    return (
      <div className="fin-plan-offer__page">
        <h2 className="fin-plan-offer__title">
          Fact: Those with a financial plan are almost twice as likely to have
          saved $100,000, and are 70% more likely to feel confident about
          achieving their goals.*
        </h2>
        <FinancialPlanWheel componentName={componentName} />
        <h2 className="fin-plan-offer__title pc-u-mt0 pc-u-pb">
          For a one-time fee of{" "}
          <span className="fin-plan-offer__highlight">$499</span>, one of our
          financial planners will provide you with a comprehensive plan
        </h2>
        <div className="pc-layout pc-layout--center pc-u-mb pc-u-pb-- pc-u-mt--">
          <button
            type="button"
            className="pc-btn pc-btn--primary"
            onClick={this.onContinueClick}
          >
            See a Personalized Plan Example
          </button>
        </div>
        <a
          className="fin-plan-offer__footer-url pc-u-mb-"
          target="_blank"
          onClick={this.onExternalLinkClick}
          href="https://www.brightplan.com/blog/top-5-benefits-of-having-a-financial-plan"
          rel="noreferrer"
        >
          *https://www.brightplan.com/blog/top-5-benefits-of-having-a-financial-plan
        </a>
      </div>
    );
  }
}

FactPage.propTypes = {
  componentName: PropTypes.string,
  onContinueClick: PropTypes.func,
};
