import markUserMessageAsUnviewed from "libs/pcap/utils/markUserMessageAsUnviewed";

const VISIBLE_USER_MESSAGES = {};

export function add(userMessage) {
  VISIBLE_USER_MESSAGES[userMessage.userMessageId] = userMessage;
}

export function addOnClose(userMessage, onClose) {
  if (!onClose) {
    return;
  }
  const userMessageId = userMessage.userMessageId;
  if (!VISIBLE_USER_MESSAGES[userMessageId]) {
    VISIBLE_USER_MESSAGES[userMessageId] = userMessage;
  }
  VISIBLE_USER_MESSAGES[userMessageId] = Object.assign(
    { close: onClose },
    VISIBLE_USER_MESSAGES[userMessageId]
  );
}

export function remove(userMessageId) {
  if (VISIBLE_USER_MESSAGES[userMessageId]) {
    delete VISIBLE_USER_MESSAGES[userMessageId];
  }
}

export function removeAll() {
  Object.keys(VISIBLE_USER_MESSAGES).forEach((userMessageId) => {
    remove(userMessageId);
  });
}

export function markUnviewedAndRemove(userMessageId) {
  const userMessage = VISIBLE_USER_MESSAGES[userMessageId];
  if (userMessage) {
    markUserMessageAsUnviewed(userMessageId);
    if (userMessage.close) {
      userMessage.close();
    }
    remove(userMessageId);
  }
}

export function markUnviewedAndRemoveAll() {
  Object.keys(VISIBLE_USER_MESSAGES).forEach((userMessageId) => {
    markUnviewedAndRemove(userMessageId);
  });
}

export function getCount() {
  return Object.keys(VISIBLE_USER_MESSAGES).length;
}

export function isEmpty() {
  return !getCount();
}
