import {
  annotationCustomType,
  annotationCalloutCircle,
  annotationCallout,
} from "d3-svg-annotation";

export const circleRight = annotationCustomType(annotationCalloutCircle, {
  className: "pc-annotation",
  note: {
    lineType: "horizontal",
    align: "left",
    orientation: "topBottom",
  },
});

export const circleLeft = annotationCustomType(annotationCalloutCircle, {
  className: "pc-annotation",
  note: {
    lineType: "horizontal",
    align: "right",
    orientation: "topBottom",
  },
});

export const basicLabel = annotationCustomType(annotationCallout, {
  className: "pc-annotation",
  note: {
    lineType: "horizontal",
    align: "middle",
  },
});

export const defaultSubject = {
  radius: 6,
  radiusPadding: 6,
};
