import React from "react";
import Input from "components/common/form/Input";
import BaseHoldingsAddRow from "../BaseHoldingsAddRow";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";
import { formatCurrency } from "libs/pcap/utils/format";
import { POSITIVE_CURRENCY_FORMAT } from "components/common/form/formattingOptions";
import deepCopy from "deep-copy";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

class ManualHoldingsAddRow extends BaseHoldingsAddRow {
  constructor(props) {
    super(props);
    this.handleFetchQuote = this.handleFetchQuote.bind(this);
    this.tickerValidator = Object.assign(
      { conform: this.isNotExistingHolding },
      props.schema.properties.ticker
    );
  }

  handleFetchQuote(ev) {
    if (
      ev.target.value !== "" &&
      this.props.onFetchQuote &&
      this.isNotExistingHolding(ev.target.value)
    ) {
      let model = deepCopy(this.state.model);
      model.cusip = "";
      model.description = "";
      model.price = "";
      model.costBasis = "";
      this.setState({
        model,
        errors: undefined,
        loading: true,
      });
      this.props
        .onFetchQuote(ev.target.value)
        .then((security) => {
          model.ticker = security.ticker;
          model.description = security.description;
          model.price = security.price;
          model.cusip = security.cusip;
          this.setState(
            {
              model,
              loading: false,
              tickerFound: IS_EMPOWER ? security.price != null : true,
            },
            () => {
              // Focusing shares input after fetching quote
              let quantityInput = this.inputElements.find(
                (input) => input.props.name === "quantity"
              );
              if (quantityInput) {
                quantityInput.focus();
              }
            }
          );
        })
        .catch((errors) => {
          this.setState({
            errors,
            loading: false,
            tickerFound: false,
          });
        });
    }
  }

  render() {
    const { columns, onCancel, schema, holdings } = this.props;
    const { model, loading, tickerFound } = this.state;
    return (
      <form
        className="table__row--edit pc-bg-dark qa-add-holding-form"
        onSubmit={this.handleSubmit}
      >
        <LoadingOverlay active={loading} />
        <div className="holdings-table__edit-row-inner">
          <div
            className={
              columns[0].className +
              " pc-holdings-grid-account-details-cell--holding-name  table__column table__column--holdings-edit-cell"
            }
          >
            <Input
              ref={this.storeInputRef}
              className="qa-add-holdings-ticker"
              type="text"
              name="ticker"
              placeholder="Ticker or CUSIP"
              value={model.ticker}
              validator={this.tickerValidator}
              errorBlockClassName="table__row--edit pc-bg-dark"
              onChange={this.handleInputChange}
              onBlur={this.handleFetchQuote}
              sizeVariation="full"
              disabled={loading}
            />
          </div>
          <div
            className={`table__column table__column--holdings-edit-cell ${columns[1].className}`}
          >
            <Input
              ref={this.storeInputRef}
              type="text"
              name="quantity"
              placeholder="Shares"
              value={model.quantity}
              validator={schema.properties.quantity}
              errorBlockClassName="is-hidden"
              onChange={this.handleInputChange}
              sizeVariation="full"
              disabled={loading}
              formattingOptions={POSITIVE_CURRENCY_FORMAT}
              className="qa-add-holding-quantity"
            />
          </div>
          <div
            className={`table__column table__column--holdings-edit-cell ${columns[2].className}`}
          >
            {tickerFound ? (
              <label className="qa-holding-price-label">
                {formatCurrency(model.price)}
              </label>
            ) : (
              <Input
                ref={this.storeInputRef}
                type="text"
                name="price"
                placeholder="Price"
                prefix="$"
                value={model.price}
                validator={schema.properties.price}
                errorBlockClassName="is-hidden"
                onChange={this.handleInputChange}
                sizeVariation="full"
                disabled={loading}
                className="qa-add-holding-price"
                formattingOptions={POSITIVE_CURRENCY_FORMAT}
              />
            )}
          </div>
          <div
            className={`table__column table__column--holdings-edit-cell pc-u-pt- ${columns[3].className}`}
          >
            {formatCurrency(
              model.price && model.quantity ? model.price * model.quantity : 0
            )}
          </div>
        </div>
        <div className="holdings-table__edit-row-inner">
          <div
            className={`table__column pc-u-pt0 table__column--holdings-edit-cell ${columns[0].className} pc-holdings-grid-account-details-cell--holding-name`}
          >
            <label className="pc-label">Description</label>
            <Input
              ref={this.storeInputRef}
              type="text"
              name="description"
              value={model.description}
              validator={schema.properties.description}
              errorBlockClassName="is-hidden"
              onChange={this.handleInputChange}
              sizeVariation="full"
              disabled={loading}
            />
            {this.state.errors && (
              <Message
                messages={this.state.errors}
                className="qa-add-holdings-error"
              />
            )}
          </div>
          <div
            className={`table__column pc-u-pt0 table__column--holdings-edit-cell ${columns[1].className} pc-holdings-grid-cell--holding-shares--editing`}
          >
            <label className="pc-label u-text-left">Cost Basis</label>
            <Input
              ref={this.storeInputRef}
              type="text"
              name="costBasis"
              value={model.costBasis}
              placeholder="Optional"
              validator={schema.properties.costBasis}
              errorBlockClassName="is-hidden"
              onChange={this.handleInputChange}
              sizeVariation="full"
              disabled={loading}
              prefix="$"
              formattingOptions={POSITIVE_CURRENCY_FORMAT}
            />
          </div>
          <div className="table__column table__column--holdings-edit-actions">
            <button
              type="button"
              className={`pc-btn pc-btn--small qa-close-edit-holding ${
                holdings.length === 0 ? "is-hidden" : ""
              }`}
              onClick={onCancel}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="pc-btn pc-btn--small pc-btn--primary qa-save-holding"
              disabled={loading || isEmpowerPrivilegedMode()}
            >
              Done
            </button>
          </div>
        </div>
      </form>
    );
  }
}

ManualHoldingsAddRow.defaultProps = BaseHoldingsAddRow.defaultProps;

ManualHoldingsAddRow.propTypes = BaseHoldingsAddRow.propTypes;

export default ManualHoldingsAddRow;
