import React, { Component } from "react";
import PropTypes from "prop-types";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import AccountDetails from "components/transferFunds/AccountDetails";
import { promisify } from "utils/service";
import Services from "services";
import toClient from "accessors/formField/mappers/toClient";
import toServer from "accessors/transfer/captureAccountDetails/mappers/toServer";
import { noop, isEmpty } from "underscore";
import LoadingOverlay from "components/common/LoadingOverlay";
import { getSource } from "components/common/attributionStore";
import { mapFieldValuesByName } from "utils/formField";

class AccountDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { initializing: true };
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() {
    this.props.onMounted("Transfer Funds");
    this.getTransferAdditionalFieldInfo();
  }

  componentWillUnmount() {
    if (this.getTransferAdditionalFieldInfoPromise) {
      this.getTransferAdditionalFieldInfoPromise.cancel();
    }
    if (this.captureAccountDetailsPromise) {
      this.captureAccountDetailsPromise.cancel();
    }
  }

  async getTransferAdditionalFieldInfo() {
    this.setState({ loading: true });

    const {
      getTransferAdditionalFieldInfo,
      sourceAccountId,
      targetAccountId,
      onMounted,
    } = this.props;

    const requestParams = {
      sourceAccountId,
      targetAccountId,
      source: getSource(),
    };

    this.getTransferAdditionalFieldInfoPromise = makeCancelablePromise(
      getTransferAdditionalFieldInfo(requestParams)
    );

    try {
      const rs = await this.getTransferAdditionalFieldInfoPromise.promise;
      const { prompts: formFields, userAccountId } = toClient(rs);

      if (isEmpty(formFields)) {
        this.props.onComplete(true);
        return;
      }

      onMounted("Account Details");

      const selectedAccount = this.props.accounts.find(
        (a) => a.userAccountId === userAccountId
      );

      this.setState({
        model: { ...this.state.model, ...mapFieldValuesByName(formFields) },
        loading: false,
        initializing: false,
        formFields,
        selectedAccount,
        errors: undefined,
      });
    } catch (errors) {
      if (this.getTransferAdditionalFieldInfoPromise.isCanceled()) {
        return;
      }
      this.setState({ errors, initializing: false, loading: false });
    }
  }

  async handleContinue(model) {
    this.setState({ loading: true });

    const {
      onComplete,
      captureAccountDetails,
      sourceAccountId,
      targetAccountId,
    } = this.props;

    this.captureAccountDetailsPromise = makeCancelablePromise(
      captureAccountDetails(
        toServer(Object.assign({ sourceAccountId, targetAccountId }, model))
      )
    );

    try {
      await this.captureAccountDetailsPromise.promise;
      this.setState({ errors: undefined, loading: false });
      onComplete(false);
    } catch (errors) {
      if (this.captureAccountDetailsPromise.isCanceled()) {
        return;
      }
      this.setState({ errors, loading: false });
    }
  }

  render() {
    const {
      formFields,
      errors,
      loading,
      initializing,
      model,
      selectedAccount,
    } = this.state;
    const { CustomView } = this.props;

    if (initializing) {
      return (
        <LoadingOverlay
          key="init-account-details" // Added for automation, react tries to reuse this element for `qa-loading-overlay--account-details`
          active={true}
          className="qa-loading-overlay--account-details-init"
        />
      );
    }
    return (
      <>
        <LoadingOverlay
          key="account-details"
          active={loading}
          className="qa-loading-overlay--account-details"
        />
        <CustomView
          model={model}
          errors={errors}
          onContinue={this.handleContinue}
          onBack={this.props.onBack}
          formFields={formFields}
          className={this.props.className}
          account={selectedAccount}
          transferType={this.props.transferType}
        />
      </>
    );
  }
}

AccountDetailsContainer.propTypes = {
  sourceAccountId: PropTypes.number.isRequired,
  targetAccountId: PropTypes.number.isRequired,
  // `onComplete(skip: Boolean)` - The callback receives a boolean flag indicating whether it is being called
  // as a result of no prompts being available on the page (`true`) or the user pressing Continue button (`false`).
  onComplete: PropTypes.func,
  onBack: PropTypes.func,
  getTransferAdditionalFieldInfo: PropTypes.func,
  captureAccountDetails: PropTypes.func,
  className: PropTypes.string,
  accounts: PropTypes.array,
  onMounted: PropTypes.func,
  transferType: PropTypes.number.isRequired,
  CustomView: PropTypes.func,
};

AccountDetailsContainer.defaultProps = {
  onComplete: noop,
  onBack: noop,
  getTransferAdditionalFieldInfo: promisify(
    Services.Transfer.getTransferAdditionalFieldInfo
  ),
  captureAccountDetails: promisify(Services.Transfer.captureAccountDetails),
  className: undefined,
  accounts: [],
  onMounted: noop,
  CustomView: AccountDetails,
};

export default AccountDetailsContainer;
