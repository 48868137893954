import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import ComponentAnalytics from "components/common/ComponentAnalytics";

const trackUserKeyPressOnce = _.once((props) => {
  ComponentAnalytics.trackEvent(
    props.componentName,
    "Enter Email Text in Form"
  );
});

export default class EmailAdvisor extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      disableSubmitButton: true,
    };
  }

  componentDidMount() {
    this.textarea.focus();
  }

  onChange() {
    const text = this.textarea.value.trim();
    this.setState({ disableSubmitButton: !text });
    trackUserKeyPressOnce(this.props);
  }

  onSubmitClick(e) {
    e.preventDefault();
    this.props.onRequestSendEmail(this.textarea.value.trim());
    ComponentAnalytics.trackButtonClick(e, this.props.componentName);
  }

  onScheduleACallLinkClick(e) {
    this.props.onEmailAdvisorDone();
    ComponentAnalytics.trackLinkClick(e, this.props.componentName);
    this.openScheduleACallLink();
  }

  openScheduleACallLink() {
    window.open(this.props.scheduleACallLink, "_blank");
  }

  render() {
    const { errorMessage, userFirstName } = this.props;

    let errorBlock;
    if (errorMessage) {
      errorBlock = (
        <p className="pc-u-mt-- pc-help-block--error">{errorMessage}</p>
      );
    }

    return (
      <section className="email-advisor">
        <div className="pc-layout pc-layout--auto pc-layout--small">
          <div className="pc-layout__item pc-u-2/5">
            <div className="email-advisor__image" />
            <h4 className="email-advisor__name">Andrew C. Thompson</h4>
            <p className="email-advisor__description">
              7 Years of Experience
              <br />
              Our Advisor
              <br />
              Since 2013
            </p>
          </div>
          <div className="pc-layout__item pc-u-3/5">
            <p className="email-advisor__intro">
              Hi{userFirstName ? ` ${userFirstName}` : ""}, I'm here to help
              you. I can take a closer look at your portfolio, recommend an
              investment strategy or you can{" "}
              <a onClick={this.onScheduleACallLinkClick.bind(this)}>
                setup a quick call
              </a>{" "}
              with me to discuss your investments and financial planning
              considerations.
            </p>
          </div>
        </div>
        <form className="email-advisor__form">
          <textarea
            ref={(textarea) => {
              this.textarea = textarea;
            }}
            placeholder="How can Andrew help?"
            className="input input--full email-advisor__textarea"
            onChange={this.onChange.bind(this)}
          />
          {errorBlock}
          <div className="email-advisor__button-row">
            <button
              type="submit"
              className="pc-btn pc-btn--primary"
              onClick={this.onSubmitClick.bind(this)}
              disabled={this.state.disableSubmitButton}
            >
              Get in Touch with Us
            </button>
          </div>
        </form>
      </section>
    );
  }
}

EmailAdvisor.propTypes = {
  // Component name to use when tracking analytics events
  componentName: PropTypes.string.isRequired,
  // User's first name
  userFirstName: PropTypes.string.isRequired,
  // Link to redirect user to when she clicks on `setup a quick call` link
  scheduleACallLink: PropTypes.string.isRequired,
  // Any error message to show
  errorMessage: PropTypes.string,
  // Email sender
  onRequestSendEmail: PropTypes.func.isRequired,
  // Callback function to run when this module is done (e.g. user clicked on a link or submitted an email)
  onEmailAdvisorDone: PropTypes.func.isRequired,
};
