import PropTypes from "prop-types";
import React from "react";
import { formatNumber } from "libs/pcap/utils/format";
import "textfill";

export default class FlexibleDollar extends React.Component {
  componentDidMount() {
    $(this.container).textfill({
      widthOnly: true,
      maxFontPixels: this.props.maxFontPixels,
    });
  }

  componentDidUpdate() {
    $(this.container).textfill({
      widthOnly: true,
      maxFontPixels: this.props.maxFontPixels,
    });
  }

  render() {
    const { value, supClassName, containerWidth } = this.props;
    const renderSup = Boolean(supClassName);
    return (
      <div
        ref={(el) => {
          this.container = el;
        }}
        style={{ width: containerWidth }}
      >
        <span>
          {renderSup ? (
            <sup className={supClassName}>{value < 0 ? "-" : ""}$</sup>
          ) : (
            <small>{value < 0 ? "-" : ""}$</small>
          )}

          {formatNumber(Math.abs(value), 0)}
        </span>
      </div>
    );
  }
}

FlexibleDollar.propTypes = {
  value: PropTypes.number,
  containerWidth: PropTypes.string,
  maxFontPixels: PropTypes.number,
  supClassName: PropTypes.string,
};

FlexibleDollar.defaultProps = {
  value: undefined,
  containerWidth: undefined,
  maxFontPixels: 24,
  supClassName: "",
};
