import React from "react";
import PropTypes from "prop-types";
import Services from "services";
import ClientRecommendation401kView from "components/AccountDetails/Recommendation401k/ClientRecommendation401kView";
import Loading from "components/common/loading/Loading";
import { promisify } from "utils/service";
import objectPath from "object-path";
import { trackView } from "components/common/ComponentAnalytics";

export default class ClientRecommendation401kContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, isDisclaimerModalOpen: false };
    this.getRecommendation = promisify(
      Services.EmployerSponsoredRetirementPlanner.getInput
    );

    this.handleDisclaimerClick = this.handleDisclaimerClick.bind(this);
    this.handleDisclaimerClose = this.handleDisclaimerClose.bind(this);
  }

  handleDisclaimerClick() {
    this.setState({ isDisclaimerModalOpen: true });
  }

  handleDisclaimerClose() {
    this.setState({ isDisclaimerModalOpen: false });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <ClientRecommendation401kView
        userHoldings={this.state.userHoldings}
        recommendedHoldings={this.state.recommendedHoldings}
        action={this.state.action}
        hasSignificantDiffHoldings={this.state.hasSignificantDiffHoldings}
        allocationSet={this.state.allocationSet}
        plannerStage={this.state.plannerStage}
        title={this.state.title}
        takeaway={this.state.takeaway}
        note={this.state.note}
        onDisclaimerClick={this.handleDisclaimerClick}
        onDisclaimerClose={this.handleDisclaimerClose}
        isDisclaimerModalOpen={this.state.isDisclaimerModalOpen}
      />
    );
  }

  componentDidMount() {
    trackView("Account Details - Employer Plan Recommendation tab");
    const { userAccountId } = this.props;
    this.getRecommendation().then((recommendation) => {
      const accountData = objectPath.get(
        recommendation,
        `input.plannerAccounts.${userAccountId}`
      );
      if (accountData) {
        const userHoldings = accountData.currentFundAllocations.map((uh) => {
          return {
            name: uh.symbol || "",
            description: uh.description,
            value: uh.percentage,
          };
        });
        const recommendedHoldings = accountData.targetFundAllocations.map(
          (rh) => {
            return {
              name: rh.symbol || "",
              description: rh.description,
              value: rh.percentage,
            };
          }
        );
        this.setState({
          plannerStage: accountData.plannerStage,
          userHoldings,
          recommendedHoldings,
          allocationSet: accountData.assetClassAllocation.allocationSet,
          action: accountData.actions,
          hasSignificantDiffHoldings: Boolean(
            accountData.hasSignificantDiffHoldings
          ),
          title: accountData.titles && accountData.titles[0],
          takeaway: accountData.takeaways && accountData.takeaways[0],
          note: accountData.notes && accountData.notes[0],
          loading: false,
        });
      }
    });
  }
}

ClientRecommendation401kContainer.propTypes = {
  userAccountId: PropTypes.number.isRequired,
};
