/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import PropTypes from "prop-types";
import markUserMessageAsClicked from "libs/pcap/utils/markUserMessageAsClicked";
import { isEmpty } from "underscore";
import { trackClick } from "components/common/ComponentAnalytics";
import InfoTooltipText from "components/common/InfoTooltipText";

class ACESidebarNotification extends React.Component {
  constructor(props) {
    super(props);
  }

  handleLinkClick(e, userMessage) {
    if (userMessage && userMessage.userMessageId) {
      markUserMessageAsClicked(userMessage.userMessageId);
    }
    trackClick(null, userMessage.template, userMessage.action[0].label);
  }

  handleClose(e, userMessage) {
    this.props.handleClose(userMessage.userMessageId);
    trackClick(null, userMessage.template, "Close button");
  }

  render() {
    const { userMessage } = this.props;
    if (isEmpty(userMessage)) {
      return <div />;
    }

    const userMessageObj = userMessage.resources[0];
    let tooltipContent = userMessageObj?.jsonContent?.tooltipContent;
    const tooltipText = userMessageObj?.jsonContent?.tooltipText;
    const displayLocations = userMessage?.displayLocations;
    const imageUrl = userMessageObj?.jsonContent?.imageUrl;
    if (
      tooltipText &&
      !tooltipContent &&
      displayLocations[0].includes("BANK")
    ) {
      tooltipContent = `The Empower Personal Cash™ Annual Percentage Yield (APY) as of ${userMessageObj?.jsonContent?.apyDate} is ${userMessageObj?.jsonContent?.apyPcb} APY (${userMessageObj?.jsonContent?.interestRatePcb} interest rate). The calculation for APY is rounded to the nearest basis point. Both the interest rate and APY are variable and subject to change at UMB's discretion at any time without notice.`;
    }

    return (
      <div className="ace-sidebar-notification__container">
        <div className="ace-sidebar-notification__close-button">
          <button
            className="pc-btn pc-btn--stripped pc-u-p0"
            onClick={(e) => this.handleClose(e, userMessage)}
            data-testid="ace-sidebar-notification__close-icon"
          >
            <svg className="pc-u-mb-- pc-u-ml-" width="12" height="12">
              <use xlinkHref="#pc-icon__close-x"></use>
            </svg>
          </button>
        </div>
        <div className="ace-sidebar-notification__content">
          <div className="ace-sidebar-notification__body">
            {Boolean(imageUrl) && (
              <img
                className="ace-sidebar-notification__image"
                src={imageUrl}
                alt="Empower Personal Cash logo"
              />
            )}
          </div>

          <div className="ace-sidebar-notification__body-text">
            {tooltipText && (
              <span>
                <InfoTooltipText
                  text={tooltipText}
                  className="ace-sidebar-notification__tooltip-text"
                  tooltipContent={tooltipContent}
                  tooltipProps={{ placement: "bottom" }}
                />
              </span>
            )}
            <span dangerouslySetInnerHTML={{ __html: userMessage.summary }} />
          </div>
        </div>
        <div className="ace-sidebar-notification__actions pc-u-mb- pc-u-mt--">
          {userMessage.action.map((el) => (
            <div
              className="ace-sidebar-notification__action"
              key={el}
              data-testid="ace-sidebar-notification__link"
            >
              <a
                onClick={(e) => this.handleLinkClick(e, userMessage)}
                href={el.url}
                className="pc-btn pc-btn--small"
              >
                {el.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

ACESidebarNotification.propTypes = {
  userMessage: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ACESidebarNotification;
