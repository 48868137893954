import PropTypes from "prop-types";
import React from "react";
import FancySelectUtils from "components/common/form/FancySelect/FancySelectUtils";
import Select, { components } from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";
import SelectableAccount from "components/common/multiselectDropdown/SelectableAccount";
import { noop, some, isEqual } from "underscore";
import deepCopy from "deep-copy";

const Option = (props) => {
  const account = props.getAccount(props.value);
  const isDisabled = props.getIsAccountDisabled
    ? props.getIsAccountDisabled(account)
    : false;

  return (
    <components.Option
      {...props}
      className={isDisabled ? "Select__option--is-disabled" : ""}
    >
      <SelectableAccount
        className="accounts-selector__account"
        showBalancesAndTimeStamp
        value={props.value}
        checked={props.isSelected}
        name={account.firmName}
        info={props.label}
        balance={account.balance}
        precision={2}
        onChange={noop}
        disabled={isDisabled}
      />
    </components.Option>
  );
};

Option.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  info: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  getAccount: PropTypes.func.isRequired,
  getIsAccountDisabled: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Option.defaultProps = {
  isSelected: false,
  label: undefined,
  info: undefined,
  name: undefined,
  onChange: undefined,
  getIsAccountDisabled: undefined,
};

const Menu = (props) => {
  const { onClickSelectAll, allAccountsCount, selectedAccountsCount } = props;
  const isAllSelected = allAccountsCount === selectedAccountsCount;

  return (
    <components.Menu {...props}>
      {onClickSelectAll && (
        <label className="selectable-item__checkbox pc-u-p-">
          <input
            type="checkbox"
            checked={isAllSelected}
            className="pc-u-mr-"
            value={isAllSelected}
            onChange={onClickSelectAll}
          />
          All accounts
        </label>
      )}
      {props.children}
      <div className="u-text-right pc-u-m--">
        <button
          type="button"
          className="pc-btn pc-btn--small pc-btn--cancel"
          onClick={props.onCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="pc-btn pc-btn--small pc-btn--primary"
          onClick={props.onDone}
          disabled={props.selectedAccountsCount === 0}
        >
          Done
        </button>
      </div>
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  selectedAccountsCount: PropTypes.number.isRequired,
  allAccountsCount: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onClickSelectAll: PropTypes.func,
};

Menu.defaultProps = {
  onClickSelectAll: undefined,
};

const MenuList = (props) => {
  return (
    <PerfectScrollbar>
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </PerfectScrollbar>
  );
};

MenuList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const ValueContainer = (props) => {
  const { selectedAccountsCount, allAccountsCount, allAccountsLabel } = props;

  let content;
  let sentenceCaseClass = allAccountsLabel ? "" : "u-sentence-case";
  if (selectedAccountsCount === allAccountsCount) {
    content = allAccountsLabel || "All Accounts";
  } else if (selectedAccountsCount === 1) {
    content = `${selectedAccountsCount} Account`;
  } else {
    content = `${selectedAccountsCount} Accounts`;
  }

  return (
    <components.ValueContainer {...props}>
      <button
        className={`accounts-selector__value-container ${sentenceCaseClass}`}
        onClick={props.onButtonClick}
      >
        {content}
      </button>
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  selectedAccountsCount: PropTypes.number.isRequired,
  allAccountsCount: PropTypes.number.isRequired,
  allAccountsLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

ValueContainer.defaultProps = {
  allAccountsLabel: undefined,
  onButtonClick: undefined,
};

const buildDropdownOptions = (accounts) => {
  return accounts.map((a) => {
    return {
      value: a.accountId,
      label: a.name,
    };
  });
};

const accountsAreDifferent = (newAccounts, accounts) => {
  if (newAccounts.length !== accounts.length) {
    return true;
  }
  return some(newAccounts, (newAccount) => {
    const account = accounts.find((a) => a.accountId === newAccount.accountId);
    if (!account) {
      return true;
    }
    return !isEqual(newAccount, account);
  });
};

const selectedOptionsAreDifferent = (newOptions, options) => {
  if (newOptions.length !== options.length) {
    return true;
  }
  return some(newOptions, (newOption) => {
    return options.findIndex((o) => o.value === newOption.value) === -1;
  });
};

export default class AccountsSelector extends React.Component {
  constructor(props) {
    super(props);

    const options = buildDropdownOptions(props.accounts);
    const selectedOptions = buildDropdownOptions(props.selectedAccounts);
    this.state = {
      initialAccounts: props.accounts,
      initialSelectedOptions: deepCopy(selectedOptions),
      options: options,
      selectedOptions: selectedOptions,
      open: false,
    };

    this.wrapperRef = React.createRef();
    this.buildMenu = this.buildMenu.bind(this);
    this.buildValueContainer = this.buildValueContainer.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.getAccount = this.getAccount.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  static getDerivedStateFromProps(newProps, oldState) {
    // Updates state when accounts prop changes or selected options are different
    const newSelectedOptions = buildDropdownOptions(newProps.selectedAccounts);
    if (
      accountsAreDifferent(newProps.accounts, oldState.initialAccounts) ||
      selectedOptionsAreDifferent(
        newSelectedOptions,
        oldState.initialSelectedOptions
      )
    ) {
      return {
        initialAccounts: newProps.accounts,
        initialSelectedOptions: deepCopy(newSelectedOptions),
        options: buildDropdownOptions(newProps.accounts),
        selectedOptions: newSelectedOptions,
      };
    }
    return oldState;
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  buildMenu(menuProps) {
    const { selectedOptions } = this.state;
    const { accounts, showSelectAll } = this.props;

    return (
      <Menu
        {...menuProps}
        allAccountsCount={accounts.length}
        selectedAccountsCount={selectedOptions.length}
        onCancel={this.handleCancel}
        onDone={this.handleDone}
        onClickSelectAll={showSelectAll ? this.handleSelectAllClick : undefined}
      />
    );
  }

  buildValueContainer(valueContainerProps) {
    const { accounts, selectedAccounts, allAccountsLabel } = this.props;

    return (
      <ValueContainer
        {...valueContainerProps}
        selectedAccountsCount={selectedAccounts.length}
        allAccountsCount={accounts.length}
        allAccountsLabel={allAccountsLabel}
        onButtonClick={this.handleToggle}
      />
    );
  }

  getAccount(value) {
    const { accounts } = this.props;

    return accounts.find((a) => a.accountId === value);
  }

  handleChange(selectedOptions) {
    const { accounts, selectedAccounts, getIsAccountDisabled } = this.props;
    const wasSelected = (accountId) =>
      selectedAccounts.findIndex((a) => a.accountId === accountId) !== -1;
    const isSelected = (accountId) =>
      selectedOptions.findIndex((a) => a.value === accountId) !== -1;

    const newSelectedOptions = accounts
      .map((a) => {
        const isDisabled = getIsAccountDisabled
          ? getIsAccountDisabled(a)
          : false;
        const getSelected = isDisabled ? wasSelected : isSelected;
        if (getSelected(a.accountId)) {
          return {
            value: a.accountId,
            label: a.name,
          };
        }
        return null;
      })
      .filter((option) => option !== null);
    this.setState({
      selectedOptions: newSelectedOptions,
    });
  }

  handleCancel() {
    const { selectedAccounts, onCancel } = this.props;
    const selectedOptions = buildDropdownOptions(selectedAccounts);
    this.setState({
      selectedOptions: selectedOptions,
      open: false,
    });
    onCancel();
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleToggle(e) {
    e.preventDefault();

    const { open } = this.state;

    if (open) {
      this.handleCancel();
    } else {
      this.handleOpen();
    }
  }

  handleDone() {
    const { accounts, onDone } = this.props;
    const { selectedOptions } = this.state;
    const selectedAccounts = accounts.filter(
      (account) =>
        selectedOptions.findIndex(
          (option) => option.value === account.accountId
        ) !== -1
    );
    onDone(selectedAccounts);
    this.setState({
      open: false,
    });
  }

  handleSelectAllClick(event) {
    const shouldSelectAll = event.target.checked;

    let toggleAccounts = shouldSelectAll
      ? buildDropdownOptions(this.props.accounts)
      : [];

    this.handleChange(toggleAccounts);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.open
    ) {
      this.handleCancel();
    }
  }

  render() {
    const { options, selectedOptions, open } = this.state;

    return (
      <div ref={this.wrapperRef}>
        <Select
          classNamePrefix="Select"
          className={this.props.selectClassName}
          options={options}
          isMulti
          menuIsOpen={open}
          onMenuOpen={this.handleOpen}
          onMenuClose={this.handleCancel}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          isSearchable={false}
          menuPlacement={this.props.menuPlacement}
          onChange={this.handleChange}
          components={{
            Option: (optionProps) => (
              <Option
                {...optionProps}
                getAccount={this.getAccount}
                getIsAccountDisabled={this.props.getIsAccountDisabled}
              />
            ),
            Menu: this.buildMenu,
            MenuList: MenuList,
            ValueContainer: this.buildValueContainer,
            DropdownIndicator: FancySelectUtils.DropdownIndicator,
            NoOptionsMessage: FancySelectUtils.NoOptionsMessage,
          }}
          styles={FancySelectUtils.FancySelectStyles}
          value={selectedOptions}
        />
      </div>
    );
  }
}

AccountsSelector.propTypes = {
  accounts: PropTypes.array,
  selectedAccounts: PropTypes.array,
  allAccountsLabel: PropTypes.string,
  getIsAccountDisabled: PropTypes.func,
  onCancel: PropTypes.func,
  onDone: PropTypes.func,
  showSelectAll: PropTypes.bool,
  menuPlacement: PropTypes.string,
  selectClassName: PropTypes.string,
};

AccountsSelector.defaultProps = {
  accounts: [],
  selectedAccounts: [],
  allAccountsLabel: undefined,
  getIsAccountDisabled: undefined,
  onCancel: noop,
  onDone: noop,
  showSelectAll: true,
  menuPlacement: "auto",
  selectClassName: "accounts-selector__select Select Select--multiple",
};
