import React from "react";
import { filter, last, sortBy } from "underscore";
import Loading from "components/common/loading/Loading";
import parseResponseErrors from "libs/pcap/utils/response";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import * as feedURLs from "views/modules/advisor/sections/feedUrls";
import $ from "jquery";
import InsightBlog from "./InsightBlog";

const BLOG_SESSION_KEY = "LATEST_BLOG_POST";

/**
 * @param {string} url URL from blog post
 * @returns {string} Last path of a URL
 */
export const getURLPath = (url) =>
  last(
    filter(url.split("/"), function (part) {
      return part !== "";
    })
  );

export default class InsightBlogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: null,
    };

    this.blogActionClick = this.blogActionClick.bind(this);
  }

  componentDidMount() {
    try {
      this.viewBlog();
      const storedBlog = sessionStorage.getItem(BLOG_SESSION_KEY);

      if (storedBlog) {
        this.generateBlog(JSON.parse(storedBlog));
      } else {
        this.fetchJsonFeed(feedURLs.WEEKLY_COMMENTARY_URL_JSON)
          .then((feed) => {
            if (feed.items && feed.items.length > 0) {
              const mostRecentArticle = sortBy(
                feed.items,
                ({ date_published: datePublished }) => datePublished
              )
                .reverse()
                .slice(0, 1)[0];

              sessionStorage.setItem(
                BLOG_SESSION_KEY,
                JSON.stringify(mostRecentArticle)
              );
              this.generateBlog(mostRecentArticle);
              return;
            }

            this.setState({ loading: false });
          })
          .catch(() => {
            // Swallowing the API error as this section is
            // not critical to render the page.
            this.setState({ loading: false });
          });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  fetchJsonFeed(jsonUrl) {
    return new Promise((resolve, reject) => {
      $.getJSON(jsonUrl)
        .then(resolve)
        .fail((err) => {
          const errors = parseResponseErrors(err, {});
          if (errors) {
            reject(errors);
          }
        });
    });
  }

  generateBlog(post) {
    const bloglink = getURLPath(post.url);
    this.setState({
      title: post.title,
      image: post.featured_image,
      text: post.content_html,
      link: bloglink,
      loading: false,
    });
  }

  viewBlog() {
    ComponentAnalytics.trackEvent("Blog Insight", "View Insight", {
      component: "BLOG",
    });
  }

  blogActionClick(e) {
    ComponentAnalytics.trackEvent("Blog Insight", "Click Insight", {
      component: "BLOG",
      buttonLabel: e.target.innerHTML,
    });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <InsightBlog
        image={this.state.image}
        text={this.state.text}
        title={this.state.title}
        link={this.state.link}
        onActionClick={this.blogActionClick}
      />
    );
  }
}
