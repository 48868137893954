import PropTypes from "prop-types";
import React from "react";
import Message from "components/common/Message";
import LoadingOverlay from "components/common/LoadingOverlay";

const RemoveAccountView = ({
  onCancel,
  onRemoveAccount,
  loading,
  errors,
  modalCancelButtonText,
  modalConfirmButtonText,
  modalBody,
  renderButtonSet,
  customActions,
}) => {
  return (
    <div>
      <Message severity="error" className="pc-u-mb-" messages={errors} />
      <LoadingOverlay active={loading} />
      {modalBody}
      {renderButtonSet(
        {
          onCancel,
          modalCancelButtonText,
          onRemoveAccount,
          modalConfirmButtonText,
        },
        customActions
      )}
    </div>
  );
};

RemoveAccountView.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onRemoveAccount: PropTypes.func.isRequired,
  modalCancelButtonText: PropTypes.string.isRequired,
  modalConfirmButtonText: PropTypes.string.isRequired,
  modalBody: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  renderButtonSet: PropTypes.func,
  customActions: PropTypes.object,
};

const renderButtonSet = (buttonProps) => {
  return (
    <div className="u-text-right pc-u-mt">
      <button
        type="button"
        onClick={buttonProps.onCancel}
        className="pc-btn js-remove-account__cancel qa-remove-account-cancel"
      >
        {buttonProps.modalCancelButtonText}
      </button>
      <button
        type="button"
        onClick={buttonProps.onRemoveAccount}
        className="pc-btn pc-btn--danger js-remove-account__confirm qa-remove-account-confirm"
      >
        {buttonProps.modalConfirmButtonText}
      </button>
    </div>
  );
};

renderButtonSet.displayName = "ButtonSet";

RemoveAccountView.defaultProps = {
  loading: false,
  errors: [],
  renderButtonSet,
  customActions: undefined,
};

export default RemoveAccountView;
