import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import BudgetingContainer from "components/ClientJourney/EmergencyFund/Budgeting/BudgetingContainer";

export default class BudgetingSummaryEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
  }
  handleSave(budget) {
    const { onSave, onClosed } = this.props;
    onSave(budget);
    onClosed();
  }
  render() {
    return (
      <Modal
        componentName="RetirementSavingsSummary"
        contentLabel="Set Monthly Budget"
        title="Set Monthly Budget"
        onClosed={this.props.onClosed}
        isOpen={this.props.isOpen}
        className="pc-modal qa-modal pc-modal--small"
      >
        <BudgetingContainer
          onCancel={this.props.onClosed}
          doneButtonText="Save"
          onDone={this.handleSave}
        />
      </Modal>
    );
  }
}
BudgetingSummaryEditModal.propTypes = {
  isOpen: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
};

BudgetingSummaryEditModal.defaultProps = {
  isOpen: true,
};
