import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import ApplyTransactionRuleContainer from "./ApplyTransactionRuleContainer";
import { noop } from "underscore";

const ApplyTransactionRuleModal = (props) => {
  const {
    isOpen,
    model,
    originalModel,
    categories,
    handleSubmit,
    handleCloseModal,
  } = props;

  return (
    <Modal
      componentName="ApplyTransactionRuleModal"
      className="pc-modal--smedium"
      contentLabel="Apply Changes to Future Transactions?"
      title="Apply changes to future transactions?"
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onClosed={handleCloseModal}
    >
      <ApplyTransactionRuleContainer
        handleCloseModal={handleCloseModal}
        handleSubmit={handleSubmit}
        originalModel={originalModel}
        model={model}
        categories={categories}
      />
    </Modal>
  );
};

ApplyTransactionRuleModal.propTypes = {
  isOpen: PropTypes.bool,
  model: PropTypes.object,
  originalModel: PropTypes.object,
  categories: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleCloseModal: PropTypes.func,
};

ApplyTransactionRuleModal.defaultProps = {
  isOpen: false,
  model: {},
  originalModel: {},
  categories: [],
  handleSubmit: noop,
  handleCloseModal: noop,
};

export default ApplyTransactionRuleModal;
