import PropTypes from "prop-types";
import React from "react";
import SelectableItemsList from "components/common/multiselectDropdown/SelectableItemsList";

const preserveCaseRebranding = "u-preserve-case";
const SelectableItemsGroup = (props) => {
  const {
    header,
    className,
    classNameHeader,
    classNameList,
    onChangeAll,
    checkedAll,
  } = props;
  return (
    <div className={className}>
      <div className={classNameHeader}>
        <label className={preserveCaseRebranding}>
          <input
            type="checkbox"
            data-group-name={header}
            checked={checkedAll}
            onChange={onChangeAll}
            className={`pc-u-mr-- drop-menu ${preserveCaseRebranding}`}
            tabIndex="-1"
          />
          {header}
        </label>
      </div>
      <SelectableItemsList {...props} className={classNameList} />
    </div>
  );
};

SelectableItemsGroup.propTypes = Object.assign(
  {},
  SelectableItemsList.propTypes,
  {
    classNameList: PropTypes.string,
    header: PropTypes.string,
    onChangeAll: PropTypes.func,
  }
);

SelectableItemsGroup.defaultProps = {
  classNameHeader: "",
  classNameList: "",
};

export default SelectableItemsGroup;
