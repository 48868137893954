import PropTypes from "prop-types";
import React from "react";
import { InviteSecondaryAccountOwnerContainer } from "components/hysa/InviteSecondaryAccountOwner/Container";
import { create } from "accessors/pcb/enrollment/accessor";
import Modal from "components/modal/Modal";
import { getSource } from "components/common/attributionStore";
import { noop } from "underscore";

const InviteSecondaryAccountOwnerModal = ({
  onCancel,
  onDone,
  onWithdrawnInvitation,
  onInviteSent,
  isOpen,
  userAccountId,
}) => {
  return (
    <Modal
      componentName="Add Account Owner"
      title="Add Account Owner"
      isOpen={isOpen}
      onClosed={onCancel}
      className="pc-modal pc-modal--invite-account-owner"
      shouldCloseOnOverlayClick={false}
    >
      <InviteSecondaryAccountOwnerContainer
        sendInviteService={({ personId }) => {
          return create(
            {
              userAccountId,
              personRoles: {
                [personId]: "SECONDARY",
              },
            },
            { source: getSource() }
          );
        }}
        userAccountId={userAccountId}
        onCancel={onCancel}
        onInviteSent={onInviteSent}
        onDone={onDone}
        onWithdrawnInvitation={onWithdrawnInvitation}
      />
    </Modal>
  );
};

InviteSecondaryAccountOwnerModal.propTypes = {
  userAccountId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onWithdrawnInvitation: PropTypes.func.isRequired,
  onInviteSent: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

InviteSecondaryAccountOwnerModal.defaultProps = {
  onInviteSent: noop,
};

export default InviteSecondaryAccountOwnerModal;
