import React from "react";
import AccountBox from "components/AccountBox/AccountBox";

export default function AccountOption(props) {
  return (
    <div className="account-select-option">
      <AccountBox
        {...props}
        className="account-box--option"
        showLogo={true}
        showAvailableCash={false}
        showAvailableBalance={false}
      />
    </div>
  );
}
