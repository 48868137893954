import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { timeFormat, extent, select, transition, max, selectAll } from "d3";
import PcapChart from "libs/pcap/chart/chart";
import { formatCurrency } from "libs/pcap/utils/format";

const Y_DOMAIN_PADDING_FACTOR = 1.2;
const DEFAULT_CHART_XSCALE_PADDING_INNER = 0.2;
const NARROW_CHART_XSCALE_PADDING_INNER = 0.5;
const DISPLAY_ALL_TICK_LABELS_LIMIT = 5;
const INTERVAL_DISPLAYED_TICK_LABELS = 2;

const tickMonthFormatter = timeFormat("%b");

function getKeyAccessor(item) {
  return item.date;
}

/**
 * @param {object} item - data point
 * Given the date, parse it and return the 3-letter
 * @returns {function} the X Accessor function for the D3 wrapper
 */
function xAccessor(item) {
  return moment(item.date);
}

/**
 * @param {object} item - data point
 * @returns {function} the Y Accessor function for the D3 wrapper.
 */
function yAccessor(item) {
  return item.aggregateCashBalance;
}

function getPaddingOuter(numberOfDataPoints) {
  return numberOfDataPoints > DISPLAY_ALL_TICK_LABELS_LIMIT ? 0 : 1;
}

function getPaddingInner(numberOfDataPoints) {
  return numberOfDataPoints > 1
    ? DEFAULT_CHART_XSCALE_PADDING_INNER
    : NARROW_CHART_XSCALE_PADDING_INNER;
}
// Make sure both the bars and the fund recommendation rectangle are contained by the chart
function buildYDomain(data, recommendationMax) {
  const maxBarValue = Math.max(...data.map((d) => d.aggregateCashBalance));
  return extent([
    0,
    Y_DOMAIN_PADDING_FACTOR * Math.max(maxBarValue, recommendationMax),
  ]);
}

/**
 * Tick Format function.
 * @param {object} momentDate - date wrapped in MomentJS
 * @param {int} index - index within the loop
 * @param {list} list - the list that we are looping through
 * @return {string} formatted string
 */
function xAxisTickFormatter(momentDate, index, list) {
  if (
    list.length <= DISPLAY_ALL_TICK_LABELS_LIMIT ||
    index % INTERVAL_DISPLAYED_TICK_LABELS === 0
  ) {
    return tickMonthFormatter(momentDate);
  }
  return "";
}

export default class EmergencyFundSummaryChart extends React.Component {
  componentDidMount() {
    this.renderChart();
  }

  /**
   * Post-update operations to update d3 graph
   */
  componentDidUpdate() {
    this.updateChart();
  }

  /**
   * Render the D3 chart
   */
  renderChart() {
    const { data } = this.props;

    const yDomain = buildYDomain(this.props.data, this.props.recommendationMax);

    this.chart = PcapChart({
      type: "bar",
      showXGrid: false,
      showYGrid: false,
      showXAxis: true,
      showYAxis: false,
      key: getKeyAccessor,
      x: xAccessor,
      y: yAccessor,
      yDomain,
      margin: {
        left: 5,
        right: 5,
        bottom: 25,
      },
    });

    this.chart.xAxis.tickFormat(xAxisTickFormatter);
    this.chart.xScale.paddingInner(getPaddingInner(data.length));
    this.chart.xScale.paddingOuter(getPaddingOuter(data.length));

    const container = select(this.container);

    container.datum([data]).call(this.chart);

    this.chartBody = select(this.container).selectAll(".js-chart-body");

    this.renderBackground(data);
    this.highlightLastBar();
    if (data && data.length > 0) {
      this.attachMouseEvents(data);

      if (data.length > 1) {
        this.renderSuggestedFund();
      }
    }
  }

  renderBackgroundBars(context) {
    const hasInheritedTransition = context instanceof transition;
    const selection = hasInheritedTransition ? context.selection() : context;

    const xScale = this.chart.xScale;
    const yScale = this.chart.yScale;
    const scaledXAccessor = (d) => {
      return xScale(xAccessor(d));
    };

    const yMax = Math.max(
      this.props.recommendationMax,
      max(this.props.data, (item) => item.aggregateCashBalance)
    );
    const barHeight = yScale(0) - yScale(yMax);

    const bars = selection.selectAll("rect").data((d) => d);

    // update
    bars
      .transition(transition())
      .attr("x", scaledXAccessor)
      .attr("y", yScale(yMax))
      .attr("height", barHeight)
      .attr("width", xScale.bandwidth());

    // create
    bars
      .enter()
      .append("rect")
      .attr("class", "emergency-fund-summary__chart-background-bar")
      .attr("x", scaledXAccessor)
      .attr("y", yScale(yMax))
      .attr("height", barHeight)
      .attr("width", xScale.bandwidth());

    // remove
    bars.exit().remove();
  }

  renderBackground(data) {
    const chartBackground = this.chartBody
      .selectAll(".js-emergency-fund-summary__chart-background")
      .data([data]);

    chartBackground.call(this.renderBackgroundBars.bind(this));
    chartBackground.exit().remove();

    chartBackground
      .enter()
      .append("g")
      .classed(
        "emergency-fund-summary__chart-background js-emergency-fund-summary__chart-background qa-emergency-fund-summary__chart-background",
        true
      )
      .call(this.renderBackgroundBars.bind(this))
      .lower();
  }

  renderSuggestedFund() {
    const { xScale, yScale } = this.chart;
    const rectangleHeight =
      yScale(this.props.recommendationMin) -
      yScale(this.props.recommendationMax); // positive direction is down in d3 coordinate system
    const rectangleWidth = xScale.range()[1]; // Get entire chart width

    select(".js-emergency-fund-highlight").remove();

    select(".js-emergency-fund-summary__chart-background")
      .append("rect")
      .classed(
        "js-emergency-fund-highlight emergency-fund__highlight-area",
        true
      )
      .attr("y", yScale(this.props.recommendationMax))
      .attr("width", rectangleWidth)
      .attr("height", rectangleHeight);
  }

  /**
   * Update the D3 chart
   */
  updateChart() {
    const { data } = this.props;
    this.chart.xScale.paddingInner(getPaddingInner(data.length));
    this.chart.xScale.paddingOuter(getPaddingOuter(data.length));
    select(this.container).datum([data]).call(this.chart);

    this.renderBackground(data);
    this.highlightLastBar();
    if (data && data.length > 0) {
      this.attachMouseEvents(data);
    }

    if (data.length > 1) {
      this.renderSuggestedFund();
    }
  }

  attachMouseEvents(data) {
    const currentMonthBalance = data[data.length - 1].aggregateCashBalance;
    const el = document.querySelector(
      `${
        this.props.balanceSelectorPrefix || ""
      } .js-emergency-fund-current-balance`
    );

    let bars = selectAll(".js-emergency-fund-summary-chart .js-chart-bar").on(
      "mouseover.emergencyFund",
      function (ev) {
        el.innerHTML = formatCurrency(ev.aggregateCashBalance, 0);
        bars.classed("chart__bar--active", false);
        // eslint-disable-next-line no-invalid-this, consistent-this
        this.setAttribute(
          "class",
          // eslint-disable-next-line no-invalid-this, consistent-this
          this.getAttribute("class") + " chart__bar--active"
        );
        //eslint-disable-next-line no-invalid-this, consistent-this
        this.setAttribute("role", "img");
        // eslint-disable-next-line no-invalid-this, consistent-this
        this.setAttribute(
          "aria-label",
          `${moment(ev.date).format("MMMM")} Emergency fund is:${formatCurrency(
            ev.aggregateCashBalance,
            0
          )} `
        );
      }
    );
    selectAll(".js-emergency-fund-summary-chart").on(
      "mouseleave.emergencyFund",
      function () {
        el.innerHTML = formatCurrency(currentMonthBalance, 0);
        bars.classed("chart__bar--active", false);
        this.highlightLastBar();
      }.bind(this)
    );
  }

  highlightLastBar() {
    const lastBar = document.querySelector(
      ".js-emergency-fund-summary-chart .js-chart-bar:last-child"
    );
    if (lastBar) {
      lastBar.setAttribute(
        "class",
        lastBar.getAttribute("class") + " chart__bar--active"
      );
    }
  }

  render() {
    const { svgChartAriaLabel } = this.props;

    return (
      <svg
        width="100%"
        height="100%"
        className="summary-widget__body summary-widget__chart emergency-fund-summary__chart js-emergency-fund-summary-chart"
        ref={(container) => {
          this.container = container;
        }}
        aria-label={svgChartAriaLabel}
        alt={svgChartAriaLabel}
      />
    );
  }
}

EmergencyFundSummaryChart.propTypes = {
  data: PropTypes.array,
  recommendationMax: PropTypes.number,
  recommendationMin: PropTypes.number,
  balanceSelectorPrefix: PropTypes.string,
  svgChartAriaLabel: PropTypes.string,
};

EmergencyFundSummaryChart.defaultProps = {
  data: [],
  recommendationMin: 0,
  recommendationMax: 0,
  balanceSelectorPrefix: "",
  svgChartAriaLabel: null,
};
