import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";
import { sortBy } from "underscore";

const ManageTags = (props) => {
  const {
    loading,
    errors,
    tags,
    disableCreateButton,
    onShowCreateTag,
    onShowUpdateTag,
    onShowDeleteTag,
  } = props;

  return (
    <div className="manage-tags pc-u-mv-">
      <Message className="pc-u-mb" severity="error" messages={errors} />
      <LoadingOverlay active={loading} />
      <ul className="manage-tags__list pc-u-ml0 l-spaced l-spaced--left">
        {sortBy(tags, (t) => t.label?.toLowerCase()).map((tag) => {
          return (
            <li
              className={`manage-tags__list-item qa-manage-tags__list-item pc-u-mr- pc-u-mb-${
                tag.isCustom ? "" : " manage-tags__list-item--system"
              }`}
              key={`${tag.label?.replace(/ /g, "_")}_${tag.value}`}
            >
              {tag.isCustom ? (
                <div>
                  {tag.label}{" "}
                  <div className="manage-tags__list-item-buttons">
                    <button
                      aria-label="Update Tag"
                      type="button"
                      onClick={() => onShowUpdateTag(tag)}
                      className="pc-btn pc-btn--link manage-tags__list-item-button pc-btn--tiny pc-u-p0 js-update-tag qa-update-tag__icon"
                    >
                      &nbsp;
                      <svg
                        className="manage-tags__list-item-button--update"
                        viewBox="0 0 14 14"
                      >
                        <use xlinkHref="#icon-pencil" />
                      </svg>
                    </button>
                    <button
                      aria-label="Update Tag"
                      type="button"
                      onClick={() => onShowDeleteTag(tag)}
                      className="pc-btn pc-btn--link manage-tags__list-item-button pc-btn--tiny pc-u-p0 js-delete-tag qa-delete-tag__icon"
                    >
                      <svg
                        aria-label="Remove Tag"
                        className="icon-svg"
                        viewBox="0 0 24 24"
                      >
                        <use xlinkHref="#pc-icon__remove" />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  {tag.label}
                  <InfoTooltipIcon
                    className="manage-tags__info pc-u-ml-"
                    title="This tag is a default tag so you cannot edit or delete it."
                    tooltipProps={{ placement: "left" }}
                  />
                </div>
              )}
            </li>
          );
        })}
      </ul>
      <div
        className="pc-u-mt"
        title={
          disableCreateButton
            ? "Maximum number of custom tags limit reached."
            : "Create Tag"
        }
      >
        <button
          type="button"
          className="pc-btn pc-btn--primary js-create-tag qa-manage-tags__create-tag-button"
          onClick={() => onShowCreateTag()}
          disabled={disableCreateButton}
        >
          Create Tag
        </button>
      </div>
    </div>
  );
};

ManageTags.propTypes = {
  loading: PropTypes.bool,
  disableCreateButton: PropTypes.bool,
  errors: PropTypes.array,
  tags: PropTypes.array.isRequired,
  onShowCreateTag: PropTypes.func.isRequired,
  onShowUpdateTag: PropTypes.func.isRequired,
  onShowDeleteTag: PropTypes.func.isRequired,
};

ManageTags.defaultProps = {
  loading: false,
  disableCreateButton: false,
  errors: undefined,
};

export default ManageTags;
