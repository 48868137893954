import PropTypes from "prop-types";
import React from "react";
import "libs/pcap/utils/date";
import DividendsReceivedContainer from "components/dividendsReceived/DividendsReceivedContainer";
import Loading from "components/common/LoadingOverlay";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import mixpanel from "mixpanel";
import { getEventData } from "components/opportunitiesSummary/InsightsUtils";
import deepCopy from "deep-copy";
import PersonalStrategyTradesMadeContainer from "components/PersonalStrategy/PersonalStrategyTradesMade/PersonalStrategyTradesMadeContainer";

class MyStrategy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      normalDividends: 0,
      taxAdvantagedDividends: 0,
      totalDividends: 0,
      normalDividendsMonthly: 0,
      taxAdvantagedDividendsMonthly: 0,
      totalDividendsMonthly: 0,
      loading: true,
    };

    this.finishedLoading = this.finishedLoading.bind(this);
  }
  componentDidMount() {
    mixpanel.trackEvent(
      `View Insight`,
      getEventData({ message: this.props.message })
    );
  }

  finishedLoading() {
    this.setState({ loading: false });
  }

  render() {
    const { message, DividendsReceived, isAddFundsEligible, TradesMade } =
      this.props;
    // Non-default version of DividendsReceived does not need a loader
    const userMessage = deepCopy(message);

    // remove add funds button if ADD_FUNDS_ELIGIBLE UI preferences is false
    if (!isAddFundsEligible) {
      const messageActions = userMessage?.action?.filter(
        (a) => a.key !== "ADD_FUNDS"
      );
      userMessage.action = messageActions;
    }

    const usingDefaultDividendsReceived =
      DividendsReceived === DividendsReceivedContainer;
    return (
      <div className={`insight__message${IS_EMPOWER ? " pc-u-mt-" : ""}`}>
        {usingDefaultDividendsReceived && (
          <Loading
            active={this.state.loading}
            className={"pc-overlay--solid"}
          />
        )}
        <div className="insight__header qa-insight-header">
          <a
            className="summary-widget__title--link"
            href="#/strategy"
            dangerouslySetInnerHTML={{ __html: userMessage.title }}
            aria-label="Go to Personal strategy"
          />
        </div>
        <div className="insight__summary">
          {DividendsReceived ? (
            DividendsReceived
          ) : (
            <DividendsReceivedContainer
              parentComponent={"Insight Widget"}
              finishedLoading={this.finishedLoading}
              message={userMessage}
            />
          )}
        </div>
        <div className="insight__summary insight__summary--my-strategy-trades-made">
          {TradesMade ? (
            TradesMade
          ) : (
            <PersonalStrategyTradesMadeContainer titleClassName="u-text-bold" />
          )}
        </div>
        <div className="insight__buttons">
          {getActionButtons({
            message: userMessage,
            appointmentReason: "My Strategy",
            buttonClass: "pc-btn pc-btn--full pc-u-ml0 pc-u-mb",
          })}
        </div>
      </div>
    );
  }
}

MyStrategy.defaultProps = {
  message: {},
  DividendsReceived: undefined,
  isAddFundsEligible: false,
  TradesMade: undefined,
};

MyStrategy.propTypes = {
  message: PropTypes.object,
  DividendsReceived: PropTypes.node,
  isAddFundsEligible: PropTypes.bool,
  TradesMade: PropTypes.node,
};

export default MyStrategy;
