import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "libs/pcap/utils/format";

const StockOptionsNav = (props) => {
  const { isPublic, account, vestedAmount } = props;
  let accountClosedClass = account.closedDate ? "account-details-closed" : "";
  return (
    <div className="nav-secondary nav-secondary--top">
      <div className="l-spaced l-spaced--flush">
        <h1
          className={`nav-secondary__title qa-page-title ${accountClosedClass}`}
          title={account.name}
        >
          {account.name}
        </h1>
        {!isPublic && (
          <div
            className={`account-details-info account-details-info--stock-vested ${accountClosedClass}`}
          >
            Vested{" "}
            <span
              className={`account-details-info__value ${accountClosedClass}`}
            >
              {formatCurrency(vestedAmount, 0)}
            </span>
          </div>
        )}
      </div>
      {isPublic && (
        <div className="l-spaced l-spaced--flush pc-u-mt-">
          <div
            className={`account-details-account-name js-account-details-account-name ${accountClosedClass}`}
          >
            {account.ticker}
          </div>
          <div
            className={`account-details-info account-details-info--stock-vested ${accountClosedClass}`}
          >
            Vested{" "}
            <span
              className={`account-details-info__value ${accountClosedClass}`}
            >
              {formatCurrency(vestedAmount, 0)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

StockOptionsNav.propTypes = {
  account: PropTypes.object,
  vestedAmount: PropTypes.number,
  isPublic: PropTypes.bool,
};

StockOptionsNav.defaultProps = {
  account: {},
  vestedAmount: 0,
  isPublic: false,
};

export default StockOptionsNav;
