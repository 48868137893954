import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import moment from "moment";
import Loading from "components/common/loading/Loading";
import Message from "components/common/Message";
import PortfolioBalanceSummaryZeroState from "components/portfolioBalanceSummary/PortfolioBalanceSummaryZeroState";
import PortfolioBalanceSummaryView from "components/portfolioBalanceSummary/PortfolioBalanceSummaryView";
import PortfolioBalanceSummaryPartialHistory from "components/portfolioBalanceSummary/PortfolioBalanceSummaryPartialHistory";
import {
  aggregateBalanceSummary,
  aggregateBalanceSummaryByAccountIds,
} from "./utils/aggregateBalanceSummary";

function isPersonalCapitalClient() {
  return window && window.userHasOnusAccounts === true;
}

export default class PortfolioBalanceSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterName: "all",
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  /**
   * The user wants to filter the histories
   * @param {String} filterName - what to filter by
   */
  handleFilterChange(filterName) {
    this.setState({
      filterName: filterName,
    });
  }

  /**
   * Creates a hash of segmented account data
   * @param {array} accounts - a list of User Accounts
   * @returns {object} segmented data
   */
  parseAccountsIntoSegmentedData(accounts) {
    let investments = {
      all: {
        label: "All Investments",
        accountIds: [],
        total: 0,
      },
      pc: {
        label: "Personal Strategy",
        accountIds: [],
        total: 0,
      },
      other: {
        label: "Other Accounts",
        accountIds: [],
        total: 0,
      },
    };

    if (accounts && accounts.length) {
      // parse all Investment Accounts
      accounts.forEach((item) => {
        const accountId = item.userAccountId;
        const accountBalance = item.currentBalance;
        if (
          item.productType === "INVESTMENT" &&
          accountId &&
          !Number.isNaN(accountBalance)
        ) {
          investments.all.accountIds.push(accountId);
          investments.all.total += accountBalance;

          if (item.isOnUs || item.isOnUsRetirement) {
            investments.pc.accountIds.push(accountId);
            investments.pc.total += accountBalance;
          } else {
            investments.other.accountIds.push(accountId);
            investments.other.total += accountBalance;
          }
          return item;
        }
      });
    }

    return investments;
  }

  /**
   * Find and sum up history information by accountIds
   * @param {array} histories - history data to be parsed.
   * @param {array} parsedAccountData - return value of `parseAccountsIntoSegmentedData`
   * @param {string} filterName - all|pc|other
   * @return {array} an array of histories whose aggregateBalance values are summed by accountIds
   */
  parseDataHistories(histories, parsedAccountData, filterName) {
    if (!(histories && histories.length)) {
      return null;
    }

    if (!_.contains(["all", "pc", "other"], filterName)) {
      return null;
    }

    // 1) All filter
    if (filterName === "all") {
      return aggregateBalanceSummary(histories);
    }

    // 2) Personal Capital or 'Other' Accounts filter
    const filterByAccountIds = parsedAccountData[filterName].accountIds || [];

    return aggregateBalanceSummaryByAccountIds(histories, filterByAccountIds);
  }

  render() {
    const {
      useVariableTimeFrame,
      loading,
      errors,
      histories,
      accounts,
      ace,
      headerText,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (!_.isEmpty(errors)) {
      return <Message severity="error" messages={errors} />;
    }

    // If only the very minimal amount of history data.
    if (histories && histories.length === 1) {
      const balance = _.last(histories).aggregateBalance;

      return (
        <PortfolioBalanceSummaryPartialHistory balance={balance} ace={ace} />
      );
    }

    // if Personal Capital client
    if (isPersonalCapitalClient()) {
      const filterName = this.state.filterName;

      const segmentedAccounts = this.parseAccountsIntoSegmentedData(
        accounts,
        histories
      );
      const filteredHistories = this.parseDataHistories(
        histories,
        segmentedAccounts,
        filterName
      );

      return (
        <PortfolioBalanceSummaryView
          histories={filteredHistories}
          headerText={headerText}
          useVariableTimeFrame={useVariableTimeFrame}
          filters={[
            {
              id: "all",
              name: "All investments",
              className: "qa-portfolio-balance-summery-all-investment",
              balance: segmentedAccounts.all.total,
            },
            {
              id: "pc",
              name: "Personal Strategy",
              className: "qa-portfolio-balance-summery-personal-capital",
              balance: segmentedAccounts.pc.total,
            },
            {
              id: "other",
              name: "Other accounts",
              className: "qa-portfolio-balance-summery-other-accounts",
              balance: segmentedAccounts.other.total,
            },
          ]}
          activeFilter={filterName}
          onFilterChange={this.handleFilterChange}
          ace={ace}
        />
      );
    }

    // else, just another regular PC user
    const lastHistory = _.last(histories);
    const balance = lastHistory ? lastHistory.aggregateBalance : null;
    const filteredHistories =
      (histories &&
        histories.map((item) => {
          return {
            date: moment(item.date),
            aggregateBalance: item.aggregateBalance,
            oneDayChange: item.aggregateDailyChangeAmount,
          };
        })) ||
      null;

    if (!histories) {
      return <PortfolioBalanceSummaryZeroState />;
    }

    return (
      <PortfolioBalanceSummaryView
        headerText={headerText}
        histories={filteredHistories}
        balance={balance}
        useVariableTimeFrame={useVariableTimeFrame}
        ace={ace}
      />
    );
  }
}

PortfolioBalanceSummary.propTypes = {
  headerText: PropTypes.string,
  loading: PropTypes.bool,
  accounts: PropTypes.array,
  histories: PropTypes.array,
  errors: PropTypes.array,
  useVariableTimeFrame: PropTypes.bool,
  ace: PropTypes.object,
};

PortfolioBalanceSummary.defaultProps = {
  headerText: undefined,
  loading: false,
  accounts: undefined,
  histories: undefined,
  errors: undefined,
  useVariableTimeFrame: false,
  ace: undefined,
};
