var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"pc-layout__item\">\n        <button\n          type=\"button\"\n          class=\"pc-btn pc-btn--tiny pc-btn--stripped js-edit-account-button\"\n          title=\"Edit account\"\n          aria-label=\"Edit account\"\n        >\n          <svg class=\"account-details-edit-account-icon\" viewBox=\"0 0 16 16\">\n            <use xlink:href=\"#icon-pencil\"></use>\n          </svg>\n        </button>\n      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <svg\n        class=\"icon__help-circled\"\n        viewBox=\"0 0 16 16\"\n        data-toggle=\"tooltip\"\n        data-placement=\"bottom\"\n        title=\"As described in your Empower Personal Cash Program Agreement, you have established a Funding Account with UMB for the purpose of placing funds into the Program. Each Business Day, UMB, as your agent and custodian, sweeps funds from your Funding Account into Program Accounts in your Custodial Account.\"\n      >\n      </svg>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span class=\"js-closed-text\">Balance</span>\n      <span\n        class=\"account-details-info__value js-closed-text qa-details-info-value\"\n        >"
    + container.escapeExpression(__default(require("../helpers/dollarAndCentsAmount.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"balance") : depth0),true,true,(depth0 != null ? lookupProperty(depth0,"isLiability") : depth0),{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":54,"column":9},"end":{"line":54,"column":63}}}))
    + "</span\n        >\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"pc-layout pc-layout--auto centi\">\n      <div class=\"account-details-info pc-layout__item js-apy\">\n        APY\n        <svg\n          class=\"icon__help-circled\"\n          viewBox=\"0 0 16 16\"\n          data-toggle=\"tooltip\"\n          data-placement=\"auto\"\n          title=\"Please see your Empower Personal Cash Customer Agreement for more information on the APY for Empower Personal Cash. Interest is paid by Program Banks, and not by UMB.\"\n        >\n       </svg>\n        <span class=\"account-details-info__value\">\n          "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"apy") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":94,"column":10},"end":{"line":94,"column":68}}})) != null ? stack1 : "")
    + "\n        </span\n        >\n      </div>\n    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/dollarAndCentsAmount.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"apy") : depth0),{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":94,"column":21},"end":{"line":94,"column":51}}}))
    + "%";
},"10":function(container,depth0,helpers,partials,data) {
    return "-";
},"12":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"sweep-program-container js-sweep-program-container\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"nav-secondary js-secondary-nav\">\n  <div class=\"l-spaced l-spaced--flush pc-u-mb-\">\n    <div class=\"pc-layout pc-layout--auto pc-layout--flush pc-layout--middle\">\n      <div class=\"pc-layout__item\">\n        <h1\n          class=\"u-preserve-case nav-secondary__title qa-page-title js-account-details-firm-name js-closed-text\"\n          title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"firmName") : depth0), depth0))
    + "\"\n          data-hj-masked\n        >\n          "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cappedFirmName") : depth0), depth0))
    + "\n        </h1>\n      </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"isOnUsBank") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":26,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"js-account-details-action-container\"></div>\n  </div>\n  <div class=\"l-spaced l-spaced--flush\">\n    <div class=\"account-details-name-container\">\n      <div\n        class=\"account-details-account-name js-account-details-account-name qa-account-details-account-name js-closed-text\"\n        data-hj-masked\n      >\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cappedName") : depth0), depth0))
    + "\n      </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isOnUsBank") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":6},"end":{"line":47,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"account-details-info\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"isOnUsBank") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":6},"end":{"line":56,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>\n\n<div class=\"nav-secondary nav-secondary--feature-controls\">\n  <div class=\"l-spaced l-spaced--flush\">\n    <div class=\"pc-layout pc-layout--auto centi\">\n      <div class=\"account-details-info pc-layout__item js-available-balance qa-available-balance\">\n        Available Balance\n        <svg\n          class=\"icon__help-circled\"\n          viewBox=\"0 0 16 16\"\n          data-toggle=\"tooltip\"\n          data-placement=\"auto\"\n          title=\"These funds are available for withdrawal. Any holds on your deposits to the program will temporarily prevent you from withdrawing funds from those deposits.\"\n        >\n        </svg>\n        <span class=\"account-details-info__value js-available-balance-value\"\n          >"
    + alias2(__default(require("../helpers/dollarAndCentsAmount.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"availableBalance") : depth0),false,true,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":75,"column":11},"end":{"line":75,"column":63}}}))
    + "</span\n        >\n      </div>\n      <div class=\"account-details-info pc-layout__item js-closed-date qa-closed-date is-hidden\">\n      </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isOnUsBank") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":4},"end":{"line":99,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"pc-layout pc-layout--auto pc-layout--small\">\n"
    + ((stack1 = container.invokePartial(require("../partials/refetchTransactions.html"),depth0,{"name":"templates/partials/refetchTransactions","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <div class=\"pc-layout__item\">\n        <div class=\"dateSelector\"></div>\n      </div>\n    </div>\n  </div>\n</div>\n\n<div class=\"frame\">\n  <div class=\"visualization offset visualization--cash-section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isOnUsBank") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":4},"end":{"line":113,"column":11}}})) != null ? stack1 : "")
    + "    <div id=\"balanceChart\" class=\"chart chart--cash-section qa-balance-chart\">\n      <div\n        id=\"balanceSVG\"\n        class=\"raphaelChart js-balance-svg qa-balance-svg\"\n      ></div>\n      <div class=\"balance legend\">\n        <table>\n          <tr>\n            <td class=\"balance\">\n              <label></label>\n              <span></span>\n            </td>\n            <td class=\"change\">\n              <label>1-Day Change</label>\n              <span></span>\n            </td>\n          </tr>\n        </table>\n      </div>\n      <div class=\"startDate\"></div>\n      <div class=\"endDate\"></div>\n      <div class=\"noData js-no-balance-data\">\n        <table>\n          <tr>\n            <td>\n              <p class=\"u-text-bold pc-u-mb0\">\n                No balance changes to display<span\n                  class=\"js-account-details-no-data-date-range\"\n                ></span>\n              </p>\n              <p>\n                Select a different time frame to see how your balance changes\n                over time\n              </p>\n            </td>\n          </tr>\n        </table>\n      </div>\n      <div\n        class=\"noData js-zero-data-for-balance-chart qa-zero-data-balance-chart\"\n      >\n        <table>\n          <tr>\n            <td>\n              <svg class=\"account-details__zero-data-balance-chart-icon\">\n                <use xlink:href=\"#pc-icon__sun\" />\n              </svg>\n              <p class=\"u-text-bold pc-u-mb0\">We only have today’s balance.</p>\n              <p>Return tomorrow to see how your balance changes over time.</p>\n            </td>\n          </tr>\n        </table>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});