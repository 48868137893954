import PropTypes from "prop-types";
import React from "react";
import AbstractForm from "components/common/form/AbstractForm";
import FieldSet from "components/common/FieldSet";
import toClient from "accessors/formField/mappers/toClient";
import Services from "services";
import { promisify } from "utils/service";
import markUserMessageAsViewed from "libs/pcap/utils/markUserMessageAsViewed";
import {
  getNamespace,
  removeInterjectionFromActiveQueue,
  addHandledInterjectionUserMessageId,
} from "components/interjection/helpers";
import Mixpanel from "mixpanel";
import eventBus from "eventBus";
import { defer, noop } from "underscore";

export default class Promo extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      showLinkAccount: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.setOnBeforeClose) {
      this.props.setOnBeforeClose(({ onDone }) => {
        if (!this.state.showLinkAccount) {
          eventBus.trigger("linkAccount:cancel");
        }
        onDone();
      });
    }
  }
  componentDidUpdate() {
    if (this.state.showLinkAccount) {
      defer(this.props.onRequestClose);
    }
  }

  handleSubmit(event) {
    event?.preventDefault();
    const { addUserResponse, userMessage } = this.props;
    const id = event.target.getAttribute("name");
    const value = event.target.getAttribute("value");
    markUserMessageAsViewed(userMessage.userMessageId);
    removeInterjectionFromActiveQueue(getNamespace(userMessage));
    addHandledInterjectionUserMessageId(userMessage.userMessageId);
    addUserResponse({ userResponse: JSON.stringify({ [id]: value }) });

    Mixpanel.trackEvent(`Click on ${userMessage.template}`, {
      component: userMessage.template,
      user_message_id: userMessage.userMessageId, // eslint-disable-line camelcase
      user_message_title: userMessage.title, // eslint-disable-line camelcase
      message_template: userMessage.template, // eslint-disable-line camelcase
      view_template: userMessage.viewTemplate, // eslint-disable-line camelcase
      button_label: event.target.innerText, // eslint-disable-line camelcase
    });
    this.setState({ showLinkAccount: true });
  }

  render() {
    const { imageSource, jsonContent, userMessage } = this.props;
    const fields = toClient(jsonContent.questionGroup);
    return (
      <form onSubmit={this.handleSubmit} className="js-promo-form">
        <div className="promo-container ">
          <div className="promo-container__header pc-u-pb-- pc-u-mb">
            <h2
              className="promo-container__header-title"
              dangerouslySetInnerHTML={{ __html: userMessage.title }}
            />
            <div className="u-text-center">
              <img src={imageSource} alt="" />
            </div>
          </div>
          <div className="promo-container__body pc-u-mt">
            <div
              className="promo-container__text"
              dangerouslySetInnerHTML={{ __html: userMessage.summary }}
            />
            <FieldSet
              fieldsConfig={fields.prompts}
              model={{}}
              refFunc={this.storeInputRef}
              shallowModelPropertyPath={true}
              className="promo-container__buttonOptions"
              onClick={this.handleSubmit}
            />
          </div>
          {jsonContent.footerDisclaimer && (
            <div
              className="promo-container__footer"
              dangerouslySetInnerHTML={{ __html: jsonContent.footerDisclaimer }}
            />
          )}
        </div>
      </form>
    );
  }
}

Promo.propTypes = {
  userMessage: PropTypes.object.isRequired,
  jsonContent: PropTypes.object.isRequired,
  imageSource: PropTypes.string.isRequired,
  setOnBeforeClose: PropTypes.func,
  onRequestClose: PropTypes.func,
  addUserResponse: PropTypes.func,
};

Promo.defaultProps = {
  addUserResponse: promisify(Services.UserResponse.add),
  setOnBeforeClose: noop,
  onRequestClose: noop,
};
