import BaseAccountSelectorView from "views/components/accountSelector/baseAccountSelectorView2";
import Account from "models/account";
import _ from "underscore";
import { getGroupLabel } from "views/modules/sidebar/utils/empowerAccounts";

const EmpowerAccountSelectorView = BaseAccountSelectorView.extend({
  initialize: function () {
    BaseAccountSelectorView.prototype.initialize.apply(this, arguments);
  },

  createGroupNames: function (groups, groupLabels, filter) {
    groups.splice(0, groups.length);
    groups.unshift("EMPOWER");
    this.groupLabels.EMPOWER = getGroupLabel(
      `${window.institutionalPartnerName} accounts`
    );

    if (filter) {
      _.each(
        filter,
        function (el) {
          groups.push(Account.PRODUCT_TYPES[el]);
        },
        this
      );
    } else {
      _.each(Account.PRODUCT_TYPES, function (propertyName) {
        groups.push(Account.PRODUCT_TYPES[propertyName]);
      });
    }
  },

  groupAccountsBy: function (groupName, accounts) {
    return _.filter(
      accounts,
      function (account) {
        if (account.get("isEmpower") && groupName === "EMPOWER") {
          return true;
        }
        return (
          account.get("productType") === groupName &&
          account.get("userAccountId") !== undefined &&
          !account.get("isEmpower")
        ); // eslint-disable-line eqeqeq
      },
      this
    );
  },
});

export default EmpowerAccountSelectorView;
