import React from "react";
import PropTypes from "prop-types";

const ReferralHeader = ({ referralSubTitle, referralContent, title }) => (
  <>
    <h1 className={"toll_gate_off_ramp__title u-text-center"}>{title}</h1>
    <h2 className="toll_gate_off_ramp__referral-subtitle u-text-center pc-u-mt-">
      {referralSubTitle}
    </h2>
    {referralContent}
  </>
);

export default ReferralHeader;

ReferralHeader.propTypes = {
  referralSubTitle: PropTypes.string,
  referralContent: PropTypes.element,
  title: PropTypes.string.isRequired,
};

ReferralHeader.defaultProps = {
  referralContent: <></>,
  referralSubTitle: "",
};
