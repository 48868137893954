/* eslint-disable camelcase */

import PropTypes from "prop-types";
import React from "react";
import TransactionsGridController from "./TransactionsGridController/TransactionsGridController";
import Services from "services";
import Message from "components/common/Message";
import { isEmpty, isEqual } from "underscore";
import transactionsToClient from "./toClient";
import deepCopy from "deep-copy";
import IframeClient from "partner/iframeClient";
import NON_DISCRETIONARY_TRANSACTION_CATEGORIES from "./nonDiscretionaryTransactionCategories";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import { promisify, subscribify } from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import LoadingOverlay from "components/common/LoadingOverlay";
import { getCsvFileName } from "utils/transactionsCsvFileNameUtils";
import { trackEvent } from "components/common/ComponentAnalytics";

const iframeClient = IframeClient.getInstance();

export default class TransactionsGridContainer extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(nextProps.categories) &&
      !isEqual(nextProps.categories, prevState.prevPropsCategories)
    ) {
      return {
        categories: deepCopy(nextProps.categories),
        prevPropsCategories: deepCopy(nextProps.categories),
      };
    }

    if (
      !isEmpty(nextProps.tags) &&
      !isEqual(nextProps.tags, prevState.prevPropsTags)
    ) {
      return {
        tags: deepCopy(nextProps.tags),
        prevPropsTags: deepCopy(nextProps.tags),
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: isEmpty(props.tags) || isEmpty(props.categories),
      errors: [],
      tags: [],
      categories: [],
      liveRegionText: "",
    };

    this.handleUpdateTransactions = this.handleUpdateTransactions.bind(this);
    this.handleDeleteTransaction = this.handleDeleteTransaction.bind(this);
    this.handleAddManualTransaction =
      this.handleAddManualTransaction.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
    this.handleCategoriesSuccess = this.handleCategoriesSuccess.bind(this);
    this.handleTagsSuccess = this.handleTagsSuccess.bind(this);
  }

  componentDidMount() {
    // In advisor app do not call transactions APIs
    // when canViewTransactions false.
    const showTransactions =
      window.canViewTransactions == null ? true : window.canViewTransactions;
    if (window.isAdvisorApp && !showTransactions) {
      return;
    }

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.getTransactionTagsCancelablePromise) {
      this.getTransactionTagsCancelablePromise.cancel();
    }

    if (this.getTransactionsTagsSubscription) {
      this.getTransactionsTagsSubscription.unwatch();
      this.getTransactionsTagsSubscription.off("change");
    }

    if (this.getTransactionCategoriesPromise) {
      this.getTransactionCategoriesPromise.cancel();
    }

    if (this.getTransactionCategoriesSubscription) {
      this.getTransactionCategoriesSubscription.unwatch();
      this.getTransactionCategoriesSubscription.off("change");
    }
  }

  componentDidUpdate() {
    if (IS_IFRAMED) {
      iframeClient.triggerResize();
    }
  }

  fetchData() {
    const { categories, tags, getTags, watchTransactionCategories } =
      this.props;
    const isCategoriesEmpty = isEmpty(categories);
    const isTagsEmpty = isEmpty(tags);

    if (!isCategoriesEmpty && !isTagsEmpty) {
      this.setState({
        loading: false,
      });
      return;
    }

    if (isTagsEmpty) {
      if (this.getTransactionsTagsSubscription) {
        this.getTransactionsTagsSubscription.unwatch();
        this.getTransactionsTagsSubscription.off("change");
      }

      this.getTransactionsTagsSubscription = getTags();

      this.getTransactionsTagsSubscription.on("change", this.handleTagsSuccess);
    }

    this.getTransactionTagsCancelablePromise = makeCancelablePromise(
      isTagsEmpty
        ? this.getTransactionsTagsSubscription.promise
        : Promise.resolve(tags)
    );

    if (isCategoriesEmpty) {
      if (this.getTransactionCategoriesSubscription) {
        this.getTransactionCategoriesSubscription.unwatch();
        this.getTransactionCategoriesSubscription.off("change");
      }

      this.getTransactionCategoriesSubscription = watchTransactionCategories();
      this.getTransactionCategoriesSubscription.on(
        "change",
        this.handleCategoriesSuccess
      );
    }

    this.getTransactionCategoriesPromise = makeCancelablePromise(
      isCategoriesEmpty
        ? this.getTransactionCategoriesSubscription.promise
        : Promise.resolve(categories)
    );

    Promise.all([
      this.getTransactionCategoriesPromise.promise,
      this.getTransactionTagsCancelablePromise.promise,
    ])
      .then(this.handleSuccess)
      .catch(this.handleFailure);
  }

  handleSuccess([categories, tags]) {
    this.setState({
      loading: false,
      tags,
      categories,
    });

    if (IS_IFRAMED) {
      iframeClient.triggerResize();
    }
  }

  handleCategoriesSuccess(categories) {
    this.setState({
      loading: false,
      categories,
    });

    if (IS_IFRAMED) {
      iframeClient.triggerResize();
    }
  }

  handleTagsSuccess(tags) {
    this.setState({
      loading: false,
      tags,
    });

    if (IS_IFRAMED) {
      iframeClient.triggerResize();
    }
  }

  handleFailure(errors) {
    if (this.getTransactionCategoriesPromise.isCanceled()) {
      return;
    }

    if (this.getTransactionTagsCancelablePromise.isCanceled()) {
      return;
    }

    this.setState({
      errors,
      loading: false,
    });
  }

  handleTags(model) {
    const transactionRequestModel = deepCopy(model);

    if (!isEmpty(transactionRequestModel.tags)) {
      transactionRequestModel.customTags = JSON.stringify(
        transactionRequestModel.tags
      );

      trackEvent("Transactions Grid", "Assign Custom Tags", {
        // eslint-disable-next-line camelcase
        custom_tag_names: transactionRequestModel.tags.join(),
      });

      delete transactionRequestModel.tags;
    }

    return transactionRequestModel;
  }

  handleUpdateTransactions(model) {
    const transactionRequestModel = this.handleTags(model);

    /* Mixpanel tracking when a transaction is updated to a non discretionary spending category */
    try {
      const userTransactionIds = JSON.parse(
        transactionRequestModel.userTransactionIds
      );
      const { transactions } = this.props;
      const { categories } = this.state;

      const modifiedTransactionCategory = categories.find(
        (c) =>
          c.transactionCategoryId ===
          transactionRequestModel.transactionCategoryId
      );

      const modifiedTransactionCategoryName = modifiedTransactionCategory
        ? modifiedTransactionCategory.originalName ||
          modifiedTransactionCategory.name
        : "";

      const isModifiedToNonDiscretionaryCategory =
        NON_DISCRETIONARY_TRANSACTION_CATEGORIES.includes(
          modifiedTransactionCategoryName
        );

      if (
        !isEmpty(userTransactionIds) &&
        !isEmpty(transactions) &&
        !isEmpty(categories)
      ) {
        userTransactionIds.forEach((userTransactionId) => {
          const transaction = transactions.find(
            (t) => t.userTransactionId === userTransactionId
          );
          let transactionCategory;
          if (transaction) {
            transactionCategory = categories.find(
              (c) => c.transactionCategoryId === transaction.categoryId
            );
          }

          const transactionCategoryName =
            transactionCategory?.originalName ??
            transactionCategory?.name ??
            "";

          if (
            transactionCategoryName !== modifiedTransactionCategoryName &&
            isModifiedToNonDiscretionaryCategory
          ) {
            ComponentAnalytics.trackEvent(
              "Transactions Grid",
              "Update Category For Transaction",
              {
                from_transaction_category: transactionCategoryName,
                to_transaction_category: modifiedTransactionCategoryName,
                user_transaction_id: userTransactionId,
              }
            );
          }
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Tracking Error: ", e);
    }
    /* Mixpanel end */
    const numberOfTransactionsEdited =
      JSON.parse(model?.userTransactionIds)?.length > 1 ? "s" : "";
    return new Promise((resolve, reject) => {
      this.props
        .updateUserTransactions(transactionRequestModel)
        .then((response) => {
          this.setState({
            loading: false,
          });
          this.handleLiveRegion(
            `Transaction${numberOfTransactionsEdited} updated`
          );
          resolve(response);
        })
        .catch(reject);
    });
  }

  handleDeleteTransaction(model) {
    return new Promise((resolve, reject) => {
      this.props
        .deleteManualTransaction(model)
        .then((response) => {
          this.setState({
            loading: false,
          });
          this.handleLiveRegion(`Manual transaction deleted`);
          resolve(response);
        })
        .catch(reject);
    });
  }

  handleLiveRegion(description) {
    this.setState({ liveRegionText: description }, () => {
      setTimeout(
        () => {
          this?.setState({ liveRegionText: "" });
        },
        100,
        this
      );
    });
  }

  handleAddManualTransaction(model) {
    return new Promise((resolve, reject) => {
      this.props
        .addManualTransaction(model)
        .then((response) => {
          this.setState({
            loading: false,
          });
          this.handleLiveRegion(`Manual Transaction added`);
          resolve(response);
        })
        .catch(reject);
    });
  }

  render() {
    // In advisor app do not show transactions gird
    // when canViewTransactions false.
    const showTransactions =
      window.canViewTransactions == null ? true : window.canViewTransactions;
    if (window.isAdvisorApp && !showTransactions) {
      return null;
    }

    const { categories, tags, loading, errors } = this.state;
    const {
      transactions,
      startDate,
      endDate,
      csvFileName,
      transactionCategoryRulesFlag,
      manualTransactionsFlag,
      eligibleForManualTransactions,
      accountsList,
    } = this.props;
    const transactionsForGrid = transactionsToClient(
      transactions,
      categories,
      tags
    );
    return (
      <div>
        <LoadingOverlay active={loading} modifier="always-centered" />
        <Message severity="error" messages={errors} />
        <TransactionsGridController
          {...this.props}
          onUpdateTransactions={this.handleUpdateTransactions}
          onDeleteTransaction={this.handleDeleteTransaction}
          onAddManualTransaction={this.handleAddManualTransaction}
          transactions={transactionsForGrid}
          categories={categories}
          tags={tags}
          csvFileName={csvFileName || getCsvFileName(startDate, endDate)}
          transactionCategoryRulesFlag={transactionCategoryRulesFlag}
          manualTransactionsFlag={manualTransactionsFlag}
          eligibleForManualTransactions={eligibleForManualTransactions}
          accountsList={accountsList}
          accountForManualTransaction={
            eligibleForManualTransactions ? this.props.account : undefined
          }
        />
        <div aria-live="polite" className="sr-only">
          {this.state.liveRegionText}
        </div>
      </div>
    );
  }
}

TransactionsGridContainer.propTypes = {
  transactions: PropTypes.array.isRequired, // TODO maybe not req
  renderDuplicates: PropTypes.bool,
  isEditable: PropTypes.bool,
  groupBy: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  columns: PropTypes.array,
  // Unless the component is used in the storybook do not pass categories.
  // Otherwise the consumer of this component has to watch for the categories server changes.
  categories: PropTypes.array,
  tags: PropTypes.array,
  getTags: PropTypes.func,
  paginator: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  searchAccessors: PropTypes.arrayOf(PropTypes.func),
  csvFileName: PropTypes.string,
  buildDataForExportToCsv: PropTypes.func,
  updateUserTransactions: PropTypes.func,
  deleteManualTransaction: PropTypes.func,
  addManualTransaction: PropTypes.func,
  watchTransactionCategories: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  transactionCategoryRulesFlag: PropTypes.bool,
  manualTransactionsFlag: PropTypes.bool,
  eligibleForManualTransactions: PropTypes.bool,
  account: PropTypes.object,
  accountsList: PropTypes.array,
};

TransactionsGridContainer.defaultProps = {
  renderDuplicates: false,
  isEditable: true,
  groupBy: undefined,
  className: undefined,
  columns: undefined,
  paginator: undefined,
  csvFileName: undefined,
  startDate: undefined,
  categories: [],
  tags: [],
  accountsList: undefined,
  getTags: subscribify(Services.Tags.get),
  endDate: undefined,
  searchAccessors: undefined,
  updateUserTransactions: promisify(Services.Transactions.updates2),
  deleteManualTransaction: promisify(Services.Transactions.delete),
  addManualTransaction: promisify(Services.Transactions.add),
  watchTransactionCategories: subscribify(Services.TransactionCategories.get),
  buildDataForExportToCsv: undefined,
  transactionCategoryRulesFlag: true,
  manualTransactionsFlag: false,
  eligibleForManualTransactions: false,
  account: undefined,
};
