import PropTypes from "prop-types";
import React from "react";
import ChartReact from "libs/pcap/chart/chart--react";
import PercentChange from "components/common/PercentChange";

/**
 * @returns {function} the X Accessor function for the D3 wrapper
 */
function getXAccessor() {
  return function (item) {
    return item.index;
  };
}

/**
 * @returns {function} the Y Accessor function for the D3 wrapper
 */
function getYAccessor() {
  return function (item) {
    return item.value;
  };
}

export default class IndexSummary extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const label = this.props.label;
    const changeToday = this.props.changeToday;

    // set up the data for the Area Chart
    const data = this.props.histories.map((item, index) => {
      item.index = index;
      return item;
    });

    let primaryIndexClass = "";
    if (this.props.isPrimary) {
      primaryIndexClass = "index-summary__header--primary";
    }

    return (
      <section
        className={
          "index-summary qa-" + (label ? label.replace(/[\s]+/g, "-") : "")
        }
      >
        <div
          className={
            "index-summary__header qa-index-summary-header " + primaryIndexClass
          }
        >
          <div className="l-spaced l-spaced--flush">
            <div className="pc-zeta qa-label">{label}</div>
            <div className="pc-zeta qa-percent-change">
              <PercentChange context={changeToday} />
            </div>
          </div>
        </div>
        <ChartReact
          data={[data]}
          type="area"
          className="index-summary__chart dashboard-chart"
          interpolate="monotone"
          showYGrid={false}
          showXGrid={false}
          showXAxis={false}
          showYAxis={false}
          showYZeroLine={false}
          x={getXAccessor()}
          y={getYAccessor()}
        />
      </section>
    );
  }
}

IndexSummary.propTypes = {
  histories: PropTypes.array,
  changeToday: PropTypes.number,
  label: PropTypes.string,
  isPrimary: PropTypes.bool,
};
