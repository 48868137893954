import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import ShareWithFriends from "components/shareWithFriends/ShareWithFriends";
import {
  getIsEnabledPromise,
  getFunnelAttributesPromise,
} from "components/shareWithFriends/ShareWithFriendsUtils";
import { trackEvent } from "components/common/ComponentAnalytics";

// Share with Friends
const COMPONENT_NAME = "Share with Friends";
const MODAL_TITLE = `Share the ${EMPOWER_PERSONAL_DASHBOARD}`;
const MODAL_CLASS_NAME = "share-with-friends-modal pc-modal--rebrand";

export default class ShareWithFriendsModal extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      isEnabled: false,
    };
  }

  componentDidMount() {
    Promise.all([getIsEnabledPromise(), getFunnelAttributesPromise()]).then(
      ([isEnabled, funnelAttrs]) => {
        if (
          !isEnabled ||
          funnelAttrs.isSuspicious ||
          !funnelAttrs.referralCampaign
        ) {
          trackEvent(
            COMPONENT_NAME,
            "Attempt to View Disabled Share with Friends",
            {
              isUserReferralEnabled: isEnabled,
              isSuspicious: funnelAttrs.isSuspicious,
              referralCampaign: funnelAttrs.referralCampaign,
            }
          );
          this.props.onModalClosed();
          return;
        }

        this.setState({
          isEnabled,
          keyParam: funnelAttrs.referralCampaign,
        });
      }
    );
  }

  render() {
    const { isEnabled, keyParam } = this.state;
    const { onModalClosed } = this.props;
    const componentName = COMPONENT_NAME;
    return (
      <Modal
        componentName={componentName}
        title={MODAL_TITLE}
        className={`${MODAL_CLASS_NAME} campaign-key--${keyParam}`}
        onClosed={onModalClosed}
        // react-modal APIs
        contentLabel={componentName}
        isOpen={isEnabled}
        shouldCloseOnOverlayClick={false}
      >
        {keyParam && (
          <ShareWithFriends keyParam={keyParam} componentName={componentName} />
        )}
      </Modal>
    );
  }
}

ShareWithFriendsModal.propTypes = {
  onModalClosed: PropTypes.func.isRequired,
};
