import moment from "moment";

/**
 * Checks if it is first day of the month
 *
 * @return {Boolean}          the boolean value of the check.
 */
export default function isItFirstDayOfTheMonth() {
  return moment().isSame(moment().startOf("month"), "day");
}
