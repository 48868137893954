import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import Message from "components/common/Message";
import Input from "components/common/form/Input";
import LoadingOverlay from "components/common/LoadingOverlay";
import CurrencyFormatterWithCents from "components/common/form/formatters/currencyWithCents";

const MAXLENGTH_AMOUNT = 4; // Micro Deposits must be between $0.01 to $0.49

class MicroDepositVerify extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    this.focus();

    if (IS_EMPOWER)
      window.dashboardUtils?.eventBus.dispatch("verify_account.page.load");
  }

  handleCancel() {
    if (IS_EMPOWER)
      window.dashboardUtils?.eventBus.dispatch(
        "verify_account.cancel_button.click"
      );

    this.props.onCancel();
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    if (IS_EMPOWER)
      window.dashboardUtils?.eventBus.dispatch(
        "verify_account.verify_button.click"
      );

    const { model } = this.state;
    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.onVerify(model);
    }
  }

  render() {
    const { validator, errors, loading, amountFormatter } = this.props;

    return (
      <>
        {IS_EMPOWER && (
          <h1 className="nav-secondary__title js-transfer-confirmation-title pc-u-mb">
            Verify account
          </h1>
        )}
        <form onSubmit={this.handleSubmit}>
          <section>
            <Message className="pc-u-mb" severity="error" messages={errors} />
            <p className="js-micro-deposit-verify__takeaway pc-u-mb">
              To verify your account, enter the two micro deposits below. If you
              don’t
              {IS_EMPOWER ? <br /> : " "}
              see them, it will take 2-3 business days to appear in your
              account.
            </p>
            <div className="pc-layout pc-layout--small pc-form-group">
              <LoadingOverlay active={loading} />
              <div
                className={`pc-layout__item ${
                  IS_EMPOWER ? `pc-u-1/4` : `pc-u-1/2`
                }`}
              >
                <label className="pc-label js-micro-deposit-verify__amount1-label">
                  Amount 1
                  <Input
                    ref={this.storeInputRef}
                    type="text"
                    id="microDepositAmount1"
                    sizeVariation="full"
                    name="microDepositAmount1"
                    onChange={this.handleInputChange}
                    validator={validator.amount}
                    maxLength={MAXLENGTH_AMOUNT}
                    prefix="$"
                    formatter={amountFormatter}
                    data-hj-masked
                  />
                </label>
              </div>
              <div
                className={`pc-layout__item ${
                  IS_EMPOWER ? `pc-u-1/4` : `pc-u-1/2`
                }`}
              >
                <label className="pc-label js-micro-deposit-verify__amount2-label">
                  Amount 2
                  <Input
                    ref={this.storeInputRef}
                    type="text"
                    id="microDepositAmount2"
                    sizeVariation="full"
                    name="microDepositAmount2"
                    onChange={this.handleInputChange}
                    validator={validator.amount}
                    maxLength={MAXLENGTH_AMOUNT}
                    prefix="$"
                    formatter={amountFormatter}
                    data-hj-masked
                  />
                </label>
              </div>
            </div>
            <div className="u-text-right">
              <button
                type="button"
                className="js-micro-deposit-verify-cancel pc-btn"
                onClick={this.handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="js-micro-deposit-verify-done pc-btn pc-btn--primary"
              >
                Verify
              </button>
            </div>
          </section>
        </form>
      </>
    );
  }
}

MicroDepositVerify.propTypes = {
  model: PropTypes.object.isRequired,
  onVerify: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  logoPath: PropTypes.string,
  errors: PropTypes.array,
  amountFormatter: PropTypes.object,
  loading: PropTypes.bool,
};

MicroDepositVerify.defaultProps = {
  loading: false,
  amountFormatter: CurrencyFormatterWithCents(2),
  logoPath: "",
  errors: [],
  validator: {
    amount: {
      type: "number",
      required: true,
      allowEmpty: false,
      messages: {
        required: "This field is required.",
        allowEmpty: "This field is required.",
      },
    },
  },
};

export default MicroDepositVerify;
