/* eslint-disable eqeqeq */
/* eslint-disable no-invalid-this */

import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import Account from "models/account";
import AccountSelectorTemplateRebrand from "templates/components/accountSelector/home_rebrand/baseAccountSelector2.html";
import Mixpanel from "mixpanel";
import "dropdown";
import { formatCurrency } from "libs/pcap/utils/format";
import "backbone.view";
/**
 * Creating a custom accounts collection
 * to duck the singleton issue of the core AccountsCollection
 * @type {*}
 */

var AccountsCollection = Backbone.Collection.extend({
  model: Account,
  idAttribute: "userAccountId",
  comparator: function (account) {
    return account.get("firmName") + account.get("name");
  },
  get: function (id) {
    return this.find(function (acct) {
      return (
        acct.get("userAccountId") == id
      ); /* unsure acct.get('userAccountId') and id are the same type */ // eslint-disable-line eqeqeq
    });
  },
});

const AccountSelector = "Account Selector";
const jsAccountGroup = ".js-account-group";

var BaseAccountSelectorView = Backbone.View.extend({
  events: {
    "click .js-checkbox-all": "toggleAllAccounts",
    "click .js-account-selector-toggle": "toggleAccountsMenu",
    "click .js-checkbox-group": "toggleAccountGroup",
    "click .js-checkbox-account": "toggleAccount",
    "click .js-done-button": "onDone",
    "click .js-cancel-button": "onCancel",
    "click .js-account-selector-menu": "preventClose",
    "hidden.bs.dropdown	.js-account-selector-dropdown": "onCancel",

    "click .js-checkbox-all.holdings": "onClickAccounts",
    "click .js-cancel-button.holdings": "OnCancelAccounts",
    "click .js-done-button.holdings": "OnDoneAccounts",

    "click .js-checkbox-all.balances": "onClickAccounts",
    "click .js-cancel-button.balances": "OnCancelAccounts",
    "click .js-done-button.balances": "OnDoneAccounts",

    "click .js-checkbox-all.performance": "onClickAccounts",
    "click .js-cancel-button.performance": "OnCancelAccounts",
    "click .js-done-button.performance": "OnDoneAccounts",

    "click .js-checkbox-all.allocation": "onClickAccounts",
    "click .js-cancel-button.allocation": "OnCancelAccounts",
    "click .js-done-button.allocation": "OnDoneAccounts",

    "click .js-checkbox-all.ussector": "onClickAccounts",
    "click .js-cancel-button.ussector": "OnCancelAccounts",
    "click .js-done-button.ussector": "OnDoneAccounts",
  },

  initialize: function (config) {
    _.extend(this, Backbone.Events);

    this.closedAccountsFilter = config.closedAccountsFilter;
    this.showBalances = Boolean(window.isAdvisorApp);

    this.filter = config.filter || false;

    this.groups = [];
    /**
     * groupLabels is a mapping of groupNames to groupLabels, which is use to display
     * more user-friendly text.
     * This property is meant to be over-written if you're defining a different set of groups.
     */
    this.groupLabels = {
      BANK: "Cash",
      INVESTMENT: "Investment",
      CREDIT_CARD: "Credit",
      LOAN: "Loan",
      MORTGAGE: "Mortgage",
    };

    /**
     * selectedUserAccountIds are the users finalized list of userAccountIds of accounts they have selected
     *
     * NOTE: this value is kept as 'all' until the user selects a subset of accounts
     * or a subset of accounts is injected from the outside.
     *
     * If the user selects all accounts, it is again set to 'all' to indicate
     * that all accounts have been selected.
     *
     * NOTE: it IS possible that the _selectedUserAccountIds
     * can have IDs of removed accounts.
     * Because the getSelectedUserAccounts() trims these off, we don't care now.
     *
     * @type {Array | 'all'}
     */
    this.isShown = false;

    this.cleanedUserAccountIds = [];
    /**
     * selectedUserAccountIds is the finalized list of chosen userAccountIds
     */
    this.selectedUserAccountIds = [];
    /**
     * checkedUserAccountIds is the list of chosen userAccountIds while the user is editing the selection.
     * It is used to set the state of "All Accounts" checkbox.
     * It is synchronized with selectedUserAccountIds when the user submits the changes.
     * ExcludedAccounts is an Array containing the IDs of the accounts excluded from household
     */
    this.checkedUserAccountIds = [];
    this.allAccountsSelected = true;
    this.excludedAccounts = [];
    this.filteredAccounts = [];

    this.createGroupNames(this.groups, this.groupLabels, this.filter);

    this.excludeManualAccounts = config.excludeManualAccounts || false;
    this.accountsCollection = new AccountsCollection();
    this.accountsCollection.on("reset", this.onAccountCollectionChange, this);
    this.accountsCollection.on("change", this.onAccountCollectionChange, this);

    this.render();
    this.fetchAccounts();
  },

  /**
   * createGroupNames populates based on the criteria logic define in the method.
   * This method is meant to be over-written if you're defining a different set of groups.
   * It is invoked in initialize().
   *
   * @param {array} groups - An array of groups where accounts are grouped by
   * @param {array} groupLabels - An array of user-friendly labels that mapped to the corresponding groupName
   * @param {array} filter - An array of groupNames of approved groupNames.  This parameter is optional
   */

  createGroupNames: function (groups, groupLabels, filter) {
    groups.splice(0, groups.length);

    if (filter) {
      _.each(
        filter,
        function (el) {
          groups.push(Account.PRODUCT_TYPES[el]);
        },
        this
      );
    } else {
      _.each(Account.PRODUCT_TYPES, function (propertyName) {
        groups.push(Account.PRODUCT_TYPES[propertyName]);
      });
    }
  },

  // eslint-disable-next-line sonarjs/cognitive-complexity
  onAccountCollectionChange: function () {
    var oldCleanedUserAccountIds = [];
    this.filteredAccounts = this.filterAccountsFromDropdownResult(
      this.accountsCollection
    );
    if (_.isFunction(this.closedAccountsFilter)) {
      this.filteredAccounts = this.closedAccountsFilter(this.filteredAccounts);
    }

    let allAccountsSelected = this.accountData.every(
      (groupData) => groupData.checked
    );

    // When there are accounts excluded from household
    // this.allAccountsSelected is true, but on UI accounts excluded from household are not checked.
    // allAccountsSelected should be set as per actual selection.
    if (!allAccountsSelected) {
      const accountsList = this.accountData.reduce(
        function (prev, curr) {
          return {
            accounts: [...(prev.accounts || []), ...(curr.accounts || [])],
          };
        },
        { accounts: [] }
      );

      if (accountsList.accounts.length) {
        allAccountsSelected = accountsList.accounts.every(
          (account) => account.checked || account.isExcludeFromHousehold
        );
      }
    }

    // If is not first run
    if (this.cleanedUserAccountIds.length !== 0) {
      oldCleanedUserAccountIds = this.cleanedUserAccountIds;
    }

    this.cleanedUserAccountIds = _.map(
      this.filteredAccounts,
      function (account) {
        return account.get("userAccountId");
      }
    );
    this.cleanedUserAccountIds = _.filter(
      this.cleanedUserAccountIds,
      function (userAccountId) {
        return userAccountId !== undefined;
      }
    );

    // If new accounts were aggregated AND a group is selected (All accounts or All personal capital accounts), we have to check them by default
    if (
      oldCleanedUserAccountIds.length !== 0 &&
      oldCleanedUserAccountIds.length !== this.cleanedUserAccountIds.length &&
      (this.onUsSelected || allAccountsSelected)
    ) {
      var newAccounts = this.cleanedUserAccountIds.filter(function (element) {
        return !oldCleanedUserAccountIds.includes(element);
      });

      // If only personal capital accounts are selected, then we've gotta filter the newAccounts array so we only check personal capital accounts
      if (!allAccountsSelected && this.onUsSelected) {
        newAccounts = newAccounts.filter((na) => {
          const account = this.filteredAccounts.find(
            (fa) => fa.get("userAccountId") === na
          );
          return this.isAccountOnUs(account);
        });
      }

      this.selectedUserAccountIds =
        this.selectedUserAccountIds.concat(newAccounts);
      this.checkedUserAccountIds =
        this.checkedUserAccountIds.concat(newAccounts);
    }

    if (allAccountsSelected) {
      this.selectedUserAccountIds = this.cleanedUserAccountIds.slice(0);
      this.checkedUserAccountIds = this.cleanedUserAccountIds.slice(0);
    } else {
      // remove account from selectedUserAccountIds and checkedUserAccountIds if user unlinks an account
      this.selectedUserAccountIds = _.filter(
        this.selectedUserAccountIds,
        function (userAccountId) {
          return _.contains(this.cleanedUserAccountIds, userAccountId);
        }.bind(this)
      );

      this.checkedUserAccountIds = _.filter(
        this.checkedUserAccountIds,
        function (userAccountId) {
          return _.contains(this.cleanedUserAccountIds, userAccountId);
        }.bind(this)
      );

      if (this.checkedUserAccountIds.length === 0) {
        this.setSelectedUserAccountIds("all");
      }
    }

    this.setAccountsExcludedFromSelection();
  },
  getCheckedAccountIds: function () {
    const checkedIds = [];
    this.$el.find(".js-checkbox-account:checked").each(function (i, checkbox) {
      var id = parseInt($(checkbox).val(), 10);
      checkedIds.push(id);
    });
    return checkedIds;
  },
  accountIsExcludedFromSelection: function (account) {
    // Default is to exclude accounts that are excluded from household
    return account.get("isExcludeFromHousehold");
  },
  setAccountsExcludedFromSelection: function () {
    var accountsToExclude = [];
    var accountsUnSelectedByUser = [];
    const checkedIds = this.getCheckedAccountIds();

    this.filteredAccounts.forEach((account) => {
      if (
        this.accountIsExcludedFromSelection(account) &&
        !checkedIds.includes(account.get("userAccountId"))
      ) {
        accountsToExclude.push(account.get("userAccountId"));
      }
    });

    // This is to cover the edge case: When the user unselect accounts from the dropdown, and
    // then exclude an account, the accounts unselected by the user should persist their state. So
    // originalAccounts - selectedAccounts = allUnselected
    // allUnselected - excludedAccounts = UserUnselected
    accountsUnSelectedByUser = this.filteredAccounts
      .filter(
        function (account) {
          return (
            !_.contains(
              this.selectedUserAccountIds,
              account.get("userAccountId")
            ) &&
            !_.contains(this.excludedAccounts, account.get("userAccountId"))
          );
        }.bind(this)
      )
      .map(function (account) {
        return account.get("userAccountId");
      });

    // the UserUnselected accounts should remain unselected no matter what.
    this.selectedUserAccountIds = this.filteredAccounts
      .filter(function (account) {
        return (
          !_.contains(accountsToExclude, account.get("userAccountId")) &&
          !_.contains(accountsUnSelectedByUser, account.get("userAccountId"))
        );
      })
      .map(function (account) {
        return account.get("userAccountId");
      });

    this.excludedAccounts = accountsToExclude;
    this.checkedUserAccountIds = this.selectedUserAccountIds.slice(0);

    // If there are accounts excluded
    if (accountsToExclude.length > 0) {
      this.allAccountsSelected = false;
      // If all selected
    } else if (
      this.selectedUserAccountIds.length === this.filteredAccounts.length
    ) {
      //
      this.allAccountsSelected = true;
    }

    this.render();
    this.trigger("selectedUserAccountIds", this.selectedUserAccountIds);
  },

  /**
   * Determines what accounts show up in the account dropdown list.
   * This method is meant to be over-written if you're defining a different set of groups.
   * It is invoked in onAccountCollectionChange().
   */

  filterAccountsFromDropdownResult: function (accountsCollection) {
    return accountsCollection.filter(function (account) {
      if (this.groups.toString().indexOf(account.get("productType")) == -1) {
        // eslint-disable-line eqeqeq
        return false;
      }

      return !(
        this.excludeManualAccounts === true && account.get("isManual") === true
      );
    }, this);
  },

  fetchAccounts: function () {
    if (this.accountsWatchId) {
      Services.Accounts.get.unwatch(this.accountsWatchId);
    }

    this.accountsWatchId = Services.Accounts.get.watch(
      this.onAccountsFetched,
      this
    );
  },

  onAccountsFetched: function (err, response) {
    if (err === null) {
      this.accountsCollection.reset(response.spData.accounts);
    } else {
      throw err;
    }
  },

  buttonLabel: function () {
    var label;
    if (this.allAccountsSelected) {
      label = "All Accounts";
    } else {
      switch (this.selectedUserAccountIds.length) {
        case 0:
          label = "No Accounts";
          break;

        case 1:
          label = "1 Account";
          break;

        default:
          label = this.selectedUserAccountIds.length + " Accounts";
      }
    }

    return label;
  },

  /**
   * This method is meant to be over-written if you're using a different handlebar template.
   */

  render: function () {
    var accountsArray = _.isFunction(this.closedAccountsFilter)
      ? this.closedAccountsFilter(this.accountsCollection.models)
      : this.accountsCollection.models;
    this.accountData = this.generateAccountDataForMenu(
      this.groups,
      accountsArray
    );

    var accountsTemplateData = {
      allAccounts: this.allAccountsSelected,
      buttonLabel: this.buttonLabel(),
      accountGroups: this.accountData,
      showBalances: this.showBalances,
    };

    const template = AccountSelectorTemplateRebrand;

    this.$el.html(template(accountsTemplateData));
    this.toggleDoneButton();
    return this;
  },
  isAccountOnUs: function (account) {
    if (!account) return false;
    return account.isOnUs || account.isOnUsRetirement;
  },
  /*
    returns an object with account data that the dropdown menu will use for display/interaction
    with an account. overridable if account properties that are passed to the dropdown view need
    to change.
  */
  mapAccountToMenuObject: function (account) {
    return {
      name: account.get("name"),
      userAccountId: account.get("userAccountId"),
      closedDate: account.get("closedDate"),
      isExcludeFromHousehold: account.get("isExcludeFromHousehold"),
      firmName: account.get("firmName"),
      balance: formatCurrency(account.get("balance")),
      checked: _.contains(
        this.checkedUserAccountIds,
        account.get("userAccountId")
      ),
    };
  },
  /*
    groups, and sorts accounts, and puts them in a format that the menu will use.
  */
  generateAccountDataForMenu: function (groups, accountsArray) {
    var self = this;
    var accountGroups = [];
    var groupSelected = true;
    this.menuItemCount = 0;

    _.each(
      groups,
      function (groupName) {
        groupSelected = true;

        var accounts = this.groupAccountsBy(groupName, accountsArray);
        accounts = _.map(
          accounts,
          function (account) {
            if (
              groupSelected &&
              !_.contains(
                this.checkedUserAccountIds,
                account.get("userAccountId")
              )
            ) {
              groupSelected = false;
            }

            return this.mapAccountToMenuObject(account);
          }.bind(this)
        );

        accounts = _.sortBy(accounts, function (account) {
          // TODO: remove after API data is fixed
          return account?.name?.toLowerCase();
        });

        if (accounts.length > 0) {
          var group = {
            name: self.groupLabels[groupName],
            accounts: accounts,
            checked: groupSelected,
          };

          accountGroups.push(group);
          self.menuItemCount++;
        }
      }.bind(this)
    );

    return accountGroups;
  },

  /**
   * groupAccountsBy returns an array of accounts that satisfies the grouping criteria defined in this method.
   * This method is meant to be over-written if you're defining a different set of groups.
   * It is invoked in generateAccountDataForMenu().
   *
   * @param (string) groupName - Name of the group to associate accounts with
   * @param (array) accounts - An array of accounts
   */

  groupAccountsBy: function (groupName, accounts) {
    return _.filter(
      accounts,
      function (account) {
        if (this.excludeManualAccounts === true) {
          // eslint-disable-line no-invalid-this
          return (
            account.get("productType") === groupName &&
            account.get("isManual") === false &&
            account.get("userAccountId") !== undefined
          ); // eslint-disable-line eqeqeq
        }
        return (
          account.get("productType") === groupName &&
          account.get("userAccountId") !== undefined
        ); // eslint-disable-line eqeqeq
      },
      this
    );
  },

  toggleAllAccounts: function (event) {
    this.allAccountsSelected = $(event.currentTarget).prop("checked");

    this.$el.find(":checkbox").prop("checked", this.allAccountsSelected);

    if (this.allAccountsSelected) {
      this.checkedUserAccountIds = this.cleanedUserAccountIds.slice(0);
    } else {
      this.checkedUserAccountIds = [];
    }

    this.onClickAccounts(event);
    this.toggleDoneButton();

    Mixpanel.trackEvent("Toggle All Accounts on Account Selector", {
      component: AccountSelector,
    });
  },

  /**
   * toggleAccountGroup sets the check.
   * This method is meant to be over-written if you're defining a different set of groups.
   * It is invoked in generateAccountDataForMenu().
   * @param (string) groupName - Name of the group to associate accounts with
   * @param (array) accounts - An array of accounts
   */

  toggleAccountGroup: function (event) {
    var isGroupChecked = $(event.currentTarget).prop("checked");
    var accountCheckboxes = this.$el
      .find(event.currentTarget)
      .closest(jsAccountGroup)
      .find(".js-checkbox-account");

    _.each(accountCheckboxes, function (checkbox) {
      $(checkbox).prop("checked", isGroupChecked);
    });

    this.updateCheckedUserAccountIds();
    this.onClickAccounts(event);
    Mixpanel.trackEvent("Toggle Account Group in Account Selector", {
      component: AccountSelector,
    });
  },

  toggleAccount: function (event) {
    var isChecked = $(event.currentTarget).prop("checked");
    var groupCheckBox = $(event.currentTarget)
      .closest(jsAccountGroup)
      .find(".js-checkbox-group");
    groupCheckBox = $(groupCheckBox);

    if (!isChecked && groupCheckBox.prop("checked")) {
      groupCheckBox.prop("checked", false);
    } else {
      var accountInputs = $(event.currentTarget)
        .closest(jsAccountGroup)
        .children()
        .find(".js-checkbox-account");

      var uncheckedAccount = _.find(accountInputs, function (input) {
        return !$(input).prop("checked");
      });

      if (uncheckedAccount) {
        groupCheckBox.prop("checked", false);
      } else {
        groupCheckBox.prop("checked", true);
      }
    }

    this.updateCheckedUserAccountIds();
    this.onClickAccounts(event);
    Mixpanel.trackEvent("Toggle Account in Account Selector", {
      component: AccountSelector,
    });
  },

  updateCheckedUserAccountIds: function () {
    // update checkedIds
    this.checkedUserAccountIds = this.getCheckedAccountIds();

    this.allAccountsSelected =
      this.cleanIds(this.checkedUserAccountIds).toString() ==
      this.cleanIds(this.cleanedUserAccountIds).toString(); // eslint-disable-line eqeqeq
    this.$el.find(".js-checkbox-all").prop("checked", this.allAccountsSelected);
    this.toggleDoneButton();
  },

  /**
   * Public method for externally setting selectedUserAccountIds.
   *
   * @param ids Accepts either a string with the value of 'all' or an array of userAccountIds
   * @param bool Determinate if accounts excluded from household should be unselected by default
   */

  setSelectedUserAccountIds: function (ids, preventExcludeExcludedAccounts) {
    if (this.isNewSelectedUserAccountIdsTheSame(ids)) {
      return;
    } else if (ids === "all") {
      this.allAccountsSelected = true;
      this.selectedUserAccountIds = this.cleanedUserAccountIds.slice(0);
      this.checkedUserAccountIds = this.cleanedUserAccountIds.slice(0);
    } else {
      this.allAccountsSelected = false;
      this.selectedUserAccountIds = _.filter(
        ids,
        function (userAccountId) {
          return _.contains(this.cleanedUserAccountIds, userAccountId);
        }.bind(this)
      );

      this.checkedUserAccountIds = this.selectedUserAccountIds.slice(0);
    }

    if (!preventExcludeExcludedAccounts) {
      this.setAccountsExcludedFromSelection();
    }
    this.render();
  },

  isNewSelectedUserAccountIdsTheSame: function (ids) {
    var isTheSame = false;

    // if ids is "all"
    if (_.isString(ids)) {
      if (_.isString(ids) && this.allAccountsSelected) {
        isTheSame = true;
      } else {
        this.selectedUserAccountIds = this.cleanedUserAccountIds;
      }
      // else "ids" is an array of ids
    } else if (
      this.cleanIds(ids).toString() ==
      this.cleanIds(this.selectedUserAccountIds).toString()
    ) {
      // eslint-disable-line eqeqeq
      isTheSame = true;
    } else {
      this.selectedUserAccountIds = ids.slice(0);
    }

    return isTheSame;
  },

  cleanIds: function (ids) {
    return _.sortBy(_.uniq(ids.slice(0)), _.identity);
  },

  toggleDoneButton: function () {
    if (this.checkedUserAccountIds.length == 0) {
      // eslint-disable-line eqeqeq
      this.$el.find(".js-done-button").prop("disabled", true);
    } else {
      this.$el.find(".js-done-button").prop("disabled", false);
    }
  },

  onDone: function () {
    var self = this;
    var sameSelection = self.isNewSelectedUserAccountIdsTheSame(
      self.checkedUserAccountIds
    );
    self.render();
    if (!sameSelection) {
      // var selection = (self.allAccountsSelected) ? 'all' : self.selectedUserAccountIds;
      self.trigger("selectedUserAccountIds", self.selectedUserAccountIds);
    }

    Mixpanel.trackEvent("Click on Done Button on Account Selector", {
      component: AccountSelector,
    });
  },

  onCancel: function () {
    var self = this;
    this.$(".js-account-selector-btn").dropdown("toggle");
    self.checkedUserAccountIds = self.selectedUserAccountIds.slice(0);
    self.allAccountsSelected =
      self.cleanIds(self.cleanedUserAccountIds).toString() ===
      self.cleanIds(self.selectedUserAccountIds).toString();
    self.render();

    Mixpanel.trackEvent("Click on Cancel Button on Account Selector", {
      component: AccountSelector,
    });
  },

  preventClose: function (event) {
    event.stopPropagation();
  },

  onClose: function () {
    this.accountsCollection.off("removed", this.onAccountRemoved, this);
    this.accountsCollection.off("reset", this.onAccountCollectionChange, this);
    this.accountsCollection.off("change", this.onAccountCollectionChange, this);

    if (this.accountsWatchId) {
      Services.Accounts.get.unwatch(this.accountsWatchId);
      delete this.accountsWatchId;
    }

    $(document.body).off(".BaseAccountSelectorView");
  },

  onClickAccounts: function (event) {
    if (IS_EMPOWER) {
      let callType = "";
      if (event.currentTarget.classList.contains("holdings")) {
        callType = "holdings";
      }

      if (event.currentTarget.classList.contains("balances")) {
        callType = "balances";
      }

      if (event.currentTarget.classList.contains("performance")) {
        callType = "performance";
      }

      if (event.currentTarget.classList.contains("allocation")) {
        callType = "allocation";
      }

      if (event.currentTarget.classList.contains("ussector")) {
        callType = "us_sectors";
      }

      window.dashboardUtils?.eventBus.dispatch(
        "portfolio_" + callType + ".accounts.click"
      );
    }
  },

  OnCancelAccounts: function (event) {
    if (IS_EMPOWER) {
      let callType = "";
      if (event.currentTarget.classList.contains("holdings")) {
        callType = "holdings";
      }

      if (event.currentTarget.classList.contains("balances")) {
        callType = "balances";
      }

      if (event.currentTarget.classList.contains("performance")) {
        callType = "performance";
      }

      if (event.currentTarget.classList.contains("allocation")) {
        callType = "allocation";
      }

      if (event.currentTarget.classList.contains("ussector")) {
        callType = "us_sectors";
      }

      window.dashboardUtils?.eventBus.dispatch(
        "portfolio_" + callType + ".accounts.cancel.click"
      );
    }
  },

  OnDoneAccounts: function (event) {
    if (IS_EMPOWER) {
      let callType = "";
      if (event.currentTarget.classList.contains("holdings")) {
        callType = "holdings";
      }

      if (event.currentTarget.classList.contains("balances")) {
        callType = "balances";
      }

      if (event.currentTarget.classList.contains("performance")) {
        callType = "performance";
      }

      if (event.currentTarget.classList.contains("allocation")) {
        callType = "allocation";
      }

      if (event.currentTarget.classList.contains("ussector")) {
        callType = "us_sectors";
      }

      window.dashboardUtils?.eventBus.dispatch(
        "portfolio_" + callType + ".accounts.done.click"
      );
    }
  },
});

export default BaseAccountSelectorView;
