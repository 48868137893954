import $ from "jquery";
import _ from "underscore";
var REGEX_STARTS_WITH_HASH = /^#\//;
var REGEX_FUNDING_APP = /^\/page\/funding/;
var REGEX_ENROLLMENT = /^\/page\/enrollment/;

var IS_PRODUCTION_ENVIRONMENT =
  window.location.host.toLowerCase() === "home.personalcapital.com";

$.pcap = {};

$.pcap.stripDollarFormatting = function (stringInput) {
  var parsed = parseFloat(stringInput.replace(/\$|,/g, ""));
  if (isNaN(parsed)) {
    return 0;
  }
  return parsed;
};
$.pcap.formatDollars = function (
  number,
  wrap,
  dash,
  addComparator,
  showZeroAsDash,
  usePositiveSymbol = false
) {
  if (typeof number != "undefined" && !isNaN(number)) {
    number = parseFloat(number);
    var string = "";
    if (wrap !== false) {
      string = '<span class="dollar noChange">';
      if (number < 0) {
        string = '<span class="dollar negative">-';
        number = number * -1;
      } else if (number > 0) {
        const positiveSymbol = usePositiveSymbol ? "+" : "";
        string = '<span class="dollar positive">' + positiveSymbol;
      }
    }
    if (number > 0 && number < 1 && addComparator) {
      string = string + "&lt; $1";
    } else {
      if (number < 0 && wrap == false) {
        number = number * -1;
        string += "-";
      }
      string =
        string +
        "$" +
        Math.floor(number)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      if (showZeroAsDash && number === 0)
        string = (wrap !== false ? '<span class="dollar noChange">' : "") + "-";
    }

    if (wrap !== false) {
      string += "</span>";
    }
    return string;
  }
  return dash !== true ? "N/A" : "-";
};
$.pcap.formatDollarsAndCents = function (
  number,
  wrap,
  dash,
  addComparator,
  usePositiveSymbol = false
) {
  if (typeof number == "number" && !isNaN(number)) {
    number = parseFloat(number);
    var string = "";
    if (wrap !== false) {
      string = '<span class="dollar noChange">';
      if (number < 0) {
        string = '<span class="dollar negative">-';
        number = number * -1;
      } else if (number > 0) {
        const positiveSymbol = usePositiveSymbol ? "+" : "";
        string = '<span class="dollar positive">' + positiveSymbol;
      }
    }
    if (number > 0 && number < 1 && addComparator) {
      string = string + "&lt; $1";
    } else {
      if (number < 0 && wrap == false) {
        number = number * -1;
        string += "-";
      }
      string =
        string +
        "$" +
        number
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (wrap !== false) {
      string += "</span>";
    }
    return string;
  }
  return dash !== true ? "N/A" : "-";
};

/**
 *  85000 => $85,000
 * @deprecated use formatCurrency from `libs/pcap/utils/format.js`
 */
$.pcap.formatSimpleDollars = function (number, skipDollarSign) {
  number = parseFloat(number);
  if (isNaN(number)) return "--";
  return (
    (!skipDollarSign ? "$" : "") +
    number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};
$.pcap.formatLegacyPercentage = function (val, precision) {
  return (parseFloat(val) * 100).toFixed(precision) + "%";
};
$.pcap.percentageChange = function (context, options) {
  if (typeof context != "undefined") {
    var direction = "noChange";
    var positiveSign = "";
    if (context > 0) {
      direction = "positive";
      positiveSign = "+";
    } else if (context < 0) {
      direction = "negative";
    }
    return (
      '<span class="percentageChange marketMove ' +
      direction +
      '">' +
      positiveSign +
      context.toFixed(2) +
      "%</span>"
    );
  }
  return "N/A";
};

//get query string params
$.pcap.getQueryStringParams = function (queryString) {
  if (!queryString) return undefined;
  var result = {};
  try {
    var re = /([^&=]+)=([^&]*)/g,
      m;
    while ((m = re.exec(queryString))) {
      result[decodeURIComponent(m[1])] = decodeURIComponent(
        m[2].replace(/\+/g, " ")
      );
    }
  } catch (e) {
    return undefined;
  }
  return result;
};

/**
 * @deprecated Use `scripts/libs/pcap/utils/isEmpty`
 */
$.pcap.isObjectEmpty = function (object, propertyName, ignorableProperties) {
  var empty = true;
  ignorableProperties = ignorableProperties || [];
  if (object[propertyName]) {
    if (object[propertyName] !== "") {
      for (var key in object[propertyName]) {
        if (
          object[propertyName][key] &&
          ignorableProperties.indexOf(key) == -1
        ) {
          empty = false;
          break;
        }
      }
    }
  }
  return empty;
};

/*
 * Replaces all of the properties in the appliedObject with the properties
 * in the referenceObject.  Any properties that are not in the referenceObject
 * will be deleted in the appliedObject.
 */
$.pcap.replaceObject = function (referenceObject, appliedObject) {
  var prop;

  // copy properties from referenceObject to appliedObject
  for (prop in referenceObject) {
    appliedObject[prop] = referenceObject[prop];
  }

  // delete properties in appliedObject that does not exist in referenceObject
  for (prop in appliedObject) {
    if (_.isUndefined(referenceObject[prop])) {
      delete appliedObject[prop];
    }
  }
};
$.pcap.truncateString = function (val, length) {
  if (val && val.length > length) {
    return val.substring(0, length) + "...";
  }
  return val;
};
$.pcap.truncateText = function (text, maxLength) {
  var spaceIndex;
  for (var i = maxLength; i >= 0; i--) {
    if (text[i] == " ") {
      spaceIndex = i;
      break;
    }
  }
  return text.substr(0, spaceIndex);
};
/*
 * Allows only numeric values to be entered into
 * an input field.
 */
$.pcap.isNumberKey = function (event) {
  var charCode = event.which ? event.which : event.keyCode;
  if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

// http://jqueryvalidation.org/jQuery.validator.format/
$.pcap.format = function (source, params) {
  if (arguments.length === 1) {
    return function () {
      var args = $.makeArray(arguments);
      args.unshift(source);
      return $.pcap.format.apply(this, args);
    };
  }
  if (arguments.length > 2 && params.constructor !== Array) {
    params = $.makeArray(arguments).slice(1);
  }
  if (params.constructor !== Array) {
    params = [params];
  }
  $.each(params, function (i, n) {
    source = source.replace(new RegExp("\\{" + i + "\\}", "g"), function () {
      return n;
    });
  });
  return source;
};
$.pcap.round = function (num, dec) {
  dec = dec || 1; // Default to 1 decimal place
  dec = Math.pow(10, dec);
  return Math.round(num * dec) / dec;
};
$.pcap.setCookie = function (
  cookieName,
  cookieValue,
  expireDays,
  useTopLevelDomain
) {
  var date = new Date();
  date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + date.toUTCString();
  var domain = useTopLevelDomain
    ? "; path=/; domain=." +
      (IS_PRODUCTION_ENVIRONMENT ? "personalcapital.com" : "pcapcloud.com")
    : "";
  document.cookie = cookieName + "=" + cookieValue + "; " + expires + domain;
};
$.pcap.getCookie = function (cookieName) {
  var name = cookieName + "=",
    cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var c = cookieArr[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
};
/**
 * Usage: $.pcap.stripTags('<p>John</p> <br /><b>B</b> <i>Smith</i>', '<i><b>'); => 'John <b>B</b> <i>Smith</i>'
 */
$.pcap.stripTags = function (string, allowedTags) {
  // Making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
  allowedTags = (
    String(allowedTags || "")
      .toLowerCase()
      .match(/<[a-z][a-z0-9]*>/g) || []
  ).join("");
  var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
    comments = /<!--[\s\S]*?-->/gi;
  return string.replace(comments, "").replace(tags, function ($0, $1) {
    return allowedTags.indexOf("<" + $1.toLowerCase() + ">") > -1 ? $0 : "";
  });
};
$.pcap.ensureFooterIsAtBottom = function () {
  var footerContainer = $("#footerContainer"),
    minHeight =
      $(window).height() -
      $("#menuContainer").height() -
      footerContainer.height() -
      21;
  footerContainer.siblings(".moduleFrame").css("min-height", minHeight);
};
$.pcap.isTouchDevice = function () {
  return "ontouchstart" in window || window.navigator.msMaxTouchPoints;
};
/*
 * Truncate a given number to given decimal places
 * decimalPlaces is optional and will default to 2
 */
$.pcap.truncateNumber = function (number, decimalPlaces) {
  if (_.isUndefined(number)) {
    return;
  }
  if (_.isUndefined(decimalPlaces)) {
    decimalPlaces = 2;
  }

  // get the multiplication factor
  var power = Math.pow(10, decimalPlaces);

  // return truncated number
  return Math.floor(number * power) / power;
};

/*
 * utility function to determine if url is an internal web app url
 */
$.pcap.isWebAppUrl = function (url) {
  var isWebApp =
    REGEX_STARTS_WITH_HASH.test(url) ||
    REGEX_FUNDING_APP.test(url) ||
    REGEX_ENROLLMENT.test(url);
  return (url && (isWebApp || url.startsWith(window.baseUrl))) || false;
};
/*
 * utility function to capitalize only the first letter
 * eg: john snow -> John snow
 * eg: dragon Boat -> Dragon Boat
 */
$.pcap.capitalizeFirstLetter = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
/**
 * Utility function to capitalize the first letter of each word
 * eg: john snow -> John Snow
 */
$.pcap.capitalizeWords = function (str) {
  return str.replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
};
/*
 * Add "https://" to url string if it does not start with it
 */
$.pcap.ensureUrlHasHttps = function (url) {
  if (!url) {
    return url;
  }
  return (url || "").toLowerCase().indexOf("https://") === 0
    ? url
    : `https://${url}`;
};
/*
 * $.pcap.printf('{0} {1} {0} {2}', 0, 1, 2) -> 0 1 0 2
 */
$.pcap.printf = function (str) {
  var args = Array.prototype.slice.call(arguments, 1);
  return str.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

export default $.pcap;
