import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
import deepCopy from "deep-copy";

class BaseHoldingsEditRow extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: deepCopy(props.model),
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnRemove = this.handleOnRemove.bind(this);
    this.isNotExistingHolding = this.isNotExistingHolding.bind(this);
  }

  componentDidMount() {
    let descriptionInput = this.inputElements.find(
      (input) => input.props.name === "description"
    );
    if (descriptionInput) {
      descriptionInput.focus();
    }
  }

  handleSubmit(ev) {
    ev.preventDefault();
    if (this.validate().valid) {
      this.setState({ loading: true });
      this.props
        .onSubmit(this.state.model)
        .then((holding) => {
          if (this.props.onSaved) {
            this.props.onSaved(holding);
          }
        })
        .catch((errors) => {
          this.setState({ errors, loading: false });
        });
    }
  }

  handleOnRemove() {
    this.setState({ loading: true });
    if (this.props.onRemoveHolding) {
      this.props.onRemoveHolding(this.state.model).catch((errors) => {
        this.setState({ errors, loading: false });
      });
    }
  }

  isNotExistingHolding(v) {
    // Empty field
    if (!v) {
      return true;
    }

    const { model } = this.state;

    // -> holding ticker must not be in the list, and if found it should not be the one being edited
    const foundHoldingWithSameTicker = this.props.holdings.find(
      (h) => h.ticker === v
    );

    // If is the same holding that we're currently editing do not validate
    if (foundHoldingWithSameTicker) {
      return foundHoldingWithSameTicker.sourceAssetId === model.sourceAssetId;
    }

    return true;
  }

  render() {
    return "NOOP"; /* NOOP */
  }
}

BaseHoldingsEditRow.defaultProps = {
  holdings: [],
  schema: {
    type: "object",
    properties: {
      ticker: {
        required: true,
        type: "string",
        allowEmpty: false,
        messages: {
          required: "Ticker is required.",
          conform: "This security already exists for this account.",
          allowEmpty: "Please enter a ticker",
        },
      },
      quantity: {
        required: true,
        type: "number",
        allowEmpty: false,
        minimum: 0,
        messages: {
          required: "Please enter quantity.",
          type: "Must be a numeric value.",
          allowEmpty: "Please enter a quantity",
          minimum: "Please enter a quantity greater than 0.",
        },
      },
      price: {
        required: true,
        type: "number",
        allowEmpty: false,
        minimum: 0,
        messages: {
          required: "Please enter price.",
          type: "Must be a numeric value.",
          allowEmpty: "Please enter a price",
          minimum: "Please enter a price greater than 0.",
        },
      },
      description: {
        required: false,
        type: "string",
      },
      costBasis: {
        required: false,
        allowEmpty: true,
        type: "number",
        minimum: 0,
        messages: {
          type: "Must be a numeric value.",
          minimum: "Please enter a cost basis greater than 0.",
        },
      },
    },
  },
};

BaseHoldingsEditRow.propTypes = {
  model: PropTypes.object.isRequired,
  holdings: PropTypes.array,
  columns: PropTypes.array.isRequired,
  errors: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onRemoveHolding: PropTypes.func,
  onSaved: PropTypes.func,
};

export default BaseHoldingsEditRow;
