import React from "react";
export default function BeneficiaryIcon() {
  return (
    <svg
      width="110px"
      height="110px"
      viewBox="0 0 110 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon_beneficiary</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Group3---Round-2-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="No-beneficiary-on-file-–-change-online"
          transform="translate(-75.000000, -62.000000)"
        >
          <g
            id="marketing/security/link_01-copy-78"
            transform="translate(75.000000, 62.000000)"
          >
            <g filter="url(#filter-1)" id="circle">
              <g transform="translate(0.000000, -0.000611)">
                <path
                  d="M110,54.9992369 C110,85.3747971 85.3759816,110 55,110 C24.6255445,110 0,85.3747971 0,54.9992369 C0,24.6252029 24.6255445,0 55,0 C85.3759816,0 110,24.6252029 110,54.9992369"
                  id="Fill-1"
                  fill="#F3F6F7"
                ></path>
              </g>
            </g>
            <g
              transform="translate(20.638808, 27.575167)"
              strokeLinecap="round"
              strokeWidth="1.5"
            >
              <path
                d="M18.2477267,37.8062361 C17.3419434,37.4945694 13.9922543,36.6481806 10.9113482,36.6481806 C7.83354943,36.6481806 4.48230665,37.4945694 3.57652336,37.8062361 C2.67074008,38.1179028 0,39.3645694 0,42.3024861 L0,54.4681806"
                stroke="#002157"
              ></path>
              <line
                x1="4.88983144"
                y1="54.4684861"
                x2="4.88983144"
                y2="45.3751528"
                stroke="#002157"
              ></line>
              <path
                d="M19.2551193,25.3229167 C19.2551193,29.8543056 15.5201227,33.5270833 10.9119697,33.5270833 C6.30381663,33.5270833 2.56882004,29.8543056 2.56882004,25.3229167 C2.56882004,20.7915278 6.30381663,17.11875 10.9119697,17.11875 C15.5201227,17.11875 19.2551193,20.7915278 19.2551193,25.3229167 Z"
                stroke="#002157"
              ></path>
              <path
                d="M52.3827058,31.257875 C50.9580004,30.7689861 45.6833275,29.4367639 40.8374647,29.4367639 C35.9931554,29.4367639 30.7200363,30.7689861 29.2953308,31.257875 C27.8706254,31.7482917 23.6664237,33.7099583 23.6664237,38.3360694 L23.6664237,54.5809306"
                stroke="#002157"
              ></path>
              <line
                x1="31.3612314"
                y1="54.5804722"
                x2="31.3612314"
                y2="43.1695"
                stroke="#002157"
              ></line>
              <path
                d="M53.968215,12.9097222 C53.968215,20.0413889 48.0907224,25.8209722 40.8382415,25.8209722 C33.5873142,25.8209722 27.7098217,20.0413889 27.7098217,12.9097222 C27.7098217,5.77958333 33.5873142,0 40.8382415,0 C48.0907224,0 53.968215,5.77958333 53.968215,12.9097222 Z"
                stroke="#002157"
              ></path>
              <path
                d="M72.7121804,43.5621389 C72.7121804,49.7236667 67.631715,54.7210278 61.364254,54.7210278 C55.0983466,54.7210278 50.0178812,49.7236667 50.0178812,43.5621389 C50.0178812,37.3990833 55.0983466,32.40325 61.364254,32.40325 C67.631715,32.40325 72.7121804,37.3990833 72.7121804,43.5621389 Z"
                stroke="#002157"
              ></path>
              <polyline
                stroke="#DB1830"
                points="55.3747426 44.9676944 59.8523883 48.9047778 66.5067104 38.3081111"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
