import PropTypes from "prop-types";
import React from "react";
import FactPage from "./FactPage";
import HowDoesItWorkPage from "./HowDoesItWorkPage";
import FormPage from "./FormPage";
import CongratsPage from "./CongratsPage";

const PAGE_1 = 1;
const PAGE_2 = 2;
const PAGE_3 = 3;
const PAGE_4 = 4;
const LAST_PAGE = PAGE_4;

export default class FinancialPlanOffer extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      page: PAGE_1,
    };
    this.onContinueClick = this.onContinueClick.bind(this);
  }

  static getDerivedStateFromProps(props) {
    if (!props || !props.pageNumber) {
      return {};
    }
    return { page: props.pageNumber };
  }

  onContinueClick(lastPageNumber) {
    if (lastPageNumber < LAST_PAGE) {
      this.setState({ page: lastPageNumber + 1 });
    }
  }

  render() {
    const { componentName } = this.props;
    const { page } = this.state;
    const sharedProps = {
      componentName,
      onContinueClick: this.onContinueClick,
    };
    return (
      <div className="fin-plan-offer">
        {page === PAGE_1 && <FactPage {...sharedProps} />}
        {page === PAGE_2 && <HowDoesItWorkPage {...sharedProps} />}
        {page === PAGE_3 && <FormPage {...sharedProps} />}
        {page === PAGE_4 && <CongratsPage {...sharedProps} />}
      </div>
    );
  }
}

FinancialPlanOffer.propTypes = {
  componentName: PropTypes.string,
};
