import PropTypes from "prop-types";
import React from "react";
import Message from "components/common/Message";
import getCategoriesByType from "./CategoryUtils";
import { noop } from "underscore";

export default class CategoriesManager extends React.Component {
  constructor(props) {
    super(props);
  }

  getCategoryListElement(category, i) {
    if (category.isEditable) {
      return (
        <li
          className="categories-manager__list-element"
          key={i}
          data-category-id={category.transactionCategoryId}
          onClick={this.props.onShowEditCategory}
        >
          <div className="categories-manager__element-name">
            {category.name}
          </div>
          <svg
            className="categories-manager__list-element-edit"
            viewBox="0 0 9 9"
          >
            <use xlinkHref="#icon-pencil" />
          </svg>
        </li>
      );
    }
    return (
      <li
        className="categories-manager__list-element"
        key={i}
        data-category-id={category.transactionCategoryId}
      >
        <div className="categories-manager__element-name">{category.name}</div>
      </li>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      categoriesByType: getCategoriesByType(nextProps.categories),
    });
  }

  UNSAFE_componentWillMount() {
    this.setState({
      categoriesByType: getCategoriesByType(this.props.categories),
    });
  }

  render() {
    return (
      <div>
        {this.props.message && (
          <Message
            severity={this.props.message.severity}
            messages={this.props.message.messages}
          />
        )}
        <div className="pc-layout--right pc-u-mb- pc-u-mt--">
          <button
            className="pc-btn pc-btn--primary"
            onClick={this.props.onShowAddCategory}
          >
            Create category
          </button>
        </div>
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/3">
            <h3 className="heading--emphasized">Income</h3>
            <ul className="pc-list-bare categories-manager__categories-list qa-income-categories-list">
              {this.state.categoriesByType.income.map(
                this.getCategoryListElement.bind(this)
              )}
            </ul>
          </div>
          <div className="pc-layout__item pc-u-1/3">
            <h3 className="heading--emphasized">Expense</h3>
            <ul className="pc-list-bare categories-manager__categories-list qa-expense-categories-list">
              {this.state.categoriesByType.expense.map(
                this.getCategoryListElement.bind(this)
              )}
            </ul>
          </div>
          <div className="pc-layout__item pc-u-1/3">
            <h3 className="heading--emphasized">Other</h3>
            <ul className="pc-list-bare categories-manager__categories-list qa-others-categories-list">
              {this.state.categoriesByType.other.map(
                this.getCategoryListElement.bind(this)
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

CategoriesManager.propTypes = {
  categories: PropTypes.array,
  message: PropTypes.object,
  onShowEditCategory: PropTypes.func,
  onShowAddCategory: PropTypes.func,
};
CategoriesManager.defaultProps = {
  categories: [],
  message: undefined,
  onShowAddCategory: noop,
  onShowEditCategory: noop,
};
