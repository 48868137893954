import PropTypes from "prop-types";
import React from "react";

const imgMap = (imageType) => {
  const map = {
    LINK_ACCOUNTS: `${
      window.staticUrl || ""
    }/static/img/completenessMeter/link.svg`,
    LINK_ACCOUNTS_ACTIVE: `${
      window.staticUrl || ""
    }/static/img/completenessMeter/link--active.svg`,
    BE_INFORMED: `${
      window.staticUrl || ""
    }/static/img/completenessMeter/be-informed.svg`,
    BE_INFORMED_ACTIVE: `${
      window.staticUrl || ""
    }/static/img/completenessMeter/be-informed--active.svg`,
    COMPLETED: `${
      window.staticUrl || ""
    }/static/img/completenessMeter/checkmark--active.svg`,
  };

  return map[imageType];
};

export default class ProgressMeter extends React.Component {
  generateImage(step) {
    if (step.status === "COMPLETED" || this.props.isCompleted) {
      return imgMap("COMPLETED");
    }
    const key =
      this.props.activeIndex === step.key ? step.key + "_ACTIVE" : step.key;
    return imgMap(key) || imgMap("LINK_ACCOUNTS");
  }

  generateSteps(steps) {
    return steps.map((step, i) => {
      return (
        <div
          key={step.key}
          className={`progress-meter-step${
            step.status === "COMPLETED" ? " progress-meter-step--complete" : ""
          }`}
        >
          <img
            src={this.generateImage(step, i)}
            alt=""
            className="progress-meter-step__icon"
          />
          <div className="progress-meter-step__title">{step.title}</div>
        </div>
      );
    });
  }

  render() {
    const data = this.props.data;
    const { meterTitle, scoreTitle, scorePercentage, steps } = data;

    return (
      <div className="js-progress-meter progress-meter-container">
        <div className="progress-meter-top">
          <div className="js-progress-meter-title progress-meter__title">
            {meterTitle}
          </div>
          <div className="js-progress-meter-score-title progress-meter__score-title">
            {scoreTitle}
          </div>
        </div>
        <div className="progress-meter-bar__filler-container">
          <div
            className="js-progress-bar-filled progress-meter-bar__filler"
            style={{ width: scorePercentage + "%" }}
          />
        </div>
        <div className="js-progress-step-container progress-meter-step-container">
          {this.generateSteps(steps)}
        </div>
      </div>
    );
  }
}

ProgressMeter.propTypes = {
  data: PropTypes.object,
  activeIndex: PropTypes.string,
  isCompleted: PropTypes.bool,
};

ProgressMeter.defaultProps = {
  data: undefined,
  activeIndex: undefined,
  isCompleted: false,
};
