import _ from "underscore";
import React from "react";
import ReactDOM from "react-dom";
import Backbone from "backbone";
import moment from "moment";
import DateUtils from "libs/pcap/utils/date";
import StockOptionsContainer from "components/stockOptions/Container";

export default Backbone.View.extend({
  el: "#stockOptions",

  initialize: function (options) {
    this.options = options;
    this.nowString = moment().format(DateUtils.DISPLAY_FORMAT);

    // Use url path to set drilldown state
    if (!_.isUndefined(this.options.path) && this.options.path !== "") {
      this.pathNodes = this.options.path.split("/");
    }
    this.render();
  },

  renderReactComponent: function (options) {
    ReactDOM.render(
      React.createElement(StockOptionsContainer, options),
      this.parentContainer
    );
    window.dispatchEvent(new CustomEvent("pageloaded"));
    window.scrollTo(0, 0);
  },

  updateView: function (newViewState) {
    this.renderReactComponent(newViewState);
  },

  render: function () {
    this.parentContainer = document.createElement("div");
    this.el.appendChild(this.parentContainer);
    this.renderReactComponent(this.options);
  },

  remove: function () {
    ReactDOM.unmountComponentAtNode(this.parentContainer);
    Backbone.View.prototype.remove.apply(this, arguments);
  },
});
