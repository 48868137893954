import React from "react";
import PropTypes from "prop-types";
import ChartReact from "libs/pcap/chart/chart--react";
import { scaleLinear, select } from "d3";

const ONE_HUNDRED_PERCENT = 100;
const ONE_SECOND = 1000;

// Don't show "Age" label if calculation less than minimum (i.e. retirement is in near future). If retired, only show age.
const MIN_LABEL_WIDTH = 20;
const MAX_LABEL_WIDTH = 75;

export default class InterviewChart extends React.Component {
  render() {
    const { age, retirementAge, lifeExpectancy } = this.props;
    const labelWidth = Math.max(
      (ONE_HUNDRED_PERCENT * (retirementAge - age)) / (lifeExpectancy - age),
      0
    );
    return (
      <div>
        <div className="pc-u-ph-">
          <ChartReact
            data={[this.props.timeSeries]}
            xScale={scaleLinear()}
            className="js-retirement-planner-interview-chart retirement-planner-interview__chart"
            type="area"
            showXGrid={false}
            showYGrid={false}
            showXAxis={false}
            showYAxis={false}
            includeYZero={true}
            showYZeroLine={true}
          />
        </div>
        <div className="pc-u-ph- retirement-planner-interview__chart__label-container">
          {(labelWidth > MIN_LABEL_WIDTH || age > retirementAge) && (
            <span
              className="retirement-planner-interview__chart__label"
              style={{
                width:
                  Math.min(
                    Math.max(labelWidth, MIN_LABEL_WIDTH),
                    MAX_LABEL_WIDTH
                  ) + "%",
              }}
            >{`Age ${age}`}</span>
          )}
          {age <= retirementAge && (
            <span className="retirement-planner-interview__chart__label">{`Retire @ ${retirementAge}`}</span>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    select(".js-retirement-planner-interview-chart")
      .append("rect")
      .classed(
        "js-interview-chart-overlay retirement-planner-interview__chart--not-retired",
        true
      )
      .lower();
    this.updateOverlay();
  }

  componentDidUpdate() {
    this.updateOverlay();
  }

  updateOverlay() {
    const { age, retirementAge, lifeExpectancy } = this.props;
    if (!age || !retirementAge || !lifeExpectancy) {
      return;
    }

    const w = select(
      ".js-retirement-planner-interview-chart .js-chart-series-group"
    )
      .node()
      .getBoundingClientRect().width;
    const x = scaleLinear().domain([age, lifeExpectancy]).range([0, w]);

    select(".js-interview-chart-overlay")
      .transition()
      .duration(ONE_SECOND)
      .attr("x", x(age))
      .attr("width", Math.max(x(retirementAge) - x(age), 0))
      .attr("height", "100%");
  }
}

InterviewChart.propTypes = {
  age: PropTypes.number,
  retirementAge: PropTypes.number,
  lifeExpectancy: PropTypes.number,
  timeSeries: PropTypes.array,
};
