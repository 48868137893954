/* eslint-disable camelcase */
import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import { promisify } from "utils/service";
import * as sidebarConstants from "components/sidebar/constants";
import EditPlanModal from "components/financialPlanner/EditPlanModal";
import eventBus from "eventBus";
import moment from "moment";
import {
  SET_MASTER_PLAN_VIEW,
  EDIT_PLAN_VIEW,
} from "components/financialPlanner/financialPlannerConstants";
import {
  hasUnenrolledMtrEligibleAccounts,
  hasEnrolledAllMtrEligibleAccounts,
  hasMtrEligibleAccounts,
} from "utils/account";
import RetirementPlannerEvents from "./RetirementPlannerEvents";

const REQUEST_SOURCE = "USER";

export default class EditPlanModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
    };

    this.handleSave = this.handleSave.bind(this);
    this.makePlanMaster = this.makePlanMaster.bind(this);
    this.renamePlan = this.renamePlan.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleViewChanged = this.handleViewChanged.bind(this);
    this.handleAPIErrors = this.handleAPIErrors.bind(this);
  }

  renamePlan(planName) {
    this.handleSave(planName, this.props.plan.isMasterPlan, () => {
      eventBus.trigger(
        "editplan:planrenamed",
        this.props.plan.planId,
        planName
      );
      this.setState({ loading: false });
      this.props.onClosed();
    });
  }

  makePlanMaster() {
    this.handleSave(this.props.plan.planName, true, async () => {
      eventBus.trigger("planner:viewselected", "index");
      eventBus.trigger("planlist:planmademaster", this.props.plan.planId);
      eventBus.trigger(
        "planlist:updated",
        sidebarConstants.PLAN_LIST_SELECTION_TYPE_BY_ID,
        this.props.plan.planId
      );

      let routeAfterSave = "managedAccounts#/managed-accounts";
      const accounts = this.props.accounts;
      const whatIf = this.props.plan.result.whatIf;
      const alternativeStrategy = whatIf?.alternativeStrategy;
      const alternativeStrategyScope = whatIf?.alternativeStrategyScope;
      const accountsToAdviceByMa = whatIf?.accountsToAdviceByMa;
      const hasEnrolledAllAccounts =
        hasEnrolledAllMtrEligibleAccounts(accounts);
      const hasSomeUnenrolledAccounts =
        hasUnenrolledMtrEligibleAccounts(accounts);

      const shouldFollowMtrSaveStrategyFlow =
        IS_EMPOWER &&
        window.isAdvisorApp &&
        Boolean(this.props.isGMWB) === false &&
        hasMtrEligibleAccounts(accounts);

      if (!shouldFollowMtrSaveStrategyFlow) {
        this.props.onClosed();
        return this.setState({ loading: false });
      }

      if (hasEnrolledAllAccounts && !alternativeStrategy) {
        this.props.onClosed();
        return this.setState({ loading: false });
      }

      if (
        hasEnrolledAllAccounts &&
        alternativeStrategy &&
        alternativeStrategyScope !== "ALL"
      ) {
        const newPath = `/home/implement-advice?alternativeStrategy=${alternativeStrategy}`;
        return window.location.assign(routeAfterSave + newPath);
      }

      if (
        hasSomeUnenrolledAccounts &&
        alternativeStrategy &&
        alternativeStrategyScope !== "ALL"
      ) {
        routeAfterSave += accountsToAdviceByMa
          ? `/get-started?strategyChanged=true&alternativeStrategy=${alternativeStrategy}&accountsToAdviceByMa=${accountsToAdviceByMa}`
          : `/get-started?strategyChanged=true&alternativeStrategy=${alternativeStrategy}`;

        return window.location.assign(routeAfterSave);
      }

      if (hasSomeUnenrolledAccounts && !alternativeStrategy) {
        routeAfterSave += `/get-started`;
        return window.location.assign(routeAfterSave);
      }
    });
  }

  handleSave(planName, makeMaster, callback) {
    this.setState({ loading: true });
    const planMetadata = {
      planName,
      planDate: moment().toDate(),
      isWhatIf: !makeMaster,
      status: this.props.plan.planStatus,
    };

    const payload = {
      input: JSON.stringify(this.props.plan.result.whatIf),
      plan: JSON.stringify(planMetadata),
      myLifePlanId: this.props.plan.planId,
      requestSource: REQUEST_SOURCE,
    };

    this.props
      .savePlan(payload)
      .then(() => {
        callback();
      })
      .catch(this.handleAPIErrors);

    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        RetirementPlannerEvents.COMPARE_SCENARIOS_MAKE_THIS_OFFICIAL_SAVE_PLAN
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
          "select_button",
        event_properties: {
          selection:
            RetirementPlannerEvents.COMPARE_SCENARIOS_MAKE_THIS_OFFICIAL_SAVE_PLAN,
        },
      });
    }
  }

  handleDelete() {
    this.setState({ loading: true });
    this.props
      .deletePlan({ myLifePlanId: this.props.plan.planId })
      .then(() => {
        eventBus.trigger(
          "planlist:updated",
          sidebarConstants.PLAN_LIST_SELECTION_TYPE_MASTER,
          null
        );
      })
      .catch(this.handleAPIErrors);
  }

  handleAPIErrors(errors) {
    this.setState({
      errors: errors,
      loading: false,
    });
  }

  fetchPlanComparison() {
    this.setState({ loading: true });
    this.props
      .fetchComparisons({ myLifePlanId: this.props.plan.planId })
      .then((response) => {
        this.setState({ loading: false, planComparison: response || null });
      })
      .catch(this.handleAPIErrors);
  }

  // viewType can be set from either parent or child:
  // From parent: viewType can be EDIT_PLAN_VIEW or SET_MASTER_PLAN_VIEW depending
  // on where it is launched from.
  // From child: If the user clicks "Make This My Official Plan", viewType will be
  // SET_MASTER_PLAN_VIEW and it will be bubbled up in order to make needed service calls.
  handleViewChanged(viewType) {
    if (this.props.onViewChange) {
      this.props.onViewChange(viewType);
    }

    if (viewType === SET_MASTER_PLAN_VIEW) {
      this.fetchPlanComparison();
    }
  }

  componentDidMount() {
    if (this.props.viewType === SET_MASTER_PLAN_VIEW) {
      this.fetchPlanComparison();
    }
  }

  render() {
    return (
      <EditPlanModal
        componentName="EditPlanDialog"
        errors={this.state.errors}
        isOpen={this.props.isOpen}
        plan={this.props.plan}
        renamePlan={this.renamePlan}
        makePlanMaster={this.makePlanMaster}
        onDelete={this.handleDelete}
        onViewChange={this.handleViewChanged}
        planComparison={this.state.planComparison}
        loading={this.state.loading}
        onClosed={this.props.onClosed}
        viewType={this.props.viewType}
        isGMWB={this.props.isGMWB}
        maModelPortfolios={this.props.maModelPortfolios}
        accounts={this.props.accounts}
      />
    );
  }
}

EditPlanModalContainer.propTypes = {
  fetchComparisons: PropTypes.func,
  savePlan: PropTypes.func,
  deletePlan: PropTypes.func,
  viewType: PropTypes.string,
  onClosed: PropTypes.func.isRequired,
  onViewChange: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  plan: PropTypes.object.isRequired,
  isGMWB: PropTypes.bool,
  maModelPortfolios: PropTypes.array,
  accounts: PropTypes.array,
};

EditPlanModalContainer.defaultProps = {
  fetchComparisons: promisify(Services.myLife.compareInputsWithMaster),
  savePlan: promisify(Services.myLife.savePlan),
  deletePlan: promisify(Services.myLife.deletePlan),
  viewType: EDIT_PLAN_VIEW,
  onViewChange: undefined,
  isGMWB: undefined,
  maModelPortfolios: [],
  accounts: [],
};
