import React from "react";
import Wizard from "common/Wizard";
import PropTypes from "prop-types";
import TransferDetails from "../TransferDetails";
import deepCopy from "deep-copy";
import { noop, isEmpty } from "underscore";
import AccountDetailsContainer from "components/transferFunds/AccountDetailsContainer";
import VerifyTransferContainer from "../VerifyTransferContainer";
import UploadStatements from "../UploadStatements";
import TransferReview from "../TransferReview";
import TransferConfirmation from "../TransferConfirmation";
import AccountDetail from "../AccountDetail";
import memoizeOne from "memoize-one";
import {
  isAcatTransfer,
  findAccountByUserAccountId,
  isOnUs,
} from "utils/account";
// Use human-readable names as this serves as a title for the modal.
import {
  STEP_TRANSFER_FUNDS,
  STEP_ACCOUNT_DETAILS,
  STEP_VERIFY_TRANSFER,
  STEP_UPLOAD_STATEMENT,
  STEP_REVIEW,
  STEP_CONFIRMATION,
} from "./constants";
import { ModalContext } from "components/modal/ModalContext";
import { EditRecurringTransfersContext } from "components/transferFunds/EditRecurringTransfers/EditRecurringTransfersContext";
import TradingBlackoutModal from "../../common/modals/TradingBlackout/TradingBlackoutModal";
import { DELIVERY_METHOD_WIRE_TRANSFER } from "../../../../components/transferFunds/utils/constants";

// storing two memoized functions for efficiency
const getSourceAccount = memoizeOne(findAccountByUserAccountId);
const getTargetAccount = memoizeOne(findAccountByUserAccountId);

const DOCUSIGN_SUCCESS_MSG =
  "Your request has been received and will be processed shortly. The estimated completion timeframe is 2-5 business days. If you have any questions regarding this transfer please contact your advisor or our support team.";
const DOCUSIGN_SUCCESS_MSG_EPIA =
  "You’ve successfully completed your deposit request. Typically within 5-7 days, your account will be funded via asset transfer. When your assets are received, they will be liquidated and then reinvested based on the account investment elections you made today.";
const DOCUSIGN_WARNING_MSG =
  "Your request cannot be completed at this time, one of our advisors will contact you shortly.";
const BLACKOUT_MODAL_MSG =
  "Withdrawals are not accepted between 3:50-4:00 pm ET (or 10 minutes before the ";

function buildPages(props, pages) {
  const { sourceAccountId, targetAccountId } = props.model;
  const sourceAccount = getSourceAccount(sourceAccountId, props.sourceAccounts);
  const targetAccount = getTargetAccount(targetAccountId, props.targetAccounts);
  const statementUploadStepIndex = pages.indexOf(STEP_UPLOAD_STATEMENT);

  if (
    isAcatTransfer(sourceAccount, targetAccount) &&
    !isOnUs(sourceAccount) &&
    isOnUs(targetAccount)
  ) {
    if (statementUploadStepIndex === -1) {
      const reviewStepIndex = pages.indexOf(STEP_REVIEW);
      const newPages = pages.slice();
      newPages.splice(reviewStepIndex, 0, STEP_UPLOAD_STATEMENT);
      return newPages;
    }
  } else if (statementUploadStepIndex !== -1) {
    const newPages = pages.slice();
    newPages.splice(statementUploadStepIndex, 1);
    return newPages;
  }
}

export default class TransferFundsWizard extends Wizard {
  constructor(props) {
    super(props);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleTransferFund = this.handleTransferFund.bind(this);
    this.handleAccountDetailsComplete =
      this.handleAccountDetailsComplete.bind(this);
    this.handleUploadStatementComplete =
      this.handleUploadStatementComplete.bind(this);
    this.handleVerifyTransferComplete =
      this.handleVerifyTransferComplete.bind(this);
  }

  handleContinue(model) {
    this.props.onNextPage(model, () => {
      // navigate to the next page after the container notifies about a completed API call
      this.handleNext();
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { sourceAccountId, targetAccountId } = props.model;
    const newState = { ...super.getDerivedStateFromProps(props, state) };
    if (sourceAccountId !== state.prevPropsSourceAccountId) {
      newState.prevPropsSourceAccountId = sourceAccountId;
    }

    if (targetAccountId !== state.prevPropsTargetAccountId) {
      newState.prevPropsTargetAccountId = targetAccountId;
    }

    if (
      sourceAccountId !== state.prevPropsSourceAccountId ||
      targetAccountId !== state.prevPropsTargetAccountId
    ) {
      // `state.pages` will not be initialized during the initial call.
      // Falling back to `newState.pages` as that will be available after
      // calling `super.getDerivedStateFromProps()`.
      const newPages = props.pageBuilder?.(
        props,
        state.pages ?? newState.pages
      );
      if (newPages) {
        newState.pages = newPages;
      }
    }

    if (!isEmpty(newState)) {
      return newState;
    }

    return null;
  }

  componentDidMount() {
    this.props.onChildMounted(this.getCurrentPageName());
  }

  componentDidUpdate(_, prevState) {
    const { enableForm, onChildMounted } = this.props;
    if (this.isReviewPage() && enableForm) {
      this.setState({
        pageIndex: this.getPageIndexByName(STEP_TRANSFER_FUNDS),
      });
    }

    if (
      prevState.pageIndex !== this.state.pageIndex &&
      this.getCurrentPageName() !== STEP_ACCOUNT_DETAILS
    ) {
      onChildMounted(this.getCurrentPageName());
    }
  }

  handleTransferFund(model) {
    let optionalAdditionalPayloadObject = {
      context: this.context ? "EDIT_RECURRING" : "NEW_TRANSFER",
    };
    window.dashboardUtils?.eventBus.dispatch(
      "review_your_request.submit_button.click",
      optionalAdditionalPayloadObject
    );
    this.props.onTransferFund(model, () => {
      this.handleNext();
    });
  }

  isConfirmationPage() {
    return this.getCurrentPageName() === STEP_CONFIRMATION;
  }

  isReviewPage() {
    return this.getCurrentPageName() === STEP_REVIEW;
  }

  trackClickBack() {
    const currentPage = this.getCurrentPageName();
    switch (currentPage) {
      case STEP_ACCOUNT_DETAILS:
        window.dashboardUtils?.eventBus.dispatch(
          "account_details.back_button.click"
        );
        window.dashboardUtils?.eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
          event_properties: {
            selection: "account_details.back_button.click",
          },
        });
        break;
      case STEP_UPLOAD_STATEMENT:
        window.dashboardUtils?.eventBus.dispatch(
          "upload_statements.back_button.click"
        );
        window.dashboardUtils?.eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
          event_properties: {
            selection: "upload_statements.back_button.click",
          },
        });
        break;
      case STEP_REVIEW: {
        let optionalAdditionalPayloadObject = {
          context: this.context ? "EDIT_RECURRING" : "NEW_TRANSFER",
        };
        window.dashboardUtils?.eventBus.dispatch(
          "review_your_request.cancel_button.click",
          optionalAdditionalPayloadObject
        );
        break;
      }
      default:
        break;
    }
  }

  handleBack(callback) {
    this.trackClickBack();
    this.setState({ prevPageIndex: this.state.pageIndex });
    window.scrollTo(0, 0);
    super.handleBack(callback);
  }

  handleNext(callback) {
    this.setState({ prevPageIndex: this.state.pageIndex });
    window.scrollTo(0, 0);
    super.handleNext(callback);
  }

  /**
   * `handleAccountDetailsComplete` can be called immediately upon the load of
   * `AccountDetailsContainer` in case there are no prompts to display on the step.
   *
   * This handler determines whether the user is going forward or backward in the flow
   * and calls the right handler to proceed with the flow in the correct direction.
   *
   * @param {Boolean} skip a boolean flag indicating whether it is being called as a result of
   * no prompts being available on the page (`true`) or the user pressing Continue button (`false`).
   */
  handleAccountDetailsComplete(skip) {
    if (skip) {
      const { pageIndex, prevPageIndex = 0 } = this.state;
      if (pageIndex > prevPageIndex) {
        this.handleContinue();
      } else {
        this.handleBack();
      }
    } else {
      this.handleContinue();
    }
  }

  handleUploadStatementComplete(statements) {
    this.handleContinue({ statements });
    window.dashboardUtils?.eventBus.dispatch(
      "upload_statements.continue_button.click"
    );
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type:
        window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
      event_properties: {
        selection: "upload_statements.continue_button.click",
      },
    });
  }

  handleVerifyTransferComplete(skip) {
    if (skip) {
      const { pageIndex, prevPageIndex = 1 } = this.state;
      if (pageIndex > prevPageIndex) {
        this.handleContinue();
      } else {
        this.handleBack();
      }
    } else {
      this.handleContinue();
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getPage() {
    const {
      personFullName,
      isContributionYearDisabled,
      onTargetAccountChange,
      errors,
      model,
      onBack,
      onMakeAnotherTransfer,
      onBackToHome,
      onClose,
      sourceAccounts,
      targetAccounts,
      contributionYears,
      onSourceAccountChange,
      docusignResult,
      stateType,
      transferType,
      onChildMounted,
      onLinkAccount,
      targetAccountOwnerAge,
      hasRecurringTransferEstablished,
      isPreviousYearContribution,
      onModelChange,
      isTaxWithholdingEnabled,
      isPSTaxWithholdingEnabled,
      hasOneTimeFrequency,
      isToDropDownDisabled,
      isFromDropDownDisabled,
      shouldShowTradingBlackoutWarningMsg,
      isTradingSuspended,
      styleGuideData,
    } = this.props;
    let pageName =
      docusignResult === undefined
        ? this.getCurrentPageName()
        : STEP_CONFIRMATION;
    const sourceAccount = getSourceAccount(
      model.sourceAccountId,
      sourceAccounts
    );
    const targetAccount = getTargetAccount(
      model.targetAccountId,
      targetAccounts
    );

    const isYodleeAllowed =
      sourceAccount?.isStatementDownloadEligible && !sourceAccount?.isManual;

    let page, successMsg, warningMsg, pershingMessages;

    switch (pageName) {
      case STEP_ACCOUNT_DETAILS:
        page = (
          <AccountDetailsContainer
            className="transfer-funds-container-component__content"
            sourceAccountId={model.sourceAccountId}
            targetAccountId={model.targetAccountId}
            accounts={sourceAccounts.concat(targetAccounts)}
            onComplete={this.handleAccountDetailsComplete}
            onBack={this.handleBack}
            onMounted={onChildMounted}
            transferType={transferType}
            CustomView={AccountDetail}
          />
        );
        break;
      case STEP_VERIFY_TRANSFER: {
        page = (
          <VerifyTransferContainer
            sourceAccountId={model.sourceAccountId}
            targetAccountId={model.targetAccountId}
            className="transfer-funds-container-component__content"
            onComplete={this.handleVerifyTransferComplete}
            onBack={this.handleBack}
            onMounted={onChildMounted}
            onBackToHome={onBackToHome}
            shouldShowTradingBlackoutWarningMsg={
              shouldShowTradingBlackoutWarningMsg
            }
          />
        );
        break;
      }
      case STEP_UPLOAD_STATEMENT: {
        // This step is displayed for ACAT (Investment -> Investment) transfers. `isOnUs` check is sufficient.
        const externalAccount = sourceAccount.isOnUs
          ? targetAccount
          : sourceAccount;
        page = (
          <UploadStatements
            statements={model.statements}
            userAccountId={externalAccount.userAccountId}
            accountName={externalAccount.name}
            firmName={externalAccount.firmName}
            logoPath={externalAccount.logoPath}
            balance={externalAccount.balance}
            onContinue={this.handleUploadStatementComplete}
            className="transfer-funds-container-component__content"
            onBack={this.handleBack}
            onMounted={onChildMounted}
            transferType={transferType}
            isYodleeAllowed={isYodleeAllowed}
          />
        );
        break;
      }
      case STEP_REVIEW:
        page = (
          <ModalContext.Consumer>
            {(inModal) => (
              <TransferReview
                personFullName={personFullName}
                errors={errors}
                model={deepCopy(model)}
                sourceAccount={sourceAccount}
                targetAccount={targetAccount}
                onCancel={this.handleBack}
                onSubmit={this.handleTransferFund}
                showTitle={!inModal}
                shouldShowTradingBlackoutWarningMsg={
                  shouldShowTradingBlackoutWarningMsg
                }
                isTradingSuspended={isTradingSuspended}
              />
            )}
          </ModalContext.Consumer>
        );
        break;
      case STEP_CONFIRMATION:
        // Confirmation message needed to be changed based on the boolean values of docusignResult, thats why explicit validation is made here.
        if (docusignResult === true) {
          successMsg =
            targetAccount?.productCategory === "EMPOWER_PREMIER"
              ? DOCUSIGN_SUCCESS_MSG_EPIA
              : DOCUSIGN_SUCCESS_MSG;
        } else if (docusignResult === false) {
          warningMsg = DOCUSIGN_WARNING_MSG;
        } else if (docusignResult === undefined) {
          if (model.details?.messages?.[0]?.type === "SUCCESS") {
            successMsg = model.details.messages[0].description;
          } else if (model.details?.messages?.[0]?.type === "WARNING") {
            warningMsg = model.details.messages[0].description;
          }
        }
        pershingMessages = model.details?.ruleEngineErrors;
        page = (
          <ModalContext.Consumer>
            {(inModal) => (
              <TransferConfirmation
                model={deepCopy(model)}
                sourceAccount={sourceAccount}
                targetAccount={targetAccount}
                onMakeAnotherTransfer={onMakeAnotherTransfer}
                onBackToHome={onBackToHome}
                onClose={onClose}
                messageSuccess={successMsg}
                messageWarning={warningMsg}
                pershingMessages={pershingMessages}
                showTitle={!inModal}
                displayAdvisorElements={IS_ADVISOR}
              />
            )}
          </ModalContext.Consumer>
        );
        break;
      case STEP_TRANSFER_FUNDS:
      default:
        page = (
          <ModalContext.Consumer>
            {(inModal) => (
              <TransferDetails
                personFullName={personFullName}
                errors={errors}
                model={deepCopy(model)}
                sourceAccounts={sourceAccounts}
                targetAccounts={targetAccounts}
                contributionYears={contributionYears}
                onContinue={this.handleContinue}
                onSourceAccountChange={onSourceAccountChange}
                onTargetAccountChange={onTargetAccountChange}
                onBack={onBack}
                onLinkAccount={onLinkAccount}
                isContributionYearDisabled={isContributionYearDisabled}
                stateType={stateType}
                transferType={transferType}
                hasRecurringTransferEstablished={
                  hasRecurringTransferEstablished
                }
                isPreviousYearContribution={isPreviousYearContribution}
                onModelChange={onModelChange}
                isTaxWithholdingEnabled={isTaxWithholdingEnabled}
                isPSTaxWithholdingEnabled={isPSTaxWithholdingEnabled}
                shouldShowCryptoWarning={false}
                targetAccountOwnerAge={targetAccountOwnerAge}
                hasOneTimeFrequency={hasOneTimeFrequency}
                isToDropDownDisabled={isToDropDownDisabled}
                isFromDropDownDisabled={isFromDropDownDisabled}
                showTitle={!inModal}
                shouldShowTradingBlackoutWarningMsg={
                  shouldShowTradingBlackoutWarningMsg
                }
                styleGuideData={styleGuideData}
              />
            )}
          </ModalContext.Consumer>
        );
        break;
    }

    return page;
  }

  render() {
    const {
      shouldShowTradingBlackoutWarningModal,
      onBlackoutModalClose,
      model,
    } = this.props;
    return (
      <div className="transfer-funds-fund-account-wizard qa-page-transfer-funds">
        {this.getPage()}
        {model.deliveryMethod !== DELIVERY_METHOD_WIRE_TRANSFER && (
          <TradingBlackoutModal
            isOpen={shouldShowTradingBlackoutWarningModal}
            onClose={onBlackoutModalClose}
            modalMsg={BLACKOUT_MODAL_MSG}
          />
        )}
      </div>
    );
  }
}

TransferFundsWizard.propTypes = {
  pageIndex: PropTypes.number,
  sourceAccounts: PropTypes.array,
  targetAccounts: PropTypes.array,
  model: PropTypes.object,
  errors: PropTypes.array,
  onSourceAccountChange: PropTypes.func,
  onTargetAccountChange: PropTypes.func,
  onNextPage: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onMakeAnotherTransfer: PropTypes.func,
  onBackToHome: PropTypes.func,
  onClose: PropTypes.func,
  onLinkAccount: PropTypes.func,
  personFullName: PropTypes.string,
  onTransferFund: PropTypes.func.isRequired,
  onChildMounted: PropTypes.func,
  docusignResult: PropTypes.bool,
  stateType: PropTypes.string,
  transferType: PropTypes.number.isRequired,
  contributionYears: PropTypes.object,
  targetAccountOwnerAge: PropTypes.number,
  hasRecurringTransferEstablished: PropTypes.bool,
  isPreviousYearContribution: PropTypes.bool,
  onModelChange: PropTypes.func,
  pageBuilder: PropTypes.func,
  isPSTaxWithholdingEnabled: PropTypes.bool,
  isTaxWithholdingEnabled: PropTypes.bool,
  hasOneTimeFrequency: PropTypes.bool,
  isToDropDownDisabled: PropTypes.bool,
  isFromDropDownDisabled: PropTypes.bool,
  isContributionYearDisabled: PropTypes.bool,
  shouldShowTradingBlackoutWarningModal: PropTypes.bool,
  shouldShowTradingBlackoutWarningMsg: PropTypes.bool,
  isTradingSuspended: PropTypes.bool,
  onBlackoutModalClose: PropTypes.func,
};

TransferFundsWizard.defaultProps = Object.assign({}, Wizard.defaultProps, {
  pages: [
    STEP_TRANSFER_FUNDS,
    STEP_ACCOUNT_DETAILS,
    STEP_VERIFY_TRANSFER,
    STEP_UPLOAD_STATEMENT,
    STEP_REVIEW,
    STEP_CONFIRMATION,
  ],
  pageBuilder: buildPages,
  sourceAccounts: [],
  targetAccounts: [],
  onChildMounted: noop,
  onLinkAccount: undefined,
  docusignResult: undefined,
  stateType: undefined,
  contributionYears: undefined,
  targetAccountOwnerAge: undefined,
  hasRecurringTransferEstablished: false,
  isPreviousYearContribution: false,
  onModelChange: noop,
  hasOneTimeFrequency: undefined,
  isToDropDownDisabled: undefined,
  isFromDropDownDisabled: undefined,
  isContributionYearDisabled: false,
  isPSTaxWithholdingEnabled: false,
  isTaxWithholdingEnabled: false,
  isTradingSuspended: false,
  shouldShowTradingBlackoutWarningModal: false,
  shouldShowTradingBlackoutWarningMsg: false,
});

TransferFundsWizard.contextType = EditRecurringTransfersContext;
