/* eslint-disable sonarjs/no-duplicate-string, sonarjs/cognitive-complexity */
import analytics from "analytics";
import processViewState from "./utils/processViewState";
import * as customEvents from "libs/pcap/utils/customEvents";
import eventBus from "eventBus";
import * as Constants from "constants/styles";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import "backbone.view";
import BackboneReactView from "common/BackboneReactView";
import "backbone.queryparams";
import React from "react";
import ReactDOM from "react-dom";
import AppOverlay from "appOverlay";
import AceTests from "libs/aceTests";
import Mixpanel from "mixpanel";
import PostLoginActionsView from "views/modules/postLoginActions";
import SidebarView2 from "views/modules/sidebar/sidebar2";
import DashboardNewView from "components/dashboard/DashboardBackboneView";
import ShareWithFriendsModal from "components/shareWithFriends/ShareWithFriendsModal";
import FinancialPlanOfferModal from "components/FinancialPlanOffer/FinancialPlanOfferModal";
import ReferralCenterViewNative from "components/referralCenterNative/ReferralCenterView";
import ReferralCenterViewIframe from "components/referralCenter/ReferralCenterView";
import DocumentVaultContainer from "components/documentVault/DocumentVaultContainer";
import _401kView from "views/modules/_401k/_401k";
import AllTransactionsViewContainer from "views/modules/allTransactions/allTransactionsViewContainer";
import AccountDetailsView2 from "views/modules/accountDetailsView2";
import ContentIframeView from "views/modules/contentIframe/contentIframeView";
import CashFlowIncomeExpenseView from "views/modules/cashFlowV2/cashFlowIncomeExpenseView";
import CashFlowView from "views/modules/cashFlowV2/cashFlowView";
import UpgradeBrowserBanner from "views/components/upgradeBrowserBanner";
import LinkAccountModal from "views/modal-windows/linkAccountView";
import FirstUseView2 from "views/modules/firstUseView2";
import BillsView from "views/modules/billsView";
import StockOptionsView from "views/modules/stockOptions/stockOptionsView";
import EnrollNowModal from "views/components/enrollNowModal/enrollNowModalView";
import generateSimulationMessages from "views/modules/forecast/components/simulationMessagesGenerator";
import getReferralLinkView from "views/components/getReferralLinkView";
import AccountDetailState from "stateModels/accountDetailState";
import CashFlowState from "stateModels/cashFlowState";
import StockOptionState from "stateModels/stockOptionState";
import ProfileState from "stateModels/profileState";
import NetWorthState from "stateModels/netWorthState";
import ModalContentTemplate from "templates/components/modalContent/modalContent.html";
import CustomZendeskHelpButtonTemplate from "templates/components/customZendeskHelpButton.html";
import * as PlannerSession from "components/planner/common/session";
import ConfirmModal from "common/ConfirmModal/ConfirmModal";
import * as AttributionStore from "components/common/attributionStore";
import GetSourceFromUrl from "libs/pcap/utils/getSourceFromUrl";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import abFrameworkProperties from "libs/pcap/utils/abFrameworkProperties";
import getPreviousRouteHash from "libs/pcap/utils/getPreviousRouteHash";
import getUrlParameterByName from "libs/pcap/utils/getUrlParameterByName";
import isProductionEnvironment from "libs/pcap/utils/isProductionEnvironment";
import { createViewTrackingEvent } from "libs/pcap/utils/createTrackingEvent";
import objectPath from "object-path";
import AppointmentModal from "components/scheduling/AppointmentModal";
import ContactUsFormModal from "components/ContactUsForm/ContactUsFormModal";
import TransferManagerContainer from "components/transferManager/TransferManagerContainer";
import "libs/pcap/utils/utils";
import { promisify } from "utils/service";
import { isClient } from "libs/pcap/utils/userStage";
import NotificationContainer from "components/notifications/NotificationsContainer";
import {
  showMadLibsInterjectionReviewModal,
  setMadLibsInterjectionReviewCustomContent,
  isFirstUse,
  trackTestGroupID,
} from "libs/pcap/utils/customAppointmentSchedulerUtils";
import * as InterjectionUtils from "components/interjection/InterjectionUtils";
import SidebarModel from "models/sidebar";
import fireGoogleTagManagerEvent from "libs/pcap/utils/fireGoogleTagManagerEvent";
import getAbFrameworkPromise from "libs/pcap/utils/getAbFrameworkPromise";
import { updateSidebarSelectedAccount } from "views/modules/sidebar/utils/accountUtils";
import TollGateOffRampPageView from "views/components/firstUse/tollGateOffRampPageView/TollGateOffRampPageView";
import { getFeatureFlagData } from "views/components/firstUse/firstUseQuestionsBaseView";
import { AB_TEST_GROUP_A } from "libs/pcap/utils/getABTestGroupForCurrentUser";
import { getFromSessionStorage } from "libs/pcap/utils/customAppointmentSchedulerUtils";
import { isMobileDevice } from "../libs/pcap/utils/isMobileDevice";
import moment from "moment";

const ROUTE_ACTIONS_EDUCATION_PLANNER = [
  "forecast",
  "education",
  "showExcludeAccountsFromAdviceModal",
];
const ROUTE_ACTIONS_RETIREMENT_PLANNER_SECTIONS = [
  "index",
  "income-event",
  "spending-goal",
  "education-goal",
  "retirement-spending",
  "paycheck",
  "scenario",
];
const ROUTE_ACTION_PROPOSAL = "proposal";
const ROUTE_ACTION_MEETING = "viewMeeting";

const SIDEBAR_SHOW_DELAY_FIRST_TIME_SMALL_VIEWPORT = 3000;
const CLASS_IS_FIRST_USE_APPOINTMENT = "is-first-use-appointment";

const ZENDESK_SUPPORT_ELEMENTS_SELECTOR = 'iframe[class^="zEWidget-"]';
const USER_USER_STAGE_TO_TIER_MAPPING = { A: 4, B: 3, C: 2, D: 2, E: 1 };
const CUSTOM_SUPPORT_BUTTON_USER_TIER_THRESHOLD = 3;

const TALKABLE_REFERRAL_CENTER_ID = "referralCenter";
const TALKABLE_SITE_ID = `pcap${isProductionEnvironment() ? "" : "-test"}`;
const TALKABLE_CLIENT_JS_URL = `https://d2jjzw81hqbuqv.cloudfront.net/integration/clients/${TALKABLE_SITE_ID}.min.js`;

const CRANKWHEEL_DOMAIN = "crankwheel.com";

const GTM_EVENT_LAUNCH_ADVISOR_CHAT = "/launch-advisor-chat";

const KEEP_ALIVE_INTERVAL = 300000; // 5 minutes

const EVENT_CLICK = "click.router";
const SELECTOR_SIDEBAR = "#sidebarContent";
const SELECTOR_MENU = "#menuContainer";
const SELECTOR_FOOTER = "#footerContainer";
const CLASS_FULLSIZE_MODULE = "fullsize-module";
const SELECTOR_MODULE = "#moduleContainer";
const SELECTOR_STOCK_OPTIONS = "#stockOptions";
const SELECTOR_EDUCATION = "#education";
const SELECTOR_SAVINGS_PLANNER = "#savingsPlanner";
const ELEMENT_DIV = "<div></div>";
const ROUTE_ACCOUNTS_ADD = "#/accounts/add";
const SELECTOR_RECOMMENDATIONS = "#recommendations";
const SELECTOR_TRANSFER_MANAGER = "#transfer-manager";
const EVENT_MILESTONES_UPDATED = "milestones:updated";
const ROUTE_STRATEGY_RECOMMENDATION = "#/strategy/recommendation";
const SELECTOR_PLANNING_HISTORY = "#planningHistory";
const SELECTOR_OPEN_BANK_ACCOUNT = "#open-bank-account";
const SELECTOR_CLOSE_BANK_ACCOUNT = "#close-bank-account";
const SELECTOR_CLOSED_ACCOUNTS = "#closed-accounts";
const SELECTOR_ENROLL_RETIREMENT_ABOUT_PAGE = "#enrollRetirementAboutPage";
const SELECTOR_ENROLL_RETIREMENT_INTRO_PAGE = "#enrollRetirementIntroPage";
const SELECTOR_ENROLL_RETIREMENT_CONFIRMATION = "#enrollRetirementConfirmation";
const SELECTOR_ENROLL_RETIREMENT_ACCOUNT = "#enrollRetirementAccount";

const SELECTOR_MAIN_MENU_LINK =
  ".menu__container > ul.menu--primary:not(.menu--submenu) > li.menu__item > a.menu__action";
const SELECTOR_NESTED_MENU_LINK =
  "ul.menu--submenu > li.menu__item.menu--nested-action > a.menu__action";

const SELECTOR_SUB_MENU_LINK =
  "ul.menu--submenu > li.menu__item > a.menu__action";

const SELECTOR_LAST_MENU_ITEM =
  ".menu__container > ul.menu--primary:not(.menu--submenu) > li.menu__item > ul.menu--submenu > li.menu__item:last-child > a.menu__action";

const SELECTOR_ALL_MENU_LINK =
  ".menu__container > ul.menu--primary:not(.menu--submenu) > li.menu__item > ul.menu--submenu > li.menu__item:not(.menu--nested-action) > a.menu__action";

const SELECTOR_NESTED_SUBMENU_LINK =
  "ul.menu--nested > li.menu__item > a.menu__action";

const SELECTOR_MENU_SUBMENU = "ul.menu--submenu";
const CLASS_SUBMENU = "menu--visible";

const MOBILE_WIDTH = 420;

function isLeavingPlanner(routeAction) {
  return !ROUTE_ACTIONS_EDUCATION_PLANNER.includes(routeAction);
}

function isLeavingProposal(routeAction, activeID) {
  return routeAction !== ROUTE_ACTION_PROPOSAL && activeID === "#proposal";
}

function isLeavingMeeting(routeAction, activeID) {
  return routeAction !== ROUTE_ACTION_MEETING && activeID === "#meeting";
}

function isRetirementPlannerOnboarding(routeAction) {
  return routeAction.includes("retirement-planner-onboarding");
}

function getDefaultForecastSection(section) {
  if (_.isUndefined(section) || !section.length) {
    return "index";
  }
  return section;
}

function triggerHotjar() {
  window.hj?.("trigger", "js-hj-retirement-planner-improvements");
  window.hj?.("tagRecording", [
    "retirement planner improvements",
    window.userGuid,
  ]);
}

const ROUTE_ACTIONS_THAT_COLLAPSE_SIDEBAR = [
  ROUTE_ACTION_PROPOSAL,
  "openBankAccount",
  "transferFunds",
  "enrollRetirementAboutPage",
  "enrollRetirementIntroPage",
  "enrollRetirementConfirmation",
  "enrollRetirementAccount",
  "retirementPlannerOnboarding",
];

const fetchUIPreferences = promisify(Services.Profile.getUIPreferences);
const fetchFunnelAttributes = promisify(Services.Profile.getFunnelAttributes);
const fetchPerson = promisify(Services.Person.get);
const DEFAULT_DATE_RANGE = "90_DAYS";

const Router = Backbone.Router.extend({
  activeModuleID: null,
  activeModuleView: null,
  routes: {
    "": "callDashboard",
    dashboard: "dashboard",
    "401k": "show401k",
    "accounts/details": "accountDetails",
    advisor: "advisor",
    "advisor/:section": "advisor",
    "advisor/:section/:item": "advisor",
    "advisor-chat": "advisorChat",
    crypto: "crypto",
    portfolio: "portfolio",
    "portfolio/:section": "portfolio",
    "portfolio/:section/*path": "portfolio",
    invest: "onlineShopping",
    "invest/*path": "onlineShopping",
    "investment-checkup": "investmentCheckup",
    "investment-checkup/:section": "investmentCheckup",
    "investment-checkup/:section/*path": "investmentCheckup",
    cashFlow: "cashFlow",
    cashManager: "cashFlow",
    "cash-flow": "cashFlow",
    "cash-flow/": "cashFlow",
    "cash-flow/spending(/:categoryId)": "budgeting",
    "cash-flow/*path": "cashFlowIncomeExpense",
    documents: "documents",
    "documents/:section": "documents",
    "all-transactions": "transactions",
    "all-transactions/*path": "transactions",
    "all-transactions/:path": "transactions",
    "frame/*path": "showFrame",
    "stock-options": "stockOptions",
    "accounts/add": "showAddAccount",
    "accounts/add/*path": "showAddAccount",
    firstuse: "showFirstUse",
    "firstuse/*path": "showFirstUse",
    bills: "bills",
    notifications: "notifications",
    "settings(/:path)(/:subpath)": "settings",
    "net-worth": "netWorth",
    "net-worth/*path": "netWorth",
    "enroll-now": "enrollNowModal",
    "manual-classifications": "manualClassifications",
    "manual-classifications/*path": "manualClassifications",
    "retirement-planner": "forecast",
    "retirement-planner/:section": "forecast",
    "retirement-planner/edit/*path": "forecast",
    "retirement-planner/add/*path": "forecast",
    "exclude-accounts": "showExcludeAccountsFromAdviceModal",
    education: "education",
    "savings-planner": "savingsPlanner",
    "savings-planner(/:section)": "savingsPlanner",
    appointment: "appointment",
    "appointment/*path": "appointment",
    demo: "crankWheelInstantDemo",
    recommendation: "recommendations",
    "contact-us": "contactUs",
    "get-referral-link": "getReferralLink",
    "referral-center": "referralCenter",
    share: "shareWithFriends",
    strategy: "strategy",
    "strategy/:section": "strategy",
    proposal: "proposal",
    "completeness-meter": "callDashboard",
    "open-bank-account(/:step)(?ua=:id)": "openBankAccount",
    "close-bank-account(?ua=:id)": "closeBankAccount",
    "transfer-funds(/:step)(?to=:toAccountId)(&from=:fromAccountId)":
      "transferFunds",
    "view-meeting/*meetingId": "viewMeeting",
    "fp-topics": "planningTopics",
    "401k-recommendation": "employerPlanAnalysis",
    "emergency-fund-wizard": "emergencyFundWizard",
    "savings-strategy-wizard": "savingsStrategyWizard",
    "debt-review-wizard": "debtReviewWizard",
    "planning-history": "planningHistory",
    "strategy-review-wizard": "strategyReviewWizard",
    "planning-history(?activeMilestoneId=:activeMilestoneId)":
      "planningHistory",
    "request-advice": "requestAdviceModal",
    "emergency-fund-accounts": "emergencyFundAccounts",
    "insurance-wizard": "insuranceWizard",
    "equity-compensation-wizard": "equityCompensationWizard",
    "closed-accounts": "closedAccounts",
    "social-security-wizard": "socialSecurityWizard",
    "risk-tolerance-wizard": "riskToleranceWizard",
    "retirement-planner-onboarding": "retirementPlannerOnboarding",
    "edit-account/:id": "editAccount",
    "reconnect-retirement-account?ua=:id": "reconnectRetirementAccount",
    "update-forwarding-contact-information": "updateForwardingContactInfo",
    "enroll-retirement-account/about": "enrollRetirementAboutPage",
    "enroll-retirement-account/intro": "enrollRetirementIntroPage",
    "enroll-retirement-account/confirmation(/:state)":
      "enrollRetirementConfirmation",
    "enroll-retirement-account(/:step)": "enrollRetirementAccount",
    "transfer-manager": "transferManager",
    "transfer-manager/:section": "transferManager",
    "dashboard-welcome": "dashboardWelcome",
    "*notFound": "notFound",
  },
  initialize: function () {
    // event listener to update document title
    eventBus.bind(
      customEvents.updateDocumentTitle,
      this.updateDocumentTitle,
      this
    );
    eventBus.on("logout", PlannerSession.clear);
    eventBus.on("pagerendered", this.removePreloader.bind(this));
    eventBus.on("appnavigate", this.navigate.bind(this));

    window.addEventListener("pageloaded", (event) => {
      const { detail } = event;
      if (detail?.withoutScroll) {
        this.removePreloaderWithoutScroll();
      } else {
        const preserveView = detail?.preserveView || false;
        this.removePreloader(preserveView);
      }
    });

    // some modules directly access app overlay on the router
    this.appOverlay = AppOverlay;
    this.initApp();
    this.clearSessionStorage();
    this.keyboardNavigationFixes();
  },
  clearSessionStorage: function () {
    if (sessionStorage.getItem("COMPLETENESS_METER_RESPONSE")) {
      sessionStorage.removeItem("COMPLETENESS_METER_RESPONSE");
    }
  },
  initApp: function () {
    var self = this;

    // Enable this code when you need to perform a feature access check.
    //
    // NOTE: We probably need to do something smarter here since we
    // let the app initialize while this request is in progress.
    // The access to `AppRouter.featureAccess` from a view will fail
    // if the `Services.Profile.getUIPreferences` is not complete.
    //
    //
    this.postLoginActions = new PostLoginActionsView();

    Services.Profile.getUIPreferences(
      async function (err, response) {
        window.hasStrategyReviewFeature =
          response?.spData?.features?.STRATEGY_REVIEW_V2 || false;
        this.sidebar = new SidebarView2();
        if (isRetirementPlannerOnboarding(getPreviousRouteHash())) {
          this.collapseSidebar();
        } else {
          this.expandSidebar();
        }
        if (!isFirstUse()) {
          $(".js-sidebar").removeClass("u-invisible");
        }
        if (response?.spData?.marketingABTestingFeatures) {
          const enableAppointmentSchedulerAfterAggregation = getFeatureFlagData(
            response.spData.marketingABTestingFeatures,
            "enableAppointmentSchedulerAfterAggregation"
          );
          if (enableAppointmentSchedulerAfterAggregation === AB_TEST_GROUP_A) {
            window.sessionStorage.setItem(
              "enableAppointmentSchedulerAfterAggregation",
              "1"
            );
          }
        }
        const hasRoadmap = Boolean(
          window.userHasOnusAccounts && response?.spData?.features?.WEB_BACKBONE
        );

        if (hasRoadmap) {
          const { default: RoadmapContainer } = await import(
            "components/ClientJourney/Roadmap/RoadmapContainer"
          );
          this.roadmap = new BackboneReactView({
            el: ".js-roadmap",
            component: RoadmapContainer,
          });
        }
        // Load the Yodlee Fastlink module
        var yodleeScript = document.createElement("script");
        yodleeScript.type = "text/javascript";
        yodleeScript.src =
          "https://participant.empower-retirement.com/ui/customization-ui/assets/scripts/yodlee-initialize.js";
        document.head.appendChild(yodleeScript);
      }.bind(this)
    );

    new UpgradeBrowserBanner();

    // append modals at the root level
    $(".js-main-container").after(ModalContentTemplate);

    self.frames = {
      "six-principles": cmsUrl + "wealth-management/invest-smarter?inPcapApp",
      "investing/enroll": cmsUrl + "wealth-management?inPcapApp",
    };
    // map the use of frames
    $("#mainMenu a").each(function (index, element) {
      if ($(element).attr("href").indexOf("/frame") !== -1) {
        // bit of a hack
        self.frames[
          $(element).attr("href").substr(23) // eslint-disable-line no-magic-numbers
        ] = $(element).attr("data-source"); /*Prev impl*/
      }
    });

    $(document.body).on(
      EVENT_CLICK,
      "#appSignOut, .signOut, #js-action-signout",
      function (e) {
        e.preventDefault();
        Mixpanel.trackEvent(
          "Sign Out",
          { component: "Main Menu" },
          {
            // eslint-disable-next-line camelcase
            send_immediately: true,
            callback: () => {
              Services.Signout.get();
            },
          }
        );
      }
    );

    // Mixpanel Tracking for click event for main menu items.
    $(document.body).on(
      EVENT_CLICK,
      ".js-main-menu-item-action",
      function (event) {
        ComponentAnalytics.trackClick(event, "MainMenu");
      }
    );

    // handle window size adjustment
    $(window).resize(function () {
      var iframeHeight =
        $(SELECTOR_SIDEBAR).height() -
        $(SELECTOR_MENU).height() -
        $(SELECTOR_FOOTER).height() -
        21; /*Prev impl*/ // eslint-disable-line no-magic-numbers
      $("#moduleContainer #iframe iframe").css({ height: iframeHeight });
      $("#moduleContainer #proposal-frame").css({ height: iframeHeight });
    });

    this.initializeIframePostMessageListener();
    window.addEventListener(
      "message",
      function (e) {
        // handle post messages originating from our content site, crankwheel or current domain
        if (
          e.origin === cmsUrl ||
          e.origin === location.origin ||
          (e.origin && e.origin.indexOf(CRANKWHEEL_DOMAIN) !== -1)
        ) {
          var data = e.data;
          self.handlePostMessage(data);
        }
      },
      false
    );

    $(document.body).on(EVENT_CLICK, ".closeModal", function (e) {
      e.preventDefault();
      $.colorbox.close();
    });

    // initialize advice stream
    this.notifications = document.createElement("div");
    $("#js-container-app-notifications").append(this.notifications);
    const props = { isNotificationCenter: false };
    ReactDOM.render(
      React.createElement(NotificationContainer, props),
      this.notifications
    );

    // add hashchange event
    $(window).on("hashchange", self.hashchange.bind(self));
    self.hashchange();

    // Show tier 3 and 4 users a custom Zendesk support button to redirect them to the support page
    /* eslint-disable sonarjs/no-collapsible-if */
    Services.Person.get(
      function (err, response) {
        if (!err && response && response.spHeader) {
          var tier =
            USER_USER_STAGE_TO_TIER_MAPPING[response.spHeader.userStage];
          if (tier >= CUSTOM_SUPPORT_BUTTON_USER_TIER_THRESHOLD) {
            this.useCustomZendeskHelpButton();
          }
        }

        if (!err && response && response.spData) {
          if (response.spData.address?.state === "CA") {
            $.pcap.setCookie("pcapRegion", "ca", "1", true);
          }
          window.sessionStorage.setItem(
            "firstUsePersonData",
            JSON.stringify(response.spData)
          );
        }
      }.bind(this)
    );

    AceTests.initialize();

    Mixpanel.trackZendeskHelpButtonClicks();

    this.setupAttributionTracking();
    this.setRebrandCookie();
  },

  isActiveModalDialog(id) {
    return id === this.activeModalDialog?.moduleId;
  },

  showModalDialog(id, instance) {
    // Load dashboard behind the modal if we hit the modal URL directly.
    if (!this.activeModuleID) {
      this.dashboard();
    }

    if (this.activeModalDialog) {
      // Prevent the infinite loop of redirects between the modal dialogs on close.
      this.history.pop();

      this.destroyActiveModalDialog();
    }

    this.activeModalDialog = instance;
    this.activeModalDialog.moduleId = id;
    const { el } = instance;
    if (!el.parentElement) {
      document.body.appendChild(el);
    }
  },

  updateActiveModalDialog(props) {
    this.activeModalDialog.update(props);
  },

  destroyActiveModalDialog() {
    this.activeModalDialog?.remove();
    this.activeModalDialog = null;
  },

  expandSidebar(forceExpand) {
    // Expand the sidebar only when the sidebar is not closed manually
    // Or when it is force opened due to a aggregation error or a PCB account enrollment is finished.
    if (this.sidebar && (forceExpand || !this.sidebar.isManuallyClosed)) {
      this.sidebar.open();
      this.sidebar.showCollapsedView = false;
    }
  },

  collapseSidebar() {
    if (this.sidebar) {
      this.sidebar.collapse();
      this.sidebar.showCollapsedView = true;
    }
  },

  setRebrandCookie: function () {
    const isRebrandCookie = "rebrand";
    const cookieLifetimeInDays = 1;
    const storeCookieInTopLevelDomain = true;
    $.pcap.setCookie(isRebrandCookie, "", -1, storeCookieInTopLevelDomain);

    $.pcap.setCookie(
      isRebrandCookie,
      JSON.stringify(window.isWebRebrandingEnabled),
      cookieLifetimeInDays,
      storeCookieInTopLevelDomain
    );
  },

  setupAttributionTracking: function () {
    AttributionStore.clear();

    // capture attribution source from deep links into the app
    var source = GetSourceFromUrl();
    if (source !== "") {
      AttributionStore.setSource(source);
    }

    // capture attribution source from various links in the app
    $(document.body).on(
      "click.attribution",
      "[data-attribution-source]",
      function (e) {
        AttributionStore.setSource(e.currentTarget.dataset.attributionSource);
      }
    );
  },

  cleanupMeeting: function () {
    this.isMeetingActive = false;
    // Reset container styles and iframe src
    $("#meeting-iframe").remove();
    $(document.body).removeClass(CLASS_FULLSIZE_MODULE);
    $("#meeting").hide();

    $('a[href="/page/enrollment/start"]').off(
      "click.meetinglock",
      this.handleEnrollmentClick.bind(this)
    );

    clearInterval(this.keepAliveTimer);

    if (this.sidebar) {
      this.sidebar.expand();
      this.sidebar.showCollapsedView = false;
    }
  },

  // intercepts every route change
  execute: function (callback, args, routeAction) {
    if (isLeavingPlanner(routeAction)) {
      if (PlannerSession.hasUnsavedChanges()) {
        this.renderLeavePlannerConfirm({
          isOpen: true,
          onCancel: function () {
            window.history.back();
            this.renderLeavePlannerConfirm({ isOpen: false });
          }.bind(this),
          onConfirm: function () {
            PlannerSession.clear();
            this.renderLeavePlannerConfirm({ isOpen: false });
            this.sidebar?.hidePlansSidebar();

            // proceed with the routing
            callback?.apply(this, args);
          }.bind(this),
          onClosed: function () {
            ReactDOM.unmountComponentAtNode(this.plannerConfirmEl);
          }.bind(this),
        });
        return false;
      }

      this.sidebar?.hidePlansSidebar();
    }

    if (isLeavingProposal(routeAction, this.activeModuleID)) {
      // Reset container styles and iframe src
      $("#proposal-frame").attr("src", "about:blank");
      $(document.body).removeClass(CLASS_FULLSIZE_MODULE);
      $("#proposal").hide();
    }

    if (isLeavingMeeting(routeAction, this.activeModuleID)) {
      if (this.isMeetingActive) {
        this.showMeetingLeaveDialog(callback, args);
        return false;
      }
      this.cleanupMeeting();
    }
    const gettingStartedOverview = $(".getting-started-overview__container");
    if (
      !ROUTE_ACTIONS_THAT_COLLAPSE_SIDEBAR.includes(routeAction) &&
      !gettingStartedOverview.length
    ) {
      this.expandSidebar();
    }
    if (routeAction !== "netWorth" && this.sidebar && this.sidebar.$el) {
      this.sidebar.$el.find(".js-networth-section").removeClass("selected");
    }

    // Removes selected account in sidebar when route changes.
    if (this.sidebar && this.sidebar.$el) {
      this.sidebar.$el
        .find(".js-accounts-list .js-account")
        .removeClass("selected");
    }

    if (routeAction !== "appointment" && this.appointmentModal) {
      this.removeScheduleAppointment();
    }

    // proceed with the routing
    callback?.apply(this, args);
  },

  showMeetingLeaveDialog: function (callback, args) {
    this.renderLeaveMeetingConfirm({
      isOpen: true,
      onCancel: function () {
        location.hash = `/view-meeting/${this.meetingId}`;
        this.renderLeaveMeetingConfirm({ isOpen: false });
      }.bind(this),
      onConfirm: function () {
        this.renderLeaveMeetingConfirm({ isOpen: false });
        this.cleanupMeeting();
        if (callback) {
          callback.apply(this, args);
        }
      }.bind(this),
      onClosed: function () {
        if (this.meetingConfirmEl) {
          ReactDOM.unmountComponentAtNode(this.meetingConfirmEl);
        }
      }.bind(this),
    });
  },

  renderLeaveMeetingConfirm: function (props) {
    if (!this.meetingConfirmEl) {
      this.meetingConfirmEl = document.createElement("div");
      document.body.appendChild(this.meetingConfirmEl);
    }
    ReactDOM.render(
      React.createElement(
        ConfirmModal,
        Object.assign(
          {
            children: "Are you sure you want to leave the meeting?",
            cancelLabel: "Stay in Meeting",
            confirmLabel: "Leave Meeting",
          },
          props
        )
      ),
      this.meetingConfirmEl
    );
  },

  renderLeavePlannerConfirm: function (props) {
    if (!this.plannerConfirmEl) {
      this.plannerConfirmEl = document.createElement("div");
      document.body.appendChild(this.plannerConfirmEl);
    }
    ReactDOM.render(
      React.createElement(
        ConfirmModal,
        Object.assign(
          {
            children:
              "You have unsaved changes to your plan. Are you sure you want to leave this page?",
            cancelLabel: "Stay on this Page",
            confirmLabel: "Leave this Page",
          },
          props
        )
      ),
      this.plannerConfirmEl
    );
  },

  useCustomZendeskHelpButton: function () {
    // Remove vendor's original button
    var originalWidget = $(ZENDESK_SUPPORT_ELEMENTS_SELECTOR);
    if (!originalWidget.length) {
      return;
    }
    originalWidget.closest("div").addClass("is-hidden");
    var customZendeskHelpButton = $(
      CustomZendeskHelpButtonTemplate({
        organizationSupportUrl: EMPOWER_SUPPORT_URL,
      })
    );
    $("body").append(customZendeskHelpButton);
    customZendeskHelpButton.on("click", function () {
      Mixpanel.trackEvent(
        "Click on Custom Zendesk Help Button and View Support Page",
        {
          component: "Zendesk Support Button",
        }
      );
    });
  },
  transactions: function (path) {
    let props = {};
    if (_.isObject(path)) {
      props = path;
    } else if (typeof path === "string") {
      props.activeFilter = path && path.toUpperCase();
    }

    Promise.all([fetchUIPreferences()]).then(([uiPreferences]) => {
      props.transactionCategoryRulesFlag = objectPath.get(
        uiPreferences,
        "features.TRANSACTION_CATEGORY_RULES"
      );
      props.manualTransactionsFlag = objectPath.get(
        uiPreferences,
        "features.ADD_MANUAL_TRANSACTION"
      );

      eventBus.trigger("updateDocumentTitle", "Transactions");
      if (this.showModule("#transactions")) {
        eventBus.once("allTransactionsViewRendered", () => {
          this.removePreloaderWithoutScroll();
          this.scrollToTopPreservingXCoordinate();
        });
        this.activeModuleView = new BackboneReactView({
          el: "#transactions",
          component: AllTransactionsViewContainer,
          componentProps: props,
        });
      } else {
        this.activeModuleView.update({
          activeFilter: path && path.toUpperCase(),
        });
      }
    });
  },
  accountDetails: function (path, params) {
    if (_.isObject(path)) {
      params = path;
    }
    eventBus.trigger("updateDocumentTitle", "Account Details");

    Promise.all([fetchUIPreferences(), fetchFunnelAttributes()]).then(
      ([uiPreferences, funnelAttributes]) => {
        var viewState = new AccountDetailState(params.ua),
          propertyName;

        viewState.transactionCategoryRulesFlag = objectPath.get(
          uiPreferences,
          "features.TRANSACTION_CATEGORY_RULES"
        );
        viewState.manualTransactionsFlag = objectPath.get(
          uiPreferences,
          "features.ADD_MANUAL_TRANSACTION"
        );
        viewState.isInvestmentCheckupV2 = objectPath.get(
          uiPreferences,
          "features.INVESTMENT_CHECKUP_V2"
        );
        viewState.isUserAggQual =
          funnelAttributes.daysSinceAggregationQualified >= 0;

        viewState.isUserClient = funnelAttributes.isClient;
        viewState.is365Enabled = objectPath.get(
          uiPreferences,
          "features.ENABLE_365_DAY_TRANSACTION"
        );

        viewState.showCloseCashAccount = objectPath.get(
          uiPreferences,
          "features.CLOSE_CASH_ACCOUNT"
        );

        for (var i = 0; i < viewState.optionalUrlParams.length; i++) {
          propertyName = viewState.optionalUrlParams[i];
          if (!_.isUndefined(params[propertyName])) {
            viewState[propertyName] = params[propertyName];
          }
        }

        updateSidebarSelectedAccount();
        if (this.showModule("#accountDetails")) {
          this.activeModuleView = new AccountDetailsView2(viewState);
        } else {
          this.activeModuleView.updateView(viewState);
        }
      }
    );
    // TODO: Investigate moving it to showModule
    // $('#moduleContainer').scrollTop(0);
  },
  start: function () {
    // pass user stage data to GA
    /* eslint-disable camelcase, no-underscore-dangle, no-unused-expressions */
    window._pcap_mbc = $.pcap.getCookie("PCAP_MBC"); /*Prev impl*/
    if (window._pcap_mbc !== "") {
      /*Prev impl*/
      window.ga &&
        window.ga("set", "dimension6", window._pcap_mbc); /*Prev impl*/
    }
    /* eslint-disable camelcase, no-underscore-dangle, no-unused-expressions */

    // track history
    this.history = [];
    this.trackRouteHistory();

    // start router
    Backbone.history.start();
  },
  hashchange: function () {
    InterjectionUtils.checkForActiveInterjection({
      showBlankDisplayLocationInterjection: true,
      calledByHashChange: true,
    });
    createViewTrackingEvent();
    // update usermessage if a url contains userMessageId and action
    this.updateOutOfAppNotifications();
  },
  notFound: function (path) {
    ComponentAnalytics.trackEvent(
      "Non-Existent Route",
      "Tried to Navigate to Non-Existent Route",
      { path: path }
    );
    this.callDashboard();
  },
  callDashboard: function () {
    if (numberOfAccounts <= 0) {
      if (hasEnrollments) {
        this.navigate("#/advisor");
      } else {
        this.navigate("#/firstuse");
      }
    } else {
      this.navigate(DASHBOARD_URL);
    }
  },
  async dashboard() {
    eventBus.trigger("updateDocumentTitle", "Dashboard");
    if (this.showModule("#dashboard")) {
      let useUnifiedMar = false,
        firstTimeForecastExperience = false;
      let gettingStartedOverviewData = {};

      try {
        const [uiPreferencesData, funnelAttributes, person] = await Promise.all(
          [fetchUIPreferences(), fetchFunnelAttributes(), fetchPerson()]
        );
        const { features, uiPreferences, marketingABTestingFeatures } =
          uiPreferencesData;
        useUnifiedMar = features?.USE_UNIFIED_MAR;
        firstTimeForecastExperience =
          uiPreferences?.firstTimeForecastExperience;
        const gettingStartedOverviewTest =
          getFeatureFlagData(
            marketingABTestingFeatures,
            "gettingStartedOverview"
          ) === AB_TEST_GROUP_A;
        gettingStartedOverviewData = {
          enableGettingStartedOverview:
            gettingStartedOverviewTest &&
            funnelAttributes.daysSinceRegistration === 0,
          notesForAdvisor: person.notesForAdvisor,
          personName: person.name?.firstName,
          personAge: person.age,
        };
        if (!this.logGettingStartedPage) {
          this.logGettingStartedPage = true;
          if (gettingStartedOverviewTest) {
            Mixpanel.trackEvent("GettingStartedPageForEveryone_Test");
            Mixpanel.updateProfile(
              { TestGroupID: "GettingStartedPageForEveryone_Test" },
              null,
              true
            );
          } else {
            Mixpanel.trackEvent("GettingStartedPageForEveryone_Control");
            Mixpanel.updateProfile(
              { TestGroupID: "GettingStartedPageForEveryone_Control" },
              null,
              true
            );
          }
        }
      } catch (error) {
        this.handleFetchUIPreferencesError(error);
      }
      this.activeModuleView = new DashboardNewView({
        useUnifiedMar,
        firstTimeForecastExperience,
        gettingStartedOverviewData,
      });

      if (window.innerWidth <= Constants.MQ_BREAKPOINT_WIDE && this.sidebar) {
        this.sidebar.pinSideBar();
        setTimeout(
          function () {
            // Only unpin if no account is resolving
            if (!this.sidebar.isAnyExtraFormVisible()) {
              this.sidebar.unPinSideBar();
            }
          }.bind(this),
          SIDEBAR_SHOW_DELAY_FIRST_TIME_SMALL_VIEWPORT
        );
      }
    }
  },
  advisor: function (section, subsection) {
    // In case the advisor route contains query params, the router calls
    // this function passing the query params object as the first `section` argument.
    // We're not interested in query params here, so replacing `section` value with
    // the default section.
    if (_.isObject(section)) {
      section = "index";
    }
    var self = this;
    eventBus.trigger("updateDocumentTitle", "Advisor");
    if (section === undefined || section === "") {
      section = "index";
    }

    var tabName = $.pcap.capitalizeWords(section.replace(/-/g, " "));
    if (tabName === "Index") {
      tabName = "Advisor";
    }

    if (self.showModule("#advisorDetails")) {
      // By this point, calls to getUIPreferences API should already be cached by main.js
      Services.Profile.getUIPreferences(async function (err, response) {
        self.addFundsEligible =
          response &&
          response.spData &&
          response.spData.features &&
          response.spData.features.ADD_FUNDS_ELIGIBLE === true;
        const { default: AdvisorDetailsView } = await import("advisorModule");
        self.activeModuleView = new AdvisorDetailsView({
          section: section,
          subsection: subsection,
          addFundsEligible: self.addFundsEligible,
        });
        Mixpanel.trackEvent("View Advisor Page");
      });
    } else {
      self.activeModuleView.showSection(section, subsection);
      Mixpanel.trackEvent("View " + tabName + " Tab", {
        component: "Advisor Page",
        tab: "Advisor",
      });
    }
  },
  advisorChat: function () {
    if (!window.dataLayer) {
      return;
    }
    Mixpanel.trackEvent("Visit #/advisor-chat");
    var mainAppContentIsBlank = $(SELECTOR_MODULE).height() === 0;
    if (mainAppContentIsBlank) {
      this.navigate(DASHBOARD_URL);
    }
    window.dataLayer.push({ event: GTM_EVENT_LAUNCH_ADVISOR_CHAT }); // Trigger `Intercom launch-advisor-chat` tag in Google Tag Manager
  },
  portfolio: function (section, path) {
    var self = this;
    eventBus.trigger("updateDocumentTitle", "Portfolio");

    fetchUIPreferences().then(async (uiPreferences) => {
      const uiPreferenceDateRange =
        uiPreferences?.defaultDateRanges?.NETWORTH_INVESTING ||
        DEFAULT_DATE_RANGE;
      const isNewAllocationViewEnabled =
        uiPreferences?.features?.WEB_NEW_HOLDINGS_GRID_ALLOCATION_PAGE;
      const isNewSectorsViewEnabled =
        uiPreferences?.features?.WEB_NEW_HOLDINGS_GRID_SECTORS_PAGE;
      const isHoldingsGraphSticky =
        uiPreferences?.features?.WEB_STICKY_HOLDINGS_GRAPH;

      const serviceVersion =
        uiPreferenceDateRange === DEFAULT_DATE_RANGE ? "" : "V2";
      if (section === undefined || section === "") {
        section = "balances";
      }
      var showMod = self.showModule("#portfolio");
      if (showMod) {
        const { default: PortfolioView } = await import("portfolioModule");
        this.activeModuleView = new PortfolioView({
          section: section,
          serviceVersion,
          uiPreferenceDateRange,
          path: path,
          isNewAllocationViewEnabled,
          isNewSectorsViewEnabled,
          isHoldingsGraphSticky,
        });
      } else {
        this.activeModuleView.showSection(section, path);
      }
    });
  },
  onlineShopping: function (path) {
    Services.Profile.getUIPreferences(
      async function (err, response) {
        const isRetirementPlannerV2 =
          response.spData.features.RETIREMENT_PLANNER_V2;
        if (_.isObject(path)) {
          path = "";
        }

        const preselectedAccount = getUrlParameterByName("psaid");
        eventBus.trigger("updateDocumentTitle", "Invest Now");

        if ($("#online-shopping").length === 0) {
          $(SELECTOR_MODULE).append(
            '<div id="online-shopping" class="module"></div>'
          );
        }

        if (this.showModule("#online-shopping")) {
          const { default: OnlineShoppingView } = await import(
            "views/modules/onlineShopping/onlineShoppingView"
          );
          this.activeModuleView = new OnlineShoppingView({
            path: path,
            preselectedAccount: preselectedAccount,
            isRetirementPlannerV2,
          });
        } else {
          this.activeModuleView.updateView({
            path: path,
            preselectedAccount: preselectedAccount,
          });
        }
      }.bind(this)
    );
  },
  async crypto(path) {
    eventBus.trigger("updateDocumentTitle", "Crypto Holdings");

    if ($("#crypto").length === 0) {
      $(SELECTOR_MODULE).append('<div id="crypto" class="module"></div>');
    }

    var self = this;
    updateSidebarSelectedAccount();
    if (this.showModule("#crypto")) {
      const { default: CryptoView } = await import(
        "views/modules/crypto/crypto"
      );
      self.activeModuleView = new CryptoView({ path: path });
    } else {
      self.activeModuleView.updateView({ path: path });
    }
  },
  investmentCheckup: function (section, path) {
    eventBus.trigger("updateDocumentTitle", "Investment Checkup");
    Services.Profile.getUIPreferences(
      async function (err, response) {
        if (response && response.spData && response.spData.features) {
          this.isInvestmentCheckupV2 =
            response.spData.features.INVESTMENT_CHECKUP_V2;
        }

        if (section === undefined || section === "") {
          section = "allocation";
        }

        var tabName = $.pcap.capitalizeFirstLetter(section);
        if (this.showModule("#investmentCheckup")) {
          const { default: InvestmentCheckupView } = await import(
            "investmentCheckupModule"
          );
          this.activeModuleView = new InvestmentCheckupView({
            section: section,
            path: path,
            isInvestmentCheckupV2: this.isInvestmentCheckupV2,
          });
          Mixpanel.trackEvent("View Investment Checkup");
        } else {
          this.activeModuleView.showSection(section, path);
          var eventName = "View " + tabName + " Tab";
          Mixpanel.trackEvent(
            eventName,
            {
              component: "Investment Checkup First Use",
              tab: section,
            },
            { ignoreNextEventName: eventName }
          );
        }
      }.bind(this)
    );
  },
  cashFlow: function (path, params) {
    eventBus.trigger("updateDocumentTitle", "Cash Flow");
    var result = processViewState(
      "#cashFlow",
      path,
      params,
      "cashFlow",
      CashFlowState
    );
    if (path && path.startDate) {
      result.viewConfiguration.startDate = path.startDate;
      delete result.viewConfiguration.endDate;
    }
    // If not coming from any of the cashflow views (not persisting date filters) and there's
    // no date in the URL params, we should clean the previous date in the state
    if (
      !(this.activeModuleView instanceof CashFlowIncomeExpenseView) &&
      !(path && path.startDate)
    ) {
      delete result.viewConfiguration.startDate;
      delete result.viewConfiguration.endDate;
    }
    // True when coming from another module, False when been on cashflow
    if (this.showModule("#cashFlow")) {
      this.activeModuleView = new CashFlowView(result.viewConfiguration);
    } else {
      this.activeModuleView.updateView(result.viewState);
    }

    // TODO: Investigate moving it to showModule
    $(SELECTOR_MODULE).scrollTop(0);
  },
  cashFlowIncomeExpense: function (path, params) {
    eventBus.trigger("updateDocumentTitle", "Cash Flow Income - Expense");
    var result = processViewState(
      "#cashFlowIncomeExpense",
      path,
      params,
      "cashFlow",
      CashFlowState
    );
    // True when coming from another module, False when been on cashflow income or expense
    if (this.showModule("#cashFlowIncomeExpense")) {
      this.activeModuleView = new CashFlowIncomeExpenseView(
        result.viewConfiguration
      );
    } else {
      this.activeModuleView.updateView(result.viewState);
    }
    // TODO: Investigate moving it to showModule
    $(SELECTOR_MODULE).scrollTop(0);
  },

  async budgeting(categoryId) {
    eventBus.trigger("updateDocumentTitle", "Budgeting");
    $(SELECTOR_MODULE).append('<div id="budgeting"></div>');
    if (this.showModule("#budgeting")) {
      eventBus.once("pagerendered", () => {
        this.removePreloaderWithoutScroll();
        this.scrollToTopPreservingXCoordinate();
      });
      const { default: BudgetingContainer } = await import(
        "components/budgeting/BudgetingContainer"
      );
      this.activeModuleView = new BackboneReactView({
        el: "#budgeting",
        component: BudgetingContainer,
        componentProps: {
          categoryId,
        },
      });
    } else {
      this.activeModuleView.update({
        categoryId,
      });
    }
  },

  documents: function (section) {
    eventBus.trigger("updateDocumentTitle", "Documents");

    const tab = section ? section.toUpperCase() : section;

    ComponentAnalytics.trackEvent(
      "Document Vault",
      `Document Vault Tab Loaded: ${tab}`,
      { tab }
    );

    if (this.showModule("#documents")) {
      this.removePreloaderWithoutScroll();
      this.activeModuleView = new BackboneReactView({
        el: "#documents",
        component: DocumentVaultContainer,
        componentProps: {
          tab,
        },
      });
    } else {
      this.activeModuleView.update({
        tab,
      });
    }
  },
  bills: function () {
    eventBus.trigger("updateDocumentTitle", "Bills");

    if ($("#bills").length === 0) {
      $(SELECTOR_MODULE).append('<div id="bills" class="module"></div>');
    }
    if (this.showModule("#bills")) {
      this.activeModuleView = new BillsView();
    }
  },
  async manualClassifications(path) {
    eventBus.trigger("updateDocumentTitle", "Manual Classifications");
    if ($("#manualClassifications").length === 0) {
      $(SELECTOR_MODULE).append(
        '<div id="manualClassifications" class="module qa-manual-classifications"></div>'
      );
    }

    if (this.showModule("#manualClassifications")) {
      var self = this;
      const { default: ManualClassificationsView } = await import(
        "manualClassificationsModule"
      );
      self.activeModuleView = new ManualClassificationsView({
        path: path,
      });
    } else {
      this.activeModuleView.navigateTo(path);
    }
  },
  async notifications() {
    eventBus.trigger("updateDocumentTitle", "Notifications");
    if ($("#notifications").length === 0) {
      $(SELECTOR_MODULE).append(
        '<div id="notifications" class="module"></div>'
      );
    }
    if (this.showModule("#notifications")) {
      const { default: NotificationsView } = await import(
        "notificationsModule"
      );
      this.activeModuleView = new NotificationsView();
    }
  },
  show401k: function () {
    eventBus.trigger("updateDocumentTitle", "401k Fee Analyzer");

    if (this.showModule("#_401k")) {
      this.activeModuleView = new _401kView();
      Mixpanel.trackEvent("View Retirement Fee Analyzer");
    }
  },
  stockOptions: function (href, params) {
    eventBus.trigger("updateDocumentTitle", "Stock Options");

    var result = processViewState(
      SELECTOR_STOCK_OPTIONS,
      href,
      params,
      "stockOptionsView",
      StockOptionState
    );
    if (!$(SELECTOR_MODULE).find(SELECTOR_STOCK_OPTIONS).length) {
      $(SELECTOR_MODULE).append('<div id="stockOptions" class="module"></div>');
    }
    updateSidebarSelectedAccount();
    if (this.showModule(SELECTOR_STOCK_OPTIONS)) {
      this.activeModuleView = new StockOptionsView(result.viewConfiguration);
    } else {
      this.activeModuleView.updateView(result.viewState);
    }
  },
  settings: function (path, params) {
    const showNewPersonModal = params === "add-person";
    let settingsInitIsDone = false;

    eventBus.trigger("updateDocumentTitle", "Settings");
    Services.Profile.getUIPreferences.watch(async (err, response) => {
      const showDelegateAccess = objectPath.get(
        response,
        "spData.features.WEB_DELEGATE_ACCESS",
        false
      );
      const showTotpEnroll = objectPath.get(
        response,
        "spData.features.ENABLE_TOTP_AUTH",
        false
      );

      if ($("#profile").length === 0) {
        $(SELECTOR_MODULE).append('<div id="profile" class="module"></div>');
      }

      const mobileAppOnlySettingsPaths = [
        "email-subscriptions",
        "foreign-login-alert",
      ];
      if (mobileAppOnlySettingsPaths.includes(path)) {
        path = "settings";
      }

      var result = processViewState(
        "#profile",
        //PFM-10498 preventing passing invalid path to profile, instead the phoneView
        //component checks the url internally to trigger the phone update flow
        path === "phone-number" ? "" : path,
        params,
        "profile",
        ProfileState
      );
      var self = this;
      const showProfileModule = self.showModule("#profile");

      if (showProfileModule || settingsInitIsDone) {
        const { default: ProfileView } = await import("profileModule");
        Promise.all([
          promisify(Services.Profile.get)(),
          promisify(Services.Accounts.get)(),
        ]).then(([profileData, accountData]) => {
          const hasNoClosedOnUsRetirementAccounts = accountData.accounts.find(
            (a) => !a.closedDate && a.isOnUsRetirement
          );
          const onUsRetirementPaymentType = settingsInitIsDone
            ? objectPath.get(
                response,
                "spData.uiPreferences.onUsRetirementPaymentType",
                "NULL"
              )
            : profileData?.onUsRetirementPaymentType;
          const isPaymentTypeCreditCard = onUsRetirementPaymentType === "CARD";
          const isPaymentTypeNull =
            !onUsRetirementPaymentType || onUsRetirementPaymentType === "NULL";
          const isPaymentTypeOnUs =
            onUsRetirementPaymentType === "ONUS" ||
            onUsRetirementPaymentType === "NEW_ONUS";
          const showBilling = hasNoClosedOnUsRetirementAccounts;
          if (settingsInitIsDone) {
            this.activeModuleView.activeSubView.options = Object.assign(
              {},
              this.activeModuleView.activeSubView.options,
              {
                showBilling,
                isPaymentTypeCreditCard,
                isPaymentTypeOnUs,
                isPaymentTypeNull,
              }
            );
            this.activeModuleView.activeSubView.render();
          } else {
            this.activeModuleView = new ProfileView(
              Object.assign({}, result.viewConfiguration, {
                showDelegateAccess,
                showTotpEnroll,
                showNewPersonModal,
                showBilling,
                isPaymentTypeCreditCard,
                isPaymentTypeOnUs,
                isPaymentTypeNull,
              })
            );

            settingsInitIsDone = true;
          }
        });
      }

      if (showProfileModule) {
        Mixpanel.trackEvent("View Settings");
      } else {
        self.activeModuleView.updateView(result.viewState);
        var tabName = path ? $.pcap.capitalizeFirstLetter(path) : "Settings";
        var userClickedOnEditProfileBtn = tabName.indexOf("/") > -1;
        if (!userClickedOnEditProfileBtn) {
          Mixpanel.trackEvent("View " + tabName + " Tab", {
            component: "Settings Page",
            tab: tabName,
          });
        }
      }
    });
  },
  netWorth: function (path, params) {
    eventBus.trigger("updateDocumentTitle", "Net Worth");

    if ($("#netWorth").length === 0) {
      $(SELECTOR_MODULE).append('<div id="netWorth" class="module"></div>');
    }

    fetchUIPreferences().then(async (uiPreferences) => {
      const uiPreferenceDateRange =
        uiPreferences?.defaultDateRanges?.NETWORTH_INVESTING ||
        DEFAULT_DATE_RANGE;

      var result = processViewState(
        "#netWorth",
        path,
        params,
        "netWorth",
        NetWorthState
      );
      const serviceVersion =
        uiPreferenceDateRange === DEFAULT_DATE_RANGE ? "" : "V2";
      if (this.showModule("#netWorth")) {
        const { default: NetWorthView2 } = await import("netWorthModule2");
        this.activeModuleView = new NetWorthView2(
          Object.assign({}, result.viewConfiguration, {
            serviceVersion,
            uiPreferenceDateRange,
          })
        );
      } else {
        this.activeModuleView.updateView(result.viewState);
      }

      if (this.sidebar && this.sidebar.$el) {
        this.sidebar.$el.find(".js-networth-section").addClass("selected");
      }
    });
  },
  async showExcludeAccountsFromAdviceModal() {
    ComponentAnalytics.trackView("Accounts For Advice", {
      component: "Retirement Planner",
    });

    const id = "excludeAccountsFromAdviceModal";

    const previousRoute = getPreviousRouteHash();
    const onModalClosed = () => {
      window.AppRouter.navigate(previousRoute);
    };

    const componentProps = {
      onClosed: onModalClosed,
      onSaved: () => {
        if (this.activeModuleView && this.activeModuleView.refreshView) {
          this.activeModuleView.refreshView();
        }
      },
    };

    if (this.isActiveModalDialog(id)) {
      this.updateActiveModalDialog(componentProps);
    } else {
      const { default: ExcludeAccountFromHouseholdModalContainer } =
        await import(
          "views/modules/profile/ExcludeAccountFromHousehold/ExcludeAccountFromHouseholdModalContainer"
        );
      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: ExcludeAccountFromHouseholdModalContainer,
        })
      );
    }
  },
  initializeForecastDOM: function () {
    if ($("#forecast").length === 0) {
      $(SELECTOR_MODULE).append('<div id="forecast" class="module"></div>');
    }
  },
  forecast: function (section, params) {
    var self = this;
    Services.Profile.getUIPreferences(async function (err, response) {
      const attributes = await fetchFunnelAttributes();
      let isOnboardingRP = false;
      if (response?.spData?.marketingABTestingFeatures) {
        const features = response.spData.marketingABTestingFeatures;
        const retirementPlannerOnboardingFeatureFlag = getFeatureFlagData(
          features,
          "retirementPlannerOnboarding"
        );
        isOnboardingRP =
          retirementPlannerOnboardingFeatureFlag === AB_TEST_GROUP_A &&
          attributes.marketingId === "utmcct=customretirement" &&
          (moment(
            response.spData?.uiPreferences?.firstTimeForecastExperienceDate
          ).format("M/D/YYYY") === moment().format("M/D/YYYY") ||
            attributes.daysSinceRegistration < 0);
        if (isOnboardingRP) {
          window.sessionStorage.setItem(
            "qqThresholdAmount",
            features.AB_TEST_CONFIGURATION.featureDetails
              .retirementPlannerOnboarding?.qqThresholdAmount
          );
          window.sessionStorage.setItem(
            "maxqqThresholdAmount",
            features.AB_TEST_CONFIGURATION.featureDetails
              .retirementPlannerOnboarding?.maxqqThresholdAmount
          );
        }
      }

      const firstUseCompleted = params && params.firstusecompleted;
      const isFirstUseForecast =
        objectPath.get(
          response,
          "spData.uiPreferences.firstTimeForecastExperience"
        ) && !firstUseCompleted;
      const isDelegate = objectPath.get(response, "spHeader.isDelegate");
      const isRetirementPlannerV2 = objectPath.get(
        response,
        "spData.features.RETIREMENT_PLANNER_V2"
      );
      const isUserAggQual =
        objectPath.get(response, "spHeader.userStage") === "D";

      const isRetirementCashFlowPlanning = response.spData.enabledFPTopicsList
        ? response.spData.enabledFPTopicsList.includes(
            "Retirement Cash Flow Planning"
          )
        : false;

      // Only show paycheck tab to clients and agg qual prospects
      // and prospects with Retirement Cash Flow planning feature flag on
      const hasRetirementPaycheck =
        isUserAggQual ||
        window.userHasOnusAccounts ||
        isRetirementCashFlowPlanning;
      eventBus.trigger("updateDocumentTitle", "Retirement Planner");
      self.initializeForecastDOM();
      section = getDefaultForecastSection(section);

      const isStandardRP =
        !isFirstUseForecast &&
        ROUTE_ACTIONS_RETIREMENT_PLANNER_SECTIONS.includes(section);

      self.updatePlanIdOnMainPage(section, params);

      if (isStandardRP) {
        self.showDefaultRetirementPlannerView(
          isOnboardingRP && firstUseCompleted
        );
      }

      const isComparisonDisabled = section === "paycheck";
      const isNewScenarioDisabled = section === "paycheck";
      const checkPoorMarket = params?.poorMarket === "true";

      if (self.showModule("#forecast", generateSimulationMessages())) {
        if (isStandardRP) {
          self.clearPlanIdAndRenderSidebarPlans(
            section,
            isComparisonDisabled,
            isNewScenarioDisabled,
            isOnboardingRP
          );
        }

        const { default: ForecastView } = await import("forecastModule");
        self.activeModuleView = new ForecastView({
          section: section,
          planId: self.planId,
          hasRetirementPaycheck,
          isRetirementPlannerV2,
          isUserAggQual,
          isDelegate,
          checkPoorMarket,
          isRetirementCashFlowPlanning,
          isOnboardingRP: isOnboardingRP,
        });

        triggerHotjar();
      } else if (self.activeModuleView && self.activeModuleView.activeView) {
        if (isStandardRP) {
          self.updateAndRenderSidebarPlans(
            section,
            isComparisonDisabled,
            isNewScenarioDisabled,
            isDelegate,
            isFirstUseForecast
          );
        }
        self.activeModuleView.showSection(section);
      }

      // Forecast router will run again immediately after RP interview, to set the plan ID in URL.
      // Clear the cache again to make sure the interview won't run again.
      if (isFirstUseForecast) {
        Services.cache.clear("/api/profile/getUIPreferences");
      }
    });
  },

  async education(params = {}) {
    eventBus.trigger("updateDocumentTitle", "Edit Education Goal");

    if ($(SELECTOR_EDUCATION).length === 0) {
      $(SELECTOR_MODULE).append('<div id="education" class="module"></div>');
    }
    const goalKey = params.goalKey;
    const planId =
      (params.myLifePlanId && parseInt(params.myLifePlanId, 10)) || undefined;

    if (this.showModule(SELECTOR_EDUCATION)) {
      this.removePreloaderWithoutScroll();
      if (this.sidebar) {
        this.sidebar.renderSidebarPlans({ disabled: true });
      }
      const { default: EducationGoalPreviewContainer } = await import(
        "components/planner/education/EducationGoalPreview/EducationGoalPreviewContainer"
      );
      this.activeModuleView = new BackboneReactView({
        el: SELECTOR_EDUCATION,
        component: EducationGoalPreviewContainer,
        componentProps: {
          goalKey,
          planId,
        },
      });
    } else {
      this.activeModuleView.update({
        goalKey,
        planId,
      });
    }
  },

  savingsPlanner: function (section) {
    Services.Profile.getUIPreferences(async (err, response) => {
      // Used to enable full version of Savings Planner for prospects
      const hasFullSavingsPlannerFeature = Boolean(
        response?.spData?.enabledFPTopicsList?.includes(
          "Personal Savings Strategy"
        )
      );
      const isFirstUse = Boolean(
        response?.spData?.uiPreferences?.firstTimePersonalSavingsStrategyWizard
      );
      if (hasFullSavingsPlannerFeature && isFirstUse) {
        this.navigate("#/savings-strategy-wizard");
        return;
      }
      if ($(SELECTOR_SAVINGS_PLANNER).length === 0) {
        $(SELECTOR_MODULE).append(
          '<div id="savingsPlanner" class="module"></div>'
        );
      }

      eventBus.trigger("updateDocumentTitle", "Savings Planner");
      const getPerson = promisify(Services.Person.get);
      if (this.showModule(SELECTOR_SAVINGS_PLANNER)) {
        const { default: SavingsPlannerContainer } = await import(
          "components/savingsPlanner/SavingsPlannerContainer"
        );
        getPerson().then((person) => {
          this.isPersonRetired = Boolean(person?.isRetired);
          this.removePreloaderWithoutScroll();
          this.activeModuleView = new BackboneReactView({
            el: SELECTOR_SAVINGS_PLANNER,
            component: SavingsPlannerContainer,
            componentProps: {
              section,
              isRetired: this.isPersonRetired,
              hasFullSavingsPlannerFeature,
            },
          });
        });
      } else {
        this.activeModuleView.update({
          section,
          isRetired: this.isPersonRetired,
          hasFullSavingsPlannerFeature,
        });
      }
    });
  },

  // By design, this module has no corresponding route
  financialPlanOffer: function () {
    const previousRoute = getPreviousRouteHash();
    if (!this.finPlanOfferLauncherNode) {
      const onModalClosed = () => {
        this.navigate(previousRoute);
        this.removeFinancialPlanOffer();
      };
      const props = { onModalClosed };
      this.finPlanOfferLauncherNode = $(ELEMENT_DIV).appendTo("body").get(0);
      ReactDOM.render(
        React.createElement(FinancialPlanOfferModal, props),
        this.finPlanOfferLauncherNode
      );
    }
  },
  removeFinancialPlanOffer: function () {
    if (this.finPlanOfferLauncherNode) {
      ReactDOM.unmountComponentAtNode(this.finPlanOfferLauncherNode);
      this.finPlanOfferLauncherNode.parentNode.removeChild(
        this.finPlanOfferLauncherNode
      );
      this.finPlanOfferLauncherNode = null;
      this.finPlanOfferPreviousRoute = null;
    }
  },
  tollGateOffRampPage: function (referralOnboardingTest, referrerName) {
    if (!this.tollGateOffRampPageView) {
      this.appointment({
        isTollGateOffRampPageView: true,
        referralOnboardingTest,
        referrerName,
      });
      $(".js-first-use-skip-for-now").show();

      // Change "Skip for now" button text depending on screen width
      if ($(window).width() <= MOBILE_WIDTH) {
        $(".js-first-use-skip-for-now").text("Skip");
      }

      $(window).on("resize", function () {
        if ($(window).width() <= MOBILE_WIDTH) {
          $(".js-first-use-skip-for-now").text("Skip");
        } else {
          $(".js-first-use-skip-for-now").text("Skip for now");
        }
      });

      window.localStorage.setItem("onTollGateOffRampPage", "1");
    }
  },
  removeTollGateOffRampPageView: function () {
    if (this.tollGateOffRampPageView) {
      ReactDOM.unmountComponentAtNode(this.tollGateOffRampPageView);
      this.tollGateOffRampPageView.parentNode.removeChild(
        this.tollGateOffRampPageView
      );
      this.tollGateOffRampPageView = null;
    }
    window.localStorage.setItem("onUserGoalsPage", "1");
  },
  crankWheelInstantDemo: function () {
    fireGoogleTagManagerEvent("LOAD_CRANK_WHEEL_INSTANT_DEMO"); // Load setup script via GTM
    ComponentAnalytics.trackEvent(
      "CrankWheel Instant Demo",
      "View CrankWheel Instant Demo"
    );
    this.navigate(
      getPreviousRouteHash({
        hashesToReplaceWithDashboard: ["#/demo"],
      })
    );
  },

  async recommendations(section) {
    eventBus.trigger("updateDocumentTitle", "Recommendations");
    if ($(SELECTOR_RECOMMENDATIONS).length === 0) {
      $(SELECTOR_MODULE).append(
        '<div id="recommendations" class="module webapp3"></div>'
      );
    }
    var self = this;
    if (_.isUndefined(section) || !section.length) {
      section = "index";
    }

    if (self.showModule(SELECTOR_RECOMMENDATIONS)) {
      const { default: RecommendationsModule } = await import(
        "recommendationsModule"
      );
      self.activeModuleView = new RecommendationsModule({
        el: SELECTOR_RECOMMENDATIONS,
        displayPageTitle: true,
      });
      Mixpanel.trackEvent("View Recommendations");
    }
  },
  contactUs: function (queryString) {
    const onModalClosed = function () {
      if (this.contactUsNode) {
        ReactDOM.unmountComponentAtNode(this.contactUsNode);
        this.contactUsNode.parentNode.removeChild(this.contactUsNode);
        this.contactUsNode = null;
      }
      const previousRoute = getPreviousRouteHash({
        stepsToGoBack: 2,
        hashesToReplaceWithDashboard: ["#/contact-us"],
      });
      window.AppRouter.navigate(previousRoute);
    }.bind(this);
    this.contactUsNode = $(ELEMENT_DIV).appendTo("body").get(0);
    const props = {
      onCancel: onModalClosed,
      destination: queryString && queryString.destination,
      emailSubject: queryString && queryString.subject,
      emailBody: queryString && queryString.body,
    };

    ReactDOM.render(
      React.createElement(ContactUsFormModal, props),
      this.contactUsNode
    );
  },
  getReferralLink: function () {
    eventBus.trigger("updateDocumentTitle", "Get Referral Link");
    const onModalClosed = function () {
      const previousRoute = getPreviousRouteHash({
        stepsToGoBack: 2,
        hashesToReplaceWithDashboard: ["#/get-referral-link"],
      });
      window.AppRouter.navigate(previousRoute);
    };
    new getReferralLinkView({ onModalClosed });
  },
  showFrame: function (href) {
    var self = this;
    if (this.activeModuleID === "#iframe") {
      $("#iframeFrame")[0].src = self.frames[href];
    } else {
      this.showModule("#iframe", true);
      this.activeModuleView = new ContentIframeView(self.frames[href]);
    }
  },
  showAddAccount: function (path, params) {
    Services.Profile.getUIPreferences(function (err, response) {
      var cryptoFeatureFlag =
        response &&
        response.spData &&
        response.spData.features &&
        response.spData.features.CRYPTO_CURRENCY;
      const isResumeLinkingEnabled =
        response.spData?.features?.AGGR_RESUME_LINKING;
      const useUnifiedMar = response?.spData?.features?.USE_UNIFIED_MAR;
      const isConnectivityTrackerEnabled =
        response?.spData?.features?.ENABLE_CONNECTIVITY_TRACKER;
      // add modal box to body if it does not exists
      if ($("#linkAccountModal").length === 0) {
        $("#modalContent").append('<div id="linkAccountModal"></div>');
      }
      // normalize if params are passed at root level eg: accounts/add?productId=123
      if (_.isObject(path)) {
        params = path;
        path = undefined;
      }

      params = params || {};
      params.cryptoFeatureFlag = cryptoFeatureFlag;
      params.isResumeLinkingEnabled = isResumeLinkingEnabled;
      params.useUnifiedMar = useUnifiedMar;
      params.isConnectivityTrackerEnabled = isConnectivityTrackerEnabled;
      new LinkAccountModal(path, params);
      eventBus.trigger("updateDocumentTitle", "Link Account");
    });
  },
  async showFirstUse(path) {
    if (!isFirstUse()) {
      this.navigate(DASHBOARD_URL);
      // If user comes from reminder email link that asks her to add first FI
      if (path === "investment/add") {
        this.navigate("#/accounts/add/investment");
      }
      return;
    }

    // Hide l-content
    $(".l-content").hide();
    if (this.activeModuleID === "#firstuse") {
      this.activeModuleView.close();
      this.activeModuleView = null;
      var sidebar = $(".js-sidebar").addClass("u-invisible");
      $('<div id="firstUseModule"></div>').insertBefore(sidebar);
    }
    let socialProofFlag = false;
    let marketingABTesting = {};
    try {
      const { features, marketingABTestingFeatures } =
        await fetchUIPreferences();
      socialProofFlag = features?.WEB_SOCIAL_PROOF;
      marketingABTesting = marketingABTestingFeatures ?? {};
    } catch (error) {
      this.handleFetchUIPreferencesError(error);
    }
    const attributes = await fetchFunnelAttributes();
    const retirementPlannerOnboardingFeatureFlag = getFeatureFlagData(
      marketingABTesting,
      "retirementPlannerOnboarding"
    );
    const isOnboardingRP =
      retirementPlannerOnboardingFeatureFlag === AB_TEST_GROUP_A;
    if (attributes.marketingId === "utmcct=customretirement") {
      if (isOnboardingRP && !isMobileDevice()) {
        Mixpanel.trackEvent("DirectToRP_Test");
        Mixpanel.updateProfile({ TestGroupID: "DirectToRP_Test" }, null, true);
        window.skipLinkAccount = true;
        const person = getFromSessionStorage("firstUsePersonData");
        if (
          !window.isQuestionnaireAnswered ||
          (!person?.income?.salary &&
            !person?.annualSpendingGoal?.userInputAnnualSpendingGoal)
        ) {
          window.PCAP.skipFirstUse = true;
          $("#firstUseModule").hide();
          $(".l-content").show();
          this.collapseSidebar();
          $(".js-sidebar").removeClass("u-invisible");
          this.navigate("#/retirement-planner-onboarding");
          window.sessionStorage.removeItem("firstUsePersonData");
          return;
        }
      } else {
        Mixpanel.trackEvent("DirectToRP_Control");
        Mixpanel.updateProfile(
          { TestGroupID: "DirectToRP_Control" },
          null,
          true
        );
        window.sessionStorage.removeItem("firstUsePersonData");
      }
    }

    this.activeModuleView = new FirstUseView2(
      path,
      socialProofFlag,
      marketingABTesting
    );
    this.activeModuleID = "#firstuse";

    const documentTitle = `Welcome to Empower`;

    eventBus.trigger("updateDocumentTitle", documentTitle);
  },

  async retirementPlannerOnboarding() {
    this.forecast("index");
  },

  enrollNowModal: function () {
    // add modal box to body if it does not exists
    if ($("#enrollNowModal").length === 0) {
      $("#modalContent").append('<div id="enrollNowModal"></div>');
    }
    new EnrollNowModal();
    eventBus.trigger("updateDocumentTitle", "Enroll Now");
  },
  showModule: function (moduleID, messages) {
    this.destroyActiveModalDialog();

    if (moduleID === this.activeModuleID) {
      return false;
    }

    var isFirstUse = this.checkForFirstUse();
    if (isFirstUse) {
      return;
    }

    this.addPreloader(messages);
    this.orphanedModuleView = this.activeModuleView;
    this.orphanedModuleID = this.activeModuleID;
    this.highlightMenu();
    // To fix PFM-3422, set initial height to 0, it will be restored to `auto` when preloader is removed
    $(moduleID).show().css({ "z-index": 100, height: 0 });
    this.activeModuleID = moduleID;
    this.scrollToTopPreservingXCoordinate();

    return true;
  },
  checkForFirstUse: function () {
    if (isFirstUse()) {
      this.navigate("#/firstuse");
      return true;
    }
    $("#firstUseModule").hide();
    $(".l-content").show();
    return false;
  },

  /**
   * Covers the viewport with the modal overlay.
   *
   * @param  {Array} [messages] An optional array of messages.
   *                            If not provided, the default ones will be used.
   * @param  {Object} options   Optional settings object
   */
  addPreloader: function (messages, options = {}) {
    AppOverlay.show(messages, options);
  },

  /**
   * Removes the "Loading..." modal overlay. Destroys the active view.
   * @param  {boolean} [preserveView] an optional flag to preserve the view.
   */
  removePreloader: function (preserveView) {
    if (!preserveView && this.orphanedModuleView) {
      this.orphanedModuleView.close();
      this.orphanedModuleView = null;
      $(SELECTOR_MODULE).append(
        '<div id="' +
          this.orphanedModuleID.substr(1) +
          '" class="module"></div>'
      );
    }
    AppOverlay.hide();
    $(this.activeModuleID)
      .show()
      .css({ "z-index": 200 })
      .css({ opacity: 1, visibility: "visible", height: "auto" });
    $(SELECTOR_MODULE).scrollTop(0);
    eventBus.trigger("moduledisplayed", this.activeModelView);
  },
  removePreloaderWithoutScroll: function () {
    if (this.orphanedModuleView) {
      this.orphanedModuleView.close();
      this.orphanedModuleView = null;
      $(SELECTOR_MODULE).append(
        '<div id="' +
          this.orphanedModuleID.substr(1) +
          '" class="module"></div>'
      );
    }
    AppOverlay.hide();
    $(this.activeModuleID)
      .show()
      .css({ "z-index": 200 })
      .css({ opacity: 1, visibility: "visible", height: "auto" });
    eventBus.trigger("moduledisplayed", this.activeModelView);
  },
  highlightMenu: function () {
    var urlHash = Backbone.history.getHash();
    // reset all menu items
    $("a.menuItem", SELECTOR_MENU).removeClass("liDroppedDown");
    if (urlHash === "/401k") {
      $('a[data-menu-name="investing"]', SELECTOR_MENU).addClass(
        "liDroppedDown"
      );
    } else {
      $('a[data-menu-name="home"]', SELECTOR_MENU).addClass("liDroppedDown");
    }
  },
  updateDocumentTitle: function (title) {
    const documentTitle = `Empower - ${title}`;

    $(document).attr("title", documentTitle);
    analytics.sendPageView(Backbone.history.getHash());
  },
  handlePostMessage: function (data) {
    if (data.type === "cw.session.ended") {
      this.isMeetingActive = false;
      this.navigate(DASHBOARD_URL);
    }
  },
  /*
   * Update user messages as viewed when a user clicks on an action
   * from a user message that was sent via email
   */
  updateOutOfAppNotifications: function () {
    var hashAry = location.hash.split("?"),
      allowedActions = ["markViewed"];

    if (hashAry.length > 1) {
      var queryparams = $.pcap.getQueryStringParams(hashAry[1]);
      if (
        queryparams &&
        !isNaN(queryparams.messageId) &&
        _.contains(allowedActions, queryparams.action)
      ) {
        // update usermessage
        Services.UserMessages.updateV2({
          userMessageIds: "[" + queryparams.messageId + "]",
          actions: '["' + queryparams.action + '"]',
        });
      }
    }
  },

  referralCenter: function () {
    getAbFrameworkPromise({
      path: "referralCenter",
    }).then((refCenterProperties) => {
      if (refCenterProperties.showReferralCenterIframe) {
        if ($(`#${TALKABLE_REFERRAL_CENTER_ID}`).length) {
          this.showReferralCenter(ReferralCenterViewIframe);
        } else {
          $(SELECTOR_MODULE).append(
            `<div id="${TALKABLE_REFERRAL_CENTER_ID}" class="module"></div>`
          );
          if (this.isTalkableScriptLoaded) {
            this.showReferralCenter(ReferralCenterViewIframe);
          } else {
            $.getScript(TALKABLE_CLIENT_JS_URL, () => {
              this.isTalkableScriptLoaded = true;
              this.showReferralCenter(ReferralCenterViewIframe);
            });
          }
        }
      } else if ($(`#${TALKABLE_REFERRAL_CENTER_ID}`).length) {
        this.showReferralCenter();
      } else {
        $(SELECTOR_MODULE).append(
          `<div id="${TALKABLE_REFERRAL_CENTER_ID}" class="module"></div>`
        );
        this.showReferralCenter();
      }
    });
  },

  showReferralCenter: function (ReferralCenterView = ReferralCenterViewNative) {
    const referralCenterSelector = `#${TALKABLE_REFERRAL_CENTER_ID}`;
    if (this.showModule(referralCenterSelector)) {
      eventBus.trigger("updateDocumentTitle", "Referral Center");
      this.addPreloader();
      this.activeModuleView = new ReferralCenterView({
        $el: $(referralCenterSelector),
        talkableSiteId: TALKABLE_SITE_ID,
        email: window.username,
        firstName: window.usernameFirst,
        lastName: window.usernameLast,
        userGuid: window.userGuid,
        baseUrl: window.baseUrl,
        bundleVersion: window.staticVersion,
      }).once("doneLoading", () => {
        this.removePreloader();
      });
    }
  },

  shareWithFriends: function () {
    if (!this.shareModalLauncherNode) {
      var previousRoute = getPreviousRouteHash();
      var onModalClosed = function () {
        window.AppRouter.navigate(previousRoute);
        this.removeShareWithFriendsModal();
      }.bind(this);
      this.shareModalLauncherNode = $(ELEMENT_DIV).appendTo("body").get(0);
      var props = { onModalClosed: onModalClosed };
      if (this.isTalkableScriptLoaded) {
        ReactDOM.render(
          React.createElement(ShareWithFriendsModal, props),
          this.shareModalLauncherNode
        );
      } else {
        $.getScript(TALKABLE_CLIENT_JS_URL, () => {
          this.isTalkableScriptLoaded = true;
          ReactDOM.render(
            React.createElement(ShareWithFriendsModal, props),
            this.shareModalLauncherNode
          );
        });
      }
    }
  },

  removeShareWithFriendsModal: function () {
    if (this.shareModalLauncherNode) {
      ReactDOM.unmountComponentAtNode(this.shareModalLauncherNode);
      this.shareModalLauncherNode.parentNode.removeChild(
        this.shareModalLauncherNode
      );
      this.shareModalLauncherNode = null;
    }
  },

  async transferManager(section) {
    const back = () => {
      const previousRoute = getPreviousRouteHash({ stepsToGoBack: 2 });
      this.navigate(previousRoute);
    };
    const VALID_SECTIONS = ["active", "history", "recurring"];
    let isTaxWithholdingEnabled = false;

    try {
      const { features } = await fetchUIPreferences();
      isTaxWithholdingEnabled = features?.WEB_RETIREMENT_WITHHOLDING;
      // TODO: Update when ready
    } catch (error) {
      this.handleFetchUIPreferencesError(error);
    }

    if (!section) section = "active";

    if (!VALID_SECTIONS.includes(section)) back();
    if ($(SELECTOR_TRANSFER_MANAGER).length === 0) {
      $(SELECTOR_MODULE).append(
        '<div id="transfer-manager" class="module"></div>'
      );
    }

    eventBus.trigger("updateDocumentTitle", "Transfer Manager");

    if (this.showModule(SELECTOR_TRANSFER_MANAGER)) {
      this.activeModuleView = new BackboneReactView({
        el: SELECTOR_TRANSFER_MANAGER,
        component: TransferManagerContainer,
        componentProps: {
          section,
          isTaxWithholdingEnabled,
        },
      });

      window.dispatchEvent(new CustomEvent("pageloaded"));
    } else {
      this.activeModuleView.update({ section });
    }
  },

  async strategy(section) {
    eventBus.trigger("updateDocumentTitle", "Strategy");
    if ($("#strategy").length === 0) {
      $(SELECTOR_MODULE).append('<div id="strategy" class="module"></div>');
    }

    if (!section) {
      section = "overview";
    }
    if (this.showModule("#strategy")) {
      let showTaxBenefit = false;
      try {
        const { uiPreferences } = await fetchUIPreferences();
        showTaxBenefit =
          uiPreferences?.["personalStrategy.enableGainAndLoss"] || false;
      } catch (error) {
        this.handleFetchUIPreferencesError(error);
      }
      const { default: StrategyView } = await import(
        "views/modules/strategy/strategy"
      );
      this.activeModuleView = new StrategyView({
        section: section,
        isAdvisorApp: false,
        showTaxBenefit,
      });
      if (typeof hj !== "undefined") {
        hj("trigger", "strategyFeedback");
      }
    } else {
      this.activeModuleView.updateView(section);
    }
  },

  trackRouteHistory: function () {
    var history = this.history;
    Backbone.history.on("route", function () {
      history.push({
        hash: window.location.hash,
      });
    });
  },

  proposal: function () {
    var iframeHeight =
      $(SELECTOR_SIDEBAR).height() -
      $(SELECTOR_MENU).height() -
      $(SELECTOR_FOOTER).height() -
      21; // eslint-disable-line no-magic-numbers

    $("#proposal-frame")
      .css({
        height: iframeHeight,
        width: "100%",
        border: 0,
        overflow: "hidden",
      })
      .attr("src", "/page/login/proposal")
      .on("load", function () {
        this.contentWindow.focus(); // eslint-disable-line no-invalid-this
      });

    $(document.body).addClass(CLASS_FULLSIZE_MODULE);
    this.showModule("#proposal");
    this.removePreloader();

    if (this.sidebar) {
      this.sidebar.collapse();
      this.sidebar.showCollapsedView = true;
    }
  },

  handleEnrollmentClick: function (e) {
    if (this.isMeetingActive) {
      e.preventDefault();
      e.stopPropagation();
      const link = $(e.target).attr("href");
      this.showMeetingLeaveDialog(function () {
        location.href = link;
      });
    }
  },
  async dashboardWelcome() {
    const id = "DashboardWelcomeModal";
    if (!this.isActiveModalDialog(id)) {
      AppOverlay.show();
      const { default: DashboardWelcomeModal } = await import(
        "views/components/firstUse/DashboardWelcomeModal/DashboardWelcomeModal"
      );
      const onModalClosed = () => {
        Mixpanel.trackEvent("Click Demo Dashboard Welcome Modal Get Started");
        window.AppRouter.navigate(DASHBOARD_URL);
      };
      const componentProps = {
        isOpen: true,
        onDone: onModalClosed,
      };
      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: DashboardWelcomeModal,
        })
      );
      AppOverlay.hide();
    }
  },
  async planningTopics(params) {
    const id = "FPQuestionnaireModal";
    const milestoneId = params?.userMilestoneId;
    const targetTopicTop = params?.targetTopicTop;

    if (!this.isActiveModalDialog(id)) {
      const previousRoute = getPreviousRouteHash();
      AppOverlay.show();
      const { default: FPQuestionnaireModal } = await import(
        "components/ClientJourney/TopicQuestionnaire/FPQuestionnaireModal"
      );
      const onModalClosed = () => {
        window.AppRouter.navigate(previousRoute);
      };
      const componentProps = {
        userMilestoneId: milestoneId,
        onDone: onModalClosed,
        isOpen: true,
        targetTopicTop,
      };
      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: FPQuestionnaireModal,
        })
      );
      AppOverlay.hide();
    }
  },

  async employerPlanAnalysis(params) {
    const id = "EmployerPlanAnalysisModal";
    if (!this.isActiveModalDialog(id)) {
      let previousRoute = getPreviousRouteHash();
      AppOverlay.show();
      const { default: EmployerPlanAnalysisModal } = await import(
        "components/ClientJourney/EmployerPlanAnalysis/EmployerPlanAnalysisModal"
      );
      const onDone = (updatedMilestone, accountId) => {
        window.AppRouter.navigate(previousRoute);
        if (updatedMilestone) {
          eventBus.trigger(EVENT_MILESTONES_UPDATED, updatedMilestone);
        }
        if (accountId) {
          window.location.assign(
            `${
              window.baseUrl
            }page/login/app?time=${new Date().getTime()}#/accounts/details?ua=${accountId}&tabId=recommendation`
          );
        }
      };

      const componentProps = {
        userMilestoneId: params?.userMilestoneId,
        onDone,
        isOpen: true,
      };
      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: EmployerPlanAnalysisModal,
        })
      );
      AppOverlay.hide();
    }
  },

  async emergencyFundWizard(params) {
    const id = "EmergencyFundModal";
    if (!this.isActiveModalDialog(id)) {
      let previousRoute = getPreviousRouteHash();
      const { default: EmergencyFundModal } = await import(
        "components/ClientJourney/EmergencyFund/EmergencyFundModal"
      );
      const onDone = (updatedMilestone) => {
        window.AppRouter.navigate("#/savings-planner/emergency-fund");
        if (previousRoute.includes("#/savings-planner/emergency-fund")) {
          window.location.reload();
        }
        if (updatedMilestone) {
          eventBus.trigger(EVENT_MILESTONES_UPDATED, updatedMilestone);
        }
      };
      const onModalClosed = () => {
        window.AppRouter.navigate(previousRoute);
      };
      const componentProps = {
        userMilestoneId: params?.userMilestoneId,
        onDone,
        onModalClosed,
        isOpen: true,
      };

      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: EmergencyFundModal,
        })
      );
      AppOverlay.hide();
    }
  },

  async savingsStrategyWizard(params) {
    const id = "PersonalSavingsStrategyWizard";
    if (!this.isActiveModalDialog(id)) {
      const previousRoute = getPreviousRouteHash();
      AppOverlay.show();
      const { default: PersonalSavingsStrategyWizard } = await import(
        "components/ClientJourney/PersonalSavingsStrategy/PersonalSavingsStrategyWizard"
      );
      const onDone = (updatedMilestone) => {
        this.navigate("#/savings-planner");
        if (previousRoute?.includes("#/savings-planner")) {
          window.location.reload();
        }
        if (updatedMilestone) {
          eventBus.trigger(EVENT_MILESTONES_UPDATED, updatedMilestone);
        }
      };
      const onModalClosed = () => {
        window.AppRouter.navigate(previousRoute);
      };
      const componentProps = {
        userMilestoneId: params?.userMilestoneId,
        onDone,
        onModalClosed,
        isOpen: true,
      };
      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: PersonalSavingsStrategyWizard,
        })
      );
      AppOverlay.hide();
    }
  },

  async requestAdviceModal(params) {
    if (!params || !params.userMilestoneId) {
      return;
    }
    const id = "RequestAdviceModal";
    if (!this.isActiveModalDialog(id)) {
      let previousRoute = getPreviousRouteHash();
      AppOverlay.show();
      const { default: RequestAdviceModal } = await import(
        "components/ClientJourney/RequestAdvice/RequestAdviceModal"
      );
      const onDone = (updatedUserMilestone) => {
        if (updatedUserMilestone) {
          eventBus.trigger(EVENT_MILESTONES_UPDATED, updatedUserMilestone);
        }
        window.AppRouter.navigate(previousRoute);
      };
      const componentProps = {
        userMilestoneId: params.userMilestoneId,
        onDone,
      };
      this.showModalDialog(
        id,
        new BackboneReactView({ componentProps, component: RequestAdviceModal })
      );
      AppOverlay.hide();
    }
  },

  async strategyReviewWizard(params) {
    const id = "StrategyReviewWizardModal";
    if (!this.isActiveModalDialog(id)) {
      let previousRoute = getPreviousRouteHash();
      AppOverlay.show();
      const { default: StrategyReviewWizardModal } = await import(
        "components/PersonalStrategy/Recommendation/StrategyReviewWizardModal"
      );

      const componentProps = this.getStrategyReviewWizardProps(
        params,
        previousRoute
      );

      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: StrategyReviewWizardModal,
        })
      );
      AppOverlay.hide();
    }
  },

  async debtReviewWizard(params) {
    const id = "DebtReviewWizard";
    if (!this.isActiveModalDialog(id)) {
      let previousRoute = getPreviousRouteHash();
      AppOverlay.show();
      const { default: DebtReviewWizard } = await import(
        "components/ClientJourney/DebtManagementReview/DebtReviewWizard/DebtReviewWizard"
      );
      const onDone = (updatedMilestone) => {
        this.navigate("#/savings-planner/debt-paydown");
        if (previousRoute?.includes("#/savings-planner/debt-paydown")) {
          window.location.reload();
        }
        if (updatedMilestone) {
          eventBus.trigger(EVENT_MILESTONES_UPDATED, updatedMilestone);
        }
      };
      const onModalClosed = () => {
        this.navigate(previousRoute);
      };

      const componentProps = {
        userMilestoneId: params?.userMilestoneId,
        onDone,
        onModalClosed,
        isOpen: true,
      };
      this.showModalDialog(
        id,
        new BackboneReactView({ componentProps, component: DebtReviewWizard })
      );
      AppOverlay.hide();
    }
  },

  async equityCompensationWizard(params) {
    const id = "EquityCompensationWizardModal";
    if (!this.isActiveModalDialog(id)) {
      let previousRoute = getPreviousRouteHash();
      AppOverlay.show();
      const { default: EquityCompensationWizardModal } = await import(
        "components/ClientJourney/EquityCompensation/EquityCompensationWizardModal"
      );
      const onModalClosed = () => {
        window.AppRouter.navigate(previousRoute);
      };

      const handleDone = (shouldLinkAccount, updatedMilestone) => {
        if (updatedMilestone) {
          eventBus.trigger(EVENT_MILESTONES_UPDATED, updatedMilestone);
        }

        if (shouldLinkAccount) {
          AppRouter.navigate(ROUTE_ACCOUNTS_ADD, { replace: true });
        } else {
          onModalClosed();
        }
      };
      const componentProps = {
        onModalClosed,
        onDone: handleDone,
        isOpen: true,
        milestoneId: params?.userMilestoneId,
      };

      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: EquityCompensationWizardModal,
        })
      );
      AppOverlay.hide();
    }
  },

  async insuranceWizard(params) {
    const id = "InsuranceModal";
    if (!this.isActiveModalDialog(id)) {
      let previousRoute = getPreviousRouteHash();
      AppOverlay.show();
      const { default: InsuranceModal } = await import(
        "components/ClientJourney/Insurance/InsuranceModal/InsuranceModal"
      );

      const onModalClosed = () => {
        window.AppRouter.navigate(previousRoute);
      };
      const onDone = (updatedMilestone) => {
        this.handleWizardComplete(updatedMilestone, onModalClosed);
      };

      const componentProps = {
        onDone,
        onModalClosed,
        isOpen: true,
        milestoneId: params?.userMilestoneId,
      };

      this.showModalDialog(
        id,
        new BackboneReactView({ componentProps, component: InsuranceModal })
      );
      AppOverlay.hide();
    }
  },

  async riskToleranceWizard() {
    const id = "RiskToleranceWizardModal";
    if (!this.isActiveModalDialog(id)) {
      AppOverlay.show();
      const { default: RiskToleranceWizardModal } = await import(
        "components/PersonalStrategy/Recommendation/RiskToleranceWizardModal/RiskToleranceWizardModal"
      );
      const previousRoute = getPreviousRouteHash();
      const onModalClosed = () => {
        this.navigate(previousRoute);
      };
      const onDone = () => {
        if (previousRoute?.includes("#/settings/profile")) {
          this.navigate("#/settings/profile");
          window.location.reload();
        } else {
          this.navigate(ROUTE_STRATEGY_RECOMMENDATION);
          if (previousRoute?.includes(ROUTE_STRATEGY_RECOMMENDATION)) {
            window.location.reload();
          }
        }
      };
      const componentProps = { onDone, onClosed: onModalClosed, isOpen: true };
      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: RiskToleranceWizardModal,
        })
      );
      AppOverlay.hide();
    }
  },
  async emergencyFundAccounts() {
    const id = "AccountSelectionContainer";

    if (!this.isActiveModalDialog(id)) {
      const previousRoute = getPreviousRouteHash();
      AppOverlay.show();
      const { default: Modal } = await import("components/modal/Modal");
      const { default: AccountSelectionContainer } = await import(
        "components/ClientJourney/EmergencyFund/AccountSelection/AccountSelectionContainer"
      );
      const onModalClosed = () => {
        window.AppRouter.navigate(previousRoute);
      };

      const componentProps = {
        isOpen: true,
        title: "Emergency Fund Accounts",
        className: "pc-modal--small",
        componentName: "Emergency Fund Accounts Modal",
        onClosed: onModalClosed,
        children: (
          <AccountSelectionContainer
            hasBack={false}
            onDone={onModalClosed}
            doneLabelText={"Save"}
          />
        ),
      };
      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: Modal,
        })
      );
      AppOverlay.hide();
    }
  },

  async socialSecurityWizard(params) {
    const id = "SocialSecurityWizardModal";
    const previousRoute = getPreviousRouteHash();
    const onModalClosed = () => {
      window.AppRouter.navigate(previousRoute);

      //always reload to reflect retirement plan check edits when closed before or after wizard completes
      if (
        previousRoute.includes("#/settings") ||
        previousRoute.includes("#/retirement-planner")
      ) {
        window.location.reload();
      }
    };
    const onDone = (updatedMilestone) => {
      this.handleWizardComplete(updatedMilestone, onModalClosed);
    };
    const componentProps = {
      onDone,
      onModalClosed,
      isOpen: true,
      milestoneId: params?.userMilestoneId,
    };

    if (!this.isActiveModalDialog(id)) {
      AppOverlay.show();
      const { default: SocialSecurityWizardModal } = await import(
        "components/ClientJourney/SocialSecurity/SocialSecurityWizardModal"
      );
      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: SocialSecurityWizardModal,
        })
      );
      AppOverlay.hide();
    }
  },

  async planningHistory(params) {
    if ($(SELECTOR_PLANNING_HISTORY).length === 0) {
      $(SELECTOR_MODULE).append(
        '<div id="planningHistory" class="module"></div>'
      );
    }
    const activeMilestoneId = params?.activeMilestoneId;
    if (this.showModule(SELECTOR_PLANNING_HISTORY)) {
      const { default: PlanningHistoryContainer } = await import(
        "components/ClientJourney/PlanningHistory/PlanningHistoryContainer"
      );
      this.removePreloaderWithoutScroll();
      this.activeModuleView = new BackboneReactView({
        el: SELECTOR_PLANNING_HISTORY,
        component: PlanningHistoryContainer,
        componentProps: { activeMilestoneId },
      });

      ComponentAnalytics.trackView("Planning History Views", {
        component: "Planning History",
      });
    } else {
      this.activeModuleView.update({ activeMilestoneId });
    }
  },

  viewMeeting: function (meetingId) {
    let self = this;

    eventBus.trigger("updateDocumentTitle", "Meeting Viewer");
    this.isMeetingActive = true;
    this.meetingId = meetingId;
    var iframeHeight =
      $(SELECTOR_SIDEBAR).height() -
      $(SELECTOR_MENU).height() -
      $(SELECTOR_FOOTER).height() -
      21; // eslint-disable-line no-magic-numbers

    $('a[href="/page/enrollment/start"]').on(
      "click.meetinglock",
      this.handleEnrollmentClick.bind(this)
    );

    ComponentAnalytics.trackEvent("View Meeting", "View Meeting Route Loaded", {
      meetingId,
    });

    if (this.showModule("#meeting")) {
      $("#meeting-frame").remove();
      const crankWheelLinkRebranding = "https://empower.crankwheel.com/empower";
      let iframe = $(
        `<iframe src="${crankWheelLinkRebranding}?hl=en&c=${meetingId}" id="meeting-iframe" />`
      )
        .css({
          height: iframeHeight,
          width: "100%",
          border: 0,
          overflow: "hidden",
        })
        .on("load", function () {
          this.contentWindow.focus(); // eslint-disable-line no-invalid-this
          self.removePreloader();
          ComponentAnalytics.trackEvent(
            "View Meeting",
            "View Meeting Iframe Loaded",
            { meetingId }
          );
        });
      $("#meeting").append(iframe);

      this.keepAliveTimer = setInterval(() => {
        Services.Profile.getUIPreferencesNoCache();
      }, KEEP_ALIVE_INTERVAL);

      $(window).resize(
        _.debounce(function () {
          var iframeHeight =
            $(SELECTOR_SIDEBAR).height() -
            $(SELECTOR_MENU).height() -
            $(SELECTOR_FOOTER).height() -
            21; // eslint-disable-line no-magic-numbers
          $("#meeting-iframe").css({ height: iframeHeight });
        }, 100)
      );

      $(document.body).addClass(CLASS_FULLSIZE_MODULE);
    }
    if (this.sidebar) {
      this.sidebar.collapse();
      this.sidebar.showCollapsedView = true;
    }
  },
  /* eslint-disable sonarjs/cognitive-complexity */
  async appointment(
    {
      isFirstUseAppointment = false,
      isMadLibsInterjectionReviewModal = false,
      type = undefined,
      subtypes = undefined,
      duration = undefined,
      isTollGateOffRampPageView = false,
      referralOnboardingTest = "",
      referrerName = "",
      isOnboardingAQ = false,
    } = {},
    previousRoute
  ) {
    const isFirstUse = this.checkForFirstUse();
    if (
      isFirstUse &&
      !isFirstUseAppointment &&
      !isMadLibsInterjectionReviewModal &&
      !isTollGateOffRampPageView
    ) {
      return;
    }
    const is401kEnrollment = type === "ENROLLMENT_401K";

    // If the appointment type in the query params is for 401K Enrollment but the user
    // is not eligible for the 401K Enrollment call type, don't pass it as a default value
    if (!is401kEnrollment && type === "ENROLLMENT_401K") {
      type = undefined;
    }

    if (!previousRoute) {
      previousRoute = getPreviousRouteHash();
    }

    // Appointment scheduling can be accessed via a deep-link. In that case there will an empty page in the background.
    // Check if there is no other page in the background and load the dashboard as a fall-back.
    if (!this.activeModuleID) {
      this.dashboard();
    }
    this.initializeAppointmentView(
      isFirstUseAppointment,
      isMadLibsInterjectionReviewModal,
      isOnboardingAQ
    );

    promisify(Services.Advisor.get, true)()
      .then(({ spData: advisor, spHeader: headers }) => {
        const clientStatus = isClient(
          objectPath.get(headers, "userStage"),
          objectPath.get(advisor, "status")
        );

        let source = GetSourceFromUrl();
        if (source) {
          AttributionStore.setSource(source);
        }

        if (isTollGateOffRampPageView) {
          const pageViewEl = document.createElement("div");
          $(".moduleContainer").get(0).append(pageViewEl);
          this.tollGateOffRampPageView = pageViewEl;
          const component = TollGateOffRampPageView;
          this.removePageView = this.removeTollGateOffRampPageView;

          new BackboneReactView({
            el: pageViewEl,
            component,
            componentProps: {
              onClose: () => {
                this.removePageView();
                this.navigateAfterAppointmentModalClosed(previousRoute);
              },
              isClient: clientStatus,
              advisorData: advisor,
              isFirstUseAppointment,
              isMadLibsInterjectionReviewModal,
              type,
              is401kEnrollment,
              subtypes: subtypes ? JSON.parse(subtypes) : undefined,
              duration: duration ? parseInt(duration, 10) : undefined,
              isTopicPreselected:
                type != null && subtypes != null && duration != null,
              hasOnboardingAppointmentPage: true,
              referralOnboardingTest,
              referrerName,
            },
          });
        } else {
          this.appointmentModal = new BackboneReactView({
            component: AppointmentModal,
            componentProps: {
              onClosed: () => {
                this.removeScheduleAppointment();
                this.navigateAfterAppointmentModalClosed(
                  previousRoute,
                  isOnboardingAQ
                );
              },
              isClient: clientStatus,
              advisorData: advisor,
              isFirstUseAppointment,
              isMadLibsInterjectionReviewModal,
              type,
              is401kEnrollment,
              subtypes: subtypes ? JSON.parse(subtypes) : undefined,
              duration: duration ? parseInt(duration, 10) : undefined,
              isTopicPreselected:
                type != null && subtypes != null && duration != null,
            },
          });
        }
      })
      .catch(() => {
        return;
      });
  },

  async openBankAccount(pageName) {
    eventBus.trigger("updateDocumentTitle", "Open Bank Account");
    if ($(SELECTOR_OPEN_BANK_ACCOUNT).length === 0) {
      $(SELECTOR_MODULE).append(
        '<div id="open-bank-account" class="module"></div>'
      );
    }

    const userAccountId = getUrlParameterByName("ua");
    if (this.showModule(SELECTOR_OPEN_BANK_ACCOUNT)) {
      const { default: OpenAccountContainer } = await import(
        "components/hysa/OpenAccountContainer"
      );
      this.activeModuleView = new BackboneReactView({
        el: SELECTOR_OPEN_BANK_ACCOUNT,
        componentProps: {
          pageName,
          userAccountId,
          onEnrollmentProcessed: () => {
            window.scrollTo(0, 0);
            this.expandSidebar(true);

            const transferFundsMenu = document.querySelector(
              ".js-main-menu-transfer-funds"
            );
            if (transferFundsMenu) {
              transferFundsMenu.classList.remove("is-hidden");
            }
          },
        },
        component: OpenAccountContainer,
      });
      this.collapseSidebar();
      if (typeof window.hj !== "undefined") {
        window.hj("trigger", "js-hj-personal-capital-cash");
        window.hj("tagRecording", ["Personal Capital Cash", window.userGuid]);
      }
    } else {
      this.activeModuleView.update({ pageName, userAccountId });
    }
    window.scrollTo(0, 0);
  },

  async closeBankAccount() {
    eventBus.trigger("updateDocumentTitle", "Close Account");
    if ($(SELECTOR_CLOSE_BANK_ACCOUNT).length === 0) {
      $(SELECTOR_MODULE).append(
        '<div id="close-bank-account" class="module"></div>'
      );
    }

    const cashAccountId = getUrlParameterByName("ua");
    if (this.showModule(SELECTOR_CLOSE_BANK_ACCOUNT)) {
      const { default: closeAccountContainer } = await import(
        "components/hysa/CloseAccountContainer"
      );
      this.activeModuleView = new BackboneReactView({
        el: SELECTOR_CLOSE_BANK_ACCOUNT,
        componentProps: {
          cashAccountId,
        },
        component: closeAccountContainer,
      });
      this.collapseSidebar();
    } else {
      this.activeModuleView.update({ cashAccountId });
    }
    window.scrollTo(0, 0);
  },

  async transferFunds(pageName) {
    let isTaxWithholdingEnabled = false;
    let isPSTaxWithholdingEnabled = false;
    let isRMDEnabled = false;
    try {
      const { features } = await fetchUIPreferences();
      isTaxWithholdingEnabled = features?.WEB_RETIREMENT_WITHHOLDING;
      isPSTaxWithholdingEnabled = features?.WEB_TAX_WITHDRAWAL;
      isRMDEnabled = features?.WEB_WITHDRAWAL_RMD;
    } catch (error) {
      this.handleFetchUIPreferencesError(error);
    }

    const fromAccountIdParam = getUrlParameterByName("from");
    const toAccountIdParam = getUrlParameterByName("to");
    const signedParam = getUrlParameterByName("signed");
    const transferType = getUrlParameterByName("transferType");
    const transferAmount = getUrlParameterByName("amount");
    const frequency = getUrlParameterByName("frequency");

    const docusignResult = signedParam ? signedParam === "true" : undefined;

    const model = {
      sourceAccountId:
        (fromAccountIdParam && parseInt(fromAccountIdParam, 10)) || undefined,
      targetAccountId:
        (toAccountIdParam && parseInt(toAccountIdParam, 10)) || undefined,
      transferAmount: parseFloat(transferAmount) || undefined,
      frequency,
    };

    const hasTargetAccount = Boolean(model.targetAccountId);
    eventBus.trigger("updateDocumentTitle", "Transfer Funds");

    let componentProps = {
      isToDropDownDisabled: hasTargetAccount,
      pageName,
      model,
      onSelectAccountWithAggregationError: (userSiteId) => {
        this.expandSidebar(true);
        SidebarModel.trigger("showSiteExtras", userSiteId);
      },
      onLinkAccount: () => {
        this.navigate(getPreviousRouteHash({ stepsToGoBack: 2 }), {
          trigger: true,
        });
        this.navigate(ROUTE_ACCOUNTS_ADD);
      },
      isOpen: true,
      onCancel: () => {
        this.navigate(getPreviousRouteHash({ stepsToGoBack: 2 }));
      },
      docusignResult,
      isTaxWithholdingEnabled,
      isPSTaxWithholdingEnabled,
      isRMDEnabled,
      transferType: parseInt(transferType, 10) || undefined,
    };

    const id = "TransferFundsModal";
    if (this.isActiveModalDialog(id)) {
      this.updateActiveModalDialog(componentProps);
    } else {
      // Show overlay for the async module import.
      // The component instance is handling its own loading state.
      AppOverlay.show();

      const { default: TransferFundsModal } = await import(
        "components/transferFunds/TransferFundsModal"
      );
      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: TransferFundsModal,
        })
      );
      AppOverlay.hide();
    }
  },

  async editAccount(userAccountId) {
    userAccountId = parseInt(userAccountId, 10);
    if (isNaN(userAccountId)) {
      this.navigate(getPreviousRouteHash());
      return;
    }

    let componentProps = {
      userAccountId,
      isOpen: true,
      onCancel: () => {
        this.navigate(getPreviousRouteHash({ stepsToGoBack: 2 }));
      },
      onSave: () => {
        this.navigate(getPreviousRouteHash({ stepsToGoBack: 2 }));
      },
    };

    const id = "EditAccountModal";
    if (this.isActiveModalDialog(id)) {
      this.updateActiveModalDialog(componentProps);
    } else {
      // Show overlay for the async module import.
      // The component instance is handling its own loading state.
      AppOverlay.show();

      const { default: EditAccountModal } = await import(
        "components/common/EditAccount/EditAccountContainer"
      );
      this.showModalDialog(
        id,
        new BackboneReactView({
          componentProps,
          component: EditAccountModal,
        })
      );
      AppOverlay.hide();
    }
  },

  async closedAccounts() {
    if ($(SELECTOR_CLOSED_ACCOUNTS).length === 0) {
      $(SELECTOR_MODULE).append(
        '<div id="closed-accounts" class="module"></div>'
      );
    }
    if (this.showModule(SELECTOR_CLOSED_ACCOUNTS)) {
      const { default: ClosedAccountsContainer } = await import(
        "components/ClosedAccounts/Container"
      );
      this.activeModuleView = new BackboneReactView({
        el: SELECTOR_CLOSED_ACCOUNTS,
        component: ClosedAccountsContainer,
      });
    } else {
      this.activeModuleView.update();
    }
  },

  removeScheduleAppointment() {
    this.appointmentModal.remove();
    this.appointmentModal = null;
  },

  async reconnectRetirementAccount(userAccountId) {
    const id = "ReconnectAccountModal";

    if (!this.isActiveModalDialog(id)) {
      AppOverlay.show();

      const { default: ReconnectAccountModal } = await import(
        "components/feex/ReconnectAccountModal/ReconnectAccountModal"
      );

      const onClose = () => {
        window.AppRouter.navigate(getPreviousRouteHash({ stepsToGoBack: 2 }));
      };

      this.showModalDialog(
        id,
        new BackboneReactView({
          component: ReconnectAccountModal,
          componentProps: {
            userAccountId,
            onClose,
          },
        })
      );

      AppOverlay.hide();
    }
  },

  async updateForwardingContactInfo(params) {
    const id = "ForwardingContactInfoModal";

    if (!this.isActiveModalDialog(id)) {
      AppOverlay.show();

      const { default: ForwardingContactInfoModal } = await import(
        "components/feex/ForwardingContactInfoModal/ForwardingContactInfoModal"
      );

      const onClose = () => {
        window.AppRouter.navigate(getPreviousRouteHash({ stepsToGoBack: 2 }));
      };

      this.showModalDialog(
        id,
        new BackboneReactView({
          component: ForwardingContactInfoModal,
          componentProps: {
            ...params,
            onClose,
          },
        })
      );

      AppOverlay.hide();
    }
  },

  async enrollRetirementAboutPage() {
    let feexServiceEnabled = false;
    let isFeeXEligible = false;
    try {
      const { features, uiPreferences } = await fetchUIPreferences();
      feexServiceEnabled = features?.USE_FEEX_SERVICE;
      isFeeXEligible = uiPreferences?.isEligibleForManageRetirementAccount;
    } catch (error) {
      this.handleFetchUIPreferencesError(error);
    }
    if (feexServiceEnabled) {
      if ($(SELECTOR_ENROLL_RETIREMENT_ABOUT_PAGE).length === 0) {
        $(SELECTOR_MODULE).append(
          '<div id="enrollRetirementAboutPage" class="module"></div>'
        );
      }
      if (this.showModule(SELECTOR_ENROLL_RETIREMENT_ABOUT_PAGE)) {
        const { default: EnrollmentAboutPage } = await import(
          "components/feex/EnrollmentAboutPage"
        );
        this.activeModuleView = new BackboneReactView({
          el: SELECTOR_ENROLL_RETIREMENT_ABOUT_PAGE,
          component: EnrollmentAboutPage,
          componentProps: {
            isFeeXEligible,
          },
        });
      } else {
        this.activeModuleView.update();
      }
      this.collapseSidebar();
    } else {
      const previousRoute = getPreviousRouteHash();
      this.navigate(previousRoute);
    }
  },

  async enrollRetirementIntroPage() {
    let feexServiceEnabled = false;
    let isFeeXEligible = false;
    let uiPreferencesResponse;
    const isRetirementNewClient = sessionStorage.getItem(
      "RETIREMENT_IS_NEW_CLIENT"
    );
    try {
      uiPreferencesResponse = await fetchUIPreferences();
      const { features, uiPreferences } = uiPreferencesResponse;
      feexServiceEnabled = features?.USE_FEEX_SERVICE;
      isFeeXEligible =
        isRetirementNewClient ||
        uiPreferences?.isEligibleForManageRetirementAccount;
    } catch (error) {
      this.handleFetchUIPreferencesError(error);
    }
    if (feexServiceEnabled && isFeeXEligible) {
      if ($(SELECTOR_ENROLL_RETIREMENT_INTRO_PAGE).length === 0) {
        $(SELECTOR_MODULE).append(
          '<div id="enrollRetirementIntroPage" class="module"></div>'
        );
      }
      if (this.showModule(SELECTOR_ENROLL_RETIREMENT_INTRO_PAGE)) {
        const { default: EnrollmentIntroPage } = await import(
          "components/feex/EnrollmentIntroPage"
        );
        this.activeModuleView = new BackboneReactView({
          el: SELECTOR_ENROLL_RETIREMENT_INTRO_PAGE,
          component: EnrollmentIntroPage,
          componentProps: {
            // eslint-disable-next-line no-undef
            isFeexStripeEnabled:
              uiPreferencesResponse?.features?.WEB_FEEX_STRIPE_INTEGRATION ||
              false,
          },
        });
      } else {
        this.activeModuleView.update();
      }
      this.collapseSidebar();
    } else {
      const previousRoute = getPreviousRouteHash();
      this.navigate(previousRoute);
    }
  },

  async enrollRetirementConfirmation(state) {
    let feexServiceEnabled = false;
    let isFeeXEligible = false;
    const isRetirementNewClient = sessionStorage.getItem(
      "RETIREMENT_IS_NEW_CLIENT"
    );
    try {
      const { features, uiPreferences } = await fetchUIPreferences();
      feexServiceEnabled = features?.USE_FEEX_SERVICE;
      isFeeXEligible =
        isRetirementNewClient ||
        uiPreferences?.isEligibleForManageRetirementAccount;
    } catch (error) {
      this.handleFetchUIPreferencesError(error);
    }
    if (feexServiceEnabled && isFeeXEligible) {
      if ($(SELECTOR_ENROLL_RETIREMENT_CONFIRMATION).length === 0) {
        $(SELECTOR_MODULE).append(
          '<div id="enrollRetirementConfirmation" class="module"></div>'
        );
      }
      if (this.showModule(SELECTOR_ENROLL_RETIREMENT_CONFIRMATION)) {
        const {
          EnrollmentConfirmationSuccess,
          EnrollmentConfirmationProcessing,
        } = await import("components/feex/EnrollmentConfirmationPage");

        let component;
        if (state === "processing") {
          component = EnrollmentConfirmationProcessing;
        } else {
          component = EnrollmentConfirmationSuccess;
        }

        this.activeModuleView = new BackboneReactView({
          el: SELECTOR_ENROLL_RETIREMENT_CONFIRMATION,
          component,
        });
      } else {
        this.activeModuleView.update();
      }
      this.collapseSidebar();
    } else {
      const previousRoute = getPreviousRouteHash();
      this.navigate(previousRoute);
    }
  },

  async enrollRetirementAccount(pageName) {
    let feexServiceEnabled = false;
    let isFeeXEligible = false;
    const isRetirementNewClient = sessionStorage.getItem(
      "RETIREMENT_IS_NEW_CLIENT"
    );
    try {
      const { features, uiPreferences } = await fetchUIPreferences();
      feexServiceEnabled = features?.USE_FEEX_SERVICE;
      isFeeXEligible =
        isRetirementNewClient ||
        uiPreferences?.isEligibleForManageRetirementAccount;
    } catch (error) {
      this.handleFetchUIPreferencesError(error);
    }
    if (feexServiceEnabled && isFeeXEligible) {
      if ($(SELECTOR_ENROLL_RETIREMENT_ACCOUNT).length === 0) {
        $(SELECTOR_MODULE).append(
          '<div id="enrollRetirementAccount" class="module"></div>'
        );
      }
      if (this.showModule(SELECTOR_ENROLL_RETIREMENT_ACCOUNT)) {
        const { default: EnrollmentWizardContainer } = await import(
          "components/feex/EnrollmentWizardContainer"
        );
        this.activeModuleView = new BackboneReactView({
          el: SELECTOR_ENROLL_RETIREMENT_ACCOUNT,
          component: EnrollmentWizardContainer,
          componentProps: {
            pageName,
          },
        });
      } else {
        this.activeModuleView.update({ pageName });
      }
      window.scrollTo(0, 0);
      this.collapseSidebar();
    } else {
      const previousRoute = getPreviousRouteHash();
      this.navigate(previousRoute);
    }
  },
  handleWizardComplete: function (updatedMilestone, onModalClosed) {
    if (updatedMilestone) {
      eventBus.trigger(EVENT_MILESTONES_UPDATED, updatedMilestone);
      onModalClosed();
    }
  },
  scrollToTopPreservingXCoordinate: function () {
    // Always scroll to the top, preserving the x coordinate for small viewports
    window.scrollTo(window.scrollX, 0);
  },
  initializeIframePostMessageListener: function () {
    if (!window.addEventListener) {
      window.addEventListener = function (n, f) {
        window.attachEvent("on" + n, function () {
          f(event);
        });
      };
    }
  },
  getStrategyReviewWizardProps: function (params, previousRoute) {
    const handleDone = (updatedMilestone, navigate = true) => {
      if (updatedMilestone) {
        eventBus.trigger(EVENT_MILESTONES_UPDATED, updatedMilestone);
      }
      if (navigate) {
        this.navigate(ROUTE_STRATEGY_RECOMMENDATION);
        if (previousRoute?.includes(ROUTE_STRATEGY_RECOMMENDATION)) {
          window.location.reload();
        }
      }
    };

    const onModalClosed = (shouldReload) => {
      this.navigate(previousRoute);
      if (
        (previousRoute.includes("#/settings") ||
          previousRoute.includes("#/retirement-planner")) &&
        shouldReload
      ) {
        window.location.reload();
      }
    };

    const handleScheduleACall = (appointmentDeepLink) => {
      window.location.replace(appointmentDeepLink);
    };
    return {
      onDone: handleDone,
      onCancelWizard: handleScheduleACall,
      onModalClosed,
      milestoneId: params?.userMilestoneId,
      forceAssessRisk: params?.forceAssessRisk === "true",
    };
  },
  initializeAppointmentView: function (
    isFirstUseAppointment,
    isMadLibsInterjectionReviewModal,
    isOnboardingAQ
  ) {
    if (isFirstUseAppointment) {
      if (
        !objectPath.get(
          abFrameworkProperties,
          "firstuse.showLinkAccountUnderMadLibsQQInterjection"
        )
      ) {
        $("body").addClass(CLASS_IS_FIRST_USE_APPOINTMENT);
      }
      eventBus.trigger("firstUseQuestionnaire:completed"); // Show Link Account section
    } else if (isMadLibsInterjectionReviewModal) {
      setMadLibsInterjectionReviewCustomContent(isOnboardingAQ);
      ComponentAnalytics.trackEvent(
        "Appointment Scheduler",
        "View Mad Libs Dismissed Review Modal",
        { isFirstUse }
      );
    } else if (window.sessionStorage.getItem("testGroupID")) {
      trackTestGroupID();
    }
  },
  navigateAfterAppointmentModalClosed: function (
    previousRoute,
    isOnboardingAQ
  ) {
    if (
      window.sessionStorage.getItem(
        "showMadLibsReviewModalOnMadLibsModalClose"
      ) &&
      (showMadLibsInterjectionReviewModal() || isOnboardingAQ)
    ) {
      window.sessionStorage.removeItem(
        "showMadLibsReviewModalOnMadLibsModalClose"
      );
      window.AppRouter.appointment({
        isMadLibsInterjectionReviewModal: true,
        isOnboardingAQ: isOnboardingAQ,
      });
    } else {
      $("body").removeClass(CLASS_IS_FIRST_USE_APPOINTMENT);
      if (previousRoute?.includes("#/strategy-review-wizard")) {
        window.location.hash = ROUTE_STRATEGY_RECOMMENDATION;
        window.location.reload();
      } else {
        window.localStorage.removeItem("onInformationGatheringPage");
        window.localStorage.removeItem("onTollGateOffRampPage");
        if (isFirstUse()) {
          this.showFirstUse();
        } else {
          this.navigate(previousRoute);
        }
      }
    }
  },
  updatePlanIdOnMainPage: function (section, params) {
    if (section === "index" || section === "paycheck") {
      this.planId =
        params &&
        parseInt(params.planId, 10) > 0 &&
        parseInt(params.planId, 10);
    }
  },
  showDefaultRetirementPlannerView: function (isOnboardingRP) {
    if (isOnboardingRP) {
      this.sidebar.collapse();
    } else {
      this.sidebar.showTabs();
      this.sidebar.showPlansContainer();
      this.sidebar.highlightTab("Scenarios");
    }
  },
  clearPlanIdAndRenderSidebarPlans: function (
    section,
    isComparisonDisabled,
    isNewScenarioDisabled,
    inOnboardingRP
  ) {
    // If navigating directly to a subsection such as add income-event or spending-goal,
    // blank out any plan ID that might have been in place from earlier in the session and load main plan
    if (
      section === "income-event" ||
      section === "spending-goal" ||
      section === "scenario"
    ) {
      this.planId = null;
    }
    this.sidebar.renderSidebarPlans({
      activePlanId: this.planId,
      disabled: false,
      isComparisonView: false,
      isComparisonDisabled,
      isNewScenarioDisabled,
      inOnboardingRP,
    });
  },
  updateAndRenderSidebarPlans: function (
    section,
    isComparisonDisabled,
    isNewScenarioDisabled,
    isDelegate,
    isFirstUseForecast
  ) {
    this.activeModuleView.updateView({
      section,
      planId: this.planId,
      firstTimeForecastExperience: isFirstUseForecast,
      isDelegate,
    });
    this.sidebar.renderSidebarPlans({
      activePlanId: this.planId,
      disabled: false,
      isComparisonView: false,
      isComparisonDisabled,
      isNewScenarioDisabled,
      isDelegate,
    });
  },
  handleFetchUIPreferencesError(error) {
    analytics.sendEngineeringEvent(
      "Error",
      `ERROR: getUIPreferences > ${error.message}`
    );
  },
  onClickMenuHandler: function () {
    $(SELECTOR_MAIN_MENU_LINK + ", " + SELECTOR_NESTED_MENU_LINK).on(
      "keydown",
      function (e) {
        if (["Enter", " "].includes(e.key)) {
          e.preventDefault();
          const ulElement = $(e.currentTarget).nextAll("ul");
          ulElement.addClass(CLASS_SUBMENU);
          ulElement.find("li:first-child a").focus();
        }
      }
    );

    $(SELECTOR_ALL_MENU_LINK).on("keydown", function (e) {
      if (e.key === "Enter") {
        const ulElement = $(e.currentTarget).parent().parent();
        ulElement.removeClass(CLASS_SUBMENU);
      } else if (e.key === " ") {
        e.preventDefault();
      }
    });
    $(SELECTOR_NESTED_SUBMENU_LINK).on("keydown", function (e) {
      if (e.key === "Enter") {
        const ulElement = $(e.currentTarget)
          .parent()
          .parent()
          .parent()
          .find("ul");
        const parentUlElement = ulElement.parent().parent().parent().find("ul");
        ulElement.removeClass(CLASS_SUBMENU);
        parentUlElement.removeClass(CLASS_SUBMENU);
      } else if (e.key === " ") {
        e.preventDefault();
      }
    });
  },
  onFocusMenuHandler: function () {
    const hideUlElement = function (ulElement) {
      ulElement.removeClass(CLASS_SUBMENU);
    };
    $(
      SELECTOR_MAIN_MENU_LINK +
        ", " +
        SELECTOR_SUB_MENU_LINK +
        ", " +
        SELECTOR_NESTED_MENU_LINK
    ).on("focus", function (e) {
      const currentUL = $(e.currentTarget).parent().find("ul");
      const previousUL = $(e.currentTarget).parent().prev("li").find("ul");
      if ($(currentUL).length) {
        hideUlElement(currentUL);
      }
      if ($(previousUL).length) {
        hideUlElement(previousUL);
      }
    });

    $(SELECTOR_LAST_MENU_ITEM).on("blur", function (e) {
      const isShiftTabbing = $(e.relatedTarget).hasClass("menu__action");
      if (
        !isShiftTabbing &&
        $(e.currentTarget).parent().parent().hasClass("menu--submenu")
      ) {
        const currentUL = $(e.currentTarget)
          .parent()
          .parent()
          .parent()
          .find("ul");
        if ($(currentUL).length) {
          hideUlElement(currentUL);
        }
      }
    });

    $(SELECTOR_MENU_SUBMENU).on("mouseleave", function (e) {
      $(e.currentTarget).removeClass(CLASS_SUBMENU);
    });
  },
  updateMenuRoles: function () {
    $(SELECTOR_MAIN_MENU_LINK).attr("tabindex", 0);
    $(SELECTOR_NESTED_MENU_LINK).attr("tabindex", 0);
  },
  keyboardNavigationFixes: function () {
    this.updateMenuRoles();
    this.onClickMenuHandler();
    this.onFocusMenuHandler();
  },
});

export default Router;

let INSTANCE;

export function getInstance() {
  if (!INSTANCE) {
    INSTANCE = new Router();
  }
  return INSTANCE;
}
