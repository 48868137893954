import PropTypes from "prop-types";
import React from "react";
import { noop } from "underscore";

const InsightBlog = ({ link, title, image, text, onActionClick }) => {
  return (
    <div>
      <div className="qa-insight-blog-container insight__blog-container">
        {title && link && (
          <a
            href={`#/advisor/daily-capital-blog/${link}`}
            target="_top"
            className="insight__blog-header insight__header qa-insight-header"
            aria-label={`Go to Personal Capital blog and read article:${title}`}
          >
            {title}
          </a>
        )}
        {image && (
          <div
            className="insight__blog-image"
            style={{ backgroundImage: `url(${image})` }}
          />
        )}
        {text && (
          <div className="insight__market-commentary-frame-container">
            <div
              className="insight__blog-text"
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
            <div className="insight__summary-overflow-fade" />
          </div>
        )}
        {link && (
          <a
            onClick={onActionClick}
            className="insight__blog-continue-reading insight__blog-view-all insight__action"
            href={`#/advisor/daily-capital-blog/${link}`}
          >
            Continue reading
          </a>
        )}
        <a
          onClick={onActionClick}
          className="insight__action insight__blog-view-all"
          href="#/advisor/daily-capital-blog"
        >
          View all blog entries
        </a>
      </div>
    </div>
  );
};

InsightBlog.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  onActionClick: PropTypes.func,
};

InsightBlog.defaultProps = {
  image: "",
  text: "",
  title: "",
  link: "",
  onActionClick: noop,
};

export default InsightBlog;
