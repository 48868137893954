import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";
import EmailAdvisorLauncher from "components/contactAdvisor/emailAdvisor/EmailAdvisorLauncher";
import { normalizeForDisplay } from "libs/pcap/utils/humanize";

/**
 * Provides methods for mounting and unmounting contact advisor launchers
 */
export default {
  renderEmailAdvisorLauncher: function (module) {
    this.emailAdvisorLauncherContainerNode = $("<div></div>")
      .appendTo("body")
      .get(0);
    ReactDOM.render(
      React.createElement(EmailAdvisorLauncher, {
        featureName: normalizeForDisplay(module),
        modalComponentName: "DSF Email Advisor Modal",
      }),
      this.emailAdvisorLauncherContainerNode
    );
  },

  unmountEmailAdvisorLauncher: function () {
    if (this.emailAdvisorLauncherContainerNode) {
      ReactDOM.unmountComponentAtNode(this.emailAdvisorLauncherContainerNode);
      this.emailAdvisorLauncherContainerNode.parentNode.removeChild(
        this.emailAdvisorLauncherContainerNode
      );
    }
  },
};
