import React from "react";
import mixpanel from "mixpanel";

const TITLE = "Welcome to your Empower Personal Wealth dashboard";

class ManualAccountRecaptureWelcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleLinkAccountClick = this.handleLinkAccountClick.bind(this);
  }

  handleLinkAccountClick() {
    mixpanel.trackEvent("Click_Link_account_insight_widget", {
      component: "Manual Account Recapture Welcome Insight",
    });
    window.location.href = "#/accounts/add";
  }

  getSummary() {
    return (
      <div className="pc-u-mb-">
        To get the most out of our free tools, start linking your accounts. By
        securely linking accounts, you will be able to take advantage of all our
        features:
      </div>
    );
  }

  getFeatures() {
    return (
      <div>
        <ul>
          <li>Track net worth changes</li>
          <li>Calculate monthly cash flow</li>
          <li>Track retirement savings</li>
          <li>Tracking portfolio performance</li>
          <li>Analyze asset allocation</li>
          <li>Analyze investment fees</li>
        </ul>
      </div>
    );
  }

  render() {
    const summary = this.getSummary();
    const features = this.getFeatures();

    return (
      <div className="insight__message">
        <div className="insight__header qa-insight-header">{TITLE}</div>
        <div className={`insight__summary `}>
          {summary}
          {features}
        </div>
        <div className="insight__buttons">
          <button
            onClick={this.handleLinkAccountClick}
            className={"pc-btn pc-btn--full"}
            data-tracking-flag={true}
          >
            Link account
          </button>
        </div>
      </div>
    );
  }
}

export default ManualAccountRecaptureWelcome;
