export default function () {
  var messages = [
    {
      time: 100,
      message: "Running 1 simulation...",
    },
  ];

  var count = 1;
  while (count < 5000) {
    count += 500 + Math.floor(Math.random() * 700);
    messages.push({
      time: 100,
      message: "Running " + count + " simulations...",
    });
  }

  messages.push(
    {
      time: 500,
      message: "Running 5000 simulations...",
    },
    {
      time: 1000,
      message: "Finding Median...",
    },
    {
      time: 1000,
      message: "Finding 10th Percentile...",
    },
    {
      time: 1000,
      message: "Testing patience...",
    }
  );

  return messages;
}
