/* eslint-disable camelcase */

import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import Services from "services";
import { pluck, defer } from "underscore";
import TrackAccountsOrPickATime from "./TrackAccountsOrPickATime";
import {
  getCurrentFeatureName,
  getNamespace,
  removeInterjectionFromActiveQueue,
  addHandledInterjectionUserMessageId,
  USER_EVENT,
  ACTION_KEY,
} from "../helpers";
import {
  trackClick,
  trackView,
  trackEvent,
} from "components/common/ComponentAnalytics";
import markUserMessageAsViewed from "libs/pcap/utils/markUserMessageAsViewed";

const COMPONENT_NAME = "Interjection Modal - Track Accounts or Pick a Time";
const MODAL_WIDTH = 800;

export default class TrackAccountsOrPickATimeModal extends React.Component {
  constructor({ interjection }) {
    super(...arguments);
    this.state = {
      showModal: true,
    };
    this.onActionClick = this.onActionClick.bind(this);
    this.triggerEmail = this.triggerEmail.bind(this);
    const currentFeatureName = getCurrentFeatureName(
      interjection.displayLocations
    );
    this.modalTitle = interjection.title.replace(
      "[FEATURE_NAME]",
      currentFeatureName
    );
    this.interjectionMixpanelEventData = {
      message_template: interjection.template,
      view_template: interjection.viewTemplate,
      feature_name: currentFeatureName,
    };
    trackView(COMPONENT_NAME, this.interjectionMixpanelEventData);
  }

  componentDidMount() {
    defer(() => {
      markUserMessageAsViewed(this.props.interjection.userMessageId);
    });
  }

  onActionClick(actionKey, actionTitle) {
    const { interjection, onModalClosed } = this.props;
    const userClickedOnCTA = [
      ACTION_KEY.TRACK_ACCOUNTS,
      ACTION_KEY.PICK_A_TIME,
    ].includes(actionKey);
    if (userClickedOnCTA) {
      trackClick(
        null,
        COMPONENT_NAME,
        `${actionTitle} Action Button`,
        this.interjectionMixpanelEventData
      );
    } else if (this.userHasClickedOnCTA) {
      return; // Closing modal after user clicked on a CTA
    }

    switch (actionKey) {
      case ACTION_KEY.TRACK_ACCOUNTS:
        this.triggerEmail(USER_EVENT.INTERJECTION_TRACK_ACCOUNTS_CLICK);
        break;
      case ACTION_KEY.PICK_A_TIME:
        // Navigate to Appointment Scheduler below after interjection modal is closed
        break;
      default:
        this.triggerEmail(USER_EVENT.INTERJECTION_CLOSE_MODAL_CLICK);
    }

    removeInterjectionFromActiveQueue(getNamespace(interjection));
    addHandledInterjectionUserMessageId(interjection.userMessageId);

    this.userHasClickedOnCTA = true;

    this.setState({ showModal: false }, () => {
      onModalClosed();
      if (actionKey === ACTION_KEY.PICK_A_TIME) {
        window.AppRouter.navigate("#/appointment");
      }
    });
  }

  triggerEmail(userEvent) {
    //require(['services'], (Services) => {
    Services.UserEvent.createUserEvent(
      { eventTypeName: userEvent },
      (error, response) => {
        const emailSent =
          !error && response && response.spHeader && response.spHeader.success;
        trackEvent(
          COMPONENT_NAME,
          `Create ${userEvent} User Event`,
          Object.assign(
            {
              success: emailSent,
              error:
                error ||
                JSON.stringify(pluck(response.spHeader.errors, "message")),
            },
            this.interjectionMixpanelEventData
          )
        );
      }
    );
    //});
  }

  render() {
    const { interjection } = this.props;
    return (
      <Modal
        componentName={COMPONENT_NAME}
        title={this.modalTitle}
        onClosed={this.onActionClick.bind(this, "Close Modal")}
        // react-modal APIs
        contentLabel={COMPONENT_NAME}
        isOpen={this.state.showModal}
        style={{ content: { width: MODAL_WIDTH } }}
        shouldCloseOnOverlayClick={false}
      >
        <TrackAccountsOrPickATime
          data={interjection}
          onActionClick={this.onActionClick}
        />
      </Modal>
    );
  }
}

TrackAccountsOrPickATimeModal.propTypes = {
  onModalClosed: PropTypes.func.isRequired,
  interjection: PropTypes.object.isRequired,
};
