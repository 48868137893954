import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "libs/pcap/utils/format";
import { fromNow } from "libs/pcap/utils/fromNow";
import AbstractForm from "components/common/form/AbstractForm";

const getImageBaseUrl = () =>
  IS_ADVISOR
    ? `${window.staticUrl || ""}/static/img/`
    : `${window.baseUrl}static/img/`;

export default class AccountSummary extends AbstractForm {
  displayAccountDetail(label, value) {
    return (
      value && (
        <div className="l-spaced l-spaced--flush pc-u-mb--">
          <div className="edit-account__text--label">{label}</div>
          <div className="edit-account__text--info">{value}</div>
        </div>
      )
    );
  }

  handleClick() {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "edit_account_exclude_from_advice_clicked_event"
      );
    }
  }

  render() {
    const { model, logoClassName } = this.props;
    let balance = model.balance;
    let isCreditAccount = model.productType === "CREDIT_CARD";
    if (isCreditAccount) {
      balance *= -1;
    }

    const disableFields = Boolean(model.closedDate);
    const zillowLink =
      model.addressline && model.city && model.zip && model.state;
    return (
      <div>
        {model.logoPath && !model.useHomeValuation && (
          <div className={`edit-account__logo-container ${logoClassName}`}>
            {model.logoPath.includes("pcap-cash.png") ? (
              <img
                src={model.logoPath.replace("pcap-cash", "empower-cash")}
                className="u-full-center--abs"
                alt={model.accountName}
              />
            ) : (
              <img
                src={model.logoPath}
                className="u-full-center--abs"
                alt={model.accountName}
              />
            )}
          </div>
        )}
        {model.useHomeValuation && (
          <>
            <div
              className={`edit-account__logo-container edit-account__logo-container--zillow ${logoClassName}`}
            >
              <a
                className="imageLink"
                href="https://www.zillow.com"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={
                    getImageBaseUrl() +
                    "linkAccount/Zillow_Wordmark_Blue_RGB_lrg.png"
                  }
                  height="40px"
                  alt="Real Estate on Zillow"
                />
              </a>
              <div>Zestimate&reg; home valuation</div>
            </div>
          </>
        )}
        <div className="account-summary__balance-display u-text-center">
          {formatCurrency(balance)}
        </div>
        <div className="pc-u-mt--">
          {isCreditAccount &&
            !model.isManual &&
            this.displayAccountDetail(
              "Available Credit",
              formatCurrency(model.availableCredit)
            )}
          {this.displayAccountDetail(
            "Balance as of",
            fromNow(model.lastRefreshed)
          )}
          {!(
            IS_EMPOWER &&
            model.isEmpower &&
            model.originalFirmName !== "empower-retail-pcap-cash"
          ) &&
            this.displayAccountDetail(
              "Date Linked",
              fromNow(model.createdDate)
            )}
          {!window.isAdvisorApp &&
            model.closedDate &&
            this.props.onReopenAccountClick &&
            this.props.onChangeClosedDateClick && (
              <>
                {this.displayAccountDetail(
                  "Marked as Closed",
                  fromNow(model.closedDate)
                )}
                <div className="l-spaced l-spaced--flush milli">
                  <div
                    className="pc-btn pc-btn--link qa-open-reopen-account-modal js-open-reopen-account-modal"
                    onClick={this.props.onReopenAccountClick}
                  >
                    Reopen
                  </div>
                  <div
                    className="pc-btn pc-btn--link qa-open-change-close-date js-open-change-close-date"
                    onClick={this.props.onChangeClosedDateClick}
                  >
                    Change Closed Date
                  </div>
                </div>
              </>
            )}
          {this.props.hasExcludeFromAdvice && (
            <div className="l-spaced l-spaced--flush">
              <label htmlFor="exclude" className="edit-account__text--label">
                Exclude from Investment and Planning tools.
              </label>
              <input
                id="exclude"
                disabled={disableFields}
                type="checkbox"
                checked={model.isExcludeFromHousehold}
                name="isExcludeFromHousehold"
                ref={this.props.storeInputRef}
                onChange={this.props.onInputChange}
                onClick={this.handleClick}
              />
            </div>
          )}
          {this.props.hasTreatAsInvestment && (
            <div className="l-spaced l-spaced--flush">
              <label
                htmlFor="treatedAsInvestment"
                className="edit-account__text--label"
              >
                Group with investment accounts
              </label>
              <input
                id="treatedAsInvestment"
                disabled={disableFields}
                type="checkbox"
                checked={model.treatedAsInvestment}
                name="treatedAsInvestment"
                ref={this.props.storeInputRef}
                onChange={this.props.onInputChange}
                onClick={this.handleClick}
              />
            </div>
          )}
          {model.useHomeValuation && (
            <>
              {model.zillowStatus !== "INTERNAL_ERROR" &&
                model.homeUrl !== undefined &&
                zillowLink && (
                  <div>
                    <a
                      className="js-zillow-details-link pc-u-pv- milli"
                      href={model.homeUrl}
                      rel="noreferrer"
                      target="_blank"
                    >
                      See more details for {model.addressline} on Zillow
                    </a>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    );
  }
}

AccountSummary.propTypes = {
  model: PropTypes.object.isRequired,
  hasExcludeFromAdvice: PropTypes.bool,
  hasTreatAsInvestment: PropTypes.bool,
  storeInputRef: PropTypes.func,
  onInputChange: PropTypes.func,
  onChangeClosedDateClick: PropTypes.func,
  onReopenAccountClick: PropTypes.func,
  logoClassName: PropTypes.string,
};

AccountSummary.defaultProps = {
  hasExcludeFromAdvice: false,
  hasTreatAsInvestment: false,
  storeInputRef: undefined,
  logoClassName: "",
};
