import React from "react";
import PropTypes from "prop-types";
import { promisify } from "utils/service";
import Services from "services";
import { noop } from "underscore";
import TransferFundsContainer from "components/transferFunds/TransferFundsContainer";
import { TRANSFER_TYPE_CUSTOM } from "components/transferFunds/utils/constants";
import {
  STEP_TRANSFER_FUNDS,
  STEP_REVIEW,
  STEP_CONFIRMATION,
} from "components/transferFunds/TransferFundsWizard/constants";

const EDIT_TRANSFERS_WIZARD_FLOW = [
  STEP_TRANSFER_FUNDS,
  STEP_REVIEW,
  STEP_CONFIRMATION,
];

const editHelpText =
  "You can edit the amount and frequency of an existing transfer. If you would like to select different accounts please set up a new recurring transfer.";

class EditRecurringTransfersContainer extends React.Component {
  render() {
    const { transfer } = this.props;

    return (
      <TransferFundsContainer
        model={transfer}
        transferType={TRANSFER_TYPE_CUSTOM}
        onBack={this.props.onCancel}
        isToDropDownDisabled={true}
        isFromDropDownDisabled={true}
        isContributionYearDisabled={true}
        hasOneTimeFrequency={false}
        serviceSubmitTransfer={this.props.serviceUpdateInstruction}
        trackEventSubmit={noop}
        trackEventSubmitError={noop}
        pageBuilder={null}
        hasRecurringTransferEstablished={false}
        wizardPages={EDIT_TRANSFERS_WIZARD_FLOW}
        onTransferFunds={this.props.onTransferFundSubmitted}
        recurringTransferHelpText={editHelpText}
      />
    );
  }
}

EditRecurringTransfersContainer.defaultProps = {
  serviceUpdateInstruction: promisify(
    Services.Transfer.updateTransferInstruction,
    true
  ),
  onTransferFundSubmitted: undefined,
};

EditRecurringTransfersContainer.propTypes = {
  serviceUpdateInstruction: PropTypes.func,
  transfer: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onTransferFundSubmitted: PropTypes.func,
};

export default EditRecurringTransfersContainer;
