// This is a list of category names
// which are considered as non discretionary spending categories.
const NON_DISCRETIONARY_TRANSACTION_CATEGORIES = [
  "Cable/Satellite",
  "Child/Dependent",
  "Dues & Subscriptions",
  "Groceries",
  "Healthcare/Medical",
  "Home Improvement",
  "Home Maintenance",
  "Insurance",
  "Loans",
  "Mortgages",
  "Online Services",
  "Other Bills",
  "Rent",
  "Service Charges/Fees",
  "Taxes",
  "Telephone",
  "Travel",
  "Utilities",
];

export default NON_DISCRETIONARY_TRANSACTION_CATEGORIES;
