import UnmountWithTimeout from "components/higherOrderComponents/unmountWithTimeout";
import Snackbar from "components/common/Snackbar";
import React from "react";

// Give user 15 seconds to read the message.
const TIMEOUT = 15000;
const SplitYourTransactionToastWithTimeout = UnmountWithTimeout(
  Snackbar,
  TIMEOUT
);

const SplitYourTransactionToast = () => (
  <SplitYourTransactionToastWithTimeout
    isOpen={true}
    className={
      "snackbar split-transaction__snackbar js-split-transaction__snackbar"
    }
    message={
      <div className="pc-u-p-">
        <p className="split-transaction__toast-header pc-u-mb--">
          Saved Split Transaction Successfully!
        </p>
        <p className="pc-delta pc-u-mb0">
          Note that this split will not be reflected on our mobile application.
          The calculations for Budgeting and Cash Flow on mobile may be
          different than what you see here.
        </p>
      </div>
    }
  />
);

export default SplitYourTransactionToast;
