import React from "react";
import PropTypes from "prop-types";

const ExpandedOverviewHeader = ({ personName, onToggleOverview }) => {
  return (
    <div className="expanded-overview__header-container pc-u-mb- pc-u-ml-">
      <h1 className="expanded-overview__header">
        {personName
          ? `Welcome, ${personName}! Here are some popular
      ways to get started:`
          : "Welcome! Here are some popular ways to get started:"}
      </h1>
      <button
        onClick={onToggleOverview}
        className="pc-btn pc-btn--stripped u-preserve-case expanded-overview__toggle-container"
      >
        <img
          src={`${window.staticUrl || ""}/static/img/icons/expand-more.svg`}
          alt="hide"
          className="expanded-overview__toggle-icon icon-svg pc-u-mr-"
        />
        <b>Hide</b>
      </button>
    </div>
  );
};

ExpandedOverviewHeader.propTypes = {
  onToggleOverview: PropTypes.func.isRequired,
  personName: PropTypes.string,
};

ExpandedOverviewHeader.defaultProps = {
  personName: "",
};

export default ExpandedOverviewHeader;
