/* eslint-disable no-console, no-underscore-dangle, valid-jsdoc*/
import _ from "underscore";

import moment from "moment";
import Backbone from "backbone";
import { DISPLAY_FORMAT } from "libs/pcap/utils/date2";
var _DEBUG = false;
var _START_END_REGEXP = /[se]/;
/**
 *
 * @param input
 * @param value
 * @param now -- largely provided for test purposes, sets today's date; it itself is a constructor input to a new PickerDate.
 * @constructor
 */
function PickerDate(input, now) {
  _.extend(this, Backbone.Events);

  if (now) {
    if (_DEBUG) {
      console.log("setting PD now", now);
    }
    this.setNow(new PickerDate(now).getMoment());
  } else {
    this.setNow(moment());
  }

  if (_.isString(input)) {
    if (_DEBUG) {
      console.log("setting PD from string", input);
    }
    this.setString(input);
  } else if (_.isArray(input)) {
    if (_DEBUG) {
      console.log("setting PD from array", input);
    }
    this.setOffset(input);
  } else if (input instanceof PickerDate) {
    if (_DEBUG) {
      console.log("setting PD from PickerDate", input, input.getMoment());
    }
    this.setMoment(input.getMoment());
  } else if (input) {
    // assuming its a moment
    if (_DEBUG) {
      console.log("setting PD _date directly", input);
    }
    this._date = input;
  } else {
    if (_DEBUG) {
      console.log("setting PD to now");
    }
    this.setToNow();
  }

  if (!this._date) {
    this._date = this._now.clone();
  }
}

_.extend(PickerDate.prototype, {
  startOf: function (time) {
    this._date.startOf(time);
  },

  add: function (a, b) {
    this._date.add(a, b);
  },

  subtract: function (a, b) {
    this._date.subtract(a, b);
  },

  getString: function () {
    if (!this._date) {
      console.log("bad PickerDate: ", this);
      throw new Error("date not found");
    }
    return this._date.format(DISPLAY_FORMAT);
  },

  toString: function () {
    return this.getString();
  },

  getDate: function () {
    return this._date.toDate();
  },

  setNow: function (n) {
    if (n) {
      if (_.isString(n)) {
        n = moment(n, DISPLAY_FORMAT);
      }
      this._now = n;
    } else {
      this._now = moment();
    }
  },

  getNow: function () {
    return this._now.clone();
  },

  setToNow: function () {
    this._date = this._now.clone();
    this._upDate();
  },

  getMoment: function () {
    return this._date.clone();
  },

  setMoment: function (m) {
    this._date = m ? m.clone() : this._now.clone();
  },

  setString: function (input) {
    if (!input) {
      throw new Error("bad input to PickerDate::setString");
    }
    this._date = moment(input, DISPLAY_FORMAT);
    this._upDate();
  },

  /**
   * sets a date based on offset in days/months/years from today.
   * past dates are expressed as negative numbers.
   * @param which: string('d', 'm'..) || 'd,-10' || array. Standard moment JS notation
   * If starting from the beginning of the period, add an 's'. If starting from the end add an 'e'
   * i.e. start from the beginning of last month and end at the last day of last month. value = 'M,-1s;M-1e'
   * @param value: (optional,int)
   * @return call to itself recursivly when getting more than one param
   */
  setOffset: function (which, value) {
    if (arguments.length === 1) {
      if (_.isString(which)) {
        which = which.split(",");
      }
      return this.setOffset.apply(this, which);
    }
    // if value comes with an 's'' like '3s' start from the beginning of the period
    // if it comes with an 'e' start from the end
    if (_START_END_REGEXP.test(value)) {
      // remove the 's' or 'e' so we can use the value
      var startEnd = value[value.length - 1];
      value = value.slice(0, -1);
      // apply changes
      this._date = this.getNow().add(value, which);
      this._date =
        startEnd === "s" ? this._date.startOf(which) : this._date.endOf(which);
    } else {
      this._date = this.getNow().add(value, which);
    }
    this._upDate();
  },

  _upDate: function () {
    var date = this.getString();
    if (this._lastValue === date) {
      return;
    }
    this.trigger("change", date);
    this._lastValue = date;
  },
});

export default PickerDate;
