import PropTypes from "prop-types";
import React from "react";
import { first, last, findLastIndex, isEmpty } from "underscore";
import ChartReact from "libs/pcap/chart/chart--react";
import { curveBasis } from "d3";
import { formatCurrency } from "libs/pcap/utils/format";

const Y_DOMAIN_PADDING = 10;

function xAccessor(item) {
  return item.age;
}

function yAccessor(item) {
  return item.value;
}

function xFormatter(age) {
  return "Age " + age;
}

function yFormatter(value) {
  return formatCurrency(value, 0);
}

// get the age either from the current projection or the new one if the current is empty
function getAge(currentProjection, newProjection, accessor) {
  const projection = isEmpty(currentProjection)
    ? newProjection
    : currentProjection;
  return isEmpty(projection) ? null : accessor(projection).age;
}

function isProjectionDecrease(projection) {
  if (isEmpty(projection)) {
    return false;
  }

  const startData = first(projection);
  const endData = last(projection);
  return startData.value > endData.value;
}

const TOOLTIP_CONFIG = {
  xFormat: xFormatter,
  yFormat: yFormatter,
  className: "chart-tooltip--projection-summary chart-tooltip--small",
  legendClassName: "chart-legend--box",
};

class ProjectionSummary extends React.Component {
  buildChartData(currentStrategyProjection, newStrategyProjection) {
    let chartData = [[], [], currentStrategyProjection];
    let currentLastYearData, newLastYearData;
    if (this.props.additionalValueAtRetirement < 0) {
      chartData = [[], currentStrategyProjection, newStrategyProjection];
    } else if (this.props.additionalValueAtRetirement > 0) {
      chartData = [newStrategyProjection, [], currentStrategyProjection];
    } else if (
      this.props.currentValueAtRetirement === 0 &&
      newStrategyProjection.length > 0
    ) {
      const currentLastYearIndex = findLastIndex(
        currentStrategyProjection,
        (d) => {
          return d.value !== 0;
        }
      );
      currentLastYearData = currentStrategyProjection[currentLastYearIndex];
      const newLastYearIndex = findLastIndex(newStrategyProjection, (d) => {
        return d.value !== 0;
      });
      newLastYearData = newStrategyProjection[newLastYearIndex];
      const isSameAge =
        newLastYearData && currentLastYearData.age === newLastYearData.age;
      if (
        newLastYearData.age < currentLastYearData.age ||
        (isSameAge && newLastYearData.value < currentLastYearData.value)
      ) {
        chartData = [[], currentStrategyProjection, newStrategyProjection];
      } else if (
        newLastYearData.age > currentLastYearData.age ||
        (isSameAge && newLastYearData.value > currentLastYearData.value)
      ) {
        chartData = [newStrategyProjection, [], currentStrategyProjection];
      }
    }
    return { newLastYearData, currentLastYearData, chartData };
  }

  render() {
    let {
      currentStrategyProjection,
      newStrategyProjection,
      classNameChart,
      yDomain,
    } = this.props;
    const hasCurrentProjection = !isEmpty(currentStrategyProjection);

    let { newLastYearData, currentLastYearData, chartData } =
      this.buildChartData(currentStrategyProjection, newStrategyProjection);

    const isGain =
      this.props.additionalValueAtRetirement > 0 ||
      (newLastYearData && newLastYearData.age > currentLastYearData.age);
    const isLoss =
      this.props.additionalValueAtRetirement < 0 ||
      (newLastYearData && newLastYearData.age < currentLastYearData.age);

    const currentAge = getAge(
      currentStrategyProjection,
      newStrategyProjection,
      first
    );
    const endAge = getAge(
      currentStrategyProjection,
      newStrategyProjection,
      last
    );

    let className =
      "projection-summary__chart-legend chart-legend chart-legend--vertical chart-legend--box";

    // move the legend to the right when the projection starts at its height and decreases towards retirement
    className = isProjectionDecrease(currentStrategyProjection)
      ? className + " projection-summary__chart-legend--right"
      : className;
    return (
      <div className={this.props.className}>
        <ul className={className}>
          {hasCurrentProjection && (
            <li className="chart-legend__item projection-summary__chart-legend-item--current">
              {isLoss ? "New Projection" : "Current Projection"}
            </li>
          )}
          {isGain && (
            <li className="chart-legend__item projection-summary__chart-legend-item--gain">
              Additional Projection
            </li>
          )}
          {isLoss && (
            <li className="chart-legend__item projection-summary__chart-legend-item--loss">
              Lost Projection
            </li>
          )}
        </ul>
        <div className={`projection-summary__chart ${classNameChart}`}>
          <ChartReact
            data={chartData}
            ref={(el) => {
              this.chartReact = el;
            }}
            className="dashboard-chart"
            type="area"
            curve={curveBasis}
            showYGrid={false}
            showXGrid={false}
            showXAxis={false}
            showYAxis={false}
            showYZeroLine={false}
            x={xAccessor}
            y={yAccessor}
            tooltip={TOOLTIP_CONFIG}
            ariaLabel={"Personal Strategy® Retirement Projection"}
            tabIndex={0}
            yDomain={yDomain}
            yDomainPadding={Y_DOMAIN_PADDING}
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
          />
        </div>
        <div className="l-spaced l-spaced--flush pc-zeta">
          <span>
            {this.props.endIsRetirement ? "Age " + currentAge : "Today"}
          </span>
          <div className={`u-sentence-case`}>
            <span>
              {this.props.endIsRetirement
                ? "Retirement Age " + endAge
                : "In 20 Years"}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

ProjectionSummary.propTypes = {
  className: PropTypes.string,
  classNameChart: PropTypes.string,
  endIsRetirement: PropTypes.bool,
  currentValueAtRetirement: PropTypes.number,
  additionalValueAtRetirement: PropTypes.number,
  currentStrategyProjection: PropTypes.array,
  newStrategyProjection: PropTypes.array,
  yDomain: PropTypes.array,
};

ProjectionSummary.defaultProps = {
  className: "",
  classNameChart: "",
};

export default ProjectionSummary;
