import React from "react";
import PropTypes from "prop-types";
import DateUtils from "libs/pcap/utils/date";
import moment from "moment";
import memoizeOne from "memoize-one";
import Breadcrumbs from "components/common/Breadcrumbs";
import AccountsDropdown from "components/common/AccountsDropdown";
import DateRangeSelector from "views/components/dateRangeSelectorView2";
import BudgetingWatch from "components/budgeting/BudgetingWatch";
import BudgetingScrollableList from "components/budgeting/BudgetingScrollableList";
import CashManagerHistoryBarChart from "components/budgeting/CashManagerHistoryBarChart";
import TransactionsGridContainer from "components/TransactionsGridV3/TransactionsGridContainer";
import IframeClient from "partner/iframeClient";
import Message from "components/common/Message";

const iframeClient = IframeClient.getInstance();
/**
 * Process fetched transactions and update state accordingly.
 *
 *  @param {Array} transactions retrieved transactions.
 */
const processTransactionData = memoizeOne((transactions) => {
  return transactions;
});

const DEFAULT_BREADCRUMBS = {
  label: "All Spending",
  link: "#/cash-flow/spending",
};

// TO DO: Update to support custom institutional group name
function empowerGroupSort(groupA, groupB) {
  let returnValue = 0;
  if (groupA === "Empower accounts") {
    returnValue = -1;
  } else if (groupB === "Empower accounts") {
    returnValue = 1;
  } else if (groupA < groupB) {
    returnValue = -1;
  } else if (groupB > groupA) {
    returnValue = 1;
  }

  return returnValue;
}

class BudgetingView extends React.Component {
  constructor(props) {
    super(props);

    this.dateRangeSelectorRef = React.createRef();
    this.handleChangeDateRangeEvent =
      this.handleChangeDateRangeEvent.bind(this);
  }

  componentDidMount() {
    this.initializeDateRangeSelector();
    if (IS_IFRAMED) {
      iframeClient.triggerResize();
    }
  }

  componentDidUpdate() {
    if (IS_IFRAMED) {
      iframeClient.triggerResize();
    }
  }

  /**
   * Create the `DateRangeSelector` backbone component.
   * [extracted for easier testing]
   */
  initializeDateRangeSelector() {
    this.dateRangeSelector = new DateRangeSelector({
      el: this.dateRangeSelectorRef.current,
      initialStartDate: this.props.startDate,
      initialEndDate: this.props.endDate,
    });
    this.dateRangeSelector.on(
      "change:dateRange",
      this.handleChangeDateRangeEvent
    );
  }

  handleChangeDateRangeEvent(
    startDate,
    endDate,
    quickPick,
    dropdownClassSelector
  ) {
    this.props.onDateRangeChange(
      startDate,
      endDate,
      quickPick,
      this.handleFocusDropdownEvent(dropdownClassSelector)
    );
  }

  handleFocusDropdownEvent(dropDownClassSelector) {
    return () => {
      this.dateRangeSelector.trigger("focus:dropdown", dropDownClassSelector);
    };
  }

  /**
   * Generate a message to display when no valid transactions are found
   * for the current search/type/date range.
   *
   * @return {String} a zero-state message.
   */
  generateZeroStateMessage() {
    const { startDate, endDate } = this.props;
    const today = moment().endOf("day");
    const startString = startDate.format(DateUtils.DISPLAY_FORMAT);
    const endString = endDate.isSame(today)
      ? "today"
      : endDate.format(DateUtils.DISPLAY_FORMAT);

    return `No transactions match your search criteria between ${startString} and ${endString}.`;
  }

  render() {
    const {
      startDate,
      endDate,
      transactions,
      targetBudget,
      past,
      current,
      onBudgetUpdate,
      accounts,
      onAccountsChange,
      level,
      transactionsGroups,
      transactionsHistory,
      transactionCategories,
      errors,
      transactionCategoryRulesFlag,
    } = this.props;

    let transactionsWithBudgeting = processTransactionData(transactions);
    let breadcrumbsItems = [];
    const renderCategoryLevel = level && level.type === "category";

    if (renderCategoryLevel) {
      breadcrumbsItems.push({ label: level.label });
    }
    const dynamicDescription = `Bar graph of spending over time from ${startDate} to ${endDate}`;

    return (
      <section className="budgeting__view">
        <div className="nav-secondary">
          <h1 className="nav-secondary__title qa-page-title">Budgeting</h1>
        </div>
        <div className="nav-secondary nav-secondary--feature-controls l-spaced l-spaced--flush">
          <Breadcrumbs
            items={[DEFAULT_BREADCRUMBS, ...breadcrumbsItems]}
            className={`pc-breadcrumb--tiny pc-breadcrumb--root-only-as-leaf`}
          />
          <div className="l-spaced l-spaced--flush">
            <AccountsDropdown
              items={accounts}
              onChange={onAccountsChange}
              className="qa-budgeting-account-selector"
              allowEmpty={false}
              sortBy="name"
              groupBy={(a) => a.group}
              groupSortBy={IS_EMPOWER ? empowerGroupSort : undefined}
              showBalancesAndTimeStamp={Boolean(window.isAdvisorApp)}
              isAccountDropdownSelectorFocus={
                this.props.isAccountDropdownSelectorFocus
              }
            />
            <div
              className="dateSelector pc-u-ml- budgeting__date-range-selector"
              ref={this.dateRangeSelectorRef}
              style={this.props.isZeroState ? { zIndex: 0 } : undefined}
            />
          </div>
        </div>
        <Message className="pc-u-mt" severity="error" messages={errors} />
        {startDate && endDate && (
          <section
            className={`l-spaced l-spaced--flush budgeting__charts pc-u-pt`}
          >
            {current && (
              <BudgetingWatch
                current={current}
                past={past}
                startDate={startDate}
                endDate={endDate}
                targetBudget={targetBudget}
                onBudgetUpdate={onBudgetUpdate}
              />
            )}
            <div className="budgeting__categories qa-budgeting-categories">
              <BudgetingScrollableList
                level={level}
                transactionsGroups={transactionsGroups || []}
              />
            </div>
            <div className="budgeting__history-chart">
              {transactionsHistory && (
                <CashManagerHistoryBarChart
                  data={transactionsHistory}
                  startDate={startDate}
                  endDate={endDate}
                  ariaLabel={dynamicDescription}
                />
              )}
            </div>
            <div aria-live="polite" aria-atomic className="sr-only">
              {dynamicDescription}
            </div>
          </section>
        )}
        <section className="budgeting__transactions pc-u-pt">
          <TransactionsGridContainer
            isEditable={true}
            transactions={transactionsWithBudgeting}
            className={"budgeting-transactions__grid-container"}
            zeroState={this.generateZeroStateMessage()}
            startDate={startDate}
            endDate={endDate}
            categories={transactionCategories}
            transactionCategoryRulesFlag={transactionCategoryRulesFlag}
          />
        </section>
      </section>
    );
  }
}

BudgetingView.propTypes = {
  accounts: PropTypes.array.isRequired,
  transactionsGroups: PropTypes.array,
  transactionCategories: PropTypes.array,
  errors: PropTypes.array,
  transactionsHistory: PropTypes.array,
  startDate: PropTypes.objectOf(moment).isRequired,
  endDate: PropTypes.objectOf(moment).isRequired,
  transactions: PropTypes.array.isRequired,
  targetBudget: PropTypes.number.isRequired,
  onBudgetUpdate: PropTypes.func.isRequired,
  onAccountsChange: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  current: PropTypes.shape({
    totalValue: PropTypes.number.isRequired,
    strongDialValue: PropTypes.number.isRequired,
    lightDialValue: PropTypes.number.isRequired,
  }),
  past: PropTypes.shape({
    totalValue: PropTypes.number.isRequired,
    strongDialValue: PropTypes.number.isRequired,
    lightDialValue: PropTypes.number.isRequired,
  }),
  level: PropTypes.object,
  isAccountDropdownSelectorFocus: PropTypes.bool,
  transactionCategoryRulesFlag: PropTypes.bool,
  isZeroState: PropTypes.bool,
};

BudgetingView.defaultProps = {
  past: undefined,
  transactionsHistory: [],
  transactionsGroups: [],
  errors: [],
  transactionCategories: [],
  current: {
    totalValue: 0,
    strongDialValue: 0,
    lightDialValue: 0,
  },
  level: undefined,
  isAccountDropdownSelectorFocus: false,
  transactionCategoryRulesFlag: false,
  isZeroState: false,
};

export default BudgetingView;
