import { FIRST_NAME } from "utils/patterns";
import { EMAIL, US_PHONE } from "utils/patterns";

const delegateAccessValidator = {
  type: "object",
  properties: {
    id: {
      type: "number",
      required: true,
      messages: {
        required: "must be selected",
      },
    },
    firstName: {
      type: "string",
      allowEmpty: false,
      pattern: FIRST_NAME.source,
      messages: {
        pattern: "Name may not include special characters or numbers.",
      },
      maxLength: 19,
    },
    emailAddress: {
      type: "string",
      allowEmpty: false,
      pattern: EMAIL,
      messages: {
        pattern: "must be a valid email address",
      },
      maxLength: 50,
    },
    phone: {
      type: "string",
      allowEmpty: false,
      pattern: US_PHONE,
      messages: {
        pattern: "must be a valid US phone number",
      },
    },
    relationship: {
      type: "string",
      allowEmpty: false,
      required: true,
      messages: {
        required: "must not be empty",
        allowEmpty: "must not be empty",
      },
    },
    isConsented: {
      type: "boolean",
      required: true,
      messages: {
        required: `You must allow access to your ${EMPOWER_PERSONAL_DASHBOARD} account in order to add a delegate user.`,
      },
    },
  },
};

export default delegateAccessValidator;
