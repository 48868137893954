export function getKeyByAccountType({
  group = "",
  type = "",
  subType = "",
} = {}) {
  return `${group}-${type}-${subType}`;
}

export function mapByAccountType(contributionLimits = []) {
  return new Map(
    contributionLimits.map((c) => {
      return [
        getKeyByAccountType(c),
        { limits: c.contributionLimits, messages: c.messages },
      ];
    })
  );
}
