export const getTransactionEditedMessage = (model, categories) => {
  let transactionEditedMessage = "";
  const { originalCategoryId, originalDescription, description, categoryId } =
    model;
  const category = categories.find(
    (category) => category.transactionCategoryId === categoryId
  )?.name;
  if (
    Boolean(category) &&
    Boolean(description) &&
    originalDescription !== description &&
    categoryId !== originalCategoryId
  ) {
    transactionEditedMessage = `Transaction updated to ${description} and ${category} based on your previous edits`;
    // eslint-disable-next-line no-negated-condition
  } else if (Boolean(category) && originalCategoryId !== categoryId) {
    transactionEditedMessage = `Transaction updated to ${category} based on your previous edits`;
    // eslint-disable-next-line sonarjs/no-duplicated-branches
  } else {
    transactionEditedMessage = `Transaction updated to ${description} based on your previous edits`;
  }

  return transactionEditedMessage;
};

export default getTransactionEditedMessage;
