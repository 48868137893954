import { chain } from "underscore";

const sortUserMessagesByRank = (userMessages) => {
  return chain(userMessages)
    .each((msg) => {
      msg.rank = msg.rank || 0;
    })
    .sortBy("rank")
    .value()
    .reverse();
};

export default sortUserMessagesByRank;
