import PropTypes from "prop-types";
import React from "react";
import BaseInput from "components/common/form/BaseInput";
import CompositeInput from "components/common/form/CompositeInput";
import FancySelect from "components/common/form/FancySelect";
import { isEmpty, uniqueId } from "underscore";

function getKey(value, index) {
  if (index === 0 && value === "") {
    return uniqueId("empty-select-group");
  }
  return index;
}

function getUniqueOptions(options, value, curInputValue) {
  return options.filter(
    (o) => o.value === curInputValue || !value.includes(o.value)
  );
}

/**
 * `SelectGroup` component represents a list of select inputs with the ability
 * to add or remove lines. It also provides the validation support.
 * Validator should be provided via `validator` attribute in the format
 * https://github.com/flatiron/revalidator#schema
 *
 * Example:
 * ```
    {
      required: true
    }
 * ```
 *
 * `SelectGroup` options can be defined as an array of objects via `options` prop:
 * ```
    <SelectGroup
      options={[ {value: 'example_value', label: 'Example Option'}, ... ]}
    />
 * ```
 *
 * `SelectGroup` value an array of strings corresponding to the `value` attribute on
 * the option. Specify via `value` prop:
 * ```
    <SelectGroup
      options={[ {value: 'example_value', label: 'Example Option'}, ... ]}
      value={['example_value']}
    />
 * ```
 *
 * @export SelectGroup
 * @class SelectGroup
 * @extends {CompositeInput}
 */
export default class SelectGroup extends CompositeInput {
  constructor({ value }) {
    super(...arguments);

    if (isEmpty(value)) {
      value = undefined;
    }

    // Override the default `value` of an empty string coming from `BaseInput` constructor.
    this.state.value = value;

    this.elements = new Map();
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleChange({ target }) {
    const { value: inputValue } = target;

    const index = parseInt(target.dataset.index, 10);

    let { value } = this.state;
    if (!value) {
      value = [];
    }

    value[index] = inputValue;

    this.handleChangeWithValue(value);
  }

  handleAddItem() {
    let { value } = this.state;

    // do not allow to add more items before all of the existing are populated
    for (const el of this.elements.values()) {
      const { valid } = el.validate();
      if (!valid) {
        return;
      }
    }

    if (!value) {
      value = [];
    }
    value.push("");

    this.handleChangeWithValue(value);
  }

  handleDeleteItem({ target }) {
    let { value } = this.state;

    if (value) {
      // do not allow to remove the last item
      if (value.length === 1) {
        value = undefined;
      } else {
        const index = parseInt(target.dataset.index, 10);
        value.splice(index, 1);
      }
    }

    this.handleChangeWithValue(value);
  }

  render() {
    const { options, name, disabled, helpText, labelAddItem } = this.props;
    // Allows to display a single select when the value is unset.
    const value = this.state.value ?? [""];
    const className = `select-group ${this.props.className}`;

    return (
      <div className={className}>
        <div>
          {value.map((v, i) => (
            <div
              className="select-group__row js-select-group-row"
              key={getKey(v, i)}
            >
              <div className="select-group__row-input">
                <FancySelect
                  name={name}
                  value={v}
                  data-index={i}
                  ref={((i) => (element) => {
                    if (element) {
                      this.elements.set(i, element);
                    } else {
                      this.elements.delete(i);
                    }
                  })()}
                  validator={{
                    required: true,
                    allowEmpty: false,
                    messages: {
                      required: "must be selected",
                      allowEmpty: "must be selected",
                    },
                  }}
                  isDisabled={disabled}
                  onChange={this.handleChange}
                  options={getUniqueOptions(options, value, v)}
                  data-hj-masked
                />
              </div>
              <button
                type="button"
                className="select-group__row-delete pc-btn pc-btn--stripped js-action-delete-item"
                data-index={i}
                onClick={this.handleDeleteItem}
                disabled={disabled}
              >
                x
              </button>
            </div>
          ))}
        </div>
        {helpText && (
          <label className="pc-help-block pc-help-block--small">
            {helpText}
          </label>
        )}
        <button
          type="button"
          className="pc-btn pc-btn--small pc-u-mt- js-action-add-item"
          onClick={this.handleAddItem}
          disabled={disabled || isEmpty(getUniqueOptions(options, value))}
        >
          {labelAddItem}
        </button>
        {this.getErrorBlock({ placeholder: this.props.errorPlaceholder })}
      </div>
    );
  }
}

SelectGroup.propTypes = Object.assign({}, BaseInput.propTypes, {
  className: PropTypes.string,
  value: PropTypes.array,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  errorPlaceholder: PropTypes.bool,
  options: PropTypes.array,
  labelAddItem: PropTypes.node,
});

SelectGroup.defaultProps = Object.assign({}, BaseInput.defaultProps, {
  className: "",
  options: [],
  labelAddItem: "+ Add",
});
