import { timeFormat } from "d3";
import { formatCurrency } from "libs/pcap/utils/format";

const dateFormat = timeFormat("%B %e");

/**
 * Returns a generator function for the tooltip template
 * for one day change value.
 *
 * @param {Object}    options                 options object
 * @param {Function}  options.x               x accessor function
 * @param {Function}  options.y               y accessor function
 * @returns {Function} the generator function which returns a template function to be used with the tooltip
 */
export default function (options) {
  return function (data, i) {
    const { x, y } = options;
    data = data[0]; // only one dataseries is displayed

    const xValue = dateFormat(x(data[i]));
    const yValue = formatCurrency(y(data[i]), 0);
    const oneDayChange = data[i].oneDayChange;
    const isOneDayChangeNegative = oneDayChange < 0;

    let oneDayChangeDisplay = formatCurrency(oneDayChange, 0);
    let oneDayChangeClassName;
    if (isOneDayChangeNegative) {
      oneDayChangeClassName = "u-text-danger";
    } else {
      oneDayChangeClassName = "u-text-success";
      oneDayChangeDisplay = "+" + oneDayChangeDisplay;
    }
    return `<div class="chart-tooltip__header">${xValue}</div>
      <ul class="chart-legend chart-legend--vertical u-text-right">
        <li class="chart-legend__item">${yValue}</li>
        <li class="chart-legend__item ${oneDayChangeClassName}">${oneDayChangeDisplay}</li>
      </ul>`;
  };
}
