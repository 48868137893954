import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Services from "services";
import Mixpanel from "mixpanel";
import { promisify } from "utils/service";
import { noop } from "underscore";
import toClient from "accessors/formField/mappers/toClient";
import SurveyView from "./SurveyView";
import ClosingMessageView from "./ClosingMessageView";
import PostLoginActionsProvider from "../PostLoginActionsProvider";

const UserResponseSurveyCustomContainer = (props) => {
  const {
    addUserResponse,
    componentName,
    imageSource,
    jsonContent,
    onRequestClose,
    plaDetails,
    userMessage,
  } = props;
  const [model] = useState({});
  const [questions] = useState(
    () => toClient(jsonContent.questionGroup).prompts ?? []
  );
  const [currentViewIndex, setCurrentViewIndex] = useState(0);
  const [closingMessageViewData, setClosingMessageViewData] = useState({});
  const [customInputValue, setCustomInputValue] = useState("");

  useEffect(() => {
    if (currentViewIndex === 1) {
      Mixpanel.trackEvent(
        `View ${componentName}-${Object.values(model)[0]}`,
        plaDetails
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewIndex]);

  const addCustomInputValueToModel = () => {
    let tempModel = { ...model };
    const name = questions[0].parts[0].name;
    if (customInputValue !== "") {
      if (Object.keys(model).length === 0) {
        tempModel[name] = ["OTHER", customInputValue];
      } else {
        tempModel[name].push("OTHER", customInputValue);
      }
    }

    return { ...tempModel };
  };

  const handleSubmit = () => {
    const responseModel = addCustomInputValueToModel();
    addUserResponse({ userResponse: JSON.stringify(responseModel) });
    const targetDepends = `${Object.keys(responseModel)[0]}=${
      Object.values(responseModel)[0]
    }`;
    const targetResponse = toClient(jsonContent.questionGroup).responses?.find(
      (r) => r.depends === targetDepends
    );
    if (!targetResponse?.summary && !targetResponse?.title) {
      onRequestClose();
    } else {
      setClosingMessageViewData(targetResponse);
      setCurrentViewIndex((prevIndex) => prevIndex + 1);
    }

    const surveyMixpanelProperties = {
      ...plaDetails,
      surveyResponse: Object.values(responseModel)[0],
    };
    Mixpanel.trackEvent(
      `Click on ${componentName} - Submit`,
      surveyMixpanelProperties
    );
    Mixpanel.trackEvent(
      `Click on ${componentName}-${Object.values(responseModel)[0]} - Submit`,
      plaDetails
    );
  };

  const handleCustomInputFieldChange = (value, prefix) => {
    if (value === "") setCustomInputValue(value);
    else setCustomInputValue(`${prefix}_${value}`);
  };

  const buttonClassName = jsonContent.buttonClassName;
  const userResponseOptionClassName = jsonContent.userResponseOptionClassName;
  const customInputField = jsonContent.customInputField;

  return (
    <>
      {currentViewIndex === 0 ? (
        <SurveyView
          componentName={componentName}
          fields={[questions[0]]}
          imageSource={imageSource}
          model={model}
          onSubmit={handleSubmit}
          plaDetails={plaDetails}
          summary={userMessage.summary}
          title={userMessage.title}
          buttonClassName={buttonClassName}
          userResponseOptionClassName={userResponseOptionClassName}
          customInputField={customInputField}
          customInputValue={customInputValue}
          handleCustomInputFieldChange={handleCustomInputFieldChange}
        />
      ) : (
        <PostLoginActionsProvider
          {...props}
          userMessage={closingMessageViewData}
        >
          {({ getButtons, imageSource }) => (
            <ClosingMessageView
              componentName={componentName}
              getButtons={getButtons}
              imageSource={imageSource}
              model={model}
              summary={closingMessageViewData?.summary}
              title={closingMessageViewData?.title}
            />
          )}
        </PostLoginActionsProvider>
      )}
    </>
  );
};

UserResponseSurveyCustomContainer.propTypes = {
  addUserResponse: PropTypes.func,
  componentName: PropTypes.string,
  imageSource: PropTypes.string,
  jsonContent: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func,
  plaDetails: PropTypes.object,
  userMessage: PropTypes.object,
};

UserResponseSurveyCustomContainer.defaultProps = {
  addUserResponse: promisify(Services.UserResponse.add),
  componentName: "User Response Survey",
  imageSource: "",
  onRequestClose: noop,
  plaDetails: {},
  userMessage: {},
};

export default UserResponseSurveyCustomContainer;
