import React from "react";
import PropTypes from "prop-types";

const AdvisorMessageBox = (props) => {
  const { className, isAdvisor, message } = props;

  if (!isAdvisor) return null;

  return (
    <div
      data-testid="advisor-message-box"
      className={`advisor-message-box pc-u-p pc-u-mv ${className}`}
    >
      {message.length > 0 &&
        message.map((item, i) => {
          const key = `advisor-message-${i + 1}`;
          return (
            <div className="advisor-message-box__container" key={key}>
              <h4 className="advisor-message-box__title u-text-bold pc-u-mb-">
                {item.title}
              </h4>
              <div className="advisor-message-box__content">{item.content}</div>
            </div>
          );
        })}
    </div>
  );
};

AdvisorMessageBox.propTypes = {
  className: PropTypes.string,
  isAdvisor: PropTypes.bool,
  message: PropTypes.array,
};

AdvisorMessageBox.defaultProps = {
  className: "",
  message: [{ title: "Confirm", content: "Message" }],
  isAdvisor: IS_ADVISOR,
};

export default AdvisorMessageBox;
