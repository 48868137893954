import PropTypes from "prop-types";
import React from "react";
import PlanListRow from "components/sidebar/PlanListRow";
import { omit } from "underscore";

function renderPlan(plan, planProps) {
  const { onPlanSelected, disabled, activePlanIds } = planProps;

  // Do not allow PCAP users to edit plans prepared by advisors
  return (
    <PlanListRow
      key={plan.planId}
      disabled={disabled}
      isEditable={window.isAdvisorApp || !plan.planStatus}
      plan={plan}
      isActive={activePlanIds.includes(plan.planId)}
      onPlanSelected={onPlanSelected}
      isGMWB={planProps.isGMWB}
      accounts={planProps.accounts}
      maModelPortfolios={planProps.maModelPortfolios}
    />
  );
}

const ViewPlanList = (props) => {
  const newProps = omit(props, "activePlanId");
  newProps.activePlanIds = [props.activePlanId];
  newProps.renderPlan = renderPlan;

  return props.render(newProps);
};

ViewPlanList.propTypes = {
  masterPlan: PropTypes.array,
  alternativePlans: PropTypes.array,
  advisorPlans: PropTypes.array,
  activePlanId: PropTypes.number,
  disabled: PropTypes.bool,
  onPlanSelected: PropTypes.func,
  plans: PropTypes.array,
  errors: PropTypes.array,
  render: PropTypes.func,
  isGMWB: PropTypes.bool,
  accounts: PropTypes.array,
  maModelPortfolios: PropTypes.array,
};

ViewPlanList.defaultProps = {
  isGMWB: false,
  accounts: [],
  maModelPortfolios: [],
};

export default ViewPlanList;
