import React, { useState } from "react";
import PropTypes from "prop-types";
import noop from "utils/noop";
import DocumentUploaderContainer from "components/common/DocumentUploader/DocumentUploaderContainer";
import DocumentUploaderHeader from "components/common/DocumentUploader/DocumentUploaderHeader";
import Modal from "components/modal/Modal";
import FancySelect from "components/common/form/FancySelect";
import Services from "services";
import { promisify } from "utils/service";
import { getDocumentEndpoint } from "../../utils/enrollmentUtils";

import {
  DOCUMENT_ENDPOINT_PATHS,
  INPUT_FILE_TYPES,
  TYPE_VALIDATORS,
  MAX_DV_FILE_SIZE,
} from "components/common/DocumentUploader/utils/constants";

const UPLOAD_STATUS_MESSAGES = {
  UPLOADING: "",
  SUCCESS: (
    <b className="document-upload-modal__uploader-message--success">
      successfully uploaded!
    </b>
  ),
  FAIL: (
    <b className="document-upload-modal__uploader-message--fail">
      failed to upload!
    </b>
  ),
};

const UPLOAD_STATUS_ICON_SET = {
  BODY_ICON: "#icon__file-upload",
  SUCCESS: "#icon__success-upload",
  FAIL: "#icon__fail-upload",
};

const DocumentUploadModal = ({
  className,
  isOpen,
  onCancel,
  onDone,
  getDocumentsTypes,
}) => {
  const [documentType, setDocumentType] = useState();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [maxFileSize, setMaxFileSize] = useState("");
  const [uploadAPIEndpoint, setUploadAPIEndpoint] = useState("");

  React.useEffect(() => {
    getDocumentsTypes().then((documentsType) => {
      const maxFileSize = documentsType.maxFileSize;
      const documentType = documentsType.documentTypeMapping;
      const documents = documentType.map((doc) => {
        return {
          value: doc.key,
          label: doc.label,
        };
      });
      setMaxFileSize(maxFileSize);
      setDocumentTypes(documents);
      setUploadAPIEndpoint(
        getDocumentEndpoint(DOCUMENT_ENDPOINT_PATHS.EMPOWER_DOCUMENT)
      );
    });
  }, []);

  const onChangeSelect = ({ target }) => {
    setDocumentType(target.value);
  };

  const documentTypesList = INPUT_FILE_TYPES.ALL.split(",");

  return (
    <Modal
      isOpen={isOpen}
      onClosed={() => {
        setDocumentType(undefined);
        onCancel();
      }}
      className={`document-upload-modal pc-modal--large ${className}`}
      title="Upload document"
      componentName="UploadDocumentModal"
      shouldCloseOnOverlayClick={false}
    >
      <div className="document-upload-modal__body">
        <div className="pc-u-pb">
          Upload photos or scans of forms and other supporting documentation.
        </div>
        <div className="pc-layout pc-u-pb">
          <div className="document-upload-modal__subtitle pc-layout__item">
            <h3 className="pc-u-mb-">Document type:</h3>
          </div>
          <div className="pc-layout__item pc-u-1/3">
            <FancySelect
              className="document-upload-modal__select"
              options={documentTypes}
              value={documentType}
              onChange={onChangeSelect}
              name="documentType"
            />
          </div>
        </div>
        {documentType && (
          <>
            <div className="pc-layout pc-u-pb--">
              <div className="document-upload-modal__subtitle pc-layout__item">
                <h3 className="pc-u-mb-">Documents</h3>
              </div>
              <DocumentUploaderHeader
                documentTypesList={documentTypesList}
                maxFileSize={maxFileSize}
                headerCopy={
                  "For accurate processing, please be sure to include all relevant pages in a clear, legible format."
                }
              />
            </div>
            <div className="pc-layout pc-u-pb">
              <div className="document-upload-modal__uploader pc-layout__item">
                <DocumentUploaderContainer
                  acceptingFiles={INPUT_FILE_TYPES.ALL}
                  fileTypeValidator={TYPE_VALIDATORS.IMAGE_DOCUMENTS.validate}
                  fileNameValidator={TYPE_VALIDATORS.DOCUMENT_NAME.validate}
                  footerUploadList={true}
                  maxFileSize={MAX_DV_FILE_SIZE}
                  multiple={false}
                  typeErrorMessage={
                    TYPE_VALIDATORS.IMAGE_DOCUMENTS.errorFormatter
                  }
                  fileNameErrorMessage={
                    TYPE_VALIDATORS.DOCUMENT_NAME.errorFormatter
                  }
                  requestParams={{
                    documentType,
                  }}
                  uploadAPIEndpoint={uploadAPIEndpoint}
                  uploadIconSet={UPLOAD_STATUS_ICON_SET}
                  uploadStatusMessages={UPLOAD_STATUS_MESSAGES}
                  onBack={onCancel}
                  onDone={onDone}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

DocumentUploadModal.propTypes = {
  className: PropTypes.string,
  getDocumentsTypes: PropTypes.func,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onDone: PropTypes.func,
};

DocumentUploadModal.defaultProps = {
  className: "",
  isOpen: false,
  onCancel: noop,
  onDone: noop,
  getDocumentsTypes: promisify(Services.DocumentType.get),
};

export default DocumentUploadModal;
