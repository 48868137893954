import React from "react";
import { formatCurrency } from "libs/pcap/utils/format";
import {
  positiveNegativeColoredCurrency,
  positiveNegativeColoredPercentage,
} from "./formatters";
import {
  holdingAndAccountNameComparator,
  numberAndAccountNameComparator,
} from "./sortingUtils";

const defaultColumns = [
  {
    header: "Holding",
    accessor: (d) => d,
    formatter: function holdingName(d) {
      return (
        <>
          <div className="u-text-bold qa-ticker" title={d.description}>
            {d.ticker}
          </div>
          <div
            className="pc-holdings-grid-cell--holding-description u-clip-text"
            title={d.description}
          >
            {d.description}
          </div>
        </>
      );
    },
    // eslint-disable-next-line sonarjs/no-duplicate-string
    className: "pc-holdings-grid-cell--holding-name qa-holding-name",
    headerClassName:
      "pc-holdings-grid-cell--holding-name qa-holding-name-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-name qa-holding-name",
    isSortable: true,
    summaryAccessor: () =>
    "Grand total",
    comparator: holdingAndAccountNameComparator,
  },
  {
    header: "Shares",
    accessor: (d) => d.quantity,
    className:
      "table__column--right pc-holdings-grid-cell--holding-shares qa-holding-shares",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-shares qa-holding-shares-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-shares",
    isSortable: true,
    comparator: numberAndAccountNameComparator,
  },
  {
    header: "Price",
    accessor: (d) => d.price,
    className:
      "table__column--right pc-holdings-grid-cell--holding-price qa-holding-price",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-price qa-holding-price-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-price",
    formatter: function holdingPrice(price) {
      return <span>{formatCurrency(price)}</span>;
    },
    isSortable: true,
    comparator: numberAndAccountNameComparator,
  },
  {
    header: "Change",
    accessor: (d) => d.change,
    className:
      "table__column--right pc-holdings-grid-cell--holding-change qa-holding-change",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-change qa-holding-change-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-change",
    formatter: function holdingChange(change) {
      return <span>{formatCurrency(change)}</span>;
    },
    isSortable: true,
    comparator: numberAndAccountNameComparator,
  },
  {
    header: "1 Day %",
    accessor: (d) => d.oneDayPercentChange,
    className:
      "table__column--right pc-holdings-grid-cell--holding-one-day-percentage qa-holding-one-day-percentage",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-one-day-percentage qa-holding-one-day-percentage-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-one-day-percentage",
    formatter: positiveNegativeColoredPercentage,
    isSortable: true,
    sortOrder: "desc",
    comparator: numberAndAccountNameComparator,
  },
  {
    header: "1 day $",
    accessor: (d) => d.oneDayValueChange,
    className:
      "table__column--right pc-holdings-grid-cell--holding-one-day-usd qa-holding-one-day-usd",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-one-day-usd qa-holding-one-day-usd-col-header",
    summaryClassName:
      "table__column--right pc-holdings-grid-cell--holding-one-day-usd qa-one-day-change-value",
    formatter: positiveNegativeColoredCurrency,
    isSortable: true,
    comparator: numberAndAccountNameComparator,
    summaryFormatter: formatCurrency,
    summaryAccessor: (data) => {
      return data.reduce((total, holding) => {
        return total + holding.oneDayValueChange;
      }, 0);
    },
  },
  {
    header: "Value",
    accessor: (d) => d.value,
    className:
      "table__column--right pc-holdings-grid-cell--holding-value qa-holding-value",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-value qa-holding-value-col-header",
    summaryClassName:
      "table__column--right pc-holdings-grid-cell--holding-value",
    formatter: function holdingValue(value) {
      return <span>{formatCurrency(value)}</span>;
    },
    isSortable: true,
    comparator: numberAndAccountNameComparator,
    summaryFormatter: formatCurrency,
    summaryAccessor: (data) => {
      return data.reduce((total, holding) => {
        return total + holding.value;
      }, 0);
    },
  },
];

const empowerDefaultColumns = [
  {
    header: "Holding",
    accessor: (d) => d,
    formatter: function holdingName(d) {
      return (
        <>
          <div className="u-text-bold qa-ticker" title={d.description}>
            {d.ticker}
          </div>
          <div
            className="pc-holdings-grid-cell--holding-description u-clip-text"
            title={d.description}
          >
            {d.description}
          </div>
        </>
      );
    },
    className: "pc-holdings-grid-cell--holding-name qa-holding-name",
    headerClassName:
      "pc-holdings-grid-cell--holding-name qa-holding-name-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-name qa-holding-name",
    isSortable: true,
    summaryAccessor: () => "Grand Total",
    comparator: holdingAndAccountNameComparator,
  },
  {
    header: "Shares",
    accessor: (d) => d.quantity,
    className:
      "table__column--right pc-holdings-grid-cell--holding-shares qa-holding-shares",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-shares qa-holding-shares-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-shares",
    isSortable: true,
    comparator: numberAndAccountNameComparator,
  },
  {
    header: "Price",
    accessor: (d) => d.price,
    className:
      "table__column--right pc-holdings-grid-cell--holding-price qa-holding-price",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-price qa-holding-price-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-price",
    formatter: function holdingPrice(price) {
      return <span>{formatCurrency(price)}</span>;
    },
    isSortable: true,
    comparator: numberAndAccountNameComparator,
  },
  {
    header: "Change",
    accessor: (d) => d.change,
    className:
      "table__column--right pc-holdings-grid-cell--holding-change qa-holding-change",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-change qa-holding-change-col-header",
    summaryClassName: "pc-holdings-grid-cell--holding-change",
    formatter: function holdingChange(change) {
      return <span>{formatCurrency(change)}</span>;
    },
    isSortable: true,
    comparator: numberAndAccountNameComparator,
  },
  {
    header: "1 day $",
    accessor: (d) => d.oneDayValueChange,
    className:
      "table__column--right pc-holdings-grid-cell--holding-one-day-usd qa-holding-one-day-usd",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-one-day-usd qa-holding-one-day-usd-col-header",
    summaryClassName:
      "table__column--right pc-holdings-grid-cell--holding-one-day-usd qa-one-day-change-value",
    formatter: positiveNegativeColoredCurrency,
    sortOrder: "desc",
    isSortable: true,
    comparator: numberAndAccountNameComparator,
    summaryFormatter: formatCurrency,
    summaryAccessor: (data) => {
      return data.reduce((total, holding) => {
        return total + holding.oneDayValueChange;
      }, 0);
    },
  },
  {
    header: "Value",
    accessor: (d) => d.value,
    className:
      "table__column--right pc-holdings-grid-cell--holding-value qa-holding-value",
    headerClassName:
      "table__column--right pc-holdings-grid-cell--holding-value qa-holding-value-col-header",
    summaryClassName:
      "table__column--right pc-holdings-grid-cell--holding-value",
    formatter: function holdingValue(value) {
      return <span>{formatCurrency(value)}</span>;
    },
    isSortable: true,
    comparator: numberAndAccountNameComparator,
    summaryFormatter: formatCurrency,
    summaryAccessor: (data) => {
      return data.reduce((total, holding) => {
        return total + holding.value;
      }, 0);
    },
  },
];

// Empower doesn't need "1 Day %" column for Holding tab in Portfolio page.
const filteredDefaultColumns = IS_EMPOWER
  ? empowerDefaultColumns
  : defaultColumns;

export default filteredDefaultColumns;
