import { timeDay, timeWeek, timeFormat } from "d3";
import CashFlowBaseView from "views/modules/cashFlowV2/cashFlowBaseView";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import CashFlowState from "stateModels/cashFlowState";
import Formats from "formats";
import CashFlowTemplate from "templates/cashFlow.html";
import LineGraph from "views/components/cashFlow/lineGraph";
import BarGraph from "views/components/cashFlow/cashFlowBarGraph";
import moment from "moment";
import dollarAndCentsAmount from "templates/helpers/dollarAndCentsAmount";
import mixpanel from "../../../libs/pcap/utils/mixpanel";
import Services from "services";
import BackboneReactView from "common/BackboneReactView";
import BudgetingExperienceZeroState from "components/budgeting/BudgetingExperienceZeroState";
import AppOverlay from "appOverlay";
import { subscribify } from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import {
  currentMonthData,
  previousMonthData,
  transactionCategories,
} from "./cashFlowZeroStateMockData";

var TWO_MONTHS = 2;
var THREE_WEEKS = 3;
const CASHFLOW_CLASSES = "cash-flow qa-cash-flow";

export default CashFlowBaseView.extend({
  el: "#cashFlow",

  initialize: function () {
    this.unwatchAccounts();
    _.extend(this.events, CashFlowBaseView.prototype.events);
    CashFlowBaseView.prototype.initialize.apply(this, arguments);
    this.getAccountsSubscription = subscribify(Services.Accounts.get)();
    this.getAccountsSubscription.on(
      "change",
      this.handleSuccessFetchAccounts,
      this
    );
    this.fetchAccountsCancelablePromise = makeCancelablePromise(
      this.getAccountsSubscription.promise
    );

    this.fetchAccountsCancelablePromise.promise
      .then((accountsData) => {
        this.handleSuccessFetchAccounts(accountsData);
      })
      .catch(() => {
        if (this.fetchAccountsCancelablePromise.isCanceled()) {
          return;
        }
      });
  },

  validateRenderView: function () {
    if (this.isZeroState === true) {
      this.showZeroState();
    } else {
      this.showFeature();
    }
  },

  handleSuccessFetchAccounts: function (accountsData) {
    const validAccounts = accountsData?.accounts?.find(
      (account) =>
        !account.isManual &&
        (account.productType === "BANK" ||
          account.productType === "CREDIT_CARD")
    );
    this.isZeroState = _.isUndefined(validAccounts);
    window.dispatchEvent(new CustomEvent("pageloaded"));
    this.validateRenderView();
  },

  unwatchAccounts: function () {
    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unwatch();
      this.getAccountsSubscription.off("change");
      this.getAccountsSubscription = null;
    }
    if (this.fetchAccountsCancelablePromise) {
      this.fetchAccountsCancelablePromise.cancel();
    }
  },

  showZeroState: function () {
    if (IS_EMPOWER) {
      document.querySelector("#moduleContainer").dispatchEvent(
        new CustomEvent("zeroState", {
          detail: { page: "cashFlow", close: false },
        })
      );
    }
    if (!this.empowerZeroStateDisplayed) {
      if (IS_EMPOWER) {
        this.transactionCategories = transactionCategories;
        this.options.startDate = moment(
          currentMonthData.startDate,
          "YYYY-MM-DD"
        );
        this.options.endDate = moment(currentMonthData.endDate, "YYYY-MM-DD");
        this.render();
        this.onTransactionsReceived(currentMonthData, previousMonthData);
        this.empowerZeroStateDisplayed = true;
      } else if (!this.zeroState) {
        this.$el.removeClass(CASHFLOW_CLASSES);
        if (this.watchIdCurrentPeriod) {
          Services.Transactions.get.unwatch(this.watchIdCurrentPeriod);
          delete this.watchIdCurrentPeriod;
        }
        this.removeAccountSelector();
        this.removeDateRangeSelector();
        this.destroyFixedControls();
        this.$el.empty();
        this.$el.append('<div id="cashFlowZeroState"></div>');
        this.zeroState = new BackboneReactView({
          el: "#cashFlowZeroState",
          component: BudgetingExperienceZeroState,
          componentProps: {
            componentName: "Cash flow",
            title: "Cash flow",
            sectionClassName: "cashFlow__view",
          },
        });
      }
    }
  },

  showFeature: function () {
    if (this.zeroState) {
      this.zeroState.remove();
      delete this.zeroState;
      this.$el.remove("#cashFlowZeroState");
    }
    if (!this.$el.hasClass(CASHFLOW_CLASSES)) {
      this.$el.addClass(CASHFLOW_CLASSES);
      this.render();
      this.fetchData();
      this.on(
        "featurecontrolstuck",
        function (direction, featureControlSelector) {
          var offset = 0;
          if (direction === "up") {
            offset = window.scrollY;
            if (offset === undefined) {
              offset = window.document.documentElement.scrollTop;
            }
          }
          this.dateRangeSelector.setPosition(offset, featureControlSelector);
        }.bind(this)
      );
      mixpanel.trackEvent("View Cash Flow", {
        component: "cashFlowView",
      });
    }
  },

  isTwoOrMoreMonthsSelected: function () {
    var startDate = this.getStartDateObj();
    var endDate = this.getEndDateObj();
    var monthsNumber = endDate.diff(startDate, "months", true);
    if (monthsNumber >= TWO_MONTHS) {
      return true;
    }

    // Ensure that we return `true` for edge cases where the range is less than two months arithmetically:
    // - `2016-08-01 - 2016-09-30` - two full months
    // - `2016-07-31 - 2016-09-30` - two full months +1 day
    if (monthsNumber > 1) {
      var firstDayFirstMonth = startDate.clone().startOf("month");
      var lastDayLastMonth = endDate.clone().endOf("month");
      var isFirstDayOfMonth = startDate.isSame(firstDayFirstMonth, "day");
      var isLastDayOfMonth = endDate.isSame(lastDayLastMonth, "day");
      if (isFirstDayOfMonth && isLastDayOfMonth) {
        return true;
      } else if (isLastDayOfMonth) {
        var startOfPrevMonth = endDate
          .clone()
          .startOf("month")
          .subtract(1, "month");
        return startDate.isBefore(startOfPrevMonth);
      }
    }

    return false;
  },

  /**
   * Returns the chart class to be displayed for the current state of the page.
   * Line chart class will is returned if any full month or the current month is selected.
   * Otherwise, the bar chart class is returned.
   *
   * @returns {Backbone.View} The chart class.
   */
  getChartClass: function () {
    return this.isTwoOrMoreMonthsSelected() ? BarGraph : LineGraph;
  },

  /**
   * Specifies the daily interval if the range within the same month or the rang is less than 3 weeks.
   * *3* weeks range is not special in any way. It is chosen as the longest range we can fit
   * daily ticks of `mm/dd` format in without an overlap.
   *
   * Otherwise, fallbacks to weekly interval.
   * @returns {Function} The weekly interval function.
   */
  getLineChartXTicks: function () {
    var startDate = this.getStartDateObj();
    var endDate = this.getEndDateObj();
    return startDate.isSame(endDate, "month") ||
      moment(endDate).diff(startDate, "week") < THREE_WEEKS
      ? timeDay.every(1)
      : timeWeek.every(1);
  },

  /**
   * Specifies the `dd` format for line chart X axis ticks if the selected date range is within
   * one month. Otherwise, adds the month, as in `mm/dd`.
   * @returns {Function} The formatter function.
   */
  getLineChartXTickFormat: function () {
    var startDate = this.getStartDateObj();
    var endDate = this.getEndDateObj();
    return startDate.isSame(endDate, "month")
      ? timeFormat("%d")
      : timeFormat("%m/%d");
  },

  updateView: function (newViewState) {
    AppOverlay.show();
    this.fetchAccountsCancelablePromise.promise
      .then((accountsData) => {
        this.handleSuccessFetchAccounts(accountsData);
      })
      .catch(() => {
        if (this.fetchAccountsCancelablePromise.isCanceled()) {
          return;
        }
      });
    Backbone.View.prototype.updateView.call(this, newViewState);
    this.trackViewState("cashFlow", CashFlowState, false);
  },

  render: function () {
    if (this.killed) {
      return;
    }

    this.removeAccountSelector();
    this.removeDateRangeSelector();
    this.$el.html(CashFlowTemplate({ isEmpower: IS_EMPOWER }));
    this.renderDateRangeSelector();
    this.renderAccountSelector();

    if (this.isDisplayed) {
      this.initializeAllFixedControls();
    } else {
      this.once(
        "displayed",
        function () {
          this.initializeAllFixedControls();
        }.bind(this)
      );
    }

    return this;
  },

  buildBarGraphData: function () {
    var filter = this.getTransactionsFilter();
    return this.getCashFlow(this.dataTransactionsCurrPeriod, filter, "MONTH");
  },

  initializeBarGraph: function () {
    // add another div so that `.js-bar-chart-container` stays in DOM when the view is destroyed
    var el = $("<div>").appendTo(this.$(".js-bar-chart-container"));

    return new BarGraph({
      el: el,
      data: this.buildBarGraphData(),
    });
  },

  onTransactionsReceived: function () {
    CashFlowBaseView.prototype.onTransactionsReceived.apply(this, arguments);
    this.renderTakeaways();
  },

  mixinAmount: function (target) {
    target.amount = function (value) {
      // var dollarValue = Formats.filters.dollar_no_cents(value);
      var dollarValue = dollarAndCentsAmount(value, true, true);
      target.find(".amount").text(dollarValue);
    };
  },

  mixinPercent: function (target) {
    target.percent = function (value) {
      var percentage = Formats.filters.percent(value);
      target.find(".amount").text(percentage);
    };
  },

  mixinLabel: function (target) {
    target.label = function (value) {
      target.find("label").text(value);
    };
  },

  mixinPip: function (target) {
    target.pip = function (show) {
      var pip = target.find(".pip");
      if (show) {
        pip.show();
      } else {
        pip.hide();
      }
    };
  },

  renderCashLegend: function () {
    /* NOOP */
  },

  getDrilldownLevel: function () {
    /* NOOP */
  },
});
