import React from "react";
import PropTypes from "prop-types";
import { Choices } from "./Choices";

const Question = ({
  handleSubmit,
  question,
  submitLabel,
  isSubmitted,
  handleNextQuestion,
  nextQuestionLabel,
  correctLabel,
  incorrectLabel,
  showCta,
  currentValue,
  setCurrentValue,
  handleCtaClick,
  ctaUrl,
  ctaText,
  aboveCtaHtmlContent,
  isInterjection,
  getButtons,
}) => {
  const correctAnswer = question.choices.find(
    (choice) => choice.isCorrect === true
  ).text;
  const getButton = () => {
    if (isInterjection && showCta && isSubmitted) {
      return (
        <>
          {aboveCtaHtmlContent && (
            <div dangerouslySetInnerHTML={{ __html: aboveCtaHtmlContent }} />
          )}
          {getButtons({
            getButtonClassName: () =>
              "simple-image__cta pc-btn pc-btn--small pc-btn--primary",
          })}
        </>
      );
    }
    const urlTarget = ctaUrl.includes("www.personalcapital.com")
      ? "_blank"
      : "";
    if (showCta && isSubmitted) {
      return (
        <>
          {aboveCtaHtmlContent && (
            <div dangerouslySetInnerHTML={{ __html: aboveCtaHtmlContent }} />
          )}
          <a href={ctaUrl} rel="noreferrer" target={urlTarget}>
            <button className="pc-btn pc-btn--primary" onClick={handleCtaClick}>
              {ctaText}
            </button>
          </a>
        </>
      );
    }

    return isSubmitted ? (
      <button
        className="pc-btn pc-btn--primary"
        onClick={() => {
          setCurrentValue("");
          handleNextQuestion();
        }}
      >
        {nextQuestionLabel}
      </button>
    ) : (
      <button
        className="pc-btn pc-btn--primary"
        onClick={() => {
          handleSubmit(currentValue);
        }}
        disabled={currentValue === ""}
      >
        {submitLabel}
      </button>
    );
  };

  return (
    <div>
      <div className="pc-advice-notification__title-container">
        <h1
          className="pc-advice-notification__title qa-pla-title"
          dangerouslySetInnerHTML={{ __html: question.questionText }}
        />
      </div>
      <div className={`user-quiz__result `}>
        {isSubmitted && (
          <React.Fragment>
            {correctAnswer === currentValue ? (
              <div className="user-quiz__result-correct">{correctLabel}</div>
            ) : (
              <div className="user-quiz__result-incorrect">
                {incorrectLabel}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <Choices
        choices={question.choices}
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        isSubmitted={isSubmitted}
        correctAnswer={correctAnswer}
      />
      <div className={`pc-u-mt- u-text-right`}>{getButton()}</div>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  isSubmitted: PropTypes.bool.isRequired,
  handleNextQuestion: PropTypes.func.isRequired,
  nextQuestionLabel: PropTypes.string,
  correctLabel: PropTypes.string,
  incorrectLabel: PropTypes.string,
  showCta: PropTypes.bool.isRequired,
  currentValue: PropTypes.string.isRequired,
  setCurrentValue: PropTypes.func.isRequired,
  handleCtaClick: PropTypes.func.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  aboveCtaHtmlContent: PropTypes.string,
  getButtons: PropTypes.func,
  isInterjection: PropTypes.bool,
};

Question.defaultProps = {
  submitLabel: "Submit",
  nextQuestionLabel: "Next Question",
  correctLabel: "Correct",
  incorrectLabel: "Incorrect",
  aboveCtaHtmlContent: undefined,
  getButtons: () => null,
  isInterjection: false,
};

export default Question;
