import PERSONALCAPITAL from "PERSONALCAPITAL";
import { isUndefined, isNumber, isObject, clone } from "underscore";

/**
 * processRouteParams takes the routing parameters:
 *
 * @param {string} elementSelector - A string that represents the ID of the html element for the module view
 * @param {string} path - A string that represents the routing path that is passed down to the modules and sub-views
 * @param {object} params - A generic object that contain values used to update the corresponding viewState
 * @param {string} moduleName A string that represents the name of the module where its state is being tracked
 * @param {class} stateModelClass Class of the stateModel
 * @param {string} idAttribute - (optional) A string used specify the name of the property in the state model to be used as a look-up key.
 * @param {string} idValue (optional) The value of the idAttribute that is used to look up the corresponding state model.
 *
 * @return {object} viewstate - A generic object that contains viewState and viewConfigration objects.  ViewConfiguration is used
 *      to initialize the module view.
 */
export default function (
  elementSelector,
  path,
  params,
  moduleName,
  stateModelClass,
  idAttribute,
  idValue
) {
  var module, viewState, i, stateProperty, key;

  if (isObject(path)) {
    params = Object.assign({}, path);
    path = undefined;
  }

  if (!isUndefined(idAttribute)) {
    key = isNumber(idValue) ? "_" + idValue : idValue;
  }

  // Define new viewState model if it doesn't exist in PERSONALCAPITAL state tracking system
  if (PERSONALCAPITAL.has(moduleName)) {
    module = PERSONALCAPITAL.get(moduleName);

    viewState = isUndefined(idAttribute) ? clone(module) : clone(module[key]);

    if (!isUndefined(params)) {
      for (i = 0; i < viewState.optionalUrlParams.length; i++) {
        stateProperty = viewState.optionalUrlParams[i];

        if (
          !isUndefined(params[stateProperty]) &&
          params[stateProperty] !== ""
        ) {
          viewState[stateProperty] = params[stateProperty];
        }
      }
    }
  } else {
    viewState = new stateModelClass();

    if (!isUndefined(params)) {
      for (i = 0; i < viewState.optionalUrlParams.length; i++) {
        stateProperty = viewState.optionalUrlParams[i];

        // If this.options has a property that is defined, it indicates that
        // the change is driven by url and the change needs to be reflected
        // in viewState
        if (
          !isUndefined(params[stateProperty]) &&
          params[stateProperty] !== ""
        ) {
          viewState[stateProperty] = params[stateProperty];
        }
      }
    }
  }

  viewState.path = isUndefined(path) ? "" : path;

  var viewConfiguration = Object.assign({ el: elementSelector }, viewState);
  return { viewState: viewState, viewConfiguration: viewConfiguration };
}
