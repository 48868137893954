import Services from "services";
import abFrameworkProperties from "libs/pcap/utils/abFrameworkProperties";
import parseResponseErrors from "libs/pcap/utils/response";
import analytics from "analytics";

let config;

export const getConfig = () => {
  if (!config) {
    config = abFrameworkProperties.shareWithFriends || {};
  }
  return config;
};

export const getIsEnabledPromise = () =>
  new Promise((resolve, reject) => {
    Services.Profile.getUIPreferences((err, response) => {
      const errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        analytics.sendEngineeringEvent(
          "Error",
          "ShareWithFriendsUtils.js: Services.Profile.getUIPreferences: " +
            JSON.stringify(errors)
        );
        return;
      }
      const isEnabled = Boolean(
        response?.spData?.features?.USER_REFERRAL_PROGRAM
      );
      resolve(isEnabled);
    });
  });

export const getFunnelAttributesPromise = () =>
  new Promise((resolve, reject) => {
    Services.Profile.getFunnelAttributes((err, response) => {
      const errors = parseResponseErrors(err, response);
      if (!errors && response && response.spData) {
        resolve(response.spData);
      } else {
        reject(errors);
        analytics.sendEngineeringEvent(
          "Error",
          "ShareWithFriendsUtils.js: Services.Profile.getFunnelAttributes: " +
            JSON.stringify(errors)
        );
      }
    });
  });

export default {
  getConfig,
  getIsEnabledPromise,
  getFunnelAttributesPromise,
};
