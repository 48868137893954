import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import ExpandedOverview from "./ExpandedOverview";
import CollapsedOverview from "./CollapsedOverview";
import {
  useLockBodyScroll,
  useSetTilesBasedOnGoals,
  useExpandableState,
} from "./hooks";
import useComponentAnalytics from "utils/hooks/Analytics/useComponentAnalytics";
import { COMPONENT_NAME } from "./helper";

const GettingStartedOverview = ({ notesForAdvisor, personName, personAge }) => {
  const [tiles, analyticsData] = useSetTilesBasedOnGoals(
    notesForAdvisor,
    personAge
  );
  const [isExpanded, handleToggleOverview] = useExpandableState(
    true,
    COMPONENT_NAME
  );
  useLockBodyScroll(isExpanded);
  useComponentAnalytics(COMPONENT_NAME, analyticsData);

  return ReactDOM.createPortal(
    <>
      <div>&nbsp;</div>
      <div className={`getting-started-overview__container`}>
        <div
          className={`getting-started-overview__content ${
            isExpanded ? "expanded" : "collapsed"
          }`}
        >
          {!isExpanded && (
            <CollapsedOverview onToggleOverview={handleToggleOverview} />
          )}
          <CSSTransition
            in={isExpanded}
            unmountOnExit={true}
            classNames={"transition-overview-slide"}
            timeout={500}
          >
            <ExpandedOverview
              onToggleOverview={handleToggleOverview}
              personName={personName}
              personAge={personAge}
              tiles={tiles}
            />
          </CSSTransition>
        </div>
      </div>
    </>,
    document.querySelector("body")
  );
};

ExpandedOverview.propTypes = {
  notesForAdvisor: PropTypes.object,
  personName: PropTypes.string,
  personAge: PropTypes.number,
};

ExpandedOverview.defaultProps = {
  notesForAdvisor: {},
  personName: "",
  personAge: null,
};

export default GettingStartedOverview;
