import $ from "jquery";
import Backbone from "backbone";
import Services from "services";
import Mixpanel from "mixpanel";
import sidebarModel from "models/sidebar";
import * as detailsLink2 from "templates/helpers/detailsLink2";
import AccountTemplate from "templates/modules/sidebar/account2.html";
import BlockedTemplate from "templates/modules/sidebar/blocked2.html";
import EmpowerAccountTemplate from "templates/modules/sidebar/empowerAccount.html";
import editAccountView from "views/components/editAccountView2";
import React from "react";
import ReactDOM from "react-dom";
import Modal from "components/modal/Modal";
import MarkAccountAsClosedContainer from "components/common/EditAccount/MarkAccountAsClosedContainer";
import RemoveAccountContainer from "components/common/RemoveAccount/RemoveAccountContainer/RemoveAccountContainer";
import { removeAccountsByAccountId } from "accessors/account/accessor";
import CloseAccountTemplate from "templates/modules/sidebar/closeAccount2.html";
import IssueTemplate from "templates/modules/sidebar/reportIssue.html";
import UserSubscribeTemplate from "templates/modules/sidebar/userCanSubscribe.html";
import ReportedIssueTemplate from "templates/modules/sidebar/reportedIssue.html";
import "tooltip";
import { isEmpowerPaeMode } from "views/modules/sidebar/utils/accountUtils";
const IS_OPEN_CLASSNAMES = " sidebar-account--is-open pc-bg-dark";
const PROGRESS_TIME_OUT = 6000;
const submittedTicketText =
  "This issue has been reported to our support team for resolution. For updates, please reply to the email you received from our support team.";
const ANIMATION_TIME = 300;
const ACCOUNT_ERROR_STATE = [
  "VISIT_SITE",
  "MIGRATE_OAUTH",
  "OAUTH_RECONNECT",
  "INITIATE_REFRESH",
  "MORE_INFO",
  "RETIREMENT_ADDITIONAL_SECURITY_QUESTIONS",
  "RETIREMENT_CREDENTIALS_HAVE_CHANGED",
  "RETIREMENT_INCORRECT_SECURITY_ANSWERS",
  "RETIREMENT_SET_UP_SECURITY_QUESTIONS",
  "RETIREMENT_DEFAULT_HANDLER",
];
import {
  triggerContextSwitch,
  requiresContextSwitch,
} from "empower/utils/contextSwitch/contextSwitchUtils";
import { isTRSPensionGoal } from "views/modules/sidebar/utils/empowerAccounts";
import moment from "moment";
const tooltipTrigger = "hover click";

var AccountView = Backbone.View.extend({
  tagName: "li",
  className: "js-account sidebar-account",
  events: {
    "click .js-account-close": "closeAccount",
    "click .js-account-submit": "refreshAccount",
    "click .js-account-cancel": "cancel",
    "click .js-move-it": "moveIt",
    "click .js-move-it-confirm": "moveItConfirm",
    "click .js-account-remove": "confirmRemoval",
    "click .js-report-issue": "submitSupportTicket",
    "click .edit": "switchToEditMode",
    "click .notificationIcon": "onNotificationClicked",
    "click .js-sidebar-account-title": "onFirmNameClicked",
    "click .zestimateCopy": "zestimateCopyClick",
    "click .js-notify-me": "notifyMeWhenUpdatedClick",
    "click .js-enroll-now-button": "handleEnrollNowClick",
    "click .js-rollover-button": "handleWithdrawalsAndRolloverClick",
  },
  /* eslint-disable sonarjs/cognitive-complexity */
  initialize: function () {
    if (this.model) {
      this.model.on("change", this.onModelChange, this);
      this.model.on("removed", this.remove, this);
      this.model.on(
        "editAccountModalLoginChangeSubmit",
        function () {
          this.$el.removeClass(`js-editing-form ${IS_OPEN_CLASSNAMES}`);
        }.bind(this)
      );
    }
    sidebarModel.on("change:state", this.onSidebarStateChanged, this);

    this.editing = false;
    this.isExtraFormVisible = false;

    window.addEventListener("updateSidebar", () => {
      this.onViewingAccountDetails();
    });

    this.onViewingAccountDetails();

    this.$el.on("click", (event) => {
      if (IS_EMPOWER) {
        const eventBus = window.dashboardUtils
          ? window.dashboardUtils.eventBus
          : null;
        const eventName = "sidebar_accounts_click_event";
        if (eventBus) {
          eventBus.dispatch(eventName);
          eventBus.dispatchAmplitude({
            event_type:
              window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
              "select_button",
            event_properties: { selection: eventName }
          });
        }
        const empowerAttributes = this.model.get("empowerAttributes");
        const empowerData = this.model.get("empowerData");
        const isEmpowerAccount = this.model.get("isEmpower");
        const accountSummary = empowerData?.accountSummary;
        const originalFirmName = this.model.get("originalFirmName");
        // if there is no accountSummary field, it means the account is not fully aggregated.
        // Don't take the user to the account details page
        if (
          isEmpowerAccount &&
          !accountSummary &&
          originalFirmName !== "empower-retail-pcap" &&
          originalFirmName !== "empower-retail-pcap-cash" &&
          originalFirmName !== "empower-retail-pcap-ps"
        ) {
          return;
        }
        const requiresEmpowerContextSwitch = requiresContextSwitch(
          empowerData,
          empowerAttributes
        );
        let hasEnrollNowOption =
          (empowerData?.enrollmentInfo?.isNQ ||
            empowerData?.enrollmentInfo?.isSecondary) &&
          empowerData?.enrollmentInfo?.isEligible;
        if (hasEnrollNowOption) {
          const enrollmentDatePassed = moment(
            empowerData?.enrollmentInfo.nqEnrollmentDate
          ).isBefore(moment.now(), "day");
          hasEnrollNowOption = enrollmentDatePassed
            ? false
            : hasEnrollNowOption;
        }

        if (hasEnrollNowOption) {
          this.onViewClicked.apply(this, arguments);
        } else if (requiresEmpowerContextSwitch) {
          triggerContextSwitch(empowerAttributes.planId);
          event.preventDefault();
        } else {
          this.onViewClicked.apply(this, arguments);
        }
      } else {
        this.onViewClicked.apply(this, arguments);
      }
    });
  },
  isAccountEditable: function (account) {
    const isPartnerAccount = account.isPartner && !IS_EMPOWER;
    const originalFirmName = this.model.get("originalFirmName");
    // Empower Cash account should not be editable for SSO users
    if (
      IS_EMPOWER &&
      window.integratedSharedData?.isInboundSSOUser &&
      account.isOnUsBank
    ) {
      return false;
    }
    // For an empower account, if there is no accountSummary field,
    // it means the account is not fully aggregated. return false so that the account is not editable.
    if (
      IS_EMPOWER &&
      account.isEmpower &&
      !account?.empowerData?.accountSummary &&
      originalFirmName !== "empower-retail-pcap-cash"
    ) {
      return false;
    }
    return !(
      account.isOnUs ||
      account.isOnUs401K ||
      isPartnerAccount ||
      account.isOnUsRetirement
    );
  },
  render: function () {
    var html,
      data = this.model.toJSON(),
      nextAction = data.nextAction || {};

    //passing staticUrl for image references in template files
    data.staticUrl = window.staticUrl;
    this.edited = false;

    if (this.editing) {
      this.editing = false;
      if (isTRSPensionGoal(data?.empowerAttributes)) {
        window.location.href = `#/goals/view?goalKey=${data.empowerAttributes.pensionIncomeGoalKey}`;
      } else {
        editAccountView(this.model, {
          isFromSidebar: true,
        });
        sidebarModel.trigger("ensureElementIsVisible", this.$el);
      }
    } else {
      this.$el.removeClass(
        `error caution refreshing editing normal refreshRequired resolving refreshable isOnUs ${IS_OPEN_CLASSNAMES}`
      );

      let nextActionFlag;
      if (nextAction.reportAction === "REPORT") {
        nextActionFlag = "REPORT";
      } else if (nextAction.reportAction === "REPORTED") {
        nextActionFlag = "REPORTED";
      } else {
        nextActionFlag = nextAction.action;
      }

      data.isAccountEditable = this.isAccountEditable(data);
      data.rebrandOrganizationName = "Empower";
      switch (nextActionFlag) {
        case "CLOSE_ACCOUNT":
          this.$el.addClass("error");
          html = CloseAccountTemplate(data);
          break;
        case "REPORT":
          this.$el.addClass("warning");
          data.submittedTicketText = submittedTicketText;
          html = IssueTemplate(data);
          this.setResolving();
          break;
        case "REPORTED":
          this.$el.addClass("warning");
          data.submittedTicketText = submittedTicketText;
          html = ReportedIssueTemplate(data);
          this.setResolving();
          break;
        case "BLOCKED":
          this.$el.addClass("warning");
          //not enabled
          if (data.nextAction.hasUserSubscribedToNotification === undefined) {
            html = BlockedTemplate(data);
          } else if (data.nextAction.hasUserSubscribedToNotification) {
            //Already subscribed
            //TODO remove after https://personalcapital.jira.com/browse/PFM-11059 is resolved
            data.nextAction.nextActionMessage =
              "We are having some trouble updating this site. We will notify you as soon as your account has successfully connected again.";

            // If it has been more than 48 hours since first aggregation
            // OR we don't know when the first aggregation happened
            // offer to remove the account. We don't want the user to have a 'stuck' experience
            const twoDaysBefore = moment().subtract({ hours: 48 });

            // if firstAggregationStartedDate field is present, use it to determine
            // whether it has been 48 hours since the first aggregation attempt.
            // if firstAggregationStartedDate field is not present, we are going to assume that
            // it was aggregated more than 48 hours ago.
            let waitingTooLongToImport = true;
            if (
              data.firstAggregationStartedDate &&
              data.firstAggregationStartedDate > twoDaysBefore
            ) {
              waitingTooLongToImport = false;
            }
            if (waitingTooLongToImport) {
              html = BlockedTemplate(data);
            } else {
              html = AccountTemplate(data);
            }
          } else {
            //Not subscribed
            data.disableSubscribeButton =
              data.nextAction.hasUserSubscribedToNotification;
            html = UserSubscribeTemplate(data);
            this.setResolving();
          }
          break;
        case "WAIT":
          this.$el.addClass("refreshing");
          html = AccountTemplate(data);
          this.setProgressMessageTimeout();
          this.isExtraFormVisible = false;
          sidebarModel.trigger("account:wait");
          break;
        default:
          this.$el.addClass("normal");
          if (IS_EMPOWER && data.isEmpower) {
            const isCurrentContext = () => {
              if (data.empowerData?.accountSummary) {
                return data.empowerData?.accountSummary?.currentContext;
              }
              const nonMonthlyAccounts =
                data.empowerData?.accountSummaries?.nonMonthlyAccounts;
              const currentAccu = nonMonthlyAccounts.filter((val) => {
                return val.pcapAccountId === data.userAccountId.toString();
              });
              return currentAccu[0]?.currentContext;
            };
            data.isNewHire =
              // eslint-disable-next-line no-unneeded-ternary
              data.empowerData.enrollmentInfo.nqEnrollmentDate === "12/31/2099"
                ? true
                : false;
            data.showRollOverCTA = data.empowerAttributes?.showRollOverCTA;
            const isEmulatorMode = isEmpowerPaeMode();
            const hideAccount =
              isEmulatorMode && !isCurrentContext() ? " hidden" : "";
            const containerClassName =
              "sidebar-account__header qa-sidebar-account-header" +
              (data.empowerData?.enrollmentInfo?.isNQ &&
              data.empowerData?.enrollmentInfo?.isEligible &&
              data.empowerData?.enrollmentInfo?.showEnrollmentDate
                ? " sidebar-account__enroll-now--nq"
                : "") +
              hideAccount;
            html = EmpowerAccountTemplate({ ...data, containerClassName });
          } else {
            //INITIATE_REFRESH, VISIT_SITE, MORE_INFO, NONE
            if (this.model.attributes.zillowStatus === "INTERNAL_ERROR") {
              data.nextAction.nextActionMessage =
                "Zestimate service is temporarily down.";
            }
            // For all manually linked accounts (except for homes that have zillow evaluation),
            // do not show the timestamp, instead, show the text 'Manual Entry' with a tooltip.
            if (
              data.isManual &&
              (!data.isHome || (data.isHome && !data.useHomeValuation))
            ) {
              data.dontShowTimestamp = true;
              data.manualAccountTooltipText =
                "This account was manually added and is not connected to a financial institution; it does not reflect confirmed assets.";
            }
            html = AccountTemplate(data);
          }
          break;
      }
      if (this.accountBlockTooltipElement) {
        this.accountBlockTooltipElement.tooltip("destroy");
      }
      if (this.manualEntryTooltipElement) {
        this.manualEntryTooltipElement.tooltip("destroy");
      }
      this.$el.html(html);
      this.postRender();
    }
    return this;
  },
  moveItConfirm() {
    Mixpanel.trackEvent("Click Initial Move It", {
      component: "Sidebar",
    });
  },
  moveIt() {
    Mixpanel.trackEvent("Click Confirm Move It", {
      component: "Sidebar",
    });
    this.$(".js-move-it-confirmation").show().css("display", "flex");
    this.$(".js-move-it").hide();
  },
  submitSupportTicket() {
    $(".js-report-issue").prop("disabled", true);
    $(".js-report-text").text(submittedTicketText);

    const postData = {
      accountId: this.model.get("accountId"),
    };
    Services.SubmitSupportTicket.send(postData, function (err) {
      if (err) {
        Mixpanel.trackEvent("Support Ticket Failed to Send", {
          component: "Email Us Modal",
          postData: postData.reason + " for " + postData.accountId,
          confirm_success: false, //eslint-disable-line camelcase
        });
      } else {
        Mixpanel.trackEvent("Support Tick Sent Successfully ", {
          component: "Email Us Modal",
          confirm_success: true, //eslint-disable-line camelcase
          postData: postData.reason + " for " + postData.accountId,
        });
      }
    });
  },
  postRender: function () {
    if (this.model.get("isManual") === false) {
      this.$el.addClass("refreshable");
    }

    // allow users to refresh home account
    if (
      this.model.get("isHome") === true &&
      this.model.get("useHomeValuation") === true &&
      this.model.get("nextAction")?.action === "NONE"
    ) {
      this.$el.addClass("refreshable");
    }
    // mark manual if appropriate
    if (this.model.get("isManual") === true) {
      this.$el.addClass("isManual");
    }
    // mark isOnUs if appropriate
    if (
      this.model.get("isOnUs") === true ||
      this.model.get("isOnUs401K") === true
    ) {
      this.$el.addClass("isOnUs");
    }

    if (this.model.get("isOnUsBank")) {
      this.$el.addClass("isOnUsBank");
    }

    // Mark isPartner for partner account.
    if (this.model.get("isPartner")) {
      this.$el.addClass("isPartner");
    }

    // adding accountId as data attribute
    /* cspell:disable-next-line */
    this.$el.attr("data-accountid", this.model.get("accountId"));
    this.tooltipElement = this.$el.find('[data-toggle="tooltip"]');
    this.tooltipElement.tooltip({
      placement: "right",
      container: this.$el,
      trigger: tooltipTrigger,
    });
    this.accountBlockTooltipElement = this.$el.find(
      '[data-toggle="account-block-tooltip"]'
    );
    this.accountBlockTooltipElement.tooltip({
      placement: "right",
      container: "body",
      trigger: tooltipTrigger,
    });

    this.manualEntryTooltipElement = this.$el.find(
      '[data-toggle="manual-entry-tooltip"]'
    );
    this.manualEntryTooltipElement.tooltip({
      placement: "auto",
      container: "body",
      trigger: tooltipTrigger,
    });
  },
  onModelChange: function () {
    this.render();
  },
  onViewingAccountDetails: function () {
    this.$el.removeClass("selected");

    let hash = window.location.hash,
      selectedId;
    if (hash.match(/ua=/)) {
      // highlight by userAccountId
      selectedId = hash
        .match(/ua=[0-9]+/)[0]
        .substring(3); /* Previous impl (aka black magic)*/ //eslint-disable-line no-magic-numbers
    } else if (hash.match(/userProductId=/)) {
      // highlight by userProductId
      selectedId = hash
        .match(/userProductId=[0-9]+/)[0]
        .substring(14); /* Previous impl (aka black magic)*/ //eslint-disable-line no-magic-numbers
    } else if (hash.match(/a=/)) {
      selectedId = hash
        .match(/a=[0-9_]+/)[0]
        .substring(2); /* Previous impl (aka black magic)*/ //eslint-disable-line no-magic-numbers
    }
    if (
      !this.$el.hasClass("sidebar-account--on-us-bank-setup") &&
      selectedId &&
      this.model.get("userAccountId") === parseInt(selectedId, 10)
    ) {
      this.$el.addClass("selected");
      sidebarModel.trigger("ensureElementIsVisible", this.$el);
    }
  },
  onNotificationClicked: function (e) {
    if (e) {
      e.stopPropagation();
    }
    /*
     * Since we are using CSS transition to show/hide the refresh icon on the accounts AND transition cant animate
     * display property, we use visibility hidden to toggle the state. This if code is required to prevent executing
     * the refresh code when the refresh icon is not showing up.
     */
    if ($(e.target).css("visibility") === "visible") {
      if (this.model.get("nextAction")?.action === "NONE") {
        this.model.refresh();
      } else if (
        this.model.get("nextAction")?.action === "INITIATE_REFRESH" &&
        this.model.get("mfaType") !== "SECURITY_QUESTION"
      ) {
        this.refreshAccount();
      } else if (
        this.model.get("isHome") === true &&
        this.model.get("nextAction")?.action !== "NONE"
      ) {
        this.switchToEditMode(e);
      } else {
        this.setResolving();
      }
    }
  },
  setResolving: function (isUARError) {
    var account = this.model.toJSON();
    // If the account is in blackout and it has never aggregated successfully (is in still importing section)
    // prevent this view from expanding. Condition taken from accountGroup2.js@ownsAccount
    // TODO once we integrate this with the notify me project, this will be removed so the view expands and the notify me btn shows
    if (
      account.nextAction?.action === "BLACKOUT" &&
      (typeof account.lastRefreshed === "undefined" ||
        account.lastRefreshed < 0)
    ) {
      return;
    }
    sidebarModel.trigger("requestState", sidebarModel.EXPANDED);
    if (isUARError) {
      this.showExtrasInSiteView();
    } else {
      this.showExtras();
    }
  },
  setProgressMessageTimeout: function () {
    var self = this;
    setTimeout(function () {
      self.$el
        .find(".progressBar .progressMessage")
        .html("Verifying credentials...");
    }, PROGRESS_TIME_OUT);
  },
  closeAccount: function (e) {
    e.stopPropagation();
    if (!this.markAccountClosedModal) {
      this.markAccountClosedModal = document.createElement("div");
      this.markAccountClosedModal.className = "js-mark-account-closed-modal";
      document.body.appendChild(this.markAccountClosedModal);
    }

    ReactDOM.render(
      <Modal
        isOpen={true}
        componentName="MarkAccountAsClosedModal"
        title="Mark as Closed"
        className="pc-modal--small pc-modal--close-account"
        onClosed={this.handleMarkAccountClosedModalClose.bind(this)}
      >
        <MarkAccountAsClosedContainer
          model={this.model?.toJSON()}
          onCancel={this.handleMarkAccountClosedModalClose.bind(this)}
          onMarkAsClosed={this.handleMarkAccountClosedModalClose.bind(this)}
        />
      </Modal>,
      this.markAccountClosedModal
    );
  },

  confirmRemoval: function (e) {
    e.stopPropagation();
    this.isExtraFormVisible = false;

    if (!this.removeAccountModal) {
      this.removeAccountModal = document.createElement("div");
      this.removeAccountModal.className = "js-remove-account-modal";
      document.body.appendChild(this.removeAccountModal);
    }

    ReactDOM.render(
      <RemoveAccountContainer
        modalBody={
          <p>
            This permanently removes all transaction and historic data for this
            account. It will likely affect your aggregated balances, such as
            past net worth, and historic spending totals.
          </p>
        }
        modalConfirmButtonText="Remove"
        modalCancelButtonText="Cancel"
        modalTitle="Remove Account"
        modalClassName="js-remove-account-modal"
        componentName="RemoveAccountModal"
        service={() => removeAccountsByAccountId([this.model.get("accountId")])}
        onAccountRemoved={this.closeRemoveAccountModal.bind(this, true)}
        onCancel={this.closeRemoveAccountModal.bind(this, false)}
        isOpen={true}
      />,
      this.removeAccountModal
    );
  },
  closeRemoveAccountModal: function (isAccountRemoved) {
    if (this.removeAccountModal) {
      ReactDOM.unmountComponentAtNode(this.removeAccountModal);
    }

    if (isAccountRemoved) {
      sidebarModel.trigger("account:remove");
    }
  },
  handleMarkAccountClosedModalClose: function () {
    if (this.markAccountClosedModal) {
      ReactDOM.unmountComponentAtNode(this.markAccountClosedModal);
    }
  },

  refreshAccount: function (e) {
    if (e) {
      e.stopPropagation();
      $(e.currentTarget).text("Connecting...");
    }
    this.refreshedAccount = true;
    this.model.refresh();
  },
  cancel: function (e) {
    if (e == null || !$(e.currentTarget).hasClass("disabled")) {
      e.stopPropagation();
      if (this.$el.hasClass("editing")) {
        this.cancelEdits();
      } else {
        this.hideExtras();
        sidebarModel.trigger("account:cancel");
      }
      this.$el.removeClass(`js-editing-form ${IS_OPEN_CLASSNAMES}`);
    }
  },
  cancelEdits: function () {
    this.editing = false;
    this.render();
  },

  onSidebarStateChanged: function (model, state) {
    if (state !== model.EXPANDED) {
      this.hideExtras();
      if (this.editing) {
        this.editing = false;
        this.render();
      }
    }
  },
  onFirmNameClicked: function (e) {
    // PFA-6386 hack
    if (this.model.isPlainManualAccount()) {
      this.switchToEditMode(e);
    }
  },
  /* eslint-disable sonarjs/cognitive-complexity */
  onViewClicked: function (e) {
    const data = this.model.toJSON();
    // If they didn't click on the notification icon or the edit icon
    if (
      !$(e.target).hasClass("notificationIcon") &&
      !$(e.target).hasClass("edit") &&
      !this.$el.hasClass("resolving") &&
      !this.model.get("isHome") &&
      !this.$el.hasClass("editing")
    ) {
      //if the account is in error state
      if (ACCOUNT_ERROR_STATE.includes(this.model.get("nextAction")?.action)) {
        //Show error on the site
        this.setResolving(true);
      } else if (this.model.get("nextAction")?.action === "BLOCKED") {
        //if the account was first aggregated more than 2 days ago, do not return here.
        let waitingTooLongToImport = false;
        const twoDaysBefore = moment().subtract({ hours: 48 });
        if (data.firstAggregationStartedDate < twoDaysBefore) {
          waitingTooLongToImport = true;
        }
        if (
          this.model.get("nextAction")?.hasUserSubscribedToNotification &&
          !waitingTooLongToImport
        ) {
          return;
        }
        // Show the actions and force them to resolve the issues
        this.setResolving();
      }

      if (IS_EMPOWER) {
        const empowerAccountOverviewUrl =
          this.model.get("empowerData")?.accountSummary?.accountURL;
        if (empowerAccountOverviewUrl) {
          window.location =
            empowerAccountOverviewUrl.charAt(0) === "/"
              ? empowerAccountOverviewUrl
              : "/" + empowerAccountOverviewUrl;

          return;
        }
      }

      let url = detailsLink2.generateDetailsURL(this.model.toJSON());
      if (url !== "") {
        // show the details page
        window.location.href = url;
      }
    }
  },
  switchToEditMode: function (e) {
    var data = this.model.toJSON();
    if (this.isAccountEditable(data)) {
      this.editing = true;
      this.render();
    }
    if (IS_EMPOWER) {
      const eventBus = window.dashboardUtils
        ? window.dashboardUtils.eventBus
        : null;
      const eventName = "sidebar_edit_account_clicked_event";
      if (eventBus) {
        eventBus.dispatch(eventName);
        eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
            "select_button",
          event_properties: { selection: eventName }
        });
      }
    }
    e.stopPropagation();
  },
  showExtras: function () {
    this.isExtraFormVisible = true;
    this.$el
      .find(".js-account-extras")
      .removeClass("is-hidden")
      .slideDown(ANIMATION_TIME, function () {
        sidebarModel.trigger("refreshScrollBar");
      });
    this.$el.addClass(`resolving ${IS_OPEN_CLASSNAMES}`);
    if (this.model.get("nextAction")?.action === "MORE_INFO") {
      this.$el.addClass("js-editing-form");
    }
    sidebarModel.trigger("ensureElementIsVisible", this.$el);
  },
  showExtrasInSiteView: function () {
    sidebarModel.trigger("showSiteExtras", this.model.get("userSiteId"));
  },
  hideExtras: function () {
    this.isExtraFormVisible = false;
    var extrasContainer = this.$el.find(".js-account-extras");
    extrasContainer.slideUp(ANIMATION_TIME, function () {
      extrasContainer.addClass("is-hidden");
      sidebarModel.trigger("refreshScrollBar");
    });
    this.$el.removeClass(`resolving ${IS_OPEN_CLASSNAMES}`);
  },
  zestimateCopyClick: function (e) {
    e.stopPropagation();
  },
  remove: function () {
    if (this.accountBlockTooltipElement) {
      this.accountBlockTooltipElement.tooltip("destroy");
    }
    if (this.manualEntryTooltipElement) {
      this.manualEntryTooltipElement.tooltip("destroy");
    }
    Backbone.View.prototype.remove.apply(this, arguments);
  },

  notifyMeWhenUpdatedClick: function (e) {
    e.target.disabled = true;
    Services.Profile.updateUserSitePreferences({
      userSiteId: this.model.get("userSiteId"),
      subscribeForNotification: true,
    });
    Mixpanel.trackEvent("Click Notify Me", {
      component: "Sidebar",
    });
  },

  handleEnrollNowClick: function (e) {
    if (e) {
      e.stopPropagation();
    }
    let empowerEnrollmentInfo = this.model.get("empowerData").enrollmentInfo;

    if (
      empowerEnrollmentInfo.isLockHeed !== undefined &&
      empowerEnrollmentInfo.isLockHeed.groupLockheedPlan
    ) {
      window.dispatchEvent(
        new CustomEvent("pc_showLockheedModal", {
          detail: {
            response: empowerEnrollmentInfo.isLockHeed,
          },
        })
      );
    } else {
      let enrollNowPage = empowerEnrollmentInfo.redirectUrl;
      // redirect within domain
      window.location =
        enrollNowPage.charAt(0) === "/" ? enrollNowPage : "/" + enrollNowPage;
    }
  },

  handleWithdrawalsAndRolloverClick: function (e) {
    if (e) {
      e.stopPropagation();
    }
    const empowerAttributes = this.model.get("empowerAttributes");

    const contextId = empowerAttributes?.contextId;
    const planIdParts = empowerAttributes?.planId.split("_");
    const planId = planIdParts[0];
    // redirect within domain
    if (contextId && planId) {
      window.location =
        "/participant/accounts/#/account/" +
        contextId +
        "/" +
        planId +
        "/distribution/landing";
    }
  },
});
export default AccountView;
