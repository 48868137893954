import React from "react";
import moment from "moment";
import DateUtils from "libs/pcap/utils/date";
import { formatCurrency } from "libs/pcap/utils/format";
import { frequencyLabel } from "components/transferFunds/utils/labels";
import {
  ACTION_DELETE,
  ACTION_EDIT,
  STAGE_PENDING,
} from "components/transferFunds/utils/constants";

export const accountNameFormatter = (firmName, accountName) => {
  return (
    <>
      <div className="u-text-bold"> {firmName}</div>
      <span className="recurring-transfers-table__text-secondary">
        {accountName}
      </span>
    </>
  );
};

export const actionsFormatter = (d, actionHandlers) => {
  let actionColumnValue = [];
  const hasDeleteAction = d?.actions?.includes(ACTION_DELETE);
  const hasEditAction = d?.actions?.includes(ACTION_EDIT);

  if (hasEditAction && actionHandlers?.onEdit) {
    actionColumnValue.push(
      <button
        key="key-edit-transfer-button"
        type="button"
        className="pc-btn pc-btn--stripped pc-u-p0 recurring-transfers-action-edit qa-recurring-transfers-table-action-edit"
        onClick={() => {
          if (d?.state !== STAGE_PENDING) {
            actionHandlers.onEdit(d);
          }
        }}
      >
        <svg className="icon-svg">
          <use href="#icon-pencil"></use>
        </svg>
      </button>
    );
  }
  if (hasDeleteAction && actionHandlers?.onDelete) {
    actionColumnValue.push(
      <button
        key="key-delete-transfer-button"
        type="button"
        className="pc-btn pc-btn--stripped pc-u-p0 recurring-transfers-action-delete qa-recurring-transfers-table-action-delete"
        onClick={() => {
          if (d?.state !== STAGE_PENDING) {
            actionHandlers.onDelete(d);
          }
        }}
      >
        <svg className="icon-svg">
          <use href="#pc-icon__close-x"></use>
        </svg>
      </button>
    );
  }

  return actionColumnValue;
};

// default table configuration without actions
export const DEFAULT_COLUMNS = [
  {
    header: "From",
    accessor: (a) => a,
    formatter: (a) => accountNameFormatter(a.fromFirm, a.fromName),
    className:
      "table-column-recurring-transfer-account--default qa-recurring-transfers-table-from",
  },
  {
    header: "To",
    accessor: (d) => d,
    formatter: (a) => accountNameFormatter(a.toFirm, a.toName),
    className:
      "table-column-recurring-transfer-account--default qa-recurring-transfers-table-to",
  },
  {
    header: "Amount",
    accessor: (d) => d.transferAmount,
    formatter: (a) => formatCurrency(a),
    className:
      "table-column-recurring-transfer-amount--default qa-recurring-transfers-table-amount",
  },
  {
    header: "Frequency",
    accessor: (d) => d.frequency,
    formatter: (d) => frequencyLabel(d),
    className:
      "table-column-recurring-transfer-frequency--default qa-recurring-transfers-table-frequency",
  },
  {
    header: "Next Request",
    accessor: (d) => d.nextTransferDate,
    formatter: (d) => (d ? moment(d).format(DateUtils.DISPLAY_FORMAT) : ""),
    className:
      "table-column-recurring-transfer-next--default qa-recurring-transfers-table-next-date",
  },
];

export const WITH_ACTIONS_COLUMNS = (actionHandlers) => {
  return [
    {
      header: "From",
      accessor: (a) => a,
      formatter: (a) => accountNameFormatter(a.fromFirm, a.fromName),
      className:
        "table-column-recurring-transfer-account--with-actions qa-recurring-transfers-table-from",
    },
    {
      header: "To",
      accessor: (d) => d,
      formatter: (a) => accountNameFormatter(a.toFirm, a.toName),
      className:
        "table-column-recurring-transfer-account--with-actions qa-recurring-transfers-table-to",
    },
    {
      header: "Amount",
      accessor: (d) => d.transferAmount,
      formatter: (a) => formatCurrency(a),
      className:
        "table-column-recurring-transfer-amount--with-actions qa-recurring-transfers-table-amount",
    },
    {
      header: "Frequency",
      accessor: (d) => d.frequency,
      formatter: (d) => frequencyLabel(d),
      className:
        "table-column-recurring-transfer-frequency--with-actions qa-recurring-transfers-table-frequency",
    },
    {
      header: "Next Request",
      accessor: (d) => d.nextTransferDate,
      formatter: (d) => (d ? moment(d).format(DateUtils.DISPLAY_FORMAT) : ""),
      className:
        "table-column-recurring-transfer-next--with-actions qa-recurring-transfers-table-next-date",
    },
    {
      header: "Actions",
      accessor: (d) => d,
      formatter: (d) => actionsFormatter(d, actionHandlers),
      className:
        "table-column-recurring-transfer-actions--with-actions qa-recurring-transfers-table-actions",
    },
  ];
};
