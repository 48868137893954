import { Events } from "backbone";
import Services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import accountToClientModel from "accessors/account/mappers/toClient";
import { STATE_BLOCKED } from "utils/account";

function mergeResponses(accounts, states) {
  return accounts
    .filter((a) => a.userAccountId != null)
    .map((a) => {
      const accountStateDetails = states.find(
        (as) => as.userAccountId === a.userAccountId
      )?.details;

      const accountState = accountStateDetails
        ? accountStateDetails.reduce((result, cur) => {
            result[cur.type] = {
              state: cur.state,
            };
            if (cur.action) {
              result[cur.type].action = cur.action;
            }
            return result;
          }, {})
        : { PCB: { state: STATE_BLOCKED }, PERSHING: { state: STATE_BLOCKED } };
      return Object.assign({}, a, { stateForTransfer: accountState });
    });
}

export default function subscribeToAccountsAndStateForTransfer() {
  const result = Object.assign({}, Events);
  let watchProductAccounts;
  let watchStateForTransfer;
  let accounts = [];
  let statesForTransfer = [];
  result.unwatch = () => {
    if (watchProductAccounts) {
      Services.Accounts.get.unwatch(watchProductAccounts);
    }
    if (watchStateForTransfer) {
      Services.Transfer.getStateForTransfer.unwatch(watchStateForTransfer);
    }
  };
  result.promise = new Promise((resolve, reject) => {
    let responseCount = 0;
    watchProductAccounts = Services.Accounts.get.watch({}, (err, response) => {
      const errors = parseResponseErrors(err, response);
      if (errors) {
        reject(errors);
        return;
      }

      responseCount++;
      accounts = ((response.spData && response.spData.accounts) || []).map(
        accountToClientModel
      );
      if (responseCount > 2) {
        result.trigger("change", mergeResponses(accounts, statesForTransfer));
      } else if (responseCount === 2) {
        resolve(mergeResponses(accounts, statesForTransfer));
      }
    });
    watchStateForTransfer = Services.Transfer.getStateForTransfer.watch(
      {},
      (err, response) => {
        const errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }

        responseCount++;
        statesForTransfer = response.spData;
        if (responseCount > 2) {
          result.trigger("change", mergeResponses(accounts, statesForTransfer));
        } else if (responseCount === 2) {
          resolve(mergeResponses(accounts, statesForTransfer));
        }
      }
    );
  });
  return result;
}
