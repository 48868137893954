import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "libs/pcap/utils/format";
import { fromNow } from "libs/pcap/utils/fromNow";

const RemoveAccountView = (props) => {
  const { model, hideMarkAsClosedMessage } = props;
  let isCreditAccount = model.productType === "CREDIT_CARD";
  let balance = model.balance;
  if (isCreditAccount) {
    balance *= -1;
  }

  return (
    <div>
      {model.logoPath && (
        <div className="edit-account__logo-container">
          <img src={model.logoPath} className="u-full-center--abs" />
        </div>
      )}
      <div className="l-spaced l-spaced--flush edit-account__text--info">
        <div className="u-text-bold">{model.firmName}</div>
        <div className="u-text-bold">{formatCurrency(balance)}</div>
      </div>
      <div className="l-spaced l-spaced--flush edit-account__text--info">
        <div>{model.name}</div>
        <div>{fromNow(model.lastRefreshed)}</div>
      </div>
      <div className="pc-u-mv-">
        This <span className="u-text-bold">permanently removes</span> all
        transaction and historic data for this account. It will likely affect
        your aggregated balances, such as past net worth, and historic spending
        totals.
        {`${
          hideMarkAsClosedMessage
            ? ""
            : " If you want to keep the historic data, you can mark the account as closed."
        }`}
      </div>
    </div>
  );
};

RemoveAccountView.propTypes = {
  model: PropTypes.object.isRequired,
  hideMarkAsClosedMessage: PropTypes.bool,
};

RemoveAccountView.defaultProps = {
  hideMarkAsClosedMessage: false,
};

export default RemoveAccountView;
