var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "u-invisible";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"chart-legend chart-legend--line chart-legend--cash-flow js-chart-legend "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"previousMonthLabel") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":83},"end":{"line":1,"column":135}}})) != null ? stack1 : "")
    + "\">\n  <li class=\"chart-legend__item js-current-month "
    + alias4(((helper = (helper = lookupProperty(helpers,"classNameLegendCurrent") || (depth0 != null ? lookupProperty(depth0,"classNameLegendCurrent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classNameLegendCurrent","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":75}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currentMonthLabel") || (depth0 != null ? lookupProperty(depth0,"currentMonthLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currentMonthLabel","hash":{},"data":data,"loc":{"start":{"line":2,"column":77},"end":{"line":2,"column":98}}}) : helper)))
    + "</li>\n  <li class=\"chart-legend__item chart-legend__item--last-month js-previous-month "
    + alias4(((helper = (helper = lookupProperty(helpers,"classNameLegendPrevious") || (depth0 != null ? lookupProperty(depth0,"classNameLegendPrevious") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classNameLegendPrevious","hash":{},"data":data,"loc":{"start":{"line":3,"column":81},"end":{"line":3,"column":108}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"previousMonthLabel") || (depth0 != null ? lookupProperty(depth0,"previousMonthLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"previousMonthLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":110},"end":{"line":3,"column":132}}}) : helper)))
    + "</li>\n</ul>\n<svg class=\"svg-chart svg-chart--cash-flow\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"graphAltText") || (depth0 != null ? lookupProperty(depth0,"graphAltText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"graphAltText","hash":{},"data":data,"loc":{"start":{"line":5,"column":49},"end":{"line":5,"column":67}}}) : helper)))
    + "\"></svg>\n";
},"useData":true});