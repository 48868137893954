import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "libs/pcap/utils/format";
import LoadingOverlay from "components/common/LoadingOverlay";

const PERCENTAGE_MULTIPLIER = 100;

export function renderAddAccountButton({
  isRetired,
  noTaxable,
  noTaxAdvantaged,
  renderButtons,
}) {
  const sourceName = "My_Strategy_Dividends_Received";
  if (noTaxable) {
    return renderButtons(
      "Take advantage of tax loss harvesting.",
      "Open a Taxable Account",
      sourceName
    );
  } else if (noTaxAdvantaged) {
    if (isRetired) {
      return renderButtons(
        "Save more for retirement.",
        "Open a Tax Advantaged Account",
        sourceName
      );
    }
    return renderButtons(
      "Consolidate retirement accounts.",
      "Open a Tax Advantaged Account",
      sourceName
    );
  }
  return null;
}

const DividendsReceivedWidget = (props) => {
  let {
    titleClassName,
    isRetired,
    loading,
    normalDividends,
    noTaxable,
    noTaxAdvantaged,
    renderButtons,
    taxAdvantagedDividends,
    totalDividends,
    totalDividendsMonthly,
    isEmpower,
  } = props;

  return (
    <div className="summary-widget qa-dividends-received-widget">
      <LoadingOverlay active={loading} className="pc-overlay--solid" />
      <div className="l-spaced l-spaced--flush">
        <h2
          className={`js-dividends-received-widget__title qa-dividends-received-widget__title dividends-received-widget__title ${titleClassName}`}
        >
          Dividends
        </h2>
        <h4 className="dividends-received-widget__title dividends-received-widget__title--right">
          {formatCurrency(totalDividends)}
        </h4>
      </div>
      <div className="l-spaced l-spaced--flush">
        <p className="dividends-received-widget__sub-title">
          {formatCurrency(totalDividendsMonthly)}{" "}
          <span
            className={`u-sentence-case personal-strategy-widget__sub-title`}
          >
            This Month
          </span>
        </p>
        <p
          className={`u-sentence-case dividends-received-widget__sub-title dividends-received-widget__sub-title--right-gray u-text-right personal-strategy-widget__sub-title`}
        >
          This Year
        </p>
      </div>
      {!noTaxable && (
        <div className="dividends-received-widget--reduced-margin">
          <div className="dividends-received-widget__graphs_container">
            <p className="dividends-received-widget__graph_text">Taxable</p>
            <p className="dividends-received-widget__graph_text">
              {formatCurrency(normalDividends, 0)}
            </p>
          </div>
          <div className="dividends-received-widget__graph dividends-received-widget__graph-background">
            <div
              className="dividends-received-widget__graph dividends-received-widget__graph-filler js-taxable"
              data-testid="taxable"
              style={{
                width: `${
                  (normalDividends / totalDividends) * PERCENTAGE_MULTIPLIER
                }%`,
              }}
            ></div>
          </div>
        </div>
      )}
      {!noTaxAdvantaged && (
        <div>
          <div className="dividends-received-widget__graphs_container">
            <p className="dividends-received-widget__graph_text">
              Tax-Advantaged
            </p>
            <p className="dividends-received-widget__graph_text">
              {formatCurrency(taxAdvantagedDividends, 0)}
            </p>
          </div>
          <div className="dividends-received-widget__graph dividends-received-widget__graph-background">
            <div
              className="dividends-received-widget__graph dividends-received-widget__graph-filler dividends-received-widget__tax-advantage js-tax-advantaged"
              data-testid="tax-advantaged"
              style={{
                width:
                  (taxAdvantagedDividends / totalDividends) *
                    PERCENTAGE_MULTIPLIER +
                  "%",
              }}
            ></div>
          </div>
        </div>
      )}
      {!isEmpower &&
        renderAddAccountButton({
          isRetired,
          noTaxable,
          noTaxAdvantaged,
          renderButtons,
        })}
    </div>
  );
};

DividendsReceivedWidget.propTypes = {
  titleClassName: PropTypes.string,
  isRetired: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  normalDividends: PropTypes.number.isRequired,
  noTaxable: PropTypes.bool.isRequired,
  noTaxAdvantaged: PropTypes.bool.isRequired,
  renderButtons: PropTypes.func.isRequired,
  taxAdvantagedDividends: PropTypes.number.isRequired,
  totalDividends: PropTypes.number.isRequired,
  totalDividendsMonthly: PropTypes.number.isRequired,
  isEmpower: PropTypes.bool,
};

DividendsReceivedWidget.defaultProps = {
  titleClassName: "",
  isEmpower: false,
};

export default DividendsReceivedWidget;
