import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import AccountTypeCombo from "components/common/EditAccount/AccountTypeCombo";
import { CSSTransition } from "react-transition-group";
import { filterOutOptions } from "components/modal/editAccount/utils/selectOptions";
import EnumJointAccountTypes from "enums/jointAccountTypes";
import {
  isJointAccount,
  isEmployerSponsoredRetirementAccount,
  isTrust,
  isOwnerShareSupported,
} from "utils/account";
import FancySelect from "components/common/form/FancySelect/FancySelect";
import trustTypes from "enums/kindsOfTrust";
import secondaryTrustTypes from "enums/kindsOfTrustSecondary.js";
import { CURRENCY_FORMAT } from "components/common/form/formattingOptions";
import { hasPrintableCharacters } from "common/validators/functions.js";
import { POSITIVE_CURRENCY_FORMAT } from "components/common/form/formattingOptions";
import ButtonGroup from "components/common/buttonGroup";
import { isEmpowerPaeMode } from "../../../views/modules/sidebar/utils/accountUtils";

const MAX_ACCOUNT_NUMBER_LENGTH = 50;
const MAX_BALANCE_DIGITS = 19;
const MAX_PERCENT_DIGITS = 4; // 4 to support decimal values such as 66.6
const MAX_DOLLAR_DIGITS = 13;

function getMatchLimitProps(model, validator) {
  if (model.employerMatchLimitType === "dollar") {
    return {
      id: "eaMaxMatchDollarValue",
      name: "maxMatchDollarValue",
      maxLength: MAX_DOLLAR_DIGITS,
      prefix: "",
      suffix: "$",
      validator: validator.properties.maxMatchDollarValue,
      value: model.maxMatchDollarValue || "", // don't let inactive values default to zero
    };
  }

  // percent
  return {
    name: "maxMatchPercentOfSalary",
    maxLength: MAX_PERCENT_DIGITS,
    prefix: "",
    suffix: "%",
    validator: validator.properties.maxMatchPercentOfSalary,
    value: model.maxMatchPercentOfSalary || "", // don't let inactive values default to zero
  };
}

const isEmulatorMode = isEmpowerPaeMode();

class FinancialAssetAccountDetails extends React.Component {
  renderTrustSection({ model, disabled }) {
    let trustType = "",
      secondaryTrustType = "";

    if (model.additionalAttributes && model.additionalAttributes.trust) {
      trustType = model.additionalAttributes.trust.kindOfTrust || "";
      secondaryTrustType =
        model.additionalAttributes.trust.kindOfTrustSecondary || "";
    }

    return (
      <div className="pc-form-group--small">
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/3">
            <label htmlFor="eaKindOfTrust" className="pc-label--tiny">
              Trust type
            </label>
          </div>
          <div className="pc-layout__item pc-u-2/3">
            <div className="pc-layout">
              <div className="pc-layout__item pc-u-1/2">
                <FancySelect
                  id="eaKindOfTrust"
                  name="additionalAttributes.trust.kindOfTrust"
                  ref={this.props.storeInputRef}
                  value={trustType}
                  className={`input--full qa-trust-type "Select--mini`}
                  onChange={this.props.onInputChange}
                  validator={this.props.schema.properties.trustType}
                  options={trustTypes}
                  isDisabled={disabled}
                  ariaLabel="Trust type"
                />
              </div>
              <div className="pc-layout__item pc-u-1/2">
                <FancySelect
                  id="eaKindOfTrustSecondary"
                  name="additionalAttributes.trust.kindOfTrustSecondary"
                  ref={this.props.storeInputRef}
                  value={secondaryTrustType}
                  className={`input--full qa-secondary-trust-type Select--mini`}
                  onChange={this.props.onInputChange}
                  validator={this.props.schema.properties.trustSecondaryType}
                  options={secondaryTrustTypes}
                  isDisabled={disabled}
                  ariaLabel={`Type of ${trustType} Trust`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOwnershipType({ model, ownershipTypes, disabled }) {
    const sentenceCaseClassName = "u-sentence-case u-display-block";

    return (
      <div>
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/3">
            <label
              htmlFor="eaOwnershipType"
              className={`pc-label--tiny ${sentenceCaseClassName}`}
            >
              Ownership Type
            </label>
          </div>
          <div className="pc-layout__item pc-u-2/3">
            <FancySelect
              id="eaOwnershipType"
              name="ownershipType"
              ref={this.props.storeInputRef}
              value={model.ownershipType}
              className={`input--full qa-ownership-type Select--mini`}
              onChange={this.props.onInputChange}
              validator={this.props.schema.properties.ownershipType}
              options={ownershipTypes}
              isDisabled={disabled}
              menuPortalTarget={document.body}
              ariaLabel="Ownership type"
            />
          </div>
        </div>
      </div>
    );
  }

  renderJointAccountType({ additionalAttributes, disabled }) {
    return (
      <div className="pc-form-group--small">
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/3">
            <label htmlFor="eaJointAccountType" className="pc-label--tiny">
              What kind of joint account?
            </label>
          </div>
          <div className="pc-layout__item pc-u-2/3">
            <FancySelect
              id="eaJointAccountType"
              name="additionalAttributes.jointAccountType"
              ref={this.props.storeInputRef}
              value={additionalAttributes.jointAccountType}
              className={`input--full qa-joint-account-type Select--mini`}
              onChange={this.props.onInputChange}
              validator={
                this.props.schema.properties.additionalAttributes.properties
                  .jointAccountType
              }
              options={EnumJointAccountTypes}
              isDisabled={disabled}
              ariaLabel="What kind of joint account?"
            />
          </div>
        </div>
      </div>
    );
  }

  renderOwnersSection({ model, beneficiaries, disabled }) {
    const primaryOwners = model.stakeholders.PRIMARY || [];
    const primaryOwner =
      (primaryOwners.length &&
        primaryOwners[0].personId &&
        primaryOwners[0].personId.toString()) ||
      "";
    const { optionsPeople } = this.props;
    const beneficiariesIds = beneficiaries.map((b) => String(b.personId));
    const optionsPrimaryOwner = filterOutOptions(
      optionsPeople,
      [model.secondaryOwner].concat(beneficiariesIds)
    );

    const sentenceCaseClassName = "u-sentence-case u-display-block";

    return (
      <div>
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/3">
            <label
              htmlFor="eaAccountOwnerInput"
              className={`pc-label--tiny ${sentenceCaseClassName}`}
            >
              Account Owner
            </label>
          </div>
          <div className="pc-layout__item pc-u-2/3">
            <FancySelect
              inputId="eaAccountOwnerInput"
              id="eaAccountOwner"
              name="stakeholders.PRIMARY.0.personId"
              ref={this.props.storeInputRef}
              value={primaryOwner}
              className={`input--full qa-primary-owner Select--mini`}
              onChange={this.props.onInputChange}
              validator={this.props.schema.properties.primaryOwner}
              options={optionsPrimaryOwner}
              menuPortalTarget={document.body}
              isDisabled={disabled}
              ariaLabel="Account owner"
            />
          </div>
        </div>
      </div>
    );
  }

  renderAdditionalOwner({ model, beneficiaries, disabled }) {
    const secondaryOwners = model.stakeholders.SECONDARY || [];
    const secondaryOwner =
      (secondaryOwners.length &&
        secondaryOwners[0].personId &&
        secondaryOwners[0].personId.toString()) ||
      "";
    const beneficiariesIds = beneficiaries.map((b) => String(b.personId));
    const optionsSecondaryOwner = filterOutOptions(
      this.props.optionsPeople,
      [model.primaryOwner].concat(beneficiariesIds)
    );

    return (
      <div className="pc-form-group--small">
        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/3">
            <label htmlFor="eaSecondaryOwner" className="pc-label--tiny">
              Additional Owner
            </label>
          </div>
          <div className="pc-layout__item pc-u-2/3">
            <FancySelect
              id="eaSecondaryOwner"
              name="stakeholders.SECONDARY.0.personId"
              ref={this.props.storeInputRef}
              value={secondaryOwner}
              className={`input--full qa-secondary-owner Select--mini`}
              onChange={this.props.onInputChange}
              validator={this.props.schema.properties.secondaryOwner}
              options={optionsSecondaryOwner}
              menuPortalTarget={document.body}
              isDisabled={disabled}
              ariaLabel="Additional owner"
            />
          </div>
        </div>
      </div>
    );
  }

  renderRetirementDetails({ model, additionalAttributes, disabled = false }) {
    const {
      onInputChange,
      storeInputRef,
      schema,
      onMatchProfitChange,
      currentMatchProfitValue,
      onToggleChange,
    } = this.props;
    const employerMatchProfitValues = [
      { label: "Both", value: "BOTH" },
      { label: "Match", value: "MATCH" },
      { label: "Profit Sharing", value: "PROFIT_SHARING" },
      { label: "Neither", value: "NEITHER" },
    ];
    const toggleOptions = (isLowerCase) => [
      {
        label: "$",
        value: isLowerCase ? "DOLLAR".toLowerCase() : "DOLLAR",
      },
      {
        label: "%",
        value: isLowerCase ? "PERCENT".toLowerCase() : "PERCENT",
      },
    ];
    const matchLimitProps = getMatchLimitProps(model, schema);

    return (
      <div>
        <div className="pc-form-group--small">
          <div className="pc-layout">
            <fieldset
              className="current-employer"
              aria-label="Is this plan from your current employer?"
            >
              <div className="pc-layout__item pc-u-2/3">
                <label className="pc-label--tiny">
                  Is this plan from your current employer?
                </label>
              </div>

              <div className="pc-layout__item pc-u-1/3">
                <label className="pc-label pc-label--inline pc-label--tiny">
                  <input
                    type="radio"
                    name="additionalAttributes.isCurrentEmployer"
                    className="qa-plan-source"
                    ref={storeInputRef}
                    value="Y"
                    disabled={disabled}
                    checked={additionalAttributes.isCurrentEmployer === "Y"}
                    onChange={onInputChange}
                  />
                  Yes
                </label>
                <label className="pc-label pc-label--inline pc-label--tiny pc-u-ml">
                  <input
                    type="radio"
                    name="additionalAttributes.isCurrentEmployer"
                    className="qa-plan-source"
                    ref={storeInputRef}
                    value="N"
                    disabled={disabled}
                    checked={additionalAttributes.isCurrentEmployer === "N"}
                    onChange={onInputChange}
                  />
                  No
                </label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="pc-form-group--small">
          <div className="pc-layout">
            <div className="pc-layout__item pc-u-5/7">
              <label
                htmlFor="eaProfitContributionInput"
                className="pc-label--tiny"
              >
                Does your employer have a match or profit sharing?
              </label>
            </div>
            <div className="pc-layout__item pc-u-2/7">
              <FancySelect
                inputId="eaProfitContributionInput"
                ariaLabel={"Does your employer have a match or profit sharing?"}
                id="eaProfitContribution"
                name="currentMatchProfitValue"
                ref={storeInputRef}
                value={currentMatchProfitValue}
                className={`input--full qa-current-match-profit-value Select--mini`}
                onChange={onMatchProfitChange}
                validator={this.props.schema.properties.primaryOwner}
                options={employerMatchProfitValues}
                menuPortalTarget={document.body}
                isDisabled={disabled}
              />
            </div>
          </div>
        </div>
        {model.hasSponsorMatch === "Y" &&
          additionalAttributes.isCurrentEmployer === "Y" && (
            <div>
              <div className="pc-form-group--small">
                <div className="pc-layout">
                  <div className="pc-layout__item pc-u-1/3">
                    <label
                      htmlFor="eaMatchPercentOfContribution"
                      className="pc-label--tiny"
                    >
                      Employer Match
                    </label>
                  </div>
                  <div className="pc-layout__item pc-u-2/3">
                    <Input
                      type="text"
                      id="eaMatchPercentOfContribution"
                      name="matchPercentOfContribution"
                      ref={storeInputRef}
                      value={model.matchPercentOfContribution}
                      disabled={disabled}
                      className="input--tiny qa-match-percent-contribution"
                      maxLength={MAX_PERCENT_DIGITS}
                      suffix="%"
                      sizeVariation="full"
                      onChange={onInputChange}
                      formattingOptions={POSITIVE_CURRENCY_FORMAT}
                      validator={schema.properties.matchPercentOfContribution}
                      ariaLabel="Employer match"
                    />
                  </div>
                </div>
              </div>
              <div className="pc-form-group--small">
                <div className="pc-layout">
                  <div className="pc-layout__item pc-u-1/3">
                    <label
                      htmlFor="eaContributionValue"
                      className="pc-label--tiny"
                    >
                      Employer match limit
                    </label>
                  </div>
                  <div className="pc-layout__item pc-u-2/3">
                    <div className="pc-layout">
                      <div className="pc-layout__item pc-u-1/3">
                        <ButtonGroup
                          selectedValue={model.employerMatchLimitType}
                          options={toggleOptions(true)}
                          className="retirement-toggle"
                          onChange={(e) =>
                            onToggleChange(e, "employerMatchLimitType")
                          }
                        />
                      </div>
                      <div className="pc-layout__item pc-u-2/3 pc-u-pl0">
                        <Input
                          id="eaContributionValue"
                          type="text"
                          {...matchLimitProps}
                          ref={storeInputRef}
                          disabled={disabled}
                          className="input--tiny qa-contribution_limit"
                          sizeVariation="full"
                          onChange={this.props.onEmployerMatchLimitChange}
                          ariaLabel="Employer match limit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {model.hasProfitSharing === "Y" &&
          additionalAttributes.isCurrentEmployer === "Y" && (
            <div className="pc-form-group--small">
              <div className="pc-layout">
                <div className="pc-layout__item pc-u-1/3">
                  <label
                    htmlFor="eaProfitSharingValue"
                    className="pc-label--tiny"
                  >
                    Profit sharing
                  </label>
                </div>
                <div className="pc-layout__item pc-u-2/3">
                  <div className="pc-layout">
                    <div className="pc-layout__item pc-u-1/3">
                      <ButtonGroup
                        selectedValue={model.profitSharingUnit}
                        options={toggleOptions()}
                        className="retirement-toggle"
                        onChange={(e) => onToggleChange(e, "profitSharingUnit")}
                      />
                    </div>
                    <div className="pc-layout__item pc-u-2/3 pc-u-pl0">
                      <Input
                        type="text"
                        id="eaProfitSharingValue"
                        name="profitSharingValue"
                        ref={storeInputRef}
                        value={model.profitSharingValue}
                        disabled={disabled}
                        className="input--tiny qa-profit-sharing"
                        maxLength={
                          model.profitSharingUnit === "PERCENT"
                            ? MAX_PERCENT_DIGITS
                            : MAX_DOLLAR_DIGITS
                        }
                        suffix={
                          toggleOptions().filter(
                            (option) => option.value === model.profitSharingUnit
                          )[0]?.label
                        }
                        sizeVariation="full"
                        onChange={onInputChange}
                        formattingOptions={POSITIVE_CURRENCY_FORMAT}
                        validator={
                          model.profitSharingUnit === "PERCENT"
                            ? schema.properties.profitSharingValuePercent
                            : schema.properties.profitSharingValueDollar
                        }
                        ariaLabel="Profit sharing"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }

  render() {
    const { model, onInputChange, ownershipTypes } = this.props;
    const { isOnUsBank, isOnUs, isOnUsRetirement } = model;
    const hasTrustType = isTrust(model.accountTypeGroup, model.ownershipType);
    const hasOwnershipType = ownershipTypes && ownershipTypes.length > 1;
    const additionalAttributes = model.additionalAttributes || {};
    const beneficiaries = model.stakeholders.BENEFICIARY || [];
    const isEmpowerAccount = IS_EMPOWER && model.isEmpower;
    const disableFields = Boolean(model.closedDate) || isEmpowerAccount;
    const disableRetirementDetails =
      Boolean(model.closedDate) ||
      isOnUs ||
      (isEmpowerAccount && model.empowerAttributes?.hasRecordKeptPlanMatch);
    const { isAdvisorApp } = window;

    const sentenceCaseClassName = "u-sentence-case u-display-block";

    return (
      <div>
        <div className="pc-layout">
          {!isOnUsBank && (
            <div className="pc-form-group--small">
              <div className="pc-layout__item pc-u-1/3">
                <label
                  htmlFor="firmName"
                  className={`pc-label--tiny ${sentenceCaseClassName}`}
                >
                  Firm Name
                </label>
              </div>
              <div className="pc-layout__item pc-u-2/3">
                <Input
                  id="firmName"
                  className="input--tiny qa-firm-name"
                  type="text"
                  name="firmName"
                  sizeVariation="full"
                  onChange={onInputChange}
                  value={model.firmName}
                  ref={this.props.storeInputRef}
                  validator={this.props.schema.properties.firmName}
                  disabled={isOnUs || isOnUsRetirement || disableFields}
                  maxLength={200}
                  ariaLabel="Firm name"
                />
              </div>
            </div>
          )}
          <div className="pc-form-group--small">
            <div className="pc-layout__item pc-u-1/3">
              <label
                htmlFor="accountName"
                className={`pc-label--tiny ${sentenceCaseClassName}`}
              >
                Account Name
              </label>
            </div>
            <div className="pc-layout__item pc-u-2/3">
              <Input
                id="accountName"
                className="input--tiny qa-account-name"
                type="text"
                name="name"
                sizeVariation="full"
                onChange={onInputChange}
                value={model.name}
                ref={this.props.storeInputRef}
                validator={this.props.schema.properties.name}
                disabled={isOnUs || isOnUsRetirement || disableFields}
                maxLength={200}
                ariaLabel="Account name"
              />
            </div>
          </div>
          <CSSTransition
            in={model.isManual}
            unmountOnExit={true}
            classNames="transition-form-group"
            className="js-edit-account-account-number pc-form-group--small"
            timeout={250}
          >
            <div>
              <div className="pc-layout__item pc-u-1/3">
                <label
                  htmlFor="accountNumber"
                  className={`pc-label--tiny ${sentenceCaseClassName}`}
                >
                  Account Number
                </label>
              </div>
              <div className="pc-layout__item pc-u-2/3">
                <Input
                  id="accountNumber"
                  className="input--tiny qa-account-number"
                  type="text"
                  name="accountNumber"
                  sizeVariation="full"
                  onChange={onInputChange}
                  value={model.accountNumber}
                  maxLength={MAX_ACCOUNT_NUMBER_LENGTH}
                  ref={this.props.storeInputRef}
                  validator={this.props.schema.properties.accountNumber}
                  disabled={disableFields}
                  ariaLabel=" Account number"
                />
              </div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={
              model.isManual &&
              (model.productType === "BANK" || model.treatedAsInvestment)
            }
            unmountOnExit={true}
            classNames="transition-form-group"
            className="js-edit-account-balance pc-form-group--small"
            timeout={250}
          >
            <div>
              <div className="pc-layout__item pc-u-1/3">
                <label
                  htmlFor="currentBalance"
                  className={`pc-label--tiny ${sentenceCaseClassName}`}
                >
                  Current Balance
                </label>
              </div>
              <div className="pc-layout__item pc-u-2/3">
                <Input
                  id="currentBalance"
                  className="input--tiny qa-current-balance"
                  type="text"
                  name="currentBalance"
                  maxLength={MAX_BALANCE_DIGITS}
                  sizeVariation="full"
                  prefix="$"
                  onChange={onInputChange}
                  value={model.currentBalance}
                  formattingOptions={CURRENCY_FORMAT}
                  ref={this.props.storeInputRef}
                  validator={this.props.schema.properties.currentBalance}
                  disabled={disableFields}
                  ariaLabel="Current balance"
                />
              </div>
            </div>
          </CSSTransition>
        </div>

        <div className="pc-layout">
          <div className="pc-layout__item pc-u-1/3">
            <label
              htmlFor="accountTypeInput"
              className={`pc-label--tiny ${sentenceCaseClassName}`}
            >
              Account Type
            </label>
          </div>
          <div className="pc-layout__item pc-u-2/3">
            <AccountTypeCombo
              id="accountType"
              name="accountTypeCombo"
              className="Select--tiny qa-account-type"
              ref={this.props.storeInputRef}
              onChange={this.props.onAccountTypeChange}
              value={{
                group: model.accountTypeGroup,
                type: model.accountTypeNew,
                subType: model.accountTypeSubtype,
              }}
              validator={this.props.schema.properties.accountTypeCombo}
              disabled={
                model.treatedAsInvestment ||
                isOnUs ||
                isOnUsBank ||
                isOnUsRetirement ||
                disableFields
              }
              optionsGroup={this.props.accountGroups}
              typesMap={this.props.accountTypesMap}
              subTypesMap={this.props.accountSubTypesMap}
              ariaLabels={{
                group: "Account Type",
                type: `Type of ${model.accountTypeGroup} account`,
                subType: `Type of ${model.accountTypeNew} account`,
              }}
              inputId={"accountTypeInput"}
            />
          </div>
        </div>
        <CSSTransition
          in={hasTrustType}
          unmountOnExit={true}
          className="js-edit-account-trust-section"
          classNames="transition-form-group"
          timeout={250}
        >
          {this.renderTrustSection({ model, disabled: disableFields })}
        </CSSTransition>
        <CSSTransition
          in={hasOwnershipType}
          unmountOnExit={true}
          classNames="transition-form-group"
          className="js-edit-account-ownership-type-section pc-form-group--small"
          timeout={250}
        >
          {this.renderOwnershipType({
            model,
            ownershipTypes,
            disabled: (isOnUs && !isAdvisorApp) || isOnUsBank || disableFields,
          })}
        </CSSTransition>
        <CSSTransition
          in={isJointAccount(model.ownershipType)}
          unmountOnExit={true}
          classNames="transition-form-group"
          timeout={250}
          className="js-joint-account-type-section"
        >
          {this.renderJointAccountType({
            additionalAttributes,
            disabled: (isOnUs && !isAdvisorApp) || isOnUsBank || disableFields,
          })}
        </CSSTransition>
        <CSSTransition
          in={
            Boolean(model.ownershipType) &&
            !isOwnerShareSupported(
              model.accountTypeGroup,
              additionalAttributes.jointAccountType
            )
          }
          unmountOnExit={true}
          className="js-edit-account-primary-owner-section pc-form-group--small"
          classNames="transition-form-group"
          timeout={250}
        >
          {this.renderOwnersSection({
            model,
            beneficiaries,
            disabled:
              ((isOnUs && !isAdvisorApp) || isOnUsBank || disableFields) &&
              !isOnUsRetirement,
          })}
        </CSSTransition>
        <CSSTransition
          in={
            isJointAccount(model.ownershipType) &&
            !isOwnerShareSupported(
              model.accountTypeGroup,
              additionalAttributes.jointAccountType
            )
          }
          unmountOnExit={true}
          classNames="transition-form-group"
          timeout={250}
        >
          {this.renderAdditionalOwner({
            model,
            beneficiaries,
            disabled: (isOnUs && !isAdvisorApp) || isOnUsBank || disableFields,
          })}
        </CSSTransition>
        <CSSTransition
          in={isEmployerSponsoredRetirementAccount(
            model.accountTypeNew,
            model.accountTypeSubtype
          )}
          unmountOnExit={true}
          classNames="transition-employer-sponsored-section"
          timeout={250}
        >
          {this.renderRetirementDetails({
            model,
            additionalAttributes,
            disabled: isEmulatorMode || disableRetirementDetails,
          })}
        </CSSTransition>
      </div>
    );
  }
}

FinancialAssetAccountDetails.propTypes = {
  model: PropTypes.object.isRequired,
  accountTypesMap: PropTypes.object.isRequired,
  optionsPeople: PropTypes.array,
  schema: PropTypes.object,
  onInputChange: PropTypes.func.isRequired,
  onAccountTypeChange: PropTypes.func.isRequired,
  onEmployerMatchLimitChange: PropTypes.func.isRequired,
  accountGroups: PropTypes.array.isRequired,
  accountSubTypesMap: PropTypes.object.isRequired,
  storeInputRef: PropTypes.func.isRequired,
  ownershipTypes: PropTypes.array,
  onMatchProfitChange: PropTypes.func.isRequired,
  currentMatchProfitValue: PropTypes.string,
  onToggleChange: PropTypes.func.isRequired,
};

FinancialAssetAccountDetails.defaultProps = {
  optionsPeople: [],
  ownershipTypes: [],
  currentMatchProfitValue: "",
  schema: {
    type: "object",
    properties: {
      firmName: {
        allowEmpty: false,
        required: true,
        type: "string",
        conform: hasPrintableCharacters,
        messages: {
          conform: "must not be empty",
        },
      },
      name: {
        allowEmpty: false,
        required: true,
        type: "string",
        conform: hasPrintableCharacters,
        messages: {
          conform: "must not be empty",
        },
      },
      accountNumber: {
        type: "string",
      },
      currentBalance: {
        type: "number",
        required: true,
        allowEmpty: false,
        maximum: 999999999.99,
        messages: {
          maximum: "Balance cannot be greater than $99,999,999.99",
        },
      },
      accountTypeCombo: {
        type: "object",
        properties: {
          group: {
            allowEmpty: false,
          },
          type: {
            allowEmpty: false,
          },
          subType: {
            allowEmpty: false,
          },
        },
      },
      matchPercentOfContribution: {
        type: "number",
        exclusiveMinimum: 0,
        maximum: 100,
        required: true,
        messages: {
          type: "numbers only",
          exclusiveMinimum: "positive numbers only",
          maximum: "100 maximum",
          required: "Value is required.",
        },
      },
      additionalAttributes: {
        type: "object",
        properties: {
          jointAccountType: {
            allowEmpty: false,
          },
        },
      },
      maxMatchDollarValue: {
        type: "number",
        exclusiveMinimum: 0,
        required: true,
        messages: {
          type: "numbers only",
          exclusiveMinimum: "positive numbers only",
          required: "Value is required.",
        },
      },
      maxMatchPercentOfSalary: {
        type: "number",
        exclusiveMinimum: 0,
        maximum: 100,
        required: true,
        messages: {
          type: "numbers only",
          exclusiveMinimum: "positive numbers only",
          maximum: "100 maximum",
          required: "Value is required.",
        },
      },
      profitSharingValueDollar: {
        type: "number",
        exclusiveMinimum: 0,
        required: true,
        messages: {
          type: "numbers only",
          exclusiveMinimum: "positive numbers only",
          required: "Value is required.",
        },
      },
      profitSharingValuePercent: {
        type: "number",
        exclusiveMinimum: 0,
        maximum: 100,
        required: true,
        messages: {
          type: "numbers only",
          exclusiveMinimum: "positive numbers only",
          maximum: "100 maximum",
          required: "Value is required.",
        },
      },
      currentMatchProfitValue: {
        type: "string",
      },
    },
  },
};

export default FinancialAssetAccountDetails;
