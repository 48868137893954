import React from "react";
import PropTypes from "prop-types";
import { noop } from "underscore";
import ComponentAnalytics from "components/common/ComponentAnalytics";

const ConfirmView = (props) => {
  const {
    trackViewTitle,
    componentName,
    className,
    onCancel,
    cancelLabel,
    onConfirm,
    confirmLabel,
    showConfirmationButton,
    confirmButtonClassName,
    buttonRef,
    disabled
  } = props;
  const content = props.children || props.content;

  React.useEffect(() => {
    if (componentName && trackViewTitle) {
      ComponentAnalytics.trackView(trackViewTitle, {
        component: componentName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      {content}
      <div className="pc-u-mt u-text-right">
        <button
          type="button"
          className="pc-btn qa-cancel-btn"
          onClick={onCancel}
        >
          {cancelLabel}
        </button>
        {showConfirmationButton && (
          <button
            aria-disabled={disabled}
            type="submit"
            className={`pc-btn ${confirmButtonClassName} qa-submit-btn ${disabled ? "is-disabled" : ""}`}
            onClick={disabled ? noop : onConfirm}
            ref={buttonRef}
          >
            {confirmLabel}
          </button>
        )}
      </div>
    </div>
  );
};

ConfirmView.propTypes = {
  trackViewTitle: PropTypes.string,
  componentName: PropTypes.string,
  className: PropTypes.string,
  confirmButtonClassName: PropTypes.string,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.node,
  buttonRef: PropTypes.func,
  showConfirmationButton: PropTypes.bool,
  disabled: PropTypes.bool
};

ConfirmView.defaultProps = {
  trackViewTitle: undefined,
  componentName: undefined,
  className: undefined,
  onCancel: noop,
  onConfirm: noop,
  cancelLabel: "Cancel",
  confirmButtonClassName: "pc-btn--primary",
  confirmLabel: "Confirm",
  children: undefined,
  content: undefined,
  buttonRef: undefined,
  showConfirmationButton: true,
};

export default ConfirmView;
