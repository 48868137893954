import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import SiteView2 from "views/modules/sidebar/partials/site2";
import AccountGroupTemplate from "templates/modules/sidebar/accountGroup2.html";
import AccountGroupHeaderTemplate from "templates/modules/sidebar/accountGroupHeader2.html";
import Account from "models/account";

const STILL_IMPORTING_GROUP_CONFIG = {
  label: "STILL IMPORTING",
  isLiability: false,
  isStillImporting: true,
  isNeedAttention: false,
  isInstitutionalPartner: false,
};

const STILL_IMPORTING_ACCOUNTS = ["ONUS_ACCOUNT_IN_REVIEW"];

let AccountGroupView = Backbone.View.extend({
  className: "sidebar-account__group",
  tagName: "li",
  events: {
    "click .sidebar-account__group-header": "isSidebarAccountExpanded",
  },
  initialize: function (options) {
    this.options = options;
    this.$el.html(
      AccountGroupTemplate({
        groupName: options.groupName,
        panelName: `${options.groupName}_GROUP_VIEW`,
        label: Account.PRODUCT_TYPES_LABELS[options.groupName],
        groupClassName: "sidebar-account__group-header--thin",
      })
    );
    this.$el.addClass(
      this.options.groupName +
        " qa-" +
        this.options.groupName.toLowerCase().replace("_", "-") +
        "-accounts"
    );
    $(options.elementContainer).append(this.$el);
    this.$list = this.$("ul");
    this.$(".js-sidebar-account__group-header").html(
      AccountGroupHeaderTemplate(STILL_IMPORTING_GROUP_CONFIG)
    );
    this.model.on("requestRenderAccount", this.render, this);
    this.collection.on("reset", this.render, this);
    this.collection.on("freshen", this.render, this);
    this.siteViews = {};
    this.accountViews = {};
    this.render();
  },
  render: function () {
    _.each(this.siteViews, (view) => {
      view.toBeRemoved = true;
    });

    _.each(this.accountViews, (view) => {
      view.toBeRemoved = true;
    });

    let accountsInProgress = this.collection.filter(
      (account) =>
        STILL_IMPORTING_ACCOUNTS.includes(account.get("nextAction")?.action) &&
        account.get("nextAction")?.iconType === "WARNING"
    );

    if (_.isEmpty(accountsInProgress)) {
      this.$el.hide();
    } else {
      let groupedAccounts = _.groupBy(accountsInProgress, (account) =>
        account.get("name")
      );
      _.each(groupedAccounts, this.renderAccount, this);
      this.$el.show();
    }

    _.each(this.siteViews, (view) => {
      if (view.toBeRemoved) {
        view.remove();
        delete this.siteViews[view.model.at(0).get("userSiteId")];
      }
    });

    _.each(this.accountViews, (view) => {
      if (view.toBeRemoved) {
        view.remove();
        delete this.accountViews[view.model.get("userAccountId")];
      }
    });
  },
  renderAccount: function (accounts, userSiteId) {
    var view = this.siteViews[userSiteId];
    if (typeof view === "object") {
      view.model.reset(accounts);
    } else {
      view = this.siteViews[userSiteId] = new SiteView2({
        userSiteId,
        accounts,
      });
      this.$list.append(view.render().el);
    }
    view.toBeRemoved = false;
  },
  isAnyExtraFormVisible: function () {
    return (
      _.some(this.siteViews, function (accountView) {
        return accountView.isExtraFormVisible;
      }) ||
      _.some(this.accountViews, function (accountView) {
        return accountView.isExtraFormVisible;
      })
    );
  },
  isSidebarAccountExpanded: function (e) {
    console.log(e);

    const buttonClassName =
      this.$el.context.children[0]?.childNodes[1]?.className;

    this.$el.context.children[0].childNodes[1].ariaExpanded =
      buttonClassName.includes("collapsed") ? "true" : "false";
  },
});
export default AccountGroupView;
