import moment from "moment";

const accountName = "Credit Card – Ending in 1111";
const transactionDate = "2024-01-29";
const gastropubDescription = "Gastropub";
const traderStoreDescription = "Trader store";
const localPizzaDescription = "Local Pizza Parlor";
const cableDescription = "Cable";
const atmDescription = "ATM withdrawal";
const mortgageDescription = "Mortgage payment";
const curbShopDescription = "Curb Shop";
const gasStationDescription = "Gas Station";
const hotelDescription = "Hotel";

const BudgetingMockData = {
  accounts: [],
  startDate: moment().startOf("month"),
  endDate: moment().endOf("day"),
  level: {
    type: "root",
  },
  transactions: [
    {
      isModifiedByRule: false,
      isInterest: false,
      accountName,
      description: gastropubDescription,
      categoryName: "Restaurants/Dining",
      isCredit: false,
      isEditable: true,
      isCashOut: true,
      merchantId: "wRxslWQ1ysoMahVlGC5US8Tk7GW3WmmiFDfA5PqYtyM",
      price: 0,
      userTransactionId: 1,
      currency: "USD",
      isDuplicate: false,
      merchantType: "OTHERS",
      originalDescription: "",
      isSpending: true,
      amount: 16.48,
      transactionTypeId: 175,
      isIncome: false,
      includeInCashManager: true,
      merchant: gastropubDescription,
      isNew: false,
      isCashIn: false,
      transactionDate,
      transactionType: "Purchase",
      categoryType: "EXPENSE",
      accountId: "2254935_3184544_2107292",
      originalAmount: 16.48,
      isCost: false,
      userAccountId: 2107292,
      simpleDescription: gastropubDescription,
      subType: "PURCHASE",
      hasViewed: false,
      categoryId: 35,
      status: "posted",
    },
    {
      isModifiedByRule: false,
      isInterest: false,
      accountName,
      description: traderStoreDescription,
      categoryName: "Groceries",
      isCredit: false,
      isEditable: true,
      isCashOut: true,
      merchantId: "wRxslWQ1ysoMahVlGC5US8Tk7GW3WmmiFDfA5PqYtyM",
      price: 0,
      userTransactionId: 2,
      currency: "USD",
      isDuplicate: false,
      merchantType: "OTHERS",
      originalDescription: "",
      isSpending: true,
      amount: 30.03,
      transactionTypeId: 175,
      isIncome: false,
      includeInCashManager: true,
      merchant: traderStoreDescription,
      isNew: false,
      isCashIn: false,
      transactionDate,
      transactionType: "Purchase",
      categoryType: "EXPENSE",
      accountId: "2254935_3184544_2107292",
      originalAmount: 30.03,
      isCost: false,
      userAccountId: 2107292,
      simpleDescription: traderStoreDescription,
      subType: "PURCHASE",
      hasViewed: false,
      categoryId: 17,
      status: "posted",
    },
    {
      isModifiedByRule: false,
      isInterest: false,
      accountName,
      description: localPizzaDescription,
      categoryName: "Restaurants/Dining",
      isCredit: false,
      isEditable: true,
      isCashOut: true,
      merchantId: "wRxslWQ1ysoMahVlGC5US8Tk7GW3WmmiFDfA5PqYtyM",
      price: 0,
      userTransactionId: 3,
      currency: "USD",
      isDuplicate: false,
      merchantType: "OTHERS",
      originalDescription: "",
      isSpending: true,
      amount: 20.22,
      transactionTypeId: 175,
      isIncome: false,
      includeInCashManager: true,
      merchant: localPizzaDescription,
      isNew: false,
      isCashIn: false,
      transactionDate,
      transactionType: "Purchase",
      categoryType: "EXPENSE",
      accountId: "2254935_3184544_2107292",
      originalAmount: 20.22,
      isCost: false,
      userAccountId: 2107292,
      simpleDescription: localPizzaDescription,
      subType: "PURCHASE",
      hasViewed: false,
      categoryId: 35,
      status: "posted",
    },
    {
      isModifiedByRule: false,
      isInterest: false,
      accountName,
      description: cableDescription,
      categoryName: "Online Services",
      isCredit: false,
      isEditable: true,
      isCashOut: true,
      merchantId: "wRxslWQ1ysoMahVlGC5US8Tk7GW3WmmiFDfA5PqYtyM",
      price: 0,
      userTransactionId: 4,
      currency: "USD",
      isDuplicate: false,
      merchantType: "OTHERS",
      originalDescription: "",
      isSpending: true,
      amount: 59.99,
      transactionTypeId: 175,
      isIncome: false,
      includeInCashManager: true,
      merchant: cableDescription,
      isNew: false,
      isCashIn: false,
      transactionDate: "2024-01-28",
      transactionType: "Purchase",
      categoryType: "EXPENSE",
      accountId: "2254935_3184544_2107292",
      originalAmount: 59.99,
      isCost: false,
      userAccountId: 2107292,
      simpleDescription: cableDescription,
      subType: "PURCHASE",
      hasViewed: false,
      categoryId: 27,
      status: "posted",
    },
    {
      isModifiedByRule: false,
      isInterest: false,
      accountName,
      description: atmDescription,
      categoryName: "ATM/Cash",
      isCredit: false,
      isEditable: true,
      isCashOut: true,
      merchantId: "wRxslWQ1ysoMahVlGC5US8Tk7GW3WmmiFDfA5PqYtyM",
      price: 0,
      userTransactionId: 5,
      currency: "USD",
      isDuplicate: false,
      merchantType: "OTHERS",
      originalDescription: "",
      isSpending: true,
      amount: 200.0,
      transactionTypeId: 175,
      isIncome: false,
      includeInCashManager: true,
      merchant: atmDescription,
      isNew: false,
      isCashIn: false,
      transactionDate: "2024-01-28",
      transactionType: "Purchase",
      categoryType: "EXPENSE",
      accountId: "2254935_3184544_2107292",
      originalAmount: 200.0,
      isCost: false,
      userAccountId: 2107292,
      simpleDescription: atmDescription,
      subType: "PURCHASE",
      hasViewed: false,
      categoryId: 1,
      status: "posted",
    },
    {
      isModifiedByRule: false,
      isInterest: false,
      accountName,
      description: mortgageDescription,
      categoryName: "Mortgages",
      isCredit: false,
      isEditable: true,
      isCashOut: true,
      merchantId: "wRxslWQ1ysoMahVlGC5US8Tk7GW3WmmiFDfA5PqYtyM",
      price: 0,
      userTransactionId: 6,
      currency: "USD",
      isDuplicate: false,
      merchantType: "OTHERS",
      originalDescription: "",
      isSpending: true,
      amount: 840.93,
      transactionTypeId: 175,
      isIncome: false,
      includeInCashManager: true,
      merchant: mortgageDescription,
      isNew: false,
      isCashIn: false,
      transactionDate: "2024-01-25",
      transactionType: "Purchase",
      categoryType: "EXPENSE",
      accountId: "2254935_3184544_2107292",
      originalAmount: 840.93,
      isCost: false,
      userAccountId: 2107292,
      simpleDescription: mortgageDescription,
      subType: "PURCHASE",
      hasViewed: false,
      categoryId: 24,
      status: "posted",
    },
    {
      isModifiedByRule: false,
      isInterest: false,
      accountName,
      description: curbShopDescription,
      categoryName: "Clothing/Shoes",
      isCredit: false,
      isEditable: true,
      isCashOut: true,
      merchantId: "wRxslWQ1ysoMahVlGC5US8Tk7GW3WmmiFDfA5PqYtyM",
      price: 0,
      userTransactionId: 7,
      currency: "USD",
      isDuplicate: false,
      merchantType: "OTHERS",
      originalDescription: "",
      isSpending: true,
      amount: 19.99,
      transactionTypeId: 175,
      isIncome: false,
      includeInCashManager: true,
      merchant: curbShopDescription,
      isNew: false,
      isCashIn: false,
      transactionDate: "2024-01-25",
      transactionType: "Purchase",
      categoryType: "EXPENSE",
      accountId: "2254935_3184544_2107292",
      originalAmount: 19.99,
      isCost: false,
      userAccountId: 2107292,
      simpleDescription: curbShopDescription,
      subType: "PURCHASE",
      hasViewed: false,
      categoryId: 9,
      status: "posted",
    },
    {
      isModifiedByRule: false,
      isInterest: false,
      accountName,
      description: gasStationDescription,
      categoryName: "Gasoline/Fuel",
      isCredit: false,
      isEditable: true,
      isCashOut: true,
      merchantId: "wRxslWQ1ysoMahVlGC5US8Tk7GW3WmmiFDfA5PqYtyM",
      price: 0,
      userTransactionId: 8,
      currency: "USD",
      isDuplicate: false,
      merchantType: "OTHERS",
      originalDescription: "",
      isSpending: true,
      amount: 40.0,
      transactionTypeId: 175,
      isIncome: false,
      includeInCashManager: true,
      merchant: gasStationDescription,
      isNew: false,
      isCashIn: false,
      transactionDate: "2024-01-20",
      transactionType: "Purchase",
      categoryType: "EXPENSE",
      accountId: "2254935_3184544_2107292",
      originalAmount: 40.0,
      isCost: false,
      userAccountId: 2107292,
      simpleDescription: gasStationDescription,
      subType: "PURCHASE",
      hasViewed: false,
      categoryId: 14,
      status: "posted",
    },
    {
      isModifiedByRule: false,
      isInterest: false,
      accountName,
      description: hotelDescription,
      categoryName: "Travel",
      isCredit: false,
      isEditable: true,
      isCashOut: true,
      merchantId: "wRxslWQ1ysoMahVlGC5US8Tk7GW3WmmiFDfA5PqYtyM",
      price: 0,
      userTransactionId: 9,
      currency: "USD",
      isDuplicate: false,
      merchantType: "OTHERS",
      originalDescription: "",
      isSpending: true,
      amount: 270.88,
      transactionTypeId: 175,
      isIncome: false,
      includeInCashManager: true,
      merchant: hotelDescription,
      isNew: false,
      isCashIn: false,
      transactionDate: "2024-01-18",
      transactionType: "Purchase",
      categoryType: "EXPENSE",
      accountId: "2254935_3184544_2107292",
      originalAmount: 270.88,
      isCost: false,
      userAccountId: 2107292,
      simpleDescription: hotelDescription,
      subType: "PURCHASE",
      hasViewed: false,
      categoryId: 39,
      status: "posted",
    },
    {
      isModifiedByRule: false,
      isInterest: false,
      accountName,
      description: gasStationDescription,
      categoryName: "Gasoline/Fuel",
      isCredit: false,
      isEditable: true,
      isCashOut: true,
      merchantId: "wRxslWQ1ysoMahVlGC5US8Tk7GW3WmmiFDfA5PqYtyM",
      price: 0,
      userTransactionId: 10,
      currency: "USD",
      isDuplicate: false,
      merchantType: "OTHERS",
      originalDescription: "",
      isSpending: true,
      amount: 60.0,
      transactionTypeId: 175,
      isIncome: false,
      includeInCashManager: true,
      merchant: gasStationDescription,
      isNew: false,
      isCashIn: false,
      transactionDate: "2024-01-07",
      transactionType: "Purchase",
      categoryType: "EXPENSE",
      accountId: "2254935_3184544_2107292",
      originalAmount: 60.0,
      isCost: false,
      userAccountId: 2107292,
      simpleDescription: gasStationDescription,
      subType: "PURCHASE",
      hasViewed: false,
      categoryId: 14,
      status: "posted",
    },
  ],
  transactionsGroups: [],
  transactionsHistory: [],
  current: {
    totalValue: 4000,
    strongDialValue: 2812,
    lightDialValue: 80,
  },
  past: {
    totalValue: 4000,
    strongDialValue: 3079,
    lightDialValue: 80,
  },
  targetBudget: 4000,
  onDateRangeChange: () => undefined,
  onAccountsChange: () => undefined,
  onBudgetUpdate: () => undefined,
  errors: null,
  isAccountDropdownSelectorFocus: false,
  transactionCategoryRulesFlag: true,
  isZeroState: true,
};

export default BudgetingMockData;
