import React from "react";
import PropTypes from "prop-types";
import { noop } from "underscore";

const ClosingMessageView = ({
  componentName,
  getButtons,
  imageSource,
  model,
  summary,
  title,
}) => {
  return (
    <>
      <div className="pc-advice-notification__title-container">
        <h1
          className="pc-advice-notification__title qa-pla-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      {(Boolean(summary) || Boolean(imageSource)) && (
        <section className={`pc-layout ${summary ? "" : "u-text-center"}`}>
          {summary && (
            <summary
              className={`pc-layout__item  user-response-survey-closing-view__summary ${
                imageSource ? "pc-u-4/7" : ""
              }`}
              dangerouslySetInnerHTML={{ __html: summary }}
            ></summary>
          )}
          {Boolean(imageSource) && (
            <img
              src={imageSource}
              alt=""
              className={`pc-layout__item ${
                summary ? "pc-u-3/7" : ""
              } user-response-survey__body-image`}
            />
          )}
        </section>
      )}
      <footer className="u-text-center pc-u-mb-- pc-u-mt-">
        {getButtons({
          getButtonClassName: () => "pc-btn pc-btn--primary",
          customizedComponentName: `${componentName}-${
            Object.values(model)[0]
          }`,
        })}
      </footer>
    </>
  );
};

ClosingMessageView.propTypes = {
  componentName: PropTypes.string,
  getButtons: PropTypes.func,
  imageSource: PropTypes.string,
  model: PropTypes.object,
  summary: PropTypes.string,
  title: PropTypes.string,
};

ClosingMessageView.defaultProps = {
  componentName: "",
  getButtons: noop,
  imageSource: "",
  model: {},
  summary: "",
  title: "",
};

export default ClosingMessageView;
