import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Services from "services";
import AccountsCollection from "collections/accounts";
import BillsTemplate2 from "templates/bills2.html";
import moment from "moment";
import DateUtil from "libs/pcap/utils/date";
import IframeClient from "partner/iframeClient";
import mixpanel from "../../libs/pcap/utils/mixpanel";

const DATA_GRID_ROW_ACTIVE = "pc-datagrid__row--active";

var BillsView = Backbone.View.extend({
  el: "#bills",
  events: {
    "click button.save": "onSaveClick",
    "click .js-old-datarow": "onRowClick",
    "click .pc-datagrid__row": "pcRowClick",
    "click .js-save-btn": "onSaveClick",
  },
  initialize: function (options) {
    this.options = options;
    this.render();
    this.accounts = new AccountsCollection();
    this.fetchAccounts();
    mixpanel.trackEvent("View Bills", {
      component: "billsView",
    });
  },
  fetchAccounts: function () {
    this.accountsWatchId = Services.Accounts.get.watch(
      this.onAccountsFetched,
      this
    );
  },
  onAccountsFetched: function (err, response) {
    if (err === null) {
      if (this.accounts.length < 1) {
        this.accounts.reset(response.spData.accounts);
      } else {
        this.accounts.freshen(response.spData.accounts);
      }

      var qualifiedAccount = this.accounts.find(function (account) {
        var productType = account.get("productType");
        return (
          productType === "CREDIT_CARD" ||
          productType === "LOAN" ||
          productType === "MORTGAGE"
        );
      });

      if (qualifiedAccount) {
        this.getBillReminders();
        this.setZeroState("hide");
      } else {
        this.setZeroState("show");
        AppRouter.removePreloader();

        if (IS_IFRAMED) {
          IframeClient.getInstance().triggerResize();
        }
      }
    }
  },
  setZeroState: function (showOrHide) {
    if (showOrHide === "show") {
      this.$el.find(".js-non-zeroState").hide();
      this.$el.find(".featureControls > p").hide();
      this.$el.find(".zeroState").show();
    } else {
      this.$el.find(".js-non-zeroState").show();
      this.$el.find(".featureControls > p").show();
      this.$el.find(".zeroState").hide();
    }
  },
  render: function () {
    this.$el.html(BillsTemplate2());
    const dataGridCellTopClass = "pc-datagrid__cell pc-datagrid__cell--top";
    var dataColumns = [
      {
        title: '<span class="pc-datagrid__sort-action">Due</span>',
        className: dataGridCellTopClass,
        width: "9%",
      },
      {
        title: '<span class="pc-datagrid__sort-action">Account</span>',
        className: dataGridCellTopClass,
        width: "25%",
      },
      {
        title: '<span class="pc-datagrid__sort-action">Last Payment</span>',
        className: dataGridCellTopClass,
        width: "14%",
      },
      {
        title:
          '<span class="pc-datagrid__sort-action">Statement Balance</span>',
        className: dataGridCellTopClass + " tabular-numbers",
        type: "formattedWrappedDollar",
        width: "16%",
      },
      {
        title: '<span class="pc-datagrid__sort-action">Min. Due</span>',
        className: dataGridCellTopClass + " tabular-numbers",
        type: "formattedWrappedDollar",
        width: "11%",
      },
      {
        title:
          '<span class="pc-datagrid__sort-action pc-datatable__right-header">Current Balance</span>',
        className:
          dataGridCellTopClass +
          " pc-datagrid__cell--align-right tabular-numbers",
        type: "formattedWrappedDollar",
        width: "15%",
      },
    ];

    this.table = $("#billsTable").DataTable({
      oClasses: {
        sSortAsc: "datatable-sort--asc",
        sSortDesc: "datatable-sort--desc",
      },
      searching: false,
      retrieve: true,
      paging: false,
      info: false,
      destroy: true,
      order: [[0, "asc"]],
      columns: dataColumns,
      language: { zeroRecords: "No bills due at this time" },
      fnRowCallback: function (nRow) {
        nRow.className = "pc-datagrid__row";
        if (this.previousActiveRow && nRow === this.previousActiveRow.get(0)) {
          nRow.classList.add(DATA_GRID_ROW_ACTIVE);
        }
        return nRow;
      }.bind(this),
    });
    $(this.table.tables().header()).addClass("pc-datagrid__row--header");
    return this;
  },
  onClose: function () {
    if (this.getBillRemindersWatchId) {
      Services.BillReminders.get.unwatch(this.getBillRemindersWatchId);
    }

    if (this.accountsWatchId) {
      Services.Accounts.get.unwatch(this.accountsWatchId);
    }
  },
  getBillReminders: function () {
    if (this.getBillRemindersWatchId) {
      Services.BillReminders.get.unwatch(this.getBillRemindersWatchId);
    }

    this.getBillRemindersWatchId = Services.BillReminders.get.watch(
      this.onGetBillReminders,
      this
    );
  },
  onGetBillReminders: function (err, response) {
    var data = [],
      foundAccount;

    if (!_.isUndefined(response.spData) && response.spData.length > 0) {
      // store reference to raw bills data so that it can be used to calculate totals
      this.billsData = response.spData;
      _.each(
        response.spData,
        function (bill) {
          foundAccount = this.accounts.find(function (account) {
            return account.get("userAccountId") === bill.userAccountId;
          }, this);

          var lastPaymentDate =
            bill.lastPaymentDate === 0
              ? "-"
              : moment(bill.lastPaymentDate).format(DateUtil.DISPLAY_FORMAT);
          var htmlBtnMarkAsPaid =
            '<div class="js-datagrid__hidden-info is-hidden pc-datagrid__hidden-info pc-datagrid__hidden-info--no-padding"><button class="pc-btn pc-btn--primary pc-btn--small js-save-btn" data-billpaymentid="' +
            bill.billPaymentId +
            '">Mark As Paid</button></div>';
          var htmlBtnPaid =
            '<div class="js-datagrid__hidden-info is-hidden pc-datagrid__hidden-info">Marked as Paid</div>';

          data.push([
            moment(bill.dueDate).format(DateUtil.DISPLAY_FORMAT),
            '<div class="u-clip-text" title="' +
              bill.accountName +
              '" data-hj-masked>' +
              bill.accountName +
              '</div><div class="js-datagrid__hidden-info is-hidden pc-datagrid__hidden-info"><a class="pc-datagrid__cell-link loginUrl" href="' +
              foundAccount.get("loginUrl") +
              '" target="_blank">Go to website</a></div>',
            "<div>" +
              lastPaymentDate +
              '</div><div class="js-datagrid__hidden-info is-hidden pc-datagrid__hidden-info" title="' +
              $.pcap.formatDollarsAndCents(
                bill.lastPaymentAmount,
                false,
                true
              ) +
              '">' +
              $.pcap.formatDollarsAndCents(
                bill.lastPaymentAmount,
                false,
                true
              ) +
              "</div>",
            '<div title="' +
              $.pcap.formatDollarsAndCents(bill.statementBalance, false, true) +
              '">' +
              $.pcap.formatDollarsAndCents(bill.statementBalance, false, true) +
              "</div>",
            '<div title="' +
              $.pcap.formatDollarsAndCents(bill.minAmountDue, false, true) +
              '">' +
              $.pcap.formatDollarsAndCents(bill.minAmountDue, false, true) +
              "</div>",
            '<div title="' +
              $.pcap.formatDollarsAndCents(bill.amountDue, false, true) +
              '">' +
              $.pcap.formatDollarsAndCents(bill.amountDue, false, true) +
              "</div>" +
              (bill.status === "PAID" ? htmlBtnPaid : htmlBtnMarkAsPaid),
          ]);
        }.bind(this)
      );
    }

    this.table.clear();
    this.table.rows.add(data).draw();

    AppRouter.removePreloader();

    if (IS_IFRAMED) {
      IframeClient.getInstance().triggerResize();
    }

    if (data.length > 0) {
      this.table.row(0).nodes().toJQuery().trigger("click");
    }
  },
  onRowClick: function (event) {
    var row = $(event.currentTarget);

    if (row && !row.hasClass("active")) {
      row.addClass("active");
      row.find(".hidden").show();

      if (this.previousActiveRow) {
        this.previousActiveRow.removeClass("active");
        this.previousActiveRow.find(".hidden").hide();
      }

      this.previousActiveRow = row;
    }
  },
  pcRowClick: function (event) {
    var row = $(event.currentTarget);

    if (row && !row.hasClass(DATA_GRID_ROW_ACTIVE)) {
      row.addClass(DATA_GRID_ROW_ACTIVE);
      row.find(".js-datagrid__hidden-info").toggleClass("is-hidden");

      if (this.previousActiveRow) {
        this.previousActiveRow.removeClass(DATA_GRID_ROW_ACTIVE);
        this.previousActiveRow
          .find(".js-datagrid__hidden-info")
          .toggleClass("is-hidden");
      }

      this.previousActiveRow = row;
    }
  },
  onSaveClick: function (event) {
    this.paidRowPos = this.table
      .row($(event.currentTarget).closest("tr"))
      .index();
    this.billPaymentId = Number(
      event.currentTarget.getAttribute("data-billpaymentid")
    );
    Services.BillReminders.update(
      {
        billPaymentId: this.billPaymentId,
        status: "PAID",
      },
      this.onBillPaid,
      this
    );
  },
  onBillPaid: function () {
    var self = this;
    this.table.row(this.paidRowPos).remove().draw();
    this.previousActiveRow = undefined;
    this.billsData = _.reject(this.billsData, function (bill) {
      return bill.billPaymentId === self.billPaymentId;
    });

    var nodes = this.table.rows().nodes();
    if (nodes.length > 0) {
      this.table.row(0).nodes().toJQuery().trigger("click");
    }

    if (IS_IFRAMED) {
      IframeClient.getInstance().triggerResize();
    }
  },
  calculateTotals: function (billsData) {
    if (!billsData || billsData.length === 0) {
      return null;
    }

    var totalBalance = 0,
      totalMinDue = 0,
      totalAmount = 0;

    _.each(billsData, function (bill) {
      if (
        _.isNumber(bill.statementBalance) &&
        !_.isNaN(bill.statementBalance)
      ) {
        totalBalance += bill.statementBalance;
      }

      if (_.isNumber(bill.minAmountDue) && !_.isNaN(bill.minAmountDue)) {
        totalMinDue += bill.minAmountDue;
      }

      if (_.isNumber(bill.amountDue) && !_.isNaN(bill.amountDue)) {
        totalAmount += bill.amountDue;
      }
    });

    return {
      totalBalance: $.pcap.formatDollarsAndCents(totalBalance, false, true),
      totalMinDue: $.pcap.formatDollarsAndCents(totalMinDue, false, true),
      totalAmount: $.pcap.formatDollarsAndCents(totalAmount, false, true),
    };
  },
});

export default BillsView;
