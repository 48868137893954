import PropTypes from "prop-types";
import React, { Component } from "react";
import Table from "libs/pcap/table/Table";
import Pagination from "libs/pcap/table/pagination/Pagination";
import memoizeOne from "memoize-one";
import { isEmpty, uniq, flatten, noop, clone } from "underscore";

/**
 * Different columns will need their data to be accessed in different ways,
 * in order to be the correct data/format for building the filter map.
 *
 * @param {object} column which contains the available accessors.
 *
 * @return {function} the appropriate accessor.
 */
function getFilterLabelAccessor(column) {
  const { filterAccessor, accessor, formatter } = column;

  if (filterAccessor) {
    return filterAccessor;
  } else if (accessor && formatter) {
    return (dataRow) => formatter(accessor(dataRow));
  }

  return accessor;
}

/**
 * Convert labels into the specific object format that `TableColumnFilter`
 * will expect of its `options`.
 *
 * @param {array} labels the array to transform.
 *
 * @return {array} the new, transformed array.
 */
function buildFilterOptions(labels) {
  const options = [];

  let numericalValue = 1;

  for (const label of labels) {
    if (label) {
      options.push({
        label,
        value: label,
        numericalValue: numericalValue++,
      });
    }
  }

  return options;
}

/**
 * Attempt to get a comparator from the column which can be used to sort the filter options.
 * If the column has a `filterOptionsComparator`, it will override the `comparator`.
 * If neither exist, it will return `null` (and ultimately use a default sort on the options).
 *
 * @param {object} column which contains the comparator(s).
 *
 * @returns {?function} comparator to sort the column's data by, if one can be found.
 */
export function getComparatorForFilterOptions(column) {
  // Override of normal comparator for column which has more complicated sorting rules.
  // 'Tags' in transactions grid is the main example at present time.
  if (column.filterOptionsComparator) {
    return column.filterOptionsComparator;
  }
  if (column.comparator) {
    return column.comparator;
  }

  // This would mainly be caused by cases when a column is filterable but not sortable.
  // eslint-disable-next-line no-console
  console.error(
    "All filterable columns must define `comparator` or `filterOptionsComparator`" +
      " override, so filter options can be sorted in the dropdown.",
    column
  );

  return null;
}

/**
 * For table `columns` which are filterable, look at their `data` to generate
 * a unique list of `filterOptions` which will be used to populate the filter
 * dropdown.
 *
 * @param {array} data for which to generate the filter options.
 * @param {array} columns to which to attach the filter options.
 * @param {array} activeFilters array of active filters by column.
 * @param {boolean} disableTransactionsFilter array of active filters by column.
 *
 * @returns {array} cloned columns with `filterOptions` added.
 */
export function getColumnsWithActiveFiltersAndOptions(
  data,
  columns,
  activeFilters = [],
  disableTransactionsFilter = false
) {
  return columns.map((originalColumn) => {
    const column = clone(originalColumn);

    if (column.isFilterable) {
      column.disableTransactionsFilter = disableTransactionsFilter;
      const columnLabels = data.map(getFilterLabelAccessor(column));
      // Columns with multiple labels (such as tags) need flattening.
      const uniqueLabels = Array.isArray(columnLabels[0])
        ? uniq(flatten(columnLabels))
        : uniq(columnLabels);

      const columnComparator = getComparatorForFilterOptions(column);

      // Columns with no `columnComparator` will console error and then fail to sort.
      if (columnComparator) {
        // Already comparing strings directly, so accessor should just return the data without accessing.
        uniqueLabels.sort(columnComparator((d) => d, "asc"));
      }

      const filterOptions = buildFilterOptions(uniqueLabels);

      if (filterOptions) {
        column.filterOptions = filterOptions;
      }

      let filterIndex = activeFilters.findIndex(
        (filter) => filter.header === column.header
      );
      if (filterIndex > -1) {
        column.activeFilter = activeFilters[filterIndex].value.filter(
          (filter) => uniqueLabels.includes(filter)
        );
      }
    }

    return column;
  });
}

/**
 * Sets the sorting attributes to the column in the columns array passed by param that
 * match the column in the second argument (comparing headers)
 *
 * @param {array} columns an array containing all the columns
 * @param {array} sortByColumn column the column that we should set the attributes for in the first argument
 * @param {string} sortOrder a string containing the sort order for the column
 *
 * @returns {array} columns with the sorting properties set based on the second param
 */
export function setSortingStateToColumn(columns, sortByColumn, sortOrder) {
  return columns.map((originalColumn) => {
    if (originalColumn.header === sortByColumn.header) {
      originalColumn.sortOrder = sortOrder;
    } else {
      delete originalColumn.sortOrder;
    }
    return originalColumn;
  });
}

/**
 * Sort a `data` set by a `column`, if `column` has a `comparator`.
 *
 * @param {array} data to sort.
 * @param {object} column to sort by.
 * @param {string} sortOrder 'asc' or 'desc'.
 *
 * @returns {array} the sorted data
 */
export function getSortedData(data, column, sortOrder) {
  let dataToSort = data.slice();

  if (!column.comparator) {
    // eslint-disable-next-line no-console
    console.error(
      "Warning: Column object must define `comparator` function for the sort to work.",
      arguments
    );
    return dataToSort;
  }

  return dataToSort.sort(column.comparator(column.accessor, sortOrder));
}

/**
 * Find out whether the data corresponding to some `filteredColumns` matches
 * at least one of the `activeFilter` labels on EVERY one of the columns.
 *
 * @param {object} dataRow which contains data to access and compare.
 * @param {array} filteredColumns with an `activeFilter` array to compare against,
 * and `accessor`s to grab and format the data into a comparable string.
 *
 * @returns {boolean} Does the row pass every group of filters?
 */
export function doesDataRowPassFilterForEveryColumn(dataRow, filteredColumns) {
  return filteredColumns.every((column) => {
    // If data only had to match a single column's filter, `getFilteredData`
    // wouldn't properly return an intersection of the multiple filters.
    const { activeFilter } = column;
    const dataLabel = getFilterLabelAccessor(column)(dataRow);

    // Only need to pass a SINGLE filter on each column.
    return activeFilter.some((filterLabel) => {
      if (Array.isArray(dataLabel)) {
        return dataLabel.includes(filterLabel);
      }

      return dataLabel === filterLabel;
    });
  });
}

/**
 * Get a subset of data which matches a set of (potentially) multiple
 * simultaneous `activeFilter`s on the associated `columns`.
 *
 * @param {array} data of rows to filter.
 * @param {array} columns containing the `activeFilter` labels.
 *
 * @returns {array} of data which passes the filters in the columns.
 */
export function getFilteredData(data, columns) {
  const activelyFilteredColumns = columns.filter((column) => {
    return column.activeFilter && !isEmpty(column.activeFilter);
  });

  return data.filter((dataRow) => {
    return doesDataRowPassFilterForEveryColumn(
      dataRow,
      activelyFilteredColumns
    );
  });
}

/**
 * Slice out a single page of `data`, starting form `pageStart` and
 * including a `stepSize` amount of entries.
 *
 * @param {array} data to paginate.
 * @param {number} pageStart where in the array to begin the slice.
 * @param {object} stepSize number of items to include per page.
 *
 * @returns {array} one page's worth of data.
 */
export function getPaginatedData(data, pageStart, stepSize) {
  return data.slice(pageStart, pageStart + stepSize);
}

// Memoize these functions locally so they can be separately memoized at the parent level.
const memoizedGetColumnsWithActiveFiltersAndOptions = memoizeOne(
  getColumnsWithActiveFiltersAndOptions
);
const memoizedGetPaginatedData = memoizeOne(getPaginatedData);
const memoizedGetFilteredData = memoizeOne(getFilteredData);
const memoizedGetSortedData = memoizeOne(getSortedData);
const memoizedSetSortingStateToColumn = memoizeOne(setSortingStateToColumn);

/**
 * This is a wrapper component for `Table` which provides sorting capabilities. It intercepts `onRequestSort`
 * callback sort data in memory. Uses `Pagination` to paginate if `shouldPaginate` prop is true
 *
 *
 * ## Props
 * Look at `Table` component's documentation for the complete list of supported properties. Additional properties are:
 * - `paginator` {Object | Boolean} - Object with data describing how to paginate table. Defaults to object with below values.
 *                                  - `stepSize`  {Number}     - Size of each page if table is paginated. Default 100
 *                                  - `start`     {Number}     - Where pagination should start. Default 0.
 *                                  - `className` {String}     - Classes to apply to paginator.

 *
 * ## Comparator
 * In addition to the standard column definition, `comparator` function must be provided for the sortable columns.
 *
 * The function receives:
 * 1. `accessor` function for the column
 * 2. Sort order `asc` or `desc`
 *
 * The function returns `compareFunction` which is used for actual comparison in `Array.prototype.sort(compareFunction)`.
 *
 *
 * There is generally no reason for implementing a custom comparator. There are standard comparators available in
 * `scripts/libs/pcap/table/data/compare.js`. They should cover most possible cases:
 *  - `compareAlpha`
 *  - `compareNum`
 *
 * Example:
    ```
    {
      header: 'First Name',
      accessor: d => d.firstName,
      isSortable: true,
      sortOrder: 'asc',
      comparator: compareAlpha
    }
    ```
 *
 * @class TableClient
 * @extends {Component}
 */
class TableClient extends Component {
  constructor(props) {
    super(props);

    const { data, columns: columnsProp, paginator, isFilteringEnabled } = props;
    const columns = isFilteringEnabled
      ? memoizedGetColumnsWithActiveFiltersAndOptions(data, columnsProp, [])
      : columnsProp;
    const sortedColumn = columns.find(
      (col) => typeof col.sortOrder === "string"
    );

    if (!sortedColumn) {
      // eslint-disable-next-line no-console
      console.error(
        "Warning: Using a sortable table, but none of the columns define the default `sortOrder`. Consider setting `sortOrder` on one of the columns."
      );
    }

    const pageStartProp = paginator && paginator.start ? paginator.start : 0;

    this.state = {
      columns,
      sortedColumn,
      sortOrder: sortedColumn.sortOrder,
      prevPageStartProp: pageStartProp,
      pageStart: pageStartProp,
      activeFilters: [],
      paginatorView: "VIEW_LESS",
    };

    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleColumnFilterChange = this.handleColumnFilterChange.bind(this);
    this.handleToggleOnViewPagination =
      this.handleToggleOnViewPagination.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};
    const {
      data: nextData,
      columns: nextColumns,
      isFilteringEnabled,
    } = nextProps;
    const {
      prevPageStartProp: prevPageStart,
      activeFilters,
      sortedColumn,
      sortOrder,
    } = prevState;
    const nextPageStart =
      nextProps.paginator && nextProps.paginator.start
        ? nextProps.paginator.start
        : 0;

    newState.columns = isFilteringEnabled
      ? memoizedGetColumnsWithActiveFiltersAndOptions(
          nextData,
          nextColumns,
          activeFilters
        )
      : nextColumns;

    // if the new columns contains the column we were sorting for, set the sort in the column array
    if (
      newState.columns.some((column) => column.header === sortedColumn.header)
    ) {
      // Set sorting state to the new columns
      newState.columns = memoizedSetSortingStateToColumn(
        newState.columns,
        sortedColumn,
        sortOrder
      );
    } else {
      // If the column that we were sorting for is not longer present, reset the sorting to whichever column prop has the sortOrder attribute
      newState.sortedColumn = newState.columns.find(
        (col) => typeof col.sortOrder === "string"
      );
      newState.sortOrder =
        (newState.sortedColumn && newState.sortedColumn.sortOrder) || undefined;
    }

    if (nextPageStart !== prevPageStart) {
      newState.pageStart = nextPageStart;
      newState.prevPageStartProp = nextPageStart;
    }

    return isEmpty(newState) ? null : newState;
  }

  handleColumnFilterChange(columnHeader, value) {
    let activeFilters = clone(this.state.activeFilters);
    let filterIndex = this.state.activeFilters.findIndex(
      (filter) => filter.header === columnHeader
    );

    if (filterIndex > -1) {
      activeFilters[filterIndex].value = value;
    } else {
      activeFilters.push({ header: columnHeader, value });
    }
    this.setState({ activeFilters });
  }

  handleRequestSort(sortedColumn, sortOrder) {
    const { onRequestSort } = this.props;
    this.setState({
      sortedColumn,
      sortOrder,
      pageStart: 0,
    });
    onRequestSort(sortedColumn, sortOrder);
  }

  handlePaginationChange(start) {
    const { onRequestPaginate } = this.props;
    this.setState({
      pageStart: start,
    });
    onRequestPaginate(start);
  }

  handleToggleOnViewPagination() {
    const { paginatorView } = this.state;
    const { onRequestToggleViewPagination } = this.props;
    const newPaginatorView =
      paginatorView === "VIEW_LESS" ? "VIEW_ALL" : "VIEW_LESS";
    this.setState({
      paginatorView: newPaginatorView,
    });
    onRequestToggleViewPagination(newPaginatorView);
  }

  render() {
    const { data, paginator, className, tableClassName } = this.props;
    const { columns, sortedColumn, sortOrder, pageStart, paginatorView } =
      this.state;
    const sortedData = memoizedGetSortedData(data, sortedColumn, sortOrder);
    const filteredData = memoizedGetFilteredData(sortedData, columns);
    const paginatedData =
      paginator &&
      paginator.stepSize &&
      paginatorView === "VIEW_LESS" &&
      memoizedGetPaginatedData(filteredData, pageStart, paginator.stepSize);
    const showPaginator = paginator && filteredData.length > paginator.stepSize;
    const showPaginatorAtBottom =
      showPaginator && paginator && paginator.showAtBottom;
    const paginatorElement = showPaginator ? (
      <div
        className={`pc-pagination__container ${
          paginator.containerClassName || ""
        }`}
      >
        {paginator.showViewAll && (
          <button
            className={`pc-btn pc-btn--link pc-u-mr- ${paginator.viewAllClassName}`}
            onClick={this.handleToggleOnViewPagination}
          >
            {paginatorView === "VIEW_LESS" ? "View All" : "View Less"}
          </button>
        )}

        {paginatorView === "VIEW_LESS" && (
          <Pagination
            className={paginator.className}
            total={filteredData && filteredData.length}
            onPageChange={this.handlePaginationChange}
            stepSize={paginator.stepSize}
            rangeStart={pageStart}
          />
        )}
      </div>
    ) : null;

    return (
      <div className={className}>
        {showPaginator && paginatorElement}
        <Table
          {...this.props}
          columns={columns}
          data={paginatedData || filteredData}
          onRequestSort={this.handleRequestSort}
          onColumnFilterChange={this.handleColumnFilterChange}
          className={tableClassName}
        />

        {showPaginatorAtBottom && paginatorElement}
      </div>
    );
  }
}

TableClient.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  hasStickyHeader: PropTypes.bool,
  groupBy: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  groupByClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  getGroupByValue: PropTypes.func,
  stickyHeaderOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stickyHeaderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  tableClassName: PropTypes.string,
  onRowClick: PropTypes.func,
  onRequestSort: PropTypes.func,
  onRequestPaginate: PropTypes.func,
  onRequestToggleViewPagination: PropTypes.func,
  paginator: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isFilteringEnabled: PropTypes.bool,
};

TableClient.defaultProps = {
  data: [],
  groupBy: undefined,
  groupByClassName: undefined,
  getGroupByValue: noop,
  hasStickyHeader: false,
  stickyHeaderOffset: undefined,
  stickyHeaderWidth: undefined,
  paginator: {
    stepSize: 100,
    start: 0,
    className: "",
  },
  className: undefined,
  tableClassName: undefined,
  onRowClick: noop,
  onRequestSort: noop,
  onRequestPaginate: noop,
  onRequestToggleViewPagination: noop,
  isFilteringEnabled: false,
};

export default TableClient;
