import PropTypes from "prop-types";
import React from "react";
import { filter } from "underscore";
import CompletenessMeter from "./CompletenessMeter";
import Mixpanel from "mixpanel";
import analytics from "analytics";

export default class CompletenessMeterContainer extends React.Component {
  constructor(props) {
    super(props);

    const isCompleted = props.data.scorePercentage === 100;
    const tips = this.getTipsArray(this.props.data.steps);

    this.state = {
      tipIndex: 0,
      tips: tips,
      isCompleted: isCompleted,
    };

    this.onNextTipClick = this.onNextTipClick.bind(this);
    this.onUnavailableHover = this.onUnavailableHover.bind(this);
    this.onTipActionClick = this.onTipActionClick.bind(this);
  }

  getTipsArray(steps) {
    const filteredSteps = filter(steps, (step) => {
      return step.status === "INCOMPLETE";
    });
    let filteredTips = [];

    filteredSteps.forEach((step) => {
      step.tips.forEach((tip) => {
        if (tip.status !== "COMPLETED" && tip.status !== "SKIPPED") {
          tip.stepTitle = step.summary;
          tip.stepKey = step.key;
          filteredTips.push(tip);
        }
      });
    });

    return filteredTips;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const isCompleted = nextProps.data.scorePercentage === 100;
    const tips = this.getTipsArray(nextProps.data.steps);

    this.setState({ tipIndex: 0, isCompleted: isCompleted, tips: tips });
  }

  onNextTipClick(e, action) {
    Mixpanel.trackEvent("Click on Completeness Meter Card Next Tip", {
      "From Step": action.title,
      "Action Key": action.key,
      "Current Score": this.props.data.scorePercentage,
    });

    this.setState({
      tipIndex:
        this.state.tipIndex + 1 > this.state.tips.length - 1
          ? 0
          : this.state.tipIndex + 1,
    });
  }

  onTipActionClick(e, isUnavailable, action) {
    if (isUnavailable) {
      e.preventDefault();
    } else if (IS_EMPOWER) {
      analytics.sendEmpowerTrackEvent(
        "Landing Page Banner",
        `${action.title} - ${action.key}`
      );
    } else {
      Mixpanel.trackEvent("Click on Completeness Meter Next Step", {
        "Next Step": action.title,
        "Action Key": action.key,
        "Current Score": this.props.data.scorePercentage,
      });
    }
  }

  onUnavailableHover(e, action, reason) {
    Mixpanel.trackEvent("Click on Completeness Meter Unavailable Tooltip", {
      "Next Step": action.title,
      "Action Key": action.key,
      "Unavailable Tip": reason,
      "Current Score": this.props.data.scorePercentage,
    });
  }

  render() {
    const { tipIndex, errors, isCompleted, tips } = this.state;
    const { data } = this.props;
    const activeTip = tips[tipIndex];
    const areMultipleTips = tips.length > 1;

    return (
      <CompletenessMeter
        errors={errors}
        onNextTipClick={this.onNextTipClick}
        onTipActionClick={this.onTipActionClick}
        stepIndex={(activeTip && activeTip.stepKey) || ""}
        tipData={activeTip}
        areMultipleTips={areMultipleTips}
        stepData={data}
        isCompleted={isCompleted}
        onUnavailableHover={this.onUnavailableHover}
        showFinalAnimation={data.showFinalAnimation}
        showAddAccounts={this.props.showAddAccounts}
      />
    );
  }
}

CompletenessMeterContainer.propTypes = {
  data: PropTypes.object,
  showAddAccounts: PropTypes.bool,
};

CompletenessMeterContainer.defaultProps = {
  data: undefined,
  showAddAccounts: true,
};
