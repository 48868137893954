/** It pre-process an alphanumeric value to be sorted so the sorting is valid. Here we can
 *  handle cases like when the alphanumeric value been sorted is undefined or null.
 * @param {String} val - The value to be processed before sorting.
 *
 * @return {String} The string to sort against.
 */
export function valueProcessorAlpha(val) {
  if (val && typeof val === "string") {
    return val.toUpperCase();
  }
  return "";
}

/** It pre-process a numeric value to be sorted so the sorting is valid. Here we can
 *  handle cases like when the number been sorted is undefined or null.
 * @param {Number} val - The number to be procesed.
 *
 * @return {Object | 0} The moment object representing the date provided by params. If val is undefined or null
 *                      the value returned will be 0.
 */
export function valueProcessorNum(val) {
  if (val == null) {
    return 0;
  }
  return val;
}

/** Function to build a comparator
 *
 * @param {Function} valueProcessor - It pre-process the value to be sorted, so the sorting is valid. Here we can
 *                                    handle cases like when the element been sorted is undefined or null
 * @return {Function} The comparator function
 */
export function buildComparator(valueProcessor) {
  return function (accessor, order) {
    return function (a, b) {
      if (order === "desc") {
        const temp = a;
        a = b;
        b = temp;
      }

      let aVal = valueProcessor(accessor(a));
      let bVal = valueProcessor(accessor(b));

      if (aVal < bVal) {
        return -1;
      }
      if (aVal > bVal) {
        return 1;
      }

      return 0;
    };
  };
}

/**
 * Comparator function for alphabetical values to use in `TableClient` component
 * as `comparator` function.
 */
export const compareAlpha = buildComparator(valueProcessorAlpha);

/**
 * Comparator function for numerical values to use in `TableClient` component
 * as `comparator` function.
 */
export const compareNum = buildComparator(valueProcessorNum);
