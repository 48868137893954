import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import SiteView2 from "views/modules/sidebar/partials/site2";
import AccountGroupTemplate from "templates/modules/sidebar/accountGroup2.html";
import AccountGroupHeaderTemplate from "templates/modules/sidebar/accountGroupHeader2.html";
import Account from "models/account";

const RESUME_LINKING_GROUP_CONFIG = {
  label: "RESUME LINKING",
  isLiability: false,
  isStillImporting: false,
  isNeedAttention: true,
  isInstitutionalPartner: false,
};

/* This component will process these 2 nextAction values */
const NEXT_ACTION_TO_SHOW = ["PAUSED_LINKING", "RESUME_LINKING"];

let AccountGroupView = Backbone.View.extend({
  className: "sidebar-account__group",
  tagName: "li",
  initialize: function (options) {
    this.options = options;
    this.$el.html(
      AccountGroupTemplate({
        groupName: options.groupName,
        panelName: `${options.groupName}_GROUP_VIEW`,
        label: Account.PRODUCT_TYPES_LABELS[options.groupName],
        groupClassName: "sidebar-account__group-header--thin",
      })
    );
    this.$el.addClass(
      this.options.groupName +
        " qa-" +
        this.options.groupName.toLowerCase().replace("_", "-") +
        "-accounts"
    );
    $(options.elementContainer).append(this.$el);
    this.$list = this.$("ul");
    this.$(".js-sidebar-account__group-header").html(
      AccountGroupHeaderTemplate(RESUME_LINKING_GROUP_CONFIG)
    );
    this.model.on("requestRenderAccount", this.render, this);
    this.collection.on("reset", this.render, this);
    this.collection.on("freshen", this.render, this);
    this.siteViews = {};
    this.render();
  },
  render: function () {
    // mark all views for deletion
    _.each(this.siteViews, (view) => {
      view.toBeRemoved = true;
    });

    // Only render accounts where the next action is in NEXT_ACTION_TO_SHOW
    // Accounts that are not in Blackout periods anymore, OR  Still in blackout OR OAuth that were abandoned
    let accountsInError = this.collection.filter((act) =>
      NEXT_ACTION_TO_SHOW.includes(act.get("nextAction")?.action)
    );
    if (accountsInError?.length > 0) {
      let sites = _.groupBy(accountsInError, (act) => act.get("userSiteId"));
      _.each(sites, this.renderSite, this);
      this.$el.show();
    } else {
      this.$el.hide();
    }
    // remove views still marked for deletion
    _.each(this.siteViews, (view) => {
      if (view.toBeRemoved) {
        view.remove();
        delete this.siteViews[view.model.at(0).get("userSiteId")];
      }
    });
  },
  renderSite: function (accounts, userSiteId) {
    var view = this.siteViews[userSiteId];
    // in-place updates, cache a reference to each account renderer by accountId and re-use the renderer if it was already created
    if (typeof view === "object") {
      // exists, update it
      view.model.reset(accounts);
    } else {
      view = this.siteViews[userSiteId] = new SiteView2({
        userSiteId,
        accounts,
      });
      this.$list.append(view.render().el);
    }
    view.toBeRemoved = false;
  },
  isAnyExtraFormVisible: function () {
    return _.some(this.siteViews, function (accountView) {
      return accountView.isExtraFormVisible;
    });
  },
});
export default AccountGroupView;
