var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"custom-zendesk-help-button\" href=\"https://"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"organizationSupportUrl") || (depth0 != null ? lookupProperty(depth0,"organizationSupportUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"organizationSupportUrl","hash":{},"data":data,"loc":{"start":{"line":1,"column":52},"end":{"line":1,"column":78}}}) : helper)))
    + "/hc/en-us\" target=\"_blank\">\n  <span class=\"custom-zendesk-help-button__icon\">\n    <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" viewBox=\"0 0 20 20\">\n      <path d=\"M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z\"></path>\n      <circle cx=\"10\" cy=\"15\" r=\"1\"></circle>\n      <path d=\"M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z\"></path>\n    </svg>\n  </span>\n  <span class=\"custom-zendesk-help-button__label pc-epsilon\">Help</span>\n</a>\n";
},"useData":true});