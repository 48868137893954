import React, { useState } from "react";
import PropTypes from "prop-types";
import ConfirmModal from "common/ConfirmModal/ConfirmModal";
import { promisify } from "utils/service";
import Services from "services";
import Message from "components/common/Message";
import TooltipIcon from "../../../common/TooltipIcon/TooltipIcon";
import { template } from "../../columns/default";

const stopPropagation = (event) => {
  event.stopPropagation();
};

export default function TransactionDescription({
  transaction,
  serviceCancelTransaction,
}) {
  const [isConfirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState();

  return (
    <>
      <div className="transaction-description__container">
        <div className="transaction-description__left u-clip-text">
          {transaction.description}
        </div>
        {transaction.imageUrl && (
          <div className="transaction-description__right">
            <TooltipIcon
              title={`<img class="transaction-description__image" alt="Transaction Image" src="${transaction.imageUrl}" />`}
              tooltipProps={{ template }}
            />
          </div>
        )}
      </div>
      {transaction.isCancellable && (
        <div className="transaction-description__container">
          <div className="transaction-description__left">
            <button
              type="button"
              className="pc-btn pc-btn--link pc-btn--small js-action-cancel"
              onClick={(event) => {
                event.stopPropagation();
                setErrors();
                setConfirmCancelOpen(true);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {isConfirmCancelOpen && (
        <ConfirmModal
          title="Cancel Transaction"
          cancelLabel="No"
          confirmLabel="Yes"
          loading={isLoading}
          onClickContainer={stopPropagation}
          onConfirm={() => {
            setErrors();
            setLoading(true);
            serviceCancelTransaction({
              referenceId: transaction.referenceId,
            }).then(
              () => {
                setLoading(false);
                setConfirmCancelOpen(false);
              },
              (errors) => {
                setLoading(false);
                setErrors(errors);
              }
            );
          }}
          onCancel={() => setConfirmCancelOpen(false)}
          isOpen={isConfirmCancelOpen}
        >
          <div>
            <Message severity="error" messages={errors} />
            Are you sure you would like to cancel this transaction?
          </div>
        </ConfirmModal>
      )}
    </>
  );
}

TransactionDescription.propTypes = {
  transaction: PropTypes.object.isRequired,
  serviceCancelTransaction: PropTypes.func,
};

TransactionDescription.defaultProps = {
  serviceCancelTransaction: promisify(Services.Transactions.cancel),
};
