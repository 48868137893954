import { getSource } from "components/common/attributionStore";
import { isEmpty } from "underscore";

const PHONE_IDENTIFIER = "PHONE";
const EMAIL_IDENTIFIER = "EMAIL";

export const getTimeRangeSegment = (appointmentId) => {
  if (!appointmentId) return;
  const arr = appointmentId.split("-");
  return `${arr[1]}-${arr[2]}`;
};

export const findAvailability = (appointmentId, availabilities) => {
  return availabilities.find(
    (a) =>
      getTimeRangeSegment(a.appointmentId) ===
      getTimeRangeSegment(appointmentId)
  );
};

export const newAppointment = (
  person,
  isLimited = false,
  appointmentType,
  duration,
  selectedFpTopics
) => {
  const appointment = {
    appointmentStatus: "FREE",
  };

  if (person) {
    if (isLimited) {
      if (person.contacts) {
        let phone = person.contacts.find((c) => {
          return c.type === PHONE_IDENTIFIER && c.primary;
        });
        let email = person.contacts.find((c) => {
          return c.type === EMAIL_IDENTIFIER && c.primary;
        });
        Object.assign(appointment, {
          userEmail: email ? email.value : "",
          userPhoneNumber: phone ? phone.value.replace(/\./g, "*") : "",
        });
      }
    } else {
      const { name = {} } = person;
      Object.assign(appointment, {
        firstName: name.firstName,
        userEmail: person.emailAddress,
        userPhoneNumber: person.phone,
      });
    }
  }

  if (appointmentType) {
    appointment.appointmentType = appointmentType;
  }

  if (duration) {
    appointment.duration = duration;
  }

  if (selectedFpTopics) {
    appointment.selectedFpTopics = selectedFpTopics;
  }

  return appointment;
};

export const isNewAppointment = (appointment) => {
  return appointment.appointmentStatus === "FREE";
};

export const getAppointmentTime = (appointment, availabilities, reschedule) => {
  let appointmentStartTime, appointmentEndTime;
  if (reschedule || isNewAppointment(appointment)) {
    // In reschedule flow, the newly selected appointment id is stored
    // under `newAppointmentId`, while the old one remains `appointmentId`.
    const appointmentId =
      appointment[reschedule ? "newAppointmentId" : "appointmentId"];
    if (appointmentId && !isEmpty(availabilities)) {
      const availability = findAvailability(appointmentId, availabilities);
      appointmentStartTime = availability?.startTime;
      appointmentEndTime = availability?.endTime;
    }
  } else {
    appointmentStartTime = appointment.startTime;
    appointmentEndTime = appointment.endTime;
  }
  return { appointmentStartTime, appointmentEndTime };
};

export const getEndOfMonth = (moment) => {
  return moment.clone().endOf("month");
};

export const getImageUrl = (id) => {
  if (id) {
    return `${window.staticUrl}/advisor/images/${id}.jpg`;
  }
};

/**
 * Prospects who have an advisor assigned don't have a service advisor assigned to them yet.
 * This method returns the service advisor from the `getAdvisor` response
 * and falls back to the sales advisor if there is no service advisor.
 *
 * @param {Object} advisorResponse `getAdvisor` API response
 * @returns {Object} the advisor name and id
 */
export const getAdvisor = (advisorResponse) => {
  let id = advisorResponse.serviceId;
  let name = advisorResponse.serviceName;
  let isCFP = advisorResponse.serviceIsCFP;

  if (id == null) {
    id = advisorResponse.id;
    name = advisorResponse.name;
    isCFP = advisorResponse.isCFP;
  }

  return {
    id,
    imgURL: getImageUrl(id),
    name: name ? `${name}${isCFP ? " CFP®" : ""}` : name, // don't concatenate when name is undefined
  };
};

export const getAppointmentSource = () => {
  return getSource() || "";
};

export const generateAppointmentDeepLink = (appointment) => {
  return `#/appointment?type=${appointment.appointmentType}&duration=${appointment.defaultDuration}`;
};
