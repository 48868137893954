import React from "react";
import Wizard from "common/Wizard";
import PropTypes from "prop-types";
import TransferFunds from "../TransferFunds";
import TransferConfirmation from "../TransferConfirmation";
import deepCopy from "deep-copy";
import { noop, isEmpty } from "underscore";
import AccountDetailsContainer from "../AccountDetailsContainer";
import UploadStatement from "components/transferFunds/UploadStatement";
import memoizeOne from "memoize-one";
import {
  isAcatTransfer,
  findAccountByUserAccountId,
  isOnUs,
  isOnUsInvestment,
  isOnUsBank,
} from "utils/account";

// Use human-readable names as this serves as a title for the modal.
import {
  STEP_TRANSFER_FUNDS,
  STEP_ACCOUNT_DETAILS,
  STEP_UPLOAD_STATEMENT,
  STEP_REVIEW,
  STEP_CONFIRMATION,
} from "./constants";

import {
  ACCOUNT_TYPE_PCC,
  ACCOUNT_TYPE_ONUS,
  ACCOUNT_TYPE_EXTERNAL,
} from "../utils/constants";

// storing two memoized functions for efficiency
const getSourceAccount = memoizeOne(findAccountByUserAccountId);
const getTargetAccount = memoizeOne(findAccountByUserAccountId);

function buildPages(props, pages) {
  const { sourceAccountId, targetAccountId } = props.model;
  const sourceAccount = getSourceAccount(sourceAccountId, props.sourceAccounts);
  const targetAccount = getTargetAccount(targetAccountId, props.targetAccounts);
  const statementUploadStepIndex = pages.indexOf(STEP_UPLOAD_STATEMENT);

  if (
    isAcatTransfer(sourceAccount, targetAccount) &&
    !isOnUs(sourceAccount) &&
    isOnUs(targetAccount)
  ) {
    if (statementUploadStepIndex === -1) {
      const reviewStepIndex = pages.indexOf(STEP_REVIEW);
      const newPages = pages.slice();
      newPages.splice(reviewStepIndex, 0, STEP_UPLOAD_STATEMENT);
      return newPages;
    }
  } else if (statementUploadStepIndex !== -1) {
    const newPages = pages.slice();
    newPages.splice(statementUploadStepIndex, 1);
    return newPages;
  }
}

export default class TransferFundsWizard extends Wizard {
  constructor(props) {
    super(props);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleTransferFund = this.handleTransferFund.bind(this);
    this.handleAccountDetailsComplete =
      this.handleAccountDetailsComplete.bind(this);
    this.handleUploadStatementComplete =
      this.handleUploadStatementComplete.bind(this);
  }

  handleContinue(model) {
    this.props.onNextPage(model, () => {
      // navigate to the next page after the container notifies about a completed API call
      this.handleNext();
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { sourceAccountId, targetAccountId } = props.model;
    const newState = { ...super.getDerivedStateFromProps(props, state) };
    if (sourceAccountId !== state.prevPropsSourceAccountId) {
      newState.prevPropsSourceAccountId = sourceAccountId;
    }

    if (targetAccountId !== state.prevPropsTargetAccountId) {
      newState.prevPropsTargetAccountId = targetAccountId;
    }

    if (
      sourceAccountId !== state.prevPropsSourceAccountId ||
      targetAccountId !== state.prevPropsTargetAccountId
    ) {
      // `state.pages` will not be initialized during the initial call.
      // Falling back to `newState.pages` as that will be available after
      // calling `super.getDerivedStateFromProps()`.
      const newPages = props.pageBuilder?.(
        props,
        state.pages ?? newState.pages
      );
      if (newPages) {
        newState.pages = newPages;
      }
    }

    if (!isEmpty(newState)) {
      return newState;
    }

    return null;
  }

  componentDidMount() {
    this.props.onChildMounted(this.getCurrentPageName());
  }

  componentDidUpdate(_, prevState) {
    const { enableForm, onChildMounted } = this.props;

    if (this.isReviewPage() && enableForm) {
      this.setState({
        pageIndex: this.getPageIndexByName(STEP_TRANSFER_FUNDS),
      });
    }

    if (
      prevState.pageIndex !== this.state.pageIndex &&
      this.getCurrentPageName() !== STEP_ACCOUNT_DETAILS
    ) {
      onChildMounted(this.getCurrentPageName());
    }
  }

  handleTransferFund(model) {
    this.props.onTransferFund(model, () => {
      this.handleNext();
    });
  }

  isConfirmationPage() {
    return this.getCurrentPageName() === STEP_CONFIRMATION;
  }

  isReviewPage() {
    return this.getCurrentPageName() === STEP_REVIEW;
  }

  handleBack(callback) {
    this.setState({ prevPageIndex: this.state.pageIndex });
    super.handleBack(callback);
  }

  handleNext(callback) {
    this.setState({ prevPageIndex: this.state.pageIndex });
    super.handleNext(callback);
  }

  /**
   * `handleAccountDetailsComplete` can be called immediately upon the load of
   * `AccountDetailsContainer` in case there are no prompts to display on the step.
   *
   * This handler determines whether the user is going forward or backward in the flow
   * and calls the right handler to proceed with the flow in the correct direction.
   *
   * @param {Boolean} skip a boolean flag indicating whether it is being called as a result of
   * no prompts being available on the page (`true`) or the user pressing Continue button (`false`).
   */
  handleAccountDetailsComplete(skip) {
    if (skip) {
      const { pageIndex, prevPageIndex = 0 } = this.state;
      if (pageIndex > prevPageIndex) {
        this.handleContinue();
      } else {
        this.handleBack();
      }
    } else {
      this.handleContinue();
    }
  }

  handleUploadStatementComplete(statements) {
    this.handleContinue({ statements });
  }

  getPage() {
    const {
      personFullName,
      isToDropDownDisabled,
      isFromDropDownDisabled,
      isContributionYearDisabled,
      onTargetAccountChange,
      errors,
      model,
      onBack,
      sourceAccounts,
      targetAccounts,
      contributionYears,
      onSourceAccountChange,
      onViewAccountNumber,
      docusignResult,
      stateType,
      transferType,
      onChildMounted,
      onLinkAccount,
      targetAccountOwnerAge,
      hasRecurringTransferEstablished,
      isPreviousYearContribution,
      onModelChange,
      hasOneTimeFrequency,
      recurringTransferHelpText,
      isTaxWithholdingEnabled,
      isPSTaxWithholdingEnabled,
      isRMDEnabled,
      showTimeOutError,
      onEditFederalTaxWithholding,
    } = this.props;
    let pageName =
      docusignResult === undefined
        ? this.getCurrentPageName()
        : STEP_CONFIRMATION;

    const sourceAccount = getSourceAccount(
      model.sourceAccountId,
      sourceAccounts
    );
    const targetAccount = getTargetAccount(
      model.targetAccountId,
      targetAccounts
    );

    const isAcat = isAcatTransfer(sourceAccount, targetAccount);
    let sourceType = ACCOUNT_TYPE_EXTERNAL;

    if (isOnUsInvestment(sourceAccount)) {
      sourceType = ACCOUNT_TYPE_ONUS;
    } else if (isOnUsBank(sourceAccount)) {
      sourceType = ACCOUNT_TYPE_PCC;
    }

    let page;
    switch (pageName) {
      case STEP_ACCOUNT_DETAILS:
        page = (
          <AccountDetailsContainer
            className="transfer-funds-container-component__content"
            sourceAccountId={model.sourceAccountId}
            targetAccountId={model.targetAccountId}
            accounts={sourceAccounts.concat(targetAccounts)}
            onComplete={this.handleAccountDetailsComplete}
            onBack={this.handleBack}
            onMounted={onChildMounted}
            transferType={transferType}
          />
        );
        break;
      case STEP_UPLOAD_STATEMENT: {
        // This step is displayed for ACAT (Investment -> Investment) transfers. `isOnUs` check is sufficient.
        const externalAccount = sourceAccount.isOnUs
          ? targetAccount
          : sourceAccount;

        page = (
          <UploadStatement
            className="transfer-funds-container-component__content"
            statements={model.statements}
            userAccountId={externalAccount.userAccountId}
            accountName={externalAccount.name}
            firmName={externalAccount.firmName}
            logoPath={externalAccount.logoPath}
            balance={externalAccount.balance}
            onContinue={this.handleUploadStatementComplete}
            onBack={this.handleBack}
            transferType={transferType}
          />
        );
        break;
      }
      case STEP_REVIEW:
        page = (
          <TransferFunds
            personFullName={personFullName}
            errors={errors}
            model={deepCopy(model)}
            sourceAccounts={sourceAccounts}
            targetAccounts={targetAccounts}
            contributionYears={contributionYears}
            readonly={true}
            onContinue={this.handleTransferFund}
            onViewAccountNumber={onViewAccountNumber}
            isToDropDownDisabled={isToDropDownDisabled}
            isFromDropDownDisabled={isFromDropDownDisabled}
            isContributionYearDisabled={isContributionYearDisabled}
            onBack={this.handleBack}
            stateType={stateType}
            transferType={transferType}
            showEditAccountNumber={true}
            hasOneTimeFrequency={hasOneTimeFrequency}
            hasRecurringTransferEstablished={hasRecurringTransferEstablished}
            isPreviousYearContribution={isPreviousYearContribution}
            onModelChange={onModelChange}
            recurringTransferHelpText={recurringTransferHelpText}
            isTaxWithholdingEnabled={isTaxWithholdingEnabled}
          />
        );
        break;
      case STEP_CONFIRMATION:
        page = (
          <TransferConfirmation
            transferType={transferType}
            sourceType={sourceType}
            amount={Number(model.transferAmount)}
            docusignResult={docusignResult}
            isAcatTransfer={isAcat}
            sourceAccount={sourceAccount}
            targetAccount={targetAccount}
          />
        );
        break;
      case STEP_TRANSFER_FUNDS:
      default:
        page = (
          <TransferFunds
            personFullName={personFullName}
            errors={errors}
            model={deepCopy(model)}
            sourceAccounts={sourceAccounts}
            targetAccounts={targetAccounts}
            contributionYears={contributionYears}
            onViewAccountNumber={onViewAccountNumber}
            onContinue={this.handleContinue}
            onSourceAccountChange={onSourceAccountChange}
            onTargetAccountChange={onTargetAccountChange}
            onBack={
              showTimeOutError ? this.props.handleTimeoutErrorBack : onBack
            }
            onLinkAccount={onLinkAccount}
            isToDropDownDisabled={isToDropDownDisabled}
            isFromDropDownDisabled={isFromDropDownDisabled}
            isContributionYearDisabled={isContributionYearDisabled}
            stateType={stateType}
            transferType={transferType}
            showEditAccountNumber={false}
            targetAccountOwnerAge={targetAccountOwnerAge}
            hasOneTimeFrequency={hasOneTimeFrequency}
            hasRecurringTransferEstablished={hasRecurringTransferEstablished}
            isPreviousYearContribution={isPreviousYearContribution}
            onModelChange={onModelChange}
            recurringTransferHelpText={recurringTransferHelpText}
            isTaxWithholdingEnabled={isTaxWithholdingEnabled}
            isPSTaxWithholdingEnabled={isPSTaxWithholdingEnabled}
            isRMDEnabled={isRMDEnabled}
            readonly={showTimeOutError ? true : undefined}
            disableSubmitButton={showTimeOutError}
            onEditFederalTaxWithholding={onEditFederalTaxWithholding}
          />
        );
        break;
    }

    return page;
  }

  render() {
    return (
      <div className="transfer-funds-fund-account-wizard qa-page-transfer-funds">
        {this.isConfirmationPage() ? (
          this.getPage()
        ) : (
          <div className="pc-layout pc-layout--large pc-layout--center">
            <div className="pc-layout__item">{this.getPage()}</div>
          </div>
        )}
      </div>
    );
  }
}

TransferFundsWizard.propTypes = {
  pageIndex: PropTypes.number,
  sourceAccounts: PropTypes.array,
  targetAccounts: PropTypes.array,
  model: PropTypes.object,
  errors: PropTypes.array,
  onSourceAccountChange: PropTypes.func,
  onTargetAccountChange: PropTypes.func,
  onNextPage: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onLinkAccount: PropTypes.func,
  personFullName: PropTypes.string,
  onTransferFund: PropTypes.func.isRequired,
  isToDropDownDisabled: PropTypes.bool,
  isFromDropDownDisabled: PropTypes.bool,
  isContributionYearDisabled: PropTypes.bool,
  enableForm: PropTypes.bool,
  onViewAccountNumber: PropTypes.func,
  onChildMounted: PropTypes.func,
  docusignResult: PropTypes.bool,
  stateType: PropTypes.string,
  transferType: PropTypes.number.isRequired,
  contributionYears: PropTypes.object,
  targetAccountOwnerAge: PropTypes.number,
  hasRecurringTransferEstablished: PropTypes.bool,
  isPreviousYearContribution: PropTypes.bool,
  hasOneTimeFrequency: PropTypes.bool,
  onModelChange: PropTypes.func,
  pageBuilder: PropTypes.func,
  recurringTransferHelpText: PropTypes.string,
  isTaxWithholdingEnabled: PropTypes.bool,
  isPSTaxWithholdingEnabled: PropTypes.bool,
  isRMDEnabled: PropTypes.bool,
  showTimeOutError: PropTypes.bool,
  handleTimeoutErrorBack: PropTypes.func,
  onEditFederalTaxWithholding: PropTypes.func,
};

TransferFundsWizard.defaultProps = Object.assign({}, Wizard.defaultProps, {
  pages: [
    STEP_TRANSFER_FUNDS,
    STEP_ACCOUNT_DETAILS,
    STEP_UPLOAD_STATEMENT,
    STEP_REVIEW,
    STEP_CONFIRMATION,
  ],
  pageBuilder: buildPages,
  sourceAccounts: [],
  targetAccounts: [],
  onViewAccountNumber: noop,
  onChildMounted: noop,
  onLinkAccount: undefined,
  docusignResult: undefined,
  stateType: undefined,
  contributionYears: undefined,
  targetAccountOwnerAge: undefined,
  hasRecurringTransferEstablished: false,
  isPreviousYearContribution: false,
  hasOneTimeFrequency: undefined,
  onModelChange: noop,
  isToDropDownDisabled: false,
  isFromDropDownDisabled: false,
  isContributionYearDisabled: false,
  recurringTransferHelpText: undefined,
  isTaxWithholdingEnabled: false,
  isPSTaxWithholdingEnabled: false,
  isRMDEnabled: false,
  showTimeOutError: false,
  handleTimeoutErrorBack: noop,
  onEditFederalTaxWithholding: noop,
});
