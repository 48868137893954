import React from "react";
import PropTypes from "prop-types";

const IncorrectIcon = () => (
  <svg
    className="user-quiz__icon user-quiz__incorrect-icon"
    width="13"
    height="13"
  >
    <use xlinkHref="#pc-icon__close-x"></use>
  </svg>
);

const CorrectIcon = () => (
  <img
    src={`${window.staticUrl || ""}/static/img/icons/checkmark--green.svg`}
    alt=""
    className="user-quiz__icon user-quiz__correct-icon"
  />
);

const DisabledInputIcon = () => (
  <input type="radio" name="user-quiz-choice" checked={false} disabled />
);

const getIcon = (correctAnswer, currentValue, choice) => {
  if (correctAnswer === currentValue) {
    if (choice.isCorrect) {
      return <CorrectIcon />;
    }
    return <DisabledInputIcon />;
  }
  if (choice.isCorrect) {
    return <CorrectIcon />;
  }
  if (currentValue === choice.text) {
    return <IncorrectIcon />;
  }
  return <DisabledInputIcon />;
};

export const Choices = ({
  choices,
  currentValue,
  setCurrentValue,
  isSubmitted,
  correctAnswer,
}) => {
  return (
    <div className="user-quiz-choices">
      {choices.map((choice) => {
        return (
          <div key={choice.text}>
            <label className="pc-label pc-label--inline pc-u-ml">
              {isSubmitted ? (
                getIcon(correctAnswer, currentValue, choice)
              ) : (
                <input
                  type="radio"
                  name="user-quiz-choice"
                  value={choice.text}
                  checked={currentValue === choice.text}
                  onChange={() => setCurrentValue(choice.text)}
                />
              )}
              <span className="user-quiz-choice-text">{choice.text}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

Choices.propTypes = {
  choices: PropTypes.array.isRequired,
  currentValue: PropTypes.string.isRequired,
  setCurrentValue: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  correctAnswer: PropTypes.string.isRequired,
};
