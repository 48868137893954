/* eslint-disable react/prop-types */

import React, { Component } from "react";
import FancySelect from "../common/form/FancySelect/FancySelect";
import PropTypes from "prop-types";
import CategoriesManager from "components/modal/categoriesManager/CategoriesManagerContainer";
import memoizeOne from "memoize-one";

export const getGroupCategories = memoizeOne((transactionCategories) => {
  const groupedCategories = [];
  const groups = [];
  transactionCategories.forEach((category) => {
    const categoryType = category.type
      ? category.type.replace("_", " ")
      : "Uncategorized";
    if (!groups[categoryType]) {
      groups[categoryType] = [];
    }
    groups[categoryType].push({
      value: category.transactionCategoryId,
      label: category.name,
    });
  });
  Object.keys(groups)
    .sort()
    .forEach((key) => {
      groupedCategories.push({ label: key, options: groups[key] });
    });
  return groupedCategories;
});
/*
 The category manager manages all CRUD operations over categories and based in the props passed in instantiation it will
 open the `Add` mode to add a category or the `default` (where all the categories are listed) mode to open the Category manager view.

 While editing a transactions, if the user opens the category manager (click in manage categories before typing anything in the search)
 and perform an action, we should always take him back to the default state, the category list. This is different if the user wanted
 to create a transaction, after creation, the category manager (which was rendering the add category mode) should close and
 the parent component refresh with the new category.

 So we have two scenarios:

 - When the user opens the category selector to select a category and then clicks the Categories manager button:
      Open the CRUD over transaction categories. Every time there's a successful transaction (CUD),
      the user should be taken back to the category manager.
 - When the user opens in the category selector and search a transaction that doesn't exist. Then the user clicks the Create "<t.name>" category button:
      This should open the category manager in Add Category mode. After the user added it
      (successful create transaction), the category manager should close, notify the parent the new category
      and the parent (category selector) should update adding the new category to the list and selecting it by default.
 */
export class CategorySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      searchResultCount: 0,
      isCategoryManagerOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearchInput = this.clearSearchInput.bind(this);
    this.handleOpenCategoryManager = this.handleOpenCategoryManager.bind(this);
    this.handleCloseCategoryManager = this.handleCloseCategoryManager.bind(
      this
    );
    this.handleOnCategoryCreated = this.handleOnCategoryCreated.bind(this);
  }

  handleChange(ev) {
    if (this.props.onChange) {
      this.props.onChange(ev);
    }
  }

  handleSearch(data) {
    const { searchInput, searchResultCount } = this.state;
    if (
      data.searchInput !== searchInput ||
      data.resultCount !== searchResultCount
    ) {
      this.setState({
        searchInput: data.searchInput,
        searchResultCount: data.resultCount,
      });
    }
  }

  clearSearchInput() {
    this.setState({ searchInput: "" });
  }

  handleOpenCategoryManager() {
    this.setState({ isCategoryManagerOpen: true });
  }

  handleCloseCategoryManager() {
    this.setState({ isCategoryManagerOpen: false, searchInput: "" });
  }

  handleOnCategoryCreated(category) {
    this.setState({ searchInput: "" });
    this.handleCloseCategoryManager();
    if (this.props.onCategoryCreated) {
      this.props.onCategoryCreated(category);
    }
  }

  render() {
    const {
      searchInput,
      searchResultCount,
      isCategoryManagerOpen,
    } = this.state;
    const belowButtonLabel =
      !searchInput || searchResultCount
        ? "Manage Categories"
        : `"${searchInput}" (Create category)`;
    const isAddingCategory = searchInput.length > 0;
    let className = (this.props.className || "") + " qa-categories-selector";

    return (
      <div>
        {isCategoryManagerOpen && (
          <CategoriesManager
            isOpen={true}
            action={isAddingCategory ? "Add" : ""}
            categoryName={isAddingCategory ? searchInput : ""}
            categoryType={this.props.categoryType}
            handleModalClosed={this.handleCloseCategoryManager}
            onCancel={this.handleCloseCategoryManager}
            onSuccess={
              isAddingCategory ? this.handleOnCategoryCreated : undefined
            }
          />
        )}
        <FancySelect
          {...this.props}
          className={className}
          value={this.props.value}
          isSearchable={true}
          matchFromStart={false}
          onSearch={this.handleSearch}
          // If the category manager is not open, when the fancy select menu get's closed without selection, we need to
          // clear the serch input, otherwise, when setting the state to open the categoryManager, isAddingCategory will be false and the
          // onSuccessCallback prop passed in the top to CategoriesManager won't execute.
          // This means that after adding a category, the category manager will show up instead of getting the new category preselected in the
          // category selector and the manager closed.
          onMenuClose={
            isCategoryManagerOpen ? undefined : this.clearSearchInput
          }
          menuFooterComponent={
            <button
              onClick={this.handleOpenCategoryManager}
              type="button"
              className="pc-transactions-grid-edit__category-selector-btn qa-open-category-manager-btn Select__menu-button pc-btn pc-btn--ghost pc-btn--small"
            >
              {belowButtonLabel}
            </button>
          }
          onChange={this.handleChange}
          options={getGroupCategories(this.props.options)}
        />
      </div>
    );
  }
}

CategorySelector.defaultProps = Object.assign({}, FancySelect.defaultProps, {
  value: "",
});
CategorySelector.propTypes = Object.assign({}, FancySelect.propTypes, {
  onCategoryCreated: PropTypes.func,
});
CategorySelector.displayName = FancySelect.displayName || FancySelect.name;
