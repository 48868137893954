import Handlebars from "handlebars/runtime";

export default function (label) {
  var link = NETWORTH_URL + "/";
  switch (label.toLowerCase()) {
    case "still importing":
      link = "javascript:void(0)"; // eslint-disable-line no-script-url
      break;
    case "cash":
      link += "cash";
      break;
    case "investment":
      link += "investment";
      break;
    case "credit":
      link += "credit";
      break;
    case "loan":
      link += "loan";
      break;
    case "mortgage":
      link += "mortgage";
      break;
    case "other asset":
      link += "other";
      break;
    case "other liability":
      link += "other/liability";
      break;
    default:
      break;
  }

  return new Handlebars.SafeString(link);
}
