import PropTypes from "prop-types";
import React from "react";
import EditPlanModalContainer from "components/financialPlanner/EditPlanModalContainer";
import {
  EDIT_PLAN_VIEW,
  MAX_RP_PERCENT,
} from "components/financialPlanner/financialPlannerConstants";
import moment from "moment";
import { DISPLAY_FORMAT } from "libs/pcap/utils/date2";

const ONE_HUNDRED_PERCENT = 100;

export default class PlanListRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = { editing: false };
    this.handleClick = this.handleClick.bind(this);
    this.onEditModalClosed = this.onEditModalClosed.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
  }

  onDragStart(event) {
    event.dataTransfer.setData("text", JSON.stringify(this.props.plan));
  }

  render() {
    const { plan } = this.props;
    const { result } = plan;
    const panelClasses = `sidebar-plan${
      this.props.isActive ? " is-active" : ""
    } js-sidebar-plan qa-plan`;
    let percentSuccess;
    if (result && result.thermometer && result.thermometer.data) {
      percentSuccess = Math.min(
        MAX_RP_PERCENT,
        Math.round(
          ONE_HUNDRED_PERCENT - result.thermometer.data.percentageOfMiss,
          2
        )
      );
    }

    return (
      <div draggable={this.props.isDraggable} onDragStart={this.onDragStart}>
        <div className={panelClasses} onClick={this.handleClick}>
          <div className="sidebar-plan__first-col">
            {this.props.isEditable && (
              <svg
                className="sidebar-plan__edit-icon js-edit-plan qa-edit-plan"
                viewBox="0 0 9 9"
              >
                <use xlinkHref="#icon-pencil" />
              </svg>
            )}
          </div>
          <div className="sidebar-plan__second-col">
            <h4 className="sidebar-plan__title u-clip-text qa-plan-name">
              {plan.planName || "My Plan"}
            </h4>
            {plan.date ? (
              <span className="sidebar-plan__date milli">
                Updated{" "}
                {moment(plan.date, DISPLAY_FORMAT).format(DISPLAY_FORMAT)}
              </span>
            ) : null}
          </div>
          <div className="sidebar-plan__third-col">
            {typeof percentSuccess === "number" && (
              <div className="sidebar-plan__circle qa-plan-percent">
                {percentSuccess}
              </div>
            )}
          </div>
        </div>
        <EditPlanModalContainer
          isOpen={this.state.editing}
          plan={plan}
          onClosed={this.onEditModalClosed}
          onViewChange={this.handleViewChange}
          viewType={this.state.modalViewType}
          isGMWB={this.props.isGMWB}
          accounts={this.props.accounts}
          maModelPortfolios={this.props.maModelPortfolios}
        />
      </div>
    );
  }

  onEditModalClosed() {
    this.setState({ editing: false, modalViewType: EDIT_PLAN_VIEW });
  }

  handleViewChange(viewType) {
    this.setState({ modalViewType: viewType });
  }

  handleClick(event) {
    if (this.props.disabled) {
      return;
    }

    const isTargetEditButton =
      event.target.getAttribute("class") &&
      event.target.getAttribute("class").includes("js-edit-plan");

    let isTargetParentEditButton = false;
    if (
      event.target.parentNode &&
      event.target.parentNode.getAttribute("class") &&
      event.target.parentNode.getAttribute("class").includes("js-edit-plan")
    ) {
      isTargetParentEditButton = true;
    }

    if (isTargetEditButton || isTargetParentEditButton) {
      this.setState({ editing: true });
    } else if (!this.props.isActive) {
      if (this.props.onPlanSelected) {
        this.props.onPlanSelected(this.props.plan);
      }
    }
  }
}

PlanListRow.propTypes = {
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  isDraggable: PropTypes.bool,
  isEditable: PropTypes.bool,
  plan: PropTypes.object,
  onPlanSelected: PropTypes.func,
  isGMWB: PropTypes.bool,
  accounts: PropTypes.array,
  maModelPortfolios: PropTypes.array,
};

PlanListRow.defaultProps = {
  isActive: false,
  disabled: false,
  isDraggable: false,
  isEditable: false,
  plan: undefined,
  onPlanSelected: undefined,
  isGMWB: false,
  accounts: [],
  maModelPortfolios: [],
};
