import PropTypes from "prop-types";
import React from "react";
import DocumentUploaderContainer from "components/common/DocumentUploader/DocumentUploaderContainer";
import Modal from "components/modal/Modal";
import {
  DOCUMENT_ENDPOINT_PATHS,
  MAX_IMAGE_SIZE,
  TYPE_VALIDATORS,
  INPUT_FILE_TYPES,
} from "components/common/DocumentUploader/utils/constants";
import { getDocumentEndpoint } from "empower/utils/enrollmentUtils";

/**
 *
 * Image upload modal for transactions
 *
 * @returns {Modal} Modal component with document uploader
 **/
const TransactionImageUploadModal = ({
  onFileUploaded,
  onDone,
  userTransactionId,
  title,
  onFileUploadError,
}) => {
  const uploadAPIEndpoint = getDocumentEndpoint(
    DOCUMENT_ENDPOINT_PATHS.TRANSACTION_IMAGE
  );

  return (
    <Modal
      componentName={`Transaction Image Upload: ${title}`}
      title={title}
      isOpen={true}
      onClosed={onDone}
      shouldCloseOnOverlayClick={false}
    >
      <DocumentUploaderContainer
        maxFileSize={MAX_IMAGE_SIZE}
        fileTypeValidator={TYPE_VALIDATORS.IMAGES.validate}
        typeErrorMessage={TYPE_VALIDATORS.IMAGES.errorFormatter}
        acceptingFiles={INPUT_FILE_TYPES.IMAGES}
        onDone={onDone}
        uploadAPIEndpoint={uploadAPIEndpoint}
        requestParams={{
          userTransactionId,
        }}
        multiple={false}
        binaryParamName="imageBytes"
        onFileUploaded={onFileUploaded}
        onFileUploadError={onFileUploadError}
      />
    </Modal>
  );
};

TransactionImageUploadModal.defaultProps = {
  title: "Add Image",
  onFileUploaded: undefined,
  onFileUploadError: undefined,
};

TransactionImageUploadModal.propTypes = {
  onDone: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func,
  onFileUploadError: PropTypes.func,
  userTransactionId: PropTypes.number.isRequired,
  title: PropTypes.string,
};

export default TransactionImageUploadModal;
