/* eslint-disable no-magic-numbers */
import PropTypes from "prop-types";
import React from "react";
import BaseInput from "../BaseInput";
import CompositeInput from "../CompositeInput";
import Checkbox from "components/common/form/Checkbox";

export default class ButtonSelect extends CompositeInput {
  handleSelectButton(buttonValue) {
    let compositeValue = buttonValue;

    if (this.props.multiple) {
      // Use an array of `buttonValue` to implement multiple select
      const valueArray = Array.isArray(this.state.value)
        ? this.state.value
        : [this.state.value];

      // Enforce select option uniqueness via Set()
      const valueSet = new Set(valueArray);
      const isSelected = valueSet.has(buttonValue);

      if (isSelected) {
        valueSet.delete(buttonValue);
      } else {
        valueSet.add(buttonValue);
      }

      // Convert Set back to array for storage in `this.state`
      compositeValue = [...valueSet];
    }

    this.handleChangeWithValue(compositeValue);
  }

  getRadioButtonIcon() {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="button-select__radio-button button-select__radio-button--selected"
      >
        <circle cx="8" cy="8" r="7.5" fill="white" stroke="#0077CC" />
        <circle cx="8" cy="8" r="4" fill="#0077CC" />
      </svg>
    );
  }

  onKeyPress(buttonValue, e) {
    const enterOrSpace =
      e.key === "Enter" || e.key === " " || e.key === "Spacebar"; // Older browsers may return "Spacebar" instead of " " for the Space Bar key
    if (enterOrSpace) {
      e.preventDefault();
      this.handleSelectButton(buttonValue);
    }
  }

  render() {
    const buttonOptions = this.props.options;
    const customButtonClass = this.props.btnClassName;
    return (
      <div className="pc-form-group button-select-form-group">
        <div>
          {
            // eslint-disable-next-line sonarjs/cognitive-complexity
            buttonOptions.map((option) => {
              const buttonValue = option.value;
              const valueIsBoolean = typeof this.state.value === "boolean";
              let valueArray = Array.isArray(this.state.value)
                ? this.state.value
                : [this.state.value];
              if (!valueIsBoolean && !this.state.value) {
                valueArray = [];
              }
              const valueSet = new Set(valueArray);
              const isSelected = valueSet.has(buttonValue);
              const { disabled } = option;
              const btnClassName = `button-select__item input--full pc-btn ${customButtonClass} ${
                isSelected ? "is-active" : ""
              } ${disabled ? "disabled" : ""}`;

              let clickHandler;
              let keyPressHandler;
              if (!disabled) {
                clickHandler = this.handleSelectButton.bind(this, buttonValue);
                keyPressHandler = this.onKeyPress.bind(this, buttonValue);
              }
              return (
                // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                <div
                  key={buttonValue}
                  role={this.props.inputType}
                  tabIndex={disabled ? undefined : "0"}
                  aria-disabled={disabled}
                  aria-checked={isSelected}
                  className={btnClassName}
                  onClick={clickHandler}
                  onKeyPress={keyPressHandler}
                >
                  {this.props.showRadio ? (
                    <div className="button-select__item-radio">
                      {this.props.inputType === "checkbox" ? (
                        <Checkbox
                          checked={isSelected}
                          className={"button-select__checkbox"}
                          label={""}
                          name={option.value}
                        />
                      ) : (
                        <>
                          {isSelected ? (
                            this.getRadioButtonIcon()
                          ) : (
                            <div className="button-select__radio-button button-select__radio-button--unselected"></div>
                          )}
                        </>
                      )}
                      <div className="button-select__radio-label">
                        {option?.description ? (
                          <>
                            {option.label}
                            <br />
                            <span className="button-select__radio-description">
                              {option.description}
                            </span>
                          </>
                        ) : (
                          option.label
                        )}
                      </div>
                    </div>
                  ) : (
                    <>{option.label}</>
                  )}
                </div>
              );
            })
          }
        </div>
        {this.getErrorBlock({ placeholder: this.props.errorPlaceholder })}
      </div>
    );
  }
}

ButtonSelect.defaultProps = {
  multiple: false,
  showRadio: false,
  btnClassName: "pc-btn--small",
  inputType: "radio",
};

ButtonSelect.propTypes = Object.assign({}, BaseInput.propTypes, {
  className: PropTypes.string,
  errorPlaceholder: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]),
  showRadio: PropTypes.bool,
  btnClassName: PropTypes.string,
  options: PropTypes.array.isRequired,
  multiple: PropTypes.bool, // Default false
  inputType: PropTypes.oneOf(["checkbox", "radio"]),
});
