import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "underscore";
import { formatCurrency } from "libs/pcap/utils/format";
import ScrollableList from "components/common/ScrollableList";

const POSITION_TOP = "TOP";
const POSITION_BOTTOM = "BOTTOM";
// static from container class .budgeting__categories
const MAX_SCROLL_CONTAINER_HEIGHT = 215;

const BudgetingScrollableList = (props) => {
  const { level, transactionsGroups } = props;
  const containerRef = useRef();
  const [disableArrow, setDisableArrow] = useState(POSITION_TOP);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  const maxScrollContainerHeight = MAX_SCROLL_CONTAINER_HEIGHT;

  const setContainerRef = (ref) => {
    containerRef.current = ref;
  };

  const scrollToListClick = (position) => {
    const curr = containerRef.current;
    const isValidScrollTop =
      position === POSITION_TOP && disableArrow !== POSITION_TOP;
    const isValidScrollBottom =
      position === POSITION_BOTTOM && disableArrow !== POSITION_BOTTOM;
    const isValidScroll = isValidScrollTop || isValidScrollBottom;

    // adding or removing offset from the current scroll position
    if (curr && isValidScroll) {
      let scrollToPosition;
      if (isValidScrollTop) {
        scrollToPosition = currentPosition - maxScrollContainerHeight;
      } else if (isValidScrollBottom) {
        scrollToPosition = currentPosition + maxScrollContainerHeight;
      }
      curr.scrollTop = scrollToPosition;
      setCurrentPosition(scrollToPosition);
    }
    // `handleScroll` will handle the disabling/enabling up and down arrows
  };

  const handleScrollableUpdate = (isScrollable) => {
    setIsScrollable(isScrollable);
  };

  // handles both button and wheel scroll
  const handleScroll = () => {
    const curr = containerRef.current;
    if (curr) {
      //check if current position `curr.scrollTop` is scrolled to the top, bottom, or within middle
      setCurrentPosition(curr.scrollTop);
      if (curr.scrollTop === 0) {
        setDisableArrow(POSITION_TOP);
      } else if (curr.scrollTop === curr.scrollHeight - curr.offsetHeight) {
        setDisableArrow(POSITION_BOTTOM);
      } else {
        setDisableArrow(null);
      }
    }
  };
  const isUpArrowDisabled = disableArrow === POSITION_TOP;
  const isDownArrowDisabled = disableArrow === POSITION_BOTTOM;
  const renderCategoryLevel = level && level.type === "category";
  const shouldRenderZeroState = isEmpty(transactionsGroups);

  return (
    <>
      {!shouldRenderZeroState && isScrollable && (
        <div
          onClick={() => scrollToListClick(POSITION_TOP)}
          className="scrollable-list__arrow scrollable-list__arrow---up js-categories-list-up-arrow"
        >
          <div
            className={`scrollable-list__arrow-inner scrollable-list__arrow-inner---up ${
              isUpArrowDisabled ? "scrollable-list__arrow--disabled" : ""
            }`}
          >
            &#9652;
          </div>
        </div>
      )}
      <ScrollableList
        renderZeroState={() => <div className="l-spaced"></div>}
        renderListItem={(d) => {
          return renderCategoryLevel ? (
            <div className="l-spaced l-spaced--flush l-spaced--nowrap milli budgeting__category-text">
              <div title={d.originalName || d.name} className="u-clip-text">
                {d.name}
              </div>
              <div className="pc-u-ml--">{formatCurrency(d.amount)}</div>
            </div>
          ) : (
            <a
              className="pc-breadcrumb__node budgeting__category-link qa-budgeting__category-link"
              href={`#/cash-flow/spending/${d.id}`}
              title={d.originalName || d.name}
            >
              <div className="l-spaced l-spaced--flush l-spaced--nowrap milli">
                <div className="u-clip-text qa-budgeting__category-name">
                  {d.name}
                </div>
                <div className="pc-u-ml--">{formatCurrency(d.amount)}</div>
              </div>
            </a>
          );
        }}
        data={transactionsGroups || []}
        getContainerRef={setContainerRef}
        onScroll={handleScroll}
        onScrollableUpdated={handleScrollableUpdate}
      />
      {!shouldRenderZeroState && isScrollable && (
        <div
          onClick={() => scrollToListClick(POSITION_BOTTOM)}
          className="scrollable-list__arrow scrollable-list__arrow---down js-categories-list-down-arrow"
        >
          <div
            className={`scrollable-list__arrow-inner scrollable-list__arrow-inner---down ${
              isDownArrowDisabled ? "scrollable-list__arrow--disabled" : ""
            }`}
          >
            &#9662;
          </div>
        </div>
      )}
    </>
  );
};

BudgetingScrollableList.propTypes = {
  transactionsGroups: PropTypes.array,
  level: PropTypes.object,
};

BudgetingScrollableList.defaultProps = {
  level: undefined,
  transactionsGroups: [],
};

export default BudgetingScrollableList;
