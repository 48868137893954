function getCategoriesByType(categories) {
  let categoriesByType = {
    income: [],
    expense: [],
    other: [],
  };

  for (let category of categories) {
    switch (category.type) {
      case "INCOME":
        categoriesByType.income.push(category);
        break;
      case "EXPENSE":
        categoriesByType.expense.push(category);
        break;
      default:
        categoriesByType.other.push(category);
        break;
    }
  }
  categoriesByType.income.sort((categoryA, categoryB) =>
    categoryA.name.localeCompare(categoryB.name)
  );
  categoriesByType.expense.sort((categoryA, categoryB) =>
    categoryA.name.localeCompare(categoryB.name)
  );
  categoriesByType.other.sort((categoryA, categoryB) =>
    categoryA.name.localeCompare(categoryB.name)
  );

  return categoriesByType;
}

export default getCategoriesByType;
