import React from "react";
import PropTypes from "prop-types";
import Accordion from "components/common/Accordion/Accordion";
import { useToggle } from "./useToggle";

const TollGateHeader = ({
  accordionHeader,
  accordionText,
  expandedByDefault,
  title,
}) => {
  const [expanded, toggle] = useToggle(expandedByDefault);

  return (
    <>
      <h1 className={`toll_gate_off_ramp__title u-text-center`}>{title}</h1>
      <Accordion
        header={accordionHeader}
        isExpanded={expanded}
        onClick={toggle}
        text={accordionText}
      />
    </>
  );
};

export default TollGateHeader;

TollGateHeader.propTypes = {
  accordionHeader: PropTypes.string.isRequired,
  accordionText: PropTypes.string.isRequired,
  expandedByDefault: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

TollGateHeader.defaultProps = {
  expandedByDefault: true,
};
