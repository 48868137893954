import React from "react";
import PropTypes from "prop-types";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";

function WarningToolTip({ label, message }) {
  return (
    <div className="pc-layout pc-layout--small pc-u-mb">
      <span className="pc-layout__item pc-u-2/7" />
      <div className="pc-layout__item pc-u-5/7">
        <div className="l-spaced l-spaced--left l-spaced--nowrap">
          <p className="centi pc-u-mb0">{label}</p>
          <InfoTooltipIcon
            title={message}
            tooltipProps={{ placement: "auto" }}
          />
        </div>
      </div>
    </div>
  );
}

WarningToolTip.propTypes = {
  label: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default WarningToolTip;
