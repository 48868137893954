import React from "react";
import { isEmpty, flatten } from "underscore";
import TransactionDescription from "../cash/TransactionDescription";
import DateUtils from "../../../libs/pcap/utils/date";
import {
  compositeAlphaAndTimeComparator,
  compositeAmountAndTimeComparator,
  compositeDescriptionAndTimeComparator,
  compositeTagsAndTimeComparator,
  compositeTimeComparator,
} from "./sortingUtils";
import { compareAlpha } from "../../../libs/pcap/table/data/compare";
import InfoTooltipText from "../../common/InfoTooltipText/InfoTooltipText";
import { formatCurrency } from "../../../libs/pcap/utils/format";
import moment from "moment";

const TRANSACTION_STATUS_CANCELLED = "Cancelled";

export default [
  {
    header: "Date",
    accessor: (d) => d.transactionDate,
    formatter: (d) => moment(d).format(DateUtils.DISPLAY_FORMAT),
    className: "pc-transactions-grid-cell--date qa-transaction-time",
    headerClassName: "pc-transactions-grid-cell--date qa-datagrid-date",
    summaryClassName: "pc-transactions-grid-cell--date",
    isSortable: true,
    sortOrder: "desc",
    summaryAccessor: () => "Total",
    comparator: compositeTimeComparator,
    isFilterable: false,
  },
  {
    header: "Description",
    accessor: (d) => d,
    titleAccessor: (d) => d.originalDescription,
    className:
      "pc-transactions-grid-cell--cashflow-account-details-description u-clip-text qa-description pc-u-pr0",
    headerClassName:
      "pc-transactions-grid-cell--cashflow-account-details-description qa-datagrid-description",
    summaryClassName:
      "pc-transactions-grid-cell--cashflow-account-details-description",
    isSortable: true,
    comparator: compositeDescriptionAndTimeComparator,
    filterOptionsComparator: compositeAlphaAndTimeComparator,
    isFilterable: false,
    // eslint-disable-next-line react/display-name
    formatter: (t) => {
      return <TransactionDescription transaction={t} />;
    },
  },
  {
    header: "Category",
    headerPluralityOverrides: {
      // See `ColumnFilterToggle.js`
      // `Category` has more complicated plural rules than just adding an `s` on the end.
      plural: "Categories",
    },
    accessor: (d) => (isEmpty(d.splits) ? d.categoryName : ""),
    className:
      "pc-transactions-grid-cell--category u-clip-text qa-category-name",
    headerClassName:
      "pc-transactions-grid-cell--category qa-data-grid-category",
    summaryClassName: "pc-transactions-grid-cell--category",
    isSortable: true,
    comparator: compositeAlphaAndTimeComparator,
    filterAccessor: (d) =>
      d.splits ? d.splits.map((s) => s.categoryName) : [d.categoryName],
    filterOptionsComparator: compareAlpha,
    isFilterable: true,
  },
  {
    header: "Tags",
    headerPluralityOverrides: {
      // See `ColumnFilterToggle.js`
      // Tags is already plural in the header, so we need the singular.
      singular: "Tag",
      // But after that, we can pluralize in the default way by adding `s`, so no need for a plural.
    },
    accessor: (d) => d,
    className: "pc-transactions-grid-cell--tags u-clip-text",
    headerClassName: "pc-transactions-grid-cell--tags qa-datagrid-tags",
    summaryClassName: "pc-transactions-grid-cell--tags",
    isSortable: true,
    comparator: compositeTagsAndTimeComparator,
    formatter: function tags(transaction) {
      //If the transaction is duplicate, then we should show duplicate here instead of tags
      if (transaction.isDuplicate) {
        return <span>(Duplicate)</span>;
      }

      if (!isEmpty(transaction.splits)) {
        return null;
      }

      if (!isEmpty(transaction.selectedTagNames)) {
        let tagsText = transaction.selectedTagNames[0];
        if (transaction.selectedTagNames.length > 1) {
          // eslint-disable-next-line no-magic-numbers
          tagsText = `${tagsText.slice(0, 4)}...,(${
            transaction.selectedTagNames.length
          })`;
        }
        return (
          <InfoTooltipText
            text={tagsText}
            className="qa-tag-name"
            tooltipContent={transaction.selectedTagNames.join(", ")}
            tooltipProps={{ placement: "left" }}
          ></InfoTooltipText>
        );
      }
      return "";
    },
    isFilterable: true,
    filterAccessor: (d) =>
      d.splits
        ? flatten(d.splits.map((s) => s.selectedTagNames))
        : d.selectedTagNames,
    // The list of tags displayed in the filter dropdown needs simpler sorting than the data column itself.
    filterOptionsComparator: compareAlpha,
  },
  {
    header: "Amount",
    accessor: (transaction) => transaction,
    className:
      "pc-transactions-grid-cell--amount pc-transactions-grid-cell--amount-parent u-text-right qa-value tabular-numbers",
    headerClassName:
      "pc-transactions-grid-cell--amount table__column--right qa-datagrid-amount",
    summaryClassName:
      "pc-transactions-grid-cell--amount u-text-right qa-transactions-total-amount",
    isSortable: true,
    comparator: compositeAmountAndTimeComparator,
    formatter: function amount(transaction) {
      const value = transaction.isCredit
        ? transaction.amount
        : transaction.amount * -1;
      return (
        <span
          title={transaction.isCredit ? "Credit" : "Debit"}
          className={value > 0 ? "positive" : ""}
        >
          {formatCurrency(value)}
        </span>
      );
    },
    summaryFormatter: formatCurrency,
    summaryAccessor: (data) => {
      return data.reduce((total, dataRow) => {
        if (dataRow.transactionStatus === TRANSACTION_STATUS_CANCELLED) {
          return total;
        }
        return dataRow.isCredit
          ? total + dataRow.amount
          : total - dataRow.amount;
      }, 0);
    },
    isFilterable: false,
  },
];
