import React from "react";
import PropTypes from "prop-types";
import TransferFundsWizard from "empower/components/TransferFunds/TransferFundsWizard";
import Services from "services";
import { findErrorByCode } from "libs/pcap/utils/response";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import MicroDepositInitiateContainer from "components/transferFunds/MicroDepositInitiateContainer";
import MicroDepositVerifyContainer from "components/transferFunds/MicroDepositVerifyContainer";
import AccountNumberFormContainer from "components/transferFunds/AccountNumberFormContainer";
import IncompleteAccount, {
  ManualAccount,
  AggregationError,
  MissingAccountType,
} from "components/transferFunds/IncompleteAccount";
import AppOverlay from "appOverlay";
import Overlay from "libs/overlay/overlay";
import {
  ACTION_VERIFY_MICRO_DEPOSIT,
  ACTION_INITIATE_MICRO_DEPOSIT,
  ACTION_REQUIRE_ACCOUNT_NUMBER,
  ACTION_FIX_AGGREGATION_ERROR,
  ACTION_AGGREGATION_IN_PROGRESS,
  ACTION_AGGREGATE_ACCOUNT,
  ACTION_CLASSIFY_ACCOUNT,
  ACTION_WAIT_IAV,
  ACTION_INITIATE_IAV,
  STATE_PENDING,
  STATE_BLOCKED,
} from "utils/account";
import subscribeToAccountsAndStateForTransfer from "components/transferFunds/utils/subscriptions";
import { noop, isEqual, isEmpty, first } from "underscore";
import memoizeOne from "memoize-one";
import { promisify } from "utils/service";
import { fullName } from "libs/pcap/utils/person";
import { trackEvent } from "components/common/ComponentAnalytics";
import MicroDepositInitiatedStatus from "components/transferFunds/TransferFunds/MicroDepositInitiatedStatus";
import { getSource } from "components/common/attributionStore";
import deepCopy from "deep-copy";
import { isOnUs, isOnUsBank, isOnUsInvestment } from "utils/account";
import toServer from "accessors/transfer/submitTransfer/mappers/toServer";
import {
  TRANSFER_TYPE_CONTRIBUTE,
  TRANSFER_TYPE_INTERNAL,
  TRANSFER_TYPE_WITHDRAW,
} from "components/transferFunds/utils/constants";
import LoadingOverlay from "components/common/LoadingOverlay";
import Loading from "components/common/loading/Loading";
import { mapByAccountType } from "components/transferFunds/utils/contributionLimits";
import { getByAccountIds } from "components/transferFunds/utils/transferInstructions";
import {
  mapByUserAccountId,
  getAccountOwnerAge,
} from "components/transferFunds/utils/personAccounts";
import { fetchPersonAccounts } from "accessors/account/accessor";
import { getPersons } from "accessors/person/accessor";
import { getKeyByAccountType } from "components/transferFunds/utils/contributionLimits";
import { storeTransferRequest } from "../utils/TransferRequestManager/transferRequestManager";
import {
  isBetweenWarningPeriod,
  isBeforeWarningPeriod,
  tradingBlackoutPeriodFormat,
  remainingTimeToStartWarningPeriod,
  isBetweenSuspensionPeriod,
  isBeforeSuspendPeriod,
  remainingTimeToStartSuspendPeriod,
  remainingTimeToEndSuspendPeriod,
} from "../../common/modals/TradingBlackout/utils";
import EditAccountContainer from "components/common/EditAccount/EditAccountContainer";
import { getStyleGuideUrl } from "empower/components/manageInvestmentFlow/utils";
import { externalAccountsForPremierWithdrawalsFilter } from "components/transferFunds/utils/accountFilters";

const ERROR_CODE_SELECT_DIFFERENT_ACCOUNT = 118;
const ERROR_CODE_SUBMIT_MICRO_DEPOSIT = 119;
const ERROR_CODE_REENTER_ACCOUNT_NUMBER = 120;
const ERROR_CODE_ACTION_MICRO_DEPOSIT_INITIATED = 122;
const PERSHING_TYPE = "PERSHING";
const PCB_TYPE = "PCB";

const SECOND = 1000;
const CURRENT_YEAR = new Date().getFullYear();
const TRANSFER_FUNDS = "Transfer Funds";

const findAccount = (userAccountId, accounts = []) => {
  if (!userAccountId) {
    return;
  }

  return accounts.find((a) => a.userAccountId === userAccountId);
};

const findSourceAccount = memoizeOne(findAccount);
const findTargetAccount = memoizeOne(findAccount);

const filterAccounts = (accounts, accountsFilter) => {
  if (isEmpty(accounts)) {
    return [];
  }

  return accounts.filter((account) => accountsFilter(account));
};

const filterSourceAccounts = memoizeOne(filterAccounts);
const filterTargetAccounts = memoizeOne(filterAccounts);

const determineTargetAccountsFilter = (
  sourceAccount,
  transferType,
  targetAccountsFilter
) =>
  sourceAccount?.productCategory === "EMPOWER_PREMIER" &&
  transferType === TRANSFER_TYPE_WITHDRAW
    ? externalAccountsForPremierWithdrawalsFilter
    : targetAccountsFilter;

function getAccountTransferStateType(account) {
  if (account.isOnUsBank) {
    return PCB_TYPE;
  }
  if (account.isOnUs) {
    return PERSHING_TYPE;
  }
}

const getContributionYearsByAccount = memoizeOne(
  (contributionLimits, account) => {
    return contributionLimits.get(
      getKeyByAccountType({
        group: account.accountTypeGroup,
        type: account.accountTypeNew,
        subType: account.accountTypeSubtype,
      })
    );
  }
);

const getTransferStateType = memoizeOne(
  (sourceAccountId, targetAccountId, accounts = []) => {
    if (!sourceAccountId && !targetAccountId) {
      return;
    }

    const sourceAccount = findSourceAccount(sourceAccountId, accounts);
    const targetAccount = findTargetAccount(targetAccountId, accounts);

    const isOnUsSource = isOnUs(sourceAccount);
    const isOnUsTarget = isOnUs(targetAccount);

    if (isOnUsSource && isOnUsTarget) {
      if (isOnUsInvestment(sourceAccount)) {
        return getAccountTransferStateType(sourceAccount);
      }
      return getAccountTransferStateType(targetAccount);
    }

    if (isOnUsSource) {
      return getAccountTransferStateType(sourceAccount);
    }

    if (isOnUsTarget) {
      return getAccountTransferStateType(targetAccount);
    }
  }
);

const trackSubmitTransferErrors = (errors, source) => {
  trackEvent(TRANSFER_FUNDS, "Transfer Funds Rejected", {
    error: first(errors),
    source,
  });
};

const trackSubmitTransfer = (source) => {
  trackEvent(TRANSFER_FUNDS, "Transfer Funds Submitted", { source });
};

// default way to handle submitTransfer errors
// override default with this.props.onSubmitErrors
const parseSubmitTransferErrors = (errorResponse) => {
  const reenterAccountNumberError = findErrorByCode(
    errorResponse,
    ERROR_CODE_REENTER_ACCOUNT_NUMBER
  );
  const submitMicroDepositError = findErrorByCode(
    errorResponse,
    ERROR_CODE_SUBMIT_MICRO_DEPOSIT
  );
  const selectDifferentAccountError = findErrorByCode(
    errorResponse,
    ERROR_CODE_SELECT_DIFFERENT_ACCOUNT
  );
  const showMicroDepositInitiatedStatus = findErrorByCode(
    errorResponse,
    ERROR_CODE_ACTION_MICRO_DEPOSIT_INITIATED
  );

  return {
    hasSelectDifferentAccountError: Boolean(selectDifferentAccountError),
    showMicroDepositInitiatedStatus: Boolean(showMicroDepositInitiatedStatus),
    reenterAccountNumberError: reenterAccountNumberError
      ? [reenterAccountNumberError.message]
      : undefined,
    submitMicroDepositError: submitMicroDepositError
      ? [submitMicroDepositError.message]
      : undefined,
    errors:
      reenterAccountNumberError || submitMicroDepositError
        ? []
        : errorResponse.errors,
  };
};

function checkStyleGuideUrl(getStyleGuide) {
  if (window.styleGuideUrl) {
    getStyleGuide();
  } else {
    promisify(Services.Profile.getUIPreferences)().then((response) => {
      window.styleGuideUrl = response.participantPreferences?.styleGuideUrl;
      getStyleGuide();
    });
  }
}

export default class TransferFundsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      initializing: true,
      isIAVFailed: false,
      personFullName: "",
      renderDeferredComponent: false,
      errors: [],
      accounts: [],
      model: props.model,
      pageIndex: 0,
      isTradingSuspended: false,
      shouldShowTradingBlackoutWarningModal: false,
      shouldShowTradingBlackoutWarningMsg: false,
      showEditAccountContainer: false,
      styleGuide: {},
    };

    this.iavOverlay = new Overlay({
      target: document.body,
      className:
        "pc-overlay--transfer-funds-iav js-transfer-funds-iav-overlay qa-loading-overlay--transfer-iav",
      messages: [
        {
          message:
            "We're verifying your account. This may take up to 2 minutes.",
        },
      ],
    });
    this.onError = this.onError.bind(this);
    this.handleOnNextPage = this.handleOnNextPage.bind(this);
    this.handleOnTransferFund = this.handleOnTransferFund.bind(this);
    this.onAccountsServerChange = this.onAccountsServerChange.bind(this);
    this.handleSourceAccountChange = this.handleSourceAccountChange.bind(this);
    this.handleTargetAccountChange = this.handleTargetAccountChange.bind(this);
    this.handleChooseDifferentAccount =
      this.handleChooseDifferentAccount.bind(this);
    this.handleMicroDepositInitiateDone =
      this.handleMicroDepositInitiateDone.bind(this);
    this.handleMicroDepositInitiateCancel =
      this.handleMicroDepositInitiateCancel.bind(this);
    this.handleBackToDashboard = this.handleBackToDashboard.bind(this);
    this.handleMicroDepositVerifySubmit =
      this.handleMicroDepositVerifySubmit.bind(this);
    this.handleMicroDepositVerifyCancel =
      this.handleMicroDepositVerifyCancel.bind(this);
    this.handleEditAccountNumberSubmit =
      this.handleEditAccountNumberSubmit.bind(this);
    this.handleEditAccountNumberCancel =
      this.handleEditAccountNumberCancel.bind(this);
    this.handleAggregateAccountCancel =
      this.handleAggregateAccountCancel.bind(this);
    this.handleProvideAccountDetails =
      this.handleProvideAccountDetails.bind(this);
    this.trackWaitIavAbandoned = this.trackWaitIavAbandoned.bind(this);
    this.trackWaitAggregationAbandoned =
      this.trackWaitAggregationAbandoned.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleBlackoutModalClose = this.handleBlackoutModalClose.bind(this);
    this.setTimerForTradeSuspendPeriodEnd =
      this.setTimerForTradeSuspendPeriodEnd.bind(this);
    this.handleOnSaveEditAccount = this.handleOnSaveEditAccount.bind(this);
    this.timerForBlackoutWindow = null;
    this.timerForSuspendWindow = null;
    this.timerForTradeResume = null;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.model, prevState.prevModel)) {
      return {
        model: Object.assign({}, nextProps.model),
        prevModel: Object.assign({}, nextProps.model),
      };
    }

    return null;
  }

  // TODO Need to update Cognitive Complexity as new ticket, outside of code migration
  // eslint-disable-next-line sonarjs/cognitive-complexity
  componentDidMount() {
    this.subscribeToAccountsAndStateForTransfer =
      subscribeToAccountsAndStateForTransfer();
    this.subscribeToAccountsAndStateForTransfer.on(
      "change",
      this.onAccountsServerChange
    );
    this.getAccountsCancelablePromise = makeCancelablePromise(
      this.subscribeToAccountsAndStateForTransfer.promise
    );

    const serviceArray = [
      this.getAccountsCancelablePromise.promise,
      this.props.serviceGetContributionLimits(),
      this.props.serviceGetPersonAccounts(["PRIMARY"]),
      this.props.serviceGetPersons(),
      this.props.serviceGetTransferInstructions(),
    ];
    if (
      this.props.transferType === TRANSFER_TYPE_WITHDRAW ||
      this.props.transferType === TRANSFER_TYPE_INTERNAL
    ) {
      serviceArray.push(this.props.serviceGetTradingBlackoutPeriod());
    }

    Promise.all(serviceArray)
      .then(
        ([
          accounts,
          contributionLimits,
          personAccounts,
          household,
          recurringTransferInstructions,
          tradingBlackoutRes,
        ]) => {
          personAccounts = mapByUserAccountId(personAccounts);
          // advisor app doesn't expose `personId`
          const loggedInPerson = window.personId
            ? household.find((p) => p.id === window.personId)
            : household.find((p) => p.relationship === "SELF");

          const { model } = this.state;
          const { sourceAccountId, targetAccountId } = model;
          const targetAccount = findTargetAccount(targetAccountId, accounts);

          contributionLimits = mapByAccountType(contributionLimits);

          let contributionYears;
          if (contributionLimits && targetAccount && isOnUs(targetAccount)) {
            contributionYears = getContributionYearsByAccount(
              contributionLimits,
              targetAccount
            );
            if (
              contributionYears?.limits &&
              contributionYears.limits.length === 1
            ) {
              model.contributionYear = contributionYears.limits[0].year;
            }
          }

          let tradeWarning = false,
            tradeSuspend = false;
          if (tradingBlackoutRes) {
            const tradingBlackoutPeriod =
              tradingBlackoutPeriodFormat(tradingBlackoutRes);
            // Will be true, if the current time is between the Trade Warning Period
            tradeWarning = isBetweenWarningPeriod(tradingBlackoutPeriod);
            // Will be true, if the current time is between the Trade Suspend Period
            tradeSuspend = isBetweenSuspensionPeriod(tradingBlackoutPeriod);
            // Will be true, if the current time is before the Warning Period
            if (isBeforeWarningPeriod(tradingBlackoutPeriod)) {
              // Timeout will be set for the remaining Period, will help to popup the modal & message while the warning time starts
              this.timerForBlackoutWindow = setTimeout(() => {
                this.setState({
                  shouldShowTradingBlackoutWarningModal: true,
                  shouldShowTradingBlackoutWarningMsg: true,
                });
              }, remainingTimeToStartWarningPeriod(tradingBlackoutPeriod));
            }
            //Set timer if the current time is between the Suspend period
            if (tradeSuspend) {
              this.setTimerForTradeSuspendPeriodEnd(tradingBlackoutPeriod);
            }
            // Will be true, if the current time is before the Suspend Start Period
            if (isBeforeSuspendPeriod(tradingBlackoutPeriod)) {
              // Timeout will be set for the remaining Period, will help to disable Submit button while the suspend time starts
              this.timerForSuspendWindow = setTimeout(() => {
                this.setState({
                  isTradingSuspended: true,
                });
              }, remainingTimeToStartSuspendPeriod(tradingBlackoutPeriod));
              this.setTimerForTradeSuspendPeriodEnd(tradingBlackoutPeriod);
            }
          }

          this.setState({
            household,
            accounts,
            personAccounts,
            personFullName: fullName(loggedInPerson),
            targetAccountOwnerAge: getAccountOwnerAge(
              targetAccountId,
              personAccounts,
              household
            ),
            contributionYears,
            contributionLimits,
            // use props to set to false for edit RT flow
            hasRecurringTransferEstablished:
              this.props.hasRecurringTransferEstablished ??
              Boolean(
                getByAccountIds(
                  sourceAccountId,
                  targetAccountId,
                  recurringTransferInstructions
                )
              ),
            recurringTransferInstructions,
            loading: false,
            initializing: false,
            model,
            shouldShowTradingBlackoutWarningModal: tradeWarning,
            shouldShowTradingBlackoutWarningMsg: tradeWarning,
            isTradingSuspended: tradeSuspend,
          });
        }
      )
      .catch((errors) => {
        this.setState({ initializing: false });
        if (this.getAccountsCancelablePromise.isCanceled()) {
          return;
        }
        this.onError(errors);
      });

    const getStyleGuide = () => {
      const styleGuideUrl = getStyleGuideUrl("distribution");

      this.getStyleGuidePromise = makeCancelablePromise(styleGuideUrl);

      this.getStyleGuidePromise.promise
        .then((res) => {
          this.setState({
            styleGuide: res,
          });
        })
        .catch(() => {
          this.setState({
            errors: ["Style guide failed to fetch."],
          });
        });
    };

    checkStyleGuideUrl(getStyleGuide);
  }

  // TODO Need to update Cognitive Complexity as new ticket, outside of code migration
  // eslint-disable-next-line sonarjs/cognitive-complexity
  componentDidUpdate(prevProps, prevState) {
    const { sourceAccountsFilter, targetAccountsFilter, transferType } =
      this.props;
    const { model, accounts } = this.state;
    const { model: prevModel, accounts: prevAccounts } = prevState;

    const hasSourceChanged =
      prevModel.sourceAccountId !== model.sourceAccountId;
    const hasTargetChanged =
      prevModel.targetAccountId !== model.targetAccountId;

    // ensure if the selected account combination is valid
    if (
      hasSourceChanged ||
      hasTargetChanged ||
      !isEqual(prevAccounts, accounts) ||
      prevProps.sourceAccountsFilter !== sourceAccountsFilter ||
      prevProps.targetAccountsFilter !== targetAccountsFilter
    ) {
      const { sourceAccountId, targetAccountId } =
        this.ensureValidAccountSelection({
          sourceAccountId: model.sourceAccountId,
          targetAccountId: model.targetAccountId,
          accounts,
          sourceAccountsFilter,
          targetAccountsFilter,
          transferType,
          hasSourceChanged,
          hasTargetChanged,
        });

      if (
        sourceAccountId !== model.sourceAccountId ||
        targetAccountId !== model.targetAccountId
      ) {
        model.sourceAccountId = sourceAccountId;
        model.targetAccountId = targetAccountId;
        this.setState({
          model,
        });
      }
    }

    // clear the account previously selected for a deferred action if the selection changes
    if (
      prevModel.sourceAccountId !== model.sourceAccountId ||
      prevModel.targetAccountId !== model.targetAccountId
    ) {
      this.setState({
        accountSelectedForUserAction: undefined,
        modeInitiateMicroDeposit: false,
        modeVerifyMicroDeposit: false,
        modeDeferredEditAccountNumber: false,
        modeAggregateManualAccount: false,
        modeFixAggregationError: false,
        modeFixAccountClassification: false,
      });
    }

    if (prevModel.targetAccountId !== model.targetAccountId) {
      const { contributionLimits, accounts } = this.state;

      const targetAccount = findTargetAccount(model.targetAccountId, accounts);

      let contributionYears;
      if (contributionLimits && targetAccount && isOnUs(targetAccount)) {
        contributionYears = getContributionYearsByAccount(
          contributionLimits,
          targetAccount
        );
        if (contributionYears?.limits?.length === 1) {
          model.contributionYear = contributionYears.limits[0].year;
        }
      }

      this.setState({
        contributionYears,
        targetAccountOwnerAge: getAccountOwnerAge(
          model.targetAccountId,
          this.state.personAccounts,
          this.state.household
        ),
        model,
      });
    }

    if (
      prevModel.sourceAccountId !== model.sourceAccountId ||
      prevModel.targetAccountId !== model.targetAccountId
    ) {
      this.setState({
        hasRecurringTransferEstablished: Boolean(
          getByAccountIds(
            model.sourceAccountId,
            model.targetAccountId,
            this.state.recurringTransferInstructions
          )
        ),
      });
    }

    // trigger an action based on the state for transfer
    if (
      prevModel.sourceAccountId !== model.sourceAccountId ||
      prevModel.targetAccountId !== model.targetAccountId ||
      !isEqual(prevAccounts, accounts) ||
      prevProps.sourceAccountsFilter !== sourceAccountsFilter ||
      prevProps.targetAccountsFilter !== targetAccountsFilter
    ) {
      this.tryTriggerAction(prevProps, prevState);
    }
  }

  handleBlackoutModalClose() {
    this.setState({ shouldShowTradingBlackoutWarningModal: false });
  }

  setTimerForTradeSuspendPeriodEnd(tradingBlackoutPeriod) {
    // Timeout will be set for the remaining Period, will help to enable Submit button & hide warning message while the suspend time ends
    this.timerForTradeResume = setTimeout(() => {
      this.setState({
        shouldShowTradingBlackoutWarningMsg: false,
        isTradingSuspended: false,
      });
    }, remainingTimeToEndSuspendPeriod(tradingBlackoutPeriod));
  }

  triggerAction(account, { state, action }) {
    // this removes the loader for aggregation error
    AppOverlay.hide();

    this.iavOverlay.hide();
    // TODO Check if "aggErrorUserSiteId" and "onAccountChanged" are deprecated
    // We want to trigger sidebar close only when there is an aggregation error in
    // previously selected account and the current account is different from previously selected account
    if (
      this.aggErrorUserSiteId &&
      this.aggErrorUserSiteId !== account.userSiteId
    ) {
      this.props.onAccountChanged();
      this.aggErrorUserSiteId = null;
    }

    const { firmName } = account;
    if (this.waitIavStartTime && action !== ACTION_WAIT_IAV) {
      this.trackWaitIavProcessed(firmName, state, action);
    }
    if (
      this.waitAggregationStartTime &&
      action !== ACTION_AGGREGATION_IN_PROGRESS
    ) {
      this.trackWaitAggregationProcessed(firmName, state, action);
    }

    // Other states needs to be implemented
    if (STATE_PENDING) {
      switch (action) {
        case ACTION_WAIT_IAV:
          this.trackWaitIavStarted(firmName);
          this.iavOverlay.show();
          break;
        case ACTION_INITIATE_IAV:
          this.triggerIav(account);
          break;
        case ACTION_INITIATE_MICRO_DEPOSIT:
          this.triggerMicroDepositInitiation(account);
          break;
        case ACTION_VERIFY_MICRO_DEPOSIT:
          this.triggerMicroDepositVerification(account);
          break;
        case ACTION_REQUIRE_ACCOUNT_NUMBER:
          // this.triggerEnterAccountNumber(account, true);
          this.triggerEnterAccountNumber(account);
          break;
        case ACTION_AGGREGATE_ACCOUNT:
          this.triggerAggregateManualAccount(account);
          break;
        case ACTION_CLASSIFY_ACCOUNT:
          this.triggerFixAccountClassification(account);
          break;
        case ACTION_FIX_AGGREGATION_ERROR:
          trackEvent(TRANSFER_FUNDS, "View Aggregation Error", {
            // eslint-disable-next-line camelcase
            fi_name: firmName,
            source: getSource(),
          });
          this.aggErrorUserSiteId = account.userSiteId;
          this.props.onSelectAccountWithAggregationError(account.userSiteId);
          this.triggerAggregateAccount(account);
          break;
        case ACTION_AGGREGATION_IN_PROGRESS:
          this.trackWaitAggregationStarted(firmName);
          AppOverlay.show();
          break;
        default:
      }
    }
  }

  findPrevExternalAccount(
    prevAccounts,
    prevSourceAccountsFilter,
    prevSourceAccountId,
    prevTargetAccountsFilter,
    prevTargetAccountId
  ) {
    const prevSourceAccounts = filterAccounts(
      prevAccounts,
      prevSourceAccountsFilter
    );
    const prevSourceAccount = findAccount(
      prevSourceAccountId,
      prevSourceAccounts
    );

    const prevTargetAccounts = filterAccounts(
      prevAccounts,
      prevTargetAccountsFilter
    );
    const prevTargetAccount = findAccount(
      prevTargetAccountId,
      prevTargetAccounts
    );
    return isOnUs(prevSourceAccount) ? prevTargetAccount : prevSourceAccount;
  }

  /**
   * Sets isIAVFailed into the state.
   * 1. The selected account state changes from IAV -> Initiate Micro Deposit
   * 2. The selected account state changes from IAV -> Require Account Number
   *
   * @param {String} action - the current account action
   * @param {Object} prevState - the previous state
   * @param {String} prevAccountAction - the previous account action
   */
  setIsIAVFailed(action, prevState, prevAccountAction) {
    const isIAVFailed =
      (action === ACTION_INITIATE_MICRO_DEPOSIT ||
        action === ACTION_REQUIRE_ACCOUNT_NUMBER) &&
      prevAccountAction === ACTION_WAIT_IAV;

    if (prevState.isIAVFailed !== isIAVFailed) {
      this.setState({ isIAVFailed });
    }
  }

  /**
   * Triggers action if:
   * 1. Different account is selected
   * 2. The state changes on the selected account
   * @param {Object} prevProps previous props
   * @param {Object} prevState previous state
   */
  // TODO Need to update Cognitive Complexity as new ticket, outside of code migration
  // eslint-disable-next-line sonarjs/cognitive-complexity
  tryTriggerAction(prevProps, prevState) {
    const { model, accounts } = this.state;
    const { sourceAccountId, targetAccountId } = model;

    // The transfer state type and the action on the account can only be determined when both accounts are selected.
    if (!sourceAccountId || !targetAccountId) {
      return;
    }

    const stateType = getTransferStateType(
      sourceAccountId,
      targetAccountId,
      accounts
    );

    // The transfer state type is not determined yet. Unable to detect the action.
    if (!stateType) {
      return;
    }

    const { sourceAccountsFilter, targetAccountsFilter, transferType } =
      this.props;

    const sourceAccounts = filterSourceAccounts(accounts, sourceAccountsFilter);
    const sourceAccount = findSourceAccount(sourceAccountId, sourceAccounts);

    const currentTargetAccountsFilter = determineTargetAccountsFilter(
      sourceAccount,
      transferType,
      targetAccountsFilter
    );
    const targetAccounts = filterTargetAccounts(
      accounts,
      currentTargetAccountsFilter
    );
    const targetAccount = findTargetAccount(targetAccountId, targetAccounts);

    if (!sourceAccount || !targetAccount) {
      return;
    }

    const externalAccount = isOnUs(sourceAccount)
      ? targetAccount
      : sourceAccount;
    // The action is supposed to be performed on an external account to complete the verification.
    // Return early if both selected accounts are onus.
    if (!externalAccount) {
      return;
    }

    const { state, action } = externalAccount.stateForTransfer[stateType];

    const { model: prevModel, accounts: prevAccounts } = prevState;
    const {
      sourceAccountId: prevSourceAccountId,
      targetAccountId: prevTargetAccountId,
    } = prevModel;
    if (
      sourceAccountId !== prevSourceAccountId ||
      targetAccountId !== prevTargetAccountId
    ) {
      this.triggerAction(externalAccount, { state, action });
      return;
    }

    const {
      sourceAccountsFilter: prevSourceAccountsFilter,
      targetAccountsFilter: prevTargetAccountsFilter,
    } = prevProps;

    let prevAccountState, prevAccountAction;

    if (prevAccounts && prevSourceAccountsFilter && prevTargetAccountsFilter) {
      const prevStateType = getTransferStateType(
        prevSourceAccountId,
        prevTargetAccountId,
        prevAccounts
      );

      const prevExternalAccount = this.findPrevExternalAccount(
        prevAccounts,
        prevSourceAccountsFilter,
        prevSourceAccountId,
        prevTargetAccountsFilter,
        prevTargetAccountId
      );
      if (prevExternalAccount) {
        prevAccountState =
          prevExternalAccount.stateForTransfer[prevStateType].state;
        prevAccountAction =
          prevExternalAccount.stateForTransfer[prevStateType].action;
      }
    }

    this.setIsIAVFailed(action, prevState, prevAccountAction);

    if (
      prevAccountAction !== ACTION_INITIATE_MICRO_DEPOSIT &&
      (state !== prevAccountState || action !== prevAccountAction)
    ) {
      this.triggerAction(externalAccount, { state, action });
    }
  }

  /**
   * This method ensures that the current selection is valid based on the current state of the selected accounts.
   *
   * @param {Object} params The parameters object
   * @param {Number} params.sourceAccountId The source account id
   * @param {Number} params.targetAccountId The target account id
   * @param {Array} params.accounts The complete list of accounts
   * @param {Function} params.sourceAccountsFilter The filter function to be applied on the source account dropdown
   * @param {Function} params.targetAccountsFilter The filter function to be applied on the target account dropdown
   * @param {Number} params.transferType The type of transfer
   * @returns {Object} selection The valid selection
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  ensureValidAccountSelection({
    sourceAccountId,
    targetAccountId,
    accounts,
    sourceAccountsFilter,
    targetAccountsFilter,
    transferType,
    hasSourceChanged,
    hasTargetChanged,
  }) {
    let sourceAccounts = filterSourceAccounts(accounts, sourceAccountsFilter);

    // Reset the selected account from the model if:
    // 1. The account disappears from the accounts list
    // 2. The account transfer type becomes blocked
    const sourceAccount = findSourceAccount(sourceAccountId, sourceAccounts);
    const currentTargetAccountsFilter = determineTargetAccountsFilter(
      sourceAccount,
      transferType,
      targetAccountsFilter
    );
    let targetAccounts = filterTargetAccounts(
      accounts,
      currentTargetAccountsFilter
    );
    const targetAccount = findTargetAccount(targetAccountId, targetAccounts);

    if (!sourceAccount) {
      sourceAccountId = undefined;
    }

    if (!targetAccount) {
      targetAccountId = undefined;
    }

    // Both `sourceAccount` and `targetAccount` may be undefined as they can disappear from the lists.
    const stateType = getTransferStateType(
      sourceAccount?.userAccountId,
      targetAccount?.userAccountId,
      accounts
    );

    const isInternalOnlyCash =
      isOnUsBank(sourceAccount) && isOnUsBank(targetAccount);
    const isInternalOnlyEPIA =
      sourceAccount?.productCategory === "EMPOWER_PREMIER" &&
      targetAccount?.productCategory === "EMPOWER_PREMIER";
    const isInternalOnlyPS =
      sourceAccount?.productCategory === "EMPOWER_PERSONAL_STRATEGY" &&
      targetAccount?.productCategory === "EMPOWER_PERSONAL_STRATEGY";
    const isInvalidAccountCombination =
      isInternalOnlyCash || isInternalOnlyEPIA || isInternalOnlyPS;

    if (stateType) {
      if (
        transferType === TRANSFER_TYPE_CONTRIBUTE &&
        sourceAccount?.stateForTransfer[stateType].state === STATE_BLOCKED
      ) {
        sourceAccountId = undefined;
      }

      if (
        transferType === TRANSFER_TYPE_WITHDRAW &&
        targetAccount?.stateForTransfer[stateType].state === STATE_BLOCKED
      ) {
        targetAccountId = undefined;
      }

      if (transferType === TRANSFER_TYPE_INTERNAL) {
        if (
          hasTargetChanged &&
          (sourceAccount?.stateForTransfer[stateType].state === STATE_BLOCKED ||
            isInvalidAccountCombination)
        ) {
          sourceAccountId = undefined;
        }

        if (
          hasSourceChanged &&
          (targetAccount?.stateForTransfer[stateType].state === STATE_BLOCKED ||
            isInvalidAccountCombination)
        ) {
          targetAccountId = undefined;
        }
      }
    }

    return { sourceAccountId, targetAccountId };
  }

  componentWillUnmount() {
    if (this.getAccountsCancelablePromise) {
      this.getAccountsCancelablePromise.cancel();
    }

    if (this.subscribeToAccountsAndStateForTransfer) {
      this.subscribeToAccountsAndStateForTransfer.unwatch();
    }

    if (this.subscribeToAccountsAndStateForTransfer) {
      this.subscribeToAccountsAndStateForTransfer.off("change");
    }

    if (this.timerForBlackoutWindow) {
      clearTimeout(this.timerForBlackoutWindow);
    }

    if (this.timerForSuspendWindow) {
      clearTimeout(this.timerForSuspendWindow);
    }

    if (this.timerForTradeResume) {
      clearTimeout(this.timerForTradeResume);
    }

    this.trackWaitIavAbandoned();
    this.trackWaitAggregationAbandoned();
    this.iavOverlay.remove();
  }

  trackWaitIavStarted(firmName) {
    trackEvent(TRANSFER_FUNDS, "IAV Started", {
      // eslint-disable-next-line camelcase
      fi_name: firmName,
      source: getSource(),
    });
    this.waitIavStartTime = Date.now();
    window.addEventListener("beforeunload", this.trackWaitIavAbandoned);
  }

  trackWaitIavAbandoned() {
    if (this.waitIavStartTime) {
      const waitIavElapsedTime = (Date.now() - this.waitIavStartTime) / SECOND;
      trackEvent(TRANSFER_FUNDS, "IAV Abandoned", {
        // eslint-disable-next-line camelcase
        process_time_seconds: waitIavElapsedTime,
        source: getSource(),
      });
    }
    window.removeEventListener("beforeunload", this.trackWaitIavAbandoned);
  }

  trackWaitIavProcessed(firmName, state, action) {
    const waitIavElapsedTime = (Date.now() - this.waitIavStartTime) / SECOND;
    this.waitIavStartTime = null;
    trackEvent(TRANSFER_FUNDS, "IAV Processed", {
      // eslint-disable-next-line camelcase
      process_time_seconds: waitIavElapsedTime,
      // eslint-disable-next-line camelcase
      fi_name: firmName,
      state: state,
      action: action,
      source: getSource(),
    });
    window.removeEventListener("beforeunload", this.trackWaitIavAbandoned);
  }

  trackWaitAggregationStarted(firmName) {
    trackEvent(TRANSFER_FUNDS, "Aggregation Started", {
      // eslint-disable-next-line camelcase
      fi_name: firmName,
      source: getSource(),
    });
    this.waitAggregationStartTime = Date.now();
    window.addEventListener("beforeunload", this.trackWaitAggregationAbandoned);
  }

  trackWaitAggregationAbandoned() {
    if (this.waitAggregationStartTime) {
      const waitAggregationElapsedTime =
        (Date.now() - this.waitAggregationStartTime) / SECOND;
      trackEvent(TRANSFER_FUNDS, "Aggregation Abandoned", {
        // eslint-disable-next-line camelcase
        process_time_seconds: waitAggregationElapsedTime,
        source: getSource(),
      });
    }
    window.removeEventListener(
      "beforeunload",
      this.trackWaitAggregationAbandoned
    );
  }

  trackWaitAggregationProcessed(firmName, state, action) {
    const waitAggregationElapsedTime =
      (Date.now() - this.waitAggregationStartTime) / SECOND;
    this.waitAggregationStartTime = null;
    trackEvent(TRANSFER_FUNDS, "Aggregation Processed", {
      // eslint-disable-next-line camelcase
      process_time_seconds: waitAggregationElapsedTime,
      // eslint-disable-next-line camelcase
      fi_name: firmName,
      state: state,
      action: action,
      source: getSource(),
    });
    window.removeEventListener(
      "beforeunload",
      this.trackWaitAggregationAbandoned
    );
  }

  triggerIav({ userAccountId }) {
    this.props
      .serviceInitiateIav({
        userAccountId,
      })
      .then((rs) => {
        const accountStateDetails = rs[0].details;
        const accountState = accountStateDetails
          ? accountStateDetails.reduce((result, cur) => {
              result[cur.type] = {
                state: cur.state,
              };
              if (cur.action) {
                result[cur.type].action = cur.action;
              }
              return result;
            }, {})
          : {
              PCB: { state: STATE_BLOCKED },
              PERSHING: { state: STATE_BLOCKED },
            };

        // The updated `WAIT_IAV` status will be handled by `triggerAction` method.
        const accounts = deepCopy(this.state.accounts);
        const account = accounts.find(
          (a) => a.userAccountId === rs[0].userAccountId
        );
        if (account) {
          Object.assign(account, { stateForTransfer: accountState });
          this.setState({ accounts });
        }
      }, this.onError);
  }

  triggerMicroDepositInitiation(selectedAccount) {
    const newState = {
      modeInitiateMicroDeposit: true,
      modeVerifyMicroDeposit: false,
      accountSelectedForUserAction: selectedAccount,
    };

    this.setState(newState);
  }

  triggerMicroDepositVerification(selectedAccount) {
    const newState = {
      modeVerifyMicroDeposit: true,
      modeInitiateMicroDeposit: false,
      accountSelectedForUserAction: selectedAccount,
    };

    this.setState(newState);
  }

  triggerEnterAccountNumber(selectedAccount) {
    const newState = {
      modeVerifyMicroDeposit: false,
      modeInitiateMicroDeposit: false,
      accountSelectedForUserAction: selectedAccount,
      modeDeferredEditAccountNumber: true,
    };

    this.setState(newState);
  }

  triggerAggregateManualAccount(selectedAccount) {
    this.setState({
      modeAggregateManualAccount: true,
      accountSelectedForUserAction: selectedAccount,
    });
  }

  triggerAggregateAccount(selectedAccount) {
    this.setState({
      modeFixAggregationError: true,
      accountSelectedForUserAction: selectedAccount,
    });
  }

  triggerFixAccountClassification(selectedAccount) {
    this.setState({
      modeFixAccountClassification: true,
      accountSelectedForUserAction: selectedAccount,
    });
  }

  onAccountsServerChange(accounts) {
    this.setState({
      accounts,
    });
  }

  onError(errors) {
    this.setState({
      errors,
      loading: false,
    });
  }

  handleOnNextPage(model, callback) {
    let newState = { errors: [], hasSelectDifferentAccountError: false };

    if (model) {
      model = { ...this.state.model, ...model };
      newState.model = model;
    }

    const suspendWizard = this.state.accountSelectedForUserAction;
    if (suspendWizard) {
      newState.renderDeferredComponent = true;
    }
    this.setState(newState);

    if (callback && !suspendWizard) {
      // this call advances the page index in `Wizard` component
      callback(model);
    }
  }

  /*
   * serviceSubmitTransfer defaults to submit transfers flow
   * serviceSubmitTransfer is overridden for edit recurring transfers flow
   */

  handleOnTransferFund(model, callback) {
    this.setState({ loading: true });
    const source = getSource();

    this.props.trackEventSubmit(source);
    this.props
      .serviceSubmitTransfer(Object.assign({}, toServer(model), { source }))
      .then((response) => {
        const docusignUrl = response.spData?.details?.docusignURL;
        const model = Object.assign({}, this.state.model, response.spData);
        if (docusignUrl) {
          storeTransferRequest(model);
          location.href = docusignUrl;
          return;
        }

        this.setState({
          model,
          loading: false,
          errors: [],
        });
        callback(model);
      })
      .catch((errorResponse) => {
        this.props.trackEventSubmitErrors(errorResponse.errors, source);
        const errorStates = this.props.onSubmitErrors(errorResponse);

        if (
          errorStates?.reenterAccountNumberError?.length > 0 ||
          errorStates?.submitMicroDepositError?.length > 0
        ) {
          const { model, accounts } = this.state;
          const { sourceAccountId, targetAccountId } = model;
          const { sourceAccountsFilter, targetAccountsFilter } = this.props;
          const sourceAccounts = filterSourceAccounts(
            accounts,
            sourceAccountsFilter
          );
          const targetAccounts = filterTargetAccounts(
            accounts,
            targetAccountsFilter
          );
          const sourceAccount = findSourceAccount(
            sourceAccountId,
            sourceAccounts
          );
          const targetAccount = findTargetAccount(
            targetAccountId,
            targetAccounts
          );
          const externalAccount = isOnUs(sourceAccount)
            ? targetAccount
            : sourceAccount;

          this.setState({
            ...errorStates,
            accountSelectedForUserAction: externalAccount,
            loading: false,
          });
        } else {
          this.setState({
            ...errorStates,
            loading: false,
          });
        }
      });
  }

  handleSourceAccountChange(sourceAccountId) {
    const { model } = this.state;
    const { isToDropDownDisabled } = this.props;

    let { targetAccountId } = model;

    if (
      !isToDropDownDisabled &&
      targetAccountId &&
      sourceAccountId === targetAccountId
    ) {
      // Clear selection in to drop-down when sourceAccount is the same
      targetAccountId = undefined;
    }

    const newState = {
      model: Object.assign({}, this.state.model, {
        sourceAccountId,
        targetAccountId,
        statements: undefined, // reset statements for the previously selected account if any
      }),
    };

    if (this.state.hasSelectDifferentAccountError) {
      newState.errors = [];
      newState.hasSelectDifferentAccountError = false;
    }

    trackEvent(TRANSFER_FUNDS, "Select Account In Drop Down", {
      subcomponent: "from",
      source: getSource(),
    });
    this.setState(newState);
  }

  handleTargetAccountChange(targetAccountId) {
    const { model } = this.state;
    const { isToDropDownDisabled } = this.props;
    let { sourceAccountId } = model;

    if (
      !isToDropDownDisabled &&
      sourceAccountId &&
      sourceAccountId === targetAccountId
    ) {
      // Clear selection in from drop-down when targetAccount is the same
      sourceAccountId = undefined;
    }

    const newState = {
      model: Object.assign({}, this.state.model, {
        targetAccountId,
        sourceAccountId,
        statements: undefined, // reset statements for the previously selected account if any
      }),
    };

    if (this.state.hasSelectDifferentAccountError) {
      newState.errors = [];
      newState.hasSelectDifferentAccountError = false;
    }

    trackEvent(TRANSFER_FUNDS, "Select Account In Drop Down", {
      subcomponent: "to",
      source: getSource(),
    });
    this.setState(newState);
  }

  handleModelChange(model) {
    this.setState({
      model: Object.assign({}, this.state.model, model),
    });
  }

  clearSelectedAccount() {
    let newState = {};
    const { model, accountSelectedForUserAction } = this.state;
    if (isEmpty(accountSelectedForUserAction) || isEmpty(model)) {
      return;
    }

    if (model.sourceAccountId === accountSelectedForUserAction.userAccountId) {
      newState.sourceAccountId = undefined;
    }

    if (model.targetAccountId === accountSelectedForUserAction.userAccountId) {
      newState.targetAccountId = undefined;
    }

    return newState;
  }

  handleMicroDepositInitiateDone() {
    this.setState({
      modeInitiateMicroDeposit: false,
      model: Object.assign({}, this.state.model, this.clearSelectedAccount()),
      submitMicroDepositError: undefined,
      renderDeferredComponent: false,
    });
  }

  handleMicroDepositInitiateCancel() {
    this.setState({
      submitMicroDepositError: undefined,
      renderDeferredComponent: false,
    });
  }

  handleBackToDashboard() {
    // TODO Pending changes to be applied in separate Jira ticket after user clicks Dashboard button
    location.hash = DASHBOARD_URL;
  }

  handleProvideAccountDetails() {
    this.setState({
      showEditAccountContainer: !this.state.showEditAccountContainer,
    });
  }

  handleOnSaveEditAccount() {
    this.handleProvideAccountDetails();
    this.handleAggregateAccountCancel();
  }

  handleMicroDepositVerifyCancel() {
    this.handleAggregateAccountCancel();
  }

  handleAggregateAccountCancel() {
    this.setState({
      renderDeferredComponent: false,
    });
  }

  handleEditAccountNumberCancel() {
    // Edit account number form can be opened in only ONE use-case:
    // 1. We receive `REENTER_ACCOUNT_NUMBER` state for transfer for the selected account from the server.
    //    This is driven by `modeDeferredEditAccountNumber` state variable.
    //
    // Note that unlike the hPCAP version of the Transfers flow:
    //     1) The Empower app will not allow the user to edit their account number
    //     2) PENDING - The user will view their account number details as a modal
    this.setState({
      reenterAccountNumberError: undefined,
      renderDeferredComponent: false,
    });
  }

  handleEditAccountNumberSubmit() {
    const newState = {
      renderDeferredComponent: false,
      accountSelectedForUserAction: undefined,
      reenterAccountNumberError: undefined,
      modeDeferredEditAccountNumber: false,
    };
    if (
      this.state.modeDeferredEditAccountNumber &&
      !this.state.reenterAccountNumberError
    ) {
      newState.pageIndex = this.state.pageIndex + 1;
    }
    this.setState(newState);
  }

  handleMicroDepositVerifySubmit() {
    this.setState({
      modeVerifyMicroDeposit: false,
      renderDeferredComponent: false,
      accountSelectedForUserAction: undefined,
      pageIndex: this.state.pageIndex + 1,
    });
  }

  handleChooseDifferentAccount() {
    const state = {
      modeVerifyMicroDeposit: false,
      showMicroDepositInitiatedStatus: false,
      accountSelectedForUserAction: undefined,
      errors: [],
      pageIndex: 0,
    };

    const { accounts, model } = this.state;
    const { sourceAccountId } = model;
    const { sourceAccountsFilter } = this.props;
    const sourceAccounts = filterSourceAccounts(accounts, sourceAccountsFilter);
    const sourceAccount = findSourceAccount(sourceAccountId, sourceAccounts);

    let clearExternalAccountState;
    if (isOnUs(sourceAccount)) {
      clearExternalAccountState = { targetAccountId: undefined };
    } else {
      clearExternalAccountState = { sourceAccountId: undefined };
    }

    state.model = Object.assign(
      {},
      this.state.model,
      clearExternalAccountState
    );
    this.setState(state);
  }

  loadAdditionalComponents() {
    const {
      isIAVFailed,
      accountSelectedForUserAction,
      modeVerifyMicroDeposit,
      modeInitiateMicroDeposit,
      modeAggregateManualAccount,
      modeFixAggregationError,
      modeFixAccountClassification,
      submitMicroDepositError,
      reenterAccountNumberError,
      renderDeferredComponent,
      modeDeferredEditAccountNumber,
    } = this.state;

    if (
      reenterAccountNumberError ||
      (renderDeferredComponent && modeDeferredEditAccountNumber)
    ) {
      return (
        <AccountNumberFormContainer
          userAccountId={accountSelectedForUserAction.userAccountId}
          firmName={accountSelectedForUserAction.firmName}
          accountName={accountSelectedForUserAction.name}
          balance={accountSelectedForUserAction.balance}
          logoPath={accountSelectedForUserAction.logoPath}
          onSubmit={this.handleEditAccountNumberSubmit}
          onCancel={this.handleEditAccountNumberCancel}
          errors={reenterAccountNumberError}
          isIAVFailed={isIAVFailed}
          onMounted={this.props.onChildMounted}
          className="transfer-funds-container-component__content"
        />
      );
    }

    if (modeVerifyMicroDeposit) {
      return (
        <MicroDepositVerifyContainer
          onCancel={this.handleMicroDepositVerifyCancel}
          onVerify={this.handleMicroDepositVerifySubmit}
          firmName={accountSelectedForUserAction.firmName}
          userAccountId={accountSelectedForUserAction.userAccountId}
          onMounted={this.props.onChildMounted}
        />
      );
    }

    if (modeInitiateMicroDeposit || submitMicroDepositError) {
      return (
        <MicroDepositInitiateContainer
          userAccountId={accountSelectedForUserAction.userAccountId}
          logoPath={accountSelectedForUserAction.logoPath}
          onDone={this.handleMicroDepositInitiateDone}
          onCancel={this.handleMicroDepositInitiateCancel}
          onBackToDashboard={this.handleBackToDashboard}
          firmName={accountSelectedForUserAction.firmName}
          accountName={accountSelectedForUserAction.name}
          errors={submitMicroDepositError}
          isIAVFailed={isIAVFailed}
          onMounted={this.props.onChildMounted}
          className="transfer-funds-container-component__content"
        />
      );
    }

    if (modeAggregateManualAccount) {
      return (
        <IncompleteAccount
          className="transfer-funds-container-component__content"
          accountName={accountSelectedForUserAction.name}
          firmName={accountSelectedForUserAction.firmName}
          logoPath={accountSelectedForUserAction.logoPath}
          balance={accountSelectedForUserAction.balance}
          onBack={this.handleAggregateAccountCancel}
          displayName="Link account"
          onConfirm={this.props.onLinkAccount}
          confirmLabel="Link account"
          onMounted={this.props.onChildMounted}
        >
          <ManualAccount />
        </IncompleteAccount>
      );
    }

    if (modeFixAggregationError) {
      return (
        <IncompleteAccount
          className="transfer-funds-container-component__content"
          accountName={accountSelectedForUserAction.name}
          firmName={accountSelectedForUserAction.firmName}
          action={ACTION_FIX_AGGREGATION_ERROR}
          logoPath={accountSelectedForUserAction.logoPath}
          balance={accountSelectedForUserAction.balance}
          onBack={this.handleAggregateAccountCancel}
          displayName="Fix aggregation error"
          onMounted={this.props.onChildMounted}
        >
          <AggregationError />
        </IncompleteAccount>
      );
    }

    if (modeFixAccountClassification) {
      return (
        <IncompleteAccount
          className="transfer-funds-container-component__content"
          accountName={accountSelectedForUserAction.name}
          firmName={accountSelectedForUserAction.firmName}
          logoPath={accountSelectedForUserAction.logoPath}
          balance={accountSelectedForUserAction.balance}
          onBack={this.handleAggregateAccountCancel}
          onConfirm={this.handleProvideAccountDetails}
          confirmLabel="Edit Account"
          displayName="Provide Account Details"
          onMounted={this.props.onChildMounted}
        >
          <MissingAccountType />
        </IncompleteAccount>
      );
    }
  }

  render() {
    const {
      personFullName,
      accountSelectedForUserAction,
      loading,
      errors,
      reenterAccountNumberError,
      submitMicroDepositError,
      model,
      accounts,
      showMicroDepositInitiatedStatus,
      renderDeferredComponent,
      contributionYears,
      pageIndex,
      targetAccountOwnerAge,
      hasRecurringTransferEstablished,
      shouldShowTradingBlackoutWarningModal,
      shouldShowTradingBlackoutWarningMsg,
      isTradingSuspended,
      showEditAccountContainer,
      styleGuide,
    } = this.state;
    const {
      onChildMounted,
      onBack,
      isToDropDownDisabled,
      isFromDropDownDisabled,
      isContributionYearDisabled,
      onLinkAccount,
      onClose,
      onMakeAnotherTransfer,
      onBackToHome,
      docusignResult,
      sourceAccountsFilter,
      targetAccountsFilter,
      transferType,
      hasOneTimeFrequency,
      pageBuilder,
      wizardPages,
      isTaxWithholdingEnabled,
      isPSTaxWithholdingEnabled,
    } = this.props;

    // wait for API calls in componentDidMount
    if (this.state.initializing) {
      return <Loading />;
    }

    if (showMicroDepositInitiatedStatus) {
      return (
        <MicroDepositInitiatedStatus
          onChooseDifferentAccount={this.handleChooseDifferentAccount}
          subTitle={errors[0]}
          title={"Bank verification"}
        />
      );
    }

    const stateType = getTransferStateType(
      model.sourceAccountId,
      model.targetAccountId,
      accounts
    );

    const isPreviousYearContribution = contributionYears?.limits?.length
      ? model.contributionYear && CURRENT_YEAR !== model.contributionYear
      : false;

    const sourceAccount = findSourceAccount(model.sourceAccountId, accounts);
    const isPremierInvestmentSource =
      sourceAccount?.productCategory === "EMPOWER_PREMIER";

    const currentTargetAccountsFilter = determineTargetAccountsFilter(
      sourceAccount,
      transferType,
      targetAccountsFilter
    );
    let targetAccounts = filterTargetAccounts(
      accounts,
      currentTargetAccountsFilter
    );
    let sourceAccounts = filterSourceAccounts(accounts, sourceAccountsFilter);

    // Filtering out the account that is selected
    // avoid user from selecting same account that is selected in disabled inputs

    if (isToDropDownDisabled) {
      sourceAccounts = sourceAccounts.filter(
        (sa) => sa.userAccountId !== model.targetAccountId
      );
    }

    if (isFromDropDownDisabled) {
      targetAccounts = targetAccounts.filter(
        (ta) => ta.userAccountId !== model.sourceAccountId
      );
    }

    const enableForm = Boolean(
      !isEmpty(errors) || submitMicroDepositError || reenterAccountNumberError
    );

    return (
      <>
        <LoadingOverlay active={loading} />
        {(renderDeferredComponent ||
          reenterAccountNumberError ||
          submitMicroDepositError) &&
        accountSelectedForUserAction &&
        accountSelectedForUserAction.userAccountId ? (
          this.loadAdditionalComponents()
        ) : (
          <TransferFundsWizard
            pageIndex={pageIndex}
            personFullName={personFullName}
            sourceAccounts={sourceAccounts}
            targetAccounts={targetAccounts}
            contributionYears={contributionYears}
            targetAccountOwnerAge={targetAccountOwnerAge}
            model={model}
            errors={errors}
            enableForm={enableForm}
            onNextPage={this.handleOnNextPage}
            onTransferFund={this.handleOnTransferFund}
            onSourceAccountChange={this.handleSourceAccountChange}
            onTargetAccountChange={this.handleTargetAccountChange}
            isToDropDownDisabled={isToDropDownDisabled}
            isFromDropDownDisabled={isFromDropDownDisabled}
            isContributionYearDisabled={isContributionYearDisabled}
            onViewAccountNumber={this.handleViewAccountNumber}
            onModelChange={this.handleModelChange}
            onLinkAccount={onLinkAccount}
            onClose={onClose}
            onMakeAnotherTransfer={onMakeAnotherTransfer}
            onBackToHome={onBackToHome}
            onBack={onBack}
            onChildMounted={onChildMounted}
            docusignResult={docusignResult}
            stateType={stateType}
            transferType={transferType}
            hasRecurringTransferEstablished={hasRecurringTransferEstablished}
            isPreviousYearContribution={isPreviousYearContribution}
            hasOneTimeFrequency={hasOneTimeFrequency}
            pageBuilder={pageBuilder}
            pages={wizardPages}
            isTaxWithholdingEnabled={isTaxWithholdingEnabled}
            isPSTaxWithholdingEnabled={isPSTaxWithholdingEnabled}
            shouldShowTradingBlackoutWarningModal={
              shouldShowTradingBlackoutWarningModal && isPremierInvestmentSource
            }
            shouldShowTradingBlackoutWarningMsg={
              shouldShowTradingBlackoutWarningMsg && isPremierInvestmentSource
            }
            isTradingSuspended={isTradingSuspended && isPremierInvestmentSource}
            onBlackoutModalClose={this.handleBlackoutModalClose}
            styleGuideData={styleGuide}
          />
        )}
        {showEditAccountContainer && (
          <EditAccountContainer
            userAccountId={accountSelectedForUserAction.userAccountId}
            isOpen={showEditAccountContainer}
            onCancel={this.handleProvideAccountDetails}
            onSave={this.handleOnSaveEditAccount}
          />
        )}
      </>
    );
  }
}

TransferFundsContainer.defaultProps = {
  model: {},
  isToDropDownDisabled: false,
  isFromDropDownDisabled: false,
  isContributionYearDisabled: false,
  // the default filters do not do any filtering
  targetAccountsFilter: () => true,
  sourceAccountsFilter: () => true,
  onSelectAccountWithAggregationError: noop,
  onAccountChanged: noop,
  onBack: undefined,
  onChildMounted: noop,
  onLinkAccount: noop,
  onSubmitErrors: parseSubmitTransferErrors,
  onClose: undefined,
  onMakeAnotherTransfer: undefined,
  onBackToHome: undefined,
  docusignResult: undefined,
  serviceInitiateIav: promisify(Services.Transfer.getStateForTransfer),
  serviceGetContributionLimits: promisify(
    Services.Accounts.getContributionLimits
  ),
  serviceGetPersonAccounts: fetchPersonAccounts,
  serviceGetPersons: getPersons,
  serviceGetTransferInstructions: promisify(
    Services.Transfer.getTransferInstruction
  ),
  serviceSubmitTransfer: promisify(Services.Transfer.submitTransfer, true),
  trackEventSubmitErrors: trackSubmitTransferErrors,
  trackEventSubmit: trackSubmitTransfer,
  hasOneTimeFrequency: undefined,
  pageBuilder: undefined,
  wizardPages: undefined,
  hasRecurringTransferEstablished: undefined,
  isTaxWithholdingEnabled: false,
  isPSTaxWithholdingEnabled: false,
  serviceGetTradingBlackoutPeriod: promisify(
    Services.EmpowerInvestment.getTradingBlackoutPeriod
  ),
};

TransferFundsContainer.propTypes = {
  model: PropTypes.object,
  isToDropDownDisabled: PropTypes.bool,
  isFromDropDownDisabled: PropTypes.bool,
  isContributionYearDisabled: PropTypes.bool,
  targetAccountsFilter: PropTypes.func,
  sourceAccountsFilter: PropTypes.func,
  onSelectAccountWithAggregationError: PropTypes.func,
  onAccountChanged: PropTypes.func,
  onBack: PropTypes.func,
  onLinkAccount: PropTypes.func,
  onSubmitErrors: PropTypes.func,
  onClose: PropTypes.func,
  onMakeAnotherTransfer: PropTypes.func,
  onBackToHome: PropTypes.func,
  serviceInitiateIav: PropTypes.func,
  serviceGetContributionLimits: PropTypes.func,
  serviceGetPersonAccounts: PropTypes.func,
  serviceGetPersons: PropTypes.func,
  serviceGetTransferInstructions: PropTypes.func,
  serviceSubmitTransfer: PropTypes.func,
  trackEventSubmitErrors: PropTypes.func,
  trackEventSubmit: PropTypes.func,
  onChildMounted: PropTypes.func,
  docusignResult: PropTypes.bool,
  transferType: PropTypes.number.isRequired,
  hasOneTimeFrequency: PropTypes.bool,
  pageBuilder: PropTypes.func,
  wizardPages: PropTypes.array,
  hasRecurringTransferEstablished: PropTypes.bool,
  isTaxWithholdingEnabled: PropTypes.bool,
  isPSTaxWithholdingEnabled: PropTypes.bool,
  serviceGetTradingBlackoutPeriod: PropTypes.func,
};
