var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"legendItem qa-legend-item-"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\" data-legend-color="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " data-sliceid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sliceId") : depth0), depth0))
    + "\">\n				<div class=\"color\">\n					<div class=\"colorSwatch\" style=\"background-color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + "\"></div>\n				</div>\n				<div class=\"description qa-legend-desc\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" data-hj-masked>\n					"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n				</div>\n				<div class=\"amount\" title=\""
    + alias2(__default(require("../helpers/dollarAndCentsAmount.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),true,true,(depths[1] != null ? lookupProperty(depths[1],"isNegative") : depths[1]),true,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":14,"column":31},"end":{"line":14,"column":96}}}))
    + "\">\n					"
    + alias2(__default(require("../helpers/dollarAndCentsAmount.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),true,true,(depths[1] != null ? lookupProperty(depths[1],"isNegative") : depths[1]),true,{"name":"dollarAndCentsAmount","hash":{},"data":data,"loc":{"start":{"line":15,"column":5},"end":{"line":15,"column":70}}}))
    + "\n				</div>\n			</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"scrollUp disabled\">\n	<div>&#9652;</div>\n</div>\n<div class=\"js-cashflow-scrollable cashflow-scrollable-area\">\n	<div class=\"js-cashflow-scrollable__container cashflow-scrollable-area__container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n<div class=\"scrollDown\">\n	<div>&#9662;</div>\n</div>\n";
},"useData":true,"useDepths":true});