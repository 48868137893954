import { isEmployerSponsoredRetirementAccount } from "utils/account";

// shouldShowRecommendationTab is used within account details view for investment and manual portfolio view
export default function shouldShowRecommendationTab(accountModel, options) {
  if (
    !accountModel ||
    accountModel.get("isOnUsRetirement") ||
    !options ||
    IS_EMPOWER
  )
    return false;

  const { attributes } = accountModel;
  const isRetirementAccount = isEmployerSponsoredRetirementAccount(
    attributes.accountTypeNew,
    attributes.accountTypeSubtype
  );
  const isActive = Boolean(
    attributes.additionalAttributes &&
      attributes.additionalAttributes.isCurrentEmployer === "Y"
  );

  const { isUserAggQual, isUserClient } = options;
  return (
    isRetirementAccount &&
    !attributes.isOnUs401K &&
    !attributes.isOnUs &&
    ((isUserAggQual && !isUserClient) || (isUserClient && isActive))
  );
}
