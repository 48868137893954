import $ from "jquery";
import Backbone from "backbone";
import EnrollNowModalTemplate from "templates/components/enrollNowModal/enrollNowModal.html";

export default Backbone.View.extend({
  events: {
    "click .cancel": "closeModalClick",
    "click .getStarted": "getStartedClick",
  },
  el: "#enrollNowModal",
  initialize: function () {
    var self = this;

    // render the view
    self.render();

    // show the view in modal window using colorbox
    $.colorbox({
      inline: true,
      href: self.$el,
      overlayClose: false,
      transition: "none",
    });
  },
  render: function () {
    var self = this;
    self.$el.html(EnrollNowModalTemplate());
    return this;
  },
  getStartedClick: function () {
    this.closeModal();
    window.location.href =
      IS_EMPOWER && !IS_ADVISOR ? ENROLL_URL_PREFIX : "/page/enrollment/start";
  },
  closeModalClick: function (e) {
    e.preventDefault();
    this.closeModal();
    window.history.back();
  },
  closeModal: function () {
    $.colorbox.close();
  },
});
