import DateUtils from "libs/pcap/utils/date";
import moment from "moment";

/**
 * Generate a filename string based on current date range.
 *
 * @param {string|object} startDate of the exported transactions.
 * @param {string|object} endDate of the exported transactions.
 *
 * @return {String} filename for the exported transactions CSV.
 */
export function getCsvFileName(startDate, endDate) {
  const defaultName = "transactions";

  // checking falsy first, since moment(undefined) will
  // return a valid date of today
  if (!startDate || !endDate) {
    return defaultName;
  }

  startDate = moment(startDate, DateUtils.API_FORMAT);
  endDate = moment(endDate, DateUtils.API_FORMAT);

  if (!startDate.isValid() || !endDate.isValid()) {
    return defaultName;
  }

  return (
    startDate.format(DateUtils.API_FORMAT) +
    " thru " +
    endDate.format(DateUtils.API_FORMAT) +
    " transactions"
  );
}
