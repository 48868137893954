/* eslint-disable sonarjs/cognitive-complexity, sonarjs/no-duplicate-string */
import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import services from "services";
import analytics from "analytics";
import parseResponseErrors from "libs/pcap/utils/response";
import ProjectionSummary from "components/projectionSummary/ProjectionSummary";
import ProjectionSummaryHeader from "./ProjectionSummaryHeader";
import ProjectionSummaryForm from "./ProjectionSummaryForm";
import ProjectionSummaryAccountContributionsContainer from "components/projectionSummary/ProjectionSummaryAccountContributionsContainer";
import { roundDecimal } from "libs/pcap/utils/decimalAdjust";

const PROJECTION_SUMMARY_MONTHS = 12;

const CONTRIBUTION_FREQUENCIES = {
  WEEKLY_ON_MON: "Weekly",
  WEEKLY_ON_TUE: "Weekly",
  WEEKLY_ON_WED: "Weekly",
  WEEKLY_ON_THUR: "Weekly",
  WEEKLY_ON_FRI: "Weekly",
  SEMI_MONTHLY: "Semi-monthly",
  MONTHLY: "Monthly",
  BI_MONTHLY: "Bi-monthly",
  QUARTERLY: "Quarterly",
  SEMI_ANNUALLY: "Semi-annually",
  ANNUALLY: "Annually",
};
export default class ProjectionSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: null,
      isContributionModalOpen: false,
      parsedContributions: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const {
      allWithdrawals,
      allContributions,
      aggregateContributions,
      accountName,
    } = this.state.parsedContributions;

    const numberOfContributions =
      (allContributions && allContributions.length) || 0;
    const numberOfWithdrawals = (allWithdrawals && allWithdrawals.length) || 0;

    return (
      <div>
        <ProjectionSummary
          loading={this.state.loading}
          errors={this.state.errors}
          additionalValueAtRetirement={this.state.additionalValueAtRetirement}
          currentValueAtRetirement={this.state.currentValueAtRetirement}
          currentStrategyProjection={this.state.currentStrategyProjection}
          newStrategyProjection={this.state.newStrategyProjection}
          endIsRetirement={this.state.endIsRetirement}
          ace={this.props.ace}
          title={this.props.title}
          form={
            this.props.hideForm ? (
              <React.Fragment />
            ) : (
              <ProjectionSummaryForm
                accountName={accountName}
                currentContribution={aggregateContributions}
                newContribution={this.state.newContribution}
                numberOfWithdrawals={numberOfWithdrawals}
                numberOfContributions={numberOfContributions}
                onContributionChange={this.onContributionChange.bind(this)}
                onResetContributionChange={this.onResetContributionChange.bind(
                  this
                )}
                showContributionModal={this.showContributionModal.bind(this)}
                onContributionSubmit={this.handleSubmit}
              />
            )
          }
          header={
            <ProjectionSummaryHeader
              currentValue={this.state.currentValueAtRetirement}
              additionalValue={this.state.additionalValueAtRetirement}
              isWithdrawal={aggregateContributions < 0}
              endIsRetirement={this.state.endIsRetirement}
            />
          }
        />
        {this.state.isContributionModalOpen && (
          <ProjectionSummaryAccountContributionsContainer
            onClose={this.onCloseContributionModal.bind(this)}
            isOpen={this.state.isContributionModalOpen}
            allWithdrawals={allWithdrawals}
            allContributions={allContributions}
          />
        )}
      </div>
    );
  }

  componentDidMount() {
    Promise.all([
      this.fetchPerson(),
      this.fetchAccounts(),
      this.fetchStandingFundingInstructions(),
    ])
      .catch((errors) => {
        this.setState({
          loading: false,
          errors: errors,
        });
        analytics.sendEngineeringEvent(
          "Error",
          "`ProjectionSummaryContainer()` " + JSON.stringify(errors)
        );
      })
      .then(
        ([responsePerson, responseAccounts, responseFundingInstructions]) => {
          const parsedContributions = this.parseFundingInstructions(
            responseFundingInstructions,
            responseAccounts
          );

          this.fetchStrategyProjection(
            roundDecimal(
              parsedContributions.aggregateContributions *
                PROJECTION_SUMMARY_MONTHS,
              2
            )
          )
            .catch((errors) => {
              this.setState({
                loading: false,
                errors: errors,
              });
              analytics.sendEngineeringEvent(
                "Error",
                "`ProjectionSummaryContainer()` " + JSON.stringify(errors)
              );
            })
            .then((responseStrategyProjection) => {
              const projectionAtRetirement = _.last(
                responseStrategyProjection.median_series
              );
              const endIsRetirement =
                responsePerson.retirementAge === projectionAtRetirement.age;
              this.setState({
                endIsRetirement,
                accounts: responseAccounts,
                parsedContributions: parsedContributions,
                currentStrategyProjection:
                  responseStrategyProjection.median_series,
                currentValueAtRetirement: projectionAtRetirement.value,
                loading: false,
              });
              if (this.props.newContributionAmount) {
                this.onContributionChange(this.props.newContributionAmount);
              }
            });
        }
      );
  }

  componentWillUnmount() {
    services.Accounts.get.unwatch(this.watchedAccounts);
  }

  /**
   * Fetch Person
   * @return {Promise} a Promise
   */
  fetchPerson() {
    return new Promise((resolve, reject) => {
      services.Person.get(
        {},
        (err, response) => {
          const errors = parseResponseErrors(err, response);
          if (errors) {
            reject(errors);
            return;
          }
          resolve(response.spData);
        },
        this
      );
    });
  }

  /**
   * Fetch Standing Funding Instructions
   * @return {Promise} a Promise
   */
  fetchStandingFundingInstructions() {
    return new Promise((resolve, reject) => {
      services.StandingFundingInstructions.get(
        {},
        (err, response) => {
          const errors = parseResponseErrors(err, response);
          if (errors) {
            reject(errors);
            return;
          }
          resolve(response.spData.instructions);
        },
        this
      );
    });
  }

  /**
   * Fetch Strategy Projection
   * @param {Number} additionalContributionPerYear - amount to add to contribution per year as integer dollar
   * @return {Promise} a Promise
   */
  fetchStrategyProjection(additionalContributionPerYear = 0) {
    return new Promise((resolve, reject) => {
      services.StrategyProjection.get(
        {
          additionalContributionPerYear: additionalContributionPerYear,
        },
        (err, response) => {
          const errors = parseResponseErrors(err, response);
          if (errors) {
            reject(errors);
            return;
          }
          resolve(response.spData.projection);
        },
        this
      );
    });
  }

  handleSubmit(contribution) {
    location.href = `${TRANSFER_FUNDS_URL}?transferType=1&amount=${contribution}&frequency=MONTHLY`;
  }

  /**
   * Fetch accounts
   * @return {Promise} a Promise
   */
  fetchAccounts() {
    return new Promise((resolve, reject) => {
      this.watchedAccounts = services.Accounts.get.watch(
        {},
        (err, response) => {
          const errors = parseResponseErrors(err, response);
          if (errors) {
            reject(errors);
            return;
          }
          const onUsAccounts = response.spData.accounts.filter((account) => {
            return account.isOnUs || account.isOnUsRetirement;
          });
          // when watching an API call using get.watch(), the data could change and
          // execute this callback again.
          if (this.hasFetchedAccounts) {
            this.setState({
              accounts: onUsAccounts,
            });
            return;
          }
          this.hasFetchedAccounts = true;
          resolve(onUsAccounts);
        },
        this
      );
    });
  }

  /**
   * Parse Funding instructions to get summed monthly contributions for each on-us account
   * @param {Array} instructions - funding instructions returned from getStandingFundingInstructions
   * @param {Array} accounts - accounts returned from getAccounts2
   * @return {Object} contributionsByAccount {Object} - For each on-us account, gives map of contribution in that account by frequency
   *                  aggregateContributions {Number} - total contributions across all on-us accounts
   */
  parseFundingInstructions(instructions = [], accounts) {
    let aggregateContributions = 0;
    let contributionsByAccount = {};
    let allContributions = [];
    let allWithdrawals = [];
    let usedAccounts = [];
    let accountName;

    instructions.forEach(function (instructionRaw) {
      if (
        instructionRaw.frequency &&
        instructionRaw.monthlyContributionAmount
      ) {
        const account = _.find(accounts, (account) => {
          return account.userAccountId === instructionRaw.targetAccountId;
        });

        const instruction = {
          targetAccountId: instructionRaw.targetAccountId,
          accountName: account && account.originalName,
          frequency: CONTRIBUTION_FREQUENCIES[instructionRaw.frequency],
          amount: instructionRaw.amount,
          monthlyContributionAmount: instructionRaw.monthlyContributionAmount,
          id: instructionRaw.id,
        };

        if (!contributionsByAccount[instruction.targetAccountId]) {
          contributionsByAccount[instruction.targetAccountId] = {
            aggregate: 0,
            accountName: instruction.accountName,
          };
          usedAccounts.push(account);
        }

        contributionsByAccount[instruction.targetAccountId].aggregate +=
          instruction.monthlyContributionAmount;
        aggregateContributions += instruction.monthlyContributionAmount;

        if (instruction.monthlyContributionAmount < 0) {
          allWithdrawals.push(instruction);
        } else {
          allContributions.push(instruction);
        }
      }
    }, this);

    if (usedAccounts.length === 1) {
      accountName = usedAccounts[0].originalName;
    } else if (usedAccounts.length === 0) {
      const onUsAccounts = _.filter(accounts, (account) => {
        return (
          (account.isOnUs || account.isOnUsRetirement) && !account.closedDate
        );
      });
      accountName =
        onUsAccounts.length === 1 ? onUsAccounts[0].originalName : "";
    }

    return {
      allWithdrawals,
      allContributions,
      aggregateContributions,
      accountName,
    };
  }

  /**
   * Parse `contributionsByAccount` from parseFundingInstructions to get list of contributions and withdrawals
   * @param {Object} contributionsByAccount - For each on-us account, a map of contributions/withdrawals in that account by frequency
   * @return {Object} allContributions {Array} - All Contributions grouped by same account and frequency
   *                  allWithdrawals {Array} - All Withdrawals grouped by same account and frequency
   */
  getWithdrawalsAndContributions(contributionsByAccount = {}) {
    let rawContributions = [];

    _.forEach(contributionsByAccount, (value) => {
      rawContributions = rawContributions.concat(
        this.contributionsForAccount(value)
      );
    });

    let groupedContributions = _.groupBy(rawContributions, (contribution) => {
      return contribution.amount < 0;
    });
    let allContributions = groupedContributions.false;
    let allWithdrawals = groupedContributions.true;
    return { allContributions, allWithdrawals };
  }

  contributionsForAccount(account) {
    let contributions = [];
    _.forEach(account.frequencies, (value, key) => {
      contributions.push({
        accountName: account.accountName,
        frequency: key,
        amount: value,
      });
    });
    return contributions;
  }

  /**
   * Called by ProjectionSummaryView to fetch new projections for chart and update state
   * @param {Number} value - new monthly contribution amount
   */
  onContributionChange(value) {
    const newYearlyContribution = value * PROJECTION_SUMMARY_MONTHS;

    this.fetchStrategyProjection(roundDecimal(newYearlyContribution, 2))
      .catch((errors) => {
        this.setState({
          loading: false,
          errors: errors,
        });
        analytics.sendEngineeringEvent(
          "Error",
          "`ProjectionSummaryContainer()` " + JSON.stringify(errors)
        );
      })
      .then((responseStrategyProjection) => {
        const projectionAtRetirement = _.last(
          responseStrategyProjection.median_series
        );

        this.setState({
          newStrategyProjection: responseStrategyProjection.median_series,
          additionalValueAtRetirement:
            projectionAtRetirement.value - this.state.currentValueAtRetirement,
          newContribution: value,
        });
      });
  }

  onResetContributionChange() {
    this.setState({
      newStrategyProjection: [],
      additionalValueAtRetirement: 0,
      newContribution: undefined,
    });
  }

  onCloseContributionModal() {
    this.setState({
      isContributionModalOpen: false,
    });
  }

  showContributionModal() {
    this.setState({
      isContributionModalOpen: true,
    });
  }
}

ProjectionSummaryContainer.propTypes = {
  ace: PropTypes.object,
  title: PropTypes.string,
  newContributionAmount: PropTypes.number,
  hideForm: PropTypes.bool,
};
ProjectionSummaryContainer.defaultProps = {
  ace: undefined,
  title: "Projection",
  newContributionAmount: 0,
  hideForm: false,
};
