import React from "react";
import PropTypes from "prop-types";
import { AccountNumberFormComponent } from "components/transferFunds/AccountNumberForm";
import { promisify } from "utils/service";
import Services from "services";
import { getSource } from "components/common/attributionStore";
import { findErrorByCode } from "libs/pcap/utils/response";
import { without, noop } from "underscore";
import LoadingOverlay from "components/common/LoadingOverlay";

const ERROR_RECONFIRM_ACCOUNT_NUMBER_MISMATCH = 123;
const ERROR_RECONFIRM_PENDING_TRANSFER_EXISTS = 124;

export default class AccountNumberFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: props.errors,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.onMounted("Account Details");
  }

  handleSubmit(model) {
    this.setState({ loading: true });
    this.props
      .captureAccountDetails(
        Object.assign({}, model, {
          source: getSource(),
          accountConfirmed: this.state.accountConfirmed,
        })
      )
      .then(
        ({ spData: account }) => {
          this.setState({
            loading: false,
            accountConfirmed: undefined,
            errors: undefined,
            warnings: undefined,
          });
          this.props.onSubmit(Object.assign({}, model, account));
        },
        (response) => {
          let { errors } = response.spHeader;
          const warnings = [];

          const errAccountNumberMismatch = findErrorByCode(
            response,
            ERROR_RECONFIRM_ACCOUNT_NUMBER_MISMATCH
          );
          if (errAccountNumberMismatch) {
            warnings.push(errAccountNumberMismatch);
          }

          const errPendingTransferExists = findErrorByCode(
            response,
            ERROR_RECONFIRM_PENDING_TRANSFER_EXISTS
          );
          if (errPendingTransferExists) {
            warnings.push(errPendingTransferExists);
          }

          // don't display errors identified as warnings
          errors = without(errors, ...warnings);

          this.setState({
            errors: errors.map((e) => e.message),
            warnings: warnings.map((w) => w.message),
            accountConfirmed: warnings.length ? true : undefined,
            loading: false,
          });
        }
      );
  }

  render() {
    const {
      userAccountId,
      logoPath,
      accountName,
      balance,
      firmName,
      onCancel,
      isIAVFailed,
      className,
    } = this.props;
    const { loading, errors, warnings } = this.state;

    return (
      <>
        <LoadingOverlay active={loading} />
        {IS_EMPOWER && (
          <h1 className="nav-secondary__title js-transfer-confirmation-title pc-u-mb">
            Account details
          </h1>
        )}
        {isIAVFailed && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label className="pc-help-block pc-help-block--small pc-help-block--error pc-u-mb js-error-cannot-verify-iav">
            We are having some trouble verifying this account. Let&apos;s try
            verifying a different way.
          </label>
        )}
        <AccountNumberFormComponent
          model={{
            userAccountId,
          }}
          errors={errors}
          warnings={warnings}
          logoPath={logoPath}
          accountName={accountName}
          balance={balance}
          firmName={firmName}
          onSubmit={this.handleSubmit}
          onCancel={onCancel}
          className={className}
        />
      </>
    );
  }
}

AccountNumberFormContainer.propTypes = {
  captureAccountDetails: PropTypes.func,
  userAccountId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  accountName: PropTypes.string,
  balance: PropTypes.number,
  firmName: PropTypes.string,
  logoPath: PropTypes.string,
  errors: PropTypes.array,
  onMounted: PropTypes.func,
  isIAVFailed: PropTypes.bool,
  className: PropTypes.string,
};

AccountNumberFormContainer.defaultProps = {
  captureAccountDetails: promisify(
    Services.PCBAccount.captureAccountDetails,
    true
  ),
  accountName: undefined,
  firmName: undefined,
  logoPath: undefined,
  balance: undefined,
  errors: undefined,
  onMounted: noop,
  isIAVFailed: false,
  className: undefined,
};
