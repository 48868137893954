/* eslint-disable camelcase, sonarjs/cognitive-complexity */
/* eslint react/require-default-props: 0 */

import PropTypes from "prop-types";
import React from "react";
import TransactionsGridTableClient from "./transactionsGridTableClient/TransactionsGridTableClient";
import TransactionEditRow from "./TransactionEditRow";
import Row from "libs/pcap/table/Row";
import { isEqual, isEmpty, noop } from "underscore";
import defaultColumns from "./columns/default";
import defaultSplitColumns from "./columns/defaultSplit";
import deepCopy from "deep-copy";
import transactionsToClient from "./toClient";
import IframeClient from "partner/iframeClient";
import { isEmpowerPrivilegedMode } from "../../views/modules/sidebar/utils/accountUtils";
import ManualTransactionRow from "./ManualTransactionRow";
import moment from "moment";
import { DISPLAY_FORMAT } from "libs/pcap/utils/date2";
import Table from "libs/pcap/table/Table";

const iframeClient = IframeClient.getInstance();

export class TransactionsGrid extends React.Component {
  constructor(props) {
    super(props);
    let transactions = props.renderDuplicates
      ? props.transactions
      : TransactionsGrid.filterDuplicatedTransactions(props.transactions);
    this.state = {
      transactions,
      prevPropsIsEditable: props.isEditable,
      prevPropsIsManualOpen: props.isAddManualOpen,
    };

    this.generateTransactionClassName =
      this.generateTransactionClassName.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleSavedTransaction = this.handleSavedTransaction.bind(this);
    this.handleSavedManualTransaction =
      this.handleSavedManualTransaction.bind(this);
    this.handleCloseEditor = this.handleCloseEditor.bind(this);
    this.getRow = this.getRow.bind(this);
    this.getManualTransactionComponent =
      this.getManualTransactionComponent.bind(this);
  }

  static filterDuplicatedTransactions(transactions) {
    return transactions.filter((t) => !t.isDuplicate);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    // If the transactions change, or the renderDuplicates prop changed
    if (
      !isEqual(nextProps.transactions, prevState.prevPropsTransactions) ||
      nextProps.renderDuplicates !== prevState.prevPropsRenderDuplicates
    ) {
      newState.transactions = nextProps.renderDuplicates
        ? nextProps.transactions
        : TransactionsGrid.filterDuplicatedTransactions(nextProps.transactions);
      newState.prevPropsTransactions = nextProps.transactions;
      newState.prevPropsRenderDuplicates = nextProps.renderDuplicates;
    }

    //If the grid is not editable now (i.e. we open the multi edit...)
    if (
      nextProps.isEditable !== prevState.prevPropsIsEditable &&
      !nextProps.isEditable
    ) {
      newState.transactionBeingEdited = undefined;
    } else if (nextProps.isEditable) {
      newState.prevPropsIsEditable = nextProps.isEditable;
    }

    //If the Add Manual transaction button is clicked
    if (nextProps.isAddManualOpen !== prevState.prevPropsIsManualOpen) {
      newState.isAddManualOpen = nextProps.isAddManualOpen;
      newState.prevPropsIsManualOpen = nextProps.isAddManualOpen;
      if (nextProps.isAddManualOpen) {
        newState.transactionBeingEdited = null;
      }
    }

    return isEmpty(newState) ? null : newState;
  }

  static keyAccessor(transaction) {
    return transaction.userTransactionId;
  }

  componentDidUpdate(_, prevState) {
    if (
      IS_IFRAMED &&
      prevState.transactionBeingEdited !== this.state.transactionBeingEdited
    ) {
      iframeClient.triggerResize();
    }
  }

  /**
   * This function will be passed all the way down to the Row component, where it can generate special classes for pending transactions or other cases.
   *
   * @param {Object} transaction for which the class is being generated.
   *
   * @return {?String} the special class name, if any.
   */
  generateTransactionClassName(transaction) {
    if (transaction.status === "pending") {
      return "table__row--pending-transaction qa-pending-transaction";
    }
    return null;
  }

  handleRowClick(ev, transaction) {
    const { handleCloseManualTransactionContainer } = this.props;
    handleCloseManualTransactionContainer();
    if (transaction.isEditable) {
      const AMPLITUDE_EVENTS = window.integratedSharedData?.AMPLITUDE_EVENTS;
      const eventBus = window.dashboardUtils?.eventBus;
      const selection = "budget_page.expand_transactions_button.click";
      eventBus?.dispatch(selection);
      eventBus?.dispatchAmplitude({
        event_type: AMPLITUDE_EVENTS?.SELECT_BUTTON ?? "select_button",
        event_properties: {
          selection,
        },
      });
      this.setState({
        transactionBeingEdited: deepCopy(transaction),
        isAddManualOpen: true,
      });
    }
  }

  handleSavedManualTransaction(
    transactionResponse,
    closeManualTransaction = true
  ) {
    const { categories, tags, startDate, endDate } = this.props;
    let transactions = deepCopy(this.state.transactions);
    let transaction =
      transactionsToClient(transactionResponse, categories, tags)[0] || {};
    // Add the newly created manual transaction to the existing transactions list
    // only if within selected date range
    const isTransactionDateWithinRange = moment(
      transaction.transactionDate
    ).isBetween(startDate, endDate, "days", "[]");
    if (isTransactionDateWithinRange) {
      transactions.push(transaction);
    }

    if (closeManualTransaction) {
      this.setState({
        transactionBeingEdited: null,
        isAddManualOpen: false,
        transactions: transactions,
      });
    } else {
      this.setState({
        transactions: transactions,
      });
    }
  }

  handleSavedTransaction(
    transactionResponse,
    closeEditMode = true,
    deleted = false
  ) {
    const { categories, tags, startDate, endDate } = this.props;
    let transactions = deepCopy(this.state.transactions);
    // Remove the deleted transation from the grid
    if (deleted) {
      transactions = transactions.filter(
        (t) => t.userTransactionId !== transactionResponse.userTransactionId
      );
    } else {
      let transaction =
        transactionsToClient(transactionResponse, categories, tags)[0] || {};
      const isDateWithinRange = moment(transaction.transactionDate).isBetween(
        startDate,
        endDate,
        "days",
        "[}"
      );
      if (!isDateWithinRange && transaction.isManual) {
        transactions = transactions.filter(
          (t) => t.userTransactionId !== transaction.userTransactionId
        );
      } else {
        transactions[
          transactions.findIndex(
            (t) => t.userTransactionId === transaction.userTransactionId
          )
        ] = transaction;
      }
    }

    if (closeEditMode) {
      this.setState({
        transactionBeingEdited: null,
        transactions: transactions,
        isAddManualOpen: false,
      });
    } else {
      this.setState({
        transactions: transactions,
      });
    }
  }

  handleCloseEditor() {
    this.setState({ transactionBeingEdited: null, isAddManualOpen: false });
  }

  getManualTransactionComponent(renderHeader = false) {
    const AddManual = this.props.ManualTransactionRow;
    const {
      accountForManualTransaction,
      columns,
      headerRowClassName,
      tags,
      categories,
      className,
      handleCloseManualTransactionContainer,
      onAddManualTransaction,
    } = this.props;

    const model = accountForManualTransaction
      ? {
          manualTransactionDate: moment().endOf("day").format(DISPLAY_FORMAT),
          userAccountId: accountForManualTransaction.get("userAccountId"),
          description: undefined,
          categoryId: 112,
          amount: undefined,
          isDuplicate: false,
          selectedTags: [],
          currentSelectedAccount:
            accountForManualTransaction.get("userAccountId"),
          currentSelectedAccountCreateDate:
            accountForManualTransaction.get("createdDate"),
        }
      : undefined;

    const tableClassName = `table--primary table__body--primary pc-transactions-grid centi ${
      className || ""
    }`;

    const getManual = (
      <AddManual
        categories={categories}
        tags={tags}
        columns={columns}
        onAddManualTransaction={onAddManualTransaction}
        onCancel={handleCloseManualTransactionContainer}
        accountsList={this.props.accountsListForManualTransactions}
        accountForManualTransaction={accountForManualTransaction}
        onSavedManualTransaction={this.handleSavedManualTransaction}
        model={model}
      />
    );
    const getHeader = (
      <Table
        {...this.props}
        columns={columns}
        onRequestSort={this.handleRequestSort}
        onColumnFilterOpen={this.handleColumnFilterOpen}
        onColumnFilterChange={this.handleColumnFilterChange}
        className={tableClassName + " table--overflow-visible"}
        headerRowClassName={headerRowClassName}
        zeroState={""}
      />
    );
    // For Zero state, render the table header and the manual transaction component
    if (renderHeader) {
      return (
        <div className="table table--hoverable table--primary table__body--primary pc-transactions-grid centi table--actionable table--overflow-visible">
          <div className="rowGroup>"></div>
          {getHeader}
          {getManual}
        </div>
      );
    }
    // For everything else, just render the manual transaction component.
    return (
      <div className="">
        <div className="rowGroup>"></div>
        {getManual}
      </div>
    );
  }

  getRow(props) {
    const {
      columns,
      categories,
      tags,
      onUpdateTransactions,
      onDeleteTransaction,
      isEditable,
      splitColumns,
      transactionCategoryRulesFlag,
      startDate,
      endDate,
      isAddManualOpen,
    } = this.props;
    const { transactionBeingEdited } = this.state;
    //If the Add Manual button is clicked, get the Manual Transaction component
    if (isAddManualOpen && !transactionBeingEdited) {
      return (
        <>
          {props.index === 0 ? this.getManualTransactionComponent() : null}
          <Row {...props} />
        </>
      );
    }
    if (
      transactionBeingEdited &&
      transactionBeingEdited.userTransactionId === props.data.userTransactionId
    ) {
      const EditRow = this.props.TransactionEditRow;
      if (isEmpty(transactionBeingEdited.splits)) {
        return (
          <EditRow
            {...props}
            columns={columns}
            categories={categories}
            tags={tags}
            model={transactionBeingEdited}
            onSaved={this.handleSavedTransaction}
            onClose={this.handleCloseEditor}
            onSubmit={onUpdateTransactions}
            onDeleteTransaction={onDeleteTransaction}
            isPrivileged={isEmpowerPrivilegedMode()}
            transactionCategoryRulesFlag={transactionCategoryRulesFlag}
            startDate={startDate}
            endDate={endDate}
          />
        );
      }

      // Render Row and the Split Your Transaction modal.
      return (
        <>
          <Row {...props} />
          <EditRow
            {...props}
            columns={columns}
            categories={categories}
            tags={tags}
            model={transactionBeingEdited}
            onSaved={this.handleSavedTransaction}
            onClose={this.handleCloseEditor}
            onSubmit={onUpdateTransactions}
            onDeleteTransaction={onDeleteTransaction}
            isPrivileged={isEmpowerPrivilegedMode()}
            transactionCategoryRulesFlag={transactionCategoryRulesFlag}
            startDate={startDate}
            endDate={endDate}
          />
        </>
      );
    }

    if (props.data.isDuplicate && this.props.renderDuplicates) {
      return (
        <>
          <Row
            {...props}
            className="pc-datagrid__row--duplicate-transaction transactions__label-duplicate qa-duplicated-transaction-row js-duplicated-transaction-row"
          />
          {props.data.imageUrl ? (
            <img
              className="is-hidden js-transaction-image-pre-fetcher"
              src={props.data.imageUrl}
              alt=""
            />
          ) : null}
        </>
      );
    }

    if (isEmpty(props.data.splits) || splitColumns == null) {
      return (
        <>
          <Row {...props} />
          {props.data.imageUrl ? (
            <img
              className="is-hidden js-transaction-image-pre-fetcher"
              src={props.data.imageUrl}
              alt=""
            />
          ) : null}
        </>
      );
    }

    // Do not show transaction row when all splits excluded.
    return isEmpty(
      props.data.splits.filter((s) => !s.excludeSplit && !s.filterSplit)
    ) ? null : (
      <div className="transaction-split qa-transaction-split">
        <Row
          {...props}
          className="transaction-split__parent qa-transaction-split__parent"
        />
        {/* Do not show an excluded split */}
        {props.data.splits.map((split) =>
          split.excludeSplit || split.filterSplit ? null : (
            <Row
              key={split.userTransactionId}
              {...props}
              className="qa-transaction-split__child"
              data={split}
              columns={splitColumns}
              onClick={
                isEditable
                  ? () => this.handleRowClick(null, props.data)
                  : undefined
              }
            />
          )
        )}
        {props.data.imageUrl ? (
          <img
            className="is-hidden js-transaction-image-pre-fetcher"
            src={props.data.imageUrl}
            alt=""
          />
        ) : null}
      </div>
    );
  }

  render() {
    let handleRowClick;
    let { className, isEditable } = this.props;

    // enables row clicks if the component is initialized as `editable` and we're not editing a row at the moment
    if (isEditable) {
      handleRowClick = this.handleRowClick;
      className += " table--actionable";
    }
    const { isAddManualOpen, transactions, transactionBeingEdited } =
      this.state;
    const numOfTransactions = transactions?.length;

    if (!numOfTransactions && isAddManualOpen && !transactionBeingEdited) {
      return this.getManualTransactionComponent(true);
    }

    return (
      <TransactionsGridTableClient
        {...this.props}
        disableTransactionsFilters={Boolean(this.state.transactionBeingEdited)}
        rowClassName={this.generateTransactionClassName}
        keyAccessor={TransactionsGrid.keyAccessor}
        data={this.state.transactions}
        onRowClick={handleRowClick}
        Row={this.getRow}
        tableClassName={`table--primary table__body--primary pc-transactions-grid centi ${
          className || ""
        }`}
        isFilteringEnabled={true}
      />
    );
  }
}

TransactionsGrid.propTypes = {
  transactions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  tags: PropTypes.array,
  renderDuplicates: PropTypes.bool,
  groupBy: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  headerRowClassName: PropTypes.string,
  onRowClick: PropTypes.func,
  onPageDataChanged: PropTypes.func,
  onTransactionSortAndOrFilter: PropTypes.func,
  onUpdateTransactions: PropTypes.func,
  onDeleteTransaction: PropTypes.func,
  columns: PropTypes.array,
  splitColumns: PropTypes.array,
  isEditable: PropTypes.bool,
  paginator: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  TransactionEditRow: PropTypes.elementType,
  transactionCategoryRulesFlag: PropTypes.bool,
  accountsListForManualTransactions: PropTypes.array,
  isAddManualOpen: PropTypes.bool,
  handleCloseManualTransactionContainer: PropTypes.func,
  onAddManualTransaction: PropTypes.func,
  ManualTransactionRow: PropTypes.elementType,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

TransactionsGrid.defaultProps = {
  columns: defaultColumns,
  splitColumns: defaultSplitColumns,
  tags: [],
  renderDuplicates: false,
  groupBy: undefined,
  className: undefined,
  headerRowClassName: undefined,
  onRowClick: undefined,
  onPageDataChanged: undefined,
  onTransactionSortAndOrFilter: undefined,
  onUpdateTransactions: noop,
  onDeleteTransaction: noop,
  onAddManualTransaction: noop,
  isEditable: false,
  paginator: undefined,
  TransactionEditRow: TransactionEditRow,
  accountsListForManualTransactions: undefined,
  isAddManualOpen: false,
  ManualTransactionRow: ManualTransactionRow,
  handleCloseManualTransactionContainer: noop,
  accountForManualTransaction: undefined,
};
