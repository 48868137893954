export const TRANSFER_TYPE_CONTRIBUTE = 1;
export const TRANSFER_TYPE_INTERNAL = 2;
export const TRANSFER_TYPE_WITHDRAW = 3;
export const TRANSFER_TYPE_CUSTOM = 4;

export const FREQUENCY_ONE_TIME = "ONE_TIME";
export const FREQUENCY_WEEKLY = "WEEKLY";
export const FREQUENCY_EVERY_OTHER_WEEK = "EVERY_OTHER_WEEK";
export const FREQUENCY_SEMI_MONTHLY = "SEMI_MONTHLY";
export const FREQUENCY_MONTHLY = "MONTHLY";
export const FREQUENCY_QUARTERLY = "QUARTERLY";
export const FREQUENCY_YEARLY = "YEARLY";

export const MODE_ONE_TIME = "ONE_TIME";
export const MODE_RECURRING = "RECURRING";

export const DAY_OF_WEEK_MONDAY = "MONDAY";
export const DAY_OF_WEEK_TUESDAY = "TUESDAY";
export const DAY_OF_WEEK_WEDNESDAY = "WEDNESDAY";
export const DAY_OF_WEEK_THURSDAY = "THURSDAY";
export const DAY_OF_WEEK_FRIDAY = "FRIDAY";

// the state of the the transfer instruction controls how the instruction looks like on UI.
export const STAGE_PENDING = "PENDING";
export const STAGE_INACTIVE = "INACTIVE";
export const STAGE_ACTIVE = "ACTIVE";
export const STAGE_ERROR = "ERROR";

// actions for ACTIVE state
export const ACTION_EDIT = "EDIT";
export const ACTION_DELETE = "DELETE";

// user-friendly error message. Returned when state == ”ERROR”.
export const ACTION_REENTER_ACCOUNT_NUMBER = "REENTER_ACCOUNT_NUMBER";
export const ACTION_VERIFY_MICRO_DEPOSIT = "VERIFY_MICRO_DEPOSIT";
export const ACTION_REACTIVATE = "REACTIVATE";

export const ACCOUNT_TYPE_PCC = "PCC";
export const ACCOUNT_TYPE_ONUS = "PCAP";
export const ACCOUNT_TYPE_EXTERNAL = "EXTERNAL";

export const DELIVERY_METHOD_DIRECT_DEPOSIT = "DIRECT_DEPOSIT";
export const DELIVERY_METHOD_WIRE_TRANSFER = "WIRE_TRANSFER";

export const OPTIONS_DAY_OF_WEEK = [
  { label: "Monday", value: DAY_OF_WEEK_MONDAY },
  { label: "Tuesday", value: DAY_OF_WEEK_TUESDAY },
  { label: "Wednesday", value: DAY_OF_WEEK_WEDNESDAY },
  { label: "Thursday", value: DAY_OF_WEEK_THURSDAY },
  { label: "Friday", value: DAY_OF_WEEK_FRIDAY },
];

export const OPTIONS_FREQUENCY = [
  { label: "One Time", value: FREQUENCY_ONE_TIME },
  { label: "Weekly", value: FREQUENCY_WEEKLY },
  { label: "Monthly", value: FREQUENCY_MONTHLY },
  { label: "Annually", value: FREQUENCY_YEARLY },
];

//values for deliveryMethod
export const DELIVERY_METHODS = [
  { label: "Direct Deposit", value: DELIVERY_METHOD_DIRECT_DEPOSIT },
  { label: "Wire Transfer", value: DELIVERY_METHOD_WIRE_TRANSFER },
];
