import React from "react";
import PropTypes from "prop-types";

const CollapsedOverview = ({ onToggleOverview }) => {
  return (
    <button
      className="pc-btn pc-btn--stripped u-preserve-case collapsed-overview"
      onClick={onToggleOverview}
    >
      <div className="collapsed-overview__content">
        <b>Get started with recommendations</b>
        <img
          src={`${window.staticUrl || ""}/static/img/icons/expand-more.svg`}
          alt="expand"
          className="collapsed-overview__toggle-icon icon-svg"
        />
      </div>
    </button>
  );
};

CollapsedOverview.propTypes = {
  onToggleOverview: PropTypes.func.isRequired,
};

CollapsedOverview.defaultProps = {};

export default CollapsedOverview;
