export const cryptoCoins = [
  "btc",
  "bitcoin",
  "eth",
  "ethereum",
  "doge",
  "shiba",
  "inu",
  "etc",
  "shib",
  "cardano",
  "ada",
  "stellar",
  "xlm",
  "litecoin",
  "ltc",
  "chainlink",
  "link",
  "safemoon",
  "coin",
  "solana",
  "sol",
  "maticnetwork",
  "matic",
  "bal",
  "algorand",
  "algo",
  "polkadot",
  "dot",
  "graph",
  "grt",
  "compound",
  "comp",
  "xrp",
  "usdcoin",
  "usdc",
  "usdt",
  "cosmos",
  "atom",
  "bitcoincash",
  "uniswap",
  "uni",
  "tezos",
  "xtz",
  "bch",
  "qi",
  "dai",
  "bnb",
  "mana",
  "avax",
  "cro",
  "luna",
  "xmr",
  "eos",
  "curv",
];

export const cryptoExchanges = [
  "coinbase",
  "binance",
  "kraken",
  "bittrex",
  "blockfi",
  "voyager",
  "gemini",
  "QI",
  "PancakeSwap",
  "Uniswap",
  "dydx",
  "sushiswap",
  "curve",
  "1inch",
  "quickswap",
  "0x",
  "polkaswap",
];

export const manualHome = ["home", "house"];
