import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "underscore";
import PerfectScrollbar from "react-perfect-scrollbar";

const DEFAULT_CONTAINER_CLASS_NAME = "scrollable-list__container";

class ScrollableList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isScrollable: false,
    };
  }

  componentDidMount() {
    this.updateIsScrollable();
  }

  componentDidUpdate() {
    this.updateIsScrollable();
  }

  updateIsScrollable() {
    const isScrollable = this.checkIsScrollable();
    if (isScrollable !== this.state.isScrollable) {
      this.setState({ isScrollable });
    }
    this.props.onScrollableUpdated?.(isScrollable);
  }

  checkIsScrollable() {
    if (this.scrollContainerRef) {
      return (
        this.scrollContainerRef.scrollHeight >
        this.scrollContainerRef.clientHeight
      );
    }

    return false;
  }

  render() {
    const {
      data,
      renderZeroState,
      renderListItem,
      className,
      listItemClassName,
    } = this.props;
    const { isScrollable } = this.state;

    if (isEmpty(data)) {
      return renderZeroState();
    }

    // Appending "--scrollable" to container class name so that
    // components can give a different treatment when there is a scrollbar on container
    let containerClassName = DEFAULT_CONTAINER_CLASS_NAME;
    if (className) {
      containerClassName += ` ${className}${
        isScrollable ? ` ${className}--scrollable` : ""
      }`;
    } else {
      containerClassName += isScrollable
        ? ` ${DEFAULT_CONTAINER_CLASS_NAME}--scrollable`
        : "";
    }

    return (
      <div className={containerClassName}>
        <PerfectScrollbar
          onScroll={this.props.onScroll}
          containerRef={(el) => {
            this.scrollContainerRef = el;
            this.props.getContainerRef?.(this.scrollContainerRef);
          }}
        >
          <ul
            className={`pc-u-ml0 pc-u-mb0 scrollable-list${
              isScrollable ? " pc-u-pr- pc-u-pl-" : ""
            }`}
          >
            {data.map((d) => {
              return (
                <li
                  key={d.id}
                  className={`scrollable-list__list-item ${listItemClassName}`}
                >
                  {renderListItem(d)}
                </li>
              );
            })}
          </ul>
        </PerfectScrollbar>
      </div>
    );
  }
}

ScrollableList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  renderZeroState: PropTypes.func.isRequired,
  renderListItem: PropTypes.func.isRequired,
  className: PropTypes.string,
  listItemClassName: PropTypes.string,
  getContainerRef: PropTypes.func,
  onScrollableUpdated: PropTypes.func,
  onScroll: PropTypes.func,
};

ScrollableList.defaultProps = {
  className: "",
  listItemClassName: "",
  getContainerRef: undefined,
  onScrollableUpdated: undefined,
  onScroll: undefined,
};

export default ScrollableList;
