import _ from "underscore";
import BaseStateModel from "stateModels/baseStateModel";
function StockOptonState() {
  this.baseUrl = "/stock-options";
  this.optionalUrlParams = ["ua"];
}

StockOptonState.prototype = new BaseStateModel();
StockOptonState.prototype.constructor = StockOptonState;

export default StockOptonState;
