/* eslint-disable default-case */
/* cSpell:disable */
import { findKey, isEmpty, isEqual, isObject, omit } from "underscore";
import { fullName } from "libs/pcap/utils/person";
import deepCopy from "deep-copy";

import {
  OPTION_DECIDE_LATER,
  OPTION_DO_IT_FOR_ME,
  OPTION_DO_IT_MYSELF,
  OPTION_HELP_ME_DO_IT,
  STEP_DECIDE_LATER,
  STEP_DO_IT_FOR_ME,
  STEP_DO_IT_MYSELF,
  STEP_HELP_ME,
  STEP_TARGET_DATE_FUND,
  STEP_RISK_BASED_FUND,
} from "../components/EnrollmentWizard/constants";
import {
  OWNERSHIP_TYPE_INDIVIDUAL,
  OWNERSHIP_TYPE_JOINT,
  OWNERSHIP_TYPE_JOINT_TOD,
  OWNERSHIP_TYPE_INDIVIDUAL_TOD,
} from "enums/ownershipTypes";
import AccountTypeMappers from "views/modules/enrollmentNew/utils/accountTypeMappers";
import memoizeOne from "memoize-one";
import toFieldDefinition from "accessors/formField/mappers/toClient";

const ROLLOVER_IRA_FIRMNAME = "Traditional IRA";
export const MONEY_TYPE_MAP = {
  ROLLOVER: ROLLOVER_IRA_FIRMNAME,
  ROTH: "Roth IRA",
  AFTERTAX: ROLLOVER_IRA_FIRMNAME,
  AFTERTAXONLY: ROLLOVER_IRA_FIRMNAME,
};

/**
 *
 * @param {Array} roles List of person roles
 * @returns {String} The list of person roles concatenated with a comma `,`
 */
export const setPersonRoles = (roles) => (roles ?? []).join(",");

/**
 *
 * @param {String} roles String of person roles concatenated with a comma `,`
 * @returns {Array} The list of roles as an Array
 */
export const getPersonRoles = (roles) => (roles ? roles.split(",") : []);

/**
 * Returns an array with the keys for the specified `values`
 * @param {Object} obj Object to find keys from
 * @param {Function} iteratee The truth test to apply
 * @returns {Array} List of keys
 */
export function findKeys(obj, iteratee) {
  return Object.entries(obj)
    .filter(([, v]) => iteratee(v))
    .map(([k]) => k);
}

export const findOwnerId = (owners) => {
  const ownerKey = findKey(owners, (roles) =>
    getPersonRoles(roles).includes("PRIMARY")
  );
  if (ownerKey) {
    return Number(ownerKey);
  }
  return null;
};

export const findSecondaryOwnerId = (owners) => {
  const ownerKey = findKey(owners, (roles) =>
    getPersonRoles(roles).includes("SECONDARY")
  );
  if (ownerKey) {
    return Number(ownerKey);
  }
  return null;
};

/**
 * Iterates through `obj1` and returns a new object with the values
 * that are different between them
 * @param {object} obj1 The object that comes with changes
 * @param {object} obj2 The second object
 * @returns {object} New object with the values that are different
 */

export function objectDiff(obj1, obj2) {
  return omit(obj1, (v, k) => {
    if (typeof v === "object") return isEqual(v, obj2[k]);
    return obj2[k] === v;
  });
}

export function getSourceAccountOwnersIds(sourceAccount) {
  const ids = findKeys(sourceAccount?.accountOwners, (roles) =>
    ["PRIMARY", "SECONDARY"].includes(roles)
  );
  return ids.map((id) => Number(id));
}

export function getOwnerByPersonRoles(
  personRoles = {},
  persons,
  hasRole = "PRIMARY"
) {
  const ownerIds = Object.entries(personRoles).filter(
    // eslint-disable-next-line no-unused-vars
    ([a, b]) => b === hasRole
  );

  return persons?.find((p) => {
    return ownerIds?.find(([ownerId]) => {
      return p.id === Number(ownerId);
    });
  });
}

export function getSourceAccountOwner(sourceAccount, persons) {
  const owners = sourceAccount?.accountOwners ?? sourceAccount?.owners;
  return getOwnerByPersonRoles(owners, persons);
}

export function getTargetAccountJointOwner(targetAccount, persons) {
  const owners = targetAccount?.enrollmentData?.personRoles;
  if (owners) {
    return getOwnerByPersonRoles(owners, persons, "SECONDARY");
  }
}

export function getTargetAccountOwner(targetAccount, persons) {
  return getOwnerByPersonRoles(
    targetAccount?.enrollmentData?.personRoles,
    persons
  );
}

export function formatName(name) {
  if (!name || name === "" || typeof name !== "string") return "";

  return name
    .split(" ")
    .map((n) => {
      const excludeWords = ["IRA", "SEP", "MMA"];
      if (excludeWords.includes(n)) return n;

      return n.charAt(0).toUpperCase() + n.substring(1).toLowerCase();
    })
    .join(" ");
}

const getAccountNameBySubtype = (
  accountType,
  accountTypeSubtype,
  ownershipType
) => {
  let accountName = "";
  switch (accountType) {
    case "RETIREMENT_IRA":
      if (accountTypeSubtype === "INHERITED_TRADITIONAL") {
        accountName = "Empower Premier Inherited Traditional IRA";
      } else if (accountTypeSubtype === "INHERITED_ROTH") {
        accountName = "Empower Premier Inherited Roth IRA";
      } else if (accountTypeSubtype === "SEP") {
        accountName = "Empower Premier SEP IRA";
      } else if (accountTypeSubtype === "ROTH") {
        accountName = "Empower Premier Roth IRA";
      } else {
        accountName = "Empower Premier Traditional IRA";
      }
      break;
    case "INVESTMENT_INVESTMENT":
      if (ownershipType === "INDIVIDUAL") {
        accountName = "Empower Premier Individual Investment Account";
      } else if (ownershipType === "JOINT") {
        accountName = "Empower Premier Joint Investment Account";
      } else if (ownershipType === "INDIVIDUAL_TOD") {
        accountName =
          "Empower Premier Individual Transfer on Death Investment Account";
      } else if (ownershipType === "JOINT_TOD") {
        accountName =
          "Empower Premier Joint Transfer on Death Investment Account";
      }
      break;
  }
  return accountName;
};

export function getAccountName(
  accountTypeGroup,
  accountTypeNew,
  accountTypeSubtype,
  ownershipType = ""
) {
  const subtypeName = accountTypeSubtype
    ? ` ${formatName(accountTypeSubtype)}`
    : "";
  const typeNewName = accountTypeNew ? ` ${formatName(accountTypeNew)}` : "";
  let accountName = `Empower Premier${subtypeName}${typeNewName}`;
  switch (accountTypeGroup) {
    case "RETIREMENT":
      if (accountTypeNew === "IRA") {
        accountName = getAccountNameBySubtype(
          "RETIREMENT_IRA",
          accountTypeSubtype,
          ownershipType
        );
      }
      break;
    case "INVESTMENT":
      if (accountTypeNew === "INVESTMENT") {
        accountName = getAccountNameBySubtype(
          "INVESTMENT_INVESTMENT",
          accountTypeSubtype,
          ownershipType
        );
      }
      break;
    case "TRUST":
      accountName = "Empower Premier Trust Investment Account";
      break;
    case "CUSTODIAL":
      accountName = "Empower Premier Custodial Investment Account";
      break;
    case "ESTATE":
      accountName = "Empower Premier Estate Investment Account";
      break;
    case "CONSERVATORSHIP":
      accountName = "Empower Premier Conservatorship Investment Account";
      break;
    case "GUARDIANSHIP":
      accountName = "Empower Premier Guardianship Investment Account";
      break;
  }

  return accountName;
}

export function targetAccountFormat(targetAccount) {
  const accountInfo = targetAccount?.enrollmentData?.accountInfo ?? {};

  return {
    name: accountInfo?.title?.line2,
    firmName: accountInfo?.title?.line1,
    balance:
      accountInfo?.fundingSecuritiesTransferSecurities === "F"
        ? accountInfo?.fundingSecuritiesTransferSecuritiesAmount
        : accountInfo?.fundingSecuritiesTransferSecuritiesPartialAmount,
  };
}

export function targetAccountIntroFormat(targetAccount) {
  return {
    name: targetAccount?.enrollmentData?.accountInfo?.title?.line2 ?? "",
    firmName: targetAccount?.enrollmentData?.accountInfo?.title?.line1 ?? "",
  };
}
export function targetAccountOptionFormat(targetAccount) {
  const accountInfo = targetAccount?.enrollmentData?.accountInfo ?? {};

  return {
    userAccountId: targetAccount?.enrollAccountId ?? 0,
    name: accountInfo?.title?.line2,
    firmName: accountInfo?.title?.line1,
    balance:
      accountInfo?.fundingSecuritiesTransferSecurities === "F"
        ? accountInfo?.fundingSecuritiesTransferSecuritiesAmount
        : accountInfo?.fundingSecuritiesTransferSecuritiesPartialAmount,
  };
}

export function getNewTargetAccounts(sourceAccount, persons) {
  let enrollmentAccounts = [];
  if (sourceAccount.targetAccountsData) {
    const owner = getSourceAccountOwner(sourceAccount, persons);
    sourceAccount.targetAccountsData.forEach((type, index) => {
      enrollmentAccounts.push({
        enrollmentData: {
          accountInfo: {
            ownershipType: sourceAccount.ownershipType || "INDIVIDUAL",
            title: {
              line1: `Empower Premier ${
                MONEY_TYPE_MAP[type.fundingType] || ""
              }`,
              line2: fullName(owner),
            },
            accountTypeGroup: type?.accountGroup,
            accountOptionLabel: type.fundingType,
            accountType: type.fundingType,
            accountTypeNew: type?.accountTypeNew,
            accountTypeSubtype: type?.accountTypeSubtype,
            fundingSecuritiesTransferSecuritiesAmount: type?.balance,
            fundingSecuritiesTransferSecurities: "F",
          },
          personRoles: {
            [owner.id]: "PRIMARY",
          },
        },
        enrollAccountId: index,
        options: type.options,
      });
    });
  }
  if (sourceAccount.accountTypeGroup === "BANK") {
    enrollmentAccounts.push({
      enrollmentData: {
        accountInfo: {
          accountOptionLabel: sourceAccount.accountTypeNew,
          // Removed constant after spanish translation bug
          accountTypeNew: "INVESTMENT",
          accountTypeGroup: "INVESTMENT",
          fundingSecuritiesTransferSecuritiesAmount: sourceAccount.balance,
          fundingSecuritiesTransferSecurities: "P",
          ownershipType: sourceAccount.ownershipType || "INDIVIDUAL",
        },
      },
      enrollAccountId: 0,
    });
  }

  return enrollmentAccounts;
}

export function getBankTargetAccountFromOwnership(
  targetAccounts,
  persons,
  owners,
  sourceAccount,
  features
) {
  const targetAccount = deepCopy(targetAccounts[0]);
  const data = targetAccount.enrollmentData;
  let ownershipType = sourceAccount.ownershipType || "INDIVIDUAL";

  if (
    (ownershipType === OWNERSHIP_TYPE_JOINT &&
      !features?.WEB_TAXABLE_JOINT_ROLLOVER) ||
    (ownershipType === OWNERSHIP_TYPE_JOINT_TOD &&
      !features?.WEB_TAXABLE_JOINT_TOD_ROLLOVER) ||
    (ownershipType === OWNERSHIP_TYPE_INDIVIDUAL_TOD &&
      !features?.WEB_TAXABLE_INDIVIDUAL_TOD_ROLLOVER)
  ) {
    ownershipType = OWNERSHIP_TYPE_INDIVIDUAL;
  }

  const accountInfo = data.accountInfo;
  const {
    accountTypeGroup = "",
    accountTypeNew = "",
    accountTypeSubtype = "",
  } = accountInfo;
  const name = getAccountName(
    accountTypeGroup,
    accountTypeNew,
    accountTypeSubtype,
    ownershipType
  );
  const owner = getOwnerByPersonRoles(owners, persons);

  const accountType = ownershipType;

  targetAccount.enrollmentData = {
    ...data,
    personRoles: owners,
    accountInfo: {
      ...data.accountInfo,
      accountType,
      ownershipType,
      title: {
        line1: name,
        line2: fullName(owner),
      },
    },
  };

  if (ownershipType === "JOINT") {
    targetAccount.enrollmentData.accountInfo.jointTenancyState =
      owner?.address?.state || "";
  }

  return [targetAccount];
}

/**
 * Creates a dictionary of persons from the list given to optimize a person search by id
 * @param {Array} persons The list of persons to transform
 * @returns {object} A dictionary of persons with the form `{id: personObj}`
 */
export function getPersonsDictionaryFromList(persons) {
  return deepCopy(persons).reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {}
  );
}

export function formatEnrollmentInfo(enrollmentInfo) {
  return {
    enrollAccountId: enrollmentInfo.enrollmentID,
    enrollmentData: enrollmentInfo,
  };
}

export function formatAccountIndexToString(accountIndex) {
  let ordinalNumb;
  switch (accountIndex) {
    case 0:
      ordinalNumb = "the first";
      break;
    case 1:
      ordinalNumb = "the second";
      break;
    default:
      ordinalNumb = "this";
      break;
  }
  return ordinalNumb;
}

export function formatInvestmentToSever(targetAccount, investmentProfile) {
  const investment = {
    ...investmentProfile,
    finraEmployerName: investmentProfile.finraEmployerName ?? "",
  };

  if (!investmentProfile?.profileAccountId) {
    investment.enrollmentId = targetAccount.enrollAccountId;
  }

  if (investmentProfile.isFinraEmployer === null) {
    delete investment.isFinraEmployer;
  }

  return investment;
}

export const formatTargetAccountToFunding = (
  account,
  ownerId,
  fundingType = "SECURITIES"
) => {
  const fundingTransferAmount = parseFloat(
    account.enrollmentData.accountInfo.fundingSecuritiesTransferSecurities ===
      "F"
      ? account?.enrollmentData?.accountInfo
          ?.fundingSecuritiesTransferSecuritiesAmount
      : account?.enrollmentData?.accountInfo
          ?.fundingSecuritiesTransferSecuritiesPartialAmount
  );
  let fundingObject = {
    enrollAccountId: account.enrollAccountId,
    fundingSourceId: ownerId,
    fundingInfo: {
      fundingType,
      fundingSecuritiesTransferSecuritiesAmount: fundingTransferAmount,
      fundingSecuritiesTransferSecurities:
        account.enrollmentData.accountInfo.fundingSecuritiesTransferSecurities,
      fundingSourceUserAccountId: ownerId,
      fundingTransferAmount: fundingTransferAmount,
    },
  };
  if (
    account?.enrollmentData?.fundingIds &&
    account?.enrollmentData?.fundingIds.length
  ) {
    fundingObject.fundingInfo.fundingId = account.enrollmentData.fundingIds[0];
  }
  return fundingObject;
};

/**
 * Use the UI fields returned from Preferences v2 service to map the properties that need to be
 * provided for payload enrollment/save action.
 *
 * @param {Object} person - The object where data will be taken.
 * @param {Array} formPrompts - The array where UI field key properties will be taken
 * @returns {Object} - The specific form data object mapped.
 */
function mapPersonByFields(person = {}, formPrompts = []) {
  const mappedPerson = {};
  const cleankeyList = [
    ...new Set(
      formPrompts.map((field) => field?.parts.map((i) => i?.name)).flat()
    ),
  ]; // map every field name property and remove any duplicated keys
  const hasBirthdayKey = cleankeyList.some((key) => key.includes("birthday"));
  const hasAddressKey = cleankeyList.some((key) => key.includes("address"));
  const hasPercentage = cleankeyList.some((key) => key.includes("percentage"));
  const fieldKeyList = hasAddressKey
    ? cleankeyList.filter((key) => !key.includes("address"))
    : cleankeyList;

  if (hasAddressKey) {
    mappedPerson.address = person?.address;
  }

  if (hasBirthdayKey) {
    mappedPerson.birthday = person?.birthday;
  }

  fieldKeyList.forEach((key) => {
    if (key === "personId") {
      mappedPerson[key] = person?.personId || person?.id;
    } else {
      mappedPerson[key] = person[key];
    }
  });

  if (person?.percentage && hasPercentage) {
    mappedPerson.percentage = person?.percentage;
  }

  if (hasAddressKey && person?.address?.postalCode) {
    mappedPerson.address.postalCode =
      person?.address?.postalCode &&
      person?.address?.postalCode.replace(/[ -]/g, "");
  }

  return mappedPerson;
}

/**
 * Returns a cleaned version of an object.
 * Removing "" - undefined - null values.
 * @param {Object} obj - The object to be modified.
 * @returns {Object} - The cleaned version.
 */
export function removeEmpty(obj) {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        removeEmpty(obj[key]);
      }
      if (obj[key] === "" || obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    }
  }
  return obj;
}

export function formatContactsToServer(person, formPrompts = []) {
  if (
    (!formPrompts && formPrompts.length === 0) ||
    (!person && isEmpty(person))
  )
    return {};
  const formatted = mapPersonByFields(person, formPrompts);
  return removeEmpty(formatted);
}

export function formatAccountsToDetails(
  sourceAccount,
  targetAccount,
  investmentChoice
) {
  const { accountInfo } = targetAccount.enrollmentData;
  let formatInvestmentChoice;

  switch (investmentChoice) {
    case STEP_DECIDE_LATER:
    case OPTION_DECIDE_LATER:
      formatInvestmentChoice = "Decide later";
      break;
    case STEP_DO_IT_FOR_ME:
    case OPTION_DO_IT_FOR_ME:
      formatInvestmentChoice = "My Total Retirement™";
      break;
    case STEP_HELP_ME:
    case STEP_TARGET_DATE_FUND:
    case STEP_RISK_BASED_FUND:
    case OPTION_HELP_ME_DO_IT:
      formatInvestmentChoice = "Help me do it";
      break;
    case STEP_DO_IT_MYSELF:
    case OPTION_DO_IT_MYSELF:
      formatInvestmentChoice = "Do it myself";
      break;
    default:
      formatInvestmentChoice = "";
      break;
  }

  return {
    sourceAccount: {
      name: sourceAccount.firmName,
      account: sourceAccount.name,
    },
    targetAccount: {
      name: accountInfo.title.line1,
      account: accountInfo.title.line2,
    },
    fundingDetails:
      accountInfo?.fundingSecuritiesTransferSecurities === "F"
        ? accountInfo?.fundingSecuritiesTransferSecuritiesAmount
        : accountInfo?.fundingSecuritiesTransferSecuritiesPartialAmount,
    investmentChoice: formatInvestmentChoice,
  };
}

export function accountTypeLabelFormat(accountType) {
  switch (accountType) {
    case "ROLLOVER":
      return "pre-tax balance";
    case "ROTH":
      return "roth balance";
    case "AFTERTAXONLY":
      return "after tax contributions";
    case "AFTERTAX":
      return "roth balance and after tax contributions";
    case "CHECKING":
      return "checking balance";
    case "PRETAX":
      return "pre-tax balance";
    case "PREAFTERTAX":
      return "pre-tax and after tax contributions";
    default:
      return "balance";
  }
}

export function accountTypeAmountLabelFormat(accountType) {
  switch (accountType) {
    case "ROLLOVER":
      return "pre-tax amount";
    case "ROTH":
      return "roth amount";
    case "AFTERTAXONLY":
      return "after tax amount";
    case "AFTERTAX":
      return "roth amount and after tax amount";
    case "CHECKING":
      return "checking amount";
    default:
      return "";
  }
}

export function enrollmentCipVerification(cipVerifications) {
  let cipIdentityFail = [];
  if (!cipVerifications) return cipIdentityFail;

  cipIdentityFail = cipVerifications.filter((cip) => cip.legalDocumentNeeded);

  return cipIdentityFail;
}

export function findPersonById(persons, personId) {
  return persons.find(({ id }) => id === personId);
}

export function formattedCipFails(cipVerifications, persons) {
  if (!cipVerifications && !persons) return [];

  let cipFails = enrollmentCipVerification(cipVerifications);

  return cipFails.map((p) => {
    const { errors } = p;
    const person = findPersonById(persons, p.personId);
    return person ? { name: fullName(person), errors } : null;
  });
}

/* eslint-disable sonarjs/cognitive-complexity */
export function getTargetAccountsFromMoneyOut(data, owner, sourceAccount) {
  let accounts = [];

  const payToSelfNonRothAmt =
    !data.payToSelfNonRothAmt || isNaN(data.payToSelfNonRothAmt)
      ? 0
      : data.payToSelfNonRothAmt;
  const payToSelfRothAmt =
    !data.payToSelfRothAmt || isNaN(data.payToSelfRothAmt)
      ? 0
      : data.payToSelfRothAmt;
  const hierarchyPayToSelfAmt =
    !data.hierarchyPayToSelfAmt || isNaN(data.hierarchyPayToSelfAmt)
      ? 0
      : data.hierarchyPayToSelfAmt;
  const balancesByMoneyTypePostTax = sourceAccount?.balancesByMoneyType?.find(
    (b) => b.accountType === "ROTH"
  );
  const balancesByMoneyTypePreTax = sourceAccount?.balancesByMoneyType?.find(
    (b) => b.accountType === "ROLLOVER"
  );
  let nonRothAmount = data.nonRothRolloverAmt;
  let rothAmount = data.rothRolloverAmt;
  if (balancesByMoneyTypePostTax) {
    if (
      payToSelfRothAmt > 0 ||
      (data.accountToOpenIndicator === "B" && !rothAmount)
    ) {
      rothAmount = data.maxAmtIndRoth
        ? 0
        : balancesByMoneyTypePostTax.balance - payToSelfRothAmt;
    } else if (data.payToSelfAfterTaxBasisInd === "Y" || !rothAmount) {
      rothAmount = balancesByMoneyTypePostTax.balance;
    }
  }
  if (balancesByMoneyTypePreTax) {
    if (
      payToSelfNonRothAmt > 0 ||
      ((data.accountToOpenIndicator === "B" ||
        data.accountToOpenIndicator === "T") &&
        !nonRothAmount)
    ) {
      nonRothAmount = data.maxAmtIndNonRoth
        ? 0
        : balancesByMoneyTypePreTax.balance - payToSelfNonRothAmt;
    } else if (data.payToSelfAfterTaxBasisInd === "Y" || !nonRothAmount) {
      nonRothAmount = balancesByMoneyTypePreTax.balance;
    }
  }
  if (data.hierarchyPlanInd === "Y") {
    if (data.accountToOpenIndicator === "T") {
      accounts.push({
        balance: data.hierarchyRolloverAmt - hierarchyPayToSelfAmt,
        fullBalance: data.maxAmtIndNonRoth,
        type: "ROLLOVER",
      });
    } else if (data.accountToOpenIndicator === "R") {
      accounts.push({
        balance: data.hierarchyRolloverAmt - hierarchyPayToSelfAmt,
        fullBalance: data.maxAmtIndRoth,
        type: "ROTH",
      });
    } else if (data.accountToOpenIndicator === "B") {
      accounts.push(
        {
          balance: balancesByMoneyTypePreTax.balance,
          fullBalance: data.maxAmtIndNonRoth,
          type: "ROLLOVER",
        },
        {
          balance: balancesByMoneyTypePostTax.balance,
          fullBalance: data.maxAmtIndRoth,
          type: "ROTH",
        }
      );
    }
  } else {
    if (
      data.accountToOpenIndicator === "B" ||
      data.accountToOpenIndicator === "R"
    ) {
      accounts.push({
        balance: rothAmount,
        fullBalance: data.maxAmtIndRoth,
        type: "ROTH",
      });
    }
    if (
      data.accountToOpenIndicator === "B" ||
      data.accountToOpenIndicator === "T"
    ) {
      accounts.push({
        balance: nonRothAmount,
        fullBalance: data.maxAmtIndNonRoth,
        type: "ROLLOVER",
      });
    }
  }

  const createTargetAccount = ({ balance, fullBalance, type }, index) => {
    if (!balance) return null;
    const firstName = owner?.name?.firstName ?? "";
    const middleName = owner?.name?.middleName
      ? ` ${owner?.name?.middleName}`
      : "";
    const lastName = owner?.name?.lastName ? ` ${owner?.name?.lastName}` : "";

    return {
      enrollAccountId: index,
      enrollmentData: {
        accountInfo: {
          title: {
            line1: `Empower Premier ${MONEY_TYPE_MAP[type] ?? ""}`,
            line2: `${firstName}${middleName}${lastName}`,
          },
          accountType: type,
          accountTypeNew: "IRA",
          accountTypeGroup: "RETIREMENT",
          accountTypeSubtype: type,
          accountOptionLabel: type,
          fundingSecuritiesTransferSecuritiesAmount: balance,
          fundingSecuritiesTransferSecurities: fullBalance ? "F" : "P",
        },
        personRoles: {
          [owner.id]: "PRIMARY",
        },
      },
    };
  };

  return accounts
    .map(createTargetAccount)
    .filter((account) => Boolean(account));
}

export function enrollmentMoneyOutInfo(gaId, indId) {
  if (
    !location.hostname.includes("retirementpartner.com") &&
    !location.hostname.includes("empower-retirement.com")
  ) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const moneyOutUrl = `/participant-web-services/rest/distribution/pcapHoldGetDataInSession?gaId=${gaId}&indId=${indId}`;
    $.ajax({
      error: (_, __, err) => reject(err),
      success: (res) => resolve(res),
      type: "GET",
      url: moneyOutUrl,
    });
  });
}

export function mapEnrollmentsToTargetAccounts(
  targetAccounts,
  enrollments = [],
  sourceAccount = {}
) {
  return targetAccounts.map((account) => {
    const { accountTypeNew, accountTypeGroup, accountTypeSubtype } =
      // eslint-disable-next-line no-unsafe-optional-chaining
      account?.enrollmentData?.accountInfo;

    // order enrollments by id instead date because date has no complete tme
    enrollments = enrollments.sort(
      (a, b) => new Date(b.enrollmentID) - new Date(a.enrollmentID)
    );

    const enrollment = deepCopy(enrollments).find((enroll) => {
      const newTarget = enroll?.accountInfo ?? {};

      return (
        newTarget.accountTypeNew === accountTypeNew &&
        newTarget.accountTypeGroup === accountTypeGroup &&
        (accountTypeSubtype
          ? newTarget.accountTypeSubtype === accountTypeSubtype
          : true)
      );
    });

    if (enrollment) {
      const { accountInfo } = enrollment;
      delete enrollment.accountInfo;
      account.enrollAccountId = enrollment.enrollmentID;
      account.enrollmentData = { ...account.enrollmentData, ...enrollment };
      account.enrollmentData.accountInfo = {
        ...accountInfo,
        ...account.enrollmentData.accountInfo,
      };

      if (enrollment?.fundingIds?.length > 0) {
        account.enrollmentData.fundingInfo = {
          fundingId: enrollment.fundingIds[0],
        };
      }
    } else {
      const enrollmentInProgress = enrollments.find(
        (enrollment) => enrollment.status === "IN_PROGRESS"
      );
      const hasEnrollmentInProgress = !isEmpty(enrollmentInProgress);

      if (hasEnrollmentInProgress) {
        delete account.enrollAccountId;
      }
    }

    if (sourceAccount?.accountTypeGroup === "BANK") {
      //setting partial amount for bank accounts
      account.accountPartialAmount = 0;
      account.enrollmentData.accountInfo.fundingSecuritiesTransferSecurities =
        "P";
      account.enrollmentData.accountInfo.fundingSecuritiesTransferSecuritiesPartialAmount =
        "0.00";
    }

    return account;
  });
}

export function formatProfileDetailsToServer(
  profileDetails,
  isEmployed,
  isTargetAccountTaxable
) {
  const profileDetailsState = deepCopy(profileDetails);
  const { politicallyExposedPersonName } = profileDetailsState;
  profileDetailsState.politicallyExposedPersonFirstName =
    politicallyExposedPersonName?.firstName;
  profileDetailsState.politicallyExposedPersonLastName =
    politicallyExposedPersonName?.lastName;
  profileDetailsState.politicallyExposedPersonSuffix =
    politicallyExposedPersonName?.suffix;

  const emptyBd = {
    employeeThis: "N",
    employeeAnother: "N",
    employeeRelatedThis: "N",
    employeeRelatedAnother: "N",
  };

  const profileDetailsCleanAttr = [
    "affiliatedWithBroker",
    "employeeCheck",
    "employeeRelatedCheck",
    "politicallyExposedPersonName",
  ];

  if (!isEmployed && !isTargetAccountTaxable) {
    profileDetailsState.affiliatedWithBroker = false;
  }

  const optionBoth = profileDetailsState?.affiliatedWithBroker === "B";
  const optionIndividual = profileDetailsState?.affiliatedWithBroker === "I";
  const optionFamiliar = profileDetailsState?.affiliatedWithBroker === "F";
  const optionNone = profileDetailsState?.affiliatedWithBroker === "N";

  const bdDetails =
    profileDetailsState?.affiliatedWithBroker || !optionNone
      ? profileDetailsState?.bdDetails || {}
      : emptyBd;

  bdDetails.employeeAnotherName = bdDetails?.employeeAnotherName ?? "";
  bdDetails.employeeRelatedAnotherName =
    bdDetails?.employeeRelatedAnotherName ?? "";

  switch (true) {
    case optionIndividual || optionBoth:
      bdDetails.employeeThis = bdDetails.employeeAnother === "Y" ? "N" : "Y";
      if (optionIndividual) {
        bdDetails.employeeRelatedThis = "N";
        bdDetails.employeeRelatedAnother = "N";
      }
      break;
    case optionFamiliar || optionBoth:
      bdDetails.employeeRelatedThis =
        bdDetails.employeeRelatedAnother === "Y" ? "N" : "Y";
      if (optionFamiliar) {
        bdDetails.employeeThis = "N";
        bdDetails.employeeAnother = "N";
      }
      break;
    default:
      break;
  }

  if (!profileDetailsState?.employeeCheck) {
    bdDetails.employeeThis = "N";
    bdDetails.employeeAnother = "N";
  }

  if (!profileDetailsState?.employeeRelatedCheck) {
    bdDetails.employeeRelatedThis = "N";
    bdDetails.employeeRelatedAnother = "N";
  }

  if (!profileDetailsState?.isShareHolder) {
    profileDetailsCleanAttr.push("shareHolderName");
  }

  if (!profileDetailsState?.isFinraEmployee) {
    profileDetailsCleanAttr.push("finraEmployeeName");
  }

  if (bdDetails?.employeeAnother === "N") {
    delete bdDetails.employeeAnotherName;
  }

  if (bdDetails?.employeeRelatedAnother === "N") {
    delete bdDetails.employeeRelatedAnotherName;
  }

  const profileDetailsClean = omit(
    profileDetailsState,
    profileDetailsCleanAttr
  );

  profileDetailsClean.bdDetails = bdDetails;

  return profileDetailsClean;
}

export function ssnStringToSSNObject(ssn) {
  let formattedSSN = {};
  const formattedTaxID = ssn.includes("-")
    ? ssn.split("-")
    : // eslint-disable-next-line no-magic-numbers
      [ssn.slice(0, 3), ssn.slice(4, 6), ssn.slice(-4)];
  formattedTaxID.forEach((num, i) => (formattedSSN[`ssn${i + 1}`] = num));
  return formattedSSN;
}

export function parseAccountTypes(types) {
  if (!types) {
    return {};
  }

  let accountGroupsByProductType =
    AccountTypeMappers.mapChildrenByParentValue(types);
  let accountGroups = accountGroupsByProductType.INVESTMENT;

  let accountTypesByGroup =
    AccountTypeMappers.mapChildrenByParentValue(accountGroups);
  let accountSubTypesByType =
    AccountTypeMappers.mapAccountSubTypesByType(accountGroups);

  return {
    accountGroups,
    accountTypesByGroup,
    accountSubTypesByType,
  };
}

export function targetAccountsToOptions(targetAccounts) {
  if (!targetAccounts || targetAccounts.length === 0) {
    return [];
  }

  return targetAccounts.map((t, i) => {
    return {
      value: t?.enrollAccountId || i,
      name: t.enrollmentData.accountInfo?.title?.line2,
      firmName: t.enrollmentData.accountInfo?.title?.line1,
    };
  });
}

export function getInvestmentLinkProps(
  isAdvisor,
  targetRecordKeeper,
  isJoint = false,
  enrollmentId = false,
  firmName = "",
  isIRA = false,
  isInvestment = false
) {
  let href;
  const target = "_blank";

  const showInvestmentResearch =
    isIRA || !targetRecordKeeper || targetRecordKeeper === "EASY";
  const showInvestmentResearchEPIA = isJoint || isInvestment;
  const showAdvisorInvestmentLineup =
    isAdvisor && targetRecordKeeper === "PERSHING";

  switch (true) {
    case showInvestmentResearch:
      href =
        "https://ira.empower-retirement.com/participant/#/articles/MYERIRA/investmentResearch";
      break;
    case showInvestmentResearchEPIA:
      href =
        "https://ira.empower-retirement.com/participant/#/articles/MYERIRA/investmentResearchEPIA";
      break;
    case showAdvisorInvestmentLineup:
      href = enrollmentId
        ? `/epadvisor/page/agentlogin/investmentLineup?eid=${enrollmentId}&firmName=${firmName}`
        : "/epadvisor/page/agentlogin/investmentLineup";
      break;
    default:
      href = enrollmentId
        ? `#/investment-lineup?eid=${enrollmentId}&firmName=${firmName}`
        : "#/investment-lineup";
      break;
  }

  return { href, target };
}

export function getOptionLabel(contributionType = [], subtype) {
  let optionLabel = subtype;
  let updateContributionTypes = deepCopy(contributionType);

  const hasAllTypes =
    contributionType.includes("AFTERTAX") &&
    contributionType.includes("PRETAX") &&
    contributionType.includes("ROTH");

  switch (subtype) {
    case "INVESTMENT":
      optionLabel = subtype;
      break;
    case "ROLLOVER":
      if (hasAllTypes) {
        updateContributionTypes = ["AFTERTAX", "PRETAX"];
      }
      if (
        !updateContributionTypes.includes("AFTERTAX") &&
        updateContributionTypes.includes("PRETAX")
      ) {
        optionLabel = subtype;
      }
      if (
        updateContributionTypes.includes("AFTERTAX") &&
        !updateContributionTypes.includes("PRETAX")
      ) {
        optionLabel = "AFTERTAXONLY";
      }
      if (
        updateContributionTypes.includes("PRETAX") &&
        updateContributionTypes.includes("AFTERTAX")
      ) {
        optionLabel = "PREAFTERTAX";
      }
      break;
    case "ROTH":
      if (hasAllTypes) {
        updateContributionTypes = ["ROTH"];
      }
      if (updateContributionTypes.includes("ROTH")) {
        optionLabel = subtype;
      }
      break;
    default:
      optionLabel = "";
      break;
  }
  return optionLabel;
}

export function getTargetAccountsForExternal(sourceAccount, persons, types) {
  const {
    accountTypesByGroup = [],
    accountGroups = [],
    accountSubTypesByType = [],
  } = parseAccountTypes(types);
  const owners = sourceAccount?.owners;
  const selfOwner = persons?.find((p) => p.relationship === "SELF");
  const contributionType =
    sourceAccount?.additionalAttributes?.contributionType;
  let enrollmentAccounts = [];
  if (accountGroups && accountGroups.length) {
    accountGroups.forEach((group) => {
      const accountTypeGroup = group.value;
      const accountTypes = accountTypesByGroup[accountTypeGroup];
      if (accountTypes && accountTypes.length) {
        accountTypes.forEach((type, accTypeIndex) => {
          const accountTypeNew = type.value;
          let accountSubTypes = accountSubTypesByType[accountTypeNew];

          const mapTargetAccount = (subType, index, balance) => {
            return {
              enrollmentData: {
                accountInfo: {
                  accountOptionLabel: getOptionLabel(
                    contributionType,
                    subType.value
                  ),
                  accountTypeNew,
                  accountTypeGroup,
                  accountTypeSubtype: subType.value,
                  ownershipType: sourceAccount.ownershipType || "INDIVIDUAL",
                  title: {
                    line1: getAccountName(
                      accountTypeGroup,
                      accountTypeNew,
                      subType.value,
                      sourceAccount.ownershipType || "INDIVIDUAL"
                    ),
                    line2: fullName(selfOwner),
                  },
                  fundingSecuritiesTransferSecuritiesAmount: balance,
                  fundingSecuritiesTransferSecurities: "F",
                },
                personRoles: { [selfOwner.id]: "PRIMARY" },
              },
              enrollAccountId: index,
            };
          };

          if (accountSubTypes && accountSubTypes.length) {
            accountSubTypes.forEach((subType, index) => {
              let balance = sourceAccount.balance;
              if (!sourceAccount.isEmpower && accountSubTypes.length === 2) {
                balance = balance / 2;
              }
              enrollmentAccounts.push(
                mapTargetAccount(subType, index, balance)
              );
            });
          }

          if (!accountSubTypes) {
            let balance = sourceAccount.balance;
            const mappedTargetAccount = mapTargetAccount(
              "",
              accTypeIndex,
              balance
            );
            mappedTargetAccount.enrollmentData.accountOptionLabel =
              getOptionLabel(contributionType, accountTypeGroup);

            if (
              mappedTargetAccount?.enrollmentData?.accountInfo
                ?.ownershipType === "JOINT"
            ) {
              mappedTargetAccount.enrollmentData.accountInfo.jointTenancyState =
                selfOwner?.address?.state || "";

              mappedTargetAccount.enrollmentData.personRoles =
                sourceAccount?.ownershipType.includes("INDIVIDUAL")
                  ? { [selfOwner.id]: "PRIMARY" }
                  : owners;
            }

            enrollmentAccounts.push(mappedTargetAccount);
          }
        });
      }
    });
  }

  return enrollmentAccounts;
}

export function mergeTargetAccountsWithoutDuplicates(
  firstTargets = [],
  secondTargets = [],
  includeOptionLabel = false
) {
  if (firstTargets.length === 0) return secondTargets;
  if (secondTargets.length === 0) return firstTargets;

  let mergedTargets = [];
  const targets = deepCopy(firstTargets).concat(deepCopy(secondTargets));

  let updatedOwnershipType, updatedLine, updatedPersonRoles;

  const updatedTypeGroup =
    secondTargets[0]?.enrollmentData?.accountInfo?.accountTypeGroup;
  const updateData =
    updatedTypeGroup === "INVESTMENT" || updatedTypeGroup === "BANK";

  if (
    updateData &&
    secondTargets[0]?.enrollmentData?.accountInfo?.ownershipType &&
    secondTargets[0]?.enrollmentData?.accountInfo?.title?.line1
  ) {
    updatedOwnershipType =
      secondTargets[0]?.enrollmentData?.accountInfo?.ownershipType;
    updatedLine = secondTargets[0]?.enrollmentData?.accountInfo?.title?.line1;
  }

  if (updateData && secondTargets[0]?.enrollmentData?.personRoles) {
    updatedPersonRoles = secondTargets[0]?.enrollmentData?.personRoles;
  }

  targets.forEach((target) => {
    const {
      accountTypeNew,
      accountTypeGroup,
      accountTypeSubtype,
      accountOptionLabel,
      title,
      fundingSecuritiesTransferSecuritiesAmount,
    } = target?.enrollmentData?.accountInfo ?? {};

    const account = mergedTargets.find((t) => {
      const newTarget = t?.enrollmentData?.accountInfo ?? {};
      return (
        newTarget.accountOptionLabel === accountOptionLabel &&
        newTarget.accountTypeNew === accountTypeNew &&
        newTarget.accountTypeGroup === accountTypeGroup &&
        newTarget.accountTypeSubtype === accountTypeSubtype &&
        newTarget.title?.line2 === title?.line2 &&
        Number(newTarget.fundingSecuritiesTransferSecuritiesAmount) ===
          Number(fundingSecuritiesTransferSecuritiesAmount)
      );
    });

    if (
      !account &&
      (!includeOptionLabel ||
        (includeOptionLabel &&
          target?.enrollmentData?.accountInfo?.accountOptionLabel &&
          target?.enrollmentData?.accountInfo?.accountOptionLabel !== ""))
    ) {
      // Update some data if accounts are different just in title and ownership (Only for bank and investment accounts)
      const accountInfo = target?.enrollmentData?.accountInfo;
      const personRoles = target?.enrollmentData?.personRoles;

      if (
        accountInfo?.accountTypeGroup === "INVESTMENT" ||
        accountInfo?.accountTypeGroup === "BANK"
      ) {
        if (
          accountInfo?.ownershipType !== updatedOwnershipType &&
          accountInfo?.title?.line1 !== updatedLine
        ) {
          target.enrollmentData.accountInfo.ownershipType =
            updatedOwnershipType;
          target.enrollmentData.accountInfo.title.line1 = updatedLine;
        }

        if (!isEqual(personRoles, updatedPersonRoles)) {
          target.enrollmentData.personRoles = updatedPersonRoles;
        }
      }

      mergedTargets.push(target);
    }
  });
  return mergedTargets;
}

export function getDocumentEndpoint(path) {
  return window.baseUrl + path;
}

export function stepNameToServer(step) {
  if (!step) {
    return "";
  }
  return step.toUpperCase().replaceAll("-", "_");
}

export function serverToStepName(step) {
  if (!step) {
    return "";
  }
  return step.toLowerCase().replaceAll("_", "-");
}

const buildFormFields = memoizeOne(
  (formFields) => toFieldDefinition({ prompts: formFields }).prompts
);

export function getPromptsFromForms(forms, isJoint) {
  const formFields = forms.map((form) => {
    const formId = form.formId
      .toLowerCase()
      .replace(/(_)([a-z])/g, (string) => string[1].toUpperCase());

    return {
      [formId.concat(isJoint ? "JointPrompts" : "Prompts")]: buildFormFields(
        form.prompts
      ),
    };
  });
  const prompts = {};

  formFields.forEach((element, index) => {
    Object.assign(prompts, formFields[index]);
  });

  return prompts;
}

export function getFormattedSSN(ssn) {
  if (isEmpty(ssn)) return undefined;

  if (isObject(ssn)) {
    const { ssn1, ssn2, ssn3 } = ssn;

    return `${ssn1}-${ssn2}-${ssn3}`;
  }

  // eslint-disable-next-line no-magic-numbers
  return ssn.length >= 9
    ? ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3")
    : ssn;
}

export const isEnrolledMTR = (account) => {
  return account?.enrollmentStatus === "MTR";
};

export const createSponsorAccountData = (account = {}, sponsorAccount = {}) => {
  return {
    name: account.name,
    balance: account.balance,
    userAccountId: sponsorAccount.pcapUserAccountId,
  };
};

export const getSponsorAccountsEnrolled = (
  sponsorAccountsData = [],
  accounts = []
) => {
  const sponsorAccounts = [];
  sponsorAccountsData.forEach((sponsorAccount) => {
    if (isEnrolledMTR(sponsorAccount)) {
      const account = accounts.find(
        (a) => a.userAccountId === sponsorAccount.pcapUserAccountId
      );
      if (account) {
        sponsorAccounts.push(createSponsorAccountData(account, sponsorAccount));
      }
    }
  });
  return sponsorAccounts;
};

export const getEnrolledAccountsBySponsor = (
  responseAccounts,
  enrollmentSponsorData
) => {
  let sponsors = [];

  for (const siteProductID in enrollmentSponsorData) {
    if (Object.hasOwn(enrollmentSponsorData, siteProductID)) {
      const sponsorData = enrollmentSponsorData[siteProductID];
      sponsors.push({
        title: sponsorData[0]?.sponsorName,
        mtrProgramName: sponsorData[0]?.mtrProgramName,
        onlineAdviceProgramName: sponsorData[0]?.onlineAdviceProgramName,
        accounts: getSponsorAccountsEnrolled(
          sponsorData,
          responseAccounts.accounts
        ),
      });
    }
  }
  return sponsors;
};
