import PropTypes from "prop-types";
import React from "react";
import { trackClick, trackView } from "components/common/ComponentAnalytics";
import StepsSection from "./StepsSection";
import FinancialPlanGallery from "./FinancialPlanGallery";

const CURRENT_PAGE = 2;

export default class HowDoesItWorkPage extends React.Component {
  constructor() {
    super(...arguments);
    this.onContinueClick = this.onContinueClick.bind(this);
  }

  componentDidMount() {
    const { componentName } = this.props;
    trackView("How Does It Work Page", { componentName });
  }

  onContinueClick() {
    trackClick(null, this.props.componentName, "Continue Button");
    this.props.onContinueClick(CURRENT_PAGE);
  }

  render() {
    const { componentName } = this.props;
    return (
      <div className="fin-plan-offer__page">
        <StepsSection onContinueClick={this.onContinueClick} />
        <FinancialPlanGallery componentName={componentName} />
      </div>
    );
  }
}

HowDoesItWorkPage.propTypes = {
  componentName: PropTypes.string,
  onContinueClick: PropTypes.func,
};
