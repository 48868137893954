var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "pc-u-pt";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"moduleFrame appTemplate\">\n  <div class=\"nav-secondary l-spaced l-spaced--flush js-secondary-nav\">\n    <h1 class=\"nav-secondary__title qa-page-title\">\n        Cash flow\n    </h1>\n    <nav>\n      <ul class=\"pc-layout pc-layout--auto pc-layout--small\">\n        <li class=\"pc-layout__item\">\n          <a class=\"cashflow pc-btn pc-btn--bare is-active js-cash-flow-tabs qa-active qa-cash-flow-tab\" href=\"#/cash-flow\" aria-current=\"true\">Cash Flow</a>\n        </li>\n        <li class=\"pc-layout__item\">\n          <a class=\"income pc-btn pc-btn--bare js-cash-flow-tabs qa-income-tab\" href=\"#/cash-flow/income\" aria-current=\"false\">Income</a>\n        </li>\n        <li class=\"pc-layout__item\">\n          <a class=\"expense pc-btn pc-btn--bare js-cash-flow-tabs qa-expense-tab\" href=\"#/cash-flow/expense\" aria-current=\"false\">Expense</a>\n        </li>\n      </ul>\n    </nav>\n  </div>\n	<div class=\"frame\">\n		<div class=\"nav-secondary nav-secondary--feature-controls l-spaced l-spaced--flush js-feature-controls\">\n      <div></div>\n			<div class=\"pc-layout pc-layout--auto pc-layout--small\">\n        <div class=\"pc-layout__item\">\n          <div class=\"accountSelector\"></div>\n        </div>\n        <div class=\"pc-layout__item\">\n          <div class=\"dateSelector\"></div>\n        </div>\n			</div>\n		</div>\n		<div class=\"visualization offset\">\n			<div class=\"cash-flow__bar-chart__container js-bar-chart-container\"></div>\n      <div class=\"cash-flow__line-chart__container js-line-chart-container\"></div>\n		</div>\n    <div class=\"takeAways "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":26},"end":{"line":36,"column":57}}})) != null ? stack1 : "")
    + "\">\n      <div class=\"firstTakeAway qa-first-take-away\" aria-live=\"assertive\" >\n      </div>\n      <div class=\"secondTakeAway qa-second-take-away\" aria-live=\"assertive\" >\n      </div>\n    </div>\n    <div class=\"gridFrame offset "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":33},"end":{"line":42,"column":64}}})) != null ? stack1 : "")
    + "\"></div>\n    <div id=\"cash-flow__info\" class=\"sr-only\" aria-live=\"assertive\"></div>\n	</div>\n</div>\n";
},"useData":true});