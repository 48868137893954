import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import _ from "underscore";
import $ from "jquery";
import analytics from "analytics";
import {
  trackView,
  trackButtonClick,
  trackLinkClick,
} from "components/common/ComponentAnalytics";
import getAceMessageActionClassNameByType from "libs/pcap/utils/getAceMessageActionClassNameByType";
import markUserMessageAsClicked from "libs/pcap/utils/markUserMessageAsClicked";
import markUserMessageAsDismissed from "libs/pcap/utils/markUserMessageAsDismissed";
import markUserMessageAsImpressed from "libs/pcap/utils/markUserMessageAsImpressed";
import Modal from "components/modal/Modal";
import { noop } from "underscore";

const CLOSE_BANNER_SELECTOR = "js-btn-dismiss";
const CLOSE_BANNER_URL_VALUE = "CLOSE";

export default class AceAppWideBanner extends React.Component {
  constructor() {
    super(...arguments);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.handleBannerLinkClick = this.handleBannerLinkClick.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleMessageClick = this.handleMessageClick.bind(this);
    this.state = {
      bannerHtml: null,
      confirmationText: null,
    };
  }

  componentDidMount() {
    const { userMessage } = this.props;
    this.setState({ bannerHtml: userMessage.summary });
    trackView(this.props.componentName, {
      template: userMessage && userMessage.template,
    });
    markUserMessageAsImpressed(userMessage.userMessageId);
  }

  onButtonClick(event) {
    const { onRemove, componentName, userMessage } = this.props;
    const button = event.target;
    const isCloseBannerAction =
      button &&
      button.className &&
      button.className.includes(CLOSE_BANNER_SELECTOR);
    let buttonLabel = null;

    if (isCloseBannerAction) {
      // Tell ACE to not send this user message down again until after dormant number of days
      markUserMessageAsDismissed(userMessage.userMessageId);
      buttonLabel = "Close Button";
      onRemove();
    }

    trackButtonClick(event, componentName, buttonLabel, {
      template: userMessage && userMessage.template,
    });
  }

  getButtonRow() {
    const { userMessage } = this.props;
    let buttonRow = null;

    if (userMessage && _.isArray(userMessage.action)) {
      const buttons = userMessage.action.map((button) => {
        let buttonComponent = null;
        if (button.label) {
          const sharedProps = {
            key: button.label,
            onClick: this.onButtonClick,
            className: `${getAceMessageActionClassNameByType(
              button.type
            )} pc-btn--tiny`,
          };
          const buttonUrlIsCloseBanner =
            String(button.url).toUpperCase() === CLOSE_BANNER_URL_VALUE;
          const useAnchorTag = button.url && !buttonUrlIsCloseBanner;
          buttonComponent = useAnchorTag ? (
            <a {...sharedProps} href={button.url}>
              {button.label}
            </a>
          ) : (
            <button {...sharedProps} type="button">
              {button.label}
            </button>
          );
        }
        return buttonComponent;
      });
      buttonRow = <div className="app-wide-banner__buttons">{buttons}</div>;
    }

    return buttonRow;
  }

  handleMessageClick(event) {
    if (this.isAnchorTag(event.target)) {
      const { componentName, userMessage, onMarkAsViewed } = this.props;
      const linkLabel = event.target.innerText;
      this.handleBannerLinkClick(event);

      if (userMessage && userMessage.userMessageId) {
        markUserMessageAsClicked(userMessage.userMessageId);
        onMarkAsViewed(userMessage.userMessageId);
      }

      trackLinkClick(event, componentName, linkLabel, {
        template: userMessage && userMessage.template,
      });
    }
  }

  handleBannerLinkClick(event) {
    const url = event.target.getAttribute("data-url");
    let postBody = event.target.getAttribute("data-post-body");
    const closeTimeout = event.target.getAttribute("data-close-timeout") || 0;
    const confirmationText = event.target.getAttribute(
      "data-confirmation-text"
    );
    this.setState({ closeTimeout, confirmationText });
    if (url) {
      postBody = (postBody && JSON.parse(postBody)) || {};
      postBody.csrf = window.csrf;
      $.ajax({
        type: "POST",
        url: url,
        data: postBody,
        success: (data) => this.handleResponse(true, data, url),
        error: (data) => this.handleResponse(false, data, url),
        dataType: "json",
      });
    }
  }

  handleResponse(isResponseSuccessful, data, url) {
    const { onRemove, onMarkAsViewed, userMessage } = this.props;
    if (isResponseSuccessful && _.isEmpty(data.spHeader.errors)) {
      if (this.state.confirmationText) {
        this.setState({ bannerHtml: this.state.confirmationText });
      }
      setTimeout(() => {
        onMarkAsViewed(userMessage.userMessageId);
        onRemove();
      }, this.state.closeTimeout * 1000);
    } else {
      this.setState({ bannerHtml: "Error occurred. Please try again later" });
      analytics.sendEngineeringEvent(
        "Error",
        `AceAppWideBanner.js: ${url}: ${JSON.stringify(data)}`
      );
    }
  }

  isAnchorTag(target) {
    return target.tagName === "A";
  }

  render() {
    const { userMessage } = this.props;
    if (_.isEmpty(userMessage)) {
      return null;
    }
    const buttonRow = this.getButtonRow();
    return (
      <section className="app-wide-banner app-wide-banner--prominent">
        <div className="app-wide-banner__content">
          <div
            className="app-wide-banner__message"
            dangerouslySetInnerHTML={{ __html: this.state.bannerHtml }}
            onClick={this.handleMessageClick}
          />
          <span
            className="app-wide-banner__close-x js-btn-dismiss"
            onClick={this.onButtonClick.bind(this)}
          >
            &times;
          </span>
        </div>
        {buttonRow}
      </section>
    );
  }
}

AceAppWideBanner.defaultProps = {
  componentName: "App Wide Banner",
  userMessage: {},
};

AceAppWideBanner.propTypes = {
  // Component name to use when tracking analytics events
  componentName: PropTypes.string,
  // A user message object from getUserMessages API
  userMessage: PropTypes.object,
  // Callback to close/remove the ACE block
  onRemove: PropTypes.func.isRequired,
  onMarkAsViewed: PropTypes.func.isRequired,
};

export const VIEW_TEMPLATE_APP_WIDE_BANNER = "APP_WIDE_BANNER";

export const isAppWideBannerUserMessage = (msg) =>
  msg.viewTemplate && msg.viewTemplate === VIEW_TEMPLATE_APP_WIDE_BANNER;

export const renderAppWideBannerToNode = (userMessage, mountNode) => {
  if (!mountNode) {
    throw Error("`mountNode` is required");
  }
  ReactDOM.render(
    React.createElement(AceAppWideBanner, {
      userMessage,
      onMarkAsViewed: noop,
      onRemove: noop,
    }),
    mountNode
  );
};

export const ModalWithAceAppWideBanner = ({ userMessage, onClose }) => {
  return (
    <Modal
      componentName=""
      className="pc-modal--huge pc-modal--bare"
      onClosed={null}
      // react-modal APIs
      contentLabel=""
      isOpen={true}
    >
      <AceAppWideBanner
        userMessage={userMessage}
        onMarkAsViewed={noop}
        onRemove={onClose}
      />
      <div className="u-text-center pc-u-pb-">
        <button className="pc-btn pc-btn--small" onClick={onClose}>
          Close
        </button>
      </div>
    </Modal>
  );
};

ModalWithAceAppWideBanner.propTypes = {
  userMessage: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export const renderAppWideBannerToModal = (userMessage) => {
  const mountNode = document.createElement("div");
  document.body.appendChild(mountNode);
  const onClose = () => {
    ReactDOM.unmountComponentAtNode(mountNode);
    mountNode.parentNode.removeChild(mountNode);
  };
  ReactDOM.render(
    React.createElement(ModalWithAceAppWideBanner, { userMessage, onClose }),
    mountNode
  );
  return null;
};
