import moment from "moment";

/**
 * Returns a string with the difference of the provided timestamp and now date
 *
 * @param {String} timestamp  unix format timestamp
 * @return {String}           the difference from now.
 */
export function fromNow(timestamp) {
  if (timestamp != null) {
    let duration = moment.duration(new Date().getTime() - timestamp);
    return duration.asHours() <= 24
      ? moment(timestamp).fromNow()
      : moment(timestamp).format("M/D/YYYY");
  }
  return "";
}
