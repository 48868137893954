/* cSpell:disable */
import React from "react";
import PropTypes from "prop-types";
import Loading from "components/common/loading/Loading";
import { noop } from "underscore";

const YodleeStatementFetcher = (props) => {
  const { loading, onCancel, onRemove } = props;

  const doc = props.document;
  const isDocumentReady = doc && doc?.name && !loading;

  return (
    <div>
      <div className="yodlee-fetcher">
        <div className="yodlee-fetcher__body">
          <div className="yodlee-fetcher-text-section pc-u-mb--">
            {!isDocumentReady && (
              <span className="yodlee-fetcher__body-icon">
                <Loading />
              </span>
            )}
            <span className="yodlee-fetcher__body-text">
              {isDocumentReady
                ? "You're in luck! We were able to grab your statement for you."
                : "Please wait a few moments while we try to retrieve your account statement using the credentials you provided when linking your account."}
            </span>
          </div>
          {isDocumentReady && (
            <p className="yodlee-fetcher__body-result">
              <strong>{doc && doc.name && doc.name}</strong>
            </p>
          )}
          <div className="yoddlee-fetcher__body-actions">
            {loading && (
              <button
                onClick={onCancel}
                className="yodlee-fetcher__body-action btn btn-link js-yodlee-fetcher__cancel-btn"
              >
                Cancel
              </button>
            )}
            {isDocumentReady && (
              <>
                <a
                  href={doc?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="yodlee-fetcher__body-action btn btn-link js-yodlee-fetcher__view-btn"
                >
                  View
                </a>
                <button
                  className="yodlee-fetcher__body-action btn btn-link js-yodlee-fetcher__remove-btn"
                  onClick={(e) => onRemove(e, doc)}
                >
                  Remove
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

YodleeStatementFetcher.propTypes = {
  document: PropTypes.object,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
};

YodleeStatementFetcher.defaultProps = {
  document: undefined,
  loading: true,
  onCancel: noop,
  onRemove: noop,
};

export default YodleeStatementFetcher;
