import React from "react";
import PropTypes from "prop-types";

const GrantsGraph = (props) => {
  const { showZeroState, loading, updatingGraph, grants } = props;
  return (
    <div className="visualization tipholder offset" style={{ height: "345px" }}>
      {showZeroState && !loading && (
        <img
          className="zero-state-graph"
          src={`${
            window.staticUrl || ""
          }/static/img/stockOptions/zeroStateGraph.png`}
        />
      )}
      {showZeroState && !loading && (
        <p className="zero-state-text" ng-show="showZeroState()">
          Track your stock options for this company by adding your first grant
          below.
        </p>
      )}
      <div className="grant-graph-placeholder"></div>
      {updatingGraph && grants.length !== 0 && (
        <div className="updatingGraph">Updating graph...</div>
      )}
    </div>
  );
};

GrantsGraph.propTypes = {
  showZeroState: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  updatingGraph: PropTypes.bool,
  grants: PropTypes.array.isRequired,
};

GrantsGraph.defaultProps = {
  updatingGraph: false,
};

export default GrantsGraph;
