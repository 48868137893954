import React from "react";
export default function SecurityLockIcon() {
  return (
    <svg
      width="110px"
      height="110px"
      viewBox="0 0 110 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon_security_lock</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Group3---Round-2-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Security-guarantee"
          transform="translate(-75.000000, -62.000000)"
        >
          <g
            id="marketing/security/link_01-copy-78"
            transform="translate(75.000000, 62.000000)"
          >
            <g filter="url(#filter-1)" id="circle">
              <g transform="translate(0.000000, -0.000611)">
                <path
                  d="M110,54.9992369 C110,85.3747971 85.3759816,110 55,110 C24.6255445,110 0,85.3747971 0,54.9992369 C0,24.6252029 24.6255445,0 55,0 C85.3759816,0 110,24.6252029 110,54.9992369"
                  id="Fill-1"
                  fill="#FDF8E7"
                ></path>
              </g>
            </g>
            <g transform="translate(32.478710, 22.257125)" strokeWidth="1.5">
              <path
                d="M9.45438068,20.7854167 L9.45438068,13.2870833 C9.45438068,5.97972222 15.4571134,0 22.7925938,0 C30.1280743,0 36.1323406,5.97972222 36.1323406,13.2870833 L36.1323406,20.7854167"
                stroke="#002157"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M28.6175757,41.9639306 C28.6175757,38.7586528 26.0103592,36.1599028 22.7927472,36.1599028 C19.5751352,36.1599028 16.9679187,38.7586528 16.9679187,41.9639306 C16.9679187,44.0905972 18.1273632,45.9285139 19.8389242,46.9399028 L19.8389242,55.0447639 L25.7066951,55.0447639 L25.7066951,46.9612917 C27.4381936,45.9560139 28.6175757,44.105875 28.6175757,41.9639306 Z"
                stroke="#DB1830"
              ></path>
              <path
                d="M42.5267693,63.9885278 L3.05964531,63.9885278 C1.36955552,63.9885278 0,62.6226944 0,60.9406111 L0,30.0336667 C0,28.3515833 1.36955552,26.98575 3.05964531,26.98575 L42.5267693,26.98575 C44.2168591,26.98575 45.5864146,28.3515833 45.5864146,30.0336667 L45.5864146,60.9406111 C45.5864146,62.6226944 44.2168591,63.9885278 42.5267693,63.9885278 Z"
                stroke="#002157"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
