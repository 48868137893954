import PropTypes from "prop-types";
import React from "react";
import { formatCurrency } from "libs/pcap/utils/format";
import Loading from "components/common/loading/Loading";
import Message from "components/common/Message";
import ChartReact from "libs/pcap/chart/chart--react";
import ImportantDataPoints from "components/importantDataPoints/ImportantDataPoints";
import { scaleTime } from "d3";
import moment from "moment";
import CtaOverlay from "components/common/CtaOverlay";
import oneDayChangeTemplate from "common/chartTooltips/oneDayChange";
import { isEmpty } from "underscore";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";
import { handleAmplitudeEvent } from "../../empower/utils/eventUtils";

const templateTooltip = oneDayChangeTemplate({
  x: (d) => d.x,
  y: (d) => d.y,
});
const Y_DOMAIN_PADDING = 10;

export default class NetWorthSummary extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleGaEventKeyDown = this.handleGaEventKeyDown.bind(this);
  }

  redirectToNetWorth() {
    window.location.href = NETWORTH_URL;
  }

  handleGaEvent() {
    window.dashboardUtils?.eventBus.dispatch("net_worth_widget_clicked_event");
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type: window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
      event_properties: {
        selection: "net_worth_widget_clicked_event",
      },
    });
  }

  handleGaEventKeyDown(e) {
    if (e.key === " ") {
      this.handleGaEvent();
    }
  }

  handleOnKeyDown(e) {
    if (["Enter", " "].includes(e.key)) {
      this.redirectToNetWorth();
    }
  }

  handleTooltipClick = () => {
    const name = "Net Worth";
    const element = "SELECT_BUTTON";
    const section = "Widget.toolTip";
    const payload = { widget: name };

    handleAmplitudeEvent(element, section, payload);
  };

  render() {
    const { loading, errors, ace, netWorth, histories } = this.props;
    const ZERO_VALUE = 0;
    const netWorthValue = formatCurrency(netWorth, ZERO_VALUE);
    const networthText = `Your Net worth is ${netWorthValue}`;

    if (loading) {
      return <Loading />;
    }

    if (!isEmpty(errors)) {
      return <Message severity="error" messages={errors} />;
    }

    let aceOverlay;
    if (ace) {
      aceOverlay = <CtaOverlay message={ace} widgetName="Net Worth Summary" />;
    }

    const data = histories.map((d) => {
      return {
        x: moment(d.date),
        y: d.networth,
        oneDayChange: d.oneDayNetworthChange,
      };
    });

    // set up the list of dataPoints for display
    let dataPoints = this.parseImportantDataPoints();

    const dataPointsLabel = dataPoints.reduce((text, currentItem) => {
      const displayValue = formatCurrency(currentItem.value, 0);
      const label = `${currentItem.name} change ${displayValue}. `;
      return text + label;
    }, "");

    const chartAriaLabel = `Net worth chart, click to go to net worth page. ${dataPointsLabel}`;
    const empowerSharedData = window.integratedSharedData;
    const tooltip =
      empowerSharedData?.translations?.get("zeroStateDashboard")?.netWorth
        ?.tooltip;
    const tooltipClass = tooltip ? "with-tooltip" : "";
    const widgetClass = "qa-networth-summery-widget";

    return (
      <section
        className={`summary-widget ${widgetClass} ${tooltipClass}`}
        alt="data visualization on change of total net worth"
      >
        <div className="summary-widget__header">
          <div className="l-spaced l-spaced--flush">
            <div className="pc-delta header-container">
              <h2 className="pc-u-mb0">
                <a
                  href={NETWORTH_URL}
                  className="summary-widget__title--link networth-summary-widget__title qa-networth-summery-widget-title"
                  aria-label="Go to Net worth"
                  onClick={this.handleGaEvent}
                  onKeyDown={this.handleGaEventKeyDown}
                >
                  Net worth
                </a>
              </h2>
              {tooltip && (
                <InfoTooltipIcon
                  title={tooltip}
                  container={false}
                  parentClassName={widgetClass}
                  tooltipProps={{
                    trigger: "click",
                    role: "alert",
                  }}
                  onClick={this.handleTooltipClick}
                />
              )}
            </div>
            <label
              aria-label={networthText}
              className="pc-delta u-text-primary networth-summary-widget__value qa-networth-summery-widget-value"
            >
              {netWorthValue}
            </label>
          </div>
        </div>
        <ChartReact
          ariaDescribedbyId="networthSummaryChart"
          data={[data]}
          type="area"
          xScale={scaleTime()}
          className="summary-widget__body summary-widget__chart networth-summary__chart dashboard-chart"
          interpolate="monotone"
          showYGrid={false}
          showXGrid={false}
          showXAxis={false}
          showYAxis={false}
          showYZeroLine={true}
          tooltip={{
            template: templateTooltip,
            className: "chart-tooltip--small",
          }}
          onClick={this.redirectToNetWorth}
          ariaLabel={chartAriaLabel}
          tabIndex={0}
          yDomainPadding={Y_DOMAIN_PADDING}
          onKeyDown={this.handleOnKeyDown}
        />
        <div className="summary-widget__footer">
          <ImportantDataPoints dataPoints={dataPoints} />
        </div>
        {aceOverlay}
      </section>
    );
  }

  /**
   * Parses data to create a properly formatted list
   * @param {object} props - component props
   * @returns {array} an array of objects to display
   */
  parseImportantDataPoints() {
    const { change, changeLabel, changeToday } = this.props;
    let dataPoints = [];
    if (change) {
      dataPoints.push({
        name: changeLabel,
        value: change, // disable linting
      });
    }
    if (changeToday) {
      dataPoints.push({
        name: "1-day",
        value: changeToday,
      });
    }

    return dataPoints;
  }
}

NetWorthSummary.propTypes = {
  errors: PropTypes.array,
  ace: PropTypes.object,
  loading: PropTypes.bool,
  histories: PropTypes.array,
  changeToday: PropTypes.number,
  change: PropTypes.number,
  changeLabel: PropTypes.string,
  netWorth: PropTypes.number,
};

NetWorthSummary.defaultProps = {
  errors: [],
  ace: undefined,
  loading: false,
  histories: [],
  changeToday: undefined,
  changeLabel: "90-day",
  change: undefined,
  netWorth: undefined,
};
