import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import AbstractForm from "components/common/form/AbstractForm";
import deepCopy from "deep-copy";
import { isEqual, isEmpty, noop } from "underscore";
import Input from "components/common/form/Input";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import { CategorySelector } from "components/TransactionsGridV3/CategorySelector";
import InputTagsContainer from "components/common/InputTags/Container";
import { formatCurrency } from "libs/pcap/utils/format";
import ConfirmModal from "common/ConfirmModal";
import { POSITIVE_CURRENCY_FORMAT } from "components/common/form/formattingOptions";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import memoizeOne from "memoize-one";
import objectPath from "object-path";
import { trackEvent } from "components/common/ComponentAnalytics";
import TransactionImageUploadModal from "components/TransactionImageUploadModal";
import EditTransactionImageModal from "components/common/EditTransactionImage/Modal";

const filterCategories = memoizeOne((categories, parentCategoryType) => {
  return categories.filter((c) => c.type === parentCategoryType);
});

const getVisibleSplitsLength = memoizeOne(
  (splits) => splits.filter((s) => !s.isToBeDeleted).length
);

const createSplit = (model, amount) => ({
  categoryId: model.categoryId,
  selectedTagIds: model.selectedTagIds,
  amount: amount == null ? model.amount : 0,
});

const AMOUNT_MAX_LENGTH = 11;
const SPLITS_LIMIT = 4;
const getAmountDifference = (model) => {
  const splitsTotal = model.splits
    .filter((s) => !s.isToBeDeleted)
    .reduce((total, s) => total + parseFloat(s.amount || 0), 0)
    .toFixed(2);
  return (model.amount - splitsTotal).toFixed(2);
};

class SplitYourTransaction extends AbstractForm {
  constructor(props) {
    super(props);
    const model = deepCopy(props.model);

    if (isEmpty(model.splits)) {
      model.splits = [createSplit(model), createSplit(model, 0)];
    }

    this.state = {
      model,
      showUndoSplitsConfirmation: false,
      showTransactionImageUploader: false,
      showEditTransactionImageModal: false,
      parentCategoryType: "",
      errors: props.errors,
    };

    this.handleCategoryCreated = this.handleCategoryCreated.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUnSplit = this.handleUnSplit.bind(this);
    this.handleRemoveSplit = this.handleRemoveSplit.bind(this);
    this.handleAddSplit = this.handleAddSplit.bind(this);
    this.handleTagsModified = this.handleTagsModified.bind(this);
    this.renderSplit = this.renderSplit.bind(this);
    this.handleCancelUndoSplits = this.handleCancelUndoSplits.bind(this);
    this.handleShowUndoSplits = this.handleShowUndoSplits.bind(this);
    this.handleRevertToOriginalDescription =
      this.handleRevertToOriginalDescription.bind(this);
    this.handleShowTransactionImageUploader =
      this.handleShowTransactionImageUploader.bind(this);
    this.handleCloseTransactionImageUploader =
      this.handleCloseTransactionImageUploader.bind(this);
    this.handleDeleteTransactionImage =
      this.handleDeleteTransactionImage.bind(this);
    this.handleEditImageClick = this.handleEditImageClick.bind(this);
  }

  handleShowTransactionImageUploader() {
    this.setState({
      showTransactionImageUploader: true,
      showEditTransactionImageModal: false,
    });
  }

  handleCloseTransactionImageUploader() {
    this.setState({
      showTransactionImageUploader: false,
      showEditTransactionImageModal: false,
    });
  }

  handleDeleteTransactionImage() {
    const { onDeleteTransactionImage } = this.props;
    if (onDeleteTransactionImage) {
      onDeleteTransactionImage().then(() => {
        this.setState({
          showEditTransactionImageModal: false,
        });
      });
    }
  }

  handleEditImageClick() {
    this.setState({
      showEditTransactionImageModal: true,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};
    if (!isEqual(nextProps.errors, prevState.prevPropsErrors)) {
      newState.errors = nextProps.errors;
      newState.prevPropsErrors = nextProps.errors;
    }

    if (!isEqual(nextProps.tags, prevState.prevPropsTags)) {
      newState.tags = nextProps.tags;
      newState.prevPropsTags = nextProps.tags;
    }

    const parentCategoryType =
      nextProps.categories.find(
        (c) => c.transactionCategoryId === nextProps.model.categoryId
      )?.type || "";

    const nextCategories = filterCategories(
      nextProps.categories,
      parentCategoryType
    );

    if (!isEqual(nextCategories, prevState.prevPropsCategories)) {
      newState.categories = nextCategories;
      newState.prevPropsCategories = nextCategories;
      newState.parentCategoryType = parentCategoryType;
    }

    if (
      !isEqual(nextProps.model?.imageUrl, prevState.prevPropsModel?.imageUrl)
    ) {
      const model = deepCopy(prevState.model);
      model.imageUrl = nextProps.model.imageUrl;
      newState.prevPropsModel = nextProps.model;
      newState.model = model;
    }

    if (!isEmpty(newState)) {
      return newState;
    }

    return null;
  }

  componentDidMount() {
    this.saveOriginalModel();
    this.focusAndDescriptionField();
  }

  handleTagsModified(selectedTagIds, name) {
    const { model } = this.state;
    objectPath.set(model, name, selectedTagIds);
    this.setState({ model });
  }

  handleCategoryCreated(category, index) {
    const { parentCategoryType, model } = this.state;
    if (category.type === parentCategoryType) {
      const splits = [...model.splits];
      splits[index].categoryId = category.transactionCategoryId;
      this.setState({ model: Object.assign({}, model, { splits }) });
    }
  }

  handleAddSplit() {
    const { originalModel, model } = this.state;
    const amountDifference = getAmountDifference(model);
    const enableAddAnotherSplit = Number(amountDifference) > 0;
    if (
      getVisibleSplitsLength(model.splits) <= SPLITS_LIMIT &&
      enableAddAnotherSplit
    ) {
      const modelCopy = deepCopy(model);
      const split = createSplit(deepCopy(originalModel), 0);
      modelCopy.splits.push(split);
      this.setState({ model: modelCopy });
    }
  }

  handleRemoveSplit(event) {
    const splitIndex = event.currentTarget.dataset.index;

    const { model } = this.state;
    if (getVisibleSplitsLength(model.splits) > 2) {
      const modelCopy = deepCopy(model);
      objectPath.set(modelCopy.splits, `${splitIndex}.isToBeDeleted`, true);
      this.setState({ model: modelCopy });
    }
  }

  handleCancelUndoSplits() {
    this.setState({
      showUndoSplitsConfirmation: false,
      errors: undefined,
    });
  }

  handleShowUndoSplits() {
    if (this.state.model.splits.some((s) => s.userTransactionId)) {
      this.setState({
        showUndoSplitsConfirmation: true,
        errors: undefined,
      });
    }
  }

  focusAndDescriptionField() {
    let descriptionInput = this.inputElements.find(
      (input) => input.props.name === "description"
    );
    if (descriptionInput) {
      descriptionInput.focus();
    }
  }

  handleUnSplit() {
    let requestModel = {
      userTransactionId: this.state.model.userTransactionId,
      splits: "[]",
    };
    this.props.onUnSplit(requestModel);
  }

  handleRevertToOriginalDescription() {
    let { model } = this.state;
    model.description = model.originalDescription;
    this.setState({ model });
    this.focusAndDescriptionField();
  }

  renderSplit(split, index) {
    if (split.isToBeDeleted) {
      return null;
    }

    const { tags, categories, model, parentCategoryType } = this.state;
    const { schema } = this.props;
    const splitTagName = `splits.${index}.selectedTagIds`;
    const amountDifference = getAmountDifference(model);

    return (
      <CSSTransition
        key={`split-${index}`}
        classNames="split-transaction__transition"
        timeout={250}
      >
        <div
          className={`pc-layout pc-layout--small pc-u-m0 split-transaction__split-row js-split-transaction__split-row qa-split-transaction__split-row qa-split-transaction__split-row-${index}`}
        >
          <div className="pc-layout__item pc-u-pl0 pc-u-pv pc-u-pr- pc-u-1/4">
            <div className="pc-layout pc-layout--small pc-u-m0">
              <div className="pc-layout__item pc-u-pl0 pc-u-8/9"></div>
              <div className="pc-layout__item pc-u-pl-- pc-u-1/9 u-text-center split-transaction__split-icon">
                <svg className="icon-svg">
                  <use href="#icon-split-transaction"></use>
                </svg>
              </div>
            </div>
          </div>
          <div className="pc-layout__item pc-u-pl0 pc-u-pv pc-u-pr- pc-u-1/4">
            <CategorySelector
              options={categories}
              name={`splits.${index}.categoryId`}
              onChange={this.handleInputChange}
              value={split.categoryId}
              categoryType={parentCategoryType}
              onCategoryCreated={(category) =>
                this.handleCategoryCreated(category, index)
              }
            />
          </div>
          <div className="pc-layout__item pc-u-pl0 pc-u-pv pc-u-pr- pc-u-1/4">
            <InputTagsContainer
              tags={tags}
              value={split.selectedTagIds}
              className={`Select--small split-transactions__input-tags js-split-transactions__input-tags-${index}`}
              name={splitTagName}
              onTagsModified={(selectedTagIds) =>
                this.handleTagsModified(selectedTagIds, splitTagName)
              }
            />
          </div>
          <div className="pc-layout__item pc-u-pl0 pc-u-pv pc-u-pr- pc-u-1/4">
            <div className="pc-layout pc-layout--small pc-u-m0">
              <div className="pc-layout__item pc-u-pl0 pc-u-8/9">
                <Input
                  ref={this.storeInputRef}
                  prefix={"$"}
                  type="text"
                  name={`splits.${index}.amount`}
                  className={
                    amountDifference === "0.00" ? undefined : "input--error"
                  }
                  sizeVariation="full"
                  value={split.amount}
                  onChange={this.handleInputChange}
                  formattingOptions={POSITIVE_CURRENCY_FORMAT}
                  maxLength={AMOUNT_MAX_LENGTH}
                  validator={schema.properties.amount}
                  data-hj-masked
                  autocomplete="off"
                />
              </div>
              <div className="pc-layout__item pc-u-pl-- pc-u-1/9 u-text-center split-transaction__split-icon">
                <button
                  type="button"
                  className={`pc-btn pc-btn--tiny pc-btn--stripped js-split-transaction__split-row-remove-${index} qa-split-transaction__split-row-remove-${index}`}
                  onClick={this.handleRemoveSplit}
                  data-index={index}
                  disabled={getVisibleSplitsLength(model.splits) <= 2}
                >
                  <svg className="icon-svg icon--small">
                    <use href="#pc-icon__close-x"></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    const { model, originalModel } = this.state;
    const modelCopy = deepCopy(model);
    const amountDifference = getAmountDifference(modelCopy);
    if (this.validate().valid && amountDifference === "0.00") {
      if (this.checkIfFormHasChanged()) {
        const shouldSaveSplits = !isEqual(
          originalModel.splits,
          modelCopy.splits
        );
        let requestModel = {
          userTransactionIds: JSON.stringify([modelCopy.userTransactionId]),
          description: modelCopy.description,
          transactionCategoryId: parseInt(modelCopy.categoryId, 10),
          isDuplicate: Boolean(modelCopy.isDuplicate),
        };

        if (isEmpty(modelCopy.selectedTagIds)) {
          requestModel.customTags = "[]";
        } else {
          requestModel.customTags = JSON.stringify(modelCopy.selectedTagIds);

          trackEvent("Transactions Grid", "Assign Custom Tags", {
            // eslint-disable-next-line camelcase
            custom_tag_names: modelCopy.selectedTagIds.join(),
          });
        }

        this.props.onUpdate(requestModel, !shouldSaveSplits).then(() => {
          if (shouldSaveSplits) {
            let requestModel = {
              userTransactionId: modelCopy.userTransactionId,
              splits: JSON.stringify(
                modelCopy.splits
                  .filter((s) => !s.isToBeDeleted)
                  .map((s) => {
                    s.tags = JSON.stringify(s.selectedTagIds);
                    delete s.selectedTagIds;
                    return s;
                  })
              ),
            };
            this.props.onSplit(requestModel);
          }
        });

        return;
      }

      this.props.onCancel();
    }
  }

  render() {
    const {
      loading,
      onCancel,
      schema,
      imageErrors,
      imageLoading,
      onFileUploaded,
      isPrivileged,
    } = this.props;
    const {
      model,
      tags,
      categories,
      showUndoSplitsConfirmation,
      showEditTransactionImageModal,
      showTransactionImageUploader,
      errors,
    } = this.state;

    const hideOriginalDescriptionTooltip = Boolean(
      model.originalDescription &&
        model.originalDescription === model.description
    );

    const handleGAEvent = () => {
      //GA tag
      if (IS_EMPOWER) {
        window.dashboardUtils?.eventBus.dispatch(
          `budget_page.split_transactions_save_button.click`
        );
        window.dashboardUtils?.eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
            "select_button",
          event_properties: {
            selection: `budget_page.split_transactions_save_button.click`,
          },
        });
      }
    };

    // Enable undo splits only when a transaction is already split.
    const isUndoSplitsDisabled = !model.splits.some((s) => s.userTransactionId);
    const amountDifference = getAmountDifference(model);
    const disableAddAnotherSplit = Number(amountDifference) <= 0;
    return (
      <div className="u-position--relative">
        {!showUndoSplitsConfirmation && (
          <>
            <Message className="pc-u-mb" severity="error" messages={errors} />
            <LoadingOverlay active={loading} />
          </>
        )}
        <form
          className="qa-split-transaction-form split-transaction__form"
          onSubmit={isPrivileged ? noop : this.handleSubmit}
        >
          <div className="pc-layout pc-layout--small pc-u-m0 table__row--header u-text-bold">
            <div className="pc-layout__item pc-u-pl0 pc-u-pr- pc-u-pb-- pc-u-1/4">
              Description
            </div>
            <div className="pc-layout__item pc-u-pl0 pc-u-pr- pc-u-pb-- pc-u-1/4">
              Category
            </div>
            <div className="pc-layout__item pc-u-pl0 pc-u-pr- pc-u-pb-- pc-u-1/4">
              Tags
            </div>
            <div className="pc-layout__item pc-u-pl0 pc-u-pr- pc-u-pb-- pc-u-1/4">
              Amount
            </div>
          </div>
          <div className="pc-layout pc-layout--small pc-u-m0">
            <div className="pc-layout__item pc-u-pl0 pc-u-pv pc-u-pr- pc-u-1/4">
              <div className="pc-layout pc-layout--small pc-u-m0">
                <div
                  className={`pc-layout__item pc-u-pl0 pc-u-${
                    hideOriginalDescriptionTooltip ? "1/1" : "8/9"
                  }`}
                >
                  <Input
                    ref={this.storeInputRef}
                    containerClassName="qa-split-transaction-description-input"
                    type="text"
                    name="description"
                    value={model.description}
                    validator={schema.properties.description}
                    onChange={this.handleInputChange}
                    sizeVariation="full"
                  />
                </div>
                {!hideOriginalDescriptionTooltip && (
                  <div className="pc-layout__item pc-u-pl-- pc-u-1/9 u-text-center split-transaction__tooltip">
                    <InfoTooltipIcon
                      className="js-original-description-tooltip"
                      title={`<div>Original description:</div>${model.originalDescription}<div>Double click to use original</div>`}
                      onDoubleClick={this.handleRevertToOriginalDescription}
                      tooltipProps={{
                        container: ".split-transaction__form",
                        placement: "right",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="pc-layout__item pc-u-pl0 pc-u-pv pc-u-pr- pc-u-1/4">
              <CategorySelector
                options={categories}
                name="categoryId"
                value={model.categoryId}
                isDisabled={true}
              />
            </div>
            <div className="pc-layout__item pc-u-pl0 pc-u-pv pc-u-pr- pc-u-1/4">
              {!isEmpty(model.selectedTagIds) && (
                <InputTagsContainer
                  tags={tags}
                  isDisabled={true}
                  value={model.selectedTagIds}
                  className="Select--small split-transactions__input-tags js-split-transactions__parent-input-tags"
                />
              )}
            </div>
            <div className="pc-layout__item pc-u-pl0 pc-u-pv pc-u-pr- pc-u-1/4 split-transaction__parent-amount">
              {formatCurrency(model.isCredit ? model.amount : -model.amount)}
            </div>
          </div>
          {!isEmpty(model.splits) && (
            <TransitionGroup>
              {model.splits.map(this.renderSplit)}
            </TransitionGroup>
          )}
          <div className="pc-layout pc-layout--small pc-u-m0 table__row--header u-text-bold">
            <div className="pc-layout__item pc-u-pl0 pc-u-pr- pc-u-pb- pc-u-1/4">
              <button
                type="button"
                className="pc-btn pc-btn--small js-split-transaction-add qa-split-transaction-add pc-btn--primary"
                onClick={this.handleAddSplit}
                disabled={
                  getVisibleSplitsLength(model.splits) > SPLITS_LIMIT ||
                  disableAddAnotherSplit
                }
              >
                Add Another Split
              </button>
              {disableAddAnotherSplit && (
                <InfoTooltipIcon
                  className="pc-u-ml-"
                  title={`<ul class="pc-u-mb0 pc-u-ml-"><li class="pc-u-mb--">Remaining amount should be greater than $0.00.</li><li>Maximum 5 splits are allowed for a transaction.</li></ul>`}
                  tooltipProps={{
                    container: ".split-transaction__form",
                    placement: "right",
                  }}
                />
              )}
            </div>
          </div>
          <div className="pc-layout pc-layout--small pc-u-pt- pc-u-m0 table__row--footer u-text-bold">
            <div className="pc-layout__item pc-u-pl0 pc-u-pr- pc-u-pb-- pc-u-3/4">
              Amount remaining to assign
            </div>
            <div className="pc-layout__item pc-u-pl0 pc-u-pr- pc-u-pb-- pc-u-1/4">
              <label className="js-remaining-amount">
                {formatCurrency(amountDifference)}
              </label>
              <CSSTransition
                in={amountDifference !== "0.00"}
                unmountOnExit={true}
                classNames="transition-height"
                timeout={250}
              >
                <label className="pc-help-block pc-help-block--small pc-help-block--error js-remaining-amount-message">
                  Must be equal to $0.00.
                </label>
              </CSSTransition>
            </div>
          </div>
          <div className="l-spaced l-spaced--flush pc-u-mt">
            <div>
              <button
                type="button"
                className="pc-btn js-split-transaction-undo qa-split-transaction-undo pc-btn--danger"
                onClick={this.handleShowUndoSplits}
                disabled={isUndoSplitsDisabled}
              >
                Undo Splits
              </button>
            </div>
            <div>
              {!model.imageUrl && (
                <button
                  aria-disabled={!!isPrivileged}
                  type="button"
                  className={`pc-btn js-split__add-transaction-image qa-add-transaction-image ${
                    isPrivileged ? "is-disabled" : ""
                  }`}
                  onClick={
                    isPrivileged
                      ? noop
                      : this.handleShowTransactionImageUploader
                  }
                >
                  Add Image
                </button>
              )}
              {model.imageUrl && (
                <button
                  type="button"
                  className="pc-btn js-split__edit-transaction-image qa-edit-transaction-image"
                  onClick={this.handleEditImageClick}
                >
                  Edit Image
                </button>
              )}
              <button
                type="button"
                className="pc-btn js-split-transaction-cancel"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                aria-disabled={!!isPrivileged}
                type="submit"
                className={`pc-btn pc-btn--primary qa-split-transaction-submit ${
                  isPrivileged ? "is-disabled" : ""
                }`}
                onClick={isPrivileged ? noop : handleGAEvent}
              >
                Save
              </button>
            </div>
          </div>
        </form>
        {showTransactionImageUploader && (
          <TransactionImageUploadModal
            onDone={this.handleCloseTransactionImageUploader}
            userTransactionId={model.userTransactionId}
            title={model.imageUrl ? "Replace Image" : undefined}
            onFileUploaded={onFileUploaded}
          />
        )}
        {showEditTransactionImageModal && (
          <EditTransactionImageModal
            onCancel={this.handleCloseTransactionImageUploader}
            imageUrl={model.imageUrl}
            onDelete={this.handleDeleteTransactionImage}
            onReplace={this.handleShowTransactionImageUploader}
            loading={imageLoading}
            errors={imageErrors}
          />
        )}
        {showUndoSplitsConfirmation && (
          <ConfirmModal
            title="Undo Splits For This Transaction"
            confirmLabel="Undo Splits"
            onConfirm={this.handleUnSplit}
            onCancel={this.handleCancelUndoSplits}
            isOpen={true}
            confirmButtonClassName="pc-btn--danger"
            disabled={isPrivileged}
          >
            <div>
              <Message className="pc-u-mb" severity="error" messages={errors} />
              <LoadingOverlay active={loading} />
              <p className="pc-u-mb-">
                Are you sure you want to undo the splits?
              </p>{" "}
              <p>
                Any split transaction assigned to this transaction will be
                removed and revert to the original state.
              </p>
            </div>
          </ConfirmModal>
        )}
      </div>
    );
  }
}

SplitYourTransaction.propTypes = {
  model: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSplit: PropTypes.func.isRequired,
  onUnSplit: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  onCategoryCreated: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  schema: PropTypes.object,
  errors: PropTypes.array,
  imageErrors: PropTypes.array,
  imageLoading: PropTypes.bool,
  onDeleteTransactionImage: PropTypes.func,
  onFileUploaded: PropTypes.func,
  isPrivileged: PropTypes.bool,
};

SplitYourTransaction.defaultProps = {
  schema: {
    type: "object",
    properties: {
      description: {
        type: "string",
        allowEmpty: false,
        messages: {
          allowEmpty: "Description is required.",
        },
      },
      amount: {
        type: "number",
        required: true,
        maxLength: AMOUNT_MAX_LENGTH,
        conform: (a) => {
          return parseFloat(a) > 0;
        },
        messages: {
          required: "Amount is required.",
          conform: "Must be more than $0.",
        },
      },
    },
  },
  loading: false,
  errors: undefined,
  imageErrors: undefined,
  imageLoading: false,
  onDeleteTransactionImage: undefined,
  onFileUploaded: noop,
  isPrivileged: false,
};

export default SplitYourTransaction;
