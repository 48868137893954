import PropTypes from "prop-types";
import React from "react";
import analytics from "analytics";
import mixpanel from "mixpanel";
import WidgetZeroState from "components/dashboard/WidgetZeroState";
import services from "services";

function trackButtonClick(widgetName, message, label, index) {
  let clickNumberText = "";
  if (index) {
    clickNumberText = " " + (index + 1);
  }
  analytics.sendEngagementEvent(
    "Notification Action Click" + clickNumberText,
    message.template
  );

  mixpanel.trackEvent(
    `Click on Dashboard Zero State Notification '${label}' Button in ${widgetName}`,
    {
      component: widgetName,
    }
  );
}

function formatMessageObject(props) {
  let message = props.message || {},
    action = message.action || [];

  message.buttons = action.map((action, index) => {
    return {
      title: action.title,
      link: action.url,
      attributionSource: action.attributionSource,
      trackingCallback: () => {
        trackButtonClick(props.widgetName, message, action.title, index);
      },
    };
  });

  return message;
}

export default class WidgetZeroStateContainer extends React.Component {
  constructor(props) {
    super(props);

    let message = formatMessageObject(props);
    this.state = {
      message: message,
      title: message.title,
      buttons: message.buttons,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.message ||
      nextProps.message.userMessageId === this.props.message.userMessageId
    ) {
      return;
    }

    let message = formatMessageObject(nextProps);

    this.setState({
      message: message,
      title: message.title,
      buttons: message.buttons,
    });

    if (!message.isStale && !message.lastViewedTime) {
      analytics.sendEngagementEvent(
        "Viewed Dashboard Notification",
        message.template
      );
      mixpanel.trackEvent(
        "View Dashboard Zero State Notification in " + this.props.widgetName,
        {
          component: this.props.widgetName,
        }
      );
      services.UserMessages.updateV2({
        userMessageIds: JSON.stringify([message.userMessageId]),
        actions: '["markViewed"]',
      });
    }
  }

  render() {
    return (
      <WidgetZeroState buttons={this.state.buttons} title={this.state.title} />
    );
  }
}

WidgetZeroStateContainer.propTypes = {
  message: PropTypes.object,
  widgetName: PropTypes.string,
};
