import _ from "underscore";
var _boxID = 0;

var _DEBUG = false;
var _DEBUG_RECT = false;
var _DEBUG_TEXT = false;
var _DEBUG_DIM = true;

var percentRE = /([\d.]+)%$/;

function _measure(prop, target, basis, basisTarget) {
  if (_.isArray(prop)) {
    return _.reduce(
      prop,
      function (out, propItem) {
        return out || _measure(propItem, target, basis, basisTarget);
      },
      0
    );
  }

  var value = target[prop];
  if (!value) {
    return 0;
  }

  return value ? _value(value, target, basis, basisTarget) : 0;
}

function _value(value, target, basis, basisTarget) {
  if (percentRE.test(value)) {
    var percent = parseFloat(value.replace("%", "")) / 100;
    if (basis) {
      if (!basisTarget) {
        basisTarget = target;
      }
      if (_.isString(basis)) {
        if (_.isFunction(basisTarget[basis])) {
          basis = basisTarget[basis]();
        } else {
          basis = basisTarget[basis];
        }
      }
      return basis * percent;
    }
    return { value: percent, percent: true };
  } else if (_.isString(value)) {
    return target[value];
  }
  return value;
}

/**
 * Boxes express nested rectangles in terms of percent/measurement/offset
 * from each other; it allows you to do "virtual DOM" calculus.
 *
 * Note that each Box has (up to) ONE raphael element; this element is created
 * when Box.draw() is called on root, and therefore, should only be called once.
 *
 * Most measurements can be expressed as numbers (pixels) or percent; the latter indicates
 * a fraction of the parents' innerWidth or innerHeight.
 *
 * Parent boxes cannot have any measurements as percentage values.
 *
 * @param params {object} overrided for default values.
 * @constructor
 */

var untitled = 0;
function Box(params) {
  if (!params.name) {
    //throw new Error('params with no name: ' + JSON.stringify(params, true, 3));
    params.name = "untitled" + ++untitled;
  }
  this.parent = null;
  this.width = 0;
  this.widthPos = "left";

  this.height = 0;
  this.heightPos = "top";

  this.raphaelElements = {};

  this.padding = 0;

  /**
   * spaces this box against inner region of parent.
   * is relative to parent's width, not parent's innerWidth;
   * that is, the distance of a given region from its parent
   * is the maximum of the parent region's padding and the region's margin.
   *
   * @type {Number}
   */
  this.marginLeft = 0;
  this.marginTop = 0;
  this.marginRight = 0;
  this.marginBottom = 0;
  this.margin = 0;
  this.drawMode = "none";

  /**
   * By default, boxes are drawn as, well, boxes.
   * They can also be drawn as lines or text.
   * @type {String}
   */

  this.drawMode = "rect";
  this.drawAttrs = {};

  this.name = "untitled";
  _.extend(this, params);
  this.id = ++_boxID;
  this.children = [];
}

/**
 * OFFSET, PADDING, MARGIN
 *
 * All of these govern the interaction and drawing area of a given Box from the outer bounds of its parent Box.
 * Note there are a LOT of ways that you can screw yourself with offset - creating unusable/negative areas.
 * At this point, none of these measurements affect the root object.
 * (One could make an argument for using margin, but its not implemented this way now.)
 *
 * All padding/offset can be set globally (i.e., margin) or individually(leftMargin).
 * If there is an individual measurement, it overrides the global measurement.
 *
 * OFFSET
 *   Offset is the net minimum distance between the parents full width and the objects' left edge.
 *   If the object is right aligned and not 100% width, this may be overridden by a larger gap.
 *
 * PADDING
 *   Padding is the minimum offset of ALL child boxes of a given Box. that is, a given boxes' dimensions
 *   are NOT effected by its padding setting; however its CHILDREN's left, right, width, etc. ARE
 *   affected by this boes padding.
 *
 * MARGIN
 *   Margin is the minimum offset of THIS element from its PARENTS left edge.
 *
 * PADDING AND MARGIN COMBINED
 *   Your offset is the MAXIMUM of the padding from your parent and your own margin. (that is, they don't stack.)
 *
 */

var _boxMixin = {
  close: function () {
    _.each(this.getElements(), function (e) {
      e.element.attr({ title: "" });
      e.element.remove();
    });

    _.each(this.children, function (child) {
      child.close();
    });
  },

  path: function () {
    return this.parent ? this.parent.path() + "." + this.name : this.name;
  },

  fade: function (n, time, easing, callback) {
    time = time || 0;
    easing = easing || "<";

    if (_DEBUG) console.log("************* fading ", this.name, "to ", n);

    _.each(this.getElements(), function (elementRecord, index) {
      var opacity = n;
      if (elementRecord.box.drawAttrs.hasOwnProperty("opacity")) {
        opacity *= elementRecord.box.drawAttrs.opacity;
        if (_DEBUG)
          console.log(
            "fading ",
            elementRecord.box.name,
            "to ",
            opacity,
            "based on box opactiy ",
            elementRecord.box.drawAttrs.opacity
          );
      } else if (_DEBUG) console.log("fading ", elementRecord.box.name, "to ", opacity, "(no opacity in attrs)");

      elementRecord.element.animate({ opacity: opacity }, time, easing);

      elementRecord.element.attr({
        text: n >= 1 ? elementRecord.text : "",
      });
    });
    if (callback) {
      setTimeout(callback, time);
    }
  },

  getElements: function () {
    return _.map(this.getRoot().raphaelElements, function (e) {
      return e;
    });
  },

  addElement: function (element, name) {
    if (typeof name == "undefined")
      this.error("attempt to add undefined element", element);
    if (this.getRoot().raphaelElements.hasOwnProperty(name)) {
      //	this.error('adding duplicate name' + name + ' to ' + this.name, element);
      name += untitled++;
    }

    this.getRoot().raphaelElements[name] = {
      element: element,
      name: name,
      box: this,
      text: element.attrs.text,
    };

    return element;
  },

  getElement: function (name) {
    return this.getRoot().raphaelElements[name];
  },

  error: function (msg, data) {
    console.log("box error", msg, this, data);
    throw new Error(msg);
  },

  tween: function (target, time, easing, callback) {
    this.getRoot()._tween(target, time, easing, callback);
  },

  _tween: function (target, time, easing, callback) {
    var ele = this.getElements();
    var self = this;

    var doneGot = false;

    function onDone() {
      if (doneGot) {
        return;
      }
      doneGot = true;
      target.fade(1, 0, ">", function () {});

      self.fade(0, 50, "<", callback);
    }

    _.each(
      ele,
      function (elementRecord, index) {
        var match = target.getElement(elementRecord.name);
        if (match) {
          var a = match.element.attrs;
          var tween = {
            x: a.x,
            y: a.y,
            height: a.height,
            width: a.width,
            ms: time,
          };

          elementRecord.element.animate(tween, time, easing, onDone);
        } else {
          if (_DEBUG)
            console.log("unable to find match for element", elementRecord.name);
          if (_DEBUG)
            console.log(
              "target elements: ",
              _.pluck(target.raphaelElements, "name")
            );
          elementRecord.element.remove();
        }
      },
      this
    );
  },

  _drawRect: function (paper, attrs) {
    attrs["stroke-width"] = 0;

    var a = attrs ? attrs : this.getAttrs();
    if (this.$role == "BAR" && this.height != "0%") {
      //	console.log('drawing rect for ', this.path());
    }

    var left = this.getLeft();
    var top = this.getTop();
    if (top < 78) {
      //console.log('top?');
      top = this.getTop();
    }
    var width = this.getWidth();
    var right = this.getRight();
    var left = this.getLeft();
    var bottom = this.getBottom();
    var height = this.getHeight();
    var bottomMargin = this.getBottomMargin();

    if (this.$role == "BAR" && this.height != "0%") {
      //console.log('top: ', top, 'bottom: ', bottom, 'bottomMargin:', bottomMargin);
    }

    if (height < 0 || width < 0) {
      console.log(" !!!!!!!!!!!!!! bad dimension: ", this);
      var width = this.getWidth();
      var height = this.getHeight();
    }

    left = Math.floor(left);
    top = Math.floor(top);
    width = Math.ceil(width);
    height = Math.ceil(height);

    try {
      this.addElement(
        paper.rect(left, top, width, height).attr(a),
        this.name + "Rect"
      );
    } catch (err) {
      console.log("error:", err, this._dim());
      throw err;
    }
  },

  _dim: function () {
    return [
      "l:" + this.getLeft(),
      "t:" + this.getTop(),
      "r:" + this.getRight(),
      "b:" + this.getBottom(),
      "lo:" + this.getLeftOffset(),
      "ro:" + this.getRightOffset(),
      "to:" + this.getTopOffset(),
      "bo:" + this.getBottomOffset(),
      "lm:" + this.getLeftMargin(),
      "rm:" + this.getRightMargin(),
      "tm:" + this.getTopMargin(),
      "bm:" + this.getBottomMargin(),
    ]
      .join(" ")
      .replace(/\.[\d]+/g, "");
  },

  _arrowPoints: function (attrs) {
    var left = this.getLeft(),
      top = this.getTop(),
      width = this.getWidth(),
      right = this.getRight(),
      bottom = this.getBottom(),
      height = this.getHeight();

    switch (this.arrowRel) {
      case "T":
        var Voffset = (top - bottom) / 2 + 0.5;
        top += Voffset;
        bottom += Voffset;
    }

    var points;

    switch (this.arrowMode) {
      case "L":
        points = [
          [right, bottom],
          [left, (top + bottom) / 2],
          [right, top],
          [right, bottom],
        ];

        break;
      case "Li":
        points = [
          [left, bottom],
          [right, (top + bottom) / 2],
          [left, top],
          [left, bottom],
        ];

        break;

      case "R":
        points = [
          [left, bottom],
          [right, (top + bottom) / 2],
          [left, top],
          [left, bottom],
        ];
        break;

      case "Ri":
        points = [
          [right, bottom],
          [left, (top + bottom) / 2],
          [right, top],
          [right, bottom],
        ];

        break;

      case "T":
        break;

      case "B":
        break;
    }

    return points;
  },

  _drawLineIE8: function (paper, attrs) {
    if (!this.lineBox) {
      switch (attrs.$lineType) {
        case "T":
          attrs["stroke-width"] = 0;
          if (!attrs.fill) {
            attrs.fill = "#000000";
          }
          this.lineBox = new Box({
            name: this.name + "_linebox",
            height: 1,
            width: "100%",
            drawMode: "rect",
            drawAttrs: attrs,
          });
          break;

        case "B":
          attrs["stroke-width"] = 0;
          if (!attrs.fill) {
            attrs.fill = "#000000";
          }
          this.lineBox = new Box({
            name: this.name + "_linebox",
            height: 1,
            width: "100%",
            drawMode: "rect",
            marginTop: "100%",
            drawAttrs: attrs,
          });
          break;

        case "L":
          attrs["stroke-width"] = 0;
          if (!attrs.fill) {
            attrs.fill = "#000000";
          }
          this.lineBox = new Box({
            name: this.name + "_linebox",
            width: 1,
            height: "100%",
            drawMode: "rect",
            drawAttrs: attrs,
          });
          break;

        case "R":
          attrs["stroke-width"] = 0;
          if (!attrs.fill) {
            attrs.fill = "#000000";
          }
          this.lineBox = new Box({
            name: this.name + "_linebox",
            width: 1,
            height: "100%",
            drawMode: "rect",
            marginLeft: "100%",
            drawAttrs: attrs,
          });
          break;

        default:
          this.error("unknown lineType", this.attrs.$lineType);
      }
      this.add(this.lineBox);
    }
    this.lineBox.draw(paper);
  },

  _drawLine: function (paper, attrs) {
    /**
     * the line box is a "dummy box" that we harvest location data from.
     * @type {Array}
     */
    var segments = [];
    if (!this.lineBox) {
      switch (attrs.$lineType) {
        case "T":
          if (!attrs.fill) {
            attrs.fill = "#000000";
          }
          /*	this.lineBox = new Box({
                          name:     this.name + '_linebox',
                          height:   1,
                          width:    '100%',
                          drawMode: 'none'
                      });
                      this.add(this.lineBox);*/
          segments.push([
            [this.getLeft(), this.getTop()],
            [this.getRight(), this.getTop()],
          ]);
          break;

        case "B":
          if (!attrs.fill) {
            attrs.fill = "#000000";
          }
          /*		this.lineBox = new Box({
                          name:      this.name + '_linebox',
                          height:    1,
                          width:     '100%',
                          drawMode:  'none',
                          heightPos: 'bottom'
                      });
                      this.add(this.lineBox);*/
          segments.push([
            [this.getLeft(), this.getBottom()],
            [this.getRight(), this.getBottom()],
          ]);
          break;

        case "L":
          if (!attrs.fill) {
            attrs.fill = "#000000";
          }
          /*	this.lineBox = new Box({
                          name:     this.name + '_linebox',
                          height:   '100%',
                          width:    1,
                          drawMode: 'none',
                          widthPos: 'left'
                      });
                      this.add(this.lineBox);*/
          segments.push([
            [this.getLeft(), this.getTop()],
            [this.getLeft(), this.getBottom()],
          ]);
          break;

        case "R":
          if (!attrs.fill) {
            attrs.fill = "#000000";
          }
          /*	this.lineBox = new Box({
                          name:     this.name + '_linebox',
                          height:   '100%',
                          width:    1,
                          drawMode: 'none',
                          widthPos: 'right'
                      });
                      this.add(this.lineBox);*/
          segments.push([
            [this.getRight(), this.getTop()],
            [this.getRight(), this.getBottom()],
          ]);
          break;

        default:
          this.error("unknown lineType", this.attrs.$lineType);
      }
    }
    //	this.lineBox.draw(paper); // probably not necessary.

    if (attrs.$arrows) {
      switch (attrs.$lineType) {
        /**
         * note - the arrowBoxL/R exist to give
         * the _arrowPoints() method
         * a coordinate basis.
         */
        case "T":
          if (attrs.$arrows.L) {
            this.arrowBoxL = new Box({
              name: this.name + "_arrowBoxL",
              height: attrs.$arrows.L == "i" ? 8 : 4,
              width: 8,
              widthPos: "left",
              heightPos: "top",
              drawMode: "none",
              arrowMode: attrs.$arrows.L == "i" ? "Li" : "L",
              arrowRel: "T",
            });
            this.add(this.arrowBoxL);
            segments.push(this.arrowBoxL._arrowPoints());
          }

          if (attrs.$arrows.R) {
            this.arrowBoxR = new Box({
              name: this.name + "_arrowBoxL",
              height: attrs.$arrows.R == "i" ? 8 : 4,
              width: 8,
              widthPos: "right",
              heightPos: "top",
              drawMode: "none",
              arrowMode: attrs.$arrows.R == "i" ? "Ri" : "R",
              arrowRel: "T",
              marginLeft: 8,
            });
            this.add(this.arrowBoxR);
            segments.push(this.arrowBoxR._arrowPoints());
          }

          break;
      }
    }

    var pathStr = _.map(segments, function (pathSet) {
      return _.map(pathSet, function (coord, i) {
        if (!_.isNumber(coord[0]) || !_.isNumber(coord[1])) {
          throw new Error("bad line");
        }
        return (i ? "L" : "M") + coord[0] + "," + (coord[1] + 1.5);
      }).join("");
    }).join("");

    var a = _.extend({ "stroke-width": 1, stroke: attrs.fill }, this.drawAttrs);
    if (!a["stroke-width"]) {
      console.log("line with no stroke-width:", this);
    }

    /**
     * the actual line is drawn, and its element is registered
     */
    var path = paper.path(pathStr).attr(a);

    $.addSVGClass(path, "crispEdges");
    this.addElement(path, this.path() + this.name);
  },

  _drawText: function (paper, attrs) {
    var left, top, right, bottom, textTop, textLeft, anchor;
    var l = this.getLeft();
    var t = this.getTop();
    var r = this.getRight();
    var b = this.getBottom();
    var w = this.getWidth();
    var h = this.getHeight();

    left = this.getInnerLeft();
    right = this.getInnerRight();
    bottom = this.getInnerBottom();
    top = this.getInnerTop();

    switch (attrs.$textAnchor) {
      case "L":
        textLeft = left;
        textTop = (top + bottom) / 2;
        anchor = "start";
        break;

      case "BR":
        textLeft = right;
        r = this.getRight();
        textTop = bottom - 0.8 * attrs["font-size"];
        anchor = "end";
        break;

      case "TL":
        textLeft = left;
        textTop = top + 0.8 * attrs["font-size"];
        anchor = "start";
        break;

      case "BL":
        textLeft = left;
        textTop = bottom - 0.8 * attrs["font-size"];
        anchor = "start";
        break;

      case "TR":
      default:
        textLeft = right;
        textTop = top + 0.8 * attrs["font-size"];
        anchor = "end";

        break;
    }

    if (_DEBUG_TEXT) {
      console.log(
        "text: ",
        attrs.text,
        "left/top:",
        textLeft,
        textTop,
        "anchor:",
        anchor
      );
    }

    var text = paper
      .text(textLeft, textTop, attrs.text)
      .attr(attrs)
      .attr({ "text-anchor": anchor });
    $.addSVGClass(text, "noEvents");
    this.addElement(text, this.path() + "Text");
  },

  draw: function (paper) {
    var attrs = this.drawAttrs;
    //attrs['stroke-width'] = 0;

    switch (this.drawMode) {
      case "arrow":
        this._drawArrow(paper, attrs);
        break;

      case "rect":
      case "box":
        this._drawRect(paper, attrs);

        this.drawChildren(paper);
        break;

      case "line":
        this._drawLine(paper, attrs);
        break;

      case "text":
        this._drawText(paper, attrs);
        break;

      case "none":
        this.drawChildren(paper);
        break;

      default:
        this.error("bad/no draw mode ", this.drawMode);
    }
  },

  remove: function (box) {
    if (_.isArray(box)) {
      return _.each(box, this.remove, this);
    }
    this.children = _.reject(this.children, function (child) {
      return child.id == box.id;
    });
  },

  drawChildren: function (paper) {
    _.each(this.children, function (child) {
      child.draw(paper);
    });
  },

  getAttrs: function () {
    var op = 1 / Math.pow(2, this.getDepth());
    var stripe = this.childIndex ? this.childIndex : 0;
    stripe += this.getDepth();
    stripe %= 2;

    var attrs = {
      fill: stripe ? "#000000" : "#FFFFFF",
      opacity: op,
    };

    if (this.drawAttrs.title) {
      attrs.title = this.drawAttrs.title;
    }

    return attrs;
  },

  getCenter: function () {
    return {
      x: this.getLeft() + this.getWidth() / 2,
      y: this.getTop() + this.getHeight() / 2,
    };
  },

  getDepth: function () {
    return 1 + (this.parent ? this.parent.getDepth() : 0);
  },

  measure: function (m, base) {
    return _measure(m, this, base);
  },

  value: function (value, base) {
    return _value(value, this, base);
  },

  hGrid: function (params, filter) {
    params = _.clone(params);
    _.defaults(params, { name: "box", count: 1 });

    var w = 100 / params.count;
    var count = params.count;
    delete params.count;
    var name = params.name;
    delete params.name;

    _.each(
      _.range(0, count),
      function (index) {
        var item = _.extend(
          {
            width: w + "%",
            marginLeft: index * w - 0.25 + "%",
            height: "100%",
            hgridIndex: index,
            name: name + " " + index,
          },
          params
        );

        if (filter) {
          item = filter(item, index, this);
        }

        this.add(new Box(item));
      },
      this
    );
  },

  vGrid: function (params, filter) {
    params = _.clone(params);
    _.defaults(params, { name: "box", count: 1 });

    var h = 100 / params.count;
    var count = params.count;
    delete params.count;
    var name = params.name;
    delete params.name;

    _.each(
      _.range(0, count),
      function (index) {
        var item = _.extend(
          {
            width: "100%",
            marginTop: index * h + "%",
            height: h + "%",
            vgridIndex: index,
            name: name + " " + index,
          },
          params
        );
        if (filter) {
          item = filter(item, index, this);
        }
        this.add(new Box(item));
      },
      this
    );
  },

  /* ********* BOTTOM BOUNDARIES *********** */ getBottomOffset: function () {
    if (!this.parent) return this.getBottomMargin();
    return Math.max(this.getBottomMargin(), this.parent.getBottomPadding());
  },

  getBottomMargin: function () {
    return _measure(["marginBottom", "margin"], this, "getHeight", this.parent);
  },

  getBottomPadding: function () {
    return _measure(["paddingBottom", "padding"], this, "getHeight");
  },

  /* ********* TOP BOUNDARIES *********** */

  getTopOffset: function () {
    if (!this.parent) return this.getTopMargin();
    return Math.max(this.getTopMargin(), this.parent.getTopPadding());
  },

  getTopMargin: function () {
    return _measure(["marginTop", "margin"], this, "getHeight", this.parent);
  },
  getTopPadding: function () {
    return _measure(["paddingTop", "padding"], this, "getHeight");
  },

  /* ********* LEFT BOUNDARIES *********** */
  getLeftOffset: function () {
    if (!this.parent) return this.getLeftMargin();
    return Math.max(this.getLeftMargin(), this.parent.getLeftPadding());
  },
  getLeftMargin: function () {
    return _measure(["marginLeft", "margin"], this, "getWidth", this.parent);
  },
  getLeftPadding: function () {
    return _measure(["paddingLeft", "padding"], this, "getWidth", this.parent);
  },

  /* ********* RIGHT BOUNDARIES *********** */
  getRightOffset: function () {
    if (!this.parent) return this.getRightMargin();
    return Math.max(this.getRightMargin(), this.parent.getRightPadding());
  },
  getRightMargin: function () {
    return _measure(["marginRight", "margin"], this, "getWidth", this.parent);
  },
  getRightPadding: function () {
    return _measure(["paddingRight", "padding"], this, "getWidth");
  },

  /* ************* INNER WIDTHS ************* */ getInnerWidth: function () {
    var leftPadding = this.getLeftPadding();
    var rightPadding = this.getRightPadding();
    var width = this.getWidth();
    return width - (leftPadding + rightPadding);
  },
  getInnerHeight: function () {
    var topPadding = this.getTopPadding();
    var bottomPadding = this.getBottomPadding();
    return this.getHeight() - (topPadding + bottomPadding);
  },
  getLeft: function () {
    var left = 0;
    if (this.parent) {
      left = this.parent.getLeft();
    } else {
      return this.getLeftMargin();
    }

    switch (this.widthPos) {
      case "left":
        var leftOffset = this.getLeftOffset();
        left += leftOffset;
        break;

      case "right":
        var parentWidth = this.parent.getWidth();
        var width = this.getWidth();
        var roffset = this.getRightOffset();
        left += parentWidth - width - roffset;
        break;

      default:
        throw new Error("bad value for widthPos!");
    }

    return left;
  },
  getInnerLeft: function () {
    var left = this.getLeft();
    var lp = this.getLeftPadding();
    if (isNaN(left) || isNaN(lp)) {
      throw new Error("bad left padding");
    }

    return left + lp;
  },
  getTop: function () {
    var top = 0;
    if (this.parent) {
      top = this.parent.getTop();
    } else {
      return this.getTopMargin();
    }

    var bottom = this.parent.getBottom();

    switch (this.heightPos) {
      case "top":
        var tOff = this.getTopOffset();
        var topOffset = this.parent.getTop() + tOff;
        top = topOffset;
        break;

      case "bottom":
        if (this.$role == "BAR") {
          //console.log('bar .....')
        }
        var bOff = this.getBottomOffset();
        top = bottom - (this.getHeight() + bOff);
        break;

      default:
        this.error("bad value for heightPos!");
    }

    return top;
  },
  getInnerTop: function () {
    return this.getTop() + this.getTopPadding();
  },
  getRight: function () {
    var left = this.getLeft();
    var width = this.getWidth();
    if (isNaN(left) || isNaN(width)) {
      var left = this.getLeft();
      var width = this.getWidth();
      throw new Error("bad measure for getRight");
    }
    return left + width;
  },
  getInnerRight: function (e) {
    if (e) {
      console.log("gir: ", this.getRight(), this.getRightPadding());
    }
    return this.getRight() - this.getRightPadding();
  },
  getBottom: function () {
    return this.getTop() + this.getHeight();
  },
  getInnerBottom: function () {
    return this.getBottom() - this.getBottomPadding();
  },
  add: function (box) {
    box.childIndex = this.children.length;
    this.children.push(box);
    box.parent = this;
    return this;
  },
  addChild: function (b) {
    return this.add(b);
  },
  getWidth: function () {
    return _measure(
      "width",
      this,
      this.parent ? this.parent.getInnerWidth() : 0
    );
  },
  getHeight: function () {
    return _measure(
      "height",
      this,
      this.parent ? this.parent.getInnerHeight() : 0
    );
  },
  getRoot: function () {
    return this.parent ? this.parent.getRoot() : this;
  },
};
_.extend(Box.prototype, _boxMixin);

export default Box;
