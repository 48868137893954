/* eslint-disable no-undef */
/* eslint-disable camelcase */
// TODO remove eslint-disable when fixed in EMPOWER-13015
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-magic-numbers */
import React from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import Checkbox from "components/common/form/Checkbox";
import AbstractForm from "components/common/form/AbstractForm";
import Message from "components/common/Message";
import CurrencyFormatterWithCents from "components/common/form/formatters/currencyWithCents";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";
import ReactTooltip from "react-tooltip";
import DatePickerInput from "components/common/form/DatePickerInput";
import FancySelect from "components/common/form/FancySelect";
import Textarea from "components/common/form/Textarea";
import AccountSelect, {
  accountsToOptions,
} from "components/common/form/AccountSelect";
import AddManualAccountModal from "../../../empower/components/AddManualAccountForm/Modal/AddManualAccountModal";
import { isEqual, noop, isEmpty } from "underscore";
import {
  STATE_LOCKED,
  STATE_BLOCKED,
  isAcatTransfer,
  isOnUs,
  isOnUsInvestment,
  findAccountByUserAccountId,
  isIraAccountByType,
  isBankAccount,
  isRetirementAccount,
} from "utils/account";
import {
  DISPLAY_FORMAT,
  FULL_MONTH_DATE_YEAR_FORMAT,
} from "libs/pcap/utils/date2";
import moment from "moment";
import { trackView, trackEvent } from "components/common/ComponentAnalytics";
import {
  POSITIVE_CURRENCY_FORMAT,
  FIVE_DECIMAL_PLACES_FORMAT,
} from "components/common/form/formattingOptions";
import ConfirmModal from "common/ConfirmModal/ConfirmModal";
import memoizeOne from "memoize-one";
import { getSource } from "components/common/attributionStore";
import {
  TRANSFER_TYPE_CONTRIBUTE,
  TRANSFER_TYPE_INTERNAL,
  TRANSFER_TYPE_WITHDRAW,
  FREQUENCY_ONE_TIME,
  FREQUENCY_WEEKLY,
  FREQUENCY_MONTHLY,
  FREQUENCY_YEARLY,
  OPTIONS_DAY_OF_WEEK,
  OPTIONS_FREQUENCY,
} from "components/transferFunds/utils/constants";
import { formatCurrency } from "libs/pcap/utils/format";
import { scheduleLabel, frequencyLabel } from "../utils/labels";
import { EditRecurringTransfersContext } from "../EditRecurringTransfers/EditRecurringTransfersContext";
import WarningToolTip from "./WarningTooltip/WarningTooltip";
import EmpowerWithdrawManagedModal from "../EmpowerWithdrawManagedModal";
import EmpowerWithdrawNonManagedModal from "../EmpowerWithdrawNonManagedModal";
import TransferFundsWithdrawalTaxWithholdingContainer from "../TransferFundsWithdrawalTaxWithholding/Container";
import Modal from "components/modal/Modal";
import EditFederalTaxWithholding from "../EditFederalTaxWithholding/EditFederalTaxWithholding";
import RequiredMinimumDistributionContainer from "../RequiredMinimumDistribution/Container/RequiredMinimumDistributionContainer";
import RequiredMinimumDistributionInformationModal from "../RequiredMinimumDistributionInformationModal";

//Updated the Lower limit as current day. So user can select the next day.
const LOWER_DATE_LIMIT = moment();
const UPPER_DATE_LIMIT = moment().add(1, "year");
const LAST_ELIGIBLE_DATE = 28;
const MESSAGES_SELECTED = "must be selected";
const MESSAGES_NOT_EMPTY = "must not be empty";
const TRANSFER_FUNDS = "Transfer Funds";
export const CRYPTO_WARNING_LABEL =
  "Warning about transferring from an account with cryptocurrency";
export const CRYPTO_WARNING_MESSAGE =
  "If you have cryptocurrency held in the 'From' account, it may be liquidated by the sending institution as part of a 'Full' transfer. If this is not your intent, please select 'Partial' transfer instead.";
export const IRA_CONTRIBUTION_WARNING_LABEL =
  "Warning about IRA contribution limits";
export const IRA_CONTRIBUTION_WARNING_ROTH_MESSAGE =
  "Roth IRA contributions may be limited based on your filing status and income. Please see the IRS website or speak with your advisor for more information.";
export const IRA_CONTRIBUTION_WARNING_TRADITIONAL_MESSAGE =
  "Your traditional IRA contributions may be tax-deductible. The deduction may be limited if you or your spouse is covered by a retirement plan at work or your income exceeds certain levels. Please see the IRS website or speak with your advisor for more information.";

const organizationNameCash = "Empower Personal Cash";

const formatFrequency = memoizeOne(frequencyLabel);

const formatSchedule = memoizeOne(scheduleLabel);

const getContributionLimitByYear = memoizeOne((contributionYears, year) =>
  contributionYears?.limits.find((c) => c.year === year)
);

/**
 * Start Date restrictions
 * - Can select the next day for all options
 * - For weekly frequency, block weekends
 * - For all other frequencies, block dates later than the 28th
 * - Not more than 365 days in the future
 */
const isAllowedDate = memoizeOne((currentDate) => {
  return (
    currentDate.isAfter(LOWER_DATE_LIMIT) &&
    currentDate.isBefore(UPPER_DATE_LIMIT) &&
    currentDate.date() <= LAST_ELIGIBLE_DATE
  );
});

const isAllowedDateWeekly = memoizeOne((currentDate) => {
  // 0 == Sunday, 6 == Saturday
  const isWeekend = currentDate.day() % 6 === 0;
  return (
    currentDate.isAfter(LOWER_DATE_LIMIT) &&
    currentDate.isBefore(UPPER_DATE_LIMIT) &&
    !isWeekend
  );
});

const startDateToMoment = memoizeOne((startDate) => {
  return moment(startDate, DISPLAY_FORMAT);
});

const memoizedIsAcatTransfer = memoizeOne(isAcatTransfer);
// storing two memoized functions for efficiency
const getSourceAccount = memoizeOne(findAccountByUserAccountId);
const getTargetAccount = memoizeOne(findAccountByUserAccountId);
const buildOptionsContributionYears = memoizeOne((contributionYears) => {
  return contributionYears.limits.map((c) => ({
    label: c.year,
    value: c.year,
  }));
});

const buildValidatorForTransferAmount = memoizeOne((validator, limit) => {
  validator = {
    ...validator,
    ...{ maximum: limit },
  };
  validator.messages.maximum = `Your total annual contribution cannot exceed ${formatCurrency(
    limit,
    2,
    false
  )}. If unsure, discuss with your advisor.`;
  return validator;
});

/**
 * Creates a validator for federal tax withholding inputs.
 * Displays error message if combined value of inputs is more than 100%.
 *
 * @param {number} taxWithholdingState - The state tax withholding value in the model.
 * @returns {object} The validator for the provided input element.
 */
export const buildValidatorForFederalTaxWithholdings = (
  taxWithholdingState = 0
) => ({
  allowEmpty: true,
  required: false,
  pattern: /^\s*[0-9]+([0-9][0-9]{0,2})?$|^$|^\s*$/,
  maximum: 100 - taxWithholdingState,
  messages: {
    maximum:
      "Federal and State Tax Withholding cannot exceed a combined value of 100%",
    pattern:
      "Federal Tax Withholding percentage must be entered as a whole number.",
  },
});

/**
 * Creates a validator for state tax withholding inputs.
 * Displays error message if combined value of inputs is more than 100%.
 *
 * @param {number} taxWithholdingFederal - The federal tax withholding value in the model.
 * @returns {object} The validator for the provided input element.
 */
export const buildValidatorForStateTaxWithholdings = (
  taxWithholdingFederal = 0
) => ({
  allowEmpty: true,
  required: false,
  type: "number",
  maximum: 100 - taxWithholdingFederal,
  messages: {
    maximum:
      "Federal and State Tax Withholding cannot exceed a combined value of 100%",
  },
});

const memoizeBuildValidatorForStateTaxWithholdings = memoizeOne(
  buildValidatorForStateTaxWithholdings
);
const memoizeBuildValidatorForFederalTaxWithholdings = memoizeOne(
  buildValidatorForFederalTaxWithholdings
);

function isOnusAccountDropdown(accounts) {
  if (!accounts) {
    return false;
  }

  return accounts.some(isOnUs);
}
// storing two memoized functions for efficiency
const isOnusSourceAccountDropdown = memoizeOne(isOnusAccountDropdown);
const isOnusTargetAccountDropdown = memoizeOne(isOnusAccountDropdown);

const MAXLENGTH_XID = 11;

const VIEW_ACCOUNT_NUMBER_TEXT = `Use this link to verify the Account and routing number for the selected non Empower Account.`;

function trackPageView(readonly) {
  let pageName = TRANSFER_FUNDS;
  if (readonly) {
    pageName += " Review";
  }
  trackView(pageName, { component: TRANSFER_FUNDS, source: getSource() });
}

function getExternalAccount(model, sourceAccounts, targetAccounts) {
  const { sourceAccountId, targetAccountId } = model;
  const fromAccount = sourceAccounts.find(
    (a) => a.userAccountId === sourceAccountId
  );
  const toAccount = targetAccounts.find(
    (a) => a.userAccountId === targetAccountId
  );
  let externalAccount;
  if (fromAccount && !isOnUs(fromAccount)) {
    externalAccount = fromAccount;
  }
  if (toAccount && !isOnUs(toAccount)) {
    externalAccount = toAccount;
  }
  return externalAccount;
}

function isOnUsBank(userAccountId, accounts) {
  return accounts.some(
    (a) => a.isOnUsBank && a.userAccountId === userAccountId
  );
}

/**
 * Checks model properties corresponding with rendered inputs to ensure
 * they're not undefined, null, or an empty string.
 *
 * @param {object} model Model of data entered into form
 * @param {[]} inputs Input refs
 * @param {boolean} isPremierInvestmentSource EPIA sourceAccount if selected
 * @returns {boolean} Success of validation
 */
export function softValidate(model, inputs, isPremierInvestmentSource) {
  if (!inputs || inputs.length === 0) {
    return false;
  }

  // Don't validate inputs that aren't required and are allowed to be empty.
  // If an input's validator doesn't define these fields, assume it is required
  // and is not allowed to be empty.
  inputs = inputs.filter(
    ({ props: { validator: { allowEmpty = false, required = true } = {} } }) =>
      !allowEmpty && required
  );

  if (IS_ADVISOR) {
    inputs = inputs.filter(({ props }) => props.name !== "instructions");
  }

  for (let i = 0; i < inputs.length; i++) {
    const val = model[inputs[i].props.name];

    if (
      IS_EMPOWER &&
      inputs[i].props.name === "transferAmount" &&
      isPremierInvestmentSource &&
      (val < inputs[i].props.validator.minimum ||
        val > inputs[i].props.validator.maximum)
    ) {
      return false;
    }
    if (
      inputs[i].ref !== null &&
      !inputs[i].unmounted &&
      (val === undefined || val === "" || val === null)
    ) {
      return false;
    }
  }

  return true;
}

// storing two memoized functions for efficiency
const isSourceOnUsBank = memoizeOne(isOnUsBank);
const isTargetOnUsBank = memoizeOne(isOnUsBank);

// Formerly known as Personal Capital Cash, renamed as Empower Personal Cash
function isPccTransfer({
  sourceAccountId,
  targetAccountId,
  sourceAccounts,
  targetAccounts,
}) {
  const sourceAccount = getSourceAccount(sourceAccountId, sourceAccounts);
  const targetAccount = getTargetAccount(targetAccountId, targetAccounts);
  const cashAccountSelected =
    isSourceOnUsBank(sourceAccountId, sourceAccounts) ||
    isTargetOnUsBank(targetAccountId, targetAccounts);
  return (
    cashAccountSelected &&
    !isOnUsInvestment(sourceAccount) &&
    !isOnUsInvestment(targetAccount)
  );
}

function isCredit({
  sourceAccountId,
  targetAccountId,
  sourceAccounts,
  targetAccounts,
}) {
  return (
    isSourceOnUsBank(sourceAccountId, sourceAccounts) &&
    !isTargetOnUsBank(targetAccountId, targetAccounts)
  );
}

class TransferFunds extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      showAggregationError: false,
      showConfirmTransferDialog: false,
      isFormComplete: false,
      isManagedAccount: false,
      showManagedEmpowerWithdrawModal: false,
      showNonManagedEmpowerWithdrawModal: false,
      isAddAccountModalOpen: false,
      showEditFederalTaxWithholdingEmpowerModal: false,
      showRequiredMinimumDistributionInformationModal: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSourceAccountChange = this.handleSourceAccountChange.bind(this);
    this.handleTargetAccountChange = this.handleTargetAccountChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
    this.handleConfirmTransfer = this.handleConfirmTransfer.bind(this);
    this.handleCancelTransfer = this.handleCancelTransfer.bind(this);
    this.handleOnBack = this.handleOnBack.bind(this);
    this.shouldDisableOptionSource = this.shouldDisableOptionSource.bind(this);
    this.shouldDisableOptionTarget = this.shouldDisableOptionTarget.bind(this);
    this.handleSourceAccountMenuOpen =
      this.handleSourceAccountMenuOpen.bind(this);
    this.handleTargetAccountMenuOpen =
      this.handleTargetAccountMenuOpen.bind(this);
    this.handleConfirmEmpowerWithdrawManagedModal =
      this.handleConfirmEmpowerWithdrawManagedModal.bind(this);
    this.handleCancelEmpowerWithdrawManagedModal =
      this.handleCancelEmpowerWithdrawManagedModal.bind(this);
    this.handleConfirmEmpowerWithdrawNonManagedModal =
      this.handleConfirmEmpowerWithdrawNonManagedModal.bind(this);
    this.handleCancelEmpowerWithdrawNonManagedModal =
      this.handleCancelEmpowerWithdrawNonManagedModal.bind(this);
    this.handleClosedEmpowerWithdrawNonManagedModal =
      this.handleClosedEmpowerWithdrawNonManagedModal.bind(this);
    this.handleLinkAccount = this.handleLinkAccount.bind(this);
    this.handleEditFederalTaxWithholdingEmpowerModalShow =
      this.handleEditFederalTaxWithholdingEmpowerModalShow.bind(this);
    this.handleEditFederalTaxWithholdingEmpowerModalCancel =
      this.handleEditFederalTaxWithholdingEmpowerModalCancel.bind(this);
    this.handleEditFederalTaxWithholdingEmpowerModalSave =
      this.handleEditFederalTaxWithholdingEmpowerModalSave.bind(this);
    this.handleRequiredMinimumDistributionInformationModalShow =
      this.handleRequiredMinimumDistributionInformationModalShow.bind(this);
    this.handleRequiredMinimumDistributionInformationModalCancel =
      this.handleRequiredMinimumDistributionInformationModalCancel.bind(this);
  }

  componentDidMount() {
    trackPageView(this.props.readonly);
    const { sourceAccounts, model } = this.props;
    const sourceAccount = sourceAccounts.find(
      (a) => a.userAccountId === model?.sourceAccountId
    );
    const isPremierInvestmentSource =
      sourceAccount?.productCategory === "EMPOWER_PREMIER";
    this.setState({
      isPremierInvestmentSource,
      isFormComplete: softValidate(
        this.state.model,
        this.inputElements,
        isPremierInvestmentSource
      ),
    });

    if (sourceAccount?.empowerAttributes?.maEnrollmentStatus === "MTR") {
      this.setState({ isManagedAccount: true });
    }
    if (this.state.model.isAccountCloseIndicator) {
      this.state.model.isAccountCloseIndicator = false;
      this.setState({
        model: this.state.model,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.readonly !== this.props.readonly) {
      trackPageView(this.props.readonly);
    }

    const { sourceAccounts, targetAccounts } = this.props;
    const { sourceAccountId, targetAccountId } = this.state.model;

    if (
      isPccTransfer({
        sourceAccountId: sourceAccountId,
        targetAccountId: targetAccountId,
        sourceAccounts,
        targetAccounts,
      })
    ) {
      const newIsCredit = isCredit({
        sourceAccountId: sourceAccountId,
        targetAccountId: targetAccountId,
        sourceAccounts,
        targetAccounts,
      });

      if (newIsCredit !== prevState.isCredit) {
        this.setState({
          isCredit: newIsCredit,
        });

        if (this.state.model.isConsented) {
          this.state.model.isConsented = false;
          this.setState({
            model: this.state.model,
          });
        }
      }
    }

    if (!isEqual(prevProps.model, this.props.model)) {
      const sourceAccount = getSourceAccount(sourceAccountId, sourceAccounts);
      const isPremierInvestmentSource =
        sourceAccount?.productCategory === "EMPOWER_PREMIER";
      const isManagedAccount =
        sourceAccount?.empowerAttributes?.maEnrollmentStatus === "MTR";
      this.setState({
        isPremierInvestmentSource,
        isManagedAccount,
        isFormComplete: softValidate(
          this.state.model,
          this.inputElements,
          isPremierInvestmentSource
        ),
      });
    }
    /* eslint-disable sonarjs/no-collapsible-if */
    if (
      !isEqual(prevProps.sourceAccounts, this.props.sourceAccounts) &&
      prevProps.sourceAccounts.length < this.props.sourceAccounts.length
    ) {
      if (window.location.hash === "#/accounts/add?skipAccountOrSavings") {
        this.state.model.sourceAccountId =
          this.props.sourceAccounts[
            this.props.sourceAccounts.length - 1
          ].userAccountId;

        this.setState({
          model: this.state.model,
        });

        this.props.model.sourceAccountId = this.state.model.sourceAccountId;
      }
    }
    if (
      !isEqual(prevProps.sourceAccounts, this.props.sourceAccounts) ||
      !isEqual(prevProps.targetAccounts, this.props.targetAccounts)
    )
      this.setState({ isAddAccountModalOpen: false });
  }

  updateModel(name, value) {
    super.updateModel(name, value);
    this.props.onModelChange(this.state.model);
  }

  handleLinkAccount(status = true) {
    this.setState((state) => ({ ...state, isAddAccountModalOpen: status }));
  }

  static getDerivedStateFromProps(props, state) {
    const {
      model,
      hasRecurringTransferEstablished,
      isPreviousYearContribution,
    } = props;
    const newState = {};
    if (!isEqual(model, state.prevPropsModel)) {
      newState.model = Object.assign({}, state.model, model);
      newState.prevPropsModel = model;

      if (
        isPccTransfer({
          sourceAccountId: model.sourceAccountId,
          targetAccountId: model.targetAccountId,
          sourceAccounts: props.sourceAccounts,
          targetAccounts: props.targetAccounts,
        })
      ) {
        const newIsCredit = isCredit({
          sourceAccountId: model.sourceAccountId,
          targetAccountId: model.targetAccountId,
          sourceAccounts: props.sourceAccounts,
          targetAccounts: props.targetAccounts,
        });

        if (newIsCredit !== state.isCredit) {
          newState.isCredit = newIsCredit;
        }
      }

      // Defaulting to one time for the case where we received a new model,
      // but `hasRecurringTransferEstablished` hasn't changed.
      if (hasRecurringTransferEstablished) {
        newState.model.frequency = FREQUENCY_ONE_TIME;
      }
    }

    if (
      hasRecurringTransferEstablished !==
      state.prevPropsHasRecurringTransferEstablished
    ) {
      if (hasRecurringTransferEstablished) {
        newState.model = {
          ...state.model,
          ...newState.model,
          frequency: FREQUENCY_ONE_TIME,
        };
      }

      newState.prevPropsHasRecurringTransferEstablished =
        hasRecurringTransferEstablished;
    }

    if (
      isPreviousYearContribution !== state.prevPropsIsPreviousYearContribution
    ) {
      if (isPreviousYearContribution) {
        newState.model = {
          ...state.model,
          ...newState.model,
          frequency: FREQUENCY_ONE_TIME,
        };
      }
      newState.prevPropsIsPreviousYearContribution = isPreviousYearContribution;
    }

    return isEmpty(newState) ? null : newState;
  }

  handleSubmit(event) {
    event.preventDefault();
    const { model, isManagedAccount, isPremierInvestmentSource } = this.state;
    const validateResult = this.validate();
    if (IS_EMPOWER) {
      let optionalAdditionalPayloadObject = {
        context: this.context ? "EDIT_RECURRING" : "NEW_TRANSFER",
      };
      window.dashboardUtils?.eventBus.dispatch(
        "select_accounts.continue_button.click",
        optionalAdditionalPayloadObject
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
        event_properties: {
          selection: "select_accounts.continue_button.click",
          optionalAdditionalPayloadObject,
        },
      });
    }
    if (validateResult.valid) {
      const { sourceAccounts, readonly } = this.props;
      const sourceAccount = sourceAccounts.find(
        (a) => a.userAccountId === model.sourceAccountId
      );

      const availableBalance = parseFloat(sourceAccount.balance);
      const ninetyPercentOfAvailableBalance = availableBalance * 0.9;
      const isManagedEmpowerWithdrawForAccountClose = () => {
        return (
          IS_EMPOWER &&
          isPremierInvestmentSource &&
          isManagedAccount &&
          parseFloat(model.transferAmount) <= availableBalance &&
          parseFloat(model.transferAmount) >= ninetyPercentOfAvailableBalance
        );
      };

      const availableCash = parseFloat(sourceAccount.availableCash);
      const isNonManagedEmpowerWithdrawForAccountClose = () => {
        return (
          IS_EMPOWER &&
          isPremierInvestmentSource &&
          !isManagedAccount &&
          parseFloat(model.transferAmount) === availableCash &&
          parseFloat(model.transferAmount) === availableBalance
        );
      };

      if (isManagedEmpowerWithdrawForAccountClose()) {
        this.setState({ showManagedEmpowerWithdrawModal: true });
      } else if (isNonManagedEmpowerWithdrawForAccountClose()) {
        this.setState({ showNonManagedEmpowerWithdrawModal: true });
      } else if (
        !readonly &&
        parseFloat(model.transferAmount) > sourceAccount.balance
      ) {
        this.setState({
          showConfirmTransferDialog: true,
          isTransferFromInvestmentAccount:
            sourceAccount.productType === "INVESTMENT",
        });
      } else {
        this.props.onContinue(model);
      }
    }
  }

  handleOnBack() {
    if (
      window.location.hash === "#/accounts/add?skipAccountOrSavings" ||
      this.props.onBack?.name === noop.name
    ) {
      window.history.back();
    } else {
      this.props.onBack();
    }
  }

  handleConfirmTransfer() {
    this.setState({ showConfirmTransferDialog: false });
    this.props.onContinue(this.state.model);
  }

  handleCancelTransfer() {
    this.setState({ showConfirmTransferDialog: false });
  }

  handleConfirmEmpowerWithdrawManagedModal() {
    this.state.model.isAccountCloseIndicator = true;
    this.setState({
      showManagedEmpowerWithdrawModal: false,
      model: this.state.model,
    });
    this.props.onContinue(this.state.model);
  }

  handleCancelEmpowerWithdrawManagedModal() {
    this.setState({ showManagedEmpowerWithdrawModal: false });
  }

  handleConfirmEmpowerWithdrawNonManagedModal() {
    this.state.model.isAccountCloseIndicator = true;
    this.setState({
      showNonManagedEmpowerWithdrawModal: false,
      model: this.state.model,
    });
    this.props.onContinue(this.state.model);
  }

  handleCancelEmpowerWithdrawNonManagedModal() {
    this.state.model.isAccountCloseIndicator = false;
    this.setState({
      showNonManagedEmpowerWithdrawModal: false,
      model: this.state.model,
    });
    this.props.onContinue(this.state.model);
  }

  handleClosedEmpowerWithdrawNonManagedModal() {
    this.setState({ showNonManagedEmpowerWithdrawModal: false });
  }

  handleSourceAccountChange({ target: { value } }) {
    if (IS_EMPOWER) {
      const { sourceAccounts } = this.props;
      const sourceAccount = sourceAccounts.find(
        (a) => a.userAccountId === value
      );
      if (sourceAccount?.empowerAttributes?.maEnrollmentStatus === "MTR") {
        this.setState({ isManagedAccount: true });
      } else {
        this.setState({ isManagedAccount: false });
      }
      let optionalAdditionalPayloadObject = {
        product_type: sourceAccount?.productType,
        group: sourceAccount?.accountTypeGroup,
        type: sourceAccount?.accountTypeNew,
        sub_type: sourceAccount?.accountTypeSubtype,
      };
      window.dashboardUtils?.eventBus.dispatch(
        "select_accounts.source_account_dropdown",
        optionalAdditionalPayloadObject
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type: window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_MENU,
        event_properties: {
          selection: "select_accounts.source_account_dropdown",
        },
      });
    }
    this.handleInputChange.apply(this, arguments);
    this.props.onSourceAccountChange(value);
  }

  handleTargetAccountChange({ target: { value } }) {
    if (IS_EMPOWER) {
      const { targetAccounts } = this.props;
      const targetAccount = targetAccounts.find(
        (a) => a.userAccountId === value
      );
      let optionalAdditionalPayloadObject = {
        product_type: targetAccount?.productType,
        group: targetAccount?.accountTypeGroup,
        type: targetAccount?.accountTypeNew,
        sub_type: targetAccount?.accountTypeSubtype,
      };
      window.dashboardUtils?.eventBus.dispatch(
        "select_accounts.target_account_dropdown",
        optionalAdditionalPayloadObject
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type: window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_MENU,
        event_properties: {
          selection: "select_accounts.target_account_dropdown",
        },
      });
    }
    this.handleInputChange.apply(this, arguments);
    this.props.onTargetAccountChange(value);
  }

  handleSourceAccountMenuOpen() {
    trackEvent(TRANSFER_FUNDS, "View Drop Down", {
      subcomponent: "from",
      source: getSource(),
    });
  }

  handleTargetAccountMenuOpen() {
    trackEvent(TRANSFER_FUNDS, "View Drop Down", {
      subcomponent: "to",
      source: getSource(),
    });
  }

  handleAmountChange({ target: { value } }) {
    if (IS_EMPOWER) {
      let optionalAdditionalPayloadObject = {
        value: value,
        context: this.context ? "EDIT_RECURRING" : "NEW_TRANSFER",
      };
      window.dashboardUtils?.eventBus.dispatch(
        "select_accounts.amount_dropdown",
        optionalAdditionalPayloadObject
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_FUND ??
          "select_fund",
        event_properties: {
          selection: "select_accounts.amount_dropdown",
          optionalAdditionalPayloadObject,
        },
      });
    }
    this.handleInputChange.apply(this, arguments);
  }

  handleFrequencyChange({ target: { value } }) {
    if (IS_EMPOWER) {
      let optionalAdditionalPayloadObject = {
        value: value,
        context: this.context ? "EDIT_RECURRING" : "NEW_TRANSFER",
      };
      window.dashboardUtils?.eventBus.dispatch(
        "select_accounts.frequency_dropdown",
        optionalAdditionalPayloadObject
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type:
          window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_FUND ??
          "select_fund",
        event_properties: {
          selection: "select_accounts.frequency_dropdown",
          optionalAdditionalPayloadObject,
        },
      });
    }
    this.handleInputChange.apply(this, arguments);
  }

  handleEditFederalTaxWithholdingEmpowerModalShow() {
    this.setState({ showEditFederalTaxWithholdingEmpowerModal: true });
  }

  handleEditFederalTaxWithholdingEmpowerModalCancel() {
    this.setState({ showEditFederalTaxWithholdingEmpowerModal: false });
  }

  handleEditFederalTaxWithholdingEmpowerModalSave(
    updatedTaxWithholdingFederal
  ) {
    let newState = {
      showEditFederalTaxWithholdingEmpowerModal: false,
    };
    const currentTaxWithholdingFederal = this.state.model.taxWithholdingFederal;
    if (updatedTaxWithholdingFederal !== currentTaxWithholdingFederal) {
      let updatedModel = { ...this.state.model };
      updatedModel.taxWithholdingFederal = updatedTaxWithholdingFederal;
      newState.model = updatedModel;
    }
    this.setState(newState);
  }

  handleRequiredMinimumDistributionInformationModalShow(eventFromButtonClick) {
    const hasPreviouslyDisplayedRmdModal = window.sessionStorage.getItem(
      "hasPreviouslyDisplayedRmdModal"
    );

    super.updateModel("hasRMD", true);

    if (eventFromButtonClick) {
      eventFromButtonClick.preventDefault();
      this.setState({ showRequiredMinimumDistributionInformationModal: true });
    } else if (!hasPreviouslyDisplayedRmdModal) {
      this.setState({ showRequiredMinimumDistributionInformationModal: true });
    }
  }

  handleRequiredMinimumDistributionInformationModalCancel() {
    window.sessionStorage.setItem("hasPreviouslyDisplayedRmdModal", true);
    this.setState({
      showRequiredMinimumDistributionInformationModal: false,
    });
  }

  renderViewAccountNumberLink(account, type) {
    const { model } = this.state;
    const selectedId = model[`${type}AccountId`];

    if (selectedId && account?.userAccountId === selectedId) {
      return (
        <div className="pc-layout pc-layout--small pc-u-mb pc-u-mt--">
          <div className="pc-layout__item pc-u-1/4"></div>
          <div className="pc-layout__item pc-u-3/4">
            <button
              className={`pc-btn--link pc-btn--small qa-transfer-funds__view-account-info js-transfer-funds__view-account-info--${type}`}
              type="button"
              onClick={() => this.props.onViewAccountNumber(account)}
            >
              View Account / Routing Information
            </button>
            <InfoTooltipIcon title={VIEW_ACCOUNT_NUMBER_TEXT} />
          </div>
        </div>
      );
    }

    return null;
  }

  shouldDisableOption(account) {
    const { stateType } = this.props;

    if (!stateType) {
      return false;
    }

    let isDisable;

    const state = account.stateForTransfer[stateType].state;
    switch (state) {
      case STATE_LOCKED:
        isDisable = true;
        break;
      case STATE_BLOCKED:
        isDisable = true;
        break;
      default:
        isDisable = false;
    }
    return isDisable;
  }

  shouldDisableOptionSource(account) {
    const { transferType } = this.props;
    if (transferType === TRANSFER_TYPE_WITHDRAW) {
      return false;
    }
    return this.shouldDisableOption(account);
  }

  shouldDisableOptionTarget(account) {
    const { transferType } = this.props;
    if (
      transferType === TRANSFER_TYPE_CONTRIBUTE ||
      (transferType === TRANSFER_TYPE_INTERNAL && !IS_EMPOWER)
    ) {
      return false;
    }
    return this.shouldDisableOption(account);
  }

  renderAchSection() {
    const {
      amountFormatter,
      readonly,
      validator,
      contributionYears,
      targetAccountOwnerAge,
      isContributionYearDisabled,
      targetAccounts,
      sourceAccounts,
      styleGuideData,
      transferType,
      isRMDEnabled,
    } = this.props;
    const { model, isManagedAccount } = this.state;

    let transferAmountValidator = validator.transferAmount;
    let htmlErrorMessage = false;

    if (contributionYears) {
      const contributionLimit = getContributionLimitByYear(
        contributionYears,
        model.contributionYear
      );
      if (contributionLimit) {
        const { medianAge, lowerLimit, upperLimit } = contributionLimit;
        let limit =
          targetAccountOwnerAge >= medianAge ? upperLimit : lowerLimit;
        transferAmountValidator = buildValidatorForTransferAmount(
          transferAmountValidator,
          limit
        );
      }
    }

    if (IS_EMPOWER) {
      const toAccount = targetAccounts.find(
        (a) => a.userAccountId === model.targetAccountId
      );
      const fromAccount = sourceAccounts.find(
        (a) => a.userAccountId === model.sourceAccountId
      );
      let maximumValue;
      let maximumMessage;

      if (toAccount?.productCategory === "EMPOWER_PREMIER") {
        maximumValue = 1500000;
        maximumMessage = `Please enter an amount less than or equal to $1,500,000. For incoming amounts greater than $1,500,000, please contact us at 866-317-6586 to hear your options.`;
      }

      if (fromAccount?.productCategory === "EMPOWER_PREMIER") {
        const nonAdvisorLimit = 49999;
        if (!IS_ADVISOR) {
          maximumValue = nonAdvisorLimit;
          maximumMessage = `Call a representative at ${styleGuideData?.retailSolutions?.transferFunds.representativePhoneNum} Monday – Friday between ${styleGuideData?.retailSolutions?.transferFunds.representativeTiming} to hear more about your options.`;
        }

        if (isManagedAccount && fromAccount?.balance !== undefined) {
          const availableBalance = parseFloat(fromAccount.balance);
          const ninetyPercentOfAvailableBalance = availableBalance * 0.9;
          const formattedBalance = formatCurrency(availableBalance, 2, false);

          if (model.transferAmount > fromAccount.balance) {
            maximumValue = availableBalance;
            maximumMessage = `Withdrawal amount should not be greater than ${formattedBalance}`;
          } else if (
            IS_ADVISOR &&
            model.transferAmount <= nonAdvisorLimit &&
            model.transferAmount < availableBalance &&
            model.transferAmount >= ninetyPercentOfAvailableBalance
          ) {
            maximumValue = ninetyPercentOfAvailableBalance - 1;
            maximumMessage = `To request this amount, please call us at (1-877-788-6261).`;
          }
        } else if (
          !isManagedAccount &&
          fromAccount?.availableCash !== undefined &&
          model.transferAmount > parseFloat(fromAccount.availableCash)
        ) {
          const availableCash = parseFloat(fromAccount.availableCash);
          const formattedCash = formatCurrency(availableCash, 2, false);
          const manageInvestmentsUrl = `${
            IS_ADVISOR ? "netWorth" : ""
          }#/manage-investments/diy?firmName=${fromAccount.firmName}&ua=${
            fromAccount.userAccountId
          }`;
          htmlErrorMessage = true;
          maximumValue = availableCash;
          maximumMessage = `${formattedCash} available to withdraw as of last business day. This is the amount of cash that is available to withdraw from your account. Recently deposited funds and pending activity may impact this amount. To generate additional cash, sell some investments through the <a href="${manageInvestmentsUrl}">Manage investments</a> feature.`;
        }
      }

      transferAmountValidator = {
        ...transferAmountValidator,
        minimum: 1,
      };
      if (maximumValue !== undefined) {
        transferAmountValidator.maximum = maximumValue;
      }
      if (maximumMessage !== undefined) {
        transferAmountValidator.messages.maximum = maximumMessage;
      }
    }

    if (transferType === TRANSFER_TYPE_WITHDRAW) {
      const isCashAccount = sourceAccounts.find(
        (account) =>
          account.userAccountId === model?.sourceAccountId && account.isOnUsBank
      );
      if (isCashAccount) {
        transferAmountValidator = {
          ...transferAmountValidator,
          minimum: undefined,
          exclusiveMinimum: 0,
          messages: { exclusiveMinimum: "Must be greater than 0" },
        };
      }
    }

    return (
      <>
        <label
          className={`pc-label pc-layout pc-layout--small pc-form-group${
            readonly && model.hasRMD ? " pc-u-mb--" : ""
          }`}
        >
          <span className="pc-layout__item pc-u-1/4" id="amountTextLabel">
            Amount
          </span>
          <div className="pc-layout__item pc-u-3/4">
            {readonly ? (
              <b className="js-review-transfer-amount">
                ${amountFormatter.format(model.transferAmount || "")}
              </b>
            ) : (
              <>
                <Input
                  ref={this.storeInputRef}
                  prefix={"$"}
                  type="text"
                  id="transferAmount"
                  name="transferAmount"
                  className="qa-input-amount js-input-amount transfer-funds__second-col"
                  sizeVariation="full"
                  value={model.transferAmount}
                  onChange={this.handleInputChange}
                  formattingOptions={POSITIVE_CURRENCY_FORMAT}
                  maxLength={MAXLENGTH_XID}
                  validator={transferAmountValidator}
                  data-hj-masked
                  errorPlaceholder={htmlErrorMessage}
                  errorBlockClassName="transfer-funds__error-block-with-link"
                  labelledby={"transferAmount amountTextLabel"}
                />
                {isRMDEnabled &&
                  transferType === TRANSFER_TYPE_WITHDRAW &&
                  model?.sourceAccountId && (
                    <RequiredMinimumDistributionContainer
                      userAccountId={model.sourceAccountId}
                      handleModalShow={
                        this
                          .handleRequiredMinimumDistributionInformationModalShow
                      }
                    />
                  )}
              </>
            )}
          </div>
        </label>
        {contributionYears?.limits?.length && (
          <div className="pc-layout pc-layout--small pc-form-group">
            <div className="pc-layout__item pc-u-1/4">
              <label
                className="pc-label"
                id="ariaContributionYearLabel"
                htmlFor="ariaContributionYearInput"
              >
                Contribution Year
              </label>
            </div>
            <div className="pc-layout__item pc-u-3/4">
              {readonly || isContributionYearDisabled ? (
                <b className="js-review-contribution-year">
                  {model.contributionYear}
                </b>
              ) : (
                <FancySelect
                  ref={this.storeInputRef}
                  id="contributionYear"
                  name="contributionYear"
                  className="js-input-contribution-year transfer-funds__second-col"
                  value={model.contributionYear}
                  onChange={this.handleInputChange}
                  validator={validator.contributionYear}
                  options={buildOptionsContributionYears(contributionYears)}
                  inputId="ariaContributionYearLabel"
                  selectInputId="ariaContributionYearInput"
                />
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  renderAcatSection() {
    const { readonly, validator } = this.props;
    const { model } = this.state;

    return (
      <div className="pc-layout pc-layout--small pc-form-group">
        <div className="pc-layout__item pc-u-1/4">
          <label
            className="pc-label"
            id="ariaAcatTransferTypeLabel"
            htmlFor="ariaAcatTransferTypeInput"
          >
            Amount
          </label>
        </div>
        <div className="pc-layout__item pc-u-3/4">
          {readonly ? (
            <b className="js-review-acat-transfer-type">
              {model.acatTransferType === "PARTIAL" ? "Partial" : "Full"}
            </b>
          ) : (
            <FancySelect
              ref={this.storeInputRef}
              type="text"
              id="acatTransferType"
              name="acatTransferType"
              className="js-input-acat-transfer-type transfer-funds__second-col"
              value={model.acatTransferType}
              onChange={this.handleAmountChange}
              validator={validator.acatTransferType}
              options={[
                { label: "Partial", value: "PARTIAL" },
                { label: "Full", value: "FULL" },
              ]}
              data-hj-masked
              inputId="ariaAcatTransferTypeLabel"
              selectInputId="ariaAcatTransferTypeInput"
            />
          )}
        </div>
      </div>
    );
  }

  renderRecurringSection(shouldDisplayAdvisorNotes, sourceAccount) {
    const {
      readonly,
      validator,
      hasRecurringTransferEstablished,
      isPreviousYearContribution,
      hasOneTimeFrequency,
      transferType,
    } = this.props;
    const { model } = this.state;
    let frequencyOptions = OPTIONS_FREQUENCY;
    const hideOneTimeFrequency = !hasOneTimeFrequency;

    if (hideOneTimeFrequency) {
      frequencyOptions = OPTIONS_FREQUENCY.filter(
        (fo) => fo.value !== FREQUENCY_ONE_TIME
      );
    }

    if (
      !IS_EMPOWER &&
      transferType === TRANSFER_TYPE_WITHDRAW &&
      this.checkIsOnUsInvestment(sourceAccount)
    ) {
      frequencyOptions = OPTIONS_FREQUENCY.filter(
        (fo) => fo.value !== FREQUENCY_WEEKLY
      );
    }

    return (
      <div className={`pc-layout pc-layout--small pc-form-group`}>
        <div className="pc-layout__item pc-u-1/4">
          <label
            className="pc-label"
            id="ariaFrequencyLabel"
            htmlFor="ariaFrequencyInput"
          >
            Frequency
          </label>
        </div>
        <div className={"pc-layout__item pc-u-3/4"}>
          {readonly ? (
            <b className="js-review-frequency">
              {formatFrequency(model.frequency)}
            </b>
          ) : (
            <>
              <FancySelect
                ref={this.storeInputRef}
                type="text"
                id="frequency"
                name="frequency"
                className="js-input-frequency qa-input-frequency transfer-funds__second-col"
                value={model.frequency}
                onChange={this.handleFrequencyChange}
                options={frequencyOptions}
                validator={validator.frequency}
                isDisabled={
                  hasRecurringTransferEstablished || isPreviousYearContribution
                }
                inputId="ariaFrequencyLabel"
                selectInputId="ariaFrequencyInput"
              />
              {hasRecurringTransferEstablished && (
                <HelpBlockRecurringTransferExists />
              )}
              {isPreviousYearContribution && (
                <div className="pc-help-block pc-help-block--small js-previous-year-contribution-error">
                  Only one-time transfers are allowed for prior year IRA
                  contributions.
                </div>
              )}
            </>
          )}
        </div>
        {model.frequency && model.frequency !== FREQUENCY_ONE_TIME ? (
          <>
            <div className="pc-layout__item pc-u-1/4 pc-u-mt">
              <label className="pc-label" htmlFor="startDate">
                Start Date
              </label>
            </div>
            <div className="pc-layout__item pc-u-3/4 pc-u-pr- pc-u-mb pc-u-mt">
              {readonly ? (
                <div className="js-review-start-date u-text-bold">
                  {model.startDate}
                </div>
              ) : (
                <DatePickerInput
                  className="js-input-recurring-start-date qa-input-recurring-start-date transfer-funds__second-col"
                  key="startDate"
                  id="startDate"
                  name="startDate"
                  value={model.startDate}
                  ref={this.storeInputRef}
                  onChange={this.handleInputChange}
                  isAllowedDate={
                    model.frequency === FREQUENCY_WEEKLY
                      ? isAllowedDateWeekly
                      : isAllowedDate
                  }
                  validator={
                    model.frequency === FREQUENCY_WEEKLY
                      ? validator.startDateWeekly
                      : validator.startDate
                  }
                  displayDateFormat={DISPLAY_FORMAT}
                  disabled={readonly}
                  position="top"
                />
              )}
            </div>
          </>
        ) : null}
        {model.frequency && model.frequency === FREQUENCY_WEEKLY ? (
          <>
            <div className="pc-layout__item pc-u-1/4">
              <label
                className="pc-label"
                id="ariaScheduleLabel"
                htmlFor="ariaScheduleInput"
              >
                Day of Week
              </label>
            </div>
            <div className="pc-layout__item pc-u-3/4 pc-u-mb">
              {readonly ? (
                <b className="js-review-schedule">
                  {formatSchedule(model.schedule)}
                </b>
              ) : (
                <FancySelect
                  ref={this.storeInputRef}
                  type="text"
                  id="schedule"
                  name="schedule"
                  className="js-input-schedule qa-input-schedule transfer-funds__second-col"
                  value={model.schedule}
                  validator={validator.schedule}
                  onChange={this.handleInputChange}
                  options={OPTIONS_DAY_OF_WEEK}
                  inputId="ariaScheduleLabel"
                  selectInputId="ariaScheduleInput"
                />
              )}
            </div>
          </>
        ) : null}
        {this.props.recurringTransferHelpText && (
          <div className="pc-help-block pc-help-block--small pc-u-ml js-recurring-transfer-help-text">
            {this.props.recurringTransferHelpText}
          </div>
        )}
      </div>
    );
  }

  renderTaxWithholdingSection() {
    const { readonly } = this.props;
    const { model } = this.state;

    const [stateTaxWithholdingValidator, federalTaxWithholdingValidator] = [
      memoizeBuildValidatorForStateTaxWithholdings(model.taxWithholdingFederal),
      memoizeBuildValidatorForFederalTaxWithholdings(model.taxWithholdingState),
    ];

    let formattedTaxWithholdingState;

    if (readonly)
      formattedTaxWithholdingState = `${
        model.stateTaxRateType === "AMOUNT"
          ? "$" + model.taxWithholdingState
          : model.taxWithholdingState + "%"
      }`;

    /* eslint-disable jsx-a11y/label-has-associated-control */
    return (
      <div className="pc-layout pc-layout--small pc-form-group js-tax-withholdings">
        <div className="pc-layout__item pc-u-1/4">
          <div className="pc-layout pc-layout--flush">
            <label
              className={IS_EMPOWER ? "pc-u-pr--" : `pc-layout__item pc-u-3/4`}
            >
              Tax Withholding
            </label>
            <svg
              className="icon__help-circled pc-layout__item pc-u-5/5 transfer-funds__tooltip"
              data-tip={
                "Tax Withholding is not required. For Roth IRA withdrawals, it is unusual to withhold taxes."
              }
            />
          </div>
        </div>
        <div className="pc-layout__item pc-u-1/4">
          <div
            className={`l-spaced l-spaced--${
              readonly ? "left" : "flush"
            } l-spaced--nowrap l-spaced--top ${
              IS_EMPOWER ? "transfer-funds__second-col" : ""
            }`}
          >
            <div className={`pc-u-p-- ${readonly ? "pc-u-pt0" : ""}`}>
              <label className="pc-label" htmlFor="taxWithholdingFederal">
                Federal
              </label>
            </div>
            <div
              className={`pc-u-p-- pc-u-pt0${IS_EMPOWER ? " pc-u-pr0" : ""}`}
            >
              {readonly ? (
                <div className="js-review-federal-withholdings">
                  <span className="u-text-bold">
                    {model.taxWithholdingFederal || 0}
                  </span>
                  %
                </div>
              ) : (
                <Input
                  ref={this.storeInputRef}
                  type="text"
                  id="taxWithholdingFederal"
                  name="taxWithholdingFederal"
                  className="qa-input-tax-withholding-federal js-input-tax-withholding-federal pc-layout--right"
                  sizeVariation="full"
                  value={model.taxWithholdingFederal}
                  formattingOptions={FIVE_DECIMAL_PLACES_FORMAT}
                  onChange={this.handleInputChange}
                  maxLength={MAXLENGTH_XID}
                  placeholder="0"
                  validator={federalTaxWithholdingValidator}
                  data-hj-masked
                  suffix="%"
                />
              )}
            </div>
          </div>
        </div>
        <div className="pc-layout__item pc-u-1/4">
          <div
            className={`l-spaced l-spaced--${
              readonly ? "left" : "flush"
            } l-spaced--nowrap l-spaced--top`}
          >
            <div className={`pc-u-p-- ${readonly ? "pc-u-pt0" : ""}`}>
              <label className="pc-label" htmlFor="taxWithholdingState">
                State
              </label>
            </div>
            <div className="pc-u-p-- pc-u-pt0">
              {readonly ? (
                <div className="js-review-tax-withholding-state">
                  <span className="u-text-bold">
                    {formattedTaxWithholdingState}
                  </span>
                </div>
              ) : (
                <Input
                  ref={this.storeInputRef}
                  type="text"
                  id="taxWithholdingState"
                  name="taxWithholdingState"
                  className="qa-input-tax-withholding-state js-input-tax-withholding-state pc-layout--right"
                  sizeVariation="full"
                  value={model.taxWithholdingState}
                  onChange={this.handleInputChange}
                  maxLength={MAXLENGTH_XID}
                  placeholder="0"
                  formattingOptions={FIVE_DECIMAL_PLACES_FORMAT}
                  validator={stateTaxWithholdingValidator}
                  data-hj-masked
                  suffix="%"
                />
              )}
            </div>
          </div>
        </div>
        <ReactTooltip effect="solid" />
      </div>
    );
  }
  /* eslint-enable */

  formatAmountReceived() {
    const { model } = this.state;
    const initialCalculation =
      model.amountAfterTax ??
      model.transferAmount *
        (1 -
          ((model.taxWithholdingFederal || 0) / 100 +
            (model.taxWithholdingState || 0) / 100));

    let returnString = formatCurrency(Math.max(initialCalculation, 0), 2);

    if (model.frequency === FREQUENCY_WEEKLY) {
      returnString = returnString.concat(" per week");
      if (model.schedule)
        returnString = returnString.concat(
          ` on ${
            model.schedule.charAt(0) + model.schedule.slice(1).toLowerCase()
          }s`
        );
    }

    if (model.frequency === FREQUENCY_MONTHLY) {
      returnString = returnString.concat(" per month");
      if (model.startDate)
        returnString = returnString.concat(
          ` on the ${moment(model.startDate).format("Do")}`
        );
    }

    if (model.frequency === FREQUENCY_YEARLY) {
      returnString = returnString.concat(" per year");
      if (model.startDate)
        returnString = returnString.concat(
          ` on ${moment(model.startDate).format("M/D")}`
        );
    }

    return returnString;
  }

  checkIsOnUsInvestment(sourceAccount) {
    return isOnUsInvestment(sourceAccount);
  }

  checkIsAcatTransfer(sourceAccount, targetAccount, model) {
    return (
      memoizedIsAcatTransfer(sourceAccount, targetAccount) &&
      model.acatTransferType &&
      model.acatTransferType === "PARTIAL"
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      personFullName,
      isToDropDownDisabled,
      isFromDropDownDisabled,
      onBack,
      errors,
      readonly,
      sourceAccounts,
      targetAccounts,
      validator,
      today,
      stateType,
      showEditAccountNumber,
      onLinkAccount,
      contributionYears,
      isTaxWithholdingEnabled,
      isPSTaxWithholdingEnabled,
      shouldShowTradingBlackoutWarningMsg,
      onEditFederalTaxWithholding,
    } = this.props;

    const {
      model,
      isCredit,
      showConfirmTransferDialog,
      showManagedEmpowerWithdrawModal,
      showNonManagedEmpowerWithdrawModal,
      showEditFederalTaxWithholdingEmpowerModal,
      showRequiredMinimumDistributionInformationModal,
      isTransferFromInvestmentAccount,
      isFormComplete,
      isPremierInvestmentSource,
      isAddAccountModalOpen,
    } = this.state;
    const sourceAccountOptions = accountsToOptions(sourceAccounts, {
      stateType,
    });
    const targetAccountOptions = accountsToOptions(targetAccounts, {
      stateType,
    });
    const submitButtonLabel = readonly ? "Submit Transfer" : "Continue";

    const externalAccount = getExternalAccount(
      model,
      sourceAccounts,
      targetAccounts
    );

    const sourceAccount = getSourceAccount(
      model.sourceAccountId,
      sourceAccounts
    );
    const targetAccount = getTargetAccount(
      model.targetAccountId,
      targetAccounts
    );

    const shouldDisplayConsent = isPccTransfer({
      sourceAccountId: model.sourceAccountId,
      targetAccountId: model.targetAccountId,
      sourceAccounts,
      targetAccounts,
    });

    let consentLabel;

    const shouldDisplayAdvisorNotes =
      (!IS_EMPOWER && this.checkIsOnUsInvestment(sourceAccount)) ||
      this.checkIsAcatTransfer(sourceAccount, targetAccount, model);

    const startDate = model.startDate
      ? startDateToMoment(model.startDate)
      : today;

    const shouldDisplayTaxWithholdings =
      isTaxWithholdingEnabled &&
      !isPSTaxWithholdingEnabled &&
      !model.id &&
      isOnUsInvestment(sourceAccount) &&
      isRetirementAccount(sourceAccount) &&
      isBankAccount(targetAccount);

    const shouldDisplayPSTaxWithholdings =
      !shouldDisplayTaxWithholdings &&
      isPSTaxWithholdingEnabled &&
      !model.id &&
      model.transferAmount > 0 &&
      isOnUsInvestment(sourceAccount) &&
      isRetirementAccount(sourceAccount) &&
      isBankAccount(targetAccount);

    const isTargetAccountRoth = isIraAccountByType(targetAccount, "ROTH");

    const shouldShowIraContributionWarning =
      !IS_EMPOWER &&
      isBankAccount(sourceAccount) &&
      (isIraAccountByType(targetAccount, "TRADITIONAL") || isTargetAccountRoth);

    const shouldShowCryptoWarning =
      model.acatTransferType === "FULL" &&
      memoizedIsAcatTransfer(sourceAccount, targetAccount) &&
      !readonly &&
      this.props.shouldShowCryptoWarning;

    const shouldShowFrequencyDropdown =
      !memoizedIsAcatTransfer(sourceAccount, targetAccount) &&
      !(IS_EMPOWER && isPremierInvestmentSource);

    // Determine the correct text based on frequency selection and whether it's a withdrawal or deposit
    if (shouldDisplayConsent) {
      if (isCredit) {
        consentLabel =
          model.frequency === FREQUENCY_ONE_TIME ? (
            <div className="u-preserve-case">
              I, <span data-hj-masked>{personFullName}</span>, authorize UMB to
              initiate a single ACH electronic transfer as shown above on{" "}
              {today.format(FULL_MONTH_DATE_YEAR_FORMAT)}. This transfer will be
              swept from Program Accounts to your Funding Account and then
              transferred as shown above. The {organizationNameCash}™ Customer
              Account balance shown above is your Available Program Balance.
            </div>
          ) : (
            <div className="u-preserve-case">
              I, <span data-hj-masked>{personFullName}</span>, authorize UMB to
              initiate a {formatFrequency(model.frequency)} recurring ACH
              electronic transfer as shown above beginning on{" "}
              {startDate.format(FULL_MONTH_DATE_YEAR_FORMAT)} until I cancel.
              We’ll process your transfer on the next business day if it falls
              on a weekend or holiday. These funds will be swept from Program
              Accounts to your Funding Account at UMB and then transferred as
              shown above. The {organizationNameCash} Customer Account balance
              shown above is your Available Program Balance.
            </div>
          );
      } else {
        consentLabel =
          model.frequency === FREQUENCY_ONE_TIME ? (
            <div className="u-preserve-case">
              I, <span data-hj-masked>{personFullName}</span>, authorize UMB to
              initiate a single ACH electronic transfer as shown above on{" "}
              {today.format(FULL_MONTH_DATE_YEAR_FORMAT)}. This transfer will be
              transferred into your Funding Account at UMB as shown above and
              then swept into Program Accounts. The {organizationNameCash}™
              Customer Account balance shown above is your Available Program
              Balance.
            </div>
          ) : (
            <div className="u-preserve-case">
              I, <span data-hj-masked>{personFullName}</span>, authorize UMB to
              initiate a {formatFrequency(model.frequency)} recurring ACH
              electronic transfer as shown above beginning on{" "}
              {startDate.format(FULL_MONTH_DATE_YEAR_FORMAT)} until I cancel.
              We’ll process your transfer on the next business day if it falls
              on a weekend or holiday. These funds will be transferred into your
              funding Account at UMB as shown above and then swept into Program
              Accounts. The {organizationNameCash} Customer Account balance
              shown above is your Available Program Balance.
            </div>
          );
      }
    }
    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className="transfer-funds"
      >
        <section>
          <Message className="pc-u-mb" severity="error" messages={errors} />
          <Message
            className="pc-u-mb"
            severity="warning"
            messages={contributionYears?.messages}
          />
          <div className="pc-layout--middle pc-layout--small pc-form-group">
            <div className="pc-layout__item pc-u-1/4">
              <label
                className="pc-label"
                id="ariaSourceAccountIdLabel"
                htmlFor="ariaSourceAccountIdInput"
              >
                From
              </label>
            </div>
            <div className="pc-layout__item pc-u-3/4">
              <AccountSelect
                id="sourceAccountId"
                name="sourceAccountId"
                className="Select--account qa-input-from-account js-input-from-account"
                placeholder="Select an account…"
                options={sourceAccountOptions}
                isOptionDisabled={this.shouldDisableOptionSource}
                value={model.sourceAccountId}
                ref={this.storeInputRef}
                onChange={this.handleSourceAccountChange}
                onMenuOpen={this.handleSourceAccountMenuOpen}
                isDisabled={isFromDropDownDisabled || readonly}
                menuPortalTarget={document.body}
                menuFooterComponent={
                  isOnusSourceAccountDropdown(sourceAccounts) ? undefined : (
                    <div className="account-select-footer">
                      <button
                        type="button"
                        onClick={
                          IS_ADVISOR
                            ? () => this.handleLinkAccount(true)
                            : onLinkAccount
                        }
                        className="pc-btc pc-btn--link pc-btn--tiny"
                      >
                        {sourceAccountOptions.length
                          ? "Link a new account."
                          : "Link Account"}
                      </button>
                    </div>
                  )
                }
                data-hj-masked
                inputId="ariaSourceAccountIdLabel"
                selectInputId="ariaSourceAccountIdInput"
              />
            </div>
          </div>
          <hr className="transfer-funds__divider pc-u-mv" />
          {showEditAccountNumber &&
            this.renderViewAccountNumberLink(externalAccount, "source")}
          <div className="pc-layout--middle pc-layout--small pc-form-group">
            <div className="pc-layout__item pc-u-1/4">
              <label
                className="pc-label"
                id="ariaTargetAccountIdLabel"
                htmlFor="ariaTargetAccountIdInput"
              >
                To
              </label>
            </div>
            <div className="pc-layout__item pc-u-3/4">
              <AccountSelect
                id="targetAccountId"
                name="targetAccountId"
                data-testid="targetAccountId"
                className="Select--account qa-input-to-account js-input-to-account"
                placeholder="Select an account…"
                options={targetAccountOptions}
                value={model.targetAccountId}
                ref={this.storeInputRef}
                onChange={this.handleTargetAccountChange}
                onMenuOpen={this.handleTargetAccountMenuOpen}
                isDisabled={isToDropDownDisabled || readonly}
                isOptionDisabled={this.shouldDisableOptionTarget}
                menuPortalTarget={document.body}
                menuFooterComponent={
                  isOnusTargetAccountDropdown(targetAccounts) ? undefined : (
                    <div className="account-select-footer">
                      <button
                        type="button"
                        onClick={
                          IS_ADVISOR
                            ? () => this.handleLinkAccount(true)
                            : onLinkAccount
                        }
                        className="pc-btc pc-btn--link pc-btn--tiny"
                      >
                        {targetAccountOptions.length
                          ? "Transfer to a different account."
                          : "Link Account"}
                      </button>
                    </div>
                  )
                }
                data-hj-masked
                inputId="ariaTargetAccountIdLabel"
                selectInputId="ariaTargetAccountIdInput"
              />
            </div>
          </div>
          {showEditAccountNumber &&
            this.renderViewAccountNumberLink(externalAccount, "target")}
          {memoizedIsAcatTransfer(sourceAccount, targetAccount)
            ? this.renderAcatSection()
            : this.renderAchSection()}
          {readonly && model.hasRMD && (
            <div className="pc-layout pc-layout--small pc-layout--right js-amount-received">
              <p className="pc-layout__item pc-u-3/4 transfer-funds__rmd-secondary-text">
                This withdrawal will count towards your annual required minimum
                distribution (RMD) required by the IRS.
              </p>
            </div>
          )}
          {shouldDisplayTaxWithholdings && this.renderTaxWithholdingSection()}
          {shouldShowFrequencyDropdown &&
            this.renderRecurringSection(
              shouldDisplayAdvisorNotes,
              sourceAccount
            )}
          {shouldDisplayPSTaxWithholdings && (
            <TransferFundsWithdrawalTaxWithholdingContainer
              onChange={this.handleInputChange}
              updateModel={this.updateModel}
              storeInputRef={this.storeInputRef}
              model={model}
              onEditFederalTaxWithholding={
                IS_EMPOWER
                  ? this.handleEditFederalTaxWithholdingEmpowerModalShow
                  : onEditFederalTaxWithholding
              }
            />
          )}
          {shouldDisplayAdvisorNotes && (
            <div className="pc-layout pc-layout--small pc-form-group">
              <div className="pc-layout__item pc-u-1/4">
                <label
                  className="pc-label js-instructions-label"
                  htmlFor="instructions"
                >
                  Instructions
                </label>
              </div>
              <div className="pc-layout__item pc-u-3/4">
                {readonly ? (
                  <div className="js-review-transfer-instructions u-text-bold">
                    {model.instructions}
                  </div>
                ) : (
                  <Textarea
                    id="instructions"
                    name="instructions"
                    ref={this.storeInputRef}
                    className="js-input-instructions transfer-funds__advisor-notes pc-u-1/1"
                    rows={3}
                    value={model.instructions}
                    placeholder="You can specify a percent of the account, a specific dollar amount, or specific securities."
                    onChange={this.handleInputChange}
                    validator={validator.instructions}
                    data-hj-masked
                  />
                )}
              </div>
            </div>
          )}
          {shouldShowCryptoWarning && (
            <WarningToolTip
              className="js-crypto-warning"
              label={CRYPTO_WARNING_LABEL}
              message={CRYPTO_WARNING_MESSAGE}
            />
          )}
          {shouldShowIraContributionWarning && (
            <WarningToolTip
              className="js-ira-contribution-warning"
              label={IRA_CONTRIBUTION_WARNING_LABEL}
              message={
                isTargetAccountRoth
                  ? IRA_CONTRIBUTION_WARNING_ROTH_MESSAGE
                  : IRA_CONTRIBUTION_WARNING_TRADITIONAL_MESSAGE
              }
            />
          )}
          {shouldDisplayConsent && (
            <div className="pc-form-group pc-u-mb+">
              <Checkbox
                ref={this.storeInputRef}
                name="isConsented"
                value={true}
                checked={model.isConsented}
                className="qa-input-consent js-input-consent"
                checkboxClassName="transfer-funds__consent-input pc-u-mr--"
                labelClassName="transfer-funds__consent-label"
                label={consentLabel}
                validator={validator.isConsented}
                disabled={readonly}
                onChange={this.handleInputChange}
              />
            </div>
          )}
          {shouldDisplayTaxWithholdings && model.transferAmount && (
            <div className="pc-layout pc-layout--small js-amount-received">
              <p className="pc-layout__item pc-u-1/4">Amount You Receive</p>
              <p className="pc-layout__item pc-u-3/4 u-text-bold">
                {this.formatAmountReceived()}
              </p>
            </div>
          )}
          {shouldShowTradingBlackoutWarningMsg && (
            <div className="pc-u-mb- pc-u-pt">
              Transactions cannot be conducted 10 minutes prior to market
              closure (3:50 PM EST).
            </div>
          )}
          <div className="pc-modal__footer pc-modal__footer--transfer-funds">
            {onBack && (
              <button
                type="button"
                className="js-transfer-funds-fund-account-back pc-btn"
                onClick={this.handleOnBack}
              >
                Back
              </button>
            )}
            <button
              type="submit"
              className="qa-button-submit js-transfer-funds-fund-account-next pc-btn pc-btn--primary"
              disabled={
                this.props.disableSubmitButton || (!readonly && !isFormComplete)
              }
            >
              {submitButtonLabel}
            </button>
          </div>
        </section>
        {showConfirmTransferDialog && (
          <ConfirmModal
            onConfirm={this.handleConfirmTransfer}
            onCancel={this.handleCancelTransfer}
            isOpen={true}
          >
            {`The transfer amount is greater than the last available${
              isTransferFromInvestmentAccount ? " cash " : " "
            }balance retrieved for this account. Do you want to continue?`}
          </ConfirmModal>
        )}
        {showManagedEmpowerWithdrawModal && (
          <EmpowerWithdrawManagedModal
            onConfirm={this.handleConfirmEmpowerWithdrawManagedModal}
            onCancel={this.handleCancelEmpowerWithdrawManagedModal}
            onClosed={this.handleCancelEmpowerWithdrawManagedModal}
          />
        )}
        {showNonManagedEmpowerWithdrawModal && (
          <EmpowerWithdrawNonManagedModal
            onConfirm={this.handleConfirmEmpowerWithdrawNonManagedModal}
            onCancel={this.handleCancelEmpowerWithdrawNonManagedModal}
            onClosed={this.handleClosedEmpowerWithdrawNonManagedModal}
          />
        )}
        {IS_ADVISOR && (
          <AddManualAccountModal
            isOpen={isAddAccountModalOpen}
            onCancel={() => this.handleLinkAccount(false)}
          />
        )}
        {showEditFederalTaxWithholdingEmpowerModal && (
          <Modal
            componentName="EditFederalTaxWithholdingModal"
            contentLabel="Edit Federal Tax Withholding Modal"
            title="Edit federal tax withholding"
            className="pc-modal--medium"
            shouldCloseOnOverlayClick={false}
            isOpen={true}
            onClosed={this.handleEditFederalTaxWithholdingEmpowerModalCancel}
          >
            <EditFederalTaxWithholding
              onCancel={this.handleEditFederalTaxWithholdingEmpowerModalCancel}
              onSave={this.handleEditFederalTaxWithholdingEmpowerModalSave}
              originalTaxWithholdingPercentage={model.taxWithholdingFederal}
              federalTaxOptOut={model.federalTaxOptOut}
            ></EditFederalTaxWithholding>
          </Modal>
        )}
        {showRequiredMinimumDistributionInformationModal && (
          <RequiredMinimumDistributionInformationModal
            onClosed={
              this.handleRequiredMinimumDistributionInformationModalCancel
            }
          />
        )}
      </form>
    );
  }
}

TransferFunds.propTypes = {
  errors: PropTypes.array,
  sourceAccounts: PropTypes.array,
  targetAccounts: PropTypes.array,
  model: PropTypes.object,
  readonly: PropTypes.bool,
  isToDropDownDisabled: PropTypes.bool,
  isFromDropDownDisabled: PropTypes.bool,
  isContributionYearDisabled: PropTypes.bool,
  hasOneTimeFrequency: PropTypes.bool,
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onLinkAccount: PropTypes.func,
  onSourceAccountChange: PropTypes.func,
  onTargetAccountChange: PropTypes.func,
  onViewAccountNumber: PropTypes.func,
  personFullName: PropTypes.string,
  amountFormatter: PropTypes.object,
  today: PropTypes.objectOf(moment),
  stateType: PropTypes.string,
  transferType: PropTypes.number.isRequired,
  contributionYears: PropTypes.object,
  showEditAccountNumber: PropTypes.bool,
  targetAccountOwnerAge: PropTypes.number,
  hasRecurringTransferEstablished: PropTypes.bool,
  isPreviousYearContribution: PropTypes.bool,
  onModelChange: PropTypes.func,
  recurringTransferHelpText: PropTypes.string,
  isTaxWithholdingEnabled: PropTypes.bool,
  isPSTaxWithholdingEnabled: PropTypes.bool,
  isRMDEnabled: PropTypes.bool,
  shouldShowCryptoWarning: PropTypes.bool,
  shouldShowTradingBlackoutWarningMsg: PropTypes.bool,
  disableSubmitButton: PropTypes.bool,
  onEditFederalTaxWithholding: PropTypes.func,
};

TransferFunds.defaultProps = {
  model: {},
  today: moment(),
  onSourceAccountChange: noop,
  onTargetAccountChange: noop,
  onViewAccountNumber: noop,
  onLinkAccount: undefined,
  amountFormatter: CurrencyFormatterWithCents(2),
  errors: [],
  sourceAccounts: [],
  targetAccounts: [],
  isToDropDownDisabled: false,
  isFromDropDownDisabled: false,
  isContributionYearDisabled: false,
  hasOneTimeFrequency: true,
  stateType: undefined,
  validator: {
    accountId: {
      allowEmpty: false,
      required: true,
      type: "number",
      messages: {
        required: MESSAGES_NOT_EMPTY,
      },
    },
    startDate: DatePickerInput.getValidator({
      valueDateFormat: DISPLAY_FORMAT,
      allowEmpty: false,
      isAllowedDate: isAllowedDate,
    }),
    startDateWeekly: DatePickerInput.getValidator({
      valueDateFormat: DISPLAY_FORMAT,
      allowEmpty: false,
      isAllowedDate: isAllowedDateWeekly,
    }),
    schedule: {
      required: true,
      allowEmpty: false,
      messages: {
        required: MESSAGES_SELECTED,
      },
    },
    frequency: {
      required: true,
      allowEmpty: false,
      messages: {
        required: MESSAGES_SELECTED,
      },
    },
    transferAmount: {
      type: "number",
      required: true,
      maxLength: MAXLENGTH_XID,
      messages: {
        required: MESSAGES_NOT_EMPTY,
      },
    },
    contributionYear: {
      type: "number",
      required: true,
      allowEmpty: false,
      messages: {
        required: MESSAGES_SELECTED,
        allowEmpty: MESSAGES_SELECTED,
      },
    },
    acatTransferType: {
      type: "string",
      allowEmpty: false,
      messages: {
        required: MESSAGES_SELECTED,
      },
    },
    instructions: {
      type: "string",
      required: !IS_ADVISOR,
      allowEmpty: IS_ADVISOR,
      messages: {
        required: MESSAGES_NOT_EMPTY,
      },
    },
    isConsented: {
      type: "boolean",
      required: true,
      messages: {
        required:
          "You must authorize the transfer in order to submit the request.",
      },
    },
  },
  contributionYears: undefined,
  showEditAccountNumber: false,
  targetAccountOwnerAge: undefined,
  hasRecurringTransferEstablished: false,
  isPreviousYearContribution: false,
  onModelChange: noop,
  recurringTransferHelpText: undefined,
  isTaxWithholdingEnabled: false,
  isPSTaxWithholdingEnabled: false,
  isRMDEnabled: false,
  shouldShowCryptoWarning: true,
  shouldShowTradingBlackoutWarningMsg: false,
  disableSubmitButton: false,
  onEditFederalTaxWithholding: noop,
};

TransferFunds.contextType = EditRecurringTransfersContext;

/* eslint-disable no-undef */
function HelpBlockRecurringTransferExists() {
  return (
    <div className="pc-help-block pc-help-block--small">
      A recurring transfer is already established between these accounts so only
      a one time transfer is allowed. Use{" "}
      <a href={RECURRING_TRANSFERS_URL}>this link</a> to view the existing
      recurring transfer.
    </div>
  );
}
/* eslint-enable */

export default TransferFunds;
