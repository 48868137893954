import { first } from "underscore";

function getFirstPersonIdForRole(personsWithRole) {
  if (!personsWithRole) {
    return;
  }

  return first(personsWithRole).personId;
}

function buildStakeholders(account) {
  const stakeholders = {};
  if (account.stakeholders) {
    stakeholders.primaryOwner = getFirstPersonIdForRole(
      account.stakeholders.PRIMARY
    );
    stakeholders.secondaryOwner = getFirstPersonIdForRole(
      account.stakeholders.SECONDARY
    );
    stakeholders.custodian = getFirstPersonIdForRole(
      account.stakeholders.CUSTODIAN
    );
    stakeholders.guardian = getFirstPersonIdForRole(
      account.stakeholders.GUARDIAN
    );
    stakeholders.executor = getFirstPersonIdForRole(
      account.stakeholders.EXECUTOR
    );
  }
  return stakeholders;
}

function buildStockOptionsDetails(account) {
  if (!account.isEsog) {
    return {};
  }

  return {
    shouldOnlyTrackUnvestedShares: !account.trackVestedOptions,
    shouldStopVesting: Boolean(account.stopVestingDate),
  };
}

/**
 * Takes a request object and converts to client-side format.
 *
 * @param {Object} account the API response
 * @returns {Object} the account model
 */
export default function (account) {
  return Object.assign(
    {},
    account,
    buildStakeholders(account),
    buildStockOptionsDetails(account)
  );
}
