import React from "react";
import PropTypes from "prop-types";
import WarningMessage from "../../WarningMessage/WarningMessage";

const VerifyTransferMessage = (props) => {
  return (
    <div className={props.className}>
      <h1 className="nav-secondary__title">Attention</h1>
      <div className="pc-u-pt">
        <WarningMessage
          message={props.message}
          containerClassName="verify-transfer__warning-notification-message__container"
          iconClassName="verify-transfer__warning-notification-message__icon"
        />
      </div>
      {props.shouldShowTradingBlackoutWarningMsg && (
        <div className="pc-u-mb- pc-u-pt">
          Transactions cannot be conducted 10 minutes prior to market closure
          (3:50 PM EST).
        </div>
      )}
      <div className="pc-layout pc-layout--right verify-transfer__btn">
        <button
          type="button"
          className="pc-btn back-btn js-verify-transfer-back-btn"
          onClick={props.onBack}
        >
          Back
        </button>
        <button
          type="button"
          className="pc-btn pc-btn--primary js-verify-transfer-return-to-home-btn"
          onClick={props.onReturnToHome}
        >
          Return to Home
        </button>
      </div>
    </div>
  );
};

VerifyTransferMessage.propTypes = {
  message: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onReturnToHome: PropTypes.func.isRequired,
  className: PropTypes.string,
  shouldShowTradingBlackoutWarningMsg: PropTypes.bool,
};

VerifyTransferMessage.defaultProps = {
  className: undefined,
  shouldShowTradingBlackoutWarningMsg: false,
};

export default VerifyTransferMessage;
