import React from "react";
import PropTypes from "prop-types";
import { promisify } from "utils/service";
import Services from "services";
import AddManualAccountForm from "../AddManualAccountForm";
import noop from "utils/noop";
import { filterAccountTypes } from "./utils";
import EnrollmentServices from "enrollmentServices";
import { pluck } from "underscore";
import { isEmpowerPrivilegedMode } from "../../../views/modules/sidebar/utils/accountUtils";

const INVESTMENT_PRODUCT = /INVESTMENT/;

export default class AddManualAccountContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {},
      accountTypeGroups: [],
      firmNameSuggestions: [],
      loading: false,
      errors: [],
    };

    this.fetchFirmNameSuggestions = this.fetchFirmNameSuggestions.bind(this);
    this.handleFormSave = this.handleFormSave.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getAccountTypes({ source: "ENROLLMENT" })
      .then((accountTypes) => {
        this.setState({ accountTypeGroups: filterAccountTypes(accountTypes) });
      })
      .catch((err) => this.setState({ errors: err }))
      .finally(() => this.setState({ loading: false }));
  }

  fetchFirmNameSuggestions(firmName) {
    this.props
      .searchSites({
        searchString: firmName,
        productTypes: JSON.stringify(["BANK", "INVESTMENT"]),
      })
      .then((sites) => {
        const sitesNames = pluck(sites, "name");
        const firmNameSuggestions = sitesNames.map((site, index) => ({
          label: site,
          value: site,
          index,
        }));
        this.setState({ firmNameSuggestions });
      })
      .catch((errors) => this.setState({ errors }));
  }

  handleFormSave({
    accountTypeGroup,
    accountTypeNew,
    accountTypeSubtype,
    currentBalance,
    firmName,
  }) {
    const { createAccounts, onCancel, onSave, updateHoldings } = this.props;

    this.setState({ loading: true });

    createAccounts({
      isManual: true,
      firmName,
      accountTypeGroup,
      accountTypeNew,
      accountTypeSubtype,
      currentBalance,
      unlinkTargetAccounts: true,
      requestSource: "ENROLLMENT",
    })
      .then((res) => {
        const { userAccountId, productType } = res.accounts[0];

        if (!INVESTMENT_PRODUCT.test(productType)) {
          return Promise.resolve();
        }

        return updateHoldings({
          userAccountId,
          description: "Cash",
          quantity: currentBalance,
          price: 1,
          priceSource: "USER",
          sourceAssetId: "USER_DESCR_Cash",
        });
      })
      .then(() => {
        onSave(() => this.setState({ loading: false }, onCancel));
      })
      .catch((errors) => this.setState({ errors, loading: false }));
  }

  render() {
    const { onCancel } = this.props;
    const { accountTypeGroups, firmNameSuggestions, loading, errors } =
      this.state;
    const isPrivileged = isEmpowerPrivilegedMode();

    return (
      <AddManualAccountForm
        accountTypeGroups={accountTypeGroups}
        errors={errors}
        firmNameSuggestions={firmNameSuggestions}
        loading={loading}
        onCancel={onCancel}
        onFirmNameChange={this.fetchFirmNameSuggestions}
        onSave={this.handleFormSave}
        isPrivileged={isPrivileged}
      />
    );
  }
}

AddManualAccountContainer.propTypes = {
  createAccounts: PropTypes.func,
  getAccountTypes: PropTypes.func,
  searchSites: PropTypes.func,
  updateHoldings: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

AddManualAccountContainer.defaultProps = {
  createAccounts: promisify(Services.Accounts.create),
  getAccountTypes: promisify(Services.AccountTypes.get),
  searchSites: promisify(EnrollmentServices.SearchSites.get),
  updateHoldings: promisify(Services.Holdings.update),
  onCancel: noop,
  onSave: noop,
};
