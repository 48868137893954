import objectPath from "object-path";

export function getInitialModelWithOptionFields(fields = []) {
  let model = {};
  let optionFields = fields.filter(
    (field) =>
      field.parts.some(
        (parts) =>
          parts.type === "OPTIONS" ||
          parts.type === "RADIO" ||
          parts.type === "CHECKBOX"
      ) && field.depends === undefined
  );
  optionFields.forEach((optionalField) => {
    optionalField.parts.forEach((part) => {
      let valueToSetInModel;
      switch (part.type) {
        case "CHECKBOX":
          if (part.validIds) {
            valueToSetInModel = part.validIds[0];
          } else {
            valueToSetInModel = false;
          }
          break;
        default:
          //"OPTIONS", "RADIO"
          valueToSetInModel = part.validIds[0];
          break;
      }
      objectPath.set(model, part.id, valueToSetInModel);
    });
  });
  return model;
}
