import { isEmpty } from "underscore";

const amountSummaryAccessor = (data) => {
  return Number(
    data
      .reduce((total, transaction) => {
        if (transaction.isEmpower) return total + (transaction.amount ?? 0);

        let transactionAmount;
        if (isEmpty(transaction.splits)) {
          transactionAmount = transaction.amount;
        } else {
          transactionAmount = transaction.splits
            .filter((s) => !s.excludeSplit && !s.filterSplit)
            .reduce((iv, s) => iv + s.amount, 0);
        }

        transactionAmount = transaction.isCredit
          ? transactionAmount
          : -transactionAmount;

        return total + transactionAmount;
      }, 0)
      .toFixed(2)
  );
};

export default amountSummaryAccessor;
