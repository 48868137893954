import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import FinancialPlanOffer from "components/FinancialPlanOffer/FinancialPlanOffer";

const COMPONENT_NAME = "Financial Plan Offer";
const MODAL_WIDTH = 848;

export default class FinancialPlanOfferModal extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      showModal: true,
      modalTitle:
        "Gain total financial confidence with a Personalized Financial Plan",
      pageNumber: "",
    };
  }

  static getDerivedStateFromProps(props) {
    if (!props || !props.pageNumber) {
      return {};
    }
    return { pageNumber: props.pageNumber };
  }

  render() {
    const { onModalClosed } = this.props;
    const { showModal, modalTitle, pageNumber } = this.state;
    return (
      <Modal
        componentName={COMPONENT_NAME}
        className="fin-plan-offer__modal"
        title={modalTitle}
        onClosed={onModalClosed}
        // react-modal APIs
        contentLabel={COMPONENT_NAME}
        isOpen={showModal}
        style={{ content: { width: MODAL_WIDTH } }}
        shouldCloseOnOverlayClick={false}
      >
        <FinancialPlanOffer
          ref={(el) => {
            this.formContainer = el;
          }}
          pageNumber={pageNumber}
          componentName={COMPONENT_NAME}
        />
      </Modal>
    );
  }
}

FinancialPlanOfferModal.propTypes = {
  onModalClosed: PropTypes.func.isRequired,
};
