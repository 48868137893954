const STATE_NON_QUAL = "NON-QUAL";
const STATE_QUAL_NOT_ASSIGNED = "QUAL_NOT_ASSIGNED";
const STATE_AGG_QUAL = "AGG_QUAL";
const STATE_CLIENT = "CLIENT";
const STATE_CLIENT_PCG_QUAL = "CLIENT_PCG_QUAL";
const STATE_CLIENT_PCG = "CLIENT_PCG";
const STATE_CLIENT_INVESTMENT_SERVICE = "CLIENT_INVESTMENT_SERVICE";

/**
 * Calculates user state based on the codified user stage from `spHeader`.
 *
 * @param  {String} userStage         Codified user stage.
 * @param  {String} assignmentStatus  Assignment status from `getAdvisor` api.
 * @return {String}                   The user stage for the page.
 */
const getUserState = (userStage, assignmentStatus) => {
  // by default show non-qual version
  let result = STATE_NON_QUAL;

  if (userStage === "C" || userStage === "D") {
    if (assignmentStatus === "ASSIGNED") {
      result = STATE_AGG_QUAL;
    } else {
      result = STATE_QUAL_NOT_ASSIGNED;
    }
  } else if (userStage === "E") {
    result = STATE_CLIENT;
  } else if (userStage === "F") {
    result = STATE_CLIENT_PCG_QUAL;
  } else if (userStage === "G") {
    result = STATE_CLIENT_PCG;
  } else if (userStage === "H") {
    result = STATE_CLIENT_INVESTMENT_SERVICE;
  }
  return result;
};

const isClient = (userStage, assignmentStatus) => {
  return [
    STATE_CLIENT,
    STATE_CLIENT_PCG_QUAL,
    STATE_CLIENT_PCG,
    STATE_CLIENT_INVESTMENT_SERVICE,
  ].includes(getUserState(userStage, assignmentStatus));
};

const isAggQualProspect = (userStage, assignmentStatus) => {
  return [STATE_AGG_QUAL, STATE_QUAL_NOT_ASSIGNED].includes(
    getUserState(userStage, assignmentStatus)
  );
};

export {
  isClient,
  isAggQualProspect,
  getUserState,
  STATE_NON_QUAL,
  STATE_QUAL_NOT_ASSIGNED,
  STATE_AGG_QUAL,
  STATE_CLIENT,
  STATE_CLIENT_PCG_QUAL,
  STATE_CLIENT_PCG,
  STATE_CLIENT_INVESTMENT_SERVICE,
};
