import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";

function RetirementAgeValidationModal(props) {
  return (
    <Modal
      componentName={props.componentName}
      contentLabel="Confirm Age"
      title="Confirm Age"
      isOpen={true}
      className="pc-modal--small pc-confirm-age-modal"
      showCloseButton={false}
      onClosed={props.onClosed}
    >
      <p>It looks like you&apos;re already retired. Is that correct?</p>
      <div className="u-text-right pc-modal__actions">
        <button
          type="button"
          className="pc-btn pc-modal-no-button u-preserve-case"
          onClick={props.handleNo}
        >
          No, I&apos;m not retired
        </button>
        <button
          type="submit"
          className="pc-btn pc-btn--primary pc-modal-yes-button u-preserve-case"
          onClick={props.handleYes}
        >
          Yes, I&apos;m retired
        </button>
      </div>
    </Modal>
  );
}

RetirementAgeValidationModal.propTypes = {
  componentName: PropTypes.string,
  handleNo: PropTypes.func,
  handleYes: PropTypes.func,
  onClosed: PropTypes.func,
};
RetirementAgeValidationModal.defaultProps = {
  componentName: "confirm-age-modal",
  handleNo: undefined,
  handleYes: undefined,
  onClosed: undefined,
};

export default RetirementAgeValidationModal;
