import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { noop } from "underscore";
import InfoTooltipIcon from "components/common/InfoTooltipIcon/InfoTooltipIcon";
import AccountBox from "components/AccountBox/AccountBox";

const TOOLTIP_ROUTING_NUMBER =
  "Your bank routing number is a nine-digit code that's based on the U.S. Bank location where your account was opened. It's the first set of numbers printed on the bottom of your checks, on the left side.";

const AccountNumberFormView = (props) => {
  const {
    firmName,
    accountName,
    logoPath,
    balance,
    accountNumber,
    routingNumber,
    className,
    onEdit,
    onMounted,
    onContinue,
    onClose,
    isEmpowerTransferReviewModal,
  } = props;
  useEffect(() => onMounted("Account Details"), [onMounted]);
  return (
    <div>
      <div className={className}>
        <AccountBox
          showLogo={true}
          logoPath={logoPath}
          firmName={firmName}
          name={accountName}
          balance={balance}
          className="pc-u-2/3 pc-u-mb+"
        />
        <div className="centi pc-layout">
          <div className="pc-layout__item pc-u-1/4">
            Routing Number
            <InfoTooltipIcon
              title={TOOLTIP_ROUTING_NUMBER}
              className="pc-u-ml--"
            />
          </div>
          <strong className="qa-account-number-form-view-routing-num pc-layout__item pc-u-1/4">
            {routingNumber}
          </strong>
        </div>
        <div className="account-number-form-view__divider" />
        <div className="centi pc-u-mb pc-layout">
          <div className="pc-layout__item pc-u-1/4">Account Number</div>
          <strong className="qa-account-number-form-view-acc-num pc-layout__item pc-u-1/4">
            {accountNumber}
          </strong>
        </div>
      </div>
      <div className="account-number-form-view__buttons">
        {isEmpowerTransferReviewModal ? (
          <button
            type="button"
            className="pc-btn js-account-number-form-view-close"
            onClick={onClose}
          >
            Close
          </button>
        ) : (
          <>
            <button
              type="button"
              className="pc-btn js-account-number-form-view-edit"
              onClick={onEdit}
            >
              Edit
            </button>
            <button
              type="button"
              className="pc-btn pc-btn--primary js-account-number-form-view-continue qa-account-number-form-view-continue"
              onClick={onContinue}
            >
              Continue
            </button>
          </>
        )}
      </div>
    </div>
  );
};

AccountNumberFormView.propTypes = {
  firmName: PropTypes.string.isRequired,
  accountName: PropTypes.string,
  accountNumber: PropTypes.string,
  routingNumber: PropTypes.string,
  logoPath: PropTypes.string,
  className: PropTypes.string,
  balance: PropTypes.number,
  isEmpowerTransferReviewModal: PropTypes.bool,
  onEdit: PropTypes.func,
  onContinue: PropTypes.func,
  onClose: PropTypes.func,
  onMounted: PropTypes.func,
};

AccountNumberFormView.defaultProps = {
  accountNumber: "-",
  routingNumber: "-",
  accountName: undefined,
  logoPath: undefined,
  className: undefined,
  balance: undefined,
  isEmpowerTransferReviewModal: false,
  onEdit: noop,
  onContinue: noop,
  onClose: noop,
  onMounted: noop,
};

export default AccountNumberFormView;
