import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Input from "components/common/form/Input";
import Checkbox from "components/common/form/Checkbox";
import PersonSelect from "common/PersonSelect";
import { noop, isEmpty } from "underscore";
import FancySelect from "components/common/form/FancySelect/index";
import deepCopy from "deep-copy";
import { FIRST_NAME } from "utils/patterns";
import { EMAIL, US_PHONE } from "utils/patterns";
import { PHONE_NUMBER_FORMAT } from "components/common/form/formattingOptions";
import { CSSTransition } from "react-transition-group";
import getAvailableRelationships from "views/modules/enrollmentNew/utils/getAvailableRelationships";
import LegalNameInput from "components/common/form/LegalNameInput";
import InfoTooltipIcon from "components/common/InfoTooltipIcon";

const organizationNameAccount = `${EMPOWER_PERSONAL_DASHBOARD} account`;

const organizationNameCash = "Empower Personal Cash™";

function isNew(id) {
  return id === -1;
}

function createNewPerson() {
  return {
    id: -1,
    name: {
      firstName: "",
      lastName: "",
      middleName: "",
      salutation: "",
      suffix: "",
    },
  };
}

function getDisabledFieldsConfig(name, disabled) {
  return {
    [`${name}.id`]: disabled,
    [`${name}.name`]: disabled,
    [`${name}.emailAddress`]: disabled,
    [`${name}.phone`]: disabled,
    [`${name}.relationship`]: disabled,
  };
}

export function getDisabledFieldsConfigForPcapUser(name) {
  return {
    [`${name}.emailAddress`]: true,
    [`${name}.phone`]: true,
  };
}

export default function SelectPersonInput({
  name,
  value = { name: {} }, // person record
  refFunc,
  onChange,
  selectOptions,
  household,
  validator,
  selectDropdownHeading,
  selectDropdownPlaceholder,
  consentLabel,
  showPersonDropdown,
  showRelationship,
  isAddNewPersonEnabled,
  loading,
  disabled,
}) {
  const [person, setPerson] = useState(value);
  const newPerson =
    isAddNewPersonEnabled && isEmpty(selectOptions)
      ? createNewPerson()
      : { name: {} };
  const [isConsented, setConsent] = useState();
  const relationships = useMemo(() => {
    return getAvailableRelationships(household, person.relationship);
    // Self should never appear in the dropdown of relationships.
    // Removing the relationship manually as an additional precaution.
    //return res.filter(r => r.value !== "SELF");
  }, [household, person]);

  const handlePersonChange = (ev) => {
    const { value } = ev.target;
    const person = isNew(value)
      ? createNewPerson()
      : deepCopy(household.find((p) => p.id === value));

    setPerson(person);
    onChange({
      target: {
        name,
        value: person,
      },
    });
  };

  let disabledConfig;
  if (typeof disabled === "boolean") {
    disabledConfig = getDisabledFieldsConfig(name, disabled);
  } else {
    disabledConfig = disabled;
  }

  const personId = person.id || newPerson.id;

  // Show loader only when selected person is not empty
  if (loading && !person.id) {
    return null;
  }

  return (
    <>
      <label className="pc-label pc-u-mb--" htmlFor="personId">
        {selectDropdownHeading}
      </label>
      {showPersonDropdown && (
        <div className="pc-layout pc-layout--small pc-form-group">
          <div className="pc-layout__item pc-u-1/2">
            <PersonSelect
              id="personId"
              ref={refFunc}
              value={personId}
              name={`${name}.id`}
              people={selectOptions}
              onChange={handlePersonChange}
              isAddNewPersonEnabled={isAddNewPersonEnabled}
              validator={validator.properties.id}
              placeholder={selectDropdownPlaceholder}
              disabled={disabledConfig[`${name}.id`]}
              className="qa-input-person-select"
            />
          </div>
        </div>
      )}
      <CSSTransition
        in={Boolean(personId)}
        unmountOnExit={true}
        className="js-joint-account-owner qa-secondary-account-owner"
        classNames={
          showRelationship
            ? "transition-joint-account-owner-input"
            : "transition-joint-account-owner-input--no-relationship"
        }
        timeout={250}
      >
        <div>
          <div className="pc-layout pc-layout--small pc-form-group">
            <div className="pc-layout__item">
              <LegalNameInput
                refFunc={refFunc}
                onChange={onChange}
                value={person.name}
                name={`${name}.name`}
                helpBlock=""
                disabled={
                  disabledConfig[`${name}.name`] ||
                  (person.isEnrolled && !window.isAdvisorApp)
                }
              />
            </div>
          </div>
          <div className="pc-layout pc-layout--small pc-form-group">
            <div className="pc-layout__item pc-u-1/2">
              <label className="pc-label" htmlFor="emailAddress">
                Email
                <InfoTooltipIcon
                  className="pc-u-ml--"
                  title={`The email must be unique and cannot be registered to another ${organizationNameAccount} or user.`}
                />
              </label>
              <Input
                key={personId}
                ref={refFunc}
                type="text"
                id="emailAddress"
                sizeVariation="full"
                name={`${name}.emailAddress`}
                value={person.emailAddress}
                onChange={onChange}
                validator={validator.properties.emailAddress}
                maxLength={50}
                className="qa-input-email"
                disabled={disabledConfig[`${name}.emailAddress`]}
                data-hj-masked
              />
            </div>
            <div className="pc-layout__item pc-u-1/2">
              <label className="pc-label" htmlFor="phone">
                Phone Number
              </label>
              <Input
                key={personId}
                ref={refFunc}
                type="text"
                id="phone"
                formattingOptions={PHONE_NUMBER_FORMAT}
                sizeVariation="full"
                name={`${name}.phone`}
                value={person.phone}
                onChange={onChange}
                validator={validator.properties.phone}
                className="qa-input-phone-num"
                disabled={disabledConfig[`${name}.phone`]}
                data-hj-masked
              />
            </div>
          </div>
          {showRelationship && (
            <div className="pc-layout pc-layout--small pc-form-group">
              <div className="pc-layout__item pc-u-1/2">
                <label className="pc-label" htmlFor="relationship">
                  Relationship
                </label>
                <FancySelect
                  key={personId}
                  ref={refFunc}
                  id="relationship"
                  value={person.relationship}
                  name={`${name}.relationship`}
                  options={relationships}
                  onChange={onChange}
                  validator={validator.properties.relationship}
                  className="qa-input-relationship"
                  isDisabled={disabledConfig[`${name}.relationship`]}
                />
              </div>
            </div>
          )}
          {consentLabel && (
            <div className="pc-form-group">
              <Checkbox
                checkboxClassName="person_consent-checkbox qa-input-consent"
                key={personId}
                ref={refFunc}
                name="isConsented"
                value={true}
                checked={isConsented}
                onChange={(ev) => setConsent(ev.target.checked)}
                label={consentLabel}
                validator={validator.properties.isConsented}
              />
            </div>
          )}
        </div>
      </CSSTransition>
    </>
  );
}

SelectPersonInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  refFunc: PropTypes.func,
  validator: PropTypes.object,
  consentLabel: PropTypes.node,
  selectDropdownHeading: PropTypes.node,
  selectDropdownPlaceholder: PropTypes.string,
  // `selectOptions` is an array of persons that is being used as options
  // in the person select dropdown. May differ from the content of `household` array.
  selectOptions: PropTypes.array,
  // `household` is an unfiltered array of all persons in the household
  household: PropTypes.array,
  showPersonDropdown: PropTypes.bool,
  showRelationship: PropTypes.bool,
  loading: PropTypes.bool,
  isAddNewPersonEnabled: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

SelectPersonInput.defaultProps = {
  value: undefined, // the default value is set in the arguments to avoid a share by reference
  refFunc: noop,
  consentLabel: undefined,
  selectDropdownHeading: "Tell us a bit more about the additional owner.",
  selectDropdownPlaceholder: "Select additional owner",
  selectOptions: [],
  household: [],
  validator: {
    type: "object",
    properties: {
      id: {
        type: "number",
        required: true,
        messages: {
          required: "must be selected",
        },
      },
      firstName: {
        type: "string",
        allowEmpty: false,
        pattern: FIRST_NAME.source,
        messages: {
          pattern: "Name may not include special characters or numbers.",
        },
        maxLength: 19,
      },
      emailAddress: {
        type: "string",
        allowEmpty: false,
        pattern: EMAIL,
        messages: {
          pattern: "must be a valid email address",
        },
        maxLength: 50,
      },
      phone: {
        type: "string",
        allowEmpty: false,
        pattern: US_PHONE,
        messages: {
          pattern: "must be a valid US phone number",
        },
      },
      relationship: {
        type: "string",
        allowEmpty: false,
        required: true,
        messages: {
          required: "must not be empty",
          allowEmpty: "must not be empty",
        },
      },
      isConsented: {
        type: "boolean",
        required: true,
        messages: {
          required: `You must allow access to your account in order to add an owner to ${organizationNameCash}.`,
        },
      },
    },
  },
  showPersonDropdown: true,
  showRelationship: true,
  loading: false,
  isAddNewPersonEnabled: true,
  disabled: {},
};
