import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import { isEqual, noop } from "underscore";
import { subscribify } from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import memoizeOne from "memoize-one";
import Account from "models/account";
import { isEmpty, groupBy } from "underscore";
import deepCopy from "deep-copy";

const setHideSplitTransaction = memoizeOne((transactions) => {
  transactions.map((t) => (t.hideSplitTransaction = true));
});

const groupTransactionsByProductType = memoizeOne((accounts, transactions) => {
  const accountTypeGroupsDict = {};

  // build a dict between accountId and productType so that it can be
  // used to group transactions by productType.
  accounts.forEach((account) => {
    accountTypeGroupsDict[account.accountId] = account.productType;
  });

  // group all transactions by productType.
  return groupBy(transactions, (transaction) => {
    return accountTypeGroupsDict[transaction.accountId];
  });
});

/**
 * `UserTransactionsFetcher` Makes a request to fetch and watch transactions based on prop.apiParameters and
 * renders the component passed in props.Component passing the fetched transactions from the API filtered by props.dataFilter if provided in the transactions prop.
 * It also proxies all the other props passed to this by using the spread operator ie. <Component {...this.props} transactions ...
 *
 * If transactions are provided by props, this component will throw an alert.
 *
 */
export default class UserTransactionsFetcher extends React.Component {
  constructor(props) {
    super(props);

    //This is here just in case that someone using this fetcher passed transactions to the children component.
    if (
      process.env.NODE_ENV !== "production" &&
      props.apiParameters &&
      props.transactions
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        "TransactionsGridContainer was initialized with an" + //eslint-disable-line no-console
          " array of transactions and apiParameters as props, this is an invalid operation since passing " +
          "apiParameters will make the grid fetch its own transactions. These props are exclusive from each other"
      );
    }

    this.state = {
      loading: true,
      transactions: [],
      accounts: [],
      errors: [],
    };

    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleTransactionsSuccess = this.handleTransactionsSuccess.bind(this);
    this.handleAccountsSuccess = this.handleAccountsSuccess.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
  }

  componentDidMount() {
    this.fetchAndWatchTransactions();
  }

  componentDidUpdate() {
    if (!isEqual(this.state.prevPropApiParameters, this.props.apiParameters)) {
      this.fetchAndWatchTransactions();
    }
  }

  componentWillUnmount() {
    if (this.fetchTransactionsCancelablePromise) {
      this.fetchTransactionsCancelablePromise.cancel();
    }

    if (this.getTransactionsSubscription) {
      this.getTransactionsSubscription.unwatch();
      this.getTransactionsSubscription.off("change");
    }
    if (this.fetchAccountsCancelablePromise) {
      this.fetchAccountsCancelablePromise.cancel();
    }

    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unwatch();
      this.getAccountsSubscription.off("change");
    }
  }

  fetchAndWatchTransactions() {
    this.setState({
      loading: true,
      prevPropApiParameters: this.props.apiParameters,
    });
    let { apiParameters } = this.props;
    if (this.getTransactionsSubscription) {
      this.getTransactionsSubscription.unwatch();
      this.getTransactionsSubscription.off("change");
    }

    this.getTransactionsSubscription =
      this.props.watchTransactions(apiParameters);
    this.getTransactionsSubscription.on(
      "change",
      this.handleTransactionsSuccess
    );
    this.fetchTransactionsCancelablePromise = makeCancelablePromise(
      this.getTransactionsSubscription.promise
    );
    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unwatch();
      this.getAccountsSubscription.off("change");
    }

    this.getAccountsSubscription = this.props.watchAccounts(apiParameters);
    this.getAccountsSubscription.on("change", this.handleAccountsSuccess);
    this.fetchAccountsCancelablePromise = makeCancelablePromise(
      this.getAccountsSubscription.promise
    );
    Promise.all([
      this.fetchTransactionsCancelablePromise.promise,
      this.fetchAccountsCancelablePromise.promise,
    ])
      .then(this.handleSuccess)
      .catch(this.handleFailure);
  }

  handleSuccess([transactionsResponse, accountsResponse]) {
    const transactions = deepCopy(transactionsResponse?.transactions || []);
    const allTransactionsGroupedByProductType = groupTransactionsByProductType(
      accountsResponse.accounts,
      transactions
    );
    const investmentTransactions =
      allTransactionsGroupedByProductType[Account.PRODUCT_TYPES.INVESTMENT];

    if (!isEmpty(investmentTransactions)) {
      setHideSplitTransaction(investmentTransactions);
    }

    this.setState(
      {
        transactions,
        accounts: accountsResponse.accounts,
        errors: [],
        loading: false,
      },
      this.props.onFinishLoading
    );
  }

  handleAccountsSuccess(accountsResponse) {
    const transactions = deepCopy(this.state.transactions || []);
    const allTransactionsGroupedByProductType = groupTransactionsByProductType(
      accountsResponse.accounts,
      transactions
    );
    const investmentTransactions =
      allTransactionsGroupedByProductType[Account.PRODUCT_TYPES.INVESTMENT];

    if (!isEmpty(investmentTransactions)) {
      setHideSplitTransaction(investmentTransactions);
    }

    this.setState(
      {
        transactions,
        accounts: accountsResponse.accounts,
        errors: [],
        loading: false,
      },
      this.props.onFinishLoading
    );
  }

  handleTransactionsSuccess(transactionsResponse) {
    const transactions = deepCopy(transactionsResponse?.transactions || []);
    const allTransactionsGroupedByProductType = groupTransactionsByProductType(
      this.state.accounts,
      transactions
    );
    const investmentTransactions =
      allTransactionsGroupedByProductType[Account.PRODUCT_TYPES.INVESTMENT];

    if (!isEmpty(investmentTransactions)) {
      setHideSplitTransaction(investmentTransactions);
    }

    this.setState(
      {
        transactions,
        errors: [],
        loading: false,
      },
      this.props.onFinishLoading
    );
  }

  handleFailure(errors) {
    this.setState(
      {
        errors,
        loading: false,
      },
      this.props.onFinishLoading
    );
  }

  render() {
    const { loading, errors, transactions } = this.state;
    let { dataFilter } = this.props;
    const { Component } = this.props;
    return (
      <div>
        <LoadingOverlay active={loading} modifier="always-centered" />
        <Message severity="error" messages={errors} />
        <Component
          {...this.props}
          transactions={dataFilter ? dataFilter(transactions) : transactions}
        />
      </div>
    );
  }
}

UserTransactionsFetcher.defaultProps = {
  apiParameters: undefined,
  onFinishLoading: noop,
  transactions: undefined,
  watchTransactions: subscribify(Services.Transactions.get),
  watchAccounts: subscribify(Services.Accounts.get),
  dataFilter: undefined,
  transactionCategoryRulesFlag: false,
  manualTransactionsFlag: false,
  eligibleForManualTransactions: false,
  account: undefined,
};
UserTransactionsFetcher.propTypes = {
  apiParameters: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    accountsId: PropTypes.array,
  }),
  Component: PropTypes.elementType.isRequired,
  onFinishLoading: PropTypes.func,
  transactions: PropTypes.array,
  watchTransactions: PropTypes.func,
  watchAccounts: PropTypes.func,
  dataFilter: PropTypes.func,
  transactionCategoryRulesFlag: PropTypes.bool,
  manualTransactionsFlag: PropTypes.bool,
  eligibleForManualTransactions: PropTypes.bool,
  account: PropTypes.object,
};
