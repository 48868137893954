import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { noop, isUndefined } from "underscore";

import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import Input from "components/common/form/Input";
import Checkbox from "components/common/form/Checkbox";
import { formatCurrency } from "libs/pcap/utils/format";
import {
  POSITIVE_CURRENCY_FORMAT,
  PERCENTAGE_FORMAT,
} from "../../common/form/formattingOptions";

const TransferFundsWithdrawalTaxWithholding = ({
  taxWithholdingFederal,
  taxWithholdingState,
  amountAfterTax,
  stateTaxWithholdingType,
  stateTaxRateType,
  stateTaxRateFlatAmount,
  stateTaxRatePercentage,
  federalTaxRate,
  handleChange,
  federalTaxOptOut,
  stateTaxOptOut,
  stateTaxModified,
  transferAmount,
  ...props
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { loading, errors, onEditFederalTaxWithholding } = props;
  const [stateTaxHelpText, setStateTaxHelpText] = useState();
  const [stateTaxSuffixOrPrefix, setStateTaxSuffixOrPrefix] = useState();
  const [stateTaxHelpTextValue, setStateTaxHelpTextValue] = useState();
  const [cleaveInstance, setCleaveInstance] = useState();
  const [stateTaxDisabled, setStateTaxDisabled] = useState(stateTaxOptOut);

  useEffect(() => {
    if (amountAfterTax) handleChange("amountAfterTax", amountAfterTax);
    if (stateTaxRateType) handleChange("stateTaxRateType", stateTaxRateType);
    setStateTaxSuffixOrPrefix(
      stateTaxRateType === "PERCENTAGE" ? { suffix: "%" } : { prefix: "$" }
    );

    const stateTaxAmount = stateTaxRateFlatAmount || stateTaxRatePercentage;

    const stateTaxHelpText = `${
      stateTaxRateType === "PERCENTAGE"
        ? stateTaxAmount + "%"
        : "$" + stateTaxAmount
    }`;

    setStateTaxHelpTextValue(stateTaxHelpText);
    switch (stateTaxWithholdingType) {
      case "NOT_APPLICABLE":
        setStateTaxHelpText("Your state does not collect income tax.");
        break;

      case "VOLUNTARY":
        setStateTaxHelpText(
          "Your state tax withholding is voluntary. Applies to taxable amount only."
        );
        break;

      case "MANDATORY_WITH_FEDERAL_TAX_HELD":
        setStateTaxHelpText(
          `Your state withholding is a minimum of ${stateTaxHelpTextValue} if withholding federal income tax. Applies to taxable amount only.`
        );
        break;

      case "MANDATORY_WITHOUT_FEDERAL_TAX_HELD":
        setStateTaxHelpText(
          `Your state withholding is a minimum of ${stateTaxHelpTextValue}. Applies to taxable amount only.`
        );
        break;

      case "MANDATORY_WITH_TOTAL_DISTRIBUTION":
        setStateTaxHelpText(
          `Since you are taking a total disbursement, your state withholding is a minimum of ${stateTaxHelpTextValue}. Applies to taxable amount only.`
        );
        break;

      default:
        break;
    }
  }, [
    stateTaxRateFlatAmount,
    stateTaxRatePercentage,
    stateTaxRateType,
    stateTaxWithholdingType,
    amountAfterTax,
    handleChange,
    stateTaxHelpTextValue,
  ]);

  useEffect(() => {
    if (
      !stateTaxModified &&
      (!isUndefined(stateTaxRateFlatAmount) ||
        !isUndefined(stateTaxRatePercentage))
    ) {
      const stateTax = stateTaxRateFlatAmount || stateTaxRatePercentage;
      handleChange("taxWithholdingState", stateTax);
      if (!isUndefined(cleaveInstance?.setRawValue)) {
        cleaveInstance.setRawValue(parseFloat(stateTax));
      }
    }

    if (isUndefined(taxWithholdingFederal) && !isUndefined(federalTaxRate)) {
      handleChange("taxWithholdingFederal", federalTaxRate);
    }
  }, [
    cleaveInstance,
    federalTaxRate,
    handleChange,
    stateTaxModified,
    stateTaxRateFlatAmount,
    stateTaxRatePercentage,
    taxWithholdingFederal,
  ]);

  const handleFederalTaxOptOut = (ev) => {
    const updatedFederalTaxOptOutValue = !federalTaxOptOut;
    const newTaxWithholdingFederal = updatedFederalTaxOptOutValue
      ? 0
      : federalTaxRate;

    handleChange("taxWithholdingFederal", newTaxWithholdingFederal);
    handleChange(ev, updatedFederalTaxOptOutValue);
  };

  const handleStateTaxOptOut = (ev) => {
    const updatedStateTaxOptOutValue = !stateTaxOptOut;
    const newStateTaxValue = updatedStateTaxOptOutValue
      ? 0
      : stateTaxRateFlatAmount || stateTaxRatePercentage;

    handleChange("taxWithholdingState", newStateTaxValue);
    handleChange(ev, updatedStateTaxOptOutValue);
    handleChange("stateTaxModified", true);
    setStateTaxDisabled(!stateTaxDisabled);
    cleaveInstance.setRawValue(parseFloat(newStateTaxValue));
  };

  const onCleaveInit = (cleave) => {
    setCleaveInstance(cleave);
  };

  const onStateTaxChange = (e, val) => {
    e.preventDefault();

    handleChange("taxWithholdingState", val);
    handleChange("stateTaxModified", true);
  };

  let stateTaxValidator = {
    type: "number",
    required: true,
    allowEmpty: false,
  };

  if (
    !stateTaxOptOut &&
    (stateTaxWithholdingType === "VOLUNTARY" ||
      stateTaxWithholdingType === "MANDATORY_WITH_TOTAL_DISTRIBUTION" ||
      stateTaxWithholdingType === "MANDATORY_WITHOUT_FEDERAL_TAX_HELD" ||
      (stateTaxWithholdingType === "MANDATORY_WITH_FEDERAL_TAX_HELD" &&
        !federalTaxOptOut))
  ) {
    stateTaxValidator = {
      minimum: stateTaxRateFlatAmount || stateTaxRatePercentage,
      messages: {
        minimum: `Must be at least ${stateTaxHelpTextValue}`,
        maximum: `Cannot exceed the transfer amount minus federal tax percentage`,
      },
      ...stateTaxValidator,
    };

    if (stateTaxRateType === "AMOUNT") {
      stateTaxValidator = {
        maximum: transferAmount - transferAmount * (federalTaxRate / 100),
        ...stateTaxValidator,
      };
    }
  }

  return (
    <>
      <Message className="pc-u-mb" severity="error" messages={errors} />
      <LoadingOverlay active={loading} data-testid="js-withdrawal-loading" />
      {IS_EMPOWER ? (
        <h2 className="tax-withholding__header pc-u-mt+ pc-u-mb-">
          Tax withholding
        </h2>
      ) : (
        <h4 className="tax-withholding__header pc-u-mt+ pc-u-mb-">
          Tax withholding
        </h4>
      )}
      <p>
        In most cases, federal and state income tax law requires that we
        withhold tax from your distribution. We have added the minimum
        withholdings below based on the{" "}
        <a
          href="https://www.irs.gov/pub/irs-pdf/i5498e.pdf"
          target="_blank"
          rel="noreferrer"
        >
          IRA/ESA Distribution Request Instructions
        </a>
        , if applicable. You can use the elections below or edit your
        withholdings based on your preference.
      </p>
      <div
        className={`pc-layout pc-layout--small pc-form-group${
          IS_EMPOWER ? " pc-u-mt" : ""
        }`}
      >
        <div className="pc-layout__item pc-u-1/4">
          <label className="pc-label" htmlFor="taxWithholdingFederal">
            Federal
          </label>
        </div>
        <div className="pc-layout__item pc-u-3/4">
          <div className="l-spaced l-spaced-nowrap l-spaced--top l-spaced--flush l-spaced--left">
            <Input
              ref={props.storeInputRef}
              type="number"
              id="taxWithholdingFederal"
              name="taxWithholdingFederal"
              containerClassName="tax-withholding__input pc-u-mr--"
              sizeVariation="full"
              disabled={true}
              value={taxWithholdingFederal}
              onChange={handleChange}
              suffix={"%"}
            />
            <button
              className="pc-btn pc-u-mr-- tax-withholding__edit-federal"
              type="button"
              onClick={onEditFederalTaxWithholding}
            >
              Edit or learn more
            </button>
            <Checkbox
              name="federalTaxOptOut"
              checked={federalTaxOptOut}
              className="tax-withholding__opt-out-checkbox tax-withholding__federal-opt-out-checkbox"
              labelTextClassName="tax-withholding__opt-out-label"
              label="Do not withhold federal income tax"
              onChange={handleFederalTaxOptOut}
            />
          </div>
        </div>
        <div
          className={`pc-layout pc-layout--right pc-u-mt${
            IS_EMPOWER ? "--" : ""
          }`}
        >
          <div className="pc-layout__item pc-u-3/4 milli">
            <p className="tax-withholding__help-text pc-u-pl-- pc-u-mb0">
              Your federal withholding rate is determined by the type of payment
              you will receive. For nonperiodic payments, the default
              withholding rate is 10%. You can edit or learn more about
              withholding rates.
            </p>
          </div>
        </div>
      </div>
      <div className="pc-layout pc-layout--small pc-form-group">
        <div className="pc-layout__item pc-u-1/4">
          <label className="pc-label" htmlFor="taxWithholdingState">
            State
          </label>
        </div>
        <div className="pc-layout__item pc-u-3/4">
          <div className="l-spaced l-spaced-nowrap l-spaced--top l-spaced--flush l-spaced--left">
            <Input
              ref={props.storeInputRef}
              type="text"
              id="taxWithholdingState"
              name="taxWithholdingState"
              containerClassName="tax-withholding__input-container--state pc-u-mr--"
              className="tax-withholding__input--state"
              sizeVariation="full"
              disabled={
                stateTaxWithholdingType === "NOT_APPLICABLE" ||
                stateTaxDisabled ||
                stateTaxOptOut
              }
              validator={stateTaxValidator}
              value={taxWithholdingState}
              formattingOptions={
                stateTaxRateType === "PERCENTAGE"
                  ? PERCENTAGE_FORMAT
                  : {
                      useFormattedValue: false,
                      numeralDecimalScale: 2,
                      ...POSITIVE_CURRENCY_FORMAT,
                    }
              }
              onChange={onStateTaxChange}
              step={0.01}
              errorBlockClassName={"tax-withholding__input--state-error"}
              onInit={onCleaveInit}
              {...stateTaxSuffixOrPrefix}
            />
            {stateTaxWithholdingType !== "MANDATORY_WITHOUT_FEDERAL_TAX_HELD" &&
              stateTaxWithholdingType !==
                "MANDATORY_WITH_TOTAL_DISTRIBUTION" && (
                <Checkbox
                  className="tax-withholding__opt-out-checkbox"
                  name="stateTaxOptOut"
                  labelTextClassName="tax-withholding__opt-out-label"
                  checked={
                    stateTaxWithholdingType === "NOT_APPLICABLE" ||
                    stateTaxOptOut
                  }
                  disabled={
                    stateTaxWithholdingType === "NOT_APPLICABLE" ||
                    (stateTaxWithholdingType ===
                      "MANDATORY_WITH_FEDERAL_TAX_HELD" &&
                      federalTaxOptOut !== true)
                  }
                  label="Do not withhold state income tax"
                  onChange={handleStateTaxOptOut}
                />
              )}
          </div>
        </div>
        <div className="pc-layout pc-layout--right">
          <div className="pc-layout__item pc-u-3/4 milli">
            <p className="tax-withholding__help-text pc-u-pl-- pc-u-mb0">
              {stateTaxHelpText}
            </p>
          </div>
        </div>
      </div>
      {amountAfterTax && (
        <div className="pc-layout pc-layout--small pc-form-group pc-u-mb0">
          <div className="pc-layout__item pc-u-1/4">
            <label className="pc-label" htmlFor="federalTaxRate">
              Amount you receive
            </label>
          </div>
          <div className="pc-layout__item pc-u-3/4">
            <p className="u-text-bold">
              {formatCurrency(amountAfterTax, 2, false)}
            </p>
          </div>
        </div>
      )}
      <div className="pc-layout pc-layout--small milli">
        <div className="pc-layout__item pc-u-4/4">
          <p className="tax-withholding__help-text">
            I understand that I am still liable for the payment of federal
            income tax on the taxable amount. I also understand that I may be
            subject to tax penalties under the estimated tax payment rules if my
            payments of estimated tax and withholding, if any, are not adequate.
          </p>
        </div>
      </div>
    </>
  );
};

TransferFundsWithdrawalTaxWithholding.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  storeInputRef: PropTypes.func,
  handleChange: PropTypes.func,
  onFederalTaxWithholdingSave: PropTypes.func,
  taxWithholdingFederal: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  taxWithholdingState: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  stateTaxRateType: PropTypes.string,
  stateTaxWithholdingType: PropTypes.string,
  stateTaxRatePercentage: PropTypes.string,
  stateTaxRateFlatAmount: PropTypes.string,
  amountAfterTax: PropTypes.string,
  federalTaxRate: PropTypes.string,
  federalTaxOptOut: PropTypes.bool,
  stateTaxOptOut: PropTypes.bool,
  onEditFederalTaxWithholding: PropTypes.func,
  stateTaxModified: PropTypes.bool,
  transferAmount: PropTypes.string,
};

TransferFundsWithdrawalTaxWithholding.defaultProps = {
  loading: false,
  errors: undefined,
  storeInputRef: noop,
  handleChange: noop,
  onFederalTaxWithholdingSave: noop,
  taxWithholdingFederal: undefined,
  taxWithholdingState: "0",
  amountAfterTax: undefined,
  stateTaxRateType: undefined,
  stateTaxWithholdingType: undefined,
  stateTaxRatePercentage: undefined,
  stateTaxRateFlatAmount: undefined,
  federalTaxRate: undefined,
  federalTaxOptOut: false,
  stateTaxOptOut: false,
  onEditFederalTaxWithholding: noop,
  stateTaxModified: false,
  transferAmount: undefined,
};

export default TransferFundsWithdrawalTaxWithholding;
