import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import { timeMonths } from "d3";
import moment from "moment";
import services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import InvestableCashSummary from "components/investableCashSummary/InvestableCashSummary";
import InvestableCashZeroState from "components/investableCashSummary/InvestableCashSummaryZeroState";
import DateUtils from "libs/pcap/utils/date";

const PROPER_DATE_FORMAT = DateUtils.API_FORMAT;
const MAX_NUMBER_MONTHS_TO_DISPLAY = 12;

function parseUserAccountIds(accounts) {
  return accounts
    .filter(
      (item) =>
        _.contains(["BANK", "INVESTMENT"], item.productType) &&
        !item.isExcludeFromHousehold
    )
    .map((item) => item.userAccountId);
}

export default class InvestableCashSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: null,
    };
  }

  /**
   * @param {object} options - optional params
   * @returns {object} new object config
   */
  getFetchConfig(options) {
    let parsedUserAccountIds = options.accounts
      ? parseUserAccountIds(options.accounts)
      : [];
    const currentDate = moment();
    const currentDateFormatted = currentDate.format(PROPER_DATE_FORMAT);
    const oneYearAgoDateFormatted = currentDate
      .subtract(1, "year")
      .format(PROPER_DATE_FORMAT);

    return {
      endDate: currentDateFormatted,
      startDate: oneYearAgoDateFormatted,
      intervalType: "MONTH",
      types: JSON.stringify(["cashBalances"]),
      userAccountIds: JSON.stringify(parsedUserAccountIds),
    };
  }

  /**
   * If there are gaps in the monthly data due to zero transactions,
   *   then backfill these missing data points
   * @param {array} histories - uncleansed ata
   * @return {array} cleansed data
   */
  backfillMissingHistoriesData(histories) {
    const MIN_NUMBER_OF_MONTHS = 2;

    if (histories < MIN_NUMBER_OF_MONTHS) {
      return histories;
    }

    let dateRange = timeMonths(
      moment(_.first(histories).date),
      moment(_.last(histories).date).add(1, "day") // the resultset should be inclusive
    );

    return dateRange.map((date, index, list) => {
      let dateFormatted = moment(date).format(PROPER_DATE_FORMAT);
      let found = _.findWhere(histories, { date: dateFormatted });
      if (found) {
        return found;
      }
      return {
        date: dateFormatted,
        aggregateCashBalance: list[index - 1].aggregateCashBalance,
      };
    });
  }

  onAccountsFetched(err, response) {
    const errors = parseResponseErrors(err, response);
    if (errors) {
      this.setState({
        loading: false,
        errors: errors,
      });
      return;
    }

    const params = this.getFetchConfig({ accounts: response.spData.accounts });

    // If there are no (bank OR investment) accounts OR all of them are excluded set zero state
    if (JSON.parse(params.userAccountIds).length === 0) {
      this.setState({
        zeroState: true,
        loading: false,
      });
      return;
    }

    services.Histories.get(params, this.onHistoriesFetched, this);
  }

  onHistoriesFetched(err, response) {
    const errors = parseResponseErrors(err, response);
    if (errors) {
      this.setState({
        loading: false,
        errors: errors,
      });
      return;
    }

    let histories = this.backfillMissingHistoriesData(
      response.spData.histories || []
    );

    // display up to the last 12 data points.
    if (histories.length > MAX_NUMBER_MONTHS_TO_DISPLAY) {
      histories = histories.slice(
        histories.length - MAX_NUMBER_MONTHS_TO_DISPLAY
      );
    }
    this.setState({
      histories: histories,
      loading: false,
    });
  }

  componentDidMount() {
    this.watchedAccountsService = services.Accounts.get.watch(
      {},
      this.onAccountsFetched,
      this
    );
  }

  componentWillUnmount() {
    services.Accounts.get.unwatch(this.watchedAccountsService);
  }

  render() {
    const { title } = this.props;
    if (this.state.zeroState) {
      return <InvestableCashZeroState />;
    }

    return (
      <InvestableCashSummary
        errors={this.state.errors}
        loading={this.state.loading}
        title={title}
        histories={this.state.histories}
        ace={this.props.ace}
      />
    );
  }
}

InvestableCashSummaryContainer.propTypes = {
  ace: PropTypes.object,
  title: PropTypes.string,
};

InvestableCashSummary.defaultProps = {
  title: "Investable Cash",
};
