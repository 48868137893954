import React from "react";
import PropTypes from "prop-types";
import Services from "services";
import { promisify } from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import { getAccounts } from "accessors/account/accessor";
import { WITH_ACTIONS_COLUMNS } from "components/transferFunds/utils/recurringTransfersTable";
import RecurringTransfersTable from "components/transferFunds/RecurringTransfersTable";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import EditRecurringTransfersContainer from "components/transferFunds/EditRecurringTransfers/Container";
import CancelRecurringTransferContainer from "components/transferFunds/CancelRecurringTransfer/Container";
import { getTransfersData } from "components/transferFunds/utils/transferInstructions";
import memoizeOne from "memoize-one";
import deepCopy from "deep-copy";
import LoadingOverlay from "components/common/LoadingOverlay";
import Modal from "components/modal/Modal";

// determine which form to render when user clicks action icons
const ACTION_EDIT = 1;
const ACTION_CANCEL = 2;

//defines recurring transfer table data object
const recurringTransferTableEntry = (
  fromAccount,
  toAccount,
  transferDetails
) => {
  return {
    ...transferDetails,
    frequency: transferDetails.frequency,
    nextTransferDate: transferDetails.nextTransferDate,
    fromAccount: fromAccount,
    fromFirm: fromAccount.firmName,
    fromName: fromAccount.name,
    toAccount: toAccount,
    toFirm: toAccount.firmName,
    toName: toAccount.name,
    transferInstruction: transferDetails,
  };
};

export const memoizedGetTransferData = memoizeOne(getTransfersData);

class RecurringTransfersTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initializing: true,
      loading: true,
      actionType: null,
      selectedTransfer: null,
    };

    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleActionCancel = this.handleActionCancel.bind(this);
    this.handleActionDone = this.handleActionDone.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    if (this.fetchDataRequest) {
      this.fetchDataRequest.cancel();
    }
  }

  fetchData() {
    this.setState({ loading: true });

    this.fetchDataRequest = makeCancelablePromise(
      Promise.all([
        this.props.fetchTransferInstructions(),
        this.props.fetchAccounts(),
      ])
    );
    this.fetchDataRequest.promise
      .then(([getInstructionsResponse, accountsResponse]) => {
        this.setState({
          transferInstructions: getInstructionsResponse,
          accounts: accountsResponse,
          initializing: false,
          loading: false,
        });
      })
      .catch((errors) => {
        if (this.fetchDataRequest.isCanceled()) {
          return;
        }
        this.setState({
          initializing: false,
          loading: false,
          fetchDataErrors: errors,
        });
      });
  }

  handleActionCancel() {
    this.handleActionDone(false);
  }

  handleActionDone(shouldRefresh = true) {
    if (shouldRefresh) {
      this.fetchData();
    }
    this.setState({
      actionType: null,
    });
  }

  handleClickDelete(d) {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "recurring_requests.delete_icon.click"
      );
    }
    ComponentAnalytics.trackClick(
      null,
      "Recurring Requests",
      "Cancel Recurring Request"
    );

    this.setState({
      selectedTransfer: d,
      actionType: ACTION_CANCEL,
    });
  }

  handleClickEdit(d) {
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "recurring_requests.edit_icon.click"
      );
    }
    ComponentAnalytics.trackClick(
      null,
      "Recurring Requests",
      "Edit Recurring Request"
    );

    // adjust data formats for the `model` used in transfer funds form validators and submit API call
    const model = deepCopy(d.transferInstruction);
    model.startDate = "";

    this.setState({
      selectedTransfer: model,
      actionType: ACTION_EDIT,
    });
  }

  render() {
    const {
      accounts,
      transferInstructions,
      actionType,
      loading,
      initializing,
      fetchDataErrors,
      selectedTransfer,
    } = this.state;
    const { CustomEditTransfer } = this.props;

    const renderActionComponent = ({ actionType, ...props }) => {
      const ActionComponent = (props) =>
        actionType === ACTION_CANCEL ? (
          <CancelRecurringTransferContainer
            transfer={props.transfer}
            onCancel={props.onCancel}
            onDeleted={props.onDeleted}
          />
        ) : (
          <CustomEditTransfer
            transfer={props.transfer}
            onCancel={props.onCancel}
            onTransferFundSubmitted={props.onTransferFundSubmitted}
          />
        );
      ActionComponent.displayName =
        actionType === ACTION_CANCEL
          ? CancelRecurringTransferContainer.name
          : CustomEditTransfer.name;

      const componentName = actionType === ACTION_EDIT ? "Edit" : "Cancel";

      return (
        <Modal
          componentName={`${componentName} Recurring Transfer Container`}
          isOpen={true}
          className="pc-modal pc-modal--transfer-funds "
          title={`${componentName} Recurring Request`}
          onClosed={props.onCancel}
          shouldCloseOnOverlayClick={false}
        >
          <ActionComponent {...props} />
        </Modal>
      );
    };

    if (initializing) {
      return <LoadingOverlay active />;
    }

    if (!actionType) {
      const transfers = memoizedGetTransferData(
        accounts,
        transferInstructions,
        recurringTransferTableEntry
      );

      return (
        <>
          <LoadingOverlay active={loading} />
          <RecurringTransfersTable
            fetchDataErrors={fetchDataErrors}
            transfers={transfers}
            columns={WITH_ACTIONS_COLUMNS({
              onDelete: this.handleClickDelete,
              onEdit: this.handleClickEdit,
            })}
          />
        </>
      );
    }

    return renderActionComponent({
      actionType,
      transfer: selectedTransfer,
      onCancel: this.handleActionCancel,
      onDeleted: this.handleActionDone,
      onTransferFundSubmitted: this.handleActionDone,
    });
  }
}

RecurringTransfersTableContainer.propTypes = {
  fetchTransferInstructions: PropTypes.func,
  fetchAccounts: PropTypes.func,
  CustomEditTransfer: PropTypes.func,
};

RecurringTransfersTableContainer.defaultProps = {
  fetchTransferInstructions: promisify(
    Services.Transfer.getTransferInstruction
  ),
  fetchAccounts: getAccounts,
  CustomEditTransfer: EditRecurringTransfersContainer,
};

export default RecurringTransfersTableContainer;
