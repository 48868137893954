import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";
import CopyPlanView from "components/financialPlanner/CopyPlanView";

const CopyPlanModal = (props) => {
  if (props.plans) {
    return (
      <Modal
        componentName={props.componentName}
        contentLabel="New Scenario"
        title="New Scenario"
        loading={props.loading}
        onClosed={props.onClosed}
        isOpen={props.isOpen}
        className="pc-modal--small qa-new-scenario-modal"
        overlayClassName="forecast-react-modal"
      >
        <CopyPlanView
          {...props}
          planOptions={props.plans}
          age={props.age}
          lifeExpectancy={props.lifeExpectancy}
        />
      </Modal>
    );
  }
  return null;
};

CopyPlanModal.propTypes = {
  checkPoorMarket: PropTypes.bool,
  componentName: PropTypes.string,
  errors: PropTypes.array,
  onClosed: PropTypes.func,
  loading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  plans: PropTypes.array,
  strategyOptions: PropTypes.array,
  userStrategies: PropTypes.array,
  isGLWB: PropTypes.bool,
  age: PropTypes.number.isRequired,
  lifeExpectancy: PropTypes.number.isRequired,
  accounts: PropTypes.array,
};

CopyPlanModal.defaultProps = {
  checkPoorMarket: false,
  componentName: "",
  errors: [],
  loading: false,
  plans: undefined,
  strategyOptions: undefined,
  userStrategies: undefined,
  isGLWB: false,
  onClosed: undefined,
  accounts: [],
};

export default CopyPlanModal;
