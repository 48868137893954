import React, { useState } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { trackClick } from "components/common/ComponentAnalytics";

const BUTTON_LABEL_COPY = "Copy link";
const BUTTON_LABEL_COPIED = "Copied";
const BUTTON_LABEL_RESTORE_TEXT_DELAY_DURATION = 2000;

const ShareLink = ({ componentName, text, contentToCopy }) => {
  const [copied, setCopied] = useState(false);
  let copyLinkTimer;

  const onCopyLink = () => {
    clearInterval(copyLinkTimer);
    setCopied(true);
    copyLinkTimer = window.setTimeout(() => {
      setCopied(false);
    }, BUTTON_LABEL_RESTORE_TEXT_DELAY_DURATION);
    trackClick(null, componentName, "Copy Link");
  };

  return (
    <div className="referral-center__share-link pc-layout">
      <div
        className=" pc-layout__item pc-u-4/5 referral-center__share-link-content"
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
      <div className="pc-layout__item pc-u-1/5 u-text-right">
        <CopyToClipboard text={contentToCopy} onCopy={onCopyLink}>
          <button
            type="button"
            className={`pc-btn pc-btn--primary qa-copy-button`}
          >
            {copied ? BUTTON_LABEL_COPIED : BUTTON_LABEL_COPY}
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

ShareLink.propTypes = {
  componentName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  contentToCopy: PropTypes.string.isRequired,
};

export default ShareLink;
