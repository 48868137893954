import React from "react";
import objectPath from "object-path";
import PropTypes from "prop-types";
import noop from "utils/noop";
import PostLoginActionsProvider from "../PostLoginActionsProvider";
import UserResponseSurveyCustomContainer from "./UserResponseSurveyCustomContainer";

const UserResponseSurveyCustom = (props) => {
  return (
    <PostLoginActionsProvider {...props}>
      {({ jsonContent, getButtons, imageSource }) => (
        <UserResponseSurveyCustomContainer
          {...props}
          componentName={objectPath.get(props, "userMessage.template")}
          getButtons={getButtons}
          imageSource={imageSource}
          jsonContent={jsonContent}
          onRequestClose={props.onRequestClose}
        />
      )}
    </PostLoginActionsProvider>
  );
};

UserResponseSurveyCustom.propTypes = {
  onRequestClose: PropTypes.func,
};

UserResponseSurveyCustom.defaultProps = {
  onRequestClose: noop,
};

export default UserResponseSurveyCustom;
