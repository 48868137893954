/* eslint-disable camelcase, no-magic-numbers, eqeqeq, no-invalid-this, sonarjs/no-duplicate-string, sonarjs/cognitive-complexity */
import eventBus from "eventBus";
import $ from "jquery";
import _ from "underscore";
import deepCopy from "deep-copy";
import Backbone from "backbone";
import Services from "services";
import "backbone.view";
import BackboneReactView from "common/BackboneReactView";
import React from "react";
import ReactDOM from "react-dom";
import AccountsCollection from "collections/accounts";
import transitionEndEvent from "libs/pcap/utils/transitionEndEvent";
import animationEndEvent from "libs/pcap/utils/animationEndEvent";
import AddAccountTemplate from "templates/components/addAccount/addAccount.html";
import AccountsSearchResultTemplateRebranded from "templates/components/addAccount/partials/home_rebrand/accountsSearchResult.html";
import AccountSubmittedWithTrophyTemplateRebranded from "templates/components/addAccount/partials/home_rebrand/accountSubmittedWithTrophy.html";
import AddAccountSuggestionsTemplate from "templates/components/addAccount/partials/addAccountSuggestions.html";
import SuggestedAndPopularSitesTemplate from "templates/components/addAccount/partials/suggestedAndPopularSites.html";
import CustomAccountsTemplate from "templates/components/addAccount/partials/customAccounts.html";
import CustomAccountAddFormTemplate from "templates/components/addAccount/partials/customAccountAddForm.html";
import CustomAccountSubmittedTemplateRebrand from "templates/components/addAccount/partials/home_rebrand/customAccountSubmitted.html";
import SendRequestFormTemplate from "templates/components/addAccount/partials/sendRequestForm.html";
import SendRequestFormSubmittedTemplate from "templates/components/addAccount/partials/sendRequestSubmitted.html";
import analytics from "analytics";
import Mixpanel from "mixpanel";
import getOrdinal from "libs/pcap/utils/getOrdinal";
import abFrameworkProperties from "libs/pcap/utils/abFrameworkProperties";
import InfoModalView, {
  DEFAULT_CLASS_NAMES,
} from "views/modal-windows/infoModalView";
import "tooltip";
import AccountCredentialFields from "../../components/common/EditAccount/AccountCredentialFields";
import ZillowCredentialFields from "../../components/common/EditAccount/AccountCredentialFields/ZillowCredentialFields";
import { error } from "logger";
import {
  isEmpowerPaeMode,
  isEmpowerPrivilegedMode,
} from "views/modules/sidebar/utils/accountUtils";
import { getInitialModelWithOptionFields } from "../../components/common/EditAccount/AccountCredentialFields/utils";
import CurrencyWithCents from "../../components/common/form/formatters/currencyWithCents";
import FiDefaultSuccessScreen from "../../components/oauth/FiDefaultSuccessScreen/FiDefaultSuccessScreen";
import SuggestExistingSites from "../../components/SuggestExistingSites/Container/SuggestExistingSitesContainer";
import AccountBlackoutPeriod from "../../components/AccountBlackoutPeriod";
import WhatToExpectTestModal from "../../components/WhatToExpectTest/Modal/WhatToExpectTestModal";
import FastlinkContainerModal from "views/components/FastlinkContainer/Modal/FastlinkContainerModal";
import NotReadyToLinkAnAccountModal from "./firstUse/NotReadyToLinkAnAccountModal/NotReadyToLinkAnAccountModal";
import parseResponseErrors from "../../libs/pcap/utils/response";
import {
  setAppointmentData,
  SCHEDULE_APPOINTMENT_CONTENT,
  clearSessionStorage,
} from "libs/pcap/utils/customAppointmentSchedulerUtils";
import {
  cryptoCoins,
  cryptoExchanges,
  manualHome,
} from "../../libs/pcap/utils/searchAccountsExtraOptions";
import { handleAmplitudeEvent } from "../../empower/utils/eventUtils";
const organizationName = EMPOWER_PERSONAL_DASHBOARD;
const organizationNameDashboard = EMPOWER_PERSONAL_DASHBOARD;
var POPUP_ON_LEAVE_ADD_FI = {
  title: "Setup is almost complete",
  content: `<p>Link just one account to the ${organizationNameDashboard} to better understand your money. Users that try ${organizationName} understand where their money goes, see how it's invested and spend an average of 15% less.</p>`,
};

const LINK_ACCOUNT_DONE_EVENT = "linkAccount:done";
const ACCOUNT_AGGREGATION_EVENT = "Account Aggregation";
const SEARCH_INPUT_CLASS = ".js-search-account-input";
const CUSTOM_ACCOUNT_FORM_CLASS = ".customAccountForm";
const SEND_REQUEST_FORM_CLASS = ".sendRequestForm";
const ACCOUNT_AUTHENTICATION_CLASS = ".accountAuthentication";
const TIER2_AUTHENTICATION_CLASS = ".tier2 " + ACCOUNT_AUTHENTICATION_CLASS;
const SUGGESTIONS_CLASS = ".suggestions";
const SEARCH_RESULTS_CLASS = ".searchResults";
const CUSTOM_ACCOUNTS_CLASS = ".customAccounts";
const ACCOUNT_SUBMITTED_CLASS = ".accountSubmitted";
const LINK_ACCOUNT_COPY_CLASS = ".accountSubmitted .linkAccountCopy";
const LINK_ACCOUNT_SCREEN_COMPONENT = "Link Account Screen";
const FIRST_USE_ADD_FI_SUCCESS_SCREEN_COMPONENT =
  "First Use Add FI Success Screen";
const ADD_FLOW = "ADD";
const EDIT_FLOW = "EDIT";

let currencyFormatter = CurrencyWithCents(2);
const isEmulatorMode = isEmpowerPaeMode();
const isPrivileged = isEmpowerPrivilegedMode();

const securityExplainerLink = IS_EMPOWER
  ? `"https://participant.empower-retirement.com/participant/#/articles/securityTips"`
  : `"https://www.empower.com/empower-difference/security?utm_source=pcap&utm_medium=referral"`;
var SECURITY_MESSAGES = [
  "We use bank-level military-grade encryption to keep your account safe.",
  "No one can move money in or out of accounts you link on your dashboard.",
  `<a href=${securityExplainerLink} class="learnSecurity link js-learn-about-our-security" target="_blank">Learn about our security</a>`,
];

const getImageBaseUrl = () =>
  IS_ADVISOR
    ? `${window.staticUrl || ""}/static/img/`
    : `${window.baseUrl}static/img/`;

var AddAccountView2 = Backbone.View.extend({
  className: "addAccount",
  events: {
    "keyup .js-search-account-input": "searchAccounts",
    "click .js-search-account-input": "searchAccountsClick",
    "keyup .js-add-fi-success-search": "searchAccountsPostAddFiSuccess",
    "focus .js-add-fi-success-search": "onAddFiSuccessSearchInputFocus",
    "click .js-add-fi-success-search-reset": "onAddFiSuccessSearchResetClick",
    "click .js-add-fi-success-done-linking": "onAddFiSuccessDoneLinkingClick",
    "click .js-add-fi-success-go-to-dashboard":
      "onAddFiSuccessGoToDashboardClick",
    "click .js-add-fi-success-run-investment-checkup":
      "onAddFiSuccessRunICClick",
    "click .js-add-fi-success-refer-a-friend": "onAddFiSuccessRunReferAFriend",
    "click .searchAccountsWrapper .reset": "resetSearchAccounts",
    "click .accountSearchResult": "searchResultClick",
    "click .siteSearchResult": "loadAccountAuthenticationForm",
    "click .back": "goBack",
    "click .selectDifferentInstitution": "onSelectDifferentInstitution",
    "click .suggestionTiles.site": "onSiteSuggestionTileClick",
    "click .submitForm.active": "submitForm",
    "focus input": "removeInvalid",
    "focus select": "removeInvalid",
    "click .cancelAddAccount": "cancelAddAccount",
    "click .js-get-site": "getSiteLink",
    "click .closeAddAccount": "cancelAddAccount",
    "click .allCustomAccountsButton": "showAllCustomAccounts",
    "click .customAccount": "showCustomAccountFormHandler",
    "click .showSendRequestForm": "showSendRequestForm",
    "submit form.customAccountsCreation": "submitCustomAccountCreation",
    "submit form.requestSupportForm": "submitRequestSupportForm",
    "click .closeSendRequest": "closeSendRequest",
    "click .linkAccountComplete": "onLinkAccountDone",
    "click .js-done-linking-button": "doneLinkingHandler",
    "click .customAccountContinue": "customAccountContinueClick",
    "click .js-learn-about-our-security": "learnAboutOurSecurityClick",
    "click .backLinkAnAccountClick": "onBackLinkAnAccount",
  },
  lastSection: "", // USED TO STORE LAST STATE THAT COULD BE USED BY BACK BUTTON
  isFirstAccountAggregated: false, // USED TO DETERMINE IF AT LEAST ONE ACCOUNT OF CHOSEN PRODUCT TYPE HAS AGGREGATED
  authenticationObject: {
    siteId: null,
    productId: null,
    credentials: null,
  },
  productType: "",
  configDefaults: {
    suggestionsMatrix: "4x3",
    width: {
      "4x3": 500,
      "3x4": 375,
    },
    height: 600,
    headerBackground: "#fcfcfc",
    productType: "",
    subProductType: "",
    showManualAccounts: true,
    showDefaultSuccessView: true,
    numberOfSuggestions: 12,
    showAllProducts: false,
    showProgressBar: true,
    allowUserToContinue: false,
    linkAccountCompleteText: "Done",
    linkAccountMessage: {},
  },
  whatToExpectModalActive: false,
  initialize: function (elementContainer, config) {
    if (_.isUndefined(config)) {
      config = {};
    }
    var self = this;
    this.isDynamicPopularTilesEnabled = config.isDynamicPopularTilesEnabled;
    this.isConnectivityTrackerEnabled = config.isConnectivityTrackerEnabled;
    this.isResumeLinkingEnabled = config.isResumeLinkingEnabled;
    this.isFirstUseScreen = window.location.href.indexOf("#/firstuse") > -1;
    this.whatToExpectModalActive = this.isFirstUseScreen
      ? Math.random() >= 0.5
      : false;
    this.staticImageBaseUrl = getImageBaseUrl();
    this.render(config);
    $(elementContainer).html(this.$el);

    self.$el.find("input").placeholder();

    if (!isPrivileged) {
      self.$el.find(SEARCH_INPUT_CLASS).focus();
    }
    this.$el.addClass("suggestions");

    this.setConfiguration(config);
    this.linkAccountMessage = config.linkAccountMessage;

    if (this.productType == "MANUAL") {
      if (config.isManualOnly) {
        this.renderManualProducts();
      } else {
        var renderSuggestionsDeferred = this.renderSuggestions();
        renderSuggestionsDeferred.done(this.renderManualProducts.bind(this));
      }
    } else if (
      this.productType == "INVESTMENT" &&
      this.config.subProductType == "RETIREMENT"
    ) {
      // Investing -> Retirement Feed Analyzer -> Link Account
      this.render401kProducts();
    } else {
      this.renderSuggestions();
    }

    this.collection = new AccountsCollection();
    this.fetchAccounts();
    this.fetchCustomAccounts();

    this.newSearch = true; // Flag to indicate a new account/site search is taking place

    eventBus.on("linkAccount:productTypeChange", self.updateProductType, self);
    eventBus.on(
      "linkAccount:renderScheduleAppointmentModal",
      self.renderScheduleAppointmentModal,
      self
    );

    this.viewFiSearchScreenComponent = LINK_ACCOUNT_SCREEN_COMPONENT;
    if (this.isFirstUseScreen) {
      Mixpanel.trackEvent("View FI Search Screen", {
        component: this.viewFiSearchScreenComponent,
        is_first_use: this.isFirstUseScreen,
      });
      Mixpanel.trackEvent("View FI Search Screen", {
        component: "Personalized Onboarding",
        is_first_use: this.isFirstUseScreen,
        title: this.linkAccountMessage.title,
        subTitle: this.linkAccountMessage.subTitle,
      });
    } else {
      Mixpanel.trackEvent("View FI Search Screen", {
        component: this.viewFiSearchScreenComponent,
        is_first_use: this.isFirstUseScreen,
      });
    }

    Services.Accounts.get(function (err, response) {
      if (response && response.spData && response.spData.accounts) {
        self.accountCount = response.spData.accounts.filter(function (account) {
          return !account.isManual;
        }).length;
      } else {
        analytics.sendEngineeringEvent(
          "Error",
          "Add Accounts: Services.accounts.get: " + err
        );
      }
    });

    Services.Profile.getFunnelAttributes(function (err, response) {
      if (response?.spData) {
        self.daysSinceRegistration = response.spData.daysSinceRegistration;
      }
      if (response?.spHeader) {
        self.userStageBeforeAddAccount = response.spHeader.userStage;
      }
      if (
        response &&
        response.spData &&
        !_.isUndefined(response.spData.daysSinceAggregationQualified)
      ) {
        self.showReferral =
          response.spData.daysSinceAggregationQualified > -1 ||
          response.spData.isClient;
        if (self.showReferral) {
          self.currentUserReferralData = self.getCurrentUserReferralData();
          if (!self.currentUserReferralData) {
            self.showReferral = false;
          }
        }
        self.stateSelector = self.showReferral
          ? ".js-referral-state"
          : ".js-run-ic-state";
      } else {
        analytics.sendEngineeringEvent(
          "Error",
          "Add Accounts: Services.Profile.getFunnelAttributes: " + err
        );
      }
      Services.Advisor.get(function (err, resp) {
        self.isNoAdvisorAssigned =
          resp?.spData?.status === "AWAITING_ASSIGNMENT" ||
          resp?.spData?.status === "NOT_ASSIGNED";
        if (
          self.daysSinceRegistration < 6 &&
          self.userStageBeforeAddAccount < "D" &&
          self.isNoAdvisorAssigned &&
          window.sessionStorage.getItem(
            "enableAppointmentSchedulerAfterAggregation"
          ) === "1"
        ) {
          window.sessionStorage.setItem("isEligibleForAppointment", "1");
          setAppointmentData();
        }
      });
    });

    if (config.siteId) {
      this.config.siteId = config.siteId;
      this.loadAccountAuthenticationForm(null, false);
    }
  },
  setConfiguration: function (config) {
    var $el = this.$el;
    this.config = $.extend(/* deep clone */ true, {}, this.configDefaults);

    // set width and height
    if (config.width) {
      this.config.width = config.width;
    }
    $el.css("min-width", this.config.width[config.suggestionsMatrix] + 10);
    $el.find(".wrap").css("width", this.config.width[config.suggestionsMatrix]);
    this.containerHeight = config.height;

    //set aggregation level , empower only
    this.aggregationLevel = config.aggregationLevel;

    // set filter
    this.productType = config.productType;
    this.config.subProductType = config.subProductType;

    // set showDefaultSuccessView
    // if (_.isBoolean(config.showDefaultSuccessView)){
    // this.config.showDefaultSuccessView = config.showDefaultSuccessView;
    // }

    if (_.isBoolean(config.showManualAccounts)) {
      this.config.showManualAccounts = config.showManualAccounts;
    }

    if (_.isNumber(config.numberOfSuggestions)) {
      this.config.numberOfSuggestions = config.numberOfSuggestions;
    }

    if (_.isBoolean(config.showAllProducts)) {
      this.config.showAllProducts = config.showAllProducts;
    }

    if (_.isBoolean(config.showProgressBar)) {
      this.config.showProgressBar = config.showProgressBar;
    }

    if (_.isBoolean(config.allowUserToContinue)) {
      this.config.allowUserToContinue = config.allowUserToContinue;
    }

    if (_.isString(config.linkAccountCompleteText)) {
      this.config.linkAccountCompleteText = config.linkAccountCompleteText;
    }
    this.config.cryptoFeatureFlag = config.cryptoFeatureFlag;
    this.config.accountExpectationTest = config.accountExpectationTest;
    this.config.useUnifiedMar = config.useUnifiedMar;
  },
  remove: function () {
    if (this.accountsWatchId) {
      Services.Accounts.get.unwatch(this.accountsWatchId);
      delete this.accountsWatchId;
    }

    if (this.fiCredentialsContainer) {
      ReactDOM.unmountComponentAtNode(this.fiCredentialsContainer);
    }

    if (this.accountCredentialsUpdateSuccess) {
      this.accountCredentialsUpdateSuccess.remove();
    }

    if (this.SuggestExistingSites) {
      this.SuggestExistingSites.remove();
    }
    Backbone.View.prototype.remove.apply(this, arguments);
  },
  fetchAccounts: function () {
    this.accountsWatchId = Services.Accounts.get.watch(
      this.onAccountsFetched,
      this
    );
  },
  fetchCustomAccounts: function (callback) {
    Services.CustomProducts.get(
      function (err, data) {
        this.customAccounts = data.spData;
        if (_.isFunction(callback)) {
          callback();
        }
      }.bind(this)
    );
  },
  onAccountsFetched: function (err, response) {
    var self = this;
    this.userStage =
      (response && response.spHeader && response.spHeader.userStage) || "";
    this.isUserClient = _.contains(["E", "F", "G"], this.userStage);

    if (err == null) {
      if (this.collection.length < 1) {
        this.collection.reset(response.spData.accounts);
      } else {
        this.collection.freshen(response.spData.accounts);
      }

      var productType = self.productType;
      // if all products are displayed and user add a product other than targeted product type, we do not hold the user
      if (this.config.showAllProducts) {
        productType = "";
      }
      var aggregatedAccounts =
        self.getAggregatedAccountsOfProductType(productType);
      if (aggregatedAccounts.length > 0) {
        self.isFirstAccountAggregated = true;
      }

      if (self.isFirstAccountAggregated) {
        // publish the event
        eventBus.trigger(
          "linkAccount:firstAccountAggregated",
          aggregatedAccounts
        );

        // hide the progress bar and show the continue link to feature page
        var progressBar = this.$el.find(".progressBar:visible");
        if (progressBar.length > 0) {
          progressBar.find(".progressMessage").hide();
          progressBar.find(".continueToFeature").show();
          setTimeout(function () {
            progressBar.slideUp();
          }, 5000);
        }
        self.$el.find(".linkAccountComplete").show();
      }
    }
  },

  render: function (config) {
    this.$el.html(
      AddAccountTemplate({
        isFirstUseScreen: this.isFirstUseScreen,
        isEmpower: IS_EMPOWER,
        staticImageBaseUrl: this.staticImageBaseUrl,
        isManualOnly: config.isManualOnly,
        isEmulatorMode: isEmulatorMode || isPrivileged,
        isFromCashMarketing: config.isFromCashMarketing,
      })
    );
    $(".toolTipped", this.$el).tipTip();
    return this;
  },
  // override-able attributes begin
  cancelAddAccount: function () {
    // managed by sidebar, overwritten in 401k config
  },
  onBackLinkAnAccount: function (e) {
    e.preventDefault();
    const modal = document.getElementById("linkAccountModal");
    if (modal) {
      ReactDOM.unmountComponentAtNode(modal);
      while (modal.lastChild) {
        modal.removeChild(modal.lastChild);
      }
    }
    window.location.hash = "#/accounts/add";
    window.location.reload(false);
  },
  renderSuggestions: function () {
    var self = this;
    var deferred = $.Deferred();
    const filterByAccountTypes = JSON.stringify(
      _.isArray(this.productType) ? this.productType : [this.productType]
    );
    Services.PopularSites.get({ filterByAccountTypes }, function (err, data) {
      var jsonData = data.spData || [],
        resultType =
          data.spData && data.spData.resultType
            ? data.spData.resultType
            : "sites",
        suggestions = self.stripBlackoutPeriodSuggestions(jsonData),
        suggestedSites = [],
        popularSites = [];
      for (var i = 0; i < suggestions.length; i++) {
        if (suggestions[i].isSuggested) {
          suggestedSites.push(suggestions[i]);
        } else {
          popularSites.push(suggestions[i]);
        }
      }
      self.$el.find(".popularSitesOnly").toggle(!suggestedSites.length);
      self.$el
        .find(".suggestedAndPopularSites")
        .show(Boolean(suggestedSites.length));
      if (suggestedSites.length) {
        self.$el.find(".suggestions .scroller .suggestedAndPopularSites").html(
          SuggestedAndPopularSitesTemplate({
            // List based layout
            suggestedSites: suggestedSites,
            popularSites: popularSites,
            hasPopularSites: popularSites.length > 0,
          })
        );
      } else {
        self.$el.find(".suggestions .scroller .container").html(
          AddAccountSuggestionsTemplate({
            // Tile based layout
            results: _.first(suggestions, self.config.numberOfSuggestions),
            type: resultType,
            isEmulatorMode: isEmulatorMode,
          })
        );
      }
      //Send Mix Panel events based on whether dynamic popular tiles are enabled or not
      if (self.isDynamicPopularTilesEnabled) {
        // Send the DynamicTiles_test event
        Mixpanel.trackEvent("DynamicTiles_test");
      } else {
        // Send the DynamicTiles_control event
        Mixpanel.trackEvent("DynamicTiles_control");
      }
      self.showSection(SUGGESTIONS_CLASS);
      self.$el.find(".suggestions .scroller").slimScroll({
        height: self.getTier2Height(SUGGESTIONS_CLASS),
      });
      deferred.resolve();
      // Prevent bottom contents overlapping when going from questionnaire to first use link fi screen
      if (self.isFirstUseScreen) {
        self.$(".tier3").removeClass("u-invisible");
      }
    });
    return deferred;
  },
  stripBlackoutPeriodSuggestions: function (suggestions) {
    if (suggestions.length) {
      return suggestions.filter(
        (suggestion) => !suggestion.isSiteInBlackoutPeriod
      );
    }
    return suggestions;
  },
  renderManualProducts: function () {
    var self = this;
    self.fetchCustomAccounts(function () {
      self.$el
        .find(".customAccounts .list")
        .html(CustomAccountsTemplate({ products: self.customAccounts }));
      self.showSection(CUSTOM_ACCOUNTS_CLASS);
      self.$el.find(".customAccounts .list.scroller").slimScroll({
        height: self.getTier2Height(CUSTOM_ACCOUNTS_CLASS),
      });
      if (isEmulatorMode === true) {
        return;
      }
      eventBus.trigger("linkAccount:searchResults");
      if (self.config.subProductType) {
        self.showCustomAccountForm(
          self
            .$(
              ".customAccount." +
                (self.config.subProductType || "").toLowerCase()
            )
            .data("custom_type")
        );
      }
    });
  },
  render401kProducts: function () {
    var self = this;
    Services.PopularSites.get(
      { filterByAccountTypes: JSON.stringify(["RETIREMENT"]) },
      function (err, data) {
        var top401ks = _.first(data.spData, self.config.numberOfSuggestions);
        self.showSection(SUGGESTIONS_CLASS);
        self.$el.find(".suggestions .scroller .container").html(
          AddAccountSuggestionsTemplate({
            results: top401ks,
            type: "sites",
            isEmulatorMode: isEmulatorMode,
          })
        );
        self.$el.find(".suggestions .scroller").slimScroll({
          height: self.getTier2Height(SUGGESTIONS_CLASS),
        });
      }
    );
  },
  getCurrentUserReferralData: function () {
    const referralNavLink = $("#js-refer-a-friend-menu-item a");

    // IMPORTANT
    // ‾‾‾‾‾‾‾‾‾
    // MG doesn't have a referral program, thus `js-refer-a-friend-menu-item` element doesn't exist.
    // Make sure to preserve the current logic and return `false` for MG if this code ever gets refactored.
    if (!referralNavLink.length) {
      return false;
    }
    const linkLabel = referralNavLink.text().trim(); // i.e. `Get $20`
    const linkLabelSplitArr = linkLabel.split("$");
    if (linkLabelSplitArr.length !== 2) {
      return false;
    }
    return {
      amount: linkLabelSplitArr[1],
      link: $("#js-refer-a-friend-menu-item a")
        .attr("href")
        .replace("source=web-app-menu", "source=add-account-success"),
    };
  },
  handleSuccess(data) {
    this.onAddAccountPostSuccess({ data });
  },
  onAddAccountPostSuccess: function (data) {
    if (!this.config.showDefaultSuccessView) {
      return;
    }
    data.linkAccountCompleteText = this.config.linkAccountCompleteText;
    $(".firstUseLinkAccountTitle").hide();
    if ($(".js-first-use-header .js-logo-container").length > 0) {
      $(".js-first-use-header .js-logo-container").remove();
      $(".js-first-use-header .js-first-use-skip-account").remove();
    }
    var STAR_COUNT = 6;
    var DELAY_BEFORE_RUN_IC_STATE = 1000;
    var RUN_IC_STATE_EVENT_NAME = "View Add FI Success Post Trophy Screen";
    let doAnimation = false;
    this.accountCount = this.accountCount || 0;
    if (data.name) {
      this.accountCount++;
      doAnimation = true;
    }
    // Do not show any stars or animation if no accounts have been linked yet.
    if (this.accountCount === 0) return;

    var showTrophyState = this.accountCount <= STAR_COUNT;
    var isMinAccountCountMet = this.accountCount > STAR_COUNT;
    var nth = getOrdinal(this.accountCount);
    var template = AccountSubmittedWithTrophyTemplateRebranded;
    $(".js-first-use-link-account-header").addClass("is-hidden");
    /**
     * - From star 1 to 5: show trophy
     * - On star 6: show trophy, animate 6th star, then show run IC section
     * - On star 7 and afterwards: show Run IC section
     */
    this.$el.find(ACCOUNT_SUBMITTED_CLASS).html(
      template(
        $.extend(
          true,
          {
            popularFisHtml: $(".popularSitesOnly .container").html(),
            showTrophyState: showTrophyState,
            showRunIcState: isMinAccountCountMet && !this.showReferral, //
            showReferralState: isMinAccountCountMet && this.showReferral,
            referralData: this.currentUserReferralData,
            showGoToDashboardLink: this.accountCount >= STAR_COUNT,
            accountCount: this.accountCount,
            isLastStar: this.accountCount === STAR_COUNT,
            nth: nth,
            hasInvestmentCheckup: !IS_EMPOWER,
            isEmpower: IS_EMPOWER,
            doAnimation: doAnimation,
            successMessage: this.linkAccountMessage?.successMessage,
            hasSuccessMessage: Boolean(this.linkAccountMessage?.successMessage),
            dashboardUrl: DASHBOARD_URL,
          },
          data
        )
      )
    );
    if (window.localStorage.getItem("linkAccountMessage")) {
      window.localStorage.removeItem("linkAccountMessage");
    }
    var animatedStar = this.$(".js-animated-star");

    if (showTrophyState && doAnimation) {
      animatedStar.one(
        animationEndEvent,
        function () {
          // Star animation would not work without this 1ms delay
          animatedStar.one(
            transitionEndEvent,
            function () {
              // Wait until star animation ends, then hide nth gray star underneath it
              this.$(
                ".js-add-fi-success-stars span:nth(" +
                  (this.accountCount - 1) +
                  ")"
              ).css("visibility", "hidden");
              if (this.accountCount === STAR_COUNT) {
                _.delay(
                  function () {
                    // Hide trophy state and show run IC state
                    this.$(".js-trophy-state").fadeOut(
                      function () {
                        this.$(this.stateSelector).fadeIn();
                        Mixpanel.trackEvent(RUN_IC_STATE_EVENT_NAME, {
                          component: FIRST_USE_ADD_FI_SUCCESS_SCREEN_COMPONENT,
                          is_first_use: this.isFirstUseScreen,
                        });
                        if (isMinAccountCountMet && this.showReferral) {
                          Mixpanel.trackEvent("View Add FI Success Referral", {
                            component: "Add FI Success Screen",
                            is_first_use: this.isFirstUseScreen,
                          });
                        }
                      }.bind(this)
                    );
                  }.bind(this),
                  DELAY_BEFORE_RUN_IC_STATE
                );
              }
            }.bind(this)
          );
          animatedStar.removeClass("animated zoomInDown");
          animatedStar.addClass(
            ["add-fi-success__star--animate-end", "_" + nth].join(" ")
          );
        }.bind(this)
      );
      animatedStar.addClass("animated zoomInDown");
    }

    var eventName = RUN_IC_STATE_EVENT_NAME;
    if (showTrophyState) {
      eventName = "View Add FI Success Trophy Screen";
    }
    Mixpanel.trackEvent(eventName, {
      component: FIRST_USE_ADD_FI_SUCCESS_SCREEN_COMPONENT,
      is_first_use: this.isFirstUseScreen,
    });

    if (
      this.config.showProgressBar &&
      !this.isFirstAccountAggregated &&
      !this.config.allowUserToContinue
    ) {
      // show progress bar
      this.$el.find(".progressBar").show();
    }
    this.showSection(ACCOUNT_SUBMITTED_CLASS);
  },
  onAccountUpdatedSuccess: function () {
    this.$el
      .find(ACCOUNT_SUBMITTED_CLASS)
      .html(
        "<div class='js-update-account-success-container pc-u-mt++ pc-u-pt'></div>"
      );
    this.accountCredentialsUpdateSuccess = new BackboneReactView({
      el: this.$(".js-update-account-success-container"),
      component: FiDefaultSuccessScreen,
      componentProps: {
        onClose: this.handleClose.bind(this),
      },
    });

    this.showSection(ACCOUNT_SUBMITTED_CLASS);
  },

  onManualAccountRecapture: async function () {
    $(".js-first-use-link-account-header").addClass("is-hidden");
    $(".linkAccountContainer").addClass("is-hidden");
    $(".footer").addClass("is-hidden");

    // Navigate to Add your assets modal
    const { default: ManualAccountRecaptureWizard } = await import(
      "../../components/ManualAccountRecapture/ManualAccountRecaptureWizard"
    );
    this.ManualAccountRecapture = new BackboneReactView({
      el: "#firstUseLinkAccount",
      component: ManualAccountRecaptureWizard,
      componentProps: {
        onDone: () => {
          window.PCAP.skipFirstUse = true;
          this.ManualAccountRecapture.remove();
          this.handleClose();
        },
      },
    });
  },

  onAddCustomAccountPostSuccess: async function (data) {
    const template = CustomAccountSubmittedTemplateRebrand;

    this.$el.find(ACCOUNT_SUBMITTED_CLASS).html(
      template({
        name: data.name,
      })
    );
    var account = data.account;
    if (!_.isUndefined(account)) {
      window.numberOfAccounts += 1;
      if (account.isEsog === true || account.isManualPortfolio === true) {
        var href = "";
        if (account.isEsog === true) {
          href = "#/stock-options?ua=" + account.userAccountId;
          this.$el
            .find(LINK_ACCOUNT_COPY_CLASS)
            .html("Click continue to add<br/> your first grant");
        } else if (account.isCrypto) {
          href = "#/crypto?ua=" + account.userAccountId;
          this.$el
            .find(LINK_ACCOUNT_COPY_CLASS)
            .html("Click continue to add<br/> your assets");
        } else if (account.isManualPortfolio) {
          href = "#/accounts/details?ua=" + account.userAccountId;
          this.$el
            .find(LINK_ACCOUNT_COPY_CLASS)
            .html("Click continue to add<br/> your first holding");
        }
        this.$el.find(".accountSubmitted .buttonContainer").hide();
        var continueEl = this.$el.find(
          ".accountSubmitted .buttonContainer.continue"
        );
        continueEl.find("a.pc-btn").attr("href", href);
        continueEl.show();
        continueEl.removeClass("hidden");
      }
    }
    this.showSection(ACCOUNT_SUBMITTED_CLASS);
  },
  // override-able attributes end
  searchSuggestion: function (e) {
    var self = this;
    e.preventDefault();
    if (isEmulatorMode === true) {
      return;
    }
    var suggestionTerm = $(e.currentTarget).data("name");
    self.$el.find(SEARCH_INPUT_CLASS).val(suggestionTerm).trigger("keyup");
  },
  searchTerm: "",

  /**
   *
   * @description This function search in a js file (searchAccountsExtraOptions.js) for
   *    matches in order to add specific accounts suggestions
   * @param {string} searchTerm the input of the search field
   * @param {array} customAccounts array of objects for manual accounts
   * @param {object} processedSearchResults results already added to the list
   * @returns {array} Array of accounts if there is a match and the object is not already
   *    added, or empty array if there's no match
   */
  addManualAccountsResult: function (
    searchTerm,
    customAccounts,
    processedSearchResults
  ) {
    const { popular, regular } = processedSearchResults;
    let matchType = "";
    const CRYPTO = "Cryptocurrency";
    const HOME = "Home";
    const lcSearchTerm = searchTerm.toLowerCase();
    if (
      popular.filter((acc) => acc.name === CRYPTO).length === 0 &&
      regular.filter((acc) => acc.name === CRYPTO).length === 0
    ) {
      cryptoCoins.forEach((cryptoCoin) => {
        let lcElement = cryptoCoin.toLowerCase();
        if (
          lcElement.includes(lcSearchTerm) ||
          lcSearchTerm.includes(lcElement)
        ) {
          matchType = CRYPTO;
        }
      });
      cryptoExchanges.forEach((cryptoExchange) => {
        let lcElement = cryptoExchange.toLowerCase();
        if (
          lcElement.includes(lcSearchTerm) ||
          lcSearchTerm.includes(lcElement)
        ) {
          matchType = CRYPTO;
        }
      });
    }
    if (
      popular.filter((acc) => acc.name === HOME).length === 0 &&
      regular.filter((acc) => acc.name === HOME).length === 0
    ) {
      manualHome.forEach((element) => {
        let lcElement = element.toLowerCase();
        if (
          lcElement.includes(lcSearchTerm) ||
          lcSearchTerm.includes(lcElement)
        ) {
          matchType = HOME;
        }
      });
    }
    switch (matchType) {
      case CRYPTO:
        return customAccounts.filter((account) => account.name === CRYPTO);
      case HOME:
        return customAccounts.filter((account) => account.name === HOME);
      default:
        return [];
    }
  },

  searchAccounts: function () {
    var self = this;
    if (!this.searchAccountInput) {
      this.searchAccountInput = this.$(SEARCH_INPUT_CLASS);
    }
    this.searchTerm = this.searchAccountInput.val();
    if (this.searchTerm.length > 0) {
      this.searchAccountInput.siblings(".reset").addClass("active");
    } else {
      this.searchAccountInput.siblings(".reset").removeClass("active");
    }
    if (isEmulatorMode === true) {
      return;
    }
    if (this.searchTerm.length > 2) {
      // publish the search results section being viewed
      if (!this.$el.hasClass("searchResults")) {
        eventBus.trigger("linkAccount:searchResults");
      }

      if (this.newSearch) {
        if (window.ga) {
          window.ga(
            "send",
            "event",
            "Add Account",
            "Search Products",
            this.productType
          );
        }
        analytics.sendPageView("/search-products-in-add-fi-form");
        Mixpanel.trackEvent("Search FI", {
          component: LINK_ACCOUNT_SCREEN_COMPONENT,
          search: this.searchTerm,
          product_type: this.productType,
          is_first_use: this.isFirstUseScreen,
        });
        this.newSearch = false;
      }

      var searchAccountCallback = function (err, data) {
        // we do not display search results for search terms that are less than two chars in length
        // TODO: better test would be to compare if the api' search term and current search term are the same
        if (self.searchTerm.length <= 2) {
          return;
        }
        const template = AccountsSearchResultTemplateRebranded;

        self.showSection(SEARCH_RESULTS_CLASS);
        var searchResults = (self.searchResults = data.spData);
        if (searchResults.length === 0) {
          $(".tier1", self.$el).addClass("noResults");
        } else {
          $(".tier1", self.$el).removeClass("noResults");
        }
        var processedSearchResults = self.processSearchResults(searchResults);
        var manualAccountArray = self.addManualAccountsResult(
          self.searchTerm,
          self.customAccounts,
          processedSearchResults
        );
        if (manualAccountArray.length > 0) {
          // manual Account element will be added at the beginning of the search list.
          processedSearchResults.regular.unshift(manualAccountArray[0]);
          processedSearchResults.found = true;
        }

        self.$el.find(".searchResults .productTypes").hide();
        self.$el.find(".searchResults .scroller").html(
          template({
            sites: processedSearchResults,
            staticUrl: window.staticUrl || "",
          })
        );
        self.$el.find(".searchResults .scroller").slimScroll({
          height: self.getTier2Height(SEARCH_RESULTS_CLASS),
        });
        Mixpanel.trackEvent("Search results are shown", {
          component: LINK_ACCOUNT_SCREEN_COMPONENT,
          search: self.searchTerm,
          number_of_popular_results: processedSearchResults?.popular?.length,
          number_of_regular_results: processedSearchResults?.regular?.length,
          product_type: self.productType,
          is_first_use: self.isFirstUseScreen,
        });
        self.trigger("onsearchaccount");
      };
      Services.SearchSites.get(
        { searchString: self.searchTerm },
        searchAccountCallback
      );
    } else {
      // publish the search results section being viewed
      if (!self.$el.hasClass("suggestions")) {
        eventBus.trigger("linkAccount:suggestions");
      }
      self.showSection(SUGGESTIONS_CLASS);
      this.newSearch = true;
    }
  },
  searchAccountsClick: function () {
    if (isEmulatorMode === true) {
      return;
    }
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "modal_link_your_account_search_box"
      );
      handleAmplitudeEvent(
        "SELECT_BUTTON",
        "modal_link_your_account_search_box"
      );
    }
  },
  searchAccountsPostAddFiSuccess: function (e) {
    if (isEmulatorMode === true) {
      return;
    }
    var MIN_CHARS_TO_SEARCH = 3;
    var targetInput = $(e.currentTarget);
    var query = $.trim(targetInput.val());
    if (query.length >= MIN_CHARS_TO_SEARCH) {
      var input = this.$(SEARCH_INPUT_CLASS);
      this.once("onsearchaccount", function () {
        input.val(targetInput.val());
        input.focus();
      });
      input.val(query);
      this.searchAccounts();
      Mixpanel.trackEvent("Search FI", {
        component: FIRST_USE_ADD_FI_SUCCESS_SCREEN_COMPONENT,
        search: query,
        is_first_use: this.isFirstUseScreen,
      });
    }
  },
  onAddFiSuccessSearchInputFocus: function () {
    Mixpanel.trackEvent("Focus on Search Input Field", {
      component: FIRST_USE_ADD_FI_SUCCESS_SCREEN_COMPONENT,
      is_first_use: this.isFirstUseScreen,
    });
  },
  onAddFiSuccessSearchResetClick: function () {
    Mixpanel.trackEvent("Click on Reset Icon on Search Input Field", {
      component: FIRST_USE_ADD_FI_SUCCESS_SCREEN_COMPONENT,
      is_first_use: this.isFirstUseScreen,
    });
  },
  onAddFiSuccessDoneLinkingClick: function () {
    Mixpanel.trackEvent("Click on I'm Done Linking Accounts Link", {
      component: FIRST_USE_ADD_FI_SUCCESS_SCREEN_COMPONENT,
      account_count: this.accountCount,
      is_first_use: this.isFirstUseScreen,
    });
  },
  onAddFiSuccessGoToDashboardClick: function (event) {
    event.preventDefault();
    eventBus.trigger(LINK_ACCOUNT_DONE_EVENT, { skipHistory: true });
    if (IS_EMPOWER) {
      this.remove();
      window.location.href = DASHBOARD_URL;
    } else {
      AppRouter.navigate(DASHBOARD_URL, { replace: true });
    }
    Mixpanel.trackEvent("Click on Go to Dashboard Link", {
      component: FIRST_USE_ADD_FI_SUCCESS_SCREEN_COMPONENT,
      account_count: this.accountCount,
      is_first_use: this.isFirstUseScreen,
    });
  },
  onAddFiSuccessRunICClick: function (event) {
    event.preventDefault();
    eventBus.trigger(LINK_ACCOUNT_DONE_EVENT, { skipHistory: true });
    if (!IS_EMPOWER) {
      AppRouter.navigate("#/investment-checkup/allocation", { replace: true });
    }
    Mixpanel.trackEvent("Click on Run Investment Checkup", {
      component: FIRST_USE_ADD_FI_SUCCESS_SCREEN_COMPONENT,
      account_count: this.accountCount,
      is_first_use: this.isFirstUseScreen,
    });
  },
  onAddFiSuccessRunReferAFriend: function (event) {
    const url = this.isUserClient
      ? "#/referral-center?key=referral_center_c&source=link-account-modal"
      : "#/referral-center?key=referral_center_b&source=link-account-modal";
    event.preventDefault();
    eventBus.trigger(LINK_ACCOUNT_DONE_EVENT, { skipHistory: true });
    if (!IS_EMPOWER) {
      AppRouter.navigate(url, { replace: true });
    }
    Mixpanel.trackEvent("Click on Refer a Friend Link", {
      component: "Add FI Success Screen",
      account_count: this.accountCount,
      is_first_use: this.isFirstUseScreen,
    });
  },
  resetSearchAccounts: function () {
    Mixpanel.trackEvent("Clear FI Search Result", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
    });
    this.newSearch = true;
    this.resetFlow();
  },
  resetSearchAccountsFilter: function () {
    var parentTab = this.$el.find(".searchResults ul.tab");
    parentTab.children("li").removeClass("active");
    parentTab.children("li:first-child").addClass("active");
  },
  submitForm: function (e) {
    e.preventDefault();
    if (isEmulatorMode === true) {
      return;
    }
    $(e.currentTarget).parents("form").trigger("submit");
  },

  updateAccount: function (authenticationObject, editingAccountId) {
    var self = this;
    let payload = {
      hasUpdateCredentials: true,
      credentials: authenticationObject.credentials,
      accountId: editingAccountId,
    };
    Services.Accounts.updateCredentials(payload, (err, response) => {
      let errors = parseResponseErrors(err, response);
      if (errors) {
        self.$(".js-update-credentials-error").html(errors[0]).show();
        self
          .$(".submitAccountAuthentication")
          .addClass("active")
          .text("Update");
      } else {
        self.onAccountUpdatedSuccess();
      }
    });
  },
  analyticsPageviewEvent: function () {
    if (!IS_EMPOWER && !self.editingAccountId) {
      window.ga && window.ga("send", "pageview", "/accounts/add/submit"); // eslint-disable-line no-unused-expressions
    }
  },

  // add the created account to a global list to track aggregation
  addAccountToGlobalList: function (siteId, productName) {
    if (siteId) {
      window.PCAP.accountsSubmitted = window.PCAP.accountsSubmitted || {};
      window.PCAP.accountsSubmitted[siteId] = {
        siteId: siteId,
        productName: productName,
        timestamp: Math.round(new Date().getTime() / 1000),
        component: LINK_ACCOUNT_SCREEN_COMPONENT,
        product_type: this.productType || "",
        is_first_use: this.isFirstUseScreen,
        fi_name: this.siteData.name || "",
        is_OAuth: this.siteData.isOauth || false,
      };
    }
  },

  empowerAnalyticsSaveEvent: function (productName) {
    if (IS_EMPOWER) {
      let label = "Linked";
      if (productName) {
        label += ` - ${productName}`;
      }

      analytics.sendEmpowerTrackEvent(ACCOUNT_AGGREGATION_EVENT, label, "Save");
    }
  },
  // object created to pass information from submitCustomAccountCreation
  // function to customAccountCreation callback to reduce cognitive complexity
  customAccountCreationObj: {},
  submitCustomAccountCreation: function (e) {
    var self = this;
    e.preventDefault();
    if (isEmulatorMode === true) {
      return;
    }

    var formEl = $(e.currentTarget),
      customAccountType = formEl.find('input[name="productName"]').val(),
      customAccounts = self.customAccounts,
      customAccount = _.find(customAccounts, function (customAccount) {
        return customAccount.name == customAccountType;
      });

    this.customAccountCreationObj = {
      formEl,
      customAccountType,
      customAccount,
    };
    self.validateAndSubmit(
      e.currentTarget,
      self.submitCustomAccountCreationCallback.bind(self)
    );
  },

  // build post data
  submitCustomAccountCreationCallback: function () {
    var self = this;
    const { formEl, customAccountType, customAccount } =
      self.customAccountCreationObj;

    var formData = {
      productId: formEl.find('input[name="productId"]').val(),
      isManual: true,
      isManualPortfolio: customAccount.isManualPortfolio || false,
      isCrypto: customAccount.isCrypto || false,
      isEsog: customAccount.isEsog || false,
    };

    self.customAccountCreationAnalytics(customAccountType);

    if (customAccount.isHome === true && self.credentialsModel.currentBalance) {
      self.credentialsModel.currentBalance = currencyFormatter.unformat(
        self.credentialsModel.currentBalance
      );
    }

    formData.credentials = JSON.stringify(self.credentialsModel);

    // add esog specific data
    if (customAccount.isEsog) {
      var companyName = formEl.find('[name = "companyName"]'),
        unvestOptionsEl = formEl.find('[name = "trackOnlyUnvestedOptions"]');
      if (companyName.length > 0) {
        formData.name = formData.userAccountName = companyName.val();
      }
      if (unvestOptionsEl.length > 0) {
        formData.trackVestedOptions = !unvestOptionsEl.is(":checked");
      }
    }

    // check if account already exists and take appropriate action
    if (
      customAccount.isHome === true &&
      !_.isUndefined(self.customAccountLinked) &&
      !_.isUndefined(self.customAccountLinked.userAccountId)
    ) {
      // update account
      Mixpanel.trackEvent(`Updated a ${customAccount.name} manual account`, {
        component: LINK_ACCOUNT_SCREEN_COMPONENT,
        fi_name: customAccount.name,
        product_type: this.productType || "",
        is_first_use: this.isFirstUseScreen,
      });
      formData.accountId = self.customAccountLinked.accountId;
      Services.Accounts.updateManual(
        formData,
        self.handleCustomAccountResponse,
        self
      );
    } else {
      // WEB-4105
      formData.manualCredentials = formData.credentials;
      // create account
      Mixpanel.trackEvent(`Created a ${customAccount.name} manual account`, {
        component: LINK_ACCOUNT_SCREEN_COMPONENT,
        fi_name: customAccount.name,
        product_type: this.productType || "",
        is_first_use: this.isFirstUseScreen,
      });
      Services.Accounts.create(
        formData,
        self.handleCustomAccountResponse,
        self
      );
    }
  },
  customAccountCreationAnalytics: function (customAccountType) {
    if (window.ga && !IS_EMPOWER) {
      window.ga(
        "send",
        "pageview",
        "/accounts/add/" +
          customAccountType.replace(/\s+/g, "-").toLowerCase() +
          "/submit"
      );
    } else if (IS_EMPOWER) {
      analytics.sendEmpowerTrackEvent(
        ACCOUNT_AGGREGATION_EVENT,
        "Manual - " + customAccountType,
        "Save"
      );
      window.dashboardUtils?.eventBus.dispatch(
        "modal_link_your_account_done_clicked_event" + customAccountType
      );
      handleAmplitudeEvent(
        "SELECT_BUTTON",
        "modal_link_your_account_done_clicked_event" + customAccountType
      );
    }
  },
  handleCustomAccountResponse: function (err, data) {
    var self = this;
    var account =
      _.isObject(data.spData) &&
      _.isArray(data.spData.accounts) &&
      data.spData.accounts.length > 0
        ? data.spData.accounts[0]
        : undefined;
    var customProductName = _.isUndefined(account.customProductName)
      ? "account"
      : account.customProductName;
    self.customAccountLinked = account;
    if (account.isHome === true && account.nextAction.action != "NONE") {
      // hold the user flow and show the next action
      var nextActionSection = self.$el.find(".nextActionMessage");
      if (nextActionSection.length <= 0) {
        self.$el
          .find(".buttonContainer.actions")
          .before(
            '<div class="formElement nextActionMessage">' +
              account.nextAction.nextActionMessage +
              "</div>"
          );
      } else {
        nextActionSection.html(account.nextAction.nextActionMessage);
      }
      self.$el.find(".submitForm").addClass("active").text("Done");
    } else {
      // move forward and publish the account created state
      eventBus.trigger("linkAccount:created", account);
      self.trackMixpanelCredentialsSubmission(account);
      self.trackMixpanelAggSuccess(account);
      self.onAddCustomAccountPostSuccess({
        name: customProductName,
        account: account,
      });
    }
  },
  trackMixpanelCredentialsSubmission: function (account) {
    account = account || {};
    Mixpanel.trackEvent("FI Credentials Submit Success", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      fi_name: account.firmName,
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
    });
  },
  trackMixpanelAggSuccess: function (account) {
    account = account || {};
    /* Send the "First Acct Agg Success" event under 2 scenarios
     * 1) The user links the first yodlee account in the First use screen
     * 2) The user who has USE_UNIFIED_MAR feature flag set to true, links the first yodlee account from the dashboard
     */
    if (
      this.isFirstUseScreen ||
      (this.config.useUnifiedMar && !this.accountCount)
    ) {
      Mixpanel.trackEvent("First Acct Agg Success", {
        component: LINK_ACCOUNT_SCREEN_COMPONENT,
        fi_name: account.firmName,
        product_type: this.productType,
        is_first_use: this.isFirstUseScreen,
        is_OAuth: account.isOAuth,
      });
    }
    Mixpanel.trackEvent("FI Agg Success", {
      component: this.viewFiSearchScreenComponent,
      fi_name: account.firmName,
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
      is_OAuth: account.isOAuth,
    });
  },
  validateAndSubmit: function (formEl, callback) {
    //validate
    if (this.fiCredentialsFormRef.validate().valid) {
      $(formEl, self.$el)
        .find(".submitForm")
        .removeClass("active")
        .text("linking...");
      callback();
    } else {
      Mixpanel.trackEvent("FI Credentials Submit Attempt", {
        component: LINK_ACCOUNT_SCREEN_COMPONENT,
        product_type: this.productType,
        is_first_use: this.isFirstUseScreen,
      });
    }
  },
  removeInvalid: function (e) {
    var el = $(e.currentTarget);
    el.parents(".formElement").removeClass("invalid");
    el.siblings(".inputError").html("");
    // remove next action message on input focus
    this.$el.find(".nextActionMessage").remove();
  },
  searchResultClick: function (e) {
    if (isEmulatorMode === true) {
      return;
    }
    Mixpanel.trackEvent("Click on FI in Search Result", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      fi_name: $(e.currentTarget).data("name"),
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
    });
    if ($(e.currentTarget).hasClass("customAccountSearch")) {
      this.showCustomAccountFormHandler(e);
    } else {
      this.loadAccountAuthenticationForm(e);
    }
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "modal_link_your_account_search_match_clicked_event"
      );
      handleAmplitudeEvent(
        "SELECT_BUTTON",
        "modal_link_your_account_search_match_clicked_event"
      );
    }
  },
  getSiteLink: function (e) {
    e.preventDefault();
    this.loadAccountAuthenticationForm(e, true);
  },

  handleCredentialFieldsChange: function (e) {
    this.credentialsModel[e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.renderFiCredentials();
  },
  handleZillowCredentialFieldsChange: function (e) {
    this.credentialsModel[e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (e.target.name === "useHomeValuation" && e.target.checked) {
      delete this.credentialsModel.currentBalance;
    }

    this.renderZillowCredentials();
  },
  loadAccountAuthenticationForm: function (e, doNotTrack = false) {
    if (isEmpowerPaeMode === true) {
      return;
    }
    eventBus.trigger("linkAccount:accountAuthentication");
    var siteId = this.config.siteId;

    if (e) {
      e.preventDefault();
      siteId = $(e.currentTarget).data("site_id");
    }

    Services.GetSite.get(
      { siteId: siteId, searchString: this.searchTerm },
      (err, d) => {
        let siteData = deepCopy(d.spData);
        window.ga && window.ga("send", "pageview", "/accounts/add/form"); // eslint-disable-line no-unused-expressions
        siteData.securityMessages = SECURITY_MESSAGES;
        if (siteData.suggestedSites) {
          siteData.hasSuggestedSites = true;
          siteData.suggestedSitesHTML = "";
          siteData.suggestedSites.forEach((site) => {
            siteData.suggestedSitesHTML =
              siteData.suggestedSitesHTML +
              '<div class="add-account__suggested-site">' +
              site +
              "</div>";
          });
        }
        this.siteData = siteData;
        this.doNotTrack = doNotTrack;
        if (this.siteData.isSiteInBlackoutPeriod) {
          this.renderBlackoutPeriodScreen(this.siteData);
          this.showSection(ACCOUNT_AUTHENTICATION_CLASS, doNotTrack);
          if (this.isResumeLinkingEnabled) {
            this.sendBlackoutAggregationAttempt(this.siteData);
          }
        } else if (this.isFirstUseScreen) {
          this.manageModalToShow();
        } else if (this.siteData.isOAuth) {
          this.renderFastlink();
        } else {
          this.renderSuggestExistingSites();
          this.showSection(ACCOUNT_AUTHENTICATION_CLASS, doNotTrack);
        }
      }
    );
    Mixpanel.trackEvent("View FI Credentials Screen", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      fi_name: $(e.currentTarget).data("name") || "",
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
    });
  },
  accountAggExpectationMixpanel: (testGroup) => {
    Mixpanel.updateProfile(
      { TestGroupID: "AccountAddExpectation_" + testGroup },
      null,
      true
    );
    Mixpanel.trackEvent("AccountAddExpectation_" + testGroup, {
      component: "First Use",
    });
  },
  manageModalToShow: function () {
    if (this.config.accountExpectationTest) {
      if (this.whatToExpectModalActive) {
        this.renderWhatToExpectTestModal(this.siteData);
        this.accountAggExpectationMixpanel("Test");
      } else {
        this.renderFastlink();
        this.accountAggExpectationMixpanel("Control");
      }
    } else {
      this.renderFastlink();
    }
  },
  sendBlackoutAggregationAttempt: function (siteData) {
    this.authenticationObject.siteId = siteData.siteId;
    Services.AddAccount.post(this.authenticationObject, null);
  },
  renderBlackoutPeriodScreen: function (siteData) {
    const BLACKOUT_PERIOD_ID = "accountBlackoutPeriod";
    if ($("#" + BLACKOUT_PERIOD_ID).length === 0) {
      this.$(TIER2_AUTHENTICATION_CLASS).append(
        '<div id="' + BLACKOUT_PERIOD_ID + '" class="module"></div>'
      );
    }
    this.AccountBlackoutPeriod = new BackboneReactView({
      el: "#" + BLACKOUT_PERIOD_ID,
      component: AccountBlackoutPeriod,
      componentProps: {
        onBack: this.handleBackToSearch.bind(this),
        name: siteData.name,
        logoPath: siteData.logoPath,
        instructions: siteData.instructions,
      },
    });
    $("h2.linkAccountTitle").html("Connection Currently Unavailable");
    Mixpanel.trackEvent("View Blackout Modal", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      fi_name: siteData.name,
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
    });
  },
  renderWhatToExpectTestModal: function (siteData) {
    if ($("#whatToExpectTestModal").length === 0) {
      this.$(TIER2_AUTHENTICATION_CLASS).append(
        '<div id="whatToExpectTestModal" class="module"></div>'
      );
    }
    this.WhatToExpectTestModal = new BackboneReactView({
      el: "#whatToExpectTestModal",
      component: WhatToExpectTestModal,
      componentProps: {
        onBack: this.handleBackToSearch.bind(this),
        onContinue: this.whatToExpectContinue.bind(this),
        isOpen: true,
        name: siteData.name,
        logoPath: siteData.logoPath,
        isOAuth: siteData.isOAuth,
      },
    });
    Mixpanel.trackEvent("View What to Expect Modal", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      fi_name: siteData.name,
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
    });
  },
  whatToExpectContinue: function () {
    const { siteData } = this;
    Mixpanel.trackEvent("Continue on What to Expect Modal", {
      component: "What To Expect Modal",
      fi_name: this.siteData.name,
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
    });
    if (this.WhatToExpectTestModal) {
      this.WhatToExpectTestModal.remove();
    }
    this.renderFastlink(siteData.userSiteId);
  },
  renderSuggestExistingSites: function () {
    let { siteData } = this;
    if ($("#suggestExistingSites").length === 0) {
      this.$(TIER2_AUTHENTICATION_CLASS).append(
        '<div id="suggestExistingSites" class="module"></div>'
      );
    }

    this.SuggestExistingSites = new BackboneReactView({
      el: "#suggestExistingSites",
      component: SuggestExistingSites,
      componentProps: {
        onUpdateSite: this.handleOnLinkNewAccount.bind(this),
        onLinkNewAccount: this.handleOnLinkNewAccount.bind(this),
        onBack: this.handleBackToSearch.bind(this),
        siteId: siteData.siteId,
        siteName: siteData.name,
      },
    });
  },
  shouldModalClose() {
    if (!window.localStorage.getItem("LeaveAddFi") && this.isFirstUseScreen) {
      this.goBack();
      return false;
    }
    return true;
  },

  renderFastlink: function (userSiteId) {
    this.fastlinkFlow = userSiteId ? EDIT_FLOW : ADD_FLOW;
    let { siteData } = this;
    if ($("#fastlink-container").length === 0) {
      this.$(".tier2 .accountAuthentication").append(
        '<div id="fastlink-container" class="module"></div>'
      );
    }
    this.FastlinkContainerModal = new BackboneReactView({
      el: "#fastlink-container",
      component: FastlinkContainerModal,
      componentProps: {
        onSuccess: this.handleFastlinkOnSuccess.bind(this),
        onClose: this.handleFastlinkOnClose.bind(this),
        onCancel: this.handleFastlinkOnClose.bind(this),
        siteId: siteData.siteId,
        flow: this.fastlinkFlow,
        isOpen: true,
        userSiteId: userSiteId,
        shouldModalClose: this.shouldModalClose.bind(this),
        belowSuccessThresholdWarning: this.isConnectivityTrackerEnabled
          ? siteData.belowSuccessThresholdWarning
          : "",
      },
    });
  },
  handleFastlinkOnSuccess(data) {
    if (data?.spHeader?.userStage > "C") {
      window.sessionStorage.setItem("userIsAQ", "1");
    }
    if (isEmulatorMode === true) {
      return;
    }
    if (this.FastlinkContainerModal) {
      this.FastlinkContainerModal.remove();
    }
    if (data.spData.accounts.length > 0) {
      var self = this;
      if (this.fastlinkFlow === ADD_FLOW) {
        const accountName = data.spData.accounts[0].firmName;
        // TODO Check behavior with all subscribers of this event. We don't pass account details in this trigger
        // firstUseView2.js and investmentCheckupLinkAcccountView.js
        eventBus.trigger("linkAccount:created");
        self.trackMixpanelCredentialsSubmission({ firmName: accountName });
        self.trackMixpanelAggSuccess({
          firmName: accountName,
          isOAuth: data.spData.accounts[0].isOAuth,
        });
        self.empowerAnalyticsSaveEvent(accountName);
        this.onAddAccountPostSuccess({ name: accountName });
        eventBus.trigger("FASTLINK_ACCOUNT_LINKED", data.spData.accounts);
      } else {
        this.onAccountUpdatedSuccess();
      }
    }
  },

  handleFastlinkOnClose() {
    if (this.FastlinkContainerModal) {
      this.FastlinkContainerModal.remove();
    }
    this.goBack();
  },

  handleFastlinkOnEvent(data) {
    console.log("addAccount2 handleFastlinkOnEvent data: ", data);
  },

  handleBackToSearch(e) {
    e.preventDefault();
    this.resetFlow();
    if (this.SuggestExistingSites) {
      this.SuggestExistingSites.remove();
    }

    if (this.AccountBlackoutPeriod) {
      this.AccountBlackoutPeriod.remove();
      $("h2.linkAccountTitle").html("Link Your Account");
    }

    if (this.WhatToExpectTestModal) {
      Mixpanel.trackEvent("Back on What to Expect Modal", {
        component: "What To Expect Modal",
        fi_name: this.siteData.name,
        product_type: this.productType,
        is_first_use: this.isFirstUseScreen,
      });
      this.WhatToExpectTestModal.remove();
    }
  },

  handleClose(e) {
    if (e) {
      e.preventDefault();
    }
    $.colorbox.close();
    this.remove();
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        `modal_link_your_account_x_close_button_clicked_event`
      );
      handleAmplitudeEvent(
        "SELECT_BUTTON",
        "modal_link_your_account_x_close_button_clicked_event"
      );
    }
    window.location.href = DASHBOARD_URL;

    Mixpanel.trackEvent("Close Link Account Modal", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      is_first_use: this.isFirstUseScreen,
    });
  },

  handleOnLinkNewAccount(userSiteId) {
    this.SuggestExistingSites.remove();
    if (userSiteId) {
      this.renderFastlink(userSiteId);
      return;
    }
    this.renderFastlink();
  },

  renderZillowCredentials: function () {
    if (!this.credentialsModel) {
      //initial model for zillow react form
      this.credentialsModel = {
        propertyType: "PRIMARY_RESIDENCE",
        state: "AL",
        useHomeValuation: false,
      };
    }
    this.fiCredentialsContainer = this.$(
      ".customAccountForm .js-account-credentials"
    ).get(0);
    try {
      ReactDOM.render(
        React.createElement(ZillowCredentialFields, {
          onChange: this.handleZillowCredentialFieldsChange.bind(this),
          model: this.credentialsModel,
          ref: (instance) => {
            this.fiCredentialsFormRef = instance;
          },
        }),
        this.fiCredentialsContainer
      );
    } catch (e) {
      error(e);
    }
  },
  renderFiCredentials: function () {
    if (!this.credentialsModel) {
      this.credentialsModel = getInitialModelWithOptionFields(this.prompts);
    }
    if (this.isManual) {
      this.fiCredentialsContainer = this.$(
        ".customAccountForm .js-account-credentials"
      ).get(0);
    } else {
      this.fiCredentialsContainer = this.$(".js-account-credentials").get(0);
    }

    try {
      ReactDOM.render(
        React.createElement(AccountCredentialFields, {
          fields: this.prompts,
          onChange: this.handleCredentialFieldsChange.bind(this),
          model: this.credentialsModel,
          ref: (instance) => {
            this.fiCredentialsFormRef = instance;
          },
        }),
        this.fiCredentialsContainer
      );
    } catch (e) {
      error(e);
    }
  },
  onSelectDifferentInstitution: function (e) {
    e.preventDefault();
    if (this.aggregationLevel === "MANUAL_ONLY") {
      this.resetManualOnlyFlow();
    } else {
      this.resetFlow();
    }
  },
  onSiteSuggestionTileClick: function (e) {
    e.preventDefault();
    if (isEmulatorMode || isPrivileged) {
      return;
    }
    var isOnAddFiSuccessScreen = $(e.currentTarget)
      .parent()
      .hasClass("js-add-fi-success-popular-fis");
    if (IS_EMPOWER) {
      analytics.sendEmpowerTrackEvent(
        ACCOUNT_AGGREGATION_EVENT,
        `Linked Account - ${$(e.currentTarget).data("name")}`,
        "Add"
      );
      window.dashboardUtils?.eventBus.dispatch(
        "modal_link_your_account_select_bank"
      );
      handleAmplitudeEvent(
        "SELECT_BUTTON",
        "modal_link_your_account_select_bank"
      );
    } else {
      Mixpanel.trackEvent("Click on Popular FI Tile", {
        component: isOnAddFiSuccessScreen
          ? "FIRST_USE_ADD_FI_SUCCESS"
          : "LINK_ACCOUNT",
        popular_fi_name: $(e.currentTarget).data("name"),
        product_type: this.productType,
        is_first_use: this.isFirstUseScreen,
      });
    }

    if (window.ga) {
      window.ga("send", "event", {
        name: `${$(e.currentTarget).data("name")} tile`,
      });
    }

    var isMultiLogin = $(e.currentTarget).data("is_multi_login");
    if (isMultiLogin) {
      this.searchSuggestion(e);
    } else {
      this.loadAccountAuthenticationForm(e);
    }
  },

  shouldPerformHesitantUserTest: function () {
    // If is editing an existing account, then we don't need to perform the hesitant user test
    if (this.editingAccountId) {
      return false;
    }
    return this.isFirstUseScreen && !window.localStorage.getItem("LeaveAddFi");
  },
  aggregationAbandonersMixpanelReport: function (isAggregationAbandonersTest) {
    if (isAggregationAbandonersTest) {
      Mixpanel.updateProfile(
        { TestGroupID: "ActivateAggAbandon_Test" },
        null,
        true
      );
      Mixpanel.trackEvent("ActivateAggAbandon_Test", {
        component: "First Use",
      });
    } else {
      Mixpanel.updateProfile(
        { TestGroupID: "ActivateAggAbandon_Control" },
        null,
        true
      );
      Mixpanel.trackEvent("ActivateAggAbandon_Control", {
        component: "First Use",
      });
    }
    Mixpanel.trackEvent("View Aggregation Abandon Interrupt", {
      component: "First Use",
    });
  },

  renderFirstUseLeaveModal: async function (
    userIsOnLoginScreen,
    isAggregationAbandonersTest = false,
    isFirstUse = false
  ) {
    let firstUseZIndex = this.$el.css("z-index");

    if (
      (userIsOnLoginScreen && window.localStorage.getItem("LeaveAddFi")) ||
      (!userIsOnLoginScreen &&
        window.localStorage.getItem("AggregationAbandon"))
    ) {
      return;
    }

    if (isFirstUse) {
      this.aggregationAbandonersMixpanelReport(isAggregationAbandonersTest);
    } else {
      Mixpanel.trackEvent("View First Use Leave Add FI Modal", {
        component: "First Use",
      });
    }

    const CLOSE_AGGREGATION_ABANDON_INTERRUPT =
      "Close Aggregation Abandon Interrupt";

    if (isFirstUse && this.config.useUnifiedMar) {
      Mixpanel.trackEvent("View_MOB_Recapture", {
        component: "First Use",
      });
      this.firstUseLeaveAddFiModal = new BackboneReactView({
        component: NotReadyToLinkAnAccountModal,
        componentProps: {
          onCancel: () => this.firstUseLeaveAddFiModal.remove(),
          onContinue: () => {
            this.firstUseLeaveAddFiModal.remove();
            _.delay(
              function () {
                this.onManualAccountRecapture();
              }.bind(this),
              250
            );
          },
        },
      });
    } else {
      this.firstUseLeaveAddFiModal = new InfoModalView({
        wrap: true,
        title: POPUP_ON_LEAVE_ADD_FI.title,
        content: POPUP_ON_LEAVE_ADD_FI.content,
        className: `${DEFAULT_CLASS_NAMES} modal--abs-center`,
        isAggregationAbandonersTest: false,
      }).once("hidden", () => {
        this.firstUseLeaveAddFiModal.remove();
        this.firstUseLeaveAddFiModal = null;
        if (isFirstUse) {
          Mixpanel.trackEvent(CLOSE_AGGREGATION_ABANDON_INTERRUPT, {
            component: "First Use",
          });
        } else {
          Mixpanel.trackEvent("Close First Use Leave Add FI Modal", {
            component: "First Use",
          });
        }
        this.onAddAccountPostSuccess({});
      });
      this.firstUseLeaveAddFiModal.$el
        .parent()
        .next(".modal-backdrop")
        .css("z-index", firstUseZIndex + 1);
      this.firstUseLeaveAddFiModal.$el.css("z-index", firstUseZIndex + 1 + 1);
    }

    if (userIsOnLoginScreen) {
      window.localStorage.setItem("LeaveAddFi", "1");
    } else {
      window.localStorage.setItem("AggregationAbandon", "1");
    }
  },
  goBack: function (e) {
    if (e) {
      e.preventDefault();
    }
    if (
      this.shouldPerformHesitantUserTest() &&
      !this.isManual &&
      !this.$el.hasClass("customAccounts")
    ) {
      this.renderFirstUseLeaveModal(true);
      if (this.fastlinkFlow === ADD_FLOW) {
        this.resetFlow();
      }
      return;
    }
    if (IS_EMPOWER) {
      let title;
      if (e) {
        title = $(e.currentTarget)
          .parents(".customAccountsCreation")
          .find(".title")
          .text();
      }

      let label = "";
      if (title === "Home") {
        label = ".home_vault";
      } else if (title === "Manual Investment Holdings") {
        label = ".manual_investment_holdings";
      } else if (title === "Custom Stock Options") {
        label = ".custom_stock_options";
      } else {
        label = ".select_account";
      }
      window.dashboardUtils?.eventBus.dispatch(
        "modal_link_your_account_back_clicked_event" + label
      );
      handleAmplitudeEvent(
        "SELECT_BUTTON",
        "modal_link_your_account_back_clicked_event" + label
      );
    }
    if (this.$el.hasClass("customAccountForm")) {
      this.showSection("." + this.lastSection);
      this.cleanUpFieldForm();
    } else if (this.$el.hasClass("aboutSecurity")) {
      this.showSection("." + this.lastSection);
    } else {
      this.resetFlow();
    }
    Mixpanel.trackEvent("Click on Back Button on Form", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
    });
  },
  cleanUpFieldForm() {
    if (this.credentialsModel) {
      delete this.credentialsModel;
    }
    if (this.prompts) {
      delete this.prompts;
    }
    delete this.isManual;
    if (this.fiCredentialsContainer) {
      ReactDOM.unmountComponentAtNode(this.fiCredentialsContainer);
      delete this.fiCredentialsContainer;
    }

    this.$(".authenticationPanel").remove();
    delete this.editingAccountId;
  },
  resetManualOnlyFlow: function (e) {
    var self = this;
    window.ga && window.ga("send", "pageview", "/accounts/add"); // eslint-disable-line no-unused-expressions
    if (e) {
      e.preventDefault();
    }
    delete self.customAccountLinked;

    this.renderManualProducts();
  },
  resetFlow: function (e) {
    var self = this;
    this.cleanUpFieldForm();

    window.ga && window.ga("send", "pageview", "/accounts/add"); // eslint-disable-line no-unused-expressions
    if (e) {
      e.preventDefault();
    }
    // reset any custom linked account
    delete self.customAccountLinked;

    if (this.isFirstUseScreen && this.accountCount > 0) {
      // We are here in the first Use scenario due to close or cancel in a fastlink modal
      // after at least one account has been successfully linked. Show the previous trophy screen
      this.onAddAccountPostSuccess({});
    } else {
      // publish the search results section being viewed
      if (!self.$el.hasClass("suggestions")) {
        eventBus.trigger("linkAccount:suggestions");
      }
      self.showSection(SUGGESTIONS_CLASS);
    }
    this.$(SEARCH_INPUT_CLASS)
      .val("")
      .focus()
      .siblings(".reset")
      .removeClass("active");
    $(".tier2 input, .tier2 select", self.$el).each(function () {
      $(this).val($(this).data("default"));
      $(this).parents(".formElement").removeClass("invalid");
    });
    $(".tier2 option", self.$el).eq(0).prop("selected", true);
    $(".offlineAccounts form, .manualHoldings form").show();
    $("a.submitForm", self.$el).addClass("active").text("Continue");
    $(".cancelAddAccount", self.$el).text("Cancel");
    //$('.tier4 .back', this.$el).removeClass('hidden');
    $(".tier1", self.$el).removeClass("noResults");
  },
  showSection: function (className, doNotTrack) {
    var self = this;
    if (_.isUndefined(doNotTrack)) {
      doNotTrack = false;
    }
    // publish the section being viewed

    eventBus.trigger("linkAccount:showSection:" + className.substr(1));
    self.$el.find(".tier2 > .wrap > div").addClass("hidden");
    // update section title if available

    if (typeof this.updateSectionTitle == "function") {
      this.updateSectionTitle(className);
    }
    // store the current class so back button can use it
    var classNames = self.$el.attr("class").split(" ");

    if (!doNotTrack) {
      self.lastSection = classNames.length > 1 ? classNames[1] : "";
    }

    self.$el.attr("class", "addAccount").addClass(className.substr(1));
    if (!self.config.showManualAccounts) {
      self.$el.addClass("hideManualAccounts");
    }

    // calculate tier2 height

    self.$el
      .find(".tier2")
      .height(
        self.containerHeight -
          ($("div.addAccount .tier1:visible").outerHeight() +
            $("div.addAccount .tier3:visible").outerHeight() +
            $("div.addAccount .progressBar:visible").outerHeight())
      );
    self.$el.find(className).removeClass("hidden");
    self.$el.find(className).find("input:eq(0)").focus();
  },
  showCustomAccountFormHandler: function (e) {
    e.preventDefault();
    if (isEmulatorMode === true) {
      return;
    }
    const customType = $(e.currentTarget).data("custom_type");
    this.showCustomAccountForm(customType);
    Mixpanel.trackEvent(`Click on add ${customType} manual account`, {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      fi_name: customType,
      product_type: this.productType || "",
      is_first_use: this.isFirstUseScreen,
    });

    if (window.ga) window.ga("send", "event", { customType });
  },
  showCustomAccountForm: function (customAccountType) {
    if (isEmulatorMode === true) {
      return;
    }
    var self = this;
    if (customAccountType && customAccountType !== "") {
      if (IS_EMPOWER) {
        analytics.sendEmpowerTrackEvent(
          ACCOUNT_AGGREGATION_EVENT,
          `Manual - ${customAccountType}`,
          "Add"
        );
        const label =
          customAccountType === "Home"
            ? "modal_link_your_account_add_home_value_clicked_event"
            : "modal_link_your_account_add_portfolio_clicked_event";
        window.dashboardUtils?.eventBus.dispatch(label);
      } else if (window.ga) {
        window.ga(
          "send",
          "pageview",
          "/accounts/add/" +
            customAccountType.replace(/\s+/g, "-").toLowerCase()
        );
      }
      var selectedCustomAccount = _.find(
        self.customAccounts,
        function (customAccount) {
          return customAccount.name == customAccountType;
        }
      );

      self.$el.find(CUSTOM_ACCOUNT_FORM_CLASS).html(
        CustomAccountAddFormTemplate({
          ...selectedCustomAccount,
          isEmulatorMode: isEmulatorMode || isPrivileged,
        })
      );

      this.prompts = selectedCustomAccount.prompts;
      this.isManual = true;
      this.processCustomPrompt(this.prompts);

      // Home needs to render a custom form element since theres some hide show logic
      // involved an the server is not returning the fields to hide/show as dependency
      if (!selectedCustomAccount.isHome) {
        this.renderFiCredentials();
      }

      if (selectedCustomAccount.isEsog === true) {
        self.$el
          .find('.customAccountForm [name="trackOnlyUnvestedOptions"]')
          .siblings("label")
          .hide();
        self.$el
          .find(".customAccountForm .formElement:last")
          .after(
            '<div class="formElement"><p class="additionalCopy">If your company is public, use the stock option feature to track only the value of your unvested options, and track vested options by linking your company’s brokerage account.</p></div>'
          );
        self.$el
          .find(".customAccountForm .submitCustomAccount")
          .html("Continue");
      }
      if (selectedCustomAccount.isHome === true) {
        this.renderZillowCredentials();
      }
      self.showSection(CUSTOM_ACCOUNT_FORM_CLASS);
      self.$el.find(".customAccountForm input").placeholder();
      self.$el.find(".customAccountForm .scroller").slimScroll({
        height: self.getTier2Height(CUSTOM_ACCOUNT_FORM_CLASS),
      });
      self.$el.find(CUSTOM_ACCOUNT_FORM_CLASS).find("input:eq(2)").focus();
    }
  },
  processCustomPrompt: function (currentPrompts) {
    // Add a maxValue validation field for currentBalance
    return currentPrompts.map((prompt) => {
      if (prompt.parts[0].id === "currentBalance") {
        prompt.parts[0].maxValue = 99999999.99;
        prompt.parts[0].validator = {
          pattern: "^(?!\\s+$).*$",
          type: "number",
          required: true,
          allowEmpty: false,
        };
      }
      if (prompt.parts[0].id === "name") {
        prompt.parts[0].validator = {
          pattern: "^(?!\\s+$).*$",
          type: "string",
          required: true,
          allowEmpty: false,
          messages: "Not a valid string",
        };
      }
      if (prompt.parts[0].id === "accountNumber") {
        prompt.parts[0].validator = {
          pattern: "^(?!\\s+$).*$",
          type: "string",
          required: false,
          allowEmpty: true,
          messages: "Not a valid string",
        };
      }
      return prompt;
    });
  },
  showAllCustomAccounts: function (e) {
    e.preventDefault();
    if (isEmulatorMode === true) {
      return;
    }
    Mixpanel.trackEvent("Click on Add Manual Account Button", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
    });
    if (!_.isUndefined(e)) {
      e.preventDefault();
    }
    this.renderManualProducts();
    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "modal_link_your_account_more_clicked_event"
      );
      handleAmplitudeEvent(
        "SELECT_BUTTON",
        "modal_link_your_account_more_clicked_event"
      );
    }
  },
  showSendRequestForm: function (e) {
    e.preventDefault();
    var self = this;
    var searchPhrasePrepend = this.isUserClient ? "" : "Site Not Supported - "; //Prepend a special string for non-client users per WEB-3258
    self.$el.find(SEND_REQUEST_FORM_CLASS).html(
      SendRequestFormTemplate({
        searchPhrase: searchPhrasePrepend + self.searchTerm,
      })
    );
    self.showSection(SEND_REQUEST_FORM_CLASS);
    self.$el.find(".sendRequestForm input").placeholder();
    self.$el.find(".sendRequestForm .scroller").slimScroll({
      height: self.getTier2Height(SEND_REQUEST_FORM_CLASS),
    });
    Mixpanel.trackEvent("Click on Send Request Button", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      product_type: this.productType,
      is_first_use: this.isFirstUseScreen,
    });
    self.$el.find(".sendRequestForm .button").slimScroll({
      disabled: "disabled",
    });
  },
  submitRequestSupportForm: function (e) {
    e.preventDefault();
    var self = this;
    var formEl = this.$el.find(".sendRequestForm form");
    var message =
      formEl.find('[name="searchPhrase"]').val() +
      " : " +
      formEl.find('[name="loginUrl"]').val() +
      " : " +
      formEl.find('[name="otherInformation"]').val();
    Services.Feedback.send({ description: message }, function (err, data) {
      self.onSubmitRequestSupportForm(data);
    });
  },
  onSubmitRequestSupportForm: function () {
    this.$el.find(".sendRequestSubmitted").html(
      SendRequestFormSubmittedTemplate({
        isUserClient: this.isUserClient,
      })
    );
    this.showSection(".sendRequestSubmitted");
  },
  closeSendRequest: function (e) {
    e.preventDefault();
    this.resetFlow();
  },
  getTier2Height: function (state) {
    var self = this,
      $el = self.$el,
      height = $el.find(".tier2").height();
    switch (state) {
      case SUGGESTIONS_CLASS: {
        return height + "px";
      }
      case SEARCH_RESULTS_CLASS: {
        //scroller height: deduct tabs height if visible
        height = height - $el.find(".productTypes:visible").outerHeight();
        return height + "px";
      }
      case ACCOUNT_AUTHENTICATION_CLASS: {
        //scroller height: deduct logo height

        height = height - $el.find(".logoShell").outerHeight();
        return height + "px";
      }
      case CUSTOM_ACCOUNT_FORM_CLASS: {
        //scroller height: deduct logo height
        height =
          height - $el.find(".customAccountForm .logoShell").outerHeight();
        return height + "px";
      }
      case CUSTOM_ACCOUNTS_CLASS: {
        //scroller height: deduct title height
        height = height - $el.find(".customAccounts .title").outerHeight();
        return height + "px";
      }
      case SEND_REQUEST_FORM_CLASS: {
        return height + "px";
      }
      default: {
        return height + "px";
      }
    }
  },
  onLinkAccountDone: function (e) {
    e.preventDefault();
    eventBus.trigger(LINK_ACCOUNT_DONE_EVENT);
    this.renderScheduleAppointmentModal();
  },
  doneLinkingHandler: function (e) {
    e.preventDefault();
    eventBus.trigger("firstUseLinkAccount:doneLinking");
  },
  getAggregatedAccountsOfProductType: function (productType) {
    var self = this,
      accounts = self.collection;

    // filter accounts by product type and next action
    accounts = self.collection.filter(function (account) {
      if (_.isUndefined(productType) || $.trim(productType).length === 0) {
        return account.get("nextAction")?.action != "WAIT";
      }
      return (
        account.get("productType") == productType &&
        account.get("nextAction")?.action != "WAIT"
      );
    });
    return accounts;
  },
  getFeatureLink: function (account) {
    var productType = account.get("productType");
    if (productType === "INVESTMENT") {
      return PORTFOLIO_URL;
    }

    return "#/all-transactions";
  },
  updateProductType: function (type) {
    this.productType = type;
    this.renderSuggestions();
  },
  customAccountContinueClick: function (e) {
    e.preventDefault();
    let continueToUrl = $(e.currentTarget).attr("href");

    // user is redirected after linking manual investment or adding custom stock options
    const requiresRedirect =
      continueToUrl.includes("stock-options") ||
      continueToUrl.includes("accounts/details");

    if (IS_EMPOWER && !requiresRedirect) {
      continueToUrl = DASHBOARD_URL;
    }
    eventBus.trigger("linkAccount:manualAccountContinue", continueToUrl);
  },
  learnAboutOurSecurityClick: function () {
    Mixpanel.trackEvent("Click Learn About Our Security Link", {
      component: LINK_ACCOUNT_SCREEN_COMPONENT,
      is_first_use: this.isFirstUseScreen,
    });
  },
  preventDefault: function (e) {
    e.preventDefault();
  },
  /*
   * Iterate through search results to build a map of popular and regular search results
   * To begin with we are iterating only through top 3 results for this test
   * If the test proves successful, this logic will be moved to server.
   */
  processSearchResults: function (searchResults) {
    // eslint-disable-next-line sonarjs/prefer-object-literal
    var sites = {},
      highlightTopSites =
        (abFrameworkProperties.linkAccount &&
          abFrameworkProperties.linkAccount.highlightTopSites) ||
        false;

    if (highlightTopSites) {
      // iterate through first three results to determine if their popularity is greater than 1M
      var firstResults = searchResults.slice(0, 3);
      var popular = _.filter(firstResults, function (site) {
        return site.popularity > 1000000;
      });
      var popularIds = _.map(popular, function (site) {
        return site.siteId;
      });
      // remove the popular search results from the main search results
      var regular = _.filter(searchResults, function (site) {
        return !_.contains(popularIds, site.siteId);
      });

      sites.popular = popular;
      sites.regular = regular;
    } else {
      sites.popular = [];
      sites.regular = searchResults;
    }
    sites.found = sites.popular.length > 0 || sites.regular.length > 0;
    return sites;
  },

  renderScheduleAppointmentModal: function () {
    if (
      window.sessionStorage.getItem("userIsAQ") === "1" &&
      window.sessionStorage.getItem("isEligibleForAppointment") === "1"
    ) {
      Mixpanel.updateProfile(
        { TestGroupID: "Enable_Appointment_Scheduler_After_Aggregation" },
        null,
        true
      );
      Mixpanel.trackEvent("Enable_Appointment_Scheduler_After_Aggregation");
      window.sessionStorage.setItem(
        "madLibsQQInterjection",
        JSON.stringify(SCHEDULE_APPOINTMENT_CONTENT)
      );
      setTimeout(
        () =>
          window.AppRouter.appointment({
            isFirstUseAppointment: true,
            isOnboardingAQ: true,
          }),
        500
      );
      Mixpanel.trackEvent("Display appointment modal post aggregation", {
        component: LINK_ACCOUNT_SCREEN_COMPONENT,
        is_first_use: this.isFirstUseScreen,
      });
      window.sessionStorage.removeItem(
        "enableAppointmentSchedulerAfterAggregation"
      );
    }
    clearSessionStorage();
    window.sessionStorage.removeItem("userIsAQ");
    window.sessionStorage.removeItem("isEligibleForAppointment");
  },
});
export default AddAccountView2;
