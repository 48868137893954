/**
 *  Matches `wide` breakpoint in `scss/framework/framework.scss`
 */
export const MQ_BREAKPOINT_WIDE = 1248;

export const SPACING_UNIT = 24;
export const SPACING_UNIT_SMALL = SPACING_UNIT / 2;
export const SPACING_UNIT_TINY = SPACING_UNIT_SMALL / 2;
export const SPACING_UNIT_LARGE = SPACING_UNIT * 2;
export const SPACING_UNIT_HUGE = SPACING_UNIT_LARGE * 2;
