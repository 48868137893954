export function mapByUserAccountId(personAccounts = []) {
  return new Map(personAccounts.map((a) => [a.userAccountId, a]));
}

export function getAccountOwnerAge(accountId, personAccounts, household) {
  if (!accountId || !personAccounts || !household) {
    return;
  }

  const personAccount = personAccounts.get(accountId);
  if (personAccount) {
    const owner = household.find((p) => p.id === personAccount.personId);
    return owner.age;
  }
}
