import _ from "underscore";

/**
 * Removes the select options matching the values containing
 * in the array of `values` from the array of `options`.
 *
 * @param {Array} options the select options in the format of `[{label, value}]`
 * @param {Array} values  the array of values (primitives) to filter out
 * @returns {Array} the new array instance with the filtered out values.
 */
export function filterOutOptions(options, values) {
  return options.filter((option) => values.indexOf(option.value) === -1);
}

/**
 * Returns the default option value.
 * 1. If the list of available options consists of one item, return the item.
 * 2. If the current value is present in the list of the available options, return the current value.
 * 3. Otherwise, returns an empty string.
 *
 * @param {String} currentValue the current value
 * @param {Array} options the select options
 * @returns {String} the default value
 */
export function getDefaultValue(currentValue, options) {
  if (options && options.length === 1) {
    return options[0].value;
  }

  if (currentValue && _.find(options, (d) => d.value === currentValue)) {
    return currentValue;
  }

  return "";
}
