import Services from "services";

const markUserMessageAsDismissed = (id) => {
  Services.UserMessages.updateV2({
    userMessageIds: "[" + id + "]",
    actions: '["markDismissed"]',
  });
};

export default markUserMessageAsDismissed;
