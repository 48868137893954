/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "libs/pcap/table/Table";
import { formatCurrency, formatPercent } from "libs/pcap/utils/format";
import { m, LazyMotion, domAnimation } from "framer-motion";
import { noop } from "underscore";
import { formatGainLoss } from "../../utils";

// eslint-disable-next-line sonarjs/cognitive-complexity
function FundTransferBox({
  title,
  transferData,
  handleDelete,
  handleEdit,
  isCollapsable,
  includeGainLoss,
  onLinkClick,
  isCash,
}) {
  const [isActive, setIsActive] = useState(true);

  const linkFormatter = (d) => (
    <a
      className="pc-label"
      href={d.investmentOverviewLink}
      target="_blank"
      rel="noreferrer"
      onClick={() => onLinkClick(d)}
    >
      {d.name}
    </a>
  );

  const sourceFund = transferData?.sourceFund ?? transferData?.from ?? [];
  const destinationFunds =
    transferData?.destinationFunds ?? transferData?.to ?? [];

  const name = sourceFund[0]?.legalName ?? "";
  const changePercent =
    sourceFund[0]?.changePercent ?? sourceFund[0]?.percentAllocation ?? 0;
  const balance = sourceFund[0]?.balance ?? 0;
  const investmentOverviewLink = sourceFund[0]?.investmentOverviewLink ?? "";
  const shortTermUnrealizedGainLoss =
    sourceFund[0]?.shortTermUnrealizedGainLoss ?? "";
  const longTermUnrealizedGainLoss =
    sourceFund[0]?.longTermUnrealizedGainLoss ?? "";
  const totalUnrealizedGainLoss = sourceFund[0]?.totalUnrealizedGainLoss ?? "";
  const totalGainLossPercentage = sourceFund[0]?.totalGainLossPercentage ?? "";

  const transferSourceData = [
    {
      name,
      changePercent,
      balance,
      investmentOverviewLink,
      shortTermUnrealizedGainLoss,
      longTermUnrealizedGainLoss,
      totalUnrealizedGainLoss,
      totalGainLossPercentage,
    },
  ];
  const transferDestinationData = destinationFunds?.map((f) => {
    return {
      name: f.legalName ?? "",
      allocationPercentage: f.allocationPercent ?? f.percentAllocation ?? 0,
      investmentOverviewLink: f.investmentOverviewLink ?? "",
      shortTermUnrealizedGainLoss: f.shortTermUnrealizedGainLoss ?? "",
      longTermUnrealizedGainLoss: f.longTermUnrealizedGainLoss ?? "",
    };
  });

  const iconToShow = isActive ? "#icon__minus-box" : "#icon__plus-box";

  const formatCurrentAllocation = (d) => {
    return isCash
      ? formatCurrency(d, 2, false)
      : formatPercent(d, { precision: 2 });
  };

  let SOURCE_TABLE_COLUMNS = [
    {
      id: "INVESTMENT",
      header: "INVESTMENT",
      accessor: (d) => d,
      formatter: linkFormatter,
      className: "table__column--investment",
      style: {
        width: includeGainLoss ? "20%" : "40%",
      },
    },
    {
      id: "UNREALIZED_SHORT_TERM_GAIN_LOSS",
      header: "UNREALIZED SHORT-TERM GAIN/LOSS",
      accessor: (d) => d.shortTermUnrealizedGainLoss,
      formatter: formatGainLoss,
      style: {
        width: "20%",
        fontSize: "15px",
      },
      className: "table__column--right pc-u-pr0",
    },
    {
      id: "UNREALIZED_LONG_TERM_GAIN_LOSS",
      header: "UNREALIZED LONG-TERM GAIN/LOSS",
      accessor: (d) => d.longTermUnrealizedGainLoss,
      formatter: formatGainLoss,
      style: {
        width: "20%",
        fontSize: "15px",
      },
      className: "table__column--right pc-u-pr0",
    },
    {
      id: "TOTAL_GAIN_LOSS",
      header: "TOTAL GAIN/LOSS",
      accessor: (d) => d,
      formatter: (d) => (
        <div style={{ display: "block" }}>
          {formatGainLoss(d.totalUnrealizedGainLoss)}
          {formatGainLoss(d.totalGainLossPercentage, true)}
        </div>
      ),
      style: {
        width: "14%",
        fontSize: "15px",
      },
      className: "table__column--right pc-u-pr0",
    },
    {
      id: "BALANCE",
      header: "BALANCE",
      accessor: (d) => d.balance,
      className: "table__column--right table__column--balance",
      formatter: formatCurrency,
      style: {
        width: includeGainLoss ? "13%" : "20%",
      },
    },
    {
      id: "CURRENT_ALLOCATION",
      header: "CURRENT ALLOCATION",
      accessor: (d) => d.changePercent,
      className: "table__column--right table__column--percentage",
      formatter: (d) => formatCurrentAllocation(d),
      style: {
        width: includeGainLoss ? "13%" : "40%",
      },
    },
  ];

  let DESTINATION_TABLE_COLUMNS = [
    {
      id: "INVESTMENT",
      header: "INVESTMENT",
      accessor: (d) => d,
      formatter: linkFormatter,
      className: "table__column--investment",
      style: {
        width: includeGainLoss ? "20%" : "40%",
      },
    },
    {
      id: "UNREALIZED_SHORT_TERM_GAIN_LOSS",
      header: "UNREALIZED SHORT-TERM GAIN/LOSS",
      accessor: (d) => d.shortTermUnrealizedGainLoss,
      formatter: formatGainLoss,
      style: {
        width: "20%",
        fontSize: "15px",
      },
      className: "table__column--right pc-u-pr0",
    },
    {
      id: "UNREALIZED_LONG_TERM_GAIN_LOSS",
      header: "UNREALIZED LONG-TERM GAIN/LOSS",
      accessor: (d) => d.longTermUnrealizedGainLoss,
      formatter: formatGainLoss,
      style: {
        width: "20%",
        fontSize: "15px",
      },
      className: "table__column--right pc-u-pr0",
    },
    {
      id: "NEW_ALLOCATION",
      header: "NEW ALLOCATION",
      accessor: (d) => d.allocationPercentage,
      className: "table__column--right table__column--percentage",
      formatter: (d) => formatCurrentAllocation(d),
      style: {
        width: includeGainLoss ? "40%" : "60%",
      },
    },
  ];

  if (!includeGainLoss) {
    SOURCE_TABLE_COLUMNS.splice(1, 3);
    DESTINATION_TABLE_COLUMNS.splice(1, 2);
  }

  return (
    <LazyMotion features={domAnimation}>
      <div
        key={`${transferSourceData[0].name}-${transferSourceData[0].allocationPercentage}`}
        className="fund-transfer-box"
      >
        <div className="fund-transfer-box__heading">
          {isCollapsable && (
            <button
              className="pc-btn--link js-toggle-btn qa-toggle-btn"
              onClick={() => setIsActive((prev) => !prev)}
            >
              <svg
                className="fund-transfer-box__icon-header pc-u-mr-"
                viewBox="0 0 15 14"
                data-testid={iconToShow}
              >
                <use
                  xmlns="http://www.w3.org/1999/xlink"
                  xlinkHref={iconToShow}
                />
              </svg>
            </button>
          )}
          <span className="fund-transfer-box__header">{title}</span>
        </div>

        <m.div
          className="fund-transfer-box__mask"
          animate={{ height: isActive ? "auto" : 0 }}
        >
          {(handleEdit || handleDelete) && (
            <div className="fund-transfer-box__modify-container">
              {handleDelete && (
                <button
                  className="pc-btn--link delete-transfer-link js-delete-btn u-text-underline"
                  onClick={() =>
                    handleDelete(
                      transferSourceData[0].name,
                      transferSourceData[0].allocationPercentage
                    )
                  }
                >
                  Delete
                </button>
              )}
              {handleEdit && (
                <button
                  className="pc-btn--link edit-transfer-link js-edit-btn u-text-underline"
                  onClick={() =>
                    handleEdit(
                      transferSourceData[0].name,
                      transferSourceData[0].allocationPercentage
                    )
                  }
                >
                  Edit
                </button>
              )}
            </div>
          )}

          <div className="fund-transfer-box__content">
            <div className="pc-layout pc-u-pl-">
              <div>
                <span className="fund-transfer-box__sub-header">
                  Transfer From
                </span>
                <Table
                  columns={SOURCE_TABLE_COLUMNS}
                  data={transferSourceData}
                  className="fund-transfer-box__table pc-u-mt-"
                  rowClassName="fund-transfer-box__table-row"
                />
              </div>
              <div>
                <span className="fund-transfer-box__sub-header">
                  Transfer To
                </span>
                <Table
                  columns={DESTINATION_TABLE_COLUMNS}
                  data={transferDestinationData}
                  className="fund-transfer-box__table pc-u-mt-"
                  rowClassName="fund-transfer-box__table-row"
                />
              </div>
            </div>
          </div>
        </m.div>
      </div>
    </LazyMotion>
  );
}

FundTransferBox.propTypes = {
  title: PropTypes.string,
  transferData: PropTypes.shape({
    sourceFund: PropTypes.array,
    destinationFunds: PropTypes.arrayOf(PropTypes.object),
    from: PropTypes.array,
    to: PropTypes.array,
  }),
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  isCollapsable: PropTypes.bool,
  includeGainLoss: PropTypes.bool,
  onLinkClick: PropTypes.func,
  isCash: PropTypes.bool,
};

FundTransferBox.defaultProps = {
  title: "Item",
  transferData: {},
  handleDelete: undefined,
  handleEdit: undefined,
  isCollapsable: false,
  includeGainLoss: true,
  onLinkClick: noop,
  isCash: false,
};

export default FundTransferBox;
