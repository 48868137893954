import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { noop } from "underscore";

const DATE_FORMAT = "MMM DD, YYYY";
class NotificationItem extends React.Component {
  formatDate(date) {
    return moment(date).format(DATE_FORMAT).toUpperCase();
  }

  render() {
    return this.props.componentName === "NavBar Notifications"
      ? this.renderNavBarItem()
      : this.renderNotifCenterItem();
  }

  renderNavBarItem() {
    const { message, handleClick, componentName, handleActionLinkClick } =
      this.props;

    const sentenceCaseClassName = "u-sentence-case";

    return (
      <div
        className="notification-item"
        onClick={(e) => handleClick(e, message, componentName)}
      >
        <div className="notification-item__date">
          {this.formatDate(message.updatedTime)}
        </div>
        <div className="notification-item__content-container">
          <div className="notification-item__dot-container">
            <div className="notification-item__dot" />
          </div>
          <div className="notification-item__content">
            <div
              className={`notification-item__content-text ${sentenceCaseClassName}`}
            >
              {message.title.replace(/(<([^>]+)>)/gi, "")}
            </div>
            <div className="notification-item__content-links-container">
              {message.action &&
                message.action.map((el, ind) => (
                  <a
                    onClick={(e) => handleActionLinkClick(e, message, ind)}
                    className={`pc-btn--link js-notification-item__link ${sentenceCaseClassName}`}
                    key={ind}
                    href={el.url}
                  >
                    {el.title}
                  </a>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNotifCenterItem() {
    const { message, handleClick, componentName } = this.props;
    return (
      <div
        className="notification-item notification-item--notif-center"
        onClick={(e) => handleClick(e, message, componentName)}
      >
        <div
          className={`notification-item__content-container ${
            message.template
              ? "qa-" + message.template.toLowerCase().replace(/_+/g, "-")
              : ""
          }`}
        >
          <div className="notification-item__dot-container notification-item--notif-center__dot-container">
            <div
              className={`notification-item__dot ${
                message.read ? "notification-item__dot-read" : ""
              }`}
            />
          </div>
          <div className="notification-item__content">
            <div className="notification-item__date notification-item--notif-center__date qa-notification-date">
              {this.formatDate(message.updatedTime)}
            </div>
            <div
              className={
                "notification-item__content-text notification-item--notif-center__content-text qa-notification-title"
              }
            >
              {message.title.replace(/(<([^>]+)>)/gi, "")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NotificationItem.defaultProps = {
  handleActionLinkClick: noop,
  componentName: "",
};

NotificationItem.propTypes = {
  message: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleActionLinkClick: PropTypes.func,
  componentName: PropTypes.string,
};

export default NotificationItem;
