import PropTypes from "prop-types";
import React from "react";
import { trackClick, trackView } from "components/common/ComponentAnalytics";
import DisclaimerModal from "./DisclaimerModal";

const CURRENT_PAGE = 3;

export default class FormPage extends React.Component {
  constructor() {
    super(...arguments);
    this.state = { isDisclaimerOpen: false };
    this.onOpenDisclaimerClick = this.onOpenDisclaimerClick.bind(this);
    this.onCloseDisclaimerClick = this.onCloseDisclaimerClick.bind(this);
    this.onContinueClick = this.onContinueClick.bind(this);
  }

  componentDidMount() {
    const { componentName } = this.props;
    trackView("Purchase Plan Button Page", { componentName });
  }

  onOpenDisclaimerClick(event) {
    const { componentName } = this.props;
    event.preventDefault();
    this.setState({ isDisclaimerOpen: true });
    trackClick(event, { componentName });
  }

  onCloseDisclaimerClick() {
    this.setState({ isDisclaimerOpen: false });
  }

  onContinueClick(event) {
    const { componentName, onContinueClick } = this.props;
    trackClick(event, componentName);
    onContinueClick(CURRENT_PAGE);
  }

  render() {
    const { componentName } = this.props;
    const { isDisclaimerOpen } = this.state;
    return (
      <div className="fin-plan-offer__page fin-plan-offer__page-text-only">
        <h2 className="fin-plan-offer__title pc-u-mt++">
          For a one-time fee of{" "}
          <span className="fin-plan-offer__highlight">$499</span>, you can take
          control of your
          <br />
          finances and gain confidence about reaching your goals.
        </h2>
        <div className="pc-layout pc-layout--center pc-u-mt">
          <a href="" onClick={this.onOpenDisclaimerClick}>
            Terms and Conditions
          </a>
        </div>
        <div className="pc-layout pc-layout--center pc-u-mt+ pc-u-mb++">
          <button
            type="button"
            className="pc-btn pc-btn--primary"
            onClick={this.onContinueClick}
          >
            Purchase Your Personalized Plan
          </button>
        </div>
        <DisclaimerModal
          componentName={componentName}
          isOpen={isDisclaimerOpen}
          onClosed={this.onCloseDisclaimerClick}
        />
      </div>
    );
  }
}

FormPage.propTypes = {
  componentName: PropTypes.string,
  onContinueClick: PropTypes.func,
};
