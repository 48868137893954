import sortUserMessagesByRank from "libs/pcap/utils/sortUserMessagesByRank";

const FEATURE_HASH_TO_NAME_MAP = {
  "#/retirement-planner": "Retirement Planner",
  "#/investment-checkup": "Investment Checkup",
  "#/401k": "Retirement Fee Analyzer",
};
const SESSION_NAME_ACTIVE_INTERJECTION = "ACTIVE_INTERJECTION";
const SESSION_NAME_HANDLED_INTERJECTION_USER_MESSAGE_IDS =
  "HANDLED_INTERJECTION_USER_MESSAGE_IDS";

export const ACTIVE_INTERJECTION_NAMESPACES = {};

export const USER_MSG_TYPE = {
  APP_WIDE_BANNER: "APP_WIDE_BANNER",
  INTERJECTION: "INTERJECTION",
  USER_RESPONSE_BANNER: "USER_RESPONSE_BANNER",
};

export function getCurrentFeatureName(displayLocations = []) {
  const currentHash = window.location.hash;
  const featureHash = displayLocations.find((location) =>
    currentHash.includes(location)
  );
  return FEATURE_HASH_TO_NAME_MAP[featureHash] || "";
}

export function getNamespace(interjectionUserMessage) {
  // e.g. ACTIVE_INTERJECTION.INTERJECTION_RP_IC_FA_WEB.TrackAccountsOrPickATimeModal
  return interjectionUserMessage
    ? `.${interjectionUserMessage.template}.${
        interjectionUserMessage.viewTemplate || "BasicContentInterjectionModal"
      }`
    : "";
}

export function removeInterjectionFromActiveQueue(namespace) {
  delete ACTIVE_INTERJECTION_NAMESPACES[namespace];
  window.sessionStorage.removeItem(
    SESSION_NAME_ACTIVE_INTERJECTION + namespace
  );
}

export function getHandledInterjectionUserMessageIds() {
  return JSON.parse(
    window.sessionStorage.getItem(
      SESSION_NAME_HANDLED_INTERJECTION_USER_MESSAGE_IDS
    ) || "[]"
  );
}

export function addInterjectionToActiveQueue(interjectionUserMessage) {
  const namespace = getNamespace(interjectionUserMessage);
  const isHandled = getHandledInterjectionUserMessageIds().includes(
    interjectionUserMessage.userMessageId
  );
  if (isHandled && interjectionUserMessage.lastViewedTime) {
    removeInterjectionFromActiveQueue(namespace);
  } else {
    ACTIVE_INTERJECTION_NAMESPACES[namespace] = 1;
    window.sessionStorage.setItem(
      SESSION_NAME_ACTIVE_INTERJECTION + namespace,
      JSON.stringify(interjectionUserMessage)
    );
  }
}

export function getSortedActiveInterjections() {
  const activeInterjections = [];
  Object.keys(ACTIVE_INTERJECTION_NAMESPACES).forEach((namespace) => {
    const activeInterjectionStr = window.sessionStorage.getItem(
      SESSION_NAME_ACTIVE_INTERJECTION + namespace
    );
    if (activeInterjectionStr) {
      activeInterjections.push(JSON.parse(activeInterjectionStr));
    }
  });

  return activeInterjections.length
    ? sortUserMessagesByRank(activeInterjections)
    : activeInterjections;
}

export function addHandledInterjectionUserMessageId(interjectionUserMessageId) {
  const handledInterjectionUserMessageIds = getHandledInterjectionUserMessageIds();
  if (handledInterjectionUserMessageIds.includes(interjectionUserMessageId)) {
    return;
  }
  handledInterjectionUserMessageIds.push(interjectionUserMessageId);
  window.sessionStorage.setItem(
    SESSION_NAME_HANDLED_INTERJECTION_USER_MESSAGE_IDS,
    JSON.stringify(handledInterjectionUserMessageIds)
  );
}

// These names were kept as 'interstitial' to match ENUM values on server.
export const USER_EVENT = {
  INTERJECTION_TRACK_ACCOUNTS_CLICK: "INTERSTITIAL_TRACK_ACCOUNTS_CLICK",
  INTERJECTION_CLOSE_MODAL_CLICK: "INTERSTITIAL_CLOSE_MODAL_CLICK",
};

export const ACTION_KEY = {
  TRACK_ACCOUNTS: "track-accounts",
  PICK_A_TIME: "pick-a-time",
};

export function isAppointmentAction(actionKey) {
  return actionKey === ACTION_KEY.PICK_A_TIME;
}

export function isInterjectionUserMessage(msg) {
  return msg.category === USER_MSG_TYPE.INTERJECTION;
}

export function isUserResponseBannerUserMessage(msg) {
  return msg.category === "BANNER" && msg.viewTemplate === "USER_RESPONSE_PLA";
}
