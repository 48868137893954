import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import ReopenAccountView from "./ReopenAccountView";
import { promisify } from "utils/service";

export default class ReopenAccountContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.handleOnReopen = this.handleOnReopen.bind(this);
    this.reopenAccountService = promisify(Services.Accounts.reopen);
  }

  handleOnReopen() {
    this.setState({ loading: true });
    this.reopenAccountService({
      accountId: this.props.model.accountId,
    })
      .then(() => {
        this.setState(
          {
            loading: false,
            errors: undefined,
          },
          this.props.onReopened
        );
      })
      .catch((errors) => {
        this.setState({
          loading: false,
          errors,
        });
      });
  }

  render() {
    const { model, onCancel } = this.props;

    return (
      <ReopenAccountView
        model={model}
        onCancel={onCancel}
        errors={this.state.errors}
        loading={this.state.loading}
        onReopen={this.handleOnReopen}
      />
    );
  }
}

ReopenAccountContainer.propTypes = {
  model: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReopened: PropTypes.func.isRequired,
};
