import { STATE_BLOCKED, STATE_VERIFIED } from "utils/account";

export const isValidAccount = (account) => {
  return Object.values(account.stateForTransfer).some(
    ({ state }) => state !== STATE_BLOCKED
  );
};

export const pcapAccountsFilter = (account) => {
  return isValidAccount(account) && (account.isOnUsBank || account.isOnUs);
};

export const externalAccountsFilter = (account) => {
  return (
    isValidAccount(account) &&
    (account.productType === "INVESTMENT" || account.productType === "BANK") &&
    !account.isOnUsBank &&
    !account.isOnUs
  );
};

export const externalAndCashAccountsFilter = (
  account,
  cashAccountIdToClose
) => {
  return (
    isValidAccount(account) &&
    (account.productType === "INVESTMENT" || account.productType === "BANK") &&
    !account.isOnUs &&
    cashAccountIdToClose !== account?.userAccountId
  );
};

export const externalAccountsForPremierWithdrawalsFilter = (account) => {
  return (
    isValidAccount(account) &&
    account.productType === "BANK" &&
    !account.isOnUsBank &&
    !account.isOnUs
  );
};

export const externalAccountForCloseAccountFilter = (account) => {
  return (
    account.stateForTransfer?.PCB?.state === STATE_VERIFIED &&
    account.productType === "BANK" &&
    !account.isOnUsBank &&
    !account.isOnUs &&
    !account.isManual
  );
};
