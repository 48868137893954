import PropTypes from "prop-types";
import React from "react";
import mixpanel from "mixpanel";
import ChartReact from "libs/pcap/chart/chart--react";
import { formatNumber } from "libs/pcap/utils/format";
import { select, selectAll, scaleBand } from "d3";
import withTooltips from "components/higherOrderComponents/withTooltips";
import _ from "underscore";
import { annotation } from "d3-svg-annotation";
import { basicLabel } from "common/annotationTypes";
import { getActionButtons } from "components/opportunitiesSummary/InsightsUtils";
import { getEventData } from "components/opportunitiesSummary/InsightsUtils";

const SMALL_MARGIN = 6;
const LABEL_Y_OFFSET = 13;

function xAccessor(item) {
  return item.accountName;
}

function yAccessor(item) {
  return item.accountName;
}

class RetirementFeeAnalyzer extends React.Component {
  constructor(props) {
    super(props);

    const baseInvestment = _.first(
      this.props.message.resources[0].jsonContent.chartData[0].data
    ).y;
    const pcapEarnings =
      _.last(this.props.message.resources[0].jsonContent.chartData[0].data).y -
      baseInvestment;
    const pcapLabel =
      this.props.message.resources[0].jsonContent.chartData[0].description;
    const otherEarnings =
      _.last(this.props.message.resources[0].jsonContent.chartData[1].data).y -
      baseInvestment;
    const otherLabel =
      this.props.message.resources[0].jsonContent.chartData[1].description;
    const total = _.last(
      this.props.message.resources[0].jsonContent.chartData[0].data
    ).y;
    const baseInvestmentVisual =
      baseInvestment >= total * 0.25 ? baseInvestment : total * 0.25; // eslint-disable-line no-magic-numbers
    const otherEarningsVisual =
      otherEarnings >= total * 0.3 ? otherEarnings : total * 0.3; // eslint-disable-line no-magic-numbers
    const feesLost = pcapEarnings - otherEarningsVisual;

    this.state = {
      otherAccountsLabel: otherLabel,
      stackedBarData: [
        {
          accountName: pcapLabel,
          investment: baseInvestment,
          investmentVisual: baseInvestmentVisual,
          earningsNetFees: pcapEarnings,
          earningsNetFeesVisual: pcapEarnings,
          lostToFees: 0,
        },
        {
          accountName: otherLabel,
          investment: baseInvestment,
          investmentVisual: baseInvestmentVisual,
          earningsNetFees: otherEarnings,
          earningsNetFeesVisual: otherEarningsVisual,
          lostToFees: feesLost,
        },
      ],
    };
  }

  componentDidMount() {
    const { storeTooltipRef, markAsViewed, markAsImpressed } = this.props;

    this.formatGraph();
    this.renderAnnotations();

    storeTooltipRef(document.getElementById("feesDisclaimer"), {
      container: "body",
      placement: `${this.props.isLarge ? "bottom" : "top"}`,
    });
    storeTooltipRef(document.getElementById("combinedFunds"), {
      container: "body",
      placement: "top",
    });

    mixpanel.trackEvent(
      `View Insight`,
      getEventData({ message: this.props.message })
    );

    if (this.props.isFirstRendered) {
      markAsImpressed(this.props.message.userMessageId);
    } else {
      markAsViewed(this.props.message.userMessageId);
    }
  }

  formatGraph() {
    const barchart = this.chartReact.chart;
    const { disableAnimations } = this.props;

    select(".insights-graph--fee-analyzer")
      .append("defs")
      .append("pattern")
      .attr("id", "crossStitch")
      .attr("width", "5")
      .attr("height", "5")
      .attr("patternUnits", "userSpaceOnUse")
      .attr("patternTransform", "rotate(120)")
      .append("rect")
      .attr("width", "1")
      .attr("height", "5")
      .attr("transform", "translate(0,0)")
      .attr("fill", `#db291f`);

    const lostToFees = select(
      select(".chart__stack--bar-lostToFees").node().lastChild
    );
    lostToFees.style("fill", "url(#crossStitch)");

    selectAll(
      ".insight__chart-container--fee-analyzer .chart__axis-value"
    ).attr("dy", LABEL_Y_OFFSET);
    selectAll(
      ".insight__chart-container--fee-analyzer .chart__stack--bar .chart__bar"
    ).each(function (rect) {
      if (
        !this.parentNode
          .getAttribute("class")
          .includes("chart__stack--bar-lostToFees")
      ) {
        // eslint-disable-line no-invalid-this
        const isEarnings = this.parentNode
          .getAttribute("class")
          .includes("chart__stack--bar-earningsNetFeesVisual"); // eslint-disable-line no-invalid-this
        const graph = select(".insights-graph--fee-analyzer");
        const labelOffset = isEarnings ? -3 : 3; // eslint-disable-line no-magic-numbers
        const xOffset = Math.round(this.getAttribute("width")) / 2; // eslint-disable-line no-invalid-this
        const currentX = Math.round(this.getAttribute("x")) + xOffset; // eslint-disable-line no-invalid-this
        const label = graph
          .append("text")
          .classed(
            `insights-chart-label ${
              disableAnimations ? "insights-chart-label--no-animation" : ""
            }`,
            true
          )
          .attr(
            "y",
            barchart.options.yScale((rect[1] + rect[0]) / 2) + labelOffset
          )
          .attr("x", currentX);
        const labelValues = isEarnings
          ? [
              "Earnings",
              `Net fees`,
              "$" + formatNumber(rect.data.earningsNetFees, 0),
            ]
          : ["Investment", "$" + formatNumber(rect.data.investment, 0)];
        labelValues.forEach((phrase, i) => {
          const yOffSet = i === 0 ? null : LABEL_Y_OFFSET;
          label
            .append("tspan")
            .attr("dy", yOffSet)
            .attr("x", currentX)
            .text(phrase);
        });
      }
    });
  }

  renderAnnotations() {
    const { isLarge, valueALeft, valueBLeft } = this.props;
    const chart = this.chartReact.chart;

    const xValueA = valueALeft || (isLarge ? 278 : 219); //eslint-disable-line no-magic-numbers
    const xValueB = valueBLeft || (isLarge ? 80 : 65); //eslint-disable-line no-magic-numbers

    const annotations = [
      {
        type: basicLabel,
        className: "js-cost-of-cash pc-annotation--middle",
        note: {
          title:
            this.state.otherAccountsLabel
              .toLocaleLowerCase()
              .charAt(0)
              .toUpperCase() +
            this.state.otherAccountsLabel.toLocaleLowerCase().slice(1),
          wrap: 80,
          padding: 0,
          dy: 10,
        },
        x: xValueA,
        y: chart.options.yScale(0) + SMALL_MARGIN,
        dy: 1,
      },
      {
        type: basicLabel,
        className: "pc-annotation pc-annotation--middle",
        note: {
          title: "Empower",
          wrap: 80,
          padding: 0,
        },
        dy: 1,
        x: xValueB,
        y: chart.options.yScale(0) + SMALL_MARGIN,
      },
    ];

    const makeAnnotations = annotation().annotations(annotations);

    select(".insights-graph--fee-analyzer .js-chart-body")
      .append("g")
      .attr("class", "annotation-group")
      .call(makeAnnotations);
    selectAll(".annotation-note-title").attr("font-size", "12");
    makeAnnotations.updateText();
  }

  render() {
    const { message = {}, markAsViewed, disableAnimations } = this.props;

    const chart = (
      <div className="insight__chart-container insight__chart-container--fee-analyzer">
        <ChartReact
          ref={(el) => {
            this.chartReact = el;
          }}
          className="insights-graph--fee-analyzer"
          data={[this.state.stackedBarData]}
          type="bar"
          stacks={["investmentVisual", "earningsNetFeesVisual", "lostToFees"]}
          interpolate="monotone"
          showXGrid={false}
          showYGrid={false}
          showYAxis={false}
          showXAxis={false}
          margin={{ bottom: 36 }}
          xScale={scaleBand().paddingInner(0.36).paddingOuter(0.1)} // eslint-disable-line no-magic-numbers
          x={xAccessor}
          y={yAccessor}
          disableAnimations={disableAnimations}
        />
      </div>
    );

    if (this.props.chartOnly) {
      return chart;
    }

    return (
      <div className="insight__message">
        <div
          className="insight__header qa-insight-header u-sentence-case"
          dangerouslySetInnerHTML={{ __html: message.title }}
        />
        <div className="insight__summary">
          <div
            className="insight__summary"
            dangerouslySetInnerHTML={{ __html: message.summary }}
          />
          {chart}
        </div>
        <div className="insight__buttons">
          {getActionButtons({
            message,
            markAsViewed,
            appointmentReason: "Fee Analyzer",
          })}
        </div>
      </div>
    );
  }
}

RetirementFeeAnalyzer.defaultTypes = {
  chartOnly: false,
  disableAnimations: false,
  valueALeft: undefined,
  valueBLeft: undefined,
};

RetirementFeeAnalyzer.propTypes = {
  chartOnly: PropTypes.bool,
  isLarge: PropTypes.bool,
  message: PropTypes.object,
  markAsViewed: PropTypes.func,
  markAsImpressed: PropTypes.func,
  isFirstRendered: PropTypes.bool,
  disableAnimations: PropTypes.bool,
  storeTooltipRef: PropTypes.func, // Coming from withToolTip factory
  valueALeft: PropTypes.number,
  valueBLeft: PropTypes.number,
};

export default withTooltips(RetirementFeeAnalyzer);
