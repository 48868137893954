import React, { useState, useEffect } from "react";
import ReferralCenter from "./ReferralCenter";
import PropTypes from "prop-types";
import { formatCurrency } from "libs/pcap/utils/format";
import deepCopy from "deep-copy";

const MESSAGE_REFERRAL_CENTER_NOT_AVAILABLE =
  "Referral Center is not available";
const DEFAULT_REFERRAL_AMOUNT = 20;
const CLIENT_REFERRAL_AMOUNT = 50;
const termsUrl = "https://www.empower.com/hpc/terms-of-use";
const ReferralCenterContainer = ({
  referralUrl,
  isNotAvailable,
  funnelAttrs,
  referralCenterAbFrmwrk,
}) => {
  const [rewardAmount, setRewardAmount] = useState(DEFAULT_REFERRAL_AMOUNT);

  const getCopy = (rewardAmount, referralCenterAbFrmwrk, isClient) => {
    const organizationName = EMPOWER_PERSONAL_DASHBOARD;
    let friendName = "Your friend";
    if (window.usernameFirst && window.usernameLast) {
      friendName = `${window.usernameFirst} ${window.usernameLast}`;
    } else if (window.usernameFirst && !window.usernameLast) {
      friendName = `Your friend, ${window.usernameFirst}`;
    } else if (!window.usernameFirst && window.usernameLast) {
      friendName = `Your friend, ${window.usernameLast}`;
    }
    const defaultCopy = {
      headline: `Share the ${organizationName} and you'll get each ${formatCurrency(
        rewardAmount,
        0
      )}.<sup>1</sup>`,
      description: "",
      footnote:
        "Or use your personal share link:&nbsp;<span>$REFERRAL_URL</span>",
      disclaimer: `${formatCurrency(
        rewardAmount,
        0
      )} offer only for new users who link a qualified account. Six month offer for new balances of $250,000 or more. Two month offer for new balances of $100,000 or more.`,
      disclaimerLinkText: "See terms",
      disclaimerLinkUrl: termsUrl,
      emailBody: `I use ${organizationName} to get a clear picture of my financial life from my net worth to my spending and saving habits. Their easy-to-use tools have helped me gain confidence by knowing the full scope of my finances.`,
      emailSubject: `${friendName} wants to share a suite of free financial tools with you, ${organizationName}`,
      contentToCopy: `$REFERRAL_URL`,
    };

    const clientOverrides = {
      headline:
        "Help a Friend and Get up to 6 Months of Free Wealth Management",
      description: `You’ll each also get ${formatCurrency(
        rewardAmount,
        0
      )} if they use our free tools.`,
    };

    const data = isClient
      ? { ...defaultCopy, ...clientOverrides }
      : defaultCopy;

    if (referralCenterAbFrmwrk.customize && !isClient) {
      return { ...data, ...referralCenterAbFrmwrk.user };
    }
    if (referralCenterAbFrmwrk.customize && isClient) {
      return { ...data, ...referralCenterAbFrmwrk.client };
    }
    return data;
  };

  const [copyData, setCopyData] = useState(
    getCopy(rewardAmount, referralCenterAbFrmwrk)
  );

  useEffect(() => {
    let data = deepCopy(copyData);
    if (funnelAttrs.isClient) {
      setRewardAmount(CLIENT_REFERRAL_AMOUNT);
      data = getCopy(CLIENT_REFERRAL_AMOUNT, referralCenterAbFrmwrk, true);
    }
    const footnote = data.footnote.replace("$REFERRAL_URL", referralUrl);
    const contentToCopy = data.contentToCopy.replace(
      "$REFERRAL_URL",
      referralUrl
    );
    data.footnote = footnote;
    data.contentToCopy = contentToCopy;
    setCopyData(data);
  }, [funnelAttrs, referralCenterAbFrmwrk]);

  if (isNotAvailable) {
    return <div>{MESSAGE_REFERRAL_CENTER_NOT_AVAILABLE}</div>;
  }

  return <ReferralCenter copyData={copyData} />;
};

ReferralCenterContainer.propTypes = {
  referralUrl: PropTypes.string,
  isNotAvailable: PropTypes.bool,
  funnelAttrs: PropTypes.object.isRequired,
  referralCenterAbFrmwrk: PropTypes.object.isRequired,
};

ReferralCenterContainer.defaultProps = {
  referralUrl: undefined,
  isNotAvailable: false,
};

export default ReferralCenterContainer;
