import moment from "moment";
import { isJointAccount, isOwnerShareSupported } from "utils/account";
import { API_FORMAT } from "libs/pcap/utils/date2";

export function stakeholdersToServer(account) {
  const stakeholders = Object.assign({}, account.stakeholders);

  // Default percentage for "regular" accounts to 100. At most 1 primary stakeholder and 1 secondary
  // stakeholder should exist (each)
  const jointAccountType =
    account.additionalAttributes &&
    account.additionalAttributes.jointAccountType;
  if (
    !isOwnerShareSupported(account.accountTypeGroup, jointAccountType) &&
    stakeholders.PRIMARY
  ) {
    if (stakeholders.PRIMARY.length > 1) {
      stakeholders.PRIMARY = stakeholders.PRIMARY.slice(0, 1);
    }
    if (stakeholders.PRIMARY[0].personId) {
      stakeholders.PRIMARY[0].percentage = 100;
    }
  }

  if (
    !isOwnerShareSupported(account.accountTypeGroup, jointAccountType) &&
    stakeholders.SECONDARY
  ) {
    if (stakeholders.SECONDARY.length > 1) {
      stakeholders.SECONDARY = stakeholders.SECONDARY.slice(0, 1);
    }
    if (stakeholders.SECONDARY[0].personId) {
      stakeholders.SECONDARY[0].percentage = 100;
    }
  }

  const otherRoles = ["custodian", "guardian", "executor"];
  otherRoles.forEach((role) => {
    if (account[role]) {
      stakeholders[role.toUpperCase()] = [
        {
          personId: account[role],
          role: role.toUpperCase(),
        },
      ];
    }
  });

  return stakeholders;
}

/**
 * Takes a plain account object and converts to API request format for updateAccount.
 *
 * @param {Object} account the account object
 * @return {Object} the request object
 */
export function updateAccountToServer(account) {
  const attrs = Object.assign({}, account);

  delete attrs.stakeholders;
  delete attrs.ownerPersonId;

  // some cleanup
  if (attrs.additionalAttributes && !isJointAccount(attrs.ownershipType)) {
    delete attrs.additionalAttributes.jointAccountType;
  }

  attrs.additionalAttributes = JSON.stringify(attrs.additionalAttributes);
  // fake request source to make API work with the new account type properties
  attrs.requestSource = window.isAdvisorApp ? "ADVISOR" : "USER";

  if (attrs.isEsog) {
    attrs.trackVestedOptions = !attrs.shouldOnlyTrackUnvestedShares;

    if (attrs.shouldStopVesting) {
      attrs.stopVestingDate = attrs.stopVestingDate
        ? attrs.stopVestingDate
        : moment().format(API_FORMAT);
    } else {
      attrs.stopVestingDate = null;
    }
  }

  return attrs;
}

/**
 * Takes a plain account object and converts to API request format for saveAccount.
 *
 * @param {Object} account the account object
 * @return {Object} the request object
 */
export function createAccountToServer(account) {
  let accountToServer = Object.assign({}, account);
  accountToServer.credentials = JSON.stringify({
    description: account.description,
    term: account.term,
    faceValue: account.faceValue,
    effectiveDate: account.effectiveDate,
    expirationDate: account.expirationDate,
    cashValue: account.cashValue,
    insuranceType: account.accountTypeSubtype,
  });

  delete accountToServer.description;
  delete accountToServer.term;
  delete accountToServer.faceValue;
  delete accountToServer.effectiveDate;
  delete accountToServer.expirationDate;
  delete accountToServer.cashValue;
  delete accountToServer.accountTypeSubtype;
  delete accountToServer.primaryOwner;

  return accountToServer;
}
