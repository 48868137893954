import React from "react";
import Input from "components/common/form/Input";
import FancySelect from "components/common/form/FancySelect";
import NameSuffixes from "enums/nameSuffixes";
import PropTypes from "prop-types";
import { noop } from "underscore";
import { FIRST_NAME } from "utils/patterns";

export default function LegalNameInput({
  refFunc,
  name,
  value,
  onChange,
  validator,
  className,
  helpBlock,
  disabled,
  inputColAdjustment,
  menuPortalTarget,
  labelFirstName,
  labelMiddleName,
  labelLastName,
  middleName,
}) {
  const isEmpty = (str) => str?.length === 0;
  const enableIfEmpty = (val) => (isEmpty(val) ? false : disabled);

  const isFirstNameDisabled = enableIfEmpty(value.firstName);
  const isLastNameDisabled = enableIfEmpty(value.lastName);

  return (
    <div className={`pc-layout pc-layout--small ${className}`}>
      <div className="pc-layout__item pc-u-1/2">
        <div className="pc-layout pc-layout--small">
          <div
            className={`pc-layout__item pc-u-${
              middleName ? inputColAdjustment?.firstName || "4/6" : "1"
            }`}
          >
            <label className="pc-label" htmlFor="firstName">
              {labelFirstName}
            </label>
            <Input
              ref={refFunc}
              type="text"
              id="firstName"
              className="qa-first-name"
              sizeVariation="full"
              name={`${name}.firstName`}
              value={value.firstName}
              onChange={onChange}
              validator={validator.properties.firstName}
              maxLength={19}
              disabled={isFirstNameDisabled}
              data-hj-masked
              ariaLabel={labelFirstName}
              ariaRequired={
                validator.properties.firstName &&
                validator.properties.firstName.allowEmpty === false
              }
            />
            {helpBlock && (
              <div className="pc-help-block pc-help-block--small">
                {helpBlock}
              </div>
            )}
          </div>
          {middleName && (
            <div
              className={`pc-layout__item pc-u-${
                inputColAdjustment?.middleName || "2/6"
              }`}
            >
              <label className="pc-label u-preserve-case" htmlFor="middleName">
                {labelMiddleName}
              </label>
              <Input
                ref={refFunc}
                id="middleName"
                type="text"
                sizeVariation="full"
                name={`${name}.middleName`}
                value={value.middleName}
                onChange={onChange}
                validator={validator.properties.middleName}
                maxLength={15}
                disabled={disabled}
                data-hj-masked
                ariaLabel={labelMiddleName}
              />
            </div>
          )}
        </div>
      </div>
      <div className="pc-layout__item pc-u-1/2">
        <div className="pc-layout pc-layout--small">
          <div
            className={`pc-layout__item pc-u-${
              inputColAdjustment?.lastName || "4/6"
            }`}
          >
            <label className="pc-label" htmlFor="lastName">
              {labelLastName}
            </label>
            <Input
              ref={refFunc}
              type="text"
              id="lastName"
              className="qa-last-name"
              sizeVariation="full"
              name={`${name}.lastName`}
              value={value.lastName}
              onChange={onChange}
              validator={validator.properties.lastName}
              maxLength={26}
              disabled={isLastNameDisabled}
              data-hj-masked
              ariaLabel={labelLastName}
              ariaRequired={
                validator.properties.lastName &&
                validator.properties.lastName.allowEmpty === false
              }
            />
          </div>
          <div
            className={`pc-layout__item pc-u-${
              inputColAdjustment?.suffix || "2/6"
            }`}
          >
            <label
              className="pc-label"
              id="ariaSuffixLabel"
              htmlFor="ariaSuffixInput"
            >
              Suffix
            </label>
            <FancySelect
              ref={refFunc}
              id="suffix"
              options={NameSuffixes}
              sizeVariation="full"
              name={`${name}.suffix`}
              value={value.suffix}
              onChange={onChange}
              placeholder=""
              isDisabled={disabled}
              data-hj-masked
              menuPortalTarget={menuPortalTarget}
              isSearchable={false}
              inputId="ariaSuffixLabel"
              selectInputId="ariaSuffixInput"
              ariaLabel="Suffix"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

LegalNameInput.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string.isRequired,
  refFunc: PropTypes.func,
  onChange: PropTypes.func,
  validator: PropTypes.object,
  className: PropTypes.string,
  helpBlock: PropTypes.string,
  disabled: PropTypes.bool,
  inputColAdjustment: PropTypes.object,
  menuPortalTarget: PropTypes.node,
  labelFirstName: PropTypes.string,
  labelMiddleName: PropTypes.string,
  labelLastName: PropTypes.string,
  middleName: PropTypes.bool,
};

LegalNameInput.defaultProps = {
  value: {},
  refFunc: noop,
  onChange: noop,
  className: "",
  helpBlock: "As it appears on government ID",
  validator: {
    type: "object",
    properties: {
      firstName: {
        type: "string",
        allowEmpty: false,
        messages: {
          pattern: "Name may not include special characters or numbers.",
        },
        pattern: FIRST_NAME.source,
        maxLength: 19,
      },
      middleName: { type: "string", maxLength: 15 },
      lastName: {
        type: "string",
        allowEmpty: false,
        messages: {
          pattern: "Name may not include special characters or numbers.",
        },
        pattern: FIRST_NAME.source,
        maxLength: 26,
      },
    },
  },
  disabled: undefined,
  inputColAdjustment: {},
  menuPortalTarget: undefined,
  labelFirstName: "First name",
  labelMiddleName: "M.I.",
  labelLastName: "Last name",
  middleName: true,
};
