var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h2 class=\"add-fi-success__title\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"successMessage") : depth0), depth0))
    + "</h2>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <h1 class=\"add-fi-success__title\">Congratulations!</h1>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"add-fi-success__stars js-add-fi-success-stars\">\n      <span class=\"add-fi-success__star "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",2,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":40},"end":{"line":19,"column":51}}})) != null ? stack1 : "")
    + "\"></span\n      >\n      <span class=\"add-fi-success__star "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",3,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":40},"end":{"line":22,"column":51}}})) != null ? stack1 : "")
    + "\"></span\n      >\n      <span class=\"add-fi-success__star "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",4,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":40},"end":{"line":25,"column":51}}})) != null ? stack1 : "")
    + "\"></span\n      >\n      <span class=\"add-fi-success__star "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",5,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":40},"end":{"line":28,"column":51}}})) != null ? stack1 : "")
    + "\"></span\n      >\n      <span class=\"add-fi-success__star "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",6,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":40},"end":{"line":31,"column":51}}})) != null ? stack1 : "")
    + "\"></span\n      >\n      <span class=\"add-fi-success__star add-fi-success__star--last\"></span>\n      <span\n        class=\"add-fi-success__star add-fi-success__star--on add-fi-success__star--animate js-animated-star\"\n      ></span>\n    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "add-fi-success__star--on";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"add-fi-success__stars js-add-fi-success-stars\">\n      <span class=\"add-fi-success__star "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",1,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":40},"end":{"line":41,"column":51}}})) != null ? stack1 : "")
    + "\"></span\n      >\n      <span class=\"add-fi-success__star "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",2,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":40},"end":{"line":44,"column":51}}})) != null ? stack1 : "")
    + "\"></span\n      >\n      <span class=\"add-fi-success__star "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",3,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":40},"end":{"line":47,"column":51}}})) != null ? stack1 : "")
    + "\"></span\n      >\n      <span class=\"add-fi-success__star "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",4,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":40},"end":{"line":50,"column":51}}})) != null ? stack1 : "")
    + "\"></span\n      >\n      <span class=\"add-fi-success__star "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",5,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":40},"end":{"line":53,"column":51}}})) != null ? stack1 : "")
    + "\"></span\n      >\n      <span class=\"add-fi-success__star add-fi-success__star--last "
    + ((stack1 = __default(require("../../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountCount") : depth0),"\n        >=",6,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":67},"end":{"line":56,"column":51}}})) != null ? stack1 : "")
    + " \"></span\n      >\n    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "<br/>";
},"14":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"buttonContainer\">\n    <a\n      class=\"pc-btn pc-btn--primary js-add-fi-success-run-investment-checkup\"\n      href=\"#/investment-checkup/allocation/\"\n      >Run Investment Checkup</a\n    >\n  </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"add-fi-success__done-linking add-fi-success__done-linking--rebranding\">\n  <a\n    href="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"dashboardUrl") : depth0), depth0))
    + "\n    class=\"linkAccountComplete qa-link-account-completed js-add-fi-success-go-to-dashboard "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":141,"column":91},"end":{"line":141,"column":152}}})) != null ? stack1 : "")
    + "\"\n    >Go to Dashboard</a\n  >\n</p>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "pc-btn ";
},"19":function(container,depth0,helpers,partials,data) {
    return "add-fi-success__action";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"add-fi-success__done-linking add-fi-success__done-linking--rebranding\">\n  <a\n  href=\"#\"\n  class=\"linkAccountComplete qa-link-account-completed js-add-fi-success-done-linking "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":149,"column":86},"end":{"line":149,"column":147}}})) != null ? stack1 : "")
    + "\"\n  >I’m done linking accounts</a\n  >\n</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class=\"trophy-state__container js-trophy-state"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTrophyState") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":48},"end":{"line":2,"column":93}}})) != null ? stack1 : "")
    + " trophy-state__container--rebranding\"\n>\n  <div class=\"add-fi-success__trophy add-fi-success__trophy--rebranding\">\n    <span>"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"accountCount") : depth0), depth0))
    + "</span>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSuccessMessage") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":11,"column":9}}})) != null ? stack1 : "")
    + "  <p class=\"add-fi-success__congrats-text\">\n    You just linked your <span>"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"nth") : depth0), depth0))
    + "</span> account. Average<br />\n    users like you link <span>6</span> accounts. Way to go!\n  </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"doAnimation") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":16,"column":2},"end":{"line":59,"column":9}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"js-run-ic-state"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRunIcState") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":27},"end":{"line":61,"column":71}}})) != null ? stack1 : "")
    + "\">\n  <p class=\"add-fi-success__congrats-text pc-u-mt+\">\n    If you've linked all of your accounts, "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":43},"end":{"line":63,"column":80}}})) != null ? stack1 : "")
    + "you are ready to go!\n  </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInvestmentCheckup") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":2},"end":{"line":73,"column":9}}})) != null ? stack1 : "")
    + "  <p class=\"add-fi-success__can-link-more-accounts\">\n    You can always link more accounts:\n  </p>\n</div>\n<div class=\"js-referral-state"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showReferralState") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":29},"end":{"line":78,"column":76}}})) != null ? stack1 : "")
    + "\">\n  <h2 class=\"referral-center__banner__title\">\n    Refer <span class=\"referral-center__banner__title-a\">a</span> Friend\n  </h2>\n  <div class=\"pc-layout referral-center__banner__content\">\n    <div\n      class=\"pc-layout__item pc-u-1/2 referral-center__banner--action-text referral-center__banner-left-half\"\n    >\n      For You\n      <div class=\"referral-center__banner__amount\">\n        &#36;"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"referralData") : depth0)) != null ? lookupProperty(stack1,"amount") : stack1), depth0))
    + "\n      </div>\n    </div>\n    <!--\n    -->\n    <div\n      class=\"pc-layout__item pc-u-1/2 referral-center__banner--action-text referral-center__banner-right-half\"\n    >\n      For Them\n      <div class=\"referral-center__banner__amount\">\n        &#36;"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"referralData") : depth0)) != null ? lookupProperty(stack1,"amount") : stack1), depth0))
    + "\n      </div>\n    </div>\n  </div>\n  <p\n    class=\"add-fi-success__congrats-text add-fi-success__congrats-text--refer pc-u-mt\"\n  >\n    Share the Empower Personal Dashboard™ and you'll each get &#36;"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"referralData") : depth0)) != null ? lookupProperty(stack1,"amount") : stack1), depth0))
    + ", on\n    us\n  </p>\n  <div class=\"buttonContainer\">\n    <a\n      class=\"pc-btn pc-btn--primary js-add-fi-success-refer-a-friend\"\n      href=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"referralData") : depth0)) != null ? lookupProperty(stack1,"link") : stack1), depth0))
    + "\"\n      >Refer Friends</a\n    >\n  </div>\n  <p class=\"add-fi-success__can-link-more-accounts\">\n    You can always link more accounts:\n  </p>\n</div>\n<div class=\"suggestions\">\n  <div\n    class=\"js-add-fi-success-popular-fis container\"\n    style=\"min-height: inherit;\"\n  >\n    "
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"popularFisHtml") : depth0), depth0)) != null ? stack1 : "")
    + "\n  </div>\n</div>\n<div class=\"tier1\" style=\"display:block;padding-top:0;\">\n  <div class=\"searchAccountsWrapper\">\n    <input\n      class=\"input input--full pc-u-pt- pc-u-pb- js-add-fi-success-search\"\n      type=\"text\"\n      placeholder=\"Enter another bank name or URL\"\n    />\n    <span class=\"reset js-add-fi-success-search-reset\">X</span>\n  </div>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showGoToDashboardLink") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":137,"column":0},"end":{"line":153,"column":7}}})) != null ? stack1 : "");
},"useData":true});