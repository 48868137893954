import Analytics from "analytics";
import ButtonGroup from "../../../components/common/buttonGroup/ButtonGroup";
import DateRangeSelector from "views/components/dateRangeSelectorView2";
import DateUtils from "libs/pcap/utils/date";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import mixpanel from "../../../libs/pcap/utils/mixpanel";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Services from "services";
import { getCsvFileName } from "utils/transactionsCsvFileNameUtils";
import TransactionsGridContainer from "../../../components/TransactionsGridV3/TransactionsGridContainer";
import {
  empowerActionSearchAccessor,
  formattedPriceSearchAccessor,
  formattedQuantitySearchAccessor,
  unformattedPriceSearchAccessor,
} from "../../../components/TransactionsGridV3/searchAccessors/searchAccessors";
import TransactionsGridController from "../../../components/TransactionsGridV3/TransactionsGridController/TransactionsGridController";
import empowerTransactionsColumns, {
  buildDataForExportToCsvEmpowerColumns,
} from "../../../components/TransactionsGridV3/columns/empowerTransactionsManager";
import { isEmpty, groupBy } from "underscore";
import RequestDetailContainer from "../../../components/TransactionsGridV3/RequestDetailContainer";
import eventBus from "eventBus";
import AppOverlay from "appOverlay";
import Message from "components/common/Message";
import { subscribify, promisify } from "utils/service";
import IframeClient from "partner/iframeClient";
import Account from "models/account";
import memoizeOne from "memoize-one";
import deepCopy from "deep-copy";
import { getGroupLabel } from "views/modules/sidebar/utils/empowerAccounts";
import { transactionsZeroStateData } from "./transactionsZeroStateData";

const iframeClient = IframeClient.getInstance();

const DEFAULT_PRODUCT_TYPE_FILTER = "ALL";

const setHideSplitTransaction = memoizeOne((transactions) => {
  transactions.map((t) => (t.hideSplitTransaction = true));
});
let PRODUCT_FILTER_OPTIONS = [
  { label: "All", value: "ALL" },
  { label: "Cash", value: "BANK" },
  { label: "Investment", value: "INVESTMENT" },
  { label: "Credit", value: "CREDIT_CARD" },
  { label: "Loan", value: "LOAN" },
  { label: "Mortgage", value: "MORTGAGE" },
];
if (IS_EMPOWER) {
  const label = getGroupLabel(window.institutionalPartnerName);
  PRODUCT_FILTER_OPTIONS.splice(1, 0, {
    label: label,
    value: "INVESTMENT_EMPOWER_ACCOUNT",
  });
}
const INITIAL_DATE_RANGE_DAYS = 89;
const ALL_TRANSACTIONS_VIEW_RENDERED_EVENT = "allTransactionsViewRendered";
const OFFSET_FOR_STICKY_TRIGGER = 24;
const ERR_MSG_CONTAINER_ID = "all-transactions-page-error";

export default class AllTransactionsViewContainer extends React.Component {
  constructor(props) {
    super(props);

    const dateRangeEnd = moment().endOf("day");
    const dateRangeStart = moment()
      .startOf("day")
      .subtract(INITIAL_DATE_RANGE_DAYS, "days");

    this.state = {
      dateRangeEnd,
      dateRangeStart,
      allTransactionsGroupedByProductType: {},
      showTransactionDetail: false,
      transactionDetails: {},
      isZeroState: false,
    };
    this.groupTransactionsByProductType =
      this.groupTransactionsByProductType.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchTransactions = this.fetchTransactions.bind(this);
    this.processTransactionData = this.processTransactionData.bind(this);
    this.initializeDateRangeSelector =
      this.initializeDateRangeSelector.bind(this);
    this.destroyDateRangeSelector = this.destroyDateRangeSelector.bind(this);
    this.navigateToProductPath = this.navigateToProductPath.bind(this);
    this.getValidProductFilter = this.getValidProductFilter.bind(this);
    this.handleFilterButtonClick = this.handleFilterButtonClick.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleGetTransactionsSuccess =
      this.handleGetTransactionsSuccess.bind(this);
    this.handleGetTransactionsFailure =
      this.handleGetTransactionsFailure.bind(this);
    this.handleGetAccountsSuccess = this.handleGetAccountsSuccess.bind(this);
    this.handleGetAccountsFailure = this.handleGetAccountsFailure.bind(this);
    this.dateRangeSelectorRef = React.createRef();
    this.handleTransactionsDetails = this.handleTransactionsDetails.bind(this);
    this.hideTransactionsDetails = this.hideTransactionsDetails.bind(this);
    this.isEligibleForManualTransactions =
      this.isEligibleForManualTransactions.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
    if (!IS_EMPOWER) {
      window.addEventListener("scroll", this.handleScroll);
    }

    this.initializeDateRangeSelector();
    eventBus.once(ALL_TRANSACTIONS_VIEW_RENDERED_EVENT, () => {
      // caching DOM references for future use in `handleScroll`
      const dateSelectorRef = document.querySelector(".dateSelector");
      this.dateSelectorBottom =
        (dateSelectorRef && dateSelectorRef.getBoundingClientRect().bottom) ||
        null;
      this.datePickerRef = document.querySelector("#ui-datepicker-div");
    });
    eventBus.on(ALL_TRANSACTIONS_VIEW_RENDERED_EVENT, () => {
      AppOverlay.hide();
      // This line is to hide the loader in when page is iframed
      eventBus.trigger("pagerendered");
      if (IS_IFRAMED) {
        iframeClient.triggerResize();
      }
    });
    this.fetchData();
    mixpanel.trackEvent("View Transactions", {
      component: "allTransactionsViewContainer",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showTransactionDetail === true) {
      this.initializeDateRangeSelector();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    eventBus.off(ALL_TRANSACTIONS_VIEW_RENDERED_EVENT);
    if (this.fetchTransactionsCancelablePromise) {
      this.fetchTransactionsCancelablePromise.cancel();
    }

    if (this.getTransactionsSubscription) {
      this.getTransactionsSubscription.unwatch();
      this.getTransactionsSubscription.off("change");
    }

    if (this.fetchAccountsCancelablePromise) {
      this.fetchAccountsCancelablePromise.cancel();
    }

    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unwatch();
      this.getAccountsSubscription.off("change");
    }

    if (this.dateRangeSelector) {
      this.destroyDateRangeSelector();
    }
  }

  /**
   * Handles position of the date selector as user scrolls
   * and page headers become sticky.
   */

  handleScroll() {
    if (!this.datePickerRef || this.dateSelectorBottom === null) {
      return;
    }
    let datePickerStyle = this.datePickerRef.style;
    let offset = window.scrollY;
    if (offset === undefined) {
      offset = window.document.documentElement.scrollTop;
    }
    if (window.scrollY > OFFSET_FOR_STICKY_TRIGGER) {
      Object.assign(datePickerStyle, {
        position: "fixed",
        top: this.dateSelectorBottom - OFFSET_FOR_STICKY_TRIGGER + "px", // string assignment for cross browser support
      });
    } else {
      Object.assign(datePickerStyle, {
        position: "absolute",
        top: this.dateSelectorBottom + "px", // string assignment for cross browser support
      });
    }
  }

  /**
   * Pre-group sets of transactions for each product, so that they can be
   * instantly loaded on filter button change.
   *
   * @param {Array} accounts all accounts for the user.
   * @param {Array} transactions all transactions for the user.
   *
   * @return {Object} grouped transactions by product category.
   */
  groupTransactionsByProductType(accounts, transactions) {
    const accountTypeGroupsDict = {};

    // build a dict between accountId and productType so that it can be
    // used to group transactions by productType.
    accounts.forEach((account) => {
      accountTypeGroupsDict[account.accountId] = account.productType;
      if (account.isEmpower) {
        accountTypeGroupsDict[account.accountId] =
          account.productType + "_EMPOWER_ACCOUNT";
      }
    });

    // group all transactions by productType.
    return groupBy(transactions, (transaction) => {
      return accountTypeGroupsDict[transaction.accountId];
    });
  }

  /**
   * Fetch the accounts, then call the other promises to fetch transactions
   * and categories.
   */
  fetchData() {
    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unwatch();
      this.getAccountsSubscription.off("change");
    }

    this.getAccountsSubscription = this.props.watchAccounts();
    this.getAccountsSubscription.on("change", this.handleGetAccountsSuccess);
    this.fetchAccountsCancelablePromise = makeCancelablePromise(
      this.getAccountsSubscription.promise
    );
    this.fetchAccountsCancelablePromise.promise
      .then(this.handleGetAccountsSuccess)
      .catch(this.handleGetAccountsFailure);
  }

  /**
   * Fetch all the transactions for the given account ids, within the current
   * date range.
   *
   */
  fetchTransactions() {
    const { isEmpower, selectedAccountId } = this.props;

    const accountIds = this.state.accounts.map(
      (account) => account.userAccountId
    );
    let options = {};
    if (isEmpower && selectedAccountId) {
      options = {
        userAccountIds: JSON.stringify([selectedAccountId]),
        startDate: this.state.dateRangeStart.format(DateUtils.API_FORMAT),
        endDate: this.state.dateRangeEnd.format(DateUtils.API_FORMAT),
      };
    } else {
      options = {
        userAccountId: JSON.stringify(accountIds),
        startDate: this.state.dateRangeStart.format(DateUtils.API_FORMAT),
        endDate: this.state.dateRangeEnd.format(DateUtils.API_FORMAT),
      };
    }

    if (this.getTransactionsSubscription) {
      this.getTransactionsSubscription.unwatch();
      this.getTransactionsSubscription.off("change");
    }

    this.getTransactionsSubscription = this.props.watchTransactions(options);
    this.getTransactionsSubscription.on(
      "change",
      this.handleGetTransactionsSuccess
    );
    this.fetchTransactionsCancelablePromise = makeCancelablePromise(
      this.getTransactionsSubscription.promise
    );
    this.fetchTransactionsCancelablePromise.promise
      .then(this.handleGetTransactionsSuccess)
      .catch(this.handleGetTransactionsFailure);
  }

  /**
   * Process fetched transactions and update state accordingly.
   *
   *  @param {Array} transactions retrieved transactions.
   */
  async processTransactionData(transactions) {
    const { isEmpower, selectedAccountId, getInvestmentTransfers } = this.props;

    let allTransactions = [...transactions];
    if (isEmpower) {
      const options = {
        startDate: this.state.dateRangeStart.format(DateUtils.API_FORMAT),
        endDate: this.state.dateRangeEnd.format(DateUtils.API_FORMAT),
      };
      try {
        const investmentTransfersRes = await getInvestmentTransfers({
          investmentTransfers: JSON.stringify({
            ...options,
            userAccountId: selectedAccountId,
          }),
        });

        if (investmentTransfersRes?.transfers) {
          allTransactions = [
            ...allTransactions,
            ...investmentTransfersRes.transfers.map((t) => ({
              ...t,
              // We need this attribute for the sorting used in `sortingUtils.js` on function `compositeTimeComparator`
              transactionDate: t.createdDate
                ? moment(t.createdDate).format(DateUtils.API_FORMAT)
                : null,
              isEmpower: true,
            })),
          ];
        }
      } catch (err) {
        this.setState({ error: err });
        return;
      }
    }
    const allTransactionsGroupedByProductType =
      this.groupTransactionsByProductType(this.state.accounts, allTransactions);
    allTransactionsGroupedByProductType.ALL = allTransactions;

    // Split transaction is not supported for Investment account transactions.
    const investmentTransactions =
      allTransactionsGroupedByProductType[Account.PRODUCT_TYPES.INVESTMENT];

    if (!isEmpty(investmentTransactions)) {
      setHideSplitTransaction(investmentTransactions);
    }

    this.setState(
      {
        error: [],
        allTransactionsGroupedByProductType,
      },
      () => {
        eventBus.trigger(ALL_TRANSACTIONS_VIEW_RENDERED_EVENT);
      }
    );
  }

  /**
   * Create the `DateRangeSelector` backbone component.
   * [extracted for easier testing]
   */
  initializeDateRangeSelector() {
    this.dateRangeSelector = new DateRangeSelector({
      el: this.dateRangeSelectorRef.current,
      initialStartDate: this.state.dateRangeStart,
      initialEndDate: this.state.dateRangeEnd,
    });
    this.dateRangeSelector.on("change:dateRange", this.handleDateRangeChange);
  }

  /**
   * Remove the `DateRangeSelector` and remove its event.
   * [Extracted for stubbing]
   */
  destroyDateRangeSelector() {
    this.dateRangeSelector.off("change:dateRange");
    this.dateRangeSelector.remove();
    delete this.dateRangeSelector;
  }

  /**
   * Route to the given product url.
   * Extracted to function for easier testing.
   *
   * @param {String} activeFilter routed product filter.
   */
  navigateToProductPath(activeFilter) {
    window.location.hash = `#/all-transactions/${activeFilter}`;
    // Always scroll to the top, preserving the x coordinate for small viewports
    window.scrollTo(window.scrollX, 0);
  }

  /**
   * Prevent an invalid filter from being passed by path.
   *
   * @param {String} filter proposed filter.
   * @return {String} a valid or default filter.
   */
  getValidProductFilter(filter) {
    if (PRODUCT_FILTER_OPTIONS.some((option) => option.value === filter)) {
      return filter;
    }

    return DEFAULT_PRODUCT_TYPE_FILTER;
  }

  /**
   * Look in `PRODUCT_FILTER_OPTIONS` for a product which matches a given `value`, and return its corresponding `label`.
   *
   * @param {String} productValue The `value` to look up a matching `product` by.
   *
   * @returns {?String} The `label` of the `product` matching the `productValue`, if one can be found.
   */
  getProductLabelFromValue(productValue) {
    const productsMatchingValue = PRODUCT_FILTER_OPTIONS.filter(
      (option) => option.value === productValue
    );

    if (isEmpty(productsMatchingValue)) {
      // eslint-disable-next-line no-console
      console.error("WARNING: Bad `productValue`, no matching product");

      return null;
    }

    if (productsMatchingValue.length > 1) {
      // eslint-disable-next-line no-console
      console.error(
        "WARNING: Multiple products in `PRODUCT_FILTER_OPTIONS` contain the same `value`"
      );
    }

    return productsMatchingValue[0].label;
  }

  /**
   * When a filter button is clicked, reroute the page to the url of the
   * corresponding product type group.
   *
   * @param {String} activeFilter value of the clicked button.
   */
  handleFilterButtonClick(activeFilter) {
    this.navigateToProductPath(activeFilter);
  }

  /**
   * When the date range changes, filter the previously filtered transactions further,
   * so that they can be instantly loaded by the ButtonGroup.
   *
   * @param {String} startDate minimum valid transaction date.
   * @param {String} endDate maximum valid transaction date.
   */
  handleDateRangeChange(startDate, endDate) {
    const dateRangeStart = moment(startDate, DateUtils.DISPLAY_FORMAT).startOf(
      "day"
    );
    const dateRangeEnd = moment(endDate, DateUtils.DISPLAY_FORMAT).endOf("day");
    if (!dateRangeStart.isValid()) {
      this.handleInvalidDateInput(document.querySelector(".js-start-date"));
      return;
    }
    if (!dateRangeEnd.isValid()) {
      this.handleInvalidDateInput(document.querySelector(".js-end-date"));
      return;
    }
    AppOverlay.show();
    this.setState(
      {
        dateRangeStart,
        dateRangeEnd,
        errors: null,
      },
      this.fetchTransactions
    );
    // Always scroll to the top, preserving the x coordinate for small viewports
    window.scrollTo(window.scrollX, 0);
  }

  handleInvalidDateInput(inputClass) {
    this.setState(
      {
        errors: ["Please enter a valid date."],
      },
      () => {
        if (inputClass && typeof inputClass.focus === "function") {
          inputClass.focus();
        }
      }
    );
  }

  handleGetAccountsSuccess(response) {
    if (IS_EMPOWER) {
      if (response && response.accounts && response.accounts.length) {
        this.setState(
          {
            errors: [],
            accounts: response.accounts,
          },
          this.fetchTransactions
        );
      } else {
        document.querySelector("#moduleContainer").dispatchEvent(
          new CustomEvent("zeroState", {
            detail: { page: "transactions", close: false },
          })
        );
        this.setState({
          errors: [],
          accounts: [],
          isZeroState: true,
        });
        this.handleGetTransactionsSuccess({
          transactions: transactionsZeroStateData,
        });
      }
    } else {
      this.setState(
        {
          errors: [],
          accounts: (response && response.accounts) || [],
        },
        this.fetchTransactions
      );
    }
  }

  handleGetAccountsFailure(errors) {
    Analytics.sendEngineeringEvent(
      "Error",
      "Services.Accounts.getV2: " + JSON.stringify(errors)
    );

    if (this.fetchAccountsCancelablePromise.isCanceled()) {
      return;
    }

    this.setState({ errors });
  }

  handleGetTransactionsSuccess(response) {
    this.processTransactionData(deepCopy(response?.transactions || []));
  }

  handleGetTransactionsFailure(errors) {
    Analytics.sendEngineeringEvent(
      "Error",
      "Services.Transactions.getV2: " + JSON.stringify(errors)
    );

    if (this.fetchTransactionsCancelablePromise.isCanceled()) {
      return;
    }

    this.setState({ errors });
    AppOverlay.hide();
    // This line is to hide the loader in when page is iframed
    eventBus.trigger("pagerendered");
    if (IS_IFRAMED) {
      iframeClient.triggerResize();
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
    });
  }

  handleTransactionsDetails(transaction) {
    this.setState({
      showTransactionDetail: true,
      transactionDetails: transaction,
    });
    this.scrollToTop();
  }

  hideTransactionsDetails() {
    this.setState({
      showTransactionDetail: false,
      transactionDetails: {},
    });
    this.scrollToTop();
  }

  // This links the datepicker input fields to the error message if it shows
  addDescribedByToDatepicker() {
    document
      .querySelector(".js-start-date")
      ?.setAttribute("aria-describedby", ERR_MSG_CONTAINER_ID);
    document
      .querySelector(".js-end-date")
      ?.setAttribute("aria-describedby", ERR_MSG_CONTAINER_ID);
  }

  isEligibleForManualTransactions(accounts) {
    //if there are no accounts, you can't add manual transactions
    if (!accounts || accounts.length === 0) {
      return false;
    }
    const allowedAccounts = accounts.filter(
      (acc) => acc.isManualTransactionAllowed
    );
    return allowedAccounts.length;
  }

  render() {
    const {
      activeFilter,
      search,
      isEmpower,
      accountName,
      selectedAccountId,
      transactionCategoryRulesFlag,
      manualTransactionsFlag,
    } = this.props;
    const validProduct = this.getValidProductFilter(activeFilter);
    const groupedTransactionsForProductType =
      this.state.allTransactionsGroupedByProductType[validProduct] || [];
    const {
      dateRangeStart,
      dateRangeEnd,
      errors,
      showTransactionDetail,
      transactionDetails,
      accounts,
    } = this.state;
    const buttonGroupClass = "pc-btn-group--primary";

    let empowerProps = {};
    if (isEmpower) {
      empowerProps.columns = empowerTransactionsColumns(
        this.handleTransactionsDetails
      );
      empowerProps.searchAccessors = [
        ...TransactionsGridController.defaultProps.searchAccessors,
        empowerActionSearchAccessor,
        formattedQuantitySearchAccessor,
        unformattedPriceSearchAccessor,
        formattedPriceSearchAccessor,
      ];
      empowerProps.buildDataForExportToCsv =
        buildDataForExportToCsvEmpowerColumns;
    }

    this.addDescribedByToDatepicker();
    const eligibleManualTransactionsAccountsCount =
      this.isEligibleForManualTransactions(accounts);

    return (
      <section>
        <Message severity="error" messages={errors} id={ERR_MSG_CONTAINER_ID} />
        <div className="nav-secondary all-transactions__nav-secondary js-secondary-nav">
          {isEmpower && accountName ? (
            <h3>{accountName}</h3>
          ) : (
            <h1 className="nav-secondary__title qa-page-title">Transactions</h1>
          )}
        </div>
        {!showTransactionDetail && (
          <>
            <div className="nav-secondary nav-secondary--feature-controls all-transactions__feature-controls js-feature-controls qa-transactions-sec-nav">
              <div
                className={`l-spaced l-spaced--flush ${
                  isEmpower ? "l-spaced--right" : ""
                }`}
              >
                {isEmpower ? null : (
                  <ButtonGroup
                    options={PRODUCT_FILTER_OPTIONS}
                    selectedValue={validProduct}
                    onChange={this.handleFilterButtonClick}
                    className={buttonGroupClass}
                    buttonClass="all-transactions-product-type-tab"
                  />
                )}
                {this.state.isZeroState ? null : (
                  <div
                    className="dateSelector all-transactions__date-range-selector js-all-transactions-date-range-selector"
                    ref={this.dateRangeSelectorRef}
                  />
                )}
              </div>
            </div>
            <TransactionsGridContainer
              {...empowerProps}
              isEditable={!isEmpower}
              transactions={groupedTransactionsForProductType}
              csvFileName={getCsvFileName(dateRangeStart, dateRangeEnd)}
              startDate={dateRangeStart}
              endDate={dateRangeEnd}
              className={"all-transactions__grid-container"}
              activeProductFilterLabel={this.getProductLabelFromValue(
                validProduct
              )}
              transactionCategoryRulesFlag={transactionCategoryRulesFlag}
              searchInput={search}
              manualTransactionsFlag={manualTransactionsFlag}
              eligibleForManualTransactions={
                eligibleManualTransactionsAccountsCount > 0
              }
              accountsList={accounts}
            />
          </>
        )}
        {showTransactionDetail && (
          <RequestDetailContainer
            hideTransactionsDetails={this.hideTransactionsDetails}
            transaction={transactionDetails}
            userAccountId={selectedAccountId}
          />
        )}
      </section>
    );
  }
}

AllTransactionsViewContainer.propTypes = {
  watchTransactions: PropTypes.func,
  watchAccounts: PropTypes.func,
  getInvestmentTransfers: PropTypes.func,
  activeFilter: PropTypes.string,
  search: PropTypes.string,
  isEmpower: PropTypes.bool,
  selectedAccountId: PropTypes.number,
  accountName: PropTypes.string,
  transactionCategoryRulesFlag: PropTypes.bool,
  manualTransactionsFlag: PropTypes.bool,
};

AllTransactionsViewContainer.defaultProps = {
  watchTransactions: subscribify(Services.Transactions.get),
  watchAccounts: subscribify(Services.Accounts.get),
  getInvestmentTransfers: promisify(
    Services.EmpowerInvestment.getInvestmentTransfers
  ),
  activeFilter: undefined,
  search: undefined,
  isEmpower: false,
  selectedAccountId: undefined,
  accountName: undefined,
  transactionCategoryRulesFlag: false,
  manualTransactionsFlag: false,
};
