/* eslint-disable sonarjs/cognitive-complexity */
import PropTypes from "prop-types";
import React from "react";
import PERSONALCAPITAL from "PERSONALCAPITAL";
import moment from "moment";
import { API_FORMAT } from "libs/pcap/utils/date2";
import memoizeOne from "memoize-one";
import AppOverlay from "appOverlay";

// Transactions Grid
import UserTransactionsFetcher from "components/common/Fetchers/UserTransactionsFetcherV2";

import accountDetailsCashflowColumns, {
  buildDataForExportToCSVAccountDetailsCashflow,
} from "components/TransactionsGridV3/columns/accountDetailsCashflow";
import accountDetailsCashflowSplit from "components/TransactionsGridV3/columns/accountDetailsCashflowSplit";
import TransactionsGridContainer from "components/TransactionsGridV3/TransactionsGridContainer";
import TransactionEditRowAccountDetailsCashflow from "components/TransactionsGridV3/TransactionEditRowAccountDetailsCashflow";
import accountDetailsBalanceAndIncomeColumns, {
  balanceAndIncomeSearchAccessors,
  buildDataForExportToCSVAccountDetailsBalanceAndIncome,
} from "components/TransactionsGridV3/columns/accountDetailsBalanceAndIncome";
import TransactionEditRowAccountDetailsBalancesAndIncome from "components/TransactionsGridV3/TransactionEditRowAccountDetailsBalancesAndIncome";
import PcapCashGridFooterAndDisclaimers from "./pcapCashFooterDisclaimer";
import accountDetailsPcapCashColumns from "components/TransactionsGridV3/columns/accountDetailsPcapCash";
// Holdings Grid
import HoldingsFetcher from "components/common/Fetchers/HoldingsFetcher";
import HoldingsGridContainer from "components/holdingsGrid/HoldingsGridContainer";
import AggregatedHoldingsEditRow from "components/holdingsGrid/AggregatedHoldings/AggregatedHoldingsEditRow";
import ManualHoldingsEditRow from "components/holdingsGrid/ManualHoldings/ManualHoldingsEditRow";
import accountDetailsHoldingsColumns from "components/holdingsGrid/columns/accountDetailsHoldings";

const transactionIncomeFilter = memoizeOne((transactions) => {
  return transactions.filter((t) => t.isIncome);
});

const cashHoldingFilterOut = memoizeOne((holdings) => {
  return holdings.filter((t) => t.holdingType !== "Cash");
});

export default class DatagridView2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionsFilter: PERSONALCAPITAL.get("transactionsFilter"),
    };
    PERSONALCAPITAL.on(
      "change:transactionsFilter",
      this.handleTransactionsFilterChange.bind(this)
    );
  }

  componentWillUnmount() {
    PERSONALCAPITAL.off(
      "change:transactionsFilter",
      this.handleTransactionsFilterChange
    );
  }

  handleTransactionsFilterChange(model) {
    AppOverlay.show();
    this.setState({
      transactionsFilter: model.get("transactionsFilter"),
    });
  }

  static handleFinishLoading() {
    window.dispatchEvent(new CustomEvent("pageloaded"));
  }

  render() {
    const {
      tabId,
      account,
      userAccountIds,
      dataFilter,
      transactionCategoryRulesFlag,
      manualTransactionsFlag,
      eligibleForManualTransactions,
    } = this.props;
    const startDate = moment(this.state.transactionsFilter.startDate);
    const endDate = moment(this.state.transactionsFilter.endDate);
    const apiParameters = {
      userAccountIds: account
        ? JSON.stringify([account.get("userAccountId")])
        : JSON.stringify(userAccountIds),
      startDate: startDate.format(API_FORMAT),
      endDate: endDate.format(API_FORMAT),
      tabId,
    };
    // TODO here we render the right grid containers based in tabId, passing account and transactionsFilter
    switch (tabId) {
      case "cashFlowPage":
        return (
          <UserTransactionsFetcher
            Component={TransactionsGridContainer}
            apiParameters={apiParameters}
            onFinishLoading={DatagridView2.handleFinishLoading}
            startDate={startDate}
            endDate={endDate}
            dataFilter={dataFilter}
            className="pc-u-mt-"
            transactionCategoryRulesFlag={transactionCategoryRulesFlag}
            manualTransactionsFlag={manualTransactionsFlag}
            eligibleForManualTransactions={eligibleForManualTransactions}
            account={account}
          />
        );
      default:
      case "cashflow":
      case "charges":
        return (
          <>
            <UserTransactionsFetcher
              Component={TransactionsGridContainer}
              apiParameters={apiParameters}
              onFinishLoading={DatagridView2.handleFinishLoading}
              // Grid props
              startDate={startDate}
              endDate={endDate}
              columns={
                this.props.account.get("isOnUsBank")
                  ? accountDetailsPcapCashColumns
                  : accountDetailsCashflowColumns
              }
              splitColumns={accountDetailsCashflowSplit}
              isEditable={
                !account.get("isOnUs") || !account.get("isOnUsRetirement")
              }
              renderDuplicates={true}
              TransactionEditRow={TransactionEditRowAccountDetailsCashflow}
              buildDataForExportToCsv={
                buildDataForExportToCSVAccountDetailsCashflow
              }
              transactionCategoryRulesFlag={transactionCategoryRulesFlag}
              manualTransactionsFlag={manualTransactionsFlag}
              eligibleForManualTransactions={eligibleForManualTransactions}
              account={account}
            />
            {this.props.account.get("isOnUsBank") && (
              <PcapCashGridFooterAndDisclaimers />
            )}
          </>
        );
      case "balance":
        // Loan account tabId is balance, which is also investment -> balance && income tab ID.
        // In this case we need to set the columns based on the product type
        if (
          ["LOAN", "MORTGAGE"].includes(this.props.account.get("productType"))
        ) {
          return (
            <UserTransactionsFetcher
              Component={TransactionsGridContainer}
              apiParameters={apiParameters}
              onFinishLoading={DatagridView2.handleFinishLoading}
              // Grid props
              startDate={startDate}
              endDate={endDate}
              columns={accountDetailsCashflowColumns}
              isEditable={
                !account.get("isOnUs") || !account.get("isOnUsRetirement")
              }
              renderDuplicates={true}
              TransactionEditRow={TransactionEditRowAccountDetailsCashflow}
              transactionCategoryRulesFlag={transactionCategoryRulesFlag}
              manualTransactionsFlag={manualTransactionsFlag}
              eligibleForManualTransactions={eligibleForManualTransactions}
              account={account}
            />
          );
        }
        return (
          <UserTransactionsFetcher
            Component={TransactionsGridContainer}
            apiParameters={apiParameters}
            onFinishLoading={DatagridView2.handleFinishLoading}
            // Grid props
            startDate={startDate}
            endDate={endDate}
            columns={accountDetailsBalanceAndIncomeColumns}
            splitColumns={null}
            isEditable={
              !account.get("isOnUs") || !account.get("isOnUsRetirement")
            }
            renderDuplicates={true}
            TransactionEditRow={
              TransactionEditRowAccountDetailsBalancesAndIncome
            }
            searchAccessors={balanceAndIncomeSearchAccessors}
            buildDataForExportToCsv={
              buildDataForExportToCSVAccountDetailsBalanceAndIncome
            }
            transactionCategoryRulesFlag={transactionCategoryRulesFlag}
            manualTransactionsFlag={manualTransactionsFlag}
            eligibleForManualTransactions={eligibleForManualTransactions}
            account={account}
          />
        );
      case "income":
        return (
          <UserTransactionsFetcher
            Component={TransactionsGridContainer}
            apiParameters={apiParameters}
            onFinishLoading={DatagridView2.handleFinishLoading}
            dataFilter={transactionIncomeFilter}
            // Grid props
            startDate={startDate}
            endDate={endDate}
            columns={accountDetailsBalanceAndIncomeColumns}
            isEditable={
              !account.get("isOnUs") || !account.get("isOnUsRetirement")
            }
            renderDuplicates={true}
            TransactionEditRow={
              TransactionEditRowAccountDetailsBalancesAndIncome
            }
            searchAccessors={balanceAndIncomeSearchAccessors}
            buildDataForExportToCsv={
              buildDataForExportToCSVAccountDetailsBalanceAndIncome
            }
            transactionCategoryRulesFlag={transactionCategoryRulesFlag}
            manualTransactionsFlag={manualTransactionsFlag}
            eligibleForManualTransactions={eligibleForManualTransactions}
            account={account}
          />
        );
      case "holdings":
        return (
          <HoldingsFetcher
            Component={HoldingsGridContainer}
            //Filter cash holding for manual accounts
            dataFilter={
              this.props.account.get("isManual")
                ? cashHoldingFilterOut
                : undefined
            }
            userAccountIds={apiParameters.userAccountIds}
            onFinishLoading={DatagridView2.handleFinishLoading}
            // Grid props
            className={"pc-holdings-grid--account-details"}
            isEditable={
              !account.get("isOnUs") && !account.get("isOnUsRetirement")
            }
            showAddHolding={account.get("isManual")}
            EditHoldingRow={
              account.get("isManual")
                ? ManualHoldingsEditRow
                : AggregatedHoldingsEditRow
            }
            showSearch={true}
            userAccountId={account.get("userAccountId")}
            columns={accountDetailsHoldingsColumns}
            account={account}
          />
        );
    }
  }
}

DatagridView2.propTypes = {
  tabId: PropTypes.string,
  userAccountIds: PropTypes.array,
  dataFilter: PropTypes.func,
  account: PropTypes.object,
  transactionCategoryRulesFlag: PropTypes.bool,
  manualTransactionsFlag: PropTypes.bool,
  eligibleForManualTransactions: PropTypes.bool,
};

DatagridView2.defaultProps = {
  tabId: "cashflow",
  userAccountIds: [],
  dataFilter: (t) => t,
  account: undefined,
  transactionCategoryRulesFlag: false,
  manualTransactionsFlag: false,
  eligibleForManualTransactions: false,
};
