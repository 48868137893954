import PropTypes from "prop-types";
import React from "react";
import services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import RetirementSavingsSummary from "components/retirementSavingsSummary/RetirementSavingsSummary";
import RetirementWithdrawalsSummary from "components/retirementSavingsSummary/RetirementWithdrawalsSummary";
import getZeroStateUserMessage from "libs/pcap/utils/getZeroStateUserMessage";
import { SPACING_UNIT } from "constants/styles";
import { trackClick } from "components/common/ComponentAnalytics";
import { isAggQualProspect } from "libs/pcap/utils/userStage";
import { noop } from "underscore";
import ZeroStateWidget from "../dashboard/ZeroStateWidget";

const DISPLAY_LOCATION = "#/dashboard/retirement-planner";
const SMART_WITHDRAWAL_URL = RETIREMENT_PLANNER_URL + "/paycheck";
const SAVINGS_PLANNER_URL = "#/savings-planner";

export default class RetirementSavingsSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      isUserRetired: false,
      loading: true,
    };

    this.onRetirementCashFlowFetched =
      this.onRetirementCashFlowFetched.bind(this);
  }

  componentDidMount() {
    services.Accounts.getRetirementCashFlow(
      {},
      this.onRetirementCashFlowFetched
    );
  }

  componentWillUnmount() {
    this.componentUnmounted = true;
  }

  onRetirementCashFlowFetched(err, response) {
    if (this.componentUnmounted) {
      return;
    }

    const errors = parseResponseErrors(err, response);
    if (errors) {
      this.setState(
        {
          loading: false,
          errors: errors,
        },
        this.props.onDCDashboardComponentLoaded
      );
      return;
    }

    let currentBalance;
    if (response.spData.isRetired) {
      currentBalance =
        response.spData.current < 0 ? 0 : response.spData.current;
    } else {
      currentBalance = response.spData.current;
    }

    this.setState(
      {
        currentBalance,
        targetBalance: response.spData.target,
        isUserRetired: response.spData.isRetired,
        isUserProspect: isAggQualProspect(response.spHeader.userStage),
        isUserClient: response.spData.isClient,
        portfolios: response.spData.portfolio,
        rmdIncome: response.spData.rmdIncome,
        errors: null,
        loading: false,
        zeroState: getZeroStateUserMessage(
          this.props.messages,
          DISPLAY_LOCATION
        ),
        annualSavingsNeededFrom: response.spData.annualSavingsNeededFrom,
        annualSavingsNeededTo: response.spData.annualSavingsNeededTo,
        isCalculatedWithdrawal: response.spData.syncWithRP,
      },
      this.props.onDCDashboardComponentLoaded
    );
  }

  handleTitleClick(e) {
    trackClick(e, "Retirement Savings Widget");
    window.dashboardUtils?.eventBus.dispatch(
      "retirement_savings_widget_title_click_event"
    );
    window.dashboardUtils?.eventBus.dispatchAmplitude({
      event_type: window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
      event_properties: {
        selection: "retirement_savings_widget_title_click_event",
      },
    });
    return true;
  }

  render() {
    const { isUserRetired, isUserClient, isUserProspect } = this.state;

    let titleText;
    let titleUrl;
    let enrolledInFullManagedAccounts = false;

    if (window.managedAccountsOptions) {
      enrolledInFullManagedAccounts =
        window.managedAccountsOptions.enrolledInFullManagedAccounts;
    }

    if (isUserRetired) {
      if ((isUserClient || isUserProspect) && enrolledInFullManagedAccounts) {
        titleText = "Planned Withdrawals";
        titleUrl = SMART_WITHDRAWAL_URL;
      } else {
        titleText = "Retirement Withdrawals";
        titleUrl = SAVINGS_PLANNER_URL;
      }
    } else {
      titleUrl = SAVINGS_PLANNER_URL;
      titleText = "Retirement Savings";
    }

    const title = (
      <h2 className="pc-delta pc-u-mb0">
        <a
          href={titleUrl}
          className="summary-widget__title--link"
          onClick={this.handleTitleClick}
          aria-label={`Go to ${titleText}`}
        >
          {titleText}
        </a>
      </h2>
    );

    if (
      !this.state.loading &&
      (!this.state.currentBalance || this.state.currentBalance === 0) &&
      this.props.accounts &&
      this.props.accounts.length === 0 &&
      window.integratedSharedData
    ) {
      const translations = window.integratedSharedData?.translations;
      const zeroStateTranslations = translations?.get("zeroStateDashboard");

      return (
        <ZeroStateWidget
          displayName={zeroStateTranslations?.savingsPlanner?.title}
          cta={zeroStateTranslations?.savingsPlanner?.cta}
          link={"#/savings-planner"}
          label={zeroStateTranslations?.savingsPlanner?.label}
          tooltip={zeroStateTranslations?.savingsPlanner?.tooltip}
          buttonAlign={"center"}
          buttonClass={"btn__retirementSavings"}
          widgetHeaderClass={"header__retirementSavings"}
          backgroundName="retirementsavings"
        />
      );
    }

    if (!isUserRetired) {
      return (
        <RetirementSavingsSummary
          title={title}
          errors={this.state.errors}
          loading={this.state.loading}
          currentBalance={this.state.currentBalance}
          targetBalance={this.state.targetBalance}
          annualSavingsNeededFrom={this.state.annualSavingsNeededFrom}
          annualSavingsNeededTo={this.state.annualSavingsNeededTo}
          arcWidth={SPACING_UNIT}
        />
      );
    }
    return (
      <RetirementWithdrawalsSummary
        title={title}
        errors={this.state.errors}
        loading={this.state.loading}
        isUserClient={this.state.isUserClient}
        rmdIncome={this.state.rmdIncome}
        currentBalance={this.state.currentBalance}
        targetBalance={this.state.targetBalance}
        portfolios={this.state.portfolios}
        zeroState={this.state.zeroState}
        ace={this.props.ace}
        isCalculatedWithdrawal={this.state.isCalculatedWithdrawal}
      />
    );
  }
}

RetirementSavingsSummaryContainer.propTypes = {
  accounts: PropTypes.array,
  messages: PropTypes.array,
  ace: PropTypes.object,
  onDCDashboardComponentLoaded: PropTypes.func,
};

RetirementSavingsSummaryContainer.defaultProps = {
  accounts: [],
  messages: [],
  ace: null,
  onDCDashboardComponentLoaded: noop,
};
