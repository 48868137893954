var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"title\">Request Support</h3>\n<form class=\"requestSupportForm\">\n	<p>We will review the volume of users that request the same institution and let you know our progress.</p>\n	<div class=\"formElement\">\n		<label>Your search</label>\n		<input type=\"text\" name=\"searchPhrase\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"searchPhrase") || (depth0 != null ? lookupProperty(depth0,"searchPhrase") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"searchPhrase","hash":{},"data":data,"loc":{"start":{"line":6,"column":48},"end":{"line":6,"column":64}}}) : helper)))
    + "\" />\n	</div>\n	<div class=\"formElement\">\n		<label>Website for this institution</label>\n		<span class=\"optionalInput\">optional</span>\n		<input type=\"text\" name=\"loginUrl\" placeholder=\"www.amcbank.com\"/>\n	</div>\n	<div class=\"formElement\">\n		<label>Any details that might help us</label>\n		<span class=\"optionalInput\">optional</span>\n		<textarea name=\"otherInformation\" rows=\"5\" placeholder=\"Based in Kansas City, affiliated with Citibank etc...\"></textarea>\n	</div>\n	<div class=\"buttonContainer\">\n		<a class=\"sendRequest active submitForm pc-btn pc-btn--primary\" href=\"#\">Send Request</a>\n		<input type=\"submit\" class=\"offscreen\" />\n	</div>\n</form>\n";
},"useData":true});