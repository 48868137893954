/* eslint-disable camelcase */

import React from "react";
import PostLoginActionsProvider from "views/components/postLoginActions/PostLoginActionsProvider.js";
import Promo from "./Promo";
import PropTypes from "prop-types";
import noop from "utils/noop";

const PromoModal = (props) => {
  return (
    <PostLoginActionsProvider {...props}>
      {({ jsonContent, imageSource, userMessage }) => (
        <Promo
          {...props}
          jsonContent={jsonContent}
          imageSource={imageSource}
          userMessage={userMessage}
          componentName={userMessage.template}
        />
      )}
    </PostLoginActionsProvider>
  );
};

PromoModal.propTypes = {
  onRequestClose: PropTypes.func,
  setOnBeforeClose: PropTypes.func,
};

PromoModal.defaultProps = {
  onRequestClose: noop,
  setOnBeforeClose: noop,
};
export default PromoModal;
