import PropTypes from "prop-types";
import React from "react";
import Modal from "components/modal/Modal";

const InvestmentCheckupDisclaimerModal = (props) => {
  const { componentName, isOpen, onClosed } = props;
  const modalComponentName = `${componentName} Disclaimer`;

  const organizationName = "Empower";
  const organizationNameDashboard = "Empower Personal Dashboard™";

  return (
    <Modal
      componentName={modalComponentName}
      contentLabel={modalComponentName}
      isOpen={isOpen}
      onClosed={onClosed}
      title="Investment Checkup Disclaimer"
    >
      <p>
        {`The Investment Checkup is intended to provide insight based on the
        accounts you have aggregated on the ${organizationNameDashboard} and other
        information you have provided. Because there is no way to know all
        information about your finances or your personal situation, comments or
        analysis may be incomplete or inaccurate.`}
      </p>
      <p>
        All insight provided represents a courtesy extended to you for
        educational purpose and you should not rely on this information as the
        primary basis of your investment, financial, or tax planning decisions.
        You should consult your legal or tax professional regarding your
        specific situation.
      </p>
      <p>
        Past performance is not a guarantee of future return, nor is it
        necessarily indicative of future performance. Keep in mind investing
        involves risk. The value of your investment will fluctuate over time and
        you may gain or lose money.
      </p>
      <p>
        Comparative analysis, including Monte Carlo analysis, is intended to
        illustrate hypothetical performance of various allocation strategies
        based on a long-term historical asset class performance and is not meant
        to imply actual performance or results.
      </p>
      <p>
        Much of the analysis does not include taxes or fees, which can have a
        material impact. It is not possible to invest directly in an index
        without incurring expenses, which will lower the actual return.
      </p>
      <p>
        {`Investment Checkup includes content supplied by companies that are not
        affiliated with ${organizationName}. Please refer to`}{" "}
        <a
          href={`https://${EMPOWER_SUPPORT_URL}/hc/en-us/articles/201170270-What-are-the-primary-limitations-of-the-Investment-Checkup`}
          target="_blank"
          rel="noreferrer"
        >
          Investment Check FAQ
        </a>{" "}
        for additional information, including data sources.
      </p>
      <div className="u-text-right">
        <button className="pc-btn pc-btn--primary" onClick={onClosed}>
          Close
        </button>
      </div>
    </Modal>
  );
};

InvestmentCheckupDisclaimerModal.propTypes = {
  componentName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClosed: PropTypes.func,
};

InvestmentCheckupDisclaimerModal.defaultProps = {
  componentName: "",
  isOpen: false,
  onClosed: () => null,
};

export default InvestmentCheckupDisclaimerModal;
